import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../hooks/theme";
import Btn from "../button/Button";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import VoteBtn from "../VoteButton/VoteBtn";
import { BiMessageRounded } from "react-icons/bi";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch, useSelector } from "react-redux";
import {
  get_replies_by_comment_id_async,
  vote_comment_async,
} from "../../services/newsFeedService";
import useUiThemeStyles from "../../hooks/theme_styles";
import { asyncStatus } from "../../utils/async_status";
import { timeAgo } from "../../utils/common/dateFormater";
import { primary_color } from "../../utils/color";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const Replies = ({ comment_id }) => {
  const [accordion, setAccordion] = useState(false);
  const theme = useTheme();
  //   if (depth <= 0) {
  //     return null; // Base case to stop recursion
  //   }
  const { user_data, userAuth } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      get_replies_by_comment_id_async({
        user_id: userAuth ? user_data?._id : "",
        comment_id,
      })
    );
  }, []);

  const {
    vote_comment_status,
    replies_by_comment_status,
    replies_by_comment_data,
    replies_by_comment_error,
  } = useSelector((state) => state.newsFeed);
  const ui_styles = useUiThemeStyles();
  const [loadingId, setLoadingId] = useState("");

  return (
    <Stack
      ml={2}
      my={2}
      flexWrap={"wrap"}
      // flexDirection={"column"}
      gap={1}
      justifyContent={"start"}
    >
      {replies_by_comment_status === asyncStatus.IDLE ||
      replies_by_comment_status === asyncStatus.LOADING ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          // paddingLeft={5}
          justifyContent={"center"}
        >
          <CircularProgress size={20} />
        </Stack>
      ) : replies_by_comment_status === asyncStatus.SUCCEEDED ? (
        replies_by_comment_data?.map((e) => {
          const {
            first_name,
            last_name,
            user_id,
            profile_pic,
            comment_text,
            score,
            vote,
            _id,
            post_id,
            updatedAt,
          } = e;

          return (
            <Stack direction={"row"}>
              <Stack flexDirection={"row"} justifyContent={"start"} mr={1}>
                <Avatar
                  alt={first_name + " " + last_name}
                  src={profile_pic}
                  sx={{ width: 30, height: 30 }}
                />
              </Stack>

              <Stack flexDirection={"column"}>
                <Stack
                  flexDirection={"row"}
                  // justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    textAlign={"start"}
                    fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                    fontWeight={"bold"}
                    marginRight={1}
                    color={theme.text_color}
                  >
                    {first_name} {last_name}
                  </Typography>
                  <Typography
                    textAlign={"start"}
                    fontSize={{ xs: 8, sm: 10, md: 12, lg: 12, xl: 14 }}
                    fontWeight={"bold"}
                    color={"gray"}
                  >
                    {timeAgo(updatedAt)}
                  </Typography>
                </Stack>

                <Typography
                  textAlign={"start"}
                  color={theme.text_color}
                  fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                  sx={{ fontWeight: "lighter" }}
                >
                  {comment_text}
                </Typography>
                <Stack flexDirection={"row"}>
                  {/* <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                   
                    <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      sx={
                        vote === "upvote"
                          ? ui_styles.voted_styles
                          : ui_styles.vote_idle
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "upvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "upvote",
                            })
                          );
                        }
                      }}
                      icon={<ImArrowUp size={15} />}
                    />
                    <Typography
                      variant="p"
                      sx={{ color: theme.text_color, fontSize: 14 }}
                    >
                      {score}
                    </Typography>
                  
                    <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "downvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "downvote",
                            })
                          );
                        }
                      }}
                      sx={
                        vote === "downvote"
                          ? ui_styles.voted_styles
                          : ui_styles.vote_idle
                      }
                      icon={
                        <ImArrowDown
                          // color={theme.icon_color}
                          size={15}
                        />
                      }
                    />
                    
                  </Stack> */}
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      sx={
                        vote === "upvote"
                          ? {
                              color: primary_color,
                              backgroundColor: "transparent",
                              "&.Mui-disabled": {
                                color: primary_color,
                              },
                              // "&:hover": {
                              //   color: "gray",
                              // },
                            }
                          : {
                              color: "gray",
                              backgroundColor: "transparent",
                              "&.Mui-disabled": {
                                color: primary_color,
                              },
                              // "&:hover": {
                              //   color: primary_color,
                              // },
                            }
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "upvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "upvote",
                            })
                          );
                        }
                      }}
                      icon={
                        vote ? <FaHeart size={15} /> : <FaRegHeart size={15} />
                      }
                    />
                    <Typography
                      variant="p"
                      sx={{ color: theme.text_color, fontSize: 14 }}
                    >
                      {score}
                    </Typography>

                    {/* <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "downvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type: "reply",
                              user_id,
                              vote: "downvote",
                            })
                          );
                        }
                      }}
                      sx={
                        vote === "downvote"
                          ? ui_styles.voted_styles
                          : ui_styles.vote_idle
                      }
                      icon={
                        <ImArrowDown
                          // color={theme.icon_color}
                          size={15}
                        />
                      }
                    /> */}
                  </Stack>
                </Stack>
                {/* <Stack
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setAccordion((prev) => !prev);
        }}
      >
        {!accordion ? (
          <KeyboardArrowDownIcon
            color={theme.dark_icon_text_color}
            size={15}
          />
        ) : (
          <KeyboardArrowRightIcon
            color={theme.dark_icon_text_color}
            size={15}
          />
        )}
        <Typography
          variant="p"
          sx={{
            color: theme.text_color,
            fontSize: 14,
            textTransform: "none",
          }}
        >
          Replies
        </Typography>
      </Stack> */}
                {/* <Replies depth={depth - 1} /> */}
              </Stack>
            </Stack>
          );
        })
      ) : (
        <Stack>
          <Typography>Error : {replies_by_comment_error}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Replies;
