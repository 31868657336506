import React, { useEffect, useState } from "react";
import "./main.css";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CreatePostCard from "../../../component/CreatePostCard/CreatePostCard";
import HomeSideCard from "../../../component/homeSideCard/homeSideCard";
import Post from "../../../component/post/Post";
import { RxCross1 } from "react-icons/rx";
import useTheme from "../../../hooks/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  get_comments_by_post_id_async,
  search_post_async,
} from "../../../services/newsFeedService";
import CommentsModalComp from "../../../component/CommentsModal/CommentsModalComp";
import { asyncStatus } from "../../../utils/async_status";
import { orange } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import CreatePostModal from "../../../component/CreatePostModal/CreatePostModal";
import { setEditPostData } from "../../../store/slice/newsFeedSlice";

const Home = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const {
    search_post_status,
    search_post_data,
    no_more_post,
    search_post_error,
  } = useSelector((state) => state.newsFeed);
  const { userAuth, user_data } = useSelector((state) => state.authSlice);
  useEffect(() => {
    if (pageNumber === 1) {
      dispatch(
        search_post_async({
          user_id: userAuth ? user_data._id : "",
          query: "",
          page: pageNumber.toString(),
          postPerPage: "5",
        })
      );
    }
  }, []);
  const [open, setOpen] = useState(false);
  const handleOpen = (post_id) => {
    setOpen(true);
    dispatch(
      get_comments_by_post_id_async({
        user_id: userAuth ? user_data._id : "",
        post_id,
      })
    );
  };
  const handleClose = () => setOpen(false);
  const [createPostopen, setCreatePostOpen] = useState(false);
  const handleCreatePostOpen = (post_id) => {
    setCreatePostOpen(true);
  };

  const edit_post_handle = (data) => {
    dispatch(setEditPostData(data));
  };
  const handleCreatePostClose = () => setCreatePostOpen(false);
  const paginationFunc = () => {
    console.log("status", search_post_status);

    setLoading(true);
    dispatch(
      search_post_async({
        user_id: userAuth ? user_data._id : "",
        query: "",
        page: (pageNumber + 1).toString(),
        postPerPage: "5",
      })
    );
    setPageNumber(pageNumber + 1);
    console.log("naya page");
  };

  console.log("status", user_data);

  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: theme.bg_color }}
    >
      <CommentsModalComp
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
      />
      <CreatePostModal
        handleOpen={handleCreatePostOpen}
        handleClose={handleCreatePostClose}
        open={createPostopen}
      />
      <Container>
        <Grid container mt={2} spacing={2}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            {userAuth && <CreatePostCard onClick={handleCreatePostOpen} />}

            <Stack mb={1}>
              {search_post_status === asyncStatus.LOADING && !loading ? (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={300}
                >
                  <CircularProgress sx={{ color: orange[600] }} />
                </Stack>
              ) : search_post_data?.length ? (
                search_post_data?.map((e) => {
                  return (
                    <Post data={e} edit_handle={() => edit_post_handle(e)} />
                  );
                })
              ) : (
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.card_bg_color,
                    my: 4,
                    padding: 2,
                  }}
                >
                  <Typography sx={{ color: theme.text_color }}>
                    No posts for you! ( Join communities for posts )
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigation("/CreateCommunity")}
                    sx={{ maxWidth: 200 }}
                  >
                    Join Communities
                  </Button>
                </Stack>
              )}
              {loading && search_post_status === asyncStatus.LOADING && (
                <Stack p={1} justifyContent={"center"} alignItems={"center"}>
                  <CircularProgress />
                </Stack>
              )}
              {!no_more_post && search_post_status !== asyncStatus.LOADING && (
                <Button onClick={paginationFunc}>Load more</Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
