import React, { useState } from "react";
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./input.css"; // assuming you have your custom styles here
import { lable_color } from "../../utils/color";

function PasswordInput(props) {
  const {
    id,
    placeholder,
    label,
    onChange,
    required,
    className,
    width,
    style,
    name,
    key,
    spacingg,
    checkDetails,
    setIsCheckedhandle,
    forgotClick,
    forgot_password,
    dontShowCheckbox,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setIsCheckedhandle(event.target.checked);
  };

  return (
    <Stack spacing={spacingg ? spacingg : 1}>
      <Stack flexDirection={"row"} alignItems={"center"}>
        <label
          htmlFor={id}
          style={{
            color: lable_color,
            display: "block",
            fontSize: "18px",
            width: "100%",
            fontWeight: 400,
          }}
        >
          {label}
        </label>
        <InputAdornment position="end">
          <IconButton
            edge="end"
            aria-label="toggle password visibility"
            onClick={handleTogglePassword}
          >
            {showPassword ? (
              <Visibility sx={{ fontSize: 15, mt: 0.6 }} />
            ) : (
              <VisibilityOff sx={{ fontSize: 15, mt: 0.6 }} />
            )}{" "}
            {showPassword ? (
              <label
                htmlFor={id}
                style={{
                  color: lable_color,
                  display: "block",
                  fontSize: 15,
                  width: "100%",
                  fontWeight: 400,
                }}
              >
                show
              </label>
            ) : (
              <label
                htmlFor={id}
                style={{
                  color: lable_color,
                  display: "block",
                  fontSize: 15,
                  width: "100%",
                  fontWeight: 400,
                }}
              >
                hide
              </label>
            )}
          </IconButton>
        </InputAdornment>
      </Stack>
      <input
        key={key}
        name={name}
        type={showPassword ? "text" : "password"}
        width={width}
        id={id}
        placeholder={placeholder}
        className="inputStyle borderStylingInputs"
        onChange={onChange}
        required={required}
        style={{
          padding: "13px 15px",
          borderRadius: "4px",
          fontSize: "18px",
          color: "#383535",
          ...style,
        }}
      />

      <p
        color="textSecondary"
        style={{
          fontSize: 10.3,
          marginTop: 8,
          marginBottom: 10,
          color: "#666666",
        }}
      >
        Use 8 or more characters with a mix of letters, numbers & symbols
      </p>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {!dontShowCheckbox && (
          <Stack flexDirection={"row"} alignItems={"center"}>
            <input
              style={{
                width: 17,
                height: 17,
              }}
              checked={isChecked}
              onChange={handleCheckboxChange}
              type="checkbox"
            />
            <Stack
              sx={{
                fontSize: { md: 12, lg: 12, sm: 10, xs: 10 },
                color: "#111111",
                fontWeight: 400,
                mt: -0.3,
              }}
            >
              {checkDetails}
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{
            color: "#0D4587",
            fontSize: 13,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={forgotClick}
        >
          {forgot_password}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PasswordInput;
