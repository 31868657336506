import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { lable_color, primary_color } from "../../utils/color";
import { useSelector } from "react-redux";
import useTheme from "../../hooks/theme";
import { useNavigate } from "react-router-dom";

function SelectSubTopic(props) {
  const { id, label, style, handleClick, defaultValue } = props;

  // const select_handle = (e) => {
  //   const value = e.target.value;
  //   // handleClick(value);
  // };
  const {
    get_all_sub_topics_by_topic_id_status,
    get_all_sub_topics_by_topic_id_data,
    get_all_sub_topics_by_topic_id_error,
  } = useSelector((state) => state.community);
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack spacing={1}>
      <label
        htmlFor={id}
        style={{
          color: theme.gray,
          display: "block",
          fontSize: "18px",
          // width: "100%",
          fontWeight: 500,
        }}
      >
        {label}
      </label>

      <select
        // className="inputStyle"
        onChange={(e) => handleClick(e)}
        name="sub_topic_id"
        // defaultValue={defaultValue ? defaultValue : ""}
        style={{
          padding: "20px 10px",
          borderRadius: "7px",
          ...style,
          cursor: "pointer",
        }}
      >
        <option selected disabled>
          Select sub topic
        </option>
        {get_all_sub_topics_by_topic_id_data?.map((e) => (
          <option value={e._id}>{e?.name}</option>
        ))}
        {/* <option value="">Gaming</option>
        <option value="">Sports</option> */}
        {/* {React.Children.toArray(
          dataaaa.data?.map((e, i) => {
            return <option value={e._id}>{e.instituteName}</option>;
          })
        )} */}
      </select>
      <Stack
        sx={{ textAlign: "right", alignItems: "end" }}
        width={1}
        textAlign={"end"}
      >
        <Button
          onClick={() => {
            navigate("/request-sub-topic");
          }}
          sx={{
            cursor: "pointer",
            fontSize: 14,
            textTransform: "none",
            color: primary_color,
            textAlign: "right",
            padding: 0,
          }}
          children={"Request for custom sub topic!"}
        />
      </Stack>
    </Stack>
  );
}

export default SelectSubTopic;
