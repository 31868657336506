export function arraysEqual(arr1, arr2) {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
  
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
  
    for (let i = 0; i < sortedArr1.length; i++) {
      const obj1 = JSON.stringify(sortedArr1[i]);
      const obj2 = JSON.stringify(sortedArr2[i]);
      if (obj1 !== obj2) {
        return false;
      }
    }
  
    return true;
  }
  