import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./carousel_styles.css";

// import required modules
import { Pagination } from "swiper/modules";
import { Stack, Typography } from "@mui/material";
import useTheme from "../../hooks/theme";
import { primary_color } from "../../utils/color";

export default function Carousel({ images_array }) {
  const theme = useTheme();
  return (
    <Swiper pagination={true} modules={[Pagination]}>
      {images_array?.map((e) => {
        return (
          <SwiperSlide
            style={{ flexDirection: "column", backgroundColor: "transparent" }}
          >
            <Stack padding={1} border={`1px solid ${theme?.text_color}`} mx={2}>
              <img
                src={e?.url}
                style={{ width: "100%", height: "40vh", objectFit: "contain" }}
              />

              {e?.caption && (
                <Typography
                  textOverflow={true}
                  sx={{
                    width: "100%",
                    color: theme?.text_color,
                    backgroundColor: theme?.card_bg_color,
                  }}
                >
                  {e?.caption}
                </Typography>
              )}
            </Stack>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
