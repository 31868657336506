import "./style.css";
import * as React from "react";
import { Box, Button, Stack } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import { sideBarData } from "../../utils/routelist";
import { primary_color } from "../../utils/color";
import Header from "../header/Header";
import useTheme from "../../hooks/theme";
import { useSelector } from "react-redux";

export default function Templates_SideBar({
  change_side_menu_handle,
  active_sidebar_index,
}) {
  const theme = useTheme();
  const { userAuth } = useSelector((state) => state.authSlice);

  // console.log(activeIndex);
  return (
    <>
      <Header />
      <div
        style={{
          background: theme.bg_text_divider,
        }}
        className="main_dashoard"
      >
        <Stack
          display={{ md: "none", sm: "none", xs: "none", lg: "flex" }}
          // py={3}
          // px={4}
          my={3}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            // style={{ maxWidth: 50, height: 50, width: "100%" }}
            className="logoImage"
            src={logo}
            alt="logo"
          />
        </Stack>

        {sideBarData.map((e, i) => {
          const { name, linkTo, icon, list_in_sidebar, auth_required } = e;
          if (list_in_sidebar) {
            if (auth_required) {
              return (
                userAuth && (
                  <React.Fragment key={i}>
                    {
                      <NavLink
                        to={`${linkTo}`}
                        onClick={() => change_side_menu_handle(i)}
                        style={{
                          ":hover": {
                            backgroundColor: "#514e4e",
                            textDecoration: "none",
                          },
                          backgroundColor:
                            i === active_sidebar_index
                              ? theme.active_side_bg_color
                              : null,
                          textDecoration: "none",
                        }}
                        className="iconsList"
                      >
                        <div className="contentparent">
                          <Box
                            className="iconsbtn"
                            sx={{
                              color:
                                i === active_sidebar_index
                                  ? theme.active_side_text_color
                                  : theme.drawer_text_color,
                            }}
                          >
                            {icon}
                          </Box>
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color:
                                i === active_sidebar_index
                                  ? theme.active_side_text_color
                                  : theme.drawer_text_color,
                              display: {
                                md: "none",
                                sm: "none",
                                xs: "none",
                                lg: "block",
                              },
                            }}
                          >
                            {name}
                          </Box>
                        </div>
                      </NavLink>
                    }
                  </React.Fragment>
                )
              );
            } else {
              return (
                <React.Fragment key={i}>
                  {
                    <NavLink
                      to={`${linkTo}`}
                      onClick={() => change_side_menu_handle(i)}
                      style={{
                        ":hover": {
                          backgroundColor: "#514e4e",
                          textDecoration: "none",
                        },
                        backgroundColor:
                          i === active_sidebar_index
                            ? theme.active_side_bg_color
                            : null,
                        textDecoration: "none",
                      }}
                      className="iconsList"
                    >
                      <div className="contentparent">
                        <Box
                          className="iconsbtn"
                          sx={{
                            color:
                              i === active_sidebar_index
                                ? theme.active_side_text_color
                                : theme.drawer_text_color,
                          }}
                        >
                          {icon}
                        </Box>
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color:
                              i === active_sidebar_index
                                ? theme.active_side_text_color
                                : theme.drawer_text_color,
                            display: {
                              md: "none",
                              sm: "none",
                              xs: "none",
                              lg: "block",
                            },
                          }}
                        >
                          {name}
                        </Box>
                      </div>
                    </NavLink>
                  }
                </React.Fragment>
              );
            }
          }
          // return (
          // list_in_sidebar && (

          //   )
          // );
        })}
      </div>
    </>
  );
}
