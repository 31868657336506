import { Avatar, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../hooks/theme";
import useUiThemeStyles from "../../hooks/theme_styles";
import { useDispatch, useSelector } from "react-redux";
import {
  get_comments_by_post_id_async,
  reply_by_comment_id_async,
  vote_comment_async,
} from "../../services/newsFeedService";
import { orange } from "@mui/material/colors";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Replies from "../Replies/RepliesComp";
import ReplyInput from "../input/ReplyInput";
import { asyncStatus } from "../../utils/async_status";
import { timeAgo } from "../../utils/common/dateFormater";
import VoteBtn from "../VoteButton/VoteBtn";
import { primary_color } from "../../utils/color";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const ShowComments = ({ post_id }) => {
  const [accordion, setAccordion] = useState(false);
  const theme = useTheme();
  const ui_styles = useUiThemeStyles();
  const {
    get_comments_status,
    get_comments_data,
    get_comments_error,
    vote_comment_status,
    reply_comment_status,
  } = useSelector((state) => state.newsFeed);
  const { userAuth, user_data } = useSelector((state) => state.authSlice);
  const { theme: currentTheme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [loadingId, setLoadingId] = useState("");
  const [accordionId, setAccordionId] = useState("");
  const [commentId, setCommentId] = useState("");
  const [replyText, setReplyText] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState("");
  const [showInputForCommentId, setShowInputForCommentId] = useState("");

  useEffect(() => {
    dispatch(
      get_comments_by_post_id_async({
        user_id: userAuth ? user_data._id : "",
        post_id,
      })
    );
  }, []);
  useEffect(() => {
    if (reply_comment_status === asyncStatus.SUCCEEDED) {
      setShowInputForCommentId("");
    }
  }, [reply_comment_status]);

  return (
    <Stack
      //   container
      // flexWrap={"wrap"}
      sx={{
        // ...style,
        backgroundColor: theme.card_bg_color,
        width: 1,
        my: 2,
        // height: { md: "80%", lg: "80%", sm: "80%", xs: "70%" },
        // overflowY: "scroll",
      }}
      flexDirection={"column"}
      alignItems={"start"}
    >
      {get_comments_status === asyncStatus.LOADING ||
      get_comments_status === asyncStatus.IDLE ? (
        <Stack
          width={1}
          //   height={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress sx={{ color: orange[600] }} />
        </Stack>
      ) : get_comments_data?.length ? (
        get_comments_data?.map((e) => {
          const {
            first_name,
            last_name,
            profile_pic,
            comment_text,
            score,
            repliesCount,
            post_id,
            _id,
            comment_type,
            user_id,
            vote,
            updatedAt,
          } = e;

          return (
            <Stack
              flexDirection={"row"}
              width={1}
              justifyContent={"start"}
              alignItems={"flex-start"}
              // bgcolor={"blue"}
            >
              <Stack flexDirection={"row"} justifyContent={"start"} mr={1}>
                <Avatar
                  alt={first_name + " " + last_name}
                  src={profile_pic}
                  sx={{ width: 30, height: 30 }}
                />
              </Stack>
              <Stack flexDirection={"column"}>
                <Stack
                  flexDirection={"row"}
                  // justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    textAlign={"start"}
                    fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                    fontWeight={"bold"}
                    marginRight={1}
                    color={theme.text_color}
                  >
                    {first_name + " " + last_name}
                  </Typography>
                  <Typography
                    textAlign={"start"}
                    fontSize={{ xs: 8, sm: 10, md: 12, lg: 12, xl: 14 }}
                    fontWeight={"bold"}
                    color={"gray"}
                  >
                    {timeAgo(updatedAt)}
                  </Typography>
                </Stack>

                <Typography
                  textAlign={"start"}
                  color={theme.text_color}
                  fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                  sx={{ fontWeight: "lighter" }}
                >
                  {comment_text}
                </Typography>
                <Stack flexDirection={"row"}>
                  {/* <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      sx={
                        vote === "upvote"
                          ? ui_styles.voted_styles
                          : ui_styles.vote_idle
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "upvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "upvote",
                            })
                          );
                        }
                      }}
                      icon={<ImArrowUp size={15} />}
                    />
                    <Typography
                      variant="p"
                      sx={{ color: theme.text_color, fontSize: 14 }}
                    >
                      {score}
                    </Typography>
                    <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "downvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "downvote",
                            })
                          );
                        }
                      }}
                      sx={
                        vote === "downvote"
                          ? ui_styles.voted_styles
                          : ui_styles.vote_idle
                      }
                      icon={<ImArrowDown size={15} />}
                    />
                    <Typography
                      onClick={() => {
                        if (showInputForCommentId === _id) {
                          setShowInputForCommentId(""); // Close the input
                        } else {
                          setShowInputForCommentId(_id); // Open the input for this comment
                        }
                        setReplyCommentId(_id); // Set the comment ID for replying
                      }}
                      color={theme.text_color}
                      sx={{ cursor: "pointer" }}
                      fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                    >
                      Reply
                    </Typography>
                  </Stack> */}
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      sx={
                        vote === "upvote"
                          ? {
                              color: primary_color,
                              backgroundColor: "transparent",
                              "&.Mui-disabled": {
                                color: primary_color,
                              },
                              // "&:hover": {
                              //   color: "gray",
                              // },
                            }
                          : {
                              color: "gray",
                              backgroundColor: "transparent",
                              "&.Mui-disabled": {
                                color: primary_color,
                              },
                              // "&:hover": {
                              //   color: primary_color,
                              // },
                            }
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "upvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "upvote",
                            })
                          );
                        }
                      }}
                      icon={
                        vote ? <FaHeart size={15} /> : <FaRegHeart size={15} />
                      }
                    />
                    <Typography
                      variant="p"
                      sx={{ color: theme.text_color, fontSize: 14 }}
                    >
                      {score}
                    </Typography>
                    {/* <VoteBtn
                      disabled={
                        vote_comment_status === asyncStatus.LOADING &&
                        loadingId === _id
                      }
                      onClick={() => {
                        setLoadingId(_id);
                        if (vote === "downvote") {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "",
                            })
                          );
                        } else {
                          dispatch(
                            vote_comment_async({
                              post_id,
                              comment_id: _id,
                              comment_type,
                              user_id,
                              vote: "downvote",
                            })
                          );
                        }
                      }}
                      sx={
                        vote === "downvote"
                          ? ui_styles.voted_styles
                          : ui_styles.vote_idle
                      }
                      icon={<ImArrowDown size={15} />}
                    /> */}
                    <Typography
                      onClick={() => {
                        if (showInputForCommentId === _id) {
                          setShowInputForCommentId(""); // Close the input
                        } else {
                          setShowInputForCommentId(_id); // Open the input for this comment
                        }
                        setReplyCommentId(_id); // Set the comment ID for replying
                      }}
                      color={theme.text_color}
                      sx={{ cursor: "pointer" }}
                      fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                    >
                      Reply
                    </Typography>
                  </Stack>
                </Stack>

                <Stack></Stack>
                {repliesCount > 0 && (
                  <Stack
                    // mb={0}
                    flexDirection={"row"}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setAccordionId(_id);
                      setAccordion((prev) => !prev);
                    }}
                  >
                    {!accordion ? (
                      <KeyboardArrowDownIcon
                        color={theme.dark_icon_text_color}
                        size={15}
                      />
                    ) : (
                      <KeyboardArrowRightIcon
                        color={theme.dark_icon_text_color}
                        size={15}
                      />
                    )}
                    <Typography
                      variant="p"
                      sx={{
                        color: theme.text_color,
                        fontSize: 14,
                        textTransform: "none",
                      }}
                    >
                      {repliesCount} {repliesCount > 1 ? "Replies" : "Reply"}
                    </Typography>
                  </Stack>
                )}
                {accordion && accordionId === _id && repliesCount > 0 && (
                  <Replies comment_id={_id} />
                )}
                {showInputForCommentId === _id && replyCommentId === _id && (
                  <Stack flexGrow={1} mt={1}>
                    <ReplyInput
                      loading={
                        reply_comment_status === asyncStatus.LOADING &&
                        commentId === _id
                      }
                      onChange={(e) => {
                        setCommentId(_id);
                        setReplyText(e.target.value);
                      }}
                      value={replyText && commentId === _id ? replyText : ""}
                      onClick={() => {
                        dispatch(
                          reply_by_comment_id_async({
                            post_id: post_id,
                            comment_text: replyText,
                            parent_comment_id: _id,
                          })
                        );
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          );
        })
      ) : (
        <Stack height={"10vh"} justifyContent={"center"} alignItems={"center"}>
          <Typography sx={{ color: theme.text_color }}>No comments!</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ShowComments;
