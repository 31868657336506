import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import { main_routes } from "../../utils/routelist";
import { useDispatch, useSelector } from "react-redux";
import Home from "../../screens/dashboard/dashboardScreens/Home";
import Dashboard from "../../screens/dashboard/Dashboard";
import { check_auth_async } from "../../services/authService";
import { asyncStatus } from "../../utils/async_status";
import { save_tokens_constant } from "../../store/constants/constants";
import { setUserAuth } from "../../store/slice/AuthSlice";
import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import { Public_Routes } from "./PublicRoutes";
import { Private_Routes } from "./PrivateRoutes";
import { connectSocket } from "../../utils/socket_functions";
import { toast } from "react-toastify";
import { exit_session } from "../../store/slice/logoutSlice";
import { Both_Routes } from "./BothRoutes";
import { Otp_Verify_Route } from "./Otp_Verify_Route";
import { Render_Router_Elements } from "./Render_Router_Elements";

const RouterApp = () => {
  const {
    userAuth,
    user_data,
    otpVerified,
    check_auth_error,
    check_auth_status,
    banned,
  } = useSelector((state) => state.authSlice);
  // console.log("user_data", user_data);
  const dispatch = useDispatch();
  useEffect(() => {
    const authTokens = localStorage.getItem(save_tokens_constant)
      ? JSON.parse(localStorage.getItem(save_tokens_constant))
      : null;

    if (check_auth_status === asyncStatus.IDLE) {
      if (authTokens) {
        dispatch(check_auth_async());
      } else {
        dispatch(setUserAuth(false));
      }
    }
  }, []);
  useEffect(() => {
    connectSocket();
  }, []);
  console.log("error", check_auth_error);
  if (
    check_auth_status === asyncStatus.LOADING ||
    check_auth_status === asyncStatus.IDLE
  ) {
    return <LinearProgress />;
  }

  if (check_auth_status === asyncStatus.ERROR) {
    return (
      <Stack>
        <Typography>{check_auth_error.message}</Typography>
      </Stack>
    );
  }
  if (check_auth_status === asyncStatus.SUCCEEDED) {
    if (banned) {
      return (
        <Stack
          sx={{
            width: 1,
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>You are banned</Typography>
          <Typography>Ban reason: {user_data?.ban_reason}</Typography>
          <Typography>
            Ban till: {user_data?.ban_until.split("T")[0]}
          </Typography>
          <Button
            onClick={async () => {
              exit_session();
            }}
            variant="contained"
            sx={{ width: 50, paddingLeft: 1, paddingRight: 1 }}
            children={<Typography sx={{ fontSize: 12 }}>Logout</Typography>}
          />
        </Stack>
      );
    }
    return (
      <>
        <Router>
          <Routes>
            {/* {React.Children.toArray(
              main_routes.map((page) => {
                const { linkTo, element, auth_required, otpScreen } = page;
                // if()
                // let renderScreen;
                // if (!userAuth) {
                //   if (!auth_required) {
                //     renderScreen = element;
                //   }
                //   // renderScreen = element;
                // } else {
                //   if (!otpVerified && otpScreen) {
                //     renderScreen = element;
                //   } else {
                //     console.log("interested");
                //   }
                // }
                return <Route element={element} path={linkTo} />;
              })
            )} */}

            <Route element={<Public_Routes />}>
              {React.Children.toArray(
                main_routes.map((route, ind) => {
                  const { caption, linkTo, element, auth_required, both } =
                    route;
                  if (!auth_required) {
                  }
                  return (
                    !auth_required &&
                    !both && <Route key={ind} element={ <Render_Router_Elements>{element}</Render_Router_Elements> } path={linkTo} />
                  );
                })
              )}
            </Route>

            <Route element={<Private_Routes />}>
              {React.Children.toArray(
                main_routes.map((route, ind) => {
                  const { caption, linkTo, element, auth_required, both } =
                    route;

                  return (
                    auth_required &&
                    !both && <Route key={ind} element={<Render_Router_Elements>{element}</Render_Router_Elements> } path={linkTo} />
                  );
                })
              )}
            </Route>

            <Route element={<Both_Routes />}>
              {main_routes.map((route, ind) => {
                const {
                  caption,
                  linkTo,
                  element,
                  auth_required,
                  both,
                  otpScreen,
                } = route;

                return (
                  both &&
                  !otpScreen && (
                    <Route key={ind} element={<Render_Router_Elements>{element}</Render_Router_Elements> } path={linkTo} />
                  )
                );
              })}
            </Route>

            <Route element={<Otp_Verify_Route />}>
              {main_routes.map((route, ind) => {
                const {
                  caption,
                  linkTo,
                  element,
                  auth_required,
                  both,
                  otpScreen,
                } = route;

                return (
                  otpScreen && (
                    <Route key={ind} element={<Render_Router_Elements>{element}</Render_Router_Elements> } path={linkTo} />
                  )
                );
              })}
            </Route>

            {/* Initial Route (Home Page) */}
            {/* <Route
            path="/"
            element={
              userAuth && otpVerified ? (
                <Navigate to="/dashboard" />
              ) : (
                <Dashboard />
              )
            }
          /> */}
          </Routes>
        </Router>
      </>
    );
  }
};

export default RouterApp;
