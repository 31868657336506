import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandle } from "../config/apiHandle/apiHandle";
import { type_constants } from "../store/constants/constants";

export const get_all_topics_async = createAsyncThunk(
  type_constants.GET_ALL_TOPICS,
  async () => {
    try {
      const response = await apiHandle.get("/user/get-all-topics");
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_all_sub_topics_by_topic_id_async = createAsyncThunk(
  type_constants.GET_ALL_SUB_TOPICS_BY_ID,
  async (topic_id) => {
    try {
      const response = await apiHandle.get(
        `/user/get-all-sub-topics-by-topic-id/${topic_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const create_community_async = createAsyncThunk(
  type_constants.CREATE_COMMUNITY,
  async (data) => {
    try {
      const response = await apiHandle.post("/user/create-community", data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const search_community_async = createAsyncThunk(
  type_constants.SEARCH_COMMUNITY,
  async ({ search_query, userId }) => {
    console.log("-------------->", userId);
    try {
      const response = await apiHandle.get(
        `/search-community?searchQuery=${search_query}&user_id=${userId}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const join_community_async = createAsyncThunk(
  type_constants.JOIN_COMMUNITY,
  async (data) => {
    try {
      const response = await apiHandle.post("/user/join-community", data);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_joined_community_async = createAsyncThunk(
  type_constants.GET_JOINED_COMMUNITY,
  async (data) => {
    try {
      const response = await apiHandle.get(
        "/user/get-joined-communities",
        data
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_community_post_async = createAsyncThunk(
  type_constants.GET_COMMUNITY_POSTS_BY_ID,
  async ({ user_id, community_id }) => {
    try {
      const response = await apiHandle.get(
        `/get-community-by-id?user_id=${user_id}&community_id=${community_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
