import React from "react";
import "./createpost.css";
import { Stack } from "@mui/material";
import useTheme from "../../hooks/theme";
import CommunityButton from "../button/CommunityBtn";
import { AiFillEye } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import CreateCommunityModal from "../Community/CommunityModal";

const CreateCommunityCard = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        backgroundColor: theme.card_bg_color,
        height: { md: 100, lg: 100, sm: 100, lg: 10 },
        padding: "25px",
        textAlign: "start",
        borderRadius: "5px",
      }}
    >
      <Stack
        sx={{
          fontSize: 23,
          marginBottom: "7px",
          color: theme.text_color,
          fontWeight: "700",
          fontFamily: "sans-serif",
        }}
      >
        {" "}
        Welcome to Community
      </Stack>
      <Stack sx={{ fontSize: 13, color: theme.text_color }}>
        Follow Community to explore your interests on Appquaintance.
      </Stack>
      <Stack flexDirection={"row"} alignItems={"center"} gap={2} mt={2}>
        <Stack>
          <CreateCommunityModal />
        </Stack>
      </Stack>
    </div>
  );
};

export default CreateCommunityCard;
