import React, { useEffect, useState } from "react";
import "./main.css";
import useTheme from "../../../hooks/theme";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import profile from "../../../assets/cover_profile.png";
import Btn from "../../../component/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  follow_unfollow_user_async,
  get_profile_async,
} from "../../../services/profileService";
import { asyncStatus } from "../../../utils/async_status";
import { get_user_post_async } from "../../../services/newsFeedService";
import { RxCross1 } from "react-icons/rx";
import Post from "../../../component/post/Post";
import { orange } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { setGetMyPostDataClear } from "../../../store/slice/newsFeedSlice";
const UnknownProfile = () => {
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state.authSlice);
  useEffect(() => {
    dispatch(setGetMyPostDataClear());
    dispatch(get_profile_async(id));
  }, []);
  const {
    user_profile_status,
    user_profile_data,
    user_profile_error,
    follow_unfollow_status,
  } = useSelector((state) => state.userProfile);

  const {
    get_my_post_status,
    get_my_post_error,
    get_my_post_data,
    no_more_my_post,
  } = useSelector((state) => state.newsFeed);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    if (user_profile_status === asyncStatus.SUCCEEDED) {
      dispatch(
        get_user_post_async({
          user_id: id,
          page: pageNumber.toString(),
          postPerPage: "5",
        })
      );
      console.log("running");
    }
  }, [user_profile_status]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const paginationFunc = () => {
    setLoading(true);
    // const { scrollHeight, scrollTop } = document.documentElement;
    // const { innerHeight } = window
    // console.log(scrollHeight, innerHeight, scrollTop);
    // if (scrollTop + innerHeight === scrollHeight) {
    dispatch(
      get_user_post_async({
        user_id: id,
        page: (pageNumber + 1).toString(),
        postPerPage: "5",
      })
    );
    setPageNumber(pageNumber + 1);
    console.log("naya page", user_profile_data);
  };
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      if (get_my_post_status === asyncStatus.SUCCEEDED) {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional: for smooth scrolling
        });
        setLoaded(true);
      }
    }
  }, [get_my_post_status]);

  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: theme.bg_color, color: theme.text_color }}
    >
      <Stack padding={10}>
        {user_profile_status === asyncStatus.IDLE ||
        user_profile_status == asyncStatus.LOADING ? (
          <Stack
            height={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Stack>
        ) : user_profile_status === asyncStatus.ERROR ? (
          <Stack
            height={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>Something went wrong!</Typography>
          </Stack>
        ) : user_profile_status === asyncStatus.SUCCEEDED ? (
          <Stack
            width={1}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Avatar
                //   alt={first_name + " " + last_name}
                src={user_profile_data?.profile_pic}
                sx={{
                  width: { xs: 80, sm: 100, md: 120, lg: 120, xl: 120 },
                  height: { xs: 80, sm: 100, md: 120, lg: 120, xl: 120 },
                }}
              />
              <Stack flexDirection={"column"} alignItems={"start"} paddingX={5}>
                <Typography
                  fontSize={22}
                  fontWeight={"bold"}
                  color={theme.text_color}
                >
                  {user_profile_data?.first_name +
                    " " +
                    user_profile_data?.last_name}
                </Typography>
                <Stack direction={"row"} justifyContent={"start"} gap={5}>
                  <Typography
                    fontSize={14}
                    fontWeight={"bold"}
                    color={theme.text_color}
                  >
                    {user_profile_data?.posts}{" "}
                    {user_profile_data?.posts <= 1 ? "post" : "posts"}
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"bold"}
                    color={theme.text_color}
                  >
                    {user_profile_data?.followers}{" "}
                    {user_profile_data?.followers <= 1
                      ? "follower"
                      : "followers"}
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"bold"}
                    color={theme.text_color}
                  >
                    {user_profile_data?.following}{" "}
                    {user_profile_data?.following <= 1
                      ? "following"
                      : "followings"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Btn
              label={user_profile_data?.followed ? "Unfollow" : "Follow"}
              onClick={() => {
                dispatch(follow_unfollow_user_async(id));
              }}
              loading={follow_unfollow_status === asyncStatus.LOADING}
              sx={{
                fontSize: 10,
                color: "white",
                backgroundColor: "#FE9316",
                ":hover": {
                  backgroundColor: "#c66a00",
                },
                alignItems: "center",
                justifyContent: "center",
                padding: "8px 8px",
              }}
            />
          </Stack>
        ) : null}
        <Stack alignItems={"start"} mt={5}>
          <Typography
            fontSize={{ xl: 16, lg: 14, md: 14, sm: 12, xs: 12 }}
            sx={{
              borderBottom: `3px solid ${theme.icon_color}`,
              color: theme.text_color,
            }}
          >
            Posts
          </Typography>

          <Stack width={1}>
            {get_my_post_status === asyncStatus.LOADING && !loading ? (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={300}
              >
                <CircularProgress sx={{ color: orange[600] }} />
              </Stack>
            ) : !get_my_post_data?.length ? (
              <Stack
                height={"60vh"}
                width={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography>No Post found!</Typography>
              </Stack>
            ) : (
              get_my_post_data?.map((e) => {
                return (
                  <Post
                    data={e}
                    // handleOpenComment={handleOpen}
                    // sideicon={<RxCross1 color={theme.text_color} />}
                  />
                );
              })
            )}
            {loading && get_my_post_status === asyncStatus.LOADING && (
              <Stack
                p={1}
                justifyContent={"center"}
                backgroundColor={theme.bg_color}
                alignItems={"center"}
              >
                <CircularProgress />
              </Stack>
            )}
            {!no_more_my_post &&
            get_my_post_status !== asyncStatus.LOADING &&
            get_my_post_data?.length ? (
              <Button onClick={paginationFunc}>Load more</Button>
            ) : null}
            {/* {!no_more_my_post &&
              get_my_post_status !== asyncStatus.LOADING && (
                <Button onClick={paginationFunc}>Load more</Button>
              ) &&
              get_my_post_data?.length > 0} */}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default UnknownProfile;
