import React from "react";
import logo from "../../assets/AQ.png";
import { Avatar, Button, Stack } from "@mui/material";
import { FaUsers } from "react-icons/fa";
import { BsFillBellFill } from "react-icons/bs";
import "./header.css";
import SearchInput from "../input/SearchInput";
import CommunityButton from "../button/CommunityBtn";
import { primary_color } from "../../utils/color";
import crown from "../../assets/crown.png";
import SearchIcon from "@mui/icons-material/Search";
import LayoutSideBar from "../theme/LayoutSidebar";
import useTheme from "../../hooks/theme";
import NotificationPopOver from "../notification/NotificationPopOver";
import PremiumPkgCard from "../premiumpkg/PremiumPkgCard";
import { useDispatch, useSelector } from "react-redux";
import { setCommunitySearch } from "../../store/slice/communitySlice";
import { search_community_async } from "../../services/communityService";
import { useNavigate } from "react-router-dom";
import { search_post_async } from "../../services/newsFeedService";

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { search_query } = useSelector((state) => state.community);
  const { userAuth, user_data, search_filter } = useSelector(
    (state) => state.authSlice
  );
  const navigation = useNavigate();
  return (
    <div
      className="topbar_body"
      style={{ backgroundColor: theme.card_bg_color }}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          justifyContent: {
            md: "none",
            lg: "space-between",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          ml={{ sm: 5, xs: 1 }}
        >
          <Stack
            display={{ md: "block", lg: "block", sm: "none", xs: "none" }}
            mt={2}
          >
            <SearchInput
              onChange={(e) => dispatch(setCommunitySearch(e.target.value))}
              iconClick={() => {
                if (window.location.pathname.includes("/Search")) {
                  if (search_filter === "post") {
                    dispatch(
                      search_post_async({
                        user_id: userAuth ? user_data?._id : "",
                        query: search_query,
                        page: "1",
                        postPerPage: "5",
                      })
                    );
                    console.log("post search");
                  } else if (search_filter === "community") {
                    console.log("aaaaaaa", user_data);
                    dispatch(
                      search_community_async({
                        user_id: userAuth ? user_data?._id : "",
                        search_query,
                      })
                    );
                  }
                } else {
                  navigation("/Search");
                }
              }}
            />
          </Stack>
          <Stack
            display={{ md: "block", lg: "block", sm: "none", xs: "none" }}
            mt={2}
            ml={2}
          >
            <CommunityButton
              borderColor={theme.icon_color}
              color={theme.dark_icon_text_color}
              sx={{
                borderColor: primary_color,
                // color: theme.dark_icon_text_color,
                textTransform: "capitalize",
              }}
              startIcon={
                <FaUsers size={22} color={theme.dark_icon_text_color} />
              }
              title={"Community"}
            />
          </Stack>

          {/* ==============
          ===================================== */}

          {/* =================================================== */}
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          // ml={{ sm: 10, xs: 10 }}
        >
          {userAuth && (
            <>
              <Stack
                display={{ md: "block", lg: "block", sm: "none", xs: "none" }}
                mt={2}
                ml={2}
              >
                <PremiumPkgCard />
              </Stack>
              <Stack
                display={{ md: "block", lg: "block", sm: "none", xs: "none" }}
                mt={2}
                ml={2}
              >
                {/* <BsFillBellFill color={theme.text_color} size={30} /> */}
                <NotificationPopOver />
              </Stack>
              <Stack
                display={{ md: "block", lg: "block", sm: "none", xs: "none" }}
                mt={2}
                ml={2}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    userAuth
                      ? user_data?.profile_pic
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                />
              </Stack>
              <Stack
                display={{ md: "block", lg: "block", sm: "none", xs: "none" }}
                mt={2}
                ml={1}
                color={theme.text_color}
              >
                {userAuth ? user_data?.first_name : "Username"}
              </Stack>
            </>
          )}

          <Stack
            display={{ md: "flex", lg: "flex", sm: "none", xs: "none" }}
            ml={1}
            mt={1.5}
            flexDirection={"row"}
            alignItems={"center"}
          >
            {!userAuth && (
              <Stack
                onClick={() => navigation("/login")}
                sx={{
                  border: `1px solid ${theme.icon_color}`,
                  p: 1,
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    color: theme.dark_icon_text_color,
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  Login / Signup
                </button>
              </Stack>
            )}
            <LayoutSideBar />
          </Stack>
          {/* ================================== mobile screen ====================================== */}
          <Stack
            sx={{
              flexDirection: { md: "none", lg: "none", sm: "row", xs: "row" },
              justifyContent: {
                md: "none",
                lg: "none",
                sm: "space-between",
                xs: "space-between",
              },
              alignItems: {
                md: "none",
                lg: "none",
                sm: "center",
                xs: "center",
              },
            }}
          >
            <Stack
              mt={2}
              ml={2}
              mr={{ md: 0, lg: 0, sm: 4, xs: 4 }}
              sx={{
                display: { md: "none", lg: "none", sm: "block", xs: "block" },
              }}
            >
              <img
                // style={{ maxWidth: 79, height: 50, width: "100%" }}
                className="headerLogoImg"
                src={logo}
              />
            </Stack>
            <Stack
              sx={{
                flexDirection: { md: "none", lg: "none", sm: "row", xs: "row" },
              }}
            >
              <Stack
                display={{ md: "none", lg: "none", sm: "block", xs: "block" }}
                mt={2}
                ml={2}
              >
                <img src={crown} width={25} />
              </Stack>
              <Stack
                display={{ md: "none", lg: "none", sm: "block", xs: "block" }}
                mt={2}
                ml={2}
              >
                {/* <BsFillBellFill size={25} /> */}
                <NotificationPopOver />
              </Stack>
              <Stack
                display={{ md: "none", lg: "none", sm: "flex", xs: "flex" }}
                flexDirection={"row"}
                alignItems={"center"}
                // mt={2}
                ml={2}
              >
                {!userAuth && (
                  <Stack
                    onClick={() => navigation("/login")}
                    sx={{
                      border: `1px solid ${theme.icon_color}`,
                      p: {
                        xl: "7px",
                        lg: "7px",
                        md: "7px",
                        sm: "7px",
                        xs: "2px",
                      },
                      px: {
                        xl: "7px",
                        lg: "7px",
                        md: "7px",
                        sm: "7px",
                        xs: "0px",
                      },
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <button
                      className="headerLoginBtn"
                      style={{ color: theme.dark_icon_text_color }}
                    >
                      Login / Signup
                    </button>
                  </Stack>
                )}
                <LayoutSideBar />
              </Stack>
            </Stack>
          </Stack>

          {/* ================================== mobile screen ====================================== */}
        </Stack>
      </Stack>
    </div>
  );
};

export default Header;
