import { createAsyncThunk } from "@reduxjs/toolkit";
import { type_constants } from "../store/constants/constants";
import { apiHandle } from "../config/apiHandle/apiHandle";

export const search_post_async = createAsyncThunk(
  type_constants.SEARCH_POST,
  async ({ user_id, query, page, postPerPage }) => {
    try {
      const response = await apiHandle.get(
        `/get-post-by-search?user_id=${user_id}&query=${query}&page=${page}&postPerPage=${postPerPage}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const vote_post_async = createAsyncThunk(
  type_constants.VOTE_POST,
  async (obj) => {
    try {
      const response = await apiHandle.post(`/user/vote-post`, obj);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_comments_by_post_id_async = createAsyncThunk(
  type_constants.GET_COMMENTS,
  async ({ user_id, post_id }) => {
    try {
      const response = await apiHandle.get(
        `/get-comments-by-post-id?user_id=${user_id}&post_id=${post_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const post_comment_async = createAsyncThunk(
  type_constants.POST_COMMENTS,
  async (obj) => {
    try {
      const response = await apiHandle.post(`/user/comment-post`, obj);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const vote_comment_async = createAsyncThunk(
  type_constants.VOTE_COMMENT,
  async (obj) => {
    try {
      const response = await apiHandle.post(`/user/vote-comment`, obj);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_replies_by_comment_id_async = createAsyncThunk(
  type_constants.GET_REPLIES_BY_COMMENT_ID,
  async ({ user_id, comment_id }) => {
    try {
      const response = await apiHandle.get(
        `/get-replies-by-parent-comment-id/?user_id=${user_id}&comment_id=${comment_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const reply_by_comment_id_async = createAsyncThunk(
  type_constants.REPLY_COMMENT,
  async (obj) => {
    try {
      const response = await apiHandle.post(`/user/reply-on-post-comment`, obj);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const create_post_async = createAsyncThunk(
  type_constants.CREATE_POST,
  async (obj) => {
    try {
      const response = await apiHandle.post(`/user/create-post`, obj);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const edit_post_async = createAsyncThunk(
  type_constants.EDITED_POST,
  async ({ id, post_obj }) => {
    try {
      const response = await apiHandle.post(`/user/edit-post/${id}`, post_obj);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const bookmark_post_async = createAsyncThunk(
  type_constants.BOOKMARK_POST,
  async (post_id) => {
    try {
      const response = await apiHandle.post(`/user/bookmark/${post_id}`);
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_bookmarks_async = createAsyncThunk(
  type_constants.GET_BOOKMARKS,
  async ({ user_id, page, postPerPage }) => {
    try {
      const response = await apiHandle.get(
        `/user/get-bookmarks?user_id=${user_id}&page=${page}&postPerPage=${postPerPage}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_post_id_async = createAsyncThunk(
  type_constants.GET_POST_BY_ID,
  async ({ user_id, post_id }) => {
    try {
      const response = await apiHandle.get(
        `/get-post-by-id?user_id=${user_id}&post_id=${post_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const get_user_post_async = createAsyncThunk(
  type_constants.GET_USER_POST,
  async ({ user_id, page, postPerPage }) => {
    try {
      const response = await apiHandle.get(
        `/user/get-my-post?page=${page}&postPerPage=${postPerPage}&user_id=${user_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);

export const delete_post_async = createAsyncThunk(
  type_constants.DELETE_POST,
  async (post_id) => {
    try {
      const response = await apiHandle.delete(
        `/user/delete-post-by-id/${post_id}`
      );
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log({ error });
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);
