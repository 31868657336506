import React, { useEffect, useState } from "react";
import "./main.css";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Post from "../../../component/post/Post";
import { BsFillBookmarkFill } from "react-icons/bs";
import { primary_color } from "../../../utils/color";
import useTheme from "../../../hooks/theme";
import { useDispatch, useSelector } from "react-redux";
import CommunityCard from "../../../component/Community/CommunityCard";
import { asyncStatus } from "../../../utils/async_status";
import { search_community_async } from "../../../services/communityService";
import { setSearchFilter } from "../../../store/slice/AuthSlice";
import { orange } from "@mui/material/colors";
import { RxCross1 } from "react-icons/rx";
import {
  get_comments_by_post_id_async,
  search_post_async,
} from "../../../services/newsFeedService";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Stack textAlign={"left"} width={1}>
          {children}
        </Stack>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    // id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Search = () => {
  const theme = useTheme();
  const {
    search_community_status,
    search_community_error,
    search_community_data,
    search_query,
  } = useSelector((state) => state.community);
  const { userAuth, user_data } = useSelector((state) => state.authSlice);
  const { search_post_status, search_post_data } = useSelector(
    (state) => state.newsFeed
  );
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      dispatch(setSearchFilter("post"));
    } else if (newValue === 1) {
      dispatch(setSearchFilter("community"));
    }
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = (post_id) => {
    setOpen(true);
    dispatch(
      get_comments_by_post_id_async({
        user_id: userAuth ? user_data._id : "",
        post_id,
      })
    );
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (value === 0) {
      dispatch(setSearchFilter("post"));
      dispatch(
        search_post_async({
          user_id: userAuth ? user_data._id : "",
          query: search_query,
          page: "1",
          postPerPage: "5",
        })
      );
      console.log("post search");
    } else if (value === 1) {
      dispatch(setSearchFilter("community"));
      dispatch(
        search_community_async({
          search_query,
          userId: userAuth ? user_data?._id : "",
        })
      );
    }
  }, [, value]);
  console.log("status", search_community_status);
  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: theme.bg_color }}
    >
      <Container sx={{ paddingTop: 10 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Posts" {...a11yProps(0)} />
            <Tab label="Communities" {...a11yProps(1)} />
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {search_post_status === asyncStatus.LOADING ? (
            <Stack justifyContent={"center"} alignItems={"center"} height={300}>
              <CircularProgress sx={{ color: orange[600] }} />
            </Stack>
          ) : (
            search_post_data?.map((e) => {
              return (
                <Post
                  data={e}
                  // handleOpenComment={handleOpen}
                  // sideicon={<RxCross1 color={theme.text_color} />}
                />
              );
            })
          )}
        </CustomTabPanel>
        <CustomTabPanel sx={{ textAlign: "left" }} value={value} index={1}>
          {search_community_status === asyncStatus.LOADING ||
          search_community_status === asyncStatus.IDLE ? (
            <Stack
              display={"flex"}
              my={10}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress sx={{ color: "#FE9316" }} />
            </Stack>
          ) : search_community_status === asyncStatus.ERROR ? (
            <Stack
              display={"flex"}
              my={10}
              justifyContent={"start"}
              // alignItems={"center"}
            >
              <Typography fontSize={14} color={"red"}>
                {search_community_error}
              </Typography>
            </Stack>
          ) : (
            search_community_data?.map((e) => {
              const {
                community_image_url,
                followers,
                title,
                description,
                _id,
                joined,
              } = e;
              return (
                <CommunityCard
                  title={title}
                  id={_id}
                  description={description}
                  image_url={community_image_url}
                  followers={followers}
                  joined={joined}
                />
              );
            })
          )}
        </CustomTabPanel>
      </Container>
    </div>
  );
};

export default Search;
