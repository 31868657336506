import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { ThemeProvider } from "@mui/material";
import RouterApp from "./config/Routers/AppRouter";
import "./styles_&_themes/styles_&_themes/global_theme/global_theme.css";
import material_ui_theme from "./styles_&_themes/styles_&_themes/material_ui_theme/material_ui_theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {

  return (
    <Provider store={store}>
      <ThemeProvider theme={material_ui_theme}>
        <div>
          <dialog
            // open
            id="dialog"
            style={{ border: "none" }}
          ></dialog>
        </div>
        <RouterApp />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
