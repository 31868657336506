import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../hooks/theme";
import CloseIcon from "@mui/icons-material/Close";
import { RiFontSize } from "react-icons/ri";
import { ImImages } from "react-icons/im";
import Btn from "../button/Button";
import useUiThemeStyles from "../../hooks/theme_styles";
import ReactQuill from "react-quill";
import axios from "axios";
import { baseURL } from "../../config/apiHandle/apiHandle";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CustomSelect from "../customSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { get_joined_community_async } from "../../services/communityService";
import { asyncStatus } from "../../utils/async_status";
import {
  create_post_async,
  edit_post_async,
} from "../../services/newsFeedService";
import "./createPostModal.css";
import {
  setCreatePostStatusIdle,
  setEditPostData,
} from "../../store/slice/newsFeedSlice";
import { primary_color } from "../../utils/color";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const generateOtp = () => {
  let otp = "";
  for (let i = 0; i < 8; i++) {
    const ranVal = Math.round(Math.random() * 9);
    otp = otp + ranVal;
  }
  return otp;
};

const CreatePostModal = ({ handleOpen, handleClose, open }) => {
  const [accordion, setAccordion] = useState(false);
  const [isFormatter, setIsFormatter] = useState(false);

  const theme = useTheme();
  const ui_styles = useUiThemeStyles();
  const toolbarStyles = `
  .ql-stroke {
      stroke: ${theme.text_color} !important; /* Change the text color of the toolbar */
      /* Other styles if needed */
    }
    .ql-snow .ql-picker-label::before {
        color: ${theme.text_color} ; 
    }
   
  `;

  const [postImage, setPostImage] = useState([]);
  const [text_html_code_block, setText_html_code_block] = useState("");
  const [privacy, setPrivacy] = useState("anyone");
  const [community, setCommunity] = useState("");
  const [title, setTitle] = useState("");
  const [selectedPic, setSelectedPic] = useState(
    postImage?.length ? postImage[0] : {}
  );
  const [currentInd, setCurrentInd] = useState(0);
  console.log("postImage", postImage);
  const upload_image = async (files) => {
    try {
      let uploadedUrlArray = [];

      await Promise.all(
        Array.from(files).map(async (file, index) => {
          const formData = new FormData();
          formData.append("upload_file", file);

          try {
            const response = await axios.post(
              `${baseURL}/upload-image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            uploadedUrlArray.push({
              caption: "",
              url: response.data.url,
              id: generateOtp(),
            });
          } catch (err) {
            console.error(err);
          }
        })
      );

      setPostImage((prevPostImages) => [
        ...prevPostImages,
        ...uploadedUrlArray,
      ]);
      setSelectedPic(postImage.length ? postImage[0] : uploadedUrlArray[0]);
    } catch (error) {
      console.error(error);
    }
  };
  const removeImage = (obj) => {
    let a = postImage.filter((x) => x.id !== obj.id);
    setPostImage([...a]);
  };
  const editImageObj = (event) => {
    let a = postImage.find((x) => x.id === selectedPic.id);
    console.log("mil gya", a);
    a.caption = event.target.value;
    const data = postImage?.with(currentInd, a);
    setPostImage(data);
  };

  console.log("selectedPic", selectedPic);
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      dispatch(get_joined_community_async());
    }
  }, [, open]);

  const {
    get_joined_community_status,
    get_joined_community_error,
    get_joined_community_data,
  } = useSelector((state) => state.community);
  const {
    create_post_status,
    create_post_data,
    create_post_error,
    edit_post_status,
    edit_post_error,
    edit_post_data,
  } = useSelector((state) => state.newsFeed);
  const { user_data, userAuth } = useSelector((state) => state.authSlice);

  // setting for edit post data

  useEffect(() => {
    if (edit_post_data) {
      const {
        title,
        community_id,
        text_html_code_block,
        image_url_array,
        post_privacy,
      } = edit_post_data;

      setPostImage(image_url_array);
      setText_html_code_block(text_html_code_block);
      setPrivacy(post_privacy);
      setCommunity(community_id);
      setTitle(title);
    } else {
      setPostImage([]);
      setText_html_code_block("");
      setPrivacy("anyone");
      setCommunity("");
      setTitle("");
    }
  }, [edit_post_data]);

  const create_post = () => {
    const post_data_obj = {
      text_html_code_block,
      post_privacy: privacy,
      community_id: community,
      title,
      image_url_array: postImage.length ? postImage : [],
    };
    if (edit_post_data) {
      dispatch(
        edit_post_async({
          post_obj: post_data_obj,
          id: edit_post_data._id,
        })
      );
    } else {
      dispatch(create_post_async(post_data_obj));
    }
  };
  useEffect(() => {
    if (create_post_status === asyncStatus.SUCCEEDED) {
      close_modal_handle();
      dispatch(setCreatePostStatusIdle());
      setPrivacy("anyone");
      setCommunity("");
      setPostImage([]);
      setText_html_code_block("");
    }
  }, [create_post_status]);

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    },
  };

  const close_modal_handle = () => {
    handleClose();
    setPostImage([]);
    setText_html_code_block("");
    setPrivacy("anyone");
    setCommunity("");
    setTitle("");
    dispatch(setEditPostData(null));
  };

  return (
    <Modal
      open={open || edit_post_data}
      onClose={close_modal_handle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 1000001,
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.6)",
      }}
    >
      <Stack
        container
        className="nice"
        sx={{
          ...style,
          backgroundColor: theme.card_bg_color,
          width: { xl: 0.4, md: 0.4, lg: 0.4, sm: 0.6, xs: 0.7 },
          height: { md: "80%", lg: "80%", sm: "80%", xs: "70%" },
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
        }}
        flexDirection={"column"}
        alignItems={"start"}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={1}
          alignItems={"center"}
        >
          <Stack flexDirection={"row"}>
            <Avatar
              //   alt={first_name + " " + last_name}
              src={
                userAuth
                  ? user_data?.profile_pic
                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              }
              sx={{
                width: { xs: 25, sm: 35, md: 50, lg: 50, xl: 50 },
                height: { xs: 25, sm: 35, md: 50, lg: 50, xl: 50 },
              }}
            />
            <Box px={2} flexDirection={"column"} justifyContent={"center"}>
              <Typography
                fontWeight={"bold"}
                color={theme.text_color}
                fontSize={{ xs: 12, sm: 14, md: 16, lg: 16, xl: 16 }}
              >
                {user_data?.first_name} {user_data?.last_name}
              </Typography>

              <CustomSelect
                defaultValue={"anyone"}
                value={privacy}
                onChange={(e) => setPrivacy(e.target.value)}
                options={[
                  { value: "anyone", option: "Post to anyone" },
                  { value: "private", option: "Private" },
                  // { value: "friends", option: "Friends" },
                ]}
              />
            </Box>
          </Stack>
          <CloseIcon
            onClick={close_modal_handle}
            // size={{ xs: 10, sm: 15, md: 20, lg: 20, xl: 20 }}
            sx={{
              cursor: "pointer",
              fontSize: { xs: 15, sm: 15, md: 20, lg: 20, xl: 20 },
              color: theme.text_color,
            }}
          />
        </Stack>
        {get_joined_community_status === asyncStatus.IDLE ||
        get_joined_community_status === asyncStatus.LOADING ? (
          <Stack
            justifyContent={"center"}
            width={60}
            height={60}
            alignItems={"center"}
          >
            <CircularProgress size={10} />
          </Stack>
        ) : get_joined_community_status === asyncStatus.ERROR ? (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography style={{ color: "red" }}>
              {get_joined_community_error}
            </Typography>
          </Stack>
        ) : (
          <Stack mt={2}>
            <select
              onChange={(e) => {
                setCommunity(e.target.value);
                console.log(e.target.value);
              }}
              style={{ padding: 10 }}
              defaultValue={""}
              value={community}
            >
              <option value={""} disabled>
                Choose community
              </option>
              {get_joined_community_data.map((e, i) => {
                return (
                  <option value={e?.community_id}>{e?.community_title}</option>
                );
              })}
            </select>
          </Stack>
        )}
        <Stack width={1} marginTop={5} mb={10}>
          <input
            type="text"
            defaultValue={"sfsfsfsdfds"}
            style={{
              backgroundColor: theme.card_bg_color,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
              textIndent: "5px",
              width: "100%",
              fontSize: 16,
              border: `2px solid ${theme.light_gray}`,
              outline: "none",
              marginBottom: "10px",
              color: theme.text_color,
            }}
            placeholder="Enter Post Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <Stack width={1} alignItems={"center"}>
            <Stack
              paddingX={"10px"}
              width={0.6}
              direction={"row"}
              flexWrap={"wrap"}
              // justifyContent={"space-between"}
              gap={2}
              alignItems={"center"}
            >
              {postImage.map((e, i) => {
                return (
                  <Box
                    sx={{ position: "relative", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedPic(e);
                      setCurrentInd(i);
                    }}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        border:
                          currentInd === i
                            ? `2px solid ${primary_color}`
                            : "none",
                      }}
                      className="smallImages"
                      src={e?.url}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Stack>
          {postImage.length ? (
            <Stack width={1} alignItems={"center"} mb={2}>
              <Stack
                sx={{
                  border: `2px solid ${theme?.light_gray}`,
                  padding: "10px",
                }}
              >
                <Box sx={{ position: "relative", spacing: 5 }}>
                  <img
                    style={{
                      height: "60vh",
                      width: "100%",
                      objectFit: "contain",
                      aspectRatio: 1 / 1,
                    }}
                    src={postImage[currentInd]?.url}
                  />
                  <Stack alignItems={"center"} marginTop={1} marginBottom={3}>
                    <input
                      type="text"
                      value={selectedPic.caption}
                      onChange={(event) => editImageObj(event)}
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        textIndent: "5px",
                        width: "100%",
                      }}
                      placeholder="Image caption (optional)"
                    />
                  </Stack>

                  <ClearIcon
                    onClick={() => removeImage(selectedPic)}
                    sx={{
                      fontSize: 20,
                      position: "absolute",
                      zIndex: 101,
                      top: 5,
                      right: 5,
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Stack
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  paddingX={1}
                  m={0}
                  // minHeight={70}
                  gap={2}
                ></Stack>
              </Stack>
            </Stack>
          ) : null}

          <Stack
            width={1}
            // sx={{ height: { md: "60%", lg: "60%", sm: "60%", xs: "50%" } }}
          >
            <style>{toolbarStyles}</style>
            <ReactQuill
              modules={modules}
              style={{
                // height: "auto",
                color: theme.text_color,
              }}
              value={text_html_code_block}
              onChange={(e) => {
                setText_html_code_block(e);
              }}
              theme="snow"
            />
          </Stack>
        </Stack>

        <Stack>
          <Typography style={{ color: "red", fontSize: 14 }}>
            {create_post_error}
          </Typography>
        </Stack>
        <Stack
          position={"sticky"}
          bottom={"-10px"}
          left={0}
          width={0.98}
          height={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          margin={0}
          // padding={1}
        >
          <Box flexDirection={"row"}>
            <IconButton
              component="label"
              sx={{
                backgroundColor: theme.card_bg_color,
                color: theme.text_color,
                padding: 1,
                borderRadius: 2,
                // ml: 1,
                ":hover": {
                  backgroundColor: theme.text_color,
                  color: theme.card_bg_color,
                },
              }}
            >
              <ImImages cursor={"pointer"} size={25} />
              <input
                type="file"
                onChange={(e) => upload_image(e.target.files)}
                multiple
                hidden
              />
            </IconButton>
          </Box>
          <Btn
            sx={{ color: theme.text_color, fontSize: "15px" }}
            onClick={create_post}
            label={edit_post_data ? "Update" : "Post"}
            loading={create_post_status === asyncStatus.LOADING}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CreatePostModal;
