import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

function Btn(props) {
  const {
    label,
    onClick,
    fullWidth,
    sx,
    size,
    color,
    disabled,
    loading,
    type,
  } = props;
  return (
    <>
      <LoadingButton
        disableElevation
        size={size}
        onClick={onClick}
        loading={loading}
        // className="componentbtn"
        fullWidth={fullWidth}
        sx={sx}
        color={color}
        disabled={disabled}
        variant="contained"
        type={type}
      >
        {label}
      </LoadingButton>
    </>
  );
}
export default Btn;
