// import TestRoute from "../screens/TestRoute";
import Login from "../screens/auth/login/login";
import Signup from "../screens/auth/signup/signup";
import Dashboard from "../screens/dashboard/Dashboard";
import {
  AiFillHome,
  AiOutlinePlus,
  AiOutlineMessage,
  AiFillFileAdd,
} from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import Home from "../screens/dashboard/dashboardScreens/Home";
import CreateCommunity from "../screens/dashboard/dashboardScreens/CreateCommunity";
import Profile from "../screens/dashboard/dashboardScreens/Profile";
import Messages from "../screens/dashboard/dashboardScreens/Messages";
import Bookmarks from "../screens/dashboard/dashboardScreens/Bookmarks";

import CreatePost from "../screens/dashboard/dashboardScreens/RequestSubTopicScreen";
import Settings from "../screens/dashboard/dashboardScreens/Settings";
import VerifyOtp from "../screens/auth/verifyOtp/verifyOtp";
import ForgotPassword from "../screens/auth/forgotPassword/forgotPassword";
import ResetPassword from "../screens/auth/resetPassword/resetPassword";
import VerifyOtpPassword from "../screens/auth/verifyOtp/verifyOtpPassword";
import Search from "../screens/dashboard/dashboardScreens/Search";
import CommunityDetails from "../screens/dashboard/dashboardScreens/CommunityDetails";
import ViewPost from "../screens/dashboard/dashboardScreens/ViewPost";
import UnknownProfile from "../screens/dashboard/dashboardScreens/UnknownProfile";
import RequestSubTopicScreen from "../screens/dashboard/dashboardScreens/RequestSubTopicScreen";
export const main_routes = [
  {
    caption: "Dashboard",
    linkTo: "/*",
    element: <Dashboard />,
    auth_required: false,
    both: true,
  },
  {
    caption: "Login",
    linkTo: "/login",
    element: <Login />,
    auth_required: false,
  },
  {
    caption: "Signup",
    linkTo: "/signup",
    element: <Signup />,
    auth_required: false,
  },
  {
    caption: "verifyOtp",
    linkTo: "/verifyOtp",
    element: <VerifyOtp />,
    auth_required: true,
    otpScreen: true,
    both: true,
  },
  {
    caption: "ForgotPassword",
    linkTo: "/forgotPassword",
    element: <ForgotPassword />,
    auth_required: false,
    // otpScreen: true,
  },
  {
    caption: "ResetPassword",
    linkTo: "/resetPassword",
    element: <ResetPassword />,
    auth_required: false,
    // otpScreen: true,
  },
  {
    caption: "VerifyOtpPassword",
    linkTo: "/verifyOtpPassword",
    element: <VerifyOtpPassword />,
    auth_required: false,
    // otpScreen: true,
  },
];

export const sideBarData = [
  {
    name: "Home",
    linkTo: "/",
    icon: <AiFillHome size={20} />,
    element: <Home />,
    both: true,
    list_in_sidebar: true,
  },
  {
    name: "Communities",
    linkTo: "/CreateCommunity",
    icon: <AiOutlinePlus size={20} />,
    element: <CreateCommunity />,
    both: true,
    list_in_sidebar: true,
  },
  {
    name: "Profile",
    linkTo: "/Profile",
    icon: <BiSolidUser size={20} />,
    element: <Profile />,
    both: true,
    list_in_sidebar: true,
    auth_required: true,
  },
  {
    name: "UnknownProfile",
    linkTo: "/UnknownProfile/:id",
    icon: <BiSolidUser size={20} />,
    element: <UnknownProfile />,
    both: true,
    list_in_sidebar: false,
    auth_required: true,
  },
  {
    name: "Messages",
    linkTo: "/Messages",
    icon: <AiOutlineMessage size={20} />,
    element: <Messages />,
    both: true,
    list_in_sidebar: true,
    auth_required: true,
  },
  {
    name: "Bookmarks",
    linkTo: "/Bookmarks",
    icon: <BsFillBookmarkFill size={20} />,
    element: <Bookmarks />,
    both: true,
    list_in_sidebar: true,
    auth_required: true,
  },
  {
    name: "Search",
    linkTo: "/Search",
    icon: <BsFillBookmarkFill size={20} />,
    element: <Search />,
    both: true,
    list_in_sidebar: false,
  },
  {
    name: "CommunityDetails",
    linkTo: "/CommunityDetails/:id",
    icon: <BsFillBookmarkFill size={20} />,
    element: <CommunityDetails />,
    both: true,
    list_in_sidebar: false,
  },
  {
    name: "Post",
    linkTo: "/Post/:id",
    icon: <BsFillBookmarkFill size={20} />,
    element: <ViewPost />,
    both: true,
    list_in_sidebar: false,
  },
  {
    name: "Settings",
    linkTo: "/Settings",
    icon: <IoMdSettings size={20} />,
    element: <Settings />,
    both: true,
    list_in_sidebar: true,
    auth_required: true,
  },
  {
    name: "Request Sub Topic",
    linkTo: "/request-sub-topic",
    icon: <AiFillFileAdd size={20} />,
    element: <RequestSubTopicScreen />,
    auth_required: true,
    both: false,
    list_in_sidebar: false,
  },
];
