import React, { useEffect, useState } from "react";
import { primary_color } from "../../../utils/color";
import { Container, Grid, Stack, Typography } from "@mui/material";
import Six_by_six from "../../../component/grid/Six_by_six";
import login_img from "../../../assets/login_img.png";
import "../auth.css";
import Input from "../../../component/input/Input";
import PasswordInput from "../../../component/input/PasswordInput";
import Btn from "../../../component/button/Button";
// import DividerCom from "../../../component/divider/Divider";
// import { BsFacebook, BsApple, BsGoogle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login_user_post_async } from "../../../services/authService";
import { asyncStatus } from "../../../utils/async_status";
// import { setIdleLoginStatus } from "../../../store/slice/AuthSlice";
import useTheme from "../../../hooks/theme";
import style_css_login from "./style.module.css";

const Login = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const { login_status, login_data, login_error } = useSelector(
    (state) => state.authSlice
  );
  const theme = useTheme();
  const loginHandle = () => {
    dispatch(login_user_post_async(data));
  };

  useEffect(() => {
    if (login_status === asyncStatus.SUCCEEDED) {
      navigation("/");
      // dispatch(setIdleLoginStatus());
    }
  }, [, login_status]);
  const [isChecked, setIsChecked] = useState(false);
  const setIsCheckedhandle = (e) => {
    setIsChecked(e);
  };
  return (
    <Stack
      justifyContent={"center"}
      sx={{
        backgroundColor: primary_color,
        height: { md: "100dvh", lg: "100dvh", sm: "100dvh", xs: "100dvh" },
      }}
    >
      <Container maxWidth={"lg"} sx={{ p: { md: 5, lg: 5, sm: 0, xs: 0 } }}>
        <Six_by_six
          first_style={{}}
          first_section={
            <Stack>
              <Stack className="heading">Welcome to APPQuaintance!</Stack>

              <Stack className="sub_heading">Discover Your Passions and</Stack>
              <Stack className="sub_heading">Connect with the World</Stack>
              <Stack>
                <img width={530} src={login_img} />
              </Stack>
            </Stack>
          }
          second_section={
            <Stack
              sx={{
                backgroundColor: "#FFFFFF",
                p: { md: 8, lg: 8, sm: 5, xs: 5 },
                borderRadius: "10px",
                mt: 0,
              }}
            >
              <Stack className={style_css_login.Logintitle}>Log In</Stack>
              <Stack mt={5}>
                <Grid container spacing={2}>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Input
                      onChange={(e) =>
                        setData({ ...data, email_or_phone: e.target.value })
                      }
                      label="Email address or Phone Number"
                    />
                  </Grid>

                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <PasswordInput
                      setIsCheckedhandle={setIsCheckedhandle}
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      label="Password"
                      checkDetails={"Remember me"}
                      forgot_password="Forgot Password"
                      forgotClick={() => navigation("/forgotPassword")}
                      dontShowCheckbox={true}
                    />
                    {login_status === asyncStatus.ERROR && (
                      <Typography color={"red"}>{login_error}</Typography>
                    )}
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12} mt={5}>
                    <Btn
                      loading={login_status === asyncStatus.LOADING}
                      onClick={() => loginHandle()}
                      label={"Log in"}
                      fullWidth={true}
                      sx={{
                        p: 1,
                        // backgroundColor: primary_color,
                        borderRadius: "8px",
                        mt: 1,
                      }}
                    />
                  </Grid>
                </Grid>

                <Stack
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <p> Dont have an account?</p>{" "}
                  <p
                    onClick={() => navigation("/signup")}
                    style={{
                      color: primary_color,
                      cursor: "pointer",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {" "}
                    Sign Up
                  </p>
                </Stack>
              </Stack>
              {/* <Stack flexDirection={"row"} alignItems={"center"} mt={8}>
                <DividerCom content={"Or continue with"} />
              </Stack> */}
              {/* <Stack
                mt={2}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <BsFacebook size={30} color={primary_color} />
                <BsApple size={30} color={primary_color} />
                <BsGoogle size={30} color={primary_color} />
              </Stack> */}
            </Stack>
          }
        />
      </Container>
    </Stack>
  );
};

export default Login;
