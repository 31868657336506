import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useTheme from "../../hooks/theme";
import Btn from "../button/Button";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import VoteBtn from "../VoteButton/VoteBtn";
import { BiMessageRounded } from "react-icons/bi";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Replies from "../Replies/RepliesComp";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus } from "../../utils/async_status";
import { orange } from "@mui/material/colors";
import useUiThemeStyles from "../../hooks/theme_styles";
import {
  reply_by_comment_id_async,
  vote_comment_async,
} from "../../services/newsFeedService";
import ReplyInput from "../input/ReplyInput";
import {timeAgo} from "../../utils/common/dateFormater";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const CommentsModalComp = ({ handleOpen, handleClose, open }) => {
  const [accordion, setAccordion] = useState(false);
  const theme = useTheme();
  const ui_styles = useUiThemeStyles();
  const {
    get_comments_status,
    get_comments_data,
    get_comments_error,
    vote_comment_status,
    reply_comment_status,
  } = useSelector((state) => state.newsFeed);
  const { theme: currentTheme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [loadingId, setLoadingId] = useState("");
  const [accordionId, setAccordionId] = useState("");
  const [commentId, setCommentId] = useState("");
  const [replyText, setReplyText] = useState("");
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        zIndex: 1000001,
        alignItems: "center",
        // width: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
      }}
    >
      {/* <Box
   
        // className="scroll_content"
      > */}

      <Stack
        container
        // flexWrap={"wrap"}
        sx={{
          ...style,
          backgroundColor: theme.card_bg_color,
          width: { md: 0.9, lg: 0.9, sm: 0.8, xs: 0.8 },
          height: { md: "80%", lg: "80%", sm: "80%", xs: "70%" },
          overflowY: "scroll",
        }}
        flexDirection={"column"}
        alignItems={"start"}
      >
        {get_comments_status === asyncStatus.LOADING ||
        get_comments_status === asyncStatus.IDLE ? (
          <Stack
            width={1}
            height={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress sx={{ color: orange[600] }} />
          </Stack>
        ) : (
          get_comments_data?.map((e) => {
            const {
              first_name,
              last_name,
              profile_pic,
              comment_text,
              score,
              repliesCount,
              post_id,
              _id,
              comment_type,
              user_id,
              vote,
              updatedAt,
            } = e;

            return (
              <Stack
                flexDirection={"row"}
                width={1}
                justifyContent={"start"}
                alignItems={"flex-start"}
                // bgcolor={"blue"}
              >
                <Stack flexDirection={"row"} justifyContent={"start"} mr={1}>
                  <Avatar
                    alt={first_name + " " + last_name}
                    src={profile_pic}
                    sx={{ width: 30, height: 30 }}
                  />
                </Stack>
                <Stack flexDirection={"column"}>
                  <Stack
                    flexDirection={"row"}
                    // justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      textAlign={"start"}
                      fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                      fontWeight={"bold"}
                      marginRight={1}
                      color={theme.text_color}
                    >
                      {first_name + " " + last_name}
                    </Typography>
                    <Typography
                      textAlign={"start"}
                      fontSize={{ xs: 8, sm: 10, md: 12, lg: 12, xl: 14 }}
                      fontWeight={"bold"}
                      color={"gray"}
                    >
                      {timeAgo(updatedAt)}
                    </Typography>
                  </Stack>

                  <Typography
                    textAlign={"start"}
                    color={theme.text_color}
                    fontSize={{ xs: 10, sm: 12, md: 14, lg: 14, xl: 16 }}
                    sx={{ fontWeight: "lighter" }}
                  >
                    {comment_text}
                  </Typography>
                  <Stack flexDirection={"row"}>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                      <VoteBtn
                        disabled={
                          vote_comment_status === asyncStatus.LOADING &&
                          loadingId === _id
                        }
                        sx={
                          vote === "upvote"
                            ? ui_styles.voted_styles
                            : ui_styles.vote_idle
                        }
                        onClick={() => {
                          setLoadingId(_id);
                          if (vote === "upvote") {
                            dispatch(
                              vote_comment_async({
                                post_id,
                                comment_id: _id,
                                comment_type,
                                user_id,
                                vote: "",
                              })
                            );
                          } else {
                            dispatch(
                              vote_comment_async({
                                post_id,
                                comment_id: _id,
                                comment_type,
                                user_id,
                                vote: "upvote",
                              })
                            );
                          }
                        }}
                        icon={<ImArrowUp size={15} />}
                      />
                      <Typography
                        variant="p"
                        sx={{ color: theme.text_color, fontSize: 14 }}
                      >
                        {score}
                      </Typography>
                      <VoteBtn
                        disabled={
                          vote_comment_status === asyncStatus.LOADING &&
                          loadingId === _id
                        }
                        onClick={() => {
                          setLoadingId(_id);
                          if (vote === "downvote") {
                            dispatch(
                              vote_comment_async({
                                post_id,
                                comment_id: _id,
                                comment_type,
                                user_id,
                                vote: "",
                              })
                            );
                          } else {
                            dispatch(
                              vote_comment_async({
                                post_id,
                                comment_id: _id,
                                comment_type,
                                user_id,
                                vote: "downvote",
                              })
                            );
                          }
                        }}
                        sx={
                          vote === "downvote"
                            ? ui_styles.voted_styles
                            : ui_styles.vote_idle
                        }
                        icon={
                          <ImArrowDown
                            // color={theme.icon_color}
                            size={15}
                          />
                        }
                      />
                      {/* <VoteBtn
                        icon={
                          <>
                            <BiMessageRounded
                              color={theme.dark_icon_text_color}
                              size={15}
                            />
                            <Typography
                              variant="p"
                              sx={{
                                color: theme.text_color,
                                fontSize: 14,
                                textTransform: "none",
                              }}
                            >
                              Reply
                            </Typography>
                          </>
                        }
                      /> */}
                    </Stack>
                  </Stack>

                  <Stack></Stack>
                  {repliesCount > 0 && (
                    <Stack
                      // mb={0}
                      flexDirection={"row"}
                      alignItems={"center"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setAccordionId(_id);
                        setAccordion((prev) => !prev);
                      }}
                    >
                      {!accordion ? (
                        <KeyboardArrowDownIcon
                          color={theme.dark_icon_text_color}
                          size={15}
                        />
                      ) : (
                        <KeyboardArrowRightIcon
                          color={theme.dark_icon_text_color}
                          size={15}
                        />
                      )}
                      <Typography
                        variant="p"
                        sx={{
                          color: theme.text_color,
                          fontSize: 14,
                          textTransform: "none",
                        }}
                      >
                        {repliesCount} Replies
                      </Typography> 
                    </Stack>
                  )}
                  {accordion && accordionId === _id && repliesCount > 0 && (
                    <Replies comment_id={_id} />
                  )}
                  <Stack flexGrow={1} mt={1}>
                    <ReplyInput
                      loading={
                        reply_comment_status === asyncStatus.LOADING &&
                        commentId === _id
                      }
                      onChange={(e) => {
                        setCommentId(_id);
                        setReplyText(e.target.value);
                      }}
                      value={replyText && commentId === _id ? replyText : ""}
                      onClick={() => {
                        dispatch(
                          reply_by_comment_id_async({
                            post_id: post_id,
                            comment_text: replyText,
                            parent_comment_id: _id,
                          })
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            );
          })
        )}
      </Stack>
      {/* </Box> */}
    </Modal>
  );
};

export default CommentsModalComp;
