import React, { useEffect, useState } from "react";
import "./main.css";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { BsFillBookmarkFill } from "react-icons/bs";
import { primary_color } from "../../../utils/color";
import useTheme from "../../../hooks/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  get_bookmarks_async,
  get_comments_by_post_id_async,
} from "../../../services/newsFeedService";
import { orange } from "@mui/material/colors";
import { asyncStatus } from "../../../utils/async_status";
import { RxCross1 } from "react-icons/rx";
import Post from "../../../component/post/Post";
import CommentsModalComp from "../../../component/CommentsModal/CommentsModalComp";

const Bookmarks = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const data = {
    first_name: "test",
    last_name: "test",
    profile_pic: "test",
    score: "test",
    text_html_code_block: "<p>test</p>",
    updatedAt: "test",
    vote: "test",
    // image_url: "",
    comments_count: "test",
    _id: "test",
  };
  useEffect(() => {
    if (pageNumber === 1) {
      dispatch(
        get_bookmarks_async({
          user_id: userAuth ? user_data._id : "",
          page: pageNumber.toString(),
          postPerPage: "5",
        })
      );
    }
  }, []);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { userAuth, user_data } = useSelector((state) => state.authSlice);

  const {
    get_bookmarks_status,
    get_bookmarks_data,
    get_bookmarks_error,
    no_more_bookmark_post,
  } = useSelector((state) => state.newsFeed);
  const handleOpen = (post_id) => {
    setOpen(true);
    dispatch(
      get_comments_by_post_id_async({
        user_id: userAuth ? user_data._id : "",
        post_id,
      })
    );
  };
  const handleClose = () => setOpen(false);
  const paginationFunc = () => {
    console.log("status", get_bookmarks_status);

    setLoading(true);
    // const { scrollHeight, scrollTop } = document.documentElement;
    // const { innerHeight } = window
    // console.log(scrollHeight, innerHeight, scrollTop);
    // if (scrollTop + innerHeight === scrollHeight) {
    dispatch(
      get_bookmarks_async({
        user_id: userAuth ? user_data._id : "",
        page: (pageNumber + 1).toString(),
        postPerPage: "5",
      })
    );
    setPageNumber(pageNumber + 1);
    console.log("naya page");
  };
  return (
    <div
      className="screen_position_container"
      style={{
        backgroundColor: theme.bg_color,
        minHeight: "auto",
        height: "100vh",
      }}
    >
      <CommentsModalComp
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
      />
      <Container backgroundColor={theme.bg_color}>
        <Grid container mt={2} spacing={2}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <Stack>
              {get_bookmarks_status === asyncStatus.LOADING && !loading ? (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={300}
                >
                  <CircularProgress sx={{ color: orange[600] }} />
                </Stack>
              ) : !get_bookmarks_data?.length ? (
                <Stack
                  height={"60vh"}
                  width={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>No Bookmarks added!</Typography>
                </Stack>
              ) : (
                get_bookmarks_data?.map((e) => {
                  return (
                    <Post
                      data={{ ...e.post, bookmarked: true }}
                      // handleOpenComment={handleOpen}
                      // sideicon={<RxCross1 color={theme.text_color} />}
                    />
                  );
                })
              )}
              {loading && get_bookmarks_status === asyncStatus.LOADING && (
                <Stack
                  p={1}
                  justifyContent={"center"}
                  backgroundColor={theme.bg_color}
                  alignItems={"center"}
                >
                  <CircularProgress />
                </Stack>
              )}
              {get_bookmarks_data?.length
                ? !no_more_bookmark_post &&
                  get_bookmarks_status !== asyncStatus.LOADING && (
                    <Button onClick={paginationFunc}>Load more</Button>
                  )
                : null}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Bookmarks;
