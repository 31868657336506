import { Button, IconButton } from "@mui/material";
import React from "react";
import { ImArrowUp } from "react-icons/im";
import useTheme from "../../hooks/theme";

const VoteBtn = ({ icon, onClick, disabled, selected, sx }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: 1,
        minWidth: "auto",
        backgroundColor: selected ? theme.icon_color_hover : "transparent",
        p: 0.4,
        ...sx,
      }}
      children={icon}
    />
  );
};

export default VoteBtn;
VoteBtn.defaultProps = {
  icon: <ImArrowUp size={20} />,
  selected: true,
};
