import React from "react";
import "./main.css";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme as muiUseTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Stack } from "@mui/material";

import useTheme from "../../../hooks/theme";
import RequestCustomSubTopicModal from "../../../component/RequestCustomSubTopicModal/RequestCustomSubTopicModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const RequestSubTopicScreen = () => {
  const theme = muiUseTheme();
  const myTheme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: myTheme.bg_color, color: myTheme.text_color }}
    >
      {/* <Box sx={{ bgcolor: "background.paper", width: 500 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Item One" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            Item One
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            Item Three
          </TabPanel>
        </SwipeableViews>
      </Box> */}
      <Container sx={{ paddingTop: 10 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                textTransform: "none",
              }}
              label="Custom sub-topics Requests"
              {...a11yProps(0)}
            />
            <RequestCustomSubTopicModal />
            {/* <Tab label="Communities" {...a11yProps(1)} /> */}
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack alignItems={"start"} gap={2}>
            {new Array(10).fill(" ").map((e, i) => {
              return (
                <Stack
                  sx={{
                    border: `1px solid ${myTheme.light_gray}`,
                    width: 1,
                    borderRadius: 2,
                    padding: {
                      xl: "20px",
                      lg: "20px",
                      md: "18px",
                      sm: "15px",
                      xs: "10px",
                    },
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      // alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack alignItems={"start"}>
                      <Typography
                        sx={{
                          color: myTheme.text_color,
                          fontSize: { xl: 18, lg: 18, md: 18, sm: 15, xs: 14 },
                        }}
                      >
                        New game
                      </Typography>
                      {i % 2 !== 0 && (
                        <Stack alignItems={"start"}>
                          <Typography
                            sx={{
                              color: myTheme.text_color,
                              fontSize: {
                                xl: 15,
                                lg: 15,
                                md: 15,
                                sm: 13,
                                xs: 12,
                              },
                              fontWeight: "bold",
                              textDecorationLine: "underline",
                            }}
                          >
                            Rejection Cause:
                          </Typography>

                          <Typography
                            sx={{
                              color: myTheme.text_color,
                              fontSize: {
                                xl: 14,
                                lg: 14,
                                md: 14,
                                sm: 12,
                                xs: 11,
                              },
                              textAlign: "start",
                            }}
                          >
                            Your request has been rejected
                          </Typography>
                        </Stack>
                      )}

                      <Stack alignItems={"start"}>
                        <Typography
                          sx={{
                            color: myTheme.text_color,
                            fontSize: {
                              xl: 15,
                              lg: 15,
                              md: 15,
                              sm: 13,
                              xs: 12,
                            },
                            fontWeight: "bold",
                            textDecorationLine: "underline",
                          }}
                        >
                          Request
                        </Typography>

                        <Typography
                          sx={{
                            color: myTheme.text_color,
                            fontSize: {
                              xl: 14,
                              lg: 14,
                              md: 14,
                              sm: 12,
                              xs: 11,
                            },
                            textAlign: "start",
                          }}
                        >
                          I want a custom sub-topic
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "auto",
                      }}
                    >
                      {i % 2 === 0 ? (
                        <Typography
                          sx={{
                            fontSize: {
                              xl: 14,
                              lg: 14,
                              md: 14,
                              sm: 12,
                              xs: 11,
                            },
                            color: "green",
                          }}
                        >
                          Accepted!
                        </Typography>
                      ) : (
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: {
                                xl: 14,
                                lg: 14,
                                md: 14,
                                sm: 12,
                                xs: 11,
                              },
                              color: "red",
                            }}
                          >
                            Rejected!
                          </Typography>
                        </Stack>
                      )}
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: myTheme.text_color,
                          fontSize: {
                            xl: 14,
                            lg: 14,
                            md: 14,
                            sm: 12,
                            xs: 11,
                          },
                        }}
                      >
                        $200
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </TabPanel>
        {/* <TabPanel sx={{ textAlign: "left" }} value={value} index={1}>
          <Typography>tab 2</Typography>
        </TabPanel> */}
      </Container>
    </div>
  );
};

export default RequestSubTopicScreen;
