export function timeAgo(utcDate) {
  const date = new Date(utcDate); // Convert UTC string to a Date object
  const now = new Date();

  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "just now";
  }
}

export function chatTimeAgo(utcDate) {
  const date = new Date(utcDate); // Convert UTC string to a Date object
  const now = new Date();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
  function isWithinOneDay(date1, date2) {
    const timeDiff = Math.abs(date2 - date1);
    return timeDiff <= oneDay && date1.getDate() === date2.getDate();
  }

  if (isWithinOneDay(date, now)) {
    // If it's within the same day, return the time
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  } else {
    // For older messages, return date and time
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  }
}
// const getTime = () => {
//   const date = get
// };
