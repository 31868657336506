import React from "react";

const CustomSelect = ({ options, onChange, value, defaultValue }) => {
  return (
    <select
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      style={{ borderWidth: 0, outline: "none" }}
    >
      {options.map((e) => {
        return (
          <option style={{ color: "gray" }} value={e.value}>
            {e.option}
          </option>
        );
      })}
    </select>
  );
};

export default CustomSelect;
