import React from "react";
import { Stack } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import "./input.css"; // Assuming you have your custom styles here
import useTheme from "../../hooks/theme";

function MessageInput(props) {
  const { value, placeholder, id, onChange, className, style, iconClick,onKeyUp } =
    props;
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <input
        type="text"
        id={id}
        placeholder={"Send Message"}
        className={`inputStyle `}
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        style={{
          padding: "10px 10px",
          borderBottomLeftRadius: "7px",
          borderTopLeftRadius: "7px",
          backgroundColor: theme.bg_inp_color,
          border: "none",
          outline: "none",
          width: "100%",
          ...style,
        }}
      />
      <SendIcon
        onClick={iconClick}
        style={{
          backgroundColor: "#FE9316",
          padding: "9px",
          // borderRadius: "7.5px",
          color: "white",
          marginLeft: -3,
          cursor: "pointer",
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
        }}
      />
    </Stack>
  );
}

export default MessageInput;
