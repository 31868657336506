import { createSlice } from "@reduxjs/toolkit";
import ReactGA from 'react-ga4';

import { asyncStatus } from "../../utils/async_status";
import {
  bookmark_post_async,
  create_post_async,
  get_bookmarks_async,
  get_comments_by_post_id_async,
  get_user_post_async,
  get_post_id_async,
  get_replies_by_comment_id_async,
  post_comment_async,
  reply_by_comment_id_async,
  search_post_async,
  vote_comment_async,
  vote_post_async,
  delete_post_async,
  edit_post_async,
} from "../../services/newsFeedService";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/common/display_toast_container";
import { get_community_post_async } from "../../services/communityService";

const initialState = {
  search_post_status: asyncStatus.IDLE,
  search_post_data: null,
  search_post_error: null,
  no_more_post: false,
  vote_status: asyncStatus.IDLE,
  vote_data: null,
  vote_error: null,

  get_comments_status: asyncStatus.IDLE,
  get_comments_data: null,
  get_comments_error: null,

  post_comment_status: asyncStatus.IDLE,
  post_comment_data: null,
  post_comment_error: null,

  vote_comment_status: asyncStatus.IDLE,
  vote_comment_data: null,
  vote_comment_error: null,

  replies_by_comment_status: asyncStatus.IDLE,
  replies_by_comment_data: [],
  replies_by_comment_error: null,

  reply_comment_status: asyncStatus.IDLE,
  reply_comment_data: null,
  reply_comment_error: null,

  create_post_status: asyncStatus.IDLE,
  create_post_data: null,
  create_post_error: null,

  edit_post_status: asyncStatus.IDLE,
  edit_post_error: null,
  edit_post_data: null,

  bookmark_post_status: asyncStatus.IDLE,
  bookmark_post_data: null,
  bookmark_post_error: null,

  get_bookmarks_status: asyncStatus.IDLE,
  get_bookmarks_data: null,
  get_bookmarks_error: null,
  no_more_bookmark_post: false,

  get_community_post_status: asyncStatus.IDLE,
  get_community_post_error: null,
  get_community_post_data: null,

  get_post_by_id_status: asyncStatus.IDLE,
  get_post_by_id_error: null,
  get_post_by_id_data: null,

  get_my_post_status: asyncStatus.IDLE,
  get_my_post_error: null,
  get_my_post_data: null,
  no_more_my_post: false,

  delete_post_status: asyncStatus.IDLE,
  delete_post_data: null,
  delete_post_error: null,
};

const newsFeedSlice = createSlice({
  name: "newsFeed",
  initialState: initialState,
  reducers: {
    setVoteStatus(state, { payload }) {
      state.vote_status = payload;
    },
    setGetMyPostDataClear(state, { payload }) {
      state.get_my_post_data = null;
    },
    setCreatePostStatusIdle(state) {
      state.create_post_status = asyncStatus.IDLE;
      state.create_post_error = null;
      state.create_post_data = null;
    },
    setEditPostData(state, { payload }) {
      state.edit_post_data = payload;
    },
  },
  extraReducers: (builder) => {
    // ======================== >>>>>>>>>> Get ALl posts

    builder.addCase(search_post_async.pending, (state, { payload }) => {
      state.search_post_status = asyncStatus.LOADING;
      state.search_post_error = null;
    });

    builder.addCase(search_post_async.fulfilled, (state, { payload }) => {
      state.search_post_status = asyncStatus.SUCCEEDED;
      const { search_post_data } = state;
      let a = search_post_data || [];
      if (!payload.data.length) {
        state.no_more_post = true;
      } else {
        if (search_post_data === null) {
          state.search_post_data = payload.data;
        } else {
          console.log("i am groot");
          state.search_post_data = [...a, ...payload.data];
        }
      }

      state.search_post_error = null;
    });
    builder.addCase(search_post_async.rejected, (state, actions) => {
      state.search_post_status = asyncStatus.ERROR;

      state.search_post_error = actions.error.message;
    });
    // ======================== >>>>>>>>>> Vote

    builder.addCase(vote_post_async.pending, (state, { payload }) => {
      state.vote_status = asyncStatus.LOADING;
      state.vote_data = null;
      state.vote_error = null;
    });

    builder.addCase(vote_post_async.fulfilled, (state, { payload }) => {


      state.vote_status = asyncStatus.SUCCEEDED;
      state.vote_data = payload.data;
      const {
        search_post_data,
        get_community_post_data,
        get_bookmarks_data,
        get_post_by_id_data,
        get_my_post_data,
      } = state;
      // let data = [];
      let updatedSearchPostData = search_post_data?.map((item) => {
        if (item._id === payload.data.post_id) {
          return {
            ...item,
            vote: payload.data.vote,
            score: payload.data.score,
          };
        }

        return item;
      });


      if (payload.data.vote) {


        ReactGA.event({
          category: 'Like Post',
          action: payload.data.vote,
          label: 'Like Post',
          value: payload.data.post_id,
        });
      }

      state.search_post_data = updatedSearchPostData;
      if (get_community_post_data !== null) {
        let updatedCommunityPostData = get_community_post_data?.posts?.map(
          (item) => {
            if (item._id === payload.data.post_id) {
              return {
                ...item,
                vote: payload.data.vote,
                score: payload.data.score,
              };
            }

            return item;
          }
        );

        state.get_community_post_data.posts = updatedCommunityPostData;
      }
      if (get_bookmarks_data !== null) {
        console.log("not null", get_bookmarks_data);
        let updatedBookmarkPostData = get_bookmarks_data?.map((item) => {
          if (item.post._id === payload.data.post_id) {
            return {
              ...item,
              post: {
                ...item.post,

                vote: payload.data.vote,
                score: payload.data.score,
              },
            };
          }

          return item;
        });
        console.log("updatedBookmarkPostData", updatedBookmarkPostData);
        state.get_bookmarks_data = updatedBookmarkPostData;
      }
      if (get_my_post_data !== null) {
        console.log("not null", get_my_post_data);
        let updatedBookmarkPostData = get_my_post_data?.map((item) => {
          if (item._id === payload.data.post_id) {
            return {
              ...item,

              vote: payload.data.vote,
              score: payload.data.score,
            };
          }

          return item;
        });
        console.log("updatedBookmarkPostData", updatedBookmarkPostData);
        state.get_my_post_data = updatedBookmarkPostData;
      }

      if (get_post_by_id_data !== null) {
        if (payload.data.post_id === get_post_by_id_data._id) {
          state.get_post_by_id_data = {
            ...get_post_by_id_data,
            vote: payload.data.vote,
            score: payload.data.score,
          };
        }
      }

      state.vote_error = null;
    });
    builder.addCase(vote_post_async.rejected, (state, actions) => {
      state.vote_status = asyncStatus.ERROR;
      state.vote_data = null;
      state.vote_error = actions.error.message;
    });

    // ======================== >>>>>>>>>> Get ALl posts

    builder.addCase(
      get_comments_by_post_id_async.pending,
      (state, { payload }) => {
        state.get_comments_status = asyncStatus.LOADING;
        state.get_comments_data = null;
        state.get_comments_error = null;
      }
    );

    builder.addCase(
      get_comments_by_post_id_async.fulfilled,
      (state, { payload }) => {
        state.get_comments_status = asyncStatus.SUCCEEDED;
        state.get_comments_data = payload.data;
        state.get_comments_error = null;
      }
    );
    builder.addCase(
      get_comments_by_post_id_async.rejected,
      (state, actions) => {
        state.get_comments_status = asyncStatus.ERROR;
        state.get_comments_data = null;
        state.get_comments_error = actions.error.message;
      }
    );

    builder.addCase(post_comment_async.pending, (state, { payload }) => {
      state.post_comment_status = asyncStatus.LOADING;
      state.post_comment_data = null;
      state.post_comment_error = null;
    });

    builder.addCase(post_comment_async.fulfilled, (state, { payload }) => {
      state.post_comment_status = asyncStatus.SUCCEEDED;
      state.post_comment_data = payload.data;
      const {
        search_post_data,
        get_community_post_data,
        get_bookmarks_data,
        get_post_by_id_data,
        get_my_post_data,
      } = state;
      // let data = [];
      let updatedSearchPostData = search_post_data?.map((item) => {
        if (item._id === payload.data.post_id) {
          return {
            ...item,
            comments_count: item.comments_count + 1,
          };
        }

        return item;
      });
      state.search_post_data = updatedSearchPostData;

      if (get_community_post_data !== null) {
        let updatedCommunityPostData = get_community_post_data?.posts?.map(
          (item) => {
            if (item._id === payload.data.post_id) {
              return {
                ...item,
                comments_count: item.comments_count + 1,
              };
            }

            return item;
          }
        );
        state.get_community_post_data.posts = updatedCommunityPostData;
      }

      if (get_bookmarks_data !== null) {
        let updatedBookmarksPostData = get_bookmarks_data?.map((item) => {
          if (item.post._id === payload.data.post_id) {
            return {
              ...item,
              post: {
                ...item.post,
                comments_count: item.post.comments_count + 1,
              },
            };
          }

          return item;
        });
        state.get_bookmarks_data = updatedBookmarksPostData;
      }
      if (get_my_post_data !== null) {
        let updatedMyPostData = get_my_post_data?.map((item) => {
          if (item._id === payload.data.post_id) {
            return {
              ...item,
              comments_count: item.comments_count + 1,
            };
          }

          return item;
        });
        state.get_my_post_data = updatedMyPostData;
      }

      if (get_post_by_id_data !== null) {
        if (payload.data.post_id === get_post_by_id_data._id) {
          state.get_post_by_id_data = {
            ...get_post_by_id_data,
            comments_count: get_post_by_id_data.comments_count + 1,
          };
        }
      }
      state.post_comment_error = null;
      success_toast_message("Comment added");
    });
    builder.addCase(post_comment_async.rejected, (state, actions) => {
      state.vote_comment_status = asyncStatus.ERROR;
      state.vote_comment_data = null;
      state.vote_comment_error = actions.error.message;
      error_toast_message(actions.error.message);
    });

    builder.addCase(vote_comment_async.pending, (state, { payload }) => {
      state.vote_comment_status = asyncStatus.LOADING;
      state.vote_comment_data = null;
      state.vote_comment_error = null;




    });

    builder.addCase(vote_comment_async.fulfilled, (state, { payload }) => {
      state.vote_comment_status = asyncStatus.SUCCEEDED;
      state.vote_comment_data = payload.data;
      const { get_comments_data, replies_by_comment_data } = state;
      // let data = [];
      let updatedSearchPostData;
      if (payload.data.comment_type === "comment") {
        updatedSearchPostData = get_comments_data?.map((item) => {
          if (
            item._id === payload.data.comment_id &&
            item.post_id === payload.data.post_id
          ) {
            return {
              ...item,
              vote: payload.data.vote,
              score: payload.data.score,
            };
          }

          return item;
        });


        if (payload.data.vote) {
          ReactGA.event({
            category: `Like Comment ${payload.data.comment_type === 'reply' ? "Reply" : ""}`,
            action: payload.data.vote,
            label: `Like Comment ${payload.data.comment_type === 'reply' ? "Reply" : ""}`,
            value: payload.data._id,
          });
        }

        state.get_comments_data = updatedSearchPostData;
      } else {
        updatedSearchPostData = replies_by_comment_data?.map((item) => {
          if (
            item._id === payload.data.comment_id &&
            item.post_id === payload.data.post_id
          ) {
            return {
              ...item,
              vote: payload.data.vote,
              score: payload.data.score,
            };
          }

          return item;
        });

        state.replies_by_comment_data = updatedSearchPostData;
      }

      state.vote_comment_error = null;
    });
    builder.addCase(vote_comment_async.rejected, (state, actions) => {
      state.vote_comment_status = asyncStatus.ERROR;
      state.vote_comment_data = null;
      state.vote_comment_error = actions.error.message;
    });

    builder.addCase(
      get_replies_by_comment_id_async.pending,
      (state, { payload }) => {
        state.replies_by_comment_status = asyncStatus.LOADING;
        state.replies_by_comment_data = null;
        state.replies_by_comment_error = null;
      }
    );

    builder.addCase(
      get_replies_by_comment_id_async.fulfilled,
      (state, { payload }) => {
        state.replies_by_comment_status = asyncStatus.SUCCEEDED;
        state.replies_by_comment_data = payload.data;
        state.replies_by_comment_error = null;
      }
    );
    builder.addCase(
      get_replies_by_comment_id_async.rejected,
      (state, actions) => {
        state.replies_by_comment_status = asyncStatus.ERROR;
        state.replies_by_comment_data = null;
        state.replies_by_comment_error = actions.error.message;
      }
    );

    builder.addCase(reply_by_comment_id_async.pending, (state, { payload }) => {
      state.reply_comment_status = asyncStatus.LOADING;
      state.reply_comment_data = null;
      state.reply_comment_error = null;
    });

    builder.addCase(
      reply_by_comment_id_async.fulfilled,
      (state, { payload }) => {
        state.reply_comment_status = asyncStatus.SUCCEEDED;
        const { replies_by_comment_data } = state;
        let a = replies_by_comment_data;
        a.push(payload.data);
        state.replies_by_comment_data = a;

        const { get_comments_data } = state;
        let updatedData = get_comments_data?.map((item) => {
          if (
            item._id === payload.data.parent_comment_id &&
            item.post_id === payload.data.post_id
          ) {
            return {
              ...item,
              repliesCount: item.repliesCount + 1,
            };
          }

          return item;
        });


        ReactGA.event({
          category: `Post Comment ${payload.data.comment_type === 'reply' ? "Reply" : ""}`,
          action: payload.data.comment_type,
          label: `Post Comment ${payload.data.comment_type === 'reply' ? "Reply" : ""}`,
          value: payload.data._id,
        });

        state.get_comments_data = updatedData;

        state.reply_comment_data = payload.data;
        state.reply_comment_error = null;
      }
    );
    builder.addCase(reply_by_comment_id_async.rejected, (state, actions) => {
      state.reply_comment_status = asyncStatus.ERROR;
      state.reply_comment_data = null;
      state.reply_comment_error = actions.error.message;
    });

    builder.addCase(create_post_async.pending, (state, { payload }) => {
      state.create_post_status = asyncStatus.LOADING;
      state.create_post_data = null;
      state.create_post_error = null;
    });

    builder.addCase(create_post_async.fulfilled, (state, { payload }) => {
      state.create_post_status = asyncStatus.SUCCEEDED;
      state.create_post_data = payload.data;
      state.create_post_error = null;

      ReactGA.event({
        category: `Post Published`,
        action: 'published',
        label: `Post Published`,
        value: payload.data._id,
      });
      success_toast_message("Post created");
    });
    builder.addCase(create_post_async.rejected, (state, actions) => {
      state.create_post_status = asyncStatus.ERROR;
      state.create_post_data = null;
      state.create_post_error = actions.error.message;
    });

    builder.addCase(edit_post_async.pending, (state, payload) => {
      state.edit_post_status = asyncStatus.LOADING;
      state.edit_post_error = null;
      state.edit_post_data = null;
    });

    builder.addCase(edit_post_async.fulfilled, (state, payload) => {
      state.edit_post_status = asyncStatus.SUCCEEDED;
      state.edit_post_error = null;
    });

    builder.addCase(edit_post_async.rejected, (state, payload) => {
      state.edit_post_error = payload.action;
    });

    builder.addCase(bookmark_post_async.pending, (state, { payload }) => {
      state.bookmark_post_status = asyncStatus.LOADING;
      state.bookmark_post_data = null;
      state.bookmark_post_error = null;

    });

    builder.addCase(bookmark_post_async.fulfilled, (state, { payload }) => {
      state.bookmark_post_status = asyncStatus.SUCCEEDED;

      state.bookmark_post_data = payload.data;

      if (!payload.data.bookmarked && state.get_bookmarks_data !== null) {
        let newData = state.get_bookmarks_data.filter((e) => {
          return e.post._id !== payload.data.post_id;
        });
        state.get_bookmarks_data = newData;
      }

      if (payload.data.bookmarked) {
        ReactGA.event({
          category: `Post Bookmarked`,
          action: 'bookmarked',
          label: `Post Bookmarked`,
          value: payload.data.post_id
        });
      }


      const {
        search_post_data,
        get_community_post_data,
        get_post_by_id_data,
        get_my_post_data,
      } = state;
      // let data = [];
      let updatedSearchPostData = search_post_data?.map((item) => {
        if (item._id === payload.data.post_id) {
          return {
            ...item,
            bookmarked: payload.data.bookmarked,
          };
        }

        return item;
      });
      state.search_post_data = updatedSearchPostData;
      if (state.get_community_post_data !== null) {
        let updatedCommunityPostData = get_community_post_data?.posts?.map(
          (item) => {
            if (item._id === payload.data.post_id) {
              return {
                ...item,
                bookmarked: payload.data.bookmarked,
              };
            }

            return item;
          }
        );
        state.get_community_post_data.posts = updatedCommunityPostData;
      }
      if (state.get_my_post_data !== null) {
        let updatedMyPostData = get_my_post_data?.map((item) => {
          if (item._id === payload.data.post_id) {
            return {
              ...item,
              bookmarked: payload.data.bookmarked,
            };
          }

          return item;
        });
        state.get_my_post_data = updatedMyPostData;
      }
      if (get_post_by_id_data !== null) {
        if (payload.data.post_id === get_post_by_id_data._id) {
          state.get_post_by_id_data = {
            ...get_post_by_id_data,
            bookmarked: payload.data.bookmarked,
          };
        }
      }
      state.bookmark_post_error = null;
      success_toast_message(
        payload.data.bookmarked
          ? "Post bookmarked"
          : "Post removed from bookmarks!"
      );
    });
    builder.addCase(bookmark_post_async.rejected, (state, actions) => {
      state.bookmark_post_status = asyncStatus.ERROR;
      state.bookmark_post_data = null;
      state.bookmark_post_error = actions.error.message;
    });
    builder.addCase(get_bookmarks_async.pending, (state, { payload }) => {
      state.get_bookmarks_status = asyncStatus.LOADING;
      state.get_bookmarks_error = null;
    });

    builder.addCase(get_bookmarks_async.fulfilled, (state, { payload }) => {
      state.get_bookmarks_status = asyncStatus.SUCCEEDED;
      if (!payload.data.length) {
        state.no_more_bookmark_post = true;
      } else {
        if (state.get_bookmarks_data === null) {
          state.get_bookmarks_data = payload.data;
        } else {
          console.log("i am groot");
          state.get_bookmarks_data = [
            ...state.get_bookmarks_data,
            ...payload.data,
          ];
        }
      }
      state.get_bookmarks_error = null;
      // success_toast_message("Post bookmarked");
    });
    builder.addCase(get_bookmarks_async.rejected, (state, actions) => {
      state.get_bookmarks_status = asyncStatus.ERROR;
      state.get_bookmarks_error = actions.error.message;
    });

    builder.addCase(get_community_post_async.pending, (state, { payload }) => {
      state.get_community_post_status = asyncStatus.LOADING;
      state.get_community_post_error = null;
      state.get_community_post_data = null;
    });

    builder.addCase(
      get_community_post_async.fulfilled,
      (state, { payload }) => {
        state.get_community_post_status = asyncStatus.SUCCEEDED;
        state.get_community_post_data = payload.data;
        state.get_community_post_error = null;
      }
    );
    builder.addCase(get_community_post_async.rejected, (state, actions) => {
      state.get_community_post_status = asyncStatus.ERROR;
      state.get_community_post_data = null;
      state.get_community_post_error = actions.error.message;
    });

    builder.addCase(get_post_id_async.pending, (state, { payload }) => {
      state.get_post_by_id_status = asyncStatus.LOADING;
      state.get_post_by_id_error = null;
      state.get_post_by_id_data = null;
    });

    builder.addCase(get_post_id_async.fulfilled, (state, { payload }) => {
      state.get_post_by_id_status = asyncStatus.SUCCEEDED;
      state.get_post_by_id_data = payload.data;
      state.get_post_by_id_error = null;
    });
    builder.addCase(get_post_id_async.rejected, (state, actions) => {
      state.get_post_by_id_status = asyncStatus.ERROR;
      state.get_post_by_id_data = null;
      state.get_post_by_id_error = actions.error.message;
    });

    builder.addCase(get_user_post_async.pending, (state, { payload }) => {
      state.get_my_post_status = asyncStatus.LOADING;
      state.get_my_post_error = null;
    });

    builder.addCase(get_user_post_async.fulfilled, (state, { payload }) => {
      state.get_my_post_status = asyncStatus.SUCCEEDED;
      if (!payload.data.length) {
        state.no_more_my_post = true;
      } else {
        if (state.get_my_post_data === null) {
          state.get_my_post_data = payload.data;
        } else {
          console.log("i am groot");
          state.get_my_post_data = [...state.get_my_post_data, ...payload.data];
        }
      }
      // state.get_my_post_data = payload.data;
      state.get_my_post_error = null;
    });
    builder.addCase(get_user_post_async.rejected, (state, actions) => {
      state.get_my_post_status = asyncStatus.ERROR;
      state.get_my_post_data = null;
      state.get_my_post_error = actions.error.message;
    });

    builder.addCase(delete_post_async.pending, (state, { payload }) => {
      state.delete_post_status = asyncStatus.LOADING;
      state.delete_post_data = null;
      state.delete_post_error = null;
    });

    builder.addCase(delete_post_async.fulfilled, (state, { payload }) => {
      let { get_my_post_data } = state;
      let updatedData = get_my_post_data?.filter(
        (e, i) => e._id !== payload.data._id
      );
      state.get_my_post_data = updatedData;
      state.delete_post_status = asyncStatus.SUCCEEDED;
      state.delete_post_data = payload.data;
      state.delete_post_error = null;
      success_toast_message(payload.message);
    });
    builder.addCase(delete_post_async.rejected, (state, actions) => {
      state.delete_post_status = asyncStatus.ERROR;
      state.delete_post_data = null;
      state.delete_post_error = actions.error.message;
    });
  },
});

export const {
  setVoteStatus,
  setGetMyPostDataClear,
  setCreatePostStatusIdle,
  setEditPostData,
} = newsFeedSlice.actions;

export default newsFeedSlice.reducer;
