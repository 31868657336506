import { createSlice } from "@reduxjs/toolkit";

import { asyncStatus } from "../../utils/async_status";

import {
  change_password_async,
  edit_profile_async,
  follow_unfollow_user_async,
  get_profile_async,
} from "../../services/profileService";
import { toast } from "react-toastify";
import { delete_post_async } from "../../services/newsFeedService";

const initialState = {
  user_profile_status: asyncStatus.IDLE,
  user_profile_data: null,
  user_profile_error: null,

  follow_unfollow_status: asyncStatus.IDLE,
  follow_unfollow_data: null,
  follow_unfollow_error: null,

  edit_profile_status: asyncStatus.IDLE,
  edit_profile_error: null,
  edit_profile_data: null,

};

const userProfileSlice = createSlice({
  name: "user_profile",
  initialState: initialState,
  reducers: {
    // setIdleStatus(state, actions) {
    //   state.register_status = asyncStatus.IDLE;
    // },
  },
  extraReducers: (builder) => {
    // ===================== Register Slice ==================== //

    builder.addCase(get_profile_async.pending, (state, { payload }) => {
      state.user_profile_status = asyncStatus.LOADING;
    });

    builder.addCase(get_profile_async.fulfilled, (state, { payload }) => {
      state.user_profile_status = asyncStatus.SUCCEEDED;
      state.user_profile_data = payload.data;
    });
    builder.addCase(get_profile_async.rejected, (state, actions) => {
      state.user_profile_status = asyncStatus.ERROR;
      state.user_profile_error = actions.error.message;
    });

    builder.addCase(
      follow_unfollow_user_async.pending,
      (state, { payload }) => {
        state.follow_unfollow_status = asyncStatus.LOADING;
        state.follow_unfollow_data = null;
        state.follow_unfollow_error = null;
      }
    );

    builder.addCase(
      follow_unfollow_user_async.fulfilled,
      (state, { payload }) => {
        state.follow_unfollow_status = asyncStatus.SUCCEEDED;
        state.follow_unfollow_data = payload.followee_id;
        const { user_profile_data } = state;
        if (user_profile_data !== null) {
          console.log("ids", user_profile_data?._id, payload?.followee_id);
          if (user_profile_data?._id === payload?.followee_id) {
            console.log("sameHey");
            let followCount = 0;
            if (payload?.followed === true) {
              followCount = 1;
            } else {
              followCount = -1;
            }
            state.user_profile_data = {
              ...user_profile_data,
              followed: payload.followed,
              followers: user_profile_data?.followers + followCount,
            };
          }
        }
        state.follow_unfollow_error = null;
      }
    );
    builder.addCase(follow_unfollow_user_async.rejected, (state, actions) => {
      state.follow_unfollow_status = asyncStatus.ERROR;
      state.follow_unfollow_error = actions.error.message;
      state.follow_unfollow_data = null;
    });

    builder.addCase(change_password_async.pending, (state, { payload }) => {
      state.change_password_status = asyncStatus.LOADING;
      state.change_password_error = null;
      state.change_password_data = null;
    });

    builder.addCase(change_password_async.fulfilled, (state, { payload }) => {
      state.change_password_status = asyncStatus.SUCCEEDED;
      state.change_password_data = payload.data;
      state.change_password_error = null;

      toast.success("Password changed successfully!", {
        position: "top-center",
      });
    });
    builder.addCase(change_password_async.rejected, (state, actions) => {
      state.change_password_status = asyncStatus.ERROR;
      state.change_password_data = null;
      state.change_password_error = actions.error.message;
    });

    builder.addCase(edit_profile_async.pending, (state, { payload }) => {
      state.edit_profile_status = asyncStatus.LOADING;
      state.edit_profile_error = null;
      state.edit_profile_data = null;
    });

    builder.addCase(edit_profile_async.fulfilled, (state, { payload }) => {
      state.edit_profile_status = asyncStatus.SUCCEEDED;
      state.edit_profile_data = payload.data;
      state.edit_profile_error = null;

      toast.success("Profile edited successfully!", {
        position: "top-center",
      });
    });
    builder.addCase(edit_profile_async.rejected, (state, actions) => {
      state.edit_profile_status = asyncStatus.ERROR;
      state.edit_profile_data = null;
      state.edit_profile_error = actions.error.message;
    });

 
  },
});

// export const {
//   setIdleStatus,
// } = userAuthSlice.actions;
export default userProfileSlice.reducer;
