import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { IconButton, Stack } from "@mui/material";
import useTheme from "../../hooks/theme";
import { IoShareSocial } from "react-icons/io5";
import ShareIcon from "@mui/icons-material/Share";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  PinterestIcon,
} from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { success_toast_message } from "../../utils/common/display_toast_container";
export default function SharePopOver({ url, icon, title }) {
  const theme = useTheme();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            {...bindTrigger(popupState)}
            // onClick={() => navigation(`/Post/${_id}`)}
          >
            <IconButton
              //   onClick={() => alert("a")}
              children={
                icon || (
                  <ShareIcon
                    sx={{ color: theme.dark_icon_text_color }}
                    size={28}
                  />
                )
              }
            />
          </Stack>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Stack padding={1} sx={{ backgroundColor: theme.card_bg_color }}>
              <Typography color={theme.text_color} fontWeight={"bold"}>
                {title || "Share to"}
              </Typography>
              <Stack
                // width={"40vw"}
                // minWidth={"250px"}
                maxWidth={"300px"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                alignItems={"center"}
                padding={1}
                gap={1}
              >
                <FacebookShareButton url={url}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={url}>
                  <XIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={url}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <PinterestShareButton
                  media="https://res.cloudinary.com/dpsxh6ywz/image/upload/v1707218012/AQ_obqb7r.png"
                  url={url}
                >
                  <PinterestIcon size={32} round />
                </PinterestShareButton>
                <CopyToClipboard
                  onCopy={() => {
                    success_toast_message("Copied to clipboard!");
                  }}
                  text={url}
                >
                  <Stack
                    borderRadius={100}
                    //   padding={"10px"}
                    sx={{
                      cursor: "pointer",
                      // border: `1px solid ${theme.text_color}`,
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 20, color: theme.gray }} />
                  </Stack>
                </CopyToClipboard>
              </Stack>
            </Stack>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
