import React from "react";
import { Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./input.css"; // Assuming you have your custom styles here
import { primary_color } from "../../utils/color";
import useTheme from "../../hooks/theme";
import Btn from "../button/Button";

function ReplyInput(props) {
  const {
    value,
    placeholder,
    id,
    onChange,
    className,
    style,
    onClick,
    loading,
  } = props;
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="stretch" minWidth={"80%"} width={"100%"}>
      <input
        type="text"
        id={id}
        placeholder={"Reply here"}
        className={`commentinputStyle `}
        value={value}
        onChange={onChange}
        style={{
          // padding:10,
          height: "auto",
          borderBottomLeftRadius: "7px",
          borderTopLeftRadius: "7px",
          backgroundColor: "#EDEDED",
          border: "none",
          fontSize: 12,
          textIndent: "5px",
          outline: "none",
          width: "100%",
          ...style,
        }}
      />
      <Btn
        loading={loading}
        onClick={onClick}
        sx={{
          // padding:10,
          // marginBlockStart: 10,
          backgroundColor: "#FE9316",
          ":hover": {
            backgroundColor: "#FE9316",
          },
          height: "auto",
          // padding: 1,
          color: "white",
          cursor: "pointer",
          borderRadius: 0,
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
          fontSize: { xs: 9, sm: 11, md: 11, lg: 12, xl: 12 },
        }}
        label={"Reply"}
      />
      {/* <Stack
        className="Comment_text"
        onClick={onClick}
        style={{
          backgroundColor: "#FE9316",
          padding: "10px 10px",
          color: "white",
          marginLeft: -10,
          cursor: "pointer",
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
        }}
      >
        Comment
      </Stack> */}
    </Stack>
  );
}

export default ReplyInput;
