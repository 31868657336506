import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../hooks/theme";
import coverProfile from "../../../assets/cover_profile.png";
import "./communityDetails.css";
import Btn from "../../../component/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  get_community_post_async,
  join_community_async,
} from "../../../services/communityService";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { get_comments_by_post_id_async } from "../../../services/newsFeedService";
import Post from "../../../component/post/Post";
import CommentsModalComp from "../../../component/CommentsModal/CommentsModalComp";
import { asyncStatus } from "../../../utils/async_status";
import { orange } from "@mui/material/colors";
import SharePopOver from "../../../component/popover/SharePopOver";

import ShareIcon from "@mui/icons-material/Share";
import { primary_color } from "../../../utils/color";
const CommunityDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const is400pxScreen = useMediaQuery("(max-width: 400px)");
  const {
    get_community_post_status,
    get_community_post_error,
    get_community_post_data,
    join_community_status,
  } = useSelector((state) => state.newsFeed);
  const { userAuth, user_data, search_filter } = useSelector(
    (state) => state.authSlice
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      get_community_post_async({
        user_id: userAuth ? user_data._id : "",
        community_id: id,
      })
    );
  }, []);
  const [open, setOpen] = useState(false);

  const handleOpen = (post_id) => {
    setOpen(true);
    dispatch(
      get_comments_by_post_id_async({
        user_id: userAuth ? user_data._id : "",
        post_id,
      })
    );
  };
  const handleClose = () => setOpen(false);
  const [loadingId, setLoadingId] = useState("");
  const navigation = useNavigate();
  console.log(get_community_post_data);
  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: theme.bg_color }}
    >
      <Container>
        <CommentsModalComp
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
        />
        {get_community_post_status === asyncStatus.IDLE ||
        get_community_post_status === asyncStatus.LOADING ? (
          <Stack
            sx={{ height: "50vh" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Stack>
        ) : get_community_post_status === asyncStatus.ERROR ? (
          <Stack
            sx={{ height: "50vh" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography color={primary_color}>
              {get_community_post_error}{" "}
            </Typography>
            {get_community_post_error === "Please join community first" ? (
              <Button
                variant="outlined"
                onClick={() =>
                  dispatch(join_community_async({ community_id: id }))
                }
              >
                Click to Join
              </Button>
            ) : (
              ""
            )}
          </Stack>
        ) : (
          <>
            <Stack
              sx={{
                width: "100%",
                position: "relative",
                alignItems: "center",
                backgroundColor: theme.bg_color,
              }}
            >
              <Stack
                // width={1}
                alignItems={"center"}
                sx={{
                  width: "100%",
                  aspectRatio: 16 / 9,
                  backgroundImage: `url(${get_community_post_data?.community_cover_image_url})`,
                  objectFit: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  filter: "brightness(50%) contrast(120%) grayscale(30%)",
                  height: {
                    xl: "50vh",
                    lg: "50vh",
                    md: "45vh",
                    sm: "35vh",
                    xs: is400pxScreen ? "25vh" : "35vh",
                  },
                }}
              ></Stack>
              <Stack
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  alignItems: "center",
                  bottom: 10,
                }}
                width={"100%"}
              >
                {/* <img
              style={{
                width: "90%",
                objectFit:"contain",
                height: {
                  xl: "35vh",
                  lg: "20vh",
                  md: "15vh",
                  sm: "8vh",
                  xs: "8vh",
                },
                // paddingLeft: "10%",
                // paddingRight: "10%",
              }}
              src={get_community_post_data?.community_cover_image_url}
            /> */}
                <Stack
                  width={1}
                  mt={{ xl: -3, lg: -3, md: -3, sm: -2, xs: 0 }}
                  pl={{ xl: 5, lg: 5, md: 4, sm: 3, xs: 1 }}
                  alignItems={"center"}
                >
                  <Stack
                    width={0.9}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"end"}
                    >
                      <img
                        src={get_community_post_data?.community_image_url}
                        className="profile"
                      />
                      <Stack
                        alignItems={"start"}
                        justifyContent={"end"}
                        ml={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 0.5 }}
                      >
                        <Typography
                          fontSize={{ xl: 16, lg: 16, md: 14, sm: 12, xs: 12 }}
                          fontWeight={"bold"}
                          color={theme.text_color_white}
                        >
                          {get_community_post_data?.title}
                        </Typography>
                        <Typography
                          fontSize={{ xl: 10, lg: 10, md: 10, sm: 8, xs: 6 }}
                          color={theme.text_color_white}
                        >
                          {get_community_post_data?.description}
                        </Typography>
                        <Typography
                          fontSize={{ xl: 14, lg: 14, md: 14, sm: 12, xs: 10 }}
                          color={theme.text_color_white}
                        >
                          {get_community_post_data?.followers}{" "}
                          {get_community_post_data?.followers > 1
                            ? "members"
                            : "member"}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"end"}
                    >
                      {!get_community_post_data?.joined && (
                        <Btn
                          label={
                            get_community_post_data?.joined
                              ? "Joined"
                              : "Join Community"
                          }
                          onClick={() => {
                            setLoadingId(id);
                            dispatch(
                              join_community_async({ community_id: id })
                            );
                          }}
                          loading={
                            join_community_status === asyncStatus.LOADING &&
                            id === loadingId
                          }
                          disabled={get_community_post_data?.joined}
                          sx={{
                            fontSize: { xs: 6, sm: 8, md: 10, lg: 10, xl: 10 },
                            padding: {
                              xl: "10px",
                              lg: "10px",
                              md: "10px",
                              sm: "8px",
                              xs: "6px",
                            },
                            color: "white",
                            backgroundColor: "#FE9316",
                            ":hover": {
                              backgroundColor: "#c66a00",
                            },
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      )}
                      <Stack alignItems={"center"}>
                        <SharePopOver
                          title={"Share invitation url"}
                          icon={<ShareIcon color={"primary"} size={28} />}
                          // navigator={navigation}
                          url={`https://app-quaintance-web.web.app/CommunityDetails/${id}`}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              {get_community_post_status === asyncStatus.IDLE ||
              get_community_post_status === asyncStatus.LOADING ? (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={300}
                >
                  <CircularProgress sx={{ color: orange[600] }} />
                </Stack>
              ) : get_community_post_status === asyncStatus.SUCCEEDED ? (
                get_community_post_data?.posts.length ? (
                  get_community_post_data?.posts?.map((e) => {
                    return (
                      <Post
                        data={e}
                        // handleOpenComment={handleOpen}
                        // sideicon={<RxCross1 color={theme.text_color} />}
                      />
                    );
                  })
                ) : (
                  <Stack
                    height={"40vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography sx={{ color: theme.text_color }}>
                      No posts found in this community!
                    </Typography>
                  </Stack>
                )
              ) : null}
            </Stack>
          </>
        )}
        {/* <Container>
      </Container> */}
      </Container>
    </div>
  );
};

export default CommunityDetails;
