import React from "react";
import { Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./input.css"; // Assuming you have your custom styles here
import { primary_color } from "../../utils/color";
import useTheme from "../../hooks/theme";
import { useDispatch, useSelector } from "react-redux";

function SearchInput(props) {
  const { value, placeholder, id, onChange, className, style, iconClick } =
    props;
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="stretch" spacing={1}>
      <input
        type="text"
        id={id}
        placeholder={"Search"}
        className={`inputStyle `}
        value={value}
        onChange={onChange}
        style={{
          padding: "10px 10px",
          borderBottomLeftRadius: "7px",
          borderTopLeftRadius: "7px",
          backgroundColor: theme.bg_inp_color,
          border: "none",
          outline: "none",
          width: "300px",
          color: theme.text_color,
          ...style,
        }}
      />
      <SearchIcon
        onClick={iconClick}
        style={{
          backgroundColor: "#FE9316",
          padding: "9px",
          // borderRadius: "7.5px",
          color: "white",
          marginLeft: -3,
          cursor: "pointer",
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
        }}
      />
    </Stack>
  );
}

export default SearchInput;
