import React, { useEffect } from 'react'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export const Render_Router_Elements = ({ children }) => {

    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Landing Page" });
        // ReactGA.pageview(location.pathname + location.search);
        // console.log(location.pathname + location.search + location.state)
    }, [location]);
    return (
        <>
            {children}
        </>
    )
}


