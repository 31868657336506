export const type_constants = {
  AUTH: "AUTH",
  REGISTER_USER: "REGISTER_USER",
  LOGIN_USER: "LOGIN_USER",
  CHECK_AUTH: "CHECK_AUTH",
  VERIFY_OTP: "VERIFY_OTP",
  VERIFY_OTP_RESET: "VERIFY_OTP_RESET",
  RESEND_OTP: "RESEND_OTP",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  GET_ALL_TOPICS: "GET_ALL_TOPICS",
  GET_ALL_SUB_TOPICS_BY_ID: "GET_ALL_SUB_TOPICS_BY_ID",
  CREATE_COMMUNITY: "CREATE_COMMUNITY",
  SEARCH_COMMUNITY: "SEARCH_COMMUNITY",
  JOIN_COMMUNITY: "JOIN_COMMUNITY",
  GET_JOINED_COMMUNITY: "GET_JOINED_COMMUNITY",
  SEARCH_POST: "SEARCH_POST",
  VOTE_POST: "VOTE_POST",
  GET_COMMENTS: "GET_COMMENTS",
  POST_COMMENTS: "POST_COMMENTS",
  VOTE_COMMENT: "VOTE_COMMENT",
  GET_REPLIES_BY_COMMENT_ID: "GET_REPLIES_BY_COMMENT_ID",
  REPLY_COMMENT: "REPLY_COMMENT",
  CREATE_POST: "CREATE_POST",
  EDITED_POST: "EDITED_POST",
  BOOKMARK_POST: "BOOKMARK_POST",
  GET_BOOKMARKS: "GET_BOOKMARKS",
  GET_POST_BY_ID: "GET_POST_BY_ID",
  GET_USER_POST: "GET_USER_POST",
  DELETE_POST: "DELETE_POST",
  EDIT_POST: "EDIT_POST",
  GET_COMMUNITY_POSTS_BY_ID: "GET_COMMUNITY_POSTS_BY_ID",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  FOLLOW_UNFOLLOW: "FOLLOW_UNFOLLOW",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  EDIT_PROFILE: "EDIT_PROFILE",
  GET_MESSENGERS: "GET_MESSENGERS",
};

export const save_tokens_constant = "@usertokens";
export const session_expired = "@session_expired";

export const constants = () => {
  return {
    light_theme: "light",
    dark_theme: "dark",
  };
};
