const webTheme = {
  light: {
    light_gray: "lightgray",
    gray: "gray",
    text_color: "black",
    text_color_white: "white",
    bg_color: "#f1f1f1",
    bg_inp_color: "#ebebeb",
    icon_color: "#FE9316",
    icon_color_hover: "white",
    bg_color_nav: "white",
    bg_text_divider: "white",
    active_side_bg_color: "#E5E5E5",
    active_side_text_color: "#777",
    card_bg_color: "white",
    dark_icon_text_color: "#777777",
    drawer_text_color: "#777",
    input_background: "#f4f4f4",
    icon_facebook: "#1178F2",
    invert_color: "black",
  },
  dark: {
    light_gray: "#4f4f4f",
    gray: "gray",
    text_color: "white",
    text_color_white: "black",
    bg_color: "black",
    bg_inp_color: "#403c3c",
    icon_color: "white",
    icon_color_hover: "#FE9316",
    bg_color_nav: "black",
    bg_color_main_content: "#101010",
    bg_text_divider: "#2D2D2D",
    active_side_bg_color: "black",
    active_side_text_color: "white",
    card_bg_color: "#2D2D2D",
    dark_icon_text_color: "white",
    icon_facebook: "#1178F2",
    drawer_text_color: "white",
    input_background: "#403c3c",
    invert_color: "white",
  },
};
export { webTheme };
