import React, { useEffect, useState } from "react";
import "./main.css";
import useTheme from "../../../hooks/theme";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { exit_session } from "../../../store/slice/logoutSlice";
import Input from "../../../component/input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  change_password_async,
  edit_profile_async,
} from "../../../services/profileService";
import { asyncStatus } from "../../../utils/async_status";
import { setUserData } from "../../../store/slice/AuthSlice";
import { FaCamera } from "react-icons/fa";

import { styled } from "@mui/material/styles";
import { apiHandle, baseURL } from "../../../config/apiHandle/apiHandle";
import axios from "axios";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import EditNoteIcon from "@mui/icons-material/EditNote";
const Settings = () => {
  const theme = useTheme();
  const [changePassword, setChangePassword] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editSocialLinks, setEditSocialLinks] = useState(false);
  const [passwordsObj, setPasswordsObj] = useState({});
  const [editProfileData, setEditProfileData] = useState({});
  const { user_data } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  const {
    change_password_status,
    change_password_error,
    change_password_data,
  } = useSelector((state) => state.userProfile);

  const { edit_profile_status, edit_profile_error, edit_profile_data } =
    useSelector((state) => state.userProfile);

  const changePass = () => {
    dispatch(change_password_async({ ...passwordsObj }));
  };
  useEffect(() => {
    if (change_password_status === asyncStatus.SUCCEEDED) {
      setChangePassword(!changePassword);
    }
  }, [change_password_status]);
  useEffect(() => {
    if (edit_profile_status === asyncStatus.SUCCEEDED) {
      dispatch(setUserData(edit_profile_data));
      setEditProfile(false);
      setEditSocialLinks(false);
    }
  }, [edit_profile_status]);
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.text_color}`,
  }));

  const [profileImage, setProfileImage] = useState(user_data?.profile_pic);
  const [imgLoading, setImgLoading] = useState(false);
  const upload_image = async (e) => {
    setImgLoading((prev) => !prev);
    const { files } = e.target;
    if (files.length) {
      try {
        const formData = new FormData();
        formData.append("upload_file", files[0]);
        const response = await axios.post(`${baseURL}/upload-image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setProfileImage(response.data.url);
        setImgLoading(false);
      } catch (error) {
        // Handle any errors
        setImgLoading(false);
        console.error("Error:", error);
      }
    }
  };
  return (
    <div
      className="screen_position_container"
      style={{
        backgroundColor: theme.bg_color,
        color: theme.text_color,
        paddingTop: "30px",
      }}
    >
      <Stack
        sx={{
          backgroundColor: theme.card_bg_color,
          padding: { xl: 10, lg: 10, md: 10, sm: 5, xs: 1 },
        }}
      >
        <Typography
          sx={{
            color: theme.text_color,
            fontWeight: "700",
            fontSize: 23,
            textAlign: "start",
            fontFamily: "arial",
          }}
        >
          Account Settings
        </Typography>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        <Stack alignItems={"start"} my={1}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              !imgLoading && (
                <IconButton
                  component="label"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {}}
                >
                  <SmallAvatar
                    sx={{
                      width: { xs: 8, sm: 15, md: 20, lg: 20, xl: 20 },
                      height: { xs: 8, sm: 15, md: 20, lg: 20, xl: 20 },

                      backgroundColor: theme.text_color,
                      padding: 1,
                      borderRadius: 50,
                    }}
                    variant="square"
                    alt="Remy Sharp"
                    src={
                      "https://res.cloudinary.com/dpsxh6ywz/image/upload/v1705491753/camera-solid_1_euat91.svg"
                    }
                  />
                  <input
                    type="file"
                    onChange={(e) => upload_image(e)}
                    // multiple
                    hidden
                  />
                </IconButton>
              )
            }
          >
            {imgLoading ? (
              <CircularProgress />
            ) : (
              <Avatar
                alt="camera"
                sx={{
                  width: { xs: 30, sm: 60, md: 80, lg: 80, xl: 80 },
                  height: { xs: 30, sm: 60, md: 80, lg: 80, xl: 80 },
                }}
                src={profileImage}
              />
            )}
          </Badge>
          {user_data?.profile_pic !== profileImage && (
            <Grid
              container
              gap={"10px"}
              maxWidth={300}
              sx={{ flexDirection: "row" }}
              my={2}
            >
              <Grid item md={5} lg={5} sm={5} xs={5}>
                <Button
                  onClick={() => {
                    setProfileImage(user_data?.profile_pic);
                  }}
                  variant="contained"
                  fullWidth={true}
                  children={
                    <Typography sx={{ color: "white", fontSize: 10 }}>
                      Close
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={5} lg={5} sm={5} xs={5}>
                <Button
                  onClick={() => {
                    dispatch(edit_profile_async({ profile_pic: profileImage }));
                    setEditProfile(false);
                  }}
                  disabled={edit_profile_status === asyncStatus.LOADING}
                  variant="contained"
                  fullWidth={true}
                  children={
                    <Typography sx={{ color: "white", fontSize: 10 }}>
                      Save changes!
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          )}
        </Stack>
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 14, marginBottom: "6px" }}
              >
                Name
              </Typography>
              <Typography sx={{ color: theme.text_color }}>
                {user_data?.first_name + " " + user_data?.last_name}
              </Typography>
            </Stack>
            {!editProfile && (
              <EditNoteIcon
                sx={{
                  color: theme.primary_color,
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  fontSize: 28,
                }}
                onClick={() => setEditProfile(true)}
              />
            )}
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Box>

        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 14, marginBottom: "6px" }}
              >
                Username
              </Typography>
              <Typography sx={{ color: theme.text_color }}>
                {user_data?.user_name ? user_data?.user_name : "N/A"}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Box>
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 14, marginBottom: "6px" }}
              >
                Country
              </Typography>
              <Typography sx={{ color: theme.text_color }}>
                {user_data?.country ? user_data?.country : "N/A"}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Box>
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 14, marginBottom: "6px" }}
              >
                City
              </Typography>
              <Typography sx={{ color: theme.text_color }}>
                {user_data?.city ? user_data?.city : "N/A"}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Box>
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 15, marginBottom: "6px" }}
              >
                Phone number
              </Typography>
              <Typography sx={{ color: theme.text_color }}>
                {user_data?.phone_number ? user_data?.phone_number : "N/A"}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Box>

        {editProfile && (
          <Stack>
            <Grid container my={2} rowSpacing={2} spacing={3}>
              <Grid item md={6} lg={6} sm={12} xs={12}>
                <input
                  type="text"
                  onChange={(e) =>
                    setEditProfileData({
                      ...editProfileData,
                      first_name: e.target.value,
                    })
                  }
                  defaultValue={user_data?.first_name}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textIndent: "5px",
                    width: "100%",
                  }}
                  placeholder="First name"
                />
              </Grid>
              <Grid item md={6} lg={6} sm={12} xs={12}>
                <input
                  type="text"
                  onChange={(e) =>
                    setEditProfileData({
                      ...editProfileData,
                      last_name: e.target.value,
                    })
                  }
                  defaultValue={user_data?.last_name}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textIndent: "5px",
                    width: "100%",
                  }}
                  placeholder="Last name"
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <input
                  type="text"
                  onChange={(e) =>
                    setEditProfileData({
                      ...editProfileData,
                      user_name: e.target.value,
                    })
                  }
                  defaultValue={
                    user_data?.user_name ? user_data?.user_name : ""
                  }
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textIndent: "5px",
                    width: "100%",
                  }}
                  placeholder="Username"
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <input
                  type="text"
                  onChange={(e) =>
                    setEditProfileData({
                      ...editProfileData,
                      country: e.target.value,
                    })
                  }
                  defaultValue={user_data?.country ? user_data?.country : ""}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textIndent: "5px",
                    width: "100%",
                  }}
                  placeholder="Country"
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <input
                  type="text"
                  onChange={(e) =>
                    setEditProfileData({
                      ...editProfileData,
                      city: e.target.value,
                    })
                  }
                  defaultValue={user_data?.city ? user_data?.city : ""}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textIndent: "5px",
                    width: "100%",
                  }}
                  placeholder="City"
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <input
                  type="number"
                  onChange={(e) =>
                    setEditProfileData({
                      ...editProfileData,
                      phone_number: e.target.value,
                    })
                  }
                  defaultValue={
                    user_data?.phone_number ? user_data?.phone_number : ""
                  }
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textIndent: "5px",
                    width: "100%",
                  }}
                  placeholder="Phone number"
                />
              </Grid>
            </Grid>
            {edit_profile_status === asyncStatus.ERROR && (
              <Typography
                style={{ color: "red", textAlign: "start", fontSize: 15 }}
              >
                {edit_profile_error}
              </Typography>
            )}
            <Grid
              container
              gap={1}
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
              my={2}
            >
              <Grid item md={5} lg={5} sm={12} xs={12}>
                <Button
                  onClick={() => {
                    setEditProfile(!editProfile);
                  }}
                  variant="contained"
                  fullWidth={true}
                  children={
                    <Typography sx={{ color: "white", fontSize: 14 }}>
                      Close
                    </Typography>
                  }
                />
              </Grid>
              <Grid item md={5} lg={5} sm={12} xs={12}>
                <Button
                  onClick={() => dispatch(edit_profile_async(editProfileData))}
                  disabled={edit_profile_status === asyncStatus.LOADING}
                  variant="contained"
                  fullWidth={true}
                  children={
                    <Typography sx={{ color: "white", fontSize: 14 }}>
                      Save changes!
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        )}

        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 14, marginBottom: "6px" }}
              >
                Email
              </Typography>
              <Typography sx={{ color: theme.text_color, fontSize: 15 }}>
                {user_data?.email}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Box>
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack alignItems={"start"}>
              <Typography
                sx={{ color: "#a2a1a1", fontSize: 15, marginBottom: "6px" }}
              >
                Password
              </Typography>
              <Typography
                sx={{
                  color: theme.text_color,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                ..............
              </Typography>
            </Stack>
            {!changePassword && (
              <EditNoteIcon
                sx={{
                  color: theme.primary_color,

                  textDecorationLine: "underline",
                  cursor: "pointer",
                  fontSize: 28,
                }}
                onClick={() => setChangePassword(true)}
              />
            )}
          </Stack>
          {changePassword && (
            <Stack>
              <Grid container my={2} rowSpacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                  <input
                    type="text"
                    onChange={(e) =>
                      setPasswordsObj({
                        ...passwordsObj,
                        old_password: e.target.value,
                      })
                    }
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      textIndent: "5px",
                      width: "100%",
                    }}
                    placeholder="Old Password"
                  />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                  <input
                    type="text"
                    onChange={(e) =>
                      setPasswordsObj({
                        ...passwordsObj,
                        new_password: e.target.value,
                      })
                    }
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      textIndent: "5px",
                      width: "100%",
                    }}
                    placeholder="New Password"
                  />
                </Grid>
              </Grid>
              {change_password_status === asyncStatus.ERROR && (
                <Typography
                  style={{ color: "red", textAlign: "start", fontSize: 14 }}
                >
                  {change_password_error}
                </Typography>
              )}
              <Grid
                container
                gap={1}
                sx={{ flexDirection: "row", justifyContent: "space-between" }}
                my={2}
              >
                <Grid item md={5} lg={5} sm={12} xs={12}>
                  <Button
                    onClick={() => {
                      setChangePassword(!changePassword);
                    }}
                    variant="contained"
                    fullWidth={true}
                    children={
                      <Typography sx={{ color: "white", fontSize: 14 }}>
                        Close
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={5} lg={5} sm={12} xs={12}>
                  <Button
                    onClick={changePass}
                    disabled={change_password_status === asyncStatus.LOADING}
                    variant="contained"
                    fullWidth={true}
                    children={
                      <Typography sx={{ color: "white", fontSize: 14 }}>
                        Change Password
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Stack>
          )}
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

          <Typography
            sx={{
              color: theme.text_color,
              fontWeight: "bold",
              fontSize: 16,
              textAlign: "start ",
            }}
          >
            Social Media Links
          </Typography>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            mt={2}
            justifyContent={"space-between"}
          >
            <Stack
              gap={4}
              flexDirection={"row"}
              justifyContent={"space-evenly"}
            >
              <FaSquareXTwitter
                style={{ cursor: "pointer" }}
                color={theme.text_color}
                size={35}
                onClick={() => {
                  user_data?.twitter
                    ? window.open(user_data?.twitter)
                    : setEditSocialLinks(true);
                }}
              />
              {/* <FaFacebookSquare
                style={{
                  cursor: "pointer",
                  backgroundColor: "white",
                  outline: "none",
                }}
                color={theme.icon_facebook}
                size={35}
                onClick={() => {
                  user_data?.facebook
                    ? window.open(user_data?.facebook)
                    : setEditSocialLinks(true);
                }}
              /> */}

              <img
                src={"/facebook.svg"}
                style={{ cursor: "pointer", margin: "auto" }}
                color={theme.icon_facebook}
                width={37}
                onClick={() => {
                  user_data?.facebook
                    ? window.open(user_data?.facebook)
                    : setEditSocialLinks(true);
                }}
              />

              <img
                src={"/instagram.png"}
                style={{ cursor: "pointer" }}
                color={theme.icon_facebook}
                width={53}
                onClick={() => {
                  user_data?.instagram
                    ? window.open(user_data?.instagram)
                    : setEditSocialLinks(true);
                }}
              />
              {/* <FaInstagramSquare
                style={{ cursor: "pointer" }}
                color={"#9938a8"}
                size={35}
                onClick={() => {
                  user_data?.instagram
                    ? window.open(user_data?.instagram)
                    : setEditSocialLinks(true);
                }}
              /> */}
            </Stack>
            {!editSocialLinks && (
              <EditNoteIcon
                sx={{
                  color: theme.primary_color,
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  fontSize: 28,
                }}
                onClick={() => setEditSocialLinks(true)}
              />
            )}
          </Stack>
          {editSocialLinks && (
            <Stack>
              <Grid container my={2} rowSpacing={2} spacing={3}>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <input
                    type="text"
                    onChange={(e) =>
                      setEditProfileData({
                        ...editProfileData,
                        facebook: e.target.value,
                      })
                    }
                    defaultValue={user_data?.facebook}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      textIndent: "5px",
                      width: "100%",
                    }}
                    placeholder="Facebook profile url"
                  />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <input
                    type="text"
                    onChange={(e) =>
                      setEditProfileData({
                        ...editProfileData,
                        instagram: e.target.value,
                      })
                    }
                    defaultValue={user_data?.instagram}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      textIndent: "5px",
                      width: "100%",
                    }}
                    placeholder="Instagram profile url"
                  />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <input
                    type="text"
                    onChange={(e) =>
                      setEditProfileData({
                        ...editProfileData,
                        twitter: e.target.value,
                      })
                    }
                    defaultValue={user_data?.twitter}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      textIndent: "5px",
                      width: "100%",
                    }}
                    placeholder="Twitter profile url"
                  />
                </Grid>
              </Grid>
              {edit_profile_status === asyncStatus.ERROR && (
                <Typography
                  style={{ color: "red", textAlign: "start", fontSize: 14 }}
                >
                  {edit_profile_error}
                </Typography>
              )}
              <Grid
                container
                gap={1}
                sx={{ flexDirection: "row", justifyContent: "space-between" }}
                my={2}
              >
                <Grid item md={5} lg={5} sm={12} xs={12}>
                  <Button
                    onClick={() => {
                      setEditSocialLinks(!editSocialLinks);
                    }}
                    variant="contained"
                    fullWidth={true}
                    children={
                      <Typography sx={{ color: "white", fontSize: 14 }}>
                        Close
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={5} lg={5} sm={12} xs={12}>
                  <Button
                    onClick={() =>
                      dispatch(edit_profile_async(editProfileData))
                    }
                    disabled={edit_profile_status === asyncStatus.LOADING}
                    variant="contained"
                    fullWidth={true}
                    children={
                      <Typography sx={{ color: "white", fontSize: 14 }}>
                        Save changes!
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Stack>
          )}
        </Box>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

        <Button
          onClick={async () => {
            exit_session();
          }}
          variant="contained"
          sx={{ width: 60, paddingLeft: 6, paddingRight: 6 }}
          children={
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                fontFamily: "sans-serif",
              }}
              to={"/login"}
            >
              Logout
            </Link>
          }
        />
      </Stack>
    </div>
  );
};

export default Settings;
