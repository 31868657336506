import { createAsyncThunk } from "@reduxjs/toolkit";
import { type_constants } from "../store/constants/constants";
import { apiHandle } from "../config/apiHandle/apiHandle";

export const get_messengers_async = createAsyncThunk(
  type_constants.GET_MESSENGERS,
  async () => {
    try {
      const response = await apiHandle.get("/user/get-messengers");
      const res_data = await response.data;
      return res_data;
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        throw Error(error.response.data.message);
      } else {
        throw Error(error.message);
      }
    }
  }
);