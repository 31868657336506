import React from "react";
import "./createpost.css";
import { Avatar, Stack } from "@mui/material";
import { BsPlusSquare } from "react-icons/bs";
// import { CgNotes } from "react-icons/cg";
import useTheme from "../../hooks/theme";
import { useSelector } from "react-redux";

const CreatePostCard = ({ onClick }) => {
  const theme = useTheme();
  const { user_data, userAuth } = useSelector((state) => state.authSlice);
  return (
    <div
      className="container"
      onClick={onClick}
      style={{
        backgroundColor: theme.card_bg_color,
        cursor: "pointer",
        borderRadius: "5px",
      }}
    >
      <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
        <Stack>
          <Avatar
            sizes="lg"
            alt="Remy Sharp"
            src={
              userAuth
                ? user_data?.profile_pic
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
          />
        </Stack>
        <Stack width={"100%"}>
          <textarea
            // onResize={false}
            aria-disabled={true}
            rows={2}
            type="text"
            placeholder={"What do you want to share ?"}
            className={`inputStylee`}
            style={{
              backgroundColor: theme.bg_inp_color,
              borderRadius: "5px",
              outline: "none",
              padding: "auto",
              fontFamily: "sans-serif",
            }}
          />
        </Stack>
      </Stack>
      <Stack
        flexDirection={"row"}
        gap={4}
        justifyContent={"flex-end"}
        sx={{ mt: 1 }}
      >
        <Stack flexDirection={"row"} mt={2} gap={1} alignItems={"center"}>
          <Stack>
            <BsPlusSquare size={23} color={theme.text_color} />
          </Stack>
          <Stack className="bottom_text" style={{ color: theme.text_color }}>
            Create Post
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default CreatePostCard;
