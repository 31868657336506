import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { IoAddSharp } from "react-icons/io5";
import useTheme from "../../hooks/theme";

const squareSize = "80px";

const displayInputSelectedImage = (imageFile) => {
  return URL.createObjectURL(imageFile);
};

export const Upload_image_component = (props) => {
  const { selectedImageHandle, selectedImage } = props;
  const theme = useTheme();
  return (
    <Stack sx={{ mt: "10px", mb: "20px" }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
      >
        {!selectedImage ? (
          <Stack flexDirection={"column"} alignItems={"center"}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: squareSize,
                height: squareSize,
                backgroundColor: "#F4F4F4",
                marginRight: "15px",
                marginTop: "15px",
                //   border: "2px dashed lightgray",
                borderRadius: "50%",
              }}
            >
              <Button
                variant="text"
                component="label"
                sx={{ width: "100%", height: "100%", color: "gray" }}
              >
                <Stack>
                  <IoAddSharp size={25} color="#989898" />
                </Stack>
                <input
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    selectedImageHandle(e);
                  }}
                  type="file"
                  name="community_image_url"
                  hidden
                />
              </Button>
            </Stack>
            <Stack fontSize={13} color={theme.gray} mt={0.6}>
              Upload Photo
            </Stack>
          </Stack>
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              width: squareSize,
              height: squareSize,
              backgroundImage: `url(${selectedImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              marginRight: "15px",
              marginTop: "15px",
              borderRadius: "50%",
            }}
          ></Stack>
        )}
      </Stack>
      {selectedImage && (
        <Stack sx={{ width: squareSize }} mt={1}>
          <Button variant="text" component="label">
            <input
              onChange={(e) => {
                selectedImageHandle(e);
              }}
              type="file"
              hidden
            />
            <Typography align="center" fontSize={9} textTransform="none">
              Change Photo
            </Typography>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
