import { createSlice } from "@reduxjs/toolkit";

import { asyncStatus } from "../../utils/async_status";

import {
  get_profile_async,
} from "../../services/profileService";
import { get_messengers_async } from "../../services/chatService";

const initialState = {
  messenger_status: asyncStatus.IDLE,
  messenger_data: null,
  messenger_error: null,





  currentChat:null
};

const chatsSlice = createSlice({
  name: "chats",
  initialState: initialState,
  reducers: {
    setCurrentChat(state, {payload}) {
      state.currentChat =payload;
    },
  },
  extraReducers: (builder) => {
    // ===================== Register Slice ==================== //

    builder.addCase(get_messengers_async.pending, (state, { payload }) => {
      state.messenger_status = asyncStatus.LOADING;
      state.messenger_data = null;
      state.messenger_error = null;
    });

    builder.addCase(get_messengers_async.fulfilled, (state, { payload }) => {
      state.messenger_status = asyncStatus.SUCCEEDED;
      state.messenger_data = payload.data;
      state.messenger_error = null;
    });
    builder.addCase(get_messengers_async.rejected, (state, actions) => {
      state.messenger_status = asyncStatus.ERROR;
      state.messenger_data = null;
      state.messenger_error = actions.error.message;
    });

 
  },
});

export const {
  setCurrentChat,
} = chatsSlice.actions;
export default chatsSlice.reducer;
