import React from "react";
import "./main.css";
import useTheme from "../../../hooks/theme";
import { Container, Grid, Stack } from "@mui/material";
import HomeSideCard from "../../../component/homeSideCard/homeSideCard";
import CreateCommunityCard from "../../../component/CreatePostCard/CreateCommunityCard";
import DiscoverCommunity from "../../../component/CreatePostCard/DiscoverCommunity";
import { useSelector } from "react-redux";
const CreateCommunity = () => {
  const theme = useTheme();
  const { userAuth } = useSelector((state) => state.authSlice);
  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: theme.bg_color, color: theme.text_color }}
    >
      <Container>
        <Grid container mt={2} spacing={2}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            {userAuth && <CreateCommunityCard />}
            <Stack mb={1} mt={2}>
              <DiscoverCommunity />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CreateCommunity;
