import { socket } from "../config/apiHandle/apiHandle";
let id;
const connectSocket = () => {
    socket.on('connect', () => {
        id = socket.id
        console.log('Connected to server', socket?.id);
        
    });
}


export { connectSocket }