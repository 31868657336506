import React, { useEffect, useState } from "react";
import { primary_color } from "../../../utils/color";
import { Container, Grid, Stack } from "@mui/material";
import "../auth.css";
import Btn from "../../../component/button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../component/input/Input";
import { toast } from "react-toastify";
import { reset_password_create_password_async } from "../../../services/authService";
import { asyncStatus } from "../../../utils/async_status";
import {
  setIdleForgotOtpStatus,
  setIdleResetOtpStatus,
  setIdleResetPasswordStatus,
} from "../../../store/slice/AuthSlice";

const ResetPassword = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [data, setdata] = useState({});

  const { reset_status, reset_data, reset_error, reset_otp_data } = useSelector(
    (state) => state.authSlice
  );

  const verifyHandle = () => {
    let obj = {
      new_password: null,
      otp: reset_otp_data?.otp,
      email: reset_otp_data?.email,
    };

    const { newpassword, confirm_password } = data;

    if (confirm_password !== newpassword) {
      toast.error("Confirm Password & New Password Should be Same ", {
        position: "top-center",
      });
    } else if (confirm_password === newpassword) {
      obj.new_password = newpassword;
      dispatch(reset_password_create_password_async(obj));
    }
  };

  useEffect(() => {
    if (reset_status === asyncStatus.SUCCEEDED) {
      navigation("/login");
      dispatch(setIdleForgotOtpStatus(setIdleForgotOtpStatus));
      dispatch(setIdleResetOtpStatus(setIdleResetOtpStatus));
      dispatch(setIdleResetPasswordStatus(setIdleResetPasswordStatus));
    }
  }, [, reset_status]);

  return (
    <Stack
      sx={{
        backgroundColor: primary_color,
        height: { md: "130vh", lg: "130vh", sm: "110vh", xs: "110vh" },
      }}
    >
      <Container maxWidth={"sm"} sx={{ p: { md: 5, lg: 5, sm: 0, xs: 0 } }}>
        <Stack
          sx={{
            backgroundColor: "#FFFFFF",
            p: { md: 8, lg: 8, sm: 5, xs: 5 },
            borderRadius: "20px",
            mt: 2,
          }}
        >
          <Stack className="otp_heading">Reset Password</Stack>

          <Stack mt={10}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Input
                  onChange={(e) =>
                    setdata({ ...data, newpassword: e.target.value })
                  }
                  label="New Password"
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Input
                  onChange={(e) =>
                    setdata({ ...data, confirm_password: e.target.value })
                  }
                  label="Confirm Password"
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Btn
                  loading={reset_status === asyncStatus.LOADING}
                  onClick={() => verifyHandle()}
                  label={"Send"}
                  fullWidth={true}
                  sx={{
                    p: 1,
                    // backgroundColor: "#0D4587",
                    borderRadius: "8px",
                    mt: 15,
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default ResetPassword;
