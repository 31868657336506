import { useSelector } from "react-redux";
import useTheme from "./theme";

const useUiThemeStyles = () => {
  const theme = useTheme();

  const styles = {
    light: {
      vote_idle_style: {
        backgroundColor: "transparent",
        color: "#FE9316",
        border: "1px solid #FE9316",
      },
      vote_on_hover_style: {
        backgroundColor: "#FE9316",
        border: "1px solid #FE9316",
        color: theme.bg_color,
      },

      icon_idle_style: {
        backgroundColor: theme.card_bg_color,
        color: theme.text_color,
        padding: 10,
      },
      icon_hover_style: {
        backgroundColor: theme.text_color,
        color: theme.card_bg_color,
        padding: 10,
      },
      // voted_state: {
      //   backgroundColor: theme.icon_color_hover,
      //   border: `1px solid ${theme.icon_color}`,
      //   "&:hover": {
      //     color: "red",
      //     backgroundColor: theme.icon_color,
      //     border: `1px solid ${theme.icon_color_hover}`,
      //   },
      // },
    },
    // dark: {
    //   vote_idle: {
    //     backgroundColor: theme.icon_color_hover,
    //     border: `1px solid ${theme.icon_color}`,
    //     "&:hover": {
    //       color: "red",
    //       backgroundColor: theme.icon_color,
    //       border: `1px solid ${theme.icon_color_hover}`,
    //     },
    //   },
    //   voted_state: {},
    // },
  };

  const mode_theme = "light";

  const vote_idle = {
    ...styles[mode_theme].vote_idle_style,
    "&:hover": { ...styles[mode_theme].vote_on_hover_style },
  };
  const voted_styles = {
    ...styles[mode_theme].vote_on_hover_style,
    "&:hover": { ...styles[mode_theme].vote_idle_style },
  };

  return { ...styles[mode_theme], voted_styles, vote_idle };
};
export default useUiThemeStyles;
