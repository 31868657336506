import React, { useEffect, useState } from "react";
import { primary_color } from "../../../utils/color";
import { Container, Grid, Stack, Typography } from "@mui/material";
import "../auth.css";
import Btn from "../../../component/button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../component/input/Input";
import { reset_password_request_otp_async } from "../../../services/authService";
import { asyncStatus } from "../../../utils/async_status";

const ForgotPassword = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [data, setdata] = useState({});

  const { forgot_status, forgot_data, forgot_error } = useSelector(
    (state) => state.authSlice
  );

  // console.log("forgot_data", forgot_data);
  const verifyHandle = () => {
    dispatch(reset_password_request_otp_async(data));
  };

  useEffect(() => {
    if (forgot_status === asyncStatus.SUCCEEDED) {
      navigation("/verifyOtpPassword");
    }
  }, [, forgot_status]);

  return (
    <Stack
      sx={{
        backgroundColor: primary_color,
        height: { md: "130vh", lg: "130vh", sm: "110vh", xs: "110vh" },
      }}
    >
      <Container maxWidth={"sm"} sx={{ p: { md: 5, lg: 5, sm: 0, xs: 0 } }}>
        <Stack
          sx={{
            backgroundColor: "#FFFFFF",
            p: { md: 8, lg: 8, sm: 5, xs: 5 },
            borderRadius: "16px",
            mt: 2,
          }}
        >
          <Stack
            className="otp_heading poppins-medium"
            // sx={{ fontFamily: "poppins-regular " }}
          >
            Forget Password
          </Stack>
          <Stack
            className="otp_sub"
            sx={{ color: "#686868", mt: 2, fontSize: "16px" }}
          >
            The Verification Email will be sent to the mailbox. please check it
            !
          </Stack>

          <Stack mt={10}>
            <Grid container spacing={0}>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Input
                  onChange={(e) => setdata({ ...data, email: e.target.value })}
                  label="Email"
                  placeholder="Enter Email Address"
                />
                <Typography style={{ color: "red" }}>{forgot_error}</Typography>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Btn
                  loading={forgot_status === asyncStatus.LOADING}
                  onClick={() => verifyHandle()}
                  label={"Send"}
                  fullWidth={true}
                  sx={{
                    p: 1,
                    // backgroundColor: "#0D4587",
                    borderRadius: "8px",
                    mt: 5,
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default ForgotPassword;
