import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_post_id_async } from "../../../services/newsFeedService";
import { useParams } from "react-router-dom";
import Post from "../../../component/post/Post";
import { RxCross1 } from "react-icons/rx";
import useTheme from "../../../hooks/theme";
import { asyncStatus } from "../../../utils/async_status";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import ShowComments from "../../../component/Comments/ShowComments";
import DeleteIcon from "@mui/icons-material/Delete";
const ViewPost = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userAuth, user_data } = useSelector((state) => state.authSlice);
  const { get_post_by_id_status, get_post_by_id_error, get_post_by_id_data } =
    useSelector((state) => state.newsFeed);
  useEffect(() => {
    dispatch(
      get_post_id_async({
        user_id: userAuth ? user_data?._id : "",
        post_id: id,
      })
    );
  }, []);
  const theme = useTheme();
  console.log("working", get_post_by_id_data);
  return (
    <div
      className="screen_position_container"
      style={{ backgroundColor: theme.bg_color, minHeight: "100vh" }}
    >
      <Container backgroundColor={theme.card_bg_color}>
        {get_post_by_id_status === asyncStatus.IDLE ||
        get_post_by_id_status === asyncStatus.LOADING ? (
          <Stack
            backgroundColor={theme.card_bg_color}
            height={"40vh"}
            width={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Stack>
        ) : get_post_by_id_status === asyncStatus.SUCCEEDED ? (
          <Post
            data={get_post_by_id_data}
            // handleOpenComment={handleOpen}
            sideicon={
              user_data?._id === get_post_by_id_data?.user_id ? (
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => alert("noooooooooooooooooool")}
                  color={theme.text_color}
                />
              ) : null
            }
          />
        ) : get_post_by_id_status === asyncStatus.ERROR ? (
          <Stack
            backgroundColor={theme.card_bg_color}
            height={"40vh"}
            width={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography color={"red"}>Something went wrong</Typography>
          </Stack>
        ) : null}
        <Stack pl={1} backgroundColor={theme.card_bg_color}>
          <Typography
            sx={{
              fontSize: { xl: 24, lg: 22, md: 20, sm: 18, xs: 16 },
              color: theme.text_color,
              fontWeight: "bold",
              width: 1,
              my: 1,
              textAlign: "start",
              textDecorationLine: "underline",
              textIndent: "5px",
            }}
          >
            Comments
          </Typography>
          {get_post_by_id_status === asyncStatus.SUCCEEDED && (
            <ShowComments post_id={get_post_by_id_data?._id} />
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default ViewPost;
