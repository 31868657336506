import { Divider, Stack } from "@mui/material";
import React, { useEffect } from "react";
import useTheme from "../../hooks/theme";
import { useDispatch, useSelector } from "react-redux";
import { join_community_async } from "../../services/communityService";
import Btn from "../button/Button";
import { asyncStatus } from "../../utils/async_status";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setJoinCommunityStatusIdle } from "../../store/slice/communitySlice";

const CommunityCard = ({
  title,
  description,
  image_url,
  followers,
  id,
  joined,
}) => {
  const theme = useTheme();
  const { join_community_status, join_community_error, join_community_data } =
    useSelector((state) => state.community);
  const [loadingId, setLoadingId] = useState("");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    if (join_community_status === asyncStatus.SUCCEEDED) {
      setLoadingId("");
      dispatch(setJoinCommunityStatusIdle());
    }
  }, []);

  return (
    <Stack mt={2}>
      <Divider sx={{ border: 0.4, borderColor: "#9F9F9F", opacity: 0.3 }} />
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        mt={1}
        sx={{
          justifyContent: {
            md: "space-between",
            lg: "space-between",
            sm: "space-between",
            xs: "space-between",
          },
        }}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
          <Stack>
            <img
              src={image_url}
              width={53}
              height={53}
              style={{ borderRadius: "100%" }}
            />
          </Stack>
          <Stack
            sx={{ cursor: "pointer" }}
            onClick={() => navigation(`/CommunityDetails/${id}`)}
            flexDirection={"column"}
          >
            <span
              style={{
                fontWeight: "bold",
                marginBottom: "3px",
                fontSize: 15,
                color: theme.text_color,
              }}
            >
              {title}
            </span>
            <span
              style={{
                fontSize: 10.5,
                marginBottom: "4px",
                color: theme.text_color,
              }}
            >
              {description}
            </span>
            <span
              style={{
                fontSize: 10.5,
                marginBottom: "4px",
                color: "#a7a7a7",
              }}
            >
              {`${followers} ${followers?.length < 1 ? "members" : "member"}`}
            </span>
          </Stack>
        </Stack>
        <Btn
          label={joined ? "Leave" : "Join"}
          onClick={() => {
            setLoadingId(id);
            dispatch(join_community_async({ community_id: id }));
          }}
          loading={
            join_community_status === asyncStatus.LOADING && id === loadingId
          }
          // disabled={joined}
          sx={{
            fontSize: 10,
            backgroundColor: "#FE9316",
            ":hover": {
              backgroundColor: "#c66a00",
            },
            alignItems: "center",
            // justifyContent: "center",
            padding: "8px 30px",
          }}
        />
        {/* <Stack
          onClick={() => dispatch(join_community_async({ community_id: id }))}
          sx={{
            backgroundColor: "#FE9316",
            color: "white",
            p: 1,
            borderRadius: 1,
            fontSize: 13,
            width: 80,
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          Join
        </Stack> */}
      </Stack>
    </Stack>
  );
};

export default CommunityCard;
