import {
  Box,
  Button,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useTheme from "../../hooks/theme";
import "./RequestCustomSubTopicModal.css";
import { primary_color } from "../../utils/color";
import Input from "../input/Input";
import Select_dropdown from "../select/Select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const RequestCustomSubTopicModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  return (
    <>
      <Button sx={{ textTransform: "none" }} onClick={handleOpen}>
        Create Request
      </Button>

      <Modal
        disableScrollLock
        open={open}
        className="scroll-remove"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: { md: "40%", lg: "40%", sm: "50%", xs: "80%" },
            height: { md: "70%", lg: "70%", sm: "80%", xs: "70%" },
            // height: { md: "70%", lg: "70%", sm: "50%", xs: "auto" },
            overflowY: "scroll",
            backgroundColor: theme.bg_color,
          }}
          className="scroll_content scroll-remove"
        >
          <Stack gap={2}>
            <Typography
              sx={{
                color: primary_color,
                fontSize: 22,
                mb: 2,
                textDecorationLine: "underline",
              }}
            >
              Request for custom sub-topic:
            </Typography>
            <Select_dropdown
              //   handleClick={inputHandle}
              style={{
                padding: "10px 10px",
                borderBottomLeftRadius: "7px",
                borderTopLeftRadius: "7px",
                backgroundColor: "#EDEDED",
                border: `1px solid ${theme.gray}`,
                outline: "none",
              }}
              label="Select Topic"
            />
            <Input label={"Sub-topic name"} placeholder={"Sub-topic"} />

            <TextareaAutosize
              style={{
                width: "100%",
                // height: { md: "30%", lg: "30%", sm: "30%", xs: "20%" },
                // border: "none",
                //   padding: 10,
                // outline: "none",
                fontSize: { xs: 12, sm: 14, md: 16, lg: 16, xl: 16 },
                resize: "none",
                backgroundColor: "transparent",
                overflow: "auto",
                color: theme.text_color,
              }}
              minRows={5}
              //   onChange={(e) => setBanData({ ...banData, ban_reason: e.target.value })}
              // onChange={(e) =>
              //   setText_html_code_block(`<p>${e.target.value}</p>`)
              // }
              // aria-label="empty textarea"
              placeholder="Explain your request here!"
            />
            <Button variant="outlined">Submit</Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default RequestCustomSubTopicModal;
