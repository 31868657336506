import React from "react";
import "./style.css";
import { Route, Routes } from "react-router-dom";
import { Stack } from "@mui/material";
import { sideBarData } from "../../utils/routelist";
// import TestRoute from "../../screens/TestRoute";
import Home from "../../screens/dashboard/dashboardScreens/Home";
import { useSelector } from "react-redux";

const Template_Side_Drawer = () => {
  const { userAuth } = useSelector((state) => state.authSlice);
  return (
    <>
      <div
        style={{ backgroundColor: "white", color: "black" }}
        className="body"
      >
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            // marginTop: "50px",
          }}
        >
          <div
            style={{ backgroundColor: "white", color: "black" }}
            className="contrastbg"
          >
            <Routes>
              {React.Children.toArray(
                sideBarData.map((e, i) => {
                  <Route key={i} path={"/"} element={<Home />} />;
                })
              )}
            </Routes>
            <Stack sx={{}}>
              <Routes>
                {React.Children.toArray(
                  sideBarData.map((e, i) => {
                    if (e.auth_required) {
                      return (
                        userAuth && (
                          <Route key={i} path={e.linkTo} element={e.element} />
                        )
                      );
                    } else {
                      return (
                        <Route key={i} path={e.linkTo} element={e.element} />
                      );
                    }
                  })
                )}
              </Routes>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template_Side_Drawer;
