export const countries_data = [
    {
        "label": "Afghanistan",
        "code": "AF",
        "phone": "93",
        "cities": [
            "‘Alāqahdārī Dīshū",
            "Aībak",
            "Andkhoy",
            "Āqchah",
            "Ārt Khwājah",
            "Asadabad",
            "Ashkāsham",
            "Āsmār",
            "Baghlān",
            "Balkh",
            "Bāmyān",
            "Baraki Barak",
            "Bāsawul",
            "Bāzārak",
            "Chahār Burj",
            "Charikar",
            "Chīras",
            "Darzāb",
            "Dasht-e Archī",
            "Dowlatābād",
            "Farah",
            "Fayrōz Kōh",
            "Fayzabad",
            "Gardez",
            "Gereshk",
            "Ghazni",
            "Ghōriyān",
            "Ghormach",
            "Herāt",
            "Ḩukūmatī Azrah",
            "Ḩukūmatī Dahanah-ye Ghōrī",
            "Imām Şāḩib",
            "Jabal os Saraj",
            "Jalālābād",
            "Jurm",
            "Kabul",
            "Kafir Qala",
            "Kandahār",
            "Karukh",
            "Khanabad",
            "Khandūd",
            "Khāsh",
            "Khōst",
            "Khulm",
            "Kuhsān",
            "Kunduz",
            "Kushk",
            "Lab-Sar",
            "Larkird",
            "Lashkar Gāh",
            "Markaz-e Ḩukūmat-e Darwēshān",
            "Markaz-e Woluswalī-ye Āchīn",
            "Maymana",
            "Mazār-e Sharīf",
            "Mehtar Lām",
            "Mīr Bachah Kōṯ",
            "Mīrābād",
            "Nahrīn",
            "Nīlī",
            "Paghmān",
            "Panjāb",
            "Pārūn",
            "Pul-e ‘Alam",
            "Pul-e Khumrī",
            "Qal‘ah-ye Shahr",
            "Qala i Naw",
            "Qalāt",
            "Qarah Bāgh",
            "Qarāwul",
            "Qarchī Gak",
            "Qarqīn",
            "Rāghistān",
            "Rūdbār",
            "Sang-e Chārak",
            "Sangīn",
            "Sar-e Pul",
            "Saṟōbī",
            "Shahrak",
            "Shibirghān",
            "Shīnḏanḏ",
            "Sidqābād",
            "Tagāw-Bāy",
            "Taloqan",
            "Tarinkot",
            "Tīr Pul",
            "Tukzār",
            "Uruzgān",
            "Wākhān",
            "Zaṟah Sharan",
            "Zaranj",
            "Zarghūn Shahr",
            "Zindah Jān"
        ]
    },
    {
        "label": "Aland Islands",
        "code": "AX",
        "phone": "+358-18",
        "cities": []
    },
    {
        "label": "Albania",
        "code": "AL",
        "phone": "355",
        "cities": [
            "Bajram Curri",
            "Ballsh",
            "Banaj",
            "Bashkia Berat",
            "Bashkia Bulqizë",
            "Bashkia Devoll",
            "Bashkia Divjakë",
            "Bashkia Dropull",
            "Bashkia Durrës",
            "Bashkia Fier",
            "Bashkia Finiq",
            "Bashkia Himarë",
            "Bashkia Kavajë",
            "Bashkia Kelcyrë",
            "Bashkia Klos",
            "Bashkia Kolonjë",
            "Bashkia Konispol",
            "Bashkia Krujë",
            "Bashkia Kuçovë",
            "Bashkia Kurbin",
            "Bashkia Lezhë",
            "Bashkia Libohovë",
            "Bashkia Malësi e Madhe",
            "Bashkia Maliq",
            "Bashkia Mallakastër",
            "Bashkia Mat",
            "Bashkia Memaliaj",
            "Bashkia Mirditë",
            "Bashkia Patos",
            "Bashkia Përmet",
            "Bashkia Poliçan",
            "Bashkia Pukë",
            "Bashkia Pustec",
            "Bashkia Selenicë",
            "Bashkia Shijak",
            "Bashkia Skrapar",
            "Bashkia Tepelenë",
            "Bashkia Vau i Dejës",
            "Bashkia Vlorë",
            "Bashkia Vorë",
            "Berat",
            "Bilisht",
            "Bulqizë",
            "Burrel",
            "Çorovodë",
            "Delvinë",
            "Divjakë",
            "Durrës",
            "Durrës District",
            "Ersekë",
            "Fier",
            "Fier-Çifçi",
            "Fushë-Arrëz",
            "Fushë-Krujë",
            "Gjinkar",
            "Gjirokastër",
            "Himarë",
            "Kamëz",
            "Kavajë",
            "Këlcyrë",
            "Klos",
            "Konispol",
            "Koplik",
            "Korçë",
            "Krrabë",
            "Krujë",
            "Krumë",
            "Ksamil",
            "Kuçovë",
            "Kukës",
            "Kurbnesh",
            "Laç",
            "Lazarat",
            "Leskovik",
            "Lezhë",
            "Libohovë",
            "Libonik",
            "Lushnjë",
            "Maliq",
            "Mamurras",
            "Mborje",
            "Memaliaj",
            "Milot",
            "Orikum",
            "Patos",
            "Patos Fshat",
            "Përmet",
            "Peshkopi",
            "Pogradec",
            "Poliçan",
            "Pukë",
            "Roskovec",
            "Rrëshen",
            "Rrethi i Beratit",
            "Rrethi i Bulqizës",
            "Rrethi i Delvinës",
            "Rrethi i Devollit",
            "Rrethi i Dibrës",
            "Rrethi i Hasit",
            "Rrethi i Kavajës",
            "Rrethi i Kolonjës",
            "Rrethi i Krujës",
            "Rrethi i Kuçovës",
            "Rrethi i Kukësit",
            "Rrethi i Kurbinit",
            "Rrethi i Malësia e Madhe",
            "Rrethi i Mallakastrës",
            "Rrethi i Matit",
            "Rrethi i Shkodrës",
            "Rrethi i Skraparit",
            "Rrethi i Tiranës",
            "Rrogozhinë",
            "Rubik",
            "Sarandë",
            "Selenicë",
            "Shëngjin",
            "Shijak",
            "Shkodër",
            "Sinaballaj",
            "Sukth",
            "Tepelenë",
            "Tirana",
            "Ulëz",
            "Ura Vajgurore",
            "Vau i Dejës",
            "Velçan",
            "Vlorë",
            "Vorë",
            "Voskopojë",
            "Vukatanë"
        ]
    },
    {
        "label": "Algeria",
        "code": "DZ",
        "phone": "213",
        "cities": [
            "‘Aïn el Hadjel",
            "’Aïn Abid",
            "’Aïn Benian",
            "’Aïn Boucif",
            "’Aïn Deheb",
            "’Aïn el Bell",
            "’Aïn el Hadjar",
            "’Aïn el Hammam",
            "’Aïn el Melh",
            "’Aïn el Turk",
            "’Aïn Merane",
            "Abou el Hassan",
            "Adrar",
            "Aflou",
            "Aïn Arnat",
            "Aïn Beïda",
            "Aïn Bessem",
            "Aïn Defla",
            "Aïn El Berd District",
            "Aïn el Bya",
            "Aïn Fakroun",
            "Aïn Kercha",
            "Aïn Oussera",
            "Aïn Sefra",
            "Aïn Smara",
            "Aïn Taya",
            "Aïn Temouchent",
            "Aïn Touta",
            "Akbou",
            "Algiers",
            "Amizour",
            "Ammi Moussa",
            "Annaba",
            "Aoulef",
            "Arbatache",
            "Arhribs",
            "Arris",
            "Azazga",
            "Azzaba",
            "Bab Ezzouar",
            "BABOR - VILLE",
            "Balidat Ameur",
            "Baraki",
            "Barbacha",
            "Barika",
            "Batna",
            "Béchar",
            "Bejaïa",
            "Belarbi",
            "Ben Badis Sid Bel Abbés",
            "Ben Mehidi",
            "Beni Amrane",
            "Beni Douala",
            "Beni Mered",
            "Beni Mester",
            "Beni Saf",
            "Bensekrane",
            "Berrahal",
            "Berriane",
            "Berrouaghia",
            "Besbes",
            "Bir el Ater",
            "Bir el Djir",
            "Birine",
            "Birkhadem",
            "Biskra",
            "Blida",
            "Boghni",
            "Bordj Bou Arreridj",
            "Bordj el Kiffan",
            "Bordj Ghdir",
            "Bordj Zemoura",
            "Boû Arfa",
            "Bou Hanifia el Hamamat",
            "Bou Ismaïl",
            "Bou Tlelis",
            "Boudjima",
            "Boudouaou",
            "Boufarik",
            "Bougaa",
            "Bougara",
            "Bouinan",
            "Bouïra",
            "Boukadir",
            "Boumagueur",
            "Boumahra Ahmed",
            "Boumerdas",
            "Brezina",
            "Chabet el Ameur",
            "Charef",
            "Chebli",
            "Chelghoum el Aïd",
            "Chemini",
            "Cheraga",
            "Cheria",
            "Chetouane",
            "Chiffa",
            "Chlef",
            "Chorfa",
            "Constantine",
            "Dar Chioukh",
            "Dar el Beïda",
            "Debila",
            "Dellys",
            "Didouche Mourad",
            "Djamaa",
            "Djamaa",
            "Djebilet Rosfa",
            "Djelfa",
            "Djidiouia",
            "Douera",
            "Draa Ben Khedda",
            "Draa el Mizan",
            "Drean",
            "Ech Chettia",
            "El Abadia",
            "El Abiodh Sidi Cheikh",
            "El Achir",
            "El Affroun",
            "El Amria",
            "El Aouinet",
            "El Attaf",
            "El Bayadh",
            "El Bour",
            "El Eulma",
            "El Hadjar",
            "El Hadjira",
            "El Hadjira",
            "el hed",
            "El Idrissia",
            "El Kala",
            "El Khroub",
            "El Kseur",
            "El Malah",
            "El Oued",
            "El Tarf",
            "Es Senia",
            "Feraoun",
            "Freha",
            "Frenda",
            "Ghardaïa",
            "Guelma",
            "Hadjout",
            "Hamma Bouziane",
            "Hammam Bou Hadjar",
            "Hammamet",
            "Haoud El Hamra",
            "Hassi Messaoud",
            "Hassi Messaoud",
            "Héliopolis",
            "Hennaya",
            "I-n-Salah",
            "Ighram",
            "Illizi",
            "Jijel",
            "Karkira",
            "Khemis el Khechna",
            "Khemis Miliana",
            "Khenchela",
            "Kolea",
            "Ksar Chellala",
            "Ksar el Boukhari",
            "L’Arbaa Naït Irathen",
            "Laghouat",
            "Lakhdaria",
            "Lamtar",
            "Larbaâ",
            "Lardjem",
            "M’Sila",
            "Makouda",
            "Mansoûra",
            "Mansourah",
            "Marhoum",
            "Mascara",
            "Mazouna",
            "Médéa",
            "Meftah",
            "Megarine",
            "Megarine",
            "Mehdia daira de meghila",
            "Mekla",
            "Melouza",
            "Merine",
            "Merouana",
            "Mers el Kebir",
            "Meskiana",
            "Messaad",
            "Metlili Chaamba",
            "Mezaourou",
            "Mila",
            "Moggar",
            "Mostaganem",
            "Moulay Slissen",
            "Mouzaïa",
            "N'Goussa",
            "Naama",
            "Naciria",
            "Nedroma",
            "Oran",
            "Ouargla",
            "Ouargla",
            "Oued el Abtal",
            "Oued el Alleug",
            "Oued Fodda",
            "Oued Rhiou",
            "Oued Sly",
            "Ouled Mimoun",
            "Ouled Moussa",
            "Oum el Bouaghi",
            "Oumache",
            "Râs el Aïoun",
            "Râs el Oued",
            "Reggane",
            "Reghaïa",
            "Reguiba",
            "Relizane",
            "Remchi",
            "Robbah",
            "Rouached",
            "Rouiba",
            "Rouissat",
            "Rouissat",
            "Saïda",
            "Salah Bey",
            "Saoula",
            "Sebdou",
            "Seddouk",
            "Sedrata",
            "Sétif",
            "Sfissef",
            "Sidi Abdelli",
            "Sidi Aïssa",
            "Sidi Akkacha",
            "Sidi Ali Boussidi",
            "Sidi Amrane",
            "Sidi Amrane",
            "Sidi Bel Abbès",
            "Sidi Brahim",
            "Sidi ech Chahmi",
            "Sidi Hamadouche",
            "Sidi Khaled",
            "Sidi Mérouane",
            "Sidi Moussa",
            "Sidi Okba",
            "Sidi Senoussi سيدي سنوسي",
            "Sidi Slimane",
            "Sidi Yacoub",
            "Sidi Yahia",
            "Sig",
            "Skikda",
            "Smala",
            "Sougueur",
            "Souk Ahras",
            "Souma",
            "Sour el Ghozlane",
            "Tabia Sid Bel Abbés",
            "Tadmaït",
            "Taibet",
            "Tamalous",
            "Tamanrasset",
            "Tamellaht",
            "Tamerna Djedida",
            "Tazoult-Lambese",
            "Tebesbest",
            "Tebesbest",
            "Tébessa",
            "Teghalimet",
            "Telagh",
            "Telerghma",
            "Tenezara",
            "Tenira",
            "Tessala",
            "Thenia",
            "Theniet el Had",
            "Tiaret",
            "Timimoun",
            "Timizart",
            "Tindouf",
            "Tipasa",
            "Tirmitine",
            "Tissemsilt",
            "Tizi Gheniff",
            "Tizi Ouzou",
            "Tizi Rached",
            "Tizi-n-Tleta",
            "Tlemcen",
            "Tolga",
            "Touggourt",
            "Touggourt",
            "Zemoura",
            "Zeralda",
            "Zeribet el Oued",
            "Zerouala"
        ]
    },
    {
        "label": "American Samoa",
        "code": "AS",
        "phone": "+1-684",
        "cities": []
    },
    {
        "label": "Andorra",
        "code": "AD",
        "phone": "376",
        "cities": [
            "Andorra la Vella",
            "Arinsal",
            "Canillo",
            "El Tarter",
            "Encamp",
            "la Massana",
            "les Escaldes",
            "Ordino",
            "Pas de la Casa",
            "Sant Julià de Lòria"
        ]
    },
    {
        "label": "Angola",
        "code": "AO",
        "phone": "244",
        "cities": [
            "Ambriz",
            "Baía Farta",
            "Balombo",
            "Belas",
            "Benguela",
            "Bocoio",
            "Bula Atumba",
            "Caála",
            "Cabinda",
            "Caconda",
            "Cacuaco",
            "Caimbambo",
            "Caluquembe",
            "Camabatela",
            "Camacupa",
            "Catabola",
            "Catumbela",
            "Catumbela",
            "Caxito",
            "Cazaji",
            "Cazenga",
            "Chela",
            "Chibia",
            "Chicomba",
            "Chipindo",
            "Chissamba",
            "Chongoroi",
            "Cubal",
            "Cuito",
            "Cuvango",
            "Dande",
            "Gambos",
            "Ganda",
            "Huambo",
            "Humpata",
            "Icolo e Bengo",
            "Jamba",
            "Léua",
            "Lobito",
            "Longonjo",
            "Luanda",
            "Luau",
            "Lubango",
            "Lucapa",
            "Luena",
            "Lumeje",
            "Malanje",
            "Matala",
            "Mbanza Congo",
            "Menongue",
            "Muxima",
            "N'zeto",
            "N’dalatando",
            "Nambuangongo",
            "Ondjiva",
            "Pango Aluquém",
            "Quibala",
            "Quilengues",
            "Quipungo",
            "Saurimo",
            "Soio",
            "Sumbe",
            "Sumbe",
            "Talatona",
            "Uacu Cungo",
            "Úcua",
            "Uíge",
            "Viana"
        ]
    },
    {
        "label": "Anguilla",
        "code": "AI",
        "phone": "+1-264",
        "cities": []
    },
    {
        "label": "Antarctica",
        "code": "AQ",
        "phone": "672",
        "cities": []
    },
    {
        "label": "Antigua And Barbuda",
        "code": "AG",
        "phone": "+1-268",
        "cities": [
            "All Saints",
            "Bolands",
            "Codrington",
            "Falmouth",
            "Liberta",
            "Parham",
            "Piggotts",
            "Potters Village",
            "Saint John’s"
        ]
    },
    {
        "label": "Argentina",
        "code": "AR",
        "phone": "54",
        "cities": [
            "28 de Noviembre",
            "Abra Pampa",
            "Achiras",
            "Adelia María",
            "Agua de Oro",
            "Aguilares",
            "Alba Posse",
            "Albardón",
            "Aldea San Antonio",
            "Alderetes",
            "Alejandro Roca",
            "Alejo Ledesma",
            "Allen",
            "Almafuerte",
            "Almafuerte",
            "Alpachiri",
            "Alta Gracia",
            "Alta Italia",
            "Alto Río Senguer",
            "Altos de Chipión",
            "Aluminé",
            "Alvear",
            "Añatuya",
            "Ancasti",
            "Andacollo",
            "Andalgalá",
            "Añelo",
            "Anguil",
            "Antofagasta de la Sierra",
            "Apolinario Saravia",
            "Aranguren",
            "Arata",
            "Arauco",
            "Arias",
            "Aristóbulo del Valle",
            "Armstrong",
            "Arroyito",
            "Arroyo Cabral",
            "Arroyo del Medio",
            "Arroyo Seco",
            "Arrufó",
            "Avellaneda",
            "Aviá Terai",
            "Azara",
            "Balnearia",
            "Balvanera",
            "Barracas",
            "Barrancas",
            "Barranqueras",
            "Basail",
            "Belgrano",
            "Bell Ville",
            "Bella Italia",
            "Bella Vista",
            "Beltrán",
            "Bernardo de Irigoyen",
            "Bernardo Larroudé",
            "Bernasconi",
            "Berón de Astrada",
            "Berrotarán",
            "Boedo",
            "Bonpland",
            "Bonpland",
            "Bovril",
            "Brinkmann",
            "Buchardo",
            "Buena Esperanza",
            "Buenos Aires",
            "Burruyacú",
            "Buta Ranquil",
            "Cachí",
            "Cafayate",
            "Caimancito",
            "Calchaquí",
            "Caleta Olivia",
            "Caleufú",
            "Calilegua",
            "Calingasta",
            "Camarones",
            "Camilo Aldao",
            "Campo Gallo",
            "Campo Grande",
            "Campo Largo",
            "Campo Quijano",
            "Campo Ramón",
            "Campo Viera",
            "Cañada de Gómez",
            "Cañada de Luque",
            "Canals",
            "Candelaria",
            "Candelaria",
            "Capayán",
            "Capilla del Monte",
            "Capioví",
            "Capitán Bermúdez",
            "Capitán Solari",
            "Caraguatay",
            "Carcarañá",
            "Carnerillo",
            "Carrilobo",
            "Caseros",
            "Casilda",
            "Castelli",
            "Castro Barros",
            "Catriel",
            "Catriló",
            "Caucete",
            "Cavanagh",
            "Ceibas",
            "Centenario",
            "Ceres",
            "Cerro Azul",
            "Cerro Corá",
            "Cervantes",
            "Chajarí",
            "Chamical",
            "Chañar Ladeado",
            "Charadai",
            "Charata",
            "Charras",
            "Chavarría",
            "Chazón",
            "Chichinales",
            "Chicoana",
            "Chilecito",
            "Chimbas",
            "Chimpay",
            "Choele Choel",
            "Chorotis",
            "Chos Malal",
            "Ciervo Petiso",
            "Cinco Saltos",
            "Cintra",
            "Cipolletti",
            "Clodomira",
            "Clorinda",
            "Colegiales",
            "Colón",
            "Colonia Aurora",
            "Colonia Barón",
            "Colonia Benítez",
            "Colonia Dora",
            "Colonia Elía",
            "Colonia Elisa",
            "Colonia La Tordilla",
            "Colonia San Bartolomé",
            "Colonias Unidas",
            "Comallo",
            "Comandante Fontana",
            "Comandante Luis Piedra Buena",
            "Comodoro Rivadavia",
            "Concarán",
            "Concepción",
            "Concepción de la Sierra",
            "Concepción del Bermejo",
            "Concepción del Uruguay",
            "Concordia",
            "Conscripto Bernardi",
            "Contraalmirante Cordero",
            "Córdoba",
            "Coronda",
            "Coronel Baigorria",
            "Coronel Belisle",
            "Coronel Du Graty",
            "Coronel Moldes",
            "Corral de Bustos",
            "Corralito",
            "Corrientes",
            "Corzuela",
            "Cosquín",
            "Costa Sacate",
            "Coté-Lai",
            "Crespo",
            "Cruz Alta",
            "Cruz de los Milagros",
            "Cruz del Eje",
            "Cuesta Blanca",
            "Curuzú Cuatiá",
            "Cutral-Có",
            "Dalmacio Vélez Sársfield",
            "Darwin",
            "Deán Funes",
            "Del Campillo",
            "Departamento Capital",
            "Departamento de Aguirre",
            "Departamento de Albardón",
            "Departamento de Almirante Brown",
            "Departamento de Aluminé",
            "Departamento de Ambato",
            "Departamento de Ancasti",
            "Departamento de Andalgalá",
            "Departamento de Añelo",
            "Departamento de Angaco",
            "Departamento de Anta",
            "Departamento de Antofagasta de la Sierra",
            "Departamento de Apóstoles",
            "Departamento de Arauco",
            "Departamento de Avellaneda",
            "Departamento de Banda",
            "Departamento de Belgrano",
            "Departamento de Bella Vista",
            "Departamento de Bermejo",
            "Departamento de Berón de Astrada",
            "Departamento de Biedma",
            "Departamento de Burruyacú",
            "Departamento de Cainguás",
            "Departamento de Calamuchita",
            "Departamento de Caleu-Caleu",
            "Departamento de Calingasta",
            "Departamento de Candelaria",
            "Departamento de Capayán",
            "Departamento de Capital",
            "Departamento de Capital",
            "Departamento de Capital",
            "Departamento de Capital",
            "Departamento de Capital",
            "Departamento de Capital",
            "Departamento de Caseros",
            "Departamento de Castellanos",
            "Departamento de Catán-Lil",
            "Departamento de Caucete",
            "Departamento de Cerrillos",
            "Departamento de Chicoana",
            "Departamento de Chimbas",
            "Departamento de Chos-Malal",
            "Departamento de Choya",
            "Departamento de Cochinoca",
            "Departamento de Collón-Curá",
            "Departamento de Colón",
            "Departamento de Comandante Fernández",
            "Departamento de Concepción",
            "Departamento de Concepción de la Sierra",
            "Departamento de Confluencia",
            "Departamento de Constitución",
            "Departamento de Cruz Alta",
            "Departamento de Cruz del Eje",
            "Departamento de Curuzú Cuatiá",
            "Departamento de Cushamen",
            "Departamento de Deseado",
            "Departamento de Doce de Octubre",
            "Departamento de Dos de Abril",
            "Departamento de El Alto",
            "Departamento de Eldorado",
            "Departamento de Empedrado",
            "Departamento de Escalante",
            "Departamento de Esquina",
            "Departamento de Famaillá",
            "Departamento de Florentino Ameghino",
            "Departamento de Fray Mamerto Esquiú",
            "Departamento de Futaleufú",
            "Departamento de Gaimán",
            "Departamento de Gastre",
            "Departamento de General Alvear",
            "Departamento de General Alvear",
            "Departamento de General Donovan",
            "Departamento de General Güemes",
            "Departamento de General Güemes",
            "Departamento de General Lamadrid",
            "Departamento de General Manuel Belgrano",
            "Departamento de General Paz",
            "Departamento de General Roca",
            "Departamento de General San Martín",
            "Departamento de Godoy Cruz",
            "Departamento de Goya",
            "Departamento de Graneros",
            "Departamento de Guachipas",
            "Departamento de Gualeguaychú",
            "Departamento de Guaraní",
            "Departamento de Guasayán",
            "Departamento de Guaymallén",
            "Departamento de Güer Aike",
            "Departamento de Iglesia",
            "Departamento de Iguazú",
            "Departamento de Independencia",
            "Departamento de Independencia",
            "Departamento de Iruya",
            "Departamento de Itatí",
            "Departamento de Ituzaingó",
            "Departamento de Jáchal",
            "Departamento de Juárez Celman",
            "Departamento de La Capital",
            "Departamento de La Cocha",
            "Departamento de La Paz",
            "Departamento de La Paz",
            "Departamento de La Poma",
            "Departamento de La Viña",
            "Departamento de Lácar",
            "Departamento de Lago Argentino",
            "Departamento de Languiñeo",
            "Departamento de Las Heras",
            "Departamento de Lavalle",
            "Departamento de Lavalle",
            "Departamento de Leandro N. Alem",
            "Departamento de Libertad",
            "Departamento de Libertador General San Martín",
            "Departamento de Loreto",
            "Departamento de Los Andes",
            "Departamento de Luján",
            "Departamento de Lules",
            "Departamento de Magallanes",
            "Departamento de Maipú",
            "Departamento de Maipú",
            "Departamento de Malargüe",
            "Departamento de Marcos Juárez",
            "Departamento de Mártires",
            "Departamento de Mburucuyá",
            "Departamento de Mercedes",
            "Departamento de Metán",
            "Departamento de Minas",
            "Departamento de Minas",
            "Departamento de Monte Caseros",
            "Departamento de Montecarlo",
            "Departamento de Monteros",
            "Departamento de Moreno",
            "Departamento de Nueve de Julio",
            "Departamento de Nueve de Julio",
            "Departamento de Nueve de Julio",
            "Departamento de O’Higgins",
            "Departamento de Oberá",
            "Departamento de Paraná",
            "Departamento de Paso de Indios",
            "Departamento de Paso de los Libres",
            "Departamento de Pilcomayo",
            "Departamento de Pomán",
            "Departamento de Presidencia de la Plaza",
            "Departamento de Presidente Roque Sáenz Peña",
            "Departamento de Quitilipi",
            "Departamento de Rawson",
            "Departamento de Rawson",
            "Departamento de Rinconada",
            "Departamento de Río Chico",
            "Departamento de Río Chico",
            "Departamento de Río Cuarto",
            "Departamento de Río Hondo",
            "Departamento de Río Primero",
            "Departamento de Río Seco",
            "Departamento de Río Segundo",
            "Departamento de Río Senguerr",
            "Departamento de Rivadavia",
            "Departamento de Rivadavia",
            "Departamento de Rivadavia",
            "Departamento de Robles",
            "Departamento de Rosario de la Frontera",
            "Departamento de Rosario de Lerma",
            "Departamento de Saladas",
            "Departamento de San Alberto",
            "Departamento de San Carlos",
            "Departamento de San Carlos",
            "Departamento de San Cosme",
            "Departamento de San Cristóbal",
            "Departamento de San Fernando",
            "Departamento de San Ignacio",
            "Departamento de San Javier",
            "Departamento de San Javier",
            "Departamento de San Javier",
            "Departamento de San Justo",
            "Departamento de San Justo",
            "Departamento de San Lorenzo",
            "Departamento de San Lorenzo",
            "Departamento de San Luis del Palmar",
            "Departamento de San Martín",
            "Departamento de San Martín",
            "Departamento de San Martín",
            "Departamento de San Martín",
            "Departamento de San Martín",
            "Departamento de San Miguel",
            "Departamento de San Pedro",
            "Departamento de San Rafael",
            "Departamento de San Roque",
            "Departamento de Santa Lucía",
            "Departamento de Santa María",
            "Departamento de Santa Rosa",
            "Departamento de Santa Rosa",
            "Departamento de Santo Tomé",
            "Departamento de Sargento Cabral",
            "Departamento de Sarmiento",
            "Departamento de Sarmiento",
            "Departamento de Sarmiento",
            "Departamento de Sauce",
            "Departamento de Simoca",
            "Departamento de Sobremonte",
            "Departamento de Tapenagá",
            "Departamento de Tehuelches",
            "Departamento de Telsen",
            "Departamento de Tinogasta",
            "Departamento de Toay",
            "Departamento de Totoral",
            "Departamento de Trancas",
            "Departamento de Tulumba",
            "Departamento de Tumbaya",
            "Departamento de Tunuyán",
            "Departamento de Tupungato",
            "Departamento de Ullúm",
            "Departamento de Unión",
            "Departamento de Valle Viejo",
            "Departamento de Veinticinco de Mayo",
            "Departamento de Veinticinco de Mayo",
            "Departamento de Vera",
            "Departamento de Yerba Buena",
            "Departamento de Zapala",
            "Departamento de Zonda",
            "Despeñaderos",
            "Devoto",
            "Diamante",
            "Doblas",
            "Dolavón",
            "Domínguez",
            "Dos Arroyos",
            "Dos de Mayo",
            "Eduardo Castex",
            "El Aguilar",
            "El Alcázar",
            "El Arañado",
            "El Bolsón",
            "El Calafate",
            "El Carril",
            "El Colorado",
            "El Cuy",
            "El Galpón",
            "El Hoyo",
            "El Huecú",
            "El Maitén",
            "El Quebrachal",
            "El Rodeo",
            "El Soberbio",
            "El Tío",
            "El Trébol",
            "Elena",
            "Embajador Martini",
            "Embalse",
            "Embarcación",
            "Empedrado",
            "Esperanza",
            "Esquel",
            "Esquina",
            "Estanislao del Campo",
            "Etruria",
            "Famaillá",
            "Federación",
            "Federal",
            "Felipe Yofré",
            "Fiambalá",
            "Firmat",
            "Florentino Ameghino",
            "Fontana",
            "Formosa",
            "Fraile Pintado",
            "Fray Luis A. Beltrán",
            "Fray Luis Beltrán",
            "Funes",
            "Gaimán",
            "Gálvez",
            "Gancedo",
            "Garruchos",
            "Garuhapé",
            "Garupá",
            "Gastre",
            "Gato Colorado",
            "General Acha",
            "General Alvear",
            "General Baldissera",
            "General Cabrera",
            "General Campos",
            "General Conesa",
            "General Enrique Godoy",
            "General Enrique Mosconi",
            "General Enrique Mosconi",
            "General Fernández Oro",
            "General Galarza",
            "General José de San Martín",
            "General Levalle",
            "General Manuel J. Campos",
            "General Pico",
            "General Pinedo",
            "General Ramírez",
            "General Roca",
            "General Roca",
            "General San Martín",
            "General Vedia",
            "Gobernador Costa",
            "Gobernador Gálvez",
            "Gobernador Gregores",
            "Gobernador Juan E. Martínez",
            "Gobernador Mansilla",
            "Gobernador Roca",
            "Gobernador Virasora",
            "Godoy Cruz",
            "Goya",
            "Granadero Baigorria",
            "Graneros",
            "Gualeguay",
            "Gualeguaychú",
            "Guaraní",
            "Guatimozín",
            "Guatraché",
            "Hasenkamp",
            "Helvecia",
            "Herlitzka",
            "Hermoso Campo",
            "Hernández",
            "Hernando",
            "Herradura",
            "Herrera",
            "Hersilia",
            "Hoyo de Epuyén",
            "Hualfín",
            "Huanchillas",
            "Huerta Grande",
            "Huillapima",
            "Huinca Renancó",
            "Humahuaca",
            "Ibarreta",
            "Icaño",
            "Idiazábal",
            "Ingeniero Guillermo N. Juárez",
            "Ingeniero Jacobacci",
            "Ingeniero Luiggi",
            "Ingeniero Luis A. Huergo",
            "Ingenio La Esperanza",
            "Inriville",
            "Intendente Alvear",
            "Iriondo Department",
            "Isla Verde",
            "Itá Ibaté",
            "Italó",
            "Itatí",
            "Ituzaingó",
            "Jacinto Arauz",
            "James Craik",
            "Jardín América",
            "Jesús María",
            "Joaquín V. González",
            "José de San Martín",
            "Juan Martín de Pueyrredón",
            "Juan Pujol",
            "Junín de los Andes",
            "Justiniano Posse",
            "Justo Daract",
            "La Adela",
            "La Banda",
            "La Caldera",
            "La Calera",
            "La Carlota",
            "La Cesira",
            "La Clotilde",
            "La Cocha",
            "La Criolla",
            "La Cruz",
            "La Cumbre",
            "La Eduvigis",
            "La Escondida",
            "La Falda",
            "La Francia",
            "La Granja",
            "La Leonesa",
            "La Maruja",
            "La Mendieta",
            "La Para",
            "La Paz",
            "La Playosa",
            "La Puerta de San José",
            "La Punta",
            "La Quiaca",
            "La Rioja",
            "La Tigra",
            "La Toma",
            "La Verde",
            "Laborde",
            "Laboulaye",
            "Lago Puelo",
            "Laguna Larga",
            "Laguna Limpia",
            "Laguna Naick-Neck",
            "Laguna Paiva",
            "Laguna Yema",
            "Lamarque",
            "Lapachito",
            "Larroque",
            "Las Acequias",
            "Las Breñas",
            "Las Coloradas",
            "Las Garcitas",
            "Las Heras",
            "Las Heras",
            "Las Higueras",
            "Las Junturas",
            "Las Lajas",
            "Las Lajitas",
            "Las Lomitas",
            "Las Ovejas",
            "Las Parejas",
            "Las Perdices",
            "Las Plumas",
            "Las Rosas",
            "Las Toscas",
            "Las Varas",
            "Las Varillas",
            "Leones",
            "Libertad",
            "Libertador General San Martín",
            "Lomas de Vallejos",
            "Loncopué",
            "Londres",
            "Lonquimay",
            "Loreto",
            "Loreto",
            "Los Altos",
            "Los Antiguos",
            "Los Charrúas",
            "Los Cocos",
            "Los Cóndores",
            "Los Conquistadores",
            "Los Frentones",
            "Los Helechos",
            "Los Juríes",
            "Los Laureles",
            "Los Menucos",
            "Los Surgentes",
            "Los Telares",
            "Los Varela",
            "Lucas González",
            "Luján",
            "Macachín",
            "Machagai",
            "Maciá",
            "Maimará",
            "Mainque",
            "Makallé",
            "Malabrigo",
            "Malagueño",
            "Malvinas Argentinas",
            "Maquinchao",
            "Marcos Juárez",
            "Margarita Belén",
            "Mariano I. Loza",
            "Mariano Moreno",
            "Mártires",
            "Marull",
            "Mattaldi",
            "Mburucuyá",
            "Melincué",
            "Mendiolaza",
            "Mendoza",
            "Mercedes",
            "Merlo",
            "Miguel Riglos",
            "Mina Clavero",
            "Miramar",
            "Mocoretá",
            "Mojón Grande",
            "Monte Buey",
            "Monte Caseros",
            "Monte Cristo",
            "Monte Maíz",
            "Montecarlo",
            "Monteros",
            "Morrison",
            "Morteros",
            "Mutquín",
            "Napenay",
            "Naschel",
            "Neuquén",
            "Noetinger",
            "Nogoyá",
            "Ñorquinco",
            "Nuestra Señora del Rosario de Caa Catí",
            "Nueve de Julio",
            "Nueve de Julio",
            "Oberá",
            "Obispo Trejo",
            "Oliva",
            "Oncativo",
            "Ordóñez",
            "Oro Verde",
            "Palma Sola",
            "Palmar Grande",
            "Palo Santo",
            "Palpalá",
            "Pampa Almirón",
            "Pampa de los Guanacos",
            "Pampa del Indio",
            "Pampa del Infierno",
            "Panambí",
            "Paraná",
            "Parera",
            "Pascanas",
            "Pasco",
            "Paso de la Patria",
            "Paso de los Libres",
            "Pedro R. Fernández",
            "Pérez",
            "Perito Moreno",
            "Perugorría",
            "Picada Gobernador López",
            "Pico Truncado",
            "Picún Leufú",
            "Piedra del Águila",
            "Piedras Blancas",
            "Pilar",
            "Pilcaniyeu",
            "Piquillín",
            "Pirané",
            "Plaza Huincul",
            "Plottier",
            "Pocito",
            "Pomán",
            "Porteña",
            "Posadas",
            "Pozo del Molle",
            "Pozo del Tigre",
            "Presidencia de la Plaza",
            "Presidencia Roca",
            "Presidencia Roque Sáenz Peña",
            "Pronunciamiento",
            "Pueblo Libertador",
            "Puerta de Corral Quemado",
            "Puerto Bermejo",
            "Puerto Deseado",
            "Puerto Eldorado",
            "Puerto Esperanza",
            "Puerto Ibicuy",
            "Puerto Iguazú",
            "Puerto Leoni",
            "Puerto Libertad",
            "Puerto Madryn",
            "Puerto Piray",
            "Puerto Rico",
            "Puerto Santa Cruz",
            "Puerto Tirol",
            "Puerto Vilelas",
            "Puerto Yeruá",
            "Quemú Quemú",
            "Quilino",
            "Quimilí",
            "Quitilipi",
            "Rada Tilly",
            "Rafaela",
            "Rancul",
            "Rawson",
            "Realicó",
            "Reconquista",
            "Recreo",
            "Recreo",
            "Resistencia",
            "Retiro",
            "Riacho Eh-Eh",
            "Riachuelo",
            "Río Ceballos",
            "Río Colorado",
            "Río Cuarto",
            "Río Gallegos",
            "Río Grande",
            "Río Mayo",
            "Río Pico",
            "Río Segundo",
            "Río Tercero",
            "Río Turbio",
            "Roldán",
            "Rosario",
            "Rosario del Tala",
            "Rufino",
            "Ruiz de Montoya",
            "Sacanta",
            "Saladas",
            "Saldán",
            "Salsacate",
            "Salsipuedes",
            "Salta",
            "Sampacho",
            "Samuhú",
            "San Agustín",
            "San Agustín de Valle Fértil",
            "San Antonio",
            "San Antonio de Litín",
            "San Antonio de los Cobres",
            "San Antonio Oeste",
            "San Basilio",
            "San Benito",
            "San Bernardo",
            "San Carlos",
            "San Carlos",
            "San Carlos Centro",
            "San Carlos de Bariloche",
            "San Cosme",
            "San Cristóbal",
            "San Fernando del Valle de Catamarca",
            "San Francisco",
            "San Francisco de Laishí",
            "San Francisco del Chañar",
            "San Francisco del Monte de Oro",
            "San Gustavo",
            "San Javier",
            "San Jorge",
            "San José",
            "San José de Feliciano",
            "San José de Jáchal",
            "San José de la Dormida",
            "San Juan",
            "San Julián",
            "San Justo",
            "San Justo",
            "San Lorenzo",
            "San Luis",
            "San Luis del Palmar",
            "San Martín",
            "San Martín",
            "San Martín de los Andes",
            "San Miguel",
            "San Miguel de Tucumán",
            "San Pedro",
            "San Pedro",
            "San Pedro de Jujuy",
            "San Rafael",
            "San Ramón de la Nueva Orán",
            "San Salvador",
            "San Salvador de Jujuy",
            "San Vicente",
            "Santa Ana",
            "Santa Anita",
            "Santa Clara",
            "Santa Elena",
            "Santa Eufemia",
            "Santa Fe",
            "Santa Isabel",
            "Santa Lucía",
            "Santa Lucía",
            "Santa Magdalena",
            "Santa María",
            "Santa María",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa de Calamuchita",
            "Santa Rosa de Río Primero",
            "Santa Rosa de Tastil",
            "Santa Rosa del Conlara",
            "Santa Sylvina",
            "Santiago del Estero",
            "Santiago Temple",
            "Santo Pipó",
            "Santo Tomé",
            "Santo Tomé",
            "Sarmiento",
            "Sastre",
            "Saturnino M. Laspiur",
            "Sauce de Luna",
            "Sebastián Elcano",
            "Seguí",
            "Senillosa",
            "Serrano",
            "Serrezuela",
            "Sierra Colorada",
            "Sierra Grande",
            "Simoca",
            "Sumampa",
            "Sunchales",
            "Suncho Corral",
            "Tabossi",
            "Taco Pozo",
            "Tacuarendí",
            "Tafí del Valle",
            "Tafí Viejo",
            "Tancacha",
            "Tartagal",
            "Tecka",
            "Telén",
            "Termas de Río Hondo",
            "Ticino",
            "Tilcara",
            "Tilisarao",
            "Tinogasta",
            "Tintina",
            "Tío Pujio",
            "Toledo",
            "Tolhuin",
            "Tostado",
            "Totoras",
            "Trancas",
            "Trelew",
            "Trenel",
            "Tres Capones",
            "Tres Isletas",
            "Trevelin",
            "Ubajay",
            "Ucacha",
            "Unión",
            "Unquillo",
            "Urdinarrain",
            "Uriburu",
            "Ushuaia",
            "Valcheta",
            "Valle Hermoso",
            "Veinticinco de Mayo",
            "Veinticinco de Mayo",
            "Venado Tuerto",
            "Vera",
            "Viale",
            "Viamonte",
            "Victoria",
            "Victorica",
            "Vicuña Mackenna",
            "Viedma",
            "Villa Allende",
            "Villa Ángela",
            "Villa Ascasubi",
            "Villa Atamisqui",
            "Villa Basilio Nievas",
            "Villa Berna",
            "Villa Berthet",
            "Villa Bustos",
            "Villa Cañás",
            "Villa Carlos Paz",
            "Villa Concepción del Tío",
            "Villa Constitución",
            "Villa Cura Brochero",
            "Villa de Soto",
            "Villa del Dique",
            "Villa del Rosario",
            "Villa del Rosario",
            "Villa del Totoral",
            "Villa Dolores",
            "Villa Elisa",
            "Villa Escolar",
            "Villa General Belgrano",
            "Villa General Guemes",
            "Villa General Mitre",
            "Villa General Roca",
            "Villa Giardino",
            "Villa Hernandarias",
            "Villa Huidobro",
            "Villa La Angostura",
            "Villa Las Rosas",
            "Villa Lugano",
            "Villa Mantero",
            "Villa María",
            "Villa María Grande",
            "Villa Mercedes",
            "Villa Mugueta",
            "Villa Nueva",
            "Villa Ocampo",
            "Villa Ojo de Agua",
            "Villa Ortúzar",
            "Villa Paranacito",
            "Villa Paula de Sarmiento",
            "Villa Reducción",
            "Villa Regina",
            "Villa Rumipal",
            "Villa Santa Rita",
            "Villa Trinidad",
            "Villa Tulumba",
            "Villa Urquiza",
            "Villa Valeria",
            "Villaguay",
            "Vinchina",
            "Vista Alegre",
            "Wanda",
            "Wenceslao Escalante",
            "Winifreda",
            "Yacimiento Río Turbio",
            "Yapeyú",
            "Yataity Calle",
            "Yerba Buena",
            "Yuto",
            "Zapala"
        ]
    },
    {
        "label": "Armenia",
        "code": "AM",
        "phone": "374",
        "cities": [
            "Abovyan",
            "Abovyan",
            "Agarak",
            "Agarak",
            "Agarakadzor",
            "Agarakavan",
            "Aghavnadzor",
            "Aghavnadzor",
            "Aghavnatun",
            "Akht’ala",
            "Akhuryan",
            "Aknalich",
            "Aknashen",
            "Akner",
            "Akunk’",
            "Akunk’",
            "Alashkert",
            "Alaverdi",
            "Amasia",
            "Angeghakot’",
            "Anushavan",
            "Apaga",
            "Aparan",
            "Arabkir",
            "Aragats",
            "Arak’s",
            "Aralez",
            "Aramus",
            "Ararat",
            "Arazap’",
            "Arbat’",
            "Archis",
            "Areni",
            "Arevabuyr",
            "Arevashat",
            "Arevashogh",
            "Arevik",
            "Arevik",
            "Arevshat",
            "Arevshat",
            "Argavand",
            "Argavand",
            "Argel",
            "Armash",
            "Armavir",
            "Arrap’i",
            "Arshaluys",
            "Artashat",
            "Arteni",
            "Artimet",
            "Artsvaberd",
            "Arzakan",
            "Arzni",
            "Ashnak",
            "Ashtarak",
            "Astghadzor",
            "Avshar",
            "Aygavan",
            "Aygehovit",
            "Aygek",
            "Aygepat",
            "Aygeshat",
            "Aygestan",
            "Aygezard",
            "Azatamut",
            "Azatan",
            "Baghramyan",
            "Bagratashen",
            "Balahovit",
            "Bambakashat",
            "Bardzrashen",
            "Basen",
            "Bazum",
            "Berd",
            "Berdavan",
            "Berk’anush",
            "Bjni",
            "Brrnakot’",
            "Burastan",
            "Buzhakan",
            "Byurakan",
            "Byuravan",
            "Byureghavan",
            "Chambarak",
            "Chochkan",
            "Dalar",
            "Dalarik",
            "Darakert",
            "Darpas",
            "Dashtavan",
            "Ddmashen",
            "Dilijan",
            "Dimitrov",
            "Doghs",
            "Drakhtik",
            "Dsegh",
            "Dvin",
            "Dzit’hank’ov",
            "Dzoraghbyur",
            "Dzoragyugh",
            "Dzorastan",
            "Fantan",
            "Fioletovo",
            "Gagarin",
            "Gandzak",
            "Garrni",
            "Gavarr",
            "Gay",
            "Geghakert",
            "Geghamasar",
            "Geghamavan",
            "Geghanist",
            "Getap’",
            "Getashen",
            "Getazat",
            "Ghukasavan",
            "Gladzor",
            "Gmbet’",
            "Gogaran",
            "Goght’",
            "Goravan",
            "Goris",
            "Griboyedov",
            "Gugark’",
            "Gyulagarak",
            "Gyumri",
            "Haghartsin",
            "Hats’avan",
            "Hayanist",
            "Haykashen",
            "Haykavan",
            "Hnaberd",
            "Horrom",
            "Hovtamej",
            "Hovtashat",
            "Hovtashen",
            "Hrazdan",
            "Ijevan",
            "Janfida",
            "Jermuk",
            "Jrahovit",
            "Jrashen",
            "Jrashen",
            "K’anak’erravan",
            "Kamo",
            "Kapan",
            "Kaputan",
            "Karanlukh",
            "Karbi",
            "Karchaghbyur",
            "Kasakh",
            "Khasht’arrak",
            "Khndzoresk",
            "Khoronk’",
            "Kosh",
            "Kotayk’",
            "Lanjaghbyur",
            "Lchap’",
            "Lchashen",
            "Lenughi",
            "Lerrnakert",
            "Lerrnanist",
            "Lerrnants’k’",
            "Lerrnapat",
            "Lerrnavan",
            "Lichk’",
            "Lorut",
            "Lukashin",
            "Lusarrat",
            "Madina",
            "Malishka",
            "Maralik",
            "Margahovit",
            "Margara",
            "Marmarashen",
            "Marmashen",
            "Martuni",
            "Masis",
            "Mayakovski",
            "Mayisyan",
            "Mayisyan",
            "Meghradzor",
            "Meghrashen",
            "Meghri",
            "Merdzavan",
            "Mets Mant’ash",
            "Mets Masrik",
            "Mets Parni",
            "Metsamor",
            "Metsavan",
            "Mosesgegh",
            "Mrganush",
            "Mrgashat",
            "Mrgashen",
            "Mrgavan",
            "Mrgavet",
            "Musalerr",
            "Myasnikyan",
            "Nalbandyan",
            "Navur",
            "Nerk’in Getashen",
            "Nizami",
            "Nor Armavir",
            "Nor Geghi",
            "Nor Gyugh",
            "Nor Yerznka",
            "Norabats’",
            "Norakert",
            "Noramarg",
            "Norashen",
            "Norashen",
            "Noratus",
            "Noyakert",
            "Noyemberyan",
            "Nshavan",
            "Odzun",
            "Oshakan",
            "P’ok’r Mant’ash",
            "P’shatavan",
            "Parravak’ar",
            "Pemzashen",
            "Prroshyan",
            "Ptghni",
            "Ptghunk’",
            "Rrind",
            "Sarahart’",
            "Saramej",
            "Saratak",
            "Sardarapat",
            "Sarigyugh",
            "Sarukhan",
            "Sasunik",
            "Sayat’-Nova",
            "Sevan",
            "Shaghat",
            "Shahumyan",
            "Shahumyan",
            "Shatin",
            "Shenavan",
            "Shenavan",
            "Shinuhayr",
            "Shirak",
            "Shirakamut",
            "Shnogh",
            "Sis",
            "Sisavan",
            "Solak",
            "Spandaryan",
            "Spitak",
            "Step’anavan",
            "Surenavan",
            "T’alin",
            "Tandzut",
            "Taronik",
            "Tashir",
            "Tegh",
            "Tsaghkaber",
            "Tsaghkadzor",
            "Tsaghkahovit",
            "Tsaghkunk’",
            "Tsiatsan",
            "Tsovagyugh",
            "Tsovak",
            "Tsovasar",
            "Tsovazard",
            "Tsovinar",
            "Urrut",
            "Ushi",
            "Vagharshapat",
            "Vaghashen",
            "Vahagni",
            "Vahan",
            "Vanadzor",
            "Vardablur",
            "Vardadzor",
            "Vardenik",
            "Vardenis",
            "Varser",
            "Vayk’",
            "Vedi",
            "Verin Artashat",
            "Verin Dvin",
            "Verin Getashen",
            "Verishen",
            "Vernashen",
            "Voskehask",
            "Voskehat",
            "Vosketap’",
            "Voskevan",
            "Voskevaz",
            "Vostan",
            "Yeghegis",
            "Yeghegnadzor",
            "Yeghegnavan",
            "Yeghegnut",
            "Yeghegnut",
            "Yeghvard",
            "Yeranos",
            "Yeraskhahun",
            "Yerazgavors",
            "Yerevan",
            "Zangakatun",
            "Zarr",
            "Zarrit’ap’",
            "Zorak",
            "Zoravan",
            "Zovaber",
            "Zovuni"
        ]
    },
    {
        "label": "Aruba",
        "code": "AW",
        "phone": "297",
        "cities": []
    },
    {
        "label": "Australia",
        "code": "AU",
        "phone": "61",
        "cities": [
            "Abbey",
            "Abbotsbury",
            "Abbotsford",
            "Abbotsford",
            "Abercrombie",
            "Aberdare",
            "Aberdeen",
            "Aberfeldie",
            "Aberfoyle Park",
            "Aberglasslyn",
            "Abermain",
            "Acacia Gardens",
            "Acacia Ridge",
            "Acton",
            "Acton Park",
            "Adamstown",
            "Adamstown Heights",
            "Adelaide",
            "Adelaide city centre",
            "Adelaide Hills",
            "Agnes Water",
            "Ainslie",
            "Airds",
            "Airlie Beach",
            "Airport West",
            "Aitkenvale",
            "Alawa",
            "Albanvale",
            "Albany",
            "Albany city centre",
            "Albany Creek",
            "Albert Park",
            "Albert Park",
            "Alberton",
            "Albion",
            "Albion Park",
            "Albion Park Rail",
            "Albury",
            "Albury Municipality",
            "Aldavilla",
            "Alderley",
            "Aldershot",
            "Aldgate",
            "Aldinga Beach",
            "Alexander Heights",
            "Alexandra",
            "Alexandra Headland",
            "Alexandra Hills",
            "Alexandria",
            "Alexandrina",
            "Alfords Point",
            "Alfred Cove",
            "Alfredton",
            "Algester",
            "Alice River",
            "Alice Springs",
            "Alkimos",
            "Allambie Heights",
            "Allansford",
            "Allawah",
            "Allenby Gardens",
            "Allenstown",
            "Alligator Creek",
            "Allora",
            "Alphington",
            "Alpine",
            "Alstonville",
            "Alton Downs",
            "Altona",
            "Altona Meadows",
            "Altona North",
            "Alyangula",
            "Amaroo",
            "Ambarvale",
            "Anangu Pitjantjatjara",
            "Andergrove",
            "Andrews Farm",
            "Angaston",
            "Angle Park",
            "Angle Vale",
            "Anglesea",
            "Anna Bay",
            "Annandale",
            "Annandale",
            "Annangrove",
            "Annerley",
            "Anstead",
            "Anula",
            "Apollo Bay",
            "Appin",
            "Applecross",
            "Araluen",
            "Arana Hills",
            "Aranda",
            "Ararat",
            "Arcadia",
            "Arcadia vale",
            "Ardeer",
            "Ardross",
            "Ardrossan",
            "Argenton",
            "Armadale",
            "Armadale",
            "Armidale",
            "Armstrong Creek",
            "Arncliffe",
            "Aroona",
            "Artarmon",
            "Arundel",
            "Ascot",
            "Ascot",
            "Ascot",
            "Ascot Park",
            "Ascot Vale",
            "Ashburton",
            "Ashburton",
            "Ashbury",
            "Ashby",
            "Ashcroft",
            "Ashfield",
            "Ashfield",
            "Ashford",
            "Ashgrove",
            "Ashmont",
            "Ashmore",
            "Ashtonfield",
            "Ashwood",
            "Aspendale",
            "Aspendale Gardens",
            "Aspley",
            "Asquith",
            "Athelstone",
            "Atherton",
            "Athol Park",
            "Attadale",
            "Attwood",
            "Atwell",
            "Aubin Grove",
            "Auburn",
            "Auchenflower",
            "Augusta",
            "Augusta-Margaret River Shire",
            "Augustine Heights",
            "Aurukun",
            "Austinmer",
            "Austins Ferry",
            "Austral",
            "Australind",
            "Aveley",
            "Avenel",
            "Avenell Heights",
            "Avoca",
            "Avoca",
            "Avoca Beach",
            "Avondale",
            "Avondale Heights",
            "Ayr",
            "Babinda",
            "Bacchus Marsh",
            "Badger Creek",
            "Badgingarra",
            "Bagdad",
            "Bahrs Scrub",
            "Bairnsdale",
            "Bakers Creek",
            "Bakers Hill",
            "Bakewell",
            "Balaclava",
            "Balaklava",
            "Balcatta",
            "Bald Hills",
            "Baldivis",
            "Balga",
            "Balgowlah",
            "Balgowlah Heights",
            "Balgownie",
            "Balhannah",
            "Ballajura",
            "Ballan",
            "Ballarat",
            "Ballarat Central",
            "Ballarat East",
            "Ballarat North",
            "Ballina",
            "Balmain",
            "Balmain East",
            "Balmoral",
            "Balnarring",
            "Balonne Shire",
            "Balranald",
            "Balwyn",
            "Balwyn North",
            "Bamaga",
            "Banana",
            "Bangalow",
            "Bangor",
            "Banjup",
            "Banks",
            "Banksia",
            "Banksia Beach",
            "Banksia Grove",
            "Banksia Park",
            "Banksmeadow",
            "Bankstown",
            "Bannockburn",
            "Banora Point",
            "Banyo",
            "Banyule",
            "Bar Beach",
            "Baranduda",
            "Barcaldine",
            "Barcoo",
            "Barden Ridge",
            "Bardia",
            "Bardon",
            "Bardwell Park",
            "Bardwell Valley",
            "Barellan Point",
            "Bargara",
            "Bargo",
            "Barham",
            "Barkly",
            "Barmera",
            "Barney Point",
            "Barnsley",
            "Barooga",
            "Barossa",
            "Barraba",
            "Barrack Heights",
            "Barton",
            "Barunga West",
            "Barwon Heads",
            "Basin View",
            "Bass Coast",
            "Bass Hill",
            "Bassendean",
            "Bateau Bay",
            "Batehaven",
            "Bateman",
            "Batemans Bay",
            "Bathurst",
            "Bathurst city centre",
            "Bathurst Regional",
            "Batlow",
            "Battery Hill",
            "Battery Point",
            "Baulkham Hills",
            "Baw Baw",
            "Baxter",
            "Bay View",
            "Baynton",
            "Bayonet Head",
            "Bayside",
            "Bayswater",
            "Bayswater",
            "Bayswater North",
            "Bayview Heights",
            "Beachlands",
            "Beachmere",
            "Beacon Hill",
            "Beaconsfield",
            "Beaconsfield",
            "Beaconsfield",
            "Beaconsfield",
            "Beaconsfield Upper",
            "Beaudesert",
            "Beaufort",
            "Beaumaris",
            "Beaumont",
            "Beaumont Hills",
            "Beauty Point",
            "Beckenham",
            "Bedford",
            "Bedford Park",
            "Bedfordale",
            "Beechboro",
            "Beechworth",
            "Beecroft",
            "Beeliar",
            "Beenleigh",
            "Beerwah",
            "Bega",
            "Bega Valley",
            "Belair",
            "Belconnen",
            "Beldon",
            "Belfield",
            "Belgian Gardens",
            "Belgrave",
            "Belgrave Heights",
            "Belgrave South",
            "Bell Park",
            "Bell Post Hill",
            "Bella Vista",
            "Bellamack",
            "Bellambi",
            "Bellara",
            "Bellbird",
            "Bellbird Park",
            "Bellbowrie",
            "Bellerive",
            "Bellevue",
            "Bellevue Heights",
            "Bellevue Hill",
            "Bellfield",
            "Bellingen",
            "Bellmere",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont North",
            "Belmont South",
            "Belmore",
            "Belrose",
            "Belyuen",
            "Benalla",
            "Benaraby",
            "Bendigo",
            "Bendigo city centre",
            "Bennett Springs",
            "Benowa",
            "Bensville",
            "Bentleigh",
            "Bentleigh East",
            "Bentley",
            "Bentley Park",
            "Berala",
            "Beresfield",
            "Beresford",
            "Berkeley",
            "Berkeley Vale",
            "Berkshire Park",
            "Bermagui",
            "Berowra",
            "Berowra Heights",
            "Berri",
            "Berri and Barmera",
            "Berridale",
            "Berriedale",
            "Berrigan",
            "Berrimah",
            "Berrinba",
            "Berry",
            "Berserker",
            "Bertram",
            "Berwick",
            "Beulah Park",
            "Beveridge",
            "Beverley",
            "Beverley",
            "Beverley Park",
            "Beverly Hills",
            "Bexley",
            "Bexley North",
            "Bibra Lake",
            "Bicton",
            "Bidwill",
            "Biggera Waters",
            "Bilambil Heights",
            "Bilgola Plateau",
            "Bilinga",
            "Bilingurr",
            "Biloela",
            "Bindoon",
            "Bingara",
            "Binningup",
            "Birchgrove",
            "Birdwood",
            "Birkdale",
            "Birkenhead",
            "Birmingham Gardens",
            "Birrong",
            "Birtinya",
            "Bittern",
            "Black Forest",
            "Black Hill",
            "Black Mountain",
            "Black River",
            "Black Rock",
            "Blackall",
            "Blackall Tambo",
            "Blackalls Park",
            "Blackburn",
            "Blackburn North",
            "Blackburn South",
            "Blackbutt",
            "Blackett",
            "Blackheath",
            "Blackmans Bay",
            "Blacks Beach",
            "Blacksmiths",
            "Blackstone Heights",
            "Blacktown",
            "Blackwall",
            "Blackwater",
            "Blackwood",
            "Blair Athol",
            "Blair Athol",
            "Blairgowrie",
            "Blakehurst",
            "Blakeview",
            "Bland",
            "Blaxland",
            "Blayney",
            "Bli Bli",
            "Bligh Park",
            "Blind Bight",
            "Blue Bay",
            "Blue Haven",
            "Blue Mountains Municipality",
            "Bluewater",
            "Bluff Point",
            "Boambee",
            "Boambee East",
            "Boddington",
            "Bogan",
            "Bogangar",
            "Boggabri",
            "Bohle Plains",
            "Bokarina",
            "Bolton Point",
            "Bolwarra Heights",
            "Bomaderry",
            "Bombala",
            "Bonbeach",
            "Bondi",
            "Bondi Beach",
            "Bondi Junction",
            "Bongaree",
            "Bonnells Bay",
            "Bonner",
            "Bonnet Bay",
            "Bonny Hills",
            "Bonnyrigg",
            "Bonnyrigg Heights",
            "Bonogin",
            "Bonville",
            "Bonython",
            "Booie",
            "Booker Bay",
            "Boonah",
            "Boondall",
            "Booragoon",
            "Booragul",
            "Booral",
            "Boorowa",
            "Booval",
            "Bordertown",
            "Boronia",
            "Boronia Heights",
            "Boroondara",
            "Bossley Park",
            "Botanic Ridge",
            "Botany",
            "Botany Bay",
            "Boulder",
            "Bouldercombe",
            "Boulia",
            "Bourke",
            "Bourkelands",
            "Bow Bowing",
            "Bowen",
            "Bowen Hills",
            "Bowen Mountain",
            "Bowenfels",
            "Bowral",
            "Bowraville",
            "Box Hill",
            "Box Hill North",
            "Box Hill South",
            "Boyanup",
            "Boyne Island",
            "Boyup Brook",
            "Brabham",
            "Bracken Ridge",
            "Bradbury",
            "Braddon",
            "Brahma Lodge",
            "Braidwood",
            "Braitling",
            "Brandon",
            "Branxton",
            "Branyan",
            "Brassall",
            "Bray Park",
            "Braybrook",
            "Break O'Day",
            "Breakfast Point",
            "Brendale",
            "Brentwood",
            "Brewarrina",
            "Briagolong",
            "Briar Hill",
            "Bridgeman Downs",
            "Bridgetown",
            "Bridgetown-Greenbushes",
            "Bridgewater",
            "Bridgewater",
            "Bridport",
            "Bright",
            "Brighton",
            "Brighton",
            "Brighton",
            "Brighton",
            "Brighton East",
            "Brighton-Le-Sands",
            "Brimbank",
            "Bringelly",
            "Brinkin",
            "Brinsmead",
            "Brisbane",
            "Broadbeach",
            "Broadbeach Waters",
            "Broadford",
            "Broadmeadow",
            "Broadmeadows",
            "Broadview",
            "Broadwater",
            "Brockman",
            "Broken Hill",
            "Broken Hill Municipality",
            "Brompton",
            "Bronte",
            "Brookdale",
            "Brookfield",
            "Brookfield",
            "Brooklyn",
            "Brooklyn Park",
            "Brookton",
            "Brookvale",
            "Brookwater",
            "Broome",
            "Broomehill-Tambellup",
            "Broulee",
            "Brown Hill",
            "Bruce",
            "Bruce Rock",
            "Brunswick",
            "Brunswick",
            "Brunswick East",
            "Brunswick Heads",
            "Brunswick West",
            "Bucasia",
            "Bucca",
            "Buccan",
            "Buddina",
            "Buderim",
            "Budgewoi",
            "Buff Point",
            "Bulahdelah",
            "Bulgarra",
            "Bulimba",
            "Bull Creek",
            "Bullaburra",
            "Bulleen",
            "Bulli",
            "Bulloo",
            "Bullsbrook",
            "Buloke",
            "Bunbury",
            "Bundaberg",
            "Bundaberg East",
            "Bundaberg North",
            "Bundaberg South",
            "Bundaberg West",
            "Bundall",
            "Bundamba",
            "Bundanoon",
            "Bundeena",
            "Bundoora",
            "Bungalow",
            "Bungarribee",
            "Bungendore",
            "Buninyong",
            "Bunya",
            "Bunyip",
            "Burbank",
            "Burdekin",
            "Burdell",
            "Burke",
            "Burleigh Heads",
            "Burleigh Waters",
            "Burnett Heads",
            "Burnie",
            "Burns Beach",
            "Burnside",
            "Burnside",
            "Burnside",
            "Burnside Heights",
            "Buronga",
            "Burpengary",
            "Burpengary East",
            "Burra",
            "Burradoo",
            "Burraneer",
            "Burrill Lake",
            "Burrum Heads",
            "Burswood",
            "Burton",
            "Burwood",
            "Burwood",
            "Burwood East",
            "Busby",
            "Bushland Beach",
            "Busselton",
            "Busselton city cenre",
            "Butler",
            "Buttaba",
            "Buxton",
            "Byford",
            "Byron Bay",
            "Byron Shire",
            "Bywong",
            "Cabarita",
            "Cabarlah",
            "Cable Beach",
            "Cabonne",
            "Caboolture",
            "Caboolture South",
            "Cabramatta",
            "Cabramatta West",
            "Caddens",
            "Cairnlea",
            "Cairns",
            "Cairns City",
            "Cairns North",
            "Calala",
            "Calamvale",
            "California Gully",
            "Calista",
            "Callaghan",
            "Callala Bay",
            "Calliope",
            "Caloundra",
            "Caloundra West",
            "Calwell",
            "Camberwell",
            "Cambewarra Village",
            "Cambooya",
            "Cambridge",
            "Cambridge",
            "Cambridge Gardens",
            "Cambridge Park",
            "Camden",
            "Camden Haven",
            "Camden Park",
            "Camden South",
            "Cameron Park",
            "Camillo",
            "Camira",
            "Cammeray",
            "Camp Hill",
            "Camp Mountain",
            "Campaspe",
            "Campbell",
            "Campbellfield",
            "Campbells Creek",
            "Campbelltown",
            "Campbelltown",
            "Campbelltown Municipality",
            "Camperdown",
            "Camperdown",
            "Campsie",
            "Canada Bay",
            "Canadian",
            "Canberra",
            "Canley Heights",
            "Canley Vale",
            "Canning",
            "Canning Vale",
            "Cannington",
            "Cannon Hill",
            "Cannonvale",
            "Canowindra",
            "Canterbury",
            "Canterbury",
            "Canton Beach",
            "Canungra",
            "Capalaba",
            "Cape Woolamai",
            "Capel",
            "Capella",
            "Caravonica",
            "Carbrook",
            "Cardiff",
            "Cardiff Heights",
            "Cardiff South",
            "Cardinia",
            "Cardup",
            "Cardwell",
            "Carey Park",
            "Carina Heights",
            "Carindale",
            "Carine",
            "Caringbah",
            "Caringbah South",
            "Carisbrook",
            "Carlingford",
            "Carlisle",
            "Carlton",
            "Carlton",
            "Carlton North",
            "Carnamah",
            "Carnarvon",
            "Carnegie",
            "Carnes Hill",
            "Caroline Springs",
            "Carpentaria",
            "Carramar",
            "Carramar",
            "Carrara",
            "Carrathool",
            "Carrington",
            "Carrum",
            "Carrum Downs",
            "Carseldine",
            "Carss Park",
            "Cartwright",
            "Carwoola",
            "Casey",
            "Casey",
            "Cashmere",
            "Casino",
            "Cassowary Coast",
            "Casterton",
            "Castle Cove",
            "Castle Hill",
            "Castlecrag",
            "Castlemaine",
            "Castlereagh",
            "Castletown",
            "Casuarina",
            "Casuarina",
            "Casula",
            "Cataby",
            "Catalina",
            "Catherine Field",
            "Caulfield",
            "Caulfield East",
            "Caulfield North",
            "Caulfield South",
            "Caversham",
            "Caves Beach",
            "Cecil Hills",
            "Cedar Grove",
            "Cedar Vale",
            "Ceduna",
            "Centenary Heights",
            "Centennial Park",
            "Central Coast",
            "Central Darling",
            "Central Desert",
            "Central Goldfields",
            "Central Highlands",
            "Central Highlands",
            "Cervantes",
            "Cessnock",
            "Chadstone",
            "Chain Valley Bay",
            "Chambers Flat",
            "Champion Lakes",
            "Chandler",
            "Chapel Hill",
            "Chapman",
            "Chapman Valley",
            "Charles Sturt",
            "Charlestown",
            "Charleville",
            "Charlton",
            "Charmhaven",
            "Charnwood",
            "Charters Towers",
            "Charters Towers City",
            "Chatswood",
            "Chatswood West",
            "Chatsworth",
            "Chelmer",
            "Chelsea",
            "Chelsea Heights",
            "Cheltenham",
            "Cheltenham",
            "Cherbourg",
            "Chermside",
            "Chermside West",
            "Cherrybrook",
            "Chester Hill",
            "Chewton",
            "Chidlow",
            "Chifley",
            "Chifley",
            "Chigwell",
            "Childers",
            "Chiltern",
            "Chinchilla",
            "Chinderah",
            "Chippendale",
            "Chipping Norton",
            "Chirnside Park",
            "Chisholm",
            "Chiswick",
            "Chittaway Bay",
            "Chittering",
            "Christie Downs",
            "Christies Beach",
            "Churchill",
            "Churchill",
            "Churchlands",
            "Chuwar",
            "Circular Head",
            "City",
            "City Beach",
            "City of Cockburn",
            "City of Perth",
            "City of Sydney",
            "City of West Torrens",
            "Clapham",
            "Clare",
            "Clare and Gilbert Valleys",
            "Claremont",
            "Claremont",
            "Claremont Meadows",
            "Clarence",
            "Clarence Gardens",
            "Clarence Park",
            "Clarence Town",
            "Clarence Valley",
            "Clarendon Vale",
            "Clarinda",
            "Clarkson",
            "Clayfield",
            "Claymore",
            "Clayton",
            "Clayton South",
            "Clear Island Waters",
            "Clearview",
            "Clemton Park",
            "Clermont",
            "Cleve",
            "Cleveland",
            "Clifton",
            "Clifton Beach",
            "Clifton Hill",
            "Clifton Springs",
            "Clinton",
            "Cloncurry",
            "Clontarf",
            "Clontarf",
            "Clovelly",
            "Clovelly Park",
            "Cloverdale",
            "Clunes",
            "Clyde",
            "Clyde North",
            "Coal Point",
            "Cobar",
            "Cobbitty",
            "Cobden",
            "Cobram",
            "Coburg",
            "Coburg North",
            "Cockatoo",
            "Cockburn Central",
            "Coconut Grove",
            "Coes Creek",
            "Coffs Harbour",
            "Cohuna",
            "Colac",
            "Colac-Otway",
            "Coldstream",
            "Coleambally",
            "Colebee",
            "Coledale",
            "Collaroy",
            "Collaroy Plateau",
            "College Grove",
            "Collie",
            "Collingwood",
            "Collingwood Park",
            "Collinsville",
            "Collinswood",
            "Colo Vale",
            "Colonel Light Gardens",
            "Colyton",
            "Como",
            "Como",
            "Concord",
            "Concord West",
            "Condell Park",
            "Conder",
            "Condobolin",
            "Condon",
            "Coniston",
            "Connells Point",
            "Connolly",
            "Constitution Hill",
            "Coober Pedy",
            "Coodanup",
            "Cooee Bay",
            "Coogee",
            "Coogee",
            "Cook",
            "Cook Shire",
            "Cooks Hill",
            "Cooktown",
            "Coolah",
            "Coolamon",
            "Coolangatta",
            "Coolaroo",
            "Coolbellup",
            "Coolbinia",
            "Coolgardie",
            "Cooloola Cove",
            "Cooloongup",
            "Coolum Beach",
            "Cooma",
            "Coomalie",
            "Coombabah",
            "Coombs",
            "Coomera",
            "Coominya",
            "Coonabarabran",
            "Coonamble",
            "Coopers Plains",
            "Cooran",
            "Cooranbong",
            "Cooroibah",
            "Coorow",
            "Cooroy",
            "Coorparoo",
            "Cootamundra",
            "Copacabana",
            "Coppabella",
            "Copper Coast",
            "Coraki",
            "Coral Cove",
            "Corangamite",
            "Cordeaux Heights",
            "Corinda",
            "Corindi Beach",
            "Corio",
            "Corlette",
            "Cornubia",
            "Coromandel Valley",
            "Corowa",
            "Corrigin",
            "Corrimal",
            "Corryong",
            "Cossack",
            "Cotswold Hills",
            "Cottesloe",
            "Coutts Crossing",
            "Cowandilla",
            "Cowaramup",
            "Cowell",
            "Cowes",
            "Cowra",
            "Crace",
            "Crafers",
            "Crafers West",
            "Craigburn Farm",
            "Craigie",
            "Craigieburn",
            "Craiglie",
            "Craigmore",
            "Craignish",
            "Cranbourne",
            "Cranbourne East",
            "Cranbourne North",
            "Cranbourne South",
            "Cranbourne West",
            "Cranbrook",
            "Cranbrook",
            "Cranebrook",
            "Cranley",
            "Crawley",
            "Cremorne",
            "Cremorne",
            "Cremorne Point",
            "Crescent Head",
            "Cressy",
            "Crestmead",
            "Crestwood",
            "Creswick",
            "Crib Point",
            "Cringila",
            "Cromer",
            "Cronulla",
            "Crookwell",
            "Crows Nest",
            "Crows Nest",
            "Croydon",
            "Croydon",
            "Croydon",
            "Croydon Hills",
            "Croydon North",
            "Croydon Park",
            "Croydon South",
            "Crystal Brook",
            "Cuballing",
            "Cue",
            "Culburra Beach",
            "Culcairn",
            "Cumbalum",
            "Cumberland Park",
            "Cunderdin",
            "Cundletown",
            "Cunnamulla",
            "Curl Curl",
            "Curra",
            "Currajong",
            "Currambine",
            "Currans Hill",
            "Currie",
            "Currimundi",
            "Currumbin",
            "Currumbin Valley",
            "Currumbin Waters",
            "Curtin",
            "Cygnet",
            "D’Aguilar",
            "Daceyville",
            "Daglish",
            "Daisy Hill",
            "Dakabin",
            "Dalby",
            "Dalkeith",
            "Dallas",
            "Dalmeny",
            "Dalwallinu",
            "Dalyellup",
            "Dampier",
            "Dampier Peninsula",
            "Dandaragan",
            "Dandenong",
            "Dandenong North",
            "Dapto",
            "Darch",
            "Dardanup",
            "Darebin",
            "Darley",
            "Darling Downs",
            "Darling Heights",
            "Darling Point",
            "Darlinghurst",
            "Darlington",
            "Darlington",
            "Darlington",
            "Darlington Point",
            "Darra",
            "Darwin",
            "Davidson",
            "Davistown",
            "Davoren Park",
            "Daw Park",
            "Dawesville",
            "Dayboro",
            "Daylesford",
            "Dayton",
            "Deagon",
            "Deakin",
            "Dean Park",
            "Deception Bay",
            "Dee Why",
            "Deebing Heights",
            "Deer Park",
            "Deeragun",
            "Delacombe",
            "Delahey",
            "Delaneys Creek",
            "Deloraine",
            "Denham",
            "Denham Court",
            "Deniliquin",
            "Denistone",
            "Denistone East",
            "Denman",
            "Denmark",
            "Dennington",
            "Depot Hill",
            "Derby",
            "Derby-West Kimberley",
            "Dernancourt",
            "Derrimut",
            "Derwent Valley",
            "Desert Springs",
            "Devon Meadows",
            "Devonport",
            "Dharruk",
            "Diamantina",
            "Diamond Creek",
            "Dianella",
            "Dickson",
            "Dicky Beach",
            "Diddillibah",
            "Diggers Rest",
            "Dimboola",
            "Dimbulah",
            "Dingley Village",
            "Dinner Plain",
            "Djugun",
            "Docklands",
            "Dodges Ferry",
            "Dolls Point",
            "Donald",
            "Doncaster",
            "Doncaster East",
            "Dongara",
            "Donnybrook",
            "Donnybrook-Balingup",
            "Donvale",
            "Doolandella",
            "Doomadgee",
            "Doonan",
            "Doonside",
            "Dora Creek",
            "Doreen",
            "Dorrigo",
            "Dorset",
            "Double Bay",
            "Doubleview",
            "Douglas",
            "Douglas Park",
            "Dover Gardens",
            "Dover Heights",
            "Doveton",
            "Dowerin",
            "Downer",
            "Drayton",
            "Drewvale",
            "Driver",
            "Dromana",
            "Drouin",
            "Drummond Cove",
            "Drummoyne",
            "Drysdale",
            "Dubbo",
            "Dudley",
            "Dudley Park",
            "Duffy",
            "Dulwich",
            "Dulwich Hill",
            "Dumbleyung Shire",
            "Duncraig",
            "Dundas",
            "Dundas Valley",
            "Dundowran Beach",
            "Dunedoo",
            "Dungog",
            "Dunlop",
            "Dunsborough",
            "Durack",
            "Durack",
            "Dural",
            "Dutton Park",
            "Dynnyrne",
            "Dysart",
            "Eagle Farm",
            "Eagle Point",
            "Eagle Vale",
            "Eagleby",
            "Eaglehawk",
            "Eaglemont",
            "Earlville",
            "Earlwood",
            "East Albury",
            "East Arnhem",
            "East Bairnsdale",
            "East Ballina",
            "East Bendigo",
            "East Branxton",
            "East Brisbane",
            "East Bunbury",
            "East Cannington",
            "East Carnarvon",
            "East Corrimal",
            "East Devonport",
            "East Fremantle",
            "East Geelong",
            "East Gippsland",
            "East Gosford",
            "East Hills",
            "East Innisfail",
            "East Ipswich",
            "East Jindabyne",
            "East Kempsey",
            "East Killara",
            "East Kurrajong",
            "East Launceston",
            "East Lindfield",
            "East Lismore",
            "East Mackay",
            "East Maitland",
            "East Melbourne",
            "East Perth",
            "East Pilbara",
            "East Ryde",
            "East Side",
            "East Tamworth",
            "East Toowoomba",
            "East Victoria Park",
            "Eastern Heights",
            "Eastlakes",
            "Eastwood",
            "Eaton",
            "Eatons Hill",
            "Echuca",
            "Echunga",
            "Eden",
            "Eden Hill",
            "Eden Hills",
            "Eden Park",
            "Edens Landing",
            "Edensor Park",
            "Edge Hill",
            "Edgecliff",
            "Edgewater",
            "Edgeworth",
            "Edithvale",
            "Edmondson Park",
            "Edmonton",
            "Edwardstown",
            "Eglinton",
            "Eglinton",
            "Eight Mile Plains",
            "Eimeo",
            "Elanora",
            "Elanora Heights",
            "Elderslie",
            "Eleebana",
            "Elermore Vale",
            "Eli Waters",
            "Elimbah",
            "Elizabeth Bay",
            "Elizabeth Downs",
            "Elizabeth East",
            "Elizabeth Grove",
            "Elizabeth Hills",
            "Elizabeth North",
            "Elizabeth Park",
            "Elizabeth South",
            "Elizabeth Vale",
            "Ellalong",
            "Ellen Grove",
            "Ellenbrook",
            "Elliminyt",
            "Elliott Heads",
            "Elliston",
            "Elsternwick",
            "Eltham",
            "Eltham North",
            "Elwood",
            "Embleton",
            "Emerald",
            "Emerald",
            "Emerald Beach",
            "Emerton",
            "Empire Bay",
            "Emu Heights",
            "Emu Park",
            "Emu Plains",
            "Encounter Bay",
            "Endeavour Hills",
            "Enfield",
            "Engadine",
            "Enmore",
            "Enoggera",
            "Epping",
            "Epping",
            "Epsom",
            "Erina",
            "Erindale",
            "Ermington",
            "Erskine",
            "Erskine Park",
            "Erskineville",
            "Eschol Park",
            "Esk",
            "Esperance",
            "Esperance Shire",
            "Essendon",
            "Essendon North",
            "Essendon West",
            "Estella",
            "Ethelton",
            "Etheridge",
            "Ettalong",
            "Ettalong Beach",
            "Eudlo",
            "Eulomogo",
            "Eumemmerring",
            "Eumundi",
            "Euroa",
            "Eurobodalla",
            "Evandale",
            "Evandale",
            "Evans Head",
            "Evanston",
            "Evanston Gardens",
            "Evanston Park",
            "Evatt",
            "Everard Park",
            "Everton Hills",
            "Everton Park",
            "Exeter",
            "Exmouth",
            "Eynesbury",
            "Fadden",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield East",
            "Fairfield Heights",
            "Fairfield West",
            "Fairlight",
            "Fairview Park",
            "Fairy Meadow",
            "Falcon",
            "Falls Creek",
            "Fannie Bay",
            "Farmborough Heights",
            "Farrar",
            "Farrer",
            "Faulconbridge",
            "Fawkner",
            "Felixstow",
            "Fennell Bay",
            "Fern Bay",
            "Fern Hill",
            "Ferndale",
            "Ferntree Gully",
            "Fernvale",
            "Ferny Creek",
            "Ferny Grove",
            "Ferny Hills",
            "Ferryden Park",
            "Fig Tree Pocket",
            "Figtree",
            "Findon",
            "Fingal Bay",
            "Finley",
            "Firle",
            "Fisher",
            "Fitzgibbon",
            "Fitzroy",
            "Fitzroy Crossing",
            "Fitzroy North",
            "Five Dock",
            "Flagstaff Hill",
            "Flemington",
            "Fletcher",
            "Flinders",
            "Flinders",
            "Flinders",
            "Flinders Park",
            "Flinders Ranges",
            "Flinders View",
            "Flora Hill",
            "Floraville",
            "Floreat",
            "Florey",
            "Flynn",
            "Footscray",
            "Forbes",
            "Forde",
            "Forest Glen",
            "Forest Hill",
            "Forest Hill",
            "Forest Lake",
            "Forest Lodge",
            "Forestdale",
            "Forestville",
            "Forestville",
            "Forrest",
            "Forrestdale",
            "Forresters Beach",
            "Forrestfield",
            "Forster",
            "Fortitude Valley",
            "Foster",
            "Franklin",
            "Franklin",
            "Franklin Harbour",
            "Frankston",
            "Frankston East",
            "Frankston North",
            "Frankston South",
            "Fraser",
            "Fraser Coast",
            "Frederickton",
            "Freeling",
            "Freemans Reach",
            "Fremantle",
            "Frenchs Forest",
            "Frenchville",
            "Freshwater",
            "Freshwater",
            "Fulham",
            "Fulham Gardens",
            "Fullarton",
            "Gagebrook",
            "Gailes",
            "Galiwinku",
            "Galston",
            "Gannawarra",
            "Garbutt",
            "Garden Island",
            "Garden Suburb",
            "Garfield",
            "Garran",
            "Gateshead",
            "Gatton",
            "Gaven",
            "Gawler",
            "Gawler East",
            "Gawler South",
            "Gayndah",
            "Gaythorne",
            "Geebung",
            "Geelong",
            "Geelong city centre",
            "Geelong West",
            "Geeveston",
            "Geilston Bay",
            "Gelorup",
            "Gembrook",
            "Geographe",
            "George Town",
            "Georges Hall",
            "Georgetown",
            "Geraldton",
            "Geraldton city centre",
            "Gerringong",
            "Gidgegannup",
            "Gilberton",
            "Gilgandra",
            "Gillen",
            "Gilles Plains",
            "Gillieston Heights",
            "Gilmore",
            "Gilston",
            "Gin Gin",
            "Gingin",
            "Giralang",
            "Girards Hill",
            "Girraween",
            "Girraween",
            "Girrawheen",
            "Gisborne",
            "Gladesville",
            "Gladstone",
            "Gladstone Central",
            "Gladstone Park",
            "Glamorgan/Spring Bay",
            "Glandore",
            "Glass House Mountains",
            "Glebe",
            "Glen Alpine",
            "Glen Eden",
            "Glen Eira",
            "Glen Forrest",
            "Glen Huntly",
            "Glen Innes",
            "Glen Innes Severn",
            "Glen Iris",
            "Glen Iris",
            "Glen Osmond",
            "Glen Waverley",
            "Glenalta",
            "Glenbrook",
            "Glendale",
            "Glendalough",
            "Glendenning",
            "Gleneagle",
            "Glenelg",
            "Glenelg",
            "Glenelg East",
            "Glenelg North",
            "Glenelg South",
            "Glenella",
            "Glenferrie",
            "Glenfield",
            "Glenfield Park",
            "Glengarry",
            "Glengowrie",
            "Glenhaven",
            "Glenmore Park",
            "Glenning Valley",
            "Glenorchy",
            "Glenorie",
            "Glenroy",
            "Glenroy",
            "Glenside",
            "Glenunga",
            "Glenvale",
            "Glenview",
            "Glenwood",
            "Glossodia",
            "Gloucester",
            "Glynde",
            "Gnangara",
            "Gnowangerup",
            "Gol Gol",
            "Gold Coast",
            "Golden Bay",
            "Golden Beach",
            "Golden Grove",
            "Golden Plains",
            "Golden Point",
            "Golden Square",
            "Gooburrum",
            "Goodna",
            "Goodwood",
            "Goodwood",
            "Googong",
            "Goolwa",
            "Goolwa Beach",
            "Goomalling",
            "Goondiwindi",
            "Goonellabah",
            "Gooseberry Hill",
            "Gordon",
            "Gordon",
            "Gordon",
            "Gordon Park",
            "Gordonvale",
            "Gorokan",
            "Gosford",
            "Gosnells",
            "Goulburn",
            "Goulburn Mulwaree",
            "Gowanbrae",
            "Gowrie",
            "Gowrie Junction",
            "Goyder",
            "Gracemere",
            "Graceville",
            "Grafton",
            "Grange",
            "Grange",
            "Grant",
            "Granton",
            "Granville",
            "Granville",
            "Grasmere",
            "Grasmere",
            "Gray",
            "Grays Point",
            "Greater Bendigo",
            "Greater Dandenong",
            "Greater Geelong",
            "Greater Hume Shire",
            "Greater Shepparton",
            "Green Head",
            "Green Valley",
            "Greenacre",
            "Greenacres",
            "Greenbank",
            "Greenfield Park",
            "Greenfields",
            "Greenmount",
            "Greenock",
            "Greensborough",
            "Greenslopes",
            "Greenvale",
            "Greenway",
            "Greenwell Point",
            "Greenwich",
            "Greenwith",
            "Greenwood",
            "Gregory Hills",
            "Grenfell",
            "Greta",
            "Greystanes",
            "Griffin",
            "Griffith",
            "Griffith",
            "Grose Vale",
            "Grovedale",
            "Guildford",
            "Guildford West",
            "Gulfview Heights",
            "Gulgong",
            "Gulliver",
            "Gulmarrad",
            "Gumdale",
            "Gunbalanya",
            "Gundagai",
            "Gundaroo",
            "Gungahlin",
            "Gunn",
            "Gunnedah",
            "Guyra",
            "Gwandalan",
            "Gwelup",
            "Gwydir",
            "Gwynneville",
            "Gymea",
            "Gymea Bay",
            "Gympie",
            "Gympie Regional Council",
            "Haberfield",
            "Hackett",
            "Hackham",
            "Hackham West",
            "Haddon",
            "Hadfield",
            "Hadspen",
            "Hahndorf",
            "Halekulani",
            "Hallam",
            "Hallett Cove",
            "Halls Creek",
            "Halls Head",
            "Hamersley",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton Hill",
            "Hamlyn Heights",
            "Hamlyn Terrace",
            "Hammond Park",
            "Hammondville",
            "Hampstead Gardens",
            "Hampton",
            "Hampton East",
            "Hampton Park",
            "Hannans",
            "Hanwood",
            "Happy Valley",
            "Harlaxton",
            "Harrington",
            "Harrington Park",
            "Harris Park",
            "Harrisdale",
            "Harrison",
            "Harristown",
            "Harvey",
            "Hassall Grove",
            "Hastings",
            "Hatton Vale",
            "Haven",
            "Hawker",
            "Hawkesbury",
            "Hawks Nest",
            "Hawthorn",
            "Hawthorn",
            "Hawthorn East",
            "Hawthorn South",
            "Hawthorndene",
            "Hawthorne",
            "Hay",
            "Hay Point",
            "Hayborough",
            "Haymarket",
            "Hazelbrook",
            "Hazelwood North",
            "Hazelwood Park",
            "Healesville",
            "Healy",
            "Heathcote",
            "Heathcote",
            "Heatherton",
            "Heathmont",
            "Heathridge",
            "Heathwood",
            "Heatley",
            "Hebersham",
            "Heckenberg",
            "Hectorville",
            "Heddon Greta",
            "Heidelberg",
            "Heidelberg Heights",
            "Heidelberg West",
            "Helena Valley",
            "Helensburgh",
            "Helensvale",
            "Helidon",
            "Hemmant",
            "Hendra",
            "Henley Beach",
            "Henley Beach South",
            "Henley Brook",
            "Henty",
            "Hepburn",
            "Herbert",
            "Herdsmans Cove",
            "Heritage Park",
            "Hermit Park",
            "Herne Hill",
            "Herne Hill",
            "Herston",
            "Hervey Bay",
            "Hewett",
            "Heyfield",
            "Heywood",
            "Higgins",
            "High Wycombe",
            "Highbury",
            "Highett",
            "Highfields",
            "Highgate",
            "Highgate",
            "Highgate Hill",
            "Highland Park",
            "Highton",
            "Highvale",
            "Hilbert",
            "Hill River",
            "Hill Top",
            "Hillarys",
            "Hillbank",
            "Hillcrest",
            "Hillcrest",
            "Hillcrest",
            "Hillman",
            "Hillsdale",
            "Hillside",
            "Hillston",
            "Hillvue",
            "Hilton",
            "Hinchinbrook",
            "Hinchinbrook",
            "Hindmarsh",
            "Hindmarsh Island",
            "Hmas Cerberus",
            "Hobart",
            "Hobart city centre",
            "Hobartville",
            "Hobsons Bay",
            "Hocking",
            "Hodgson Vale",
            "Holbrook",
            "Holden Hill",
            "Holder",
            "Holdfast Bay",
            "Holland Park",
            "Holland Park West",
            "Holloways Beach",
            "Hollywell",
            "Holmesville",
            "Holmview",
            "Holroyd",
            "Holsworthy",
            "Holt",
            "Holtze",
            "Home Hill",
            "Homebush",
            "Homebush West",
            "Hope Island",
            "Hope Vale",
            "Hope Valley",
            "Hoppers Crossing",
            "Horningsea Park",
            "Hornsby",
            "Hornsby Heights",
            "Hornsby Shire",
            "Horsham",
            "Horsley",
            "Horsley Park",
            "Hotham Heights",
            "Hove",
            "Howard",
            "Howard Springs",
            "Howlong",
            "Howrah",
            "Hoxton Park",
            "Hughenden",
            "Hughes",
            "Hughesdale",
            "Hume",
            "Humpty Doo",
            "Hunters Hill",
            "Hunterview",
            "Huntfield Heights",
            "Huntingdale",
            "Huntingdale",
            "Huntingwood",
            "Huntly",
            "Huon Valley",
            "Huonville",
            "Hurlstone Park",
            "Hurstbridge",
            "Hurstville",
            "Hurstville Grove",
            "Hyde Park",
            "Hyde Park",
            "Idalia",
            "Illawong",
            "Iluka",
            "Iluka",
            "Inala",
            "Indented Head",
            "Indigo",
            "Indooroopilly",
            "Ingham",
            "Ingle Farm",
            "Ingleburn",
            "Inglewood",
            "Innaloo",
            "Innes Park",
            "Innisfail",
            "Innisfail Estate",
            "Inverell",
            "Inverleigh",
            "Inverloch",
            "Invermay",
            "Invermay Park",
            "Ipswich",
            "Ironbark",
            "Irwin",
            "Irymple",
            "Isaac",
            "Isaacs",
            "Isabella Plains",
            "Islington",
            "Ivanhoe",
            "Ivanhoe East",
            "Jabiru",
            "Jacana",
            "Jackass Flat",
            "Jacobs Well",
            "Jamberoo",
            "Jamboree Heights",
            "Jamestown",
            "Jamisontown",
            "Jan Juc",
            "Jandakot",
            "Jandowae",
            "Jane Brook",
            "Jannali",
            "Jarrahdale",
            "Jensen",
            "Jerilderie",
            "Jerrabomberra",
            "Jerramungup",
            "Jesmond",
            "Jewells",
            "Jilliby",
            "Jimboomba",
            "Jindabyne",
            "Jindalee",
            "Jindalee",
            "Jindera",
            "Jingili",
            "Johnston",
            "Jolimont",
            "Joondalup",
            "Joondanna",
            "Jordan Springs",
            "Joslin",
            "Joyner",
            "Jubilee Pocket",
            "Julatten",
            "Junction Hill",
            "Junction Village",
            "Junee",
            "Junortoun",
            "Jurien Bay",
            "Kadina",
            "Kahibah",
            "Kalamunda",
            "Kalbar",
            "Kalbarri",
            "Kaleen",
            "Kalgoorlie",
            "Kalgoorlie/Boulder",
            "Kalimna",
            "Kalinga",
            "Kalkie",
            "Kallangur",
            "Kallaroo",
            "Kallista",
            "Kalorama",
            "Kambah",
            "Kambalda East",
            "Kambalda West",
            "Kamerunga",
            "Kanahooka",
            "Kandos",
            "Kangaroo Flat",
            "Kangaroo Ground",
            "Kangaroo Island",
            "Kangaroo Point",
            "Kanimbla",
            "Kanwal",
            "Kapooka",
            "Kapunda",
            "Karabar",
            "Karalee",
            "Karama",
            "Karana Downs",
            "Karawara",
            "Kardinya",
            "Kareela",
            "Kariong",
            "Karnup",
            "Karoonda East Murray",
            "Karratha",
            "Karrinyup",
            "Karuah",
            "Karumba",
            "Katanning",
            "Katherine",
            "Katherine East",
            "Katherine South",
            "Katoomba",
            "Kawana",
            "Kawungan",
            "Kealba",
            "Kearneys Spring",
            "Kearns",
            "Kedron",
            "Keilor",
            "Keilor Downs",
            "Keilor East",
            "Keilor Lodge",
            "Keilor Park",
            "Keiraville",
            "Keith",
            "Kellerberrin",
            "Kellyville",
            "Kellyville Ridge",
            "Kelmscott",
            "Kelso",
            "Kelso",
            "Kelvin Grove",
            "Kemps Creek",
            "Kempsey",
            "Kendall",
            "Kenmore",
            "Kenmore Hills",
            "Kennington",
            "Kensington",
            "Kensington",
            "Kensington Gardens",
            "Kensington Grove",
            "Kensington Park",
            "Kent Shire",
            "Kent Town",
            "Kenthurst",
            "Kentish",
            "Kenwick",
            "Keperra",
            "Kepnock",
            "Kerang",
            "Kersbrook",
            "Kew",
            "Kew",
            "Kew East",
            "Kewarra Beach",
            "Kewdale",
            "Keysborough",
            "Kialla",
            "Kiama",
            "Kiama Downs",
            "Kiara",
            "Kidman Park",
            "Kilaben Bay",
            "Kilburn",
            "Kilcoy",
            "Kilkenny",
            "Killara",
            "Killarney Heights",
            "Killarney Vale",
            "Kilmore",
            "Kilsyth",
            "Kilsyth South",
            "Kimba",
            "Kin Kora",
            "King Creek",
            "King Island",
            "Kingaroy",
            "Kingborough",
            "Kinglake",
            "Kinglake West",
            "Kings Beach",
            "Kings Langley",
            "Kings Meadows",
            "Kings Park",
            "Kings Park",
            "Kingsbury",
            "Kingscliff",
            "Kingscote",
            "Kingsford",
            "Kingsgrove",
            "Kingsley",
            "Kingsthorpe",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston Beach",
            "Kingston South East",
            "Kingsville",
            "Kingswood Park",
            "Kinross",
            "Kippa-Ring",
            "Kirkwood",
            "Kirrawee",
            "Kirribilli",
            "Kirwan",
            "Kleinton",
            "Klemzig",
            "Knox",
            "Knoxfield",
            "Kogarah",
            "Kogarah Bay",
            "Kojonup",
            "Kondinin",
            "Koo-Wee-Rup",
            "Koonawarra",
            "Koondoola",
            "Koongal",
            "Kooralbyn",
            "Koorda",
            "Kooringal",
            "Kootingal",
            "Koroit",
            "Korora",
            "Korumburra",
            "Kosciuszko National Park",
            "Kotara",
            "Kotara South",
            "Kowanyama",
            "Ku-ring-gai",
            "Kulin",
            "Kuluin",
            "Kununurra",
            "Kuraby",
            "Kuranda",
            "Kurnell",
            "Kurraba Point",
            "Kurrajong Heights",
            "Kurralta Park",
            "Kurri Kurri",
            "Kurunjang",
            "Kurwongbah",
            "Kwinana",
            "Kyabram",
            "Kyle Bay",
            "Kyneton",
            "Kyogle",
            "Labrador",
            "Lachlan",
            "Laidley",
            "Lake Albert",
            "Lake Cargelligo",
            "Lake Cathie",
            "Lake Gardens",
            "Lake Grace",
            "Lake Haven",
            "Lake Heights",
            "Lake Illawarra",
            "Lake Macdonald",
            "Lake Macquarie Shire",
            "Lake Munmorah",
            "Lake Wendouree",
            "Lakelands",
            "Lakelands",
            "Lakemba",
            "Lakes Entrance",
            "Lakewood",
            "Lalor",
            "Lalor Park",
            "Lambton",
            "Lamington",
            "Lammermoor",
            "Lancefield",
            "Lancelin",
            "Landsborough",
            "Landsdale",
            "Lane Cove",
            "Lane Cove North",
            "Lane Cove West",
            "Lang Lang",
            "Langford",
            "Langwarrin",
            "Langwarrin South",
            "Lansvale",
            "Lara",
            "Larapinta",
            "Largs",
            "Largs Bay",
            "Largs North",
            "Larrakeyah",
            "Latham",
            "Lathlain",
            "Latrobe",
            "Latrobe",
            "Lauderdale",
            "Launceston",
            "Launceston city centre",
            "Launching Place",
            "Laverton",
            "Laverton",
            "Lavington",
            "Lawnton",
            "Lawrence",
            "Lawson",
            "Leabrook",
            "Leanyer",
            "Leda",
            "Leederville",
            "Leeman",
            "Leeming",
            "Leeton",
            "Legana",
            "Leichhardt",
            "Leichhardt",
            "Leinster",
            "Lemon Tree Passage",
            "Lenah Valley",
            "Lennox Head",
            "Leonay",
            "Leongatha",
            "Leonora",
            "Leopold",
            "Leppington",
            "Leschenault",
            "Lesmurdie",
            "Lethbridge Park",
            "Leumeah",
            "Leura",
            "Lewisham",
            "Lewiston",
            "Liberty Grove",
            "Lidcombe",
            "Light",
            "Lightning Ridge",
            "Lilli Pilli",
            "Lilydale",
            "Lilyfield",
            "Linden Park",
            "Lindfield",
            "Lindisfarne",
            "Lisarow",
            "Lismore",
            "Lismore Heights",
            "Lismore Municipality",
            "Litchfield",
            "Lithgow",
            "Little Bay",
            "Little Grove",
            "Little Hampton",
            "Little Mountain",
            "Little River",
            "Liverpool",
            "Liverpool Plains",
            "Llanarth",
            "Llandilo",
            "Lobethal",
            "Lockhart",
            "Lockhart River",
            "Lockleys",
            "Lockridge",
            "Lockyer",
            "Lockyer Valley",
            "Loddon",
            "Loftus",
            "Logan",
            "Logan Central",
            "Logan City",
            "Logan Reserve",
            "Logan Village",
            "Loganholme",
            "Loganlea",
            "Londonderry",
            "Long Beach",
            "Long Gully",
            "Long Jetty",
            "Longford",
            "Longford",
            "Longreach",
            "Longueville",
            "Longwarry",
            "Lorn",
            "Lorne",
            "Lota",
            "Lovely Banks",
            "Lower Beechmont",
            "Lower Chittering",
            "Lower Eyre Peninsula",
            "Lower King",
            "Lower Mitcham",
            "Lower Plenty",
            "Lowood",
            "Loxton",
            "Loxton Waikerie",
            "Lucknow",
            "Luddenham",
            "Ludmilla",
            "Lugarno",
            "Lurnea",
            "Lutana",
            "Lutwyche",
            "Lynbrook",
            "Lyndoch",
            "Lyneham",
            "Lynwood",
            "Lyons",
            "Lyons",
            "Lysterfield",
            "Macarthur",
            "Macclesfield",
            "MacDonnell",
            "Macedon",
            "Macedon Ranges",
            "Macgregor",
            "Macgregor",
            "Machans Beach",
            "Mackay",
            "Mackay City",
            "Mackenzie",
            "Macksville",
            "Maclean",
            "Macleay Island",
            "Macleod",
            "Macmasters Beach",
            "Macquarie",
            "Macquarie Fields",
            "Macquarie Hills",
            "Macquarie Links",
            "Macquarie Park",
            "Maddingley",
            "Maddington",
            "Madeley",
            "Madora Bay",
            "Maffra",
            "Magill",
            "Magnetic Island",
            "Maida Vale",
            "Maiden Gully",
            "Maidstone",
            "Main Beach",
            "Maitland",
            "Maitland",
            "Maitland city centre",
            "Maitland Municipality",
            "Malabar",
            "Malaga",
            "Malak",
            "Malanda",
            "Maldon",
            "Maleny",
            "Mallacoota",
            "Mallala",
            "Malua Bay",
            "Malvern",
            "Malvern",
            "Malvern East",
            "Mandurah",
            "Mandurah city centre",
            "Mangerton",
            "Mango Hill",
            "Manifold Heights",
            "Manilla",
            "Maningrida",
            "Manjimup",
            "Manly",
            "Manly Vale",
            "Manly West",
            "Mannering Park",
            "Manning",
            "Manningham",
            "Manningham",
            "Mannum",
            "Manoora",
            "Mansfield",
            "Mansfield",
            "Mansfield Park",
            "Manunda",
            "Mapleton",
            "Mapoon",
            "Maralinga Tjarutja",
            "Marangaroo",
            "Maranoa",
            "Maraylya",
            "Marayong",
            "Marble Bar",
            "Marcoola",
            "Marden",
            "Mardi",
            "Mareeba",
            "Margaret River",
            "Margate",
            "Margate",
            "Marian",
            "Maribyrnong",
            "Marino",
            "Marion",
            "Marks Point",
            "Marleston",
            "Marmion",
            "Marong",
            "Maroochy River",
            "Maroochydore",
            "Maroondah",
            "Maroubra",
            "Marrara",
            "Marrickville",
            "Marsden",
            "Marsfield",
            "Martin",
            "Marulan",
            "Maryborough",
            "Maryborough",
            "Maryland",
            "Maryville",
            "Mascot",
            "Maslin Beach",
            "Matraville",
            "Maudsland",
            "Mawson",
            "Mawson Lakes",
            "Mayfield",
            "Mayfield",
            "Mayfield East",
            "Mayfield West",
            "Maylands",
            "Maylands",
            "McCracken",
            "McCrae",
            "McDowall",
            "McGraths Hill",
            "McKail",
            "McKellar",
            "McKinlay",
            "McKinnon",
            "McLaren Flat",
            "McLaren Vale",
            "McMahons Point",
            "Meadow Heights",
            "Meadow Springs",
            "Meadowbank",
            "Meadowbrook",
            "Meadows",
            "Meander Valley",
            "Medina",
            "Medindie",
            "Medowie",
            "Meekatharra",
            "Melba",
            "Melbourne",
            "Melbourne City Centre",
            "Melrose Park",
            "Melrose Park",
            "Melton",
            "Melton South",
            "Melton West",
            "Melville",
            "Menai",
            "Menangle",
            "Meningie",
            "Menora",
            "Mentone",
            "Menzies",
            "Menzies",
            "Merbein",
            "Merewether",
            "Merewether Heights",
            "Meridan Plains",
            "Merimbula",
            "Meringandan West",
            "Mermaid Beach",
            "Mermaid Waters",
            "Mernda",
            "Merredin",
            "Merrimac",
            "Merriwa",
            "Merriwa",
            "Merrylands",
            "Merrylands West",
            "Metford",
            "Metung",
            "Miami",
            "Miandetta",
            "Mickleham",
            "Mid Murray",
            "Mid-Western Regional",
            "Middle Cove",
            "Middle Park",
            "Middle Park",
            "Middle Ridge",
            "Middle Swan",
            "Middlemount",
            "Middleton",
            "Middleton Grange",
            "Midland",
            "Midvale",
            "Midway Point",
            "Mildura",
            "Mildura Shire",
            "Mile End",
            "Miles",
            "Milingimbi",
            "Mill Park",
            "Millars Well",
            "Millbank",
            "Millbridge",
            "Miller",
            "Millers Point",
            "Millgrove",
            "Millicent",
            "Millmerran",
            "Millner",
            "Millstream",
            "Millswood",
            "Millthorpe",
            "Milperra",
            "Milsons Point",
            "Milton",
            "Milton",
            "Minchinbury",
            "Mindarie",
            "Minden",
            "Miners Rest",
            "Mingenew",
            "Minlaton",
            "Minto",
            "Minyama",
            "Mira Mar",
            "Miranda",
            "Mirani",
            "Mirboo North",
            "Mirrabooka",
            "Mission Beach",
            "Mission River",
            "Mitcham",
            "Mitcham",
            "Mitchell",
            "Mitchell",
            "Mitchell",
            "Mitchell Park",
            "Mitchelton",
            "Mittagong",
            "Moama",
            "Moana",
            "Modbury",
            "Modbury Heights",
            "Modbury North",
            "Moe",
            "Moffat Beach",
            "Moggill",
            "Moil",
            "Moira",
            "Molendinar",
            "Mollymook",
            "Mollymook Beach",
            "Molong",
            "Mona Vale",
            "Monash",
            "Monash",
            "Monash",
            "Monbulk",
            "Monkland",
            "Mont Albert",
            "Mont Albert North",
            "Montello",
            "Monterey",
            "Montmorency",
            "Monto",
            "Montrose",
            "Montrose",
            "Montville",
            "Moolap",
            "Mooloolaba",
            "Moonah",
            "Moonbi",
            "Moonee Beach",
            "Moonee Ponds",
            "Moonee Valley",
            "Moonta Bay",
            "Moora",
            "Moorabbin",
            "Moorabool",
            "Moorak",
            "Moore Park Beach",
            "Moorebank",
            "Mooroobool",
            "Moorooduc",
            "Moorooka",
            "Mooroolbark",
            "Mooroopna",
            "Moranbah",
            "Morawa",
            "Morayfield",
            "Mordialloc",
            "Moree",
            "Moree Plains",
            "Moreland",
            "Moreton Bay",
            "Morisset",
            "Morley",
            "Morningside",
            "Mornington",
            "Mornington",
            "Mornington",
            "Mornington Peninsula",
            "Morpeth",
            "Morphett Vale",
            "Morphettville",
            "Mortdale",
            "Mortlake",
            "Mortlake",
            "Moruya",
            "Morwell",
            "Mosman",
            "Mosman Park",
            "Moss Vale",
            "Mossman",
            "Moulden",
            "Mount Alexander",
            "Mount Annan",
            "Mount Austin",
            "Mount Barker",
            "Mount Barker",
            "Mount Buller",
            "Mount Claremont",
            "Mount Clear",
            "Mount Colah",
            "Mount Compass",
            "Mount Coolum",
            "Mount Cotton",
            "Mount Crosby",
            "Mount Dandenong",
            "Mount Druitt",
            "Mount Duneed",
            "Mount Eliza",
            "Mount Evelyn",
            "Mount Gambier",
            "Mount Gravatt",
            "Mount Gravatt East",
            "Mount Hawthorn",
            "Mount Helen",
            "Mount Helena",
            "Mount Hutton",
            "Mount Isa",
            "Mount Keira",
            "Mount Kembla",
            "Mount Kuring-Gai",
            "Mount Lawley",
            "Mount Lewis",
            "Mount Lofty",
            "Mount Louisa",
            "Mount Low",
            "Mount Macedon",
            "Mount Magnet",
            "Mount Marshall",
            "Mount Martha",
            "Mount Melville",
            "Mount Morgan",
            "Mount Nasura",
            "Mount Nathan",
            "Mount Nelson",
            "Mount Ommaney",
            "Mount Ousley",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pritchard",
            "Mount Remarkable",
            "Mount Richon",
            "Mount Riverview",
            "Mount Saint Thomas",
            "Mount Sheridan",
            "Mount Stuart",
            "Mount Tarcoola",
            "Mount Vernon",
            "Mount Warren Park",
            "Mount Warrigal",
            "Mount Waverley",
            "Mountain Creek",
            "Moura",
            "Mowbray",
            "Moyne",
            "Muchea",
            "Mudgee",
            "Mudgeeraba",
            "Mudjimba",
            "Muirhead",
            "Mukinbudin",
            "Mulambin",
            "Mulgoa",
            "Mulgrave",
            "Mullaloo",
            "Mullumbimby",
            "Mulwala",
            "Mundaring",
            "Mundijong",
            "Mundingburra",
            "Mundoolun",
            "Mundubbera",
            "Munno Para",
            "Munno Para West",
            "Munruben",
            "Munster",
            "Murarrie",
            "Murchison",
            "Murdoch",
            "Murgon",
            "Murray",
            "Murray",
            "Murray Bridge",
            "Murrindindi",
            "Murrumba Downs",
            "Murrumbateman",
            "Murrumbeena",
            "Murrurundi",
            "Murweh",
            "Murwillumbah",
            "Muswellbrook",
            "Myaree",
            "Mylor",
            "Myrtle Bank",
            "Myrtleford",
            "Nabiac",
            "Nagambie",
            "Nailsworth",
            "Nairne",
            "Nakara",
            "Nambour",
            "Nambucca",
            "Nambucca Heads",
            "Nambucca Shire",
            "Nana Glen",
            "Nanango",
            "Nannup",
            "Nanum",
            "Napranum",
            "Naracoorte",
            "Naracoorte and Lucindale",
            "Narangba",
            "Narara",
            "Narellan",
            "Narellan Vale",
            "Narembeen",
            "Naremburn",
            "Narooma",
            "Narrabeen",
            "Narrabri",
            "Narrabundah",
            "Narrandera",
            "Narrawallee",
            "Narraweena",
            "Narre Warren",
            "Narre Warren North",
            "Narre Warren South",
            "Narrogin",
            "Narromine",
            "Narwee",
            "Nathalia",
            "Nathan",
            "Nebo",
            "Nedlands",
            "Neerim South",
            "Nelly Bay",
            "Nelson Bay",
            "Nerang",
            "Netherby",
            "Netley",
            "Neutral Bay",
            "New Auckland",
            "New Beith",
            "New Farm",
            "New Gisborne",
            "New Lambton",
            "New Lambton Heights",
            "New Norfolk",
            "New Town",
            "Newborough",
            "Newcastle",
            "Newcastle city centre",
            "Newcastle East",
            "Newcomb",
            "Newington",
            "Newington",
            "Newman",
            "Newmarket",
            "Newnham",
            "Newport",
            "Newport",
            "Newport",
            "Newstead",
            "Newstead",
            "Newton",
            "Newtown",
            "Newtown",
            "Newtown",
            "Ngaanyatjarraku",
            "Ngukurr",
            "Ngunnawal",
            "Nhill",
            "Nhulunbuy",
            "Niagara Park",
            "Nicholls",
            "Nichols Point",
            "Nicholson",
            "Nickol",
            "Niddrie",
            "Nightcliff",
            "Nillumbik",
            "Nimbin",
            "Ninderry",
            "Ningi",
            "Noarlunga Downs",
            "Noble Park",
            "Noble Park North",
            "Nollamara",
            "Nome",
            "Noosa Heads",
            "Noosaville",
            "Noranda",
            "Noraville",
            "Norlane",
            "Norman Gardens",
            "Norman Park",
            "Normanhurst",
            "Normanton",
            "Normanville",
            "North Adelaide",
            "North Albury",
            "North Avoca",
            "North Balgowlah",
            "North Beach",
            "North Bendigo",
            "North Boambee Valley",
            "North Bondi",
            "North Booval",
            "North Brighton",
            "North Brighton",
            "North Burnett",
            "North Coogee",
            "North Curl Curl",
            "North Epping",
            "North Fremantle",
            "North Geelong",
            "North Gosford",
            "North Haven",
            "North Haven",
            "North Hobart",
            "North Ipswich",
            "North Lake",
            "North Lakes",
            "North Lambton",
            "North Mackay",
            "North Maclean",
            "North Manly",
            "North Melbourne",
            "North Narrabeen",
            "North Nowra",
            "North Parramatta",
            "North Perth",
            "North Plympton",
            "North Richmond",
            "North Rocks",
            "North Ryde",
            "North St Marys",
            "North Strathfield",
            "North Sydney",
            "North Tamworth",
            "North Toowoomba",
            "North Turramurra",
            "North Wahroonga",
            "North Ward",
            "North Warrandyte",
            "North Willoughby",
            "North Wollongong",
            "North Wonthaggi",
            "Northam",
            "Northampton Shire",
            "Northbridge",
            "Northbridge",
            "Northcote",
            "Northern Areas",
            "Northern Grampians",
            "Northern Midlands",
            "Northern Peninsula Area",
            "Northfield",
            "Northgate",
            "Northmead",
            "Norville",
            "Norwood",
            "Norwood",
            "Norwood Payneham St Peters",
            "Notting Hill",
            "Novar Gardens",
            "Nowra",
            "Nowra Hill",
            "Nudgee",
            "Nullagine",
            "Nulsen",
            "Numurkah",
            "Nunawading",
            "Nundah",
            "Nungarin",
            "Nuriootpa",
            "Nyngan",
            "Nyora",
            "O'Connor",
            "O'Sullivan Beach",
            "O’Halloran Hill",
            "Oak Flats",
            "Oak Park",
            "Oakdale",
            "Oakden",
            "Oakdowns",
            "Oakey",
            "Oakford",
            "Oakhurst",
            "Oakhurst",
            "Oaklands Park",
            "Oakleigh",
            "Oakleigh East",
            "Oakleigh South",
            "Oakville",
            "Oatlands",
            "Oatley",
            "Oberon",
            "Ocean Grove",
            "Ocean Reef",
            "Ocean Shores",
            "Officer",
            "Old Bar",
            "Old Beach",
            "Old Erowal Bay",
            "Old Guildford",
            "Old Noarlunga",
            "Old Reynella",
            "Old Toongabbie",
            "Olinda",
            "One Mile",
            "One Tree Hill",
            "Onkaparinga",
            "Onkaparinga Hills",
            "Onslow",
            "Oonoonba",
            "Ooralea",
            "Oran Park",
            "Orana",
            "Orange",
            "Orange Municipality",
            "Orangeville",
            "Orbost",
            "Orchard Hills",
            "Orelia",
            "Ormeau",
            "Ormeau Hills",
            "Ormiston",
            "Ormond",
            "Orroroo/Carrieton",
            "Osborne",
            "Osborne Park",
            "Ottoway",
            "Ourimbah",
            "Ouyen",
            "Oxenford",
            "Oxley",
            "Oxley",
            "Oxley Park",
            "Oxley Vale",
            "Oyster Bay",
            "Pacific Paradise",
            "Pacific Pines",
            "Padbury",
            "Paddington",
            "Paddington",
            "Padstow",
            "Padstow Heights",
            "Page",
            "Pagewood",
            "Pakenham",
            "Pakenham Upper",
            "Palm Beach",
            "Palm Beach",
            "Palm Cove",
            "Palm Island",
            "Palmerston",
            "Palmerston",
            "Palmwoods",
            "Palmyra",
            "Panania",
            "Panorama",
            "Panton Hill",
            "Para Hills",
            "Para Hills West",
            "Para Vista",
            "Paraburdoo",
            "Paradise",
            "Paradise Point",
            "Parafield Gardens",
            "Paralowie",
            "Parap",
            "Paringa",
            "Park Avenue",
            "Park Grove",
            "Park Holme",
            "Park Orchards",
            "Park Ridge",
            "Park Ridge South",
            "Parkdale",
            "Parkerville",
            "Parkes",
            "Parkhurst",
            "Parkinson",
            "Parklea",
            "Parkside",
            "Parkside",
            "Parkville",
            "Parkwood",
            "Parkwood",
            "Parmelia",
            "Paroo",
            "Parramatta",
            "Parramatta Park",
            "Parrearra",
            "Pasadena",
            "Pascoe Vale",
            "Pascoe Vale South",
            "Patterson Lakes",
            "Payneham",
            "Payneham South",
            "Paynesville",
            "Peachester",
            "Peak Hill",
            "Peakhurst",
            "Peakhurst Heights",
            "Pearce",
            "Pearce",
            "Pearcedale",
            "Pearsall",
            "Pegs Creek",
            "Pelican Waters",
            "Pemberton",
            "Pemulwuy",
            "Pendle Hill",
            "Penguin",
            "Pennant Hills",
            "Pennington",
            "Penola",
            "Penrith",
            "Penrith Municipality",
            "Penshurst",
            "Peppermint Grove",
            "Peregian Beach",
            "Peregian Springs",
            "Perenjori",
            "Perth",
            "Perth",
            "Perth city centre",
            "Peterborough",
            "Peterhead",
            "Petersham",
            "Petrie",
            "Petrie Terrace",
            "Phillip",
            "Phillip",
            "Phillip Island",
            "Pialba",
            "Piara Waters",
            "Piccadilly",
            "Picnic Point",
            "Picton",
            "Pie Creek",
            "Pimlico",
            "Pimpama",
            "Pine Mountain",
            "Pingelly",
            "Pinjarra",
            "Pioneer",
            "Pitt Town",
            "Pittsworth",
            "Plainland",
            "Plantagenet Shire",
            "Playford",
            "Plenty",
            "Plumpton",
            "Plumpton",
            "Plympton",
            "Plympton Park",
            "Point Clare",
            "Point Cook",
            "Point Frederick",
            "Point Lonsdale",
            "Point Piper",
            "Point Vernon",
            "Pokolbin",
            "Pomona",
            "Pooraka",
            "Pormpuraaw",
            "Port Adelaide",
            "Port Adelaide Enfield",
            "Port Augusta",
            "Port Augusta West",
            "Port Broughton",
            "Port Denison",
            "Port Douglas",
            "Port Elliot",
            "Port Fairy",
            "Port Hacking",
            "Port Hedland",
            "Port Kembla",
            "Port Kennedy",
            "Port Lincoln",
            "Port Macquarie",
            "Port Macquarie-Hastings",
            "Port Melbourne",
            "Port Noarlunga",
            "Port Noarlunga South",
            "Port Phillip",
            "Port Pirie",
            "Port Pirie City and Dists",
            "Port Pirie South",
            "Port Pirie West",
            "Port Sorell",
            "Port Stephens Shire",
            "Port Willunga",
            "Portarlington",
            "Portland",
            "Portland",
            "Potts Point",
            "Pottsville",
            "Pottsville Beach",
            "Prahran",
            "Prairiewood",
            "Preston",
            "Prestons",
            "Primbee",
            "Princes Hill",
            "Proserpine",
            "Prospect",
            "Prospect",
            "Prospect Vale",
            "Puckapunyal",
            "Pullenvale",
            "Punchbowl",
            "Putney",
            "Pymble",
            "Pyrenees",
            "Pyrmont",
            "Quairading",
            "Quakers Hill",
            "Quarry Hill",
            "Queanbeyan",
            "Queanbeyan East",
            "Queanbeyan West",
            "Queens Park",
            "Queens Park",
            "Queenscliff",
            "Queenscliff",
            "Queenscliffe",
            "Queenstown",
            "Queenstown",
            "Queenton",
            "Quilpie",
            "Quindalup",
            "Quinns Rocks",
            "Quirindi",
            "Quorn",
            "Raby",
            "Raceview",
            "Raglan",
            "Railway Estate",
            "Rainbow Beach",
            "Ramsgate",
            "Ramsgate Beach",
            "Randwick",
            "Ranelagh",
            "Rangeville",
            "Rangeway",
            "Rangewood",
            "Rankin Park",
            "Rapid Creek",
            "Rasmussen",
            "Rathmines",
            "Ravenhall",
            "Ravenshoe",
            "Ravensthorpe",
            "Ravenswood",
            "Ravenswood",
            "Raworth",
            "Raymond Terrace",
            "Razorback",
            "Red Cliffs",
            "Red Hill",
            "Red Hill",
            "Redan",
            "Redbank",
            "Redbank Plains",
            "Redcliffe",
            "Redfern",
            "Redhead",
            "Redland",
            "Redland Bay",
            "Redlynch",
            "Redwood Park",
            "Reedy Creek",
            "Regans Ford",
            "Regency Downs",
            "Regents Park",
            "Regents Park",
            "Reid",
            "Renmark",
            "Renmark Paringa",
            "Renmark West",
            "Renown Park",
            "Research",
            "Reservoir",
            "Revesby",
            "Revesby Heights",
            "Reynella",
            "Reynella East",
            "Rhodes",
            "Richardson",
            "Richlands",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond Valley",
            "Ridgehaven",
            "Ridgewood",
            "Ridleyton",
            "Ringwood",
            "Ringwood East",
            "Ringwood North",
            "Ripponlea",
            "Risdon Park",
            "Risdon Park South",
            "Risdon Vale",
            "River Heads",
            "Riverhills",
            "Riverside",
            "Riverstone",
            "Riverton",
            "Rivervale",
            "Riverview",
            "Riverview",
            "Riverwood",
            "Rivett",
            "Robe",
            "Robertson",
            "Robertson",
            "Robina",
            "Robinvale",
            "Rochedale",
            "Rochedale South",
            "Rocherlea",
            "Rochester",
            "Rockbank",
            "Rockdale",
            "Rockhampton",
            "Rockingham",
            "Rockingham city centre",
            "Rocklea",
            "Rockville",
            "Rodd Point",
            "Roebuck",
            "Rokeby",
            "Roleystone",
            "Roma",
            "Romaine",
            "Romsey",
            "Rooty Hill",
            "Roper Gulf",
            "Ropes Crossing",
            "Rosanna",
            "Rose Bay",
            "Rose Park",
            "Rosebery",
            "Rosebery",
            "Rosebud",
            "Rosebud West",
            "Rosedale",
            "Rosehill",
            "Roselands",
            "Rosemeadow",
            "Rosemount",
            "Rosenthal Heights",
            "Rosetta",
            "Roseville",
            "Roseville Chase",
            "Rosewater",
            "Rosewood",
            "Ross",
            "Ross Creek",
            "Rosslea",
            "Rosslyn Park",
            "Rossmoyne",
            "Rostrevor",
            "Rothwell",
            "Rouse Hill",
            "Rowville",
            "Roxburgh Park",
            "Roxby Downs",
            "Royal Park",
            "Royston Park",
            "Rozelle",
            "Runaway Bay",
            "Runcorn",
            "Rural View",
            "Ruse",
            "Rushcutters Bay",
            "Rushworth",
            "Russell Island",
            "Russell Lea",
            "Russell Vale",
            "Rutherford",
            "Rutherglen",
            "Rydalmere",
            "Ryde",
            "Rye",
            "Sadadeen",
            "Sadleir",
            "Sadliers Crossing",
            "Safety Bay",
            "Safety Beach",
            "Saint Albans",
            "Saint Andrews",
            "Saint Andrews Beach",
            "Saint Helena",
            "Saint Ives",
            "Saint Kilda",
            "Saint Leonards",
            "Saint Leonards",
            "Saint Peters",
            "Salamander Bay",
            "Sale",
            "Salisbury",
            "Salisbury",
            "Salisbury Downs",
            "Salisbury East",
            "Salisbury Heights",
            "Salisbury North",
            "Salisbury Park",
            "Salisbury Plain",
            "Salt Ash",
            "Salter Point",
            "Samford Valley",
            "Samson",
            "San Remo",
            "San Remo",
            "Sanctuary Point",
            "Sandford",
            "Sandgate",
            "Sandhurst",
            "Sandringham",
            "Sandringham",
            "Sandstone",
            "Sandstone Point",
            "Sandy Bay",
            "Sandy Beach",
            "Sans Souci",
            "Sapphire Beach",
            "Saratoga",
            "Sarina",
            "Sassafras",
            "Sawtell",
            "Scarborough",
            "Scarborough",
            "Scarness",
            "Scenic Rim",
            "Schofields",
            "Scone",
            "Scoresby",
            "Scottsdale",
            "Scullin",
            "Seabrook",
            "Seacliff",
            "Seacliff Park",
            "Seacombe Gardens",
            "Seacombe Heights",
            "Seaford",
            "Seaford",
            "Seaford Meadows",
            "Seaford Rise",
            "Seaforth",
            "Seaholme",
            "Seaton",
            "Seaview Downs",
            "Sebastopol",
            "Secret Harbour",
            "Seddon",
            "Sefton",
            "Sefton Park",
            "Selby",
            "Sellicks Beach",
            "Semaphore",
            "Semaphore Park",
            "Semaphore South",
            "Serpentine",
            "Serpentine-Jarrahdale",
            "Seven Hills",
            "Seven Hills",
            "Seven Mile Beach",
            "Seventeen Mile Rocks",
            "Seville",
            "Seville Grove",
            "Seymour",
            "Shailer Park",
            "Shalvey",
            "Shark Bay",
            "Sharon",
            "Shearwater",
            "Sheffield",
            "Sheidow Park",
            "Sheldon",
            "Shell Cove",
            "Shelley",
            "Shellharbour",
            "Shelly Beach",
            "Shenton Park",
            "Shepparton",
            "Shepparton East",
            "Shoal Bay",
            "Shoalhaven Heads",
            "Shoalhaven Shire",
            "Shoalwater",
            "Shorewell Park",
            "Shorncliffe",
            "Shortland",
            "Silkstone",
            "Silvan",
            "Silver Sands",
            "Silverdale",
            "Silverwater",
            "Sinagra",
            "Singleton",
            "Singleton",
            "Singleton Heights",
            "Sinnamon Park",
            "Sippy Downs",
            "Skennars Head",
            "Skye",
            "Slacks Creek",
            "Slade Point",
            "Smithfield",
            "Smithfield",
            "Smithfield Plains",
            "Smiths Lake",
            "Smithton",
            "Smythes Creek",
            "Smythesdale",
            "Snug",
            "Soldiers Hill",
            "Soldiers Hill",
            "Soldiers Point",
            "Solomontown",
            "Somers",
            "Somersby",
            "Somerset",
            "Somerset",
            "Somerton Park",
            "Somerville",
            "Somerville",
            "Sorell",
            "Sorrento",
            "Sorrento",
            "South Albury",
            "South Bathurst",
            "South Bowenfels",
            "South Brighton",
            "South Brisbane",
            "South Bunbury",
            "South Burnett",
            "South Carnarvon",
            "South Coogee",
            "South Fremantle",
            "South Gippsland",
            "South Gladstone",
            "South Grafton",
            "South Granville",
            "South Guildford",
            "South Hedland",
            "South Hobart",
            "South Hurstville",
            "South Kalgoorlie",
            "South Kempsey",
            "South Kingsville",
            "South Kolan",
            "South Lake",
            "South Launceston",
            "South Lismore",
            "South Mackay",
            "South Maclean",
            "South Melbourne",
            "South Morang",
            "South Murwillumbah",
            "South Nowra",
            "South Penrith",
            "South Perth",
            "South Plympton",
            "South Tamworth",
            "South Toowoomba",
            "South Townsville",
            "South Turramurra",
            "South Wentworthville",
            "South West Rocks",
            "South Windsor",
            "South Yarra",
            "South Yunderup",
            "Southbank",
            "Southern Downs",
            "Southern Grampians",
            "Southern Mallee",
            "Southern Midlands",
            "Southern River",
            "Southport",
            "Southside",
            "Spalding",
            "Spearwood",
            "Speers Point",
            "Spence",
            "Spencer Park",
            "Spotswood",
            "Spreyton",
            "Spring Farm",
            "Spring Hill",
            "Springdale Heights",
            "Springfield",
            "Springfield Lakes",
            "Springsure",
            "Springvale",
            "Springvale",
            "Springvale South",
            "Springwood",
            "Springwood",
            "St Agnes",
            "St Albans Park",
            "St Andrews",
            "St Clair",
            "St George",
            "St George Ranges",
            "St Georges",
            "St Helena",
            "St Helens",
            "St Helens Park",
            "St Huberts Island",
            "St Ives Chase",
            "St James",
            "St Johns Park",
            "St Kilda East",
            "St Kilda West",
            "St Leonards",
            "St Marys",
            "St Morris",
            "St Peters",
            "St. Georges Basin",
            "Stafford",
            "Stafford Heights",
            "Stanhope Gardens",
            "Stanmore",
            "Stanthorpe",
            "Stanwell Park",
            "Stawell",
            "Stirling",
            "Stirling",
            "Stirling",
            "Stirling North",
            "Stockton",
            "Stoneville",
            "Stonnington",
            "Stonyfell",
            "Stratford",
            "Strathalbyn",
            "Strathalbyn",
            "Strathbogie",
            "Strathdale",
            "Strathfield",
            "Strathfield South",
            "Strathfieldsaye",
            "Strathmerton",
            "Strathmore",
            "Strathpine",
            "Stratton",
            "Streaky Bay",
            "Stretton",
            "Stuart",
            "Stuart Park",
            "Sturt",
            "Subiaco",
            "Success",
            "Suffolk Park",
            "Summer Hill",
            "Summerhill",
            "Summerland Point",
            "Sun Valley",
            "Sunbury",
            "Sunnybank",
            "Sunnybank Hills",
            "Sunrise Beach",
            "Sunset",
            "Sunset Beach",
            "Sunshine",
            "Sunshine Bay",
            "Sunshine Beach",
            "Sunshine Coast",
            "Sunshine North",
            "Sunshine West",
            "Surf Coast",
            "Surfers Paradise",
            "Surfside",
            "Surrey Downs",
            "Surrey Hills",
            "Surry Hills",
            "Sussex Inlet",
            "Sutherland",
            "Sutherland Shire",
            "Sutton",
            "Svensson Heights",
            "Swan",
            "Swan Hill",
            "Swan View",
            "Swanbourne",
            "Swansea",
            "Sydenham",
            "Sydenham",
            "Sydney",
            "Sydney Central Business District",
            "Sydney Olympic Park",
            "Sylvania",
            "Sylvania Waters",
            "Table Top",
            "Tahmoor",
            "Taigum",
            "Tailem Bend",
            "Tallai",
            "Tallangatta",
            "Tallebudgera",
            "Tallebudgera Valley",
            "Tamarama",
            "Tamborine",
            "Tamborine Mountain",
            "Tammin",
            "Tamworth",
            "Tamworth Municipality",
            "Tanah Merah",
            "Tanawha",
            "Tanilba Bay",
            "Tannum Sands",
            "Tanunda",
            "Taperoo",
            "Tapping",
            "Tara",
            "Taranganba",
            "Tarcoola Beach",
            "Taree",
            "Taren Point",
            "Taringa",
            "Tarneit",
            "Taroona",
            "Tarragindi",
            "Tarrawanna",
            "Tarro",
            "Tascott",
            "Tasman Peninsula",
            "Tathra",
            "Tatiara",
            "Tatton",
            "Tatura",
            "Taylors Hill",
            "Taylors Lakes",
            "Tea Gardens",
            "Tea Tree Gully",
            "Tecoma",
            "Teesdale",
            "Telarah",
            "Telfer",
            "Telina",
            "Telopea",
            "Temora",
            "Temora Municipality",
            "Tempe",
            "Templestowe",
            "Templestowe Lower",
            "Tenambit",
            "Teneriffe",
            "Tennant Creek",
            "Tennyson",
            "Tennyson Point",
            "Tenterfield",
            "Tenterfield Municipality",
            "Teralba",
            "Terang",
            "Terranora",
            "Terrigal",
            "Terry Hills",
            "Tewantin",
            "Thabeban",
            "The Basin",
            "The Coorong",
            "The Entrance",
            "The Entrance North",
            "The Gap",
            "The Gap",
            "The Gemfields",
            "The Hill",
            "The Hills Shire",
            "The Junction",
            "The Oaks",
            "The Patch",
            "The Ponds",
            "The Range",
            "The Rock",
            "The Rocks",
            "The Vines",
            "Thebarton",
            "Theodore",
            "Thirlmere",
            "Thirroul",
            "Thomastown",
            "Thomson",
            "Thornbury",
            "Thorneside",
            "Thornlands",
            "Thornleigh",
            "Thornlie",
            "Thornton",
            "Three Springs",
            "Thurgoona",
            "Thursday Island",
            "Tieri",
            "Tighes Hill",
            "Timboon",
            "Tin Can Bay",
            "Tinana",
            "Tingalpa",
            "Tingira Heights",
            "Tinonee",
            "Tivoli",
            "Tiwi",
            "Tiwi Islands",
            "Tocumwal",
            "Tolga",
            "Tolland",
            "Tom Price",
            "Tomakin",
            "Tomerong",
            "Tongala",
            "Toodyay",
            "Toogoolawah",
            "Toogoom",
            "Toongabbie",
            "Tooradin",
            "Toorak",
            "Toorak Gardens",
            "Toormina",
            "Tootgarook",
            "Toowong",
            "Toowoomba",
            "Toronto",
            "Torquay",
            "Torquay",
            "Torrens",
            "Torrens Park",
            "Torrensville",
            "Torres",
            "Torres Strait Island Region",
            "Toukley",
            "Townsville",
            "Townview",
            "Towong",
            "Towradgi",
            "Trafalgar",
            "Trangie",
            "Tranmere",
            "Tranmere",
            "Traralgon",
            "Travancore",
            "Trayning",
            "Tregear",
            "Trentham",
            "Trevallyn",
            "Trigg",
            "Trinity Beach",
            "Trinity Gardens",
            "Trinity Park",
            "Trott Park",
            "Truganina",
            "Trunding",
            "Tuart Hill",
            "Tuggerawong",
            "Tugun",
            "Tullamarine",
            "Tully",
            "Tumbarumba",
            "Tumbi Vmbi",
            "Tumby Bay",
            "Tumut",
            "Tuncurry",
            "Tura Beach",
            "Turner",
            "Turners Beach",
            "Tuross Head",
            "Turramurra",
            "Turrella",
            "Turvey Park",
            "Tusmore",
            "Tweed",
            "Tweed Heads",
            "Tweed Heads South",
            "Tweed Heads West",
            "Twin Waters",
            "Two Rocks",
            "Two Wells",
            "Tyabb",
            "Ulladulla",
            "Ultimo",
            "Ulverstone",
            "Umina Beach",
            "Unanderra",
            "Underdale",
            "Underwood",
            "Unley",
            "Unley Park",
            "Upper Burnie",
            "Upper Caboolture",
            "Upper Coomera",
            "Upper Gascoyne",
            "Upper Hunter Shire",
            "Upper Kedron",
            "Upper Lachlan Shire",
            "Upper Mount Gravatt",
            "Upwey",
            "Uralla",
            "Urangan",
            "Urraween",
            "Urunga",
            "Usher",
            "Utakarra",
            "Vale Park",
            "Valentine",
            "Valla Beach",
            "Valley Heights",
            "Valley View",
            "Varsity Lakes",
            "Vasse",
            "Vaucluse",
            "Vermont",
            "Vermont South",
            "Victor Harbor",
            "Victoria Daly",
            "Victoria Park",
            "Victoria Plains",
            "Victoria Point",
            "Viewbank",
            "Villawood",
            "Vincent",
            "Vincent",
            "Vincentia",
            "Vineyard",
            "Virginia",
            "Virginia",
            "Virginia",
            "Viveash",
            "Voyager Point",
            "Wacol",
            "Wadalba",
            "Wadeye",
            "Wagait",
            "Wagaman",
            "Wagga Wagga",
            "Waggrakine",
            "Wagin",
            "Wahgunyah",
            "Wahroonga",
            "Waikerie",
            "Waikiki",
            "Waitara",
            "Wakefield",
            "Wakeley",
            "Wakerley",
            "Walcha",
            "Walgett",
            "Walkerston",
            "Walkervale",
            "Walkerville",
            "Walkley Heights",
            "Wallacia",
            "Wallalong",
            "Wallan",
            "Wallaroo",
            "Wallerawang",
            "Wallington",
            "Walloon",
            "Wallsend",
            "Wamberal",
            "Wamboin",
            "Wamuran",
            "Wandal",
            "Wandana Heights",
            "Wandering",
            "Wandi",
            "Wandin North",
            "Wandina",
            "Wandoan",
            "Wandong",
            "Wangaratta",
            "Wangi Wangi",
            "Wanguri",
            "Wannanup",
            "Wanneroo",
            "Wanniassa",
            "Wantirna",
            "Wantirna South",
            "Warabrook",
            "Waramanga",
            "Warana",
            "Waratah",
            "Waratah West",
            "Waratah/Wynyard",
            "Warburton",
            "Wareemba",
            "Warialda",
            "Warilla",
            "Warnbro",
            "Warner",
            "Warners Bay",
            "Waroona",
            "Warracknabeal",
            "Warradale",
            "Warragamba",
            "Warragul",
            "Warrandyte",
            "Warrane",
            "Warranwood",
            "Warrawee",
            "Warrawong",
            "Warren",
            "Warren Shire",
            "Warriewood",
            "Warrimoo",
            "Warrnambool",
            "Warrumbungle Shire",
            "Warwick",
            "Warwick",
            "Warwick Farm",
            "Watanobbi",
            "Waterford",
            "Waterford West",
            "Waterloo Corner",
            "Watermans Bay",
            "Waterview Heights",
            "Waterways",
            "Watson",
            "Watsonia",
            "Watsonia North",
            "Wattle Grove",
            "Wattle Grove",
            "Wattle Park",
            "Wattle Ponds",
            "Wattle Range",
            "Wattleglen",
            "Wauchope",
            "Waurn Ponds",
            "Wavell Heights",
            "Waverley",
            "Waverley",
            "Waverton",
            "Wayville",
            "Weddin",
            "Wee Waa",
            "Weetangera",
            "Weipa",
            "Wellard",
            "Wellesley Islands",
            "Wellington",
            "Wellington",
            "Wellington Point",
            "Wembley",
            "Wembley Downs",
            "Wendouree",
            "Wentworth",
            "Wentworth Falls",
            "Wentworth Point",
            "Wentworthville",
            "Werribee",
            "Werribee South",
            "Werrington",
            "Werrington County",
            "Werrington Downs",
            "Werris Creek",
            "Wesburn",
            "West Albury",
            "West Arnhem",
            "West Arthur",
            "West Ballina",
            "West Bathurst",
            "West Beach",
            "West Beach",
            "West Busselton",
            "West Coast",
            "West Croydon",
            "West End",
            "West Footscray",
            "West Gladstone",
            "West Gosford",
            "West Haven",
            "West Hindmarsh",
            "West Hobart",
            "West Hoxton",
            "West Kempsey",
            "West Lakes",
            "West Lakes Shore",
            "West Lamington",
            "West Launceston",
            "West Leederville",
            "West Mackay",
            "West Melbourne",
            "West Moonah",
            "West Nowra",
            "West Pennant Hills",
            "West Perth",
            "West Pymble",
            "West Rockhampton",
            "West Ryde",
            "West Tamar",
            "West Tamworth",
            "West Ulverstone",
            "West Wallsend",
            "West Wimmera",
            "West Wodonga",
            "West Wollongong",
            "West Woombye",
            "West Wyalong",
            "Westbourne Park",
            "Westbrook",
            "Westbury",
            "Westcourt",
            "Westdale",
            "Western Downs",
            "Westlake",
            "Westleigh",
            "Westmead",
            "Westmeadows",
            "Westminster",
            "Weston",
            "Westonia",
            "Wetherill Park",
            "Whalan",
            "Whale Beach",
            "Wheeler Heights",
            "Wheelers Hill",
            "White Gum Valley",
            "White Hills",
            "White Rock",
            "Whitebridge",
            "Whitehorse",
            "Whitfield",
            "Whitsunday",
            "Whitsundays",
            "Whittington",
            "Whittlesea",
            "Whyalla",
            "Whyalla Jenkins",
            "Whyalla Norrie",
            "Whyalla Playford",
            "Whyalla Stuart",
            "Wickepin",
            "Wickham",
            "Wickham",
            "Wilberforce",
            "Wiley Park",
            "Willagee",
            "Willaston",
            "Willetton",
            "Williams",
            "Williams Landing",
            "Williamstown",
            "Williamstown",
            "Williamstown North",
            "Williamtown",
            "Willmot",
            "Willoughby",
            "Willoughby East",
            "Willow Vale",
            "Willowbank",
            "Willunga",
            "Wilson",
            "Wilsonton",
            "Wilsonton Heights",
            "Wilston",
            "Wilton",
            "Wiluna",
            "Winchelsea",
            "Windale",
            "Windang",
            "Windaroo",
            "Windradyne",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor Downs",
            "Windsor Gardens",
            "Wingecarribee",
            "Wingham",
            "Winmalee",
            "Winston",
            "Winston Hills",
            "Winthrop",
            "Winton",
            "Wishart",
            "Withcott",
            "Withers",
            "Witta",
            "Wodonga",
            "Wollert",
            "Wolli Creek",
            "Wollondilly",
            "Wollongbar",
            "Wollongong",
            "Wollongong city centre",
            "Wollstonecraft",
            "Wondai",
            "Wondunna",
            "Wonga Park",
            "Wongaling Beach",
            "Wongan-Ballidu",
            "Wongawallan",
            "Wonthaggi",
            "Wonthella",
            "Woodanilling",
            "Woodberry",
            "Woodbine",
            "Woodbridge",
            "Woodcroft",
            "Woodcroft",
            "Woodend",
            "Woodend",
            "Woodford",
            "Woodford",
            "Woodgate",
            "Woodpark",
            "Woodridge",
            "Woodridge",
            "Woodrising",
            "Woodroffe",
            "Woodside",
            "Woodvale",
            "Woodville",
            "Woodville Gardens",
            "Woodville North",
            "Woodville Park",
            "Woodville South",
            "Woodville West",
            "Woody Point",
            "Woolgoolga",
            "Woollahra",
            "Woolloomooloo",
            "Woolloongabba",
            "Woolooware",
            "Wooloowin",
            "Woombye",
            "Woongarrah",
            "Woonona",
            "Woorabinda",
            "Woori Yallock",
            "Woorim",
            "Wooroloo",
            "Woorree",
            "Woree",
            "Worongary",
            "Woronora",
            "Woronora Heights",
            "Worrigee",
            "Woy Woy",
            "Wright",
            "Wudinna",
            "Wujal Wujal",
            "Wulagi",
            "Wulguru",
            "Wulkuraka",
            "Wundowie",
            "Wurruk",
            "Wurrumiyanga",
            "Wurtulla",
            "Wy Yung",
            "Wyalkatchem",
            "Wyee",
            "Wyee Point",
            "Wyndham",
            "Wyndham Vale",
            "Wyndham-East Kimberley",
            "Wynn Vale",
            "Wynnum",
            "Wynnum West",
            "Wynyard",
            "Wyoming",
            "Wyong",
            "Wyongah",
            "Wyreema",
            "Yackandandah",
            "Yagoona",
            "Yakamia",
            "Yalgoo",
            "Yallah",
            "Yallambie",
            "Yallingup",
            "Yallourn North",
            "Yalyalup",
            "Yamanto",
            "Yamba",
            "Yanchep",
            "Yandina",
            "Yangebup",
            "Yankalilla",
            "Yaroomba",
            "Yarra",
            "Yarra Glen",
            "Yarra Junction",
            "Yarra Ranges",
            "Yarrabah",
            "Yarrabilba",
            "Yarragon",
            "Yarralumla",
            "Yarram",
            "Yarraman",
            "Yarrambat",
            "Yarravel",
            "Yarraville",
            "Yarrawarrah",
            "Yarrawonga",
            "Yarriambiack",
            "Yass",
            "Yass Valley",
            "Yatala",
            "Yea",
            "Yeerongpilly",
            "Yenda",
            "Yennora",
            "Yeppoon",
            "Yeppoon city centre",
            "Yeronga",
            "Yerrinbool",
            "Yilgarn",
            "Yokine",
            "Yoogali",
            "York",
            "Yorke Peninsula",
            "Yorkeys Knob",
            "Young",
            "Youngtown",
            "Yowie Bay",
            "Yulara",
            "Yungaburra",
            "Zetland",
            "Zillmere",
            "Zilzie",
            "Zuccoli"
        ]
    },
    {
        "label": "Austria",
        "code": "AT",
        "phone": "43",
        "cities": [
            "Abfaltersbach",
            "Absam",
            "Absdorf",
            "Abtenau",
            "Abtissendorf",
            "Abwinden",
            "Achau",
            "Achenkirch",
            "Aderklaa",
            "Adlwang",
            "Admont",
            "Adnet",
            "Afiesl",
            "Aflenz Kurort",
            "Aggsbach",
            "Ahorn",
            "Aibl",
            "Aichkirchen",
            "Aigen im Ennstal",
            "Aigen im Mühlkreis",
            "Ainet",
            "Aistersheim",
            "Albeck",
            "Alberndorf in der Riedmark",
            "Alberschwende",
            "Albersdorf",
            "Aldrans",
            "Alkoven",
            "Alland",
            "Allentsteig",
            "Allerheiligen bei Wildon",
            "Allerheiligen im Mühlkreis",
            "Allerheiligen im Mürztal",
            "Allhaming",
            "Allhartsberg",
            "Alpbach",
            "Altach",
            "Altaussee",
            "Altenberg bei Linz",
            "Altenburg",
            "Altendorf",
            "Altenfelden",
            "Altenmarkt an der Triesting",
            "Altenmarkt bei Fürstenfeld",
            "Altenmarkt bei Sankt Gallen",
            "Altenmarkt im Pongau",
            "Altheim",
            "Althofen",
            "Altlengbach",
            "Altlichtenberg",
            "Altlichtenwarth",
            "Altmelon",
            "Altmünster",
            "Amlach",
            "Ampass",
            "Ampflwang",
            "Amras",
            "Amstetten",
            "Andau",
            "Andelsbuch",
            "Andlersdorf",
            "Andorf",
            "Andritz",
            "Angath",
            "Anger",
            "Anger",
            "Angerberg",
            "Angern an der March",
            "Anif",
            "Annabichl",
            "Anras",
            "Ansfelden",
            "Antau",
            "Anthering",
            "Apetlon",
            "Apfelberg",
            "Arbesbach",
            "Ardagger Stift",
            "Ardning",
            "Arnfels",
            "Arnoldstein",
            "Arnreit",
            "Arriach",
            "Arzberg",
            "Arzl",
            "Arzl im Pitztal",
            "Aschach an der Donau",
            "Aschach an der Steyr",
            "Aschau",
            "Aschau im Zillertal",
            "Aschbach Markt",
            "Aspang Markt",
            "Asparn an der Zaya",
            "Asperhofen",
            "Asten",
            "Attendorf",
            "Attersee",
            "Attnang",
            "Attnang-Puchheim",
            "Atzbach",
            "Atzenbrugg",
            "Atzesberg",
            "Au",
            "Au",
            "Au am Leithaberge",
            "Au an der Traun",
            "Audorf",
            "Auen",
            "Auersbach",
            "Auersthal",
            "Aurach",
            "Aurach am Hongar",
            "Aurolzmünster",
            "Ausserbraz",
            "Außervillgraten",
            "Axams",
            "Bachmanning",
            "Bad Aussee",
            "Bad Bleiberg",
            "Bad Blumau",
            "Bad Deutsch-Altenburg",
            "Bad Eisenkappel",
            "Bad Erlach",
            "Bad Fischau",
            "Bad Gams",
            "Bad Gastein",
            "Bad Gleichenberg",
            "Bad Goisern",
            "Bad Hall",
            "Bad Häring",
            "Bad Hofgastein",
            "Bad Ischl",
            "Bad Kleinkirchheim",
            "Bad Kreuzen",
            "Bad Leonfelden",
            "Bad Pirawarth",
            "Bad Radkersburg",
            "Bad Sankt Leonhard im Lavanttal",
            "Bad Sauerbrunn",
            "Bad Schallerbach",
            "Bad Schönau",
            "Bad Tatzmannsdorf",
            "Bad Traunstein",
            "Bad Vöslau",
            "Bad Waltersdorf",
            "Bad Zell",
            "Baden",
            "Badersdorf",
            "Baierdorf-Umgebung",
            "Bairisch Kölldorf",
            "Baldramsdorf",
            "Bärnbach",
            "Bärnkopf",
            "Bartholomäberg",
            "Barwies",
            "Batschuns",
            "Baumgarten bei Gnas",
            "Baumkirchen",
            "Behamberg",
            "Berg",
            "Berg",
            "Berg im Drautal",
            "Bergern",
            "Bergham",
            "Bergheim",
            "Bergland",
            "Bernardin",
            "Berndorf",
            "Berndorf",
            "Berndorf bei Salzburg",
            "Bernhardsthal",
            "Bernstein",
            "Berwang",
            "Bezau",
            "Biberbach",
            "Biberwier",
            "Bichlbach",
            "Biedermannsdorf",
            "Bierbaum am Auersbach",
            "Bildstein",
            "Birgitz",
            "Birkfeld",
            "Bisamberg",
            "Bischofshofen",
            "Bischofstetten",
            "Bizau",
            "Blaindorf",
            "Bleiburg",
            "Blindenmarkt",
            "Blons",
            "Bludenz",
            "Bludesch",
            "Bockfließ",
            "Bocksdorf",
            "Bodensdorf",
            "Böheimkirchen",
            "Bramberg am Wildkogel",
            "Brand",
            "Brandenberg",
            "Brandln",
            "Braunau am Inn",
            "Braunau Neustadt",
            "Bregenz",
            "Breitenau",
            "Breitenbach am Inn",
            "Breitenbrunn",
            "Breitenfeld am Tannenriegel",
            "Breitenfeld an der Rittschein",
            "Breitenfurt bei Wien",
            "Breitenwang",
            "Bretstein",
            "Brixen im Thale",
            "Brixlegg",
            "Bromberg",
            "Bruck am Ziller",
            "Bruck an der Großglocknerstraße",
            "Bruck an der Leitha",
            "Bruck an der Mur",
            "Brückl",
            "Bruckneudorf",
            "Brunn",
            "Brunn am Gebirge",
            "Brunn an der Schneebergbahn",
            "Brunn an der Wild",
            "Buch in Tirol",
            "Buchbach",
            "Buchkirchen",
            "Burgau",
            "Burgfried",
            "Burgfried",
            "Bürmoos",
            "Bürs",
            "Bürserberg",
            "Dalaas",
            "Damüls",
            "Debant",
            "Dechantskirchen",
            "Dellach",
            "Dellach im Drautal",
            "Desselbrunn",
            "Deuchendorf",
            "Deutsch Goritz",
            "Deutsch Griffen",
            "Deutsch Jahrndorf",
            "Deutsch Kaltenbrunn",
            "Deutsch-Griffen",
            "Deutsch-Wagram",
            "Deutschfeistritz",
            "Deutschkreutz",
            "Deutschlandsberg",
            "Dickerldorf",
            "Diemlach",
            "Dienersdorf",
            "Dienten am Hochkönig",
            "Diesseits",
            "Dietach",
            "Dietachdorf",
            "Dietersdorf",
            "Dietersdorf am Gnasbach",
            "Diex",
            "Dobersberg",
            "Döbriach",
            "Dölsach",
            "Donaustadt",
            "Donawitz",
            "Donnersbach",
            "Donnerskirchen",
            "Doppl",
            "Doren",
            "Dorfbeuern",
            "Dorfgastein",
            "Dörfl",
            "Dorfstetten",
            "Dornbirn",
            "Draschen",
            "Drasenhofen",
            "Drassburg",
            "Drösing",
            "Droß",
            "Düns",
            "Dünserberg",
            "Dürnau",
            "Dürnkrut",
            "Dürnstein",
            "Dürnstein in der Steiermark",
            "Ebbs",
            "Eben am Achensee",
            "Eben im Pongau",
            "Ebenau",
            "Ebenfurth",
            "Ebensee",
            "Ebenthal",
            "Ebenthal",
            "Eberau",
            "Ebergassing",
            "Eberndorf",
            "Eberschwang",
            "Ebersdorf",
            "Eberstalzell",
            "Eberstein",
            "Ebreichsdorf",
            "Ebriach",
            "Eching",
            "Echsenbach",
            "Eckartsau",
            "Edelsbach bei Feldbach",
            "Edelschrott",
            "Edelsgrub",
            "Edelstal",
            "Edelstauden",
            "Edlitz",
            "Edt bei Lambach",
            "Eferding",
            "Egg",
            "Eggenberg",
            "Eggenburg",
            "Eggendorf",
            "Eggern",
            "Eggersdorf bei Graz",
            "Ehenbichl",
            "Ehrenhausen",
            "Ehrwald",
            "Eibiswald",
            "Eichberg",
            "Eichenberg",
            "Eichfeld",
            "Eichgraben",
            "Eidenberg",
            "Eisbach",
            "Eisenerz",
            "Eisenkappel-Vellach",
            "Eisenstadt",
            "Eisenstadt Stadt",
            "Eisenstadt-Umgebung",
            "Eisgarn",
            "Elbigenalp",
            "Elixhausen",
            "Ellbögen",
            "Ellmau",
            "Elmen",
            "Elsbethen",
            "Eltendorf",
            "Emmersdorf an der Donau",
            "Empersdorf",
            "Endach",
            "Engelhartstetten",
            "Engerwitzdorf",
            "Enns",
            "Ennsdorf",
            "Enzenreith",
            "Enzersdorf an der Fischa",
            "Enzersfeld",
            "Eppenstein",
            "Erl",
            "Erlauf",
            "Ernstbrunn",
            "Ernsthofen",
            "Erpersdorf",
            "Erpfendorf",
            "Ertl",
            "Esch",
            "Eschenau",
            "Eugendorf",
            "Euratsfeld",
            "Faak am See",
            "Faistenau",
            "Falkenburg",
            "Falkenstein",
            "Fallbach",
            "Farrach",
            "Favoriten",
            "Feffernitz",
            "Fehring",
            "Feistritz am Wechsel",
            "Feistritz an der Drau",
            "Feistritz an der Gail",
            "Feistritz bei Knittelfeld",
            "Feistritz im Rosental",
            "Feistritz ob Bleiburg",
            "Feld am See",
            "Feldbach",
            "Feldkirch",
            "Feldkirchen an der Donau",
            "Feldkirchen bei Graz",
            "Feldkirchen District",
            "Feldkirchen in Kärnten",
            "Felixdorf",
            "Fels am Wagram",
            "Fendels",
            "Ferlach",
            "Ferndorf",
            "Fernitz",
            "Ferschnitz",
            "Fieberbrunn",
            "Fiecht",
            "Filzmoos",
            "Finkenberg",
            "Finkenstein",
            "Finkenstein am Faaker See",
            "Fischamend-Markt",
            "Fischbach",
            "Fisching",
            "Fiss",
            "Flachau",
            "Fladnitz im Raabtal",
            "Flatschach",
            "Flattach",
            "Flaurling",
            "Fliess",
            "Flirsch",
            "Floing",
            "Floridsdorf",
            "Föderlach I",
            "Fohnsdorf",
            "Forchach",
            "Forchtenstein",
            "Fornach",
            "Forstau",
            "Frankenburg",
            "Frankenfels",
            "Frankenmarkt",
            "Frannach",
            "Frantschach-Sankt Gertraud",
            "Frastanz",
            "Frauenberg",
            "Frauenkirchen",
            "Frauenstein",
            "Fraxern",
            "Freidorf an der Laßnitz",
            "Freiland bei Deutschlandsberg",
            "Freindorf",
            "Freistadt",
            "Fresach",
            "Fresen",
            "Friedberg",
            "Friesach",
            "Fritzens",
            "Frohnleiten",
            "Frohsdorf",
            "Fügen",
            "Fügenberg",
            "Fulpmes",
            "Fürnitz",
            "Fürstenfeld",
            "Furth an der Triesting",
            "Furth bei Göttweig",
            "Fuschl am See",
            "Fußach",
            "Gaaden",
            "Gaal",
            "Gabersdorf",
            "Gablitz",
            "Gaflenz",
            "Gailitz",
            "Gainfarn",
            "Gaißau",
            "Gallizien",
            "Gallmannsegg",
            "Gallneukirchen",
            "Gallspach",
            "Gallzein",
            "Galtür",
            "Gaming",
            "Gamlitz",
            "Gampern",
            "Gams bei Hieflau",
            "Gänserndorf",
            "Ganz",
            "Garanas",
            "Gars am Kamp",
            "Garsten",
            "Gaschurn",
            "Gasen",
            "Gaspoltshofen",
            "Gastern",
            "Gattendorf",
            "Gaubitsch",
            "Gaumberg",
            "Gaweinstal",
            "Geboltskirchen",
            "Gedersdorf",
            "Geidorf",
            "Geistthal",
            "Gemmersdorf",
            "Georgenberg",
            "Geras",
            "Gerasdorf bei Wien",
            "Gerersdorf",
            "Gerlamoos",
            "Gerlos",
            "Gerlosberg",
            "Gerolding",
            "Gersdorf an der Feistritz",
            "Gföhl",
            "Gießhübl",
            "Gitschtal",
            "Glanegg",
            "Glanegg",
            "Glasenbach",
            "Gleinstätten",
            "Gleisdorf",
            "Glinzendorf",
            "Globasnitz",
            "Glödnitz",
            "Gloggnitz",
            "Glojach",
            "Gmünd",
            "Gmünd",
            "Gmünd in Kärnten",
            "Gmunden",
            "Gnadendorf",
            "Gnadenwald",
            "Gnas",
            "Gneixendorf",
            "Gnesau",
            "Gniebing",
            "Göfis",
            "Going",
            "Goldwörth",
            "Göllersdorf",
            "Golling an der Salzach",
            "Gols",
            "Göpfritz an der Wild",
            "Görtschach",
            "Gosau",
            "Gosdorf",
            "Göss",
            "Gössenberg",
            "Gossendorf",
            "Gösting",
            "Göstling an der Ybbs",
            "Göttelsberg",
            "Götzendorf an der Leitha",
            "Götzens",
            "Götzis",
            "Grabersdorf",
            "Grafendorf bei Hartberg",
            "Grafenegg",
            "Grafenschachen",
            "Grafenschlag",
            "Grafenstein",
            "Grafenwörth",
            "Gralla",
            "Gramais",
            "Gramastetten",
            "Gramatneusiedl",
            "Grambach",
            "Grän",
            "Gratkorn",
            "Gratwein",
            "Graz",
            "Graz Stadt",
            "Greifenburg",
            "Grein",
            "Greinsfurth",
            "Greisdorf",
            "Gressenberg",
            "Gresten",
            "Gries",
            "Gries",
            "Gries am Brenner",
            "Gries im Sellrain",
            "Grieskirchen",
            "Griffen",
            "Grimmenstein",
            "Grins",
            "Grinzens",
            "Gröbming",
            "Grödig",
            "Groß Sankt Florian",
            "Groß-Engersdorf",
            "Groß-Enzersdorf",
            "Groß-Gerungs",
            "Groß-Schweinbarth",
            "Groß-Siegharts",
            "Grossarl",
            "Großdorf",
            "Großebersdorf",
            "Grossgmain",
            "Großharras",
            "Großhofen",
            "Grosshöflein",
            "Großkirchheim",
            "Großklein",
            "Großkrut",
            "Großlobming",
            "Großmugl",
            "Grosspetersdorf",
            "Grossraming",
            "Großriedenthal",
            "Großrußbach",
            "Großsölk",
            "Grosssulz",
            "Großweikersdorf",
            "Grünau im Almtal",
            "Grünbach am Schneeberg",
            "Grünburg",
            "Gschnitz",
            "Gschwandt",
            "Gugging",
            "Gumpoldskirchen",
            "Gundersdorf",
            "Günselsdorf",
            "Gunskirchen",
            "Guntersdorf",
            "Guntramsdorf",
            "Gurk",
            "Güssing",
            "Gutau",
            "Gutenbrunn",
            "Gutenstein",
            "Guttaring",
            "Güttenbach",
            "Haag",
            "Haag",
            "Haag am Hausruck",
            "Habach",
            "Hackerberg",
            "Hadersdorf am Kamp",
            "Hadres",
            "Hafendorf",
            "Hafnerbach",
            "Hagenberg im Mühlkreis",
            "Hagenbrunn",
            "Haibach im Mühlkreis",
            "Haid",
            "Haiden",
            "Haidershofen",
            "Haidl",
            "Haiming",
            "Hainburg an der Donau",
            "Hainersdorf",
            "Hainfeld",
            "Hainsdorf im Schwarzautal",
            "Hainzenberg",
            "Halbenrain",
            "Halbturn",
            "Hall bei Admont",
            "Hall in Tirol",
            "Hallein",
            "Hallwang",
            "Handenberg",
            "Hannersdorf",
            "Hard",
            "Hardegg",
            "Haringsee",
            "Harland",
            "Harmannsdorf",
            "Hart",
            "Hart",
            "Hart bei Graz",
            "Hart im Zillertal",
            "Hartberg",
            "Hartheim",
            "Hartkirchen",
            "Hartl",
            "Hartmannsdorf",
            "Haselbach",
            "Häselgehr",
            "Haslach an der Mühl",
            "Hatting",
            "Hatzendorf",
            "Haugschlag",
            "Haugsdorf",
            "Haunoldstein",
            "Haus",
            "Haus im Ennstal",
            "Hausbrunn",
            "Hauskirchen",
            "Hausleiten",
            "Hausmannstätten",
            "Hausmening",
            "Heidenreichstein",
            "Heiligenblut",
            "Heiligenbrunn",
            "Heiligeneich",
            "Heiligenkreuz",
            "Heiligenkreuz am Waasen",
            "Heiligenkreuz im Lafnitztal",
            "Heimschuh",
            "Heinfels",
            "Heiterwang",
            "Hellmonsödt",
            "Hengsberg",
            "Henndorf am Wallersee",
            "Hennersdorf",
            "Hermagor",
            "Hermagor District",
            "Hermagor-Pressegger See",
            "Hernals",
            "Hernstein",
            "Herrnbaumgarten",
            "Herzogenburg",
            "Herzogsdorf",
            "Hetzendorf",
            "Heugraben",
            "Hieflau",
            "Hietzing",
            "Himberg",
            "Himmelberg",
            "Himmelreich",
            "Hinterberg",
            "Hinterbrühl",
            "Hinterglemm",
            "Hinterleiten",
            "Hintersee",
            "Hippach",
            "Hirm",
            "Hirnsdorf",
            "Hirschbach",
            "Hirschegg",
            "Hirtenberg",
            "Hittisau",
            "Hitzendorf",
            "Hochburg-Ach",
            "Hochfilzen",
            "Hochleithen",
            "Hochpoint",
            "Höchst",
            "Hochtregist",
            "Hochwolkersdorf",
            "Hof",
            "Höf",
            "Hof",
            "Hof am Leithaberge",
            "Hof bei Salzburg",
            "Hof bei Straden",
            "Hofamt Priel",
            "Höfen",
            "Hofkirchen im Traunkreis",
            "Höflein",
            "Höfling",
            "Hofstätten an der Raab",
            "Hofstetten",
            "Hohenau",
            "Hohenau an der Raab",
            "Hohenberg",
            "Hoheneich",
            "Hohenems",
            "Hohenruppersdorf",
            "Hohentauern",
            "Hohenthurn",
            "Hohenweiler",
            "Hollabrunn",
            "Hollenegg",
            "Hollenstein an der Ybbs",
            "Hollenthon",
            "Hollersbach im Pinzgau",
            "Holzgau",
            "Holzhausen",
            "Hönigsberg",
            "Hopfgarten im Brixental",
            "Hopfgarten in Defereggen",
            "Hörbranz",
            "Horitschon",
            "Horn",
            "Hornstein",
            "Hörsching",
            "Hörtendorf",
            "Hötting",
            "Hundsheim",
            "Hürm",
            "Hüttau",
            "Hutten",
            "Hüttenberg",
            "Hüttschlag",
            "Igls",
            "Illmitz",
            "Ilz",
            "Imst",
            "Imsterberg",
            "Innerbraz",
            "Innere Stadt",
            "Innere Stadt",
            "Innerschwand",
            "Innervillgraten",
            "Innsbruck",
            "Innsbruck Stadt",
            "Inzenhof",
            "Inzersdorf im Kremstal",
            "Inzing",
            "Irdning",
            "Irrsdorf",
            "Irschen",
            "Ischgl",
            "Itter",
            "Jabing",
            "Jagerberg",
            "Jaidhof",
            "Jakomini",
            "Japons",
            "Jedenspeigen",
            "Jenbach",
            "Jennersdorf",
            "Jerzens",
            "Jochberg",
            "Johnsbach",
            "Jois",
            "Judenburg",
            "Judendorf",
            "Jungholz",
            "Kaibing",
            "Kainach bei Voitsberg",
            "Kainbach",
            "Kaindorf",
            "Kaindorf an der Sulm",
            "Kaisersdorf",
            "Kalsdorf bei Graz",
            "Kaltenbach",
            "Kaltenbach",
            "Kaltenleutgeben",
            "Kalwang",
            "Kammern im Liesingtal",
            "Kapellen",
            "Kapelln",
            "Kapfenberg",
            "Kapfenstein",
            "Kapfing",
            "Kappel am Krappfeld",
            "Kappl",
            "Kaprun",
            "Karlstetten",
            "Karres",
            "Karrösten",
            "Kartitsch",
            "Kasten bei Böheimkirchen",
            "Katsdorf",
            "Katzelsdorf",
            "Kaumberg",
            "Kauns",
            "Kautzen",
            "Kematen an der Krems",
            "Kematen an der Ybbs",
            "Kematen in Tirol",
            "Kemeten",
            "Kennelbach",
            "Keutschach am See",
            "Kierling",
            "Kilb",
            "Kindberg",
            "Kirchbach",
            "Kirchbach in Steiermark",
            "Kirchberg am Wagram",
            "Kirchberg am Walde",
            "Kirchberg am Wechsel",
            "Kirchberg an der Pielach",
            "Kirchberg an der Raab",
            "Kirchberg in Tirol",
            "Kirchberg ob der Donau",
            "Kirchbichl",
            "Kirchdorf an der Krems",
            "Kirchdorf in Tirol",
            "Kirchenviertel",
            "Kirchschlag",
            "Kirchschlag bei Linz",
            "Kirchschlag in der Buckligen Welt",
            "Kirchstetten",
            "Kirnberg an der Mank",
            "Kittsee",
            "Kitzbühel",
            "Kitzeck im Sausal",
            "Klaffer am Hochficht",
            "Klagenfurt",
            "Klagenfurt am Wörthersee",
            "Klagenfurt-Land District",
            "Klaus",
            "Klausen-Leopoldsdorf",
            "Kleblach-Lind",
            "Klein Sankt Paul",
            "Klein-Neusiedl",
            "Klein-Pöchlarn",
            "Kleinarl",
            "Kleinedling",
            "Kleinhöflein im Burgenland",
            "Kleinlobming",
            "Kleinmürbisch",
            "Kleinsöding",
            "Kleinsölk",
            "Kleinzell",
            "Klingenbach",
            "Klöch",
            "Kloster",
            "Klösterle",
            "Klosterneuburg",
            "Knittelfeld",
            "Kobenz",
            "Kobersdorf",
            "Koblach",
            "Köflach",
            "Kohfidisch",
            "Kohlberg",
            "Kohlschwarz",
            "Kolsass",
            "Kolsassberg",
            "Königsdorf",
            "Königstetten",
            "Königswiesen",
            "Kopfing im Innkreis",
            "Koppl",
            "Korneuburg",
            "Kössen",
            "Köstendorf",
            "Kötschach",
            "Kötschach-Mauthen",
            "Kottingbrunn",
            "Köttmannsdorf",
            "Krakaudorf",
            "Krakauhintermühlen",
            "Krakauschatten",
            "Kramsach",
            "Kraubath an der Mur",
            "Krems",
            "Krems an der Donau",
            "Krems an der Donau Stadt",
            "Kremsdorf",
            "Kremsmünster",
            "Krensdorf",
            "Krieglach",
            "Krimml",
            "Krispl",
            "Kritzendorf",
            "Kronstorf",
            "Krottendorf",
            "Krottendorf bei Ligist",
            "Krumau am Kamp",
            "Krumbach",
            "Krumbach Markt",
            "Krumegg",
            "Krumnussbaum",
            "Krumpendorf",
            "Krusdorf",
            "Kuchl",
            "Kufstein",
            "Kühnsdorf",
            "Kukmirn",
            "Kulm am Zirbitz",
            "Kumberg",
            "Kundl",
            "Laa an der Thaya",
            "Laab",
            "Laab im Walde",
            "Laahen",
            "Laakirchen",
            "Labuch",
            "Lackenbach",
            "Lackendorf",
            "Ladendorf",
            "Ladis",
            "Lafnitz",
            "Lambach",
            "Lamnitz",
            "Lamprechtshausen",
            "Landeck",
            "Landegg",
            "Landl",
            "Landskron",
            "Lang",
            "Langau",
            "Langegg bei Graz",
            "Langen",
            "Langenegg",
            "Längenfeld",
            "Langenlebarn-Oberaigen",
            "Langenlois",
            "Langenrohr",
            "Langenstein",
            "Langenwang",
            "Langenzersdorf",
            "Langholzfeld",
            "Langschlag",
            "Lannach",
            "Lans",
            "Lanzendorf",
            "Lanzenkirchen",
            "Lassee",
            "Lassnitzhöhe",
            "Launsdorf",
            "Laussa",
            "Lauterach",
            "Lavamünd",
            "Lavant",
            "Laxenburg",
            "Lech",
            "Lechaschau",
            "Ledenitzen",
            "Lehen",
            "Leiben",
            "Leibnitz",
            "Leisach",
            "Leitendorf",
            "Leitersdorf im Raabtal",
            "Leithaprodersdorf",
            "Leitring",
            "Leitzersdorf",
            "Lembach im Mühlkreis",
            "Lend",
            "Lend",
            "Lendorf",
            "Lengenfeld",
            "Lengfelden",
            "Lenzing",
            "Leoben",
            "Leobendorf",
            "Leobersdorf",
            "Leogang",
            "Leonding",
            "Leonstein",
            "Leopoldsdorf",
            "Leopoldsdorf im Marchfelde",
            "Leppen",
            "Lerchenfeld",
            "Lermoos",
            "Lesachtal",
            "Leutasch",
            "Leutschach",
            "Lichtenegg",
            "Lichtenegg",
            "Lichtenwörth",
            "Liebenau",
            "Liebenfels",
            "Lieboch",
            "Lienz",
            "Liesingtal",
            "Liezen",
            "Ligist",
            "Lilienfeld",
            "Lind",
            "Lingenau",
            "Linz",
            "Linz Stadt",
            "Litschau",
            "Litzelsdorf",
            "Lobersberg",
            "Lochau",
            "Lockenhaus",
            "Lödersdorf",
            "Lofer",
            "Loich",
            "Loipersbach im Burgenland",
            "Loipersdorf bei Fürstenfeld",
            "Loosdorf",
            "Loretto",
            "Lorüns",
            "Losenstein",
            "Ludesch",
            "Ludmannsdorf",
            "Luftenberg an der Donau",
            "Lunz am See",
            "Lurnfeld",
            "Lustenau",
            "Lutzmannsburg",
            "Mäder",
            "Magdalensberg",
            "Maierdorf",
            "Mailberg",
            "Maishofen",
            "Maissau",
            "Mallnitz",
            "Malta",
            "Mank",
            "Mannersdorf am Leithagebirge",
            "Mannersdorf an der Rabnitz",
            "Manning",
            "Mannsdorf an der Donau",
            "Mannswörth",
            "Marbach an der Donau",
            "Marchegg",
            "Marchtrenk",
            "Margarethen am Moos",
            "Maria Alm am Steinernen Meer",
            "Maria Ellend",
            "Maria Enzersdorf",
            "Maria Laach am Jauerling",
            "Maria Lankowitz",
            "Maria Lanzendorf",
            "Maria Neustift",
            "Maria Rain",
            "Maria Saal",
            "Maria Schmolln",
            "Maria Taferl",
            "Maria Wörth",
            "Maria-Anzbach",
            "Mariahof",
            "Mariapfarr",
            "Mariasdorf",
            "Mariastein",
            "Mariatal",
            "Mariatrost",
            "Mariazell",
            "Markersdorf an der Pielach",
            "Markgrafneusiedl",
            "Markt Allhau",
            "Markt Neuhodis",
            "Markt Piesting",
            "Markt Sankt Martin",
            "Martinsberg",
            "Marz",
            "Matrei am Brenner",
            "Matrei in Osttirol",
            "Mattersburg",
            "Mattighofen",
            "Mattsee",
            "Matzen",
            "Matzendorf",
            "Mauer bei Amstetten",
            "Mauerbach",
            "Mauerkirchen",
            "Maurach",
            "Mautbrücken",
            "Mautern",
            "Mautern in Steiermark",
            "Mauterndorf",
            "Mayrhofen",
            "Medraz",
            "Meggenhofen",
            "Mehrnbach",
            "Meidling",
            "Meiningen",
            "Melk",
            "Mellach",
            "Mellau",
            "Merkendorf",
            "Metnitz",
            "Mettersdorf am Saßbach",
            "Michaelerberg",
            "Michaelnbach",
            "Micheldorf",
            "Micheldorf in Oberösterreich",
            "Michelhausen",
            "Mieders",
            "Mieming",
            "Miesenbach",
            "Millstatt",
            "Mils bei Imst",
            "Mils bei Solbad Hall",
            "Mischendorf",
            "Mistelbach",
            "Mittelberg",
            "Mitterbach am Erlaufsee",
            "Mitterberghütten",
            "Mitterdorf an der Raab",
            "Mitterdorf im Mürztal",
            "Mitterhofen",
            "Mitterkirchen im Machland",
            "Mitterlabill",
            "Mitterndorf",
            "Mitterndorf an der Fischa",
            "Mitterndorf im Steirischen Salzkammergut",
            "Mitterpullendorf",
            "Mittersill",
            "Mittertreffling",
            "Mödling",
            "Modriach",
            "Mogersdorf",
            "Möggers",
            "Mölbling",
            "Möllbrücke",
            "Möllersdorf",
            "Molln",
            "Mönchhof",
            "Mondsee",
            "Mönichkirchen",
            "Mönichwald",
            "Moosbrunn",
            "Moosburg",
            "Moosdorf",
            "Mooskirchen",
            "Mörbisch am See",
            "Mortantsch",
            "Mörtschach",
            "Moschendorf",
            "Mötz",
            "Muckendorf an der Donau",
            "Muggendorf",
            "Mühlau",
            "Mühlbach am Hochkönig",
            "Mühlbachl",
            "Mühldorf",
            "Mühldorf",
            "Mühldorf",
            "Mühldorf bei Feldbach",
            "Mühlen",
            "Mühlgraben",
            "Müllendorf",
            "Münchendorf",
            "Munderfing",
            "Münster",
            "Muntlix",
            "Münzbach",
            "Münzkirchen",
            "Murau",
            "Mureck",
            "Mürzhofen",
            "Mürzsteg",
            "Mürzzuschlag",
            "Musau",
            "Mutters",
            "Naarn im Machlande",
            "Naas",
            "Namlos",
            "Nassereith",
            "Natternbach",
            "Natters",
            "Nauders",
            "Nebelberg",
            "Neckenmarkt",
            "Neidling",
            "Nenzing",
            "Nesselwängle",
            "Nestelbach bei Graz",
            "Nestelbach im Ilztal",
            "Neu-Feffernitz",
            "Neu-Guntramsdorf",
            "Neualm",
            "Neubau",
            "Neuberg an der Mürz",
            "Neuberg im Burgenland",
            "Neudau",
            "Neudorf bei Staatz",
            "Neudörfl",
            "Neue Heimat",
            "Neufeld an der Leitha",
            "Neufurth",
            "Neuhaus",
            "Neuhaus am Klausenbach",
            "Neuhofen an der Krems",
            "Neuhofen an der Ybbs",
            "Neukirchen",
            "Neukirchen am Großvenediger",
            "Neukirchen am Walde",
            "Neukirchen an der Vöckla",
            "Neukirchen bei Lambach",
            "Neulengbach",
            "Neumarkt am Wallersee",
            "Neumarkt an der Ybbs",
            "Neumarkt im Hausruckkreis",
            "Neumarkt im Mühlkreis",
            "Neumarkt in Steiermark",
            "Neunkirchen",
            "Neusiedl",
            "Neusiedl am See",
            "Neusiedl an der Zaya",
            "Neustift an der Lafnitz",
            "Neustift bei Güssing",
            "Neustift im Stubaital",
            "Neutal",
            "Neutillmitsch",
            "Neuzeug",
            "Nickelsdorf",
            "Niederalm",
            "Niederau",
            "Niederbreitenbach",
            "Niederdorf",
            "Niederhollabrunn",
            "Niederleis",
            "Niederndorf",
            "Niederneukirchen",
            "Niedernsill",
            "Niederöblarn",
            "Niederschöckl",
            "Niederstraß",
            "Niederthalheim",
            "Niederwölz",
            "Nikitsch",
            "Niklasdorf",
            "Nikolsdorf",
            "Nitscha",
            "Nöchling",
            "Nöstlbach",
            "Nötsch im Gailtal",
            "Nußdorf am Haunsberg",
            "Nüziders",
            "Obdach",
            "Ober-Grafendorf",
            "Oberaich",
            "Oberalm",
            "Oberau",
            "Oberdorf am Hochegg",
            "Oberdorf im Burgenland",
            "Oberdörfl",
            "Oberdrauburg",
            "Obergäu",
            "Oberhaag",
            "Oberhaid",
            "Oberhart",
            "Oberhausen",
            "Oberhofen am Irrsee",
            "Oberhofen im Inntal",
            "Oberkurzheim",
            "Oberlienz",
            "Oberloisdorf",
            "Obermieming",
            "Obermillstatt",
            "Obernberg am Brenner",
            "Obernberg am Inn",
            "Oberndorf an der Melk",
            "Oberndorf bei Salzburg",
            "Oberndorf bei Schwanenstadt",
            "Oberndorf in der Ebene",
            "Oberndorf in Tirol",
            "Oberneukirchen",
            "Oberperfuss",
            "Oberpremstätten",
            "Oberpullendorf",
            "Oberrettenbach",
            "Oberschützen",
            "Obersdorf",
            "Obersiebenbrunn",
            "Oberstorcha",
            "Obertilliach",
            "Obertrum am See",
            "Obervellach",
            "Obervogau",
            "Oberwagram",
            "Oberwaltersdorf",
            "Oberwang",
            "Oberwart",
            "Oberweg",
            "Oberwölbling",
            "Oberwölz Stadt",
            "Oberzeiring",
            "Öblarn",
            "Obsteig",
            "Oedt",
            "Oehling",
            "Oepping",
            "Oetz",
            "Oeynhausen",
            "Offenhausen",
            "Oggau",
            "Ohlsdorf",
            "Olbendorf",
            "Ollersdorf im Burgenland",
            "Oppenberg",
            "Opponitz",
            "Ort im Innkreis",
            "Ortgraben",
            "Orth an der Donau",
            "Oslip",
            "Ossiach",
            "Ostermiething",
            "Osterwitz",
            "Ottakring",
            "Ottenschlag",
            "Ottenschlag im Mühlkreis",
            "Ottensheim",
            "Ottenthal",
            "Otterthal",
            "Ottnang am Hausruck",
            "Ötztal-Bahnhof",
            "Pabneukirchen",
            "Pack",
            "Paldau",
            "Palfau",
            "Palting",
            "Pama",
            "Pamhagen",
            "Parbasdorf",
            "Parndorf",
            "Parschlug",
            "Pasching",
            "Passail",
            "Paternion",
            "Patsch",
            "Pattigham",
            "Paudorf",
            "Payerbach",
            "Peggau",
            "Pennewang",
            "Penz",
            "Perchau am Sattel",
            "Perchtoldsdorf",
            "Perg",
            "Perlsdorf",
            "Pernegg",
            "Pernegg an der Mur",
            "Pernersdorf",
            "Pernitz",
            "Persenbeug",
            "Pertlstein",
            "Perwang am Grabensee",
            "Petronell-Carnuntum",
            "Pettenbach",
            "Pettnau",
            "Pettneu",
            "Petzenkirchen",
            "Peuerbach",
            "Pfaffenhofen",
            "Pfaffing",
            "Pfaffstätten",
            "Pfarrkirchen bei Bad Hall",
            "Pfarrwerfen",
            "Pflach",
            "Pfons",
            "Pfunds",
            "Pians",
            "Piberegg",
            "Pichl",
            "Pichl bei Wels",
            "Pichling bei Köflach",
            "Pierbach",
            "Piesendorf",
            "Pilgersdorf",
            "Pill",
            "Pillichsdorf",
            "Pinggau",
            "Pinkafeld",
            "Pinsdorf",
            "Pirching am Traubenberg",
            "Piringsdorf",
            "Pirka",
            "Pischeldorf",
            "Pischelsdorf in der Steiermark",
            "Pistorf",
            "Pitschgau",
            "Pitten",
            "Pitzenberg",
            "Plainfeld",
            "Plankenau",
            "Plesching",
            "Pöchlarn",
            "Pöckau",
            "Podersdorf am See",
            "Poggersdorf",
            "Pöggstall",
            "Politischer Bezirk Amstetten",
            "Politischer Bezirk Baden",
            "Politischer Bezirk Bludenz",
            "Politischer Bezirk Braunau am Inn",
            "Politischer Bezirk Bregenz",
            "Politischer Bezirk Bruck an der Leitha",
            "Politischer Bezirk Bruck-Mürzzuschlag",
            "Politischer Bezirk Deutschlandsberg",
            "Politischer Bezirk Dornbirn",
            "Politischer Bezirk Eferding",
            "Politischer Bezirk Feldkirch",
            "Politischer Bezirk Freistadt",
            "Politischer Bezirk Gänserndorf",
            "Politischer Bezirk Gmünd",
            "Politischer Bezirk Gmunden",
            "Politischer Bezirk Graz-Umgebung",
            "Politischer Bezirk Grieskirchen",
            "Politischer Bezirk Güssing",
            "Politischer Bezirk Hallein",
            "Politischer Bezirk Hartberg-Fürstenfeld",
            "Politischer Bezirk Hollabrunn",
            "Politischer Bezirk Horn",
            "Politischer Bezirk Imst",
            "Politischer Bezirk Innsbruck Land",
            "Politischer Bezirk Jennersdorf",
            "Politischer Bezirk Kirchdorf an der Krems",
            "Politischer Bezirk Kitzbühel",
            "Politischer Bezirk Korneuburg",
            "Politischer Bezirk Krems",
            "Politischer Bezirk Kufstein",
            "Politischer Bezirk Landeck",
            "Politischer Bezirk Leibnitz",
            "Politischer Bezirk Leoben",
            "Politischer Bezirk Lienz",
            "Politischer Bezirk Liezen",
            "Politischer Bezirk Lilienfeld",
            "Politischer Bezirk Linz-Land",
            "Politischer Bezirk Mattersburg",
            "Politischer Bezirk Melk",
            "Politischer Bezirk Mistelbach",
            "Politischer Bezirk Mödling",
            "Politischer Bezirk Murau",
            "Politischer Bezirk Murtal",
            "Politischer Bezirk Neunkirchen",
            "Politischer Bezirk Neusiedl am See",
            "Politischer Bezirk Oberpullendorf",
            "Politischer Bezirk Oberwart",
            "Politischer Bezirk Perg",
            "Politischer Bezirk Reutte",
            "Politischer Bezirk Ried im Innkreis",
            "Politischer Bezirk Rohrbach",
            "Politischer Bezirk Salzburg-Umgebung",
            "Politischer Bezirk Sankt Johann im Pongau",
            "Politischer Bezirk Sankt Pölten",
            "Politischer Bezirk Schärding",
            "Politischer Bezirk Scheibbs",
            "Politischer Bezirk Schwaz",
            "Politischer Bezirk Steyr-Land",
            "Politischer Bezirk Südoststeiermark",
            "Politischer Bezirk Tamsweg",
            "Politischer Bezirk Tulln",
            "Politischer Bezirk Urfahr-Umgebung",
            "Politischer Bezirk Vöcklabruck",
            "Politischer Bezirk Voitsberg",
            "Politischer Bezirk Waidhofen an der Thaya",
            "Politischer Bezirk Weiz",
            "Politischer Bezirk Wiener Neustadt",
            "Politischer Bezirk Zwettl",
            "Pöllau",
            "Pöllauberg",
            "Polling in Tirol",
            "Pöls",
            "Poppendorf",
            "Pörtschach am Wörthersee",
            "Pöttelsdorf",
            "Pottenbrunn",
            "Pottendorf",
            "Pottenstein",
            "Pötting",
            "Pottschach",
            "Pöttsching",
            "Potzneusiedl",
            "Poysdorf",
            "Pradl",
            "Pram",
            "Prambachkirchen",
            "Pramet",
            "Preding",
            "Pregarten",
            "Preitenegg",
            "Prellenkirchen",
            "Pressbaum",
            "Priel",
            "Prigglitz",
            "Prinzersdorf",
            "Proleb",
            "Prottes",
            "Pruggern",
            "Prutz",
            "Puch bei Hallein",
            "Puch bei Weiz",
            "Puchberg am Schneeberg",
            "Puchenau",
            "Puchenstuben",
            "Puchheim",
            "Puchlkirchen am Trattberg",
            "Pucking",
            "Pühret",
            "Pulkau",
            "Puntigam",
            "Purbach am Neusiedler See",
            "Purgstall",
            "Purkersdorf",
            "Pusarnitz",
            "Pusterwald",
            "Pyhra",
            "Raab",
            "Raaba",
            "Raabau",
            "Raabs an der Thaya",
            "Raach am Hochgebirge",
            "Raasdorf",
            "Rabensburg",
            "Rabenstein an der Pielach",
            "Rabenwald",
            "Rachau",
            "Radenthein",
            "Radfeld",
            "Radstadt",
            "Raffelstetten",
            "Raggal",
            "Ragnitz",
            "Raiding",
            "Rain",
            "Rainbach im Mühlkreis",
            "Ramingstein",
            "Ramsau",
            "Ramsau am Dachstein",
            "Ramsau im Zillertal",
            "Randegg",
            "Rangersdorf",
            "Ranggen",
            "Raning",
            "Rankweil",
            "Rannersdorf",
            "Ranshofen",
            "Ranten",
            "Rappottenstein",
            "Rassach",
            "Rastenfeld",
            "Ratsch an der Weinstraße",
            "Ratschendorf",
            "Ratten",
            "Rattenberg",
            "Ratzersdorf an der Traisen",
            "Rauchenwarth",
            "Rauris",
            "Ravelsbach",
            "Raxendorf",
            "Rechnitz",
            "Reding",
            "Redleiten",
            "Redlham",
            "Regau",
            "Rehberg",
            "Reichenau",
            "Reichenau an der Rax",
            "Reichenau im Mühlkreis",
            "Reichendorf",
            "Reichenfels",
            "Reichenthal",
            "Reichraming",
            "Reifling",
            "Reinbach",
            "Reingers",
            "Reinsberg",
            "Reisenberg",
            "Reißeck",
            "Reitberg",
            "Reiterndorf",
            "Reith bei Kitzbühel",
            "Reith bei Seefeld",
            "Reith im Alpbachtal",
            "Rennweg am Katschberg",
            "Rettenegg",
            "Rettenschöss",
            "Retz",
            "Retznei",
            "Reuthe",
            "Reutte",
            "Ried im Innkreis",
            "Ried im Oberinntal",
            "Ried im Traunkreis",
            "Ried im Zillertal",
            "Ried in der Riedmark",
            "Riedlingsdorf",
            "Riefensberg",
            "Riegersburg",
            "Ries",
            "Rietz",
            "Riezlern",
            "Rinn",
            "Rittersdorf",
            "Ritzing",
            "Rohr im Burgenland",
            "Rohr im Gebirge",
            "Rohr im Kremstal",
            "Rohrau",
            "Rohrbach an der Gölsen",
            "Rohrbach an der Lafnitz",
            "Rohrbach bei Mattersburg",
            "Rohrbach-Berg",
            "Rohrberg",
            "Röhrenbach",
            "Rohrendorf bei Krems",
            "Roitham",
            "Röns",
            "Roppen",
            "Röschitz",
            "Rosegg",
            "Rosenau am Sonntagberg",
            "Rosental an der Kainach",
            "Rotenturm an der Pinka",
            "Röthelstein",
            "Röthis",
            "Rottenmann",
            "Ruden",
            "Rudersdorf",
            "Rufling",
            "Rum",
            "Ruprechtshofen",
            "Rust",
            "Rust Stadt",
            "Rüstorf",
            "Rutzenham",
            "Saalbach",
            "Saalfelden am Steinernen Meer",
            "Sachsenburg",
            "Salla",
            "Sallingberg",
            "Salzburg",
            "Salzburg Stadt",
            "Sammersdorf",
            "Sankt Aegidi",
            "Sankt Aegyd am Neuwalde",
            "Sankt Andrä",
            "Sankt Andrä am Zicksee",
            "Sankt Andrä im Lungau",
            "Sankt Andrä vor dem Hagenthale",
            "Sankt Anna am Aigen",
            "Sankt Anton im Montafon",
            "Sankt Bartholomä",
            "Sankt Blasen",
            "Sankt Corona am Wechsel",
            "Sankt Dionysen",
            "Sankt Egyden an der Drau",
            "Sankt Florian",
            "Sankt Gallen",
            "Sankt Gallenkirch",
            "Sankt Georgen am Fillmannsbach",
            "Sankt Georgen am Längsee",
            "Sankt Georgen am Leithagebirge",
            "Sankt Georgen am Ybbsfelde",
            "Sankt Georgen an der Gusen",
            "Sankt Georgen an der Leys",
            "Sankt Georgen an der Stiefing",
            "Sankt Georgen bei Obernberg am Inn",
            "Sankt Georgen im Attergau",
            "Sankt Georgen im Lavanttal",
            "Sankt Georgen ob Judenburg",
            "Sankt Georgen ob Murau",
            "Sankt Gilgen",
            "Sankt Gotthard im Mühlkreis",
            "Sankt Ilgen",
            "Sankt Jakob",
            "Sankt Jakob im Rosental",
            "Sankt Jakob im Walde",
            "Sankt Jakob-Breitenau",
            "Sankt Johann am Tauern",
            "Sankt Johann bei Herberstein",
            "Sankt Johann im Pongau",
            "Sankt Johann im Saggautal",
            "Sankt Johann in der Haide",
            "Sankt Johann in Tirol",
            "Sankt Josef (Weststeiermark)",
            "Sankt Kanzian am Klopeiner See",
            "Sankt Katharein an der Laming",
            "Sankt Kathrein am Hauenstein",
            "Sankt Kathrein am Offenegg",
            "Sankt Lambrecht",
            "Sankt Leonhard",
            "Sankt Leonhard",
            "Sankt Leonhard",
            "Sankt Leonhard am Forst",
            "Sankt Leonhard am Hornerwald",
            "Sankt Lorenz",
            "Sankt Lorenzen am Wechsel",
            "Sankt Lorenzen bei Knittelfeld",
            "Sankt Lorenzen bei Scheifling",
            "Sankt Lorenzen im Mürztal",
            "Sankt Magdalen",
            "Sankt Marein bei Graz",
            "Sankt Marein bei Knittelfeld",
            "Sankt Marein bei Neumarkt",
            "Sankt Marein im Mürztal",
            "Sankt Margareten im Rosental",
            "Sankt Margarethen an der Raab",
            "Sankt Margarethen an der Sierning",
            "Sankt Margarethen bei Knittelfeld",
            "Sankt Margarethen im Burgenland",
            "Sankt Margarethen im Lungau",
            "Sankt Marien",
            "Sankt Martin",
            "Sankt Martin",
            "Sankt Martin",
            "Sankt Martin am Grimming",
            "Sankt Martin am Ybbsfelde",
            "Sankt Martin an der Raab",
            "Sankt Martin bei Lofer",
            "Sankt Martin im Mühlkreis",
            "Sankt Martin im Sulmtal",
            "Sankt Michael im Burgenland",
            "Sankt Michael im Lungau",
            "Sankt Michael in Obersteiermark",
            "Sankt Nikolai im Sausal",
            "Sankt Nikolai im Sölktal",
            "Sankt Nikolai ob Draßling",
            "Sankt Oswald bei Freistadt",
            "Sankt Oswald bei Plankenwarth",
            "Sankt Oswald ob Eibiswald",
            "Sankt Pantaleon",
            "Sankt Paul im Lavanttal",
            "Sankt Peter",
            "Sankt Peter",
            "Sankt Peter am Hart",
            "Sankt Peter am Kammersberg",
            "Sankt Peter am Ottersbach",
            "Sankt Peter im Sulmtal",
            "Sankt Peter in der Au Markt",
            "Sankt Peter ob Judenburg",
            "Sankt Peter-Freienstein",
            "Sankt Pölten",
            "Sankt Pölten Stadt",
            "Sankt Radegund bei Graz",
            "Sankt Ruprecht",
            "Sankt Ruprecht an der Raab",
            "Sankt Stefan",
            "Sankt Stefan im Gailtal",
            "Sankt Stefan im Rosental",
            "Sankt Stefan ob Leoben",
            "Sankt Stefan ob Stainz",
            "Sankt Ulrich am Pillersee",
            "Sankt Ulrich am Waasen",
            "Sankt Ulrich bei Steyr",
            "Sankt Urban",
            "Sankt Valentin",
            "Sankt Veit am Vogau",
            "Sankt Veit an der Glan",
            "Sankt Veit an der Glan District",
            "Sankt Veit an der Gölsen",
            "Sankt Veit im Pongau",
            "Sankt Veit in Defereggen",
            "Sankt Wolfgang im Salzkammergut",
            "Sarleinsbach",
            "Satteins",
            "Sattledt",
            "Sautens",
            "Schachen bei Vorau",
            "Schachendorf",
            "Schäffern",
            "Schafwiesen",
            "Schalchen",
            "Schalchham",
            "Schandorf",
            "Schardenberg",
            "Schärding",
            "Schärding Vorstadt",
            "Scharndorf",
            "Scharnitz",
            "Scharnstein",
            "Schattendorf",
            "Schattwald",
            "Scheffau am Tennengebirge",
            "Scheffau am Wilden Kaiser",
            "Scheibbs",
            "Scheifling",
            "Schenkenfelden",
            "Schiefling am See",
            "Schildorn",
            "Schladming",
            "Schlaiten",
            "Schlatt",
            "Schleben",
            "Schleedorf",
            "Schleinbach",
            "Schlierbach",
            "Schlins",
            "Schlitters",
            "Schlüsslberg",
            "Schnepfau",
            "Schnifis",
            "Schöder",
            "Schönau an der Triesting",
            "Schönbach",
            "Schönberg im Stubaital",
            "Schöndorf",
            "Schönering",
            "Schönkirchen-Reyersdorf",
            "Schönwies",
            "Schoppernau",
            "Schörfling",
            "Schottwien",
            "Schrattenbach",
            "Schrattenberg",
            "Schrattenthal",
            "Schrems",
            "Schrems bei Frohnleiten",
            "Schröcken",
            "Schruns",
            "Schützen am Gebirge",
            "Schwadorf",
            "Schwanberg",
            "Schwanenstadt",
            "Schwarzach",
            "Schwarzach im Pongau",
            "Schwarzau am Steinfeld",
            "Schwarzau im Gebirge",
            "Schwarzau im Schwarzautal",
            "Schwarzenau",
            "Schwarzenbach",
            "Schwarzenbach an der Pielach",
            "Schwarzenberg",
            "Schwaz",
            "Schwechat",
            "Schweiggers",
            "Schweinbach",
            "Schwendau",
            "Schwendt",
            "Schwertberg",
            "Schwoich",
            "Sebersdorf",
            "Seckau",
            "See",
            "Seebach",
            "Seebenstein",
            "Seeboden",
            "Seefeld in Tirol",
            "Seeham",
            "Seekirchen am Wallersee",
            "Seewalchen",
            "Seewalchen",
            "Seggauberg",
            "Seibersdorf",
            "Seiersberg",
            "Seitenberg",
            "Seitenstetten Markt",
            "Sellrain",
            "Selzthal",
            "Semmering",
            "Semriach",
            "Senftenberg",
            "Serfaus",
            "Seyring",
            "Sibratsgfäll",
            "Siegendorf im Burgenland",
            "Siegersdorf bei Herberstein",
            "Sieggraben",
            "Sieghartskirchen",
            "Sierndorf",
            "Sierning",
            "Siezenheim",
            "Sigless",
            "Sigmundsherberg",
            "Silbertal",
            "Sillian",
            "Silz",
            "Simmering",
            "Sinabelkirchen",
            "Sirnitz",
            "Sistrans",
            "Sittersdorf",
            "Sitzendorf an der Schmida",
            "Soboth",
            "Söchau",
            "Södingberg",
            "Sölden",
            "Söll",
            "Sollenau",
            "Sommerein",
            "Sonnberg im Mühlkreis",
            "Sonntagberg",
            "Sooss",
            "Spannberg",
            "Spatenhof",
            "Spielberg bei Knittelfeld",
            "Spielfeld",
            "Spillern",
            "Spital am Pyhrn",
            "Spital am Semmering",
            "Spittal an der Drau",
            "Spittal an der Drau District",
            "Spitz",
            "Spratzern",
            "St Anton am Arlberg",
            "St. Agathen und Perau",
            "St. Andrä",
            "St. Margareten im Rosental",
            "St. Martin of Tennengebirge",
            "St. Paul im Lavanttal",
            "St. Stefan im Gailtal",
            "St. Urban",
            "St. Veit an der Glan",
            "St. Veiter Vorstadt",
            "Staatz",
            "Stadl an der Mur",
            "Stadl-Hausruck",
            "Stadl-Paura",
            "Stadl-Traun",
            "Stadtschlaining",
            "Stainach",
            "Stainz",
            "Stainz bei Straden",
            "Stall",
            "Stallehr",
            "Stallhof",
            "Stallhofen",
            "Stambach",
            "Stams",
            "Stans",
            "Stanz bei Landeck",
            "Stanzach",
            "Stattegg",
            "Stattersdorf",
            "Statzendorf",
            "Steeg",
            "Stegersbach",
            "Stein",
            "Stein an der Donau",
            "Steinabrückl",
            "Steinach am Brenner",
            "Steinakirchen am Forst",
            "Steinbach am Attersee",
            "Steinbrunn",
            "Steindorf am Ossiacher See",
            "Steinerkirchen an der Traun",
            "Steinfeld",
            "Steinfeld",
            "Steinhaus",
            "Stenzengreith",
            "Stephanshart",
            "Stetteldorf am Wagram",
            "Stetten",
            "Steuerberg",
            "Steyr",
            "Steyr Stadt",
            "Steyregg",
            "Stinatz",
            "Stiwoll",
            "Stockenboi",
            "Stockerau",
            "Stollhofen",
            "Stoob",
            "Stössing",
            "Stotzing",
            "Straden",
            "Strallegg",
            "Strass",
            "Strass im Zillertal",
            "Straß in Steiermark",
            "Straßburg",
            "Straßburg-Stadt",
            "Strassen",
            "Strassengel",
            "Straßgang",
            "Strassham",
            "Strasshof an der Nordbahn",
            "Strasswalchen",
            "Stratzing",
            "Strem",
            "Strengberg",
            "Strengen",
            "Strobl",
            "Stronsdorf",
            "Stubenberg",
            "Studenzen",
            "Stuhlfelden",
            "Stumm",
            "Stummerberg",
            "Suetschach",
            "Sulz",
            "Sulzberg",
            "Sulztal an der Weinstraße",
            "Tadten",
            "Taiskirchen im Innkreis",
            "Tamsweg",
            "Tannheim",
            "Tarrenz",
            "Tattendorf",
            "Taufkirchen an der Trattnach",
            "Taugl",
            "Tauplitz",
            "Taxach",
            "Taxenbach",
            "Techelsberg",
            "Teesdorf",
            "Telfes im Stubai",
            "Telfs",
            "Terfens",
            "Ternberg",
            "Ternitz",
            "Teufenbach",
            "Thal",
            "Thalgau",
            "Thalheim bei Wels",
            "Thaur",
            "Thaya",
            "Theresienfeld",
            "Thomasberg",
            "Thomatal",
            "Thörl",
            "Thumersbach",
            "Thüringen",
            "Thurn",
            "Tiefenbach",
            "Tiefgraben",
            "Tieschen",
            "Tillmitsch",
            "Tillmitsch Links der Laßnitz",
            "Timelkam",
            "Timenitz",
            "Tobadill",
            "Tobaj",
            "Traboch",
            "Tragwein",
            "Trahütten",
            "Traisen",
            "Traiskirchen",
            "Traismauer",
            "Trattenbach",
            "Traun",
            "Traunkirchen",
            "Trausdorf an der Wulka",
            "Trautmannsdorf an der Leitha",
            "Trautmannsdorf in Oststeiermark",
            "Trebesing",
            "Treffen",
            "Treglwang",
            "Treibach",
            "Tribuswinkel",
            "Trieben",
            "Triebendorf",
            "Trins",
            "Tristach",
            "Trofaiach",
            "Trössing",
            "Trumau",
            "Tschachoritsch",
            "Tschagguns",
            "Tschanigraben",
            "Tulbing",
            "Tulfes",
            "Tulln",
            "Tullnerbach-Lawies",
            "Turnau",
            "Türnitz",
            "Tux",
            "Tweng",
            "Übelbach",
            "Übersaxen",
            "Übersbach",
            "Uderns",
            "Ufer",
            "Ulrichsberg",
            "Ulrichskirchen",
            "Umberg",
            "Umhausen",
            "Ungenach",
            "Ungerdorf",
            "Unken",
            "Unterauersbach",
            "Unterbergla",
            "Untere Fellach",
            "Untereisenfeld",
            "Unterfladnitz",
            "Unterfrauenhaid",
            "Unterjosefstal",
            "Unterkohlstätten",
            "Unterlamm",
            "Unterlangkampfen",
            "Unternberg",
            "Unterperfuss",
            "Unterpremstätten",
            "Untersiebenbrunn",
            "Unterstinkenbrunn",
            "Untertauern",
            "Untertilliach",
            "Unterwagram",
            "Unterwaltersdorf",
            "Unterwart",
            "Utschtal",
            "Uttendorf",
            "Uttendorf",
            "Utzenaich",
            "Vals",
            "Vandans",
            "Vasoldsberg",
            "Veitsch",
            "Velden am Wörther See",
            "Velden am Wörthersee",
            "Velm",
            "Viechtwang",
            "Viehhausen",
            "Viehhofen",
            "Viehofen",
            "Vienna",
            "Vigaun",
            "Viktorsberg",
            "Viktring",
            "Viktringer Vorstadt",
            "Villach",
            "Villach-Innere Stadt",
            "Villach-Land District",
            "Villacher Vorstadt",
            "Vils",
            "Virgen",
            "Vitis",
            "Vöcklabruck",
            "Vöcklamarkt",
            "Vogau",
            "Voitsberg",
            "Volders",
            "Voldöpp",
            "Völkendorf",
            "Völkermarkt",
            "Völkermarkt District",
            "Völkermarkter Vorstadt",
            "Völs",
            "Vomp",
            "Vorau",
            "Vorchdorf",
            "Vorderhornbach",
            "Vordernberg",
            "Vorderthiersee",
            "Vösendorf",
            "Wagna",
            "Wagnergraben",
            "Wagnitz",
            "Wagrain",
            "Wagrain",
            "Wagram",
            "Waidhofen an der Thaya",
            "Waidhofen an der Ybbs",
            "Waidhofen an der Ybbs Stadt",
            "Waidmannsfeld",
            "Waidring",
            "Waiern",
            "Waizenkirchen",
            "Walchen",
            "Walchsee",
            "Wald am Schoberpaß",
            "Wald im Pinzgau",
            "Waldegg",
            "Waldenstein",
            "Waldhausen",
            "Walding",
            "Waldkirchen am Wesen",
            "Waldkirchen an der Thaya",
            "Waldneukirchen",
            "Waldprechting",
            "Waldzell",
            "Wallern an der Trattnach",
            "Wallern im Burgenland",
            "Wallsee",
            "Walpersbach",
            "Wals",
            "Walserfeld",
            "Waltendorf",
            "Wang",
            "Wängle",
            "Warmbad-Judendorf",
            "Wartberg",
            "Wartberg an der Krems",
            "Wartberg ob der Aist",
            "Warth",
            "Warth",
            "Wartmannstetten",
            "Wattenberg",
            "Wattens",
            "Weer",
            "Weerberg",
            "Weibern",
            "Weichselbaum",
            "Weiden am See",
            "Weiden bei Rechnitz",
            "Weidling",
            "Weigelsdorf",
            "Weikendorf",
            "Weikersdorf am Steinfelde",
            "Weilbach",
            "Weiler",
            "Weinburg",
            "Weinburg am Saßbach",
            "Weingraben",
            "Weinzierl am Walde",
            "Weinzierl bei Krems",
            "Weissach",
            "Weissenbach am Lech",
            "Weissenbach an der Triesting",
            "Weißenbach bei Liezen",
            "Weißenkirchen im Attergau",
            "Weißenkirchen in der Wachau",
            "Weißensee",
            "Weißenstein",
            "Weisskirchen an der Traun",
            "Weißkirchen in Steiermark",
            "Weistrach",
            "Weiten",
            "Weitensfeld",
            "Weitensfeld im Gurktal",
            "Weitersfeld",
            "Weitersfelden",
            "Weitra",
            "Weiz",
            "Wels",
            "Wels Stadt",
            "Wels-Land",
            "Wendling",
            "Weng im Innkreis",
            "Wenns",
            "Weppersdorf",
            "Werfen",
            "Werfenweng",
            "Wernberg",
            "Werndorf",
            "Wernersdorf",
            "Wernstein am Inn",
            "Westendorf",
            "Wettmannstätten",
            "Wetzelsdorf",
            "Weyer",
            "Weyregg",
            "Wiener Neudorf",
            "Wiener Neustadt",
            "Wiener Neustadt Stadt",
            "Wienersdorf",
            "Wies",
            "Wieselburg",
            "Wiesen",
            "Wiesfleck",
            "Wiesing",
            "Wiesmath",
            "Wildalpen",
            "Wildermieming",
            "Wildon",
            "Wildschönau",
            "Wilfersdorf",
            "Wilfleinsdorf",
            "Wilhelmsburg",
            "Willendorf am Steinfelde",
            "Wilten",
            "Wimpassing",
            "Wimpassing an der Leitha",
            "Wimpassing im Schwarzatale",
            "Wimsbach",
            "Winden am See",
            "Windhaag bei Freistadt",
            "Windhaag bei Perg",
            "Windhag",
            "Windigsteig",
            "Windischgarsten",
            "Winklarn",
            "Winklern",
            "Winklern bei Oberwölz",
            "Winzendorf",
            "Wippenham",
            "Wispl",
            "Wolfau",
            "Wolfern",
            "Wölfnitz",
            "Wolfpassing",
            "Wolfsbach",
            "Wolfsberg",
            "Wolfsberg District",
            "Wolfsberg im Schwarzautal",
            "Wolfsegg am Hausruck",
            "Wolfsgraben",
            "Wolfsthal",
            "Wolfurt",
            "Wolkersdorf im Weinviertel",
            "Wöllersdorf",
            "Wördern",
            "Wörgl",
            "Wörschach",
            "Wörterberg",
            "Wörth an der Lafnitz",
            "Wudmath",
            "Wulkaprodersdorf",
            "Wullersdorf",
            "Wundschuh",
            "Würflach",
            "Würmla",
            "Ybbs an der Donau",
            "Ybbsitz",
            "Ysper",
            "Zagersdorf",
            "Zams",
            "Zederhaus",
            "Zeillern",
            "Zeiselmauer",
            "Zell",
            "Zell",
            "Zell am Moos",
            "Zell am Pettenfirst",
            "Zell am See",
            "Zell am Ziller",
            "Zell an der Pram",
            "Zell-Arzberg",
            "Zell-Markt",
            "Zellberg",
            "Zellerndorf",
            "Zeltweg",
            "Zerlach",
            "Zettling",
            "Zeutschach",
            "Ziersdorf",
            "Zillingdorf",
            "Zillingtal",
            "Zirl",
            "Zistersdorf",
            "Zöbern",
            "Zöblen",
            "Zurndorf",
            "Zwentendorf",
            "Zwettl an der Rodl",
            "Zwettl Stadt",
            "Zwölfaxing"
        ]
    },
    {
        "label": "Azerbaijan",
        "code": "AZ",
        "phone": "994",
        "cities": [
            "Ağdam",
            "Ağdaş",
            "Agdzhabedy",
            "Aghstafa",
            "Aghsu",
            "Aliabad",
            "Altıağac",
            "Amirdzhan",
            "Aran",
            "Arıqdam",
            "Arıqıran",
            "Askyaran",
            "Astara",
            "Avşar",
            "Badamdar",
            "Bakıxanov",
            "Baku",
            "Balakhani",
            "Barda",
            "Baş Göynük",
            "Basqal",
            "Belokany",
            "Beylagan",
            "Bilajari",
            "Bilajer",
            "Binagadi",
            "Biny Selo",
            "Birinci Aşıqlı",
            "Boradigah",
            "Böyük Qaramurad",
            "Buzovna",
            "Cahri",
            "Çalxanqala",
            "Çatax",
            "Ceyranbatan",
            "Çinarlı",
            "Çobansığnaq",
            "Corat",
            "Culfa",
            "Deste",
            "Digah",
            "Divichibazar",
            "Dolyar",
            "Dondar Quşçu",
            "Dünyamalılar",
            "Dzagam",
            "Əhmədbəyli",
            "Faldarlı",
            "Fizuli",
            "Ganja",
            "Geoktschai",
            "Gilgilçay",
            "Goranboy",
            "Gyuzdek",
            "Hacı Zeynalabdin",
            "Hacıhüseynli",
            "Hacıqabul",
            "Hadrut",
            "Haftoni",
            "Heydarabad",
            "Horadiz",
            "Hövsan",
            "Imishli",
            "İsmayıllı",
            "Jalilabad",
            "Jebrail",
            "Julfa Rayon",
            "Kerbakhiar",
            "Khirdalan",
            "Khodzhi-Gasan",
            "Khyzy",
            "Kilyazi",
            "Kizhaba",
            "Korgöz",
            "Kyadabek",
            "Kyurdarmir",
            "Kyzyl-Burun",
            "Laçın",
            "Lankaran",
            "Lerik",
            "Lökbatan",
            "Mamrux",
            "Mardakan",
            "Martakert",
            "Masally",
            "Maştağa",
            "Mincivan",
            "Mingelchaur",
            "Mughan",
            "Nakhchivan",
            "Nardaran",
            "Neftçala",
            "Nizami Rayonu",
            "Novosaratovka",
            "Novyy Karanlug",
            "Oğlanqala",
            "Oğuz",
            "Ordubad",
            "Ordubad Rayon",
            "Orjonikidze",
            "Pirallahı",
            "Prishibinskoye",
            "Pushkino",
            "Puta",
            "Qabaqçöl",
            "Qala",
            "Qandax",
            "Qaraçala",
            "Qaraçuxur",
            "Qaramanlı",
            "Qaraxanlı",
            "Qarayeri",
            "Qasım İsmayılov",
            "Qax",
            "Qax İngiloy",
            "Qaxbaş",
            "Qazax",
            "Qırmızı Bazar",
            "Qırmızı Samux",
            "Qıvraq",
            "Qızılhacılı",
            "Qobu",
            "Qobustan",
            "Qobustan",
            "Quba",
            "Qubadlı",
            "Qusar",
            "Qutqashen",
            "Ramana",
            "Saatlı",
            "Sabirabad",
            "Sabunçu",
            "Şahbuz",
            "Saloğlu",
            "Salyan",
            "Samur",
            "Samux",
            "Samuxlu",
            "Sanqaçal",
            "Saray",
            "Sedarak",
            "Severo-Vostotchnyi Bank",
            "Shahbuz Rayon",
            "Shamakhi",
            "Shamkhor",
            "Sharur City",
            "Sheki",
            "Shushi",
            "Şirvan",
            "Sovetabad",
            "Sumbatan-diza",
            "Sumqayıt",
            "Şuraabad",
            "Tazakend",
            "Terter",
            "Tovuz",
            "Türkan",
            "Ujar",
            "Vank",
            "Verkhniy Dashkesan",
            "Vurğun",
            "Xaçmaz",
            "Xıllı",
            "Xocalı",
            "Xudat",
            "Yanıqlı",
            "Yardımlı",
            "Yaycı",
            "Yelenendorf",
            "Yeni Suraxanı",
            "Yevlakh",
            "Yukhary-Dashkesan",
            "Yuxarı Aran",
            "Zabrat",
            "Zangilan",
            "Zaqatala",
            "Zardob",
            "Zyrya"
        ]
    },
    {
        "label": "Bahrain",
        "code": "BH",
        "phone": "973",
        "cities": [
            "Al Ḩadd",
            "Al Muharraq",
            "Ar Rifā‘",
            "Dār Kulayb",
            "Jidd Ḩafş",
            "Madīnat ‘Īsá",
            "Madīnat Ḩamad",
            "Manama",
            "Sitrah"
        ]
    },
    {
        "label": "Bangladesh",
        "code": "BD",
        "phone": "880",
        "cities": [
            "Azimpur",
            "Badarganj",
            "Bagerhat",
            "Bājitpur",
            "Bandarban",
            "Baniachang",
            "Barguna",
            "Barisal",
            "Bera",
            "Bhairab Bāzār",
            "Bhāndāria",
            "Bhātpāra Abhaynagar",
            "Bherāmāra",
            "Bhola",
            "Bibir Hat",
            "Bogra",
            "Brahmanbaria",
            "Burhānuddin",
            "Chandpur",
            "Chapai Nababganj",
            "Char Bhadrāsan",
            "Char Golora",
            "Chhāgalnāiya",
            "Chhātak",
            "Chilmāri",
            "Chittagong",
            "Chuadanga",
            "Comilla",
            "Cox's Bazar",
            "Cox’s Bāzār",
            "Dhaka",
            "Dinajpur",
            "Dohār",
            "Faridpur",
            "Feni",
            "Gafargaon",
            "Gaibandha",
            "Gaurnadi",
            "Gazipur",
            "Gopalganj",
            "Habiganj",
            "Hājīganj",
            "Ishurdi",
            "Jahedpur",
            "Jamalpur",
            "Jessore",
            "Jhalokati",
            "Jhenaidah",
            "Jhingergācha",
            "Joymontop",
            "Joypur Hāt",
            "Joypurhat",
            "Kālia",
            "Kālīganj",
            "Kesabpur",
            "Khagrachhari",
            "Khanbaniara",
            "Khulna",
            "Kishoregonj",
            "Kishorganj",
            "Kurigram",
            "Kushtia",
            "Lākshām",
            "Lakshmipur",
            "Lālmohan",
            "Lalmonirhat",
            "Lalmonirhat District",
            "Madaripur",
            "Magura",
            "Mahasthangarh",
            "Manikchari",
            "Manikganj",
            "Mathba",
            "Maulavi Bāzār",
            "Maulvibazar",
            "Mehendiganj",
            "Meherpur",
            "Mirzāpur",
            "Morrelgonj",
            "Muktāgācha",
            "Munshiganj",
            "Mymensingh",
            "Nabīnagar",
            "Nāgarpur",
            "Nageswari",
            "Nālchiti",
            "Naogaon",
            "Narail",
            "Narayanganj",
            "Narsingdi",
            "Natore",
            "Nawābganj",
            "Netrakona",
            "Nilphamari Zila",
            "Noakhali",
            "Nowlamary",
            "Pabna",
            "Pālang",
            "Paltan",
            "Panchagarh",
            "Pār Naogaon",
            "Parbatipur",
            "Parvez Ali",
            "Parvez Ali Hossain",
            "Patiya",
            "Patuakhali",
            "Phultala",
            "Pīrgaaj",
            "Pirojpur",
            "Puthia",
            "Rāipur",
            "Rajbari",
            "Rajshahi",
            "Rāmganj",
            "Ramnagar",
            "Rangamati",
            "Rangpur",
            "Raojān",
            "Saidpur",
            "Sakhipur",
            "Sandwīp",
            "Sarankhola",
            "Sarishābāri",
            "Sātkania",
            "Satkhira",
            "Sayani",
            "Shāhzādpur",
            "Shariatpur",
            "Sherpur",
            "Shibganj",
            "Sirajganj",
            "Sonārgaon",
            "Sunamganj",
            "Sylhet",
            "Tangail",
            "Teknāf",
            "Thakurgaon",
            "Tungi",
            "Tungipāra",
            "Ujalpur",
            "Uttar Char Fasson"
        ]
    },
    {
        "label": "Barbados",
        "code": "BB",
        "phone": "+1-246",
        "cities": [
            "Bathsheba",
            "Bridgetown",
            "Crane",
            "Greenland",
            "Holetown",
            "Oistins",
            "Speightstown"
        ]
    },
    {
        "label": "Belarus",
        "code": "BY",
        "phone": "375",
        "cities": [
            "Aktsyabrski",
            "Antopal’",
            "Ashmyanski Rayon",
            "Ashmyany",
            "Asipovichy",
            "Asipovitski Rayon",
            "Asnyezhytsy",
            "Astrashytski Haradok",
            "Astravyets",
            "Astravyetski Rayon",
            "Atolina",
            "Azyartso",
            "Babruysk",
            "Babruyski Rayon",
            "Bal’shavik",
            "Balbasava",
            "Baran’",
            "Baranovichi",
            "Baranovichskiy Rayon",
            "Baruny",
            "Barysaw",
            "Barysawski Rayon",
            "Blon’",
            "Bobr",
            "Borovlyany",
            "Brahin",
            "Brahinski Rayon",
            "Braslaw",
            "Braslawski Rayon",
            "Brest",
            "Brestski Rayon",
            "Buda-Kashalyova",
            "Buynichy",
            "Byahoml’",
            "Byalynichy",
            "Byalynitski Rayon",
            "Byarezinski Rayon",
            "Byaroza",
            "Byarozawka",
            "Byarozawski Rayon",
            "Byelaazyorsk",
            "Byerazino",
            "Byeshankovitski Rayon",
            "Bykhaw",
            "Chachersk",
            "Chacherski Rayon",
            "Charnawchytsy",
            "Chashniki",
            "Chashnitski Rayon",
            "Chavuski Rayon",
            "Chavusy",
            "Chervyen’",
            "Chervyen’ski Rayon",
            "Cherykaw",
            "Cherykawski Rayon",
            "Chyrvonaya Slabada",
            "Chyst’",
            "Damachava",
            "Dashkawka",
            "Davyd-Haradok",
            "Dobrush",
            "Dokshytski Rayon",
            "Dokshytsy",
            "Dowsk",
            "Drahichyn",
            "Drahichynski Rayon",
            "Druya",
            "Druzhny",
            "Drybin",
            "Drybinski Rayon",
            "Dubrowna",
            "Dukora",
            "Dyatlovo",
            "Dzisna",
            "Dzyarzhynsk",
            "Enyerhyetykaw",
            "Fanipol",
            "Frunzyenski Rayon",
            "Grodnenskiy Rayon",
            "Hal’shany",
            "Hantsavichy",
            "Hantsavitski Rayon",
            "Haradok",
            "Haradotski Rayon",
            "Haradzishcha",
            "Haradzyeya",
            "Hatava",
            "Hlusha",
            "Hlusk",
            "Hlybokaye",
            "Hlybotski Rayon",
            "Homyel'",
            "Homyel’ski Rayon",
            "Horad Baranavichy",
            "Horad Brest",
            "Horad Hrodna",
            "Horad Smalyavichy",
            "Horad Zhodzina",
            "Horatski Rayon",
            "Horki",
            "Hotsk",
            "Hrodna",
            "Hyeranyony",
            "Il’ya",
            "Indura",
            "Ivanava",
            "Ivanawski Rayon",
            "Ivatsevichy",
            "Ivyanyets",
            "Iwye",
            "Kadino",
            "Kalinkavichy",
            "Kalodzishchy",
            "Kamyanyets",
            "Kamyanyetski Rayon",
            "Kamyanyuki",
            "Kamyennyya Lavy",
            "Kapyl’",
            "Kapyl’ski Rayon",
            "Karanyowka",
            "Karelichy",
            "Karelitski Rayon",
            "Karma",
            "Kastrychnitski Rayon",
            "Kastsyukovichy",
            "Kastsyukowka",
            "Khal’ch",
            "Khatsyezhyna",
            "Khodasy",
            "Kholopenichi",
            "Khotsimsk",
            "Khotsimski Rayon",
            "Khoyniki",
            "Kirawsk",
            "Klichaw",
            "Klimavichy",
            "Klyetsk",
            "Klyetski Rayon",
            "Kobryn",
            "Kokhanava",
            "Konstantinovo",
            "Korolëv Stan",
            "Kosava",
            "Krasnapol’ski Rayon",
            "Krasnapollye",
            "Krasnaye",
            "Krasnosel’skiy",
            "Krasnyy Bereg",
            "Kreva",
            "Kruhlaye",
            "Krupki",
            "Krychaw",
            "Kryvichy",
            "Lahishyn",
            "Lahoysk",
            "Lida",
            "Lidski Rayon",
            "Loshnitsa",
            "Loyew",
            "Luhavaya Slabada",
            "Luninyets",
            "Lyakhavichy",
            "Lyasny",
            "Lyel’chytski Rayon",
            "Lyel’chytsy",
            "Lyeninski Rayon",
            "Lyepyel’",
            "Lyepyel’ski Rayon",
            "Lyeskawka",
            "Lyntupy",
            "Lyozna",
            "Lyoznyenski Rayon",
            "Lyuban’",
            "Lyubcha",
            "Machulishchy",
            "Mahilyow",
            "Mahilyowski Rayon",
            "Maladzyechna",
            "Malaryta",
            "Mar’’ina Horka",
            "Maskowski Rayon",
            "Mazyr",
            "Mazyrski Rayon",
            "Michanovichi",
            "Mikashevichy",
            "Minsk",
            "Mir",
            "Mosar",
            "Mosty",
            "Motal’",
            "Mstsislaw",
            "Myadzyel",
            "Myadzyel’ski Rayon",
            "Myazhysyatki",
            "Myorski Rayon",
            "Myory",
            "Myshkavichy",
            "Narach",
            "Narowlya",
            "Nasilava",
            "Navapolatsk",
            "Novaya Huta",
            "Novogrudok",
            "Novolukoml’",
            "Novosel’ye",
            "Novy Svyerzhan’",
            "Nyakhachava",
            "Nyasvizh",
            "Nyasvizhski Rayon",
            "Orsha",
            "Osveya",
            "Palykavichy Pyershyya",
            "Partyzanski Rayon",
            "Parychy",
            "Pastavy",
            "Pastawski Rayon",
            "Peramoga",
            "Pinsk",
            "Plyeshchanitsy",
            "Polatsk",
            "Polatski Rayon",
            "Posëlok Voskhod",
            "Prawdzinski",
            "Pruzhanski Rayon",
            "Pruzhany",
            "Pryvol’ny",
            "Pukhavichy",
            "Pukhavichy Raion",
            "Pyatryshki",
            "Pyetrykaw",
            "Radashkovichy",
            "Rahachow",
            "Rahachowski Rayon",
            "Rakaw",
            "Ramanavichy",
            "Rasonski Rayon",
            "Rasony",
            "Rechytsa",
            "Ross’",
            "Rudzyensk",
            "Ruzhany",
            "Salihorsk",
            "Samakhvalavichy",
            "Sapotskin",
            "Sarachy",
            "Sasnovy Bor",
            "Savyetski Rayon",
            "Schomyslitsa",
            "Sharkawshchyna",
            "Sharkawshchynski Rayon",
            "Shchuchyn",
            "Shchuchynski Rayon",
            "Shklow",
            "Shklowski Rayon",
            "Shumilinski Rayon",
            "Skidel’",
            "Slabada",
            "Slawharad",
            "Slonim",
            "Slutsk",
            "Slutski Rayon",
            "Smalyavitski Rayon",
            "Smarhon’",
            "Smilavichy",
            "Snow",
            "Soly",
            "Stan’kava",
            "Staradarozhski Rayon",
            "Starobin",
            "Staryya Darohi",
            "Stawbtsowski Rayon",
            "Stolin",
            "Stolinski Rayon",
            "Stowbtsy",
            "Svir",
            "Svislach",
            "Svislach",
            "Svyetlahorsk",
            "Syanno",
            "Syenitsa",
            "Syennyenski Rayon",
            "Syomkava",
            "Talachyn",
            "Tsentral’ny Rayon",
            "Tsimkavichy",
            "Tsyelyakhany",
            "Turaw",
            "Turets-Bayary",
            "Urechcha",
            "Ushachy",
            "Usiazh",
            "Uzda",
            "Uzdzyenski Rayon",
            "Valozhyn",
            "Vasilyevichy",
            "Veyno",
            "Vidzy",
            "Vilyeyka",
            "Vilyeyski Rayon",
            "Vishnyeva",
            "Vishow",
            "Vitebsk",
            "Volkovysk",
            "Voranava",
            "Vyalikaya Byerastavitsa",
            "Vyaliki Trastsyanets",
            "Vyerkhnyadzvinsk",
            "Vyerkhnyadzvinski Rayon",
            "Vyetka",
            "Vyetkawski Rayon",
            "Vysokaye",
            "Yalizava",
            "Yel’sk",
            "Yubilyeyny",
            "Zamostochye",
            "Zaslawye",
            "Zavodski Rayon",
            "Zel’va",
            "Zhabinka",
            "Zhabinkawski Rayon",
            "Zhaludok",
            "Zhdanovichy",
            "Zhirovichi",
            "Zhlobin",
            "Zhlobinski Rayon",
            "Zhytkavichy",
            "Znamenka",
            "Zyembin",
            "Октябрьский"
        ]
    },
    {
        "label": "Belgium",
        "code": "BE",
        "phone": "32",
        "cities": [
            "Aalst",
            "Aalter",
            "Aarschot",
            "Aartselaar",
            "Aiseau",
            "Alken",
            "Alveringem",
            "Amay",
            "Amblève",
            "Andenne",
            "Anderlues",
            "Anhée",
            "Ans",
            "Anthisnes",
            "Antoing",
            "Antwerpen",
            "Anzegem",
            "Ardooie",
            "Arendonk",
            "Arlon",
            "As",
            "Asse",
            "Assenede",
            "Assesse",
            "Ath",
            "Attert",
            "Aubange",
            "Aubel",
            "Avelgem",
            "Awans",
            "Aywaille",
            "Baarle-Hertog",
            "Baelen",
            "Balen",
            "Basse Lasne",
            "Bassenge",
            "Bastogne",
            "Beaumont",
            "Beauraing",
            "Beauvechain",
            "Beernem",
            "Beerse",
            "Beersel",
            "Begijnendijk",
            "Bekkevoort",
            "Beloeil",
            "Beringen",
            "Berlaar",
            "Berlare",
            "Berloz",
            "Bernissart",
            "Bertem",
            "Bertogne",
            "Bertrix",
            "Bever",
            "Beveren",
            "Beyne-Heusay",
            "Bierbeek",
            "Bièvre",
            "Bilzen",
            "Binche",
            "Blankenberge",
            "Blégny",
            "Bocholt",
            "Boechout",
            "Bonheiden",
            "Boom",
            "Boortmeerbeek",
            "Borgloon",
            "Bornem",
            "Borsbeek",
            "Bouillon",
            "Boussu",
            "Boutersem",
            "Braine-l'Alleud",
            "Braine-le-Château",
            "Braine-le-Comte",
            "Braives",
            "Brasschaat",
            "Brecht",
            "Bredene",
            "Bree",
            "Brugelette",
            "Brugge",
            "Brunehault",
            "Brussels",
            "Buggenhout",
            "Bullange",
            "Burdinne",
            "Butgenbach",
            "Celles",
            "Cerfontaine",
            "Chapelle-lez-Herlaimont",
            "Charleroi",
            "Chastre-Villeroux-Blanmont",
            "Châtelet",
            "Chaudfontaine",
            "Chaumont-Gistoux",
            "Chièvres",
            "Chimay",
            "Chiny",
            "Ciney",
            "Clavier",
            "Colfontaine",
            "Comblain-au-Pont",
            "Courcelles",
            "Court-Saint-Étienne",
            "Couvin",
            "Crisnée",
            "Dalhem",
            "Damme",
            "Daverdisse",
            "De Haan",
            "De Panne",
            "De Pinte",
            "Deerlijk",
            "Deinze",
            "Denderleeuw",
            "Dendermonde",
            "Dentergem",
            "Dessel",
            "Destelbergen",
            "Deurne",
            "Diegem",
            "Diepenbeek",
            "Diest",
            "Diksmuide",
            "Dilbeek",
            "Dinant",
            "Dison",
            "Doische",
            "Donceel",
            "Dour",
            "Drogenbos",
            "Duffel",
            "Durbuy",
            "Écaussinnes-d’Enghien",
            "Edegem",
            "Eeklo",
            "Éghezée",
            "Ellezelles",
            "Enghien",
            "Engis",
            "Érezée",
            "Erquelinnes",
            "Esneux",
            "Essen",
            "Estaimpuis",
            "Estinnes-au-Val",
            "Étalle",
            "Eupen",
            "Evergem",
            "Faimes",
            "Farciennes",
            "Fauvillers",
            "Ferrières",
            "Fexhe-le-Haut-Clocher",
            "Flémalle-Haute",
            "Fléron",
            "Fleurus",
            "Flobecq",
            "Floreffe",
            "Florennes",
            "Florenville",
            "Forville",
            "Fosses-la-Ville",
            "Frameries",
            "Frasnes-lez-Buissenal",
            "Froidchapelle",
            "Galmaarden",
            "Gavere",
            "Gedinne",
            "Geel",
            "Geer",
            "Geetbets",
            "Gembloux",
            "Genappe",
            "Genk",
            "Gent",
            "Geraardsbergen",
            "Gerpinnes",
            "Gesves",
            "Gingelom",
            "Gistel",
            "Glabbeek",
            "Gooik",
            "Gouvy",
            "Grez-Doiceau",
            "Grimbergen",
            "Grobbendonk",
            "Haacht",
            "Haaltert",
            "Habay-la-Vieille",
            "Halen",
            "Halle",
            "Hamme",
            "Hamoir",
            "Hamois",
            "Hannut",
            "Harelbeke",
            "Hasselt",
            "Hastière-Lavaux",
            "Havelange",
            "Heers",
            "Heist-op-den-Berg",
            "Helchteren",
            "Hemiksem",
            "Hensies",
            "Herbeumont",
            "Herent",
            "Herentals",
            "Herenthout",
            "Herk-de-Stad",
            "Herne",
            "Héron",
            "Herselt",
            "Herstal",
            "Herve",
            "Herzele",
            "Heusden",
            "Hoboken",
            "Hoegaarden",
            "Hoeilaart",
            "Hoeselt",
            "Holsbeek",
            "Hooglede",
            "Hoogstraten",
            "Hotton",
            "Houffalize",
            "Houthalen",
            "Houthulst",
            "Houyet",
            "Hove",
            "Huldenberg",
            "Hulshout",
            "Huy",
            "Ichtegem",
            "Ieper",
            "Incourt",
            "Ingelmunster",
            "Ittre",
            "Izegem",
            "Jabbeke",
            "Jalhay",
            "Jodoigne",
            "Juprelle",
            "Jurbise",
            "Kalmthout",
            "Kampenhout",
            "Kapelle-op-den-Bos",
            "Kapellen",
            "Kaprijke",
            "Kasterlee",
            "Keerbergen",
            "Kinrooi",
            "Knesselare",
            "Knokke-Heist",
            "Koekelare",
            "Koksijde",
            "Kontich",
            "Kortemark",
            "Kortenaken",
            "Kortenberg",
            "Kortessem",
            "Kortrijk",
            "Kraainem",
            "Kruibeke",
            "Kruishoutem",
            "Kuurne",
            "La Bruyère",
            "La Calamine",
            "La Hulpe",
            "La Louvière",
            "La Roche-en-Ardenne",
            "Laarne",
            "Lanaken",
            "Landen",
            "Lebbeke",
            "Lede",
            "Ledeberg",
            "Ledegem",
            "Léglise",
            "Lendelede",
            "Lens",
            "Leopoldsburg",
            "Lessines",
            "Leuven",
            "Libin",
            "Lichtervelde",
            "Liedekerke",
            "Liège",
            "Lier",
            "Lierneux",
            "Lille",
            "Limbourg",
            "Lincent",
            "Linkebeek",
            "Lint",
            "Lobbes",
            "Lochristi",
            "Lokeren",
            "Lommel",
            "Londerzeel",
            "Lontzen",
            "Louvain-la-Neuve",
            "Lovendegem",
            "Lubbeek",
            "Lummen",
            "Maaseik",
            "Maasmechelen",
            "Machelen",
            "Maldegem",
            "Malmédy",
            "Manage",
            "Manhay",
            "Marche-en-Famenne",
            "Marchin",
            "Martelange",
            "Mechelen",
            "Meerhout",
            "Meise",
            "Meix-devant-Virton",
            "Melle",
            "Menen",
            "Merbes-le-Château",
            "Merchtem",
            "Merelbeke",
            "Merksplas",
            "Messancy",
            "Mettet",
            "Meulebeke",
            "Middelkerke",
            "Modave",
            "Moerbeke",
            "Mol",
            "Momignies",
            "Mons",
            "Mons-lez-Liège",
            "Mont-Saint-Guibert",
            "Moorslede",
            "Morlanwelz-Mariemont",
            "Mortsel",
            "Mouscron",
            "Musson",
            "Namur",
            "Nandrin",
            "Nassogne",
            "Nazareth",
            "Neerpelt",
            "Neufchâteau",
            "Nevele",
            "Niel",
            "Nieuwerkerken",
            "Nieuwpoort",
            "Nijlen",
            "Ninove",
            "Nivelles",
            "Noville-les-Bois",
            "Ohey",
            "Olen",
            "Olne",
            "Onhaye",
            "Oostduinkerke",
            "Oosterzele",
            "Oostkamp",
            "Oostmalle",
            "Oostrozebeke",
            "Opglabbeek",
            "Opwijk",
            "Oreye",
            "Ostend",
            "Ottignies",
            "Oud-Heverlee",
            "Oud-Turnhout",
            "Oudenaarde",
            "Oudenburg",
            "Ouffet",
            "Oupeye",
            "Overijse",
            "Overpelt",
            "Paliseul",
            "Pecq",
            "Peer",
            "Pepingen",
            "Pepinster",
            "Perre",
            "Péruwelz",
            "Perwez",
            "Philippeville",
            "Pittem",
            "Plombières",
            "Pont-à-Celles",
            "Poperinge",
            "Profondeville",
            "Province de Liège",
            "Province de Namur",
            "Province du Brabant Wallon",
            "Province du Hainaut",
            "Province du Luxembourg",
            "Provincie Antwerpen",
            "Provincie Limburg",
            "Provincie Oost-Vlaanderen",
            "Provincie Vlaams-Brabant",
            "Provincie West-Vlaanderen",
            "Putte",
            "Puurs",
            "Quaregnon",
            "Quévy-le-Petit",
            "Quiévrain",
            "Raeren",
            "Ramillies",
            "Ranst",
            "Ravels",
            "Rebecq-Rognon",
            "Remicourt",
            "Rendeux",
            "Retie",
            "Riemst",
            "Rijkevorsel",
            "Rixensart",
            "Rochefort",
            "Roeselare",
            "Roeulx",
            "Ronse",
            "Rotselaar",
            "Rouvroy",
            "Ruiselede",
            "Rumes",
            "Rumst",
            "Saint-Ghislain",
            "Saint-Hubert",
            "Saint-Léger",
            "Saint-Nicolas",
            "Saint-Vith",
            "Sainte-Ode",
            "Schelle",
            "Schilde",
            "Schoten",
            "Seneffe",
            "Seraing",
            "Silly",
            "Sint-Amands",
            "Sint-Genesius-Rode",
            "Sint-Gillis-Waas",
            "Sint-Joris",
            "Sint-Katelijne-Waver",
            "Sint-Kruis",
            "Sint-Laureins",
            "Sint-Lievens-Houtem",
            "Sint-Maria-Lierde",
            "Sint-Martens-Latem",
            "Sint-Martens-Lennik",
            "Sint-Niklaas",
            "Sint-Pieters-Leeuw",
            "Sint-Pieters-Voeren",
            "Sint-Truiden",
            "Soignies",
            "Sombreffe",
            "Somme-Leuze",
            "Soumagne",
            "Spa",
            "Sprimont",
            "Stabroek",
            "Staden",
            "Stavelot",
            "Steenokkerzeel",
            "Stekene",
            "Stoumont",
            "Tellin",
            "Temse",
            "Tenneville",
            "Terkoest",
            "Ternat",
            "Tervuren",
            "Tessenderlo",
            "Theux",
            "Thuin",
            "Tielt",
            "Tienen",
            "Tinlot",
            "Tintigny",
            "Tongeren",
            "Torhout",
            "Tournai",
            "Tremelo",
            "Trois-Ponts",
            "Trooz",
            "Tubize",
            "Turnhout",
            "Vaux-sur-Sûre",
            "Verlaine",
            "Verviers",
            "Veurne",
            "Vielsalm",
            "Villers-la-Ville",
            "Villers-le-Bouillet",
            "Vilvoorde",
            "Virton",
            "Visé",
            "Vorselaar",
            "Vosselaar",
            "Waarschoot",
            "Waasmunster",
            "Wachtebeke",
            "Waimes",
            "Walcourt",
            "Walhain-Saint-Paul",
            "Wanze",
            "Waregem",
            "Waremme",
            "Wasseiges",
            "Waterloo",
            "Wavre",
            "Welkenraedt",
            "Wellen",
            "Wellin",
            "Wemmel",
            "Wenduine",
            "Wervik",
            "Westerlo",
            "Wetteren",
            "Wevelgem",
            "Wezembeek-Oppem",
            "Wichelen",
            "Wielsbeke",
            "Wijnegem",
            "Willebroek",
            "Wingene",
            "Wommelgem",
            "Wuustwezel",
            "Yvoir",
            "Zandhoven",
            "Zaventem",
            "Zedelgem",
            "Zeebrugge",
            "Zele",
            "Zelzate",
            "Zemst",
            "Zingem",
            "Zoersel",
            "Zomergem",
            "Zonhoven",
            "Zonnebeke",
            "Zottegem",
            "Zoutleeuw",
            "Zuienkerke",
            "Zulte",
            "Zutendaal",
            "Zwevegem",
            "Zwijndrecht"
        ]
    },
    {
        "label": "Belize",
        "code": "BZ",
        "phone": "501",
        "cities": [
            "Belize City",
            "Belmopan",
            "Benque Viejo el Carmen",
            "Corozal",
            "Dangriga",
            "Hopelchén",
            "Orange Walk",
            "Placencia",
            "Punta Gorda",
            "San Ignacio",
            "San Pedro",
            "Shipyard",
            "Valley of Peace"
        ]
    },
    {
        "label": "Benin",
        "code": "BJ",
        "phone": "229",
        "cities": [
            "Abomey",
            "Abomey-Calavi",
            "Allada",
            "Banikoara",
            "Bassila",
            "Bembèrèkè",
            "Bétérou",
            "Bohicon",
            "Comé",
            "Commune of Agbangnizoun",
            "Commune of Athieme",
            "Commune of Djougou",
            "Cotonou",
            "Cové",
            "Dassa-Zoumé",
            "Djakotomey",
            "Djougou",
            "Dogbo",
            "Guilmaro",
            "Hévié",
            "Hinvi",
            "Kandi",
            "Kétou",
            "Lokossa",
            "Malanville",
            "Natitingou",
            "Nikki",
            "Ouidah",
            "Parakou",
            "Pobé",
            "Porto-Novo",
            "Sakété",
            "Savalou",
            "Savé",
            "Tanguieta",
            "Tchaourou"
        ]
    },
    {
        "label": "Bermuda",
        "code": "BM",
        "phone": "+1-441",
        "cities": [
            "Devonshire",
            "Flatts",
            "Hamilton",
            "Paget",
            "Somerset",
            "Southampton",
            "St. George's",
            "Tucker’s Town",
            "Warwick"
        ]
    },
    {
        "label": "Bhutan",
        "code": "BT",
        "phone": "975",
        "cities": [
            "Daga",
            "Daphu",
            "Gasa",
            "Ha",
            "Jakar",
            "Lhuentse",
            "Mongar",
            "Pajo",
            "Panbang",
            "Paro",
            "Pemagatshel",
            "Phuntsholing",
            "Punākha",
            "Samdrup Jongkhar",
            "Samtse",
            "Sarpang",
            "Shemgang",
            "Thimphu",
            "Trashi Yangtse",
            "Trashigang",
            "Trongsa",
            "Tsimasham",
            "Tsirang",
            "Wangdue Phodrang"
        ]
    },
    {
        "label": "Bolivia",
        "code": "BO",
        "phone": "591",
        "cities": [
            "Abapó",
            "Achacachi",
            "Aiquile",
            "Amarete",
            "Arani",
            "Ascención de Guarayos",
            "Ascensión",
            "Atocha",
            "Batallas",
            "Bermejo",
            "Betanzos",
            "Bolivar",
            "Boyuibe",
            "Buena Vista",
            "Camargo",
            "Camiri",
            "Capinota",
            "Caranavi",
            "Challapata",
            "Charagua",
            "Chimoré",
            "Chulumani",
            "Cliza",
            "Cobija",
            "Cochabamba",
            "Colchani",
            "Colchani",
            "Colomi",
            "Colquechaca",
            "Colquiri",
            "Comarapa",
            "Concepción",
            "Coripata",
            "Coroico",
            "Cotoca",
            "Curahuara de Carangas",
            "Enrique Baldivieso",
            "Entre Ríos",
            "Eucaliptus",
            "German Busch",
            "Guanay",
            "Guarayos",
            "Guayaramerín",
            "Huanuni",
            "Huarina",
            "Huatajata",
            "Independencia",
            "Irpa Irpa",
            "Jorochito",
            "José Manuel Pando",
            "La Bélgica",
            "La Paz",
            "Lahuachaca",
            "Limoncito",
            "Litoral de Atacama",
            "Llallagua",
            "Los Negros",
            "Machacamarca",
            "Mairana",
            "Mapiri",
            "Mineros",
            "Mizque",
            "Monteagudo",
            "Montero",
            "Nor Carangas Province",
            "Okinawa Número Uno",
            "Oruro",
            "Padilla",
            "Pailón",
            "Patacamaya",
            "Paurito",
            "Poopó",
            "Portachuelo",
            "Potosí",
            "Provincia Abuná",
            "Provincia Alonzo de Ibáñez",
            "Provincia Andrés Ibáñez",
            "Provincia Ángel Sandoval",
            "Provincia Arani",
            "Provincia Arce",
            "Provincia Aroma",
            "Provincia Arque",
            "Provincia Avaroa",
            "Provincia Avilez",
            "Provincia Ayopaya",
            "Provincia Azurduy",
            "Provincia Bautista Saavedra",
            "Provincia Belisario Boeto",
            "Provincia Camacho",
            "Provincia Campero",
            "Provincia Capinota",
            "Provincia Carangas",
            "Provincia Carrasco",
            "Provincia Cercado",
            "Provincia Cercado",
            "Provincia Cercado",
            "Provincia Cercado",
            "Provincia Chaparé",
            "Provincia Charcas",
            "Provincia Chayanta",
            "Provincia Chiquitos",
            "Provincia Cordillera",
            "Provincia Daniel Campos",
            "Provincia Esteban Arce",
            "Provincia Florida",
            "Provincia Franz Tamayo",
            "Provincia General Bilbao",
            "Provincia General Federico Román",
            "Provincia General José Ballivián",
            "Provincia Germán Jordán",
            "Provincia Gran Chaco",
            "Provincia Gualberto Villarroel",
            "Provincia Hernando Siles",
            "Provincia Ichilo",
            "Provincia Ingavi",
            "Provincia Inquisivi",
            "Provincia Iténez",
            "Provincia Iturralde",
            "Provincia Ladislao Cabrera",
            "Provincia Larecaja",
            "Provincia Linares",
            "Provincia Loayza",
            "Provincia Los Andes",
            "Provincia Luis Calvo",
            "Provincia Madre de Dios",
            "Provincia Mamoré",
            "Provincia Manco Kapac",
            "Provincia Manuel María Caballero",
            "Provincia Manuripi",
            "Provincia Marbán",
            "Provincia Méndez",
            "Provincia Mizque",
            "Provincia Modesto Omiste",
            "Provincia Moxos",
            "Provincia Muñecas",
            "Provincia Murillo",
            "Provincia Nicolás Suárez",
            "Provincia Nor Chichas",
            "Provincia Nor Cinti",
            "Provincia Nor Lípez",
            "Provincia Nor Yungas",
            "Provincia Ñuflo de Chávez",
            "Provincia O’Connor",
            "Provincia Omasuyos",
            "Provincia Oropeza",
            "Provincia Pacajes",
            "Provincia Pantaleón Dalence",
            "Provincia Poopó",
            "Provincia Punata",
            "Provincia Quijarro",
            "Provincia Quillacollo",
            "Provincia Rafael Bustillo",
            "Provincia Saavedra",
            "Provincia Sabaya",
            "Provincia Sajama",
            "Provincia San Pedro de Totora",
            "Provincia Santiesteban",
            "Provincia Sara",
            "Provincia Saucari",
            "Provincia Sud Chichas",
            "Provincia Sud Cinti",
            "Provincia Sud Lípez",
            "Provincia Sud Yungas",
            "Provincia Tapacarí",
            "Provincia Tomás Barron",
            "Provincia Tomás Frías",
            "Provincia Tomina",
            "Provincia Vaca Diez",
            "Provincia Vallegrande",
            "Provincia Velasco",
            "Provincia Warnes",
            "Provincia Yacuma",
            "Provincia Yamparáez",
            "Provincia Zudáñez",
            "Puerto de Mejillones",
            "Puerto Quijarro",
            "Puesto de Pailas",
            "Punata",
            "Quillacollo",
            "Quime",
            "Reyes",
            "Riberalta",
            "Roboré",
            "Rurrenabaque",
            "Sacaba",
            "Samaipata",
            "San Borja",
            "San Carlos",
            "San Ignacio de Velasco",
            "San Juan del Surutú",
            "San Julian",
            "San Matías",
            "San Pablo",
            "San Pedro",
            "San Pedro",
            "San Ramón",
            "Santa Ana de Yacuma",
            "Santa Bárbara",
            "Santa Cruz de la Sierra",
            "Santa Rita",
            "Santa Rosa",
            "Santa Rosa del Sara",
            "Santiago del Torno",
            "Sebastian Pagador Province",
            "Sipe Sipe",
            "Sorata",
            "Sucre",
            "Sud Carangas Province",
            "Tarabuco",
            "Tarata",
            "Tarija",
            "Tiahuanaco",
            "Tiquipaya",
            "Tiraque Province",
            "Totora",
            "Totoral",
            "Trinidad",
            "Tupiza",
            "Urubichá",
            "Uyuni",
            "Vallegrande",
            "Villa Yapacaní",
            "Villamontes",
            "Villazón",
            "Viloco",
            "Warnes",
            "Yacuiba",
            "Yumani"
        ]
    },
    {
        "label": "Bonaire, Sint Eustatius and Saba",
        "code": "BQ",
        "phone": "599",
        "cities": [
            "Boven Bolivia",
            "Dorp Tera Kora",
            "Oranjestad",
            "Rincon",
            "St. Johns",
            "The Bottom",
            "Windwardside",
            "Zion's Hill"
        ]
    },
    {
        "label": "Bosnia and Herzegovina",
        "code": "BA",
        "phone": "387",
        "cities": [
            "Balatun",
            "Banja Luka",
            "Banovići",
            "Barice",
            "Bihać",
            "Bijela",
            "Bijeljina",
            "Bila",
            "Bileća",
            "Blagaj",
            "Blatnica",
            "Bosanska Krupa",
            "Bosanski Petrovac",
            "Bosansko Grahovo",
            "Brčko",
            "Breza",
            "Brka",
            "Brod",
            "Bronzani Majdan",
            "Bugojno",
            "Busovača",
            "Bužim",
            "Čajniče",
            "Čapljina",
            "Cazin",
            "Čečava",
            "Čelić",
            "Čelinac",
            "Cim",
            "Čitluk",
            "Ćoralići",
            "Crnići",
            "Derventa",
            "Divičani",
            "Doboj",
            "Dobrinje",
            "Dobrljin",
            "Domaljevac",
            "Donja Dubica",
            "Donja Mahala",
            "Donja Međiđa",
            "Donji Vakuf",
            "Drežnica",
            "Drinovci",
            "Drvar",
            "Dubrave Donje",
            "Dubrave Gornje",
            "Dubravica",
            "Dvorovi",
            "Foča",
            "Fojnica",
            "Gacko",
            "Glamoč",
            "Gnojnica",
            "Goražde",
            "Gorica",
            "Gornja Breza",
            "Gornja Koprivna",
            "Gornja Tuzla",
            "Gornje Moštre",
            "Gornje Živinice",
            "Gornji Vakuf",
            "Gostovići",
            "Gračanica",
            "Gradačac",
            "Gradiška",
            "Gromiljak",
            "Grude",
            "Hadžići",
            "Hercegovačko-Neretvanski Kanton",
            "Hiseti",
            "Hotonj",
            "Ilići",
            "Ilijaš",
            "Istočni Mostar",
            "Izačić",
            "Jablanica",
            "Jajce",
            "Janja",
            "Jelah",
            "Jezerski",
            "Kačuni",
            "Kakanj",
            "Kalenderovci Donji",
            "Kanton Sarajevo",
            "Karadaglije",
            "Kiseljak",
            "Kladanj",
            "Ključ",
            "Kneževo",
            "Knežica",
            "Kobilja Glava",
            "Kočerin",
            "Konjic",
            "Koran",
            "Kostajnica",
            "Kotor Varoš",
            "Kovači",
            "Kozarska Dubica",
            "Krupa na Vrbasu",
            "Laktaši",
            "Lamovita",
            "Liješnica",
            "Livno",
            "Ljubinje",
            "Ljubuški",
            "Lokvine",
            "Lopare",
            "Lukavac",
            "Lukavica",
            "Maglaj",
            "Maglajani",
            "Mahala",
            "Mala Kladuša",
            "Malešići",
            "Marićka",
            "Maslovare",
            "Mejdan - Obilićevo",
            "Milići",
            "Mionica",
            "Modriča",
            "Mostar",
            "Mramor",
            "Mrkonjić Grad",
            "Neum",
            "Nevesinje",
            "Novi Grad",
            "Novi Šeher",
            "Novi Travnik",
            "Obudovac",
            "Odžak",
            "Olovo",
            "Omanjska",
            "Omarska",
            "Opština Oštra Luka",
            "Opština Višegrad",
            "Orahovica Donja",
            "Orašac",
            "Orašje",
            "Orguz",
            "Oštra Luka",
            "Ostrožac",
            "Otoka",
            "Pajić Polje",
            "Pale",
            "Pazarić",
            "Peći",
            "Pećigrad",
            "Pelagićevo",
            "Petkovci",
            "Piskavica",
            "Pjanići",
            "Podbrdo",
            "Podhum",
            "Podzvizd",
            "Polje",
            "Polje-Bijela",
            "Popovi",
            "Potoci",
            "Pribinić",
            "Priboj",
            "Prijedor",
            "Prozor",
            "Puračić",
            "Radišići",
            "Rodoč",
            "Rogatica",
            "Rudo",
            "Rumboci",
            "Šamac",
            "Sanica",
            "Sanski Most",
            "Sapna",
            "Sarajevo",
            "Šekovići",
            "Šerići",
            "Šipovo",
            "Široki Brijeg",
            "Skokovi",
            "Sladna",
            "Sokolac",
            "Solina",
            "Srbac",
            "Srebrenica",
            "Srebrenik",
            "Stanari",
            "Starcevica",
            "Stijena",
            "Stjepan-Polje",
            "Stolac",
            "Šturlić",
            "Šumatac",
            "Svodna",
            "Tasovčići",
            "Tešanj",
            "Tešanjka",
            "Teslić",
            "Todorovo",
            "Tojšići",
            "Tomislavgrad",
            "Travnik",
            "Trebinje",
            "Trn",
            "Tržačka Raštela",
            "Turbe",
            "Tuzla",
            "Ugljevik",
            "Ustikolina",
            "Vareš",
            "Varoška Rijeka",
            "Velagići",
            "Velika Kladuša",
            "Velika Obarska",
            "Vidoši",
            "Višegrad",
            "Visoko",
            "Vitez",
            "Vitina",
            "Vlasenica",
            "Vogošća",
            "Voljevac",
            "Vrnograč",
            "Vukovije Donje",
            "Zabrišće",
            "Zavidovići",
            "Zborište",
            "Željezno Polje",
            "Zenica",
            "Žepče",
            "Živinice",
            "Živinice",
            "Zvornik"
        ]
    },
    {
        "label": "Botswana",
        "code": "BW",
        "phone": "267",
        "cities": [
            "Bokaa",
            "Botlhapatlou",
            "Dekar",
            "Dukwe",
            "Dutlwe",
            "Gabane",
            "Gaborone",
            "Gaphatshwe",
            "Ghanzi",
            "Gobojango",
            "Gweta",
            "Hukuntsi",
            "Janeng",
            "Kalamare",
            "Kang",
            "Kanye",
            "Khakhea",
            "Khudumelapye",
            "Kopong",
            "Lehututu",
            "Lenchwe Le Tau",
            "Letlhakane",
            "Letlhakeng",
            "Letsheng",
            "Maapi",
            "Machaneng",
            "Mahalapye",
            "Makaleng",
            "Makobeng",
            "Makwata",
            "Manyana",
            "Masunga",
            "Mathakola",
            "Mathambgwane",
            "Mathathane",
            "Maun",
            "Maunatlala",
            "Metsemotlhaba",
            "Mmathubudukwane",
            "Mmopone",
            "Mochudi",
            "Mogapi",
            "Mogoditshane",
            "Moijabana",
            "Molepolole",
            "Mookane",
            "Mopipi",
            "Mosetse",
            "Mosopa",
            "Nata",
            "Nkoyaphiri",
            "Nokaneng",
            "Orapa",
            "Otse",
            "Palapye",
            "Pandamatenga",
            "Pilane",
            "Pilikwe",
            "Rakops",
            "Ramokgonami",
            "Ramotswa",
            "Ratholo",
            "Sebina",
            "Sefophe",
            "Sehithwa",
            "Sekoma",
            "Serowe",
            "Shakawe",
            "Sua",
            "Tamasane",
            "Thamaga",
            "Tobane",
            "Tonota",
            "Tshabong",
            "Werda"
        ]
    },
    {
        "label": "Bouvet Island",
        "code": "BV",
        "phone": "0055",
        "cities": []
    },
    {
        "label": "Brazil",
        "code": "BR",
        "phone": "55",
        "cities": [
            "Abadia de Goiás",
            "Abadia dos Dourados",
            "Abadiânia",
            "Abaeté",
            "Abaetetuba",
            "Abaiara",
            "Abaíra",
            "Abaré",
            "Abatiá",
            "Abdon Batista",
            "Abel Figueiredo",
            "Abelardo Luz",
            "Abre Campo",
            "Abreu e Lima",
            "Abreulândia",
            "Acaiaca",
            "Açailândia",
            "Acajutiba",
            "Acará",
            "Acarape",
            "Acaraú",
            "Acari",
            "Acauã",
            "Aceguá",
            "Acopiara",
            "Acorizal",
            "Acrelândia",
            "Acreúna",
            "Açu",
            "Açucena",
            "Adamantina",
            "Adelândia",
            "Adolfo",
            "Adrianópolis",
            "Adustina",
            "Afogados da Ingazeira",
            "Afonso Bezerra",
            "Afonso Cláudio",
            "Afonso Cunha",
            "Afrânio",
            "Afuá",
            "Agrestina",
            "Agricolândia",
            "Agrolândia",
            "Agronômica",
            "Água Azul do Norte",
            "Água Boa",
            "Água Boa",
            "Água Branca",
            "Água Branca",
            "Água Branca",
            "Água Clara",
            "Água Comprida",
            "Água Doce",
            "Água Doce do Maranhão",
            "Água Doce do Norte",
            "Água Fria",
            "Água Fria de Goiás",
            "Água Limpa",
            "Água Nova",
            "Água Preta",
            "Água Santa",
            "Aguaí",
            "Aguanil",
            "Águas Belas",
            "Águas da Prata",
            "Águas de Chapecó",
            "Águas de Lindóia",
            "Águas de Santa Bárbara",
            "Águas de São Pedro",
            "Águas Formosas",
            "Águas Frias",
            "Águas Lindas de Goiás",
            "Águas Mornas",
            "Águas Vermelhas",
            "Agudo",
            "Agudos",
            "Agudos do Sul",
            "Águia Branca",
            "Aguiar",
            "Aguiarnópolis",
            "Aimorés",
            "Aiquara",
            "Aiuaba",
            "Aiuruoca",
            "Ajuricaba",
            "Alagoa",
            "Alagoa Grande",
            "Alagoa Nova",
            "Alagoinha",
            "Alagoinha",
            "Alagoinha do Piauí",
            "Alagoinhas",
            "Alambari",
            "Albertina",
            "Alcântara",
            "Alcântaras",
            "Alcantil",
            "Alcinópolis",
            "Alcobaça",
            "Aldeias Altas",
            "Alecrim",
            "Alegre",
            "Alegrete",
            "Alegrete do Piauí",
            "Alegria",
            "Além Paraíba",
            "Alenquer",
            "Alexandria",
            "Alexânia",
            "Alfenas",
            "Alfredo Chaves",
            "Alfredo Marcondes",
            "Alfredo Vasconcelos",
            "Alfredo Wagner",
            "Algodão de Jandaíra",
            "Alhandra",
            "Aliança",
            "Aliança do Tocantins",
            "Almadina",
            "Almas",
            "Almeirim",
            "Almenara",
            "Almino Afonso",
            "Almirante Tamandaré",
            "Almirante Tamandaré do Sul",
            "Aloândia",
            "Alpercata",
            "Alpestre",
            "Alpinópolis",
            "Alta Floresta",
            "Alta Floresta d'Oeste",
            "Altair",
            "Altamira",
            "Altamira do Maranhão",
            "Altamira do Paraná",
            "Altaneira",
            "Altãnia",
            "Alterosa",
            "Altinho",
            "Altinópolis",
            "Alto Alegre",
            "Alto Alegre",
            "Alto Alegre do Maranhão",
            "Alto Alegre do Pindaré",
            "Alto Alegre dos Parecis",
            "Alto Araguaia",
            "Alto Bela Vista",
            "Alto Boa Vista",
            "Alto Caparaó",
            "Alto do Rodrigues",
            "Alto Feliz",
            "Alto Garças",
            "Alto Horizonte",
            "Alto Jequitibá",
            "Alto Longá",
            "Alto Paraguai",
            "Alto Paraíso",
            "Alto Paraíso",
            "Alto Paraíso de Goiás",
            "Alto Paraná",
            "Alto Parnaíba",
            "Alto Piquiri",
            "Alto Rio Doce",
            "Alto Rio Novo",
            "Alto Santo",
            "Alto Taquari",
            "Altônia",
            "Altos",
            "Alumínio",
            "Alvarães",
            "Alvarenga",
            "Álvares Florence",
            "Álvares Machado",
            "Álvaro de Carvalho",
            "Alvinlândia",
            "Alvinópolis",
            "Alvorada",
            "Alvorada",
            "Alvorada d'Oeste",
            "Alvorada de Minas",
            "Alvorada do Gurguéia",
            "Alvorada do Norte",
            "Alvorada do Sul",
            "Amajari",
            "Amambai",
            "Amapá",
            "Amapá do Maranhão",
            "Amaporã",
            "Amaraji",
            "Amaral Ferrador",
            "Amaralina",
            "Amarante",
            "Amarante do Maranhão",
            "Amargosa",
            "Amaturá",
            "Amélia Rodrigues",
            "América Dourada",
            "Americana",
            "Americano do Brasil",
            "Américo Brasiliense",
            "Américo de Campos",
            "Ametista do Sul",
            "Amontada",
            "Amorinópolis",
            "Amparo",
            "Amparo",
            "Amparo da Serra",
            "Amparo de São Francisco",
            "Ampére",
            "Anadia",
            "Anagé",
            "Anahy",
            "Anajás",
            "Anajatuba",
            "Analândia",
            "Anamã",
            "Ananás",
            "Ananindeua",
            "Anápolis",
            "Anapu",
            "Anapurus",
            "Anastácio",
            "Anaurilândia",
            "Anchieta",
            "Anchieta",
            "Andaraí",
            "Andirá",
            "Andorinha",
            "Andradas",
            "Andradina",
            "André da Rocha",
            "Andrelândia",
            "Angatuba",
            "Angelândia",
            "Angélica",
            "Angelim",
            "Angelina",
            "Angical",
            "Angical do Piauí",
            "Angico",
            "Angicos",
            "Angra dos Reis",
            "Anguera",
            "Ângulo",
            "Anhanguera",
            "Anhembi",
            "Anhumas",
            "Anicuns",
            "Anísio de Abreu",
            "Anita Garibaldi",
            "Anitápolis",
            "Anori",
            "Anta Gorda",
            "Antas",
            "Antonina",
            "Antonina do Norte",
            "Antônio Almeida",
            "Antônio Cardoso",
            "Antônio Carlos",
            "Antônio Carlos",
            "Antônio Dias",
            "Antônio Gonçalves",
            "Antônio João",
            "Antônio Martins",
            "Antônio Olinto",
            "Antônio Prado",
            "Antônio Prado de Minas",
            "Aparecida",
            "Aparecida",
            "Aparecida d'Oeste",
            "Aparecida de Goiânia",
            "Aparecida do Rio Doce",
            "Aparecida do Rio Negro",
            "Aparecida do Taboado",
            "Aperibé",
            "Apiacá",
            "Apiacás",
            "Apiaí",
            "Apicum-Açu",
            "Apiúna",
            "Apodi",
            "Aporá",
            "Aporé",
            "Apuarema",
            "Apucarana",
            "Apuí",
            "Apuiarés",
            "Aquidabã",
            "Aquidauana",
            "Aquiraz",
            "Arabutã",
            "Araçagi",
            "Araçaí",
            "Aracaju",
            "Araçariguama",
            "Araçás",
            "Aracati",
            "Aracatu",
            "Araçatuba",
            "Araci",
            "Aracitaba",
            "Aracoiaba",
            "Araçoiaba",
            "Araçoiaba da Serra",
            "Aracruz",
            "Araçu",
            "Araçuaí",
            "Aragarças",
            "Aragoiânia",
            "Aragominas",
            "Araguacema",
            "Araguaçu",
            "Araguaiana",
            "Araguaína",
            "Araguainha",
            "Araguanã",
            "Araguanã",
            "Araguapaz",
            "Araguari",
            "Araguatins",
            "Araioses",
            "Aral Moreira",
            "Aramari",
            "Arambaré",
            "Arame",
            "Aramina",
            "Arandu",
            "Arantina",
            "Arapeí",
            "Arapiraca",
            "Arapoema",
            "Araponga",
            "Arapongas",
            "Araporã",
            "Arapoti",
            "Arapuá",
            "Arapuã",
            "Araputanga",
            "Araquari",
            "Arara",
            "Araranguá",
            "Araraquara",
            "Araras",
            "Ararendá",
            "Arari",
            "Araricá",
            "Araripe",
            "Araripina",
            "Araruama",
            "Araruna",
            "Araruna",
            "Arataca",
            "Aratiba",
            "Aratuba",
            "Aratuípe",
            "Arauá",
            "Araucária",
            "Araújos",
            "Araxá",
            "Arceburgo",
            "Arco-Íris",
            "Arcos",
            "Arcoverde",
            "Areado",
            "Areal",
            "Arealva",
            "Areia",
            "Areia Branca",
            "Areia Branca",
            "Areia de Baraúnas",
            "Areial",
            "Areias",
            "Areiópolis",
            "Arenápolis",
            "Arenópolis",
            "Arês",
            "Argirita",
            "Aricanduva",
            "Arinos",
            "Aripuanã",
            "Ariquemes",
            "Ariranha",
            "Ariranha do Ivaí",
            "Armação",
            "Armação dos Búzios",
            "Armazém",
            "Arneiroz",
            "Aroazes",
            "Aroeiras",
            "Aroeiras do Itaim",
            "Arraial",
            "Arraial do Cabo",
            "Arraias",
            "Arroio do Meio",
            "Arroio do Padre",
            "Arroio do Sal",
            "Arroio do Tigre",
            "Arroio dos Ratos",
            "Arroio Grande",
            "Arroio Trinta",
            "Artur Nogueira",
            "Aruanã",
            "Arujá",
            "Arvoredo",
            "Arvorezinha",
            "Ascurra",
            "Aspásia",
            "Assaí",
            "Assaré",
            "Assis",
            "Assis Brasil",
            "Assis Chateaubriand",
            "Assunção",
            "Assunção do Piauí",
            "Astolfo Dutra",
            "Astorga",
            "Atalaia",
            "Atalaia",
            "Atalaia do Norte",
            "Atalanta",
            "Ataléia",
            "Atibaia",
            "Atílio Vivacqua",
            "Atins",
            "Augustinópolis",
            "Augusto Corrêa",
            "Augusto de Lima",
            "Augusto Pestana",
            "Augusto Severo",
            "Áurea",
            "Aurelino Leal",
            "Auriflama",
            "Aurilândia",
            "Aurora",
            "Aurora",
            "Aurora do Pará",
            "Aurora do Tocantins",
            "Autazes",
            "Avaí",
            "Avanhandava",
            "Avaré",
            "Aveiro",
            "Avelino Lopes",
            "Avelinópolis",
            "Axixá",
            "Axixá do Tocantins",
            "Babaçulândia",
            "Bacabal",
            "Bacabeira",
            "Bacuri",
            "Bacurituba",
            "Bady Bassitt",
            "Baependi",
            "Bagé",
            "Bagre",
            "Baía da Traição",
            "Baía Formosa",
            "Baianópolis",
            "Baião",
            "Baixa Grande",
            "Baixa Grande do Ribeiro",
            "Baixio",
            "Baixo Guandu",
            "Balbinos",
            "Baldim",
            "Baliza",
            "Balneário Arroio do Silva",
            "Balneário Barra do Sul",
            "Balneário Camboriú",
            "Balneário Gaivota",
            "Balneário Piçarras",
            "Balneário Pinhal",
            "Balneário Rincão",
            "Balsa Nova",
            "Bálsamo",
            "Balsas",
            "Bambuí",
            "Banabuiú",
            "Bananal",
            "Bananeiras",
            "Bandeira",
            "Bandeira do Sul",
            "Bandeirante",
            "Bandeirantes",
            "Bandeirantes",
            "Bandeirantes do Tocantins",
            "Bannach",
            "Banzaê",
            "Barão",
            "Barão de Antonina",
            "Barão de Cocais",
            "Barão de Cotegipe",
            "Barão de Grajaú",
            "Barão de Melgaço",
            "Barão de Monte Alto",
            "Barão do Triunfo",
            "Baraúna",
            "Baraúna",
            "Barbacena",
            "Barbalha",
            "Barbosa",
            "Barbosa Ferraz",
            "Barcarena",
            "Barcelona",
            "Barcelos",
            "Bariri",
            "Barra",
            "Barra Bonita",
            "Barra Bonita",
            "Barra d'Alcântara",
            "Barra da Estiva",
            "Barra de Guabiraba",
            "Barra de Santa Rosa",
            "Barra de Santana",
            "Barra de Santo Antônio",
            "Barra de São Francisco",
            "Barra de São Miguel",
            "Barra de São Miguel",
            "Barra do Bugres",
            "Barra do Chapéu",
            "Barra do Choça",
            "Barra do Corda",
            "Barra do Garças",
            "Barra do Guarita",
            "Barra do Jacaré",
            "Barra do Mendes",
            "Barra do Ouro",
            "Barra do Piraí",
            "Barra do Quaraí",
            "Barra do Ribeiro",
            "Barra do Rio Azul",
            "Barra do Rocha",
            "Barra do Turvo",
            "Barra dos Coqueiros",
            "Barra Funda",
            "Barra Longa",
            "Barra Mansa",
            "Barra Velha",
            "Barracão",
            "Barracão",
            "Barras",
            "Barreira",
            "Barreiras",
            "Barreiras do Piauí",
            "Barreirinha",
            "Barreirinhas",
            "Barreiro do Jaíba",
            "Barreiros",
            "Barretos",
            "Barrinha",
            "Barro",
            "Barro Alto",
            "Barro Alto",
            "Barro Duro",
            "Barro Preto",
            "Barrocas",
            "Barrolândia",
            "Barroquinha",
            "Barros Cassal",
            "Barroso",
            "Barueri",
            "Bastos",
            "Bataguassu",
            "Bataiporã",
            "Batalha",
            "Batalha",
            "Batatais",
            "Batayporã",
            "Baturité",
            "Bauru",
            "Bayeux",
            "Bebedouro",
            "Beberibe",
            "Beira Rio",
            "Bela Cruz",
            "Bela Vista",
            "Bela Vista da Caroba",
            "Bela Vista de Goiás",
            "Bela Vista de Minas",
            "Bela Vista do Maranhão",
            "Bela Vista do Paraíso",
            "Bela Vista do Piauí",
            "Bela Vista do Toldo",
            "Belágua",
            "Belém",
            "Belém",
            "Belém",
            "Belém de Maria",
            "Belém de São Francisco",
            "Belém do Brejo do Cruz",
            "Belém do Piauí",
            "Belém do São Francisco",
            "Belford Roxo",
            "Belmiro Braga",
            "Belmonte",
            "Belmonte",
            "Belo Campo",
            "Belo Horizonte",
            "Belo Jardim",
            "Belo Monte",
            "Belo Oriente",
            "Belo Vale",
            "Belterra",
            "Beneditinos",
            "Benedito Leite",
            "Benedito Novo",
            "Benevides",
            "Benjamin Constant",
            "Benjamin Constant do Sul",
            "Bento de Abreu",
            "Bento Fernandes",
            "Bento Gonçalves",
            "Bequimão",
            "Berilo",
            "Berizal",
            "Bernardino Batista",
            "Bernardino de Campos",
            "Bernardo do Mearim",
            "Bernardo Sayão",
            "Bertioga",
            "Bertolínia",
            "Bertópolis",
            "Beruri",
            "Betânia",
            "Betânia do Piauí",
            "Betim",
            "Bezerros",
            "Bias Fortes",
            "Bicas",
            "Biguaçu",
            "Bilac",
            "Biquinhas",
            "Birigui",
            "Biritiba Mirim",
            "Biritiba-Mirim",
            "Biritinga",
            "Bituruna",
            "Blumenau",
            "Boa Esperança",
            "Boa Esperança",
            "Boa Esperança",
            "Boa Esperança do Iguaçu",
            "Boa Esperança do Sul",
            "Boa Hora",
            "Boa Nova",
            "Boa Saúde",
            "Boa Ventura",
            "Boa Ventura de São Roque",
            "Boa Viagem",
            "Boa Vista",
            "Boa Vista",
            "Boa Vista da Aparecida",
            "Boa Vista das Missões",
            "Boa Vista do Buricá",
            "Boa Vista do Cadeado",
            "Boa Vista do Gurupi",
            "Boa Vista do Incra",
            "Boa Vista do Ramos",
            "Boa Vista do Sul",
            "Boa Vista do Tupim",
            "Boca da Mata",
            "Boca do Acre",
            "Bocaina",
            "Bocaina",
            "Bocaina de Minas",
            "Bocaina do Sul",
            "Bocaiúva",
            "Bocaiúva do Sul",
            "Bodó",
            "Bodocó",
            "Bodoquena",
            "Bofete",
            "Boituva",
            "Bom Conselho",
            "Bom Despacho",
            "Bom Jardim",
            "Bom Jardim",
            "Bom Jardim",
            "Bom Jardim da Serra",
            "Bom Jardim de Goiás",
            "Bom Jardim de Minas",
            "Bom Jesus",
            "Bom Jesus",
            "Bom Jesus",
            "Bom Jesus",
            "Bom Jesus",
            "Bom Jesus da Lapa",
            "Bom Jesus da Penha",
            "Bom Jesus da Serra",
            "Bom Jesus das Selvas",
            "Bom Jesus de Goiás",
            "Bom Jesus do Amparo",
            "Bom Jesus do Araguaia",
            "Bom Jesus do Galho",
            "Bom Jesus do Itabapoana",
            "Bom Jesus do Norte",
            "Bom Jesus do Oeste",
            "Bom Jesus do Sul",
            "Bom Jesus do Tocantins",
            "Bom Jesus do Tocantins",
            "Bom Jesus dos Perdões",
            "Bom Lugar",
            "Bom Princípio",
            "Bom Princípio do Piauí",
            "Bom Progresso",
            "Bom Repouso",
            "Bom Retiro",
            "Bom Retiro do Sul",
            "Bom Sucesso",
            "Bom Sucesso",
            "Bom Sucesso",
            "Bom Sucesso de Itararé",
            "Bom Sucesso do Sul",
            "Bombinhas",
            "Bonfim",
            "Bonfim",
            "Bonfim do Piauí",
            "Bonfinópolis",
            "Bonfinópolis de Minas",
            "Boninal",
            "Bonito",
            "Bonito",
            "Bonito",
            "Bonito",
            "Bonito de Minas",
            "Bonito de Santa Fé",
            "Bonópolis",
            "Boqueirão",
            "Boqueirão do Leão",
            "Boqueirão do Piauí",
            "Boquim",
            "Boquira",
            "Borá",
            "Boracéia",
            "Borba",
            "Borborema",
            "Borborema",
            "Borda da Mata",
            "Borebi",
            "Borrazópolis",
            "Bossoroca",
            "Botelhos",
            "Botucatu",
            "Botumirim",
            "Botuporã",
            "Botuverá",
            "Bozano",
            "Braço do Norte",
            "Braço do Trombudo",
            "Braga",
            "Bragança",
            "Bragança Paulista",
            "Braganey",
            "Branquinha",
            "Brás Pires",
            "Brasil Novo",
            "Brasilândia",
            "Brasilândia de Minas",
            "Brasilândia do Sul",
            "Brasilândia do Tocantins",
            "Brasiléia",
            "Brasileira",
            "Brasília",
            "Brasília de Minas",
            "Brasnorte",
            "Braúna",
            "Braúnas",
            "Brazabrantes",
            "Brazópolis",
            "Brejão",
            "Brejetuba",
            "Brejinho",
            "Brejinho",
            "Brejinho de Nazaré",
            "Brejo",
            "Brejo Alegre",
            "Brejo da Madre de Deus",
            "Brejo de Areia",
            "Brejo do Cruz",
            "Brejo do Piauí",
            "Brejo dos Santos",
            "Brejo Grande",
            "Brejo Grande do Araguaia",
            "Brejo Santo",
            "Brejões",
            "Brejolândia",
            "Breu Branco",
            "Breves",
            "Britânia",
            "Brochier",
            "Brodósqui",
            "Brodowski",
            "Brotas",
            "Brotas de Macaúbas",
            "Brumadinho",
            "Brumado",
            "Brunópolis",
            "Brusque",
            "Bueno Brandão",
            "Buenópolis",
            "Buenos Aires",
            "Buerarema",
            "Bugre",
            "Buíque",
            "Bujari",
            "Bujaru",
            "Buri",
            "Buritama",
            "Buriti",
            "Buriti Alegre",
            "Buriti Bravo",
            "Buriti de Goiás",
            "Buriti do Tocantins",
            "Buriti dos Lopes",
            "Buriti dos Montes",
            "Buriticupu",
            "Buritinópolis",
            "Buritirama",
            "Buritirana",
            "Buritis",
            "Buritis",
            "Buritizal",
            "Buritizeiro",
            "Butiá",
            "Caapiranga",
            "Caaporã",
            "Caarapó",
            "Caatiba",
            "Cabaceiras",
            "Cabaceiras do Paraguaçu",
            "Cabeceira Grande",
            "Cabeceiras",
            "Cabeceiras do Piauí",
            "Cabedelo",
            "Cabixi",
            "Cabo",
            "Cabo de Santo Agostinho",
            "Cabo Frio",
            "Cabo Verde",
            "Cabrália Paulista",
            "Cabreúva",
            "Cabrobó",
            "Caçador",
            "Caçapava",
            "Caçapava do Sul",
            "Cacaulândia",
            "Cacequi",
            "Cáceres",
            "Cachoeira",
            "Cachoeira Alta",
            "Cachoeira da Prata",
            "Cachoeira de Goiás",
            "Cachoeira de Minas",
            "Cachoeira de Pajeú",
            "Cachoeira do Arari",
            "Cachoeira do Piriá",
            "Cachoeira do Sul",
            "Cachoeira dos Índios",
            "Cachoeira Dourada",
            "Cachoeira Dourada",
            "Cachoeira Grande",
            "Cachoeira Paulista",
            "Cachoeiras de Macacu",
            "Cachoeirinha",
            "Cachoeirinha",
            "Cachoeirinha",
            "Cachoeiro de Itapemirim",
            "Cacimba de Areia",
            "Cacimba de Dentro",
            "Cacimbas",
            "Cacimbinhas",
            "Cacique Doble",
            "Cacoal",
            "Caconde",
            "Caçu",
            "Caculé",
            "Caém",
            "Caetanópolis",
            "Caetanos",
            "Caeté",
            "Caetés",
            "Caetité",
            "Cafarnaum",
            "Cafeara",
            "Cafelândia",
            "Cafelândia",
            "Cafezal do Sul",
            "Caiabu",
            "Caiana",
            "Caiapônia",
            "Caibaté",
            "Caibi",
            "Caiçara",
            "Caiçara",
            "Caiçara do Norte",
            "Caiçara do Rio do Vento",
            "Caicó",
            "Caieiras",
            "Cairu",
            "Caiuá",
            "Cajamar",
            "Cajapió",
            "Cajari",
            "Cajati",
            "Cajazeiras",
            "Cajazeiras do Piauí",
            "Cajazeirinhas",
            "Cajobi",
            "Cajueiro",
            "Cajueiro da Praia",
            "Cajuri",
            "Cajuru",
            "Calçado",
            "Calçoene",
            "Caldas",
            "Caldas Brandão",
            "Caldas Novas",
            "Caldazinha",
            "Caldeirão Grande",
            "Caldeirão Grande do Piauí",
            "Califórnia",
            "Calmon",
            "Calumbi",
            "Camacan",
            "Camaçari",
            "Camacho",
            "Camalaú",
            "Camamu",
            "Camanducaia",
            "Camapuã",
            "Camaquã",
            "Camaragibe",
            "Camargo",
            "Cambará",
            "Cambará do Sul",
            "Cambé",
            "Cambira",
            "Camboriú",
            "Cambuci",
            "Cambuí",
            "Cambuquira",
            "Cametá",
            "Camocim",
            "Camocim de São Félix",
            "Campanário",
            "Campanha",
            "Campestre",
            "Campestre",
            "Campestre da Serra",
            "Campestre de Goiás",
            "Campestre do Maranhão",
            "Campina da Lagoa",
            "Campina das Missões",
            "Campina do Monte Alegre",
            "Campina do Simão",
            "Campina Grande",
            "Campina Grande do Sul",
            "Campina Verde",
            "Campinaçu",
            "Campinápolis",
            "Campinas",
            "Campinas",
            "Campinas do Piauí",
            "Campinas do Sul",
            "Campinorte",
            "Campo Alegre",
            "Campo Alegre",
            "Campo Alegre de Goiás",
            "Campo Alegre de Lourdes",
            "Campo Alegre do Fidalgo",
            "Campo Azul",
            "Campo Belo",
            "Campo Belo do Sul",
            "Campo Bom",
            "Campo Bonito",
            "Campo do Brito",
            "Campo do Meio",
            "Campo do Tenente",
            "Campo Erê",
            "Campo Florido",
            "Campo Formoso",
            "Campo Grande",
            "Campo Grande",
            "Campo Grande do Piauí",
            "Campo Largo",
            "Campo Largo do Piauí",
            "Campo Limpo de Goiás",
            "Campo Limpo Paulista",
            "Campo Magro",
            "Campo Maior",
            "Campo Mourão",
            "Campo Novo",
            "Campo Novo de Rondônia",
            "Campo Novo do Parecis",
            "Campo Redondo",
            "Campo Verde",
            "Campo Verde",
            "Campos Altos",
            "Campos Belos",
            "Campos Borges",
            "Campos de Júlio",
            "Campos do Jordão",
            "Campos dos Goytacazes",
            "Campos Gerais",
            "Campos Lindos",
            "Campos Novos",
            "Campos Novos Paulista",
            "Campos Sales",
            "Campos Verdes",
            "Camutanga",
            "Cana Verde",
            "Canaã",
            "Canaã dos Carajás",
            "CanaBrava do Norte",
            "Cananéia",
            "Canapi",
            "Canápolis",
            "Canápolis",
            "Canarana",
            "Canarana",
            "Canas",
            "Canavieira",
            "Canavieiras",
            "Candeal",
            "Candeias",
            "Candeias",
            "Candeias do Jamari",
            "Candelária",
            "Candiba",
            "Cândido de Abreu",
            "Cândido Godói",
            "Cândido Mendes",
            "Cândido Mota",
            "Cândido Rodrigues",
            "Cândido Sales",
            "Candiota",
            "Candói",
            "Canela",
            "Canelinha",
            "Canguaretama",
            "Canguçu",
            "Canhoba",
            "Canhotinho",
            "Canindé",
            "Canindé de São Francisco",
            "Canitar",
            "Canoas",
            "Canoinhas",
            "Cansanção",
            "Cantá",
            "Cantagalo",
            "Cantagalo",
            "Cantagalo",
            "Cantanhede",
            "Canto do Buriti",
            "Canudos",
            "Canudos do Vale",
            "Canutama",
            "Capanema",
            "Capanema",
            "Capão Alto",
            "Capão Bonito",
            "Capão Bonito do Sul",
            "Capão da Canoa",
            "Capão do Cipó",
            "Capão do Leão",
            "Caparaó",
            "Capela",
            "Capela",
            "Capela de Santana",
            "Capela do Alto",
            "Capela do Alto Alegre",
            "Capela Nova",
            "Capelinha",
            "Capetinga",
            "Capim",
            "Capim Branco",
            "Capim Grosso",
            "Capinópolis",
            "Capinzal",
            "Capinzal do Norte",
            "Capistrano",
            "Capitão",
            "Capitão Andrade",
            "Capitão de Campos",
            "Capitão Enéas",
            "Capitão Gervásio Oliveira",
            "Capitão Leônidas Marques",
            "Capitão Poço",
            "Capitólio",
            "Capivari",
            "Capivari de Baixo",
            "Capivari do Sul",
            "Capixaba",
            "Capoeiras",
            "Caputira",
            "Caraá",
            "Caracaraí",
            "Caracol",
            "Caracol",
            "Caraguatatuba",
            "Caraí",
            "Caraíbas",
            "Carambeí",
            "Caranaíba",
            "Carandaí",
            "Carangola",
            "Carapebus",
            "Carapicuíba",
            "Caratinga",
            "Carauari",
            "Caraúbas",
            "Caraúbas",
            "Caraúbas do Piauí",
            "Caravelas",
            "Carazinho",
            "Carbonita",
            "Cardeal da Silva",
            "Cardoso",
            "Cardoso Moreira",
            "Careaçu",
            "Careiro",
            "Careiro da Várzea",
            "Cariacica",
            "Carianos",
            "Caridade",
            "Caridade do Piauí",
            "Carinhanha",
            "Carira",
            "Cariré",
            "Cariri do Tocantins",
            "Caririaçu",
            "Cariús",
            "Carlinda",
            "Carlópolis",
            "Carlos Barbosa",
            "Carlos Chagas",
            "Carlos Gomes",
            "Carmésia",
            "Carmo",
            "Carmo da Cachoeira",
            "Carmo da Mata",
            "Carmo de Minas",
            "Carmo do Cajuru",
            "Carmo do Paranaíba",
            "Carmo do Rio Claro",
            "Carmo do Rio Verde",
            "Carmolândia",
            "Carmópolis",
            "Carmópolis de Minas",
            "Carnaíba",
            "Carnaúba dos Dantas",
            "Carnaubais",
            "Carnaubal",
            "Carnaubeira da Penha",
            "Carneirinho",
            "Carneiros",
            "Caroebe",
            "Carolina",
            "Carpina",
            "Carrancas",
            "Carrapateira",
            "Carrasco Bonito",
            "Caruaru",
            "Carutapera",
            "Carvalhópolis",
            "Carvalhos",
            "Carvoeira",
            "Casa Branca",
            "Casa Grande",
            "Casa Nova",
            "Casca",
            "Cascalho Rico",
            "Cascavel",
            "Cascavel",
            "Caseara",
            "Caseiros",
            "Casimiro de Abreu",
            "Casinhas",
            "Casserengue",
            "Cássia",
            "Cássia dos Coqueiros",
            "Cassilândia",
            "Castanhal",
            "Castanheira",
            "Castanheiras",
            "Castelândia",
            "Castelo",
            "Castelo do Piauí",
            "Castilho",
            "Castro",
            "Castro Alves",
            "Cataguases",
            "Catalão",
            "Catanduva",
            "Catanduvas",
            "Catanduvas",
            "Catarina",
            "Catas Altas",
            "Catas Altas da Noruega",
            "Catende",
            "Catiguá",
            "Catingueira",
            "Catolândia",
            "Catolé do Rocha",
            "Catu",
            "Catuípe",
            "Catuji",
            "Catunda",
            "Caturaí",
            "Caturama",
            "Caturité",
            "Catuti",
            "Caucaia",
            "Cavalcante",
            "Caxambu",
            "Caxambu do Sul",
            "Caxias",
            "Caxias do Sul",
            "Caxingó",
            "Ceará Mirim",
            "Ceará-Mirim",
            "Cedral",
            "Cedral",
            "Cedro",
            "Cedro",
            "Cedro de São João",
            "Cedro do Abaeté",
            "Celso Ramos",
            "Centenário",
            "Centenário",
            "Centenário do Sul",
            "Central",
            "Central de Minas",
            "Central do Maranhão",
            "Centralina",
            "Centro do Guilherme",
            "Centro Novo do Maranhão",
            "Cerejeiras",
            "Ceres",
            "Cerqueira César",
            "Cerquilho",
            "Cerrito",
            "Cerro Azul",
            "Cerro Branco",
            "Cerro Corá",
            "Cerro Grande",
            "Cerro Grande do Sul",
            "Cerro Largo",
            "Cerro Negro",
            "Cesário Lange",
            "Céu Azul",
            "Cezarina",
            "Chã de Alegria",
            "Chã Grande",
            "Chã Preta",
            "Chácara",
            "Chalé",
            "Chapada",
            "Chapada da Natividade",
            "Chapada de Areia",
            "Chapada do Norte",
            "Chapada dos Guimarães",
            "Chapada Gaúcha",
            "Chapadão do Céu",
            "Chapadão do Lageado",
            "Chapadão do Sul",
            "Chapadinha",
            "Chapecó",
            "Charqueada",
            "Charqueadas",
            "Charrua",
            "Chaval",
            "Chavantes",
            "Chaves",
            "Chiador",
            "Chiapetta",
            "Chopinzinho",
            "Choró",
            "Chorozinho",
            "Chorrochó",
            "Chuí",
            "Chupinguaia",
            "Chuvisca",
            "Cianorte",
            "Cícero Dantas",
            "Cidade Gaúcha",
            "Cidade Ocidental",
            "Cidelândia",
            "Cidreira",
            "Cipó",
            "Cipotânea",
            "Ciríaco",
            "Claraval",
            "Claro dos Poções",
            "Cláudia",
            "Cláudio",
            "Clementina",
            "Clevelândia",
            "Coaraci",
            "Coari",
            "Cocal",
            "Cocal",
            "Cocal de Telha",
            "Cocal do Sul",
            "Cocal dos Alves",
            "Cocalinho",
            "Cocalzinho de Goiás",
            "Cocos",
            "Codajás",
            "Codó",
            "Coelho Neto",
            "Coimbra",
            "Coité do Nóia",
            "Coivaras",
            "Colares",
            "Colatina",
            "Colíder",
            "Colina",
            "Colinas",
            "Colinas",
            "Colinas do Sul",
            "Colinas do Tocantins",
            "Colméia",
            "Colniza",
            "Colômbia",
            "Colombo",
            "Colônia do Gurguéia",
            "Colônia do Piauí",
            "Colônia Leopoldina",
            "Colônia Leopoldina",
            "Colorado",
            "Colorado",
            "Colorado do Oeste",
            "Coluna",
            "Combinado",
            "Comendador Gomes",
            "Comendador Levy Gasparian",
            "Comercinho",
            "Comodoro",
            "Conceição",
            "Conceição da Aparecida",
            "Conceição da Barra",
            "Conceição da Barra de Minas",
            "Conceição da Feira",
            "Conceição das Alagoas",
            "Conceição das Pedras",
            "Conceição de Ipanema",
            "Conceição de Macabu",
            "Conceição do Almeida",
            "Conceição do Araguaia",
            "Conceição do Canindé",
            "Conceição do Castelo",
            "Conceição do Coité",
            "Conceição do Jacuípe",
            "Conceição do Lago-Açu",
            "Conceição do Mato Dentro",
            "Conceição do Pará",
            "Conceição do Rio Verde",
            "Conceição do Tocantins",
            "Conceição dos Ouros",
            "Conchal",
            "Conchas",
            "Concórdia",
            "Concórdia do Pará",
            "Condado",
            "Condado",
            "Conde",
            "Conde",
            "Condeúba",
            "Condor",
            "Cônego Marinho",
            "Confins",
            "Confresa",
            "Congo",
            "Congonhal",
            "Congonhas",
            "Congonhas do Norte",
            "Congonhinhas",
            "Conquista",
            "Conquista D'oeste",
            "Conselheiro Lafaiete",
            "Conselheiro Mairinck",
            "Conselheiro Pena",
            "Consolação",
            "Constantina",
            "Contagem",
            "Contenda",
            "Contendas do Sincorá",
            "Coqueiral",
            "Coqueiro Baixo",
            "Coqueiro Seco",
            "Coqueiros do Sul",
            "Coração de Jesus",
            "Coração de Maria",
            "Corbélia",
            "Cordeiro",
            "Cordeirópolis",
            "Cordeiros",
            "Cordilheira Alta",
            "Cordisburgo",
            "Cordislândia",
            "Coreaú",
            "Coremas",
            "Corguinho",
            "Coribe",
            "Corinto",
            "Cornélio Procópio",
            "Coroaci",
            "Coroados",
            "Coroatá",
            "Coromandel",
            "Coronel Barros",
            "Coronel Bicaco",
            "Coronel Domingos Soares",
            "Coronel Ezequiel",
            "Coronel Fabriciano",
            "Coronel Freitas",
            "Coronel João Pessoa",
            "Coronel João Sá",
            "Coronel José Dias",
            "Coronel Macedo",
            "Coronel Martins",
            "Coronel Murta",
            "Coronel Pacheco",
            "Coronel Pilar",
            "Coronel Sapucaia",
            "Coronel Vivida",
            "Coronel Xavier Chaves",
            "Córrego Danta",
            "Córrego do Bom Jesus",
            "Córrego do Ouro",
            "Córrego Fundo",
            "Corrego Grande",
            "Córrego Novo",
            "Correia Pinto",
            "Corrente",
            "Correntes",
            "Correntina",
            "Cortês",
            "Corumbá",
            "Corumbá de Goiás",
            "Corumbaíba",
            "Corumbataí",
            "Corumbataí do Sul",
            "Corumbiara",
            "Corupá",
            "Coruripe",
            "Cosmópolis",
            "Cosmorama",
            "Costa Marques",
            "Costa Rica",
            "Costeira do Pirajubae",
            "Cotegipe",
            "Cotia",
            "Cotiporã",
            "Cotriguaçu",
            "Couto de Magalhães de Minas",
            "Couto Magalhães",
            "Coxilha",
            "Coxim",
            "Coxixola",
            "Craíbas",
            "Crateús",
            "Crato",
            "Cravinhos",
            "Cravolândia",
            "Criciúma",
            "Crisólita",
            "Crisópolis",
            "Crissiumal",
            "Cristais",
            "Cristais Paulista",
            "Cristal",
            "Cristal do Sul",
            "Cristalândia",
            "Cristalândia do Piauí",
            "Cristália",
            "Cristalina",
            "Cristiano Otoni",
            "Cristianópolis",
            "Cristina",
            "Cristinápolis",
            "Cristino Castro",
            "Cristópolis",
            "Crixás",
            "Crixás do Tocantins",
            "Croatá",
            "Cromínia",
            "Crucilândia",
            "Cruz",
            "Cruz Alta",
            "Cruz das Almas",
            "Cruz do Espírito Santo",
            "Cruz Machado",
            "Cruzália",
            "Cruzaltense",
            "Cruzeiro",
            "Cruzeiro da Fortaleza",
            "Cruzeiro do Iguaçu",
            "Cruzeiro do Oeste",
            "Cruzeiro do Sul",
            "Cruzeiro do Sul",
            "Cruzeiro do Sul",
            "Cruzeta",
            "Cruzília",
            "Cruzmaltina",
            "Cubatão",
            "Cubati",
            "Cuiabá",
            "Cuité",
            "Cuité de Mamanguape",
            "Cuitegi",
            "Cujubim",
            "Cumari",
            "Cumaru",
            "Cumaru do Norte",
            "Cumbe",
            "Cunha",
            "Cunha Porã",
            "Cunhataí",
            "Cuparaque",
            "Cupira",
            "Curaçá",
            "Curimatá",
            "Curionópolis",
            "Curitiba",
            "Curitibanos",
            "Curiúva",
            "Currais",
            "Currais Novos",
            "Curral de Cima",
            "Curral de Dentro",
            "Curral Novo do Piauí",
            "Curral Velho",
            "Curralinho",
            "Curralinhos",
            "Curuá",
            "Curuçá",
            "Cururupu",
            "Curvelândia",
            "Curvelo",
            "Custódia",
            "Cutias",
            "Damianópolis",
            "Damião",
            "Damolândia",
            "Darcinópolis",
            "Dário Meira",
            "Datas",
            "David Canabarro",
            "Davinópolis",
            "Davinópolis",
            "Delfim Moreira",
            "Delfinópolis",
            "Delmiro Gouveia",
            "Delta",
            "Demerval Lobão",
            "Denise",
            "Deodápolis",
            "Deputado Irapuan Pinheiro",
            "Derrubadas",
            "Descalvado",
            "Descanso",
            "Descoberto",
            "Desterro",
            "Desterro de Entre Rios",
            "Desterro do Melo",
            "Dezesseis de Novembro",
            "Diadema",
            "Diamante",
            "Diamante d'Oeste",
            "Diamante do Norte",
            "Diamante do Sul",
            "Diamantina",
            "Diamantino",
            "Dianópolis",
            "Dias d'Ávila",
            "Dilermando de Aguiar",
            "Diogo de Vasconcelos",
            "Dionísio",
            "Dionísio Cerqueira",
            "Diorama",
            "Dirce Reis",
            "Dirceu Arcoverde",
            "Divina Pastora",
            "Divinésia",
            "Divino",
            "Divino das Laranjeiras",
            "Divino de São Lourenço",
            "Divinolândia",
            "Divinolândia de Minas",
            "Divinópolis",
            "Divinópolis de Goiás",
            "Divinópolis do Tocantins",
            "Divisa Alegre",
            "Divisa Nova",
            "Divisópolis",
            "Dobrada",
            "Dois Córregos",
            "Dois Irmãos",
            "Dois Irmãos das Missões",
            "Dois Irmãos do Buriti",
            "Dois Irmãos do Tocantins",
            "Dois Lajeados",
            "Dois Riachos",
            "Dois Vizinhos",
            "Dolcinópolis",
            "Dom Aquino",
            "Dom Basílio",
            "Dom Bosco",
            "Dom Cavati",
            "Dom Eliseu",
            "Dom Expedito Lopes",
            "Dom Feliciano",
            "Dom Inocêncio",
            "Dom Joaquim",
            "Dom Macedo Costa",
            "Dom Pedrito",
            "Dom Pedro",
            "Dom Pedro de Alcântara",
            "Dom Silvério",
            "Dom Viçoso",
            "Domingos Martins",
            "Domingos Mourão",
            "Dona Emma",
            "Dona Eusébia",
            "Dona Francisca",
            "Dona Inês",
            "Dores de Campos",
            "Dores de Guanhães",
            "Dores do Indaiá",
            "Dores do Rio Preto",
            "Dores do Turvo",
            "Doresópolis",
            "Dormentes",
            "Douradina",
            "Douradina",
            "Dourado",
            "Douradoquara",
            "Dourados",
            "Doutor Camargo",
            "Doutor Maurício Cardoso",
            "Doutor Pedrinho",
            "Doutor Ricardo",
            "Doutor Severiano",
            "Doutor Ulysses",
            "Doverlândia",
            "Dracena",
            "Duartina",
            "Duas Barras",
            "Duas Estradas",
            "Dueré",
            "Dumont",
            "Duque Bacelar",
            "Duque de Caxias",
            "Durandé",
            "Echaporã",
            "Ecoporanga",
            "Edealina",
            "Edéia",
            "Eirunepé",
            "Eldorado",
            "Eldorado",
            "Eldorado do Carajás",
            "Eldorado do Sul",
            "Elesbão Veloso",
            "Elias Fausto",
            "Eliseu Martins",
            "Elisiário",
            "Elísio Medrado",
            "Elói Mendes",
            "Emas",
            "Embaúba",
            "Embu",
            "Embu das Artes",
            "Embu Guaçu",
            "Embu-Guaçu",
            "Emilianópolis",
            "Encantado",
            "Encanto",
            "Encruzilhada",
            "Encruzilhada do Sul",
            "Enéas Marques",
            "Engenheiro Beltrão",
            "Engenheiro Caldas",
            "Engenheiro Coelho",
            "Engenheiro Navarro",
            "Engenheiro Paulo de Frontin",
            "Engenho Velho",
            "Entre Folhas",
            "Entre Rios",
            "Entre Rios",
            "Entre Rios de Minas",
            "Entre Rios do Oeste",
            "Entre Rios do Sul",
            "Entre-Ijuís",
            "Envira",
            "Epitaciolândia",
            "Equador",
            "Erebango",
            "Erechim",
            "Ererê",
            "Érico Cardoso",
            "Ermo",
            "Ernestina",
            "Erval Grande",
            "Erval Seco",
            "Erval Velho",
            "Ervália",
            "Escada",
            "Esmeralda",
            "Esmeraldas",
            "Espera Feliz",
            "Esperança",
            "Esperança do Sul",
            "Esperança Nova",
            "Esperantina",
            "Esperantina",
            "Esperantinópolis",
            "Espigão Alto do Iguaçu",
            "Espigão d'Oeste",
            "Espinosa",
            "Espírito Santo",
            "Espírito Santo do Dourado",
            "Espírito Santo do Pinhal",
            "Espírito Santo do Turvo",
            "Esplanada",
            "Espumoso",
            "Estação",
            "Estância",
            "Estância Velha",
            "Esteio",
            "Estiva",
            "Estiva Gerbi",
            "Estreito",
            "Estrela",
            "Estrela d'Oeste",
            "Estrela Dalva",
            "Estrela de Alagoas",
            "Estrela do Indaiá",
            "Estrela do Norte",
            "Estrela do Norte",
            "Estrela do Sul",
            "Estrela Velha",
            "Euclides da Cunha",
            "Euclides da Cunha Paulista",
            "Eugênio de Castro",
            "Eugenópolis",
            "Eunápolis",
            "Eusébio",
            "Ewbank da Câmara",
            "Extrema",
            "Extrema",
            "Extremoz",
            "Exu",
            "Fagundes",
            "Fagundes Varela",
            "Faina",
            "Fama",
            "Faria Lemos",
            "Farias Brito",
            "Faro",
            "Farol",
            "Farroupilha",
            "Fartura",
            "Fartura do Piauí",
            "Fátima",
            "Fátima",
            "Fátima do Sul",
            "Faxinal",
            "Faxinal do Soturno",
            "Faxinal dos Guedes",
            "Faxinalzinho",
            "Fazenda Nova",
            "Fazenda Rio Grande",
            "Fazenda Vilanova",
            "Feijó",
            "Feira da Mata",
            "Feira de Santana",
            "Feira Grande",
            "Feira Nova",
            "Feira Nova",
            "Feira Nova do Maranhão",
            "Felício dos Santos",
            "Felipe Guerra",
            "Felisburgo",
            "Felixlândia",
            "Feliz",
            "Feliz Deserto",
            "Feliz Natal",
            "Fênix",
            "Fernandes Pinheiro",
            "Fernandes Tourinho",
            "Fernando de Noronha",
            "Fernando de Noronha (Distrito Estadual)",
            "Fernando Falcão",
            "Fernando Pedroza",
            "Fernando Prestes",
            "Fernandópolis",
            "Fernão",
            "Ferraz de Vasconcelos",
            "Ferreira Gomes",
            "Ferreiros",
            "Ferros",
            "Fervedouro",
            "Figueira",
            "Figueirão",
            "Figueirópolis",
            "Figueirópolis d'Oeste",
            "Filadélfia",
            "Filadélfia",
            "Firmino Alves",
            "Firminópolis",
            "Flexeiras",
            "Flor da Serra do Sul",
            "Flor do Sertão",
            "Flora Rica",
            "Floraí",
            "Florânia",
            "Floreal",
            "Flores",
            "Flores da Cunha",
            "Flores de Goiás",
            "Flores do Piauí",
            "Floresta",
            "Floresta",
            "Floresta Azul",
            "Floresta do Araguaia",
            "Floresta do Piauí",
            "Florestal",
            "Florestópolis",
            "Floriano",
            "Floriano Peixoto",
            "Florianópolis",
            "Flórida",
            "Flórida Paulista",
            "Florínea",
            "Fonte Boa",
            "Fontoura Xavier",
            "Formiga",
            "Formigueiro",
            "Formosa",
            "Formosa da Serra Negra",
            "Formosa do Oeste",
            "Formosa do Rio Preto",
            "Formosa do Sul",
            "Formoso",
            "Formoso",
            "Formoso do Araguaia",
            "Forquetinha",
            "Forquilha",
            "Forquilhinha",
            "Fortaleza",
            "Fortaleza de Minas",
            "Fortaleza do Tabocão",
            "Fortaleza dos Nogueiras",
            "Fortaleza dos Valos",
            "Fortim",
            "Fortuna",
            "Fortuna de Minas",
            "Foz do Iguaçu",
            "Foz do Jordão",
            "Fraiburgo",
            "Franca",
            "Francinópolis",
            "Francisco Alves",
            "Francisco Ayres",
            "Francisco Badaró",
            "Francisco Beltrão",
            "Francisco Dantas",
            "Francisco Dumont",
            "Francisco Macedo",
            "Francisco Morato",
            "Francisco Sá",
            "Francisco Santos",
            "Franciscópolis",
            "Franco da Rocha",
            "Frecheirinha",
            "Frederico Westphalen",
            "Freguesia do Ribeirao da Ilha",
            "Frei Gaspar",
            "Frei Inocêncio",
            "Frei Lagonegro",
            "Frei Martinho",
            "Frei Miguelinho",
            "Frei Paulo",
            "Frei Rogério",
            "Fronteira",
            "Fronteira dos Vales",
            "Fronteiras",
            "Fruta de Leite",
            "Frutal",
            "Frutuoso Gomes",
            "Fundão",
            "Funilândia",
            "Gabriel Monteiro",
            "Gado Bravo",
            "Gália",
            "Galiléia",
            "Galinhos",
            "Galvão",
            "Gameleira",
            "Gameleira de Goiás",
            "Gameleiras",
            "Gandu",
            "Garanhuns",
            "Gararu",
            "Garça",
            "Garibaldi",
            "Garopaba",
            "Garrafão do Norte",
            "Garruchos",
            "Garuva",
            "Gaspar",
            "Gastão Vidigal",
            "Gaúcha do Norte",
            "Gaurama",
            "Gavião",
            "Gavião Peixoto",
            "Geminiano",
            "General Câmara",
            "General Carneiro",
            "General Carneiro",
            "General Maynard",
            "General Salgado",
            "General Sampaio",
            "Gentil",
            "Gentio do Ouro",
            "Getulina",
            "Getúlio Vargas",
            "Gilbués",
            "Girau do Ponciano",
            "Giruá",
            "Glaucilândia",
            "Glicério",
            "Glória",
            "Glória d'Oeste",
            "Glória de Dourados",
            "Glória do Goitá",
            "Glorinha",
            "Godofredo Viana",
            "Godoy Moreira",
            "Goiabeira",
            "Goiana",
            "Goianá",
            "Goianápolis",
            "Goiandira",
            "Goianésia",
            "Goianésia do Pará",
            "Goiânia",
            "Goianinha",
            "Goianira",
            "Goianorte",
            "Goiás",
            "Goiatins",
            "Goiatuba",
            "Goioerê",
            "Goioxim",
            "Gonçalves",
            "Gonçalves Dias",
            "Gongogi",
            "Gonzaga",
            "Gouveia",
            "Gouvelândia",
            "Governador Archer",
            "Governador Celso Ramos",
            "Governador Dix Sept Rosado",
            "Governador Dix-Sept Rosado",
            "Governador Edison Lobão",
            "Governador Eugênio Barros",
            "Governador Jorge Teixeira",
            "Governador Lindenberg",
            "Governador Luiz Rocha",
            "Governador Mangabeira",
            "Governador Newton Bello",
            "Governador Nunes Freire",
            "Governador Valadares",
            "Graça",
            "Graça Aranha",
            "Gracho Cardoso",
            "Grajaú",
            "Gramado",
            "Gramado dos Loureiros",
            "Gramado Xavier",
            "Grandes Rios",
            "Granito",
            "Granja",
            "Granjeiro",
            "Grão Mogol",
            "Grão Pará",
            "Gravatá",
            "Gravataí",
            "Gravatal",
            "Groaíras",
            "Grossos",
            "Grupiara",
            "Guabiju",
            "Guabiraba",
            "Guabiruba",
            "Guaçuí",
            "Guadalupe",
            "Guaíba",
            "Guaiçara",
            "Guaimbê",
            "Guaíra",
            "Guaíra",
            "Guairaçá",
            "Guaiúba",
            "Guajará",
            "Guajará Mirim",
            "Guajará-Mirim",
            "Guajeru",
            "Guamaré",
            "Guamiranga",
            "Guanambi",
            "Guanhães",
            "Guapé",
            "Guapiaçu",
            "Guapiara",
            "Guapimirim",
            "Guapirama",
            "Guapó",
            "Guaporé",
            "Guaporema",
            "Guará",
            "Guarabira",
            "Guaraçaí",
            "Guaraci",
            "Guaraci",
            "Guaraciaba",
            "Guaraciaba",
            "Guaraciaba do Norte",
            "Guaraciama",
            "Guaraí",
            "Guaraíta",
            "Guaramiranga",
            "Guaramirim",
            "Guaranésia",
            "Guarani",
            "Guarani d'Oeste",
            "Guarani das Missões",
            "Guarani de Goiás",
            "Guaraniaçu",
            "Guarantã",
            "Guarantã do Norte",
            "Guarapari",
            "Guarapuava",
            "Guaraqueçaba",
            "Guarará",
            "Guararapes",
            "Guararema",
            "Guaratinga",
            "Guaratinguetá",
            "Guaratuba",
            "Guarda-Mor",
            "Guareí",
            "Guariba",
            "Guaribas",
            "Guarinos",
            "Guarujá",
            "Guarujá do Sul",
            "Guarulhos",
            "Guatambú",
            "Guatapará",
            "Guaxupé",
            "Guia Lopes da Laguna",
            "Guidoval",
            "Guimarães",
            "Guimarânia",
            "Guiratinga",
            "Guiricema",
            "Gurinhatã",
            "Gurinhém",
            "Gurjão",
            "Gurupá",
            "Gurupi",
            "Guzolândia",
            "Harmonia",
            "Heitoraí",
            "Heliodora",
            "Heliópolis",
            "Herculândia",
            "Herval",
            "Herval d'Oeste",
            "Herveiras",
            "Hidrolândia",
            "Hidrolândia",
            "Hidrolina",
            "Holambra",
            "Honório Serpa",
            "Horizonte",
            "Horizontina",
            "Hortolândia",
            "Hugo Napoleão",
            "Hulha Negra",
            "Humaitá",
            "Humaitá",
            "Humberto de Campos",
            "Iacanga",
            "Iaciara",
            "Iacri",
            "Iaçu",
            "Iapu",
            "Iaras",
            "Iati",
            "Ibaiti",
            "Ibarama",
            "Ibaretama",
            "Ibaté",
            "Ibateguara",
            "Ibatiba",
            "Ibema",
            "Ibertioga",
            "Ibiá",
            "Ibiaçá",
            "Ibiaí",
            "Ibiam",
            "Ibiapina",
            "Ibiara",
            "Ibiassucê",
            "Ibicaraí",
            "Ibicaré",
            "Ibicoara",
            "Ibicuí",
            "Ibicuitinga",
            "Ibimirim",
            "Ibipeba",
            "Ibipitanga",
            "Ibiporã",
            "Ibiquera",
            "Ibirá",
            "Ibiracatu",
            "Ibiraci",
            "Ibiraçu",
            "Ibiraiaras",
            "Ibirajuba",
            "Ibirama",
            "Ibirapitanga",
            "Ibirapuã",
            "Ibirapuitã",
            "Ibirarema",
            "Ibirataia",
            "Ibirité",
            "Ibirubá",
            "Ibitiara",
            "Ibitinga",
            "Ibitirama",
            "Ibititá",
            "Ibitiúra de Minas",
            "Ibituruna",
            "Ibiúna",
            "Ibotirama",
            "Icapuí",
            "Içara",
            "Icaraí de Minas",
            "Icaraíma",
            "Icatu",
            "Icém",
            "Ichu",
            "Icó",
            "Iconha",
            "Ielmo Marinho",
            "Iepê",
            "Igaci",
            "Igaporã",
            "Igaraçu do Tietê",
            "Igaracy",
            "Igarapava",
            "Igarapé",
            "Igarapé Açu",
            "Igarapé do Meio",
            "Igarapé Grande",
            "Igarapé Miri",
            "Igarapé-Açu",
            "Igarapé-Miri",
            "Igarassu",
            "Igaratá",
            "Igaratinga",
            "Igrapiúna",
            "Igreja Nova",
            "Igrejinha",
            "Iguaba Grande",
            "Iguaí",
            "Iguape",
            "Iguaraçu",
            "Iguaracy",
            "Iguatama",
            "Iguatemi",
            "Iguatu",
            "Iguatu",
            "Ijaci",
            "Ijuí",
            "Ilha Comprida",
            "Ilha das Flores",
            "Ilha de Itamaracá",
            "Ilha Grande",
            "Ilha Grande",
            "Ilha Solteira",
            "Ilhabela",
            "Ilhéus",
            "Ilhota",
            "Ilicínea",
            "Ilópolis",
            "Imaculada",
            "Imaruí",
            "Imbaú",
            "Imbé",
            "Imbé de Minas",
            "Imbituba",
            "Imbituva",
            "Imbuia",
            "Imigrante",
            "Imperatriz",
            "Inácio Martins",
            "Inaciolândia",
            "Inajá",
            "Inajá",
            "Inconfidentes",
            "Indaiabira",
            "Indaial",
            "Indaiatuba",
            "Independência",
            "Independência",
            "Indiana",
            "Indianópolis",
            "Indianópolis",
            "Indiaporã",
            "Indiara",
            "Indiaroba",
            "Indiavaí",
            "Ingá",
            "Ingaí",
            "Ingazeira",
            "Inhacorá",
            "Inhambupe",
            "Inhangapi",
            "Inhapi",
            "Inhapim",
            "Inhaúma",
            "Inhuma",
            "Inhumas",
            "Inimutaba",
            "Inocência",
            "Inúbia Paulista",
            "Iomerê",
            "Ipaba",
            "Ipameri",
            "Ipanema",
            "Ipanguaçu",
            "Ipaporanga",
            "Ipatinga",
            "Ipauçu",
            "Ipaumirim",
            "Ipaussu",
            "Ipê",
            "Ipecaetá",
            "Iperó",
            "Ipeúna",
            "Ipiaçu",
            "Ipiaú",
            "Ipiguá",
            "Ipira",
            "Ipirá",
            "Ipiranga",
            "Ipiranga de Goiás",
            "Ipiranga do Norte",
            "Ipiranga do Piauí",
            "Ipiranga do Sul",
            "Ipixuna",
            "Ipixuna do Pará",
            "Ipojuca",
            "Iporá",
            "Iporã",
            "Iporã do Oeste",
            "Iporanga",
            "Ipu",
            "Ipuã",
            "Ipuaçu",
            "Ipubi",
            "Ipueira",
            "Ipueiras",
            "Ipueiras",
            "Ipueiras",
            "Ipuiúna",
            "Ipumirim",
            "Ipupiara",
            "Iracema",
            "Iracema",
            "Iracema do Oeste",
            "Iracemápolis",
            "Iraceminha",
            "Iraí",
            "Iraí de Minas",
            "Irajuba",
            "Iramaia",
            "Iranduba",
            "Irani",
            "Irapuã",
            "Irapuru",
            "Iraquara",
            "Irará",
            "Irati",
            "Irati",
            "Irauçuba",
            "Irecê",
            "Iretama",
            "Irineópolis",
            "Irituia",
            "Irupi",
            "Isaías Coelho",
            "Israelândia",
            "Itá",
            "Itaara",
            "Itabaiana",
            "Itabaiana",
            "Itabaianinha",
            "Itabela",
            "Itaberá",
            "Itaberaba",
            "Itaberaí",
            "Itabi",
            "Itabira",
            "Itabirinha",
            "Itabirito",
            "Itaboraí",
            "Itabuna",
            "Itacajá",
            "Itacambira",
            "Itacarambi",
            "Itacaré",
            "Itacoatiara",
            "Itacorubi",
            "Itacuruba",
            "Itacurubi",
            "Itaeté",
            "Itagi",
            "Itagibá",
            "Itagimirim",
            "Itaguaçu",
            "Itaguaçu da Bahia",
            "Itaguaí",
            "Itaguajé",
            "Itaguara",
            "Itaguari",
            "Itaguaru",
            "Itaguatins",
            "Itaí",
            "Itaíba",
            "Itaiçaba",
            "Itainópolis",
            "Itaiópolis",
            "Itaipava do Grajaú",
            "Itaipé",
            "Itaipulândia",
            "Itaitinga",
            "Itaituba",
            "Itajá",
            "Itajá",
            "Itajaí",
            "Itajobi",
            "Itaju",
            "Itaju do Colônia",
            "Itajubá",
            "Itajuípe",
            "Italva",
            "Itamaracá",
            "Itamaraju",
            "Itamarandiba",
            "Itamarati",
            "Itamarati de Minas",
            "Itamari",
            "Itambacuri",
            "Itambaracá",
            "Itambé",
            "Itambé",
            "Itambé",
            "Itambé do Mato Dentro",
            "Itamogi",
            "Itamonte",
            "Itanagra",
            "Itanhaém",
            "Itanhandu",
            "Itanhangá",
            "Itanhém",
            "Itanhomi",
            "Itaobim",
            "Itaoca",
            "Itaocara",
            "Itapaci",
            "Itapagé",
            "Itapagipe",
            "Itapajé",
            "Itaparica",
            "Itapé",
            "Itapebi",
            "Itapecerica",
            "Itapecerica da Serra",
            "Itapecuru Mirim",
            "Itapejara d'Oeste",
            "Itapema",
            "Itapemirim",
            "Itaperuçu",
            "Itaperuna",
            "Itapetim",
            "Itapetinga",
            "Itapetininga",
            "Itapeva",
            "Itapeva",
            "Itapevi",
            "Itapicuru",
            "Itapipoca",
            "Itapira",
            "Itapiranga",
            "Itapiranga",
            "Itapirapuã",
            "Itapirapuã Paulista",
            "Itapiratins",
            "Itapissuma",
            "Itapitanga",
            "Itapiúna",
            "Itapoá",
            "Itápolis",
            "Itaporã",
            "Itaporã do Tocantins",
            "Itaporanga",
            "Itaporanga",
            "Itaporanga d'Ajuda",
            "Itapororoca",
            "Itapuã do Oeste",
            "Itapuca",
            "Itapuí",
            "Itapura",
            "Itapuranga",
            "Itaquaquecetuba",
            "Itaquara",
            "Itaqui",
            "Itaquiraí",
            "Itaquitinga",
            "Itarana",
            "Itarantim",
            "Itararé",
            "Itarema",
            "Itariri",
            "Itarumã",
            "Itati",
            "Itatiaia",
            "Itatiaiuçu",
            "Itatiba",
            "Itatiba do Sul",
            "Itatim",
            "Itatinga",
            "Itatira",
            "Itatuba",
            "Itaú",
            "Itaú de Minas",
            "Itaúba",
            "Itaubal",
            "Itauçu",
            "Itaueira",
            "Itaúna",
            "Itaúna do Sul",
            "Itaverava",
            "Itinga",
            "Itinga do Maranhão",
            "Itiquira",
            "Itirapina",
            "Itirapuã",
            "Itiruçu",
            "Itiúba",
            "Itobi",
            "Itororó",
            "Itu",
            "Ituaçu",
            "Ituberá",
            "Itueta",
            "Ituiutaba",
            "Itumbiara",
            "Itumirim",
            "Itupeva",
            "Itupiranga",
            "Ituporanga",
            "Iturama",
            "Itutinga",
            "Ituverava",
            "Iuiu",
            "Iúna",
            "Ivaí",
            "Ivaiporã",
            "Ivaté",
            "Ivatuba",
            "Ivinhema",
            "Ivolândia",
            "Ivorá",
            "Ivoti",
            "Jaboatão",
            "Jaboatão dos Guararapes",
            "Jaborá",
            "Jaborandi",
            "Jaborandi",
            "Jaboti",
            "Jaboticaba",
            "Jaboticabal",
            "Jaboticatubas",
            "Jaçanã",
            "Jacaraci",
            "Jacaraú",
            "Jacaré dos Homens",
            "Jacareacanga",
            "Jacareí",
            "Jacarezinho",
            "Jaci",
            "Jaciara",
            "Jacinto",
            "Jacinto Machado",
            "Jacobina",
            "Jacobina do Piauí",
            "Jacuí",
            "Jacuípe",
            "Jacuizinho",
            "Jacundá",
            "Jacupiranga",
            "Jacutinga",
            "Jacutinga",
            "Jaguapitã",
            "Jaguaquara",
            "Jaguaraçu",
            "Jaguarão",
            "Jaguarari",
            "Jaguaré",
            "Jaguaretama",
            "Jaguari",
            "Jaguariaíva",
            "Jaguaribara",
            "Jaguaribe",
            "Jaguaripe",
            "Jaguariúna",
            "Jaguaruana",
            "Jaguaruna",
            "Jaíba",
            "Jaicós",
            "Jales",
            "Jambeiro",
            "Jampruca",
            "Janaúba",
            "Jandaia",
            "Jandaia do Sul",
            "Jandaíra",
            "Jandaíra",
            "Jandira",
            "Janduís",
            "Jangada",
            "Janiópolis",
            "Januária",
            "Japaraíba",
            "Japaratinga",
            "Japaratuba",
            "Japeri",
            "Japi",
            "Japira",
            "Japoatã",
            "Japonvar",
            "Japorã",
            "Japurá",
            "Japurá",
            "Jaqueira",
            "Jaquirana",
            "Jaraguá",
            "Jaraguá do Sul",
            "Jaraguari",
            "Jaramataia",
            "Jardim",
            "Jardim",
            "Jardim Alegre",
            "Jardim de Angicos",
            "Jardim de Piranhas",
            "Jardim do Mulato",
            "Jardim do Seridó",
            "Jardim Olinda",
            "Jardim Paulista",
            "Jardinópolis",
            "Jardinópolis",
            "Jari",
            "Jarinu",
            "Jaru",
            "Jataí",
            "Jataizinho",
            "Jataúba",
            "Jateí",
            "Jati",
            "Jatobá",
            "Jatobá",
            "Jatobá do Piauí",
            "Jaú",
            "Jaú do Tocantins",
            "Jaupaci",
            "Jauru",
            "Jeceaba",
            "Jenipapo de Minas",
            "Jenipapo dos Vieiras",
            "Jequeri",
            "Jequiá da Praia",
            "Jequié",
            "Jequitaí",
            "Jequitibá",
            "Jequitinhonha",
            "Jeremoabo",
            "Jericó",
            "Jeriquara",
            "Jerônimo Monteiro",
            "Jerumenha",
            "Jesuânia",
            "Jesuítas",
            "Jesúpolis",
            "Jetibá",
            "Ji Paraná",
            "Ji-Paraná",
            "Jijoca de Jericoacoara",
            "Jiquiriçá",
            "Jitaúna",
            "Joaçaba",
            "Joaíma",
            "Joanésia",
            "Joanópolis",
            "João Alfredo",
            "João Câmara",
            "João Costa",
            "João Dias",
            "João Dourado",
            "João Lisboa",
            "João Monlevade",
            "João Neiva",
            "João Pessoa",
            "João Pinheiro",
            "João Ramalho",
            "Joaquim Felício",
            "Joaquim Gomes",
            "Joaquim Nabuco",
            "Joaquim Pires",
            "Joaquim Távora",
            "Joca Claudino",
            "Joca Marques",
            "Jóia",
            "Joinville",
            "Jordânia",
            "Jordão",
            "José Boiteux",
            "José Bonifácio",
            "José da Penha",
            "José de Freitas",
            "José Gonçalves de Minas",
            "José Raydan",
            "Joselândia",
            "Josenópolis",
            "Joviânia",
            "Juá dos Vieiras",
            "Juara",
            "Juarez Távora",
            "Juarina",
            "Juatuba",
            "Juazeirinho",
            "Juazeiro",
            "Juazeiro do Norte",
            "Juazeiro do Piauí",
            "Jucás",
            "Jucati",
            "Jucuruçu",
            "Jucurutu",
            "Juína",
            "Juiz de Fora",
            "Júlio Borges",
            "Júlio de Castilhos",
            "Júlio Mesquita",
            "Jumirim",
            "Junco do Maranhão",
            "Junco do Seridó",
            "Jundiá",
            "Jundiá",
            "Jundiaí",
            "Jundiaí do Sul",
            "Junqueiro",
            "Junqueirópolis",
            "Jupi",
            "Jupiá",
            "Juquiá",
            "Juquitiba",
            "Juramento",
            "Juranda",
            "Jurema",
            "Jurema",
            "Juripiranga",
            "Juru",
            "Juruá",
            "Juruaia",
            "Juruena",
            "Juruti",
            "Juscimeira",
            "Jussara",
            "Jussara",
            "Jussara",
            "Jussari",
            "Jussiape",
            "Jutaí",
            "Juti",
            "Juvenília",
            "Kaloré",
            "Lábrea",
            "Lacerdópolis",
            "Ladainha",
            "Ladário",
            "Lafaiete Coutinho",
            "Lagamar",
            "Lagarto",
            "Lages",
            "Lago da Pedra",
            "Lago do Junco",
            "Lago dos Rodrigues",
            "Lago Verde",
            "Lagoa",
            "Lagoa",
            "Lagoa Alegre",
            "Lagoa Bonita do Sul",
            "Lagoa d'Anta",
            "Lagoa da Canoa",
            "Lagoa da Confusão",
            "Lagoa da Prata",
            "Lagoa de Dentro",
            "Lagoa de Itaenga",
            "Lagoa de Pedras",
            "Lagoa de São Francisco",
            "Lagoa de Velhos",
            "Lagoa do Barro do Piauí",
            "Lagoa do Carro",
            "Lagoa do Itaenga",
            "Lagoa do Mato",
            "Lagoa do Ouro",
            "Lagoa do Piauí",
            "Lagoa do Sítio",
            "Lagoa do Tocantins",
            "Lagoa dos Gatos",
            "Lagoa dos Patos",
            "Lagoa dos Três Cantos",
            "Lagoa Dourada",
            "Lagoa Formosa",
            "Lagoa Grande",
            "Lagoa Grande",
            "Lagoa Grande do Maranhão",
            "Lagoa Nova",
            "Lagoa Real",
            "Lagoa Salgada",
            "Lagoa Santa",
            "Lagoa Santa",
            "Lagoa Seca",
            "Lagoa Vermelha",
            "Lagoão",
            "Lagoinha",
            "Lagoinha do Piauí",
            "Laguna",
            "Laguna Carapã",
            "Laje",
            "Laje do Muriaé",
            "Lajeado",
            "Lajeado",
            "Lajeado do Bugre",
            "Lajeado Grande",
            "Lajeado Novo",
            "Lajedão",
            "Lajedinho",
            "Lajedo",
            "Lajedo do Tabocal",
            "Lajes",
            "Lajes Pintadas",
            "Lajinha",
            "Lamarão",
            "Lambari",
            "Lambari d'Oeste",
            "Lamim",
            "Landri Sales",
            "Lapa",
            "Lapão",
            "Laranja da Terra",
            "Laranjal",
            "Laranjal",
            "Laranjal do Jari",
            "Laranjal Paulista",
            "Laranjeiras",
            "Laranjeiras do Sul",
            "Lassance",
            "Lastro",
            "Laurentino",
            "Lauro de Freitas",
            "Lauro Muller",
            "Lavandeira",
            "Lavínia",
            "Lavras",
            "Lavras da Mangabeira",
            "Lavras do Sul",
            "Lavrinhas",
            "Leandro Ferreira",
            "Lebon Régis",
            "Leme",
            "Leme do Prado",
            "Lençóis",
            "Lençóis Paulista",
            "Leoberto Leal",
            "Leopoldina",
            "Leopoldo de Bulhões",
            "Leópolis",
            "Liberato Salzano",
            "Liberdade",
            "Licínio de Almeida",
            "Lidianópolis",
            "Lima Campos",
            "Lima Duarte",
            "Limeira",
            "Limeira do Oeste",
            "Limoeiro",
            "Limoeiro de Anadia",
            "Limoeiro do Ajuru",
            "Limoeiro do Norte",
            "Lindoeste",
            "Lindóia",
            "Lindóia do Sul",
            "Lindolfo Collor",
            "Linha Nova",
            "Linhares",
            "Lins",
            "Livramento",
            "Livramento de Nossa Senhora",
            "Livramento do Brumado",
            "Lizarda",
            "Loanda",
            "Lobato",
            "Logradouro",
            "Londrina",
            "Lontra",
            "Lontras",
            "Lorena",
            "Loreto",
            "Lourdes",
            "Louveira",
            "Lucas",
            "Lucas do Rio Verde",
            "Lucélia",
            "Lucena",
            "Lucianópolis",
            "Luciara",
            "Lucrécia",
            "Luís Antônio",
            "Luís Correia",
            "Luís Domingues",
            "Luís Eduardo Magalhães",
            "Luís Gomes",
            "Luisburgo",
            "Luislândia",
            "Luiz Alves",
            "Luiziana",
            "Luiziânia",
            "Luminárias",
            "Lunardelli",
            "Lupércio",
            "Lupionópolis",
            "Lutécia",
            "Luz",
            "Luzerna",
            "Luziânia",
            "Luzilândia",
            "Luzinópolis",
            "Macaé",
            "Macaíba",
            "Macajuba",
            "Maçambará",
            "Macambira",
            "Macapá",
            "Macaparana",
            "Macarani",
            "Macatuba",
            "Macau",
            "Macaubal",
            "Macaúbas",
            "Macedônia",
            "Maceió",
            "Machacalis",
            "Machadinho",
            "Machadinho d'Oeste",
            "Machado",
            "Machados",
            "Macieira",
            "Macuco",
            "Macururé",
            "Madalena",
            "Madeiro",
            "Madre de Deus",
            "Madre de Deus de Minas",
            "Mãe d'Água",
            "Mãe do Rio",
            "Maetinga",
            "Mafra",
            "Magalhães Barata",
            "Magalhães de Almeida",
            "Magda",
            "Magé",
            "Maiquinique",
            "Mairi",
            "Mairinque",
            "Mairiporã",
            "Mairipotaba",
            "Major Gercino",
            "Major Isidoro",
            "Major Sales",
            "Major Vieira",
            "Malacacheta",
            "Malhada",
            "Malhada de Pedras",
            "Malhada dos Bois",
            "Malhador",
            "Mallet",
            "Malta",
            "Mamanguape",
            "Mambaí",
            "Mamborê",
            "Mamonas",
            "Mampituba",
            "Manacapuru",
            "Manaíra",
            "Manaquiri",
            "Manari",
            "Manaus",
            "Mâncio Lima",
            "Mandaguaçu",
            "Mandaguari",
            "Mandirituba",
            "Manduri",
            "Manfrinópolis",
            "Manga",
            "Mangaratiba",
            "Mangueirinha",
            "Manhuaçu",
            "Manhumirim",
            "Manicoré",
            "Manoel Emídio",
            "Manoel Ribas",
            "Manoel Urbano",
            "Manoel Viana",
            "Manoel Vitorino",
            "Mansidão",
            "Mantena",
            "Mantenópolis",
            "Maquiné",
            "Mar de Espanha",
            "Mar Vermelho",
            "Mara Rosa",
            "Maraã",
            "Marabá",
            "Marabá Paulista",
            "Maracaçumé",
            "Maracaí",
            "Maracajá",
            "Maracaju",
            "Maracanã",
            "Maracanaú",
            "Maracás",
            "Maragogi",
            "Maragogipe",
            "Maraial",
            "Marajá do Sena",
            "Maranguape",
            "Maranhãozinho",
            "Marapanim",
            "Marapoama",
            "Maratá",
            "Marataizes",
            "Marau",
            "Maraú",
            "Maravilha",
            "Maravilha",
            "Maravilhas",
            "Marcação",
            "Marcelândia",
            "Marcelino Ramos",
            "Marcelino Vieira",
            "Marcionílio Souza",
            "Marco",
            "Marcolândia",
            "Marcos Parente",
            "Marechal Cândido Rondon",
            "Marechal Deodoro",
            "Marechal Floriano",
            "Marechal Thaumaturgo",
            "Marema",
            "Mari",
            "Maria da Fé",
            "Maria Helena",
            "Marialva",
            "Mariana",
            "Mariana Pimentel",
            "Mariano Moro",
            "Marianópolis do Tocantins",
            "Mariápolis",
            "Maribondo",
            "Maricá",
            "Marilac",
            "Marilândia",
            "Marilândia do Sul",
            "Marilena",
            "Marília",
            "Mariluz",
            "Maringá",
            "Marinópolis",
            "Mário Campos",
            "Mariópolis",
            "Maripá",
            "Maripá de Minas",
            "Marituba",
            "Marizópolis",
            "Marliéria",
            "Marmeleiro",
            "Marmelópolis",
            "Marques de Souza",
            "Marquinho",
            "Martinho Campos",
            "Martinópole",
            "Martinópolis",
            "Martins",
            "Martins Soares",
            "Maruim",
            "Marumbi",
            "Marzagão",
            "Mascote",
            "Massapê",
            "Massapê do Piauí",
            "Massaranduba",
            "Massaranduba",
            "Mata",
            "Mata de São João",
            "Mata Grande",
            "Mata Roma",
            "Mata Verde",
            "Matão",
            "Mataraca",
            "Mateiros",
            "Matelândia",
            "Materlândia",
            "Mateus Leme",
            "Mathias Lobato",
            "Matias Barbosa",
            "Matias Cardoso",
            "Matias Olímpio",
            "Matina",
            "Matinha",
            "Matinhas",
            "Matinhos",
            "Matipó",
            "Mato Castelhano",
            "Mato Grosso",
            "Mato Leitão",
            "Mato Queimado",
            "Mato Rico",
            "Mato Verde",
            "Matões",
            "Matões do Norte",
            "Matos Costa",
            "Matozinhos",
            "Matrinchã",
            "Matriz de Camaragibe",
            "Matupá",
            "Maturéia",
            "Matutina",
            "Mauá",
            "Mauá da Serra",
            "Maués",
            "Maurilândia",
            "Maurilândia do Tocantins",
            "Mauriti",
            "Maxaranguape",
            "Maximiliano de Almeida",
            "Mazagão",
            "Medeiros",
            "Medeiros Neto",
            "Medianeira",
            "Medicilândia",
            "Medina",
            "Meleiro",
            "Melgaço",
            "Mendes",
            "Mendes Pimentel",
            "Mendonça",
            "Mercedes",
            "Mercês",
            "Meridiano",
            "Meruoca",
            "Mesópolis",
            "Mesquita",
            "Mesquita",
            "Messias",
            "Messias Targino",
            "Miguel Alves",
            "Miguel Calmon",
            "Miguel Leão",
            "Miguel Pereira",
            "Miguelópolis",
            "Milagres",
            "Milagres",
            "Milagres do Maranhão",
            "Milhã",
            "Milton Brandão",
            "Mimoso de Goiás",
            "Mimoso do Sul",
            "Minaçu",
            "Minador do Negrão",
            "Minas do Leão",
            "Minas Novas",
            "Minduri",
            "Mineiros",
            "Mineiros do Tietê",
            "Ministro Andreazza",
            "Mira Estrela",
            "Mirabela",
            "Miracatu",
            "Miracema",
            "Miracema do Tocantins",
            "Mirador",
            "Mirador",
            "Miradouro",
            "Miraguaí",
            "Miraí",
            "Miraíma",
            "Miranda",
            "Miranda do Norte",
            "Mirandiba",
            "Mirandopólis",
            "Mirangaba",
            "Miranorte",
            "Mirante",
            "Mirante da Serra",
            "Mirante do Paranapanema",
            "Miraselva",
            "Mirassol",
            "Mirassol d'Oeste",
            "Mirassolândia",
            "Miravânia",
            "Mirim Doce",
            "Mirinzal",
            "Missal",
            "Missão Velha",
            "Mocajuba",
            "Mococa",
            "Modelo",
            "Moeda",
            "Moema",
            "Mogeiro",
            "Mogi das Cruzes",
            "Mogi Guaçu",
            "Mogi Mirim",
            "Moiporá",
            "Moita Bonita",
            "Moju",
            "Mojuí Dos Campos",
            "Mombaça",
            "Mombuca",
            "Monção",
            "Monções",
            "Mondaí",
            "Mongaguá",
            "Monjolos",
            "Monsenhor Gil",
            "Monsenhor Hipólito",
            "Monsenhor Paulo",
            "Monsenhor Tabosa",
            "Montadas",
            "Montalvânia",
            "Montanha",
            "Montanhas",
            "Montauri",
            "Monte Alegre",
            "Monte Alegre",
            "Monte Alegre de Goiás",
            "Monte Alegre de Minas",
            "Monte Alegre de Sergipe",
            "Monte Alegre do Piauí",
            "Monte Alegre do Sul",
            "Monte Alegre dos Campos",
            "Monte Alto",
            "Monte Aprazível",
            "Monte Azul",
            "Monte Azul Paulista",
            "Monte Belo",
            "Monte Belo do Sul",
            "Monte Carlo",
            "Monte Carmelo",
            "Monte Castelo",
            "Monte Castelo",
            "Monte das Gameleiras",
            "Monte do Carmo",
            "Monte Formoso",
            "Monte Horebe",
            "Monte Mor",
            "Monte Negro",
            "Monte Santo",
            "Monte Santo de Minas",
            "Monte Santo do Tocantins",
            "Monte Sião",
            "Monte Verde",
            "Monteiro",
            "Monteiro Lobato",
            "Monteirópolis",
            "Montenegro",
            "Montes Altos",
            "Montes Claros",
            "Montes Claros de Goiás",
            "Montezuma",
            "Montividiu",
            "Montividiu do Norte",
            "Morada Nova",
            "Morada Nova de Minas",
            "Moraújo",
            "Moreilândia",
            "Moreira Sales",
            "Moreno",
            "Mormaço",
            "Morpará",
            "Morretes",
            "Morrinhos",
            "Morrinhos",
            "Morrinhos do Sul",
            "Morro Agudo",
            "Morro Agudo de Goiás",
            "Morro Cabeça no Tempo",
            "Morro da Cruz",
            "Morro da Fumaça",
            "Morro da Garça",
            "Morro do Chapéu",
            "Morro do Chapéu do Piauí",
            "Morro do Pilar",
            "Morro Grande",
            "Morro Redondo",
            "Morro Reuter",
            "Morros",
            "Mortugaba",
            "Morungaba",
            "Mossâmedes",
            "Mossoró",
            "Mostardas",
            "Motuca",
            "Mozarlândia",
            "Muaná",
            "Mucajaí",
            "Mucambo",
            "Mucugê",
            "Muçum",
            "Mucuri",
            "Mucurici",
            "Muitos Capões",
            "Muliterno",
            "Mulungu",
            "Mulungu",
            "Mulungu do Morro",
            "Mundo Novo",
            "Mundo Novo",
            "Mundo Novo",
            "Munhoz",
            "Munhoz de Melo",
            "Muniz Ferreira",
            "Muniz Freire",
            "Muquém do São Francisco",
            "Muqui",
            "Muriaé",
            "Muribeca",
            "Murici",
            "Murici dos Portelas",
            "Muricilândia",
            "Muritiba",
            "Murutinga do Sul",
            "Mutuípe",
            "Mutum",
            "Mutunópolis",
            "Muzambinho",
            "Nacip Raydan",
            "Nantes",
            "Nanuque",
            "Não Me Toque",
            "Não-Me-Toque",
            "Naque",
            "Narandiba",
            "Natal",
            "Natalândia",
            "Natércia",
            "Natividade",
            "Natividade",
            "Natividade da Serra",
            "Natuba",
            "Navegantes",
            "Naviraí",
            "Nazaré",
            "Nazaré",
            "Nazaré da Mata",
            "Nazaré do Piauí",
            "Nazaré Paulista",
            "Nazareno",
            "Nazarezinho",
            "Nazária",
            "Nazário",
            "Neópolis",
            "Nepomuceno",
            "Nerópolis",
            "Neves Paulista",
            "Nhamundá",
            "Nhandeara",
            "Nicolau Vergueiro",
            "Nilo Peçanha",
            "Nilópolis",
            "Nina Rodrigues",
            "Ninheira",
            "Nioaque",
            "Nipoã",
            "Niquelândia",
            "Nísia Floresta",
            "Niterói",
            "Nobres",
            "Nonoai",
            "Nordestina",
            "Normandia",
            "Nortelândia",
            "Nossa Senhora Aparecida",
            "Nossa Senhora da Glória",
            "Nossa Senhora das Dores",
            "Nossa Senhora das Graças",
            "Nossa Senhora de Lourdes",
            "Nossa Senhora de Nazaré",
            "Nossa Senhora do Livramento",
            "Nossa Senhora do Socorro",
            "Nossa Senhora dos Remédios",
            "Nova Aliança",
            "Nova Aliança do Ivaí",
            "Nova Alvorada",
            "Nova Alvorada do Sul",
            "Nova América",
            "Nova América da Colina",
            "Nova Andradina",
            "Nova Araçá",
            "Nova Aurora",
            "Nova Aurora",
            "Nova Bandeirantes",
            "Nova Bassano",
            "Nova Belém",
            "Nova Boa Vista",
            "Nova Brasilândia",
            "Nova Brasilândia d'Oeste",
            "Nova Bréscia",
            "Nova Campina",
            "Nova Canaã",
            "Nova Canaã do Norte",
            "Nova Canaã Paulista",
            "Nova Candelária",
            "Nova Cantu",
            "Nova Castilho",
            "Nova Colinas",
            "Nova Crixás",
            "Nova Cruz",
            "Nova Era",
            "Nova Erechim",
            "Nova Esperança",
            "Nova Esperança do Piriá",
            "Nova Esperança do Sudoeste",
            "Nova Esperança do Sul",
            "Nova Europa",
            "Nova Fátima",
            "Nova Fátima",
            "Nova Floresta",
            "Nova Friburgo",
            "Nova Glória",
            "Nova Granada",
            "Nova Guarita",
            "Nova Guataporanga",
            "Nova Hartz",
            "Nova Ibiá",
            "Nova Iguaçu",
            "Nova Iguaçu de Goiás",
            "Nova Independência",
            "Nova Iorque",
            "Nova Ipixuna",
            "Nova Itaberaba",
            "Nova Itarana",
            "Nova Lacerda",
            "Nova Laranjeiras",
            "Nova Lima",
            "Nova Londrina",
            "Nova Luzitânia",
            "Nova Mamoré",
            "Nova Marilândia",
            "Nova Maringá",
            "Nova Módica",
            "Nova Monte Verde",
            "Nova Mutum",
            "Nova Nazaré",
            "Nova Odessa",
            "Nova Olímpia",
            "Nova Olímpia",
            "Nova Olinda",
            "Nova Olinda",
            "Nova Olinda",
            "Nova Olinda do Maranhão",
            "Nova Olinda do Norte",
            "Nova Pádua",
            "Nova Palma",
            "Nova Palmeira",
            "Nova Petrópolis",
            "Nova Ponte",
            "Nova Porteirinha",
            "Nova Prata",
            "Nova Prata do Iguaçu",
            "Nova Ramada",
            "Nova Redenção",
            "Nova Resende",
            "Nova Roma",
            "Nova Roma do Sul",
            "Nova Rosalândia",
            "Nova Russas",
            "Nova Santa Bárbara",
            "Nova Santa Helena",
            "Nova Santa Rita",
            "Nova Santa Rita",
            "Nova Santa Rosa",
            "Nova Serrana",
            "Nova Soure",
            "Nova Tebas",
            "Nova Timboteua",
            "Nova Trento",
            "Nova Ubiratã",
            "Nova União",
            "Nova União",
            "Nova Venécia",
            "Nova Veneza",
            "Nova Veneza",
            "Nova Viçosa",
            "Nova Xavantina",
            "Novais",
            "Novo Acordo",
            "Novo Airão",
            "Novo Alegre",
            "Novo Aripuanã",
            "Novo Barreiro",
            "Novo Brasil",
            "Novo Cabrais",
            "Novo Cruzeiro",
            "Novo Gama",
            "Novo Hamburgo",
            "Novo Horizonte",
            "Novo Horizonte",
            "Novo Horizonte",
            "Novo Horizonte do Norte",
            "Novo Horizonte do Oeste",
            "Novo Horizonte do Sul",
            "Novo Itacolomi",
            "Novo Jardim",
            "Novo Lino",
            "Novo Machado",
            "Novo Mundo",
            "Novo Oriente",
            "Novo Oriente de Minas",
            "Novo Oriente do Piauí",
            "Novo Planalto",
            "Novo Progresso",
            "Novo Repartimento",
            "Novo Santo Antônio",
            "Novo Santo Antônio",
            "Novo São Joaquim",
            "Novo Tiradentes",
            "Novo Triunfo",
            "Novo Xingu",
            "Novorizonte",
            "Nuporanga",
            "Óbidos",
            "Ocara",
            "Ocauçu",
            "Oeiras",
            "Oeiras do Pará",
            "Oiapoque",
            "Olaria",
            "Óleo",
            "Olho d'Água",
            "Olho d'Água das Cunhãs",
            "Olho d'Água das Flores",
            "Olho d'Água do Casado",
            "Olho d'Água do Piauí",
            "Olho d'Água Grande",
            "Olho-d'Água do Borges",
            "Olhos-d'Água",
            "Olímpia",
            "Olímpio Noronha",
            "Olinda",
            "Olinda Nova do Maranhão",
            "Olindina",
            "Olivedos",
            "Oliveira",
            "Oliveira de Fátima",
            "Oliveira dos Brejinhos",
            "Oliveira Fortes",
            "Olivença",
            "Onça de Pitangui",
            "Onda Verde",
            "Oratórios",
            "Oriente",
            "Orindiúva",
            "Oriximiná",
            "Orizânia",
            "Orizona",
            "Orlândia",
            "Orleans",
            "Orobó",
            "Orocó",
            "Orós",
            "Ortigueira",
            "Osasco",
            "Oscar Bressane",
            "Osório",
            "Osvaldo Cruz",
            "Otacílio Costa",
            "Ourém",
            "Ouriçangas",
            "Ouricuri",
            "Ourilândia do Norte",
            "Ourinhos",
            "Ourizona",
            "Ouro",
            "Ouro Branco",
            "Ouro Branco",
            "Ouro Branco",
            "Ouro Fino",
            "Ouro Preto",
            "Ouro Preto do Oeste",
            "Ouro Velho",
            "Ouro Verde",
            "Ouro Verde",
            "Ouro Verde de Goiás",
            "Ouro Verde de Minas",
            "Ouro Verde do Oeste",
            "Ouroeste",
            "Ourolândia",
            "Ouvidor",
            "Pacaembu",
            "Pacajá",
            "Pacajus",
            "Pacaraima",
            "Pacatuba",
            "Pacatuba",
            "Paço do Lumiar",
            "Pacoti",
            "Pacujá",
            "Padre Bernardo",
            "Padre Carvalho",
            "Padre Marcos",
            "Padre Paraíso",
            "Paes Landim",
            "Pai Pedro",
            "Paial",
            "Paiçandu",
            "Paim Filho",
            "Paineiras",
            "Painel",
            "Pains",
            "Paiva",
            "Pajeú do Piauí",
            "Palestina",
            "Palestina",
            "Palestina de Goiás",
            "Palestina do Pará",
            "Palhano",
            "Palhoça",
            "Palma",
            "Palma Sola",
            "Palmácia",
            "Palmares",
            "Palmares do Sul",
            "Palmares Paulista",
            "Palmas",
            "Palmas",
            "Palmas de Monte Alto",
            "Palmeira",
            "Palmeira",
            "Palmeira d'Oeste",
            "Palmeira das Missões",
            "Palmeira do Piauí",
            "Palmeira dos Índios",
            "Palmeirais",
            "Palmeirândia",
            "Palmeirante",
            "Palmeiras",
            "Palmeiras de Goiás",
            "Palmeiras do Tocantins",
            "Palmeirina",
            "Palmeirópolis",
            "Palmelo",
            "Palminópolis",
            "Palmital",
            "Palmital",
            "Palmitinho",
            "Palmitos",
            "Palmópolis",
            "Palotina",
            "Panamá",
            "Panambi",
            "Pancas",
            "Panelas",
            "Panorama",
            "Pantanal",
            "Pantano do Sul",
            "Pantano Grande",
            "Pão de Açúcar",
            "Papagaios",
            "Papanduva",
            "Paquetá",
            "Pará de Minas",
            "Paracambi",
            "Paracatu",
            "Paracuru",
            "Paragominas",
            "Paraguaçu",
            "Paraguaçu Paulista",
            "Paraí",
            "Paraíba do Sul",
            "Paraibano",
            "Paraibuna",
            "Paraipaba",
            "Paraíso",
            "Paraíso",
            "Paraíso das Águas",
            "Paraíso do Norte",
            "Paraíso do Sul",
            "Paraíso do Tocantins",
            "Paraisópolis",
            "Parambu",
            "Paramirim",
            "Paramoti",
            "Paraná",
            "Paranã",
            "Paranacity",
            "Paranaguá",
            "Paranaíba",
            "Paranaiguara",
            "Paranaíta",
            "Paranapanema",
            "Paranapoema",
            "Paranapuã",
            "Paranatama",
            "Paranatinga",
            "Paranavaí",
            "Paranhos",
            "Paraopeba",
            "Parapuã",
            "Parari",
            "Paratinga",
            "Paraty",
            "Paraú",
            "Parauapebas",
            "Paraúna",
            "Parazinho",
            "Pardinho",
            "Pareci Novo",
            "Parecis",
            "Parelhas",
            "Pariconha",
            "Parintins",
            "Paripiranga",
            "Paripueira",
            "Pariquera Açu",
            "Pariquera-Açu",
            "Parisi",
            "Parnaguá",
            "Parnaíba",
            "Parnamirim",
            "Parnamirim",
            "Parnarama",
            "Parobé",
            "Passa e Fica",
            "Passa Quatro",
            "Passa Sete",
            "Passa Tempo",
            "Passa-Vinte",
            "Passabém",
            "Passagem",
            "Passagem",
            "Passagem Franca",
            "Passagem Franca do Piauí",
            "Passira",
            "Passo de Camaragibe",
            "Passo de Torres",
            "Passo do Sobrado",
            "Passo Fundo",
            "Passos",
            "Passos Maia",
            "Pastos Bons",
            "Patis",
            "Pato Bragado",
            "Pato Branco",
            "Patos",
            "Patos de Minas",
            "Patos do Piauí",
            "Patrocínio",
            "Patrocínio do Muriaé",
            "Patrocínio Paulista",
            "Patu",
            "Paty do Alferes",
            "Pau Brasil",
            "Pau d'Arco",
            "Pau d'Arco",
            "Pau D'arco do Piauí",
            "Pau dos Ferros",
            "Paudalho",
            "Pauini",
            "Paula Cândido",
            "Paula Freitas",
            "Paulicéia",
            "Paulínia",
            "Paulino Neves",
            "Paulista",
            "Paulista",
            "Paulista Flórida",
            "Paulistana",
            "Paulistânia",
            "Paulistas",
            "Paulo Afonso",
            "Paulo Bento",
            "Paulo de Faria",
            "Paulo Frontin",
            "Paulo Jacinto",
            "Paulo Lopes",
            "Paulo Ramos",
            "Pavão",
            "Paverama",
            "Pavussu",
            "Pé de Serra",
            "Peabiru",
            "Peçanha",
            "Pederneiras",
            "Pedra",
            "Pedra Azul",
            "Pedra Bela",
            "Pedra Bonita",
            "Pedra Branca",
            "Pedra Branca",
            "Pedra Branca do Amapari",
            "Pedra do Anta",
            "Pedra do Indaiá",
            "Pedra Dourada",
            "Pedra Grande",
            "Pedra Lavrada",
            "Pedra Mole",
            "Pedra Preta",
            "Pedra Preta",
            "Pedralva",
            "Pedranópolis",
            "Pedrão",
            "Pedras Altas",
            "Pedras de Fogo",
            "Pedras de Maria da Cruz",
            "Pedras Grandes",
            "Pedregulho",
            "Pedreira",
            "Pedreiras",
            "Pedrinhas",
            "Pedrinhas Paulista",
            "Pedrinópolis",
            "Pedro Afonso",
            "Pedro Alexandre",
            "Pedro Avelino",
            "Pedro Canário",
            "Pedro de Toledo",
            "Pedro do Rosário",
            "Pedro Gomes",
            "Pedro II",
            "Pedro Laurentino",
            "Pedro Leopoldo",
            "Pedro Osório",
            "Pedro Régis",
            "Pedro Teixeira",
            "Pedro Velho",
            "Peixe",
            "Peixe-Boi",
            "Peixoto de Azevedo",
            "Pejuçara",
            "Pelotas",
            "Penaforte",
            "Penalva",
            "Penápolis",
            "Pendências",
            "Penedo",
            "Penha",
            "Pentecoste",
            "Pequeri",
            "Pequi",
            "Pequizeiro",
            "Perdigão",
            "Perdizes",
            "Perdões",
            "Pereira Barreto",
            "Pereiras",
            "Pereiro",
            "Peri Mirim",
            "Periquito",
            "Peritiba",
            "Peritoró",
            "Perobal",
            "Pérola",
            "Pérola d'Oeste",
            "Perolândia",
            "Peruíbe",
            "Pescador",
            "Pesqueira",
            "Petrolândia",
            "Petrolândia",
            "Petrolina",
            "Petrolina de Goiás",
            "Petrópolis",
            "Piaçabuçu",
            "Piacatu",
            "Piancó",
            "Piatã",
            "Piau",
            "Picada Café",
            "Piçarra",
            "Picos",
            "Picuí",
            "Piedade",
            "Piedade de Caratinga",
            "Piedade de Ponte Nova",
            "Piedade do Rio Grande",
            "Piedade dos Gerais",
            "Piên",
            "Pilão Arcado",
            "Pilar",
            "Pilar",
            "Pilar de Goiás",
            "Pilar do Sul",
            "Pilões",
            "Pilões",
            "Pilõezinhos",
            "Pimenta",
            "Pimenta Bueno",
            "Pimenteiras",
            "Pimenteiras do Oeste",
            "Pindaí",
            "Pindamonhangaba",
            "Pindaré Mirim",
            "Pindaré-Mirim",
            "Pindoba",
            "Pindobaçu",
            "Pindorama",
            "Pindorama do Tocantins",
            "Pindoretama",
            "Pingo-d'Água",
            "Pinhais",
            "Pinhal",
            "Pinhal da Serra",
            "Pinhal de São Bento",
            "Pinhal Grande",
            "Pinhalão",
            "Pinhalzinho",
            "Pinhalzinho",
            "Pinhão",
            "Pinhão",
            "Pinheiral",
            "Pinheirinho do Vale",
            "Pinheiro",
            "Pinheiro Machado",
            "Pinheiro Preto",
            "Pinheiros",
            "Pinheiros",
            "Pintadas",
            "Pinto Bandeira",
            "Pintópolis",
            "Pio IX",
            "Pio XII",
            "Piquerobi",
            "Piquet Carneiro",
            "Piquete",
            "Piracaia",
            "Piracanjuba",
            "Piracema",
            "Piracicaba",
            "Piracuruca",
            "Piraí",
            "Piraí do Norte",
            "Piraí do Sul",
            "Piraju",
            "Pirajuba",
            "Pirajuí",
            "Pirambu",
            "Piranga",
            "Pirangi",
            "Piranguçu",
            "Piranguinho",
            "Piranhas",
            "Piranhas",
            "Pirapemas",
            "Pirapetinga",
            "Pirapó",
            "Pirapora",
            "Pirapora do Bom Jesus",
            "Pirapozinho",
            "Piraquara",
            "Piraquê",
            "Pirassununga",
            "Piratini",
            "Piratininga",
            "Piratuba",
            "Piraúba",
            "Pirenópolis",
            "Pires do Rio",
            "Pires Ferreira",
            "Piripá",
            "Piripiri",
            "Piritiba",
            "Pirpirituba",
            "Pitanga",
            "Pitangueiras",
            "Pitangueiras",
            "Pitangui",
            "Pitimbu",
            "Piuí",
            "Pium",
            "Piúma",
            "Piumhi",
            "Placas",
            "Plácido de Castro",
            "Planaltina",
            "Planaltina",
            "Planaltina do Paraná",
            "Planaltino",
            "Planalto",
            "Planalto",
            "Planalto",
            "Planalto",
            "Planalto Alegre",
            "Planalto da Serra",
            "Planura",
            "Platina",
            "Poá",
            "Poção",
            "Poção de Pedras",
            "Pocinhos",
            "Poço Branco",
            "Poço Dantas",
            "Poço das Antas",
            "Poço das Trincheiras",
            "Poço de José de Moura",
            "Poço Fundo",
            "Poço Redondo",
            "Poço Verde",
            "Poções",
            "Poconé",
            "Poços de Caldas",
            "Pocrane",
            "Pojuca",
            "Poloni",
            "Pombal",
            "Pombos",
            "Pomerode",
            "Pompéia",
            "Pompéu",
            "Pongaí",
            "Ponta de Pedras",
            "Ponta Grossa",
            "Ponta Porã",
            "Pontal",
            "Pontal do Araguaia",
            "Pontal do Paraná",
            "Pontalina",
            "Pontalinda",
            "Pontão",
            "Ponte Alta",
            "Ponte Alta do Bom Jesus",
            "Ponte Alta do Norte",
            "Ponte Alta do Tocantins",
            "Ponte Branca",
            "Ponte Nova",
            "Ponte Preta",
            "Ponte Serrada",
            "Pontes e Lacerda",
            "Pontes Gestal",
            "Ponto Belo",
            "Ponto Chique",
            "Ponto dos Volantes",
            "Ponto Novo",
            "Populina",
            "Poranga",
            "Porangaba",
            "Porangatu",
            "Porciúncula",
            "Porecatu",
            "Portalegre",
            "Portão",
            "Porteirão",
            "Porteiras",
            "Porteirinha",
            "Portel",
            "Portelândia",
            "Porto",
            "Porto Acre",
            "Porto Alegre",
            "Porto Alegre do Norte",
            "Porto Alegre do Piauí",
            "Porto Alegre do Tocantins",
            "Porto Amazonas",
            "Pôrto Barra do Ivinheima",
            "Porto Barreiro",
            "Porto Belo",
            "Porto Calvo",
            "Porto da Folha",
            "Porto de Moz",
            "Porto de Pedras",
            "Porto do Mangue",
            "Porto dos Gaúchos",
            "Porto Esperidião",
            "Porto Estrela",
            "Porto Feliz",
            "Porto Ferreira",
            "Porto Firme",
            "Porto Franco",
            "Porto Grande",
            "Porto Lucena",
            "Porto Mauá",
            "Porto Murtinho",
            "Porto Nacional",
            "Porto Real",
            "Porto Real do Colégio",
            "Porto Rico",
            "Porto Rico do Maranhão",
            "Porto Seguro",
            "Porto União",
            "Porto Velho",
            "Porto Vera Cruz",
            "Porto Vitória",
            "Porto Walter",
            "Porto Xavier",
            "Posse",
            "Posto da Mata",
            "Pôsto Fiscal Rolim de Moura",
            "Poté",
            "Potengi",
            "Potim",
            "Potiraguá",
            "Potirendaba",
            "Potiretama",
            "Pouso Alegre",
            "Pouso Alto",
            "Pouso Novo",
            "Pouso Redondo",
            "Poxoréo",
            "Poxoréu",
            "Pracinha",
            "Pracuúba",
            "Prado",
            "Prado Ferreira",
            "Pradópolis",
            "Prados",
            "Praia Grande",
            "Praia Grande",
            "Praia Norte",
            "Prainha",
            "Pranchita",
            "Prata",
            "Prata",
            "Prata do Piauí",
            "Pratânia",
            "Pratápolis",
            "Pratinha",
            "Presidente Alves",
            "Presidente Bernardes",
            "Presidente Bernardes",
            "Presidente Castello Branco",
            "Presidente Castelo Branco",
            "Presidente Dutra",
            "Presidente Dutra",
            "Presidente Epitácio",
            "Presidente Figueiredo",
            "Presidente Getúlio",
            "Presidente Jânio Quadros",
            "Presidente Juscelino",
            "Presidente Juscelino",
            "Presidente Kennedy",
            "Presidente Kennedy",
            "Presidente Kubitschek",
            "Presidente Lucena",
            "Presidente Médici",
            "Presidente Médici",
            "Presidente Nereu",
            "Presidente Olegário",
            "Presidente Prudente",
            "Presidente Sarney",
            "Presidente Tancredo Neves",
            "Presidente Vargas",
            "Presidente Venceslau",
            "Primavera",
            "Primavera",
            "Primavera de Rondônia",
            "Primavera do Leste",
            "Primeira Cruz",
            "Primeiro de Maio",
            "Princesa",
            "Princesa Isabel",
            "Professor Jamil",
            "Progresso",
            "Promissão",
            "Propriá",
            "Protásio Alves",
            "Prudente de Morais",
            "Prudentópolis",
            "Pugmil",
            "Pureza",
            "Putinga",
            "Puxinanã",
            "Quadra",
            "Quaraí",
            "Quartel Geral",
            "Quarto Centenário",
            "Quatá",
            "Quatiguá",
            "Quatipuru",
            "Quatis",
            "Quatro Barras",
            "Quatro Irmãos",
            "Quatro Pontes",
            "Quebrangulo",
            "Quedas do Iguaçu",
            "Queimada Nova",
            "Queimadas",
            "Queimadas",
            "Queimados",
            "Queiroz",
            "Queluz",
            "Queluzito",
            "Querência",
            "Querência do Norte",
            "Quevedos",
            "Quijingue",
            "Quilombo",
            "Quinta do Sol",
            "Quintana",
            "Quinze de Novembro",
            "Quipapá",
            "Quirinópolis",
            "Quissamã",
            "Quitandinha",
            "Quiterianópolis",
            "Quixaba",
            "Quixaba",
            "Quixabeira",
            "Quixadá",
            "Quixelô",
            "Quixeramobim",
            "Quixeré",
            "Rafael Fernandes",
            "Rafael Godeiro",
            "Rafael Jambeiro",
            "Rafard",
            "Ramilândia",
            "Rancharia",
            "Rancho Alegre",
            "Rancho Alegre d'Oeste",
            "Rancho Queimado",
            "Raposa",
            "Raposos",
            "Raul Soares",
            "Realeza",
            "Rebouças",
            "Recife",
            "Recreio",
            "Recursolândia",
            "Redenção",
            "Redenção",
            "Redenção da Serra",
            "Redenção do Gurguéia",
            "Redentora",
            "Reduto",
            "Regeneração",
            "Regente Feijó",
            "Reginópolis",
            "Registro",
            "Relvado",
            "Remanso",
            "Remígio",
            "Renascença",
            "Reriutaba",
            "Resende",
            "Resende Costa",
            "Reserva",
            "Reserva do Cabaçal",
            "Reserva do Iguaçu",
            "Residencia Moacir PU5BHV",
            "Resplendor",
            "Ressaquinha",
            "Restinga",
            "Restinga Sêca",
            "Retirolândia",
            "Riachão",
            "Riachão",
            "Riachão das Neves",
            "Riachão do Bacamarte",
            "Riachão do Dantas",
            "Riachão do Jacuípe",
            "Riachão do Poço",
            "Riachinho",
            "Riachinho",
            "Riacho da Cruz",
            "Riacho das Almas",
            "Riacho de Santana",
            "Riacho de Santana",
            "Riacho de Santo Antônio",
            "Riacho dos Cavalos",
            "Riacho dos Machados",
            "Riacho Frio",
            "Riachuelo",
            "Riachuelo",
            "Rialma",
            "Rianápolis",
            "Ribamar Fiquene",
            "Ribas do Rio Pardo",
            "Ribeira",
            "Ribeira do Amparo",
            "Ribeira do Piauí",
            "Ribeira do Pombal",
            "Ribeirão",
            "Ribeirão Bonito",
            "Ribeirão Branco",
            "Ribeirão Cascalheira",
            "Ribeirão Claro",
            "Ribeirão Corrente",
            "Ribeirão da Ilha",
            "Ribeirão das Neves",
            "Ribeirão do Largo",
            "Ribeirão do Pinhal",
            "Ribeirão do Sul",
            "Ribeirão dos Índios",
            "Ribeirão Grande",
            "Ribeirão Pires",
            "Ribeirão Preto",
            "Ribeirão Vermelho",
            "Ribeirãozinho",
            "Ribeiro Gonçalves",
            "Ribeirópolis",
            "Rifaina",
            "Rincão",
            "Rinópolis",
            "Rio Acima",
            "Rio Azul",
            "Rio Bananal",
            "Rio Bom",
            "Rio Bonito",
            "Rio Bonito do Iguaçu",
            "Rio Branco",
            "Rio Branco",
            "Rio Branco do Ivaí",
            "Rio Branco do Sul",
            "Rio Brilhante",
            "Rio Casca",
            "Rio Claro",
            "Rio Claro",
            "Rio Crespo",
            "Rio da Conceição",
            "Rio das Antas",
            "Rio das Flores",
            "Rio das Ostras",
            "Rio das Pedras",
            "Rio de Contas",
            "Rio de Janeiro",
            "Rio do Antônio",
            "Rio do Campo",
            "Rio do Fogo",
            "Rio do Oeste",
            "Rio do Pires",
            "Rio do Prado",
            "Rio do Sul",
            "Rio Doce",
            "Rio dos Bois",
            "Rio dos Cedros",
            "Rio dos Índios",
            "Rio Espera",
            "Rio Formoso",
            "Rio Fortuna",
            "Rio Grande",
            "Rio Grande da Serra",
            "Rio Grande do Piauí",
            "Rio Largo",
            "Rio Manso",
            "Rio Maria",
            "Rio Negrinho",
            "Rio Negro",
            "Rio Negro",
            "Rio Novo",
            "Rio Novo do Sul",
            "Rio Paranaíba",
            "Rio Pardo",
            "Rio Pardo de Minas",
            "Rio Piracicaba",
            "Rio Pomba",
            "Rio Preto",
            "Rio Preto da Eva",
            "Rio Quente",
            "Rio Real",
            "Rio Rufino",
            "Rio Sono",
            "Rio Tavares",
            "Rio Tinto",
            "Rio Verde",
            "Rio Verde de Mato Grosso",
            "Rio Vermelho",
            "Riolândia",
            "Riozinho",
            "Riqueza",
            "Ritápolis",
            "Riversul",
            "Roca Sales",
            "Rochedo",
            "Rochedo de Minas",
            "Rodeio",
            "Rodeio Bonito",
            "Rodeiro",
            "Rodelas",
            "Rodolfo Fernandes",
            "Rodrigues Alves",
            "Rolador",
            "Rolândia",
            "Rolante",
            "Rolim de Moura",
            "Romaria",
            "Romelândia",
            "Roncador",
            "Ronda Alta",
            "Rondinha",
            "Rondolândia",
            "Rondon",
            "Rondon do Pará",
            "Rondonópolis",
            "Roque Gonzales",
            "Rorainópolis",
            "Rosana",
            "Rosário",
            "Rosário da Limeira",
            "Rosário do Catete",
            "Rosário do Ivaí",
            "Rosário do Sul",
            "Rosário Oeste",
            "Roseira",
            "Roteiro",
            "Rubelita",
            "Rubiácea",
            "Rubiataba",
            "Rubim",
            "Rubinéia",
            "Rurópolis",
            "Russas",
            "Ruy Barbosa",
            "Ruy Barbosa",
            "Sabará",
            "Sabáudia",
            "Sabino",
            "Sabinópolis",
            "Saboeiro",
            "Saco dos Limoes",
            "Sacramento",
            "Sagrada Família",
            "Sagres",
            "Sairé",
            "Saldanha Marinho",
            "Sales",
            "Sales Oliveira",
            "Salesópolis",
            "Salete",
            "Salgadinho",
            "Salgadinho",
            "Salgado",
            "Salgado de São Félix",
            "Salgado Filho",
            "Salgueiro",
            "Salinas",
            "Salinas da Margarida",
            "Salinópolis",
            "Salitre",
            "Salmourão",
            "Saloá",
            "Saltinho",
            "Saltinho",
            "Salto",
            "Salto da Divisa",
            "Salto de Pirapora",
            "Salto do Céu",
            "Salto do Itararé",
            "Salto do Jacuí",
            "Salto do Lontra",
            "Salto Grande",
            "Salto Veloso",
            "Salvador",
            "Salvador das Missões",
            "Salvador do Sul",
            "Salvaterra",
            "Sambaíba",
            "Sampaio",
            "Sananduva",
            "Sanclerlândia",
            "Sandolândia",
            "Sandovalina",
            "Sangão",
            "Sanharó",
            "Sant'Ana do Livramento",
            "Santa Adélia",
            "Santa Albertina",
            "Santa Amélia",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara d'Oeste",
            "Santa Bárbara de Goiás",
            "Santa Bárbara do Leste",
            "Santa Bárbara do Monte Verde",
            "Santa Bárbara do Pará",
            "Santa Bárbara do Sul",
            "Santa Bárbara do Tugúrio",
            "Santa Branca",
            "Santa Brígida",
            "Santa Carmem",
            "Santa Cecília",
            "Santa Cecília",
            "Santa Cecília do Pavão",
            "Santa Cecília do Sul",
            "Santa Clara d'Oeste",
            "Santa Clara do Sul",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz Cabrália",
            "Santa Cruz da Baixa Verde",
            "Santa Cruz da Conceição",
            "Santa Cruz da Esperança",
            "Santa Cruz da Vitória",
            "Santa Cruz das Palmeiras",
            "Santa Cruz de Goiás",
            "Santa Cruz de Minas",
            "Santa Cruz de Monte Castelo",
            "Santa Cruz de Salinas",
            "Santa Cruz do Arari",
            "Santa Cruz do Capibaribe",
            "Santa Cruz do Escalvado",
            "Santa Cruz do Piauí",
            "Santa Cruz do Rio Pardo",
            "Santa Cruz do Sul",
            "Santa Cruz do Xingu",
            "Santa Cruz dos Milagres",
            "Santa Efigênia de Minas",
            "Santa Ernestina",
            "Santa Fé",
            "Santa Fé de Goiás",
            "Santa Fé de Minas",
            "Santa Fé do Araguaia",
            "Santa Fé do Sul",
            "Santa Filomena",
            "Santa Filomena",
            "Santa Filomena do Maranhão",
            "Santa Gertrudes",
            "Santa Helena",
            "Santa Helena",
            "Santa Helena",
            "Santa Helena",
            "Santa Helena de Goiás",
            "Santa Helena de Minas",
            "Santa Inês",
            "Santa Inês",
            "Santa Inês",
            "Santa Inês",
            "Santa Isabel",
            "Santa Isabel",
            "Santa Isabel do Ivaí",
            "Santa Isabel do Rio Negro",
            "Santa Izabel do Oeste",
            "Santa Izabel do Pará",
            "Santa Juliana",
            "Santa Leopoldina",
            "Santa Lúcia",
            "Santa Lúcia",
            "Santa Luz",
            "Santa Luzia",
            "Santa Luzia",
            "Santa Luzia",
            "Santa Luzia",
            "Santa Luzia d'Oeste",
            "Santa Luzia do Itanhy",
            "Santa Luzia do Norte",
            "Santa Luzia do Pará",
            "Santa Luzia do Paruá",
            "Santa Margarida",
            "Santa Margarida do Sul",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria da Boa Vista",
            "Santa Maria da Serra",
            "Santa Maria da Vitória",
            "Santa Maria das Barreiras",
            "Santa Maria de Itabira",
            "Santa Maria de Jetibá",
            "Santa Maria do Cambucá",
            "Santa Maria do Herval",
            "Santa Maria do Oeste",
            "Santa Maria do Pará",
            "Santa Maria do Salto",
            "Santa Maria do Suaçuí",
            "Santa Maria do Tocantins",
            "Santa Maria Madalena",
            "Santa Mariana",
            "Santa Mercedes",
            "Santa Monica",
            "Santa Mônica",
            "Santa Quitéria",
            "Santa Quitéria do Maranhão",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita d'Oeste",
            "Santa Rita de Caldas",
            "Santa Rita de Cássia",
            "Santa Rita de Ibitipoca",
            "Santa Rita de Jacutinga",
            "Santa Rita de Minas",
            "Santa Rita do Araguaia",
            "Santa Rita do Itueto",
            "Santa Rita do Novo Destino",
            "Santa Rita do Pardo",
            "Santa Rita do Passa Quatro",
            "Santa Rita do Sapucaí",
            "Santa Rita do Tocantins",
            "Santa Rita do Trivelato",
            "Santa Rosa",
            "Santa Rosa da Serra",
            "Santa Rosa de Goiás",
            "Santa Rosa de Lima",
            "Santa Rosa de Lima",
            "Santa Rosa de Viterbo",
            "Santa Rosa do Piauí",
            "Santa Rosa do Purus",
            "Santa Rosa do Sul",
            "Santa Rosa do Tocantins",
            "Santa Salete",
            "Santa Teresa",
            "Santa Teresinha",
            "Santa Tereza",
            "Santa Tereza de Goiás",
            "Santa Tereza do Oeste",
            "Santa Tereza do Tocantins",
            "Santa Terezinha",
            "Santa Terezinha",
            "Santa Terezinha",
            "Santa Terezinha",
            "Santa Terezinha de Goiás",
            "Santa Terezinha de Itaipu",
            "Santa Terezinha do Progresso",
            "Santa Terezinha do Tocantins",
            "Santa Vitória",
            "Santa Vitória do Palmar",
            "Santaluz",
            "Santana",
            "Santana",
            "Santana da Boa Vista",
            "Santana da Ponte Pensa",
            "Santana da Vargem",
            "Santana de Cataguases",
            "Santana de Mangueira",
            "Santana de Parnaíba",
            "Santana de Pirapama",
            "Santana do Acaraú",
            "Santana do Araguaia",
            "Santana do Cariri",
            "Santana do Deserto",
            "Santana do Garambéu",
            "Santana do Ipanema",
            "Santana do Itararé",
            "Santana do Jacaré",
            "Santana do Livramento",
            "Santana do Manhuaçu",
            "Santana do Maranhão",
            "Santana do Matos",
            "Santana do Mundaú",
            "Santana do Paraíso",
            "Santana do Piauí",
            "Santana do Riacho",
            "Santana do São Francisco",
            "Santana do Seridó",
            "Santana dos Garrotes",
            "Santana dos Montes",
            "Santanópolis",
            "Santarém",
            "Santarém Novo",
            "Santiago",
            "Santiago do Sul",
            "Santo Afonso",
            "Santo Amaro",
            "Santo Amaro da Imperatriz",
            "Santo Amaro das Brotas",
            "Santo Amaro do Maranhão",
            "Santo Anastácio",
            "Santo André",
            "Santo André",
            "Santo Ângelo",
            "Santo Antônio",
            "Santo Antônio da Alegria",
            "Santo Antônio da Barra",
            "Santo Antônio da Patrulha",
            "Santo Antônio da Platina",
            "Santo Antônio das Missões",
            "Santo Antônio de Goiás",
            "Santo Antônio de Jesus",
            "Santo Antônio de Lisboa",
            "Santo Antônio de Pádua",
            "Santo Antônio de Posse",
            "Santo Antônio do Amparo",
            "Santo Antônio do Aracanguá",
            "Santo Antônio do Aventureiro",
            "Santo Antônio do Caiuá",
            "Santo Antônio do Descoberto",
            "Santo Antônio do Grama",
            "Santo Antônio do Içá",
            "Santo Antônio do Itambé",
            "Santo Antônio do Jacinto",
            "Santo Antônio do Jardim",
            "Santo Antônio do Leste",
            "Santo Antônio do Leverger",
            "Santo Antônio do Monte",
            "Santo Antônio do Palma",
            "Santo Antônio do Paraíso",
            "Santo Antônio do Pinhal",
            "Santo Antônio do Planalto",
            "Santo Antônio do Retiro",
            "Santo Antônio do Rio Abaixo",
            "Santo Antônio do Sudoeste",
            "Santo Antônio do Tauá",
            "Santo Antônio dos Lopes",
            "Santo Antônio dos Milagres",
            "Santo Augusto",
            "Santo Cristo",
            "Santo Estêvão",
            "Santo Expedito",
            "Santo Expedito do Sul",
            "Santo Hipólito",
            "Santo Inácio",
            "Santo Inácio do Piauí",
            "Santópolis do Aguapeí",
            "Santos",
            "Santos Dumont",
            "São Benedito",
            "São Benedito do Rio Preto",
            "São Benedito do Sul",
            "São Bentinho",
            "São Bento",
            "São Bento",
            "São Bento Abade",
            "São Bento do Norte",
            "São Bento do Sapucaí",
            "São Bento do Sul",
            "São Bento do Tocantins",
            "São Bento do Trairí",
            "São Bento do Una",
            "São Bernardino",
            "São Bernardo",
            "São Bernardo do Campo",
            "São Bonifácio",
            "São Borja",
            "São Brás",
            "São Brás do Suaçuí",
            "São Braz do Piauí",
            "São Caetano de Odivelas",
            "São Caetano do Sul",
            "São Caitano",
            "São Carlos",
            "São Carlos",
            "São Carlos do Ivaí",
            "São Cristóvão",
            "São Cristóvão do Sul",
            "São Desidério",
            "São Domingos",
            "São Domingos",
            "São Domingos",
            "São Domingos",
            "São Domingos",
            "São Domingos das Dores",
            "São Domingos do Araguaia",
            "São Domingos do Azeitão",
            "São Domingos do Capim",
            "São Domingos do Cariri",
            "São Domingos do Maranhão",
            "São Domingos do Norte",
            "São Domingos do Prata",
            "São Domingos do Sul",
            "São Felipe",
            "São Felipe d'Oeste",
            "São Félix",
            "São Félix de Balsas",
            "São Félix de Minas",
            "São Félix do Araguaia",
            "São Félix do Coribe",
            "São Félix do Piauí",
            "São Félix do Tocantins",
            "São Félix do Xingu",
            "São Fernando",
            "São Fidélis",
            "São Francisco",
            "São Francisco",
            "São Francisco",
            "São Francisco",
            "São Francisco de Assis",
            "São Francisco de Assis do Piauí",
            "São Francisco de Goiás",
            "São Francisco de Itabapoana",
            "São Francisco de Paula",
            "São Francisco de Paula",
            "São Francisco de Sales",
            "São Francisco do Brejão",
            "São Francisco do Conde",
            "São Francisco do Glória",
            "São Francisco do Guaporé",
            "São Francisco do Maranhão",
            "São Francisco do Oeste",
            "São Francisco do Pará",
            "São Francisco do Piauí",
            "São Francisco do Sul",
            "São Gabriel",
            "São Gabriel",
            "São Gabriel da Cachoeira",
            "São Gabriel da Palha",
            "São Gabriel do Oeste",
            "São Geraldo",
            "São Geraldo da Piedade",
            "São Geraldo do Araguaia",
            "São Geraldo do Baixio",
            "São Gonçalo",
            "São Gonçalo do Abaeté",
            "São Gonçalo do Amarante",
            "São Gonçalo do Amarante",
            "São Gonçalo do Gurguéia",
            "São Gonçalo do Pará",
            "São Gonçalo do Piauí",
            "São Gonçalo do Rio Abaixo",
            "São Gonçalo do Rio Preto",
            "São Gonçalo do Sapucaí",
            "São Gonçalo dos Campos",
            "São Gotardo",
            "São Jerônimo",
            "São Jerônimo da Serra",
            "São João",
            "São João",
            "São João Batista",
            "São João Batista",
            "São João Batista do Glória",
            "São João d'Aliança",
            "São João da Baliza",
            "São João da Barra",
            "São João da Boa Vista",
            "São João da Canabrava",
            "São João da Fronteira",
            "São João da Lagoa",
            "São João da Mata",
            "São João da Paraúna",
            "São João da Ponta",
            "São João da Ponte",
            "São João da Serra",
            "São João da Urtiga",
            "São João da Varjota",
            "São João das Duas Pontes",
            "São João das Missões",
            "São João de Iracema",
            "São João de Meriti",
            "São João de Pirabas",
            "São João del Rei",
            "São João do Araguaia",
            "São João do Arraial",
            "São João do Caiuá",
            "São João do Cariri",
            "São João do Carú",
            "São João do Itaperiú",
            "São João do Ivaí",
            "São João do Jaguaribe",
            "São João do Manhuaçu",
            "São João do Manteninha",
            "São João do Oeste",
            "São João do Oriente",
            "São João do Pacuí",
            "São João do Paraíso",
            "São João do Paraíso",
            "São João do Pau d'Alho",
            "São João do Piauí",
            "São João do Polêsine",
            "São João do Rio do Peixe",
            "São João do Sabugi",
            "São João do Soter",
            "São João do Sul",
            "São João do Tigre",
            "São João do Triunfo",
            "São João dos Inhamuns",
            "São João dos Patos",
            "São João Evangelista",
            "São João Nepomuceno",
            "São Joaquim",
            "São Joaquim da Barra",
            "São Joaquim de Bicas",
            "São Joaquim do Monte",
            "São Jorge",
            "São Jorge d'Oeste",
            "São Jorge do Ivaí",
            "São Jorge do Patrocínio",
            "São José",
            "São José da Barra",
            "São José da Bela Vista",
            "São José da Boa Vista",
            "São José da Coroa Grande",
            "São José da Lagoa Tapada",
            "São José da Laje",
            "São José da Lapa",
            "São José da Safira",
            "São José da Tapera",
            "São José da Varginha",
            "São José da Vitória",
            "São José das Missões",
            "São José das Palmeiras",
            "São José de Caiana",
            "São José de Espinharas",
            "São José de Mipibu",
            "São José de Piranhas",
            "São José de Princesa",
            "São José de Ribamar",
            "São José de Ubá",
            "São José do Alegre",
            "São José do Barreiro",
            "São José do Belmonte",
            "São José do Bonfim",
            "São José do Brejo do Cruz",
            "São José do Calçado",
            "São José do Campestre",
            "São José do Cedro",
            "São José do Cerrito",
            "São José do Divino",
            "São José do Divino",
            "São José do Egito",
            "São José do Goiabal",
            "São José do Herval",
            "São José do Hortêncio",
            "São José do Inhacorá",
            "São José do Jacuípe",
            "São José do Jacuri",
            "São José do Mantimento",
            "São José do Norte",
            "São José do Ouro",
            "São José do Peixe",
            "São José do Piauí",
            "São José do Povo",
            "São José do Rio Claro",
            "São José do Rio Pardo",
            "São José do Rio Preto",
            "São José do Sabugi",
            "São José do Seridó",
            "São José do Sul",
            "São José do Vale do Rio Preto",
            "São José do Xingu",
            "São José dos Ausentes",
            "São José dos Basílios",
            "São José dos Campos",
            "São José dos Cordeiros",
            "São José dos Pinhais",
            "São José dos Quatro Marcos",
            "São José dos Ramos",
            "São Julião",
            "São Leopoldo",
            "São Lourenço",
            "São Lourenço da Mata",
            "São Lourenço da Serra",
            "São Lourenço do Oeste",
            "São Lourenço do Piauí",
            "São Lourenço do Sul",
            "São Ludgero",
            "São Luís",
            "São Luís",
            "São Luís de Montes Belos",
            "São Luís do Curu",
            "São Luis do Piauí",
            "São Luís do Quitunde",
            "São Luís Gonzaga do Maranhão",
            "São Luiz do Norte",
            "São Luiz do Paraitinga",
            "São Luiz Gonzaga",
            "São Mamede",
            "São Manoel do Paraná",
            "São Manuel",
            "São Marcos",
            "São Martinho",
            "São Martinho",
            "São Martinho da Serra",
            "São Mateus",
            "São Mateus do Maranhão",
            "São Mateus do Sul",
            "São Miguel",
            "São Miguel Arcanjo",
            "São Miguel da Baixa Grande",
            "São Miguel da Boa Vista",
            "São Miguel das Matas",
            "São Miguel das Missões",
            "São Miguel de Taipu",
            "São Miguel do Aleixo",
            "São Miguel do Anta",
            "São Miguel do Araguaia",
            "São Miguel do Fidalgo",
            "São Miguel do Gostoso",
            "São Miguel do Guamá",
            "São Miguel do Guaporé",
            "São Miguel do Iguaçu",
            "São Miguel do Oeste",
            "São Miguel do Passa Quatro",
            "São Miguel do Tapuio",
            "São Miguel do Tocantins",
            "São Miguel dos Campos",
            "São Miguel dos Milagres",
            "São Nicolau",
            "São Patrício",
            "São Paulo",
            "São Paulo das Missões",
            "São Paulo de Olivença",
            "São Paulo do Potengi",
            "São Pedro",
            "São Pedro",
            "São Pedro",
            "São Pedro da Água Branca",
            "São Pedro da Aldeia",
            "São Pedro da Cipa",
            "São Pedro da Serra",
            "São Pedro da União",
            "São Pedro das Missões",
            "São Pedro de Alcântara",
            "São Pedro do Butiá",
            "São Pedro do Iguaçu",
            "São Pedro do Ivaí",
            "São Pedro do Paraná",
            "São Pedro do Piauí",
            "São Pedro do Suaçuí",
            "São Pedro do Sul",
            "São Pedro do Turvo",
            "São Pedro dos Crentes",
            "São Pedro dos Ferros",
            "São Rafael",
            "São Raimundo das Mangabeiras",
            "São Raimundo do Doca Bezerra",
            "São Raimundo Nonato",
            "São Roberto",
            "São Romão",
            "São Roque",
            "São Roque de Minas",
            "São Roque do Canaã",
            "São Salvador do Tocantins",
            "São Sebastião",
            "São Sebastião",
            "São Sebastião da Amoreira",
            "São Sebastião da Bela Vista",
            "São Sebastião da Boa Vista",
            "São Sebastião da Grama",
            "São Sebastião da Vargem Alegre",
            "São Sebastião de Lagoa de Roça",
            "São Sebastião do Alto",
            "São Sebastião do Anta",
            "São Sebastião do Caí",
            "São Sebastião do Maranhão",
            "São Sebastião do Oeste",
            "São Sebastião do Paraíso",
            "São Sebastião do Passé",
            "São Sebastião do Rio Preto",
            "São Sebastião do Rio Verde",
            "São Sebastião do Tocantins",
            "São Sebastião do Uatumã",
            "São Sebastião do Umbuzeiro",
            "São Sepé",
            "São Simão",
            "São Simão",
            "São Thomé das Letras",
            "São Tiago",
            "São Tomás de Aquino",
            "São Tomé",
            "São Tomé",
            "São Valentim",
            "São Valentim do Sul",
            "São Valério",
            "São Valério do Sul",
            "São Vendelino",
            "São Vicente",
            "São Vicente",
            "São Vicente de Minas",
            "São Vicente do Seridó",
            "São Vicente do Sul",
            "São Vicente Ferrer",
            "São Vicente Férrer",
            "Sapé",
            "Sapeaçu",
            "Sapezal",
            "Sapiranga",
            "Sapopema",
            "Sapucaí-Mirim",
            "Sapucaia",
            "Sapucaia",
            "Sapucaia",
            "Sapucaia do Sul",
            "Saquarema",
            "Sarandi",
            "Sarandi",
            "Sarapuí",
            "Sardoá",
            "Sarutaiá",
            "Sarzedo",
            "Sátiro Dias",
            "Satuba",
            "Satubinha",
            "Saubara",
            "Saudade do Iguaçu",
            "Saudades",
            "Saúde",
            "Schroeder",
            "Seabra",
            "Seara",
            "Sebastianópolis do Sul",
            "Sebastião Barros",
            "Sebastião Laranjeiras",
            "Sebastião Leal",
            "Seberi",
            "Sede Nova",
            "Segredo",
            "Selbach",
            "Selvíria",
            "Sem-Peixe",
            "Sena Madureira",
            "Senador Alexandre Costa",
            "Senador Amaral",
            "Senador Canedo",
            "Senador Cortes",
            "Senador Elói de Souza",
            "Senador Firmino",
            "Senador Georgino Avelino",
            "Senador Guiomard",
            "Senador José Bento",
            "Senador José Porfírio",
            "Senador La Rocque",
            "Senador Modestino Gonçalves",
            "Senador Pompeu",
            "Senador Rui Palmeira",
            "Senador Sá",
            "Senador Salgado Filho",
            "Sengés",
            "Senhor do Bonfim",
            "Senhora de Oliveira",
            "Senhora do Porto",
            "Senhora dos Remédios",
            "Sentinela do Sul",
            "Sento Sé",
            "Serafina Corrêa",
            "Sericita",
            "Seringueiras",
            "Sério",
            "Seritinga",
            "Seropédica",
            "Serra",
            "Serra Alta",
            "Serra Azul",
            "Serra Azul de Minas",
            "Serra Branca",
            "Serra Caiada",
            "Serra da Raiz",
            "Serra da Saudade",
            "Serra de São Bento",
            "Serra do Mel",
            "Serra do Navio",
            "Serra do Ramalho",
            "Serra do Salitre",
            "Serra dos Aimorés",
            "Serra Dourada",
            "Serra Grande",
            "Serra Negra",
            "Serra Negra do Norte",
            "Serra Nova Dourada",
            "Serra Preta",
            "Serra Redonda",
            "Serra Talhada",
            "Serrana",
            "Serrania",
            "Serrano do Maranhão",
            "Serranópolis",
            "Serranópolis de Minas",
            "Serranópolis do Iguaçu",
            "Serranos",
            "Serraria",
            "Serrinha",
            "Serrinha",
            "Serrinha dos Pintos",
            "Serrita",
            "Serro",
            "Serrolândia",
            "Sertaneja",
            "Sertânia",
            "Sertanópolis",
            "Sertão",
            "Sertão Santana",
            "Sertãozinho",
            "Sertãozinho",
            "Sete Barras",
            "Sete de Setembro",
            "Sete Lagoas",
            "Sete Quedas",
            "Setubinha",
            "Severiano de Almeida",
            "Severiano Melo",
            "Severínia",
            "Siderópolis",
            "Sidrolândia",
            "Sigefredo Pacheco",
            "Silva Jardim",
            "Silvânia",
            "Silvanópolis",
            "Silveira Martins",
            "Silveirânia",
            "Silveiras",
            "Silves",
            "Silvianópolis",
            "Simão Dias",
            "Simão Pereira",
            "Simões",
            "Simões Filho",
            "Simolândia",
            "Simonésia",
            "Simplício Mendes",
            "Sinimbu",
            "Sinop",
            "Siqueira Campos",
            "Sirinhaém",
            "Siriri",
            "Sítio d'Abadia",
            "Sítio do Mato",
            "Sítio do Quinto",
            "Sítio Novo",
            "Sítio Novo",
            "Sítio Novo do Tocantins",
            "Sobradinho",
            "Sobradinho",
            "Sobrado",
            "Sobral",
            "Sobrália",
            "Socorro",
            "Socorro do Piauí",
            "Solânea",
            "Soledade",
            "Soledade",
            "Soledade de Minas",
            "Solidão",
            "Solonópole",
            "Sombrio",
            "Sonora",
            "Sooretama",
            "Sorocaba",
            "Sorriso",
            "Sossêgo",
            "Soure",
            "Sousa",
            "Souto Soares",
            "Sucupira",
            "Sucupira do Norte",
            "Sucupira do Riachão",
            "Sud Mennucci",
            "Sul Brasil",
            "Sulina",
            "Sumaré",
            "Sumé",
            "Sumidouro",
            "Surubim",
            "Sussuapara",
            "Suzanápolis",
            "Suzano",
            "Tabaí",
            "Tabaporã",
            "Tabapuã",
            "Tabatinga",
            "Tabatinga",
            "Tabira",
            "Taboão da Serra",
            "Tabocas do Brejo Velho",
            "Taboleiro Grande",
            "Tabuleiro",
            "Tabuleiro do Norte",
            "Tacaimbó",
            "Tacaratu",
            "Taciba",
            "Tacima",
            "Tacuru",
            "Taguaí",
            "Taguatinga",
            "Taiaçu",
            "Tailândia",
            "Taió",
            "Taiobeiras",
            "Taipas do Tocantins",
            "Taipu",
            "Taiúva",
            "Talismã",
            "Tamandaré",
            "Tamarana",
            "Tambaú",
            "Tamboara",
            "Tamboril",
            "Tamboril do Piauí",
            "Tanabi",
            "Tangará",
            "Tangará",
            "Tangará da Serra",
            "Tanguá",
            "Tanhaçu",
            "Tanque d'Arca",
            "Tanque do Piauí",
            "Tanque Novo",
            "Tanquinho",
            "Taparuba",
            "Tapauá",
            "Tapejara",
            "Tapejara",
            "Tapera",
            "Tapera",
            "Taperoá",
            "Taperoá",
            "Tapes",
            "Tapira",
            "Tapira",
            "Tapiraí",
            "Tapiraí",
            "Tapiramutá",
            "Tapiratiba",
            "Tapurah",
            "Taquara",
            "Taquaraçu de Minas",
            "Taquaral",
            "Taquaral de Goiás",
            "Taquarana",
            "Taquari",
            "Taquaritinga",
            "Taquaritinga do Norte",
            "Taquarituba",
            "Taquarivaí",
            "Taquaruçu do Sul",
            "Taquarussu",
            "Tarabai",
            "Tarauacá",
            "Tarrafas",
            "Tartarugalzinho",
            "Tarumã",
            "Tarumirim",
            "Tasso Fragoso",
            "Tatuí",
            "Tauá",
            "Taubaté",
            "Tavares",
            "Tavares",
            "Tefé",
            "Teixeira",
            "Teixeira de Freitas",
            "Teixeira Soares",
            "Teixeiras",
            "Teixeirópolis",
            "Tejuçuoca",
            "Tejupá",
            "Telêmaco Borba",
            "Telha",
            "Tenente Ananias",
            "Tenente Laurentino Cruz",
            "Tenente Portela",
            "Tenório",
            "Teodoro Sampaio",
            "Teodoro Sampaio",
            "Teofilândia",
            "Teófilo Otoni",
            "Teolândia",
            "Teotônio Vilela",
            "Terenos",
            "Teresina",
            "Teresina de Goiás",
            "Teresópolis",
            "Terezinha",
            "Terezópolis de Goiás",
            "Terra Alta",
            "Terra Boa",
            "Terra de Areia",
            "Terra Nova",
            "Terra Nova",
            "Terra Nova do Norte",
            "Terra Rica",
            "Terra Roxa",
            "Terra Roxa",
            "Terra Santa",
            "Tesouro",
            "Teutônia",
            "Theobroma",
            "Tianguá",
            "Tibagi",
            "Tibau",
            "Tibau do Sul",
            "Tietê",
            "Tigrinhos",
            "Tijucas",
            "Tijucas do Sul",
            "Timbaúba",
            "Timbaúba dos Batistas",
            "Timbé do Sul",
            "Timbiras",
            "Timbó",
            "Timbó Grande",
            "Timburi",
            "Timon",
            "Timóteo",
            "Tio Hugo",
            "Tiradentes",
            "Tiradentes do Sul",
            "Tiros",
            "Tobias Barreto",
            "Tocantínia",
            "Tocantinópolis",
            "Tocantins",
            "Tocos do Moji",
            "Toledo",
            "Toledo",
            "Tomar do Geru",
            "Tomazina",
            "Tombos",
            "Tomé Açu",
            "Tomé-Açu",
            "Tonantins",
            "Toritama",
            "Torixoréu",
            "Toropi",
            "Torre de Pedra",
            "Torres",
            "Torrinha",
            "Touros",
            "Trabiju",
            "Tracuateua",
            "Tracunhaém",
            "Traipu",
            "Trairão",
            "Trairi",
            "Trajano de Moraes",
            "Tramandaí",
            "Travesseiro",
            "Tremedal",
            "Tremembé",
            "Três Arroios",
            "Três Barras",
            "Três Barras do Paraná",
            "Três Cachoeiras",
            "Três Corações",
            "Três Coroas",
            "Três de Maio",
            "Três Forquilhas",
            "Três Fronteiras",
            "Três Lagoas",
            "Três Marias",
            "Três Palmeiras",
            "Três Passos",
            "Três Pontas",
            "Três Ranchos",
            "Três Rios",
            "Treviso",
            "Treze de Maio",
            "Treze Tílias",
            "Trindade",
            "Trindade",
            "Trindade",
            "Trindade do Sul",
            "Triunfo",
            "Triunfo",
            "Triunfo",
            "Triunfo Potiguar",
            "Trizidela do Vale",
            "Trombas",
            "Trombudo Central",
            "Tubarão",
            "Tucano",
            "Tucumã",
            "Tucunduva",
            "Tucuruí",
            "Tufilândia",
            "Tuiuti",
            "Tumiritinga",
            "Tunápolis",
            "Tunas",
            "Tunas do Paraná",
            "Tuneiras do Oeste",
            "Tuntum",
            "Tupã",
            "Tupaciguara",
            "Tupanatinga",
            "Tupanci do Sul",
            "Tupanciretã",
            "Tupandi",
            "Tuparendi",
            "Tuparetama",
            "Tupãssi",
            "Tupi Paulista",
            "Tupirama",
            "Tupiratins",
            "Turiaçu",
            "Turilândia",
            "Turiúba",
            "Turmalina",
            "Turmalina",
            "Turuçu",
            "Tururu",
            "Turvânia",
            "Turvelândia",
            "Turvo",
            "Turvo",
            "Turvolândia",
            "Tutóia",
            "Uarini",
            "Uauá",
            "Ubá",
            "Ubaí",
            "Ubaíra",
            "Ubaitaba",
            "Ubajara",
            "Ubaporanga",
            "Ubarana",
            "Ubatã",
            "Ubatuba",
            "Uberaba",
            "Uberlândia",
            "Ubirajara",
            "Ubiratã",
            "Ubiretama",
            "Uchoa",
            "Uibaí",
            "Uiramutã",
            "Uirapuru",
            "Uiraúna",
            "Ulianópolis",
            "Umari",
            "Umarizal",
            "Umbaúba",
            "Umburanas",
            "Umburatiba",
            "Umbuzeiro",
            "Umirim",
            "Umuarama",
            "Una",
            "Unaí",
            "União",
            "União da Serra",
            "União da Vitória",
            "União de Minas",
            "União do Oeste",
            "União do Sul",
            "União dos Palmares",
            "União Paulista",
            "Uniflor",
            "Unistalda",
            "Upanema",
            "Uraí",
            "Urandi",
            "Urânia",
            "Urbano Santos",
            "Uru",
            "Uruaçu",
            "Uruana",
            "Uruana de Minas",
            "Uruará",
            "Urubici",
            "Uruburetama",
            "Urucânia",
            "Urucará",
            "Uruçuca",
            "Uruçuí",
            "Urucuia",
            "Urucurituba",
            "Uruguaiana",
            "Uruoca",
            "Urupá",
            "Urupema",
            "Urupês",
            "Urussanga",
            "Urutaí",
            "Utinga",
            "Vacaria",
            "Vale de São Domingos",
            "Vale do Anari",
            "Vale do Paraíso",
            "Vale do Sol",
            "Vale Real",
            "Vale Verde",
            "Valença",
            "Valença",
            "Valença do Piauí",
            "Valente",
            "Valentim Gentil",
            "Valinhos",
            "Valparaíso",
            "Valparaíso de Goiás",
            "Vanini",
            "Vargeão",
            "Vargem",
            "Vargem",
            "Vargem Alegre",
            "Vargem Alta",
            "Vargem Bonita",
            "Vargem Bonita",
            "Vargem Grande",
            "Vargem Grande do Rio Pardo",
            "Vargem Grande do Sul",
            "Vargem Grande Paulista",
            "Varginha",
            "Varjão",
            "Varjão de MInas",
            "Varjota",
            "Varre-Sai",
            "Várzea",
            "Várzea",
            "Várzea Alegre",
            "Várzea Branca",
            "Várzea da Palma",
            "Várzea da Roça",
            "Várzea do Poço",
            "Várzea Grande",
            "Várzea Grande",
            "Várzea Nova",
            "Várzea Paulista",
            "Varzedo",
            "Varzelândia",
            "Vassouras",
            "Vazante",
            "Venâncio Aires",
            "Venda Nova do Imigrante",
            "Venha-Ver",
            "Ventania",
            "Venturosa",
            "Vera",
            "Vera Cruz",
            "Vera Cruz",
            "Vera Cruz",
            "Vera Cruz",
            "Vera Cruz do Oeste",
            "Vera Mendes",
            "Veranópolis",
            "Verdejante",
            "Verdelândia",
            "Verê",
            "Vereda",
            "Veredinha",
            "Veríssimo",
            "Vermelho Novo",
            "Vertente do Lério",
            "Vertentes",
            "Vespasiano",
            "Vespasiano Corrêa",
            "Viadutos",
            "Viamão",
            "Viana",
            "Viana",
            "Vianópolis",
            "Vicência",
            "Vicente Dutra",
            "Vicentina",
            "Vicentinópolis",
            "Viçosa",
            "Viçosa",
            "Viçosa",
            "Viçosa do Ceará",
            "Victor Graeff",
            "Vidal Ramos",
            "Videira",
            "Vieiras",
            "Vieirópolis",
            "Vigia",
            "Vila Bela da Santíssima Trindade",
            "Vila Boa",
            "Vila Flor",
            "Vila Flores",
            "Vila Lângaro",
            "Vila Maria",
            "Vila Nova do Piauí",
            "Vila Nova do Sul",
            "Vila Nova dos Martírios",
            "Vila Pavão",
            "Vila Propício",
            "Vila Rica",
            "Vila Valério",
            "Vila Velha",
            "Vilhena",
            "Vinhedo",
            "Viradouro",
            "Virgem da Lapa",
            "Virgínia",
            "Virginópolis",
            "Virgolândia",
            "Virmond",
            "Visconde do Rio Branco",
            "Viseu",
            "Vista Alegre",
            "Vista Alegre do Alto",
            "Vista Alegre do Prata",
            "Vista Gaúcha",
            "Vista Serrana",
            "Vitor Meireles",
            "Vitória",
            "Vitória Brasil",
            "Vitória da Conquista",
            "Vitória das Missões",
            "Vitória de Santo Antão",
            "Vitória do Jari",
            "Vitória do Mearim",
            "Vitória do Xingu",
            "Vitorino",
            "Vitorino Freire",
            "Volta Grande",
            "Volta Redonda",
            "Votorantim",
            "Votuporanga",
            "Wagner",
            "Wall Ferraz",
            "Wanderlândia",
            "Wanderley",
            "Wenceslau Braz",
            "Wenceslau Braz",
            "Wenceslau Guimarães",
            "Westfália",
            "Witmarsum",
            "Xambioá",
            "Xambrê",
            "Xangri-lá",
            "Xanxerê",
            "Xapuri",
            "Xavantina",
            "Xaxim",
            "Xexéu",
            "Xinguara",
            "Xique Xique",
            "Xique-Xique",
            "Zabelê",
            "Zacarias",
            "Zé Doca",
            "Zortéa"
        ]
    },
    {
        "label": "British Indian Ocean Territory",
        "code": "IO",
        "phone": "246",
        "cities": []
    },
    {
        "label": "Brunei",
        "code": "BN",
        "phone": "673",
        "cities": [
            "Bandar Seri Begawan",
            "Bangar",
            "Berakas A",
            "Kapok",
            "Kuala Belait",
            "Mentiri",
            "Serasa",
            "Seria",
            "Tutong"
        ]
    },
    {
        "label": "Bulgaria",
        "code": "BG",
        "phone": "359",
        "cities": [
            "Aheloy",
            "Ahtopol",
            "Aksakovo",
            "Alfatar",
            "Anton",
            "Antonovo",
            "Apriltsi",
            "Ardino",
            "Asen",
            "Asenovgrad",
            "Asparuhovo",
            "Aytos",
            "Balchik",
            "Balgarevo",
            "Banite",
            "Bansko",
            "Bata",
            "Batak",
            "Batanovtsi",
            "Belene",
            "Belitsa",
            "Belogradchik",
            "Beloslav",
            "Belovo",
            "Berkovitsa",
            "Blagoevgrad",
            "Boboshevo",
            "Bobov Dol",
            "Bolyarovo",
            "Borino",
            "Borovan",
            "Borovo",
            "Botevgrad",
            "Boychinovtsi",
            "Boynitsa",
            "Bozhurishte",
            "Bratsigovo",
            "Bregovo",
            "Breznik",
            "Brezovo",
            "Brusartsi",
            "Buhovo",
            "Burgas",
            "Byala",
            "Byala Cherkva",
            "Byala Slatina",
            "Chavdar",
            "Chelopech",
            "Chepelare",
            "Chernomorets",
            "Cherven Bryag",
            "Chiprovtsi",
            "Chirpan",
            "Chuprene",
            "Dalgopol",
            "Debelets",
            "Devin",
            "Devnya",
            "Dimitrovgrad",
            "Dimovo",
            "Dobrich",
            "Dolna Banya",
            "Dolna Mitropolia",
            "Dolni Chiflik",
            "Dolni Dabnik",
            "Dospat",
            "Dragoman",
            "Drenovets",
            "Dryanovo",
            "Dulovo",
            "Dunavtsi",
            "Dupnitsa",
            "Dve Mogili",
            "Dzhebel",
            "Elena",
            "Elhovo",
            "Elin Pelin",
            "Etropole",
            "Gabrovo",
            "Garmen",
            "General Toshevo",
            "Glavinitsa",
            "Godech",
            "Gorna Malina",
            "Gorna Oryahovitsa",
            "Gotse Delchev",
            "Gramada",
            "Gŭlŭbovo",
            "Gulyantsi",
            "Gurkovo",
            "Gyovren",
            "Hadzhidimovo",
            "Harmanli",
            "Haskovo",
            "Hayredin",
            "Hisarya",
            "Ihtiman",
            "Iskar",
            "Isperih",
            "Ivanovo",
            "Ivaylovgrad",
            "Kalofer",
            "Kaloyanovo",
            "Kameno",
            "Kardzhali",
            "Karlovo",
            "Karnobat",
            "Kavarna",
            "Kaynardzha",
            "Kazanlak",
            "Kermen",
            "Kilifarevo",
            "Kirkovo",
            "Kiten",
            "Kiten",
            "Klisura",
            "Knezha",
            "Kocherinovo",
            "Kolarovo",
            "Koprivshtitsa",
            "Kostinbrod",
            "Kotel",
            "Koynare",
            "Kozloduy",
            "Kresna",
            "Krichim",
            "Krivodol",
            "Krumovgrad",
            "Krushari",
            "Kubrat",
            "Kula",
            "Kyustendil",
            "Lakatnik",
            "Laki",
            "Lesichovo",
            "Letnitsa",
            "Levski",
            "Lom",
            "Lovech",
            "Loznitsa",
            "Lukovit",
            "Lyaskovets",
            "Lyubimets",
            "Madan",
            "Madzharovo",
            "Maglizh",
            "Makresh",
            "Malko Tarnovo",
            "Medkovets",
            "Medovene",
            "Mezdra",
            "Mineralni Bani",
            "Mirkovo",
            "Mizia",
            "Montana",
            "Nedelino",
            "Nesebar",
            "Nevestino",
            "Nikolaevo",
            "Nikopol",
            "Nova Zagora",
            "Novo Selo",
            "Obshtina Aksakovo",
            "Obshtina Alfatar",
            "Obshtina Anton",
            "Obshtina Antonovo",
            "Obshtina Ardino",
            "Obshtina Asenovgrad",
            "Obshtina Avren",
            "Obshtina Aytos",
            "Obshtina Balchik",
            "Obshtina Banite",
            "Obshtina Bansko",
            "Obshtina Batak",
            "Obshtina Belene",
            "Obshtina Belitsa",
            "Obshtina Belogradchik",
            "Obshtina Beloslav",
            "Obshtina Belovo",
            "Obshtina Blagoevgrad",
            "Obshtina Boboshevo",
            "Obshtina Bobov Dol",
            "Obshtina Bolyarovo",
            "Obshtina Borino",
            "Obshtina Borovan",
            "Obshtina Borovo",
            "Obshtina Botevgrad",
            "Obshtina Boychinovtsi",
            "Obshtina Boynitsa",
            "Obshtina Bozhurishte",
            "Obshtina Bratsigovo",
            "Obshtina Bratya Daskalovi",
            "Obshtina Burgas",
            "Obshtina Byala",
            "Obshtina Byala",
            "Obshtina Chavdar",
            "Obshtina Chelopech",
            "Obshtina Chepelare",
            "Obshtina Chernoochene",
            "Obshtina Cherven Bryag",
            "Obshtina Chiprovtsi",
            "Obshtina Chirpan",
            "Obshtina Dalgopol",
            "Obshtina Devin",
            "Obshtina Devnya",
            "Obshtina Dimitrovgrad",
            "Obshtina Dimovo",
            "Obshtina Dobrich",
            "Obshtina Dobrich-Selska",
            "Obshtina Dolna Banya",
            "Obshtina Dolna Mitropolia",
            "Obshtina Dolni Chiflik",
            "Obshtina Dolni Dabnik",
            "Obshtina Dospat",
            "Obshtina Dragoman",
            "Obshtina Dryanovo",
            "Obshtina Dulovo",
            "Obshtina Dupnitsa",
            "Obshtina Dve Mogili",
            "Obshtina Dzhebel",
            "Obshtina Elena",
            "Obshtina Elhovo",
            "Obshtina Elin Pelin",
            "Obshtina Etropole",
            "Obshtina Gabrovo",
            "Obshtina Galabovo",
            "Obshtina Garmen",
            "Obshtina General Toshevo",
            "Obshtina Georgi Damyanovo",
            "Obshtina Glavinitsa",
            "Obshtina Gorna Malina",
            "Obshtina Gorna Oryahovitsa",
            "Obshtina Gotse Delchev",
            "Obshtina Gramada",
            "Obshtina Gulyantsi",
            "Obshtina Gurkovo",
            "Obshtina Harmanli",
            "Obshtina Haskovo",
            "Obshtina Hayredin",
            "Obshtina Hisarya",
            "Obshtina Iskar",
            "Obshtina Isperih",
            "Obshtina Ivanovo",
            "Obshtina Ivaylovgrad",
            "Obshtina Kaloyanovo",
            "Obshtina Kameno",
            "Obshtina Kardzhali",
            "Obshtina Karlovo",
            "Obshtina Karnobat",
            "Obshtina Kavarna",
            "Obshtina Kaynardzha",
            "Obshtina Kazanlŭk",
            "Obshtina Kirkovo",
            "Obshtina Knezha",
            "Obshtina Kocherinovo",
            "Obshtina Koprivshtitsa",
            "Obshtina Kostenets",
            "Obshtina Kostinbrod",
            "Obshtina Kotel",
            "Obshtina Kovachevtsi",
            "Obshtina Kozloduy",
            "Obshtina Kresna",
            "Obshtina Krichim",
            "Obshtina Krivodol",
            "Obshtina Krushari",
            "Obshtina Kubrat",
            "Obshtina Kuklen",
            "Obshtina Kula",
            "Obshtina Kyustendil",
            "Obshtina Laki",
            "Obshtina Lesichovo",
            "Obshtina Levski",
            "Obshtina Lom",
            "Obshtina Lovech",
            "Obshtina Loznitsa",
            "Obshtina Lyaskovets",
            "Obshtina Madan",
            "Obshtina Madzharovo",
            "Obshtina Maglizh",
            "Obshtina Malko Tarnovo",
            "Obshtina Maritsa",
            "Obshtina Mezdra",
            "Obshtina Mineralni Bani",
            "Obshtina Mirkovo",
            "Obshtina Mizia",
            "Obshtina Momchilgrad",
            "Obshtina Montana",
            "Obshtina Nedelino",
            "Obshtina Nesebar",
            "Obshtina Nevestino",
            "Obshtina Nikolaevo",
            "Obshtina Nikopol",
            "Obshtina Nova Zagora",
            "Obshtina Omurtag",
            "Obshtina Opaka",
            "Obshtina Opan",
            "Obshtina Oryahovo",
            "Obshtina Panagyurishte",
            "Obshtina Parvomay",
            "Obshtina Pavel Banya",
            "Obshtina Pavlikeni",
            "Obshtina Pazardzhik",
            "Obshtina Pernik",
            "Obshtina Perushtitsa",
            "Obshtina Peshtera",
            "Obshtina Petrich",
            "Obshtina Pirdop",
            "Obshtina Pleven",
            "Obshtina Plovdiv",
            "Obshtina Polski Trambesh",
            "Obshtina Pomorie",
            "Obshtina Popovo",
            "Obshtina Pordim",
            "Obshtina Pravets",
            "Obshtina Primorsko",
            "Obshtina Provadia",
            "Obshtina Radnevo",
            "Obshtina Radomir",
            "Obshtina Rakitovo",
            "Obshtina Rakovski",
            "Obshtina Razgrad",
            "Obshtina Razlog",
            "Obshtina Rila",
            "Obshtina Rodopi",
            "Obshtina Roman",
            "Obshtina Rudozem",
            "Obshtina Ruse",
            "Obshtina Ruzhintsi",
            "Obshtina Sadovo",
            "Obshtina Saedinenie",
            "Obshtina Samokov",
            "Obshtina Samuil",
            "Obshtina Sandanski",
            "Obshtina Sapareva Banya",
            "Obshtina Satovcha",
            "Obshtina Septemvri",
            "Obshtina Sevlievo",
            "Obshtina Shabla",
            "Obshtina Silistra",
            "Obshtina Simitli",
            "Obshtina Sitovo",
            "Obshtina Sliven",
            "Obshtina Slivnitsa",
            "Obshtina Slivo Pole",
            "Obshtina Smolyan",
            "Obshtina Sopot",
            "Obshtina Sozopol",
            "Obshtina Stamboliyski",
            "Obshtina Stambolovo",
            "Obshtina Stara Zagora",
            "Obshtina Straldzha",
            "Obshtina Strazhitsa",
            "Obshtina Strelcha",
            "Obshtina Strumyani",
            "Obshtina Suhindol",
            "Obshtina Sungurlare",
            "Obshtina Suvorovo",
            "Obshtina Svilengrad",
            "Obshtina Svishtov",
            "Obshtina Svoge",
            "Obshtina Targovishte",
            "Obshtina Tervel",
            "Obshtina Teteven",
            "Obshtina Topolovgrad",
            "Obshtina Treklyano",
            "Obshtina Tryavna",
            "Obshtina Tsar Kaloyan",
            "Obshtina Tsenovo",
            "Obshtina Tundzha",
            "Obshtina Tutrakan",
            "Obshtina Tvarditsa",
            "Obshtina Ugarchin",
            "Obshtina Valchedram",
            "Obshtina Valchidol",
            "Obshtina Varna",
            "Obshtina Varshets",
            "Obshtina Veliko Tŭrnovo",
            "Obshtina Velingrad",
            "Obshtina Vetovo",
            "Obshtina Vetrino",
            "Obshtina Vidin",
            "Obshtina Vratsa",
            "Obshtina Yakimovo",
            "Obshtina Yakoruda",
            "Obshtina Yambol",
            "Obshtina Zavet",
            "Obshtina Zemen",
            "Obshtina Zlataritsa",
            "Obshtina Zlatitsa",
            "Obshtina Zlatograd",
            "Obzor",
            "Omurtag",
            "Opaka",
            "Oryahovo",
            "Panagyurishte",
            "Parvomay",
            "Parvomaytsi",
            "Pavel Banya",
            "Pavlikeni",
            "Pazardzhik",
            "Pernik",
            "Perushtitsa",
            "Peshtera",
            "Petrich",
            "Pirdop",
            "Pleven",
            "Plovdiv",
            "Polski Trambesh",
            "Pomorie",
            "Popovo",
            "Pordim",
            "Pravets",
            "Primorsko",
            "Provadia",
            "Radnevo",
            "Radomir",
            "Rakitovo",
            "Rakovski",
            "Ravda",
            "Razgrad",
            "Razlog",
            "Rila",
            "Roman",
            "Rudozem",
            "Ruen",
            "Ruse",
            "Ruzhintsi",
            "Sadovo",
            "Saedinenie",
            "Samokov",
            "Samuil",
            "Sandanski",
            "Sapareva Banya",
            "Sarafovo",
            "Sarnitsa",
            "Sarnitsa Obshtina",
            "Satovcha",
            "Senovo",
            "Septemvri",
            "Sevlievo",
            "Shabla",
            "Shipka",
            "Shumen",
            "Silistra",
            "Simeonovgrad",
            "Simitli",
            "Sitovo",
            "Slavyanovo",
            "Sliven",
            "Slivnitsa",
            "Slivo Pole",
            "Smolyan",
            "Sofia",
            "Sozopol",
            "Sredets",
            "Stamboliyski",
            "Stara Kresna",
            "Stara Zagora",
            "Stolichna Obshtina",
            "Straldzha",
            "Strazhitsa",
            "Strelcha",
            "Strumyani",
            "Suhindol",
            "Sungurlare",
            "Suvorovo",
            "Sveti Vlas",
            "Svilengrad",
            "Svishtov",
            "Svoge",
            "Targovishte",
            "Tervel",
            "Teteven",
            "Topolovgrad",
            "Topolovo",
            "Tran",
            "Troyan",
            "Tryavna",
            "Tsar Kaloyan",
            "Tsarevo",
            "Tsenovo",
            "Tutrakan",
            "Tvarditsa",
            "Ugarchin",
            "Valchedram",
            "Valchidol",
            "Varna",
            "Varshets",
            "Veliko Tŭrnovo",
            "Velingrad",
            "Vetovo",
            "Vetrino",
            "Vidin",
            "Vratsa",
            "Yablanitsa",
            "Yakimovo",
            "Yakoruda",
            "Yambol",
            "Zavet",
            "Zemen",
            "Zlataritsa",
            "Zlatitsa",
            "Zlatni Pyasatsi",
            "Zlatograd"
        ]
    },
    {
        "label": "Burkina Faso",
        "code": "BF",
        "phone": "226",
        "cities": [
            "Banfora",
            "Barani",
            "Batié",
            "Bazega Province",
            "Bobo-Dioulasso",
            "Bogandé",
            "Boromo",
            "Boulsa",
            "Boussé",
            "Dano",
            "Dédougou",
            "Diapaga",
            "Diébougou",
            "Djibo",
            "Dori",
            "Fada N'gourma",
            "Garango",
            "Gayéri",
            "Gnagna Province",
            "Gorom-Gorom",
            "Goulouré",
            "Gourcy",
            "Houndé",
            "Kadiogo Province",
            "Kaya",
            "Kokologo",
            "Kombissiri",
            "Kongoussi",
            "Koudougou",
            "Koupéla",
            "Kouritenga Province",
            "Léo",
            "Manga",
            "Nahouri Province",
            "Nouna",
            "Ouagadougou",
            "Ouahigouya",
            "Ouargaye",
            "Oubritenga",
            "Pama",
            "Pitmoaga",
            "Pô",
            "Province de l’Oudalan",
            "Province de la Bougouriba",
            "Province de la Comoé",
            "Province de la Komandjoari",
            "Province de la Kompienga",
            "Province de la Kossi",
            "Province de la Léraba",
            "Province de la Sissili",
            "Province de la Tapoa",
            "Province des Balé",
            "Province des Banwa",
            "Province du Bam",
            "Province du Boulgou",
            "Province du Boulkiemdé",
            "Province du Ganzourgou",
            "Province du Gourma",
            "Province du Houet",
            "Province du Ioba",
            "Province du Kénédougou",
            "Province du Koulpélogo",
            "Province du Kourwéogo",
            "Province du Loroum",
            "Province du Mouhoun",
            "Province du Namentenga",
            "Province du Nayala",
            "Province du Noumbièl",
            "Province du Passoré",
            "Province du Poni",
            "Province du Sanguié",
            "Province du Sanmatenga",
            "Province du Séno",
            "Province du Soum",
            "Province du Sourou",
            "Province du Tuy",
            "Province du Yagha",
            "Province du Yatenga",
            "Province du Ziro",
            "Province du Zondoma",
            "Réo",
            "Salanso",
            "Sapouy",
            "Sindou",
            "Tenkodogo",
            "Titao",
            "Toma",
            "Tougan",
            "Yako",
            "Ziniaré",
            "Zorgo",
            "Zoundweogo Province"
        ]
    },
    {
        "label": "Burundi",
        "code": "BI",
        "phone": "257",
        "cities": [
            "Bubanza",
            "Bujumbura",
            "Bururi",
            "Cankuzo",
            "Cibitoke",
            "Gitega",
            "Karuzi",
            "Kayanza",
            "Kirundo",
            "Makamba",
            "Muramvya",
            "Muyinga",
            "Mwaro",
            "Ngozi",
            "Rumonge",
            "Rutana",
            "Ruyigi"
        ]
    },
    {
        "label": "Cambodia",
        "code": "KH",
        "phone": "855",
        "cities": [
            "Angkor Chey",
            "Bakan",
            "Banlung",
            "Banteay Meas",
            "Baribour",
            "Battambang",
            "Cheung Prey",
            "Chhouk District",
            "Kampong Bay",
            "Kampong Cham",
            "Kampong Chhnang",
            "Kampong Speu",
            "Kampong Tranch",
            "Kampot",
            "Khan 7 Makara",
            "Khan Châmkar Mon",
            "Khan Dângkaô",
            "Khan Duŏn Pénh",
            "Khan Méan Chey",
            "Khan Russey Keo",
            "Khan Saen Sokh",
            "Khan Sala Krau",
            "Koh Kong",
            "Kracheh",
            "Krakor",
            "Kratié",
            "Krŏng Chbar Mon",
            "Krŏng Doun Kaev",
            "Krong Kep",
            "Krŏng Sênmônoŭrôm",
            "Krŏng Ta Khmau",
            "Lumphat",
            "Mongkol Borei",
            "Pailin",
            "Paoy Paet",
            "Phnom Penh",
            "Phumĭ Véal Srê",
            "Prey Veng",
            "Pursat",
            "Rolea B'ier",
            "Sampov Meas",
            "Samraong",
            "Sangkom Thmei",
            "Sen Monorom",
            "Siem Reap",
            "Sihanoukville",
            "Sisophon",
            "Smach Mean Chey",
            "Snuol",
            "Srae Ambel",
            "Srŏk Âk Phnŭm",
            "Srŏk Ândong Méas",
            "Srŏk Ângkôr Borei",
            "Srŏk Ângkôr Thum",
            "Srŏk Bâ Kêv",
            "Srŏk Ban Lŭng",
            "Srŏk Banăn",
            "Srŏk Bântéay Âmpĭl",
            "Srŏk Basedth",
            "Srŏk Bathéay",
            "Srŏk Batum Sakôr",
            "Srŏk Bâvĭl",
            "Srŏk Borei Cholsar",
            "Srŏk Ch’êh Sên",
            "Srŏk Chhêb",
            "Srŏk Chol Kiri",
            "Srŏk Chŭm Kiri",
            "Srŏk Dâmnăk Châng’aeur",
            "Srŏk Dângtóng",
            "Srŏk Kaev Seima",
            "Srŏk Kâmpóng Léav",
            "Srŏk Kândiĕng",
            "Srŏk Khsăch Kândal",
            "Srŏk Kông Pĭsei",
            "Srŏk Koun Mom",
            "Srŏk Kulén",
            "Srŏk Malai",
            "Srŏk Mésang",
            "Srŏk Môndôl Seima",
            "Srŏk Ŏdŏngk",
            "Srŏk Ou Chum",
            "Srŏk Ou Ya Dav",
            "Srŏk Pech Chreada",
            "Srŏk Prasat Bakong",
            "Srŏk Preăh Sdéch",
            "Srŏk Rotanak Mondol",
            "Srŏk Rôviĕng",
            "Srŏk Sameakki Mean Chey",
            "Srŏk Sâmraông",
            "Srŏk Srêsén",
            "Srok Stueng Hav",
            "Srŏk Svay Chék",
            "Srŏk Svay Chrŭm",
            "Srŏk Ta Vêng",
            "Srŏk Tbêng Méanchey",
            "Srŏk Trâpeăng Prasat",
            "Srok Tuek Chhou",
            "Srŏk Véal Vêng",
            "Stueng Traeng",
            "Stung Treng",
            "Svay Leu",
            "Svay Rieng",
            "Ta Khmau",
            "Takeo",
            "Tbeng Meanchey",
            "Varin"
        ]
    },
    {
        "label": "Cameroon",
        "code": "CM",
        "phone": "237",
        "cities": [
            "Abong Mbang",
            "Akom II",
            "Akono",
            "Akonolinga",
            "Ambam",
            "Babanki",
            "Bafang",
            "Bafia",
            "Bafoussam",
            "Bali",
            "Bamenda",
            "Bamendjou",
            "Bamusso",
            "Bana",
            "Bandjoun",
            "Bangangté",
            "Bankim",
            "Bansoa",
            "Banyo",
            "Batibo",
            "Batouri",
            "Bazou",
            "Bekondo",
            "Bélabo",
            "Bélel",
            "Belo",
            "Bertoua",
            "Bétaré Oya",
            "Bogo",
            "Bonabéri",
            "Boyo",
            "Buea",
            "Diang",
            "Dibombari",
            "Dimako",
            "Dizangué",
            "Djohong",
            "Douala",
            "Doumé",
            "Dschang",
            "Ébolowa",
            "Edéa",
            "Eséka",
            "Essé",
            "Évodoula",
            "Fako Division",
            "Faro Department",
            "Fontem",
            "Foumban",
            "Foumbot",
            "Fundong",
            "Garoua",
            "Garoua Boulaï",
            "Guider",
            "Hauts-Plateaux",
            "Jakiri",
            "Kaélé",
            "Kontcha",
            "Koung-Khi",
            "Kousséri",
            "Koza",
            "Kribi",
            "Kumba",
            "Kumbo",
            "Lagdo",
            "Lebialem",
            "Limbe",
            "Lolodorf",
            "Loum",
            "Makary",
            "Mamfe",
            "Manjo",
            "Maroua",
            "Mayo-Banyo",
            "Mayo-Louti",
            "Mayo-Rey",
            "Mayo-Sava",
            "Mayo-Tsanaga",
            "Mbalmayo",
            "Mbam-Et-Inoubou",
            "Mbandjok",
            "Mbang",
            "Mbanga",
            "Mbankomo",
            "Mbengwi",
            "Mbouda",
            "Mefou-et-Akono",
            "Meïganga",
            "Melong",
            "Mfoundi",
            "Mindif",
            "Minta",
            "Mme-Bafumen",
            "Mokolo",
            "Mora",
            "Mouanko",
            "Mundemba",
            "Mutengene",
            "Muyuka",
            "Mvangué",
            "Mvila",
            "Nanga Eboko",
            "Ndelele",
            "Ndikiniméki",
            "Ndom",
            "Ngambé",
            "Ngaoundéré",
            "Ngomedzap",
            "Ngoro",
            "Ngou",
            "Nguti",
            "Njinikom",
            "Nkongsamba",
            "Nkoteng",
            "Noun",
            "Ntui",
            "Obala",
            "Okoa",
            "Okola",
            "Ombésa",
            "Penja",
            "Pitoa",
            "Poli",
            "Rey Bouba",
            "Saa",
            "Sangmélima",
            "Somié",
            "Tcholliré",
            "Tibati",
            "Tignère",
            "Tiko",
            "Tonga",
            "Vina",
            "Wum",
            "Yabassi",
            "Yagoua",
            "Yaoundé",
            "Yokadouma",
            "Yoko"
        ]
    },
    {
        "label": "Canada",
        "code": "CA",
        "phone": "1",
        "cities": [
            "Abbotsford",
            "Abitibi-Témiscamingue",
            "Acton Vale",
            "Adstock",
            "Agassiz",
            "Airdrie",
            "Ajax",
            "Albanel",
            "Alberton",
            "Aldergrove",
            "Aldergrove East",
            "Algoma",
            "Alliston",
            "Alma",
            "Altona",
            "Amherst",
            "Amherstburg",
            "Amigo Beach",
            "Amos",
            "Amqui",
            "Ancaster",
            "Ange-Gardien",
            "Angus",
            "Anmore",
            "Annapolis County",
            "Antigonish",
            "Arbutus Ridge",
            "Armstrong",
            "Arnprior",
            "Asbestos",
            "Ashcroft",
            "Assiniboia",
            "Athabasca",
            "Atikokan",
            "Attawapiskat",
            "Aurora",
            "Aylmer",
            "Azilda",
            "Baie Ste. Anne",
            "Baie-Comeau",
            "Baie-D'Urfé",
            "Baie-Saint-Paul",
            "Ballantrae",
            "Bancroft",
            "Banff",
            "Barraute",
            "Barrhead",
            "Barrie",
            "Barrière",
            "Bas-Saint-Laurent",
            "Bassano",
            "Bath",
            "Bathurst",
            "Bay Roberts",
            "Bay St. George South",
            "Beaconsfield",
            "Beauceville",
            "Beauharnois",
            "Beaumont",
            "Beaupré",
            "Beausejour",
            "Beaverlodge",
            "Bécancour",
            "Bedford",
            "Behchokǫ̀",
            "Belfast",
            "Belleville",
            "Bells Corners",
            "Belmont",
            "Beloeil",
            "Berthierville",
            "Berwick",
            "Biggar",
            "Binbrook",
            "Black Diamond",
            "Blackfalds",
            "Blainville",
            "Bluewater",
            "Bois-des-Filion",
            "Boisbriand",
            "Boissevain",
            "Bon Accord",
            "Bonaventure",
            "Bonavista",
            "Bonnyville",
            "Botwood",
            "Boucherville",
            "Bouctouche",
            "Bourget",
            "Bow Island",
            "Bowen Island",
            "Bracebridge",
            "Brampton",
            "Brandon",
            "Brant",
            "Brantford",
            "Breakeyville",
            "Bridgewater",
            "Brockville",
            "Bromont",
            "Brooks",
            "Brossard",
            "Brownsburg-Chatham",
            "Brussels",
            "Buckingham",
            "Burford",
            "Burgeo",
            "Burlington",
            "Burnaby",
            "Burns Lake",
            "Cabano",
            "Cache Creek",
            "Cacouna",
            "Calgary",
            "Calmar",
            "Cambridge",
            "Camlachie",
            "Campbell River",
            "Campbellton",
            "Camrose",
            "Candiac",
            "Canmore",
            "Canora",
            "Cantley",
            "Cap-Chat",
            "Cap-Santé",
            "Cape Breton County",
            "Capitale-Nationale",
            "Capreol",
            "Carberry",
            "Carbonear",
            "Cardston",
            "Carignan",
            "Carleton",
            "Carleton Place",
            "Carleton-sur-Mer",
            "Carlyle",
            "Carman",
            "Carstairs",
            "Casselman",
            "Castlegar",
            "Catalina",
            "Cedar",
            "Central Coast Regional District",
            "Centre-du-Québec",
            "Chambly",
            "Chambord",
            "Chandler",
            "Channel-Port aux Basques",
            "Chapais",
            "Charlemagne",
            "Charlottetown",
            "Chase",
            "Château-Richer",
            "Châteauguay",
            "Chatham",
            "Chatham-Kent",
            "Chaudière-Appalaches",
            "Chemainus",
            "Chertsey",
            "Chester",
            "Chestermere",
            "Chetwynd",
            "Chibougamau",
            "Chilliwack",
            "Chute-aux-Outardes",
            "Clarence-Rockland",
            "Clarenville-Shoal Harbour",
            "Claresholm",
            "Clyde River",
            "Coaldale",
            "Coalhurst",
            "Coaticook",
            "Cobourg",
            "Cochrane",
            "Cochrane District",
            "Colchester",
            "Cold Lake",
            "Cole Harbour",
            "Collingwood",
            "Colwood",
            "Conception Bay South",
            "Concord",
            "Constance Bay",
            "Contrecoeur",
            "Cookshire",
            "Cookshire-Eaton",
            "Cookstown",
            "Coombs",
            "Coquitlam",
            "Corner Brook",
            "Cornwall",
            "Cornwall",
            "Corunna",
            "Côte-Nord",
            "Côte-Saint-Luc",
            "Coteau-du-Lac",
            "Courtenay",
            "Cow Bay",
            "Cowansville",
            "Cowichan Bay",
            "Crabtree",
            "Cranbrook",
            "Creston",
            "Cross Lake 19A",
            "Crossfield",
            "Cumberland",
            "Dalmeny",
            "Danville",
            "Dartmouth",
            "Dauphin",
            "Daveluyville",
            "Dawson City",
            "Dawson Creek",
            "De Salaberry",
            "Deep River",
            "Deer Lake",
            "Delaware",
            "Deloraine",
            "Delson",
            "Delta",
            "Denman Island",
            "Denman Island Trust Area",
            "Deseronto",
            "Deux-Montagnes",
            "Devon",
            "Didsbury",
            "Dieppe",
            "Digby",
            "Digby County",
            "Disraeli",
            "Dolbeau-Mistassini",
            "Dollard-Des Ormeaux",
            "Donnacona",
            "Dorchester",
            "Dorval",
            "Dowling",
            "Drayton Valley",
            "Drummondville",
            "Dryden",
            "Duck Lake",
            "Duncan",
            "Dunham",
            "Durham",
            "Ear Falls",
            "East Angus",
            "East Broughton",
            "East Gwillimbury",
            "East Wellington",
            "East York",
            "Edmonton",
            "Edmundston",
            "Edson",
            "Elk Point",
            "Elkford",
            "Elliot Lake",
            "Ellison",
            "Elmvale",
            "Enderby",
            "Englehart",
            "English Corner",
            "Eskasoni 3",
            "Espanola",
            "Essex",
            "Esterhazy",
            "Estevan",
            "Etobicoke",
            "Exeter",
            "Fairview",
            "Fairwinds",
            "Falher",
            "Fall River",
            "Fallingbrook",
            "Farnham",
            "Ferme-Neuve",
            "Fermont",
            "Fernie",
            "Flin Flon",
            "Florenceville-Bristol",
            "Foam Lake",
            "Fogo Island",
            "Forestville",
            "Fort Erie",
            "Fort Frances",
            "Fort Macleod",
            "Fort McMurray",
            "Fort McPherson",
            "Fort Nelson",
            "Fort Saskatchewan",
            "Fort Smith",
            "Fort St. John",
            "Fort-Coulonge",
            "Fossambault-sur-le-Lac",
            "Fox Creek",
            "Franklin",
            "Fraser Valley Regional District",
            "Fredericton",
            "French Creek",
            "Fruitvale",
            "Fundy Bay",
            "Gambo",
            "Gananoque",
            "Gaspé",
            "Gaspésie-Îles-de-la-Madeleine",
            "Gatineau",
            "Gibbons",
            "Gibsons",
            "Gimli",
            "Gjoa Haven",
            "Glace Bay",
            "Glencoe",
            "Godefroy",
            "Goderich",
            "Golden",
            "Golden",
            "Goulds",
            "Granby",
            "Grand Bank",
            "Grand Centre",
            "Grand Falls-Windsor",
            "Grand Forks",
            "Grande Cache",
            "Grande Prairie",
            "Grande-Digue",
            "Gravelbourg",
            "Gravenhurst",
            "Greater Lakeburn",
            "Greater Napanee",
            "Greater Sudbury",
            "Greenstone",
            "Greenwood",
            "Grimshaw",
            "Grunthal",
            "Guelph",
            "Haines Junction",
            "Haldimand County",
            "Haliburton Village",
            "Halifax",
            "Halton",
            "Hamilton",
            "Hampstead",
            "Hampton",
            "Hanceville",
            "Hanna",
            "Hanover",
            "Hantsport",
            "Happy Valley-Goose Bay",
            "Harbour Breton",
            "Harrison Brook",
            "Harriston",
            "Hauterive",
            "Havre-Saint-Pierre",
            "Hawkesbury",
            "Hay River",
            "Hayes Subdivision",
            "Headingley",
            "Hearst",
            "Heritage Pointe",
            "Hérouxville",
            "High Level",
            "High Prairie",
            "High River",
            "Hinton",
            "Hope",
            "Hornby Island",
            "Hornepayne",
            "Houston",
            "Hudson",
            "Hudson Bay",
            "Humboldt",
            "Huntingdon",
            "Huntsville",
            "Huron East",
            "Ile des Chênes",
            "Indian Head",
            "Ingersoll",
            "Innisfil",
            "Inuvik",
            "Invermere",
            "Iqaluit",
            "Iroquois Falls",
            "Irricana",
            "Jarvis",
            "Jasper Park Lodge",
            "Joliette",
            "Jonquière",
            "Kamloops",
            "Kamsack",
            "Kanata",
            "Kapuskasing",
            "Kawartha Lakes",
            "Kelowna",
            "Kenora",
            "Kensington",
            "Kentville",
            "Kerrobert",
            "Keswick",
            "Keswick Ridge",
            "Killam",
            "Killarney",
            "Kimberley",
            "Kincardine",
            "Kindersley",
            "King",
            "Kingsey Falls",
            "Kingston",
            "Kirkland",
            "Kirkland Lake",
            "Kitchener",
            "Kitimat",
            "Kugluktuk",
            "L'Ancienne-Lorette",
            "L'Ange-Gardien",
            "L'Ascension-de-Notre-Seigneur",
            "L'Assomption",
            "L'Épiphanie",
            "L'Île-Perrot",
            "L'Orignal",
            "La Broquerie",
            "La Conception",
            "La Haute-Saint-Charles",
            "La Malbaie",
            "La Minerve",
            "La Pocatière",
            "La Prairie",
            "La Ronge",
            "La Sarre",
            "La Tuque",
            "Labelle",
            "Labrador City",
            "Lac du Bonnet",
            "Lac La Biche",
            "Lac-Alouette",
            "Lac-Brome",
            "Lac-Connelly",
            "Lac-Lapierre",
            "Lac-Mégantic",
            "Lac-Simon",
            "Lachute",
            "Lacolle",
            "Lacombe",
            "Ladner",
            "Ladysmith",
            "Lake Cowichan",
            "Lake Echo",
            "Lakefield",
            "Lambton Shores",
            "Lamont",
            "Landmark",
            "Langenburg",
            "Langford",
            "Langham",
            "Langley",
            "Lanigan",
            "Lanoraie",
            "Lantz",
            "Lappe",
            "Larkspur",
            "Laurel",
            "Laval",
            "Lavaltrie",
            "Le Bic",
            "le Plateau",
            "Leamington",
            "Lebel-sur-Quévillon",
            "Leblanc",
            "Leduc",
            "Les Cèdres",
            "Les Coteaux",
            "Les Escoumins",
            "Lethbridge",
            "Lévis",
            "Lewisporte",
            "Lillooet",
            "Limoges",
            "Lincoln",
            "Lindsay",
            "Linière",
            "Lions Bay",
            "Listowel",
            "Little Current",
            "Lively",
            "Lloydminster",
            "Logan Lake",
            "London",
            "Longueuil",
            "Lorette",
            "Lorraine",
            "Louiseville",
            "Lower Sackville",
            "Lucan",
            "Luceville",
            "Lumby",
            "Lumsden",
            "Lunenburg",
            "Lutes Mountain",
            "Macamic",
            "Mackenzie",
            "Macklin",
            "Madoc",
            "Magog",
            "Magrath",
            "Malartic",
            "Maliotenam",
            "Manawan",
            "Mandeville",
            "Manitoulin District",
            "Manitouwadge",
            "Maniwaki",
            "Manning",
            "Mannville",
            "Maple Creek",
            "Maple Ridge",
            "Maple Ridge",
            "Marathon",
            "Maria",
            "Marieville",
            "Markdale",
            "Markham",
            "Martensville",
            "Marystown",
            "Mascouche",
            "Maskinongé",
            "Matagami",
            "Matane",
            "Mattawa",
            "Mauricie",
            "Mayerthorpe",
            "McEwen",
            "Meadow Lake",
            "Meaford",
            "Medicine Hat",
            "Melfort",
            "Melita",
            "Melocheville",
            "Melville",
            "Mercier",
            "Merritt",
            "Métabetchouan",
            "Metabetchouan-Lac-a-la-Croix",
            "Metcalfe",
            "Metchosin",
            "Metro Vancouver Regional District",
            "Middleton",
            "Midland",
            "Mildmay",
            "Mill Woods Town Centre",
            "Millbrook",
            "Millet",
            "Milton",
            "Minnedosa",
            "Mirabel",
            "Miramichi",
            "Mission",
            "Mississauga",
            "Mississauga Beach",
            "Mistissini",
            "Moncton",
            "Mont-Joli",
            "Mont-Laurier",
            "Mont-Royal",
            "Mont-Saint-Grégoire",
            "Mont-Saint-Hilaire",
            "Mont-Tremblant",
            "Montague",
            "Montmagny",
            "Montréal",
            "Montréal-Est",
            "Montréal-Ouest",
            "Moose Factory",
            "Moose Jaw",
            "Moose Lake",
            "Moosomin",
            "Moosonee",
            "Morden",
            "Morin-Heights",
            "Morinville",
            "Morris",
            "Morrisburg",
            "Mount Albert",
            "Mount Brydges",
            "Mount Pearl",
            "Nackawic",
            "Nakusp",
            "Nanaimo",
            "Nanton",
            "Napanee",
            "Napanee Downtown",
            "Napierville",
            "Neebing",
            "Neepawa",
            "Nelson",
            "Nepean",
            "Neuville",
            "New Carlisle",
            "New Glasgow",
            "New Hamburg",
            "New Maryland",
            "New Westminster",
            "New-Richmond",
            "Newmarket",
            "Niagara Falls",
            "Nicolet",
            "Nipawin",
            "Nipissing District",
            "Niverville",
            "Noonan",
            "Nord-du-Québec",
            "Norfolk County",
            "Norman Wells",
            "Normandin",
            "North Battleford",
            "North Bay",
            "North Cowichan",
            "North Oyster/Yellow Point",
            "North Perth",
            "North Saanich",
            "North Vancouver",
            "North York",
            "Norwood",
            "Notre-Dame-de-Grâce",
            "Notre-Dame-de-l'Île-Perrot",
            "Notre-Dame-des-Prairies",
            "Notre-Dame-du-Lac",
            "Notre-Dame-du-Mont-Carmel",
            "Oak Bay",
            "Oakville",
            "Oka",
            "Okanagan",
            "Okanagan Falls",
            "Okotoks",
            "Olds",
            "Oliver",
            "Omemee",
            "Orangeville",
            "Orillia",
            "Ormstown",
            "Oromocto",
            "Osgoode",
            "Oshawa",
            "Osoyoos",
            "Ottawa",
            "Otterburn Park",
            "Outaouais",
            "Outlook",
            "Owen Sound",
            "Oxbow",
            "Oxford",
            "Paisley",
            "Pangnirtung",
            "Papineauville",
            "Parc-Boutin",
            "Paris",
            "Parkhill",
            "Parksville",
            "Parrsboro",
            "Parry Sound",
            "Parry Sound District",
            "Pasadena",
            "Peace River",
            "Peace River Regional District",
            "Peachland",
            "Peel",
            "Pelican Narrows",
            "Pemberton",
            "Pembroke",
            "Penhold",
            "Penticton",
            "Perth",
            "Petawawa",
            "Peterborough",
            "Petrolia",
            "Pickering",
            "Picton",
            "Pictou",
            "Pictou County",
            "Picture Butte",
            "Piedmont",
            "Pierreville",
            "Pilot Butte",
            "Pincher Creek",
            "Pincourt",
            "Pitt Meadows",
            "Plantagenet",
            "Plattsville",
            "Plessisville",
            "Pohénégamook",
            "Pointe-Calumet",
            "Pointe-Claire",
            "Pointe-du-Lac",
            "Ponoka",
            "Pont Rouge",
            "Pont-Rouge",
            "Port Alberni",
            "Port Colborne",
            "Port Coquitlam",
            "Port Hawkesbury",
            "Port Hope",
            "Port McNeill",
            "Port Moody",
            "Port Rowan",
            "Port Stanley",
            "Port Williams",
            "Port-Cartier",
            "Portage la Prairie",
            "Portneuf",
            "Powassan",
            "Powell River",
            "Preeceville",
            "Prescott",
            "Prévost",
            "Prince Albert",
            "Prince Edward",
            "Prince George",
            "Prince Rupert",
            "Princeton",
            "Princeville",
            "Princeville",
            "Provost",
            "Puntledge",
            "Québec",
            "Queenswood Heights",
            "Quesnel",
            "Quinte West",
            "Rainy River District",
            "Rankin Inlet",
            "Rawdon",
            "Raymond",
            "Rayside-Balfour",
            "Red Deer",
            "Red Lake",
            "Regina",
            "Regina Beach",
            "Regional District of Alberni-Clayoquot",
            "Regional District of Central Okanagan",
            "Regional Municipality of Waterloo",
            "Renfrew",
            "Repentigny",
            "Revelstoke",
            "Richelieu",
            "Richibucto",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond Hill",
            "Rideau Park",
            "Ridgetown",
            "Rigaud",
            "Rimbey",
            "Rimouski",
            "Rivers",
            "Rivière-du-Loup",
            "Rivière-Rouge",
            "Roberval",
            "Roblin",
            "Rock Forest",
            "Rockwood",
            "Rocky Mountain House",
            "Rosemère",
            "Rosetown",
            "Rossland",
            "Rosthern",
            "Rougemont",
            "Rouyn-Noranda",
            "Royston",
            "Russell",
            "Sackville",
            "Sacré-Coeur",
            "Saguenay",
            "Saint Andrews",
            "Saint John",
            "Saint-Adolphe-d'Howard",
            "Saint-Alexandre",
            "Saint-Amable",
            "Saint-Ambroise",
            "Saint-André-Avellin",
            "Saint-Anselme",
            "Saint-Antoine",
            "Saint-Antoine-de-Tilly",
            "Saint-Augustin",
            "Saint-Augustin-de-Desmaures",
            "Saint-Barnabé-Sud",
            "Saint-Basile-le-Grand",
            "Saint-Boniface",
            "Saint-Bruno",
            "Saint-Bruno-de-Guigues",
            "Saint-Bruno-de-Montarville",
            "Saint-Canut",
            "Saint-Césaire",
            "Saint-Charles",
            "Saint-Côme-Linière",
            "Saint-Constant",
            "Saint-Cyrille-de-Wendover",
            "Saint-Damase",
            "Saint-Denis-sur-Richelieu",
            "Saint-Donat-de-Montcalm",
            "Saint-Édouard",
            "Saint-Elzéar",
            "Saint-Éphrem-de-Beauce",
            "Saint-Eustache",
            "Saint-Félicien",
            "Saint-Félix-de-Valois",
            "Saint-Gabriel",
            "Saint-Gédéon",
            "Saint-Georges",
            "Saint-Germain-de-Grantham",
            "Saint-Henri",
            "Saint-Hippolyte",
            "Saint-Honoré",
            "Saint-Hyacinthe",
            "Saint-Isidore",
            "Saint-Jacques-le-Mineur",
            "Saint-Jean-Baptiste",
            "Saint-Jean-sur-Richelieu",
            "Saint-Jérôme",
            "Saint-Joseph",
            "Saint-Joseph-de-Beauce",
            "Saint-Joseph-de-Coleraine",
            "Saint-Joseph-du-Lac",
            "Saint-Lambert-de-Lauzon",
            "Saint-Laurent",
            "Saint-Lazare",
            "Saint-Léonard",
            "Saint-Léonard",
            "Saint-Léonard-d'Aston",
            "Saint-Liboire",
            "Saint-Lin-Laurentides",
            "Saint-Marc-des-Carrières",
            "Saint-Mathieu",
            "Saint-Michel",
            "Saint-Michel-des-Saints",
            "Saint-Nazaire",
            "Saint-Norbert",
            "Saint-Pacôme",
            "Saint-Pascal",
            "Saint-Philippe-de-La Prairie",
            "Saint-Pie",
            "Saint-Pierre-les-Becquets",
            "Saint-Prime",
            "Saint-Raphaël",
            "Saint-Raymond",
            "Saint-Rémi",
            "Saint-Rémi-de-Tingwick",
            "Saint-Sauveur",
            "Saint-Sauveur-des-Monts",
            "Saint-Siméon",
            "Saint-Thomas",
            "Saint-Tite",
            "Saint-Victor",
            "Saint-Zotique",
            "Sainte Catherine de la Jacques Cartier",
            "Sainte-Adèle",
            "Sainte-Agathe-des-Monts",
            "Sainte-Anne-de-Bellevue",
            "Sainte-Anne-des-Monts",
            "Sainte-Anne-des-Plaines",
            "Sainte-Béatrix",
            "Sainte-Catherine",
            "Sainte-Croix",
            "Sainte-Élisabeth",
            "Sainte-Julie",
            "Sainte-Julienne",
            "Sainte-Madeleine",
            "Sainte-Marie",
            "Sainte-Marthe-sur-le-Lac",
            "Sainte-Martine",
            "Sainte-Sophie",
            "Sainte-Thècle",
            "Sainte-Thérèse",
            "Salaberry-de-Valleyfield",
            "Salisbury",
            "Salluit",
            "Salmo",
            "Salmon Arm",
            "Salt Spring Island",
            "Saltair",
            "Sarnia",
            "Saskatoon",
            "Sault Ste. Marie",
            "Scarborough",
            "Seaforth",
            "Sechelt",
            "Selkirk",
            "Senneterre",
            "Sept-Îles",
            "Sexsmith",
            "Shannon",
            "Shaunavon",
            "Shawinigan",
            "Shawville",
            "Shediac",
            "Shediac Bridge-Shediac River",
            "Shelburne",
            "Shelburne",
            "Shellbrook",
            "Sherbrooke",
            "Sherwood Park",
            "Shilo",
            "Shippagan",
            "Sicamous",
            "Silver Berry",
            "Simcoe",
            "Sioux Lookout",
            "Six Mile",
            "Skatepark",
            "Slave Lake",
            "Smithers",
            "Smiths Falls",
            "Smoky Lake",
            "Sooke",
            "Sorel-Tracy",
            "Souris",
            "Souris",
            "South Huron",
            "South Pender Harbour",
            "South River",
            "Sparwood",
            "Spirit River",
            "Springbrook",
            "Springdale",
            "Springhill",
            "Spruce Grove",
            "St-Jean-Port-Joli",
            "St. Adolphe",
            "St. Albert",
            "St. Anthony",
            "St. Catharines",
            "St. George",
            "St. John's",
            "St. Thomas",
            "Starlight Village",
            "Steinbach",
            "Stephenville",
            "Stephenville Crossing",
            "Stettler",
            "Stirling",
            "Stonewall",
            "Stoney Point",
            "Stony Plain",
            "Stratford",
            "Strathmore",
            "Sudbury",
            "Summerland",
            "Summerside",
            "Sundre",
            "Surrey",
            "Sussex",
            "Sutton",
            "Swan Hills",
            "Swan River",
            "Swift Current",
            "Sydney",
            "Sydney Mines",
            "Sylvan Lake",
            "Taber",
            "Tamarack",
            "Tavistock",
            "Témiscaming",
            "Temiskaming Shores",
            "Terrace",
            "Terrasse-des-Pins",
            "Terrebonne",
            "The Pas",
            "Thessalon",
            "Thetford-Mines",
            "Thompson",
            "Thornhill",
            "Thorold",
            "Three Hills",
            "Thunder Bay",
            "Thunder Bay District",
            "Thurso",
            "Timiskaming District",
            "Timmins",
            "Tisdale",
            "Tobermory",
            "Tofield",
            "Tofino",
            "Torbay",
            "Toronto",
            "Toronto county",
            "Tottenham",
            "Tracadie-Sheila",
            "Trail",
            "Trois-Rivières",
            "Truro",
            "Tsawwassen",
            "Tumbler Ridge",
            "Tweed",
            "Two Hills",
            "Ucluelet",
            "Unity",
            "Upper Island Cove",
            "Uxbridge",
            "Val-d'Or",
            "Val-David",
            "Val-des-Monts",
            "Val-Morin",
            "Valcourt",
            "Vallée-Jonction",
            "Valley East",
            "Valleyview",
            "Vancouver",
            "Vanderhoof",
            "Vanier",
            "Varennes",
            "Vaudreuil-Dorion",
            "Vaughan",
            "Vegreville",
            "Venise-en-Québec",
            "Verchères",
            "Vermilion",
            "Vernon",
            "Victoria",
            "Victoriaville",
            "Viking",
            "Ville-Marie",
            "Vineland",
            "Virden",
            "Virgil",
            "Vulcan",
            "Wabana",
            "Wadena",
            "Wainwright",
            "Wakefield",
            "Walnut Grove",
            "Walpole Island",
            "Warman",
            "Warwick",
            "Wasaga Beach",
            "Waskaganish",
            "Waswanipi",
            "Waterford",
            "Waterloo",
            "Waterloo",
            "Watford",
            "Watrous",
            "Watson Lake",
            "Wawa",
            "Weedon Centre",
            "Welcome Beach",
            "Welland",
            "Wellesley",
            "Wells",
            "Wembley",
            "Wendover",
            "West End",
            "West Kelowna",
            "West Lorne",
            "West St. Paul",
            "West Vancouver",
            "Westlake",
            "Westlock",
            "Westmount",
            "Wetaskiwin",
            "Weyburn",
            "Weymontachie",
            "Whistler",
            "White City",
            "White Rock",
            "Whitecourt",
            "Whitehorse",
            "Wild Rose",
            "Wilkie",
            "Williams Lake",
            "Willowdale",
            "Winchester",
            "Windsor",
            "Windsor",
            "Windsor",
            "Wingham",
            "Winkler",
            "Winnipeg",
            "Wolfville",
            "Woodstock",
            "Wynyard",
            "Yamachiche",
            "Yarmouth",
            "Yellowknife",
            "York",
            "Yorkton"
        ]
    },
    {
        "label": "Cape Verde",
        "code": "CV",
        "phone": "238",
        "cities": [
            "Assomada",
            "Calheta",
            "Cidade Velha",
            "Cova Figueira",
            "Espargos",
            "Igreja",
            "João Teves",
            "Mindelo",
            "Nova Sintra",
            "Pedra Badejo",
            "Pombas",
            "Ponta do Sol",
            "Porto Novo",
            "Praia",
            "Ribeira Brava",
            "Ribeira Grande",
            "Sal Rei",
            "Santa Cruz",
            "Santa Maria",
            "São Domingos",
            "São Filipe",
            "Tarrafal",
            "Tarrafal de São Nicolau",
            "Vila do Maio"
        ]
    },
    {
        "label": "Cayman Islands",
        "code": "KY",
        "phone": "+1-345",
        "cities": []
    },
    {
        "label": "Central African Republic",
        "code": "CF",
        "phone": "236",
        "cities": [
            "Alindao",
            "Bambari",
            "Bamingui",
            "Bangassou",
            "Bangui",
            "Baoro",
            "Batangafo",
            "Berberati",
            "Bimbo",
            "Birao",
            "Boali",
            "Bocaranga",
            "Boda",
            "Boganangone",
            "Bossangoa",
            "Bouar",
            "Bouca",
            "Bozoum",
            "Bria",
            "Carnot",
            "Damara",
            "Gambo",
            "Gamboula",
            "Grimari",
            "Ippy",
            "Kabo",
            "Kaga Bandoro",
            "Kaga-Bandoro",
            "Kembé",
            "Kouango",
            "Mbaiki",
            "Mobaye",
            "Mongoumba",
            "Ndélé",
            "Nola",
            "Obo",
            "Ouadda",
            "Ouanda-Djallé",
            "Ouango",
            "Paoua",
            "Rafai",
            "Sibut",
            "Zemio"
        ]
    },
    {
        "label": "Chad",
        "code": "TD",
        "phone": "235",
        "cities": [
            "Abéché",
            "Adré",
            "Am Timan",
            "Aozou",
            "Ati",
            "Baguirmi Department",
            "Bébédja",
            "Béboto",
            "Beïnamar",
            "Benoy",
            "Béré",
            "Biltine",
            "Bitkine",
            "Bokoro",
            "Bol",
            "Bongor",
            "Bousso",
            "Chari Department",
            "Dababa",
            "Doba",
            "Fada",
            "Faya-Largeau",
            "Gaoui",
            "Goundi",
            "Gounou Gaya",
            "Goz Beïda",
            "Goz Béïda",
            "Guelendeng",
            "Iriba",
            "Kelo",
            "Koumra",
            "Kyabé",
            "Lac Wey",
            "Laï",
            "Linia",
            "Mandjafa",
            "Mao",
            "Massaguet",
            "Massakory",
            "Massenya",
            "Mboursou Léré",
            "Melfi",
            "Moïssala",
            "Mongo",
            "Moundou",
            "Moussoro",
            "N'Djamena",
            "Oum Hadjer",
            "Pala",
            "Sarh"
        ]
    },
    {
        "label": "Chile",
        "code": "CL",
        "phone": "56",
        "cities": [
            "Algarrobo",
            "Alhué",
            "Alto Biobío",
            "Alto del Carmen",
            "Alto Hospicio",
            "Ancud",
            "Andacollo",
            "Angol",
            "Antártica",
            "Antofagasta",
            "Antuco",
            "Arauco",
            "Arica",
            "Aysén",
            "Buin",
            "Bulnes",
            "Cabildo",
            "Cabo de Hornos",
            "Cabrero",
            "Calama",
            "Calbuco",
            "Caldera",
            "Calera de Tango",
            "Calle Larga",
            "Camarones",
            "Camiña",
            "Canela",
            "Cañete",
            "Carahue",
            "Cartagena",
            "Casablanca",
            "Castro",
            "Catemu",
            "Cauquenes",
            "Cerrillos",
            "Cerro Navia",
            "Chaitén",
            "Chañaral",
            "Chanco",
            "Chépica",
            "Chiguayante",
            "Chile Chico",
            "Chillán",
            "Chillán Viejo",
            "Chimbarongo",
            "Cholchol",
            "Chonchi",
            "Cisnes",
            "Cobquecura",
            "Cochamó",
            "Cochrane",
            "Codegua",
            "Coelemu",
            "Coihueco",
            "Coínco",
            "Colbún",
            "Colchane",
            "Colina",
            "Collipulli",
            "Coltauco",
            "Combarbalá",
            "Concepción",
            "Conchalí",
            "Concón",
            "Constitución",
            "Contulmo",
            "Copiapó",
            "Coquimbo",
            "Coronel",
            "Corral",
            "Coyhaique",
            "Cunco",
            "Curacautín",
            "Curacaví",
            "Curaco de Vélez",
            "Curanilahue",
            "Curarrehue",
            "Curepto",
            "Curicó",
            "Dalcahue",
            "Diego de Almagro",
            "Doñihue",
            "El Bosque",
            "El Carmen",
            "El Monte",
            "El Quisco",
            "El Tabo",
            "Empedrado",
            "Ercilla",
            "Estación Central",
            "Florida",
            "Freire",
            "Freirina",
            "Fresia",
            "Frutillar",
            "Futaleufú",
            "Futrono",
            "Galvarino",
            "General Lagos",
            "Gorbea",
            "Graneros",
            "Guaitecas",
            "Hijuelas",
            "Hualaihué",
            "Hualañé",
            "Hualpén",
            "Hualqui",
            "Huara",
            "Huasco",
            "Huechuraba",
            "Illapel",
            "Independencia",
            "Iquique",
            "Isla de Maipo",
            "Isla de Pascua",
            "Juan Fernández",
            "La Calera",
            "La Cisterna",
            "La Cruz",
            "La Estrella",
            "La Florida",
            "La Granja",
            "La Higuera",
            "La Ligua",
            "La Pintana",
            "La Reina",
            "La Serena",
            "La Unión",
            "Lago Ranco",
            "Lago Verde",
            "Laguna Blanca",
            "Laja",
            "Lampa",
            "Lanco",
            "Las Cabras",
            "Las Condes",
            "Lautaro",
            "Lebu",
            "Licantén",
            "Limache",
            "Linares",
            "Litueche",
            "Llaillay",
            "Llanquihue",
            "Lo Barnechea",
            "Lo Espejo",
            "Lo Prado",
            "Lolol",
            "Loncoche",
            "Longaví",
            "Lonquimay",
            "Los Álamos",
            "Los Andes",
            "Los Ángeles",
            "Los Lagos",
            "Los Muermos",
            "Los Sauces",
            "Los Vilos",
            "Lota",
            "Lumaco",
            "Machalí",
            "Macul",
            "Máfil",
            "Maipú",
            "Malloa",
            "Marchigüe",
            "María Elena",
            "María Pinto",
            "Mariquina",
            "Maule",
            "Maullín",
            "Mejillones",
            "Melipeuco",
            "Melipilla",
            "Molina",
            "Monte Patria",
            "Mostazal",
            "Mulchén",
            "Nacimiento",
            "Nancagua",
            "Natales",
            "Navidad",
            "Negrete",
            "Ninhue",
            "Ñiquén",
            "Nogales",
            "Nueva Imperial",
            "Ñuñoa",
            "O'Higgins",
            "Olivar",
            "Ollagüe",
            "Olmué",
            "Osorno",
            "Ovalle",
            "Padre Hurtado",
            "Padre Las Casas",
            "Paihuano",
            "Paillaco",
            "Paine",
            "Palena",
            "Palmilla",
            "Panguipulli",
            "Panquehue",
            "Papudo",
            "Paredones",
            "Parral",
            "Pedro Aguirre Cerda",
            "Pelarco",
            "Pelluhue",
            "Pemuco",
            "Peñaflor",
            "Peñalolén",
            "Pencahue",
            "Penco",
            "Peralillo",
            "Perquenco",
            "Petorca",
            "Peumo",
            "Pica",
            "Pichidegua",
            "Pichilemu",
            "Pinto",
            "Pirque",
            "Pitrufquén",
            "Placilla",
            "Portezuelo",
            "Porvenir",
            "Pozo Almonte",
            "Primavera",
            "Providencia",
            "Puchuncaví",
            "Pucón",
            "Pudahuel",
            "Puente Alto",
            "Puerto Montt",
            "Puerto Octay",
            "Puerto Varas",
            "Pumanque",
            "Punitaqui",
            "Punta Arenas",
            "Puqueldón",
            "Purén",
            "Purranque",
            "Putaendo",
            "Putre",
            "Puyehue",
            "Queilén",
            "Quellón",
            "Quemchi",
            "Quilaco",
            "Quilicura",
            "Quilleco",
            "Quillón",
            "Quillota",
            "Quilpué",
            "Quinchao",
            "Quinta de Tilcoco",
            "Quinta Normal",
            "Quintero",
            "Quirihue",
            "Rancagua",
            "Ránquil",
            "Rauco",
            "Recoleta",
            "Renaico",
            "Renca",
            "Rengo",
            "Requínoa",
            "Retiro",
            "Rinconada",
            "Río Bueno",
            "Río Claro",
            "Río Hurtado",
            "Río Ibáñez",
            "Río Negro",
            "Río Verde",
            "Romeral",
            "Saavedra",
            "Sagrada Familia",
            "Salamanca",
            "San Antonio",
            "San Bernardo",
            "San Carlos",
            "San Clemente",
            "San Esteban",
            "San Fabián",
            "San Felipe",
            "San Fernando",
            "San Gregorio",
            "San Ignacio",
            "San Javier",
            "San Joaquín",
            "San José de Maipo",
            "San Juan de la Costa",
            "San Miguel",
            "San Nicolás",
            "San Pablo",
            "San Pedro",
            "San Pedro de Atacama",
            "San Pedro de la Paz",
            "San Rafael",
            "San Ramón",
            "San Rosendo",
            "San Vicente",
            "Santa Bárbara",
            "Santa Cruz",
            "Santa Juana",
            "Santa María",
            "Santiago",
            "Santo Domingo",
            "Sierra Gorda",
            "Talagante",
            "Talca",
            "Talcahuano",
            "Taltal",
            "Temuco",
            "Teno",
            "Teodoro Schmidt",
            "Tierra Amarilla",
            "Tiltil",
            "Timaukel",
            "Tirúa",
            "Tocopilla",
            "Toltén",
            "Tomé",
            "Torres del Paine",
            "Tortel",
            "Traiguén",
            "Treguaco",
            "Tucapel",
            "Valdivia",
            "Vallenar",
            "Valparaíso",
            "Vichuquén",
            "Victoria",
            "Vicuña",
            "Vilcún",
            "Villa Alegre",
            "Villa Alemana",
            "Villarrica",
            "Viña del Mar",
            "Vitacura",
            "Yerbas Buenas",
            "Yumbel",
            "Yungay",
            "Zapallar"
        ]
    },
    {
        "label": "China",
        "code": "CN",
        "phone": "86",
        "cities": [
            "Aba Zangzu Qiangzu Zizhizhou",
            "Acheng",
            "Ailan Mubage",
            "Aksu",
            "Aksu Diqu",
            "Altay",
            "Altay Diqu",
            "Anbu",
            "Anda",
            "Anjiang",
            "Ankang",
            "Anlu",
            "Anping",
            "Anqing",
            "Anqing Shi",
            "Anqiu",
            "Anshan",
            "Anshun",
            "Anxiang",
            "Anyang",
            "Anyang Shi",
            "Aoshi",
            "Aral",
            "Aykol",
            "Babu",
            "Badaogu",
            "Badu",
            "Bahuang",
            "Baicheng",
            "Baihe",
            "Baijian",
            "Baijiantan",
            "Baiqi",
            "Baiquan",
            "Baise City",
            "Baise Shi",
            "Baisha",
            "Baishan",
            "Baishi",
            "Baishishan",
            "Baiyin",
            "Baiyun",
            "Baluntaicun",
            "Bamencheng",
            "Bamiantong",
            "Bangdong",
            "Bangjun",
            "Baoding",
            "Baoji Shi",
            "Baoqing",
            "Baoshan",
            "Baotou",
            "Baoying",
            "Barkam",
            "Basuo",
            "Bayan",
            "Bayan Nur",
            "Bayannur Shi",
            "Bayingolin Mongol Zizhizhou",
            "Bazhong Shi",
            "Bei’an",
            "Beibei",
            "Beicang",
            "Beichengqu",
            "Beidaihehaibin",
            "Beidao",
            "Beihai",
            "Beihuaidian",
            "Beijing",
            "Beilizigu",
            "Beipiao",
            "Beishancun",
            "Beizhai",
            "Benchu",
            "Bengbu",
            "Benxi",
            "Bianzhuang",
            "Biaokou",
            "Bijie",
            "Binhai New Area",
            "Binhe",
            "Binzhou",
            "Binzhou",
            "Biyong",
            "Bojia",
            "Boli",
            "Boshan",
            "Botou",
            "Boyang",
            "Bozhou",
            "Bozhou",
            "Buhe",
            "Burang",
            "Caidian",
            "Caijia",
            "Caijiapu",
            "Cangzhou",
            "Cangzhou Shi",
            "Caodian",
            "Caohe",
            "Chabaihu",
            "Chadian",
            "Chaihe",
            "Changchi",
            "Changchun",
            "Changde",
            "Changji",
            "Changji Huizu Zizhizhou",
            "Changleng",
            "Changli",
            "Changling",
            "Changping",
            "Changqiao",
            "Changqing",
            "Changsha",
            "Changsha",
            "Changsha Shi",
            "Changshu City",
            "Changtu",
            "Changtun",
            "Changzhi",
            "ChangZhou",
            "Changzhou",
            "Chaohu",
            "Chaoyang",
            "Chaoyang",
            "Chaozhou",
            "Chatian",
            "Chengde",
            "Chengde Prefecture",
            "Chengdu",
            "Chengguan",
            "Chenghua",
            "Chengjiao",
            "Chengmen",
            "Chengqu",
            "Chengtangcun",
            "Chengtougu",
            "Chengxiang",
            "Chengyang",
            "Chengzhong",
            "Chengzihe",
            "Chenzhou",
            "Chifeng",
            "Chitu",
            "Chixi",
            "Chizhou",
            "Chizhou Shi",
            "Chonglong",
            "Chongqing",
            "Chongru",
            "Chongshan",
            "Chongzuo Shi",
            "Chumi",
            "Chuxiong Yizu Zizhizhou",
            "Chuzhou",
            "Chuzhou Shi",
            "Cuijiamatou",
            "Dabachang",
            "Dabaozi",
            "Dadeng",
            "Dadonghai",
            "Dadukou",
            "Dadunqiu",
            "Daixi",
            "Dakoutun",
            "Dalai",
            "Dali",
            "Dali Baizu Zizhizhou",
            "Dalian",
            "Daliang",
            "Dalianwan",
            "Dandong",
            "Danjiangkou",
            "Danshui",
            "Danyang",
            "Daokou",
            "Daqiao",
            "Daqing",
            "Darong",
            "Dasha",
            "Dashentang",
            "Dashiqiao",
            "Dashitou",
            "Datong",
            "Datong",
            "Datong Shi",
            "Dawangtai",
            "Dawukou",
            "Daxing",
            "Daxinzhuang",
            "Daye",
            "Dazhai",
            "Dazhong",
            "Dazhongzhuang",
            "Dazhou",
            "Dazuo",
            "Dehang",
            "Dehong Daizu Jingpozu Zizhizhou",
            "Dehui",
            "Delingha",
            "Dengjiapu",
            "Dengying",
            "Dengyuantai",
            "Dengzhou",
            "Dêqên",
            "Dêqên Tibetan Autonomous Prefecture",
            "Deqing",
            "Deyang",
            "Dezhou",
            "Dingcheng",
            "Dinghaicun",
            "Dingtao",
            "Dingtoucun",
            "Dingxi Shi",
            "Dingzhou",
            "Dongchongcun",
            "Dongcun",
            "Dongdai",
            "Dongditou",
            "Dongdu",
            "Dongfeng",
            "Dongguan",
            "Donghai",
            "Donghu",
            "Dongkan",
            "Dongling",
            "Dongling",
            "Dongning",
            "Dongshan Dongzuxiang",
            "Dongsheng",
            "Dongshigu",
            "Dongta",
            "Dongtai",
            "Dongxi",
            "Dongxi",
            "Dongxing",
            "Dongyang",
            "Dongyuan",
            "Ducheng",
            "Dundong",
            "Dunhua",
            "Duobao",
            "Duyun",
            "E’erguna",
            "Encheng",
            "Enshi",
            "Enshi Tujiazu Miaozu Zizhizhou",
            "Erdaojiang",
            "Erenhot",
            "Erwangzhuang",
            "Ezhou",
            "Ezhou Shi",
            "Fangchenggang Shi",
            "Fangshan",
            "Fangting",
            "Fanzhuang",
            "Feicheng",
            "Feiluan",
            "Fendou",
            "Fengcheng",
            "Fenghua",
            "Fenghuang",
            "Fengkou",
            "Fengpu",
            "Fengrun",
            "Fengtai (Ninghe)",
            "Fengxian",
            "Fengxiang",
            "Fengzhou",
            "Fenyi",
            "Foshan",
            "Foshan Shi",
            "Fu’an",
            "Fubao",
            "Fuding",
            "Fujin",
            "Fukang",
            "Fuli",
            "Fuling",
            "Fuqing",
            "Fushun",
            "Fuxin",
            "Fuyang",
            "Fuyang",
            "Fuyang Shi",
            "Fuyu",
            "Fuyu",
            "Fuyuan",
            "Fuzhou",
            "Fuzhuang",
            "Gaizhou",
            "Gangdong",
            "Gannan",
            "Ganshui",
            "Gantang",
            "Ganzhou",
            "Ganzhou Shi",
            "Gaogou",
            "Gaojingzhuang",
            "Gaomi",
            "Gaoniang",
            "Gaoping",
            "Gaoqiao",
            "Gaowu",
            "Gaoyao",
            "Gaoyi",
            "Gaoyou",
            "Gaozeng",
            "Gaozhou",
            "Garzê Zangzu Zizhizhou",
            "Gejiu",
            "Genhe",
            "Golmud",
            "Golog Tibetan Autonomous Prefecture",
            "Gongchangling",
            "Gongzhuling",
            "Guandu",
            "Guang’an",
            "Guangming",
            "Guangshui",
            "Guangyuan",
            "Guangzhou",
            "Guangzhou Shi",
            "Guankou",
            "Guantou",
            "Gucheng Chengguanzhen",
            "Gufeng",
            "Guigang",
            "Guilin",
            "Guilin Shi",
            "Guiping",
            "Guiren",
            "Guixi",
            "Guiyang",
            "Guli",
            "Guofuchang",
            "Guozhen",
            "Gushu",
            "Gutao",
            "Guye",
            "Haibei Tibetan Autonomous Prefecture",
            "Haicheng",
            "Haikou",
            "Haikou",
            "Haikuotiankong",
            "Hailar",
            "Hailin",
            "Hailun",
            "Haimen",
            "Haining",
            "Haizhou",
            "Hami",
            "Hancheng",
            "Hancheng",
            "Hanchuan",
            "Handan",
            "Hangzhou",
            "Hanjiashu",
            "Hanting",
            "Hanzhong",
            "Harbin",
            "Hebeitun",
            "Hebi",
            "Hechi Shi",
            "Hechuan",
            "Hecun",
            "Hede",
            "Hefei",
            "Hefei Shi",
            "Hegang",
            "Heihe",
            "Heishan",
            "Helong",
            "Hengbanqiao",
            "Hengshui",
            "Hengyang",
            "Hepingjie",
            "Hepo",
            "Hetang",
            "Hexiangqiao",
            "Hexiwu",
            "Heyuan",
            "Heze",
            "Hezuo",
            "Hohhot",
            "Honggang",
            "Honghe Hanizu Yizu Zizhizhou",
            "Hongjiang",
            "Hongqiao",
            "Hongtang",
            "Hongyang",
            "Hotan",
            "Houyu",
            "Hoxtolgay",
            "Huadian",
            "Huai'an",
            "Huai’an",
            "Huai’an Shi",
            "HuaiAn",
            "Huaibei",
            "Huaicheng",
            "Huaidian",
            "Huaihua",
            "Huainan",
            "Huainan Shi",
            "Huaiyuan Chengguanzhen",
            "Huanan",
            "Huangcaotuo",
            "Huanggang",
            "Huanggang",
            "Huangjinjing",
            "Huanglong",
            "Huangmaoyuan",
            "Huangmei",
            "Huangnan Zangzu Zizhizhou",
            "Huangnihe",
            "Huangpi",
            "Huangqiao",
            "Huangshan",
            "Huangshan Shi",
            "Huangshi",
            "Huangtian",
            "Huangtukuang",
            "Huangxikou",
            "Huangyan",
            "Huangzhou",
            "Huanren",
            "Huantuo",
            "Huaqiao",
            "Huaqiu",
            "Huayin",
            "Huayuan",
            "Huazhou",
            "Huazhou",
            "Huichang",
            "Huicheng",
            "Huilong",
            "Huinan",
            "Huizhou",
            "Hulan",
            "Hulan Ergi",
            "Huludao",
            "Huludao Shi",
            "Hulunbuir Region",
            "Humen",
            "Hunchun",
            "Huocheng",
            "Huogezhuang",
            "Huomachong",
            "Huoqiu Chengguanzhen",
            "Huotong",
            "Hushitai",
            "Hutang",
            "Huzhou",
            "Ili Kazak Zizhizhou",
            "Jalai Nur",
            "Ji’an",
            "Ji’an",
            "Jiagedaqi",
            "Jiamaying",
            "Jiamusi",
            "Jian’ou",
            "Jiancheng",
            "Jiangfang",
            "Jiangkou",
            "Jiangkouxu",
            "Jiangmen",
            "Jiangshi",
            "Jianguang",
            "Jiangwakou",
            "Jiangyan",
            "Jiangyin",
            "Jiangyou",
            "Jiangzi",
            "Jianjiang",
            "Jiannan",
            "Jianshe",
            "Jiaojiang",
            "Jiaozhou",
            "Jiaozuo",
            "Jiashan",
            "Jiaxing",
            "Jiaxing Shi",
            "Jiayuguan",
            "Jiazi",
            "Jidong",
            "Jiehu",
            "Jieshi",
            "Jieshou",
            "Jiexiu",
            "Jieyang",
            "Jijiang",
            "Jilin",
            "Jimo",
            "Jinan",
            "Jinchang",
            "Jincheng",
            "Jingdezhen",
            "Jingdezhen Shi",
            "Jingfeng",
            "Jinghong",
            "Jingjiang",
            "Jingling",
            "Jingmen",
            "Jingmen Shi",
            "Jingzhou",
            "Jinhe",
            "Jinhua",
            "Jining",
            "Jining",
            "Jinji",
            "Jinjiang",
            "Jinjiang",
            "Jinjing",
            "Jinsha",
            "Jinshi",
            "Jinshiqiao",
            "Jinxiang",
            "Jinzhong Shi",
            "Jinzhou",
            "Jishu",
            "Jishui",
            "Jitoucun",
            "Jiujiang",
            "Jiupu",
            "Jiuquan",
            "Jiutai",
            "Jixi",
            "Jiyuan",
            "Juegang",
            "Juye",
            "Kaifeng",
            "Kaihua",
            "Kaitong",
            "Kaiyuan",
            "Kaiyuan",
            "Kaiyuan",
            "Kangding",
            "Karamay",
            "Karamay Shi",
            "Kashgar",
            "Kaxgar Diqu",
            "Kengyuan",
            "Kerencun",
            "Korla",
            "Kuai’an",
            "Kuandian",
            "Kuiju",
            "Kunming",
            "Kunshan",
            "Kunyang",
            "Kuqa",
            "Kutao",
            "Kuytun",
            "Laibin",
            "Laiwu",
            "Laixi",
            "Laiyang",
            "Laizhou",
            "Langfang",
            "Langfang Shi",
            "Langtoucun",
            "Langxiang",
            "Langzhong",
            "Lanli",
            "Lantian",
            "Lanxi",
            "Lanxi",
            "Lanzhou",
            "Laocheng",
            "Laohekou",
            "Laojunmiao",
            "Lecheng",
            "Leiyang",
            "Lengshuijiang",
            "Lengshuitan",
            "Leshan",
            "Lhasa",
            "Liangcunchang",
            "Lianghu",
            "Liangping District",
            "Liangshan Yizu Zizhizhou",
            "Liangxiang",
            "Liangyaping",
            "Lianhe",
            "Lianhecun",
            "Lianjiang",
            "Lianran",
            "Lianshan",
            "Lianyuan",
            "LianYunGang",
            "Lianyungang Shi",
            "Lianzhou",
            "Lianzhou",
            "Lianzhuang",
            "Liaocheng",
            "Liaoyang",
            "Liaoyuan",
            "Liaozhong",
            "Licheng",
            "Licheng",
            "Lichuan",
            "Lijiang",
            "Lincang Shi",
            "Lincheng",
            "Linfen",
            "Lingbao Chengguanzhen",
            "Lingcheng",
            "Lingdong",
            "Linghai",
            "Lingyuan",
            "Linhai",
            "Linjiang",
            "Linkou",
            "Linkou",
            "Linping",
            "Linqiong",
            "Linqu",
            "Linshui",
            "Lintingkou",
            "Lintong",
            "Linxi",
            "Linxia Chengguanzhen",
            "Linxia Huizu Zizhizhou",
            "Linyi",
            "Lishu",
            "Lishui",
            "Liuduzhai",
            "Liuhe",
            "Liujiading",
            "Liukuaizhuang",
            "Liupanshui",
            "Liuwudiancun",
            "Liuzhou Shi",
            "Liuzikou",
            "Lixiqiao",
            "Longfeng",
            "Longgang",
            "Longjiang",
            "Longjing",
            "Longlisuo",
            "Longmen",
            "Longnan Shi",
            "Longquan",
            "Longtan",
            "Longtou’an",
            "Longyan",
            "Loudi",
            "Loushanguan",
            "Lu’an",
            "Luancheng",
            "Lubu",
            "Lucheng",
            "Lüliang",
            "Luocheng",
            "Luocheng",
            "Luohe",
            "Luohe Shi",
            "Luojiu",
            "Luoqiao",
            "Luorong",
            "Luotuofangzi",
            "Luoyang",
            "Luoyang",
            "Luoyang",
            "Luqiao",
            "Lüshun",
            "Luxia",
            "Luyang",
            "Luzhou",
            "Ma’an",
            "Maba",
            "Mabai",
            "Macheng",
            "Majie",
            "Malin",
            "Manzhouli",
            "Maoming",
            "Maoping",
            "Maoping",
            "Maping",
            "Mawu",
            "Meichang",
            "Meihekou",
            "Meipu",
            "Meishan Shi",
            "Meizhou",
            "Mengcheng Chengguanzhen",
            "Mengquan",
            "Mengyin",
            "Mentougou",
            "Mianyang",
            "Min’an",
            "Minggang",
            "Mingguang",
            "Mingshui",
            "Mingshui",
            "Mingyue",
            "Minzhu",
            "Mishan",
            "Miyang",
            "Mizhou",
            "Mositai",
            "Mudanjiang",
            "Mudu",
            "Mujiayingzi",
            "Nada",
            "Nagqu",
            "Nagqu Diqu",
            "Nanchang",
            "Nanchong",
            "Nanding",
            "Nandu",
            "Nanfeng",
            "Nangong",
            "NanJing",
            "Nanjing",
            "Nanlong",
            "Nanma",
            "Nanmuping",
            "Nanning",
            "Nanpiao",
            "Nanping",
            "Nantai",
            "NanTong",
            "Nantong",
            "Nanyang",
            "Nanzhang Chengguanzhen",
            "Nanzhou",
            "Nehe",
            "Neijiang",
            "Neikeng",
            "Nenjiang",
            "Ngari Diqu",
            "Nianzishan",
            "Ning’an",
            "Ningbo",
            "Ningde",
            "Ninghai",
            "Ninghai",
            "Ningyang",
            "Nujiang Lisuzu Zizhizhou",
            "Nyingchi Prefecture",
            "Ordos",
            "Ordos Shi",
            "Oroqen Zizhiqi",
            "Ouyang",
            "Pandu",
            "Panjin Shi",
            "Panshan",
            "Panshi",
            "Panzhihua",
            "Panzhuang",
            "Pengcheng",
            "Pingdingshan",
            "Pingdu",
            "Pingjiang",
            "Pingliang",
            "Pingnan",
            "Pingshan",
            "Pingxiang",
            "Pingyi",
            "Pingyin",
            "Pingzhuang",
            "Pizhou",
            "Poyang",
            "Prefecture of Chenzhou",
            "Pucheng",
            "Puhechang",
            "Puji",
            "Pukou",
            "Pulandian",
            "Pumiao",
            "Puning",
            "Puqi",
            "Putian",
            "Puyang",
            "Puyang Chengguanzhen",
            "Puyang Shi",
            "Puzi",
            "Qamdo",
            "Qamdo Shi",
            "Qapqal",
            "Qiancheng",
            "Qiandongnan Miao and Dong Autonomous Prefecture",
            "Qianjiang",
            "Qianxinan Bouyeizu Miaozu Zizhizhou",
            "Qianzhou",
            "Qiaojiang",
            "Qibu",
            "Qidu",
            "Qimeng",
            "Qincheng",
            "Qingdao",
            "Qinggang",
            "Qingguang",
            "Qingjiangqiao",
            "Qinglang",
            "Qingnian",
            "Qingping",
            "Qingquan",
            "Qingshancun",
            "Qingxi",
            "Qingyang",
            "Qingyang Shi",
            "Qingyuan",
            "Qingzhou",
            "Qinhuangdao",
            "Qinnan",
            "Qinzhou",
            "Qionghai",
            "Qionghu",
            "Qiongshan",
            "Qiqihar",
            "Quanzhou",
            "Qufu",
            "Qujing",
            "Quzhou",
            "Renqiu",
            "Rikaze",
            "Rizhao",
            "Rong’an",
            "Rucheng",
            "Runing",
            "Runsong",
            "Ruoshui",
            "Ruzhou",
            "Saga",
            "Salaqi",
            "Sanchahe",
            "Sanchazi",
            "Sangmu",
            "Sangzi",
            "Sanmao",
            "Sanming",
            "Sansha",
            "Sanshui",
            "Sanya",
            "Shache",
            "Shahecheng",
            "Shajiang",
            "Shancheng",
            "Shangcang",
            "Shanghai",
            "Shangjie",
            "Shangmei",
            "Shangqiu",
            "Shangrao",
            "Shangri-La",
            "Shangyu",
            "Shangzhi",
            "Shanhaiguan",
            "Shanhecun",
            "Shanmen",
            "Shannan Diqu",
            "Shanting",
            "Shantou",
            "Shanwei",
            "Shanxia",
            "Shanyang",
            "Shaoguan",
            "Shaowu",
            "Shaoxing",
            "Shaping",
            "Shapingba District",
            "Shashi",
            "Shazikou",
            "Shengli",
            "Shenjiamen",
            "Shennongjia",
            "Shenyang",
            "Shenzhen",
            "Shiguai",
            "Shihezi",
            "Shijiang",
            "Shijiaochang",
            "Shijiazhuang",
            "Shijiazhuang Shi",
            "Shijing",
            "Shilaorencun",
            "Shilin",
            "Shilong",
            "Shima",
            "Shimianzhuang",
            "Shiqi",
            "Shiqian",
            "Shiqiao",
            "Shitanjing",
            "Shiwan",
            "Shixing",
            "Shiyan",
            "Shizilu",
            "Shizuishan",
            "Shouguang",
            "Shoushan",
            "Shuangcheng",
            "Shuanghejiedao",
            "Shuangjiang",
            "Shuangjiang",
            "Shuangxi",
            "Shuangxi",
            "Shuangyang",
            "Shuangyashan",
            "Shuangzhu",
            "Shuiche",
            "Shuidatian",
            "Shuikou",
            "Shulan",
            "Shunyi",
            "Shuozhou",
            "Sigaozhuang",
            "Simenqian",
            "Siping",
            "Sishilichengzi",
            "Sishui",
            "Songjiang",
            "Songjianghe",
            "Songkan",
            "Songling",
            "Songling",
            "Songyang",
            "Songyuan",
            "Suicheng",
            "Suifenhe",
            "Suihua",
            "Suileng",
            "Suining",
            "Suixi",
            "Suizhou",
            "Sujiatun",
            "Suohe",
            "Suozhen",
            "SuQian",
            "SuZhou",
            "Suzhou",
            "Suzhou",
            "Suzhou Shi",
            "Tacheng",
            "Tacheng Diqu",
            "Tahe",
            "Tai’an",
            "Taihe",
            "Taihecun",
            "Taikang",
            "Tailai",
            "Taiping",
            "Taishan",
            "Taixing",
            "Taiyuan",
            "TaiZhou",
            "Taizhou",
            "Taizhou",
            "Tanbei",
            "Tangjiafang",
            "Tangjiazhuang",
            "Tangkou",
            "Tangping",
            "Tangshan",
            "Tangshan Shi",
            "Tangzhai",
            "Tantou",
            "Tanwan",
            "Taozhuang",
            "Tatou",
            "Tianchang",
            "Tianfu",
            "Tianjin",
            "Tianpeng",
            "Tianshui",
            "Tieli",
            "Tieling",
            "Tieling Shi",
            "Tingdong",
            "Tingjiang",
            "Tongchuan",
            "Tongchuanshi",
            "Tonggu",
            "Tonghua",
            "Tonghua Shi",
            "Tongliao",
            "Tongren",
            "Tongren Diqu",
            "Tongshan",
            "Tongwan",
            "Tongzhou",
            "Touying",
            "Tumen",
            "Tuokou",
            "Turpan",
            "Turpan Diqu",
            "Tuzhai",
            "Ulanhot",
            "Ürümqi",
            "Urumqi Shi",
            "Wacheng",
            "Wafangdian",
            "Wangkui",
            "Wangqing",
            "Wangqinzhuang",
            "Wanning",
            "Wantouqiao",
            "Wanxian",
            "Wanzhou District",
            "Weifang",
            "Weihai",
            "Weinan",
            "Weining",
            "Weiwangzhuang",
            "Wenchang",
            "Wenlan",
            "Wenling",
            "Wenquan",
            "Wenshan City",
            "Wenshan Zhuangzu Miaozu Zizhizhou",
            "Wenshang",
            "Wenshui",
            "Wenxing",
            "Wenzhou",
            "Wubao",
            "Wuchang",
            "Wucheng",
            "Wuchuan",
            "Wuda",
            "Wuhai",
            "Wuhan",
            "Wuhu",
            "Wulingyuan",
            "Wusong",
            "Wuwei",
            "Wuxi",
            "Wuxi",
            "WuXi",
            "Wuxue",
            "Wuyang",
            "Wuyang",
            "Wuyishan",
            "Wuyucun",
            "Wuzhen",
            "Wuzhong",
            "Wuzhou",
            "Xi’an",
            "Xiabaishi",
            "Xiahu",
            "Xiajiang",
            "Xialiang",
            "Xiamen",
            "Xiancun",
            "Xiangcheng Chengguanzhen",
            "Xiangtan",
            "Xiangxi Tujiazu Miaozu Zizhizhou",
            "Xiangxiang",
            "Xiangyang",
            "Xiangyun",
            "Xianju",
            "Xianning",
            "Xianning Prefecture",
            "Xiannü",
            "Xianrenwan",
            "Xiantan",
            "Xiantao",
            "Xianxi",
            "Xianyang",
            "Xiaogan",
            "Xiaohenglong",
            "Xiaolingwei",
            "Xiaolongmen",
            "Xiaoshajiang",
            "Xiaoshan",
            "Xiaoshi",
            "Xiaoweizhai",
            "Xiawuqi",
            "Xiazhen",
            "Xiazhuang",
            "Xibing",
            "Xichang",
            "Xiditou",
            "Xifeng",
            "Xihe",
            "Xiliguantun",
            "Xilin Gol Meng",
            "Xilin Hot",
            "Xiling",
            "Ximei",
            "Xinan",
            "Xincheng",
            "Xindi",
            "Xindian",
            "Xindian",
            "Xingcheng",
            "Xinghua",
            "Xinglongshan",
            "Xingning",
            "Xingtai",
            "Xinhualu",
            "Xinhui",
            "Xining",
            "Xinji",
            "Xinkaikou",
            "Xinmin",
            "Xinpu",
            "Xinqing",
            "Xinshi",
            "Xintai",
            "Xintian",
            "Xinxiang",
            "Xinxiang Shi",
            "Xinxing",
            "Xinyang",
            "Xinyi",
            "Xinyu",
            "Xinyuan",
            "Xinzhan",
            "Xinzhi",
            "Xinzhou",
            "Xinzhou",
            "Xiongzhou",
            "Xireg",
            "Xishan",
            "Xishan",
            "Xitazhuang",
            "Xiulin",
            "Xiuyan",
            "Xiuying",
            "Xixi",
            "Xixiang",
            "Xiyan",
            "Xuanzhou",
            "Xuchang",
            "Xuchang Shi",
            "Xucheng",
            "Xujiaba",
            "Xunchang",
            "XuZhou",
            "Yakeshi",
            "Yakou",
            "Yakou",
            "Yanbian Chaoxianzu Zizhizhou",
            "YanCheng",
            "Yancheng",
            "Yangchun",
            "Yanggu",
            "Yanghou",
            "Yangjiang",
            "Yangjinzhuang",
            "Yangliuqing",
            "Yangquan",
            "Yangshuo",
            "Yangtou",
            "Yangzhong",
            "YangZhou",
            "Yangzhou",
            "Yanji",
            "Yanjiang",
            "Yanliang",
            "Yanmen",
            "Yanshi Chengguanzhen",
            "Yanta",
            "Yantai",
            "Yantian",
            "Yantongshan",
            "Yanzhou",
            "Yaoshi",
            "Yashan",
            "Yatou",
            "Yatunpu",
            "Yebaishou",
            "Yibin",
            "Yichang",
            "Yicheng",
            "Yicheng",
            "Yichun",
            "Yichun",
            "Yigou",
            "Yilan",
            "Yima",
            "Yinchuan",
            "Yingcheng",
            "Yingchuan",
            "Yingdu",
            "Yingkou",
            "Yinglin",
            "Yingshang Chengguanzhen",
            "Yinliu",
            "Yinzhu",
            "Yishui",
            "Yiwu",
            "Yixingfu",
            "Yiyang",
            "Yongchuan",
            "Yongfeng",
            "Yongning",
            "Yongzhou",
            "Youguzhuang",
            "Youhao",
            "Youyupu",
            "Yuanping",
            "Yucheng",
            "Yucheng",
            "Yuci",
            "Yudong",
            "Yuelongzhuang",
            "Yueyang",
            "Yueyang Shi",
            "Yuguzhuang",
            "Yulin",
            "Yulinshi",
            "Yuncheng",
            "Yunfu",
            "Yunmeng Chengguanzhen",
            "Yunyang",
            "Yushan",
            "Yushan",
            "Yushu",
            "Yushu Zangzu Zizhizhou",
            "Yutan",
            "Yuxi",
            "Yuxia",
            "Yuyao",
            "Yuzhong District",
            "Zangguy",
            "Zaojiacheng",
            "Zaoyang",
            "Zaozhuang",
            "Zengjia",
            "Zhabei",
            "Zhaishi Miaozu Dongzuxiang",
            "Zhalantun",
            "Zhangjiagang",
            "Zhangjiajie",
            "Zhangjiakou",
            "Zhangjiakou Shi",
            "Zhangjiakou Shi Xuanhua Qu",
            "Zhangjiawo",
            "Zhangwan",
            "Zhangye",
            "Zhangye Shi",
            "Zhangzhou",
            "Zhanjiang",
            "Zhaobaoshan",
            "Zhaodong",
            "Zhaogezhuang",
            "Zhaoguli",
            "Zhaoguli",
            "Zhaoqing",
            "Zhaotong",
            "Zhaoyuan",
            "Zhaoyuan",
            "Zhaozhou",
            "Zhengjiatun",
            "Zhengzhou",
            "Zhenhaicun",
            "ZhenJiang",
            "Zhenjiang",
            "Zhenlai",
            "Zhenzhou",
            "Zhicheng",
            "Zhicheng",
            "Zhijiang",
            "Zhongba",
            "Zhongchao",
            "Zhongfang",
            "Zhongfang",
            "Zhongshan",
            "Zhongshan Prefecture",
            "Zhongshu",
            "Zhongwei",
            "Zhongxiang",
            "Zhongxing",
            "Zhongzhai",
            "Zhoucheng",
            "Zhoucun",
            "Zhoukou",
            "Zhoushan",
            "Zhouzhuang",
            "Zhu Cheng City",
            "Zhuanghe",
            "Zhuangyuan",
            "Zhuhai",
            "Zhuji",
            "Zhujiachang",
            "Zhujiajiao",
            "Zhumadian",
            "Zhumadian Shi",
            "Zhuoyang",
            "Zhuqi",
            "Zhushi",
            "Zhuzhou",
            "Zhuzhou Shi",
            "Zhuzhoujiang Miaozuxiang",
            "Zibo",
            "Zigong",
            "Zijinglu",
            "Zoucheng",
            "Zunhua",
            "Zunyi"
        ]
    },
    {
        "label": "Christmas Island",
        "code": "CX",
        "phone": "61",
        "cities": []
    },
    {
        "label": "Cocos (Keeling) Islands",
        "code": "CC",
        "phone": "61",
        "cities": []
    },
    {
        "label": "Colombia",
        "code": "CO",
        "phone": "57",
        "cities": [
            "Abejorral",
            "Abrego",
            "Abriaquí",
            "Acacías",
            "Acandí",
            "Acevedo",
            "Achí",
            "Agua de Dios",
            "Aguachica",
            "Aguada",
            "Aguadas",
            "Aguazul",
            "Agustín Codazzi",
            "Aipe",
            "Albán",
            "Albania",
            "Albania",
            "Albania",
            "Alcalá",
            "Aldana",
            "Alejandría",
            "Algarrobo",
            "Algeciras",
            "Almaguer",
            "Almeida",
            "Alpujarra",
            "Altamira",
            "Alto Baudó",
            "Altos del Rosario",
            "Alvarado",
            "Amagá",
            "Amalfi",
            "Ambalema",
            "Anapoima",
            "Ancuya",
            "Andalucía",
            "Andes",
            "Angelópolis",
            "Angostura",
            "Anolaima",
            "Anorí",
            "Anserma",
            "Ansermanuevo",
            "Anza",
            "Anzoátegui",
            "Apartadó",
            "Apía",
            "Apulo",
            "Aquitania",
            "Aracataca",
            "Aranzazu",
            "Aratoca",
            "Arauca",
            "Arauquita",
            "Arbeláez",
            "Arboleda",
            "Arboledas",
            "Arboletes",
            "Arcabuco",
            "Arenal",
            "Argelia",
            "Argelia",
            "Argelia",
            "Ariguaní",
            "Arjona",
            "Armenia",
            "Armenia",
            "Armero",
            "Arroyohondo",
            "Astrea",
            "Ataco",
            "Atrato",
            "Ayapel",
            "Bagadó",
            "Bahía Solano",
            "Bajo Baudó",
            "Balboa",
            "Balboa",
            "Baranoa",
            "Baraya",
            "Barbacoas",
            "Barbosa",
            "Barbosa",
            "Barichara",
            "Barranca de Upía",
            "Barrancabermeja",
            "Barrancas",
            "Barranco de Loba",
            "Barranco Minas",
            "Barranquilla",
            "Becerril",
            "Belalcázar",
            "Belén",
            "Belén",
            "Belén de Los Andaquies",
            "Belén de Umbría",
            "Bello",
            "Belmira",
            "Beltrán",
            "Berbeo",
            "Betania",
            "Betéitiva",
            "Betulia",
            "Betulia",
            "Bituima",
            "Boavita",
            "Bochalema",
            "Bogotá D.C.",
            "Bogotá D.C.",
            "Bojacá",
            "Bojayá",
            "Bolívar",
            "Bolívar",
            "Bolívar",
            "Bosconia",
            "Boyacá",
            "Briceño",
            "Briceño",
            "Bucaramanga",
            "Bucarasica",
            "Buenaventura",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenos Aires",
            "Buesaco",
            "Bugalagrande",
            "Buriticá",
            "Busbanzá",
            "Cabrera",
            "Cabrera",
            "Cabuyaro",
            "Cacahual",
            "Cáceres",
            "Cachipay",
            "Cachirá",
            "Cácota",
            "Caicedo",
            "Caicedonia",
            "Caimito",
            "Cajamarca",
            "Cajibío",
            "Cajicá",
            "Calamar",
            "Calamar",
            "Calarca",
            "Caldas",
            "Caldas",
            "Caldono",
            "Cali",
            "California",
            "Calima",
            "Caloto",
            "Campamento",
            "Campo de la Cruz",
            "Campoalegre",
            "Campohermoso",
            "Canalete",
            "Cañasgordas",
            "Candelaria",
            "Candelaria",
            "Cantagallo",
            "Caparrapí",
            "Capitanejo",
            "Caqueza",
            "Caracolí",
            "Caramanta",
            "Carcasí",
            "Carepa",
            "Carmen de Apicalá",
            "Carmen de Carupa",
            "Carmen del Darien",
            "Carolina",
            "Cartagena de Indias",
            "Cartagena del Chairá",
            "Cartago",
            "Caruru",
            "Casabianca",
            "Castilla la Nueva",
            "Caucasia",
            "Cepitá",
            "Cereté",
            "Cerinza",
            "Cerrito",
            "Cerro de San Antonio",
            "Cértegui",
            "Chachagüí",
            "Chaguaní",
            "Chalán",
            "Chameza",
            "Chaparral",
            "Charalá",
            "Charta",
            "Chía",
            "Chigorodó",
            "Chima",
            "Chimá",
            "Chimichagua",
            "Chinácota",
            "Chinavita",
            "Chinchiná",
            "Chinú",
            "Chipaque",
            "Chipatá",
            "Chiquinquirá",
            "Chíquiza",
            "Chiriguaná",
            "Chiscas",
            "Chita",
            "Chitagá",
            "Chitaraque",
            "Chivatá",
            "Chivolo",
            "Chivor",
            "Choachí",
            "Chocontá",
            "Cicuco",
            "Ciénaga",
            "Ciénaga de Oro",
            "Ciénega",
            "Cimitarra",
            "Circasia",
            "Cisneros",
            "Ciudad Bolívar",
            "Clemencia",
            "Cocorná",
            "Coello",
            "Cogua",
            "Colombia",
            "Colón",
            "Colón",
            "Coloso",
            "Combita",
            "Concepción",
            "Concepción",
            "Concordia",
            "Concordia",
            "Condoto",
            "Confines",
            "Consaca",
            "Contadero",
            "Contratación",
            "Convención",
            "Copacabana",
            "Coper",
            "Córdoba",
            "Córdoba",
            "Córdoba",
            "Corinto",
            "Coromoro",
            "Corozal",
            "Corrales",
            "Cota",
            "Cotorra",
            "Covarachía",
            "Coveñas",
            "Coyaima",
            "Cravo Norte",
            "Cuaspud",
            "Cubará",
            "Cubarral",
            "Cucaita",
            "Cucunubá",
            "Cúcuta",
            "Cucutilla",
            "Cuítiva",
            "Cumaral",
            "Cumaribo",
            "Cumbal",
            "Cumbitara",
            "Cunday",
            "Curillo",
            "Curití",
            "Curumaní",
            "Dabeiba",
            "Dagua",
            "Dibulla",
            "Distracción",
            "Dolores",
            "Donmatías",
            "Dosquebradas",
            "Duitama",
            "Durania",
            "Ebéjico",
            "El Agrado",
            "El Águila",
            "El Bagre",
            "El Banco",
            "El Cairo",
            "El Calvario",
            "El Cantón de San Pablo",
            "El Carmen",
            "El Carmen de Atrato",
            "El Carmen de Bolívar",
            "El Carmen de Chucurí",
            "El Carmen de Viboral",
            "El Castillo",
            "El Cerrito",
            "El Charco",
            "El Cocuy",
            "El Colegio",
            "El Copey",
            "El Doncello",
            "El Dorado",
            "El Dovio",
            "El Encanto",
            "El Espino",
            "El Guacamayo",
            "El Guamo",
            "El Molino",
            "El Paso",
            "El Paujil",
            "El Peñol",
            "El Peñón",
            "El Peñón",
            "El Peñón",
            "El Piñon",
            "El Playón",
            "El Retén",
            "El Retorno",
            "El Roble",
            "El Rosal",
            "El Rosario",
            "El Santuario",
            "El Tablón de Gómez",
            "El Tambo",
            "El Tambo",
            "El Tarra",
            "El Zulia",
            "Elias",
            "Encino",
            "Enciso",
            "Entrerrios",
            "Envigado",
            "Espinal",
            "Facatativá",
            "Falan",
            "Filadelfia",
            "Filandia",
            "Firavitoba",
            "Flandes",
            "Florencia",
            "Florencia",
            "Floresta",
            "Florián",
            "Florida",
            "Floridablanca",
            "Fomeque",
            "Fonseca",
            "Fortul",
            "Fosca",
            "Francisco Pizarro",
            "Fredonia",
            "Fresno",
            "Frontino",
            "Fuente de Oro",
            "Fundación",
            "Funes",
            "Funza",
            "Fúquene",
            "Fusagasugá",
            "Gachala",
            "Gachancipá",
            "Gachantivá",
            "Gachetá",
            "Galán",
            "Galapa",
            "Galeras",
            "Gama",
            "Gamarra",
            "Gambita",
            "Gameza",
            "Garagoa",
            "Garzón",
            "Génova",
            "Gigante",
            "Ginebra",
            "Giraldo",
            "Girardot",
            "Girardota",
            "Girón",
            "Gómez Plata",
            "González",
            "Gramalote",
            "Granada",
            "Granada",
            "Granada",
            "Guaca",
            "Guacamayas",
            "Guacarí",
            "Guachené",
            "Guachetá",
            "Guachucal",
            "Guadalajara de Buga",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guaduas",
            "Guaitarilla",
            "Gualmatán",
            "Guamal",
            "Guamal",
            "Guamo",
            "Guapi",
            "Guapotá",
            "Guaranda",
            "Guarne",
            "Guasca",
            "Guatapé",
            "Guataquí",
            "Guatavita",
            "Guateque",
            "Guática",
            "Guavatá",
            "Guayabal de Siquima",
            "Guayabetal",
            "Guayatá",
            "Güepsa",
            "Güicán",
            "Gutiérrez",
            "Hacarí",
            "Hatillo de Loba",
            "Hato",
            "Hato Corozal",
            "Hatonuevo",
            "Heliconia",
            "Herrán",
            "Herveo",
            "Hispania",
            "Hobo",
            "Honda",
            "Ibagué",
            "Icononzo",
            "Iles",
            "Imués",
            "Inírida",
            "Inzá",
            "Ipiales",
            "Iquira",
            "Isnos",
            "Istmina",
            "Itagui",
            "Ituango",
            "Iza",
            "Jambaló",
            "Jamundí",
            "Jardín",
            "Jenesano",
            "Jericó",
            "Jericó",
            "Jerusalén",
            "Jesús María",
            "Jordán",
            "Juan de Acosta",
            "Junín",
            "Juradó",
            "La Apartada",
            "La Argentina",
            "La Belleza",
            "La Calera",
            "La Capilla",
            "La Ceja",
            "La Celia",
            "La Chorrera",
            "La Cruz",
            "La Cumbre",
            "La Dorada",
            "La Esperanza",
            "La Estrella",
            "La Florida",
            "La Gloria",
            "La Guadalupe",
            "La Jagua de Ibirico",
            "La Jagua del Pilar",
            "La Llanada",
            "La Macarena",
            "La Merced",
            "La Mesa",
            "La Montañita",
            "La Palma",
            "La Paz",
            "La Paz",
            "La Pedrera",
            "La Peña",
            "La Pintada",
            "La Plata",
            "La Playa",
            "La Primavera",
            "La Salina",
            "La Sierra",
            "La Tebaida",
            "La Tola",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Uvita",
            "La Vega",
            "La Vega",
            "La Victoria",
            "La Victoria",
            "La Victoria",
            "La Virginia",
            "Labateca",
            "Labranzagrande",
            "Landázuri",
            "Lebrija",
            "Leiva",
            "Lejanías",
            "Lenguazaque",
            "Lérida",
            "Leticia",
            "Líbano",
            "Liborina",
            "Linares",
            "Litoral del San Juan",
            "Lloró",
            "López de Micay",
            "Lorica",
            "Los Andes",
            "Los Córdobas",
            "Los Palmitos",
            "Los Patios",
            "Los Santos",
            "Lourdes",
            "Luruaco",
            "Macanal",
            "Macaravita",
            "Maceo",
            "Macheta",
            "Madrid",
            "Magangué",
            "Magüí Payán",
            "Mahates",
            "Maicao",
            "Majagual",
            "Málaga",
            "Malambo",
            "Mallama",
            "Manatí",
            "Manaure",
            "Manaure Balcón del Cesar",
            "Maní",
            "Manizales",
            "Manta",
            "Manzanares",
            "Mapiripán",
            "Mapiripana",
            "Margarita",
            "María la Baja",
            "Marinilla",
            "Maripí",
            "Marmato",
            "Marquetalia",
            "Marsella",
            "Marulanda",
            "Matanza",
            "Medellín",
            "Medina",
            "Medio Atrato",
            "Medio Baudó",
            "Medio San Juan",
            "Melgar",
            "Mercaderes",
            "Mesetas",
            "Milán",
            "Miraflores",
            "Miraflores",
            "Miranda",
            "Miriti - Paraná",
            "Mistrató",
            "Mitú",
            "Mocoa",
            "Mogotes",
            "Molagavita",
            "Momil",
            "Mompós",
            "Mongua",
            "Monguí",
            "Moniquirá",
            "Moñitos",
            "Montebello",
            "Montecristo",
            "Montelíbano",
            "Montenegro",
            "Montería",
            "Monterrey",
            "Morales",
            "Morales",
            "Morelia",
            "Morichal",
            "Morroa",
            "Mosquera",
            "Mosquera",
            "Motavita",
            "Murillo",
            "Murindó",
            "Mutatá",
            "Mutiscua",
            "Muzo",
            "Nariño",
            "Nariño",
            "Nariño",
            "Nataga",
            "Natagaima",
            "Nechí",
            "Necoclí",
            "Neira",
            "Neiva",
            "Nemocón",
            "Nilo",
            "Nimaima",
            "Nobsa",
            "Nocaima",
            "Norcasia",
            "Norosí",
            "Nóvita",
            "Nueva Granada",
            "Nuevo Colón",
            "Nunchía",
            "Nuquí",
            "Obando",
            "Ocamonte",
            "Ocaña",
            "Oiba",
            "Oicatá",
            "Olaya",
            "Olaya Herrera",
            "Onzaga",
            "Oporapa",
            "Orito",
            "Orocué",
            "Ortega",
            "Ospina",
            "Otanche",
            "Ovejas",
            "Pachavita",
            "Pacho",
            "Pacoa",
            "Pácora",
            "Padilla",
            "Páez",
            "Páez",
            "Paicol",
            "Pailitas",
            "Paime",
            "Paipa",
            "Pajarito",
            "Palermo",
            "Palestina",
            "Palestina",
            "Palmar",
            "Palmar de Varela",
            "Palmas del Socorro",
            "Palmira",
            "Palmito",
            "Palocabildo",
            "Pamplona",
            "Pamplonita",
            "Pana Pana",
            "Pandi",
            "Panqueba",
            "Papunaua",
            "Páramo",
            "Paratebueno",
            "Pasca",
            "Pasto",
            "Patía",
            "Pauna",
            "Paya",
            "Paz de Ariporo",
            "Paz de Río",
            "Pedraza",
            "Pelaya",
            "Peñol",
            "Pensilvania",
            "Peque",
            "Pereira",
            "Pesca",
            "Piamonte",
            "Piedecuesta",
            "Piedras",
            "Piendamo",
            "Pijao",
            "Pijiño del Carmen",
            "Pinchote",
            "Pinillos",
            "Piojó",
            "Pisba",
            "Pital",
            "Pitalito",
            "Pivijay",
            "Planadas",
            "Planeta Rica",
            "Plato",
            "Policarpa",
            "Polonuevo",
            "Ponedera",
            "Popayán",
            "Pore",
            "Potosí",
            "Pradera",
            "Prado",
            "Providencia",
            "Providencia",
            "Pueblo Bello",
            "Pueblo Nuevo",
            "Pueblo Rico",
            "Pueblorrico",
            "Puebloviejo",
            "Puente Nacional",
            "Puerres",
            "Puerto Alegría",
            "Puerto Arica",
            "Puerto Asís",
            "Puerto Berrío",
            "Puerto Boyacá",
            "Puerto Caicedo",
            "Puerto Carreño",
            "Puerto Colombia",
            "Puerto Colombia",
            "Puerto Concordia",
            "Puerto Escondido",
            "Puerto Gaitán",
            "Puerto Guzmán",
            "Puerto Leguízamo",
            "Puerto Libertador",
            "Puerto Lleras",
            "Puerto López",
            "Puerto Nare",
            "Puerto Nariño",
            "Puerto Parra",
            "Puerto Rico",
            "Puerto Rico",
            "Puerto Rondón",
            "Puerto Salgar",
            "Puerto Santander",
            "Puerto Santander",
            "Puerto Tejada",
            "Puerto Triunfo",
            "Puerto Wilches",
            "Pulí",
            "Pupiales",
            "Puracé",
            "Purificación",
            "Purísima",
            "Quebradanegra",
            "Quetame",
            "Quibdó",
            "Quimbaya",
            "Quinchía",
            "Quípama",
            "Quipile",
            "Ragonvalia",
            "Ramiriquí",
            "Ráquira",
            "Recetor",
            "Regidor",
            "Remedios",
            "Remolino",
            "Repelón",
            "Restrepo",
            "Restrepo",
            "Retiro",
            "Ricaurte",
            "Ricaurte",
            "Río de Oro",
            "Río Iro",
            "Río Quito",
            "Río Viejo",
            "Rioblanco",
            "Riofrío",
            "Riohacha",
            "Rionegro",
            "Rionegro",
            "Riosucio",
            "Riosucio",
            "Risaralda",
            "Rivera",
            "Roberto Payán",
            "Roldanillo",
            "Roncesvalles",
            "Rondón",
            "Rosas",
            "Rovira",
            "Sabana de Torres",
            "Sabanagrande",
            "Sabanalarga",
            "Sabanalarga",
            "Sabanalarga",
            "Sabanas de San Angel",
            "Sabaneta",
            "Saboyá",
            "Sácama",
            "Sáchica",
            "Sahagún",
            "Saladoblanco",
            "Salamina",
            "Salamina",
            "Salazar",
            "Saldaña",
            "Salento",
            "Salgar",
            "Samacá",
            "Samaná",
            "Samaniego",
            "Sampués",
            "San Agustín",
            "San Alberto",
            "San Andrés",
            "San Andrés",
            "San Andrés de Cuerquía",
            "San Andrés de Sotavento",
            "San Antero",
            "San Antonio",
            "San Antonio del Tequendama",
            "San Benito",
            "San Benito Abad",
            "San Bernardo",
            "San Bernardo",
            "San Bernardo del Viento",
            "San Calixto",
            "San Carlos",
            "San Carlos",
            "San Carlos de Guaroa",
            "San Cayetano",
            "San Cayetano",
            "San Cristóbal",
            "San Diego",
            "San Eduardo",
            "San Estanislao",
            "San Felipe",
            "San Fernando",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Gil",
            "San Jacinto",
            "San Jacinto del Cauca",
            "San Jerónimo",
            "San Joaquín",
            "San José",
            "San José de Albán",
            "San José de la Montaña",
            "San José de Miranda",
            "San José de Pare",
            "San José de Uré",
            "San José del Fragua",
            "San José del Guaviare",
            "San José del Palmar",
            "San Juan de Arama",
            "San Juan de Betulia",
            "San Juan de Río Seco",
            "San Juan de Urabá",
            "San Juan del Cesar",
            "San Juan Nepomuceno",
            "San Juanito",
            "San Lorenzo",
            "San Luis",
            "San Luis",
            "San Luis de Gaceno",
            "San Luis de Palenque",
            "San Luis de Sincé",
            "San Marcos",
            "San Martín",
            "San Martín",
            "San Martín de Loba",
            "San Mateo",
            "San Miguel",
            "San Miguel",
            "San Miguel de Sema",
            "San Onofre",
            "San Pablo",
            "San Pablo",
            "San Pablo de Borbur",
            "San Pedro",
            "San Pedro",
            "San Pedro de Cartago",
            "San Pedro de los Milagros",
            "San Pedro de Uraba",
            "San Pelayo",
            "San Rafael",
            "San Roque",
            "San Sebastián",
            "San Sebastián de Buenavista",
            "San Sebastián de Mariquita",
            "San Vicente de Chucurí",
            "San Vicente del Caguán",
            "San Vicente Ferrer",
            "San Zenón",
            "Sandoná",
            "Santa Ana",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara de Pinto",
            "Santa Catalina",
            "Santa Fe de Antioquia",
            "Santa Helena Del Opón",
            "Santa Isabel",
            "Santa Lucía",
            "Santa María",
            "Santa María",
            "Santa Marta",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa de Cabal",
            "Santa Rosa de Osos",
            "Santa Rosa de Viterbo",
            "Santa Rosa del Sur",
            "Santa Rosalia",
            "Santa Sofía",
            "Santacruz",
            "Santana",
            "Santander de Quilichao",
            "Santiago",
            "Santiago",
            "Santiago de Tolú",
            "Santo Domingo",
            "Santo Tomás",
            "Santuario",
            "Sapuyes",
            "Saravena",
            "Sardinata",
            "Sasaima",
            "Sativanorte",
            "Sativasur",
            "Segovia",
            "Sesquilé",
            "Sevilla",
            "Siachoque",
            "Sibaté",
            "Sibundoy",
            "Silos",
            "Silvania",
            "Silvia",
            "Simacota",
            "Simijaca",
            "Simití",
            "Sincelejo",
            "Sipí",
            "Sitionuevo",
            "Soacha",
            "Soatá",
            "Socha",
            "Socorro",
            "Socotá",
            "Sogamoso",
            "Solano",
            "Soledad",
            "Solita",
            "Somondoco",
            "Sonson",
            "Sopetrán",
            "Soplaviento",
            "Sopó",
            "Sora",
            "Soracá",
            "Sotaquirá",
            "Sotará",
            "Suaita",
            "Suan",
            "Suárez",
            "Suárez",
            "Suaza",
            "Subachoque",
            "Sucre",
            "Sucre",
            "Sucre",
            "Suesca",
            "Supatá",
            "Supía",
            "Suratá",
            "Susa",
            "Susacón",
            "Sutamarchán",
            "Sutatausa",
            "Sutatenza",
            "Tabio",
            "Tadó",
            "Talaigua Nuevo",
            "Tamalameque",
            "Támara",
            "Tame",
            "Támesis",
            "Taminango",
            "Tangua",
            "Taraira",
            "Tarapacá",
            "Tarazá",
            "Tarqui",
            "Tarso",
            "Tasco",
            "Tauramena",
            "Tausa",
            "Tello",
            "Tena",
            "Tenerife",
            "Tenjo",
            "Tenza",
            "Teorama",
            "Teruel",
            "Tesalia",
            "Tibacuy",
            "Tibaná",
            "Tibasosa",
            "Tibirita",
            "Tibú",
            "Tierralta",
            "Timana",
            "Timbío",
            "Timbiquí",
            "Tinjacá",
            "Tipacoque",
            "Tiquisio",
            "Titiribí",
            "Toca",
            "Tocaima",
            "Tocancipá",
            "Togüí",
            "Toledo",
            "Toledo",
            "Tolú Viejo",
            "Tona",
            "Tópaga",
            "Topaipí",
            "Toribio",
            "Toro",
            "Tota",
            "Totoró",
            "Trinidad",
            "Trujillo",
            "Tubará",
            "Tuchín",
            "Tuluá",
            "Tumaco",
            "Tunja",
            "Tununguá",
            "Túquerres",
            "Turbaco",
            "Turbaná",
            "Turbo",
            "Turmequé",
            "Tuta",
            "Tutazá",
            "Ubalá",
            "Ubaque",
            "Ulloa",
            "Umbita",
            "Une",
            "Unguía",
            "Unión Panamericana",
            "Uramita",
            "Uribe",
            "Uribia",
            "Urrao",
            "Urumita",
            "Usiacurí",
            "Útica",
            "Valdivia",
            "Valencia",
            "Valle de San José",
            "Valle de San Juan",
            "Valle del Guamuez",
            "Valledupar",
            "Valparaíso",
            "Valparaíso",
            "Vegachí",
            "Vélez",
            "Venadillo",
            "Venecia",
            "Venecia",
            "Ventaquemada",
            "Vergara",
            "Versalles",
            "Vetas",
            "Vianí",
            "Victoria",
            "Vigía del Fuerte",
            "Vijes",
            "Villa Caro",
            "Villa de Leyva",
            "Villa de San Diego de Ubate",
            "Villa del Rosario",
            "Villa Rica",
            "Villagarzón",
            "Villagómez",
            "Villahermosa",
            "Villamaría",
            "Villanueva",
            "Villanueva",
            "Villanueva",
            "Villanueva",
            "Villapinzón",
            "Villarrica",
            "Villavicencio",
            "Villavieja",
            "Villeta",
            "Viotá",
            "Viracachá",
            "Vistahermosa",
            "Viterbo",
            "Yacopí",
            "Yacuanquer",
            "Yaguará",
            "Yalí",
            "Yarumal",
            "Yavaraté",
            "Yolombó",
            "Yondó",
            "Yopal",
            "Yotoco",
            "Yumbo",
            "Zambrano",
            "Zapatoca",
            "Zapayán",
            "Zaragoza",
            "Zarzal",
            "Zetaquira",
            "Zipacón",
            "Zipaquirá",
            "Zona Bananera"
        ]
    },
    {
        "label": "Comoros",
        "code": "KM",
        "phone": "269",
        "cities": [
            "Adda-Douéni",
            "Antsahé",
            "Assimpao",
            "Bahani",
            "Bambadjani",
            "Bambao",
            "Bandajou",
            "Barakani",
            "Bimbini",
            "Boungouéni",
            "Bouni",
            "Chandra",
            "Chezani",
            "Chindini",
            "Chironkamba",
            "Chitrouni",
            "Chouani",
            "Daji",
            "Dembéni",
            "Djoyézi",
            "Domoni",
            "Douniani",
            "Dzahadjou",
            "Dziani",
            "Fomboni",
            "Foumbouni",
            "Hajoho",
            "Hantsindzi",
            "Harembo",
            "Héroumbili",
            "Hoani",
            "Itsandra",
            "Itsandzéni",
            "Ivouani",
            "Kangani",
            "Kavani",
            "Koki",
            "Koni-Djodjo",
            "Koni-Ngani",
            "Koua",
            "Kyo",
            "Limbi",
            "Lingoni",
            "Madjeouéni",
            "Magnassini-Nindri",
            "Mandza",
            "Maraharé",
            "Mavingouni",
            "Mbéni",
            "Mirontsi",
            "Mitsamiouli",
            "Mitsoudjé",
            "Mjamaoué",
            "Mjimandra",
            "Mnoungou",
            "Mohoro",
            "Moroni",
            "Moutsamoudou",
            "Moya",
            "Mramani",
            "Mrémani",
            "Mtakoudja",
            "Mtsamdou",
            "Mvouni",
            "Nioumachoua",
            "Nioumamilima",
            "Ntsaouéni",
            "Ntsoudjini",
            "Ongoni",
            "Ouanani",
            "Ouani",
            "Ouellah",
            "Ouhozi",
            "Ourovéni",
            "Oussivo",
            "Ouzini",
            "Pajé",
            "Patsi",
            "Salimani",
            "Séléa",
            "Sima",
            "Singani",
            "Tsidjé",
            "Tsimbeo",
            "Vanadjou",
            "Vanambouani",
            "Vouani",
            "Ziroudani"
        ]
    },
    {
        "label": "Congo",
        "code": "CG",
        "phone": "242",
        "cities": [
            "Brazzaville",
            "Djambala",
            "Dolisie",
            "Ewo",
            "Gamboma",
            "Impfondo",
            "Kayes",
            "Kinkala",
            "Loandjili",
            "Madingou",
            "Makoua",
            "Mossendjo",
            "Ouésso",
            "Owando",
            "Pointe-Noire",
            "Sémbé",
            "Sibiti"
        ]
    },
    {
        "label": "Cook Islands",
        "code": "CK",
        "phone": "682",
        "cities": []
    },
    {
        "label": "Costa Rica",
        "code": "CR",
        "phone": "506",
        "cities": [
            "Abangares",
            "Acosta",
            "Alajuela",
            "Alajuelita",
            "Alvarado",
            "Ángeles",
            "Aserrí",
            "Atenas",
            "Bagaces",
            "Barva",
            "Batán",
            "Belén",
            "Belén",
            "Bijagua",
            "Buenos Aires",
            "Calle Blancos",
            "Cañas",
            "Canoas",
            "Carrillo",
            "Carrillos",
            "Cartago",
            "Chacarita",
            "Ciudad Cortés",
            "Colima",
            "Colón",
            "Concepción",
            "Corredor",
            "Corredores",
            "Cot",
            "Coto Brus",
            "Curridabat",
            "Daniel Flores",
            "Desamparados",
            "Desamparados",
            "Dota",
            "El Guarco",
            "Escazú",
            "Esparza",
            "Esquipulas",
            "Flores",
            "Fortuna",
            "Garabito",
            "Goicoechea",
            "Golfito",
            "Granadilla",
            "Grecia",
            "Guácimo",
            "Guadalupe",
            "Guápiles",
            "Guatuso",
            "Heredia",
            "Hojancha",
            "Ipís",
            "Jacó",
            "Jiménez",
            "Juntas",
            "La Asunción",
            "La Cruz",
            "La Fortuna",
            "La Suiza",
            "La Unión",
            "León Cortés",
            "Liberia",
            "Limón",
            "Llorente",
            "Los Chiles",
            "Matina",
            "Mercedes",
            "Mercedes",
            "Miramar",
            "Montes de Oca",
            "Montes de Oro",
            "Mora",
            "Moravia",
            "Nandayure",
            "Naranjo",
            "Nicoya",
            "Oreamuno",
            "Orosí",
            "Orotina",
            "Osa",
            "Pacayas",
            "Palmares",
            "Palmichal",
            "Paquera",
            "Paraíso",
            "Parrita",
            "Patarrá",
            "Pejibaye",
            "Pérez Zeledón",
            "Pital",
            "Poás",
            "Pococí",
            "Pocora",
            "Pocosol",
            "Puntarenas",
            "Puriscal",
            "Purral",
            "Quepos",
            "Quesada",
            "Río Segundo",
            "Roxana",
            "Sabanilla",
            "Sabanilla",
            "Salitral",
            "Salitrillos",
            "Sámara",
            "San Antonio",
            "San Carlos",
            "San Diego",
            "San Felipe",
            "San Francisco",
            "San Ignacio",
            "San Isidro",
            "San Isidro",
            "San José",
            "San José",
            "San Josecito",
            "San Juan",
            "San Juan",
            "San Juan de Dios",
            "San Marcos",
            "San Mateo",
            "San Miguel",
            "San Pablo",
            "San Pedro",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael Abajo",
            "San Rafael Arriba",
            "San Ramón",
            "San Vicente",
            "San Vicente de Moravia",
            "San Vito",
            "Santa Ana",
            "Santa Bárbara",
            "Santa Cruz",
            "Santiago",
            "Santiago",
            "Santo Domingo",
            "Sarapiquí",
            "Sardinal",
            "Siquirres",
            "Sixaola",
            "Talamanca",
            "Tarrazú",
            "Tejar",
            "Tibás",
            "Tilarán",
            "Tobosi",
            "Tres Ríos",
            "Tucurrique",
            "Turrialba",
            "Turrubares",
            "Upala",
            "Valverde Vega",
            "Vázquez de Coronado",
            "Zarcero"
        ]
    },
    {
        "label": "Cote D'Ivoire (Ivory Coast)",
        "code": "CI",
        "phone": "225",
        "cities": [
            "Abengourou",
            "Abidjan",
            "Abobo",
            "Aboisso",
            "Adiaké",
            "Adzopé",
            "Affery",
            "Agboville",
            "Agnéby-Tiassa",
            "Agnibilékrou",
            "Akoupé",
            "Anyama",
            "Arrah",
            "Ayamé",
            "Bafing",
            "Bangolo",
            "Bélier",
            "Béoumi",
            "Béré",
            "Biankouma",
            "Bingerville",
            "Bocanda",
            "Bondoukou",
            "Bongouanou",
            "Bonoua",
            "Botro",
            "Bouaflé",
            "Bouaké",
            "Bouna",
            "Bounkani",
            "Cavally",
            "Dabakala",
            "Dabou",
            "Daloa",
            "Danané",
            "Daoukro",
            "Dimbokro",
            "Divo",
            "Duekoué",
            "Folon",
            "Gagnoa",
            "Gbêkê",
            "Gbôklé",
            "Gôh",
            "Gontougo",
            "Grand-Bassam",
            "Grand-Lahou",
            "Grands-Ponts",
            "Guémon",
            "Guibéroua",
            "Guiglo",
            "Hambol",
            "Haut-Sassandra",
            "Iffou",
            "Indénié-Djuablin",
            "Issia",
            "Kabadougou",
            "Katiola",
            "Lakota",
            "Lôh-Djiboua",
            "Man",
            "Mankono",
            "Marahoué",
            "Moronou",
            "N'Zi",
            "Nawa",
            "Odienné",
            "Oumé",
            "Sakassou",
            "San-Pédro",
            "Sassandra",
            "Séguéla",
            "Sinfra",
            "Sud-Comoé",
            "Tabou",
            "Tanda",
            "Tiassalé",
            "Tonkpi",
            "Touba",
            "Toulépleu Gueré",
            "Toumodi",
            "Vavoua",
            "Worodougou",
            "Yamoussoukro",
            "Zuénoula"
        ]
    },
    {
        "label": "Croatia",
        "code": "HR",
        "phone": "385",
        "cities": [
            "Andrijaševci",
            "Antunovac",
            "Babina Greda",
            "Bakar",
            "Bale",
            "Bale-Valle",
            "Banjol",
            "Baška",
            "Baška Voda",
            "Batrina",
            "Bedekovčina",
            "Beli Manastir",
            "Belica",
            "Belišće",
            "Benkovac",
            "Beretinec",
            "Bestovje",
            "Bibinje",
            "Bijelo Brdo",
            "Bilje",
            "Biograd na Moru",
            "Bistra",
            "Bistrinci",
            "Bizovac",
            "Bjelovar",
            "Bobota",
            "Bogdanovci",
            "Bol",
            "Borovo",
            "Borovo Selo",
            "Bošnjaci",
            "Brckovljani",
            "Brdovec",
            "Bregana",
            "Brela",
            "Brestovac",
            "Breznica",
            "Breznički Hum",
            "Brezovac",
            "Brezovica",
            "Bribir",
            "Brijest",
            "Brinje",
            "Brnaze",
            "Brodarica",
            "Brodski Varoš",
            "Bršadin",
            "Brtonigla",
            "Brtonigla-Verteneglio",
            "Budaševo",
            "Budinščina",
            "Budrovci",
            "Buje",
            "Buje-Buie",
            "Bukovlje",
            "Buzdohanj",
            "Buzet",
            "Čačinci",
            "Čađavica",
            "Čakovec",
            "Čavle",
            "Cavtat",
            "Čazma",
            "Čeminac",
            "Centar",
            "Čepin",
            "Cerić",
            "Cerna",
            "Cernik",
            "Cernik",
            "Cestica",
            "Čibača",
            "Cres",
            "Crikvenica",
            "Crnac",
            "Dalj",
            "Darda",
            "Daruvar",
            "Davor",
            "Dekanovec",
            "Delnice",
            "Dežanovac",
            "Dicmo",
            "Domašinec",
            "Donja Bistra",
            "Donja Lomnica",
            "Donja Zdenčina",
            "Donje Ladanje",
            "Donji Andrijevci",
            "Donji Miholjac",
            "Donji Stupnik",
            "Donji Vinjani",
            "Draž",
            "Dražice",
            "Drenova",
            "Drenovci",
            "Drniš",
            "Drnje",
            "Dubrava",
            "Dubrovačko primorje",
            "Duće",
            "Dugi Rat Općina",
            "Dugopolje",
            "Dvor",
            "Đakovo",
            "Đelekovec",
            "Đulovac",
            "Đurđenovac",
            "Đurđevac",
            "Đurmanec",
            "Erdut",
            "Ernestinovo",
            "Farkaševac",
            "Fažana",
            "Fažana-Fasana",
            "Ferdinandovac",
            "Feričanci",
            "Funtana",
            "Funtana-Fontane",
            "Fužine",
            "Gala",
            "Galižana",
            "Galovac",
            "Garčin",
            "Garešnica",
            "Glina",
            "Gola",
            "Goričan",
            "Gorjani",
            "Gornja Bistra",
            "Gornja Rijeka",
            "Gornje Vratno",
            "Gornji Bogićevci",
            "Gornji Karin",
            "Gornji Kneginec",
            "Gospić",
            "Grabovci",
            "Gračac",
            "Gračec",
            "Grad Beli Manastir",
            "Grad Biograd na Moru",
            "Grad Bjelovar",
            "Grad Buzet",
            "Grad Čabar",
            "Grad Čakovec",
            "Grad Čazma",
            "Grad Crikvenica",
            "Grad Daruvar",
            "Grad Delnice",
            "Grad Donja Stubica",
            "Grad Donji Miholjac",
            "Grad Drniš",
            "Grad Dubrovnik",
            "Grad Dugo Selo",
            "Grad Garešnica",
            "Grad Glina",
            "Grad Grubišno Polje",
            "Grad Hrvatska Kostajnica",
            "Grad Hvar",
            "Grad Imotski",
            "Grad Ivanec",
            "Grad Jastrebarsko",
            "Grad Klanjec",
            "Grad Koprivnica",
            "Grad Korčula",
            "Grad Krapina",
            "Grad Križevci",
            "Grad Krk",
            "Grad Kutina",
            "Grad Labin",
            "Grad Ludbreg",
            "Grad Makarska",
            "Grad Našice",
            "Grad Nova Gradiška",
            "Grad Novi Marof",
            "Grad Novska",
            "Grad Omiš",
            "Grad Opatija",
            "Grad Orahovica",
            "Grad Osijek",
            "Grad Pakrac",
            "Grad Pazin",
            "Grad Petrinja",
            "Grad Ploče",
            "Grad Požega",
            "Grad Rijeka",
            "Grad Samobor",
            "Grad Šibenik",
            "Grad Sinj",
            "Grad Sisak",
            "Grad Slatina",
            "Grad Slavonski Brod",
            "Grad Split",
            "Grad Supetar",
            "Grad Sveti Ivan Zelina",
            "Grad Trogir",
            "Grad Valpovo",
            "Grad Varaždin",
            "Grad Velika Gorica",
            "Grad Vinkovci",
            "Grad Virovitica",
            "Grad Vis",
            "Grad Vrbovec",
            "Grad Vrbovsko",
            "Grad Vrgorac",
            "Grad Vukovar",
            "Grad Zabok",
            "Grad Zaprešić",
            "Grad Zlatar",
            "Grad Županja",
            "Gradac",
            "Gradec",
            "Gradići",
            "Gradina",
            "Gradište",
            "Gradska četvrt Donji grad",
            "Gradska četvrt Gornji Grad - Medvescak",
            "Gradska četvrt Podsljeme",
            "Greda",
            "Grožnjan",
            "Grožnjan-Grisignana",
            "Grubine",
            "Grubišno Polje",
            "Gudovac",
            "Gundinci",
            "Gunja",
            "Gvozd",
            "Hercegovac",
            "Hlebine",
            "Hodošan",
            "Horvati",
            "Hrašćica",
            "Hreljin",
            "Hrvace",
            "Hrvatska Kostajnica",
            "Hvar",
            "Ilok",
            "Imotski",
            "Ivanec",
            "Ivankovo",
            "Ivanovec",
            "Ivanska",
            "Jablanovec",
            "Jadranovo",
            "Jagodnjak",
            "Jakovlje",
            "Jakšić",
            "Jalkovec",
            "Jalžabet",
            "Jankomir",
            "Jarmina",
            "Jasenice",
            "Jastrebarsko",
            "Jelisavac",
            "Jelsa",
            "Jesenice",
            "Jesenje",
            "Ježdovec",
            "Josipovac",
            "Kali",
            "Kalinovac",
            "Kamen",
            "Kampor",
            "Kanfanar",
            "Kapela",
            "Kaptol",
            "Karanac",
            "Karlobag",
            "Karojba",
            "Kašina",
            "Kastav",
            "Kaštel Gomilica",
            "Kaštel Kambelovac",
            "Kaštel Lukšić",
            "Kaštel Novi",
            "Kaštel Štafilić",
            "Kaštel Stari",
            "Kaštela",
            "Kaštelir-Labinci",
            "Kerestinec",
            "Kistanje",
            "Klana",
            "Klanjec",
            "Klenovnik",
            "Klis",
            "Kneževi Vinogradi",
            "Knin",
            "Komin",
            "Komiža",
            "Komletinci",
            "Konavle",
            "Končanica",
            "Konjščina",
            "Koprivnica",
            "Koprivnički Ivanec",
            "Korčula",
            "Korenica",
            "Koška",
            "Košute",
            "Kotoriba",
            "Kraljevica",
            "Krapina",
            "Krasica",
            "Križ",
            "Križevci",
            "Krk",
            "Kruševica",
            "Kruševo",
            "Kućan Marof",
            "Kuče",
            "Kumrovec",
            "Kuršanec",
            "Kuševac",
            "Kutina",
            "Kutjevo",
            "Labin",
            "Ladimirevci",
            "Laslovo",
            "Lastovo",
            "Legrad",
            "Lekenik",
            "Lepoglava",
            "Lički Osik",
            "Lipik",
            "Lipovljani",
            "Ližnjan",
            "Ližnjan-Lisignano",
            "Ljubešćica",
            "Lokvičići",
            "Lonjica",
            "Lopar",
            "Lopatinec",
            "Lovas",
            "Lovran",
            "Lovreć",
            "Lučko",
            "Ludbreg",
            "Luka",
            "Lukavec",
            "Lumbarda",
            "Lupoglav",
            "Lupoglav",
            "Lužani",
            "Mače",
            "Mačkovec",
            "Magadenovac",
            "Makarska",
            "Mala Subotica",
            "Mali Lošinj",
            "Malinska-Dubašnica",
            "Marčana",
            "Marčelji",
            "Marija Bistrica",
            "Marijanci",
            "Marinići",
            "Marjanci",
            "Markovac Našički",
            "Markušica",
            "Martin",
            "Martinska Ves",
            "Matulji",
            "Medulin",
            "Metković",
            "Mičevec",
            "Mihotići",
            "Mihovljan",
            "Mihovljan",
            "Mikleuš",
            "Milna",
            "Mirkovci",
            "Mljet",
            "Mokošica",
            "Molve",
            "Motovun",
            "Motovun-Montona",
            "Mraclin",
            "Mravince",
            "Mrkopalj",
            "Muć",
            "Mursko Središće",
            "Murter",
            "Murter-Kornati",
            "Našice",
            "Nedelišće",
            "Nedeljanec",
            "Negoslavci",
            "Nerežišća",
            "Nijemci",
            "Nin",
            "Njivice",
            "Nova Bukovica",
            "Nova Gradiška",
            "Novalja",
            "Novi Vinodolski",
            "Novi Zagreb",
            "Novigrad",
            "Novigrad Općina",
            "Novigrad-Cittanova",
            "Novo Čiče",
            "Novo Selo Rok",
            "Novo Virje",
            "Novoselec",
            "Novska",
            "Nuštar",
            "Obrovac",
            "Odra",
            "Okrug",
            "Okrug Gornji",
            "Okučani",
            "Omiš",
            "Omišalj",
            "Opatija",
            "Općina Dubrava",
            "Općina Dvor",
            "Općina Gvozd",
            "Općina Lanišće",
            "Općina Lastovo",
            "Oprisavci",
            "Oprtalj-Portole",
            "Opuzen",
            "Orahovica",
            "Orebić",
            "Orehovica",
            "Orešje",
            "Oriovac",
            "Oroslavje",
            "Osijek",
            "Osljak",
            "Otočac",
            "Otok",
            "Otok",
            "Pag",
            "Pakoštane",
            "Pakrac",
            "Pazin",
            "Peklenica",
            "Perušić",
            "Peteranec",
            "Petlovac",
            "Petrijanec",
            "Petrijevci",
            "Petrinja",
            "Pirovac",
            "Piškorevci",
            "Pitomača",
            "Pleternica",
            "Plitvička Jezera",
            "Podgora",
            "Podgorač",
            "Podhum",
            "Podravska Moslavina",
            "Podstrana",
            "Podturen",
            "Podvinje",
            "Pojatno",
            "Pojezerje",
            "Polača",
            "Poličnik",
            "Popovača",
            "Popovača",
            "Poreč",
            "Poreč-Parenzo",
            "Posedarje",
            "Postira",
            "Požega",
            "Predavac",
            "Pregrada",
            "Preko",
            "Prelog",
            "Preseka",
            "Pribislavec",
            "Pridraga",
            "Prigorje Brdovečko",
            "Primošten",
            "Privlaka",
            "Privlaka",
            "Proložac",
            "Promina",
            "Pučišća",
            "Pula",
            "Pula-Pola",
            "Punat",
            "Pušća",
            "Rab",
            "Rabac",
            "Radoboj",
            "Rakitje",
            "Rakov Potok",
            "Raša",
            "Rasinja",
            "Ražanac",
            "Reka",
            "Remetinec",
            "Repušnica",
            "Rešetari",
            "Retkovci",
            "Rezovac",
            "Rijeka",
            "Rogoznica",
            "Rogoznica Općina",
            "Rokovci",
            "Rovinj",
            "Rovinj-Rovigno",
            "Rovišće",
            "Rubeši",
            "Rude",
            "Runović",
            "Runovići",
            "Ruščica",
            "Sali",
            "Samobor",
            "Šandrovac",
            "Sarvaš",
            "Satnica Đakovačka",
            "Seget",
            "Seget Vranjica",
            "Selca",
            "Selce",
            "Semeljci",
            "Senj",
            "Šenkovec",
            "Sesvete",
            "Severin",
            "Šibenik",
            "Sibinj",
            "Sigetec",
            "Sikirevci",
            "Sinj",
            "Sirač",
            "Široko Polje",
            "Sisak",
            "Škabrnja",
            "Skrad",
            "Skradin",
            "Škrljevo",
            "Slatina",
            "Slatine",
            "Slavonski Brod",
            "Slivno",
            "Slobodnica",
            "Smokvica",
            "Šodolovci",
            "Solin",
            "Soljani",
            "Šolta",
            "Sopje",
            "Špišić Bukovica",
            "Split",
            "Sračinec",
            "Srinjine",
            "Stari Grad",
            "Stari Grad",
            "Stari Jankovci",
            "Stari Perkovci",
            "Starigrad",
            "Stenjevec",
            "Štitar",
            "Stobreč",
            "Ston",
            "Strahoninec",
            "Strizivojna",
            "Strmec",
            "Stubičke Toplice",
            "Stupnik",
            "Suhopolje",
            "Sukošan",
            "Sunja",
            "Supetar",
            "Supetarska Draga",
            "Sveta Nedelja",
            "Sveta Nedelja",
            "Sveta Nedjelja",
            "Sveti Đurđ",
            "Sveti Filip i Jakov",
            "Sveti Juraj na Bregu",
            "Sveti Križ Začretje",
            "Sveti Lovreč",
            "Tar",
            "Tar-Vabriga-Torre Abrega",
            "Tenja",
            "Tisno",
            "Tkon",
            "Tordinci",
            "Tovarnik",
            "Tribunj",
            "Trilj",
            "Trogir",
            "Trpinja",
            "Tučepi",
            "Turanj",
            "Turjaci",
            "Tužno",
            "Ugljan",
            "Umag",
            "Umag-Umago",
            "Valbandon",
            "Valpovo",
            "Varaždin",
            "Vela Luka",
            "Velika",
            "Velika Gorica",
            "Velika Kopanica",
            "Velika Mlaka",
            "Velika Ostrna",
            "Velika Pisanica",
            "Veliki Drvenik",
            "Veliki Grđevac",
            "Velimirovac",
            "Vidovci",
            "Vidovec",
            "Viljevo",
            "Vinež",
            "Vinica",
            "Vinkovci",
            "Vinodolska općina",
            "Vir",
            "Virje",
            "Virovitica",
            "Vis",
            "Viškovci",
            "Viškovo",
            "Višnjan-Visignano",
            "Višnjevac",
            "Vižinada-Visinada",
            "Vladislavci",
            "Voćin",
            "Vodice",
            "Vodnjan",
            "Vodnjan-Dignano",
            "Vođinci",
            "Voloder",
            "Vranjic",
            "Vratišinec",
            "Vrbanja",
            "Vrbnik",
            "Vrbovec",
            "Vrbovsko",
            "Vrgorac",
            "Vrlika",
            "Vrpolje",
            "Vrsar",
            "Vrsar-Orsera",
            "Vrsi",
            "Vuka",
            "Vukovar",
            "Zabok",
            "Zadar",
            "Zadvorsko",
            "Zagreb",
            "Zagvozd",
            "Zaprešić",
            "Zažablje",
            "Zdenci",
            "Zdenci Brdovečki",
            "Ždralovi",
            "Zlatar",
            "Zlatar Bistrica",
            "Zmijavci",
            "Žminj",
            "Zrinski Topolovac",
            "Žrnovnica",
            "Žrnovo",
            "Župa dubrovačka",
            "Županja"
        ]
    },
    {
        "label": "Cuba",
        "code": "CU",
        "phone": "53",
        "cities": [
            "Abreus",
            "Aguada de Pasajeros",
            "Alacranes",
            "Alamar",
            "Alquízar",
            "Amancio",
            "Arroyo Naranjo",
            "Artemisa",
            "Bahía Honda",
            "Banes",
            "Baracoa",
            "Baraguá",
            "Bartolomé Masó",
            "Batabanó",
            "Bauta",
            "Bayamo",
            "Bejucal",
            "Bolondrón",
            "Boyeros",
            "Cabaiguán",
            "Cabañas",
            "Cacocum",
            "Caibarién",
            "Calabazar de Sagua",
            "Calimete",
            "Camagüey",
            "Camajuaní",
            "Campechuela",
            "Candelaria",
            "Cárdenas",
            "Cauto Cristo",
            "Centro Habana",
            "Cerro",
            "Chambas",
            "Ciego de Ávila",
            "Cienfuegos",
            "Cifuentes",
            "Ciro Redondo",
            "Colombia",
            "Colón",
            "Condado",
            "Consolación del Sur",
            "Contramaestre",
            "Corralillo",
            "Cruces",
            "Cueto",
            "Cumanayagua",
            "Diez de Octubre",
            "El Caney",
            "El Cobre",
            "Encrucijada",
            "Esmeralda",
            "Esperanza",
            "Florencia",
            "Florida",
            "Fomento",
            "Gibara",
            "Guáimaro",
            "Guanabacoa",
            "Guanajay",
            "Guane",
            "Guantánamo",
            "Guayos",
            "Güines",
            "Güira de Melena",
            "Guisa",
            "Habana del Este",
            "Havana",
            "Holguín",
            "Isabela de Sagua",
            "Jagüey Grande",
            "Jamaica",
            "Jaruco",
            "Jatibonico",
            "Jesús Menéndez",
            "Jiguaní",
            "Jimaguayú",
            "Jobabo",
            "Jobabo",
            "Jovellanos",
            "La Habana Vieja",
            "La Salud",
            "La Sierpe",
            "Lajas",
            "Las Tunas",
            "Limonar",
            "Los Arabos",
            "Los Palacios",
            "Madruga",
            "Maisí",
            "Manatí",
            "Manguito",
            "Manicaragua",
            "Mantua",
            "Manzanillo",
            "Mariel",
            "Martí",
            "Matanzas",
            "Media Luna",
            "Minas",
            "Minas de Matahambre",
            "Moa",
            "Morón",
            "Municipio de Abreus",
            "Municipio de Artemisa",
            "Municipio de Banes",
            "Municipio de Bauta",
            "Municipio de Bayamo",
            "Municipio de Cabaiguán",
            "Municipio de Cárdenas",
            "Municipio de Ciego de Ávila",
            "Municipio de Cienfuegos",
            "Municipio de Consolación del Sur",
            "Municipio de Florida",
            "Municipio de Guanajay",
            "Municipio de Guane",
            "Municipio de Guantánamo",
            "Municipio de Güines",
            "Municipio de Holguín",
            "Municipio de Jatibonico",
            "Municipio de La Palma",
            "Municipio de Los Palacios",
            "Municipio de Manzanillo",
            "Municipio de Mariel",
            "Municipio de Matanzas",
            "Municipio de Melena del Sur",
            "Municipio de Morón",
            "Municipio de Niquero",
            "Municipio de Nuevitas",
            "Municipio de Palma Soriano",
            "Municipio de Placetas",
            "Municipio de San Cristóbal",
            "Municipio de Sancti Spíritus",
            "Municipio de Santa Clara",
            "Municipio de Santiago de Cuba",
            "Municipio de Trinidad",
            "Niquero",
            "Nueva Gerona",
            "Nuevitas",
            "Palma Soriano",
            "Palmira",
            "Pedro Betancourt",
            "Perico",
            "Pinar del Río",
            "Placetas",
            "Primero de Enero",
            "Puerto Esperanza",
            "Puerto Padre",
            "Quemado de Güines",
            "Quivicán",
            "Rancho Veloz",
            "Ranchuelo",
            "Regla",
            "Río Cauto",
            "Río Guayabal de Yateras",
            "Rodas",
            "Sagua de Tánamo",
            "Sagua la Grande",
            "San Antonio de los Baños",
            "San Antonio del Sur",
            "San Cristobal",
            "San Diego de Los Baños",
            "San Germán",
            "San José de las Lajas",
            "San Luis",
            "San Luis",
            "San Miguel del Padrón",
            "San Nicolás de Bari",
            "Sancti Spíritus",
            "Santa Clara",
            "Santa Cruz del Norte",
            "Santa Cruz del Sur",
            "Santiago de Cuba",
            "Santiago de las Vegas",
            "Santo Domingo",
            "Sibanicú",
            "Soroa",
            "Topes de Collantes",
            "Trinidad",
            "Unión de Reyes",
            "Varadero",
            "Venezuela",
            "Vertientes",
            "Viñales",
            "Yaguajay",
            "Yara",
            "Zaza del Medio"
        ]
    },
    {
        "label": "Curaçao",
        "code": "CW",
        "phone": "599",
        "cities": []
    },
    {
        "label": "Cyprus",
        "code": "CY",
        "phone": "357",
        "cities": [
            "Acherítou",
            "Áchna",
            "Ágios Tomás",
            "Ágios Týchon",
            "Akáki",
            "Alámpra",
            "Ammochostos Municipality",
            "Aradíppou",
            "Aredioú",
            "Argáka",
            "Astromerítis",
            "Athíenou",
            "Avgórou",
            "Ayia Napa",
            "Chlórakas",
            "Dáli",
            "Derýneia",
            "Dhromolaxia",
            "Emba",
            "Ergátes",
            "Erími",
            "Famagusta",
            "Frénaros",
            "Géri",
            "Germasógeia",
            "Geroskipou",
            "Geroskípou (quarter)",
            "Geroskípou Municipality",
            "Kakopetriá",
            "Káto Defterá",
            "Káto Pýrgos",
            "Kissonerga",
            "Kíti",
            "Klírou",
            "Kofínou",
            "Kokkinotrimithiá",
            "Koloni",
            "Kolossi",
            "Konia",
            "Kórnos",
            "Kyperoúnta",
            "Kyrenia",
            "Kyrenia Municipality",
            "Lápithos",
            "Larnaca",
            "Léfka",
            "Lefkónoiko",
            "Lemesós",
            "Leonárisso",
            "Limassol",
            "Liopétri",
            "Livádia",
            "Lýmpia",
            "Lythrodóntas",
            "Mámmari",
            "Meneou",
            "Méniko",
            "Mesógi",
            "Mórfou",
            "Mosfilotí",
            "Mouttagiáka",
            "Nicosia",
            "Nicosia Municipality",
            "Páchna",
            "Páno Defterá",
            "Páno Polemídia",
            "Paphos",
            "Paralímni",
            "Parekklisha",
            "Pégeia",
            "Peléndri",
            "Péra",
            "Pérgamos",
            "Peristeróna",
            "Perivólia",
            "Pissoúri",
            "Pólis",
            "Protaras",
            "Psevdás",
            "Psimolofou",
            "Pýla",
            "Pyrgos",
            "Rizokárpaso",
            "Sotíra",
            "Soúni-Zanakiá",
            "Tála",
            "Tersefánou",
            "Tríkomo",
            "Troúlloi",
            "Tsáda",
            "Tséri",
            "Voróklini",
            "Xylofágou",
            "Xylotymbou",
            "Ýpsonas"
        ]
    },
    {
        "label": "Czech Republic",
        "code": "CZ",
        "phone": "420",
        "cities": [
            "Abertamy",
            "Adamov",
            "Albrechtice",
            "Albrechtice nad Orlicí",
            "Aš",
            "Babice",
            "Bakov nad Jizerou",
            "Bánov",
            "Bartošovice",
            "Baška",
            "Batelov",
            "Bavorov",
            "Bechlín",
            "Bechyně",
            "Bečov",
            "Bedihošť",
            "Bělá nad Radbuzou",
            "Bělá pod Bezdězem",
            "Bělotín",
            "Benátky nad Jizerou",
            "Benecko",
            "Benešov",
            "Benešov nad Černou",
            "Benešov nad Ploučnicí",
            "Bernartice",
            "Beroun",
            "Bezdružice",
            "Bílá Lhota",
            "Bílá Třemešná",
            "Bílina",
            "Bílina Kyselka",
            "Bílovec",
            "Bílovice",
            "Bílovice nad Svitavou",
            "Blansko",
            "Blatná",
            "Blažovice",
            "Blížejov",
            "Blížkovice",
            "Blovice",
            "Blučina",
            "Bludov",
            "Bochov",
            "Bohdalov",
            "Bohumín",
            "Bohuňovice",
            "Bohuslavice",
            "Bohušovice nad Ohří",
            "Bohutín",
            "Bojkovice",
            "Bolatice",
            "Bor",
            "Borek",
            "Bořetice",
            "Bořitov",
            "Borohrádek",
            "Borová Lada",
            "Borovany",
            "Boršice",
            "Boskovice",
            "Bošovice",
            "Bouzov",
            "Božice",
            "Braňany",
            "Brandýs nad Labem-Stará Boleslav",
            "Brandýs nad Orlicí",
            "Brandýsek",
            "Braník",
            "Brantice",
            "Břasy",
            "Břeclav",
            "Březí",
            "Březnice",
            "Březnice",
            "Březno",
            "Březolupy",
            "Březová",
            "Březová",
            "Březová",
            "Březová nad Svitavou",
            "Břidličná",
            "Brloh",
            "Brněnec",
            "Brniště",
            "Brno",
            "Brodek u Přerova",
            "Broumov",
            "Brtnice",
            "Brumovice",
            "Bruntál",
            "Brušperk",
            "Buchlovice",
            "Bučovice",
            "Budišov",
            "Budišov nad Budišovkou",
            "Budyně nad Ohří",
            "Bukovany",
            "Bukovec",
            "Buštěhrad",
            "Byšice",
            "Býšť",
            "Bystřany",
            "Bystré",
            "Bystřec",
            "Bystřice",
            "Bystřice",
            "Bystřice nad Pernštejnem",
            "Bystřice pod Hostýnem",
            "Bzenec",
            "Čáslav",
            "Častolovice",
            "Čebín",
            "Čechtice",
            "Čejč",
            "Čejkovice",
            "Čeladná",
            "Čelákovice",
            "Čelechovice na Hané",
            "Čerčany",
            "Cerhenice",
            "Černá Hora",
            "Černčice",
            "Černilov",
            "Černošice",
            "Černošín",
            "Černovice",
            "Černožice",
            "Černý Most",
            "Červená Voda",
            "Červené Pečky",
            "Červenka",
            "Červený Kostelec",
            "Česká Kamenice",
            "Česká Lípa",
            "Česká Skalice",
            "Česká Třebová",
            "Česká Ves",
            "České Budějovice",
            "České Meziříčí",
            "České Velenice",
            "Český Brod",
            "Český Dub",
            "Český Krumlov",
            "Český Rudolec",
            "Český Těšín",
            "Chabařovice",
            "Cheb",
            "Chlebičov",
            "Chlum u Třeboně",
            "Chlumčany",
            "Chlumec",
            "Chlumec nad Cidlinou",
            "Choceň",
            "Chodov",
            "Chodová Planá",
            "Chomutov",
            "Chotěboř",
            "Chotěbuz",
            "Chotěšov",
            "Chotoviny",
            "Chrast",
            "Chrást",
            "Chrastava",
            "Chřibská",
            "Chropyně",
            "Chroustovice",
            "Chrudim",
            "Chuchelná",
            "Chvalčov",
            "Chvaletice",
            "Chvalšiny",
            "Chyňava",
            "Chýnov",
            "Chyšky",
            "Čimelice",
            "Církvice",
            "Cítov",
            "Čížkovice",
            "Čkyně",
            "Cvikov",
            "Dačice",
            "Dalovice",
            "Dambořice",
            "Darkovice",
            "Dašice",
            "Davle",
            "Děčín",
            "Desná",
            "Dětmarovice",
            "Divišov",
            "Dlouhá Třebová",
            "Dobrá",
            "Dobřany",
            "Dobratice",
            "Dobřichovice",
            "Dobříš",
            "Dobřív",
            "Dobroměřice",
            "Dobronín",
            "Dobrovice",
            "Dobruška",
            "Dobšice",
            "Doksy",
            "Doksy",
            "Dolany",
            "Dolní Bečva",
            "Dolní Benešov",
            "Dolní Beřkovice",
            "Dolní Bohdíkov",
            "Dolní Bojanovice",
            "Dolní Bousov",
            "Dolní Břežany",
            "Dolní Bukovsko",
            "Dolní Cerekev",
            "Dolní Čermná",
            "Dolní Černilov",
            "Dolní Dobrouč",
            "Dolní Dunajovice",
            "Dolní Dvořiště",
            "Dolní Kounice",
            "Dolní Lhota",
            "Dolní Loućky",
            "Dolní Lutyně",
            "Dolní Němčí",
            "Dolní Počernice",
            "Dolní Podluží",
            "Dolní Poustevna",
            "Dolní Roveň",
            "Dolní Rychnov",
            "Dolní Sloupnice",
            "Dolní Studénky",
            "Dolní Újezd",
            "Dolní Újezd",
            "Dolní Žandov",
            "Dolní Životice",
            "Doloplazy",
            "Domanín",
            "Domažlice",
            "Doubrava",
            "Doubravice nad Svitavou",
            "Doudleby nad Orlicí",
            "Drahanovice",
            "Drásov",
            "Dřevohostice",
            "Dřiteň",
            "Drnholec",
            "Drnovice",
            "Dub nad Moravou",
            "Dubá",
            "Dubí",
            "Dubicko",
            "Dubňany",
            "Dubné",
            "Duchcov",
            "Dvorce",
            "Dvůr Králové nad Labem",
            "Dýšina",
            "Francova Lhota",
            "Františkovy Lázně",
            "Frenštát pod Radhoštěm",
            "Fryčovice",
            "Frýdek-Místek",
            "Frýdlant",
            "Frýdlant nad Ostravicí",
            "Frymburk",
            "Fryšták",
            "Fulnek",
            "Golčův Jeníkov",
            "Grygov",
            "Habartov",
            "Habry",
            "Háj ve Slezsku",
            "Halenkov",
            "Halenkovice",
            "Hanušovice",
            "Harrachov",
            "Hartmanice",
            "Hať",
            "Havířov",
            "Havlíčkův Brod",
            "Hazlov",
            "Hejnice",
            "Herálec",
            "Heřmanova Huť",
            "Heřmanův Městec",
            "Hevlín",
            "Hlinsko",
            "Hlohovec",
            "Hlubočky",
            "Hluboká nad Vltavou",
            "Hlučín",
            "Hluk",
            "Hněvošice",
            "Hněvotín",
            "Hnojník",
            "Hodkovice nad Mohelkou",
            "Hodkovičky",
            "Hodonice",
            "Hodonín",
            "Hodslavice",
            "Holasovice",
            "Holešov",
            "Holice",
            "Holoubkov",
            "Holýšov",
            "Horažďovice",
            "Hořice",
            "Horka nad Moravou",
            "Horní Bečva",
            "Horní Benešov",
            "Horní Bludovice",
            "Horní Branná",
            "Horní Bříza",
            "Horní Cerekev",
            "Horní Čermná",
            "Horní Jelení",
            "Horní Jiřetín",
            "Horní Lideč",
            "Horní Město",
            "Horní Moštěnice",
            "Horní Planá",
            "Horní Počaply",
            "Horní Počernice",
            "Horní Slavkov",
            "Horní Sloupnice",
            "Horní Štěpánov",
            "Horní Stropnice",
            "Horní Suchá",
            "Horní Těrlicko",
            "Horoměřice",
            "Hořovice",
            "Horšovský Týn",
            "Hošťálková",
            "Hostavice",
            "Hostěradice",
            "Hostinné",
            "Hostivice",
            "Hošťka",
            "Hostomice",
            "Hostomice",
            "Hostouň",
            "Hovězí",
            "Hovorany",
            "Hovorčovice",
            "Hrabyně",
            "Hradec Králové",
            "Hradec nad Moravici",
            "Hradec nad Svitavou",
            "Hrádek",
            "Hrádek",
            "Hrádek nad Nisou",
            "Hradištko",
            "Hranice",
            "Hranice",
            "Hrdějovice",
            "Hřebeč",
            "Hrob",
            "Hrochův Týnec",
            "Hromnice",
            "Hronov",
            "Hrotovice",
            "Hroznětín",
            "Hroznová Lhota",
            "Hrušky",
            "Hrušovany nad Jevišovkou",
            "Hrušovany u Brna",
            "Hudlice",
            "Hukvaldy",
            "Hulín",
            "Humpolec",
            "Husinec",
            "Hustopeče",
            "Hustopeče Nad Bečvou",
            "Hvozdná",
            "Hýskov",
            "Ivančice",
            "Ivanovice na Hané",
            "Jablonec nad Jizerou",
            "Jablonec nad Nisou",
            "Jablonné nad Orlicí",
            "Jablonné v Podještědí",
            "Jablůnka",
            "Jablunkov",
            "Jáchymov",
            "Jakartovice",
            "Jalubí",
            "Janov nad Nisou",
            "Janovice",
            "Janovice nad Úhlavou",
            "Jaroměř",
            "Jaroměřice",
            "Jaroměřice nad Rokytnou",
            "Jaroslavice",
            "Jarošov nad Nežárkou",
            "Javorník",
            "Jedlová",
            "Jedovnice",
            "Jemnice",
            "Jeneč",
            "Jesenice",
            "Jeseník",
            "Jeseník nad Odrou",
            "Jevíčko",
            "Jevišovice",
            "Jičín",
            "Jihlava",
            "Jilemnice",
            "Jílové",
            "Jílové u Prahy",
            "Jimramov",
            "Jince",
            "Jindřichov",
            "Jindřichův Hradec",
            "Jiříkov",
            "Jirkov",
            "Jirny",
            "Jistebnice",
            "Jistebník",
            "Josefův Důl",
            "Kačice",
            "Kadaň",
            "Kájov",
            "Kamenice",
            "Kamenice",
            "Kamenice nad Lipou",
            "Kamenický Šenov",
            "Kamenné Žehrovice",
            "Kamenný Přívoz",
            "Kamenný Újezd",
            "Kaplice",
            "Kardašova Řečice",
            "Karlín",
            "Karlovice",
            "Karlovy Vary",
            "Karolinka",
            "Karviná",
            "Kasejovice",
            "Kašperské Hory",
            "Katovice",
            "Kaznějov",
            "Kbely",
            "Kdyně",
            "Kelč",
            "Kladno",
            "Kladruby",
            "Klášter",
            "Klášterec nad Ohří",
            "Klatovy",
            "Klecany",
            "Klenčí pod Čerchovem",
            "Klimkovice",
            "Klobouky",
            "Klobuky",
            "Kněždub",
            "Kněžice",
            "Kněžmost",
            "Kněžpole",
            "Kobeřice",
            "Kobylí",
            "Kojetín",
            "Kokory",
            "Kolín",
            "Kolinec",
            "Komárov",
            "Komorní Lhotka",
            "Konice",
            "Kopidlno",
            "Kopřivnice",
            "Kořenov",
            "Koryčany",
            "Kosmonosy",
            "Kosova Hora",
            "Košťálov",
            "Košťany",
            "Kostelec na Hané",
            "Kostelec nad Černými Lesy",
            "Kostelec nad Labem",
            "Kostelec nad Orlicí",
            "Kostice",
            "Kouřim",
            "Kout na Šumavě",
            "Kovářov",
            "Kovářská",
            "Kožlany",
            "Kozlovice",
            "Kozmice",
            "Kralice na Hané",
            "Králíky",
            "Kralovice",
            "Kralupy nad Vltavou",
            "Králův Dvůr",
            "Kraslice",
            "Krásná Hora nad Vltavou",
            "Krásná Lípa",
            "Kravaře",
            "Křemže",
            "Křenovice",
            "Křepice",
            "Křešice",
            "Křinec",
            "Křižanov",
            "Křížová",
            "Krmelín",
            "Krnov",
            "Kroměříž",
            "Krouna",
            "Krumvíř",
            "Krupka",
            "Kryry",
            "Kunčice pod Ondřejníkem",
            "Kunčina",
            "Kunín",
            "Kunovice",
            "Kunštát",
            "Kunvald",
            "Kunžak",
            "Kuřim",
            "Kutná Hora",
            "Kvasice",
            "Kvasiny",
            "Kyjov",
            "Kynšperk nad Ohří",
            "Lanškroun",
            "Lány",
            "Lanžhot",
            "Lázně Bělohrad",
            "Lázně Bohdaneč",
            "Lázně Kynžvart",
            "Ledeč nad Sázavou",
            "Ledenice",
            "Lednice",
            "Lelekovice",
            "Lenešice",
            "Lešná",
            "Leština",
            "Letňany",
            "Letohrad",
            "Letonice",
            "Letovice",
            "Lhenice",
            "Libáň",
            "Libčice nad Vltavou",
            "Libeň",
            "Liberec",
            "Liběšice",
            "Líbeznice",
            "Libice nad Cidlinou",
            "Libiš",
            "Libochovice",
            "Libouchec",
            "Libušín",
            "Lichnov",
            "Lichnov (o. Nový Jičín)",
            "Lidečko",
            "Líně",
            "Lípa",
            "Lipník nad Bečvou",
            "Lipov",
            "Lipovec",
            "Liptál",
            "Lipůvka",
            "Lišov",
            "Litoměřice",
            "Litomyšl",
            "Litovel",
            "Litvínov",
            "Litvínovice",
            "Lochovice",
            "Loděnice",
            "Loket",
            "Lom u Mostu",
            "Lomnice",
            "Lomnice",
            "Lomnice nad Lužnicí",
            "Lomnice nad Popelkou",
            "Loštice",
            "Loučeň",
            "Loučná nad Desnou",
            "Loučovice",
            "Louka",
            "Louny",
            "Lovosice",
            "Lubenec",
            "Luby",
            "Lučany nad Nisou",
            "Lučina",
            "Ludgeřovice",
            "Luhačovice",
            "Luka nad Jihlavou",
            "Lukavec",
            "Lukavice",
            "Lukov",
            "Luštěnice",
            "Lutín",
            "Luže",
            "Lužec nad Vltavou",
            "Lužice",
            "Lužná",
            "Lysá nad Labem",
            "Lysice",
            "Machov",
            "Majetín",
            "Malá Skála",
            "Malá Strana",
            "Malé Svatoňovice",
            "Malonty",
            "Malšice",
            "Manětín",
            "Mariánské Lázně",
            "Markvartovice",
            "Měcholupy",
            "Měčín",
            "Meclov",
            "Medlov",
            "Mělník",
            "Měnín",
            "Měřín",
            "Merklín",
            "Merklín",
            "Měšice",
            "Městec Králové",
            "Městečko Trnávka",
            "Město",
            "Město Albrechtice",
            "Město Brno",
            "Město Libavá",
            "Město Touškov",
            "Metylovice",
            "Meziboři",
            "Meziměstí",
            "Mikulášovice",
            "Mikulčice",
            "Mikulov",
            "Mikulovice",
            "Milevsko",
            "Milíkov",
            "Milín",
            "Milotice",
            "Milovice",
            "Mimoň",
            "Miřetice",
            "Miroslav",
            "Mirošov",
            "Mirotice",
            "Mirovice",
            "Mistřice",
            "Mladá Boleslav",
            "Mladá Vožice",
            "Mladé Buky",
            "Mnichovice",
            "Mnichovo Hradiště",
            "Mníšek",
            "Mníšek pod Brdy",
            "Modřany",
            "Modřice",
            "Mohelnice",
            "Mohelno",
            "Mokrá Hora",
            "Mokré Lazce",
            "Moravany",
            "Moravany",
            "Moravičany",
            "Morávka",
            "Moravská Nová Ves",
            "Moravská Třebová",
            "Moravské Budějovice",
            "Moravský Beroun",
            "Moravský Krumlov",
            "Moravský Písek",
            "Moravský Žižkov",
            "Mořkov",
            "Most",
            "Mostek",
            "Mostkovice",
            "Mosty u Jablunkova",
            "Moutnice",
            "Mrákov",
            "Mšeno",
            "Mukařov",
            "Mutěnice",
            "Mýto",
            "Načeradec",
            "Náchod",
            "Náklo",
            "Náměšť na Hané",
            "Náměšť nad Oslavou",
            "Napajedla",
            "Nasavrky",
            "Návsí u Jablunkova",
            "Nechanice",
            "Nedakonice",
            "Nedašov",
            "Nedvědice",
            "Nehvizdy",
            "Nejdek",
            "Nelahozeves",
            "Němčice nad Hanou",
            "Nepomuk",
            "Neratovice",
            "Nesovice",
            "Netolice",
            "Netvořice",
            "Neveklov",
            "Nezamyslice",
            "Nezvěstice",
            "Nivnice",
            "Nižbor",
            "Nosislav",
            "Nová Bystřice",
            "Nová Cerekev",
            "Nová Paka",
            "Nová Role",
            "Nová Včelnice",
            "Nové Hrady",
            "Nové Město na Moravě",
            "Nové Město nad Metují",
            "Nové Město pod Smrkem",
            "Nové Sedlo",
            "Nové Strašecí",
            "Nové Syrovice",
            "Nové Veselí",
            "Novosedlice",
            "Novosedly",
            "Nový Bor",
            "Nový Bydžov",
            "Nový Hrozenkov",
            "Nový Jičín",
            "Nový Knín",
            "Nový Malín",
            "Nýdek",
            "Nymburk",
            "Nýřany",
            "Nýrsko",
            "Obecnice",
            "Obrnice",
            "Odolena Voda",
            "Odry",
            "Ohrazenice",
            "Okres Benešov",
            "Okres Beroun",
            "Okres Blansko",
            "Okres Břeclav",
            "Okres Brno-venkov",
            "Okres Bruntál",
            "Okres Česká Lípa",
            "Okres České Budějovice",
            "Okres Český Krumlov",
            "Okres Cheb",
            "Okres Chomutov",
            "Okres Chrudim",
            "Okres Děčín",
            "Okres Domažlice",
            "Okres Frýdek-Místek",
            "Okres Havlíčkův Brod",
            "Okres Hodonín",
            "Okres Hradec Králové",
            "Okres Jablonec nad Nisou",
            "Okres Jeseník",
            "Okres Jičín",
            "Okres Jihlava",
            "Okres Jindřichův Hradec",
            "Okres Karlovy Vary",
            "Okres Karviná",
            "Okres Kladno",
            "Okres Klatovy",
            "Okres Kolín",
            "Okres Kroměříž",
            "Okres Kutná Hora",
            "Okres Liberec",
            "Okres Litoměřice",
            "Okres Louny",
            "Okres Mělník",
            "Okres Mladá Boleslav",
            "Okres Most",
            "Okres Náchod",
            "Okres Nový Jičín",
            "Okres Nymburk",
            "Okres Olomouc",
            "Okres Opava",
            "Okres Ostrava-město",
            "Okres Pardubice",
            "Okres Pelhřimov",
            "Okres Písek",
            "Okres Plzeň-jih",
            "Okres Plzeň-město",
            "Okres Plzeň-sever",
            "Okres Prachatice",
            "Okres Praha-východ",
            "Okres Praha-západ",
            "Okres Přerov",
            "Okres Příbram",
            "Okres Prostějov",
            "Okres Rakovník",
            "Okres Rokycany",
            "Okres Rychnov nad Kněžnou",
            "Okres Semily",
            "Okres Sokolov",
            "Okres Strakonice",
            "Okres Šumperk",
            "Okres Svitavy",
            "Okres Tábor",
            "Okres Tachov",
            "Okres Teplice",
            "Okres Třebíč",
            "Okres Trutnov",
            "Okres Uherské Hradiště",
            "Okres Ústí nad Labem",
            "Okres Ústí nad Orlicí",
            "Okres Vsetín",
            "Okres Vyškov",
            "Okres Žďár nad Sázavou",
            "Okres Zlín",
            "Okres Znojmo",
            "Okříšky",
            "Okrouhlice",
            "Olbramovice",
            "Oldřišov",
            "Olešnice",
            "Olomouc",
            "Oloví",
            "Olšany",
            "Ondřejov",
            "Opařany",
            "Opatov",
            "Opatovice nad Labem",
            "Opava",
            "Opočno",
            "Ořechov",
            "Orlová",
            "Osečná",
            "Osek",
            "Osek",
            "Osek nad Bečvou",
            "Osík",
            "Oskava",
            "Oslavany",
            "Osoblaha",
            "Ostopovice",
            "Ostrava",
            "Ostravice",
            "Ostroměř",
            "Ostrov",
            "Ostrov u Macochy",
            "Ostrožská Lhota",
            "Ostrožská Nová Ves",
            "Otaslavice",
            "Otice",
            "Otnice",
            "Otrokovice",
            "Pacov",
            "Palkovice",
            "Pardubice",
            "Paseka",
            "Paskov",
            "Pavlíkov",
            "Pchery",
            "Pecka",
            "Pečky",
            "Pelhřimov",
            "Pěnčín",
            "Perštejn",
            "Peruc",
            "Petrov",
            "Petrovice",
            "Petrovice u Karviné",
            "Petřvald",
            "Pilníkov",
            "Pilsen",
            "Písečná",
            "Písek",
            "Písek",
            "Píšť",
            "Planá",
            "Planá nad Lužnicí",
            "Plaňany",
            "Plánice",
            "Plasy",
            "Plavy",
            "Plesná",
            "Plumlov",
            "Poběžovice",
            "Počátky",
            "Podbořany",
            "Poděbrady",
            "Podivín",
            "Podolí",
            "Pohořelice",
            "Polepy",
            "Polešovice",
            "Police nad Metují",
            "Polička",
            "Polná",
            "Pomezí",
            "Poniklá",
            "Popovice",
            "Poříčany",
            "Poříčí nad Sázavou",
            "Postoloprty",
            "Postřekov",
            "Postřelmov",
            "Postupice",
            "Potštát",
            "Povrly",
            "Pozlovice",
            "Pozořice",
            "Prachatice",
            "Prachovice",
            "Prague",
            "Praha 1",
            "Praha 16",
            "Praha 20",
            "Praha 21",
            "Přáslavice",
            "Pražmo",
            "Předklášteří",
            "Předměřice nad Labem",
            "Přelouč",
            "Přemyslovice",
            "Přerov",
            "Přerov nad Labem",
            "Přeštice",
            "Příbor",
            "Příbram",
            "Přibyslav",
            "Příkazy",
            "Přimda",
            "Příšovice",
            "Proboštov",
            "Proseč",
            "Prosek",
            "Prostějov",
            "Prostřední Bečva",
            "Protivanov",
            "Protivín",
            "Provodov-Šonov",
            "Průhonice",
            "Prušánky",
            "Prusinovice",
            "Psáry",
            "Ptení",
            "Pustá Polom",
            "Pustiměř",
            "Pyšely",
            "Radiměř",
            "Radnice",
            "Radomyšl",
            "Radonice",
            "Radslavice",
            "Radvanice",
            "Rájec-Jestřebí",
            "Ráječko",
            "Rajhrad",
            "Rajhradice",
            "Rakovník",
            "Rakvice",
            "Rapotín",
            "Raškovice",
            "Raspenava",
            "Rataje",
            "Ratiboř",
            "Ratíškovice",
            "Řečany nad Labem",
            "Řehlovice",
            "Řepiště",
            "Řevnice",
            "Řevničov",
            "Řež",
            "Říčany",
            "Říčany",
            "Rohatec",
            "Rokycany",
            "Rokytnice",
            "Rokytnice nad Jizerou",
            "Rokytnice v Orlických Horách",
            "Ronov nad Doubravou",
            "Ropice",
            "Rosice",
            "Rosice",
            "Rotava",
            "Rouchovany",
            "Roudnice nad Labem",
            "Rousínov",
            "Rovensko pod Troskami",
            "Rožďalovice",
            "Rožmitál pod Třemšínem",
            "Rožnov pod Radhoštěm",
            "Roztoky",
            "Rtyně v Podkrkonoší",
            "Ruda nad Moravou",
            "Rudná",
            "Rudník",
            "Rudolfov",
            "Rumburk",
            "Rybí",
            "Rybitví",
            "Rychnov nad Kněžnou",
            "Rychvald",
            "Rýmařov",
            "Sadov",
            "Sadská",
            "Šakvice",
            "Samotíšky",
            "Šanov",
            "Šardice",
            "Satalice",
            "Šatov",
            "Sázava",
            "Seč",
            "Sedlčany",
            "Sedlec",
            "Sedlice",
            "Sedliště",
            "Sedlnice",
            "Semily",
            "Senice na Hané",
            "Šenov",
            "Sepekov",
            "Šestajovice",
            "Ševětín",
            "Sezemice",
            "Sezimovo Ústí",
            "Šilheřovice",
            "Šitbořice",
            "Skalná",
            "Skrbeň",
            "Skuhrov nad Bělou",
            "Skuteč",
            "Slaný",
            "Šlapanice",
            "Slatiňany",
            "Slatinice",
            "Slavičín",
            "Slavkov",
            "Slavkov u Brna",
            "Slavonice",
            "Šluknov",
            "Slušovice",
            "Smečno",
            "Smidary",
            "Smiřice",
            "Smržice",
            "Smržovka",
            "Soběslav",
            "Sobotín",
            "Sobotka",
            "Sokolnice",
            "Sokolov",
            "Solnice",
            "Spálené Poříčí",
            "Špindlerův Mlýn",
            "Spytihněv",
            "Srubec",
            "Stachy",
            "Šťáhlavy",
            "Staňkov",
            "Stará Huť",
            "Stará Paka",
            "Stará Ves nad Ondřejnicí",
            "Staré Hradiště",
            "Staré Křečany",
            "Staré Město",
            "Staré Město",
            "Staré Město",
            "Staré Město",
            "Staré Nechanice",
            "Stařeč",
            "Staříč",
            "Starý Bohumín",
            "Starý Jičín",
            "Starý Kolín",
            "Starý Plzenec",
            "Stěbořice",
            "Štěchovice",
            "Štěnovice",
            "Štěpánkovice",
            "Štěpánov",
            "Šternberk",
            "Štětí",
            "Stěžery",
            "Štítina",
            "Štítná nad Vláří",
            "Štíty",
            "Stochov",
            "Stod",
            "Štoky",
            "Stonava",
            "Strakonice",
            "Štramberk",
            "Strančice",
            "Strání",
            "Strašice",
            "Stráž",
            "Stráž nad Nisou",
            "Stráž pod Ralskem",
            "Strážná",
            "Strážnice",
            "Strážov",
            "Střelice",
            "Stříbro",
            "Střížkov",
            "Strmilov",
            "Strunkovice nad Blanicí",
            "Studená",
            "Studenec",
            "Studénka",
            "Suchá Loz",
            "Suchdol",
            "Suchdol nad Lužnicí",
            "Suchohrdly",
            "Sudkov",
            "Sudoměřice",
            "Šumice",
            "Šumperk",
            "Sušice",
            "Svatava",
            "Světlá nad Sázavou",
            "Sviadnov",
            "Švihov",
            "Svitávka",
            "Svitavy",
            "Svoboda nad Úpou",
            "Svratka",
            "Tábor",
            "Tachov",
            "Tanvald",
            "Tasovice",
            "Tečovice",
            "Telč",
            "Telnice",
            "Teplice",
            "Teplice nad Metují",
            "Terezín",
            "Těšany",
            "Těšetice",
            "Tichá",
            "Tišice",
            "Tišnov",
            "Tlučná",
            "Tlumačov",
            "Topolná",
            "Toužim",
            "Tovačov",
            "Traplice",
            "Třebechovice pod Orebem",
            "Třebenice",
            "Třebíč",
            "Třeboň",
            "Třebotov",
            "Třemošná",
            "Třemošnice",
            "Třešť",
            "Trhové Sviny",
            "Trhový Štěpánov",
            "Třinec",
            "Trmice",
            "Trnava",
            "Trojanovice",
            "Troubelice",
            "Troubky",
            "Troubsko",
            "Tršice",
            "Trutnov",
            "Tuchlovice",
            "Tuchoměřice",
            "Tupesy",
            "Turnov",
            "Tvarožná",
            "Tvrdonice",
            "Týn nad Vltavou",
            "Týnec",
            "Týnec nad Labem",
            "Týnec nad Sázavou",
            "Týniště nad Orlicí",
            "Údlice",
            "Uherské Hradiště",
            "Uherský Brod",
            "Uherský Ostroh",
            "Uhlířské Janovice",
            "Újezd",
            "Újezd",
            "Únanov",
            "Unhošť",
            "Uničov",
            "Úpice",
            "Určice",
            "Úsov",
            "Úštěk",
            "Ústí nad Orlicí",
            "Úvaly",
            "Vacenovice",
            "Václavovice",
            "Vacov",
            "Valašská Bystřice",
            "Valašská Polanka",
            "Valašské Klobouky",
            "Valašské Meziříčí",
            "Valdice",
            "Valdice",
            "Valtice",
            "Vamberk",
            "Vápenná",
            "Varnsdorf",
            "Včelná",
            "Vejprnice",
            "Vejprty",
            "Velehrad",
            "Velemín",
            "Velešín",
            "Velim",
            "Velká Bíteš",
            "Velká Bystřice",
            "Velká Dobrá",
            "Velká Hleďsebe",
            "Velká nad Veličkou",
            "Velká Polom",
            "Velké Bílovice",
            "Velké Březno",
            "Velké Hamry",
            "Velké Heraltice",
            "Velké Hoštice",
            "Velké Karlovice",
            "Velké Losiny",
            "Velké Meziříčí",
            "Velké Němčice",
            "Velké Opatovice",
            "Velké Pavlovice",
            "Velké Popovice",
            "Velké Poříčí",
            "Velké Přílepy",
            "Velké Svatoňovice",
            "Velký Beranov",
            "Velký Osek",
            "Velký Šenov",
            "Velký Týnec",
            "Velký Újezd",
            "Veltruby",
            "Veltrusy",
            "Velvary",
            "Vendryně",
            "Verneřice",
            "Věrovany",
            "Veřovice",
            "Veselí nad Lužnicí",
            "Veselí nad Moravou",
            "Vestec",
            "Větřní",
            "Veverská Bítýška",
            "Vidče",
            "Vidnava",
            "Vikýřovice",
            "Vilémov",
            "Vilémov",
            "Vimperk",
            "Vinařice",
            "Viničné Šumice",
            "Vintířov",
            "Višňova",
            "Višňové",
            "Vítkov",
            "Vizovice",
            "Vlachovice",
            "Vlachovo Březí",
            "Vladislav",
            "Vlašim",
            "Vlčnov",
            "Vlčnov",
            "Vlkoš",
            "Vnorovy",
            "Vodňany",
            "Vojkovice",
            "Volary",
            "Volyně",
            "Votice",
            "Vracov",
            "Vrané nad Vltavou",
            "Vranovice",
            "Vratimov",
            "Vrbátky",
            "Vrbice",
            "Vrbice",
            "Vrbno pod Pradědem",
            "Vrbovec",
            "Vrchlabí",
            "Vrdy",
            "Vřesina",
            "Vroutek",
            "Všemina",
            "Všenory",
            "Všeruby",
            "Všestary",
            "Všetaty",
            "Vsetín",
            "Vysehrad",
            "Vyškov",
            "Vysočany",
            "Vysoké Mýto",
            "Vysoké nad Jizerou",
            "Vyšší Brod",
            "Žabčice",
            "Zábřeh",
            "Zabrušany",
            "Žacléř",
            "Zadní Mostek",
            "Záhorovice",
            "Zaječí",
            "Zaječov",
            "Zákupy",
            "Žamberk",
            "Žandov",
            "Zásmuky",
            "Zašová",
            "Zastávka",
            "Žatec",
            "Zátor",
            "Zbiroh",
            "Zborovice",
            "Zbraslav",
            "Zbraslavice",
            "Zbůch",
            "Zbýšov",
            "Ždánice",
            "Žďár",
            "Žďár nad Sázavou",
            "Žďár nad Sázavou Druhy",
            "Zdiby",
            "Zdice",
            "Zdíkov",
            "Zdounky",
            "Žebrák",
            "Zeleneč",
            "Želešice",
            "Želetava",
            "Železná Ruda",
            "Železnice",
            "Železný Brod",
            "Želiv",
            "Žeravice",
            "Židlochovice",
            "Žihle",
            "Žirovnice",
            "Žitenice",
            "Žiželice",
            "Zlaté Hory",
            "Žleby",
            "Zlechov",
            "Zlín",
            "Zliv",
            "Zlonice",
            "Žlutava",
            "Žlutice",
            "Znojmo",
            "Zruč nad Sázavou",
            "Zubří",
            "Žulová",
            "Zvole"
        ]
    },
    {
        "label": "Democratic Republic of the Congo",
        "code": "CD",
        "phone": "243",
        "cities": [
            "Aketi",
            "Bandundu",
            "Basoko",
            "Beni",
            "Boende",
            "Bolobo",
            "Boma",
            "Bondo",
            "Bongandanga",
            "Bosobolo",
            "Bukama",
            "Bukavu",
            "Bulungu",
            "Bumba",
            "Bunia",
            "Businga",
            "Buta",
            "Butembo",
            "Gandajika",
            "Gbadolite",
            "Gemena",
            "Goma",
            "Haut Katanga",
            "Ilebo",
            "Inongo",
            "Isiro",
            "Kabalo",
            "Kabare",
            "Kabinda",
            "Kalemie",
            "Kambove",
            "Kamina",
            "Kampene",
            "Kasangulu",
            "Kasongo",
            "Kasongo-Lunda",
            "Kikwit",
            "Kindu",
            "Kinshasa",
            "Kipushi",
            "Kisangani",
            "Kongolo",
            "Libenge",
            "Likasi",
            "Lisala",
            "Lodja",
            "Lubao",
            "Lubumbashi",
            "Luebo",
            "Lukolela",
            "Lusambo",
            "Mangai",
            "Masina",
            "Matadi",
            "Mbandaka",
            "Mbanza-Ngungu",
            "Mbuji-Mayi",
            "Moanda",
            "Mushie",
            "Mweka",
            "Mwene-Ditu",
            "Nioki",
            "Sake",
            "Tshela",
            "Tshikapa",
            "Uvira",
            "Wamba",
            "Watsa",
            "Yangambi"
        ]
    },
    {
        "label": "Denmark",
        "code": "DK",
        "phone": "45",
        "cities": [
            "Aabenraa",
            "Aabenraa Kommune",
            "Aalborg",
            "Aarhus",
            "Aarhus Kommune",
            "Aars",
            "Åbybro",
            "Åkirkeby",
            "Albertslund",
            "Albertslund Kommune",
            "Ålborg Kommune",
            "Ålestrup",
            "Allerød Kommune",
            "Allingåbro",
            "Arden",
            "Årslev",
            "Årup",
            "Asnæs",
            "Assens",
            "Assens Kommune",
            "Assentoft",
            "Augustenborg",
            "Auning",
            "Avlum",
            "Ærø Kommune",
            "Ærøskøbing",
            "Ballerup",
            "Ballerup Kommune",
            "Beder",
            "Bellinge",
            "Billund",
            "Billund Kommune",
            "Birkerød",
            "Bjæverskov",
            "Bjerringbro",
            "Blovstrød",
            "Bogense",
            "Bording Kirkeby",
            "Bornholm Kommune",
            "Borup",
            "Børkop",
            "Bramming",
            "Brande",
            "Brædstrup",
            "Brejning",
            "Brenderup",
            "Broager",
            "Brovst",
            "Brøndby Kommune",
            "Brønderslev",
            "Brønderslev Kommune",
            "Brørup",
            "Bullerup",
            "Charlottenlund",
            "Christiansfeld",
            "Christianshavn",
            "Christiansø",
            "Copenhagen",
            "Dianalund",
            "Dragør",
            "Dragør Kommune",
            "Dronninglund",
            "Dybbøl",
            "Ebeltoft",
            "Egedal Kommune",
            "Egtved",
            "Ejby",
            "Ejby",
            "Esbjerg",
            "Esbjerg Kommune",
            "Espergærde",
            "Faaborg",
            "Faaborg-Midtfyn Kommune",
            "Fanø Kommune",
            "Farsø",
            "Farum",
            "Favrskov Kommune",
            "Faxe",
            "Faxe Kommune",
            "Faxe Ladeplads",
            "Fensmark",
            "Fjerritslev",
            "Fløng",
            "Forlev",
            "Framlev",
            "Fredensborg",
            "Fredensborg Kommune",
            "Fredericia",
            "Fredericia Kommune",
            "Frederiksberg",
            "Frederiksberg",
            "Frederiksberg Kommune",
            "Frederikshavn",
            "Frederikshavn Kommune",
            "Frederikssund",
            "Frederikssund Kommune",
            "Frederiksværk",
            "Frejlev",
            "Fuglebjerg",
            "Furesø Kommune",
            "Galten",
            "Ganløse",
            "Gentofte Kommune",
            "Gilleleje",
            "Gistrup",
            "Give",
            "Gjellerup",
            "Gladsaxe Municipality",
            "Glamsbjerg",
            "Glostrup",
            "Glostrup Kommune",
            "Gørlev",
            "Gram",
            "Gråsten",
            "Græsted",
            "Grenaa",
            "Greve",
            "Greve Kommune",
            "Gribskov Kommune",
            "Grindsted",
            "Guderup",
            "Guldborgsund Kommune",
            "Gundsømagle",
            "Haderslev",
            "Haderslev Kommune",
            "Hadsten",
            "Hadsund",
            "Hals",
            "Halsnæs Kommune",
            "Hammel",
            "Hammerum",
            "Hanstholm",
            "Harboøre",
            "Hårby",
            "Hårlev",
            "Haslev",
            "Havdrup",
            "Hedehusene",
            "Hedensted",
            "Hedensted Kommune",
            "Hellebæk",
            "Helsinge",
            "Helsingør",
            "Helsingør Kommune",
            "Herlev Kommune",
            "Herning",
            "Herning Kommune",
            "Hillerød",
            "Hillerød Kommune",
            "Hinnerup",
            "Hirtshals",
            "Hjallerup",
            "Hjortshøj",
            "Hjørring",
            "Hjørring Kommune",
            "Hobro",
            "Holbæk",
            "Holbæk Kommune",
            "Holeby",
            "Holstebro",
            "Holstebro Kommune",
            "Holsted",
            "Hornbæk",
            "Hornslet",
            "Hornsyld",
            "Horsens",
            "Horsens Kommune",
            "Højby",
            "Høje-Taastrup Kommune",
            "Høng",
            "Hørning",
            "Hørsholm",
            "Hørsholm Kommune",
            "Høruphav",
            "Hørve",
            "Humlebæk",
            "Hundested",
            "Hurup",
            "Hvide Sande",
            "Hvidovre",
            "Hvidovre Kommune",
            "Ikast",
            "Ikast-Brande Kommune",
            "Ishøj",
            "Ishøj Kommune",
            "Jammerbugt Kommune",
            "Jægerspris",
            "Jelling",
            "Juelsminde",
            "Jyderup",
            "Jyllinge",
            "Kalundborg",
            "Kalundborg Kommune",
            "Karup",
            "Kås",
            "Kerteminde",
            "Kerteminde Kommune",
            "Kibæk",
            "Kirke Hvalsø",
            "Kjellerup",
            "Klarup",
            "Kokkedal",
            "Kolding",
            "Kolding Kommune",
            "Kolt",
            "Kongens Lyngby",
            "Korsør",
            "København",
            "Køge",
            "Køge Kommune",
            "Kruså",
            "Langå",
            "Langeland Kommune",
            "Langeskov",
            "Læso Kommune",
            "Lejre",
            "Lejre Kommune",
            "Lemvig",
            "Lemvig Kommune",
            "Lillerød",
            "Lind",
            "Liseleje",
            "Lolland Kommune",
            "Løgstør",
            "Løgten",
            "Løgumkloster",
            "Løjt Kirkeby",
            "Løkken",
            "Lunderskov",
            "Lyngby-Tårbæk Kommune",
            "Lynge",
            "Lystrup",
            "Malling",
            "Måløv",
            "Mariager",
            "Mariagerfjord Kommune",
            "Maribo",
            "Mårslet",
            "Marstal",
            "Middelfart",
            "Middelfart Kommune",
            "Morsø Kommune",
            "Munkebo",
            "Nakskov",
            "Næstved",
            "Næstved Kommune",
            "Neder Holluf",
            "Neder Vindinge",
            "Nexø",
            "Nibe",
            "Nivå",
            "Nordborg",
            "Nordby",
            "Norddjurs Kommune",
            "Nordfyns Kommune",
            "Nødebo",
            "Nørre Åby",
            "Nørre Alslev",
            "Nørresundby",
            "Nyborg",
            "Nyborg Kommune",
            "Nykøbing Falster",
            "Nykøbing Mors",
            "Nykøbing Sjælland",
            "Nyråd",
            "Odder",
            "Odder Kommune",
            "Odense",
            "Odense Kommune",
            "Odsherred Kommune",
            "Oksbøl",
            "Osted",
            "Otterup",
            "Ølgod",
            "Ølstykke",
            "Ørslev",
            "Padborg",
            "Pandrup",
            "Præstø",
            "Randers",
            "Randers Kommune",
            "Rebild Kommune",
            "Ribe",
            "Ringe",
            "Ringkøbing",
            "Ringkøbing-Skjern Kommune",
            "Ringsted",
            "Ringsted Kommune",
            "Roskilde",
            "Roskilde Kommune",
            "Rødby",
            "Rødbyhavn",
            "Rødding",
            "Rødekro",
            "Rødovre",
            "Rødovre Kommune",
            "Rødvig",
            "Rønde",
            "Rønne",
            "Rønnede",
            "Rudersdal Kommune",
            "Rudkøbing",
            "Ry",
            "Ryomgård",
            "Sabro",
            "Sakskøbing",
            "Samsø Kommune",
            "Sæby",
            "Seden",
            "Silkeborg",
            "Silkeborg Kommune",
            "Sindal",
            "Skaerbaek",
            "Skagen",
            "Skanderborg",
            "Skanderborg Kommune",
            "Skælskør",
            "Skævinge",
            "Skibby",
            "Skive",
            "Skive Kommune",
            "Skjern",
            "Skovby",
            "Skørping",
            "Slagelse",
            "Slagelse Kommune",
            "Slangerup",
            "Smørumnedre",
            "Snejbjerg",
            "Snoghøj",
            "Solbjerg",
            "Solrød",
            "Solrød Kommune",
            "Solrød Strand",
            "Sorø",
            "Sorø Kommune",
            "Søften",
            "Sønder Bjert",
            "Sønderborg",
            "Sønderborg Kommune",
            "Søndersø",
            "Spentrup",
            "Starup",
            "Stavnsholt",
            "Stavtrup",
            "Stege",
            "Stenlille",
            "Stenløse",
            "Stevns Kommune",
            "Stige",
            "Stilling",
            "Stoholm",
            "Store Heddinge",
            "Storvorde",
            "Støvring",
            "Strandby",
            "Strib",
            "Strøby Egede",
            "Struer",
            "Struer Kommune",
            "Stubbekøbing",
            "Sundby",
            "Sunds",
            "Svebølle",
            "Svejbæk",
            "Svendborg",
            "Svendborg Kommune",
            "Svenstrup",
            "Svinninge",
            "Svogerslev",
            "Syddjurs Kommune",
            "Taastrup",
            "Tarm",
            "Tårnby",
            "Tårnby Kommune",
            "Tårs",
            "Taulov",
            "Thisted",
            "Thisted Kommune",
            "Thurø By",
            "Thyborøn",
            "Tinglev",
            "Tjæreborg",
            "Toftlund",
            "Tommerup",
            "Tommerup Stationsby",
            "Tølløse",
            "Tønder",
            "Tønder Kommune",
            "Tørring",
            "Tranbjerg",
            "Trige",
            "Trørød",
            "Tune",
            "Ulfborg",
            "Ullerslev",
            "Ulstrup",
            "Vadum",
            "Vallensbæk",
            "Vallensbæk Kommune",
            "Vamdrup",
            "Vanløse",
            "Varde",
            "Varde Kommune",
            "Værløse",
            "Vejen",
            "Vejen Kommune",
            "Vejle",
            "Vejle Kommune",
            "Veksø",
            "Vestbjerg",
            "Vester Hassing",
            "Vester-Skerninge",
            "Vesthimmerland Kommune",
            "Viborg",
            "Viborg Kommune",
            "Viby",
            "Videbæk",
            "Vildbjerg",
            "Vindeby",
            "Vinderup",
            "Vindinge",
            "Vipperød",
            "Virklund",
            "Vissenbjerg",
            "Vodskov",
            "Vojens",
            "Vordingborg",
            "Vordingborg Kommune",
            "Vrå"
        ]
    },
    {
        "label": "Djibouti",
        "code": "DJ",
        "phone": "253",
        "cities": [
            "'Ali Sabieh",
            "Alaïli Ḏaḏḏa‘",
            "Arta",
            "Dikhil",
            "Djibouti",
            "Dorra",
            "Gâlâfi",
            "Goubétto",
            "Holhol",
            "Loyada",
            "Obock",
            "Tadjourah"
        ]
    },
    {
        "label": "Dominica",
        "code": "DM",
        "phone": "+1-767",
        "cities": [
            "Berekua",
            "Calibishie",
            "Castle Bruce",
            "Colihaut",
            "La Plaine",
            "Mahaut",
            "Marigot",
            "Pointe Michel",
            "Pont Cassé",
            "Portsmouth",
            "Rosalie",
            "Roseau",
            "Saint Joseph",
            "Salisbury",
            "Soufrière",
            "Wesley",
            "Woodford Hill"
        ]
    },
    {
        "label": "Dominican Republic",
        "code": "DO",
        "phone": "+1-809 and 1-829",
        "cities": [
            "Agua Santa del Yuna",
            "Altamira",
            "Amina",
            "Arenoso",
            "Arroyo Salado",
            "Azua",
            "Baitoa",
            "Bajos de Haina",
            "Baní",
            "Bayaguana",
            "Bella Vista",
            "Bisonó",
            "Boca Chica",
            "Boca de Yuma",
            "Bohechío",
            "Bonao",
            "Cabarete",
            "Cabral",
            "Cabrera",
            "Cachón",
            "Cambita Garabitos",
            "Cana Chapetón",
            "Canoa",
            "Castañuelas",
            "Castillo",
            "Cayetano Germosén",
            "Cercado Abajo",
            "Cevicos",
            "Ciudad Nueva",
            "Concepción de La Vega",
            "Constanza",
            "Cotuí",
            "Cristo Rey",
            "Cristóbal",
            "Dajabón",
            "Don Juan",
            "Duvergé",
            "El Cacao",
            "El Carril",
            "El Cercado",
            "El Factor",
            "El Guayabal",
            "El Palmar",
            "El Peñón",
            "El Pino",
            "El Puerto",
            "El Valle",
            "Enriquillo",
            "Ensanche Luperón",
            "Esperalvillo",
            "Esperanza",
            "Estebanía",
            "Estero Hondo",
            "Fantino",
            "Fundación",
            "Galván",
            "Gaspar Hernández",
            "Gonzalo",
            "Guananico",
            "Guatapanal",
            "Guayabal",
            "Guayabo Dulce",
            "Guaymate",
            "Guayubín",
            "Hatillo Palma",
            "Hato Mayor del Rey",
            "Higüey",
            "Hostos",
            "Imbert",
            "Jaibón",
            "Jamao al Norte",
            "Jaquimeyes",
            "Jarabacoa",
            "Jicomé",
            "Jima Abajo",
            "Jimaní",
            "Joba Arriba",
            "Juan Adrián",
            "Juan de Herrera",
            "Juan López Abajo",
            "Juancho",
            "Juncalito Abajo",
            "La Agustina",
            "La Canela",
            "La Caya",
            "La Ciénaga",
            "La Descubierta",
            "La Entrada",
            "La Julia",
            "La Romana",
            "La Uvilla",
            "Laguna Salada",
            "Las Charcas",
            "Las Guáranas",
            "Las Matas de Farfán",
            "Las Matas de Santa Cruz",
            "Las Salinas",
            "Las Terrenas",
            "Licey al Medio",
            "Loma de Cabrera",
            "Los Botados",
            "Los Hidalgos",
            "Los Llanos",
            "Los Ríos",
            "Luperón",
            "Maimón",
            "Maizal",
            "Majagual",
            "Mao",
            "Matanzas",
            "Matayaya",
            "Mella",
            "Miches",
            "Moca",
            "Monción",
            "Monte Cristi",
            "Monte Llano",
            "Monte Plata",
            "Nagua",
            "Neiba",
            "Nizao",
            "Otra Banda",
            "Oviedo",
            "Padre Las Casas",
            "Palmar Arriba",
            "Palmar de Ocoa",
            "Paraíso",
            "Partido",
            "Paya",
            "Pedernales",
            "Pedro Corto",
            "Pedro García",
            "Pedro Sánchez",
            "Pepillo Salcedo",
            "Peralta",
            "Pescadería",
            "Piedra Blanca",
            "Pimentel",
            "Pizarrete",
            "Polo",
            "Postrer Río",
            "Pueblo Viejo",
            "Puerto Plata",
            "Punta Cana",
            "Quisqueya",
            "Ramón Santana",
            "Restauración",
            "Rincón",
            "Río Grande",
            "Río San Juan",
            "Río Verde Arriba",
            "Sabana Buey",
            "Sabana de la Mar",
            "Sabana del Puerto",
            "Sabana Grande de Boyá",
            "Sabana Grande de Palenque",
            "Sabana Iglesia",
            "Sabana Yegua",
            "Sabaneta",
            "Salcedo",
            "Salsipuedes",
            "Salvaleón de Higüey",
            "Samaná",
            "San Carlos",
            "San Cristóbal",
            "San Fernando de Monte Cristi",
            "San Francisco de Macorís",
            "San Gregorio de Nigua",
            "San Ignacio de Sabaneta",
            "San José de Las Matas",
            "San José de Ocoa",
            "San Juan de la Maguana",
            "San Pedro de Macorís",
            "San Rafael del Yuma",
            "San Víctor Arriba",
            "Sánchez",
            "Santa Cruz de Barahona",
            "Santa Cruz de El Seibo",
            "Santiago de los Caballeros",
            "Santo Domingo",
            "Santo Domingo Este",
            "Santo Domingo Oeste",
            "Santo Tomás de Jánico",
            "Sosúa",
            "Tábara Arriba",
            "Tamayo",
            "Tamboril",
            "Tenares",
            "Tireo Arriba",
            "Vallejuelo",
            "Veragua Arriba",
            "Vicente Noble",
            "Villa Altagracia",
            "Villa Bisonó",
            "Villa Consuelo",
            "Villa Elisa",
            "Villa Francisca",
            "Villa González",
            "Villa Isabela",
            "Villa Jaragua",
            "Villa Riva",
            "Villa Tapia",
            "Villa Vásquez",
            "Villarpando",
            "Yaguate",
            "Yamasá",
            "Yayas de Viajama"
        ]
    },
    {
        "label": "East Timor",
        "code": "TL",
        "phone": "670",
        "cities": [
            "Aileu",
            "Ainaro",
            "Alas",
            "Atauro Island",
            "Baguia",
            "Barique",
            "Baucau",
            "Baukau",
            "Bazartete",
            "Cristo Rei",
            "Dili",
            "Ermera Villa",
            "Fatuberliu",
            "Fatumean",
            "Fohorem",
            "Gleno",
            "Hato-Udo",
            "Hatulia",
            "Iliomar",
            "Laclo",
            "Laclubar",
            "Lacluta",
            "Laga",
            "Lautem",
            "Lequidoe",
            "Letefoho",
            "Likisá",
            "Lospalos",
            "Luro",
            "Maliana",
            "Manatuto",
            "Manatutu",
            "Maubara",
            "Maucatar",
            "Metinaro",
            "Ossu",
            "Quelicai",
            "Railaco",
            "Remexio",
            "Same",
            "Soibada",
            "Suai",
            "Tilomar",
            "Turiscai",
            "Tutuala",
            "Uatocarabau",
            "Uatolari",
            "Vemasse",
            "Venilale",
            "Viqueque"
        ]
    },
    {
        "label": "Ecuador",
        "code": "EC",
        "phone": "593",
        "cities": [
            "Alausí",
            "Alfredo Baquerizo Moreno",
            "Ambato",
            "Archidona",
            "Atuntaqui",
            "Azogues",
            "Babahoyo",
            "Bahía de Caráquez",
            "Baláo",
            "Balzar",
            "Baños",
            "Boca Suno",
            "Calceta",
            "Cañar",
            "Cantón Portoviejo",
            "Cantón San Fernando",
            "Catarama",
            "Cayambe",
            "Chone",
            "Colimes",
            "Coronel Marcelino Maridueña",
            "Cotacachi",
            "Cuenca",
            "El Ángel",
            "El Triunfo",
            "Eloy Alfaro",
            "Esmeraldas",
            "Francisco de Orellana Canton",
            "Gualaceo",
            "Gualaquiza",
            "Guano",
            "Guaranda",
            "Guayaquil",
            "Huaquillas",
            "Ibarra",
            "Jipijapa",
            "Junín",
            "La Libertad",
            "La Maná",
            "La Troncal",
            "La Unión",
            "Latacunga",
            "Llacao",
            "Loja",
            "Lomas de Sargentillo",
            "Loreto Canton",
            "Macas",
            "Machachi",
            "Machala",
            "Manta",
            "Milagro",
            "Montalvo",
            "Montecristi",
            "Muisne",
            "Naranjal",
            "Naranjito",
            "Nueva Loja",
            "Nulti",
            "Otavalo",
            "Paján",
            "Palenque",
            "Palestina",
            "Palora",
            "Pampanal de Bolívar",
            "Pasaje",
            "Pedernales",
            "Pedro Carbo",
            "Pelileo",
            "Píllaro",
            "Pimampiro",
            "Piñas",
            "Playas",
            "Portovelo",
            "Portoviejo",
            "Puerto Ayora",
            "Puerto Baquerizo Moreno",
            "Puerto Bolívar",
            "Puerto Francisco de Orellana",
            "Puerto Villamil",
            "Pujilí",
            "Puyo",
            "Quevedo",
            "Quito",
            "Rio Verde",
            "Riobamba",
            "Rocafuerte",
            "Rosa Zarate",
            "Salinas",
            "Samborondón",
            "San Gabriel",
            "San Lorenzo de Esmeraldas",
            "San Miguel",
            "San Miguel de Salcedo",
            "San Vicente",
            "Sangolquí",
            "Santa Ana",
            "Santa Elena",
            "Santa Lucía",
            "Santa Rosa",
            "Santo Domingo de los Colorados",
            "Saquisilí",
            "Sucre",
            "Sucúa",
            "Tena",
            "Tosagua",
            "Tulcán",
            "Tutamandahostel",
            "Valdez",
            "Velasco Ibarra",
            "Ventanas",
            "Vinces",
            "Yaguachi Nuevo",
            "Yantzaza",
            "Zamora",
            "Zaruma"
        ]
    },
    {
        "label": "Egypt",
        "code": "EG",
        "phone": "20",
        "cities": [
            "‘Izbat al Burj",
            "10th of Ramadan",
            "Abnūb",
            "Abū al Maţāmīr",
            "Abu Qir",
            "Abū Qurqāş",
            "Abu Simbel",
            "Abū Tīj",
            "Ad Dilinjāt",
            "Agami",
            "Ain Sukhna",
            "Ajā",
            "Akhmīm",
            "Al ‘Alamayn",
            "Al ‘Ayyāţ",
            "Al Badārī",
            "Al Bājūr",
            "Al Balyanā",
            "Al Bawīţī",
            "Al Fashn",
            "Al Fayyūm",
            "Al Ḩāmūl",
            "Al Ḩawāmidīyah",
            "Al Jammālīyah",
            "Al Khānkah",
            "Al Khārijah",
            "Al Maḩallah al Kubrá",
            "Al Manshāh",
            "Al Manşūrah",
            "Al Manzalah",
            "Al Maţarīyah",
            "Al Minyā",
            "Al Qanāţir al Khayrīyah",
            "Al Qurein",
            "Al Quşayr",
            "Al Qūşīyah",
            "Al Wāsiţah",
            "Alexandria",
            "Ar-Raml",
            "Arish",
            "Aş Şaff",
            "Ash Shuhadā’",
            "Ashmūn",
            "Aswan",
            "Asyūţ",
            "Awlad Saqr",
            "Awsīm",
            "Az Zarqā",
            "Badr",
            "Banhā",
            "Banī Mazār",
            "Banī Suwayf",
            "Basyūn",
            "Bilbeis",
            "Bilqās",
            "Borg El Arab",
            "Bulaq",
            "Būsh",
            "Cairo",
            "Dahab",
            "Damanhūr",
            "Damietta",
            "Dayr Mawās",
            "Dayrūţ",
            "Dikirnis",
            "Dishnā",
            "Disūq",
            "Diyarb Negm",
            "El Gouna",
            "El Husseiniya",
            "El Mataria",
            "El-Tor",
            "Faqous",
            "Fāraskūr",
            "Farshūţ",
            "Fustat",
            "Fuwwah",
            "Giza",
            "Hadayek El Kobba",
            "Ḩawsh ‘Īsá",
            "Heliopolis",
            "Helwan",
            "Hihya",
            "Hurghada",
            "Ibshawāy",
            "Idfū",
            "Idkū",
            "Ismailia",
            "Isnā",
            "Iţsā",
            "Jirjā",
            "Juhaynah",
            "Kafr ad Dawwār",
            "Kafr ash Shaykh",
            "Kafr az Zayyāt",
            "Kafr Saqr",
            "Kawm Ḩamādah",
            "Kawm Umbū",
            "Kousa",
            "Luxor",
            "Maadi",
            "Madīnat Sittah Uktūbar",
            "Makadi Bay",
            "Mallawī",
            "Manfalūţ",
            "Markaz Abū Ḩammād",
            "Markaz al Uqşur",
            "Markaz Disūq",
            "Markaz Jirjā",
            "Markaz Sūhāj",
            "Marsa Alam",
            "Mashtoul El Souk",
            "Maţāy",
            "Mersa Matruh",
            "Minya El Qamh",
            "Minyat an Naşr",
            "Montaza",
            "Munshāt ‘Alī Āghā",
            "Munūf",
            "Musturud",
            "Naja' Ḥammādī",
            "New Administrative Capital of Egypt",
            "New Borg El Arab",
            "New Salhia",
            "Nuwaybi‘a",
            "Port Said",
            "Qalyūb",
            "Qaşr al Farāfirah",
            "Qinā",
            "Quţūr",
            "Quwaysinā",
            "Ras Gharib",
            "Rosetta",
            "Safaga",
            "Saint Catherine",
            "Samālūţ",
            "Samannūd",
            "Sharm el-Sheikh",
            "Shibīn al Kawm",
            "Shibīn al Qanāṭir",
            "Shirbīn",
            "Shubra",
            "Sidi Bishr",
            "Sīdī Sālim",
            "Siwa Oasis",
            "Sohag",
            "Suez",
            "Sumusţā as Sulţānī",
            "Ţahţā",
            "Talā",
            "Ţalkhā",
            "Ţāmiyah",
            "Tanda",
            "Toukh",
            "Tura",
            "Zagazig",
            "Zefta"
        ]
    },
    {
        "label": "El Salvador",
        "code": "SV",
        "phone": "503",
        "cities": [
            "Acajutla",
            "Aguilares",
            "Ahuachapán",
            "Anamorós",
            "Antiguo Cuscatlán",
            "Apastepeque",
            "Apopa",
            "Armenia",
            "Atiquizaya",
            "Ayutuxtepeque",
            "Berlín",
            "Cacaopera",
            "Candelaria de La Frontera",
            "Chalatenango",
            "Chalchuapa",
            "Chapeltique",
            "Chinameca",
            "Chirilagua",
            "Ciudad Arce",
            "Ciudad Barrios",
            "Coatepeque",
            "Cojutepeque",
            "Concepción Batres",
            "Concepción de Ataco",
            "Conchagua",
            "Corinto",
            "Cuscatancingo",
            "Delgado",
            "El Congo",
            "El Paisnal",
            "El Rosario",
            "El Tránsito",
            "Guatajiagua",
            "Guaymango",
            "Guazapa",
            "Ilopango",
            "Intipucá",
            "Izalco",
            "Jiquilisco",
            "Jocoro",
            "Juayúa",
            "Jucuapa",
            "Jucuarán",
            "Jujutla",
            "La Libertad",
            "La Unión",
            "Lolotique",
            "Mejicanos",
            "Metapán",
            "Moncagua",
            "Nahuizalco",
            "Nueva Concepción",
            "Nueva Esparta",
            "Nueva Guadalupe",
            "Nuevo Cuscatlán",
            "Olocuilta",
            "Ozatlán",
            "Panchimalco",
            "Pasaquina",
            "Puerto El Triunfo",
            "Quezaltepeque",
            "Rosario de Mora",
            "San Agustín",
            "San Alejo",
            "San Antonio del Monte",
            "San Francisco",
            "San Francisco Menéndez",
            "San Juan Opico",
            "San Marcos",
            "San Martín",
            "San Miguel",
            "San Pablo Tacachico",
            "San Pedro Masahuat",
            "San Rafael Oriente",
            "San Salvador",
            "San Sebastián",
            "San Vicente",
            "Santa Ana",
            "Santa Elena",
            "Santa Rosa de Lima",
            "Santa Tecla",
            "Santiago de María",
            "Santiago Nonualco",
            "Santo Tomás",
            "Sensuntepeque",
            "Sesori",
            "Sociedad",
            "Sonsonate",
            "Sonzacate",
            "Soyapango",
            "Suchitoto",
            "Tacuba",
            "Tecoluca",
            "Tenancingo",
            "Texistepeque",
            "Tonacatepeque",
            "Usulután",
            "Victoria",
            "Zacatecoluca",
            "Zaragoza"
        ]
    },
    {
        "label": "Equatorial Guinea",
        "code": "GQ",
        "phone": "240",
        "cities": [
            "Aconibe",
            "Acurenam",
            "Añisoc",
            "Ayene",
            "Bata",
            "Bicurga",
            "Bitica",
            "Cogo",
            "Ebebiyin",
            "Evinayong",
            "Luba",
            "Machinda",
            "Malabo",
            "Mbini",
            "Mengomeyén",
            "Mikomeseng",
            "Mongomo",
            "Ncue",
            "Nsang",
            "Nsok",
            "Rebola",
            "Río Campo",
            "San Antonio de Palé",
            "Santiago de Baney"
        ]
    },
    {
        "label": "Eritrea",
        "code": "ER",
        "phone": "291",
        "cities": [
            "Adi Keyh",
            "Ak’ordat",
            "Asmara",
            "Assab",
            "Barentu",
            "Dek’emhāre",
            "Edd",
            "Keren",
            "Massawa",
            "Mendefera",
            "Teseney"
        ]
    },
    {
        "label": "Estonia",
        "code": "EE",
        "phone": "372",
        "cities": [
            "Abja-Paluoja",
            "Alatskivi",
            "Anija vald",
            "Antsla",
            "Antsla vald",
            "Aruküla",
            "Aseri",
            "Audru",
            "Elva",
            "Haabneeme",
            "Haapsalu",
            "Haapsalu linn",
            "Haljala",
            "Haljala vald",
            "Harku",
            "Harku vald",
            "Hullo",
            "Iisaku",
            "Järva-Jaani",
            "Järvakandi",
            "Jõelähtme vald",
            "Jõgeva",
            "Jõgeva vald",
            "Jõhvi",
            "Jõhvi vald",
            "Jüri",
            "Kadrina",
            "Kadrina vald",
            "Kallaste",
            "Kambja vald",
            "Kanepi",
            "Kanepi vald",
            "Kärdla",
            "Karksi-Nuia",
            "Kehra",
            "Kehtna",
            "Kehtna vald",
            "Keila",
            "Kihnu vald",
            "Kiili",
            "Kiili vald",
            "Kilingi-Nõmme",
            "Kiviõli",
            "Koeru",
            "Kohila",
            "Kohila vald",
            "Kohtla-Järve",
            "Kohtla-Nõmme",
            "Kõrveküla",
            "Kose",
            "Kunda",
            "Kurepalu",
            "Kuressaare",
            "Kuusalu",
            "Laagri",
            "Lääne-Nigula vald",
            "Lihula",
            "Liiva",
            "Linaküla",
            "Loksa",
            "Loksa linn",
            "Loo",
            "Lüganuse vald",
            "Luunja",
            "Luunja vald",
            "Maardu",
            "Maardu linn",
            "Märjamaa",
            "Märjamaa vald",
            "Mõisaküla",
            "Muhu vald",
            "Mustvee",
            "Narva",
            "Narva-Jõesuu",
            "Narva-Jõesuu linn",
            "Nõo",
            "Nõo vald",
            "Orissaare",
            "Otepää vald",
            "Paide",
            "Paide linn",
            "Paikuse",
            "Pajusti",
            "Paldiski",
            "Pärnu",
            "Pärnu linn",
            "Pärnu-Jaagupi",
            "Peipsiääre vald",
            "Põltsamaa",
            "Põltsamaa vald",
            "Põlva",
            "Põlva vald",
            "Pringi",
            "Puhja",
            "Püssi",
            "Raasiku",
            "Rae vald",
            "Rakke",
            "Rakvere",
            "Rakvere vald",
            "Räpina",
            "Räpina vald",
            "Rapla",
            "Rapla vald",
            "Riisipere",
            "Rõuge",
            "Rõuge vald",
            "Ruhnu",
            "Ruhnu vald",
            "Rummu",
            "Saarde vald",
            "Saku",
            "Saku vald",
            "Särevere",
            "Saue",
            "Saue vald",
            "Sauga",
            "Sillamäe",
            "Sindi",
            "Sõmeru",
            "Suure-Jaani",
            "Tabasalu",
            "Taebla",
            "Tallinn",
            "Tamsalu",
            "Tapa",
            "Tapa vald",
            "Tartu",
            "Tartu linn",
            "Tartu vald",
            "Tehumardi",
            "Toila",
            "Tootsi",
            "Tori vald",
            "Tõrva",
            "Turba",
            "Türi",
            "Türi vald",
            "Ülenurme",
            "Uuemõisa",
            "Uulu",
            "Vaiatu",
            "Vaida",
            "Väike-Maarja",
            "Väike-Maarja vald",
            "Valga",
            "Vana-Antsla",
            "Vändra",
            "Värska",
            "Viimsi",
            "Viimsi vald",
            "Viiratsi",
            "Viljandi",
            "Viljandi vald",
            "Vinni",
            "Vinni vald",
            "Viru-Nigula vald",
            "Võhma",
            "Voka",
            "Vormsi vald",
            "Võru",
            "Võru vald"
        ]
    },
    {
        "label": "Ethiopia",
        "code": "ET",
        "phone": "251",
        "cities": [
            "Abomsa",
            "Addiet Canna",
            "Addis Ababa",
            "Ādīgrat",
            "Ādīs ‘Alem",
            "Ādīs Zemen",
            "Administrative Zone 1",
            "Administrative Zone 2",
            "Administrative Zone 3",
            "Afder Zone",
            "Āgaro",
            "Alaba Special Wereda",
            "Arba Minch",
            "Āreka",
            "Arsi Zone",
            "Asaita",
            "Āsasa",
            "Āsbe Teferī",
            "Asosa",
            "Āwash",
            "Axum",
            "Bahir Dar",
            "Bako",
            "Batī",
            "Bedelē",
            "Bedēsa",
            "Bench Maji Zone",
            "Bichena",
            "Bishoftu",
            "Bodītī",
            "Bonga",
            "Burē",
            "Butajīra",
            "Dabat",
            "Debark’",
            "Debre Birhan",
            "Debre Mark’os",
            "Debre Sīna",
            "Debre Tabor",
            "Debre Werk’",
            "Deder",
            "Degehabur Zone",
            "Dejen",
            "Dembī Dolo",
            "Desē",
            "Dīla",
            "Dire Dawa",
            "Dodola",
            "Dubti",
            "East Harerghe Zone",
            "East Shewa Zone",
            "East Wellega Zone",
            "Felege Neway",
            "Fichē",
            "Finote Selam",
            "Gambēla",
            "Gebre Guracha",
            "Gedeo Zone",
            "Gēdo",
            "Gelemso",
            "Genet",
            "Gewanē",
            "Gīdolē",
            "Gimbi",
            "Ginir",
            "Goba",
            "Gode Zone",
            "Gondar",
            "Gorē",
            "Guji Zone",
            "Guraghe Zone",
            "Hadiya Zone",
            "Hāgere Hiywet",
            "Hagere Maryam",
            "Hāgere Selam",
            "Harar",
            "Hawassa",
            "Hīrna",
            "Hosa’ina",
            "Huruta",
            "Illubabor Zone",
            "Inda Silasē",
            "Jijiga",
            "Jimma",
            "Jimma Zone",
            "Jinka",
            "K’olīto",
            "Kembata Alaba Tembaro Zone",
            "Kemisē",
            "Kibre Mengist",
            "Kofelē",
            "Kombolcha",
            "Konso",
            "Korem",
            "Lalībela",
            "Leku",
            "Liben zone",
            "Lobuni",
            "Maych’ew",
            "Mēga",
            "Mek'ele",
            "Mendī",
            "Metahāra",
            "Metekel",
            "Metu",
            "Mīzan Teferī",
            "Mojo",
            "Nazrēt",
            "Nejo",
            "North Shewa Zone",
            "North Shewa Zone",
            "North Wollo Zone",
            "Robīt",
            "Sebeta",
            "Semera",
            "Sendafa",
            "Shakiso",
            "Shambu",
            "Shashemenē",
            "Sheka Zone",
            "Shinile Zone",
            "Sidama Zone",
            "Sirre",
            "Sodo",
            "South Gondar Zone",
            "South Wollo Zone",
            "Southeastern Tigray Zone",
            "Southern Tigray Zone",
            "Tippi",
            "Tulu Bolo",
            "Turmi",
            "Wag Hemra Zone",
            "Waliso",
            "Wendo",
            "Wenjī",
            "Were Īlu",
            "Werota",
            "West Harerghe Zone",
            "West Wellega Zone",
            "Wolayita Zone",
            "Yabēlo",
            "Yem",
            "Yirga ‘Alem",
            "Ziway"
        ]
    },
    {
        "label": "Falkland Islands",
        "code": "FK",
        "phone": "500",
        "cities": []
    },
    {
        "label": "Faroe Islands",
        "code": "FO",
        "phone": "298",
        "cities": []
    },
    {
        "label": "Fiji Islands",
        "code": "FJ",
        "phone": "679",
        "cities": [
            "Ba",
            "Ba Province",
            "Bua Province",
            "Cakaudrove Province",
            "Kadavu Province",
            "Labasa",
            "Lau Province",
            "Lautoka",
            "Levuka",
            "Lomaiviti Province",
            "Macuata Province",
            "Nadi",
            "Naitasiri Province",
            "Namosi Province",
            "Nandronga and Navosa Province",
            "Ra Province",
            "Rewa Province",
            "Serua Province",
            "Suva",
            "Tailevu Province"
        ]
    },
    {
        "label": "Finland",
        "code": "FI",
        "phone": "358",
        "cities": [
            "Äänekoski",
            "Äetsä",
            "Ähtäri",
            "Aimala",
            "Aitoo",
            "Akaa",
            "Alahärmä",
            "Alajärvi",
            "Alastaro",
            "Alavieska",
            "Alavus",
            "Amuri",
            "Anjala",
            "Artjärvi",
            "Asikkala",
            "Askainen",
            "Askola",
            "Aura",
            "Auttoinen",
            "Brändö",
            "Dragsfjärd",
            "Eckerö",
            "Ekenäs",
            "Elimäki",
            "Eno",
            "Enonkoski",
            "Enontekiö",
            "Espoo",
            "Eura",
            "Eurajoki",
            "Evijärvi",
            "Finström",
            "Föglö",
            "Forssa",
            "Geta",
            "Gumböle",
            "Haapajärvi",
            "Haapavesi",
            "Haihara",
            "Hailuoto",
            "Halikko",
            "Hallila",
            "Halsua",
            "Hämeenkoski",
            "Hämeenkyrö",
            "Hämeenlinna",
            "Hamina",
            "Hammarland",
            "Hankasalmi",
            "Hanko",
            "Harjavalta",
            "Hartola",
            "Hauho",
            "Haukipudas",
            "Haukivuori",
            "Hausjärvi",
            "Heinävesi",
            "Heinola",
            "Helsinki",
            "Hervanta",
            "Hietalahti",
            "Himanka",
            "Hirvensalmi",
            "Hollola",
            "Houtskär",
            "Huittinen",
            "Humppila",
            "Hyrynsalmi",
            "Hyvinge",
            "Ii",
            "Iitti",
            "Ikaalinen",
            "Ilmajoki",
            "Ilomantsi",
            "Imatra",
            "Inari",
            "Ingå",
            "Iniö",
            "Isojoki",
            "Isokyrö",
            "Ivalo",
            "Jaala",
            "Jakobstad",
            "Jalasjärvi",
            "Jämijärvi",
            "Jämsä",
            "Jämsänkoski",
            "Janakkala",
            "Jäppilä",
            "Järvenpää",
            "Joensuu",
            "Jokioinen",
            "Jomala",
            "Joroinen",
            "Joutsa",
            "Joutseno",
            "Jurva",
            "Juuka",
            "Juupajoki",
            "Juva",
            "Jyväskylä",
            "Kaarela",
            "Kaarina",
            "Kajaani",
            "Kalajoki",
            "Kallio",
            "Kälviä",
            "Kalvola",
            "Kangasala",
            "Kangasniemi",
            "Kankaanpää",
            "Kannonkoski",
            "Kannus",
            "Karhula",
            "Karijoki",
            "Karinainen",
            "Karis",
            "Karjalohja",
            "Karkkila",
            "Kärkölä",
            "Kärsämäki",
            "Karstula",
            "Karvia",
            "Kaskinen",
            "Kauhajoki",
            "Kauhava",
            "Kauniainen",
            "Kaustinen",
            "Kellokoski",
            "Kemi",
            "Kemijärvi",
            "Keminmaa",
            "Kempele",
            "Kerava",
            "Kerimäki",
            "Kesälahti",
            "Kestilä",
            "Keuruu",
            "Kihniö",
            "Kiihtelysvaara",
            "Kiikala",
            "Kiikka",
            "Kiikoinen",
            "Kiiminki",
            "Kilo",
            "Kimito",
            "Kinnula",
            "Kirkkonummi",
            "Kisko",
            "Kitee",
            "Kittilä",
            "Kiukainen",
            "Kivijärvi",
            "Kokemäki",
            "Kokkola",
            "Kolari",
            "Kolho",
            "Konnevesi",
            "Kontiolahti",
            "Korpilahti",
            "Korsholm",
            "Korsnäs",
            "Kortesjärvi",
            "Kotka",
            "Koukkuniemi",
            "Kouvola",
            "Köyliö",
            "Kristinestad",
            "Kronoby",
            "Kuhmalahti",
            "Kuhmo",
            "Kuhmoinen",
            "Kuivaniemi",
            "Kullaa",
            "Kumlinge",
            "Kuortane",
            "Kurikka",
            "Kuru",
            "Kustavi",
            "Kuusamo",
            "Kuusjoki",
            "Kylmäkoski",
            "Kyyjärvi",
            "Lahti",
            "Laihia",
            "Laitila",
            "Lammi",
            "Längelmäki",
            "Längelmäki",
            "Lapinjärvi",
            "Lappajärvi",
            "Lappeenranta",
            "Lappi",
            "Lapua",
            "Larsmo",
            "Laukaa",
            "Lauttasaari",
            "Lavia",
            "Lehtimäki",
            "Leivonmäki",
            "Lemi",
            "Lemland",
            "Lempäälä",
            "Lemu",
            "Lentävänniemi",
            "Lestijärvi",
            "Lieksa",
            "Lielahti",
            "Lieto",
            "Liljendal",
            "Liminka",
            "Liperi",
            "Lohja",
            "Lohtaja",
            "Loppi",
            "Lovisa",
            "Luhanka",
            "Lumijoki",
            "Luopioinen",
            "Luumäki",
            "Luvia",
            "Malax",
            "Mäntsälä",
            "Mänttä",
            "Mäntyharju",
            "Mariehamn",
            "Marttila",
            "Masku",
            "Mellilä",
            "Mellunkylä",
            "Merijärvi",
            "Merikarvia",
            "Merimasku",
            "Messukylä",
            "Miehikkälä",
            "Mietoinen",
            "Mikkeli",
            "Mouhijärvi",
            "Muhos",
            "Multia",
            "Munkkiniemi",
            "Muonio",
            "Muurame",
            "Muurla",
            "Mynämäki",
            "Myrskylä",
            "Naantali",
            "Nagu",
            "Naistenmatka",
            "Nakkila",
            "Nastola",
            "Nekala",
            "Nivala",
            "Nokia",
            "Noormarkku",
            "Nousiainen",
            "Nuijamaa",
            "Nurmes",
            "Nurmi",
            "Nurmijärvi",
            "Nurmo",
            "Nuutajärvi",
            "Nykarleby",
            "Oravais",
            "Orimattila",
            "Oripää",
            "Orivesi",
            "Osara",
            "Otaniemi",
            "Oulainen",
            "Oulu",
            "Oulunsalo",
            "Outokumpu",
            "Padasjoki",
            "Paimio",
            "Pälkäne",
            "Paltamo",
            "Pargas",
            "Parikkala",
            "Parkano",
            "Pedersöre",
            "Pelkosenniemi",
            "Pello",
            "Perho",
            "Pernå",
            "Perniö",
            "Pertteli",
            "Pertunmaa",
            "Petäjävesi",
            "Pieksämäki",
            "Pihtipudas",
            "Piikkiö",
            "Piippola",
            "Pirkkala",
            "Pispala",
            "Pohja",
            "Pohjaslahti",
            "Polvijärvi",
            "Pomarkku",
            "Pori",
            "Pornainen",
            "Porvoo",
            "Posio",
            "Pöytyä",
            "Pudasjärvi",
            "Pukkila",
            "Pulkkila",
            "Punkaharju",
            "Punkalaidun",
            "Puolanka",
            "Puumala",
            "Pyhäjärvi",
            "Pyhäjärvi",
            "Pyhäjoki",
            "Pyhäntä",
            "Pyhäranta",
            "Pyhäselkä",
            "Pyhtää",
            "Pylkönmäki",
            "Raahe",
            "Rääkkylä",
            "Raaseporin",
            "Raisio",
            "Rantasalmi",
            "Rantsila",
            "Ranua",
            "Rauma",
            "Rautjärvi",
            "Reisjärvi",
            "Renko",
            "Replot",
            "Riihimäki",
            "Ristiina",
            "Ristijärvi",
            "Ristinummi",
            "Rovaniemi",
            "Ruokolahti",
            "Ruotsinpyhtää",
            "Ruovesi",
            "Rusko",
            "Ruukki",
            "Rymättylä",
            "Sääksmäki",
            "Saari",
            "Saarijärvi",
            "Sahalahti",
            "Säkylä",
            "Salla",
            "Salo",
            "Saltvik",
            "Sammatti",
            "Särkisalo",
            "Sastamala",
            "Sauvo",
            "Savitaipale",
            "Savonlinna",
            "Savonranta",
            "Savukoski",
            "Säynätsalo",
            "Seinäjoki",
            "Sibbo",
            "Sievi",
            "Siikainen",
            "Siikajoki",
            "Simo",
            "Siuntio",
            "Sodankylä",
            "Soini",
            "Somero",
            "Sotkamo",
            "Sulkava",
            "Sumiainen",
            "Sund",
            "Suodenniemi",
            "Suolahti",
            "Suomusjärvi",
            "Suomussalmi",
            "Suoniemi",
            "Sysmä",
            "Taipalsaari",
            "Taivalkoski",
            "Taivassalo",
            "Tammela",
            "Tampere",
            "Tarvasjoki",
            "Teeriniemi",
            "Teisko",
            "Tervakoski",
            "Tervola",
            "Teuva",
            "Tohmajärvi",
            "Toholampi",
            "Toijala",
            "Toivakka",
            "Tornio",
            "Tottijärvi",
            "Töysä",
            "Turku",
            "Tuulos",
            "Tuupovaara",
            "Tuusula",
            "Tyrnävä",
            "Ullava",
            "Ulvila",
            "Urjala",
            "Utajärvi",
            "Utsjoki",
            "Uurainen",
            "Uusikaupunki",
            "Vaala",
            "Vaasa",
            "Vähäkyrö",
            "Vahto",
            "Valkeakoski",
            "Valtimo",
            "Vammala",
            "Vampula",
            "Vantaa",
            "Väståboland",
            "Västanfjärd",
            "Vehmaa",
            "Velkua",
            "Vesilahti",
            "Veteli",
            "Vihanti",
            "Vihti",
            "Viiala",
            "Viitasaari",
            "Viljakkala",
            "Vilppula",
            "Vimpeli",
            "Virojoki",
            "Virrat",
            "Virtasalmi",
            "Vörå",
            "Vuokatti",
            "Vuolijoki",
            "Vuores",
            "Vuosaari",
            "Ylämaa",
            "Yläne",
            "Yli-Ii",
            "Ylihärmä",
            "Ylikiiminki",
            "Ylistaro",
            "Ylitornio",
            "Ylivieska",
            "Ylöjärvi",
            "Ypäjä"
        ]
    },
    {
        "label": "France",
        "code": "FR",
        "phone": "33",
        "cities": [
            "Abbaretz",
            "Abbeville",
            "Abeilhan",
            "Abilly",
            "Ablain-Saint-Nazaire",
            "Ableiges",
            "Ablis",
            "Ablon",
            "Ablon-sur-Seine",
            "Abondance",
            "Abondant",
            "Abreschviller",
            "Abrest",
            "Abscon",
            "Abzac",
            "Achenheim",
            "Achères",
            "Achères-la-Forêt",
            "Achicourt",
            "Achiet-le-Grand",
            "Acigné",
            "Acquigny",
            "Afa",
            "Agde",
            "Agen",
            "Agneaux",
            "Agnetz",
            "Agny",
            "Agon-Coutainville",
            "Agonac",
            "Ahetze",
            "Ahuillé",
            "Ahun",
            "Ahuy",
            "Aiffres",
            "Aiglemont",
            "Aigné",
            "Aigre",
            "Aigrefeuille-sur-Maine",
            "Aigueblanche",
            "Aiguefonde",
            "Aigueperse",
            "Aigues-Mortes",
            "Aigues-Vives",
            "Aiguillon",
            "Aigurande",
            "Aillant-sur-Tholon",
            "Aillevillers-et-Lyaumont",
            "Ailly-sur-Noye",
            "Ailly-sur-Somme",
            "Aimargues",
            "Aime",
            "Ainay-le-Château",
            "Airaines",
            "Aire-sur-la-Lys",
            "Airvault",
            "Aiserey",
            "Aiton",
            "Aix-en-Othe",
            "Aix-en-Provence",
            "Aix-les-Bains",
            "Aix-Noulette",
            "Aixe-sur-Vienne",
            "Aizenay",
            "Ajaccio",
            "Ajain",
            "Alata",
            "Alba-la-Romaine",
            "Albens",
            "Albert",
            "Albertville",
            "Albi",
            "Albias",
            "Albigny-sur-Saône",
            "Albitreccia",
            "Alby-sur-Chéran",
            "Alençon",
            "Alénya",
            "Aléria",
            "Alès",
            "Alfortville",
            "Algolsheim",
            "Algrange",
            "Alignan-du-Vent",
            "Alissas",
            "Alixan",
            "Alizay",
            "Allaire",
            "Allan",
            "Allanche",
            "Allassac",
            "Allauch",
            "Allègre",
            "Alleins",
            "Allennes-les-Marais",
            "Allevard",
            "Allex",
            "Allières-et-Risset",
            "Allinges",
            "Allonne",
            "Allonnes",
            "Allonzier-la-Caille",
            "Allouagne",
            "Alpes-de-Haute-Provence",
            "Alpes-Maritimes",
            "Alsting",
            "Althen-des-Paluds",
            "Altkirch",
            "Altorf",
            "Alzonne",
            "Amancy",
            "Amanlis",
            "Amanvillers",
            "Ambarès-et-Lagrave",
            "Ambazac",
            "Ambérieu-en-Bugey",
            "Ambérieux-en-Dombes",
            "Ambert",
            "Ambès",
            "Ambierle",
            "Ambillou",
            "Amblainville",
            "Ambleny",
            "Ambleteuse",
            "Amboise",
            "Ambon",
            "Ambrières-les-Vallées",
            "Ambronay",
            "Amfreville",
            "Amfreville-la-Mi-Voie",
            "Amiens",
            "Amilly",
            "Ammerschwihr",
            "Amnéville",
            "Amou",
            "Amplepuis",
            "Ampuis",
            "Ancenis",
            "Ancerville",
            "Ancône",
            "Ancy-le-Franc",
            "Ancy-sur-Moselle",
            "Andance",
            "Andancette",
            "Andard",
            "Andé",
            "Andelnans",
            "Andernos-les-Bains",
            "Andeville",
            "Andilly",
            "Andilly",
            "Andlau",
            "Andolsheim",
            "Andouillé",
            "Andres",
            "Andrest",
            "Andrésy",
            "Andrezé",
            "Andrézieux-Bouthéon",
            "Anduze",
            "Anet",
            "Anetz",
            "Angers",
            "Angerville",
            "Angerville-l’Orcher",
            "Angervilliers",
            "Angevillers",
            "Angicourt",
            "Angles",
            "Anglet",
            "Angoulême",
            "Angoulins",
            "Angres",
            "Angresse",
            "Angy",
            "Anhiers",
            "Aniane",
            "Aniche",
            "Anizy-le-Château",
            "Annay",
            "Annecy",
            "Annecy-le-Vieux",
            "Annemasse",
            "Annequin",
            "Annesse-et-Beaulieu",
            "Annet-sur-Marne",
            "Anneyron",
            "Annezin",
            "Annoeullin",
            "Annonay",
            "Annot",
            "Anor",
            "Anould",
            "Ansauvillers",
            "Anse",
            "Ansouis",
            "Anstaing",
            "Anthy-sur-Léman",
            "Antibes",
            "Antigny",
            "Antonne-et-Trigonant",
            "Antony",
            "Antrain",
            "Antran",
            "Anzin",
            "Anzin-Saint-Aubin",
            "Aoste",
            "Aouste-sur-Sye",
            "Appietto",
            "Appoigny",
            "Apprieu",
            "Apremont",
            "Apt",
            "Arâches-la-Frasse",
            "Aramon",
            "Arbent",
            "Arbois",
            "Arbonne",
            "Arbonne-la-Forêt",
            "Arbouans",
            "Arbus",
            "Arc-et-Senans",
            "Arc-lès-Gray",
            "Arc-sur-Tille",
            "Arcachon",
            "Arcangues",
            "Arcey",
            "Archamps",
            "Arches",
            "Archettes",
            "Archigny",
            "Arcis-sur-Aube",
            "Arçonnay",
            "Arcueil",
            "Ardennes",
            "Ardentes",
            "Ardin",
            "Ardres",
            "Arenc",
            "Arenthon",
            "Arès",
            "Arette",
            "Argancy",
            "Argelers",
            "Argelès-Gazost",
            "Argeliers",
            "Argences",
            "Argent-sur-Sauldre",
            "Argentan",
            "Argentat",
            "Argenteuil",
            "Argenton-les-Vallées",
            "Argenton-sur-Creuse",
            "Argentré",
            "Argentré-du-Plessis",
            "Arinthod",
            "Arlanc",
            "Arles",
            "Arles",
            "Arleux",
            "Armbouts-Cappel",
            "Armenonville-les-Gâtineaux",
            "Armentières",
            "Armentières-en-Brie",
            "Armissan",
            "Armoy",
            "Arnac-la-Poste",
            "Arnac-Pompadour",
            "Arnage",
            "Arnas",
            "Arnay-le-Duc",
            "Arnèke",
            "Arnières-sur-Iton",
            "Arnouville",
            "Aron",
            "Arpajon",
            "Arpajon-sur-Cère",
            "Arques",
            "Arques-la-Bataille",
            "Arradon",
            "Arras",
            "Arrou",
            "Ars-en-Ré",
            "Ars-sur-Formans",
            "Ars-sur-Moselle",
            "Arsac",
            "Art-sur-Meurthe",
            "Artannes-sur-Indre",
            "Artas",
            "Artemare",
            "Artenay",
            "Arthaz-Pont-Notre-Dame",
            "Arthès",
            "Arthez-de-Béarn",
            "Arthon",
            "Arthon-en-Retz",
            "Artiguelouve",
            "Artigues-près-Bordeaux",
            "Artix",
            "Artres",
            "Arudy",
            "Arvert",
            "Arveyres",
            "Arzano",
            "Arzens",
            "Arzon",
            "Ascain",
            "Aschères-le-Marché",
            "Asnières-sur-Nouère",
            "Asnières-sur-Oise",
            "Asnières-sur-Seine",
            "Aspach",
            "Aspach-le-Bas",
            "Aspach-le-Haut",
            "Aspet",
            "Aspiran",
            "Aspremont",
            "Assas",
            "Assat",
            "Assérac",
            "Asson",
            "Astaffort",
            "Athée-sur-Cher",
            "Athies-sous-Laon",
            "Athis-de-l'Orne",
            "Athis-Mons",
            "Attainville",
            "Attiches",
            "Attichy",
            "Attignat",
            "Attigny",
            "Atur",
            "Aubagne",
            "Aubais",
            "Aube-sur-Rîle",
            "Aubenas",
            "Auberchicourt",
            "Aubergenville",
            "Auberives-sur-Varèze",
            "Aubers",
            "Aubervilliers",
            "Aubevoye",
            "Aubie-et-Espessas",
            "Aubière",
            "Aubiet",
            "Aubignan",
            "Aubigné-Racan",
            "Aubigny",
            "Aubigny-au-Bac",
            "Aubigny-en-Artois",
            "Aubigny-sur-Nère",
            "Aubin",
            "Aubord",
            "Auboué",
            "Aubry-du-Hainaut",
            "Aubusson",
            "Auby",
            "Aucamville",
            "Auch",
            "Auchel",
            "Auchy-lès-Hesdin",
            "Auchy-les-Mines",
            "Audenge",
            "Audierne",
            "Audincourt",
            "Audruicq",
            "Audun-le-Roman",
            "Audun-le-Tiche",
            "Auffargis",
            "Auffay",
            "Augan",
            "Augny",
            "Augy",
            "Aulnat",
            "Aulnay",
            "Aulnay-sous-Bois",
            "Aulnay-sur-Mauldre",
            "Aulnois-sous-Laon",
            "Aulnoy-lez-Valenciennes",
            "Aulnoye-Aymeries",
            "Ault",
            "Aumale",
            "Aumetz",
            "Aumont-Aubrac",
            "Aunay-sous-Auneau",
            "Aunay-sur-Odon",
            "Auneau",
            "Auneuil",
            "Aups",
            "Auray",
            "Aurec-sur-Loire",
            "Aureilhan",
            "Aureille",
            "Auriac-sur-Vendinelle",
            "Auribeau-sur-Siagne",
            "Aurignac",
            "Aurillac",
            "Auriol",
            "Aussillon",
            "Aussonne",
            "Auterive",
            "Authie",
            "Authon-du-Perche",
            "Autrans",
            "Autry-le-Châtel",
            "Autun",
            "Auvers-le-Hamon",
            "Auvers-Saint-Georges",
            "Auvers-sur-Oise",
            "Auvillar",
            "Auxerre",
            "Auxi-le-Château",
            "Auxon-Dessous",
            "Auxonne",
            "Auxy",
            "Auzances",
            "Auzat-la-Combelle",
            "Auzebosc",
            "Auzeville-Tolosane",
            "Auzielle",
            "Auzouer-en-Touraine",
            "Availles-Limouzine",
            "Avallon",
            "Avanne-Aveney",
            "Avanton",
            "Aveize",
            "Aveizieux",
            "Avelin",
            "Avensan",
            "Avermes",
            "Avesnelles",
            "Avesnes-le-Comte",
            "Avesnes-le-Sec",
            "Avesnes-les-Aubert",
            "Avessac",
            "Avèze",
            "Avignon",
            "Avignonet-Lauragais",
            "Avilly-Saint-Léonard",
            "Avion",
            "Aviron",
            "Avize",
            "Avoine",
            "Avon",
            "Avord",
            "Avranches",
            "Avrechy",
            "Avrillé",
            "Ax-les-Thermes",
            "Ay",
            "Ay-sur-Moselle",
            "Aydat",
            "Aydoilles",
            "Ayguemorte-les-Graves",
            "Ayguesvives",
            "Ayron",
            "Ayse",
            "Aytré",
            "Azay-le-Brûlé",
            "Azay-le-Ferron",
            "Azay-le-Rideau",
            "Azay-sur-Cher",
            "Azé",
            "Azé",
            "Azille",
            "Baccarat",
            "Bachant",
            "Bachy",
            "Bacqueville-en-Caux",
            "Baden",
            "Badonviller",
            "Bagard",
            "Bâgé-la-Ville",
            "Bages",
            "Bagnac-sur-Célé",
            "Bagneaux-sur-Loing",
            "Bagnères-de-Bigorre",
            "Bagnères-de-Luchon",
            "Bagneux",
            "Bagnoles-de-l'Orne",
            "Bagnolet",
            "Bagnols-en-Forêt",
            "Bagnols-sur-Cèze",
            "Baguer-Morvan",
            "Baguer-Pican",
            "Baho",
            "Baignes-Sainte-Radegonde",
            "Baillargues",
            "Baille",
            "Bailleau-l’Évêque",
            "Bailleau-le-Pin",
            "Baillet-en-France",
            "Bailleul",
            "Bailleul-Sir-Berthoult",
            "Bailleul-sur-Thérain",
            "Bailleval",
            "Bailly",
            "Bailly-Carrois",
            "Bailly-Romainvilliers",
            "Bain-de-Bretagne",
            "Baincthun",
            "Bains",
            "Bains-les-Bains",
            "Bains-sur-Oust",
            "Bainville-sur-Madon",
            "Bais",
            "Bais",
            "Baisieux",
            "Baixas",
            "Balagny-sur-Thérain",
            "Balan",
            "Balan",
            "Balaruc-le-Vieux",
            "Balaruc-les-Bains",
            "Balazé",
            "Balbigny",
            "Baldersheim",
            "Ballainvilliers",
            "Ballaison",
            "Ballan-Miré",
            "Ballancourt-sur-Essonne",
            "Ballon",
            "Ballots",
            "Balma",
            "Balzac",
            "Ban-de-Laveline",
            "Bandol",
            "Bannalec",
            "Bantzenheim",
            "Banyuls de la Marenda",
            "Banyuls-dels-Aspres",
            "Bapaume",
            "Bar-le-Duc",
            "Bar-sur-Aube",
            "Bar-sur-Seine",
            "Baraqueville",
            "Barbaste",
            "Barbâtre",
            "Barbazan-Debat",
            "Barbechat",
            "Barbentane",
            "Barberaz",
            "Barbezieux-Saint-Hilaire",
            "Barbizon",
            "Barby",
            "Barcelonne-du-Gers",
            "Barcelonnette",
            "Bardos",
            "Barentin",
            "Barenton",
            "Bargemon",
            "Barjac",
            "Barjols",
            "Barjouville",
            "Barlin",
            "Barneville-Carteret",
            "Barneville-Plage",
            "Barr",
            "Barraux",
            "Barsac",
            "Bart",
            "Bartenheim",
            "Bas-en-Basset",
            "Bas-Rhin",
            "Bassan",
            "Basse-Goulaine",
            "Basse-Ham",
            "Bassens",
            "Bassens",
            "Bassillac",
            "Bassussarry",
            "Bastia",
            "Batilly",
            "Battenheim",
            "Batz-sur-Mer",
            "Baud",
            "Baugé-en-Anjou",
            "Baugy",
            "Baule",
            "Baulne",
            "Baulon",
            "Baume-les-Dames",
            "Bauné",
            "Bauvin",
            "Bavans",
            "Bavay",
            "Bavent",
            "Bavilliers",
            "Bayard-sur-Marne",
            "Bayeux",
            "Bayon",
            "Bayonne",
            "Bazainville",
            "Bazancourt",
            "Bazas",
            "Bazeilles",
            "Bazemont",
            "Bazet",
            "Baziège",
            "Bazoches-les-Gallerandes",
            "Bazoges-en-Pareds",
            "Bazouges-la-Pérouse",
            "Bazouges-sur-le-Loir",
            "Beaucaire",
            "Beaucamps-le-Vieux",
            "Beaucé",
            "Beauchamp",
            "Beauchamps",
            "Beauchastel",
            "Beaucourt",
            "Beaucouzé",
            "Beaucroissant",
            "Beaufay",
            "Beaufort",
            "Beaufort-en-Vallée",
            "Beaugency",
            "Beaujeu",
            "Beaulieu",
            "Beaulieu-lès-Loches",
            "Beaulieu-sous-la-Roche",
            "Beaulieu-sur-Dordogne",
            "Beaulieu-sur-Layon",
            "Beaulieu-sur-Mer",
            "Beaulon",
            "Beaumes-de-Venise",
            "Beaumetz-lès-Loges",
            "Beaumont",
            "Beaumont",
            "Beaumont-de-Lomagne",
            "Beaumont-de-Pertuis",
            "Beaumont-du-Gâtinais",
            "Beaumont-du-Périgord",
            "Beaumont-en-Véron",
            "Beaumont-Hague",
            "Beaumont-la-Ronce",
            "Beaumont-le-Roger",
            "Beaumont-lès-Valence",
            "Beaumont-Monteux",
            "Beaumont-sur-Lèze",
            "Beaumont-sur-Oise",
            "Beaumont-sur-Sarthe",
            "Beaune",
            "Beaune-la-Rolande",
            "Beaupréau",
            "Beaupuy",
            "Beaupuy",
            "Beauquesne",
            "Beaurains",
            "Beaurainville",
            "Beaurepaire",
            "Beaurepaire",
            "Beaurevoir",
            "Beausemblant",
            "Beausoleil",
            "Beautiran",
            "Beautor",
            "Beauvais",
            "Beauval",
            "Beauvallon",
            "Beauvoir-de-Marc",
            "Beauvoir-sur-Mer",
            "Beauvoir-sur-Niort",
            "Beauvois-en-Cambrésis",
            "Beauvoisin",
            "Beauzac",
            "Beauzelle",
            "Bécon-les-Granits",
            "Bédarieux",
            "Bédarrides",
            "Bédée",
            "Bédoin",
            "Bégaar",
            "Béganne",
            "Bégard",
            "Bègles",
            "Bégrolles-en-Mauges",
            "Behren-lès-Forbach",
            "Beignon",
            "Bélâbre",
            "Belberaud",
            "Belbeuf",
            "Belcodène",
            "Bélesta",
            "Belfort",
            "Belgentier",
            "Béligneux",
            "Belin-Béliet",
            "Bellac",
            "Bellaing",
            "Belle de Mai",
            "Belle-Isle-en-Terre",
            "Belle-Plagne",
            "Bellegarde",
            "Bellegarde",
            "Bellegarde-en-Forez",
            "Bellegarde-sur-Valserine",
            "Bellême",
            "Bellenaves",
            "Belleneuve",
            "Bellengreville",
            "Bellerive-sur-Allier",
            "Belleu",
            "Bellevaux",
            "Belleville",
            "Belleville",
            "Belleville-sur-Loire",
            "Belleville-sur-Meuse",
            "Belleville-sur-Vie",
            "Belley",
            "Bellignat",
            "Belligné",
            "Belloy-en-France",
            "Belmont-de-la-Loire",
            "Belmont-sur-Rance",
            "Belpech",
            "Belsunce",
            "Belvès",
            "Belz",
            "Bénéjacq",
            "Bénesse-Maremne",
            "Benet",
            "Benfeld",
            "Béning-lès-Saint-Avold",
            "Bennecourt",
            "Bennwihr",
            "Bénodet",
            "Bénouville",
            "Benquet",
            "Bérat",
            "Berck",
            "Berck-Plage",
            "Bergerac",
            "Bergheim",
            "Bergholtz",
            "Bergues",
            "Berlaimont",
            "Bernardswiller",
            "Bernaville",
            "Bernay",
            "Berné",
            "Bernes-sur-Oise",
            "Berneval-le-Grand",
            "Bernières-sur-Mer",
            "Bernin",
            "Bernis",
            "Berre-l'Étang",
            "Berre-les-Alpes",
            "Berric",
            "Berrien",
            "Berrwiller",
            "Bersée",
            "Berson",
            "Berstett",
            "Berteaucourt-les-Dames",
            "Berthecourt",
            "Bertrange",
            "Bertry",
            "Béruges",
            "Besançon",
            "Besné",
            "Bessan",
            "Bessancourt",
            "Bessay-sur-Allier",
            "Besse-et-Saint-Anastaise",
            "Bessé-sur-Braye",
            "Besse-sur-Issole",
            "Bessèges",
            "Bessenay",
            "Bessières",
            "Bessines",
            "Bessines-sur-Gartempe",
            "Béthencourt-sur-Mer",
            "Bétheny",
            "Béthisy-Saint-Martin",
            "Béthisy-Saint-Pierre",
            "Bethoncourt",
            "Béthune",
            "Betschdorf",
            "Bettancourt-la-Ferrée",
            "Betton",
            "Betz",
            "Beure",
            "Beuville",
            "Beuvillers",
            "Beuvrages",
            "Beuvry",
            "Beuzec-Cap-Sizun",
            "Beuzeville",
            "Beuzeville-la-Grenier",
            "Béville-le-Comte",
            "Beychac-et-Caillau",
            "Beynat",
            "Beynes",
            "Beynost",
            "Bezannes",
            "Béziers",
            "Bezons",
            "Bezouce",
            "Bézu-Saint-Éloi",
            "Biache-Saint-Vaast",
            "Biard",
            "Biarritz",
            "Biars-sur-Cère",
            "Bias",
            "Bidache",
            "Bidart",
            "Bienville",
            "Bierne",
            "Biesheim",
            "Biesles",
            "Biéville-Beuville",
            "Bièvres",
            "Biganos",
            "Bignan",
            "Bignoux",
            "Biguglia",
            "Bihorel",
            "Bilieu",
            "Billère",
            "Billom",
            "Billy-Berclau",
            "Billy-Montigny",
            "Billy-sur-Aisne",
            "Binic",
            "Bining",
            "Biol",
            "Biot",
            "Biscarrosse",
            "Bischheim",
            "Bischoffsheim",
            "Bischwiller",
            "Bitche",
            "Bitschwiller-lès-Thann",
            "Biviers",
            "Bizanet",
            "Bizanos",
            "Bize-Minervois",
            "Blacé",
            "Blaesheim",
            "Blagnac",
            "Blagny",
            "Blain",
            "Blaincourt-lès-Précy",
            "Blainville-Crevon",
            "Blainville-sur-Mer",
            "Blainville-sur-Orne",
            "Blaison-Gohier",
            "Blamont",
            "Blancafort",
            "Blangy-sur-Bresle",
            "Blanquefort",
            "Blanzat",
            "Blanzy",
            "Blaringhem",
            "Blausasc",
            "Blauzac",
            "Blavozy",
            "Blaye",
            "Blaye-les-Mines",
            "Blendecques",
            "Bléneau",
            "Blénod-lès-Pont-à-Mousson",
            "Blénod-lès-Toul",
            "Blérancourt",
            "Bléré",
            "Bletterans",
            "Bliesbruck",
            "Bligny-lès-Beaune",
            "Blodelsheim",
            "Blois",
            "Blonville-sur-Mer",
            "Blotzheim",
            "Bobigny",
            "Bodilis",
            "Boé",
            "Boëge",
            "Boeil-Bezing",
            "Boën-sur-Lignon",
            "Bœrsch",
            "Boeschepe",
            "Bohain-en-Vermandois",
            "Bohars",
            "Boigny-sur-Bionne",
            "Bois-Colombes",
            "Bois-d’Amont",
            "Bois-d’Arcy",
            "Bois-de-Cené",
            "Bois-Grenier",
            "Bois-Guillaume",
            "Bois-le-Roi",
            "Boiscommun",
            "Boisgervilly",
            "Boismé",
            "Boisseron",
            "Boisset-et-Gaujac",
            "Boisseuil",
            "Boissise-le-Roi",
            "Boissy-le-Châtel",
            "Boissy-le-Cutté",
            "Boissy-Saint-Léger",
            "Boissy-sous-Saint-Yon",
            "Bolbec",
            "Bollène",
            "Bollezeele",
            "Bollwiller",
            "Bologne",
            "Bompas",
            "Bon-Encontre",
            "Bon-Secours",
            "Bonchamp-lès-Laval",
            "Bondoufle",
            "Bondues",
            "Bondy",
            "Bonifacio",
            "Bonnac-la-Côte",
            "Bonnat",
            "Bonne",
            "Bonnefamille",
            "Bonnelles",
            "Bonnemain",
            "Bonnes",
            "Bonnétable",
            "Bonneuil-Matours",
            "Bonneuil-sur-Marne",
            "Bonneval",
            "Bonneveine",
            "Bonneville",
            "Bonnières-sur-Seine",
            "Bonnieux",
            "Bonny-sur-Loire",
            "Bons-en-Chablais",
            "Bonson",
            "Boofzheim",
            "Boos",
            "Boran-sur-Oise",
            "Bordeaux",
            "Bordes",
            "Bords",
            "Borel",
            "Borgo",
            "Bormes-les-Mimosas",
            "Bornel",
            "Bort-les-Orgues",
            "Bosc-le-Hard",
            "Bosdarros",
            "Bosmie-l'Aiguille",
            "Bouafle",
            "Bouaye",
            "Bouc-Bel-Air",
            "Boucau",
            "Bouchain",
            "Bouchemaine",
            "Boué",
            "Bouffémont",
            "Boufféré",
            "Bougival",
            "Bouguenais",
            "Bouillargues",
            "Bouillé-Loretz",
            "Bouilly",
            "Bouin",
            "Boujan-sur-Libron",
            "Boulange",
            "Boulay-Moselle",
            "Boulazac",
            "Boulbon",
            "Bouleurs",
            "Bouliac",
            "Boulieu-lès-Annonay",
            "Bouligny",
            "Boulleret",
            "Bouloc",
            "Boulogne-Billancourt",
            "Boulogne-sur-Gesse",
            "Boulogne-sur-Mer",
            "Bouloire",
            "Boult-sur-Suippe",
            "Bouray-sur-Juine",
            "Bourbon-l’Archambault",
            "Bourbon-Lancy",
            "Bourbonne-les-Bains",
            "Bourbourg",
            "Bourbriac",
            "Bourcefranc-le-Chapus",
            "Bourg",
            "Bourg de Joué-sur-Erdre",
            "Bourg-Achard",
            "Bourg-Argental",
            "Bourg-Blanc",
            "Bourg-de-Péage",
            "Bourg-de-Thizy",
            "Bourg-des-Comptes",
            "Bourg-en-Bresse",
            "Bourg-la-Reine",
            "Bourg-lès-Valence",
            "Bourg-Saint-Andéol",
            "Bourg-Saint-Maurice",
            "Bourganeuf",
            "Bourgbarré",
            "Bourges",
            "Bourghelles",
            "Bourgneuf",
            "Bourgneuf-en-Retz",
            "Bourgogne",
            "Bourgoin-Jallieu",
            "Bourgtheroulde-Infreville",
            "Bourguébus",
            "Bourgueil",
            "Bourlon",
            "Bournezeau",
            "Bournoncle-Saint-Pierre",
            "Bourogne",
            "Bourron-Marlotte",
            "Bourth",
            "Bousbecque",
            "Bousies",
            "Boussac",
            "Boussay",
            "Bousse",
            "Boussières",
            "Boussois",
            "Boussy-Saint-Antoine",
            "Bout-du-Pont-de-Larn",
            "Boutiers-Saint-Trojan",
            "Boutigny-sur-Essonne",
            "Bouttencourt",
            "Bouvesse-Quirieu",
            "Bouvignies",
            "Bouvigny-Boyeffles",
            "Bouville",
            "Bouvron",
            "Bouxières-aux-Chênes",
            "Bouxières-aux-Dames",
            "Bouxwiller",
            "Bouzigues",
            "Bouzillé",
            "Bouzonville",
            "Bouzy-la-Forêt",
            "Boves",
            "Boyard-Ville",
            "Boynes",
            "Bozel",
            "Bozouls",
            "Bracieux",
            "Brain-sur-Allonnes",
            "Braine",
            "Brains",
            "Bram",
            "Brandérion",
            "Brando",
            "Branges",
            "Branne",
            "Branoux-les-Taillades",
            "Brantôme",
            "Bras",
            "Brasles",
            "Brasparts",
            "Brassac",
            "Brassac-les-Mines",
            "Braud-et-Saint-Louis",
            "Brax",
            "Brax",
            "Bray-Dunes",
            "Bray-en-Val",
            "Bray-sur-Seine",
            "Bray-sur-Somme",
            "Brazey-en-Plaine",
            "Bréal-sous-Montfort",
            "Bréauté",
            "Brebières",
            "Brécé",
            "Brécey",
            "Brech",
            "Bréhal",
            "Bréhand",
            "Breil-sur-Roya",
            "Brêmes",
            "Brenouille",
            "Brens",
            "Bresles",
            "Bressols",
            "Bressuire",
            "Brest",
            "Breteil",
            "Bretenoux",
            "Breteuil",
            "Breteuil",
            "Bretignolles-sur-Mer",
            "Brétigny-sur-Orge",
            "Bretoncelles",
            "Brette-les-Pins",
            "Bretteville",
            "Bretteville-du-Grand-Caux",
            "Bretteville-l’Orgueilleuse",
            "Bretteville-sur-Laize",
            "Bretteville-sur-Odon",
            "Breuil-le-Sec",
            "Breuil-le-Vert",
            "Breuil-Magné",
            "Breuillet",
            "Breuillet",
            "Breuilpont",
            "Breuschwickersheim",
            "Bréval",
            "Bréviandes",
            "Brézé",
            "Brézins",
            "Brezolles",
            "Briançon",
            "Briare",
            "Briatexte",
            "Bricquebec",
            "Bricy",
            "Brie",
            "Brie-Comte-Robert",
            "Brié-et-Angonnes",
            "Briec",
            "Brienne-le-Château",
            "Briennon",
            "Brienon-sur-Armançon",
            "Briey",
            "Brignais",
            "Brignoles",
            "Brigueuil",
            "Briis-sous-Forges",
            "Brindas",
            "Brinon-sur-Sauldre",
            "Briollay",
            "Brion",
            "Brionne",
            "Brioude",
            "Brioux-sur-Boutonne",
            "Briouze",
            "Briscous",
            "Brison-Saint-Innocent",
            "Brissac-Quincé",
            "Brive-la-Gaillarde",
            "Brives-Charensac",
            "Brix",
            "Broglie",
            "Bron",
            "Broons",
            "Brou",
            "Brou-sur-Chantereine",
            "Brouckerque",
            "Broût-Vernet",
            "Bruay-la-Buissière",
            "Bruay-sur-l’Escaut",
            "Bruges",
            "Brugheas",
            "Bruguières",
            "Bruille-lez-Marchiennes",
            "Bruille-Saint-Amand",
            "Brûlon",
            "Brumath",
            "Brunoy",
            "Brunstatt",
            "Bruyères",
            "Bruyères-et-Montbérault",
            "Bruyères-le-Châtel",
            "Bruyères-sur-Oise",
            "Bruz",
            "Bry-sur-Marne",
            "Bû",
            "Bubry",
            "Buc",
            "Buchelay",
            "Buchères",
            "Buchy",
            "Bucquoy",
            "Bucy-le-Long",
            "Bueil",
            "Buellas",
            "Bugeat",
            "Buhl",
            "Buironfosse",
            "Buis-les-Baronnies",
            "Bulgnéville",
            "Bullion",
            "Bully",
            "Bully",
            "Bully-les-Mines",
            "Burbure",
            "Bures-sur-Yvette",
            "Burie",
            "Burlats",
            "Burnhaupt-le-Bas",
            "Burnhaupt-le-Haut",
            "Buros",
            "Bury",
            "Busigny",
            "Busnes",
            "Bussac-sur-Charente",
            "Bussang",
            "Bussière-Dunoise",
            "Bussière-Galant",
            "Bussière-Poitevine",
            "Bussières",
            "Bussy-Saint-Georges",
            "Butry-sur-Oise",
            "Buxerolles",
            "Buxières-les-Mines",
            "Buxy",
            "Buzançais",
            "Buzet-sur-Baïse",
            "Buzet-sur-Tarn",
            "Cabanac-et-Villagrains",
            "Cabannes",
            "Cabariot",
            "Cabasse",
            "Cabestany",
            "Cabourg",
            "Cabrières",
            "Cabriès",
            "Cabris",
            "Cachan",
            "Cadalen",
            "Cadaujac",
            "Caden",
            "Cadenet",
            "Caderousse",
            "Cadillac",
            "Cadolive",
            "Caen",
            "Caëstre",
            "Cagnac-les-Mines",
            "Cagnes-sur-Mer",
            "Cagny",
            "Cagny",
            "Cahagnes",
            "Cahors",
            "Cahuzac-sur-Vère",
            "Cailloux-sur-Fontaines",
            "Cairon",
            "Caissargues",
            "Cajarc",
            "Calais",
            "Calenzana",
            "Callac",
            "Callas",
            "Callian",
            "Calmont",
            "Calonne-Ricouart",
            "Calonne-sur-la-Lys",
            "Caluire-et-Cuire",
            "Calvados",
            "Calvi",
            "Calvisson",
            "Camarès",
            "Camaret-sur-Aigues",
            "Camaret-sur-Mer",
            "Cambes",
            "Cambes-en-Plaine",
            "Camblain-Châtelain",
            "Camblanes-et-Meynac",
            "Cambo-les-Bains",
            "Cambrai",
            "Cambremer",
            "Cambronne-lès-Clermont",
            "Cambronne-lès-Ribécourt",
            "Camiers",
            "Camon",
            "Camors",
            "Campagne-lès-Hesdin",
            "Campan",
            "Campbon",
            "Campénéac",
            "Camphin-en-Carembault",
            "Camphin-en-Pévèle",
            "Camps-la-Source",
            "Campsas",
            "Cancale",
            "Cancon",
            "Candé",
            "Candé-sur-Beuvron",
            "Candillargues",
            "Canéjan",
            "Canet",
            "Canet-en-Roussillon",
            "Cangey",
            "Cannes",
            "Cannes-Écluse",
            "Canohès",
            "Canon",
            "Cantal",
            "Cantaron",
            "Canteleu",
            "Cantenac",
            "Cantenay-Épinard",
            "Cantin",
            "Cany-Barville",
            "Cap-d’Ail",
            "Capbreton",
            "Capdenac-Gare",
            "Capendu",
            "Capestang",
            "Capinghem",
            "Cappelle-en-Pévèle",
            "Cappelle-la-Grande",
            "Captieux",
            "Capvern",
            "Caraman",
            "Carantec",
            "Carbon-Blanc",
            "Carbonne",
            "Carcans",
            "Carcassonne",
            "Carcès",
            "Carentan",
            "Carentoir",
            "Cargèse",
            "Carhaix-Plouguer",
            "Carignan",
            "Carignan-de-Bordeaux",
            "Carlepont",
            "Carling",
            "Carmaux",
            "Carnac",
            "Carnoules",
            "Carnoux-en-Provence",
            "Caro",
            "Caromb",
            "Carpentras",
            "Carpiquet",
            "Carquefou",
            "Carqueiranne",
            "Carrières-sous-Poissy",
            "Carrières-sur-Seine",
            "Carros",
            "Carry-le-Rouet",
            "Cars",
            "Carsac-Aillac",
            "Carspach",
            "Cartignies",
            "Carvin",
            "Cassagnes-Bégonhès",
            "Cassel",
            "Casseneuil",
            "Cassis",
            "Casson",
            "Cast",
            "Castagniers",
            "Castanet-Tolosan",
            "Castelculier",
            "Castelginest",
            "Casteljaloux",
            "Castellane",
            "Castelmaurou",
            "Castelmoron-sur-Lot",
            "Castelnau-d'Estrétefonds",
            "Castelnau-de-Guers",
            "Castelnau-de-Lévis",
            "Castelnau-de-Médoc",
            "Castelnau-le-Lez",
            "Castelnau-Montratier",
            "Castelnaudary",
            "Castelsarrasin",
            "Castets",
            "Castets-en-Dorthe",
            "Castillon-du-Gard",
            "Castillon-la-Bataille",
            "Castillonnès",
            "Castres",
            "Castres-Gironde",
            "Castries",
            "Catenoy",
            "Cattenom",
            "Cauchy-à-la-Tour",
            "Caudan",
            "Caudebec-en-Caux",
            "Caudebec-lès-Elbeuf",
            "Caudry",
            "Cauffry",
            "Caulnes",
            "Caumont",
            "Caumont-l'Éventé",
            "Caumont-sur-Durance",
            "Caunes-Minervois",
            "Cauro",
            "Caussade",
            "Cauterets",
            "Cauvigny",
            "Cauville-sur-Mer",
            "Caux",
            "Cavaillon",
            "Cavalaire-sur-Mer",
            "Cavan",
            "Caveirac",
            "Cavignac",
            "Cayeux-sur-Mer",
            "Caylus",
            "Cazaubon",
            "Cazères",
            "Cazères-sur-l’Adour",
            "Cazes-Mondenard",
            "Cazilhac",
            "Cazouls-lès-Béziers",
            "Ceaucé",
            "Cébazat",
            "Celle-Lévescault",
            "Celles-sur-Belle",
            "Celles-sur-Durolle",
            "Cellettes",
            "Cellieu",
            "Cély",
            "Cénac",
            "Cénac-et-Saint-Julien",
            "Cendras",
            "Cenon",
            "Cenon-sur-Vienne",
            "Cépet",
            "Cepoy",
            "Cérans-Foulletourte",
            "Cercoux",
            "Cercy-la-Tour",
            "Cerdon",
            "Cerelles",
            "Cérences",
            "Céreste",
            "Ceret",
            "Cergy",
            "Cergy-Pontoise",
            "Cérilly",
            "Cerisy-la-Salle",
            "Cerizay",
            "Cernay",
            "Cernay-la-Ville",
            "Cernay-lès-Reims",
            "Cerny",
            "Cérons",
            "Cers",
            "Certines",
            "Cervera de la Marenda",
            "Cervione",
            "Cessenon-sur-Orb",
            "Cessieu",
            "Cesson",
            "Cesson-Sévigné",
            "Cessy",
            "Cestas",
            "Ceton",
            "Ceyrat",
            "Ceyreste",
            "Ceyzériat",
            "Cézac",
            "Cézy",
            "Chabanais",
            "Chabeuil",
            "Chablis",
            "Châbons",
            "Chabreloche",
            "Chabris",
            "Chacé",
            "Chadrac",
            "Chagny",
            "Chaillac",
            "Chailland",
            "Chaillé-les-Marais",
            "Chailles",
            "Chaillevette",
            "Chailly-en-Bière",
            "Chailly-en-Brie",
            "Chaingy",
            "Chalabre",
            "Chalais",
            "Chalamont",
            "Chalampé",
            "Chaleins",
            "Châlette-sur-Loing",
            "Chalifert",
            "Chaligny",
            "Chalindrey",
            "Challans",
            "Challes",
            "Challes-les-Eaux",
            "Challex",
            "Challuy",
            "Chalo-Saint-Mars",
            "Chalon-sur-Saône",
            "Chalonnes-sur-Loire",
            "Châlons-en-Champagne",
            "Châlonvillars",
            "Châlus",
            "Chamagnieu",
            "Chamalières",
            "Chamant",
            "Chamarande",
            "Chamarandes-Choignes",
            "Chamberet",
            "Chambéry",
            "Chambly",
            "Chambœuf",
            "Chambon-sur-Voueize",
            "Chamboulive",
            "Chambourcy",
            "Chambourg-sur-Indre",
            "Chambray-lès-Tours",
            "Chambretaud",
            "Chameyrat",
            "Chamigny",
            "Chamonix-Mont-Blanc",
            "Champ-sur-Drac",
            "Champagnac",
            "Champagné",
            "Champagne-au-Mont-d’Or",
            "Champagné-les-Marais",
            "Champagne-Mouton",
            "Champagne-sur-Oise",
            "Champagne-sur-Seine",
            "Champagney",
            "Champagnier",
            "Champagnole",
            "Champcevinel",
            "Champcueil",
            "Champdeniers-Saint-Denis",
            "Champdieu",
            "Champeix",
            "Champenoux",
            "Champfleur",
            "Champforgeuil",
            "Champhol",
            "Champier",
            "Champigné",
            "Champignelles",
            "Champigneulles",
            "Champigny",
            "Champigny-sur-Marne",
            "Champlan",
            "Champlitte",
            "Champlitte-la-Ville",
            "Champniers",
            "Champs-sur-Marne",
            "Champs-sur-Tarentaine-Marchal",
            "Champs-sur-Yonne",
            "Champsecret",
            "Champtocé-sur-Loire",
            "Champtoceaux",
            "Champvans",
            "Chanac",
            "Chanas",
            "Chanceaux-sur-Choisille",
            "Chancelade",
            "Chancenay",
            "Chandon",
            "Changé",
            "Changis-sur-Marne",
            "Changy-les-Bois",
            "Chaniers",
            "Chanonat",
            "Chanos-Curson",
            "Chanteau",
            "Chanteheux",
            "Chantelle",
            "Chanteloup",
            "Chanteloup-en-Brie",
            "Chanteloup-les-Vignes",
            "Chantenay-Saint-Imbert",
            "Chantepie",
            "Chantilly",
            "Chantôme",
            "Chantonnay",
            "Chantraine",
            "Chanu",
            "Chaource",
            "Chapareillan",
            "Chapdes-Beaufort",
            "Chapet",
            "Chaponnay",
            "Chaponost",
            "Chappes",
            "Chaptelat",
            "Charantonnay",
            "Charavines",
            "Charbonnières-les-Bains",
            "Charbonnières-les-Varennes",
            "Charbuy",
            "Charentay",
            "Charente",
            "Charente-Maritime",
            "Charentilly",
            "Charenton-du-Cher",
            "Charenton-le-Pont",
            "Chargé",
            "Charleval",
            "Charleval",
            "Charleville-Mézières",
            "Charlieu",
            "Charly",
            "Charly-sur-Marne",
            "Charmes",
            "Charmes",
            "Charmes-sur-Rhône",
            "Charnay",
            "Charnay-lès-Mâcon",
            "Charnècles",
            "Charny",
            "Charny",
            "Charolles",
            "Chârost",
            "Charquemont",
            "Charron",
            "Charroux",
            "Chars",
            "Chartres",
            "Chartres-de-Bretagne",
            "Chartrettes",
            "Charvieu-Chavagneux",
            "Chasné-sur-Illet",
            "Chassagny",
            "Chasse-sur-Rhône",
            "Chasselay",
            "Chasseneuil-du-Poitou",
            "Chasseneuil-sur-Bonnieure",
            "Chassieu",
            "Chassors",
            "Château-Chinon(Ville)",
            "Château-d’Olonne",
            "Château-du-Loir",
            "Château-Gaillard",
            "Château-Gontier",
            "Château-Guibert",
            "Château-la-Vallière",
            "Château-Landon",
            "Château-Porcien",
            "Château-Renard",
            "Château-Renault",
            "Château-Salins",
            "Château-Thierry",
            "Châteaubernard",
            "Châteaubourg",
            "Châteaubriant",
            "Châteaudun",
            "Châteaufort",
            "Châteaugay",
            "Châteaugiron",
            "Châteaulin",
            "Châteaumeillant",
            "Châteauneuf",
            "Châteauneuf-d’Ille-et-Vilaine",
            "Châteauneuf-de-Gadagne",
            "Châteauneuf-de-Galaure",
            "Châteauneuf-du-Faou",
            "Châteauneuf-du-Pape",
            "Châteauneuf-du-Rhône",
            "Châteauneuf-en-Thymerais",
            "Châteauneuf-Grasse",
            "Châteauneuf-la-Forêt",
            "Châteauneuf-le-Rouge",
            "Châteauneuf-les-Martigues",
            "Châteauneuf-sur-Charente",
            "Châteauneuf-sur-Cher",
            "Châteauneuf-sur-Isère",
            "Châteauneuf-sur-Loire",
            "Châteauneuf-sur-Sarthe",
            "Châteauponsac",
            "Châteaurenard",
            "Châteauroux",
            "Châteauvillain",
            "Châtel",
            "Châtel-Guyon",
            "Châtel-Saint-Germain",
            "Châtel-sur-Moselle",
            "Châtelaillon-Plage",
            "Châtellerault",
            "Châtenay-Malabry",
            "Châtenay-sur-Seine",
            "Châtenois",
            "Châtenois-les-Forges",
            "Châtenoy-le-Royal",
            "Châtillon",
            "Châtillon",
            "Châtillon-Coligny",
            "Châtillon-en-Bazois",
            "Châtillon-en-Michaille",
            "Châtillon-en-Vendelais",
            "Châtillon-la-Palud",
            "Châtillon-le-Duc",
            "Châtillon-sur-Chalaronne",
            "Châtillon-sur-Cher",
            "Châtillon-sur-Cluses",
            "Châtillon-sur-Indre",
            "Châtillon-sur-Marne",
            "Châtillon-sur-Seiche",
            "Châtillon-sur-Seine",
            "Châtillon-sur-Thouet",
            "Châtonnay",
            "Chatou",
            "Châtres-sur-Cher",
            "Chatte",
            "Chatuzange-le-Goubet",
            "Chauché",
            "Chauconin-Neufmontiers",
            "Chaudes-Aigues",
            "Chaudon",
            "Chaudron-en-Mauges",
            "Chauffailles",
            "Chaulgnes",
            "Chaulnes",
            "Chaumes-en-Brie",
            "Chaumont",
            "Chaumont-en-Vexin",
            "Chaumont-sur-Loire",
            "Chaumont-sur-Tharonne",
            "Chaumontel",
            "Chaunay",
            "Chauny",
            "Chauray",
            "Chauriat",
            "Chaussin",
            "Chauvé",
            "Chauvigny",
            "Chavagne",
            "Chavagnes-en-Paillers",
            "Chavanay",
            "Chavanod",
            "Chavanoz",
            "Chavelot",
            "Chavenay",
            "Chavigny",
            "Chaville",
            "Chazelles",
            "Chazelles-sur-Lyon",
            "Chazey-sur-Ain",
            "Chécy",
            "Chef-Boutonne",
            "Cheillé",
            "Chelles",
            "Chemaudin",
            "Chemazé",
            "Chéméré",
            "Chemillé-Melay",
            "Chênehutte-Trèves-Cunault",
            "Cheniménil",
            "Chennevières-sur-Marne",
            "Chenoise",
            "Chenôve",
            "Chens-sur-Léman",
            "Cheny",
            "Cheptainville",
            "Chépy",
            "Cher",
            "Chérac",
            "Chéraute",
            "Cherbourg-Octeville",
            "Chéreng",
            "Cherisy",
            "Chermignac",
            "Chéroy",
            "Cherré",
            "Cherrueix",
            "Cherves-Richemont",
            "Cherveux",
            "Chessy",
            "Chessy",
            "Cheux",
            "Chevaigné",
            "Cheval-Blanc",
            "Chevanceaux",
            "Chevannes",
            "Cheverny",
            "Chevigny-Saint-Sauveur",
            "Chevillon",
            "Chevillon-sur-Huillard",
            "Chevilly",
            "Chevilly-Larue",
            "Chèvremont",
            "Chevreuse",
            "Chevrières",
            "Chevry",
            "Chevry-Cossigny",
            "Chézy-sur-Marne",
            "Chiché",
            "Chierry",
            "Chilleurs-aux-Bois",
            "Chilly",
            "Chilly-Mazarin",
            "Chimilin",
            "Chindrieux",
            "Chinon",
            "Chirac",
            "Chirens",
            "Chiry-Ourscamp",
            "Chissay-en-Touraine",
            "Chitenay",
            "Chocques",
            "Choisey",
            "Choisy",
            "Choisy-au-Bac",
            "Choisy-en-Brie",
            "Choisy-le-Roi",
            "Cholet",
            "Chomérac",
            "Chorges",
            "Chouzé-sur-Loire",
            "Chouzy-sur-Cisse",
            "Chutes-Lavie",
            "Chuzelles",
            "Ciboure",
            "Cinq Avenues",
            "Cinq-Mars-la-Pile",
            "Cinqueux",
            "Cintegabelle",
            "Cintré",
            "Ciré-d’Aunis",
            "Cires-lès-Mello",
            "Cirey-sur-Vezouze",
            "Ciry-le-Noble",
            "Cissé",
            "Civens",
            "Civray",
            "Civray-de-Touraine",
            "Civrieux",
            "Claira",
            "Clairac",
            "Clairoix",
            "Clairvaux-les-Lacs",
            "Claix",
            "Clamart",
            "Clamecy",
            "Clapiers",
            "Clarensac",
            "Claret",
            "Clary",
            "Claville",
            "Claye-Souilly",
            "Clécy",
            "Cléden-Poher",
            "Cléder",
            "Cléguer",
            "Cléguérec",
            "Cléon",
            "Clérac",
            "Cléré-les-Pins",
            "Clères",
            "Clérieux",
            "Clermont",
            "Clermont-Créans",
            "Clermont-en-Argonne",
            "Clermont-Ferrand",
            "Clerval",
            "Cléry-Saint-André",
            "Clichy",
            "Clichy-sous-Bois",
            "Clinchamps-sur-Orne",
            "Clion",
            "Clisson",
            "Clohars-Carnoët",
            "Clohars-Fouesnant",
            "Clonas-sur-Varèze",
            "Clouange",
            "Cloyes-sur-le-Loir",
            "Cluis",
            "Cluny",
            "Cluses",
            "Coarraze",
            "Cocheren",
            "Codognan",
            "Coësmes",
            "Coëtmieux",
            "Coëx",
            "Cognac",
            "Cognac-la-Forêt",
            "Cognin",
            "Cogny",
            "Cogolin",
            "Coignières",
            "Coincy",
            "Coise",
            "Colayrac-Saint-Cirq",
            "Coligny",
            "Collégien",
            "Colleret",
            "Colleville-Montgomery",
            "Collioure",
            "Collobrières",
            "Collonges",
            "Collonges-sous-Salève",
            "Colmar",
            "Colomars",
            "Colombe",
            "Colombelles",
            "Colombes",
            "Colombey-les-Belles",
            "Colombier-Fontaine",
            "Colombiers",
            "Colombiers",
            "Colombiès",
            "Colomiers",
            "Colpo",
            "Combaillaux",
            "Combloux",
            "Combourg",
            "Combrand",
            "Combrée",
            "Combrit",
            "Combronde",
            "Combs-la-Ville",
            "Comines",
            "Commelle",
            "Commentry",
            "Commequiers",
            "Commer",
            "Commercy",
            "Communay",
            "Compertrix",
            "Compiègne",
            "Compreignac",
            "Comps",
            "Concarneau",
            "Conches-en-Ouche",
            "Conches-sur-Gondoire",
            "Condat",
            "Condat-sur-Vienne",
            "Condé-Sainte-Libiaire",
            "Condé-sur-Huisne",
            "Condé-sur-l’Escaut",
            "Condé-sur-Noireau",
            "Condé-sur-Sarthe",
            "Condé-sur-Vesgre",
            "Condé-sur-Vire",
            "Condette",
            "Condom",
            "Condrieu",
            "Conflans-en-Jarnisy",
            "Conflans-Sainte-Honorine",
            "Confolens",
            "Congénies",
            "Congis-sur-Thérouanne",
            "Congrier",
            "Conlie",
            "Connantre",
            "Connaux",
            "Connerré",
            "Conquereuil",
            "Conques-sur-Orbiel",
            "Contamine-sur-Arve",
            "Contes",
            "Contres",
            "Contrexéville",
            "Conty",
            "Coquelles",
            "Coray",
            "Corbarieu",
            "Corbas",
            "Corbehem",
            "Corbeil-Essonnes",
            "Corbeilles",
            "Corbelin",
            "Corbenay",
            "Corbie",
            "Corbigny",
            "Corbreuse",
            "Corcieux",
            "Corcoué-sur-Logne",
            "Cordemais",
            "Cordes-sur-Ciel",
            "Cordon",
            "Corenc",
            "Corlay",
            "Corme-Royal",
            "Cormeilles",
            "Cormeilles-en-Parisis",
            "Cormelles-le-Royal",
            "Cormeray",
            "Cormery",
            "Cormicy",
            "Cormontreuil",
            "Cornas",
            "Corné",
            "Cornebarrieu",
            "Corneilhan",
            "Corneilla-del-Vercol",
            "Corneilla-la-Rivière",
            "Corneville-sur-Risle",
            "Cornier",
            "Cornil",
            "Cornillon-Confoux",
            "Cornimont",
            "Corny-sur-Moselle",
            "Coron",
            "Corpeau",
            "Corps-Nuds",
            "Corquilleroy",
            "Corrèze",
            "Corsept",
            "Corseul",
            "Corte",
            "Corzé",
            "Cosnac",
            "Cosne-Cours-sur-Loire",
            "Cosnes",
            "Cossé-le-Vivien",
            "Cotignac",
            "Coubert",
            "Coublevie",
            "Coubon",
            "Coubron",
            "Couches",
            "Couchey",
            "Coucy-le-Château-Auffrique",
            "Coudekerque-Branche",
            "Coudekerque-Village",
            "Coudoux",
            "Coudun",
            "Couëron",
            "Couffé",
            "Coufouleux",
            "Couhé",
            "Couilly-Pont-aux-Dames",
            "Couiza",
            "Coulaines",
            "Coulanges-lès-Nevers",
            "Coulans-sur-Gée",
            "Coullons",
            "Coulogne",
            "Coulombiers",
            "Coulombs",
            "Coulommiers",
            "Coulon",
            "Coulonges-sur-l’Autize",
            "Coulounieix-Chamiers",
            "Coupvray",
            "Cour-Cheverny",
            "Courbevoie",
            "Courcelles-Chaussy",
            "Courcelles-lès-Lens",
            "Courcelles-lès-Montbéliard",
            "Courcelles-sur-Seine",
            "Courchelettes",
            "Courchevel",
            "Courcité",
            "Courçon",
            "Courcouronnes",
            "Courcy",
            "Courdimanche",
            "Courlay",
            "Courlon-sur-Yonne",
            "Courmelles",
            "Cournon-d’Auvergne",
            "Cournonsec",
            "Cournonterral",
            "Courpalay",
            "Courpière",
            "Courrières",
            "Cours-de-Pile",
            "Cours-la-Ville",
            "Cours-les-Barres",
            "Coursac",
            "Coursan",
            "Courseulles-sur-Mer",
            "Courteilles",
            "Courtenay",
            "Courthézon",
            "Courtisols",
            "Courtry",
            "Courville-sur-Eure",
            "Courzieu",
            "Cousance",
            "Cousolre",
            "Coussac-Bonneval",
            "Coutances",
            "Couterne",
            "Couternon",
            "Coutiches",
            "Coutouvre",
            "Coutras",
            "Couvron-et-Aumencourt",
            "Coux",
            "Couzeix",
            "Couzon-au-Mont-d’Or",
            "Coye-la-Forêt",
            "Cozes",
            "Crach",
            "Cran-Gevrier",
            "Cransac",
            "Cranves-Sales",
            "Craon",
            "Craponne",
            "Craponne-sur-Arzon",
            "Cravanche",
            "Créances",
            "Crêches-sur-Saône",
            "Crécy-en-Ponthieu",
            "Crécy-sur-Serre",
            "Crédin",
            "Crégy-lès-Meaux",
            "Créhange",
            "Créhen",
            "Creil",
            "Creissan",
            "Creissels",
            "Crémieu",
            "Creney-près-Troyes",
            "Créon",
            "Crépy",
            "Crépy-en-Valois",
            "Crespières",
            "Crespin",
            "Cresserons",
            "Crest",
            "Créteil",
            "Creully",
            "Creuse",
            "Creutzwald",
            "Creuzier-le-Vieux",
            "Crèvecœur-le-Grand",
            "Crevin",
            "Creys-Mépieu",
            "Creysse",
            "Crézancy",
            "Criel-sur-Mer",
            "Criquebeuf-sur-Seine",
            "Criquetot-l’Esneval",
            "Crisolles",
            "Crissey",
            "Croisilles",
            "Croissy-Beaubourg",
            "Croissy-sur-Seine",
            "Croix",
            "Crolles",
            "Crosne",
            "Crossac",
            "Croth",
            "Crottet",
            "Crouy",
            "Crouy-en-Thelle",
            "Crouy-sur-Ourcq",
            "Crozon",
            "Cruas",
            "Cruet",
            "Cruseilles",
            "Crusnes",
            "Cublac",
            "Cublize",
            "Cubzac-les-Ponts",
            "Cucq",
            "Cucuron",
            "Cuers",
            "Cuffies",
            "Cuffy",
            "Cugand",
            "Cuges-les-Pins",
            "Cugnaux",
            "Cuinchy",
            "Cuincy",
            "Cuise-la-Motte",
            "Cuiseaux",
            "Cuisery",
            "Cuisiat",
            "Culhat",
            "Culoz",
            "Cunac",
            "Cunlhat",
            "Curgies",
            "Curgy",
            "Curtin",
            "Cussac",
            "Cussac-Fort-Médoc",
            "Cussac-sur-Loire",
            "Cusset",
            "Custines",
            "Cusy",
            "Cuttoli-Corticchiato",
            "Cuverville",
            "Cuxac-Cabardès",
            "Cuzieu",
            "Cysoing",
            "Dabo",
            "Dachstein",
            "Dadonville",
            "Dagneux",
            "Dainville",
            "Daix",
            "Dallet",
            "Damazan",
            "Dambach-la-Ville",
            "Damelevières",
            "Damery",
            "Damgan",
            "Damigny",
            "Dammarie",
            "Dammarie-les-Lys",
            "Dammartin-en-Goële",
            "Damparis",
            "Dampierre",
            "Dampierre-en-Burly",
            "Dampierre-en-Yvelines",
            "Dampierre-les-Bois",
            "Dampierre-sur-Salon",
            "Dampmart",
            "Damprichard",
            "Damville",
            "Dangé-Saint-Romain",
            "Danjoutin",
            "Dannemarie",
            "Dannemarie-sur-Crête",
            "Dannes",
            "Daoulas",
            "Dardilly",
            "Dargnies",
            "Darnétal",
            "Darney",
            "Darnieulles",
            "Darvoy",
            "Dasle",
            "Dauendorf",
            "Daumeray",
            "Daux",
            "Davézieux",
            "Dax",
            "Deauville",
            "Decazeville",
            "Dechy",
            "Décines-Charpieu",
            "Decize",
            "Delle",
            "Demigny",
            "Démouville",
            "Denain",
            "Denée",
            "Denguin",
            "Denicé",
            "Déols",
            "Département d'Eure-et-Loir",
            "Département d'Ille-et-Vilaine",
            "Département d'Indre-et-Loire",
            "Département de l'Ain",
            "Département de l'Aisne",
            "Département de l'Allier",
            "Département de l'Ardèche",
            "Département de l'Ariège",
            "Département de l'Aube",
            "Département de l'Aude",
            "Département de l'Aveyron",
            "Département de l'Essonne",
            "Département de l'Eure",
            "Département de l'Hérault",
            "Département de l'Indre",
            "Département de l'Isère",
            "Département de l'Oise",
            "Département de l'Orne",
            "Département de l'Yonne",
            "Département de la Corrèze",
            "Département de la Corse-du-Sud",
            "Département de la Côte-d'Or",
            "Département de la Drôme",
            "Département de la Haute-Corse",
            "Département de la Haute-Saône",
            "Département de la Loire",
            "Département de la Lozère",
            "Département de la Nièvre",
            "Département de la Vendée",
            "Département de Maine-et-Loire",
            "Département de Saône-et-Loire",
            "Département des Bouches-du-Rhône",
            "Département des Côtes-d’Armor",
            "Département des Deux-Sèvres",
            "Département des Hautes-Pyrénées",
            "Département des Pyrénées-Atlantiques",
            "Département des Pyrénées-Orientales",
            "Département du Finistère",
            "Département du Loir-et-Cher",
            "Département du Lot-et-Garonne",
            "Département du Puy-de-Dôme",
            "Département du Rhône",
            "Département du Tarn-et-Garonne",
            "Département du Val-d’Oise",
            "Département du Vaucluse",
            "Derval",
            "Désaignes",
            "Descartes",
            "Désertines",
            "Dessenheim",
            "Desvres",
            "Dettwiller",
            "Deuil-la-Barre",
            "Deûlémont",
            "Devecey",
            "Deville",
            "Déville-lès-Rouen",
            "Deyvillers",
            "Dhuizon",
            "Didenheim",
            "Die",
            "Diebling",
            "Diemeringen",
            "Diémoz",
            "Dieppe",
            "Diesen",
            "Dietwiller",
            "Dieue-sur-Meuse",
            "Dieulefit",
            "Dieulouard",
            "Dieuze",
            "Diges",
            "Dignac",
            "Digne-les-Bains",
            "Digoin",
            "Digosville",
            "Dijon",
            "Dinan",
            "Dinard",
            "Dinéault",
            "Dingé",
            "Dingsheim",
            "Dingy-Saint-Clair",
            "Dinsheim-sur-Bruche",
            "Diou",
            "Dirac",
            "Dirinon",
            "Dissay",
            "Distré",
            "Distroff",
            "Dives-sur-Mer",
            "Divion",
            "Divonne-les-Bains",
            "Dizy",
            "Docelles",
            "Dogneville",
            "Doingt",
            "Dol-de-Bretagne",
            "Dole",
            "Dollon",
            "Dolomieu",
            "Dolus-d'Oléron",
            "Dom-le-Mesnil",
            "Domagné",
            "Domalain",
            "Domancy",
            "Domarin",
            "Domart-en-Ponthieu",
            "Dombasle-sur-Meurthe",
            "Domène",
            "Domérat",
            "Domessin",
            "Domfront",
            "Domgermain",
            "Dommartin-lès-Remiremont",
            "Dommartin-lès-Toul",
            "Domme",
            "Domont",
            "Dompierre-sur-Besbre",
            "Dompierre-sur-Mer",
            "Dompierre-sur-Veyle",
            "Dompierre-sur-Yon",
            "Don",
            "Donchery",
            "Doncourt-lès-Conflans",
            "Donges",
            "Donnemarie-Dontilly",
            "Donnery",
            "Donville-les-Bains",
            "Donzenac",
            "Donzère",
            "Donzy",
            "Dordives",
            "Dordogne",
            "Dorlisheim",
            "Dormans",
            "Dornes",
            "Dortan",
            "Dossenheim-sur-Zinsel",
            "Douai",
            "Douarnenez",
            "Doubs",
            "Douchy",
            "Douchy-les-Mines",
            "Doudeville",
            "Doue",
            "Doué-la-Fontaine",
            "Doulaincourt-Saucourt",
            "Doullens",
            "Dourdan",
            "Dourges",
            "Dourgne",
            "Doussard",
            "Douvaine",
            "Douvres-la-Délivrande",
            "Douvrin",
            "Douzy",
            "Doyet",
            "Dozulé",
            "Dracy-le-Fort",
            "Draguignan",
            "Drain",
            "Drancy",
            "Drap",
            "Draveil",
            "Drefféac",
            "Drémil-Lafage",
            "Dreslincourt",
            "Dreuil-lès-Amiens",
            "Dreux",
            "Drocourt",
            "Droué",
            "Droue-sur-Drouette",
            "Druelle",
            "Drulingen",
            "Drumettaz",
            "Drusenheim",
            "Dry",
            "Ducey",
            "Duclair",
            "Dugny",
            "Dugny-sur-Meuse",
            "Duisans",
            "Dun-le-Palestel",
            "Dun-sur-Auron",
            "Dunières",
            "Dunkerque",
            "Duppigheim",
            "Duras",
            "Durrenbach",
            "Durtal",
            "Durtol",
            "Dury",
            "Duttlenheim",
            "Eaubonne",
            "Eaunes",
            "Eauze",
            "Ebersheim",
            "Ébreuil",
            "Écaillon",
            "Échalas",
            "Échenevex",
            "Échenoz-la-Méline",
            "Échillais",
            "Échiré",
            "Échirolles",
            "Eckbolsheim",
            "Eckwersheim",
            "École-Valentin",
            "Écommoy",
            "Écouché",
            "Écouen",
            "Écouflant",
            "Écourt-Saint-Quentin",
            "Écoyeux",
            "Ecques",
            "Ecquevilly",
            "Écrainville",
            "Écrouves",
            "Écueillé",
            "Écuelles",
            "Écuisses",
            "Écully",
            "Edern",
            "Égletons",
            "Égly",
            "Égreville",
            "Égriselles-le-Bocage",
            "Éguilles",
            "Eguisheim",
            "Éguzon-Chantôme",
            "Einville-au-Jard",
            "el Voló",
            "Élancourt",
            "Elbeuf",
            "Éleu-dit-Leauwette",
            "Elliant",
            "Elne",
            "Éloyes",
            "els Banys d'Arles",
            "Elven",
            "Embrun",
            "Émerainville",
            "Émerchicourt",
            "Emmerin",
            "Enchenberg",
            "Enghien-les-Bains",
            "Englefontaine",
            "Ennery",
            "Ennery",
            "Ennetières-en-Weppes",
            "Ennevelin",
            "Ennezat",
            "Ensisheim",
            "Ensuès-la-Redonne",
            "Entraigues-sur-la-Sorgue",
            "Entrammes",
            "Entrange",
            "Entraygues-sur-Truyère",
            "Entre-Deux-Guiers",
            "Entzheim",
            "Enval",
            "Envermeu",
            "Éoures",
            "Épagny",
            "Épaignes",
            "Épehy",
            "Éperlecques",
            "Épernay",
            "Épernon",
            "Épervans",
            "Epfig",
            "Épieds-en-Beauce",
            "Épinac",
            "Épinal",
            "Épinay-sous-Sénart",
            "Épinay-sur-Orge",
            "Épinay-sur-Seine",
            "Epiniac",
            "Épinouze",
            "Épône",
            "Épouville",
            "Eppeville",
            "Épron",
            "Équemauville",
            "Équeurdreville-Hainneville",
            "Équihen-Plage",
            "Éragny",
            "Erbray",
            "Erbrée",
            "Ercé-en-Lamée",
            "Ercé-près-Liffré",
            "Ercuis",
            "Erdeven",
            "Ergué-Gabéric",
            "Ermont",
            "Ernée",
            "Ernolsheim-Bruche",
            "Erquinghem-Lys",
            "Erquy",
            "Erre",
            "Erstein",
            "Ervy-le-Châtel",
            "Esbly",
            "Escalquens",
            "Escaudain",
            "Escaudœuvres",
            "Escautpont",
            "Eschau",
            "Eschbach",
            "Eschentzwiller",
            "Esches",
            "Escoutoux",
            "Eslettes",
            "Esnandes",
            "Espalion",
            "Espaly-Saint-Marcel",
            "Espelette",
            "Espéraza",
            "Esquéhéries",
            "Esquelbecq",
            "Esquerdes",
            "Esquibien",
            "Essars",
            "Essert",
            "Essey-lès-Nancy",
            "Essigny-le-Grand",
            "Essômes-sur-Marne",
            "Estagel",
            "Estaires",
            "Esternay",
            "Estevelles",
            "Estillac",
            "Estissac",
            "Estivareilles",
            "Estrablin",
            "Estrées",
            "Estrées-Saint-Denis",
            "Esvres",
            "Étables-sur-Mer",
            "Étagnac",
            "Étain",
            "Étainhus",
            "Étalans",
            "Étampes",
            "Étampes-sur-Marne",
            "Étang-sur-Arroux",
            "Étaples",
            "Étaules",
            "Étauliers",
            "Etaux",
            "Étel",
            "Éterville",
            "Étiolles",
            "Étival-Clairefontaine",
            "Étival-lès-le-Mans",
            "Étoile-sur-Rhône",
            "Étréchy",
            "Étreillers",
            "Étrelles",
            "Étrembières",
            "Étrépagny",
            "Étretat",
            "Étreux",
            "Étriché",
            "Étupes",
            "Etzling",
            "Eu",
            "Eulmont",
            "Eurre",
            "Eurville-Bienville",
            "Euville",
            "Évaux-les-Bains",
            "Évenos",
            "Évian-les-Bains",
            "Évin-Malmaison",
            "Évires",
            "Évran",
            "Évrecy",
            "Évreux",
            "Évron",
            "Évry",
            "Excideuil",
            "Exideuil",
            "Exincourt",
            "Exireuil",
            "Eybens",
            "Eygalières",
            "Eyguières",
            "Eymet",
            "Eymoutiers",
            "Eyragues",
            "Eysines",
            "Eyvigues-et-Eybènes",
            "Eyzin-Pinet",
            "Ézanville",
            "Èze",
            "Ézy-sur-Eure",
            "Fabrègues",
            "Fabrezan",
            "Faches-Thumesnil",
            "Fagnières",
            "Fains-Véel",
            "Falaise",
            "Falck",
            "Falicon",
            "Falleron",
            "Famars",
            "Fameck",
            "Fampoux",
            "Farébersviller",
            "Fareins",
            "Faremoutiers",
            "Fargues-Saint-Hilaire",
            "Farnay",
            "Farschviller",
            "Faulquemont",
            "Faulx",
            "Faumont",
            "Fauville-en-Caux",
            "Faverges",
            "Faverges-de-la-Tour",
            "Faverney",
            "Faverolles-sur-Cher",
            "Favières",
            "Fay-aux-Loges",
            "Fay-de-Bretagne",
            "Fayence",
            "Fayl-Billot",
            "Fécamp",
            "Féchain",
            "Fegersheim",
            "Fégréac",
            "Feigères",
            "Feignies",
            "Feillens",
            "Félines",
            "Fellering",
            "Felletin",
            "Fenain",
            "Fénay",
            "Feneu",
            "Fenouillet",
            "Fère-Champenoise",
            "Fère-en-Tardenois",
            "Férel",
            "Férin",
            "Fermanville",
            "Ferney-Voltaire",
            "Férolles",
            "Férolles-Attilly",
            "Ferques",
            "Ferrals-les-Corbières",
            "Ferrette",
            "Ferrière-la-Grande",
            "Ferrières-en-Brie",
            "Fesches-le-Châtel",
            "Fessenheim",
            "Festubert",
            "Féternes",
            "Feucherolles",
            "Feuchy",
            "Feuquières",
            "Feuquières-en-Vimeu",
            "Feurs",
            "Feytiat",
            "Feyzin",
            "Figanières",
            "Figari",
            "Figeac",
            "Fillé",
            "Fillinges",
            "Finhan",
            "Firmi",
            "Firminy",
            "Fismes",
            "Fitilieu",
            "Fitz-James",
            "Flamanville",
            "Flassans-sur-Issole",
            "Flaviac",
            "Flavigny-sur-Moselle",
            "Flavin",
            "Flavy-le-Martel",
            "Flaxlanden",
            "Flayosc",
            "Fléac",
            "Flers",
            "Flers-en-Escrebieux",
            "Flesselles",
            "Fleurance",
            "Fleurbaix",
            "Fleurey-sur-Ouche",
            "Fleurie",
            "Fleurieu-sur-Saône",
            "Fleurines",
            "Fleury",
            "Fleury",
            "Fleury-la-Vallée",
            "Fleury-les-Aubrais",
            "Fleury-Mérogis",
            "Fleury-sur-Andelle",
            "Fleury-sur-Orne",
            "Fléville-devant-Nancy",
            "Flines-lès-Mortagne",
            "Flines-lez-Raches",
            "Flins-sur-Seine",
            "Flixecourt",
            "Flize",
            "Flogny-la-Chapelle",
            "Floing",
            "Floirac",
            "Florac",
            "Florange",
            "Florensac",
            "Flourens",
            "Foëcy",
            "Foissiat",
            "Foix",
            "Folembray",
            "Folkling",
            "Follainville-Dennemont",
            "Folschviller",
            "Fonbeauzard",
            "Fondettes",
            "Fonsorbes",
            "Font-Romeu-Odeillo-Via",
            "Fontaine",
            "Fontaine-au-Pire",
            "Fontaine-Étoupefour",
            "Fontaine-la-Guyon",
            "Fontaine-la-Mallet",
            "Fontaine-le-Bourg",
            "Fontaine-le-Comte",
            "Fontaine-le-Dun",
            "Fontaine-lès-Dijon",
            "Fontaine-lès-Luxeuil",
            "Fontaine-Notre-Dame",
            "Fontainebleau",
            "Fontaines",
            "Fontaines-Saint-Martin",
            "Fontaines-sur-Saône",
            "Fontanil-Cornillon",
            "Fontannes",
            "Fontcouverte",
            "Fontenay",
            "Fontenay-aux-Roses",
            "Fontenay-en-Parisis",
            "Fontenay-le-Comte",
            "Fontenay-le-Fleury",
            "Fontenay-le-Marmion",
            "Fontenay-lès-Briis",
            "Fontenay-sous-Bois",
            "Fontenay-sur-Loing",
            "Fontenay-Trésigny",
            "Fontenilles",
            "Fontevraud-l'Abbaye",
            "Fontoy",
            "Fontvieille",
            "Forbach",
            "Forcalqueiret",
            "Forcalquier",
            "Forest-sur-Marque",
            "Forges-les-Bains",
            "Forges-les-Eaux",
            "Formerie",
            "Fors",
            "Fort-Mahon-Plage",
            "Fort-Mardyck",
            "Fortschwihr",
            "Fos-sur-Mer",
            "Fosses",
            "Foucarmont",
            "Foucherans",
            "Fouesnant",
            "Foug",
            "Fougères",
            "Fougerolles",
            "Fougerolles-du-Plessis",
            "Fouillard",
            "Foulayronnes",
            "Fouquereuil",
            "Fouquières-lès-Béthune",
            "Fouquières-lès-Lens",
            "Fouras",
            "Fourchambault",
            "Fourmies",
            "Fournes-en-Weppes",
            "Fourques",
            "Fourques-sur-Garonne",
            "Fourqueux",
            "Foussais-Payré",
            "Frahier-et-Chatebier",
            "Fraisans",
            "Fraisses",
            "Fraize",
            "Francheleins",
            "Francheville",
            "Francheville",
            "Franconville",
            "Frangy",
            "Franois",
            "Frans",
            "Frasne",
            "Fréhel",
            "Freigné",
            "Fréjairolles",
            "Fréjus",
            "Fréland",
            "Frelinghien",
            "Freneuse",
            "Frépillon",
            "Fresnay-sur-Sarthe",
            "Fresnes",
            "Fresnes-sur-Escaut",
            "Fresnoy-le-Grand",
            "Fresse-sur-Moselle",
            "Fressenneville",
            "Fréthun",
            "Fretin",
            "Frévent",
            "Freyming-Merlebach",
            "Frignicourt",
            "Friville-Escarbotin",
            "Froges",
            "Froideconche",
            "Froidfond",
            "Fromelennes",
            "Froncles",
            "Fronsac",
            "Frontenay-Rohan-Rohan",
            "Frontenex",
            "Frontignan",
            "Fronton",
            "Frontonas",
            "Frossay",
            "Frotey-lès-Vesoul",
            "Frouard",
            "Frouzins",
            "Fruges",
            "Fublaines",
            "Fumay",
            "Fumel",
            "Furdenheim",
            "Furiani",
            "Fussy",
            "Fuveau",
            "Gabarret",
            "Gacé",
            "Gaël",
            "Gagnac-sur-Garonne",
            "Gagny",
            "Gaillac",
            "Gaillac-Toulza",
            "Gaillan-en-Médoc",
            "Gaillard",
            "Gaillefontaine",
            "Gaillon",
            "Gainneville",
            "Galgon",
            "Gallardon",
            "Gallargues-le-Montueux",
            "Galluis",
            "Gamaches",
            "Gambais",
            "Gambsheim",
            "Gan",
            "Gandrange",
            "Ganges",
            "Gannat",
            "Gap",
            "Garancières",
            "Garat",
            "Garches",
            "Garchizy",
            "Gard",
            "Gardanne",
            "Gardonne",
            "Gardouch",
            "Garennes-sur-Eure",
            "Garéoult",
            "Gargas",
            "Gargenville",
            "Garges-lès-Gonesse",
            "Garidech",
            "Garlin",
            "Garnay",
            "Garons",
            "Gasny",
            "Gassin",
            "Gasville-Oisème",
            "Gattières",
            "Gauchy",
            "Gauriaguet",
            "Gavray",
            "Gazeran",
            "Geispolsheim",
            "Gelles",
            "Gelos",
            "Gémenos",
            "Gémozac",
            "Genas",
            "Genay",
            "Genech",
            "Génelard",
            "Générac",
            "Geneston",
            "Genillé",
            "Génissac",
            "Génissieux",
            "Genlis",
            "Gennes",
            "Gennevilliers",
            "Gensac-la-Pallue",
            "Gentilly",
            "Ger",
            "Gérardmer",
            "Gerbéviller",
            "Gerde",
            "Gergy",
            "Gers",
            "Gerstheim",
            "Gerzat",
            "Gespunsart",
            "Gesté",
            "Gestel",
            "Gétigné",
            "Geudertheim",
            "Gévezé",
            "Gevrey-Chambertin",
            "Gex",
            "Ghisonaccia",
            "Ghyvelde",
            "Giberville",
            "Gidy",
            "Gien",
            "Gières",
            "Gièvres",
            "Gif-sur-Yvette",
            "Gigean",
            "Gignac",
            "Gignac-la-Nerthe",
            "Gilette",
            "Gilley",
            "Gillonnay",
            "Gilly-sur-Isère",
            "Gimont",
            "Ginasservis",
            "Ginestas",
            "Giraumont",
            "Giromagny",
            "Gironde",
            "Gironde-sur-Dropt",
            "Giroussens",
            "Gisors",
            "Givenchy-en-Gohelle",
            "Givet",
            "Givors",
            "Givrand",
            "Givry",
            "Gleizé",
            "Glomel",
            "Goderville",
            "Godewaersvelde",
            "Gœrsdorf",
            "Goetzenbruck",
            "Gœulzin",
            "Goincourt",
            "Golbey",
            "Gometz-la-Ville",
            "Gometz-le-Châtel",
            "Gommegnies",
            "Goncelin",
            "Gond-Pontouvre",
            "Gondecourt",
            "Gondrecourt-le-Château",
            "Gondreville",
            "Gondrin",
            "Gonesse",
            "Gonfaron",
            "Gonnehem",
            "Gonneville-la-Mallet",
            "Gontaud-de-Nogaret",
            "Gorbio",
            "Gorcy",
            "Gordes",
            "Gorges",
            "Gorron",
            "Gorze",
            "Gosnay",
            "Gosné",
            "Gouaix",
            "Gouarec",
            "Goudargues",
            "Goudelin",
            "Gouesnach",
            "Gouesnou",
            "Gouézec",
            "Goult",
            "Gourdan-Polignan",
            "Gourdon",
            "Gourin",
            "Gournay-en-Bray",
            "Gournay-sur-Marne",
            "Goussainville",
            "Gouvernes",
            "Gouvieux",
            "Gouville-sur-Mer",
            "Gouy-sous-Bellonne",
            "Gouzeaucourt",
            "Gouzon",
            "Goven",
            "Grabels",
            "Graçay",
            "Grâces",
            "Gradignan",
            "Gragnague",
            "Grainville-la-Teinturière",
            "Grainville-sur-Odon",
            "Gramat",
            "Grambois",
            "Grand-Champ",
            "Grand-Charmont",
            "Grand-Couronne",
            "Grand-Fort-Philippe",
            "Grand-Fougeray",
            "Grandcamp-Maisy",
            "Grande-Synthe",
            "Grandfontaine",
            "Grandfresnoy",
            "Grandris",
            "Grandvillars",
            "Grandvilliers",
            "Grane",
            "Granges-sur-Vologne",
            "Grans",
            "Granville",
            "Grasse",
            "Gratentour",
            "Graulhet",
            "Gravelines",
            "Graveson",
            "Gravigny",
            "Gray",
            "Gray-la-Ville",
            "Gréasque",
            "Grenade",
            "Grenade-sur-l’Adour",
            "Grenay",
            "Grenay",
            "Grendelbruch",
            "Grenoble",
            "Gréoux-les-Bains",
            "Gresswiller",
            "Grésy-sur-Aix",
            "Grésy-sur-Isère",
            "Gretz-Armainvilliers",
            "Grez-Neuville",
            "Grez-sur-Loing",
            "Grézieu-la-Varenne",
            "Grièges",
            "Gries",
            "Griesheim-près-Molsheim",
            "Griesheim-sur-Souffel",
            "Grignan",
            "Grignols",
            "Grignon",
            "Grigny",
            "Grigny",
            "Grillon",
            "Grimaud",
            "Grisolles",
            "Grisy-Suisnes",
            "Groffliers",
            "Groissiat",
            "Groisy",
            "Groix",
            "Gron",
            "Gros-Réderching",
            "Grosbliederstroff",
            "Grosbreuil",
            "Groslay",
            "Grosseto-Prugna",
            "Gruchet-le-Valasse",
            "Gruffy",
            "Gruissan",
            "Gruson",
            "Guarbecque",
            "Guebwiller",
            "Guécélard",
            "Guégon",
            "Guémar",
            "Guémené-Penfao",
            "Guémené-sur-Scorff",
            "Guénange",
            "Guengat",
            "Guénin",
            "Guenrouet",
            "Guer",
            "Guérande",
            "Guérard",
            "Guéreins",
            "Guéret",
            "Guérigny",
            "Guerlesquin",
            "Guermantes",
            "Guern",
            "Guerville",
            "Guesnain",
            "Guéthary",
            "Gueugnon",
            "Gueux",
            "Guewenheim",
            "Guichainville",
            "Guichen",
            "Guiclan",
            "Guidel-Plage",
            "Guignen",
            "Guignes",
            "Guignicourt",
            "Guilers",
            "Guilherand-Granges",
            "Guillac",
            "Guillestre",
            "Guilliers",
            "Guilvinec",
            "Guînes",
            "Guingamp",
            "Guipavas",
            "Guipel",
            "Guipry",
            "Guiscard",
            "Guiscriff",
            "Guise",
            "Guissény",
            "Guîtres",
            "Gujan-Mestras",
            "Gumbrechtshoffen",
            "Gundershoffen",
            "Gurgy",
            "Guyancourt",
            "Gy",
            "Habas",
            "Habsheim",
            "Hadol",
            "Hagenthal-le-Bas",
            "Hagetmau",
            "Hagondange",
            "Haguenau",
            "Haillicourt",
            "Haisnes",
            "Hallencourt",
            "Hallennes-lez-Haubourdin",
            "Hallines",
            "Halluin",
            "Ham",
            "Ham-sous-Varsberg",
            "Hambach",
            "Hambye",
            "Hames-Boucres",
            "Hanches",
            "Hangenbieten",
            "Hanvec",
            "Harbonnières",
            "Hardinghen",
            "Hardricourt",
            "Harfleur",
            "Hargarten-aux-Mines",
            "Harly",
            "Harnes",
            "Hasnon",
            "Hasparren",
            "Haspres",
            "Hatten",
            "Haubourdin",
            "Haucourt",
            "Haulchin",
            "Haussy",
            "Haut-Rhin",
            "Haute-Garonne",
            "Haute-Goulaine",
            "Haute-Loire",
            "Haute-Marne",
            "Haute-Rivoire",
            "Haute-Savoie",
            "Haute-Vienne",
            "Hautefort",
            "Hauterive",
            "Hauterives",
            "Hautes-Alpes",
            "Hauteville-lès-Dijon",
            "Hauteville-Lompnes",
            "Hautmont",
            "Hautot-sur-Mer",
            "Hauts-de-Seine",
            "Hauville",
            "Haveluy",
            "Haverskerque",
            "Hayange",
            "Haybes",
            "Hazebrouck",
            "Hédé-Bazouges",
            "Hégenheim",
            "Heillecourt",
            "Heimsbrunn",
            "Hélesmes",
            "Helfaut",
            "Hem",
            "Hénanbihen",
            "Hendaye",
            "Hénin-Beaumont",
            "Hennebont",
            "Hénon",
            "Hénouville",
            "Henrichemont",
            "Henvic",
            "Herbault",
            "Herbeys",
            "Herbignac",
            "Herbitzheim",
            "Herblay",
            "Hérépian",
            "Hergnies",
            "Héric",
            "Héricourt",
            "Héricy",
            "Hérimoncourt",
            "Hérin",
            "Herlies",
            "Hermanville-sur-Mer",
            "Hermes",
            "Hermies",
            "Hermonville",
            "Hérouville-Saint-Clair",
            "Hérouvillette",
            "Herrlisheim",
            "Herrlisheim-près-Colmar",
            "Herry",
            "Herserange",
            "Hersin-Coupigny",
            "Héry",
            "Herzeele",
            "Hesdin",
            "Hésingue",
            "Hettange-Grande",
            "Heudreville-sur-Eure",
            "Heugas",
            "Heuringhem",
            "Heyrieux",
            "Hières-sur-Amby",
            "Hiersac",
            "Hillion",
            "Hilsenheim",
            "Hindisheim",
            "Hinges",
            "Hinx",
            "Hirel",
            "Hirsingue",
            "Hirson",
            "Hirtzbach",
            "Hirtzfelden",
            "Hochfelden",
            "Hochstatt",
            "Hœnheim",
            "Hœrdt",
            "Holnon",
            "Holtzheim",
            "Holtzwihr",
            "Holving",
            "Hombleux",
            "Homblières",
            "Hombourg-Haut",
            "Homécourt",
            "Hondschoote",
            "Honfleur",
            "Hôpital-Camfrout",
            "Horbourg-Wihr",
            "Hordain",
            "Horgues",
            "Hornaing",
            "Hornoy-le-Bourg",
            "Houdain",
            "Houdan",
            "Houdemont",
            "Houilles",
            "Houlbec-Cocherel",
            "Houlgate",
            "Houplin-Ancoisne",
            "Houplines",
            "Houppeville",
            "Hourtin",
            "Houssen",
            "Hoymille",
            "Huelgoat",
            "Huez",
            "Huismes",
            "Huisseau-sur-Cosson",
            "Huisseau-sur-Mauves",
            "Hulluch",
            "Hundling",
            "Huningue",
            "Huriel",
            "Hurigny",
            "Husseren-Wesserling",
            "Hussigny-Godbrange",
            "Huttenheim",
            "Hyères",
            "Ibos",
            "Idron",
            "Iffendic",
            "Ifs",
            "Igney",
            "Igny",
            "Igon",
            "Igoville",
            "Illange",
            "Illats",
            "Ille-sur-Têt",
            "Illfurth",
            "Illies",
            "Illkirch-Graffenstaden",
            "Illzach",
            "Imphy",
            "Incheville",
            "Indre",
            "Ingersheim",
            "Ingrandes",
            "Ingré",
            "Inguiniel",
            "Ingwiller",
            "Innenheim",
            "Irigny",
            "Irmstett",
            "Irodouër",
            "Irvillac",
            "Is-sur-Tille",
            "Isbergues",
            "Isigny-le-Buat",
            "Isigny-sur-Mer",
            "Isle",
            "Isneauville",
            "Isques",
            "Issé",
            "Issenheim",
            "Issoire",
            "Issou",
            "Issoudun",
            "Issy-les-Moulineaux",
            "Istres",
            "Itancourt",
            "Iteuil",
            "Ittenheim",
            "Itteville",
            "Itxassou",
            "Ivry-la-Bataille",
            "Ivry-sur-Seine",
            "Iwuy",
            "Izeaux",
            "Izernore",
            "Izon",
            "Jacob-Bellecombette",
            "Jacou",
            "Jallais",
            "Janneyrias",
            "Jans",
            "Janville",
            "Janville-sur-Juine",
            "Janzé",
            "Jard-sur-Mer",
            "Jardin",
            "Jargeau",
            "Jarnac",
            "Jarny",
            "Jarrie",
            "Jarville-la-Malgrange",
            "Jarzé",
            "Jassans-Riottier",
            "Jasseron",
            "Jaujac",
            "Jaunay-Clan",
            "Jausiers",
            "Jaux",
            "Javené",
            "Javron-les-Chapelles",
            "Jeanménil",
            "Jebsheim",
            "Jegun",
            "Jenlain",
            "Jeumont",
            "Job",
            "Jœuf",
            "Joigny",
            "Joinville",
            "Joinville-le-Pont",
            "Jonage",
            "Joncherey",
            "Jonchery-sur-Vesle",
            "Jonquerettes",
            "Jonquières",
            "Jonquières-Saint-Vincent",
            "Jons",
            "Jonzac",
            "Jonzieux",
            "Josselin",
            "Jouarre",
            "Jouars-Pontchartrain",
            "Joudreville",
            "Joué-lès-Tours",
            "Jouet-sur-l'Aubois",
            "Jougne",
            "Jouques",
            "Joux-la-Ville",
            "Jouy",
            "Jouy-aux-Arches",
            "Jouy-en-Josas",
            "Jouy-le-Châtel",
            "Jouy-le-Moutier",
            "Jouy-le-Potier",
            "Jouy-sur-Morin",
            "Joyeuse",
            "Jugon-les-Lacs",
            "Juigné",
            "Juigné-sur-Loire",
            "Juigné-sur-Sarthe",
            "Juillac",
            "Juillan",
            "Juilly",
            "Jujurieux",
            "Jullouville",
            "Jumelles",
            "Jumièges",
            "Jumilhac-le-Grand",
            "Jura",
            "Jurançon",
            "Jussac",
            "Jussey",
            "Jussy",
            "Juvignac",
            "Juvigné",
            "Juvigny-sous-Andaine",
            "Juvisy-sur-Orge",
            "Juziers",
            "Kaltenhouse",
            "Kaysersberg",
            "Kédange-sur-Canner",
            "Kembs",
            "Kerbach",
            "Kerlouan",
            "Kernilis",
            "Kersaint-Plabennec",
            "Kertzfeld",
            "Kervignac",
            "Keskastel",
            "Killem",
            "Kilstett",
            "Kingersheim",
            "Kintzheim",
            "Knutange",
            "Kœnigsmacker",
            "Krautergersheim",
            "Kruth",
            "Kunheim",
            "Kuntzig",
            "L'Estaque",
            "L'Haÿ-les-Roses",
            "L'Île-Bouchard",
            "L'Île-Rousse",
            "L'Union",
            "L’Escale",
            "L’Escarène",
            "L’Étang-la-Ville",
            "L’Étrat",
            "L’Isle-Adam",
            "L’Isle-d’Abeau",
            "L’Isle-en-Dodon",
            "L’Isle-Jourdain",
            "L’Isle-sur-la-Sorgue",
            "La Baconnière",
            "La Balme-de-Sillingy",
            "La Barasse",
            "La Barre-de-Monts",
            "La Barthe-de-Neste",
            "La Bassée",
            "La Bastide-des-Jourdans",
            "La Bâthie",
            "La Bâtie-Montgascon",
            "La Bâtie-Neuve",
            "La Baule-Escoublac",
            "La Bazoche-Gouet",
            "La Bazoge",
            "La Bégude-de-Mazenc",
            "La Bernardière",
            "La Bernerie-en-Retz",
            "La Biolle",
            "La Blancarde",
            "La Bohalle",
            "La Boisse",
            "La Boissière-de-Montaigu",
            "La Boissière-des-Landes",
            "La Bonneville-sur-Iton",
            "La Bouëxière",
            "La Bouilladisse",
            "La Bourboule",
            "La Boussac",
            "La Bresse",
            "La Bridoire",
            "La Broque",
            "La Bruffière",
            "La Buisse",
            "La Cabucelle",
            "La Calade",
            "La Calmette",
            "La Canourgue",
            "La Capelette",
            "La Capelle",
            "La Capelle-lès-Boulogne",
            "La Cavalerie",
            "La Celle",
            "La Celle-Saint-Avant",
            "La Celle-Saint-Cloud",
            "La Celle-sur-Morin",
            "La Cerlangue",
            "La Chaize-le-Vicomte",
            "La Chambre",
            "La Chapelle-Achard",
            "La Chapelle-Basse-Mer",
            "La Chapelle-de-Guinchay",
            "La Chapelle-de-la-Tour",
            "La Chapelle-des-Fougeretz",
            "La Chapelle-des-Marais",
            "La Chapelle-du-Genêt",
            "La Chapelle-du-Noyer",
            "La Chapelle-en-Serval",
            "La Chapelle-Gauthier",
            "La Chapelle-Heulin",
            "La Chapelle-Janson",
            "La Chapelle-la-Reine",
            "La Chapelle-Launay",
            "La Chapelle-Réanville",
            "La Chapelle-Saint-Aubin",
            "La Chapelle-Saint-Florent",
            "La Chapelle-Saint-Laurent",
            "La Chapelle-Saint-Luc",
            "La Chapelle-Saint-Mesmin",
            "La Chapelle-Saint-Ursin",
            "La Chapelle-sur-Crécy",
            "La Chapelle-sur-Erdre",
            "La Chapelle-sur-Loire",
            "La Chapelle-Thouarault",
            "La Charité-sur-Loire",
            "La Chartre-sur-le-Loir",
            "La Châtaigneraie",
            "La Châtre",
            "La Chaussée-Saint-Victor",
            "La Chevrolière",
            "La Ciotat",
            "La Clayette",
            "La Clusaz",
            "La Cluse-et-Mijoux",
            "La Colle-sur-Loup",
            "La Conception",
            "La Coquille",
            "La Côte-Saint-André",
            "La Couarde-sur-Mer",
            "La Courneuve",
            "La Couronne",
            "La Courtine",
            "La Couture",
            "La Couture-Boussey",
            "La Crau",
            "La Crèche",
            "La Croix-en-Touraine",
            "La Croix-Rouge",
            "La Croix-Valmer",
            "La Daguenière",
            "La Defense",
            "La Delorme",
            "La Destrousse",
            "La Dominelais",
            "La Fare-les-Oliviers",
            "La Farlède",
            "La Fère",
            "La Ferrière",
            "La Ferrière-aux-Étangs",
            "La Ferté-Alais",
            "La Ferté-Bernard",
            "La Ferté-Gaucher",
            "La Ferté-Imbault",
            "La Ferté-Macé",
            "La Ferté-Milon",
            "La Ferté-Saint-Aubin",
            "La Ferté-sous-Jouarre",
            "La Feuillie",
            "La Flamengrie",
            "La Flèche",
            "La Flocellière",
            "La Flotte",
            "La Force",
            "La Forest-Landerneau",
            "La Forêt-Fouesnant",
            "La Forêt-sur-Sèvre",
            "La Fouillade",
            "La Fouillouse",
            "La Fourragère",
            "La Francheville",
            "La Fresnais",
            "La Frette-sur-Seine",
            "La Gacilly",
            "La Garde",
            "La Garde-Adhémar",
            "La Garde-Freinet",
            "La Garenne-Colombes",
            "La Garnache",
            "La Gaubretière",
            "La Gaude",
            "La Génétouze",
            "La Glacerie",
            "La Gorgue",
            "La Gouesnière",
            "La Grand-Croix",
            "La Grande-Motte",
            "La Grande-Paroisse",
            "La Grigonnais",
            "La Guerche-de-Bretagne",
            "La Guérinière",
            "la Guingueta d'Ix",
            "La Guyonnière",
            "La Haie-Fouassière",
            "La Haye-du-Puits",
            "La Haye-Malherbe",
            "La Haye-Pesnel",
            "La Houssaye-en-Brie",
            "La Jarne",
            "La Jarrie",
            "La Joliette",
            "La Jubaudière",
            "La Jumellière",
            "La Lande-de-Fronsac",
            "La Lande-Patry",
            "La Limouzinière",
            "La Londe-les-Maures",
            "La Longueville",
            "La Loubière",
            "La Loupe",
            "La Machine",
            "La Madeleine",
            "La Madeleine-de-Nonancourt",
            "La Mailleraye-sur-Seine",
            "La Meauffe",
            "La Méaugon",
            "La Meignanne",
            "La Meilleraie-Tillay",
            "La Meilleraye-de-Bretagne",
            "La Membrolle-sur-Choisille",
            "La Membrolle-sur-Longuenée",
            "La Ménitré",
            "La Mézière",
            "La Milesse",
            "La Millère",
            "La Monnerie-le-Montel",
            "La Montagne",
            "La Mothe-Achard",
            "La Mothe-Saint-Héray",
            "La Motte",
            "La Motte",
            "La Motte-d’Aveillans",
            "La Motte-Saint-Jean",
            "La Motte-Saint-Martin",
            "La Motte-Servolex",
            "La Mulatière",
            "La Mure",
            "La Murette",
            "La Norville",
            "La Pacaudière",
            "La Page",
            "La Palme",
            "La Panouse",
            "La Penne-sur-Huveaune",
            "La Peyratte",
            "La Plaine-sur-Mer",
            "La Planche",
            "La Pointe Rouge",
            "La Pomme",
            "La Pommeraie-sur-Sèvre",
            "La Pommeraye",
            "La Possonnière",
            "La Pouëze",
            "La Queue-en-Brie",
            "La Queue-les-Yvelines",
            "La Ravoire",
            "La Regrippière",
            "La Remaudière",
            "La Remuée",
            "La Réole",
            "La Ricamarie",
            "La Richardais",
            "La Riche",
            "La Rivière-de-Corps",
            "La Rivière-Saint-Sauveur",
            "la Roca d'Albera",
            "La Roche-Blanche",
            "La Roche-Chalais",
            "La Roche-de-Glun",
            "La Roche-Derrien",
            "La Roche-des-Arnauds",
            "La Roche-Maurice",
            "La Roche-Posay",
            "La Roche-sur-Foron",
            "La Roche-sur-Yon",
            "La Roche-Vineuse",
            "La Rochefoucauld",
            "La Rochelle",
            "La Rochette",
            "La Rochette",
            "La Romagne",
            "La Roque-d’Anthéron",
            "La Roquebrussanne",
            "La Roquette-sur-Siagne",
            "La Rose",
            "La Salle-et-Chapelle-Aubry",
            "La Salvetat-Peyralès",
            "La Salvetat-Saint-Gilles",
            "La Salvetat-sur-Agout",
            "La Saussaye",
            "La Sauve",
            "La Séauve-sur-Semène",
            "La Séguinière",
            "La Selle-la-Forge",
            "La Sentinelle",
            "La Seyne-sur-Mer",
            "La Souterraine",
            "La Suze-sur-Sarthe",
            "La Talaudière",
            "La Tardière",
            "La Terrasse",
            "La Tessoualle",
            "La Teste-de-Buch",
            "La Timone",
            "La Tour",
            "La Tour-de-Salvagny",
            "La Tour-du-Crieu",
            "La Tour-du-Pin",
            "La Tour-en-Jarez",
            "La Tour-sur-Orb",
            "La Tourlandry",
            "La Tranche-sur-Mer",
            "La Tremblade",
            "La Trinité",
            "La Trinité-sur-Mer",
            "La Tronche",
            "La Turballe",
            "La Turbie",
            "La Valbarelle",
            "La Valentine",
            "La Valette-du-Var",
            "La Varenne",
            "La Vaupalière",
            "La Verpillière",
            "La Verrie",
            "La Verrière",
            "La Vespière",
            "La Ville-aux-Clercs",
            "La Ville-aux-Dames",
            "La Ville-du-Bois",
            "La Villedieu-du-Clain",
            "La Villette",
            "La Viste",
            "La Voulte-sur-Rhône",
            "La Vraie-Croix",
            "La Wantzenau",
            "Labaroche",
            "Labarthe-Rivière",
            "Labarthe-sur-Lèze",
            "Labastide-Beauvoir",
            "Labastide-Rouairoux",
            "Labastide-Saint-Georges",
            "Labastide-Saint-Pierre",
            "Labastide-Saint-Sernin",
            "Labastidette",
            "Labatut",
            "Labège",
            "Labégude",
            "Labenne",
            "Labeuvrière",
            "Lablachère",
            "Laboissière-en-Thelle",
            "Labouheyre",
            "Labourse",
            "Labruguière",
            "Labry",
            "Lacanau",
            "Lacapelle-Marival",
            "Lacasse",
            "Lacaune",
            "Lachapelle-aux-Pots",
            "Lachapelle-sous-Aubenas",
            "Lacroix-Falgarde",
            "Lacroix-Saint-Ouen",
            "Lacrouzette",
            "Ladignac-le-Long",
            "Ladoix-Serrigny",
            "Ladon",
            "Lafrançaise",
            "Lagardelle-sur-Lèze",
            "Lagarrigue",
            "Lagnes",
            "Lagnieu",
            "Lagny-le-Sec",
            "Lagny-sur-Marne",
            "Lagor",
            "Lagorce",
            "Lagord",
            "Lagrave",
            "Laguenne",
            "Laguiole",
            "Lahonce",
            "Laigné-en-Belin",
            "Laigneville",
            "Laillé",
            "Lailly-en-Val",
            "Laissac",
            "Laiz",
            "Lalbenque",
            "Lalevade-d’Ardèche",
            "Lalinde",
            "Lallaing",
            "Laloubère",
            "Lamagistère",
            "Lamalou-les-Bains",
            "Lamanon",
            "Lamarche",
            "Lamarche-sur-Saône",
            "Lamarque",
            "Lamastre",
            "Lamballe",
            "Lambersart",
            "Lambesc",
            "Lambres-lez-Douai",
            "Lamonzie-Saint-Martin",
            "Lamorlaye",
            "Lamothe-Montravel",
            "Lamotte-Beuvron",
            "Lampaul-Guimiliau",
            "Lampaul-Plouarzel",
            "Lampertheim",
            "Lamure-sur-Azergues",
            "Lancieux",
            "Lançon-Provence",
            "Lancrans",
            "Landas",
            "Landaul",
            "Landéan",
            "Landéda",
            "Landéhen",
            "Landeleau",
            "Landemont",
            "Landerneau",
            "Landeronde",
            "Landes",
            "Landévant",
            "Landiras",
            "Landivisiau",
            "Landivy",
            "Landrecies",
            "Landres",
            "Landrévarzec",
            "Landser",
            "Landudec",
            "Landunvez",
            "Lanester",
            "Laneuveville-devant-Nancy",
            "Langeac",
            "Langeais",
            "Langlade",
            "Langoat",
            "Langogne",
            "Langoiran",
            "Langon",
            "Langon",
            "Langonnet",
            "Langres",
            "Langrune-sur-Mer",
            "Langueux",
            "Languidic",
            "Lanmeur",
            "Lannemezan",
            "Lannilis",
            "Lannion",
            "Lannoy",
            "Lanobre",
            "Lanouaille",
            "Lanouée",
            "Lanquetot",
            "Lanrivoaré",
            "Lanrodec",
            "Lans-en-Vercors",
            "Lansargues",
            "Lanta",
            "Lantic",
            "Lanton",
            "Lantosque",
            "Lantriac",
            "Lanvallay",
            "Lanvénégen",
            "Lanvéoc",
            "Lanvollon",
            "Laon",
            "Lapalisse",
            "Lapalud",
            "Lapeyrouse-Fossat",
            "Laplume",
            "Lapoutroie",
            "Lapte",
            "Lapugnoy",
            "Laragne-Montéglin",
            "Larajasse",
            "Larçay",
            "Larchamp",
            "Larche",
            "Lardy",
            "Larequille",
            "Largentière",
            "Larmor-Baden",
            "Larmor-Plage",
            "Laroche-Saint-Cydroine",
            "Laroque",
            "Laroque-d’Olmes",
            "Laroque-Timbaut",
            "Laroquebrou",
            "Larressore",
            "Larringes",
            "Laruns",
            "Laruscade",
            "Lasalle",
            "Lassay-les-Châteaux",
            "Lasseube",
            "Lassigny",
            "Lassy",
            "Lathus-Saint-Rémy",
            "Latillé",
            "Latour-Bas-Elne",
            "Latresne",
            "Lattes",
            "Laudun-l'Ardoise",
            "Launac",
            "Launaguet",
            "Laure-Minervois",
            "Laurens",
            "Lauris",
            "Laussonne",
            "Lautenbach",
            "Lautenbachzell",
            "Lauterbourg",
            "Lautrec",
            "Lauwin-Planque",
            "Lauzerte",
            "Laval",
            "Laval-Pradel",
            "Lavalette",
            "Lavans-lès-Saint-Claude",
            "Lavardac",
            "Lavault-Sainte-Anne",
            "Lavaur",
            "Lavelanet",
            "Laventie",
            "Lavernose-Lacasse",
            "Lavérune",
            "Lavilledieu",
            "Lavit",
            "Lavoux",
            "Laxou",
            "Lay-Saint-Christophe",
            "Layrac",
            "Le Ban Saint-Martin",
            "Le Bar-sur-Loup",
            "Le Barcarès",
            "Le Barp",
            "Le Beausset",
            "Le Bignon",
            "Le Blanc",
            "Le Blanc-Mesnil",
            "Le Bois-d'Oingt",
            "Le Bois-Plage-en-Ré",
            "Le Bono",
            "Le Bosc-Roger-en-Roumois",
            "Le Boupère",
            "Le Bourg-d’Oisans",
            "Le Bourget",
            "Le Bourget-du-Lac",
            "Le Bourgneuf-la-Forêt",
            "Le Bouscat",
            "Le Bousquet-d’Orb",
            "Le Breil-sur-Mérize",
            "Le Breuil",
            "Le Breuil-sur-Couze",
            "Le Broc",
            "Le Brusquet",
            "Le Bugue",
            "Le Buisson-de-Cadouin",
            "Le Cabot",
            "Le Cailar",
            "Le Camas",
            "Le Canet",
            "Le Cannet",
            "Le Cannet-des-Maures",
            "Le Cap d'Agde",
            "Le Castellet",
            "Le Cellier",
            "Le Cendre",
            "Le Chambon-Feugerolles",
            "Le Chambon-sur-Lignon",
            "Le Champ-près-Froges",
            "Le Champ-Saint-Père",
            "Le Chapitre",
            "Le Châtelet",
            "Le Châtelet-en-Brie",
            "Le Chesnay",
            "Le Cheylard",
            "Le Cheylas",
            "Le Conquet",
            "Le Coteau",
            "Le Coudray",
            "Le Coudray-Montceaux",
            "Le Crès",
            "Le Crest",
            "Le Creusot",
            "Le Croisic",
            "Le Crotoy",
            "Le Donjon",
            "Le Dorat",
            "Le Doulieu",
            "Le Drennec",
            "Le Faou",
            "Le Faouët",
            "Le Fauga",
            "Le Fenouiller",
            "Le Fief-Sauvin",
            "Le Fleix",
            "Le Fœil",
            "Le Folgoët",
            "Le Fousseret",
            "Le Fuilet",
            "Le Garric",
            "Le Gâvre",
            "Le Grand-Bornand",
            "Le Grand-Bourg",
            "Le Grand-Lemps",
            "Le Grand-Lucé",
            "Le Grand-Pressigny",
            "Le Grand-Quevilly",
            "Le Grau-du-Roi",
            "Le Gua",
            "Le Gua",
            "Le Haillan",
            "Le Havre",
            "Le Houga",
            "Le Houlme",
            "Le Kremlin-Bicêtre",
            "Le Landreau",
            "Le Langon",
            "Le Lardin-Saint-Lazare",
            "Le Lavandou",
            "Le Longeron",
            "Le Loroux-Bottereau",
            "Le Louroux-Béconnais",
            "Le Luart",
            "Le Luc",
            "Le Lude",
            "Le Malzieu-Ville",
            "Le Manoir",
            "Le Mans",
            "Le Mas-d’Agenais",
            "Le May-sur-Èvre",
            "Le Mayet-de-Montagne",
            "Le Mée-sur-Seine",
            "Le Ménil",
            "Le Merlan",
            "Le Mesnil-en-Thelle",
            "Le Mesnil-en-Vallée",
            "Le Mesnil-Esnard",
            "Le Mesnil-le-Roi",
            "Le Mesnil-Saint-Denis",
            "Le Mesnil-sur-Oger",
            "Le Meux",
            "Le Minihic-sur-Rance",
            "Le Molay-Littry",
            "Le Monastère",
            "Le Monastier-sur-Gazeille",
            "Le Monêtier-les-Bains",
            "Le Muy",
            "Le Neubourg",
            "Le Nouvion-en-Thiérache",
            "Le Palais",
            "Le Palais-sur-Vienne",
            "Le Pallet",
            "Le Passage",
            "Le Péage-de-Roussillon",
            "Le Pêchereau",
            "Le Pecq",
            "Le Pellerin",
            "Le Perray-en-Yvelines",
            "Le Perréon",
            "Le Perreux-sur-Marne",
            "Le Perrier",
            "Le Pertre",
            "Le Petit-Quevilly",
            "Le Pharo",
            "Le Pian-Médoc",
            "Le Pin",
            "Le Pin",
            "Le Pin",
            "Le Pin-en-Mauges",
            "Le Pizou",
            "Le Plan-de-la-Tour",
            "Le Plessis-Belleville",
            "Le Plessis-Bouchard",
            "Le Plessis-Brion",
            "Le Plessis-Grammoire",
            "Le Plessis-Macé",
            "Le Plessis-Pâté",
            "Le Plessis-Robinson",
            "Le Plessis-Trévise",
            "Le Poinçonnet",
            "Le Poiré-sur-Vie",
            "Le Pont-de-Beauvoisin",
            "Le Pont-de-Claix",
            "Le Pontet",
            "Le Porge",
            "Le Port-Marly",
            "Le Portel",
            "Le Pouget",
            "Le Poujol-sur-Orb",
            "Le Pouliguen",
            "Le Pouzin",
            "Le Pradet",
            "Le Pré-Saint-Gervais",
            "Le Puy-en-Velay",
            "Le Puy-Notre-Dame",
            "Le Puy-Sainte-Réparade",
            "Le Quesnoy",
            "Le Raincy",
            "Le Redon",
            "Le Relecq-Kerhuon",
            "Le Revest-les-Eaux",
            "Le Rheu",
            "Le Rouret",
            "Le Rove",
            "Le Russey",
            "Le Sappey-en-Chartreuse",
            "Le Sequestre",
            "Le Soler",
            "Le Sourn",
            "Le Syndicat",
            "Le Taillan-Médoc",
            "Le Tallud",
            "Le Teich",
            "Le Teil",
            "Le Teilleul",
            "Le Temple-de-Bretagne",
            "Le Theil-Bocage",
            "Le Theil-de-Bretagne",
            "Le Thillay",
            "Le Thillot",
            "Le Tholonet",
            "Le Tholy",
            "Le Thor",
            "Le Thoronet",
            "Le Thou",
            "Le Thuit-Signol",
            "Le Tignet",
            "Le Touquet-Paris-Plage",
            "Le Touvet",
            "Le Trait",
            "Le Tréport",
            "Le Trévoux",
            "Le Val",
            "Le Val-Saint-Germain",
            "Le Val-Saint-Père",
            "Le Vaudreuil",
            "Le Verdon-sur-Mer",
            "Le Versoud",
            "Le Vésinet",
            "Le Vieil-Baugé",
            "Le Vieux-Marché",
            "Le Vigan",
            "Le Vigen",
            "Le Vivier-sur-Mer",
            "Lecelles",
            "Lécluse",
            "Lécousse",
            "Lectoure",
            "Lédenon",
            "Ledeuix",
            "Lédignan",
            "Leers",
            "Leffrinckoucke",
            "Leforest",
            "Legé",
            "Léguevin",
            "Léhon",
            "Lembach",
            "Lemberg",
            "Lembras",
            "Lempdes-sur-Allagnon",
            "Lencloître",
            "Lens",
            "Lent",
            "Lentigny",
            "Lentilly",
            "Léognan",
            "Léon",
            "Lepuix",
            "Léré",
            "Lérouville",
            "Léry",
            "Les Abrets",
            "Les Accates",
            "Les Ageux",
            "Les Aix-d’Angillon",
            "Les Allues",
            "Les Alluets-le-Roi",
            "Les Ancizes",
            "Les Andelys",
            "Les Angles",
            "Les Arcs",
            "Les Arnavaux",
            "Les Artigues-de-Lussac",
            "Les Attaques",
            "Les Authieux-sur-le-Port-Saint-Ouen",
            "Les Avenières",
            "Les Aygalades",
            "Les Baumettes",
            "Les Brouzils",
            "Les Caillols",
            "Les Camoins",
            "Les Champs-Géraux",
            "Les Chartreux",
            "Les Chères",
            "Les Clayes-sous-Bois",
            "Les Clouzeaux",
            "Les Contamines-Montjoie",
            "Les Côtes-d'Arey",
            "Les Crottes",
            "Les Deux Alpes",
            "Les Échelles",
            "Les Églisottes-et-Chalaures",
            "Les Epesses",
            "Les Essarts",
            "Les Essarts-le-Roi",
            "Les Fins",
            "Les Forges",
            "Les Fourgs",
            "Les Gets",
            "Les Gonds",
            "Les Grandes-Ventes",
            "Les Grands Carmes",
            "Les Hautes-Rivières",
            "Les Herbiers",
            "Les Houches",
            "Les Landes-Genusson",
            "Les Lilas",
            "Les Loges",
            "Les Loges-en-Josas",
            "Les Loges-Marchis",
            "Les Lucs-sur-Boulogne",
            "Les Mages",
            "Les Magnils-Reigniers",
            "Les Marches",
            "Les Martres-d'Artière",
            "Les Matelles",
            "Les Mathes",
            "Les Médecins",
            "Les Mées",
            "Les Molières",
            "Les Montils",
            "Les Moutiers-en-Retz",
            "Les Mureaux",
            "Les Noës-près-Troyes",
            "Les Olives",
            "Les Ormes",
            "Les Pavillons-sous-Bois",
            "Les Peintures",
            "Les Pennes-Mirabeau",
            "Les Pieux",
            "Les Ponts-de-Cé",
            "Les Riceys",
            "Les Roches-de-Condrieu",
            "Les Rosiers-sur-Loire",
            "Les Rousses",
            "Les Sables-d’Olonne",
            "Les Salles-du-Gardon",
            "Les Sorinières",
            "Les Touches",
            "Les Trois-Lucs",
            "Les Ulis",
            "Les Vans",
            "Lescar",
            "Lésigny",
            "Lesneven",
            "Lesparre-Médoc",
            "Lespignan",
            "Lespinasse",
            "Lesquin",
            "Lessay",
            "Lestelle-Bétharram",
            "Lestrem",
            "Leucate",
            "Leudeville",
            "Leuville-sur-Orge",
            "Leval",
            "Levallois-Perret",
            "Levens",
            "Lèves",
            "Levet",
            "Levier",
            "Lévignac",
            "Lévis-Saint-Nom",
            "Levroux",
            "Lewarde",
            "Lexy",
            "Leyme",
            "Leymen",
            "Leyment",
            "Lézan",
            "Lézardrieux",
            "Lézat-sur-Lèze",
            "Lezay",
            "Lezennes",
            "Lézignan-Corbières",
            "Lézignan-la-Cèbe",
            "Lézigneux",
            "Lezoux",
            "Lherm",
            "Liancourt",
            "Libercourt",
            "Libourne",
            "Licques",
            "Lièpvre",
            "Liergues",
            "Liesse-Notre-Dame",
            "Lieu-Saint-Amand",
            "Lieuran-lès-Béziers",
            "Lieurey",
            "Lieusaint",
            "Liévin",
            "Liffol-le-Grand",
            "Liffré",
            "Lignan-sur-Orb",
            "Ligné",
            "Lignières",
            "Ligny-en-Barrois",
            "Ligny-en-Cambrésis",
            "Ligny-le-Châtel",
            "Ligny-le-Ribault",
            "Ligré",
            "Ligueil",
            "Ligugé",
            "Lille",
            "Lillebonne",
            "Lillers",
            "Limas",
            "Limay",
            "Limeil-Brévannes",
            "Limerzel",
            "Limésy",
            "Limetz-Villez",
            "Limoges",
            "Limonest",
            "Limours",
            "Limoux",
            "Linards",
            "Linars",
            "Linas",
            "Lingolsheim",
            "Linguizzetta",
            "Linselles",
            "Linxe",
            "Lion-sur-Mer",
            "Lipsheim",
            "Liré",
            "Lisieux",
            "Lisle-sur-Tarn",
            "Lisses",
            "Lissieu",
            "Listrac-Médoc",
            "Lit-et-Mixe",
            "Livarot",
            "Liverdun",
            "Liverdy-en-Brie",
            "Livet-et-Gavet",
            "Livinhac-le-Haut",
            "Livré-sur-Changeon",
            "Livron-sur-Drôme",
            "Livry-Gargan",
            "Livry-sur-Seine",
            "Lizy-sur-Ourcq",
            "Llupia",
            "Loches",
            "Locmariaquer",
            "Locminé",
            "Locmiquélic",
            "Locoal-Mendon",
            "Locon",
            "Locquémeau",
            "Locquirec",
            "Loctudy",
            "Lodève",
            "Lodi",
            "Lognes",
            "Logonna-Daoulas",
            "Loire-Atlantique",
            "Loire-sur-Rhône",
            "Loiret",
            "Loiron",
            "Loisin",
            "Loison-sous-Lens",
            "Loivre",
            "Lombez",
            "Lombron",
            "Lomme",
            "Lompret",
            "Londinières",
            "Longages",
            "Longchamp",
            "Longchaumois",
            "Longecourt-en-Plaine",
            "Longeville-en-Barrois",
            "Longeville-lès-Metz",
            "Longeville-lès-Saint-Avold",
            "Longeville-sur-Mer",
            "Longfossé",
            "Longjumeau",
            "Longlaville",
            "Longnes",
            "Longny-au-Perche",
            "Longperrier",
            "Longpont-sur-Orge",
            "Longpré-les-Corps-Saints",
            "Longueau",
            "Longueil-Annel",
            "Longueil-Sainte-Marie",
            "Longuenesse",
            "Longueville",
            "Longuyon",
            "Longvic",
            "Longwy",
            "Lons",
            "Lons-le-Saunier",
            "Looberghe",
            "Loon-Plage",
            "Loos",
            "Loos-en-Gohelle",
            "Lopérec",
            "Loperhet",
            "Lorette",
            "Lorgies",
            "Lorgues",
            "Lorient",
            "Loriol-du-Comtat",
            "Loriol-sur-Drôme",
            "Lormaison",
            "Lormes",
            "Lormont",
            "Lorquin",
            "Lorrez-le-Bocage-Préaux",
            "Lorris",
            "Losne",
            "Lot",
            "Louannec",
            "Louargat",
            "Loubert",
            "Loudéac",
            "Loudun",
            "Loué",
            "Louhans",
            "Loupiac",
            "Loupian",
            "Louplande",
            "Lourches",
            "Lourdes",
            "Lourmarin",
            "Loury",
            "Louveciennes",
            "Louverné",
            "Louvie-Juzon",
            "Louviers",
            "Louvigné-de-Bais",
            "Louvigné-du-Désert",
            "Louvigny",
            "Louvres",
            "Louvroil",
            "Louzy",
            "Lovagny",
            "Loyat",
            "Loyettes",
            "Lozanne",
            "Lozinghem",
            "Luant",
            "Lubersac",
            "Luc-la-Primaube",
            "Luc-sur-Mer",
            "Luçay-le-Mâle",
            "Lucciana",
            "Lucé",
            "Luceau",
            "Lucenay",
            "Lucenay-lès-Aix",
            "Lucéram",
            "Luché-Pringé",
            "Lucinges",
            "Luçon",
            "Lucq-de-Béarn",
            "Ludon-Médoc",
            "Ludres",
            "Lugon-et-l'Île-du-Carnay",
            "Lugrin",
            "Luisant",
            "Luitré",
            "Lumbin",
            "Lumbres",
            "Lumes",
            "Lumigny-Nesles-Ormeaux",
            "Lumio",
            "Lunay",
            "Lunel",
            "Lunel-Viel",
            "Luneray",
            "Lunery",
            "Lunéville",
            "Luray",
            "Lurcy-Lévis",
            "Lure",
            "Lusanger",
            "Lusignan",
            "Lusigny",
            "Lusigny-sur-Barse",
            "Lussac",
            "Lussac-les-Châteaux",
            "Lutterbach",
            "Lutzelhouse",
            "Luxeuil-les-Bains",
            "Luynes",
            "Luz-Saint-Sauveur",
            "Luzarches",
            "Luzech",
            "Luzinay",
            "Luzy",
            "Lyaud",
            "Lyon",
            "Lys-lez-Lannoy",
            "Mably",
            "Macau",
            "Maché",
            "Machecoul",
            "Macheren",
            "Maclas",
            "Mâcon",
            "Mâcot-la-Plagne",
            "Maël-Carhaix",
            "Maffliers",
            "Magalas",
            "Magenta",
            "Magescq",
            "Magland",
            "Magnac-Laval",
            "Magnac-sur-Touvre",
            "Magnanville",
            "Magné",
            "Magny-Cours",
            "Magny-en-Vexin",
            "Magny-le-Désert",
            "Magny-le-Hongre",
            "Magny-les-Hameaux",
            "Magny-Vernois",
            "Maîche",
            "Maidières",
            "Maignelay-Montigny",
            "Maillane",
            "Maillezais",
            "Maillot",
            "Mailly-le-Camp",
            "Maincy",
            "Maing",
            "Maintenon",
            "Mainvilliers",
            "Maisdon-sur-Sèvre",
            "Maisnil-lès-Ruitz",
            "Maisons-Alfort",
            "Maisons-Laffitte",
            "Maisse",
            "Maizières-la-Grande-Paroisse",
            "Maizières-lès-Metz",
            "Malakoff",
            "Malansac",
            "Malataverne",
            "Malaucène",
            "Malaunay",
            "Malay-le-Grand",
            "Malemort-du-Comtat",
            "Malemort-sur-Corrèze",
            "Malesherbes",
            "Malestroit",
            "Malguénac",
            "Malicorne-sur-Sarthe",
            "Malijai",
            "Malintrat",
            "Malissard",
            "Mallemoisson",
            "Mallemort",
            "Malpassé",
            "Malville",
            "Malzéville",
            "Mamers",
            "Mametz",
            "Mamirolle",
            "Manche",
            "Mancieulles",
            "Mandelieu-la-Napoule",
            "Mandeure",
            "Mandres-les-Roses",
            "Manduel",
            "Mane",
            "Manéglise",
            "Manneville-sur-Risle",
            "Manom",
            "Manosque",
            "Mansac",
            "Mansigné",
            "Mansle",
            "Mantes-la-Jolie",
            "Mantes-la-Ville",
            "Manthelan",
            "Manzat",
            "Manziat",
            "Marange-Silvange",
            "Marans",
            "Maraussan",
            "Marbache",
            "Marboué",
            "Marboz",
            "Marcamps",
            "Marcellaz-Albanais",
            "Marcey-les-Grèves",
            "Marcheprime",
            "Marchiennes",
            "Marciac",
            "Marcigny",
            "Marcillac",
            "Marcillac-Vallon",
            "Marcilly-en-Villette",
            "Marcilly-le-Châtel",
            "Marcilly-sur-Eure",
            "Marcilly-sur-Tille",
            "Marck",
            "Marckolsheim",
            "Marcoing",
            "Marçon",
            "Marconne",
            "Marconnelle",
            "Marcorignan",
            "Marcoussis",
            "Marcq-en-Barœul",
            "Mardeuil",
            "Mardié",
            "Mareau-aux-Prés",
            "Mareil-Marly",
            "Mareil-sur-Mauldre",
            "Marennes",
            "Marennes",
            "Maretz",
            "Mareuil",
            "Mareuil-le-Port",
            "Mareuil-lès-Meaux",
            "Mareuil-sur-Ay",
            "Mareuil-sur-Cher",
            "Mareuil-sur-Lay-Dissais",
            "Mareuil-sur-Ourcq",
            "Margaux",
            "Margencel",
            "Margency",
            "Margny-lès-Compiègne",
            "Margon",
            "Marguerittes",
            "Marignane",
            "Marigné-Laillé",
            "Marignier",
            "Marigny",
            "Marigny-Brizay",
            "Marigny-le-Châtel",
            "Marigny-les-Usages",
            "Marin",
            "Marines",
            "Maringues",
            "Marle",
            "Marlenheim",
            "Marles-en-Brie",
            "Marles-les-Mines",
            "Marlhes",
            "Marly",
            "Marly",
            "Marly-la-Ville",
            "Marly-le-Roi",
            "Marmagne",
            "Marmagne",
            "Marmande",
            "Marmoutier",
            "Marnay",
            "Marnaz",
            "Marne",
            "Marnes-la-Coquette",
            "Marœuil",
            "Maroilles",
            "Marolles-en-Hurepoix",
            "Marolles-les-Braults",
            "Marolles-sur-Seine",
            "Maromme",
            "Marpent",
            "Marquefave",
            "Marquette-en-Ostrevant",
            "Marquette-lez-Lille",
            "Marquillies",
            "Marquion",
            "Marquise",
            "Marsac-en-Livradois",
            "Marsac-sur-Don",
            "Marsannay-la-Côte",
            "Marsanne",
            "Marsat",
            "Marseillan",
            "Marseille",
            "Marseille 01",
            "Marseille 02",
            "Marseille 03",
            "Marseille 04",
            "Marseille 05",
            "Marseille 06",
            "Marseille 07",
            "Marseille 08",
            "Marseille 09",
            "Marseille 10",
            "Marseille 11",
            "Marseille 12",
            "Marseille 13",
            "Marseille 14",
            "Marseille 15",
            "Marseille 16",
            "Marseille Bompard",
            "Marseille Endoume",
            "Marseille Prefecture",
            "Marseille Roucas-Blanc",
            "Marseille Saint-Victor",
            "Marseille Vauban",
            "Marsillargues",
            "Marsilly",
            "Marssac-sur-Tarn",
            "Martel",
            "Marthod",
            "Martignas-sur-Jalle",
            "Martignat",
            "Martigné-Briand",
            "Martigné-Ferchaud",
            "Martigné-sur-Mayenne",
            "Martigues",
            "Martillac",
            "Martin-Église",
            "Martinvast",
            "Martizay",
            "Martres-Tolosane",
            "Marvejols",
            "Mary-sur-Marne",
            "Marzan",
            "Marzy",
            "Masevaux",
            "Masnières",
            "Masny",
            "Massay",
            "Masseube",
            "Massiac",
            "Massieux",
            "Massongy",
            "Massy",
            "Matha",
            "Mathay",
            "Mathieu",
            "Matignon",
            "Matour",
            "Matzenheim",
            "Maubec",
            "Maubec",
            "Maubeuge",
            "Maubourguet",
            "Mauguio",
            "Maule",
            "Mauléon-Licharre",
            "Maulévrier",
            "Maure-de-Bretagne",
            "Maurecourt",
            "Maureilhan",
            "Maureillas-las-Illas",
            "Maurepas",
            "Mauriac",
            "Mauron",
            "Maurs",
            "Maussane-les-Alpilles",
            "Mauves",
            "Mauves-sur-Loire",
            "Mauvezin",
            "Mauzé-sur-le-Mignon",
            "Mauzé-Thouarsais",
            "Maxent",
            "Maxéville",
            "Maxilly-sur-Léman",
            "May-sur-Orne",
            "Mayenne",
            "Mayet",
            "Mazamet",
            "Mazan",
            "Mazargues",
            "Mazé",
            "Mazères",
            "Mazères-Lezons",
            "Mazet-Saint-Voy",
            "Mazières-de-Touraine",
            "Mazières-en-Mauges",
            "Mazingarbe",
            "Méaudre",
            "Méaulte",
            "Meaux",
            "Mécleuves",
            "Médan",
            "Médis",
            "Médréac",
            "Mées",
            "Megève",
            "Mehun-sur-Yèvre",
            "Meilhan",
            "Meilhan-sur-Garonne",
            "Meillac",
            "Meillonnas",
            "Meistratzheim",
            "Melay",
            "Melesse",
            "Melgven",
            "Mélisey",
            "Mellac",
            "Melle",
            "Mellecey",
            "Melrand",
            "Melun",
            "Mende",
            "Ménéac",
            "Ménerbes",
            "Ménesplet",
            "Ménestreau-en-Villette",
            "Menetou-Salon",
            "Ménétrol",
            "Ménilles",
            "Mennecy",
            "Menneval",
            "Menpenti",
            "Mens",
            "Mensignac",
            "Menthon-Saint-Bernard",
            "Menton",
            "Menucourt",
            "Méounes-lès-Montrieux",
            "Mer",
            "Mercurey",
            "Mercurol-Veaunes",
            "Mercury",
            "Mercus-Garrabet",
            "Mercy-le-Bas",
            "Merdrignac",
            "Méré",
            "Méreau",
            "Méréville",
            "Méréville",
            "Méribel",
            "Méricourt",
            "Mériel",
            "Mérignac",
            "Mérignies",
            "Mérindol",
            "Merlevenez",
            "Merlimont",
            "Mers-les-Bains",
            "Merten",
            "Mertzwiller",
            "Méru",
            "Mervans",
            "Mervent",
            "Merville",
            "Merville",
            "Merville-Franceville-Plage",
            "Merxheim",
            "Méry",
            "Méry-sur-Oise",
            "Méry-sur-Seine",
            "Mésanger",
            "Meschers-sur-Gironde",
            "Meslan",
            "Meslay-du-Maine",
            "Mesnières-en-Bray",
            "Mesquer",
            "Messac",
            "Messei",
            "Messein",
            "Messeix",
            "Messery",
            "Messigny-et-Vantoux",
            "Messimy",
            "Méteren",
            "Mettray",
            "Metz",
            "Metz",
            "Metzeral",
            "Metzervisse",
            "Meucon",
            "Meudon",
            "Meulan-en-Yvelines",
            "Meung-sur-Loire",
            "Meurchin",
            "Meursac",
            "Meursault",
            "Meurthe-et-Moselle",
            "Meuse",
            "Meximieux",
            "Mexy",
            "Meyenheim",
            "Meylan",
            "Meymac",
            "Meynes",
            "Meyrargues",
            "Meyreuil",
            "Meyrueis",
            "Meyssac",
            "Meysse",
            "Meythet",
            "Meyzieu",
            "Mèze",
            "Mezel",
            "Mézeray",
            "Mézériat",
            "Mézières-en-Brenne",
            "Mézières-en-Drouais",
            "Mézières-sur-Seine",
            "Mézin",
            "Méziré",
            "Mézy-sur-Seine",
            "Miélan",
            "Mieussy",
            "Migennes",
            "Mignaloux-Beauvoir",
            "Migné-Auxances",
            "Milhaud",
            "Milizac",
            "Millas",
            "Millau",
            "Millery",
            "Milly-la-Forêt",
            "Milly-sur-Thérain",
            "Mimbaste",
            "Mimet",
            "Mimizan",
            "Miniac-Morvan",
            "Minihy-Tréguier",
            "Mionnay",
            "Mions",
            "Mios",
            "Mirabel-aux-Baronnies",
            "Miramas",
            "Mirambeau",
            "Miramont-de-Guyenne",
            "Mirande",
            "Mirandol-Bourgnounac",
            "Miré",
            "Mirebeau",
            "Mirebeau-sur-Bèze",
            "Mirecourt",
            "Mirefleurs",
            "Miremont",
            "Mirepeix",
            "Mirepoix",
            "Mireval",
            "Miribel",
            "Miribel-les-Échelles",
            "Miserey-Salines",
            "Misérieux",
            "Missillac",
            "Mitry-Mory",
            "Mittelhausbergen",
            "Modane",
            "Moëlan-sur-Mer",
            "Mogneville",
            "Moidieu",
            "Moigny-sur-École",
            "Moirans",
            "Moirans-en-Montagne",
            "Moisdon-la-Rivière",
            "Moisenay",
            "Moislains",
            "Moissac",
            "Moisselles",
            "Moissy-Cramayel",
            "Molac",
            "Molières",
            "Molières-sur-Cèze",
            "Molinet",
            "Mollégès",
            "Molsheim",
            "Mommenheim",
            "Monbazillac",
            "Moncé-en-Belin",
            "Monchecourt",
            "Monchy-Saint-Éloi",
            "Monclar-de-Quercy",
            "Moncontour",
            "Moncoutant",
            "Mondelange",
            "Mondeville",
            "Mondonville",
            "Mondoubleau",
            "Mondragon",
            "Monein",
            "Monestiés",
            "Monéteau",
            "Monflanquin",
            "Monistrol-sur-Loire",
            "Monnaie",
            "Monnetier-Mornex",
            "Monnières",
            "Mons",
            "Mons-en-Barœul",
            "Mons-en-Pévèle",
            "Monségur",
            "Monsempron-Libos",
            "Monswiller",
            "Mont",
            "Mont-Bernanchon",
            "Mont-de-Lans",
            "Mont-de-Marsan",
            "Mont-Dore",
            "Mont-près-Chambord",
            "Mont-Saint-Aignan",
            "Mont-Saint-Éloi",
            "Mont-Saint-Martin",
            "Mont-Saxonnex",
            "Mont-sous-Vaudrey",
            "Montady",
            "Montagnac",
            "Montagnat",
            "Montagne",
            "Montagny",
            "Montaigu",
            "Montaigu-de-Quercy",
            "Montaigut",
            "Montaigut-sur-Save",
            "Montalieu",
            "Montamisé",
            "Montanay",
            "Montans",
            "Montardon",
            "Montaren-et-Saint-Médiers",
            "Montargis",
            "Montarnaud",
            "Montastruc-la-Conseillère",
            "Montataire",
            "Montauban",
            "Montauban-de-Bretagne",
            "Montaure",
            "Montauroux",
            "Montaut",
            "Montauville",
            "Montayral",
            "Montbard",
            "Montbazens",
            "Montbazin",
            "Montbazon",
            "Montbéliard",
            "Montberon",
            "Montbert",
            "Montbeton",
            "Montbizot",
            "Montblanc",
            "Montbonnot-Saint-Martin",
            "Montboucher-sur-Jabron",
            "Montbrison",
            "Montbron",
            "Montbronn",
            "Montcaret",
            "Montceau-les-Mines",
            "Montcenis",
            "Montchanin",
            "Montcornet",
            "Montcourt-Fromonville",
            "Montcresson",
            "Montcuq",
            "Montcy-Notre-Dame",
            "Montdidier",
            "Montebourg",
            "Montech",
            "Monteils",
            "Montéléger",
            "Montélier",
            "Montélimar",
            "Montenay",
            "Montendre",
            "Montenois",
            "Monterblanc",
            "Montereau-Fault-Yonne",
            "Monterfil",
            "Montescot",
            "Montescourt-Lizerolles",
            "Montesquieu-Volvestre",
            "Montesquiu d'Albera",
            "Montesson",
            "Monteux",
            "Montévrain",
            "Montfaucon",
            "Montfaucon",
            "Montfaucon-en-Velay",
            "Montfaucon-Montigné",
            "Montfavet",
            "Montfermeil",
            "Montferrand-le-Château",
            "Montferrat",
            "Montferrat",
            "Montferrier-sur-Lez",
            "Montfort-en-Chalosse",
            "Montfort-le-Gesnois",
            "Montfort-sur-Meu",
            "Montfrin",
            "Montgaillard",
            "Montgermont",
            "Montgeron",
            "Montgiscard",
            "Montgivray",
            "Montguyon",
            "Monthermé",
            "Monthyon",
            "Monticello",
            "Montier-en-Der",
            "Montierchaume",
            "Montignac",
            "Montigné-le-Brillant",
            "Montigny",
            "Montigny-en-Gohelle",
            "Montigny-en-Ostrevent",
            "Montigny-le-Bretonneux",
            "Montigny-Lencoup",
            "Montigny-lès-Cormeilles",
            "Montigny-lès-Metz",
            "Montigny-sur-Loing",
            "Montivilliers",
            "Montjean-sur-Loire",
            "Montjoie-en-Couserans",
            "Montjoire",
            "Montlaur",
            "Montlebon",
            "Montlhéry",
            "Montlieu-la-Garde",
            "Montlignon",
            "Montlivault",
            "Montlouis-sur-Loire",
            "Montluçon",
            "Montluel",
            "Montmacq",
            "Montmagny",
            "Montmain",
            "Montmarault",
            "Montmartin-sur-Mer",
            "Montmédy",
            "Montmélian",
            "Montmerle-sur-Saône",
            "Montmeyran",
            "Montmirail",
            "Montmoreau-Saint-Cybard",
            "Montmorency",
            "Montmorillon",
            "Montmorot",
            "Montoir-de-Bretagne",
            "Montoire-sur-le-Loir",
            "Montois-la-Montagne",
            "Montoison",
            "Montolivet",
            "Montournais",
            "Montpellier",
            "Montpeyroux",
            "Montpezat-de-Quercy",
            "Montpon-Ménestérol",
            "Montpont-en-Bresse",
            "Montrabé",
            "Montréal",
            "Montréal-la-Cluse",
            "Montredon",
            "Montredon-Labessonnié",
            "Montréjeau",
            "Montrem",
            "Montreuil",
            "Montreuil",
            "Montreuil-aux-Lions",
            "Montreuil-Bellay",
            "Montreuil-le-Gast",
            "Montreuil-sous-Pérouse",
            "Montreuil-sur-Ille",
            "Montreux-Château",
            "Montrevault",
            "Montrevel-en-Bresse",
            "Montrichard",
            "Montricoux",
            "Montrodat",
            "Montrond-les-Bains",
            "Montrottier",
            "Montrouge",
            "Montry",
            "Monts",
            "Montségur-sur-Lauzon",
            "Montsoult",
            "Montsûrs",
            "Montussan",
            "Moosch",
            "Morainvilliers",
            "Morancé",
            "Morancez",
            "Morangis",
            "Morannes",
            "Morbecque",
            "Morbier",
            "Morbihan",
            "Morcenx",
            "Mordelles",
            "Moréac",
            "Morée",
            "Morestel",
            "Moret-sur-Loing",
            "Moreuil",
            "Morez",
            "Morhange",
            "Morienval",
            "Morières-lès-Avignon",
            "Morigny-Champigny",
            "Morlaas",
            "Morlaix",
            "Mormant",
            "Mormoiron",
            "Mornac",
            "Mornant",
            "Mornas",
            "Morosaglia",
            "Morre",
            "Morsang-sur-Orge",
            "Morsbach",
            "Morschwiller-le-Bas",
            "Mortagne-au-Perche",
            "Mortagne-du-Nord",
            "Mortagne-sur-Gironde",
            "Mortagne-sur-Sèvre",
            "Mortain",
            "Mortcerf",
            "Morteau",
            "Mortrée",
            "Morvillars",
            "Morzine",
            "Moselle",
            "Mouans-Sartoux",
            "Mouchamps",
            "Mouchard",
            "Mouchin",
            "Mouen",
            "Mougins",
            "Mougon",
            "Mouguerre",
            "Mouilleron-en-Pareds",
            "Mouilleron-le-Captif",
            "Moulay",
            "Mouleydier",
            "Mouliets-et-Villemartin",
            "Moulins",
            "Moulins-Engilbert",
            "Moulins-lès-Metz",
            "Moulis-en-Médoc",
            "Moult",
            "Mourenx",
            "Mouret",
            "Mouriès",
            "Mourmelon-le-Grand",
            "Mouroux",
            "Mours",
            "Mours-Saint-Eusèbe",
            "Moussac",
            "Moussan",
            "Moussy-le-Neuf",
            "Moussy-le-Vieux",
            "Moustoir-Ac",
            "Mouthiers-sur-Boëme",
            "Moutiers",
            "Moûtiers",
            "Moutiers-les-Mauxfaits",
            "Mouvaux",
            "Mouxy",
            "Mouy",
            "Mouzeil",
            "Mouzillon",
            "Mouzon",
            "Moyaux",
            "Moye",
            "Moyenmoutier",
            "Moyeuvre-Grande",
            "Moyrazès",
            "Mozac",
            "Mozé-sur-Louet",
            "Mudaison",
            "Mugron",
            "Muides-sur-Loire",
            "Muizon",
            "Mulhouse",
            "Mulsanne",
            "Munchhouse",
            "Mundolsheim",
            "Munster",
            "Mûr-de-Bretagne",
            "Mur-de-Sologne",
            "Murat",
            "Muret",
            "Muron",
            "Mûrs-Erigné",
            "Murviel-lès-Béziers",
            "Murviel-lès-Montpellier",
            "Mus",
            "Mussidan",
            "Mussig",
            "Mussy-sur-Seine",
            "Muttersholtz",
            "Mutzig",
            "Muzillac",
            "Nages-et-Solorgues",
            "Nailloux",
            "Naintré",
            "Naizin",
            "Nalliers",
            "Nancray",
            "Nancy",
            "Nandy",
            "Nangis",
            "Nans-les-Pins",
            "Nanterre",
            "Nantes",
            "Nanteuil",
            "Nanteuil-en-Vallée",
            "Nanteuil-le-Haudouin",
            "Nanteuil-lès-Meaux",
            "Nantiat",
            "Nantua",
            "Naours",
            "Narbonne",
            "Nargis",
            "Narrosse",
            "Nassandres",
            "Naucelle",
            "Naucelles",
            "Navailles-Angos",
            "Navarrenx",
            "Naveil",
            "Navenne",
            "Naves",
            "Nay",
            "Nazelles-Négron",
            "Neaufles-Saint-Martin",
            "Neauphle-le-Château",
            "Nébian",
            "Nègrepelisse",
            "Nemours",
            "Néoules",
            "Nérac",
            "Nercillac",
            "Néris-les-Bains",
            "Nérondes",
            "Nersac",
            "Nesle",
            "Nesles",
            "Nesles-la-Montagne",
            "Nesles-la-Vallée",
            "Nesmy",
            "Neuf-Berquin",
            "Neuf-Brisach",
            "Neufchâteau",
            "Neufchâtel-en-Bray",
            "Neufchâtel-Hardelot",
            "Neufchef",
            "Neufgrange",
            "Neufmanil",
            "Neufmoutiers-en-Brie",
            "Neuillé-Pont-Pierre",
            "Neuilly-en-Thelle",
            "Neuilly-le-Réal",
            "Neuilly-lès-Dijon",
            "Neuilly-Plaisance",
            "Neuilly-Saint-Front",
            "Neuilly-sous-Clermont",
            "Neuilly-sur-Marne",
            "Neuilly-sur-Seine",
            "Neulise",
            "Neulliac",
            "Neung-sur-Beuvron",
            "Neussargues",
            "Neuvecelle",
            "Neuvéglise",
            "Neuves-Maisons",
            "Neuvic",
            "Neuvic-Entier",
            "Neuville-aux-Bois",
            "Neuville-de-Poitou",
            "Neuville-en-Ferrain",
            "Neuville-les-Dames",
            "Neuville-Saint-Rémy",
            "Neuville-Saint-Vaast",
            "Neuville-sur-Ain",
            "Neuville-sur-Escaut",
            "Neuville-sur-Oise",
            "Neuville-sur-Saône",
            "Neuville-sur-Sarthe",
            "Neuvy",
            "Neuvy-le-Roi",
            "Neuvy-Pailloux",
            "Neuvy-Saint-Sépulchre",
            "Neuvy-Sautour",
            "Neuvy-sur-Barangeon",
            "Neuvy-sur-Loire",
            "Neuwiller-lès-Saverne",
            "Nevers",
            "Névez",
            "Névian",
            "Néville",
            "Nevoy",
            "Nexon",
            "Neydens",
            "Nice",
            "Niderviller",
            "Niederbronn-les-Bains",
            "Niederhaslach",
            "Niederhausbergen",
            "Niedernai",
            "Niederschaeffolsheim",
            "Nieppe",
            "Nieul",
            "Nieul-le-Dolent",
            "Nieul-lès-Saintes",
            "Nieul-sur-Mer",
            "Niévroz",
            "Niherne",
            "Nilvange",
            "Nîmes",
            "Niort",
            "Nissan-lez-Enserune",
            "Nivillac",
            "Nivolas-Vermelle",
            "Noaillan",
            "Noailles",
            "Noailles",
            "Noé",
            "Nœux-les-Mines",
            "Nogaro",
            "Nogent-l’Artaud",
            "Nogent-le-Bas",
            "Nogent-le-Phaye",
            "Nogent-le-Roi",
            "Nogent-le-Rotrou",
            "Nogent-sur-Marne",
            "Nogent-sur-Oise",
            "Nogent-sur-Seine",
            "Nogent-sur-Vernisson",
            "Nogentel",
            "Nohanent",
            "Nohic",
            "Noidans-lès-Vesoul",
            "Nointot",
            "Noirétable",
            "Noirmoutier-en-l’Île",
            "Noiseau",
            "Noisiel",
            "Noisy-le-Grand",
            "Noisy-le-Roi",
            "Noisy-le-Sec",
            "Noisy-sur-École",
            "Noizay",
            "Nolay",
            "Nomain",
            "Nomeny",
            "Nomexy",
            "Nommay",
            "Nonancourt",
            "Nontron",
            "Nord",
            "Nordhouse",
            "Normanville",
            "Norrent-Fontes",
            "Norroy-lès-Pont-à-Mousson",
            "Nort-sur-Erdre",
            "Nortkerque",
            "Nostang",
            "Notre-Dame du Mont",
            "Notre-Dame Limite",
            "Notre-Dame-de-Bondeville",
            "Notre-Dame-de-Gravenchon",
            "Notre-Dame-de-Mésage",
            "Notre-Dame-de-Monts",
            "Notre-Dame-de-Riez",
            "Notre-Dame-de-Sanilhac",
            "Notre-Dame-des-Landes",
            "Nouaillé-Maupertuis",
            "Nouan-le-Fuzelier",
            "Nousty",
            "Nouvion",
            "Nouvion-sur-Meuse",
            "Nouvoitou",
            "Nouzilly",
            "Nouzonville",
            "Novalaise",
            "Novéant-sur-Moselle",
            "Noves",
            "Novillars",
            "Noyal-Muzillac",
            "Noyal-Pontivy",
            "Noyal-sur-Vilaine",
            "Noyant",
            "Noyant-la-Gravoyère",
            "Noyarey",
            "Noyelles-Godault",
            "Noyelles-lès-Vermelles",
            "Noyelles-sous-Lens",
            "Noyen-sur-Sarthe",
            "Noyers-sur-Cher",
            "Noyon",
            "Nozay",
            "Nozay",
            "Nuaillé",
            "Nueil-les-Aubiers",
            "Nueil-sur-Layon",
            "Nuillé-sur-Vicoin",
            "Nuits-Saint-Georges",
            "Nyoiseau",
            "Nyons",
            "Obenheim",
            "Oberbronn",
            "Oberhaslach",
            "Oberhausbergen",
            "Oberhergheim",
            "Oberhoffen-sur-Moder",
            "Obermodern-Zutzendorf",
            "Obernai",
            "Oberschaeffolsheim",
            "Objat",
            "Octeville",
            "Octeville-sur-Mer",
            "Oderen",
            "Odos",
            "Oermingen",
            "Œting",
            "Oeyreluy",
            "Offemont",
            "Offenheim",
            "Offranville",
            "Ogeu-les-Bains",
            "Ognes",
            "Ohlungen",
            "Oignies",
            "Oinville-sur-Montcient",
            "Oisemont",
            "Oisseau",
            "Oissel",
            "Oissery",
            "Oisy-le-Verger",
            "Olemps",
            "Oletta",
            "Olivet",
            "Ollainville",
            "Ollioules",
            "Olmeto",
            "Olonne-sur-Mer",
            "Olonzac",
            "Oloron-Sainte-Marie",
            "Omissy",
            "Ondes",
            "Ondres",
            "Onesse-Laharie",
            "Onnaing",
            "Ons-en-Bray",
            "Onzain",
            "Opéra",
            "Opio",
            "Oppède le Vieux",
            "Oradour-sur-Glane",
            "Oradour-sur-Vayres",
            "Oraison",
            "Orange",
            "Orbec",
            "Orbey",
            "Orcet",
            "Orchamps",
            "Orchamps-Vennes",
            "Orchies",
            "Orcines",
            "Orgelet",
            "Orgères",
            "Orgères-en-Beauce",
            "Orgerus",
            "Orgeval",
            "Orgon",
            "Orgueil",
            "Origny-en-Thiérache",
            "Origny-Sainte-Benoite",
            "Orival",
            "Orléans",
            "Orléat",
            "Orleix",
            "Orliénas",
            "Orly",
            "Ormes",
            "Ormesson-sur-Marne",
            "Ormoy",
            "Ornaisons",
            "Ornans",
            "Ornex",
            "Orry-la-Ville",
            "Orsan",
            "Orsay",
            "Ortaffa",
            "Orthez",
            "Orval",
            "Orvault",
            "Osny",
            "Ossé",
            "Osséja",
            "Ossun",
            "Ostheim",
            "Ostricourt",
            "Ostwald",
            "Othis",
            "Ottange",
            "Otterswiller",
            "Ottmarsheim",
            "Ottrott",
            "Ouches",
            "Oucques",
            "Oudon",
            "Ouges",
            "Ouistreham",
            "Oulins",
            "Oullins",
            "Ouroux-sur-Saône",
            "Oursbelille",
            "Ourville-en-Caux",
            "Ousse",
            "Outarville",
            "Outreau",
            "Ouveillan",
            "Ouzouer-le-Marché",
            "Ouzouer-sur-Loire",
            "Ouzouer-sur-Trézée",
            "Oye-Plage",
            "Oyonnax",
            "Oytier-Saint-Oblas",
            "Ozoir-la-Ferrière",
            "Ozouer-le-Voulgis",
            "Pabu",
            "Pacé",
            "Pacy-sur-Eure",
            "Pagny-sur-Moselle",
            "Paillet",
            "Paimboeuf",
            "Paimpol",
            "Paimpont",
            "Palais de Justice",
            "Palaiseau",
            "Palaja",
            "Palama",
            "Palau-del-Vidre",
            "Palavas-les-Flots",
            "Palinges",
            "Pamiers",
            "Pamproux",
            "Panazol",
            "Pancé",
            "Panissières",
            "Pannecé",
            "Pannes",
            "Pantin",
            "Paradou",
            "Paray-le-Monial",
            "Paray-Vieille-Poste",
            "Parçay-les-Pins",
            "Parçay-Meslay",
            "Parcé-sur-Sarthe",
            "Parempuyre",
            "Parentis-en-Born",
            "Pargny-sur-Saulx",
            "Parigné",
            "Parigné-le-Pôlin",
            "Parigny",
            "Paris",
            "Parmain",
            "Paron",
            "Parthenay",
            "Pas-de-Calais",
            "Paslières",
            "Pasly",
            "Passy",
            "Patay",
            "Pau",
            "Pauillac",
            "Paulhac",
            "Paulhaguet",
            "Paulhan",
            "Paulx",
            "Pavie",
            "Pavilly",
            "Payns",
            "Payrin-Augmontel",
            "Payzac",
            "Peaugres",
            "Péaule",
            "Péchabou",
            "Pechbonnieu",
            "Pecquencourt",
            "Pédernec",
            "Pégomas",
            "Peillac",
            "Peille",
            "Peillon",
            "Peillonnex",
            "Peipin",
            "Pélissanne",
            "Pellegrue",
            "Pellouailles-les-Vignes",
            "Pelousey",
            "Peltre",
            "Pélussin",
            "Pencran",
            "Pendé",
            "Pénestin",
            "Pennautier",
            "Penta-di-Casinca",
            "Penvénan",
            "Pépieux",
            "Percy",
            "Pérenchies",
            "Peri",
            "Périer",
            "Périers",
            "Pérignac",
            "Pérignat-lès-Sarliève",
            "Pérignat-sur-Allier",
            "Périgneux",
            "Périgny",
            "Périgny",
            "Périgueux",
            "Pernes",
            "Pernes-les-Fontaines",
            "Pérols",
            "Péron",
            "Péronnas",
            "Péronne",
            "Pérouges",
            "Péroy-les-Gombries",
            "Perpignan",
            "Perrecy-les-Forges",
            "Perreux",
            "Perriers-sur-Andelle",
            "Perrignier",
            "Perrigny",
            "Perrigny-lès-Dijon",
            "Perros-Guirec",
            "Perrusson",
            "Pers-Jussy",
            "Persan",
            "Perthes",
            "Pertuis",
            "Peschadoires",
            "Pesmes",
            "Pessac",
            "Petit-Couronne",
            "Petit-Mars",
            "Petit-Noir",
            "Petit-Réderching",
            "Petite-Forêt",
            "Petite-Rosselle",
            "Petiville",
            "Peujard",
            "Peymeinade",
            "Peynier",
            "Peypin",
            "Peyrat-de-Bellac",
            "Peyrat-le-Château",
            "Peyrehorade",
            "Peyrestortes",
            "Peyriac-de-Mer",
            "Peyriac-Minervois",
            "Peyrilhac",
            "Peyrins",
            "Peyrolles-en-Provence",
            "Peyruis",
            "Pézenas",
            "Pezens",
            "Pézilla-la-Rivière",
            "Pfaffenheim",
            "Pfaffenhoffen",
            "Pfastatt",
            "Pfetterhouse",
            "Pfulgriesheim",
            "Phalempin",
            "Phalsbourg",
            "Pia",
            "Pibrac",
            "Picauville",
            "Picquigny",
            "Piégut-Pluviers",
            "Piennes",
            "Pierre-Bénite",
            "Pierre-Buffière",
            "Pierre-Châtel",
            "Pierre-de-Bresse",
            "Pierrefeu-du-Var",
            "Pierrefitte-Nestalas",
            "Pierrefitte-sur-Seine",
            "Pierrefonds",
            "Pierrefontaine-les-Varans",
            "Pierrefort",
            "Pierrelatte",
            "Pierrelaye",
            "Pierrepont",
            "Pierres",
            "Pierrevert",
            "Pierrevillers",
            "Pierry",
            "Pietranera",
            "Pietrosella",
            "Pignan",
            "Pignans",
            "Pinet",
            "Piney",
            "Pinon",
            "Pinsaguel",
            "Piolenc",
            "Pionsat",
            "Pipriac",
            "Piré-sur-Seiche",
            "Pirey",
            "Piriac-sur-Mer",
            "Pirou",
            "Pissos",
            "Pissotte",
            "Pissy-Pôville",
            "Pithiviers",
            "Pithiviers-le-Vieil",
            "Pîtres",
            "Plabennec",
            "Plailly",
            "Plaimpied-Givaudins",
            "Plaine-Haute",
            "Plainfaing",
            "Plaintel",
            "Plaisance",
            "Plaisance-du-Touch",
            "Plaisir",
            "Plan-d'Aups-Sainte-Baume",
            "Plan-de-Cuques",
            "Plancher-Bas",
            "Plancher-les-Mines",
            "Plancoët",
            "Planguenoual",
            "Plappeville",
            "Plaudren",
            "Plauzat",
            "Pleaux",
            "Pléchâtel",
            "Plédéliac",
            "Plédran",
            "Pléguien",
            "Pléhédel",
            "Pleine-Fougères",
            "Plélan-le-Grand",
            "Plélan-le-Petit",
            "Plélo",
            "Plémet",
            "Plémy",
            "Plénée-Jugon",
            "Pléneuf-Val-André",
            "Plerguer",
            "Plérin",
            "Plescop",
            "Pleslin-Trigavou",
            "Plessala",
            "Plessé",
            "Plestan",
            "Plestin-les-Grèves",
            "Pleubian",
            "Pleucadeuc",
            "Pleudaniel",
            "Pleudihen-sur-Rance",
            "Pleugriffet",
            "Pleugueneuc",
            "Pleumartin",
            "Pleumeleuc",
            "Pleumeur-Bodou",
            "Pleumeur-Gautier",
            "Pleurtuit",
            "Pleuven",
            "Pleyben",
            "Pleyber-Christ",
            "Plobannalec-Lesconil",
            "Plobsheim",
            "Ploemel",
            "Ploemeur",
            "Ploërdut",
            "Ploeren",
            "Ploërmel",
            "Plœuc-sur-Lié",
            "Ploëzal",
            "Plogastel-Saint-Germain",
            "Plogoff",
            "Plogonnec",
            "Plombières-les-Bains",
            "Plombières-lès-Dijon",
            "Plomelin",
            "Plomeur",
            "Plomodiern",
            "Plonéis",
            "Plonéour-Lanvern",
            "Plonévez-du-Faou",
            "Plonévez-Porzay",
            "Plouagat",
            "Plouaret",
            "Plouarzel",
            "Plouasne",
            "Plouay",
            "Ploubalay",
            "Ploubazlanec",
            "Ploubezre",
            "Ploudalmézeau",
            "Ploudaniel",
            "Plouëc-du-Trieux",
            "Plouédern",
            "Plouénan",
            "Plouër-sur-Rance",
            "Plouescat",
            "Plouézec",
            "Plouezoc'h",
            "Ploufragan",
            "Plougasnou",
            "Plougastel-Daoulas",
            "Plougonvelin",
            "Plougonven",
            "Plougoulm",
            "Plougoumelen",
            "Plougourvest",
            "Plougrescant",
            "Plouguenast",
            "Plouguerneau",
            "Plouguernével",
            "Plouguiel",
            "Plouguin",
            "Plouha",
            "Plouharnel",
            "Plouhinec",
            "Plouider",
            "Plouigneau",
            "Plouisy",
            "Ploumagoar",
            "Ploumilliau",
            "Ploumoguer",
            "Plounéour-Ménez",
            "Plounéour-Trez",
            "Plounéventer",
            "Plounévez-Lochrist",
            "Plounévez-Moëdec",
            "Plounévez-Quintin",
            "Plounévézel",
            "Plouray",
            "Plourin-lès-Morlaix",
            "Plourivo",
            "Plouvien",
            "Plouvorn",
            "Plouzané",
            "Plouzévédé",
            "Plozévet",
            "Pluduno",
            "Pluguffan",
            "Pluherlin",
            "Plumaugat",
            "Plumelec",
            "Pluméliau",
            "Plumelin",
            "Plumergat",
            "Plumieux",
            "Pluneret",
            "Plurien",
            "Pluvigner",
            "Pluzunet",
            "Pocé-les-Bois",
            "Pocé-sur-Cisse",
            "Podensac",
            "Poey-de-Lescar",
            "Poilly-lez-Gien",
            "Poisat",
            "Poissy",
            "Poisy",
            "Poitiers",
            "Poix-de-Picardie",
            "Poix-du-Nord",
            "Polignac",
            "Poligny",
            "Pollestres",
            "Polliat",
            "Pollionnay",
            "Polminhac",
            "Pomarez",
            "Pomérols",
            "Pommeret",
            "Pommerit-Jaudy",
            "Pommerit-le-Vicomte",
            "Pommeuse",
            "Pommiers",
            "Pompaire",
            "Pompertuzat",
            "Pompey",
            "Pompignac",
            "Pompignan",
            "Pomponne",
            "Ponchon",
            "Poncin",
            "Pons",
            "Pont de Vivaux",
            "Pont-à-Marcq",
            "Pont-à-Mousson",
            "Pont-à-Vendin",
            "Pont-Audemer",
            "Pont-Aven",
            "Pont-Croix",
            "Pont-d'Ouilly",
            "Pont-d’Ain",
            "Pont-de-Chéruy",
            "Pont-de-Larn",
            "Pont-de-Metz",
            "Pont-de-Roide",
            "Pont-de-Salars",
            "Pont-de-Vaux",
            "Pont-de-Veyle",
            "Pont-du-Casse",
            "Pont-du-Château",
            "Pont-Écrepin",
            "Pont-en-Royans",
            "Pont-Évêque",
            "Pont-Hébert",
            "Pont-l’Abbé",
            "Pont-l’Abbé-d’Arnoult",
            "Pont-l’Évêque",
            "Pont-Remy",
            "Pont-Saint-Esprit",
            "Pont-Saint-Martin",
            "Pont-Saint-Pierre",
            "Pont-Saint-Vincent",
            "Pont-Sainte-Marie",
            "Pont-Sainte-Maxence",
            "Pont-Salomon",
            "Pont-Scorff",
            "Pont-sur-Sambre",
            "Pont-sur-Yonne",
            "Pontacq",
            "Pontailler-sur-Saône",
            "Pontarlier",
            "Pontault-Combault",
            "Pontcarré",
            "Pontcharra",
            "Pontcharra-sur-Turdine",
            "Pontchâteau",
            "Ponteilla",
            "Pontenx-les-Forges",
            "Pontfaverger-Moronvilliers",
            "Ponthierry",
            "Pontivy",
            "Pontlevoy",
            "Pontoise",
            "Pontonx-sur-l'Adour",
            "Pontorson",
            "Pontpoint",
            "Pontrieux",
            "Pontvallain",
            "Porcelette",
            "Porcheville",
            "Porcieu",
            "Pordic",
            "Pornic",
            "Pornichet",
            "Porspoder",
            "Port à Binson",
            "Port-Brillet",
            "Port-de-Bouc",
            "Port-des-Barques",
            "Port-en-Bessin-Huppain",
            "Port-la-Nouvelle",
            "Port-Louis",
            "Port-Saint-Louis-du-Rhône",
            "Port-Saint-Père",
            "Port-Sainte-Foy-et-Ponchapt",
            "Port-Sainte-Marie",
            "Port-sur-Saône",
            "Portbail",
            "Portel-des-Corbières",
            "Portes-lès-Valence",
            "Portet-sur-Garonne",
            "Portets",
            "Portieux",
            "Portiragnes",
            "Porto-Vecchio",
            "Portvendres",
            "Poses",
            "Potigny",
            "Pouancé",
            "Pougues-les-Eaux",
            "Pouilley-les-Vignes",
            "Pouillon",
            "Pouilly-en-Auxois",
            "Pouilly-lès-Feurs",
            "Pouilly-les-Nonains",
            "Pouilly-sous-Charlieu",
            "Pouilly-sur-Loire",
            "Poulainville",
            "Pouldergat",
            "Pouldreuzic",
            "Pouligny-Saint-Pierre",
            "Poullan-sur-Mer",
            "Poullaouen",
            "Poulx",
            "Pourrain",
            "Pourrières",
            "Pouru-Saint-Remy",
            "Poussan",
            "Pouxeux",
            "Pouzac",
            "Pouzauges",
            "Prades",
            "Prades-le-Lez",
            "Pradines",
            "Pragoulin",
            "Prahecq",
            "Prat",
            "Prats de Molló",
            "Prayssac",
            "Praz-sur-Arly",
            "Pré-en-Pail",
            "Préaux",
            "Préchac",
            "Précieux",
            "Précigné",
            "Précy-sur-Oise",
            "Préfailles",
            "Preignac",
            "Preignan",
            "Prémery",
            "Prémesques",
            "Prémilhat",
            "Préseau",
            "Presles-en-Brie",
            "Preuilly-sur-Claise",
            "Priay",
            "Prigonrieux",
            "Pringy",
            "Pringy",
            "Prinquiau",
            "Prissé",
            "Privas",
            "Prix-lès-Mézières",
            "Priziac",
            "Propriano",
            "Prouvy",
            "Proville",
            "Provin",
            "Provins",
            "Pruillé-le-Chétif",
            "Prunelli-di-Fiumorbo",
            "Pruniers-en-Sologne",
            "Publier",
            "Puget-sur-Argens",
            "Puget-Théniers",
            "Puget-Ville",
            "Pugnac",
            "Puicheric",
            "Puilboreau",
            "Puiseaux",
            "Puiseux-en-France",
            "Puissalicon",
            "Puisserguier",
            "Pujaudran",
            "Pujaut",
            "Pujols",
            "Pulligny",
            "Pulnoy",
            "Pulversheim",
            "Pusey",
            "Pusignan",
            "Pussay",
            "Putanges-Pont-Écrepin",
            "Puteaux",
            "Puy-Guillaume",
            "Puy-l’Évêque",
            "Puygouzon",
            "Puylaurens",
            "Puyloubier",
            "Puymoyen",
            "Puyoô",
            "Quaëdypre",
            "Quarante",
            "Quarouble",
            "Québriac",
            "Quédillac",
            "Quelaines-Saint-Gault",
            "Quéménéven",
            "Quemper-Guézennec",
            "Quend",
            "Querqueville",
            "Querrien",
            "Quesnoy-sur-Deûle",
            "Quessoy",
            "Questembert",
            "Quetigny",
            "Quettehou",
            "Quettreville-sur-Sienne",
            "Quevauvillers",
            "Quéven",
            "Quévert",
            "Quiberon",
            "Quiers-sur-Bézonde",
            "Quiévrechain",
            "Quiévy",
            "Quillan",
            "Quillebeuf-sur-Seine",
            "Quilly",
            "Quimper",
            "Quimperlé",
            "Quincampoix",
            "Quincey",
            "Quincié-en-Beaujolais",
            "Quincieux",
            "Quincy-sous-Sénart",
            "Quincy-Voisins",
            "Quingey",
            "Quinsac",
            "Quinssaines",
            "Quintenas",
            "Quintin",
            "Quissac",
            "Quistinic",
            "Rabastens",
            "Rabastens-de-Bigorre",
            "Râches",
            "Racquinghem",
            "Radinghem-en-Weppes",
            "Radon",
            "Raedersheim",
            "Rai",
            "Raillencourt-Sainte-Olle",
            "Raimbeaucourt",
            "Raismes",
            "Ramatuelle",
            "Rambervillers",
            "Rambouillet",
            "Ramonchamp",
            "Ramonville-Saint-Agne",
            "Ranchicourt",
            "Randan",
            "Rang-du-Fliers",
            "Rannée",
            "Rantigny",
            "Ranville",
            "Raon-aux-Bois",
            "Rauzan",
            "Ravenel",
            "Ravières",
            "Razac-sur-l’Isle",
            "Razès",
            "Réalmont",
            "Réalville",
            "Rebais",
            "Rebréchien",
            "Recquignies",
            "Rédené",
            "Redessan",
            "Réding",
            "Redon",
            "Régny",
            "Réguiny",
            "Réguisheim",
            "Régusse",
            "Réhon",
            "Reichshoffen",
            "Reichstett",
            "Reignac",
            "Reignac-sur-Indre",
            "Reignier-Ésery",
            "Reillanne",
            "Reims",
            "Reiningue",
            "Rémalard",
            "Rémelfing",
            "Rémilly",
            "Remiremont",
            "Remouillé",
            "Remoulins",
            "Remy",
            "Renage",
            "Renaison",
            "Renazé",
            "Renescure",
            "Rennes",
            "Renwez",
            "Replonges",
            "Requeil",
            "Réquista",
            "Ressons-sur-Matz",
            "Restigné",
            "Restinclières",
            "Rethel",
            "Retiers",
            "Retonfey",
            "Retournac",
            "Rety",
            "Reugny",
            "Reuilly",
            "Revel",
            "Revel",
            "Reventin-Vaugris",
            "Revigny-sur-Ornain",
            "Réville",
            "Revin",
            "Rexpoëde",
            "Reynès",
            "Reyrieux",
            "Rezé",
            "Rhinau",
            "Ria-Sirach",
            "Riaillé",
            "Rians",
            "Rians",
            "Riantec",
            "Ribaute-les-Tavernes",
            "Ribeauvillé",
            "Ribemont",
            "Ribérac",
            "Richardménil",
            "Richebourg",
            "Richebourg-l'Avoué",
            "Richelieu",
            "Richemont",
            "Richwiller",
            "Riec-sur-Belon",
            "Riedisheim",
            "Rieulay",
            "Rieumes",
            "Rieupeyroux",
            "Rieux",
            "Rieux",
            "Rieux-en-Cambrésis",
            "Rieux-Minervois",
            "Rieux-Volvestre",
            "Riez",
            "Rignac",
            "Rilhac-Rancon",
            "Rillieux-la-Pape",
            "Rilly-la-Montagne",
            "Rimogne",
            "Rinxent",
            "Riom",
            "Riom-ès-Montagnes",
            "Rion-des-Landes",
            "Rions",
            "Riorges",
            "Riotord",
            "Rioz",
            "Riquewihr",
            "Ris-Orangis",
            "Riscle",
            "Rive-de-Gier",
            "Rivedoux-Plage",
            "Rivery",
            "Rives",
            "Rivesaltes",
            "Rivière",
            "Rivière-Saas-et-Gourby",
            "Rivières",
            "Rixheim",
            "Roanne",
            "Robecq",
            "Robion",
            "Rocbaron",
            "Roche",
            "Roche-la-Molière",
            "Roche-lez-Beaupré",
            "Rochechouart",
            "Rochecorbon",
            "Rochefort",
            "Rochefort-du-Gard",
            "Rochefort-sur-Loire",
            "Rochegude",
            "Rochemaure",
            "Roches-Prémarie-Andillé",
            "Rocheservière",
            "Rochetaillée-sur-Saône",
            "Rocquencourt",
            "Rocroi",
            "Rodez",
            "Rodilhan",
            "Roeschwoog",
            "Rœulx",
            "Rœux",
            "Roézé-sur-Sarthe",
            "Rogerville",
            "Rognac",
            "Rognes",
            "Rognonas",
            "Rohan",
            "Rohrbach-lès-Bitche",
            "Roiffieux",
            "Roisel",
            "Roissy-en-Brie",
            "Roissy-en-France",
            "Rolampont",
            "Rolleville",
            "Romagnat",
            "Romagné",
            "Romagnieu",
            "Romagny",
            "Romainville",
            "Romanèche-Thorins",
            "Romans-sur-Isère",
            "Romanswiller",
            "Rombas",
            "Romenay",
            "Romillé",
            "Romilly-sur-Andelle",
            "Romilly-sur-Seine",
            "Romorantin-Lanthenay",
            "Ronchamp",
            "Roncherolles-sur-le-Vivier",
            "Ronchin",
            "Roncq",
            "Roost-Warendin",
            "Roquebillière",
            "Roquebrune-Cap-Martin",
            "Roquebrune-sur-Argens",
            "Roquecourbe",
            "Roquefort",
            "Roquefort-la-Bédoule",
            "Roquemaure",
            "Roquetoire",
            "Roquettes",
            "Roquevaire",
            "Roscanvel",
            "Roscoff",
            "Rosenau",
            "Rosheim",
            "Rosières",
            "Rosières-aux-Salines",
            "Rosières-en-Santerre",
            "Rosières-près-Troyes",
            "Rosny-sous-Bois",
            "Rosny-sur-Seine",
            "Rospez",
            "Rosporden",
            "Rosselange",
            "Rostrenen",
            "Rosult",
            "Rothau",
            "Rots",
            "Rouans",
            "Roubaix",
            "Rouen",
            "Rouet",
            "Rouffach",
            "Rouffiac-Tolosan",
            "Rouffignac-Saint-Cernin-de-Reilhac",
            "Rougé",
            "Rougemont",
            "Rougemont-le-Château",
            "Rougiers",
            "Rouhling",
            "Rouillac",
            "Rouillé",
            "Rouillon",
            "Roujan",
            "Roulans",
            "Roullet-Saint-Estèphe",
            "Roumare",
            "Roumazières-Loubert",
            "Rousies",
            "Roussay",
            "Rousset",
            "Roussillon",
            "Roussillon",
            "Rousson",
            "Routot",
            "Rouvroy",
            "Rouxmesnil-Bouteilles",
            "Rouziers-de-Touraine",
            "Royan",
            "Royat",
            "Roybon",
            "Roye",
            "Roye",
            "Roz-Landrieux",
            "Roz-sur-Couesnon",
            "Rozay-en-Brie",
            "Rozérieulles",
            "Rozier-en-Donzy",
            "Rozoy-sur-Serre",
            "Ruaudin",
            "Rubelles",
            "Rue",
            "Rueil-Malmaison",
            "Ruelisheim",
            "Ruelle-sur-Touvre",
            "Ruffec",
            "Ruffey-lès-Echirey",
            "Ruffiac",
            "Rugles",
            "Ruillé-sur-Loir",
            "Ruitz",
            "Rully",
            "Rumegies",
            "Rumersheim-le-Haut",
            "Rumilly",
            "Rumilly-en-Cambrésis",
            "Ruminghem",
            "Rungis",
            "Ruoms",
            "Rupt-sur-Moselle",
            "Rurange-lès-Thionville",
            "Russ",
            "Russange",
            "Ruy",
            "Saâcy-sur-Marne",
            "Sablé-sur-Sarthe",
            "Sablet",
            "Sablonceaux",
            "Sablons",
            "Sablons",
            "Sabran",
            "Sabres",
            "Saché",
            "Saclas",
            "Saclay",
            "Sacy-le-Grand",
            "Sadirac",
            "Saffré",
            "Sagy",
            "Sagy",
            "Sahurs",
            "Saignon",
            "Sail-sous-Couzan",
            "Sailly-Flibeaucourt",
            "Sailly-Labourse",
            "Sailly-lez-Lannoy",
            "Sailly-sur-la-Lys",
            "Sain-Bel",
            "Sainghin-en-Mélantois",
            "Sainghin-en-Weppes",
            "Sains-du-Nord",
            "Sains-en-Amiénois",
            "Sains-en-Gohelle",
            "Saint-Affrique",
            "Saint-Agathon",
            "Saint-Agnant",
            "Saint-Agnant-de-Versillat",
            "Saint-Agrève",
            "Saint-Aignan",
            "Saint-Aigulin",
            "Saint-Alban",
            "Saint-Alban",
            "Saint-Alban-de-Roche",
            "Saint-Alban-Leysse",
            "Saint-Alban-sur-Limagnole",
            "Saint-Alexandre",
            "Saint-Amand",
            "Saint-Amand-en-Puisaye",
            "Saint-Amand-les-Eaux",
            "Saint-Amand-Longpré",
            "Saint-Amand-Montrond",
            "Saint-Amand-sur-Sèvre",
            "Saint-Amans-Soult",
            "Saint-Amans-Valtoret",
            "Saint-Amant-de-Boixe",
            "Saint-Amant-Tallende",
            "Saint-Amarin",
            "Saint-Ambroix",
            "Saint-Amé",
            "Saint-Amour",
            "Saint-Andéol-le-Château",
            "Saint-Andiol",
            "Saint-André",
            "Saint-André-de-Corcy",
            "Saint-André-de-la-Marche",
            "Saint-André-de-la-Roche",
            "Saint-André-de-Sangonis",
            "Saint-André-de-Seignanx",
            "Saint-André-des-Eaux",
            "Saint-André-le-Gaz",
            "Saint-André-le-Puy",
            "Saint-André-les-Vergers",
            "Saint-André-lez-Lille",
            "Saint-André-sur-Orne",
            "Saint-André-sur-Vieux-Jonc",
            "Saint-André-Treize-Voies",
            "Saint-Antoine",
            "Saint-Antoine-de-Breuilh",
            "Saint-Antoine-du-Rocher",
            "Saint-Antoine-l'Abbaye",
            "Saint-Apollinaire",
            "Saint-Armel",
            "Saint-Arnoult",
            "Saint-Arnoult-en-Yvelines",
            "Saint-Astier",
            "Saint-Aubert",
            "Saint-Aubin",
            "Saint-Aubin-de-Médoc",
            "Saint-Aubin-des-Bois",
            "Saint-Aubin-des-Châteaux",
            "Saint-Aubin-des-Ormeaux",
            "Saint-Aubin-du-Cormier",
            "Saint-Aubin-en-Bray",
            "Saint-Aubin-le-Cloud",
            "Saint-Aubin-lès-Elbeuf",
            "Saint-Aubin-Routot",
            "Saint-Aubin-sur-Gaillon",
            "Saint-Aubin-sur-Mer",
            "Saint-Aubin-sur-Scie",
            "Saint-Augustin",
            "Saint-Aulaye",
            "Saint-Aunès",
            "Saint-Aupre",
            "Saint-Avé",
            "Saint-Avertin",
            "Saint-Avold",
            "Saint-Ay",
            "Saint-Baldoph",
            "Saint-Barnabé",
            "Saint-Barnabé",
            "Saint-Barthélémy",
            "Saint-Barthélemy-de-Vals",
            "Saint-Baudelle",
            "Saint-Bauzille-de-Putois",
            "Saint-Beauzire",
            "Saint-Benin-d’Azy",
            "Saint-Benoît",
            "Saint-Benoît-de-Carmaux",
            "Saint-Benoît-sur-Loire",
            "Saint-Berain-sous-Sanvignes",
            "Saint-Béron",
            "Saint-Berthevin",
            "Saint-Bon-Tarentaise",
            "Saint-Bonnet-de-Mure",
            "Saint-Bonnet-en-Champsaur",
            "Saint-Bonnet-le-Château",
            "Saint-Bonnet-les-Oules",
            "Saint-Bonnet-près-Riom",
            "Saint-Branchs",
            "Saint-Brandan",
            "Saint-Brès",
            "Saint-Brevin-les-Pins",
            "Saint-Briac-sur-Mer",
            "Saint-Brice",
            "Saint-Brice-Courcelles",
            "Saint-Brice-en-Coglès",
            "Saint-Brice-sous-Forêt",
            "Saint-Brice-sur-Vienne",
            "Saint-Brieuc",
            "Saint-Bris-le-Vineux",
            "Saint-Brisson-sur-Loire",
            "Saint-Broladre",
            "Saint-Calais",
            "Saint-Cannat",
            "Saint-Caprais-de-Bordeaux",
            "Saint-Caradec",
            "Saint-Carreuc",
            "Saint-Cassien",
            "Saint-Cast-le-Guildo",
            "Saint-Céré",
            "Saint-Cergues",
            "Saint-Cernin",
            "Saint-Cézaire-sur-Siagne",
            "Saint-Chaffrey",
            "Saint-Chamas",
            "Saint-Chamond",
            "Saint-Chaptes",
            "Saint-Charles",
            "Saint-Chef",
            "Saint-Chéron",
            "Saint-Chinian",
            "Saint-Christo-en-Jarez",
            "Saint-Christol",
            "Saint-Christol-lès-Alès",
            "Saint-Christoly-de-Blaye",
            "Saint-Christophe-du-Bois",
            "Saint-Christophe-du-Ligneron",
            "Saint-Christophe-sur-le-Nais",
            "Saint-Christophe-Vallon",
            "Saint-Ciers-d’Abzac",
            "Saint-Ciers-sur-Gironde",
            "Saint-Clair-de-la-Tour",
            "Saint-Clair-du-Rhône",
            "Saint-Claud",
            "Saint-Claude",
            "Saint-Claude-de-Diray",
            "Saint-Clément",
            "Saint-Clément",
            "Saint-Clément-de-la-Place",
            "Saint-Clément-de-Rivière",
            "Saint-Clément-des-Levées",
            "Saint-Cloud",
            "Saint-Contest",
            "Saint-Cosme-en-Vairais",
            "Saint-Coulomb",
            "Saint-Crépin-Ibouvillers",
            "Saint-Crespin-sur-Moine",
            "Saint-Cyprien",
            "Saint-Cyprien",
            "Saint-Cyprien-Plage",
            "Saint-Cyr",
            "Saint-Cyr-en-Bourg",
            "Saint-Cyr-en-Val",
            "Saint-Cyr-l’École",
            "Saint-Cyr-sous-Dourdan",
            "Saint-Cyr-sur-le-Rhône",
            "Saint-Cyr-sur-Loire",
            "Saint-Cyr-sur-Menthon",
            "Saint-Cyr-sur-Mer",
            "Saint-Cyr-sur-Morin",
            "Saint-Denis",
            "Saint-Denis-d’Oléron",
            "Saint-Denis-de-Cabanne",
            "Saint-Denis-de-Gastines",
            "Saint-Denis-de-Pile",
            "Saint-Denis-en-Bugey",
            "Saint-Denis-en-Val",
            "Saint-Denis-la-Chevasse",
            "Saint-Denis-lès-Bourg",
            "Saint-Denis-les-Ponts",
            "Saint-Denis-sur-Sarthon",
            "Saint-Désir",
            "Saint-Didier",
            "Saint-Didier",
            "Saint-Didier-de-Formans",
            "Saint-Didier-de-la-Tour",
            "Saint-Didier-en-Velay",
            "Saint-Didier-sous-Riverie",
            "Saint-Didier-sur-Chalaronne",
            "Saint-Dié-des-Vosges",
            "Saint-Dizier",
            "Saint-Dolay",
            "Saint-Domineuc",
            "Saint-Donan",
            "Saint-Doulchard",
            "Saint-Drézéry",
            "Saint-Dyé-sur-Loire",
            "Saint-Égrève",
            "Saint-Éloi",
            "Saint-Éloy-de-Gy",
            "Saint-Éloy-les-Mines",
            "Saint-Émilion",
            "Saint-Épain",
            "Saint-Erblon",
            "Saint-Erme-Outre-et-Ramecourt",
            "Saint-Estèphe",
            "Saint-Estève",
            "Saint-Étienne",
            "Saint-Étienne-au-Mont",
            "Saint-Étienne-de-Baïgorry",
            "Saint-Étienne-de-Chigny",
            "Saint-Étienne-de-Crossey",
            "Saint-Etienne-de-Cuines",
            "Saint-Étienne-de-Fontbellon",
            "Saint-Étienne-de-Mer-Morte",
            "Saint-Étienne-de-Montluc",
            "Saint-Étienne-de-Saint-Geoirs",
            "Saint-Étienne-de-Tinée",
            "Saint-Etienne-de-Tulmont",
            "Saint-Étienne-des-Oullières",
            "Saint-Étienne-du-Bois",
            "Saint-Étienne-du-Bois",
            "Saint-Étienne-du-Grès",
            "Saint-Étienne-du-Rouvray",
            "Saint-Étienne-en-Coglès",
            "Saint-Étienne-lès-Remiremont",
            "Saint-Étienne-sur-Chalaronne",
            "Saint-Eusèbe",
            "Saint-Eustache-la-Forêt",
            "Saint-Évarzec",
            "Saint-Fargeau",
            "Saint-Fargeau-Ponthierry",
            "Saint-Félicien",
            "Saint-Féliu-d’Avall",
            "Saint-Félix",
            "Saint-Félix-Lauragais",
            "Saint-Fiacre-sur-Maine",
            "Saint-Florent",
            "Saint-Florent-des-Bois",
            "Saint-Florent-le-Vieil",
            "Saint-Florent-sur-Auzonnet",
            "Saint-Florent-sur-Cher",
            "Saint-Florentin",
            "Saint-Flour",
            "Saint-Folquin",
            "Saint-Fons",
            "Saint-Forgeux",
            "Saint-Fort",
            "Saint-François",
            "Saint-Fulgent",
            "Saint-Galmier",
            "Saint-Gatien-des-Bois",
            "Saint-Gaudens",
            "Saint-Gaultier",
            "Saint-Gelais",
            "Saint-Gély-du-Fesc",
            "Saint-Gence",
            "Saint-Genès-Champanelle",
            "Saint-Genest-Lerpt",
            "Saint-Genest-Malifaux",
            "Saint-Gengoux-le-National",
            "Saint-Geniès-Bellevue",
            "Saint-Geniès-de-Comolas",
            "Saint-Geniès-de-Fontedit",
            "Saint-Geniès-de-Malgoirès",
            "Saint-Geniès-des-Mourgues",
            "Saint-Geniez-d’Olt",
            "Saint-Genis-de-Saintonge",
            "Saint-Génis-des-Fontaines",
            "Saint-Genis-Laval",
            "Saint-Genis-les-Ollières",
            "Saint-Genis-Pouilly",
            "Saint-Genix-sur-Guiers",
            "Saint-Geoire-en-Valdaine",
            "Saint-Georges-Buttavent",
            "Saint-Georges-de-Commiers",
            "Saint-Georges-de-Didonne",
            "Saint-Georges-de-Luzençon",
            "Saint-Georges-de-Mons",
            "Saint-Georges-de-Montaigu",
            "Saint-Georges-de-Pointindoux",
            "Saint-Georges-de-Reintembault",
            "Saint-Georges-de-Reneins",
            "Saint-Georges-des-Coteaux",
            "Saint-Georges-des-Groseillers",
            "Saint-Georges-du-Bois",
            "Saint-Georges-du-Bois",
            "Saint-Georges-Haute-Ville",
            "Saint-Georges-lès-Baillargeaux",
            "Saint-Georges-les-Bains",
            "Saint-Georges-sur-Allier",
            "Saint-Georges-sur-Baulche",
            "Saint-Georges-sur-Cher",
            "Saint-Georges-sur-Eure",
            "Saint-Georges-sur-Loire",
            "Saint-Geours-de-Maremne",
            "Saint-Gérand-le-Puy",
            "Saint-Géréon",
            "Saint-Germain",
            "Saint-Germain",
            "Saint-Germain-de-la-Grange",
            "Saint-Germain-de-Lusignan",
            "Saint-Germain-de-Marencennes",
            "Saint-Germain-de-Prinçay",
            "Saint-Germain-des-Fossés",
            "Saint-Germain-des-Prés",
            "Saint-Germain-des-Prés",
            "Saint-Germain-du-Bois",
            "Saint-Germain-du-Corbéis",
            "Saint-Germain-du-Plain",
            "Saint-Germain-du-Puch",
            "Saint-Germain-du-Puy",
            "Saint-Germain-en-Coglès",
            "Saint-Germain-en-Laye",
            "Saint-Germain-la-Blanche-Herbe",
            "Saint-Germain-Laprade",
            "Saint-Germain-Laval",
            "Saint-Germain-Laval",
            "Saint-Germain-Lembron",
            "Saint-Germain-lès-Arpajon",
            "Saint-Germain-les-Belles",
            "Saint-Germain-lès-Corbeil",
            "Saint-Germain-Lespinasse",
            "Saint-Germain-Nuelles",
            "Saint-Germain-sur-Avre",
            "Saint-Germain-sur-Moine",
            "Saint-Germain-sur-Morin",
            "Saint-Germain-Village",
            "Saint-Germer-de-Fly",
            "Saint-Gervais",
            "Saint-Gervais",
            "Saint-Gervais-d’Auvergne",
            "Saint-Gervais-en-Belin",
            "Saint-Gervais-la-Forêt",
            "Saint-Gervais-les-Bains",
            "Saint-Gervais-les-Trois-Clochers",
            "Saint-Gervasy",
            "Saint-Gildas-de-Rhuys",
            "Saint-Gildas-des-Bois",
            "Saint-Gilles",
            "Saint-Gilles",
            "Saint-Gilles-Croix-de-Vie",
            "Saint-Giniez",
            "Saint-Girons",
            "Saint-Gobain",
            "Saint-Gonnery",
            "Saint-Gratien",
            "Saint-Grégoire",
            "Saint-Héand",
            "Saint-Hélen",
            "Saint-Henri",
            "Saint-Herblain",
            "Saint-Herblon",
            "Saint-Hilaire",
            "Saint-Hilaire",
            "Saint-Hilaire-de-Brethmas",
            "Saint-Hilaire-de-Chaléons",
            "Saint-Hilaire-de-Clisson",
            "Saint-Hilaire-de-la-Côte",
            "Saint-Hilaire-de-Loulay",
            "Saint-Hilaire-de-Riez",
            "Saint-Hilaire-de-Talmont",
            "Saint-Hilaire-de-Villefranche",
            "Saint-Hilaire-des-Loges",
            "Saint-Hilaire-du-Harcouët",
            "Saint-Hilaire-du-Rosier",
            "Saint-Hilaire-la-Palud",
            "Saint-Hilaire-lez-Cambrai",
            "Saint-Hilaire-Petitville",
            "Saint-Hilaire-Saint-Mesmin",
            "Saint-Hippolyte",
            "Saint-Hippolyte",
            "Saint-Hippolyte",
            "Saint-Hippolyte",
            "Saint-Hippolyte-du-Fort",
            "Saint-Ismier",
            "Saint-Jacques-de-la-Lande",
            "Saint-Jacques-sur-Darnétal",
            "Saint-Jacut-les-Pins",
            "Saint-James",
            "Saint-Jans-Cappel",
            "Saint-Jean",
            "Saint-Jean",
            "Saint-Jean du Désert",
            "Saint-Jean-Bonnefonds",
            "Saint-Jean-Brévelay",
            "Saint-Jean-Cap-Ferrat",
            "Saint-Jean-d’Aulps",
            "Saint-Jean-d’Illac",
            "Saint-Jean-de-Boiseau",
            "Saint-Jean-de-Bournay",
            "Saint-Jean-de-Braye",
            "Saint-Jean-de-Fos",
            "Saint-Jean-de-Gonville",
            "Saint-Jean-de-la-Ruelle",
            "Saint-Jean-de-Liversay",
            "Saint-Jean-de-Losne",
            "Saint-Jean-de-Luz",
            "Saint-Jean-de-Maurienne",
            "Saint-Jean-de-Moirans",
            "Saint-Jean-de-Monts",
            "Saint-Jean-de-Muzols",
            "Saint-Jean-de-Niost",
            "Saint-Jean-de-Sauves",
            "Saint-Jean-de-Sixt",
            "Saint-Jean-de-Soudain",
            "Saint-Jean-de-Thouars",
            "Saint-Jean-de-Védas",
            "Saint-Jean-des-Baisants",
            "Saint-Jean-des-Champs",
            "Saint-Jean-des-Mauvrets",
            "Saint-Jean-du-Cardonnay",
            "Saint-Jean-du-Falga",
            "Saint-Jean-du-Gard",
            "Saint-Jean-en-Royans",
            "Saint-Jean-la-Poterie",
            "Saint-Jean-le-Blanc",
            "Saint-Jean-le-Vieux",
            "Saint-Jean-les-Deux-Jumeaux",
            "Saint-Jean-Pied-de-Port",
            "Saint-Jean-sur-Couesnon",
            "Saint-Jean-sur-Mayenne",
            "Saint-Jean-sur-Veyle",
            "Saint-Jeannet",
            "Saint-Jeoire",
            "Saint-Jérôme",
            "Saint-Joachim",
            "Saint-Jorioz",
            "Saint-Jory",
            "Saint-Joseph",
            "Saint-Joseph",
            "Saint-Joseph-de-Rivière",
            "Saint-Josse",
            "Saint-Jouan-des-Guérets",
            "Saint-Jouin-Bruneval",
            "Saint-Jouvent",
            "Saint-Juéry",
            "Saint-Julien",
            "Saint-Julien",
            "Saint-Julien",
            "Saint-Julien-Chapteuil",
            "Saint-Julien-de-Concelles",
            "Saint-Julien-de-Peyrolas",
            "Saint-Julien-des-Landes",
            "Saint-Julien-du-Sault",
            "Saint-Julien-en-Born",
            "Saint-Julien-en-Genevois",
            "Saint-Julien-en-Saint-Alban",
            "Saint-Julien-lès-Metz",
            "Saint-Julien-les-Rosiers",
            "Saint-Julien-les-Villas",
            "Saint-Julien-Molin-Molette",
            "Saint-Junien",
            "Saint-Just",
            "Saint-Just",
            "Saint-Just",
            "Saint-Just",
            "Saint-Just-Chaleyssin",
            "Saint-Just-d'Ardèche",
            "Saint-Just-de-Claix",
            "Saint-Just-en-Chaussée",
            "Saint-Just-en-Chevalet",
            "Saint-Just-la-Pendue",
            "Saint-Just-le-Martel",
            "Saint-Just-Luzac",
            "Saint-Just-Malmont",
            "Saint-Just-Saint-Rambert",
            "Saint-Just-Sauvage",
            "Saint-Lambert",
            "Saint-Lambert-du-Lattay",
            "Saint-Lambert-la-Potherie",
            "Saint-Lary-Soulan",
            "Saint-Lattier",
            "Saint-Laurent",
            "Saint-Laurent-Blangy",
            "Saint-Laurent-de-Brévedent",
            "Saint-Laurent-de-Chamousset",
            "Saint-Laurent-de-la-Plaine",
            "Saint-Laurent-de-la-Prée",
            "Saint-Laurent-de-la-Salanque",
            "Saint-Laurent-de-Mure",
            "Saint-Laurent-des-Arbres",
            "Saint-Laurent-des-Autels",
            "Saint-Laurent-du-Pape",
            "Saint-Laurent-du-Pont",
            "Saint-Laurent-du-Var",
            "Saint-Laurent-en-Grandvaux",
            "Saint-Laurent-en-Royans",
            "Saint-Laurent-Nouan",
            "Saint-Laurent-sur-Gorre",
            "Saint-Laurent-sur-Saône",
            "Saint-Laurent-sur-Sèvre",
            "Saint-Lazare",
            "Saint-Léger-de-Montbrun",
            "Saint-Léger-des-Bois",
            "Saint-Léger-des-Vignes",
            "Saint-Léger-du-Bourg-Denis",
            "Saint-Léger-en-Yvelines",
            "Saint-Léger-lès-Domart",
            "Saint-Léger-les-Vignes",
            "Saint-Léger-sous-Cholet",
            "Saint-Léger-sur-Dheune",
            "Saint-Léger-sur-Roanne",
            "Saint-Léon-sur-l’Isle",
            "Saint-Léonard",
            "Saint-Léonard",
            "Saint-Léonard",
            "Saint-Léonard-de-Noblat",
            "Saint-Leu",
            "Saint-Leu-la-Forêt",
            "Saint-Lizier",
            "Saint-Lô",
            "Saint-Loubès",
            "Saint-Louis",
            "Saint-Louis",
            "Saint-Louis-de-Montferrand",
            "Saint-Loup",
            "Saint-Loup-Cammas",
            "Saint-Loup-sur-Semouse",
            "Saint-Lubin-des-Joncherets",
            "Saint-Lumine-de-Clisson",
            "Saint-Lumine-de-Coutais",
            "Saint-Lunaire",
            "Saint-Lupicin",
            "Saint-Lyé",
            "Saint-Lyphard",
            "Saint-Lys",
            "Saint-Macaire",
            "Saint-Macaire-en-Mauges",
            "Saint-Maixant",
            "Saint-Malo",
            "Saint-Malo-de-Guersac",
            "Saint-Malô-du-Bois",
            "Saint-Mamert-du-Gard",
            "Saint-Mamet-la-Salvetat",
            "Saint-Mammès",
            "Saint-Mandé",
            "Saint-Mandrier-sur-Mer",
            "Saint-Manvieu",
            "Saint-Manvieu-Norrey",
            "Saint-Marc-Jaumegarde",
            "Saint-Marc-le-Blanc",
            "Saint-Marcel",
            "Saint-Marcel",
            "Saint-Marcel",
            "Saint-Marcel",
            "Saint-Marcel-d'Ardèche",
            "Saint-Marcel-lès-Annonay",
            "Saint-Marcel-lès-Sauzet",
            "Saint-Marcel-lès-Valence",
            "Saint-Marcel-sur-Aude",
            "Saint-Marcellin",
            "Saint-Marcellin-en-Forez",
            "Saint-Mard",
            "Saint-Mariens",
            "Saint-Mars-d’Outillé",
            "Saint-Mars-de-Coutais",
            "Saint-Mars-du-Désert",
            "Saint-Mars-la-Brière",
            "Saint-Mars-la-Jaille",
            "Saint-Martin-au-Laërt",
            "Saint-Martin-Belle-Roche",
            "Saint-Martin-Bellevue",
            "Saint-Martin-Boulogne",
            "Saint-Martin-d’Auxigny",
            "Saint-Martin-d’Hères",
            "Saint-Martin-de-Belleville",
            "Saint-Martin-de-Boscherville",
            "Saint-Martin-de-Crau",
            "Saint-Martin-de-Fontenay",
            "Saint-Martin-de-Hinx",
            "Saint-Martin-de-la-Place",
            "Saint-Martin-de-Landelles",
            "Saint-Martin-de-Londres",
            "Saint-Martin-de-Nigelles",
            "Saint-Martin-de-Queyrières",
            "Saint-Martin-de-Ré",
            "Saint-Martin-de-Seignanx",
            "Saint-Martin-de-Valamas",
            "Saint-Martin-de-Valgalgues",
            "Saint-Martin-des-Besaces",
            "Saint-Martin-des-Champs",
            "Saint-Martin-des-Champs",
            "Saint-Martin-des-Noyers",
            "Saint-Martin-du-Fouilloux",
            "Saint-Martin-du-Frêne",
            "Saint-Martin-du-Manoir",
            "Saint-Martin-du-Mont",
            "Saint-Martin-du-Tertre",
            "Saint-Martin-du-Tertre",
            "Saint-Martin-du-Var",
            "Saint-Martin-du-Vivier",
            "Saint-Martin-en-Bresse",
            "Saint-Martin-en-Campagne",
            "Saint-Martin-en-Haut",
            "Saint-Martin-la-Plaine",
            "Saint-Martin-Lacaussade",
            "Saint-Martin-Lalande",
            "Saint-Martin-le-Beau",
            "Saint-Martin-le-Vinoux",
            "Saint-Martin-Longueau",
            "Saint-Martin-Osmonville",
            "Saint-Martin-sous-Mouzeuil",
            "Saint-Martin-sur-Ocre",
            "Saint-Martin-sur-Oust",
            "Saint-Martin-Vésubie",
            "Saint-Mathieu",
            "Saint-Mathieu-de-Tréviers",
            "Saint-Mathurin",
            "Saint-Mathurin-sur-Loire",
            "Saint-Maur",
            "Saint-Maur-des-Fossés",
            "Saint-Maurice",
            "Saint-Maurice-de-Beynost",
            "Saint-Maurice-de-Gourdans",
            "Saint-Maurice-de-Lignon",
            "Saint-Maurice-en-Gourgois",
            "Saint-Maurice-la-Clouère",
            "Saint-Maurice-la-Souterraine",
            "Saint-Maurice-Montcouronne",
            "Saint-Maurice-sur-Dargoire",
            "Saint-Maurice-sur-Fessard",
            "Saint-Maurice-sur-Moselle",
            "Saint-Mauront",
            "Saint-Max",
            "Saint-Maximin",
            "Saint-Maximin-la-Sainte-Baume",
            "Saint-Maxire",
            "Saint-Médard-d’Eyrans",
            "Saint-Médard-de-Guizières",
            "Saint-Médard-de-Mussidan",
            "Saint-Médard-en-Jalles",
            "Saint-Médard-sur-Ille",
            "Saint-Méen-le-Grand",
            "Saint-Melaine-sur-Aubance",
            "Saint-Méloir-des-Ondes",
            "Saint-Même-le-Tenu",
            "Saint-Même-les-Carrières",
            "Saint-Memmie",
            "Saint-Menet",
            "Saint-Menges",
            "Saint-Mesmin",
            "Saint-Mexant",
            "Saint-Michel",
            "Saint-Michel",
            "Saint-Michel-Chef-Chef",
            "Saint-Michel-de-Maurienne",
            "Saint-Michel-l’Observatoire",
            "Saint-Michel-le-Cloucq",
            "Saint-Michel-Mont-Mercure",
            "Saint-Michel-sur-Meurthe",
            "Saint-Michel-sur-Orge",
            "Saint-Mihiel",
            "Saint-Mitre",
            "Saint-Mitre-les-Remparts",
            "Saint-Molf",
            "Saint-Morillon",
            "Saint-Nabord",
            "Saint-Nauphary",
            "Saint-Nazaire",
            "Saint-Nazaire",
            "Saint-Nazaire-d’Aude",
            "Saint-Nazaire-les-Eymes",
            "Saint-Nicolas",
            "Saint-Nicolas-d’Aliermont",
            "Saint-Nicolas-de-Bourgueil",
            "Saint-Nicolas-de-la-Grave",
            "Saint-Nicolas-de-la-Taille",
            "Saint-Nicolas-de-Port",
            "Saint-Nicolas-de-Redon",
            "Saint-Nicolas-du-Pélem",
            "Saint-Nizier-sous-Charlieu",
            "Saint-Nolff",
            "Saint-Nom-la-Bretêche",
            "Saint-Omer",
            "Saint-Omer-en-Chaussée",
            "Saint-Orens-de-Gameville",
            "Saint-Ouen",
            "Saint-Ouen",
            "Saint-Ouen",
            "Saint-Ouen-de-Thouberville",
            "Saint-Ouen-des-Alleux",
            "Saint-Ouën-des-Toits",
            "Saint-Ouen-du-Tilleul",
            "Saint-Ouen-en-Belin",
            "Saint-Ouen-l’Aumône",
            "Saint-Ours",
            "Saint-Pabu",
            "Saint-Paër",
            "Saint-Pair-sur-Mer",
            "Saint-Pal-de-Mons",
            "Saint-Palais",
            "Saint-Palais-sur-Mer",
            "Saint-Pantaléon-de-Larche",
            "Saint-Pardoux",
            "Saint-Pardoux-Isaac",
            "Saint-Pardoux-la-Rivière",
            "Saint-Pargoire",
            "Saint-Parize-le-Châtel",
            "Saint-Parres-aux-Tertres",
            "Saint-Paterne",
            "Saint-Paterne-Racan",
            "Saint-Pathus",
            "Saint-Paul",
            "Saint-Paul",
            "Saint-Paul",
            "Saint-Paul-Cap-de-Joux",
            "Saint-Paul-de-Fenouillet",
            "Saint-Paul-de-Jarrat",
            "Saint-Paul-de-Varax",
            "Saint-Paul-de-Varces",
            "Saint-Paul-de-Vence",
            "Saint-Paul-des-Landes",
            "Saint-Paul-en-Chablais",
            "Saint-Paul-en-Forêt",
            "Saint-Paul-en-Jarez",
            "Saint-Paul-en-Pareds",
            "Saint-Paul-lès-Dax",
            "Saint-Paul-lès-Romans",
            "Saint-Paul-Trois-Châteaux",
            "Saint-Paulet-de-Caisson",
            "Saint-Paulien",
            "Saint-Pé-de-Bigorre",
            "Saint-Pée-sur-Nivelle",
            "Saint-Péray",
            "Saint-Perdon",
            "Saint-Père",
            "Saint-Père-en-Retz",
            "Saint-Père-sur-Loire",
            "Saint-Perreux",
            "Saint-Philbert-de-Bouaine",
            "Saint-Philbert-de-Grand-Lieu",
            "Saint-Philbert-du-Peuple",
            "Saint-Philibert",
            "Saint-Piat",
            "Saint-Pierre",
            "Saint-Pierre-d’Aurillac",
            "Saint-Pierre-d’Oléron",
            "Saint-Pierre-de-Bœuf",
            "Saint-Pierre-de-Chandieu",
            "Saint-Pierre-de-Plesguen",
            "Saint-Pierre-de-Varengeville",
            "Saint-Pierre-des-Corps",
            "Saint-Pierre-des-Échaubrognes",
            "Saint-Pierre-des-Fleurs",
            "Saint-Pierre-des-Nids",
            "Saint-Pierre-du-Chemin",
            "Saint-Pierre-du-Mont",
            "Saint-Pierre-du-Perray",
            "Saint-Pierre-du-Regard",
            "Saint-Pierre-du-Vauvray",
            "Saint-Pierre-Église",
            "Saint-Pierre-en-Val",
            "Saint-Pierre-la-Cour",
            "Saint-Pierre-la-Palud",
            "Saint-Pierre-le-Moûtier",
            "Saint-Pierre-lès-Elbeuf",
            "Saint-Pierre-lès-Nemours",
            "Saint-Pierre-Montlimart",
            "Saint-Pierre-Quiberon",
            "Saint-Pierre-sur-Dives",
            "Saint-Planchers",
            "Saint-Pol-de-Léon",
            "Saint-Pol-sur-Mer",
            "Saint-Pol-sur-Ternoise",
            "Saint-Pons-de-Thomières",
            "Saint-Porchaire",
            "Saint-Porquier",
            "Saint-Pouange",
            "Saint-Pourçain-sur-Sioule",
            "Saint-Prest",
            "Saint-Priest",
            "Saint-Priest-en-Jarez",
            "Saint-Priest-sous-Aixe",
            "Saint-Priest-Taurion",
            "Saint-Privat",
            "Saint-Privat",
            "Saint-Privat-des-Vieux",
            "Saint-Privat-la-Montagne",
            "Saint-Prix",
            "Saint-Projet-Saint-Constant",
            "Saint-Prouant",
            "Saint-Pryvé-Saint-Mesmin",
            "Saint-Python",
            "Saint-Quay-Perros",
            "Saint-Quay-Portrieux",
            "Saint-Quentin",
            "Saint-Quentin-de-Baron",
            "Saint-Quentin-en-Mauges",
            "Saint-Quentin-en-Yvelines",
            "Saint-Quentin-Fallavier",
            "Saint-Quentin-la-Poterie",
            "Saint-Quentin-sur-Isère",
            "Saint-Quentin-sur-le-Homme",
            "Saint-Rambert-en-Bugey",
            "Saint-Raphaël",
            "Saint-Rémy",
            "Saint-Rémy",
            "Saint-Rémy-de-Maurienne",
            "Saint-Rémy-de-Provence",
            "Saint-Rémy-en-Mauges",
            "Saint-Rémy-en-Rollat",
            "Saint-Rémy-lès-Chevreuse",
            "Saint-Rémy-sur-Avre",
            "Saint-Rémy-sur-Durolle",
            "Saint-Renan",
            "Saint-Restitut",
            "Saint-Riquier",
            "Saint-Rogatien",
            "Saint-Romain-de-Benet",
            "Saint-Romain-de-Colbosc",
            "Saint-Romain-de-Jalionas",
            "Saint-Romain-de-Popey",
            "Saint-Romain-en-Gal",
            "Saint-Romain-la-Motte",
            "Saint-Romain-le-Puy",
            "Saint-Romain-sur-Cher",
            "Saint-Romans",
            "Saint-Saëns",
            "Saint-Samson-sur-Rance",
            "Saint-Satur",
            "Saint-Saturnin",
            "Saint-Saturnin",
            "Saint-Saturnin",
            "Saint-Saturnin-lès-Apt",
            "Saint-Saturnin-lès-Avignon",
            "Saint-Saturnin-sur-Loire",
            "Saint-Saulve",
            "Saint-Sauvant",
            "Saint-Sauves-d'Auvergne",
            "Saint-Sauveur",
            "Saint-Sauveur",
            "Saint-Sauveur",
            "Saint-Sauveur",
            "Saint-Sauveur",
            "Saint-Sauveur-d’Aunis",
            "Saint-Sauveur-de-Montagut",
            "Saint-Sauveur-des-Landes",
            "Saint-Sauveur-en-Rue",
            "Saint-Sauveur-le-Vicomte",
            "Saint-Sauveur-Lendelin",
            "Saint-Sauveur-sur-École",
            "Saint-Savin",
            "Saint-Savin",
            "Saint-Savinien",
            "Saint-Savournin",
            "Saint-Sébastien-de-Morsent",
            "Saint-Sébastien-sur-Loire",
            "Saint-Selve",
            "Saint-Senier-sous-Avranches",
            "Saint-Senoux",
            "Saint-Sernin",
            "Saint-Sernin-du-Bois",
            "Saint-Seurin-sur-l’Isle",
            "Saint-Sever",
            "Saint-Sever-Calvados",
            "Saint-Siméon-de-Bressieux",
            "Saint-Simon",
            "Saint-Sorlin-en-Bugey",
            "Saint-Sorlin-en-Valloire",
            "Saint-Souplet",
            "Saint-Soupplets",
            "Saint-Sulpice",
            "Saint-Sulpice-de-Cognac",
            "Saint-Sulpice-de-Faleyrens",
            "Saint-Sulpice-de-Pommeray",
            "Saint-Sulpice-de-Royan",
            "Saint-Sulpice-et-Cameyrac",
            "Saint-Sulpice-la-Forêt",
            "Saint-Sulpice-la-Pointe",
            "Saint-Sulpice-le-Guérétois",
            "Saint-Sulpice-les-Feuilles",
            "Saint-Sulpice-sur-Lèze",
            "Saint-Sulpice-sur-Risle",
            "Saint-Sylvain",
            "Saint-Sylvestre-Cappel",
            "Saint-Sylvestre-sur-Lot",
            "Saint-Symphorien",
            "Saint-Symphorien-de-Lay",
            "Saint-Symphorien-sur-Coise",
            "Saint-Thégonnec",
            "Saint-Thibault-des-Vignes",
            "Saint-Thibéry",
            "Saint-Thonan",
            "Saint-Thurial",
            "Saint-Thuriau",
            "Saint-Trivier-de-Courtes",
            "Saint-Trivier-sur-Moignans",
            "Saint-Trojan-les-Bains",
            "Saint-Tronc",
            "Saint-Tropez",
            "Saint-Urbain",
            "Saint-Urbain",
            "Saint-Usage",
            "Saint-Usuge",
            "Saint-Uze",
            "Saint-Vaast-la-Hougue",
            "Saint-Valérien",
            "Saint-Valery-en-Caux",
            "Saint-Valery-sur-Somme",
            "Saint-Vallier",
            "Saint-Vallier",
            "Saint-Vallier-de-Thiey",
            "Saint-Varent",
            "Saint-Vaury",
            "Saint-Venant",
            "Saint-Vérand",
            "Saint-Viance",
            "Saint-Viâtre",
            "Saint-Viaud",
            "Saint-Victor",
            "Saint-Victor-de-Cessieu",
            "Saint-Victor-la-Coste",
            "Saint-Victoret",
            "Saint-Victurnien",
            "Saint-Vigor-le-Grand",
            "Saint-Vincent-de-Mercuze",
            "Saint-Vincent-de-Paul",
            "Saint-Vincent-de-Tyrosse",
            "Saint-Vincent-des-Landes",
            "Saint-Vincent-sur-Graon",
            "Saint-Vincent-sur-Oust",
            "Saint-Vit",
            "Saint-Vite",
            "Saint-Vivien-de-Médoc",
            "Saint-Vrain",
            "Saint-Wandrille-Rançon",
            "Saint-Witz",
            "Saint-Xandre",
            "Saint-Yan",
            "Saint-Yorre",
            "Saint-Yrieix-la-Perche",
            "Saint-Yrieix-sur-Charente",
            "Saint-Yvi",
            "Saint-Yzan-de-Soudiac",
            "Saint-Zacharie",
            "Sainte-Adresse",
            "Sainte-Agnès",
            "Sainte-Anastasie-sur-Issole",
            "Sainte-Anne",
            "Sainte-Anne-d'Auray",
            "Sainte-Anne-sur-Brivet",
            "Sainte-Bazeille",
            "Sainte-Catherine",
            "Sainte-Cécile",
            "Sainte-Cécile-les-Vignes",
            "Sainte-Colombe",
            "Sainte-Colombe",
            "Sainte-Colombe-en-Bruilhois",
            "Sainte-Consorce",
            "Sainte-Croix-aux-Mines",
            "Sainte-Croix-en-Plaine",
            "Sainte-Eulalie",
            "Sainte-Euphémie",
            "Sainte-Féréole",
            "Sainte-Feyre",
            "Sainte-Flaive-des-Loups",
            "Sainte-Florine",
            "Sainte-Fortunade",
            "Sainte-Foy",
            "Sainte-Foy-de-Peyrolières",
            "Sainte-Foy-l’Argentière",
            "Sainte-Foy-la-Grande",
            "Sainte-Foy-lès-Lyon",
            "Sainte-Gauburge-Sainte-Colombe",
            "Sainte-Gemme-la-Plaine",
            "Sainte-Gemmes-sur-Loire",
            "Sainte-Geneviève",
            "Sainte-Geneviève-des-Bois",
            "Sainte-Geneviève-des-Bois",
            "Sainte-Geneviève-sur-Argence",
            "Sainte-Hélène",
            "Sainte-Hélène",
            "Sainte-Hélène-sur-Isère",
            "Sainte-Hermine",
            "Sainte-Honorine-du-Fay",
            "Sainte-Jamme-sur-Sarthe",
            "Sainte-Livrade-sur-Lot",
            "Sainte-Lizaigne",
            "Sainte-Luce-sur-Loire",
            "Sainte-Marguerite",
            "Sainte-Marguerite",
            "Sainte-Marguerite-sur-Duclair",
            "Sainte-Marie",
            "Sainte-Marie-aux-Chênes",
            "Sainte-Marie-aux-Mines",
            "Sainte-Marie-de-Gosse",
            "Sainte-Marie-de-Ré",
            "Sainte-Marie-des-Champs",
            "Sainte-Marie-Kerque",
            "Sainte-Marie-Plage",
            "Sainte-Marthe",
            "Sainte-Maure",
            "Sainte-Maure-de-Touraine",
            "Sainte-Maxime",
            "Sainte-Menehould",
            "Sainte-Mère-Église",
            "Sainte-Pazanne",
            "Sainte-Radegonde",
            "Sainte-Radegonde",
            "Sainte-Reine-de-Bretagne",
            "Sainte-Savine",
            "Sainte-Sigolène",
            "Sainte-Solange",
            "Sainte-Soulle",
            "Sainte-Suzanne",
            "Sainte-Suzanne",
            "Sainte-Terre",
            "Sainte-Tulle",
            "Sainte-Verge",
            "Saintes",
            "Saintes-Maries-de-la-Mer",
            "Saintry-sur-Seine",
            "Saints",
            "Saivres",
            "Saïx",
            "Saizerais",
            "Salagnon",
            "Salaise-sur-Sanne",
            "Salbert",
            "Salbris",
            "Saleilles",
            "Salernes",
            "Sales",
            "Saleux",
            "Salies-de-Béarn",
            "Salies-du-Salat",
            "Salignac",
            "Salignac-Eyvigues",
            "Saligny",
            "Salindres",
            "Salins",
            "Salins-les-Bains",
            "Salins-les-Thermes",
            "Sallanches",
            "Sallaumines",
            "Sallebœuf",
            "Sallertaine",
            "Salles",
            "Salles-Curan",
            "Salles-la-Source",
            "Salles-sur-Mer",
            "Salomé",
            "Salon-de-Provence",
            "Salouël",
            "Salses-le-Château",
            "Salviac",
            "Samadet",
            "Samatan",
            "Saméon",
            "Samer",
            "Sammeron",
            "Samoëns",
            "Samois-sur-Seine",
            "Samoreau",
            "San-Martino-di-Lota",
            "San-Nicolao",
            "Sanary-sur-Mer",
            "Sancé",
            "Sancerre",
            "Sancey-le-Grand",
            "Sancoins",
            "Sand",
            "Sandillon",
            "Sangatte",
            "Sanguinet",
            "Sannerville",
            "Sannois",
            "Sansac-de-Marmiesse",
            "Sant Andreu de Sureda",
            "Sant Joan de Pladecorts",
            "Sant Llorenç de Cerdans",
            "Santa-Lucia-di-Moriani",
            "Santa-Maria-di-Lota",
            "Santec",
            "Santeny",
            "Santes",
            "Sanvignes-les-Mines",
            "Saône",
            "Saran",
            "Sarbazan",
            "Sarcelles",
            "Sare",
            "Sargé-lès-le-Mans",
            "Sargé-sur-Braye",
            "Sari-Solenzara",
            "Sarlat-la-Canéda",
            "Sarralbe",
            "Sarras",
            "Sarre-Union",
            "Sarrebourg",
            "Sarreguemines",
            "Sarreinsming",
            "Sarrewerden",
            "Sarrians",
            "Sarrola",
            "Sarry",
            "Sars-Poteries",
            "Sartène",
            "Sarthe",
            "Sartilly",
            "Sartrouville",
            "Sarzeau",
            "Sassenage",
            "Sassenay",
            "Sathonay-Camp",
            "Sathonay-Village",
            "Satillieu",
            "Satolas-et-Bonce",
            "Saubens",
            "Saubion",
            "Saubrigues",
            "Saucats",
            "Saugnac-et-Cambran",
            "Saugues",
            "Saujon",
            "Saulce-sur-Rhône",
            "Saulcy-sur-Meurthe",
            "Saulgé",
            "Saulieu",
            "Saulnes",
            "Saulny",
            "Sault",
            "Sault-Brénaz",
            "Sault-lès-Rethel",
            "Saultain",
            "Saulx-les-Chartreux",
            "Saulxures-lès-Nancy",
            "Saulxures-sur-Moselotte",
            "Saulzoir",
            "Saumur",
            "Sausheim",
            "Saussan",
            "Saussay",
            "Sausset-les-Pins",
            "Sautron",
            "Sauvagnon",
            "Sauve",
            "Sauverny",
            "Sauveterre",
            "Sauveterre-de-Béarn",
            "Sauveterre-de-Guyenne",
            "Sauvian",
            "Sauviat-sur-Vige",
            "Sauvigny-les-Bois",
            "Sauxillanges",
            "Sauzé-Vaussais",
            "Sauzet",
            "Savasse",
            "Savenay",
            "Savennières",
            "Saverdun",
            "Saverne",
            "Savigné",
            "Savigné-sur-Lathan",
            "Savigneux",
            "Savigny",
            "Savigny-en-Sancerre",
            "Savigny-en-Véron",
            "Savigny-le-Temple",
            "Savigny-lès-Beaune",
            "Savigny-Lévescault",
            "Savigny-sur-Braye",
            "Savigny-sur-Orge",
            "Savoie",
            "Savonnières",
            "Savy-Berlette",
            "Sayat",
            "Saze",
            "Scaër",
            "Sceaux",
            "Scey-sur-Saône-et-Saint-Albin",
            "Scherwiller",
            "Schiltigheim",
            "Schirmeck",
            "Schirrhein",
            "Schnersheim",
            "Schœneck",
            "Schweighouse-sur-Moder",
            "Schwindratzheim",
            "Sciez",
            "Scionzier",
            "Scorbé-Clairvaux",
            "Scy-Chazelles",
            "Sébazac-Concourès",
            "Seboncourt",
            "Sebourg",
            "Seclin",
            "Secondigny",
            "Sedan",
            "Sées",
            "Séez",
            "Ségny",
            "Segonzac",
            "Segré",
            "Seichamps",
            "Seiches-sur-le-Loir",
            "Seignelay",
            "Seignosse",
            "Seigy",
            "Seilh",
            "Seilhac",
            "Seillans",
            "Seine-et-Marne",
            "Seine-Maritime",
            "Seine-Port",
            "Seine-Saint-Denis",
            "Seingbouse",
            "Seissan",
            "Sélestat",
            "Selles-Saint-Denis",
            "Selles-sur-Cher",
            "Seloncourt",
            "Selongey",
            "Seltz",
            "Selvigny",
            "Sémalens",
            "Semblançay",
            "Séméac",
            "Semoy",
            "Semur-en-Auxois",
            "Semussac",
            "Sénas",
            "Séné",
            "Senlis",
            "Sennecey-le-Grand",
            "Sennecey-lès-Dijon",
            "Senonches",
            "Senones",
            "Sens",
            "Sens-de-Bretagne",
            "Sentheim",
            "Septème",
            "Septèmes-les-Vallons",
            "Septeuil",
            "Septfonds",
            "Sequedin",
            "Seraincourt",
            "Séreilhac",
            "Serémange-Erzange",
            "Sérent",
            "Sérézin-du-Rhône",
            "Sergines",
            "Sergy",
            "Sérifontaine",
            "Sérignan",
            "Sérignan-du-Comtat",
            "Sérigné",
            "Sermaises",
            "Sermaize-les-Bains",
            "Sermérieu",
            "Sermoise-sur-Loire",
            "Sernhac",
            "Serpaize",
            "Serques",
            "Serqueux",
            "Serquigny",
            "Serre-les-Sapins",
            "Serres",
            "Serres-Castet",
            "Serrières",
            "Serrières-de-Briord",
            "Serris",
            "Servas",
            "Servian",
            "Servon",
            "Servon-sur-Vilaine",
            "Sessenheim",
            "Sète",
            "Seugy",
            "Seurre",
            "Sévérac",
            "Sévérac-le-Château",
            "Sévignac",
            "Sevran",
            "Sèvres",
            "Sèvres-Anxaumont",
            "Sevrey",
            "Sevrier",
            "Seyne-les-Alpes",
            "Seynod",
            "Seysses",
            "Seyssinet-Pariset",
            "Seyssins",
            "Seyssuel",
            "Sézanne",
            "Sibiril",
            "Sierck-les-Bains",
            "Sierentz",
            "Sigean",
            "Signes",
            "Signy-le-Petit",
            "Sigolsheim",
            "Sillans",
            "Sillé-le-Guillaume",
            "Sillery",
            "Sillingy",
            "Silly-le-Long",
            "Simandre",
            "Simandres",
            "Simiane-Collongue",
            "Sin-le-Noble",
            "Sinceny",
            "Sion-les-Mines",
            "Sireuil",
            "Sisco",
            "Sissonne",
            "Sisteron",
            "Six-Fours-les-Plages",
            "Sixt-sur-Aff",
            "Sizun",
            "Smarves",
            "Sochaux",
            "Socx",
            "Soignolles-en-Brie",
            "Soings-en-Sologne",
            "Soissons",
            "Soisy-sous-Montmorency",
            "Soisy-sur-École",
            "Soisy-sur-Seine",
            "Solaize",
            "Solers",
            "Solesmes",
            "Solesmes",
            "Solgne",
            "Soliers",
            "Solignac",
            "Solignac-sur-Loire",
            "Solliès-Pont",
            "Solliès-Toucas",
            "Solliès-Ville",
            "Solre-le-Château",
            "Somain",
            "Somme",
            "Sommières",
            "Sonchamp",
            "Songeons",
            "Sonnay",
            "Sonnaz",
            "Sonzay",
            "Soorts-Hossegor",
            "Sorbiers",
            "Sorède",
            "Sorel-Moussel",
            "Sorèze",
            "Sorges",
            "Sorgues",
            "Sorigny",
            "Sormiou",
            "Sornay",
            "Sospel",
            "Sottevast",
            "Sotteville-lès-Rouen",
            "Soual",
            "Soubise",
            "Soucelles",
            "Souchez",
            "Soucht",
            "Soucieu-en-Jarrest",
            "Soucy",
            "Soudan",
            "Soues",
            "Souesmes",
            "Souffelweyersheim",
            "Soufflenheim",
            "Souillac",
            "Soulac-sur-Mer",
            "Soulaines-sur-Aubance",
            "Soulaire-et-Bourg",
            "Soulgé-sur-Ouette",
            "Souligné-sous-Ballon",
            "Soullans",
            "Soultz-Haut-Rhin",
            "Soultz-sous-Forêts",
            "Soultzeren",
            "Soultzmatt",
            "Soumoulou",
            "Souppes-sur-Loing",
            "Souprosse",
            "Souraïde",
            "Sourcieux-les-Mines",
            "Sourdeval",
            "Sourdun",
            "Sours",
            "Sourzac",
            "Soussans",
            "Soustons",
            "Souvigny",
            "Soyaux",
            "Soyons",
            "Spay",
            "Spéracèdes",
            "Spézet",
            "Spicheren",
            "Spycker",
            "Staffelfelden",
            "Stains",
            "Steenbecque",
            "Steene",
            "Steenvoorde",
            "Steenwerck",
            "Steinbach",
            "Steinbourg",
            "Stenay",
            "Still",
            "Stiring-Wendel",
            "Stosswihr",
            "Stotzheim",
            "Strasbourg",
            "Sucé-sur-Erdre",
            "Sucy-en-Brie",
            "Suèvres",
            "Suippes",
            "Sully-sur-Loire",
            "Sulniac",
            "Sumène",
            "Sundhoffen",
            "Sundhouse",
            "Surbourg",
            "Suresnes",
            "Surgères",
            "Surtainville",
            "Survilliers",
            "Sury-le-Comtal",
            "Surzur",
            "Sussargues",
            "Suze-la-Rousse",
            "Tabanac",
            "Tacoignières",
            "Taden",
            "Taillades",
            "Taillecourt",
            "Tain-l’Hermitage",
            "Taintrux",
            "Taissy",
            "Talange",
            "Talant",
            "Talence",
            "Talensac",
            "Tallard",
            "Tallende",
            "Talloires",
            "Talmas",
            "Taluyers",
            "Tancarville",
            "Taninges",
            "Tanlay",
            "Tanneron",
            "Taponnat-Fleurignac",
            "Taradeau",
            "Tarare",
            "Tarascon",
            "Tarascon-sur-Ariège",
            "Tarbes",
            "Targon",
            "Tarn",
            "Tarnos",
            "Tartas",
            "Tassin-la-Demi-Lune",
            "Tatinghem",
            "Taulé",
            "Taulignan",
            "Taupont",
            "Tauriac",
            "Tauxigny",
            "Tavaux",
            "Tavel",
            "Taverny",
            "Tavers",
            "Teillé",
            "Telgruc-sur-Mer",
            "Teloché",
            "Templemars",
            "Templeuve-en-Pévèle",
            "Tenay",
            "Tence",
            "Tencin",
            "Tende",
            "Tennie",
            "Tenteling",
            "Tercé",
            "Tercis-les-Bains",
            "Tergnier",
            "Ternay",
            "Terrasson-Lavilledieu",
            "Territoire de Belfort",
            "Terville",
            "Tessé-la-Madeleine",
            "Tessy-sur-Vire",
            "Téteghem",
            "Teting-sur-Nied",
            "Teyran",
            "Thairé",
            "Thann",
            "Thaon",
            "Thaon-les-Vosges",
            "Théding",
            "Theillay",
            "Theix",
            "Theizé",
            "Thélus",
            "Thénac",
            "Thénezay",
            "Thenon",
            "Théoule-sur-Mer",
            "Thérouanne",
            "Thésée",
            "Theys",
            "Théza",
            "Thézan-lès-Béziers",
            "Thiais",
            "Thiant",
            "Thiberville",
            "Thiers",
            "Thiers",
            "Thiers-sur-Thève",
            "Thierville-sur-Meuse",
            "Thil",
            "Thil",
            "Thilay",
            "Thilouze",
            "Thionville",
            "Thiron Gardais",
            "Thise",
            "Thiverny",
            "Thiverval-Grignon",
            "Thiviers",
            "Thizy-les-Bourgs",
            "Thoiry",
            "Thoiry",
            "Thoissey",
            "Thomery",
            "Thônes",
            "Thonon-les-Bains",
            "Thorens-Glières",
            "Thorigné",
            "Thorigné-sur-Dué",
            "Thorigny-sur-Marne",
            "Thorigny-sur-Oreuse",
            "Thouarcé",
            "Thouaré-sur-Loire",
            "Thouars",
            "Thourotte",
            "Thuellin",
            "Thueyts",
            "Thuir",
            "Thumeries",
            "Thun-Saint-Amand",
            "Thuré",
            "Thurins",
            "Thury-Harcourt",
            "Thyez",
            "Tiercé",
            "Tiffauges",
            "Tigery",
            "Tignes",
            "Tignieu",
            "Tigy",
            "Tillé",
            "Tillières",
            "Tillières-sur-Avre",
            "Tilloy-lès-Mofflaines",
            "Tilly-sur-Seulles",
            "Tilques",
            "Tinchebray",
            "Tinqueux",
            "Tinténiac",
            "Tocane-Saint-Apre",
            "Tollevast",
            "Tomblaine",
            "Tonnay-Boutonne",
            "Tonnay-Charente",
            "Tonneins",
            "Tonnerre",
            "Tonquédec",
            "Torcé",
            "Torcy",
            "Torcy",
            "Torfou",
            "Torigni-sur-Vire",
            "Torreilles",
            "Tosse",
            "Tossiat",
            "Tôtes",
            "Toucy",
            "Toufflers",
            "Toul",
            "Toulaud",
            "Toulenne",
            "Toulon",
            "Toulon-sur-Allier",
            "Toulon-sur-Arroux",
            "Toulouges",
            "Toulouse",
            "Touques",
            "Touquin",
            "Tourbes",
            "Tourcoing",
            "Tourlaville",
            "Tournan-en-Brie",
            "Tournay",
            "Tournefeuille",
            "Tournehem-sur-la-Hem",
            "Tournes",
            "Tournon-Saint-Martin",
            "Tournon-sur-Rhône",
            "Tournus",
            "Tourouvre",
            "Tourrette-Levens",
            "Tourrettes-sur-Loup",
            "Tours",
            "Tours-sur-Marne",
            "Tourves",
            "Tourville-la-Rivière",
            "Tourville-sur-Arques",
            "Tourville-sur-Odon",
            "Toury",
            "Toussieu",
            "Toutainville",
            "Toutlemonde",
            "Touvois",
            "Touvre",
            "Tracy-le-Mont",
            "Traînou",
            "Tramoyes",
            "Trangé",
            "Trans-en-Provence",
            "Trappes",
            "Trèbes",
            "Trébeurden",
            "Trédarzec",
            "Trédrez-Locquémeau",
            "Treffiagat",
            "Treffléan",
            "Treffort-Cuisiat",
            "Trégastel",
            "Tréguier",
            "Trégunc",
            "Treignac",
            "Treillières",
            "Treize-Septiers",
            "Trélazé",
            "Trélévern",
            "Trélissac",
            "Trélivan",
            "Trélon",
            "Tremblay",
            "Tremblay-en-France",
            "Trémentines",
            "Trémery",
            "Tréméven",
            "Trémuson",
            "Tréon",
            "Trept",
            "Tresbœuf",
            "Tresques",
            "Tressange",
            "Tresserve",
            "Tresses",
            "Tressin",
            "Trets",
            "Trévé",
            "Trévol",
            "Trévou-Tréguignec",
            "Trévoux",
            "Triaize",
            "Tricot",
            "Trie-Château",
            "Trie-sur-Baïse",
            "Triel-sur-Seine",
            "Trieux",
            "Trignac",
            "Triguères",
            "Trilport",
            "Trith-Saint-Léger",
            "Trizay",
            "Troarn",
            "Troissereux",
            "Tronville-en-Barrois",
            "Trosly-Breuil",
            "Trouillas",
            "Trouville-sur-Mer",
            "Trouy",
            "Troyes",
            "Truchtersheim",
            "Trun",
            "Truyes",
            "Tucquegnieux",
            "Tuffé",
            "Tulette",
            "Tulle",
            "Tullins",
            "Turckheim",
            "Turretot",
            "Uberach",
            "Uchaud",
            "Uchaux",
            "Uckange",
            "Uffholtz",
            "Ugine",
            "Ully-Saint-Georges",
            "Ungersheim",
            "Unieux",
            "Unverre",
            "Upie",
            "Urcuit",
            "Uriménil",
            "Urmatt",
            "Urrugne",
            "Urt",
            "Urville-Nacqueville",
            "Urzy",
            "Us",
            "Ussac",
            "Ussel",
            "Usson-du-Poitou",
            "Usson-en-Forez",
            "Ustaritz",
            "Uxegney",
            "Uxem",
            "Uzein",
            "Uzemain",
            "Uzerche",
            "Uzès",
            "Vaas",
            "Vacon",
            "Vacqueyras",
            "Vacquiers",
            "Vagney",
            "Vaiges",
            "Vailhauquès",
            "Vailly-sur-Aisne",
            "Vairé",
            "Vaires-sur-Marne",
            "Vaison-la-Romaine",
            "Vaivre-et-Montoille",
            "Val Thorens",
            "Val-d’Isère",
            "Val-de-Marne",
            "Val-de-Meuse",
            "Val-de-Reuil",
            "Valady",
            "Valbonne",
            "Valdahon",
            "Valdoie",
            "Valençay",
            "Valence",
            "Valence",
            "Valence-d’Albigeois",
            "Valence-sur-Baïse",
            "Valenciennes",
            "Valencin",
            "Valensole",
            "Valentigney",
            "Valenton",
            "Valergues",
            "Valff",
            "Valfin-lès-Saint-Claude",
            "Valframbert",
            "Vallabrègues",
            "Vallauris",
            "Valleiry",
            "Valleraugue",
            "Valleroy",
            "Vallet",
            "Vallières",
            "Valliquerville",
            "Valloire",
            "Vallon-en-Sully",
            "Vallon-Pont-d’Arc",
            "Valmondois",
            "Valmont",
            "Valognes",
            "Valras-Plage",
            "Valréas",
            "Valros",
            "Vals-les-Bains",
            "Vals-près-le-Puy",
            "Vandœuvre-lès-Nancy",
            "Vannes",
            "Vanves",
            "Var",
            "Varades",
            "Varangéville",
            "Varengeville-sur-Mer",
            "Varennes-Jarcy",
            "Varennes-le-Grand",
            "Varennes-Saint-Sauveur",
            "Varennes-sur-Allier",
            "Varennes-sur-Loire",
            "Varennes-sur-Seine",
            "Varennes-Vauzelles",
            "Varetz",
            "Varilhes",
            "Varois-et-Chaignot",
            "Varrains",
            "Varreddes",
            "Vars",
            "Varzy",
            "Vasles",
            "Vasselay",
            "Vassy",
            "Vatan",
            "Vauchrétien",
            "Vaucouleurs",
            "Vaucresson",
            "Vaudelnay",
            "Vaudry",
            "Vaugneray",
            "Vaugrigneuse",
            "Vauhallan",
            "Vaujours",
            "Vaulnaveys-le-Bas",
            "Vaulnaveys-le-Haut",
            "Vaulx-en-Velin",
            "Vaulx-Milieu",
            "Vaulx-Vraucourt",
            "Vaumoise",
            "Vauréal",
            "Vauvert",
            "Vaux-en-Bugey",
            "Vaux-le-Pénil",
            "Vaux-sur-Mer",
            "Vaux-sur-Seine",
            "Vay",
            "Vayrac",
            "Vayres",
            "Veauche",
            "Vecoux",
            "Vedène",
            "Veigné",
            "Veigy",
            "Velaine-en-Haye",
            "Velaines",
            "Velars-sur-Ouche",
            "Velaux",
            "Vélines",
            "Vélizy-Villacoublay",
            "Velleron",
            "Vémars",
            "Venansault",
            "Venarey-les-Laumes",
            "Venasque",
            "Vence",
            "Vendargues",
            "Vendat",
            "Vendays-Montalivet",
            "Vendegies-sur-Écaillon",
            "Vendenheim",
            "Vendeuvre-du-Poitou",
            "Vendeuvre-sur-Barse",
            "Vendeville",
            "Vendin-le-Vieil",
            "Vendin-lès-Béthune",
            "Vendœuvres",
            "Vendôme",
            "Vendrennes",
            "Vendres",
            "Vénéjan",
            "Venelles",
            "Venerque",
            "Venette",
            "Veneux-les-Sablons",
            "Vénissieux",
            "Venizel",
            "Vennecy",
            "Venoy",
            "Ventabren",
            "Ventiseri",
            "Ver-sur-Launette",
            "Ver-sur-Mer",
            "Verberie",
            "Vercel-Villedieu-le-Camp",
            "Verdun",
            "Verdun-sur-Garonne",
            "Verdun-sur-le-Doubs",
            "Verduron",
            "Véretz",
            "Verfeil",
            "Vergèze",
            "Vergigny",
            "Vergongheon",
            "Vergt",
            "Vérines",
            "Verlinghem",
            "Vermand",
            "Vermelles",
            "Vermenton",
            "Vern-d’Anjou",
            "Vern-sur-Seiche",
            "Vernaison",
            "Vernantes",
            "Vernègues",
            "Vernet",
            "Vernet-les-Bains",
            "Verneuil-en-Halatte",
            "Verneuil-sur-Avre",
            "Verneuil-sur-Seine",
            "Verneuil-sur-Vienne",
            "Verniolle",
            "Vernioz",
            "Vernoil-le-Fourrier",
            "Vernon",
            "Vernosc-lès-Annonay",
            "Vernou-la-Celle-sur-Seine",
            "Vernou-sur-Brenne",
            "Vernouillet",
            "Vernouillet",
            "Vernoux-en-Vivarais",
            "Verny",
            "Véron",
            "Verquin",
            "Verrières",
            "Verrières-le-Buisson",
            "Vers-Pont-du-Gard",
            "Versailles",
            "Verson",
            "Versonnex",
            "Vert-en-Drouais",
            "Vert-le-Grand",
            "Vert-le-Petit",
            "Vert-Saint-Denis",
            "Vertaizon",
            "Vertheuil",
            "Verton",
            "Vertou",
            "Vertus",
            "Vervins",
            "Verzenay",
            "Verzy",
            "Vescovato",
            "Vesoul",
            "Vesseaux",
            "Vestric-et-Candiac",
            "Vétraz-Monthoux",
            "Veurey-Voroize",
            "Veynes",
            "Veyrac",
            "Veyras",
            "Veyre-Monton",
            "Veyrier-du-Lac",
            "Vézelise",
            "Vézénobres",
            "Vezin-le-Coquet",
            "Vezins",
            "Vianne",
            "Viarmes",
            "Vias",
            "Vibraye",
            "Vic-en-Bigorre",
            "Vic-Fezensac",
            "Vic-la-Gardiole",
            "Vic-le-Comte",
            "Vic-sur-Aisne",
            "Vic-sur-Cère",
            "Vic-sur-Seille",
            "Vichy",
            "Vicq",
            "Vicq-sur-Breuilh",
            "Vidauban",
            "Vieille Chapelle",
            "Vieille-Brioude",
            "Vieille-Église",
            "Vieille-Toulouse",
            "Vieillevigne",
            "Vielle-Saint-Girons",
            "Vielmur-sur-Agout",
            "Viennay",
            "Vienne",
            "Vienne",
            "Vienne-en-Val",
            "Vierzon",
            "Viesly",
            "Vieux-Berquin",
            "Vieux-Boucau-les-Bains",
            "Vieux-Charmont",
            "Vieux-Condé",
            "Vieux-Thann",
            "Vif",
            "Vigeois",
            "Vignacourt",
            "Vigneulles-lès-Hattonchâtel",
            "Vigneux-de-Bretagne",
            "Vigneux-sur-Seine",
            "Vignoc",
            "Vignot",
            "Vignoux-sur-Barangeon",
            "Vigny",
            "Vigy",
            "Vihiers",
            "Vilallonga dels Monts",
            "Villabé",
            "Village-Neuf",
            "Villaines-la-Juhel",
            "Villandry",
            "Villar-Saint-Pancrace",
            "Villard-Bonnot",
            "Villard-de-Lans",
            "Villargondran",
            "Villars",
            "Villars-les-Dombes",
            "Villaudric",
            "Villaz",
            "Villé",
            "Ville-d’Avray",
            "Ville-la-Grand",
            "Ville-sous-Anjou",
            "Ville-sous-la-Ferté",
            "Villebarou",
            "Villebernier",
            "Villeblevin",
            "Villebois",
            "Villebon-sur-Yvette",
            "Villebret",
            "Villecresnes",
            "Villecroze",
            "Villedieu-la-Blouère",
            "Villedieu-les-Poêles",
            "Villedieu-sur-Indre",
            "Villedômer",
            "Villefagnan",
            "Villefontaine",
            "Villefranche-d'Albigeois",
            "Villefranche-d’Allier",
            "Villefranche-de-Lauragais",
            "Villefranche-de-Rouergue",
            "Villefranche-sur-Cher",
            "Villefranche-sur-Mer",
            "Villefranche-sur-Saône",
            "Villefranque",
            "Villegailhenc",
            "Villegouge",
            "Villejuif",
            "Villejust",
            "Villelaure",
            "Villelongue-de-la-Salanque",
            "Villemandeur",
            "Villemeux-sur-Eure",
            "Villemoirieu",
            "Villemoisson-sur-Orge",
            "Villemolaque",
            "Villemomble",
            "Villemoustaussou",
            "Villemur-sur-Tarn",
            "Villenauxe-la-Grande",
            "Villenave-d’Ornon",
            "Villeneuve",
            "Villeneuve",
            "Villeneuve",
            "Villeneuve-d'Ascq",
            "Villeneuve-d’Olmes",
            "Villeneuve-de-Berg",
            "Villeneuve-de-la-Raho",
            "Villeneuve-de-Marsan",
            "Villeneuve-de-Rivière",
            "Villeneuve-la-Comptal",
            "Villeneuve-la-Garenne",
            "Villeneuve-la-Guyard",
            "Villeneuve-la-Rivière",
            "Villeneuve-le-Comte",
            "Villeneuve-le-Roi",
            "Villeneuve-lès-Avignon",
            "Villeneuve-lès-Béziers",
            "Villeneuve-lès-Bouloc",
            "Villeneuve-lès-Maguelone",
            "Villeneuve-les-Sablons",
            "Villeneuve-Loubet",
            "Villeneuve-Saint-Georges",
            "Villeneuve-Saint-Germain",
            "Villeneuve-sur-Bellot",
            "Villeneuve-sur-Lot",
            "Villeneuve-sur-Yonne",
            "Villeneuve-Tolosane",
            "Villennes-sur-Seine",
            "Villenouvelle",
            "Villenoy",
            "Villeparisis",
            "Villepinte",
            "Villepinte",
            "Villepreux",
            "Villeréal",
            "Villerest",
            "Villereversure",
            "Villers-Bocage",
            "Villers-Bocage",
            "Villers-Bretonneux",
            "Villers-Cotterêts",
            "Villers-Écalles",
            "Villers-en-Cauchies",
            "Villers-la-Montagne",
            "Villers-le-Lac",
            "Villers-lès-Nancy",
            "Villers-Outréaux",
            "Villers-Pol",
            "Villers-Saint-Paul",
            "Villers-Semeuse",
            "Villers-sous-Saint-Leu",
            "Villers-sur-Coudun",
            "Villers-sur-Mer",
            "Villersexel",
            "Villerupt",
            "Villes-sur-Auzon",
            "Villetaneuse",
            "Villetelle",
            "Villette-de-Vienne",
            "Villeurbanne",
            "Villevaudé",
            "Villevêque",
            "Villeveyrac",
            "Villevieille",
            "Villevocance",
            "Villey-Saint-Étienne",
            "Villié-Morgon",
            "Villiers-en-Lieu",
            "Villiers-en-Plaine",
            "Villiers-le-Bâcle",
            "Villiers-le-Bel",
            "Villiers-le-Morhier",
            "Villiers-Saint-Fréderic",
            "Villiers-Saint-Georges",
            "Villiers-sur-Loir",
            "Villiers-sur-Marne",
            "Villiers-sur-Morin",
            "Villiers-sur-Orge",
            "Vimines",
            "Vimory",
            "Vimoutiers",
            "Vimy",
            "Vinassan",
            "Vinay",
            "Vinça",
            "Vincennes",
            "Vincey",
            "Vineuil",
            "Vineuil-Saint-Firmin",
            "Vinneuf",
            "Vinon-sur-Verdon",
            "Vinsobres",
            "Violaines",
            "Violay",
            "Violès",
            "Vion",
            "Virazeil",
            "Vire",
            "Vireux-Molhain",
            "Vireux-Wallerand",
            "Virey-le-Grand",
            "Viriat",
            "Virieu",
            "Virieu-le-Grand",
            "Viriville",
            "Viroflay",
            "Viry",
            "Viry-Châtillon",
            "Viry-Noureuil",
            "Visan",
            "Vitré",
            "Vitrolles",
            "Vitry-aux-Loges",
            "Vitry-en-Artois",
            "Vitry-le-François",
            "Vitry-sur-Orne",
            "Vitry-sur-Seine",
            "Vitteaux",
            "Vittel",
            "Viuz-en-Sallaz",
            "Viuz-la-Chiésaz",
            "Vivier-au-Court",
            "Viviers",
            "Viviers-du-Lac",
            "Viviers-lès-Montagnes",
            "Viviez",
            "Vivonne",
            "Vivy",
            "Vix",
            "Vizille",
            "Vœuil-et-Giget",
            "Voglans",
            "Void-Vacon",
            "Voiron",
            "Voisenon",
            "Voisins-le-Bretonneux",
            "Volgelsheim",
            "Volmerange-les-Mines",
            "Volonne",
            "Volstroff",
            "Volvic",
            "Volx",
            "Vonnas",
            "Voreppe",
            "Vorey",
            "Vosges",
            "Vougy",
            "Vouillé",
            "Voujeaucourt",
            "Voulangis",
            "Voulx",
            "Vouneuil-sous-Biard",
            "Vouneuil-sur-Vienne",
            "Vourey",
            "Vourles",
            "Voutezac",
            "Vouvray",
            "Vouziers",
            "Vouzon",
            "Voves",
            "Vred",
            "Vrigne-aux-Bois",
            "Vue",
            "Vulaines-sur-Seine",
            "Wahagnies",
            "Wailly",
            "Walbourg",
            "Waldighofen",
            "Walincourt-Selvigny",
            "Wallers",
            "Walscheid",
            "Wambrechies",
            "Wandignies-Hamage",
            "Wangenbourg-Engenthal",
            "Warcq",
            "Wardrecques",
            "Wargnies-le-Grand",
            "Warhem",
            "Warluis",
            "Warmeriville",
            "Wasquehal",
            "Wasselonne",
            "Wassigny",
            "Wassy",
            "Watten",
            "Wattignies",
            "Wattrelos",
            "Wattwiller",
            "Wavignies",
            "Wavrechain-sous-Denain",
            "Wavrin",
            "Waziers",
            "Weitbruch",
            "Wervicq-Sud",
            "Westhoffen",
            "Wettolsheim",
            "Weyersheim",
            "Widensolen",
            "Wiesviller",
            "Wignehies",
            "Wihr-au-Val",
            "Willems",
            "Willerwald",
            "Wimereux",
            "Wimille",
            "Wimmenau",
            "Wingen-sur-Moder",
            "Wingersheim",
            "Wingles",
            "Winnezeele",
            "Wintzenheim",
            "Wisches",
            "Wissant",
            "Wissembourg",
            "Wissous",
            "Witry-lès-Reims",
            "Wittelsheim",
            "Wittenheim",
            "Wittisheim",
            "Wizernes",
            "Woerth",
            "Woincourt",
            "Woippy",
            "Wolfgantzen",
            "Wolfisheim",
            "Wormhout",
            "Woustviller",
            "Xertigny",
            "Xonrupt-Longemer",
            "Yainville",
            "Ychoux",
            "Ydes",
            "Yébleron",
            "Yenne",
            "Yerres",
            "Yerville",
            "Yèvres",
            "Yffiniac",
            "Ygos-Saint-Saturnin",
            "Ymare",
            "Youx",
            "Yport",
            "Yssingeaux",
            "Ytrac",
            "Yutz",
            "Yvelines",
            "Yves",
            "Yvetot",
            "Yvignac-la-Tour",
            "Yvrac",
            "Yvré-le-Pôlin",
            "Yzernay",
            "Yzeure",
            "Yzeures-sur-Creuse",
            "Zegerscappel",
            "Zillisheim",
            "Zimmersheim",
            "Zonza",
            "Zutkerque",
            "Zuydcoote"
        ]
    },
    {
        "label": "French Guiana",
        "code": "GF",
        "phone": "594",
        "cities": []
    },
    {
        "label": "French Polynesia",
        "code": "PF",
        "phone": "689",
        "cities": []
    },
    {
        "label": "French Southern Territories",
        "code": "TF",
        "phone": "262",
        "cities": []
    },
    {
        "label": "Gabon",
        "code": "GA",
        "phone": "241",
        "cities": [
            "Bitam",
            "Booué",
            "Cocobeach",
            "Fougamou",
            "Franceville",
            "Gamba",
            "Koulamoutou",
            "Lambaréné",
            "Lastoursville",
            "Lékoni",
            "Libreville",
            "Makokou",
            "Mayumba",
            "Mbigou",
            "Mimongo",
            "Mitzic",
            "Moanda",
            "Mouila",
            "Mounana",
            "Ndendé",
            "Ndjolé",
            "Ntoum",
            "Okondja",
            "Omboué",
            "Oyem",
            "Port-Gentil",
            "Tchibanga",
            "Zadie"
        ]
    },
    {
        "label": "Gambia The",
        "code": "GM",
        "phone": "220",
        "cities": [
            "Abuko",
            "Bakadagy",
            "Bakau",
            "Bambali",
            "Banjul",
            "Bansang",
            "Baro Kunda",
            "Barra",
            "Basse Santa Su",
            "Brifu",
            "Brikama",
            "Brikama Nding",
            "Bureng",
            "Central Baddibu",
            "Chilla",
            "Daba Kunda",
            "Dankunku",
            "Daru Rilwan",
            "Demba Kunda",
            "Denton",
            "Diabugu",
            "Diabugu Basilla",
            "Essau",
            "Farafenni",
            "Foni Bondali",
            "Foni Brefet",
            "Foni Jarrol",
            "Foni Kansala",
            "Fulladu East",
            "Fulladu West",
            "Galleh Manda",
            "Georgetown",
            "Gunjur",
            "Gunjur",
            "Gunjur Kuta",
            "Jakhaly",
            "Jali",
            "Janjanbureh",
            "Jarra Central",
            "Jarra East",
            "Jarra West",
            "Jarreng",
            "Jenoi",
            "Jifarong",
            "Jokadu",
            "Kaiaf",
            "Kantora",
            "Karantaba",
            "Karantaba",
            "Kass Wollof",
            "Katchang",
            "Keneba",
            "Kerewan",
            "Kiang Central",
            "Kiang East",
            "Kiang West District",
            "Koina",
            "Kombo Central District",
            "Kombo East District",
            "Kombo North District",
            "Kombo Saint Mary District",
            "Kombo South District",
            "Kumbija",
            "Kuntaur",
            "Kunting",
            "Lamin",
            "Lower Baddibu District",
            "Lower Niumi District",
            "Lower Saloum",
            "Mansa Konko",
            "Niamina East District",
            "Niamina West District",
            "Niani",
            "Nianija District",
            "Nioro",
            "No Kunda",
            "Nyamanari",
            "Pateh Sam",
            "Perai",
            "Saba",
            "Sabi",
            "Sami",
            "Sami District",
            "Sandu",
            "Sankwia",
            "Sara Kunda",
            "Saruja",
            "Serekunda",
            "Si Kunda",
            "Soma",
            "Somita",
            "Sudowol",
            "Sukuta",
            "Sukuta",
            "Sun Kunda",
            "Sutukoba",
            "Sutukung",
            "Toniataba",
            "Upper Baddibu",
            "Upper Niumi District",
            "Upper Saloum",
            "Wassu",
            "Wellingara Ba",
            "Wuli"
        ]
    },
    {
        "label": "Georgia",
        "code": "GE",
        "phone": "995",
        "cities": [
            "Abasha",
            "Adigeni",
            "Adigeni Municipality",
            "Agara",
            "Akhaldaba",
            "Akhaldaba",
            "Akhalgori",
            "Akhalk’alak’i",
            "Akhaltsikhe",
            "Akhaltsikhis Munitsip’alit’et’i",
            "Akhmet’a",
            "Akhmet’is Munitsip’alit’et’i",
            "Ambrolauri",
            "Ambrolauris Munitsip’alit’et’i",
            "Asp’indzis Munitsip’alit’et’i",
            "Aspindza",
            "Baghdatis Munitsip’alit’et’i",
            "Bakuriani",
            "Batumi",
            "Bich’vinta",
            "Bolnisi",
            "Bolnisis Munitsip’alit’et’i",
            "Borjomi",
            "Chakvi",
            "Chiat’ura",
            "Didi Lilo",
            "Dioknisi",
            "Dmanisis Munitsip’alit’et’i",
            "Dranda",
            "Dzegvi",
            "Gagra",
            "Gali",
            "Gantiadi",
            "Gardabani",
            "Gardabnis Munitsip’alit’et’i",
            "Gori",
            "Goris Munitsip’alit’et’i",
            "Gudauri",
            "Gudauta",
            "Gurjaani",
            "Java",
            "Jvari",
            "K’alak’i Chiat’ura",
            "K’ulashi",
            "Kaspi",
            "Kelasuri",
            "Kharagauli",
            "Khashuri",
            "Khelvachauri",
            "Khobi",
            "Khoni",
            "Khulo",
            "Kobuleti",
            "Kutaisi",
            "Kveda Chkhorots’q’u",
            "Lagodekhi",
            "Lanchkhuti",
            "Lent’ekhi",
            "Makhinjauri",
            "Manglisi",
            "Marneuli",
            "Marneulis Munitsip’alit’et’i",
            "Mart’vili",
            "Mest’ia",
            "Mest’iis Munitsip’alit’et’i",
            "Mtskheta",
            "Naghvarevi",
            "Naruja",
            "Ninotsminda",
            "Och’amch’ire",
            "Ochkhamuri",
            "Oni",
            "Orsant’ia",
            "Ozurgeti",
            "P’asanauri",
            "P’ot’i",
            "P’rimorsk’oe",
            "Qvareli",
            "Rust’avi",
            "Sach’khere",
            "Sagarejo",
            "Samtredia",
            "Senak’i",
            "Shorapani",
            "Shuakhevi",
            "Sighnaghi",
            "Sighnaghis Munitsip’alit’et’i",
            "Sokhumi",
            "Stantsiya Novyy Afon",
            "Step’antsminda",
            "Surami",
            "T’et’ri Tsqaro",
            "Tbilisi",
            "Telavi",
            "Tetrits’q’alos Munitsip’alit’et’i",
            "Tqibuli",
            "Tqvarch'eli",
            "Ts’alk’is Munitsip’alit’et’i",
            "Ts’khinvali",
            "Tsaghveri",
            "Tsalenjikha",
            "Tsalka",
            "Tsikhisdziri",
            "Tsinandali",
            "Tsnori",
            "Tsqaltubo",
            "Urek’i",
            "Vale",
            "Vani",
            "Zestap’oni",
            "Zhinvali",
            "Zugdidi"
        ]
    },
    {
        "label": "Germany",
        "code": "DE",
        "phone": "49",
        "cities": [
            "Aach",
            "Aach",
            "Aachen",
            "Aalen",
            "Abbesbüttel",
            "Abenberg",
            "Abensberg",
            "Absberg",
            "Abstatt",
            "Abtsdorf",
            "Abtsgmünd",
            "Achern",
            "Achim",
            "Achslach",
            "Achstetten",
            "Achtrup",
            "Adelberg",
            "Adelebsen",
            "Adelheidsdorf",
            "Adelmannsfelden",
            "Adelsdorf",
            "Adelsheim",
            "Adelshofen",
            "Adelsried",
            "Adelzhausen",
            "Adenau",
            "Adenbüttel",
            "Adendorf",
            "Adenstedt",
            "Adlershof",
            "Adlkofen",
            "Admannshagen-Bargeshagen",
            "Adorf",
            "Aerzen",
            "Affalterbach",
            "Affing",
            "Agathenburg",
            "Aglasterhausen",
            "Aham",
            "Ahaus",
            "Ahausen",
            "Ahlbeck",
            "Ahlden",
            "Ahlen",
            "Ahlerstedt",
            "Ahlsdorf",
            "Ahnsbeck",
            "Ahnsen",
            "Aholfing",
            "Aholming",
            "Ahorn",
            "Ahrbrück",
            "Ahrensbök",
            "Ahrensburg",
            "Ahsen-Oetzen",
            "Aichach",
            "Aichelberg",
            "Aichen",
            "Aichhalden",
            "Aichstetten",
            "Aidenbach",
            "Aidhausen",
            "Aidlingen",
            "Aiglsbach",
            "Aindling",
            "Ainring",
            "Aislingen",
            "Aitrach",
            "Aitrang",
            "Aken",
            "Albaching",
            "Albbruck",
            "Albersdorf",
            "Albershausen",
            "Albersweiler",
            "Albertshofen",
            "Albertstadt",
            "Albig",
            "Albisheim",
            "Albshausen",
            "Albstadt",
            "Aldenhoven",
            "Aldersbach",
            "Aldingen",
            "Alerheim",
            "Alesheim",
            "Aletshausen",
            "Alfdorf",
            "Alfeld",
            "Alfeld",
            "Alfhausen",
            "Alfter",
            "Algermissen",
            "Alheim",
            "Allendorf",
            "Allendorf an der Lahn",
            "Allensbach",
            "Allersberg",
            "Allershausen",
            "Alling",
            "Allmendingen",
            "Allmersbach im Tal",
            "Allstedt",
            "Alpen",
            "Alpenrod",
            "Alpirsbach",
            "Alsbach-Hähnlein",
            "Alsdorf",
            "Alsdorf",
            "Alsenz",
            "Alsfeld",
            "Alsheim",
            "Alsleben",
            "Alsterdorf",
            "Alt Duvenstedt",
            "Alt Meteln",
            "Alt Tucheband",
            "Alt Wallmoden",
            "Alt-Hohenschönhausen",
            "Alt-Sanitz",
            "Alt-Treptow",
            "Altbach",
            "Altdöbern",
            "Altdorf",
            "Altdorf",
            "Altefähr",
            "Alteglofsheim",
            "Alten Buseck",
            "Altena",
            "Altenahr",
            "Altenau",
            "Altenbeken",
            "Altenberg",
            "Altenberge",
            "Altenbuch",
            "Altenbüren",
            "Altenburg",
            "Altendiez",
            "Altendorf",
            "Altenfeld",
            "Altenglan",
            "Altengottern",
            "Altenholz",
            "Altenkirchen",
            "Altenkirchen",
            "Altenkrempe",
            "Altenkunstadt",
            "Altenmarkt",
            "Altenmedingen",
            "Altenmünster",
            "Altenpleen",
            "Altenriet",
            "Altenstadt",
            "Altenstadt",
            "Altensteig",
            "Altenthann",
            "Altentreptow",
            "Alterhofen",
            "Altes Lager",
            "Altfraunhofen",
            "Altglienicke",
            "Althegnenberg",
            "Althengstett",
            "Althütte",
            "Altkirchen",
            "Altlandsberg",
            "Altleiningen",
            "Altlußheim",
            "Altmittweida",
            "Altomünster",
            "Altona",
            "Altötting",
            "Altrich",
            "Altrip",
            "Altshausen",
            "Altstadt",
            "Altstadt Nord",
            "Altstadt Sud",
            "Altusried",
            "Alveslohe",
            "Alzenau in Unterfranken",
            "Alzey",
            "Amberg",
            "Amelinghausen",
            "Amendingen",
            "Amerang",
            "Ammerndorf",
            "Ammerthal",
            "Amöneburg",
            "Ampfing",
            "Amstetten",
            "Amtzell",
            "Andechs",
            "Andernach",
            "Angermünde",
            "Angern",
            "Angersdorf",
            "Anhausen",
            "Anklam",
            "Ankum",
            "Annaberg-Buchholz",
            "Annaburg",
            "Annweiler am Trifels",
            "Anröchte",
            "Anrode",
            "Ansbach",
            "Antdorf",
            "Anzing",
            "Apelern",
            "Apen",
            "Apensen",
            "Apfeldorf",
            "Apfelstädt",
            "Apolda",
            "Apollensdorf",
            "Appel",
            "Appen",
            "Appenheim",
            "Arberg",
            "Arenshausen",
            "Aresing",
            "Argenthal",
            "Armsheim",
            "Arnbruck",
            "Arneburg",
            "Arnsberg",
            "Arnschwang",
            "Arnstadt",
            "Arnstein",
            "Arnstorf",
            "Arpke",
            "Arrach",
            "Artern",
            "Artlenburg",
            "Arzbach",
            "Arzberg",
            "Arzberg",
            "Arzfeld",
            "Asbach",
            "Asbach-Bäumenheim",
            "Ascha",
            "Aschaffenburg",
            "Aschau am Inn",
            "Aschau im Chiemgau",
            "Ascheberg",
            "Ascheberg",
            "Aschersleben",
            "Aschheim",
            "Asendorf",
            "Aspach",
            "Asperg",
            "Assamstadt",
            "Asselfingen",
            "Aßlar",
            "Aßling",
            "Attendorn",
            "Attenhofen",
            "Attenkirchen",
            "Attenweiler",
            "Atting",
            "Atzendorf",
            "Au",
            "Au am Rhein",
            "Au in der Hallertau",
            "Aub",
            "Aue",
            "Auerbach",
            "Auerbach",
            "Auf der Horst",
            "Aufhausen",
            "Aufseß",
            "Auggen",
            "Augsburg",
            "Augustdorf",
            "Augustusburg",
            "Auhagen",
            "Auhausen",
            "Aukrug",
            "Auleben",
            "Aulendorf",
            "Auma",
            "Aura im Sinngrund",
            "Aurich",
            "Ausleben",
            "Außernzell",
            "Axstedt",
            "Aying",
            "Ayl",
            "Aystetten",
            "Baalberge",
            "Baar-Ebenhausen",
            "Babenhausen",
            "Babenhausen",
            "Babensham",
            "Bach",
            "Bacharach",
            "Bachhagel",
            "Bächingen an der Brenz",
            "Backnang",
            "Bad Abbach",
            "Bad Aibling",
            "Bad Alexandersbad",
            "Bad Arolsen",
            "Bad Bellingen",
            "Bad Belzig",
            "Bad Bentheim",
            "Bad Bergzabern",
            "Bad Berka",
            "Bad Berleburg",
            "Bad Berneck im Fichtelgebirge",
            "Bad Bevensen",
            "Bad Bibra",
            "Bad Birnbach",
            "Bad Blankenburg",
            "Bad Bocklet",
            "Bad Brambach",
            "Bad Bramstedt",
            "Bad Breisig",
            "Bad Brückenau",
            "Bad Buchau",
            "Bad Camberg",
            "Bad Ditzenbach",
            "Bad Doberan",
            "Bad Driburg",
            "Bad Düben",
            "Bad Dürkheim",
            "Bad Dürrenberg",
            "Bad Dürrheim",
            "Bad Eilsen",
            "Bad Elster",
            "Bad Ems",
            "Bad Endbach",
            "Bad Endorf",
            "Bad Essen",
            "Bad Fallingbostel",
            "Bad Feilnbach",
            "Bad Frankenhausen",
            "Bad Fredeburg",
            "Bad Freienwalde",
            "Bad Füssing",
            "Bad Gandersheim",
            "Bad Griesbach",
            "Bad Grund",
            "Bad Harzburg",
            "Bad Heilbrunn",
            "Bad Herrenalb",
            "Bad Hersfeld",
            "Bad Holzhausen",
            "Bad Homburg vor der Höhe",
            "Bad Honnef",
            "Bad Hönningen",
            "Bad Iburg",
            "Bad Karlshafen",
            "Bad Kissingen",
            "Bad Kleinen",
            "Bad Klosterlausnitz",
            "Bad Kohlgrub",
            "Bad König",
            "Bad Königshofen im Grabfeld",
            "Bad Kösen",
            "Bad Köstritz",
            "Bad Kreuznach",
            "Bad Laasphe",
            "Bad Laer",
            "Bad Langensalza",
            "Bad Lauchstädt",
            "Bad Lausick",
            "Bad Lauterberg im Harz",
            "Bad Liebenstein",
            "Bad Liebenwerda",
            "Bad Liebenzell",
            "Bad Lippspringe",
            "Bad Lobenstein",
            "Bad Marienberg",
            "Bad Meinberg",
            "Bad Mergentheim",
            "Bad Münder am Deister",
            "Bad Münster am Stein-Ebernburg",
            "Bad Münstereifel",
            "Bad Muskau",
            "Bad Nauheim",
            "Bad Nenndorf",
            "Bad Neuenahr-Ahrweiler",
            "Bad Neustadt an der Saale",
            "Bad Oeynhausen",
            "Bad Oldesloe",
            "Bad Orb",
            "Bad Peterstal-Griesbach",
            "Bad Pyrmont",
            "Bad Rappenau",
            "Bad Reichenhall",
            "Bad Rippoldsau-Schapbach",
            "Bad Rothenfelde",
            "Bad Saarow",
            "Bad Sachsa",
            "Bad Säckingen",
            "Bad Salzdetfurth",
            "Bad Salzschlirf",
            "Bad Salzuflen",
            "Bad Salzungen",
            "Bad Sassendorf",
            "Bad Schandau",
            "Bad Schlema",
            "Bad Schmiedeberg",
            "Bad Schussenried",
            "Bad Schwalbach",
            "Bad Schwartau",
            "Bad Segeberg",
            "Bad Soden am Taunus",
            "Bad Soden-Salmünster",
            "Bad Sooden-Allendorf",
            "Bad Staffelstein",
            "Bad Steben",
            "Bad Suderode",
            "Bad Sulza",
            "Bad Sülze",
            "Bad Teinach-Zavelstein",
            "Bad Tennstedt",
            "Bad Tölz",
            "Bad Überkingen",
            "Bad Urach",
            "Bad Vilbel",
            "Bad Waldsee",
            "Bad Wiessee",
            "Bad Wildbad",
            "Bad Wildungen",
            "Bad Wilsnack",
            "Bad Wimpfen",
            "Bad Windsheim",
            "Bad Wörishofen",
            "Bad Wurzach",
            "Bad Zwischenahn",
            "Badbergen",
            "Baddeckenstedt",
            "Badem",
            "Baden-Baden",
            "Badenhausen",
            "Badenweiler",
            "Baesweiler",
            "Bahlingen",
            "Bahrdorf",
            "Bahrenborstel",
            "Baienfurt",
            "Baierbrunn",
            "Baiersbronn",
            "Baiersdorf",
            "Baindt",
            "Baisweil",
            "Bakum",
            "Balge",
            "Balingen",
            "Balje",
            "Ballenstedt",
            "Baltmannsweiler",
            "Balve",
            "Balzfeld",
            "Balzhausen",
            "Bamberg",
            "Bamenohl",
            "Bammental",
            "Bann",
            "Bannewitz",
            "Banteln",
            "Banzkow",
            "Barbing",
            "Barby",
            "Barchfeld",
            "Bardowick",
            "Barenburg",
            "Barendorf",
            "Bärenstein",
            "Bargfeld-Stegen",
            "Bargstedt",
            "Bargteheide",
            "Bark",
            "Barkelsby",
            "Barleben",
            "Barmbek-Nord",
            "Barmstedt",
            "Bärnau",
            "Barnstädt",
            "Barnstorf",
            "Barntrup",
            "Barsbüttel",
            "Barsinghausen",
            "Barßel",
            "Bartenshagen-Parkentin",
            "Barth",
            "Bartholomä",
            "Barum",
            "Baruth",
            "Barver",
            "Barwedel",
            "Basdahl",
            "Bassenheim",
            "Bassum",
            "Bastheim",
            "Bastorf",
            "Battenberg",
            "Baudenbach",
            "Baumholder",
            "Baumschulenweg",
            "Baunach",
            "Baunatal",
            "Bausendorf",
            "Bautzen",
            "Bawinkel",
            "Bayenthal",
            "Bayerbach",
            "Bayerisch Eisenstein",
            "Bayerisch Gmain",
            "Bayreuth",
            "Bayrischzell",
            "Bebra",
            "Bechhofen",
            "Bechtheim",
            "Bechtolsheim",
            "Bechtsrieth",
            "Beckdorf",
            "Beckedorf",
            "Beckingen",
            "Beckum",
            "Bedburg",
            "Beedenbostel",
            "Beelen",
            "Beelitz",
            "Beerfelden",
            "Beesenlaublingen",
            "Beesenstedt",
            "Beeskow",
            "Beesten",
            "Beetzendorf",
            "Behringen",
            "Bei der Höhne",
            "Beierfeld",
            "Beiersdorf",
            "Beilrode",
            "Beilstein",
            "Beimerstetten",
            "Beindersheim",
            "Belgern",
            "Belgershain",
            "Bell",
            "Belleben",
            "Bellenberg",
            "Bellheim",
            "Belm",
            "Beltheim",
            "Bempflingen",
            "Bendestorf",
            "Bendorf",
            "Benediktbeuern",
            "Benndorf",
            "Benneckenstein",
            "Bennewitz",
            "Benningen",
            "Benningen am Neckar",
            "Bennstedt",
            "Bennungen",
            "Bensdorf",
            "Benshausen",
            "Bensheim",
            "Bentwisch",
            "Bentzin",
            "Beratzhausen",
            "Berching",
            "Berchtesgaden",
            "Berg",
            "Berg",
            "Berg im Gau",
            "Berga",
            "Berga",
            "Bergatreute",
            "Berge",
            "Bergedorf",
            "Bergen",
            "Bergen",
            "Bergen",
            "Bergen an der Dumme",
            "Bergen auf Rügen",
            "Berghaupten",
            "Bergheim",
            "Berghülen",
            "Bergisch Gladbach",
            "Bergkamen",
            "Bergkirchen",
            "Berglern",
            "Bergneustadt",
            "Bergrheinfeld",
            "Bergstedt",
            "Bergtheim",
            "Bergwitz",
            "Berkenbrück",
            "Berkenthin",
            "Berkheim",
            "Berlin",
            "Berlin Köpenick",
            "Berlin Treptow",
            "Berlingerode",
            "Berlstedt",
            "Bermatingen",
            "Bernau",
            "Bernau am Chiemsee",
            "Bernau bei Berlin",
            "Bernbeuren",
            "Bernburg",
            "Berngau",
            "Bernhardswald",
            "Bernitt",
            "Bernkastel-Kues",
            "Bernried",
            "Bernsbach",
            "Bernsdorf",
            "Bernstadt",
            "Bernstadt",
            "Bersenbrück",
            "Berstadt",
            "Berthelsdorf",
            "Bertsdorf-Hörnitz",
            "Berumbur",
            "Besigheim",
            "Bestensee",
            "Bestwig",
            "Betheln",
            "Bettingen",
            "Betzdorf",
            "Betzendorf",
            "Betzigau",
            "Beuern",
            "Beuna",
            "Beuren",
            "Beutelsbach",
            "Bevenrode",
            "Bevern",
            "Beverstedt",
            "Beverungen",
            "Bexbach",
            "Biberach",
            "Biberach an der Riß",
            "Biberbach",
            "Biblis",
            "Biburg",
            "Bichl",
            "Bickenbach",
            "Bidingen",
            "Biebelried",
            "Biebesheim",
            "Biedenkopf",
            "Biederitz",
            "Bielefeld",
            "Biendorf",
            "Bienenbüttel",
            "Biere",
            "Biesdorf",
            "Biesenthal",
            "Biessenhofen",
            "Bietigheim",
            "Bietigheim-Bissingen",
            "Bilderstoeckchen",
            "Billerbeck",
            "Billigheim",
            "Billigheim-Ingenheim",
            "Bilshausen",
            "Binau",
            "Bindlach",
            "Bingen",
            "Bingen am Rhein",
            "Binnen",
            "Binsfeld",
            "Binswangen",
            "Binzen",
            "Bippen",
            "Birenbach",
            "Birgte",
            "Birken-Honigsessen",
            "Birkenau",
            "Birkenfeld",
            "Birkenfeld",
            "Birkenfeld",
            "Birkenheide",
            "Birkenwerder",
            "Birlenbach",
            "Birresborn",
            "Birstein",
            "Bischberg",
            "Bischbrunn",
            "Bischoffen",
            "Bischofferode",
            "Bischofsgrün",
            "Bischofsheim",
            "Bischofsheim an der Rhön",
            "Bischofsmais",
            "Bischofswerda",
            "Bischofswiesen",
            "Bischweier",
            "Bisingen",
            "Bismark",
            "Bispingen",
            "Bissendorf",
            "Bissingen",
            "Bissingen an der Teck",
            "Bitburg",
            "Bitterfeld-Wolfen",
            "Bitz",
            "Blaibach",
            "Blaichach",
            "Blankenbach",
            "Blankenburg",
            "Blankenburg",
            "Blankenfelde",
            "Blankenhain",
            "Blankenheim",
            "Blankenheim",
            "Blankenrath",
            "Blankensee",
            "Blaubeuren",
            "Blaufelden",
            "Bleckede",
            "Bleialf",
            "Bleicherode",
            "Blekendorf",
            "Blender",
            "Bliedersdorf",
            "Bliesdorf",
            "Blieskastel",
            "Blindheim",
            "Blomberg",
            "Blomberg",
            "Blowatz",
            "Blumberg",
            "Bobbau",
            "Bobenheim-Roxheim",
            "Böbing",
            "Bobingen",
            "Böbingen an der Rems",
            "Bobitz",
            "Böblingen",
            "Böbrach",
            "Bocholt",
            "Bochum",
            "Bochum-Hordel",
            "Bockau",
            "Bockelwitz",
            "Bockenau",
            "Bockenem",
            "Bockenheim",
            "Bockhorn",
            "Bockhorn",
            "Bockhorst",
            "Bodelshausen",
            "Bodenfelde",
            "Bodenheim",
            "Bodenkirchen",
            "Bodenmais",
            "Bodenwerder",
            "Bodenwöhr",
            "Bodman-Ludwigshafen",
            "Bodnegg",
            "Bodolz",
            "Boffzen",
            "Bogen",
            "Bogenhausen",
            "Böhl-Iggelheim",
            "Böhlen",
            "Böhmenkirch",
            "Bohmte",
            "Bohnsdorf",
            "Boizenburg",
            "Bokel",
            "Bokholt-Hanredder",
            "Bolanden",
            "Bolheim",
            "Boll",
            "Bollendorf",
            "Bollingstedt",
            "Bollschweil",
            "Bolsterlang",
            "Bomlitz",
            "Bondorf",
            "Bönebüttel",
            "Bonefeld",
            "Bönen",
            "Bonn",
            "Bonndorf",
            "Bönnigheim",
            "Bönningstedt",
            "Bonstetten",
            "Boos",
            "Boostedt",
            "Bopfingen",
            "Boppard",
            "Bordelum",
            "Bordesholm",
            "Borgentreich",
            "Börger",
            "Börgerende-Rethwisch",
            "Borgfelde",
            "Borgholzhausen",
            "Borgstedt",
            "Borken",
            "Borken",
            "Borkheide",
            "Borkum",
            "Borkwalde",
            "Born",
            "Borna",
            "Borne",
            "Bornheim",
            "Bornheim",
            "Bornhöved",
            "Bornich",
            "Börnichen",
            "Börnsen",
            "Borrentin",
            "Borsdorf",
            "Börßum",
            "Borstel",
            "Borstel-Hohenraden",
            "Borstendorf",
            "Börtlingen",
            "Bosau",
            "Bösdorf",
            "Bösel",
            "Bösenbrunn",
            "Bösingen",
            "Bötersen",
            "Bothel",
            "Böttingen",
            "Bottrop",
            "Bötzingen",
            "Bous",
            "Bovenau",
            "Bovenden",
            "Boxberg",
            "Boxberg",
            "Brachbach",
            "Brackel",
            "Brackenheim",
            "Brahmenau",
            "Brake (Unterweser)",
            "Brakel",
            "Bramsche",
            "Bramstedt",
            "Brand",
            "Brand-Erbisdorf",
            "Brande-Hörnerkirchen",
            "Brandenburg an der Havel",
            "Brandis",
            "Brandshagen",
            "Brannenburg",
            "Braschwitz",
            "Braubach",
            "Brauneberg",
            "Braunfels",
            "Braunlage",
            "Bräunlingen",
            "Braunsbach",
            "Braunsbedra",
            "Braunschweig",
            "Breckerfeld",
            "Breddin",
            "Breddorf",
            "Bredenbek",
            "Bredstedt",
            "Breese",
            "Brehme",
            "Brehna",
            "Breidenbach",
            "Breiholz",
            "Breisach am Rhein",
            "Breitbrunn",
            "Breitenbach",
            "Breitenbach",
            "Breitenberg",
            "Breitenbrunn",
            "Breitenbrunn",
            "Breitenburg",
            "Breitenfelde",
            "Breitengüßbach",
            "Breitenthal",
            "Breitenworbis",
            "Breitnau",
            "Breitscheid",
            "Breitscheidt",
            "Breitungen",
            "Brekendorf",
            "Breklum",
            "Bremen",
            "Bremerhaven",
            "Bremervörde",
            "Brennberg",
            "Brensbach",
            "Bretten",
            "Bretzenheim",
            "Bretzfeld",
            "Breuna",
            "Brey",
            "Briedel",
            "Brieselang",
            "Briesen",
            "Brieskow-Finkenheerd",
            "Brietlingen",
            "Brilon",
            "Britten",
            "Britz",
            "Britz",
            "Brockel",
            "Brockum",
            "Broderstorf",
            "Brohl-Lützing",
            "Brokdorf",
            "Brokstedt",
            "Brome",
            "Bromskirchen",
            "Brotterode",
            "Bruchhausen-Vilsen",
            "Bruchköbel",
            "Bruchmühlbach-Miesau",
            "Bruchsal",
            "Bruchweiler-Bärenbach",
            "Bruck",
            "Brück",
            "Bruck in der Oberpfalz",
            "Bruckberg",
            "Brücken",
            "Bruckmühl",
            "Brüel",
            "Brügge",
            "Brüggen",
            "Brüggen",
            "Brühl",
            "Brühl",
            "Brumby",
            "Brunn",
            "Brunn",
            "Brunnen",
            "Brunnthal",
            "Brunsbüttel",
            "Brüsewitz",
            "Brüssow",
            "Bruttig-Fankel",
            "Bubenreuth",
            "Bubesheim",
            "Bubsheim",
            "Buch",
            "Buch",
            "Buch am Ahorn",
            "Buch am Buchrain",
            "Bucha",
            "Buchbach",
            "Buchbrunn",
            "Buchdorf",
            "Büchel",
            "Büchen",
            "Buchen in Odenwald",
            "Buchenbach",
            "Büchenbach",
            "Buchenberg",
            "Büchenbeuren",
            "Buchforst",
            "Buchheim",
            "Buchholz",
            "Buchholz",
            "Buchholz in der Nordheide",
            "Büchlberg",
            "Buchloe",
            "Bückeburg",
            "Bücken",
            "Buckenhof",
            "Buckow",
            "Buckow",
            "Büddenstedt",
            "Büdelsdorf",
            "Budenheim",
            "Büdingen",
            "Bufleben",
            "Buggingen",
            "Bühl",
            "Bühlertal",
            "Bühlertann",
            "Bühlerzell",
            "Bühren",
            "Bullay",
            "Bunde",
            "Bünde",
            "Bundenbach",
            "Bundenthal",
            "Burbach",
            "Büren",
            "Burg",
            "Burg",
            "Burg auf Fehmarn",
            "Burg bei Magdeburg",
            "Burg Stargard",
            "Burgau",
            "Burgberg",
            "Burgbernheim",
            "Burgbrohl",
            "Burgdorf",
            "Burgebrach",
            "Bürgel",
            "Burggen",
            "Burghaslach",
            "Burghaun",
            "Burghausen",
            "Burgheim",
            "Burgkirchen an der Alz",
            "Burgkunstadt",
            "Burglauer",
            "Burglengenfeld",
            "Burglesum",
            "Burgoberbach",
            "Burgpreppach",
            "Burgrieden",
            "Burgsalach",
            "Burgschwalbach",
            "Burgsinn",
            "Burgsolms",
            "Burgstädt",
            "Bürgstadt",
            "Burgstetten",
            "Burgthann",
            "Burgwerben",
            "Burgwindheim",
            "Burk",
            "Burkardroth",
            "Burkau",
            "Burkhardtsdorf",
            "Burladingen",
            "Burow",
            "Burscheid",
            "Bürstadt",
            "Burtenbach",
            "Busdorf",
            "Busenberg",
            "Büsingen",
            "Büsum",
            "Butjadingen",
            "Büttelborn",
            "Buttelstedt",
            "Buttenheim",
            "Buttenwiesen",
            "Bütthard",
            "Buttlar",
            "Buttstädt",
            "Büttstedt",
            "Butzbach",
            "Bützow",
            "Buxheim",
            "Buxtehude",
            "Cadenberge",
            "Cadolzburg",
            "Calau",
            "Calbe",
            "Calberlah",
            "Calden",
            "Callenberg",
            "Calvörde",
            "Calw",
            "Camburg",
            "Cappeln",
            "Carlow",
            "Carlsberg",
            "Carpin",
            "Casekow",
            "Castrop-Rauxel",
            "Cavertitz",
            "Celle",
            "Cham",
            "Chamerau",
            "Charlottenburg",
            "Charlottenburg-Nord",
            "Chemnitz",
            "Chieming",
            "Chorin",
            "Chostlarn",
            "Clausen",
            "Claußnitz",
            "Clausthal-Zellerfeld",
            "Cleebronn",
            "Clenze",
            "Clingen",
            "Cloppenburg",
            "Coburg",
            "Cochem",
            "Cochstedt",
            "Coesfeld",
            "Cölbe",
            "Colbitz",
            "Colditz",
            "Colmberg",
            "Contwig",
            "Coppenbrügge",
            "Cornberg",
            "Coswig",
            "Coswig",
            "Cottbus",
            "Crailsheim",
            "Crawinkel",
            "Creglingen",
            "Cremlingen",
            "Creußen",
            "Creuzburg",
            "Crimmitschau",
            "Crinitz",
            "Crivitz",
            "Crostau",
            "Crostwitz",
            "Crottendorf",
            "Cunewalde",
            "Cuxhaven",
            "Daaden",
            "Dabel",
            "Dachau",
            "Dachsenhausen",
            "Dachwig",
            "Dägeling",
            "Dahlem",
            "Dahlem",
            "Dahlen",
            "Dahlenburg",
            "Dahlenwarsleben",
            "Dahme",
            "Dahme",
            "Dahn",
            "Daisendorf",
            "Dalheim",
            "Dallgow-Döberitz",
            "Damme",
            "Damp",
            "Dänischenhagen",
            "Dankmarshausen",
            "Danndorf",
            "Dannenberg",
            "Dannewerk",
            "Dannstadt-Schauernheim",
            "Dargun",
            "Darlingerode",
            "Darmstadt",
            "Dasing",
            "Dassel",
            "Dassendorf",
            "Dassow",
            "Datteln",
            "Dattenberg",
            "Dauchingen",
            "Daun",
            "Dausenau",
            "Deckenpfronn",
            "Dedelstorf",
            "Deensen",
            "Deggendorf",
            "Deggingen",
            "Dehrn",
            "Deidesheim",
            "Deilingen",
            "Deining",
            "Deiningen",
            "Deinste",
            "Deisenhausen",
            "Deißlingen",
            "Deizisau",
            "Delbrück",
            "Delingsdorf",
            "Delitzsch",
            "Dellfeld",
            "Delligsen",
            "Delmenhorst",
            "Demen",
            "Demitz-Thumitz",
            "Demmin",
            "Denkendorf",
            "Denkingen",
            "Denklingen",
            "Dennheritz",
            "Dentlein am Forst",
            "Denzlingen",
            "Derenburg",
            "Dermbach",
            "Dernau",
            "Dernbach",
            "Derschen",
            "Dersekow",
            "Dersum",
            "Dessau",
            "Dessau-Roßlau",
            "Destedt",
            "Detern",
            "Detmerode",
            "Detmold",
            "Dettelbach",
            "Dettenhausen",
            "Dettighofen",
            "Dettingen",
            "Dettingen an der Erms",
            "Dettingen unter Teck",
            "Dettum",
            "Deuben",
            "Deuerling",
            "Deuna",
            "Deutsch Evern",
            "Deutz",
            "Deutzen",
            "Dexheim",
            "Didderse",
            "Diebach",
            "Dieblich",
            "Dieburg",
            "Diedorf",
            "Diekholzen",
            "Dielheim",
            "Dienheim",
            "Diepholz",
            "Dierdorf",
            "Dierkow-Neu",
            "Dierkow-West",
            "Diesdorf",
            "Dieskau",
            "Diespeck",
            "Dießen am Ammersee",
            "Dietenheim",
            "Dietenhofen",
            "Dietersburg",
            "Dietersheim",
            "Dieterskirchen",
            "Dietfurt",
            "Dietingen",
            "Dietmannsried",
            "Dietramszell",
            "Dietzenbach",
            "Diez",
            "Dillenburg",
            "Dillingen",
            "Dillingen an der Donau",
            "Dingelstädt",
            "Dingolfing",
            "Dingolshausen",
            "Dinkelsbühl",
            "Dinkelscherben",
            "Dinklage",
            "Dinslaken",
            "Dippach",
            "Dipperz",
            "Dippoldiswalde",
            "Dirlewang",
            "Dirmstein",
            "Dischingen",
            "Dissen",
            "Ditfurt",
            "Dittelbrunn",
            "Dittelsheim-Heßloch",
            "Dittenheim",
            "Ditzingen",
            "Dobbertin",
            "Dobel",
            "Döbeln",
            "Doberlug-Kirchhain",
            "Döbernitz",
            "Doberschütz",
            "Dobersdorf",
            "Dobien",
            "Dogern",
            "Döhlau",
            "Dohma",
            "Dohna",
            "Dohren",
            "Dölbau",
            "Dollbergen",
            "Dollern",
            "Dollerup",
            "Döllnitz",
            "Döllstädt",
            "Dombühl",
            "Domersleben",
            "Dömitz",
            "Dommershausen",
            "Dommitzsch",
            "Domsühl",
            "Donaueschingen",
            "Donaustauf",
            "Donauwörth",
            "Donnersdorf",
            "Donzdorf",
            "Dörentrup",
            "Dorfchemnitz",
            "Dorfen",
            "Dorfhain",
            "Dorfprozelten",
            "Dormagen",
            "Dormettingen",
            "Dormitz",
            "Dorndorf",
            "Dornhan",
            "Dornstadt",
            "Dornstetten",
            "Dornum",
            "Dörpen",
            "Dörrenbach",
            "Dorsten",
            "Dortmund",
            "Dorum",
            "Dörverden",
            "Dörzbach",
            "Dossenheim",
            "Dötlingen",
            "Dotternhausen",
            "Drachselsried",
            "Drage",
            "Drakenburg",
            "Drangstedt",
            "Dransfeld",
            "Dranske",
            "Drebach",
            "Drebkau",
            "Dreetz",
            "Dreieich",
            "Dreierwalde",
            "Dreikirchen",
            "Dreis",
            "Dreisen",
            "Drelsdorf",
            "Drensteinfurt",
            "Drentwede",
            "Dresden",
            "Driedorf",
            "Drochtersen",
            "Drolshagen",
            "Droyßig",
            "Drübeck",
            "Ducherow",
            "Dudeldorf",
            "Dudenhofen",
            "Duderstadt",
            "Duggendorf",
            "Duingen",
            "Duisburg",
            "Dülmen",
            "Dümmer",
            "Dummerstorf",
            "Düngenheim",
            "Dunningen",
            "Dünsen",
            "Dunum",
            "Durach",
            "Durbach",
            "Dürbheim",
            "Düren",
            "Durlangen",
            "Dürmentingen",
            "Durmersheim",
            "Dürnau",
            "Dürrhennersdorf",
            "Dürrlauingen",
            "Dürrröhrsdorf",
            "Dürrwangen",
            "Düsseldorf",
            "Düsseldorf District",
            "Düsseldorf-Pempelfort",
            "Dußlingen",
            "Duvenstedt",
            "Ebeleben",
            "Ebelsbach",
            "Ebendorf",
            "Ebensfeld",
            "Ebenweiler",
            "Eberbach",
            "Eberdingen",
            "Ebergötzen",
            "Eberhardzell",
            "Ebermannsdorf",
            "Ebermannstadt",
            "Ebernhahn",
            "Ebersbach",
            "Ebersbach an der Fils",
            "Ebersbach-Musbach",
            "Ebersberg",
            "Ebersdorf",
            "Ebersdorf",
            "Eberstadt",
            "Eberswalde",
            "Ebertsheim",
            "Ebhausen",
            "Ebnath",
            "Ebringen",
            "Ebstorf",
            "Echem",
            "Eching",
            "Echzell",
            "Eckartsberga",
            "Eckernförde",
            "Eckersdorf",
            "Eddelak",
            "Edderitz",
            "Edelsfeld",
            "Edemissen",
            "Edenkoben",
            "Ederheim",
            "Edersleben",
            "Edesheim",
            "Edewecht",
            "Ediger-Eller",
            "Edingen-Neckarhausen",
            "Effelder",
            "Effeltrich",
            "Efringen-Kirchen",
            "Egeln",
            "Egelsbach",
            "Egenhausen",
            "Egenhofen",
            "Egestorf",
            "Egg an der Günz",
            "Eggebek",
            "Eggenfelden",
            "Eggenstein-Leopoldshafen",
            "Eggenthal",
            "Eggermühlen",
            "Eggersdorf",
            "Eggesin",
            "Eggingen",
            "Egglham",
            "Egglkofen",
            "Eggolsheim",
            "Eggstätt",
            "Eging",
            "Egling",
            "Egloffstein",
            "Egmating",
            "Ehekirchen",
            "Ehingen",
            "Ehingen",
            "Ehlscheid",
            "Ehningen",
            "Ehrenburg",
            "Ehrenfriedersdorf",
            "Ehringshausen",
            "Eibau",
            "Eibelstadt",
            "Eibenstock",
            "Eichenau",
            "Eichenbarleben",
            "Eichenbühl",
            "Eichendorf",
            "Eichenried",
            "Eichenzell",
            "Eichigt",
            "Eichstätt",
            "Eichstetten",
            "Eichwalde",
            "Eickendorf",
            "Eicklingen",
            "Eidelstedt",
            "Eigeltingen",
            "Eil",
            "Eilenburg",
            "Eilsleben",
            "Eime",
            "Eimeldingen",
            "Eimen",
            "Eimke",
            "Eimsbüttel",
            "Einbeck",
            "Einhausen",
            "Eisdorf am Harz",
            "Eiselfing",
            "Eisenach",
            "Eisenbach",
            "Eisenberg",
            "Eisenberg",
            "Eisenburg",
            "Eisenhüttenstadt",
            "Eisfeld",
            "Eisingen",
            "Eisingen",
            "Eisleben Lutherstadt",
            "Eislingen",
            "Eitelborn",
            "Eiterfeld",
            "Eitorf",
            "Eitting",
            "Elbe",
            "Elbingerode",
            "Eldena",
            "Eldingen",
            "Elfershausen",
            "Elgersburg",
            "Elkenroth",
            "Ellefeld",
            "Ellenberg",
            "Ellerau",
            "Ellerbek",
            "Ellerhoop",
            "Ellerstadt",
            "Ellgau",
            "Ellhofen",
            "Ellingen",
            "Ellrich",
            "Ellwangen",
            "Ellzee",
            "Elmenhorst",
            "Elmenhorst",
            "Elmshorn",
            "Elmstein",
            "Elsdorf",
            "Elsdorf",
            "Elsdorf-Westermühlen",
            "Elsendorf",
            "Elsenfeld",
            "Elsfleth",
            "Elsnig",
            "Elster",
            "Elsterberg",
            "Elstertrebnitz",
            "Elsterwerda",
            "Elstra",
            "Elterlein",
            "Eltmann",
            "Eltville",
            "Elxleben",
            "Elz",
            "Elzach",
            "Elze",
            "Embsen",
            "Emden",
            "Emersacker",
            "Emkendorf",
            "Emlichheim",
            "Emmelsbüll-Horsbüll",
            "Emmelshausen",
            "Emmendingen",
            "Emmerich",
            "Emmering",
            "Emmingen-Liptingen",
            "Empfingen",
            "Emsbüren",
            "Emsdetten",
            "Emskirchen",
            "Emstek",
            "Emtinghausen",
            "Emtmannsberg",
            "Endingen",
            "Engeln",
            "Engelsberg",
            "Engelsbrand",
            "Engelskirchen",
            "Engelthal",
            "Engen",
            "Enger",
            "Eningen unter Achalm",
            "Enkenbach-Alsenborn",
            "Enkirch",
            "Ennepetal",
            "Ennetach",
            "Ennigerloh",
            "Ensdorf",
            "Ensdorf",
            "Enzklösterle",
            "Epfenbach",
            "Epfendorf",
            "Eppelborn",
            "Eppelheim",
            "Eppelsheim",
            "Eppenbrunn",
            "Eppendorf",
            "Eppertshausen",
            "Eppingen",
            "Eppishausen",
            "Eppstein",
            "Erbach",
            "Erbach",
            "Erbendorf",
            "Erbes-Büdesheim",
            "Erdeborn",
            "Erding",
            "Erdmannhausen",
            "Erdweg",
            "Eresing",
            "Erfde",
            "Erftstadt",
            "Erfurt",
            "Erfweiler",
            "Ergersheim",
            "Ergolding",
            "Ergoldsbach",
            "Ering",
            "Eriskirch",
            "Erkelenz",
            "Erkenbrechtsweiler",
            "Erkerode",
            "Erkheim",
            "Erkner",
            "Erkrath",
            "Erlabrunn",
            "Erlangen",
            "Erlau",
            "Erlbach",
            "Erlbach",
            "Erlenbach",
            "Erlenbach",
            "Erlenbach am Main",
            "Erlenmoos",
            "Erlensee",
            "Erligheim",
            "Ermlitz",
            "Erndtebrück",
            "Ernsgaden",
            "Ernstroda",
            "Erolzheim",
            "Erpel",
            "Erpolzheim",
            "Ersingen",
            "Ertingen",
            "Erwitte",
            "Erxleben",
            "Erzhausen",
            "Eschach",
            "Eschau",
            "Eschborn",
            "Escheburg",
            "Eschede",
            "Eschelbronn",
            "Eschenbach",
            "Eschenlohe",
            "Eschershausen",
            "Eschlkam",
            "Eschwege",
            "Eschweiler",
            "Esens",
            "Eslarn",
            "Eslohe",
            "Espelkamp",
            "Espenau",
            "Espenhain",
            "Essel",
            "Esselbach",
            "Essen",
            "Essen",
            "Essenbach",
            "Essenheim",
            "Essing",
            "Essingen",
            "Essingen",
            "Esslingen",
            "Estenfeld",
            "Esterwegen",
            "Esthal",
            "Estorf",
            "Ettenheim",
            "Ettlingen",
            "Ettringen",
            "Ettringen",
            "Etzbach",
            "Etzelwang",
            "Etzenricht",
            "Euerbach",
            "Euerdorf",
            "Eurasburg",
            "Euskirchen",
            "Eußenheim",
            "Eutin",
            "Eutingen an der Enz",
            "Everswinkel",
            "Evessen",
            "Eydelstedt",
            "Eyendorf",
            "Eystrup",
            "Fachbach",
            "Fahrdorf",
            "Fahrenbach",
            "Fahrenkrug",
            "Fahrenzhausen",
            "Faid",
            "Falkenau",
            "Falkenberg",
            "Falkenberg",
            "Falkenberg",
            "Falkenfels",
            "Falkenhagener Feld",
            "Falkenhain",
            "Falkensee",
            "Falkenstein",
            "Falkenstein",
            "Fambach",
            "Farchant",
            "Farmsen-Berne",
            "Farnstädt",
            "Faßberg",
            "Faulbach",
            "Fedderwarden",
            "Fehmarn",
            "Fehrbellin",
            "Feilbingert",
            "Feilitzsch",
            "Feldafing",
            "Feldberg",
            "Felde",
            "Feldkirchen",
            "Feldkirchen-Westerham",
            "Feldstadt",
            "Fell",
            "Fellbach",
            "Fellheim",
            "Felm",
            "Felsberg",
            "Fennpfuhl",
            "Ferdinandshof",
            "Feucht",
            "Feuchtwangen",
            "Fichtelberg",
            "Fichtenberg",
            "Fichtenwalde",
            "Filderstadt",
            "Filsum",
            "Finnentrop",
            "Finningen",
            "Finsing",
            "Finsterbergen",
            "Finsterwalde",
            "Fintel",
            "Fischach",
            "Fischbach",
            "Fischbachau",
            "Fischerbach",
            "Flachslanden",
            "Flacht",
            "Fladungen",
            "Flammersfeld",
            "Flechtingen",
            "Fleckeby",
            "Flein",
            "Flensburg",
            "Flessau",
            "Flieden",
            "Flintbek",
            "Flintsbach",
            "Flöha",
            "Flomborn",
            "Flonheim",
            "Flörsheim",
            "Florstadt",
            "Floß",
            "Flossenbürg",
            "Flöthe",
            "Fockbek",
            "Föhren",
            "Forbach",
            "Forchheim",
            "Forchheim",
            "Forchtenberg",
            "Förderstedt",
            "Föritz",
            "Forst",
            "Forst",
            "Forstinning",
            "Framersheim",
            "Frammersbach",
            "Frankenau",
            "Frankenberg",
            "Frankenberg",
            "Frankenheim",
            "Frankenstein",
            "Frankenstein",
            "Frankenthal",
            "Frankenthal",
            "Frankenwinheim",
            "Frankfurt (Oder)",
            "Frankfurt am Main",
            "Fränkisch-Crumbach",
            "Frankleben",
            "Franzburg",
            "Französisch Buchholz",
            "Frasdorf",
            "Frauenau",
            "Fraueneuharting",
            "Frauenprießnitz",
            "Frauenstein",
            "Frauenwald",
            "Fraunberg",
            "Fraureuth",
            "Frechen",
            "Freckenfeld",
            "Freden",
            "Frei-Laubersheim",
            "Freiberg",
            "Freiberg am Neckar",
            "Freiburg",
            "Freiburg",
            "Freiburg Region",
            "Freienbessingen",
            "Freiensteinau",
            "Freienwill",
            "Freihung",
            "Freilassing",
            "Freinsheim",
            "Freisbach",
            "Freisen",
            "Freising",
            "Freital",
            "Fremdingen",
            "Frensdorf",
            "Freren",
            "Freudenberg",
            "Freudenberg",
            "Freudenberg",
            "Freudenburg",
            "Freudenstadt",
            "Freudental",
            "Freyburg",
            "Freystadt",
            "Freyung",
            "Frickenhausen",
            "Frickenhausen",
            "Frickingen",
            "Fridingen an der Donau",
            "Fridolfing",
            "Friedberg",
            "Friedberg",
            "Friedeburg",
            "Friedelsheim",
            "Friedenau",
            "Friedenfels",
            "Friedenweiler",
            "Friedersdorf",
            "Friedersdorf",
            "Friedewald",
            "Friedewald",
            "Friedland",
            "Friedland",
            "Friedland",
            "Friedrichroda",
            "Friedrichsbrunn",
            "Friedrichsdorf",
            "Friedrichsfelde",
            "Friedrichshafen",
            "Friedrichshagen",
            "Friedrichshain",
            "Friedrichskoog",
            "Friedrichstadt",
            "Friedrichstadt",
            "Friedrichsthal",
            "Frielendorf",
            "Friemar",
            "Friesack",
            "Friesenhagen",
            "Friesenried",
            "Friesoythe",
            "Friolzheim",
            "Frittlingen",
            "Fritzlar",
            "Frohburg",
            "Frohnau",
            "Fröndenberg",
            "Fronhausen",
            "Fronreute",
            "Frontenhausen",
            "Frose",
            "Fuchsmühl",
            "Fuchsstadt",
            "Fuhlsbüttel",
            "Fulda",
            "Fuldatal",
            "Fünfstetten",
            "Fürfeld",
            "Fürstenau",
            "Fürstenberg",
            "Fürstenberg",
            "Fürstenberg",
            "Fürsteneck",
            "Fürstenfeldbruck",
            "Fürstenhausen",
            "Fürstenstein",
            "Fürstenwalde",
            "Fürstenzell",
            "Furth",
            "Fürth",
            "Furth im Wald",
            "Fürthen",
            "Furtwangen",
            "Füssen",
            "Fußgönheim",
            "Gablenz",
            "Gablingen",
            "Gachenbach",
            "Gadebusch",
            "Gadheim",
            "Gägelow",
            "Gaggenau",
            "Gaiberg",
            "Gaienhofen",
            "Gaildorf",
            "Gailingen",
            "Gaimersheim",
            "Gaißach",
            "Gallus",
            "Gammelsdorf",
            "Gammelshausen",
            "Gammertingen",
            "Ganderkesee",
            "Gangelt",
            "Gangkofen",
            "Gangloffsömmern",
            "Garbsen",
            "Garbsen-Mitte",
            "Garching an der Alz",
            "Garching bei München",
            "Gardelegen",
            "Garding",
            "Garlstorf",
            "Garmisch-Partenkirchen",
            "Garrel",
            "Gars",
            "Garstedt",
            "Gartenstadt",
            "Gartow",
            "Gärtringen",
            "Gartz",
            "Garz",
            "Gatersleben",
            "Gatow",
            "Gattendorf",
            "Gau-Algesheim",
            "Gau-Bickelheim",
            "Gau-Bischofsheim",
            "Gau-Odernheim",
            "Gaukönigshofen",
            "Gauting",
            "Gebesee",
            "Gebhardshain",
            "Gebsattel",
            "Gechingen",
            "Gedern",
            "Geeste",
            "Geesthacht",
            "Gefrees",
            "Gehrde",
            "Gehrden",
            "Gehren",
            "Geiersthal",
            "Geilenkirchen",
            "Geisa",
            "Geiselbach",
            "Geiselhöring",
            "Geiselwind",
            "Geisenfeld",
            "Geisenhausen",
            "Geisenheim",
            "Geising",
            "Geisingen",
            "Geisleden",
            "Geislingen",
            "Geislingen an der Steige",
            "Geismar",
            "Geithain",
            "Gelbensande",
            "Geldern",
            "Geldersheim",
            "Gelenau",
            "Gelnhausen",
            "Gelsenkirchen",
            "Geltendorf",
            "Gelting",
            "Gemeinde Friedland",
            "Gemmingen",
            "Gemmrigheim",
            "Gemünden",
            "Gemünden am Main",
            "Gemünden an der Wohra",
            "Genderkingen",
            "Gengenbach",
            "Gensingen",
            "Genthin",
            "Georgenberg",
            "Georgensgmünd",
            "Georgenthal",
            "Georgsdorf",
            "Georgsmarienhütte",
            "Gera",
            "Geraberg",
            "Gerabronn",
            "Gerach",
            "Gerbrunn",
            "Gerbstedt",
            "Gerdau",
            "Geretsried",
            "Gerhardshofen",
            "Geringswalde",
            "Gerlingen",
            "Germering",
            "Germersheim",
            "Gernrode",
            "Gernrode",
            "Gernsbach",
            "Gernsheim",
            "Geroldsgrün",
            "Geroldshausen",
            "Gerolfingen",
            "Gerolsbach",
            "Gerolsheim",
            "Gerolstein",
            "Gerolzhofen",
            "Gersdorf",
            "Gersfeld",
            "Gersheim",
            "Gersten",
            "Gerstetten",
            "Gersthofen",
            "Gerstungen",
            "Gerswalde",
            "Gerwisch",
            "Gerzen",
            "Gescher",
            "Geschwenda",
            "Gesees",
            "Geseke",
            "Geslau",
            "Gessertshausen",
            "Gestratz",
            "Gesundbrunnen",
            "Gettorf",
            "Geusa",
            "Gevelsberg",
            "Geyer",
            "Giebelstadt",
            "Gieboldehausen",
            "Giekau",
            "Gielow",
            "Giengen an der Brenz",
            "Giersleben",
            "Giesen",
            "Gießen",
            "Gifhorn",
            "Gilching",
            "Gillenfeld",
            "Gilserberg",
            "Gilten",
            "Gimbsheim",
            "Gingen an der Fils",
            "Gingst",
            "Ginsheim-Gustavsburg",
            "Girod",
            "Gittelde",
            "Gladbeck",
            "Gladenbach",
            "Glan-Münchweiler",
            "Glandorf",
            "Glashütte",
            "Glashütten",
            "Glashütten",
            "Glattbach",
            "Glatten",
            "Glaubitz",
            "Glauburg",
            "Glauchau",
            "Gleichamberg",
            "Glienicke",
            "Glinde",
            "Glindenberg",
            "Glonn",
            "Glöthe",
            "Glött",
            "Glowe",
            "Glücksburg",
            "Glückstadt",
            "Gmund am Tegernsee",
            "Gnarrenburg",
            "Gnoien",
            "Gnutz",
            "Goch",
            "Gochsheim",
            "Göda",
            "Goel",
            "Göggingen",
            "Goldbach",
            "Goldbach",
            "Goldbeck",
            "Goldberg",
            "Goldenstedt",
            "Goldkronach",
            "Göllheim",
            "Golmbach",
            "Golßen",
            "Golzow",
            "Gomadingen",
            "Gomaringen",
            "Gommern",
            "Gommersheim",
            "Gondelsheim",
            "Gondershausen",
            "Gönnheim",
            "Göppingen",
            "Görisried",
            "Görlitz",
            "Görmin",
            "Gornau",
            "Gornsdorf",
            "Görsbach",
            "Görwihl",
            "Görzig",
            "Görzke",
            "Goseck",
            "Gosheim",
            "Goslar",
            "Gössenheim",
            "Gossersweiler-Stein",
            "Gößnitz",
            "Gößweinstein",
            "Goßwitz",
            "Gotha",
            "Gottenheim",
            "Gotteszell",
            "Gottfrieding",
            "Göttingen",
            "Gottmadingen",
            "Graben",
            "Graben-Neudorf",
            "Grabenstätt",
            "Grabenstetten",
            "Grabow",
            "Grabowhöfe",
            "Grabsleben",
            "Gräfelfing",
            "Grafenau",
            "Grafenau",
            "Gräfenberg",
            "Gräfendorf",
            "Grafengehaig",
            "Gräfenhain",
            "Gräfenhainichen",
            "Grafenhausen",
            "Grafenrheinfeld",
            "Gräfenroda",
            "Grafenwiesen",
            "Grafenwöhr",
            "Grafhorst",
            "Grafing bei München",
            "Grafrath",
            "Grainau",
            "Grainet",
            "Gramkow",
            "Gramzow",
            "Granschütz",
            "Gransee",
            "Grasberg",
            "Grasbrunn",
            "Grasleben",
            "Grassau",
            "Grattersdorf",
            "Grävenwiesbach",
            "Grebenau",
            "Grebenhain",
            "Grebenstein",
            "Grebin",
            "Greding",
            "Greffern",
            "Grefrath",
            "Greifenberg",
            "Greifenstein",
            "Greifswald",
            "Greiling",
            "Greimerath",
            "Greiz",
            "Gremberghoven",
            "Gremersdorf",
            "Gremsdorf",
            "Grenzach-Wyhlen",
            "Greppin",
            "Grettstadt",
            "Greußen",
            "Greußenheim",
            "Greven",
            "Grevenbroich",
            "Grevesmühlen",
            "Gries",
            "Griesheim",
            "Griesstätt",
            "Grimma",
            "Grimmen",
            "Gröbenzell",
            "Gröbers",
            "Gröbzig",
            "Gröden",
            "Groitzsch",
            "Grolsheim",
            "Grömitz",
            "Gronau",
            "Gronau",
            "Gröningen",
            "Grönwohld",
            "Gropiusstadt",
            "Groß Ammensleben",
            "Groß Börnecke",
            "Groß Düben",
            "Groß Grönau",
            "Groß Ippener",
            "Groß Kiesow",
            "Groß Köris",
            "Groß Kreutz",
            "Groß Kummerfeld",
            "Groß Laasch",
            "Groß Lindow",
            "Groß Miltzow",
            "Groß Munzel",
            "Groß Nemerow",
            "Groß Oesingen",
            "Groß Pankow",
            "Groß Quenstedt",
            "Groß Rheide",
            "Groß Rodensleben",
            "Groß Rosenburg",
            "Groß Santersleben",
            "Groß Twülpstedt",
            "Groß Wittensee",
            "Groß Wokern",
            "Groß-Bieberau",
            "Groß-Gerau",
            "Groß-Rohrheim",
            "Groß-Umstadt",
            "Groß-Zimmern",
            "Großaitingen",
            "Großalmerode",
            "Großbardau",
            "Großbardorf",
            "Großbartloff",
            "Großbeeren",
            "Großbettlingen",
            "Großbodungen",
            "Großbothen",
            "Großbottwar",
            "Großbreitenbach",
            "Großdubrau",
            "Großeibstadt",
            "Grosselfingen",
            "Großenaspe",
            "Grossenbrode",
            "Großenehrich",
            "Großengottern",
            "Großenhain",
            "Großenkneten",
            "Großenlüder",
            "Großensee",
            "Großenseebach",
            "Großenstein",
            "Großenwiehe",
            "Großerlach",
            "Großgoltern",
            "Großhabersdorf",
            "Großhansdorf",
            "Großharthau",
            "Großhartmannsdorf",
            "Großheirath",
            "Großhennersdorf",
            "Großheubach",
            "Großholbach",
            "Großkarlbach",
            "Großkarolinenfeld",
            "Großkayna",
            "Großkmehlen",
            "Großkorbetha",
            "Großkrotzenburg",
            "Großkugel",
            "Großlangheim",
            "Großlehna",
            "Großlittgen",
            "Großmaischeid",
            "Großmonra",
            "Großnaundorf",
            "Großniedesheim",
            "Großolbersdorf",
            "Großörner",
            "Großostheim",
            "Großpösna",
            "Großpostwitz",
            "Großräschen",
            "Großreuth bei Schweinau",
            "Großrinderfeld",
            "Großröhrsdorf",
            "Großrosseln",
            "Großrückerswalde",
            "Großrudestedt",
            "Großschirma",
            "Großschönau",
            "Großschweidnitz",
            "Großsolt",
            "Großthiemig",
            "Großwallstadt",
            "Großweil",
            "Großweitzschen",
            "Großwoltersdorf",
            "Grub",
            "Grube",
            "Gruibingen",
            "Grünau",
            "Grünbach",
            "Grünberg",
            "Grünenbach",
            "Grünendeich",
            "Grunewald",
            "Grünhain",
            "Grünhainichen",
            "Grünheide",
            "Grünkraut",
            "Grünsfeld",
            "Grünstadt",
            "Grünwald",
            "Gschwend",
            "Gstadt am Chiemsee",
            "Guben",
            "Gückingen",
            "Gudensberg",
            "Guderhandviertel",
            "Gudow",
            "Güglingen",
            "Gülzow",
            "Gummersbach",
            "Gumtow",
            "Gundelfingen",
            "Gundelfingen",
            "Gundelsheim",
            "Gundelsheim",
            "Gundersheim",
            "Gundremmingen",
            "Guntersblum",
            "Güntersleben",
            "Günthersdorf",
            "Günzach",
            "Günzburg",
            "Gunzenhausen",
            "Gusenburg",
            "Güsten",
            "Güster",
            "Gusterath",
            "Güstrow",
            "Gutach",
            "Gutach im Breisgau",
            "Gütenbach",
            "Gutenberg",
            "Gutenberg",
            "Gutenstetten",
            "Gutenzell-Hürbel",
            "Gütersloh",
            "Guttau",
            "Gützkow",
            "Guxhagen",
            "Gyhum",
            "Haag an der Amper",
            "Haag in Oberbayern",
            "Haan",
            "Haar",
            "Haarbach",
            "Habach",
            "Hachenburg",
            "Hackenheim",
            "Hadamar",
            "Hadmersleben",
            "Hafenlohr",
            "Hage",
            "Hagelstadt",
            "Hagen",
            "Hagen",
            "Hagen im Bremischen",
            "Hagenbach",
            "Hagenbüchach",
            "Hagenburg",
            "Hagenow",
            "Hagnau",
            "Hahnbach",
            "Hahnheim",
            "Hähnichen",
            "Hahnstätten",
            "Haibach",
            "Haidmühle",
            "Haiger",
            "Haigerloch",
            "Haimhausen",
            "Haiming",
            "Hain-Gründau",
            "Haina",
            "Haina",
            "Hainewalde",
            "Hainichen",
            "Hainsfarth",
            "Haiterbach",
            "Hakenfelde",
            "Halbe",
            "Halbemond",
            "Halberstadt",
            "Halblech",
            "Haldensleben I",
            "Haldenwang",
            "Halensee",
            "Halfing",
            "Hallbergmoos",
            "Halle",
            "Halle",
            "Halle (Saale)",
            "Halle Neustadt",
            "Hallenberg",
            "Hallerndorf",
            "Hallstadt",
            "Halsbrücke",
            "Halsenbach",
            "Halstenbek",
            "Haltern am See",
            "Halver",
            "Hamberge",
            "Hambergen",
            "Hambrücken",
            "Hambühren",
            "Hamburg",
            "Hamburg-Altstadt",
            "Hamburg-Mitte",
            "Hamburg-Nord",
            "Hamdorf",
            "Hameln",
            "Hamm",
            "Hamm",
            "Hammah",
            "Hammelburg",
            "Hammerbrook",
            "Hammerbrücke",
            "Hammersbach",
            "Hamminkeln",
            "Hammoor",
            "Hanau am Main",
            "Handeloh",
            "Handewitt",
            "Handorf",
            "Hanerau-Hademarschen",
            "Hangard",
            "Hanhofen",
            "Hänigsen",
            "Hankensbüttel",
            "Hannover",
            "Hannoversch Münden",
            "Hansaviertel",
            "Hanstedt",
            "Hanstedt Eins",
            "Happurg",
            "Harbke",
            "Harburg",
            "Harburg",
            "Hardegsen",
            "Hardheim",
            "Hardt",
            "Haren",
            "Hargesheim",
            "Harpstedt",
            "Harrislee",
            "Harsdorf",
            "Harsefeld",
            "Harsewinkel",
            "Harsleben",
            "Harsum",
            "Hartenholm",
            "Hartenstein",
            "Hartenstein",
            "Hartha",
            "Harthausen",
            "Hartheim",
            "Hartmannsdorf",
            "Harxheim",
            "Harzgerode",
            "Hasbergen",
            "Hasel",
            "Haselau",
            "Haselbach",
            "Haselbachtal",
            "Haseldorf",
            "Haselhorst",
            "Haselünne",
            "Haslach",
            "Hasloch",
            "Hasloh",
            "Haßbergen",
            "Hassel",
            "Hasselfelde",
            "Hassendorf",
            "Haßfurt",
            "Haßleben",
            "Haßloch",
            "Haßmersheim",
            "Haste",
            "Hatten",
            "Hattenhofen",
            "Hattenhofen",
            "Hattersheim",
            "Hattert",
            "Hattingen",
            "Hattorf",
            "Hattstedt",
            "Hatzenbühl",
            "Hatzfeld",
            "Hauenstein",
            "Haundorf",
            "Haunsheim",
            "Hauptstuhl",
            "Hausach",
            "Hausen",
            "Hausen",
            "Hausen",
            "Häusern",
            "Hausham",
            "Hauswalde",
            "Hauzenberg",
            "Havelberg",
            "Haverlah",
            "Havixbeck",
            "Hawangen",
            "Hayingen",
            "Hebertsfelden",
            "Hebertshausen",
            "Hechingen",
            "Hechthausen",
            "Hecklingen",
            "Heddesheim",
            "Hedersleben",
            "Heede",
            "Heek",
            "Heemsen",
            "Heere",
            "Heeslingen",
            "Heeßen",
            "Hehlen",
            "Heide",
            "Heideck",
            "Heidelberg",
            "Heiden",
            "Heidenau",
            "Heidenau",
            "Heidenheim",
            "Heidenheim an der Brenz",
            "Heidesheim",
            "Heidgraben",
            "Heidstock",
            "Heigenbrücken",
            "Heikendorf",
            "Heilbad Heiligenstadt",
            "Heilbronn",
            "Heiligenberg",
            "Heiligengrabe",
            "Heiligenhafen",
            "Heiligenhaus",
            "Heiligenroth",
            "Heiligensee",
            "Heiligenstadt",
            "Heiligenstedten",
            "Heiligkreuzsteinach",
            "Heilsbronn",
            "Heimbach",
            "Heimbach",
            "Heimbuchenthal",
            "Heimenkirch",
            "Heimertingen",
            "Heimsheim",
            "Heinade",
            "Heinböckel",
            "Heinersdorf",
            "Heiningen",
            "Heinsberg",
            "Heinsen",
            "Heist",
            "Heistenbach",
            "Heitersheim",
            "Helbra",
            "Heldrungen",
            "Helferskirchen",
            "Helgoland",
            "Hellenhahn-Schellenberg",
            "Hellenthal",
            "Hellersdorf",
            "Hellingen",
            "Hellwege",
            "Helmbrechts",
            "Helmstadt",
            "Helmstadt-Bargen",
            "Helmstedt",
            "Helpsen",
            "Helsa",
            "Heltersberg",
            "Hemau",
            "Hemdingen",
            "Hemer",
            "Hemhofen",
            "Hemmingen",
            "Hemmingen",
            "Hemmingstedt",
            "Hemmoor",
            "Hemsbach",
            "Hemsbünde",
            "Hemslingen",
            "Hendungen",
            "Henfenfeld",
            "Hengersberg",
            "Hennef",
            "Hennigsdorf",
            "Hennstedt",
            "Hennweiler",
            "Henstedt-Ulzburg",
            "Heppenheim an der Bergstrasse",
            "Hepstedt",
            "Herbertingen",
            "Herbolzheim",
            "Herborn",
            "Herbrechtingen",
            "Herbsleben",
            "Herbstein",
            "Herdecke",
            "Herdorf",
            "Herdwangen-Schönach",
            "Heretsried",
            "Herford",
            "Herforst",
            "Hergensweiler",
            "Hergisdorf",
            "Heringen",
            "Heringen",
            "Heringsdorf",
            "Herleshausen",
            "Hermannsburg",
            "Hermaringen",
            "Hermersberg",
            "Hermeskeil",
            "Hermsdorf",
            "Hermsdorf",
            "Hermsdorf",
            "Hermsdorf",
            "Herne",
            "Heroldsbach",
            "Heroldsberg",
            "Herrenberg",
            "Herrieden",
            "Herrischried",
            "Herrngiersdorf",
            "Herrnhut",
            "Herrsching am Ammersee",
            "Hersbruck",
            "Herschdorf",
            "Herscheid",
            "Herschweiler-Pettersheim",
            "Herten",
            "Herxheim am Berg",
            "Herzberg",
            "Herzberg am Harz",
            "Herzhorn",
            "Herzlake",
            "Herzogenaurach",
            "Herzogenrath",
            "Hesel",
            "Hespe",
            "Heßdorf",
            "Heßheim",
            "Hessigheim",
            "Hessisch Lichtenau",
            "Hessisch Oldendorf",
            "Hetlingen",
            "Hettenleidelheim",
            "Hettenshausen",
            "Hettingen",
            "Hettstadt",
            "Hettstedt",
            "Hetzerath",
            "Hetzles",
            "Heubach",
            "Heuchelheim",
            "Heuchelheim bei Frankenthal",
            "Heuchlingen",
            "Heudeber",
            "Heuerßen",
            "Heusenstamm",
            "Heustreu",
            "Heusweiler",
            "Heyerode",
            "Heyersum",
            "Hiddenhausen",
            "Hiddensee",
            "Hilbersdorf",
            "Hilchenbach",
            "Hildburghausen",
            "Hilden",
            "Hilders",
            "Hildesheim",
            "Hildrizhausen",
            "Hilgermissen",
            "Hilgert",
            "Hilgertshausen-Tandern",
            "Hille",
            "Hillerse",
            "Hillesheim",
            "Hillscheid",
            "Hilpoltstein",
            "Hiltenfingen",
            "Hilter",
            "Hiltpoltstein",
            "Hilzingen",
            "Himbergen",
            "Himmelkron",
            "Himmelpforten",
            "Himmelstadt",
            "Hinte",
            "Hinterschmiding",
            "Hinterweidenthal",
            "Hinterzarten",
            "Hipstedt",
            "Hirrlingen",
            "Hirschaid",
            "Hirschau",
            "Hirschbach",
            "Hirschberg an der Bergstraße",
            "Hirschfeld",
            "Hirschfeld",
            "Hirschfelde",
            "Hirschhorn",
            "Hirzenhain",
            "Hitzacker",
            "Hitzhusen",
            "Höchberg",
            "Hochdonn",
            "Hochdorf",
            "Hochdorf-Assenheim",
            "Höchenschwand",
            "Hochfeld",
            "Höchheim",
            "Hochheim am Main",
            "Hochkirch",
            "Hochspeyer",
            "Höchst im Odenwald",
            "Hochstadt",
            "Hochstadt am Main",
            "Höchstadt an der Aisch",
            "Höchstädt an der Donau",
            "Höchstädt bei Thiersheim",
            "Hochstetten-Dhaun",
            "Höckendorf",
            "Hockenheim",
            "Hodenhagen",
            "Hof",
            "Hof",
            "Hofbieber",
            "Höfen an der Enz",
            "Hofgeismar",
            "Hofheim am Taunus",
            "Hofheim in Unterfranken",
            "Hofkirchen",
            "Hofstetten",
            "Hofstetten",
            "Hohberg",
            "Hohburg",
            "Höheinöd",
            "Höheischweiler",
            "Hohen Neuendorf",
            "Hohenaspe",
            "Hohenau",
            "Höhenberg",
            "Hohenberg an der Eger",
            "Hohenbocka",
            "Hohenbrunn",
            "Hohenburg",
            "Hohendodeleben",
            "Hohenfelde",
            "Hohenfels",
            "Hohenfurch",
            "Hohenhameln",
            "Hohenkammer",
            "Höhenkirchen-Siegertsbrunn",
            "Hohenleipisch",
            "Hohenleuben",
            "Hohenlinden",
            "Hohenlockstedt",
            "Hohenmölsen",
            "Hohenpeißenberg",
            "Hohenpolding",
            "Hohenstein-Ernstthal",
            "Hohentengen",
            "Hohenthann",
            "Hohenthurm",
            "Hohenwarsleben",
            "Hohenwarth",
            "Hohenwarthe",
            "Hohenwestedt",
            "Hohn",
            "Höhn",
            "Hohndorf",
            "Hohnhorst",
            "Höhnstedt",
            "Hohnstein",
            "Höhr-Grenzhausen",
            "Höingen",
            "Hoisdorf",
            "Holdorf",
            "Holle",
            "Holleben",
            "Hollenbach",
            "Hollenstedt",
            "Holler",
            "Hollfeld",
            "Hollingstedt",
            "Hollstadt",
            "Holm",
            "Holtland",
            "Holtsee",
            "Holzappel",
            "Holzdorf",
            "Holzgerlingen",
            "Holzgünz",
            "Holzhausen an der Haide",
            "Holzheim",
            "Holzkirchen",
            "Holzmaden",
            "Holzminden",
            "Holzweißig",
            "Holzwickede",
            "Homberg",
            "Homburg",
            "Hoogstede",
            "Hopferau",
            "Höpfingen",
            "Hoppstädten-Weiersbach",
            "Horb am Neckar",
            "Horben",
            "Hordel",
            "Hörden",
            "Hördt",
            "Horgau",
            "Horgenzell",
            "Hörgertshausen",
            "Horhausen",
            "Horka",
            "Hormersdorf",
            "Horn",
            "Hornbach",
            "Hornberg",
            "Hornburg",
            "Horneburg",
            "Hornhausen",
            "Hörnsheim",
            "Hornstorf",
            "Horrenberg",
            "Hörselgau",
            "Horst",
            "Horstedt",
            "Hörstel",
            "Horstmar",
            "Hösbach",
            "Hosenfeld",
            "Höslwang",
            "Hötensleben",
            "Höttingen",
            "Hövelhof",
            "Höxter",
            "Hoya",
            "Hoyerswerda",
            "Hoym",
            "Hückelhoven",
            "Hückeswagen",
            "Hude",
            "Huede",
            "Hüffelsheim",
            "Hüffenhardt",
            "Hüfingen",
            "Hügelsheim",
            "Huglfing",
            "Huisheim",
            "Hülben",
            "Hüllhorst",
            "Hülsede",
            "Humboldtkolonie",
            "Hummelsbüttel",
            "Hunderdorf",
            "Hundeshagen",
            "Hunding",
            "Hundsangen",
            "Hünfeld",
            "Hungen",
            "Hünxe",
            "Hurlach",
            "Hürtgenwald",
            "Hürth",
            "Hürup",
            "Husum",
            "Husum",
            "Hütschenhausen",
            "Hüttenrode",
            "Hutthurm",
            "Hüttisheim",
            "Hüttlingen",
            "Huy-Neinstedt",
            "Ibbenbüren",
            "Ichenhausen",
            "Ichtershausen",
            "Icking",
            "Idar-Oberstein",
            "Idstein",
            "Iffeldorf",
            "Iffezheim",
            "Ifta",
            "Igel",
            "Igensdorf",
            "Igersheim",
            "Iggensbach",
            "Iggingen",
            "Ihlienworth",
            "Ihringen",
            "Ihrlerstein",
            "Ilberstedt",
            "Ilbesheim",
            "Ilfeld",
            "Illerrieden",
            "Illertissen",
            "Illingen",
            "Illingen",
            "Illmensee",
            "Illschwang",
            "Ilmenau",
            "Ilmmünster",
            "Ilsede",
            "Ilsenburg",
            "Ilsfeld",
            "Ilshofen",
            "Ilvesheim",
            "Immelborn",
            "Immendingen",
            "Immenhausen",
            "Immenreuth",
            "Immenstaad am Bodensee",
            "Immenstadt im Allgäu",
            "Imsbach",
            "Inchenhofen",
            "Inden",
            "Ingelfingen",
            "Ingelheim am Rhein",
            "Ingersleben",
            "Ingoldingen",
            "Ingolstadt",
            "Innernzell",
            "Inning am Ammersee",
            "Inning am Holz",
            "Insheim",
            "Insingen",
            "Inzell",
            "Inzigkofen",
            "Inzlingen",
            "Iphofen",
            "Ippesheim",
            "Ipsheim",
            "Irchenrieth",
            "Irlbach",
            "Irrel",
            "Irsch",
            "Irschenberg",
            "Irsee",
            "Irxleben",
            "Isen",
            "Isenbüttel",
            "Iserlohn",
            "Isernhagen Farster Bauerschaft",
            "Ismaning",
            "Isny",
            "Ispringen",
            "Isselburg",
            "Issum",
            "Itterbeck",
            "Ittlingen",
            "Itzehoe",
            "Itzstedt",
            "Jacobsdorf",
            "Jagsthausen",
            "Jagstzell",
            "Jahnsdorf",
            "Jameln",
            "Jandelsbrunn",
            "Jänschwalde",
            "Jarmen",
            "Jarplund-Weding",
            "Jatznick",
            "Jävenitz",
            "Jembke",
            "Jemgum",
            "Jena",
            "Jengen",
            "Jerichow",
            "Jerrishoe",
            "Jersbek",
            "Jerxheim",
            "Jesberg",
            "Jesenwang",
            "Jesewitz",
            "Jessen",
            "Jeßnitz",
            "Jesteburg",
            "Jestetten",
            "Jettingen-Scheppach",
            "Jetzendorf",
            "Jevenstedt",
            "Jever",
            "Joachimsthal",
            "Jockgrim",
            "Johannesberg",
            "Johanngeorgenstadt",
            "Johanniskirchen",
            "Johannisthal",
            "Jöhlingen",
            "Jöhstadt",
            "Jördenstorf",
            "Jork",
            "Jübek",
            "Jüchen",
            "Jüchsen",
            "Judenbach",
            "Jugenheim",
            "Jühnde",
            "Juist",
            "Julbach",
            "Jülich",
            "Jungingen",
            "Jünkerath",
            "Jürgenshagen",
            "Jüterbog",
            "Kaarst",
            "Kadenbach",
            "Kahl am Main",
            "Kahla",
            "Kaisersbach",
            "Kaisersesch",
            "Kaiserslautern",
            "Kaisheim",
            "Kakenstorf",
            "Kalbe",
            "Kalchreuth",
            "Kalefeld",
            "Kalk",
            "Kalkar",
            "Kalkhorst",
            "Kall",
            "Kallmünz",
            "Kallstadt",
            "Kaltenengers",
            "Kaltenkirchen",
            "Kaltennordheim",
            "Kaltenwestheim",
            "Kamen",
            "Kamenz",
            "Kammerstein",
            "Kamp-Bornhofen",
            "Kamp-Lintfort",
            "Kamsdorf",
            "Kandel",
            "Kandern",
            "Kannawurf",
            "Kappel-Grafenhausen",
            "Kappeln",
            "Kappelrodeck",
            "Kapsweyer",
            "Karbach",
            "Karben",
            "Karlsdorf-Neuthard",
            "Karlsfeld",
            "Karlshagen",
            "Karlshorst",
            "Karlshuld",
            "Karlskron",
            "Karlsruhe",
            "Karlsruhe Region",
            "Karlstadt",
            "Karow",
            "Karsbach",
            "Karsdorf",
            "Karstädt",
            "Kasbach-Ohlenberg",
            "Kasel",
            "Kasendorf",
            "Kasseedorf",
            "Kassel",
            "Kastellaun",
            "Kastl",
            "Kastorf",
            "Katharinenberg",
            "Katlenburg-Lindau",
            "Katzenelnbogen",
            "Katzhütte",
            "Katzweiler",
            "Katzwinkel",
            "Kaub",
            "Kaufbeuren",
            "Kaufering",
            "Kaufungen",
            "Kaulsdorf",
            "Kaulsdorf",
            "Kavelstorf",
            "Kayhude",
            "Kayna",
            "Kefenrod",
            "Kehl",
            "Kehrig",
            "Keitum",
            "Kelberg",
            "Kelbra",
            "Kelheim",
            "Kelkheim (Taunus)",
            "Kell",
            "Kellenhusen",
            "Kellinghusen",
            "Kellmünz",
            "Kelsterbach",
            "Kemberg",
            "Kemmern",
            "Kemnath",
            "Kemnitz",
            "Kempen",
            "Kempenich",
            "Kempten (Allgäu)",
            "Kenn",
            "Kenzingen",
            "Kerpen",
            "Kerzenheim",
            "Kessin",
            "Ketsch",
            "Kettenkamp",
            "Kettershausen",
            "Kettig",
            "Ketzin",
            "Kevelaer",
            "Kiebitzreihe",
            "Kiedrich",
            "Kiefersfelden",
            "Kiel",
            "Kienberg",
            "Kierspe",
            "Kieselbronn",
            "Kindelbrück",
            "Kindenheim",
            "Kinderbeuern",
            "Kindsbach",
            "Kippenheim",
            "Kirchanschöring",
            "Kirchardt",
            "Kirchberg",
            "Kirchberg",
            "Kirchberg",
            "Kirchberg an der Iller",
            "Kirchberg an der Jagst",
            "Kirchberg an der Murr",
            "Kirchbrak",
            "Kirchdorf",
            "Kirchdorf",
            "Kirchdorf",
            "Kirchdorf am Inn",
            "Kirchdorf im Wald",
            "Kirchehrenbach",
            "Kirchen",
            "Kirchenlamitz",
            "Kirchenpingarten",
            "Kirchensittenbach",
            "Kirchentellinsfurt",
            "Kirchenthumbach",
            "Kirchgellersen",
            "Kirchhain",
            "Kirchham",
            "Kirchhaslach",
            "Kirchheim",
            "Kirchheim",
            "Kirchheim",
            "Kirchheim am Neckar",
            "Kirchheim am Ries",
            "Kirchheim an der Weinstraße",
            "Kirchheim bei München",
            "Kirchheim unter Teck",
            "Kirchheimbolanden",
            "Kirchhundem",
            "Kirchlauter",
            "Kirchlengern",
            "Kirchlinteln",
            "Kirchroth",
            "Kirchseelte",
            "Kirchseeon",
            "Kirchtimke",
            "Kirchwald",
            "Kirchwalsede",
            "Kirchweidach",
            "Kirchworbis",
            "Kirchzarten",
            "Kirchzell",
            "Kirkel",
            "Kirn",
            "Kirrweiler",
            "Kirschau",
            "Kirschweiler",
            "Kirtorf",
            "Kisdorf",
            "Kissenbrück",
            "Kissing",
            "Kißlegg",
            "Kist",
            "Kitzen",
            "Kitzingen",
            "Kitzscher",
            "Kladow",
            "Klausdorf",
            "Klausen",
            "Klein Berßen",
            "Klein Gusborn",
            "Klein Nordende",
            "Klein Offenseth-Sparrieshoop",
            "Klein Reken",
            "Klein Rogahn",
            "Klein Rönnau",
            "Klein Schwülper",
            "Klein Wanzleben",
            "Klein-Winternheim",
            "Kleinaitingen",
            "Kleinblittersdorf",
            "Kleiner Grasbrook",
            "Kleinfurra",
            "Kleinheubach",
            "Kleinkahl",
            "Kleinlangheim",
            "Kleinmachnow",
            "Kleinmaischeid",
            "Kleinostheim",
            "Kleinrinderfeld",
            "Kleinsendelbach",
            "Kleinwallstadt",
            "Kleinwenden",
            "Klettbach",
            "Kleve",
            "Klieken",
            "Klietz",
            "Klingenberg am Main",
            "Klingenmünster",
            "Klingenstein",
            "Klingenthal",
            "Klink",
            "Klipphausen",
            "Klitten",
            "Kloster Lehnin",
            "Klosterlechfeld",
            "Klostermansfeld",
            "Klotten",
            "Klötze",
            "Kluse",
            "Klüsserath",
            "Klütz",
            "Knetzgau",
            "Knittelsheim",
            "Knittlingen",
            "Kobern-Gondorf",
            "Koblenz",
            "Kochel",
            "Kodersdorf",
            "Köditz",
            "Ködnitz",
            "Köfering",
            "Kohlberg",
            "Kohlberg",
            "Kolbermoor",
            "Kolbingen",
            "Kölbingen",
            "Kolitzheim",
            "Kolkwitz",
            "Kölleda",
            "Kollmar",
            "Kölln-Reisiek",
            "Kollnburg",
            "Köln",
            "Köngen",
            "Köngernheim",
            "Königheim",
            "Königs Wusterhausen",
            "Königsbach-Stein",
            "Königsbronn",
            "Königsbrück",
            "Königsbrunn",
            "Königsdorf",
            "Königsee",
            "Königsfeld",
            "Königsfeld",
            "Königsfeld im Schwarzwald",
            "Königshain",
            "Königslutter am Elm",
            "Königstein",
            "Königstein",
            "Königstein im Taunus",
            "Königswalde",
            "Königswartha",
            "Königswinter",
            "Könitz",
            "Könnern",
            "Konradshöhe",
            "Konradsreuth",
            "Konstanz",
            "Konz",
            "Konzell",
            "Köpenick",
            "Korb",
            "Korbach",
            "Kordel",
            "Körle",
            "Körner",
            "Korntal",
            "Kornwestheim",
            "Körperich",
            "Korschenbroich",
            "Kosel",
            "Koserow",
            "Kossa",
            "Köthen",
            "Kottenheim",
            "Kottgeisering",
            "Kottweiler-Schwanden",
            "Kötzschau",
            "Kötzting",
            "Kraftsdorf",
            "Kraiburg am Inn",
            "Kraichtal",
            "Krailling",
            "Krakow am See",
            "Kramerhof",
            "Kranenburg",
            "Kranichfeld",
            "Krauchenwies",
            "Krauschwitz",
            "Krauthausen",
            "Krautheim",
            "Krebeck",
            "Krefeld",
            "Kreiensen",
            "Kreischa",
            "Kremmen",
            "Krempe",
            "Kremperheide",
            "Kressbronn am Bodensee",
            "Kretzschau",
            "Kreut",
            "Kreuzau",
            "Kreuzberg",
            "Kreuztal",
            "Kreuzwertheim",
            "Krickenbach",
            "Kriebitzsch",
            "Kriebstein",
            "Kriegsfeld",
            "Kriftel",
            "Kritzmow",
            "Krölpa",
            "Krombach",
            "Kromsdorf",
            "Kronach",
            "Kronau",
            "Kronberg",
            "Kronberg Tal",
            "Kronburg",
            "Kronshagen",
            "Kröpelin",
            "Kropp",
            "Kröppelshagen-Fahrendorf",
            "Kroppenstedt",
            "Kropstädt",
            "Kröslin",
            "Krostitz",
            "Kröv",
            "Kruft",
            "Krumbach",
            "Krummennaab",
            "Krummesse",
            "Krumpa",
            "Krün",
            "Kubschütz",
            "Kuchen",
            "Kuddewörde",
            "Kueps Oberfranken",
            "Kuhardt",
            "Kühbach",
            "Kühndorf",
            "Kührstedt",
            "Küllstedt",
            "Kulmain",
            "Kulmbach",
            "Külsheim",
            "Kumhausen",
            "Kummerfeld",
            "Kümmersbruck",
            "Kunreuth",
            "Künzell",
            "Künzelsau",
            "Künzing",
            "Kupferberg",
            "Kupferzell",
            "Kuppenheim",
            "Kürnach",
            "Kürnbach",
            "Kurort Gohrisch",
            "Kurort Jonsdorf",
            "Kurort Oberwiesenthal",
            "Kurort Oybin",
            "Kürten",
            "Kusel",
            "Kusey",
            "Küsten",
            "Kusterdingen",
            "Kutenholz",
            "Kutzenhausen",
            "Kyllburg",
            "Kyritz",
            "Laage",
            "Laar",
            "Laatzen",
            "Laberweinting",
            "Laboe",
            "Lachen",
            "Lachen-Speyerdorf",
            "Lachendorf",
            "Ladbergen",
            "Ladelund",
            "Ladenburg",
            "Laer",
            "Lage",
            "Lägerdorf",
            "Lähden",
            "Lahnstein",
            "Lahr",
            "Laichingen",
            "Lalendorf",
            "Lalling",
            "Lam",
            "Lambrecht",
            "Lambrechtshagen",
            "Lambsheim",
            "Lamerdingen",
            "Lampertheim",
            "Lampertswalde",
            "Lamspringe",
            "Lamstedt",
            "Landau an der Isar",
            "Landau in der Pfalz",
            "Landesbergen",
            "Landolfshausen",
            "Landsberg",
            "Landsberg am Lech",
            "Landsberied",
            "Landscheid",
            "Landshut",
            "Landstuhl",
            "Langballig",
            "Langeln",
            "Langelsheim",
            "Langen",
            "Langen",
            "Langenaltheim",
            "Langenargen",
            "Langenau",
            "Langenbach",
            "Langenbach bei Marienberg",
            "Langenberg",
            "Langenbernsdorf",
            "Langenbogen",
            "Langenbrettach",
            "Langenburg",
            "Langendorf",
            "Langeneichstädt",
            "Langenenslingen",
            "Langenfeld",
            "Langenfeld",
            "Langenhagen",
            "Langenhahn",
            "Langenhorn",
            "Langenhorn",
            "Langenlonsheim",
            "Langenmosen",
            "Langenneufnach",
            "Langenorla",
            "Langenpreising",
            "Langenselbold",
            "Langensendelbach",
            "Langenstein",
            "Langenwetzendorf",
            "Langenzenn",
            "Langeoog",
            "Langerringen",
            "Langerwehe",
            "Langewiesen",
            "Langfurth",
            "Langgöns",
            "Langlingen",
            "Langquaid",
            "Langstedt",
            "Langsur",
            "Langula",
            "Langwedel",
            "Langwedel",
            "Langweid",
            "Lankow",
            "Lankwitz",
            "Lanstrop",
            "Lappersdorf",
            "Lasbek",
            "Lassan",
            "Lastrup",
            "Lathen",
            "Laubach",
            "Laubach",
            "Lauben",
            "Laucha",
            "Lauchhammer",
            "Lauchheim",
            "Lauchringen",
            "Lauchröden",
            "Lauda-Königshofen",
            "Laudenbach",
            "Laudenbach",
            "Lauenau",
            "Lauenbrück",
            "Lauenburg",
            "Lauenhagen",
            "Lauf",
            "Lauf an der Pegnitz",
            "Laufach",
            "Laufdorf",
            "Laufen",
            "Laufenburg",
            "Lauffen am Neckar",
            "Laugna",
            "Lauingen",
            "Laupheim",
            "Lauscha",
            "Laußig",
            "Laußnitz",
            "Lautenbach",
            "Lauter",
            "Lauter",
            "Lauterbach",
            "Lauterbach/Schwarzwald",
            "Lauterecken",
            "Lauterhofen",
            "Lautertal",
            "Lautertal",
            "Lautrach",
            "Lawalde",
            "Lebach",
            "Lebus",
            "Lechbruck",
            "Leck",
            "Leegebruch",
            "Leer",
            "Leese",
            "Leezdorf",
            "Leezen",
            "Leezen",
            "Legau",
            "Legden",
            "Lehe",
            "Lehesten",
            "Lehmen",
            "Lehmkuhlen",
            "Lehrberg",
            "Lehre",
            "Lehrensteinsfeld",
            "Lehrte",
            "Leibertingen",
            "Leiblfing",
            "Leichlingen",
            "Leidersbach",
            "Leiferde",
            "Leimbach",
            "Leimen",
            "Leimersheim",
            "Leinburg",
            "Leinefelde-Worbis",
            "Leinfelden-Echterdingen",
            "Leingarten",
            "Leinzell",
            "Leipheim",
            "Leipzig",
            "Leisnig",
            "Leißling",
            "Leitzkau",
            "Leiwen",
            "Lemberg",
            "Lemförde",
            "Lemgo",
            "Lemsahl-Mellingstedt",
            "Lemwerder",
            "Lengdorf",
            "Lengede",
            "Lengefeld",
            "Lengenfeld",
            "Lengenwang",
            "Lengerich",
            "Lengerich",
            "Lenggries",
            "Lennestadt",
            "Lenningen",
            "Lensahn",
            "Lentföhrden",
            "Lenzen",
            "Lenzkirch",
            "Leonberg",
            "Leonberg",
            "Leopoldshöhe",
            "Letschin",
            "Letzlingen",
            "Leubnitz",
            "Leubsdorf",
            "Leubsdorf",
            "Leuchtenberg",
            "Leun",
            "Leuna",
            "Leupoldsgrün",
            "Leutenbach",
            "Leutenbach",
            "Leutenberg",
            "Leutersdorf",
            "Leutershausen",
            "Leutesdorf",
            "Leutkirch",
            "Leverkusen",
            "Lewenberg",
            "Lich",
            "Lichte",
            "Lichtenau",
            "Lichtenau",
            "Lichtenau",
            "Lichtenberg",
            "Lichtenberg",
            "Lichtenberg",
            "Lichtenfels",
            "Lichtenrade",
            "Lichtenstein",
            "Lichtentanne",
            "Lichterfelde",
            "Liebenau",
            "Liebenau",
            "Liebenburg",
            "Liebenwalde",
            "Lieberose",
            "Liebstadt",
            "Liederbach",
            "Lienen",
            "Lieser",
            "Lieskau",
            "Lilienthal",
            "Limbach",
            "Limbach-Oberfrohna",
            "Limburg an der Lahn",
            "Limburgerhof",
            "Linau",
            "Lindau",
            "Lindau",
            "Lindau",
            "Lindberg",
            "Linden",
            "Lindenberg",
            "Lindenfels",
            "Lindewitt",
            "Lindhorst",
            "Lindlar",
            "Lindow",
            "Lindwedel",
            "Lingen",
            "Lingenfeld",
            "Linkenheim-Hochstetten",
            "Linnich",
            "Lintig",
            "Linz am Rhein",
            "Lippstadt",
            "Lissendorf",
            "List",
            "Litzendorf",
            "Löbau",
            "Lobbach",
            "Löbejün",
            "Löberitz",
            "Löbichau",
            "Löbnitz",
            "Lobstädt",
            "Loburg",
            "Lochau",
            "Löchgau",
            "Löcknitz",
            "Loddin",
            "Löf",
            "Loffenau",
            "Löffingen",
            "Lohberg",
            "Lohe-Rickelshof",
            "Lohfelden",
            "Lohmar",
            "Lohmen",
            "Löhnberg",
            "Löhne",
            "Lohne",
            "Lohr am Main",
            "Lohra",
            "Lohsa",
            "Loiching",
            "Loitz",
            "Lollar",
            "Lommatzsch",
            "Longkamp",
            "Longuich",
            "Löningen",
            "Lonnerstadt",
            "Lonnig",
            "Lonsee",
            "Lorch",
            "Lörrach",
            "Lorsch",
            "Lorup",
            "Lörzweiler",
            "Losheim",
            "Loßburg",
            "Lößnitz",
            "Lostau",
            "Lotte",
            "Lottstetten",
            "Löwenstein",
            "Lower Bavaria",
            "Loxstedt",
            "Lübars",
            "Lübbecke",
            "Lübben",
            "Lübbenau",
            "Lübeck",
            "Lubmin",
            "Lübow",
            "Lübstorf",
            "Lübtheen",
            "Lübz",
            "Lüchow",
            "Lucka",
            "Luckau",
            "Luckenwalde",
            "Lüdenscheid",
            "Lüder",
            "Lüderitz",
            "Lüdersdorf",
            "Lüdersfeld",
            "Lüdinghausen",
            "Ludweiler-Warndt",
            "Ludwigsburg",
            "Ludwigsfelde",
            "Ludwigshafen am Rhein",
            "Ludwigslust",
            "Ludwigsstadt",
            "Luftkurort Arendsee",
            "Lugau",
            "Lügde",
            "Luhden",
            "Luhe-Wildenau",
            "Luisenthal",
            "Luisenthal",
            "Lunden",
            "Lüneburg",
            "Lünen",
            "Lunestedt",
            "Lünne",
            "Lunzenau",
            "Lupburg",
            "Lürschau",
            "Lurup",
            "Lustadt",
            "Lütjenburg",
            "Lütjensee",
            "Lutter am Barenberge",
            "Lützelbach",
            "Lützen",
            "Lutzerath",
            "Lützow",
            "Lychen",
            "Machern",
            "Mackenbach",
            "Magdala",
            "Magdeburg",
            "Magstadt",
            "Mahlberg",
            "Mahlsdorf",
            "Mähring",
            "Maierhöfen",
            "Maihingen",
            "Maikammer",
            "Mainaschaff",
            "Mainbernheim",
            "Mainburg",
            "Mainhardt",
            "Mainleus",
            "Mainstockheim",
            "Maintal",
            "Mainz",
            "Mainzweiler",
            "Maisach",
            "Maitenbeth",
            "Malborn",
            "Malchin",
            "Malching",
            "Malchow",
            "Malente",
            "Malgersdorf",
            "Mallersdorf-Pfaffenberg",
            "Malliß",
            "Malsch",
            "Malschwitz",
            "Malsfeld",
            "Malterdingen",
            "Mammelzen",
            "Mammendorf",
            "Mamming",
            "Manching",
            "Manderscheid",
            "Mannheim",
            "Manschnow",
            "Mansfeld",
            "Mantel",
            "Marbach am Neckar",
            "Marburg an der Lahn",
            "Margetshöchheim",
            "Mariaposching",
            "Marienberg",
            "Mariendorf",
            "Marienfelde",
            "Marienhafe",
            "Marienheide",
            "Marienrachdorf",
            "Mariental",
            "Marienthal",
            "Marienwerder",
            "Maring-Noviand",
            "Markdorf",
            "Markersbach",
            "Markersdorf",
            "Markgröningen",
            "Märkisches Viertel",
            "Markkleeberg",
            "Marklkofen",
            "Marklohe",
            "Markneukirchen",
            "Markranstädt",
            "Marksuhl",
            "Markt Berolzheim",
            "Markt Bibart",
            "Markt Einersheim",
            "Markt Erlbach",
            "Markt Indersdorf",
            "Markt Nordheim",
            "Markt Rettenbach",
            "Markt Schwaben",
            "Markt Taschendorf",
            "Markt Wald",
            "Marktbergel",
            "Marktbreit",
            "Marktgraitz",
            "Marktheidenfeld",
            "Marktl",
            "Marktleugast",
            "Marktleuthen",
            "Marktoberdorf",
            "Marktoffingen",
            "Marktredwitz",
            "Marktrodach",
            "Marktschellenberg",
            "Marktschorgast",
            "Marktsteft",
            "Marktzeuln",
            "Marl",
            "Marloffstein",
            "Marlow",
            "Marnheim",
            "Maroldsweisach",
            "Marpingen",
            "Marquartstein",
            "Marsberg",
            "Marschacht",
            "Martensrade",
            "Martfeld",
            "Martinhagen",
            "Martinsheim",
            "Martinshöhe",
            "Marxen",
            "Marxheim",
            "Marzahn",
            "Marzling",
            "Masburg",
            "Maselheim",
            "Maßbach",
            "Massenbachhausen",
            "Masserberg",
            "Massing",
            "Maßweiler",
            "Mastershausen",
            "Mauer",
            "Mauern",
            "Mauerstetten",
            "Maulbronn",
            "Maulburg",
            "Mauth",
            "Maxdorf",
            "Maxhütte-Haidhof",
            "Maxsain",
            "Mayen",
            "Mechernich",
            "Mechterstädt",
            "Meckenbeuren",
            "Meckenheim",
            "Meckenheim",
            "Meckesheim",
            "Mecklenburg",
            "Meddersheim",
            "Medebach",
            "Medlingen",
            "Meeder",
            "Meerane",
            "Meerbeck",
            "Meerbusch",
            "Meerdorf",
            "Meersburg",
            "Mehlbach",
            "Mehlingen",
            "Mehlmeisel",
            "Mehltheuer",
            "Mehren",
            "Mehrhoog",
            "Mehring",
            "Mehring",
            "Mehringen",
            "Mehrstetten",
            "Meiderich",
            "Meinersen",
            "Meinerzhagen",
            "Meiningen",
            "Meisenheim",
            "Meissen",
            "Meißenheim",
            "Meitingen",
            "Meitzendorf",
            "Melbach",
            "Melbeck",
            "Melchow",
            "Meldorf",
            "Melle",
            "Mellenbach-Glasbach",
            "Mellingen",
            "Mellinghausen",
            "Mellrichstadt",
            "Melsbach",
            "Melsdorf",
            "Melsungen",
            "Memmelsdorf",
            "Memmingen",
            "Memmingerberg",
            "Menden",
            "Mendig",
            "Mengen",
            "Mengenich",
            "Mengersgereuth-Hämmern",
            "Mengerskirchen",
            "Mengkofen",
            "Menslage",
            "Menteroda",
            "Meppen",
            "Merching",
            "Merchweiler",
            "Merdingen",
            "Merenberg",
            "Mering",
            "Merkenbach",
            "Merkendorf",
            "Merklingen",
            "Merseburg",
            "Mertesdorf",
            "Mertingen",
            "Mertloch",
            "Merxheim",
            "Merzalben",
            "Merzen",
            "Merzhausen",
            "Merzig",
            "Meschede",
            "Mesekenhagen",
            "Mespelbrunn",
            "Messel",
            "Messingen",
            "Meßkirch",
            "Meßstetten",
            "Metelen",
            "Metten",
            "Mettendorf",
            "Mettenheim",
            "Mettenheim",
            "Mettingen",
            "Mettlach",
            "Mettmann",
            "Metzingen",
            "Meudt",
            "Meuselbach",
            "Meuselwitz",
            "Meyenburg",
            "Michelau",
            "Michelbach an der Bilz",
            "Michelfeld",
            "Michelsneukirchen",
            "Michelstadt",
            "Michendorf",
            "Mickhausen",
            "Midlum",
            "Miehlen",
            "Mielkendorf",
            "Miesbach",
            "Mieste",
            "Mietingen",
            "Mihla",
            "Mildenau",
            "Mildstedt",
            "Milmersdorf",
            "Miltach",
            "Miltenberg",
            "Milz",
            "Mindelheim",
            "Minden",
            "Minfeld",
            "Mintraching",
            "Mirow",
            "Missen-Wilhams",
            "Mistelgau",
            "Mitte",
            "Mittegroßefehn",
            "Mittelbiberach",
            "Mitteleschenbach",
            "Mittelherwigsdorf",
            "Mittelhof",
            "Mittelneufnach",
            "Mittelschöntal",
            "Mittelstetten",
            "Mittenaar",
            "Mittenwald",
            "Mittenwalde",
            "Mitterfels",
            "Mitterskirchen",
            "Mitterteich",
            "Mittweida",
            "Mitwitz",
            "Mixdorf",
            "Moabit",
            "Mochau",
            "Möckern",
            "Möckmühl",
            "Mockrehna",
            "Mödingen",
            "Moers",
            "Mogendorf",
            "Mögglingen",
            "Möglingen",
            "Möhlau",
            "Mohlsdorf",
            "Möhnesee",
            "Möhrendorf",
            "Mohrkirch",
            "Moisburg",
            "Molbergen",
            "Molfsee",
            "Möllenhagen",
            "Mölln",
            "Molschleben",
            "Mömbris",
            "Mömlingen",
            "Mommenheim",
            "Mönchberg",
            "Mönchengladbach",
            "Mönchenholzhausen",
            "Mönchhagen",
            "Mönchsdeggingen",
            "Mönchsroth",
            "Mönchweiler",
            "Monheim",
            "Monheim am Rhein",
            "Mönkeberg",
            "Monschau",
            "Monsheim",
            "Mönsheim",
            "Montabaur",
            "Monzelfeld",
            "Monzingen",
            "Moorenweis",
            "Moorrege",
            "Moos",
            "Moosach",
            "Moosbach",
            "Moosburg",
            "Moosinning",
            "Moosthenning",
            "Morbach",
            "Mörfelden-Walldorf",
            "Moringen",
            "Moritzburg",
            "Mörlenbach",
            "Morsbach",
            "Morsum",
            "Mosbach",
            "Möser",
            "Mössingen",
            "Motten",
            "Möttingen",
            "Mötzing",
            "Mötzingen",
            "Much",
            "Mücheln",
            "Mücka",
            "Mudau",
            "Müden",
            "Mudersbach",
            "Mügeln",
            "Müggelheim",
            "Muggensturm",
            "Mühl Rosin",
            "Mühlacker",
            "Mühlanger",
            "Mühlau",
            "Mühlberg",
            "Mühldorf",
            "Mühlen Eichsen",
            "Mühlenbach",
            "Mühlhausen",
            "Mühlhausen",
            "Mühlhausen",
            "Mühlhausen-Ehingen",
            "Mühlheim am Bach",
            "Mühlheim am Main",
            "Mühlingen",
            "Mühltroff",
            "Muhr am See",
            "Mulda",
            "Muldenstein",
            "Mulfingen",
            "Mülheim",
            "Mülheim-Kärlich",
            "Müllheim",
            "Müllrose",
            "Mülsen",
            "Münchberg",
            "Müncheberg",
            "Münchenbernsdorf",
            "Münchhausen",
            "Münchsmünster",
            "Münchsteinach",
            "Münchweiler an der Alsenz",
            "Münchweiler an der Rodalbe",
            "Mundelsheim",
            "Munderkingen",
            "Munich",
            "Munkbrarup",
            "Münnerstadt",
            "Munningen",
            "Münsing",
            "Münsingen",
            "Munster",
            "Münster",
            "Münster",
            "Münster",
            "Münster-Sarmsheim",
            "Münsterdorf",
            "Münsterhausen",
            "Münstermaifeld",
            "Münstertal/Schwarzwald",
            "Münzenberg",
            "Murg",
            "Murnau am Staffelsee",
            "Murr",
            "Murrhardt",
            "Müschenbach",
            "Muschwitz",
            "Mutlangen",
            "Mutterstadt",
            "Mutzschen",
            "Mylau",
            "Nabburg",
            "Nachrodt-Wiblingwerde",
            "Nachterstedt",
            "Nackenheim",
            "Nagel",
            "Nagold",
            "Nahe",
            "Nahrendorf",
            "Naila",
            "Nalbach",
            "Namborn",
            "Nandlstadt",
            "Nanzdietschweiler",
            "Narsdorf",
            "Nassau",
            "Nastätten",
            "Nattheim",
            "Nauen",
            "Nauendorf",
            "Naumburg",
            "Naumburg",
            "Naundorf",
            "Naunhof",
            "Nauort",
            "Nauroth",
            "Nauwalde",
            "Nebelschütz",
            "Nebra",
            "Neckarbischofsheim",
            "Neckargemünd",
            "Neckargerach",
            "Neckarsteinach",
            "Neckarsulm",
            "Neckartailfingen",
            "Neckartenzlingen",
            "Neckarwestheim",
            "Neckarzimmern",
            "Negernbötel",
            "Nehren",
            "Neidenstein",
            "Neidlingen",
            "Neinstedt",
            "Nellingen",
            "Nennhausen",
            "Nennslingen",
            "Nentershausen",
            "Nentershausen",
            "Nerchau",
            "Neresheim",
            "Nersingen",
            "Neschwitz",
            "Nessa",
            "Nesselwang",
            "Netphen",
            "Nettersheim",
            "Nettetal",
            "Netzschkau",
            "Neu Darchau",
            "Neu Isenburg",
            "Neu Kaliß",
            "Neu Wulmstorf",
            "Neu Zauche",
            "Neu-Anspach",
            "Neu-Hohenschönhausen",
            "Neu-Pattern",
            "Neu-Ulm",
            "Neualbenreuth",
            "Neuberend",
            "Neubeuern",
            "Neubiberg",
            "Neubörger",
            "Neubrandenburg",
            "Neubrück",
            "Neubrück",
            "Neubrunn",
            "Neubukow",
            "Neubulach",
            "Neuburg",
            "Neuburg",
            "Neuburg",
            "Neuburg an der Donau",
            "Neudenau",
            "Neudietendorf",
            "Neudorf-Bornstein",
            "Neudrossenfeld",
            "Neue Neustadt",
            "Neuehrenfeld",
            "Neuenbürg",
            "Neuenburg am Rhein",
            "Neuendettelsau",
            "Neuenhagen",
            "Neuenkirchen",
            "Neuenkirchen",
            "Neuenkirchen",
            "Neuenmarkt",
            "Neuenrade",
            "Neuensalz",
            "Neuenstadt am Kocher",
            "Neuenstein",
            "Neuental",
            "Neuerburg",
            "Neufahrn",
            "Neufahrn bei Freising",
            "Neuffen",
            "Neufra",
            "Neufraunhofen",
            "Neugersdorf",
            "Neuhardenberg",
            "Neuharlingersiel",
            "Neuhaus",
            "Neuhaus am Inn",
            "Neuhaus am Rennweg",
            "Neuhaus an der Oste",
            "Neuhaus an der Pegnitz",
            "Neuhaus-Schierschnitz",
            "Neuhäusel",
            "Neuhausen",
            "Neuhausen",
            "Neuhausen auf den Fildern",
            "Neuhof",
            "Neuhof an der Zenn",
            "Neuhofen",
            "Neuhütten",
            "Neukalen",
            "Neukamperfehn",
            "Neukieritzsch",
            "Neukirch",
            "Neukirch",
            "Neukirch/Lausitz",
            "Neukirchen",
            "Neukirchen",
            "Neukirchen",
            "Neukirchen",
            "Neukirchen-Balbini",
            "Neukloster",
            "Neukölln",
            "Neuler",
            "Neulewin",
            "Neulußheim",
            "Neumagen-Dhron",
            "Neumark",
            "Neumarkt in der Oberpfalz",
            "Neumarkt-Sankt Veit",
            "Neumühle",
            "Neumünster",
            "Neunburg vorm Wald",
            "Neundorf",
            "Neunkhausen",
            "Neunkirchen",
            "Neunkirchen",
            "Neunkirchen",
            "Neunkirchen am Brand",
            "Neunkirchen am Main",
            "Neunkirchen am Sand",
            "Neuötting",
            "Neupotz",
            "Neureichenau",
            "Neuried",
            "Neuruppin",
            "Neusalza-Spremberg",
            "Neuschönau",
            "Neusitz",
            "Neusorg",
            "Neuss",
            "Neustadt",
            "Neustadt",
            "Neustadt (Hessen)",
            "Neustadt am Main",
            "Neustadt am Rennsteig",
            "Neustadt am Rübenberge",
            "Neustadt an der Aisch",
            "Neustadt an der Donau",
            "Neustadt an der Orla",
            "Neustadt an der Waldnaab",
            "Neustadt bei Coburg",
            "Neustadt in Holstein",
            "Neustadt in Sachsen",
            "Neustadt Vogtland",
            "Neustadt-Glewe",
            "Neustadt/Nord",
            "Neustadt/Süd",
            "Neustrelitz",
            "Neutraubling",
            "Neutrebbin",
            "Neuweiler",
            "Neuwied",
            "Neuwittenbek",
            "Neuzelle",
            "Neverin",
            "Newel",
            "Nickenich",
            "Nidda",
            "Nidderau",
            "Nideggen",
            "Niebüll",
            "Niedenstein",
            "Nieder-Gründau",
            "Nieder-Ingelheim",
            "Nieder-Olm",
            "Niederaichbach",
            "Niederalteich",
            "Niederau",
            "Niederaula",
            "Niederbergkirchen",
            "Niederbiel",
            "Niederbreitbach",
            "Niedercunnersdorf",
            "Niederdorf",
            "Niederdorfelden",
            "Niederdorla",
            "Niederdreisbach",
            "Niederdürenbach",
            "Niederelbert",
            "Niedererbach",
            "Niedereschach",
            "Niederfell",
            "Niederfischbach",
            "Niederfrohna",
            "Niederfüllbach",
            "Niedergörsdorf",
            "Niederkassel",
            "Niederkirchen",
            "Niederkirchen bei Deidesheim",
            "Niederklein",
            "Niederkrüchten",
            "Niederlangen",
            "Niederlauer",
            "Niedermerz",
            "Niedermohr",
            "Niedermurach",
            "Niedernberg",
            "Niederndodeleben",
            "Niederneisen",
            "Niedernhall",
            "Niedernhausen",
            "Niedernwöhren",
            "Niederorschel",
            "Niederrad",
            "Niederrieden",
            "Niederroßla",
            "Niedersachswerfen",
            "Niederschöna",
            "Niederschönenfeld",
            "Niederschöneweide",
            "Niederschönhausen",
            "Niederstetten",
            "Niederstotzingen",
            "Niederstriegis",
            "Niedertaufkirchen",
            "Niederviehbach",
            "Niederwangen",
            "Niederwerrn",
            "Niederwerth",
            "Niederwiesa",
            "Niederwinkling",
            "Niederzier",
            "Niederzimmern",
            "Niederzissen",
            "Niefern-Öschelbronn",
            "Nieheim",
            "Niemberg",
            "Niemegk",
            "Nienburg",
            "Nienburg/Saale",
            "Nienhagen",
            "Nienstädt",
            "Niepars",
            "Nierstein",
            "Niesky",
            "Nikolassee",
            "Nindorf",
            "Nippes",
            "Nister",
            "Nittel",
            "Nittenau",
            "Nittendorf",
            "Nöbdenitz",
            "Nobitz",
            "Nohfelden",
            "Nohra",
            "Nonnenhorn",
            "Nonnweiler",
            "Norddeich",
            "Norden",
            "Nordendorf",
            "Nordenham",
            "Norderney",
            "Norderstedt",
            "Nordhalben",
            "Nordhastedt",
            "Nordhausen",
            "Nordheim",
            "Nordheim",
            "Nordholz",
            "Nordhorn",
            "Nordkirchen",
            "Nordleda",
            "Nördlingen",
            "Nordrach",
            "Nordstemmen",
            "Nordwalde",
            "Norheim",
            "Nörten-Hardenberg",
            "Nörtershausen",
            "Northeim",
            "Nortmoor",
            "Nortorf",
            "Nortrup",
            "Nörvenich",
            "Nossen",
            "Nostorf",
            "Nottensdorf",
            "Nottuln",
            "Notzingen",
            "Nübbel",
            "Nudersdorf",
            "Nüdlingen",
            "Nufringen",
            "Nümbrecht",
            "Nünchritz",
            "Nürnberg",
            "Nürtingen",
            "Nußdorf",
            "Nußdorf am Inn",
            "Nusse",
            "Nußloch",
            "Nützen",
            "Ober-Flörsheim",
            "Ober-Mörlen",
            "Ober-Olm",
            "Ober-Ramstadt",
            "Ober-Saulheim",
            "Oberammergau",
            "Oberasbach",
            "Oberaudorf",
            "Oberaula",
            "Oberbergkirchen",
            "Oberbiel",
            "Oberboihingen",
            "Obercunnersdorf",
            "Oberdachstetten",
            "Oberderdingen",
            "Oberding",
            "Oberdischingen",
            "Oberdorla",
            "Oberelbert",
            "Oberelsbach",
            "Oberfell",
            "Obergriesbach",
            "Obergünzburg",
            "Obergurig",
            "Oberhaching",
            "Oberhaid",
            "Oberharmersbach",
            "Oberhausen",
            "Oberhausen",
            "Oberhausen-Rheinhausen",
            "Oberhof",
            "Oberjettingen",
            "Oberkirch",
            "Oberkochen",
            "Oberkotzau",
            "Oberleichtersbach",
            "Oberlichtenau",
            "Oberlungwitz",
            "Obermarchtal",
            "Obermaßfeld-Grimmenthal",
            "Obermehler",
            "Obermeitingen",
            "Obermichelbach",
            "Obermoschel",
            "Obernbeck",
            "Obernbreit",
            "Obernburg am Main",
            "Oberndorf",
            "Oberndorf",
            "Oberndorf",
            "Obernfeld",
            "Obernheim",
            "Obernheim-Kirchenarnbach",
            "Obernkirchen",
            "Obernzell",
            "Obernzenn",
            "Oberostendorf",
            "Oberotterbach",
            "Oberottmarshausen",
            "Oberpframmern",
            "Oberpleichfeld",
            "Oberpöring",
            "Oberreichenbach",
            "Oberreichenbach",
            "Oberreute",
            "Oberried",
            "Oberrieden",
            "Oberriexingen",
            "Oberröblingen",
            "Oberrot",
            "Oberrotweil",
            "Oberscheinfeld",
            "Oberschleißheim",
            "Oberschneiding",
            "Oberschöna",
            "Oberschöneweide",
            "Oberschwarzach",
            "Oberschweinbach",
            "Obersinn",
            "Obersöchering",
            "Obersontheim",
            "Oberstadion",
            "Oberstaufen",
            "Oberstdorf",
            "Oberstenfeld",
            "Oberstreu",
            "Obersüßbach",
            "Obertaufkirchen",
            "Oberteuringen",
            "Oberthal",
            "Oberthulba",
            "Obertraubling",
            "Obertrubach",
            "Obertshausen",
            "Oberursel",
            "Oberviechtach",
            "Oberweißbach",
            "Oberwesel",
            "Oberwiera",
            "Oberwolfach",
            "Oberzissen",
            "Obhausen",
            "Obing",
            "Obrigheim",
            "Obrigheim",
            "Ochsenfurt",
            "Ochsenhausen",
            "Ochtendung",
            "Ochtrup",
            "Ockenfels",
            "Ockenheim",
            "Odelzhausen",
            "Odenthal",
            "Oderberg",
            "Odernheim",
            "Oebisfelde",
            "Oederan",
            "Oederquart",
            "Oedheim",
            "Oelde",
            "Oelixdorf",
            "Oelsnitz",
            "Oer-Erkenschwick",
            "Oerel",
            "Oerlenbach",
            "Oerlinghausen",
            "Oettingen in Bayern",
            "Oeversee",
            "Offenau",
            "Offenbach",
            "Offenbach an der Queich",
            "Offenbach-Hundheim",
            "Offenberg",
            "Offenburg",
            "Offenhausen",
            "Offingen",
            "Offstein",
            "Ofterdingen",
            "Ofterschwang",
            "Oftersheim",
            "Ohlsbach",
            "Ohlsdorf",
            "Ohlstadt",
            "Ohmden",
            "Öhningen",
            "Ohorn",
            "Öhringen",
            "Olbernhau",
            "Olbersdorf",
            "Ölbronn-Dürrn",
            "Olching",
            "Oldenburg",
            "Oldenburg in Holstein",
            "Oldendorf",
            "Oldendorf",
            "Oldenswort",
            "Oldisleben",
            "Olfen",
            "Olpe",
            "Olsberg",
            "Olsbrücken",
            "Opfenbach",
            "Öpfingen",
            "Opladen",
            "Oppach",
            "Oppenau",
            "Oppenheim",
            "Oppenweiler",
            "Oppin",
            "Oppurg",
            "Oranienbaum",
            "Oranienburg",
            "Orenhofen",
            "Orlamünde",
            "Ornbau",
            "Orscholz",
            "Orsingen-Nenzingen",
            "Ortenberg",
            "Ortenberg",
            "Ortenburg",
            "Ortrand",
            "Osann-Monzel",
            "Osburg",
            "Oschatz",
            "Oschersleben",
            "Osdorf",
            "Osloß",
            "Osnabrück",
            "Ossendorf",
            "Oßling",
            "Oßmanstedt",
            "Ostbevern",
            "Osteel",
            "Ostelsheim",
            "Osten",
            "Ostenfeld",
            "Osterburg",
            "Osterburken",
            "Ostercappeln",
            "Osterfeld",
            "Osterhausen",
            "Osterhofen",
            "Osterholz-Scharmbeck",
            "Osternienburg",
            "Osternienburger Land",
            "Osterode am Harz",
            "Osterrönfeld",
            "Osterspai",
            "Osterwieck",
            "Ostfildern",
            "Ostheim",
            "Ostheim vor der Rhön",
            "Osthofen",
            "Ostrach",
            "Ostrau",
            "Ostrhauderfehn",
            "Östringen",
            "Ostritz",
            "Ostseebad Binz",
            "Ostseebad Boltenhagen",
            "Ostseebad Dierhagen",
            "Ostseebad Göhren",
            "Ostseebad Kühlungsborn",
            "Ostseebad Prerow",
            "Ostseebad Sellin",
            "Ostseebad Zinnowitz",
            "Oststeinbek",
            "Ötigheim",
            "Ötisheim",
            "Ottenbach",
            "Ottendorf-Okrilla",
            "Ottenhofen",
            "Ottenhofen",
            "Ottensen",
            "Ottensoos",
            "Ottenstein",
            "Otter",
            "Otterbach",
            "Otterberg",
            "Otterfing",
            "Otterndorf",
            "Ottersberg",
            "Ottersheim",
            "Otterstadt",
            "Ottersweier",
            "Otterwisch",
            "Ottobeuren",
            "Ottobrunn",
            "Ottrau",
            "Ottweiler",
            "Otzing",
            "Ötzingen",
            "Ovelgönne",
            "Overath",
            "Owen",
            "Owingen",
            "Owschlag",
            "Oy-Mittelberg",
            "Oyten",
            "Padenstedt",
            "Paderborn",
            "Pähl",
            "Pahlen",
            "Painten",
            "Palling",
            "Palzem",
            "Pampow",
            "Panker",
            "Pankow",
            "Panschwitz-Kuckau",
            "Papenburg",
            "Papendorf",
            "Pappenheim",
            "Pappenheim",
            "Parchim",
            "Parkstein",
            "Parkstetten",
            "Parsau",
            "Parsberg",
            "Partenheim",
            "Partenstein",
            "Pasewalk",
            "Pasing",
            "Passau",
            "Pastetten",
            "Patersdorf",
            "Pattensen",
            "Paulinenaue",
            "Paulsstadt",
            "Paunzhausen",
            "Pausa",
            "Pechbrunn",
            "Pegau",
            "Pegnitz",
            "Peine",
            "Peißen",
            "Peißenberg",
            "Peiting",
            "Peitz",
            "Pellingen",
            "Pellworm",
            "Pelm",
            "Pemfling",
            "Penig",
            "Penkun",
            "Pennigsehl",
            "Pentling",
            "Penzberg",
            "Penzing",
            "Penzlin",
            "Perach",
            "Perkam",
            "Perleberg",
            "Perlesreut",
            "Petersaurach",
            "Petersdorf",
            "Petershagen",
            "Petershagen",
            "Petershausen",
            "Pettendorf",
            "Petting",
            "Pettstadt",
            "Pfaffen-Schwabenheim",
            "Pfaffenhausen",
            "Pfaffenhofen",
            "Pfaffenhofen",
            "Pfaffenhofen an der Ilm",
            "Pfaffenhofen an der Roth",
            "Pfaffenweiler",
            "Pfaffing",
            "Pfaffroda",
            "Pfakofen",
            "Pfalzgrafenweiler",
            "Pfarrkirchen",
            "Pfarrweisach",
            "Pfatter",
            "Pfedelbach",
            "Pfeffelbach",
            "Pfeffenhausen",
            "Pfofeld",
            "Pforzen",
            "Pforzheim",
            "Pfreimd",
            "Pfronstetten",
            "Pfronten",
            "Pfullendorf",
            "Pfullingen",
            "Pfungstadt",
            "Philippsburg",
            "Philippsthal",
            "Piding",
            "Pielenhofen",
            "Piesport",
            "Piesteritz",
            "Pilsach",
            "Pilsting",
            "Pinneberg",
            "Pinnow",
            "Pinzberg",
            "Pirk",
            "Pirmasens",
            "Pirna",
            "Pittenhart",
            "Plaidt",
            "Planegg",
            "Plankstadt",
            "Plänterwald",
            "Plate",
            "Plattenburg",
            "Plattling",
            "Plau am See",
            "Plaue",
            "Plauen",
            "Pleidelsheim",
            "Pleinfeld",
            "Pleiskirchen",
            "Plessa",
            "Plettenberg",
            "Pleystein",
            "Pliening",
            "Pliezhausen",
            "Plochingen",
            "Plön",
            "Plößberg",
            "Plötzkau",
            "Plötzky",
            "Plüderhausen",
            "Pluwig",
            "Pobershau",
            "Pockau",
            "Pocking",
            "Podelzig",
            "Pöhl",
            "Pöhla",
            "Poing",
            "Polch",
            "Pölitz",
            "Poll",
            "Polle",
            "Polleben",
            "Pollhagen",
            "Polling",
            "Polsingen",
            "Pölzig",
            "Pommelsbrunn",
            "Pommersfelden",
            "Ponitz",
            "Poppenbüttel",
            "Poppenhausen",
            "Poppenhausen",
            "Poppenricht",
            "Pörnbach",
            "Porschdorf",
            "Porta Westfalica",
            "Porz am Rhein",
            "Poseritz",
            "Pößneck",
            "Postau",
            "Postbauer-Heng",
            "Postmünster",
            "Potsdam",
            "Pottenstein",
            "Pöttmes",
            "Pottum",
            "Pouch",
            "Poxdorf",
            "Pracht",
            "Prackenbach",
            "Pratau",
            "Prebitz",
            "Preetz",
            "Preetz",
            "Premnitz",
            "Prenzlau",
            "Prenzlauer Berg",
            "Pressath",
            "Presseck",
            "Pressig",
            "Prettin",
            "Pretzfeld",
            "Pretzier",
            "Pretzsch",
            "Pretzschendorf",
            "Preußisch Oldendorf",
            "Prichsenstadt",
            "Prien am Chiemsee",
            "Priesendorf",
            "Priestewitz",
            "Prisdorf",
            "Prittitz",
            "Prittriching",
            "Pritzwalk",
            "Probsteierhagen",
            "Probstzella",
            "Prohn",
            "Pronstorf",
            "Prosselsheim",
            "Prötzel",
            "Prüm",
            "Prutting",
            "Püchersreuth",
            "Puchheim",
            "Puderbach",
            "Pulheim",
            "Pullach im Isartal",
            "Pullenreuth",
            "Pürgen",
            "Puschendorf",
            "Puschwitz",
            "Putbus",
            "Putlitz",
            "Püttlingen",
            "Putzbrunn",
            "Pyrbaum",
            "Quakenbrück",
            "Quarnbek",
            "Quedlinburg",
            "Queidersbach",
            "Queis",
            "Quellendorf",
            "Querfurt",
            "Quern",
            "Quickborn",
            "Quierschied",
            "Quitzdorf",
            "Raben Steinfeld",
            "Rabenau",
            "Rabenau",
            "Räckelwitz",
            "Rackwitz",
            "Radbruch",
            "Raddestorf",
            "Radeberg",
            "Radebeul",
            "Radeburg",
            "Radegast",
            "Radevormwald",
            "Radibor",
            "Radis",
            "Radolfzell",
            "Raesfeld",
            "Raguhn",
            "Rahden",
            "Rahnsdorf",
            "Rain",
            "Raisdorf",
            "Raisting",
            "Raitenbuch",
            "Ralingen",
            "Ramberg",
            "Rambin",
            "Ramerberg",
            "Rammelsbach",
            "Rammenau",
            "Rammingen",
            "Ramsau",
            "Ramsen",
            "Ramsin",
            "Ramstein-Miesenbach",
            "Ramsthal",
            "Randersacker",
            "Rangendingen",
            "Rangsdorf",
            "Ranis",
            "Rannungen",
            "Ransbach-Baumbach",
            "Ranstadt",
            "Rantrum",
            "Raschau",
            "Rasdorf",
            "Raßnitz",
            "Rastatt",
            "Rastede",
            "Rastenberg",
            "Rastow",
            "Ratekau",
            "Rath",
            "Rathenow",
            "Rathmannsdorf",
            "Ratingen",
            "Rattelsdorf",
            "Rattenberg",
            "Rattiszell",
            "Ratzeburg",
            "Raubach",
            "Raubling",
            "Rauen",
            "Rauenberg",
            "Raunheim",
            "Rauschenberg",
            "Ravensburg",
            "Rechberghausen",
            "Rechenberg-Bienenmühle",
            "Rechlin",
            "Rechtenbach",
            "Rechtmehring",
            "Rechtsupweg",
            "Recke",
            "Recklinghausen",
            "Rednitzhembach",
            "Redwitz an der Rodach",
            "Rees",
            "Reeßum",
            "Regen",
            "Regensburg",
            "Regenstauf",
            "Regesbostel",
            "Regierungsbezirk Arnsberg",
            "Regierungsbezirk Darmstadt",
            "Regierungsbezirk Detmold",
            "Regierungsbezirk Gießen",
            "Regierungsbezirk Kassel",
            "Regierungsbezirk Köln",
            "Regierungsbezirk Mittelfranken",
            "Regierungsbezirk Münster",
            "Regierungsbezirk Stuttgart",
            "Regierungsbezirk Unterfranken",
            "Regis-Breitingen",
            "Regnitzlosau",
            "Rehau",
            "Rehburg-Loccum",
            "Rehden",
            "Rehe",
            "Rehfelde",
            "Rehling",
            "Rehna",
            "Reichardtswerben",
            "Reichartshausen",
            "Reichelsheim",
            "Reichenau",
            "Reichenbach",
            "Reichenbach",
            "Reichenbach an der Fils",
            "Reichenbach-Steegen",
            "Reichenbach/Vogtland",
            "Reichenberg",
            "Reichenschwand",
            "Reichenwalde",
            "Reichersbeuern",
            "Reichertshausen",
            "Reichertsheim",
            "Reichertshofen",
            "Reichling",
            "Reil",
            "Reilingen",
            "Reimlingen",
            "Reinbek",
            "Reinberg",
            "Reinfeld",
            "Reinhardshausen",
            "Reinhardtsgrimma",
            "Reinhausen",
            "Reinheim",
            "Reinickendorf",
            "Reinsberg",
            "Reinsdorf",
            "Reinsfeld",
            "Reinstorf",
            "Reisbach",
            "Reischach",
            "Reiskirchen",
            "Reit im Winkl",
            "Rellingen",
            "Remagen",
            "Remlingen",
            "Remlingen",
            "Remptendorf",
            "Remscheid",
            "Remse",
            "Renchen",
            "Rendsburg",
            "Rengsdorf",
            "Rennerod",
            "Rennertshofen",
            "Renningen",
            "Rentweinsdorf",
            "Reppenstedt",
            "Retgendorf",
            "Rethem",
            "Rethen",
            "Retschow",
            "Rettenbach",
            "Rettenberg",
            "Retzstadt",
            "Reußen",
            "Reut",
            "Reuth",
            "Reuth",
            "Reutlingen",
            "Rhade",
            "Rhaunen",
            "Rheda-Wiedenbrück",
            "Rhede",
            "Rhede",
            "Rheden",
            "Rheinau",
            "Rheinbach",
            "Rheinberg",
            "Rheinböllen",
            "Rheinbreitbach",
            "Rheinbrohl",
            "Rheine",
            "Rheinfelden",
            "Rheinsberg",
            "Rheinstetten",
            "Rheinzabern",
            "Rhens",
            "Rheurdt",
            "Rhinow",
            "Rhodt unter Rietburg",
            "Rhumspringe",
            "Ribbesbüttel",
            "Ribnitz-Damgarten",
            "Richtenberg",
            "Rickenbach",
            "Rickert",
            "Rickling",
            "Ried",
            "Riede",
            "Rieden",
            "Rieden",
            "Rieden an der Kötz",
            "Riedenberg",
            "Riedenburg",
            "Rieder",
            "Riederich",
            "Riedering",
            "Riedlingen",
            "Riedstadt",
            "Riegel",
            "Riegelsberg",
            "Riegsee",
            "Riehl",
            "Rielasingen-Worblingen",
            "Rieneck",
            "Riepsdorf",
            "Riesa",
            "Rieschweiler-Mühlbach",
            "Rieseby",
            "Rieste",
            "Riestedt",
            "Rietberg",
            "Rietheim-Weilheim",
            "Riethnordhausen",
            "Rietschen",
            "Rietz Neuendorf",
            "Rimbach",
            "Rimbach",
            "Rimpar",
            "Rimschweiler",
            "Rimsting",
            "Rinchnach",
            "Ringelai",
            "Ringleben",
            "Ringsheim",
            "Rinteln",
            "Riol",
            "Risum-Lindholm",
            "Ritterhude",
            "Rittersdorf",
            "Rittersgrün",
            "Röbel",
            "Röblingen am See",
            "Röchling-Höhe",
            "Rochlitz",
            "Rockenberg",
            "Rockenhausen",
            "Rodalben",
            "Rödelsee",
            "Roden",
            "Rodenbach",
            "Rodenbach",
            "Rodenberg",
            "Rödental",
            "Rödersheim-Gronau",
            "Rodewisch",
            "Rodgau",
            "Roding",
            "Rödinghausen",
            "Rodleben",
            "Roetgen",
            "Röfingen",
            "Rogätz",
            "Roggenburg",
            "Roggendorf",
            "Roggentin",
            "Rohr",
            "Rohr",
            "Rohrbach",
            "Rohrbach",
            "Rohrdorf",
            "Rohrdorf",
            "Rohrenfels",
            "Röhrmoos",
            "Röhrnbach",
            "Röhrsen",
            "Roigheim",
            "Roitzsch",
            "Röllbach",
            "Römhild",
            "Rommerskirchen",
            "Romrod",
            "Ronneburg",
            "Ronnenberg",
            "Ronsberg",
            "Ronshausen",
            "Rosbach vor der Höhe",
            "Rosche",
            "Rosdorf",
            "Rosenberg",
            "Rosenfeld",
            "Rosenheim",
            "Rosenow",
            "Rosenthal",
            "Rosenthal",
            "Rositz",
            "Roskow",
            "Röslau",
            "Rösrath",
            "Rossau",
            "Rossbach",
            "Roßdorf",
            "Roßhaupten",
            "Roßla",
            "Roßlau",
            "Roßleben",
            "Roßtal",
            "Roßwein",
            "Rostock",
            "Rot",
            "Rot am See",
            "Rotenburg",
            "Rotenburg an der Fulda",
            "Rötgesbüttel",
            "Roth",
            "Roth",
            "Rötha",
            "Röthenbach",
            "Röthenbach an der Pegnitz",
            "Rothenberg",
            "Rothenbuch",
            "Rothenburg",
            "Rothenburg ob der Tauber",
            "Rothenburgsort",
            "Rothenfels",
            "Rothenstein",
            "Röthlein",
            "Rott",
            "Rottach-Egern",
            "Rottenacker",
            "Rottenbach",
            "Röttenbach",
            "Rottenbuch",
            "Rottenburg",
            "Rottenburg an der Laaber",
            "Rottendorf",
            "Rotthalmünster",
            "Röttingen",
            "Rottleberode",
            "Rottweil",
            "Rötz",
            "Rövershagen",
            "Roxheim",
            "Rübeland",
            "Rückersdorf",
            "Rückersdorf",
            "Rudelzhausen",
            "Ruderatshofen",
            "Rudersberg",
            "Ruderting",
            "Rüdesheim",
            "Rüdesheim am Rhein",
            "Rüdnitz",
            "Rudolstadt",
            "Rudow",
            "Rugendorf",
            "Rügland",
            "Rühen",
            "Ruhla",
            "Ruhland",
            "Ruhmannsfelden",
            "Ruhpolding",
            "Ruhstorf",
            "Ruhwinkel",
            "Rullstorf",
            "Rülzheim",
            "Rummelsburg",
            "Rümmelsheim",
            "Rümmingen",
            "Rümpel",
            "Runding",
            "Runkel",
            "Ruppach-Goldhausen",
            "Ruppertsberg",
            "Ruppertshofen",
            "Ruppertsweiler",
            "Ruppichteroth",
            "Rüsselsheim",
            "Rust",
            "Rutesheim",
            "Rüthen",
            "Saal",
            "Saal",
            "Saal an der Saale",
            "Saalfeld",
            "Saara",
            "Saarbrücken",
            "Saarburg",
            "Saarhölzbach",
            "Saarlouis",
            "Saarwellingen",
            "Sachsen",
            "Sachsenhagen",
            "Sachsenhausen",
            "Sachsenheim",
            "Sachsenkam",
            "Saerbeck",
            "Saffig",
            "Sagard",
            "Sailauf",
            "Salach",
            "Salching",
            "Saldenburg",
            "Salem",
            "Salgen",
            "Sallgast",
            "Salmtal",
            "Salz",
            "Salzbergen",
            "Salzgitter",
            "Salzhausen",
            "Salzhemmendorf",
            "Salzkotten",
            "Salzmünde",
            "Salzwedel",
            "Salzweg",
            "Samerberg",
            "Samswegen",
            "Samtens",
            "Sand",
            "Sandau",
            "Sandberg",
            "Sande",
            "Sandersdorf",
            "Sandersleben",
            "Sandesneben",
            "Sandhausen",
            "Sandstedt",
            "Sangerhausen",
            "Sankelmark",
            "Sankt Andreasberg",
            "Sankt Augustin",
            "Sankt Blasien",
            "Sankt Egidien",
            "Sankt Englmar",
            "Sankt Gangloff",
            "Sankt Georgen im Schwarzwald",
            "Sankt Goar",
            "Sankt Goarshausen",
            "Sankt Ingbert",
            "Sankt Johann",
            "Sankt Julian",
            "Sankt Katharinen",
            "Sankt Kilian",
            "Sankt Leon-Rot",
            "Sankt Leonhard am Wonneberg",
            "Sankt Margarethen",
            "Sankt Märgen",
            "Sankt Martin",
            "Sankt Michaelisdonn",
            "Sankt Peter",
            "Sankt Peter-Ording",
            "Sankt Sebastian",
            "Sankt Wendel",
            "Sankt Wolfgang",
            "Sarstedt",
            "Sasbach",
            "Sasbachwalden",
            "Sasel",
            "Sassenberg",
            "Sassenburg",
            "Saßnitz",
            "Satow-Oberhagen",
            "Satrup",
            "Satteldorf",
            "Sauensiek",
            "Sauerlach",
            "Sauldorf",
            "Saulgau",
            "Saulgrub",
            "Sayda",
            "Schaafheim",
            "Schaalby",
            "Schacht-Audorf",
            "Schafflund",
            "Schafstädt",
            "Schäftlarn",
            "Schalkau",
            "Schalksmühle",
            "Schallstadt",
            "Schapen",
            "Scharbeutz",
            "Scharfenstein",
            "Scharnebeck",
            "Schashagen",
            "Schauenstein",
            "Schaufling",
            "Schechen",
            "Schechingen",
            "Scheden",
            "Scheer",
            "Scheeßel",
            "Scheibenberg",
            "Scheidegg",
            "Scheinfeld",
            "Schelfstadt",
            "Schelklingen",
            "Schellerten",
            "Schellhorn",
            "Schenefeld",
            "Schenkendöbern",
            "Schenkenzell",
            "Schenklengsfeld",
            "Schermbeck",
            "Schermen",
            "Schernberg",
            "Scherstetten",
            "Scheßlitz",
            "Scheuerfeld",
            "Scheuring",
            "Scheyern",
            "Schieder-Schwalenberg",
            "Schierling",
            "Schiffdorf",
            "Schifferstadt",
            "Schiffweiler",
            "Schildau",
            "Schillig",
            "Schillingen",
            "Schillingsfürst",
            "Schiltach",
            "Schiltberg",
            "Schipkau",
            "Schirgiswalde",
            "Schirmitz",
            "Schirnding",
            "Schkeuditz",
            "Schkölen",
            "Schkopau",
            "Schladen",
            "Schlagsdorf",
            "Schlaitdorf",
            "Schlaitz",
            "Schlangen",
            "Schlangenbad",
            "Schlat",
            "Schleching",
            "Schlegel",
            "Schlehdorf",
            "Schleid",
            "Schleiden",
            "Schleife",
            "Schleiz",
            "Schleswig",
            "Schlettau",
            "Schleusingen",
            "Schlieben",
            "Schliengen",
            "Schlier",
            "Schlierbach",
            "Schliersee",
            "Schlitz",
            "Schloßvippach",
            "Schlotheim",
            "Schluchsee",
            "Schlüchtern",
            "Schlüsselfeld",
            "Schmalfeld",
            "Schmalkalden",
            "Schmallenberg",
            "Schmargendorf",
            "Schmelz",
            "Schmidgaden",
            "Schmidmühlen",
            "Schmiechen",
            "Schmiedeberg",
            "Schmiedefeld",
            "Schmiedefeld am Rennsteig",
            "Schmitten",
            "Schmöckwitz",
            "Schmölln",
            "Schnaitsee",
            "Schnaittach",
            "Schnaittenbach",
            "Schneckenlohe",
            "Schneeberg",
            "Schnega",
            "Schneidlingen",
            "Schneizlreuth",
            "Schnelldorf",
            "Schneverdingen",
            "Schnürpflingen",
            "Schobüll",
            "Schochwitz",
            "Schöffengrund",
            "Schöfweg",
            "Schöllkrippen",
            "Schöllnach",
            "Schömberg",
            "Schonach im Schwarzwald",
            "Schönaich",
            "Schönau",
            "Schönau",
            "Schönau am Königssee",
            "Schönau im Schwarzwald",
            "Schönau-Berzdorf",
            "Schönbach",
            "Schönberg",
            "Schönberg",
            "Schönborn",
            "Schönburg",
            "Schondorf am Ammersee",
            "Schondra",
            "Schönebeck",
            "Schöneberg",
            "Schöneck",
            "Schönecken",
            "Schönefeld",
            "Schöneiche",
            "Schönenberg-Kübelberg",
            "Schönewalde",
            "Schönfeld",
            "Schongau",
            "Schöngeising",
            "Schönhausen",
            "Schönheide",
            "Schöningen",
            "Schönkirchen",
            "Schönsee",
            "Schönstedt",
            "Schonstett",
            "Schönthal",
            "Schonungen",
            "Schönwald",
            "Schönwald",
            "Schönwalde am Bungsberg",
            "Schopfheim",
            "Schopfloch",
            "Schopfloch",
            "Schopp",
            "Schöppenstedt",
            "Schöppingen",
            "Schorndorf",
            "Schorndorf",
            "Schornsheim",
            "Schortens",
            "Schotten",
            "Schramberg",
            "Schraplau",
            "Schrecksbach",
            "Schriesheim",
            "Schrobenhausen",
            "Schrozberg",
            "Schuby",
            "Schülp",
            "Schulzendorf",
            "Schuttertal",
            "Schutterwald",
            "Schüttorf",
            "Schwaan",
            "Schwabach",
            "Schwabenheim",
            "Schwabhausen",
            "Schwäbisch Gmünd",
            "Schwäbisch Hall",
            "Schwabmünchen",
            "Schwabsoien",
            "Schwabstedt",
            "Schwaförden",
            "Schwaig",
            "Schwaigern",
            "Schwaikheim",
            "Schwalbach",
            "Schwalbach",
            "Schwalbach am Taunus",
            "Schwallungen",
            "Schwalmstadt",
            "Schwalmtal",
            "Schwalmtal",
            "Schwanau",
            "Schwandorf in Bayern",
            "Schwanebeck",
            "Schwanewede",
            "Schwanfeld",
            "Schwangau",
            "Schwarme",
            "Schwarmstedt",
            "Schwarza",
            "Schwarzach",
            "Schwarzach",
            "Schwarzenbach",
            "Schwarzenbach an der Saale",
            "Schwarzenbek",
            "Schwarzenberg",
            "Schwarzenborn",
            "Schwarzenbruck",
            "Schwarzenfeld",
            "Schwarzheide",
            "Schwarzhofen",
            "Schwebheim",
            "Schwedelbach",
            "Schwedt (Oder)",
            "Schwegenheim",
            "Schweich",
            "Schweigen-Rechtenbach",
            "Schweina",
            "Schweinfurt",
            "Schweitenkirchen",
            "Schwelm",
            "Schwendi",
            "Schwenningen",
            "Schwenningen",
            "Schwepnitz",
            "Schwerin",
            "Schwerte",
            "Schwetzingen",
            "Schwieberdingen",
            "Schwindegg",
            "Schwörstadt",
            "Sebnitz",
            "Seck",
            "Seckach",
            "Seebach",
            "Seebach",
            "Seebad Bansin",
            "Seebad Heringsdorf",
            "Seebergen",
            "Seeburg",
            "Seedorf",
            "Seedorf",
            "Seefeld",
            "Seeg",
            "Seehausen",
            "Seehausen am Staffelsee",
            "Seeheilbad Graal-Müritz",
            "Seeheim-Jugenheim",
            "Seehof",
            "Seelbach",
            "Seelingstädt",
            "Seelitz",
            "Seelow",
            "Seelze",
            "Seeon-Seebruck",
            "Seesen",
            "Seeshaupt",
            "Seevetal",
            "Seggebruch",
            "Sehlde",
            "Sehlem",
            "Sehlen",
            "Sehnde",
            "Seibersbach",
            "Seiffen",
            "Seifhennersdorf",
            "Seinsheim",
            "Seitingen-Oberflacht",
            "Selb",
            "Selbitz",
            "Selent",
            "Seligenstadt",
            "Sellin",
            "Selm",
            "Selmsdorf",
            "Selsingen",
            "Selters",
            "Selters",
            "Selzen",
            "Sembach",
            "Senden",
            "Senden",
            "Sendenhorst",
            "Senftenberg",
            "Sengenthal",
            "Sennewitz",
            "Sennfeld",
            "Serrig",
            "Sersheim",
            "Seßlach",
            "Seth",
            "Seubersdorf",
            "Seukendorf",
            "Seulingen",
            "Sexau",
            "Seybothenreuth",
            "Seyda",
            "Sibbesse",
            "Sickenhausen",
            "Sickte",
            "Siebeldingen",
            "Siedenburg",
            "Siefersheim",
            "Siegburg",
            "Siegelsbach",
            "Siegen",
            "Siegenburg",
            "Siegsdorf",
            "Siek",
            "Sielenbach",
            "Siemensstadt",
            "Sierksdorf",
            "Siersdorf",
            "Siershahn",
            "Siersleben",
            "Sievershütten",
            "Sieverstedt",
            "Siggelkow",
            "Sigmaringen",
            "Sigmaringendorf",
            "Sigmarszell",
            "Silberstedt",
            "Sillenstede",
            "Simbach",
            "Simbach am Inn",
            "Simmelsdorf",
            "Simmerath",
            "Simmern",
            "Simmersfeld",
            "Simmertal",
            "Simmozheim",
            "Sindelfingen",
            "Sindelsdorf",
            "Singen",
            "Singhofen",
            "Sinn",
            "Sinnersdorf",
            "Sinsheim",
            "Sinzheim",
            "Sinzig",
            "Sinzing",
            "Sippersfeld",
            "Sipplingen",
            "Sittensen",
            "Sitzendorf",
            "Söchtenau",
            "Södel",
            "Soderstorf",
            "Soest",
            "Sögel",
            "Sohland",
            "Sohland am Rotstein",
            "Söhlde",
            "Sohren",
            "Sölden",
            "Solingen",
            "Sollstedt",
            "Solms",
            "Solnhofen",
            "Soltau",
            "Soltendieck",
            "Sommerach",
            "Sömmerda",
            "Sommerhausen",
            "Sommerkahl",
            "Sommersdorf",
            "Sondershausen",
            "Sondheim vor der Rhön",
            "Sonneberg",
            "Sonneborn",
            "Sonnefeld",
            "Sonnen",
            "Sonnewalde",
            "Sonsbeck",
            "Sontheim",
            "Sontheim an der Brenz",
            "Sonthofen",
            "Sontra",
            "Sörgenloch",
            "Sörup",
            "Sosa",
            "Sottrum",
            "Soyen",
            "Spabrücken",
            "Spaichingen",
            "Spalt",
            "Spandau",
            "Spangenberg",
            "Spardorf",
            "Sparneck",
            "Spay",
            "Spechbach",
            "Speicher",
            "Speichersdorf",
            "Speinshart",
            "Spelle",
            "Spenge",
            "Sperenberg",
            "Spergau",
            "Speyer",
            "Spiegelau",
            "Spiegelberg",
            "Spiesen-Elversberg",
            "Spiesheim",
            "Spornitz",
            "Spraitbach",
            "Sprakensehl",
            "Spreenhagen",
            "Sprendlingen",
            "Springe",
            "Sprockhövel",
            "St. Georg",
            "St. Pauli",
            "Staaken",
            "Stäbelow",
            "Stade",
            "Stadecken-Elsheim",
            "Stadelhofen",
            "Stadensen",
            "Stadt Wehlen",
            "Stadtallendorf",
            "Stadtbergen",
            "Stadthagen",
            "Stadtilm",
            "Stadtkyll",
            "Stadtlauringen",
            "Stadtlengsfeld",
            "Stadtlohn",
            "Stadtoldendorf",
            "Stadtprozelten",
            "Stadtrandsiedlung Malchow",
            "Stadtroda",
            "Stadtsteinach",
            "Stadum",
            "Stahnsdorf",
            "Staig",
            "Stallwang",
            "Stammbach",
            "Stammham",
            "Stamsried",
            "Stapelburg",
            "Stapelfeld",
            "Starnberg",
            "Staßfurt",
            "Stauchitz",
            "Staudach-Egerndach",
            "Staudernheim",
            "Staufen",
            "Staufenberg",
            "Stedesdorf",
            "Stedten",
            "Steeden",
            "Stegaurach",
            "Stegen",
            "Steglitz",
            "Steilshoop",
            "Steimbke",
            "Steimel",
            "Stein",
            "Steina",
            "Steinach",
            "Steinach",
            "Steinach",
            "Steinau an der Straße",
            "Steinbach",
            "Steinbach",
            "Steinbach am Taunus",
            "Steinbach-Hallenberg",
            "Steinberg",
            "Steinbergkirche",
            "Steinen",
            "Steinenbronn",
            "Steinfeld",
            "Steinfeld",
            "Steinfeld",
            "Steinfurt",
            "Steingaden",
            "Steinhagen",
            "Steinhagen",
            "Steinheid",
            "Steinheim",
            "Steinheim",
            "Steinheim am Albuch",
            "Steinheim am der Murr",
            "Steinhöfel",
            "Steinhöring",
            "Steinhorst",
            "Steinigtwolmsdorf",
            "Steinkirchen",
            "Steinkirchen",
            "Steinmauern",
            "Steinsfeld",
            "Steinweiler",
            "Steinwenden",
            "Steinwiesen",
            "Steißlingen",
            "Stelle",
            "Stellingen",
            "Stelzenberg",
            "Stendal",
            "Stephanskirchen",
            "Stephansposching",
            "Sternberg",
            "Sternenfels",
            "Sterup",
            "Stetten",
            "Stetten am Kalten Markt",
            "Stettfeld",
            "Steyerberg",
            "Stiefenhofen",
            "Stimpfach",
            "Stockach",
            "Stockelsdorf",
            "Stockheim",
            "Stöckse",
            "Stockstadt am Main",
            "Stockstadt am Rhein",
            "Stödtlen",
            "Stolberg",
            "Stolberg",
            "Stollberg",
            "Stolpe",
            "Stolpen",
            "Stolzenau",
            "Storkow",
            "Störnstein",
            "Stoßdorf",
            "Stößen",
            "Stötten am Auerberg",
            "Stöttwang",
            "Straelen",
            "Stralendorf",
            "Stralsund",
            "Strande",
            "Strasburg",
            "Straßberg",
            "Straßkirchen",
            "Straßlach-Dingharting",
            "Straubing",
            "Straupitz",
            "Strausberg",
            "Straußfurt",
            "Strehla",
            "Ströbeck",
            "Stromberg",
            "Strullendorf",
            "Struppen",
            "Struvenhütten",
            "Stubenberg",
            "Stühlingen",
            "Stuhr",
            "Stulln",
            "Stuttgart",
            "Stuttgart Feuerbach",
            "Stuttgart Mühlhausen",
            "Stuttgart-Ost",
            "Stützengrün",
            "Stützerbach",
            "Suddendorf",
            "Süderbrarup",
            "Suderburg",
            "Südergellersen",
            "Süderlügum",
            "Süderstapel",
            "Südlohn",
            "Sudwalde",
            "Sugenheim",
            "Suhl",
            "Suhlendorf",
            "Sukow",
            "Sülfeld",
            "Sulingen",
            "Sülstorf",
            "Sulz am Neckar",
            "Sulzbach",
            "Sulzbach",
            "Sulzbach am Main",
            "Sulzbach an der Murr",
            "Sulzbach-Rosenberg",
            "Sulzberg",
            "Sulzburg",
            "Sulzdorf",
            "Sulzemoos",
            "Sulzfeld",
            "Sulzfeld",
            "Sulzfeld am Main",
            "Sulzheim",
            "Sulzheim",
            "Sünching",
            "Sundern",
            "Süplingen",
            "Süpplingen",
            "Surberg",
            "Surwold",
            "Süsel",
            "Süßen",
            "Süstedt",
            "Sustrum",
            "Swabia",
            "Syke",
            "Sylt-Ost",
            "Syrau",
            "Tabarz",
            "Tacherting",
            "Taching am See",
            "Täferrot",
            "Tagmersheim",
            "Talheim",
            "Tambach-Dietharz",
            "Tamm",
            "Tangerhütte",
            "Tangermünde",
            "Tangstedt",
            "Tann",
            "Tann",
            "Tanna",
            "Tannenberg",
            "Tannenbergsthal",
            "Tännesberg",
            "Tannhausen",
            "Tannheim",
            "Tapfheim",
            "Tappenbeck",
            "Tarmstedt",
            "Tarnow",
            "Tarp",
            "Tating",
            "Tauberbischofsheim",
            "Taucha",
            "Tauche",
            "Taufkirchen",
            "Taunusstein",
            "Taura",
            "Tauscha",
            "Tautenhain",
            "Tawern",
            "Tecklenburg",
            "Tegel",
            "Tegernheim",
            "Tegernsee",
            "Teicha",
            "Teichwolframsdorf",
            "Teisendorf",
            "Teising",
            "Teisnach",
            "Teistungen",
            "Telgte",
            "Tellingstedt",
            "Teltow",
            "Tempelhof",
            "Templin",
            "Tengen",
            "Teningen",
            "Tennenbronn",
            "Tespe",
            "Tessin",
            "Teterow",
            "Tettau",
            "Tettenweis",
            "Tettnang",
            "Teublitz",
            "Teuchel",
            "Teuchern",
            "Teugn",
            "Teunz",
            "Teupitz",
            "Teuschnitz",
            "Teutschenthal",
            "Thal",
            "Thale",
            "Thaleischweiler-Fröschen",
            "Thalfang",
            "Thalheim",
            "Thalheim",
            "Thallwitz",
            "Thalmassing",
            "Thannhausen",
            "Thanstein",
            "Tharandt",
            "Thedinghausen",
            "Theilheim",
            "Theisseil",
            "Theißen",
            "Themar",
            "Theuma",
            "Thiendorf",
            "Thierhaupten",
            "Thiersheim",
            "Thierstein",
            "Tholey",
            "Thomasburg",
            "Thomm",
            "Thuine",
            "Thum",
            "Thundorf in Unterfranken",
            "Thüngen",
            "Thüngersheim",
            "Thür",
            "Thurmansbang",
            "Thurnau",
            "Thyrnau",
            "Tiddische",
            "Tiefenbach",
            "Tiefenbronn",
            "Tiefenort",
            "Tiergarten",
            "Timmaspe",
            "Timmendorfer Strand",
            "Timmenrode",
            "Tinnum",
            "Tirpersdorf",
            "Tirschenreuth",
            "Titisee-Neustadt",
            "Tittling",
            "Tittmoning",
            "Titz",
            "Todenbüttel",
            "Todendorf",
            "Todesfelde",
            "Todtenweis",
            "Todtmoos",
            "Todtnau",
            "Töging am Inn",
            "Tolk",
            "Tollwitz",
            "Tönisvorst",
            "Tönning",
            "Töpen",
            "Toppenstedt",
            "Torgau",
            "Torgelow",
            "Tornesch",
            "Tostedt",
            "Traben-Trarbach",
            "Trabitz",
            "Train",
            "Traitsching",
            "Trappenkamp",
            "Trappstadt",
            "Trassem",
            "Traunreut",
            "Traunstein",
            "Trausnitz",
            "Trautskirchen",
            "Travemünde",
            "Trebbin",
            "Trebendorf",
            "Trebgast",
            "Trebitz",
            "Trebsen",
            "Trebur",
            "Trechtingshausen",
            "Treffelstein",
            "Treffurt",
            "Treia",
            "Treis-Karden",
            "Tremsbüttel",
            "Trendelburg",
            "Treuchtlingen",
            "Treuen",
            "Treuenbrietzen",
            "Triberg",
            "Tribsees",
            "Triebel",
            "Triebes",
            "Trier",
            "Trierweiler",
            "Triftern",
            "Trinwillershagen",
            "Trippstadt",
            "Triptis",
            "Trittau",
            "Trittenheim",
            "Trochtelfingen",
            "Trogen",
            "Troisdorf",
            "Trollenhagen",
            "Trossin",
            "Trossingen",
            "Tröstau",
            "Trostberg an der Alz",
            "Trulben",
            "Trunkelsberg",
            "Trusetal",
            "Tschernitz",
            "Tübingen",
            "Tübingen Region",
            "Tucheim",
            "Tuchenbach",
            "Tülau",
            "Tuningen",
            "Tuntenhausen",
            "Türkenfeld",
            "Türkheim",
            "Türnich",
            "Tussenhausen",
            "Tüßling",
            "Tutow",
            "Tüttendorf",
            "Tuttlingen",
            "Tutzing",
            "Twistringen",
            "Tyrlaching",
            "Übach-Palenberg",
            "Überherrn",
            "Überlingen",
            "Übersee",
            "Ubstadt-Weiher",
            "Uchte",
            "Üchtelhausen",
            "Uchtspringe",
            "Udenhausen",
            "Udenheim",
            "Uder",
            "Üdersdorf",
            "Uebigau",
            "Ueckermünde",
            "Uedem",
            "Uehlfeld",
            "Uehrde",
            "Uelsen",
            "Uelversheim",
            "Uelzen",
            "Uenglingen",
            "Uetersen",
            "Uettingen",
            "Uetze",
            "Uffenheim",
            "Uffing",
            "Uftrungen",
            "Uhingen",
            "Uhldingen-Mühlhofen",
            "Ühlingen-Birkendorf",
            "Uhyst",
            "Uichteritz",
            "Ulm",
            "Ulmen",
            "Ulrichstein",
            "Umkirch",
            "Ummendorf",
            "Ummendorf",
            "Ummern",
            "Undenheim",
            "Ungerhausen",
            "Unkel",
            "Unlingen",
            "Unna",
            "Unnau",
            "Unseburg",
            "Unter-Abtsteinach",
            "Unterammergau",
            "Unterbreizbach",
            "Unterdießen",
            "Unterdietfurt",
            "Unteregg",
            "Untereisesheim",
            "Unterensingen",
            "Unterföhring",
            "Untergriesbach",
            "Untergruppenbach",
            "Unterhaching",
            "Unterhausen",
            "Unterjettingen",
            "Unterkirnach",
            "Unterkrozingen",
            "Unterleinleiter",
            "Unterlüß",
            "Untermaßfeld",
            "Untermeitingen",
            "Untermerzbach",
            "Untermünkheim",
            "Unterneukirchen",
            "Unterpleichfeld",
            "Unterreichenbach",
            "Unterreit",
            "Unterschleißheim",
            "Unterschneidheim",
            "Untersiemau",
            "Untersteinach",
            "Unterthingau",
            "Unterwellenborn",
            "Unterwössen",
            "Untrasried",
            "Upgant-Schott",
            "Upper Bavaria",
            "Upper Franconia",
            "Upper Palatinate",
            "Urbach",
            "Urbach",
            "Urbach-Überdorf",
            "Urbar",
            "Urmitz",
            "Ursberg",
            "Ursensollen",
            "Urspringen",
            "Usedom",
            "Usingen",
            "Uslar",
            "Ustersbach",
            "Uthleben",
            "Uthlede",
            "Uttenreuth",
            "Uttenweiler",
            "Utting am Ammersee",
            "Üxheim",
            "Vaale",
            "Vacha",
            "Vachendorf",
            "Vagen",
            "Vaihingen an der Enz",
            "Vallendar",
            "Valley",
            "Varel",
            "Varrel",
            "Vaterstetten",
            "Vechelde",
            "Vechta",
            "Veckenstedt",
            "Vegesack",
            "Veilsdorf",
            "Veitsbronn",
            "Veitshöchheim",
            "Velbert",
            "Velburg",
            "Velden",
            "Velen",
            "Velgast",
            "Vellberg",
            "Vellmar",
            "Velpke",
            "Velten",
            "Veltheim",
            "Venusberg",
            "Verden",
            "Veringenstadt",
            "Verl",
            "Versmold",
            "Vestenbergsgreuth",
            "Vetschau",
            "Vettelschoß",
            "Vettweiß",
            "Viechtach",
            "Vienenburg",
            "Viereck",
            "Viereth-Trunstadt",
            "Vierkirchen",
            "Viernau",
            "Viernheim",
            "Viersen",
            "Vilgertshofen",
            "Villenbach",
            "Villingen-Schwenningen",
            "Villingendorf",
            "Villmar",
            "Vilsbiburg",
            "Vilseck",
            "Vilsheim",
            "Vilshofen",
            "Vinningen",
            "Viöl",
            "Visbek",
            "Visselhövede",
            "Vlotho",
            "Vockerode",
            "Voerde",
            "Vögelsen",
            "Vogt",
            "Vogtareuth",
            "Vogtsburg",
            "Vohburg an der Donau",
            "Vohenstrauß",
            "Vöhl",
            "Vöhrenbach",
            "Vöhringen",
            "Vöhringen",
            "Voigtstedt",
            "Volkach",
            "Volkenschwand",
            "Völkershausen",
            "Volkertshausen",
            "Völklingen",
            "Volkmarsen",
            "Volkstedt",
            "Vollersode",
            "Völpke",
            "Voltlage",
            "Volxheim",
            "Vorbach",
            "Vordorf",
            "Vorra",
            "Vörstetten",
            "Vorwerk",
            "Vreden",
            "Vrees",
            "Waabs",
            "Waake",
            "Waakirchen",
            "Waal",
            "Wabern",
            "Wachau",
            "Wachenheim",
            "Wachenroth",
            "Wachtberg",
            "Wachtendonk",
            "Wächtersbach",
            "Wacken",
            "Wackernheim",
            "Wackerow",
            "Wackersberg",
            "Wackersdorf",
            "Wadern",
            "Wadersloh",
            "Wadgassen",
            "Waffenbrunn",
            "Wagenfeld",
            "Wagenhoff",
            "Waghäusel",
            "Waging am See",
            "Wahlitz",
            "Wahlstedt",
            "Wahn-Heide",
            "Wahrenholz",
            "Waiblingen",
            "Waibstadt",
            "Waidhaus",
            "Waidhofen",
            "Waidmannslust",
            "Waigolshausen",
            "Wain",
            "Waischenfeld",
            "Walchum",
            "Wald",
            "Wald",
            "Wald-Michelbach",
            "Waldalgesheim",
            "Waldaschaff",
            "Waldböckelheim",
            "Waldbreitbach",
            "Waldbröl",
            "Waldbrunn",
            "Waldbrunn",
            "Waldburg",
            "Waldbüttelbrunn",
            "Waldeck",
            "Waldems",
            "Waldenbuch",
            "Waldenburg",
            "Waldenburg",
            "Walderbach",
            "Waldershof",
            "Waldfeucht",
            "Waldfischbach-Burgalben",
            "Waldheim",
            "Waldkappel",
            "Waldkirch",
            "Waldkirchen",
            "Waldkirchen",
            "Waldkraiburg",
            "Waldmohr",
            "Waldmünchen",
            "Waldrach",
            "Waldsassen",
            "Waldsee",
            "Waldshut-Tiengen",
            "Waldsieversdorf",
            "Waldstetten",
            "Waldstetten",
            "Waldthurn",
            "Walheim",
            "Walkenried",
            "Walkertshofen",
            "Walldorf",
            "Walldorf",
            "Walldürn",
            "Wallendorf",
            "Wallenfels",
            "Wallenhorst",
            "Wallerfangen",
            "Wallerfing",
            "Wallersdorf",
            "Wallerstein",
            "Wallertheim",
            "Wallgau",
            "Wallhausen",
            "Wallhausen",
            "Wallhausen",
            "Wallmenroth",
            "Wallmerod",
            "Walluf",
            "Wallwitz",
            "Walpertskirchen",
            "Walschleben",
            "Walsdorf",
            "Walsrode",
            "Waltenhofen",
            "Waltershausen",
            "Waltrop",
            "Wandersleben",
            "Wanderup",
            "Wandlitz",
            "Wandsbek",
            "Wanfried",
            "Wang",
            "Wangels",
            "Wangen",
            "Wangerooge",
            "Wankendorf",
            "Wanna",
            "Wannsee",
            "Wannweil",
            "Wansleben",
            "Wanzleben",
            "Warburg",
            "Wardenburg",
            "Wardow",
            "Waren",
            "Warendorf",
            "Warin",
            "Warmensteinach",
            "Warmsen",
            "Warnemünde",
            "Warngau",
            "Warnow",
            "Warstein",
            "Wartenberg",
            "Wartenberg",
            "Warthausen",
            "Wartmannsroth",
            "Wasbek",
            "Wasbüttel",
            "Wäschenbeuren",
            "Wassenach",
            "Wassenberg",
            "Wasserburg",
            "Wasserburg am Inn",
            "Wasserleben",
            "Wasserliesch",
            "Wasserlosen",
            "Wassertrüdingen",
            "Wasungen",
            "Wathlingen",
            "Wattenbek",
            "Wattenheim",
            "Wattmannshagen",
            "Waxweiler",
            "Wechingen",
            "Wechselburg",
            "Weddelbrook",
            "Weddersleben",
            "Wedding",
            "Weddingstedt",
            "Wedel",
            "Weener",
            "Wees",
            "Weeze",
            "Wefensleben",
            "Weferlingen",
            "Wegberg",
            "Wegeleben",
            "Wegscheid",
            "Wehingen",
            "Wehr",
            "Wehr",
            "Wehrheim",
            "Wehringen",
            "Weibern",
            "Weibersbrunn",
            "Weichering",
            "Weichs",
            "Weida",
            "Weiden",
            "Weidenbach",
            "Weidenstetten",
            "Weidenthal",
            "Weidhausen bei Coburg",
            "Weiding",
            "Weigendorf",
            "Weihenzell",
            "Weiherhammer",
            "Weihmichl",
            "Weikersheim",
            "Weil",
            "Weil am Rhein",
            "Weil der Stadt",
            "Weil im Schönbuch",
            "Weilbach",
            "Weilburg",
            "Weiler",
            "Weiler-Simmerberg",
            "Weilerbach",
            "Weilersbach",
            "Weilerswist",
            "Weilheim",
            "Weilheim",
            "Weilheim an der Teck",
            "Weilmünster",
            "Weiltingen",
            "Weimar",
            "Weinbach",
            "Weinböhla",
            "Weingarten",
            "Weingarten",
            "Weinheim",
            "Weinsberg",
            "Weinsheim",
            "Weinstadt-Endersbach",
            "Weischlitz",
            "Weisel",
            "Weisen",
            "Weisenbach",
            "Weisendorf",
            "Weiskirchen",
            "Weismain",
            "Weissach",
            "Weißandt-Gölzau",
            "Weißbach",
            "Weißdorf",
            "Weißenberg",
            "Weißenborn",
            "Weißenborn",
            "Weißenborn-Lüderode",
            "Weißenbrunn",
            "Weißenburg in Bayern",
            "Weißenfels",
            "Weißenhorn",
            "Weißenohe",
            "Weißensberg",
            "Weißensee",
            "Weißensee",
            "Weißenstadt",
            "Weißenthurm",
            "Weißig",
            "Weißkeißel",
            "Weißwasser",
            "Weisweil",
            "Weitefeld",
            "Weiten",
            "Weitenhagen",
            "Weitersburg",
            "Weiterstadt",
            "Weitnau",
            "Weitramsdorf",
            "Welden",
            "Welle",
            "Wellen",
            "Wellendingen",
            "Wellingsbüttel",
            "Welschbillig",
            "Welsleben",
            "Welver",
            "Welzheim",
            "Welzow",
            "Wemding",
            "Wendeburg",
            "Wendelsheim",
            "Wendelstein",
            "Wenden",
            "Wendisch Evern",
            "Wendisch Rietz",
            "Wendlingen am Neckar",
            "Wendorf",
            "Wendtorf",
            "Weng",
            "Wennigsen",
            "Wentorf bei Hamburg",
            "Wenzenbach",
            "Wenzendorf",
            "Werbach",
            "Werben",
            "Werda",
            "Werdau",
            "Werder",
            "Werdervorstadt",
            "Werdohl",
            "Werftpfuhl",
            "Werl",
            "Werlte",
            "Wermelskirchen",
            "Wermsdorf",
            "Wernau",
            "Wernberg-Köblitz",
            "Werne",
            "Werneck",
            "Wernersberg",
            "Werneuchen",
            "Wernigerode",
            "Wernshausen",
            "Werpeloh",
            "Wertach",
            "Wertheim",
            "Werther",
            "Wertingen",
            "Wesel",
            "Weselberg",
            "Wesenberg",
            "Wesendorf",
            "Wesselburen",
            "Wesseling",
            "Wesseln",
            "Weßling",
            "Wessobrunn",
            "Weste",
            "Westend",
            "Westendorf",
            "Westensee",
            "Wester-Ohrstedt",
            "Westerburg",
            "Westeregeln",
            "Westergellersen",
            "Westerhausen",
            "Westerheim",
            "Westerheim",
            "Westerholt",
            "Westerhorn",
            "Westerkappeln",
            "Westerland",
            "Westerrönfeld",
            "Westerstede",
            "Westerstetten",
            "Westhagen",
            "Westhausen",
            "Westheim",
            "Westheim",
            "Westhofen",
            "Weststadt",
            "Wetschen",
            "Wetter",
            "Wetter (Ruhr)",
            "Wetterzeube",
            "Wettin",
            "Wettringen",
            "Wetzendorf",
            "Wetzlar",
            "Wewelsfleth",
            "Weyarn",
            "Weyerbusch",
            "Weyhausen",
            "Wickede",
            "Widdern",
            "Wieda",
            "Wiedemar",
            "Wiedensahl",
            "Wiedergeltingen",
            "Wiederstedt",
            "Wiednitz",
            "Wiefelstede",
            "Wiehe",
            "Wiehl",
            "Wiek",
            "Wiemersdorf",
            "Wienhausen",
            "Wieren",
            "Wiernsheim",
            "Wiesa",
            "Wiesau",
            "Wiesbaden",
            "Wiesen",
            "Wiesenau",
            "Wiesenbach",
            "Wiesenburg",
            "Wiesenfelden",
            "Wiesensteig",
            "Wiesent",
            "Wiesenthau",
            "Wiesentheid",
            "Wiesloch",
            "Wiesmoor",
            "Wiesthal",
            "Wietmarschen",
            "Wietze",
            "Wietzen",
            "Wietzendorf",
            "Wiggensbach",
            "Wilburgstetten",
            "Wildberg",
            "Wildemann",
            "Wildenberg",
            "Wildenfels",
            "Wildenhain",
            "Wildeshausen",
            "Wildflecken",
            "Wildpoldsried",
            "Wildsteig",
            "Wilgartswiesen",
            "Wilhelmsdorf",
            "Wilhelmsdorf",
            "Wilhelmsfeld",
            "Wilhelmshaven",
            "Wilhelmsruh",
            "Wilhelmstadt",
            "Wilhelmsthal",
            "Wilhermsdorf",
            "Wilkau-Haßlau",
            "Willanzheim",
            "Willebadessen",
            "Willich",
            "Willingen",
            "Willingshausen",
            "Willmering",
            "Willstätt",
            "Wilmersdorf",
            "Wilnsdorf",
            "Wilsdruff",
            "Wilstedt",
            "Wilster",
            "Wilsum",
            "Wilthen",
            "Wiltingen",
            "Wimmelburg",
            "Wimsheim",
            "Wincheringen",
            "Windach",
            "Windberg",
            "Windeby",
            "Windelsbach",
            "Winden",
            "Windesheim",
            "Windhagen",
            "Windhausen",
            "Windischeschenbach",
            "Windischleuba",
            "Windsbach",
            "Wingerode",
            "Wingst",
            "Winhöring",
            "Winkelhaid",
            "Winklarn",
            "Winnenden",
            "Winningen",
            "Winnweiler",
            "Winsen",
            "Winterbach",
            "Winterberg",
            "Winterhausen",
            "Winterhude",
            "Winterlingen",
            "Wintersdorf",
            "Winzer",
            "Wipfeld",
            "Wipperdorf",
            "Wipperfürth",
            "Wippra",
            "Wirdum",
            "Wirges",
            "Wirsberg",
            "Wischhafen",
            "Wismar",
            "Wissen",
            "Wistedt",
            "Wittelshofen",
            "Witten",
            "Wittenau",
            "Wittenberg",
            "Wittenberge",
            "Wittenburg",
            "Wittenförden",
            "Wittenhagen",
            "Witterda",
            "Wittgensdorf",
            "Wittichenau",
            "Wittingen",
            "Wittislingen",
            "Wittlich",
            "Wittmar",
            "Wittmund",
            "Wittnau",
            "Wittorf",
            "Wittstock",
            "Witzenhausen",
            "Witzhave",
            "Witzmannsberg",
            "Wohldorf-Ohlstedt",
            "Wohltorf",
            "Wohnbach",
            "Wöhrden",
            "Woldegk",
            "Wolfach",
            "Wolfegg",
            "Wolfen",
            "Wolfenbüttel",
            "Wolferode",
            "Wolfersdorf",
            "Wölfersheim",
            "Wolferstadt",
            "Wolfertschwenden",
            "Wolfhagen",
            "Wölfis",
            "Wolframs-Eschenbach",
            "Wolfratshausen",
            "Wolfsburg",
            "Wolfschlugen",
            "Wolfsegg",
            "Wolfstein",
            "Wolgast",
            "Wolken",
            "Wolkenstein",
            "Wolkramshausen",
            "Wollbach",
            "Wöllstein",
            "Wolmirsleben",
            "Wolmirstedt",
            "Wolnzach",
            "Wolpertshausen",
            "Wolpertswende",
            "Wölpinghausen",
            "Wolsdorf",
            "Woltersdorf",
            "Woltersdorf",
            "Wonfurt",
            "Wonsees",
            "Worbis",
            "Woringen",
            "Wörlitz",
            "Worms",
            "Worpswede",
            "Wörrstadt",
            "Wört",
            "Wörth",
            "Wörth am Main",
            "Wörth am Rhein",
            "Wörth an der Donau",
            "Wörth an der Isar",
            "Wörthsee",
            "Wössingen",
            "Wremen",
            "Wrestedt",
            "Wriedel",
            "Wriezen",
            "Wrist",
            "Wulfen",
            "Wülfershausen",
            "Wülfrath",
            "Wulfsen",
            "Wulften",
            "Wulkenzin",
            "Wülknitz",
            "Wulsbüttel",
            "Wünnenberg",
            "Wünschendorf",
            "Wunsiedel",
            "Wunstorf",
            "Wuppertal",
            "Wurmannsquick",
            "Wurmberg",
            "Wurmlingen",
            "Wurmsham",
            "Würselen",
            "Wurzbach",
            "Würzburg",
            "Wurzen",
            "Wüstenrot",
            "Wusterhausen",
            "Wusterhusen",
            "Wustermark",
            "Wusterwitz",
            "Wustrow",
            "Wustrow",
            "Wutöschingen",
            "Wyhl",
            "Wyk auf Föhr",
            "Xanten",
            "Zabeltitz",
            "Zaberfeld",
            "Zachenberg",
            "Zahna",
            "Zaisenhausen",
            "Zandt",
            "Zangberg",
            "Zapfendorf",
            "Zappendorf",
            "Zarpen",
            "Zarrendorf",
            "Zarrentin",
            "Zehdenick",
            "Zehlendorf",
            "Zeil",
            "Zeilarn",
            "Zeiskam",
            "Zeithain",
            "Zeitlarn",
            "Zeitlofs",
            "Zeitz",
            "Zell",
            "Zell",
            "Zell am Main",
            "Zell im Fichtelgebirge",
            "Zell im Wiesental",
            "Zell unter Aichelberg",
            "Zella-Mehlis",
            "Zellingen",
            "Zeltingen-Rachtig",
            "Zemmer",
            "Zenting",
            "Zerbst",
            "Zerf",
            "Zernien",
            "Zetel",
            "Zeulenroda",
            "Zeuthen",
            "Zeven",
            "Zielitz",
            "Ziemetshausen",
            "Zierenberg",
            "Ziertheim",
            "Ziesar",
            "Ziesendorf",
            "Ziltendorf",
            "Zimmern ob Rottweil",
            "Zingst",
            "Zinna",
            "Zirndorf",
            "Zittau",
            "Zöblitz",
            "Zolling",
            "Zörbig",
            "Zorge",
            "Zorneding",
            "Zornheim",
            "Zöschen",
            "Zossen",
            "Zschadrass",
            "Zschepplin",
            "Zscherben",
            "Zscherndorf",
            "Zschopau",
            "Zschorlau",
            "Zschornewitz",
            "Zschortau",
            "Zulpich",
            "Zurow",
            "Zusamaltheim",
            "Zusmarshausen",
            "Züssow",
            "Zuzenhausen",
            "Zweibrücken",
            "Zweiflingen",
            "Zwenkau",
            "Zwickau",
            "Zwiefalten",
            "Zwiesel",
            "Zwingenberg",
            "Zwochau",
            "Zwönitz",
            "Zwota"
        ]
    },
    {
        "label": "Ghana",
        "code": "GH",
        "phone": "233",
        "cities": [
            "Aboso",
            "Aburi",
            "Accra",
            "Aflao",
            "Agogo",
            "Akim Oda",
            "Akim Swedru",
            "Akropong",
            "Akwatia",
            "Anloga",
            "Aowin",
            "Apam",
            "Asamankese",
            "Asunafo North",
            "Asunafo South",
            "Asutifi North",
            "Asutifi South",
            "Atebubu-Amantin",
            "Atsiaman",
            "Axim",
            "Banda",
            "Bawku",
            "Begoro",
            "Bekwai",
            "Berekum East",
            "Berekum West",
            "Bia East",
            "Bia West",
            "Biakoye",
            "Bibiani",
            "Bibiani-Anhwiaso-Bekwai",
            "Bodi",
            "Bole",
            "Bolgatanga",
            "Bunkpurugu-Nyakpanduri",
            "Cape Coast",
            "Central Gonja",
            "Chereponi",
            "Dome",
            "Dormaa Central",
            "Dormaa East",
            "Dormaa West",
            "Dunkwa",
            "East Gonja",
            "East Mamprusi",
            "Ejura",
            "Elmina",
            "Foso",
            "Gbawe",
            "Ho",
            "Hohoe",
            "Jaman North",
            "Jaman South",
            "Jasikan",
            "Juaboso",
            "Kadjebi",
            "Kasoa",
            "Keta",
            "Kete Krachi",
            "Kibi",
            "Kintampo North",
            "Kintampo South",
            "Koforidua",
            "Konongo",
            "Kpandae",
            "Kpandu",
            "Krachi East",
            "Krachi Nchumuru",
            "Krachi West",
            "Kumasi",
            "Mampong",
            "Mamprugu-Moagduri",
            "Medina Estates",
            "Mpraeso",
            "Mumford",
            "Navrongo",
            "Nkoranza North",
            "Nkoranza South",
            "Nkwanta North",
            "Nkwanta South",
            "North East Gonja",
            "North Gonja",
            "Nsawam",
            "Nungua",
            "Obuase",
            "Prestea",
            "Pru East",
            "Pru West",
            "Salaga",
            "Saltpond",
            "Savelugu",
            "Sawla-Tuna-Kalba",
            "Sefwi-Akontombra",
            "Sefwi-Wiawso",
            "Sekondi-Takoradi",
            "Sene East",
            "Sene West",
            "Shama Junction",
            "Suaman",
            "Suhum",
            "Sunyani",
            "Sunyani West",
            "Swedru",
            "Tafo",
            "Tain",
            "Takoradi",
            "Tamale",
            "Tano North",
            "Tano South",
            "Tarkwa",
            "Techiman",
            "Techiman North",
            "Tema",
            "Teshi Old Town",
            "Wa",
            "Wenchi",
            "West Gonja",
            "West Mamprusi",
            "Winneba",
            "Yendi",
            "Yunyoo-Nasuan"
        ]
    },
    {
        "label": "Gibraltar",
        "code": "GI",
        "phone": "350",
        "cities": []
    },
    {
        "label": "Greece",
        "code": "GR",
        "phone": "30",
        "cities": [
            "Acharávi",
            "Acharnés",
            "Adámas",
            "Ádendro",
            "Aegina",
            "Afántou",
            "Afidnés",
            "Afrátion",
            "Áfytos",
            "Aghios Panteleímon",
            "Agía Foteiní",
            "Agía Galíni",
            "Agía Kyriakí",
            "Agía Marína",
            "Agía Marína",
            "Agía Marína",
            "Agía Paraskeví",
            "Agía Paraskeví",
            "Agía Triáda",
            "Agía Triáda",
            "Agía Triáda",
            "Agía Varvára",
            "Agía Varvára",
            "Ágio Pnévma",
            "Ágioi Anárgyroi",
            "Ágioi Déka",
            "Ágioi Theódoroi",
            "Ágios Andréas",
            "Ágios Athanásios",
            "Ágios Athanásios",
            "Agios Dimitrios",
            "Agios Dimítrios Kropiás",
            "Ágios Geórgios",
            "Ágios Geórgios",
            "Agios Georgis",
            "Agios Ioannis Rentis",
            "Ágios Loukás",
            "Ágios Matthaíos",
            "Ágios Nikólaos",
            "Ágios Nikólaos",
            "Ágios Pávlos",
            "Ágios Pétros",
            "Ágios Spyrídon",
            "Ágios Stéfanos",
            "Ágios Vasíleios",
            "Agkathiá",
            "Agnanteró",
            "Agriá",
            "Agrínio",
            "Aianí",
            "Aiánteio",
            "Aidipsós",
            "Aigáleo",
            "Aigínio",
            "Aígio",
            "Aitolikó",
            "Aíyira",
            "Akraifnía",
            "Akráta",
            "Akriní",
            "Akrolímni",
            "Aktaío",
            "Alepoú",
            "Alexándreia",
            "Alexandroupoli",
            "Alfeioúsa",
            "Alíartos",
            "Álimos",
            "Alistráti",
            "Alivéri",
            "Álli Meriá",
            "Almyrós",
            "Amaliáda",
            "Amárynthos",
            "Ambelókipoi",
            "Amfíkleia",
            "Amfilochía",
            "Ámfissa",
            "Ammochóri",
            "Amorgós",
            "Ampelákia",
            "Ampeleíes",
            "Ampelókipoi",
            "Amygdaleónas",
            "Amýntaio",
            "Anáfi",
            "Anakasiá",
            "Anaráchi",
            "Anatolí",
            "Anatolikó",
            "Anávra",
            "Anávyssos",
            "Andravída",
            "Andros",
            "Anéza",
            "Angelochóri",
            "Angelókastro",
            "Ano Arhanes",
            "Áno Kalentíni",
            "Áno Kastrítsi",
            "Áno Kómi",
            "Áno Lekhónia",
            "Áno Liósia",
            "Áno Merá",
            "Áno Sýros",
            "Anógeia",
            "Anoixi",
            "Anthíli",
            "Anthiró",
            "Anthoúsa",
            "Antikyra",
            "Antimácheia",
            "Antíparos",
            "Antirrio",
            "Ápsalos",
            "Aráchova",
            "Áratos",
            "Aravissós",
            "Archaía Olympía",
            "Archángelos",
            "Archontochóri",
            "Arfará",
            "Argalastí",
            "Argithéa",
            "Árgos",
            "Argos Orestiko",
            "Argostólion",
            "Argyroúpoli",
            "Aria",
            "Aridaía",
            "Áris",
            "Arísvi",
            "Arkalochóri",
            "Arkhaía Kórinthos",
            "Arkoúdi",
            "Árma",
            "Arnaía",
            "Árnissa",
            "Arrianá",
            "Arsénio",
            "Árta",
            "Artémida",
            "Artesianó",
            "Asímion",
            "Asíni",
            "Askós",
            "Asopía",
            "Asopós",
            "Asprángeloi",
            "Asprópyrgos",
            "Asproválta",
            "Ássiros",
            "Ássos",
            "Astakós",
            "Ástros",
            "Astypálaia",
            "Asvestochóri",
            "Atalánti",
            "Athens",
            "Athíkia",
            "Áthyra",
            "Atsipópoulo",
            "Ávato",
            "Avlónas",
            "Axioúpoli",
            "Axós",
            "Ayía Triás",
            "Áyioi Apóstoloi",
            "Áyios Adhrianós",
            "Áyios Konstandínos",
            "Áyios Konstandínos",
            "Áyios Nikólaos",
            "Áyios Thomás",
            "Áyios Vasílios",
            "Chaïdári",
            "Chairóneia",
            "Chalandrítsa",
            "Chalástra",
            "Chálki",
            "Chalkiádes",
            "Chalkída",
            "Chalkidóna",
            "Chaniá",
            "Charopó",
            "Chávari",
            "Chlói",
            "Cholargós",
            "Chóra",
            "Chóra Sfakíon",
            "Choristí",
            "Chortiátis",
            "Chrysó",
            "Chrysochórafa",
            "Chrysochóri",
            "Chrysoúpolis",
            "Corfu",
            "Darátsos",
            "Delphi",
            "Deskáti",
            "Dhafní",
            "Dhokímion",
            "Dhráfi",
            "Dhrosiá",
            "Diavatá",
            "Diavatós",
            "Dídyma",
            "Didymóteicho",
            "Dílesi",
            "Dimitsána",
            "Díon",
            "Dióni",
            "Diónysos",
            "Dístomo",
            "Domokós",
            "Domvraína",
            "Dráma",
            "Drapetsóna",
            "Dravískos",
            "Drépanon",
            "Drosiá",
            "Drymós",
            "Echínos",
            "Édessa",
            "Efkarpía",
            "Eirinoúpoli",
            "Ekáli",
            "Eksochí",
            "Elaiochóri",
            "Eláteia",
            "Elefsína",
            "Eleftherés",
            "Eleftheroúpolis",
            "Eleoúsa",
            "Ellinikó",
            "Eloúnda",
            "Émponas",
            "Emporeío",
            "Empório",
            "Epanomí",
            "Episkopí",
            "Epitálio",
            "Erátyra",
            "Erétria",
            "Ermióni",
            "Ermoúpolis",
            "Erythrés",
            "Évlalo",
            "Évosmos",
            "Evropós",
            "Evxinoúpolis",
            "Examília",
            "Exaplátanos",
            "Faliraki",
            "Farkadóna",
            "Fáros",
            "Féres",
            "Fíki",
            "Filiátes",
            "Filiatrá",
            "Filippiáda",
            "Fíliro",
            "Fílla",
            "Fillýra",
            "Filótas",
            "Filothéi",
            "Filótion",
            "Firá",
            "Flórina",
            "Folégandros",
            "Fotolívos",
            "Fry",
            "Fteliá",
            "Fylí",
            "Fyteíes",
            "Gáïos",
            "Gaïtánion",
            "Galatádes",
            "Galatás",
            "Galatás",
            "Galatás",
            "Galatiní",
            "Galátista",
            "Galátsi",
            "Galaxídhion",
            "Gargaliánoi",
            "Gastoúni",
            "Gavaloú",
            "Gázi",
            "Gázoros",
            "Géfyra",
            "Géfyra",
            "Genisséa",
            "Georgioupolis",
            "Gerakaroú",
            "Gérakas",
            "Geráki",
            "Geráni",
            "Gérgeri",
            "Giannitsá",
            "Glyfáda",
            "Gómfoi",
            "Gouménissa",
            "Goúmero",
            "Goúrnes",
            "Gra Liyiá",
            "Graikochóri",
            "Grammatikó",
            "Grammenítsa",
            "Grevená",
            "Grizáno",
            "Gýtheio",
            "Heraklion",
            "Ialysós",
            "Íasmos",
            "Ierápetra",
            "Ierissós",
            "Igoumenítsa",
            "Iliokentima",
            "Ílion",
            "Ilioúpoli",
            "Ioánnina",
            "Íos",
            "Irákleia",
            "Irákleio",
            "Irákleion",
            "Isthmía",
            "Istiaía",
            "Itéa",
            "Itéa",
            "Itháki",
            "Kainoúryion",
            "Kainoúryion",
            "Kaisarianí",
            "Kalá Déndra",
            "Kalamariá",
            "Kalamata",
            "Kalamiá",
            "Kálamos",
            "Kalampáka",
            "Kalampáki",
            "Kalávryta",
            "Kalí",
            "Kalí Vrýsi",
            "Kallifóni",
            "Kallífytos",
            "Kallithéa",
            "Kallithéa",
            "Kallíthiro",
            "Kalochóri",
            "Kalós Agrós",
            "Kalpáki",
            "Kálymnos",
            "Kalýves",
            "Kalýves Polygýrou",
            "Kalývia",
            "Kalývia Thorikoú",
            "Kamárai",
            "Kamariótissa",
            "Kamaterón",
            "Kaména Voúrla",
            "Kampánis",
            "Kanaláki",
            "Kanáli",
            "Kanália",
            "Kandíla",
            "Kapandríti",
            "Kaparéllion",
            "Karátoula",
            "Kardámaina",
            "Kardamás",
            "Kardamítsia",
            "Kardamýli",
            "Kardiá",
            "Kardítsa",
            "Karditsomagoúla",
            "Karellás",
            "Karítsa",
            "Karpathos",
            "Karpenísi",
            "Karpochóri",
            "Karyés",
            "Karyótissa",
            "Kárystos",
            "Kassándreia",
            "Kastaniés",
            "Kastélla",
            "Kastélli",
            "Kastoria",
            "Kastráki",
            "Kastrí",
            "Katastárion",
            "Kateríni",
            "Káto Achaḯa",
            "Káto Asítai",
            "Káto Dhiminió",
            "Káto Glykóvrysi",
            "Káto Goúves",
            "Káto Kamíla",
            "Káto Lekhónia",
            "Káto Lipochóri",
            "Káto Mazaráki",
            "Káto Miliá",
            "Káto Nevrokópi",
            "Káto Scholári",
            "Káto Soúlion",
            "Káto Tithoréa",
            "Katochí",
            "Katoúna",
            "Katsikás",
            "Kavála",
            "Kavallári",
            "Kavásila",
            "Kavýli",
            "Kéfalos",
            "Kentrí",
            "Keramotí",
            "Kerasochóri",
            "Keratéa",
            "Keratsíni",
            "Khalándrion",
            "Khalkoútsion",
            "Khiliomódhi",
            "Kiáto",
            "Kifisiá",
            "Kilkís",
            "Kimméria",
            "Kímolos",
            "Kinéta",
            "Kipséli",
            "Kírra",
            "Kíssamos",
            "Kítros",
            "Kítsi",
            "Kleidí",
            "Kleítos",
            "Koíla",
            "Koilás",
            "Koímisi",
            "Kokkíni Cháni",
            "Kokkinóchoma",
            "Kokkónion",
            "Kolchikón",
            "Kolindrós",
            "Kolympári",
            "Komniná",
            "Komotiní",
            "Kompóti",
            "Kónitsa",
            "Kontariótissa",
            "Kontokáli",
            "Kopanáki",
            "Kopanós",
            "Korinós",
            "Kórinthos",
            "Koróni",
            "Koropí",
            "Korydallós",
            "Koryfí",
            "Kos",
            "Kostakioí",
            "Koufália",
            "Kouloúra",
            "Koutselió",
            "Koutsopódi",
            "Kouvarás",
            "Kozáni",
            "Kranídi",
            "Kremastí",
            "Kréstena",
            "Kríkellos",
            "Krinídes",
            "Krithiá",
            "Kritsá",
            "Krokeés",
            "Krókos",
            "Krousón",
            "Krýa Vrýsi",
            "Kryonéri",
            "Kyllíni",
            "Kými",
            "Kýmina",
            "Kynopiástes",
            "Kyparissía",
            "Kyprínos",
            "Kypséli",
            "Kyrás Vrýsi",
            "Kýria",
            "Kyriáki",
            "Kýthira",
            "Kýthnos",
            "Lagkadás",
            "Lagós",
            "Lagyná",
            "Laimós",
            "Lakkí",
            "Lákkoma",
            "Lálas",
            "Lamía",
            "Langádhia",
            "Lápas",
            "Lárdos",
            "Lávara",
            "Lávrio",
            "Lechainá",
            "Léchovo",
            "Lefkada",
            "Lefkímmi",
            "Lefkónas",
            "Lékhaio",
            "Leondárion",
            "Leonídio",
            "Lepenoú",
            "Leptokaryá",
            "Levídion",
            "Lianokládhion",
            "Lianovérgi",
            "Lidoríki",
            "Ligourión",
            "Limenária",
            "Limín Khersonísou",
            "Limín Mesoyaías",
            "Límni",
            "Limnokhórion",
            "Lithakiá",
            "Lití",
            "Litóchoro",
            "Livadeiá",
            "Livaderó",
            "Livanátes",
            "Lixoúri",
            "Lófos",
            "Loukísia",
            "Loúros",
            "Loutrá Aidhipsoú",
            "Loutrá Oraías Elénis",
            "Loutráki",
            "Loutráki",
            "Loutrós",
            "Lýkeio",
            "Lykóvrysi",
            "Magoúla",
            "Magoúla",
            "Magoúla",
            "Magoúla",
            "Makrakómi",
            "Makrísia",
            "Makrochóri",
            "Makrychóri",
            "Makrýgialos",
            "Malakónta",
            "Malesína",
            "Mália",
            "Mándalo",
            "Mándra",
            "Mandráki",
            "Maniákoi",
            "Manoláda",
            "Mantoúdi",
            "Marathónas",
            "Marína",
            "Markópoulo",
            "Markópoulo Oropoú",
            "Marmárion",
            "Maroúsi",
            "Martínon",
            "Mataránga",
            "Mavrochóri",
            "Mavrommáti",
            "Mavrothálassa",
            "Mavrovoúni",
            "Megála Kalývia",
            "Megáli Khóra",
            "Megáli Panagía",
            "Megálo Chorió",
            "Megalochóri",
            "Megalochóri",
            "Megalópoli",
            "Mégara",
            "Megísti",
            "Meligalás",
            "Melíki",
            "Melíssi",
            "Melíssia",
            "Melissochóri",
            "Melíti",
            "Meneméni",
            "Menídi",
            "Mesariá",
            "Mesiméri",
            "Mesolóngi",
            "Mesopotamía",
            "Messíni",
            "Metamórfosi",
            "Methóni",
            "Metsovo",
            "Mikró Monastíri",
            "Mikrópolis",
            "Mílos",
            "Mindilóglion",
            "Mitrópoli",
            "Mitroúsi",
            "Moíres",
            "Mokhós",
            "Moláoi",
            "Mólos",
            "Monastiráki",
            "Morfovoúni",
            "Moskháton",
            "Moúlki",
            "Mourniés",
            "Mouzaki",
            "Mouzáki",
            "Mouzourás",
            "Mýki",
            "Mykonos",
            "Myrsíni",
            "Mytikas",
            "Náfpaktos",
            "Náfplio",
            "Náousa",
            "Náousa",
            "Náxos",
            "Néa Alikarnassós",
            "Néa Anatolí",
            "Néa Anchiálos",
            "Néa Apollonía",
            "Néa Artáki",
            "Néa Chalkidóna",
            "Néa Éfesos",
            "Néa Erythraía",
            "Néa Filadélfeia",
            "Néa Flogitá",
            "Néa Fókaia",
            "Néa Ionía",
            "Néa Ionía",
            "Néa Iraklítsa",
            "Néa Kallikráteia",
            "Néa Karváli",
            "Néa Karyá",
            "Néa Kíos",
            "Néa Lámpsakos",
            "Nea Lava",
            "Néa Magnisía",
            "Néa Mákri",
            "Néa Málgara",
            "Néa Manoláda",
            "Néa Mesimvría",
            "Néa Michanióna",
            "Néa Moudhaniá",
            "Néa Palátia",
            "Néa Pélla",
            "Néa Pentéli",
            "Néa Péramos",
            "Néa Péramos",
            "Néa Plágia",
            "Néa Poteídaia",
            "Néa Potídhaia",
            "Néa Róda",
            "Néa Sánta",
            "Néa Seléfkeia",
            "Néa Smýrni",
            "Néa Stíra",
            "Néa Tírins",
            "Néa Tríglia",
            "Néa Vrasná",
            "Néa Výssa",
            "Néa Zíchni",
            "Neapoli",
            "Neápoli",
            "Neápolis",
            "Neméa",
            "Néo Agionéri",
            "Néo Petrítsi",
            "Néo Psychikó",
            "Néo Rýsi",
            "Néo Soúli",
            "Neochóri",
            "Neochóri",
            "Neochóri",
            "Neochóri",
            "Neochórion",
            "Neochorópoulo",
            "Neochoroúda",
            "Néoi Epivátes",
            "Néon Monastírion",
            "Néos Marmarás",
            "Néos Mylótopos",
            "Néos Oropós",
            "Néos Skopós",
            "Neos Voutzás",
            "Nerokoúros",
            "Nestório",
            "Nigríta",
            "Níkaia",
            "Nikísiani",
            "Níkiti",
            "Nisí",
            "Nomós Achaḯas",
            "Nomós Aitolías kai Akarnanías",
            "Nomós Arkadías",
            "Nomós Chalkidikís",
            "Nomós Evrytanías",
            "Nomós Fokídos",
            "Nomós Ileías",
            "Nomós Ioannínon",
            "Nomós Irakleíou",
            "Nomós Kerkýras",
            "Nomós Kozánis",
            "Nomós Kykládon",
            "Nomós Péllis",
            "Nomós Rethýmnis",
            "Nomós Thessaloníkis",
            "Nomós Zakýnthou",
            "Oía",
            "Oichalía",
            "Oinófyta",
            "Ólynthos",
            "Omvriakí",
            "Oraiókastro",
            "Orchomenós",
            "Oreoí",
            "Orestiáda",
            "Ormýlia",
            "Ornós",
            "Oropós",
            "Ouranoupolis",
            "Ovriá",
            "Oxílithos",
            "Páchni",
            "Paianía",
            "Palaiá Epídavros",
            "Palaiá Fókaia",
            "Palaífyto",
            "Palaió Fáliro",
            "Palaió Tsiflíki",
            "Palaióchora",
            "Palaiochóri",
            "Palaiochóri",
            "Palaiokómi",
            "Palaiomonástiro",
            "Palaiópyrgos",
            "Pálairos",
            "Palamás",
            "Palekastro",
            "Pallíni",
            "Panaitólion",
            "Panórama",
            "Pánormos",
            "Pánormos",
            "Papágou",
            "Pappadátes",
            "Pappadhátai",
            "Paralía",
            "Paralía",
            "Paralía Avlídhos",
            "Paralía Ofryníou",
            "Paralía Vérgas",
            "Paramythiá",
            "Paranésti",
            "Parapótamos",
            "Paravóla",
            "Párga",
            "Páros",
            "Pásion",
            "Patitírion",
            "Pátmos",
            "Pátra",
            "Patrída",
            "Pediní",
            "Péfka",
            "Péfki",
            "Pefkochóri",
            "Pelasgía",
            "Pélla",
            "Pelópi",
            "Pentálofos",
            "Pentaplátano",
            "Pentéli",
            "Péplos",
            "Perachóra",
            "Peraía",
            "Perama",
            "Pérama",
            "Pérama",
            "Pérama",
            "Pérdika",
            "Perístasi",
            "Peristerá",
            "Peristéri",
            "Perivóli",
            "Perivólia",
            "Periyiáli",
            "Péta",
            "Peteinós",
            "Petrochóri",
            "Petroúpolis",
            "Petroússa",
            "Pigí",
            "Pikérmi",
            "Piraeus",
            "Pithári",
            "Plagiári",
            "Pláka Dílesi",
            "Platanórevma",
            "Plátanos",
            "Platariá",
            "Platý",
            "Políchni",
            "Politiká",
            "Polydéndri",
            "Polýgyros",
            "Polykárpi",
            "Polýkastro",
            "Pontisméno",
            "Póros",
            "Póros",
            "Portariá",
            "Portariá",
            "Pórto Chéli",
            "Potamiá",
            "Potamós",
            "Prámanta",
            "Préveza",
            "Prínos",
            "Proástio",
            "Próchoma",
            "Profítis Ilías",
            "Profítis Ilías",
            "Prokópi",
            "Prómachoi",
            "Prosotsáni",
            "Próti",
            "Provatás",
            "Psachná",
            "Psychikó",
            "Pteleós",
            "Ptolemaḯda",
            "Pylaía",
            "Pylí",
            "Pýli",
            "Pýlos",
            "Pyrgetós",
            "Pýrgos",
            "Pýrgos",
            "Rafína",
            "Rethymno",
            "Río",
            "Ritíni",
            "Rizári",
            "Rízia",
            "Rizó",
            "Rízoma",
            "Rizómata",
            "Rizómylos",
            "Rodhítsa",
            "Rododáfni",
            "Rodolívos",
            "Rodópoli",
            "Ródos",
            "Rodotópi",
            "Roviés",
            "Royítika",
            "Salamína",
            "Sámi",
            "Samothráki",
            "Sápes",
            "Sardínia",
            "Saronída",
            "Sárti",
            "Savália",
            "Schimatári",
            "Schísma Eloúndas",
            "Sélero",
            "Selínia",
            "Sérifos",
            "Sérres",
            "Sérvia",
            "Sevastianá",
            "Sfendámi",
            "Siátista",
            "Sidirókastro",
            "Sikyón",
            "Símantra",
            "Síndos",
            "Sísion",
            "Sitagroí",
            "Sitia",
            "Skála",
            "Skála",
            "Skála Oropoú",
            "Skalánion",
            "Skarmagkás",
            "Skiáthos",
            "Skópelos",
            "Skotoússa",
            "Skoútari",
            "Skýdra",
            "Skýros",
            "Smínthi",
            "Sochós",
            "Sofádes",
            "Sofikón",
            "Sosándra",
            "Soúda",
            "Souflí",
            "Sourotí",
            "Soúrpi",
            "Spárti",
            "Spáta",
            "Spercheiáda",
            "Sperchógeia",
            "Spétses",
            "Stalís",
            "Stamáta",
            "Stános",
            "Stathmós Mourión",
            "Stavráki",
            "Stavrós",
            "Stavrós",
            "Stavroúpoli",
            "Stefanovíkeio",
            "Steíri",
            "Stratónion",
            "Strymonikó",
            "Stylída",
            "Svorónos",
            "Sykiá",
            "Sykiá",
            "Sykiés",
            "Sylivainiótika",
            "Sými",
            "Synoikismós Chavaríou",
            "Távros",
            "Taxiárches",
            "Témeni",
            "Terpní",
            "Thásos",
            "Thérmi",
            "Thérmo",
            "Thespiés",
            "Thesprotikó",
            "Thessaloníki",
            "Thívai",
            "Thouría",
            "Thrakomakedónes",
            "Thrapsanón",
            "Tílisos",
            "Tínos",
            "Tolón",
            "Traganón",
            "Triandaíika",
            "Triandría",
            "Tríkala",
            "Tríkala",
            "Tríkeri",
            "Trílofos",
            "Trípoli",
            "Tsiflikópoulo",
            "Tsikalariá",
            "Tsotíli",
            "Tycheró",
            "Tympáki",
            "Vágia",
            "Valsamáta",
            "Valteró",
            "Vamvakófyto",
            "Vanáton",
            "Várda",
            "Vári",
            "Vári",
            "Varnávas",
            "Vartholomió",
            "Varvásaina",
            "Varybóbi",
            "Vasiliká",
            "Vasilikí",
            "Vasilikón",
            "Vathí",
            "Vathý",
            "Vathýlakkos",
            "Velestíno",
            "Vélo",
            "Velventós",
            "Vergína",
            "Véroia",
            "Vília",
            "Violí Charáki",
            "Virós",
            "Vlachiótis",
            "Vlachópoulo",
            "Vlycháda",
            "Vokhaïkó",
            "Vólakas",
            "Volos",
            "Vónitsa",
            "Voúla",
            "Voulgaréli",
            "Vouliagméni",
            "Vounoplagiá",
            "Vrachnaíika",
            "Vrakháti",
            "Vraná",
            "Vrilissia",
            "Vrontoú",
            "Vrýses",
            "Výronas",
            "Xánthi",
            "Xilópolis",
            "Xinó Neró",
            "Xiropótamos",
            "Xylaganí",
            "Xylókastro",
            "Ýdra",
            "Yimnón",
            "Ymittos",
            "Zacháro",
            "Zagorá",
            "Zakynthos",
            "Zárkos",
            "Zarós",
            "Zefyri",
            "Zevgolateió",
            "Zipári",
            "Zográfos",
            "Zonianá",
            "Zygós"
        ]
    },
    {
        "label": "Greenland",
        "code": "GL",
        "phone": "299",
        "cities": []
    },
    {
        "label": "Grenada",
        "code": "GD",
        "phone": "+1-473",
        "cities": [
            "Gouyave",
            "Grenville",
            "Hillsborough",
            "Saint David’s",
            "Saint George's",
            "Sauteurs",
            "Victoria"
        ]
    },
    {
        "label": "Guadeloupe",
        "code": "GP",
        "phone": "590",
        "cities": []
    },
    {
        "label": "Guam",
        "code": "GU",
        "phone": "+1-671",
        "cities": []
    },
    {
        "label": "Guatemala",
        "code": "GT",
        "phone": "502",
        "cities": [
            "Acatenango",
            "Agua Blanca",
            "Aguacatán",
            "Almolonga",
            "Alotenango",
            "Amatitlán",
            "Antigua Guatemala",
            "Asunción Mita",
            "Atescatempa",
            "Barberena",
            "Barillas",
            "Cabricán",
            "Cahabón",
            "Cajolá",
            "Camotán",
            "Canillá",
            "Cantel",
            "Casillas",
            "Catarina",
            "Chahal Guatemala",
            "Chajul",
            "Champerico",
            "Chiantla",
            "Chicacao",
            "Chicamán",
            "Chiché",
            "Chichicastenango",
            "Chimaltenango",
            "Chinautla",
            "Chinique",
            "Chiquimula",
            "Chiquimulilla",
            "Chisec",
            "Chuarrancho",
            "Ciudad Tecún Umán",
            "Ciudad Vieja",
            "Coatepeque",
            "Cobán",
            "Colomba",
            "Colotenango",
            "Comalapa",
            "Comapa",
            "Comitancillo",
            "Concepción",
            "Concepción Chiquirichapa",
            "Concepción Huista",
            "Concepción Las Minas",
            "Concepción Tutuapa",
            "Conguaco",
            "Cubulco",
            "Cuilapa",
            "Cuilco",
            "Cunén",
            "Cuyotenango",
            "Dolores",
            "El Adelanto",
            "El Asintal",
            "El Chol",
            "El Estor",
            "El Jícaro",
            "El Palmar",
            "El Progreso",
            "El Quetzal",
            "El Rodeo",
            "El Tejar",
            "El Tumbador",
            "Escuintla",
            "Esquipulas",
            "Esquipulas Palo Gordo",
            "Flores",
            "Flores Costa Cuca",
            "Fraijanes",
            "Génova",
            "Granados",
            "Guanagazapa",
            "Guastatoya",
            "Guatemala City",
            "Guazacapán",
            "Huehuetenango",
            "Huitán",
            "Ipala",
            "Ixchiguán",
            "Ixtahuacán",
            "Iztapa",
            "Jacaltenango",
            "Jalapa",
            "Jalpatagua",
            "Jerez",
            "Jocotán",
            "Jocotenango",
            "Joyabaj",
            "Jutiapa",
            "La Democracia",
            "La Esperanza",
            "La Gomera",
            "La Libertad",
            "La Libertad",
            "La Reforma",
            "La Tinta",
            "Lanquín",
            "Lívingston",
            "Los Amates",
            "Magdalena Milpas Altas",
            "Malacatán",
            "Malacatancito",
            "Masagua",
            "Mataquescuintla",
            "Mazatenango",
            "Melchor de Mencos",
            "Mixco",
            "Momostenango",
            "Monjas",
            "Morales",
            "Morazán",
            "Moyuta",
            "Municipio de Almolonga",
            "Municipio de Alotenango",
            "Municipio de Antigua Guatemala",
            "Municipio de Asunción Mita",
            "Municipio de Cabricán",
            "Municipio de Canillá",
            "Municipio de Cantel",
            "Municipio de Casillas",
            "Municipio de Chajul",
            "Municipio de Chicaman",
            "Municipio de Chiché",
            "Municipio de Chichicastenango",
            "Municipio de Chinique",
            "Municipio de Chiquimulilla",
            "Municipio de Ciudad Vieja",
            "Municipio de Coatepeque",
            "Municipio de Colomba",
            "Municipio de Concepción Chiquirichapa",
            "Municipio de Concepción Tutuapa",
            "Municipio de Cunén",
            "Municipio de Flores",
            "Municipio de Flores Costa Cuca",
            "Municipio de Guazacapán",
            "Municipio de Ixcan",
            "Municipio de Jalapa",
            "Municipio de Jocotenango",
            "Municipio de Joyabaj",
            "Municipio de Magdalena Milpas Altas",
            "Municipio de Malacatán",
            "Municipio de Mataquescuintla",
            "Municipio de Momostenango",
            "Municipio de Morales",
            "Municipio de Nahualá",
            "Municipio de Pachalum",
            "Municipio de Panajachel",
            "Municipio de Patzité",
            "Municipio de Poptún",
            "Municipio de Puerto Barrios",
            "Municipio de San Andrés",
            "Municipio de San Andrés Sajcabajá",
            "Municipio de San Antonio Ilotenango",
            "Municipio de San Antonio Suchitepéquez",
            "Municipio de San Benito",
            "Municipio de San Felipe",
            "Municipio de San Francisco",
            "Municipio de San Juan Cotzal",
            "Municipio de San Juan Ostuncalco",
            "Municipio de San Miguel Panán",
            "Municipio de San Pedro Jocopilas",
            "Municipio de Santa Ana",
            "Municipio de Santa Bárbara",
            "Municipio de Santa Catarina Palopó",
            "Municipio de Santa Cruz La Laguna",
            "Municipio de Santa Lucía Milpas Altas",
            "Municipio de Santa María Chiquimula",
            "Municipio de Santa María de Jesús",
            "Municipio de Sayaxché",
            "Municipio de Sipacapa",
            "Municipio de Tejutla",
            "Municipio de Totonicapán",
            "Municipio de Uspantán",
            "Municipio de Zacualpa",
            "Nahualá",
            "Nebaj",
            "Nentón",
            "Nueva Concepción",
            "Nueva Santa Rosa",
            "Nuevo Progreso",
            "Nuevo San Carlos",
            "Ocós",
            "Olintepeque",
            "Olopa",
            "Oratorio",
            "Ostuncalco",
            "Pachalum",
            "Pajapita",
            "Palencia",
            "Palestina de los Altos",
            "Palín",
            "Panajachel",
            "Panzós",
            "Parramos",
            "Pasaco",
            "Pastores",
            "Patulul",
            "Patzicía",
            "Patzité",
            "Patzún",
            "Petapa",
            "Playa Grande",
            "Pochuta",
            "Poptún",
            "Pueblo Nuevo",
            "Pueblo Nuevo Viñas",
            "Puerto Barrios",
            "Puerto San José",
            "Purulhá",
            "Quesada",
            "Quetzaltenango",
            "Quezaltepeque",
            "Rabinal",
            "Retalhuleu",
            "Río Blanco",
            "Río Bravo",
            "Sacapulas",
            "Salamá",
            "Salcajá",
            "Samayac",
            "San Agustín Acasaguastlán",
            "San Andrés",
            "San Andrés Itzapa",
            "San Andrés Sajcabajá",
            "San Andrés Semetabaj",
            "San Andrés Villa Seca",
            "San Andrés Xecul",
            "San Antonio Aguas Calientes",
            "San Antonio Huista",
            "San Antonio Ilotenango",
            "San Antonio La Paz",
            "San Antonio Palopó",
            "San Antonio Sacatepéquez",
            "San Antonio Suchitepéquez",
            "San Bartolo",
            "San Bartolomé Jocotenango",
            "San Bartolomé Milpas Altas",
            "San Benito",
            "San Bernardino",
            "San Carlos Sija",
            "San Cristóbal Acasaguastlán",
            "San Cristóbal Cucho",
            "San Cristóbal Totonicapán",
            "San Cristóbal Verapaz",
            "San Felipe",
            "San Francisco",
            "San Francisco El Alto",
            "San Francisco la Unión",
            "San Francisco Zapotitlán",
            "San Gabriel",
            "San Gaspar Ixchil",
            "San Jacinto",
            "San Jerónimo",
            "San José",
            "San José Acatempa",
            "San José Chacayá",
            "San José del Golfo",
            "San José El Ídolo",
            "San José La Arada",
            "San José Ojetenam",
            "San José Ojetenán",
            "San José Pinula",
            "San José Poaquil",
            "San Juan Atitán",
            "San Juan Bautista",
            "San Juan Chamelco",
            "San Juan Cotzal",
            "San Juan Ermita",
            "San Juan Ixcoy",
            "San Juan La Laguna",
            "San Juan Sacatepéquez",
            "San Juan Tecuaco",
            "San Lorenzo",
            "San Lorenzo",
            "San Lucas Sacatepéquez",
            "San Lucas Tolimán",
            "San Luis",
            "San Luis Ixcán",
            "San Luis Jilotepeque",
            "San Manuel Chaparrón",
            "San Marcos",
            "San Marcos La Laguna",
            "San Martín Jilotepeque",
            "San Martín Sacatepéquez",
            "San Martín Zapotitlán",
            "San Mateo",
            "San Mateo Ixtatán",
            "San Miguel Acatán",
            "San Miguel Chicaj",
            "San Miguel Dueñas",
            "San Miguel Ixtahuacán",
            "San Miguel Panán",
            "San Miguel Sigüilá",
            "San Pablo",
            "San Pablo Jocopilas",
            "San Pablo La Laguna",
            "San Pedro Ayampuc",
            "San Pedro Carchá",
            "San Pédro Jocopilas",
            "San Pedro La Laguna",
            "San Pedro Necta",
            "San Pedro Pinula",
            "San Pedro Sacatepéquez",
            "San Pedro Sacatepéquez",
            "San Rafael La Independencia",
            "San Rafael Las Flores",
            "San Rafael Petzal",
            "San Rafael Pie de la Cuesta",
            "San Raimundo",
            "San Sebastián",
            "San Sebastián Coatán",
            "San Sebastián Huehuetenango",
            "San Vicente Pacaya",
            "Sanarate",
            "Sansare",
            "Santa Ana",
            "Santa Ana Huista",
            "Santa Apolonia",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Catarina Barahona",
            "Santa Catarina Ixtahuacán",
            "Santa Catarina Mita",
            "Santa Catarina Palopó",
            "Santa Catarina Pinula",
            "Santa Clara La Laguna",
            "Santa Cruz Balanyá",
            "Santa Cruz del Quiché",
            "Santa Cruz La Laguna",
            "Santa Cruz Muluá",
            "Santa Cruz Naranjo",
            "Santa Cruz Verapaz",
            "Santa Eulalia",
            "Santa Lucía Cotzumalguapa",
            "Santa Lucia La Reforma",
            "Santa Lucía Milpas Altas",
            "Santa Lucía Utatlán",
            "Santa María Chiquimula",
            "Santa María de Jesús",
            "Santa María Ixhuatán",
            "Santa María Visitación",
            "Santa Rosa de Lima",
            "Santiago Atitlán",
            "Santiago Chimaltenango",
            "Santiago Sacatepéquez",
            "Santo Domingo Suchitepéquez",
            "Santo Domingo Xenacoj",
            "Santo Tomás La Unión",
            "Sayaxché",
            "Senahú",
            "Sibilia",
            "Sibinal",
            "Sipacapa",
            "Siquinalá",
            "Sololá",
            "Soloma",
            "Sumpango",
            "Tacaná",
            "Tactic",
            "Tajumulco",
            "Tamahú",
            "Taxisco",
            "Tecpán Guatemala",
            "Tectitán",
            "Tejutla",
            "Tiquisate",
            "Todos Santos Cuchumatán",
            "Totonicapán",
            "Tucurú",
            "Uspantán",
            "Villa Canales",
            "Villa Nueva",
            "Yepocapa",
            "Yupiltepeque",
            "Zacualpa",
            "Zapotitlán",
            "Zaragoza",
            "Zunil",
            "Zunilito"
        ]
    },
    {
        "label": "Guernsey and Alderney",
        "code": "GG",
        "phone": "+44-1481",
        "cities": []
    },
    {
        "label": "Guinea",
        "code": "GN",
        "phone": "224",
        "cities": [
            "Beyla",
            "Beyla Prefecture",
            "Boffa",
            "Boké",
            "Boke Prefecture",
            "Camayenne",
            "Conakry",
            "Coyah",
            "Dalaba",
            "Dubréka",
            "Forécariah",
            "Fria",
            "Gaoual",
            "Gaoual Prefecture",
            "Gueckedou",
            "Kankan",
            "Kankan Prefecture",
            "Kérouané",
            "Kerouane Prefecture",
            "Kimbo",
            "Kindia",
            "Koubia",
            "Koundara",
            "Koundara Prefecture",
            "Kouroussa",
            "Labé",
            "Labe Prefecture",
            "Lélouma",
            "Lelouma Prefecture",
            "Lola",
            "Macenta",
            "Mali",
            "Mali Prefecture",
            "Mamou",
            "Mamou Prefecture",
            "Mandiana",
            "Mandiana Prefecture",
            "Nzérékoré",
            "Nzerekore Prefecture",
            "Pita",
            "Préfecture de Dubréka",
            "Préfecture de Forécariah",
            "Préfecture de Guékédou",
            "Sanguéya",
            "Siguiri",
            "Siguiri Prefecture",
            "Télimélé",
            "Telimele Prefecture",
            "Tokonou",
            "Tondon",
            "Tougué",
            "Tougue Prefecture",
            "Yomou",
            "Youkounkoun"
        ]
    },
    {
        "label": "Guinea-Bissau",
        "code": "GW",
        "phone": "245",
        "cities": [
            "Bafatá",
            "Bissorã",
            "Bolama",
            "Buba",
            "Bubaque",
            "Cacheu",
            "Canchungo",
            "Catió",
            "Contuboel Sector",
            "Farim",
            "Gabú",
            "Mansôa",
            "Quebo",
            "Quinhámel"
        ]
    },
    {
        "label": "Guyana",
        "code": "GY",
        "phone": "592",
        "cities": [
            "Anna Regina",
            "Bartica",
            "Georgetown",
            "Lethem",
            "Linden",
            "Mabaruma",
            "Mahaica Village",
            "Mahaicony Village",
            "Mahdia",
            "New Amsterdam",
            "Parika",
            "Rosignol",
            "Skeldon",
            "Vreed-en-Hoop"
        ]
    },
    {
        "label": "Haiti",
        "code": "HT",
        "phone": "509",
        "cities": [
            "Acul du Nord",
            "Ansavo",
            "Anse à Galets",
            "Anse Rouge",
            "Anse-à-Pitre",
            "Anse-à-Veau",
            "Aquin",
            "Arcahaie",
            "Arcahaie",
            "Arrondissement de Bainet",
            "Arrondissement de Cerca La Source",
            "Arrondissement de Croix des Bouquets",
            "Arrondissement de Fort Liberté",
            "Arrondissement de Jacmel",
            "Arrondissement de la Grande Rivière du Nord",
            "Arrondissement de Léogâne",
            "Arrondissement de Plaisance",
            "Arrondissement de Port-au-Prince",
            "Arrondissement de Port-de-Paix",
            "Arrondissement de Port-Salut",
            "Arrondissement de Saint-Louis du Nord",
            "Arrondissement de Saint-Marc",
            "Arrondissement des Cayes",
            "Arrondissement du Borgne",
            "Arrondissement du Môle Saint-Nicolas",
            "Arrondissement du Trou du Nord",
            "Bahon",
            "Baie de Henne",
            "Baradères",
            "Belle-Anse",
            "Bombardopolis",
            "Borgne",
            "Cabaret",
            "Caracol",
            "Carice",
            "Carrefour",
            "Cavaillon",
            "Cayes-Jacmel",
            "Cerca la Source",
            "Chambellan",
            "Chantal",
            "Chardonnière",
            "Corail",
            "Cornillon",
            "Croix-des-Bouquets",
            "Dame-Marie",
            "Delmas 73",
            "Dérac",
            "Désarmes",
            "Dessalines",
            "Dondon",
            "Ennery",
            "Ferrier",
            "Fond Bassin Bleu",
            "Fond des Blancs",
            "Fond Parisien",
            "Fonds Verrettes",
            "Fort Liberté",
            "Gonaïves",
            "Grande Rivière du Nord",
            "Grande Saline",
            "Grangwav",
            "Gressier",
            "Gros Morne",
            "Hinche",
            "Jacmel",
            "Jean-Rabel",
            "Jeremi",
            "Jérémie",
            "Kenscoff",
            "Kotdefè",
            "Koto",
            "Lagonav",
            "Lascahobas",
            "Lenbe",
            "Léogâne",
            "Les Abricots",
            "Les Anglais",
            "Les Cayes",
            "Les Irois",
            "Limonade",
            "Marigot",
            "Marmelade",
            "Mayisad",
            "Milot",
            "Miragoâne",
            "Mirebalais",
            "Môle Saint-Nicolas",
            "Montòrganize",
            "Moron",
            "Okap",
            "Ouanaminthe",
            "Perches",
            "Pétionville",
            "Petit Trou de Nippes",
            "Petite Anse",
            "Petite Rivière de Nippes",
            "Phaëton",
            "Pignon",
            "Pilate",
            "Plaine du Nord",
            "Plaisance",
            "Port-à-Piment",
            "Port-au-Prince",
            "Port-de-Paix",
            "Port-Margot",
            "Quartier Morin",
            "Ranquitte",
            "Roche-à-Bateau",
            "Saint-Louis du Nord",
            "Saint-Louis du Sud",
            "Saint-Marc",
            "Saint-Raphaël",
            "Thiotte",
            "Thomassique",
            "Thomazeau",
            "Thomonde",
            "Ti Port-de-Paix",
            "Tiburon",
            "Tigwav",
            "Torbeck",
            "Trou du Nord",
            "Verrettes",
            "Wanament"
        ]
    },
    {
        "label": "Heard Island and McDonald Islands",
        "code": "HM",
        "phone": "672",
        "cities": []
    },
    {
        "label": "Honduras",
        "code": "HN",
        "phone": "504",
        "cities": [
            "Agalteca",
            "Agua Azul",
            "Agua Azul Rancho",
            "Agua Blanca Sur",
            "Agua Caliente",
            "Agua Fría",
            "Agualote",
            "Aguanqueterique",
            "Aguas del Padre",
            "Ahuas",
            "Ajuterique",
            "Alauca",
            "Alianza",
            "Alubarén",
            "Amapala",
            "Antigua Ocotepeque",
            "Apacilagua",
            "Arada",
            "Aramecina",
            "Araulí",
            "Arenal",
            "Arimís",
            "Arizona",
            "Armenia",
            "Armenta",
            "Atenas de San Cristóbal",
            "Atima",
            "Auas",
            "Auka",
            "Ayapa",
            "Azacualpa",
            "Baja Mar",
            "Balfate",
            "Bálsamo Oriental",
            "Baracoa",
            "Barra Patuca",
            "Bejuco",
            "Belén",
            "Belén Gualcho",
            "Berlín",
            "Bonito Oriental",
            "Brus Laguna",
            "Buenos Aires",
            "Cabañas",
            "Cabañas",
            "Callejones",
            "Camalote",
            "Camasca",
            "Campamento",
            "Cañaveral",
            "Candelaria",
            "Cane",
            "Carbajales",
            "Caridad",
            "Casa Quemada",
            "Casa Quemada",
            "Catacamas",
            "Cedros",
            "Ceguaca",
            "Cerro Blanco",
            "Cerro Grande",
            "Chalmeca",
            "Chinacla",
            "Chinda",
            "Chivana",
            "Choloma",
            "Choluteca",
            "Chotepe",
            "Ciudad Choluteca",
            "Cofradía",
            "Cofradía",
            "Cololaca",
            "Colomoncagua",
            "Comayagua",
            "Concepción",
            "Concepción",
            "Concepción",
            "Concepción de Guasistagua",
            "Concepción de la Barranca",
            "Concepción de María",
            "Concepción del Norte",
            "Concepción del Sur",
            "Concordia",
            "Copán",
            "Copán Ruinas",
            "Corocito",
            "Corozal",
            "Corpus",
            "Corquín",
            "Correderos",
            "Coxen Hole",
            "Coyoles Central",
            "Cucuyagua",
            "Curarén",
            "Cusuna",
            "Cuyalí",
            "Cuyamel",
            "Danlí",
            "Distrito Central",
            "Dolores",
            "Dolores",
            "Dolores Merendón",
            "Dulce Nombre",
            "Dulce Nombre de Culmí",
            "Duyure",
            "El Achiotal",
            "El Agua Dulcita",
            "El Bálsamo",
            "El Benque",
            "El Chichicaste",
            "El Chimbo",
            "El Ciruelo",
            "El Corozal",
            "El Corpus",
            "El Corpus",
            "El Cubolero",
            "El Escanito",
            "El Escaño de Tepale",
            "El Guante",
            "El Guantillo",
            "El Guapinol",
            "El Guayabito",
            "El Juncal",
            "El Llano",
            "El Lolo",
            "El Marañón",
            "El Milagro",
            "El Mochito",
            "El Negrito",
            "El Níspero",
            "El Obraje",
            "El Obraje",
            "El Ocote",
            "El Ocotón",
            "El Olivar",
            "El Paraíso",
            "El Paraíso",
            "El Pedernal",
            "El Perico",
            "El Pino",
            "El Plan",
            "El Porvenir",
            "El Porvenir",
            "El Porvenir",
            "El Porvenir",
            "El Progreso",
            "El Puente",
            "El Rancho",
            "El Rancho",
            "El Rincón",
            "El Rosario",
            "El Rosario",
            "El Rusio",
            "El Sauce",
            "El Socorro",
            "El Suyatal",
            "El Tablón",
            "El Terrero",
            "El Tigre",
            "El Tránsito",
            "El Triunfo",
            "El Triunfo de la Cruz",
            "El Tular",
            "El Zapotal del Norte",
            "Elíxir",
            "Erandique",
            "Esparta",
            "Esquías",
            "Esquipulas del Norte",
            "Flores",
            "Florida",
            "Francia",
            "Fraternidad",
            "French Harbor",
            "Goascorán",
            "Gracias",
            "Guacamaya",
            "Guaimaca",
            "Guaimitas",
            "Guajiquiro",
            "Gualaco",
            "Gualala",
            "Gualcince",
            "Gualjoco",
            "Guanaja",
            "Guarita",
            "Guarizama",
            "Guata",
            "Guayape",
            "Güinope",
            "Humuya",
            "Ilama",
            "Intibucá",
            "Iralaya",
            "Iriona",
            "Jacaleapa",
            "Jamalteca",
            "Jano",
            "Jericó",
            "Jesús de Otoro",
            "Jícaro Galán",
            "Jiquinlaca",
            "Jocón",
            "Joconal",
            "José Santos Guardiola",
            "Juan Francisco Bulnes",
            "Jutiapa",
            "Jutiapa",
            "Juticalpa",
            "Jutiquile",
            "La Alianza",
            "La Brea",
            "La Campa",
            "La Ceiba",
            "La Concepción",
            "La Criba",
            "La Encarnación",
            "La Entrada",
            "La Ermita",
            "La Esperanza",
            "La Esperanza",
            "La Estancia",
            "La Estancia",
            "La Flecha",
            "La Guacamaya",
            "La Guama",
            "La Guata",
            "La Huesa",
            "La Iguala",
            "La Jigua",
            "La Jutosa",
            "La Labor",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Lima",
            "La Masica",
            "La Mina",
            "La Paz",
            "La Playona",
            "La Rosa",
            "La Sabana",
            "La Sarrosa",
            "La Trinidad",
            "La Trinidad",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Venta",
            "La Virtud",
            "La Zumbadora",
            "Laguna Seca",
            "Laguna Verde",
            "Lamaní",
            "Langue",
            "Las Ánimas",
            "Las Flores",
            "Las Lajas",
            "Las Tejeras",
            "Las Trojes",
            "Las Vegas",
            "Las Vegas",
            "Las Vegas Santa Barbara",
            "Lauterique",
            "Lejamaní",
            "Lepaera",
            "Lepaterique",
            "Limón",
            "Liure",
            "Loma Alta",
            "Lomitas",
            "Los Caminos",
            "Los Llanitos",
            "Los Naranjos",
            "Los Planes",
            "Los Tangos",
            "Lucerna",
            "Macuelizo",
            "Magdalena",
            "Mangulile",
            "Manto",
            "Mapulaca",
            "Maraita",
            "Marale",
            "Marcala",
            "Marcovia",
            "Masaguara",
            "Mata de Plátano",
            "Mateo",
            "Meámbar",
            "Mercedes",
            "Mercedes de Oriente",
            "Mezapa",
            "Minas de Oro",
            "Mojimán",
            "Monjarás",
            "Monterrey",
            "Morazán",
            "Morocelí",
            "Morolica",
            "Municipio de Sabá",
            "Municipio de San Francisco de La Paz",
            "Municipio de Texiguat",
            "Nacaome",
            "Naco",
            "Namasigüe",
            "Naranjito",
            "Nombre de Jesús",
            "Nueva Arcadia",
            "Nueva Armenia",
            "Nueva Armenia",
            "Nueva Esperanza",
            "Nueva Frontera",
            "Nueva Jalapa",
            "Nueva Ocotepeque",
            "Nuevo Celilac",
            "Nuevo Chamelecón",
            "Ocote Paulino",
            "Ojo de Agua",
            "Ojojona",
            "Ojos de Agua",
            "Ojos de Agua",
            "Olanchito",
            "Omoa",
            "Opatoro",
            "Orica",
            "Orocuina",
            "Oropéndolas",
            "Oropolí",
            "Paptalaya",
            "Patuca",
            "Paujiles",
            "Peña Blanca",
            "Pespire",
            "Petoa",
            "Pimienta",
            "Pimienta Vieja",
            "Pinalejo",
            "Piraera",
            "Potrerillos",
            "Potrerillos",
            "Potrerillos",
            "Prieta",
            "Protección",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Puerto Alto",
            "Puerto Castilla",
            "Puerto Cortés",
            "Puerto Cortez",
            "Puerto Lempira",
            "Punta Ocote",
            "Punta Piedra",
            "Punuare",
            "Quebrada de Arena",
            "Quebrada Larga",
            "Quebrada Seca",
            "Quebradas",
            "Quezailica",
            "Quimistán",
            "Reitoca",
            "Río Abajo",
            "Río Blanquito",
            "Río Bonito",
            "Río Chiquito",
            "Río Esteban",
            "Río Lindo",
            "Roatán",
            "Sabá",
            "Sabanagrande",
            "Salamá",
            "Salamá",
            "Sambo Creek",
            "San Agustín",
            "San Andrés",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio de Cortés",
            "San Antonio de Flores",
            "San Antonio de Flores",
            "San Antonio de la Cuesta",
            "San Antonio de Oriente",
            "San Antonio del Norte",
            "San Buenaventura",
            "San Buenaventura",
            "San Diego",
            "San Esteban",
            "San Fernando",
            "San Francisco",
            "San Francisco",
            "San Francisco de Becerra",
            "San Francisco de Cones",
            "San Francisco de Coray",
            "San Francisco de la Paz",
            "San Francisco de Ojuera",
            "San Francisco de Opalaca",
            "San Francisco de Yojoa",
            "San Francisco del Valle",
            "San Ignacio",
            "San Isidro",
            "San Isidro",
            "San Jerónimo",
            "San Jerónimo",
            "San Jerónimo",
            "San Joaquín",
            "San Jorge",
            "San José",
            "San José",
            "San José",
            "San José",
            "San José de Colinas",
            "San José de Comayagua",
            "San José de Copán",
            "San José de Las Conchas",
            "San José de Río Tinto",
            "San José de Tarros",
            "San José del Boquerón",
            "San José del Potrero",
            "San Juan",
            "San Juan",
            "San Juan de Flores",
            "San Juan de Opoa",
            "San Juan de Planes",
            "San Juan Guarita",
            "San Juan Pueblo",
            "San Lorenzo",
            "San Lucas",
            "San Luis",
            "San Luis",
            "San Luis de Planes",
            "San Manuel",
            "San Manuel Colohete",
            "San Marcos",
            "San Marcos",
            "San Marcos de Caiquin",
            "San Marcos de Colón",
            "San Marcos de la Sierra",
            "San Matías",
            "San Miguelito",
            "San Miguelito",
            "San Nicolás",
            "San Nicolás",
            "San Nicolás",
            "San Pedro de Copán",
            "San Pedro de Tutule",
            "San Pedro Sula",
            "San Pedro Zacapa",
            "San Rafael",
            "San Sebastián",
            "San Sebastián",
            "San Vicente Centenario",
            "Sandy Bay",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana de Yusguare",
            "Santa Bárbara",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz de Yojoa",
            "Santa Elena",
            "Santa Elena",
            "Santa Fe",
            "Santa Fe",
            "Santa Lucía",
            "Santa Lucía",
            "Santa Lucía",
            "Santa María",
            "Santa María del Real",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita Copan",
            "Santa Rosa de Aguán",
            "Santa Rosa de Copán",
            "Santiago Puringla",
            "Savannah Bight",
            "Sensenti",
            "Siguatepeque",
            "Silca",
            "Sinuapa",
            "Soledad",
            "Sonaguera",
            "Subirana",
            "Sula",
            "Sulaco",
            "Talanga",
            "Talgua",
            "Támara",
            "Tambla",
            "Taragual",
            "Tatumbla",
            "Taujica",
            "Taulabé",
            "Teguajinal",
            "Tegucigalpa",
            "Tela",
            "Tepanguare",
            "Tepusteca",
            "Teupasenti",
            "Texíguat",
            "Tocoa",
            "Tomalá",
            "Tornabé",
            "Toyós",
            "Tras Cerros",
            "Travesía",
            "Trinidad",
            "Trinidad de Copán",
            "Trojas",
            "Trojes",
            "Trujillo",
            "Utila",
            "Vado Ancho",
            "Valladolid",
            "Valle de Ángeles",
            "Valle de Ángeles",
            "Vallecillo",
            "Veracruz",
            "Victoria",
            "Villa de San Antonio",
            "Villa de San Francisco",
            "Villa Nueva",
            "Villanueva",
            "Villeda Morales",
            "Virginia",
            "Wampusirpi",
            "Wawina",
            "Yaguacire",
            "Yamaranguila",
            "Yaruchel",
            "Yarula",
            "Yarumela",
            "Yauyupe",
            "Yocón",
            "Yorito",
            "Yoro",
            "Yuscarán",
            "Zambrano",
            "Zamora",
            "Zopilotepe"
        ]
    },
    {
        "label": "Hong Kong S.A.R.",
        "code": "HK",
        "phone": "852",
        "cities": [
            "Aberdeen",
            "Admiralty",
            "Causeway Bay",
            "Central",
            "Cheung Sha Wan",
            "Kowloon Tong",
            "Kwun Tong",
            "Lai Chi Kok",
            "Lam Tin",
            "Ma On Shan",
            "Mong Kok",
            "Sai Kung",
            "Sha Tin",
            "Sha Tin Wai",
            "Sheung Shui",
            "Stanley",
            "Stanley Peninsula",
            "Tai Mei Tuk",
            "Tai O",
            "Tai Po",
            "Tseung Kwan O",
            "Tsim Sha Tsui",
            "Tsuen Wan",
            "Tuen Mun\t",
            "Wan Chai",
            "Wong Tai Sin",
            "Yuen Long"
        ]
    },
    {
        "label": "Hungary",
        "code": "HU",
        "phone": "36",
        "cities": [
            "Aba",
            "Abádszalók",
            "Abasár",
            "Abaújszántó",
            "Abda",
            "Abony",
            "Ács",
            "Acsa",
            "Adács",
            "Ádánd",
            "Adony",
            "Ágasegyháza",
            "Ágfalva",
            "Ajak",
            "Ajka",
            "Ajkai Járás",
            "Aka",
            "Akasztó",
            "Alap",
            "Alattyán",
            "Albertirsa",
            "Algyő",
            "Almásfüzitő",
            "Alsónémedi",
            "Alsózsolca",
            "Anarcs",
            "Andornaktálya",
            "Apagy",
            "Apátfalva",
            "Apc",
            "Apostag",
            "Aranyosapáti",
            "Arló",
            "Arnót",
            "Ásotthalom",
            "Ásványráró",
            "Aszaló",
            "Ászár",
            "Aszód",
            "Aszódi Járás",
            "Babócsa",
            "Bábolna",
            "Bácsalmás",
            "Bácsalmási Járás",
            "Bácsbokod",
            "Badacsonytomaj",
            "Bag",
            "Bagamér",
            "Baja",
            "Bajai Járás",
            "Bajót",
            "Bakonybánk",
            "Bakonycsernye",
            "Bakonysárkány",
            "Bakonyszentlászló",
            "Bakonyszombathely",
            "Baks",
            "Baktalórántháza",
            "Baktalórántházai Járás",
            "Balassagyarmat",
            "Balassagyarmati Járás",
            "Balástya",
            "Balatonalmádi",
            "Balatonalmádi Járás",
            "Balatonberény",
            "Balatonboglár",
            "Balatonfenyves",
            "Balatonföldvár",
            "Balatonfüred",
            "Balatonfüredi Járás",
            "Balatonkenese",
            "Balatonlelle",
            "Balatonszabadi",
            "Balatonszárszó",
            "Balkány",
            "Ballószög",
            "Balmazújváros",
            "Balmazújvárosi Járás",
            "Bana",
            "Bánhida",
            "Baracs",
            "Baracska",
            "Báránd",
            "Barcs",
            "Barcsi Járás",
            "Bársonyos",
            "Báta",
            "Bátaszék",
            "Bátonyterenye",
            "Bátonyterenyei Járás",
            "Battonya",
            "Bátya",
            "Becsehely",
            "Bekecs",
            "Békés",
            "Békéscsaba",
            "Békéscsabai Járás",
            "Békési Járás",
            "Békéssámson",
            "Békésszentandrás",
            "Bélapátfalva",
            "Bélapátfalvai Járás",
            "Beled",
            "Bercel",
            "Beremend",
            "Berettyóújfalu",
            "Berettyóújfalui Járás",
            "Berhida",
            "Berzence",
            "Besenyőtelek",
            "Besenyszög",
            "Biatorbágy",
            "Bicske",
            "Bicskei Járás",
            "Biharkeresztes",
            "Biharnagybajom",
            "Bőcs",
            "Bodajk",
            "Bogács",
            "Bogyiszló",
            "Böhönye",
            "Bököny",
            "Bölcske",
            "Boldog",
            "Boldva",
            "Bóly",
            "Bólyi Járás",
            "Bőny",
            "Bonyhád",
            "Bonyhádi Járás",
            "Bordány",
            "Borsodnádasd",
            "Bősárkány",
            "Bucsa",
            "Budakalász",
            "Budakeszi",
            "Budakeszi Járás",
            "Budaörs",
            "Budapest",
            "Budapest I. kerület",
            "Budapest II. kerület",
            "Budapest III. kerület",
            "Budapest IV. kerület",
            "Budapest VI. kerület",
            "Budapest VIII. kerület",
            "Budapest X. kerület",
            "Budapest XI. kerület",
            "Budapest XII. kerület",
            "Budapest XIII. kerület",
            "Budapest XV. kerület",
            "Budapest XVI. kerület",
            "Budapest XVII. kerület",
            "Budapest XVIII. kerület",
            "Budapest XX. kerület",
            "Budapest XXI. kerület",
            "Budapest XXII. kerület",
            "Budapest XXIII. kerület",
            "Bugac",
            "Bugyi",
            "Buj",
            "Buják",
            "Bük",
            "Bükkösd",
            "Cece",
            "Cegléd",
            "Ceglédbercel",
            "Ceglédi Járás",
            "Celldömölk",
            "Celldömölki Járás",
            "Cibakháza",
            "Cigánd",
            "Cigándi Járás",
            "Csabrendek",
            "Csákvár",
            "Csanádapáca",
            "Csanádpalota",
            "Csány",
            "Csanytelek",
            "Császár",
            "Császártöltés",
            "Csatka",
            "Csávoly",
            "Csém",
            "Csemő",
            "Csengele",
            "Csenger",
            "Csengeri Járás",
            "Csengőd",
            "Csép",
            "Csepreg",
            "Cserkeszőlő",
            "Cserszegtomaj",
            "Csetény",
            "Csobánka",
            "Csökmő",
            "Csolnok",
            "Csömör",
            "Csongrád",
            "Csongrádi Járás",
            "Csopak",
            "Csorna",
            "Csornai Járás",
            "Csorvás",
            "Csurgó",
            "Csurgói Járás",
            "Dabas",
            "Dabasi Járás",
            "Dad",
            "Dág",
            "Dánszentmiklós",
            "Dány",
            "Dávod",
            "Debrecen",
            "Debreceni Járás",
            "Decs",
            "Dég",
            "Délegyháza",
            "Demecser",
            "Derecske",
            "Derecskei Járás",
            "Deszk",
            "Dévaványa",
            "Devecser",
            "Devecseri Járás",
            "Diósd",
            "Diósjenő",
            "Doboz",
            "Döbrököz",
            "Döge",
            "Domaszék",
            "Dombegyház",
            "Dombóvár",
            "Dombóvári Járás",
            "Dombrád",
            "Domony",
            "Dömös",
            "Domoszló",
            "Dömsöd",
            "Dorog",
            "Dunaalmás",
            "Dunabogdány",
            "Dunaföldvár",
            "Dunaharaszti",
            "Dunakeszi",
            "Dunakeszi Járás",
            "Dunapataj",
            "Dunaszekcső",
            "Dunaszentgyörgy",
            "Dunaszentmiklós",
            "dunaújváros",
            "Dunaújvárosi Járás",
            "Dunavarsány",
            "Dunavecse",
            "Dusnok",
            "Ebes",
            "Ecséd",
            "Ecser",
            "Edelény",
            "Edelényi Járás",
            "Eger",
            "Egerszalók",
            "Egri Járás",
            "Egyek",
            "Elek",
            "Előszállás",
            "Emőd",
            "Encs",
            "Encsencs",
            "Encsi Járás",
            "Enying",
            "Enyingi Járás",
            "Epöl",
            "Ercsi",
            "Érd",
            "Érdi Járás",
            "Erdőkertes",
            "Erdőtelek",
            "Érsekcsanád",
            "Érsekvadkert",
            "Erzsébetváros",
            "Esztergom",
            "Ete",
            "Etyek",
            "Fábiánsebestyén",
            "Fadd",
            "Farád",
            "Farkaslyuk",
            "Farmos",
            "Fegyvernek",
            "Fehérgyarmat",
            "Fehérgyarmati Járás",
            "Fehérvárcsurgó",
            "Felsőpakony",
            "Felsőszentiván",
            "Felsőtárkány",
            "Felsőzsolca",
            "Fényeslitke",
            "Fertőd",
            "Fertőrákos",
            "Fertőszentmiklós",
            "Földeák",
            "Földes",
            "Fonyód",
            "Fonyódi Járás",
            "Forráskút",
            "Forrópuszta",
            "Fót",
            "Fülöpjakab",
            "Fülöpszállás",
            "Füzesabony",
            "Füzesabonyi Járás",
            "Füzesgyarmat",
            "Gádoros",
            "Galgahévíz",
            "Galgamácsa",
            "Gara",
            "Gárdony",
            "Gárdonyi Járás",
            "Gégény",
            "Gencsapáti",
            "Gesztely",
            "Göd",
            "Gödöllő",
            "Gödöllői Járás",
            "Gomba",
            "Gönc",
            "Gönci Járás",
            "Görbeháza",
            "Gyál",
            "Gyáli Járás",
            "Gyenesdiás",
            "Gyermely",
            "Gyomaendrőd",
            "Gyomaendrődi Járás",
            "Gyömrő",
            "Gyöngyös",
            "Gyöngyöshalász",
            "Gyöngyösi Járás",
            "Gyöngyöspata",
            "Gyöngyössolymos",
            "Gyöngyöstarján",
            "Gyönk",
            "Győr",
            "Győri Járás",
            "Győrszemere",
            "Győrújbarát",
            "Gyula",
            "Gyulaháza",
            "Gyulai Járás",
            "Hadjúszoboszlói Járás",
            "Hajdúbagos",
            "Hajdúböszörmény",
            "Hajdúböszörményi Járás",
            "Hajdúdorog",
            "Hajdúhadház",
            "Hajdúhadházi Járás",
            "Hajdúnánás",
            "Hajdúnánási Járás",
            "Hajdúsámson",
            "Hajdúszoboszló",
            "Hajdúszovát",
            "Hajmáskér",
            "Hajós",
            "Halászi",
            "Halásztelek",
            "Halmaj",
            "Harkány",
            "Harsány",
            "Harta",
            "Hatvan",
            "Hatvani Járás",
            "Hegyháti Járás",
            "Héhalom",
            "Hejőbába",
            "Helvécia",
            "Hercegszántó",
            "Heréd",
            "Héreg",
            "Herend",
            "Hernád",
            "Hernádnémeti",
            "Heves",
            "Hevesi Járás",
            "Hévíz",
            "Hévízgyörk",
            "Hidas",
            "Hodász",
            "Hódmezővásárhely",
            "Hódmezővásárhelyi Járás",
            "Hőgyész",
            "Hort",
            "Hortobágy",
            "Hosszúhetény",
            "Hosszúpályi",
            "Ibrány",
            "Ibrányi Járás",
            "Iklad",
            "Inárcs",
            "Iregszemcse",
            "Isaszeg",
            "Iváncsa",
            "Izsák",
            "Izsófalva",
            "Ják",
            "Jánoshalma",
            "Jánoshalmai Járás",
            "Jánosháza",
            "Jánoshida",
            "Jánossomorja",
            "Járdánháza",
            "Jászalsószentgyörgy",
            "Jászapáti",
            "Jászapáti Járás",
            "Jászárokszállás",
            "Jászberény",
            "Jászberényi Járás",
            "Jászdózsa",
            "Jászjákóhalma",
            "Jászkarajenő",
            "Jászkisér",
            "Jászladány",
            "Jászszentandrás",
            "Jászszentlászló",
            "Jobbágyi",
            "Józsefváros",
            "Kaba",
            "Kadarkút",
            "Kakucs",
            "Kál",
            "Kállósemjén",
            "Kálmánháza",
            "Kalocsa",
            "Kalocsai Járás",
            "Káloz",
            "Kántorjánosi",
            "Kápolnásnyék",
            "Kaposmérő",
            "Kaposvár",
            "Kaposvári Járás",
            "Kapuvár",
            "Kapuvári Járás",
            "Karácsond",
            "Karád",
            "Karancskeszi",
            "Karancslapujtő",
            "Karcag",
            "Karcagi Járás",
            "Karcsa",
            "Kartal",
            "Kaszaper",
            "Katymár",
            "Kazár",
            "Kazincbarcika",
            "Kazincbarcikai Járás",
            "Kecel",
            "Kecskéd",
            "Kecskemét",
            "Kecskeméti Járás",
            "Kék",
            "Kelebia",
            "Kemecse",
            "Kemecsei Járás",
            "Kenderes",
            "Kengyel",
            "Kerecsend",
            "Kerekegyháza",
            "Kerékteleki",
            "Kerepes",
            "Keszthely",
            "Keszthelyi Járás",
            "Kesztölc",
            "Kétegyháza",
            "Kéthely",
            "Kevermes",
            "Kimle",
            "Kincsesbánya",
            "Kisbér",
            "Kisigmánd",
            "Kisköre",
            "Kiskőrös",
            "Kiskőrösi Járás",
            "Kiskunfélegyháza",
            "Kiskunfélegyházi Járás",
            "Kiskunhalas",
            "Kiskunhalasi Járás",
            "Kiskunlacháza",
            "Kiskunmajsa",
            "Kiskunmajsai Járás",
            "Kisláng",
            "Kisléta",
            "Kismaros",
            "Kispest",
            "Kisszállás",
            "Kistarcsa",
            "Kistelek",
            "Kisteleki Járás",
            "Kisújszállás",
            "Kisvárda",
            "Kisvárdai Járás",
            "Kiszombor",
            "Kocs",
            "Kocsér",
            "Kocsord",
            "Kóka",
            "Komádi",
            "Komárom",
            "Komló",
            "Kömlőd",
            "Komlói Járás",
            "Kompolt",
            "Kondoros",
            "Kóny",
            "Konyár",
            "Körmend",
            "Körmendi Járás",
            "Környe",
            "Körösladány",
            "Köröstarcsa",
            "Kosd",
            "Kőszeg",
            "Kőszegi Járás",
            "Kótaj",
            "Kozármisleny",
            "Kunágota",
            "Kunfehértó",
            "Kunhegyes",
            "Kunhegyesi Járás",
            "Kunmadaras",
            "Kunszentmárton",
            "Kunszentmártoni Járás",
            "Kunszentmiklós",
            "Kunszentmiklósi Járás",
            "Lábatlan",
            "Lábod",
            "Lajoskomárom",
            "Lajosmizse",
            "Lakitelek",
            "Lánycsók",
            "Leányfalu",
            "Leányvár",
            "Lébény",
            "Lengyeltóti",
            "Lenti",
            "Lenti Járás",
            "Lepsény",
            "Létavértes",
            "Letenye",
            "Letenyei Járás",
            "Levelek",
            "Litér",
            "Lőkösháza",
            "Lőrinci",
            "Lovasberény",
            "Mád",
            "Madaras",
            "Madocsa",
            "Maglód",
            "Mágocs",
            "Magyarbánhegyes",
            "Maklár",
            "Makó",
            "Makói Járás",
            "Mályi",
            "Mándok",
            "Mány",
            "Marcali",
            "Marcali Járás",
            "Máriahalom",
            "Máriapócs",
            "Maroslele",
            "Martonvásár",
            "Martonvásári Járás",
            "Mátészalka",
            "Mátészalkai Járás",
            "Mátraderecske",
            "Mátranovák",
            "Mátraterenye",
            "Mátraverebély",
            "Mecseknádasd",
            "Medgyesegyháza",
            "Megyaszó",
            "Méhkerék",
            "Mélykút",
            "Mende",
            "Mérk",
            "Mezőberény",
            "Mezőcsát",
            "Mezőcsáti Járás",
            "Mezőfalva",
            "Mezőhegyes",
            "Mezőkeresztes",
            "Mezőkovácsháza",
            "Mezőkovácsházai Járás",
            "Mezőkövesd",
            "Mezőkövesdi Járás",
            "Mezőszilas",
            "Mezőtúr",
            "Mezőtúri Járás",
            "Mezőzombor",
            "Mihályi",
            "Mikepércs",
            "Mindszent",
            "Miskolc",
            "Miskolci Járás",
            "Mocsa",
            "Mogyoród",
            "Mogyorósbánya",
            "Mohács",
            "Mohácsi Járás",
            "Monok",
            "Monor",
            "Monori Járás",
            "Monostorpályi",
            "Mór",
            "Mórahalmi Járás",
            "Mórahalom",
            "Móri Járás",
            "Mosonmagyaróvár",
            "Mosonmagyaróvári Járás",
            "Mosonszentmiklós",
            "Múcsony",
            "Murakeresztúr",
            "Nádudvar",
            "Nagyatád",
            "Nagyatádi Járás",
            "Nagybajom",
            "Nagybaracska",
            "Nagycenk",
            "Nagycserkesz",
            "Nagydobos",
            "Nagydorog",
            "Nagyecsed",
            "Nagyhalász",
            "Nagyigmánd",
            "Nagykálló",
            "Nagykállói Járás",
            "Nagykanizsa",
            "Nagykanizsai Járás",
            "Nagykáta",
            "Nagykátai Járás",
            "Nagykőrös",
            "Nagykőrösi Járás",
            "Nagykovácsi",
            "Nagymányok",
            "Nagymaros",
            "Nagyoroszi",
            "Nagyrábé",
            "Nagyréde",
            "Nagysáp",
            "Nagyszénás",
            "Nagytarcsa",
            "Napkor",
            "Naszály",
            "Nemesnádudvar",
            "Nemesvámos",
            "Németkér",
            "Neszmély",
            "Nyáregyháza",
            "Nyárlőrinc",
            "Nyékládháza",
            "Nyergesújfalu",
            "Nyírábrány",
            "Nyíracsád",
            "Nyíradony",
            "Nyíradonyi Járás",
            "Nyírbátor",
            "Nyírbátori Járás",
            "Nyírbéltek",
            "Nyírbogát",
            "Nyírbogdány",
            "Nyírcsaholy",
            "Nyíregyháza",
            "Nyíregyházi Járás",
            "Nyírgyulaj",
            "Nyírkarász",
            "Nyírlugos",
            "Nyírmada",
            "Nyírmártonfalva",
            "Nyírmeggyes",
            "Nyírmihálydi",
            "Nyírpazony",
            "Nyírtass",
            "Nyírtelek",
            "Nyírvasvári",
            "Nyúl",
            "Ócsa",
            "Őcsény",
            "Öcsöd",
            "Ófehértó",
            "Okány",
            "Ököritófülpös",
            "Olaszliszka",
            "Onga",
            "Ónod",
            "Ópályi",
            "Ópusztaszer",
            "Őrbottyán",
            "Orgovány",
            "Örkény",
            "Orosháza",
            "Orosházi Járás",
            "Oroszlány",
            "Ősi",
            "Ostoros",
            "Öttevény",
            "Ózd",
            "Ózdi Járás",
            "Ozora",
            "Pacsa",
            "Pákozd",
            "Paks",
            "Paksi Járás",
            "Pálmonostora",
            "Palotás",
            "Pánd",
            "Pannonhalma",
            "Pannonhalmi Járás",
            "Pápa",
            "Pápai Járás",
            "Parád",
            "Parádsasvár",
            "Pásztó",
            "Pásztói Járás",
            "Pátroha",
            "Páty",
            "Pázmánd",
            "Pécel",
            "Pécs",
            "Pécsi Járás",
            "Pécsvárad",
            "Pécsváradi Járás",
            "Pellérd",
            "Pér",
            "Perbál",
            "Perkáta",
            "Péteri",
            "Pétervására",
            "Pétervásárai Járás",
            "Pétfürdő",
            "Petneháza",
            "Petőfibánya",
            "Pilis",
            "Pilisborosjenő",
            "Piliscsaba",
            "Piliscsév",
            "Pilismarót",
            "Pilisszántó",
            "Pilisszentiván",
            "Pilisszentkereszt",
            "Pilisvörösvár",
            "Pilisvörösvári Járás",
            "Pincehely",
            "Pocsaj",
            "Polgár",
            "Polgárdi",
            "Pomáz",
            "Porcsalma",
            "Poroszló",
            "Prügy",
            "Püspökladány",
            "Püspökladányi Járás",
            "Pusztaföldvár",
            "Pusztaszabolcs",
            "Pusztaszer",
            "Pusztavám",
            "Putnok",
            "Putnoki Járás",
            "Rábapatona",
            "Rácalmás",
            "Ráckeresztúr",
            "Ráckeve",
            "Ráckevei Járás",
            "Rajka",
            "Rakamaz",
            "Rákóczifalva",
            "Rákócziújfalu",
            "Recsk",
            "Réde",
            "Répcelak",
            "Rétság",
            "Rétsági Járás",
            "Révfülöp",
            "Ricse",
            "Rimóc",
            "Romhány",
            "Röszke",
            "Rózsaszentmárton",
            "Rudabánya",
            "Ruzsa",
            "Sajóbábony",
            "Sajókaza",
            "Sajólád",
            "Sajóörös",
            "Sajószentpéter",
            "Sajószöged",
            "Sajóvámos",
            "Salgótarján",
            "Salgótarjáni Járás",
            "Sály",
            "Sándorfalva",
            "Sáránd",
            "Sárbogárd",
            "Sárbogárdi Járás",
            "Sárisáp",
            "Sarkad",
            "Sarkadi Járás",
            "Sárkeresztúr",
            "Sármellék",
            "Sárosd",
            "Sárospatak",
            "Sárospataki Járás",
            "Sárrétudvari",
            "Sárszentmihály",
            "Sárvár",
            "Sárvári Járás",
            "Sásd",
            "Sátoraljaújhely",
            "Sátoraljaújhelyi Járás",
            "Segesd",
            "Sellye",
            "Sellyei Járás",
            "Seregélyes",
            "Siklós",
            "Siklósi Járás",
            "Simontornya",
            "Siófok",
            "Siófoki Járás",
            "Sirok",
            "Solt",
            "Soltvadkert",
            "Solymár",
            "Somogyvár",
            "Somoskőújfalu",
            "Soponya",
            "Sopron",
            "Soproni Járás",
            "Sóskút",
            "Sükösd",
            "Sülysáp",
            "Sümeg",
            "Sümegi Járás",
            "Súr",
            "Süttő",
            "Szabadbattyán",
            "Szabadkígyós",
            "Szabadszállás",
            "Szada",
            "Szajol",
            "Szakoly",
            "Szákszend",
            "Szalkszentmárton",
            "Szamosszeg",
            "Szank",
            "Szany",
            "Szárliget",
            "Szarvas",
            "Szarvasi Járás",
            "Szászvár",
            "Szatymaz",
            "Százhalombatta",
            "Szécsény",
            "Szécsényi Járás",
            "Szedres",
            "Szeged",
            "Szegedi Járás",
            "Szeghalmi Járás",
            "Szeghalom",
            "Szegvár",
            "Székesfehérvár",
            "Székesfehérvári Járás",
            "Székkutas",
            "Szekszárd",
            "Szekszárdi Járás",
            "Szelevény",
            "Szendrő",
            "Szentendre",
            "Szentendrei Járás",
            "Szentes",
            "Szentesi Járás",
            "Szentgálpuszta",
            "Szentgotthárd",
            "Szentgotthárdi Járás",
            "Szentistván",
            "Szentkirály",
            "Szentkirályszabadja",
            "Szentlőrinc",
            "Szentlőrinci Járás",
            "Szentlőrinckáta",
            "Szentmártonkáta",
            "Szerencs",
            "Szerencsi Járás",
            "Szigetcsép",
            "Szigethalom",
            "Szigetszentmiklós",
            "Szigetszentmiklósi Járás",
            "Szigetújfalu",
            "Szigetvár",
            "Szigetvári Járás",
            "Szihalom",
            "Szikszó",
            "Szikszói Járás",
            "Szilvásvárad",
            "Szirmabesenyő",
            "Szob",
            "Szobi Járás",
            "Sződ",
            "Sződliget",
            "Szolnok",
            "Szolnoki Járás",
            "Szombathely",
            "Szombathelyi Járás",
            "Szomód",
            "Szomor",
            "Szőny",
            "Szurdokpüspöki",
            "Tab",
            "Tabi Járás",
            "Táborfalva",
            "Tahitótfalu",
            "Taksony",
            "Taktaharkány",
            "Taktaszada",
            "Tállya",
            "Tamási",
            "Tamási Járás",
            "Tápióbicske",
            "Tápiógyörgye",
            "Tápióság",
            "Tápiószecső",
            "Tápiószele",
            "Tápiószentmárton",
            "Tápiószőlős",
            "Táplánszentkereszt",
            "Tapolca",
            "Tapolcai Járás",
            "Tar",
            "Tarcal",
            "Tardos",
            "Tarján",
            "Tárkány",
            "Tarnalelesz",
            "Tarnaörs",
            "Tárnok",
            "Tarpa",
            "Tass",
            "Taszár",
            "Tatabánya",
            "Tázlár",
            "Téglás",
            "Telki",
            "Tengelic",
            "Tét",
            "Téti Járás",
            "Tihany",
            "Tiszaalpár",
            "Tiszabercel",
            "Tiszabezdéd",
            "Tiszabő",
            "Tiszabura",
            "Tiszacsege",
            "Tiszadada",
            "Tiszadob",
            "Tiszaeszlár",
            "Tiszaföldvár",
            "Tiszafüred",
            "Tiszafüredi Járás",
            "Tiszakarád",
            "Tiszakécske",
            "Tiszakécskei Járás",
            "Tiszakeszi",
            "Tiszalök",
            "Tiszalúc",
            "Tiszanagyfalu",
            "Tiszanána",
            "Tiszapüspöki",
            "Tiszaroff",
            "Tiszasüly",
            "Tiszaszentimre",
            "Tiszaszőlős",
            "Tiszaújváros",
            "Tiszaújvárosi Járás",
            "Tiszavasvári",
            "Tiszavasvári Járás",
            "Tóalmás",
            "Tokaj",
            "Tokaji Járás",
            "Tokodaltáró",
            "Tököl",
            "Tolcsva",
            "Tolna",
            "Tolnai Járás",
            "Töltéstava",
            "Tömörkény",
            "Tompa",
            "Tornyospálca",
            "Törökbálint",
            "Törökszentmiklós",
            "Törökszentmiklósi Járás",
            "Törtel",
            "Tószeg",
            "Tótkomlós",
            "Tunyogmatolcs",
            "Tura",
            "Türje",
            "Túrkeve",
            "Tuzsér",
            "Tyukod",
            "Újfehértó",
            "Újhartyán",
            "Újkígyós",
            "Újszász",
            "Újszilvás",
            "Üllés",
            "Üllő",
            "Úny",
            "Úri",
            "Úrkút",
            "Üröm",
            "Vác",
            "Váci Járás",
            "Vácszentlászló",
            "Vaja",
            "Vajszló",
            "Vál",
            "Valkó",
            "Vámosgyörk",
            "Vámospércs",
            "Várgesztes",
            "Városföld",
            "Várpalota",
            "Várpalotai Járás",
            "Vásárosnamény",
            "Vásárosnaményi Járás",
            "Vaskút",
            "Vasvár",
            "Vasvári Járás",
            "Vecsés",
            "Vecsési Járás",
            "Velence",
            "Vép",
            "Veresegyház",
            "Verőce",
            "Verpelét",
            "Vérteskethely",
            "Vértessomló",
            "Vértesszőlős",
            "Vértestolna",
            "Veszprém",
            "Veszprémi Járás",
            "Vésztő",
            "Villány",
            "Visegrád",
            "Vonyarcvashegy",
            "Zagyvarékas",
            "Zagyvaszántó",
            "Záhony",
            "Záhonyi Járás",
            "Zákányszék",
            "Zalaegerszeg",
            "Zalaegerszegi Járás",
            "Zalakomár",
            "Zalalövő",
            "Zalaszentgrót",
            "Zalaszentgróti Járás",
            "Zamárdi",
            "Zámoly",
            "Zánka",
            "Zirc",
            "Zirci Járás",
            "Zomba",
            "Zsámbék",
            "Zsámbok",
            "Zsombó",
            "Zugló"
        ]
    },
    {
        "label": "Iceland",
        "code": "IS",
        "phone": "354",
        "cities": [
            "Akrahreppur",
            "Akranes",
            "Akureyri",
            "Álftanes",
            "Ásahreppur",
            "Bláskógabyggð",
            "Borgarbyggð",
            "Borgarfjarðarhreppur",
            "Borgarnes",
            "Breiðdalshreppur",
            "Dalabyggð",
            "Dalvík",
            "Dalvíkurbyggð",
            "Egilsstaðir",
            "Eskifjörður",
            "Eyja- og Miklaholtshreppur",
            "Eyjafjarðarsveit",
            "Fjallabyggð",
            "Fjarðabyggð",
            "Fljótsdalshérað",
            "Fljótsdalshreppur",
            "Flóahreppur",
            "Garðabær",
            "Garður",
            "Grímsnes- og Grafningshreppur",
            "Grindavík",
            "Grýtubakkahreppur",
            "Hafnarfjörður",
            "Helgafellssveit",
            "Höfn",
            "Hörgársveit",
            "Hrunamannahreppur",
            "Húnaþing Vestra",
            "Húsavík",
            "Hvalfjarðarsveit",
            "Hveragerði",
            "Ísafjarðarbær",
            "Ísafjörður",
            "Keflavík",
            "Kjósarhreppur",
            "Kópavogur",
            "Langanesbyggð",
            "Laugar",
            "Mosfellsbaer",
            "Mosfellsbær",
            "Mýrdalshreppur",
            "Neskaupstaður",
            "Ólafsvík",
            "Reyðarfjörður",
            "Reykhólahreppur",
            "Reykjanesbær",
            "Reykjavík",
            "Sandgerði",
            "Sauðárkrókur",
            "Selfoss",
            "Seltjarnarnes",
            "Siglufjörður",
            "Skaftárhreppur",
            "Skagabyggð",
            "Skeiða- og Gnúpverjahreppur",
            "Skorradalshreppur",
            "Skútustaðahreppur",
            "Snæfellsbær",
            "Strandabyggð",
            "Stykkishólmur",
            "Svalbarðsstrandarhreppur",
            "Sveitarfélagið Skagafjörður",
            "Tálknafjarðarhreppur",
            "Tjörneshreppur",
            "Vestmannaeyjabær",
            "Vestmannaeyjar",
            "Vogar",
            "Þingeyjarsveit",
            "Þorlákshöfn"
        ]
    },
    {
        "label": "India",
        "code": "IN",
        "phone": "91",
        "cities": [
            "Abhaneri",
            "Abhayapuri",
            "Abiramam",
            "Abohar",
            "Abrama",
            "Abu",
            "Abu Road",
            "Achalpur",
            "Achhnera",
            "Adalaj",
            "Adampur",
            "Adawad",
            "Addanki",
            "Adilabad",
            "Adirampattinam",
            "Adoni",
            "Adra",
            "Adur",
            "Aduthurai",
            "Afzalgarh",
            "Afzalpur",
            "Agar",
            "Agar Panchaitan",
            "Agartala",
            "Agol",
            "Agra",
            "Aheri",
            "Ahmadpur",
            "Ahmedabad",
            "Ahmednagar",
            "Ahmedpur",
            "Ahraura",
            "Ahwa",
            "Aidalpur",
            "Airoli",
            "Airwa",
            "Aistala",
            "Aizawl",
            "Ajaigarh",
            "Ajara",
            "Ajitgarh",
            "Ajjampur",
            "Ajmer",
            "Ajnala",
            "Akalgarh",
            "Akalkot",
            "Akaltara",
            "Akasahebpet",
            "Akbarpur",
            "Akhnur",
            "Akividu",
            "Akkarampalle",
            "Aklera",
            "Akluj",
            "Aknapur",
            "Akodia",
            "Akola",
            "Akola",
            "Akolner",
            "Akot",
            "Akrani",
            "Akrund",
            "Alagapuram",
            "Alampur",
            "Alampur",
            "Aland",
            "Alandi",
            "Alandur",
            "Alanganallur",
            "Alangayam",
            "Alangudi",
            "Alangulam",
            "Alappakkam",
            "Alappuzha",
            "Alawalpur",
            "Aldona",
            "Ale",
            "Alibag",
            "Aliganj",
            "Aligarh",
            "Alipur",
            "Alipurduar",
            "Alirajpur",
            "Alkuti",
            "Allahabad",
            "Allahganj",
            "Allapalli",
            "Almora",
            "Alnavar",
            "Along",
            "Alot",
            "Alur",
            "Aluva",
            "Alwa Tirunagari",
            "Alwar",
            "Alwaye",
            "Amalapuram",
            "Amalner",
            "Amanganj",
            "Amanpur",
            "Amarkantak",
            "Amarnath",
            "Amarpatan",
            "Amarpur",
            "Amarpur",
            "Amarwara",
            "Amauli",
            "Ambad",
            "Ambagarh Chauki",
            "Ambah",
            "Ambahta",
            "Ambajogai",
            "Ambala",
            "Ambasa",
            "Ambasamudram",
            "Ambattur",
            "Ambedkar Nagar",
            "Ambegaon",
            "Ambernath",
            "Ambikapur",
            "Ambur",
            "Amet",
            "Amethi",
            "Amgaon",
            "Amguri",
            "Amla",
            "Amlagora",
            "Amli",
            "Amloh",
            "Ammapettai",
            "Amod",
            "Amod",
            "Amravati",
            "Amreli",
            "Amritsar",
            "Amroha",
            "Amroli",
            "Amta",
            "Amtala",
            "Amudalavalasa",
            "Anakapalle",
            "Anamalais",
            "Anand",
            "Anandnagar",
            "Anandpur Sahib",
            "Anantapur",
            "Anantnag",
            "Andal",
            "Andheri",
            "Andippatti",
            "Andol",
            "Andura",
            "Anekal",
            "Angamali",
            "Angul",
            "Angul District",
            "Anjad",
            "Anjangaon",
            "Anjar",
            "Anjarle",
            "Anjaw",
            "Ankleshwar",
            "Ankola",
            "Annamalainagar",
            "Annavasal",
            "Annigeri",
            "Annur",
            "Anshing",
            "Anta",
            "Anthiyur",
            "Antri",
            "Antu",
            "Anupgarh",
            "Anuppur",
            "Anupshahr",
            "Aonla",
            "Arag",
            "Arakkonam",
            "Arambagh community development block",
            "Arambol",
            "Arang",
            "Arangaon",
            "Arantangi",
            "Araria",
            "Araul",
            "Arcot",
            "Ardhapur",
            "Argaon",
            "Arimalam",
            "Ariyalur",
            "Arkalgud",
            "Arki",
            "Arni",
            "Aron",
            "Aroor",
            "Arrah",
            "Arsikere",
            "Artist Village",
            "Arukutti",
            "Arumbavur",
            "Arumuganeri",
            "Aruppukkottai",
            "Aruvankad",
            "Arvi",
            "Arwal",
            "Asalatganj",
            "Asandh",
            "Asansol",
            "Asarganj",
            "Ashoknagar",
            "Ashoknagar Kalyangarh",
            "Ashta",
            "Ashta",
            "Ashti",
            "Asifabad",
            "Asika",
            "Asind",
            "Asoda",
            "Assaye",
            "Astagaon",
            "Atarra",
            "Ateli Mandi",
            "Athagarh",
            "Athni",
            "Atmakur",
            "Atrauli",
            "Atraulia",
            "Attayyampatti",
            "Attili",
            "Attingal",
            "Attur",
            "Aundh Satara",
            "Aurad",
            "Auraiya",
            "Aurangabad",
            "Aurangabad",
            "Auras",
            "Auroville",
            "Ausa",
            "Avadi",
            "Avanigadda",
            "Avanoor",
            "Avinashi",
            "Awantipur",
            "Ayakudi",
            "Ayodhya",
            "Ayyampettai",
            "Azamgarh",
            "Azhikkal",
            "Azizpur",
            "Babai",
            "Baberu",
            "Babina",
            "Babra",
            "Babrala",
            "Babugarh",
            "Bachhraon",
            "Bachhrawan",
            "Bada Barabil",
            "Badagara",
            "Badami",
            "Badarpur",
            "Badarwas",
            "Baddi",
            "Badgam",
            "Badhni Kalan",
            "Badkulla",
            "Badlapur",
            "Badnapur",
            "Badnawar",
            "Badnera",
            "Baduria",
            "Badvel",
            "Baga",
            "Bagaha",
            "Bagalkot",
            "Bagar",
            "Bagasara",
            "Bagasra",
            "Bagdogra",
            "Bagepalli",
            "Bageshwar",
            "Bagewadi",
            "Bagh",
            "Bagha Purana",
            "Baghpat",
            "Baghra",
            "Bagli",
            "Bagnan",
            "Bagra",
            "Bagula",
            "Bah",
            "Bahadurganj",
            "Bahadurgarh",
            "Baheri",
            "Bahjoi",
            "Bahraich",
            "Bahraigh",
            "Bahsuma",
            "Bahua",
            "Bahula",
            "Baidyabati",
            "Baihar",
            "Baikunthpur",
            "Baikunthpur",
            "Bail-Hongal",
            "Bairagnia",
            "Baisi",
            "Bajna",
            "Bakani",
            "Bakewar",
            "Bakharla",
            "Bakhtiyarpur",
            "Bakloh",
            "Bakreswar",
            "Baksa",
            "Baksar",
            "Bakshwaha",
            "Balachor",
            "Balagam",
            "Balaghat",
            "Balamau",
            "Balangir",
            "Balapur",
            "Balapur",
            "Balapur Akola district",
            "Balarampur",
            "Balasinor",
            "Balasore",
            "Baldeo",
            "Baldeogarh",
            "Baldev",
            "Baleshwar",
            "Bali",
            "Bali Chak",
            "Balimila",
            "Balisana",
            "Ballalpur",
            "Ballard Estate",
            "Ballari",
            "Ballarpur",
            "Ballia",
            "Bally",
            "Balod",
            "Baloda",
            "Baloda Bazar",
            "Balotra",
            "Balrampur",
            "Balugaon",
            "Balurghat",
            "Bamanbore",
            "Bamangola community development block",
            "Bambolim",
            "Bamboo Flat",
            "Bamna",
            "Bamor Kalan",
            "Bamora",
            "Banapur",
            "Banas Kantha",
            "Banat",
            "Banavar",
            "Banbasa",
            "Banda",
            "Banda",
            "Banda Maharashtra",
            "Bandia",
            "Bandikui",
            "Bandipore",
            "Bandora",
            "Bandra",
            "Baner",
            "Baneswar",
            "Banga",
            "Bangalore Rural",
            "Bangalore Urban",
            "Banganapalle",
            "Bangaon",
            "Bangaon",
            "Bangarapet",
            "Bangarmau",
            "Banihal",
            "Banjar",
            "Banka",
            "Banki",
            "Bankot",
            "Bankra",
            "Bankura",
            "Banmankhi",
            "Bannur",
            "Banposh",
            "Bansberia",
            "Bansdih",
            "Bansgaon",
            "Bansi",
            "Bansihari community development block",
            "Banswada",
            "Banswara",
            "Banthra",
            "Bantva",
            "Bantval",
            "Banur",
            "Bapatla",
            "Bar Bigha",
            "Bara Banki",
            "Bara Uchana",
            "Barabazar",
            "Baragaon",
            "Baragarh",
            "Baramati",
            "Baramula",
            "Baran",
            "Baranagar",
            "Barasat",
            "Barauli",
            "Baraut",
            "Barbil",
            "Bardhaman",
            "Bardoli",
            "Bareilly",
            "Barela",
            "Bargarh",
            "Barghat",
            "Bargi",
            "Barh",
            "Barhalganj",
            "Barhi",
            "Barhiya",
            "Bari",
            "Bari Sadri",
            "Bariarpur",
            "Barjala",
            "Barjora",
            "Barka Kana",
            "Barkhera",
            "Barkhera Kalan",
            "Barki Saria",
            "Barkot",
            "Barmer",
            "Barnala",
            "Barokhar",
            "Barpali",
            "Barpathar",
            "Barpeta",
            "Barpeta Road",
            "Barrackpore",
            "Barsana",
            "Barsi",
            "Baruipur",
            "Baruni",
            "Barwadih",
            "Barwala",
            "Barwani",
            "Barwar (Lakhimpur Kheri)",
            "Basanti",
            "Basar",
            "Basavakalyan",
            "Basavana Bagevadi",
            "Basi",
            "Basirhat",
            "Basmat",
            "Basna",
            "Basni",
            "Basoda",
            "Bastar",
            "Basti",
            "Basudebpur",
            "Basugaon",
            "Baswa",
            "Batala",
            "Batoti",
            "Baud",
            "Baudh",
            "Bavdhan",
            "Bawal",
            "Bawali",
            "Bawana",
            "Bawanbir",
            "Bayana",
            "Bazpur",
            "Beawar",
            "Bedi",
            "Beed",
            "Beed",
            "Begamganj",
            "Begampur",
            "Begowal",
            "Begun",
            "Begusarai",
            "Behat",
            "Behror",
            "Bela",
            "Belagavi",
            "Belaguntha",
            "Belda",
            "Beldanga",
            "Beliatore",
            "Bellampalli",
            "Belluru",
            "Belonia",
            "Belsand",
            "Beltangadi",
            "Belthara",
            "Belur",
            "Belur",
            "Bemetara",
            "Benaulim",
            "Bengaluru",
            "Beniganj",
            "Beohari",
            "Berasia",
            "Berhampore",
            "Beri Khas",
            "Beswan",
            "Betamcherla",
            "Betma",
            "Bettiah",
            "Betul",
            "Betul Bazar",
            "Bewar",
            "Beypore",
            "Bhabhra",
            "Bhabhua",
            "Bhachau",
            "Bhadarsa",
            "Bhadarwah",
            "Bhadasar",
            "Bhadaur",
            "Bhadgaon Maharashtra",
            "Bhadohi",
            "Bhadra",
            "Bhadrachalam",
            "Bhadradri Kothagudem",
            "Bhadrak",
            "Bhadrakh",
            "Bhadran",
            "Bhadravati",
            "Bhadreswar",
            "Bhagalpur",
            "Bhagirathpur",
            "Bhagwantnagar",
            "Bhainsdehi",
            "Bhaisa",
            "Bhalki",
            "Bhandara",
            "Bhandardaha",
            "Bhandardara",
            "Bhander",
            "Bhandu",
            "Bhandup",
            "Bhanjanagar",
            "Bhanpura",
            "Bhanpuri",
            "Bhanvad",
            "Bharatpur",
            "Bharatpura",
            "Bhargain",
            "Bharthana",
            "Bharuch",
            "Bharwari",
            "Bhasawar",
            "Bhatapara",
            "Bhatgaon",
            "Bhatha",
            "Bhatinda",
            "Bhatkal",
            "Bhatpara",
            "Bhattiprolu",
            "Bhaupur",
            "Bhavani",
            "Bhavnagar",
            "Bhawaniganj",
            "Bhawanigarh",
            "Bhawanipatna",
            "Bhawanipur",
            "Bhayandar",
            "Bhayavadar",
            "Bhigvan",
            "Bhikangaon",
            "Bhikhi",
            "Bhilai",
            "Bhildi",
            "Bhilwara",
            "Bhim Tal",
            "Bhimavaram",
            "Bhimtal",
            "Bhimunipatnam",
            "Bhind",
            "Bhindar",
            "Bhinga",
            "Bhinmal",
            "Bhitarwar",
            "Bhiwadi",
            "Bhiwandi",
            "Bhiwani",
            "Bhiwapur",
            "Bhognipur",
            "Bhogpur",
            "Bhojpur",
            "Bhojpur Dharampur",
            "Bhojudih",
            "Bhokar",
            "Bhokardan",
            "Bholath",
            "Bhongaon",
            "Bhongir",
            "Bhoom",
            "Bhoom",
            "Bhopal",
            "Bhor",
            "Bhowali",
            "Bhuban",
            "Bhubaneswar",
            "Bhudgaon",
            "Bhugaon",
            "Bhuj",
            "Bhuma",
            "Bhusaval",
            "Biaora",
            "Bidar",
            "Bidhnu",
            "Bidhuna",
            "Bighapur",
            "Bighapur Khurd",
            "Bihar Sharif",
            "Bihariganj",
            "Bihpuriagaon",
            "Bijapur",
            "Bijawar",
            "Bijbehara",
            "Bijni",
            "Bijnor",
            "Bijrauni",
            "Bijur",
            "Bikaner",
            "Bikapur",
            "Bikramganj",
            "Bilara",
            "Bilari",
            "Bilariaganj",
            "Bilashi",
            "Bilasipara",
            "Bilaspur",
            "Bilaspur",
            "Bilaspur",
            "Bilaspur",
            "Bilgi",
            "Bilgram",
            "Bilhaur",
            "Bilimora",
            "Bilkha",
            "Biloli",
            "Bilsanda",
            "Bilsi",
            "Bilthra",
            "Binauli",
            "Binaur",
            "Bindki",
            "Binka",
            "Birbhaddar",
            "Birbhum district",
            "Birdpur",
            "Birmitrapur",
            "Birpara",
            "Birpur",
            "Birpur",
            "Birur",
            "Bisalpur",
            "Bisanda Buzurg",
            "Bisauli",
            "Bisenda Buzurg",
            "Bishnah",
            "Bishnupur",
            "Bishnupur",
            "Bishunpur Urf Maharajganj",
            "Bissau",
            "Biswan",
            "Bithur",
            "Bobbili",
            "Bodhan",
            "Bodinayakkanur",
            "Bodri",
            "Boisar",
            "Bokajan",
            "Bokakhat",
            "Bokaro",
            "Bolanikhodan",
            "Bolpur",
            "Bomdila",
            "Bongaigaon",
            "Borgaon Manju",
            "Borivali",
            "Borkhera",
            "Borsad",
            "Botad",
            "Brahmapur",
            "Brahmapuri",
            "Brajarajnagar",
            "Breach Candy",
            "Budaun",
            "Buddh Gaya",
            "Budge Budge",
            "Budhana",
            "Budhlada",
            "Buguda",
            "Bulandshahr",
            "Buldana",
            "Bundi",
            "Bundu",
            "Burhanpur",
            "Burhar",
            "Buriya",
            "Burla",
            "Buxar",
            "Byadgi",
            "Byculla",
            "Byndoor",
            "Cachar",
            "Calangute",
            "Canacona",
            "Candolim",
            "Canning",
            "Captainganj",
            "Carapur",
            "Cavelossim",
            "Central Delhi",
            "Chabua",
            "Chaibasa",
            "Chail",
            "Chakan",
            "Chakapara",
            "Chakdaha",
            "Chakia",
            "Chakia",
            "Chaklasi",
            "Chakradharpur",
            "Chakrata",
            "Chaksu",
            "Chakulia",
            "Chakur",
            "Chalala",
            "Chalisgaon",
            "Challakere",
            "Challapalle",
            "Chaloda",
            "Chamba",
            "Chamoli",
            "Champa",
            "Champadanga",
            "Champahati",
            "Champaner",
            "Champawat",
            "Champdani",
            "Champhai",
            "Champua",
            "Chamrajnagar",
            "Chanasma",
            "Chanda",
            "Chandannagar",
            "Chandauli",
            "Chandauli District",
            "Chandausi",
            "Chandbali",
            "Chandel",
            "Chanderi",
            "Chandgad",
            "Chandia",
            "Chandigarh",
            "Chandil",
            "Chandla",
            "Chandor",
            "Chandpur",
            "Chandrakona",
            "Chandrapur",
            "Chanduasi",
            "Chandur",
            "Chandur",
            "Chandur Bazar",
            "Changanacheri",
            "Changlang",
            "Channagiri",
            "Channapatna",
            "Channarayapatna",
            "Chapar",
            "Chapra",
            "Charkhari",
            "Charkhi Dadri",
            "Charthawal",
            "Chas",
            "Chatakonda",
            "Chatra",
            "Chatrapur",
            "Chaupal",
            "Chausala",
            "Chechat",
            "Chelakara",
            "Chembur",
            "Chemmumiahpet",
            "Chengam",
            "Chengannur",
            "Chennai",
            "Chennimalai",
            "Cherpulassery",
            "Cherrapunji",
            "Cherthala",
            "Chetput",
            "Chettipalaiyam",
            "Chetwayi",
            "Cheyyar",
            "Cheyyur",
            "Chhabra",
            "Chhachhrauli",
            "Chhala",
            "Chhapar",
            "Chhaprauli",
            "Chharra",
            "Chhata",
            "Chhatapur",
            "Chhatarpur",
            "Chhibramau",
            "Chhindwara",
            "Chhitauni",
            "Chhota Udepur",
            "Chhoti Sadri",
            "Chhuikhadan",
            "Chhutmalpur",
            "Chicalim",
            "Chichli",
            "Chicholi",
            "Chichondi Patil",
            "Chidambaram",
            "Chidawa",
            "Chik Ballapur",
            "Chikhli",
            "Chikhli (Buldhana)",
            "Chikhli (Jalna)",
            "Chikitigarh",
            "Chikkaballapur",
            "Chikkamagaluru",
            "Chiknayakanhalli",
            "Chikodi",
            "Chilakalurupet",
            "Chillupar",
            "Chima",
            "Chimur",
            "Chinchani",
            "Chinchinim",
            "Chincholi",
            "Chinchpokli",
            "Chingleput",
            "Chinna Salem",
            "Chinnachowk",
            "Chinnamanur",
            "Chinnasekkadu",
            "Chintamani",
            "Chiplun",
            "Chipurupalle",
            "Chirala",
            "Chirang",
            "Chirgaon",
            "Chiria",
            "Chitapur",
            "Chitradurga",
            "Chitrakoot",
            "Chitrakoot Dham",
            "Chittaranjan",
            "Chittarkonda",
            "Chittaurgarh",
            "Chittoor",
            "Chittur",
            "Chodavaram",
            "Cholapuram",
            "Chopan",
            "Chopda",
            "Chorhat",
            "Chotila",
            "Chowari",
            "Chuari Khas",
            "Chuda",
            "Chunar",
            "Churachandpur",
            "Churk",
            "Churu",
            "Churulia",
            "Clement Town",
            "Closepet",
            "Cochin",
            "Coimbatore",
            "Colaba",
            "Colachel",
            "Colgong",
            "Colonelganj",
            "Colovale",
            "Colva",
            "Contai",
            "Cooch Behar",
            "Coondapoor",
            "Cortalim",
            "Cossimbazar",
            "Cuddalore",
            "Cuddapah",
            "Cumbum",
            "Cumbum",
            "Cuncolim",
            "Curchorem",
            "Curti",
            "Cuttack",
            "Dabhoda",
            "Dabhoi",
            "Dabhol",
            "Daboh",
            "Dabra",
            "Dabwali",
            "Daddi",
            "Dadra",
            "Dadra & Nagar Haveli",
            "Dadri",
            "Dagshai",
            "Dahanu",
            "Dahegam",
            "Dahivel",
            "Dahod",
            "Daitari",
            "Dakor",
            "Dakshin Dinajpur district",
            "Dakshina Kannada",
            "Dalhousie",
            "Dalkola",
            "Dalmau",
            "Dalsingh Sarai",
            "Daltonganj",
            "Dam Dam",
            "Daman",
            "Damnagar",
            "Damoh",
            "Dandeli",
            "Dandi",
            "Dangs (India)",
            "Dankaur",
            "Danta",
            "Dantewada",
            "Dapoli",
            "Daraganj",
            "Daranagar",
            "Darbhanga",
            "Dariba",
            "Darjeeling",
            "Darlawn",
            "Darrang",
            "Darsi",
            "Darwha",
            "Daryapur",
            "Dasna",
            "Dasnapur",
            "Dasuya",
            "Dataganj",
            "Datia",
            "Dattapur",
            "Daudnagar",
            "Daulatabad",
            "Daulatpur",
            "Daulatpur",
            "Daund",
            "Daurala",
            "Dausa",
            "Davanagere",
            "Davorlim",
            "Dayal Bagh",
            "Dayapar",
            "Debagram",
            "Debipur",
            "Deccan Gymkhana",
            "Deglur",
            "Dehradun",
            "Dehri",
            "Dehu",
            "Delhi",
            "Delvada",
            "Delwada",
            "Denkanikota",
            "Deoband",
            "Deogarh",
            "Deogarh",
            "Deogarh",
            "Deolali",
            "Deolapar",
            "Deoli",
            "Deoli",
            "Deoli",
            "Deoni",
            "Deoranian",
            "Deori",
            "Deori Khas",
            "Deoria",
            "Depalpur",
            "Dera Baba Nanak",
            "Dera Bassi",
            "Dera Gopipur",
            "Derapur",
            "Dergaon",
            "Deshnoke",
            "Desur",
            "Detroj",
            "Deulgaon Raja",
            "Devadanappatti",
            "Devakottai",
            "Devanhalli",
            "Devaprayag",
            "Devarkonda",
            "Devbhumi Dwarka",
            "Devgadh Bariya",
            "Devgarh",
            "Devrukh",
            "Dewa",
            "Dewas",
            "Dhaka",
            "Dhalai",
            "Dhali",
            "Dhamnod",
            "Dhampur",
            "Dhamtari",
            "Dhana",
            "Dhanaula",
            "Dhanaura",
            "Dhanbad",
            "Dhandhuka",
            "Dhanera",
            "Dhanghata",
            "Dhaniakhali community development block",
            "Dhansura",
            "Dhanwar",
            "Dhar",
            "Dharampur",
            "Dharampuri",
            "Dharamsala",
            "Dharangaon",
            "Dharapuram",
            "Dharasana",
            "Dharau",
            "Dharavi",
            "Dharchula",
            "Dhari",
            "Dhariwal",
            "Dharmabad",
            "Dharmadam",
            "Dharmanagar",
            "Dharmapuri",
            "Dharmavaram",
            "Dharuhera",
            "Dharur",
            "Dharwad",
            "Dhasa",
            "Dhaulpur",
            "Dhaurahra",
            "Dhawalpuri",
            "Dhekiajuli",
            "Dhemaji",
            "Dhenkanal",
            "Dhilwan",
            "Dhing",
            "Dhola",
            "Dholera",
            "Dholka",
            "Dhone",
            "Dhoraji",
            "Dhrangadhra",
            "Dhrol",
            "Dhubri",
            "Dhudi",
            "Dhulagari",
            "Dhule",
            "Dhulia",
            "Dhulian",
            "Dhupguri",
            "Dhuri",
            "Dhuwaran",
            "Diamond Harbour",
            "Dibai",
            "Dibang Valley",
            "Dibrugarh",
            "Dicholi",
            "Didwana",
            "Dig",
            "Digapahandi",
            "Digboi",
            "Digha",
            "Dighori",
            "Dighwara",
            "Diglur",
            "Digras",
            "Diguvametta",
            "Dima Hasao District",
            "Dimapur",
            "Dina Nagar",
            "Dinapore",
            "Dindigul",
            "Dindori",
            "Dindori Maharashtra",
            "Dinhata",
            "Diphu",
            "Dirba",
            "Disa",
            "Dispur",
            "Diu",
            "Diveagar",
            "Divrasai",
            "Dod Ballapur",
            "Doda",
            "Dohad",
            "Dohrighat",
            "Doiwala",
            "Domariaganj",
            "Dombivli",
            "Domjur",
            "Dondaicha",
            "Dongargaon",
            "Dongargarh",
            "Dongri",
            "Doraha",
            "Dornakal",
            "Dostpur",
            "Dubrajpur",
            "Dudhani",
            "Dudhi",
            "Dugadda",
            "Dugda",
            "Duliagaon",
            "Dum Duma",
            "Dumka",
            "Dumkhal",
            "Dumra",
            "Dumraon",
            "Dungarpur",
            "Dungarpur",
            "Durg",
            "Durgapur",
            "Durgapur",
            "Durgapur Chandrapur",
            "Dusi",
            "Dwarahat",
            "Dwarka",
            "East Delhi",
            "East District",
            "East Garo Hills",
            "East Godavari",
            "East Jaintia Hills",
            "East Kameng",
            "East Khasi Hills",
            "East Siang",
            "Edakkulam",
            "Egra",
            "Elamanchili",
            "Elayirampannai",
            "Ellenabad",
            "Ellore",
            "Elumalai",
            "Elur",
            "Emmiganur",
            "Eral",
            "Erandol",
            "Eraniel",
            "Erattupetta",
            "Ernakulam",
            "Erode",
            "Erraguntla",
            "Erumaippatti",
            "Etah",
            "Etawa",
            "Etawah",
            "Etikoppaka",
            "Etmadpur",
            "Ettaiyapuram",
            "Faizabad",
            "Faizpur",
            "Falakata",
            "Farah",
            "Farakka",
            "Faridabad",
            "Faridkot",
            "Faridnagar",
            "Faridpur",
            "Farrukhabad",
            "Farrukhnagar",
            "Farrukhnagar",
            "Fatehabad",
            "Fatehabad",
            "Fatehganj West",
            "Fatehgarh",
            "Fatehgarh Churian",
            "Fatehgarh Sahib",
            "Fatehpur",
            "Fatehpur",
            "Fatehpur (Barabanki)",
            "Fatehpur Chaurasi",
            "Fatehpur Sikri",
            "Fatwa",
            "Fazilka",
            "Ferokh",
            "Firozabad",
            "Firozpur",
            "Firozpur District",
            "Firozpur Jhirka",
            "Forbesganj",
            "Fort",
            "Fort Gloster",
            "French Rocks",
            "Fyzabad",
            "Gadag",
            "Gadag-Betageri",
            "Gadarwara",
            "Gadchiroli",
            "Gaddi Annaram",
            "Gadhada",
            "Gadhinglaj",
            "Gadwal",
            "Gagret",
            "Gahlon",
            "Gahmar",
            "Gaighata community development block",
            "Gaini",
            "Gairkata",
            "Gajapati",
            "Gajendragarh",
            "Gajraula",
            "Gajuwaka",
            "Galiakot",
            "Gandai",
            "Ganderbal",
            "Gandevi",
            "Gandhi Nagar",
            "Gandhidham",
            "Gandhinagar",
            "Gangadharpur",
            "Gangaikondan",
            "Gangakher",
            "Ganganagar",
            "Gangapur",
            "Gangapur",
            "Gangarampur",
            "Gangavalli",
            "Gangawati",
            "Gangoh",
            "Gangolli",
            "Gangtok",
            "Ganguvada",
            "Ganj Dundawara",
            "Ganj Dundwara",
            "Ganj Muradabad",
            "Ganjam",
            "Gannavaram",
            "Ganpatipule",
            "Garautha",
            "Gardhiwala",
            "Garha Brahman",
            "Garhakota",
            "Garhi Pukhta",
            "Garhmuktesar",
            "Garhshankar",
            "Garhwa",
            "Garhwa",
            "Garhwal",
            "Gariaband",
            "Gariadhar",
            "Garui",
            "Garulia",
            "Gaurela",
            "Gauriganj",
            "Gauripur",
            "Gautam Buddha Nagar",
            "Gautampura",
            "Gawan",
            "Gaya",
            "Gevrai",
            "Ghanaur",
            "Ghansor",
            "Gharaunda",
            "Ghargaon",
            "Gharghoda",
            "Ghatal",
            "Ghatampur",
            "Ghatanji",
            "Ghatkesar",
            "Ghatkopar",
            "Ghatsila",
            "Ghaziabad",
            "Ghazipur",
            "Ghiror",
            "Gho Brahmanan de",
            "Ghodasar",
            "Ghoga",
            "Ghogha",
            "Ghorawal",
            "Ghosi",
            "Ghoti Budrukh",
            "Ghugus",
            "Ghumarwin",
            "Gidam",
            "Giddalur",
            "Giddarbaha",
            "Gingee",
            "Gir Somnath",
            "Girgaon",
            "Giria",
            "Giridih",
            "Goa Velha",
            "Goalpara",
            "Gobardanga",
            "Gobichettipalayam",
            "Gobindapur",
            "Gobindpur",
            "Godda",
            "Godhra",
            "Gogapur",
            "Gohadi",
            "Gohana",
            "Gohand",
            "Gohpur",
            "Gokak",
            "Gokarna",
            "Gokavaram",
            "Gokul",
            "Gola Bazar",
            "Gola Gokarannath",
            "Golaghat",
            "Golakganj",
            "Gomati",
            "Gomoh",
            "Gonda",
            "Gondal",
            "Gondia",
            "Gopalganj",
            "Gopalpur",
            "Gopalpur",
            "Gopalur",
            "Gopamau",
            "Gopinathpur",
            "Gopinathpur",
            "Gorai",
            "Gorakhpur",
            "Gorakhpur",
            "Gorantla",
            "Goregaon",
            "Goribidnur",
            "Gorubathan",
            "Gorur",
            "Gorwa",
            "Gosaba",
            "Gosainganj",
            "Gosanimari",
            "Goshainganj",
            "Goshaingaon",
            "Govardhan",
            "GovindapuramChilakaluripetGuntur",
            "Govindgarh",
            "Govindgarh",
            "Greater Noida",
            "Gua",
            "Gubbi",
            "Gudalur",
            "Gudari",
            "Gudibanda",
            "Gudivada",
            "Gudiyatham",
            "Gudlavalleru",
            "Gudur",
            "Gudur",
            "Guduvancheri",
            "Guhagar",
            "Guirim",
            "Gulabpura",
            "Gulaothi",
            "Guledagudda",
            "Gumia",
            "Gumla",
            "Gummidipundi",
            "Guna",
            "Gundlupet",
            "Gunnaur",
            "Guntakal Junction",
            "Guntur",
            "Gunupur",
            "Gurdaha",
            "Gurdaspur",
            "Gurgaon",
            "Gurh",
            "Gurmatkal",
            "Gursahaiganj",
            "Gursarai",
            "Guru Har Sahai",
            "Guruvayur",
            "Guskhara",
            "Guwahati",
            "Gwalior",
            "Gyalshing",
            "Gyanpur",
            "Habra",
            "Hadagalli",
            "Hadapsar Pune",
            "Hadgaon",
            "Haflong",
            "Hailakandi",
            "Hajan",
            "Hajipur",
            "Hajipur",
            "Hajo",
            "Haldaur",
            "Haldia",
            "Haldibari",
            "Haldwani",
            "Halenda",
            "Halisahar",
            "Haliyal",
            "Halkarni",
            "Halol",
            "Halvad",
            "Hamirpur",
            "Hamirpur",
            "Hampi",
            "Handia",
            "Hangal",
            "Hansi",
            "Hansot",
            "Hanumangarh",
            "Hapur",
            "Haraipur",
            "Haraiya",
            "Harangul",
            "Harbatpur",
            "Harchandpur",
            "Harda",
            "Harda Khas",
            "Hardoi",
            "Harduaganj",
            "Hariana",
            "Haridwar",
            "Harihar",
            "Harij",
            "Harindanga",
            "Haringhata",
            "Haripur",
            "Harnai",
            "Harpalpur",
            "Harpanahalli",
            "Harrai",
            "Harsol",
            "Harsud",
            "Harur",
            "Hasanpur",
            "Hasanpur",
            "Hasimara",
            "Hassan",
            "Hastinapur",
            "Hata",
            "Hata (India)",
            "Hathras",
            "Hathuran",
            "Hatod",
            "Hatta",
            "Haveri",
            "Hayuliang",
            "Hazaribag",
            "Hazaribagh",
            "Heggadadevankote",
            "Helwak",
            "Hesla",
            "Hilsa",
            "Himatnagar",
            "Hindaun",
            "Hindoria",
            "Hindupur",
            "Hindusthan Cables Town",
            "Hinganghat",
            "Hingoli",
            "Hinjilikatu",
            "Hirakud",
            "Hiranagar",
            "Hirapur",
            "Hirapur Hamesha",
            "Hirekerur",
            "Hiriyur",
            "Hisar",
            "Hisua",
            "Hodal",
            "Hojai",
            "Holalkere",
            "Hole Narsipur",
            "Homnabad",
            "Honavar",
            "Honnali",
            "Hooghly district",
            "Hosanagara",
            "Hosangadi",
            "Hosdurga",
            "Hoshangabad",
            "Hoshiarpur",
            "Hoskote",
            "Hospet",
            "Hosur",
            "Hotgi",
            "Howli",
            "Howrah",
            "Hubballi",
            "Hukeri",
            "Hulas",
            "Hungund",
            "Hunsur",
            "Husainabad",
            "Hyderabad",
            "Iawar",
            "Ibrahimpur",
            "Ichalkaranji",
            "Ichapur",
            "Ichchapuram",
            "Ichhawar",
            "Idappadi",
            "Idar",
            "Idukki",
            "Igatpuri",
            "Iglas",
            "Ikauna",
            "Iklehra",
            "Ilampillai",
            "Ilkal",
            "Iluppur",
            "Imphal East",
            "Imphal West",
            "Inda Chhoi",
            "Indapur",
            "Indergarh",
            "Indi",
            "Indore",
            "Indpur community development block",
            "Indragarh",
            "Indri",
            "Ingraj Bazar",
            "Injambakkam",
            "Iringal",
            "Irinjalakuda",
            "Irugur",
            "Isagarh",
            "Ishanpur",
            "Islamnagar",
            "Islamnagar (Badaun)",
            "Islampur",
            "Islampur",
            "Itanagar",
            "Itarsi",
            "Itaunja",
            "Itimadpur",
            "Jabalpur",
            "Jafarpur",
            "Jagadhri",
            "Jagalur",
            "Jagannathpur",
            "Jagatsinghpur",
            "Jagdalpur",
            "Jagdishpur",
            "Jagdispur",
            "Jaggayyapeta",
            "Jagnair",
            "Jagraon",
            "Jagtial",
            "Jahanabad",
            "Jahanabad",
            "Jahanabad (Pilibhit)",
            "Jahangirabad",
            "Jahangirpur",
            "Jahazpur",
            "Jaigaon",
            "Jainpur",
            "Jaipur",
            "Jais",
            "Jaisalmer",
            "Jaisinghnagar",
            "Jaisingpur",
            "Jaitapur",
            "Jaitaran",
            "Jaithari",
            "Jaito",
            "Jajpur",
            "Jakhal",
            "Jakhangaon",
            "Jakhau",
            "Jalakandapuram",
            "Jalalabad",
            "Jalalabad",
            "Jalali",
            "Jalalpore",
            "Jalalpur",
            "Jalalpur",
            "Jalandhar",
            "Jalarpet",
            "Jalaun",
            "Jalesar",
            "Jaleshwar",
            "Jalgaon",
            "Jalgaon Jamod",
            "Jalia",
            "Jalkot",
            "Jalna",
            "Jalor",
            "Jalore",
            "Jalpaiguri",
            "Jamadoba",
            "Jamai",
            "Jamalpur",
            "Jambuda",
            "Jambusar",
            "Jamkhandi",
            "Jamkhed",
            "Jammalamadugu",
            "Jammu",
            "Jamnagar",
            "Jamod",
            "Jamshedpur",
            "Jamtara",
            "Jamui",
            "Jamuria",
            "Jandiala",
            "Jandiala Guru",
            "Janephal",
            "Jangaon",
            "Janghai",
            "Jangipur",
            "Janjgir",
            "Janjgir-Champa",
            "Jansath",
            "Jaoli",
            "Jaora",
            "Jarod",
            "Jarwa",
            "Jarwal",
            "Jasdan",
            "Jashpur",
            "Jashpurnagar",
            "Jasidih",
            "Jaspur",
            "Jasrana",
            "Jaswantnagar",
            "Jat Sangli",
            "Jatani",
            "Jatara",
            "Jategaon",
            "Jaunpur",
            "Jaurian",
            "Jawad",
            "Jawala Mukhi",
            "Jawhar",
            "Jayamkondacholapuram",
            "Jayashankar Bhupalapally",
            "Jaynagar",
            "Jaynagar Majilpur",
            "Jaysingpur",
            "Jehanabad",
            "Jejur",
            "Jejuri",
            "Jetalpur",
            "Jetalsar",
            "Jetpur",
            "Jetpur (Navagadh)",
            "Jevargi",
            "Jewar",
            "Jeypore",
            "Jha-Jha",
            "Jhabua",
            "Jhajhar",
            "Jhajjar",
            "Jhalawar",
            "Jhalida",
            "Jhalod",
            "Jhalrapatan",
            "Jhalu",
            "Jhanjharpur",
            "Jhansi",
            "Jhargram",
            "Jharia",
            "Jharsuguda",
            "Jharsuguda District",
            "Jhilimili",
            "Jhinjhak",
            "Jhinjhana",
            "Jhulasan",
            "Jhunjhunun",
            "Jhusi",
            "Jind",
            "Jintur",
            "Jiran",
            "Jiribam",
            "Jiyanpur",
            "Jobat",
            "Jobner",
            "Jodhpur",
            "Jodhpur",
            "Jodhpur (Ahmedabad)",
            "Jodia",
            "Jodiya Bandar",
            "Jogbani",
            "Jogeshwari",
            "Jogighopa",
            "Jogindarnagar",
            "Jogulamba Gadwal",
            "Jorethang",
            "Jorhat",
            "Joshimath",
            "Jua",
            "Jubbal",
            "Jugsalai",
            "Juhu",
            "Jumri Tilaiya",
            "Junagadh",
            "Junagarh",
            "Junnar",
            "Jutogh",
            "Jyotiba Phule Nagar",
            "Kabeerdham",
            "Kabrai",
            "Kachchh",
            "Kachholi",
            "Kachhwa",
            "Kachurwahi",
            "Kadakkavoor",
            "Kadambur",
            "Kadaura",
            "Kadayanallur",
            "Kadegaon",
            "Kadi",
            "Kadipur",
            "Kadiri",
            "Kadod",
            "Kadur",
            "Kadus",
            "Kagal",
            "Kagarol",
            "Kagaznagar",
            "Kaij",
            "Kaikalur",
            "Kailaras",
            "Kailashahar",
            "Kaimganj",
            "Kaimori",
            "Kaimur District",
            "Kaintragarh",
            "Kairana",
            "Kaithal",
            "Kakching",
            "Kakdwip",
            "Kakinada",
            "Kakori",
            "Kakrala",
            "Kalaburgi",
            "Kaladhungi",
            "Kalagarh Project Colony",
            "Kalahandi",
            "Kalaikunda",
            "Kalakkadu",
            "Kalamassery",
            "Kalamb",
            "Kalamb Osmanabad",
            "Kalamboli",
            "Kalamnuri",
            "Kalanaur",
            "Kalanaur",
            "Kalanwali",
            "Kalas",
            "Kalavad",
            "Kalavai",
            "Kalavoor",
            "Kalghatgi",
            "Kali(DK)",
            "Kaliaganj",
            "Kalikapur",
            "Kalimpong",
            "Kalinagar",
            "Kalka",
            "Kallakkurichchi",
            "Kallidaikurichi",
            "Kallupatti",
            "Kalmeshwar",
            "Kalna",
            "Kalol",
            "Kalpatta",
            "Kalpi",
            "Kalugumalai",
            "Kalundri",
            "Kalyan",
            "Kalyandurg",
            "Kalyani",
            "Kalyani Nagar",
            "Kalyanpur",
            "Kamakhyanagar",
            "Kamalapuram",
            "Kamalganj",
            "Kamalpur",
            "Kaman",
            "Kamareddi",
            "Kamareddy",
            "Kamargaon",
            "Kamarhati",
            "Kamarpukur",
            "Kamatgi",
            "Kamjong",
            "Kampil",
            "Kampli",
            "Kamptee",
            "Kamrup",
            "Kamrup Metropolitan",
            "Kamuthi",
            "Kanadukattan",
            "Kancheepuram",
            "Kanchipuram",
            "Kanchrapara",
            "Kandhamal",
            "Kandhla",
            "Kandi",
            "Kandla",
            "Kandla port",
            "Kandra",
            "Kandri",
            "Kandukur",
            "Kangar",
            "Kangayam",
            "Kangpokpi",
            "Kangra",
            "Kanigiri",
            "Kanina Khas",
            "Kankanhalli",
            "Kankauli",
            "Kankavli",
            "Kanke",
            "Kanker",
            "Kankipadu",
            "Kankon",
            "Kannad",
            "Kannangad",
            "Kannauj",
            "Kannavam",
            "Kanniyakumari",
            "Kannod",
            "Kannur",
            "Kanodar",
            "Kanor",
            "Kanpur",
            "Kanpur Dehat",
            "Kant",
            "Kantabanji",
            "Kanth",
            "Kantilo",
            "Kanuru",
            "Kapadvanj",
            "Kapren",
            "Kaptanganj",
            "Kapurthala",
            "Kara",
            "Karad",
            "Karaikal",
            "Karaikkudi",
            "Karajagi",
            "Karamadai",
            "Karambakkudi",
            "Karamsad",
            "Karanja",
            "Karanja Lad",
            "Karanpur",
            "Karari",
            "Karauli",
            "Karbi Anglong",
            "Karbigwan",
            "Karchana",
            "Kareli",
            "Karera",
            "Kargil",
            "Karhal",
            "Kariana",
            "Kariapatti",
            "Karimganj",
            "Karimnagar",
            "Karimpur",
            "Karjan",
            "Karjat",
            "Karkala",
            "Karkamb",
            "Karmala",
            "Karnal",
            "Karol Bagh",
            "Karrapur",
            "Kartarpur",
            "Karumbakkam",
            "Karur",
            "Karwar",
            "Kasara",
            "Kasaragod",
            "Kasaragod District",
            "Kasauli",
            "Kasba",
            "Kasganj",
            "Kashipur",
            "Kasoda",
            "Kasrawad",
            "Katangi",
            "Katghora",
            "Kathor",
            "Kathua",
            "Kati",
            "Katihar",
            "Katni",
            "Katol",
            "Katpadi",
            "Katpur",
            "Katra",
            "Katra",
            "Katral",
            "Katras",
            "Kattanam",
            "Kattivakkam",
            "Kattupputtur",
            "Katwa",
            "Kausani",
            "Kaushambi District",
            "Kavali",
            "Kavalur",
            "Kavaratti",
            "Kaveripatnam",
            "Kawant",
            "Kawardha",
            "Kayalpattinam",
            "Kayankulam",
            "Kayattar",
            "Kayavarohan",
            "Keelakarai",
            "Kekri",
            "Kelamangalam",
            "Kemri",
            "Kenda",
            "Kendrapara",
            "Kenduadih",
            "Kendujhar",
            "Kerur",
            "Kerwada",
            "Keshabpur",
            "Keshod",
            "Keshorai Patan",
            "Kesinga",
            "Khachrod",
            "Khada",
            "Khadki",
            "Khaga",
            "Khagaria",
            "Khagaul",
            "Khailar",
            "Khailar",
            "Khair",
            "Khairabad",
            "Khairagarh",
            "Khajuraho Group of Monuments",
            "Khalapur",
            "Khalilabad",
            "Khallar",
            "Khallikot",
            "Khamanon",
            "Khamaria",
            "Khambhalia",
            "Khambhat",
            "Khamgaon",
            "Khamharia",
            "Khammam",
            "Khanapur",
            "Khanapur",
            "Khandala",
            "Khandela",
            "Khandwa",
            "Khangaon",
            "Khanna",
            "Khanpur",
            "Khanpur",
            "Khapa",
            "Kharagpur",
            "Kharagpur",
            "Kharakvasla",
            "Kharar",
            "Kharar",
            "Kharba",
            "Kharda",
            "Khardaha",
            "Kharela",
            "Khargapur",
            "Kharghar",
            "Khargone",
            "Khargupur",
            "Kharhial",
            "Kharkhauda",
            "Kharkhauda",
            "Kharod",
            "Kharsawan",
            "Kharsia",
            "Kharsundi",
            "Kharupatia",
            "Khatauli",
            "Khategaon",
            "Khatima",
            "Khatra",
            "Khaur",
            "Khavda",
            "Khawhai",
            "Khed",
            "Kheda",
            "Khedbrahma",
            "Khedoi",
            "Khekra",
            "Khemkaran",
            "Kherali",
            "Kheralu",
            "Kheri",
            "Kheri Sampla",
            "Khetia",
            "Khetri",
            "Khilchipur",
            "Khirkiyan",
            "Khoni",
            "Khonsa",
            "Khopoli",
            "Khordha",
            "Khowai",
            "Khudaganj",
            "Khujner",
            "Khuldabad",
            "Khunti",
            "Khurai",
            "Khurda",
            "Khurja",
            "Khusropur",
            "Khutar",
            "Kichha",
            "Kil Bhuvanagiri",
            "Kilvelur",
            "Kinnaur",
            "Kinwat",
            "Kirakat",
            "Kirandul",
            "Kiranur",
            "Kiraoli",
            "Kiratpur",
            "Kiri Buru",
            "Kirnahar",
            "Kishanganj",
            "Kishangarh",
            "Kishanpur",
            "Kishanpur baral",
            "Kishni",
            "Kishtwar",
            "Kithor",
            "Kizhake Chalakudi",
            "Koath",
            "Kodagu",
            "Kodaikanal",
            "Kodala",
            "Kodar",
            "Kodarma",
            "Kodigenahalli",
            "Kodinar",
            "Kodlipet",
            "Kodoli",
            "Kodumudi",
            "Kodungallur",
            "Koelwar",
            "Kohima",
            "Kokrajhar",
            "Kolanukonda",
            "Kolar",
            "Kolaras",
            "Kolasib",
            "Kolhapur",
            "Kolkata",
            "Kollam",
            "Kollegal",
            "Kombai",
            "Konanur",
            "Konarka",
            "Konch",
            "Kondagaon",
            "Kondalwadi",
            "Kondapalle",
            "Kondhali",
            "Konganapuram",
            "Konnagar",
            "Konnur",
            "Koothanallur",
            "Kopaganj",
            "Kopar Khairane",
            "Kopargaon",
            "Kopela",
            "Koppa",
            "Koppal",
            "Koradachcheri",
            "Koradi",
            "Korampallam",
            "Koraput",
            "Koratagere",
            "Koratla",
            "Korba",
            "Koregaon",
            "Koria",
            "Korukollu",
            "Korwai",
            "Kosamba",
            "Kosi",
            "Kosigi",
            "Kot Isa Khan",
            "Kota",
            "Kota",
            "Kota",
            "Kotagiri",
            "Kotamangalam",
            "Kotaparh",
            "Kotar",
            "Kotdwara",
            "Kothapet",
            "Kothara",
            "Kotharia",
            "Kothi",
            "Kotkapura",
            "Kotkhai",
            "Kotla",
            "Kotma",
            "Kotputli",
            "Kotra",
            "Kottagudem",
            "Kottaiyur",
            "Kottapalli",
            "Kottayam",
            "Kotturu",
            "Kotwa",
            "Kovalam",
            "Kovilpatti",
            "Kovvur",
            "Koynanagar",
            "Kozhikode",
            "Krishna",
            "Krishnagiri",
            "Krishnanagar",
            "Krishnapur",
            "Krishnarajpet",
            "Kshirpai",
            "Kuchaiburi",
            "Kuchaman",
            "Kuchera",
            "Kuchesar",
            "Kuchinda",
            "Kud",
            "Kudachi",
            "Kudal",
            "Kudarkot",
            "Kudligi",
            "Kuhi",
            "Kuju",
            "Kukarmunda",
            "Kukatpally",
            "Kukma",
            "Kukshi",
            "Kulattur",
            "Kulgam",
            "Kulittalai",
            "Kulpahar",
            "Kulpi",
            "Kultali",
            "Kulti",
            "Kulu",
            "Kumaralingam",
            "Kumbakonam",
            "Kumbalam",
            "Kumbhraj",
            "Kumhari",
            "Kumher",
            "Kumsi",
            "Kumta",
            "Kunda",
            "Kundarkhi",
            "Kundarki",
            "Kundgol",
            "Kundla",
            "Kunigal",
            "Kunnamangalam",
            "Kunnamkulam",
            "Kunnattur",
            "Kunnumma",
            "Kuppam",
            "Kupwara",
            "Kurandvad",
            "Kurankhed",
            "Kurara",
            "Kurduvadi",
            "Kurebharsaidkhanpur",
            "Kurgunta",
            "Kurinjippadi",
            "Kurnool",
            "Kurseong",
            "Kurud",
            "Kurukshetra",
            "Kurung Kumey",
            "Kushalgarh",
            "Kushalnagar",
            "Kushinagar",
            "Kushtagi",
            "Kusmara",
            "Kusumba",
            "Kutch district",
            "Kuthaund",
            "Kutiatodu",
            "Kutiyana",
            "Kuttalam",
            "Kuttampuzha",
            "Kuzhithurai",
            "Kyathampalle",
            "Kyelang",
            "Lachhmangarh Sikar",
            "Ladakh",
            "Ladnun",
            "Ladol",
            "Ladwa",
            "Lahar",
            "Laharpur",
            "Lahul and Spiti",
            "Lakhandur",
            "Lakheri",
            "Lakhimpur",
            "Lakhimpur",
            "Lakhipur",
            "Lakhisarai",
            "Lakhna",
            "Lakhnadon",
            "Lakhpat",
            "Lakhtar",
            "Laksar",
            "Lakshadweep",
            "Lakshettipet",
            "Lakshmeshwar",
            "Lal Bahadur Nagar",
            "Lala",
            "Lalam",
            "Lalganj",
            "Lalganj",
            "Lalgarh",
            "Lalgola",
            "Lalgudi",
            "Lalitpur",
            "Lalpur",
            "Lalsot",
            "Lambhua",
            "Langhnaj",
            "Lanja",
            "Lansdowne",
            "Lar",
            "Lasalgaon",
            "Latehar",
            "Lathi",
            "Latur",
            "Laungowal",
            "Lavasa",
            "Lawar",
            "Lawar Khas",
            "Lawngtlai",
            "Leh",
            "Leteri",
            "Limbdi",
            "Limkheda",
            "Lingsugur",
            "Lodhikheda",
            "Lohaghat",
            "Lohardaga",
            "Loharu",
            "Lohit District",
            "Lohogaon",
            "Lonar",
            "Lonavla",
            "Londa",
            "Loni",
            "Lormi",
            "Losal",
            "Lower Dibang Valley",
            "Lower Subansiri",
            "Loyabad",
            "Luckeesarai",
            "Lucknow",
            "Lucknow District",
            "Ludhiana",
            "Lumding Railway Colony",
            "Lunavada",
            "Lunglei",
            "Machalpur",
            "Macherla",
            "Machhali Shahar",
            "Machhiwara",
            "Machhlishahr",
            "Machilipatnam",
            "Madambakkam",
            "Madanapalle",
            "Madanpur",
            "Maddagiri",
            "Maddur",
            "Madgaon",
            "Madhavpur Ghed",
            "Madhepura",
            "Madhi",
            "Madhoganj",
            "Madhogarh",
            "Madhogarh",
            "Madhubani",
            "Madhupur",
            "Madhyamgram",
            "Madikeri",
            "Madipakkam",
            "Madugula",
            "Madukkarai",
            "Madukkur",
            "Madurai",
            "Madurantakam",
            "Magadi",
            "Magam",
            "Maghar",
            "Mahabaleshwar",
            "Mahaban",
            "Mahabubabad",
            "Mahad",
            "Mahalingpur",
            "Maham",
            "Mahape",
            "Maharajgani",
            "Maharajganj",
            "Mahasamund",
            "Mahbubnagar",
            "Mahe",
            "Mahe",
            "Mahemdavad",
            "Mahendragarh",
            "Mahesana",
            "Maheshwar",
            "Mahgawan",
            "Mahiari",
            "Mahim",
            "Mahisa",
            "Mahishadal community development block",
            "Mahmudabad",
            "Mahoba",
            "Maholi",
            "Mahrajganj",
            "Mahrajganj (Raebareli)",
            "Mahroni",
            "Mahudha",
            "Mahul",
            "Mahur",
            "Mahuva",
            "Mahuva (Surat)",
            "Mahwah",
            "Maibong",
            "Maihar",
            "Mailani",
            "Mainaguri",
            "Maindargi",
            "Mainpuri",
            "Mairang",
            "Mairwa",
            "Majalgaon",
            "Majholi",
            "Majhupur",
            "Majitha",
            "Makanpur",
            "Makhjan",
            "Makhu",
            "Makrana",
            "Maksi",
            "Makum",
            "Malabar Hill",
            "Malad",
            "Malappuram",
            "Malasa",
            "Malaut",
            "Malavalli",
            "Malegaon",
            "Malerkotla",
            "Malhargarh",
            "Malihabad",
            "Malkajgiri",
            "Malkangiri",
            "Malkapur",
            "Malkera",
            "Mallapuram",
            "Mallasamudram",
            "Mallur",
            "Malpe",
            "Malpur",
            "Malpura",
            "Malur",
            "Malvan",
            "Mamit",
            "Manali",
            "Manali",
            "Manalurpettai",
            "Manamadurai",
            "Manappakkam",
            "Manapparai",
            "Manasa",
            "Manavadar",
            "Manavalakurichi",
            "Manawar",
            "Manchar",
            "Mancheral",
            "Mandal",
            "Mandal",
            "Mandalgarh",
            "Mandamarri",
            "Mandangad",
            "Mandapam",
            "Mandapeta",
            "Mandasa",
            "Mandawar",
            "Mandawar",
            "Mandhal",
            "Mandholi Kalan",
            "Mandi",
            "Mandideep",
            "Mandla",
            "Mandleshwar",
            "Mandsaur",
            "Mandvi",
            "Mandvi (Surat)",
            "Mandwa",
            "Mandya",
            "Maner",
            "Mangalagiri",
            "Mangalam",
            "Mangaldai",
            "Mangaluru",
            "Mangan",
            "Mangaon",
            "Mangawan",
            "Manglaur",
            "Mangrol",
            "Mangrol",
            "Mangrol (Junagadh)",
            "Mangrul Pir",
            "Maniar",
            "Manihari",
            "Manikpara",
            "Manikpur",
            "Manipal",
            "Manjeri",
            "Manjeshvar",
            "Manjhanpur",
            "Manjlegaon",
            "Mankachar",
            "Mankapur",
            "Mankeshwar",
            "Mankhurd",
            "Manmad",
            "Mannarakkat",
            "Mannargudi",
            "Manohar Thana",
            "Manoharpur",
            "Manoharpur",
            "Manor",
            "Manpur",
            "Mansa",
            "Mansa",
            "Mansar",
            "Manthani",
            "Manuguru",
            "Manvi",
            "Manwat",
            "Mapuca",
            "Marahra",
            "Marakkanam",
            "Marandahalli",
            "Marayur",
            "Maregaon",
            "Margherita",
            "Marhaura",
            "Mariahu",
            "Mariani",
            "Markapur",
            "Masaurhi Buzurg",
            "Masila",
            "Masinigudi",
            "Mastiholi",
            "Masur India",
            "Mataundh",
            "Mathabhanga",
            "Matheran",
            "Mathura",
            "Matiali community development block",
            "Matigara community development block",
            "Mattanur",
            "Mattur",
            "Matunga",
            "Mau",
            "Mau",
            "Mau Aima",
            "Mau Aimma",
            "Maudaha",
            "Mauganj",
            "Maur Mandi",
            "Maurawan",
            "Mavelikara",
            "Mavoor",
            "Mawana",
            "Mawar",
            "Mayakonda",
            "Mayiladuthurai",
            "Mayurbhanj",
            "Mazagaon",
            "Medak",
            "Medchal",
            "Medchal Malkajgiri",
            "Medinipur",
            "Meerut",
            "Meethari Marwar",
            "Meghraj",
            "Mehdawal",
            "Mehekar",
            "Mehergaon",
            "Mehkar",
            "Mehnagar",
            "Mehndawal",
            "Mehsana",
            "Mejia community development block",
            "Melukote",
            "Melur",
            "Memari",
            "Mendarda",
            "Merta",
            "Mettuppalaiyam",
            "Mettur",
            "Mhasla",
            "Mhasvad",
            "Mihona",
            "Milak",
            "Milkipur",
            "Minjur",
            "Miraj",
            "Miranpur",
            "Miranpur Katra",
            "Mirganj",
            "Mirialguda",
            "Mirik",
            "Mirzapur",
            "Misrikh",
            "Mithapur",
            "Modasa",
            "Moga",
            "Mohadi",
            "Mohali",
            "Mohan",
            "Mohanpur",
            "Mohanur",
            "Mohgaon",
            "Mohiuddi nagar",
            "Mohol",
            "Mohpa",
            "Mokameh",
            "Mokhada taluka",
            "Mokokchung",
            "Mon",
            "Monghyr",
            "Monoharpur",
            "Mora Maharashtra",
            "Moradabad",
            "Moram",
            "Moranha",
            "Morar",
            "Morbi",
            "Morena",
            "Morigaon",
            "Morinda",
            "Morjim",
            "Mormugao",
            "Morsi",
            "Morva (Hadaf)",
            "Morwa",
            "Moth",
            "Mothihari",
            "Mowad",
            "Mubarakpur",
            "Mudbidri",
            "Muddebihal",
            "Mudgal",
            "Mudgere",
            "Mudhol",
            "Mudkhed",
            "Mudukulattur",
            "Mughal Sarai",
            "Mugma",
            "Muhammadabad",
            "Mukerian",
            "Mukher",
            "Mukteshwar",
            "Mul",
            "Mulanur",
            "Mulbagal",
            "Mulgund",
            "Mulher",
            "Mulki",
            "Multai",
            "Mulund",
            "Muluppilagadu",
            "Mumbai",
            "Mumbai Suburban",
            "Mundargi",
            "Mundgod",
            "Mundi",
            "Mundra",
            "Mundwa",
            "Mungaoli",
            "Mungeli",
            "Munger",
            "Mungra Badshahpur",
            "Munirabad",
            "Munnar",
            "Munsyari",
            "Muradabad",
            "Muradnagar",
            "Muragacha",
            "Murbad",
            "Murgud",
            "Muri",
            "Murliganj",
            "Mursan",
            "Murshidabad",
            "Murtajapur",
            "Murud (Raigad)",
            "Murud (Ratnagiri)",
            "Murudeshwara",
            "Murum",
            "Murwara",
            "Musafir-Khana",
            "Musafirkhana",
            "Mushabani",
            "Musiri",
            "Mussoorie",
            "Mustafabad",
            "Muttupet",
            "Muvattupula",
            "Muvattupuzha",
            "Muzaffarnagar",
            "Muzaffarpur",
            "Mysuru",
            "Nabadwip",
            "Nabagram",
            "Nabarangpur",
            "Nabha",
            "Nabinagar",
            "Nadapuram",
            "Nadaun",
            "Nadbai",
            "Nadgaon",
            "Nadia district",
            "Nadiad",
            "Nadiad",
            "Nadigaon",
            "Naduvannur",
            "Naduvattam",
            "Nagamangala",
            "Nagaon",
            "Nagapattinam",
            "Nagapur",
            "Nagar",
            "Nagar",
            "Nagar Karnul",
            "Nagari",
            "Nagarukhra",
            "Nagaur",
            "Nagda",
            "Nagercoil",
            "Nagina",
            "Nagireddipalli",
            "Nagla",
            "Nagod",
            "Nagothana",
            "Nagpur",
            "Nagpur Division",
            "Nagrakata",
            "Nagram",
            "Nagrota",
            "Nagwa",
            "Nahan",
            "Naharlagun",
            "Nahorkatiya",
            "Naigarhi",
            "Naihati",
            "Naini Tal",
            "Nainpur",
            "Nainwa",
            "Najafgarh",
            "Najibabad",
            "Nakodar",
            "Naksalbari",
            "Nakur",
            "Nala Sopara",
            "Nalagarh",
            "Nalanda",
            "Nalbari",
            "Naldhara",
            "Naldurg",
            "Nalegaon",
            "Nalgonda",
            "Nalhati",
            "Naliya",
            "Nalpur",
            "Namagiripettai",
            "Namakkal",
            "Nambiyur",
            "Nambutalai",
            "Namchi",
            "Namkhana community development block",
            "Namli",
            "Nampur",
            "Namrup",
            "Nanauta",
            "Nandambakkam",
            "Nanded",
            "Nandgaon",
            "Nandgaon",
            "Nandigama",
            "Nandigram",
            "Nandikotkur",
            "Nandnee",
            "Nandura",
            "Nandura Buzurg",
            "Nandurbar",
            "Nandyal",
            "Nangal",
            "Nangavalli",
            "Nangi",
            "Nangilickondan",
            "Nangloi Jat",
            "Nanguneri",
            "Nanjangud",
            "Nannilam",
            "Nanpara",
            "Napasar",
            "Naraina",
            "Naraini",
            "Narasannapeta",
            "Narasapur",
            "Narasaraopet",
            "Narasimharajapura",
            "Narasingapuram",
            "Narauli",
            "Naraura",
            "Naravarikuppam",
            "Narayanavanam",
            "Narayangaon",
            "Narayangarh",
            "Narayangarh",
            "Narayanpet",
            "Narayanpur",
            "Nardana",
            "Naregal",
            "Narela",
            "Narendranagar",
            "Nargol",
            "Nargund",
            "Narharpur",
            "Nariman Point",
            "Narkhed",
            "Narmada",
            "Narnaul",
            "Narnaund",
            "Naroda",
            "Narora",
            "Narsimhapur",
            "Narsinghgarh",
            "Narsingi",
            "Narsipatnam",
            "Narwana",
            "Narwar",
            "Nashik",
            "Nashik Division",
            "Nasirabad",
            "Naspur",
            "Nasriganj",
            "Nasrullahganj",
            "Nathdwara",
            "Nattam",
            "Nattarasankottai",
            "Naugachhia",
            "Naugama",
            "Naurangpur",
            "Nautanwa",
            "Navalgund",
            "Navapur",
            "Navelim",
            "Navi Mumbai",
            "Navsari",
            "Nawa",
            "Nawabganj",
            "Nawabganj (Barabanki)",
            "Nawabganj (Bareilly)",
            "Nawada",
            "Nawalgarh",
            "Nawanshahr",
            "Nawanshahr",
            "Naya Bazar",
            "Nayagarh",
            "Nayagarh District",
            "Nayagram community development block",
            "Nayudupet",
            "Nazira",
            "Nedumangad",
            "Needamangalam",
            "Neelankarai",
            "Neem ka Thana",
            "Neemuch",
            "Negapatam",
            "Nelamangala",
            "Nellikkuppam",
            "Nellore",
            "Nepanagar",
            "Neral",
            "Nerur",
            "Neturhat",
            "Nevasa",
            "New Delhi",
            "Newara",
            "Neyyattinkara",
            "Nichlaul",
            "Nicobar",
            "Nidadavole",
            "Nighoj",
            "Nigoh",
            "Nihtaur",
            "Nikora",
            "Nilakottai",
            "Nilanga",
            "Nileshwar",
            "Nilgiri",
            "Nilgiris",
            "Nilokheri",
            "Nimaj",
            "Nimaparha",
            "Nimbahera",
            "Nipani",
            "Niphad",
            "Nira Narsingpur",
            "Nirmal",
            "Nirmali",
            "Nirsa",
            "Niwai",
            "Niwari",
            "Nizamabad",
            "Nizamabad",
            "Nizar",
            "Noamundi",
            "Nohar",
            "Noida",
            "Nokha",
            "Noney",
            "Nongpoh",
            "Nongstoin",
            "Noria",
            "North 24 Parganas district",
            "North Delhi",
            "North District",
            "North East Delhi",
            "North Garo Hills",
            "North Goa",
            "North Guwahati",
            "North Lakhimpur",
            "North Tripura",
            "North Vanlaiphai",
            "North West Delhi",
            "Nowrangapur",
            "Nuapada",
            "Nuh",
            "Numaligarh",
            "Nurmahal",
            "Nurpur",
            "Nurpur Kalan",
            "Nuzvid",
            "Nyamti",
            "Obra",
            "Odadar",
            "Odlabari",
            "Odugattur",
            "Okha",
            "Olpad",
            "Omalur",
            "Ongole",
            "Ooty",
            "Orai",
            "Oran",
            "Orchha",
            "Osmanabad",
            "Ottappalam",
            "Ozar",
            "Pabal",
            "Pachmarhi",
            "Pachora",
            "Pachperwa",
            "Padam",
            "Padampur",
            "Padampur",
            "Paddhari",
            "Padmanabhapuram",
            "Padra",
            "Padrauna",
            "Pahasu",
            "Pahlgam",
            "Pahur Maharashtra",
            "Paigaon",
            "Paikpara",
            "Paithan",
            "Pakala",
            "Pakur",
            "Palackattumala",
            "Palakkad district",
            "Palakkodu",
            "Palakollu",
            "Palamedu",
            "Palampur",
            "Palamu",
            "Palani",
            "Palanpur",
            "Palanswa",
            "Palasa",
            "Palasbari",
            "Palavakkam",
            "Palera",
            "Palghar",
            "Palghat",
            "Pali",
            "Pali",
            "Pali",
            "Pali Raigad",
            "Palia Kalan",
            "Palitana",
            "Paliyad",
            "Palkonda",
            "Palladam",
            "Pallappatti",
            "Pallattur",
            "Pallavaram",
            "Palle",
            "Pallevada",
            "Pallikondai",
            "Pallipattu",
            "Pallippatti",
            "Palmaner",
            "Paloncha",
            "Palsana",
            "Palso",
            "Palwal",
            "Palwancha",
            "Panagar",
            "Panagarh",
            "Panaji",
            "Panamaram",
            "Panara",
            "Panch Mahals",
            "Panchgani",
            "Panchkula",
            "Panchla",
            "Panchmahal district",
            "Panchmura",
            "Pandaria",
            "Pandatarai",
            "Pandhana",
            "Pandharpur",
            "Pandhurli",
            "Pandhurna",
            "Pandoh",
            "Pandua",
            "Pangala",
            "Panhala",
            "Panihati",
            "Panipat",
            "Panna",
            "Panruti",
            "Pansemal",
            "Panskura",
            "Panvel",
            "Paonta Sahib",
            "Papanasam",
            "Papireddippatti",
            "Papparappatti",
            "Pappinissheri",
            "Paradip Garh",
            "Paramagudi",
            "Paras Rampur",
            "Parasia",
            "Paravur Tekkumbhagam",
            "Parbatipur",
            "Parbhani",
            "Pardi",
            "Parel",
            "Parichha",
            "Parichhatgarh",
            "Pariyapuram",
            "Parlakimidi",
            "Parli Vaijnath",
            "Parner",
            "Parnera",
            "Parol",
            "Parola",
            "Parseoni",
            "Parshadepur",
            "Partapur",
            "Partur",
            "Parvatipuram",
            "Parvatsar",
            "Parwanoo",
            "Pasan",
            "Paschim Medinipur district",
            "Pashchim Champaran",
            "Pashchim Singhbhum",
            "Pasighat",
            "patamda",
            "Patamundai",
            "Patan",
            "Patan",
            "Patan",
            "Patan",
            "Patancheru",
            "Pataudi",
            "Pathakpura",
            "Pathalgaon",
            "Pathanamthitta",
            "Pathankot",
            "Pathardi",
            "Pathardih",
            "Patharia",
            "Pathri",
            "Patiala",
            "Patiali",
            "Patiram",
            "Patna",
            "Patnagarh",
            "Patrasaer",
            "Pattan",
            "Pattanamtitta",
            "Patti",
            "Patti",
            "Pattukkottai",
            "Patuli",
            "Patur",
            "Paturda",
            "Paud",
            "Pauni",
            "Pauri",
            "Pavi Jetpur",
            "Pavugada",
            "Pavuluru",
            "Pawai",
            "Pawayan",
            "Pawni",
            "Payagpur",
            "Payyannur",
            "Pedana",
            "pedda nakkalapalem",
            "Peddapalli",
            "Peddapuram",
            "Pedgaon",
            "Pehowa",
            "Peint",
            "Pen",
            "Pendra",
            "Pennadam",
            "Pennagaram",
            "Pennathur",
            "Penugonda",
            "Penukonda",
            "Peraiyur",
            "Perambalur",
            "Peranamallur",
            "Peranampattu",
            "Peravurani",
            "Peren",
            "Perinthalmanna",
            "Periyakulam",
            "Periyanayakkanpalaiyam",
            "Periyanegamam",
            "Periyapatti",
            "Periyapattinam",
            "Pernem",
            "Perumbavoor",
            "Perumpavur",
            "Perundurai",
            "Perungudi",
            "Perur",
            "Perya",
            "Petlad",
            "Petlawad",
            "Phagwara",
            "Phalauda",
            "Phalodi",
            "Phaltan",
            "Phaphamau",
            "Phaphund",
            "Phariha",
            "Phek",
            "Pheona",
            "Pherzawl",
            "Phillaur",
            "Phirangipuram",
            "Phulambri",
            "Phulbani",
            "Phulera",
            "Phulpur",
            "Pichhaura",
            "Pihani",
            "Pilani",
            "Pilibangan",
            "Pilibhit",
            "Piliv",
            "Pilkhua",
            "Pilkhuwa",
            "Pimpalgaon Baswant",
            "Pimpalgaon Raja",
            "Pimpri",
            "Pimpri-Chinchwad",
            "Pinahat",
            "Pindwara",
            "Pinjaur",
            "Pipalkoti",
            "Pipar",
            "Pipavav",
            "Pipili",
            "Piplod",
            "Piploda",
            "Pippara",
            "Pipraich",
            "Pipri",
            "Pipri",
            "Piravam",
            "Pirawa",
            "Piriyapatna",
            "Piro",
            "Pitampura",
            "Pithampur",
            "Pithapuram",
            "Pithora",
            "Pithoragarh",
            "Pokaran",
            "Polasara",
            "Polavaram",
            "Pollachi",
            "Polur",
            "Ponda",
            "Ponmana",
            "Ponnamaravati",
            "Ponnampet",
            "Ponnani",
            "Ponneri",
            "Ponnur",
            "Ponnuru",
            "Poonamalle",
            "Porbandar",
            "Porsa",
            "Port Blair",
            "Porur",
            "Powai",
            "Prabhadevi",
            "Prabhas Patan",
            "Prakasam",
            "Prakasha",
            "Prantij",
            "Pratapgarh",
            "Pratapgarh",
            "Prayagraj (Allahabad)",
            "Proddatur",
            "Puducherry",
            "Pudukkottai",
            "Puduppatti",
            "Pudur",
            "Puduvayal",
            "Pujali",
            "Pukhrayan",
            "Pulgaon",
            "Pulivendla",
            "Puliyangudi",
            "Puliyur",
            "Pullambadi",
            "Pulwama",
            "Punahana",
            "Punalur",
            "Punasa",
            "Punch",
            "Puncha community development block",
            "Pundri",
            "Pune",
            "Pune Division",
            "Punganuru",
            "Punjai Puliyampatti",
            "Puntamba",
            "Pupri",
            "Pural",
            "Puranpur",
            "Purba Champaran",
            "Purba Medinipur district",
            "Purba Singhbhum",
            "Puri",
            "Purmafi",
            "Purna",
            "Purnia",
            "Purulia",
            "Purushottampur",
            "Purwa",
            "Pusad",
            "Pushkar",
            "Puttaparthi",
            "Puttur",
            "Puttur",
            "Qadian",
            "Qadirganj",
            "Qazigund",
            "Quepem",
            "Queula",
            "Quthbullapur",
            "Rabkavi",
            "Rabupura",
            "Radaur",
            "Radha Kund",
            "Radhakund",
            "Radhanagari",
            "Radhanpur",
            "Raebareli",
            "Rafiganj",
            "Raghogarh",
            "Raghudebbati",
            "Raghunathpur",
            "Raghunathpur",
            "Raha",
            "Rahata",
            "Rahatgarh",
            "Rahimatpur",
            "Rahon",
            "Rahuri",
            "Raia",
            "Raichur",
            "Raiganj",
            "Raigarh",
            "Raigarh",
            "Raikot",
            "Raipur",
            "Raipur",
            "Raipur",
            "Raireshwar",
            "Raisen",
            "Raisinghnagar",
            "Raiwala Bara",
            "Raj Nandgaon",
            "Rajahmundry",
            "Rajakhera",
            "Rajaldesar",
            "Rajanna Sircilla",
            "Rajaori",
            "Rajapalaiyam",
            "Rajapur",
            "Rajapur",
            "Rajasansi",
            "Rajauri",
            "Rajgarh",
            "Rajgarh",
            "Rajgarh",
            "Rajgir",
            "Rajgurunagar",
            "Rajkot",
            "Rajmahal",
            "Rajnagar",
            "Rajnagar community development block",
            "Rajpipla",
            "Rajpur",
            "Rajpura",
            "Rajsamand",
            "Rajula",
            "Rajur",
            "Rajura",
            "Ralegaon",
            "Ram Das",
            "Ramachandrapuram",
            "Ramagundam",
            "Ramamangalam",
            "Ramanagara",
            "Ramanathapuram",
            "Ramanayyapeta",
            "Ramanuj Ganj",
            "Ramapuram",
            "Ramban",
            "Rambha",
            "Ramchandrapur",
            "Rameswaram",
            "Ramewadi",
            "Ramganj Mandi",
            "Ramgarh",
            "Ramgarh",
            "Ramgarh",
            "Ramgundam",
            "Ramjibanpur",
            "Ramkola",
            "Ramnagar",
            "Ramnagar",
            "Ramnagar",
            "Ramnagar",
            "Ramnagar",
            "Rampachodavaram",
            "Rampur",
            "Rampur",
            "Rampur Hat",
            "Rampura",
            "Rampura",
            "Rampura",
            "Ramtek",
            "Ranaghat",
            "Ranapur",
            "Ranavav",
            "Ranchi",
            "Rangapara",
            "Rangareddi",
            "Rangia",
            "Rangpo",
            "Rani",
            "Rania",
            "Ranibennur",
            "Raniganj",
            "Ranikhet",
            "Ranipet",
            "Ranipur",
            "Ranipur Barsi",
            "Ranir Bazar",
            "Ranpur",
            "Rapar",
            "Rasipuram",
            "Rasra",
            "Rasulabad",
            "Ratangarh",
            "Ratangarh",
            "Ratanpur",
            "Rath",
            "Ratia",
            "Ratlam",
            "Ratnagiri",
            "Raurkela",
            "Raver",
            "Rawatbhata",
            "Rawatsar",
            "Raxaul",
            "Ray",
            "Raya",
            "Rayachoti",
            "Rayadrug",
            "Rayagada",
            "Raybag",
            "Raypur",
            "Razam",
            "Razampeta",
            "Razole",
            "Reha",
            "Rehar",
            "Rehli",
            "Rehti",
            "Remuna",
            "Renapur",
            "Renavi",
            "Rengali",
            "Renigunta",
            "Renukoot",
            "Renukut",
            "Reoti",
            "Reotipur",
            "Repalle",
            "Revadanda",
            "Revdanda",
            "Revelganj",
            "Rewa",
            "Rewari",
            "Ri-Bhoi",
            "Riasi",
            "Richha",
            "Ringas",
            "Rishikesh",
            "Rishra",
            "Risod",
            "Robertsganj",
            "Robertsonpet",
            "Roha",
            "Roha",
            "Rohini",
            "Rohru",
            "Rohtak",
            "Rohtas",
            "Ron",
            "Roorkee",
            "Rudarpur",
            "Rudauli",
            "Rudraprayag",
            "Rupnagar",
            "Rura",
            "Rusera",
            "Sabalgarh",
            "Sabalpur",
            "Sabar Kantha",
            "Sabathu",
            "Sabrum",
            "Sachendi",
            "Sachin",
            "Sadabad",
            "Sadalgi",
            "Sadaseopet",
            "Sadat",
            "Sadri",
            "Safidon",
            "Safipur",
            "Sagar",
            "Sagar",
            "Sagauli",
            "Sahapur",
            "Saharanpur",
            "Saharsa",
            "Sahaspur",
            "Sahaswan",
            "Sahawar",
            "Sahibabad",
            "Sahibganj",
            "Sahpau",
            "Saidpur",
            "Saiha",
            "Sailana",
            "Sailu",
            "Saint Thomas Mount",
            "Sainthia",
            "Sairang",
            "Saitlaw",
            "Sakhanu",
            "Sakit",
            "Sakleshpur",
            "Sakol",
            "Sakoli",
            "Sakri",
            "Sakti",
            "Salanpur community development block",
            "Salaya",
            "Salem",
            "Salempur",
            "Saligao",
            "Salon",
            "Salumbar",
            "Salur",
            "Samakhiali",
            "Samalkha",
            "Samalkot",
            "Samastipur",
            "Samba",
            "Sambalpur",
            "Sambhal",
            "Sambhar",
            "Samdari",
            "Samrala",
            "Samthar",
            "Samudrapur",
            "Sanand",
            "Sanaur",
            "Sanawad",
            "Sanchi",
            "Sanchor",
            "Sancoale",
            "Sandi",
            "Sandila",
            "Sandur",
            "Sangameshwar",
            "Sangamner",
            "Sangareddi",
            "Sangaria",
            "Sangli",
            "Sangod",
            "Sangola",
            "Sangrampur Maharashtra",
            "Sangrur",
            "Sanguem",
            "Sanivarsante",
            "Sankarpur",
            "Sankeshwar",
            "Sankheda",
            "Sankrail",
            "Sanquelim",
            "Sant Kabir Nagar",
            "Sant Ravi Das Nagar",
            "Santipur",
            "Santokhgarh",
            "Santoshpur",
            "Santuri community development block",
            "Sanvordem",
            "Sanwer",
            "Saoli",
            "Saoner",
            "Sapatgram",
            "Sarahan",
            "Sarai Akil",
            "Sarai Ekdil",
            "Sarai Mir",
            "Saraikela",
            "Saraipali",
            "Saran",
            "Sarangarh",
            "Sarangkheda",
            "Sarangpur",
            "Sarauli",
            "Sarbhon",
            "Sardarshahr",
            "Sardhana",
            "Sardoi",
            "Sardulgarh",
            "Sarenga",
            "Sargur",
            "Sarila",
            "Sarka Ghat",
            "Sarkhej",
            "Sarubera",
            "Sarupathar",
            "Sarurpur",
            "Sarwar",
            "Sasni",
            "Saswad",
            "Satana",
            "Satara",
            "Satara Division",
            "Sathamba",
            "Sathankulam",
            "Sathupalli",
            "Sathyamangalam",
            "Satna",
            "Satpati",
            "Satrikh",
            "Sattenapalle",
            "Sattur",
            "Satwas",
            "Saugor",
            "Saundatti",
            "Saurikh",
            "Sausar",
            "Savantvadi",
            "Savanur",
            "Savarkundla",
            "Savda",
            "Savlaj",
            "Savli",
            "Sawai Madhopur",
            "Sawantvadi",
            "Sayalkudi",
            "Sayla",
            "Sector",
            "Secunderabad",
            "Sehore",
            "Selu",
            "Senapati",
            "Sendhwa",
            "Seohara",
            "Seondha",
            "Seoni",
            "Seoni",
            "Seoni Malwa",
            "Seorinarayan",
            "Seram",
            "Serampore",
            "Serchhip",
            "Serilingampalle",
            "Serpur",
            "Serula",
            "Sevagram",
            "Seven Pagodas",
            "Sewri",
            "Shadipur Julana",
            "Shahabad",
            "Shahabad",
            "Shahabad",
            "Shahada",
            "Shahapur",
            "Shahbazpur",
            "Shahdol",
            "Shahganj",
            "Shahgarh",
            "Shahi",
            "Shahid Bhagat Singh Nagar",
            "Shahjahanpur",
            "Shahkot",
            "Shahpur",
            "Shahpur",
            "Shahpur",
            "Shahpur",
            "Shahpur",
            "Shahpura",
            "Shahpura",
            "Shajapur",
            "Sham Churasi",
            "Shamgarh",
            "Shamli",
            "Shamsabad",
            "Shankargarh",
            "Sharanur",
            "Shedbal",
            "Shegaon",
            "Sheikhpura",
            "Sheoganj",
            "Sheohar",
            "Sheopur",
            "Shergarh",
            "Sherghati",
            "Sherkot",
            "Shertallai",
            "Shevgaon",
            "Shibnagar",
            "Shiggaon",
            "Shikarpur",
            "Shikarpur",
            "Shikarpur (Bulandshahr)",
            "Shikohabad",
            "Shikrapur",
            "Shillong",
            "Shimla",
            "Shimoga",
            "Shiraguppi",
            "Shirala",
            "Shirdi",
            "Shirgaon",
            "Shirhatti",
            "Shirol",
            "Shirpur",
            "Shirud",
            "Shirwal",
            "Shishgarh",
            "Shivaji Nagar",
            "Shivpuri",
            "Shivrajpur",
            "Shivrajpur",
            "Sholinghur",
            "Shorapur",
            "Shrawasti",
            "Shrigonda",
            "Shrirangapattana",
            "Shujalpur",
            "Shupiyan",
            "Shyamnagar West Bengal",
            "Sibsagar",
            "Siddapur",
            "Siddharthnagar",
            "Siddhaur",
            "Siddhpur",
            "Siddipet",
            "Sidhauli",
            "Sidhi",
            "Sidhpura",
            "Sidlaghatta",
            "Sihor",
            "Sihora",
            "Sijua",
            "Sikandarabad",
            "Sikandarpur",
            "Sikandra",
            "Sikandra Rao",
            "Sikandrabad",
            "Sikar",
            "Sikka",
            "Silao",
            "Silapathar",
            "Silchar",
            "Siliguri",
            "Sillod",
            "Silvassa",
            "Simaria",
            "Simdega",
            "Simga",
            "Sindewahi",
            "Sindgi",
            "Sindhnur",
            "Sindhudurg",
            "Sindi",
            "Sindkheda",
            "Singanallur",
            "Singapperumalkovil",
            "Singapur",
            "Singarayakonda",
            "Singoli",
            "Singrauli",
            "Singtam",
            "Singur",
            "Sini",
            "Sinnar",
            "Sinor",
            "Sion Mumbai",
            "Sira",
            "Sirathu",
            "Sirhind-Fategarh",
            "Sirkazhi",
            "Sirmaur",
            "Sirmaur",
            "Sirohi",
            "Sironcha",
            "Sironj",
            "Sirpur",
            "Sirsa",
            "Sirsa",
            "Sirsaganj",
            "Sirsi",
            "Sirsi",
            "Sirsilla",
            "Siruguppa",
            "Sirumugai",
            "Sirur",
            "Sisauli",
            "Siswa Bazar",
            "Sitamarhi",
            "Sitamau",
            "Sitapur",
            "Sitarganj",
            "Sivaganga",
            "Sivagiri",
            "Sivakasi",
            "Sivala East Godavari district",
            "Siwan",
            "Siwana",
            "Soalkuchi",
            "Sodpur",
            "Sohagi",
            "Sohagpur",
            "Sohna",
            "Sojat",
            "Sojitra",
            "Solan",
            "Solap",
            "Solapur",
            "Solim",
            "Someshwar",
            "Sompeta",
            "Somvarpet",
            "Sonada",
            "Sonala",
            "Sonamukhi",
            "Sonamura",
            "Sonari",
            "Sonarpur community development block",
            "Sonbhadra",
            "Sonegaon",
            "Sonepur",
            "Songadh",
            "Songir",
            "Sonipat",
            "Sonitpur",
            "Sonvad",
            "Sopur",
            "Sorab",
            "Sorada",
            "Sorbhog",
            "Soro",
            "Soron",
            "South 24 Parganas district",
            "South Andaman",
            "South Delhi",
            "South District",
            "South Garo Hills",
            "South Goa",
            "South Tripura",
            "South West Delhi",
            "South West Garo Hills",
            "South West Khasi Hills",
            "Soygaon",
            "Soyibug",
            "Sravana Belgola",
            "Sri Dungargarh",
            "Sri Madhopur",
            "Sri Muktsar Sahib",
            "Srikakulam",
            "Srikhanda",
            "Srimushnam",
            "Srinagar",
            "Srinagar",
            "Sringeri",
            "Srinivaspur",
            "Sriperumbudur",
            "Sriramnagar",
            "Srirampur",
            "Srisailain",
            "Srivaikuntam",
            "Srivardhan",
            "Srivilliputhur",
            "Suar",
            "Subarnapur",
            "Suchindram",
            "Sujangarh",
            "Suket",
            "Sultanpur",
            "Sultanpur",
            "Sultanpur",
            "Sultanpur Lodhi",
            "Sulur",
            "Suluru",
            "Sulya",
            "Sumbal",
            "Sunam",
            "Sundargarh",
            "Sundarnagar",
            "Sunel",
            "Suntikoppa",
            "Supaul",
            "Supedi",
            "Surajgarh",
            "Surandai",
            "Surat",
            "Suratgarh",
            "Surendranagar",
            "Surgana",
            "Surguja",
            "Suri",
            "Surianwan",
            "Suriapet",
            "Susner",
            "Sutrapada",
            "Swamimalai",
            "Swarupnagar community development block",
            "Tadepalle",
            "Tadepallegudem",
            "Tadpatri",
            "Tajpur",
            "Takdah",
            "Takhatgarh",
            "Takhatpur",
            "Taki",
            "Taklibhan",
            "Tal",
            "Talaja",
            "Talbahat",
            "Talbid",
            "Talcher",
            "Talegaon Dabhade",
            "Talegaon Dhamdhere",
            "Taleigao",
            "Talen",
            "Talgram",
            "Talikota",
            "Talipparamba",
            "Taloda",
            "Talode",
            "Talwandi Bhai",
            "Talwara",
            "Tambaram",
            "Tamenglong",
            "Tamluk",
            "Tanakpur",
            "Tanda",
            "Tandur",
            "Tanjore",
            "Tankara",
            "Tanuku",
            "Taoru",
            "Tapi",
            "Tarabha",
            "Tarakeswar",
            "Taramangalam",
            "Tarana",
            "Taranagar",
            "Tarapur",
            "Tardeo",
            "Tarikere",
            "Tarn Taran Sahib",
            "Tasgaon",
            "Tattayyangarpettai",
            "Tawang",
            "Teghra",
            "Tehri",
            "Tehri-Garhwal",
            "Tekanpur",
            "Tekari",
            "Tekkalakote",
            "Tekkali",
            "Telhara",
            "Tellicherry",
            "Tendukheda",
            "Tengnoupal",
            "Teonthar",
            "Terdal",
            "Terha",
            "Tezpur",
            "Tezu",
            "Thakurdwara",
            "Thakurganj",
            "Thalner",
            "Than",
            "Thana Bhawan",
            "Thandla",
            "Thane",
            "Thanesar",
            "Thang",
            "Thangadh",
            "Thanjavur",
            "Thanna Mandi",
            "Thanniyam",
            "Tharad",
            "Tharangambadi",
            "Thasra",
            "The Dangs",
            "Theni",
            "Thenkasi",
            "Thenzawl",
            "Theog",
            "Thirukattupalli",
            "Thiruthani",
            "Thiruvaiyaru",
            "Thiruvallur",
            "Thiruvananthapuram",
            "Thiruvarur",
            "Thiruvidaimaruthur",
            "Thoothukudi",
            "Thoubal",
            "Thrissur",
            "Tigri",
            "Tijara",
            "Tikaitnagar",
            "Tikamgarh",
            "Tikri",
            "Tilhar",
            "Tilsahri",
            "Tindivanam",
            "Tindwari",
            "Tinnanur",
            "Tinsukia",
            "Tiptur",
            "Tira Sujanpur",
            "Tirap",
            "Tirodi",
            "Tirthahalli",
            "Tiruchchendur",
            "Tiruchengode",
            "Tiruchirappalli",
            "Tirukkoyilur",
            "Tirumakudal Narsipur",
            "Tirumala",
            "Tirumullaivasal",
            "Tirunelveli",
            "Tirunelveli Kattabo",
            "Tirupati",
            "Tiruppalaikudi",
            "Tirupparangunram",
            "Tiruppur",
            "Tiruppuvanam",
            "Tirur",
            "Tiruttangal",
            "Tiruvalla",
            "Tiruvannamalai",
            "Tiruvottiyur",
            "Tisaiyanvilai",
            "Titabar",
            "Titagarh",
            "Titlagarh",
            "Titron",
            "Todabhim",
            "Todaraisingh",
            "Tohana",
            "Tondi",
            "Tonk",
            "Topchanchi",
            "Tori Fatehpur",
            "Tori-Fatehpur",
            "Tosham",
            "Tral",
            "Trimbak",
            "Trombay",
            "Tsrar Sharif",
            "Tuensang",
            "Tuensang District",
            "Tufanganj",
            "Tulin",
            "Tuljapur",
            "Tulsipur",
            "Tumakuru",
            "Tumsar",
            "Tundla",
            "Tuni",
            "Tura",
            "Turaiyur",
            "Turuvekere",
            "Uchalan",
            "Udaipur",
            "Udaipur",
            "Udaipura",
            "Udalguri",
            "Udangudi",
            "Udayagiri",
            "Udgir",
            "Udham Singh Nagar",
            "Udhampur",
            "Udpura",
            "Udumalaippettai",
            "Udupi",
            "Ugu",
            "Ujhani",
            "Ujjain",
            "Ukhrul",
            "Uklana",
            "Ukwa",
            "Ula",
            "Ulhasnagar",
            "Ullal",
            "Uluberia",
            "Umarga",
            "Umaria",
            "Umarkhed",
            "Umarkot",
            "Umarpada",
            "Umrala",
            "Umred",
            "Umreth",
            "Umri",
            "Umri",
            "Un",
            "Un",
            "Una",
            "Una",
            "Unakoti",
            "Unhel",
            "Uniara",
            "Unjha",
            "Unnao",
            "Upleta",
            "Uppal Kalan",
            "Upper Siang",
            "Upper Subansiri",
            "Uppiliyapuram",
            "Uran",
            "Uravakonda",
            "Uri",
            "Uruli Kanchan",
            "Usawan",
            "Usehat",
            "Usilampatti",
            "Uska",
            "Utran",
            "Utraula",
            "Uttamapalaiyam",
            "Uttar Bastar Kanker",
            "Uttar Dinajpur district",
            "Uttar Kannada",
            "Uttarkashi",
            "Uttarpara Kotrung",
            "Uttiramerur",
            "Uttukkuli",
            "V.S.K.Valasai (Dindigul-Dist.)",
            "Vada",
            "Vadakku Valliyur",
            "Vadakku Viravanallur",
            "Vadamadurai",
            "Vadgam",
            "Vadgaon",
            "Vadigenhalli",
            "Vadippatti",
            "vadlamuru",
            "Vadlapudi",
            "Vadnagar",
            "Vadner",
            "Vadodara",
            "Vagator",
            "Vaghodia",
            "Vaghodia INA",
            "Vaijapur",
            "Vaikam",
            "Vairag",
            "Vaishali",
            "Valangaiman",
            "Valavanur",
            "Vallabh Vidyanagar",
            "Vallabhipur",
            "Vallam",
            "Valparai",
            "Valpoy",
            "Valsad",
            "Valsang",
            "Vanala",
            "Vandalur",
            "Vandavasi",
            "Vangaon",
            "Vaniyambadi",
            "Vansda",
            "Vanthli",
            "Vapi",
            "Varanasi",
            "Varangaon",
            "Varca",
            "Varkala",
            "Vartej",
            "Vasa",
            "Vasavad",
            "Vasco da Gama",
            "Vashi",
            "Vasind",
            "Vaso",
            "Vasudevanallur",
            "Vataman",
            "Vattalkundu",
            "Vatul",
            "Vayalar",
            "Vedaraniyam",
            "Vedasandur",
            "Vejalpur",
            "Velankanni",
            "Velas Maharashtra",
            "Vellanur",
            "Vellore",
            "Velneshwar",
            "Velur",
            "Vemalwada",
            "Vengavasal",
            "Vengurla",
            "Venkatagiri",
            "Vepagunta",
            "Veraval",
            "Vetapalem",
            "Vettaikkaranpudur",
            "Vettavalam",
            "Vettur",
            "Vidisha",
            "Vijapur",
            "Vijayapura",
            "Vijayapuri",
            "Vijayawada",
            "Vijaydurg",
            "Vikarabad",
            "Vikasnagar",
            "Vikhroli",
            "Vikravandi",
            "Vilattikulam",
            "Vile Parle",
            "Villupuram",
            "Vinchhiya",
            "Vinchur",
            "Vindhyachal",
            "Vinukonda",
            "Viraganur",
            "Viramgam",
            "Virar",
            "Virarajendrapet",
            "Virpur",
            "Virudhunagar",
            "Visakhapatnam",
            "Visavadar",
            "Visnagar",
            "Vita Maharashtra",
            "Vite",
            "Vizianagaram",
            "Vizianagaram District",
            "Vriddhachalam",
            "Vrindavan",
            "Vuyyuru",
            "Vyara",
            "Wadala",
            "Wadgaon",
            "Wadhai",
            "Wadhwan",
            "Wadi",
            "Wadner",
            "Wadwani",
            "Waghai",
            "Wagholi",
            "Wai",
            "Wakad",
            "Walajapet",
            "Walgaon",
            "Walki",
            "Wallajahbad",
            "Walterganj",
            "Wani",
            "Wankaner",
            "Wanparti",
            "Warangal",
            "Waraseoni",
            "Wardha",
            "Waris Aliganj",
            "Warora",
            "Warud",
            "Washim",
            "Wayanad",
            "Wazirganj",
            "Wellington",
            "Wer",
            "West Delhi",
            "West District",
            "West Garo Hills",
            "West Godavari",
            "West Jaintia Hills",
            "West Kameng",
            "West Khasi Hills",
            "West Siang",
            "West Tripura",
            "Wokha",
            "Worli",
            "Yadgir",
            "Yamunanagar",
            "Yanam",
            "Yanam",
            "Yanamalakuduru",
            "Yarada",
            "Yaval",
            "Yavatmal",
            "Yelahanka",
            "Yelandur",
            "Yelbarga",
            "Yellandu",
            "Yellapur",
            "Yeola",
            "Yol",
            "Yusufpur",
            "Zafarabad",
            "Zahirabad",
            "Zaidpur",
            "Zamania",
            "Zira",
            "Ziro",
            "Zunheboto"
        ]
    },
    {
        "label": "Indonesia",
        "code": "ID",
        "phone": "62",
        "cities": [
            "Abepura",
            "Adiwerna",
            "Amahai",
            "Ambarawa",
            "Ambarita",
            "Ambon",
            "Amlapura",
            "Amlapura city",
            "Amuntai",
            "Arjawinangun",
            "Astanajapura",
            "Atambua",
            "Babat",
            "Baekrajan",
            "Baki",
            "Balaipungut",
            "Balapulang",
            "Balikpapan",
            "Balung",
            "Banda Aceh",
            "Bandar",
            "Bandar Lampung",
            "Bandung",
            "Bangil",
            "Bangkalan",
            "Banjar",
            "Banjar",
            "Banjar Wangsian",
            "Banjaran",
            "Banjarmasin",
            "Banyumas",
            "Banyuwangi",
            "Barabai",
            "Batam",
            "Batang",
            "Batu",
            "Baturaden",
            "Baturaja",
            "Bedugul",
            "Bejubang Dua",
            "Bekasi",
            "Belawan",
            "Bengkayang",
            "Bengkulu",
            "Berastagi",
            "Besuki",
            "Biak",
            "Bima",
            "Binjai",
            "Bireun",
            "Blitar",
            "Blora",
            "Bogor",
            "Bojonegoro",
            "Bondowoso",
            "Bontang",
            "Boyolali",
            "Boyolangu",
            "Buaran",
            "Buduran",
            "Bukittinggi",
            "Bulakamba",
            "Candi Prambanan",
            "Caringin",
            "Ceper",
            "Cepu",
            "Ciamis",
            "Ciampea",
            "Cibinong",
            "Cicurug",
            "Cikampek",
            "Cikarang",
            "Cikupa",
            "Cileungsir",
            "Cileunyi",
            "Cimahi",
            "Ciputat",
            "Ciranjang-hilir",
            "Cirebon",
            "Citeureup",
            "City of Balikpapan",
            "Colomadu",
            "Comal",
            "Curug",
            "Curup",
            "Dampit",
            "Delanggu",
            "Deli Tua",
            "Demak",
            "Denpasar",
            "Depok",
            "Diwek",
            "Dompu",
            "Driyorejo",
            "Dukuhturi",
            "Dumai",
            "East Halmahera Regency",
            "Ende",
            "Galesong",
            "Gambiran Satu",
            "Gampengrejo",
            "Gatak",
            "Gebog",
            "Gedangan",
            "Genteng",
            "Gili Air",
            "Gombong",
            "Gongdanglegi Kulon",
            "Gorontalo",
            "Gresik",
            "Gresik Regency",
            "Grogol",
            "Gunung Kendil",
            "Gunungsitoli",
            "Indramayu",
            "Insrom",
            "Jambi City",
            "Jaten",
            "Jatibarang",
            "Jatiroto",
            "Jatiwangi",
            "Jayapura",
            "Jekulo",
            "Jember",
            "Jimbaran",
            "Jogonalan",
            "Jombang",
            "Juwana",
            "Kabanjahe",
            "Kabupaten Aceh Barat",
            "Kabupaten Aceh Barat Daya",
            "Kabupaten Aceh Besar",
            "Kabupaten Aceh Jaya",
            "Kabupaten Aceh Selatan",
            "Kabupaten Aceh Singkil",
            "Kabupaten Aceh Tamiang",
            "Kabupaten Aceh Tengah",
            "Kabupaten Aceh Tenggara",
            "Kabupaten Aceh Timur",
            "Kabupaten Aceh Utara",
            "Kabupaten Agam",
            "Kabupaten Alor",
            "Kabupaten Asahan",
            "Kabupaten Asmat",
            "Kabupaten Badung",
            "Kabupaten Balangan",
            "Kabupaten Bandung",
            "Kabupaten Bandung Barat",
            "Kabupaten Banggai",
            "Kabupaten Banggai Kepulauan",
            "Kabupaten Banggai Laut",
            "Kabupaten Bangka",
            "Kabupaten Bangka Barat",
            "Kabupaten Bangka Selatan",
            "Kabupaten Bangka Tengah",
            "Kabupaten Bangkalan",
            "Kabupaten Bangli",
            "Kabupaten Banjar",
            "Kabupaten Banjarnegara",
            "Kabupaten Bantaeng",
            "Kabupaten Bantul",
            "Kabupaten Banyumas",
            "Kabupaten Banyuwangi",
            "Kabupaten Barito Kuala",
            "Kabupaten Barito Selatan",
            "Kabupaten Barito Timur",
            "Kabupaten Barito Utara",
            "Kabupaten Barru",
            "Kabupaten Batang",
            "Kabupaten Batang Hari",
            "Kabupaten Batu Bara",
            "Kabupaten Bekasi",
            "Kabupaten Belitung",
            "Kabupaten Belitung Timur",
            "Kabupaten Belu",
            "Kabupaten Bener Meriah",
            "Kabupaten Bengkalis",
            "Kabupaten Bengkulu Selatan",
            "Kabupaten Bengkulu Tengah",
            "Kabupaten Bengkulu Utara",
            "Kabupaten Berau",
            "Kabupaten Biak Numfor",
            "Kabupaten Bima",
            "Kabupaten Bintan",
            "Kabupaten Bireuen",
            "Kabupaten Blitar",
            "Kabupaten Blora",
            "Kabupaten Boalemo",
            "Kabupaten Bogor",
            "Kabupaten Bojonegoro",
            "Kabupaten Bolaang Mongondow",
            "Kabupaten Bolaang Mongondow Selatan",
            "Kabupaten Bolaang Mongondow Timur",
            "Kabupaten Bolaang Mongondow Utara",
            "Kabupaten Bombana",
            "Kabupaten Bondowoso",
            "Kabupaten Bone",
            "Kabupaten Bone Bolango",
            "Kabupaten Boven Digoel",
            "Kabupaten Boyolali",
            "Kabupaten Brebes",
            "Kabupaten Buleleng",
            "Kabupaten Bulukumba",
            "Kabupaten Bulungan",
            "Kabupaten Bungo",
            "Kabupaten Buol",
            "Kabupaten Buru",
            "Kabupaten Buru Selatan",
            "Kabupaten Buton",
            "Kabupaten Buton Selatan",
            "Kabupaten Buton Tengah",
            "Kabupaten Buton Utara",
            "Kabupaten Ciamis",
            "Kabupaten Cianjur",
            "Kabupaten Cilacap",
            "Kabupaten Cirebon",
            "Kabupaten Dairi",
            "Kabupaten Deiyai",
            "Kabupaten Deli Serdang",
            "Kabupaten Demak",
            "Kabupaten Dharmasraya",
            "Kabupaten Dogiyai",
            "Kabupaten Dompu",
            "Kabupaten Donggala",
            "Kabupaten Empat Lawang",
            "Kabupaten Ende",
            "Kabupaten Enrekang",
            "Kabupaten Fakfak",
            "Kabupaten Flores Timur",
            "Kabupaten Garut",
            "Kabupaten Gayo Lues",
            "Kabupaten Gianyar",
            "Kabupaten Gorontalo",
            "Kabupaten Gorontalo Utara",
            "Kabupaten Gowa",
            "Kabupaten Grobogan",
            "Kabupaten Gunung Kidul",
            "Kabupaten Gunung Mas",
            "Kabupaten Halmahera Barat",
            "Kabupaten Halmahera Selatan",
            "Kabupaten Halmahera Tengah",
            "Kabupaten Halmahera Utara",
            "Kabupaten Hulu Sungai Selatan",
            "Kabupaten Hulu Sungai Tengah",
            "Kabupaten Hulu Sungai Utara",
            "Kabupaten Humbang Hasundutan",
            "Kabupaten Indragiri Hilir",
            "Kabupaten Indragiri Hulu",
            "Kabupaten Indramayu",
            "Kabupaten Intan Jaya",
            "Kabupaten Jayapura",
            "Kabupaten Jayawijaya",
            "Kabupaten Jember",
            "Kabupaten Jembrana",
            "Kabupaten Jeneponto",
            "Kabupaten Jepara",
            "Kabupaten Jombang",
            "Kabupaten Kaimana",
            "Kabupaten Kampar",
            "Kabupaten Kapuas",
            "Kabupaten Karang Asem",
            "Kabupaten Karanganyar",
            "Kabupaten Karawang",
            "Kabupaten Karimun",
            "Kabupaten Karo",
            "Kabupaten Katingan",
            "Kabupaten Kaur",
            "Kabupaten Kebumen",
            "Kabupaten Kediri",
            "Kabupaten Keerom",
            "Kabupaten Kendal",
            "Kabupaten Kepahiang",
            "Kabupaten Kepulauan Anambas",
            "Kabupaten Kepulauan Aru",
            "Kabupaten Kepulauan Mentawai",
            "Kabupaten Kepulauan Meranti",
            "Kabupaten Kepulauan Sangihe",
            "Kabupaten Kepulauan Sula",
            "Kabupaten Kepulauan Talaud",
            "Kabupaten Kepulauan Yapen",
            "Kabupaten Kerinci",
            "Kabupaten Klaten",
            "Kabupaten Klungkung",
            "Kabupaten Kolaka",
            "Kabupaten Kolaka Timur",
            "Kabupaten Kolaka Utara",
            "Kabupaten Konawe",
            "Kabupaten Konawe Kepulauan",
            "Kabupaten Konawe Selatan",
            "Kabupaten Konawe Utara",
            "Kabupaten Kota Baru",
            "Kabupaten Kotawaringin Barat",
            "Kabupaten Kotawaringin Timur",
            "Kabupaten Kuantan Singingi",
            "Kabupaten Kudus",
            "Kabupaten Kulon Progo",
            "Kabupaten Kuningan",
            "Kabupaten Kupang",
            "Kabupaten Kutai Barat",
            "Kabupaten Kutai Kartanegara",
            "Kabupaten Kutai Timur",
            "Kabupaten Labuhan Batu",
            "Kabupaten Labuhan Batu Selatan",
            "Kabupaten Labuhan Batu Utara",
            "Kabupaten Lamandau",
            "Kabupaten Lamongan",
            "Kabupaten Lampung Barat",
            "Kabupaten Lampung Selatan",
            "Kabupaten Lampung Tengah",
            "Kabupaten Lampung Timur",
            "Kabupaten Lampung Utara",
            "Kabupaten Langkat",
            "Kabupaten Lanny Jaya",
            "Kabupaten Lebak",
            "Kabupaten Lebong",
            "Kabupaten Lembata",
            "Kabupaten Lima Puluh Kota",
            "Kabupaten Lingga",
            "Kabupaten Lombok Barat",
            "Kabupaten Lombok Tengah",
            "Kabupaten Lombok Timur",
            "Kabupaten Lombok Utara",
            "Kabupaten Lumajang",
            "Kabupaten Luwu",
            "Kabupaten Luwu Timur",
            "Kabupaten Luwu Utara",
            "Kabupaten Madiun",
            "Kabupaten Magelang",
            "Kabupaten Magetan",
            "Kabupaten Mahakam Hulu",
            "Kabupaten Majalengka",
            "Kabupaten Majene",
            "Kabupaten Malaka",
            "Kabupaten Malang",
            "Kabupaten Malinau",
            "Kabupaten Maluku Barat Daya",
            "Kabupaten Maluku Tengah",
            "Kabupaten Maluku Tenggara",
            "Kabupaten Maluku Tenggara Barat",
            "Kabupaten Mamasa",
            "Kabupaten Mamberamo Raya",
            "Kabupaten Mamberamo Tengah",
            "Kabupaten Mamuju",
            "Kabupaten Mamuju Tengah",
            "Kabupaten Mamuju Utara",
            "Kabupaten Mandailing Natal",
            "Kabupaten Manggarai",
            "Kabupaten Manggarai Barat",
            "Kabupaten Manggarai Timur",
            "Kabupaten Manokwari",
            "Kabupaten Manokwari Selatan",
            "Kabupaten Mappi",
            "Kabupaten Maros",
            "Kabupaten Maybrat",
            "Kabupaten Merangin",
            "Kabupaten Merauke",
            "Kabupaten Mesuji",
            "Kabupaten Mimika",
            "Kabupaten Minahasa",
            "Kabupaten Minahasa Selatan",
            "Kabupaten Minahasa Tenggara",
            "Kabupaten Minahasa Utara",
            "Kabupaten Mojokerto",
            "Kabupaten Morowali Utara",
            "Kabupaten Muara Enim",
            "Kabupaten Muaro Jambi",
            "Kabupaten Mukomuko",
            "Kabupaten Muna",
            "Kabupaten Muna Barat",
            "Kabupaten Murung Raya",
            "Kabupaten Musi Banyuasin",
            "Kabupaten Musi Rawas",
            "Kabupaten Musi Rawas Utara",
            "Kabupaten Nabire",
            "Kabupaten Nagan Raya",
            "Kabupaten Nagekeo",
            "Kabupaten Natuna",
            "Kabupaten Nduga",
            "Kabupaten Ngada",
            "Kabupaten Nganjuk",
            "Kabupaten Ngawi",
            "Kabupaten Nias",
            "Kabupaten Nias Barat",
            "Kabupaten Nias Utara",
            "Kabupaten Nunukan",
            "Kabupaten Ogan Ilir",
            "Kabupaten Ogan Komering Ilir",
            "Kabupaten Ogan Komering Ulu",
            "Kabupaten Ogan Komering Ulu Selatan",
            "Kabupaten Ogan Komering Ulu Timur",
            "Kabupaten Pacitan",
            "Kabupaten Padang Lawas",
            "Kabupaten Padang Lawas Utara",
            "Kabupaten Padang Pariaman",
            "Kabupaten Pakpak Bharat",
            "Kabupaten Pamekasan",
            "Kabupaten Pandeglang",
            "Kabupaten Pangandaran",
            "Kabupaten Pangkajene Dan Kepulauan",
            "Kabupaten Paniai",
            "Kabupaten Parigi Moutong",
            "Kabupaten Pasaman",
            "Kabupaten Pasaman Barat",
            "Kabupaten Paser",
            "Kabupaten Pasuruan",
            "Kabupaten Pati",
            "Kabupaten Pegunungan Bintang",
            "Kabupaten Pekalongan",
            "Kabupaten Pelalawan",
            "Kabupaten Pemalang",
            "Kabupaten Penajam Paser Utara",
            "Kabupaten Penukal Abab Lematang Ilir",
            "Kabupaten Pesawaran",
            "Kabupaten Pesisir Barat",
            "Kabupaten Pesisir Selatan",
            "Kabupaten Pidie",
            "Kabupaten Pinrang",
            "Kabupaten Pohuwato",
            "Kabupaten Polewali Mandar",
            "Kabupaten Ponorogo",
            "Kabupaten Poso",
            "Kabupaten Pringsewu",
            "Kabupaten Probolinggo",
            "Kabupaten Pulang Pisau",
            "Kabupaten Pulau Morotai",
            "Kabupaten Pulau Taliabu",
            "Kabupaten Puncak Jaya",
            "Kabupaten Purbalingga",
            "Kabupaten Purwakarta",
            "Kabupaten Purworejo",
            "Kabupaten Raja Ampat",
            "Kabupaten Rejang Lebong",
            "Kabupaten Rembang",
            "Kabupaten Rokan Hilir",
            "Kabupaten Rokan Hulu",
            "Kabupaten Rote Ndao",
            "Kabupaten Sabu Raijua",
            "Kabupaten Samosir",
            "Kabupaten Sampang",
            "Kabupaten Sarmi",
            "Kabupaten Sarolangun",
            "Kabupaten Seluma",
            "Kabupaten Semarang",
            "Kabupaten Seram Bagian Barat",
            "Kabupaten Seram Bagian Timur",
            "Kabupaten Serang",
            "Kabupaten Serdang Bedagai",
            "Kabupaten Seruyan",
            "Kabupaten Siak",
            "Kabupaten Siau Tagulandang Biaro",
            "Kabupaten Sidenreng Rappang",
            "Kabupaten Sidoarjo",
            "Kabupaten Sigi",
            "Kabupaten Sijunjung",
            "Kabupaten Sikka",
            "Kabupaten Simalungun",
            "Kabupaten Simeulue",
            "Kabupaten Sinjai",
            "Kabupaten Situbondo",
            "Kabupaten Sleman",
            "Kabupaten Solok",
            "Kabupaten Solok Selatan",
            "Kabupaten Soppeng",
            "Kabupaten Sorong",
            "Kabupaten Sorong Selatan",
            "Kabupaten Sragen",
            "Kabupaten Subang",
            "Kabupaten Sukabumi",
            "Kabupaten Sukamara",
            "Kabupaten Sukoharjo",
            "Kabupaten Sumba Barat",
            "Kabupaten Sumba Barat Daya",
            "Kabupaten Sumba Tengah",
            "Kabupaten Sumba Timur",
            "Kabupaten Sumbawa",
            "Kabupaten Sumbawa Barat",
            "Kabupaten Sumedang",
            "Kabupaten Sumenep",
            "Kabupaten Supiori",
            "Kabupaten Tabalong",
            "Kabupaten Tabanan",
            "Kabupaten Takalar",
            "Kabupaten Tambrauw",
            "Kabupaten Tana Tidung",
            "Kabupaten Tana Toraja",
            "Kabupaten Tanah Bumbu",
            "Kabupaten Tanah Datar",
            "Kabupaten Tanah Laut",
            "Kabupaten Tangerang",
            "Kabupaten Tanggamus",
            "Kabupaten Tanjung Jabung Barat",
            "Kabupaten Tanjung Jabung Timur",
            "Kabupaten Tapanuli Selatan",
            "Kabupaten Tapanuli Tengah",
            "Kabupaten Tapanuli Utara",
            "Kabupaten Tapin",
            "Kabupaten Tasikmalaya",
            "Kabupaten Tebo",
            "Kabupaten Tegal",
            "Kabupaten Teluk Bintuni",
            "Kabupaten Teluk Wondama",
            "Kabupaten Temanggung",
            "Kabupaten Timor Tengah Selatan",
            "Kabupaten Timor Tengah Utara",
            "Kabupaten Toli-Toli",
            "Kabupaten Tolikara",
            "Kabupaten Toraja Utara",
            "Kabupaten Trenggalek",
            "Kabupaten Tuban",
            "Kabupaten Tulangbawang",
            "Kabupaten Tulungagung",
            "Kabupaten Wajo",
            "Kabupaten Waropen",
            "Kabupaten Way Kanan",
            "Kabupaten Wonogiri",
            "Kabupaten Wonosobo",
            "Kabupaten Yahukimo",
            "Kabupaten Yalimo",
            "Kalianget",
            "Kamal",
            "Kapuas Hulu",
            "Karangampel",
            "Karanganom",
            "Karangsembung",
            "Kartasura",
            "Katabu",
            "Kawalu",
            "Kayong Utara",
            "Kebomas",
            "Kebonarun",
            "Kediri",
            "Kedungwaru",
            "Kedungwuni",
            "Kefamenanu",
            "Kencong",
            "Kendari",
            "Kepanjen",
            "Kertosono",
            "Ketanggungan",
            "Ketapang",
            "Kijang",
            "Kisaran",
            "Klangenan",
            "Klaten",
            "Klungkung",
            "Komodo",
            "Kota Administrasi Jakarta Barat",
            "Kota Administrasi Jakarta Pusat",
            "Kota Administrasi Jakarta Selatan",
            "Kota Administrasi Jakarta Timur",
            "Kota Administrasi Jakarta Utara",
            "Kota Ambon",
            "Kota Balikpapan",
            "Kota Banda Aceh",
            "Kota Bandar Lampung",
            "Kota Bandung",
            "Kota Banjar",
            "Kota Banjar Baru",
            "Kota Banjarmasin",
            "Kota Batam",
            "Kota Batu",
            "Kota Baubau",
            "Kota Bekasi",
            "Kota Bengkulu",
            "Kota Bima",
            "Kota Binjai",
            "Kota Bitung",
            "Kota Blitar",
            "Kota Bogor",
            "Kota Bontang",
            "Kota Bukittinggi",
            "Kota Cilegon",
            "Kota Cimahi",
            "Kota Cirebon",
            "Kota Denpasar",
            "Kota Depok",
            "Kota Dumai",
            "Kota Gorontalo",
            "Kota Gunungsitoli",
            "Kota Jambi",
            "Kota Jayapura",
            "Kota Kediri",
            "Kota Kendari",
            "Kota Kotamobagu",
            "Kota Kupang",
            "Kota Langsa",
            "Kota Lhokseumawe",
            "Kota Lubuklinggau",
            "Kota Madiun",
            "Kota Magelang",
            "Kota Makassar",
            "Kota Malang",
            "Kota Manado",
            "Kota Mataram",
            "Kota Medan",
            "Kota Metro",
            "Kota Mojokerto",
            "Kota Padang",
            "Kota Padang Panjang",
            "Kota Padangsidimpuan",
            "Kota Pagar Alam",
            "Kota Palangka Raya",
            "Kota Palembang",
            "Kota Palopo",
            "Kota Palu",
            "Kota Pangkal Pinang",
            "Kota Parepare",
            "Kota Pariaman",
            "Kota Pasuruan",
            "Kota Payakumbuh",
            "Kota Pekalongan",
            "Kota Pekanbaru",
            "Kota Pematang Siantar",
            "Kota Prabumulih",
            "Kota Probolinggo",
            "Kota Sabang",
            "Kota Salatiga",
            "Kota Samarinda",
            "Kota Sawah Lunto",
            "Kota Semarang",
            "Kota Serang",
            "Kota Sibolga",
            "Kota Solok",
            "Kota Sorong",
            "Kota Subulussalam",
            "Kota Sukabumi",
            "Kota Sungai Penuh",
            "Kota Surabaya",
            "Kota Surakarta",
            "Kota Tangerang",
            "Kota Tangerang Selatan",
            "Kota Tanjung Balai",
            "Kota Tanjung Pinang",
            "Kota Tasikmalaya",
            "Kota Tebing Tinggi",
            "Kota Tegal",
            "Kota Ternate",
            "Kota Tidore Kepulauan",
            "Kota Tomohon",
            "Kota Tual",
            "Kota Yogyakarta",
            "Kotabumi",
            "Kraksaan",
            "Kresek",
            "Krian",
            "Kroya",
            "Kuala Tungkal",
            "Kualakapuas",
            "Kubu Raya",
            "Kudus",
            "Kuningan",
            "Kupang",
            "Kuta",
            "Kutoarjo",
            "Labuan",
            "Labuan Bajo",
            "Labuan Lombok",
            "Labuhan Deli",
            "Lahat",
            "Lahat Regency",
            "Laikit Laikit II (Dimembe)",
            "Lamongan",
            "Landak",
            "Langsa",
            "Lasem",
            "Lawang",
            "Lebaksiu",
            "Legian",
            "Lembang",
            "Lembar",
            "Lhokseumawe",
            "Loa Janan",
            "Lovina",
            "Lubuklinggau",
            "Lumajang",
            "Luwuk",
            "Madiun",
            "Magelang",
            "Majalengka",
            "Majenang",
            "Majene",
            "Makassar",
            "Malang",
            "Mamuju",
            "Manado",
            "Manggar",
            "Manismata",
            "Manokwari",
            "Margahayukencana",
            "Margasari",
            "Maros",
            "Martapura",
            "Mataram",
            "Maumere",
            "Medan",
            "Melawi",
            "Mempawah",
            "Mendaha",
            "Mertoyudan",
            "Metro",
            "Meulaboh",
            "Mlonggo",
            "Mojoagung",
            "Mojokerto",
            "Morowali Regency",
            "Mranggen",
            "Muncar",
            "Munduk",
            "Muntilan",
            "Muntok",
            "Nabire",
            "Naisano Dua",
            "Negara",
            "Nganjuk",
            "Ngawi",
            "Ngemplak",
            "Ngoro",
            "Ngunut",
            "Nusa Dua",
            "Paciran",
            "Padalarang",
            "Padang",
            "Padangsidempuan",
            "Pagar Alam",
            "Pakisaji",
            "Palangkaraya",
            "Palembang",
            "Palimanan",
            "Palopo",
            "Palu",
            "Pamanukan",
            "Pamekasan",
            "Pameungpeuk",
            "Pamulang",
            "Panarukan",
            "Pandaan",
            "Pandeglang",
            "Pangkalan Brandan",
            "Pangkalanbuun",
            "Pangkalpinang",
            "Panji",
            "Parapat",
            "Pare",
            "Parepare",
            "Pariaman",
            "Parung",
            "Pasarkemis",
            "Paseh",
            "Pasuruan",
            "Pati",
            "Payakumbuh",
            "Pecangaan",
            "Pekalongan",
            "Pekan Bahapal",
            "Pekanbaru",
            "Pelabuhanratu",
            "Pemalang",
            "Pemangkat",
            "Pematangsiantar",
            "Pemenang",
            "Perbaungan",
            "Percut",
            "Plumbon",
            "Polewali",
            "Ponorogo",
            "Pontianak",
            "Poso",
            "Pototano",
            "Prabumulih",
            "Praya",
            "Prigen",
            "Probolinggo",
            "Purbalingga",
            "Purwakarta",
            "Purwodadi",
            "Purwokerto",
            "Rajapolah",
            "Randudongkal",
            "Rangkasbitung",
            "Rantauprapat",
            "Rantepao",
            "Rembangan",
            "Rengasdengklok",
            "Reuleuet",
            "Ruteng",
            "Sabang",
            "Salatiga",
            "Samarinda",
            "Sambas",
            "Sampang",
            "Sampit",
            "Sanggau",
            "Sawangan",
            "Sekadau",
            "Selayar Islands Regency",
            "Selogiri",
            "Semarang",
            "Senggigi",
            "Sengkang",
            "Sepatan",
            "Serang",
            "Seririt",
            "Serpong",
            "Sibolga",
            "Sidareja",
            "Sidoarjo",
            "Sigli",
            "Sijunjung",
            "Simpang",
            "Sinabang",
            "Singaparna",
            "Singaraja",
            "Singkawang",
            "Singkil",
            "Singojuruh",
            "Singosari",
            "Sinjai",
            "Sintang",
            "Situbondo",
            "Slawi",
            "Soe",
            "Sofifi",
            "Sokaraja",
            "Soko",
            "Solok",
            "Soreang",
            "Sorong",
            "South Tangerang",
            "Sragen",
            "Srono",
            "Stabat",
            "Sukabumi",
            "Sumbawa Besar",
            "Sumber",
            "Sumberpucung",
            "Sumedang",
            "Sumedang Utara",
            "Sumenep",
            "Sungai Penuh",
            "Sungailiat",
            "Sunggal",
            "Surabaya",
            "Surakarta",
            "Tabanan",
            "Tangerang",
            "Tanggul",
            "Tanggulangin",
            "Tanjung Pandan",
            "Tanjung Pinang",
            "Tanjung Selor",
            "Tanjungagung",
            "Tanjungbalai",
            "Tanjungtiram",
            "Tarakan",
            "Tarub",
            "Tasikmalaya",
            "Tayu",
            "Tebingtinggi",
            "Tegal",
            "Teluk Nibung",
            "Teluknaga",
            "Terbanggi Besar",
            "Ternate",
            "Tobelo",
            "Tojo Una-Una Regency",
            "Tomohon",
            "Tomok Bolon",
            "Tondano",
            "Tongging",
            "Trenggalek",
            "Trucuk",
            "Tual",
            "Tuban",
            "Tuktuk Sonak",
            "Tulangan Utara",
            "Tulungagung",
            "Ubud",
            "Ungaran",
            "Waingapu",
            "Wakatobi Regency",
            "Wanaraja",
            "Wangon",
            "Watampone",
            "Wedi",
            "Welahan",
            "Weleri",
            "Weru",
            "Wiradesa",
            "Wongsorejo",
            "Wonopringgo",
            "Wonosobo",
            "Yogyakarta"
        ]
    },
    {
        "label": "Iran",
        "code": "IR",
        "phone": "98",
        "cities": [
            "Ab Meshkin",
            "Ab Pakhsh",
            "Abad",
            "Abadan",
            "Abadeh",
            "Abadeh Tashk",
            "Abali",
            "Abarkooh",
            "Abarkuh",
            "Abarlaq-e Sofla",
            "Abbar",
            "Abbasabad",
            "Abdalan",
            "abdanan",
            "Abdol Rahim",
            "Abeshahmad",
            "Abezhdan",
            "Abgarm",
            "Abhar",
            "Abibeiglou",
            "Abou Homeyzeh",
            "Abouzeidabad",
            "Abrisham",
            "Abrumand",
            "Absard",
            "Abshineh",
            "Abu Musa",
            "Abu ol Fathabad",
            "Abumusa ",
            "Abyek",
            "Abyek",
            "Achachi",
            "Adimi",
            "Afus",
            "Aghajari",
            "Aghcheh Qayah",
            "Ahar",
            "Ahel",
            "Ahmad-abad-e-Solat",
            "Ahmadabad",
            "Ahmadabad-E Mostowfi",
            "Ahmadabad-e Tappeh",
            "Ahmadsargurab",
            "Ahram",
            "Ahu Tappeh",
            "Ahvaz",
            "Ajabshir",
            "Ajin",
            "Ajorlu",
            "Akanlu",
            "Akbarabad",
            "Akleh",
            "Alamarvdasht",
            "Alan-e Sofla",
            "Alanjeh",
            "Alasht",
            "Alavijeh",
            "Alborz",
            "Aleshtar",
            "Alfavut",
            "Aliabad-e Posht Shahr",
            "Aliabad-e-Katul",
            "Aligudarz",
            "Alishahr",
            "Aluni",
            "Alvan",
            "Alvand",
            "Alvand",
            "Aminshahr ",
            "Amir Kala",
            "Amirabad-e Kord",
            "Amiriyeh",
            "Amlash",
            "Amol",
            "Amzajerd",
            "Anabad",
            "Anar",
            "Anarak",
            "Anarestan",
            "Anbar Olum",
            "Anbarabad",
            "Anbaran",
            "Andika",
            "Andimeshk",
            "Andisheh",
            "Andouhjerd",
            "Angoshteh",
            "Anjir Baghi",
            "Ansar ol Emam",
            "Anuch",
            "Aq Bolagh-e Aqdaq",
            "Aq Bolagh-e Latgah",
            "Aq Bolagh-e Morshed",
            "Aq Chay",
            "Aq Qala",
            "Aq Qayeh",
            "Aqa Bolaghi",
            "Aqa Jan Bolaghi",
            "Aqaj",
            "Aqcheh Kharabeh",
            "Aqchelu",
            "Aqda",
            "Aqkend",
            "Aradan",
            "Arak",
            "Aran va Bidgol",
            "Arateh",
            "Ardabil",
            "Ardagh",
            "Ardakan",
            "Ardal",
            "Ardestan",
            "Arges-e Sofla",
            "Arian Shahr",
            "Arikan",
            "Arjmand",
            "Arkavaz",
            "Armaqankhaneh",
            "Armardeh",
            "Arpa Darreh",
            "Arsanjan",
            "Arsenjan",
            "Arteh Bolagh",
            "Artiman",
            "Arvand Kenar",
            "Arzan Fud",
            "Arzuvaj",
            "Asadabad",
            "Asadieh",
            "Asalem",
            "Asaluyeh",
            "Asara",
            "Aseman Abad",
            "Asghar Abad",
            "Ashkanan",
            "Ashkezar",
            "Ashkhaneh",
            "Ashmizan",
            "Ashtian",
            "Asir",
            "Askaran",
            "Aslan Duz",
            "Astaneh",
            "Astaneh-ye Ashrafiyeh",
            "astara",
            "Ava",
            "Avaj",
            "Avajiq",
            "Avarzaman",
            "Aveh",
            "Ayask",
            "Azadi",
            "Azadshahr",
            "Azandarian",
            "Azarshahr",
            "azeh Abad",
            "Azna",
            "Aznahri",
            "Baba Khanjar",
            "Baba Monir",
            "Baba Pir",
            "Baba Qasem",
            "Babaheydar",
            "BabAnar",
            "Babarashani",
            "Babol",
            "Babolqani",
            "Babolsar",
            "Bad Khvoreh",
            "Badreh",
            "Badroud",
            "Baduleh",
            "Bafgh",
            "Bafq",
            "Bafrān",
            "Bafruiyeh",
            "Baft",
            "Bagh Malek",
            "Baghbahadoran",
            "Baghershahr",
            "Baghestan",
            "Bagheyn",
            "Baghmalek",
            "Baghshad",
            "Bahabad",
            "Bahar",
            "Baharan Shahr",
            "Baharestan",
            "Baharestan",
            "Bahmai",
            "Bahman",
            "Bahnamir",
            "Bahreman",
            "Bajestan",
            "Bajgiran",
            "Bakharz",
            "Bakhshāyesh",
            "Baladeh ",
            "Baladeh",
            "Balavah Tareh",
            "Balvard",
            "Bam",
            "Bampour",
            "Banak",
            "Banaruyeh",
            "Band Aman",
            "Bandar Abbas",
            "Bandar Anzali",
            "Bandar Bushehr",
            "Bandar Charak",
            "Bandar Deylam",
            "Bandar Ganaveh",
            "Bandar Imam Khomeini",
            "Bandar Khamir",
            "Bandar Kong",
            "Bandar Lengeh",
            "Bandar Mahshahr",
            "Bandar Rig",
            "Bandar Torkaman",
            "Bandar-e Gaz",
            "Bandar-e Kangan",
            "Bandar-e-Jask",
            "BandarSiraf",
            "Baneh",
            "Banesareh",
            "Banevreh",
            "Bar",
            "Baraband",
            "Baravat",
            "Bardaskan",
            "Bardeh Rasheh",
            "Bardestan",
            "Bardsir",
            "Barehsar",
            "Barf Anbar",
            "Bargecheh",
            "Barreh Farakh",
            "Baruq",
            "Barzok",
            "Barzul",
            "Bashagard",
            "Bashqurtaran",
            "Basht",
            "Basmenj",
            "Bastak",
            "Bastam",
            "Bavanat",
            "Bavi",
            "Bayangan",
            "Bayg",
            "Bayranshahr",
            "Bazar Jomeh",
            "Bazargan",
            "Bazeran",
            "Bazmān",
            "Bazoft",
            "Bedreh",
            "Behabad",
            "Behbahan",
            "Behkandan",
            "Behshahr",
            "Belartu",
            "Ben",
            "Benab e Marand",
            "Bent",
            "Beshik Tappeh",
            "Beydokht",
            "Beyram",
            "Beyza",
            "Bezanjan",
            "Biaj",
            "Biatan-e Sofla",
            "Bid Korpeh-ye Sofla",
            "Bid Korpeh-ye Vosta",
            "Bidestan",
            "Bidrubeh-ye",
            "Bifanaj",
            "Bighash",
            "Bijar",
            "Bikah",
            "Bileh Savar",
            "Binalud",
            "Birjand",
            "Bish Aghaj",
            "Bisotun",
            "Bitervan",
            "Biyārjomand",
            "Boeen-e-Sofla",
            "Bojnurd",
            "Bolbanabad ",
            "Boldaji",
            "Boluk",
            "Bonab",
            "Bonjār",
            "Borazjan",
            "Bord Khun",
            "Borjaki",
            "Borkhvar",
            "Boroujen",
            "Borujen",
            "Borujerd",
            "Boshkan",
            "Boshrouyeh",
            "Boshruyeh",
            "Bostan",
            "Bostan Abad",
            "Bostanabad",
            "Boyukabad",
            "Bozchalu",
            "Bozineh Jerd",
            "Buin va Miandasht",
            "Buin Zahra",
            "Bujin",
            "Bukan",
            "Bumehen",
            "Bushehr",
            "Bustandar",
            "Buyaqchi",
            "Chabahar",
            "Chaboksar",
            "Chadegan",
            "Chaf and Chamkhaleh",
            "Chahar Bolagh",
            "Chahar Dangeh",
            "Chakaneh",
            "Chal Boqa",
            "Chalan Chulan",
            "Chaldoran",
            "Chaleh Kand",
            "Chalus",
            "Chamestan",
            "Chamgolak",
            "Chamgordan",
            "Chamran Town",
            "Chapeshlu",
            "Chaqabol",
            "Charam",
            "Charavimaq",
            "Charbagh",
            "Charburj",
            "Chardavol County",
            "Charoq",
            "Chasht Khvoreh",
            "Chatroud",
            "Chavarzagh",
            "Chavibdeh",
            "Chaypareh",
            "Chelgard",
            "Chelgerd",
            "Chenaran",
            "Chenaran Shahr",
            "Chenari",
            "Chermahin",
            "Cheshmeh Malek",
            "Cheshmeh Pahn-e Nanaj",
            "Cheshmeh Qandab",
            "Cheshmeh Qareh",
            "Cheshmeh Qassaban",
            "Cheshmeh Valad",
            "Cheshmeh Zowraq",
            "Chitab",
            "Chnare",
            "Choghadak",
            "Choghamish",
            "Choliche",
            "Choobar",
            "Choqa Sarahi",
            "Choram",
            "Chovar",
            "Chowtash",
            "Chulak Qapanuri",
            "City Kazeroon",
            "Dabiran",
            "Dabudasht",
            "Dahnejerd",
            "Dalahu",
            "Dalaki",
            "Daland",
            "Dalgan",
            "Dali Chu",
            "Damaneh",
            "Damaq",
            "Damavand",
            "Damghan",
            "Dandi",
            "Danesfahan",
            "Daqdaqabad",
            "Dar Qeshlaq",
            "Darab",
            "Daran",
            "Darani-ye Sofla",
            "Daraq",
            "Darb Gonbad",
            "Darb-e Behesht",
            "Darcham",
            "Dargahan",
            "Dargaz",
            "Dariun",
            "Darjazin",
            "Darkhovin",
            "Darmian",
            "Darreh Chenar",
            "Darreh Mianeh-ye Sofla",
            "Darreh Mirza",
            "Darreh Shahr",
            "Darreh-ye Ebrahim",
            "Darrod",
            "Darsibeh",
            "Dashtak",
            "Dashtestan",
            "Dashti",
            "Dashti",
            "Dashtkar",
            "Dastena",
            "Dastgerd",
            "Dastjerd",
            "Davarzan",
            "Davijan",
            "Davoud Abad",
            "Dayyer",
            "Deh Chaneh",
            "Deh Dalian",
            "Deh Ful",
            "Deh Mianeh",
            "Deh Nush",
            "Deh Piaz",
            "Deh-e Shaker",
            "Dehagh",
            "Dehaghan",
            "Dehaj",
            "Dehaqan",
            "Dehbārez",
            "Dehdasht",
            "Dehdez",
            "Dehgolan",
            "Dehloran",
            "Dehnow-e Avarzaman",
            "Dehram",
            "Delbaran",
            "Delfan",
            "Delgosha",
            "Delijan",
            "Delvar",
            "Deyhuk",
            "Deylam",
            "Deylaman",
            "Dezej",
            "Dezful",
            "Dezhkord",
            "Dibaj",
            "Dingeleh Kahriz",
            "Dishmok",
            "Divandarreh",
            "Dizaj Diz",
            "Diziche",
            "Do Rudan",
            "DoBorji",
            "Dogonbadan",
            "Dolatabad",
            "Dorahak",
            "Doraneh",
            "Dorcheh",
            "Dorcheh Piaz",
            "Dorud",
            "Dorudgaran",
            "Douzeh",
            "Dow Baraleh",
            "Dow Sari",
            "Dowlat Abad",
            "Dowlatabad",
            "Dowlatabad-e Hajjilu",
            "Dowlujerdin-e Bala",
            "Durijan",
            "Durnian",
            "Dust Mohammad",
            "Duzduzan",
            "Efzar",
            "Eij",
            "Ekhteyarabad",
            "Elhayi",
            "Emad Deh",
            "Emam Shahr",
            "Emamzade Abdollah",
            "Emamzadeh Khatun",
            "Emamzadeh Pir Nahan",
            "Eqbaliyeh",
            "Eqbaliyeh",
            "Eqlid",
            "Eresk",
            "Esfahan",
            "Esfarayen",
            "Esfarvarin",
            "Esfedan",
            "Eshqabad",
            "Eshqabad",
            "Eshtehard",
            "Eskanan",
            "Eslāmābād",
            "Eslamabad-e-Gharb",
            "Eslamiyeh",
            "Eslamshahr",
            "Espakeh",
            "Estahban",
            "Evaz",
            "Evogli",
            "Eyvan",
            "Eyvanekey",
            "Ezgeleh",
            "Ezhieh",
            "Fadami ",
            "Fahraj",
            "Fahrumand",
            "Fakhrabad",
            "Falavarjan",
            "Famast",
            "Famenin",
            "Fanouj",
            "Fanuj",
            "Faradonbeh",
            "Farahan",
            "Faraqi",
            "Farasfaj",
            "Farashband",
            "Fardis",
            "Fareghan",
            "Fareydunshahr",
            "Farhadgerd",
            "Farim",
            "Fariman",
            "Farmahin",
            "Farooj",
            "Farrokh Shahr",
            "Farrokhi",
            "Farrokhshahr",
            "Farsan",
            "Farsban",
            "Faruj",
            "Farvaz",
            "Faryab",
            "Faryazan",
            "Fasa",
            "Fasham",
            "Fasijan",
            "FatholMobin",
            "Fazelabad",
            "Ferdosiye",
            "Ferdows",
            "Fereydan",
            "Fereydun Shahr",
            "Fereydunkenar",
            "Ferunabad",
            "Feyz Abad",
            "Fiazaman",
            "Fin",
            "Firouz Abad",
            "Firouzeh",
            "Firuraq",
            "Firuzabad",
            "Firuzabad-e Sofla",
            "Firuzan",
            "Firuzeh",
            "Firuzkuh",
            "Fooladshahr",
            "Fuman",
            "Gachsaran",
            "Gahru",
            "Gahvareh",
            "Galehdar",
            "Galikesh",
            "Galugah",
            "Gamasa",
            "Gamasb",
            "Gandoman",
            "Ganj Tappeh",
            "Ganjab",
            "Garab",
            "Garab-e Sofla",
            "Garmaab",
            "Garmdareh",
            "Garmeh",
            "Garmsar",
            "Gashani",
            "Gatab",
            "Gav Zaban",
            "Gavkaran",
            "Gazanak",
            "Gazborkhar",
            "Gazik",
            "Genaveh",
            "Gerash",
            "Gerd Kashaneh",
            "Germi",
            "Gerouk",
            "Ghadamgah",
            "Ghahderijan",
            "Ghale Tol",
            "Ghalehganj",
            "Ghamsar",
            "Ghare Bolagh",
            "Gharqabad",
            "Ghayen",
            "Ghazyatan",
            "Ghirokarzin",
            "Ghohestan",
            "Gholam Veys",
            "Ghurchi Bashi",
            "Gilan Gharb",
            "Givaki",
            "Giyan",
            "Gogan",
            "Gol Darreh-ye Anuch",
            "Gol Heydar",
            "Gol Tappeh",
            "Golbāf",
            "Golbahar",
            "Goldasht",
            "Golestan",
            "Golgir",
            "Golmakan",
            "Golmorti",
            "Golparabad",
            "Golpayegan",
            "Golsar",
            "Golshahr",
            "Golshan",
            "Golshir",
            "Golushjerd",
            "Golzar",
            "Gomish Tepe",
            "Gomishan",
            "Gomush Bolagh",
            "Gonabad",
            "Gonbad Chay",
            "Gonbad Kavus",
            "Gonbad-e Kabud",
            "Gonbaki",
            "Googad",
            "Gorgab",
            "Gorgan",
            "Gosht",
            "Gostar",
            "Gotvand",
            "Gouharan",
            "Gowdin",
            "Gozal Abdal",
            "Gug Qash",
            "Gujak",
            "Gujan",
            "Gulvand",
            "Gunestan",
            "Gur Gaz",
            "Gurab Zarmikh",
            "Guriyeh",
            "Gurjiu",
            "Gushalan",
            "Gusheh-ye Kasavand",
            "Haarooni",
            "Habibabad",
            "Hachiroud",
            "Hadishahr",
            "Hafshejan",
            "Haft Cheshmeh",
            "Haftkel",
            "Haji Abad",
            "HajiAbad",
            "Hajiabad",
            "Hajji Kheder",
            "Hajji Maqsud",
            "Hajji Tu",
            "Hajjiabad",
            "Halab",
            "Halashi",
            "Hamadan",
            "Hamashahr",
            "Hamedan",
            "Hamidia",
            "Hamidiyeh",
            "Hamil",
            "Hamilabad",
            "Hamoon",
            "Hamzehlu-ye Sofla",
            "Hanna",
            "Hanza",
            "Hanzeh",
            "Haramabad",
            "Harand",
            "Harat",
            "Harsin",
            "Hasan Kusej",
            "Hasan Teymur",
            "Hasanabad",
            "HasanAbad",
            "Hasanabad Yasukand",
            "Hasanabad-e Emam",
            "Hasanabad-e Qush Bolagh",
            "Hasanabad-e Sheverin",
            "Hashjin",
            "Hasht Bandi",
            "Hashtgerd",
            "Hashtgerd New City",
            "Hashtpar",
            "Hashtrood",
            "Hashtrud",
            "Hassan Abad",
            "Haviq",
            "Hemmatabad",
            "Hendijan",
            "Hendoudar",
            "Heriraz",
            "Heris",
            "Hesami",
            "Hesar-e Garmkhan",
            "Hesar-e Qarah Baghi",
            "Hesar-e Qujeh Baghi",
            "Heydareh-ye Dar-e Emam",
            "Heydareh-ye Posht-e Shahr",
            "Hidaj",
            "Hidouj",
            "Hir",
            "Hirmand",
            "Hizaj",
            "Hojedk",
            "Holvar-e Sofla",
            "Hom-e Khosrow",
            "Homashahr",
            "Horhoreh",
            "Hormuz",
            "Horr",
            "Hoseynabad-e Bahar",
            "Hoseynabad-e Nazem",
            "Hoseynabad-e Shamlu",
            "Hoseyniye",
            "Hosseinabad ",
            "Hoveyzeh",
            "Hudaraj",
            "Hurand",
            "Ijrud",
            "Ilam",
            "Ilkhichi",
            "Imam",
            "Imam Hassan",
            "Imanshahr",
            "Incheboron",
            "Iraneh",
            "Iranshahr",
            "Isfahan",
            "Isti Bolagh",
            "Ivar",
            "IzadKhast",
            "Izadshahr",
            "Izeh",
            "Jafarabad",
            "Jafarie",
            "Jahrom",
            "Jajarm",
            "Jālq",
            "Jam",
            "Jamishlu",
            "Janah",
            "Jandaq",
            "Jangal",
            "Jannat Makan",
            "JannatShahr",
            "Jaria",
            "Jask",
            "Javadabad",
            "Javan Qala",
            "Javanrud",
            "Javar Sajin",
            "Javarseyan",
            "Jayezan",
            "Jebalbarez",
            "Jelin",
            "Jerbanlu",
            "Jighi",
            "Jijan Kuh",
            "Jijian Rud",
            "Jin Taraqayah",
            "Jirindih",
            "Jiroft",
            "Joghatay",
            "Jolfa",
            "Jongiyeh",
            "Josheqaneqali",
            "Joupar",
            "Joveyn",
            "Jowkar",
            "Jowsheqan va Kamu",
            "Jowzam",
            "Jowzdan",
            "Junqan",
            "Juraqan",
            "Juybar",
            "Juyom",
            "Kaboodrahang",
            "Kabudrahang",
            "Kadkan",
            "Kahak",
            "Kahard",
            "Kahkadan",
            "Kahnooj",
            "Kahnuj",
            "Kahriz",
            "Kahriz-e Baba Hoseyn",
            "Kahriz-e Boqazi",
            "Kahriz-e Hoseynabad-e Nazem",
            "Kahriz-e Jamal",
            "Kahriz-e Salim",
            "Kahrizak",
            "Kahrizsang",
            "Kaj",
            "Kakhk",
            "Kaki",
            "Kal Geh-ye Sardasht",
            "Kal Kabud",
            "Kalaleh",
            "Kalameh",
            "Kalar Dasht",
            "Kalat",
            "kalāte Khij",
            "Kalāteh",
            "Kalb Hesari",
            "Kaleybar",
            "Kalilabad",
            "Kalleh Bast",
            "Kamak-e Sofla",
            "Kamalshahr",
            "Kamar Boneh",
            "Kamazan",
            "Kamfiruz",
            "Kamyaran",
            "Kand Halan",
            "Kand Tappeh",
            "Kand-e Bolaghi",
            "Kangan",
            "Kangavar",
            "Kangavar-e Kohneh",
            "Kani Dinar",
            "Kani Sur",
            "Karafs",
            "Karaj",
            "Karasf",
            "Karchan",
            "Kariz",
            "Kark-e Sofla",
            "Karkevand",
            "Kartilabad",
            "Karun",
            "Karzin",
            "Kasavand",
            "Kashan",
            "Kashmar",
            "Kaslan Qayah",
            "Kavanaj",
            "Kavar",
            "Kazem Abad",
            "Kazerun",
            "Kelachay",
            "Kelarabad",
            "Kelardasht",
            "Kelishad and Soderjaan",
            "Kelishad va Sudarjan",
            "Kelvanaq",
            "Kerend-e Gharb",
            "Kerman",
            "Kermanshah",
            "Kesb",
            "Keshavarz",
            "Ketalem and Sadat Shahr",
            "Keykaleh",
            "Keyni Sayeh",
            "Khabar Arkhi",
            "Khaf",
            "Khaje",
            "Khajoo Shahr",
            "Khakali",
            "Khaku",
            "Khaled Abad",
            "Khaleq Verdi",
            "Khalifan",
            "Khalil Abad",
            "Khalil Kord",
            "Khalil Shahr",
            "Khalilabad",
            "Khalkhal",
            "Khamaneh",
            "Khamir",
            "Khānbebin",
            "KhaneZenian",
            "Khaniman",
            "Khanook",
            "Khansar",
            "Kharajoo",
            "Kharameh",
            "Kharg",
            "Khark",
            "Kharv",
            "Kharvana",
            "Khash",
            "Khatam",
            "KhatonAbad",
            "Khavaran",
            "Khavas Kuh",
            "Khenejin",
            "Kheradmand",
            "Kherameh",
            "Khesht",
            "Kheyr Qoli",
            "Khezrabad",
            "Khezri Dashtebayaz",
            "Khoda Afarin",
            "Khodabandeh",
            "Khomam",
            "Khomarlu",
            "Khomein",
            "Khomeygan",
            "Khomeyn",
            "Khomeyni Shahr",
            "Khondab",
            "Khonj",
            "Khoome Zar",
            "Khoozi",
            "Khormoj",
            "Khorramabad",
            "Khorramabad",
            "Khorrambid",
            "Khorramdarreh",
            "Khorramdasht",
            "Khorramshahr",
            "Khorusi-ye Jonubi",
            "Khorzugh",
            "Khoshkebijar",
            "Khoshkrud",
            "Khoshroud Pey",
            "Khosrowshah",
            "Khour",
            "Khowshab",
            "Khowy",
            "Khoy",
            "Khur",
            "Khursand",
            "Khusf",
            "Khvaf",
            "Khvajeh Hesar",
            "Khvajeh Hoseyni",
            "Khvajeh Kandi",
            "Khvansar",
            "Khvoshab-e Sofla",
            "Kia Kola",
            "Kian Shahr",
            "Kiar",
            "Kiasar",
            "Kiashahr",
            "Kilan",
            "Kish",
            "Kitu",
            "Kivi",
            "Kohanabad",
            "Kohgiluyeh",
            "Kohnush",
            "Kojur",
            "Kolanjan",
            "Kolor",
            "Komeh",
            "Komijan",
            "Komshecheh",
            "Konar Torshan",
            "Konarak",
            "Konartakhteh",
            "Kondor",
            "Konjineh",
            "Koohsar",
            "Kooshk",
            "Koozehkonan",
            "Kopen",
            "Kord Bolagh",
            "Kordkuy",
            "Korei",
            "Korzan",
            "Koshkonar",
            "Koshksaray",
            "Koshkuiyeh",
            "Kouhenjan",
            "Kouhestak",
            "Kouhin",
            "Koushkak",
            "Kowsar",
            "Kowzareh",
            "Kuchesfahan",
            "Kuh Sefid",
            "Kuhani",
            "Kuhbanan",
            "Kuhdasht",
            "Kuhi Khil",
            "Kuhpayeh",
            "Kuhrang",
            "Kumeleh",
            "Kunani",
            "Kur Kahriz",
            "Kuraim",
            "Kusaj Khalil",
            "Kut Abdollah",
            "Kut-e Seyyed Naim",
            "Kutah Darreh",
            "Kuzaran",
            "Lahijan",
            "Lahroud",
            "Laleh Dan",
            "Laleh zaar",
            "Lalejin",
            "Lali",
            "Lamerd",
            "Lamiyan",
            "Landeh",
            "Langarud",
            "Lapouyee",
            "Lar",
            "Larestan",
            "Lasht-e Nesha",
            "Latgah",
            "Latifi",
            "Lavandvil",
            "Lavasan",
            "Lavashan",
            "Laybid",
            "Leilan",
            "Lemazan",
            "Lendeh",
            "Lenjan",
            "Leyli Yadegar",
            "Likak",
            "Lisar",
            "Lordegan",
            "Lotfabad",
            "Louleman",
            "Loumar",
            "Lowshan",
            "Lujali",
            "Luluhar",
            "Madavan",
            "Mah Velat",
            "Mahabad",
            "Mahabad",
            "Mahalat",
            "Mahallat",
            "Mahan",
            "Mahanabad",
            "Mahbar",
            "Mahdasht",
            "Mahdaviyeh",
            "Mahdi Shahr",
            "Mahdishahr",
            "Mahmood Abad nemooneh",
            "Mahmoodabad",
            "Mahmudabad",
            "Mahneshan",
            "Mahnian",
            "Mahriz",
            "Majlesi",
            "Maklavan",
            "Maku",
            "Mal-e Khalifeh",
            "Malard",
            "Malayer",
            "Malek-e Ashtar",
            "Malekan",
            "Malekshahi",
            "Malusan",
            "Mamaqan",
            "Mamasani",
            "Mamulan",
            "Mamuniyeh",
            "Maneh va Samalqan",
            "Mangavi",
            "Manjil",
            "Manoojan",
            "Mansur-e Aqai",
            "Mansuriyeh",
            "Manujan",
            "Manzariyeh",
            "Maragheh",
            "Marand",
            "Maraveh",
            "Maraveh Tappeh",
            "Mardehak",
            "Marganlar",
            "Margoon",
            "Marivan",
            "Marjaghal",
            "Marvast",
            "Marvdasht",
            "Marvil",
            "Maryanaj",
            "Marzanabad",
            "Marzikola",
            "Masal",
            "Mashhad",
            "Mashhad Rizeh",
            "Mashkat",
            "Masiri",
            "Masjed Soleyman",
            "Masjedin",
            "Masuleh",
            "Mayamey",
            "Maymeh",
            "Mazayejan",
            "Mazdavand",
            "Mazhin",
            "Mazraeh",
            "Mehr",
            "Mehraban",
            "Mehran",
            "Mehrdasht",
            "Mehrestan",
            "Mehriz",
            "Menj",
            "Menjan",
            "Meshgin Shahr",
            "Meshkan",
            "Mesinak",
            "Meybod",
            "Meymand",
            "Meymeh",
            "Meyvaleh",
            "Miandoab",
            "Miandorud",
            "Mianeh",
            "Mianrood",
            "Mianzulan",
            "Midavod",
            "Mihamleh-ye Sofla",
            "Milab",
            "Milajerd",
            "Minab",
            "Minudasht",
            "Minushahr",
            "Mirabad",
            "Mirjaveh",
            "Mirza Hesari",
            "Mishen",
            "Miyan Rahan",
            "Miyanshahr",
            "Moallem Kelayeh",
            "Mobarak Abad",
            "Mobarakabad",
            "Mobarakeh",
            "Mobarakin",
            "Mohajeran",
            "Mohajeran",
            "Mohamadan",
            "Mohammad Abad",
            "Mohammad Shahr",
            "Mohammad Shahr",
            "Mohammadābād",
            "Mohammadabad",
            "Mohammadabad-e Chulak",
            "Mohammadi",
            "Mohammadiyeh ",
            "Mohammadiyeh-e Sofla",
            "Mohammadyar",
            "Mohara",
            "Mohr",
            "MohyAbad",
            "Mojen",
            "Mokarrabi",
            "Molham Darreh",
            "Molkabad",
            "Molla Bodagh",
            "Mollasani",
            "Momen Abad",
            "Monavvar Tappeh",
            "Moqavemat",
            "Moradlu",
            "Morghabad",
            "Moshrageh",
            "Mousiyan",
            "Mowdaran",
            "Muchesh",
            "Mud",
            "Murmuri ",
            "Musa Bolaghi",
            "Naein",
            "Nafch",
            "Naghan",
            "Naghneh",
            "Nahandar",
            "Nahavand",
            "Nain",
            "Najaf Shahr",
            "Najafabad",
            "Najafiyeh",
            "Nakhl Taghi",
            "Nakilabad",
            "Nakin",
            "Nalous",
            "Namileh",
            "Namin",
            "Nanaj",
            "Naqadeh",
            "Naragh",
            "Narjeh",
            "Narmashir",
            "Nashtarud",
            "Nashtifan",
            "Nasimshahr",
            "Nasirshahr",
            "Nasr Abad",
            "Nasrabad",
            "Natanz",
            "Nazarabad",
            "Nazarkahrizi",
            "Nazok-e Olya",
            "Nazul",
            "Negar",
            "Negar Khatun",
            "Negarabad",
            "Neghab",
            "Neginshahr",
            "Negour",
            "Nehbandan",
            "Nehenjeh",
            "Neka",
            "Nesar",
            "Neshar",
            "Neyriz",
            "Neyshabur",
            "Nezamshahr",
            "Niasar",
            "Nik Abad",
            "Nik Pey",
            "Nikshahr",
            "Nil Shahr",
            "Nimbolook",
            "Nimruz",
            "Nimvar",
            "Nir",
            "Nir",
            "Niyanj",
            "No Bandegan",
            "Nodej",
            "Nodoushan",
            "Nokhandan",
            "Noshin",
            "Nosrat Abad",
            "Nosratabad",
            "Nosratabad-e Behraz",
            "Nosratabad-e Laklak",
            "Noukābād",
            "Nourabad",
            "Nowbaran",
            "Nowdan",
            "Nowdeh Khanduz",
            "Nowdeshah",
            "Nowjein",
            "Nowkandeh",
            "Nowshahr",
            "Nowsud",
            "Nur",
            "Nurabad",
            "Nurabad",
            "Nurabad-e Simineh",
            "Nurbahar",
            "Nushabad",
            "Ojaq",
            "Omidcheh",
            "Omidiyeh",
            "Ommatlar",
            "Orzueeyeh",
            "Orzuiyeh",
            "Oshnavieh",
            "Oshnaviyeh",
            "Oshtoran",
            "Oshtorinan",
            "Oshtorjin",
            "Osku",
            "Otaghvar",
            "Ovraqin",
            "Owrteh Qamish",
            "Owryad",
            "Paein Hoular",
            "Pahleh",
            "Pahnabad",
            "Pakdasht",
            "Panbeh Dar",
            "Parand",
            "Parandak",
            "Pardis",
            "Paresar",
            "Pariz",
            "Parluk",
            "Parsabad",
            "Parsian",
            "Parvaraq",
            "Pasargad",
            "Pataveh",
            "Paveh",
            "Payandeh",
            "Peyhan",
            "Pileh Jin",
            "Pir Anbar",
            "Pir Badam",
            "Pir Bakran",
            "Pir Khodaverdi",
            "Pir Malu",
            "Pir Mishan",
            "Pir Savaran",
            "Pir Taj",
            "Pir Yusof",
            "Piranshahr",
            "PirCity",
            "Piruz",
            "Pish Ghaleh",
            "Pishin",
            "Pishva",
            "Pol Dokhtar",
            "Pol Sefid",
            "Pol-e Dokhtar",
            "Poldasht",
            "Pordanjan",
            "Poshtjin",
            "Pul",
            "Qabanqoli",
            "Qader Khalaj",
            "Qaderabad",
            "Qadimi",
            "Qaem Shahr",
            "Qaemshahr",
            "Qaemyeh",
            "Qaen",
            "Qahavand",
            "Qahderijan",
            "Qahjavarestan",
            "Qajar Ab-e Sofla",
            "Qalandarabad",
            "Qaleh Ganj",
            "Qaleh Qazi",
            "Qaleh Raisi",
            "Qaleh-ye Khvajeh",
            "Qanavat",
            "Qarah Day",
            "Qarah Tegini",
            "Qarahziyaeddin",
            "Qaranqu Darreh",
            "Qarchak",
            "Qareh Aghaj",
            "Qarnabad",
            "Qasabeh",
            "Qasabestan",
            "Qasemabad",
            "Qasemabad-e Laklak",
            "Qasr-e Shirin",
            "Qasr-e-qand",
            "Qater Owlan",
            "Qatruyeh",
            "Qayesh",
            "Qazan Qarah",
            "Qazi",
            "Qazi Mardan",
            "Qazvin",
            "Qeshlaq Baba Rostam",
            "Qeshlaq-e Anuch",
            "Qeshlaq-e Deh Ful",
            "Qeshlaq-e Dehnow",
            "Qeshlaq-e Mohammadi",
            "Qeshlaq-e Najaf",
            "Qeshlaq-e Pust Shuran",
            "Qeshlaq-e Qobad",
            "Qeshlaq-e Shirazi",
            "Qeshlaq-e Sofla",
            "Qeshlaq-e Valiollah",
            "Qeshm",
            "Qeydar",
            "Qeydli Bolagh",
            "Qilab",
            "Qir va Karzin",
            "Qods",
            "Qohurd-e Sofla",
            "Qoli Laleh-ye Sofla",
            "Qolqolabad",
            "Qom",
            "Qorveh",
            "Qorveh-e Darjazin",
            "QotbAbad",
            "Qotur",
            "Quch Tappeh",
            "Quchan",
            "Qushchi",
            "Qushjeh",
            "Qushkhaneh",
            "Rabat",
            "Rabor",
            "Rafsanjan",
            "Rahimabad",
            "Rahmanabad Malayer",
            "Ramhormoz",
            "Ramian",
            "Ramishan",
            "Ramsar",
            "Ramshir",
            "Ranekouh",
            "Rasht",
            "Rashtkhar",
            "Rāsk",
            "Rastguyan",
            "Rasulabad-e Sofla",
            "Ravand-e Vosta",
            "Ravansar",
            "Ravar",
            "Rayegan-e Sofla",
            "Rayen",
            "Raz",
            "Razaj",
            "Razan",
            "Razaviye",
            "Razeqan",
            "Razi",
            "Razini",
            "Razmian",
            "Rehnan",
            "Rey",
            "Reyhan",
            "Reza Baghi",
            "Rezvankadeh",
            "Rezvanshahr",
            "Rezvanshahr",
            "Rezveh",
            "Rigan",
            "Rijab",
            "Rineh",
            "Rivash",
            "Rivasijan",
            "Riz",
            "Robat",
            "Robat Karim",
            "Robat-e Sheverin",
            "Robat-e-Sang",
            "Rod Baneh",
            "Rodian",
            "Rofayyeh",
            "RonizOlya",
            "Roshtkhvar",
            "Rostam",
            "Rostam kola",
            "Rostamabad",
            "Royan",
            "Rud Avar",
            "Rud-e Hasan-e Sofla",
            "Rudab",
            "Rudan",
            "Rudbar",
            "Rudbar",
            "Rudehen",
            "Rudsar",
            "Rumeshkhan County",
            "Ruydar",
            "SaadatShahr",
            "Sabashahr",
            "Sabzevar",
            "Sadabad",
            "Sadra",
            "Safa Riz",
            "Safadasht",
            "Safaiyeh",
            "Safashahr",
            "Safiabad",
            "Safiabad",
            "Sagz Abad",
            "Sahamabad",
            "Sahand",
            "Saheb",
            "Sahneh",
            "Sain Qaleh",
            "Salafchegan",
            "Salami",
            "Sāland",
            "Salas-e Baba Jani",
            "Saleh Abad",
            "Saleh Abad",
            "Salehabad",
            "salehie",
            "Salehshahr",
            "Salilak",
            "Salman Shahr",
            "Salmas",
            "Saluk",
            "Saman",
            "Samen",
            "Samen",
            "Samsami",
            "Sanaj",
            "Sanandaj",
            "Sangan",
            "Sangar",
            "Sangdevin",
            "Sanjuzan",
            "Sankhast",
            "Saqqez",
            "Sar Dasht",
            "Sar Duran",
            "Sarab",
            "Sarab-e Dowreh",
            "Sarab-e Tajar",
            "Sarabbagh",
            "Sarableh",
            "Sarakhs",
            "Saravak",
            "Saravan",
            "Sarayan",
            "Sarayjuq",
            "Sarbaz",
            "Sarbishe",
            "Sarbisheh",
            "Sardarabad",
            "Sardasht",
            "Sardasht Bashagard",
            "Sardroud",
            "Sarein",
            "Sareyn",
            "sarfariyab",
            "Sargaz",
            "Sari",
            "Sarijlu",
            "Sarkan",
            "Sarkhon Kalateh",
            "SarKhun",
            "Saroogh",
            "Sarpol Zahab",
            "Sarvabad",
            "Sarvestan",
            "Satar",
            "Savadkuh",
            "Saveh",
            "Savojbolagh",
            "Saziyan",
            "Sedeh",
            "Sedeh Lenjan",
            "Sefid Khaneh",
            "Sefid Sang",
            "Sefid Shahr",
            "Sefiddasht",
            "Segavi",
            "Seh Qaleh",
            "Sejzi",
            "Selseleh",
            "Semaleh",
            "Semirom",
            "Semnan",
            "Senderk",
            "Sepid Dasht",
            "Sepidan",
            "Serish Abad",
            "Serkan",
            "Serow",
            "Setaq",
            "Seydun",
            "Seyedan",
            "Shabab",
            "Shaban Kareh",
            "Shabestar",
            "Shadegan",
            "Shademaneh",
            "Shadmehr",
            "Shaft",
            "Shahbaz",
            "Shahdad",
            "Shahedieh",
            "Shahedshahr",
            "Shahin Dej",
            "Shahin Shahr",
            "Shahmirzad",
            "Shahr-e-Rey",
            "Shahrabad",
            "Shahrak Mes Sarcheshmeh",
            "Shahrak-e Emam Hasan",
            "Shahrbabak",
            "Shahrekord",
            "Shahreza",
            "Shahrezu",
            "Shahriar",
            "ShahrKian",
            "Shahroud",
            "Shahrud",
            "Shal",
            "Shalamzar ",
            "Shalman",
            "Shamsabad",
            "Shanabad",
            "Shandiz",
            "Shanjur",
            "Shapur Abad",
            "Sharabian",
            "Sharafkhaneh",
            "Sharif Abad",
            "Sharifabad",
            "Sharifabad-e Quzan",
            "Sharifabad-e Tajar",
            "Shatzal",
            "Shavand",
            "Shazand",
            "Shemiranat",
            "Shemshak",
            "Shendabad",
            "Sherafat",
            "Sheshdeh",
            "Sheshtamad",
            "Sheverin",
            "Sheyban",
            "Sheykh Jarrah",
            "Shilandar-e Sofla",
            "Shir Barat",
            "Shiraz",
            "Shirgah",
            "Shirin Su",
            "Shirud",
            "Shirvan",
            "Shonbeh",
            "Shool Abad",
            "Shooshtar",
            "Shoqan",
            "Showt",
            "Shusf",
            "Shush",
            "Shush Ab",
            "Shushtar",
            "Shuyesheh",
            "Siah Cheshmeh",
            "Siah Goleh",
            "Siahkal",
            "SiahMansur",
            "Siahrood",
            "Sib Dar",
            "Sib va Suran",
            "Silvana",
            "Silvar",
            "Simin-e Abaru",
            "Simin-e Zagheh",
            "Siminshahr",
            "Simmineh",
            "Simorgh County",
            "Sin",
            "Siravand",
            "Sirdan",
            "Sirik",
            "Sirjan",
            "Sirkān",
            "Sirvan",
            "Sis",
            "Sisakht",
            "Sistaneh",
            "Soghad",
            "Sohrevard",
            "Sojas",
            "Soleh Bon",
            "Soltanabad",
            "Soltanieh",
            "SoltanShahr",
            "Solulan",
            "Someh Sara",
            "Sonqor",
            "Soomar",
            "Sorkh Kand",
            "Sorkh Roud",
            "Sorkheh",
            "Soufian",
            "Srmast",
            "Sudejan",
            "Sulijeh",
            "Suq",
            "Surak",
            "Suran",
            "Sureshjan",
            "Surmaq",
            "Surtajin",
            "Susangerd",
            "Sutlaq",
            "Suzā",
            "Tabas",
            "Tabas",
            "Tabas Masina",
            "Tabriz",
            "Tafresh",
            "Tafrijan",
            "Taft",
            "Tahunabad",
            "Tajar-e Samen",
            "Tajrish",
            "Takab",
            "Takestan",
            "Takht",
            "Takhtiabad",
            "Taleb abad",
            "Taleh Jerd-e Sofla",
            "Taleqan",
            "Talesh",
            "Talkhooncheh",
            "Tamuzan",
            "Tang Eram",
            "Tangestan",
            "Tankaman",
            "Tappeh Yazdan",
            "Tappeh-ye Dibi",
            "Taqanak",
            "Taraqayah",
            "Tarik Darreh-ye Bala",
            "Tark",
            "Tarkhinabad",
            "Tarom",
            "Tarq Rud",
            "Tas Tappeh",
            "Tasbandi",
            "Tashan",
            "Tasran",
            "Tasuj",
            "Tatar Olia",
            "Tavaleh",
            "Tavaneh",
            "Taveh",
            "Tavilan-e Sofla",
            "Taybad",
            "Tazakand",
            "Taze Shar",
            "Tazehnab-e Sofla",
            "Tazian",
            "Tehran",
            "Tekmeh Dash",
            "Termianak",
            "Teymourlou",
            "Timi Jan",
            "Tiran",
            "Tirour",
            "Titkanlu",
            "Tohid",
            "Tonekabon",
            "Toodeshk",
            "Torbat Heydariyeh",
            "Torbat-e-Jam",
            "Torghabeh",
            "Torkalaki",
            "Torkaman",
            "Tuchaqaz",
            "Tulki Tappeh",
            "Tup Aghaj",
            "Tureh",
            "Turkman Chay",
            "Tusk-e Sofla",
            "Tutal",
            "Tutkabon",
            "Tuyserkan",
            "Uraman Takht",
            "Urmia",
            "Vafr-e Jin",
            "Vahandeh",
            "Vahdatiyeh",
            "Vahidieh",
            "Vahman",
            "Vahnan",
            "Vaighan",
            "Vajargah",
            "Vali Mohammad",
            "Vali Sir Ab",
            "Vanak",
            "Vandarabad",
            "Varamin",
            "Varavi",
            "Varayeneh",
            "Varazaneh",
            "Varchaq",
            "Vardanjan ",
            "Vardavard-e Sofla",
            "Vardavard-e Vosta",
            "Varkaneh",
            "Varnamkhast",
            "Varqestan",
            "Varvazin",
            "Varzaneh",
            "Varzaqan",
            "Varzeqān",
            "Vasaj",
            "Vashur",
            "Vasian",
            "Vasleh",
            "Vazvan",
            "Vesht",
            "Veys",
            "Veysian",
            "Vezendan",
            "Yalfan",
            "Yamchi",
            "Yarom Qayah",
            "Yaromjeh Bagh",
            "Yasuj",
            "Yazd",
            "Yazdan Shahr",
            "Yeganeh",
            "Yekanabad",
            "Yekleh",
            "Yeserlu",
            "Yunes",
            "Yunesi",
            "Yunji",
            "Zabol",
            "Zaboli",
            "Zagheh",
            "Zagheh-ye Anuch",
            "Zagheh-ye Tasbandi",
            "Zaghlijeh",
            "Zahak",
            "Zahedan",
            "Zahedshahr",
            "Zahreh",
            "Zamanabad-e Mohammadabad",
            "Zangeneh",
            "Zangeneh-ye Sofla",
            "Zangiabad",
            "Zanjan",
            "Zapon",
            "Zarābād",
            "Zarach",
            "Zaramin-e Sofla",
            "Zarand",
            "Zarandiyeh",
            "Zargarmahalleh",
            "Zarghan",
            "Zarinshahr",
            "Zarnaq",
            "Zarneh",
            "Zarrin Abad",
            "Zarrin Bagh",
            "Zarrin Dasht",
            "Zarrin Rood",
            "Zarrin Shahr",
            "Zarrineh ",
            "Zavareh",
            "Zaveh",
            "Zavieh",
            "Zayandehrod",
            "Zazeran",
            "Zeh-e Kalut",
            "Zehak",
            "Zeraq",
            "Zeydabad",
            "Ziaabad",
            "Ziabar",
            "Ziar",
            "Ziarat",
            "Zibashahr",
            "Zirab",
            "Zirabiyeh",
            "Zirkuh",
            "Ziyārat Ali",
            "Zohaan",
            "Zonouz",
            "Zurabad",
            "سروستان"
        ]
    },
    {
        "label": "Iraq",
        "code": "IQ",
        "phone": "964",
        "cities": [
            "‘Afak",
            "‘Alī al Gharbī",
            "‘Anah",
            "‘Anat al Qadīmah",
            "‘Aqrah",
            "Abu Ghraib District",
            "Abū Ghurayb",
            "Ad Dīwānīyah",
            "Ad Dujayl",
            "Adamiyah",
            "Al ‘Amādīyah",
            "Al ‘Amārah",
            "Al ‘Azīzīyah",
            "Al Adel",
            "Al Baladiyat",
            "Al Başrah al Qadīmah",
            "Al Fallūjah",
            "Al Fāw",
            "Al Hārithah",
            "Al Ḩayy",
            "Al Ḩillah",
            "Al Hindīyah",
            "Al Jamaa",
            "Al Kūt",
            "Al Mawşil al Jadīdah",
            "Al Miqdādīyah",
            "Al Mishkhāb",
            "Al Musayyib",
            "Al Saadoon Park",
            "Al Salhiah",
            "Al Za'franiya",
            "Al-Hamdaniya",
            "Al-Mejar Al-Kabi District",
            "Al-Ubaidi",
            "Al-Wahda",
            "Ameria",
            "Amil District",
            "Amin",
            "Ar Rumaythah",
            "Ar Ruţbah",
            "Arasat AlHindiya",
            "Arbil",
            "As Samawah",
            "As Sulaymānīyah",
            "Aş Şuwayrah",
            "Ash Shāmīyah",
            "Ash Shaţrah",
            "Ash Shaykhān",
            "At Taifiya",
            "Az Zubayr",
            "Bab Al Moatham",
            "Bab Al Sharqi",
            "Baghdad",
            "Baghdad Al Jadeeda",
            "Baijai",
            "Balad",
            "Baladrūz",
            "Baqubah",
            "Basrah",
            "Bataween",
            "Batifa",
            "Bayaa",
            "Bayjī",
            "Baynjiwayn",
            "Binouk",
            "Bo'aitha",
            "Camp Sarah",
            "Daoudi",
            "Dihok",
            "Dora",
            "Erbil",
            "Ghadeer",
            "Ghazaliya",
            "Gherai'at",
            "Green Zone",
            "Habibiya",
            "Ḩadīthah",
            "Ḩalabjah",
            "Harthiya",
            "Hīt",
            "Hīt District",
            "Hurriya",
            "Imam Qasim",
            "Iskan",
            "Jadriyah",
            "Jamila",
            "Jamjamāl",
            "Jihad",
            "Kadhimiya",
            "Kamaliyah",
            "Karada",
            "Karbala",
            "Khadra",
            "Khāliş",
            "Kifrī",
            "Kirkuk",
            "Koysinceq",
            "Kufa",
            "Mandalī",
            "Mansour",
            "Mashtal",
            "Mosul",
            "Mustansiriya",
            "Nāḩiyat al Fuhūd",
            "Nāḩiyat ash Shināfīyah",
            "Nahiyat Ghammas",
            "Nāḩīyat Saddat al Hindīyah",
            "Najaf",
            "Nasiriyah",
            "Qaḑā’ Kifrī",
            "Qadisiyah",
            "Ramadi",
            "Rāwah",
            "Ruwāndiz",
            "Sadr City",
            "Saidiya",
            "Sāmarrā’",
            "Sha'ab",
            "Shaqlāwah",
            "Sheik Maaruf",
            "Shorjah",
            "Sīnah",
            "Sinjar",
            "Soran",
            "Suleikh",
            "Tall ‘Afar",
            "Tallkayf",
            "Tikrīt",
            "Tozkhurmato",
            "Umm Qaşr",
            "Ur District",
            "Wazireya",
            "Yarmouk",
            "Zaxo",
            "Ziyouna"
        ]
    },
    {
        "label": "Ireland",
        "code": "IE",
        "phone": "353",
        "cities": [
            "Abbeyfeale",
            "Abbeyleix",
            "Adare",
            "Aghada",
            "An Cabhán",
            "An Clár",
            "An Iarmhí",
            "An Longfort",
            "An Mhí",
            "An Muileann gCearr",
            "An Ros",
            "Annacotty",
            "Ardee",
            "Ardnacrusha",
            "Arklow",
            "Artane",
            "Ashbourne",
            "Ashford",
            "Askeaton",
            "Athboy",
            "Athenry",
            "Athgarvan",
            "Athlone",
            "Athy",
            "Aughrim",
            "Bagenalstown",
            "Bailieborough",
            "Balally",
            "Balbriggan",
            "Baldoyle",
            "Ballaghaderreen",
            "Ballina",
            "Ballina",
            "Ballinasloe",
            "Ballinroad",
            "Ballinrobe",
            "Ballinteer",
            "Ballisodare",
            "Ballivor",
            "Ballyboden",
            "Ballybofey",
            "Ballybunnion",
            "Ballyconnell",
            "Ballyfermot",
            "Ballygerry",
            "Ballyhaunis",
            "Ballyjamesduff",
            "Ballylinan",
            "Ballymahon",
            "Ballymote",
            "Ballymun",
            "Ballyragget",
            "Ballyshannon",
            "Balrothery",
            "Baltinglass",
            "Banagher",
            "Bandon",
            "Bantry",
            "Bayside",
            "Bearna",
            "Beaumont",
            "Belmullet",
            "Belturbet",
            "Birr",
            "Blackrock",
            "Blanchardstown",
            "Blarney",
            "Blessington",
            "Bonnybrook",
            "Booterstown",
            "Boyle",
            "Bray",
            "Bunclody",
            "Buncrana",
            "Bundoran",
            "Cabinteely",
            "Cabra",
            "Caherconlish",
            "Cahersiveen",
            "Cahir",
            "Callan",
            "Carlingford",
            "Carlow",
            "Carndonagh",
            "Carnew",
            "Carrick-on-Shannon",
            "Carrick-on-Suir",
            "Carrickmacross",
            "Carrigaline",
            "Carrigtwohill",
            "Cashel",
            "Castlebar",
            "Castlebellingham",
            "Castleblayney",
            "Castlebridge",
            "Castlecomer",
            "Castleconnell",
            "Castledermot",
            "Castleisland",
            "Castleknock",
            "Castlemartyr",
            "Castlepollard",
            "Castlerea",
            "Castletown",
            "Cavan",
            "Celbridge",
            "Chapelizod",
            "Charlesland",
            "Cherry Orchard",
            "Cherryville",
            "Ciarraí",
            "Cill Airne",
            "Clane",
            "Clara",
            "Claregalway",
            "Claremorris",
            "Clifden",
            "Clogherhead",
            "Clonakilty",
            "Clondalkin",
            "Clones",
            "Clonskeagh",
            "Cloyne",
            "Cluain Meala",
            "Cobh",
            "Collooney",
            "Confey",
            "Convoy",
            "Coolock",
            "Cootehill",
            "Cork",
            "Cork City",
            "County Carlow",
            "County Cork",
            "County Donegal",
            "County Galway",
            "County Leitrim",
            "County Monaghan",
            "County Tipperary",
            "Courtown",
            "Croom",
            "Crosshaven",
            "Crossmolina",
            "Crumlin",
            "Daingean",
            "Dalkey",
            "Darndale",
            "Derrinturn",
            "Derry",
            "Derrybeg",
            "Dingle",
            "Dollymount",
            "Donabate",
            "Donaghmede",
            "Donegal",
            "Donnybrook",
            "Donnycarney",
            "Drogheda",
            "Droichead Nua",
            "Dromiskin",
            "Drumcondra",
            "Dublin",
            "Dublin City",
            "Duleek",
            "Dún Laoghaire",
            "Dún Laoghaire-Rathdown",
            "Dunboyne",
            "Dundalk",
            "Dundrum",
            "Dungarvan",
            "Dungloe",
            "Dunleer",
            "Dunlewy",
            "Dunmanway",
            "Dunmore East",
            "Dunshaughlin",
            "Eadestown",
            "Edenderry",
            "Edgeworthstown",
            "Enfield",
            "Ennis",
            "Enniscorthy",
            "Enniskerry",
            "Fairview",
            "Ferbane",
            "Fermoy",
            "Ferns",
            "Fethard",
            "Fingal County",
            "Finglas",
            "Firhouse",
            "Foxford",
            "Foxrock",
            "Gaillimh",
            "Galway City",
            "Glasnevin",
            "Gorey",
            "Gort",
            "Graiguenamanagh",
            "Granard",
            "Greenhills",
            "Greystones",
            "Gweedore",
            "Hartstown",
            "Howth",
            "Inishcrone",
            "Jobstown",
            "Johnstown",
            "Kanturk",
            "Kells",
            "Kenmare",
            "Kentstown",
            "Kilbeggan",
            "Kilcock",
            "Kilcoole",
            "Kilcullen",
            "Kildare",
            "Kilkenny",
            "Kill",
            "Killaloe",
            "Killester",
            "Killorglin",
            "Killumney",
            "Killybegs",
            "Kilmacanoge",
            "Kilmallock",
            "Kilpedder",
            "Kilquade",
            "Kilrush",
            "Kiltamagh",
            "Kingscourt",
            "Kinlough",
            "Kinnegad",
            "Kinsale",
            "Kinsealy-Drinan",
            "Knocklyon",
            "Lanesborough",
            "Laois",
            "Laytown",
            "Leifear",
            "Leixlip",
            "Letterkenny",
            "Listowel",
            "Little Bray",
            "Loch Garman",
            "Longford",
            "Longwood",
            "Loughlinstown",
            "Loughrea",
            "Lú",
            "Lucan",
            "Luimneach",
            "Lusk",
            "Macroom",
            "Malahide",
            "Mallow",
            "Manorhamilton",
            "Marino",
            "Maynooth",
            "Mayo County",
            "Midleton",
            "Millstreet",
            "Milltown",
            "Mitchelstown",
            "Moate",
            "Monaghan",
            "Monasterevin",
            "Monkstown",
            "Mooncoin",
            "Moone",
            "Moroe",
            "Mount Merrion",
            "Mountmellick",
            "Mountrath",
            "Moville",
            "Moycullen",
            "Moyross",
            "Muff",
            "Mullagh",
            "Naas",
            "Navan",
            "Nenagh",
            "Nenagh Bridge",
            "New Ross",
            "Newcastle",
            "Newcastle West",
            "Newmarket on Fergus",
            "Newport",
            "Newtown Cunningham",
            "Newtown Trim",
            "Newtownmountkennedy",
            "Old Kilcullen",
            "Oldbawn",
            "Oldcastle",
            "Oranmore",
            "Oughterard",
            "Palmerstown",
            "Passage West",
            "Piltown",
            "Portarlington",
            "Portlaoise",
            "Portlaw",
            "Portmarnock",
            "Portraine",
            "Portumna",
            "Prosperous",
            "Raheny",
            "Ramelton",
            "Raphoe",
            "Ráth Luirc",
            "Rathangan",
            "Rathcoole",
            "Rathcormac",
            "Rathdowney",
            "Rathdrum",
            "Rathgar",
            "Rathkeale",
            "Rathmines",
            "Rathnew",
            "Rathwire",
            "Ratoath",
            "Rialto",
            "Ringsend",
            "Rochfortbridge",
            "Roscommon",
            "Roscrea",
            "Rosslare",
            "Saggart",
            "Sallins",
            "Sallynoggin",
            "Sandyford",
            "Sandymount",
            "Shankill",
            "Shannon",
            "Sixmilebridge",
            "Skerries",
            "Skibbereen",
            "Slane",
            "Sligo",
            "South Dublin",
            "Stamullin",
            "Stradbally",
            "Strandhill",
            "Sutton",
            "Swinford",
            "Swords",
            "Tallaght",
            "Templemore",
            "Templeogue",
            "Terenure",
            "Termonfeckin",
            "Thomastown",
            "Thurles",
            "Tipperary",
            "Tobercurry",
            "Tower",
            "Trá Mhór",
            "Tralee",
            "Trim",
            "Tuam",
            "Tullamore",
            "Tullow",
            "Tullyallen",
            "Uíbh Fhailí",
            "Valleymount",
            "Virginia",
            "Waterford",
            "Watergrasshill",
            "Westport",
            "Whitegate",
            "Wicklow",
            "Youghal"
        ]
    },
    {
        "label": "Israel",
        "code": "IL",
        "phone": "972",
        "cities": [
            "‘Eilabun",
            "‘En Boqeq",
            "‘Uzeir",
            "Abū Ghaush",
            "Acre",
            "Afula",
            "Arad",
            "Ashdod",
            "Ashkelon",
            "Atlit",
            "Azor",
            "Basmat Ṭab‘ūn",
            "Bat Yam",
            "Beersheba",
            "Beit Jann",
            "Bet Dagan",
            "Bet She’an",
            "Bet Shemesh",
            "Bet Yiẕẖaq",
            "Bīr el Maksūr",
            "Bnei Ayish",
            "Bnei Brak",
            "Bu‘eina",
            "Buqei‘a",
            "Caesarea",
            "Dabbūrīya",
            "Daliyat al Karmel",
            "Deir Ḥannā",
            "Dimona",
            "Eilat",
            "El Fureidīs",
            "El Mazra‘a",
            "Elyakhin",
            "Er Reina",
            "Esh Sheikh Dannūn",
            "Eṭ Ṭaiyiba",
            "Even Yehuda",
            "Gan Yavne",
            "Ganei Tikva",
            "Gedera",
            "Giv'at Shmuel",
            "Givatayim",
            "Hadera",
            "Haifa",
            "Har Adar",
            "Herzliya",
            "Herzliya Pituah",
            "Hod HaSharon",
            "H̱olon",
            "Ḥurfeish",
            "Ibṭīn",
            "Iksāl",
            "Jaffa",
            "Jaljūlya",
            "Jerusalem",
            "Jīsh",
            "Judeida Makr",
            "Kābūl",
            "Kafr Kammā",
            "Kafr Kannā",
            "Kafr Mandā",
            "Kafr Miṣr",
            "Kafr Qāsim",
            "Karmi’el",
            "Kaukab Abū el Hījā",
            "Kefar H̱abad",
            "Kefar Rosh HaNiqra",
            "Kefar Shemaryahu",
            "Kefar Tavor",
            "Kefar Weradim",
            "Kefar Yona",
            "Kfar Saba",
            "Kfar Yasif",
            "Lapid",
            "Lehavim",
            "Lod",
            "maalot Tarshīhā",
            "Maghār",
            "Mazkeret Batya",
            "Metulla",
            "Mevasseret Ẕiyyon",
            "Mi‘ilyā",
            "Midreshet Ben-Gurion",
            "Migdal Ha‘Emeq",
            "Mitzpe Ramon",
            "Modi‘in Makkabbim Re‘ut",
            "Modiin Ilit",
            "Nahariyya",
            "Naḥf",
            "Nazareth",
            "Nefat ‘Akko",
            "Neẖalim",
            "Nein",
            "Nesher",
            "Ness Ziona",
            "Netanya",
            "Netivot",
            "Nirit",
            "Nof Ayalon",
            "Nordiyya",
            "Ofaqim",
            "Or Yehuda",
            "Pardesiyya",
            "Pasuta",
            "Petaẖ Tiqwa",
            "Qalansuwa",
            "Qiryat Ata",
            "Qiryat Bialik",
            "Qiryat Gat",
            "Qiryat Moẕqin",
            "Qiryat Shemona",
            "Qiryat Yam",
            "Ra'anana",
            "Rahat",
            "Ramat Gan",
            "Ramat HaSharon",
            "Ramat Yishay",
            "Ramla",
            "Reẖovot",
            "Rekhasim",
            "Rishon LeẔiyyon",
            "Rosh Ha‘Ayin",
            "Rosh Pinna",
            "Rumat Heib",
            "Safed",
            "Sājūr",
            "Sakhnīn",
            "Sallama",
            "Savyon",
            "Sederot",
            "Shelomi",
            "Shibli",
            "Shoham",
            "Sūlam",
            "Tamra",
            "Tel Aviv",
            "Tel Mond",
            "Tiberias",
            "Timrat",
            "Tirah",
            "Tirat Karmel",
            "Umm el Faḥm",
            "West Jerusalem",
            "Yavné",
            "Yavne’el",
            "Yehud",
            "Yehud-Monosson",
            "Yeroẖam",
            "Ẕur Hadassa",
            "Ẕur Moshe"
        ]
    },
    {
        "label": "Italy",
        "code": "IT",
        "phone": "39",
        "cities": [
            "Abano Terme",
            "Abbadia Cerreto",
            "Abbadia Lariana",
            "Abbadia San Salvatore",
            "Abbasanta",
            "Abbateggio",
            "Abbazia",
            "Abbazia Pisani",
            "Abbiategrasso",
            "Abetone",
            "Abriola",
            "Acate",
            "Accadia",
            "Acceglio",
            "Accettura",
            "Acciano",
            "Acconia",
            "Accumoli",
            "Acerenza",
            "Acerno",
            "Acerra",
            "Aci Bonaccorsi",
            "Aci Castello",
            "Aci Catena",
            "Aci Sant'Antonio",
            "Aci Trezza",
            "Acilia-Castel Fusano-Ostia Antica",
            "Acireale",
            "Acitrezza",
            "Acquafondata",
            "Acquaformosa",
            "Acquafredda",
            "Acqualagna",
            "Acquanegra Cremonese",
            "Acquanegra sul Chiese",
            "Acquapendente",
            "Acquappesa",
            "Acquarica del Capo",
            "Acquaro",
            "Acquasanta Terme",
            "Acquasparta",
            "Acquaviva",
            "Acquaviva Collecroce",
            "Acquaviva d'Isernia",
            "Acquaviva delle Fonti",
            "Acquaviva Picena",
            "Acquaviva Platani",
            "Acquedolci",
            "Acqui Terme",
            "Acri",
            "Acuto",
            "Adelfia",
            "Adrano",
            "Adrara San Martino",
            "Adrara San Rocco",
            "Adria",
            "Adro",
            "Affi",
            "Affile",
            "Afragola",
            "Africo Nuovo",
            "Africo Vecchio",
            "Agazzano",
            "Agerola",
            "Aggius",
            "Agira",
            "Agliana",
            "Agliandroni-Paternella",
            "Agliano",
            "Agliano Terme",
            "Agliè",
            "Aglientu",
            "Agna",
            "Agnadello",
            "Agnana Calabra",
            "Agnone",
            "Agnosine",
            "Agordo",
            "Agosta",
            "Agra",
            "Agrate",
            "Agrate Brianza",
            "Agrate Conturbia",
            "Agrigento",
            "Agropoli",
            "Agugliano",
            "Agugliaro",
            "Aicurzio",
            "Aidomaggiore",
            "Aidone",
            "Aielli",
            "Aiello Calabro",
            "Aiello del Friuli",
            "Aiello del Sabato",
            "Aieta",
            "Ailano",
            "Ailoche",
            "Airali",
            "Airasca",
            "Airola",
            "Airole",
            "Airuno",
            "Aisone",
            "Ala",
            "Alà dei Sardi",
            "Ala di Stura",
            "Alagna",
            "Alagna Valsesia",
            "Alanno",
            "Alano di Piave",
            "Alassio",
            "Alatri",
            "Alba",
            "Alba Adriatica",
            "Albagiara",
            "Albairate",
            "Albanella",
            "Albano di Lucania",
            "Albano Laziale",
            "Albano Sant'Alessandro",
            "Albano Vercellese",
            "Albaredo",
            "Albaredo",
            "Albaredo Arnaboldi",
            "Albaredo d'Adige",
            "Albareto",
            "Albaretto della Torre",
            "Albavilla",
            "Albenga",
            "Albera Ligure",
            "Alberi",
            "Alberobello",
            "Alberona",
            "Albese Con Cassano",
            "Albettone",
            "Albi",
            "Albiano",
            "Albiano d'Ivrea",
            "Albiano Magra",
            "Albiate",
            "Albidona",
            "Albignano",
            "Albignasego",
            "Albinea",
            "Albinia",
            "Albino",
            "Albiolo",
            "Albisola Marina",
            "Albisola Superiore",
            "Albizzate",
            "Albonese",
            "Albosaggia",
            "Albuccione",
            "Albugnano",
            "Albuzzano",
            "Alcamo",
            "Alcara Li Fusi",
            "Aldeno",
            "Aldino",
            "Ales",
            "Alessandria",
            "Alessandria del Carretto",
            "Alessandria della Rocca",
            "Alessano",
            "Alezio",
            "Alfano",
            "Alfedena",
            "Alfianello",
            "Alfiano Natta",
            "Alfonsine",
            "Alghero",
            "Algua",
            "Alì",
            "Alì Terme",
            "Alia",
            "Aliano",
            "Alice Bel Colle",
            "Alice Castello",
            "Alice Superiore",
            "Alife",
            "Alimena",
            "Aliminusa",
            "Allai",
            "Alleghe",
            "Allein",
            "Allerona",
            "Alliste",
            "Allumiere",
            "Almè",
            "Almenno San Bartolomeo",
            "Almenno San Salvatore",
            "Almese-Rivera",
            "Alonte",
            "Alpette",
            "Alpignano",
            "Alpo",
            "Alseno",
            "Alserio",
            "Altamura",
            "Altare",
            "Altavilla Irpina",
            "Altavilla Milicia",
            "Altavilla Monferrato",
            "Altavilla Silentina",
            "Altavilla Vicentina",
            "Altedo",
            "Altidona",
            "Altilia",
            "Altino",
            "Altissimo",
            "Altivole",
            "Alto",
            "Altofonte",
            "Altomonte",
            "Altopascio",
            "Alviano",
            "Alvignano",
            "Alvito",
            "Alzano Lombardo",
            "Alzano Scrivia",
            "Alzate Brianza",
            "Amalfi",
            "Amandola",
            "Amantea",
            "Amaro",
            "Amaroni",
            "Amaseno",
            "Amato",
            "Amatrice",
            "Ambivere",
            "Amblar",
            "Ambra",
            "Ameglia",
            "Amelia",
            "Amendolara",
            "Ameno",
            "Amica",
            "Amodio-Massariola",
            "Amorosi",
            "Ampezzo",
            "Anacapri",
            "Anagni",
            "Ancarano",
            "Anchione",
            "Ancona",
            "Andali",
            "Andalo",
            "Andalo Valtellino",
            "Andezeno",
            "Andora",
            "Andorno Cacciorna",
            "Andrano",
            "Andrate",
            "Andreis",
            "Andreotta",
            "Andretta-Mattinella",
            "Andria",
            "Andriano",
            "Anduins",
            "Anela",
            "Anfo",
            "Angera",
            "Anghiari",
            "Angiari",
            "Angolo Terme",
            "Angri",
            "Angrogna",
            "Anguillara Sabazia",
            "Anguillara Veneta",
            "Anitrella-Chiaiamari",
            "Annicco",
            "Annone di Brianza",
            "Annone Veneto",
            "Annunziata",
            "Anoia Inferiore",
            "Anoia Superiore",
            "Antagnod",
            "Antegnate",
            "Antella",
            "Anterivo",
            "Antessano",
            "Antey-Saint-Andrè",
            "Anticoli Corrado",
            "Antignano",
            "Antillo",
            "Antonimina",
            "Antrodoco",
            "Antronapiana",
            "Anversa degli Abruzzi",
            "Anzano del Parco",
            "Anzano di Puglia",
            "Anzi",
            "Anzio",
            "Anzola d'Ossola",
            "Anzola dell'Emilia",
            "Aosta",
            "Apecchio",
            "Apice Vecchio",
            "Apiro",
            "Apollosa",
            "Appiano Gentile",
            "Appignano",
            "Appignano del Tronto",
            "Aprica",
            "Apricale",
            "Apricena",
            "Aprigliano",
            "Aprilia",
            "Aquara",
            "Aquila di Arroscia",
            "Aquileia",
            "Aquilonia",
            "Aquino",
            "Ara Nova",
            "Aradeo",
            "Aragona",
            "Aramengo",
            "Arba",
            "Arbatax",
            "Arbia",
            "Arbizzano-Santa Maria",
            "Arborea",
            "Arborio",
            "Arbus",
            "Arcade",
            "Arcavacata",
            "Arce",
            "Arcene",
            "Arceto",
            "Arcevia",
            "Archi",
            "Arci-Empolitana",
            "Arcidosso",
            "Arcinazzo Romano",
            "Arcisate",
            "Arco",
            "Arcola",
            "Arcole",
            "Arconate",
            "Arcore",
            "Arcugnano-Torri",
            "Ardara",
            "Ardauli",
            "Ardea",
            "Ardenno",
            "Ardesio",
            "Ardole San Marino",
            "Ardore",
            "Ardore Marina",
            "Area Produttiva",
            "Arena",
            "Arena Po",
            "Arenella",
            "Arenzano",
            "Arese",
            "Arezzo",
            "Argegno",
            "Argelato",
            "Argenta",
            "Argine",
            "Arguello",
            "Argusto",
            "Ari",
            "Ariano",
            "Ariano",
            "Ariano Irpino-Martiri",
            "Ariccia",
            "Arielli",
            "Arienzo",
            "Arignano",
            "Arino",
            "Ariola",
            "Aritzo",
            "Arizzano",
            "Arlate",
            "Arlena di Castro",
            "Arlesega",
            "Arluno",
            "Armeno",
            "Armento",
            "Armetta",
            "Armo",
            "Armungia",
            "Arnad",
            "Arnara",
            "Arnasco",
            "Arnesano",
            "Arola",
            "Arola-Preazzano",
            "Arona",
            "Arosio",
            "Arpaia",
            "Arpaise",
            "Arpino",
            "Arpino",
            "Arquà Petrarca",
            "Arquà Polesine",
            "Arquata del Tronto",
            "Arquata Scrivia",
            "Arre",
            "Arrone",
            "Arsago Seprio",
            "Arsiè",
            "Arsiero",
            "Arsita",
            "Arsoli",
            "Arta Terme",
            "Artegna",
            "Artena",
            "Artogne",
            "Arvier",
            "Arzachena",
            "Arzago d'Adda",
            "Arzana",
            "Arzano",
            "Arzene",
            "Arzergrande",
            "Arzignano",
            "Ascea",
            "Asciano",
            "Ascoli Piceno",
            "Ascoli Satriano",
            "Ascrea",
            "Asiago",
            "Asigliano Veneto",
            "Asigliano Vercellese",
            "Asola",
            "Asolo",
            "Asparetto",
            "Assago",
            "Assemini",
            "Assisi",
            "Asso",
            "Assolo",
            "Assoro",
            "Asti",
            "Asuni",
            "Ateleta",
            "Atella",
            "Atena Lucana",
            "Atessa",
            "Atina",
            "Atina Inferiore",
            "Atrani",
            "Atri",
            "Atripalda",
            "Attigliano",
            "Attimis",
            "Atzara",
            "Auditore",
            "Augusta",
            "Auletta",
            "Aulla",
            "Aurano",
            "Aurelia",
            "Aurigo",
            "Aurisina",
            "Aurogna",
            "Auronzo",
            "Ausonia",
            "Austis",
            "Avane",
            "Avegno",
            "Avelengo",
            "Avella",
            "Avellino",
            "Averara",
            "Aversa",
            "Avetrana",
            "Avezzano",
            "Aviano",
            "Aviano-Castello",
            "Aviatico",
            "Avigliana",
            "Avigliano",
            "Avigliano Umbro",
            "Avio",
            "Avise",
            "Avola",
            "Avolasca",
            "Avvocata",
            "Ayas",
            "Aymavilles",
            "Azeglio",
            "Azzanello",
            "Azzano",
            "Azzano d'Asti",
            "Azzano Decimo",
            "Azzano Mella",
            "Azzano San Paolo",
            "Azzate",
            "Azzio",
            "Azzone",
            "Baceno",
            "Bacoli",
            "Bacu Abis",
            "Badalasco",
            "Badalucco",
            "Badesi",
            "Badia",
            "Badia al Pino",
            "Badia Calavena",
            "Badia Pavese",
            "Badia Polesine",
            "Badia Tedalda",
            "Badile",
            "Badoere",
            "Badolato",
            "Badolato Marina",
            "Bagaladi",
            "Baganzola",
            "Baggiovara",
            "Bagheria",
            "Bagnacavallo",
            "Bagnara Calabra",
            "Bagnara di Romagna",
            "Bagnaria",
            "Bagnaria Arsa",
            "Bagnarola",
            "Bagnarola",
            "Bagnasco",
            "Bagnatica",
            "Bagni di Lucca",
            "Bagni di Tivoli",
            "Bagno",
            "Bagno a Ripoli",
            "Bagno Roselle",
            "Bagnoli",
            "Bagnoli del Trigno",
            "Bagnoli di Sopra",
            "Bagnoli Irpino",
            "Bagnolo",
            "Bagnolo Cremasco",
            "Bagnolo del Salento",
            "Bagnolo di Po",
            "Bagnolo in Piano",
            "Bagnolo Mella",
            "Bagnolo Piemonte",
            "Bagnolo San Vito",
            "Bagnone",
            "Bagnoregio",
            "Bagolino",
            "Baia",
            "Baiano",
            "Bairo",
            "Baiso",
            "Bajardo",
            "Balangero",
            "Baldichieri d'Asti",
            "Baldissero Canavese",
            "Baldissero d'Alba",
            "Baldissero Torinese",
            "Balestrate",
            "Balestrino",
            "Ballabio",
            "Ballao",
            "Ballò",
            "Balme",
            "Balmuccia",
            "Balocco",
            "Balsorano",
            "Balsorano Nuovo",
            "Balvano",
            "Balzo",
            "Balzola",
            "Banari",
            "Bancali",
            "Banchette",
            "Bandito",
            "Bannio",
            "Banzano",
            "Banzi",
            "Baone",
            "Baradili",
            "Baraggia",
            "Baragiano",
            "Baranello",
            "Barano d'Ischia",
            "Baranzate",
            "Barasso",
            "Baratili San Pietro",
            "Baratte",
            "Barba",
            "Barbania",
            "Barbara",
            "Barbarano Romano",
            "Barbarano Vicentino",
            "Barbarasco",
            "Barbaresco",
            "Barbariga",
            "Barbata",
            "Barberi",
            "Barberino di Mugello",
            "Barberino Val d'Elsa",
            "Barbianello",
            "Barbiano",
            "Barbiano - Barbian",
            "Barbisano",
            "Barbona",
            "Barcellona Pozzo di Gotto",
            "Barchi",
            "Barcis",
            "Barco",
            "Bard",
            "Bardello",
            "Bardi",
            "Bardineto",
            "Bardolino",
            "Bardonecchia",
            "Bareggio",
            "Barengo",
            "Baressa",
            "Barete",
            "Barga",
            "Bargagli",
            "Bargano",
            "Barge",
            "Barghe",
            "Bari",
            "Bari Sardo",
            "Bariano",
            "Baricella",
            "Barile",
            "Barisciano",
            "Barlassina",
            "Barletta",
            "Barni",
            "Barolo",
            "Barone",
            "Barone Canavese",
            "Baronissi",
            "Barquedo",
            "Barra",
            "Barrafranca",
            "Barrali",
            "Barrea",
            "Baruchella",
            "Barumini",
            "Barzago",
            "Barzana",
            "Barzanò",
            "Barzio",
            "Basaldella",
            "Basaluzzo",
            "Bascapè",
            "Baschi",
            "Basciano",
            "Baselga di Pinè",
            "Baselice",
            "Basiano",
            "Basicò",
            "Basiglio",
            "Basiliano",
            "Basiliano-Vissandone",
            "Basilicagoiano",
            "Basilicanova",
            "Bassano Bresciano",
            "Bassano del Grappa",
            "Bassano in Teverina",
            "Bassano Romano",
            "Bassiano",
            "Bassignana",
            "Bassone",
            "Bastardo",
            "Bastia",
            "Bastia Mondovì",
            "Bastia umbra",
            "Bastida de' Dossi",
            "Bastida Pancarana",
            "Bastiglia",
            "Battaglia Terme",
            "Battaglione-Bagnara",
            "Battifolle-Ruscello-Poggiola",
            "Battifollo",
            "Battipaglia",
            "Battuda",
            "Baucina",
            "Bauladu",
            "Baunei",
            "Baveno",
            "Bazzano",
            "Beccacivetta-Azzano",
            "Bedero",
            "Bedero Valcuvia",
            "Bedizzole",
            "Bedonia",
            "Bedulita",
            "Bee",
            "Begliano",
            "Beinasco",
            "Beinette",
            "Belcastro",
            "Belfiore",
            "Belforte",
            "Belforte",
            "Belforte del Chienti",
            "Belforte Monferrato",
            "Belgioioso",
            "Belgirate",
            "Bella",
            "Bella Farnia",
            "Bellagio",
            "Bellano",
            "Bellante",
            "Bellante Stazione",
            "Bellaria-Igea Marina",
            "Bellavista",
            "Bellegra",
            "Bellinzago Lombardo",
            "Bellinzago Novarese",
            "Bellizzi",
            "Bellocchi",
            "Bellona",
            "Bellosguardo",
            "Belluno",
            "Bellusco",
            "Belmonte Calabro",
            "Belmonte Castello",
            "Belmonte del Sannio",
            "Belmonte in Sabina",
            "Belmonte Mezzagno",
            "Belmonte Piceno",
            "Belpasso",
            "Belsito",
            "Beltiglio-San Giovanni",
            "Belvedere",
            "Belvedere",
            "Belvedere Langhe",
            "Belvedere Marittimo",
            "Belvedere Ostrense",
            "Belvedere Spinello",
            "Belvedere-Piano Tavola",
            "Belveglio",
            "Belverde",
            "Belvì",
            "Bema",
            "Bene Lario",
            "Bene Vagienna",
            "Benestare",
            "Benetutti",
            "Benevello",
            "Benevento",
            "Benna",
            "Bentivoglio",
            "Berbenno",
            "Berbenno di Valtellina",
            "Berceto",
            "Berchidda",
            "Beregazzo",
            "Beregazzo con Figliaro",
            "Bereguardo",
            "Bergamasco",
            "Bergamo",
            "Bergantino",
            "Bergeggi",
            "Bergolo",
            "Berlingo",
            "Bernalda",
            "Bernareggio",
            "Bernate Ticino",
            "Bernezzo",
            "Berra",
            "Berriat",
            "Bersezio",
            "Bersone",
            "Bertinoro",
            "Bertiolo",
            "Bertipaglia",
            "Bertonico",
            "Berzano di San Pietro",
            "Berzano di Tortona",
            "Berzantina",
            "Berzo",
            "Berzo Inferiore",
            "Berzo San Fermo",
            "Besana in Brianza",
            "Besano",
            "Besate",
            "Besenello",
            "Besenzone",
            "Besnate",
            "Besozzo",
            "Bessude",
            "Bettola",
            "Bettola",
            "Bettola-Zeloforomagno",
            "Bettolle",
            "Bettona",
            "Beura",
            "Bevagna",
            "Beverino",
            "Bevilacqua",
            "Biancade",
            "Biancavilla",
            "Bianchi",
            "Bianco",
            "Biandrate",
            "Biandronno",
            "Bianzano",
            "Bianzè",
            "Bianzone",
            "Biassono",
            "Bibano",
            "Bibbiano",
            "Bibbiena",
            "Bibbona",
            "Bibiana",
            "Bibione",
            "Biccari",
            "Bicinicco",
            "Bidonì",
            "Biella",
            "Bienno",
            "Bieno",
            "Bientina",
            "Bigolino",
            "Binago",
            "Binasco",
            "Binetto",
            "Bioglio-Portula-Andrè",
            "Bionaz",
            "Bione",
            "Birori",
            "Bisaccia",
            "Bisaccia Nuova",
            "Bisacquino",
            "Bisceglie",
            "Bisegna",
            "Bisenti",
            "Bisignano",
            "Bistagno",
            "Bisuschio",
            "Bitetto",
            "Bitonto",
            "Bitritto",
            "Bitti",
            "Bivio di Capanelle",
            "Bivio Mortola",
            "Bivio San Polo",
            "Bivio Santa Cecilia",
            "Bivona",
            "Bivongi",
            "Bizzarone",
            "Bleggio Superiore",
            "Blello",
            "Blera",
            "Blessagno",
            "Blevio",
            "Blufi",
            "Boara Pisani",
            "Bobbio",
            "Bobbio Pellice",
            "Boca",
            "Bocchigliero",
            "Boccioleto",
            "Bocco",
            "Bocenago",
            "Bodio",
            "Bodio Lomnago",
            "Boffalora d'Adda",
            "Boffalora Sopra Ticino",
            "Bogliasco",
            "Bogogno",
            "Boissano",
            "Bojano",
            "Bojon-Lova",
            "Bolano",
            "Bolbeno",
            "Bolgare",
            "Bolladello-Peveranza",
            "Bollate",
            "Bollengo",
            "Bologna",
            "Bolognano",
            "Bolognano-Vignole",
            "Bolognetta",
            "Bolognola",
            "Bolotana",
            "Bolsena",
            "Boltiere",
            "Bolzano",
            "Bolzano Novarese",
            "Bolzano Vicentino",
            "Bomarzo",
            "Bomba",
            "Bompensiere",
            "Bompietro",
            "Bomporto",
            "Bonarcado",
            "Bonassola",
            "Bonate Sopra",
            "Bonate Sotto",
            "Bonavicina",
            "Bonavigo",
            "Bondeno",
            "Bondeno",
            "Bondione",
            "Bondo",
            "Bondone",
            "Bonea",
            "Bonefro",
            "Bonemerse",
            "Bonferraro",
            "Bonifati",
            "Bonito",
            "Bonnanaro",
            "Bono",
            "Bonorva",
            "Bonvicino",
            "Bora Bassa",
            "Borbona",
            "Borca",
            "Bordano",
            "Bordighera",
            "Bordolano",
            "Bore",
            "Borello",
            "Boretto",
            "Borgagne",
            "Borgarello",
            "Borgaro Torinese",
            "Borgata Marina",
            "Borgetto",
            "Borghetto",
            "Borghetto d'Arroscia",
            "Borghetto di Borbera",
            "Borghetto di Vara",
            "Borghetto Lodigiano",
            "Borghetto Santo Spirito",
            "Borghetto-Melara",
            "Borghi",
            "Borgia",
            "Borgiallo",
            "Borgio",
            "Borgo",
            "Borgo",
            "Borgo a Buggiano",
            "Borgo a Mozzano",
            "Borgo d'Ale",
            "Borgo di Ranzo",
            "Borgo di Terzo",
            "Borgo Fornari-Pieve",
            "Borgo Grappa",
            "Borgo Hermada",
            "Borgo Lotti",
            "Borgo Massano",
            "Borgo Melano",
            "Borgo Pace",
            "Borgo Podgora",
            "Borgo Priolo",
            "Borgo Sabotino-Foce Verde",
            "Borgo San Dalmazzo",
            "Borgo San Giacomo",
            "Borgo San Giovanni",
            "Borgo San Lorenzo",
            "Borgo San Martino",
            "Borgo San Michele",
            "Borgo San Siro",
            "Borgo Santa Maria",
            "Borgo Santa Maria Immacolata",
            "Borgo Stazione",
            "Borgo Ticino",
            "Borgo Tossignano",
            "Borgo Val di Taro",
            "Borgo Valsugana",
            "Borgo Velino",
            "Borgo Vercelli",
            "Borgoforte",
            "Borgofranco d'Ivrea",
            "Borgofranco sul Po",
            "Borgolavezzaro",
            "Borgomale",
            "Borgomanero",
            "Borgomaro",
            "Borgomasino",
            "Borgone Susa",
            "Borgonovo Valtidone",
            "Borgonuovo",
            "Borgoratto Alessandrino",
            "Borgoratto Mormorolo",
            "Borgoricco-San Michele delle Badesse-Sant'Eufemia",
            "Borgorose",
            "Borgosatollo",
            "Borgosesia",
            "Bormida",
            "Bormida-Genepro",
            "Bormio",
            "Bornasco",
            "Bornato",
            "Borno",
            "Boroneddu",
            "Borore",
            "Borrello",
            "Borriana",
            "Borso del Grappa",
            "Bortigali",
            "Bortigiadas",
            "Bortolot",
            "Borutta",
            "Borzano",
            "Borzonasca",
            "Bosa",
            "Bosaro",
            "Boschetto",
            "Boschi Sant'Anna",
            "Bosco",
            "Bosco Chiesanuova",
            "Bosco Ex Parmigiano",
            "Bosco Marengo",
            "Bosco Mesola",
            "Boscochiaro",
            "Bosconero",
            "Boscoreale",
            "Boscotrecase",
            "Bosentino-Migazzone",
            "Bosia",
            "Bosio",
            "Bosisio Parini",
            "Bosnasco",
            "Bossico",
            "Bossolasco",
            "Botricello",
            "Botrugno",
            "Botta",
            "Bottanuco",
            "Bottega",
            "Botticino",
            "Bottidda",
            "Bottrighe",
            "Bova",
            "Bova Marina",
            "Bovalino",
            "Bovalino Superiore",
            "Bovegno",
            "Boves",
            "Bovezzo",
            "Boville Ernica",
            "Bovino",
            "Bovisio-Masciago",
            "Bovolenta",
            "Bovolone",
            "Bozzole",
            "Bozzolo",
            "Bra",
            "Bracca",
            "Braccagni",
            "Bracciano",
            "Bracigliano",
            "Braglia",
            "Braies",
            "Brancaleone",
            "Brancaleone-Marina",
            "Brandico",
            "Brandizzo",
            "Branzi",
            "Braone",
            "Brebbia",
            "Breda",
            "Bregano",
            "Breganze",
            "Bregnano",
            "Breguzzo",
            "Breia",
            "Brembate",
            "Brembate di Sopra",
            "Brembilla",
            "Brembio",
            "Breme",
            "Brendola",
            "Brenna",
            "Brennero - Brenner",
            "Brenno Useria",
            "Breno",
            "Brenta",
            "Brentino Belluno",
            "Brentonico",
            "Brenzone",
            "Breo",
            "Brescello",
            "Brescia",
            "Bresimo",
            "Bressa",
            "Bressana",
            "Bressanone",
            "Bressanvido",
            "Bresso",
            "Brez",
            "Brezza",
            "Brezzo di Bedero",
            "Briaglia",
            "Briatico",
            "Bricco di Neive",
            "Bricherasio",
            "Brienno",
            "Brienza",
            "Briga Alta",
            "Briga Novarese",
            "Brignano",
            "Brignano Gera d'Adda",
            "Brindisi",
            "Brindisi Montagna",
            "Brinzio",
            "Briona",
            "Brione",
            "Brione",
            "Briosco",
            "Brisighella",
            "Brissago-Valtravaglia",
            "Brittoli",
            "Brivio",
            "Broccostella",
            "Brogliano",
            "Brognaturo",
            "Brognoligo-Costalunga",
            "Brolo",
            "Brondello",
            "Brongio",
            "Broni",
            "Bronte",
            "Bronzolo",
            "Brossasco",
            "Brosso",
            "Brovello",
            "Brovello-Carpugnino",
            "Brozolo",
            "Brozzo",
            "Brucoli",
            "Brugherio",
            "Brugine",
            "Brugnato",
            "Brugnera",
            "Bruino",
            "Brumano",
            "Bruna",
            "Brunate",
            "Brunello",
            "Brunico",
            "Bruno",
            "Brusaporto",
            "Brusasco",
            "Brusciano",
            "Brusimpiano",
            "Brusnengo",
            "Brusson",
            "Bruzolo",
            "Bruzzano Zeffirio",
            "Bubano",
            "Bubbiano",
            "Bubbio",
            "Buccheri",
            "Bucchianico",
            "Bucciano",
            "Buccinasco",
            "Buccino",
            "Bucine",
            "Buddusò",
            "Budoia",
            "Budoni",
            "Budrio",
            "Buffalora-Bettole",
            "Buggerru",
            "Buglio in Monte",
            "Bugnara",
            "Buguggiate",
            "Buia",
            "Bulciago",
            "Bulgarograsso",
            "Bulgorello",
            "Bultei",
            "Bulzi",
            "Buonabitacolo",
            "Buonalbergo",
            "Buonconvento",
            "Buonvicino",
            "Burago di Molgora",
            "Burano",
            "Burcei",
            "Burgio",
            "Burgos",
            "Buriasco",
            "Burolo",
            "Buronzo",
            "Busachi",
            "Busalla",
            "Busana",
            "Busano",
            "Busca",
            "Buscate",
            "Buscemi",
            "Buscoldo",
            "Buseto Palizzolo",
            "Busnago",
            "Buso",
            "Bussero",
            "Busseto",
            "Bussi sul Tirino",
            "Busso",
            "Bussolengo",
            "Bussoleno",
            "Busto Arsizio",
            "Busto Garolfo",
            "Butera",
            "Buti",
            "Buttapietra",
            "Buttigliera Alta",
            "Buttigliera d'Asti",
            "Buttrio",
            "Cà Dè Fabbri",
            "Ca' d'Andrea",
            "Ca' degli Oppi",
            "Ca' di Sola",
            "Ca' Rainati",
            "Ca' Savio",
            "Ca' Tiepolo",
            "Cabella Ligure",
            "Cabiate",
            "Cabras",
            "Caccamo",
            "Caccuri",
            "Cadè-Gaida",
            "Cadegliano-Viconago",
            "Cadelbosco di Sopra",
            "Cadelbosco di Sotto",
            "Cadenabbia",
            "Caderzone Terme",
            "Cadine",
            "Cadipietra",
            "Cadoneghe",
            "Cadorago-Caslino al Piano",
            "Cadrezzate",
            "Caerano di San Marco",
            "Cafasse",
            "Caggiano",
            "Cagli",
            "Cagliari",
            "Caglio",
            "Cagnano Amiterno",
            "Cagnano Varano",
            "Cagno",
            "Cagnò",
            "Caianello",
            "Caiazzo",
            "Caines",
            "Caines - Kuens",
            "Caino",
            "Caiolo",
            "Caira",
            "Cairano",
            "Cairate",
            "Cairo Montenotte",
            "Caivano",
            "Cala Gonone",
            "Calabritto",
            "Calalzo di Cadore",
            "Calamandrana",
            "Calamonaci",
            "Calangianus",
            "Calanna",
            "Calasca-Castiglione",
            "Calascibetta",
            "Calascio",
            "Calasetta",
            "Calatabiano",
            "Calatafimi",
            "Calavino",
            "Calcara",
            "Calcata Nuova",
            "Calceranica al Lago",
            "Calchera-Frontale",
            "Calci",
            "Calciano",
            "Calcinaia",
            "Calcinate",
            "Calcinato",
            "Calcinelli",
            "Calcio",
            "Calco",
            "Caldaro sulla Strada del Vino",
            "Caldarola",
            "Calderara di Reno",
            "Calderino",
            "Caldes",
            "Caldierino-Rota",
            "Caldiero",
            "Caldine",
            "Caldogno-Rettorgole-Cresole",
            "Caldonazzo",
            "Calendasco",
            "Calenzano",
            "Caleppio",
            "Calerno",
            "Calestano",
            "Calice al Cornoviglio",
            "Calice Ligure",
            "Calimera",
            "Calitri",
            "Calizzano",
            "Callabiana - Chiesa",
            "Calliano",
            "Calliano",
            "Calmasino",
            "Calò",
            "Calolziocorte",
            "Caloppezzati",
            "Calosso",
            "Caloveto",
            "Calozzo",
            "Caltabellotta",
            "Caltagirone",
            "Caltana",
            "Caltanissetta",
            "Caltavuturo",
            "Caltignaga",
            "Calto",
            "Caltrano",
            "Calusco d'Adda",
            "Caluso",
            "Calvagese della Riviera",
            "Calvanico",
            "Calvatone",
            "Calvello",
            "Calvene",
            "Calvenzano",
            "Calvera",
            "Calvi",
            "Calvi dell'Umbria",
            "Calvi Risorta",
            "Calvignano",
            "Calvignasco",
            "Calvisano",
            "Calvisi",
            "Calvizzano",
            "Camagna Monferrato",
            "Camaiore",
            "Camairago",
            "Camalò",
            "Camandona",
            "Camastra",
            "Cambiago",
            "Cambiano",
            "Cambiasca",
            "Camburzano",
            "Camerana",
            "Camerano",
            "Camerano",
            "Camerano Casasco",
            "Camerata Cornello",
            "Camerata Nuova",
            "Camerata Picena",
            "Cameri",
            "Camerino",
            "Camerota",
            "Camigliano",
            "Camilleri-Vallelata",
            "Caminata",
            "Camini",
            "Camino",
            "Camino al Tagliamento",
            "Camisano",
            "Camisano Vicentino",
            "Cammarata",
            "Camnago-Boscone",
            "Camo",
            "Camogli",
            "Camoneone",
            "Campagna",
            "Campagna Lupia",
            "Campagnano di Roma",
            "Campagnatico",
            "Campagnola",
            "Campagnola Cremasca",
            "Campagnola Emilia",
            "Campalto",
            "Campana",
            "Campanarello",
            "Campanella-Gianforma",
            "Camparada",
            "Campegine",
            "Campello sul Clitunno",
            "Campertogno",
            "Campese",
            "Campi Bisenzio",
            "Campi Salentina",
            "Campiglia Cervo",
            "Campiglia dei Berici",
            "Campiglia Marittima",
            "Campiglione",
            "Campiglione",
            "Campione",
            "Campitello",
            "Campitello di Fassa",
            "Campli",
            "Campo",
            "Campo Calabro",
            "Campo di Carne",
            "Campo di Giove",
            "Campo di Trens",
            "Campo Jemini",
            "Campo Ligure",
            "Campo Limpido-Favale",
            "Campo nell'Elba",
            "Campo San Martino",
            "Campo Tizzoro",
            "Campo Tures",
            "Campobasso",
            "Campobello di Licata",
            "Campobello di Mazara",
            "Campocavallo",
            "Campochiaro",
            "Campochiesa",
            "Campodarsego",
            "Campodenno",
            "Campodimele",
            "Campodipietra",
            "Campodolcino",
            "Campodoro",
            "Campofelice di Fitalia",
            "Campofelice di Roccella",
            "Campofilone",
            "Campofiorenzo-California",
            "Campofiorito",
            "Campoformido",
            "Campofranco",
            "Campogalliano",
            "Campolattaro",
            "Campoleone",
            "Campoli Appennino",
            "Campoli del Monte Taburno",
            "Campolieto",
            "Campolongo al Torre",
            "Campolongo al Torre-Cavenzano",
            "Campolongo Maggiore Liettoli",
            "Campolongo sul Brenta",
            "Campomaggiore",
            "Campomarino",
            "Campomolino",
            "Campomorone",
            "Camponogara",
            "Campora",
            "Campora San Giovanni",
            "Camporeale",
            "Camporgiano",
            "Camporosso",
            "Camporotondo di Fiastrone",
            "Camporotondo Etneo",
            "Camposampiero",
            "Camposano",
            "Camposanto",
            "Campospinoso",
            "Campotosto",
            "Camucia-Monsigliolo",
            "Camugnano",
            "Canal San Bovo",
            "Canale",
            "Canale d'Agordo",
            "Canale Monterano",
            "Canalicchio",
            "Canaro",
            "Canavaccio",
            "Canazei",
            "Cancellara",
            "Cancello-Arnone",
            "Canda",
            "Candela",
            "Candelo",
            "Candelù",
            "Candia Canavese",
            "Candia Lomellina",
            "Candiana",
            "Candida",
            "Candide",
            "Candidoni",
            "Candiolo",
            "Canegrate",
            "Canelli",
            "Canepina",
            "Caneva",
            "Canicattì",
            "Canicattini Bagni",
            "Caniezza",
            "Canino",
            "Canischio",
            "Canistro Inferiore",
            "Canna",
            "Cannalonga",
            "Cannara",
            "Cannero Riviera",
            "Canneto",
            "Canneto",
            "Canneto Pavese",
            "Canneto sull'Oglio",
            "Cannizzaro-Favara",
            "Cannobio",
            "Cannole",
            "Canolo",
            "Canonica",
            "Canonica d'Adda",
            "Canosa di Puglia",
            "Canosa Sannita",
            "Canosio",
            "Canova-San Zeno",
            "Canove di Roana",
            "Cansano",
            "Cantagallo",
            "Cantagrillo-Casalguidi",
            "Cantalice",
            "Cantalupa",
            "Cantalupo",
            "Cantalupo in Sabina",
            "Cantalupo Ligure",
            "Cantalupo nel Sannio",
            "Cantarana",
            "Cantello",
            "Canterano",
            "Cantiano",
            "Cantinella",
            "Cantoira",
            "Cantù",
            "Canzano",
            "Canzo",
            "Caorle",
            "Caorso",
            "Capaccio",
            "Capaccio Scalo",
            "Capaci",
            "Capalbio",
            "Capanne-Prato-Cinquale",
            "Capannoli",
            "Capannori",
            "Capena",
            "Capergnanica",
            "Capestrano",
            "Capezzano Inferiore",
            "Capezzano-Cologna",
            "Capiago-Intimiano-Olmeda",
            "Capirro",
            "Capistrano",
            "Capistrello",
            "Capitan Loreto",
            "Capitello",
            "Capitignano",
            "Capitignano",
            "Capizzi",
            "Capo d'Orlando",
            "Capo di Ponte",
            "Capo Rizzuto",
            "Capodarco",
            "Capodimonte",
            "Capodrise",
            "Capoliveri",
            "Capolona",
            "Caponago",
            "Caporciano",
            "Caposele",
            "Capoterra",
            "Capovalle",
            "Cappadocia",
            "Cappella Cantone",
            "Cappella de' Picenardi",
            "Cappella Maggiore",
            "Cappelle sul Tavo",
            "Cappelletta",
            "Cappone",
            "Capracotta",
            "Capraia e Limite",
            "Capraia Isola",
            "Capralba",
            "Capranica",
            "Capranica Prenestina",
            "Caprara",
            "Caprarica di Lecce",
            "Caprarola",
            "Caprauna",
            "Caprese Michelangelo",
            "Caprezzo",
            "Capri",
            "Capri Leone",
            "Capriana",
            "Capriano",
            "Capriano del Colle",
            "Capriata d'Orba",
            "Capriate San Gervasio",
            "Capriati A Volturno",
            "Caprie",
            "Capriglia",
            "Capriglia Irpina",
            "Capriglio",
            "Caprino",
            "Caprino Veronese",
            "Capriolo",
            "Capriva del Friuli",
            "Capua",
            "Capurso",
            "Caraffa del Bianco",
            "Caraffa di Catanzaro",
            "Caraglio",
            "Caramagna Piemonte",
            "Caramanico Terme",
            "Carano",
            "Carano",
            "Carapelle",
            "Carapelle Calvisio",
            "Carasco",
            "Carassai",
            "Carate Brianza",
            "Carate Urio",
            "Caravaggio",
            "Caravate",
            "Caravino",
            "Caravonica",
            "Carbognano",
            "Carbonara al Ticino",
            "Carbonara di Nola",
            "Carbonara di Po",
            "Carbonara Scrivia",
            "Carbonate",
            "Carbone",
            "Carbonera",
            "Carbonia",
            "Carcare",
            "Carceri",
            "Carchitti",
            "Carcoforo",
            "Cardano",
            "Cardano al Campo",
            "Cardè",
            "Cardedu",
            "Cardeto",
            "Cardinale",
            "Cardito",
            "Careggine",
            "Carema",
            "Carenno",
            "Carentino",
            "Careri",
            "Caresana",
            "Caresanablot",
            "Carezzano Maggiore",
            "Carfizzi",
            "Cargeghe",
            "Cariati",
            "Carife",
            "Carifi-Torello-Priscoli",
            "Carignano",
            "Carimate",
            "Carinaro",
            "Carini",
            "Carinola",
            "Carisio",
            "Carisolo",
            "Carlantino",
            "Carlazzo",
            "Carlentini",
            "Carlino",
            "Carloforte",
            "Carlopoli",
            "Carmagnola",
            "Carmiano",
            "Carmignano",
            "Carmignano di Brenta",
            "Carnago",
            "Carnate",
            "Carobbio",
            "Carobbio degli Angeli",
            "Carolei",
            "Carona",
            "Caronia",
            "Caronno Pertusella",
            "Caronno Varesino",
            "Carosino",
            "Carovigno",
            "Carovilli",
            "Carpaneto Piacentino",
            "Carpanzano",
            "Carpasio",
            "Carpegna",
            "Carpenedolo",
            "Carpeneto",
            "Carpesica",
            "Carpi Centro",
            "Carpiano",
            "Carpignano Salentino",
            "Carpignano Sesia",
            "Carpineti",
            "Carpineto della Nora",
            "Carpineto Romano",
            "Carpineto Sinello",
            "Carpino",
            "Carpinone",
            "Carraia",
            "Carrara",
            "Carrara San Giorgio",
            "Carrara-Pontenuovo",
            "Carrè",
            "Carrega Ligure",
            "Carro",
            "Carrodano",
            "Carrosio",
            "Carrozziere",
            "Carrù",
            "Carruba",
            "Carrubazza-Motta",
            "Carsoli",
            "Cartiera-Stazione",
            "Cartigliano",
            "Cartignano",
            "Cartoceto",
            "Cartosio",
            "Cartura",
            "Carugate",
            "Carugo",
            "Carunchio",
            "Carvico",
            "Carzago Riviera",
            "Carzano",
            "Casa del Diavolo",
            "Casa Ponte",
            "Casa Santa",
            "Casabona",
            "Casacalenda",
            "Casacanditella",
            "Casacorba",
            "Casagiove",
            "Casal Cermelli",
            "Casal di Principe",
            "Casal Palocco",
            "Casal Velino",
            "Casalanguida",
            "Casalattico",
            "Casalazzara",
            "Casalbeltrame",
            "Casalbordino-Miracoli",
            "Casalbore",
            "Casalborgone",
            "Casalbuono",
            "Casalbuttano",
            "Casalciprano",
            "Casalduni",
            "Casale",
            "Casale",
            "Casale Corte Cerro",
            "Casale Cremasco",
            "Casale di Scodosia",
            "Casale Litta",
            "Casale Marittimo",
            "Casale Monferrato",
            "Casale sul Sile",
            "Casalecchio di Reno",
            "Casaleggio Boiro",
            "Casaleggio Novara",
            "Casaleone",
            "Casaletto Ceredano",
            "Casaletto di Sopra",
            "Casaletto Lodigiano",
            "Casaletto Spartano",
            "Casaletto Vaprio",
            "Casalfiumanese",
            "Casalgrande",
            "Casalgrasso",
            "Casali",
            "Casali-San Potito",
            "Casalincontrada",
            "Casalini",
            "Casalino",
            "Casalmaggiore",
            "Casalmaiocco",
            "Casalmorano",
            "Casalmoro",
            "Casalnoceto",
            "Casalnuovo di Napoli",
            "Casalnuovo Monterotaro",
            "Casaloldo",
            "Casalpusterlengo",
            "Casalromano",
            "Casalserugo",
            "Casaluce",
            "Casalvecchio di Puglia",
            "Casalvecchio Siculo",
            "Casalvieri",
            "Casalvolone",
            "Casalzuigno",
            "Casamarciano",
            "Casamassella",
            "Casamassima",
            "Casamicciola Terme",
            "Casandrino",
            "Casano-Dogana-Isola",
            "Casanova",
            "Casanova Elvo",
            "Casanova Lerrone",
            "Casanova Lonati",
            "Casape",
            "Casapesenna",
            "Casapinta",
            "Casaprota",
            "Casapulla",
            "Casarano",
            "Casarea",
            "Casargo",
            "Casarile",
            "Casarsa della Delizia",
            "Casarza Ligure",
            "Casasco",
            "Casasco Intelvi",
            "Casate",
            "Casatenovo",
            "Casatisma",
            "Casavatore",
            "Casavecchia",
            "Casazza",
            "Cascano",
            "Cascia",
            "Casciago",
            "Casciana Terme",
            "Cascina",
            "Cascina Elisa",
            "Cascinare",
            "Cascine-La Croce",
            "Cascinette d'Ivrea",
            "Casco",
            "Case Campoli-Panetta",
            "Case Nuove",
            "Casei",
            "Caselette",
            "Casella",
            "Caselle",
            "Caselle in Pittari",
            "Caselle Landi",
            "Caselle Lurani",
            "Caselle Torinese",
            "Caseo",
            "Caserta",
            "Casette d'Ete",
            "Casette Verdini",
            "Casier",
            "Casignana",
            "Casina",
            "Casine",
            "Casinina",
            "Casirate d'Adda",
            "Caslino d'Erba",
            "Casnate Con Bernate",
            "Casnigo",
            "Casola",
            "Casola di Napoli",
            "Casola in Lunigiana",
            "Casola Valsenio",
            "Casole Bruzio",
            "Casole d'Elsa",
            "Casoli",
            "Casone",
            "Casoni",
            "Casorate Primo",
            "Casorate Sempione",
            "Casorezzo",
            "Casoria",
            "Casorzo",
            "Casperia",
            "Caspoggio",
            "Cassacco",
            "Cassago Brianza",
            "Cassano Allo Ionio",
            "Cassano d'Adda",
            "Cassano delle Murge",
            "Cassano Irpino",
            "Cassano Magnago",
            "Cassano Spinola",
            "Cassano Valcuvia",
            "Cassaro",
            "Cassibile",
            "Cassiglio",
            "Cassina de' Pecchi",
            "Cassina Rizzardi",
            "Cassina Valsassina",
            "Cassinasco",
            "Cassine",
            "Cassinelle-Concentrico",
            "Cassinetta di Lugagnano",
            "Cassino",
            "Cassino d'Alberi",
            "Cassola",
            "Cassolnovo",
            "Castagnaro",
            "Castagneto Carducci",
            "Castagneto Po",
            "Castagnito",
            "Castagnole",
            "Castagnole delle Lanze",
            "Castagnole Monferrato",
            "Castagnole Piemonte",
            "Castana",
            "Castanea delle Furie",
            "Castano Primo",
            "Casteggio",
            "Castegnato",
            "Castel Baronia",
            "Castel Boglione",
            "Castel Bolognese",
            "Castel Campagnano",
            "Castel Castagna",
            "Castel Chiodato",
            "Castel Condino",
            "Castel d'Aiano",
            "Castel d'Ario",
            "Castel del Giudice",
            "Castel del Monte",
            "Castel del Piano",
            "Castel del Rio",
            "Castel di Casio",
            "Castel di Ieri",
            "Castel di Judica",
            "Castel di Lama",
            "Castel di Lucio",
            "Castel di Sangro",
            "Castel di Sasso",
            "Castel di Tora",
            "Castel Frentano",
            "Castel Fusano",
            "Castel Gabbiano",
            "Castel Gandolfo",
            "Castel Giorgio",
            "Castel Goffredo",
            "Castel Guelfo di Bologna",
            "Castel Madama",
            "Castel Maggiore",
            "Castel Mella",
            "Castel Morrone",
            "Castel Ritaldi",
            "Castel Rocchero",
            "Castel Rozzone",
            "Castel San Giorgio",
            "Castel San Giovanni",
            "Castel San Lorenzo",
            "Castel San Niccolò",
            "Castel San Pietro Romano",
            "Castel San Pietro Terme",
            "Castel San Vincenzo",
            "Castel Sant'Angelo",
            "Castel Sant'Elia",
            "Castel Viscardo",
            "Castel Vittorio",
            "Castel Volturno",
            "Castelbaldo",
            "Castelbelforte",
            "Castelbellino",
            "Castelbello",
            "Castelbello-Ciardes - Kastelbell-Tschars",
            "Castelbianco",
            "Castelbottaccio",
            "Castelbuono",
            "Castelceriolo",
            "Castelcivita",
            "Castelcovati",
            "Castelcucco",
            "Casteldaccia",
            "Casteldelci",
            "Casteldelfino",
            "Casteldidone",
            "Castelfidardo",
            "Castelfiorentino",
            "Castelfondo",
            "Castelforte",
            "Castelfranci",
            "Castelfranco di Sopra",
            "Castelfranco di Sotto",
            "Castelfranco Emilia",
            "Castelfranco in Miscano",
            "Castelfranco Veneto",
            "Castelgomberto",
            "Castelgrande",
            "Castelguglielmo",
            "Castelguidone",
            "Castell'Alfero",
            "Castell'Arquato",
            "Castell'Azzara",
            "Castell'Umberto",
            "Castellafiume",
            "Castellalto",
            "Castellammare del Golfo",
            "Castellammare di Stabia",
            "Castellamonte",
            "Castellana",
            "Castellana Sicula",
            "Castellaneta",
            "Castellania",
            "Castellanza",
            "Castellar",
            "Castellar Guidobono",
            "Castellarano",
            "Castellaro",
            "Castellazzo Bormida",
            "Castellazzo Novarese",
            "Castelleone",
            "Castelleone di Suasa",
            "Castellero",
            "Castelletto",
            "Castelletto",
            "Castelletto Cervo",
            "Castelletto d'Erro",
            "Castelletto d'Orba",
            "Castelletto di Branduzzo",
            "Castelletto Merli",
            "Castelletto Molina",
            "Castelletto Monferrato",
            "Castelletto Po",
            "Castelletto Sopra Ticino",
            "Castelletto Stura",
            "Castelletto Uzzone",
            "Castelli",
            "Castelli Calepio",
            "Castellina in Chianti",
            "Castellina Marittima",
            "Castellina Scalo",
            "Castellinaldo",
            "Castellino del Biferno",
            "Castellino Tanaro",
            "Castelliri",
            "Castello",
            "Castello Cabiaglio",
            "Castello d'Agogna",
            "Castello d'Argile",
            "Castello del Matese",
            "Castello dell'Acqua",
            "Castello di Annone",
            "Castello di Brianza",
            "Castello di Cisterna",
            "Castello di Godego",
            "Castello Molina di Fiemme",
            "Castello Tesino",
            "Castellucchio",
            "Castelluccio dei Sauri",
            "Castelluccio Inferiore",
            "Castelluccio Superiore",
            "Castelluccio Valmaggiore",
            "Castelluzzo",
            "Castelmarte",
            "Castelmassa",
            "Castelmassimo",
            "Castelmauro",
            "Castelmezzano",
            "Castelminio",
            "Castelmola",
            "Castelnovetto",
            "Castelnovo",
            "Castelnovo Bariano",
            "Castelnovo di Sotto",
            "Castelnovo ne'Monti",
            "Castelnuovo",
            "Castelnuovo Belbo",
            "Castelnuovo Berardenga",
            "Castelnuovo Bocca d'Adda",
            "Castelnuovo Bormida",
            "Castelnuovo Bozzente",
            "Castelnuovo Calcea",
            "Castelnuovo Cilento",
            "Castelnuovo dei Sabbioni",
            "Castelnuovo del Garda",
            "Castelnuovo della Daunia",
            "Castelnuovo di Ceva",
            "Castelnuovo di Conza",
            "Castelnuovo di Farfa",
            "Castelnuovo di Garfagnana",
            "Castelnuovo di Porto",
            "Castelnuovo di Val di Cecina",
            "Castelnuovo Don Bosco",
            "Castelnuovo Magra",
            "Castelnuovo Nigra",
            "Castelnuovo Parano",
            "Castelnuovo Rangone",
            "Castelnuovo Scrivia",
            "Castelnuovo Vomano",
            "Castelpagano",
            "Castelpetroso",
            "Castelpizzuto",
            "Castelplanio",
            "Castelpoto",
            "Castelraimondo",
            "Castelrotto",
            "Castelsantangelo sul Nera",
            "Castelsaraceno",
            "Castelsardo",
            "Castelseprio",
            "Castelsilano",
            "Castelspina",
            "Casteltermini",
            "Casteltodino",
            "Castelveccana",
            "Castelvecchio Calvisio",
            "Castelvecchio di Rocca Barbena",
            "Castelvecchio Subequo",
            "Castelvenere",
            "Castelverde",
            "Castelverrino",
            "Castelvetere in Val Fortore",
            "Castelvetere sul Calore",
            "Castelvetrano",
            "Castelvetro di Modena",
            "Castelvetro Piacentino",
            "Castelvisconti",
            "Castenaso",
            "Castenedolo",
            "Castiadas",
            "Castiglion Fibocchi",
            "Castiglion Fiorentino",
            "Castiglione",
            "Castiglione",
            "Castiglione a Casauria",
            "Castiglione Cosentino",
            "Castiglione d'Adda",
            "Castiglione d'Intelvi",
            "Castiglione d'Orcia",
            "Castiglione dei Pepoli",
            "Castiglione del Genovesi",
            "Castiglione del Lago",
            "Castiglione della Pescaia",
            "Castiglione delle Stiviere",
            "Castiglione di Garfagnana",
            "Castiglione di Sicilia",
            "Castiglione Falletto",
            "Castiglione in Teverina",
            "Castiglione Messer Marino",
            "Castiglione Messer Raimondo",
            "Castiglione Olona",
            "Castiglione Tinella",
            "Castiglione Torinese",
            "Castignano",
            "Castilenti",
            "Castino",
            "Castion",
            "Castione",
            "Castione Andevenno",
            "Castione della Presolana",
            "Castions",
            "Castions di Strada",
            "Castiraga Vidardo",
            "Casto",
            "Castorano",
            "Castrezzato",
            "Castri di Lecce",
            "Castrignano De' Greci",
            "Castrignano del Capo",
            "Castro",
            "Castro",
            "Castro dei Volsci",
            "Castrocaro Terme e Terra del Sole",
            "Castrocielo",
            "Castrofilippo",
            "Castrolibero",
            "Castromediano",
            "Castronno",
            "Castronuovo di Sant'Andrea",
            "Castronuovo di Sicilia",
            "Castropignano",
            "Castroreale",
            "Castroregio",
            "Castrovillari",
            "Cataeggio",
            "Catania",
            "Catanzaro",
            "Catena",
            "Catenanuova",
            "Catignano",
            "Cattolica",
            "Cattolica Eraclea",
            "Caulonia",
            "Caulonia Marina",
            "Cautano",
            "Cava d'Aliga",
            "Cava Dè Tirreni",
            "Cava Manara",
            "Cava-Cuculera Nobile",
            "Cavacurta",
            "Cavaglià",
            "Cavaglietto",
            "Cavaglio D'Agogna",
            "Cavaglio-Spoccia",
            "Cavagnolo",
            "Cavajon Veronese",
            "Cavalcaselle",
            "Cavalese",
            "Cavallasca",
            "Cavallerleone",
            "Cavallermaggiore",
            "Cavallina",
            "Cavallino",
            "Cavallino",
            "Cavallirio",
            "Cavareno",
            "Cavargna",
            "Cavaria Con Premezzo",
            "Cavarzere",
            "Cavaso del Tomba",
            "Cavasso Nuovo",
            "Cavatore",
            "Cavazzo Carnico",
            "Cavazzona",
            "Cave",
            "Cavedago",
            "Cavedine",
            "Cavenago d'Adda",
            "Cavenago di Brianza",
            "Cavernago",
            "Cavezzo",
            "Cavino",
            "Cavizzana",
            "Cavolano-Schiavoi",
            "Cavoni-Ginestreto",
            "Cavour",
            "Cavriago",
            "Cavriana",
            "Cavriglia-Monastero",
            "Cazzago Brabbia",
            "Cazzago San Martino-Calino",
            "Cazzago-Ex Polo",
            "Cazzano di Tramigna",
            "Cazzano Sant'Andrea",
            "Ceccano",
            "Cecchina",
            "Cecchini",
            "Cecima",
            "Cecina",
            "Cedegolo",
            "Cedessano",
            "Cedrasco",
            "Cefalà Diana",
            "Cefalù",
            "Ceggia",
            "Ceglie Messapica",
            "Celano",
            "Celat-San Tomaso Agordino",
            "Celenza sul Trigno",
            "Celenza Valfortore",
            "Celico",
            "Cella Dati",
            "Cella Monte",
            "Cellamare",
            "Cellara",
            "Cellarengo",
            "Cellatica",
            "Celle di Bulgheria",
            "Celle di San Vito",
            "Celle Enomondo",
            "Celle Ligure",
            "Celleno",
            "Cellere",
            "Cellino Attanasio",
            "Cellino San Marco",
            "Cellio",
            "Cellole",
            "Cellore",
            "Cembra",
            "Cenadi",
            "Cenaia",
            "Cenate di Sotto",
            "Cenate Sopra",
            "Cencenighe Agordino",
            "Cendon",
            "Cene",
            "Ceneselli",
            "Cengio Alto",
            "Centa San Nicolò",
            "Centallo",
            "Centinarola",
            "Cento",
            "Centobuchi",
            "Centola",
            "Centrache",
            "Centrale",
            "Centrale",
            "Centro Urbano",
            "Centuripe",
            "Cepagatti",
            "Ceparana-Carpena",
            "Cepina",
            "Ceppaloni",
            "Ceppo Morelli",
            "Ceprano",
            "Cerami",
            "Ceramida-Pellegrina",
            "Ceranesi",
            "Cerano",
            "Cerano d'Intelvi",
            "Ceranova",
            "Ceraso",
            "Cerasolo",
            "Cerbaia",
            "Cerbara",
            "Cercemaggiore",
            "Cercenasco",
            "Cercepiccola",
            "Cerchiara di Calabria",
            "Cerchio",
            "Cercino",
            "Cercivento",
            "Cercola",
            "Cerda",
            "Cerea",
            "Cereda-Cozza Cornedo",
            "Ceregnano",
            "Cerello-Battuello",
            "Cerenzia",
            "Ceres",
            "Ceresane-Curanuova",
            "Ceresara",
            "Cerese",
            "Cereseto",
            "Ceresole Alba",
            "Ceresole Reale",
            "Cerete Alto",
            "Ceretto Lomellina",
            "Cerfignano",
            "Cergnago",
            "Ceriale",
            "Ceriana",
            "Ceriano Laghetto",
            "Cerignale",
            "Cerignola",
            "Cerisano",
            "Cerlongo",
            "Cermenate",
            "Cermes",
            "Cermignano",
            "Cernobbio",
            "Cernusco Lombardone",
            "Cernusco sul Naviglio",
            "Cerquotti-Madonna del Piano",
            "Cerratina",
            "Cerreto Castello",
            "Cerreto d'Asti",
            "Cerreto d'Esi",
            "Cerreto di Spoleto",
            "Cerreto Grue",
            "Cerreto Guidi",
            "Cerreto Langhe",
            "Cerreto Laziale",
            "Cerreto Sannita",
            "Cerrina",
            "Cerrione",
            "Cerro al Lambro",
            "Cerro al Volturno",
            "Cerro Maggiore",
            "Cerro Tanaro",
            "Cerro Veronese",
            "Cersosimo",
            "Certaldo",
            "Certosa",
            "Certosa di Pavia",
            "Cerva",
            "Cervara di Roma",
            "Cervarese Santa Croce",
            "Cervaro",
            "Cervasca",
            "Cervatto",
            "Cerveno",
            "Cervere",
            "Cervesina",
            "Cerveteri",
            "Cervia",
            "Cervicati",
            "Cervignano d'Adda",
            "Cervignano del Friuli",
            "Cervinara",
            "Cervino",
            "Cervo",
            "Cerza",
            "Cerzeto",
            "Cesa",
            "Cesa",
            "Cesana Brianza",
            "Cesana Torinese",
            "Cesano",
            "Cesano Boscone",
            "Cesano Maderno",
            "Cesara",
            "Cesarò",
            "Cesarolo",
            "Cesate",
            "Cesena",
            "Cesenatico",
            "Cesinali",
            "Cesio",
            "Cesiomaggiore",
            "Cessalto",
            "Cessaniti",
            "Cessapalombo",
            "Cessole",
            "Cetara",
            "Ceto",
            "Cetona",
            "Cetraro",
            "Cetraro Marina",
            "Ceva",
            "Cevo",
            "Challand-Saint-Anselme",
            "Challand-Saint-Victor",
            "Chambave",
            "Chamois",
            "Champdepraz",
            "Champorcher",
            "Charvensod",
            "Chatillon",
            "Chef-Lieu",
            "Cherasco",
            "Cheremule",
            "Chiaia",
            "Chiaiano",
            "Chialamberto",
            "Chiampo",
            "Chianche",
            "Chianchitta-Pallio",
            "Chianchitta-Trappitello",
            "Chianciano Terme",
            "Chianni",
            "Chianocco",
            "Chiaramonte Gulfi",
            "Chiaramonti",
            "Chiarano",
            "Chiaravalle",
            "Chiaravalle",
            "Chiaravalle Centrale",
            "Chiari",
            "Chiaromonte",
            "Chiassa-Tregozzano",
            "Chiauci",
            "Chiaulis",
            "Chiavari",
            "Chiavenna",
            "Chiaverano",
            "Chienes",
            "Chieri",
            "Chiesa",
            "Chiesa",
            "Chiesa di Macra",
            "Chiesa in Valmalenco",
            "Chiesa Nuova",
            "Chiesanuova",
            "Chiesina Uzzanese",
            "Chiesino-Collodi",
            "Chieti",
            "Chieuti",
            "Chieve",
            "Chignolo d'Isola",
            "Chignolo Po",
            "Chioggia",
            "Chiomonte",
            "Chions",
            "Chiopris",
            "Chitignano",
            "Chiuduno",
            "Chiugiana-La Commenda",
            "Chiuppano",
            "Chiuro",
            "Chiusa",
            "Chiusa di Pesio",
            "Chiusa di San Michele",
            "Chiusa Sclafani",
            "Chiusaforte",
            "Chiusanico",
            "Chiusano d'Asti",
            "Chiusano di San Domenico",
            "Chiusavecchia",
            "Chiusdino",
            "Chiusi",
            "Chiusi della Verna",
            "Chiusi Scalo",
            "Chivasso",
            "Ciaculli",
            "Ciampino",
            "Cianciana",
            "Ciano",
            "Ciano d'Enza",
            "Ciavolo",
            "Cibiana",
            "Cicagna",
            "Cicala",
            "Cicciano",
            "Cicerale",
            "Ciciliano",
            "Cicognolo",
            "Ciconia",
            "Ciconio",
            "Cigliano",
            "Cigliè",
            "Cigognola",
            "Cigole",
            "Cilavegna",
            "Cimadolmo",
            "Cimbergo",
            "Cimbro",
            "Cimego",
            "Ciminà",
            "Ciminna",
            "Cimitile",
            "Cimolais",
            "Cimoneri",
            "Cinaglio",
            "Cineto Romano",
            "Cingia de' Botti",
            "Cingoli",
            "Cinigiano",
            "Cinisello Balsamo",
            "Cinisi",
            "Cino",
            "Cinque Frondi",
            "Cintano",
            "Cinte Tesino",
            "Cinto Caomaggiore",
            "Cintolese",
            "Cinzano",
            "Ciorlano",
            "Cipolleto",
            "Cipressa",
            "Circello",
            "Ciriè",
            "Cirigliano",
            "Cirimido",
            "Cirò",
            "Cirò Marina",
            "Cis",
            "Cisano",
            "Cisano Bergamasco",
            "Ciserano",
            "Cislago",
            "Cisliano",
            "Cismon del Grappa",
            "Cison di Valmarino",
            "Cissone",
            "Cisterna d'Asti",
            "Cisterna di Latina",
            "Cisternino",
            "Citerna",
            "Città della Pieve",
            "Città di Castello",
            "Città Giardino",
            "Città metropolitana di Milano",
            "Città metropolitana di Roma Capitale",
            "Città Sant'Angelo",
            "Cittadella",
            "Cittadella del Capo",
            "Cittaducale",
            "Cittanova",
            "Cittareale",
            "Cittiglio",
            "Civate",
            "Civesio",
            "Civezza",
            "Civezzano",
            "Civiasco",
            "Cividale del Friuli",
            "Cividate al Piano",
            "Cividate Camuno",
            "Civita",
            "Civita Castellana",
            "Civita d'Antino",
            "Civitacampomarano",
            "Civitaluparella",
            "Civitanova Alta",
            "Civitanova del Sannio",
            "Civitanova Marche",
            "Civitaquana",
            "Civitavecchia",
            "Civitella Alfedena",
            "Civitella Casanova",
            "Civitella d'Agliano",
            "Civitella del Tronto",
            "Civitella di Romagna",
            "Civitella in Val di Chiana",
            "Civitella Marittima",
            "Civitella Messer Raimondo",
            "Civitella Roveto",
            "Civitella San Paolo",
            "Cizzago-Comezzano",
            "Classe",
            "Claut",
            "Clauzetto",
            "Clavesana",
            "Claviere",
            "Cles",
            "Cleto",
            "Clivio",
            "Clodig",
            "Cloz",
            "Clusane",
            "Clusone",
            "Coassolo",
            "Coassolo Torinese",
            "Coazze",
            "Coazzolo",
            "Coccaglio",
            "Coccanile-Cesta",
            "Cocconato",
            "Cocquio",
            "Cocullo",
            "Codaruina",
            "Codemondo-Quaresimo",
            "Codevigo",
            "Codevilla",
            "Codigoro",
            "Codisotto",
            "Codiverno",
            "Codogna-Cardano",
            "Codognè",
            "Codogno",
            "Codroipo",
            "Codrongianos",
            "Coggiola",
            "Cogliate",
            "Cogne",
            "Cogoleto",
            "Cogollo del Cengio",
            "Cogolo",
            "Cogorno",
            "Col San Martino",
            "Colà",
            "Colazza",
            "Colbordolo",
            "Coldragone",
            "Colere",
            "Coli",
            "Colico Piano",
            "Colla-Muggiasca",
            "Collagna",
            "Collalbo",
            "Collalto Sabino",
            "Collarmele",
            "Collazzone",
            "Colle Brianza",
            "Colle Campano-Scrima",
            "Colle d'Anchise",
            "Colle del Pino",
            "Colle di Fuori",
            "Colle di Tora",
            "Colle di Val d'Elsa",
            "Colle Isarco",
            "Colle Mainello",
            "Colle San Magno",
            "Colle Sannita",
            "Colle Santa Lucia",
            "Colle Spina",
            "Colle Umberto",
            "Colle Verde",
            "Collebeato",
            "Collecchio",
            "Collecorvino",
            "Colledara",
            "Colledimacine",
            "Colledimezzo",
            "Colleferro",
            "Collefontana-Fontana Liri Inferiore",
            "Collegiove",
            "Collegno",
            "Collelongo",
            "Collemeto",
            "Collepardo",
            "Collepasso",
            "Collepepe",
            "Collepietro",
            "Colleranesco",
            "Colleretto Castelnuovo",
            "Colleretto Giacosa",
            "Collesalvetti",
            "Collesano",
            "Colletorto",
            "Collevecchio",
            "Colleverde II",
            "Colli a Volturno",
            "Colli del Tronto",
            "Colli di Enea",
            "Colli sul Velino",
            "Colliano",
            "Collinas",
            "Collio",
            "Collobiano",
            "Colloredo di Monte Albano",
            "Colloredo di Monte Albano-Lauzzana",
            "Colloredo di Prato",
            "Colmurano",
            "Colobraro",
            "Cologna Spiaggia",
            "Cologna Veneta",
            "Cologna-Caraverio",
            "Cologne",
            "Cologno al Serio",
            "Cologno Monzese",
            "Colognola ai Colli",
            "Colombaro",
            "Colombaro-Timoline",
            "Colombella",
            "Colombiera-Molicciara",
            "Colonna",
            "Colonnella",
            "Colonno",
            "Colorina",
            "Colorno",
            "Colosimi",
            "Colturano",
            "Colubro",
            "Colugna",
            "Colzate",
            "Comabbio",
            "Comacchio",
            "Comano",
            "Comazzo",
            "Comeana",
            "Comeglians",
            "Comerio",
            "Comignago",
            "Cominio",
            "Comiso",
            "Comitini",
            "Comiziano",
            "Commessaggio",
            "Commezzadura",
            "Como",
            "Compiano",
            "Compiobbi",
            "Comun Nuovo",
            "Comunanza",
            "Cona",
            "Conca Casale",
            "Conca dei Marini",
            "Conca della Campania",
            "Concerviano",
            "Concesio",
            "Conco",
            "Concordia Sagittaria",
            "Concordia sulla Secchia",
            "Concorezzo",
            "Condino",
            "Condofuri",
            "Condove",
            "Condrò",
            "Conegliano",
            "Confienza",
            "Configni",
            "Conflenti",
            "Coniolo Bricco",
            "Consandolo",
            "Conscenti",
            "Conscio",
            "Conselice",
            "Conselve",
            "Consiglio di Rumo",
            "Contessa Entellina",
            "Contigliano",
            "Contrada",
            "Controguerra",
            "Controne",
            "Contursi Terme",
            "Conversano",
            "Conzano",
            "Coperchia",
            "Copertino",
            "Copiano",
            "Copparo",
            "Coppito",
            "Corana",
            "Corato",
            "Corbanese",
            "Corbara",
            "Corbetta",
            "Corbola",
            "Corbolone",
            "Corcagnano",
            "Corchiano",
            "Corciano",
            "Corcolle",
            "Cordenons",
            "Cordignano",
            "Cordovado",
            "Coredo",
            "Coreglia Antelminelli",
            "Coreglia Ligure",
            "Coreno Ausonio",
            "Corfinio",
            "Corgeno",
            "Cori",
            "Coriano",
            "Corigliano Calabro",
            "Corigliano d'Otranto",
            "Corigliano Scalo",
            "Corinaldo",
            "Corio",
            "Corleone",
            "Corleto Monforte",
            "Corleto Perticara",
            "Cormano",
            "Cormons",
            "Corna Imagna",
            "Cornaiano",
            "Cornalba",
            "Cornale",
            "Cornaredo",
            "Cornate d'Adda",
            "Cornedo All'Isarco",
            "Cornedo Vicentino",
            "Corneliano d'Alba",
            "Corneno-Galliano-Carella Mariaga",
            "Corniglio",
            "Corno di Rosazzo",
            "Corno Giovine",
            "Cornovecchio",
            "Cornuda",
            "Corpo Reno",
            "Corpolò",
            "Correggio",
            "Correzzana",
            "Correzzola",
            "Corrido",
            "Corridonia",
            "Corropoli",
            "Corrubbio",
            "Corsanico-Bargecchia",
            "Corsano",
            "Corsico",
            "Corsione",
            "Cortaccia sulla Strada del Vino",
            "Cortale",
            "Cortandone",
            "Cortanze",
            "Cortazzone",
            "Corte",
            "Corte de' Cortesi",
            "Corte de' Cortesi con Cignone",
            "Corte de' Frati",
            "Corte Franca",
            "Cortemaggiore",
            "Cortemilia",
            "Corteno Golgi",
            "Cortenova",
            "Cortenuova",
            "Corteolona",
            "Cortiglione",
            "Cortina d'Ampezzo",
            "Cortina sulla Strada del Vino",
            "Cortino",
            "Cortoghiana",
            "Cortona",
            "Corvara",
            "Corvara in Badia",
            "Corvaro",
            "Corvino San Quirico",
            "Corzano",
            "Coseano",
            "Cosenza",
            "Cosio di Arroscia",
            "Cosio Valtellino",
            "Cosniga-Zoppè",
            "Cosoleto",
            "Cossano Belbo",
            "Cossano Canavese",
            "Cossato",
            "Cosseria",
            "Cossignano",
            "Cossogno",
            "Cossoine",
            "Cossombrato",
            "Costa de' Nobili",
            "Costa di Mezzate",
            "Costa di Rovigo",
            "Costa di Serina",
            "Costa Lambro",
            "Costa Masnaga",
            "Costa Sant'Abramo",
            "Costa Valle Imagna",
            "Costa Vescovato",
            "Costa Volpino",
            "Costa-Barco",
            "Costabissara",
            "Costacciaro",
            "Costano",
            "Costanzana",
            "Costarainera",
            "Costaroni",
            "Costermano",
            "Costigliole d'Asti",
            "Costiglione Saluzzo",
            "Cotignola",
            "Cotronei",
            "Cottanello",
            "Country Park",
            "Courmayeur",
            "Covelo",
            "Covo",
            "Covolo-Levada",
            "Cozzana",
            "Cozzo",
            "Craco-Sant'Angelo",
            "Crandola Valsassina",
            "Cras",
            "Crava",
            "Cravagliana",
            "Cravanzana",
            "Craveggia",
            "Creazzo",
            "Crecchio",
            "Credaro",
            "Credera",
            "Crema",
            "Cremella",
            "Cremenaga",
            "Cremeno",
            "Cremia",
            "Cremolino",
            "Cremona",
            "Cremosano",
            "Crepaldo",
            "Crescentino",
            "Crespadoro",
            "Crespano del Grappa",
            "Crespellano",
            "Crespiatica",
            "Crespina",
            "Crespino",
            "Cressa",
            "Creto",
            "Cretone",
            "Crevacuore",
            "Crevalcore",
            "Crevoladossola",
            "Crichi",
            "Crispano",
            "Crispi Cavour",
            "Crispiano",
            "Crissolo",
            "Crocefieschi",
            "Crocetta del Montello",
            "Crocetta-Nogarè",
            "Crodo",
            "Crognaleto",
            "Crone",
            "Cropalati",
            "Cropani",
            "Cropani Marina",
            "Crosia",
            "Crosio della Valle",
            "Crotone",
            "Crotta d'Adda",
            "Crova",
            "Croviana",
            "Crucoli",
            "Crugnola",
            "Cuasso al Monte",
            "Cuasso al Piano",
            "Cuccaro Monferrato",
            "Cuccaro Vetere",
            "Cucciago",
            "Cuccurano",
            "Cuceglio",
            "Cuggiono",
            "Cugliate-Fabiasco",
            "Cuglieri",
            "Cugnoli",
            "Cumiana",
            "Cumignano sul Naviglio",
            "Cunardo",
            "Cuneo",
            "Cunettone-Villa",
            "Cunevo",
            "Cunico",
            "Cuorgnè",
            "Cupello",
            "Cupra Marittima",
            "Cupramontana",
            "Cura Carpignano",
            "Curcuris",
            "Cureggio",
            "Curiglia",
            "Curinga",
            "Curino",
            "Curno",
            "Curon Venosta",
            "Cursi",
            "Cursolo",
            "Curtarolo",
            "Curti",
            "Cusago",
            "Cusano",
            "Cusano Mutri",
            "Cusercoli",
            "Cusino",
            "Cusio",
            "Custonaci",
            "Cutigliano",
            "Cutro",
            "Cutrofiano",
            "Cuveglio",
            "Cuvio",
            "Daiano",
            "Dairago",
            "Dalmine",
            "Dambel",
            "Danta",
            "Daone",
            "Darfo Boario Terme",
            "Dasà",
            "Davagna",
            "Daverio",
            "Davoli",
            "Dazio",
            "Decima",
            "Decimomannu",
            "Decimoputzu",
            "Decollatura",
            "Dego",
            "Deiva Marina",
            "Delebio",
            "Delia",
            "Delianuova",
            "Deliceto",
            "Dello",
            "Demonte",
            "Denice",
            "Denno",
            "Depressa",
            "Dernice",
            "Derovere",
            "Deruta",
            "Dervio",
            "Desana",
            "Dese",
            "Desenzano del Garda",
            "Desio",
            "Desulo",
            "Diamante",
            "Diano Arentino",
            "Diano Castello",
            "Diano d'Alba",
            "Diano Marina",
            "Diano San Pietro",
            "Dicomano",
            "Dignano",
            "Dimaro",
            "Dinami",
            "Dipignano",
            "Diso",
            "Divignano",
            "Dizzasco-Biazzeno",
            "Dobbiaco",
            "Doberdò del Lago",
            "Dodici Morelli",
            "Dogliani",
            "Dogliola",
            "Dogna",
            "Dolcè",
            "Dolceacqua",
            "Dolcedo",
            "Dolegna del Collio",
            "Dolianova",
            "Dolina",
            "Dolo",
            "Dolzago",
            "Domanico",
            "Domaso",
            "Domegge di Cadore",
            "Domicella",
            "Domio",
            "Domodossola",
            "Domus de Maria",
            "Domusnovas",
            "Don",
            "Don",
            "Donato",
            "Dongo",
            "Donigala Fenugheddu",
            "Donnalucata",
            "Donnas",
            "Donnici Inferiore",
            "Donoratico",
            "Donorì",
            "Donzella",
            "Dorgali",
            "Doria",
            "Dorio",
            "Dormelletto",
            "Dorno",
            "Dorsino",
            "Dorzano",
            "Dosimo",
            "Dosolo",
            "Dossena",
            "Dosso",
            "Dosso del Liro",
            "Dosso-Ville",
            "Dossobuono",
            "Dosson",
            "Doues",
            "Dovadola",
            "Dovera",
            "Dozza",
            "Dragonea",
            "Dragoni",
            "Drapia",
            "Drena",
            "Dresano",
            "Drizzona",
            "Dro",
            "Dronero",
            "Drubiaglio-Grangia",
            "Druento",
            "Druogno",
            "Dualchi",
            "Dubino",
            "Due Carrare",
            "Dueville",
            "Dugenta",
            "Duino",
            "Dumenza",
            "Duno",
            "Duomo",
            "Durazzano",
            "Duronia",
            "Dusino",
            "Eboli",
            "Edolo",
            "Egna",
            "Elice",
            "Elini",
            "Ello",
            "Elmas",
            "Emarese",
            "Empoli",
            "Endine",
            "Enego",
            "Enemonzo-Quinis",
            "Enna",
            "Entracque",
            "Entratico",
            "Envie",
            "Episcopia",
            "Era",
            "Eraclea",
            "Erba",
            "Erbè",
            "Erbezzo",
            "Erbusco",
            "Erchie",
            "Ercolano",
            "Eremo",
            "Erice",
            "Erli",
            "Erto",
            "Erula",
            "Erve",
            "Esanatoglia",
            "Escalaplano",
            "Escolca",
            "Esine",
            "Esino Lario",
            "Esperia",
            "Esporlatu",
            "Este",
            "Esterzili",
            "Etroubles",
            "Evangelisti-Rubino",
            "Exilles",
            "Fabbriche di Vallico",
            "Fabbrico",
            "Fabriano",
            "Fabrica di Roma",
            "Fabrizia",
            "Fabrizio",
            "Fabro",
            "Fabro Scalo",
            "Faedis",
            "Faedo",
            "Faedo Valtellino",
            "Faella",
            "Faenza",
            "Faeto",
            "Fagagna",
            "Faggeto Lario",
            "Faggiano",
            "Fagnano Castello",
            "Fagnano Olona-Bergoro",
            "Fai della Paganella",
            "Faiano",
            "Faicchio",
            "Falcade Alto",
            "Falciano del Massico",
            "Falconara Albanese",
            "Falconara Marittima",
            "Falcone",
            "Faleria",
            "Falerna",
            "Falerna Scalo",
            "Falerone",
            "Fallo",
            "Falmenta",
            "Faloppio",
            "Falvaterra",
            "Falzè di Piave",
            "Falzè-Signoressa",
            "Falzes",
            "Fanano",
            "Fanano",
            "Fane",
            "Fanna",
            "Fano",
            "Fano Adriano",
            "Fantasina",
            "Fanzolo",
            "Fara Filiorum Petri",
            "Fara Gera d'Adda",
            "Fara in Sabina",
            "Fara Novarese",
            "Fara Olivana",
            "Fara San Martino",
            "Fara Vicentino",
            "Faraldo-Nocelleto",
            "Fardella",
            "Farigliano",
            "Farindola",
            "Farini",
            "Farnese",
            "Faro Superiore",
            "Farra d'Alpago",
            "Farra d'Isonzo",
            "Farra di Soligo",
            "Fasano",
            "Fascia",
            "Fauglia",
            "Faule",
            "Favale di Malvaro",
            "Favara",
            "Favari-Avatanei",
            "Faver",
            "Favignana",
            "Favria",
            "Feglino",
            "Feisoglio",
            "Felegara",
            "Feletto",
            "Felina",
            "Felino",
            "Felitto",
            "Felizzano",
            "Fellegara",
            "Felline",
            "Felonica",
            "Feltre",
            "Fenegrò",
            "Fenestrelle",
            "Fenile",
            "Fénis",
            "Ferentillo",
            "Ferentino",
            "Ferla",
            "Fermignano",
            "Fermo",
            "Ferno",
            "Feroleto Antico",
            "Feroleto della Chiesa",
            "Ferrada",
            "Ferrandina",
            "Ferrara",
            "Ferrara di Monte Baldo",
            "Ferrazzano",
            "Ferrera di Varese",
            "Ferrera Erbognone",
            "Ferrere",
            "Ferriera",
            "Ferriere",
            "Ferriere",
            "Ferruzzano",
            "Fiamignano",
            "Fiano",
            "Fiano Romano",
            "Fiavè",
            "Ficarazzi",
            "Ficarolo",
            "Ficarra",
            "Ficulle",
            "Fidenza",
            "Fiè Allo Sciliar",
            "Fiera di Primiero",
            "Fierozzo",
            "Fiesco",
            "Fiesole",
            "Fiesse",
            "Fiesso",
            "Fiesso d'Artico",
            "Fiesso Umbertiano",
            "Figino",
            "Figino Serenza",
            "Figliaro",
            "Figline Valdarno",
            "Figline Vegliaturo",
            "Filacciano",
            "Filadelfia",
            "Filago",
            "Filandari",
            "Filattiera",
            "Filettino",
            "Filetto",
            "Filettole",
            "Filiano",
            "Filighera",
            "Filignano",
            "Filogaso",
            "Filottrano",
            "Finale",
            "Finale Emilia",
            "Finale Ligure",
            "Fino del Monte",
            "Fino Mornasco",
            "Fiorano",
            "Fiorano al Serio",
            "Fiorano Canavese",
            "Fiorenzuola d'Arda",
            "Firenzuola",
            "Firmo",
            "Fisciano",
            "Fiuggi",
            "Fiumalbo-Dogana",
            "Fiumana",
            "Fiumara",
            "Fiume Veneto",
            "Fiumedinisi",
            "Fiumefreddo Bruzio",
            "Fiumefreddo Sicilia",
            "Fiumicello",
            "Fiumicino-Isola Sacra",
            "Fivizzano",
            "Fizzonasco",
            "Flaibano",
            "Flavon",
            "Fleccia-Chianavasso",
            "Flero",
            "Florence",
            "Floresta",
            "Floridia",
            "Florinas",
            "Flumeri",
            "Fluminimaggiore",
            "Flussio",
            "Fobello",
            "Focene",
            "Foggia",
            "Foglianise",
            "Fogliano",
            "Fogliano",
            "Foglizzo",
            "Fognano",
            "Foiano della Chiana",
            "Foiano di Val Fortore",
            "Folgaria",
            "Folignano",
            "Foligno",
            "Follina",
            "Follonica",
            "Folzano",
            "Fombio",
            "Fondachelli-Fantina",
            "Fondi",
            "Fondo",
            "Fonni",
            "Fontainemore",
            "Fontana delle Monache",
            "Fontana Nuova-Bevia",
            "Fontanafredda",
            "Fontanarosa",
            "Fontane-Zurane-Gresine",
            "Fontanelice",
            "Fontanella",
            "Fontanella-Ozino",
            "Fontanellato",
            "Fontanelle",
            "Fontaneto D'Agogna",
            "Fontanetto Po",
            "Fontanigorda",
            "Fontanile",
            "Fontaniva",
            "Fonte Umano-San Martino Alta",
            "Fonteblanda",
            "Fontecchio",
            "Fontechiari",
            "Fontegreca",
            "Fonteno",
            "Fontevivo",
            "Fontignano",
            "Fonzaso",
            "Foppolo",
            "Forano",
            "Force",
            "Forche",
            "Forchia",
            "Forcola",
            "Forcoli",
            "Fordongianus",
            "Forenza",
            "Foresta",
            "Foresto Sparso",
            "Forette",
            "Forgaria nel Friuli",
            "Forino",
            "Forio",
            "Forlì",
            "Forlì del Sannio",
            "Forlimpopoli",
            "Formazza",
            "Formello",
            "Formia",
            "Formica",
            "Formicola",
            "Formigara",
            "Formigine",
            "Formigliana",
            "Formignana",
            "Fornace",
            "Fornace Zarattini",
            "Fornacelle",
            "Fornacette",
            "Fornaci",
            "Fornaci",
            "Fornaci",
            "Fornaci di Barga",
            "Fornase",
            "Fornelli",
            "Forni Avoltri",
            "Forni di Sopra",
            "Forni di Sotto",
            "Forno Canavese",
            "Forno di Zoldo",
            "Fornole",
            "Fornovo di Taro",
            "Fornovo San Giovanni",
            "Forte dei Marmi",
            "Fortezza",
            "Fortunago",
            "Forza d'Agrò",
            "Fosciandora",
            "Fosdinovo",
            "Fossa",
            "Fossa",
            "Fossacesia",
            "Fossalta di Piave",
            "Fossalta di Portogruaro",
            "Fossalto",
            "Fossalunga",
            "Fossano",
            "Fossato di Vico",
            "Fossato Ionico-Fossatello-San Luca Marcelluzzo",
            "Fossato Serralta",
            "Fossignano",
            "Fossò",
            "Fosso Ghiaia",
            "Fossoli",
            "Fossombrone",
            "Foza",
            "Frabosa Soprana",
            "Frabosa Sottana",
            "Fraconalto",
            "Fragagnano",
            "Fragneto L'Abate",
            "Fragneto Monforte",
            "Fraine",
            "Framura",
            "Francavilla al Mare",
            "Francavilla Angitola",
            "Francavilla Bisio",
            "Francavilla d'Ete",
            "Francavilla di Sicilia",
            "Francavilla Fontana",
            "Francavilla in Sinni",
            "Francavilla Marittima",
            "Francenigo",
            "Franche",
            "Francica",
            "Francofonte",
            "Francolino",
            "Francolise",
            "Frascaro",
            "Frascarolo",
            "Frascati",
            "Frascineto",
            "Frassilongo",
            "Frassinelle Polesine",
            "Frassinello Monferrato",
            "Frassineto Po",
            "Frassinetto",
            "Frassino",
            "Frassinoro",
            "Frasso Sabino",
            "Frasso Telesino",
            "Fratta Polesine",
            "Fratta Terme",
            "Fratta Todina",
            "Frattamaggiore",
            "Frattaminore",
            "Fratte Rosa",
            "Fraviano",
            "Frazione Chiesa",
            "Frazzanò",
            "Fregene",
            "Fregona",
            "Fresagrandinaria",
            "Frescada",
            "Fresonara",
            "Frigento",
            "Frignano",
            "Frinco",
            "Frisa",
            "Frisanco",
            "Front",
            "Frontino",
            "Frontone",
            "Frosinone",
            "Frosolone",
            "Frossasco",
            "Frugarolo",
            "Fubine",
            "Fucecchio",
            "Fuipiano Valle Imagna",
            "Fulgatore-Torretta",
            "Fumane",
            "Fumone",
            "Funes - Villnoess",
            "Fuorigrotta",
            "Fuorni",
            "Furato",
            "Furci",
            "Furci Siculo",
            "Furnari",
            "Furore",
            "Furtei",
            "Fuscaldo",
            "Fusignano",
            "Fusine",
            "Fusine",
            "Futani",
            "Gabbio-Cereda-Ramate",
            "Gabbioneta",
            "Gabella",
            "Gabiano",
            "Gabicce Mare",
            "Gaby",
            "Gadesco-Pieve Delmona",
            "Gadoni",
            "Gaeta",
            "Gaggi",
            "Gaggiano",
            "Gaggino",
            "Gaggio",
            "Gaggio Montano",
            "Gaglianico",
            "Gagliano Aterno",
            "Gagliano Castelferrato",
            "Gagliano del Capo",
            "Gagliato",
            "Gagliole",
            "Gaiano",
            "Gaiarine",
            "Gaiba",
            "Gaibanella-Sant'Edigio",
            "Gaiola",
            "Gaiole in Chianti",
            "Gairo Sant'Elena",
            "Gais",
            "Galati Mamertino",
            "Galatina",
            "Galatone",
            "Galatro",
            "Galbiate",
            "Galeata",
            "Galgagnano",
            "Gallarate",
            "Gallese",
            "Galliano",
            "Galliate",
            "Galliate Lombardo",
            "Galliavola",
            "Gallicano",
            "Gallicano nel Lazio",
            "Gallicchio",
            "Galliera Veneta",
            "Gallinaro",
            "Gallio",
            "Gallipoli",
            "Gallo",
            "Gallo",
            "Gallo",
            "Gallo Matese",
            "Gallo-Tre Re-Mezzana Corti",
            "Gallodoro",
            "Galluccio",
            "Galta",
            "Galtellì",
            "Galugnano",
            "Galzignano",
            "Gamalero",
            "Gambara",
            "Gambarana",
            "Gambasca",
            "Gambassi Terme",
            "Gambatesa",
            "Gambellara",
            "Gamberale",
            "Gambettola",
            "Gambolò",
            "Gambugliano",
            "Ganda",
            "Gandellino",
            "Gandino",
            "Gandosso",
            "Gangi",
            "Ganna",
            "Garadassi",
            "Garaguso",
            "Garbagna",
            "Garbagna Novarese",
            "Garbagnate",
            "Garbagnate Milanese",
            "Garbagnate Monastero",
            "Garda",
            "Gardola",
            "Gardone Riviera",
            "Gardone Val Trompia",
            "Garessio",
            "Gargallo",
            "Gargazzone",
            "Gargnano",
            "Garino",
            "Garlasco",
            "Garlate",
            "Garlenda",
            "Garniga Nuova",
            "Garzeno",
            "Garzigliana",
            "Gasperina",
            "Gassino Torinese",
            "Gatteo a Mare",
            "Gatteo-Sant'Angelo",
            "Gattico",
            "Gattinara",
            "Gavardo-Sopraponte",
            "Gavarno Rinnovata",
            "Gavarno-Tribulina",
            "Gavazzana",
            "Gavello",
            "Gaverina Terme",
            "Gavi",
            "Gavignano",
            "Gavirate",
            "Gavoi",
            "Gavorrano",
            "Gazoldo degli Ippoliti",
            "Gazzada Schianno",
            "Gazzaniga",
            "Gazzo",
            "Gazzo",
            "Gazzola",
            "Gazzolo-Volpino",
            "Gazzuolo",
            "Gela",
            "Gello",
            "Gemini",
            "Gemmano",
            "Gemona",
            "Gemonio",
            "Genazzano",
            "Genga",
            "Genio Civile",
            "Genivolta",
            "Genoa",
            "Genola",
            "Genoni",
            "Genuri",
            "Genzano di Lucania",
            "Genzano di Roma",
            "Genzone",
            "Gera Lario",
            "Gerace",
            "Geraci Siculo",
            "Gerano",
            "Gerbido",
            "Gerbole",
            "Gerbole-Zucche",
            "Gerenzago",
            "Gerenzano",
            "Gergei",
            "Germagnano",
            "Germagno",
            "Germignaga",
            "Gerocarne",
            "Gerola Alta",
            "Geromina",
            "Gerosa",
            "Gerre de' Caprioli",
            "Gesico",
            "Gessate",
            "Gessopalena",
            "Gesturi",
            "Gesualdo",
            "Ghedi",
            "Ghemme",
            "Ghiaie",
            "Ghiare-Madonna",
            "Ghiffa",
            "Ghilarza",
            "Ghisalba",
            "Ghislarengo",
            "Giacalone",
            "Giacciano con Baruchella",
            "Giaglione",
            "Giammoro",
            "Gianico",
            "Giano dell'Umbria",
            "Giano Vetusto",
            "Giardina Gallotti",
            "Giardinello",
            "Giardini-Naxos",
            "Giarole",
            "Giarratana",
            "Giarre",
            "Giave",
            "Giavenale",
            "Giaveno",
            "Giavera del Montello",
            "Giba",
            "Gifflenga",
            "Giffone",
            "Giffoni Valle Piana",
            "Giglio Castello",
            "Gignese",
            "Gignod",
            "Gildone",
            "Gimigliano",
            "Ginestra",
            "Ginestra degli Schiavoni",
            "Ginestra Fiorentina",
            "Ginosa",
            "Gioi",
            "Gioia dei Marsi",
            "Gioia del Colle",
            "Gioia Sannitica",
            "Gioia Tauro",
            "Gioiosa Ionica",
            "Gioiosa Marea",
            "Gionghi-Cappella",
            "Giorgilorio",
            "Giove",
            "Giovenzano",
            "Giovi-Ponte alla Chiassa",
            "Giovinazzo",
            "Giovo",
            "Girardi-Bellavista-Terrazze",
            "Girasole",
            "Girifalco",
            "Gironico al Piano",
            "Gissi",
            "Giudecca",
            "Giuggianello",
            "Giugliano in Campania",
            "Giuliana",
            "Giulianello",
            "Giuliano di Roma",
            "Giuliano Teatino",
            "Giulianova",
            "Giuncugnano",
            "Giungano",
            "Giurdignano",
            "Giussago",
            "Giussano",
            "Giustenice",
            "Giustino",
            "Giusvalla",
            "Givoletto",
            "Gizzeria",
            "Gliaca",
            "Glorenza",
            "Glorie",
            "Godega",
            "Godiasco",
            "Godo",
            "Godrano",
            "Goito",
            "Golasecca",
            "Golferenzo",
            "Golfo Aranci",
            "Gombito",
            "Gonars",
            "Goni",
            "Gonnesa",
            "Gonnoscodina",
            "Gonnosfanadiga",
            "Gonnosnò",
            "Gonnostramatza",
            "Gonte",
            "Gonzaga",
            "Gordona",
            "Gorga",
            "Gorgo al Monticano",
            "Gorgo della Chiesa",
            "Gorgoglione",
            "Gorgonzola",
            "Goriano Sicoli",
            "Gorizia",
            "Gorla Maggiore",
            "Gorla Minore",
            "Gorlago",
            "Gorle",
            "Gornate Olona",
            "Gorno",
            "Goro",
            "Gorreto",
            "Gorzano",
            "Gorzegno",
            "Gosaldo",
            "Gossolengo",
            "Gottasecca",
            "Gottolengo",
            "Govone",
            "Gozzano",
            "Gradara",
            "Gradisca d'Isonzo",
            "Grado",
            "Gradoli",
            "Graffignana",
            "Graffignano",
            "Graglia",
            "Gragnano",
            "Gragnano Trebbiense",
            "Grammichele",
            "Grana",
            "Granarolo",
            "Granarolo dell'Emilia e Viadagola",
            "Grancona",
            "Grand Brissogne",
            "Grandate",
            "Grandola ed Uniti",
            "Graniti",
            "Granozzo con Monticello",
            "Grantola",
            "Grantorto",
            "Granvilla",
            "Granze",
            "Grassano",
            "Grassina Ponte a Ema",
            "Grassobbio",
            "Gratacasolo",
            "Gratteri",
            "Grauno",
            "Gravedona-San Gregorio",
            "Gravellona",
            "Gravellona Toce",
            "Gravere",
            "Gravina di Catania",
            "Gravina in Puglia",
            "Grazzanise",
            "Grazzano Badoglio",
            "Greci",
            "Greggio",
            "Gremiasco",
            "Gressan",
            "Gressoney-La-Trinitè",
            "Gressoney-Saint-Jean",
            "Greve in Chianti",
            "Grezzago",
            "Grezzana",
            "Griante",
            "Gricignano di Aversa",
            "Grignano",
            "Grignano Polesine",
            "Grignasco",
            "Grigno",
            "Grimaldi",
            "Grinzane Cavour",
            "Grisignano di Zocco",
            "Grisolia",
            "Grizzana",
            "Grizzana Morandi",
            "Grognardo",
            "Gromlongo",
            "Gromo",
            "Grondona",
            "Grone",
            "Grontardo",
            "Gropello Cairoli",
            "Gropparello",
            "Groscavallo",
            "Grosio",
            "Grosotto",
            "Grosseto",
            "Grosso",
            "Grottaferrata",
            "Grottaglie",
            "Grottaminarda",
            "Grottammare",
            "Grottazzolina",
            "Grotte",
            "Grotte di Castro",
            "Grotte Santo Stefano",
            "Grotteria",
            "Grottola",
            "Grottole",
            "Grottolella",
            "Gruaro",
            "Gruaro-Bagnara",
            "Grugliasco",
            "Grumello Cremonese",
            "Grumello del Monte",
            "Grumento Nova",
            "Grumes",
            "Grumo Appula",
            "Grumo Nevano",
            "Grumolo delle Abbadesse",
            "Grumolo Pedemonte",
            "Grunuovo-Campomaggiore San Luca",
            "Guagnano",
            "Gualdo",
            "Gualdo Cattaneo",
            "Gualdo Tadino",
            "Gualtieri",
            "Gualtieri Sicaminò",
            "Guamaggiore",
            "Guanzate",
            "Guarcino",
            "Guarda Veneta",
            "Guardabosone",
            "Guardamiglio",
            "Guardavalle",
            "Guardavalle Marina",
            "Guardea",
            "Guardia Lombardi",
            "Guardia Perticara",
            "Guardia Piemontese",
            "Guardia Sanframondi",
            "Guardiagrele",
            "Guardialfiera",
            "Guardiaregia",
            "Guardistallo",
            "Guarene",
            "Guasila",
            "Guastalla",
            "Guasticce",
            "Guazzino",
            "Guazzora",
            "Gubbio",
            "Gudo Visconti",
            "Guglionesi",
            "Guidizzolo",
            "Guidomandri Marina",
            "Guidonia",
            "Guidonia Montecelio",
            "Guiglia",
            "Guilmi",
            "Guinzano",
            "Gurro",
            "Guspini",
            "Gussago",
            "Gussola",
            "Hone",
            "Ialmicco",
            "Idro",
            "Iesolo",
            "Iglesias",
            "Igliano",
            "Il Romito",
            "Ilbono",
            "Illasi",
            "Illorai",
            "Imbersago",
            "Imer",
            "Imola",
            "Imperia",
            "Imposte",
            "Impruneta",
            "Inarzo",
            "Incisa in Val d'Arno",
            "Incisa Scapaccino",
            "Incudine",
            "Induno Olona",
            "Ingria",
            "Intragna",
            "Introbio",
            "Introd",
            "Introdacqua",
            "Introzzo",
            "Inverigo",
            "Inverno",
            "Inverno e Monteleone",
            "Inverso Pinasca",
            "Inveruno",
            "Invorio",
            "Inzago",
            "Irgoli",
            "Irma",
            "Irsina",
            "Isasca",
            "Isca Marina",
            "Isca sullo Ionio",
            "Ischia",
            "Ischia di Castro",
            "Ischia Porto",
            "Ischitella",
            "Iseo",
            "Isera",
            "Isernia",
            "Isili",
            "Isnello",
            "Isola",
            "Isola D'Arbia",
            "Isola d'Asti",
            "Isola del Cantone",
            "Isola del Giglio",
            "Isola del Gran Sasso d'Italia",
            "Isola del Liri",
            "Isola del Piano",
            "Isola della Scala",
            "Isola delle Femmine",
            "Isola di Capo Rizzuto",
            "Isola di Fondra",
            "Isola Dovarese",
            "Isola Rizza",
            "Isola Sant'Antonio",
            "Isola Vicentina",
            "Isolabella",
            "Isolabona",
            "Isolaccia",
            "Isole Tremiti",
            "Isorella",
            "Isoverde",
            "Ispani",
            "Ispica",
            "Ispra",
            "Issiglio",
            "Issime",
            "Isso",
            "Issogne",
            "Istia D'Ombrone-Le Stiacciole",
            "Istrana",
            "Itala",
            "Itri",
            "Ittireddu",
            "Ittiri",
            "Ivrea",
            "Izano",
            "Jacurso",
            "Jelsi",
            "Jenne",
            "Jerago Con Orago",
            "Jerzu",
            "Jesi",
            "Jolanda di Savoia",
            "Jonadi",
            "Joppolo",
            "Joppolo Giancaxio",
            "Jovencan",
            "Kamma",
            "L'Aquila",
            "La Botte",
            "La Caletta",
            "La California",
            "La Cassa",
            "La Cretaz-Roisan",
            "La Forma",
            "La Loggia",
            "La Maddalena",
            "La Magdeleine",
            "La Martella",
            "La Massimina-Casal Lumbroso",
            "La Morra",
            "La Pieve-Molino",
            "La Place",
            "La Rosa",
            "La Rotta",
            "La Saletta-Tamara",
            "La Salle",
            "La Salute di Livenza",
            "La Spezia",
            "La Thuile",
            "La Valle - Wengen",
            "La Valle Agordina",
            "La Villa",
            "Labico",
            "Labro",
            "Lacchiarella",
            "Lacco Ameno",
            "Lacedonia",
            "Laces",
            "Laconi",
            "Lacugnano",
            "Ladispoli",
            "Laerru",
            "Laganadi",
            "Laghetti",
            "Laghetto",
            "Laghi",
            "Laglio",
            "Lagnasco",
            "Lago",
            "Lagonegro",
            "Lagosanto",
            "Lagundo",
            "Laigueglia",
            "Lainate",
            "Laino",
            "Laino Borgo",
            "Laino Castello-Nuovo Centro",
            "Laion",
            "Laives",
            "Lajatico",
            "Lallio",
            "Lama",
            "Lama dei Peligni",
            "Lama Pezzoli",
            "Lambrinia",
            "Lambrugo",
            "Lamezia Terme",
            "Lamie",
            "Lamie di Olimpie-Selva",
            "Lamon",
            "Lamosano",
            "Lampedusa",
            "Lamporecchio",
            "Lamporo",
            "Lana",
            "Lancenigo-Villorba",
            "Lanciano",
            "Lancusi-Penta-Bolano",
            "Landiona",
            "Landriano",
            "Langhirano",
            "Langosco",
            "Lanusei",
            "Lanuvio",
            "Lanvario",
            "Lanzada",
            "Lanzara",
            "Lanzo d'Intelvi",
            "Lanzo Torinese",
            "Lapedona",
            "Lapio",
            "Lappano",
            "Larciano",
            "Lardaro",
            "Larderia",
            "Lardirago",
            "Lari",
            "Lariano",
            "Larino",
            "Las Plassas",
            "Lasa",
            "Lascari",
            "Lases",
            "Lasino",
            "Lasnigo",
            "Lassolaz",
            "Lastebasse",
            "Lastra a Signa",
            "Latera",
            "Laterina",
            "Laterza",
            "Latiano",
            "Latina",
            "Latina Scalo",
            "Latisana",
            "Latronico",
            "Lattarico",
            "Lauco",
            "Laura",
            "Laureana Cilento",
            "Laureana di Borrello",
            "Lauregno",
            "Laurenzana",
            "Lauria",
            "Lauriano",
            "Laurignano",
            "Laurino",
            "Laurito",
            "Lauro",
            "Lauzacco",
            "Lavagna",
            "Lavagno",
            "Lavello",
            "Lavena Ponte Tresa",
            "Laveno",
            "Lavenone",
            "Lavezzola",
            "Laviano",
            "Lavinaio-Monterosso",
            "Lavinio",
            "Lavis",
            "Laxolo",
            "Lazise",
            "Lazzaro",
            "Lazzate",
            "Lazzeretto",
            "Le Casine-Perignano-Spinelli",
            "Le Castella",
            "Le Forna",
            "Le Grazie",
            "Le Grazie di Ancona",
            "Le Rughe",
            "Lecce",
            "Lecce Nei Marsi",
            "Lecco",
            "Leffe",
            "Leggiuno",
            "Legnago",
            "Legnano",
            "Legnaro",
            "Lei",
            "Leini",
            "Leivi",
            "Lemie",
            "Lemna",
            "Lendinara",
            "Leni",
            "Lenna",
            "Lenno",
            "Leno",
            "Lenola",
            "Lenta",
            "Lentate sul Seveso",
            "Lentella",
            "Lentiai",
            "Lentigione-Sorbolo a Mane",
            "Lentini",
            "Leone-Santa Elisabetta",
            "Leonessa",
            "Leonforte",
            "Leporano",
            "Leporano Marina",
            "Lequile",
            "Lequio Berria",
            "Lequio Tanaro",
            "Lercara Friddi",
            "Lerici",
            "Lerma",
            "Lesa",
            "Lesegno",
            "Lesignano de'Bagni",
            "Lesina",
            "Lesmo",
            "Lesna",
            "Lessolo",
            "Lessona",
            "Lestans",
            "Lestizza",
            "Letino",
            "Letojanni",
            "Lettere",
            "Lettomanoppello",
            "Lettopalena",
            "Leuca",
            "Levada",
            "Levanto",
            "Levata",
            "Levate",
            "Leverano",
            "Levice",
            "Levico Terme",
            "Levone",
            "Lezzeno",
            "Li Punti-San Giovanni",
            "Liberi",
            "Librizzi",
            "Licata",
            "Licciana Nardi",
            "Licenza",
            "Licinella-Torre di Paestum",
            "Licodia Eubea",
            "Licusati",
            "Lido",
            "Lido",
            "Lido Adriano",
            "Lido degli Estensi",
            "Lido dei Pini",
            "Lido di Fermo",
            "Lido di Jesolo",
            "Lido di Ostia",
            "Lido di Pomposa-Lido degli Scacchi",
            "Lierna",
            "Lignana",
            "Lignano Sabbiadoro",
            "Ligonchio",
            "Ligosullo",
            "Lillianes",
            "Limana",
            "Limatola",
            "Limbadi-Caroni",
            "Limbiate",
            "Limena",
            "Limidi",
            "Limido Comasco",
            "Limina",
            "Limite",
            "Limiti di Greccio",
            "Limone Piemonte",
            "Limone sul Garda",
            "Limosano",
            "Linarolo",
            "Linate",
            "Linera",
            "Linguaglossa",
            "Lioni",
            "Lipari",
            "Lipomo",
            "Lirio",
            "Lisanza",
            "Liscate",
            "Liscia",
            "Lisciano Niccone",
            "Lisiera",
            "Lisignago",
            "Lisio",
            "Lissone",
            "Litta Parodi-Cascinagrossa",
            "Livera",
            "Liveri",
            "Livigno",
            "Livinallongo del Col di Lana",
            "Livo",
            "Livo",
            "Livorno",
            "Livorno Ferraris",
            "Livraga",
            "Lizzanello",
            "Lizzano",
            "Lizzano in Belvedere",
            "Loano",
            "Loazzolo",
            "Locana",
            "Locara",
            "Locate di Triulzi",
            "Locate Varesino",
            "Locatello",
            "Loceri",
            "Locorotondo",
            "Locri",
            "Loculi",
            "Lodè",
            "Lodetto",
            "Lodi",
            "Lodi Vecchio",
            "Lodine",
            "Lodrino",
            "Lodrone-Darzo",
            "Lograto",
            "Loiano",
            "Loiri Porto San Paolo",
            "Lomagna",
            "Lomazzo",
            "Lombardore",
            "Lombriasco",
            "Lomello",
            "Lona-Lases",
            "Lonate Ceppino",
            "Lonate Pozzolo",
            "Lonato",
            "Londa",
            "Longano",
            "Longara",
            "Longare",
            "Longarone",
            "Longastrino",
            "Longhena",
            "Longhi",
            "Longi",
            "Longiano",
            "Longobardi",
            "Longobucco",
            "Longone al Segrino",
            "Longone Sabino",
            "Lonigo",
            "Loranzè",
            "Loreggia",
            "Loreggiola",
            "Loreglia",
            "Lorenzago di Cadore",
            "Lorenzana",
            "Loreo",
            "Loreto",
            "Loreto Aprutino",
            "Loreto Stazione",
            "Loria Bessica",
            "Loro Ciuffenna",
            "Loro Piceno",
            "Lorsica",
            "Loseto",
            "Losine",
            "Lotzorai",
            "Lovere",
            "Lovero",
            "Lozio",
            "Lozza",
            "Lozzo Atestino",
            "Lozzo di Cadore",
            "Lozzolo",
            "Lu",
            "Lu Bagnu",
            "Lubriano",
            "Lucca",
            "Lucca Sicula",
            "Lucera",
            "Lucignano",
            "Lucinasco",
            "Lucino",
            "Lucino-Rodano",
            "Lucito",
            "Luco dei Marsi",
            "Luco Mugello",
            "Lucoli",
            "Lucrezia",
            "Lucugnano",
            "Ludriano",
            "Lugagnano",
            "Lugagnano Val d'Arda",
            "Lughignano",
            "Lugnacco",
            "Lugnano in Teverina",
            "Lugo",
            "Lugo di Vicenza",
            "Lugugnana",
            "Luino",
            "Luisago",
            "Lula",
            "Lumellogno",
            "Lumezzane",
            "Lumignano",
            "Lunamatrona",
            "Lunano",
            "Lunetta-Frassino",
            "Lungavilla",
            "Lungro",
            "Luogosano",
            "Luogosanto",
            "Lupara",
            "Lurago d'Erba",
            "Lurago Marinone",
            "Lurano",
            "Luras",
            "Lurate Caccivio",
            "Lusciano",
            "Luserna",
            "Luserna",
            "Lusernetta",
            "Lusevera",
            "Lusia",
            "Lusiana",
            "Lusigliè",
            "Luson - Luesen",
            "Lustra",
            "Lutrano",
            "Luvinate",
            "Luzzana",
            "Luzzano",
            "Luzzara",
            "Luzzi",
            "Maccacari",
            "Maccagno",
            "Maccarese",
            "Maccastorna",
            "Macchia",
            "Macchia d'Isernia",
            "Macchia Valfortore",
            "Macchiagodena",
            "Maccoli-Perrillo",
            "Macello",
            "Macerata",
            "Macerata Campania",
            "Macerata Feltria",
            "Macere",
            "Macherio",
            "Macine-Borgo Loreto",
            "Maclodio",
            "Macomer",
            "Macra",
            "Macugnaga",
            "Maddaloni",
            "Madignano",
            "Madone",
            "Madonna del Sasso",
            "Madonna dell'Acqua",
            "Madonna Dell'Albero",
            "Madrano-Canzolino",
            "Maenza",
            "Maerne",
            "Mafalda",
            "Magasa",
            "Magenta",
            "Maggiora",
            "Magherno",
            "Magione",
            "Magisano",
            "Magliano",
            "Magliano Alfieri",
            "Magliano Alpi",
            "Magliano De'Marsi",
            "Magliano di Tenna",
            "Magliano in Toscana",
            "Magliano Romano",
            "Magliano Sabina",
            "Magliano Vetere",
            "Maglie",
            "Magliolo",
            "Maglione",
            "Maglione-Crosa",
            "Magnacavallo",
            "Magnago",
            "Magnano",
            "Magnano in Riviera",
            "Magno",
            "Magomadas",
            "Magrè sulla Strada del Vino",
            "Magreglio",
            "Magreta",
            "Magugnano",
            "Maida",
            "Maierà",
            "Maierato",
            "Maiolati Spontini",
            "Maiori",
            "Mairago",
            "Mairano",
            "Maissana",
            "Majano",
            "Malagnino",
            "Malalbergo",
            "Malavicina",
            "Malborghetto",
            "Malcesine",
            "Malche-Santa Croce-Serroni",
            "Malè",
            "Malegno",
            "Maleo",
            "Malesco",
            "Maletto",
            "Malfa",
            "Malgesso",
            "Malgrate",
            "Malito",
            "Mallare",
            "Malles Venosta",
            "Malmantile",
            "Malnate",
            "Malo",
            "Malonno",
            "Malosco",
            "Maltignano",
            "Malvagna",
            "Malvicino",
            "Malvito",
            "Mammola",
            "Mamoiada",
            "Manciano",
            "Mandanici",
            "Mandas",
            "Mandatoriccio",
            "Mandela",
            "Mandello del Lario",
            "Mandello Vitta",
            "Mandriola-Sant'Agostino",
            "Mandrogne",
            "Manduria",
            "Manera",
            "Manerba del Garda",
            "Manerbio",
            "Manesseno",
            "Manfredonia",
            "Mango",
            "Mangone",
            "Maniace",
            "Maniago",
            "Manocalzati",
            "Manoppello",
            "Manoppello Scalo",
            "Mansuè",
            "Manta",
            "Mantegazza",
            "Mantello",
            "Mantiglia di Ardea",
            "Mantignana",
            "Mantignano-Ugnano",
            "Mantova",
            "Manzano",
            "Manziana",
            "Manzolino",
            "Mapello",
            "Mappano",
            "Mara",
            "Maracalagonis",
            "Maranello",
            "Marano",
            "Marano di Napoli",
            "Marano di Valpolicella",
            "Marano Equo",
            "Marano Lagunare",
            "Marano Marchesato",
            "Marano Principato",
            "Marano sul Panaro",
            "Marano Ticino",
            "Marano Vicentino",
            "Maranola-Trivio",
            "Maranzana",
            "Maratea",
            "Marausa",
            "Marcallo",
            "Marcaria",
            "Marcedusa",
            "Marcelli",
            "Marcellina",
            "Marcellina",
            "Marcellinara",
            "Marcetelli",
            "Marcheno",
            "Marchesino-Bovo",
            "Marchirolo",
            "Marciana",
            "Marciana Marina",
            "Marcianise",
            "Marciano",
            "Marciano della Chiana",
            "Marcignago",
            "Marcignana",
            "Marco",
            "Marco Simone",
            "Marcon-Gaggio-Colmello",
            "Marconia",
            "Mardimago",
            "Marene",
            "Mareno di Piave",
            "Marentino",
            "Maresso",
            "Maretto",
            "Margarita",
            "Margherita di Savoia",
            "Margine Coperta-Traversagna",
            "Margno",
            "Mariana Mantovana",
            "Mariano Comense",
            "Mariano del Friuli",
            "Marianopoli",
            "Mariglianella",
            "Marigliano",
            "Marina",
            "Marina di Altidona",
            "Marina di Andora",
            "Marina di Ardea-Tor San Lorenzo",
            "Marina di Camerota",
            "Marina di Campo",
            "Marina di Caronia",
            "Marina di Carrara",
            "Marina di Casal Velino",
            "Marina di Cerveteri",
            "Marina di Davoli",
            "Marina di Fuscaldo",
            "Marina di Ginosa",
            "Marina di Gioiosa Ionica",
            "Marina di Grosseto",
            "Marina di Massa",
            "Marina di Montemarciano",
            "Marina di Pisa",
            "Marina di Pisa-Tirrenia-Calambrone",
            "Marina di Ragusa",
            "Marina di Ravenna",
            "Marina di San Vito",
            "Marina di Schiavonea",
            "Marina di Vasto",
            "Marina Romea",
            "Marina San Nicola",
            "Marinella",
            "Marineo",
            "Marino",
            "Mariotto",
            "Marittima",
            "Marlengo",
            "Marliana",
            "Marmentino",
            "Marmirolo",
            "Marmora",
            "Marnate",
            "Marocchi",
            "Marola",
            "Marone",
            "Maropati",
            "Marostica",
            "Marotta",
            "Marradi",
            "Marrubiu",
            "Marsaglia",
            "Marsaglia",
            "Marsala",
            "Marsciano",
            "Marsia",
            "Marsico Nuovo",
            "Marsicovetere",
            "Marsure",
            "Marta",
            "Martano",
            "Martellago",
            "Martelli-Laganosa",
            "Marti",
            "Martignacco",
            "Martignana di Po",
            "Martignano",
            "Martina Franca",
            "Martinengo",
            "Martiniana Po",
            "Martinsicuro",
            "Martirano",
            "Martirano Lombardo",
            "Martis",
            "Martone",
            "Marudo",
            "Maruggio",
            "Marzabotto",
            "Marzanello",
            "Marzano",
            "Marzano Appio",
            "Marzano di Nola",
            "Marzi",
            "Marzio",
            "Masainas",
            "Masate",
            "Mascali",
            "Mascalucia",
            "Maschito",
            "Masciago Primo",
            "Maser",
            "Masera",
            "Maserà di Padova",
            "Maserada sul Piave",
            "Masi",
            "Masi-Torello",
            "Masio",
            "Maslianico",
            "Mason Vicentino",
            "Masone",
            "Massa",
            "Massa",
            "Massa d'Albe-Corona",
            "Massa di Somma",
            "Massa Fermana",
            "Massa Finalese",
            "Massa Fiscaglia",
            "Massa Lombarda",
            "Massa Lubrense",
            "Massa Marittima",
            "Massa Martana",
            "Massafra",
            "Massalengo-Motta Vigana",
            "Massanzago-Ca' Baglioni-San Dono",
            "Massarosa",
            "Massazza",
            "Massello",
            "Massenzatico",
            "Masserano",
            "Masseria Vecchia Ovest",
            "Massignano",
            "Massimeno",
            "Massimino",
            "Massino Visconti",
            "Massiola",
            "Masullas",
            "Matelica",
            "Matera",
            "Materdomini",
            "Mathi",
            "Matinella",
            "Matino",
            "Matrice",
            "Mattarello",
            "Mattie",
            "Mattinata",
            "Maugeri",
            "Mazara del Vallo",
            "Mazara II",
            "Mazzano",
            "Mazzano Romano",
            "Mazzarino",
            "Mazzarrà Sant'Andrea",
            "Mazzarrone",
            "Mazze",
            "Mazzin",
            "Mazzo di Valtellina",
            "Meana di Susa",
            "Meana Sardo",
            "Meano",
            "Meda",
            "Mede",
            "Medea",
            "Medesano",
            "Medicina-Buda",
            "Mediglia",
            "Mediis",
            "Medolago",
            "Medole",
            "Medolla",
            "Meduna di Livenza",
            "Meduno",
            "Meggiano",
            "Megliadino San Fidenzio",
            "Megliadino San Vitale",
            "Meiern",
            "Meina",
            "Mejaniga",
            "Mel",
            "Melara",
            "Melazzo",
            "Meldola",
            "Mele",
            "Meledo",
            "Melegnano",
            "Melendugno",
            "Meleti",
            "Melfi",
            "Melia",
            "Melicuccà",
            "Melicucco",
            "Melilli",
            "Melissa",
            "Melissano",
            "Melito di Napoli",
            "Melito di Porto Salvo",
            "Melito Irpino",
            "Melizzano",
            "Melle",
            "Mello",
            "Melpignano",
            "Meltina",
            "Melzo",
            "Menaggio",
            "Menarola",
            "Menconico",
            "Mendatica",
            "Mendicino",
            "Menfi",
            "Mentana",
            "Meolo",
            "Merana",
            "Merano",
            "Merate",
            "Mercallo",
            "Mercatale",
            "Mercatale",
            "Mercatale-San Quirico",
            "Mercatello sul Metauro",
            "Mercatino Conca",
            "Mercato",
            "Mercato San Severino",
            "Mercato Saraceno",
            "Mercato Vecchio",
            "Mercenasco",
            "Mercogliano",
            "Mereto di Tomba",
            "Mergo",
            "Mergozzo",
            "Merì",
            "Merine",
            "Merlara",
            "Merlino",
            "Merone",
            "Merso di Sopra",
            "Mesagne",
            "Mese",
            "Mesenzana",
            "Mesero",
            "Mesola",
            "Mesoraca",
            "Messignadi",
            "Messina",
            "Mestre",
            "Mestrino",
            "Meta",
            "Metato",
            "Meugliano",
            "Mezzago",
            "Mezzana",
            "Mezzana Bigli",
            "Mezzana Mortigliengo",
            "Mezzana Rabattone",
            "Mezzane di Sotto",
            "Mezzanego",
            "Mezzanino",
            "Mezzano",
            "Mezzano",
            "Mezzano Inferiore",
            "Mezzate",
            "Mezzegra",
            "Mezzenile",
            "Mezzocorona",
            "Mezzogoro",
            "Mezzojuso",
            "Mezzolara",
            "Mezzoldo",
            "Mezzolombardo",
            "Mezzomerico",
            "Miagliano",
            "Miane",
            "Miano",
            "Miasino",
            "Miazzina",
            "Micigliano",
            "Miggiano",
            "Miglianico",
            "Migliarina",
            "Migliarino",
            "Migliarino",
            "Migliaro",
            "Miglierina",
            "Miglionico",
            "Mignagola",
            "Mignanego",
            "Mignano Monte Lungo",
            "Milan",
            "Milanere",
            "Milano Marittima",
            "Milazzo",
            "Milena",
            "Mileto",
            "Milis",
            "Militello in Val di Catania",
            "Militello Rosmarino",
            "Millepini",
            "Millesimo",
            "Milo",
            "Milzano",
            "Mimosa-Poggio Verde-Nuova Comunità",
            "Mineo",
            "Minerbe",
            "Minerbio",
            "Minervino di Lecce",
            "Minervino Murge",
            "Minori",
            "Minturno",
            "Minucciano",
            "Mioglia",
            "Miola di Pinè",
            "Mira Taglio",
            "Mirabella Eclano",
            "Mirabella Imbaccari",
            "Mirabello",
            "Mirabello Monferrato",
            "Mirabello Sannitico",
            "Miradolo Terme",
            "Miranda",
            "Mirandola",
            "Mirano",
            "Mirto",
            "Mirto",
            "Misano Adriatico",
            "Misano di Gera d'Adda",
            "Misilmeri",
            "Misinto",
            "Missaglia",
            "Missanello",
            "Misterbianco",
            "Mistretta",
            "Moasca",
            "Moconesi",
            "Modena",
            "Modica",
            "Modigliana",
            "Modolo",
            "Modugno",
            "Moena",
            "Moggio",
            "Moggio di Sotto",
            "Moggio Udinese",
            "Moglia",
            "Mogliano",
            "Mogliano Veneto",
            "Mogorella",
            "Mogoro",
            "Moiano",
            "Moie",
            "Moimacco",
            "Moio Alcantara",
            "Moio de' Calvi",
            "Moio della Civitella-Pellare",
            "Moiola",
            "Mola di Bari",
            "Molare",
            "Molazzana",
            "Molella",
            "Molfetta",
            "Molina Aterno",
            "Molina di Ledro",
            "Molinara",
            "Molinella",
            "Molinetto",
            "Molini",
            "Molini di Triora",
            "Molino dei Torti",
            "Molino del Pallone",
            "Molino del Piano",
            "Molino Vecchio-Scapitola-Baio",
            "Molino-Mozzi-Bittarelli",
            "Molino-Pera",
            "Molise",
            "Moliterno",
            "Mollia",
            "Molochio",
            "Molteno",
            "Moltrasio",
            "Molveno",
            "Mombaldone",
            "Mombarcaro",
            "Mombaroccio",
            "Mombaruzzo",
            "Mombasiglio",
            "Mombelli",
            "Mombello di Torino",
            "Mombello Monferrato",
            "Mombercelli",
            "Mombretto",
            "Momo",
            "Mompantero",
            "Mompeo",
            "Momperone",
            "Monacilioni",
            "Monale",
            "Monasterace",
            "Monasterace Marina",
            "Monastero Bormida",
            "Monastero di Lanzo",
            "Monastero di Vasco",
            "Monasterolo",
            "Monasterolo",
            "Monasterolo Casotto",
            "Monasterolo del Castello",
            "Monasterolo di Savigliano",
            "Monastier di Treviso",
            "Monastir",
            "Moncalieri",
            "Moncalvo",
            "Moncenisio",
            "Moncestino",
            "Monchiero Borgonuovo",
            "Monchio delle Corti",
            "Monclassico",
            "Moncrivello",
            "Moncucco",
            "Moncucco Torinese",
            "Mondaino",
            "Mondavio",
            "Mondolfo",
            "Mondovì",
            "Mondragone",
            "Moneglia",
            "Monesiglio",
            "Monfalcone",
            "Monforte d'Alba",
            "Monforte San Giorgio",
            "Monfumo",
            "Mongardino",
            "Monghidoro",
            "Mongiana",
            "Mongiardino Ligure",
            "Mongiuffi Melia",
            "Mongnod",
            "Mongrando",
            "Mongrassano",
            "Monguelfo",
            "Moniego",
            "Moniga del Garda",
            "Monleale",
            "Monno",
            "Monopoli",
            "Monreale",
            "Monsampietro Morico",
            "Monsampolo del Tronto",
            "Monsano",
            "Monselice",
            "Monserrato",
            "Monsummano Terme",
            "Montà",
            "Montabone",
            "Montacuto",
            "Montafia",
            "Montagano",
            "Montagna",
            "Montagna in Valtellina",
            "Montagnana",
            "Montagnareale",
            "Montagne",
            "Montaguto",
            "Montaione",
            "Montalbano",
            "Montalbano",
            "Montalbano Elicona",
            "Montalbano Jonico",
            "Montalcino",
            "Montaldeo",
            "Montaldo Bormida",
            "Montaldo di Mondovì",
            "Montaldo Roero",
            "Montaldo Scarampi",
            "Montaldo Torinese",
            "Montale",
            "Montale",
            "Montalenghe",
            "Montallegro",
            "Montalto",
            "Montalto delle Marche",
            "Montalto di Castro",
            "Montalto Dora",
            "Montalto Ligure",
            "Montalto Pavese",
            "Montalto Uffugo",
            "Montan-Angelin-Arensod",
            "Montanara",
            "Montanaro",
            "Montanaso Lombardo",
            "Montanera",
            "Montano",
            "Montano Antilia",
            "Montano Lucino",
            "Montappone",
            "Montaquila",
            "Montariolo",
            "Montasola",
            "Montauro",
            "Montazzoli",
            "Monte",
            "Monte Argentario",
            "Monte Caminetto",
            "Monte Castello di Vibio",
            "Monte Cerignone",
            "Monte Colombo",
            "Monte Cremasco",
            "Monte di Malo",
            "Monte di Procida",
            "Monte Giberto",
            "Monte Grimano",
            "Monte Grimano Terme",
            "Monte Marenzo",
            "Monte Migliore La Selvotta",
            "Monte Porzio",
            "Monte Porzio Catone",
            "Monte Rinaldo",
            "Monte Roberto",
            "Monte Romano",
            "Monte San Biagio",
            "Monte San Giacomo",
            "Monte San Giovanni",
            "Monte San Giovanni Campano",
            "Monte San Giovanni in Sabina",
            "Monte San Giusto",
            "Monte San Marino",
            "Monte San Martino",
            "Monte San Pietrangeli",
            "Monte San Savino",
            "Monte San Vito",
            "Monte Sant'Angelo",
            "Monte Santa Maria Tiberina",
            "Monte Urano",
            "Monte Vidon Combatte",
            "Monte Vidon Corrado",
            "Montebello",
            "Montebello di Bertona",
            "Montebello Jonico",
            "Montebello sul Sangro",
            "Montebello Vicentino",
            "Montebelluna",
            "Montebonello",
            "Montebruno",
            "Montebuono",
            "Montecalvario",
            "Montecalvo in Foglia",
            "Montecalvo Irpino",
            "Montecalvo Versiggia",
            "Montecalvoli",
            "Montecarlo",
            "Montecarotto",
            "Montecassiano",
            "Montecastello",
            "Montecastrilli",
            "Montecatini Val di Cecina",
            "Montecatini-Terme",
            "Montecavolo",
            "Montecchia di Crosara",
            "Montecchio",
            "Montecchio Emilia",
            "Montecchio Maggiore-Alte Ceccato",
            "Montecchio Precalcino",
            "Montecelio",
            "Montechiaro d'Acqui",
            "Montechiaro d'Asti",
            "Montechiarugolo",
            "Monteciccardo",
            "Montecilfone",
            "Montecompatri",
            "Montecorice",
            "Montecorvino Pugliano",
            "Montecorvino Rovella",
            "Montecosaro",
            "Montecrestese",
            "Montecreto",
            "Montedecoro",
            "Montedinove",
            "Montedoro",
            "Montefalcione",
            "Montefalco",
            "Montefalcone Appennino",
            "Montefalcone di Val Fortore",
            "Montefalcone nel Sannio",
            "Montefano",
            "Montefelcino",
            "Monteferrante",
            "Montefiascone",
            "Montefino",
            "Montefiore Conca",
            "Montefiore dell'Aso",
            "Montefiorino",
            "Monteflavio",
            "Monteforte Cilento",
            "Monteforte d'Alpone",
            "Monteforte Irpino",
            "Montefortino",
            "Montefranco",
            "Montefredane",
            "Montefusco",
            "Montegabbione",
            "Montegalda",
            "Montegaldella",
            "Montegioco",
            "Montegiordano",
            "Montegiorgio",
            "Montegranaro",
            "Montegridolfo",
            "Montegrino Valtravaglia",
            "Montegrosso",
            "Montegrosso Pian Latte",
            "Montegrotto Terme",
            "Monteiasi",
            "Montelabbate",
            "Montelanico",
            "Montelapiano",
            "Montelarco",
            "Monteleone d'Orvieto",
            "Monteleone di Fermo",
            "Monteleone di Puglia",
            "Monteleone di Spoleto",
            "Monteleone Rocca Doria",
            "Monteleone Sabino",
            "Montelepre",
            "Montelera",
            "Montelibretti",
            "Montella",
            "Montello",
            "Montelongo",
            "Montelparo",
            "Montelupo Albese",
            "Montelupo Fiorentino",
            "Montelupone",
            "Montemaggiore al Metauro",
            "Montemaggiore Belsito",
            "Montemagno",
            "Montemale di Cuneo",
            "Montemarano",
            "Montemarciano",
            "Montemarzino",
            "Montemerlo",
            "Montemesola",
            "Montemezzo",
            "Montemignaio",
            "Montemiletto",
            "Montemilone",
            "Montemitro",
            "Montemonaco",
            "Montemurlo",
            "Montemurro",
            "Montenars",
            "Montenero di Bisaccia",
            "Montenero Sabino",
            "Montenero Val Cocchiara",
            "Montenerodomo",
            "Monteodorisio",
            "Montepaone",
            "Montepaone Lido",
            "Monteparano",
            "Monteprandone",
            "Montepulciano",
            "Montepulciano Stazione",
            "Monterado",
            "Monterchi",
            "Montereale",
            "Montereale Valcellina",
            "Monterenzio",
            "Monteriggioni",
            "Monteroduni",
            "Monteroni d'Arbia",
            "Monteroni di Lecce",
            "Monterosi",
            "Monterosso al Mare",
            "Monterosso Almo",
            "Monterosso Calabro",
            "Monterosso Grana",
            "Monterotondo",
            "Monterotondo Marittimo",
            "Monterubbiano",
            "Monterusciello",
            "Montesano Salentino",
            "Montesano sulla Marcellana",
            "Montesarchio",
            "Montesardo",
            "Montescaglioso",
            "Montescano",
            "Montescheno",
            "Montescudaio",
            "Montescudo",
            "Montese",
            "Montesegale",
            "Montesilvano Marina",
            "Montesolaro",
            "Montespertoli",
            "Monteu da Po",
            "Monteu Roero",
            "Montevago",
            "Montevarchi",
            "Montevecchia",
            "Monteverde",
            "Monteverdi Marittimo",
            "Monteviale",
            "Montezemolo",
            "Monti",
            "Monti",
            "Monti d'Arena-Bosco Caggione",
            "Montiano",
            "Monticchio",
            "Monticelli",
            "Monticelli Brusati",
            "Monticelli d'Ongina",
            "Monticelli Pavese",
            "Monticelli Terme",
            "Monticelli-Fontana",
            "Monticello",
            "Monticello Brianza",
            "Monticello Conte Otto",
            "Monticello d'Alba",
            "Monticello di Fara",
            "Montichiari",
            "Monticiano",
            "Montieri",
            "Montiglio",
            "Montignano-Marzocca",
            "Montignoso",
            "Montirone",
            "Montjovet",
            "Montodine",
            "Montoggio",
            "Montone",
            "Montopoli",
            "Montopoli in Sabina",
            "Montorfano",
            "Montorio",
            "Montorio al Vomano",
            "Montorio Nei Frentani",
            "Montorio Romano",
            "Montoro Superiore",
            "Montorso Vicentino",
            "Montottone",
            "Montresta",
            "Montù Beccaria",
            "Monvalle",
            "Monza",
            "Monzambano",
            "Monzuno",
            "Morano Calabro",
            "Morano sul Po",
            "Moransengo",
            "Moraro",
            "Morazzone",
            "Morbegno",
            "Morbello",
            "Morciano di Leuca",
            "Morciano di Romagna",
            "Morcone",
            "Mordano",
            "Morengo",
            "Mores",
            "Moresco",
            "Moretta",
            "Morfasso",
            "Morgex",
            "Morgongiori",
            "Mori",
            "Moriago della Battaglia",
            "Moricone",
            "Morigerati",
            "Morimondo",
            "Morino",
            "Moriondo Torinese",
            "Morlupo",
            "Mormanno",
            "Mornago",
            "Mornese",
            "Mornico",
            "Mornico al Serio",
            "Mornico Losana",
            "Morolo",
            "Morozzo",
            "Morra de Sanctis",
            "Morro d'Alba",
            "Morro d'Oro",
            "Morro Reatino",
            "Morrone del Sannio",
            "Morrovalle",
            "Morsano",
            "Morsasco",
            "Mortara",
            "Mortegliano",
            "Morterone",
            "Moruzzo",
            "Moscazzano",
            "Moscheri",
            "Moschiano",
            "Mosciano Sant'Angelo",
            "Moscufo",
            "Mosnigo",
            "Moso in Passiria",
            "Mosorrofa",
            "Mossa",
            "Mossano",
            "Mosso",
            "Mosso Santa Maria",
            "Motta",
            "Motta Baluffi",
            "Motta Camastra",
            "Motta d'Affermo",
            "Motta Dè Conti",
            "Motta di Livenza",
            "Motta Montecorvino",
            "Motta San Giovanni",
            "Motta Sant'Anastasia",
            "Motta Santa Lucia",
            "Motta Visconti",
            "Mottafollone",
            "Mottalciata",
            "Motteggiana",
            "Mottella",
            "Mottola",
            "Mozzagrogna",
            "Mozzanica",
            "Mozzate",
            "Mozzecane",
            "Mozzo",
            "Muccia",
            "Muggia",
            "Muggiò",
            "Mugnano del Cardinale",
            "Mugnano di Napoli",
            "Mühlen in Taufers",
            "Mulazzano",
            "Mulazzo",
            "Mulino",
            "Mura",
            "Murano",
            "Muravera",
            "Murazzano",
            "Mure",
            "Murello",
            "Murialdo",
            "Murisengo",
            "Murlo",
            "Muro Leccese",
            "Muro Lucano",
            "Muros",
            "Musano",
            "Musci",
            "Muscoline",
            "Musei",
            "Musestre",
            "Musile di Piave",
            "Musso",
            "Mussolente",
            "Mussomeli",
            "Mussotto",
            "Muzza di Cornegliano Laudense",
            "Muzzana del Turgnano",
            "Muzzano",
            "Nago-Torbole",
            "Nalles",
            "Nanno",
            "Naples",
            "Napoli",
            "Narbolia",
            "Narcao",
            "Nardò",
            "Nardodipace",
            "Narni",
            "Narni Scalo",
            "Naro",
            "Narzole",
            "Nasino-Borgo",
            "Naso",
            "Natile Nuovo",
            "Naturno",
            "Nava",
            "Nave",
            "Nave San Rocco",
            "Navedano",
            "Navelli",
            "Naz-Sciaves - Natz-Schabs",
            "Nazzano",
            "Nebbiuno",
            "Negrar",
            "Negrone",
            "Neirone",
            "Neive-Borgonovo",
            "Nembro",
            "Nemi",
            "Nemoli",
            "Neoneli",
            "Nepezzano",
            "Nepi",
            "Nereto",
            "Nerito",
            "Nerola",
            "Nervesa della Battaglia",
            "Nerviano",
            "Nespolo",
            "Nesso",
            "Netro",
            "Nettuno",
            "Neviano",
            "Neviano degli Arduini",
            "Neviglie",
            "Niardo",
            "Nibbiano",
            "Nibbiola",
            "Nibionno",
            "Nicastro",
            "Nichelino",
            "Nicolosi",
            "Nicorvo",
            "Nicosia",
            "Nicotera",
            "Niella Belbo",
            "Niella Tanaro",
            "Nigoline-Bonomelli",
            "Nimis",
            "Niscemi",
            "Nissoria",
            "Niviano",
            "Nizza di Sicilia",
            "Nizza Monferrato",
            "Noale",
            "Noasca",
            "Nobile-Monguzzo",
            "Nocara",
            "Nocciano",
            "Nocelleto",
            "Nocera Inferiore",
            "Nocera Scalo",
            "Nocera Superiore",
            "Nocera Terinese",
            "Nocera Umbra",
            "Noceto",
            "Noci",
            "Nociglia",
            "Noepoli",
            "Nogara",
            "Nogaredo",
            "Nogarole Rocca",
            "Nogarole Vicentino",
            "Noha",
            "Noicattaro",
            "Nola",
            "Nole",
            "Noli",
            "Nomaglio",
            "Nomi",
            "Nonantola",
            "None",
            "Nonio",
            "Noragugume",
            "Norbello",
            "Norcia",
            "Norma",
            "Nosadello",
            "Nosate",
            "Notaresco",
            "Noto",
            "Nova Levante",
            "Nova Milanese",
            "Nova Ponente",
            "Nova Siri",
            "Nova Siri Scalo",
            "Novafeltria",
            "Novagli",
            "Novaledo",
            "Novalesa",
            "Novara",
            "Novara di Sicilia",
            "Novate Mezzola",
            "Novate Milanese",
            "Nove",
            "Novedrate",
            "Novegro-Tregarezzo-San Felice",
            "Novellara",
            "Novello",
            "Noventa",
            "Noventa di Piave",
            "Noventa Vicentina",
            "Noverasco-Sporting Mirasole",
            "Novi di Modena",
            "Novi Ligure",
            "Novi Velia",
            "Noviglio",
            "Novoledo",
            "Novoli",
            "Nucetto",
            "Nucleo Industriale di Bazzano",
            "Nughedu San Nicolò",
            "Nughedu Santa Vittoria",
            "Nule",
            "Nulvi",
            "Numana",
            "Nunziata",
            "Nuoro",
            "Nuova Conza della Campania",
            "Nuova Gibellina",
            "Nuovo Centro Urbano Poggioreale",
            "Nurachi",
            "Nuragus",
            "Nurallao",
            "Nuraminis",
            "Nuraxinieddu",
            "Nureci",
            "Nurri",
            "Nus",
            "Nusco",
            "Nuvolento",
            "Nuvolera",
            "Nuxis",
            "Occagno",
            "Occhieppo Inferiore",
            "Occhieppo Superiore",
            "Occhiobello",
            "Occimiano",
            "Odalengo Grande",
            "Odalengo Piccolo",
            "Oderzo",
            "Odolo",
            "Ofena",
            "Offagna",
            "Offanengo",
            "Offida",
            "Offlaga",
            "Oggebbio",
            "Oggiona-Santo Stefano",
            "Oggiono",
            "Oglianico",
            "Ogliastro Cilento",
            "Olbia",
            "Olcenengo",
            "Oldenico",
            "Oleggio",
            "Oleggio Castello",
            "Olevano di Lomellina",
            "Olevano Romano",
            "Olevano sul Tusciano",
            "Olgiate Comasco",
            "Olgiate Molgora",
            "Olgiate Olona",
            "Olginate",
            "Olia Speciosa",
            "Oliena",
            "Oliva Gessi",
            "Olivadi",
            "Oliveri",
            "Oliveto Citra",
            "Oliveto Lucano",
            "Olivetta San Michele",
            "Olivola",
            "Ollastra",
            "Ollolai",
            "Ollomont",
            "Olmedo",
            "Olmeneta",
            "Olmo al Brembo",
            "Olmo Gentile",
            "Oltre Brenta",
            "Oltre Il Colle",
            "Oltressenda Alta",
            "Oltrona di San Mamette",
            "Olzai",
            "Ome",
            "Omegna",
            "Omignano",
            "Onanì",
            "Onano",
            "Oncino",
            "Onè",
            "Oneta",
            "Onifai",
            "Oniferi",
            "Onigo",
            "Ono San Pietro",
            "Onore",
            "Onzo",
            "Opera",
            "Opi",
            "Oppeano",
            "Oppido Lucano",
            "Oppido Mamertina",
            "Ora",
            "Orani",
            "Oratino",
            "Orbassano",
            "Orbetello",
            "Orbetello Scalo",
            "Orcenico Inferiore",
            "Orciano di Pesaro",
            "Orciano Pisano",
            "Orco Feglino",
            "Ordona",
            "Orentano",
            "Orero",
            "Orgiano",
            "Orgosolo",
            "Oria",
            "Oricola",
            "Origgio",
            "Orino",
            "Orio al Serio",
            "Orio Canavese",
            "Orio Litta",
            "Oriolo",
            "Oriolo Romano",
            "Oristano",
            "Ormea",
            "Ormelle",
            "Ornago",
            "Ornavasso",
            "Ornica",
            "Orosei",
            "Orotelli",
            "Orria",
            "Orroli",
            "Orsago",
            "Orsara Bormida",
            "Orsara di Puglia",
            "Orsaria",
            "Orsenigo",
            "Orsogna",
            "Orsomarso",
            "Orta di Atella",
            "Orta Nova",
            "Orta San Giulio",
            "Ortacesus",
            "Orte",
            "Orte Scalo",
            "Ortelle",
            "Ortezzano",
            "Ortignano Raggiolo",
            "Ortisei",
            "Ortona",
            "Ortona dei Marsi",
            "Ortovero",
            "Ortucchio",
            "Ortueri",
            "Orune",
            "Orvieto",
            "Orvieto Scalo",
            "Orvinio",
            "Orzignano",
            "Orzinuovi",
            "Orzivecchi",
            "Osasco",
            "Osasio",
            "Oscano",
            "Oschiri",
            "Osidda",
            "Osiglia",
            "Osigo",
            "Osilo",
            "Osimo",
            "Osini",
            "Osio Sopra",
            "Osio Sotto",
            "Osmate",
            "Osnago",
            "Osoppo",
            "Ospedaletti",
            "Ospedaletto",
            "Ospedaletto",
            "Ospedaletto",
            "Ospedaletto d'Alpinolo",
            "Ospedaletto Euganeo",
            "Ospedaletto Lodigiano",
            "Ospedalicchio",
            "Ospitale di Cadore",
            "Ospitaletto",
            "Ossago Lodigiano",
            "Ossana",
            "Ossi",
            "Ossimo Superiore",
            "Ossona",
            "Ossuccio",
            "Ostana",
            "Ostellato",
            "Osteno",
            "Osteno-Claino",
            "Osteria",
            "Osteria dei Cacciatori-Stella",
            "Osteria del Gatto",
            "Osteria Grande",
            "Osteria Nuova",
            "Osteria Nuova",
            "Ostiano",
            "Ostiglia",
            "Ostra",
            "Ostra Vetere",
            "Ostuni",
            "Otranto",
            "Otricoli",
            "Ottana",
            "Ottati",
            "Ottava",
            "Ottaviano",
            "Ottiglio",
            "Ottobiano",
            "Ottone",
            "Oulx",
            "Ovada",
            "Ovaro",
            "Oviglio",
            "Ovindoli",
            "Ovodda",
            "Oyace",
            "Ozegna",
            "Ozieri",
            "Ozzano dell'Emilia",
            "Ozzano Monferrato",
            "Ozzano Taro",
            "Ozzero",
            "Pabillonis",
            "Pace del Mela",
            "Paceco",
            "Pacentro",
            "Pachino",
            "Paciano",
            "Padenghe sul Garda",
            "Padergnone",
            "Paderna",
            "Padernello",
            "Paderno",
            "Paderno d'Adda",
            "Paderno del Grappa",
            "Paderno Dugnano",
            "Paderno Franciacorta",
            "Paderno Ponchielli",
            "Padiglione",
            "Padivarma",
            "Padova",
            "Padria",
            "Padru",
            "Padula",
            "Padule-San Marco",
            "Paduli",
            "Padulle",
            "Paesana",
            "Paese",
            "Pagani",
            "Paganica-Tempera",
            "Paganico Sabino",
            "Pagazzano",
            "Pagliara",
            "Pagliare",
            "Pagliare",
            "Pagliarelle",
            "Pagliarone",
            "Paglieta",
            "Pagnacco",
            "Pagno",
            "Pagnona",
            "Pago del Vallo di Lauro",
            "Pago Veiano",
            "Paisco Loveno",
            "Paitone",
            "Paladina",
            "Palagano",
            "Palagianello",
            "Palagiano",
            "Palagonia",
            "Palaia",
            "Palanzano",
            "Palata",
            "Palau",
            "Palazzago",
            "Palazzo",
            "Palazzo Adriano",
            "Palazzo Canavese",
            "Palazzo Pignano",
            "Palazzo San Gervasio",
            "Palazzolo",
            "Palazzolo Acreide",
            "Palazzolo dello Stella",
            "Palazzolo sull'Oglio",
            "Palazzolo Vercellese",
            "Palazzuolo sul Senio",
            "Palena",
            "Palermiti",
            "Palermo",
            "Palestrina",
            "Palestro",
            "Paliano",
            "Palidano",
            "Palinuro",
            "Palizzi",
            "Palizzi Marina",
            "Pallagorio",
            "Pallanza-Intra-Suna",
            "Pallanzeno",
            "Pallare",
            "Pallerone",
            "Palma Campania",
            "Palma di Montechiaro",
            "Palmanova",
            "Palmariggi",
            "Palmas Arborea",
            "Palmi",
            "Palmiano",
            "Palmoli",
            "Palo del Colle",
            "Palombaio",
            "Palombara Sabina",
            "Palombaro",
            "Palomonte",
            "Palosco",
            "Palù",
            "Palù del Fersina",
            "Paludea",
            "Paludi",
            "Paluzza",
            "Pamparato",
            "Pancalieri",
            "Pancarana",
            "Panchià",
            "Pandino",
            "Panettieri",
            "Panicale",
            "Panighina",
            "Pannaconi",
            "Pannarano",
            "Panni",
            "Pantalla",
            "Pantelleria",
            "Pantigliate",
            "Panzano in Chianti",
            "Paola",
            "Paolisi",
            "Paolo VI",
            "Papanice",
            "Papasidero",
            "Papozze",
            "Paquier",
            "Parabiago",
            "Parabita",
            "Paratico",
            "Paravati",
            "Parcines",
            "Parco Leonardo",
            "Parco Scizzo-Parchitello",
            "Parè",
            "Parella",
            "Parenti",
            "Parete",
            "Pareto",
            "Parghelia",
            "Parlasco",
            "Parma",
            "Parmezzana Calzana",
            "Parodi Ligure",
            "Paroldo",
            "Parolise",
            "Parona",
            "Parona",
            "Parrano",
            "Parre",
            "Partanna",
            "Partinico",
            "Paruzzaro",
            "Parzanica",
            "Pascarola",
            "Pasian di Prato",
            "Pasiano",
            "Paspardo",
            "Passaggio",
            "Passarella",
            "Passerano Marmorito",
            "Passignano sul Trasimeno",
            "Passirana",
            "Passirano",
            "Passo di Mirabella-Pianopantano",
            "Passo di Treia",
            "Passo Ripe",
            "Passons",
            "Passoscuro",
            "Pasta",
            "Pastena",
            "Pasteria-Lapide",
            "Pastorano",
            "Pastrengo",
            "Pasturana",
            "Pasturo",
            "Paterno",
            "Paternò",
            "Paterno Calabro",
            "Paternopoli",
            "Patigno",
            "Patrica",
            "Pattada",
            "Patti",
            "Patù",
            "Pau",
            "Paularo",
            "Pauli Arbarei",
            "Paulilatino",
            "Paullo",
            "Paupisi",
            "Pavarolo",
            "Pavia",
            "Pavia di Udine",
            "Pavigliana",
            "Pavona",
            "Pavone Canavese",
            "Pavone del Mella",
            "Pavullo nel Frignano",
            "Pazzano",
            "Peccioli",
            "Pecco",
            "Pecetto",
            "Pecetto di Valenza",
            "Pecorara",
            "Pedace-Perito",
            "Pedagaggi",
            "Pedalino",
            "Pedara",
            "Pedaso",
            "Pedavena",
            "Pedemonte",
            "Pedemonte",
            "Pederiva",
            "Pederobba",
            "Pedesina",
            "Pedivigliano",
            "Pedrengo",
            "Peglio",
            "Peglio",
            "Pegognaga",
            "Pegolotte",
            "Peia",
            "Pelago",
            "Pella",
            "Pellaro",
            "Pellegrino Parmense",
            "Pellestrina",
            "Pellezzano",
            "Pellio Intelvi",
            "Pellizzano",
            "Pelugo",
            "Penango",
            "Pendino",
            "Penitro",
            "Penna in Teverina",
            "Penna San Giovanni",
            "Penna Sant'Andrea",
            "Pennabilli",
            "Pennadomo",
            "Pennapiedimonte",
            "Penne",
            "Pennisi",
            "Pentone",
            "Perano",
            "Perarolo di Cadore",
            "Perca",
            "Percile",
            "Percoto",
            "Perdasdefogu",
            "Perdaxius",
            "Perdifumo",
            "Perego",
            "Pereto",
            "Perfugas",
            "Pergine Valdarno",
            "Pergine Valsugana",
            "Pergola",
            "Perinaldo",
            "Perito",
            "Perledo",
            "Perletto",
            "Perlo",
            "Perloz",
            "Pernate",
            "Pernocari-Presinaci",
            "Pernumia",
            "Pero",
            "Pero",
            "Perosa Argentina",
            "Perosa Canavese",
            "Perrero",
            "Persico Dosimo",
            "Pertegada",
            "Pertengo",
            "Pertica Alta",
            "Pertica Bassa",
            "Pertosa",
            "Pertusio",
            "Perugia",
            "Pesaro",
            "Pescaglia",
            "Pescantina",
            "Pescara",
            "Pescarolo",
            "Pescasseroli",
            "Pescate",
            "Pesche",
            "Peschici",
            "Peschiera del Garda",
            "Pescia",
            "Pescia Romana",
            "Pescina",
            "Pesco Sannita",
            "Pescocostanzo",
            "Pescolanciano",
            "Pescopagano",
            "Pescopennataro",
            "Pescorocchiano",
            "Pescosansonesco Nuovo",
            "Pescosolido",
            "Peseggia-Gardigiano",
            "Pessano Con Bornago",
            "Pessina Cremonese",
            "Pessinetto",
            "Pessione",
            "Petacciato",
            "Petilia Policastro",
            "Petina",
            "Petit Fenis",
            "Petralia Soprana",
            "Petralia Sottana",
            "Petrella Salto",
            "Petrella Tifernina",
            "Petriano",
            "Petrignano",
            "Petriolo",
            "Petritoli",
            "Petrizzi",
            "Petronà",
            "Petrosino",
            "Petruro Irpino",
            "Pettenasco",
            "Pettinengo",
            "Pettineo",
            "Pettoranello del Molise",
            "Pettorano sul Gizio",
            "Pettorazza Grimani",
            "Peveragno",
            "Pezzan",
            "Pezzana",
            "Pezzano-Filetta",
            "Pezzaze",
            "Pezze di Greco",
            "Pezzolo Valle Uzzone",
            "Piacenza",
            "Piacenza d'Adige",
            "Piadena",
            "Piagge",
            "Piaggine",
            "Piamborno",
            "Pian Camuno",
            "Pian di Mugnone",
            "Pian di Scò",
            "Piana Battolla",
            "Piana Crixia",
            "Piana degli Albanesi",
            "Piana di Monte Verna",
            "Piana San Raffaele",
            "Pianazzo",
            "Piancastagnaio",
            "Piandimeleto",
            "Piane",
            "Piane Crati",
            "Piane di Montegiorgio",
            "Piane di Morro",
            "Pianella",
            "Pianello",
            "Pianello del Lario",
            "Pianello Val Tidone",
            "Pianello Vallesina",
            "Pianengo",
            "Pianezza",
            "Pianezze",
            "Pianfei",
            "Piangaiano",
            "Piangipane",
            "Pianico",
            "Pianiga",
            "Pianillo",
            "Piano",
            "Piano",
            "Piano dei Geli",
            "Piano di Conca",
            "Piano di Coreglia-Ghivizzano",
            "Piano di Follo",
            "Piano di Mommio",
            "Piano di Sorrento",
            "Piano Maglio-Blandino",
            "Piano-Molini d'Isola",
            "Pianoconte",
            "Pianola",
            "Pianopoli",
            "Pianoro",
            "Piansano",
            "Piantedo",
            "Pianura",
            "Pianura Vomano",
            "Piario",
            "Piasco",
            "Piateda Centro",
            "Piatto",
            "Piattoni-Villa Sant'Antonio",
            "Piavon",
            "Piazza",
            "Piazza",
            "Piazza",
            "Piazza",
            "Piazza al Serchio-San Michele",
            "Piazza Armerina",
            "Piazza Brembana",
            "Piazza Caduti",
            "Piazza del Galdo-Sant'Angelo",
            "Piazza di Pandola",
            "Piazza Roma",
            "Piazza-Tralia-Pendolo",
            "Piazzatorre",
            "Piazzola",
            "Piazzola sul Brenta",
            "Piazzolla",
            "Piazzolo",
            "Piccarello",
            "Picciano",
            "Picerno",
            "Picinisco",
            "Pico",
            "Pie' del Colle",
            "Pie' del Sasso",
            "Pie' Falcade",
            "Piea",
            "Piedicavallo",
            "Piedimonte",
            "Piedimonte Etneo",
            "Piedimonte Matese",
            "Piedimonte San Germano",
            "Piedimonte San Germano Alta",
            "Piedimulera",
            "Piediripa",
            "Piegaro",
            "Piegolelle-San Bartolomeo",
            "Pienza",
            "Pieranica",
            "Pieria-Prato Carnico",
            "Pieris",
            "Pietra de' Giorgi",
            "Pietra la Croce",
            "Pietra Ligure",
            "Pietra Marazzi",
            "Pietrabbondante",
            "Pietrabruna",
            "Pietracamela",
            "Pietracatella",
            "Pietracupa",
            "Pietracuta",
            "Pietradefusi",
            "Pietraferrazzana",
            "Pietrafitta",
            "Pietragalla",
            "Pietralunga",
            "Pietramelara",
            "Pietramontecorvino",
            "Pietramurata",
            "Pietranico",
            "Pietrapaola",
            "Pietrapertosa",
            "Pietraperzia",
            "Pietraporzio",
            "Pietraroja",
            "Pietrasanta",
            "Pietrastornina",
            "Pietravairano",
            "Pietre",
            "Pietrelcina",
            "Pieve",
            "Pieve",
            "Pieve a Nievole",
            "Pieve al Toppo",
            "Pieve Albignola",
            "Pieve d'Alpago",
            "Pieve d'Olmi",
            "Pieve del Cairo",
            "Pieve di Bono",
            "Pieve di Cadore",
            "Pieve di Cento",
            "Pieve di Coriano",
            "Pieve di Ledro",
            "Pieve di Soligo",
            "Pieve di Teco",
            "Pieve di Zignago",
            "Pieve Emanuele",
            "Pieve Fissiraga",
            "Pieve Fosciana",
            "Pieve Ligure",
            "Pieve Porto Morone",
            "Pieve San Giacomo",
            "Pieve Santo Stefano",
            "Pieve Tesino",
            "Pieve Torina",
            "Pieve Vergonte",
            "Pievebovigliana",
            "Pievedizio",
            "Pievepelago",
            "Piglio",
            "Pigna",
            "Pignataro Interamna",
            "Pignataro Maggiore",
            "Pignola",
            "Pignone",
            "Pigra",
            "Pila",
            "Pila",
            "Pilastro",
            "Pimentel",
            "Pimonte",
            "Pinarella",
            "Pinarolo Po",
            "Pinasca-Dubbione",
            "Pincara",
            "Pinerolo",
            "Pineta",
            "Pineto",
            "Pino d'Asti",
            "Pino sulla Sponda del Lago Maggiore",
            "Pino Torinese",
            "Pinocchio di Ancona",
            "Pinzano al Tagliamento",
            "Pinzolo",
            "Piobbico",
            "Piobesi d'Alba",
            "Piobesi Torinese",
            "Piode",
            "Pioltello",
            "Piombino",
            "Piombino Dese",
            "Pionca",
            "Pioppo",
            "Pioraco",
            "Piossasco",
            "Piovà Massaia",
            "Piove di Sacco-Piovega",
            "Piovene Rocchette",
            "Piovera",
            "Piozzano",
            "Piozzo",
            "Piraino",
            "Pisa",
            "Pisano",
            "Piscina",
            "Piscinas",
            "Piscinola",
            "Pisciotta",
            "Piscopio",
            "Pisignano",
            "Pisignano",
            "Pisogne",
            "Pisoniano",
            "Pisticci",
            "Pistoia",
            "Pistrino",
            "Piteglio",
            "Pitelli",
            "Pitigliano",
            "Piubega",
            "Piumazzo",
            "Piuro",
            "Piverone",
            "Pizzale",
            "Pizzano",
            "Pizzighettone",
            "Pizzo",
            "Pizzoferrato",
            "Pizzoli",
            "Pizzone",
            "Pizzoni",
            "Placanica",
            "Plan d'Introd",
            "Plataci",
            "Platania",
            "Platì",
            "Plaus",
            "Plesio",
            "Pleyne",
            "Ploaghe",
            "Plodio",
            "Poasco-Sorigherio",
            "Pocapaglia",
            "Pocenia",
            "Podenzana",
            "Podenzano",
            "Pofi",
            "Poggetto",
            "Poggiardo",
            "Poggibonsi",
            "Poggio A Caiano",
            "Poggio Berni",
            "Poggio Bustone",
            "Poggio Catino",
            "Poggio dei Pini",
            "Poggio Ellera",
            "Poggio Imperiale",
            "Poggio Mirteto",
            "Poggio Moiano",
            "Poggio Nativo",
            "Poggio Picenze",
            "Poggio Renatico",
            "Poggio Rusco",
            "Poggio San Lorenzo",
            "Poggio San Marcello",
            "Poggio San Vicino",
            "Poggio Sannita",
            "Poggiodomo",
            "Poggiofiorito",
            "Poggiomarino",
            "Poggioreale",
            "Poggiorsini",
            "Poggiridenti Alto",
            "Poggiridenti Piano",
            "Pogliano Milanese",
            "Pognana Lario",
            "Pognano",
            "Pogno",
            "Poiana Maggiore",
            "Poianella",
            "Poiano",
            "Poirino",
            "Polaveno",
            "Polcenigo",
            "Polesella",
            "Polesine Parmense",
            "Poli",
            "Polia",
            "Policastro Bussentino",
            "Policoro",
            "Polignano a Mare",
            "Polinago",
            "Polino",
            "Polistena",
            "Polizzi Generosa",
            "Polla",
            "Pollein",
            "Pollena Trocchia",
            "Pollenza",
            "Pollica",
            "Pollina",
            "Pollone",
            "Pollutri",
            "Polonghera",
            "Polpenazze del Garda",
            "Polverara",
            "Polverigi",
            "Polvica",
            "Pomarance",
            "Pomaretto",
            "Pomarico",
            "Pomaro Monferrato",
            "Pomarolo",
            "Pombia",
            "Pometo",
            "Pomezia",
            "Pomigliano d'Arco",
            "Pompei",
            "Pompeiana",
            "Pompiano",
            "Pomponesco",
            "Pompu",
            "Poncarale",
            "Ponderano",
            "Ponna Superiore",
            "Ponsacco",
            "Ponso",
            "Pont-Bozet",
            "Pont-Canavese",
            "Pont-Saint-Martin",
            "Pontasserchio",
            "Pontassieve",
            "Ponte",
            "Ponte A Elsa",
            "Ponte a Poppi",
            "Ponte A Tressa",
            "Ponte Arche",
            "Ponte Buggianese",
            "Ponte Caffaro",
            "Ponte Cingoli",
            "Ponte dell'Olio",
            "Ponte della Venturina",
            "Ponte di Barbarano",
            "Ponte di Castegnero",
            "Ponte di Legno",
            "Ponte di Nanto",
            "Ponte di Piave",
            "Ponte Felcino",
            "Ponte Galeria-La Pisana",
            "Ponte Gardena",
            "Ponte in Valtellina",
            "Ponte Lambro",
            "Ponte nelle Alpi",
            "Ponte nelle Alpi-Polpet",
            "Ponte Nizza",
            "Ponte Nossa",
            "Ponte Pattoli",
            "Ponte Ronca",
            "Ponte San Marco",
            "Ponte San Nicolò",
            "Ponte San Pietro",
            "Ponte Taro",
            "Pontebba",
            "Pontecagnano",
            "Pontecchio Polesine",
            "Pontechianale",
            "Pontecorvo",
            "Pontecurone",
            "Pontedassio",
            "Pontedera",
            "Pontegradella",
            "Pontelandolfo",
            "Pontelangorino",
            "Pontelatone",
            "Pontelongo",
            "Pontenure",
            "Ponteranica",
            "Ponterio-Pian di Porto",
            "Pontestazzemese",
            "Pontestura",
            "Pontevico",
            "Pontey",
            "Ponti",
            "Ponti sul Mincio",
            "Ponticella",
            "Ponticelli",
            "Ponticino",
            "Pontida",
            "Pontinia",
            "Pontinvrea",
            "Pontirolo Nuovo",
            "Pontoglio",
            "Ponton dell'Elce",
            "Pontremoli",
            "Ponza",
            "Ponzano",
            "Ponzano di Fermo",
            "Ponzano Monferrato",
            "Ponzano Romano",
            "Ponzone",
            "Popoli",
            "Poppi",
            "Porano",
            "Porcari",
            "Porcellengo",
            "Porcia",
            "Pordenone",
            "Porlezza",
            "Pornassio",
            "Porotto-Cassama",
            "Porpetto",
            "Porporano",
            "Porretta Terme",
            "Portacomaro",
            "Portalbera",
            "Porte",
            "Portella di Mare",
            "Portici",
            "Portico di Caserta",
            "Portico di Romagna",
            "Portico e San Benedetto",
            "Portigliola",
            "Porto",
            "Porto Azzurro",
            "Porto Ceresio",
            "Porto Cervo",
            "Porto Cesareo",
            "Porto Corsini",
            "Porto d'Adda",
            "Porto Empedocle",
            "Porto Ercole",
            "Porto Fuori",
            "Porto Garibaldi",
            "Porto Potenza Picena",
            "Porto Recanati",
            "Porto San Giorgio",
            "Porto Sant'Elpidio",
            "Porto Santo Stefano",
            "Porto Tolle",
            "Porto Torres",
            "Porto Valtravaglia",
            "Porto Viro",
            "Portobuffolè",
            "Portocannone",
            "Portoferraio",
            "Portofino",
            "Portogruaro",
            "Portomaggiore",
            "Portopalo di Capo Passero",
            "Portoscuso",
            "Portovenere",
            "Portula",
            "Posada",
            "Posatora",
            "Posillipo",
            "Posina",
            "Positano",
            "Possagno",
            "Posta",
            "Posta Fibreno",
            "Postal",
            "Postalesio",
            "Postiglione",
            "Postioma",
            "Postua",
            "Potenza",
            "Potenza Picena",
            "Pove del Grappa",
            "Povegliano",
            "Povegliano Veronese",
            "Poviglio",
            "Povo",
            "Povoletto",
            "Pozza",
            "Pozza di Fassa",
            "Pozzaglia Sabino",
            "Pozzaglio",
            "Pozzaglio ed Uniti",
            "Pozzale-Case Nuove",
            "Pozzallo",
            "Pozzilli",
            "Pozzillo",
            "Pozzo d'Adda",
            "Pozzol Groppo",
            "Pozzolengo",
            "Pozzoleone",
            "Pozzolo Formigaro",
            "Pozzomaggiore",
            "Pozzonovo",
            "Pozzuoli",
            "Pozzuolo",
            "Pozzuolo del Friuli",
            "Pozzuolo Martesana",
            "Pradalunga",
            "Pradamano",
            "Pradelle",
            "Pradleves",
            "Pragelato-Ruà",
            "Praia a Mare",
            "Praiano",
            "Pralboino",
            "Prali",
            "Pralormo",
            "Pralungo",
            "Pramaggiore",
            "Pramaggiore Blessaglia",
            "Pramollo",
            "Prarolo",
            "Prarostino",
            "Prasco",
            "Prascorsano",
            "Praso",
            "Prata Camportaccio",
            "Prata Centro",
            "Prata d'Ansidonia",
            "Prata di Pordenone",
            "Prata di Principato Ultra",
            "Prata Sannita Centro",
            "Pratantico-Indicatore",
            "Pratella",
            "Prati",
            "Prati",
            "Praticello",
            "Pratiglione",
            "Pratissolo",
            "Prato",
            "Prato",
            "Prato",
            "Prato Allo Stelvio",
            "Prato Carnico",
            "Prato di Coppola",
            "Prato Perillo",
            "Prato Sesia",
            "Pratola Peligna",
            "Pratola Serra",
            "Pratole",
            "Pratovecchio",
            "Pravisdomini",
            "Pray",
            "Prazzo Superiore",
            "Prè Saint Didier",
            "Preara-Moraro-Levà Nord",
            "Precenicco",
            "Preci",
            "Predappio",
            "Predazzo",
            "Predoi",
            "Predore",
            "Predosa",
            "Preganziol",
            "Pregnana Milanese",
            "Pregola",
            "Prelà Castello",
            "Premana",
            "Premariacco",
            "Premeno",
            "Premenugo",
            "Premia",
            "Premilcuore",
            "Premolo",
            "Premosello-Chiovenda",
            "Preone",
            "Preore",
            "Prepezzano",
            "Prepotto",
            "Preseglie",
            "Presenzano",
            "Presezzo",
            "Presicce",
            "Pressana",
            "Pretoro",
            "Preturo",
            "Prevalle",
            "Prezza",
            "Prezzo",
            "Priero",
            "Prignano",
            "Prignano Cilento",
            "Prignano sulla Secchia",
            "Primaluna",
            "Priocca",
            "Priola",
            "Priolo Gargallo",
            "Priula-Colfosco",
            "Priverno",
            "Prizzi",
            "Proceno",
            "Procida",
            "Progetto Case Bazzano",
            "Progetto Case Coppito 3",
            "Progetto Case Sassa Nsi",
            "Progresso",
            "Propata",
            "Prosecco-Contovello",
            "Proserpio",
            "Prossedi",
            "Prosto",
            "Provaglio d'Iseo",
            "Provaglio Val Sabbia",
            "Proves - Proveis",
            "Provezze",
            "Province of Arezzo",
            "Province of Fermo",
            "Province of Florence",
            "Province of Pisa",
            "Provincia autonoma di Trento",
            "Provincia dell' Aquila",
            "Provincia di Alessandria",
            "Provincia di Ancona",
            "Provincia di Ascoli Piceno",
            "Provincia di Asti",
            "Provincia di Avellino",
            "Provincia di Barletta - Andria - Trani",
            "Provincia di Belluno",
            "Provincia di Benevento",
            "Provincia di Bergamo",
            "Provincia di Biella",
            "Provincia di Brescia",
            "Provincia di Brindisi",
            "Provincia di Cagliari",
            "Provincia di Caltanissetta",
            "Provincia di Campobasso",
            "Provincia di Caserta",
            "Provincia di Catanzaro",
            "Provincia di Chieti",
            "Provincia di Como",
            "Provincia di Cosenza",
            "Provincia di Cremona",
            "Provincia di Crotone",
            "Provincia di Cuneo",
            "Provincia di Ferrara",
            "Provincia di Foggia",
            "Provincia di Forlì-Cesena",
            "Provincia di Frosinone",
            "Provincia di Genova",
            "Provincia di Gorizia",
            "Provincia di Grosseto",
            "Provincia di Imperia",
            "Provincia di Isernia",
            "Provincia di La Spezia",
            "Provincia di Latina",
            "Provincia di Lecce",
            "Provincia di Lecco",
            "Provincia di Livorno",
            "Provincia di Lodi",
            "Provincia di Lucca",
            "Provincia di Macerata",
            "Provincia di Mantova",
            "Provincia di Massa-Carrara",
            "Provincia di Matera",
            "Provincia di Modena",
            "Provincia di Monza e della Brianza",
            "Provincia di Novara",
            "Provincia di Nuoro",
            "Provincia di Oristano",
            "Provincia di Padova",
            "Provincia di Parma",
            "Provincia di Pavia",
            "Provincia di Perugia",
            "Provincia di Pesaro e Urbino",
            "Provincia di Pescara",
            "Provincia di Piacenza",
            "Provincia di Pistoia",
            "Provincia di Pordenone",
            "Provincia di Potenza",
            "Provincia di Prato",
            "Provincia di Ravenna",
            "Provincia di Reggio Calabria",
            "Provincia di Reggio Emilia",
            "Provincia di Rieti",
            "Provincia di Rimini",
            "Provincia di Rovigo",
            "Provincia di Salerno",
            "Provincia di Sassari",
            "Provincia di Savona",
            "Provincia di Siena",
            "Provincia di Siracusa",
            "Provincia di Sondrio",
            "Provincia di Taranto",
            "Provincia di Teramo",
            "Provincia di Terni",
            "Provincia di Treviso",
            "Provincia di Trieste",
            "Provincia di Udine",
            "Provincia di Varese",
            "Provincia di Venezia",
            "Provincia di Vercelli",
            "Provincia di Verona",
            "Provincia di Vibo-Valentia",
            "Provincia di Vicenza",
            "Provincia di Viterbo",
            "Provincia Verbano-Cusio-Ossola",
            "Provvidenti",
            "Prunetto",
            "Puegnago sul Garda",
            "Puginate",
            "Puglianello",
            "Pugliano",
            "Puia-Villanova",
            "Puianello",
            "Pula",
            "Pulce",
            "Pulfero",
            "Pulsano",
            "Pumenengo",
            "Punta Marina",
            "Puos d'Alpago",
            "Pusiano",
            "Putifigari",
            "Putignano",
            "Quaderni",
            "Quadrelle",
            "Quadri",
            "Quadrivio",
            "Quagliuzzo",
            "Qualiano",
            "Quaranti",
            "Quarantoli",
            "Quarata",
            "Quaregna",
            "Quargnento",
            "Quarna Sopra",
            "Quarna Sotto",
            "Quarona",
            "Quarrata",
            "Quart",
            "Quartesana",
            "Quartiano",
            "Quarto",
            "Quarto d'Altino",
            "Quarto Inferiore",
            "Quartu Sant'Elena",
            "Quartucciu",
            "Quasano",
            "Quassolo",
            "Quattordio",
            "Quattro Castella",
            "Quattro Strade",
            "Quattro Strade",
            "Quattromiglia",
            "Quercianella",
            "Quero",
            "Quiliano",
            "Quincinetto",
            "Quincod",
            "Quindici",
            "Quingentole",
            "Quintano",
            "Quinto di Treviso",
            "Quinto Vercellese",
            "Quinto Vicentino",
            "Quinzano d'Oglio",
            "Quistello",
            "Rabbi Fonti",
            "Rablà",
            "Racale",
            "Racalmuto",
            "Racconigi",
            "Raccuja",
            "Racines",
            "Racines - Ratschings",
            "Radda in Chianti",
            "Raddusa",
            "Radicofani",
            "Radicondoli",
            "Raffa",
            "Raffadali",
            "Ragalna",
            "Ragoli",
            "Ragusa",
            "Raiano",
            "Raldon",
            "Ramacca",
            "Ramiola",
            "Ramiseto",
            "Ramon",
            "Ramponio Verna",
            "Ramuscello",
            "Rancio Valcuvia",
            "Ranco",
            "Randazzo",
            "Ranica",
            "Ranzanico",
            "Rapagnano",
            "Rapallo",
            "Rapino",
            "Rapolano Terme",
            "Rapolla",
            "Rapone",
            "Rassa",
            "Rassina",
            "Rastignano-Carteria di Sesto",
            "Rasun Anterselva - Rasen-Antholz",
            "Rasura",
            "Rauscedo-Domanins",
            "Ravanusa",
            "Ravarino",
            "Ravascletto",
            "Ravello",
            "Ravenna",
            "Raveo",
            "Ravina",
            "Raviscanina",
            "Re",
            "Rea",
            "Realmonte",
            "Reana del Roiale",
            "Reana del Rojale",
            "Reano",
            "Recale",
            "Recanati",
            "Recco",
            "Recetto",
            "Recoaro Terme",
            "Redavalle",
            "Redondesco",
            "Refrancore",
            "Refrontolo",
            "Regalbuto",
            "Reggello",
            "Reggio Calabria",
            "Reggio nell'Emilia",
            "Reggiolo",
            "Regoledo",
            "Regolelli",
            "Reino",
            "Reitano",
            "Remanzacco",
            "Remedello",
            "Remedello di Sopra",
            "Renate",
            "Renazzo",
            "Rende",
            "Renon - Ritten",
            "Resana",
            "Rescaldina",
            "Resiutta",
            "Resuttano",
            "Retorbido",
            "Rettifilo-Vannullo",
            "Revello",
            "Revere",
            "Revigliasco",
            "Revigliasco d'Asti",
            "Revine",
            "Revò",
            "Rezzago",
            "Rezzato",
            "Rezzo",
            "Rezzoaglio",
            "Rhemes-Notre-Dame",
            "Rhemes-Saint-Georges",
            "Rho",
            "Riace",
            "Riace Marina",
            "Riale",
            "Rialto",
            "Riano",
            "Riardo",
            "Ribera",
            "Ribolla",
            "Ribordone",
            "Ricadi",
            "Ricaldone",
            "Ricca",
            "Riccia",
            "Riccione",
            "Riccò",
            "Riccò del Golfo",
            "Ricengo",
            "Ricigliano",
            "Ried",
            "Riese Pio X",
            "Riesi",
            "Rieti",
            "Rifiano",
            "Rifreddo",
            "Rignano Flaminio",
            "Rignano Garganico",
            "Rignano sull'Arno",
            "Rigolato",
            "Rilievo",
            "Rima",
            "Rimasco",
            "Rimella",
            "Rimini",
            "Rio di Pusteria",
            "Rio Marina",
            "Rio nell'Elba",
            "Rio Saliceto",
            "Rio Salso-Case Bernardi",
            "Riofreddo",
            "Riola Sardo",
            "Riolo Terme",
            "Riolunato",
            "Riomaggiore",
            "Rionero in Vulture",
            "Rionero Sannitico",
            "Riozzo",
            "Ripa Teatina",
            "Ripa-Pozzi-Querceta-Ponterosso",
            "Ripabottoni",
            "Ripacandida",
            "Ripalimosani",
            "Ripalta Arpina",
            "Ripalta Guerina",
            "Ripalta Nuova",
            "Riparbella",
            "Ripatransone",
            "Ripe",
            "Ripe San Ginesio",
            "Ripi",
            "Riposto",
            "Riscone",
            "Rittana",
            "Riva",
            "Riva del Garda",
            "Riva di Solto",
            "Riva Ligure",
            "Riva Presso Chieri",
            "Riva Valdobbia",
            "Rivalba",
            "Rivalta",
            "Rivalta Bormida",
            "Rivalta di Torino",
            "Rivamonte Agordino",
            "Rivanazzano",
            "Rivara",
            "Rivarolo Canavese",
            "Rivarolo del Re",
            "Rivarolo Mantovano",
            "Rivarone",
            "Rivarossa",
            "Rivazzurra",
            "Rive",
            "Rive d'Arcano",
            "Rivello",
            "Rivergaro",
            "Rivignano",
            "Rivisondoli",
            "Rivodutri",
            "Rivoli",
            "Rivoli Veronese",
            "Rivolta d'Adda",
            "Rivotorto",
            "Rizziconi",
            "Ro",
            "Roana",
            "Roapiana",
            "Roaschia",
            "Roascio",
            "Roasio",
            "Roata Rossi",
            "Roatto",
            "Robassomero",
            "Robbiate",
            "Robbio",
            "Robecchetto Con Induno",
            "Robecco d'Oglio",
            "Robecco Pavese",
            "Robecco sul Naviglio",
            "Robegano",
            "Robella",
            "Robilante",
            "Roburent",
            "Rocca Canavese",
            "Rocca Canterano",
            "Rocca Cigliè",
            "Rocca d'Arazzo",
            "Rocca d'Arce",
            "Rocca De' Baldi",
            "Rocca di Botte",
            "Rocca di Cambio",
            "Rocca di Capri Leone",
            "Rocca di Cave",
            "Rocca di Mezzo",
            "Rocca di Neto",
            "Rocca di Papa",
            "Rocca Grimalda",
            "Rocca Imperiale",
            "Rocca Imperiale Marina",
            "Rocca Massima",
            "Rocca Pia",
            "Rocca Pietore",
            "Rocca Priora",
            "Rocca San Casciano",
            "Rocca San Felice",
            "Rocca San Giovanni",
            "Rocca Santo Stefano",
            "Rocca Sinibalda",
            "Roccabascerana",
            "Roccabernarda",
            "Roccabianca",
            "Roccabruna",
            "Roccacasale",
            "Roccadaspide",
            "Roccafiorita",
            "Roccaforte del Greco",
            "Roccaforte Ligure",
            "Roccaforte Mondovì",
            "Roccaforzata",
            "Roccafranca",
            "Roccagiovine",
            "Roccagloriosa",
            "Roccagorga",
            "Roccalbegna",
            "Roccalumera",
            "Roccamandolfi",
            "Roccamena",
            "Roccamonfina",
            "Roccamorice",
            "Roccanova",
            "Roccantica",
            "Roccapalumba",
            "Roccapiemonte",
            "Roccarainola",
            "Roccaraso",
            "Roccaromana",
            "Roccascalegna",
            "Roccasecca",
            "Roccasecca dei Volsci",
            "Roccasecca Stazione",
            "Roccasicura",
            "Roccasparvera",
            "Roccaspinalveti-Santa Giusta",
            "Roccastrada",
            "Roccavaldina",
            "Roccaverano",
            "Roccavignale",
            "Roccavione",
            "Roccavivara",
            "Roccavivi",
            "Roccella Ionica",
            "Roccella Valdemone",
            "Roccelletta",
            "Rocchetta",
            "Rocchetta a Volturno",
            "Rocchetta Belbo",
            "Rocchetta di Vara",
            "Rocchetta Ligure",
            "Rocchetta Nervina",
            "Rocchetta Nuova",
            "Rocchetta Palafea",
            "Rocchetta Sant'Antonio",
            "Rocchetta Tanaro",
            "Roddi",
            "Roddino",
            "Rodeano",
            "Rodello",
            "Rodengo",
            "Rodengo - Rodeneck",
            "Rodengo-Saiano",
            "Rodero",
            "Rodì",
            "Rodi Garganico",
            "Rodigo",
            "Roè",
            "Roè Volciano",
            "Rofrano",
            "Rogeno",
            "Roggiano Gravina",
            "Roggione",
            "Roghudi",
            "Rogliano",
            "Rognano",
            "Rogno",
            "Rogolo",
            "Rogoredo-Valaperta-Rimoldo",
            "Roiate",
            "Roio del Sangro",
            "Roletto",
            "Rolo",
            "Romagnano",
            "Romagnano Sesia",
            "Romagnese",
            "Romallo",
            "Romana",
            "Romanengo",
            "Romano Banco",
            "Romano Canavese",
            "Romano d'Ezzelino",
            "Romano di Lombardia",
            "Romans d'Isonzo",
            "Rombiolo",
            "Rome",
            "Romeno",
            "Romentino",
            "Rometta",
            "Rometta Marea",
            "Romito Magra",
            "Ronago",
            "Roncà",
            "Roncade",
            "Roncadelle",
            "Roncanova",
            "Roncaro",
            "Roncegno",
            "Roncello",
            "Ronchi dei Legionari",
            "Ronchi di Campanile",
            "Ronchi Valsugana",
            "Ronchis",
            "Ronciglione",
            "Ronco",
            "Ronco All'Adige",
            "Ronco Biellese",
            "Ronco Briantino",
            "Ronco Canavese",
            "Ronco Scrivia",
            "Roncobello",
            "Roncocesi",
            "Roncoferraro",
            "Roncofreddo",
            "Roncofreddo-Santa Paola",
            "Roncola",
            "Roncone",
            "Rondanina",
            "Rondissone",
            "Ronsecco",
            "Ronta",
            "Ronzo-Chienis",
            "Ronzone",
            "Roppolo",
            "Rorà",
            "Roreto",
            "Rosà",
            "Rosa",
            "Rosario",
            "Rosarno",
            "Rosasco",
            "Rosate",
            "Rosazza",
            "Rosciano",
            "Rosciano",
            "Roscigno",
            "Rose",
            "Rosegaferro",
            "Rosello",
            "Roseto Capo Spulico",
            "Roseto degli Abruzzi",
            "Roseto Valfortore",
            "Rosia",
            "Rosignano Marittimo",
            "Rosignano Monferrato",
            "Rosignano Solvay-Castiglioncello",
            "Rosolina",
            "Rosolini",
            "Rosora",
            "Rossa",
            "Rossana",
            "Rossano",
            "Rossano Stazione",
            "Rossano Veneto",
            "Rossi",
            "Rossiglione",
            "Rosta",
            "Rota d'Imagna",
            "Rota Greca",
            "Roteglia",
            "Rotella",
            "Rotello",
            "Rotonda",
            "Rotondella",
            "Rotondi",
            "Rotta",
            "Rottanova",
            "Rottofreno",
            "Rotzo",
            "Roure",
            "Rovagnate",
            "Rovasenda",
            "Rovato",
            "Rovegno",
            "Roveleto",
            "Rovellasca",
            "Rovello Porro",
            "Roverbella",
            "Roverchiara",
            "Roverè della Luna",
            "Roverè Veronese",
            "Roveredo di Guà",
            "Roveredo in Piano",
            "Rovereto",
            "Rovereto",
            "Rovescala",
            "Rovetta",
            "Roviano",
            "Rovigo",
            "Rovito",
            "Rozzano",
            "Rubano",
            "Rubiana",
            "Rubiera",
            "Ruda",
            "Rudiano",
            "Rueglio",
            "Ruffano",
            "Ruffia",
            "Ruffrè",
            "Rufina",
            "Ruinas",
            "Ruino",
            "Rumo",
            "Ruoti",
            "Russi",
            "Rutigliano",
            "Rutino",
            "Ruviano",
            "Ruvo del Monte",
            "Ruvo di Puglia",
            "S.P. in Palazzi",
            "Sabaudia",
            "Sabbia",
            "Sabbio Chiese",
            "Sabbionara",
            "Sabbioneta",
            "Sacca",
            "Sacco",
            "Saccolongo",
            "Sachet",
            "Sacile",
            "Sacrofano",
            "Sadali",
            "Sagama",
            "Sagliano Micca",
            "Sagrado",
            "Sagron Mis",
            "Saint Marcel",
            "Saint Maurice",
            "Saint-Christophe",
            "Saint-Denis",
            "Saint-Nicolas",
            "Saint-Oyen",
            "Saint-Pierre",
            "Saint-Rhémy",
            "Saint-Vincent",
            "Sairano",
            "Sala",
            "Sala",
            "Sala al Barro",
            "Sala Baganza",
            "Sala Biellese",
            "Sala Bolognese",
            "Sala Comacina",
            "Sala Consilina",
            "Sala Monferrato",
            "Salandra",
            "Salaparuta",
            "Salara",
            "Salasco",
            "Salassa",
            "Salbertrand",
            "Salcedo",
            "Salcito",
            "Sale",
            "Sale delle Langhe",
            "Sale Marasino",
            "Sale San Giovanni",
            "Salemi",
            "Salento",
            "Salerano Canavese",
            "Salerano sul Lambro",
            "Salerno",
            "Saletto",
            "Saletto-San Bartolomeo",
            "Salgareda",
            "Sali Vercellese",
            "Salice Salentino",
            "Salice Terme",
            "Saliceto",
            "Saline",
            "Saline Ioniche",
            "Salino",
            "Salionze",
            "Salisano",
            "Salitto-Valle",
            "Salizzole",
            "Salle",
            "Salmour",
            "Salò",
            "Salorno",
            "Salsomaggiore Terme",
            "Saltara",
            "Saltrio",
            "Saludecio",
            "Saluggia",
            "Salussola",
            "Saluzzo",
            "Salvaterra",
            "Salvatronda",
            "Salve",
            "Salvirola",
            "Salvitelle",
            "Salvo Marina",
            "Salza di Pinerolo",
            "Salza Irpina",
            "Salzano",
            "Sam Marino",
            "Samarate",
            "Samassi",
            "Samatzai",
            "Sambiase",
            "Sambruson",
            "Sambuca",
            "Sambuca di Sicilia",
            "Sambuca Pistoiese",
            "Sambuceto",
            "Sambuci",
            "Sambuco",
            "Sambughe",
            "Sammichele di Bari",
            "Samo",
            "Samolaco",
            "Samone",
            "Samone",
            "Sampeyre",
            "Samugheo",
            "San Bartolomeo",
            "San Bartolomeo",
            "San Bartolomeo al Mare",
            "San Bartolomeo In Bosco",
            "San Bartolomeo in Galdo",
            "San Bartolomeo Val Cavargna",
            "San Basile",
            "San Basilio",
            "San Bassano",
            "San Bellino",
            "San Benedetto Belbo",
            "San Benedetto dei Marsi",
            "San Benedetto del Tronto",
            "San Benedetto in Perillis",
            "San Benedetto Po",
            "San Benedetto Ullano",
            "San Benedetto Val di Sambro",
            "San Benigno Canavese",
            "San Bernardino Verbano",
            "San Bernardo",
            "San Biagio",
            "San Biagio",
            "San Biagio",
            "San Biagio",
            "San Biagio della Cima",
            "San Biagio di Callalta",
            "San Biagio Platani",
            "San Biagio Saracinisco",
            "San Biase",
            "San Bonifacio",
            "San Bovio-San Felice",
            "San Brancato",
            "San Buono",
            "San Calogero",
            "San Candido",
            "San Canzian d'Isonzo",
            "San Carlo",
            "San Carlo All'Arena",
            "San Carlo Canavese",
            "San Carlo-Condofuri Marina",
            "San Casciano dei Bagni",
            "San Casciano in Val di Pesa",
            "San Cassiano",
            "San Cassiano",
            "San Castrese",
            "San Cataldo",
            "San Cesareo",
            "San Cesario di Lecce",
            "San Cesario sul Panaro",
            "San Chirico Nuovo",
            "San Chirico Raparo",
            "San Cipirello",
            "San Cipriano d'Aversa",
            "San Cipriano Picentino",
            "San Cipriano Po",
            "San Cipriano-S.Barbara-Centinale",
            "San Ciro-Ulmi-Filci",
            "San Clemente",
            "San Clemente",
            "San Colombano al Lambro",
            "San Colombano Belmonte",
            "San Cono",
            "San Cosmo Albanese",
            "San Costantino Albanese",
            "San Costantino Calabro",
            "San Costanzo",
            "San Cristoforo",
            "San Damaso",
            "San Damiano al Colle",
            "San Damiano d'Asti",
            "San Damiano Macra",
            "San Daniele del Friuli",
            "San Daniele Po",
            "San Defendente",
            "San Demetrio Corone",
            "San Demetrio Ne' Vestini",
            "San Didero",
            "San Donà di Piave",
            "San Donaci",
            "San Donato",
            "San Donato di Lecce",
            "San Donato di Ninea",
            "San Donato Milanese",
            "San Donato Val di Comino",
            "San Donnino",
            "San Fedele Intelvi",
            "San Fedele Superiore",
            "San Fedele-Lusignano",
            "San Fele",
            "San Felice",
            "San Felice",
            "San Felice A Cancello",
            "San Felice Circeo",
            "San Felice del Benaco",
            "San Felice del Molise",
            "San Felice sul Panaro",
            "San Ferdinando",
            "San Ferdinando",
            "San Ferdinando di Puglia",
            "San Fermo della Battaglia",
            "San Fidenzio",
            "San Fili",
            "San Filippo del Mela",
            "San Fior di Sopra",
            "San Fiorano",
            "San Floriano",
            "San Floriano del Collio",
            "San Floriano-Olmi",
            "San Floro",
            "San Francesco",
            "San Francesco al Campo",
            "San Fratello",
            "San Gabriele-Mondonuovo",
            "San Gavino Monreale",
            "San Gemini",
            "San Genesio",
            "San Genesio Atesino",
            "San Genesio ed Uniti",
            "San Gennaro Vesuviano",
            "San Germano Chisone",
            "San Germano dei Berici",
            "San Germano Vercellese",
            "San Gervasio Bresciano",
            "San Giacomo",
            "San Giacomo",
            "San Giacomo",
            "San Giacomo",
            "San Giacomo degli Schiavoni",
            "San Giacomo delle Segnate",
            "San Giacomo Filippo",
            "San Giacomo Vercellese",
            "San Gillio",
            "San Gimignano",
            "San Ginesio",
            "San Giorgio",
            "San Giorgio",
            "San Giorgio",
            "San Giorgio a Cremano",
            "San Giorgio a Liri",
            "San Giorgio al Tagliamento-Pozzi",
            "San Giorgio Albanese",
            "San Giorgio Canavese",
            "San Giorgio del Sannio",
            "San Giorgio della Richinvelda",
            "San Giorgio delle Pertiche",
            "San Giorgio di Livenza",
            "San Giorgio di Lomellina",
            "San Giorgio di Nogaro",
            "San Giorgio di Pesaro",
            "San Giorgio di Piano",
            "San Giorgio in Bosco",
            "San Giorgio in Salici",
            "San Giorgio Ionico",
            "San Giorgio la Molara",
            "San Giorgio Lucano",
            "San Giorgio Monferrato",
            "San Giorgio Morgeto",
            "San Giorgio Piacentino",
            "San Giorgio Scarampi",
            "San Giorgio Su Legnano",
            "San Giorio",
            "San Giovanni",
            "San Giovanni",
            "San Giovanni",
            "San Giovanni A Piro",
            "San Giovanni a Teduccio",
            "San Giovanni al Natisone",
            "San Giovanni Bianco",
            "San Giovanni d'Asso",
            "San Giovanni del Dosso",
            "San Giovanni di Gerace",
            "San Giovanni Gemini",
            "San Giovanni Ilarione",
            "San Giovanni in Croce",
            "San Giovanni in Fiore",
            "San Giovanni in Galdo",
            "San Giovanni in Marignano",
            "San Giovanni in Persiceto",
            "San Giovanni Incarico",
            "San Giovanni la Punta",
            "San Giovanni Lipioni",
            "San Giovanni Lupatoto",
            "San Giovanni Rotondo",
            "San Giovanni Suergiu",
            "San Giovanni Valdarno",
            "San Giovanni-Patoni",
            "San Giovanni-San Bernardino",
            "San Giuliano del Sannio",
            "San Giuliano di Puglia",
            "San Giuliano Milanese",
            "San Giuliano Terme",
            "San Giuliano Vecchio",
            "San Giuseppe",
            "San Giuseppe",
            "San Giuseppe",
            "San Giuseppe Jato",
            "San Giuseppe le Prata-Cotropagno",
            "San Giuseppe Vesuviano",
            "San Giustino",
            "San Giustino Valdarno",
            "San Giusto Canavese",
            "San Godenzo",
            "San Gregorio",
            "San Gregorio d'Ippona",
            "San Gregorio da Sassola",
            "San Gregorio di Catania",
            "San Gregorio Magno",
            "San Gregorio Matese",
            "San Gregorio nelle Alpi",
            "San Jacopo al Girone",
            "San Lazzaro",
            "San Leo",
            "San Leonardo",
            "San Leonardo",
            "San Leonardo in Passiria",
            "San Leone Mosè",
            "San Leucio del Sannio-Cavuoti",
            "San Liberale",
            "San Lorenzello",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo al Mare",
            "San Lorenzo Bellizzi",
            "San Lorenzo del Vallo",
            "San Lorenzo di Rovetta",
            "San Lorenzo di Sebato",
            "San Lorenzo in Banale",
            "San Lorenzo in Campo",
            "San Lorenzo Isontino",
            "San Lorenzo Maggiore",
            "San Lorenzo Nuovo",
            "San Luca",
            "San Lucido",
            "San Lupo",
            "San Mamete",
            "San Mango d'Aquino",
            "San Mango Piemonte",
            "San Mango sul Calore",
            "San Marcellino",
            "San Marcello",
            "San Marcello Pistoiese",
            "San Marco",
            "San Marco Argentano",
            "San Marco d'Alunzio",
            "San Marco dei Cavoti",
            "San Marco Evangelista",
            "San Marco in Lamis",
            "San Marco la Catola",
            "San Martino",
            "San Martino",
            "San Martino",
            "San Martino",
            "San Martino al Cimino",
            "San Martino al Tagliamento",
            "San Martino Alfieri",
            "San Martino Bassa",
            "San Martino Buon Albergo",
            "San Martino Canavese",
            "San Martino d'Agri",
            "San Martino Dall'Argine",
            "San Martino dei Mulini",
            "San Martino del Lago",
            "San Martino della Battaglia",
            "San Martino di Finita",
            "San Martino di Lupari",
            "San Martino di Venezze",
            "San Martino in Argine",
            "San Martino in Badia",
            "San Martino in Campo",
            "San Martino in Freddana-Monsagrati",
            "San Martino in Passiria",
            "San Martino in Pensilis",
            "San Martino in Rio",
            "San Martino in Strada",
            "San Martino in Trignano",
            "San Martino Sannita",
            "San Martino Siccomario",
            "San Martino sulla Marrucina",
            "San Martino Valle Caudina",
            "San Marzano di San Giuseppe",
            "San Marzano Oliveto",
            "San Marzano sul Sarno",
            "San Massimo",
            "San Maurizio",
            "San Maurizio D'Opaglio",
            "San Mauro",
            "San Mauro a Mare",
            "San Mauro Castelverde",
            "San Mauro Cilento",
            "San Mauro di Saline",
            "San Mauro Forte",
            "San Mauro la Bruca",
            "San Mauro Marchesato",
            "San Mauro Pascoli",
            "San Mauro Torinese",
            "San Michele",
            "San Michele",
            "San Michele",
            "San Michele al Tagliamento",
            "San Michele All'Adige",
            "San Michele dei Mucchietti",
            "San Michele di Ganzaria",
            "San Michele di Piave",
            "San Michele di Serino",
            "San Michele Mondovì",
            "San Michele Salentino",
            "San Michele Tiorre",
            "San Michele-San Giorgio",
            "San Miniato",
            "San Miniato Basso",
            "San Nazario",
            "San Nazzaro",
            "San Nazzaro Sesia",
            "San Nazzaro Val Cavargna",
            "San Niccolò di Celle",
            "San Nico",
            "San Nicola",
            "San Nicola",
            "San Nicola Arcella",
            "San Nicola Baronia",
            "San Nicola da Crissa",
            "San Nicola dell'Alto",
            "San Nicola la Strada",
            "San Nicola Manfredi",
            "San Nicolò",
            "San Nicolò",
            "San Nicolò",
            "San Nicolò a Tordino",
            "San Nicolò Comelico",
            "San Nicolò d'Arcidano",
            "San Nicolo'Gerrei",
            "San Pancrazio",
            "San Pancrazio",
            "San Pancrazio Salentino",
            "San Panfilo d'Ocre",
            "San Paolo",
            "San Paolo",
            "San Paolo",
            "San Paolo Albanese",
            "San Paolo Bel Sito",
            "San Paolo d'Argon",
            "San Paolo di Civitate",
            "San Paolo di Jesi",
            "San Paolo Solbrito",
            "San Pellegrino Terme",
            "San Pier d'Isonzo",
            "San Pier Niceto",
            "San Pierino",
            "San Piero a Sieve",
            "San Piero in Bagno",
            "San Piero Patti",
            "San Pietro",
            "San Pietro",
            "San Pietro",
            "San Pietro a Maida",
            "San Pietro a Patierno",
            "San Pietro al Natisone",
            "San Pietro al Tanagro",
            "San Pietro Apostolo",
            "San Pietro Avellana",
            "San Pietro Belvedere",
            "San Pietro Capofiume",
            "San Pietro Clarenza",
            "San Pietro d'Olba",
            "San Pietro di Cadore",
            "San Pietro di Caridà",
            "San Pietro di Feletto",
            "San Pietro di Morubio",
            "San Pietro in Amantea",
            "San Pietro in Cariano",
            "San Pietro in Casale",
            "San Pietro in Cerro",
            "San Pietro in Gu",
            "San Pietro in Guarano",
            "San Pietro in Lama",
            "San Pietro In Vincoli",
            "San Pietro in Volta",
            "San Pietro Infine",
            "San Pietro Mosezzo",
            "San Pietro Mussolino",
            "San Pietro Val Lemina",
            "San Pietro Valdastico",
            "San Pietro Vernotico",
            "San Pietro Viminario",
            "San Pio delle Camere",
            "San Polo",
            "San Polo d'Enza",
            "San Polo dei Cavalieri",
            "San Polo di Piave",
            "San Polomatese",
            "San Ponso",
            "San Possidonio",
            "San Potito Sannitico",
            "San Potito Ultra",
            "San Prisco",
            "San Procopio",
            "San Prospero",
            "San Quirico",
            "San Quirico d'Orcia",
            "San Quirino",
            "San Raffaele Cimena",
            "San Remo",
            "San Roberto",
            "San Rocco",
            "San Rocco",
            "San Rocco",
            "San Rocco",
            "San Rocco al Porto",
            "San Romano",
            "San Romano in Garfagnana",
            "San Rufo",
            "San Salvatore",
            "San Salvatore di Fitalia",
            "San Salvatore Monferrato",
            "San Salvatore Telesino",
            "San Salvo",
            "San Sebastiano",
            "San Sebastiano al Vesuvio",
            "San Sebastiano Curone",
            "San Sebastiano da Po",
            "San Secondo",
            "San Secondo di Pinerolo",
            "San Secondo Parmense",
            "San Severino Lucano",
            "San Severino Marche",
            "San Severo",
            "San Siro",
            "San Sossio Baronia",
            "San Sostene",
            "San Sosti",
            "San Sperate",
            "San Stino di Livenza",
            "San Tammaro",
            "San Teodoro",
            "San Teodoro",
            "San Terenziano",
            "San Terenzo",
            "San Tommaso Tre Archi",
            "San Valentino",
            "San Valentino in Abruzzo Citeriore",
            "San Valentino Torio",
            "San Venanzio",
            "San Venanzo",
            "San Vendemiano",
            "San Vendemiano-Fossamerlo",
            "San Vero Milis",
            "San Vigilio",
            "San Vigilio",
            "San Vincenzo",
            "San Vincenzo",
            "San Vincenzo la Costa",
            "San Vincenzo Valle Roveto",
            "San Vitaliano",
            "San Vito",
            "San Vito",
            "San Vito",
            "San Vito al Mantico",
            "San Vito al Tagliamento",
            "San Vito al Torre",
            "San Vito Chietino",
            "San Vito dei Normanni",
            "San Vito di Fagagna",
            "San Vito di Leguzzano",
            "San Vito Lo Capo",
            "San Vito Romano",
            "San Vito Sullo Ionio",
            "San Vito-Cerreto",
            "San Vittore",
            "San Vittore del Lazio",
            "San Vittore Olona",
            "San Vittoria in Matenano",
            "San Zeno",
            "San Zeno di Montagna",
            "San Zeno Naviglio",
            "San Zeno-San Giuseppe",
            "San Zenone",
            "San Zenone al Lambro",
            "San Zenone al Po",
            "San Zenone degli Ezzelini",
            "Sanarica",
            "Sandigliano",
            "Sandrà",
            "Sandrigo",
            "Sanfrè",
            "Sanfront",
            "Sangano",
            "Sangiano",
            "Sangineto",
            "Sanguinetto",
            "Sanluri",
            "Sannazzaro de' Burgondi",
            "Sannicandro di Bari",
            "Sannicandro Garganico",
            "Sannicola",
            "Sansepolcro",
            "Sant'Agapito",
            "Sant'Agata Bolognese",
            "Sant'Agata de'Goti",
            "Sant'Agata del Bianco",
            "Sant'Agata di Esaro",
            "Sant'Agata di Militello",
            "Sant'Agata di Puglia",
            "Sant'Agata Feltria",
            "Sant'Agata Fossili",
            "Sant'Agata Li Battiati",
            "Sant'Agata Martesana",
            "Sant'Agata sui Due Golfi",
            "Sant'Agata sul Santerno",
            "Sant'Agnello",
            "Sant'Agostino",
            "Sant'Albano Stura",
            "Sant'Alberto",
            "Sant'Alberto",
            "Sant'Albino",
            "Sant'Alessio Con Vialone",
            "Sant'Alessio in Aspromonte",
            "Sant'Alessio Siculo",
            "Sant'Alfio",
            "Sant'Ambrogio di Torino",
            "Sant'Ambrogio di Valpollicella",
            "Sant'Ambrogio sul Garigliano",
            "Sant'Anastasia",
            "Sant'Anatolia di Narco",
            "Sant'Andrea",
            "Sant'Andrea",
            "Sant'Andrea Apostolo dello Ionio",
            "Sant'Andrea del Garigliano",
            "Sant'Andrea di Conza",
            "Sant'Andrea Frius",
            "Sant'Andrea in Casale",
            "Sant'Andrea Ionio Marina",
            "Sant'Andrea-Pizzone-Ciamprisco",
            "Sant'Angelo",
            "Sant'Angelo",
            "Sant'Angelo A Cupolo",
            "Sant'Angelo A Fasanella",
            "Sant'Angelo A Scala",
            "Sant'Angelo All'Esca",
            "Sant'Angelo d'Alife",
            "Sant'Angelo dei Lombardi",
            "Sant'Angelo del Pesco",
            "Sant'Angelo di Brolo",
            "Sant'Angelo di Piove di Sacco",
            "Sant'Angelo in Formis",
            "Sant'Angelo in Lizzola",
            "Sant'Angelo in Pontano",
            "Sant'Angelo in Vado",
            "Sant'Angelo in Villa-Giglio",
            "Sant'Angelo le Fratte",
            "Sant'Angelo Limosano",
            "Sant'Angelo Lodigiano",
            "Sant'Angelo Lomellina",
            "Sant'Angelo Muxaro",
            "Sant'Angelo Romano",
            "Sant'Anna",
            "Sant'Anna Arresi",
            "Sant'Anna d'Alfaedo",
            "Sant'Antimo",
            "Sant'Antioco",
            "Sant'Antonino di Susa",
            "Sant'Antonio",
            "Sant'Antonio",
            "Sant'Antonio",
            "Sant'Antonio",
            "Sant'Antonio Abate",
            "Sant'Antonio di Gallura",
            "Sant'Apollinare",
            "Sant'Apollinare",
            "Sant'Arcangelo",
            "Sant'Arcangelo Trimonte",
            "Sant'Arpino",
            "Sant'Arsenio",
            "Sant'Egidio alla Vibrata",
            "Sant'Egidio del Monte Albino",
            "Sant'Elena",
            "Sant'Elena Irpina",
            "Sant'Elena Sannita",
            "Sant'Elia",
            "Sant'Elia a Pianisi",
            "Sant'Elia Fiumerapido",
            "Sant'Elpidio a Mare",
            "Sant'Eufemia a Maiella",
            "Sant'Eufemia d'Aspromonte",
            "Sant'Eusanio del Sangro",
            "Sant'Eusanio Forconese",
            "Sant'Ilario d'Enza",
            "Sant'Ilario dello Ionio",
            "Sant'Ippolito",
            "Sant'Omero",
            "Sant'Omobono Terme",
            "Sant'Onofrio",
            "Sant'Oreste",
            "Sant'Orsola",
            "Sant'Orsola Terme",
            "Santa Brigida",
            "Santa Caterina Albanese",
            "Santa Caterina dello Ionio",
            "Santa Caterina dello Ionio Marina",
            "Santa Caterina Villarmosa",
            "Santa Cesarea Terme",
            "Santa Corinna",
            "Santa Cristina",
            "Santa Cristina",
            "Santa Cristina d'Aspromonte",
            "Santa Cristina Gela",
            "Santa Cristina Valgardena",
            "Santa Croce",
            "Santa Croce Camerina",
            "Santa Croce del Sannio",
            "Santa Croce di Magliano",
            "Santa Croce Scuole",
            "Santa Croce sull'Arno",
            "Santa Domenica",
            "Santa Domenica Talao",
            "Santa Domenica Vittoria",
            "Santa Elisabetta",
            "Santa Eufemia Lamezia",
            "Santa Fiora",
            "Santa Flavia",
            "Santa Giuletta",
            "Santa Giusta",
            "Santa Giustina",
            "Santa Giustina",
            "Santa Giustina in Colle",
            "Santa Luce",
            "Santa Lucia",
            "Santa Lucia",
            "Santa Lucia",
            "Santa Lucia del Mela",
            "Santa Lucia di Piave",
            "Santa Lucia di Serino",
            "Santa Margherita",
            "Santa Margherita d'Adige",
            "Santa Margherita di Belice",
            "Santa Margherita di Staffora",
            "Santa Margherita Ligure",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria a Monte",
            "Santa Maria A Vico",
            "Santa Maria Apparente",
            "Santa Maria Capua Vetere",
            "Santa Maria Codifiume",
            "Santa Maria Coghinas",
            "Santa Maria degli Angeli",
            "Santa Maria dei Sabbioni",
            "Santa Maria del Cedro",
            "Santa Maria del Molise",
            "Santa Maria della Versa",
            "Santa Maria di Licodia",
            "Santa Maria di Non",
            "Santa Maria di Sala",
            "Santa Maria Hoè",
            "Santa Maria Imbaro",
            "Santa Maria La Carità",
            "Santa Maria la Fossa",
            "Santa Maria la Longa",
            "Santa Maria la Stella",
            "Santa Maria Maddalena",
            "Santa Maria Maggiore",
            "Santa Maria Navarrese",
            "Santa Maria Nuova",
            "Santa Maria Nuova",
            "Santa Marina",
            "Santa Marina Salina",
            "Santa Marinella",
            "Santa Marta",
            "Santa Monica-Cella",
            "Santa Ninfa",
            "Santa Paolina",
            "Santa Rufina",
            "Santa Severina",
            "Santa Sofia",
            "Santa Sofia d'Epiro",
            "Santa Tecla-Castelpagano",
            "Santa Teresa",
            "Santa Teresa di Riva",
            "Santa Teresa Gallura",
            "Santa Valburga",
            "Santa Venerina",
            "Santa Vittoria",
            "Santa Vittoria d'Alba",
            "Santadi",
            "Santandrà",
            "Santarcangelo",
            "Sante Marie",
            "Santena",
            "Santeramo in Colle",
            "Santerno",
            "Santhià",
            "Santi Cosma e Damiano",
            "Santino",
            "Santo Stefano",
            "Santo Stefano al Mare",
            "Santo Stefano Belbo",
            "Santo Stefano d'Aveto",
            "Santo Stefano del Sole",
            "Santo Stefano di Cadore",
            "Santo Stefano di Camastra",
            "Santo Stefano di Magra",
            "Santo Stefano di Rogliano",
            "Santo Stefano di Sessanio",
            "Santo Stefano in Aspromonte",
            "Santo Stefano Lodigiano",
            "Santo Stefano Quisquina",
            "Santo Stefano Roero",
            "Santo Stefano Ticino",
            "Santo Stefano-Bonaldo",
            "Santo Stefano-Carraie",
            "Santomenna",
            "Santopadre",
            "Santorso",
            "Santu Lussurgiu",
            "Sanza",
            "Sanzeno",
            "Saonara",
            "Saponara",
            "Saponara Marittima",
            "Sappada",
            "Sapri",
            "Saracena",
            "Saracinesco",
            "Sarcedo",
            "Sarconi",
            "Sardara",
            "Sardigliano",
            "Sarego",
            "Sarentino",
            "Sarezzano",
            "Sarezzo",
            "Sarmato",
            "Sarmede",
            "Sarmeola",
            "Sarnano",
            "Sarnico",
            "Sarno",
            "Sarnonico",
            "Saronno",
            "Sarroch",
            "Sarsina",
            "Sartano",
            "Sarteano",
            "Sartirana Lomellina",
            "Sarule",
            "Sarzana",
            "Sasi",
            "Sassa",
            "Sassacci",
            "Sassano",
            "Sassari",
            "Sassello",
            "Sassetta",
            "Sassinoro",
            "Sasso di Castalda",
            "Sasso Marconi",
            "Sassocorvaro",
            "Sassofeltrio",
            "Sassoferrato",
            "Sassuolo",
            "Satriano",
            "Satriano di Lucania",
            "Sauris di Sotto",
            "Sauze d'Oulx",
            "Sauze di Cesana",
            "Sava",
            "Savarna-Conventello",
            "Savelli",
            "Saviano",
            "Savigliano",
            "Savignano Irpino",
            "Savignano sul Panaro",
            "Savignano sul Rubicone",
            "Savigno",
            "Savignone",
            "Savio",
            "Saviore",
            "Savoca",
            "Savogna",
            "Savogna d'Isonzo",
            "Savoia di Lucania",
            "Savona",
            "Savonera",
            "Scafa",
            "Scafati",
            "Scagnello",
            "Scala",
            "Scala Coeli",
            "Scaldasole",
            "Scalea",
            "Scalenghe",
            "Scaletta Zanclea",
            "Scalo di Baragiano",
            "Scalo Romagnano al Monte",
            "Scaltenigo",
            "Scampia",
            "Scampitella",
            "Scandale",
            "Scandiano",
            "Scandicci",
            "Scandolara Ravara",
            "Scandolara Ripa d'Oglio",
            "Scandriglia",
            "Scannabue-Cascine Capri",
            "Scanno",
            "Scano al Brembo",
            "Scano di Montiferro",
            "Scansano",
            "Scanzano Jonico",
            "Scanzorosciate",
            "Scapoli",
            "Scarcelli",
            "Scardovari",
            "Scario",
            "Scarlino",
            "Scarlino Scalo",
            "Scarmagno",
            "Scarnafigi",
            "Scarperia",
            "Scena",
            "Scena - Schenna",
            "Scerne",
            "Scerni",
            "Scheggia",
            "Scheggino",
            "Schiavi di Abruzzo",
            "Schiavon",
            "Schignano",
            "Schilpario",
            "Schio",
            "Schivenoglia",
            "Sciacca",
            "Sciara",
            "Sciarborasca",
            "Sciaves",
            "Scicli",
            "Scido",
            "Scilla",
            "Scillato",
            "Sciolze",
            "Scisciano",
            "Sclafani Bagni",
            "Scoglitti",
            "Scomigo",
            "Scontrone",
            "Scopa",
            "Scopello",
            "Scoppito",
            "Scordia",
            "Scorrano",
            "Scortichino",
            "Scorzè",
            "Scurcola Marsicana",
            "Scurelle",
            "Scurzolengo",
            "Seano",
            "Seborga",
            "Secchiano",
            "Secinaro",
            "Seclì",
            "Secondigliano",
            "Secugnago",
            "Sedegliano",
            "Sedico",
            "Sedilo",
            "Sedini",
            "Sedriano",
            "Sedrina",
            "Sefro",
            "Segariu",
            "Seggiano",
            "Segni",
            "Segonzano",
            "Segrate",
            "Segusino",
            "Selargius",
            "Selcetta",
            "Selci",
            "Selci-Lama",
            "Selegas",
            "Selino Basso",
            "Sellano",
            "Sellero",
            "Sellia",
            "Sellia Marina",
            "Selva",
            "Selva",
            "Selva dei Molini",
            "Selva del Montello",
            "Selva di Cadore",
            "Selva di Progno",
            "Selvatelle",
            "Selvazzano Dentro",
            "Selve Marcone",
            "Selvino",
            "Semestene",
            "Semiana",
            "Seminara",
            "Semonte-Casamorcia",
            "Semproniano",
            "Senago",
            "Senale",
            "Senales",
            "Seneghe",
            "Senerchia",
            "Seniga",
            "Senigallia",
            "Senis",
            "Senise",
            "Senna Comasco",
            "Senna Lodigiana",
            "Sennariolo",
            "Sennori",
            "Senorbì",
            "Sepino",
            "Seppiana",
            "Sequals",
            "Seravezza",
            "Serdiana",
            "Seregno",
            "Seren del Grappa",
            "Sergnano",
            "Seriate",
            "Serina",
            "Serino",
            "Serle",
            "Sermide",
            "Sermoneta",
            "Sernaglia della Battaglia",
            "Sernio",
            "Serole",
            "Serone",
            "Serra d'Aiello",
            "Serra de' Conti",
            "Serra di Maiolo",
            "Serra Pedace",
            "Serra Riccò",
            "Serra San Bruno",
            "Serra San Quirico",
            "Serra Sant'Abbondio",
            "Serracapriola",
            "Serradifalco",
            "Serralunga d'Alba",
            "Serralunga di Crea",
            "Serramanna",
            "Serramazzoni",
            "Serramezzana",
            "Serramonacesca",
            "Serrano",
            "Serrapetrona",
            "Serrara Fontana",
            "Serrastretta",
            "Serrata",
            "Serravalle",
            "Serravalle a Po",
            "Serravalle di Chienti",
            "Serravalle Langhe",
            "Serravalle Pistoiese",
            "Serravalle Scrivia",
            "Serravalle Sesia",
            "Serre",
            "Serre",
            "Serre di Rapolano",
            "Serrenti",
            "Serri",
            "Serrone",
            "Serrungarina",
            "Sersale",
            "Servigliano",
            "Servo",
            "Sessa Aurunca",
            "Sessa Cilento",
            "Sessame",
            "Sessano del Molise",
            "Sesta Godano",
            "Sestino",
            "Sesto",
            "Sesto al Reghena",
            "Sesto Calende",
            "Sesto Campano",
            "Sesto ed Uniti",
            "Sesto Fiorentino",
            "Sesto Imolese",
            "Sesto San Giovanni",
            "Sestola",
            "Sestri Levante",
            "Sestriere",
            "Sestu",
            "Settala",
            "Settefrati",
            "Setteville",
            "Settime",
            "Settimo",
            "Settimo",
            "Settimo Milanese",
            "Settimo Rottaro",
            "Settimo San Pietro",
            "Settimo Torinese",
            "Settimo Vittone",
            "Settingiano",
            "Setzu",
            "Seui",
            "Seulo",
            "Sevegliano",
            "Seveso",
            "Sezzadio",
            "Sezze",
            "Sezze Scalo",
            "Sfaranda",
            "Sferracavallo",
            "Sferracavallo",
            "Sforzacosta",
            "Sfruz",
            "Sgonico",
            "Sgurgola",
            "Siamaggiore",
            "Siamanna",
            "Siano",
            "Siano",
            "Siapiccia",
            "Sibari",
            "Sicignano degli Alburni",
            "Siculiana",
            "Siddi",
            "Siderno",
            "Sieci",
            "Siena",
            "Sigillo",
            "Signa",
            "Silandro",
            "Silanus",
            "Silea",
            "Silì",
            "Siligo",
            "Siliqua",
            "Silius",
            "Sillano",
            "Sillavengo",
            "Silvano d'Orba",
            "Silvano Pietra",
            "Silvi",
            "Simala",
            "Simaxis",
            "Simbario",
            "Sinagra",
            "Sinalunga",
            "Sindia",
            "Sini",
            "Sinigo",
            "Sinio",
            "Siniscola",
            "Sinnai",
            "Sinopoli",
            "Siracusa",
            "Sirignano",
            "Siris",
            "Sirmione",
            "Sirolo",
            "Sirone",
            "Siror",
            "Sirta",
            "Sirtori",
            "Sissa",
            "Sistiana-Visogliano",
            "Siurgus Donigala",
            "Siusi",
            "Siviano",
            "Siziano",
            "Sizzano",
            "Sluderno",
            "Smarano",
            "Smerillo",
            "Soave",
            "Soave",
            "Soccavo",
            "Soccher-Paiane-Casan-Arsie",
            "Soci",
            "Soddì",
            "Sogliano al Rubicone",
            "Sogliano Cavour",
            "Soglio",
            "Soiano",
            "Solagna",
            "Solanas",
            "Solara",
            "Solarino",
            "Solaro",
            "Solarolo",
            "Solarolo",
            "Solarolo Rainerio",
            "Solarussa",
            "Solbiate",
            "Solbiate Arno",
            "Solbiate Olona",
            "Soldano",
            "Soleminis",
            "Solero",
            "Solesino",
            "Soleto",
            "Solferino",
            "Soliera",
            "Solignano",
            "Solignano Nuovo",
            "Solofra",
            "Solonghello",
            "Solopaca",
            "Solto Collina",
            "Solza",
            "Somaglia",
            "Somano",
            "Somma Lombardo",
            "Somma Vesuviana",
            "Sommacampagna",
            "Sommariva del Bosco",
            "Sommariva Perno",
            "Sommatino",
            "Sommo",
            "Sona",
            "Soncino",
            "Sondalo",
            "Sondrio",
            "Songavazzo",
            "Sonico",
            "Sonnino",
            "Soprabolzano",
            "Soprana",
            "Sora",
            "Soraga",
            "Soragna",
            "Sorano",
            "Sorbara",
            "Sorbo San Basile",
            "Sorbo Serpico",
            "Sorbolo",
            "Sordevolo",
            "Sordio",
            "Soresina",
            "Sorgà",
            "Sorgono",
            "Sori",
            "Sorianello",
            "Soriano Calabro",
            "Soriano nel Cimino",
            "Sorico",
            "Soriso",
            "Sorisole",
            "Sormano",
            "Sorradile",
            "Sorrento",
            "Sorso",
            "Sortino",
            "Sospiro",
            "Sospirolo",
            "Sossano",
            "Sostegno",
            "Sotto il Monte Giovanni XXIII",
            "Sottocastello",
            "Sottoselva",
            "Sover",
            "Soverato Marina",
            "Soverato Superiore",
            "Sovere",
            "Soveria Mannelli",
            "Soveria Simeri",
            "Soverzene",
            "Sovicille",
            "Sovico",
            "Sovizzo",
            "Sovramonte",
            "Sozzago",
            "Spadafora",
            "Spadola",
            "Sparanise",
            "Sparone",
            "Sparta'",
            "Spartimento",
            "Specchia",
            "Spello",
            "Spera",
            "Sperlinga",
            "Sperlonga",
            "Sperone",
            "Spessa",
            "Spezzano Albanese",
            "Spezzano della Sila",
            "Spezzano Piccolo",
            "Spianate",
            "Spiazzo",
            "Spicchio-Sovigliana",
            "Spigno Monferrato",
            "Spigno Saturnia Inferiore",
            "Spigno Saturnia Superiore",
            "Spilamberto",
            "Spilimbergo",
            "Spilinga",
            "Spinadesco",
            "Spinazzola",
            "Spinea-Orgnano",
            "Spineda",
            "Spinete",
            "Spineto Scrivia",
            "Spinetoli",
            "Spinetta Marengo",
            "Spinimbecco",
            "Spino d'Adda",
            "Spinone al Lago",
            "Spinoso",
            "Spirano",
            "Spoleto",
            "Spoltore",
            "Spongano",
            "Spormaggiore",
            "Sporminore",
            "Spotorno",
            "Spresiano",
            "Spriana",
            "Squillace",
            "Squillace Lido",
            "Squinzano",
            "Stabbia",
            "Staffa",
            "Staffolo",
            "Staggia",
            "Stagno Lombardo",
            "Staiti",
            "Staletti",
            "Stallavena-Lugo",
            "Stanghe",
            "Stanghella",
            "Staranzano",
            "Starza Vecchia",
            "Statte",
            "Stazione",
            "Stazione",
            "Stazione di Allerona",
            "Stazione di Padule",
            "Stazione Masotti",
            "Stazione Montalto-Coretto",
            "Stazione Valmozzola",
            "Stazione-Fornola",
            "Stazzano",
            "Stazzona",
            "Stefanaconi",
            "Stella",
            "Stella",
            "Stella Cilento",
            "Stellanello",
            "Stelvio",
            "Stenico",
            "Sternatia",
            "Stezzano",
            "Stia",
            "Stiava",
            "Stienta",
            "Stigliano",
            "Stigliano",
            "Stignano",
            "Stilo",
            "Stimigliano",
            "Stintino",
            "Stio",
            "Stornara",
            "Stornarella",
            "Storo",
            "Stra",
            "Strà-Montanara-Pieve",
            "Strada",
            "Strada",
            "Strada in Chianti",
            "Stradella",
            "Strambinello",
            "Strambino",
            "Strangolagalli",
            "Stravignino",
            "Stregna",
            "Strembo",
            "Stresa",
            "Strettoia",
            "Strevi",
            "Striano",
            "Strigno",
            "Stroncone",
            "Strongoli",
            "Stroppiana",
            "Stroppo",
            "Strozza",
            "Strudà",
            "Sturno",
            "Su Planu",
            "Suardi",
            "Subbiano",
            "Subiaco",
            "Succivo",
            "Sueglio",
            "Suelli",
            "Suello",
            "Suisio",
            "Sulbiate",
            "Sulmona",
            "Sulzano",
            "Sumirago",
            "Summaga",
            "Summonte",
            "Suni",
            "Suno",
            "Supersano",
            "Supino",
            "Surano",
            "Surbo",
            "Surdo",
            "Susa",
            "Susegana",
            "Susella",
            "Sustinente",
            "Sutera",
            "Sutri",
            "Sutrio",
            "Suvereto",
            "Suzzara",
            "Tabiago-Cibrone",
            "Taceno",
            "Tadasuni",
            "Taggì",
            "Taggia",
            "Tagliacozzo",
            "Tagliaferro",
            "Taglio",
            "Taglio di Po",
            "Tagliolo Monferrato",
            "Taibon Agordino",
            "Taino",
            "Taio",
            "Taipana",
            "Talamello",
            "Talamona",
            "Talana",
            "Taleggio",
            "Talla",
            "Talmassons",
            "Talponada",
            "Tamai",
            "Tambre",
            "Taneto",
            "Taormina",
            "Tarano",
            "Taranta Peligna",
            "Tarantasca",
            "Taranto",
            "Tarcento",
            "Tarquinia",
            "Tarsia",
            "Tartano",
            "Tarvisio",
            "Tarzo",
            "Tassarolo",
            "Tassullo",
            "Taurano",
            "Taurasi",
            "Taurianova",
            "Taurisano",
            "Tavagnacco",
            "Tavagnasco",
            "Tavarnelle Val di Pesa",
            "Tavarnuzze",
            "Tavazzano",
            "Tavenna",
            "Taverna",
            "Taverna Ravindola",
            "Taverne D'Arbia",
            "Tavernelle",
            "Tavernelle",
            "Tavernelle",
            "Tavernerio",
            "Tavernola Bergamasca",
            "Tavernole",
            "Taviano",
            "Tavigliano",
            "Tavo",
            "Tavoleto",
            "Tavullia",
            "Teana",
            "Teano",
            "Tecchiena",
            "Teggiano-Macchiaroli",
            "Teglio",
            "Teglio Veneto",
            "Tegoleto",
            "Telese",
            "Telgate",
            "Telti",
            "Telve",
            "Telve di Sopra",
            "Tempio Pausania",
            "Temù",
            "Tencarola",
            "Tenna",
            "Tenno",
            "Teor",
            "Teora",
            "Teramo",
            "Terdobbiate",
            "Terelle",
            "Terento",
            "Terenzo",
            "Tergu",
            "Terlago",
            "Terlano",
            "Terlizzi",
            "Terme",
            "Termeno",
            "Termini Imerese",
            "Termoli",
            "Ternate",
            "Ternengo",
            "Terni",
            "Terno d'Isola",
            "Terontola",
            "Terracina",
            "Terradura",
            "Terragnolo",
            "Terralba",
            "Terranova da Sibari",
            "Terranova dei Passerini",
            "Terranova di Pollino",
            "Terranova Sappo Minulio",
            "Terranuova Bracciolini",
            "Terrarossa",
            "Terrasini",
            "Terrassa Padovana",
            "Terravecchia",
            "Terraverde-Corte Palasio",
            "Terrazzo",
            "Terres",
            "Terricciola",
            "Terrossa",
            "Terruggia",
            "Tertenia",
            "Terzigno",
            "Terzo",
            "Terzo d'Aquileia",
            "Terzolas",
            "Terzorio",
            "Tesero",
            "Tesimo",
            "Tessennano",
            "Tessera",
            "Testico",
            "Teti",
            "Tetti Neirotti",
            "Tettorosso",
            "Teulada",
            "Teverola",
            "Tezze",
            "Thiene",
            "Thiesi",
            "Tiana",
            "Ticengo",
            "Ticineto",
            "Tiggiano",
            "Tiglieto",
            "Tigliole",
            "Tinnura",
            "Tione degli Abruzzi",
            "Tione di Trento",
            "Tirano",
            "Tires",
            "Tiriolo",
            "Tirolo",
            "Tissi",
            "Tito",
            "Tivoli",
            "Tivolille Pasquali-Merenzata",
            "Tizzano Val Parma",
            "Toano",
            "Tocco Caudio",
            "Tocco da Casauria",
            "Toceno",
            "Todi",
            "Toffia",
            "Toirano",
            "Tolentino",
            "Tolfa",
            "Tollegno",
            "Tollo",
            "Tolmezzo",
            "Tolve",
            "Tombelle",
            "Tombolo",
            "Tonadico",
            "Tonara",
            "Tonco",
            "Tonengo",
            "Tonengo-Casale",
            "Tonezza del Cimone",
            "Tonnara di Bonagia",
            "Tonnarella",
            "Tor Lupara",
            "Tora",
            "Torano Castello",
            "Torano Nuovo",
            "Torbole Casaglia",
            "Torcegno",
            "Torchiara",
            "Torchiarolo",
            "Torchiati",
            "Torchione-Moia",
            "Torella dei Lombardi",
            "Torella del Sannio",
            "Torelli-Torrette",
            "Torgiano",
            "Torgnon",
            "Torino",
            "Torino di Sangro",
            "Toritto",
            "Torlino Vimercati",
            "Tornaco",
            "Tornareccio",
            "Tornata",
            "Tornimparte",
            "Torno",
            "Tornolo",
            "Toro",
            "Torpè",
            "Torraca",
            "Torralba",
            "Torrazza Coste",
            "Torrazza dei Mandelli",
            "Torrazza Piemonte",
            "Torrazzo",
            "Torre Annunziata",
            "Torre Beretti",
            "Torre Boldone",
            "Torre Bormida",
            "Torre Caietani",
            "Torre Canavese",
            "Torre Caracciolo",
            "Torre Colonna-Sperone",
            "Torre d'Arese",
            "Torre d'Isola",
            "Torre de' Busi",
            "Torre de' Negri",
            "Torre de' Passeri",
            "Torre de' Picenardi",
            "Torre de' Roveri",
            "Torre del Greco",
            "Torre del Lago Puccini",
            "Torre del Mangano",
            "Torre di Mosto",
            "Torre di Ruggiero",
            "Torre di Santa Maria",
            "Torre le Nocelle",
            "Torre Melissa",
            "Torre Mondovì",
            "Torre Orsaia",
            "Torre Pallavicina",
            "Torre Pellice",
            "Torre San Giorgio",
            "Torre San Giovanni",
            "Torre San Patrizio",
            "Torre Santa Susanna",
            "Torreano",
            "Torrebelvicino",
            "Torrebruna",
            "Torrecuso",
            "Torreglia",
            "Torregrotta",
            "Torremaggiore",
            "Torrenieri",
            "Torrenova",
            "Torreselle",
            "Torresina",
            "Torretta",
            "Torretta",
            "Torretta-Scalzapecora",
            "Torrevecchia",
            "Torrevecchia Pia",
            "Torri del Benaco",
            "Torri di Quartesolo",
            "Torri in Sabina",
            "Torriana",
            "Torrice",
            "Torricella",
            "Torricella",
            "Torricella del Pizzo",
            "Torricella in Sabina",
            "Torricella Peligna",
            "Torricella Verzate",
            "Torriglia",
            "Torrile",
            "Torrion Quartara",
            "Torrioni",
            "Torrita di Siena",
            "Torrita Tiberina",
            "Tortolì",
            "Tortona",
            "Tortora",
            "Tortora Marina",
            "Tortorella",
            "Tortoreto",
            "Tortoreto Lido",
            "Tortorici",
            "Torvaianica",
            "Torvaianica Alta",
            "Torviscosa",
            "Toscanella",
            "Toscolano Maderno",
            "Tossicia",
            "Tovo di Sant'Agata",
            "Tovo San Giacomo",
            "Trabia",
            "Tradate",
            "Tragliatella Campitello",
            "Tramatza",
            "Trambileno",
            "Tramonti",
            "Tramonti di Sopra",
            "Tramonti di Sotto",
            "Tramutola",
            "Trana",
            "Trani",
            "Transacqua",
            "Traona",
            "Trapani",
            "Trappeto",
            "Trarego",
            "Trarivi",
            "Trasacco",
            "Trasaghis",
            "Trasquera",
            "Tratalias",
            "Trausella",
            "Travacò Siccomario",
            "Travagliato",
            "Travedona Monate",
            "Traversella",
            "Traversetolo",
            "Traves",
            "Travesio",
            "Travo",
            "Tre Fontane",
            "Trebaseleghe",
            "Trebbio",
            "Trebbio",
            "Trebbo",
            "Trebisacce",
            "Trecasali",
            "Trecase",
            "Trecastagni",
            "Trecate",
            "Trecchina",
            "Trecella",
            "Trecenta",
            "Tredozio",
            "Tregasio",
            "Treggiaia",
            "Treglio",
            "Tregnago",
            "Treia",
            "Treiso",
            "Tremenico",
            "Tremestieri Etneo",
            "Tremezzo",
            "Tremignon",
            "Trenta",
            "Trentinara",
            "Trento",
            "Trentola-Ducenta",
            "Trenzano",
            "Treponti",
            "Treppo Carnico",
            "Treppo Grande",
            "Trepuzzi",
            "Trequanda",
            "Tres",
            "Tresana",
            "Trescine",
            "Trescore Balneario",
            "Trescore Cremasco",
            "Tresigallo-Final di Rero",
            "Tresivio",
            "Tresnuraghes",
            "Trestina",
            "Trevenzuolo",
            "Trevi",
            "Trevi nel Lazio",
            "Trevico",
            "Treviglio",
            "Trevignano",
            "Trevignano Romano",
            "Treville",
            "Treviolo",
            "Treviso",
            "Trezzano Rosa",
            "Trezzano sul Naviglio",
            "Trezzo sull'Adda",
            "Trezzo Tinella",
            "Trezzone",
            "Tribano",
            "Tribiano",
            "Tribogna",
            "Tricarico",
            "Tricase",
            "Tricerro",
            "Tricesimo",
            "Trichiana",
            "Triei",
            "Trieste",
            "Triggiano",
            "Triginto",
            "Trigolo",
            "Trinità",
            "Trinità d'Agultu",
            "Trinità d'Agultu e Vignola",
            "Trinitapoli",
            "Trino",
            "Triora",
            "Tripi",
            "Triscina",
            "Trisobbio",
            "Trissino",
            "Triuggio",
            "Trivento",
            "Trivero-Prativero-Ponzone",
            "Trivigliano",
            "Trivignano",
            "Trivignano Udinese",
            "Trivigno",
            "Trivolzio",
            "Trodena",
            "Trodica",
            "Trofarello",
            "Troia",
            "Troina",
            "Tromello",
            "Tronca",
            "Trontano",
            "Tronzano Lago Maggiore",
            "Tronzano Vercellese",
            "Tropea",
            "Troviggiano",
            "Trovo",
            "Truccazzano",
            "Tubre",
            "Tuenno",
            "Tufara",
            "Tufillo",
            "Tufino",
            "Tufo",
            "Tuglie",
            "Tuili",
            "Tula",
            "Tuoro sul Trasimeno",
            "Turania",
            "Turano Lodigiano",
            "Turate",
            "Turbigo",
            "Turi",
            "Turin",
            "Turri",
            "Turriaco",
            "Turrivalignani",
            "Tursi",
            "Tusa",
            "Tuscania",
            "Tuturano",
            "Ubiale",
            "Ubiale Clanezzo",
            "Uboldo",
            "Ucria",
            "Udine",
            "Ugento",
            "Uggia-Pazzera-Bizzarrino",
            "Uggiano la Chiesa",
            "Uggiano Montefusco",
            "Uggiate Trevano",
            "Ulà Tirso",
            "Ulassai",
            "Uliveto Terme",
            "Ultimo - Ulten",
            "Umbertide",
            "Umbriatico",
            "Urago d'Oglio",
            "Uras",
            "Urbana",
            "Urbania",
            "Urbe",
            "Urbino",
            "Urbisaglia",
            "Urgnano",
            "Uri",
            "Ururi",
            "Urzulei",
            "Uscio",
            "Usellus",
            "Usini",
            "Usmate-Velate",
            "Ussana",
            "Ussaramanna",
            "Ussassai",
            "Usseaux",
            "Usseglio",
            "Ustica",
            "Uta",
            "Uzzano",
            "Vaccarizzo Albanese",
            "Vacchereccia",
            "Vaccheria",
            "Vacone",
            "Vacri",
            "Vada",
            "Vadena",
            "Vado",
            "Vado Centro",
            "Vado Ligure",
            "Vadue",
            "Vaglia",
            "Vaglio Basilicata",
            "Vaglio Serra",
            "Vago",
            "Vaiano",
            "Vaiano Cremasco",
            "Vaie",
            "Vailate",
            "Vairano-Patenora",
            "Vajont",
            "Val della Torre",
            "Val di Cava",
            "Val Masino",
            "Val Rezzo",
            "Valbrona",
            "Valcanneto",
            "Valda",
            "Valdagno",
            "Valdaora di Mezzo",
            "Valdengo",
            "Valderice",
            "Valdieri",
            "Valdina",
            "Valdisotto",
            "Valdobbiadene",
            "Valdottavo",
            "Valduggia",
            "Valeggio",
            "Valeggio sul Mincio",
            "Valentano",
            "Valentino",
            "Valenza",
            "Valenzano",
            "Valenzatico",
            "Valera Fratta",
            "Valfabbrica",
            "Valfenera",
            "Valfloriana",
            "Valganna",
            "Valgatara",
            "Valgioie",
            "Valgoglio",
            "Valgrana",
            "Valgreghentino",
            "Valgrisenche",
            "Valguarnera Caropepe",
            "Vall'Alta",
            "Vallà",
            "Vallada Agordina",
            "Vallanzengo",
            "Vallarsa",
            "Vallata",
            "Valle Agricola",
            "Valle Aurina - Ahrntal",
            "Valle Castellana",
            "Valle d'Aosta",
            "Valle dell'Angelo",
            "Valle di Cadore",
            "Valle di Casies - Gsies",
            "Valle di Maddaloni",
            "Valle di Vado",
            "Valle e Castello",
            "Valle Lomellina",
            "Valle Martella",
            "Valle Mosso",
            "Valle Salimbene",
            "Valle San Bartolomeo",
            "Valle San Nicolao",
            "Valle Santa",
            "Vallebona",
            "Vallecorsa",
            "Vallecrosia",
            "Vallecupa",
            "Valledolmo",
            "Valledoria",
            "Vallefiorita",
            "Valleggia",
            "Vallelonga",
            "Vallelunga Pratameno",
            "Vallemaio",
            "Vallepietra",
            "Vallerano",
            "Vallermosa",
            "Vallerotonda",
            "Vallesaccarda",
            "Vallese",
            "Valleve",
            "Valli",
            "Valli del Pasubio",
            "Vallinfreda",
            "Vallio Terme",
            "Vallo della Lucania",
            "Vallo Torinese",
            "Vallonga",
            "Valloriate",
            "Valmacca",
            "Valmadonna",
            "Valmadrera-Caserta",
            "Valmala",
            "Valmontone",
            "Valmorea",
            "Valnegra",
            "Valpelline",
            "Valperga",
            "Valprato Soana",
            "Valsavarenche",
            "Valsinni",
            "Valstagna",
            "Valstrona",
            "Valtopina",
            "Valtorta",
            "Valtournenche",
            "Valva",
            "Valvasone",
            "Valverde",
            "Valvestino",
            "Valzemola",
            "Vambolieri",
            "Vandoies - Vintl",
            "Vandoies di Sotto",
            "Vanzaghello",
            "Vanzago",
            "Vanzone",
            "Vaprio d'Adda",
            "Vaprio D'Agogna",
            "Varago",
            "Varallo",
            "Varallo Pombia",
            "Varano Borghi",
            "Varano De' Melegari",
            "Varapodio",
            "Varazze",
            "Varco Sabino",
            "Varedo",
            "Varena",
            "Varenna",
            "Varese",
            "Varese Ligure",
            "Varisella",
            "Varmo",
            "Varna",
            "Varsi",
            "Varzi",
            "Varzo",
            "Vas",
            "Vasanello",
            "Vasia",
            "Vassena",
            "Vasto",
            "Vastogirardi",
            "Vattaro",
            "Vauda Canavese Superiore",
            "Vazzano",
            "Vazzola",
            "Vecchiano-Nodica",
            "Vedano al Lambro",
            "Vedano Olona",
            "Veddasca",
            "Vedelago",
            "Vedeseta",
            "Veduggio Con Colzano",
            "Veggia-Villalunga",
            "Veggiano",
            "Veglie",
            "Veglio",
            "Vejano",
            "Velasca",
            "Veleso",
            "Velezzo Lomellina",
            "Velletri",
            "Vellezzo Bellini",
            "Velo",
            "Velo d'Astico",
            "Velo Veronese",
            "Velturno",
            "Venafro",
            "Venaria Reale",
            "Venarotta",
            "Venasca",
            "Venaus",
            "Vendone",
            "Vendrogno",
            "Venegazzù",
            "Venegono Inferiore",
            "Venegono Superiore",
            "Venetico Marina",
            "Venetico Superiore",
            "Venezzano",
            "Veniano",
            "Venice",
            "Venosa",
            "Venticano",
            "Ventimiglia",
            "Ventimiglia di Sicilia",
            "Ventoso",
            "Ventotene",
            "Venturina",
            "Venzone",
            "Verano",
            "Verano - Voeran",
            "Verano Brianza",
            "Verbania",
            "Verbicaro",
            "Vercana",
            "Verceia",
            "Vercelli",
            "Vercurago",
            "Verdellino",
            "Verdello",
            "Verderio Inferiore",
            "Verderio Superiore",
            "Verduno",
            "Vergano-Villa",
            "Vergato",
            "Verghereto",
            "Vergiate",
            "Vergnasco",
            "Verla",
            "Vermezzo",
            "Vermiglio",
            "Vernante",
            "Vernasca",
            "Vernate",
            "Vernazza",
            "Vernio",
            "Vernole",
            "Verolanuova",
            "Verolavecchia",
            "Verolengo",
            "Veroli",
            "Verona",
            "Veronella",
            "Verrayes",
            "Verres",
            "Verretto",
            "Verrone",
            "Verrua Po",
            "Verrua Savoia",
            "Vertemate Con Minoprio",
            "Vertova",
            "Verucchio",
            "Veruno",
            "Vervio",
            "Vervò",
            "Verzino",
            "Verzuolo",
            "Vescovado",
            "Vescovana",
            "Vescovato",
            "Vesime",
            "Vespolate",
            "Vessalico",
            "Vestenanova",
            "Vestignè",
            "Vestone",
            "Vestreno",
            "Veternigo",
            "Vetralla",
            "Vetto",
            "Vezza d'Alba",
            "Vezza d'Oglio",
            "Vezzano",
            "Vezzano Ligure",
            "Vezzano sul Crostolo",
            "Vezzi Portio",
            "Via Lippia",
            "Viadana",
            "Viadanica",
            "Viagrande",
            "Viale",
            "Vialfrè",
            "Viano",
            "Viareggio",
            "Viarigi",
            "Vibo Valentia",
            "Vibonati",
            "Vicalvi",
            "Vicarello",
            "Vicari",
            "Vicaria",
            "Vicchio",
            "Vicenza",
            "Viciomaggio",
            "Vico Canavese",
            "Vico del Gargano",
            "Vico Equense",
            "Vico nel Lazio",
            "Vicofertile",
            "Vicoforte",
            "Vicoli",
            "Vicolungo",
            "Vicomoscano",
            "Vicopisano",
            "Vicovaro",
            "Vidalengo",
            "Vidardo",
            "Viddalba",
            "Vidigulfo",
            "Vidor",
            "Vidracco",
            "Vieste",
            "Vietri di Potenza",
            "Vietri sul Mare",
            "Viganella",
            "Viganò",
            "Vigano San Martino",
            "Vigarano Mainarda",
            "Vigarano Pieve",
            "Vigardolo",
            "Vigasio",
            "Vigatto",
            "Vigevano",
            "Viggianello",
            "Viggiano",
            "Viggiù",
            "Vighignolo",
            "Vighizzolo",
            "Vighizzolo d'Este",
            "Vigliano Biellese",
            "Vigliano d'Asti",
            "Vigliano-Bettolino",
            "Vigliatore 2",
            "Vignacastrisi",
            "Vignale Monferrato",
            "Vignale Riotorto",
            "Vignanello",
            "Vignate",
            "Vignola",
            "Vignola",
            "Vignola",
            "Vignole Borbera",
            "Vignole-Olmi",
            "Vignolo",
            "Vignone",
            "Vigo di Cadore",
            "Vigo di Fassa",
            "Vigo di Ton",
            "Vigodarzere",
            "Vigolo",
            "Vigolo Vattaro",
            "Vigolzone",
            "Vigone",
            "Vigonovo",
            "Vigonovo-Fontanafredda",
            "Vigonza",
            "Vigonzone",
            "Viguzzolo",
            "Vill'Albese",
            "Villa",
            "Villa",
            "Villa",
            "Villa Adriana",
            "Villa Bartolomea",
            "Villa Basilica",
            "Villa Biscossi",
            "Villa Caldari",
            "Villa Carcina",
            "Villa Castelli",
            "Villa Ceccolini",
            "Villa Celiera",
            "Villa Ciambra",
            "Villa Collemandina",
            "Villa Cortese",
            "Villa d'Adda",
            "Villa d'Agri",
            "Villa d'Almè",
            "Villa d'Asolo",
            "Villa d'Ogna",
            "Villa d'Oneta",
            "Villa del Bosco",
            "Villa del Conte",
            "Villa di Briano",
            "Villa di Chiavenna",
            "Villa di Serio",
            "Villa Estense",
            "Villa Faraldi",
            "Villa Fontana",
            "Villa Fornace",
            "Villa Guardia",
            "Villa Lagarina",
            "Villa Latina",
            "Villa Lempa",
            "Villa Literno",
            "Villa Minozzo",
            "Villa Musone",
            "Villa Opicina",
            "Villa Pedergnano",
            "Villa Pigna",
            "Villa Poma",
            "Villa Potenza",
            "Villa Raspa",
            "Villa Raverio",
            "Villa Rendena",
            "Villa Rosa",
            "Villa San Filippo",
            "Villa San Giovanni",
            "Villa San Giovanni in Tuscia",
            "Villa San Giuseppe",
            "Villa San Pietro",
            "Villa San Secondo",
            "Villa Sant'Angelo",
            "Villa Sant'Antonio",
            "Villa Santa Lucia",
            "Villa Santa Lucia degli Abruzzi",
            "Villa Santa Maria",
            "Villa Santina",
            "Villa Santo Stefano",
            "Villa Verde",
            "Villa Verucchio",
            "Villa Vicentina",
            "Villa-Agnedo",
            "Villa-Borgo",
            "Villa-Nabian",
            "Villabassa",
            "Villabate",
            "Villabruna-Umin",
            "Villachiara",
            "Villacidro",
            "Villadeati",
            "Villadose",
            "Villadossola",
            "Villafalletto",
            "Villafranca",
            "Villafranca d'Asti",
            "Villafranca di Verona",
            "Villafranca in Lunigiana",
            "Villafranca Padovana",
            "Villafranca Piemonte",
            "Villafranca Sicula",
            "Villafranca Tirrena",
            "Villafrati",
            "Villaga",
            "Villaganzerla",
            "Villaggio del Pino-Le Ginestre",
            "Villaggio del Sole",
            "Villaggio Montegrappa",
            "Villaggio Residenziale",
            "Villagrande",
            "Villagrande",
            "Villagrande Strisaili",
            "Villaguattera",
            "Villair-Amerique",
            "Villalago",
            "Villalba",
            "Villalba",
            "Villalfonsina",
            "Villalvernia",
            "Villamagna",
            "Villamaina",
            "Villamar",
            "Villamarzana",
            "Villamassargia",
            "Villamiroglio",
            "Villammare",
            "Villandro",
            "Villandro - Villanders",
            "Villanova",
            "Villanova",
            "Villanova",
            "Villanova",
            "Villanova",
            "Villanova",
            "Villanova",
            "Villanova Biellese",
            "Villanova Canavese",
            "Villanova d'Albenga",
            "Villanova d'Ardenghi",
            "Villanova d'Asti",
            "Villanova del Battista",
            "Villanova del Ghebbo Canton",
            "Villanova del Sillaro",
            "Villanova Marchesana",
            "Villanova Mondovì",
            "Villanova Monferrato",
            "Villanova Monteleone",
            "Villanova Solaro",
            "Villanova Truschedu",
            "Villanova Tulo",
            "Villanovaforru",
            "Villanovafranca",
            "Villanterio",
            "Villanuova",
            "Villanuova sul Clisi",
            "Villaperuccio",
            "Villapiana",
            "Villapiana Lido",
            "Villapinta",
            "Villaputzu",
            "Villar Dora",
            "Villar Focchiardo",
            "Villar Pellice",
            "Villar Perosa",
            "Villar San Costanzo",
            "Villarbasse",
            "Villarboit",
            "Villareggia",
            "Villaricca",
            "Villaromagnano",
            "Villarosa",
            "Villarotta",
            "Villasalto",
            "Villasanta",
            "Villaseta",
            "Villasimius",
            "Villasmundo",
            "Villasor",
            "Villaspeciosa",
            "Villassio",
            "Villastellone",
            "Villata",
            "Villatora",
            "Villaurbana",
            "Villavallelonga",
            "Villaverla",
            "Ville Sur Sarre",
            "Villefranche",
            "Villeneuve",
            "Villesse",
            "Villetta Barrea",
            "Villette",
            "Villimpenta",
            "Villongo",
            "Villotta",
            "Vilminore",
            "Vilminore di Scalve",
            "Vimercate",
            "Vimodrone",
            "Vinadio",
            "Vinchiaturo",
            "Vinchio",
            "Vinci",
            "Vinovo",
            "Vinzaglio",
            "Viola",
            "Vione",
            "Vipiteno",
            "Virle Piemonte",
            "Visano",
            "Vische",
            "Visciano",
            "Visco",
            "Visone",
            "Visso",
            "Vistarino",
            "Vistrorio",
            "Vita",
            "Viterbo",
            "Viticuso",
            "Vitigliano",
            "Vitinia",
            "Vitorchiano",
            "Vittoria",
            "Vittorio Veneto",
            "Vittorito",
            "Vittuone",
            "Vitulano",
            "Vitulazio",
            "Viù",
            "Vivaro",
            "Vivaro Romano",
            "Viverone",
            "Vizzini",
            "Vizzola Ticino",
            "Vizzolo Predabissi",
            "Vò",
            "Vobarno",
            "Vobbia",
            "Vocca",
            "Vodo",
            "Voghera",
            "Voghiera",
            "Vogogna",
            "Volano",
            "Volargne",
            "Volla",
            "Volongo",
            "Volpago del Montello",
            "Volpara",
            "Volpedo",
            "Volpeglino",
            "Volpiano",
            "Volta Mantovana",
            "Voltaggio",
            "Voltago",
            "Voltago Agordino",
            "Voltana",
            "Volterra",
            "Voltido",
            "Volturara Appula",
            "Volturara Irpina",
            "Volturino",
            "Volvera",
            "Vomero",
            "Vottignasco",
            "Weather Station",
            "Zaccanopoli",
            "Zafferana Etnea",
            "Zagarise",
            "Zagarolo",
            "Zambana",
            "Zambrone",
            "Zandobbio",
            "Zanè",
            "Zanica",
            "Zapponeta",
            "Zavattarello",
            "Zeccone",
            "Zeddiani",
            "Zelarino",
            "Zelbio",
            "Zelo Buon Persico",
            "Zelo Surrigone",
            "Zeme",
            "Zenevredo",
            "Zenson di Piave",
            "Zepponami",
            "Zerba",
            "Zerbo",
            "Zerbolò",
            "Zerfaliu",
            "Zeri",
            "Zermeghedo",
            "Zero Branco",
            "Zevio",
            "Ziano di Fiemme",
            "Ziano Piacentino",
            "Zibello",
            "Zibido San Giacomo",
            "Zimone",
            "Zinasco Vecchio",
            "Zoagli",
            "Zocca",
            "Zoccorino-Vergo",
            "Zogno",
            "Zola Predosa",
            "Zolla",
            "Zollino",
            "Zona 179",
            "Zona Industriale",
            "Zona Industriale",
            "Zone",
            "Zoppè di Cadore",
            "Zoppola",
            "Zorlesco",
            "Zovencedo",
            "Zubiena",
            "Zuccarello",
            "Zuclo",
            "Zugliano",
            "Zugliano-Terenzano-Cargnacco",
            "Zuglio",
            "Zumaglia",
            "Zumpano",
            "Zungoli",
            "Zungri",
            "Zuni"
        ]
    },
    {
        "label": "Jamaica",
        "code": "JM",
        "phone": "+1-876",
        "cities": [
            "12th Street",
            "Aberdeen",
            "Aboukir",
            "Above Rocks",
            "Accompong",
            "Adelphi",
            "Aenon Town",
            "Airy Castle",
            "Albert Town",
            "Albion",
            "Albion",
            "Alderton",
            "Alexandria",
            "Alley",
            "Alligator Pond",
            "Alligator Pond",
            "Allman Town",
            "Alps",
            "Alston",
            "Alva",
            "Amiel Town",
            "Amity",
            "Amity Hall",
            "Anchovy",
            "Annotto Bay",
            "Arcadia",
            "Arcadia",
            "Arcadia",
            "Arlene Gardens",
            "Arnett Gardens",
            "Ashley",
            "Ashton",
            "Asia/Pratville",
            "Askenish",
            "Auchtembeddie",
            "August Town",
            "Baileys Vale",
            "Balaclava",
            "Balcarres",
            "Ballards  Valley",
            "Bamboo",
            "Bangor Ridge",
            "Banks",
            "Baptist",
            "Barbary Hall",
            "Barbican",
            "Barking Lodge",
            "Barneyside",
            "Barrett Town",
            "Bartons",
            "Bath",
            "Bath",
            "Bath Mountain",
            "Baxter Mountain",
            "Beaufort",
            "Beckford Kraal",
            "Beecher Town",
            "Beeston Spring",
            "Belfield",
            "Bellas Gate",
            "Bellefield",
            "Belvedere",
            "Belvedere",
            "Bensonton",
            "Berkshire",
            "Bethany",
            "Bethel",
            "Bethel Town",
            "Beverley Hills",
            "Bickersteth",
            "Big Bridge",
            "Bigwoods",
            "Bito",
            "Black Hill",
            "Black River",
            "Blackstonedge",
            "Bloxborough",
            "Blue Mountain",
            "Bluefields",
            "Bog",
            "Bog Walk",
            "Bogue",
            "Bogue",
            "Bogwalk",
            "Bohemia",
            "Bombay",
            "Bonnygate",
            "Borobridge",
            "Boucher Park",
            "Boundbrook",
            "Bounty Hall",
            "Bournemouth Gardens",
            "Braes River",
            "Braeton",
            "Brainerd",
            "Brampton",
            "Brandon Hill",
            "Brandon Hill",
            "Brandon Hill",
            "Breastworks",
            "Bridgeport",
            "Brighton",
            "Brixton Hill",
            "Broadgate",
            "Brompton",
            "Broughton",
            "Browns Hill",
            "Browns Town",
            "Bucknor",
            "Bucks Common",
            "Buff Bay",
            "Bull Bay/ Seven Mile",
            "Bull Savanna",
            "Bull Savannah",
            "Bunkers Hill",
            "Burnt Savannah",
            "Burnt Savannah",
            "Bushy Park",
            "Butt-Up",
            "Bybrook",
            "Cacoon",
            "Cacoon Castle",
            "Cairn Curran",
            "Calderwood",
            "Caledonia",
            "Camberwell",
            "Cambridge",
            "Campbell Town",
            "Cane River",
            "Canterbury",
            "Carisbrook",
            "Carmel",
            "Carron Hall",
            "Cascade",
            "Cascade",
            "Cascade",
            "Cash Hill",
            "Cassia Park",
            "Castle Comfort",
            "Castleton",
            "Catadupa",
            "Catherine Hall",
            "Catherine Mount",
            "Cauldwell",
            "Cavaliers",
            "Cave",
            "Cave Valley",
            "Caymanas",
            "Cedar Grove",
            "Cedar Valley",
            "Central Down Town",
            "Central Port Antonio",
            "Central Village",
            "Chalky Hill",
            "Chambers Pen",
            "Chantilly",
            "Chantilly",
            "Chapelton",
            "Charles Town",
            "Charles Town",
            "Chateau",
            "Cherry Gardens",
            "Chester",
            "Chester Castle",
            "Christiana",
            "Chudleigh",
            "Church Corner",
            "Claremont",
            "Clarks Town",
            "Claverty Cottage",
            "Clonmel",
            "Cobbla",
            "Cockburn Gardens",
            "Cockpit",
            "Coffee Piece",
            "Cole Gate",
            "Coleyville",
            "Colonels Ridge",
            "Comfort",
            "Comfort Castle",
            "Comfort Hall",
            "Comfort Hall",
            "Constant Spring",
            "Constant Spring Gardens",
            "Constitution Hill",
            "Content",
            "Content",
            "Content Gap",
            "Content Garden",
            "Cooreville Gardens",
            "Copse",
            "Coral Gardens",
            "Cornwall",
            "Cornwall Mountian",
            "Cotterwood",
            "Cousins Cove",
            "Coxswain",
            "Craig Head",
            "Crawford",
            "Crescent",
            "Crofts Hill",
            "Cromarty",
            "Crooked River",
            "Cross Keys",
            "Cross Roads",
            "Cuffie Ridge",
            "Cumberland",
            "Cumberland",
            "Curatoe Hill",
            "D'Aguilar Town/ Rennock Lodge",
            "Dallas",
            "Dalvey",
            "Daniel Town",
            "Danvers Pen",
            "Darliston",
            "Dawkins",
            "Deeside",
            "Delacree Park/ Union Gardens",
            "Delacree Pen",
            "Delveland",
            "Denham Town",
            "Devon",
            "Devon Pen",
            "Dias",
            "Dillion Bigwoods",
            "Discovery Bay",
            "Dover",
            "Down Town Montego Bay",
            "Downs",
            "Drapers",
            "Drewsland",
            "Drumblair",
            "Duanvale",
            "Duckenfield",
            "Duhaney Park",
            "Duhaney Pen",
            "Dumfries",
            "Duncans",
            "Dundee",
            "Durham",
            "Easington",
            "East Down Town",
            "Eastwood Park Gardens",
            "Edgewater",
            "Effortville",
            "Elderslie",
            "Eleven Miles",
            "Ellen Street",
            "Elleston Flats/ Mona Commons",
            "Enfield",
            "Enfield",
            "Ensom",
            "Epson",
            "Epworth",
            "Evergreen",
            "Ewarton",
            "Exchange",
            "Fairfield",
            "Fairfield",
            "Fairy Hill",
            "Faith's Pen",
            "Falmouth",
            "Farm Heights",
            "Fellowship",
            "Ferris",
            "Ferry",
            "Flagstaff",
            "Flamstead",
            "Flankers",
            "Fletchers Land",
            "Flint River",
            "Flower Hill",
            "Font Hill",
            "Forest Hills Gardens",
            "Forest Hills/ Plantation Height",
            "Fort Williams",
            "Four Paths",
            "Frankfield",
            "Franklyn Town",
            "Free Hill",
            "Freemans Hall",
            "Freetown",
            "French Park",
            "Friendship",
            "Friendship Gap",
            "Frome",
            "Fruitfulvale",
            "Fullersfield",
            "Fullerswood",
            "Galina",
            "Garlands",
            "Gayle",
            "George's Valley",
            "Georges Plain",
            "Gibraltar",
            "Giddy Hall",
            "Gimme-me-bit",
            "Ginger Hill",
            "Ginger Ridge",
            "Glendevon",
            "Glengoffe",
            "Glenmuir",
            "Golden Acres",
            "Golden Grove",
            "Golden Grove",
            "Golden Spring",
            "Good Intent",
            "Gooden's River",
            "Goodwill",
            "Gordon Town",
            "Goshen",
            "Grange",
            "Grange Hill",
            "Grantham",
            "Grants Pen",
            "Granville",
            "Granville",
            "Gravel Hill",
            "Great Pond",
            "Great Valley",
            "Greater Portmore",
            "Green Island",
            "Green Pond",
            "Greendale",
            "Greenvale",
            "Greenwich Town/ Newport West",
            "Greenwood",
            "Gregory Park",
            "Grove Place",
            "Grove Town",
            "Guys Hill",
            "Haddington",
            "Haddo",
            "Hagley Gap",
            "Half Way Tree",
            "Half-Way-Tree",
            "Hampden",
            "Hampstead",
            "Hampton Court",
            "Hampton Green",
            "Hannah Town/ Craig Town",
            "Harbour View",
            "Harewood",
            "Harkers Hall",
            "Harmons",
            "Harmony Town",
            "Harry Watch",
            "Hatfield",
            "Havendale",
            "Hayes",
            "Hayfield",
            "Hazard",
            "Heartease",
            "Hectors River",
            "Hellshire",
            "Hermitage",
            "Hertford",
            "Hibernia",
            "Higgin Land",
            "Highgate",
            "Hillside",
            "Hinds Town",
            "Holland",
            "Hope Bay",
            "Hope Pastures/ UTECH",
            "Hope Tavern",
            "Hopeton",
            "Hopeton",
            "Hopewell",
            "Hughenden",
            "Huntley",
            "Independence City",
            "Inverness",
            "Inverness",
            "Irish Town",
            "Ironshore",
            "Irwin",
            "Islington",
            "Jacks Hill",
            "Jacks River",
            "Jackson Town",
            "James Hill",
            "Jeffery Town",
            "Jericho",
            "Jerusalem",
            "Joe Hut",
            "John's Hall",
            "Johns Hall",
            "Johns Town",
            "Johnson Mountain",
            "Johnson Town",
            "Jones Pen",
            "Jones Town",
            "Junction",
            "Kellits",
            "Kencot",
            "Kendal",
            "Kendal",
            "Kensington",
            "Kentucky",
            "Keystone",
            "Kilmarnoch",
            "Kingston",
            "Kingston Gardens",
            "Kingsvale",
            "Kinloss",
            "Kintyre",
            "Kitson Town",
            "Knockpatrick",
            "Labyrinth",
            "Lacovia",
            "Lambs River",
            "Lancaster",
            "Lances Bay",
            "Lawrence Tavern",
            "Leamington",
            "Leeds",
            "Leith Hall",
            "Lenox Bigwoods",
            "Liguanea",
            "Lilliput",
            "Lime Hall",
            "Lime Tree Gardens",
            "Limit",
            "Linstead",
            "Linton Park",
            "Lionel Town",
            "Litchfield",
            "Lititz",
            "Little London",
            "Llandewey",
            "Llandilo",
            "Lloyds",
            "Lluidas Vale",
            "Logwood",
            "Long Bay",
            "Long Road",
            "Long Road",
            "Longville Park",
            "Longwood",
            "Lorrimers",
            "Lottery",
            "Lucea",
            "Lucea East",
            "Lucea West",
            "Lucky Hill",
            "Lyssons",
            "Mackfield",
            "Macknie",
            "Madras",
            "Maggotty",
            "Maidstone",
            "Majestic Gardens",
            "Maldon",
            "Malvern",
            "Manchioneal",
            "Mandeville",
            "Mandeville Proper",
            "Mango Valley",
            "Manley Meadows",
            "Mannings Hill",
            "Mannings Hill (Rural)",
            "Mansfield",
            "March Town",
            "Marlie Hill",
            "Maroon Town",
            "Martha Brae",
            "Martin",
            "Marverley",
            "Maryland",
            "Maryland",
            "Mason Hall",
            "Mavis Bank",
            "Maxfield Park",
            "May Day",
            "May Pen",
            "May Pen Proper",
            "Meadowbrook",
            "Meadowbrook Estate",
            "Mearnsville",
            "Medina",
            "Merrywood",
            "Middle Quarters",
            "Middleton",
            "Mike Town",
            "Mile Gully",
            "Mile Gully",
            "Milk River",
            "Mineral Heights",
            "Mitchell Town",
            "Mocho",
            "Molynes Four Roads",
            "Molynes Gardens",
            "Mona Heights",
            "Moneague",
            "Montego Bay",
            "Montego Hill",
            "Montpelier",
            "Moore Town",
            "Moores",
            "Morant Bay",
            "Morgans Forest",
            "Morgans Pass",
            "Mount Airy",
            "Mount Airy",
            "Mount Carey",
            "Mount Horeb",
            "Mount James",
            "Mount Lebanus",
            "Mount Moriah",
            "Mount Peto",
            "Mount Pleasant",
            "Mount Salem",
            "Mount Stewart",
            "Mount Zion",
            "Mountain View Gardens",
            "Mountainside",
            "Mulgrave",
            "Myersville",
            "Naggo Head",
            "Nain",
            "Nannyville",
            "Negril",
            "New Denbigh",
            "New Forest",
            "New Green",
            "New Haven",
            "New Kingston",
            "New Longsville",
            "New Market",
            "New Market Oval",
            "New Monkland",
            "New Roads",
            "New Works",
            "Newell",
            "Newport",
            "Newport East",
            "Newton",
            "Newton Square",
            "Niagara",
            "Nonsuch",
            "Norbrook",
            "Norman Gardens",
            "Norwich",
            "Norwood",
            "Oaklands",
            "Ocho Rios",
            "Old Denbigh",
            "Old England",
            "Old Harbour",
            "Old Harbour Bay",
            "Old Harbour Road",
            "Oracabessa",
            "Orange",
            "Orange Bay",
            "Orange Bay",
            "Orange Hill",
            "Orange Hill",
            "Osbourne Store",
            "Paisley",
            "Palmers Cross",
            "Pamphret",
            "Papine",
            "Paradise",
            "Parks Road",
            "Parottee",
            "Parry Town",
            "Part Of Banana Ground",
            "Part of Banana Ground",
            "Part of Douglas Castle",
            "Part Of Gutters",
            "Part of Guys Hill",
            "Part of Kellits",
            "Part of Keystone",
            "Part of Rock Hall",
            "Part of Spaldings",
            "Passage Fort",
            "Passmore Town",
            "Patrick City",
            "Paul Island",
            "Pear Tree",
            "Pear Tree Grove",
            "Peckham",
            "Pedro Plains",
            "Pell River",
            "Pembroke Hall",
            "Pennants",
            "Penwood",
            "Pepper",
            "Perth Town",
            "Petersfield",
            "Petersville",
            "Philadelphia",
            "Pike",
            "Pimento Walk",
            "Pisgah",
            "Pitfour",
            "Pleasant Valley",
            "Plowden",
            "Point Hill",
            "Pondside",
            "Pondside",
            "Poormans Corner",
            "Port Antonio",
            "Port Maria",
            "Port Morant",
            "Port Royal",
            "Porters Mountain",
            "Portland Cottage",
            "Portmore",
            "Porto Bello",
            "Porus",
            "Porus",
            "Potsdam",
            "Priory",
            "Prospect",
            "Prospect",
            "Prospect",
            "Prospect",
            "Queensborough/ Tunbridge",
            "Quick Step",
            "Race Course",
            "Rae Town",
            "Ramble",
            "Ramble",
            "Reading",
            "Red Bank",
            "Red Hills",
            "Red Hills (Rural)",
            "Red Hills Gardens",
            "Red Hills/ Sterling Castle",
            "Redwood",
            "Refuge",
            "Retirement",
            "Retirement",
            "Retreat",
            "Retreat",
            "Retrieve",
            "Revival",
            "Richmond",
            "Richmond",
            "Richmond Park",
            "Richmond Park",
            "Richmond Vale",
            "Rio Bueno",
            "Ritchies",
            "River Head",
            "River Head",
            "Riversdale",
            "Riverton City",
            "Roaring River",
            "Robins Bay",
            "Robins Hall",
            "Rock",
            "Rock Hall",
            "Rock River",
            "Rock Spring",
            "Rocky Point",
            "Roehampton",
            "Roehampton",
            "Rollington Town",
            "Rose Hall",
            "Rose Hall",
            "Rose Heights",
            "Rose Hill",
            "Rose Mount Garden",
            "Rose Town",
            "Rosemount",
            "Rowlandsfield",
            "Royal Flat",
            "Runaway Bay",
            "Russels",
            "Russia",
            "Saint Ann’s Bay",
            "Salt Marsh",
            "Salt River",
            "Salt Spring",
            "Samuels Prospect",
            "Sandside",
            "Sandy Bay",
            "Sandy Bay",
            "Sanguinetti",
            "Santa Cruz",
            "Santoy",
            "Savanna-la-Mar",
            "Savannah-la-mar Business Dist.",
            "Sawyers",
            "Scholefield",
            "Scotts Hall",
            "Scotts Pass",
            "Seaford Town",
            "Seaforth",
            "Seaton Crescent",
            "Seaview Gardens",
            "Seaward Pen",
            "Sedgepond",
            "Seven Rivers",
            "Seymour Lands",
            "Shefield",
            "Sherwood Content",
            "Sherwood Forest",
            "Shirley Castle",
            "Shoalin Grotto",
            "Silent Hill",
            "Siloah",
            "Skibo",
            "Sligoville",
            "Slipe",
            "Smithfield",
            "Smithville",
            "Snow Hill",
            "Snowdon",
            "Somerset",
            "Somerset",
            "Somerton",
            "Southfield",
            "Southside",
            "Spaldings",
            "Spanish Town",
            "Spanish Town Central",
            "Spicy Hill",
            "Spot Valley",
            "Spring Bank",
            "Spring Garden",
            "Spring Garden",
            "Spring Ground",
            "Spring Hill",
            "Springfield",
            "Springfield",
            "Spur Tree",
            "St. Ann's Bay",
            "St. D'Acre",
            "St. John's East",
            "St. John's West",
            "St. Leonards",
            "St. Margaret's Bay",
            "St. Paul's",
            "St. Peter's",
            "St. Vincent",
            "Stadium Gardens",
            "Steer Town",
            "Stettin",
            "Stewart Town",
            "Stonehenge",
            "Stony Hill",
            "Strathbogie",
            "Strawberry",
            "Struie",
            "Sturge Town",
            "Success",
            "Summer Hill",
            "Summerfield",
            "Sunderland",
            "Sunning Hill",
            "Swaby's Hope",
            "Swallowfield",
            "Swift River",
            "Tangle River",
            "Temple Hall",
            "Thompson Town",
            "Thornton",
            "Three Mile River",
            "Three Oaks/ Glendale",
            "Tivoli Gardens",
            "Tollgate",
            "Top Hill",
            "Top Hill",
            "Tower Hill",
            "Tower Hill/Moy Hall",
            "Town Head",
            "Trafalgar Park",
            "Tranquility",
            "Treadlight",
            "Treasure Beach",
            "Trench Town",
            "Trinity Ville",
            "Trinityville",
            "Troja",
            "Trout Hall",
            "Troy",
            "Truro",
            "Tucker",
            "Turners",
            "Ulster Spring",
            "Unity Hall",
            "University",
            "Vaughnsfield",
            "Victoria Town",
            "Vineyard Town",
            "Wait-A-Bit",
            "Wakefield",
            "Walderston",
            "Walkers Wood",
            "Waltham Gardens",
            "Warminister",
            "Warsop",
            "Washington Gardens",
            "Watchwell",
            "Water Lane",
            "Water Works",
            "Waterford",
            "Waterhouse",
            "Watermount",
            "Watham",
            "Watt Town",
            "Welcome Hall",
            "West Cumberland",
            "West Down Town",
            "West Green",
            "Wheelerfield",
            "White Hall",
            "White Hill",
            "White Horses",
            "Whitehall",
            "Whitehouse",
            "Whitfield Town",
            "Whithorn",
            "Williamsfield",
            "Williamsfield",
            "Willowdene",
            "Wilmington",
            "Wilson's Run",
            "Wilton Gardens/ Rema",
            "Winchester",
            "Windsor",
            "Windsor Castle",
            "Windsor Forest",
            "Wirefence",
            "Woodford",
            "Woodford Park",
            "Woodhall",
            "Woodlands",
            "Woodpark",
            "Woodside",
            "Woodsville",
            "Yallahs",
            "York",
            "York Castle",
            "York Town",
            "Zaidie Gardens"
        ]
    },
    {
        "label": "Japan",
        "code": "JP",
        "phone": "81",
        "cities": [
            "Abashiri",
            "Abashiri Shi",
            "Abiko",
            "Abiko-shi",
            "Achi",
            "Adachi Ku",
            "Agano Shi",
            "Agatsuma-gun",
            "Agematsu",
            "Ageo Shi",
            "Ageoshimo",
            "Aioi",
            "Aioi Shi",
            "Aira Shi",
            "Aisai-shi",
            "Aizu-wakamatsu Shi",
            "Akabira",
            "Akabira-shi",
            "Akaiwa Shi",
            "Akashi",
            "Akashi Shi",
            "Aki",
            "Aki-takata Shi",
            "Akiruno-shi",
            "Akishima-shi",
            "Akita",
            "Akita Shi",
            "Akune",
            "Akune Shi",
            "Ama-gun",
            "Amagasaki Shi",
            "Amagi",
            "Amakusa Gun",
            "Amakusa Shi",
            "Amami",
            "Amami Shi",
            "Ami",
            "Anan",
            "Anan Shi",
            "Anjō-shi",
            "Annaka",
            "Annaka Shi",
            "Aoki",
            "Aomori",
            "Aomori Shi",
            "Arai",
            "Arakawa Ku",
            "Arao Shi",
            "Arashiyama",
            "Arida Shi",
            "Asago-shi",
            "Asahi",
            "Asahi",
            "Asahi-shi",
            "Asahikawa",
            "Asaka",
            "Asaka-shi",
            "Asakuchi Shi",
            "Asakura Shi",
            "Ashibetsu",
            "Ashibetsu-shi",
            "Ashikaga",
            "Ashiya",
            "Ashiya Shi",
            "Aso-shi",
            "Atami",
            "Atami-shi",
            "Atsugi",
            "Atsugi Shi",
            "Awa-shi",
            "Awaji Shi",
            "Awara-shi",
            "Ayabe",
            "Ayabe-shi",
            "Ayase Shi",
            "Azumino",
            "Azumino-Shi",
            "Bandō",
            "Bandō-shi",
            "Beppu",
            "Beppu Shi",
            "Bibai",
            "Bizen Shi",
            "Bungo-ōno Shi",
            "Bungo-Takada-shi",
            "Bunkyō-ku",
            "Buzen",
            "Buzen-shi",
            "Chatan",
            "Chiba",
            "Chichibu",
            "Chichibu-shi",
            "Chigasaki",
            "Chigasaki Shi",
            "Chikugo Shi",
            "Chikuhoku",
            "Chikuma",
            "Chikuma Shi",
            "Chikusei",
            "Chikusei-shi",
            "Chikushino-shi",
            "Chino",
            "Chino",
            "Chino Shi",
            "Chiryū",
            "Chiryū-shi",
            "Chita",
            "Chita-shi",
            "Chitose",
            "Chitose Shi",
            "Chiyoda-ku",
            "Chōfu",
            "Chōfu-shi",
            "Chōshi-shi",
            "Chūō Ku",
            "Chūō-shi",
            "Daigo",
            "Daisen",
            "Daisen-shi",
            "Daitō Shi",
            "Daitōchō",
            "Date",
            "Date-shi",
            "Date-shi",
            "Dazaifu-shi",
            "Ebetsu",
            "Ebina Shi",
            "Ebino-shi",
            "Echizen-shi",
            "Edogawa Ku",
            "Edosaki",
            "Ena-shi",
            "Eniwa-shi",
            "Enzan",
            "Etajima-shi",
            "Fuchū-shi",
            "Fuchū-shi",
            "Fuchūchō",
            "Fuefuki-shi",
            "Fuji Shi",
            "Fuji-yoshida Shi",
            "Fujieda Shi",
            "Fujiidera-shi",
            "Fujikawaguchiko",
            "Fujimi",
            "Fujimi-shi",
            "Fujimino Shi",
            "Fujinomiya",
            "Fujinomiya Shi",
            "Fujioka",
            "Fujioka",
            "Fujioka Shi",
            "Fujisawa",
            "Fujisawa Shi",
            "Fujishiro",
            "Fujiyoshida",
            "Fukagawa",
            "Fukagawa-shi",
            "Fukaya-shi",
            "Fukayachō",
            "Fukiage-fujimi",
            "Fukuchiyama-shi",
            "Fukuechō",
            "Fukui-shi",
            "Fukumitsu",
            "Fukuoka",
            "Fukuoka-shi",
            "Fukura",
            "Fukuroi",
            "Fukuroi-shi",
            "Fukushima",
            "Fukushima Shi",
            "Fukutsu Shi",
            "Fukuyama",
            "Fukuyama Shi",
            "Funabashi-shi",
            "Funaishikawa",
            "Funehikimachi-funehiki",
            "Furano-shi",
            "Furukawa",
            "Fussa",
            "Futtsu",
            "Gamagōri",
            "Gamagōri-shi",
            "Geisei",
            "Gero",
            "Gero-shi",
            "Gifu-shi",
            "Ginowan Shi",
            "Gobō",
            "Gōdo",
            "Gojō",
            "Gojō-shi",
            "Gose",
            "Gosen",
            "Gosen Shi",
            "Goshogawara",
            "Goshogawara Shi",
            "Gotenba Shi",
            "Gotō Shi",
            "Gōtsu Shi",
            "Gōtsuchō",
            "Gujō",
            "Gujō-shi",
            "Gushikawa",
            "Gyōda",
            "Habikino-shi",
            "Hachimantai",
            "Hachimantai Shi",
            "Hachinohe",
            "Hachinohe Shi",
            "Hachiōji",
            "Hadano",
            "Hadano-shi",
            "Hagi",
            "Hagi Shi",
            "Haibara-akanedai",
            "Hakodate",
            "Hakodate Shi",
            "Hakone",
            "Hakuba",
            "Hakuba",
            "Hakui",
            "Hakui Shi",
            "Hakusan Shi",
            "Hamada",
            "Hamada Shi",
            "Hamakita",
            "Hamamatsu",
            "Hamamatsu-shi",
            "Hamanoichi",
            "Hamura-shi",
            "Hanamaki",
            "Hanamaki Shi",
            "Hanawa",
            "Handa",
            "Handa-shi",
            "Hannan",
            "Hannan Shi",
            "Hannō",
            "Hannō-shi",
            "Hanyū",
            "Hanyū-shi",
            "Hara",
            "Hasaki",
            "Hashima",
            "Hashima-shi",
            "Hashimoto",
            "Hashimoto Shi",
            "Hasuda",
            "Hasuda-shi",
            "Hatogaya-honchō",
            "Hatsukaichi",
            "Hatsukaichi-shi",
            "Hayama",
            "Heda",
            "Hekinan",
            "Hekinan-shi",
            "Hida",
            "Hida Shi",
            "Hidaka",
            "Hidaka-shi",
            "Higashi-murayama-shi",
            "Higashihiroshima Shi",
            "Higashikagawa Shi",
            "Higashikurume-shi",
            "Higashimatshushima Shi",
            "Higashimatsushima",
            "Higashimatsuyama Shi",
            "Higashimurayama",
            "Higashine",
            "Higashine Shi",
            "Higashiōmi-shi",
            "Higashiōsaka Shi",
            "Higashiyamato",
            "Higashiyamato-shi",
            "Hiji",
            "Hikari",
            "Hikari Shi",
            "Hiki-gun",
            "Hikone",
            "Hikone-shi",
            "Himeji",
            "Himeji Shi",
            "Himi Shi",
            "Himimachi",
            "Hino",
            "Hino",
            "Hino-shi",
            "Hioki",
            "Hioki Shi",
            "Hirado",
            "Hirado Shi",
            "Hirakata",
            "Hirakata Shi",
            "Hirakawa",
            "Hirakawa Shi",
            "Hirara",
            "Hiratachō",
            "Hiratsuka",
            "Hiratsuka Shi",
            "Hiraya",
            "Hirosaki",
            "Hirosaki Shi",
            "Hiroshima",
            "Hiroshima-shi",
            "Hisai-motomachi",
            "Hita",
            "Hita Shi",
            "Hitachi",
            "Hitachi-Naka",
            "Hitachi-ota",
            "Hitachi-shi",
            "Hitachinaka-shi",
            "Hitachiōmiya-shi",
            "Hitachiōta-shi",
            "Hitoyoshi",
            "Hitoyoshi Shi",
            "Hobaramachi",
            "Hōfu",
            "Hofu Shi",
            "Hōjō",
            "Hokota-shi",
            "Hokuto",
            "Hokuto",
            "Hokuto-shi",
            "Hokuto-shi",
            "Honchō",
            "Honchō",
            "Hondomachi-hondo",
            "Honjō",
            "Honjō Shi",
            "Honmachi",
            "Hōryūji",
            "Hotaka",
            "Hyūga-shi",
            "Ibara",
            "Ibara Shi",
            "Ibaraki",
            "Ibaraki Shi",
            "Ibusuki",
            "Ibusuki Shi",
            "Ichihara",
            "Ichihara Shi",
            "Ichikawa Shi",
            "Ichikikushikino Shi",
            "Ichinohe",
            "Ichinomiya",
            "Ichinomiya-shi",
            "Ichinoseki",
            "Ichinoseki-shi",
            "Iga-shi",
            "Iida",
            "Iida",
            "Iida-shi",
            "Iijima",
            "Iiyama",
            "Iiyama",
            "Iiyama Shi",
            "Iizuka",
            "Iizuka Shi",
            "Iizuna",
            "Ijūin",
            "Ikeda",
            "Ikeda",
            "Ikeda Shi",
            "Ikedachō",
            "Iki Shi",
            "Ikoma",
            "Ikoma-shi",
            "Ikusaka",
            "Imabari-shi",
            "Imaichi",
            "Imari Shi",
            "Imarichō-kō",
            "Imizu Shi",
            "Ina",
            "Ina",
            "Ina-shi",
            "Inabe",
            "Inabe-shi",
            "Inagi-shi",
            "Inashiki",
            "Inashiki-shi",
            "Inawashiro",
            "Inazawa",
            "Inazawa-shi",
            "Innoshima",
            "Ino",
            "Inuyama",
            "Inuyama-shi",
            "Inzai-shi",
            "Iruma-shi",
            "Isa Shi",
            "Isahaya",
            "Isahaya-shi",
            "Isawa",
            "Ise",
            "Ise-shi",
            "Isehara",
            "Isehara Shi",
            "Isesaki",
            "Isesaki Shi",
            "Ishigaki",
            "Ishigaki-shi",
            "Ishige",
            "Ishii",
            "Ishikari",
            "Ishikari-shi",
            "Ishikawa",
            "Ishikawa",
            "Ishiki",
            "Ishinomaki",
            "Ishinomaki Shi",
            "Ishioka",
            "Ishioka-shi",
            "Isumi",
            "Isumi Shi",
            "Itabashi-ku",
            "Itako",
            "Itako-shi",
            "Itami",
            "Itami Shi",
            "Itō",
            "Itō Shi",
            "Itoigawa",
            "Itoigawa Shi",
            "Itoman",
            "Itoman Shi",
            "Itoshima-shi",
            "Itsukaichi",
            "Iwade",
            "Iwade Shi",
            "Iwai",
            "Iwaki",
            "Iwaki-shi",
            "Iwakuni Shi",
            "Iwakura",
            "Iwakura-shi",
            "Iwamizawa",
            "Iwamizawa-shi",
            "Iwanai",
            "Iwanuma",
            "Iwanuma-shi",
            "Iwase",
            "Iwata",
            "Iwata-shi",
            "Iwate-gun",
            "Iwatsuki",
            "Iyo",
            "Iyo-shi",
            "Izu",
            "Izu-shi",
            "Izumi",
            "Izumi",
            "Izumi Shi",
            "Izumi Shi",
            "Izumiōtsu",
            "Izumiōtsu Shi",
            "Izumisano",
            "Izumisano Shi",
            "Izumo",
            "Izumo Shi",
            "Izunokuni-shi",
            "Jōetsu",
            "Jōetsu Shi",
            "Jōsō-shi",
            "Jōyō Shi",
            "Kadoma",
            "Kadoma Shi",
            "Kaga Shi",
            "Kagoshima",
            "Kagoshima Shi",
            "Kahoku Shi",
            "Kai-shi",
            "Kainan",
            "Kainan Shi",
            "Kaizu-shi",
            "Kaizuka",
            "Kaizuka Shi",
            "Kajiki",
            "Kakamigahara-shi",
            "Kakegawa",
            "Kakegawa Shi",
            "Kakogawa Shi",
            "Kakogawachō-honmachi",
            "Kakuda",
            "Kakuda Shi",
            "Kakunodatemachi",
            "Kama Shi",
            "Kamagaya Shi",
            "Kamaishi",
            "Kamaishi-shi",
            "Kamakura",
            "Kamakura Shi",
            "Kameda-honchō",
            "Kameoka",
            "Kameoka-shi",
            "Kameyama",
            "Kameyama Shi",
            "Kamiamakusa Shi",
            "Kamifukuoka",
            "Kamigyō-ku",
            "Kamiichi",
            "Kamiiso",
            "Kamikawa",
            "Kamimaruko",
            "Kaminokawa",
            "Kaminoyama",
            "Kaminoyama-shi",
            "Kamirenjaku",
            "Kamisu-shi",
            "Kamo",
            "Kamo Shi",
            "Kamogatachō-kamogata",
            "Kamogawa",
            "Kamogawa-shi",
            "Kamojimachō-jōgejima",
            "Kan’onji Shi",
            "Kan’onjichō",
            "Kanaya",
            "Kanazawa",
            "Kanazawa-shi",
            "Kanda",
            "Kanekomachi",
            "Kani-shi",
            "Kanie",
            "Kannabechō-yahiro",
            "Kanoya",
            "Kanoya Shi",
            "Kanuma",
            "Kanuma-shi",
            "Kanzaki Shi",
            "Karasuyama",
            "Karatsu",
            "Karatsu Shi",
            "Kariya",
            "Kariya",
            "Kariya-shi",
            "Karuizawa",
            "Kasai Shi",
            "Kasama",
            "Kasama-shi",
            "Kasamatsuchō",
            "Kasaoka",
            "Kasaoka Shi",
            "Kaseda-shirakame",
            "Kashiba-shi",
            "Kashihara",
            "Kashihara-shi",
            "Kashima",
            "Kashima Shi",
            "Kashima-shi",
            "Kashiwa",
            "Kashiwa Shi",
            "Kashiwara-shi",
            "Kashiwazaki",
            "Kashiwazaki Shi",
            "Kasuga Shi",
            "Kasugai",
            "Kasugai-shi",
            "Kasukabe",
            "Kasukabe Shi",
            "Kasumigaura",
            "Kasumigaura-shi",
            "Katagami",
            "Katagami-shi",
            "Katano Shi",
            "Katō Shi",
            "Katori-shi",
            "Katsuragi",
            "Katsuragi Shi",
            "Katsuren-haebaru",
            "Katsushika Ku",
            "Katsuta",
            "Katsuura",
            "Katsuura Gun",
            "Katsuura-shi",
            "Katsuyama",
            "Katsuyama Shi",
            "Kawachi-nagano Shi",
            "Kawage",
            "Kawagoe",
            "Kawagoe-shi",
            "Kawaguchi",
            "Kawaguchi-shi",
            "Kawakami",
            "Kawanishi",
            "Kawanishi Shi",
            "Kawanoechō",
            "Kawasaki",
            "Kawasaki",
            "Kawasaki-shi",
            "Kazo",
            "Kazo-shi",
            "Kazuno Shi",
            "Kesennuma",
            "Kesennuma Shi",
            "Kihoku-chō",
            "Kijimadaira",
            "Kikuchi",
            "Kikuchi Shi",
            "Kikugawa-shi",
            "Kimitsu",
            "Kinokawa Shi",
            "Kirishima Shi",
            "Kiryū",
            "Kiryū Shi",
            "Kisai",
            "Kisarazu",
            "Kisarazu Shi",
            "Kishiwada",
            "Kishiwada Shi",
            "Kiso",
            "Kita-ku",
            "Kitaaiki",
            "Kitaakita-shi",
            "Kitagawa",
            "Kitahama",
            "Kitahiroshima",
            "Kitahiroshima-shi",
            "Kitaibaraki",
            "Kitaibaraki-shi",
            "Kitakami",
            "Kitakami-shi",
            "Kitakata",
            "Kitakata-shi",
            "Kitakyushu",
            "Kitami",
            "Kitamoto Shi",
            "Kitanagoya",
            "Kitanagoya-shi",
            "Kitsuki",
            "Kitsuki Shi",
            "Kiyose-shi",
            "Kiyosu-shi",
            "Kizugawa-shi",
            "Kobayashi",
            "Kobayashi Shi",
            "Kobe",
            "Kōchi",
            "Kodaira-shi",
            "Kodamachō-kodamaminami",
            "Kōfu",
            "Kōfu-shi",
            "Koga",
            "Koga",
            "Koga-shi",
            "Koga-shi",
            "Koganei-shi",
            "Kogota",
            "Kōka-shi",
            "Kokubu-matsuki",
            "Kokubunji",
            "Kokubunji-shi",
            "Komae-shi",
            "Komagane",
            "Komagane-shi",
            "Komaki",
            "Komaki-shi",
            "Komatsu",
            "Komatsushima Shi",
            "Komatsushimachō",
            "Komono",
            "Komoro",
            "Komoro",
            "Komoro Shi",
            "Kōnan",
            "Kōnan",
            "Konan-shi",
            "Kōnan-shi",
            "Kōnosu",
            "Konosu-Shi",
            "Kōriyama",
            "Kōriyama Shi",
            "Kosai",
            "Kosai-shi",
            "Koseda",
            "Kōshi Shi",
            "Koshigaya Shi",
            "Koshima",
            "Kōshū-shi",
            "Kōtō-ku",
            "Kōya",
            "Kozakai-chō",
            "Ktiami Shi",
            "Kudamatsu",
            "Kudamatsu Shi",
            "Kuji",
            "Kuji-shi",
            "Kuki-shi",
            "Kukichūō",
            "Kumagaya Shi",
            "Kumamoto",
            "Kumamoto Shi",
            "Kumano",
            "Kumano-shi",
            "Kunisaki-shi",
            "Kunitachi-shi",
            "Kurashiki",
            "Kurashiki Shi",
            "Kurayoshi-shi",
            "Kure",
            "Kure-shi",
            "Kurihara",
            "Kurihara Shi",
            "Kurihashi",
            "Kurio",
            "Kurobe-shi",
            "Kuroda",
            "Kuroishi",
            "Kuroishi Shi",
            "Kuroiso",
            "Kuroshio",
            "Kurume",
            "Kurume Shi",
            "Kusatsu",
            "Kusatsu-shi",
            "Kushikino",
            "Kushima",
            "Kushima Shi",
            "Kushiro",
            "Kushiro Shi",
            "Kuwana",
            "Kuwana-shi",
            "Kyōtanabe Shi",
            "Kyōtango-shi",
            "Kyoto",
            "Machida",
            "Machida-shi",
            "Maebaru-chūō",
            "Maebashi",
            "Maebashi Shi",
            "Maibara",
            "Maibara-shi",
            "Maizuru",
            "Maizuru-shi",
            "Makabe",
            "Maki",
            "Makinohara Shi",
            "Makubetsu",
            "Makurazaki",
            "Makurazaki Shi",
            "Maniwa-shi",
            "Marugame",
            "Marugame Shi",
            "Marumori",
            "Maruoka",
            "Masaki-chō",
            "Mashiko",
            "Masuda",
            "Masuda Shi",
            "Matsubara",
            "Matsubara-shi",
            "Matsubase",
            "Matsudo Shi",
            "Matsue",
            "Matsue Shi",
            "Matsukawa",
            "Matsumoto",
            "Matsumoto",
            "Matsumoto Shi",
            "Matsushima",
            "Matsutō",
            "Matsuura",
            "Matsuura Shi",
            "Matsuyama-shi",
            "Matsuzaka-shi",
            "Meguro-ku",
            "Menuma",
            "Mibu",
            "Midori",
            "Midori Shi",
            "Mihara",
            "Mihara",
            "Mihara Shi",
            "Miharu",
            "Mikasa",
            "Mikasa-shi",
            "Miki",
            "Miki Shi",
            "Mikuni",
            "Mima Shi",
            "Mimasaka-shi",
            "Minakuchichō-matoba",
            "Minamata",
            "Minamata Shi",
            "Minami Alps-shi",
            "Minami-Sōma",
            "Minamiaiki",
            "Minamiashigara",
            "Minamiashigara Shi",
            "Minamiawaji Shi",
            "Minamibōsō Shi",
            "Minamikyushu Shi",
            "Minamimaki",
            "Minamiminowa",
            "Minamirinkan",
            "Minamisatsuma Shi",
            "Minamishimabara-shi",
            "Minamisōma Shi",
            "Minamiuonuma Shi",
            "Minato",
            "Minato-ku",
            "Mine Shi",
            "Mino",
            "Mino",
            "Mino-shi",
            "Minokamo",
            "Minokamo-shi",
            "Minoo Shi",
            "Minowa",
            "Misato Shi",
            "Misawa",
            "Misawa Shi",
            "Mishima",
            "Mishima Shi",
            "Mishima-gun",
            "Mitaka-shi",
            "Mitake",
            "Mito",
            "Mito-shi",
            "Mitoyo Shi",
            "Mitsukaidō",
            "Mitsuke",
            "Mitsuke Shi",
            "Mitsushima",
            "Miura",
            "Miura Shi",
            "Miyada",
            "Miyajima",
            "Miyaki-gun",
            "Miyako",
            "Miyako-shi",
            "Miyakojima Shi",
            "Miyakonojō",
            "Miyakonojō Shi",
            "Miyama Shi",
            "Miyanoura",
            "Miyata",
            "Miyawaka Shi",
            "Miyazaki",
            "Miyazaki-shi",
            "Miyazu",
            "Miyazu-shi",
            "Miyoshi",
            "Miyoshi Shi",
            "Miyoshi Shi",
            "Miyoshi-gun",
            "Miyoshi-shi",
            "Miyota",
            "Mizuho-shi",
            "Mizunami",
            "Mizunami-shi",
            "Mizusawa",
            "Mobara",
            "Mobara Shi",
            "Mombetsu",
            "Monbetsu Shi",
            "Mooka",
            "Mooka-shi",
            "Mori",
            "Moriguchi",
            "Morioka",
            "Morioka-shi",
            "Moriya",
            "Moriya-shi",
            "Moriyama",
            "Moriyama-shi",
            "Morohongō",
            "Motegi",
            "Motomachi",
            "Motomiya",
            "Motomiya-shi",
            "Motosu-shi",
            "Motoyama",
            "Muikamachi",
            "Mukō Shi",
            "Munakata-shi",
            "Murakami Shi",
            "Muramatsu",
            "Murayama",
            "Murayama Shi",
            "Muroran",
            "Muroran-shi",
            "Muroto",
            "Musashimurayama-shi",
            "Musashino",
            "Musashino-shi",
            "Mutsu",
            "Mutsu-shi",
            "Myoko-shi",
            "Nabari",
            "Nabari-shi",
            "Nagahama",
            "Nagahama-shi",
            "Nagai",
            "Nagai-shi",
            "Nagakute-shi",
            "Nagano",
            "Nagano",
            "Nagano Shi",
            "Nagaoka",
            "Nagaoka Shi",
            "Nagaokakyō Shi",
            "Nagareyama",
            "Nagareyama-shi",
            "Nagasaki",
            "Nagasaki-shi",
            "Nagata",
            "Nagato",
            "Nagato Shi",
            "Nagawa",
            "Nagiso",
            "Nago",
            "Nago Shi",
            "Nagoya",
            "Naha",
            "Naha Shi",
            "Nahari",
            "Naka",
            "Naka-gun",
            "Nakagami-gun",
            "Nakagawa",
            "Nakajō",
            "Nakama",
            "Nakama Shi",
            "Nakamura",
            "Nakano",
            "Nakano Shi",
            "Nakano-ku",
            "Nakanojōmachi",
            "Nakatosa",
            "Nakatsu",
            "Nakatsu Shi",
            "Nakatsugawa",
            "Nakatsugawa-shi",
            "Namegata",
            "Namegata-shi",
            "Namerikawa",
            "Namerikawa-shi",
            "Namie",
            "Namioka",
            "Nanao Shi",
            "Nanjō",
            "Nanjō Shi",
            "Nankoku",
            "Nantan-shi",
            "Nanto Shi",
            "Nanto-shi",
            "Nanyō Shi",
            "Nara-shi",
            "Narashino-shi",
            "Narita",
            "Narita-shi",
            "Narutō",
            "Naruto-shi",
            "Narutochō-mitsuishi",
            "Nasukarasuyama",
            "Nasukarasuyama-shi",
            "Nasushiobara-shi",
            "Natori Shi",
            "Nayoro",
            "Nayoro Shi",
            "Naze",
            "Neba",
            "Nemuro",
            "Nemuro-shi",
            "Nerima-ku",
            "Neyagawa",
            "Neyagawa Shi",
            "Nichinan",
            "Nichinan Shi",
            "Nihommatsu",
            "Nihonmatsu Shi",
            "Niigata",
            "Niigata Shi",
            "Niihama",
            "Niihama-shi",
            "Niimi",
            "Niimi Shi",
            "Niitsu-honchō",
            "Niiza-shi",
            "Nikaho-shi",
            "Nikkō",
            "Nikko-shi",
            "Ninohe",
            "Ninohe Shi",
            "Ninomiya",
            "Nirasaki",
            "Nirasaki-shi",
            "Niseko Town",
            "Nishi-Tokyo-shi",
            "Nishifukuma",
            "Nishinomiya Shi",
            "Nishinomiya-hama",
            "Nishinoomote",
            "Nishinoomote Shi",
            "Nishio",
            "Nishio-shi",
            "Nishishinminato",
            "Nishitōkyō-shi",
            "Nishiwaki",
            "Nishiwaki-shi",
            "Nisshin-shi",
            "Niyodogawa",
            "Nobeoka",
            "Nobeoka-shi",
            "Noboribetsu",
            "Noboribetsu-shi",
            "Noda",
            "Noda-shi",
            "Nōgata",
            "Nōgata Shi",
            "Nomi Shi",
            "Nonoichi",
            "Nonoichi-shi",
            "Noshiro",
            "Noshiro Shi",
            "Nozawaonsen",
            "Numata",
            "Numata Shi",
            "Numazu-shi",
            "Nyūzen",
            "Ōami",
            "Ōamishirasato-shi",
            "Ōarai",
            "Obama",
            "Obama-shi",
            "Obanazawa",
            "Obanazawa Shi",
            "Obihiro",
            "Obihiro Shi",
            "Obita",
            "Ōbu",
            "Ōbu-shi",
            "Obuse",
            "Ochi",
            "Ōda Shi",
            "Ōdachō-ōda",
            "Ōdate",
            "Ōdate-shi",
            "Odawara",
            "Odawara-shi",
            "Ōfunato",
            "Ōfunato-shi",
            "Oga",
            "Oga-shi",
            "Ōgaki-shi",
            "Ogawa",
            "Ogawa",
            "Ogi-shi",
            "Ogōri-shi",
            "Ogōri-shimogō",
            "Ōhara",
            "Ōi",
            "Ōi-gun",
            "Ōiso",
            "Ōita",
            "Ōita-shi",
            "Ojiya",
            "Ojiya-shi",
            "Ōkawa",
            "Ōkawa",
            "Ōkawa-shi",
            "Ōkawara",
            "Okaya",
            "Okaya",
            "Okaya Shi",
            "Okayama",
            "Okayama Shi",
            "Okazaki-shi",
            "Okegawa",
            "Okegawa-shi",
            "Okinawa",
            "Okinawa Shi",
            "Ōkuchi-shinohara",
            "Okunoya",
            "Ōkuwa",
            "Ōmachi",
            "Ōmachi",
            "Ōmachi-shi",
            "Omaezaki-shi",
            "Ōmagari",
            "Ōmamachō-ōmama",
            "Ōme",
            "Ōme-shi",
            "Omi",
            "Omigawa",
            "Ōmihachiman",
            "Ōmihachiman-shi",
            "Omitama-shi",
            "Ōmiya",
            "Ōmura",
            "Ōmura-shi",
            "Ōmuta",
            "Ōmuta Shi",
            "Onagawa Chō",
            "Onga-gun",
            "Ono",
            "Ono",
            "Ono Shi",
            "Ōno-hara",
            "Ōno-shi",
            "Onoda",
            "Ōnojō",
            "Ōnojō Shi",
            "Onomichi",
            "Onomichi-shi",
            "Osaka",
            "Ōsaka-sayama Shi",
            "Ōsaki",
            "Ōsaki Shi",
            "Ōsato-gun",
            "Ōshika",
            "Ōshū",
            "Ōshū-shi",
            "Ōta",
            "Ōta-ku",
            "Ōta-shi",
            "Ōtake",
            "Ōtake-shi",
            "Ōtaki",
            "Otari",
            "Otaru",
            "Otaru-shi",
            "Ōtawara",
            "Ōtawara-shi",
            "Otofuke",
            "Ōtoyo",
            "Ōtsu-shi",
            "Ōtsuchi",
            "Ōtsuki",
            "Ōtsuki",
            "Ōtsuki-shi",
            "Owariasahi-shi",
            "Owase",
            "Oyabe",
            "Oyabe Shi",
            "Ōyama",
            "Oyama",
            "Oyama-shi",
            "Ōzu",
            "Ōzu",
            "Ōzu-shi",
            "Rebun Gun",
            "Rifu",
            "Rikuzentakata-shi",
            "Rishiri Gun",
            "Rishiri Town",
            "Rittō-shi",
            "Rumoi",
            "Rumoi-shi",
            "Ryōtsu-minato",
            "Ryūgasaki",
            "Ryūgasaki-shi",
            "Ryūō",
            "Sabae",
            "Sabae-shi",
            "Sado Shi",
            "Saga",
            "Saga Shi",
            "Sagae",
            "Sagae-shi",
            "Sagamihara-shi",
            "Sagara",
            "Saijō",
            "Saijō-shi",
            "Saikai-shi",
            "Saiki",
            "Saiki-shi",
            "Saitama",
            "Saitama-shi",
            "Saito-shi",
            "Sakado",
            "Sakado-shi",
            "Sakae",
            "Sakai",
            "Sakai",
            "Sakai Shi",
            "Sakai-nakajima",
            "Sakai-shi",
            "Sakaide Shi",
            "Sakaidechō",
            "Sakaiminato",
            "Sakaiminato Shi",
            "Sakaki",
            "Sakata",
            "Sakata Shi",
            "Sakawa",
            "Saku",
            "Saku",
            "Saku Shi",
            "Sakuho",
            "Sakura",
            "Sakura-shi",
            "Sakura-shi",
            "Sakuragawa",
            "Sakuragawa-shi",
            "Sakurai",
            "Sakurai-shi",
            "Sambu-gun",
            "Sanada",
            "Sanda Shi",
            "Sandachō",
            "Sanjō Shi",
            "Sanmu",
            "Sanmu-shi",
            "Sano",
            "Sano-shi",
            "Sanuki-shi",
            "Sanyōonoda Shi",
            "Sapporo",
            "Sapporo-shi",
            "Sasaguri",
            "Sasayama",
            "Sasayama-shi",
            "Sasebo Shi",
            "Satsumasendai",
            "Satsumasendai Shi",
            "Satte",
            "Satte Shi",
            "Sawara",
            "Sayama",
            "Seiyo-shi",
            "Seki-shi",
            "Semboku-shi",
            "Sendai",
            "Sennan Shi",
            "Setagaya-ku",
            "Setakamachi-takayanagi",
            "Seto",
            "Seto-shi",
            "Setouchi Shi",
            "Settsu Shi",
            "Shibata",
            "Shibata Shi",
            "Shibetsu",
            "Shibetsu Shi",
            "Shibukawa",
            "Shibukawa-shi",
            "Shibushi",
            "Shibushi-shi",
            "Shibuya-ku",
            "Shido",
            "Shiida",
            "Shijonawate Shi",
            "Shiki",
            "Shiki Shi",
            "Shikoku-chūō Shi",
            "Shima",
            "Shima-shi",
            "Shimabara",
            "Shimabara-shi",
            "Shimada",
            "Shimada-shi",
            "Shimanto",
            "Shimo-furano",
            "Shimoda",
            "Shimoda-shi",
            "Shimodate",
            "Shimojō",
            "Shimokizukuri",
            "Shimonoseki Shi",
            "Shimosuwa",
            "Shimotoda",
            "Shimotsuke-shi",
            "Shimotsuma-shi",
            "Shin’ichi",
            "Shinagawa-ku",
            "Shinano",
            "Shingū",
            "Shingū",
            "Shingū-shi",
            "Shinjō",
            "Shinjō Shi",
            "Shinjuku-ku",
            "Shinshiro",
            "Shinshiro-shi",
            "Shiogama",
            "Shiojiri",
            "Shiojiri",
            "Shiojiri-shi",
            "Shiozawa",
            "Shirahamachō-usazakiminami",
            "Shirakawa Shi",
            "Shiraoi",
            "Shiraoka",
            "Shiraoka-shi",
            "Shiroi",
            "Shiroi-shi",
            "Shiroishi",
            "Shiroishi Shi",
            "Shirone",
            "Shisō-shi",
            "Shisui",
            "Shizukuishi",
            "Shizunai-furukawachō",
            "Shizuoka",
            "Shizuoka-shi",
            "Shōbara",
            "Shōbara-shi",
            "Shōbu",
            "Shūnan Shi",
            "Sobue",
            "Sodegaura-shi",
            "Sōja",
            "Sōja Shi",
            "Sōka",
            "Sōka Shi",
            "Sōma",
            "Sōma Shi",
            "Soo Shi",
            "Sōsa-shi",
            "Sueyoshichō-ninokata",
            "Suginami-ku",
            "Sugito",
            "Suibara",
            "Suita",
            "Suita Shi",
            "Sukagawa",
            "Sukagawa Shi",
            "Sukumo",
            "Sumida-ku",
            "Sumoto",
            "Sumoto Shi",
            "Sunagawa",
            "Sunagawa-shi",
            "Susaki",
            "Susono",
            "Susono Shi",
            "Suwa",
            "Suwa",
            "Suwa Shi",
            "Suzaka",
            "Suzaka",
            "Suzaka-shi",
            "Suzu",
            "Suzu Shi",
            "Suzuka",
            "Suzuka-shi",
            "Tachikawa-shi",
            "Tadotsu",
            "Tagajō Shi",
            "Tagawa",
            "Tagawa Shi",
            "Tahara",
            "Tahara-shi",
            "Tainai",
            "Tainai Shi",
            "Taitō-ku",
            "Tajimi",
            "Tajimi-shi",
            "Tajiri",
            "Taka-gun",
            "Takagi",
            "Takahagi",
            "Takahama",
            "Takahama-shi",
            "Takahashi",
            "Takahashi Shi",
            "Takahata",
            "Takaishi",
            "Takaishi Shi",
            "Takamatsu",
            "Takamatsu Shi",
            "Takamori",
            "Takanabe",
            "Takanosu",
            "Takaoka",
            "Takaoka Shi",
            "Takarazuka",
            "Takarazuka Shi",
            "Takasago Shi",
            "Takasaki",
            "Takasaki Shi",
            "Takashima",
            "Takashima-shi",
            "Takatsuki",
            "Takatsuki Shi",
            "Takayama",
            "Takayama Shi",
            "Takedamachi",
            "Takefu",
            "Takehara",
            "Takehara-shi",
            "Takeo Shi",
            "Takeochō-takeo",
            "Taketa-shi",
            "Taketoyo",
            "Takikawa",
            "Takikawa-shi",
            "Takizawa-shi",
            "Taku Shi",
            "Tama-shi",
            "Tamamura",
            "Tamana",
            "Tamana Shi",
            "Tamano",
            "Tamano Shi",
            "Tamura",
            "Tamura-shi",
            "Tanabe",
            "Tanabe-shi",
            "Tanashichō",
            "Tanba-shi",
            "Tano",
            "Tanuma",
            "Tanushimarumachi-toyoki",
            "Tarui",
            "Tarumizu",
            "Tarumizu Shi",
            "Tatebayashi",
            "Tatebayashi-shi",
            "Tateshina",
            "Tateyama",
            "Tateyama-shi",
            "Tatsuno",
            "Tatsuno",
            "Tatsuno-shi",
            "Tatsunochō-tominaga",
            "Tawaramoto",
            "Tendō",
            "Tendō Shi",
            "Tennō",
            "Tenri",
            "Tenri-shi",
            "Toba",
            "Toba-shi",
            "Tōbetsu",
            "Tochigi-shi",
            "Tochio-honchō",
            "Toda-shi",
            "Tōgane",
            "Tōgane-shi",
            "Togari",
            "Togitsu",
            "Tōkai-shi",
            "Tōkamachi",
            "Tōkamachi-shi",
            "Toki",
            "Toki-shi",
            "Tokoname",
            "Tokoname-shi",
            "Tokorozawa",
            "Tokorozawa-shi",
            "Tokushima",
            "Tokushima Shi",
            "Tokuyama",
            "Tokyo",
            "Tomakomai",
            "Tomakomai Shi",
            "Tome Shi",
            "Tōmi",
            "Tōmi",
            "Tōmi Shi",
            "Tomigusuku",
            "Tomigusuku-shi",
            "Tomioka",
            "Tomioka-shi",
            "Tomisato-shi",
            "Tomiya",
            "Tomobe",
            "Tonami Shi",
            "Tondabayashi Shi",
            "Tondabayashichō",
            "Tōno",
            "Tōno-shi",
            "Tonoshō",
            "Tōon-shi",
            "Toride",
            "Toride-shi",
            "Tosa",
            "Tosashimizu",
            "Toshima-ku",
            "Tosu Shi",
            "Tottori-shi",
            "Towada Shi",
            "Toyama Shi",
            "Tōyō",
            "Toyoake",
            "Toyoake-shi",
            "Toyohama",
            "Toyohashi",
            "Toyohashi-shi",
            "Toyokawa",
            "Toyokawa-shi",
            "Toyonaka",
            "Toyonaka Shi",
            "Toyooka",
            "Toyooka",
            "Toyooka-shi",
            "Toyoshina",
            "Toyota-shi",
            "Tsu",
            "Tsu-shi",
            "Tsubame",
            "Tsubame Shi",
            "Tsubata",
            "Tsuchiura-shi",
            "Tsugaru",
            "Tsugaru Shi",
            "Tsuiki",
            "Tsukawaki",
            "Tsukuba",
            "Tsukuba-shi",
            "Tsukubamirai",
            "Tsukubamirai-shi",
            "Tsukumi-shi",
            "Tsukumiura",
            "Tsuma",
            "Tsuno",
            "Tsuru-shi",
            "Tsuruga",
            "Tsuruga-shi",
            "Tsurugashima-shi",
            "Tsurugi-asahimachi",
            "Tsuruoka",
            "Tsuruoka Shi",
            "Tsurusaki",
            "Tsushima",
            "Tsushima Shi",
            "Tsushima-shi",
            "Tsuyama",
            "Tsuyama-shi",
            "Ube",
            "Uda Shi",
            "Ueda",
            "Ueda",
            "Ueda Shi",
            "Uekimachi-mōno",
            "Ueno-ebisumachi",
            "Uenohara",
            "Uenohara-shi",
            "Uji",
            "Uji Shi",
            "Ujiie",
            "Uki Shi",
            "Ukiha Shi",
            "Umaji",
            "Umi",
            "Unnan Shi",
            "Unzen-shi",
            "Uonuma",
            "Uonuma Shi",
            "Uozu",
            "Uozu Shi",
            "Urasoe Shi",
            "Urayasu",
            "Urayasu-shi",
            "Ureshino Shi",
            "Urugi",
            "Uruma Shi",
            "Usa",
            "Usa Shi",
            "Ushibukamachi",
            "Ushiku",
            "Ushiku-shi",
            "Usuki",
            "Usuki Shi",
            "Utashinai",
            "Utashinai-shi",
            "Uto",
            "Uto Shi",
            "Utsunomiya",
            "Utsunomiya-shi",
            "Uwajima",
            "Uwajima-shi",
            "Wajima Shi",
            "Wakayama",
            "Wakayama Shi",
            "Wakimachi",
            "Wakkanai",
            "Wakkanai Shi",
            "Wako",
            "Wakō-shi",
            "Wakuya",
            "Warabi-shi",
            "Watari",
            "Watari-gun",
            "Yabu",
            "Yabu-shi",
            "Yachimata",
            "Yachimata-shi",
            "Yachiyo-shi",
            "Yaita",
            "Yaita-shi",
            "Yaizu",
            "Yaizu Shi",
            "Yamada",
            "Yamaga Shi",
            "Yamagata",
            "Yamagata",
            "Yamagata Shi",
            "Yamagata-shi",
            "Yamaguchi Shi",
            "Yamakita",
            "Yamanashi-shi",
            "Yamanouchi",
            "Yamato-shi",
            "Yamatokōriyama-shi",
            "Yamatotakada-shi",
            "Yamazakichō-nakabirose",
            "Yame Shi",
            "Yamoto",
            "Yanagawa",
            "Yanagawa Shi",
            "Yanagawamachi-saiwaichō",
            "Yanai Shi",
            "Yao",
            "Yao-shi",
            "Yashio",
            "Yashio-shi",
            "Yashiro",
            "Yasu-shi",
            "Yasuda",
            "Yasugi Shi",
            "Yasugichō",
            "Yasuoka",
            "Yatomi-shi",
            "Yatsuomachi-higashikumisaka",
            "Yatsushiro Shi",
            "Yawata",
            "Yawata",
            "Yawata Shi",
            "Yawatahama-shi",
            "Yoichi",
            "Yōkaichiba",
            "Yokkaichi-shi",
            "Yokohama",
            "Yokohama Shi",
            "Yokosuka",
            "Yokosuka Shi",
            "Yokote",
            "Yokote-shi",
            "Yonago Shi",
            "Yonakuni",
            "Yonedacho Sendo",
            "Yonezawa",
            "Yonezawa Shi",
            "Yono",
            "Yorii",
            "Yoshida-kasugachō",
            "Yoshii",
            "Yoshikawa",
            "Yoshikawa Shi",
            "Yoshino-chō",
            "Yoshinogawa Shi",
            "Yoshitomi",
            "Yotsukaidō",
            "Yotsukaidō-shi",
            "Youkaichi",
            "Yūbari",
            "Yūbari-shi",
            "Yudomari",
            "Yufu-shi",
            "Yugawara",
            "Yūki",
            "Yūki-shi",
            "Yukuhashi",
            "Yukuhashi Shi",
            "Yurihonjō",
            "Yurihonjō-shi",
            "Yusuhara",
            "Yuza",
            "Yuzawa",
            "Yuzawa-shi",
            "Zama",
            "Zama Shi",
            "Zentsūji Shi",
            "Zushi Shi"
        ]
    },
    {
        "label": "Jersey",
        "code": "JE",
        "phone": "+44-1534",
        "cities": []
    },
    {
        "label": "Jordan",
        "code": "JO",
        "phone": "962",
        "cities": [
            "‘Ajlūn",
            "‘Anjarah",
            "‘Ayn Jannah",
            "‘Ayy",
            "‘Izrā",
            "Adir",
            "Al Azraq ash Shamālī",
            "Al Ḩamrā’",
            "Al Jafr",
            "Al Jīzah",
            "Al Jubayhah",
            "Al Karāmah",
            "Al Khinzīrah",
            "Al Kittah",
            "Al Mazār al Janūbī",
            "Al Qaşr",
            "Al Quwayrah",
            "Amman",
            "Aqaba",
            "Ar Rabbah",
            "Ar Ramthā",
            "As Salţ",
            "Ash Shajarah",
            "Ash Shawbak",
            "Aţ Ţafīlah",
            "Aţ Ţayyibah",
            "Aţ Ţayyibah",
            "Aţ Ţurrah",
            "Aydūn",
            "Balīlā",
            "Bayt Īdis",
            "Bayt Yāfā",
            "Burmā",
            "Buşayrā",
            "Dayr Yūsuf",
            "Ḩakamā",
            "Ḩalāwah",
            "Ḩātim",
            "Ḩayy al Bunayyāt",
            "Ḩayy al Quwaysimah",
            "Irbid",
            "Jarash",
            "Jāwā",
            "Judita",
            "Kafr Abīl",
            "Kafr Asad",
            "Kafr Sawm",
            "Karak City",
            "Kharjā",
            "Kitim",
            "Kurayyimah",
            "Ma'an",
            "Mādabā",
            "Mafraq",
            "Malkā",
            "Petra",
            "Qafqafā",
            "Qīr Moāv",
            "Qumaym",
            "Raymūn",
            "Rehab",
            "Rukban",
            "Russeifa",
            "Şabḩā",
            "Safi",
            "Saḩāb",
            "Saḩam al Kaffārāt",
            "Şakhrah",
            "Sakib",
            "Sāl",
            "Şammā",
            "Sūf",
            "Tala Bay",
            "Tibnah",
            "Umm al Qiţţayn",
            "Umm as Summāq",
            "Umm Qays",
            "Wādī as Sīr",
            "Waqqāş",
            "Yarqā",
            "Zaḩar",
            "Zarqa"
        ]
    },
    {
        "label": "Kazakhstan",
        "code": "KZ",
        "phone": "7",
        "cities": [
            "Abay",
            "Abay Qalasy",
            "Akkol",
            "Akkol’",
            "Akkol’",
            "Aksu",
            "Aksu",
            "Aksu-Ayuly",
            "Aktas",
            "Aktau",
            "Aktau",
            "Aktobe",
            "Aktogay",
            "Almaty",
            "Altayskiy",
            "Aqadyr",
            "Aqbaqay",
            "Aqsay",
            "Aqshataū",
            "Aqtoghay",
            "Aqtoghay Aūdany",
            "Aral",
            "Arkalyk",
            "Arys",
            "Ashchysay",
            "Astrakhan",
            "Asūbulaq",
            "Asyqata",
            "Atakent",
            "Atasū",
            "Atbasar",
            "Atyrau",
            "Auezov",
            "Ayagoz",
            "Ayat",
            "Ayteke Bi",
            "Baikonur",
            "Bakanas",
            "Balkashino",
            "Balpyk Bī",
            "Balqash",
            "Balykshi",
            "Batamshinskiy",
            "Baūtīno",
            "Bayanaul",
            "Bayganin",
            "Bayshonas",
            "Bayzhansay",
            "Belköl",
            "Belogor’ye",
            "Belogorskīy",
            "Belousovka",
            "Belyye Vody",
            "Bestobe",
            "Beyneu",
            "Birlestik",
            "Bishkul",
            "Borodulikha",
            "Borovskoy",
            "Bukhar-Zhyrau",
            "Bulayevo",
            "Būrabay",
            "Burlin",
            "Burunday",
            "Chapaev",
            "Chardara",
            "Chayan",
            "Chemolgan",
            "Chingirlau",
            "Chu",
            "Chulakkurgan",
            "Derzhavīnsk",
            "Dolinka",
            "Dossor",
            "Druzhba",
            "Dzhalagash",
            "Dzhambeyty",
            "Dzhetygara",
            "Egindiköl",
            "Ekibastuz",
            "Embi",
            "Esik",
            "Esil",
            "Fedorovka",
            "Fort-Shevchenko",
            "Fyodorovka",
            "Georgīevka",
            "Georgiyevka",
            "Glubokoye",
            "Granitogorsk",
            "Inderbor",
            "Irtyshsk",
            "Kalkaman",
            "Kandyagash",
            "Kantagi",
            "Kapshagay",
            "Karagandy",
            "Karasu",
            "Karatau",
            "Kaztalovka",
            "Kegen",
            "Kentau",
            "Khantaū",
            "Khromtau",
            "Kīevka",
            "Kokshetau",
            "Koktal",
            "Kokterek",
            "Komsomolets",
            "Kostanay",
            "Krasnogorskiy",
            "Krūgloozernoe",
            "Kurchatov",
            "Kurchum",
            "Kushoky",
            "Kyzylorda",
            "Kyzylzhar",
            "Kzyltu",
            "Lenger",
            "Leninskiy",
            "Leninskoye",
            "Lepsy",
            "Lisakovsk",
            "Lugovoy",
            "Lugovoye",
            "Makhambet",
            "Makinsk",
            "Maleyevsk",
            "Maloye Ganyushkino",
            "Maqat",
            "Maqtaaral Aūdany",
            "Martuk",
            "Matay",
            "Mayqayyng",
            "Merke",
            "Miyaly",
            "Moyynkum",
            "Moyynty",
            "Munayshy",
            "Mynaral",
            "Myrzakent",
            "Novodolinskiy",
            "Novoishimskiy",
            "Nur-Sultan",
            "Ognevka",
            "Ömirzaq",
            "Oral",
            "Ordzhonikidze",
            "Osakarovka",
            "Otegen Batyra",
            "Oytal",
            "Pavlodar",
            "Peremetnoe",
            "Pervomayka",
            "Petropavl",
            "Prigorodnoye",
            "Priisk Boko",
            "Priozersk",
            "Qaraton",
            "Qaraūyl",
            "Qarazhal",
            "Qarqaraly",
            "Qashar",
            "Qashyr",
            "Qazaly",
            "Qogham",
            "Qulsary",
            "Qusmuryn",
            "Ridder",
            "Rudnyy",
            "Sarkand",
            "Saryaghash",
            "Saryaghash Aūdany",
            "Sarykemer",
            "Saryozek",
            "Saryshaghan",
            "Sastobe",
            "Sayaq",
            "Saykhin",
            "Sayötesh",
            "Sekseūil",
            "Semey",
            "Sergeyevka",
            "Shakhan",
            "Shakhtinsk",
            "Shalkar",
            "Shalqar",
            "Shalqīya",
            "Shantobe",
            "Shar",
            "Shashūbay",
            "Shchuchinsk",
            "Shemonaīkha",
            "Shetpe",
            "Shīeli",
            "Shortandy",
            "Shubarköl",
            "Shubarkuduk",
            "Shubarshi",
            "Shyghanaq",
            "Smirnovo",
            "Soran",
            "Sozaq Aūdany",
            "Stepnogorsk",
            "Stepnyak",
            "Suykbulak",
            "Taiynsha",
            "Taldykorgan",
            "Talghar",
            "Talshik",
            "Taraz",
            "Tasböget",
            "Tasqala",
            "Taūshyq",
            "Tekeli",
            "Temir",
            "Temirlanovka",
            "Temirtau",
            "Terenozek",
            "Timiryazevo",
            "Tobol",
            "Tokarevka",
            "Torghay",
            "Troyebratskiy",
            "Tūghyl",
            "Turar Ryskulov",
            "Turgen",
            "Turkestan",
            "Tyul’kubas",
            "Ülken",
            "Urzhar",
            "Ush-Tyube",
            "Ust-Kamenogorsk",
            "Ūst’-Talovka",
            "Verkhniye Kayrakty",
            "Volodarskoye",
            "Yanykurgan",
            "Yavlenka",
            "Yeraliyev",
            "Yermentau",
            "Yrghyz",
            "Zavodskoy",
            "Zaysan",
            "Zhabagly",
            "Zhalghyztobe",
            "Zhambyl",
            "Zhanaozen",
            "Zhanga Buqtyrma",
            "Zhangatas",
            "Zhänibek",
            "Zhaqsy",
            "Zharkent",
            "Zharyk",
            "Zhelezinka",
            "Zhetibay",
            "Zhezkent",
            "Zhezqazghan",
            "Zholymbet",
            "Zhosaly",
            "Zyryanovsk"
        ]
    },
    {
        "label": "Kenya",
        "code": "KE",
        "phone": "254",
        "cities": [
            "Ahero",
            "Athi River",
            "Baringo",
            "Bondo",
            "Bungoma",
            "Busia",
            "Butere",
            "Chepareria",
            "Chuka",
            "Eldama Ravine",
            "Eldoret",
            "Embu",
            "Garissa",
            "Gazi",
            "Hola",
            "Homa Bay",
            "Isiolo",
            "Iten",
            "Kabarnet",
            "Kajiado",
            "Kakamega",
            "Kangema",
            "Kangundo",
            "Kapenguria",
            "Kapsabet",
            "Kapsowar",
            "Karuri",
            "Kericho",
            "Keroka",
            "Kerugoya",
            "Kiambu",
            "Kihancha",
            "Kijabe",
            "Kikuyu",
            "Kilifi",
            "Kinango",
            "Kipini",
            "Kipkelion",
            "Kisii",
            "Kisumu",
            "Kitale",
            "Kitui",
            "Konza",
            "Kwale",
            "Lamu",
            "Limuru",
            "Litein",
            "Lodwar",
            "Londiani",
            "Luanda",
            "Lugulu",
            "Machakos",
            "Magadi",
            "Makueni Boma",
            "Malaba",
            "Malikisi",
            "Malindi",
            "Mandera",
            "Maragua",
            "Maralal",
            "Mariakani",
            "Marsabit",
            "Maua",
            "Meru",
            "Migori",
            "Molo",
            "Mombasa",
            "Moyale",
            "Mtito Andei",
            "Muhoroni",
            "Mumias",
            "Murang’a",
            "Mwingi",
            "Nairobi",
            "Naivasha",
            "Nakuru",
            "Nambare",
            "Nandi Hills",
            "Nanyuki",
            "Naro Moru",
            "Narok",
            "Ngong",
            "Nyahururu",
            "Nyamira",
            "Nyeri",
            "Ogembo",
            "Ol Kalou",
            "Othaya",
            "Oyugis",
            "Port Victoria",
            "Pumwani",
            "Rachuonyo District",
            "Rongai",
            "Rumuruti",
            "Sagana",
            "Sawa Sawa",
            "Shimoni",
            "Siaya",
            "Sotik",
            "Sotik Post",
            "Takaungu",
            "Taveta",
            "Thika",
            "Voi",
            "Wajir",
            "Webuye",
            "Witu",
            "Wote",
            "Wundanyi",
            "Yala"
        ]
    },
    {
        "label": "Kiribati",
        "code": "KI",
        "phone": "686",
        "cities": [
            "Abaiang",
            "Abemama",
            "Ambo Village",
            "Aranuka",
            "Arorae",
            "Bairiki Village",
            "Banaba",
            "Banana Village",
            "Banraeaba Village",
            "Beru",
            "Betio Village",
            "Bikenibeu Village",
            "Bonriki Village",
            "Buota Village",
            "Butaritari",
            "Eita Village",
            "Kanton",
            "Kiritimati",
            "Kuria",
            "London Village",
            "Maiana",
            "Makin",
            "Makin Village",
            "Marakei",
            "Napari Village",
            "Nawerewere Village",
            "Nikunau",
            "Nonouti",
            "Onotoa",
            "Rawannawi Village",
            "Tabiteuea",
            "Tabuaeran",
            "Tabwakea Village",
            "Tamana",
            "Tarawa",
            "Teaoraereke Village",
            "Temaiku Village",
            "Teraina"
        ]
    },
    {
        "label": "Kosovo",
        "code": "XK",
        "phone": "383",
        "cities": []
    },
    {
        "label": "Kuwait",
        "code": "KW",
        "phone": "965",
        "cities": [
            "Abu Al Hasaniya",
            "Abu Fatira",
            "Ad Dasmah",
            "Al Aḩmadī",
            "Al Faḩāḩīl",
            "Al Farwānīyah",
            "Al Finţās",
            "Al Funayţīs",
            "Al Jahrā’",
            "Al Mahbūlah",
            "Al Manqaf",
            "Al Wafrah",
            "Al-Masayel",
            "Ar Rābiyah",
            "Ar Riqqah",
            "Ar Rumaythīyah",
            "As Sālimīyah",
            "Ash Shāmīyah",
            "Az Zawr",
            "Bayān",
            "Ḩawallī",
            "Janūb as Surrah",
            "Kuwait City",
            "Şabāḩ as Sālim",
            "Salwá"
        ]
    },
    {
        "label": "Kyrgyzstan",
        "code": "KG",
        "phone": "996",
        "cities": [
            "Ak-Suu",
            "Ala-Buka",
            "Alamudunskiy Rayon",
            "At-Bashi",
            "Aydarken",
            "Balykchy",
            "Batken",
            "Bazar-Korgon",
            "Belovodskoye",
            "Bishkek",
            "Cholpon-Ata",
            "Chong-Alay District",
            "Chuyskiy Rayon",
            "Daroot-Korgon",
            "Iradan",
            "Isfana",
            "Ivanovka",
            "Jalal-Abad",
            "Jumgal",
            "Kadzhi-Say",
            "Kaindy",
            "Kant",
            "Kara Kulja",
            "Kara Suu",
            "Kara-Balta",
            "Kara-Buurinskiy Rayon",
            "Karakol",
            "Karavan",
            "Kazarman",
            "Kemin",
            "Kerben",
            "Kochkor-Ata",
            "Kyzyl-Eshme",
            "Kyzyl-Kyya",
            "Kyzyl-Suu",
            "Lebedinovka",
            "Naryn",
            "Nookat",
            "Osh",
            "Sokulukskiy Rayon",
            "Sosnovka",
            "Suluktu",
            "Suzak",
            "Talas",
            "Talasskiy Rayon",
            "Tash-Kumyr",
            "Tokmok",
            "Toktogul",
            "Toktogul District",
            "Tyup",
            "Uzgen",
            "Uzgen District",
            "Ysyk-Ata"
        ]
    },
    {
        "label": "Laos",
        "code": "LA",
        "phone": "856",
        "cities": [
            "Anouvong district",
            "Attapeu",
            "Ban Houakhoua",
            "Ban Houayxay",
            "Ban Nahin",
            "Ban Thatèng",
            "Champasak",
            "Kaysone Phomvihane",
            "Lamam",
            "Longchaeng",
            "Luang Namtha",
            "Luang Prabang",
            "Muang Alsaphangthong",
            "Muang Atsaphan",
            "Muang Bachiangchaleunsook",
            "Muang Champasak",
            "Muang Champhon",
            "Muang Dakchung",
            "Muang Houayxay",
            "Muang Khaleum",
            "Muang Không",
            "Muang Khôngxédôn",
            "Muang Lakhonphéng",
            "Muang Laman",
            "Muang Laongam",
            "Muang Longxan",
            "Muang Louang Namtha",
            "Muang Meung",
            "Muang Mounlapamôk",
            "Muang Nong",
            "Muang Outhoumphon",
            "Muang Paktha",
            "Muang Pakxé",
            "Muang Pakxong",
            "Muang Pathoumphon",
            "Muang Pha Oudôm",
            "Muang Phin",
            "Muang Phôn-Hông",
            "Muang Phônsavan",
            "Muang Phônthong",
            "Muang Phouvong",
            "Muang Samakhixai",
            "Muang Samouay",
            "Muang Sanamxai",
            "Muang Sanxai",
            "Muang Saravan",
            "Muang Songkhon",
            "Muang Soukhouma",
            "Muang Ta-Ôy",
            "Muang Thakhèk",
            "Muang Thapangthong",
            "Muang Thatèng",
            "Muang Thathôm",
            "Muang Tônpheung",
            "Muang Toumlan",
            "Muang Vapi",
            "Muang Vilabouli",
            "Muang Xaibouli",
            "Muang Xaiséttha",
            "Muang Xanasômboun",
            "Muang Xay",
            "Muang Xayphoothong",
            "Muang Xônbouli",
            "Pakse",
            "Pakxan",
            "Pakxong",
            "Phôngsali",
            "Sainyabuli",
            "Salavan",
            "Savannakhet",
            "Thakhèk",
            "Thaphalanxay",
            "Vangviang",
            "Vientiane",
            "Xam Neua",
            "Xam Nua"
        ]
    },
    {
        "label": "Latvia",
        "code": "LV",
        "phone": "371",
        "cities": [
            "Aglona",
            "Ainaži",
            "Aizkraukle",
            "Aizpute",
            "Aknīste",
            "Aloja",
            "Alsunga",
            "Alūksne",
            "Ape",
            "Auce",
            "Baldone",
            "Baloži",
            "Baltinava",
            "Balvi",
            "Bauska",
            "Bolderaja",
            "Brocēni",
            "Carnikava",
            "Cēsis",
            "Cesvaine",
            "Cibla",
            "Dagda",
            "Daugavgrīva",
            "Daugavpils",
            "Dobele",
            "Dundaga",
            "Engure",
            "Ērgļi",
            "Garkalne",
            "Grobiņa",
            "Gulbene",
            "Iecava",
            "Ikšķile",
            "Ilūkste",
            "Inčukalns",
            "Jaunaglona",
            "Jaunciems",
            "Jaunjelgava",
            "Jaunpils",
            "Jēkabpils",
            "Jelgava",
            "Jumprava",
            "Jūrmala",
            "Kandava",
            "Karosta",
            "Kārsava",
            "Ķegums",
            "Ķekava",
            "Kocēni",
            "Koknese",
            "Krāslava",
            "Krustpils",
            "Kuldīga",
            "Lieģi",
            "Lielvārde",
            "Liepāja",
            "Līgatne",
            "Limbaži",
            "Līvāni",
            "Lubāna",
            "Ludza",
            "Madona",
            "Mālpils",
            "Mārupe",
            "Mazsalaca",
            "Mežaparks",
            "Mūrmuiža",
            "Naukšēni",
            "Nereta",
            "Nīca",
            "Ogre",
            "Olaine",
            "Ozolnieki",
            "Pāvilosta",
            "Pilsrundāle",
            "Piltene",
            "Piņķi",
            "Pļaviņas",
            "Preiļi",
            "Priekule",
            "Priekuļi",
            "Rauna",
            "Rēzekne",
            "Riebiņi",
            "Riga",
            "Roja",
            "Ropaži",
            "Rucava",
            "Rugāji",
            "Rūjiena",
            "Sabile",
            "Salacgrīva",
            "Salaspils",
            "Saldus",
            "Saulkrasti",
            "Seda",
            "Sigulda",
            "Skrīveri",
            "Skrunda",
            "Smārde",
            "Smiltene",
            "Staicele",
            "Stalbe",
            "Stende",
            "Strenči",
            "Talsi",
            "Tērvete",
            "Tīreļi",
            "Tukums",
            "Ulbroka",
            "Vaiņode",
            "Valdemārpils",
            "Valka",
            "Valmiera",
            "Vangaži",
            "Varakļāni",
            "Vecpiebalga",
            "Vecumnieki",
            "Vecvārkava",
            "Ventspils",
            "Viesīte",
            "Viļaka",
            "Viļāni",
            "Zelmeņi",
            "Zilupe"
        ]
    },
    {
        "label": "Lebanon",
        "code": "LB",
        "phone": "961",
        "cities": [
            "Aanjar",
            "Ain Ebel",
            "Baabda",
            "Baalbek",
            "Batroûn",
            "Bcharré",
            "Beirut",
            "Bhamdoun",
            "Bhamdoûn el Mhatta",
            "Caza de Aakkar",
            "Caza de Baabda",
            "Caza de Baalbek",
            "Caza de Bent Jbaïl",
            "Caza de Nabatîyé",
            "En Nâqoûra",
            "Ghazieh",
            "Habboûch",
            "Jbaïl",
            "Jounieh",
            "Marjayoûn",
            "Nabatîyé et Tahta",
            "Ra’s Bayrūt",
            "Sidon",
            "Tripoli",
            "Tyre",
            "Zahlé"
        ]
    },
    {
        "label": "Lesotho",
        "code": "LS",
        "phone": "266",
        "cities": [
            "Butha-Buthe",
            "Leribe",
            "Mafeteng",
            "Maputsoe",
            "Maseru",
            "Mohale’s Hoek",
            "Mokhotlong",
            "Nako",
            "Qacha’s Nek",
            "Quthing",
            "Teyateyaneng",
            "Thaba-Tseka"
        ]
    },
    {
        "label": "Liberia",
        "code": "LR",
        "phone": "231",
        "cities": [
            "Barclayville",
            "Bensonville",
            "Bopolu",
            "Buchanan",
            "Cestos City",
            "Fish Town",
            "Ganta",
            "Gbarnga",
            "Greenville",
            "Harper",
            "Kakata",
            "Monrovia",
            "New Yekepa",
            "Robertsport",
            "Sanniquellie",
            "Tubmanburg",
            "Voinjama",
            "Zwedru"
        ]
    },
    {
        "label": "Libya",
        "code": "LY",
        "phone": "218",
        "cities": [
            "Ajdabiya",
            "Al ‘Azīzīyah",
            "Al Abyār",
            "Al Ajaylat",
            "Al Bayḑā’",
            "Al Burayqah",
            "Al Jadīd",
            "Al Jawf",
            "Al Khums",
            "Al Marj",
            "Al Qaţrūn",
            "Al Qubbah",
            "At Tāj",
            "Awjilah",
            "Az Zāwīyah",
            "Az Zuwaytīnah",
            "Bani Walid",
            "Benghazi",
            "Brak",
            "Darnah",
            "Ghadāmis",
            "Gharyan",
            "Ghat",
            "Giado",
            "Gialo",
            "Hūn",
            "Idrī",
            "Marādah",
            "Masallātah",
            "Mişrātah",
            "Mizdah",
            "Murzuq",
            "Nālūt",
            "Qaryat Sulūq",
            "Qasr Abu Hadi",
            "Sabhā",
            "Şabrātah",
            "Sirte",
            "Şurmān",
            "Tagiura",
            "Tarhuna",
            "Tripoli",
            "Tūkrah",
            "Ubari",
            "Waddān",
            "Yafran",
            "Zalţan",
            "Zawiya",
            "Zintan",
            "Zliten",
            "Zuwārah"
        ]
    },
    {
        "label": "Liechtenstein",
        "code": "LI",
        "phone": "423",
        "cities": [
            "Balzers",
            "Eschen",
            "Gamprin",
            "Mauren",
            "Planken",
            "Ruggell",
            "Schaan",
            "Schellenberg",
            "Triesen",
            "Triesenberg",
            "Vaduz"
        ]
    },
    {
        "label": "Lithuania",
        "code": "LT",
        "phone": "370",
        "cities": [
            "Akademija (Kaunas)",
            "Akmenė",
            "Aleksotas",
            "Alytus",
            "Anyksciai",
            "Ariogala",
            "Aukstadvaris",
            "Baltoji Vokė",
            "Birštonas",
            "Birzai",
            "Būgai",
            "Dainava (Kaunas)",
            "Daugai",
            "Druskininkai",
            "Dūkštas",
            "Eiguliai",
            "Eišiškės",
            "Elektrėnai",
            "Ežerėlis",
            "Fabijoniškės",
            "Gargždai",
            "Garliava",
            "Gelgaudiškis",
            "Grigiškės",
            "Ignalina",
            "Jieznas",
            "Jonava",
            "Joniškis",
            "Juodupė",
            "Jurbarkas",
            "Justiniškės",
            "Kaišiadorys",
            "Kalvarija",
            "Kalvarija Municipality",
            "Karmėlava",
            "Kaunas",
            "Kazlų Rūda",
            "Kazlų Rūda Municipality",
            "Kėdainiai",
            "Kelmė",
            "Klaipėda",
            "Kretinga",
            "Kudirkos Naumiestis",
            "Kulautuva",
            "Kupiskis",
            "Kuršėnai",
            "Kybartai",
            "Lazdijai",
            "Lazdynai",
            "Lentvaris",
            "Linkuva",
            "Marijampolė",
            "Marijampolė Municipality",
            "Mastaiciai",
            "Mazeikiai",
            "Moletai",
            "Naujamiestis",
            "Naujoji Akmene",
            "Nemenčinė",
            "Neringa",
            "Nida",
            "Obeliai",
            "Pabradė",
            "Pagėgiai",
            "Pakruojis",
            "Palanga",
            "Panevėžys",
            "Panevėžys City",
            "Panevėžys District Municipality",
            "Pašilaičiai",
            "Pasvalys",
            "Pilaitė",
            "Plateliai",
            "Plunge",
            "Priekulė",
            "Prienai",
            "Radviliskis",
            "Ramučiai",
            "Ramygala",
            "Raseiniai",
            "Rasos",
            "Rietavas",
            "Rokiškis",
            "Rūdiškės",
            "Rusnė",
            "Sakiai",
            "Salantai",
            "Šalčininkai",
            "Sargėnai",
            "Seda",
            "Šeduva",
            "Senieji Trakai",
            "Šeškinė",
            "Šiauliai",
            "Šilainiai",
            "Šilalė",
            "Silute",
            "Simnas",
            "Sirvintos",
            "Skaidiškės",
            "Skuodas",
            "Švenčionėliai",
            "Svencionys",
            "Šventoji",
            "Taurage",
            "Telsiai",
            "Trakai",
            "Tytuvėnėliai",
            "Ukmerge",
            "Utena",
            "Vabalninkas",
            "Varėna",
            "Varniai",
            "Veisiejai",
            "Venta",
            "Viekšniai",
            "Vievis",
            "Vilkaviskis",
            "Vilkaviškis District Municipality",
            "Vilkija",
            "Vilkpėdė",
            "Vilnius",
            "Vilnius District Municipality",
            "Virbalis",
            "Visaginas",
            "Žagarė",
            "Zarasai",
            "Žemaičių Naumiestis",
            "Žiežmariai"
        ]
    },
    {
        "label": "Luxembourg",
        "code": "LU",
        "phone": "352",
        "cities": [
            "Alzingen",
            "Aspelt",
            "Bascharage",
            "Bavigne",
            "Beaufort",
            "Bech",
            "Beckerich",
            "Belvaux",
            "Berdorf",
            "Béreldange",
            "Bergem",
            "Bertrange",
            "Bettborn",
            "Bettembourg",
            "Bettendorf",
            "Betzdorf",
            "Bissen",
            "Biwer",
            "Boevange-sur-Attert",
            "Boulaide",
            "Bourscheid",
            "Bous",
            "Bridel",
            "Capellen",
            "Clemency",
            "Clervaux",
            "Colmar",
            "Commune de la Vallée de l’Ernz",
            "Commune de Préizerdaul",
            "Consdorf",
            "Contern",
            "Crauthem",
            "Dalheim",
            "Diekirch",
            "Differdange",
            "Dippach",
            "Dudelange",
            "Echternach",
            "Eischen",
            "Ell",
            "Erpeldange",
            "Esch-sur-Alzette",
            "Esch-sur-Sûre",
            "Ettelbruck",
            "Fentange",
            "Feulen",
            "Fischbach",
            "Flaxweiler",
            "Frisange",
            "Garnich",
            "Goesdorf",
            "Gonderange",
            "Grevenmacher",
            "Grosbous",
            "Hautcharage",
            "Heffingen",
            "Heisdorf",
            "Helmsange",
            "Hesperange",
            "Hobscheid",
            "Hosingen",
            "Itzig",
            "Junglinster",
            "Käerjeng",
            "Kayl",
            "Kehlen",
            "Kiischpelt",
            "Kirchberg",
            "Koerich",
            "Kopstal",
            "Lac de la Haute-Sûre",
            "Lamadelaine",
            "Larochette",
            "Lenningen",
            "Leudelange",
            "Lintgen",
            "Lorentzweiler",
            "Luxembourg",
            "Mamer",
            "Manternach",
            "Medernach",
            "Mersch",
            "Mertert",
            "Mertzig",
            "Mompach",
            "Mondercange",
            "Mondorf-les-Bains",
            "Moutfort",
            "Müllendorf",
            "Niederanven",
            "Niedercorn",
            "Niederfeulen",
            "Nommern",
            "Obercorn",
            "Olm",
            "Parc Hosingen",
            "Pétange",
            "Pontpierre",
            "Putscheid",
            "Rambrouch",
            "Reckange-sur-Mess",
            "Redange-sur-Attert",
            "Reisdorf",
            "Remich",
            "Rodange",
            "Roeser",
            "Rollingen",
            "Rosport",
            "Rumelange",
            "Saeul",
            "Sandweiler",
            "Sanem",
            "Schengen",
            "Schieren",
            "Schifflange",
            "Schouweiler",
            "Schrassig",
            "Schuttrange",
            "Septfontaines",
            "Soleuvre",
            "Stadtbredimus",
            "Steinfort",
            "Steinsel",
            "Strassen",
            "Tandel",
            "Tétange",
            "Troisvierges",
            "Tuntange",
            "Useldange",
            "Vianden",
            "Vichten",
            "Wahl",
            "Waldbillig",
            "Waldbredimus",
            "Walferdange",
            "Warken",
            "Wasserbillig",
            "Weiler-la-Tour",
            "Weiswampach",
            "Wiltz",
            "Wilwerwiltz",
            "Wincrange",
            "Winseler",
            "Wormeldange"
        ]
    },
    {
        "label": "Macau S.A.R.",
        "code": "MO",
        "phone": "853",
        "cities": []
    },
    {
        "label": "Madagascar",
        "code": "MG",
        "phone": "261",
        "cities": [
            "Ampasimanolotra",
            "Andovoranto",
            "Mahanoro",
            "Mahavelona",
            "Marolambo",
            "Toamasina",
            "Toamasina I",
            "Toamasina II",
            "Vohibinany"
        ]
    },
    {
        "label": "Malawi",
        "code": "MW",
        "phone": "265",
        "cities": [
            "Balaka",
            "Balaka District",
            "Blantyre",
            "Blantyre District",
            "Chikwawa",
            "Chikwawa District",
            "Chipoka",
            "Chiradzulu",
            "Chiradzulu District",
            "Chitipa",
            "Chitipa District",
            "Dedza",
            "Dedza District",
            "Dowa",
            "Dowa District",
            "Karonga",
            "Karonga District",
            "Kasungu",
            "Kasungu District",
            "Likoma District",
            "Lilongwe",
            "Lilongwe District",
            "Livingstonia",
            "Liwonde",
            "Luchenza",
            "Machinga",
            "Machinga District",
            "Mangochi",
            "Mangochi District",
            "Mchinji",
            "Mchinji District",
            "Monkey Bay",
            "Mponela",
            "Mulanje",
            "Mulanje District",
            "Mwanza",
            "Mwanza District",
            "Mzimba",
            "Mzimba District",
            "Mzuzu",
            "Neno District",
            "Nkhata Bay",
            "Nkhata Bay District",
            "Nkhotakota",
            "Nkhotakota District",
            "Nsanje",
            "Nsanje District",
            "Ntcheu",
            "Ntcheu District",
            "Ntchisi",
            "Ntchisi District",
            "Phalombe",
            "Phalombe District",
            "Rumphi",
            "Rumphi District",
            "Salima",
            "Salima District",
            "Thyolo",
            "Thyolo District",
            "Zomba",
            "Zomba District"
        ]
    },
    {
        "label": "Malaysia",
        "code": "MY",
        "phone": "60",
        "cities": [
            "Alor Gajah",
            "Alor Setar",
            "Ampang",
            "Ayer Hangat",
            "Bagan Pulau Ketam",
            "Bagan Serai",
            "Bahau",
            "Bakri",
            "Bandar Labuan",
            "Banting",
            "Batang Berjuntai",
            "Batu Arang",
            "Batu Berendam",
            "Batu Feringgi",
            "Batu Gajah",
            "Batu Pahat",
            "Beaufort",
            "Bedong",
            "Bemban",
            "Bentong Town",
            "Bidur",
            "Bintulu",
            "Bukit Mertajam",
            "Bukit Rambai",
            "Buloh Kasap",
            "Butterworth",
            "Chaah",
            "Cukai",
            "Daerah Batu Pahat",
            "Daerah Johor Baharu",
            "Daerah Keluang",
            "Daerah Kota Tinggi",
            "Daerah Mersing",
            "Daerah Muar",
            "Daerah Pontian",
            "Daerah Segamat",
            "Data Kakus",
            "Donggongon",
            "George Town",
            "Gua Musang",
            "Gurun",
            "Ipoh",
            "Jenjarum",
            "Jerantut",
            "Jertih",
            "Jitra",
            "Johor Bahru",
            "Juru",
            "Kampar",
            "Kampong Baharu Balakong",
            "Kampong Dungun",
            "Kampong Kadok",
            "Kampong Masjid Tanah",
            "Kampong Pangkal Kalong",
            "Kampung Ayer Keroh",
            "Kampung Ayer Molek",
            "Kampung Baharu Nilai",
            "Kampung Baru Subang",
            "Kampung Batu Feringgi",
            "Kampung Bukit Baharu",
            "Kampung Bukit Tinggi Bentong",
            "Kampung Kilim",
            "Kampung Kok",
            "Kampung Kuala Besut",
            "Kampung Pasir Gudang Baru",
            "Kampung Simpang Renggam",
            "Kampung Sungai Ara",
            "Kampung Tanjung Karang",
            "Kampung Tekek",
            "Kangar",
            "Kapit",
            "Kelapa Sawit",
            "Keningau",
            "Kepala Batas",
            "Kertih",
            "Kinarut",
            "Klang",
            "Klebang Besar",
            "Kluang",
            "Kota Belud",
            "Kota Bharu",
            "Kota Kinabalu",
            "Kota Tinggi",
            "Kuah",
            "Kuala Besut",
            "Kuala Kangsar",
            "Kuala Kedah",
            "Kuala Lipis",
            "Kuala Lumpur",
            "Kuala Perlis",
            "Kuala Pilah",
            "Kuala Selangor",
            "Kuala Sungai Baru",
            "Kuala Terengganu",
            "Kuala Teriang",
            "Kuang",
            "Kuantan",
            "Kuching",
            "Kudat",
            "Kulai",
            "Kulim",
            "Labis",
            "Labuan",
            "Ladang Seri Kundang",
            "Lahad Datu",
            "Langkawi",
            "Lawas",
            "Lidung Jelo",
            "Limbang",
            "Long Ampan Aing or Abanang",
            "Lumut",
            "Malacca",
            "Marang",
            "Marudi",
            "Mentekab",
            "Mersing",
            "Miri",
            "Muar",
            "Mukah",
            "Nibong Tebal",
            "Padang Mat Sirat",
            "Paka",
            "Pantai Cenang",
            "Pantai Remis",
            "Papar",
            "Parit Buntar",
            "Parit Raja",
            "Pasir Mas",
            "Pekan",
            "Pekan Nenas",
            "Pelabuhan Klang",
            "Perai",
            "Peringat",
            "Permatang Kuching",
            "Petaling Jaya",
            "Pontian Kechil",
            "Port Dickson",
            "Pulau Sebang",
            "Putatan",
            "Putra Heights",
            "Putrajaya",
            "Ranau",
            "Raub",
            "Rawang",
            "Sandakan",
            "Sarikei",
            "Seberang Perai",
            "Segamat",
            "Semenyih",
            "Semporna",
            "Seremban",
            "Serendah",
            "Shah Alam",
            "Sibu",
            "Simanggang",
            "Simpang Empat",
            "Skudai",
            "Subang Jaya",
            "Sungai Besar",
            "Sungai Pelek New Village",
            "Sungai Petani",
            "Sungai Udang",
            "Taiping",
            "Taman Rajawali",
            "Taman Senai",
            "Tampin",
            "Tanah Rata",
            "Tangkak",
            "Tanjung Sepat",
            "Tanjung Tokong",
            "Tapah Road",
            "Tasek Glugor",
            "Tawau",
            "Telaga Batu",
            "Teluk Intan",
            "Temerluh",
            "Tumpat",
            "Ulu Tiram",
            "Yong Peng"
        ]
    },
    {
        "label": "Maldives",
        "code": "MV",
        "phone": "960",
        "cities": [
            "Dhidhdhoo",
            "Felidhoo",
            "Fonadhoo",
            "Funadhoo",
            "Fuvahmulah",
            "Guraidhoo",
            "Hithadhoo",
            "Hulhumale",
            "Kudahuvadhoo",
            "Kulhudhuffushi",
            "Maafushi",
            "Mahibadhoo",
            "Male",
            "Manadhoo",
            "Meedhoo",
            "Muli",
            "Naifaru",
            "Thinadhoo",
            "Ugoofaaru",
            "Veymandoo",
            "Viligili"
        ]
    },
    {
        "label": "Mali",
        "code": "ML",
        "phone": "223",
        "cities": [
            "Abeïbara",
            "Ansongo",
            "Araouane",
            "Bafoulabé",
            "Bamako",
            "Banamba",
            "Bandiagara",
            "Baroueli",
            "Bougouni",
            "Cercle d’Abeïbara",
            "Cercle de Bourem",
            "Cercle de Goundam",
            "Cercle de San",
            "Dire",
            "Djénné",
            "Douentza",
            "Douentza Cercle",
            "Gao",
            "Gao Cercle",
            "Goundam",
            "Gourma-Rharous Cercle",
            "Inékar",
            "Kangaba",
            "Kati",
            "Kayes",
            "Ké-Macina",
            "Kidal",
            "Kinmparana",
            "Kita",
            "Kokofata",
            "Kolokani",
            "Kolondiéba",
            "Koulikoro",
            "Koutiala",
            "Koutiala Cercle",
            "Markala",
            "Mopti",
            "Niafunké",
            "Ntossoni",
            "Sagalo",
            "San",
            "Ségou",
            "Sikasso",
            "Sokolo",
            "Ténenkou",
            "Timbuktu",
            "Yorosso"
        ]
    },
    {
        "label": "Malta",
        "code": "MT",
        "phone": "356",
        "cities": [
            "Attard",
            "Balzan",
            "Birkirkara",
            "Birżebbuġa",
            "Cospicua",
            "Dingli",
            "Fgura",
            "Floriana",
            "Fontana",
            "Għajnsielem",
            "Għarb",
            "Għaxaq",
            "Gudja",
            "Gżira",
            "Hal Gharghur",
            "Haz-Zebbug",
            "Ħamrun",
            "Imdina",
            "Imġarr",
            "Imsida",
            "Imtarfa",
            "Kalkara",
            "Kerċem",
            "Kirkop",
            "L-Iklin",
            "Lija",
            "Luqa",
            "Marsa",
            "Marsaskala",
            "Marsaxlokk",
            "Mellieħa",
            "Mġarr",
            "Mosta",
            "Mqabba",
            "Munxar",
            "Nadur",
            "Naxxar",
            "Paola",
            "Pembroke",
            "Pietà",
            "Qala",
            "Qormi",
            "Qrendi",
            "Rabat",
            "Saint John",
            "Saint Lucia",
            "San Ġiljan",
            "San Lawrenz",
            "San Pawl il-Baħar",
            "Sannat",
            "Santa Luċija",
            "Santa Venera",
            "Senglea",
            "Siġġiewi",
            "Sliema",
            "Swieqi",
            "Ta’ Xbiex",
            "Tarxien",
            "Valletta",
            "Victoria",
            "Vittoriosa",
            "Xagħra",
            "Xewkija",
            "Xgħajra",
            "Żabbar",
            "Żebbuġ",
            "Żejtun",
            "Żurrieq"
        ]
    },
    {
        "label": "Man (Isle of)",
        "code": "IM",
        "phone": "+44-1624",
        "cities": []
    },
    {
        "label": "Marshall Islands",
        "code": "MH",
        "phone": "692",
        "cities": []
    },
    {
        "label": "Martinique",
        "code": "MQ",
        "phone": "596",
        "cities": []
    },
    {
        "label": "Mauritania",
        "code": "MR",
        "phone": "222",
        "cities": [
            "’Elb el Jmel",
            "Ain Ben Tili",
            "Aioun",
            "Akjoujt",
            "Aleg",
            "Arafat",
            "Atar",
            "Ayoun el Atrous",
            "Azougui",
            "Barkéwol",
            "Bofal",
            "Cansado",
            "Chegga",
            "Chingueṭṭi",
            "Dar-Naim",
            "Diade",
            "Fderîck",
            "Iouik",
            "Kaédi",
            "Kataouane",
            "Kiffa",
            "Ksar",
            "Ksar el Khali",
            "Legat",
            "Néma",
            "Nouadhibou",
            "Riyad",
            "Rosso",
            "Sebkha",
            "Sélibaby",
            "Sori Malé",
            "Tékane",
            "Tevragh-Zeina",
            "Teyarett",
            "Timbedra",
            "Togba",
            "Toujouonine",
            "Zouérat",
            "Zouerate"
        ]
    },
    {
        "label": "Mauritius",
        "code": "MU",
        "phone": "230",
        "cities": [
            "Albion",
            "Amaury",
            "Amitié Gokoola",
            "Arsenal",
            "Baie aux Huîtres",
            "Baie Du Cap",
            "Bambous",
            "Bambous Virieux",
            "Beau Bassin",
            "Beau Bassin-Rose Hill",
            "Beau Vallon",
            "Bel Air",
            "Bel Air Rivière Sèche",
            "Bel Ombre",
            "Bell Village",
            "Benares",
            "Black River",
            "Bois Cheri",
            "Bois des Amourettes",
            "Bon Accueil",
            "Bramsthan",
            "Brisee Verdiere",
            "Brisée Verdière",
            "Britannia",
            "Calebasses",
            "Camp Carol",
            "Camp de Masque",
            "Camp De Masque Pave",
            "Camp Diable",
            "Camp Ithier",
            "Camp Thorel",
            "Cap Malheureux",
            "Cargados Carajos",
            "Cascavelle",
            "Case Noyale",
            "Caudan Waterfront",
            "Central Flacq",
            "Centre de Flacq",
            "Chamarel",
            "Chamouny",
            "Chemin Grenier",
            "China Town",
            "Clémencia",
            "Clemencia",
            "Cluny",
            "Congomah",
            "Cottage",
            "Crève Coeur",
            "Creve Coeur",
            "Curepipe",
            "D'Epinay",
            "Dagotière",
            "Dagotiere",
            "Dubreuil",
            "Eau Coulee",
            "Ebene",
            "Ecroignard",
            "Espérance Trébuchet",
            "Esperance Trebuchet",
            "Flic en Flac",
            "Floreal",
            "Fond du Sac",
            "Forest Side",
            "Goodlands",
            "Grand Baie",
            "Grand Bel Air",
            "Grand Bois",
            "Grand Gaube",
            "Grand River South East",
            "Grand Sable",
            "Grande Retraite",
            "Grande Rivière Noire",
            "Grande Rivière Sud Est",
            "Gros Cailloux",
            "L'Avenir",
            "L'Escalier",
            "L'Esperance",
            "La Flora",
            "La Gaulette",
            "La Laura-Malenga",
            "Lalmatie",
            "Laventure",
            "Le Hochet",
            "Le Morne",
            "Les Pailles",
            "Les Salines",
            "Long Mountain",
            "Mahébourg",
            "Mahebourg",
            "Mapou",
            "Mare D'Albert",
            "Mare La Chaux",
            "Mare Tabac",
            "Medine Camp De Masque",
            "Melrose",
            "Midlands",
            "Moka",
            "Montagne Blanche",
            "Morcellement Saint André",
            "Morcellement Saint Andre",
            "New Grove",
            "Notre Dame",
            "Nouvelle Découverte",
            "Nouvelle France",
            "Olivia",
            "Pailles",
            "Pamplemousses",
            "Petit Bel Air",
            "Petit Raffray",
            "Petite Case Noyale",
            "Petite Riviere",
            "Petite Rivière",
            "Piton",
            "Plaine des Papayes",
            "Plaine Magnien",
            "Plaine Verte",
            "Plaines des Roches",
            "Pointe aux Piments",
            "Pointe aux Sables",
            "Port Louis",
            "Port Mathurin",
            "Poste de Flacq",
            "Poudre D'Or",
            "Poudre D'Or Hamlet",
            "Providence",
            "Quartier Militaire",
            "Quatre Bornes",
            "Quatre Cocos",
            "Quatre Soeurs",
            "Queen Victoria",
            "Richelieu",
            "Ripailles",
            "Riviere Des Anguilles",
            "Rivière des Anguilles",
            "Riviere Des Creoles",
            "Rivière Du Poste",
            "Rivière du Rempart",
            "Roche Terre",
            "Roches Noire",
            "Roches Noires",
            "Rose Belle",
            "Rose Hill",
            "Saint Aubin",
            "Saint Hubert",
            "Saint Julien",
            "Saint Julien D'Hotman",
            "Saint Pierre",
            "Sebastopol",
            "Seizieme Mille",
            "Soreze",
            "Souillac",
            "St Aubin",
            "St Hubert",
            "Surinam",
            "Tamarin",
            "Terre Rouge",
            "The Vale",
            "Tombeau Bay",
            "Tour Koenig",
            "Tranquebar",
            "Triolet",
            "Trois Boutiques",
            "Trou Aux Biches",
            "Trou D'Eau Douce",
            "Union Park",
            "Vacoas",
            "Vallée des Prêtres",
            "Vallee Pitot",
            "Verdun",
            "Vieux Grand Port",
            "Ville Bague",
            "Vingt Cinq"
        ]
    },
    {
        "label": "Mayotte",
        "code": "YT",
        "phone": "262",
        "cities": []
    },
    {
        "label": "Mexico",
        "code": "MX",
        "phone": "52",
        "cities": [
            "10 de Abril",
            "18 de Marzo",
            "1ra. Sección de Izapa",
            "2da. Sección de Medio Monte",
            "31 de Octubre",
            "Abala",
            "Abasolo",
            "Abasolo",
            "Abasolo",
            "Abasolo",
            "Abasolo",
            "Abasolo del Valle",
            "Abdenago C. García",
            "Abelardo L. Rodríguez",
            "Abelardo L. Rodríguez",
            "Abrevadero",
            "Acachapan y Colmena 3ra. Sección",
            "Acachuén",
            "Acacoyagua",
            "Acahuasco",
            "Acahuizotla",
            "Acajete",
            "Acajete",
            "Acala",
            "Acalco",
            "Acalpican de Morelos",
            "Acámbaro",
            "Acambay",
            "Acamilpa",
            "Acamixtla",
            "Acanceh",
            "Acapetahua",
            "Acapetlahuaya",
            "Acaponeta",
            "Acapulco de Juárez",
            "Acatempa",
            "Acatempan",
            "Acateno",
            "Acatepec",
            "Acatepec",
            "Acatepec",
            "Acatla",
            "Acatlán",
            "Acatlán",
            "Acatlán",
            "Acatlán",
            "Acatlán de Juárez Municipality",
            "Acatlán de Osorio",
            "Acatlán de Pérez Figueroa",
            "Acatzingo",
            "Acatzingo (Acatzingo de la Piedra)",
            "Acaxochitlán",
            "Acaxtlahuacán de Albino Zertuche",
            "Acayuca",
            "Acayucan",
            "Acazónica",
            "Achichipico",
            "Achotal de Moreno",
            "Acoapa",
            "Acolman de Netzahualcóyotl",
            "Aconchi",
            "Acontitla",
            "Acopinalco del Peñón",
            "Acoxcatlán",
            "Acoyotla",
            "Acteopan",
            "Actipan",
            "Actipan de Morelos",
            "Actopan",
            "Actopan",
            "Acuaco",
            "Acuamanala",
            "Acueducto Fraccionamiento",
            "Acuexcomac",
            "Acuitlapan",
            "Acuitlapilco",
            "Acuitlapilco",
            "Acuitzio",
            "Acuítzio del Canje",
            "Acula",
            "Aculco de Espinoza",
            "Acultzingo",
            "Acxotla del Monte",
            "Acxotla del Río",
            "Adjuntas del Río",
            "Adolfo López Mateos",
            "Adolfo López Mateos",
            "Adolfo López Mateos",
            "Adolfo López Mateos (El Tamarindo)",
            "Adolfo Moreno",
            "Adolfo Ruiz Cortines",
            "Adolfo Ruíz Cortines",
            "Aduana del Sásabe",
            "Agiabampo Uno",
            "Agostitlán",
            "Agrícola Lázaro Cárdenas",
            "Agrónomos Mexicanos",
            "Agua Azul",
            "Agua Azul",
            "Agua Bermeja",
            "Agua Blanca",
            "Agua Blanca Serranía",
            "Agua Buena",
            "Agua Caliente",
            "Agua Caliente",
            "Agua Caliente",
            "Agua Caliente Grande (De Gastélum)",
            "Agua Colorada",
            "Agua del Espino",
            "Agua Delgada",
            "Agua Dulce",
            "Agua Escondida",
            "Agua Escondida",
            "Agua Fría",
            "Agua Fría",
            "Agua Gorda",
            "Agua Nueva",
            "Agua Nueva",
            "Agua Paloma",
            "Agua Prieta",
            "Agua Prieta",
            "Agua Rica",
            "Agua Salada",
            "Agua Señora",
            "Agua Verde",
            "Agua Zarca",
            "Agua Zarca",
            "Agua Zarca",
            "Agua Zarca",
            "Aguacatenango",
            "Agualeguas",
            "Agualeguas Nuevo León",
            "Aguas Blancas",
            "Aguas Buenas",
            "Aguas Calientes",
            "Aguascalientes",
            "Aguilera",
            "Aguililla",
            "Agustín de Iturbide",
            "Ahome",
            "Ahuacachahue (Ndog'yo Itún Tichi)",
            "Ahuacatán",
            "Ahuacatitlán",
            "Ahuacatitlán Cuarto y Quinto Cuartel",
            "Ahuacatlán",
            "Ahuacatlán",
            "Ahuacatlán",
            "Ahuacatlán de Guadalupe",
            "Ahuacuotzingo",
            "Ahuajutla",
            "Ahualulco de Mercado Municipality",
            "Ahualulco del Sonido Trece",
            "Ahuatempan",
            "Ahuateno",
            "Ahuatepec",
            "Ahuatepec de Camino",
            "Ahuatepec Pueblo",
            "Ahuatitla",
            "Ahuatlán",
            "Ahuatlán",
            "Ahuaxintitla",
            "Ahuazotepec",
            "Ahuehuepan",
            "Ahuehuetitla",
            "Ahuehuetitla",
            "Ahuehuetitlán",
            "Ahuehuetzingo",
            "Ahuehueyo Primero Centro",
            "Ahuetita de Abajo",
            "Ahuexotitlán",
            "Ahueyahualco",
            "Ahuihuiyuco",
            "Ahuirán",
            "Ahuisculco",
            "Ahumada",
            "Ajacuba",
            "Ajalpan",
            "Ajijic",
            "Ajilhó",
            "Ajuchitlán",
            "Ajuchitlán del Progreso",
            "Ajuchitlancito",
            "Ajuno",
            "Akil",
            "Akumal",
            "Álamo",
            "Álamo Temapache",
            "Álamos",
            "Álamos Tepetitlán",
            "Alaquines",
            "Albarradas",
            "Alberto Carrera Torres",
            "Alberto Villarreal",
            "Albia",
            "Albino Zertuche",
            "Alborada",
            "Alborada Jaltenco",
            "Alcaraces",
            "Alcholoa",
            "Alcocer",
            "Alcozacán",
            "Alcozauca de Guerrero",
            "Aldama",
            "Aldama",
            "Aldama",
            "Aldama",
            "Aldama Estación",
            "Alejo González (Bilbao)",
            "Alfajayucan",
            "Alfajayucan",
            "Alfaro",
            "Alfonso G. Calderón (Poblado Siete)",
            "Alfonso G. Calderón Velarde",
            "Alfonso Garzón [Granjas Familiares]",
            "Alfonso Moguel",
            "Alfonso Reyes",
            "Alfredo V. Bonfil",
            "Alfredo V. Bonfil",
            "Alfredo V. Bonfil",
            "Alfredo V. Bonfil",
            "Alfredo V. Bonfil (Chacampalco)",
            "Alfredo V. Bonfil (Siete Ejidos)",
            "Alhuaca",
            "Alhuey",
            "Alianza Real",
            "Alista",
            "Aljojuca",
            "Allende",
            "Allende",
            "Allende",
            "Allende",
            "Allende",
            "Allende",
            "Almagres",
            "Almecatla",
            "Almolonga",
            "Almolonga",
            "Almolonga",
            "Almoloya",
            "Almoloya de Alquisiras",
            "Almoloya del Río",
            "Alpatláhuac",
            "Alpoyeca",
            "Alpuyeca",
            "Alpuyecancingo de las Montañas",
            "Altamira",
            "Altamira",
            "Altamira",
            "Altamira de Zináparo",
            "Altamirano",
            "Altata",
            "Altavista de Ramos",
            "Altepexi",
            "Alto Lucero",
            "Altotonga",
            "Altus Bosques",
            "Altzayanca",
            "Alvarado",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón",
            "Álvaro Obregón (Santa Cruz)",
            "Álvaro Obregón 2da. Sección (El Lechugal)",
            "Amacuautitlán",
            "Amacueca Municipality",
            "Amacuitlapilco",
            "Amacuzac",
            "Amado Gómez",
            "Amado Nervo",
            "Amajaquillo",
            "Amanalco de Becerra",
            "Amapa",
            "Amatán",
            "Amatanejo",
            "Amatenango de la Frontera",
            "Amatenango del Valle",
            "Amatepec",
            "Amatillo",
            "Amatitán",
            "Amatitlán",
            "Amatitlán de Azueta",
            "Amatlán",
            "Amatlán de Cañas",
            "Amatlán de los Reyes",
            "Amatlán de Quetzalcoatl",
            "Amaxac de Guerrero",
            "Amayuca",
            "Amazcala",
            "Ambrosio",
            "Amealco",
            "Ameca",
            "Ameca Municipality",
            "Amecameca",
            "Ameche",
            "Ameluca",
            "América Libre",
            "Amilcingo",
            "Amixtlán",
            "Amozoc",
            "Amozoc de Mota",
            "Ampliación Colonia Lázaro Cárdenas",
            "Ampliación de la Laguna",
            "Ampliación la Hincada",
            "Ampliación San Mateo (Colonia Solidaridad)",
            "Ampliación Tezoyuca",
            "Amuco de la Reforma",
            "Anacleto Canabal 2da. Sección",
            "Anacleto Canabal 3ra. Sección",
            "Anahuac",
            "Anáhuac",
            "Anáhuac",
            "Anáhuac",
            "Analco",
            "Analco de Ponciano Arriaga (Santa Cruz Analco)",
            "Andrés Quintana Roo",
            "Anenecuilco",
            "Angahuán",
            "Angamacutiro de la Unión",
            "Ángel Albino Corzo",
            "Ángel Albino Corzo (Guadalupe)",
            "Angel R. Cabada",
            "Angostura",
            "Animas Trujano",
            "Anoca",
            "Ansihuacuaro",
            "Antiguo Morelos",
            "Antón Lizardo",
            "Antonio Amaro",
            "Antonio Escobedo",
            "Antonio J Bermúdez",
            "Antonio Rosales",
            "Antúnez",
            "Antúnez (Morelos)",
            "Apalani",
            "Apan",
            "Apango",
            "Apango de Zaragoza",
            "Apantéopan",
            "Apantla",
            "Apapantilla",
            "Apapátaro",
            "Apas",
            "Apaseo el Alto",
            "Apaseo el Grande",
            "Apatauyan",
            "Apatzingán",
            "Apatzingán",
            "Apaxco de Ocampo",
            "Apaxtla",
            "Apaxtla de Castrejón",
            "Apazapan",
            "Apazulco",
            "Apeo",
            "Apetatitlán Antonio Carbajal",
            "Apipilulco",
            "Apizaco",
            "Apo",
            "Apoala",
            "Apodaca",
            "Apoderado",
            "Aporo",
            "Apozol",
            "Apozol de Gutiérrez",
            "Aputzio de Juárez",
            "Aquila",
            "Aquila",
            "Aquiles Córdova Morán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán",
            "Aquiles Serdán (San Fernando)",
            "Aquiles Serdán 1ra. Sección",
            "Aquismón",
            "Aquixtla",
            "Aramberri",
            "Arandas",
            "Arandas",
            "Arantepacua",
            "Aranza",
            "Araró",
            "Aratichanguío",
            "Arbolada los Sauces",
            "Arboledas",
            "Arboledas Paso Blanco [Fraccionamiento]",
            "Arbolillo",
            "Arcelia",
            "Arcelia de Rodríguez",
            "Arcila",
            "Arcinas",
            "Arellano",
            "Arena 1ra. Sección",
            "Arena 6ta. Sección (La Bolsa)",
            "Arena de Hidalgo",
            "Arenal",
            "Arenal de Álvarez (Arenal de Paco)",
            "Arenal Santa Ana",
            "Arimatea",
            "Ario de Rayón",
            "Ario de Rosales",
            "Arivechi",
            "Arizpe",
            "Armando Fernández Garza",
            "Armería",
            "Arriaga",
            "Arroyo Chical (Nuevo Arroyo Chicali)",
            "Arroyo Choápam",
            "Arroyo de Banco",
            "Arroyo de Enmedio",
            "Arroyo del Maíz Uno",
            "Arroyo del Potrero",
            "Arroyo Grande",
            "Arroyo Granizo",
            "Arroyo Hondo",
            "Arroyo Hondo",
            "Arroyo Hondo Abejonal",
            "Arroyo Limón",
            "Arroyo Palenque",
            "Arroyo San Isidro",
            "Arroyo Seco",
            "Arroyo Vista Hermosa",
            "Arroyo Zapotillo",
            "Arroyos",
            "Arteaga",
            "Arteaga",
            "Artemio Treviño",
            "Artículo Primero Constitucional",
            "Arturo Martínez Adame",
            "Arvenza Uno",
            "Ascensión",
            "Asientos",
            "Astacinga",
            "Astapa",
            "Asunción Cacalotepec",
            "Asunción Ixtaltepec",
            "Asunción Nochixtlán",
            "Asunción Ocotlán",
            "Asunción Tlacolulita",
            "Atacco",
            "Atacheo de Regalado",
            "Atalpan",
            "Atapán",
            "Atapaneo",
            "Atarjea",
            "Atarjeas de Covarrubias",
            "Atasta",
            "Atecax",
            "Atecucario de la Constitución (Atecuario)",
            "Atemanica",
            "Atempan",
            "Atenango del Río",
            "Atencingo",
            "Atengo",
            "Atengo",
            "Atenguillo",
            "Atenguillo",
            "Atenxoxola",
            "Atequiza",
            "Atexcal",
            "Atexcatzingo",
            "Aticama",
            "Atil",
            "Atitalaquia",
            "Atizapán",
            "Atla",
            "Atlacahualoya",
            "Atlacholoaya",
            "Atlacomulco de Fabela",
            "Atlahuilco",
            "Atlamajac",
            "Atlamajalcingo del Río",
            "Atlangatepec",
            "Atlapexco",
            "Atlatlahucan",
            "Atlatongo",
            "Atlautla",
            "Atlequizayan",
            "Atliaca",
            "Atlixco",
            "Atlixtac",
            "Atlzayanca",
            "Atolinga",
            "Atoluca",
            "Atonalisco",
            "Atongo",
            "Atotonilco",
            "Atotonilco",
            "Atotonilco de Tula",
            "Atotonilco el Alto Municipality",
            "Atotonilco el Bajo",
            "Atotonilco el Grande",
            "Atotonilquillo",
            "Atoyac",
            "Atoyac",
            "Atoyac de Álvarez",
            "Atoyatempan",
            "Atzacan",
            "Atzacoaloya",
            "Atzala",
            "Atzalan",
            "Atzalán",
            "Atzcatlán",
            "Atzingo (La Cumbre)",
            "Atzitzintla",
            "Augusto Gómez Villanueva",
            "Aurelio Manrique",
            "Aurora Ermita",
            "Aurora Esquipulas",
            "Aután",
            "Autlán de Navarro",
            "Axapusco",
            "Axaxacualco",
            "Axixintla",
            "Axochiapan",
            "Axochío",
            "Axotlán",
            "Axtla de Terrazas",
            "Axutla",
            "Axuxco",
            "Ayahualulco",
            "Ayahualulco",
            "Ayala",
            "Ayapa",
            "Ayapango",
            "Ayometitla",
            "Ayoquezco de Aldama",
            "Ayotitlán",
            "Ayotla",
            "Ayotlán Municipality",
            "Ayotoxco de Guerrero",
            "Ayotuxtla",
            "Ayotzinapa",
            "Ayotzintepec",
            "Ayuquila",
            "Ayutla",
            "Ayutla",
            "Ayutla de los Libres",
            "Ayutla Jalisco",
            "Azcapotzalco",
            "Azoyú",
            "Azteca",
            "Aztlán",
            "Azumbilla",
            "Baborigame",
            "Baburia",
            "Baca",
            "Bacabachi",
            "Bacabchén",
            "Bacadéhuachi",
            "Bacalar",
            "Bacame Nuevo",
            "Bacanora",
            "Bacerac",
            "Bachajón",
            "Bachigualatito",
            "Bachíniva",
            "Bachoco",
            "Bachomobampo Número Dos",
            "Bacobampo",
            "Bacorehuis",
            "Bacubirito",
            "Bácum",
            "Badiraguato",
            "Bagojo Colectivo",
            "Bahía Asunción",
            "Bahía de Kino",
            "Bahía de Lobos",
            "Bahía Tortugas",
            "Bahuichivo",
            "Bajío de Bonillas",
            "Bajío de Ratones",
            "Bajío de San José",
            "Bajío de San Nicolás",
            "Bajío Seco",
            "Bajos de Chila",
            "Bajos del Ejido",
            "Bajucu",
            "Balancán",
            "Balcones de la Calera",
            "Balleza",
            "Bamoa",
            "Banco Nacional",
            "Banderas",
            "Banderas (Guatacalca 2da. Sección)",
            "Banderas del Águila",
            "Banderilla",
            "Bangandhó",
            "Banus Vallarta",
            "Bara de Chachalacas",
            "Bariometo",
            "Barra de Cazones",
            "Barra de Navidad",
            "Barra de Tecoanapa",
            "Barranca de los Laureles",
            "Barranca de Otates",
            "Barranca de Santa Clara",
            "Barranca del Tule",
            "Barrancas",
            "Barrancas y Amate 3ra. Sección",
            "Barretal",
            "Barretos",
            "Barrio Aldamas",
            "Barrio Aztlán",
            "Barrio Bordo Nuevo",
            "Barrio Chiquichuca",
            "Barrio Cuarto (La Loma)",
            "Barrio de Arriba de San Juan Xoconusco",
            "Barrio de Boyecha",
            "Barrio de Canales",
            "Barrio de Centro del Cerrillo",
            "Barrio de Ensido",
            "Barrio de España",
            "Barrio de Guadalupe",
            "Barrio de Guadalupe del Mezquitillo",
            "Barrio de Jalisco",
            "Barrio de Jesús Fracción Primera",
            "Barrio de la Barranca",
            "Barrio de México",
            "Barrio de Nuevo León",
            "Barrio de Puentecillas",
            "Barrio de Reyes",
            "Barrio de San Isidro",
            "Barrio de San Juan",
            "Barrio de San Miguel",
            "Barrio de San Miguel",
            "Barrio de San Pedro la Cabecera",
            "Barrio de San Ramón",
            "Barrio de Tapias",
            "Barrio del Cajón",
            "Barrio el Boncho",
            "Barrio el Vivero",
            "Barrio la Joya",
            "Barrio la Tenería",
            "Barrio los Tules",
            "Barrio Nuevo",
            "Barrio Nuevo de los Muertos",
            "Barrio San Diego",
            "Barrio San Joaquín el Junco",
            "Barrio San Miguel Dorami",
            "Barrio Santa Cruz",
            "Barrio Tepetitlán Emilio Portes Gil",
            "Barrio Tlatenco",
            "Barrón",
            "Barrón",
            "Basaseachic",
            "Basconcobe",
            "Basúchil",
            "Batopilas",
            "Bautista Chico",
            "Bavispe",
            "Bécal",
            "Becanchén",
            "Bejucal de Ocampo",
            "Bejucos",
            "Belem",
            "Belem del Refugio",
            "Belén",
            "Belén Atzitzimititlán",
            "Belisario Domínguez",
            "Belisario Domínguez",
            "Bella Esperanza",
            "Bella Vista",
            "Bella Vista del Río",
            "Bellas Fuentes",
            "Bellavista",
            "Bellavista",
            "Bellavista",
            "Bellavista de Victoria (San José Bellavista)",
            "Bello Amanecer",
            "Benemérito de las Américas",
            "Benemérito Juárez",
            "Benito García (El Zorrillo)",
            "Benito González",
            "Benito Juárez",
            "Benito Juarez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez",
            "Benito Juárez (La Playita)",
            "Benito Juárez (Vinatería)",
            "Benito Juárez II (San Martín)",
            "Benito Juárez Uno",
            "Benjamín Hill",
            "Beristain",
            "Bermejillo",
            "Bernal",
            "Berriozábal",
            "Betania",
            "Betania",
            "Betania",
            "Bethania",
            "Betulia",
            "Bicercio",
            "Bienvenido",
            "Bimbaletes Aguascalientes (El Álamo)",
            "Blanca Espuma",
            "Blasillo 1ra. Sección (Nicolás Bravo)",
            "Bledos",
            "Bobashi de Guadalupe",
            "Boca de Tomatlán",
            "Boca del Monte",
            "Boca del Rio",
            "Bocaneo (San Pedro)",
            "Bocas",
            "Bochil",
            "Bochojbo Alto",
            "Bocoyna",
            "Bokoba",
            "Bolaños Municipality",
            "Bolón",
            "Bolonchén de Rejón",
            "Bomanxotha",
            "Bomintzha",
            "Bondojito",
            "Bonifacio García",
            "Bonifacio Moreno (El Aguaje)",
            "Boquiapa",
            "Boquilla de Babisas (La Boquilla de Conchos)",
            "Boquilla de las Perlas",
            "Boquillas",
            "Bordo Blanco",
            "Boshindó",
            "Bosque de Saloya",
            "Bosque Real Fraccionamiento",
            "Bosques de la Magdalena",
            "Bosques de San Pedro",
            "Boxasní",
            "Boyé",
            "Bravo",
            "Brisas Barra de Suchiate",
            "Brisas de la Navidad",
            "Brisas de Zicatela",
            "Brisas del Carrizal",
            "Briseñas",
            "Briseñas de Matamoros",
            "Brizuela",
            "Buaysiacobe",
            "Buayums",
            "Bucerías",
            "Buctzotz",
            "Buen Retiro (El Retiro)",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista (Apasco)",
            "Buena Vista (Puxcatán)",
            "Buena Vista 1ra. Sección",
            "Buena Vista de la Salud",
            "Buenaventura",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista (Matasanos)",
            "Buenavista de Allende",
            "Buenavista de Benito Juárez",
            "Buenavista de Cortés",
            "Buenavista de Cuéllar",
            "Buenavista de Juárez",
            "Buenavista de Trujillo",
            "Buenavista Tetela",
            "Buenavista Tomatlán",
            "Buenos Aires",
            "Buenos Aires",
            "Buenos Aires",
            "Buenos Aires",
            "Buenos Aires",
            "Buenos Aires",
            "Buenos Aires",
            "Buenos Aires (San Isidro)",
            "Burgos",
            "Bustamante",
            "Bustamante",
            "C-32 (Licenciado Francisco Trujillo Gurría)",
            "C-41 (Licenciado Carlos A. Madrazo)",
            "Cabecera de Indígenas",
            "Cabeza de Toro",
            "Cabezas",
            "Cabo Corrientes",
            "Cabo San Lucas",
            "Cacahoatán",
            "Cacahuatal",
            "Cacalchen",
            "Cacalomacan",
            "Cacalotán",
            "Cacalotenango",
            "Cacaloxúchitl",
            "Cacalutla",
            "Cacao",
            "Cacaté",
            "Cadereyta",
            "Cadereyta",
            "Cadereyta Jiménez",
            "Cahuatache",
            "Caimanero",
            "Caja de Agua",
            "Caja Pinta",
            "Cajelitos",
            "Cajititlán",
            "Cajonos",
            "Cala Norte",
            "Cala Sur",
            "Calakmul",
            "Calamanda",
            "Calcahualco",
            "Calcehtoc",
            "Calderitas",
            "Calera Chica",
            "Caleras",
            "Caleras de Ameche",
            "Calería",
            "Calerita",
            "Calichar Palma Sola",
            "Cálido",
            "California",
            "Calihualá",
            "Calimaya",
            "Calipan",
            "Calixtlahuaca",
            "Calkiní",
            "Calle Real",
            "Callejones",
            "Calmeca",
            "Calnali",
            "Calotmul",
            "Calpanería Atezquilla",
            "Calpulalpan",
            "Caltimacan",
            "Caltzontzín",
            "Calvario Buenavista",
            "Calvario del Carmen",
            "Calvillo",
            "Calzada del Tepozán",
            "Calzada Larga",
            "Calzadas",
            "Camajapita",
            "Camalotita",
            "Camalú",
            "Camargo",
            "Camargo",
            "Camarón de Tejeda",
            "Camelia (Barrio la Camelia)",
            "Camelia Roja",
            "Camémbaro",
            "Camerino Z. Mendoza",
            "Camichín de Jauja",
            "Camocuautla",
            "Camotepec",
            "Camotinchan",
            "Camotlán de Miraflores",
            "Campanario",
            "Campeche",
            "Campestre el Montesito",
            "Campestre Flamboyanes",
            "Campestre las Palomas Fraccionamiento",
            "Campestre Tarímbaro",
            "Campestre Villas del Álamo",
            "Campo Acosta",
            "Campo Balbuena",
            "Campo Carretero",
            "Campo Chico",
            "Campo de Águila",
            "Campo Grande",
            "Campo la Arrocera",
            "Campo Pesquero el Colorado",
            "Campo Real",
            "Campo Sesenta",
            "Cañada",
            "Cañada de Bustos",
            "Cañada de Caracheo",
            "Cañada de Cisneros",
            "Cañada de Guadarrama",
            "Cañada de Islas",
            "Cañada de Madero",
            "Cañada de Negros",
            "Cañada de Ramírez",
            "Cañada de Ricos",
            "Cañada de San Ignacio",
            "Cañada del Tabaco",
            "Cañada Grande",
            "Cañadas de Obregón",
            "Canalejas",
            "Cananea",
            "Cañas",
            "Canatlán",
            "Canchol",
            "Cancuc",
            "Cancún",
            "Candela",
            "Candelaria",
            "Candelaria Loxicha",
            "Candelaria Portezuelo",
            "Cañitas de Felipe Pescador",
            "Canoas",
            "Canoas de Arriba",
            "Cañón de Vacas",
            "Cansahcab",
            "Cantabria",
            "Cantamayec",
            "Cantera de Villagrán",
            "Cantinela",
            "Cantioc",
            "Cantón las Delicias",
            "Cantón Rancho Nuevo",
            "Cantón Villaflor",
            "Cantú",
            "Cantuna",
            "Caobanal 1ra. Sección (Mezcalapa)",
            "Caobas",
            "Capacho",
            "Capácuaro",
            "Caparroso",
            "Capilla de Guadalupe",
            "Capilla de Milpillas",
            "Capitán Felipe Castellanos Díaz (San Pedro)",
            "Capoluca",
            "Capula",
            "Capula",
            "Capula",
            "Capulálpam de Méndez",
            "Capulhuac de Mirafuentes",
            "Capulín",
            "Capulín de Bustos",
            "Caquixtle de Arriba",
            "Carácuaro",
            "Carapán",
            "Carbo",
            "Carbonera",
            "Carboneras",
            "Carboneras",
            "Carboneras",
            "Carboneras",
            "Cárdenas",
            "Cárdenas",
            "Cárdenas",
            "Cardonal",
            "Carichí",
            "Caristay",
            "Carlos A. Carrillo",
            "Carlos A. Madrazo",
            "Carmen",
            "Carmen",
            "Carmen Serdán",
            "Carmen Yalchuch",
            "Carmen Zacatal",
            "Carona",
            "Carpinteros",
            "Carranco",
            "Carrasqueño",
            "Carretas",
            "Carretas",
            "Carricitos",
            "Carrillo",
            "Carrillo Puerto",
            "Carrillo Puerto",
            "Carrillo Puerto",
            "Carrillo Puerto",
            "Carrizal",
            "Carrizal",
            "Carrizal Grande",
            "Carrizalillo",
            "Cartagena [Fraccionamiento]",
            "Casa Blanca",
            "Casa Blanca",
            "Casa Blanca",
            "Casa de Cerros",
            "Casa de Teja",
            "Casa Nueva",
            "Casa Rosa",
            "Casahuatlán",
            "Casas",
            "Casas Grandes",
            "Casas Viejas",
            "Casas Viejas",
            "Caserío de Cortés",
            "Cash",
            "Casimiro Castillo",
            "Casitas",
            "Castamay",
            "Castaños",
            "Castillo de Teayo",
            "Castro Urdiales",
            "Catarino Rodríguez",
            "Catazajá",
            "Catemaco",
            "Catishtic",
            "Caucel",
            "Caurio de Guadalupe",
            "Caxapa",
            "Caxhuacán",
            "Caxitepec",
            "Caxuxi",
            "Cayaco",
            "Cazones de Herrera",
            "Cebadilla 1ra. Sección",
            "Ceballos",
            "Cecilio Terán",
            "Cedro de la Manzana",
            "Cedros",
            "CEFERESO Número 3",
            "Ceiba 1ra. Sección (Jahuactal)",
            "Ceja de Bolitas",
            "Ceja de Bravo",
            "Celaya",
            "Celayita",
            "Celestún",
            "Cementeras del Pital",
            "Cenobio Aguilar (La Trinidad)",
            "Cenobio Moreno",
            "Cenotillo",
            "Centro",
            "Centro de Arriba",
            "Centro de Readaptación Social",
            "Centro de Readaptación Social",
            "Centro de Readaptación Social Nuevo",
            "Centro Familiar la Soledad",
            "Centro Habitacional Burócratas de Guadalupe",
            "Cepeda",
            "Cerano",
            "Cereso 14 (El Amate)",
            "Cereso del Hongo",
            "CERESO Nuevo",
            "Cerocahui",
            "Cerralvo",
            "Cerrillos (Campo 35)",
            "Cerrito de Buenos Aires",
            "Cerrito de Gasca",
            "Cerrito de Jaral",
            "Cerrito de la Cruz",
            "Cerrito de Tierra",
            "Cerrito de Zavala",
            "Cerritos",
            "Cerritos",
            "Cerritos",
            "Cerritos",
            "Cerritos de Cárdenas",
            "Cerritos del Pilar",
            "Cerro Agudo",
            "Cerro Armadillo Grande",
            "Cerro Azul",
            "Cerro Colorado",
            "Cerro Colorado de Ocampo",
            "Cerro de la Cruz",
            "Cerro de Ortega",
            "Cerro de Piedra",
            "Cerro de San Francisco",
            "Cerro de San Pedro",
            "Cerro del Aire",
            "Cerro del Murciélago",
            "Cerro Gordo",
            "Cerro Gordo",
            "Cerro Gordo",
            "Cerro Gordo",
            "Cerro Grande",
            "Cerro Guzmán",
            "Cerro La Calera",
            "Cerro las Iguanas",
            "Cerro Quemado",
            "Cerro Santa Fe",
            "Ceuta",
            "Chablé",
            "Chablekal",
            "Chacala",
            "Chacalapa",
            "Chacalapa",
            "Chacaljocóm",
            "Chacaltianguis",
            "Chachahuantla",
            "Chachahuatlán",
            "Chacoma",
            "Chacsinkin",
            "Chahuite",
            "Chahuites",
            "Chalahuiyapa",
            "Chalam",
            "Chalcatzingo",
            "Chalchihuapan",
            "Chalchihuapan",
            "Chalchihuitán",
            "Chalchihuites",
            "Chalchocoyo",
            "Chalco",
            "Chalma",
            "Chalma",
            "Chalmita",
            "Chalpicote",
            "Chamácuaro",
            "Chamacuero",
            "Chamela Jalisco",
            "Chametla",
            "Chametla",
            "Champotón",
            "Chamula",
            "Chamulapita",
            "Chan Cenote",
            "Chanal",
            "Chancol",
            "Chancuellar",
            "Changata",
            "Chankom",
            "Chapa",
            "Chapa de Mota",
            "Chapab",
            "Chapala",
            "Chapalilla",
            "Chapallal Grande",
            "Chapantongo",
            "Chaparaco",
            "Chaparrosa",
            "Chapopote Chico",
            "Chapopote Núñez",
            "Chapulco",
            "Chapulhuacán",
            "Chapulhuacanito",
            "Chapultenango",
            "Chapultepec",
            "Charapán",
            "Charapendo",
            "Charay",
            "Charcas",
            "Charco Blanco",
            "Charco Blanco",
            "Charco de Pantoja",
            "Charco Redondo",
            "Charo",
            "Chatipán",
            "Chaucingo",
            "Chavarría",
            "Chavarrillo",
            "Chavaxtla",
            "Chavinda",
            "Chayotillo",
            "Chelem",
            "Chemax",
            "Cherán",
            "Cherán Atzicuirín (Cheranástico)",
            "Chetumal",
            "Chiapa de Corzo",
            "Chiapilla",
            "Chiaucingo",
            "Chiautla",
            "Chiautla de Tapia",
            "Chicahua",
            "Chicahuaxtla",
            "Chicapa de Castro",
            "Chicavasco",
            "Chicbul",
            "Chichatla",
            "Chichén-Itzá",
            "Chichicapa",
            "Chichicapa",
            "Chichicastle 1ra. Sección",
            "Chichicaxtle",
            "Chichicuautla",
            "Chichihualco",
            "Chichimequillas",
            "Chichimequillas",
            "Chichimequillas",
            "Chichimila",
            "Chichiquila",
            "Chicoasén",
            "Chicola",
            "Chicoloapan",
            "Chicomuselo",
            "Chiconamel",
            "Chiconamel",
            "Chiconcuac",
            "Chiconcuac",
            "Chiconcuautla",
            "Chiconquiaco",
            "Chicontepec",
            "Chicontla",
            "Chicualoque",
            "Chicuasen",
            "Chicumtantic",
            "Chicxulub Pueblo",
            "Chicxulub Puerto",
            "Chiepetepec",
            "Chietla",
            "Chigmecatitlán",
            "Chignahuapan",
            "Chihuahua",
            "Chihuahua",
            "Chihuahuita",
            "Chikindzonot",
            "Chila",
            "Chila de Juárez",
            "Chila de la Sal",
            "Chilacachapa",
            "Chilacayote",
            "Chilapa",
            "Chilapa",
            "Chilapa de Álvarez",
            "Chilchota",
            "Chilchota",
            "Chilchotla",
            "Chilcuautla",
            "Chililico",
            "Chiloljá",
            "Chilón",
            "Chilpancingo",
            "Chiltepec de Hidalgo",
            "Chiltoyac",
            "Chimalapa",
            "Chimalpa Viejo",
            "Chimalpa y Tlalayote",
            "Chimaltitán",
            "Chimhucum",
            "China",
            "Chiná",
            "Chinameca",
            "Chinameca",
            "Chinampa de Gorostiza",
            "Chinampas",
            "Chinantla",
            "Chinautla",
            "Chínipas",
            "Chinobampo",
            "Chipahuatlán",
            "Chipilo de Francisco Javier Mina",
            "Chipinque de Arriba",
            "Chiquihuitillo",
            "Chiquilá",
            "Chiquilistlán",
            "Chiquilistlán Jalisco",
            "Chiquimitío",
            "Chiquinival",
            "Chiquinshulum",
            "Chirimoya (Estación Chirimoya)",
            "Chitejé de Garabato",
            "Chixtontic",
            "Chocamán",
            "Chochola",
            "Choix",
            "Cholul",
            "Cholul Cantón",
            "Cholula",
            "Chome",
            "Chonegal",
            "Chontalcoatlán",
            "Chontalpa",
            "Chontla",
            "Chosto de los Jarros",
            "Chuburná",
            "Chucándiro",
            "Chucarit",
            "Chula Vista",
            "Chulum Cárdenas",
            "Chulum Juárez",
            "Chumatlán",
            "Chumayel",
            "Chunchintok",
            "Chunchucmil",
            "Chunhuhub",
            "Chuniapan de Arriba",
            "Chupaderos",
            "Chupio",
            "Churintzio",
            "Churintzio",
            "Churipitzeo",
            "Churumuco de Morelos",
            "Cicacalco",
            "Ciénega de Flores",
            "Ciénega de Galvanes",
            "Ciénega de Nuestra Señora de Guadalupe",
            "Ciénega de Zimatlán",
            "Ciénega del Pastor",
            "Ciénega Grande",
            "Cieneguilla",
            "Cieneguilla",
            "Cieneguillas",
            "Cieneguillas",
            "Cieneguitas",
            "Cihuatlán Jalisco",
            "Cima del Sol",
            "Cinco de Febrero",
            "Cinco de Mayo",
            "Cinco de Mayo",
            "Cinco Señores",
            "Cintalapa",
            "Cintalapa de Figueroa",
            "Cipreses",
            "Citala",
            "Citilcum",
            "Citlaltépec",
            "Citlaltépetl",
            "Ciudad Acuña",
            "Ciudad Altamirano",
            "Ciudad Anáhuac",
            "Ciudad Apodaca",
            "Ciudad Ayala",
            "Ciudad Benito Juárez",
            "Ciudad Camargo",
            "Ciudad Cerralvo",
            "Ciudad Chemuyil",
            "Ciudad Coahuila (Kilómetro Cincuenta y Siete)",
            "Ciudad Constitución",
            "Ciudad Cuauhtémoc",
            "Ciudad Cuauhtémoc",
            "Ciudad de Allende",
            "Ciudad de Armería",
            "Ciudad de Chiautla de Tapia",
            "Ciudad de Cuetzalan",
            "Ciudad de Huajuapan de León",
            "Ciudad de Huitzuco",
            "Ciudad de México",
            "Ciudad de Nanacamilpa",
            "Ciudad de Tlatlauquitepec",
            "Ciudad de Villa de Álvarez",
            "Ciudad de Villaldama",
            "Ciudad del Carmen",
            "Ciudad del Maíz",
            "Ciudad Delicias",
            "Ciudad Fernández",
            "Ciudad General Escobedo",
            "Ciudad General Terán",
            "Ciudad Guadalupe Victoria",
            "Ciudad Gustavo Díaz Ordaz",
            "Ciudad Guzmán",
            "Ciudad Guzmán CERESO",
            "Ciudad Hidalgo",
            "Ciudad Hidalgo",
            "Ciudad Insurgentes",
            "Ciudad Lázaro Cárdenas",
            "Ciudad Lerdo",
            "Ciudad López Mateos",
            "Ciudad Madero",
            "Ciudad Mante",
            "Ciudad Manuel Doblado",
            "Ciudad Melchor Múzquiz",
            "Ciudad Mendoza",
            "Ciudad Miguel Alemán",
            "Ciudad Morelos",
            "Ciudad Nezahualcoyotl",
            "Ciudad Obregón",
            "Ciudad Pemex",
            "Ciudad Río Bravo",
            "Ciudad Sabinas Hidalgo",
            "Ciudad Sahagun",
            "Ciudad Satélite",
            "Ciudad Satélite del Norte",
            "Ciudad Serdán",
            "Ciudad Tula",
            "Ciudad Valles",
            "Ciudad Victoria",
            "Cliserio Alanís (San Gaspar)",
            "Cloete",
            "Coacalco",
            "Coachimalco",
            "Coachiti",
            "Coacoatzintla",
            "Coacotla",
            "Coacoyula de Álvarez",
            "Coacoyulichán",
            "Coacoyulillo",
            "Coacuilco",
            "Coahuayana de Hidalgo",
            "Coahuayana Viejo",
            "Coahuayutla de Guerrero",
            "Coahuayutla de José María Izazaga",
            "Coahuitlán",
            "Coahuixco",
            "Coahuixtla",
            "Coajomulco",
            "Coalcomán de Vázquez Pallares",
            "Coamilpa de Juárez",
            "Coamostita",
            "Coapilla",
            "Coatecas Altas",
            "Coatepec",
            "Coatepec",
            "Coatepec",
            "Coatepec Costales",
            "Coatepec Harinas",
            "Coatetelco",
            "Coatlán del Río",
            "Coatlancillo",
            "Coatzacoalcos",
            "Coatzingo",
            "Coatzintla",
            "Coba",
            "Cochoapa",
            "Cochoapa el Grande",
            "Cocohital",
            "Cocorit",
            "Cocotitlán",
            "Cocoyoc",
            "Cocoyol",
            "Cocoyotla",
            "Cocuasco",
            "Cocucho",
            "Cocula",
            "Cocula Jalisco",
            "Codorniz",
            "Coeneo de la Libertad",
            "Coetzala",
            "Cofradía",
            "Cofradía",
            "Cofradía de Juárez",
            "Cofradía de Morelos",
            "Cofradía de Navolato (Cofradía de los Rocha)",
            "Cofradía de Suchitlán",
            "Cofradía Santa Cruz de Camotlán",
            "Cohuecán",
            "Coixtlahuaca",
            "Cojumatlán de Régules",
            "Col. Bosques de las Lomas",
            "Colatlán",
            "Colinas de Plata",
            "Colinas de Santa Cruz Segunda Sección",
            "Colinas del Aeropuerto",
            "Colinas del Río Juárez",
            "Colinas del Roble",
            "Colinas del Sol",
            "Colinas del Valle",
            "Colipa",
            "Collantes",
            "Colombia",
            "Colombia de Guadalupe",
            "Colón",
            "Colonia 18 de Marzo",
            "Colonia 2 de Septiembre",
            "Colonia 24 de Febrero",
            "Colonia 28 de Mayo (Santa Rosa)",
            "Colonia 3 de Mayo",
            "Colonia 3 de Mayo (La Cruz)",
            "Colonia Abraham González (La Quemada)",
            "Colonia Adolfo López Mateos",
            "Colonia Adolfo Ruiz Cortines (Colonia Obrera)",
            "Colonia Aeropuerto",
            "Colonia Agrícola Álvaro Obregón",
            "Colonia Agrícola Analco",
            "Colonia Agrícola de Ocotepec (Colonia San José)",
            "Colonia Agrícola México (Palmitas)",
            "Colonia Agua Caliente",
            "Colonia Alborada",
            "Colonia Álvaro Obregón",
            "Colonia Álvaro Obregón",
            "Colonia Ángel Bocanegra (Adolfo López Mateos)",
            "Colonia Antorcha Campesina (Santa Rosa)",
            "Colonia Aquiles Córdoba Morán",
            "Colonia Arboledas (San Andrés)",
            "Colonia Aviación Autopan",
            "Colonia Azteca",
            "Colonia Bellavista",
            "Colonia Benito Juárez",
            "Colonia Bosques del Sol",
            "Colonia Campesina",
            "Colonia Capilla de Rayo",
            "Colonia Chalchihuecan",
            "Colonia Constitución",
            "Colonia Cuauhtémoc",
            "Colonia Cuauhtémoc",
            "Colonia Cuisillos",
            "Colonia de Guadalupe",
            "Colonia del Sol",
            "Colonia del Valle",
            "Colonia Diana Laura Riojas de Colosio",
            "Colonia Doctor Gustavo Baz",
            "Colonia Ecológica Asociación de Lucha Social (Lucha Social)",
            "Colonia el Florido",
            "Colonia el Mirador",
            "Colonia el Mirador (Llano del Ejido)",
            "Colonia el Pedregal",
            "Colonia el Pirame",
            "Colonia el Refugio",
            "Colonia el Renacimiento",
            "Colonia el Salado",
            "Colonia Emiliano Zapata",
            "Colonia Emiliano Zapata",
            "Colonia Emiliano Zapata",
            "Colonia Emiliano Zapata (San Juan Zitácuaro)",
            "Colonia Emiliano Zapata Ejido de Tenancingo",
            "Colonia Felipe Ángeles",
            "Colonia Felipe Ángeles",
            "Colonia Felipe Ángeles",
            "Colonia Fraccionamiento el Puente",
            "Colonia Francisco García Salinas",
            "Colonia Francisco Javier Mina",
            "Colonia Ganadera",
            "Colonia Gómez Morín",
            "Colonia Guadalupana",
            "Colonia Guadalupe",
            "Colonia Guadalupe",
            "Colonia Guadalupe Victoria",
            "Colonia Guadalupe Victoria (La Capilla)",
            "Colonia Gustavo Baz Prada",
            "Colonia Hatmasie",
            "Colonia Hidalgo (El Tecolote)",
            "Colonia Ignacio Allende",
            "Colonia Independencia",
            "Colonia Independencia (Monterrey)",
            "Colonia Isidro Fabela",
            "Colonia Jordán",
            "Colonia José María Morelos",
            "Colonia Juárez",
            "Colonia Juárez",
            "Colonia la Asunción",
            "Colonia la Calzada (La Olla)",
            "Colonia la Central",
            "Colonia la Granjena",
            "Colonia la Libertad",
            "Colonia la Mesa",
            "Colonia las Arboledas",
            "Colonia las Flores",
            "Colonia las Granjas",
            "Colonia las Malvinas (Colonia Antorcha)",
            "Colonia las Palmas Campamento SAGAR",
            "Colonia Latinoamericana",
            "Colonia Lázaro Cárdenas",
            "Colonia Lázaro Cárdenas",
            "Colonia Lázaro Cárdenas (Los Hornos)",
            "Colonia Lázaro Cárdenas del Río",
            "Colonia Lealtad",
            "Colonia Libertad",
            "Colonia Lindavista",
            "Colonia Loma Alta",
            "Colonia Lomas de San Ramón (Triquis)",
            "Colonia los Aguiluchos",
            "Colonia los Ángeles",
            "Colonia los Cedros",
            "Colonia los Cerritos",
            "Colonia los Remedios",
            "Colonia los Robles",
            "Colonia los Sauces",
            "Colonia Luces en el Mar",
            "Colonia Madero",
            "Colonia Manuel González",
            "Colonia Michoacana",
            "Colonia Miguel Hidalgo",
            "Colonia Militar",
            "Colonia Montemariana (Colonia Mariana)",
            "Colonia Morelos",
            "Colonia Morelos",
            "Colonia Morelos",
            "Colonia Morelos (El Nueve)",
            "Colonia Morelos de Guadalupe de Rivera",
            "Colonia Nativitas",
            "Colonia Nicolás Bravo (Kilómetro Noventa y Dos)",
            "Colonia Niños Héroes",
            "Colonia Nueva Era",
            "Colonia Nueva Revolución",
            "Colonia Nuevo México",
            "Colonia Obrera",
            "Colonia Obrera",
            "Colonia Obrera",
            "Colonia Padre Hidalgo",
            "Colonia Palma",
            "Colonia Palo Prieto (Chipitongo)",
            "Colonia Paraíso Escondido",
            "Colonia Paso del Molino",
            "Colonia Patria Nueva",
            "Colonia Pedro María Anaya",
            "Colonia Pinal Villa",
            "Colonia Plenitud",
            "Colonia Progreso",
            "Colonia Rafael Corrales Ayala",
            "Colonia Reforma Tlalmimilolpan",
            "Colonia Renacimiento",
            "Colonia Revolución",
            "Colonia Ricardo Flores Magón",
            "Colonia Rincón Viejo",
            "Colonia Rincón Villa del Valle",
            "Colonia Río Lerma (Tic Ti)",
            "Colonia San Francisco",
            "Colonia San Francisco (San Francisco)",
            "Colonia San Francisco de Asís",
            "Colonia San Isidro",
            "Colonia San Juan",
            "Colonia San Luis",
            "Colonia San Luis Rey",
            "Colonia San Ramón",
            "Colonia Santa Bárbara",
            "Colonia Santa Cecilia",
            "Colonia Sesenta y Dos",
            "Colonia Teñhe",
            "Colonia Terrazas",
            "Colonia Trigomil",
            "Colonia Tulteca Teopan",
            "Colonia Úrsulo Galván",
            "Colonia Veinte de Noviembre",
            "Colonia Veintitrés de Mayo",
            "Colonia Veintiuno de Marzo",
            "Colonia Venustiano Carranza",
            "Colonia Venustiano Carranza",
            "Colonia Venustiano Carranza",
            "Colonia Vista Bella (Lomas del Peaje)",
            "Colonia Wenceslao Labra",
            "Colonia Yucatán",
            "Colonias Pedernales",
            "Colorines",
            "Colorines",
            "Colotepec",
            "Colotlán",
            "Colotlán Municipality",
            "Colotlipa",
            "Colucan",
            "Comachuén",
            "Comala",
            "Comalapa",
            "Comalcalco",
            "Comales",
            "Comaltepec",
            "Comanjá",
            "Comanja de Corona",
            "Comanjilla",
            "Comapa",
            "Comején",
            "Comitán",
            "Comoapan",
            "Comondú",
            "Comonfort",
            "Compostela",
            "Compuertas",
            "Concá",
            "Concentración 5 de Febrero",
            "Concepción",
            "Concepción Capulac (La Ex-Hacienda)",
            "Concepción Caro",
            "Concepción Chimalpa",
            "Concepción Cuautla",
            "Concepción de Buenos Aires",
            "Concepción del Bramador",
            "Concepción del Monte",
            "Concepción del Oro",
            "Concepción Hidalgo",
            "Concepción Jolalpan",
            "Concepción la Venta",
            "Concepción Pápalo",
            "Conchos",
            "Concordia",
            "Concordia",
            "Condémbaro",
            "Conejeras",
            "Conejos",
            "Conferín Arizpe",
            "Congregación Calles",
            "Congregación el Tajín",
            "Congregación Hidalgo",
            "Congregación Ortíz",
            "Congregación Reforma",
            "Conjunto Habitacional Ecológico SUTEYM",
            "Conjunto Habitacional el Trébol",
            "Conjunto Habitacional Villas del Pedregal",
            "Conjunto Urbano la Loma I",
            "Conkal",
            "Constancia",
            "Constancia del Rosario",
            "Constancio Farfán (La Pascuala)",
            "Constitución",
            "Constitución",
            "Constitución",
            "Constitución",
            "Constitución Mexicana",
            "Contepec",
            "Contla",
            "Copainalá",
            "Cópala",
            "Copala",
            "Copales",
            "Copalillo",
            "Copanatoyac",
            "Copándaro (Copándaro del Cuatro)",
            "Copándaro de Galeana",
            "Copila",
            "Copoya",
            "Coquimatlán",
            "Corcovado",
            "Córdoba",
            "Córdoba (Santa Leticia)",
            "Corerepe",
            "Coronango",
            "Coronel Tito Hernández",
            "Coroneo",
            "Corozal",
            "Corral Blanco",
            "Corral de Barrancos",
            "Corral de Piedra",
            "Corral de Piedra",
            "Corral de Piedras de Arriba",
            "Corral del Risco (Punta de Mita)",
            "Corral Falso",
            "Corral Nuevo",
            "Corralejo de Arriba",
            "Corralero",
            "Corralillos",
            "Corralillos",
            "Corriente 1ra. Sección",
            "Cortazar",
            "Corupo",
            "Cosalá",
            "Cosamaloapan",
            "Cosamaloapan de Carpio",
            "Cosautlán",
            "Cosautlán de Carvajal",
            "Coscomate del Progreso",
            "Coscomatepec de Bravo",
            "Cosío",
            "Cosolapa",
            "Cosoleacaque",
            "Cosoltepec",
            "Costa Azul",
            "Cotaxtla",
            "Cotija de la Paz",
            "Cotorina",
            "Coxcatlán",
            "Coxolico",
            "Coxquihui",
            "Coyahualco",
            "Coyame",
            "Coyame del Sotol",
            "Coyamel",
            "Coyoacán",
            "Coyolito",
            "Coyomeapan",
            "Coyote",
            "Coyotepec",
            "Coyotepec",
            "Coyotillos",
            "Coyotillos",
            "Coyotitán",
            "Coyuca de Benítez",
            "Coyuca de Catalán",
            "Coyula",
            "Coyuquilla Norte",
            "Coyutla",
            "Cozotlán Norte",
            "Cozumel",
            "Creel",
            "Crescencio Morales (San Mateo)",
            "Crisóstomos",
            "Cristeros Fraccionamiento",
            "Cristo Rey",
            "Cristóbal Colón",
            "Cristóbal Obregón",
            "Crucecita",
            "Crucero Las Pilas",
            "Cruillas",
            "Cruz Blanca",
            "Cruz Blanca",
            "Cruz de Huanacaxtle",
            "Cruz del Milagro",
            "Cruz Grande",
            "Cruz Quemada",
            "Cruz Verde",
            "Cruztón",
            "Cuacnopalan",
            "Cuacuila",
            "Cuadrilla de Dolores",
            "Cuadrilla Vieja",
            "Cuajinicuilapa",
            "Cualác",
            "Cuamiles",
            "Cuamio",
            "Cuanacaxtitlán",
            "Cuanajo",
            "Cuanalá",
            "Cuapaxtitla",
            "Cuapiaxtla",
            "Cuapiaxtla de Madero",
            "Cuapinolito (Azulillo)",
            "Cuaracurío",
            "Cuarta Brigada",
            "Cuartel la Mesa (El Asoleadero)",
            "Cuatolol",
            "Cuatro Caminos",
            "Cuatro Caminos",
            "Cuatro Ciénegas de Carranza",
            "Cuatzoquitengo",
            "Cuauchichinola",
            "Cuauhtamazaco",
            "Cuauhtamingo",
            "Cuauhtemoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc",
            "Cuauhtémoc (Campo Cinco)",
            "Cuauhtempan (San Andrés Cuauhtempan)",
            "Cuauhtenango",
            "Cuautempan",
            "Cuautepec",
            "Cuautepec de Hinojosa",
            "Cuautinchán",
            "Cuautitlán",
            "Cuautitlán",
            "Cuautitlán de García Barragán",
            "Cuautla",
            "Cuautla",
            "Cuautlacingo",
            "Cuautlancingo",
            "Cuautlapán",
            "Cuautotola",
            "Cuautotolapan (San José)",
            "Cuaxomulco",
            "Cuaxoxpan",
            "Cuaxuxpa",
            "Cuayuca de Andrade",
            "Cuazahuatl",
            "Cubiri de Portelas",
            "Cuch Holoch",
            "Cucharas",
            "Cuchicuato",
            "Cuchulumtic",
            "Cucuchucho",
            "Cucurpe",
            "Cucuyulapa Primera Sección",
            "Cuecuecuatitla",
            "Cuencamé",
            "Cuencamé de Ceniceros",
            "Cuendo",
            "Cuentepec",
            "Cuerámaro",
            "Cuernavaca",
            "Cuesta Amarilla",
            "Cuesta Blanca",
            "Cuesta Chica",
            "Cuesta de Laja",
            "Cuesta de Ovejas",
            "Cuesta de Peñones",
            "Cuetzala del Progreso",
            "Cuetzalan",
            "Cuetzalan del Progreso",
            "Cuexcomatitlán",
            "Cuexpala",
            "Cuicatlan",
            "Cuichapa",
            "Cuilapan de Guerrero",
            "Cuisillos",
            "Cuitláhuac",
            "Cuitzeo",
            "Cuitzeo del Porvenir",
            "Cuiyachapa",
            "Culiacán",
            "Culiacancito",
            "Cúlico 2da. Sección",
            "Cumbre de Barranca Honda",
            "Cumbres III",
            "Cumpas",
            "Cumpich",
            "Cumuapa 1ra. Sección",
            "Cumuato",
            "Cuncunul",
            "Cunduacán",
            "Cuonetzingo",
            "Cuparátaro",
            "Cupareo",
            "Cupilco",
            "Cupuán del Río",
            "Cuquío",
            "Curimeo",
            "Curungueo",
            "Cusihuiriachi",
            "Custique",
            "Cuto de la Esperanza",
            "Cuto del Porvenir",
            "Cutzamala de Pinzón",
            "Cuxpala",
            "Cuyoaco",
            "Cuyutlán",
            "Cuyuxtlahuaca",
            "Cuzama",
            "Dajiedi",
            "Damasco",
            "Damian Carmona",
            "Dantzibojay",
            "Dautillos",
            "Daxtha",
            "De Parral",
            "Degollado",
            "Dehesa",
            "Delegación Cuajimalpa de Morelos",
            "Delfino Victoria (Santa Fe)",
            "Delgado de Abajo",
            "Delgado de Arriba",
            "Delicias",
            "Delta",
            "Dengantzha",
            "Denjhi",
            "Derramadero Segundo (Infiernillo)",
            "Derramaderos",
            "Detiña (San Antonio Detiña)",
            "Déxtho de Victoria",
            "Diamante de Echeverría",
            "Dieciocho de Marzo",
            "Dieciocho de Marzo",
            "Dieciocho de Marzo",
            "Dieciséis de Septiembre",
            "Dieciséis de Septiembre",
            "Diez de Octubre",
            "Dios Padre",
            "Dios Padre",
            "División del Norte",
            "División del Norte",
            "Doce de Octubre",
            "Doctor Alberto Oviedo Mota",
            "Doctor Arroyo",
            "Doctor Belisario Domínguez (La Barra)",
            "Doctor Coss",
            "Doctor Coss Nuevo León",
            "Doctor Domingo Chanona",
            "Doctor González",
            "Doctor Miguel Silva (San Guillermo)",
            "Doctor Montes de Oca",
            "Doctor Mora",
            "Doctor Rodulfo Figueroa (Tierra Blanca)",
            "Doctor Samuel León Brindis",
            "Dolores",
            "Dolores",
            "Dolores Cuadrilla de Enmedio",
            "Dolores Hidalgo",
            "Dolores Hidalgo",
            "Dolores Hidalgo Cuna de la Independencia Nacional",
            "Dolores Jaltenango",
            "Domingo Arenas",
            "Don Antonio",
            "Don Diego",
            "Don Francisco",
            "Don Samuel",
            "Donaciano Ojeda",
            "Donaji",
            "Dongu",
            "Dos Arroyos",
            "Dos Bocas",
            "Dos Ceibas",
            "Dos de Abril",
            "Dos Montes",
            "Dos Ríos",
            "Dos Ríos",
            "Dotegiare",
            "Doxey",
            "Doxhicho",
            "Doxteje Barrio Primero",
            "Doxteje Centro",
            "Dr. Belisario Domínguez",
            "Duarte",
            "Dulce Grande",
            "Durango",
            "Dzan",
            "Dzemul",
            "Dzibalchén",
            "Dzibikak",
            "Dzidzantun",
            "Dzilam de Bravo",
            "Dzilam González",
            "Dzitás",
            "Dzitbalché",
            "Dzitnup",
            "Dzityá",
            "Dziuche",
            "Dzoncauich",
            "Dzonot Carretero",
            "Dzula",
            "Dzununcan",
            "Ébano",
            "Ecatepec de Morelos",
            "Ecatzingo de Hidalgo",
            "Ecuandureo",
            "Efraín A. Gutiérrez",
            "Egipto",
            "Ejidal",
            "Ejido Agua Zarca",
            "Ejido Benito Juárez",
            "Ejido Cajón Ojo de Agua Número Dos",
            "Ejido Ciénega de Mora",
            "Ejido Cinco de Mayo",
            "Ejido Cohuibampo",
            "Ejido de Coscomate del Progreso",
            "Ejido de Dolores",
            "Ejido de Guadalupe",
            "Ejido de la Finca",
            "Ejido de Mozoquilpan",
            "Ejido de San Mateo Coapexco",
            "Ejido del Tejocote",
            "Ejido del Tunal Nenaxi",
            "Ejido Doctor Alberto Oviedo Mota (El Indiviso)",
            "Ejido el Castillo",
            "Ejido El Largo",
            "Ejido el Rosario",
            "Ejido el Saucillo",
            "Ejido El Vergel",
            "Ejido General Leandro Valle",
            "Ejido Gogorrón (Ex-Hacienda de Gogorrón)",
            "Ejido Hidalgo",
            "Ejido Javier Rojo Gómez",
            "Ejido Jesús María",
            "Ejido Jiquilpan",
            "Ejido la Guayana (Rancho Seco)",
            "Ejido la Joya",
            "Ejido la Piña (Maromilla)",
            "Ejido la Pitahaya (Santo Domingo)",
            "Ejido La Quemada",
            "Ejido la Soledad",
            "Ejido Lagunitas",
            "Ejido Lázaro Cárdenas",
            "Ejido Loma de Malacota",
            "Ejido los Huastecos",
            "Ejido Mayocoba",
            "Ejido Michoacán de Ocampo",
            "Ejido Miraflores",
            "Ejido Modelo",
            "Ejido Netzahualcóyotl",
            "Ejido Nuevo",
            "Ejido Nuevo León",
            "Ejido Ohuira",
            "Ejido Ojo de Agua",
            "Ejido Palma (Ejido San Francisco)",
            "Ejido Pátzcuaro",
            "Ejido Plan de Ayala",
            "Ejido Poblado Atenquique",
            "Ejido Quintana Roo",
            "Ejido Saltillo",
            "Ejido San Cristóbal",
            "Ejido San Diego",
            "Ejido San José Xilatzén",
            "Ejido San Lorenzo Cuauhtenco",
            "Ejido Sinaloa",
            "Ejido Sonora",
            "Ejido Tabasco",
            "Ejido Toluca",
            "Ejido Tosalibampo",
            "Ejido Uno de Febrero",
            "Ejido Venustiano Carranza",
            "Ejido Vicente Guerrero",
            "Ejido Viejo",
            "Ejido Yucatán",
            "Ejido Zaragoza",
            "Ejutla",
            "Ejutla de Crespo",
            "Ekmul",
            "Ekpedz",
            "El Abra",
            "El Acebuche",
            "El Agostadero",
            "El Aguacatal",
            "El Aguacate",
            "El Aguacate",
            "El Aguajito",
            "El Águila",
            "El Águila",
            "El Águila (La Mesa)",
            "El Ahuacate",
            "El Alto",
            "El Alvareño",
            "El Amarillo",
            "El Amarradero",
            "El Ámbar",
            "El Ámbar (El Ámbar de Echeverría)",
            "El Anono",
            "El Arador",
            "El Arca",
            "El Arco",
            "El Arco Ameca",
            "El Arenal",
            "El Arenal",
            "El Arenal",
            "El Arenal",
            "El Arenal",
            "El Asoleadero",
            "El Bajío",
            "El Bajío",
            "El Bajío",
            "El Bajío",
            "El Bajío",
            "El Bajío de Plascencia",
            "El Bajío Lomas del Club",
            "El Banco del Venado",
            "El Barco",
            "El Barrancón del Tío Blas (El Barrancón)",
            "El Barrido",
            "El Barril",
            "El Barrio",
            "El Barrio de la Soledad",
            "El Bejuco",
            "El Bellote (Miguel de la Madrid)",
            "El Bingú",
            "El Blanco",
            "El Blanco",
            "El Bosque",
            "El Bosque",
            "El Botadero",
            "El Boxtha",
            "El Brasil",
            "El Brillante Fraccionamiento",
            "El Briseño",
            "El Burrión",
            "El Cabezón",
            "El Cabi",
            "El Cacalote",
            "El Cahulote de Santa Ana",
            "El Calvario",
            "El Camalote",
            "El Camarón",
            "El Cambio",
            "El Camichín",
            "El Campanario y Oradel",
            "El Canario",
            "El Cantón",
            "El Capomal",
            "El Capulín",
            "El Capulín",
            "El Capulín",
            "El Capulín",
            "El Capulín (La Nueva Pochota)",
            "El Capulín de Chávez",
            "El Capulo (La Quebradora)",
            "El Caracol",
            "El Carmen",
            "El Carmen",
            "El Carmen",
            "El Carmen",
            "El Carmen",
            "El Carmen",
            "El Carmen",
            "El Carmen (El Desierto del Carmen)",
            "El Carmen (El Limón)",
            "El Carmen Aztama",
            "El Carmen de los Elizondo",
            "El Carmen Nuevo León",
            "El Carmen Ocotepec",
            "El Carmen Xalpatlahuaya",
            "El Carretón",
            "El Carricillo",
            "El Carrizal",
            "El Carrizal",
            "El Carrizal",
            "El Carrizalito",
            "El Carrizo",
            "El Carrizo",
            "El Castillo",
            "El Castillo",
            "El Castillo",
            "El Cazadero",
            "El Cazadero",
            "El Cedazo",
            "El Cedral",
            "El Cedro",
            "El Ceñidor",
            "El Censo",
            "El Centenario",
            "El CERESO",
            "El Cerrillo Vista Hermosa",
            "El Cerrito",
            "El Cerrito",
            "El Cerrito",
            "El Cerro Jalisco",
            "El Césped",
            "El Chacal",
            "El Chalate",
            "El Chamizal",
            "El Chante",
            "El Chauz",
            "El Chaveño",
            "El Chayote",
            "El Chico",
            "El Chilar",
            "El Chinaco (El Pujido)",
            "El Chispeadero",
            "El Chivatillo",
            "El Chocolate",
            "El Chote",
            "El Cid",
            "El Ciervo",
            "El Ciruelo",
            "El Coacoyul",
            "El Coahuayote",
            "El Cocuite",
            "El Coecillo",
            "El Colegio",
            "El Colomo",
            "El Colomo",
            "El Colorado",
            "El Colorado",
            "El Colorado",
            "El Colorado",
            "El Coloso",
            "El Columpio",
            "El Conchero",
            "El Conde",
            "El Conejo",
            "El Conejo",
            "El Congo",
            "El Consuelo",
            "El Consuelo Ulapa",
            "El Contadero de Matamoros",
            "El Control",
            "El Copal",
            "El Copalar",
            "El Copalillo",
            "El Copetillo",
            "El Cóporo",
            "El Corcovado",
            "El Corte",
            "El Corte",
            "El Cortés",
            "El Cortijo",
            "El Cortijo",
            "El Coto",
            "El Coyote",
            "El Coyotillo",
            "El Coyul",
            "El Crucero",
            "El Crucero de Arandas",
            "El Crucero de Santa María",
            "El Cuatro",
            "El Cuenqueño",
            "El Cuervero (Cuerveros)",
            "El Cuije",
            "El Cuitzillo Grande",
            "El Curtidor",
            "El Cuyo",
            "El Derramadero",
            "El Diamante (La Retranca)",
            "El Dieciséis",
            "El Diez",
            "El Diez",
            "El Dique",
            "El Divisador",
            "El Dorado",
            "El Dorado",
            "El Durazno",
            "El Durazno",
            "El Durazno",
            "El Edén",
            "El Embarcadero",
            "El Encanto",
            "El Encanto del Cerril",
            "El Esclavo",
            "El Escobillo",
            "El Escondido",
            "El Escoplo",
            "El Escribano",
            "El Espejo",
            "El Espinal",
            "El Espinal",
            "El Espinal",
            "El Espinal",
            "El Espinalillo",
            "El Estudiante",
            "El Fortín",
            "El Fraile",
            "El Fraile",
            "El Fresno",
            "El Fresno",
            "El Fuerte",
            "El Fuerte",
            "El Fuerte",
            "El Fuerte",
            "El Fuerte de la Unión",
            "El Galaneño",
            "El Gallinero",
            "El Gallo",
            "El Gato",
            "El Grullo Municipality",
            "El Guaco",
            "El Guayabo",
            "El Habal",
            "El Habillal",
            "El Hatito",
            "El Hielo",
            "El Higo",
            "El Higueral",
            "El Hollejo",
            "El Huajote",
            "El Huarache (El Guarache)",
            "El Huaxtho",
            "El Huérfano",
            "El Huexco",
            "El Huidero",
            "El Huiroche",
            "El Huitusi",
            "El Huixmí",
            "El Huizache",
            "El Humo",
            "El Jabalí",
            "El Jabalí",
            "El Jacal",
            "El Jagüey",
            "El Jaguey",
            "El Jagüey",
            "El Jaral",
            "El Jaral",
            "El Jaralillo",
            "El Jaralito",
            "El Jaralito",
            "El Jardín",
            "El Jardín",
            "El Jazmín",
            "El Jazmín",
            "El Jiadi",
            "El Jicaral",
            "El Jicote",
            "El Jobo",
            "El Jobo",
            "El Jomate",
            "El Juile",
            "El Lampotal",
            "El Laurel",
            "El Laurel",
            "El Lazo",
            "El Leal",
            "El Lencero",
            "El Lequeitio",
            "El Letrero",
            "El Limar",
            "El Limón",
            "El Limón",
            "El Limón",
            "El Limón de los Ramos",
            "El Limonar",
            "El Lindero",
            "El Llanito",
            "El Llano",
            "El Llano",
            "El Llano",
            "El Llano de Barajas",
            "El Llano de los Toros",
            "El Llano de Plascencia",
            "El Llano del Compromiso",
            "El Llano Santa María",
            "El Lobo",
            "El Lobo",
            "El Lopeño",
            "El Loreto Occidental",
            "El Lucero",
            "El Macuchi",
            "El Maestranzo",
            "El Maguey",
            "El Maguey",
            "El Maguey",
            "El Magueyal",
            "El Maluco",
            "El Manantial",
            "El Mango",
            "El Mante",
            "El Mastranzo",
            "El Mayoral de Abajo",
            "El Medineño",
            "El Mentidero",
            "El Mesón",
            "El Mesoncito",
            "El Metlapil",
            "El Mezquite Grande",
            "El Mezquitillo",
            "El Milagro",
            "El Mirador",
            "El Mirador",
            "El Mirador",
            "El Mirador",
            "El Mirador",
            "El Mogote",
            "El Molino",
            "El Molino",
            "El Molino",
            "El Molino",
            "El Molino",
            "El Molino",
            "El Mollejon",
            "El Moral",
            "El Moral",
            "El Moreno (San Miguel Moreno)",
            "El Morro",
            "El Mosquito Linares",
            "El Muey",
            "El Nabo",
            "El Nacimiento",
            "El Nacimiento",
            "El Nacimiento de Abajo",
            "El Naranjillo",
            "El Naranjito",
            "El Naranjito",
            "El Naranjo",
            "El Naranjo",
            "El Naranjo",
            "El Naranjo",
            "El Nayar",
            "El Nigromante",
            "El Nigromante",
            "El Nilo",
            "El Niño",
            "El Nio",
            "El Nith",
            "El Nogalito",
            "El Nopal",
            "El Nuevo Poblado",
            "El Obraje",
            "El Obraje",
            "El Ocotal",
            "El Ocote",
            "El Ocotillo",
            "El Ocotito",
            "El Ojo de Agua",
            "El Ojuelo",
            "El Olvera",
            "El Olvido",
            "El Organal",
            "El Oro",
            "El Oro de Hidalgo",
            "El Pacayal",
            "El Padre Juan",
            "El Palacio",
            "El Palmar",
            "El Palmar",
            "El Palmar",
            "El Palmar (San Gabriel)",
            "El Palmar de San Antonio",
            "El Palmar Grande",
            "El Palmarcito",
            "El Palmital",
            "El Palmito",
            "El Panorama",
            "El Pantano",
            "El Papayo",
            "El Paracho",
            "El Paraíso",
            "El Paraíso",
            "El Paraíso",
            "El Paraíso",
            "El Paraíso (La Charca)",
            "El Paredón",
            "El Paredoncito",
            "El Parral",
            "El Paso Real",
            "El Pastor",
            "El Pedregal",
            "El Pedregal de Guadalupe Hidalgo",
            "El Peñasco",
            "El Pericón",
            "El Perú",
            "El Pescadero",
            "El Picacho",
            "El Pilar",
            "El Pino",
            "El Pino",
            "El Pinto",
            "El Pintor",
            "El Pitahayo",
            "El Pital",
            "El Plan",
            "El Plan",
            "El Platanal",
            "El Platanar",
            "El Plateado de Joaquín Amaro",
            "El Pochotal",
            "El Pochote",
            "El Polvorín",
            "El Popote",
            "El Portal",
            "El Portezuelo",
            "El Porvenir",
            "El Porvenir",
            "El Porvenir",
            "El Porvenir",
            "El Porvenir",
            "El Porvenir (El Porvenir de Arriba)",
            "El Porvenir Agrarista",
            "El Porvenir de Velasco Suárez",
            "El Porvenir I",
            "El Potrerillo (Potrerillo del Rincón)",
            "El Potrero",
            "El Potrero",
            "El Potrero de San Diego",
            "El Potrero de Sataya",
            "El Pozo",
            "El Pozo",
            "El Pozo Santo",
            "El Pozole",
            "El Progreso",
            "El Progreso",
            "El Progreso",
            "El Progreso",
            "El Progreso Hidalgo",
            "El Pueblito",
            "El Pueblito",
            "El Pueblito",
            "El Pueblito (Garbanzal)",
            "El Puente",
            "El Puerto",
            "El Puerto",
            "El Puerto de Amolero",
            "El Puerto Magú",
            "El Puesto",
            "El Puesto",
            "El Quelite",
            "El Quemado",
            "El Quince",
            "El Ramal (Porvenir)",
            "El Ranchito",
            "El Ranchito Número Uno",
            "El Rayo",
            "El Rayo",
            "El Realito",
            "El Realito",
            "El Rebalse",
            "El Rebalsito de Apazulco",
            "El Recodo",
            "El Recodo de San José Axalco",
            "El Recreo",
            "El Recuerdo de Ancón (Xoconoxtle de Arriba)",
            "El Refugio",
            "El Refugio",
            "El Refugio",
            "El Refugio",
            "El Refugio",
            "El Refugio",
            "El Refugio de los Altos",
            "El Refugio de los Sauces",
            "El Refugio de Peñuelas",
            "El Refugio de Providencia (Providencia)",
            "El Refugio de Vázquez",
            "El Refugio Jalisco",
            "El Refugio Suchitlán",
            "El Refugio y el Reparo",
            "El Remolino",
            "El Reparo",
            "El Retiro",
            "El Rincón",
            "El Rincón",
            "El Rincón",
            "El Rincón (Santa Cruz del Rincón)",
            "El Rincón Citlaltépetl",
            "El Rincón de la Candelaria",
            "El Rincón de los Perales",
            "El Rincón de San Felipe (Tercera Manzana San Felipe)",
            "El Roble",
            "El Roble",
            "El Rodeo",
            "El Rodeo",
            "El Rodeo",
            "El Rodeo Viejo",
            "El Romereño",
            "El Rosal",
            "El Rosario",
            "El Rosario",
            "El Rosario",
            "El Rosario",
            "El Rosario",
            "El Rosario",
            "El Rosario",
            "El Rosario",
            "El Rosario de Arriba",
            "El Rucio",
            "El Rucio",
            "El Sabino",
            "El Sabino",
            "El Sabino",
            "El Sacrificio",
            "El Sacrificio",
            "El Sahuaral",
            "El Saladito",
            "El Saladito",
            "El Salero",
            "El Salero",
            "El Salitre",
            "El Salitre",
            "El Salitre",
            "El Salitre",
            "El Salitre",
            "El Salto",
            "El Salto",
            "El Salto",
            "El Salto",
            "El Salto de Espejo",
            "El Salto de Eyipantla",
            "El Salto de las Peñas",
            "El Salto de San Antonio",
            "El Salto Municipality",
            "El Salvador",
            "El Salvador",
            "El Salvador (Ranchito San José del Carmen)",
            "El Salvial",
            "El Saneal",
            "El Saucillo",
            "El Saucillo",
            "El Saucillo (Fraccionamiento)",
            "El Saucillo de los Pérez",
            "El Saucito",
            "El Saucito (El Horno)",
            "El Sauz",
            "El Saúz",
            "El Sauz (El Sauz de Villaseñor)",
            "El Sauz de Abajo",
            "El Sauz de Ibarra",
            "El Sauzal de Rodríguez",
            "El Seminario",
            "El Sibal",
            "El Silencio",
            "El Sitio",
            "El Sitio",
            "El Siviral (Jigica)",
            "El Sopial",
            "El Soyate",
            "El Súchil",
            "El Susto",
            "El Tablero",
            "El Tamarindo",
            "El Taray",
            "El Tarengo Nuevo",
            "El Tecolote",
            "El Tecolote",
            "El Tecuán",
            "El Tejar",
            "El Tejocote",
            "El Tejocote",
            "El Tejocote (El Domingo)",
            "El Tempisque",
            "El Tepame",
            "El Tepec",
            "El Tepetatal",
            "El Tepetate",
            "El Tepetate",
            "El Tepeyac",
            "El Tephé",
            "El Tepozán",
            "El Tequesquite",
            "El Tequesquite",
            "El Terrerito de Sevilla",
            "El Terrero",
            "El Terrero",
            "El Terrero",
            "El Terrero",
            "El Terrero",
            "El Ticui",
            "El Tigre",
            "El Tigre (Segunda Manzana de Crescencio Morales)",
            "El Tintal",
            "El Tizate",
            "El Tobarito",
            "El Torque",
            "El Torreón",
            "El Tortuguero",
            "El Trapiche",
            "El Triunfo",
            "El Triunfo",
            "El Triunfo",
            "El Triunfo 1ra. Sección (Cardona)",
            "El Triunfo de las Tres Maravillas",
            "El Tuito",
            "El Tular",
            "El Tule",
            "El Tule",
            "El Tule",
            "El Tule",
            "El Tulillo",
            "El Tulín",
            "El Tumbo",
            "El Tunal",
            "El Tzay",
            "El Uvalano",
            "El Vado de San Pedro",
            "El Varal",
            "El Varal (San Sebastián Número Uno)",
            "El Vegil",
            "El Veladero",
            "El Venado",
            "El Venado",
            "El Verde",
            "El Verde",
            "El Vergel",
            "El Vergel",
            "El Vergel",
            "El Vicarlo",
            "El Vigía",
            "El Volador",
            "El Walamo",
            "El Zancudo",
            "El Zapatero",
            "El Zapotal",
            "El Zapote",
            "El Zapote",
            "El Zapote Bravo",
            "El Zapote de Chávez",
            "El Zapotillo",
            "El Zapotillo",
            "Eldorado",
            "Elota",
            "Elotepec",
            "Eloxochitlán",
            "Eloxochitlán",
            "Eloxochitlán de Flores Magón",
            "Emancipación Quetzalapa",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata",
            "Emiliano Zapata (Casahuates)",
            "Emiliano Zapata (San José Bata)",
            "Emiliano Zapata (Santo Domingo)",
            "Emiliano Zapata Jalisco",
            "Emilio Carranza",
            "Emilio Carranza (Santa Cruz)",
            "Emilio Portes Gil",
            "Emilio Portes Gil",
            "Empalme",
            "Empalme Escobedo",
            "Empaque Tarriba",
            "Encarnación de Díaz",
            "Encinal Colorado",
            "Encrucijada 3ra. Sección (Las Calzadas)",
            "Enramadas",
            "Enrique Estrada",
            "Ensenada",
            "Entabladero",
            "Enthavi",
            "Entronque de Matehuala (El Huizache)",
            "Entronque Laredo-Salinas Victoria",
            "Epazoyucan",
            "Epeche Chico",
            "Epeche Grande",
            "Epigmenio González",
            "Epitacio Huerta",
            "Ermita de Guadalupe",
            "Erongarícuaro",
            "Escaleras",
            "Escalerilla Lagunas",
            "Escalerillas",
            "Escamillas",
            "Escárcega",
            "Escolasticas",
            "Escolín de Olarte",
            "Escuadrón Doscientos Uno",
            "Escuinapa",
            "Escuintla",
            "Esfuerzos Unidos",
            "Esmeralda",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Espinal",
            "Espita",
            "Esqueda",
            "Estacion Bamoa",
            "Estación Camacho",
            "Estación Capomas",
            "Estación Chimaneco",
            "Estación Coahuila",
            "Estación Conchos",
            "Estación Consuelo",
            "Estación Corral",
            "Estación Corralejo",
            "Estación de Apulco",
            "Estación de San Francisco",
            "Estación Dobladero",
            "Estación Huehuetán",
            "Estación Joaquín",
            "Estación Juanita",
            "Estación la Piedad",
            "Estación Llano",
            "Estación Mogoñé",
            "Estación Obispo",
            "Estación Pénjamo",
            "Estación Pescaderos",
            "Estación Queréndaro",
            "Estación Rosales",
            "Estación San José",
            "Estación San Manuel",
            "Estación Santa Engracia",
            "Estación Tamuín",
            "Estación Tuzantán",
            "Estación Zamora",
            "Estados Unidos",
            "Estancia de Ánimas",
            "Estancia de Guadalupe",
            "Estancia de San Nicolás",
            "Estancia del Llano",
            "Estancia Vieja de Arriba",
            "Estanzuela",
            "Estanzuela",
            "Estanzuela",
            "Estanzuela de Romero",
            "Estapilla",
            "Estero de Milpas",
            "Estero del Ídolo",
            "Esteros",
            "Estipa",
            "Estrada",
            "Estrella de Belén",
            "Etchojoa",
            "Etchoropo",
            "Etúcuaro",
            "Etúcuaro",
            "Etzatlán Municipality",
            "Euan",
            "Eureka de Media Luna (Eureka)",
            "Eureka y Belén",
            "Ex-Hacienda de Guadalupe",
            "Ex-Hacienda de Guadalupe",
            "Ex-hacienda de Xalpa",
            "Ex-Hacienda del Copal",
            "Ex-Hacienda el Hospital",
            "Ex-hacienda la Concepción",
            "Ex-Hacienda Santa Inés",
            "Ex-Rancho San Dimas",
            "Ex-Viñedos Guadalupe",
            "Excola",
            "Ezequiel Montes",
            "Fábrica Concepción",
            "Fábrica de Melchor",
            "Faja de Oro",
            "Felipe Angeles",
            "Felipe Carrillo Puerto",
            "Felipe Carrillo Puerto",
            "Felipe Carrillo Puerto",
            "Felipe Carrillo Puerto",
            "Felipe Carrillo Puerto",
            "Felipe Carrillo Puerto",
            "Felipe Neri (Cuatepec)",
            "Fermín Rabadán Cervantes",
            "Ferrería de Tula",
            "Filomeno Mata",
            "Flor Azul",
            "Flor Batavia",
            "Flor de Cacao",
            "Florencia",
            "Florencio Villarreal",
            "Fontezuelas",
            "Forjadores de Pachuca",
            "Fortín de las Flores",
            "Fracción Milpillas",
            "Fracción San Roque (El Prieto)",
            "Fraccionamiento Arboledas San Ramón",
            "Fraccionamiento Carlos Salinas de Gortari",
            "Fraccionamiento Ciudad Olmeca",
            "Fraccionamiento Ciudad Yagul",
            "Fraccionamiento Colinas del Sol",
            "Fraccionamiento Colinas Universidad",
            "Fraccionamiento Cosmópolis Octavo Sector",
            "Fraccionamiento Costa Dorada",
            "Fraccionamiento del Magisterio Tulancinguense",
            "Fraccionamiento del Valle",
            "Fraccionamiento del Valle",
            "Fraccionamiento Ex-Hacienda Catano",
            "Fraccionamiento Ex-Hacienda el Refugio",
            "Fraccionamiento Galaxia Tarímbaro",
            "Fraccionamiento Geovillas los Pinos",
            "Fraccionamiento Hacienda del Bosque",
            "Fraccionamiento la Florida",
            "Fraccionamiento la Mezquitera",
            "Fraccionamiento la Noria",
            "Fraccionamiento la Trinidad",
            "Fraccionamiento la Virgen",
            "Fraccionamiento las Fuentes",
            "Fraccionamiento las Liebres",
            "Fraccionamiento Laureles Eréndira",
            "Fraccionamiento Lomas de Ahuatlán",
            "Fraccionamiento los Álamos",
            "Fraccionamiento los Ángeles",
            "Fraccionamiento los Prados",
            "Fraccionamiento Metrópolis II",
            "Fraccionamiento Misión de San Javier",
            "Fraccionamiento Misión del Valle",
            "Fraccionamiento Monte Olivo",
            "Fraccionamiento Ocuiltzapotlán Dos",
            "Fraccionamiento Paraíso Real",
            "Fraccionamiento Paseo de las Torres",
            "Fraccionamiento Praderas de la Venta",
            "Fraccionamiento Privadas del Sol",
            "Fraccionamiento Real de San Pablo",
            "Fraccionamiento Real del Valle",
            "Fraccionamiento Real Palmas",
            "Fraccionamiento Riberas de San Jerónimo",
            "Fraccionamiento Rinconada del Valle",
            "Fraccionamiento San Miguel",
            "Fraccionamiento San Miguel",
            "Fraccionamiento Santa Cruz",
            "Fraccionamiento Universo",
            "Fraccionamiento Valle Dorado",
            "Fraccionamiento Villa Jardín",
            "Fraccionamiento Villas de Guanajuato",
            "Fraccionamiento Villas de la Llave",
            "Fraccionamiento y Club de Golf los Encinos",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco I. Madero",
            "Francisco Ibarra Ramos",
            "Francisco Ignacio Madero",
            "Francisco Javier Mina",
            "Francisco León",
            "Francisco Primo de Verdad",
            "Francisco Rueda",
            "Francisco Sarabia",
            "Francisco Sarabia",
            "Francisco Sarabia (Cerrito Pelón)",
            "Francisco Serrato (San Bartolo)",
            "Francisco Villa",
            "Francisco Villa",
            "Francisco Villa",
            "Francisco Villa",
            "Francisco Villa",
            "Francisco Z. Mena",
            "Francisco Zarco",
            "Franco",
            "Franco Tavera",
            "Fredepo",
            "Fresnillo",
            "Fresno Nichi",
            "Frías",
            "Frontera",
            "Frontera",
            "Frontera Corozal",
            "Frontera Hidalgo",
            "Fronteras",
            "Fuentes del Valle",
            "Fuentezuelas",
            "Gabino Barreda",
            "Gabriel Esquinca",
            "Gabriel Hernández (Mancinas)",
            "Gabriel Leyva Solano (Zapotillo Dos)",
            "Gabriel Leyva Velázquez",
            "Gachupines",
            "Galaxia Bonito Jalisco",
            "Galaxia la Calera",
            "Galaxia la Noria",
            "Galaxias Toluca",
            "Galeana",
            "Galeana",
            "Galeana",
            "Galeana",
            "Galecio Narcia",
            "Galerade Panales",
            "Galeras",
            "Galindo",
            "Gambara",
            "Gambino",
            "Gandhó",
            "Ganzda",
            "García",
            "Gargantillo",
            "Garza García",
            "Garza González",
            "Gasca",
            "Gavia de Rionda",
            "Gaviotas Sur (El Cedral)",
            "Genaro Estrada",
            "General Alatriste (San Joaquín)",
            "General Andrés Figueroa",
            "General Ángel Flores (La Palma)",
            "General Bravo",
            "General Calixto Contreras",
            "General Canuto A. Neri",
            "General Cepeda",
            "General Enrique Estrada",
            "General Escobedo",
            "General Felipe Ángeles",
            "General Felipe Ángeles (Los Ángeles)",
            "General Francisco Villa",
            "General Gabriel Leyva",
            "General Heliodoro Castillo",
            "General Ignacio Zaragoza",
            "General Juan José Baz (San José del Corral)",
            "General Juan José Ríos",
            "General Lauro G. Caloca (El Rascón)",
            "General Lázaro Cárdenas",
            "General Lázaro Cárdenas (El Colorado)",
            "General Luis Felipe Domínguez Suárez",
            "General Miguel Alemán",
            "General Pánfilo Natera",
            "General Pascual Fentes",
            "General Pedro María Anaya",
            "General Tapia",
            "General Terán Nuevo León",
            "General Treviño",
            "General Zuazua",
            "Geovillas Laureles del Campanario",
            "Getsemaní",
            "Gilberto Camacho",
            "Gildardo Magaña (Los Ángeles)",
            "Gilita",
            "Gobernador Cruz",
            "Gobernadores",
            "Golfo de Santa Clara",
            "Gómez Farías",
            "Gómez Farías Municipality",
            "Gómez Palacio",
            "Gómez Poniente",
            "Gonzales Ortega",
            "González",
            "González Ortega",
            "Goros Número Dos",
            "Graciano Bortoni",
            "Graciano Sánchez",
            "Grajales",
            "Gran Morelos",
            "Granada",
            "Granadas",
            "Granadillas",
            "Granados",
            "Granjas Ampliación Santa Rosa",
            "Granjenal",
            "Gregorio Méndez",
            "Guacamayas",
            "Guachinango",
            "Guachochi",
            "Guadalajara",
            "Guadalcázar",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe Buenavista",
            "Guadalupe Cachi",
            "Guadalupe Calderón",
            "Guadalupe Coté",
            "Guadalupe de Atlas",
            "Guadalupe de Lerma",
            "Guadalupe de Paso Blanco",
            "Guadalupe de Rivera",
            "Guadalupe de Tambula",
            "Guadalupe del Monte",
            "Guadalupe Enríquez",
            "Guadalupe Etla",
            "Guadalupe Grijalva",
            "Guadalupe Hidalgo",
            "Guadalupe Hidalgo",
            "Guadalupe Libertad",
            "Guadalupe Minerva",
            "Guadalupe Morelos",
            "Guadalupe Santa Ana",
            "Guadalupe Sarabia",
            "Guadalupe Septién",
            "Guadalupe Texcalac",
            "Guadalupe Tlachco",
            "Guadalupe Totoltepec",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe Victoria",
            "Guadalupe y Calvo",
            "Guadalupe Zaragoza",
            "Guamúchil",
            "Guamúchil",
            "Guamuchilillo",
            "Guanaceví",
            "Guanajuatillo",
            "Guanajuato",
            "Guándaro",
            "Guaquitepec",
            "Guasave",
            "Guasavito",
            "Guásimas (De Belem)",
            "Guatacalca",
            "Guatimapé",
            "Guayabitos",
            "Guayalejo",
            "Guayameo",
            "Guaymitas",
            "Guaytalpa",
            "Guazapares",
            "Guemes",
            "Güémez",
            "Guerrero",
            "Guerrero",
            "Guerrero",
            "Guerrero Negro",
            "Guillermo Prieto",
            "Guillermo Zúñiga",
            "Guixé",
            "Gunyo Poniente (San José Gunyo)",
            "Gustavo A. Madero",
            "Gustavo Adolfo Madero",
            "Gustavo Díaz Ordaz",
            "Gustavo Díaz Ordaz",
            "Gutiérrez Zamora",
            "Habanero 1ra. Sección (Venustiano Carranza)",
            "Hacienda Arriba",
            "Hacienda Blanca",
            "Hacienda de Cabañas",
            "Hacienda de Enmedio",
            "Hacienda de la Bolsa",
            "Hacienda de la Labor",
            "Hacienda de Márquez",
            "Hacienda la Cruz [Fraccionamiento]",
            "Hacienda los Fresnos",
            "Hacienda Nueva",
            "Hacienda San Juan",
            "Hacienda San Pedro",
            "Hacienda Santa Fe",
            "Hacienda Sotavento",
            "Hacienda Tecate",
            "Hacienda Toribio",
            "Hacienda Vieja",
            "Hacienda Vieja del Castillo",
            "Haciendas la Herradura",
            "Halachó",
            "Hampolol",
            "Haro",
            "Hecelchakán",
            "Héctor Caballero",
            "Helio García Alfaro",
            "Hércules",
            "Heriberto Valdez Romero (El Guayabo)",
            "Hermanos Cedillo",
            "Hermenegildo Galeana",
            "Hermenegildo Galeana",
            "Hermenegildo Galeana 2da. Sección",
            "Hermenegildo J. Aldana",
            "Hermosillo",
            "Hermosillo",
            "Hernández",
            "Héroes de Chapultepec",
            "Héroes de Chapultepec (Rodeo de San Antonio)",
            "Heroica Caborca",
            "Heroica Ciudad de Ejutla de Crespo",
            "Heroica Ciudad de Juchitán de Zaragoza",
            "Heroica Ciudad de Tlaxiaco",
            "Heroica Guaymas",
            "Heroica Matamoros",
            "Heróica Zitácuaro",
            "Hidalgo",
            "Hidalgo",
            "Hidalgo",
            "Hidalgo",
            "Hidalgo",
            "Hidalgo",
            "Hidalgo Amajac",
            "Hidalgo del Parral",
            "Hidalgo Joshil",
            "Hidalgotitlán",
            "Hierba de Matancillas",
            "Higinio Olivo",
            "Higuera Blanca",
            "Higuera de los Vega",
            "Higuera de Zaragoza",
            "Higueras de Abuya",
            "Higueras de los Natoches",
            "Higueras Nuevo León",
            "Higueras San Miguel",
            "Higuerillas",
            "Higuerillas",
            "Higuerón",
            "Hocaba",
            "Hoctun",
            "Holca",
            "Homun",
            "Honey",
            "Hool",
            "Hopelchén",
            "Horcasitas",
            "Hormiguero",
            "Hornitos",
            "Hostotipaquillo",
            "Hoya de Cintora (La Hoya de Arriba)",
            "Huacapa y Amestoy",
            "Huachichil",
            "Huachinantla",
            "Huachinera",
            "Huahuaxtla",
            "Huajacatlán",
            "Huajicori",
            "Huajimic",
            "Huajintepec",
            "Huajintlán",
            "Huajoyuca",
            "Huajúmbaro",
            "Hualahuises",
            "Hualcaltzinco",
            "Hualula",
            "Huamantla",
            "Huamuchapa",
            "Huamuxtitlán",
            "Huancito",
            "Huandacareo",
            "Huanguitío",
            "Huanímaro",
            "Huaniqueo de Morales",
            "Huapacal 1ra. Sección",
            "Huapacal 2da. Sección (Punta Brava)",
            "Huapinol",
            "Huaquechula",
            "Huásabas",
            "Huasca de Ocampo",
            "Huascato",
            "Huatabampo",
            "Huatecalco",
            "Huatlatlauca",
            "Huatusco",
            "Huauchinango",
            "Huautepec",
            "Huautla",
            "Huautla de Jiménez",
            "Huaxcaleca",
            "Huaxtla",
            "Huay Max",
            "Huay-Pix",
            "Huayacanes",
            "Huayacocotla",
            "Huazantlán del Río",
            "Huazulco",
            "Huazuntlán",
            "Huecahuasco",
            "Huehuetán",
            "Huehuetán",
            "Huehuetla",
            "Huehuetla",
            "Huehuetlan el Chico",
            "Huehuetlán el Grande",
            "Huehuetoca",
            "Huejonapan",
            "Huejotitán",
            "Huejotitán",
            "Huejotitlán",
            "Huejotzingo",
            "Huejúcar",
            "Huejuquilla El Alto Municipality",
            "Huejutla de Reyes",
            "Huemetla",
            "Huépac",
            "Huepaco",
            "Huepalcalco (San Miguel)",
            "Huertas Estación",
            "Huertas Familiares San Pedro",
            "Huetamo de Núñez",
            "Huexotitla",
            "Hueyapa",
            "Hueyapan",
            "Hueyapan",
            "Hueyapan de Ocampo",
            "Hueycantenango",
            "Hueyitlalpan",
            "Hueyotlipan",
            "Hueyotlipan",
            "Hueypoxtla",
            "Hueytamalco",
            "Hueytepec",
            "Hueytlalpan",
            "Huhi",
            "Huichapan",
            "Huichihuayan",
            "Huilacapixtla",
            "Huilango",
            "Huiloapan",
            "Huiloapan",
            "Huiloapan de Cuauhtémoc",
            "Huimanguillo",
            "Huimilpan",
            "Huiramba",
            "Huisichi",
            "Huitchila",
            "Huitel",
            "Huitepec",
            "Huitiupán",
            "Huitrón",
            "Huitzatarito",
            "Huitzila",
            "Huitzila",
            "Huitzilac",
            "Huitzilac",
            "Huitzilan",
            "Huitzililla",
            "Huitziltepec",
            "Huitziltepec",
            "Huitzitzilingo",
            "Huitzmaloc",
            "Huitzoltepec",
            "Huitzometl",
            "Huitzuco de los Figueroa",
            "Huixcolotla",
            "Huixcolotla",
            "Huixquilucan",
            "Huixquilucan de Degollado",
            "Huixtepec",
            "Huixtla",
            "Huizachal",
            "Huizaches",
            "Hunucmá",
            "Hunuku",
            "Ibarra",
            "Ibarrilla",
            "Ibarrilla",
            "Ichán",
            "Icxotitla",
            "Ignacio Allende",
            "Ignacio Allende",
            "Ignacio Allende",
            "Ignacio Allende",
            "Ignacio Allende (Concepción)",
            "Ignacio Bastida (Santa Catarina Tlayca)",
            "Ignacio de la Llave",
            "Ignacio Gutiérrez Gómez",
            "Ignacio López Rayón",
            "Ignacio Manuel Altamirano",
            "Ignacio Pérez",
            "Ignacio Ramírez",
            "Ignacio Ramírez",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza",
            "Ignacio Zaragoza (El Morro)",
            "Ignacio Zaragoza 1ra. Sección",
            "Iguala de la Independencia",
            "Igualapa",
            "Ihuatzio",
            "Ilamatlán",
            "Iliatenco",
            "Illescas",
            "Imuris",
            "Indaparapeo",
            "Indé",
            "Independencia",
            "Independencia",
            "Independencia",
            "Infiernillo (Morelos de Infiernillo)",
            "Ipazoltic",
            "IPROVIPE I",
            "Iquinuapa",
            "Irámuco",
            "Irapeo",
            "Irapuato",
            "Iratzio",
            "Irimbo",
            "Irolo",
            "Isaac Arriaga (Santa Ana Mancera)",
            "Isla",
            "Isla Aguada",
            "Isla del Bosque",
            "Isla Holbox",
            "Isla Mujeres",
            "Isla Soyaltepec",
            "Islas Agrarias Grupo A",
            "Islas Agrarias Grupo B",
            "Islita",
            "Ismael Flores",
            "Istaro",
            "Iturbide",
            "Iturbide",
            "Iturbide Nuevo León",
            "Itzamatitlán",
            "Itzincab Palomeque",
            "Itzoteno",
            "Ixcamilpa",
            "Ixcamilpa de Guerrero",
            "Ixcapantla",
            "Ixcapuzalco",
            "Ixcaquixtla",
            "Ixcateopan",
            "Ixcateopan de Cuauhtémoc",
            "Ixcatepec",
            "Ixcatla",
            "Ixcatla",
            "Ixcatlán",
            "Ixcuinquitlapilco",
            "Ixhuacán de los Reyes",
            "Ixhuapan",
            "Ixhuatán",
            "Ixhuatlán de Madero",
            "Ixhuatlán del Café",
            "Ixhuatlán del Sureste",
            "Ixhuatlancillo",
            "Ixil",
            "Ixmiquilpan",
            "Ixpantepec Nieves",
            "Ixpila",
            "Ixtacamaxtitlán",
            "Ixtacapa el Chico",
            "Ixtacomitán",
            "Ixtaczoquitlán",
            "Ixtahuiata (La Legua)",
            "Ixtapa",
            "Ixtapa",
            "Ixtapa",
            "Ixtapa-Zihuatanejo",
            "Ixtapaluca",
            "Ixtapan de la Concepción",
            "Ixtapan de la Sal",
            "Ixtapan del Oro",
            "Ixtapangajoya",
            "Ixtepec",
            "Ixtepec",
            "Ixticpan",
            "Ixtlahuaca",
            "Ixtlahuaca",
            "Ixtlahuaca Barrio",
            "Ixtlahuaca de Cuauhtémoc",
            "Ixtlahuaca de Rayón",
            "Ixtlahuacán",
            "Ixtlahuacán de los Membrillos",
            "Ixtlahuacán del Río",
            "Ixtlán de Juárez",
            "Ixtlán de los Hervores",
            "Ixtlán del Río",
            "Ixtlilco el Chico",
            "Ixtolco de Morelos",
            "Izamal",
            "Izolta",
            "Izotepec",
            "Iztacalco",
            "Iztacapa",
            "Iztapalapa",
            "Izúcar de Matamoros",
            "J. Isabel Flores",
            "J. Jesús Díaz Tzirio",
            "J. Jesús González Ortega (San Mateo)",
            "Jaboncillo",
            "Jacala",
            "Jacarandas",
            "Jacona",
            "Jacona de Plancarte",
            "Jagüey Blanco",
            "Jagüey de Téllez (Estación Téllez)",
            "Jalacingo",
            "Jalapa",
            "Jalapa",
            "Jalapa",
            "Jalapilla",
            "Jalapita",
            "Jalcocotán",
            "Jalcomulco",
            "Jaleaca de Catalán",
            "Jalipa",
            "Jalisco",
            "Jalmolonga (La Hacienda)",
            "Jalostotitlan",
            "Jalpa",
            "Jalpa",
            "Jalpa de los Baños",
            "Jalpa de Méndez",
            "Jalpan",
            "Jalpan",
            "Jalpilla",
            "Jalpilla",
            "Jaltenango de la Paz",
            "Jaltenco",
            "Jaltepec",
            "Jaltepec",
            "Jaltepec de Candayoc",
            "Jaltepetongo",
            "Jaltiche de Arriba",
            "Jáltipan de Morelos",
            "Jaltocan",
            "Jaltocan",
            "Jaltomate",
            "Jaluco",
            "Jalupa",
            "Jamaica",
            "Jamaica",
            "Jamapa",
            "Jamay",
            "Jamiltepec",
            "Janambo",
            "Janamuato",
            "Janitzio",
            "Janos",
            "Jantetelco",
            "Jarácuaro",
            "Jaral del Progreso",
            "Jardines de la Silla",
            "Jardines de la Silla (Jardines)",
            "Jardines de San Sebastián",
            "Jardines del Edén",
            "Jardínes del Grijalva",
            "Jarillas",
            "Jaripeo",
            "Jaritas",
            "Jauja",
            "Jaula de Abajo",
            "Jaumave",
            "Jauregui",
            "Jáuregui",
            "Javier Rojo Gómez",
            "Javier Rojo Gómez",
            "Javier Rojo Gómez",
            "Jecopaco",
            "Jerécuaro",
            "Jerez de García Salinas",
            "Jericó",
            "Jerusalén",
            "Jesús Carranza",
            "Jesús Carranza (Rancho de Jesús)",
            "Jesús del Monte",
            "Jesús del Monte",
            "Jesús del Monte",
            "Jesús Gómez Portugal",
            "Jesús Gómez Portugal (Margaritas)",
            "Jesús Huitznahuac",
            "Jesús María",
            "Jesús María",
            "Jesús María",
            "Jesús María",
            "Jesús María",
            "Jesús María",
            "Jesús María",
            "Jesús María Aguirre Galeana",
            "Jesús María de Berrones",
            "Jesús María del Terrero",
            "Jesús María Garza",
            "Jesús María Nayarit",
            "Jesús Nazareno",
            "Jesús Tepactepec",
            "Jicaltepec Autopan",
            "Jicaltepec Cuexcontitlán",
            "Jícamas",
            "Jicarero",
            "Jicayán de Tovar",
            "Jicolapa",
            "Jilotepec",
            "Jilotepec de Molina Enríquez",
            "Jilotlán de los Dolores",
            "Jilotzingo",
            "Jilotzingo",
            "Jiménez",
            "Jiménez",
            "Jiménez",
            "Jiménez",
            "Jiménez",
            "Jiménez del Teul",
            "Jiquilpan",
            "Jiquilpan (Estación Bonanza)",
            "Jiquílpan de Juárez",
            "Jiquipilas",
            "Jiquipilco",
            "Jitonhueca",
            "Jitotol",
            "Jitzamuri",
            "Jiutepec",
            "Joachín",
            "Joaquín Herrera",
            "Joaquín Miguel Gutiérrez",
            "Joaquín Miguel Gutiérrez (Margaritas)",
            "Joconoxtle",
            "Jocoqui",
            "Jocotepec",
            "Jocotitlán",
            "Jofrito",
            "Jojutla",
            "Jolalpan",
            "Joljá",
            "Jolochero 2da. Sección",
            "Jolotichán",
            "Jolsibaquil",
            "Joltealal",
            "Jomanichim",
            "Jomulco",
            "Jonacapa",
            "Jonacatepec",
            "Jonotla",
            "Jonuta",
            "Jopala",
            "Joquicingo",
            "Jorge Jiménez Cantú",
            "Jorobas",
            "José Cardel",
            "José Colomo",
            "José de Jesús Aguirre Teocaltiche",
            "José Esteban Coronado",
            "José F. Gutiérrez",
            "José Guadalupe Aguilera (Santa Lucía)",
            "José Guadalupe Rodríguez",
            "José María Morelos",
            "José María Morelos",
            "José María Morelos",
            "José María Morelos",
            "José María Morelos",
            "José María Morelos",
            "José María Morelos",
            "José María Morelos (La Yegüería)",
            "José María Morelos (San José)",
            "José María Morelos (Santa Mónica)",
            "José María Morelos y Pavón",
            "José María Morelos y Pavón",
            "José María Morelos y Pavón",
            "José María Pino Suárez",
            "José María Pino Suárez",
            "José María Pino Suárez",
            "José María Pino Suárez",
            "José Mariano Jiménez",
            "Jose Narciso Rovirosa",
            "José Refugio Salcido",
            "Josefa Ortíz de Domínguez",
            "Joshil",
            "Juan Aldama",
            "Juan Aldama",
            "Juan Aldama (El Tigre)",
            "Juan C. Bonilla",
            "Juan de la Granja",
            "Juan del Grijalva",
            "Juan Díaz Covarrubias",
            "Juan E. García",
            "Juan Escutia (Borbollón)",
            "Juan Galindo",
            "Juan Gil Preciado",
            "Juan Jacobo Torres [Bodega de Totontepec]",
            "Juan José Ríos",
            "Juan Marcos (San José Buenavista)",
            "Juan Martín",
            "Juan Morales",
            "Juan N. Méndez",
            "Juan Rodríguez Clara",
            "Juan Salazar",
            "Juan Sarabia",
            "Juanacatlán",
            "Juárez",
            "Juárez",
            "Juárez Coronaco",
            "Juárez Nuevo León",
            "Juchipila",
            "Juchique de Ferrer",
            "Juchitán de Zaragoza",
            "Juchitepec",
            "Jucutacato",
            "Julián Blanco (Dos Caminos)",
            "Julián Grajales",
            "Julián Villagrán",
            "Julimes",
            "Jumiltepec",
            "Jungapeo de Juárez",
            "Juraré",
            "Juriquilla",
            "Justo Sierra",
            "Juventino Rosas",
            "Kanasín",
            "Kancab",
            "Kancabchén",
            "Kantunil",
            "Kantunilkín",
            "Kanxoc",
            "Kaua",
            "Kilómetro 30",
            "Kilómetro 40",
            "Kilómetro Dieciséis",
            "Kilómetro Noventa y Nueve",
            "Kimbila",
            "Kinchil",
            "Kini",
            "Kinil",
            "Kochol",
            "Komchén",
            "Kopoma",
            "Kotolte",
            "La Agüita",
            "La Alameda",
            "La Albarrada (San Francisco la Albarrada)",
            "La Aldaba",
            "La Aldea",
            "La Aldea",
            "La Aldea",
            "La Amistad",
            "La Angostura",
            "La Angostura",
            "La Angostura de los Altos",
            "La Antigua",
            "La Arbolada Plus",
            "La Arena",
            "La Ascensión",
            "La Aurora",
            "La Aurora",
            "La Azozuca",
            "La Ballena",
            "La Barca",
            "La Barra de Colotepec",
            "La Barranca",
            "La Barreta",
            "La Barreta",
            "La Baticolla",
            "La Blanca",
            "La Bocana",
            "La Boquilla",
            "La Boveda",
            "La Brecha",
            "La Cabecera",
            "La Cabecera Concepción",
            "La Caja",
            "La Calera",
            "La Calera",
            "La Calera (Nacimientos)",
            "La Calera Fraccionamiento",
            "La Calle",
            "La Camelia",
            "La Campana",
            "La Campanilla",
            "La Cañada",
            "La Cañada",
            "La Cañada",
            "La Cañada",
            "La Cañada",
            "La Cañada Tezcaltilti",
            "La Candelaria",
            "La Candelaria",
            "La Candelaria",
            "La Candelaria Teotlalpan",
            "La Candelaria Tlapala",
            "La Cantera",
            "La Cantera",
            "La Cantera",
            "La Capilla",
            "La Capilla",
            "La Capilla",
            "La Cardona",
            "La Caridad",
            "La Cebadilla",
            "La Ceiba",
            "La Ceiba",
            "La Ceja",
            "La Ceja de la Capilla",
            "La Central",
            "La Cerca Blanca",
            "La Cerquilla",
            "La Chiripa",
            "La Chueca Cadereyta Jiménez",
            "La Ciénega",
            "La Ciénega",
            "La Ciénega",
            "La Ciénega de Huaistita",
            "La Cieneguita",
            "La Cieneguita",
            "La Ciudad",
            "La Cofradía",
            "La Cofradía del Rosario",
            "La Colmena de Arriba",
            "La Colonia",
            "La Colonia",
            "La Colonia",
            "La Colonia Guadalupe",
            "La Colonia San Juanito",
            "La Colorada",
            "La Compañia",
            "La Compañía",
            "La Compañía",
            "La Competencia",
            "La Comunidad",
            "La Concepción",
            "La Concepción",
            "La Concepción",
            "La Concepción",
            "La Concepción",
            "La Concepción",
            "La Concepción",
            "La Concepción (La Concha)",
            "La Concepción Coatipac (La Conchita)",
            "La Concepción de Hidalgo",
            "La Concepción de los Baños",
            "La Concepción Enyege",
            "La Concepción Xochicuautla",
            "La Concha",
            "La Concha (La Concepción)",
            "La Conchilla",
            "La Conchita",
            "La Concordia",
            "La Concordia",
            "La Constancia",
            "La Constitución",
            "La Constitución Toltepec",
            "La Corregidora",
            "La Cruz",
            "La Cruz",
            "La Cruz",
            "La Cruz",
            "La Cruz",
            "La Cruz",
            "La Cruz de Loreto",
            "La Cruz del Palmar",
            "La Cruz y Carrizal",
            "La Cuchilla",
            "La Cuesta",
            "La Cuesta de Medina",
            "La Cueva",
            "La Cuevita",
            "La Curva",
            "La D",
            "La Defensa",
            "La Despensa",
            "La Dicha",
            "La Encarnación",
            "La Ermita",
            "La Ermita (Nueva Jerusalén)",
            "La Erradura",
            "La Escalera",
            "La Escondida",
            "La Escondida",
            "La Escuadra",
            "La Esmeralda",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza",
            "La Esperanza (El Zapotal)",
            "La Esperanza [Granjas Familiares]",
            "La Esquina",
            "La Estacada",
            "La Estacada",
            "La Estación",
            "La Estancia",
            "La Estancia",
            "La Estancia",
            "La Estancia de Amezcua",
            "La Estancia de Arriba",
            "La Estancia de Ayones",
            "La Estancia de Navarro",
            "La Estancia de San José del Carmen",
            "La Estancia Sector Uno (La Estancia)",
            "La Estancita",
            "La Estanzuela",
            "La Estanzuela",
            "La Estrella",
            "La Estrella",
            "La Estrella",
            "La Fama",
            "La Fe",
            "La Finca",
            "La Flor",
            "La Floreña",
            "La Floresta",
            "La Florida",
            "La Florida",
            "La Florida",
            "La Fortuna",
            "La Fortuna Fraccionamiento",
            "La Frontera",
            "La Fuente",
            "La Fundición (Quinta Manzana)",
            "La Galarza",
            "La Garita Jalisco",
            "La Garruña",
            "La Gavia",
            "La Gila",
            "La Gloria",
            "La Gloria",
            "La Gloria",
            "La Gloria",
            "La Gloria",
            "La Glorieta",
            "La Goleta",
            "La Goma",
            "La Gotera",
            "La Grandeza",
            "La Granjena",
            "La Griega",
            "La Guadalupana",
            "La Guadalupe",
            "La Guadalupe",
            "La Guadalupe",
            "La Guásima",
            "La Guásima",
            "La Guásima",
            "La Haciendita",
            "La Hermosura",
            "La Herradura",
            "La Herradura",
            "La Higuera",
            "La Higuerilla (Los Lirios)",
            "La Higuerita (Colonia San Rafael)",
            "La Horqueta (Poblado Doce)",
            "La Huacana",
            "La Huanica",
            "La Huerta",
            "La Huevera",
            "La Humedad",
            "La Ibérica (La Gotera)",
            "La Independencia",
            "La Independencia (Las Pilas)",
            "La Isla",
            "La Isla",
            "La Isla (Kilómetro 10)",
            "La Isla de Chapachapa",
            "La Isla Km 10",
            "La Jarrilla",
            "La Joya",
            "La Joya",
            "La Joya",
            "La Joya",
            "La Joya",
            "La Joya",
            "La Joya Chica",
            "La Joya de Alardín",
            "La Joya de Calvillo",
            "La Joya Grande",
            "La Junta",
            "La Junta",
            "La Junta",
            "La Labor",
            "La Labor",
            "La Labor",
            "La Labor de la Concepción",
            "La Labor de los Alba",
            "La Laborcita",
            "La Ladera",
            "La Ladera Grande",
            "La Laguna",
            "La Laguna",
            "La Laguna",
            "La Laguna",
            "La Laguna",
            "La Laguna",
            "La Laguna Grande",
            "La Laguna y Monte del Castillo",
            "La Lagunilla",
            "La Lagunita",
            "La Laja",
            "La Laja",
            "La Laja",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Lima",
            "La Lima",
            "La Línea",
            "La Lira",
            "La Llave",
            "La Lobera",
            "La Lobera",
            "La Lobera de Portillo",
            "La Loma",
            "La Loma",
            "La Loma",
            "La Loma",
            "La Loma",
            "La Loma (La Loma de Quila)",
            "La Loma Cuexcontitlán",
            "La Loma de los Negritos",
            "La Loma de los Rizo",
            "La Loma del Nopal",
            "La Lomita",
            "La Lucha",
            "La Luz",
            "La Luz",
            "La Luz",
            "La Luz",
            "La Luz",
            "La Luz",
            "La Luz",
            "La Luz",
            "La Luz de Juárez",
            "La Maestranza",
            "La Magdalena",
            "La Magdalena",
            "La Magdalena Chichicaspa",
            "La Magdalena de los Reyes (La Magdalena)",
            "La Magdalena Tenexpan",
            "La Magdalena Tetela Morelos",
            "La Magdalena Tlaltelulco",
            "La Magdalena Yancuitlalpan",
            "La Manga 2da. Sección (El Jobal)",
            "La Manzanilla",
            "La Manzanilla de La Paz",
            "La Mata",
            "La Merced",
            "La Mesa",
            "La Mesa",
            "La Mesa",
            "La Mesa (La Mesa de Cedano)",
            "La Mesa de Berrones",
            "La Mesa de Chosto",
            "La Mesa de los Conejos",
            "La Mesa del Guitarrero",
            "La Mesilla",
            "La Mesita",
            "La Mezcalilla",
            "La Mezquitera",
            "La Mial",
            "La Mina",
            "La Mina",
            "La Mintzita (Piedra Dura)",
            "La Mira",
            "La Mira Tumbiscatio",
            "La Misión",
            "La Mohonera",
            "La Mojarra",
            "La Mojonera",
            "La Moncada",
            "La Monja",
            "La Mora",
            "La Mota",
            "La Nance",
            "La Negreta",
            "La Nopalera",
            "La Noria",
            "La Noria",
            "La Noria",
            "La Noria",
            "La Noria de San Antonio",
            "La Norita",
            "La Nueva Era",
            "La Nueva Victoria",
            "La Ordeña",
            "La Orduña",
            "La Orilla",
            "La Orilla de la Cerca",
            "La Orilla del Agua",
            "La Ortiga",
            "La Pahua",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma",
            "La Palma (Las Palmas)",
            "La Palmilla",
            "La Palmita",
            "La Palmita (La Palmita de San Gabriel)",
            "La Palmita Nuevo Leon",
            "La Palmita y Anexos (La Presita)",
            "La Panadera",
            "La Parota",
            "La Parrita",
            "La Partida",
            "La Pastoría",
            "La Patria",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz de Ordaz",
            "La Pe",
            "La Pedrera",
            "La Peña Jalisco",
            "La Pendencia",
            "La Península",
            "La Peñita de Jaltomba",
            "La Perla",
            "La Perla",
            "La Pesca",
            "La Piedad",
            "La Piedad",
            "La Pila",
            "La Pimienta",
            "La Pinta",
            "La Placita de Morelos",
            "La Planada",
            "La Planada (El Arenal)",
            "La Playa",
            "La Playa",
            "La Plaza del Limón",
            "La Popular",
            "La Pradera",
            "La Presa",
            "La Presa",
            "La Presita",
            "La Presita de Berlanga",
            "La Presita Segundo Cuartel",
            "La Presumida",
            "La Primavera",
            "La Providencia",
            "La Providencia",
            "La Providencia",
            "La Providencia",
            "La Providencia",
            "La Providencia",
            "La Providencia Siglo XXI",
            "La Puerta de San Pedro",
            "La Puerta del Coche",
            "La Puerta del Pilar",
            "La Punta",
            "La Punta",
            "La Purificación",
            "La Purísima",
            "La Purísima",
            "La Purísima",
            "La Purísima",
            "La Quemada",
            "La Rana",
            "La Raya",
            "La Red",
            "La Reforma",
            "La Reforma",
            "La Reforma",
            "La Reforma",
            "La Reforma",
            "La Reforma",
            "La Resolana",
            "La Resurrección",
            "La Ribera",
            "La Rinconada",
            "La Rinconoda",
            "La Rivera",
            "La Roca",
            "La Rosa",
            "La Rosa",
            "La Rosa Amarilla",
            "La Rosa de Castilla",
            "La Rueda",
            "La Sabana",
            "La Sábana",
            "La Sabinita",
            "La Sala (La Caliente)",
            "La Sanguijuela",
            "La Sardina",
            "La Sauceda",
            "La Sauceda",
            "La Sauceda",
            "La Selva",
            "La Sidra",
            "La Solana",
            "La Soledad",
            "La Soledad",
            "La Soledad",
            "La Soledad",
            "La Soledad",
            "La Soledad",
            "La Soledad",
            "La Soledad",
            "La Soledad Barrio",
            "La Sombra",
            "La Subida",
            "La Tabaquera",
            "La Teja",
            "La Tesorera",
            "La Tigrilla",
            "La Tijera",
            "La Tinaja",
            "La Tinaja",
            "La Tinaja",
            "La Tinajera",
            "La Tiricia",
            "La Toma",
            "La Tortuga",
            "La Tortuga",
            "La Trinidad",
            "La Trinidad",
            "La Trinidad",
            "La Trinidad",
            "La Trinidad",
            "La Trinidad",
            "La Trinidad",
            "La Trinidad Chautenco",
            "La Trinidad Chimalpa",
            "La Trinidad Tenexyecac",
            "La Trinidad Tianguismanalco",
            "La Trinitaria",
            "La Unidad Huitzizilapan",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Unión",
            "La Unión del Cuatro",
            "La Unión Ejido Mexcaltepec",
            "La Unión Paso Largo",
            "La Unión y el Cardonal",
            "La Valla",
            "La Vaquera",
            "La Vega",
            "La Venta",
            "La Venta del Astillero",
            "La Ventana",
            "La Ventilla",
            "La Ventosa",
            "La Verdolaga",
            "La Versolilla",
            "La Victoria",
            "La Victoria",
            "La Victoria",
            "La Victoria",
            "La Victoria (La Peñita)",
            "La Villa",
            "La Virgencita",
            "La Y",
            "La Yerbabuena",
            "La Yesca",
            "La Zacatecana",
            "La Zapotera",
            "Labor de Medina",
            "Labor de Montoya",
            "Labor de Peralta",
            "Lacandón",
            "Lacapan Camallagne",
            "Ladera Chica",
            "Ladrillera",
            "Ladrillera (Entronque Pesquería)",
            "Ladrilleras de Ocoro",
            "Ladrilleras del Refugio",
            "Lafragua",
            "Lagartera 1ra. Sección",
            "Lagos de Moreno",
            "Laguna Chica (Pueblo Nuevo)",
            "Laguna Colorada",
            "Laguna de Farfán",
            "Laguna de Guadalupe",
            "Laguna de Piedra",
            "Laguna de San Vicente",
            "Laguna de Santa Rita",
            "Laguna de Tabernillas (El Resbaloso)",
            "Laguna de Vaquerías",
            "Laguna del Cofre",
            "Laguna del Mante",
            "Laguna del Rey (Químicas del Rey)",
            "Laguna Larga",
            "Laguna Larga de Cortés",
            "Laguna Seca",
            "Laguneta",
            "Lagunilla",
            "Lagunilla",
            "Lagunillas",
            "Lagunillas",
            "Lagunillas",
            "Lagunillas Jalisco",
            "Lamadrid",
            "Lampazos de Naranjo",
            "Landa de Matamoros",
            "Landero y Coss",
            "Landín",
            "Larráinzar",
            "Las Aguamitas",
            "Las Alfalfas",
            "Las Amapolas",
            "Las Amarillas",
            "Las Amilpas",
            "Las Ánimas",
            "Las Ánimas",
            "Las Ánimas",
            "Las Arenitas",
            "Las Armas",
            "Las Asturias Fraccionamiento",
            "Las Azules",
            "Las Barretas",
            "Las Barrillas",
            "Las Bocas",
            "Las Brisas",
            "Las Brisas",
            "Las Brisas",
            "Las Brisas",
            "Las Calaveras",
            "Las Cañadas",
            "Las Cañadas",
            "Las Cañas",
            "Las Cañas",
            "Las Canoas",
            "Las Carretas",
            "Las Catarinas",
            "Las Chicharras",
            "Las Choapas",
            "Las Cieneguitas",
            "Las Colonias de Hidalgo",
            "Las Coloradas",
            "Las Compuertas",
            "Las Compuertas",
            "Las Cruces",
            "Las Cruces",
            "Las Cuevas",
            "Las Delicias",
            "Las Delicias",
            "Las Esperanzas (El Ranchito)",
            "Las Flechas",
            "Las Golondrinas [Fraccionamiento]",
            "Las Grullas Margen Derecha",
            "Las Grullas Margen Izquierda",
            "Las Higueras",
            "Las Higuerillas",
            "Las Huertas",
            "Las Huertas Tercera Sección",
            "Las Jarretaderas",
            "Las Juntas",
            "Las Lajas",
            "Las Lajitas",
            "Las Letras",
            "Las Liebres",
            "Las Lomas",
            "Las Lomas",
            "Las Lomas",
            "Las Lomas de Tacamichapan",
            "Las Manzanas",
            "Las Maravillas",
            "Las Margaritas",
            "Las Margaritas",
            "Las Margaritas",
            "Las Masas (La Luz Masas)",
            "Las Mercedes [Agropecuaria]",
            "Las Mesas",
            "Las Mesas de San Juan",
            "Las Mieleras",
            "Las Minas",
            "Las Moras",
            "Las Nieves",
            "Las Norias de Ojocaliente",
            "Las Ollas",
            "Las Palmas",
            "Las Palmas",
            "Las Palomas",
            "Las Peñas",
            "Las Pilas",
            "Las Pintas",
            "Las Pintas de Arriba",
            "Las Pintitas",
            "Las Plazuelas",
            "Las Primaveras [Invernadero]",
            "Las Quemazones",
            "Las Ranas",
            "Las Rosas",
            "Las Rosas",
            "Las Sabanetas",
            "Las Sabinas",
            "Las Taponas",
            "Las Tazas",
            "Las Torres",
            "Las Torres de Guadalupe",
            "Las Trancas",
            "Las Trojas",
            "Las Tunas",
            "Las Varas",
            "Las Varas",
            "Las Varas (Estación Babícora)",
            "Las Veredas",
            "Las Vigas",
            "Las Vigas de Ramírez",
            "Las Yerbitas [Aserradero]",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas",
            "Lázaro Cárdenas (Chilil)",
            "Lázaro Cárdenas (El Empalme)",
            "Lázaro Cárdenas (La Purísima)",
            "Lázaro Cárdenas (Rancho Grande)",
            "Lázaro Cárdenas (Santana)",
            "Lázaro Cárdenas Jalisco",
            "Leacaman",
            "León",
            "León de los Aldama",
            "León Guzmán",
            "Leona Vicario",
            "Leona Vicario",
            "Lepan",
            "Lerdo de Tejada",
            "Lerma",
            "Lerma de Villada",
            "Leyva Solano",
            "Libertad",
            "Libertad Álvaro Obregón",
            "Libertad Melchor Ocampo",
            "Libertad Ventanas",
            "Libre Unión",
            "Libres",
            "Licenciado Benito Juárez (Campo Gobierno)",
            "Licenciado Gustavo Díaz Ordaz",
            "Licenciado Gustavo Díaz Ordaz (18 de Marzo)",
            "Limones",
            "Linares",
            "Linares Nuevo León",
            "Lindavista",
            "Lindavista",
            "Lipuntahuaca",
            "Llano de Agua",
            "Llano de Enmedio",
            "Llano de la Lima",
            "Llano de la Puerta",
            "Llano de la Y",
            "Llano de las Flores (Barrio del Hueso)",
            "Llano de Zacapexco",
            "Llano del Tigre",
            "Llano Grande",
            "Llano Grande",
            "Llano Grande",
            "Llano Grande",
            "Llano Grande",
            "Llano Largo",
            "Llano Suchiapa",
            "Llera",
            "Llera de Canales",
            "Lo Arado",
            "Lo de Jesús (Campo Romero)",
            "Lo de Juárez",
            "Lo de Lamedo",
            "Lo de Marcos",
            "Lobatos",
            "Loché",
            "Lodo Grande",
            "Lolotla",
            "Loma Alta",
            "Loma Alta",
            "Loma Alta Taxhimay",
            "Loma Angosta",
            "Loma Blanca",
            "Loma Bonita",
            "Loma Bonita",
            "Loma Bonita",
            "Loma Bonita",
            "Loma Bonita",
            "Loma Chapultepec",
            "Loma de Bácum",
            "Loma de Guadalupe (La Biznaga)",
            "Loma de Guamúchil",
            "Loma de Juárez",
            "Loma de la Esperanza",
            "Loma de los Carmona",
            "Loma de los Hoyos",
            "Loma de San Francisco",
            "Loma de San José",
            "Loma de San Miguel",
            "Loma de Sogotegoyo",
            "Loma de Yerbabuena",
            "Loma de Zempoala",
            "Loma del Refugio",
            "Loma del Río",
            "Loma Grande",
            "Loma la Paz",
            "Loma Larga",
            "Loma Larga (Barrio de Loma Larga)",
            "Loma Linda",
            "Loma Pelada",
            "Loma Tendida",
            "Lomantán",
            "Lomas de Ahuatepec",
            "Lomas de Altavista",
            "Lomas de Barrillas",
            "Lomas de Chapultepec",
            "Lomas de la Maestranza",
            "Lomas de Río Medio Cuatro",
            "Lomas de Romero",
            "Lomas de San Agustín",
            "Lomas de San Francisco",
            "Lomas de San Juan",
            "Lomas de San Martín",
            "Lomas de San Pablo",
            "Lomas de San Sebastián",
            "Lomas de Santa Anita",
            "Lomas de Santa Cruz",
            "Lomas de Tejeda",
            "Lomas de Tenopalco",
            "Lomas de Tepemecatl",
            "Lomas de Tlatelolco",
            "Lomas del Real",
            "Lomas del Sur",
            "Lomas Verdes",
            "Lombardía",
            "Lomitas",
            "López Rayón",
            "Loreto",
            "Loreto",
            "Los Achotes",
            "Los Aguajes",
            "Los Álamos",
            "Los Álamos",
            "Los Aldamas",
            "Los Algodones",
            "Los Almendros",
            "Los Altos",
            "Los Angeles",
            "Los Angeles",
            "Los Ángeles",
            "Los Ángeles",
            "Los Ángeles Nuevo León",
            "Los Ángeles Tetela",
            "Los Arana",
            "Los Arcos",
            "Los Arcos",
            "Los Arquitos",
            "Los Aztecas",
            "Los Azulitos",
            "Los Bahuises",
            "Los Baños",
            "Los Barriles",
            "Los Benitos",
            "Los Berros",
            "Los Cabos",
            "Los Cafetales",
            "Los Cajones",
            "Los Caños",
            "Los Cavazos",
            "Los Cedros",
            "Los Cenotes",
            "Los Cerritos",
            "Los Cerritos",
            "Los Charcos",
            "Los Cides",
            "Los Condes",
            "Los Conos",
            "Los Corazones",
            "Los Cristales (Talican)",
            "Los Cues",
            "Los Desmontes",
            "Los Divorciados",
            "Los Dolores (Las Quince Letras)",
            "Los Dos Ríos",
            "Los Dulces Nombres",
            "Los Encinos",
            "Los Fierros",
            "Los Galvan",
            "Los Garcías",
            "Los Gavilanes",
            "Los Guajes",
            "Los Guerra",
            "Los Guerrero",
            "Los Herreras",
            "Los Hoyos",
            "Los Hucuares",
            "Los Ídolos",
            "Los Laureles",
            "Los Limones",
            "Los Lirios",
            "Los Liros",
            "Los Lorenzos",
            "Los Mancera",
            "Los Mangos",
            "Los Mazos",
            "Los Medranos",
            "Los Mochis",
            "Los Mogotes",
            "Los Molinos",
            "Los Naranjos",
            "Los Naranjos",
            "Los Naranjos Esquipulas",
            "Los Nicolases",
            "Los Nogales",
            "Los Ocotes",
            "Los Olivos",
            "Los Olvera",
            "Los Órganos de San Agustín",
            "Los Otates",
            "Los Palacios",
            "Los Parques",
            "Los Pescados",
            "Los Pilares",
            "Los Pilares",
            "Los Pinos",
            "Los Pinos",
            "Los Plátanos",
            "Los Pocitos",
            "Los Pocitos",
            "Los Pocitos",
            "Los Pozos",
            "Los Pozos",
            "Los Prietos",
            "Los Ramírez",
            "Los Ramírez",
            "Los Ramones",
            "Los Remedios",
            "Los Remedios",
            "Los Reyes",
            "Los Reyes",
            "Los Reyes",
            "Los Reyes Acaquilpan",
            "Los Reyes Acatlixhuayán",
            "Los Reyes de Juárez",
            "Los Reyes de Salgado",
            "Los Reyes Quiahuixtlan",
            "Los Reyes Tlanechicolpan",
            "Los Riegos",
            "Los Robles",
            "Los Rodríguez",
            "Los Romeros",
            "Los Ruiseñores",
            "Los Sabinos",
            "Los Sauces",
            "Los Sauces",
            "Los Sauces",
            "Los Saucitos",
            "Los Saucos",
            "Los Tepames",
            "Los Tepetates",
            "Los Tigres (San Marcos)",
            "Los Torrentes",
            "Los Tuzos",
            "Los Valles",
            "Los Vergara",
            "Los Zacatones",
            "Lourdes",
            "Loza de Barrera",
            "Loza de los Padres",
            "Luchanas",
            "Lucio Blanco (Los Gavilanes)",
            "Luis Espinoza",
            "Luis Gil Pérez",
            "Luis Moya",
            "Luis Rodríguez (El Vergel)",
            "Luvianos",
            "Macapule",
            "Macario J. Gómez [Colonia]",
            "Macedonio Alonso",
            "Machetla",
            "Macho de Agua",
            "Macín Chico",
            "Maclovio Herrera",
            "Maclovio Herrera (Colonia Aviación)",
            "Maclovio Rojas",
            "Mactún",
            "Macuilxóchitl de Artigas Carranza",
            "Macultepec",
            "Macuspana",
            "Macutzio",
            "Macvilhó",
            "Madera",
            "Madrid",
            "Mafafas",
            "Magdalena",
            "Magdalena",
            "Magdalena Apasco",
            "Magdalena Atlicpac",
            "Magdalena Contreras",
            "Magdalena Cuayucatepec",
            "Magdalena de Araceo",
            "Magdalena de Kino",
            "Magdalena Jaltepec",
            "Magdalena Mixtepec",
            "Magdalena Ocotlán",
            "Magdalena Teitipac",
            "Magdalena Tequisistlán",
            "Magdalena Tlacotepec",
            "Magdalena Yodocono de Porfirio Díaz",
            "Magdalena Zahuatlán",
            "Magdaleno Cedillo",
            "Magisterio Digno",
            "Maguarichi",
            "Maguey Blanco",
            "Mahuixtlan",
            "Mainero",
            "Maitinez",
            "Majastic",
            "Majomut",
            "Malagana (San Antonio del Monte)",
            "Malinalco",
            "Malinaltepec",
            "Malpaso",
            "Malpaso",
            "Maltrata",
            "Mama",
            "Mamantel",
            "Mamithi",
            "Manalisco",
            "Manantiales",
            "Mancuernas",
            "Mangas",
            "Mani",
            "Manto del Río Ejido",
            "Manuel Ávila Camacho",
            "Manuel Ávila Camacho",
            "Manuel Ávila Camacho (Ponte Duro)",
            "Manuel Benavides",
            "Manuel Doblado",
            "Manuel Edgardo Ávalos (San Isidro)",
            "Manuel Lazos",
            "Manuel León",
            "Manuel María Contreras",
            "Manuel Ojinaga",
            "Manuel Villalongín",
            "Manzana de San Luis",
            "Manzana la Cofradía",
            "Manzana Quinta (La Cañada)",
            "Manzana Segunda",
            "Manzana Sexta Parte Centro",
            "Manzana Tercera de Santa Cruz Tepexpan",
            "Manzanillo",
            "Mapachapa",
            "Mapastepec",
            "Mapimí",
            "Maravatío de Ocampo",
            "Maravatío del Encinal",
            "Maravilla Tenejapa",
            "Maravillas",
            "Maravillas",
            "Maravillas",
            "Maravillas",
            "Maravillas",
            "Marcelino Rodríguez (San Ignacio)",
            "Marcol",
            "Marfil",
            "Margaritas",
            "María Aurora",
            "María Auxiliadora",
            "María de la Torre",
            "María Lombardo de Caso",
            "Mariano Balleza",
            "Mariano Escobedo",
            "Mariano Escobedo",
            "Mariano Matamoros",
            "Marín",
            "Maripa",
            "Mariscal Subikuski",
            "Mariscala de Juárez",
            "Marquelia",
            "Marqués de Comillas",
            "Marroquín",
            "Marte R. Gómez",
            "Marte R. Gómez (Tobarito)",
            "Martinez",
            "Martínez de la Torre",
            "Martínez Domínguez",
            "Mascota Municipality",
            "Masiaca",
            "Mata de Indio",
            "Mata Loma",
            "Mata Naranjo",
            "Mata Obscura",
            "Mata Tenatito (Casco Hacienda)",
            "Matacapan",
            "Matachí",
            "Matamoros",
            "Matamoros",
            "Matamoros",
            "Matamoros",
            "Matancillas (San Isidro Matancillas)",
            "Matanzas",
            "Matatlán",
            "Matehuala",
            "Matías Romero",
            "Matlahuacales Aquiles Serdán",
            "Matzaco",
            "Matzam",
            "Mavoro",
            "Maxcanú",
            "Maxela",
            "Máximo Serdán",
            "Maxtleca de Galeana",
            "Maya Balam",
            "Maya Tecún I",
            "Mayanalán",
            "Mayapan",
            "Mayorazgo de León",
            "Mayran",
            "Mazamitlongo",
            "Mazapa",
            "Mazapa",
            "Mazapa de Madero",
            "Mazapil",
            "Mazapiltepec",
            "Mazatán",
            "Mazatán",
            "Mazatecochco",
            "Mazatepec",
            "Mazatepec",
            "Mazateupa",
            "Mazatlan",
            "Mazatlán",
            "Mazatlán",
            "Mazatlán Villa de Flores",
            "Mazín Grande",
            "Mazumiapam",
            "Mecapalapa",
            "Mecatán",
            "Mecatepec",
            "Mecatlán",
            "Mecatlán",
            "Mecayapan",
            "Mechoacanejo",
            "Mecoacán",
            "Mecoacán 2da. Sección (San Lorenzo)",
            "Medellín",
            "Medellín de Bravo",
            "Medellín y Madero Segunda Sección",
            "Medias Aguas",
            "Medina",
            "Medio Sitio",
            "Melchor Ocampo",
            "Melchor Ocampo",
            "Melchor Ocampo",
            "Melchor Ocampo",
            "Melchor Ocampo",
            "Melchor Ocampo",
            "Melchor Ocampo Nuevo León",
            "Melitón Albáñez Domínguez",
            "Méndez",
            "Menores",
            "Meoqui",
            "Mequetla",
            "Merced del Potrero",
            "Mérida",
            "Mérida",
            "Merino",
            "Mesa de Guadalupe",
            "Mesa del Huracán",
            "Mesa del Nayar",
            "Mesa Grande",
            "Mesillas",
            "Mesillas",
            "Mesón Nuevo (Cañada de la Magdalena)",
            "Mesones Hidalgo",
            "Metapa",
            "Metepec",
            "Metepec",
            "Metepec",
            "Metepec Primero",
            "Metlac Hernández (Metlac Primero)",
            "Metlaltoyuca",
            "Metlapa",
            "Metlatónoc",
            "Metztitlán",
            "Mexcala",
            "Mexcalcuautla",
            "Mexicali",
            "Mexicaltzingo",
            "Mexicanos",
            "Mexiquito",
            "Méxpan",
            "Mextepec",
            "Mexticacán",
            "Mezapa la Fábrica",
            "Mezcala",
            "Mezcala",
            "Mezcales",
            "Mezquital",
            "Mezquital del Oro",
            "Mezquite de Luna",
            "Mezquite de Sotelo",
            "Mezquite Gordo",
            "Mezquitic de la Magdalena",
            "Mezquitic Municipality",
            "Mezquititlán",
            "Mi Patria es Primero",
            "Miacatlán",
            "Miahuatlán",
            "Miahuatlán",
            "Miahuatlán de Porfirio Díaz",
            "Michac",
            "Michapa",
            "Michimaloya",
            "Mier",
            "Mier y Noriega",
            "Miguel Ahumada",
            "Miguel Alemán",
            "Miguel Alemán (La Doce)",
            "Miguel Auza",
            "Miguel Bocanegra",
            "Miguel Hidalgo",
            "Miguel Hidalgo",
            "Miguel Hidalgo",
            "Miguel Hidalgo",
            "Miguel Hidalgo",
            "Miguel Hidalgo",
            "Miguel Hidalgo 2da. Sección B (La Natividad)",
            "Miguel Negrete",
            "Miguel Utrilla (Los Chorros)",
            "Miguel Valdez Quintero (El Corazón)",
            "Milagros",
            "Milpa Alta",
            "Milpas Viejas",
            "Milpillas",
            "Milpillas",
            "Mina",
            "Mina México",
            "Mina Nuevo León",
            "Mina Vieja",
            "Minas de Barroterán",
            "Minatitlán",
            "Minatitlán",
            "Mineral de Angangueo",
            "Mineral de la Luz",
            "Mineral del Monte",
            "Minzapan",
            "Miquihuana",
            "Miraflores",
            "Miraflores",
            "Miramar",
            "Miravalle",
            "Miravalles",
            "Misantla",
            "Misija",
            "Misión de Chichimecas",
            "Misión San Luis Gonzaga Chiriyaqui",
            "Misión San Pablo",
            "Mismaloya",
            "Mismaloya (Fraccionamiento Pedregal de Santa Martha)",
            "Mitontic",
            "Mitras Poniente",
            "Mitzitón",
            "Mixcum",
            "Mixistlán de la Reforma",
            "Mixquiahuala de Juarez",
            "Mixquiapan",
            "Mixtla",
            "Mixtla de Altamirano",
            "Mixtlalcingo",
            "Mixtlán",
            "Mochicahui",
            "Mochis",
            "Mochitlán",
            "Mococha",
            "Mocorito",
            "Moctezuma",
            "Moctezuma",
            "Mohoneras",
            "Mojarras",
            "Molango",
            "Molas",
            "Molino Abajo",
            "Molino de Camou",
            "Molino de San José",
            "Molino de Santa Ana",
            "Molinos",
            "Moloacán",
            "Momax",
            "Mompaní",
            "Monclova",
            "Monclova Primer Sector",
            "Monclova Segundo Sector",
            "Montaña Monarca (Punta Altozano)",
            "Monte Alegre",
            "Monte Blanco",
            "Monte Blanco",
            "Monte Calvario",
            "Monte del Coecillo",
            "Monte Escobedo",
            "Monte Grande",
            "Monte Grande",
            "Monte Kristal",
            "Monte Largo 1ra. Sección",
            "Monte Obscuro",
            "Monte Redondo",
            "Monte Salas",
            "Monte Verde",
            "Monte Verde Chivería",
            "Montecillo",
            "Montecillos",
            "Montecristo",
            "Montecristo de Guerrero",
            "Monteleón",
            "Montemorelos",
            "Montenegro",
            "Montenegro la Lana",
            "Monteón",
            "Monterrey",
            "Monterrey",
            "Montoro",
            "Mora Villalobos",
            "Morales",
            "Moralillo",
            "Moras",
            "Morelia",
            "Morelia (Victórico Rodolfo Grajales)",
            "Morelita",
            "Morelos",
            "Morelos",
            "Morelos",
            "Morelos",
            "Morelos",
            "Morelos",
            "Morelos Cañada",
            "Morelos Piedra 3ra. Sección",
            "Moris",
            "Morocoy",
            "Moroleón",
            "Moroncarit",
            "Motovatha",
            "Motozintla",
            "Motul",
            "Motzorongo",
            "Moyahua de Estrada",
            "Moyoapan",
            "Moyos",
            "Moyotepec",
            "Moyotepec (Moyotepec de Juárez)",
            "Moyotzingo",
            "Mozomboa",
            "Mulegé",
            "Multé",
            "Muna",
            "Mundo Nuevo",
            "Munitepec de Madero",
            "Muñoz",
            "Muquén",
            "Muxupip",
            "Nacajuca",
            "Nachig",
            "Naco",
            "Nácori Chico",
            "Nacozari de García",
            "Nacozari Viejo",
            "Nacuche",
            "Nadadores",
            "Nahuatzén",
            "Naica",
            "Namiquipa",
            "Nanacatlán",
            "Nanahuatípam",
            "Nanchital de Lázaro Cárdenas del Río",
            "Náñez González",
            "Nantzha",
            "Naolinco",
            "Naolinco de Victoria",
            "Naranja de Tapia",
            "Naranjal",
            "Naranjo",
            "Naranjos",
            "Naranjos Amatlán",
            "Narciso Mendoza",
            "Narciso Mendoza",
            "Narciso Mendoza",
            "Nativitas",
            "Natívitas",
            "Naucalpan de Juárez",
            "Naupan",
            "Nautla",
            "Nauzontla",
            "Nava",
            "Navajas",
            "Navarrete",
            "Navenchauc",
            "Navojoa",
            "Navolato",
            "Nazareno",
            "Nazareno Etla",
            "Nazas",
            "Nealtican",
            "Necaxa",
            "Necoxcalco",
            "Necoxtla",
            "Necoxtla",
            "Negritas",
            "Nejapa",
            "Nejapa de Madero",
            "Nenehuaca",
            "Nepantla de Sor Juana Inés de la Cruz",
            "Nepopualco",
            "Netzahualcóyotl",
            "Neutla",
            "Nexpan",
            "Nexticapan",
            "Nextipac",
            "Nicolás Blanco (San Pancho)",
            "Nicolas Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo",
            "Nicolás Bravo 5ta. Sección (Punta Brava)",
            "Nicolás R Casillas",
            "Nicolás Romero",
            "Nicolás Ruiz",
            "Nieves",
            "Nigromante",
            "Niltepec",
            "Ninguno [Centro de Readaptación Social de Atlacholoaya]",
            "Ninguno [CERESO]",
            "Ninguno [CERESO]",
            "Niños Héroes",
            "Nochistlán de Mejía",
            "Nocupétaro",
            "Nogales",
            "Nogales",
            "Nogales",
            "Nolo",
            "Nombre de Dios",
            "Nonoava",
            "Nopala de Villagran",
            "Nopalapan",
            "Nopaltepec",
            "Nopaltepec",
            "Nopalucan",
            "Noria de Ángeles",
            "Noria del Borrego (Norias)",
            "Noria Nueva",
            "Norias del Paso Hondo",
            "Norias del Refugio",
            "Norte 1ra. Sección (San Julián)",
            "Novara",
            "Nueva América",
            "Nueva Ameyalco",
            "Nueva Apolonia",
            "Nueva Ciudad Guerrero",
            "Nueva Colombia",
            "Nueva División del Bayo (Guatemala)",
            "Nueva Esperanza",
            "Nueva Frontera",
            "Nueva Independencia",
            "Nueva Italia de Ruiz",
            "Nueva Libertad",
            "Nueva Libertad (El Colorado)",
            "Nueva Morelia",
            "Nueva Palestina",
            "Nueva Primavera",
            "Nueva Rosita",
            "Nueva Santa Rosa",
            "Nueva Sesecapa",
            "Nueva Tenochtitlán (Rizo de Oro)",
            "Nueva Villa de Padilla",
            "Nuevo Amatenango",
            "Nuevo Balsas",
            "Nuevo Carmen Tonapac",
            "Nuevo Casas Grandes",
            "Nuevo Centro de Población Agrícola el Chacón",
            "Nuevo Cereso Regional de Altamira",
            "Nuevo Crucitas",
            "Nuevo Francisco León",
            "Nuevo Guerrero",
            "Nuevo Ideal",
            "Nuevo Ixcatlán",
            "Nuevo Juan del Grijalva",
            "Nuevo Laredo",
            "Nuevo León",
            "Nuevo León",
            "Nuevo Limar",
            "Nuevo México",
            "Nuevo México",
            "Nuevo México Jalisco",
            "Nuevo Michoacán (Estación Riíto)",
            "Nuevo Milenio Valdivia",
            "Nuevo Morelos",
            "Nuevo Naranjo",
            "Nuevo Necaxa",
            "Nuevo Nicapa",
            "Nuevo Pacayal",
            "Nuevo Paso Nazareno (Chichicazapa)",
            "Nuevo Progreso",
            "Nuevo Progreso",
            "Nuevo Progreso",
            "Nuevo Pumpuapa (Cereso)",
            "Nuevo Refugio de Afuera (Nuevo Refugio)",
            "Nuevo San José Independencia",
            "Nuevo San Juan Chamula",
            "Nuevo San Juan Parangaricutiro",
            "Nuevo San Martín",
            "Nuevo San Miguel",
            "Nuevo Sitalá",
            "Nuevo Tampaón",
            "Nuevo Tantoán",
            "Nuevo Torno Largo",
            "Nuevo Urecho",
            "Nuevo Vallarta",
            "Nuevo Vicente Guerrero",
            "Nuevo Vicente Guerrero (El Chichonal)",
            "Nuevo Volcán Chichonal",
            "Nuevo Xcán",
            "Nuevo Xochimilco",
            "Nuevo Zirosto",
            "Numarán",
            "Nunkiní",
            "Nurío",
            "Nuxco",
            "Oacalco",
            "Oasis",
            "Oaxaca",
            "Oaxaca de Juárez",
            "Oaxtepec",
            "Obrajuelo",
            "Ocampo",
            "Ocampo",
            "Ocampo",
            "Ocampo",
            "Ocampo",
            "Ocampo",
            "Ocampo",
            "Occidente (San Francisco)",
            "Occidente 4ta. Sección",
            "Ocelota",
            "Ocho de Enero",
            "Ochusjob",
            "Ococh",
            "Oconahua Jalisco",
            "Ocopulco",
            "Ocosingo",
            "Ocotal Chico",
            "Ocotepec",
            "Ocotepec",
            "Ocotequila",
            "Ocotillo",
            "Ocotitlán",
            "Ocotlamanic",
            "Ocotlán",
            "Ocotlán de Betancourt",
            "Ocotlán de Morelos",
            "Ocotlán de Venustiano Carranza",
            "Ocotlán Jalisco",
            "Ocoxaltepec",
            "Ocoyoacac",
            "Ocozocoautla de Espinosa",
            "Ocozotepec",
            "Octaviano López",
            "Octavio Paz",
            "Octeyuco Dos Mil",
            "Ocuapan",
            "Ocuilan",
            "Ocuilan de Arteaga",
            "Ocuiltzapoyo",
            "Ocuituco",
            "Ocumicho",
            "Ocurio",
            "Ohuapan",
            "Ohuatipa",
            "Ohuilapam",
            "Ojinaga",
            "Ojite Rancho Nuevo",
            "Ojitos",
            "Ojo Caliente",
            "Ojo de Agua",
            "Ojo de Agua",
            "Ojo de Agua",
            "Ojo de Agua",
            "Ojo de Agua",
            "Ojo de Agua de Ballesteros",
            "Ojo de Agua de Bucio",
            "Ojo de Agua de Crucitas",
            "Ojo de Agua de la Trinidad",
            "Ojo de Agua de Mendoza",
            "Ojo de Agua de Morán Jalisco",
            "Ojo de Agua de Palmillas",
            "Ojo de Agua de Solano",
            "Ojo de Agua del Refugio",
            "Ojo de Rana",
            "Ojo Seco",
            "Ojocaliente",
            "Ojuelos de Jalisco",
            "Olcuatitán",
            "Olinalá",
            "Olintepec",
            "Olintla",
            "Oluta",
            "Omealca",
            "Ometepec",
            "Ometepec",
            "Omitlán de Juárez",
            "Omoa",
            "Onavas",
            "Once de Abril",
            "Opichen",
            "Opodepe",
            "Opopeo",
            "Oquitoa",
            "Orba (Infiernito)",
            "Orduña de Abajo",
            "Orduña de Arriba",
            "Oriental",
            "Oriental de Zapata",
            "Oriente 1ra. Sección",
            "Oriente 1ra. Sección (Santo Domingo)",
            "Oriente 2da. Sección (Palma Huaca)",
            "Oriente 2da. Sección (San Cayetano)",
            "Oriente 6ta. Sección (Los Mulatos)",
            "Orilla del Monte",
            "Orizaba",
            "Orizaba",
            "Orizabita",
            "Orranteño",
            "Ortiz",
            "Oscar Soto Maynez",
            "Oso Viejo",
            "Ostuacán",
            "Osumacinta",
            "Otates",
            "Otates",
            "Otatitlán",
            "Otatlán",
            "Oteapan",
            "Othón P. Blanco",
            "Otilpan",
            "Otumba",
            "Otzoloapan",
            "Otzolotepec",
            "Oviedo",
            "Oxchuc",
            "Oxcum",
            "Oxeloco",
            "Oxiacaque",
            "Oxinam",
            "Oxkutzcab",
            "Oxkutzkab",
            "Oxolotán",
            "Oxtotipac",
            "Oxtotitlán",
            "Oyameles de Hidalgo",
            "Ozelonacaxtla",
            "Oztotitlán",
            "Ozuluama de Mascareñas",
            "Ozumba de Alzate",
            "Pabellón de Arteaga",
            "Pabellón de Hidalgo",
            "Pablo L. Sidar",
            "Pablo L. Sidar",
            "Pachiquita",
            "Pacho Nuevo",
            "Pacho Viejo",
            "Pachuca de Soto",
            "Pachuquilla",
            "Pacú",
            "Padilla",
            "Pahactla",
            "Pahua Hueca",
            "Pahuatlán",
            "Pahuatlán de Valle",
            "Paintla",
            "Paisanos",
            "Pajacuarán",
            "Pajapan",
            "Palau",
            "Palenque",
            "Palizada",
            "Palizada",
            "Palma Grande",
            "Palma Pegada",
            "Palma Sola",
            "Palmar Chico",
            "Palmar de Bravo",
            "Palmar de Cuautla",
            "Palmar Grande",
            "Palmar Primero",
            "Palmarito Tochapan",
            "Palmas de Abajo",
            "Palmillas",
            "Palmillas",
            "Palmillas",
            "Palmillas",
            "Palmillas",
            "Palmillas",
            "Palmillas de San Juan",
            "Palmira",
            "Palmito del Verde",
            "Palo Alto",
            "Palo Alto",
            "Palo Bendito",
            "Palo Blanco",
            "Palo Colorado",
            "Palo Gacho",
            "Palo Mulato",
            "Palo Seco (El Gato)",
            "Palomar",
            "Palomares",
            "Palomas",
            "Palomas",
            "Palos Altos",
            "Palos Amarillos (Palos Amarillos Yebuciví)",
            "Palos Blancos",
            "Palos Verdes",
            "Pamatácuaro",
            "Panabá",
            "Panales",
            "Panales Jamaica (Cañones)",
            "Pañhé",
            "Panindícuaro",
            "Panotla",
            "Pantanal",
            "Pantelhó",
            "Pantepec",
            "Pantepec",
            "Pantitlán",
            "Pantla",
            "Panuaya",
            "Pánuco",
            "Pánuco de Coronado",
            "Papaloapan",
            "Papaloctipan",
            "Papalotla",
            "Papalotla",
            "Papanoa",
            "Papantla de Olarte",
            "Papatlatla",
            "Papatlazolco",
            "Paracho de Verduzco",
            "Paracuaro",
            "Parácuaro",
            "Parahuén",
            "Paraiso",
            "Paraíso del Grijalva",
            "Paraiso Novillero",
            "Paraje el Mirador",
            "Paraje la Pera",
            "Paraje Montero (Paraje Montero de Zaragoza)",
            "Paraje Nuevo",
            "Paraje Trejo (El Chaparral)",
            "Parangarico",
            "Parás",
            "Parás Nuevo León",
            "Pardo",
            "Paredes",
            "Paredón",
            "Paredón",
            "Paredón Colorado",
            "Páreo",
            "Parícuaro",
            "Parilla",
            "Parque de Poblamiento Solidaridad",
            "Parque Industrial Ciudad Mitras",
            "Parque Urbano Napateco",
            "Parral",
            "Parras de la Fuente",
            "Parrilla II",
            "Pasaje",
            "Pascala del Oro",
            "Paseo de las Cañadas",
            "Paseo de San Javier",
            "Paseo del Prado",
            "Paseo Puente Viejo",
            "Paseos de la Pradera",
            "Paseos de la Providencia [Fraccionamiento]",
            "Paseos de las Haciendas [Fraccionamiento]",
            "Paseos de San Juan",
            "Paseos del Marqués",
            "Paseos del Pedregal",
            "Paseos del Valle [Fraccionamiento]",
            "Paso Blanco",
            "Paso Blanco",
            "Paso Bonito",
            "Paso Canoa",
            "Paso Carretas",
            "Paso de Arena",
            "Paso de Cuarenta",
            "Paso de Hidalgo (Paso de Álamos)",
            "Paso de la Mina 2da. Sección (Barrial)",
            "Paso de la Mina 3ra. Sección",
            "Paso de Mata",
            "Paso de Méndez",
            "Paso de Núñez (Buenavista)",
            "Paso de Ovejas",
            "Paso de Pirules",
            "Paso de Valencia",
            "Paso del Correo",
            "Paso del Macho",
            "Paso del Toro",
            "Paso Hondo",
            "Paso Morelos",
            "Paso Nacional",
            "Paso Nacional",
            "Paso Nacional",
            "Paso Real de Sarabia",
            "Pasté",
            "Pastelera",
            "Pastor Ortíz",
            "Pastor Rovaix",
            "Pastora",
            "Pastoría",
            "Patambó",
            "Pathé",
            "Patla",
            "Patlicha",
            "Patosil",
            "Patria Nueva",
            "Patria Nueva",
            "Patria Nueva (San José el Contento)",
            "Patuán",
            "Pátzcuaro",
            "Pavencul",
            "Paxtepec",
            "Paxtle",
            "Pechucalco",
            "Pechucalco 2da. Sección (Las Cruces)",
            "Pedernales",
            "Pedregal de San José",
            "Pedregoso",
            "Pedriceña",
            "Pedro Amaro",
            "Pedro Escobedo",
            "Pedro Ibarra Hernández",
            "Pegueros",
            "Pejelagartero 1ra. Sección (Plataforma)",
            "Pemucho",
            "Peña Blanca",
            "Peña Colorada",
            "Peña del Panal",
            "Peña Flores (Palo Verde)",
            "Peñamiller",
            "Pencuyut",
            "Penjamillo de Degollado",
            "Pénjamo",
            "Pénjamo",
            "Peñuela",
            "Peñuelas",
            "Peñuelas (El Cienegal)",
            "Peotillos",
            "Peralta",
            "Pérez de Galeana",
            "Peribán de Ramos",
            "Pericos",
            "Pericos",
            "Pericotepec",
            "Perla de Acapulco",
            "Perote",
            "Perseverancia",
            "Pesqueira",
            "Pesquería",
            "Petacalco",
            "Petalcingo",
            "Petaquillas",
            "Petatlán",
            "Petlalcingo",
            "Peto",
            "Pezmatlán",
            "Piaxtla",
            "Piaxtla de Abajo",
            "Picardías",
            "Pich",
            "Pichátaro",
            "Picholco",
            "Pichucalco",
            "Pie de Gallo",
            "Piedra Blanca",
            "Piedra de Amolar",
            "Piedra Gorda",
            "Piedra Labrada",
            "Piedra Parada",
            "Piedra Pesada",
            "Piedra Pinta",
            "Piedras de Lumbre",
            "Piedras Negras",
            "Piedras Negras",
            "Piedras Negras",
            "Pihuamo",
            "Pijijiapan",
            "Pilcaya",
            "Piletas",
            "Pilotos",
            "Pinal de Amoles",
            "Piñícuaro",
            "Pino Suárez",
            "Pinos",
            "Pinotepa de Don Luis",
            "Pinotepa Nacional",
            "Pintillo",
            "Pinzándaro",
            "Pisaflores",
            "Pisaflores",
            "Piscila",
            "Piste",
            "Pitahayal",
            "Pitiquito",
            "Pixoy",
            "Placeres del Oro",
            "Plan de Agua Prieta",
            "Plan de Arroyos",
            "Plan de Ayala",
            "Plan de Ayala",
            "Plan de Ayala",
            "Plan de Ayala",
            "Plan de Ayala (Campo Cinco)",
            "Plan de Iguala",
            "Plan de las Hayas",
            "Plan del Río",
            "Plan Guanajuato (La Sandía)",
            "Plancarte",
            "Platanillo",
            "Plateros",
            "Platón Sánchez",
            "Playa Azul",
            "Playa Chica",
            "Playa de Chachalacas",
            "Playa de la Libertad",
            "Playa del Carmen",
            "Playa Vicente",
            "Playas de Rosarito",
            "Playas del Rosario",
            "Plaza Nueva",
            "Pluma Hidalgo",
            "Plutarco Elías Calles (La Y Griega)",
            "Poblado 10",
            "Poblado C-11 José María Morelos y Pavón",
            "Poblado C-21 Licenciado Benito Juárez García",
            "Poblado C-33 20 de Noviembre",
            "Poblado Chulavista (El Chorizo)",
            "Poblado Cinco",
            "Poblado Dos",
            "Poblado Lázaro Cárdenas (La Veintiocho)",
            "Poblado Número Cinco",
            "Poblado Tres",
            "Pocboc",
            "Pochahuizco",
            "Pochálcatl",
            "Pochotillo",
            "Pochotita",
            "Pochutla",
            "Pocitos",
            "Polanco",
            "Polígonos",
            "Pololcingo",
            "Polotitlán",
            "Polotitlán de la Ilustración",
            "Polutla",
            "Polvillos (San Bartolo Quinta Sección)",
            "Polyuc",
            "Pomacuarán",
            "Pomoca",
            "Pomposo Castellanos",
            "Pomuch",
            "Ponciano Arriaga",
            "Poncitlán Municipality",
            "Popo Park",
            "Popola",
            "Popolnah",
            "Porfirío Díaz",
            "Portaceli",
            "Portal de las Salinas",
            "Portes Gil",
            "Portezuelo",
            "Portezuelo",
            "Pórticos de San Antonio",
            "Portugués de Gálvez",
            "Porvenir",
            "Potam",
            "Pothé",
            "Potrerillo",
            "Potrerillo",
            "Potrerillo del Norote",
            "Potrerillos",
            "Potrerillos (Guanajal)",
            "Potrero de los Sánchez (Estación Techa)",
            "Potrero del Llano",
            "Potrero Viejo",
            "Potreros",
            "Poturo",
            "Poza Rica de Hidalgo",
            "Pozo de Ibarra",
            "Pozo de Jarillas",
            "Pozo Dulce",
            "Pozos",
            "Pozos de Gamboa",
            "Pozuelos",
            "Pozuelos",
            "Praderas de San Francisco",
            "Praderas del Potrero",
            "Prados de San Juan",
            "Prados de Santa Rosa",
            "Prados del Rosario",
            "Prados San Francisco",
            "Praxedis de Guerrero",
            "Praxedis G. Guerrero",
            "Praxédis Guerrero",
            "Presa Blanca",
            "Presa de Rayas",
            "Presa del Rosario",
            "Presas",
            "Presidente Echeverría (Laja Tendida)",
            "Presidente Juárez",
            "Presidio",
            "Presidio (Plan de Libres)",
            "PRI Chacón",
            "Primer Cantón",
            "Primera de Analco",
            "Primera Fracción de Crespo",
            "Primero de Mayo",
            "Primero de Mayo",
            "Primero de Mayo",
            "Primero de Mayo",
            "Primero de Mayo",
            "Primero de Mayo",
            "Primero de Mayo (Chupaderos)",
            "Primo Tapia",
            "Privada del Álamo",
            "Profesor Carlos Hank González",
            "Profresor Roberto Barrios",
            "Progreso",
            "Progreso",
            "Progreso",
            "Progreso",
            "Progreso",
            "Progreso",
            "Progreso",
            "Progreso",
            "Progreso (Campo 47)",
            "Progreso de Alvaro Obregon",
            "Progreso de Juárez",
            "Progreso de Zaragoza",
            "Progreso Industrial",
            "Providencia",
            "Providencia",
            "Providencia de Nápoles",
            "Puácuaro",
            "Pucté",
            "Puebla",
            "Puebla",
            "Puebla",
            "Pueblillo",
            "Pueblito de Allende",
            "Pueblo Benito García",
            "Pueblo Hidalgo",
            "Pueblo Hidalgo",
            "Pueblo Juárez (La Magdalena)",
            "Pueblo Madero (El Playón)",
            "Pueblo Mayo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Pueblo Nuevo de las Raíces",
            "Pueblo Nuevo de Morelos",
            "Pueblo Nuevo Tlalmimilolpan",
            "Pueblo Viejo",
            "Pueblo Viejo",
            "Pueblo Viejo",
            "Pueblo Viejo",
            "Pueblo Viejo",
            "Pueblo Viejo",
            "Pueblo Yaqui",
            "Pueblos Unidos",
            "Puente de Camotlán",
            "Puente de Ixtla",
            "Puente de Piedra",
            "Puente Grande",
            "Puente Jula",
            "Puentecillas",
            "Puentecillas",
            "Puentecillas (Tercera Manzana de Zirahuato)",
            "Puerta de Mangos",
            "Puerta de Palmillas",
            "Puerta del Llano",
            "Puerta del Monte",
            "Puertecito de la Virgen",
            "Puerto Adolfo Lopez Mateos",
            "Puerto Ángel",
            "Puerto Aventuras",
            "Puerto Ceiba",
            "Puerto de Aguirre",
            "Puerto de Buenavista (Lázaro Cárdenas)",
            "Puerto de Carroza",
            "Puerto de Chiquihuite",
            "Puerto de Nieto",
            "Puerto de Providencia",
            "Puerto Escondido",
            "Puerto Escondido (Tepeolulco Puerto Escondido)",
            "Puerto Esmeralda",
            "Puerto Genovevo Santiago",
            "Puerto Madero",
            "Puerto Palomas",
            "Puerto Peñasco",
            "Puerto San Carlos",
            "Puerto Vallarta",
            "Puga",
            "Pugchén Mumuntic",
            "Pujal-Coy",
            "Pungarabato",
            "Punta Arena",
            "Punta de la Vega",
            "Puntilla Aldama",
            "Purechucho",
            "Purépero de Echáiz",
            "Purificación",
            "Purísima",
            "Purísima Concepción Mayorazgo",
            "Purísima de Arista",
            "Purísima de Bustos",
            "Purísima de Covarrubias",
            "Purísima de Cubos (La Purísima)",
            "Purísima del Progreso",
            "Purísima del Rincón",
            "Puroagua",
            "Puruándiro",
            "Puruarán",
            "Pustunich",
            "Putla Villa de Guerrero",
            "Putlunichuchut (Vista Hermosa)",
            "Puxcatán",
            "Puxtla",
            "Quamila",
            "Quebrantadero",
            "Quecholac",
            "Quechulac",
            "Quechultenango",
            "Quelové",
            "Queréndaro",
            "Querendas",
            "Querétaro",
            "Querétaro",
            "Querobabi",
            "Quesería",
            "Quetchehueca",
            "Quetzalapa",
            "Quetzalapa",
            "Quiamoloapan",
            "Quila",
            "Quimichis",
            "Quinceo",
            "Quinicuena",
            "Quinta del Cedro",
            "Quintana Roo",
            "Quintana Roo",
            "Quintero",
            "Quintín Arauz",
            "Quinto Barrio (Ejido Cahuacán)",
            "Quiriego",
            "Quiroga",
            "Quitupan",
            "Rafael Delgado",
            "Rafael J. García",
            "Rafael Lucio",
            "Rafael Pascacio Gamboa",
            "Rafael Ramírez",
            "Rafael Yáñez Sosa (El Mezquite)",
            "Ramírez",
            "Ramón Corona",
            "Ramón F. Balboa",
            "Ramos Arizpe",
            "Ranchería de Pocitos",
            "Ranchito de Castro",
            "Rancho Alegre",
            "Rancho Alegre",
            "Rancho Alegre",
            "Rancho Alegre [Fraccionamiento]",
            "Rancho Bellavista [Fraccionamiento]",
            "Rancho Chico",
            "Rancho de Guadalupe",
            "Rancho de las Lomas",
            "Rancho del Padre",
            "Rancho Grande",
            "Rancho La Gloria",
            "Rancho Nuevo",
            "Rancho Nuevo",
            "Rancho Nuevo",
            "Rancho Nuevo",
            "Rancho Nuevo",
            "Rancho Nuevo de la Cruz",
            "Rancho Nuevo de la Luz",
            "Rancho Nuevo de Morelos (De Guadalupe)",
            "Rancho Nuevo del Llanito",
            "Rancho Verde",
            "Rancho Viejo",
            "Rancho Viejo",
            "Rancho Viejo",
            "Rancho Viejo",
            "Rancho Viejo",
            "Rancho Viejo (Rancho Nuevo de la Democracia)",
            "Rascón",
            "Raudales Malpaso",
            "Raya Zaragoza",
            "Raymundo Enríquez",
            "Rayón",
            "Rayón",
            "Rayón",
            "Rayones",
            "Real de Catorce",
            "Real del Sol",
            "Real del Valle (El Paraíso) [Fraccionamiento]",
            "Real Hacienda (Metrópolis)",
            "Recoveco",
            "Reforma",
            "Reforma",
            "Reforma de Pineda",
            "Reforma y Planada",
            "Residencial Arboledas",
            "Residencial las Olas",
            "Residencial Tajín",
            "Revolución Mexicana",
            "Reyes Acozac",
            "Reyes Etla",
            "Reyes Mantecón",
            "Reynosa",
            "Ricardo Flores Magón",
            "Ricardo Flores Magón",
            "Ricardo Flores Magón",
            "Ricardo Flores Magón",
            "Ricardo Flores Magón",
            "Ricardo Flores Magón",
            "Rincón Chamula",
            "Rincón de Aguirre",
            "Rincón de Barrabás",
            "Rincón de Buena Vista",
            "Rincón de Cano",
            "Rincón de Cedeños (Rincón de Dolores)",
            "Rincón de Curungueo",
            "Rincón de Guadalupe",
            "Rincón de Guayabitos",
            "Rincón de Jaimes",
            "Rincón de López",
            "Rincón de los Pirules",
            "Rincón de los Reyes",
            "Rincón de Nicolás Romero (Cedros Tercera Manzana)",
            "Rincón de Parangueo",
            "Rincón de Romos",
            "Rincón de Tamayo",
            "Rincón del Centeno",
            "Rincón del Porvenir",
            "Rincón Moreno",
            "Rincón Verde",
            "Rinconada",
            "Rinconada",
            "Rinconada de los Ángeles",
            "Rinconada la Loma [Fraccionamiento]",
            "Rinconadas de San Francisco",
            "Rinconadas del Bosque",
            "Rinconadas del Venado I",
            "Rincones de la Hacienda",
            "Río Blanco",
            "Río Blanco",
            "Río Bravo",
            "Río Bravo",
            "Río Chancalá",
            "Río Chiquito",
            "Rio de la Soledad",
            "Rio de Medina",
            "Río de Teapa",
            "Río Florido",
            "Río Frío de Juárez",
            "Río Grande",
            "Río Grande",
            "Río Grande",
            "Río Jordán",
            "Río Lagartos",
            "Río Laja",
            "Río Medio [Granja]",
            "Río Pachiñe",
            "Río Seco 2da. Sección (Santiaguito)",
            "Río Seco Puente de Doria",
            "Río Verde",
            "Río Viejo Primera Sección",
            "Rioverde",
            "Riva Palacio",
            "Rizo de Oro",
            "Rizos de la Joya (Rizos del Saucillo)",
            "Roblada Grande",
            "Rodeo",
            "Rodolfo Sánchez Taboada",
            "Rodrigo",
            "Rodrígo M. Quevedo",
            "Rodríguez Tejeda",
            "Rodulfo Figueroa",
            "Rojas de Cuauhtémoc",
            "Romerillo",
            "Romero de Guzmán",
            "Romero de Torres",
            "Romita",
            "Roque",
            "Rosa Blanca",
            "Rosales",
            "Rosales",
            "Rosamorada",
            "Rosario",
            "Rosario",
            "Rosarito",
            "Rubén Carlos Gallegos Reyes Villaldama",
            "Ruíz",
            "Rumorosa",
            "Saban",
            "Sabanas de Xalostoc",
            "Sabancuy",
            "Sabaneta",
            "Sabanilla",
            "Sabanillas",
            "Sabidos",
            "Sabinalito",
            "Sabinas",
            "Sabinas Hidalgo",
            "Sacalum",
            "Saclamantón",
            "Sacramento",
            "Sahcaba",
            "Sahuaral de Otero",
            "Sahuaripa",
            "Sahuayo",
            "Sahuayo de Morelos",
            "Sain Alto",
            "Sain Bajo",
            "Saladero",
            "Salado",
            "Salamanca",
            "Salazar",
            "Saldarriaga",
            "Salguero",
            "Salina Cruz",
            "Salinas",
            "Salinas de Hidalgo",
            "Salinas Victoria",
            "Salitral de Carrera",
            "Salitrillo",
            "Salitrillo",
            "Salitrillo",
            "Saloya 2da. Sección",
            "Saltabarranca",
            "Saltillo",
            "Saltillo",
            "Saltillo",
            "Saltillo",
            "Salto de Agua",
            "Salvador Díaz Mirón",
            "Salvador Rosas Magallón",
            "Salvador Urbina",
            "Salvatierra",
            "Samachique",
            "Samahil",
            "Samalayuca",
            "Samarkanda",
            "San Acateno",
            "San Agustín",
            "San Agustín",
            "San Agustín",
            "San Agustín Amatengo",
            "San Agustín Atenango",
            "San Agustín Atzompa",
            "San Agustín Berros",
            "San Agustín Buenavista",
            "San Agustín Chayuco",
            "San Agustín Citlali",
            "San Agustín de las Flores",
            "San Agustin de las Juntas",
            "San Agustín de las Palmas",
            "San Agustín del Maíz",
            "San Agustín del Palmar",
            "San Agustín del Pulque",
            "San Agustín Etla",
            "San Agustín Huitzizilapan",
            "San Agustín Huixaxtla",
            "San Agustín Loxicha",
            "San Agustín Mextepec",
            "San Agustín Mimbres",
            "San Agustín Oapan",
            "San Agustín Tlaxco",
            "San Agustín Tlaxiaca",
            "San Agustín Yatareni",
            "San Agustín Zapotlán",
            "San Alejo",
            "San Andrés",
            "San Andrés",
            "San Andrés",
            "San Andrés (San Andrés Chichayotla)",
            "San Andrés Ahuashuatepec",
            "San Andrés Azumiatla",
            "San Andrés Cacaloapan",
            "San Andrés Calpan",
            "San Andrés Chicahuaxtla",
            "San Andrés Cholula",
            "San Andrés Cohamiata",
            "San Andrés Coru",
            "San Andrés Cuexcontitlán",
            "San Andrés de la Cal",
            "San Andrés de las Peras",
            "San Andrés de los Gama",
            "San Andrés del Pedregal",
            "San Andrés Dinicuiti",
            "San Andrés Duraznal",
            "San Andrés Enguaro",
            "San Andrés Hidalgo",
            "San Andrés Huaxpaltepec",
            "San Andrés Huayápam",
            "San Andrés Hueyacatitla",
            "San Andrés Ixtlahuaca",
            "San Andrés Ixtlán",
            "San Andrés Metla",
            "San Andrés Mixquic",
            "San Andrés Nicolás Bravo",
            "San Andrés Ocotepec",
            "San Andrés Ocotlán",
            "San Andrés Paxtlán",
            "San Andrés Payuca",
            "San Andrés Playa Encantada (El Podrido)",
            "San Andrés Sinaxtla",
            "San Andrés Solaga",
            "San Andrés Tenejapan",
            "San Andrés Teotilalpam",
            "San Andrés Tepetitlán",
            "San Andrés Timilpan",
            "San Andrés Tlalamac",
            "San Andrés Tuxtla",
            "San Andrés Yaá",
            "San Andrés Yahuitlalpan",
            "San Andrés Zabache",
            "San Andrés Zautla",
            "San Andrés Ziróndaro",
            "San Ángel",
            "San Ángel",
            "San Angel",
            "San Antonino Castillo Velasco",
            "San Antonino el Alto",
            "San Antonino Monte Verde",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio Acahualco",
            "San Antonio Alpanocan",
            "San Antonio Arrazola",
            "San Antonio Atotonilco",
            "San Antonio Bonixi",
            "San Antonio Buenavista",
            "San Antonio Buenavista",
            "San Antonio Bulujib",
            "San Antonio Calichar",
            "San Antonio Cañada",
            "San Antonio Cárdenas",
            "San Antonio Chiverías",
            "San Antonio Coyahuacán",
            "San Antonio de Alamitos",
            "San Antonio de Corrales",
            "San Antonio de la Cal",
            "San Antonio de la Cal",
            "San Antonio de la Laguna",
            "San Antonio de las Alazanas",
            "San Antonio de las Huertas",
            "San Antonio de las Huertas",
            "San Antonio de las Palmas",
            "San Antonio de los Barrera",
            "San Antonio de los Horcones",
            "San Antonio de los Morales",
            "San Antonio de los Ríos",
            "San Antonio de los Tepetates",
            "San Antonio de los Vázquez",
            "San Antonio de Peñuelas",
            "San Antonio de Rivas",
            "San Antonio de Romerillo",
            "San Antonio del Cipres",
            "San Antonio del Coyote",
            "San Antonio del Monte",
            "San Antonio del Puente",
            "San Antonio del Rosario",
            "San Antonio el Chico",
            "San Antonio el Grande",
            "San Antonio el Llanito",
            "San Antonio el Paso",
            "San Antonio el Rico",
            "San Antonio Eménguaro",
            "San Antonio Enchisi",
            "San Antonio Gallardo",
            "San Antonio Guaracha",
            "San Antonio Huitepec",
            "San Antonio Juárez",
            "San Antonio la Isla",
            "San Antonio la Labor",
            "San Antonio la Portilla",
            "San Antonio las Palmas",
            "San Antonio Matlahuacales",
            "San Antonio Mihuacán",
            "San Antonio Molinos",
            "San Antonio Nixini",
            "San Antonio Ocopetlatlán",
            "San Antonio Ocotlán",
            "San Antonio Portezuelo",
            "San Antonio Primero",
            "San Antonio Rayón",
            "San Antonio Sahcabchén",
            "San Antonio Soledad",
            "San Antonio Solís",
            "San Antonio Tariácuri",
            "San Antonio Tecolco",
            "San Antonio Tecómitl",
            "San Antonio Tedzidz",
            "San Antonio Tepetlapa",
            "San Antonio Texas",
            "San Antonio Tlacamilco",
            "San Antonio Tlaltecahuacán",
            "San Antonio Tlatenco",
            "San Antonio Villalongín",
            "San Antonio Virreyes",
            "San Antonio Xahuento",
            "San Antonio Xoquitla",
            "San Antonio Zaragoza",
            "San Atenógenes (La Villita)",
            "San Baltazar Atlimeyaya",
            "San Baltazar Chichicapam",
            "San Baltazar Guelavila",
            "San Baltazar Loxicha",
            "San Baltazar Yatzachi el Bajo",
            "San Bartolito Tlaltelolco",
            "San Bartolo",
            "San Bartolo",
            "San Bartolo Coyotepec",
            "San Bartolo Coyotespec",
            "San Bartolo Cuautlalpan",
            "San Bartolo Cuitareo",
            "San Bartolo de Berrios",
            "San Bartolo del Llano",
            "San Bartolo del Llano (San Isidro)",
            "San Bartolo del Progreso",
            "San Bartolo el Viejo",
            "San Bartolo Lanzados",
            "San Bartolo Morelos",
            "San Bartolo Oxtotitlán",
            "San Bartolo Ozocalpan",
            "San Bartolo Tutotepec",
            "San Bartolo Yautepec",
            "San Bartolomé",
            "San Bartolomé",
            "San Bartolomé Aguas Calientes",
            "San Bartolomé Atlatlahuca",
            "San Bartolomé Ayautla",
            "San Bartolomé Coatepec",
            "San Bartolomé Cuahuixmatlac",
            "San Bartolomé Hueyapan",
            "San Bartolomé Loxícha",
            "San Bartolomé Quialana",
            "San Bartolomé Tenango",
            "San Bartolomé Tlaltelulco",
            "San Bartolome Xicomulco",
            "San Benito",
            "San Benito Encinal",
            "San Benito Xaltocan",
            "San Bernabé de las Canteras",
            "San Bernabé Temoxtitla",
            "San Bernardino",
            "San Bernardino de Milpillas Chico",
            "San Bernardino Lagunas",
            "San Bernardino Tlaxcalancingo",
            "San Bernardo",
            "San Bernardo",
            "San Bernardo",
            "San Bernardo Mixtepec",
            "San Bernardo Nuevo León",
            "San Bernardo Peña Blanca",
            "San Bernardo Tlalmimilolpan",
            "San Blas",
            "San Blas",
            "San Blas",
            "San Blas Atempa",
            "San Buenaventura",
            "San Buenaventura",
            "San Buenaventura",
            "San Buenaventura",
            "San Buenaventura",
            "San Buenaventura Atempan",
            "San Buenaventura Tecaltzingo",
            "San Caralampio",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos Autopan",
            "San Carlos Yautepec",
            "San Cayetano",
            "San Cayetano",
            "San Cayetano",
            "San Ciro de Acosta",
            "San Clemente",
            "San Clemente",
            "San Clemente de Lima",
            "San Cosme Atlamaxac",
            "San Cristóbal",
            "San Cristóbal",
            "San Cristóbal",
            "San Cristóbal",
            "San Cristóbal",
            "San Cristóbal Amatlán",
            "San Cristóbal Amoltepec",
            "San Cristóbal Chacón",
            "San Cristóbal de la Barranca Municipality",
            "San Cristóbal de las Casas",
            "San Cristóbal Hidalgo",
            "San Cristóbal Honduras",
            "San Cristóbal Lachirioag",
            "San Cristóbal los Nava",
            "San Cristóbal Nexquipayac",
            "San Cristóbal Xochimilpa",
            "San Cristóbal Zapotitlán",
            "San Damián Texoloc",
            "San Diego",
            "San Diego",
            "San Diego",
            "San Diego",
            "San Diego",
            "San Diego",
            "San Diego Alcalá",
            "San Diego de Alcalá",
            "San Diego de Alejandría",
            "San Diego de la Unión",
            "San Diego del Cerrito",
            "San Diego el Organal",
            "San Diego Huehuecalco",
            "San Diego la Huerta",
            "San Diego la Mesa Tochimiltzingo",
            "San Diego Linares",
            "San Diego los Padres Cuexcontitlán Sección 5 B",
            "San Dionisio del Mar",
            "San Dionisio Ocotepec",
            "San Dionisio Ocotlán",
            "San Elías",
            "San Esteban",
            "San Esteban (San Miguel Tateposco)",
            "San Esteban Atatlahuca",
            "San Esteban de Abajo",
            "San Esteban Tizatlán",
            "San Felipe",
            "San Felipe",
            "San Felipe",
            "San Felipe Aztatán",
            "San Felipe Cihualtepec",
            "San Felipe Coamango",
            "San Felipe de Híjar",
            "San Felipe de los Herreros",
            "San Felipe del Progreso",
            "San Felipe el Mirasol",
            "San Felipe Hidalgo",
            "San Felipe Jalapa de Díaz",
            "San Felipe los Alzati (Colonia Nueva)",
            "San Felipe Orizatlán",
            "San Felipe Otlaltepec",
            "San Felipe Pueblo Nuevo",
            "San Felipe Quiriceo",
            "San Felipe Santiago",
            "San Felipe Sultepec",
            "San Felipe Tejalápam",
            "San Felipe Teotitlán",
            "San Felipe Tílpam",
            "San Felipe Tizapa",
            "San Felipe Tlalmimilolpan",
            "San Felipe Usila",
            "San Félix Hidalgo",
            "San Félix Rijo",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco (Baños de Agua Caliente)",
            "San Francisco (El Calvito)",
            "San Francisco (Mata Clara)",
            "San Francisco Acuautla",
            "San Francisco Atotonilco",
            "San Francisco Ayotuzco",
            "San Francisco Cajonos",
            "San Francisco Chalchihuapan",
            "San Francisco Chejé",
            "San Francisco Chimalpa",
            "San Francisco Chindúa",
            "San Francisco Coatlán",
            "San Francisco Cozoaltepec",
            "San Francisco Cuapa",
            "San Francisco Cuautlancingo",
            "San Francisco Cuaxusco",
            "San Francisco Cuayalab",
            "San Francisco Cuexcontzi",
            "San Francisco de Arriba",
            "San Francisco de Asís",
            "San Francisco de Borja",
            "San Francisco de Conchos",
            "San Francisco de Durán",
            "San Francisco de Guzmán",
            "San Francisco de Horizonte (Horizonte)",
            "San Francisco de la Palma",
            "San Francisco de los Romo",
            "San Francisco de Rivas",
            "San Francisco de Tacuichamona",
            "San Francisco del Mar",
            "San Francisco del Mar Viejo",
            "San Francisco del Mezquital",
            "San Francisco del Oro",
            "San Francisco del Rincón",
            "San Francisco Grande",
            "San Francisco Huehuetlán",
            "San Francisco Independencia (Santa María Aserradero)",
            "San Francisco Ixhuatan",
            "San Francisco Jaconá",
            "San Francisco Jaltepetongo",
            "San Francisco Javier",
            "San Francisco Javier",
            "San Francisco Kobén",
            "San Francisco Lachigoló",
            "San Francisco Logueche",
            "San Francisco Loxicha",
            "San Francisco Magú",
            "San Francisco Mazapa",
            "San Francisco Mihualtepec",
            "San Francisco Nacaxtle",
            "San Francisco Nuxaño",
            "San Francisco Ocotlán",
            "San Francisco Oxtotilpan",
            "San Francisco Oxtutla",
            "San Francisco Ozolotepec",
            "San Francisco Ozomatlán",
            "San Francisco Peribán",
            "San Francisco Pujiltic",
            "San Francisco Putla",
            "San Francisco Sola",
            "San Francisco Solis",
            "San Francisco Tecoxpa",
            "San Francisco Telixtlahuaca",
            "San Francisco Temetzontla",
            "San Francisco Tenopalco",
            "San Francisco Tepeolulco",
            "San Francisco Tepexoxica",
            "San Francisco Tepeyecac",
            "San Francisco Tetetla",
            "San Francisco Tlacuilohcan",
            "San Francisco Tlalcilalcalpan",
            "San Francisco Tláloc",
            "San Francisco Tlaltica",
            "San Francisco Tutla",
            "San Francisco Xochicuautla",
            "San Francisco Zacacalco",
            "San Francisco Zacapexpan",
            "San Gabriel",
            "San Gabriel",
            "San Gabriel  Jalisco",
            "San Gabriel Azteca",
            "San Gabriel Chilac",
            "San Gabriel Ixtla",
            "San Gabriel las Palmas",
            "San Gabriel Mixtepec",
            "San Gabriel Tetzoyocán",
            "San Gabriel y San Ignacio",
            "San Gabriel Zepayautla",
            "San Gaspar",
            "San Gaspar",
            "San Gaspar de los Reyes",
            "San Gaspar Tlahuelilpan",
            "San Gaspar Tonatico",
            "San Germán",
            "San Gregorio",
            "San Gregorio",
            "San Gregorio",
            "San Gregorio",
            "San Gregorio Atzompa",
            "San Gregorio Aztotoacan",
            "San Gregorio Cuautzingo",
            "San Hipólito",
            "San Hipólito Chimalpa",
            "San Ignacio",
            "San Ignacio",
            "San Ignacio",
            "San Ignacio",
            "San Ignacio",
            "San Ignacio de Hidalgo",
            "San Ignacio de Rivera (Ojo de Agua)",
            "San Ignacio de San José Parangueo",
            "San Ignacio Municipality Jalisco",
            "San Ignacio Nopala",
            "San Ignacio Río Muerto",
            "San Ildefonso",
            "San Ildefonso",
            "San Ildefonso",
            "San Ildefonso Amatlán",
            "San Ildefonso Tultepec",
            "San Ildefonso Villa Alta",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro (El Reservado)",
            "San Isidro Alta Huerta",
            "San Isidro Apango",
            "San Isidro Boxipe",
            "San Isidro Buen Suceso",
            "San Isidro Buenavista",
            "San Isidro de Gamboa",
            "San Isidro de la Concepción",
            "San Isidro de la Estacada",
            "San Isidro de los Sauces",
            "San Isidro el Berro",
            "San Isidro Gallinero (El Gallinero)",
            "San Isidro Huilotepec",
            "San Isidro las Banderas",
            "San Isidro Mazatepec",
            "San Isidro Miranda",
            "San Isidro Monjas",
            "San Isidro Monterrosas",
            "San Isidro Palotal",
            "San Isidro Vista Hermosa",
            "San Isidro Xoteapan (San Isidro Texcaltitán)",
            "San Jacintito",
            "San Jacinto",
            "San Jacinto",
            "San Jacinto",
            "San Jacinto Amilpas",
            "San Jacinto Jalisco",
            "San Javier",
            "San Javier",
            "San Jeronimito",
            "San Jerónimo",
            "San Jerónimo",
            "San Jerónimo Acazulco",
            "San Jerónimo Almoloya",
            "San Jerónimo Amanalco",
            "San Jerónimo Axochitlán",
            "San Jerónimo Bonchete",
            "San Jerónimo Chicahualco",
            "San Jerónimo Coaltepec",
            "San Jerónimo Coatlán",
            "San Jerónimo Coyula",
            "San Jerónimo Cuatro Vientos",
            "San Jerónimo de Araceo",
            "San Jerónimo de Juárez",
            "San Jerónimo de los Jarros",
            "San Jerónimo el Grande",
            "San Jerónimo Ixtapantongo",
            "San Jerónimo Ocotitla",
            "San Jerónimo Purenchecuaro",
            "San Jerónimo Silacayoapilla",
            "San Jerónimo Sosola",
            "San Jerónimo Taviche",
            "San Jerónimo Tecóatl",
            "San Jerónimo Tlacochahuaya",
            "San Jerónimo Totoltepec",
            "San Jerónimo Tulijá",
            "San Jerónimo Xayacatlán",
            "San Jerónimo Yahuiche",
            "San Jerónimo Zacualpan",
            "San Joaquín",
            "San Joaquín Coapango",
            "San Joaquín del Monte",
            "San Joaquín Zorrillos",
            "San Jorge",
            "San Jorge Nuchita",
            "San Jorge Pueblo Nuevo",
            "San Jorge Tezoquipan",
            "San José",
            "San José",
            "San José",
            "San José Acateno",
            "San José Alchichica",
            "San José Atlán",
            "San José Atoyatenco",
            "San José Ayuquila",
            "San José Aztatla",
            "San José Barbabosa",
            "San José Boqui",
            "San José Boxay",
            "San José Buena Vista",
            "San José Buenavista",
            "San José Buenavista",
            "San José Buenavista el Grande",
            "San José Carpinteros",
            "San José Casas Caídas",
            "San José Chacalapa",
            "San José Chalmita",
            "San José Chapayal",
            "San José Chiapa",
            "San José Chiltepec",
            "San José Comalco",
            "San José Corral Blanco",
            "San José Cuamantzingo",
            "San José Cuaro",
            "San José Cuyachapa",
            "San José de Abajo",
            "San José de Abajo [Unidad Habitacional]",
            "San José de Agua Azul",
            "San José de Aura",
            "San José de Ayala",
            "San José de Bacum",
            "San José de Bernalejo (El Guayabo)",
            "San José de Castellanos",
            "San José de Cervera",
            "San José de Cuatro Caminos",
            "San José de Durán (Los Troncoso)",
            "San José de Gracia",
            "San José de Gracia",
            "San José de Gracia",
            "San José de Gracia",
            "San José de Gracia",
            "San Jose de Gracia Jalisco",
            "San José de Guanajuato",
            "San José de Guaymas",
            "San José de la Era",
            "San José de la Laja",
            "San José de la Montaña",
            "San José de la Paz",
            "San José de las Flores",
            "San José de las Flores",
            "San José de las Pilas",
            "San José de Llanos",
            "San Jose de los Olvera",
            "San José de los Reynoso",
            "San Jose de Lourdes",
            "San José de Medina",
            "San José de Mendoza",
            "San José de Raíces",
            "San José de Rancho Nuevo (Los Arrieros)",
            "San José de Tapia",
            "San José de Viñedo",
            "San José de Zaragoza",
            "San José del Cabo",
            "San José del Caliche (El Caliche)",
            "San José del Castillo",
            "San José del Cerrito de Camargo",
            "San José del Jagüey",
            "San José del Potrero",
            "San José del Progreso",
            "San José del Progreso",
            "San José del Puente",
            "San José del Rincón Centro",
            "San José del Rodeo",
            "San José del Sitio",
            "San José del Tapanco",
            "San José del Torreón",
            "San José del Tunal",
            "San José del Valle",
            "San José el Alto",
            "San José el Cuartel",
            "San José el Llanito",
            "San José el Nuevo",
            "San José el Saladillo (El Saladillo)",
            "San José el Vidrio",
            "San José Estancia Grande",
            "San José Guadalupe Otzacatipan",
            "San José Huipana",
            "San José Independencia",
            "San José Itho",
            "San José Iturbide",
            "San José Ixtapa",
            "San José Ixtapa (Barrio Viejo)",
            "San José la Garzona",
            "San José la Hacienda",
            "San José Lachiguirí",
            "San José las Chicharras",
            "San José las Lomas",
            "San José las Palmas",
            "San José Linares",
            "San José Manzanitos",
            "San José Mezapa Sección I",
            "San José Monte Chiquito",
            "San José Neria",
            "San José Ocotillos",
            "San José Ozumba",
            "San José Pathé",
            "San José Piedras Negras",
            "San José Poliutla",
            "San José Río Manzo",
            "San Jose Solís",
            "San José Súchil",
            "San José Teacalco",
            "San José Tejamanil",
            "San José Temascatío",
            "San José Tenango",
            "San José Tenejapa",
            "San José Tenería (Tenería)",
            "San José Tepeyahualco",
            "San José Tepuzas",
            "San José Tetel",
            "San José Tilapa",
            "San José Tlacotitlán",
            "San José Tlacuitlapan",
            "San José Toxi",
            "San José Tzal",
            "San José Viborillas",
            "San José Villa de Allende",
            "San José Villarreal",
            "San José Vista Hermosa",
            "San José Xicohténcatl",
            "San José Yashitinín",
            "San José Yocnajab",
            "San Juan",
            "San Juan",
            "San Juan Achichilco",
            "San Juan Achiutla",
            "San Juan Ahuehueco",
            "San Juan Ahuehueyo",
            "San Juan Amecac",
            "San Juan Atenco",
            "San Juan Atepec",
            "San Juan Atezcapan",
            "San Juan Atzingo",
            "San Juan Atzompa",
            "San Juan Bautista",
            "San Juan Bautista Cacalote",
            "San Juan Bautista Jayacatlán",
            "San Juan Bautista la Raya",
            "San Juan Bautista Lo de Soto",
            "San Juan Benito Juárez (San Juan Tararameo)",
            "San Juan Cabeza del Río",
            "San Juan Cacahuatepec",
            "San Juan Chilateca",
            "San Juan Coajomulco",
            "San Juan Coatzospam",
            "San Juan Colón",
            "San Juan Colorado",
            "San Juan Cosalá",
            "San Juan Cote Ejido",
            "San Juan Cotzocón",
            "San Juan Coxtocan",
            "San Juan Cuauhtémoc",
            "San Juan Cuautla",
            "San Juan Daxthi",
            "San Juan de Abajo",
            "San Juan de Abajo",
            "San Juan de Dios (Naranjas de Dios)",
            "San Juan de Guadalupe",
            "San Juan de la Vaquería",
            "San Juan de la Vega",
            "San Juan de las Huertas",
            "San Juan de las Manzanas",
            "San Juan de los Arcos",
            "San Juan de los Cues",
            "San Juan de los Jarros",
            "San Juan de los Lagos",
            "San Juan De Los Platanos",
            "San Juan de los Reyes (Luis Valenzuela)",
            "San Juan de Otates",
            "San Juan de Sabinas",
            "San Juan de Viña",
            "San Juan del Estado",
            "San Juan del Llanito",
            "San Juan del Río",
            "San Juan del Río",
            "San Juan del Rio del Centauro del Norte",
            "San Juan Diuxi",
            "San Juan Epatlán",
            "San Juan Evangelista",
            "San Juan Grande",
            "San Juan Guelavía",
            "San Juan Guichicovi",
            "San Juan Hueyapan",
            "San Juan Huiluco",
            "San Juan Ihualtepec",
            "San Juan Ixcaquixtla",
            "San Juan Ixtayopan",
            "San Juan Jalpa Centro",
            "San Juan Jaltepec",
            "San Juan Jaripeo",
            "San Juan Jicayán",
            "San Juan Juquila",
            "San Juan Juquila Vijanos",
            "San Juan la Isla",
            "San Juan Lachao",
            "San Juán Lachigalla",
            "San Juan Lagunas",
            "San Juan Mazatlán",
            "San Juan Metaltepec",
            "San Juan Mixtepec -Dto. 08 -",
            "San Juan Mixtepec -Dto. 26 -",
            "San Juan Ñumí",
            "San Juan Ozolotepec",
            "San Juan Pan de Arriba",
            "San Juan Pancoac",
            "San Juan Petlapa",
            "San Juan Pueblo Nuevo",
            "San Juan Quetzalcoapan",
            "San Juan Quiahue",
            "San Juan Quiotepec",
            "San Juan Raboso",
            "San Juan Sautla",
            "San Juan Sayultepec",
            "San Juan Seco de Valencia",
            "San Juan Solís",
            "San Juan Tabaá",
            "San Juan Tahitic",
            "San Juan Tecomatlán",
            "San Juan Tehuixtitlán",
            "San Juan Teitipac",
            "San Juan Tejaluca",
            "San Juan Tejupa",
            "San Juan Tepa",
            "San Juan Tepecoculco",
            "San Juan Teposcolula",
            "San Juan Tepulco",
            "San Juan Tetla",
            "San Juan Texcalpan",
            "San Juan Tezongo",
            "San Juan Tilapa",
            "San Juan Tilcuautla",
            "San Juan Tizahuapán",
            "San Juan Tlacotompa (Tlacotompa)",
            "San Juan Tomasquillo Herradura",
            "San Juan Totolapan",
            "San Juan Totolcintla",
            "San Juan Tuxco",
            "San Juan Tuxtepec",
            "San Juan Volador",
            "San Juan Xiutetelco",
            "San Juan Xochiaca",
            "San Juan Xoconusco",
            "San Juan y San Pedro Tezompa",
            "San Juan Yaee",
            "San Juan Yatzona",
            "San Juan Yautepec",
            "San Juan Zapotitlán",
            "San Juan Zitlaltepec",
            "San Juanico Sector Uno",
            "San Juanito",
            "San Juanito de Escobedo",
            "San Juanito Itzícuaro",
            "San Julián",
            "San Julián",
            "San Julio",
            "San Leoncio Jamaya",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo Achiotepec",
            "San Lorenzo Acopilco",
            "San Lorenzo Albarradas",
            "San Lorenzo Axocomanitla",
            "San Lorenzo Cacaotepec",
            "San Lorenzo Chiautzingo",
            "San Lorenzo Cuauhtenco",
            "San Lorenzo Cuaunecuiltitla",
            "San Lorenzo Huehuetitlán",
            "San Lorenzo Itzícuaro",
            "San Lorenzo Joya de Rodríguez",
            "San Lorenzo Malacota",
            "San Lorenzo Nenamicoyan",
            "San Lorenzo Oyamel",
            "San Lorenzo Queréndaro",
            "San Lorenzo Sayula",
            "San Lorenzo Sóltepec",
            "San Lorenzo Tenochtitlán",
            "San Lorenzo Tlacotepec",
            "San Lorenzo Tlacoyucan",
            "San Lorenzo Tlalmimilolpan",
            "San Lorenzo Tlaxipehuala",
            "San Lorenzo Toxico",
            "San Lorenzo Victoria",
            "San Lorenzo Vista Hermosa",
            "San Lorenzo Xaltelulco",
            "San Lucas",
            "San Lucas",
            "San Lucas",
            "San Lucas",
            "San Lucas Amalinalco",
            "San Lucas Atzala",
            "San Lucas Camotlán",
            "San Lucas del Pulque",
            "San Lucas el Grande",
            "San Lucas el Viejo",
            "San Lucas Evangelista",
            "San Lucas Huarirapeo (La Mesa de San Lucas)",
            "San Lucas Huitzilhuacán",
            "San Lucas Ocotepec",
            "San Lucas Ojitlán",
            "San Lucas Pío",
            "San Lucas Quiavini",
            "San Lucas Tecopilco",
            "San Lucas Texcaltitlán",
            "San Lucas Tlacochcalco",
            "San Lucas Totolmaloya",
            "San Lucas Zoquiápam",
            "San Luciano",
            "San Luis",
            "San Luis Acatlán",
            "San Luis Ajajalpan",
            "San Luis Amatlán",
            "San Luis Anáhuac (Toriles)",
            "San Luis Apizaquito",
            "San Luis Ayucán",
            "San Luis Boro",
            "San Luis Coyotzingo",
            "San Luis de Agua Caliente",
            "San Luis de La Loma",
            "San Luis de la Paz",
            "San Luis de Letras",
            "San Luis de Lozada",
            "San Luis Potosí",
            "San Luis Río Colorado",
            "San Luis San Pedro",
            "San Luis Soyatlán",
            "San Luis Taxhimay",
            "San Luis Tecuhautitlán",
            "San Luis Tehuiloyocan",
            "San Luqueño",
            "San Marcial Ozolotepec",
            "San Marcos",
            "San Marcos",
            "San Marcos",
            "San Marcos",
            "San Marcos",
            "San Marcos Arteaga",
            "San Marcos Atesquilapan",
            "San Marcos Contla",
            "San Marcos de la Cruz",
            "San Marcos de la Loma",
            "San Marcos de León",
            "San Marcos Eloxochitlán",
            "San Marcos Guaquilpan",
            "San Marcos Huixtoco",
            "San Marcos Nepantla",
            "San Marcos Tecomaxusco",
            "San Marcos Tlacoyalco",
            "San Marcos Tlazalpan",
            "San Marcos Yachihuacaltepec",
            "San Marcos Zacatepec",
            "San Martin",
            "San Martín",
            "San Martín",
            "San Martín",
            "San Martín",
            "San Martín Ahuatepec",
            "San Martín Azcatepec",
            "San Martín Cachihuapan",
            "San Martín Caltenco",
            "San Martín Chalchicuautla",
            "San Martín Coapaxtongo",
            "San Martín Cuautlalpan",
            "San Martín de Bolaños",
            "San Martín de Hidalgo",
            "San Martín de las Pirámides",
            "San Martín de los Canseco",
            "San Martín Ejido",
            "San Martín Esperilla",
            "San Martín Florida",
            "San Martín Itunyoso",
            "San Martín Lachila",
            "San Martín Mazateopan",
            "San Martín Obispo (San Martín San Pedro)",
            "San Martín Ojo de Agua",
            "San Martín Pachivia (Pachivia)",
            "San Martín Peras",
            "San Martín Tequesquipan (Tequesquipan)",
            "San Martín Texmelucan",
            "San Martin Texmelucan de Labastida",
            "San Martín Tilcajete",
            "San Martín Tlamapa",
            "San Martín Tlapala",
            "San Martín Toltepec",
            "San Martín Totolán",
            "San Martín Tuchicuitlapilco",
            "San Mateo",
            "San Mateo",
            "San Mateo Almomoloha",
            "San Mateo Atarasquíllo",
            "San Mateo Atenco",
            "San Mateo Ayecac",
            "San Mateo Cajonos",
            "San Mateo Capulhuac",
            "San Mateo Capultitlán",
            "San Mateo Coapexco",
            "San Mateo del Mar",
            "San Mateo Doctor Arroyo",
            "San Mateo el Viejo",
            "San Mateo Etlatongo",
            "San Mateo Huitzilzingo",
            "San Mateo Ixtacalco",
            "San Mateo Ixtlahuaca",
            "San Mateo Mozoquilpan",
            "San Mateo Nejápam",
            "San Mateo Otzacatipan",
            "San Mateo Ozolco",
            "San Mateo Parra",
            "San Mateo Piñas",
            "San Mateo Sindihui",
            "San Mateo Soltepec",
            "San Mateo Tecalco",
            "San Mateo Texcalyacac",
            "San Mateo Tlalchichilpan",
            "San Mateo Xoloc",
            "San Mateo Yoloxochitlán",
            "San Matías Atzala",
            "San Matías Cuijingo",
            "San Matías Tepetomatitlán",
            "San Matias Tlalancaleca",
            "San Melchor Betaza",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel Abejones",
            "San Miguel Achiutla",
            "San Miguel Agua Bendita",
            "San Miguel Aguacomulican",
            "San Miguel Ajusco",
            "San Miguel Almaya",
            "San Miguel Almoloyan",
            "San Miguel Aloápam",
            "San Miguel Amazcala",
            "San Miguel Ameyalco",
            "San Miguel Analco",
            "San Miguel Atepoxco",
            "San Miguel Atlamajac",
            "San Miguel Atlapulco",
            "San Miguel Axoxuca",
            "San Miguel Balderas",
            "San Miguel Canoa",
            "San Miguel Cerezo (El Cerezo)",
            "San Miguel Chimalapa",
            "San Miguel Coatlán",
            "San Miguel Coatlinchán",
            "San Miguel Contla",
            "San Miguel Cuyutlán",
            "San Miguel de Allende",
            "San Miguel de Cruces",
            "San Miguel de Horcasitas",
            "San Miguel de la Paz",
            "San Miguel de la Sierra Ayutla",
            "San Miguel de La Victoria",
            "San Miguel de las Palmas",
            "San Miguel del Arenal",
            "San Miguel del Centro",
            "San Miguel del Milagro",
            "San Miguel del Progreso",
            "San Miguel del Puerto",
            "San Miguel del Valle",
            "San Miguel Ejutla",
            "San Miguel el Alto",
            "San Miguel el Grande",
            "San Miguel el Grande",
            "San Miguel el Grande Villa Juárez",
            "San Miguel Eménguaro",
            "San Miguel Enyege",
            "San Miguel Epejan",
            "San Miguel Espejo",
            "San Miguel Figueroa",
            "San Miguel Hila",
            "San Miguel Ixitlán",
            "San Miguel Ixtapan",
            "San Miguel Jaltocan",
            "San Miguel la Sardina",
            "San Miguel Laderas",
            "San Miguel Mecatepec",
            "San Miguel Mimlapan",
            "San Miguel Mixtepec",
            "San Miguel Monteverde",
            "San Miguel Ocotenco",
            "San Miguel Octopan",
            "San Miguel Oxtotilpan",
            "San Miguel Panixtlahuaca",
            "San Miguel Papaxtla",
            "San Miguel Peras",
            "San Miguel Quetzaltepec",
            "San Miguel Suchixtepec",
            "San Miguel Tecomatlán",
            "San Miguel Tecomatlán",
            "San Miguel Tecpan",
            "San Miguel Tecuiciapan",
            "San Miguel Tecuitlapa",
            "San Miguel Tenango",
            "San Miguel Tenango",
            "San Miguel Tenextatiloyan",
            "San Miguel Tenochtitlán",
            "San Miguel Tetepelcingo",
            "San Miguel Tianguistenco",
            "San Miguel Tianguizolco",
            "San Miguel Tilquiapam",
            "San Miguel Tlacamama",
            "San Miguel Tlacotepec",
            "San Miguel Tlaixpan",
            "San Miguel Tlalpoalán",
            "San Miguel Tlamahuco",
            "San Miguel Topilejo",
            "San Miguel Totocuitlapilco",
            "San Miguel Totolapan",
            "San Miguel Totoltepec",
            "San Miguel Vindho",
            "San Miguel Xochitecatitla",
            "San Miguel Xoltepec",
            "San Miguel Xoxtla",
            "San Miguel Yuxtepec",
            "San Miguel Zapotitlán",
            "San Miguel Zapotitlan",
            "San Miguel Zoapan",
            "San Miguel Zozutla",
            "San Miguelito",
            "San Miguelito",
            "San Nicolás",
            "San Nicolás",
            "San Nicolás",
            "San Nicolás",
            "San Nicolás",
            "San Nicolás",
            "San Nicolás",
            "San Nicolas",
            "San Nicolás Amealco",
            "San Nicolas Buenos Aires",
            "San Nicolás Coatepec",
            "San Nicolás de la Condesa",
            "San Nicolás de la Torre",
            "San Nicolás de las Flores",
            "San Nicolás de los Agustinos",
            "San Nicolás de los Garza",
            "San Nicolás de los Ranchos",
            "San Nicolás del Cármen",
            "San Nicolás el Chico",
            "San Nicolás el Oro",
            "San Nicolás Guadalupe",
            "San Nicolás Obispo",
            "San Nicolás Parangueo",
            "San Nicolás Peralta",
            "San Nicolás Quialana",
            "San Nicolás Solís",
            "San Nicolás Tecomatlán",
            "San Nicolás Temascatío",
            "San Nicolás Tlazala",
            "San Nicolás Tolentino",
            "San Nicolás Tolentino",
            "San Nicolás Tolentino",
            "San Nicolás Zecalacoayan",
            "San Nicolás Zoyapetlayoca",
            "San Pablito",
            "San Pablo",
            "San Pablo Ahuatempa",
            "San Pablo Anicano",
            "San Pablo Atlazalpan",
            "San Pablo Atotonilco",
            "San Pablo Atzompa",
            "San Pablo Autopan",
            "San Pablo Coatlán",
            "San Pablo Cuatro Venados",
            "San Pablo de las Salinas",
            "San Pablo de las Tunas",
            "San Pablo de los Gallos",
            "San Pablo de los Remedios",
            "San Pablo el Grande",
            "San Pablo Etla",
            "San Pablo Güilá",
            "San Pablo Huacano",
            "San Pablo Huantepec",
            "San Pablo Huitzo",
            "San Pablo Huixtepec",
            "San Pablo Ixayoc",
            "San Pablo Ixquitlán",
            "San Pablo Macuiltianguis",
            "San Pablo Oztotepec",
            "San Pablo Pejo",
            "San Pablo Pixtún",
            "San Pablo Tecalco",
            "San Pablo Tejalpa",
            "San Pablo Tolimán",
            "San Pablo Villa de Mitla",
            "San Patricio Jalisco",
            "San Pedro",
            "San Pedro",
            "San Pedro Abajo",
            "San Pedro Amuzgos",
            "San Pedro Apatlaco",
            "San Pedro Apóstol",
            "San Pedro Arriba",
            "San Pedro Arriba 3ra. Sección",
            "San Pedro Atlapulco",
            "San Pedro Atmatla",
            "San Pedro Atocpan",
            "San Pedro Atoyac",
            "San Pedro Benito Juárez",
            "San Pedro Buenavista",
            "San Pedro Cacahuatepec",
            "San Pedro Chiautzingo",
            "San Pedro Chimay",
            "San Pedro Chochula",
            "San Pedro Cholula",
            "San Pedro Cholula",
            "San Pedro Comitancillo",
            "San Pedro Coxcaltepec Cántaros",
            "San Pedro Coyutla",
            "San Pedro Cuayuca",
            "San Pedro Cuitlapan",
            "San Pedro de Ibarra",
            "San Pedro de la Cueva",
            "San Pedro de la Hortaliza (Ejido Almoloyán)",
            "San Pedro de los Baños",
            "San Pedro de los Metates",
            "San Pedro de los Naranjos",
            "San Pedro de los Sauces",
            "San Pedro del Gallo",
            "San Pedro del Rincón",
            "San Pedro del Rosal",
            "San Pedro Denxhi Centro",
            "San Pedro Ecatepec",
            "San Pedro el Alto",
            "San Pedro el Alto",
            "San Pedro Garza Garcia",
            "San Pedro Guasave",
            "San Pedro Huamelula",
            "San Pedro Huaquilpan",
            "San Pedro Huilotepec",
            "San Pedro Huitzapula Norte (Huitzapula Norte)",
            "San Pedro Huitzizilapan",
            "San Pedro Itzicán",
            "San Pedro Itztla",
            "San Pedro Ixcatlán",
            "San Pedro Ixtlahuaca",
            "San Pedro Jácuaro",
            "San Pedro Jicayán",
            "San Pedro Juchaltengo",
            "San Pedro la Joya",
            "San Pedro Lagunillas",
            "San Pedro las Playas",
            "San Pedro Limón",
            "San Pedro Los Baños",
            "San Pedro Martir",
            "San Pedro Mártir",
            "San Pedro Mártir",
            "San Pedro Mártir Quiechapa",
            "San Pedro Mártir Yucuxaco",
            "San Pedro Matamoros",
            "San Pedro Mixtepec",
            "San Pedro Muñoztla",
            "San Pedro Nexapa",
            "San Pedro Nichtalucum",
            "San Pedro Ocopetatillo",
            "San Pedro Ocotepec",
            "San Pedro Ozumacín",
            "San Pedro Petlacotla",
            "San Pedro Piedra Gorda",
            "San Pedro Pochutla",
            "San Pedro Quiatoni",
            "San Pedro Río Mayo",
            "San Pedro Sochiápam",
            "San Pedro Tapanatepec",
            "San Pedro Tarímbaro",
            "San Pedro Taviche",
            "San Pedro Techuchulco",
            "San Pedro Tejalpa",
            "San Pedro Temamatla",
            "San Pedro Tenango",
            "San Pedro Tenayac",
            "San Pedro Tepetitlán",
            "San Pedro Tetitlán",
            "San Pedro Teutila",
            "San Pedro Tidaá",
            "San Pedro Tlachichilco",
            "San Pedro Tlacotepec",
            "San Pedro Tlaltizapan",
            "San Pedro Tlanixco",
            "San Pedro Tlaolantongo",
            "San Pedro Tlapacoyan",
            "San Pedro Tlaquilpan",
            "San Pedro Topiltepec",
            "San Pedro Totolápam",
            "San Pedro Totoltepec",
            "San Pedro Tulixtlahuaca",
            "San Pedro Tultepec",
            "San Pedro Xalcaltzinco",
            "San Pedro Xalpa",
            "San Pedro Xochiteotla",
            "San Pedro y San Pablo Teposcolula",
            "San Pedro y San Pablo Tequistepec",
            "San Pedro Yancuitlalpan",
            "San Pedro Yeloixtlahuaca",
            "San Pedro Yólox",
            "San Pedro Zacachimalpa",
            "San Pedro Zictepec",
            "San Quintín",
            "San Quintín",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael Calería",
            "San Rafael Ixtapalucan",
            "San Rafael Río Seco",
            "San Rafael Tecario",
            "San Rafael Tenanyecac",
            "San Rafael Tepatlaxco",
            "San Rafael Tlanalapan",
            "San Rafael Zaragoza",
            "San Raymundo Jalpam",
            "San Roque",
            "San Roque",
            "San Roque",
            "San Roque de Montes",
            "San Roque de Torres",
            "San Salvador",
            "San Salvador (Naranjillo)",
            "San Salvador Atenco",
            "San Salvador Cuauhtenco",
            "San Salvador El Seco",
            "San Salvador el Verde",
            "San Salvador Tizatlalli",
            "San Salvador Torrecillas",
            "San Sebastian",
            "San Sebastián",
            "San Sebastián",
            "San Sebastián",
            "San Sebastián",
            "San Sebastián",
            "San Sebastián [Fraccionamiento]",
            "San Sebastián Abasolo",
            "San Sebastián Alcomunga",
            "San Sebastián Buenos Aires",
            "San Sebastián Chimalpa",
            "San Sebastián Coatlán",
            "San Sebastián de Aparicio",
            "San Sebastián de Las Barrancas Sur",
            "San Sebastián de Salitre",
            "San Sebastián del Monte",
            "San Sebastián del Oeste",
            "San Sebastián Etla",
            "San Sebastián Ixcapa",
            "San Sebastián Nicananduta",
            "San Sebastián Nopalera",
            "San Sebastián Río Hondo",
            "San Sebastián Tecomaxtlahuaca",
            "San Sebastian Teitipac",
            "San Sebastián Tenochtitlán",
            "San Sebastián Teteles",
            "San Sebastián Tlacotepec",
            "San Sebastián Tutla",
            "San Sebastián Villanueva",
            "San Sebastián Xolalpa",
            "San Simeón Xipetzingo",
            "San Simón",
            "San Simón",
            "San Simón Almolongas",
            "San Simón Atzitzintla",
            "San Simón de Guerrero",
            "San Simón de la Laguna",
            "San Simón el Alto",
            "San Simón Tlatlahuquitepec",
            "San Simonito",
            "San Tadeo",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente (Calpulalpan Tercera Manzana)",
            "San Vicente Boquerón",
            "San Vicente Chimalhuacán",
            "San Vicente Coatlán",
            "San Vicente Coyotepec",
            "San Vicente de Flores",
            "San Vicente de Juárez",
            "San Vicente el Alto",
            "San Vicente Ferrer",
            "San Vicente la Mesilla",
            "San Vicente Lachixío",
            "San Vicente Nuñu",
            "San Vicente Palapa",
            "San Vicente Piñas",
            "San Vicente Tancuayalab",
            "San Vicente Zoyatlán",
            "Sanahcat",
            "Sanalona",
            "Sanata Lucía",
            "Sánchez",
            "Sanctorum",
            "Sanctórum",
            "Santa Adelaida",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana Acozautla",
            "Santa Ana Ahuehuepan",
            "Santa Ana Amatlán",
            "Santa Ana Azcapotzaltongo",
            "Santa Ana Batha",
            "Santa Ana Chapitiro",
            "Santa Ana Chiautempan",
            "Santa Ana Coapan",
            "Santa Ana Coatepec",
            "Santa Ana de Allende",
            "Santa Ana de Guerrero (El Cascabel)",
            "Santa Ana del Pilar",
            "Santa Ana del Valle",
            "Santa Ana Hueytlalpan",
            "Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)",
            "Santa Ana Ixtlahuatzingo (Santa Ana)",
            "Santa Ana Jilotzingo",
            "Santa Ana Jilotzingo",
            "Santa Ana la Ladera",
            "Santa Ana Maya",
            "Santa Ana Mayorazgo",
            "Santa Ana Necoxtla",
            "Santa Ana Nextlalpan",
            "Santa Ana Nichi Ejido",
            "Santa Ana Pacueco",
            "Santa Ana Tlachiahualpa",
            "Santa Ana Tlacotenco",
            "Santa Ana Tlapacoyan",
            "Santa Ana Tzacuala",
            "Santa Ana Xalmimilulco",
            "Santa Ana Zegache",
            "Santa Ana Zicatecoyan",
            "Santa Ana Zirosto",
            "Santa Anita",
            "Santa Anita",
            "Santa Anita",
            "Santa Anita",
            "Santa Anita Huiloac",
            "Santa Apolonia",
            "Santa Apolonia Teacalco",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara de La Cueva",
            "Santa Casilda",
            "Santa Catalina",
            "Santa Catalina Quierí",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina",
            "Santa Catarina Ayometla",
            "Santa Catarina Ayotzingo",
            "Santa Catarina Cuixtla",
            "Santa Catarina de Tepehuanes",
            "Santa Catarina del Monte",
            "Santa Catarina Juquila",
            "Santa Catarina Loxicha",
            "Santa Catarina Mechoacán",
            "Santa Catarina Minas",
            "Santa Catarina Quiané",
            "Santa Catarina Roatina",
            "Santa Catarina Tayata",
            "Santa Catarina Tlaltempan",
            "Santa Catarina Villanueva",
            "Santa Catarina Yecahuizotl",
            "Santa Cecilia Jalieza",
            "Santa Cecilia Tepetitlán",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara de Juárez",
            "Santa Clara de Valladares",
            "Santa Clara del Cobre",
            "Santa Clara del Tule",
            "Santa Clara Huitziltepec",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz Acapa",
            "Santa Cruz Acatepec",
            "Santa Cruz Ajajalpan",
            "Santa Cruz Amilpas",
            "Santa Cruz Aquiahuac",
            "Santa Cruz Atizapán",
            "Santa Cruz Ayotuxco",
            "Santa Cruz Bombatevi",
            "Santa Cruz Cuauhtenco",
            "Santa Cruz Cuautomatitla",
            "Santa Cruz de Barcenas",
            "Santa Cruz de Bravo",
            "Santa Cruz de Juárez",
            "Santa Cruz de Juventino Rosas",
            "Santa Cruz de la Loma (Tepetates)",
            "Santa Cruz de la Soledad",
            "Santa Cruz de las Flores Tlajomulco de Zúñiga Jalisco",
            "Santa Cruz del Astillero",
            "Santa Cruz del Monte",
            "Santa Cruz del Valle",
            "Santa Cruz el Grande",
            "Santa Cruz el Porvenir",
            "Santa Cruz Escandón",
            "Santa Cruz Ex-Hacienda",
            "Santa Cruz Huitziltepec",
            "Santa Cruz Huitzizilapan",
            "Santa Cruz Lachixolana",
            "Santa Cruz Luján",
            "Santa Cruz Mextepec",
            "Santa Cruz Mixtepec",
            "Santa Cruz Nundaco",
            "Santa Cruz Otlatla",
            "Santa Cruz Ozolotepec",
            "Santa Cruz Papalutla",
            "Santa Cruz Pocitos",
            "Santa Cruz Pueblo",
            "Santa Cruz Pueblo Nuevo",
            "Santa Cruz Pueblo Nuevo (Pueblo Nuevo)",
            "Santa Cruz Tacache de Mina",
            "Santa Cruz Tayata",
            "Santa Cruz Tetela",
            "Santa Cruz Texmalaquilla",
            "Santa Cruz Xaltetela",
            "Santa Cruz Xitla",
            "Santa Cruz Xoxocotlán",
            "Santa Cruz Yucucani",
            "Santa Elena",
            "Santa Elena",
            "Santa Elena",
            "Santa Elena",
            "Santa Elena Paliseca",
            "Santa Eulalia",
            "Santa Fé",
            "Santa Fé",
            "Santa Fé de la Laguna",
            "Santa Fe de la Purísima",
            "Santa Fe del Río",
            "Santa Fe Tepetlapa",
            "Santa Fe y la Mar",
            "Santa Gertrudis",
            "Santa Gertrudis",
            "Santa Inés de Zaragoza",
            "Santa Inés del Monte",
            "Santa Inés Tecuexcomac",
            "Santa Inés Varela la Luz",
            "Santa Inés Yatzeche",
            "Santa Isabel",
            "Santa Isabel",
            "Santa Isabel",
            "Santa Isabel",
            "Santa Isabel [Fraccionamiento]",
            "Santa Isabel Chalma",
            "Santa Isabel Cholula",
            "Santa Isabel Ixtapan",
            "Santa Isabel Xiloxoxtla",
            "Santa Juana",
            "Santa Juana Centro",
            "Santa Juana Primera Sección",
            "Santa Justina Ecatepec",
            "Santa Lucía",
            "Santa Lucía",
            "Santa Lucía Cosamaloapan",
            "Santa Lucía del Camino",
            "Santa Lucía Ocotlán",
            "Santa Lucía Potrerillo",
            "Santa Lucía Teotepec",
            "Santa Magdalena Jicotlán",
            "Santa Margarita Huitepec",
            "Santa María",
            "Santa María",
            "Santa María",
            "Santa María Acatepec",
            "Santa María Actipac",
            "Santa María Acú",
            "Santa María Ajoloapan",
            "Santa María Ajoloapan",
            "Santa María Alotepec",
            "Santa María Amajac",
            "Santa Maria Amealco",
            "Santa María Apaxco",
            "Santa María Apazco",
            "Santa María Aranzazú (Santa María)",
            "Santa María Asunción",
            "Santa María Atarasquillo",
            "Santa María Atexcac",
            "Santa María Atlihuetzian",
            "Santa María Atzompa",
            "Santa María Batha",
            "Santa María Begoña",
            "Santa María Camotlán",
            "Santa María Canchesdá",
            "Santa María Chachoápam",
            "Santa María Chico Ometepec",
            "Santa María Chilapa de Díaz",
            "Santa María Chimalapa",
            "Santa María Chimalhuacán",
            "Santa María Citendejé",
            "Santa Maria Coatepec",
            "Santa María Colotepec",
            "Santa María Cortijo",
            "Santa María Coyotepec",
            "Santa María Cuevas",
            "Santa María de Enmedio",
            "Santa María de Guadalupe",
            "Santa María de Guaymas",
            "Santa María de la Paz",
            "Santa María de los Ángeles",
            "Santa María de los Ángeles",
            "Santa María de los Ángeles",
            "Santa María del Buáraje",
            "Santa María del Llano",
            "Santa María del Monte",
            "Santa María del Monte",
            "Santa María del Oro",
            "Santa María del Oro",
            "Santa María del Refugio",
            "Santa María del Río",
            "Santa María del Rosario",
            "Santa María del Tule",
            "Santa María del Valle",
            "Santa María Ecatepec",
            "Santa María Endare",
            "Santa María Guadalupe Tecola",
            "Santa María Guelacé",
            "Santa María Guenagati",
            "Santa María Huamelula",
            "Santa María Huatulco",
            "Santa María Huazolotitlán",
            "Santa María Huecatitla",
            "Santa María Huexoculco",
            "Santa María Huiramangaro (San Juan Tumbio)",
            "Santa María Ilucan",
            "Santa María Ipalapa",
            "Santa María Ixtiyucán",
            "Santa María Ixtulco",
            "Santa María Jacatepec",
            "Santa María Jajalpa",
            "Santa María Jalapa del Marqués",
            "Santa María Jicaltepec",
            "Santa María la Alta",
            "Santa María la Asunción",
            "Santa María la Asunción",
            "Santa María la Calera",
            "Santa María Lachixío",
            "Santa Maria Macua",
            "Santa María Magdalena",
            "Santa María Magdalena",
            "Santa María Magdalena Cahuacán",
            "Santa María Magdalena Ocotitlán",
            "Santa María Malacatepec",
            "Santa María Mazatla",
            "Santa María Mixtequilla",
            "Santa María Nativitas",
            "Santa María Nativitas",
            "Santa María Nativitas",
            "Santa María Nduayaco",
            "Santa María Nenetzintla",
            "Santa María Nepopualco",
            "Santa María Palapa",
            "Santa María Pápalo",
            "Santa María Petapa",
            "Santa María Pipioltepec (Pipioltepec)",
            "Santa María Puxmetacán",
            "Santa María Quelites",
            "Santa María Quiegolani",
            "Santa María Rayón",
            "Santa María Sola",
            "Santa María Tatetla",
            "Santa María Techachalco",
            "Santa María Tecuanulco",
            "Santa María Temaxcalapa",
            "Santa María Temaxcaltepec",
            "Santa María Teopoxco",
            "Santa María Tepantlali",
            "Santa María Texcalac",
            "Santa María Texmelucan",
            "Santa María Tiltepec",
            "Santa María Tlalixtac",
            "Santa María Tlalmimilolpan",
            "Santa María Tonameca",
            "Santa María Totoltepec",
            "Santa María Transpontina",
            "Santa María Urapicho",
            "Santa María Velato",
            "Santa María Xadani",
            "Santa María Xigui",
            "Santa María Xonacatepec",
            "Santa María Yavesía",
            "Santa María Yolotepec",
            "Santa María Yucuhiti",
            "Santa María Yucunicoco",
            "Santa María Zacatepec",
            "Santa María Zacatepec",
            "Santa María Zaniza",
            "Santa María Zapotitlán",
            "Santa María Zolotepec",
            "Santa María Zoquitlán",
            "Santa Martha",
            "Santa Martha",
            "Santa Martha",
            "Santa Martha Chichihualtepec",
            "Santa Martha Hidalgo",
            "Santa Matilde",
            "Santa Mónica",
            "Santa Mónica",
            "Santa Mónica",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita Tlahuapan",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa (El Huizache)",
            "Santa Rosa (Santa Bárbara)",
            "Santa Rosa Abata",
            "Santa Rosa Caxtlahuaca",
            "Santa Rosa de Lima",
            "Santa Rosa de Lima",
            "Santa Rosa de Lima",
            "Santa Rosa de Lima",
            "Santa Rosa de Rivas",
            "Santa Rosa Jauregui",
            "Santa Rosa Loma Larga",
            "Santa Rosa los Angeles",
            "Santa Rosa Segundo",
            "Santa Rosa Treinta",
            "Santa Rosa Xajay",
            "Santa Rosalía",
            "Santa Rosalía",
            "Santa Rosalía Jalisco",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresita",
            "Santa Teresita de Don Diego",
            "Santa Úrsula",
            "Santa Verónica",
            "Santana 2da. Sección B (La Palma)",
            "Santander Jiménez",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago Acatlán",
            "Santiago Acayutlán",
            "Santiago Acozac",
            "Santiago Acutzilapan",
            "Santiago Alseseca",
            "Santiago Amoltepec",
            "Santiago Analco",
            "Santiago Apostol",
            "Santiago Astata",
            "Santiago Atzitzihuacán",
            "Santiago Ayuquililla",
            "Santiago Azajo",
            "Santiago Bayacora",
            "Santiago Cacaloxtepec",
            "Santiago Capitiro",
            "Santiago Casandeje",
            "Santiago Centro",
            "Santiago Chazumba",
            "Santiago Chilixtlahuaca",
            "Santiago Chimalpa (Chimalpa)",
            "Santiago Choápam",
            "Santiago Citendejé",
            "Santiago Coachochitlan",
            "Santiago Coltzingo",
            "Santiago Comaltepec",
            "Santiago Cuaula",
            "Santiago Cuautenco",
            "Santiago Cuautlalpan",
            "Santiago Cuixtla",
            "Santiago de Anaya",
            "Santiago de Cuenda",
            "Santiago de la Peña",
            "Santiago de Pochotitán",
            "Santiago de Querétaro",
            "Santiago del Monte",
            "Santiago del Río",
            "Santiago el Pinar",
            "Santiago Etla",
            "Santiago Huajolotitlán",
            "Santiago Huauclilla",
            "Santiago Huaxolotipac",
            "Santiago Huitlapaltepec",
            "Santiago Ihuitlán Plumas",
            "Santiago Ixcuintepec",
            "Santiago Ixcuintla",
            "Santiago Ixtaltepec",
            "Santiago Ixtayutla",
            "Santiago Jaltepec",
            "Santiago Jicayán",
            "Santiago Jocotepec",
            "Santiago Juxtlahuaca",
            "Santiago Lachiguiri",
            "Santiago Lalopa",
            "Santiago Laollaga",
            "Santiago Laxopa",
            "Santiago Llano Grande",
            "Santiago Malacatepec",
            "Santiago Mamalhuazuca",
            "Santiago Maravatío",
            "Santiago Matatlán",
            "Santiago Mexquititlán Barrio 4to.",
            "Santiago Michac",
            "Santiago Miltepec",
            "Santiago Momoxpan",
            "Santiago Nejapilla",
            "Santiago Niltepec",
            "Santiago Nundiche",
            "Santiago Nuyoó",
            "Santiago Oxtempan",
            "Santiago Oxthoc",
            "Santiago Oxtotitlán",
            "Santiago Papasquiaro",
            "Santiago Pojcol",
            "Santiago Puriatzícuaro",
            "Santiago Quiavicuzas",
            "Santiago Sochiapan",
            "Santiago Suchilquitongo",
            "Santiago Tangamandapio",
            "Santiago Tejocotillos",
            "Santiago Tenango",
            "Santiago Tenango",
            "Santiago Tepatlaxco",
            "Santiago Tepeticpac",
            "Santiago Tepetitlán",
            "Santiago Tepextla",
            "Santiago Tepeyahualco",
            "Santiago Tepopula",
            "Santiago Tetepec",
            "Santiago Texcalcingo",
            "Santiago Textitlán",
            "Santiago Teyahualco",
            "Santiago Tezontlale",
            "Santiago Tianguistenco de Galeana",
            "Santiago Tilantongo",
            "Santiago Tilapa",
            "Santiago Tílapa",
            "Santiago Tillo",
            "Santiago Tlacochcalco",
            "Santiago Tlacotepec",
            "Santiago Tlaltepoxco",
            "Santiago Tlapacoya",
            "Santiago Tlapanaloya",
            "Santiago Tlautla",
            "Santiago Tolman",
            "Santiago Tulantepec",
            "Santiago Tuxtla",
            "Santiago Undameo",
            "Santiago Xanica",
            "Santiago Yaitepec",
            "Santiago Yancuictlalpan",
            "Santiago Yancuitlalpan",
            "Santiago Yeché",
            "Santiago Yogana",
            "Santiago Yolomécatl",
            "Santiago Yosondúa",
            "Santiago Zacatepec",
            "Santiago Zacualuca",
            "Santiago Zoochila",
            "Santiaguillo",
            "Santiaguito",
            "Santiaguito (Santiaguito de Velázquez)",
            "Santiaguito Tlalcilalcalli",
            "Santillán",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo Albarradas",
            "Santo Domingo Armenta",
            "Santo Domingo Atoyatempan",
            "Santo Domingo Aztacameca",
            "Santo Domingo Chihuitán",
            "Santo Domingo de Guzmán",
            "Santo Domingo de las Palmas",
            "Santo Domingo de Morelos",
            "Santo Domingo Huehuetlán",
            "Santo Domingo Jalieza",
            "Santo Domingo Kesté",
            "Santo Domingo Ocotitlán",
            "Santo Domingo Ozolotepec",
            "Santo Domingo Petapa",
            "Santo Domingo Roayaga",
            "Santo Domingo Shomege (Shomege)",
            "Santo Domingo Tehuantepec",
            "Santo Domingo Tepuxtepec",
            "Santo Domingo Tlatayapam",
            "Santo Domingo Tomaltepec",
            "Santo Domingo Tonalá",
            "Santo Domingo Yanhuitlán",
            "Santo Domingo Yodohino",
            "Santo Domingo Zanatepec",
            "Santo Niño Aguanaval",
            "Santo Nombre",
            "Santo Tomas",
            "Santo Tomás",
            "Santo Tomás",
            "Santo Tomás Atzingo",
            "Santo Tomás Chautla",
            "Santo Tomás Chiconautla",
            "Santo Tomás de los Plátanos",
            "Santo Tomás Huatzindeo",
            "Santo Tomás Jalieza",
            "Santo Tomás la Concordia",
            "Santo Tomás Mazaltepec",
            "Santo Tomás Ocotepec",
            "Santo Tomás Tamazulapam",
            "Santos Reyes Nopala",
            "Santos Reyes Pápalo",
            "Santos Reyes Tepejillo",
            "Santos Reyes Yucuná",
            "Sapioris",
            "Sarabia",
            "Sargento López 2da. Sección (El Chuzo)",
            "Sáric",
            "Sasaltitla",
            "Sauceda de La Borda",
            "Saucillo",
            "Sauta",
            "Sauz de Calera",
            "Sayula",
            "Sayula de Alemán",
            "Sayulilla",
            "Sayulita",
            "Sebastián Lerdo de Tejada",
            "Sebastián Lerdo de Tejada",
            "Sección 23",
            "Sección del Cerrito",
            "Sector H Tres",
            "Seis de Enero",
            "Seis de Enero",
            "Seis de Octubre",
            "Seis de Octubre (Santo Niño)",
            "Senguio",
            "Señor",
            "Señor del Pozo",
            "Sentispac",
            "Sergio Butrón Casas",
            "Sergio Villaseñor",
            "Serrano",
            "Sevina",
            "Seybaplaya",
            "Seyé",
            "Shoctic",
            "Sibacá",
            "Sibaniljá Pocolum",
            "Sierra de Agua",
            "Sierra de Guadalupe",
            "Sierra Mojada",
            "Sierra Papacal",
            "Sihochac",
            "Sihuapan",
            "Silacayoápam",
            "Silao",
            "Silao de la Victoria",
            "Siltepec",
            "Silva",
            "Simeprodeso (Colectivo Nuevo)",
            "Simojovel",
            "Simojovel de Allende",
            "Simón Sarlat",
            "Sinahua",
            "Sinahuiza",
            "Sinaloa",
            "Sinaloa",
            "Sinaloa de Leyva",
            "Sinanche",
            "Sinapan",
            "Singuilucan",
            "Siqueros",
            "Sisal",
            "Sisoguichi",
            "Sitalá",
            "Sitilpech",
            "Sitio de Xitlapehua",
            "Sitio Ejido",
            "Sitpach",
            "Siviral",
            "Socavón (El Carmen)",
            "Sochiapa",
            "Socoltenango",
            "Soconusco",
            "Soconusco",
            "Solalpan 1ra. Sección",
            "Soledad Atzompa",
            "Soledad de Doblado",
            "Soledad de Graciano Sánchez",
            "Soledad Etla",
            "Soledad Morelos",
            "Soledad Nueva",
            "Soledad Salinas",
            "Solima",
            "Solosuchiapa",
            "Soltepec",
            "Sonoita",
            "Sonora",
            "Sontecomapan",
            "Sosa",
            "Soteapan",
            "Sotelo",
            "Soto la Marina",
            "Sotuta",
            "Soyaló",
            "Soyaniquilpan",
            "Soyata",
            "Soyataco",
            "Soyatitán",
            "Soyatlán de Afuera",
            "Soyatlán del Oro",
            "Soyopa",
            "Suaqui Grande",
            "Subteniente López",
            "Suc-Tuc",
            "Suchiapa",
            "Suchiate",
            "Súchil",
            "Suchilapan del Río",
            "Suchitlán",
            "Suchitlán",
            "Sucilá",
            "Sucopó",
            "Sudzal",
            "Sultepec",
            "Suma",
            "Suma de Hidalgo",
            "Sumidero",
            "Suspiro Picacho",
            "Susticacán",
            "Susupuato de Guerrero",
            "Tabasco",
            "Tacámbaro",
            "Tacámbaro de Codallos",
            "Tacátzcuaro",
            "Tacicuaro",
            "Tacoaleche",
            "Tacopan",
            "Tacotalpa",
            "Tacuba Nueva",
            "Tacubaya",
            "Tacuro (Santa María Tacuro)",
            "Tafetán",
            "Tahdzibichen",
            "Tahdziu",
            "Tahmek",
            "Talismán",
            "Talol",
            "Talpa de Allende",
            "Tamalín",
            "Tamán",
            "Tamándaro",
            "Tamápatz",
            "Tamasopo",
            "Tamaulipas",
            "Tamazola",
            "Tamazula",
            "Tamazula",
            "Tamazula",
            "Tamazula de Gordiano",
            "Tamazula de Victoria",
            "Tamazulápam del Espíritu Santo",
            "Tamazulapam Villa del Progreso",
            "Tamazulita Jalisco",
            "Tamazunchale",
            "Tambaca",
            "Tamcuime",
            "Tamiahua",
            "Tampacán",
            "Tampate",
            "Tampemoche",
            "Tampico",
            "Tampico Alto",
            "Tampiquito",
            "Tamuín",
            "Tamulte de las Sabanas",
            "Tanaco",
            "Tanamacoyan",
            "Tanaquillo",
            "Tancanhuitz",
            "Tancazahuela",
            "Tancítaro",
            "Tancoco",
            "Tandzumadz",
            "Tanetze de Zaragoza",
            "Tangancícuaro",
            "Tangancícuaro de Arista",
            "Tanganhuato",
            "Tanhuato de Guerrero",
            "Tanhuixco",
            "Taniche",
            "Taniperla",
            "Tankuché",
            "Tanques",
            "Tanquián de Escobedo",
            "Tantima",
            "Tantoyuca",
            "Tanute",
            "Tapachula",
            "Tapalapa",
            "Tapalapan",
            "Tapalpa",
            "Tapanalá",
            "Tapias de Santa Cruz (Pedro Ruiz González)",
            "Tapijulapa",
            "Tapilula",
            "Tapotzingo",
            "Tarandacuao",
            "Tarécuato",
            "Tarejero",
            "Taretán",
            "Taretán",
            "Tarímbaro",
            "Tarimoro",
            "Tasquillo",
            "Tatahuicapá",
            "Tatahuicapan",
            "Tataltepec de Valdés",
            "Tatatila",
            "Tatauzoquico",
            "Tatoxcac",
            "Tavera",
            "Taxadho",
            "Taxco",
            "Taxco el Viejo",
            "Taxtes",
            "Tayahua",
            "Tayoltita",
            "Taza de Agua Ojo Zarco",
            "Tazaquil",
            "Tazumbo",
            "Teabo",
            "Teacalco",
            "Teacalco de Dorantes (San José Teacalco)",
            "Teacapan",
            "Teapa",
            "Teayo",
            "Tecacahuaco",
            "Tecajec",
            "Tecali",
            "Tecalitlán Municipality",
            "Tecalpulco",
            "Tecama",
            "Tecámac",
            "Tecámac de Felipe Villanueva",
            "Tecamachalco",
            "Tecamachalco",
            "Tecamalucan",
            "Tecario",
            "Tecate",
            "Tecax",
            "Tecaxic",
            "Techachaltitla",
            "Techaluta de Montenegro",
            "Techichili",
            "Tecoac (Santa María Nativitas)",
            "Tecoh",
            "Tecolapan",
            "Tecolcuautla",
            "Tecolotlán",
            "Tecolots",
            "Tecoltepec",
            "Tecoluta 2da. Sección",
            "Tecolutla",
            "Tecomán",
            "Tecomatán",
            "Tecomatepec",
            "Tecomates",
            "Tecomatlán",
            "Tecomavaca",
            "Tecominoacán",
            "Tecozautla",
            "Técpan de Galeana",
            "Tecpantzacoalco",
            "Tecpatán",
            "Tecuala",
            "Tecualilla",
            "Tecuanipa",
            "Tecuanipan",
            "Tecuattitan",
            "Tecuexcontitlán (Tecoescontitlán)",
            "Tehuacán",
            "Tehuetlán",
            "Tehuilotepec",
            "Tehuipango",
            "Tehuitzingo",
            "Tehuixtla",
            "Tehuixtla",
            "Tejalpa",
            "Tejaluca",
            "Tejocote de Calera",
            "Tejupilco",
            "Tejupilco de Hidalgo",
            "Tekal de Venegas",
            "Tekanto",
            "Tekax",
            "Tekik de Regil",
            "Tekit",
            "Tekoh",
            "Tekom",
            "Telchac Pueblo",
            "Telchac Puerto",
            "Telchaquillo",
            "Telcruz",
            "Telixtac",
            "Teloloapan",
            "Teloxtoc",
            "Telpatlán",
            "Teltipán de Juárez",
            "Temalacaco",
            "Temalacayuca",
            "Temamatla",
            "Temango",
            "Temapache",
            "Temascal",
            "Temascalapa",
            "Temascalcingo",
            "Temascaltepec",
            "Temascaltepec de González",
            "Temastián (La Cantera)",
            "Temax",
            "Temextla",
            "Temimilcingo",
            "Temixco",
            "Temoac",
            "Temoaya",
            "Témoris",
            "Temósachic",
            "Temozon",
            "Tempoal",
            "Tempoal de Sánchez",
            "Tenabo",
            "Tenamaxtlán",
            "Tenampa",
            "Tenampulco",
            "Tenancingo",
            "Tenancingo",
            "Tenancingo de Degollado",
            "Tenango",
            "Tenango",
            "Tenango",
            "Tenango de Arista",
            "Tenango de Doria",
            "Tenango de las Flores",
            "Tenango del Aire",
            "Tenango el Nuevo",
            "Tenantitla",
            "Tenayuca",
            "Tenenexpan",
            "Tenería",
            "Tenería del Santuario",
            "Tenexio",
            "Tenexpa",
            "Tenextepango",
            "Tengüecho",
            "Tenixtepec",
            "Tenjay",
            "Tenochtitlán",
            "Tenosique",
            "Tentic",
            "Teocalco",
            "Teocaltiche",
            "Teocelo",
            "Teococuilco de Marcos Pérez",
            "Teocuitatlán de Corona",
            "Teofani",
            "Teojomulco",
            "Teolocholco",
            "Teoloyucan",
            "Teontepec",
            "Teopantlán",
            "Teopisca",
            "Teotihuacán",
            "Teotihuacán de Arista",
            "Teotitlán",
            "Teotitlán del Valle",
            "Teotlalco",
            "Teotlaltzingo",
            "Tepache",
            "Tepakan",
            "Tepakán",
            "Tepalcatepec",
            "Tepalcatepec",
            "Tepalcingo",
            "Tepanco",
            "Tepango",
            "Tepango de Rodríguez",
            "Tepapayeca",
            "Tepatepec",
            "Tepatitlán de Morelos",
            "Tepatlaxco",
            "Tepatlaxco de Hidalgo",
            "Tepazolco",
            "Tepeaca",
            "Tepeapulco",
            "Tepec",
            "Tepechicotlán",
            "Tepechitlán",
            "Tepecoacuilco de Trujano",
            "Tepehuacán de Guerrero",
            "Tepehuanes",
            "Tepeitic",
            "Tepeji del Río de Ocampo",
            "Tepenixtlahuaca",
            "Tepeojuma",
            "Tepepa",
            "Tepetates",
            "Tepeteno de Iturbide",
            "Tepetitán",
            "Tepetitán Reyeshogpan de Hidalgo",
            "Tepetitla",
            "Tepetitla",
            "Tepetitlán",
            "Tepetitlán",
            "Tepetixtla",
            "Tepetlacolco",
            "Tepetlán",
            "Tepetlaoxtoc",
            "Tepetlaoxtoc de Hidalgo",
            "Tepetlapa",
            "Tepetlixpa",
            "Tepetongo",
            "Tepetongo",
            "Tepetzingo",
            "Tepetzingo",
            "Tepetzingo",
            "Tepetzintla",
            "Tepetzintla",
            "Tepetzitzintla",
            "Tepexco",
            "Tepexi de Rodríguez",
            "Tepexititla",
            "Tepexpan",
            "Tepeyahualco",
            "Tepeyahualco de Cuauhtémoc",
            "Tepeyanco",
            "Tepeyehualco de Hidalgo",
            "Tepezalá",
            "Tepic",
            "Tepich",
            "Tepojaco",
            "Tepotzotlán",
            "Tepoxtepec",
            "Tepozcuautla",
            "Tepoztlán",
            "Tepusco",
            "Tepuxtepec",
            "Tequepexpan",
            "Tequesquitengo",
            "Tequesquitlán",
            "Tequexquináhuac",
            "Tequicuilco",
            "Tequila",
            "Tequila",
            "Tequila 1ra. Sección (La Aurora)",
            "Tequisistlán",
            "Tequisistlán Primero",
            "Tequisquiapan",
            "Tequisquiapan",
            "Tequixquiac",
            "Tercera Manzana de Zaragoza",
            "Teremendo",
            "Terminal de Providencia",
            "Terrazas del Valle",
            "Terrenate",
            "Tesechoacan",
            "Tesistán",
            "Tesoco",
            "Testerazo",
            "Tetecala",
            "Tetecalita",
            "Tetecolala",
            "Tetela",
            "Tetela de Ocampo",
            "Tetela del Volcán",
            "Tetelcingo",
            "Tetelco",
            "Teteles de Avila Castillo",
            "Tetelilla",
            "Teteltzingo",
            "Tetepango",
            "Tetillas",
            "Tetipac",
            "Tetitlán",
            "Tetitlán",
            "Tetiz",
            "Tetla",
            "Tetla",
            "Tetlanohcán",
            "Tetlatzinga",
            "Tetlaxco",
            "Tetzacual",
            "Teuchitlán Jalisco",
            "Teúl de González Ortega",
            "Texalpan de Abajo",
            "Texalpan de Arriba",
            "Texan de Palomeque",
            "Texca",
            "Texcaco",
            "Texcala",
            "Texcala",
            "Texcalapa de Juárez",
            "Texcaltepec",
            "Texcaltic",
            "Texcaltitán Xoteapan (Texcaltitán)",
            "Texcaltitlán",
            "Texcalyacac",
            "Texcapa",
            "Texcapilla",
            "Texcatepec",
            "Texcatepec",
            "Texcatitlán",
            "Texcoco de Mora",
            "Texhuacán",
            "Texin",
            "Texistepec",
            "Texmelucan",
            "Texmola",
            "Texocoyohuac",
            "Teya",
            "Tezapotla",
            "Tezhuatepec",
            "Teziutlan",
            "Tezoatlán de Segura y Luna",
            "Tezonapa",
            "Tezonteopan de Bonilla",
            "Tezontepec",
            "Tezontepec de Aldama",
            "Tezoquipa",
            "Tezoyuca",
            "Tezoyuca",
            "Tianguismanalco",
            "Tianguistengo",
            "Tianguistengo (La Romera)",
            "Tianguistongo",
            "Tibolón",
            "Ticopó",
            "Ticul",
            "Ticumán",
            "Tierra Blanca",
            "Tierra Blanca",
            "Tierra Blanca",
            "Tierra Blanca",
            "Tierra Blanca",
            "Tierra Blanca Booxter",
            "Tierra Colorada",
            "Tierra Colorada",
            "Tierra Fría",
            "Tierra Larga (Campo Nuevo)",
            "Tierra Negra",
            "Tierra Nueva",
            "Tierra Nueva",
            "Tierra y Libertad",
            "Tierras Coloradas",
            "Tierras Coloradas (San Pedro)",
            "Tiholop",
            "Tihosuco",
            "Tihuatlan",
            "Tijuana",
            "Tikinmul",
            "Tikuch",
            "Tila",
            "Tilapa",
            "Tilapan",
            "Tiltepec",
            "Tiltepec",
            "Tilzapotla",
            "Timilpan",
            "Timucuy",
            "Tinajas",
            "Tinajas 1ra. Sección",
            "Tinajitas",
            "Tincontlán",
            "Tingambato",
            "Tingüindín",
            "Tinum",
            "Tiquicheo",
            "Tiquicheo de Nicolás Romero",
            "Tiríndaro",
            "Tirípetio",
            "Tixcacalcupul",
            "Tixcacaltuyub",
            "Tixcancal",
            "Tixhualactún",
            "Tixkokob",
            "Tixmadeje Chiquito",
            "Tixmehuac",
            "Tixpehual",
            "Tixtla de Guerrero",
            "Tixtlancingo",
            "Tizapán el Alto",
            "Tizayuca",
            "Tizimín",
            "Tlacamilco",
            "Tlachaloya",
            "Tlachichila",
            "Tlachichilco",
            "Tlachichilco",
            "Tlachichuca",
            "Tlacoachistlahuaca",
            "Tlacoapa",
            "Tlacoatzintepec",
            "Tlacoaxtla",
            "Tlacojalpan",
            "Tlacolula",
            "Tlacolula de Matamoros",
            "Tlacolulan",
            "Tlacomulco",
            "Tlacomulco",
            "Tlacotalpan",
            "Tlacote el Bajo",
            "Tlacotepec",
            "Tlacotepec",
            "Tlacotepec",
            "Tlacotepec (San Mateo)",
            "Tlacotepec de Benito Juárez",
            "Tlacotepec de José Manzo",
            "Tlacotepec de Mejía",
            "Tlacpac",
            "Tlacuilola",
            "Tlacuilolapan",
            "Tlacuilotepec",
            "Tlacuitapan",
            "Tlacuitlapa",
            "Tláhuac",
            "Tlahualilo de Zaragoza",
            "Tlahuapa",
            "Tlahuapan",
            "Tlahuelilpan",
            "Tlahuelompa (San Francisco Tlahuelompa)",
            "Tlahuiltepa",
            "Tlahuitoltepec",
            "Tlaixpan",
            "Tlajomulco de Zuñiga",
            "Tlalchapa",
            "Tlalchiyahualica",
            "Tlalconteno",
            "Tlalcozotitlán",
            "Tlalixcoyan",
            "Tlalixtac de Cabrera",
            "Tlalixtaquilla",
            "Tlalmanalco",
            "Tlalminulpa",
            "Tlalnelhuayocan",
            "Tlalnepantla",
            "Tlalnepantla",
            "Tlalnepantla",
            "Tlalnepantla de Baz",
            "Tlalpan",
            "Tlalpujahua de Rayón",
            "Tlaltecahuacán",
            "Tlaltempanapa",
            "Tlaltenango",
            "Tlaltenango de Sánchez Román",
            "Tlaltenanguito",
            "Tlaltepango",
            "Tlaltetela",
            "Tlaltetelco (San Miguel Tlaltetelco)",
            "Tlaltizapán",
            "Tlamacazapa",
            "Tlamaco (San Gerónimo Tlamaco)",
            "Tlamanca",
            "Tlamanca de Hernández",
            "Tlamatoca",
            "Tlamaya Grande",
            "Tlaminca",
            "Tlamixtlahuacan",
            "Tlanalapa",
            "Tlanalapan",
            "Tlanchinol",
            "Tlancualpican",
            "Tlanepantla",
            "Tlanipatla",
            "Tlaola",
            "Tlapa de Comonfort",
            "Tlapacoya",
            "Tlapacoyan",
            "Tlapala",
            "Tlapanalá",
            "Tlapanaloya",
            "Tlapehuala",
            "Tlaquepaque",
            "Tlaquiltenango",
            "Tlaquiltepec",
            "Tlatempa",
            "Tlatempan",
            "Tlatenchi",
            "Tlatilpa",
            "Tlatlauquitepec",
            "Tlatlauquitepec",
            "Tlatlaya",
            "Tlatzala",
            "Tlatzala",
            "Tlaxcala",
            "Tlaxcalantongo",
            "Tlaxcalilla",
            "Tlaxcalixtlahuaca",
            "Tlaxco",
            "Tlaxco",
            "Tlaxco (Santiago Tlaxco)",
            "Tlaxcoapan",
            "Tlaxinga (Clatzinga)",
            "Tlaxmalac",
            "Tlaxocoyucan",
            "Tlaxpanaloya",
            "Tlayacapan",
            "Tlayehualancingo",
            "Tlazala de Fabela",
            "Tlazazalca",
            "Tlilapan",
            "Tlixco",
            "Tocatlán",
            "Tochimilco",
            "Tochtepec",
            "Tocob Leglemal",
            "Tocoy",
            "Tocuila",
            "Tocumbo",
            "Todos Santos",
            "Tolcayuca",
            "Tolimán",
            "Tolimán Municipality",
            "Tolome",
            "Toluca",
            "Toluca de Guadalupe",
            "Tomaquilapa",
            "Tomatlán",
            "Tomatlán",
            "Tomatlán",
            "Tomelopitos",
            "Tomendán",
            "Tomochic",
            "Tonalá",
            "Tonalá",
            "Tonalá",
            "Tonalá",
            "Tonalaco",
            "Tonalapa",
            "Tonalapa del Sur",
            "Tonalapan",
            "Tonalixco",
            "Tonalixco",
            "Tonanitla",
            "Tonatico",
            "Tonaya",
            "Tonayán",
            "Tonila",
            "Topia",
            "Topilco de Juárez",
            "Topiltepec",
            "Topolobampo",
            "Toreo Bajo (El Toreo Bajo)",
            "Torreón",
            "Totatiche Municipality",
            "Totolac",
            "Totolapa",
            "Totolapa",
            "Totolapan",
            "Totolmajac",
            "Totoltepec",
            "Totoltepec de Guerrero",
            "Totomixtlahuaca",
            "Totontepec Villa de Morelos",
            "Tototepec",
            "Tototlán",
            "Totutla",
            "Toxpalan",
            "Toxtla",
            "Tozihuic",
            "Tozongo",
            "Trancoso",
            "Transfiguración",
            "Transito Tular",
            "Transporte",
            "Trapiche de Abra",
            "Trapiche del Rosario",
            "Trapiche Viejo",
            "Trejos",
            "Tres Bocas 1ra. Sección",
            "Tres Cerros",
            "Tres de Mayo",
            "Tres Estrellas",
            "Tres Garantías",
            "Tres Marías",
            "Tres Mezquites",
            "Tres Palos",
            "Tres Picos",
            "Tres Valles",
            "Tres Zapotes",
            "Trinidad de Viguera",
            "Trinidad García de la Cadena",
            "Trinidad Zaachila",
            "Tronconal",
            "Tronconal",
            "Tubutama",
            "Tucta",
            "Tula",
            "Tula",
            "Tula",
            "Tula de Allende",
            "Tulancingo",
            "Tulancingo de Bravo",
            "Tulantongo",
            "Tulapam",
            "Tulcingo",
            "Tulcingo de Valle",
            "Tulillos de Abajo",
            "Tulimán",
            "Tulimanca",
            "Tulipanes",
            "Tultepec",
            "Tultitlán",
            "Tultitlán de Mariano Escobedo",
            "Tulum",
            "Tumbala",
            "Tumbiscatío de Ruiz",
            "Tuncingo",
            "Tungareo",
            "Tunititlán",
            "Tunkas",
            "Tupataro",
            "Tupátaro",
            "Turcio Segunda Sección",
            "Turicato",
            "Turícuaro",
            "Turuachi",
            "Tutepec",
            "Tuxcacuesco",
            "Tuxcueca",
            "Túxpam de Rodríguez Cano",
            "Tuxpan",
            "Tuxpan",
            "Tuxpan",
            "Tuxpan",
            "Tuxpan de Bolaños",
            "Tuxpanguillo",
            "Tuxtepec",
            "Tuxtilla",
            "Tuxtla",
            "Tuxtla",
            "Tuxtla Chico",
            "Tuzamapan",
            "Tuzamapan",
            "Tuzantán",
            "Tuzantla",
            "Tuzuapan",
            "Tz'Aquiviljok",
            "Tzajalá",
            "Tzajalchén",
            "Tzeltal",
            "Tzicatlacoyan",
            "Tzicatlán",
            "Tzicatlán",
            "Tzimol",
            "Tzinacapan",
            "Tzinil",
            "Tzintzimeo",
            "Tzintzingareo",
            "Tzintzuntzan",
            "Tziritzícuaro",
            "Tziscao",
            "Tzitzio",
            "Tzoeptic",
            "Tzompantepec",
            "Tzontehuitz",
            "Tzopilja",
            "Tzucacab",
            "Uayalceh de Peón",
            "Uayma",
            "Ubilio García",
            "Ucareo",
            "Uci",
            "Ucú",
            "Ucum",
            "Ukúm",
            "Uman",
            "Unidad Acaquilpan",
            "Unidad Grajales INFONAVIT",
            "Unidad Habitacional 10 de Abril",
            "Unidad Habitacional Antonio Osorio de León (Bojay)",
            "Unidad Habitacional José María Morelos y Pavón",
            "Unidad Habitacional Mariano Matamoros",
            "Unidad Habitacional Rinconada Acolapa",
            "Unidad Habitacional Santa Teresa",
            "Unidad Minera 11 de Julio",
            "Unidad San Miguel Jagüeyes",
            "Unidades Habitacionales",
            "Unidos Avanzamos",
            "Unión Agropecuarios Lázaro Cárdenas del Norte",
            "Unión Buena Vista",
            "Unión Buenavista",
            "Unión de Corrientes",
            "Unión de San Antonio",
            "Unión de Tula",
            "Unión Ejidal Tierra y Libertad",
            "Unión Hidalgo",
            "Unión Juárez",
            "Unión Roja",
            "Unión y Progreso",
            "Urecho",
            "Urén",
            "Ures",
            "Uriangato",
            "Uricho",
            "Uripitio",
            "Urique",
            "Urireo",
            "Ursulo Galván",
            "Úrsulo Galván",
            "Uruachi",
            "Uruapan",
            "Uruétaro",
            "Usipa",
            "Usmajac",
            "Uspero",
            "Uxpanapa",
            "Uzeta",
            "Valadeces",
            "Valdeflores",
            "Valencia de Cerro Gordo",
            "Valencianita",
            "Valente Diaz",
            "Valentín Gómez Farías",
            "Valerio Trujano",
            "Valladolid",
            "Valladolid",
            "Valle Alto",
            "Valle de Allende",
            "Valle de Banderas",
            "Valle de Bravo",
            "Valle de Guadalupe Municipality",
            "Valle de Juárez",
            "Valle de Juárez",
            "Valle de Lincoln",
            "Valle de los Girasoles [Fraccionamiento]",
            "Valle de Santiago",
            "Valle de Vaquerías",
            "Valle de Vázquez",
            "Valle de Zaragoza",
            "Valle del Río",
            "Valle del Rosario",
            "Valle Dorado Inn",
            "Valle Hermoso",
            "Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]",
            "Valle Luz",
            "Valle Nacional",
            "Valle Verde",
            "Vallecillo",
            "Vallecitos de Zaragoza",
            "Vallejo (Porvenir Vallejo)",
            "Valparaíso",
            "Valtierrilla",
            "Vamos Tamaulipas",
            "Vanegas",
            "Vaquerias",
            "Vare Chiquichuca",
            "Vargas",
            "Vega de Alatorre",
            "Vega del Rosario",
            "Vega del Sol",
            "Vegas de la Soledad y Soledad Dos",
            "Veinte de Noviembre",
            "Veinte de Noviembre",
            "Veinte de Noviembre",
            "Veinte de Noviembre",
            "Veinte de Noviembre (Santa Lucía)",
            "Veintidós de Febrero",
            "Veintiuno de Agosto",
            "Velardeña",
            "Venceremos",
            "Venecia",
            "Venta de Bravo",
            "Venta de Ocotillos",
            "Venta Grande",
            "Ventana",
            "Ventoquipa",
            "Venustiano Carranza",
            "Venustiano Carranza",
            "Venustiano Carranza",
            "Venustiano Carranza",
            "Venustiano Carranza",
            "Venustiano Carranza",
            "Venustiano Carranza (Peña Blanca)",
            "Veracruz",
            "Veracruz",
            "Verapaz",
            "Viborillas",
            "Vicam",
            "Vicente Camalote",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero",
            "Vicente Guerrero (San Javier)",
            "Vicente Herrera",
            "Víctor Rosales",
            "Victoria",
            "Victoria",
            "Victoria de Cortazar",
            "Victoria de Durango",
            "Vida Mejor I",
            "Viesca",
            "Villa Alberto Andrés Alvarado Arámburo",
            "Villa Aldama",
            "Villa Aldama",
            "Villa Alta",
            "Villa Avila Camacho",
            "Villa Azueta",
            "Villa Chalcatongo de Hidalgo",
            "Villa Comaltitlán",
            "Villa Corona",
            "Villa Corzo",
            "Villa Cuauhtémoc",
            "Villa Cuauhtémoc",
            "Villa de Almoloya de Juárez",
            "Villa de Cos",
            "Villa de Costa Rica",
            "Villa de El Carmen Tequexquitla",
            "Villa de Etla",
            "Villa de Guadalupe",
            "Villa de las Flores",
            "Villa de los Niños",
            "Villa de Ramos",
            "Villa de Reyes",
            "Villa de Tamazulápam del Progreso",
            "Villa de Tututepec de Melchor Ocampo",
            "Villa de Zaachila",
            "Villa del Campo",
            "Villa del Carbón",
            "Villa del Prado 2da Sección",
            "Villa Díaz Ordaz",
            "Villa Emiliano Zapata",
            "Villa Emiliano Zapata",
            "Villa Emilio Carranza",
            "Villa García",
            "Villa González Ortega",
            "Villa Gregorio García",
            "Villa Guerrero",
            "Villa Guerrero",
            "Villa Guerrero Municipality",
            "Villa Hermosa",
            "Villa Hermosa (Las Pozas)",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo",
            "Villa Hidalgo (El Cubo)",
            "Villa Independencia",
            "Villa Insurgentes (El Calabazal)",
            "Villa Jiménez",
            "Villa Juárez",
            "Villa Juárez",
            "Villa Juárez",
            "Villa Juárez",
            "Villa Juárez",
            "Villa la Venta",
            "Villa Las Esperanzas",
            "Villa las Flores",
            "Villa Lázaro Cárdenas",
            "Villa Licenciado Jesús Terán (Calvillito)",
            "Villa Luvianos",
            "Villa Madero",
            "Villa Madero",
            "Villa Madero",
            "Villa Magna",
            "Villa Mainero",
            "Villa Mariano Matamoros",
            "Villa Montemorelos",
            "Villa Morelos",
            "Villa Morelos",
            "Villa Nicolás Bravo",
            "Villa Ocampo",
            "Villa Pesqueira",
            "Villa Progreso",
            "Villa Purificación",
            "Villa Rotaria",
            "Villa Santiago",
            "Villa Sola de Vega",
            "Villa Talea de Castro",
            "Villa Tecolutilla",
            "Villa Tejúpam de la Unión",
            "Villa Union",
            "Villa Unión",
            "Villa Unión",
            "Villa Unión",
            "Villa URBI del Rey",
            "Villa Vicente Guerrero",
            "Villa Vicente Guerrero",
            "Villa Victoria",
            "Villa Victoria",
            "Villa Zaragoza",
            "Villa Zempoala",
            "Villachuato",
            "Villaflores",
            "Villagrán",
            "Villagrán",
            "Villahermosa",
            "Villahermosa Yaluma",
            "Villaldama",
            "Villaldama Nuevo León",
            "Villamar",
            "Villamorelos",
            "Villanueva",
            "Villas de Alcalá",
            "Villas de Irapuato",
            "Villas de la Hacienda [Fraccionamiento]",
            "Villas de la Laguna",
            "Villas de la Loma",
            "Villas del Campo",
            "Villas Fundadores [Fraccionamiento]",
            "Viñas del Sol",
            "Virginias",
            "Vista Hermosa",
            "Vista Hermosa",
            "Vista Hermosa",
            "Vista Hermosa",
            "Vista Hermosa (Cuasinada)",
            "Vista Hermosa de Negrete",
            "Vistas del Maguey [Fraccionamiento]",
            "Visthá",
            "Vito",
            "Viva Cárdenas",
            "Viva México",
            "Vizarrón",
            "X Cabil",
            "X-Cán",
            "X-Hazil Sur",
            "X-pichil",
            "Xaaga",
            "Xacaxomulco",
            "Xagacía",
            "Xalacapan de Lucke",
            "Xalapa de Enríquez",
            "Xalatlaco",
            "Xalatlaco",
            "Xalatzala",
            "Xalitla",
            "Xalitzintla",
            "Xalostoc",
            "Xalpatlahuac",
            "Xaltepec",
            "Xaltepuxtla",
            "Xaltianguis",
            "Xalticpac",
            "Xaltocan",
            "Xanabá",
            "Xaya",
            "Xayacatlán de Bravo",
            "Xbacab",
            "Xcanatún",
            "Xhimojay",
            "Xhitey",
            "Xhixhata",
            "Xichú",
            "Xico",
            "Xico",
            "Xicohtzinco",
            "Xicoténcatl",
            "Xicoténcatl",
            "Xicotepec",
            "Xicotepec de Juárez",
            "Xicotlán",
            "Xilitla",
            "Xilocuautla",
            "Xiloxochico de Rafael Ávila Camacho",
            "Xiquila",
            "Xiteje de Zapata",
            "Xitlama",
            "Xiutetelco",
            "Xmabén",
            "Xoampolco",
            "Xocchel",
            "Xocen",
            "Xochapa",
            "Xochiapulco",
            "Xochiatipan",
            "Xochiatipan de Castillo",
            "Xochicalco (Cirenio Longares)",
            "Xochicoatlán",
            "Xochicuautla",
            "Xochihuehuetlán",
            "Xochiltepec",
            "Xochiltepec",
            "Xochimanca",
            "Xochimilco",
            "Xochimilco",
            "Xochimilco",
            "Xochinanacatlán",
            "Xochipala",
            "Xochistlahuaca",
            "Xochitepec",
            "Xochitepec",
            "Xochitepec",
            "Xochitlán",
            "Xochitlán",
            "Xochitlán",
            "Xochitlán de Vicente Suárez",
            "Xochitlan Todos Santos",
            "Xochitlaxco (San Baltazar)",
            "Xochitonalco",
            "Xococapa",
            "Xoconoxtle el Grande",
            "Xocotepéc",
            "Xocotla",
            "Xocotlán",
            "Xocoyolzintla",
            "Xocoyucan",
            "Xohuayan",
            "Xoloateno",
            "Xolostitla de Morelos (Xolostitla)",
            "Xolotla",
            "Xometla",
            "Xonacatlan",
            "Xonalpu",
            "Xonocuautla",
            "Xopilapa",
            "Xoteapan",
            "Xoxocotla",
            "Xoxocotla",
            "Xoxolpa",
            "Xoxonacatla",
            "Xpujil",
            "Xuchapa",
            "Xul",
            "Xul-Ha",
            "Yaganiza",
            "Yago",
            "Yahualica",
            "Yahualica de González Gallo",
            "Yajalón",
            "Yalina",
            "Yalkoba",
            "Yaltem",
            "Yanga",
            "Yaonahuac",
            "Yasha",
            "Yashanal",
            "Yatzachi",
            "Yauhquemehcan",
            "Yautepec",
            "Yavaros",
            "Yaxcabá",
            "Yaxcopoil",
            "Yaxe",
            "Yaxhachen",
            "Yaxkukul",
            "Yecapixteca",
            "Yecapixtla",
            "Yécora",
            "Yecuatla",
            "Yehualtepec",
            "Yerbabuena",
            "Yerbabuena",
            "Yetla",
            "Yetla de Juárez",
            "Yetlancingo",
            "Yextla",
            "Yibeljoj",
            "Yobain",
            "Yolotepec",
            "Yoloxóchitl",
            "Yopi",
            "Yoshib",
            "Yosphí",
            "Yotholin",
            "Yucuita",
            "Yurécuaro",
            "Yuriria",
            "Yustis",
            "Yutanduchi de Guerrero",
            "Zacacoyuca",
            "Zacacuautla",
            "Zacalaca",
            "Zacamixtle",
            "Zacamulpa",
            "Zacamulpa",
            "Zacamulpa Tlalmimilolpan",
            "Zacango",
            "Zacaola",
            "Zacapala",
            "Zacapalco",
            "Zacapechpan",
            "Zacapoaxtla",
            "Zacapu",
            "Zacapuato",
            "Zacatal",
            "Zacate Colorado",
            "Zacate Colorado Segundo (Fraternidad)",
            "Zacatecas",
            "Zacatelco",
            "Zacatepec",
            "Zacatepec",
            "Zacatla",
            "Zacatlán",
            "Zacatonal de Juárez",
            "Zacatula",
            "Zacazonapan",
            "Zacoalco de Torres",
            "Zacoalpan",
            "Zacualpa",
            "Zacualpan",
            "Zacualpan",
            "Zacualpan",
            "Zacualpan",
            "Zacualpan",
            "Zacualpan de Amilpas",
            "Zacualtipán",
            "Zacualtipanito",
            "Zahuatlán de Morelos (San José)",
            "Zalamea",
            "Zamachihue",
            "Zamora",
            "Zamora",
            "Zamora Pico de Oro",
            "Zamorano",
            "Zangarro (Zangarro Nuevo)",
            "Zapoapan",
            "Zapoapan de Amapan",
            "Zapoapan de Cabañas",
            "Zapopan",
            "Zapotán",
            "Zapotanito",
            "Zapote de Barajas",
            "Zapote de Cestao",
            "Zapote de Palomas",
            "Zapote de Peralta",
            "Zapotillo de Mogotes",
            "Zapotiltic",
            "Zapotitán de Hidalgo",
            "Zapotitlán",
            "Zapotitlán de Vadillo",
            "Zapotitlán Lagunas",
            "Zapotitlán Palmas",
            "Zapotitlán Tablas",
            "Zapotlán",
            "Zapotlán de Juárez",
            "Zapotlán del Rey",
            "Zapotlán El Grande Municipality",
            "Zapotlanejo",
            "Zapuyo",
            "Zaragoza",
            "Zaragoza",
            "Zaragoza",
            "Zaragoza",
            "Zaragoza",
            "Zaragoza",
            "Zaragoza (Puerto de Medina)",
            "Zaragoza de Guadalupe",
            "Zaragoza la Montaña",
            "Zarzal",
            "Zautla",
            "Zelocotitlán",
            "Zempoala",
            "Zempoala",
            "Zentla",
            "Zentlalpan",
            "Zequentic",
            "Zicuicho",
            "Zicuirán",
            "Zihuateutla",
            "Zilacayotitlán",
            "Zimapan",
            "Zimatepec",
            "Zimatlán de Álvarez",
            "Zinacantán",
            "Zinacantepec",
            "Zinacatepec",
            "Zináparo",
            "Zinapécuaro",
            "Zipiajo",
            "Ziquítaro",
            "Ziracuaretiro",
            "Zirahuato de los Bernal",
            "Zirahuén",
            "Zirándaro",
            "Zirándaro de los Chávez",
            "Zirimícuaro",
            "Zitlala",
            "Zitlaltépec",
            "Zizicazapa",
            "Zoatecpan",
            "Zocea",
            "Zocoteaca de León",
            "Zoh-Laguna",
            "Zolonquiapa",
            "Zona Urbana Ejidal",
            "Zona Urbana Ejido Isla Mujeres",
            "Zongolica",
            "Zongozotla",
            "Zontecomatlán de López y Fuentes",
            "Zopoco",
            "Zoquiapa",
            "Zoquiapan",
            "Zoquiapan",
            "Zoquiopan",
            "Zoquite",
            "Zoquitipán",
            "Zoquitlán",
            "Zoquitlán",
            "Zothé",
            "Zotoltitlán",
            "Zotoluca",
            "Zoyatitla",
            "Zoyatlán",
            "Zoyatzingo",
            "Zoyotla",
            "Zozocolco de Guerrero",
            "Zozocolco de Hidalgo",
            "Zula",
            "Zula",
            "Zumpahuacán",
            "Zumpango",
            "Zumpango",
            "Zumpango",
            "Zumpango del Río",
            "Zurumbeneo"
        ]
    },
    {
        "label": "Micronesia",
        "code": "FM",
        "phone": "691",
        "cities": [
            "Colonia",
            "Dalipebinaw Municipality",
            "Eot Municipality",
            "Ettal Municipality",
            "Fais",
            "Fais Municipality",
            "Fananu Municipality",
            "Fanapanges Municipality",
            "Fanif Municipality",
            "Faraulep Municipality",
            "Fefen Municipality",
            "Fonoton Municipality",
            "Gagil Municipality",
            "Gilman Municipality",
            "Houk Municipality",
            "Kanifay Municipality",
            "Kitti Municipality",
            "Kolonia",
            "Kolonia Municipality",
            "Kolonia Town",
            "Kuttu Municipality",
            "Lamotrek Municipality",
            "Lekinioch Municipality",
            "Lelu Municipality",
            "Losap Municipality",
            "Maap Municipality",
            "Madolenihm Municipality",
            "Makur Municipality",
            "Malem Municipality",
            "Moch Municipality",
            "Mokil Municipality",
            "Murilo",
            "Murilo Municipality",
            "Namoluk Municipality",
            "Nema",
            "Nema Municipality",
            "Nett Municipality",
            "Ngatik",
            "Ngulu Municipality",
            "Nomwin Municipality",
            "Nukuoro Municipality",
            "Oneop Municipality",
            "Onou Municipality",
            "Onoun Municipality",
            "Paata-Tupunion Municipality",
            "Palikir - National Government Center",
            "Parem Municipality",
            "Piherarh Municipality",
            "Piis-Emwar Municipality",
            "Piis-Panewu Municipality",
            "Pingelap Municipality",
            "Pollap Municipality",
            "Polowat Municipality",
            "Pwene Municipality",
            "Ramanum Municipality",
            "Rull Municipality",
            "Rumung Municipality",
            "Ruo Municipality",
            "Sapwuahfik Municipality",
            "Satawal Municipality",
            "Satowan Municipality",
            "Siis Municipality",
            "Sokehs Municipality",
            "Ta Municipality",
            "Tafunsak Municipality",
            "Tamatam Municipality",
            "Tofol",
            "Tolensom Municipality",
            "Tomil Municipality",
            "Tonoas Municipality",
            "U Municipality",
            "Udot-Fonuweisom Municipality",
            "Ulithi Municipality",
            "Uman-Fonuweisom Municipality",
            "Unanu Municipality",
            "Utwe Municipality",
            "Weloy Municipality",
            "Weno",
            "Weno Municipality",
            "Woleai Municipality",
            "Wonei Municipality"
        ]
    },
    {
        "label": "Moldova",
        "code": "MD",
        "phone": "373",
        "cities": [
            "Anenii Noi",
            "Bălţi",
            "Basarabeasca",
            "Bender",
            "Bilicenii Vechi",
            "Biruinţa",
            "Briceni",
            "Briceni",
            "Bucovăţ",
            "Bugeac",
            "Cahul",
            "Călăraşi",
            "Camenca",
            "Cantemir",
            "Căuşeni",
            "Ceadîr-Lunga",
            "Chisinau",
            "Chiţcani",
            "Cimişlia",
            "Ciorescu",
            "Cocieri",
            "Comrat",
            "Crasnoe",
            "Cricova",
            "Criuleni",
            "Dancu",
            "Dnestrovsc",
            "Donduşeni",
            "Drochia",
            "Dubăsari",
            "Edineţ",
            "Fălești",
            "Floreşti",
            "Ghindești",
            "Giurgiuleşti",
            "Glodeni",
            "Hînceşti",
            "Hryhoriopol",
            "Ialoveni",
            "Iargara",
            "Maiac",
            "Mărculeşti",
            "Mîndreşti",
            "Nisporeni",
            "Ocniţa",
            "Orhei",
            "Otaci",
            "Pervomaisc",
            "Rezina",
            "Rîbniţa",
            "Rîşcani",
            "Saharna",
            "Sîngera",
            "Sîngerei",
            "Slobozia",
            "Şoldăneşti",
            "Soroca",
            "Stăuceni",
            "Ştefan Vodă",
            "Strășeni",
            "Taraclia",
            "Teleneşti",
            "Tiraspol",
            "Tiraspolul Nou",
            "Tvardița",
            "Ungheni",
            "Ustia",
            "Vadul lui Vodă",
            "Varniţa",
            "Vatra",
            "Vişniovca",
            "Vulcăneşti"
        ]
    },
    {
        "label": "Monaco",
        "code": "MC",
        "phone": "377",
        "cities": []
    },
    {
        "label": "Mongolia",
        "code": "MN",
        "phone": "976",
        "cities": [
            "Altai",
            "Altay",
            "Arvayheer",
            "Baruun-Urt",
            "Bayanhongor",
            "Bulgan",
            "Choibalsan",
            "Choyr",
            "Dalandzadgad",
            "Darhan",
            "Dzüünharaa",
            "Dzuunmod",
            "Erdenet",
            "Ereencav",
            "Hanh",
            "Hanhongor",
            "Harhorin",
            "Hovd",
            "Kharkhorin",
            "Khovd",
            "Mandalgovi",
            "Möngönmorĭt",
            "Möst",
            "Murun-kuren",
            "Nariynteel",
            "Nomgon Sum",
            "Ölgii",
            "Sühbaatar",
            "Tsengel",
            "Tsengel",
            "Tsetserleg",
            "Turt",
            "Ulaangom",
            "Ulaanhudag",
            "Uliastay",
            "Undurkhaan",
            "Üyönch",
            "Зуунмод"
        ]
    },
    {
        "label": "Montenegro",
        "code": "ME",
        "phone": "382",
        "cities": [
            "Andrijevica",
            "Bar",
            "Berane",
            "Bijelo Polje",
            "Budva",
            "Cetinje",
            "Danilovgrad",
            "Dobrota",
            "Golubovci",
            "Goričani",
            "Gusinje",
            "Kolašin",
            "Kotor",
            "Mataguži",
            "Mojanovići",
            "Mojkovac",
            "Nikšić",
            "Petrovac na Moru",
            "Plav",
            "Pljevlja",
            "Plužine",
            "Podgorica",
            "Prčanj",
            "Risan",
            "Rožaje",
            "Šavnik",
            "Spuž",
            "Stari Bar",
            "Šušanj",
            "Sutomore",
            "Tivat",
            "Tuzi",
            "Ulcinj",
            "Žabljak"
        ]
    },
    {
        "label": "Montserrat",
        "code": "MS",
        "phone": "+1-664",
        "cities": []
    },
    {
        "label": "Morocco",
        "code": "MA",
        "phone": "212",
        "cities": [
            "Abadou",
            "Adassil",
            "Agadir",
            "Agadir Melloul",
            "Agadir-Ida-ou-Tnan",
            "Agdz",
            "Aguelmous",
            "Ahfir",
            "Aïn Beni Mathar",
            "Aïn Leuh",
            "Akhfennir",
            "Aknoul",
            "Al Aaroui",
            "Al Fqih Ben Çalah",
            "Al Hoceïma",
            "Al-Haouz",
            "Al-Hoceima",
            "Almis Marmoucha",
            "Alnif",
            "Aoufous",
            "Aoulouz",
            "Aourir",
            "Aousserd",
            "Arazane",
            "Arbaoua",
            "Arfoud",
            "Argana",
            "Asilah",
            "Assa-Zag",
            "Azemmour",
            "Azilal",
            "Azilal Province",
            "Azrou",
            "Beni Mellal",
            "Beni-Mellal",
            "Benslimane",
            "Berkane",
            "Berrechid",
            "Berrechid Province",
            "Bhalil",
            "Bigoudine",
            "Bni Bouayach",
            "Bouabout",
            "Bouarfa",
            "Bouarouss",
            "Boujdour",
            "Boujniba",
            "Boulaouane",
            "Boulemane",
            "Bouskoura",
            "Bouznika",
            "Brikcha",
            "Bzou",
            "Cap Negro II",
            "Casablanca",
            "Chefchaouen Province",
            "Chefchaouene",
            "Chichaoua",
            "Chtouka-Ait-Baha",
            "Dar Ould Zidouh",
            "Debdou",
            "Demnate",
            "Derdara",
            "Driouch Province",
            "El Aïoun",
            "El Hajeb",
            "El Jadid",
            "El Ksiba",
            "El-Hajeb",
            "El-Jadida",
            "Errachidia",
            "Es-Semara",
            "Essaouira",
            "Fahs-Anjra",
            "Fes",
            "Fès al Bali",
            "Figuig",
            "Fnidek",
            "Fquih Ben Salah Province",
            "Galaz",
            "Ghouazi",
            "Guelmim",
            "Gueltat Zemmour",
            "Guercif",
            "Guercif Province",
            "Had Kourt",
            "Ifrane",
            "Ifrane",
            "Imilchil",
            "Imlili",
            "Imzouren",
            "Inezgane",
            "Inezgane-Ait Melloul",
            "Isseksi",
            "Itzer",
            "Jebel Tiskaouine",
            "Jerada",
            "Jorf",
            "Kasba Tadla",
            "Kelaa-Des-Sraghna",
            "Kelaat Mgouna",
            "Kenitra",
            "Kenitra Province",
            "Kerrouchen",
            "Khemisset",
            "Khenifra",
            "Khouribga",
            "Khouribga Province",
            "Ksar El Kebir",
            "Laayoune",
            "Larache",
            "M'Diq-Fnideq",
            "Madagh",
            "Marrakech",
            "Marrakesh",
            "Martil",
            "Mechraa Bel Ksiri",
            "Mediouna",
            "Meknes",
            "Mhamid",
            "Midar",
            "Midelt",
            "Midelt",
            "Missour",
            "Mohammedia",
            "Moulay Bouchta",
            "Moulay-Yacoub",
            "Nador",
            "Nouaceur",
            "Oualidia",
            "Ouaoula",
            "Ouarzazat",
            "Ouarzazate",
            "Oued Amlil",
            "Oued Laou",
            "Oued Zem",
            "Oued-Ed-Dahab",
            "Ouezzane",
            "Ouezzane Province",
            "Ouijjane",
            "Oujda-Angad",
            "Oukaïmedene",
            "Oulad Frej",
            "Oulad Tayeb",
            "Oulad Teïma",
            "Oulmes",
            "Ourtzagh",
            "Rabat",
            "Reçani",
            "Reggada",
            "Rehamna",
            "Safi",
            "Saidia",
            "Sale",
            "Sefrou",
            "Selouane",
            "Senada",
            "Settat",
            "Settat Province",
            "Setti Fatma",
            "Sidi Bennour",
            "Sidi Bousber",
            "Sidi Ifni",
            "Sidi Ifni",
            "Sidi Jaber",
            "Sidi Qacem",
            "Sidi Rahhal",
            "Sidi Redouane",
            "Sidi Slimane",
            "Sidi Smai’il",
            "Sidi Yahia El Gharb",
            "Sidi-Kacem",
            "Skhirate",
            "Skhirate-Temara",
            "Smara",
            "Smimou",
            "Souq Larb’a al Gharb",
            "Tadrart",
            "Tafraout",
            "Taghazout",
            "Tahla",
            "Taliouine",
            "Talzemt",
            "Tamanar",
            "Tamorot",
            "Tamri",
            "Tan-Tan",
            "Tanalt",
            "Tanger-Assilah",
            "Tangier",
            "Taouloukoult",
            "Taounate",
            "Taourirt",
            "Tarfaya",
            "Targuist",
            "Taroudannt",
            "Taroudant",
            "Tarsouat",
            "Tata",
            "Taza",
            "Taznakht",
            "Telouet",
            "Temara",
            "Teroual",
            "Tetouan",
            "Tidili Mesfioua",
            "Tiflet",
            "Timezgadiouine",
            "Timoulilt",
            "Tinghir",
            "Tinghir Province",
            "Tirhanimîne",
            "Tit Mellil",
            "Tiznit",
            "Tiztoutine",
            "Tmourghout",
            "Youssoufia",
            "Zagora",
            "Zaïo",
            "Zawyat an Nwaçer",
            "Zawyat ech Cheïkh",
            "Zerkten",
            "Zinat",
            "Zoumi"
        ]
    },
    {
        "label": "Mozambique",
        "code": "MZ",
        "phone": "258",
        "cities": [
            "Alto Molócuè",
            "António Enes",
            "Beira",
            "Boane District",
            "Chibuto",
            "Chimoio",
            "Chinde",
            "Chiure",
            "Chokwé",
            "Concelho de Matola",
            "Cuamba",
            "Dondo",
            "Ilha de Moçambique",
            "Inhambane",
            "KaTembe",
            "Lichinga",
            "Macia",
            "Magude District",
            "Mandimba",
            "Manhica",
            "Maputo",
            "Marracuene District",
            "Matola",
            "Matutiune District",
            "Maxixe",
            "Moamba District",
            "Mocímboa",
            "Montepuez",
            "Mutuáli",
            "Nacala",
            "Namaacha District",
            "Nampula",
            "Nhamatanda District",
            "Pemba",
            "Quelimane",
            "Ressano Garcia",
            "Tete",
            "Xai-Xai"
        ]
    },
    {
        "label": "Myanmar",
        "code": "MM",
        "phone": "95",
        "cities": [
            "Bago",
            "Bhamo",
            "Bogale",
            "Chauk",
            "Dawei",
            "Dellok",
            "Falam",
            "Hakha",
            "Hinthada",
            "Hpa-An",
            "Kanbe",
            "Kawthoung",
            "Kayan",
            "Klonhtoug",
            "Kyaikkami",
            "Kyaiklat",
            "Kyaikto",
            "Kyain Seikgyi Township",
            "Kyaukse",
            "Lashio",
            "Letpandan",
            "Loikaw",
            "Magway",
            "Mandalay",
            "Martaban",
            "Maubin",
            "Mawlaik",
            "Mawlamyine",
            "Mawlamyinegyunn",
            "Meiktila",
            "Mikenaungea",
            "Minbu",
            "Mogok",
            "Monywa",
            "Mudon",
            "Myanaung",
            "Myawadi",
            "Myaydo",
            "Myeik",
            "Myingyan",
            "Myitkyina",
            "Nay Pyi Taw",
            "Nyaungdon",
            "Nyaunglebin",
            "Nyaungshwe",
            "Pakokku",
            "Pathein",
            "Paungde",
            "Pulei",
            "Pyapon",
            "Pyay",
            "Pyin Oo Lwin",
            "Pyinmana",
            "Pyu",
            "Sagaing",
            "Shwebo",
            "Sittwe",
            "Syriam",
            "Tachilek",
            "Tagondaing",
            "Tamoowoug",
            "Taungdwingyi",
            "Taunggyi",
            "Taungoo",
            "Thanatpin",
            "Tharyarwady",
            "Thaton",
            "Thayetmyo",
            "Thongwa",
            "Twante",
            "Wakema",
            "Yamethin",
            "Yangon",
            "Yenangyaung"
        ]
    },
    {
        "label": "Namibia",
        "code": "NA",
        "phone": "264",
        "cities": [
            "Arandis",
            "Aranos",
            "Bagani",
            "Bethanie",
            "Epupa Constituency",
            "Gobabis",
            "Grootfontein",
            "Hentiesbaai",
            "Hoachanas",
            "Karasburg",
            "Karibib",
            "Katima Mulilo",
            "Katutura",
            "Keetmanshoop",
            "Khorixas",
            "Khorixas Constituency",
            "Lüderitz",
            "Maltahöhe",
            "Mariental",
            "Okahandja",
            "Okahao",
            "Okakarara",
            "Omaruru",
            "Omuthiya",
            "Ondangwa",
            "Ongandjera",
            "Ongwediva",
            "Opuwo",
            "Opuwo Constituency",
            "Oranjemund",
            "Oshakati",
            "Oshikango",
            "Otavi",
            "Otjimbingwe",
            "Otjiwarongo",
            "Outapi",
            "Outjo",
            "Rehoboth",
            "Rundu",
            "Sesfontein Constituency",
            "Swakopmund",
            "Swakopmund Constituency",
            "Tses",
            "Tsumeb",
            "Usakos",
            "Walvis Bay",
            "Warmbad",
            "Windhoek"
        ]
    },
    {
        "label": "Nauru",
        "code": "NR",
        "phone": "674",
        "cities": [
            "Anabar",
            "Arijejen",
            "Baiti",
            "Ijuw",
            "Menen",
            "Uaboe",
            "Yaren"
        ]
    },
    {
        "label": "Nepal",
        "code": "NP",
        "phone": "977",
        "cities": [
            "Bāglung",
            "Banepā",
            "Bhadrapur",
            "Bharatpur",
            "Bhattarai Danda",
            "Bhojpur",
            "Bhojpur",
            "Biratnagar",
            "Birendranagar",
            "Birgañj",
            "Butwāl",
            "Chitre",
            "Dadeldhurā",
            "Dailekh",
            "Dārchulā",
            "Dhangaḍhi̇̄",
            "Dhankutā",
            "Dharān",
            "Dhulikhel",
            "Dihi",
            "Dipayal",
            "Gaur",
            "Gulariyā",
            "Hari Bdr Tamang House",
            "Hetauda",
            "Ilām",
            "Inaruwa",
            "Jaleshwar",
            "Janakpur",
            "Jumla",
            "kankrabari Dovan",
            "Kathmandu",
            "Khanbari",
            "Kirtipur",
            "Lahān",
            "Lobujya",
            "Madhyapur Thimi",
            "Mahendranagar",
            "Malaṅgawā",
            "Nagarkot",
            "Namche Bazar",
            "Nepalgunj",
            "Panauti",
            "Pātan",
            "Pokhara",
            "Rājbirāj",
            "Rāmechhāp",
            "Siddharthanagar",
            "Siraha",
            "Tānsen",
            "Ṭikāpur",
            "Titahari",
            "Triyuga",
            "Tulsīpur",
            "Wāliṅ"
        ]
    },
    {
        "label": "Netherlands",
        "code": "NL",
        "phone": "31",
        "cities": [
            "'s Gravenmoer",
            "'s-Graveland",
            "'s-Gravenland",
            "'s-Gravenzande",
            "'s-Heerenberg",
            "'s-Hertogenbosch",
            "'t Hofke",
            "'t Horntje",
            "'t Kabel",
            "'t Nopeind",
            "'t Veld",
            "'t Zand",
            "Aagtdorp",
            "Aagtekerke",
            "Aalburg",
            "Aalden",
            "Aalsmeer",
            "Aalsmeerderbrug",
            "Aalst",
            "Aalst",
            "Aalten",
            "Aardenburg",
            "Aarle-Rixtel",
            "Aartswoud",
            "Abbekerk",
            "Abbenes",
            "Abcoude",
            "Abcoven",
            "Abdissenbosch",
            "Adegeest",
            "Aduard",
            "Afferden",
            "Akersloot",
            "Akert",
            "Akkrum",
            "Alblasserdam",
            "Aldeboarn",
            "Aldlân-Oost",
            "Alkmaar",
            "Almelo",
            "Almere Stad",
            "Almkerk",
            "Alphen",
            "Alphen aan den Rijn",
            "Alverna",
            "Amby",
            "America",
            "Amerongen",
            "Amersfoort",
            "Ammerstol",
            "Ammerzoden",
            "Amstelveen",
            "Amstenrade",
            "Amsterdam",
            "Amsterdam-Zuidoost",
            "Andel",
            "Andelst",
            "Andijk",
            "Angelslo",
            "Angeren",
            "Angerlo",
            "Ankeveen",
            "Ankeveense Rade",
            "Anklaar",
            "Annen",
            "Apeldoorn",
            "Appelscha",
            "Appingedam",
            "Arcen",
            "Arkel",
            "Arnemuiden",
            "Arnhem",
            "Assen",
            "Assendelft",
            "Asten",
            "Augustinusga",
            "Austerlitz",
            "Avenhorn",
            "Axel",
            "Baalder",
            "Baambrugge",
            "Baardwijk",
            "Baarle-Nassau",
            "Baarlo",
            "Baarn",
            "Babberich",
            "Badhoevedorp",
            "Baflo",
            "Bakel",
            "Bakenberg",
            "Bakhuizen",
            "Bakkeveen",
            "Bakkum",
            "Balk",
            "Ballast",
            "Bangert",
            "Banholt",
            "Barendrecht",
            "Bargen",
            "Barger-Oosterveld",
            "Bargeres",
            "Barneveld",
            "Barsingerhorn",
            "Bavel",
            "Bedum",
            "Beegden",
            "Beek",
            "Beek",
            "Beek gem Montferland",
            "Beekbergen",
            "Beemster",
            "Beers",
            "Beersdal",
            "Beesd",
            "Beesel",
            "Beets",
            "Beetsterzwaag",
            "Beilen",
            "Beinsdorp",
            "Belcrum",
            "Belfort",
            "Bellingwolde",
            "Belt",
            "Beltrum",
            "Bemmel",
            "Bennebroek",
            "Bennekom",
            "Benningbroek",
            "Benthuizen",
            "Bentveld",
            "Berg",
            "Berg en Bos",
            "Berg en Dal",
            "Bergeijk",
            "Bergen",
            "Bergen op Zoom",
            "Bergharen",
            "Berghem",
            "Bergschenhoek",
            "Bergstoep",
            "Beringe",
            "Berkel en Rodenrijs",
            "Berkelland",
            "Berkenwoude",
            "Berkhout",
            "Berkmeer",
            "Berkum",
            "Berlicum",
            "Berltsum",
            "Besoijen",
            "Best",
            "Besterd",
            "Beuningen",
            "Beusichem",
            "Beverwijk",
            "Biddinghuizen",
            "Biesdonk",
            "Biesland",
            "Bijlmermeer",
            "Bijvanck",
            "Bilgaard",
            "Binnenhof",
            "Binnenstad",
            "Binnenstad",
            "Bitswijk",
            "Blaarthem",
            "Bladel",
            "Blaricum",
            "Blauwe Keet",
            "Bleijerheide",
            "Bleiswijk",
            "Bleskensgraaf",
            "Blijham",
            "Blitterswijck",
            "Bloemendaal",
            "Bloemendaal",
            "Bloemhof",
            "Blokker",
            "Blokzijl",
            "Bobeldijk",
            "Bocholtz",
            "Bodegraven",
            "Bodegraven-Reeuwijk",
            "Boekel",
            "Boekel",
            "Boesingheliede",
            "Bolnes",
            "Bolsward",
            "Boornbergum",
            "Borculo",
            "Borgele",
            "Borger",
            "Borgharen",
            "Born",
            "Borne",
            "Borssele",
            "Bos en Lommer",
            "Bosch en Duin",
            "Boskamp",
            "Boskoop",
            "Bosschenhoofd",
            "Boven Bolivia",
            "Boven-Hardinxveld",
            "Bovenkarspel",
            "Boxmeer",
            "Boxtel",
            "Brabander",
            "Brachterbeek",
            "Brakel",
            "Brakkenstein",
            "Brand",
            "Breda",
            "Bredevoort",
            "Bredeweg",
            "Breezand",
            "Bregtdorp",
            "Breskens",
            "Breugel",
            "Breukelen",
            "Breukeleveen",
            "Brielle",
            "Brinkhorst",
            "Broek in Waterland",
            "Broek op Langedijk",
            "Broekhem",
            "Broekhoven",
            "Broeksittard",
            "Broeksterwâld",
            "Brouwershaven",
            "Bruchem",
            "Bruinisse",
            "Brukske",
            "Brummelhof",
            "Brummen",
            "Brunnepe",
            "Brunssum",
            "Buchten",
            "Budel",
            "Budel-Dorplein",
            "Budel-Schoot",
            "Budschop",
            "Buiksloot",
            "Buikslotermeer",
            "Buitenkaag",
            "Buitenpost",
            "Buitenveldert",
            "Bunde",
            "Bunnik",
            "Bunschoten",
            "Burdaard",
            "Burgemeesterswijk",
            "Burgerbrug",
            "Burgerveen",
            "Burgervlotbrug",
            "Burgum",
            "Bussum",
            "Butterhuizen",
            "Callantsoog",
            "Calslagen",
            "Camminghaburen",
            "Camperduin",
            "Capelle aan den IJssel",
            "Capelle-West",
            "Carnisse",
            "Castricum",
            "Catrijp",
            "Chaam",
            "Chevremont",
            "Coendersborg",
            "Coevering",
            "Coevorden",
            "Colijnsplaat",
            "Commandeurs",
            "Componistenkwartier",
            "Corlaer",
            "Cothen",
            "Cranendonck",
            "Cruquius",
            "Cuijk",
            "Culemborg",
            "Dalen",
            "Dalfsen",
            "Damwâld",
            "Dauwendaele",
            "De Aker",
            "De Bilt",
            "De Blaak",
            "De Bouwhof",
            "De Cocksdorp",
            "De Domp",
            "De Doornakkers",
            "De Drait",
            "De Fryske Marren",
            "De Glip",
            "De Goorn",
            "De Greiden",
            "De Hagen",
            "De Haukes",
            "De Haven",
            "De Heeg",
            "De Heeze",
            "De Hoven",
            "De Hulk",
            "De Kieviet",
            "De Knipe",
            "De Koog",
            "De Kooy",
            "De Kruiskamp",
            "De Kwakel",
            "De Lier",
            "De Loo",
            "De Maer",
            "De Mheen",
            "De Noord",
            "De Peulen",
            "De Pijp",
            "De Reit",
            "De Rijp",
            "De Rompert",
            "De Stolpen",
            "De Uithof",
            "De Waal",
            "De Wallen",
            "De Weere",
            "De Weere",
            "De Westereen",
            "De Wijert",
            "De Wijk",
            "De Woude",
            "Deest",
            "Deil",
            "Delden",
            "Delfshaven",
            "Delft",
            "Delfzijl",
            "Den Burg",
            "Den Dungen",
            "Den Ham",
            "Den Helder",
            "Den Hoorn",
            "Den Ilp",
            "Den Oever",
            "Deuteren",
            "Deventer",
            "Diemen",
            "Diepenheim",
            "Dieren",
            "Diessen",
            "Diever",
            "Dinteloord",
            "Dirkshorn",
            "Dirksland",
            "Doenrade",
            "Doesburg",
            "Doetinchem",
            "Dokkum",
            "Domburg",
            "Dommelen",
            "Dongen",
            "Donk",
            "Donkerbroek",
            "Doonheide",
            "Doorn",
            "Doornenburg",
            "Doornspijk",
            "Doornsteeg",
            "Doorwerth",
            "Dordrecht",
            "Dorp Tera Kora",
            "Dorst",
            "Drachten",
            "Drachtstercompagnie",
            "Drechterland",
            "Driebergen-Rijsenburg",
            "Driebruggen",
            "Driehuis",
            "Driehuizen",
            "Driehuizen",
            "Driehuizen",
            "Driel",
            "Driemanspolder",
            "Driemond",
            "Drogeham",
            "Dronryp",
            "Dronten",
            "Drumpt",
            "Druten",
            "Duindorp",
            "Duinzigt",
            "Duiven",
            "Duivendrecht",
            "Duizel",
            "Durgerdam",
            "Dwingeloo",
            "Eastermar",
            "Echtenerbrug",
            "Eckart",
            "Edam",
            "Edam-Volendam",
            "Ede",
            "Ederveen",
            "Eefde",
            "Eelde",
            "Eenigenburg",
            "Eenrum",
            "Eerbeek",
            "Eerde",
            "Eerschot",
            "Eersel",
            "Eext",
            "Eexta",
            "Egchel",
            "Egmond aan den Hoef",
            "Egmond aan Zee",
            "Egmond-Binnen",
            "Eibergen",
            "Eijsden",
            "Eijsden-Margraten",
            "Eindhoven",
            "Einighausen",
            "Ekenrooi",
            "Elburg",
            "Elden",
            "Ellecom",
            "Elshout",
            "Elsloo",
            "Elspeet",
            "Elst",
            "Emmeloord",
            "Emmen",
            "Emmer-Compascuum",
            "Emmer-Erfscheidenveen",
            "Emmerhout",
            "Emmermeer",
            "Emmerschans",
            "Emst",
            "Enkhuizen",
            "Ens",
            "Enschede",
            "Enschot",
            "Epe",
            "Epse",
            "Ermelo",
            "Erp",
            "Esch",
            "Essesteijn",
            "Etersheim",
            "Etten",
            "Exloo",
            "Farmsum",
            "Feanwâlden",
            "Feijenoord",
            "Ferwert",
            "Fijnaart",
            "Flevowijk",
            "Franeker",
            "Frankhuis",
            "Friese Buurt",
            "Gageldonk",
            "Gameren",
            "Garderen",
            "Garyp",
            "Gasselte",
            "Gasselternijveen",
            "Geenhoven",
            "Geertruidenberg",
            "Geffen",
            "Geitenkamp",
            "Geldermalsen",
            "Geldermalsen-West",
            "Geldrop",
            "Geleen",
            "Gemeente Appingedam",
            "Gemeente Delfzijl",
            "Gemeente Groningen",
            "Gemeente Loppersum",
            "Gemeente Oldambt",
            "Gemeente Pekela",
            "Gemeente Stadskanaal",
            "Gemeente Veendam",
            "Gemert",
            "Gemonde",
            "Genderen",
            "Gendringen",
            "Gendt",
            "Gennep",
            "Genoenhuis",
            "Gerwen",
            "Geuzenveld",
            "Giesbeek",
            "Giessen",
            "Giessenburg",
            "Giessendam",
            "Gieten",
            "Giethoorn",
            "Gilze",
            "Ginneken",
            "Glimmen",
            "Goedereede",
            "Goes",
            "Goirle",
            "Gooise Meren",
            "Goor",
            "Gorinchem",
            "Gorredijk",
            "Gorssel",
            "Gouda",
            "Gouderak",
            "Goudswaard",
            "Goutum",
            "Gouwe",
            "Gouwsluis",
            "Gracht",
            "Grafhorst",
            "Graft",
            "Grashoek",
            "Grasrijk",
            "Grave",
            "Grevenbicht",
            "Griffioen",
            "Grijpskerk",
            "Grijpskerke",
            "Groenekan",
            "Groenewoud",
            "Groenswaard",
            "Groesbeek",
            "Groessen",
            "Groningen",
            "Gronsveld",
            "Groot IJsselmonde",
            "Groot-Ammers",
            "Grootebroek",
            "Grootegast",
            "Grootschermer",
            "Grosthuizen",
            "Grou",
            "Gulden Bodem",
            "Gulpen",
            "Gulpen-Wittem",
            "Guttecoven",
            "Gytsjerk",
            "Haaften",
            "Haaksbergen",
            "Haalderen",
            "Haamstede",
            "Haanrade",
            "Haaren",
            "Haarle",
            "Haarlem",
            "Haarlemmerliede",
            "Haarlemmermeer",
            "Haarsteeg",
            "Haastrecht",
            "Haelen",
            "Hakkelaarsbrug",
            "Halfweg",
            "Hallum",
            "Halsteren",
            "Handel",
            "Hapert",
            "Haps",
            "Hardenberg",
            "Harderwijk",
            "Harderwijk",
            "Haren",
            "Harenkarspel",
            "Hargen",
            "Haringhuizen",
            "Harkema",
            "Harkstede",
            "Harlingen",
            "Harskamp",
            "Hasselt",
            "Hatert",
            "Hattem",
            "Hattemerbroek",
            "Haulerwijk",
            "Hauwert",
            "Havelte",
            "Hedel",
            "Heechterp",
            "Heeg",
            "Heel",
            "Heelsum",
            "Heemskerk",
            "Heemstede",
            "Heer",
            "Heerde",
            "Heerenveen",
            "Heerewaarden",
            "Heerhugowaard",
            "Heerjansdam",
            "Heerle",
            "Heerlen",
            "Hees",
            "Heesch",
            "Heesterakker",
            "Heeswijk-Dinther",
            "Heeze",
            "Hegelsom",
            "Heide",
            "Heijen",
            "Heijplaat",
            "Heiligerlee",
            "Heiloo",
            "Heino",
            "Heksenberg",
            "Helden",
            "Hellevoetsluis",
            "Helmond",
            "Helpman",
            "Helvoirt",
            "Hem",
            "Hendrik-Ido-Ambacht",
            "Hengelo",
            "Hengelo",
            "Hengevelde",
            "Hengstdal",
            "Hensbroek",
            "Herkenbosch",
            "Herpen",
            "Herten",
            "Herwijnen",
            "Het Loo",
            "Het Oostrik",
            "Heteren",
            "Heugem",
            "Heumen",
            "Heusden",
            "Heusdenhout",
            "Heythuysen",
            "Hillegom",
            "Hilvarenbeek",
            "Hilversum",
            "Hilversumse Meent",
            "Hinderdam",
            "Hippolytushoef",
            "Hobrede",
            "Hoek",
            "Hoek van Holland",
            "Hoensbroek",
            "Hoeven",
            "Hofgeest",
            "Hoge Mors",
            "Hoge Vucht",
            "Hollands Kroon",
            "Hollandsche Rading",
            "Hollum",
            "Holtum",
            "Holwerd",
            "Holysloot",
            "Holz",
            "Honselersdijk",
            "Hoofddorp",
            "Hoogblokland",
            "Hooge Mierde",
            "Hooge Zwaluwe",
            "Hoogeloon",
            "Hoogerheide",
            "Hoogeveen",
            "Hoogezand",
            "Hoogkamp",
            "Hoogkarspel",
            "Hoogmade",
            "Hoogwoud",
            "Hoorn",
            "Hopel",
            "Horssen",
            "Horstermeer",
            "Houten",
            "Houthem",
            "Huigsloot",
            "Huijbergen",
            "Huisduinen",
            "Huizen",
            "Huizum",
            "Hulsberg",
            "Hulst",
            "Hummelo",
            "Hunnerberg",
            "Hurdegaryp",
            "Husken",
            "IJburg",
            "IJlst",
            "IJmuiden",
            "IJsselstein",
            "IJzendijke",
            "Ilpendam",
            "Ingen",
            "Jagershoef",
            "Jirnsum",
            "Jisp",
            "Jordaan",
            "Joure",
            "Jubbega",
            "Julianadorp",
            "Kaalheide",
            "Kadoelen",
            "Kakert",
            "Kalsdonk",
            "Kalverdijk",
            "Kampen",
            "Kamperland",
            "Kapel",
            "Kapelle",
            "Katendrecht",
            "Katwijk aan den Rijn",
            "Katwijk aan Zee",
            "Katwoude",
            "Kedichem",
            "Keent",
            "Keijenborg",
            "Kelpen-Oler",
            "Kerckebosch",
            "Kerensheide",
            "Kerkbuurt",
            "Kerkdriel",
            "Kerkehout",
            "Kerkelanden",
            "Kerkrade",
            "Kerschoten",
            "Kessel",
            "Kesteren",
            "Kijkduin",
            "Klaaswaal",
            "Klarenbeek",
            "Klazienaveen",
            "Klein Driene",
            "Klein-Zundert",
            "Klimmen",
            "Kloetinge",
            "Klundert",
            "Koedijk",
            "Koekange",
            "Koewacht",
            "Koggenland",
            "Kolhorn",
            "Kollum",
            "Kollumerzwaag",
            "Koningsbosch",
            "Koningslust",
            "Koog aan de Zaan",
            "Kootstertille",
            "Kootwijkerbroek",
            "Kop van Zuid",
            "Korrewegwijk",
            "Kortenhoef",
            "Korvel",
            "Koudekerke",
            "Koudum",
            "Krabbendam",
            "Krakeel",
            "Kreileroord",
            "Krimpen aan den IJssel",
            "Krimpenerwaard",
            "Krommenie",
            "Krooswijk",
            "Kruisberg",
            "Kruisland",
            "Kudelstaart",
            "Kunrade",
            "Kwadijk",
            "Kwintsheul",
            "Lage Mierde",
            "Lakerlopen",
            "Lambertschaag",
            "Lammerenburg",
            "Landgraaf",
            "Landsmeer",
            "Langedijk",
            "Langeheit",
            "Langenboom",
            "Langenoord",
            "Langereis",
            "Langweer",
            "Laren",
            "Laren",
            "Lauradorp",
            "Leek",
            "Leende",
            "Leens",
            "Leerdam",
            "Leersum",
            "Leest",
            "Leesten",
            "Leeuwarden",
            "Leeuwen",
            "Leiden",
            "Leiderdorp",
            "Leimuiden",
            "Leimuiderbrug",
            "Lelystad",
            "Lemmer",
            "Lent",
            "Lepelstraat",
            "Leuken",
            "Leunen",
            "Leusden",
            "Leuth",
            "Lichtenberg",
            "Lichtenvoorde",
            "Liempde",
            "Lienden",
            "Lierop",
            "Lieshout",
            "Liessel",
            "Liesveld",
            "Lievendaal",
            "Lijnden",
            "Limbricht",
            "Limmel",
            "Limmen",
            "Lindenheuvel",
            "Lindenholt",
            "Linne",
            "Linschoten",
            "Lisse",
            "Lisserbroek",
            "Lith",
            "Lobith",
            "Lochem",
            "Loenen",
            "Lombardijen",
            "Loon op Zand",
            "Loosbroek",
            "Loosdrecht",
            "Lopik",
            "Loppersum",
            "Losser",
            "Lottum",
            "Loven",
            "Lunetten",
            "Lunteren",
            "Lutjebroek",
            "Lutjewinkel",
            "Luyksgestel",
            "Maarheeze",
            "Maarn",
            "Maarssen",
            "Maartensdijk",
            "Maasbracht",
            "Maasbree",
            "Maasdijk",
            "Maaskantje",
            "Maasland",
            "Maassluis",
            "Maastricht",
            "Made",
            "Magele",
            "Makkum",
            "Malberg",
            "Malburgen West",
            "Malta",
            "Mantgum",
            "Margraten",
            "Mariahout",
            "Mariarade",
            "Markelo",
            "Marken",
            "Markenbinnen",
            "Marrum",
            "Marsdijk",
            "Marsum",
            "Marum",
            "Matendonk",
            "Matendreef",
            "Matengaarde",
            "Matenhoeve",
            "Matenhorst",
            "Matenveld",
            "Maurik",
            "Mechelen",
            "Medemblik",
            "Meeden",
            "Meerhoven",
            "Meerlo",
            "Meerssen",
            "Meerveldhoven",
            "Meezenbroek",
            "Megen",
            "Meierijstad",
            "Meijel",
            "Mekkelholt",
            "Melderslo",
            "Melick",
            "Meliskerke",
            "Melle",
            "Menaam",
            "Meppel",
            "Merenwijk",
            "Merkelbeek",
            "Merselo",
            "Merum",
            "Meteren",
            "Middelbeers",
            "Middelburg",
            "Middelharnis",
            "Middelie",
            "Middelrode",
            "Middelsluis",
            "Middelstum",
            "Midden-Groningen",
            "Middenbeemster",
            "Middenmeer",
            "Midwolda",
            "Midwoud",
            "Mierlo",
            "Mijdrecht",
            "Mijnsheerenland",
            "Milheeze",
            "Milsbeek",
            "Minnertsga",
            "Moerbeek",
            "Moerdijk",
            "Moesel",
            "Molenhoek",
            "Monnickendam",
            "Monster",
            "Montfoort",
            "Montfort",
            "Mook",
            "Muiden",
            "Muiderberg",
            "Munstergeleen",
            "Muschberg en Geestenberg",
            "Muyeveld",
            "Naaldwijk",
            "Naarden",
            "Naastenbest",
            "Neck",
            "Neder-Hardinxveld",
            "Nederhemert",
            "Nederhemert-Noord",
            "Nederhorst den Berg",
            "Nederweert",
            "Nederwoud",
            "Neede",
            "Neerbeek",
            "Neerkant",
            "Nes",
            "Nes aan de Amstel",
            "Nibbixwoud",
            "Niekerk",
            "Nieuw- en Sint Joosland",
            "Nieuw-Beijerland",
            "Nieuw-Buinen",
            "Nieuw-Dordrecht",
            "Nieuw-Lekkerland",
            "Nieuw-Loosdrecht",
            "Nieuw-Lotbroek",
            "Nieuw-Roden",
            "Nieuw-Vennep",
            "Nieuw-Vossemeer",
            "Nieuwdorp",
            "Nieuwe Meer",
            "Nieuwe Niedorp",
            "Nieuwe Pekela",
            "Nieuwebrug",
            "Nieuwegein",
            "Nieuwehorne",
            "Nieuwendam",
            "Nieuwenhoorn",
            "Nieuwerkerk",
            "Nieuwesluis",
            "Nieuwkoop",
            "Nieuwkuijk",
            "Nieuwolda",
            "Nieuwoord",
            "Nieuwpoort",
            "Nieuwveen",
            "Nijenheim",
            "Nijkerk",
            "Nijkerkerveen",
            "Nijmegen",
            "Nijnsel",
            "Nijrees",
            "Nispen",
            "Nissewaard",
            "Nistelrode",
            "Noardburgum",
            "Noord-Hofland",
            "Noord-Scharwoude",
            "Noordbarge",
            "Noordbeemster",
            "Noordbroek",
            "Noordburen",
            "Noorddijk",
            "Noordeinde",
            "Noordeinde",
            "Noordeloos",
            "Noordgeest",
            "Noordhoek",
            "Noordhorn",
            "Noordwijk-Binnen",
            "Noordwijkerhout",
            "Noordwolde",
            "Norg",
            "Nuenen",
            "Numansdorp",
            "Nunspeet",
            "Nuth",
            "Obbicht",
            "Obdam",
            "Obergum",
            "Ochten",
            "Odijk",
            "Odiliapeel",
            "Odoorn",
            "Oegstgeest",
            "Oentsjerk",
            "Oerle",
            "Offenbeek",
            "Oirlo",
            "Oirsbeek",
            "Oirschot",
            "Oisterwijk",
            "Oldeberkoop",
            "Oldebroek",
            "Oldehove",
            "Oldemarkt",
            "Oldenzaal",
            "Olst",
            "Ommelanderwijk",
            "Ommen",
            "Ommoord",
            "Omval",
            "Onderdijk",
            "Onstwedde",
            "Oog in Al",
            "Ooij",
            "Oost Gelre",
            "Oost-Graftdijk",
            "Oost-Vlieland",
            "Oostburg",
            "Oostdorp",
            "Oosteind",
            "Oosteinde",
            "Oostelbeers",
            "Oostendorp",
            "Oosterbeek",
            "Oosterdijk",
            "Oosterend",
            "Oosterhesselen",
            "Oosterholt",
            "Oosterhoogebrug",
            "Oosterhout",
            "Oosterhout",
            "Oosterland",
            "Oosterland",
            "Oosterleek",
            "Oostermeenthe",
            "Oosterpark",
            "Oosterwolde",
            "Oosterzij",
            "Oosthuizen",
            "Oostkapelle",
            "Oostknollendam",
            "Oostrum",
            "Oostvoorne",
            "Oostwoud",
            "Oostzaan",
            "Ootmarsum",
            "Ooy",
            "Ooyerhoek",
            "Opeinde",
            "Ophemert",
            "Opheusden",
            "Ophoven",
            "Opijnen",
            "Opmeer",
            "Oppenhuizen",
            "Opperdoes",
            "Oranjestad",
            "Oranjewijk",
            "Oranjewoud",
            "Orden",
            "Orthen",
            "Osdorp",
            "Ospel",
            "Oss",
            "Ossendrecht",
            "Oterleek",
            "Othene",
            "Otterlo",
            "Ottersum",
            "Oud Gastel",
            "Oud-Beijerland",
            "Oud-Caberg",
            "Oud-Loosdrecht",
            "Oud-Vossemeer",
            "Ouddorp",
            "Oude IJsselstreek",
            "Oude Meer",
            "Oude Niedorp",
            "Oude Pekela",
            "Oude Wetering",
            "Oudega",
            "Oudehaske",
            "Oudemirdum",
            "Oudendijk",
            "Ouder-Amstel",
            "Ouderkerk aan de Amstel",
            "Ouderkerk aan den IJssel",
            "Oudeschild",
            "Oudeschoot",
            "Oudesluis",
            "Oudewater",
            "Oudkarspel",
            "Oudorp",
            "Overasselt",
            "Overberg",
            "Overhoven",
            "Overleek",
            "Overloon",
            "Overmeer",
            "Overschie",
            "Overveen",
            "Palenstein",
            "Pannerden",
            "Panningen",
            "Papendrecht",
            "Papenveer",
            "Passart",
            "Paterswolde",
            "Pathmos",
            "Peelo",
            "Peij",
            "Peize",
            "Pendrecht",
            "Penningsveer",
            "Pernis",
            "Petten",
            "Pierik",
            "Piershil",
            "Pijnacker",
            "Poeldijk",
            "Poortvliet",
            "Posterholt",
            "Pottenberg",
            "Princenhage",
            "Prinsenbeek",
            "Puiflijk",
            "Purmerend",
            "Purmerland",
            "Puth",
            "Putte",
            "Putten",
            "Quirijnstok",
            "Raalte",
            "Raam",
            "Raamsdonk",
            "Raamsdonksveer",
            "Randenbroek",
            "Ransdorp",
            "Ravenstein",
            "Reek",
            "Reeuwijk",
            "Reitdiep",
            "Renesse",
            "Renkum",
            "Renswoude",
            "Reusel",
            "Reuver",
            "Rheden",
            "Rhenen",
            "Rhoon",
            "Ridderkerk",
            "Riethoven",
            "Rijen",
            "Rijnsaterwoude",
            "Rijnsburg",
            "Rijpwetering",
            "Rijsbergen",
            "Rijsenhout",
            "Rijsoord",
            "Rijswijk",
            "Rijswijk",
            "Rincon",
            "Risdam",
            "Rivierenkwartier",
            "Rockanje",
            "Roden",
            "Roelofarendsveen",
            "Roermond",
            "Rolde",
            "Roosendaal",
            "Roosteren",
            "Rossum",
            "Rossum",
            "Rothem",
            "Rotterdam",
            "Rottevalle",
            "Rozenburg",
            "Rozendaal",
            "Rucphen",
            "Rugge",
            "Ruigoord",
            "Ruinen",
            "Ruinerwold",
            "Rustenburg",
            "Ruurlo",
            "Salderes",
            "Santpoort",
            "Sappemeer",
            "Sas van Gent",
            "Sassenheim",
            "Schagen",
            "Schagerbrug",
            "Schaijk",
            "Schalkhaar",
            "Schalkwijk",
            "Schardam",
            "Scharendijke",
            "Scharn",
            "Scharnegoutum",
            "Scharwoude",
            "Scheemda",
            "Schellingwoude",
            "Schellinkhout",
            "Schelluinen",
            "Schermerhorn",
            "Scherpenisse",
            "Scherpenzeel",
            "Scheveningen",
            "Schiebroek",
            "Schiedam",
            "Schiermonnikoog",
            "Schijndel",
            "Schilberg",
            "Schildwolde",
            "Schimmert",
            "Schinnen",
            "Schinveld",
            "Schiphol-Rijk",
            "Schipluiden",
            "Schoondijke",
            "Schoonebeek",
            "Schoonhoven",
            "Schoonoord",
            "Schoonrewoerd",
            "Schoorl",
            "Schoorldam",
            "Schouwen-Duiveland",
            "Schutsboom",
            "Sellingen",
            "Selwerd",
            "Serooskerke",
            "Sevenum",
            "Sexbierum",
            "Sibbe",
            "Siddeburen",
            "Sijbekarspel",
            "Silvolde",
            "Simpelveld",
            "Sint Annaparochie",
            "Sint Anthonis",
            "Sint Jacobiparochie",
            "Sint Jansklooster",
            "Sint Joost",
            "Sint Laurens",
            "Sint Maartenszee",
            "Sint Nicolaasga",
            "Sint Odiliënberg",
            "Sint Pancras",
            "Sint Philipsland",
            "Sint Willebrord",
            "Sint-Michielsgestel",
            "Sint-Oedenrode",
            "Sintjohannesga",
            "Sittard",
            "Sleen",
            "Sliedrecht",
            "Slikkerveer",
            "Slochteren",
            "Slootdorp",
            "Sloten",
            "Sloterdijk",
            "Slotermeer",
            "Slotervaart (former borough)",
            "Sluis",
            "Sluiskil",
            "Sluisoord",
            "Sneek",
            "Soerendonk",
            "Soest",
            "Soestdijk",
            "Someren",
            "Someren-Eind",
            "Son",
            "Spaarndam-Oost",
            "Spaarndam-West",
            "Spaarnwoude",
            "Spainkbos",
            "Spakenburg",
            "Spanbroek",
            "Spangen",
            "Spaubeek",
            "Spechtenkamp",
            "Speelheide",
            "Spekholzerheide",
            "Spierdijk",
            "Spijk",
            "Spijkenisse",
            "Spijkerboor",
            "Spoorwijk",
            "Sprengenbos",
            "Sprengenweg-Noord",
            "Sprenkelaar",
            "Sprundel",
            "St. Johns",
            "Staatsliedenkwartier",
            "Stadbroek",
            "Stadsfenne",
            "Stadskanaal",
            "Stampersgat",
            "Standdaarbuiten",
            "Staphorst",
            "Starnmeer",
            "Statenkwartier",
            "Stavenisse",
            "Stede Broec",
            "Steenbergen",
            "Steenderen",
            "Steensel",
            "Steenwijk",
            "Steenwijkerwold",
            "Stegeslag",
            "Stein",
            "Stein",
            "Stepekolk",
            "Sterrenberg",
            "Stichtse Vecht",
            "Stiens",
            "Stierop",
            "Stolwijk",
            "Stompetoren",
            "Stramproy",
            "Strijen",
            "Strijp",
            "Stroe",
            "Stroet",
            "Sûdwest Fryslân",
            "Surhuisterveen",
            "Tegelen",
            "Ten Boer",
            "Ter Apel",
            "Terborg",
            "Terbregge",
            "Terdiek",
            "Terheijden",
            "Ternaard",
            "Terneuzen",
            "Terschuur",
            "Terwinselen",
            "Terwolde",
            "Teteringen",
            "Texel",
            "The Bottom",
            "The Hague",
            "Theereheide",
            "Tholen",
            "Thorn",
            "Tiel",
            "Tienray",
            "Tijnje",
            "Tilburg",
            "Tinga",
            "Tivoli",
            "Tolkamer",
            "Tongelre",
            "Tricht",
            "Tubbergen",
            "Tuikwerd",
            "Tuindorp",
            "Tuindorp Oostzaan",
            "Tuitjenhorn",
            "Tuk",
            "Tweede Exloërmond",
            "Twekkelerveld",
            "Twello",
            "Twijzel",
            "Twijzelerheide",
            "Twisk",
            "Tynaarlo",
            "Tytsjerk",
            "Tzummarum",
            "Ubachsberg",
            "Uddel",
            "Uden",
            "Udenhout",
            "Uffelte",
            "Ugchelen",
            "Uitdam",
            "Uitgeest",
            "Uithoorn",
            "Uithuizen",
            "Uithuizermeeden",
            "Ulestraten",
            "Ulrum",
            "Ulvenhout",
            "Ureterp",
            "Urk",
            "Urmond",
            "Ursem",
            "Usquert",
            "Utrecht",
            "Vaals",
            "Vaartbroek",
            "Vaassen",
            "Valburg",
            "Valendries",
            "Valkenburg",
            "Valkenburg",
            "Valkenburg aan de Geul",
            "Valkenswaard",
            "Valkkoog",
            "Valthe",
            "Valthermond",
            "Van Ewijcksluis",
            "Varsseveld",
            "Vatrop",
            "Veen",
            "Veendam",
            "Veenendaal",
            "Veenhuizen",
            "Veenoord",
            "Veghel",
            "Veldhoven",
            "Veldhuizen",
            "Velp",
            "Velsen",
            "Velsen-Zuid",
            "Velserbroek",
            "Veltum",
            "Ven",
            "Venhorst",
            "Venhuizen",
            "Venlo",
            "Venray",
            "Vianen",
            "Vierpolders",
            "Vijfhuizen",
            "Vijlen",
            "Villapark",
            "Vinkebrug",
            "Vinkeveen",
            "Vlaardingen",
            "Vlagtwedde",
            "Vledder",
            "Vleuten",
            "Vliedberg",
            "Vlierden",
            "Vlietwijk",
            "Vlissingen",
            "Vlodrop",
            "Vlokhoven",
            "Voerendaal",
            "Vogelenzang",
            "Vogelkwartier",
            "Vogelwijk",
            "Voldijn",
            "Volendam",
            "Volkel",
            "Vollenhove",
            "Vondelwijk",
            "Voorburg",
            "Voorhout",
            "Voorschoten",
            "Voorst",
            "Voorthuizen",
            "Vorden",
            "Vorstenbosch",
            "Vredenburg",
            "Vreeswijk",
            "Vreewijk",
            "Vrieheide",
            "Vries",
            "Vriezenveen",
            "Vroomshoop",
            "Vught",
            "Vuren",
            "Waadhoeke",
            "Waalre",
            "Waalwijk",
            "Waarde",
            "Waardenburg",
            "Waarder",
            "Waarland",
            "Waddinxveen",
            "Wadway",
            "Wagenberg",
            "Wagenborgen",
            "Wageningen",
            "Wanroij",
            "Wanssum",
            "Wapenveld",
            "Warder",
            "Warffum",
            "Warga",
            "Warmenhuizen",
            "Warmond",
            "Warnsveld",
            "Waspik",
            "Wassenaar",
            "Waterakkers",
            "Waterdonken",
            "Watergang",
            "Watergraafsmeer",
            "Wateringskant",
            "Waterland",
            "Waubach",
            "Waver",
            "Weerestein",
            "Weert",
            "Weesp",
            "Weidevenne",
            "Weijpoort",
            "Weiteveen",
            "Wekerom",
            "Welberg",
            "Welgelegen",
            "Well",
            "Wellerlooi",
            "Wemeldinge",
            "Werkendam",
            "Werkhoven",
            "Wernhout",
            "Wervershoof",
            "Wessem",
            "West Betuwe",
            "West-Graftdijk",
            "West-Knollendam",
            "West-Souburg",
            "West-Terschelling",
            "Westbeemster",
            "Westdorpe",
            "Westeinde",
            "Westeneng",
            "Westenenk",
            "Westerbork",
            "Westerhaar-Vriezenveensewijk",
            "Westerhoven",
            "Westerland",
            "Westerlee",
            "Westervoort",
            "Westerwolde",
            "Westerzicht",
            "Westkapelle",
            "Westlaren",
            "Westmaas",
            "Westwoud",
            "Westzaan",
            "Weteringbrug",
            "Wierden",
            "Wieringerwaard",
            "Wieringerwerf",
            "Wijbosch",
            "Wijchen",
            "Wijdemeren",
            "Wijdenes",
            "Wijdewormer",
            "Wijhe",
            "Wijk aan Zee",
            "Wijk bij Duurstede",
            "Wijlre",
            "Wijnandsrade",
            "Wijnjewoude",
            "Wildervank",
            "Wilhelminadorp",
            "Wilnis",
            "Wimmenum",
            "Windwardside",
            "Winkel",
            "Winkewijert",
            "Winschoten",
            "Winsum",
            "Winterswijk",
            "Wirdum",
            "Wisch",
            "Wisselaar",
            "Wissenkerke",
            "Witmarsum",
            "Woensdrecht",
            "Woenselse Heide",
            "Woerden",
            "Wognum",
            "Wolder",
            "Wolfheze",
            "Wolfsbos",
            "Wolphaartsdijk",
            "Wolvega",
            "Wommels",
            "Woolde",
            "Wooldrik",
            "Workum",
            "Wormerland",
            "Wormerveer",
            "Woubrugge",
            "Woudenberg",
            "Woudhuis",
            "Woudrichem",
            "Woudsend",
            "Wouw",
            "Yerseke",
            "Ypenburg",
            "Ysselsteyn",
            "Zaamslag",
            "Zaandam",
            "Zaandijk",
            "Zaanstad",
            "Zaltbommel",
            "Zandberg",
            "Zandvoort",
            "Zeddam",
            "Zeeland",
            "Zeelst",
            "Zeewolde",
            "Zegge",
            "Zeilberg",
            "Zeist",
            "Zelhem",
            "Zesgehuchten",
            "Zetten",
            "Zevenaar",
            "Zevenbergschen Hoek",
            "Zevenhoven",
            "Zevenhuizen",
            "Zierikzee",
            "Zijtaart",
            "Zion's Hill",
            "Zoelen",
            "Zoetermeer",
            "Zoeterwoude-Dorp",
            "Zonderwijk",
            "Zonnemaat",
            "Zoutelande",
            "Zoutkamp",
            "Zuid-Beijerland",
            "Zuid-Berghuizen",
            "Zuid-Scharwoude",
            "Zuidbroek",
            "Zuiderburen",
            "Zuidermeer",
            "Zuidhorn",
            "Zuidlaren",
            "Zuidwijk",
            "Zuilichem",
            "Zundert",
            "Zutphen",
            "Zwaag",
            "Zwaagdijk-Oost",
            "Zwaagdijk-West",
            "Zwaanshoek",
            "Zwijndrecht",
            "Zwolle"
        ]
    },
    {
        "label": "New Caledonia",
        "code": "NC",
        "phone": "687",
        "cities": [
            "Bourail",
            "Dumbéa",
            "Houaïlou",
            "Koné",
            "Le Mont-Dore",
            "Lifou",
            "Maré",
            "Nouméa",
            "Païta",
            "Poindimié"
        ]
    },
    {
        "label": "New Zealand",
        "code": "NZ",
        "phone": "64",
        "cities": [
            "Ahipara",
            "Amberley",
            "Arrowtown",
            "Ashburton",
            "Ashburton District",
            "Auckland",
            "Balclutha",
            "Blenheim",
            "Bluff",
            "Brightwater",
            "Brooklyn",
            "Bulls",
            "Burnham",
            "Cambridge",
            "Castlepoint",
            "Christchurch",
            "Christchurch City",
            "Clutha District",
            "Coromandel",
            "Cromwell",
            "Darfield",
            "Dargaville",
            "Dunedin",
            "Edgecumbe",
            "Eltham",
            "Far North District",
            "Foxton",
            "Geraldine",
            "Gisborne",
            "Gore",
            "Greymouth",
            "Hamilton",
            "Hastings",
            "Hawera",
            "Hokitika",
            "Horowhenua District",
            "Invercargill",
            "Kaiapoi",
            "Kaipara District",
            "Kaitaia",
            "Kapiti Coast District",
            "Katikati",
            "Kawakawa",
            "Kawerau",
            "Kelburn",
            "Kerikeri",
            "Khandallah",
            "Kingston",
            "Leeston",
            "Levin",
            "Lincoln",
            "Lower Hutt",
            "Mackenzie District",
            "Maketu",
            "Manawatu District",
            "Mangere",
            "Manukau City",
            "Mapua",
            "Masterton",
            "Matamata",
            "Maungatapere",
            "Methven",
            "Milton",
            "Moerewa",
            "Motueka",
            "Muriwai Beach",
            "Murrays Bay",
            "Murupara",
            "Napier",
            "Nelson",
            "New Plymouth",
            "New Plymouth District",
            "Ngaruawahia",
            "Ngatea",
            "Ngunguru",
            "North Shore",
            "Oamaru",
            "Opotiki",
            "Opunake",
            "Otaki",
            "Otorohanga",
            "Oxford",
            "Paeroa",
            "Paihia",
            "Pakuranga",
            "Palmerston North",
            "Papakura",
            "Papatowai",
            "Parakai",
            "Paraparaumu",
            "Patea",
            "Petone",
            "Picton",
            "Pleasant Point",
            "Porirua",
            "Portobello",
            "Prebbleton",
            "Pukekohe East",
            "Queenstown",
            "Raglan",
            "Rakaia",
            "Red Hill",
            "Richmond",
            "Riverton",
            "Rolleston",
            "Rosebank",
            "Rothesay Bay",
            "Rotorua",
            "Ruakaka",
            "Selwyn District",
            "South Taranaki District",
            "South Waikato District",
            "South Wairarapa District",
            "Southland District",
            "Taipa",
            "Tairua",
            "Takaka",
            "Takanini",
            "Tamaki",
            "Taradale",
            "Taupo",
            "Tauranga",
            "Te Anau",
            "Te Kauwhata",
            "Thames",
            "Timaru",
            "Timaru District",
            "Tinwald",
            "Titirangi",
            "Tokoroa",
            "Turangi",
            "Upper Hutt",
            "Waihi",
            "Waihi Beach",
            "Waimakariri District",
            "Waimate North",
            "Waiouru",
            "Waipawa",
            "Wairoa",
            "Waitakere",
            "Waitangi",
            "Waitara",
            "Waiuku",
            "Wakefield",
            "Wanaka",
            "Wanganui",
            "Warkworth",
            "Wellington",
            "Wellington City",
            "Wellsford",
            "Westport",
            "Whakatane",
            "Whangamata",
            "Whangarei",
            "Whitianga",
            "Winton",
            "Wiri",
            "Woodend"
        ]
    },
    {
        "label": "Nicaragua",
        "code": "NI",
        "phone": "505",
        "cities": [
            "Achuapa",
            "Acoyapa",
            "Altagracia",
            "Belén",
            "Bluefields",
            "Boaco",
            "Bocana de Paiwas",
            "Bonanza",
            "Buenos Aires",
            "Camoapa",
            "Cárdenas",
            "Catarina",
            "Chichigalpa",
            "Chinandega",
            "Cinco Pinos",
            "Ciudad Antigua",
            "Ciudad Darío",
            "Ciudad Sandino",
            "Comalapa",
            "Condega",
            "Corinto",
            "Corn Island",
            "Cuapa",
            "Dipilto",
            "Diriá",
            "Diriamba",
            "Diriomo",
            "Dolores",
            "El Almendro",
            "El Ayote",
            "El Crucero",
            "El Cuá",
            "El Jicaral",
            "El Jícaro",
            "El Rama",
            "El Realejo",
            "El Rosario",
            "El Sauce",
            "El Tortuguero",
            "El Viejo",
            "Estelí",
            "Granada",
            "Greytown",
            "Jalapa",
            "Jinotega",
            "Jinotepe",
            "Jiquilillo",
            "Juigalpa",
            "Kukrahill",
            "La Concepción",
            "La Concordia",
            "La Conquista",
            "La Cruz de Río Grande",
            "La Libertad",
            "La Paz Centro",
            "La Paz de Carazo",
            "La Trinidad",
            "Laguna de Perlas",
            "Larreynaga",
            "Las Praderas",
            "Las Sabanas",
            "León",
            "LLano de La Cruz",
            "Macuelizo",
            "Managua",
            "Masachapa",
            "Masatepe",
            "Masaya",
            "Matagalpa",
            "Matiguás",
            "Morrito",
            "Moyogalpa",
            "Mozonte",
            "Muelle de los Bueyes",
            "Municipio de Altagracia",
            "Municipio de Belén",
            "Municipio de Buenos Aires",
            "Municipio de Cárdenas",
            "Municipio de Masatepe",
            "Municipio de Moyogalpa",
            "Municipio de Nandasmo",
            "Municipio de Niquinohomo",
            "Municipio de Potosí",
            "Municipio de Rivas",
            "Municipio de San Francisco del Norte",
            "Municipio de San Jorge",
            "Municipio de San Juan de Oriente",
            "Municipio de San Juan del Sur",
            "Municipio de San Marcos",
            "Municipio de Tola",
            "Murra",
            "Muy Muy",
            "Nagarote",
            "Nandaime",
            "Nandasmo",
            "Nindirí",
            "Niquinohomo",
            "Nueva Guinea",
            "Ocotal",
            "Palacagüina",
            "Posoltega",
            "Potosí",
            "Prinzapolka",
            "Pueblo Nuevo",
            "Puerto Cabezas",
            "Puerto Morazán",
            "Quezalguaque",
            "Quilalí",
            "Río Blanco",
            "Rivas",
            "San Carlos",
            "San Dionisio",
            "San Fernando",
            "San Jorge",
            "San José de Bocay",
            "San José de Cusmapa",
            "San José de los Remates",
            "San Juan de Limay",
            "San Juan de Oriente",
            "San Juan de Río Coco",
            "San Juan del Sur",
            "San Lorenzo",
            "San Lucas",
            "San Marcos",
            "San Miguelito",
            "San Rafael del Norte",
            "San Rafael del Sur",
            "San Ramón",
            "San Sebastián de Yalí",
            "Santa Lucía",
            "Santa María",
            "Santa Rosa del Peñón",
            "Santa Teresa",
            "Santo Domingo",
            "Santo Tomás",
            "Santo Tomás del Norte",
            "Siuna",
            "Somotillo",
            "Somoto",
            "Telica",
            "Telpaneca",
            "Terrabona",
            "Teustepe",
            "Ticuantepe",
            "Tipitapa",
            "Tisma",
            "Tola",
            "Totogalpa",
            "Valle San Francisco",
            "Villa El Carmen",
            "Villa Sandino",
            "Waslala",
            "Waspán",
            "Wiwilí",
            "Yalagüina"
        ]
    },
    {
        "label": "Niger",
        "code": "NE",
        "phone": "227",
        "cities": [
            "Abalak",
            "Agadez",
            "Aguié",
            "Alaghsas",
            "Arlit",
            "Ayorou",
            "Balleyara",
            "Bilma",
            "Birni N Konni",
            "Boboye Department",
            "Bouza",
            "Dakoro",
            "Département d' Illéla",
            "Département d’Aguié",
            "Département de Bilma",
            "Département de Birni Nkonni",
            "Département de Bouza",
            "Département de Dakoro",
            "Département de Diffa",
            "Département de Dogondoutchi",
            "Département de Dosso",
            "Département de Filingué",
            "Département de Gaya",
            "Département de Gouré",
            "Département de Kantché",
            "Département de Keïta",
            "Département de Loga",
            "Département de Madaoua",
            "Département de Maïné-Soroa",
            "Département de Mayahi",
            "Département de Nguigmi",
            "Département de Ouallam",
            "Département de Say",
            "Département de Tahoua",
            "Département de Tânout",
            "Département de Tchin-Tabaraden",
            "Département de Tchirozérine",
            "Département de Tessaoua",
            "Diffa",
            "Dogondoutchi",
            "Dosso",
            "Filingué",
            "Gaya",
            "Gouré",
            "Guidan Roumdji Department",
            "Illéla",
            "Keïta",
            "Kollo",
            "Kollo Department",
            "Madaoua",
            "Madarounfa",
            "Magaria",
            "Maïné Soroa",
            "Maradi",
            "Matamey",
            "Mayahi",
            "Mirriah",
            "Nguigmi",
            "Ouallam",
            "Say",
            "Tahoua",
            "Tanout",
            "Tchintabaraden",
            "Téra",
            "Tera Department",
            "Tessaoua",
            "Tibiri",
            "Tillabéri",
            "Tillaberi Department",
            "Zinder"
        ]
    },
    {
        "label": "Nigeria",
        "code": "NG",
        "phone": "234",
        "cities": [
            "Aba",
            "Abakaliki",
            "Abalama",
            "Abeokuta",
            "Abocho",
            "Abonnema",
            "Abraka",
            "Abuja",
            "Adani",
            "Ado Odo",
            "Ado-Ekiti",
            "Adoru",
            "Afikpo",
            "Agbabu",
            "Agbor",
            "Agenebode",
            "Ago Are",
            "Agulu",
            "Ahoada",
            "Ajasse Ipo",
            "Akankpa",
            "Ake-Eze",
            "Akko",
            "Aku",
            "Akure",
            "Alapa",
            "Aliade",
            "Amagunze",
            "Amaigbo",
            "Amassoma",
            "Amper",
            "Anchau",
            "Anka",
            "Ankpa",
            "Apapa",
            "Apomu",
            "Aramoko-Ekiti",
            "Argungu",
            "Arochukwu",
            "Asaba",
            "Asokoro",
            "Atani",
            "Auchi",
            "Auna",
            "Awgu",
            "Awka",
            "Azare",
            "Babana",
            "Babura",
            "Badagry",
            "Badeggi",
            "Bagudo",
            "Baissa",
            "Bama",
            "Bamburu",
            "Bara",
            "Baro",
            "Bauchi",
            "Beli",
            "Bena",
            "Bende",
            "Benin City",
            "Benisheikh",
            "Billiri",
            "Bin Yauri",
            "Binji",
            "Birnin Kebbi",
            "Birnin Kudu",
            "Birniwa",
            "Biu",
            "Bode Saadu",
            "Boi",
            "Boju",
            "Bokani",
            "Bomadi",
            "Bori",
            "Bornu Yassu",
            "Buga",
            "Bugana",
            "Buguma",
            "Bukuru",
            "Bununu",
            "Burumburum",
            "Burutu",
            "Calabar",
            "Chokocho",
            "Dabai",
            "Dadiya",
            "Dakingari",
            "Damasak",
            "Damaturu",
            "Damboa",
            "Dan Gora",
            "Dan Sadau",
            "Dange",
            "Danja",
            "Dankalwa",
            "Dankama",
            "Dapchi",
            "Darazo",
            "Dass",
            "Daura",
            "Daura",
            "Deba",
            "Dekina",
            "Dengi",
            "Dikwa",
            "Dindima",
            "Disina",
            "Doma",
            "Dukku",
            "Duku",
            "Dutse",
            "Dutsen Wai",
            "Dutsin-Ma",
            "Ebute Ikorodu",
            "Effium",
            "Efon-Alaaye",
            "Egbe",
            "Eha Amufu",
            "Ejigbo",
            "Ejirin",
            "Eket",
            "Ekpoma",
            "Elele",
            "Emure-Ekiti",
            "Enugu",
            "Enugu-Ezike",
            "Enugu-Ukwu",
            "Epe",
            "Esuk Oron",
            "Ete",
            "Ezza-Ohu",
            "Fiditi",
            "Fika",
            "Funtua",
            "Gabarin",
            "Gagarawa",
            "Gakem",
            "Gamboru",
            "Gandi",
            "Ganye",
            "Garki",
            "Garko",
            "Gashua",
            "Gassol",
            "Gaya",
            "Gboko",
            "Gbongan",
            "Geidam",
            "Gembu",
            "Gombe",
            "Gombi",
            "Goniri",
            "Gora",
            "Gorgoram",
            "Goronyo",
            "Gujba",
            "Gulma",
            "Gumel",
            "Gummi",
            "Gusau",
            "Gwadabawa",
            "Gwagwalada",
            "Gwandu",
            "Gwaram",
            "Gwaram",
            "Gwarinpa",
            "Gwasero",
            "Gwio Kura",
            "Gwoza",
            "Hadejia",
            "Hinna",
            "Holma",
            "Hunkuyi",
            "Ibadan",
            "Ibeto",
            "Ibi",
            "Icheu",
            "Idah",
            "Idanre",
            "Idi Iroko",
            "Ifaki",
            "Ifo",
            "Ifon",
            "Igarra",
            "Igbara-Odo",
            "Igbeti",
            "Igbo-Ora",
            "Igbo-Ukwu",
            "Igboho",
            "Igbor",
            "Igede-Ekiti",
            "Ihiala",
            "Iho",
            "Ijebu-Ife",
            "Ijebu-Igbo",
            "Ijebu-Jesa",
            "Ijebu-Ode",
            "Ijero-Ekiti",
            "Ikang",
            "Ikeja",
            "Ikem",
            "Ikere-Ekiti",
            "Ikire",
            "Ikirun",
            "Ikot Ekpene",
            "Ila Orangun",
            "Ilare",
            "Ilaro",
            "Ile-Ife",
            "Ilesa",
            "Illela",
            "Illushi",
            "Ilobu",
            "Ilorin",
            "Imeko",
            "Inisa",
            "Iperu",
            "Ipoti",
            "Isanlu-Itedoijowa",
            "Isara",
            "Ise-Ekiti",
            "Isieke",
            "Itu",
            "Iwo",
            "Jalingo",
            "Jebba",
            "Jega",
            "Jibia",
            "Jikamshi",
            "Jikwoyi",
            "Jimeta",
            "Jos",
            "Kabba",
            "Kachia",
            "Kaduna",
            "Kafanchan",
            "Kafarati",
            "Kafin Hausa",
            "Kagoro",
            "Kaiama",
            "Kajuru",
            "Kaltungo",
            "Kamba",
            "Kangiwa",
            "Kankara",
            "Kano",
            "Kari",
            "Karu",
            "Katsina",
            "Kaura Namoda",
            "Kazaure",
            "Keffi",
            "Kende",
            "Kisi",
            "Kiyawa",
            "Konkwesso",
            "Kontagora",
            "Koton-Karfe",
            "Kubwa",
            "Kujama",
            "Kuje",
            "Kukawa",
            "Kumagunnam",
            "Kumo",
            "Kurunduma",
            "Kusheriki",
            "Kuta",
            "Kwale",
            "Kwali",
            "Kwatarkwashi",
            "Kwolla",
            "Lafia",
            "Lafiagi",
            "Lagos",
            "Lajere",
            "Lalupon",
            "Lame",
            "Langtang",
            "Lapai",
            "Lau",
            "Lere",
            "Lere",
            "Lokoja",
            "Machina",
            "Madagali",
            "Madala",
            "Madara",
            "Magumeri",
            "Mahuta",
            "Maiduguri",
            "Maitama",
            "Maiyama",
            "Makoko",
            "Makurdi",
            "Mallammaduri",
            "Mando",
            "Marte",
            "Maru",
            "Mashi",
            "Mayo-Belwa",
            "Mberubu",
            "Minna",
            "Miringa",
            "Misau",
            "Modakeke",
            "Monguno",
            "Moriki",
            "Mubi",
            "Mutum Biyu",
            "Nafada",
            "Nasarawa",
            "New Shagunnu",
            "Ngala",
            "Ngurore",
            "Nguru",
            "Nkpor",
            "Nnewi",
            "Nsukka",
            "Numan",
            "Obiaruku",
            "Obolo-Eke (1)",
            "Obonoma",
            "Ochobo",
            "Ode",
            "Offa",
            "Ogaminana",
            "Ogbomoso",
            "Ogurugu",
            "Oguta",
            "Ogwashi-Uku",
            "Ohafia-Ifigh",
            "Oke Ila",
            "Oke Mesi",
            "Okeho",
            "Okene",
            "Okigwe",
            "Okuta",
            "Olupona",
            "Omoku",
            "Omu-Aran",
            "Omuo-Ekiti",
            "Ondo",
            "Onitsha",
            "Onne",
            "Opi",
            "Opobo",
            "Ore",
            "Orerokpe",
            "Orita Eruwa",
            "Orlu",
            "Orodo",
            "Osogbo",
            "Otan Ayegbaju",
            "Otukpa",
            "Owerri",
            "Owo",
            "Owode",
            "Oyan",
            "Oyo",
            "Ozubulu",
            "Pankshin",
            "Panyam",
            "Patani",
            "Patigi",
            "Pindiga",
            "Port Harcourt",
            "Potiskum",
            "Rabah",
            "Ringim",
            "Riti",
            "Ruma",
            "Rumuokoro",
            "Runka",
            "Sade",
            "Saki",
            "Samamiya",
            "Saminaka",
            "Sapele",
            "Sauri",
            "Shaffa",
            "Shanga",
            "Shani",
            "Siluko",
            "Soba",
            "Sofo-Birnin-Gwari",
            "Sokoto",
            "Suleja",
            "Suya",
            "Takum",
            "Tambuwal",
            "Tegina",
            "Tokombere",
            "Toungo",
            "Tsafe",
            "Twon-Brass",
            "Uba",
            "Ubiaja",
            "Udi",
            "Uga",
            "Ugbokpo",
            "Ugep",
            "Ughelli",
            "Ukata",
            "Umuahia",
            "Umunede",
            "Uromi",
            "Uruobo-Okija",
            "Uyo",
            "Vom",
            "Wagini",
            "Wamba",
            "Warri",
            "Wasagu",
            "Wawa",
            "Wukari",
            "Wurno",
            "Wuse",
            "Wuyo",
            "Yajiwa",
            "Yamrat",
            "Yanda Bayo",
            "Yandev",
            "Yashikera",
            "Yelwa",
            "Yenagoa",
            "Yola",
            "Yuli",
            "Zadawa",
            "Zaki Biam",
            "Zalanga",
            "Zaria",
            "Zuba",
            "Zungeru",
            "Zuru"
        ]
    },
    {
        "label": "Niue",
        "code": "NU",
        "phone": "683",
        "cities": []
    },
    {
        "label": "Norfolk Island",
        "code": "NF",
        "phone": "672",
        "cities": []
    },
    {
        "label": "North Korea",
        "code": "KP",
        "phone": "850",
        "cities": [
            "Anak",
            "Anbyŏn-ŭp",
            "Anju",
            "Aoji",
            "Ayang-ni",
            "Chaeryŏng-ŭp",
            "Changgang-gun",
            "Changyŏn",
            "Chasŏng",
            "Chongjin",
            "Chŏngju",
            "Chŏngju-gun",
            "Chunghwa",
            "Haeju",
            "Hamhŭng",
            "Hau-ri",
            "Hoemul-li",
            "Hoeryŏng",
            "Hoeyang",
            "Hongwŏn",
            "Hŭkkyo-ri",
            "Hŭngnam",
            "Hwangju-ŭp",
            "Hyesan",
            "Hyesan-dong",
            "Iwŏn-ŭp",
            "Kaesŏng",
            "Kangdong-ŭp",
            "Kanggye",
            "Kapsan-ŭp",
            "Kilju",
            "Kimch’aek-si",
            "Koksan",
            "Komusan Il-tong",
            "Kosan",
            "Kowŏn-ŭp",
            "Kujang-ŭp",
            "Kusŏng",
            "Kwaksan",
            "Kyŏngsŏng",
            "Kyŏngwŏn",
            "Manp’o",
            "Musan-ŭp",
            "Namp’o",
            "Namyang-dong",
            "Nanam",
            "Ongjin",
            "Onsŏng",
            "P’yŏngsŏng",
            "Panghyŏn-dong",
            "Pukchil-lodongjagu",
            "Puryŏng",
            "Pyŏksŏng-ŭp",
            "Pyongyang",
            "Sakchu-ŭp",
            "Samho-rodongjagu",
            "Sangsŏng-ni",
            "Sariwŏn",
            "Sil-li",
            "Sinanju",
            "Sinmak",
            "Sinsang-ni",
            "Sinŭiju",
            "Sŏnbong",
            "Songnim",
            "Sunan",
            "Sunch’ŏn",
            "Sŭngam-nodongjagu",
            "Sŭngho 1-tong",
            "Sŭngjibaegam",
            "T’ongch’ŏn-ŭp",
            "Ŭiju",
            "Ungsang-nodongjagu",
            "Wŏnsan",
            "Yŏmju-ŭp",
            "Yŏnan-ŭp",
            "Yŏngbyŏn",
            "Yonggang-ŭp",
            "Yŏnggwang-ŭp",
            "Yuktae-dong"
        ]
    },
    {
        "label": "North Macedonia",
        "code": "MK",
        "phone": "389",
        "cities": [
            "Batinci",
            "Bedinje",
            "Belčišta",
            "Beranci",
            "Berovo",
            "Bistrica",
            "Bitola",
            "Blatec",
            "Bogdanci",
            "Bogomila",
            "Bogovinje",
            "Bojane",
            "Bosilovo",
            "Brvenica",
            "Bučin",
            "Bukovik",
            "Butel",
            "Čair",
            "Capari",
            "Čaška",
            "Cegrane",
            "Čelopek",
            "Centar Župa",
            "Češinovo",
            "Cresevo",
            "Crnilište",
            "Delcevo",
            "Delogožda",
            "Demir Hisar",
            "Demir Kapija",
            "Desovo",
            "Dobarce",
            "Dobri Dol",
            "Dobrošte",
            "Dobruševo",
            "Dolna Banjica",
            "Dolneni",
            "Dolno Količani",
            "Dolno Orizari",
            "Dolno Palčište",
            "Dolno Srpci",
            "Dračevo",
            "Drugovo",
            "Džepčište",
            "Forino",
            "Gevgelija",
            "Gjorče Petro",
            "Golema Rečica",
            "Gorno Orizari",
            "Gorno Orizari",
            "Gorobinci",
            "Gostivar",
            "Gradec",
            "Gradec",
            "Gradsko",
            "Grčec",
            "Grnčari",
            "Gurgurnica",
            "Ilinden",
            "Ilovica",
            "Ivankovci",
            "Izvor",
            "Jankovec",
            "Jegunovce",
            "Kadino",
            "Kamenjane",
            "Kanatlarci",
            "Karbinci",
            "Kavadarci",
            "Kičevo",
            "Kisela Voda",
            "Kochani",
            "Konče",
            "Kondovo",
            "Kosel",
            "Krani",
            "Kratovo",
            "Kriva Palanka",
            "Krivogashtani",
            "Krusevo",
            "Krušopek",
            "Kuklis",
            "Kukurečani",
            "Kumanovo",
            "Labunista",
            "Larce",
            "Lažani",
            "Lipkovo",
            "Lisičani",
            "Ljubin",
            "Logovardi",
            "Lojane",
            "Lozovo",
            "Lukovo",
            "Makedonska Kamenica",
            "Makedonski Brod",
            "Marino",
            "Markov Grad",
            "Matejce",
            "Mešeišta",
            "Miladinovci",
            "Miletino",
            "Miravci",
            "Mislesevo",
            "Mogila",
            "Morani",
            "Murtino",
            "Negotino",
            "Negotino",
            "Nerašte",
            "Novaci",
            "Novo Selo",
            "Oblesevo",
            "Obršani",
            "Ohrid",
            "Ohrid Opština",
            "Oktisi",
            "Oraovica",
            "Orashac",
            "Orizari",
            "Otlja",
            "Pehčevo",
            "Pirava",
            "Pirok",
            "Plasnica",
            "Podareš",
            "Poroj",
            "Prilep",
            "Probishtip",
            "Pršovce",
            "Radishani",
            "Radolista",
            "Radovis",
            "Resen",
            "Romanovci",
            "Rosoman",
            "Rostusa",
            "Rusinovo",
            "Samokov",
            "Saraj",
            "Sedlarevo",
            "Sekirnik",
            "Selce",
            "Shtip",
            "Šipkovica",
            "Skopje",
            "Slatino",
            "Slepče",
            "Sopotnica",
            "Srbica",
            "Srbinovo",
            "Sredno Konjare",
            "Star Dojran",
            "Stojakovo",
            "Strelci",
            "Strimnica",
            "Struga",
            "Strumica",
            "Studeničane",
            "Sušica",
            "Šuto Orizare",
            "Sveti Nikole",
            "Tearce",
            "Tetovo",
            "Topolčani",
            "Tuin",
            "Usje",
            "Vaksince",
            "Valandovo",
            "Vasilevo",
            "Vataša",
            "Veles",
            "Velesta",
            "Veljusa",
            "Vevčani",
            "Vinica",
            "Vladimirovo",
            "Vraneštica",
            "Vrapčište",
            "Vratnica",
            "Vrutok",
            "Zajas",
            "Zelenikovo",
            "Zelino",
            "Žitoše",
            "Zletovo",
            "Zrnovci",
            "Арачиново",
            "Идризово",
            "Јурумлери",
            "Клечовце",
            "Петровец",
            "Ранковце",
            "Слупчане",
            "Сопиште",
            "Старо Нагоричане",
            "Чучер - Сандево"
        ]
    },
    {
        "label": "Northern Mariana Islands",
        "code": "MP",
        "phone": "+1-670",
        "cities": []
    },
    {
        "label": "Norway",
        "code": "NO",
        "phone": "47",
        "cities": [
            "Å i Åfjord",
            "Åfjord",
            "Agdenes",
            "Ågotnes",
            "Åkrehamn",
            "Ål",
            "Ålesund",
            "Alstahaug",
            "Alta",
            "Alvdal",
            "Åmot",
            "Åndalsnes",
            "Andenes",
            "Andøy",
            "Åneby",
            "Årdal",
            "Årdalstangen",
            "Aremark",
            "Årnes",
            "Åros",
            "Årøysund",
            "Ås",
            "Åseral",
            "Åsgårdstrand",
            "Ask",
            "Asker",
            "Askim",
            "Askøy",
            "Askvoll",
            "Åsnes",
            "Audnedal",
            "Aukra",
            "Auli",
            "Aure",
            "Aurland",
            "Aurskog-Høland",
            "Aursmoen",
            "Austevoll",
            "Austrheim",
            "Ávanuorri",
            "Averøy",
            "Bagn",
            "Balestrand",
            "Ballangen",
            "Balsfjord",
            "Bamble",
            "Bardu",
            "Barkåker",
            "Batnfjordsøra",
            "Båtsfjord",
            "Bærum",
            "Beiarn",
            "Berg",
            "Bergen",
            "Berger",
            "Berkåk",
            "Berlevåg",
            "Billingstad",
            "Bindal",
            "Bjerkreim",
            "Bjerkvik",
            "Bjørkelangen",
            "Bjørnevatn",
            "Bjugn",
            "Blakstad",
            "Bodø",
            "Bogen",
            "Bokn",
            "Borkenes",
            "Botngård",
            "Bø",
            "Bø",
            "Bømlo",
            "Brattvåg",
            "Brekstad",
            "Bremanger",
            "Brønnøy",
            "Brønnøysund",
            "Brumunddal",
            "Bryne",
            "Dale",
            "Dale",
            "Dalen",
            "Dokka",
            "Dombås",
            "Dovre",
            "Dønna",
            "Drammen",
            "Drangedal",
            "Drøbak",
            "Dyrøy",
            "Egersund",
            "Eid",
            "Eide",
            "Eidfjord",
            "Eidsberg",
            "Eidskog",
            "Eidsvåg",
            "Eidsvoll",
            "Eigersund",
            "Eike",
            "Eikelandsosen",
            "Eivindvik",
            "Elnesvågen",
            "Elverum",
            "Enebakk",
            "Engerdal",
            "Espeland",
            "Etne",
            "Etnedal",
            "Evenes",
            "Evenskjer",
            "Evjen",
            "Fagernes",
            "Fagerstrand",
            "Farnes",
            "Farsund",
            "Fauske",
            "Færder",
            "Fedje",
            "Fet",
            "Fetsund",
            "Fillan",
            "Finnøy",
            "Finnsnes",
            "Fitjar",
            "Fjaler",
            "Fjell",
            "Fjellfoten",
            "Flå",
            "Flakstad",
            "Flatanger",
            "Flateby",
            "Flekkefjord",
            "Flesberg",
            "Flora",
            "Florø",
            "Folldal",
            "Forsand",
            "Fosnes",
            "Fossbergom",
            "Fossby",
            "Førde",
            "Fræna",
            "Fredrikstad",
            "Frekhaug",
            "Frogn",
            "Frogner",
            "Frosta",
            "Frøya",
            "Fusa",
            "Fyresdal",
            "Gamvik",
            "Gaular",
            "Gaupne",
            "Gausdal",
            "Geilo",
            "Gildeskål",
            "Giske",
            "Gjemnes",
            "Gjerdrum",
            "Gjesdal",
            "Gjøvik",
            "Gladstad",
            "Glomfjord",
            "Gloppen",
            "Gol",
            "Gran",
            "Grane",
            "Granvin",
            "Gratangen",
            "Gravdal",
            "Grong",
            "Grua",
            "Grue",
            "Gryllefjord",
            "Gulen",
            "Gullhaug",
            "Gvarv",
            "Hå",
            "Hadsel",
            "Hagavik",
            "Halden",
            "Halsa",
            "Hamar",
            "Hamarøy",
            "Hammerfest",
            "Hansnes",
            "Haram",
            "Hardbakke",
            "Hareid",
            "Harstad",
            "Hasvik",
            "Hattfjelldal",
            "Hauge i Dalane",
            "Haugesund",
            "Hauknes",
            "Hægebostad",
            "Hemne",
            "Hemnes",
            "Hemnesberget",
            "Hemsedal",
            "Hermansverk",
            "Herøy",
            "Herøy",
            "Herre",
            "Hitra",
            "Hjartdal",
            "Hjelmeland",
            "Hjelset",
            "Hobøl",
            "Hol",
            "Hole",
            "Holmestrand",
            "Holtålen",
            "Hommelvik",
            "Hommersåk",
            "Honningsvåg",
            "Hopen",
            "Hornindal",
            "Horten",
            "Hov",
            "Hønefoss",
            "Høyanger",
            "Høylandet",
            "Hundorp",
            "Hurdal",
            "Hurum",
            "Hvaler",
            "Hvittingfoss",
            "Hylkje",
            "Hyllestad",
            "Ibestad",
            "Inderøy",
            "Indre Arna",
            "Indre Fosen",
            "Innbygda",
            "Inndyr",
            "Jessheim",
            "Jevnaker",
            "Jondal",
            "Jølster",
            "Jørpeland",
            "Judaberg",
            "Justvik",
            "Kabelvåg",
            "Kåfjord",
            "Kárášjohka",
            "Karasjok",
            "Karlshus",
            "Karlsøy",
            "Karmøy",
            "Kautokeino",
            "Kinsarvik",
            "Kirkenær",
            "Kirkenes",
            "Kjøllefjord",
            "Kjøpsvik",
            "Klæbu",
            "Klepp",
            "Kløfta",
            "Knappskog",
            "Knappstad",
            "Knarvik",
            "Kolvereid",
            "Kongsberg",
            "Kongsvinger",
            "Kopervik",
            "Koppang",
            "Korgen",
            "Kragerø",
            "Kristiansand",
            "Kristiansund",
            "Krødsherad",
            "Kvalsund",
            "Kvam",
            "Kvæfjord",
            "Kvænangen",
            "Kvinesdal",
            "Kvinnherad",
            "Kviteseid",
            "Kvitsøy",
            "Kyrksæterøra",
            "Lakselv",
            "Larkollen",
            "Larsnes",
            "Larvik",
            "Lauvsnes",
            "Lavangen",
            "Lærdal",
            "Lærdalsøyri",
            "Lebesby",
            "Leikanger",
            "Leirfjord",
            "Leirsund",
            "Leirvik",
            "Leka",
            "Leknes",
            "Leland",
            "Lena",
            "Lenvik",
            "Lervik",
            "Lesja",
            "Levanger",
            "Lier",
            "Lierne",
            "Liknes",
            "Lillehammer",
            "Lillestrøm",
            "Lindås",
            "Lindesnes",
            "Lom",
            "Lonevåg",
            "Loppa",
            "Løding",
            "Lødingen",
            "Løpsmarka",
            "Lørenskog",
            "Løten",
            "Lund",
            "Lundamo",
            "Lunde",
            "Lunner",
            "Lurøy",
            "Luster",
            "Lyefjell",
            "Lyngdal",
            "Lyngen",
            "Lyngseidet",
            "Lysaker",
            "Malm",
            "Måløy",
            "Målselv",
            "Malvik",
            "Mandal",
            "Manger",
            "Marker",
            "Marnardal",
            "Masfjorden",
            "Måsøy",
            "Maura",
            "Mehamn",
            "Meland",
            "Melbu",
            "Meldal",
            "Melhus",
            "Meløy",
            "Melsomvik",
            "Meråker",
            "Midsund",
            "Midtre Gauldal",
            "Mo i Rana",
            "Modalen",
            "Modum",
            "Moelv",
            "Moen",
            "Moi",
            "Molde",
            "Mosjøen",
            "Moskenes",
            "Moss",
            "Mosterhamn",
            "Myre",
            "Mysen",
            "Namdalseid",
            "Namsos",
            "Namsskogan",
            "Nannestad",
            "Narvik",
            "Naustdal",
            "Nærbø",
            "Nærøy",
            "Nedre Eiker",
            "Nes",
            "Nes",
            "Nesbyen",
            "Neskollen",
            "Nesna",
            "Nesodden",
            "Nesoddtangen",
            "Nesseby",
            "Nesset",
            "Nissedal",
            "Nittedal",
            "Nodeland",
            "Nome",
            "Nord-Aurdal",
            "Nord-Fron",
            "Nord-Odal",
            "Norddal",
            "Nordfjordeid",
            "Nordkapp",
            "Nordre Land",
            "Nordreisa",
            "Nordstranda",
            "Nore og Uvdal",
            "Noresund",
            "Norheimsund",
            "Notodden",
            "Odda",
            "Olderdalen",
            "Oppdal",
            "Oppegård",
            "Orkdal",
            "Os",
            "Os",
            "Osen",
            "Oslo",
            "Osterøy",
            "Otta",
            "Overhalla",
            "Øksfjord",
            "Øksnes",
            "Ølen",
            "Ørje",
            "Ørland",
            "Ørnes",
            "Ørskog",
            "Ørsta",
            "Østre Toten",
            "Øvre Eiker",
            "Øyer",
            "Øygarden",
            "Øystese",
            "Øystre Slidre",
            "Porsanger",
            "Porsgrunn",
            "Prestestranda",
            "Prestfoss",
            "Raarvihke - Røyrvik",
            "Råde",
            "Radøy",
            "Råholt",
            "Rakkestad",
            "Rana",
            "Randaberg",
            "Ranemsletta",
            "Raufoss",
            "Rauma",
            "Rælingen",
            "Re",
            "Reine",
            "Reinsvoll",
            "Rena",
            "Rendalen",
            "Rennebu",
            "Rennesøy",
            "Rensvik",
            "Rindal",
            "Ringebu",
            "Ringerike",
            "Ringsaker",
            "Rjukan",
            "Roan",
            "Rognan",
            "Rollag",
            "Rosendal",
            "Rotnes",
            "Rødøy",
            "Rømskog",
            "Røros",
            "Rørvik",
            "Røst",
            "Røyken",
            "Røyrvik",
            "Rubbestadneset",
            "Rygge",
            "Ryggebyen",
            "Rypefjord",
            "Sagvåg",
            "Salangen",
            "Saltdal",
            "Samnanger",
            "Sand",
            "Sandane",
            "Sande",
            "Sande",
            "Sande",
            "Sandefjord",
            "Sandnes",
            "Sandnessjøen",
            "Sandøy",
            "Sandsli",
            "Sarpsborg",
            "Sauda",
            "Sauherad",
            "Sætre",
            "Sæveland",
            "Sel",
            "Selbu",
            "Selje",
            "Seljord",
            "Selvik",
            "Sem",
            "Setermoen",
            "Sigdal",
            "Siljan",
            "Sirdal",
            "Sistranda",
            "Sjøholt",
            "Sjølyststranda",
            "Sjøvegan",
            "Skålevik",
            "Skånland",
            "Skarnes",
            "Skaun",
            "Skedsmo",
            "Ski",
            "Skien",
            "Skiptvet",
            "Skjåk",
            "Skjærhalden",
            "Skjeberg",
            "Skjervøy",
            "Skodje",
            "Skoger",
            "Skogn",
            "Skogsvågen",
            "Skoppum",
            "Skotterud",
            "Skreia",
            "Skudeneshavn",
            "Skui",
            "Smøla",
            "Snåase",
            "Snåase - Snåsa",
            "Snillfjord",
            "Sogndal",
            "Sokndal",
            "Sola",
            "Solund",
            "Songdalen",
            "Sortland",
            "Søgne",
            "Sømna",
            "Søndre Land",
            "Sør-Aurdal",
            "Sør-Fron",
            "Sør-Odal",
            "Sør-Varanger",
            "Sørfold",
            "Sørland",
            "Sørreisa",
            "Sørum",
            "Sørumsand",
            "Spetalen",
            "Spydeberg",
            "Stange",
            "Stavanger",
            "Stavern",
            "Steigen",
            "Steinkjer",
            "Steinshamn",
            "Stjørdal",
            "Stjørdalshalsen",
            "Stokke",
            "Stokmarknes",
            "Stor-Elvdal",
            "Stord",
            "Stordal",
            "Storebø",
            "Storfjord",
            "Storslett",
            "Storsteinnes",
            "Strai",
            "Strand",
            "Stranda",
            "Straume",
            "Straume",
            "Straumen",
            "Straumen",
            "Stryn",
            "Sula",
            "Suldal",
            "Sund",
            "Sunndal",
            "Sunndalsøra",
            "Surnadal",
            "Sveio",
            "Svelgen",
            "Svelvik",
            "Svolvær",
            "Syfteland",
            "Sykkylven",
            "Tana",
            "Tananger",
            "Tau",
            "Terråk",
            "Time",
            "Tingvoll",
            "Tinn",
            "Tjeldsund",
            "Tjøme",
            "Tofte",
            "Tokke",
            "Tolga",
            "Tomra",
            "Tomter",
            "Tonstad",
            "Torsken",
            "Tønsberg",
            "Tranby",
            "Tranøy",
            "Træna",
            "Tretten",
            "Trofors",
            "Tromsø",
            "Trondheim",
            "Trøgstad",
            "Trysil",
            "Tveit",
            "Tydal",
            "Tynset",
            "Tysfjord",
            "Tysnes",
            "Tysvær",
            "Ulefoss",
            "Ullensaker",
            "Ullensvang",
            "Ulstein",
            "Ulsteinvik",
            "Ulsteinvik weather pws station",
            "Ulvik",
            "Utsira",
            "Vadsø",
            "Vågå",
            "Vågåmo",
            "Vågan",
            "Vågsøy",
            "Vaksdal",
            "Våler",
            "Våler",
            "Vang",
            "Vanse",
            "Vanylven",
            "Vardø",
            "Varhaug",
            "Vatne",
            "Værøy",
            "Vedavågen",
            "Vefsn",
            "Vega",
            "Vennesla",
            "Verdal",
            "Verran",
            "Vestby",
            "Vestbygd",
            "Vestnes",
            "Vestre Slidre",
            "Vestre Toten",
            "Vestvågøy",
            "Vevelstad",
            "Vigeland",
            "Vigrestad",
            "Vik",
            "Vik",
            "Vikersund",
            "Vikeså",
            "Vikevåg",
            "Vikna",
            "Vindafjord",
            "Vinje",
            "Vinstra",
            "Volda",
            "Vormedal",
            "Voss",
            "Ytre Arna",
            "Ytrebygda"
        ]
    },
    {
        "label": "Oman",
        "code": "OM",
        "phone": "968",
        "cities": [
            "‘Ibrī",
            "Adam",
            "Al Buraymī",
            "Al Khābūrah",
            "As Suwayq",
            "Bahlā’",
            "Barkā’",
            "Bawshar",
            "Bayt al ‘Awābī",
            "Bidbid",
            "Dib Dibba",
            "Haymā’",
            "Izkī",
            "Khasab",
            "Liwá",
            "Madḩā’ al Jadīdah",
            "Muscat",
            "Nizwá",
            "Oman Smart Future City",
            "Rustaq",
            "Şaḩam",
            "Şalālah",
            "Seeb",
            "Shināş",
            "Sohar",
            "Sufālat Samā’il",
            "Sur",
            "Yanqul"
        ]
    },
    {
        "label": "Pakistan",
        "code": "PK",
        "phone": "92",
        "cities": [
            "Abbottabad",
            "Adilpur",
            "Ahmadpur Sial",
            "Ahmedpur East",
            "Akora",
            "Alik Ghund",
            "Alipur Chatha",
            "Alizai",
            "Aman Garh",
            "Amirabad",
            "Arifwala",
            "Ashanagro Koto",
            "Attock Tehsil",
            "Awārān District",
            "Baddomalhi",
            "Badin",
            "Baffa",
            "Bagarji",
            "Bahawalnagar",
            "Bahawalpur",
            "Bakhri Ahmad Khan",
            "Bandhi",
            "Bannu",
            "Barishal",
            "Barkhan",
            "Bārkhān District",
            "Basirpur",
            "Basti Dosa",
            "Bat Khela",
            "Battagram",
            "Battagram District",
            "Begowala",
            "Bela",
            "Berani",
            "Bhag",
            "Bhakkar",
            "Bhalwal",
            "Bhan",
            "Bhawana",
            "Bhera",
            "Bhimbar",
            "Bhiria",
            "Bhit Shah",
            "Bhopalwala",
            "Bozdar Wada",
            "Bulri",
            "Buner District",
            "Burewala",
            "Chāgai District",
            "Chak",
            "Chak Azam Saffo",
            "Chak Jhumra",
            "Chak One Hundred Twenty Nine Left",
            "Chak Thirty-one -Eleven Left",
            "Chak Two Hundred Forty-Nine TDA",
            "Chakwal",
            "Chaman",
            "Chamber",
            "Charsadda",
            "Chawinda",
            "Cherat Cantonement",
            "Chhor",
            "Chichawatni",
            "Chiniot",
            "Chishtian",
            "Chitral",
            "Choa Saidanshah",
            "Chowki Jamali",
            "Chuhar Jamali",
            "Chuhar Kana",
            "Chunian",
            "Dadhar",
            "Dadu",
            "Daira Din Panah",
            "Dajal",
            "Dalbandin",
            "Dandot RS",
            "Daromehar",
            "Darya Khan",
            "Darya Khan Marri",
            "Daska",
            "Daud Khel",
            "Daulatpur",
            "Daultala",
            "Daur",
            "Dera Bugti",
            "Dera Bugti District",
            "Dera Ghazi Khan",
            "Dera Ismail Khan",
            "Dera Ismāīl Khān District",
            "Dhanot",
            "Dhaunkal",
            "Dhok Awan",
            "Dhoro Naro",
            "Digri",
            "Dijkot",
            "Dinan Bashnoian Wala",
            "Dinga",
            "Dipalpur",
            "Diplo",
            "Doaba",
            "Dokri",
            "Duki",
            "Dullewala",
            "Dunga Bunga",
            "Dunyapur",
            "Eminabad",
            "Faisalabad",
            "Faqirwali",
            "Faruka",
            "Fazilpur",
            "Ferozewala",
            "Fort Abbas",
            "Gadani",
            "Gambat",
            "Garh Maharaja",
            "Garhi Khairo",
            "Garhiyasin",
            "Gharo",
            "Ghauspur",
            "Ghotki",
            "Gilgit",
            "Gojra",
            "Goth Garelo",
            "Goth Phulji",
            "Goth Radhan",
            "Gujar Khan",
            "Gujranwala",
            "Gujranwala Division",
            "Gujrat",
            "Gulishah Kach",
            "Gwadar",
            "Hadali",
            "Hafizabad",
            "Hala",
            "Hangu",
            "Haripur",
            "Harnai",
            "Harnoli",
            "Harunabad",
            "Hasan Abdal",
            "Hasilpur",
            "Haveli Lakha",
            "Havelian",
            "Hazro",
            "Hingorja",
            "Hujra Shah Muqeem",
            "Hyderabad",
            "Islamabad",
            "Islamkot",
            "Jacobabad",
            "Jāfarābād District",
            "Jahanian Shah",
            "Jalalpur Jattan",
            "Jalalpur Pirwala",
            "Jām Sāhib",
            "Jampur",
            "Jamshoro",
            "Jand",
            "Jandiala Sher Khan",
            "Jaranwala",
            "Jati",
            "Jatoi Shimali",
            "Jauharabad",
            "Jhal Magsi District",
            "Jhang",
            "Jhang Sadar",
            "Jhawarian",
            "Jhelum",
            "Jhol",
            "Jiwani",
            "Johi",
            "Kabirwala",
            "Kadhan",
            "Kahna Nau",
            "Kahuta",
            "Kakad Wari Dir Upper",
            "Kalabagh",
            "Kalaswala",
            "Kalat",
            "Kalāt District",
            "Kaleke Mandi",
            "Kallar Kahar",
            "Kalur Kot",
            "Kamalia",
            "Kamar Mushani",
            "Kambar",
            "Kamoke",
            "Kamra",
            "Kandhkot",
            "Kandiari",
            "Kandiaro",
            "Kanganpur",
            "Karachi",
            "Karak",
            "Karaundi",
            "Kario Ghanwar",
            "Karor",
            "Kashmor",
            "Kasur",
            "Keshupur",
            "Keti Bandar",
            "Khadan Khak",
            "Khadro",
            "Khairpur",
            "Khairpur Mir’s",
            "Khairpur Nathan Shah",
            "Khairpur Tamiwali",
            "Khalabat",
            "Khandowa",
            "Khanewal",
            "Khanga Dogran",
            "Khangarh",
            "Khanpur",
            "Khanpur Mahar",
            "Kharan",
            "Khārān District",
            "Kharian",
            "Khewra",
            "Khurrianwala",
            "Khushab",
            "Khuzdar",
            "Khuzdār District",
            "Kohat",
            "Kohlu",
            "Kohror Pakka",
            "Kot Addu Tehsil",
            "Kot Diji",
            "Kot Ghulam Muhammad",
            "Kot Malik Barkhurdar",
            "Kot Mumin",
            "Kot Radha Kishan",
            "Kot Rajkour",
            "Kot Samaba",
            "Kot Sultan",
            "Kotli",
            "Kotli District",
            "Kotli Loharan",
            "Kotri",
            "Kulachi",
            "Kundian",
            "Kunjah",
            "Kunri",
            "Lachi",
            "Ladhewala Waraich",
            "Lahore",
            "Lakhi",
            "Lakki",
            "Lala Musa",
            "Lalian",
            "Landi Kotal",
            "Larkana",
            "Lasbela District",
            "Layyah",
            "Layyah District",
            "Liliani",
            "Lodhran",
            "Loralai",
            "Loralai District",
            "Mach",
            "Madeji",
            "Mailsi",
            "Malakwal",
            "Malakwal City",
            "Malir Cantonment",
            "Mamu Kanjan",
            "Mananwala",
            "Mandi Bahauddin",
            "Mandi Bahauddin District",
            "Mangla",
            "Mankera",
            "Mansehra",
            "Mardan",
            "Mastung",
            "Mastung District",
            "Matiari",
            "Matli",
            "Mehar",
            "Mehmand Chak",
            "Mehrabpur",
            "Mian Channun",
            "Mianke Mor",
            "Mianwali",
            "Minchinabad",
            "Mingora",
            "Miran Shah",
            "Miro Khan",
            "Mirpur Bhtoro",
            "Mirpur District",
            "Mirpur Khas",
            "Mirpur Mathelo",
            "Mirpur Sakro",
            "Mirwah Gorchani",
            "Mitha Tiwana",
            "Mithi",
            "Moro",
            "Moza Shahwala",
            "Multan",
            "Multan District",
            "Muridke",
            "Murree",
            "Mūsa Khel District",
            "Mustafabad",
            "Muzaffarābād",
            "Muzaffargarh",
            "Nabisar",
            "Nankana Sahib",
            "Narang Mandi",
            "Narowal",
            "Nasirabad",
            "Nasīrābād District",
            "Naudero",
            "Naukot",
            "Naushahra Virkan",
            "Naushahro Firoz",
            "Nawabshah",
            "Nazir Town",
            "New Bādāh",
            "New Mirpur",
            "Noorabad",
            "North Wazīristān Agency",
            "Nowshera",
            "Nowshera Cantonment",
            "Nushki",
            "Okara",
            "Ormara",
            "Pabbi",
            "Pad Idan",
            "Paharpur",
            "Pakki Shagwanwali",
            "Pakpattan",
            "Panjgūr District",
            "Pano Aqil",
            "Pasni",
            "Pasrur",
            "Pattoki",
            "Peshawar",
            "Phalia",
            "Pind Dadan Khan",
            "Pindi Bhattian",
            "Pindi Gheb",
            "Pir Jo Goth",
            "Pir Mahal",
            "Pishin",
            "Pithoro",
            "Qadirpur Ran",
            "Qila Didar Singh",
            "Qila Saifullāh District",
            "Quetta",
            "Quetta District",
            "Rabwah",
            "Rahim Yar Khan",
            "Rahimyar Khan District",
            "Raiwind",
            "Raja Jang",
            "Rajanpur",
            "Rajo Khanani",
            "Ranipur",
            "Rasulnagar",
            "Ratodero",
            "Rawala Kot",
            "Rawalpindi",
            "Rawalpindi District",
            "Renala Khurd",
            "Risalpur Cantonment",
            "Rohri",
            "Rojhan",
            "Rustam",
            "Sadiqabad",
            "Sahiwal",
            "Sakrand",
            "Samaro",
            "Sambrial",
            "Sanghar",
            "Sangla Hill",
            "Sanjwal",
            "Sann",
            "Sarai Alamgir",
            "Sarai Naurang",
            "Sarai Sidhu",
            "Sargodha",
            "Sehwan",
            "Setharja Old",
            "Shabqadar",
            "Shahdad Kot",
            "Shahdadpur",
            "Shahkot Tehsil",
            "Shahpur",
            "Shahpur Chakar",
            "Shahr Sultan",
            "Shakargarh",
            "Sharqpur",
            "Sheikhupura",
            "Shikarpur",
            "Shingli Bala",
            "Shinpokh",
            "Shorkot",
            "Shorkot",
            "Shujaabad",
            "Sialkot",
            "Sibi",
            "Sillanwali",
            "Sinjhoro",
            "Sīta Road",
            "Skardu",
            "Sobhodero",
            "Sodhra",
            "Sohbatpur",
            "South Wazīristān Agency",
            "Sukheke Mandi",
            "Sukkur",
            "Surab",
            "Surkhpur",
            "Swabi",
            "Talagang",
            "Talamba",
            "Talhar",
            "Tandlianwala",
            "Tando Adam",
            "Tando Allahyar",
            "Tando Bago",
            "Tando Jam",
            "Tando Mitha Khan",
            "Tando Muhammad Khan",
            "Tangi",
            "Tangwani",
            "Tank",
            "Taunsa",
            "Thal",
            "Tharu Shah",
            "Thatta",
            "Thul",
            "Toba Tek Singh",
            "Topi",
            "Turbat",
            "Ubauro",
            "Umarkot",
            "Umerkot",
            "Umerkot District",
            "Upper Dir",
            "Usta Muhammad",
            "Uthal",
            "Utmanzai",
            "Vihari",
            "Wah",
            "Wana",
            "Warah",
            "Warburton",
            "Wazirabad",
            "West Punjab",
            "Yazman",
            "Zafarwal",
            "Zahir Pir",
            "Zaida",
            "Zhob",
            "Zhob District",
            "Ziarat",
            "Ziārat District"
        ]
    },
    {
        "label": "Palau",
        "code": "PW",
        "phone": "680",
        "cities": [
            "Angaur State",
            "Imeong Hamlet",
            "Kayangel",
            "Kloulklubed",
            "Koror",
            "Koror Town",
            "Melekeok Village",
            "Mengellang",
            "Ngardmau",
            "Ngchemiangel",
            "Ngchesar Hamlet",
            "Ngerkeai",
            "Ngetkib",
            "Sonsorol Village",
            "Tobi Village",
            "Ulimang"
        ]
    },
    {
        "label": "Palestinian Territory Occupied",
        "code": "PS",
        "phone": "970",
        "cities": []
    },
    {
        "label": "Panama",
        "code": "PA",
        "phone": "507",
        "cities": [
            "Achutupo",
            "Agua Buena",
            "Aguadulce",
            "Aguas Blancas",
            "Ailigandí",
            "Alanje",
            "Alcalde Díaz",
            "Algarrobos Arriba",
            "Almirante",
            "Alto Boquete",
            "Alto de Jesús",
            "Alto de La Estancia",
            "Alto del Espino",
            "Altos de San Francisco",
            "Ancón",
            "Antón",
            "Arenas",
            "Arenosa",
            "Arosemena",
            "Arraiján",
            "Aserrío de Gariché",
            "Atalaya",
            "Ave María",
            "Bágala",
            "Bahía Azul",
            "Bahía Honda",
            "Bajo Boquete",
            "Bajo Corral",
            "Barnizal",
            "Barranco",
            "Barranco Adentro",
            "Barranco Colorado",
            "Barrio Guadalupe",
            "Bastimentos",
            "Bayamón",
            "Bayano",
            "Bejuco",
            "Bella Vista",
            "Besiko",
            "Bijagual",
            "Bisira",
            "Bisvalles",
            "Boca Chica",
            "Boca de Balsa",
            "Boca de Cupé",
            "Boca de Parita",
            "Boca del Monte",
            "Bocas del Toro",
            "Boquerón",
            "Boquerón",
            "Boquete",
            "Boró",
            "Breñón",
            "Brujas",
            "Buena Vista",
            "Buenos Aires",
            "Buenos Aires",
            "Bugaba",
            "Bugabita Arriba",
            "Caballero",
            "Cabra Número Uno",
            "Cabuya",
            "Cabuya",
            "Cabuya",
            "Caimitillo",
            "Caimito",
            "Caimito",
            "Caimito",
            "Calabacito",
            "Caldera",
            "Calidonia",
            "Calobre",
            "Calovébora",
            "Calzada Larga",
            "Camarón Arriba",
            "Cambutal",
            "Camogantí",
            "Cañas",
            "Cañas Gordas",
            "Cañaveral",
            "Cañazas",
            "Cañita",
            "Canto del Llano",
            "Capellanía",
            "Capira",
            "Cartí Sugdup",
            "Cativá",
            "Cauchero",
            "Cedro Arriba",
            "Celmira",
            "Cémaco",
            "Cermeño",
            "Cerro Azul",
            "Cerro Banco",
            "Cerro Cama",
            "Cerro Caña",
            "Cerro Largo",
            "Cerro Plata",
            "Cerro Punta",
            "Cerro Viejo",
            "Chame",
            "Changuinola",
            "Chepillo",
            "Chepo",
            "Chichica",
            "Chigoré",
            "Chiguirí Arriba",
            "Chilibre",
            "Chimán",
            "Chiriquí",
            "Chiriquí Grande",
            "Chitré",
            "Chumical",
            "Chupampa",
            "Churuquita Chiquita",
            "Churuquita Grande",
            "Cirí de Los Sotos",
            "Cochea Abajo",
            "Coclé",
            "Coclé del Norte",
            "Colón",
            "Contadora",
            "Cordillera",
            "Corozal",
            "Corozal",
            "Cristóbal",
            "Cucunatí",
            "Curundú",
            "David",
            "Distrito Arraiján",
            "Distrito Chiriquí Grande",
            "Distrito de Aguadulce",
            "Distrito de Alanje",
            "Distrito de Antón",
            "Distrito de Atalaya",
            "Distrito de Balboa",
            "Distrito de Barú",
            "Distrito de Bocas del Toro",
            "Distrito de Boquerón",
            "Distrito de Boquete",
            "Distrito de Bugaba",
            "Distrito de Calobre",
            "Distrito de Cañazas",
            "Distrito de Capira",
            "Distrito de Chagres",
            "Distrito de Chame",
            "Distrito de Changuinola",
            "Distrito de Chepigana",
            "Distrito de Chepo",
            "Distrito de Chimán",
            "Distrito de Chitré",
            "Distrito de Colón",
            "Distrito de David",
            "Distrito de Dolega",
            "Distrito de Donoso",
            "Distrito de Gualaca",
            "Distrito de Guararé",
            "Distrito de La Chorrera",
            "Distrito de La Mesa",
            "Distrito de La Pintada",
            "Distrito de Las Minas",
            "Distrito de Las Palmas",
            "Distrito de Las Tablas",
            "Distrito de Los Pozos",
            "Distrito de Los Santos",
            "Distrito de Macaracas",
            "Distrito de Montijo",
            "Distrito de Natá",
            "Distrito de Ocú",
            "Distrito de Olá",
            "Distrito de Panamá",
            "Distrito de Parita",
            "Distrito de Pedasí",
            "Distrito de Pesé",
            "Distrito de Pinogana",
            "Distrito de Pocrí",
            "Distrito de Portobelo",
            "Distrito de Remedios",
            "Distrito de Renacimiento",
            "Distrito de Río de Jesús",
            "Distrito de San Carlos",
            "Distrito de San Félix",
            "Distrito de San Francisco",
            "Distrito de San Lorenzo",
            "Distrito de Santa Fé",
            "Distrito de Santa Isabel",
            "Distrito de Santa María",
            "Distrito de Santiago",
            "Distrito de Soná",
            "Distrito de Taboga",
            "Distrito de Tolé",
            "Distrito de Tonosí",
            "Distrito Penonomé",
            "Divalá",
            "Dolega District",
            "El Alto",
            "El Barrito",
            "El Cacao",
            "El Cacao",
            "El Calabacito",
            "El Cañafístulo",
            "El Caño",
            "El Capurí",
            "El Carate",
            "El Cedro",
            "El Chirú",
            "El Chorrillo",
            "El Cocal",
            "El Coclá",
            "El Coco",
            "El Coco",
            "El Copé",
            "El Cortezo",
            "El Cortezo",
            "El Cristo",
            "El Ejido",
            "El Empalme",
            "El Espavé",
            "El Espino",
            "El Espino de Santa Rosa",
            "El Giral",
            "El Guabo",
            "El Guásimo",
            "El Hato",
            "El Higo",
            "El Líbano",
            "El Macano",
            "El Manantial",
            "El Marañón",
            "El María",
            "El Muñoz",
            "El Nancito",
            "El Pájaro",
            "El Palmar",
            "El Pantano",
            "El Pedregoso",
            "El Pedregoso",
            "El Peñón",
            "El Picador",
            "El Porvenir",
            "El Porvenir",
            "El Potrero",
            "El Potrero",
            "El Progreso",
            "El Retiro",
            "El Rincón",
            "El Rincón",
            "El Roble",
            "El Silencio",
            "El Tejar",
            "El Toro",
            "El Uvito",
            "El Valle de la Unión",
            "Entradero",
            "Escobal",
            "Farallón",
            "Finca Blanco",
            "Flores",
            "Garachiné",
            "Gatún",
            "Gatuncillo",
            "Gómez",
            "Gonzalillo",
            "Guabal",
            "Guabito",
            "Guaca Arriba",
            "Guadalupe",
            "Gualaca",
            "Guararé",
            "Guararé Arriba",
            "Guarumal",
            "Guarumal",
            "Guayabal",
            "Guayabito",
            "Guayabito",
            "Guzman",
            "Hato Chami",
            "Hato Corotú",
            "Hato Montaña",
            "Horconcitos",
            "Howard",
            "Isla Bastimentos",
            "Jaqué",
            "Juan Díaz",
            "Juan Díaz",
            "Kankintú",
            "Kusapín",
            "La Arena",
            "La Cabima",
            "La Chorrera",
            "La Colorada",
            "La Colorada",
            "La Colorada",
            "La Concepción",
            "La Enea",
            "La Ensenada",
            "La Ermita",
            "La Esmeralda",
            "La Esperanza",
            "La Espigadilla",
            "La Estrella",
            "La Garceana",
            "La Guinea",
            "La Herradura",
            "La Laguna",
            "La Laguna",
            "La Laja",
            "La Loma",
            "La Loma",
            "La Mesa",
            "La Mesa",
            "La Mesa",
            "La Mesa",
            "La Miel",
            "La Mitra",
            "La Montañuela",
            "La Palma",
            "La Palma",
            "La Pasera",
            "La Peña",
            "La Pesa",
            "La Pintada",
            "La Raya de Calobre",
            "La Raya de Santa María",
            "La Tiza",
            "La Trinidad",
            "La Tronosa",
            "La Yeguada",
            "Lajamina",
            "Lajas Adentro",
            "Lajas de Tolé",
            "Lajero Arriba",
            "Las Colinas",
            "Las Cruces",
            "Las Cumbres",
            "Las Guabas",
            "Las Guabas",
            "Las Guías Abajo",
            "Las Huacas",
            "Las Huacas del Quije",
            "Las Lajas",
            "Las Lajas",
            "Las Lomas",
            "Las Margaritas",
            "Las Margaritas",
            "Las Marias",
            "Las Minas",
            "Las Minas",
            "Las Palmas",
            "Las Palmas",
            "Las Palmitas",
            "Las Sabanas",
            "Las Tablas",
            "Las Trancas",
            "Las Uvas",
            "Leones Arriba",
            "Leones Arriba",
            "Lídice",
            "Limón de Tijeras",
            "Limones",
            "Llano Abajo",
            "Llano Bonito",
            "Llano de La Cruz",
            "Llano de Piedra",
            "Llano Grande",
            "Llano Grande",
            "Llano Grande",
            "Llano Largo",
            "Llano Largo",
            "Llano Marín",
            "Llano Ñopo",
            "Lolá",
            "Loma Yuca",
            "Los Algarrobos",
            "Los Algarrobos",
            "Los Anastacios",
            "Los Ángeles",
            "Los Ángeles",
            "Los Asientos",
            "Los Canelos",
            "Los Castillos",
            "Los Castillos",
            "Los Cerritos",
            "Los Cerros de Paja",
            "Los Higos",
            "Los Llanitos",
            "Los Lotes",
            "Los Naranjos",
            "Los Olivos",
            "Los Pollos",
            "Los Pozos",
            "Los Santos",
            "Macaracas",
            "Manaca Civil",
            "Manaca Norte",
            "María Chiquita",
            "Mariato District",
            "Mata del Nance",
            "Mendoza",
            "Metetí",
            "Miramar",
            "Miramar",
            "Mirono",
            "Mogollón",
            "Monagrillo",
            "Monjarás",
            "Monte Lirio",
            "Montijo",
            "Mortí",
            "Mulatupo",
            "Müna",
            "Narganá",
            "Natá",
            "Nole Düima",
            "Nombre de Dios",
            "Nuario",
            "Nuestro Amo",
            "Nueva Arenosa",
            "Nueva Esperanza",
            "Nueva Gorgona",
            "Nuevo Arraiján",
            "Nuevo Belén",
            "Nuevo Chagres",
            "Nuevo Emperador",
            "Nuevo San Carlitos",
            "Nuevo San Juan",
            "Nuevo Vigía",
            "Nurun",
            "Ocú",
            "Olá",
            "Oma",
            "Oria Arriba",
            "Pacora",
            "Paja de Sombrero",
            "Palenque",
            "Palmas Bellas",
            "Palmira",
            "Palmira",
            "Palmira",
            "Palmira Centro",
            "Panamá",
            "Paraíso",
            "Paraíso",
            "Paredón Arriba",
            "París",
            "Parita",
            "Paritilla",
            "Parque Lefevre",
            "Pásiga",
            "Paso Canoas Arriba",
            "Pedasí",
            "Pedregal",
            "Pedregal",
            "Peña Blanca",
            "Peñas Chatas",
            "Penonomé",
            "Perales",
            "Pesé",
            "Piedras Gordas",
            "Piedras Gordas",
            "Pitaloza Arriba",
            "Plan de Chorcha",
            "Playa Chiquita",
            "Playa Leona",
            "Playón Chico",
            "Plaza de Caisán",
            "Pocrí",
            "Pocrí",
            "Ponuga",
            "Portobelillo",
            "Portobelo",
            "Potrerillos Abajo",
            "Potrerillos Arriba",
            "Potrero Grande",
            "Potuga",
            "Progreso",
            "Púcuro",
            "Pueblo Nuevo",
            "Pueblo Viejo",
            "Puerto Armuelles",
            "Puerto Caimito",
            "Puerto Escondido",
            "Puerto Obaldía",
            "Puerto Pilón",
            "Puerto Vidal",
            "Punta de Burica",
            "Punta Laurel",
            "Punta Peña",
            "Punta Róbalo",
            "Quebrada Bonita Adentro",
            "Quebrada Canoa",
            "Quebrada de Oro",
            "Quebrada de Piedra",
            "Quebrada del Rosario",
            "Quebrada Tula",
            "Querévalo",
            "Quinteño",
            "Remedios",
            "Rincón",
            "Rincón Hondo",
            "Río Abajo",
            "Río Alejandro",
            "Río Congo",
            "Río de Jesús",
            "Río Duque",
            "Río Grande",
            "Río Grande",
            "Río Hato",
            "Río Hondo",
            "Río Rita",
            "Río Sereno",
            "Rodeo Viejo",
            "Rovira Arriba",
            "Sabana Grande",
            "Sabana Grande",
            "Sabanitas",
            "Sambú",
            "San Andrés",
            "San Antonio",
            "San Bartolo",
            "San Carlos",
            "San Carlos",
            "San Felipe",
            "San Félix",
            "San Francisco",
            "San Ignacio de Tupile",
            "San Isidro",
            "San José",
            "San José",
            "San José",
            "San Juan",
            "San Juan Bautista",
            "San Juan de Dios",
            "San Lorenzo",
            "San Marcelo",
            "San Miguel",
            "San Miguelito",
            "San Pablo Nuevo Abajo",
            "San Pablo Viejo Abajo",
            "San Pedro del Espino",
            "San Vicente",
            "San Vicente de Bique",
            "Santa Ana Arriba",
            "Santa Clara",
            "Santa Clara",
            "Santa Cruz",
            "Santa Fé",
            "Santa Fé",
            "Santa Isabel",
            "Santa María",
            "Santa Marta",
            "Santa Rita",
            "Santa Rita Arriba",
            "Santa Rosa",
            "Santiago de Veraguas",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Tomás",
            "Sesteadero",
            "Sioguí Abajo",
            "Sioguí Arriba",
            "Sitio Prado",
            "Soná",
            "Sorá",
            "Sortova",
            "Tebario",
            "Tijeras",
            "Tinajas",
            "Toabré",
            "Tobobe",
            "Tocumen",
            "Tolé",
            "Tonosí",
            "Tortí",
            "Toza",
            "Tres Quebradas",
            "Tubualá",
            "Unión Chocó",
            "Unión de Azuero",
            "Unión Santeña",
            "Ustupo",
            "Utirá",
            "Valle del Risco",
            "Valle Rico",
            "Vallerriquito",
            "Veladero",
            "Veracruz",
            "Viento Frío",
            "Viguí",
            "Villa Carmen",
            "Villa Lourdes",
            "Villa Rosario",
            "Villa Unida",
            "Vista Alegre",
            "Vista Hermosa",
            "Vista Hermosa",
            "Volcán",
            "Yape",
            "Yaviza",
            "Zapotillo"
        ]
    },
    {
        "label": "Papua new Guinea",
        "code": "PG",
        "phone": "675",
        "cities": [
            "Abau",
            "Aitape",
            "Aitape Lumi",
            "Alotau",
            "Angalimp South Wahgi",
            "Arawa",
            "Baiyer Mul",
            "Bogia",
            "Bulolo",
            "Central Bougainville",
            "Chuave",
            "Daru",
            "Daulo",
            "Dei",
            "Esa’ala",
            "Finschhafen",
            "Gazelle",
            "Goilala",
            "Goroka",
            "Gumine",
            "Hagen",
            "Henganofi",
            "Huon Gulf",
            "Ialibu",
            "Ialibu Pangia",
            "Ijivitari",
            "Imbonggu",
            "Jimi",
            "Kabwum",
            "Kagua Erave",
            "Kainantu",
            "Kairuku-Hiri",
            "Kandep",
            "Kandrian",
            "Kandrian Gloucester",
            "Karimui Nomane",
            "Kavieng",
            "Kerema",
            "Kerowagi",
            "Kieta",
            "Kikori",
            "Kimbe",
            "Kiriwina Goodenough",
            "Kiunga",
            "Kokoda",
            "Kokopo",
            "Komo Margarima",
            "Kompiam Ambum",
            "Koroba-Lake Kopiago",
            "Kundiawa",
            "Lae",
            "Lagaip Porgera",
            "Lorengau",
            "Lufa",
            "Madang",
            "Manus",
            "Markham",
            "Mendi",
            "Menyamya",
            "Middle Fly",
            "Middle Ramu",
            "Morehead",
            "Mount Hagen",
            "Namatanai",
            "National Capital District",
            "Nawae",
            "Nipa Kutubu",
            "North Bougainville",
            "North Fly",
            "North Wahgi",
            "Nuku",
            "Obura Wonenara",
            "Okapa",
            "Panguna",
            "Pomio",
            "Popondetta",
            "Porgera",
            "Port Moresby",
            "Rabaul",
            "Rai Coast",
            "Rigo",
            "Samarai",
            "Samarai Murua",
            "Sinasina Yonggamugl",
            "Sohe",
            "South Bougainville",
            "South Fly",
            "Sumkar",
            "Talasea",
            "Tambul Nebilyer",
            "Tari",
            "Tari Pori",
            "Telefomin",
            "Tewai Siassi",
            "Unggai Bena",
            "Usino Bundi",
            "Vanimo",
            "Vanimo Green",
            "Wabag",
            "Wapenamanda",
            "Wau"
        ]
    },
    {
        "label": "Paraguay",
        "code": "PY",
        "phone": "595",
        "cities": [
            "Abaí",
            "Acahay",
            "Altos",
            "Antequera",
            "Areguá",
            "Arquitecto Tomás Romero Pereira",
            "Arroyos y Esteros",
            "Atyrá",
            "Belén",
            "Bella Vista",
            "Bella Vista",
            "Benjamín Aceval",
            "Buena Vista",
            "Caacupé",
            "Caaguazú",
            "Caapucú",
            "Caazapá",
            "Capiatá",
            "Capiíbary",
            "Capitán Bado",
            "Capitán Miranda",
            "Capitán Pablo Lagerenza",
            "Caraguatay",
            "Carapeguá",
            "Carayaó",
            "Carmen del Paraná",
            "Cecilio Baez",
            "Cedrales",
            "Cerrito",
            "Ciudad del Este",
            "Colonia Carlos Antonio López",
            "Colonia Catuete",
            "Colonia General Alfredo Stroessner",
            "Colonia Mauricio José Troche",
            "Colonia Menno",
            "Colonia Minga Porá",
            "Colonia Neuland",
            "Colonia Nueva Germania",
            "Colonia San Lorenzo",
            "Concepción",
            "Coronel Martínez",
            "Coronel Oviedo",
            "Corpus Christi",
            "Doctor Juan León Mallorquín",
            "Doctor Juan Manuel Frutos",
            "Edelira",
            "Emboscada",
            "Encarnación",
            "Eusebio Ayala",
            "Fernando de la Mora",
            "Filadelfia",
            "Fram",
            "Fuerte Olimpo",
            "General Artigas",
            "General Delgado",
            "General Elizardo Aquino",
            "General Higinio Morínigo",
            "General José Eduvigis Díaz",
            "Guarambaré",
            "Guayaybi",
            "Hohenau",
            "Horqueta",
            "Independencia",
            "Isla Pucú",
            "Itá",
            "Itacurubí de la Cordillera",
            "Itacurubí del Rosario",
            "Itakyry",
            "Itapé",
            "Itauguá",
            "Iturbe",
            "Jesús",
            "Juan de Ayolas",
            "La Colmena",
            "La Paloma",
            "Lambaré",
            "Lima",
            "Limpio",
            "Mariano Roque Alonso",
            "Mbocayaty",
            "Mbutuý",
            "Mbuyapey",
            "Nanawua",
            "Naranjal",
            "Natalicio Talavera",
            "Natalio",
            "Nemby",
            "Nueva Esperanza",
            "Nueva Italia",
            "Obligado",
            "Paraguarí",
            "Pedro Juan Caballero",
            "Pilar",
            "Pirapó",
            "Pirayú",
            "Piribebuy",
            "Presidente Franco",
            "Puerto Mayor Otaño",
            "Puerto Rosario",
            "Quiindy",
            "Repatriación",
            "Salto del Guairá",
            "San Alberto",
            "San Antonio",
            "San Bernardino",
            "San Cosme y Damián",
            "San Isidro de Curuguaty",
            "San Joaquín",
            "San José",
            "San Juan Bautista",
            "San Juan del Paraná",
            "San Juan Nepomuceno",
            "San Lorenzo",
            "San Miguel",
            "San Patricio",
            "San Pedro de Ycuamandiyú",
            "San Pedro del Paraná",
            "Santa Elena",
            "Santa María",
            "Santa Rita",
            "Santa Rosa Misiones",
            "Santiago",
            "Sapucaí",
            "Tacuatí",
            "Tobatí",
            "Trinidad",
            "Unión",
            "Villa Elisa",
            "Villa Florida",
            "Villa Hayes",
            "Villarrica",
            "Yaguarón",
            "Yataity del Norte",
            "Ybycuí",
            "Yegros",
            "Ygatimi",
            "Yguazú",
            "Yhú",
            "Ypacarai",
            "Yuty"
        ]
    },
    {
        "label": "Peru",
        "code": "PE",
        "phone": "51",
        "cities": [
            "Abancay",
            "Acarí",
            "Acolla",
            "Aguas Verdes",
            "Ambo",
            "Andahuaylas",
            "Andoas",
            "Anta",
            "Arequipa",
            "Ascope",
            "Asentamiento Humano Nicolas de Pierola",
            "Asuncion",
            "Atalaya",
            "Atuncolla",
            "Ayabaca",
            "Ayacucho",
            "Ayaviri",
            "Ayna",
            "Azángaro",
            "Bagua Grande",
            "Bambamarca",
            "Barranca",
            "Bellavista",
            "Bellavista",
            "Bernal",
            "Borja",
            "Buenos Aires",
            "Caballococha",
            "Cahuanuyo",
            "Cajabamba",
            "Cajamarca",
            "Cajaruro",
            "Calana",
            "Calca",
            "Caleta de Carquín",
            "Callanca",
            "Callao",
            "Camaná",
            "Campoverde",
            "Candarave",
            "Cape Pantoja",
            "Carás",
            "Carhuamayo",
            "Carhuaz",
            "Carlos Fermin Fitzcarrald",
            "Cascas",
            "Catacaos",
            "Ccaquiracunca",
            "Ccolo",
            "Ccuntuma",
            "Celendín",
            "Cerro de Pasco",
            "Chachapoyas",
            "Chancay",
            "Chanchamayo",
            "Chaupimarca",
            "Chazuta",
            "Checacupe",
            "Checca",
            "Chepen",
            "Chicama",
            "Chiclayo",
            "Chignayhua",
            "Chilca",
            "Chimbote",
            "Chincha Alta",
            "Chinchero",
            "Chipispaya",
            "Chivay",
            "Chocope",
            "Chongoyape",
            "Chosica",
            "Chota",
            "Chulucanas",
            "Chupaca",
            "Chuquitira",
            "Cocachacra",
            "Coishco",
            "Combapata",
            "Concepción",
            "Conchopata",
            "Condorcanqui",
            "Coracora",
            "Cotahuasi",
            "Cullcuyre",
            "Cusco",
            "Desaguadero",
            "El Alto",
            "El Collao",
            "El Dorado",
            "Eten",
            "Ferreñafe",
            "Gran Chimu",
            "Guadalupe",
            "Hacienda Huancane",
            "Huacaybamba",
            "Huacho",
            "Hualmay",
            "Huamachuco",
            "Huancabamba",
            "Huancavelica",
            "Huancayo",
            "Huanta",
            "Huánuco",
            "Huaral",
            "Huarancante",
            "Huaraz",
            "Huarichancara",
            "Huarmey",
            "Huasahuasi",
            "Huaura",
            "Huayna Alcalde",
            "Huaytara",
            "Huayucachi",
            "Huicungo",
            "Iberia",
            "Ica",
            "Ilabaya",
            "Ilave",
            "Ilo",
            "Imperial",
            "Independencia",
            "Indiana",
            "Iquitos",
            "Jaén",
            "Jatun Orcochiri",
            "Jauja",
            "Jayanca",
            "Jayobamba",
            "Jayune",
            "Jeberos",
            "Jenaro Herrera",
            "Jepelacio",
            "Jorge Basadre",
            "Juanjuí",
            "Julcan",
            "Juli",
            "Juliaca",
            "Junín",
            "La Breita",
            "La Huaca",
            "La Oroya",
            "La Peca",
            "La Rinconada",
            "La Unión",
            "La Unión",
            "Lagunas",
            "Lamas",
            "Lambayeque",
            "Lampa",
            "Langui",
            "Laredo",
            "Las Lomas",
            "Lauricocha",
            "Layo",
            "Lima",
            "Lima Pampa",
            "Llata",
            "Llongasora",
            "Lluta",
            "Los Aquijes",
            "Macusani",
            "Mala",
            "Máncora",
            "Maranganí",
            "Maras",
            "Marcavelica",
            "Matucana",
            "Mazamari",
            "Minas de Marcona",
            "Moche",
            "Mochumí",
            "Mollendo",
            "Monsefú",
            "Moquegua",
            "Morococha",
            "Mórrope",
            "Morropon",
            "Mosoc Cancha",
            "Mosoc Llacta",
            "Motupe",
            "Moyobamba",
            "Nauta",
            "Nazca",
            "Nuevo Imperial",
            "Ñuñoa",
            "Ollantaytambo",
            "Olmos",
            "Orcopampa",
            "Orcotuna",
            "Oropesa",
            "Otuzco",
            "Oxapampa",
            "Oyon",
            "Oyotún",
            "Pacanga",
            "Pacasmayo",
            "Pacocha",
            "Padre Abad",
            "Paiján",
            "Paita",
            "Palpa",
            "Pampamarca",
            "Pampas",
            "Pangoa",
            "Papayal",
            "Paracas",
            "Paramonga",
            "Pativilca",
            "Paucar Del Sara Sara",
            "Paucartambo",
            "Paucartambo",
            "Perené",
            "Picota",
            "Picsi",
            "Pilcomay",
            "Pimentel",
            "Pisac",
            "Pisco",
            "Piura",
            "Pomabamba",
            "Provincia de Abancay",
            "Provincia de Acobamba",
            "Provincia de Acomayo",
            "Provincia de Aija",
            "Provincia de Ambo",
            "Provincia de Andahuaylas",
            "Provincia de Angaraes",
            "Provincia de Anta",
            "Provincia de Antabamba",
            "Provincia de Antonio Raymondi",
            "Provincia de Arequipa",
            "Provincia de Ayabaca",
            "Provincia de Aymaraes",
            "Provincia de Azángaro",
            "Provincia de Bagua",
            "Provincia de Bolívar",
            "Provincia de Bolognesi",
            "Provincia de Bongará",
            "Provincia de Cajabamba",
            "Provincia de Cajamarca",
            "Provincia de Cajatambo",
            "Provincia de Calca",
            "Provincia de Camaná",
            "Provincia de Canas",
            "Provincia de Canchis",
            "Provincia de Cañete",
            "Provincia de Cangallo",
            "Provincia de Canta",
            "Provincia de Carabaya",
            "Provincia de Caravelí",
            "Provincia de Carhuaz",
            "Provincia de Casma",
            "Provincia de Castilla",
            "Provincia de Castrovirreyna",
            "Provincia de Caylloma",
            "Provincia de Celendín",
            "Provincia de Chachapoyas",
            "Provincia de Chiclayo",
            "Provincia de Chincha",
            "Provincia de Chota",
            "Provincia de Chucuito",
            "Provincia de Chumbivilcas",
            "Provincia de Chupaca",
            "Provincia de Churcampa",
            "Provincia de Concepción",
            "Provincia de Condesuyos",
            "Provincia de Contralmirante Villar",
            "Provincia de Contumazá",
            "Provincia de Coronel Portillo",
            "Provincia de Corongo",
            "Provincia de Cotabambas",
            "Provincia de Cusco",
            "Provincia de Cutervo",
            "Provincia de Daniel Carrión",
            "Provincia de Dos de Mayo",
            "Provincia de Espinar",
            "Provincia de Ferreñafe",
            "Provincia de General Sánchez Cerro",
            "Provincia de Grau",
            "Provincia de Hualgayoc",
            "Provincia de Huallaga",
            "Provincia de Huamalíes",
            "Provincia de Huamanga",
            "Provincia de Huanca Sancos",
            "Provincia de Huancabamba",
            "Provincia de Huancané",
            "Provincia de Huancavelica",
            "Provincia de Huancayo",
            "Provincia de Huanta",
            "Provincia de Huánuco",
            "Provincia de Huaral",
            "Provincia de Huaraz",
            "Provincia de Huari",
            "Provincia de Huarmey",
            "Provincia de Huarochirí",
            "Provincia de Huaylas",
            "Provincia de Ica",
            "Provincia de Ilo",
            "Provincia de Islay",
            "Provincia de Jaén",
            "Provincia de Jauja",
            "Provincia de Junín",
            "Provincia de La Convención",
            "Provincia de La Mar",
            "Provincia de La Unión",
            "Provincia de Lamas",
            "Provincia de Lambayeque",
            "Provincia de Lampa",
            "Provincia de Leoncio Prado",
            "Provincia de Lucanas",
            "Provincia de Luya",
            "Provincia de Manú",
            "Provincia de Marañón",
            "Provincia de Mariscal Cáceres",
            "Provincia de Mariscal Luzuriaga",
            "Provincia de Mariscal Nieto",
            "Provincia de Melgar",
            "Provincia de Moyobamba",
            "Provincia de Nazca",
            "Provincia de Otuzco",
            "Provincia de Oxapampa",
            "Provincia de Pacasmayo",
            "Provincia de Pachitea",
            "Provincia de Paita",
            "Provincia de Pallasca",
            "Provincia de Palpa",
            "Provincia de Parinacochas",
            "Provincia de Paruro",
            "Provincia de Pasco",
            "Provincia de Pataz",
            "Provincia de Paucartambo",
            "Provincia de Pisco",
            "Provincia de Piura",
            "Provincia de Pomabamba",
            "Provincia de Puno",
            "Provincia de Quispicanchis",
            "Provincia de Recuay",
            "Provincia de Rioja",
            "Provincia de Rodríguez de Mendoza",
            "Provincia de San Ignacio",
            "Provincia de San Martín",
            "Provincia de San Miguel",
            "Provincia de Sandia",
            "Provincia de Santa",
            "Provincia de Santa Cruz",
            "Provincia de Santiago de Chuco",
            "Provincia de Satipo",
            "Provincia de Sihuas",
            "Provincia de Sucre",
            "Provincia de Sullana",
            "Provincia de Tacna",
            "Provincia de Tahuamanú",
            "Provincia de Talara",
            "Provincia de Tambopata",
            "Provincia de Tarata",
            "Provincia de Tarma",
            "Provincia de Tayacaja",
            "Provincia de Trujillo",
            "Provincia de Tumbes",
            "Provincia de Urubamba",
            "Provincia de Víctor Fajardo",
            "Provincia de Vilcas Huamán",
            "Provincia de Yauli",
            "Provincia de Yauyos",
            "Provincia de Yungay",
            "Provincia de Zarumilla",
            "Pucallpa",
            "Pucara",
            "Pueblo Nuevo",
            "Pueblo Nuevo",
            "Puerto Inca",
            "Puerto Maldonado",
            "Puerto Santa",
            "Puerto Supe",
            "Pujocucho",
            "Punchana",
            "Puno",
            "Punta de Bombón",
            "Puquio",
            "Purus",
            "Qquea",
            "Querecotillo",
            "Queromarca",
            "Quilahuani",
            "Quilmaná",
            "Quiruvilca",
            "Reque",
            "Requena",
            "Ricardo Palma",
            "Río Grande",
            "Rioja",
            "Salaverry",
            "Salinera Colán",
            "Salitral",
            "Salvación",
            "San Agustin",
            "San Antonio De Putina",
            "San Antonio del Estrecho",
            "San Bartolo",
            "San Clemente",
            "San Ignacio",
            "San Isidro",
            "San Jerónimo",
            "San Jerónimo",
            "San José",
            "San Juan Bautista",
            "San Lorenzo",
            "San Luis",
            "San Miguel",
            "San Miguel de Cauri",
            "San Pablo",
            "San Pablo",
            "San Pedro de Cajas",
            "San Pedro de Lloc",
            "San Ramón",
            "San Roman",
            "San Vicente de Cañete",
            "Saña",
            "Sanchez Carrion",
            "Santa Ana",
            "Santa Lucía",
            "Santa María",
            "Santa Rosa",
            "Santiago",
            "Santiago de Cao",
            "Santiago de Chuco",
            "Santiago de Surco",
            "Santo Tomas",
            "Saposoa",
            "Satipo",
            "Sayán",
            "Sechura",
            "Sicaya",
            "Sicuani",
            "Sisa",
            "Soritor",
            "Subtanjalla",
            "Sullana",
            "Supe",
            "Tabalosos",
            "Tacna",
            "Talara",
            "Talavera",
            "Tambo",
            "Tambo Grande",
            "Tambopata",
            "Tamshiyacu",
            "Taquile",
            "Tarapoto",
            "Tarata",
            "Tarma",
            "Tingo María",
            "Tinta",
            "Tinyahuarco",
            "Tocache",
            "Torata",
            "Totoral",
            "Trujillo",
            "Túcume",
            "Tumbes",
            "Tungasuca",
            "Uchiza",
            "Urb. Santo Domingo",
            "Urcos",
            "Urubamba",
            "Utcubamba",
            "Végueta",
            "Vice",
            "Villa Rica",
            "Villa Tupac Amaru",
            "Viru",
            "Vizcachane",
            "Yanacancha",
            "Yanaoca",
            "Yarowilca",
            "Yauya",
            "Yungay",
            "Yunguyo",
            "Yura",
            "Yurimaguas",
            "Zarumilla",
            "Zorritos"
        ]
    },
    {
        "label": "Philippines",
        "code": "PH",
        "phone": "63",
        "cities": [
            "Aanislag",
            "Abaca",
            "Abaca",
            "Abangay",
            "Abiera",
            "Abilay",
            "Abis",
            "Aborlan",
            "Abra de Ilog",
            "Abucay",
            "Abucay",
            "Abucayan",
            "Abulug",
            "Abut",
            "Abut",
            "Abuyog",
            "Abuyog",
            "Abuyog",
            "Abuyon",
            "Acao",
            "Accusilian",
            "Accusilian",
            "Acli",
            "Acli",
            "Adams",
            "Adela",
            "Adlaon",
            "Adlay",
            "Adtugan",
            "Afusing Centro",
            "Afusing Centro",
            "Ag-ambulong",
            "Aga",
            "Aganan",
            "Agay",
            "Agay",
            "Agbannawag",
            "Agbannawag",
            "Agbannawag",
            "Agcogon",
            "Agdangan",
            "Aggugaddah",
            "Aglalana",
            "Aglayan",
            "Aglipay",
            "Agno",
            "Agno",
            "Agoncillo",
            "Agoo",
            "Agoo",
            "Agos",
            "Agpangi",
            "Agsungot",
            "Aguada",
            "Aguilar",
            "Aguilar",
            "Aguining",
            "Aguisan",
            "Agupit",
            "Agusan",
            "Agutaya",
            "Ajuy",
            "Akle",
            "Akle",
            "Alabat",
            "Alabel",
            "Alabug",
            "Alabug",
            "Alac",
            "Alac",
            "Alacaygan",
            "Alad",
            "Alae",
            "Alamada",
            "Alamada",
            "Alaminos",
            "Alaminos",
            "Alaminos",
            "Alangalang",
            "Alangalang",
            "Alangilan",
            "Alangilanan",
            "Alanib",
            "Alannay",
            "Alannay",
            "Alayao",
            "Albuera",
            "Albuera",
            "Alburquerque",
            "Alcala",
            "Alcala",
            "Alcala",
            "Alcala",
            "Alcantara",
            "Alcantara",
            "Alcoy",
            "Alegria",
            "Alegria",
            "Alegria",
            "Alegria",
            "Alegria",
            "Alejal",
            "Alemanguan",
            "Alfonso",
            "Alfonso Castañeda",
            "Algeciras",
            "Aliaga",
            "Aliaga",
            "Aliang",
            "Alibago",
            "Alibug",
            "Alibunan",
            "Alicante",
            "Alicia",
            "Alicia",
            "Alicia",
            "Alicia",
            "Alijis",
            "Alilem",
            "Alilem",
            "Alim",
            "Alimodian",
            "Alimono",
            "Alitagtag",
            "Allacapan",
            "Allacapan",
            "Allangigan Primero",
            "Allangigan Primero",
            "Allen",
            "Allen",
            "Almagro",
            "Almagro",
            "Almaguer North",
            "Almaguer North",
            "Almendras",
            "Almeria",
            "Almeria",
            "Aloguinsan",
            "Aloleng",
            "Aloleng",
            "Alpaco",
            "Altavas",
            "Alua",
            "Alua",
            "Alubijid",
            "Alubijid",
            "Alubijid",
            "Alugan",
            "Alugan",
            "Alupay",
            "Amacalan",
            "Amacalan",
            "Amadeo",
            "Amaga",
            "Amaga",
            "Amagbagan",
            "Amagbagan",
            "Amas",
            "Amas",
            "Ambaguio",
            "Ambuclao",
            "Ambuclao",
            "Ambulong",
            "Ambulong",
            "Amdos",
            "Amio",
            "Amlimay",
            "Amlimay",
            "Ampatuan",
            "Ampatuan",
            "Ampusungan",
            "Ampusungan",
            "Amucao",
            "Amucao",
            "Amulung",
            "Amulung",
            "Amuñgan",
            "Amuñgan",
            "Anahawan",
            "Anahawan",
            "Anakan",
            "Anambongan",
            "Anambongan",
            "Anao",
            "Anao",
            "Anda",
            "Anda",
            "Anda",
            "Andalan",
            "Andalan",
            "Andili",
            "Andili",
            "Andili",
            "Andop",
            "Andop",
            "Andop",
            "Andres Bonifacio",
            "Angad",
            "Angad",
            "Angadanan",
            "Angadanan",
            "Angat",
            "Angat",
            "Angatel",
            "Angatel",
            "Angeles",
            "Angeles City",
            "Angono",
            "Ani-e",
            "Anibongan",
            "Anibongan",
            "Anibongan",
            "Anilao",
            "Anini-y",
            "Anito",
            "Anito",
            "Anonang",
            "Anopog",
            "Anoring",
            "Antagan Segunda",
            "Antagan Segunda",
            "Antequera",
            "Anticala",
            "Anticala",
            "Antipolo",
            "Antipolo",
            "Antipolo",
            "Antipolo",
            "Antipolo",
            "Antipolo",
            "Anulid",
            "Anulid",
            "Anuling",
            "Anuling",
            "Anuling",
            "Anuling",
            "Apad",
            "Apalit",
            "Apalit",
            "Aparri",
            "Aparri",
            "Apas",
            "Apayao",
            "Apitong",
            "Aplaya",
            "Aplaya",
            "Apoya",
            "Apud",
            "Apurawan",
            "Aquino",
            "Araal",
            "Araceli",
            "Aramawayan",
            "Aramayuan",
            "Aranas Sur",
            "Aranda",
            "Aras-asan",
            "Aras-asan",
            "Arayat",
            "Arayat",
            "Arcangel",
            "Arenas",
            "Arenas",
            "Argao",
            "Aringay",
            "Aringay",
            "Aritao",
            "Aritao",
            "Armenia",
            "Arminia",
            "Arminia",
            "Aroroy",
            "Arteche",
            "Arteche",
            "Asia",
            "Asingan",
            "Asingan",
            "Astorga",
            "Astorga",
            "Astorga",
            "Asturga",
            "Asturias",
            "Asuncion",
            "Asuncion",
            "Atabayan",
            "Atimonan",
            "Atipuluhan",
            "Atok",
            "Atok",
            "Atop-atop",
            "Atulayan",
            "Atulayan",
            "Aumbay",
            "Aurelliana",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Avila",
            "Awallan",
            "Awallan",
            "Awang",
            "Awang",
            "Aya",
            "Ayugan",
            "Ayungon",
            "Ayusan Uno",
            "Azagra",
            "Baao",
            "Baay",
            "Baay",
            "Babag",
            "Babag",
            "Babag",
            "Babatngon",
            "Babatngon",
            "Babo-Pangulo",
            "Babug",
            "Bacabac",
            "Bacabac",
            "Bacacay",
            "Bacag",
            "Bacag",
            "Bacalan",
            "Bacarra",
            "Bacarra",
            "Bacayawan",
            "Bacayawan",
            "Bachauan",
            "Baclayon",
            "Bacnar",
            "Bacnar",
            "Bacnor East",
            "Bacnor East",
            "Bacnotan",
            "Bacnotan",
            "Baco",
            "Bacolod",
            "Bacolod",
            "Bacolod",
            "Bacolod",
            "Bacolod City",
            "Bacolod Grande",
            "Bacolod-Kalawi",
            "Bacolor",
            "Bacolor",
            "Bacon",
            "Bacong",
            "Bacoor",
            "Bacsay",
            "Bacsay",
            "Bactad Proper",
            "Bactad Proper",
            "Bacuag",
            "Bacuag",
            "Baculin",
            "Baculin",
            "Baculin",
            "Baculongan",
            "Baculongan",
            "Bacundao Weste",
            "Bacundao Weste",
            "Bacungan",
            "Bacuyangan",
            "Badak",
            "Badak",
            "Badian",
            "Badian",
            "Badiangan",
            "Badlan",
            "Badoc",
            "Badoc",
            "Bagabag",
            "Bagac",
            "Bagac",
            "Bagacay",
            "Bagacay",
            "Bagahanlad",
            "Bagakay",
            "Bagalangit",
            "Bagamanoc",
            "Bagan",
            "Bagan",
            "Baganga",
            "Baganga",
            "Baganga",
            "Bagay",
            "Baggabag B",
            "Baggabag B",
            "Baggao",
            "Bago City",
            "Bagombong",
            "Bagong Barrio",
            "Bagong Barrio",
            "Bagong Pagasa",
            "Bagong Sikat",
            "Bagong Tanza",
            "Bagong Tanza",
            "Bagong-Sikat",
            "Bagong-Sikat",
            "Bagontapay",
            "Bagontapay",
            "Bagroy",
            "Bagtic",
            "Bagu",
            "Bagu",
            "Baguer",
            "Baguer",
            "Baguinge",
            "Baguinge",
            "Baguio",
            "Baguio",
            "Bagulin",
            "Bagulin",
            "Bagumbayan",
            "Bagumbayan",
            "Bagumbayan",
            "Bagumbayan",
            "Bagumbayan",
            "Bagupaye",
            "Bah-Bah",
            "Bah-Bah",
            "Bahay",
            "Bahay Pare",
            "Bahay Pare",
            "Baheli",
            "Baikingon",
            "Bail",
            "Bail",
            "Bailan",
            "Bairan",
            "Bais",
            "Baka",
            "Baka",
            "Bakulong",
            "Bakulong",
            "Bakun",
            "Bakun",
            "Bakung",
            "Bakung",
            "Bal-os",
            "Balabac",
            "Balabag",
            "Balabagan",
            "Balabagan",
            "Balading",
            "Balagon",
            "Balagtas",
            "Balagtas",
            "Balagtas",
            "Balagtas",
            "Balagtasin",
            "Balagui",
            "Balagui",
            "Balagunan",
            "Balagunan",
            "Balagunan",
            "Balamban",
            "Balanacan",
            "Balanga",
            "Balanga",
            "Balangbalang",
            "Balangbalang",
            "Balangiga",
            "Balangiga",
            "Balangkayan",
            "Balangkayan",
            "Balangonan",
            "Balangonan",
            "Balangonan",
            "Balaoan",
            "Balaoan",
            "Balaoang",
            "Balaoang",
            "Balaogan",
            "Balas",
            "Balas",
            "Balas",
            "Balas",
            "Balasan",
            "Balasing",
            "Balasing",
            "Balatan",
            "Balatero",
            "Balayan",
            "Balayang",
            "Balayang",
            "Balayong",
            "Balbalan",
            "Balbalan",
            "Balele",
            "Baleno",
            "Baler",
            "Baler",
            "Balete",
            "Balete",
            "Balete",
            "Balibagan Oeste",
            "Balibago",
            "Balibago",
            "Baligang",
            "Balila",
            "Balili",
            "Balilihan",
            "Balimbing",
            "Balinad",
            "Balindong",
            "Balindong",
            "Balingasag",
            "Balingasay",
            "Balingasay",
            "Balingcanaway",
            "Balingcanaway",
            "Balingoan",
            "Balingueo",
            "Balingueo",
            "Balinsacayao",
            "Balinsacayao",
            "Balite",
            "Balite",
            "Balite",
            "Balite Segundo",
            "Balitoc",
            "Baliton",
            "Baliton",
            "Baliuag",
            "Baliuag",
            "Baliuag Nuevo",
            "Baliwagan",
            "Baliwagan",
            "Ballesteros",
            "Ballesteros",
            "Baloc",
            "Baloc",
            "Balocawehay",
            "Balocawehay",
            "Balogo",
            "Balogo",
            "Balogo",
            "Balogo",
            "Balogo",
            "Balogo",
            "Balogo",
            "Balogo",
            "Baloy",
            "Baloy",
            "Balsic",
            "Balsic",
            "Balucawi",
            "Balucuc",
            "Balucuc",
            "Balud",
            "Balud",
            "Balud",
            "Balud",
            "Balugo",
            "Balungao",
            "Balungao",
            "Balut",
            "Balut",
            "Balutakay",
            "Balutakay",
            "Balutakay",
            "Balutu",
            "Balutu",
            "Baluyot",
            "Baluyot",
            "Bamban",
            "Bamban",
            "Bambang",
            "Bambang",
            "Banaba",
            "Banag",
            "Banalo",
            "Banaue",
            "Banaue",
            "Banawa",
            "Banawa",
            "Banawang",
            "Banawang",
            "Banaybanay",
            "Banaybanay",
            "Banaybanay",
            "Banayoyo",
            "Banayoyo",
            "Bancal",
            "Bancasi",
            "Bancasi",
            "Bañga",
            "Banga",
            "Banga",
            "Bangad",
            "Bangad",
            "Bangad",
            "Bangad",
            "Bangahan",
            "Bangan-Oda",
            "Bangan-Oda",
            "Banganan",
            "Banganan",
            "Bangao",
            "Bangao",
            "Bangar",
            "Bangar",
            "Bangkal",
            "Bangkal",
            "Bangkirohan",
            "Bangonay",
            "Bangonay",
            "Bangued",
            "Bangued",
            "Bangui",
            "Bangui",
            "Banguingui",
            "Banhigan",
            "Bani",
            "Bani",
            "Bani",
            "Bani",
            "Banilad",
            "Banilad",
            "Banisilan",
            "Banisilan",
            "Bankaw",
            "Bankaw",
            "Banna",
            "Banocboc",
            "Banog Sur",
            "Banog Sur",
            "Banos",
            "Banquero",
            "Banquero",
            "Bansalan",
            "Bansalan",
            "Bansalan",
            "Bansud",
            "Bantacan",
            "Bantacan",
            "Bantacan",
            "Bantay",
            "Bantay",
            "Bantay",
            "Bantay",
            "Bantayan",
            "Bantayan",
            "Bantayan",
            "Bantilan",
            "Bantiqui",
            "Bantiqui",
            "Bantog",
            "Bantog",
            "Bantogon",
            "Bantogon",
            "Bantuanon",
            "Banugao",
            "Bao",
            "Baon",
            "Baon",
            "Baon",
            "Baquero Norte",
            "Baquero Norte",
            "Baracatan",
            "Baracatan",
            "Baracatan",
            "Barahan",
            "Barangobong",
            "Barangobong",
            "Baras",
            "Baras",
            "Baras",
            "Baras",
            "Barayong",
            "Barbaza",
            "Barcelona",
            "Barcelona",
            "Barcelona",
            "Barcelona",
            "Barili",
            "Barira",
            "Barira",
            "Bariw",
            "Barlig",
            "Barlig",
            "Baro",
            "Baro",
            "Barobo",
            "Barobo",
            "Barong",
            "Barong",
            "Barong Barong",
            "Barongis",
            "Barongis",
            "Barotac Nuevo",
            "Barotac Viejo",
            "Baroy",
            "Barra",
            "Barra",
            "Barucboc Norte",
            "Barucboc Norte",
            "Barugo",
            "Barugo",
            "Barurao",
            "Barurao",
            "Basa",
            "Basa",
            "Basag",
            "Basak",
            "Basak",
            "Basak",
            "Basak",
            "Basay",
            "Bascaron",
            "Basco",
            "Basco",
            "Basdiot",
            "Basey",
            "Basey",
            "Basiad",
            "Basiao",
            "Basiawan",
            "Basiawan",
            "Basiawan",
            "Basicao Coastal",
            "Basilisa",
            "Basilisa",
            "Basing",
            "Basing",
            "Basista",
            "Basista",
            "Basud",
            "Basud",
            "Basud",
            "Batac",
            "Batac City",
            "Batad",
            "Batal",
            "Batal",
            "Batan",
            "Batana",
            "Batangas",
            "Bataquil",
            "Bataquil",
            "Batarasa",
            "Batas",
            "Batasan",
            "Batasan",
            "Batasan",
            "Batasan Bata",
            "Batasan Bata",
            "Bateria",
            "Batiano",
            "Batiano",
            "Batiano",
            "Batitang",
            "Batitang",
            "Bato",
            "Bato",
            "Bato",
            "Bato",
            "Bato",
            "Bato",
            "Bato",
            "Bato",
            "Bato",
            "Bato Bato",
            "Bato Bato",
            "Batobalane",
            "Batobato",
            "Batobato",
            "Batobato",
            "Battung",
            "Battung",
            "Batu",
            "Batuan",
            "Batuan",
            "Batutitik",
            "Batutitik",
            "Bau",
            "Bau",
            "Bauan",
            "Bauan",
            "Bauan",
            "Bauang",
            "Bauang",
            "Baud",
            "Baugo",
            "Bauko",
            "Bauko",
            "Baunu-Timbangan",
            "Baunu-Timbangan",
            "Bautista",
            "Bautista",
            "Bautista",
            "Bawison",
            "Bawison",
            "Bay",
            "Bay-ang",
            "Bayabas",
            "Bayabas",
            "Bayabas",
            "Bayabas",
            "Bayambang",
            "Bayambang",
            "Bayanan",
            "Bayanan",
            "Bayang",
            "Bayang",
            "Bayanga",
            "Bayanga",
            "Bayaoas",
            "Bayaoas",
            "Bayas",
            "Bayasong",
            "Bayasong",
            "Bayawan",
            "Baybay",
            "Baybay",
            "Baybay",
            "Baybayin",
            "Baylo",
            "Baylo",
            "Baylo",
            "Bayog",
            "Bayombong",
            "Bayombong",
            "Bayugan",
            "Bayugan",
            "Bayuin",
            "Beberon",
            "Becerril",
            "Beddeng",
            "Beddeng",
            "Begang",
            "Begang",
            "Belance",
            "Belance",
            "Belison",
            "Benito Soliven",
            "Benito Soliven",
            "Besao",
            "Besao",
            "Betwagan",
            "Betwagan",
            "Biabas",
            "Bialong",
            "Bialong",
            "Biao",
            "Biao",
            "Biao",
            "Biao",
            "Biasong",
            "Biay",
            "Biay",
            "Bibiclat",
            "Bibiclat",
            "Bical Norte",
            "Bical Norte",
            "Bicos",
            "Bicos",
            "Bien Unido",
            "Biga",
            "Biga",
            "Biga",
            "Biga",
            "Bigaa",
            "Bigaan",
            "Bigaan",
            "Bignay Uno",
            "Biking",
            "Bil-Loca",
            "Bil-Loca",
            "Bilad",
            "Bilad",
            "Bilao",
            "Bilar",
            "Bilaran",
            "Biliran",
            "Bilog-Bilog",
            "Bilwang",
            "Bilwang",
            "Binabaan",
            "Binabalian",
            "Binabalian",
            "Binahaan",
            "Binalan",
            "Binalan",
            "Binalbagan",
            "Binalonan",
            "Binalonan",
            "Biñan",
            "Binangonan",
            "Binantocan",
            "Binanwanaan",
            "Binay",
            "Bincoñgan",
            "Bincoñgan",
            "Bincoñgan",
            "Binday",
            "Binday",
            "Bingawan",
            "Binguang",
            "Binguang",
            "Binidayan",
            "Binidayan",
            "Binitayan",
            "Binitinan",
            "Binlod",
            "Binmaley",
            "Binmaley",
            "Binodegahan",
            "Binon-an",
            "Binonga",
            "Bintacay",
            "Bintawan",
            "Bintawan",
            "Binuang",
            "Binuang",
            "Binuangan",
            "Binuatan",
            "Binubusan",
            "Binucayan",
            "Binucayan",
            "Binulasan",
            "Biri",
            "Biri",
            "Bislig",
            "Bislig",
            "Bislig",
            "Bislig",
            "Bitadtun",
            "Bitag Grande",
            "Bitag Grande",
            "Bitangan",
            "Bitanjuan",
            "Bitanjuan",
            "Bitaogan",
            "Bitaogan",
            "Bitaogan",
            "Bitin",
            "Biton",
            "Bitoon",
            "Bituñgol",
            "Biwang",
            "Biwang",
            "Blingkong",
            "Blingkong",
            "Blinsung",
            "Blinsung",
            "Boac",
            "Bobon",
            "Bobon",
            "Bobon",
            "Bobon",
            "Bobon",
            "Bobon Second",
            "Bobon Second",
            "Bobonan",
            "Bobonan",
            "Bocana",
            "Bocaue",
            "Bocaue",
            "Bocos",
            "Bocos",
            "Bodega",
            "Bodega",
            "Bogo",
            "Bogtong",
            "Bogtong",
            "Bohol",
            "Bokod",
            "Bokod",
            "Bolanon",
            "Bolaoit",
            "Bolaoit",
            "Bolboc",
            "Bolila",
            "Bolila",
            "Bolila",
            "Bolilao",
            "Bolinao",
            "Boliney",
            "Boliney",
            "Bolingit",
            "Bolingit",
            "Bolisong",
            "Bolitoc",
            "Bolitoc",
            "Boljoon",
            "Bolo",
            "Bolo",
            "Bolo",
            "Bolo",
            "Bolo",
            "Bolo Bolo",
            "Bolong",
            "Bolong",
            "Bombon",
            "Bonawon",
            "Bonbon",
            "Bonbon",
            "Bone South",
            "Bone South",
            "Bonga",
            "Bongabon",
            "Bongabon",
            "Boñgalon",
            "Boñgalon",
            "Bongao",
            "Bongao",
            "Bongued",
            "Bongued",
            "Bonifacio",
            "Bontoc",
            "Bontoc",
            "Bontoc",
            "Bontoc",
            "Bood",
            "Boot",
            "Borbon",
            "Borbon",
            "Borbon",
            "Borongan",
            "Borongan",
            "Boroon",
            "Bosdak",
            "Boston",
            "Boston",
            "Boston",
            "Botao",
            "Botao",
            "Botigues",
            "Botolan",
            "Botolan",
            "Boton",
            "Brgy. Bachaw Norte Kalibo",
            "Brgy. Bulwang Numancia",
            "Brgy. Mabilo New Washington",
            "Brgy. Nalook kalibo",
            "Brgy. New Buswang Kalibo",
            "Brgy. Tinigao Kalibo",
            "Buadiposo-Buntong",
            "Buadiposo-Buntong",
            "Buadtasan",
            "Buadtasan",
            "Buagsong",
            "Bual",
            "Bual",
            "Bualan",
            "Bualan",
            "Buan",
            "Buan",
            "Buang",
            "Buanoy",
            "Buansa",
            "Buansa",
            "Buawan",
            "Buayan",
            "Buayan",
            "Bubong",
            "Bucay",
            "Bucay",
            "Bucay Pait",
            "Buclad",
            "Buclad",
            "Buclad",
            "Bucloc",
            "Budta",
            "Budta",
            "Bued",
            "Bued",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenavista",
            "Buenlag",
            "Buenlag",
            "Buenlag",
            "Buenlag",
            "Buensuseso",
            "Buensuseso",
            "Buga",
            "Buga",
            "Bugaan",
            "Bugallon",
            "Bugallon",
            "Bugang",
            "Bugas",
            "Bugasan",
            "Bugasan",
            "Bugasong",
            "Bugcaon",
            "Bugho",
            "Bugho",
            "Bugko",
            "Bugko",
            "Bugo",
            "Bugsoc",
            "Buguey",
            "Buguey",
            "Buguias",
            "Buguias",
            "Buhangin",
            "Buhangin",
            "Buhangin",
            "Buhatan",
            "Buhi",
            "Bukal",
            "Bukal Sur",
            "Bukay Pait",
            "Bukid",
            "Bukid",
            "Bukid",
            "Bula",
            "Bula",
            "Bulacan",
            "Bulacan",
            "Bulacan",
            "Bulacnin",
            "Bulad",
            "Bulakan",
            "Bulalacao",
            "Bulalacao",
            "Bulalacao",
            "Bulan",
            "Bulaon",
            "Bulaon",
            "Bularit",
            "Bularit",
            "Bulasa",
            "Bulata",
            "Bulatukan",
            "Bulatukan",
            "Bulawin",
            "Bulawin",
            "Buldon",
            "Buldon",
            "Bulihan",
            "Bulihan",
            "Bulihan",
            "Buliok",
            "Buliok",
            "Buliran",
            "Buliran",
            "Buliran Segundo",
            "Buliran Segundo",
            "Bulit",
            "Bulit",
            "Buliwao",
            "Buliwao",
            "Bulo",
            "Bulod",
            "Bulog",
            "Bulog",
            "Bulu",
            "Bulu",
            "Bulualto",
            "Bulualto",
            "Buluan",
            "Buluan",
            "Buluang",
            "Buluang",
            "Buluangan",
            "Bulusan",
            "Bumbaran",
            "Bunawan",
            "Bunawan",
            "Bundoc",
            "Bundoc",
            "Bunga",
            "Bunga",
            "Bungabon",
            "Bungabon",
            "Bungabon",
            "Bungahan",
            "Bungoy",
            "Bungsuan",
            "Bunguiao",
            "Bunog",
            "Bunol",
            "Bunol",
            "Burabod",
            "Buracan",
            "Burauen",
            "Burauen",
            "Buray",
            "Burdeos",
            "Burgos",
            "Burgos",
            "Burgos",
            "Burgos",
            "Burgos",
            "Burgos",
            "Burgos",
            "Burgos",
            "Burias",
            "Burias",
            "Burias",
            "Burirao",
            "Buruanga",
            "Busay",
            "Busdi",
            "Busilak",
            "Busilak",
            "Busing",
            "Busok",
            "Busok",
            "Bustos",
            "Bustos",
            "Butag",
            "Butazon",
            "Butazon",
            "Butig",
            "Butig",
            "Butigui",
            "Butigui",
            "Butuan",
            "Butuan",
            "Butubut Norte",
            "Butubut Norte",
            "Butulan",
            "Butulan",
            "Butulan",
            "Buug",
            "Buyabod",
            "Buyo",
            "Buyuan",
            "Caabiangan",
            "Caabiangan",
            "Caba",
            "Caba",
            "Cabacao",
            "Cabacungan",
            "Cabacuñgan",
            "Cabacuñgan",
            "Cabadbaran",
            "Cabadbaran",
            "Cabadiangan",
            "Cabagan",
            "Cabagan",
            "Cabalaoangan",
            "Cabalaoangan",
            "Cabalawan",
            "Cabalitian",
            "Cabalitian",
            "Cabaluay",
            "Cabanatuan",
            "Cabanatuan City",
            "Cabanbanan",
            "Cabanbanan",
            "Cabangahan",
            "Cabangahan",
            "Cabangan",
            "Cabangan",
            "Cabanglasan",
            "Cabannungan Second",
            "Cabannungan Second",
            "Cabano",
            "Cabaritan East",
            "Cabaritan East",
            "Cabarroguis",
            "Cabarroguis",
            "Cabatang",
            "Cabatuan",
            "Cabatuan",
            "Cabatuan",
            "Cabatuan",
            "Cabay",
            "Cabay",
            "Cabay",
            "Cabayangan",
            "Cabayangan",
            "Cabayangan",
            "Cabayaoasan",
            "Cabayaoasan",
            "Cabcab",
            "Cabcaben",
            "Cabcaben",
            "Cabiao",
            "Cabiao",
            "Cabiguan",
            "Cabilao",
            "Cabilauan",
            "Cabinuangan",
            "Cabinuangan",
            "Cabinuangan",
            "Cabiraoan",
            "Cabiraoan",
            "Cabitan",
            "Cabittaogan",
            "Cabittaogan",
            "Cabodiongan",
            "Cabodiongan",
            "Cabog",
            "Cabog",
            "Cabognon",
            "Cabra",
            "Cabucgayan",
            "Cabucgayan",
            "Cabugao",
            "Cabugao",
            "Cabugao",
            "Cabugao",
            "Cabul-an",
            "Cabulay",
            "Cabungan",
            "Cabungan",
            "Caburan",
            "Caburan",
            "Caburan",
            "Cabusao",
            "Cabuyao",
            "Cadagmayan Norte",
            "Caditaan",
            "Cadiz",
            "Cadlan",
            "Cafe",
            "Cafe",
            "Cagamotan",
            "Cagamotan",
            "Cagayan",
            "Cagayan de Oro",
            "Cagayan de Tawi-Tawi",
            "Cagayancillo",
            "Cagbang",
            "Cagdianao",
            "Cagdianao",
            "Cagmanaba",
            "Cagsiay",
            "Cagwait",
            "Cagwait",
            "Caibiran",
            "Caibiran",
            "Caigangan",
            "Cainta",
            "Cajidiocan",
            "Cajimos",
            "Calaba",
            "Calaba",
            "Calaba",
            "Calaba",
            "Calabaca",
            "Calabanga",
            "Calabasa",
            "Calabugao",
            "Calaca",
            "Calachuchi",
            "Calamagui East",
            "Calamagui East",
            "Calamba",
            "Calamba",
            "Calamba",
            "Calamba",
            "Calamba",
            "Calampisauan",
            "Calamundingan",
            "Calanasan",
            "Calanasan",
            "Calancuasan Norte",
            "Calancuasan Norte",
            "Calangain",
            "Calangain",
            "Calanogas",
            "Calanogas",
            "Calantac",
            "Calantac",
            "Calantas",
            "Calantas",
            "Calantas",
            "Calaoagan",
            "Calaoagan",
            "Calapan",
            "Calape",
            "Calape",
            "Calape",
            "Calape",
            "Calasgasan",
            "Calasiao",
            "Calasiao",
            "Calatagan",
            "Calatagan",
            "Calatrava",
            "Calatrava",
            "Calatugas",
            "Calauag",
            "Calauag",
            "Calauan",
            "Calaya",
            "Calayaan",
            "Calayaan",
            "Calayan",
            "Calbayog",
            "Calbayog City",
            "Calbiga",
            "Calbiga",
            "Calepaan",
            "Calepaan",
            "Calero",
            "Calibungan",
            "Calibungan",
            "Calibutbut",
            "Calibutbut",
            "Calidñgan",
            "Calilayan",
            "Calinaoan Malasin",
            "Calinaoan Malasin",
            "Calingcuan",
            "Calingcuan",
            "Calinog",
            "Calintaan",
            "Calituban",
            "Calizo",
            "Callaguip",
            "Callaguip",
            "Caloc-an",
            "Caloc-an",
            "Calog Norte",
            "Calog Norte",
            "Calolbon",
            "Calomboyan",
            "Calomboyan",
            "Calongbuyan",
            "Calongbuyan",
            "Caloocan",
            "Calsib",
            "Calsib",
            "Calubcub Dos",
            "Calubian",
            "Calubian",
            "Calumboyan",
            "Calumpang",
            "Calumpang",
            "Calumpang",
            "Calumpang",
            "Calumpit",
            "Calumpit",
            "Caluya",
            "Cama Juan",
            "Cama Juan",
            "Camachile",
            "Camachile",
            "Camalaniugan",
            "Camalaniugan",
            "Camaley",
            "Camaley",
            "Camalig",
            "Camaligan",
            "Camalobalo",
            "Camambugan",
            "Camandag",
            "Camangcamang",
            "Cambanay",
            "Cambanugoy",
            "Cambanugoy",
            "Cambanugoy",
            "Cambuga",
            "Camias",
            "Camias",
            "Camiling",
            "Camiling",
            "Caminauit",
            "Camindangan",
            "Camingawan",
            "Camohaguin",
            "Camp Flora",
            "Campoyo",
            "Campusong",
            "Camudmud",
            "Camudmud",
            "Camudmud",
            "Can-asujan",
            "Can-avid",
            "Can-Avid",
            "Canaman",
            "Canan Norte",
            "Canan Norte",
            "Canaoalan",
            "Canaoalan",
            "Canauay",
            "Canayan",
            "Candaba",
            "Candaba",
            "Candabong",
            "Candating",
            "Candating",
            "Candelaria",
            "Candelaria",
            "Candelaria",
            "Candiis",
            "Candijay",
            "Candon",
            "Candon",
            "Candoni",
            "Canhandugan",
            "Canhandugan",
            "Canhaway",
            "Caningay",
            "Canjulao",
            "Canlaon",
            "Canmaya Diot",
            "Canomoy",
            "Canroma",
            "Cansilayan",
            "Cansolungon",
            "Cansuje",
            "Cantao-an",
            "Cantapoy",
            "Cantapoy",
            "Cantel",
            "Cantilan",
            "Cantilan",
            "Cantoria",
            "Cantoria",
            "Canturay",
            "Canubing No 2",
            "Caoayan",
            "Caoayan",
            "Capaga",
            "Capalayan",
            "Capalayan",
            "Capalonga",
            "Capandanan",
            "Capandanan",
            "Capas",
            "Capas",
            "Capissayan Sur",
            "Capissayan Sur",
            "Capitan Ramon",
            "Capoocan",
            "Capoocan",
            "Capucnasan",
            "Capul",
            "Capul",
            "Capulaan",
            "Capulaan",
            "Capuluan",
            "Capuy",
            "Carabalan",
            "Caracal",
            "Caraga",
            "Caraga",
            "Caraga",
            "Caramay",
            "Caramoan",
            "Caramoran",
            "Caramutan",
            "Caramutan",
            "Caranan",
            "Carasi",
            "Carasi",
            "Caraycaray",
            "Caraycaray",
            "Caraycayon",
            "Carcar",
            "Cardona",
            "Cardona",
            "Cardona",
            "Caridad",
            "Caridad",
            "Caridad",
            "Carig",
            "Carig",
            "Carigara",
            "Carigara",
            "Carles",
            "Carmelo",
            "Carmelo",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen",
            "Carmen Grande",
            "Carmona",
            "Caromatan",
            "Caronoan West",
            "Caronoan West",
            "Carot",
            "Carot",
            "Carpenter",
            "Carpenter Hill",
            "Carranglan",
            "Carranglan",
            "Carrascal",
            "Carrascal",
            "Carriedo",
            "Carriedo",
            "Carriedo",
            "Cartagena",
            "Caruray",
            "Carusucan",
            "Carusucan",
            "Casala-an",
            "Casambalangan",
            "Casambalangan",
            "Casay",
            "Casay",
            "Casian",
            "Casiguran",
            "Casiguran",
            "Casisang",
            "Cassanayan",
            "Castañas",
            "Castilla",
            "Castillejos",
            "Castillejos",
            "Castillo",
            "Casuguran",
            "Catabangan",
            "Catablan",
            "Cataingan",
            "Catanauan",
            "Catarman",
            "Catarman",
            "Catarman",
            "Catarman",
            "Catayauan",
            "Catayauan",
            "Catbalogan",
            "Catbalogan",
            "Cateel",
            "Cateel",
            "Cateel",
            "Caterman",
            "Caterman",
            "Caticlan",
            "Caticugan",
            "Catigbian",
            "Catmon",
            "Catmon",
            "Catmon",
            "Catmondaan",
            "Cato",
            "Cato",
            "Catubig",
            "Catubig",
            "Catuday",
            "Catuday",
            "Catungan",
            "Catungawan Sur",
            "Cauayan",
            "Cauayan",
            "Cauayan",
            "Causip",
            "Causwagan",
            "Causwagan",
            "Cavinti",
            "Cavite",
            "Cavite",
            "Cavite",
            "Cavite City",
            "Cawayan",
            "Cawayan",
            "Cawayan",
            "Cawayan",
            "Cawayan Bugtong",
            "Cawayan Bugtong",
            "Cayang",
            "Cayanga",
            "Cayanga",
            "Cayanguan",
            "Cayhagan",
            "Cayungnan",
            "Cayungnan",
            "Cebu City",
            "Cebuano",
            "Cebuano",
            "Cervantes",
            "Cervantes",
            "Cervantes",
            "Cervantes",
            "Cervantes",
            "Chambrey",
            "Cigaras",
            "City of Balanga",
            "City of Batac",
            "City of Cabadbaran",
            "City of Candon",
            "City of Gapan",
            "City of Isabela",
            "City of Kidapawan",
            "City of Koronadal",
            "City of Malolos",
            "City of Mati",
            "City of Mati",
            "City of Meycauayan",
            "City of Panabo",
            "City of Panabo",
            "City of San Fernando",
            "City of San Jose del Monte",
            "City of Tacurong",
            "City of Tagum",
            "City of Tagum",
            "City of Urdaneta",
            "City of Vigan",
            "Clarin",
            "Clarin",
            "Claver",
            "Claver",
            "Claveria",
            "Claveria",
            "Claveria",
            "Claveria",
            "Codcod",
            "Cogan",
            "Cogon",
            "Cogon",
            "Cogon",
            "Cogon",
            "Cogon",
            "Cogon Cruz",
            "Cogtong",
            "Colipapa",
            "Colongolo",
            "Colongulo",
            "Colonia",
            "Colonia",
            "Colonia",
            "Columbio",
            "Columbio",
            "Comagascas",
            "Comagascas",
            "Comillas",
            "Comillas",
            "Comillas Norte",
            "Comillas Norte",
            "Communal",
            "Communal",
            "Compostela",
            "Compostela",
            "Compostela Valley",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion",
            "Concepcion Ibaba",
            "Concordia",
            "Conduaga",
            "Conel",
            "Conner",
            "Conner",
            "Consolacion",
            "Consolacion",
            "Constancia",
            "Consuegra",
            "Consuegra",
            "Consuelo",
            "Consuelo",
            "Consuelo",
            "Conversion",
            "Conversion",
            "Corcuera",
            "Cordon",
            "Cordon",
            "Cordova",
            "Cordova",
            "Corella",
            "Corocotan",
            "Corocotan",
            "Corocotan",
            "Coron",
            "Coronon",
            "Coronon",
            "Coronon",
            "Corrooy",
            "Corrooy",
            "Cortes",
            "Cortes",
            "Cortes",
            "Cortez",
            "Cosina",
            "Cotabato",
            "Cotabato City",
            "Cotabato City",
            "Cotmon",
            "Cuambog",
            "Cuambog",
            "Cuambog",
            "Cuartero",
            "Cuenca",
            "Cuevas",
            "Cuevas",
            "Culaba",
            "Culaba",
            "Culacling",
            "Culaman",
            "Culaman",
            "Culaman",
            "Culasi",
            "Culasian",
            "Culasian",
            "Culianan",
            "Culianin",
            "Culianin",
            "Culit",
            "Culit",
            "Cullalabo del Sur",
            "Cullalabo del Sur",
            "Culubasa",
            "Culubasa",
            "Cumadcad",
            "Currimao",
            "Currimao",
            "Curry",
            "Curuan",
            "Cut-cut Primero",
            "Cut-cut Primero",
            "Cuyapo",
            "Cuyapo",
            "Cuyo",
            "Da-an Sur",
            "Daanbantayan",
            "Dacudao",
            "Dacudao",
            "Dacudao",
            "Dado",
            "Dado",
            "Dadus",
            "Dadus",
            "Daet",
            "Dagami",
            "Dagami",
            "Dagatan",
            "Dagohoy",
            "Daguioman",
            "Daguioman",
            "Daguit",
            "Daguma",
            "Dagumba-an",
            "Dagup",
            "Dagup",
            "Dagupan",
            "Dagupan",
            "Dagupan",
            "Dagupan",
            "Dahay",
            "Dahay",
            "Dakbayan sa Bislig",
            "Dakbayan sa Digos",
            "Dakbayan sa Digos",
            "Dalaguete",
            "Dalangin",
            "Dalaoig",
            "Dalaoig",
            "Daliao",
            "Daliao",
            "Dalican",
            "Dalican",
            "Daliciasao",
            "Dalipey",
            "Dalipey",
            "Dalipuga",
            "Dalirig",
            "Dalorong",
            "Dalumangcob",
            "Dalumangcob",
            "Dalupaon",
            "Dalupirip",
            "Dalupirip",
            "Dalwangan",
            "Damabalas",
            "Damabalas",
            "Damatulan",
            "Damatulan",
            "Damawato",
            "Damawato",
            "Damayan",
            "Damilag",
            "Damolog",
            "Damortis",
            "Damortis",
            "Dampol",
            "Dampol",
            "Damulog",
            "Danao",
            "Dancagan",
            "Dancalan",
            "Dangcalan",
            "Danglas",
            "Danglas",
            "Danlugan",
            "Dansuli",
            "Dansuli",
            "Dao",
            "Dao",
            "Dao",
            "Dapa",
            "Dapa",
            "Dapawan",
            "Dapdap",
            "Dapdap",
            "Dapdap",
            "Dapdapan",
            "Dapitan",
            "Dapitan",
            "Daraga",
            "Daragutan",
            "Daragutan",
            "Daraitan",
            "Daram",
            "Daram",
            "Darapidap",
            "Darapidap",
            "Dasmariñas",
            "Dasmariñas Village",
            "Dasol",
            "Dasol",
            "Dassun",
            "Dassun",
            "Datagon",
            "Datu Blah T. Sinsuat",
            "Datu Montawal",
            "Datu Odin Sinsuat",
            "Datu Paglas",
            "Datu Paglas",
            "Datu Piang",
            "Datu Piang",
            "Dauin",
            "Dauis",
            "Davan",
            "Davan",
            "Davan",
            "Davao",
            "Davao City",
            "Davao City",
            "Davila",
            "Davila",
            "Dawa-Dawa",
            "Dayap",
            "Dayapan",
            "Daykitin",
            "De la Paz",
            "De la Paz",
            "Del Carmen",
            "Del Carmen",
            "Del Carmen",
            "Del Carmen Surigao del Norte",
            "Del Gallego",
            "Del Monte",
            "Del Monte",
            "Del Pilar",
            "Del Pilar",
            "Del Pilar",
            "Del Pilar",
            "Del Pilar",
            "Del Pilar",
            "Del Pilar",
            "Del Rosario",
            "Delfin Albano",
            "Diadi",
            "Diadi",
            "Diamantina",
            "Diamantina",
            "Dian-ay",
            "Diaz",
            "Diaz",
            "Dibuluan",
            "Dibuluan",
            "Dicabisagan",
            "Dicabisagan",
            "Dicamay",
            "Dicamay",
            "Dicayong",
            "Diffun",
            "Diffun",
            "Digal",
            "Digal",
            "Digdig",
            "Digdig",
            "Digkilaan",
            "Digos",
            "Digos",
            "Digos",
            "Dilan",
            "Dilan",
            "Dilasag",
            "Diliman Primero",
            "Diliman Primero",
            "Dimaluna",
            "Dimasalang",
            "Dimataling",
            "Dimayon",
            "Dimiao",
            "Dinagat",
            "Dinagat",
            "Dinagat Islands",
            "Dinahican",
            "Dinaig",
            "Dinaig",
            "Dinalongan",
            "Dinalupihan",
            "Dinalupihan",
            "Dinapigui",
            "Dingalan",
            "Dingalan",
            "Dinganen",
            "Dinganen",
            "Dingle",
            "Dingras",
            "Dingras",
            "Dipaculao",
            "Diplahan",
            "Dipolo",
            "Dipolog",
            "Disod",
            "Ditsaan-Ramain",
            "Divilican",
            "Divisoria",
            "Divisoria",
            "Dobdoban",
            "Dodan",
            "Dodan",
            "Doljo",
            "Dolo",
            "Dolo",
            "Dolo",
            "Dologon",
            "Dolores",
            "Dolores",
            "Dolores",
            "Dolores",
            "Dolores",
            "Dolores",
            "Dolores",
            "Domalanoan",
            "Domalanoan",
            "Domampot",
            "Domampot",
            "Don Carlos",
            "Don Marcelino",
            "Don Marcelino",
            "Don Pedro",
            "Don Pedro",
            "Doña Remedios Trinidad",
            "Doña Remedios Trinidad",
            "Donsol",
            "Doong",
            "Doos",
            "Doos",
            "Dorongan Punta",
            "Dorongan Punta",
            "Dorsalanam",
            "Dos Hermanas",
            "Doyong",
            "Doyong",
            "Dualing",
            "Dualing",
            "Dueñas",
            "Duero",
            "Dugcal",
            "Dugongan",
            "Dukay",
            "Dukay",
            "Dulag",
            "Dulag",
            "Dulangan",
            "Dulangan",
            "Dulao",
            "Dulian",
            "Dulig",
            "Dulig",
            "Dumabato",
            "Dumabato",
            "Dumaguete",
            "Dumaguil",
            "Dumalag",
            "Dumalaguing",
            "Dumalinao",
            "Dumalneg",
            "Dumalneg",
            "Dumangas",
            "Dumanjog",
            "Dumarais",
            "Dumaran",
            "Dumarao",
            "Dumingag",
            "Dumlan",
            "Dumlan",
            "Dumlan",
            "Dumpay",
            "Dumpay",
            "Dungon",
            "Dunguan",
            "Dunguan",
            "Dupax del Norte",
            "Dupax del Sur",
            "Duran",
            "East Migpulao",
            "East Valencia",
            "Ebcor Town",
            "Ebcor Town",
            "Echague",
            "Echague (town)",
            "Eden",
            "Eden",
            "Egaña",
            "Eguia",
            "Eguia",
            "El Nido",
            "El Pardo",
            "El Salvador",
            "Enrile",
            "Enrile",
            "Enrique Villanueva",
            "Entablado",
            "Entablado",
            "Eraan",
            "Erenas",
            "Erenas",
            "Ermita",
            "Ermita",
            "Escalante",
            "Esmeralda",
            "Esmeralda",
            "España",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza",
            "Esperanza East",
            "Esperanza East",
            "Espiritu",
            "Estaca",
            "Estacion",
            "Estancia",
            "Estancia",
            "Estefania",
            "Estefania",
            "Estipona",
            "Estipona",
            "Estrella",
            "Estrella",
            "Estrella",
            "Eustaquio Lopez",
            "Evangelista",
            "Fabrica",
            "Famy",
            "Feliciano",
            "Ferrol",
            "Flora",
            "Flora",
            "Floridablanca",
            "Floridablanca",
            "Fuerte",
            "Fuerte",
            "Furao",
            "Furao",
            "Gabaldon",
            "Gabaldon",
            "Gabao",
            "Gabas",
            "Gabas",
            "Gabawan",
            "Gabi",
            "Gabi",
            "Gabi",
            "Gabi",
            "Gabi",
            "Gabuyan",
            "Gabuyan",
            "Gabuyan",
            "Gadu",
            "Gadu",
            "Gadung",
            "Gadung",
            "Gainza",
            "Galimuyod",
            "Galimuyod",
            "Gamay",
            "Gamay",
            "Gambalidio",
            "Gambang",
            "Gambang",
            "Gammad",
            "Gammad",
            "Gamu",
            "Gamu",
            "Gamut",
            "Gamut",
            "Ganapi",
            "Ganapi",
            "Ganassi",
            "Ganassi",
            "Gandara",
            "Gandara",
            "Gang",
            "Gang",
            "Gansing",
            "Gansing",
            "Ganyangan",
            "Gapan",
            "Gapan",
            "Gappal",
            "Gappal",
            "Garchitorena",
            "Garcia Hernandez",
            "Gasan",
            "Gatbo",
            "Gattaran",
            "Gattaran",
            "Gayaman",
            "Gayaman",
            "General Emilio Aguinaldo",
            "General Luna",
            "General Luna",
            "General Luna",
            "General Luna",
            "General Luna",
            "General MacArthur",
            "General MacArthur",
            "General Mamerto Natividad",
            "General Mamerto Natividad",
            "General Nakar",
            "General Santos",
            "General Santos",
            "General Tinio",
            "General Tinio",
            "General Trias",
            "Gerona",
            "Gerona",
            "Getulio",
            "Giawang",
            "Gibato",
            "Gibgos",
            "Gibong",
            "Gigaquit",
            "Gigaquit",
            "Gigmoto",
            "Gimampang",
            "Ginabuyan",
            "Ginabuyan",
            "Ginatilan",
            "Gines-Patay",
            "Giporlos",
            "Giporlos",
            "Gitagum",
            "Glad",
            "Glad",
            "Glamang",
            "Glamang",
            "Glan",
            "Glan",
            "Glan Peidu",
            "Glan Peidu",
            "Gloria",
            "Goa",
            "Gocoton",
            "Gocoton",
            "Goma",
            "Goma",
            "Goma",
            "Gonzaga",
            "Gonzaga",
            "Governor Generoso",
            "Governor Generoso",
            "Granada",
            "Gregorio del Pilar",
            "Gregorio del Pilar",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe",
            "Guagua",
            "Guagua",
            "Guba",
            "Gubaan",
            "Gubang",
            "Gubat",
            "Gueset",
            "Gueset",
            "Guibodangan",
            "Guiddam",
            "Guiddam",
            "Guiguinto",
            "Guiguinto",
            "Guihing Proper",
            "Guihing Proper",
            "Guihing Proper",
            "Guihulñgan",
            "Guijalo",
            "Guiling",
            "Guiling",
            "Guiljungan",
            "Guimba",
            "Guimba",
            "Guimbal",
            "Guinabsan",
            "Guinabsan",
            "Guinacotan",
            "Guinayangan Fourth District of Quezon",
            "Guindapunan",
            "Guindapunan",
            "Guindarohan",
            "Guindulman",
            "Guiniculalay",
            "Guinisiliban",
            "Guinlo",
            "Guinoaliuan",
            "Guinobatan",
            "Guinsadan",
            "Guinsadan",
            "Guinsang-an",
            "Guinsang-an",
            "Guinticgan",
            "Guintubhan",
            "Guiong",
            "Guiong",
            "Guipos",
            "Guirang",
            "Guirang",
            "Guiset East",
            "Guiset East",
            "Guisguis",
            "Guisguis",
            "Guisguis",
            "Guisijan",
            "Guiuan",
            "Guiuan",
            "Guiwanon",
            "Gulod",
            "Gumaca",
            "Gumalang",
            "Gumalang",
            "Gumalang",
            "Gumaus",
            "Gumian",
            "Gupitan",
            "Gupitan",
            "Gupitan",
            "Guruyan",
            "Gutad",
            "Gutalac",
            "Guyam Malaki",
            "Guyong",
            "Guyong",
            "Hacienda",
            "Hacienda",
            "Hacienda Refugio",
            "Hacienda Santa Rosa",
            "Hagdan",
            "Hagnaya",
            "Hagonoy",
            "Hagonoy",
            "Hagonoy",
            "Hagonoy",
            "Hagonoy",
            "Haguimit",
            "Halapitan",
            "Halayhay",
            "Halayhayin",
            "Haligue",
            "Halog West",
            "Halog West",
            "Hamoraon",
            "Hamtic",
            "Hapao",
            "Hapao",
            "Harrison",
            "Hermosa",
            "Hermosa",
            "Hernani",
            "Hernani",
            "Herrera",
            "Hibaiyo",
            "Hiju Maco",
            "Hilantagaan",
            "Hilongos",
            "Hilongos",
            "Hilotongan",
            "Himaao",
            "Himamaylan",
            "Himaya",
            "Himensulan",
            "Hinabangan",
            "Hinapalanan",
            "Hinatuan",
            "Hinatuan",
            "Hindang",
            "Hindang",
            "Hingatungan",
            "Hingatungan",
            "Hingyon",
            "Hinigaran",
            "Hinlayagan Ilaud",
            "Hinunangan",
            "Hinunangan",
            "Hinundayan",
            "Hinundayan",
            "Hipadpad",
            "Hipadpad",
            "Hipasngo",
            "Hipasngo",
            "Hipona",
            "Hobo",
            "Hondagua",
            "Hukay",
            "Hungduan",
            "Iba",
            "Iba",
            "Ibaan",
            "Ibabang Tayuman",
            "Ibajay",
            "Ibarra",
            "Ibarra",
            "Ibung",
            "Ibung",
            "Ichon",
            "Idio",
            "Idtig",
            "Idtig",
            "Igang",
            "Igbaras",
            "Igbon",
            "Igcocolo",
            "Igmaya-an",
            "Ignit",
            "Ignit",
            "Ignit",
            "Igpit",
            "Iguig",
            "Iguig",
            "Ilagan",
            "Ilagan",
            "Ilangay",
            "Ilangay",
            "Ilangay",
            "Ilaya",
            "Ilaya",
            "Ilaya",
            "Iligan City",
            "Ilihan",
            "Ilihan",
            "Ilioilio",
            "Ilioilio",
            "Ilog",
            "Iloilo",
            "Imbang",
            "Imbatug",
            "Imelda",
            "Imelda",
            "Impalutao",
            "Imus",
            "Inabaan Sur",
            "Inabaan Sur",
            "Inabanga",
            "Inangatan",
            "Inangatan",
            "Inapatan",
            "Inawayan",
            "Inawayan",
            "Inawayan",
            "Inayagan",
            "Inayauan",
            "Indanan",
            "Indang",
            "Indulang",
            "Ineangan",
            "Ineangan",
            "Infanta",
            "Infanta",
            "Infanta",
            "Inicbulan",
            "Initao",
            "Inobulan",
            "Inopacan",
            "Intampilan",
            "Intramuros",
            "Ipil",
            "Ipil",
            "Ipil",
            "Ipil",
            "Ipil",
            "Ipil",
            "Ipilan",
            "Iraga",
            "Irahuan",
            "Iraray",
            "Irasan",
            "Iraya",
            "Iriga City",
            "Irirum",
            "Irosin",
            "Isabang",
            "Isabel",
            "Isabel",
            "Isabela",
            "Isabela",
            "Isla",
            "Isla",
            "Island Garden City of Samal",
            "Island Garden City of Samal",
            "Isugod",
            "Isulan",
            "Isulan",
            "Itbayat",
            "Itbayat",
            "Itogon",
            "Itogon",
            "Ivana",
            "Ivana",
            "Ivisan",
            "Jabonga",
            "Jabonga",
            "Jaclupan",
            "Jaen",
            "Jaen",
            "Jaena",
            "Jagna",
            "Jaguimitan",
            "Jagupit",
            "Jagupit",
            "Jalajala",
            "Jalaud",
            "Jamabalod",
            "Jamindan",
            "Jampang",
            "Janagdong",
            "Jandayan Norte",
            "Janiuay",
            "Janopol",
            "Jantianon",
            "Japitan",
            "Japitan",
            "Jarigue",
            "Jaro",
            "Jaro",
            "Jasaan",
            "Javalera",
            "Javier",
            "Javier",
            "Javier",
            "Jayubó",
            "Jetafe",
            "Jiabong",
            "Jiabong",
            "Jibao-an",
            "Jimalalud",
            "Jimenez",
            "Jipapad",
            "Jolo",
            "Jolo",
            "Jones",
            "Jones",
            "Jordan",
            "Joroan",
            "Jose Abad Santos",
            "Jose Abad Santos",
            "Jose Pañganiban",
            "Josefina",
            "Jovellar",
            "Jovellar",
            "Jovellar",
            "Jovellar",
            "Juban",
            "Jubasan",
            "Jubasan",
            "Jugno",
            "Julita",
            "Julita",
            "Kabac",
            "Kabacan",
            "Kabacan",
            "Kabalantian",
            "Kabalen",
            "Kabalen",
            "Kabankalan",
            "Kabasalan",
            "Kabasalan",
            "Kabasalan",
            "Kabayan",
            "Kabilauan",
            "Kablalan",
            "Kablalan",
            "Kabugao",
            "Kabugao",
            "Kabulohan",
            "Kabulusan",
            "Kabungahan",
            "Kabuntalan",
            "Kabuynan",
            "Kabuynan",
            "Kadingilan",
            "Kagawasan",
            "Kagay",
            "Kagay",
            "Kajatian",
            "Kajatian",
            "Kalaisan",
            "Kalaisan",
            "Kalamangog",
            "Kalamangog",
            "Kalamansig",
            "Kalamansig",
            "Kalandagan",
            "Kalang",
            "Kalang",
            "Kalanganan",
            "Kalawit",
            "Kalayaan",
            "Kalbay",
            "Kalbay",
            "Kalbay",
            "Kalbugan",
            "Kalbugan",
            "Kalian",
            "Kalian",
            "Kalian",
            "Kalian",
            "Kalibo (poblacion)",
            "Kaligutan",
            "Kaligutan",
            "Kaligutan",
            "Kalilangan",
            "Kaliling",
            "Kaliliog",
            "Kalinga",
            "Kalingalan Caluang",
            "Kalugmanan",
            "Kamanga",
            "Kamanga",
            "Kambing",
            "Kambing",
            "Kampokpok",
            "Kampokpok",
            "Kananga",
            "Kananya",
            "Kandabong",
            "Kanlagay",
            "Kanlagay",
            "Kanluran",
            "Kansipati",
            "Kansipati",
            "Kaongkod",
            "Kapai",
            "Kapalong",
            "Kapalong",
            "Kapalong",
            "Kapangan",
            "Kapangan",
            "Kapatagan",
            "Kapatalan",
            "Kapatan",
            "Kapatan",
            "Kapaya",
            "Kapaya",
            "Kapingkong",
            "Kapinkong",
            "Karligan",
            "Karungdong",
            "Karungdong",
            "Kasibu",
            "Kasibu",
            "Katangawan",
            "Katico",
            "Katico",
            "Katidtuan",
            "Katidtuan",
            "Katipunan",
            "Katipunan",
            "Katipunan",
            "Katipunan",
            "Katubao",
            "Katubao",
            "Katuli",
            "Katuli",
            "Kauit",
            "Kauran",
            "Kauran",
            "Kauswagan",
            "Kauswagan",
            "Kauswagan",
            "Kawayan",
            "Kawayan",
            "Kawit",
            "Kawit",
            "Kayapa",
            "Kaytitinga",
            "Kiamba",
            "Kiamba",
            "Kiangan",
            "Kiangan",
            "Kibangay",
            "Kibawe",
            "Kiblawan",
            "Kiblawan",
            "Kiblawan",
            "Kibonsod",
            "Kibungan",
            "Kibungan",
            "Kibureau",
            "Kidapawan",
            "Kidapawan",
            "Kilim",
            "Kilim",
            "Kiloloran",
            "Kimanuit",
            "Kimaya",
            "Kinabhangan",
            "Kinabhangan",
            "Kinablangan",
            "Kinablangan",
            "Kinablangan",
            "Kinalaglagan",
            "Kinalansan",
            "Kinamayan",
            "Kinamayan",
            "Kinamayan",
            "Kinangan",
            "Kinangan",
            "Kinangan",
            "Kinatakutan",
            "Kipalbig",
            "Kipalbig",
            "Kipit",
            "Kisante",
            "Kisante",
            "Kisolon",
            "Kitango",
            "Kitango",
            "Kitaotao",
            "Kitapak",
            "Kitapak",
            "Kitcharao",
            "Kitcharao",
            "Kitobo",
            "Kiupo",
            "Kiupo",
            "Klinan",
            "Klinan",
            "Kling",
            "Kolambugan",
            "Kolape",
            "Kolape",
            "Kolumbug",
            "Koronadal",
            "Koronadal",
            "Kotkot",
            "Kuanos",
            "Kudanding",
            "Kudanding",
            "Kulaman",
            "Kulaman",
            "Kulase",
            "Kulase",
            "Kulay-Kulay",
            "Kulay-Kulay",
            "Kulempang",
            "Kulempang",
            "Kumalarang",
            "Kumalisquis",
            "Kungtad",
            "Kungtad",
            "La Carlota",
            "La Castellana",
            "La Curva",
            "La Dicha",
            "La Fortuna",
            "La Granja",
            "La Hacienda",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Libertad",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Paz",
            "La Roxas",
            "La Trinidad",
            "La Trinidad",
            "La Union",
            "La Union",
            "La Union",
            "La Union",
            "La Union",
            "Labangan",
            "Labasan",
            "Labason",
            "Labayug",
            "Labayug",
            "Labney",
            "Labney",
            "Labnig",
            "Labo",
            "Labog",
            "Labrador",
            "Labrador",
            "Labu-o",
            "Labu-o",
            "Labuan",
            "Labuñgan",
            "Labuñgan",
            "Lacag",
            "Lacaron",
            "Lacaron",
            "Lacaron",
            "Lacaron",
            "Lacdayan",
            "Lacson",
            "Lacson",
            "Lacson",
            "Lacub",
            "Lagangilang",
            "Lagangilang",
            "Lagasit",
            "Lagasit",
            "Lagawe",
            "Lagawe",
            "Lagayan",
            "Lagayan",
            "Lagindingan",
            "Lagonglong",
            "Lagonoy",
            "Laguilayan",
            "Laguilayan",
            "Laguit Centro",
            "Laguit Centro",
            "Laguitas",
            "Lais",
            "Lais",
            "Lais",
            "Laiya",
            "Lajong",
            "Lake Sebu",
            "Lal-lo",
            "Lala",
            "Lalab",
            "Lalagsan",
            "Lalauigan",
            "Lalauigan",
            "Lalig",
            "Lallayug",
            "Lallayug",
            "Lamak",
            "Lamak",
            "Lamba",
            "Lambakin",
            "Lambakin",
            "Lambayong",
            "Lambayong",
            "Lambo",
            "Lambontong",
            "Lambontong",
            "Lambunao",
            "Lamian",
            "Lamian",
            "Laminusa",
            "Laminusa",
            "Lamisahan",
            "Lamitan",
            "Lamitan",
            "Lamitan",
            "Lamitan",
            "Lamitan City",
            "Lamitan City",
            "Lampari",
            "Lampari",
            "Lampitak",
            "Lampitak",
            "Lamut",
            "Lamut",
            "Lanao",
            "Lanas",
            "Lanas",
            "Lanat",
            "Lanat",
            "Landang Laum",
            "Langatian",
            "Langcangan",
            "Langiden",
            "Langob",
            "Langpas",
            "Langpas",
            "Langtad",
            "Lañgub",
            "Languyan",
            "Languyan",
            "Lanigay",
            "Lanipao",
            "Lanna",
            "Lanna",
            "Lanot",
            "Lantangan",
            "Lantangan",
            "Lantapan",
            "Lanuza",
            "Lanuza",
            "Lao",
            "Lao",
            "Laoac",
            "Laoag",
            "Laoag",
            "Laoang",
            "Laoang",
            "Laparay",
            "Lapase",
            "Lapaz",
            "Lapi",
            "Lapi",
            "Lapinig",
            "Lapinig",
            "Lapinigan",
            "Lapinigan",
            "Lapining",
            "Lapolapo",
            "Lapu-Lapu City",
            "Lapuan",
            "Lapuan",
            "Lapuan",
            "Lapuyan",
            "Lapuz",
            "Lapuz",
            "Larap",
            "Larap",
            "Larap",
            "Larion Alto",
            "Larion Alto",
            "Las Navas",
            "Las Navas",
            "Las Nieves",
            "Las Piñas",
            "Lasam",
            "Lasam",
            "Lasang",
            "Lasang",
            "Lasang",
            "Latung",
            "Latung",
            "Laug",
            "Laug",
            "Laur",
            "Laur",
            "Laurel",
            "Lavezares",
            "Lavezares",
            "Lawang Kupang",
            "Lawang Kupang",
            "Lawigan",
            "Laya",
            "Laya",
            "Laylay",
            "Layog",
            "Layog",
            "Lazi",
            "Lebak",
            "Leganes",
            "Legaspi",
            "Legrada",
            "Lemery",
            "Lemery",
            "Lennec",
            "Lennec",
            "Leon",
            "Leon Postigo",
            "Leones East",
            "Leones East",
            "Lepa",
            "Lepa",
            "Lepanto",
            "Leuteboro",
            "Leyte",
            "Leyte",
            "Lian",
            "Lianga",
            "Lianga",
            "Libacao",
            "Libagon",
            "Libagon",
            "Libas",
            "Libas",
            "Libas",
            "Libas",
            "Libas",
            "Libas",
            "Libas",
            "Libas",
            "Libas",
            "Libato",
            "Libertad",
            "Libertad",
            "Libertad",
            "Libertad",
            "Libertad",
            "Libertad",
            "Libertad",
            "Libertad",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Libjo",
            "Libjo",
            "Libmanan",
            "Libog",
            "Libon",
            "Libona",
            "Liboran",
            "Liboro",
            "Libuganon",
            "Libuganon",
            "Libuganon",
            "Libungan",
            "Libungan",
            "Licab",
            "Licab",
            "Liciada",
            "Licuan",
            "Licuan",
            "Licuan-Baay",
            "Lidlidda",
            "Lidlidda",
            "Ligao",
            "Ligaya",
            "Ligaya",
            "Ligaya",
            "Ligayan",
            "Ligayan",
            "Lila",
            "Lilio",
            "Liliongan",
            "Liliongan",
            "Liloan",
            "Liloan",
            "Liloan",
            "Liloy",
            "Lim-oo",
            "Lim-oo",
            "Limanancong",
            "Limao",
            "Limao",
            "Limao",
            "Limaong",
            "Limasawa",
            "Limay",
            "Limay",
            "Limbaan",
            "Limbalod",
            "Limbalod",
            "Limbo",
            "Limbo",
            "Limbuhan",
            "Limon",
            "Limon",
            "Limon",
            "Limot",
            "Limot",
            "Limot",
            "Limpapa",
            "Limulan",
            "Limulan",
            "Linabo",
            "Linabuan",
            "Linabuan Sur",
            "Linamon",
            "Linan",
            "Linan (town)",
            "Linao",
            "Linao",
            "Linao",
            "Linao",
            "Linao",
            "Linaon",
            "Linay",
            "Lingasan",
            "Lingating",
            "Lingayen",
            "Lingayen",
            "Lingig",
            "Lingig",
            "Lingion",
            "Linmansangan",
            "Linmansangan",
            "Linoan",
            "Linoan",
            "Linoan",
            "Lintangan",
            "Liozon",
            "Liozon",
            "Lipa City",
            "Lipahan",
            "Lipay",
            "Lipay",
            "Lipayran",
            "Litayan",
            "Litayan",
            "Little Baguio",
            "Liwan",
            "Liwan",
            "Llanera",
            "Llanera",
            "Lloren",
            "Lloren",
            "Llorente",
            "Llorente",
            "Loacan",
            "Loacan",
            "Loay",
            "Lobo",
            "Loboc",
            "Lobong",
            "Lobong",
            "Locmayan",
            "Logon",
            "Loma de Gato",
            "Lombocan",
            "Lombog",
            "Lomboy",
            "Lomboy",
            "Longos",
            "Longos",
            "Lono",
            "Lonoy",
            "Looc",
            "Looc",
            "Looc",
            "Looc",
            "Looc",
            "Lookan",
            "Lookan",
            "Loon",
            "Lope de Vega",
            "Lope de Vega",
            "Lopez",
            "Lopez Jaena",
            "Lopez Jaena",
            "Lopez Jaena",
            "Lopez Jaena",
            "Loqueb Este",
            "Loqueb Este",
            "Loreto",
            "Loreto",
            "Los Angeles",
            "Los Angeles",
            "Los Arcos",
            "Los Arcos",
            "Los Baños",
            "Lourdes",
            "Lourdes",
            "Lourdes",
            "Lourdes",
            "Loyola",
            "Loyola",
            "Lu-uk",
            "Luba",
            "Luba",
            "Lubang",
            "Lubao",
            "Lubao",
            "Lubi",
            "Lubigan",
            "Lubuagan",
            "Lubuagan",
            "Lucap",
            "Lucap",
            "Lucapon",
            "Lucapon",
            "Lucban",
            "Lucban",
            "Lucban",
            "Lucena",
            "Lucena",
            "Lucero",
            "Lucero",
            "Lucero",
            "Lucsuhin",
            "Lugait",
            "Lugo",
            "Lugui",
            "Lugus",
            "Lugus",
            "Luisiana",
            "Lukatan",
            "Lukatan",
            "Lukatan",
            "Luklukan",
            "Luksuhin",
            "Lumangbayan",
            "Lumatil",
            "Lumatil",
            "Lumazal",
            "Lumazal",
            "Lumba-a-Bayabao",
            "Lumbac",
            "Lumbac",
            "Lumbang",
            "Lumbangan",
            "Lumbatan",
            "Lumbatan",
            "Lumbayan",
            "Lumbayanague",
            "Lumbayanague",
            "Lumbayao",
            "Lumbia",
            "Lumbog",
            "Lumil",
            "Lumuyon",
            "Lumuyon",
            "Lun Pequeño",
            "Lun Pequeño",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Luna",
            "Lunao",
            "Lunas",
            "Lunec",
            "Lunec",
            "Lunen",
            "Lunen",
            "Lungaog",
            "Lungaog",
            "Lungaog",
            "Lungog",
            "Lungog",
            "Luntal",
            "Lupao",
            "Lupao",
            "Lupi",
            "Lupi Viejo",
            "Lupo",
            "Lupon",
            "Lupon",
            "Lupon",
            "Lurugan",
            "Lusacan",
            "Lusong",
            "Lusong",
            "Lut-od",
            "Lutayan",
            "Lutayan",
            "Lutayan",
            "Luuk",
            "Luuk Datan",
            "Luuk Datan",
            "Luzon",
            "Luzon",
            "Luzon",
            "M'lang",
            "Maagnas",
            "Maan",
            "Maan",
            "Maanas",
            "Maao",
            "Maasim",
            "Maasim",
            "Maasim",
            "Maasim",
            "Maasin",
            "Maasin",
            "Maasin",
            "Maayon",
            "Maayong Tubig",
            "Mababanaba",
            "Mabahin",
            "Mabahin",
            "Mabalacat",
            "Mabalacat City",
            "Mabasa",
            "Mabasa",
            "Mabay",
            "Mabay",
            "Mabayo",
            "Mabayo",
            "Mabilang",
            "Mabilang",
            "Mabilao",
            "Mabilao",
            "Mabilbila Sur",
            "Mabilbila Sur",
            "Mabilog",
            "Mabilog",
            "Mabinay",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabini",
            "Mabitac",
            "Mabiton",
            "Mabua",
            "Mabua",
            "Mabuhay",
            "Mabuhay",
            "Mabuhay",
            "Mabuhay",
            "Mabunga",
            "Mabusag",
            "Mabusag",
            "Mabuttal East",
            "Mabuttal East",
            "Macaas",
            "Macabebe",
            "Macabebe",
            "Macabuboni",
            "Macabuboni",
            "Macabugos",
            "Macalamcam A",
            "Macalaya",
            "Macalelon",
            "Macalong",
            "Macalong",
            "Macalva Norte",
            "Macalva Norte",
            "Macapsing",
            "Macapsing",
            "Macarse",
            "Macarse",
            "MacArthur",
            "MacArthur",
            "Macatbong",
            "Macatbong",
            "Macayug",
            "Macayug",
            "Maco",
            "Maco",
            "Maco",
            "Maconacon",
            "Macrohon",
            "Macrohon",
            "Madalag",
            "Madalum",
            "Madalum",
            "Madamba",
            "Madamba",
            "Maddarulug Norte",
            "Maddarulug Norte",
            "Maddela",
            "Maddela",
            "Madrid",
            "Madrid",
            "Madridejos",
            "Maduao",
            "Maduao",
            "Maduao",
            "Madulao",
            "Magalalag",
            "Magalalag",
            "Magalang",
            "Magalang",
            "Magallanes",
            "Magallanes",
            "Magallanes",
            "Magallanes",
            "Magallanes",
            "Magallanes",
            "Magallon Cadre",
            "Maganoy",
            "Magapit",
            "Magapit Aguiguican",
            "Magarao",
            "Magatos",
            "Magatos",
            "Magatos",
            "Magay",
            "Magbay",
            "Magdalena",
            "Magdalena",
            "Magdalena",
            "Magdalena",
            "Magdiwang",
            "Magdug",
            "Magdug",
            "Magdug",
            "Maglamin",
            "Magliman",
            "Magliman",
            "Magnaga",
            "Magnaga",
            "Magnaga",
            "Magpet",
            "Magpet",
            "Magsalangi",
            "Magsaysay",
            "Magsaysay",
            "Magsaysay",
            "Magsaysay",
            "Magsaysay",
            "Magsaysay",
            "Magsaysay",
            "Magsingal",
            "Magsingal",
            "Magtaking",
            "Magtaking",
            "Magtangol",
            "Magtangol",
            "Magugpo Poblacion",
            "Maguilling",
            "Maguilling",
            "Maguinao",
            "Maguinao",
            "Maguing",
            "Maguling",
            "Maguling",
            "Maguyam",
            "Mahaba",
            "Mahabang Parang",
            "Mahagnao",
            "Mahala",
            "Mahala",
            "Mahanob",
            "Mahanob",
            "Mahanob",
            "Mahaplag",
            "Mahaplag",
            "Mahatao",
            "Mahatao",
            "Mahayag",
            "Mahayag",
            "Mahayag",
            "Mahayag",
            "Mahayag",
            "Mahinog",
            "Maibu",
            "Maibu",
            "Maigo",
            "Mailag",
            "Maimbung",
            "Maimbung",
            "Maindang",
            "Maindang",
            "Mainit",
            "Mainit",
            "Mainit",
            "Mainit",
            "Mainit Norte",
            "Maitum",
            "Maitum",
            "Majayjay",
            "Makati",
            "Makato",
            "Makilala",
            "Makilala",
            "Makir",
            "Makir",
            "Makiwalo",
            "Makiwalo",
            "Malabag",
            "Malabago",
            "Malabago",
            "Malabanan",
            "Malabanban Norte",
            "Malabang",
            "Malabang",
            "Malabog",
            "Malabon",
            "Malabon",
            "Malabon",
            "Malabonot",
            "Malabor",
            "Malabugas",
            "Malabuyoc",
            "Malacampa",
            "Malacampa",
            "Malaga",
            "Malaga",
            "Malagos",
            "Malagos",
            "Malagos",
            "Malaiba",
            "Malainen Luma",
            "Malajog",
            "Malajog",
            "Malalag",
            "Malalag",
            "Malalag",
            "Malamig",
            "Malamote",
            "Malamote",
            "Malanay",
            "Malanay",
            "Malandag",
            "Malandag",
            "Malanday",
            "Malangabang",
            "Malangas",
            "Malapag",
            "Malapag",
            "Malapatan",
            "Malapatan",
            "Malaruhatan",
            "Malasila",
            "Malasila",
            "Malasin",
            "Malasin",
            "Malasiqui",
            "Malasiqui",
            "Malasugui",
            "Malatap",
            "Malate",
            "Malawa",
            "Malawa",
            "Malawag",
            "Malay",
            "Malaya",
            "Malayal",
            "Malaybalay",
            "Malayo-an",
            "Malbang",
            "Malbang",
            "Malbug",
            "Malbug",
            "Malhiao",
            "Malibago",
            "Malibcong",
            "Malibong East",
            "Malibong East",
            "Malicboy",
            "Malidong",
            "Maligaya",
            "Maligaya",
            "Maliig",
            "Malilinao",
            "Malilipot",
            "Malim",
            "Malimono",
            "Malimono",
            "Malinao",
            "Malinao",
            "Malinao",
            "Malinao",
            "Malinao",
            "Malinao",
            "Malinao Ilaya",
            "Malinaw",
            "Malingao",
            "Malingao",
            "Malingin",
            "Malino",
            "Malino",
            "Malinta",
            "Malisbeng",
            "Malisbeng",
            "Malita",
            "Malita",
            "Malita",
            "Malitbog",
            "Malitbog",
            "Malitbog",
            "Malitubog",
            "Malitubog",
            "Mallig",
            "Mallig",
            "Malocloc",
            "Maloco",
            "Maloh",
            "Malolos",
            "Malolos",
            "Maloma",
            "Maloma",
            "Maltana",
            "Maltana",
            "Maluanluan",
            "Maluid",
            "Maluid",
            "Maluko",
            "Malungon",
            "Maluñgun",
            "Maluno Sur",
            "Maluno Sur",
            "Malusac",
            "Malusac",
            "Malusay",
            "Maluso",
            "Maluso",
            "Malvar",
            "Malway",
            "Mamala",
            "Mamali",
            "Mamali",
            "Mamatid",
            "Mambagatan",
            "Mambago",
            "Mambago",
            "Mambago",
            "Mambajao",
            "Mambatangan",
            "Mambayaan",
            "Mambog",
            "Mambog",
            "Mambulo",
            "Mamburao",
            "Mambusao",
            "Mamonit",
            "Mamonit",
            "Mampurog",
            "Mamungan",
            "Manabo",
            "Manabo",
            "Manacsac",
            "Manacsac",
            "Managa",
            "Managa",
            "Managa",
            "Managok",
            "Manalad",
            "Manaloal",
            "Manaloal",
            "Manaloal",
            "Manalongon",
            "Manamrag",
            "Mananum",
            "Manaoag",
            "Manaoag",
            "Manapa",
            "Manapa",
            "Manapla",
            "Manaring",
            "Manaring",
            "Manat",
            "Manat",
            "Manat",
            "Manatal",
            "Manatal",
            "Manaul",
            "Manaulanan",
            "Manaulanan",
            "Manay",
            "Manay",
            "Manay",
            "Mancilang",
            "Mandaluyong",
            "Mandangoa",
            "Mandaon",
            "Mandaue City",
            "Mandih",
            "Mandili",
            "Mandili",
            "Manga",
            "Manga",
            "Manga",
            "Manga",
            "Manga",
            "Mangaldan",
            "Mangaldan",
            "Mangarine",
            "Mangas",
            "Mangatarem",
            "Mangatarem",
            "Mangero",
            "Mangga",
            "Mangga",
            "Manggahan",
            "Mangili",
            "Mangili",
            "Mangili",
            "Mangoso",
            "Mangusu",
            "Manibaug Pasig",
            "Manibaug Pasig",
            "Manicahan",
            "Manika",
            "Manikling",
            "Manikling",
            "Manikling",
            "Manila",
            "Maninihon",
            "Manito",
            "Manjoy",
            "Mankayan",
            "Mankayan",
            "Manlucahoc",
            "Maño",
            "Manoc-Manoc",
            "Manogpi",
            "Manogpi",
            "Manolo Fortich",
            "Manquiring",
            "Mansalay",
            "Mansilingan",
            "Mantalongon",
            "Mantampay",
            "Mantang",
            "Mantang",
            "Manticao",
            "Mantiquil",
            "Manuangan",
            "Manuangan",
            "Manubul",
            "Manubul",
            "Manuel Roxas",
            "Manuel Roxas",
            "Manuk Mangkaw",
            "Manuk Mangkaw",
            "Manukan",
            "Manup",
            "Maonon",
            "Mapalacsiao",
            "Mapalacsiao",
            "Mapalad",
            "Mapalad",
            "Mapanas",
            "Mapanas",
            "Mapandan",
            "Mapandan",
            "Mapaniqui",
            "Mapaniqui",
            "Mapili",
            "Mapolopolo",
            "Mapolopolo",
            "Mapulo",
            "Mapulot",
            "Maputi",
            "Maquiapo",
            "Maquiapo",
            "Maquiling",
            "Marabut",
            "Maragondon",
            "Maramag",
            "Maranding",
            "Marantao",
            "Marantao",
            "Marao",
            "Maravilla",
            "Marawa",
            "Marawa",
            "Marawi",
            "Marawi City",
            "Marawis",
            "Marbel",
            "Marbel",
            "Marcos",
            "Marcos",
            "Margen",
            "Margen",
            "Margos",
            "Margosatubig",
            "Maria Aurora",
            "Maria Aurora",
            "Maria Cristina",
            "Mariano",
            "Mariano Marcos",
            "Mariano Marcos",
            "Maribojoc",
            "Maribong",
            "Maricaban",
            "Maricalom",
            "Marihatag",
            "Marihatag",
            "Marikina",
            "Marilao",
            "Marilao",
            "Marintoc",
            "Maripipi",
            "Maripipi",
            "Mariveles",
            "Mariveles",
            "Marogong",
            "Marogong",
            "Marsada",
            "Marsada",
            "Marunggas",
            "Marupit",
            "Masaba",
            "Masaguisi",
            "Masaling",
            "Masalipit",
            "Masalipit",
            "Masalukot Uno",
            "Masantol",
            "Masantol",
            "Masapang",
            "Masaraway",
            "Masarayao",
            "Masarayao",
            "Masaya",
            "Masaya Sur",
            "Masaya Sur",
            "Masbate",
            "Masiga",
            "Masinloc",
            "Masinloc",
            "Masipi West",
            "Masipi West",
            "Masiu",
            "Masiu",
            "Maslog",
            "Maslog",
            "Maslog",
            "Masoli",
            "Masonogan",
            "Mat-i",
            "Mataas Na Kahoy",
            "Matabao",
            "Matabao",
            "Matacon",
            "Matag-ob",
            "Matagbak",
            "Matala",
            "Matalam",
            "Matalam",
            "Matalom",
            "Matalom",
            "Matanao",
            "Matanao",
            "Matanao",
            "Matangad",
            "Matanog",
            "Matanog",
            "Mataya",
            "Mataya",
            "Matayumtayum",
            "Matayumtayum",
            "Mataywanac",
            "Mati",
            "Mati",
            "Mati",
            "Mati",
            "Mati",
            "Matiao",
            "Matiao",
            "Matiao",
            "Maticmatic",
            "Maticmatic",
            "Matinao",
            "Matingain",
            "Matiompong",
            "Matlang",
            "Matlang",
            "Matnog",
            "Matti",
            "Matti",
            "Matti",
            "Matuguinao",
            "Matungao",
            "Maturanoc",
            "Maturanoc",
            "Mauban",
            "Mauboh",
            "Mauboh",
            "Maugat West",
            "Mauhao",
            "Maulawin",
            "Mauraro",
            "Mawab",
            "Mawab",
            "Mawab",
            "Maxingal",
            "Maxingal",
            "Maya",
            "Mayabon",
            "Mayana",
            "Mayantoc",
            "Mayantoc",
            "Mayapusi",
            "Maydolong",
            "Maydolong",
            "Maygatasan",
            "Maygatasan",
            "Mayngaran",
            "Mayo",
            "Mayo",
            "Mayo",
            "Mayorga",
            "Mayorga",
            "Mayoyao",
            "Maypangdan",
            "Maypangdan",
            "McKinley",
            "Medellin",
            "Medina",
            "Mendez-Nuñez",
            "Mercedes",
            "Mercedes",
            "Mercedes",
            "Mercedes",
            "Merida",
            "Merida",
            "Mexico",
            "Mexico",
            "Meycauayan",
            "Meycauayan",
            "Miaga",
            "Miagao",
            "Mianay",
            "Miaray",
            "Midsalip",
            "Midsayap",
            "Midsayap",
            "Milagros",
            "Milaor",
            "Mileb",
            "Mileb",
            "Miliroc",
            "Mina",
            "Minalabac",
            "Minalin",
            "Minalin",
            "Minallo",
            "Minallo",
            "Minanga Norte",
            "Minanga Norte",
            "Minante Segundo",
            "Minante Segundo",
            "Minapan",
            "Minapan",
            "Minapasoc",
            "Mindupok",
            "Mindupok",
            "Minglanilla",
            "Minien East",
            "Minien East",
            "Minlagas",
            "Minolos",
            "Minuri",
            "Minuri",
            "Minuyan",
            "Miranda",
            "Moalboal",
            "Mobo",
            "Mogpog",
            "Moises Padilla",
            "Molave",
            "Molugan",
            "Molundo",
            "Monamon",
            "Monamon",
            "Monbon",
            "Moncada",
            "Moncada",
            "Monching",
            "Mondragon",
            "Mondragon",
            "Monkayo",
            "Monkayo",
            "Monkayo",
            "Monpon",
            "Monreal",
            "Montaneza",
            "Montecillo",
            "Montevista",
            "Montevista",
            "Montevista",
            "Montilla",
            "Moog",
            "Morales",
            "Moriones",
            "Moriones",
            "Morobuan",
            "Morong",
            "Morong",
            "Morong",
            "Motiong",
            "Motiong",
            "Motrico",
            "Motrico",
            "Mountain Province",
            "Mozon",
            "Mozzozzin Sur",
            "Mozzozzin Sur",
            "Muladbucad",
            "Mulanay",
            "Mulauin",
            "Munai",
            "Mungo",
            "Mungo",
            "Municipality of Delfin Albano",
            "Municipality of Indanan",
            "Municipality of Lantawan",
            "Municipality of Pangutaran",
            "Municipality of Sultan Gumander",
            "Municipality of Tongkil",
            "Muñoz",
            "Muñoz",
            "Muñoz East",
            "Muñoz East",
            "Muntinlupa",
            "Murcia",
            "Murcia",
            "Murcia",
            "Muricay",
            "Muti",
            "Naagas",
            "Naawan",
            "Nabangig",
            "Nabannagan West",
            "Nabannagan West",
            "Nabas",
            "Nabua",
            "Nabulao",
            "Nabunturan",
            "Nabunturan",
            "Nabunturan",
            "Naga",
            "Naga",
            "Naga",
            "Nagbacalan",
            "Nagbacalan",
            "Nagbalaye",
            "Nagbukel",
            "Nagcarlan",
            "Naghalin",
            "Naghalin",
            "Nagiba",
            "Nagpandayan",
            "Nagpandayan",
            "Nagrumbuan",
            "Nagrumbuan",
            "Nagsaing",
            "Nagsaing",
            "Nagtipunan",
            "Nagtipunan",
            "Naguelguel",
            "Naguelguel",
            "Naguilayan",
            "Naguilayan",
            "Naguilian",
            "Naguilian",
            "Naguilian",
            "Naguilian",
            "Nahawan",
            "Naic",
            "Naili",
            "Nailong",
            "Naisud",
            "Nalsian Norte",
            "Nalsian Norte",
            "Nalundan",
            "Nalus",
            "Nalus",
            "Nama",
            "Nama",
            "Nambalan",
            "Nambalan",
            "Namboongan",
            "Namboongan",
            "Nampicuan",
            "Nampicuan",
            "Namuac",
            "Namuac",
            "Nancalobasaan",
            "Nancalobasaan",
            "Nancamarinan",
            "Nancamarinan",
            "Nangalisan",
            "Nangalisan",
            "Nangan",
            "Nangan",
            "Nangan",
            "Nangka",
            "Nangka",
            "Nañgka",
            "Nanyo",
            "Nanyo",
            "Nanyo",
            "Napalitan",
            "Napnapan",
            "Napoles",
            "Napuro",
            "Napuro",
            "Naro",
            "Narra",
            "Narvacan",
            "Narvacan",
            "Nasipit",
            "Nasipit",
            "Nasugbu",
            "Natalungan",
            "National Capital Region",
            "Natividad",
            "Natividad",
            "Nato",
            "Nato",
            "Natonin",
            "Natonin",
            "Nattapian",
            "Nattapian",
            "Natubleng",
            "Natubleng",
            "Naujan",
            "Naval",
            "Naval",
            "Navatat",
            "Navatat",
            "Navotas",
            "Navotas",
            "Nena",
            "Nena",
            "Nenita",
            "Nenita",
            "New Agutaya",
            "New Baclayon",
            "New Baclayon",
            "New Baclayon",
            "New Bataan",
            "New Bataan",
            "New Batu Batu",
            "New Batu Batu",
            "New Bohol",
            "New Bohol",
            "New Bohol",
            "New Cebu",
            "New Cebu",
            "New Corella",
            "New Corella",
            "New Corella",
            "New Iloilo",
            "New Iloilo",
            "New Lagao",
            "New Lagao",
            "New Leyte",
            "New Leyte",
            "New Leyte",
            "New Panamao",
            "New Panay",
            "New Panay",
            "New Pandanon",
            "New Sibonga",
            "New Sibonga",
            "New Sibonga",
            "New Visayas",
            "New Visayas",
            "New Visayas",
            "New Washington",
            "NIA Valencia",
            "Nibaliw Central",
            "Nibaliw Central",
            "Nieves",
            "Nieves",
            "Nilombot",
            "Nilombot",
            "Ninoy",
            "Ninoy",
            "Niugan",
            "Niugan",
            "Niugan",
            "Noling",
            "Noling",
            "Norala",
            "Norala",
            "Norzagaray",
            "Norzagaray",
            "Novallas",
            "Noveleta",
            "Nueva Era",
            "Nueva Era",
            "Nueva Fuerza",
            "Nueva Valencia",
            "Nueva Vida Sur",
            "Nugas",
            "Nuing",
            "Nuing",
            "Nuing",
            "Numancia",
            "Nunguan",
            "Nunguan",
            "Nuyo",
            "Nuyo",
            "Oaqui",
            "Oaqui",
            "Obando",
            "Obando",
            "Obong",
            "Ocampo",
            "Ocaña",
            "Ochanado",
            "Ocoy",
            "Odala",
            "Odicon",
            "Odiong",
            "Odiong",
            "Odiongan",
            "Odiongan",
            "Ogod",
            "Ogtongon",
            "Okiot",
            "Old Panamao",
            "Olea",
            "Olea",
            "Olingan",
            "Olongapo",
            "Olongapo",
            "Olutanga",
            "Ondoy",
            "Opol",
            "Oracon",
            "Orani",
            "Orani",
            "Oras",
            "Oras",
            "Orion",
            "Orion",
            "Ormoc",
            "Ormoc",
            "Orong",
            "Oroquieta",
            "Osiao",
            "Osias",
            "Osias",
            "Oslob",
            "Osmeña",
            "Osmeña",
            "Oton",
            "Owak",
            "Ozamiz City",
            "Paagahan",
            "Paatan",
            "Paatan",
            "Paclolo",
            "Paco",
            "Paco Roman",
            "Paco Roman",
            "Pacol",
            "Padada",
            "Padada",
            "Padada",
            "Padang",
            "Padapada",
            "Padapada",
            "Paddaya",
            "Paddaya",
            "Padong",
            "Padong",
            "Padre Burgos",
            "Padre Burgos",
            "Padre Burgos",
            "Padre Garcia",
            "Padre Zamora",
            "Paete",
            "Pag-asa",
            "Pag-asa",
            "Pag-asa",
            "Pagadian",
            "Pagalungan",
            "Pagalungan",
            "Pagalungan",
            "Pagalungan",
            "Pagangan",
            "Pagangan",
            "Pagatin",
            "Pagatin",
            "Pagayawan",
            "Pagbilao",
            "Paglat",
            "Pagsabangan",
            "Pagsabangan",
            "Pagsabangan",
            "Pagsanahan Norte",
            "Pagsanahan Norte",
            "Pagsañgahan",
            "Pagsanghan",
            "Pagsanghan",
            "Pagsanjan",
            "Pagudpud",
            "Pagudpud",
            "Paiisa",
            "Paitan",
            "Paitan",
            "Paitan Este",
            "Paitan Este",
            "Paitan Norte",
            "Paitan Norte",
            "Pajo",
            "Pakiad",
            "Pakil",
            "Palacpalac",
            "Palacpalac",
            "Palagao Norte",
            "Palagao Norte",
            "Palahanan Uno",
            "Palali",
            "Palampas",
            "Palanan",
            "Palanas",
            "Palangue",
            "Palanit",
            "Palanit",
            "Palapag",
            "Palapag",
            "Palaroo",
            "Palaroo",
            "Palauig",
            "Palauig",
            "Palayan",
            "Palayan City",
            "Paldit",
            "Palestina",
            "Palguyod",
            "Palguyod",
            "Palhi",
            "Palhi",
            "Palian",
            "Palian",
            "Palimbang",
            "Palimbang",
            "Palkan",
            "Palkan",
            "Palma Gil",
            "Palma Gil",
            "Palma Gil",
            "Palo",
            "Palo",
            "Palomoc",
            "Palompon",
            "Palompon",
            "Palsong",
            "Paluan",
            "Palusapis",
            "Palusapis",
            "Pamantingan",
            "Pamantingan",
            "Pamatawan",
            "Pamatawan",
            "Pambisan",
            "Pambuhan",
            "Pambujan",
            "Pambujan",
            "Pamplona",
            "Pamplona",
            "Pamplona",
            "Pamplona",
            "Pan-an",
            "Panabingan",
            "Panabingan",
            "Panabo",
            "Panabo",
            "Panabo",
            "Panabuan",
            "Panabuan",
            "Panacan",
            "Panadtaban",
            "Panadtaban",
            "Panalanoy",
            "Panalanoy",
            "Panalingaan",
            "Panalipan",
            "Panalo-on",
            "Panan",
            "Panan",
            "Panay",
            "Panay",
            "Panay",
            "Panayacan",
            "Panaytayon",
            "Pance",
            "Pance",
            "Pancol",
            "Pandacan",
            "Pandacaqui",
            "Pandacaqui",
            "Pandag",
            "Pandakan",
            "Pandakan",
            "Pandami",
            "Pandan",
            "Pandan",
            "Pandan",
            "Pandan Niog",
            "Pandan Niog",
            "Pandasan",
            "Pandasan",
            "Pandasan",
            "Pandi",
            "Pandi",
            "Pando",
            "Pando",
            "Pang",
            "Pang",
            "Pangabuan",
            "Pangal Sur",
            "Pangal Sur",
            "Panganiban",
            "Panganiban",
            "Panganiban",
            "Pangao",
            "Pangapisan",
            "Pangapisan",
            "Pangascasan",
            "Pangascasan",
            "Pangdan",
            "Pangdan",
            "Pangdan",
            "Pangian",
            "Pangian",
            "Pangian",
            "Pangil",
            "Panglao",
            "Panglima Sugala",
            "Pañgobilian",
            "Pangpang",
            "Pangpang",
            "Pangpang",
            "Pangpang",
            "Panguiranan",
            "Pangulayan",
            "Pangutaran",
            "Pangyan",
            "Pangyan",
            "Panikian",
            "Panikian",
            "Panikian",
            "Panikian",
            "Panikian",
            "Panikihan",
            "Panique",
            "Paniqui",
            "Paniqui",
            "Paniqui",
            "Panitan",
            "Panitian",
            "Panlaitan",
            "Panlinlang",
            "Panognawan",
            "Pansol",
            "Pansoy",
            "Pantabangan",
            "Pantabangan",
            "Pantao",
            "Pantao-Ragat",
            "Pantar",
            "Pantay Na Matanda",
            "Pantijan No 2",
            "Pantubig",
            "Pantubig",
            "Pantukan",
            "Pantukan",
            "Pantukan",
            "Panubigan",
            "Panukulan",
            "Paoay",
            "Paoay",
            "Paombong",
            "Paombong",
            "Papaya",
            "Papaya",
            "Parabcan",
            "Paracale",
            "Paracelis",
            "Paracelis",
            "Paradahan",
            "Paraiso",
            "Paraiso",
            "Paraiso",
            "Parañaque",
            "Parang",
            "Parang",
            "Parang",
            "Parang",
            "Parang",
            "Parang",
            "Parangan",
            "Parangan",
            "Parian Dakula",
            "Parian Dakula",
            "Paringao",
            "Paringao",
            "Parioc Segundo",
            "Parioc Segundo",
            "Parion",
            "Parista",
            "Parista",
            "Pasacao",
            "Pasay",
            "Pasay",
            "Pasian",
            "Pasian",
            "Pasian",
            "Pasibi West",
            "Pasibi West",
            "Pasig",
            "Pasil",
            "Pasil",
            "Pasil",
            "Pasong Kawayan Primero",
            "Passi",
            "Pastrana",
            "Pastrana",
            "Pasuquin",
            "Pasuquin",
            "Pata",
            "Pata",
            "Pata",
            "Patabog",
            "Patao",
            "Patawag",
            "Patayac",
            "Patayac",
            "Pateros",
            "Patikul",
            "Patikul",
            "Patin-ay",
            "Patin-ay",
            "Patindeguen",
            "Patindeguen",
            "Patique",
            "Patnanungan",
            "Patnongon",
            "Pato-o",
            "Patonan",
            "Patong",
            "Patong",
            "Patpata Segundo",
            "Patpata Segundo",
            "Patria",
            "Patrocinio",
            "Pattao",
            "Pattao",
            "Patuto",
            "Pau",
            "Pau",
            "Paulba",
            "Pavia",
            "Pawa",
            "Pawa",
            "Pawak",
            "Pawak",
            "Pawican",
            "Pawili",
            "Pawing",
            "Pawing",
            "Payabon",
            "Payao",
            "Payao",
            "Payapa",
            "Payocpoc Sur",
            "Payocpoc Sur",
            "Paypay",
            "Payuhan",
            "Payuhan",
            "Pedtad",
            "Pedtad",
            "Peña",
            "Peñablanca",
            "Peñablanca",
            "Peñaranda",
            "Peñaranda",
            "Peñarrubia",
            "Peñarrubia",
            "Perez",
            "Perrelos",
            "Piagapo",
            "Piape I",
            "Pias",
            "Pias",
            "Piat",
            "Piat",
            "Piddig",
            "Piddig",
            "Pidigan",
            "Pidigan",
            "Pidsandawan",
            "Pidsandawan",
            "Pigcawayan",
            "Pigcawayan",
            "Pikit",
            "Pikit",
            "Pila",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pilar",
            "Pili",
            "Pili",
            "Pili",
            "Pilig",
            "Pililla",
            "Pimbalayan",
            "Pimbalayan",
            "Piña",
            "Piña",
            "Piña",
            "Pinabacdao",
            "Pinagsabangan",
            "Pinagsibaan",
            "Piñahan",
            "Piñahan",
            "Pinamalayan",
            "Pinambaran",
            "Pinambaran",
            "Pinamopoan",
            "Pinamopoan",
            "Pinamungahan",
            "Pinangomhan",
            "Pinaring",
            "Pinaring",
            "Pinayagan Norte",
            "Pindangan Centro",
            "Pindangan Centro",
            "Pines",
            "Pinili",
            "Pinili",
            "Pinit",
            "Pinokawan",
            "Pinoma",
            "Pinoma",
            "Pintuyan",
            "Pintuyan",
            "Pinugay",
            "Pinukpuk",
            "Pinukpuk",
            "Pio",
            "Pio",
            "Pio Duran",
            "Pitogo",
            "Pitogo",
            "Pitogo",
            "Pitogo",
            "Pitogo",
            "Pitogo",
            "Placer",
            "Placer",
            "Placer",
            "Plaridel",
            "Plaridel",
            "Plaridel",
            "Plaridel",
            "Plaridel",
            "Plaridel",
            "Plaridel",
            "Plaridel",
            "Platagata",
            "Poblacion San Felipe",
            "Poctol",
            "Pogonsili",
            "Pogonsili",
            "Pola",
            "Polahongon",
            "Polahongon",
            "Polanco",
            "Polañge",
            "Polañge",
            "Polangui",
            "Polillo",
            "Polloc",
            "Polo",
            "Polo",
            "Polo",
            "Polo",
            "Polo",
            "Polo",
            "Polomolok",
            "Polomolok",
            "Polong",
            "Polong",
            "Polong Norte",
            "Polong Norte",
            "Polonoling",
            "Polonoling",
            "Polopina",
            "Pondaguitan",
            "Pondaguitan",
            "Pondaguitan",
            "Pongol",
            "Ponong",
            "Ponot",
            "Ponso",
            "Pontevedra",
            "Pontian",
            "Poon-a-Bayabao",
            "Porac",
            "Porac",
            "Porais",
            "Porais",
            "Poro",
            "Port Area",
            "Port Barton",
            "Potia",
            "Potot",
            "Pototan",
            "Pozorrubio",
            "Pozorrubio",
            "Prado Siongco",
            "Prado Siongco",
            "President Quirino",
            "President Quirino",
            "President Roxas",
            "President Roxas",
            "President Roxas",
            "Prieto Diaz",
            "Prinza",
            "Progreso",
            "Prosperidad",
            "Prosperidad",
            "Prosperidad",
            "Province of  Zamboanga del Sur",
            "Province of Abra",
            "Province of Agusan del Norte",
            "Province of Agusan del Sur",
            "Province of Aklan",
            "Province of Albay",
            "Province of Antique",
            "Province of Aurora",
            "Province of Basilan",
            "Province of Bataan",
            "Province of Batanes",
            "Province of Batangas",
            "Province of Benguet",
            "Province of Bukidnon",
            "Province of Bulacan",
            "Province of Cagayan",
            "Province of Camarines Norte",
            "Province of Camarines Sur",
            "Province of Camiguin",
            "Province of Capiz",
            "Province of Catanduanes",
            "Province of Cavite",
            "Province of Cebu",
            "Province of Cotabato",
            "Province of Davao del Norte",
            "Province of Davao del Sur",
            "Province of Davao Oriental",
            "Province of Eastern Samar",
            "Province of Guimaras",
            "Province of Ifugao",
            "Province of Ilocos Norte",
            "Province of Ilocos Sur",
            "Province of Iloilo",
            "Province of Isabela",
            "Province of La Union",
            "Province of Laguna",
            "Province of Lanao del Norte",
            "Province of Lanao del Sur",
            "Province of Leyte",
            "Province of Maguindanao",
            "Province of Marinduque",
            "Province of Masbate",
            "Province of Mindoro Occidental",
            "Province of Mindoro Oriental",
            "Province of Misamis Occidental",
            "Province of Misamis Oriental",
            "Province of Negros Occidental",
            "Province of Negros Oriental",
            "Province of Northern Samar",
            "Province of Nueva Ecija",
            "Province of Nueva Vizcaya",
            "Province of Palawan",
            "Province of Pampanga",
            "Province of Pangasinan",
            "Province of Quezon",
            "Province of Quirino",
            "Province of Rizal",
            "Province of Romblon",
            "Province of Samar",
            "Province of Sarangani",
            "Province of Siquijor",
            "Province of Sorsogon",
            "Province of South Cotabato",
            "Province of Southern Leyte",
            "Province of Sultan Kudarat",
            "Province of Sulu",
            "Province of Surigao del Norte",
            "Province of Surigao del Sur",
            "Province of Tarlac",
            "Province of Tawi-Tawi",
            "Province of Zambales",
            "Province of Zamboanga del Norte",
            "Province of Zamboanga Sibugay",
            "Pualas",
            "Pualas",
            "Pualas",
            "Pudoc",
            "Pudoc",
            "Pudoc North",
            "Pudoc North",
            "Pudtol",
            "Puelay",
            "Puelay",
            "Puerto Bello",
            "Puerto Bello",
            "Puerto Galera",
            "Puerto Princesa",
            "Pugo",
            "Pugo",
            "Pulangbato",
            "Pulilan",
            "Pulilan",
            "Pulo",
            "Pulo",
            "Pulo",
            "Pulong Gubat",
            "Pulong Gubat",
            "Pulong Sampalok",
            "Pulong Sampalok",
            "Pulong Santa Cruz",
            "Puloypuloy",
            "Puloypuloy",
            "Pulung Santol",
            "Pulung Santol",
            "Pulungmasle",
            "Pulungmasle",
            "Pulupandan",
            "Punang",
            "Punao",
            "Punay",
            "Punay",
            "Puncan",
            "Puncan",
            "Pung-Pang",
            "Pung-Pang",
            "Pung-Pang",
            "Punolu",
            "Punolu",
            "Punta",
            "Punta",
            "Punta",
            "Punta",
            "Punta Silum",
            "Pura",
            "Pura",
            "Purac",
            "Purac",
            "Puricay",
            "Puricay",
            "Puro",
            "Puro Pinget",
            "Puro Pinget",
            "Putat",
            "Putiao",
            "Puting Kahoy",
            "Putingkahoy",
            "Putlod",
            "Putlod",
            "Putol",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon",
            "Quezon City",
            "Quiapo",
            "Quibal",
            "Quibal",
            "Quiling",
            "Quiling",
            "Quilo-quilo",
            "Quinabigan",
            "Quinagaringan",
            "Quinapondan",
            "Quinapundan",
            "Quinarayan",
            "Quinarayan",
            "Quinipot",
            "Quintong",
            "Quintong",
            "Quipot",
            "Quipot",
            "Quirino",
            "Quirino",
            "Quirino",
            "Quirino",
            "Quisao",
            "Quitang",
            "Ragan Norte",
            "Ragan Norte",
            "Ragandang",
            "Ragandang",
            "Ragay",
            "Rajal Norte",
            "Rajal Norte",
            "Ramain",
            "Ramon",
            "Ramon (municipal capital)",
            "Ramon Magsaysay",
            "Ramos",
            "Ramos",
            "Ramos West",
            "Ramos West",
            "Ranao",
            "Ranao",
            "Rancheria Payau",
            "Ransang",
            "Rapu-Rapu",
            "Real",
            "Real",
            "Real",
            "Rebe",
            "Recodo",
            "Recodo",
            "Reina Mercedes",
            "Reina Mercedes",
            "Rimpeso",
            "Rimpeso",
            "Rimus",
            "Rimus",
            "Rio Tuba",
            "Rissing",
            "Rissing",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Rizal",
            "Robonkon",
            "Rodriguez",
            "Rogongon",
            "Romblon",
            "Rominimbang",
            "Rominimbang",
            "Ronda",
            "Rosales",
            "Rosales",
            "Rosario",
            "Rosario",
            "Rosario",
            "Rosario",
            "Rosario",
            "Rosario",
            "Rosario",
            "Rotonda",
            "Rotunda",
            "Roxas",
            "Roxas",
            "Roxas",
            "Roxas City",
            "Saaban",
            "Saavedra",
            "Sabang",
            "Sabang",
            "Sabang",
            "Sabang",
            "Sabang",
            "Sabang",
            "Sabang",
            "Sabang Indan",
            "Sabangan",
            "Sablan",
            "Sablan",
            "Sablayan",
            "Sablig",
            "Sablig",
            "Sabtang",
            "Sabtang",
            "Sadanga",
            "Sadanga",
            "Sadsadan",
            "Sadsadan",
            "Sadsalan",
            "Sadsalan",
            "Sagacad",
            "Sagada",
            "Sagada",
            "Sagana",
            "Sagana",
            "Sagana",
            "Sagang",
            "Sagasa",
            "Sagasa",
            "Sagasa",
            "Sagay",
            "Sagay",
            "Sagbayan",
            "Sagnay",
            "Sagpon",
            "Sagrada",
            "Sagrada Familia",
            "Sagud-Bahley",
            "Sagud-Bahley",
            "Saguday",
            "Saguday",
            "Saguiaran",
            "Saguiaran",
            "Saguing",
            "Saguing",
            "Sagunto",
            "Sagunto",
            "Sagurong",
            "Saint Bernard",
            "Saint Bernard",
            "Sal-Lapadan",
            "Salamanca",
            "Salapungan",
            "Salapungan",
            "Salawagan",
            "Salay",
            "Salaza",
            "Salaza",
            "Salcedo",
            "Salcedo",
            "Salcedo",
            "Salcedo",
            "Salcedo",
            "Salcedo",
            "Salcedo",
            "Salimbalan",
            "Salimbao",
            "Salimbao",
            "Salinas",
            "Salinas",
            "Salingogan",
            "Salinungan Proper",
            "Salinungan Proper",
            "Salogon",
            "Salug",
            "Salunayan",
            "Salunayan",
            "Salvacion",
            "Salvacion",
            "Salvacion",
            "Salvacion",
            "Salvacion",
            "Salvacion",
            "Salvacion",
            "Salvacion I",
            "Salvacion I",
            "Salvador",
            "Samal",
            "Samal",
            "Samal",
            "Samal",
            "Samal",
            "Sambayanihan People's Village",
            "Samboan",
            "Sambuluan",
            "Sambuluan",
            "Samon",
            "Samon",
            "Sampagar",
            "Sampaloc",
            "Sampaloc",
            "Sampaloc",
            "Sampao",
            "Sampao",
            "Sampao",
            "Sampao",
            "Sampao",
            "Sampiro",
            "San Agustin",
            "San Agustin",
            "San Agustin",
            "San Agustin",
            "San Agustin",
            "San Agustin",
            "San Agustin",
            "San Alejandro",
            "San Alejandro",
            "San Alfonso",
            "San Alfonso",
            "San Alfonso",
            "San Andres",
            "San Andres",
            "San Andres",
            "San Andres",
            "San Anton",
            "San Anton",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Aquilino",
            "San Basilio",
            "San Basilio",
            "San Benito",
            "San Benito",
            "San Benito",
            "San Benito",
            "San Bernardo",
            "San Bernardo",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Casimiro",
            "San Casimiro",
            "San Celestio",
            "San Clemente",
            "San Clemente",
            "San Cristobal",
            "San Cristobal",
            "San Diego",
            "San Dionisio",
            "San Eduardo",
            "San Eduardo",
            "San Emilio",
            "San Emilio",
            "San Enrique",
            "San Esteban",
            "San Esteban",
            "San Eugenio",
            "San Eugenio",
            "San Fabian",
            "San Fabian",
            "San Fabian",
            "San Fabian",
            "San Felipe",
            "San Felipe",
            "San Felipe",
            "San Felipe Old",
            "San Felipe Old",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando",
            "San Fernando Poblacion",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Francisco",
            "San Gabriel",
            "San Gabriel",
            "San Gabriel",
            "San Gabriel First",
            "San Gabriel First",
            "San Gregorio",
            "San Guillermo",
            "San Guillermo",
            "San Ignacio",
            "San Ignacio",
            "San Ignacio",
            "San Ildefonso",
            "San Ildefonso",
            "San Ildefonso",
            "San Ildefonso",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Isidro",
            "San Jacinto",
            "San Jacinto",
            "San Jacinto",
            "San Jacinto",
            "San Jacinto",
            "San Joaquin",
            "San Joaquin",
            "San Joaquin",
            "San Joaquin",
            "San Jorge",
            "San Jorge",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose",
            "San Jose de Buan",
            "San Jose de Buan",
            "San Jose del Monte",
            "San Jose del Monte",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan",
            "San Juan de Mata",
            "San Juan de Mata",
            "San Julian",
            "San Julian",
            "San Leonardo",
            "San Leonardo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lorenzo",
            "San Lucas",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Luis",
            "San Manuel",
            "San Manuel",
            "San Manuel",
            "San Manuel",
            "San Manuel",
            "San Manuel",
            "San Marcelino",
            "San Marcelino",
            "San Mariano",
            "San Mariano",
            "San Mariano",
            "San Mariano",
            "San Mariano",
            "San Mariano",
            "San Mariano",
            "San Mariano",
            "San Martin",
            "San Mateo",
            "San Mateo",
            "San Mateo",
            "San Mateo",
            "San Mateo",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Miguel",
            "San Narciso",
            "San Narciso",
            "San Narciso",
            "San Nicolas",
            "San Nicolas",
            "San Nicolas",
            "San Nicolas",
            "San Nicolas",
            "San Nicolas",
            "San Pablo",
            "San Pablo",
            "San Pablo",
            "San Pablo",
            "San Pascual",
            "San Pascual",
            "San Pascual",
            "San Pascual",
            "San Pascual",
            "San Patricio",
            "San Patricio",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro",
            "San Pedro Apartado",
            "San Pedro Apartado",
            "San Pedro One",
            "San Policarpio",
            "San Policarpio",
            "San Policarpo",
            "San Policarpo",
            "San Quintin",
            "San Quintin",
            "San Quintin",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Rafael",
            "San Ramon",
            "San Ramon",
            "San Ramon",
            "San Remigio",
            "San Remigio",
            "San Remigio",
            "San Remigio",
            "San Remigio",
            "San Ricardo",
            "San Ricardo",
            "San Ricardo",
            "San Ricardo",
            "San Roque",
            "San Roque",
            "San Roque",
            "San Roque",
            "San Roque",
            "San Roque",
            "San Roque Dau First",
            "San Roque Dau First",
            "San Salvador",
            "San Sebastian",
            "San Sebastian",
            "San Sebastian",
            "San Sebastian",
            "San Sebastian",
            "San Simon",
            "San Simon",
            "San Teodoro",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vicente",
            "San Vincente",
            "San Vincente",
            "Sanchez Mira",
            "Sandayong Sur",
            "Sandiat Centro",
            "Sandiat Centro",
            "Sandolot",
            "Sanga-Sanga",
            "Sanga-Sanga",
            "Sangali",
            "Sangat",
            "Sangay",
            "Sañgay",
            "Sanghan",
            "Sanghan",
            "Sankanan",
            "Sanlibo",
            "Sanlibo",
            "Santa",
            "Santa",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Ana",
            "Santa Angel",
            "Santa Barbara",
            "Santa Barbara",
            "Santa Barbara",
            "Santa Barbara",
            "Santa Barbara",
            "Santa Brigida",
            "Santa Catalina",
            "Santa Catalina",
            "Santa Catalina",
            "Santa Catalina",
            "Santa Catalina Norte",
            "Santa Catalina Sur",
            "Santa Cecilia",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Elena",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe",
            "Santa Filomena",
            "Santa Ignacia",
            "Santa Ignacia",
            "Santa Ines West",
            "Santa Ines West",
            "Santa Josefa",
            "Santa Josefa",
            "Santa Juliana",
            "Santa Juliana",
            "Santa Justina",
            "Santa Lucia",
            "Santa Lucia",
            "Santa Lucia",
            "Santa Lucia",
            "Santa Lucia",
            "Santa Magdalena",
            "Santa Marcela",
            "Santa Marcela",
            "Santa Margarita",
            "Santa Margarita",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Maria",
            "Santa Monica",
            "Santa Monica",
            "Santa Monica",
            "Santa Monica",
            "Santa Monica",
            "Santa Nino",
            "Santa Paz",
            "Santa Praxedes",
            "Santa Praxedes",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita",
            "Santa Rita Aplaya",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa Sur",
            "Santa Teresa",
            "Santa Teresa",
            "Santa Teresa First",
            "Santa Teresa First",
            "Santa Teresita",
            "Santa Teresita",
            "Santa Teresita",
            "Santa Teresita",
            "Santander Poblacion",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santiago",
            "Santisimo Rosario",
            "Santo Cristo",
            "Santo Cristo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Domingo",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Niño",
            "Santo Rosario",
            "Santo Rosario",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santo Tomas",
            "Santol",
            "Santol",
            "Santol",
            "Santol",
            "Santol",
            "Santor",
            "Sapa",
            "Sapa",
            "Sapa-Sapa",
            "Sapad",
            "Sapad",
            "Sapadun",
            "Sapadun",
            "Sapang",
            "Sapang",
            "Sapang Buho",
            "Sapang Buho",
            "Sapang Dalaga",
            "Sapian",
            "Sapol",
            "Sapol",
            "Sapu Padidu",
            "Sapu Padidu",
            "Sara",
            "Sarangani",
            "Sarangani",
            "Sarangani",
            "Saravia",
            "Saraza",
            "Sariaya",
            "Sarrat",
            "Sarrat",
            "Sasmuan",
            "Satan",
            "Satan",
            "Saysain",
            "Saysain",
            "Sebaste",
            "Sebu",
            "Semirara",
            "Semut",
            "Semut",
            "Seres",
            "Sergio Osmeña Sr",
            "Sevilla",
            "Sexmoan",
            "Shariff Aguak",
            "Siari",
            "Siasi",
            "Siasi",
            "Siaton",
            "Siay",
            "Siayan",
            "Sibagat",
            "Sibagat",
            "Sibaguan",
            "Sibalom",
            "Sibonga",
            "Sibucao",
            "Sibuco",
            "Sibul",
            "Sibul",
            "Sibulan",
            "Sibulan",
            "Sibulan",
            "Sibulan",
            "Sibulan",
            "Sibulao",
            "Sibutao",
            "Siclong",
            "Siclong",
            "Sico Uno",
            "Siempre Viva",
            "Siempre Viva",
            "Sierra Bullones",
            "Sigaboy",
            "Sigaboy",
            "Sigaboy",
            "Sigay",
            "Sigay",
            "Sigma",
            "Siguinon",
            "Siguinon",
            "Sikatuna",
            "Silab",
            "Silae",
            "Silago",
            "Silago",
            "Silang",
            "Silanga",
            "Silanga",
            "Sillawit",
            "Sillawit",
            "Sillon",
            "Silongin",
            "Silvino Lobos",
            "Silvino Lobos",
            "Silway 7",
            "Silway 7",
            "Simala",
            "Simanu Sur",
            "Simanu Sur",
            "Simbahan",
            "Simimbaan",
            "Simimbaan",
            "Simod",
            "Simod",
            "Simod",
            "Simuay",
            "Simuay",
            "Simunul",
            "Simunul",
            "Sinacaban",
            "Sinait",
            "Sinait",
            "Sinait",
            "Sinait",
            "Sinala",
            "Sinamar",
            "Sinamar",
            "Sinawilan",
            "Sinawilan",
            "Sinawilan",
            "Sinayawan",
            "Sinayawan",
            "Sinayawan",
            "Sindangan",
            "Sindon",
            "Sindon",
            "Singkamas",
            "Sinilian First",
            "Sinilian First",
            "Siniloan",
            "Sinisian",
            "Sinolon",
            "Sinolon",
            "Sinonoc",
            "Sinubong",
            "Sinubong",
            "Sinubong",
            "Sinuknipan",
            "Siocon",
            "Sionogan",
            "Sionogan",
            "Sipalay",
            "Sipocot",
            "Siquijor",
            "Siraway",
            "Sirib",
            "Sirib",
            "Sirib",
            "Siruma",
            "Sison",
            "Sison",
            "Sison",
            "Sison",
            "Sitangkai",
            "Sitangkai",
            "Socorro",
            "Socorro",
            "Socorro",
            "Sogod",
            "Sogod",
            "Sogod",
            "Sogod",
            "Sogod",
            "Solana",
            "Solana",
            "Solana",
            "Solano",
            "Solano",
            "Soledad",
            "Soledad",
            "Solo",
            "Solsona",
            "Solsona",
            "Songculan",
            "Sonquil",
            "Sonquil",
            "Sorsogon",
            "South Ubian",
            "South Upi",
            "South Upi",
            "Soyung",
            "Soyung",
            "Sual",
            "Sual",
            "Suay",
            "Suba",
            "Subic",
            "Subic",
            "Subic",
            "Subusub",
            "Subusub",
            "Sudipen",
            "Sudipen",
            "Sugal",
            "Sugal",
            "Sugal",
            "Sugbongkogon",
            "Sugcad",
            "Sugod",
            "Sugpon",
            "Sugpon",
            "Suklayin",
            "Suklayin",
            "Sula",
            "Sula",
            "Sulangan",
            "Sulangan",
            "Sulangan",
            "Sulangan",
            "Sulat",
            "Sulat",
            "Sulit",
            "Sulit",
            "Sulop",
            "Sulop",
            "Sulop",
            "Sultan Kudarat",
            "Sultan Kudarat",
            "Sultan sa Barongis",
            "Sultan Sumagka",
            "Sulucan",
            "Sulucan",
            "Sumabnit",
            "Sumabnit",
            "Sumag",
            "Sumagui",
            "Sumalig",
            "Sumilao",
            "Sumisip",
            "Sumisip",
            "Sumpong",
            "Sungai",
            "Surallah",
            "Surallah",
            "Surigao",
            "Surigao City",
            "Surup",
            "Surup",
            "Surup",
            "Suso",
            "Suso",
            "Suyan",
            "Suyan",
            "Suyo",
            "Suyo",
            "Suz-on",
            "Suz-on",
            "Suz-on",
            "T'boli",
            "Taal",
            "Tabaan",
            "Tabaan",
            "Tabacao",
            "Tabacao",
            "Tabaco",
            "Tabalong",
            "Tabango",
            "Tabango",
            "Tabiauan",
            "Tabiauan",
            "Tabid",
            "Tabina",
            "Tabinay",
            "Tabing",
            "Tabing",
            "Tabio",
            "Tabio",
            "Tablac",
            "Tablac",
            "Tablas",
            "Tablas",
            "Taboc",
            "Tabogon",
            "Tabon",
            "Tabon",
            "Tabon",
            "Tabon",
            "Tabonoc",
            "Tabonok",
            "Tabontabon",
            "Tabontabon",
            "Tabu",
            "Tabuan",
            "Tabuating",
            "Tabuating",
            "Tabuc Pontevedra",
            "Tabuelan",
            "Tabug",
            "Tabug",
            "Tabuk",
            "Tabuk",
            "Tabunok",
            "Tacadang",
            "Tacadang",
            "Tacligan",
            "Tacloban",
            "Tacloban",
            "Taclobo",
            "Tacub",
            "Tacurong",
            "Tacurong",
            "Tadian",
            "Tadian",
            "Taft",
            "Taft",
            "Tagakpan",
            "Tagakpan",
            "Tagakpan",
            "Tagana-an",
            "Tagana-an",
            "Taganak",
            "Tagapul-an",
            "Tagapul-an",
            "Tagas",
            "Tagasilay",
            "Tagaytay",
            "Tagbacan Ibaba",
            "Tagbak",
            "Tagbilaran City",
            "Tagbina",
            "Tagbina",
            "Tagbita",
            "Tagbubungang Diot",
            "Tagbubungang Diot",
            "Tagburos",
            "Tagcatong",
            "Tagcatong",
            "Tagdanua",
            "Tagdanua",
            "Tagdanua",
            "Tagkawayan Sabang",
            "Tagnanan",
            "Tagnanan",
            "Tagnanan",
            "Tago",
            "Tago",
            "Tagoloan",
            "Tagoytoy",
            "Tagudin",
            "Tagudin",
            "Taguig",
            "Taguing",
            "Taguing",
            "Taguisa",
            "Taguisa",
            "Taguitic",
            "Tagum",
            "Tagum",
            "Tagum Norte",
            "Tagusao",
            "Tairan Camp",
            "Tairan Camp",
            "Tajao",
            "Takub",
            "Takub",
            "Takub",
            "Tal I Mun Doc",
            "Tal I Mun Doc",
            "Tala",
            "Talabaan",
            "Talaban",
            "Talacogon",
            "Talacogon",
            "Talaga",
            "Talaga",
            "Talaga",
            "Talagutong",
            "Talagutong",
            "Talagutong",
            "Talahib Payap",
            "Talahiban I",
            "Talaibon",
            "Talakag",
            "Talalora",
            "Talalora",
            "Talang",
            "Talang",
            "Talangnan",
            "Talavera",
            "Talavera",
            "Talayan",
            "Talayan",
            "Talibon",
            "Taligaman",
            "Taligaman",
            "Talipan",
            "Talipao",
            "Talipao",
            "Talipaw",
            "Talipaw",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisay",
            "Talisayan",
            "Talisayan",
            "Talisayan",
            "Talisayan",
            "Talitay",
            "Taloc",
            "Talokgañgan",
            "Talomo",
            "Talomo",
            "Talomo",
            "Talon",
            "Talospatang",
            "Talospatang",
            "Taloy",
            "Taloy",
            "Taloy",
            "Taloy",
            "Taltal",
            "Taltal",
            "Talubatib",
            "Talugtug",
            "Talugtug",
            "Taluksangay",
            "Talusan",
            "Taluya",
            "Taluya",
            "Tamayo",
            "Tamayo",
            "Tamayong",
            "Tamayong",
            "Tamayong",
            "Tambac",
            "Tambak",
            "Tambak",
            "Tambalan",
            "Tambalisa",
            "Tambilil",
            "Tambilil",
            "Tambo",
            "Tambo",
            "Tambo",
            "Tambo",
            "Tambo",
            "Tambong",
            "Tambongon",
            "Tambulig",
            "Tamisan",
            "Tamisan",
            "Tamisan",
            "Tamiso",
            "Tamlang",
            "Tamnag",
            "Tamnag",
            "Tamontaka",
            "Tamorong",
            "Tamorong",
            "Tampakan",
            "Tampakan",
            "Tampakan",
            "Tampakan",
            "Tamparan",
            "Tamparan",
            "Tampayan",
            "Tampilisan",
            "Tampocon",
            "Tamugan",
            "Tamugan",
            "Tamugan",
            "Tanauan",
            "Tanauan",
            "Tanauan",
            "Tanay",
            "Tandag",
            "Tandag",
            "Tandayag",
            "Tandoc",
            "Tandoc",
            "Tandubas",
            "Tangal",
            "Tangalan",
            "Tangke",
            "Tangnan",
            "Tañgo",
            "Tañgo",
            "Tangub",
            "Tanjay",
            "Tanlad",
            "Tanlad",
            "Tanlad",
            "Tanolong",
            "Tanolong",
            "Tantangan",
            "Tantangan",
            "Tanudan",
            "Tanudan",
            "Tanza",
            "Tanza",
            "Tanza",
            "Tapas",
            "Tapayan",
            "Tapayan",
            "Tapel",
            "Tapel",
            "Tapia",
            "Tapia",
            "Tapia",
            "Tapikan",
            "Tapikan",
            "Tapilon",
            "Tapon",
            "Tapul",
            "Tara",
            "Taraka",
            "Tarangnan",
            "Tarangnan",
            "Tariji",
            "Tariji",
            "Tariric",
            "Tarlac City",
            "Tarlac City",
            "Tarong",
            "Tarragona",
            "Tarragona",
            "Tarragona",
            "Tartaro",
            "Tarusan",
            "Taungoh",
            "Taungoh",
            "Taviran",
            "Taviran",
            "Tawagan",
            "Tawala",
            "Tawan tawan",
            "Tawan tawan",
            "Tawan tawan",
            "Tayabas",
            "Tayabas Ibaba",
            "Tayabo",
            "Tayabo",
            "Tayaman",
            "Tayasan",
            "Taypano",
            "Taysan",
            "Taytay",
            "Taytay",
            "Taytay",
            "Taytayan",
            "Taytayan",
            "Taytayan",
            "Taytayan",
            "Tayud",
            "Tayug",
            "Tayug",
            "Tayum",
            "Tayum",
            "Taywanak Ilaya",
            "Tebag East",
            "Tebag East",
            "Telabastagan",
            "Telabastagan",
            "Telafas",
            "Telafas",
            "Telbang",
            "Telbang",
            "Teresa",
            "Teresita",
            "Teresita",
            "Ternate",
            "Tiaong",
            "Tibagon",
            "Tibagon",
            "Tibagon",
            "Tibanbang",
            "Tibanbang",
            "Tibanbang",
            "Tibiao",
            "Tibigan",
            "Tiblawan",
            "Tiblawan",
            "Tiblawan",
            "Tibur",
            "Ticala-an",
            "Tidman",
            "Tidman",
            "Tiep",
            "Tiep",
            "Tigao",
            "Tigao",
            "Tigaon",
            "Tigbao",
            "Tigbao",
            "Tigbauan",
            "Tigbaw",
            "Tigbinan",
            "Tiglauigan",
            "Tignapalan",
            "Tignoan",
            "Tigpalay",
            "Tigtabon",
            "Tiguha",
            "Tigui",
            "Tiguib",
            "Tiguion",
            "Tiguisan",
            "Tigum",
            "Tikiw",
            "Tikiw",
            "Tilik",
            "Tiling",
            "Timonan",
            "Timpas",
            "Tinaan",
            "Tinagacan",
            "Tinago",
            "Tinalmud",
            "Tinambac",
            "Tinambacan",
            "Tinambacan",
            "Tinampo",
            "Tinang",
            "Tinang",
            "Tinaogan",
            "Tinawagan",
            "Tindog",
            "Tineg",
            "Tinglayan",
            "Tinglayan",
            "Tingloy",
            "Tiniguiban",
            "Tinoc",
            "Tinogboc",
            "Tinongan",
            "Tinoto",
            "Tinoto",
            "Tinubuan",
            "Tinutulan",
            "Tinutulan",
            "Tiparak",
            "Tipaz",
            "Tipo-Tipo",
            "Tipo-Tipo",
            "Tipolo",
            "Tiring",
            "Titay",
            "Tiwi",
            "Tiwi",
            "Tobias Fornier",
            "Toboso",
            "Toboy",
            "Toboy",
            "Tobuan",
            "Tobuan",
            "Tococ East",
            "Tococ East",
            "Tocok",
            "Tocok",
            "Toledo",
            "Tolosa",
            "Tolosa",
            "Tolosa",
            "Tomado",
            "Tomado",
            "Tomas Oppus",
            "Tomas Oppus",
            "Tombod",
            "Tombod",
            "Tombongon",
            "Tombongon",
            "Tombongon",
            "Tomingad",
            "Tominhao",
            "Tondo",
            "Tondod",
            "Tondod",
            "Tondol",
            "Tondol",
            "Tongouson",
            "Tongouson",
            "Toong",
            "Topdac",
            "Topdac",
            "Toritori",
            "Toritori",
            "Torrijos",
            "Tortosa",
            "Totolan",
            "Tran",
            "Tran",
            "Tranca",
            "Trapiche",
            "Trento",
            "Trento",
            "Trinidad",
            "Tuao",
            "Tuao",
            "Tuba",
            "Tubajon",
            "Tubajon",
            "Tubalan",
            "Tubalan",
            "Tubalan",
            "Tuban",
            "Tuban",
            "Tuban",
            "Tuban",
            "Tubao",
            "Tubao",
            "Tubao",
            "Tubaran",
            "Tubaran",
            "Tubay",
            "Tubay",
            "Tubigagmanoc",
            "Tubigan",
            "Tublay",
            "Tublay",
            "Tubli",
            "Tubo",
            "Tubod",
            "Tubod",
            "Tubod",
            "Tubod",
            "Tubod",
            "Tubod",
            "Tubod",
            "Tubod-dugoan",
            "Tuburan",
            "Tuburan",
            "Tuburan",
            "Tuburan",
            "Tucdao",
            "Tucdao",
            "Tucuran",
            "Tudela",
            "Tudela",
            "Tuding",
            "Tuding",
            "Tuganay",
            "Tuganay",
            "Tuganay",
            "Tugas",
            "Tugaya",
            "Tugaya",
            "Tugbong",
            "Tugbong",
            "Tugdan",
            "Tugos",
            "Tuguegarao",
            "Tuguegarao",
            "Tuguegarao City",
            "Tuhian",
            "Tuka",
            "Tuka",
            "Tulay",
            "Tulay na Lupa",
            "Tuli",
            "Tuli",
            "Tuli",
            "Tulunan",
            "Tulunan",
            "Tumalaytay",
            "Tumalim",
            "Tumarbong",
            "Tumauini",
            "Tumauini",
            "Tumbagaan",
            "Tumbagaan",
            "Tumcon Ilawod",
            "Tunga",
            "Tunga",
            "Tungao",
            "Tungawan",
            "Tunggol",
            "Tunggol",
            "Tungol",
            "Tungol",
            "Tuod",
            "Tupang",
            "Tupang",
            "Tupi",
            "Tupi",
            "Tupsan",
            "Turtle Islands",
            "Tutay",
            "Tutubigan",
            "Tutubigan",
            "Tuy",
            "Tuyan",
            "Tuyan",
            "Tuyum",
            "Uacon",
            "Uacon",
            "Ualog",
            "Ubay",
            "Uddiawan",
            "Uddiawan",
            "Ugac Sur",
            "Ugac Sur",
            "Ugad",
            "Ugad",
            "Ula",
            "Ula",
            "Ula",
            "Uling",
            "Umabay",
            "Umaganhan",
            "Umaganhan",
            "Umanday Centro",
            "Umanday Centro",
            "Umingan",
            "Umingan",
            "Umiray",
            "Umiray",
            "Ungca",
            "Ungus-Ungus",
            "Ungus-Ungus",
            "Unidad",
            "Unidad",
            "Unidos",
            "Unidos",
            "Union",
            "Union",
            "Union",
            "Union",
            "Unisan",
            "Unzad",
            "Unzad",
            "Upi",
            "Upi",
            "Upi",
            "Upi",
            "Upig",
            "Upig",
            "Upper Klinan",
            "Upper Klinan",
            "Upper San Mateo",
            "Upper San Mateo",
            "Urbiztondo",
            "Urbiztondo",
            "Urdaneta",
            "Urdaneta",
            "Usab",
            "Uson",
            "Utabi",
            "Uyaan",
            "Uyaan",
            "Uyong",
            "Uyong",
            "Uyugan",
            "Uyugan",
            "Valderrama",
            "Valencia",
            "Valencia",
            "Valencia",
            "Valenzuela",
            "Valladolid",
            "Valle Hermoso",
            "Vallehermoso",
            "Vargas",
            "Vargas",
            "Veruela",
            "Veruela",
            "Victoria",
            "Victoria",
            "Victoria",
            "Victoria",
            "Victoria",
            "Victorias",
            "Viejo Daan Banua",
            "Viga",
            "Viga",
            "Viga",
            "Vigan",
            "Vigan",
            "Vigo",
            "Villa Aglipay",
            "Villa Aglipay",
            "Villa Isla",
            "Villa Isla",
            "Villaba",
            "Villaba",
            "Villahermosa",
            "Villamor",
            "Villamor",
            "Villanueva",
            "Villanueva",
            "Villareal",
            "Villareal",
            "Villarosa",
            "Villarosa",
            "Villasis",
            "Villasis",
            "Villaverde",
            "Villaviciosa",
            "Villaviciosa",
            "Vintar",
            "Vintar",
            "Vinzons",
            "Virac",
            "Viriato",
            "Viriato",
            "Vista Alegre",
            "Vitali",
            "Vito",
            "Vizal San Pablo",
            "Vizal San Pablo",
            "Vizal Santo Niño",
            "Vizal Santo Niño",
            "Wañgan",
            "Wañgan",
            "Wañgan",
            "Wao",
            "Wao",
            "Wawa",
            "Wawa",
            "Wines",
            "Wines",
            "Wines",
            "Wright",
            "Wright",
            "Yapak",
            "Yeban Norte",
            "Yeban Norte",
            "Yook",
            "Yubo",
            "Yumbing",
            "Yuni",
            "Zamboanga",
            "Zamboanguita",
            "Zaragoza",
            "Zaragoza",
            "Zaragoza",
            "Zarraga",
            "Zumarraga",
            "Zumarraga"
        ]
    },
    {
        "label": "Pitcairn Island",
        "code": "PN",
        "phone": "870",
        "cities": []
    },
    {
        "label": "Poland",
        "code": "PL",
        "phone": "48",
        "cities": [
            "Abramów",
            "Adamów",
            "Adamówka",
            "Albigowa",
            "Aleksandrów",
            "Aleksandrów",
            "Aleksandrów Kujawski",
            "Aleksandrów Łódzki",
            "Alwernia",
            "Andrespol",
            "Andrychów",
            "Annopol",
            "Augustów",
            "Babiak",
            "Babica",
            "Babice",
            "Babimost",
            "Baborów",
            "Baboszewo",
            "Bachowice",
            "Baćkowice",
            "Bąków",
            "Balice",
            "Baligród",
            "Balin",
            "Bałtów",
            "Banie",
            "Banie Mazurskie",
            "Banino",
            "Baniocha",
            "Baranów",
            "Baranów",
            "Baranów Sandomierski",
            "Baranowo",
            "Baranowo",
            "Barciany",
            "Barcin",
            "Barczewo",
            "Bardo",
            "Barlinek",
            "Bartniczka",
            "Bartoszyce",
            "Baruchowo",
            "Barwałd Górny",
            "Barwałd Średni",
            "Barwice",
            "Batorz",
            "Bażanowice",
            "Bębło",
            "Bęczarka",
            "Będków",
            "Bedlno",
            "Będzin",
            "Bejsce",
            "Bełchatów",
            "Bełk",
            "Bełsznica",
            "Bełżec",
            "Bełżyce",
            "Bemowo",
            "Besko",
            "Bestwina",
            "Bestwinka",
            "Biała",
            "Biała",
            "Biała Piska",
            "Biała Podlaska",
            "Biała Rawska",
            "Białaczów",
            "Białe Błota",
            "Białka",
            "Białka Tatrzańska",
            "Białobrzegi",
            "Białobrzegi",
            "Białogard",
            "Białołeka",
            "Białośliwie",
            "Białowieża",
            "Biały Bór",
            "Biały Dunajec",
            "Białystok",
            "Biecz",
            "Bielany",
            "Bielany Wrocławskie",
            "Bielawa",
            "Bielawy",
            "Bielice",
            "Bieliny",
            "Bieliny",
            "Bielsk",
            "Bielsk Podlaski",
            "Bielsko-Biala",
            "Bielsko-Biała",
            "Bieniewice",
            "Bieńkówka",
            "Bierawa",
            "Bierdzany",
            "Bieruń",
            "Bierutów",
            "Bierzwnik",
            "Bieżuń",
            "Biłgoraj",
            "Binarowa",
            "Bircza",
            "Biskupice Radłowskie",
            "Biskupiec",
            "Biskupin",
            "Biszcza",
            "Bisztynek",
            "Blachownia",
            "Bledzew",
            "Blizanów",
            "Blizne",
            "Bliżyn",
            "Błaszki",
            "Błażowa",
            "Błędów",
            "Błonie",
            "Bobolice",
            "Bobowa",
            "Bobowo",
            "Bobrowice",
            "Bobrowniki",
            "Bobrowniki",
            "Bobrowniki",
            "Bobrowo",
            "Bochnia",
            "Boćki",
            "Bodzanów",
            "Bodzentyn",
            "Bogatynia",
            "Bogdaniec",
            "Bogoria",
            "Boguchwała",
            "Boguszów-Gorce",
            "Boguty-Pianki",
            "Bojadła",
            "Bojano",
            "Bojanów",
            "Bojanowo",
            "Bojanowo Stare",
            "Bojszowy",
            "Bojszowy Nowe",
            "Bolechowice",
            "Bolęcin",
            "Bolesław",
            "Bolesławiec",
            "Bolesławiec",
            "Boleszkowice",
            "Bolimów",
            "Bolków",
            "Bolszewo",
            "Boniewo",
            "Borek",
            "Borek Wielkopolski",
            "Borki",
            "Borkowice",
            "Borne Sulinowo",
            "Boronów",
            "Borowa",
            "Borowie",
            "Borowno",
            "Borucin",
            "Borzechów",
            "Borzęcin",
            "Borzęta",
            "Borzytuchom",
            "Bozkow",
            "Bralin",
            "Branice",
            "Braniewo",
            "Brańsk",
            "Brańszczyk",
            "Brąszewice",
            "Brdów",
            "Brenna",
            "Brenno",
            "Brochów",
            "Brodła",
            "Brodnica",
            "Brodnica",
            "Brody",
            "Brody",
            "Brody",
            "Brody-Parcele",
            "Brojce",
            "Brójce",
            "Brójce",
            "Brok",
            "Broniszewice",
            "Bronów",
            "Brudzeń Duży",
            "Brudzew",
            "Brusy",
            "Brwinów",
            "Brzączowice",
            "Brzeg",
            "Brzeg Dolny",
            "Brześć Kujawski",
            "Brzesko",
            "Brzeszcze",
            "Brzezinka",
            "Brzeziny",
            "Brzeziny",
            "Brzeziny Śląskie",
            "Brzeźnica",
            "Brzeźnica",
            "Brzeźnio",
            "Brzostek",
            "Brzóza Królewska",
            "Brzóza Stadnicka",
            "Brzozie",
            "Brzozów",
            "Brzuze",
            "Brzyska",
            "Buczek",
            "Buczkowice",
            "Budziszewice",
            "Budzów",
            "Budzów",
            "Budzyń",
            "Bujaków",
            "Buk",
            "Bukowiec",
            "Bukowina Tatrzańska",
            "Bukowno",
            "Bukowsko",
            "Bulkowo",
            "Bulowice",
            "Burzenin",
            "Busko-Zdrój",
            "Bychawa",
            "Byczyna",
            "Bydgoszcz",
            "Bystra",
            "Bystra",
            "Bystrzyca",
            "Bystrzyca Kłodzka",
            "Bytnica",
            "Bytom",
            "Bytom Odrzański",
            "Bytoń",
            "Bytów",
            "Cedry Wielkie",
            "Cedynia",
            "Cegłów",
            "Cekcyn",
            "Celestynów",
            "Ceranów",
            "Cerkwica",
            "Cewice",
            "Chałupki",
            "Chąśno",
            "Chęciny",
            "Chełm",
            "Chełm Śląski",
            "Chełmek",
            "Chełmiec",
            "Chełmno",
            "Chełmża",
            "Chlewiska",
            "Chłapowo",
            "Chmielnik",
            "Chmielnik",
            "Chmielno",
            "Chmielowice",
            "Choceń",
            "Chocianów",
            "Chociwel",
            "Chocz",
            "Choczewo",
            "Chocznia",
            "Chodecz",
            "Chodel",
            "Chodów",
            "Chodzież",
            "Chojna",
            "Chojnice",
            "Chojnów",
            "Chorkówka",
            "Choroń",
            "Choroszcz",
            "Chorzele",
            "Chorzów",
            "Choszczno",
            "Chotcza",
            "Chróścice",
            "Chrościna",
            "Chrostkowo",
            "Chruszczobród",
            "Chrzanów",
            "Chrzanów",
            "Chrząstowice",
            "Chrzypsko Wielkie",
            "Chwałowice",
            "Chwaszczyno",
            "Chybie",
            "Chynów",
            "Chyżne",
            "Ciasna",
            "Ciechanów",
            "Ciechanowiec",
            "Ciechocin",
            "Ciechocinek",
            "Ciechów",
            "Cięcina",
            "Cielądz",
            "Cielmice",
            "Ciepielów",
            "Cieplice Śląskie Zdrój",
            "Ciepłowody",
            "Cieszanów",
            "Cieszków",
            "Cieszyn",
            "Ciężkowice",
            "Cisek",
            "Cisiec",
            "Cisna",
            "Cisownica",
            "Ćmielów",
            "Cmolas",
            "Cybinka",
            "Cyców",
            "Cynków",
            "Czajków",
            "Czaniec",
            "Czaplinek",
            "Czapury",
            "Czarków",
            "Czarna",
            "Czarna Białostocka",
            "Czarna Dąbrówka",
            "Czarna Góra",
            "Czarna Woda",
            "Czarne",
            "Czarnia",
            "Czarnków",
            "Czarnochowice",
            "Czarnocin",
            "Czarnocin",
            "Czarnowąsy",
            "Czarnożyły",
            "Czarny Bór",
            "Czarny Dunajec",
            "Czastary",
            "Czaszyn",
            "Czchów",
            "Czechowice-Dziedzice",
            "Czeladź",
            "Czemierniki",
            "Czempiń",
            "Czeremcha",
            "Czermin",
            "Czermin",
            "Czernica",
            "Czernica",
            "Czernice Borowe",
            "Czernichów",
            "Czernichów",
            "Czerniejewo",
            "Czerniewice",
            "Czerniewice",
            "Czernikowo",
            "Czersk",
            "Czerwieńsk",
            "Czerwin",
            "Czerwińsk Nad Wisłą",
            "Czerwionka-Leszczyny",
            "Czerwonak",
            "Czerwonka",
            "Czerwonka",
            "Częstochowa",
            "Człopa",
            "Człuchów",
            "Czudec",
            "Czułów",
            "Czyżew",
            "Czyżowice",
            "Dąbie",
            "Dąbie",
            "Dąbie",
            "Dąbrowa",
            "Dąbrowa",
            "Dąbrowa Białostocka",
            "Dąbrowa Chełmińska",
            "Dąbrowa Górnicza",
            "Dąbrowa Tarnowska",
            "Dąbrowa Zielona",
            "Dąbrowice",
            "Dąbrówka",
            "Dąbrówno",
            "Dalachów",
            "Daleszyce",
            "Dalików",
            "Damasławek",
            "Damnica",
            "Darłowo",
            "Daszewice",
            "Daszyna",
            "Dębe Wielkie",
            "Dębica",
            "Dęblin",
            "Dębnica Kaszubska",
            "Dębno",
            "Dębno",
            "Dębno",
            "Dębów",
            "Dębowa Kłoda",
            "Dębowa Łąka",
            "Dębowiec",
            "Dębowiec",
            "Debrzno",
            "Deszczno",
            "Długie",
            "Długołęka",
            "Długomiłowice",
            "Długosiodło",
            "Dłutów",
            "Dmosin",
            "Dobczyce",
            "Dobiegniew",
            "Dobieszowice",
            "Dobra",
            "Dobra",
            "Dobra",
            "Dobre",
            "Dobre",
            "Dobre Miasto",
            "Dobrodzień",
            "Dobroń",
            "Dobroszyce",
            "Dobrzany",
            "Dobrzeń Wielki",
            "Dobrzyca",
            "Dobrzyń nad Wisłą",
            "Dobrzyniewo Duże",
            "Dolice",
            "Dolsk",
            "Dołhobyczów",
            "Domanice",
            "Domaniewice",
            "Domaradz",
            "Domaszowice",
            "Domaszowice",
            "Dominowo",
            "Dopiewo",
            "Dorohusk",
            "Doruchów",
            "Drawno",
            "Drawsko",
            "Drawsko Pomorskie",
            "Drelów",
            "Drezdenko",
            "Drobin",
            "Drogomyśl",
            "Drohiczyn",
            "Drużbice",
            "Drzewica",
            "Drzycim",
            "Dubiecko",
            "Dubienka",
            "Duczki",
            "Dukla",
            "Duszniki",
            "Duszniki-Zdrój",
            "Dwikozy",
            "Dydnia",
            "Dygowo",
            "Dynów",
            "Dywity",
            "Dziadowa Kłoda",
            "Działdowo",
            "Działoszyce",
            "Działoszyn",
            "Dziećmorowice",
            "Dzięgielów",
            "Dziekanów Leśny",
            "Dziemiany",
            "Dziergowice",
            "Dzierzążnia",
            "Dzierzgoń",
            "Dzierzgowo",
            "Dzierżoniów",
            "Dziewin",
            "Dzików Stary",
            "Dzikowiec",
            "Dziwnów",
            "Dźwierzuty",
            "Dzwola",
            "Elbląg",
            "Ełk",
            "Fabianki",
            "Fajsławice",
            "Fałków",
            "Filipów",
            "Filipowice",
            "Firlej",
            "Fordon",
            "Frampol",
            "Fredropol",
            "Frombork",
            "Frydek",
            "Frydman",
            "Frydrychowice",
            "Frysztak",
            "Gąbin",
            "Gać",
            "Gądów Mały",
            "Galewice",
            "Gałków Mały",
            "Garbatka-Letnisko",
            "Garbów",
            "Garcz",
            "Gardawice",
            "Gardeja",
            "Garwolin",
            "Gąsawa",
            "Gąsocin",
            "Gaszowice",
            "Gaworzyce",
            "Gdańsk",
            "Gdów",
            "Gdynia",
            "Gidle",
            "Giebułtów",
            "Giedlarowa",
            "Gielniów",
            "Gierałtowice",
            "Gierałtowice",
            "Gierłoż",
            "Gierzwałd",
            "Gietrzwałd",
            "Gilowice",
            "Gizałki",
            "Giżycko",
            "Glinojeck",
            "Gliwice",
            "Głogoczów",
            "Głogów",
            "Głogów Małopolski",
            "Głogówek",
            "Główczyce",
            "Głowno",
            "Głubczyce",
            "Głuchołazy",
            "Głuchów",
            "Głuchów",
            "Głuszyca",
            "Głuszyca Górna",
            "Gniazdów",
            "Gniechowice",
            "Gniew",
            "Gniewino",
            "Gniewkowo",
            "Gnieżdżewo",
            "Gniezno",
            "Gnojnik",
            "Gnojno",
            "Goczałkowice Zdrój",
            "Godów",
            "Godzianów",
            "Godziszka",
            "Godziszów",
            "Godziszów Pierwszy",
            "Gogolin",
            "Golcowa",
            "Golczewo",
            "Goleniów",
            "Goleszów",
            "Golina",
            "Golub-Dobrzyń",
            "Gołańcz",
            "Gołdap",
            "Gołkowice",
            "Gołuchów",
            "Gołymin-Ośrodek",
            "Gomunice",
            "Goniadz",
            "Góra",
            "Góra",
            "Góra Kalwaria",
            "Góra Świętej Małgorzaty",
            "Goraj",
            "Górażdże",
            "Gorenice",
            "Górki",
            "Górki Wielkie",
            "Gorlice",
            "Gorliczyna",
            "Górno",
            "Górno",
            "Górowo Iławeckie",
            "Gorzków",
            "Gorzkowice",
            "Górzno",
            "Górzno",
            "Gorzów Śląski",
            "Gorzów Wielkopolski",
            "Górzyca",
            "Gorzyce",
            "Gorzyce",
            "Gorzyce Wielkie",
            "Gorzyczki",
            "Gościcino",
            "Gościeradów",
            "Gościno",
            "Gostycyn",
            "Gostyń",
            "Gostyń",
            "Gostynin",
            "Goszczanów",
            "Goszczyn",
            "Gowarczów",
            "Goworowo",
            "Gózd",
            "Gozdnica",
            "Gozdowo",
            "Grabica",
            "Grabiszyn",
            "Grabiszynek",
            "Grabów",
            "Grabów nad Prosną",
            "Grabowiec",
            "Grabownica Starzeńska",
            "Grabowo Kościerskie",
            "Grajewo",
            "Granica",
            "Granowo",
            "Grębków",
            "Grębocice",
            "Gręboszów",
            "Grębów",
            "Grodków",
            "Grodziec",
            "Grodzisk Mazowiecki",
            "Grodzisk Wielkopolski",
            "Grodzisko Dolne",
            "Grodzisko Górne",
            "Grojec",
            "Grójec",
            "Gromadka",
            "Gromnik",
            "Gronowo Elbląskie",
            "Gronowo Górne",
            "Grudki",
            "Grudusk",
            "Grudziądz",
            "Gruszów Wielki",
            "Gruta",
            "Grybów",
            "Gryfice",
            "Gryfino",
            "Gryfów Śląski",
            "Grzechynia",
            "Grzegorzew",
            "Grzęska",
            "Grzmiąca",
            "Grzybowa Góra",
            "Grzybowo",
            "Gubin",
            "Guzów",
            "Gwoźnica Górna",
            "Gzy",
            "Haczów",
            "Hajnówka",
            "Halinów",
            "Handzlówka",
            "Hańsk",
            "Harasiuki",
            "Harbutowice",
            "Hażlach",
            "Hel",
            "Henryków",
            "Herby",
            "Hornówek",
            "Horodło",
            "Horyniec-Zdrój",
            "Hrubieszów",
            "Humniska",
            "Husów",
            "Huta Stara B",
            "Hyżne",
            "Iława",
            "Iłów",
            "Iłowa",
            "Iłowo -Osada",
            "Iłża",
            "Imielin",
            "Imielno",
            "Inowłódz",
            "Inowrocław",
            "Ińsko",
            "Inwałd",
            "Istebna",
            "Iwaniska",
            "Iwierzyce",
            "Iwkowa",
            "Iwonicz-Zdrój",
            "Izabelin",
            "Izbica",
            "Izbica Kujawska",
            "Izbicko",
            "Izdebnik",
            "Jabłoń",
            "Jabłonica Polska",
            "Jabłonka",
            "Jabłonka",
            "Jabłonna",
            "Jabłonna",
            "Jabłonna",
            "Jabłonna Lacka",
            "Jabłonowo Pomorskie",
            "Jadów",
            "Jadowniki",
            "Jadowniki Mokre",
            "Jagiełła",
            "Jakubów",
            "Jakubowice Murowane",
            "Janikowo",
            "Janków Przygodzki",
            "Jankowice",
            "Jankowice Rybnickie",
            "Janów Lubelski",
            "Janów Podlaski",
            "Janowice",
            "Janowice",
            "Janowice Wielkie",
            "Janowiec",
            "Janowiec Wielkopolski",
            "Januszkowice",
            "Jaraczewo",
            "Jarczów",
            "Jarnołtów-Jerzmanowo",
            "Jarocin",
            "Jarocin",
            "Jarosław",
            "Jaroszowice",
            "Jasień",
            "Jasień",
            "Jasienica",
            "Jasienica",
            "Jasienica",
            "Jasienica Rosielna",
            "Jasieniec",
            "Jasionów",
            "Jaśliska",
            "Jasło",
            "Jastarnia",
            "Jastków",
            "Jastrowie",
            "Jastrząb",
            "Jastrząb",
            "Jastrzębia",
            "Jastrzębia",
            "Jastrzębia Góra",
            "Jastrzębie Zdrój",
            "Jastrzębie-Zdrój",
            "Jawor",
            "Jawornik",
            "Jawornik",
            "Jawornik Polski",
            "Jaworze",
            "Jaworzno",
            "Jaworzyna Śląska",
            "Jaworzynka",
            "Jedlicze",
            "Jedlina-Zdrój",
            "Jedlińsk",
            "Jedlnia-Letnisko",
            "Jednorożec",
            "Jędrzejów",
            "Jedwabne",
            "Jedwabno",
            "Jejkowice",
            "Jelcz",
            "Jelcz Laskowice",
            "Jelenia Góra",
            "Jeleśnia",
            "Jemielnica",
            "Jenin",
            "Jerzmanowice",
            "Jeżewo",
            "Jeziora Wielkie",
            "Jeziorany",
            "Jeziorzany",
            "Jeżów",
            "Jeżów Sudecki",
            "Jeżowe",
            "Jodłowa",
            "Jodłówka",
            "Jodłówka",
            "Jodłówka-Wałki",
            "Joniec",
            "Jonkowo",
            "Jordanów",
            "Jordanów Śląski",
            "Józefosław",
            "Józefów",
            "Józefów",
            "Józefów nad Wisłą",
            "Jugów",
            "Juszczyn",
            "Juszczyna",
            "Jutrosin",
            "Kabaty",
            "Kaczory",
            "Kaczyce",
            "Kadzidło",
            "Kalej",
            "Kalety",
            "Kaliska",
            "Kalisz",
            "Kalisz Pomorski",
            "Kalwaria Zebrzydowska",
            "Kałuszyn",
            "Kamesznica",
            "Kamień",
            "Kamień",
            "Kamień Krajeński",
            "Kamień Pomorski",
            "Kamienica",
            "Kamienica",
            "Kamienica Polska",
            "Kamieniec",
            "Kamieniec Wrocławski",
            "Kamieniec Ząbkowicki",
            "Kamienna Góra",
            "Kamiennik",
            "Kamieńsk",
            "Kamionka",
            "Kamionka Wielka",
            "Kampinos",
            "Kamyk",
            "Kańczuga",
            "Kaniów",
            "Kaniów",
            "Karczew",
            "Karczmiska",
            "Kargowa",
            "Karlino",
            "Karłowice",
            "Karnice",
            "Karniewo",
            "Karpacz",
            "Karsin",
            "Kartuzy",
            "Kasina Wielka",
            "Kaszów",
            "Katowice",
            "Kąty Wrocławskie",
            "Kawęczyn",
            "Kawęczyn Nowy",
            "Kazanów",
            "Kazimierz Biskupi",
            "Kazimierz Dolny",
            "Kazimierza Wielka",
            "Kaźmierz",
            "Kcynia",
            "Kędzierzyn-Koźle",
            "Kępice",
            "Kępie Żaleszańskie",
            "Kępno",
            "Kęsowo",
            "Kętrzyn",
            "Kęty",
            "Kielanówka",
            "Kielce",
            "Kiełczów",
            "Kiełpin",
            "Kiernozia",
            "Kietrz",
            "Kije",
            "Kikół",
            "Kisielice",
            "Kiszkowo",
            "Klecza Dolna",
            "Kleczew",
            "Klembów",
            "Klenica",
            "Kleszczele",
            "Kleszczewo",
            "Kleszczów",
            "Klikuszowa",
            "Klimontów",
            "Klimontów",
            "Klonowa",
            "Kluczbork",
            "Klucze",
            "Kluki",
            "Kluszkowce",
            "Klwów",
            "Kłaj",
            "Kłecko",
            "Kłobuck",
            "Kłoczew",
            "Kłodawa",
            "Kłodawa",
            "Kłodzko",
            "Kłomnice",
            "Knurów",
            "Knyszyn",
            "Kobiernice",
            "Kobierzyce",
            "Kobiór",
            "Kobyla Góra",
            "Kobylanka",
            "Kobylanka",
            "Kobylin",
            "Kobylnica",
            "Kobylnica",
            "Kobyłka",
            "Kochanowice",
            "Kocierzew Południowy",
            "Kock",
            "Koczała",
            "Kodeń",
            "Kokotów",
            "Kolbudy",
            "Kolbuszowa",
            "Koleczkowo",
            "Kolno",
            "Kolonowskie",
            "Kolsko",
            "Koluszki",
            "Kołaczkowo",
            "Kołaczyce",
            "Kołbaskowo",
            "Kołbiel",
            "Kołczygłowy",
            "Koło",
            "Kołobrzeg",
            "Komańcza",
            "Komarów-Osada",
            "Komarówka Podlaska",
            "Komorniki",
            "Komorów",
            "Komprachcice",
            "Konary",
            "Kończyce",
            "Kończyce Małe",
            "Kończyce Wielkie",
            "Koniaków",
            "Koniecpol",
            "Konieczkowa",
            "Konin",
            "Konopiska",
            "Konotop",
            "Końskie",
            "Końskowola",
            "Konstancin-Jeziorna",
            "Konstantynów",
            "Konstantynów Łódzki",
            "Koprzywnica",
            "Korbielów",
            "Korczew",
            "Korczyna",
            "Korfantów",
            "Kórnik",
            "Kornowac",
            "Koronowo",
            "Korsze",
            "Kortowo",
            "Korytnica",
            "Korzenna",
            "Kosakowo",
            "Kościan",
            "Kościelec",
            "Kościelec",
            "Kościelisko",
            "Kościerzyna",
            "Kosina",
            "Kosów Lacki",
            "Kostomłoty Drugie",
            "Kostomłoty Pierwsze",
            "Kostrzyn",
            "Kostrzyn nad Odrą",
            "Koszalin",
            "Koszarawa",
            "Koszęcin",
            "Koszyce",
            "Koszyce Wielkie",
            "Kotla",
            "Kotlin",
            "Kotuń",
            "Kowal",
            "Kowala",
            "Kowale",
            "Kowale Oleckie",
            "Kowale-Popiele",
            "Kowalewo Pomorskie",
            "Kowary",
            "Kowiesy",
            "Kozanów",
            "Koziegłowy",
            "Kozienice",
            "Koźle",
            "Kozłów",
            "Kozłowo",
            "Koźmin Wielkopolski",
            "Koźminek",
            "Kozubszczyzna",
            "Kożuchów",
            "Kozy",
            "Kraczkowa",
            "Krajenka",
            "Kraków",
            "Kramarzówka",
            "Kramsk",
            "Krapkowice",
            "Krasiczyn",
            "Krasne",
            "Krasne",
            "Kraśniczyn",
            "Kraśnik",
            "Krasnobród",
            "Krasnopol",
            "Krasnosielc",
            "Krasnystaw",
            "Krasocin",
            "Kraszewice",
            "Krempna",
            "Krobia",
            "Kroczyce",
            "Krokowa",
            "Krościenko nad Dunajcem",
            "Krościenko Wyżne",
            "Krośnica",
            "Krośnice",
            "Krośniewice",
            "Krosno",
            "Krosno",
            "Krosno Odrzańskie",
            "Krotoszyn",
            "Kruklanki",
            "Krupski Młyn",
            "Kruszwica",
            "Krynica Morska",
            "Krynica-Zdrój",
            "Krynice",
            "Krynki",
            "Kryry",
            "Kryspinów",
            "Krzanowice",
            "Krzczonów",
            "Krzczonów",
            "Krzęcin",
            "Krzęcin",
            "Krzeczów",
            "Krzeczowice",
            "Krzemienica",
            "Krzemieniewo",
            "Krzepice",
            "Krzeszów",
            "Krzeszów",
            "Krzeszów",
            "Krzeszowice",
            "Krzeszyce",
            "Krzyki",
            "Krzykosy",
            "Krzynowłoga Mała",
            "Krzyszkowice",
            "Krzywaczka",
            "Krzywcza",
            "Krzywda",
            "Krzywiń",
            "Krzyż Wielkopolski",
            "Krzyżanów",
            "Krzyżanowice",
            "Krzyżowa",
            "Krzyżowice",
            "Ksawerów",
            "Książ Wielkopolski",
            "Książenice",
            "Księże Małe-Księże Wielkie",
            "Księżomierz",
            "Księżpol",
            "Kuczbork-Osada",
            "Kudowa-Zdrój",
            "Kuków",
            "Kunice",
            "Kunice Żarskie",
            "Kunów",
            "Kup",
            "Kurów",
            "Kuryłówka",
            "Kurzętnik",
            "Kuślin",
            "Kutno",
            "Kuźnia Raciborska",
            "Kuźnica",
            "Kwaczała",
            "Kwidzyn",
            "Kwilcz",
            "Lachowice",
            "Lądek",
            "Lądek-Zdrój",
            "Laliki",
            "Lanckorona",
            "Lasek",
            "Laskowa",
            "Laszki",
            "Latowicz",
            "Lębork",
            "Lecka",
            "Lędziny",
            "Legionowo",
            "Legnica",
            "Legnickie Pole",
            "Lelkowo",
            "Lelów",
            "Leńcze",
            "Lesko",
            "Leśna",
            "Leśna",
            "Leśna Podlaska",
            "Leśnica",
            "Leśnica",
            "Leśnica-Ratyń-Pustki",
            "Leszno",
            "Leszno",
            "Lesznowola",
            "Lewin Brzeski",
            "Lewin Kłodzki",
            "Leżajsk",
            "Lgota Wielka",
            "Libertów",
            "Libiąż",
            "Licheń Stary",
            "Lidzbark",
            "Lidzbark Warmiński",
            "Ligota",
            "Limanowa",
            "Linia",
            "Liniewo",
            "Lipce Reymontowskie",
            "Lipiany",
            "Lipie",
            "Lipinki",
            "Lipinki Łużyckie",
            "Lipka",
            "Lipnica",
            "Lipnica",
            "Lipnica Mała",
            "Lipnica Wielka",
            "Lipnik",
            "Lipnik",
            "Lipno",
            "Lipno",
            "Lipowa",
            "Lipowiec Kościelny",
            "Lipsk",
            "Lipsko",
            "Lipusz",
            "Lisewo",
            "Lisewo Malborskie",
            "Lisia Góra",
            "Lisków",
            "Liszki",
            "Lniano",
            "Lubaczów",
            "Lubań",
            "Lubanie",
            "Lubartów",
            "Lubasz",
            "Lubawa",
            "Lubawka",
            "Lubenia",
            "Lubichowo",
            "Lubicz Dolny",
            "Lubicz Górny",
            "Lubień",
            "Lubień Kujawski",
            "Lubiewo",
            "Lubin",
            "Lubiszyn",
            "Lublewo Gdańskie",
            "Lublin",
            "Lubliniec",
            "Lubniewice",
            "Lubochnia",
            "Lubomia",
            "Lubomierz",
            "Lubomierz",
            "Lubomino",
            "Luboń",
            "Lubowidz",
            "Lubraniec",
            "Lubrza",
            "Lubrza",
            "Lubsko",
            "Lubsza",
            "Lubycza Królewska",
            "Ludwikowice Kłodzkie",
            "Ludwin",
            "Ludźmierz",
            "Luszowice",
            "Lutocin",
            "Lutomiersk",
            "Lutoryż",
            "Lutowiska",
            "Lututów",
            "Luzino",
            "Lwówek",
            "Lwówek Śląski",
            "Lyski",
            "Łabiszyn",
            "Łabowa",
            "Łabunie",
            "Łąck",
            "Łącko",
            "Łączany",
            "Łączna",
            "Łagiewniki",
            "Łagów",
            "Łagów",
            "Łagów",
            "Łajski",
            "Łąka Prudnicka",
            "Łambinowice",
            "Łańcut",
            "Łanięta",
            "Łapanów",
            "Łapczyca",
            "Łapsze Niżne",
            "Łapy",
            "Łasin",
            "Łask",
            "Łaskarzew",
            "Łaszczów",
            "Łaziska",
            "Łaziska",
            "Łaziska Górne",
            "Łazy",
            "Łeba",
            "Łęczna",
            "Łęczyca",
            "Łęczyce",
            "Łęgowo",
            "Łęka Opatowska",
            "Łękawica",
            "Łęki",
            "Łęki Dolne",
            "Łęki Górne",
            "Łęki Szlacheckie",
            "Łęknica",
            "Łętownia",
            "Łętownia",
            "Łobez",
            "Łobodno",
            "Łobżenica",
            "Łochów",
            "Łodygowice",
            "Łódź",
            "Łomazy",
            "Łomianki",
            "Łomża",
            "Łoniów",
            "Łopiennik Górny",
            "Łopuszka Wielka",
            "Łopuszna",
            "Łopuszno",
            "Łosice",
            "Łosiów",
            "Łososina Dolna",
            "Łowicz",
            "Łubianka",
            "Łubniany",
            "Łubnice",
            "Łubnice",
            "Łubowo",
            "Łubowo",
            "Łuków",
            "Łukowa",
            "Łukowa",
            "Łukowica",
            "Łużna",
            "Łyse",
            "Łysomice",
            "Łyszkowice",
            "Maciejowice",
            "Majdan Królewski",
            "Maków",
            "Maków Mazowiecki",
            "Maków Podhalański",
            "Malanów",
            "Malbork",
            "Malczyce",
            "Malec",
            "Mała Wieś",
            "Małdyty",
            "Małkinia Górna",
            "Małogoszcz",
            "Małomice",
            "Mały Płock",
            "Manasterz",
            "Maniowy",
            "Marciszów",
            "Margonin",
            "Marianowo",
            "Marki",
            "Markłowice",
            "Markowa",
            "Markuszów",
            "Marszowice",
            "Maślice Małe",
            "Maślice Wielkie",
            "Masłów",
            "Maszewo",
            "Maszewo",
            "Maszewo Duże",
            "Maszkienice",
            "Mazańcowice",
            "Mędrzechów",
            "Medyka",
            "Mełgiew",
            "Mętków",
            "Miączyn",
            "Miasteczko Krajeńskie",
            "Miasteczko Śląskie",
            "Miastko",
            "Miastków Kościelny",
            "Michałów",
            "Michałów",
            "Michałów-Reginów",
            "Michałowice",
            "Michałowice",
            "Michałowo",
            "Michów",
            "Miechów",
            "Miechów Charsznica",
            "Miedziana Góra",
            "Miedzichowo",
            "Miedzna",
            "Miedźna",
            "Miedzno",
            "Międzybórz",
            "Międzybrodzie Bialskie",
            "Międzychód",
            "Międzylesie",
            "Międzylesie",
            "Międzyrzec Podlaski",
            "Międzyrzecz",
            "Międzyzdroje",
            "Miejsce Piastowe",
            "Miejska Górka",
            "Miękinia",
            "Miękinia",
            "Mielec",
            "Mieleszyn",
            "Mielno",
            "Mieroszów",
            "Mierzęcice",
            "Mieścisko",
            "Mieszkowice",
            "Miętne",
            "Mikołajki",
            "Mikołajki Pomorskie",
            "Mikołów",
            "Mikstat",
            "Milanów",
            "Milanówek",
            "Milejczyce",
            "Milejów",
            "Milicz",
            "Milówka",
            "Miłakowo",
            "Miłkowice",
            "Miłomłyn",
            "Miłoradz",
            "Miłosław",
            "Mińsk Mazowiecki",
            "Mircze",
            "Mirków",
            "Mirocin",
            "Mirosławiec",
            "Mirsk",
            "Mirzec",
            "Mława",
            "Młodzieszyn",
            "Młynary",
            "Młynarze",
            "Mnich",
            "Mników",
            "Mniów",
            "Mniszków",
            "Mochowo",
            "Modliborzyce",
            "Modlnica",
            "Modlniczka",
            "Mogielnica",
            "Mogilany",
            "Mogilno",
            "Mokobody",
            "Mokotów",
            "Mokrsko",
            "Mońki",
            "Morąg",
            "Morawica",
            "Mordy",
            "Moryń",
            "Mosina",
            "Mosty",
            "Mosty",
            "Moszczanka",
            "Moszczenica",
            "Moszczenica",
            "Mrągowo",
            "Mrocza",
            "Mrozów",
            "Mrozy",
            "Mrzezino",
            "Mrzeżyno",
            "Mstów",
            "Mszana",
            "Mszana Dolna",
            "Mszana Górna",
            "Mszczonów",
            "Murcki",
            "Murów",
            "Murowana Goślina",
            "Muszyna",
            "Mykanów",
            "Mysiadło",
            "Myślachowice",
            "Myślenice",
            "Myślibórz",
            "Mysłakowice",
            "Mysłowice",
            "Myszków",
            "Myszyniec",
            "Nadarzyn",
            "Nagłowice",
            "Nakło",
            "Nakło",
            "Nakło nad Notecią",
            "Nałęczów",
            "Namysłów",
            "Naprawa",
            "Narew",
            "Narol",
            "Naruszewo",
            "Nasielsk",
            "Nawojowa",
            "Nędza",
            "Nekla",
            "Nidek",
            "Nidzica",
            "Niebieszczany",
            "Niebocko",
            "Nieborów",
            "Niebylec",
            "Niechanowo",
            "Niechobrz",
            "Niedobczyce",
            "Niedomice",
            "Niedrzwica Duża",
            "Niedzica",
            "Niedźwiada",
            "Niedźwiada",
            "Niedźwiedź",
            "Niegowonice",
            "Nielisz",
            "Niemce",
            "Niemcza",
            "Niemodlin",
            "Nienadowa",
            "Niepołomice",
            "Nieporęt",
            "Nieszawa",
            "Nisko",
            "Niwiska",
            "Nowa Dęba",
            "Nowa Góra",
            "Nowa Ruda",
            "Nowa Sarzyna",
            "Nowa Słupia",
            "Nowa Sól",
            "Nowa Wieś",
            "Nowa Wieś Lęborska",
            "Nowa Wieś Wielka",
            "Nowe",
            "Nowe Brzesko",
            "Nowe Grocholice",
            "Nowe Lipiny",
            "Nowe Miasteczko",
            "Nowe Miasto",
            "Nowe Miasto Lubawskie",
            "Nowe Miasto nad Pilicą",
            "Nowe Miasto nad Wartą",
            "Nowe Ostrowy",
            "Nowe Sioło",
            "Nowe Skalmierzyce",
            "Nowe Warpno",
            "Nowodwór",
            "Nowogard",
            "Nowogród",
            "Nowogród Bobrzański",
            "Nowogródek Pomorski",
            "Nowogrodziec",
            "Nowosielce",
            "Nowosielce-Gniewosz",
            "Nowy Duninów",
            "Nowy Dwór Gdański",
            "Nowy Dwór Mazowiecki",
            "Nowy Korczyn",
            "Nowy Sącz",
            "Nowy Staw",
            "Nowy Targ",
            "Nowy Tomyśl",
            "Nowy Wiśnicz",
            "Nowy Żmigród",
            "Nozdrzec",
            "Nur",
            "Nurzec-Stacja",
            "Nysa",
            "Oborniki",
            "Oborniki Śląskie",
            "Obrazów",
            "Obrowo",
            "Obryte",
            "Obrzycko",
            "Obsza",
            "Ochaby",
            "Ochla",
            "Ochojno",
            "Ochota",
            "Ochotnica Dolna",
            "Odolanów",
            "Odrzykoń",
            "Odrzywół",
            "Ogrodzieniec",
            "Ojrzeń",
            "Okocim",
            "Okonek",
            "Oksa",
            "Olecko",
            "Oleśnica",
            "Oleśnica",
            "Olesno",
            "Olesno",
            "Oleszyce",
            "Olkusz",
            "Olszana",
            "Olszanica",
            "Olszanica",
            "Olszanka",
            "Olszówka",
            "Olszówka",
            "Olsztyn",
            "Olsztyn",
            "Olsztynek",
            "Olszyna",
            "Olza",
            "Oława",
            "Ołpiny",
            "Opalenica",
            "Opatów",
            "Opatów",
            "Opatów",
            "Opatówek",
            "Opatowiec",
            "Opinogóra Górna",
            "Opoczno",
            "Opole",
            "Opole Lubelskie",
            "Oporów",
            "Orchowo",
            "Orla",
            "Orle",
            "Orły",
            "Orneta",
            "Ornontowice",
            "Orońsko",
            "Orzech",
            "Orzechówka",
            "Orzesze",
            "Orzysz",
            "Osie",
            "Osięciny",
            "Osieck",
            "Osieczany",
            "Osieczna",
            "Osiedle Henrykowskie",
            "Osiedle Kosmonautów",
            "Osiedle-Nowiny",
            "Osiek",
            "Osiek",
            "Osiek",
            "Osiek",
            "Osiek",
            "Osiek Jasielski",
            "Osiek Mały",
            "Osiek nad Notecią",
            "Osielec",
            "Osielsko",
            "Osina",
            "Osjaków",
            "Ośno Lubuskie",
            "Ostaszewo",
            "Ostrężnica",
            "Ostróda",
            "Ostrołęka",
            "Ostroróg",
            "Ostroszowice",
            "Ostrów",
            "Ostrów Lubelski",
            "Ostrów Mazowiecka",
            "Ostrów Wielkopolski",
            "Ostrówek",
            "Ostrówek",
            "Ostrowiec Świętokrzyski",
            "Ostrowite",
            "Ostrowite",
            "Ostrowsko",
            "Ostrowy",
            "Ostrowy nad Okszą",
            "Ostrzeszów",
            "Oświęcim",
            "Otmuchów",
            "Otrębusy",
            "Otwock",
            "Otyń",
            "Owczarnia",
            "Ożarów",
            "Ożarów",
            "Ożarów Mazowiecki",
            "Ożarowice",
            "Ozimek",
            "Ozorków",
            "Pabianice",
            "Pacanów",
            "Pacyna",
            "Paczków",
            "Padew Narodowa",
            "Pajęczno",
            "Pakość",
            "Pakosław",
            "Pakosławice",
            "Palcza",
            "Pamiątkowo",
            "Paniówki",
            "Panki",
            "Pantalowice",
            "Paprotnia",
            "Paradyż",
            "Parchowo",
            "Parczew",
            "Partynice",
            "Parysów",
            "Parzęczew",
            "Pasłęk",
            "Pasym",
            "Paszowice",
            "Pątnów",
            "Pawlikowice",
            "Pawłosiów",
            "Pawłów",
            "Pawłowice",
            "Pawłowice-Kłokoczyce",
            "Pawłowiczki",
            "Pawonków",
            "Pcim",
            "Pecna",
            "Pęczniew",
            "Pelplin",
            "Pełczyce",
            "Pępowo",
            "Perzów",
            "Pewel Ślemieńska",
            "Pewel Wielka",
            "Piaseczno",
            "Piasek",
            "Piaski",
            "Piaski",
            "Piastów",
            "Piątek",
            "Piątnica",
            "Piechowice",
            "Piecki",
            "Piekary",
            "Piekary Śląskie",
            "Piekielnik",
            "Piekoszów",
            "Pielgrzymowice",
            "Pieniężno",
            "Pieńsk",
            "Pierściec",
            "Pierzchnica",
            "Pieszyce",
            "Pietrowice Wielkie",
            "Pietrzykowice",
            "Pilawa",
            "Pilchowice",
            "Pilczyce",
            "Pilica",
            "Pilzno",
            "Piła",
            "Piława Górna",
            "Pińczów",
            "Pionki",
            "Piotrków Kujawski",
            "Piotrków Trybunalski",
            "Pisarzowice",
            "Pisarzowice",
            "Piskorowice",
            "Pisz",
            "Piszczac",
            "Piwniczna-Zdrój",
            "Pleśna",
            "Pleszew",
            "Plewiska",
            "Pławno",
            "Płaza",
            "Płock",
            "Płoniawy-Bramura",
            "Płońsk",
            "Płoty",
            "Płużnica",
            "Pniewy",
            "Pobiedziska",
            "Pobierowo",
            "Poddębice",
            "Podebłocie",
            "Podedwórze",
            "Podegrodzie",
            "Podgórzyn",
            "Podkowa Leśna",
            "Podłęże",
            "Podwilk",
            "Pogórska Wola",
            "Pogórze",
            "Pogórze",
            "Pogorzela",
            "Pogorzyce",
            "Pogrzebień",
            "Pogwizdów",
            "Pokój",
            "Pokrówka",
            "Pokrzywnica",
            "Polańczyk",
            "Polanica-Zdrój",
            "Polanka Wielka",
            "Polanów",
            "Police",
            "Policzna",
            "Polkowice",
            "Polska Cerekiew",
            "Połajewo",
            "Połaniec",
            "Połczyn-Zdrój",
            "Połomia",
            "Połomia",
            "Pomiechówek",
            "Poniatowa",
            "Poniec",
            "Ponikiew",
            "Popielów",
            "Porąbka",
            "Porąbka Uszewska",
            "Poraj",
            "Poraż",
            "Poręba",
            "Poręba",
            "Poręba Spytkowska",
            "Poręba Wielka",
            "Poronin",
            "Poświętne",
            "Poświętne",
            "Poświętne",
            "Potęgowo",
            "Potok Górny",
            "Potok Wielki",
            "Potok Złoty",
            "Potworów",
            "Powiat aleksandrowski",
            "Powiat augustowski",
            "Powiat bartoszycki",
            "Powiat będziński",
            "Powiat bełchatowski",
            "Powiat bialski",
            "Powiat białobrzeski",
            "Powiat białogardzki",
            "Powiat białostocki",
            "Powiat bielski",
            "Powiat bielski",
            "Powiat bieruńsko-lędziński",
            "Powiat bieszczadzki",
            "Powiat biłgorajski",
            "Powiat bocheński",
            "Powiat bolesławiecki",
            "Powiat braniewski",
            "Powiat brodnicki",
            "Powiat brzeski",
            "Powiat brzeski",
            "Powiat brzeziński",
            "Powiat brzozowski",
            "Powiat buski",
            "Powiat bydgoski",
            "Powiat bytowski",
            "Powiat chełmiński",
            "Powiat chełmski",
            "Powiat chodzieski",
            "Powiat chojnicki",
            "Powiat choszczeński",
            "Powiat chrzanowski",
            "Powiat ciechanowski",
            "Powiat cieszyński",
            "Powiat czarnkowsko-trzcianecki",
            "Powiat częstochowski",
            "Powiat człuchowski",
            "Powiat dąbrowski",
            "Powiat dębicki",
            "Powiat drawski",
            "Powiat działdowski",
            "Powiat dzierżoniowski",
            "Powiat elbląski",
            "Powiat ełcki",
            "Powiat garwoliński",
            "Powiat gdański",
            "Powiat giżycki",
            "Powiat gliwicki",
            "Powiat głogowski",
            "Powiat głubczycki",
            "Powiat gnieźnieński",
            "Powiat goleniowski",
            "Powiat golubsko-dobrzyński",
            "Powiat gołdapski",
            "Powiat gorlicki",
            "Powiat górowski",
            "Powiat gorzowski",
            "Powiat gostyniński",
            "Powiat gostyński",
            "Powiat grajewski",
            "Powiat grodziski",
            "Powiat grodziski",
            "Powiat grójecki",
            "Powiat grudziądzki",
            "Powiat gryficki",
            "Powiat gryfiński",
            "Powiat hajnowski",
            "Powiat hrubieszowski",
            "Powiat iławski",
            "Powiat inowrocławski",
            "Powiat janowski",
            "Powiat jarociński",
            "Powiat jarosławski",
            "Powiat jasielski",
            "Powiat jaworski",
            "Powiat jędrzejowski",
            "Powiat jeleniogórski",
            "Powiat kaliski",
            "Powiat kamiennogórski",
            "Powiat kamieński",
            "Powiat kartuski",
            "Powiat kazimierski",
            "Powiat kędzierzyńsko-kozielski",
            "Powiat kępiński",
            "Powiat kętrzyński",
            "Powiat kielecki",
            "Powiat kluczborski",
            "Powiat kłobucki",
            "Powiat kłodzki",
            "Powiat kolbuszowski",
            "Powiat kolneński",
            "Powiat kolski",
            "Powiat kołobrzeski",
            "Powiat konecki",
            "Powiat koniński",
            "Powiat kościański",
            "Powiat kościerski",
            "Powiat koszaliński",
            "Powiat kozienicki",
            "Powiat krakowski",
            "Powiat krapkowicki",
            "Powiat kraśnicki",
            "Powiat krasnostawski",
            "Powiat krośnieński",
            "Powiat krośnieński",
            "Powiat krotoszyński",
            "Powiat kutnowski",
            "Powiat kwidzyński",
            "Powiat lęborski",
            "Powiat legionowski",
            "Powiat legnicki",
            "Powiat leski",
            "Powiat leszczyński",
            "Powiat leżajski",
            "Powiat lidzbarski",
            "Powiat limanowski",
            "Powiat lipnowski",
            "Powiat lipski",
            "Powiat lubaczowski",
            "Powiat lubański",
            "Powiat lubartowski",
            "Powiat lubelski",
            "Powiat lubiński",
            "Powiat lubliniecki",
            "Powiat lwówecki",
            "Powiat łańcucki",
            "Powiat łaski",
            "Powiat łęczycki",
            "Powiat łęczyński",
            "Powiat łobeski",
            "Powiat łódzki wschodni",
            "Powiat łomżyński",
            "Powiat łosicki",
            "powiat Łowicki",
            "Powiat łukowski",
            "Powiat makowski",
            "Powiat malborski",
            "Powiat miechowski",
            "Powiat międzychodzki",
            "Powiat międzyrzecki",
            "Powiat mielecki",
            "Powiat mikołowski",
            "Powiat milicki",
            "Powiat miński",
            "Powiat mławski",
            "Powiat mogileński",
            "Powiat moniecki",
            "Powiat mrągowski",
            "Powiat myślenicki",
            "Powiat myśliborski",
            "Powiat myszkowski",
            "Powiat nakielski",
            "Powiat namysłowski",
            "Powiat nidzicki",
            "Powiat niżański",
            "Powiat nowodworski",
            "Powiat nowodworski",
            "Powiat nowomiejski",
            "Powiat nowosądecki",
            "Powiat nowosolski",
            "Powiat nowotarski",
            "Powiat nowotomyski",
            "Powiat nyski",
            "Powiat obornicki",
            "Powiat olecki",
            "Powiat oleski",
            "Powiat oleśnicki",
            "Powiat olkuski",
            "Powiat olsztyński",
            "Powiat oławski",
            "Powiat opatowski",
            "Powiat opoczyński",
            "Powiat opolski",
            "Powiat opolski",
            "Powiat ostródzki",
            "Powiat ostrołęcki",
            "Powiat ostrowiecki",
            "Powiat ostrowski",
            "Powiat ostrowski",
            "Powiat ostrzeszowski",
            "Powiat oświęcimski",
            "Powiat otwocki",
            "Powiat pabianicki",
            "Powiat pajęczański",
            "Powiat parczewski",
            "Powiat piaseczyński",
            "Powiat pilski",
            "Powiat pińczowski",
            "Powiat piotrkowski",
            "Powiat piski",
            "Powiat pleszewski",
            "Powiat płocki",
            "Powiat płoński",
            "Powiat poddębicki",
            "Powiat policki",
            "Powiat polkowicki",
            "Powiat poznański",
            "Powiat proszowicki",
            "Powiat prudnicki",
            "Powiat pruszkowski",
            "Powiat przasnyski",
            "Powiat przemyski",
            "Powiat przeworski",
            "Powiat przysuski",
            "Powiat pszczyński",
            "Powiat pucki",
            "Powiat puławski",
            "Powiat pułtuski",
            "Powiat pyrzycki",
            "Powiat raciborski",
            "Powiat radomski",
            "Powiat radomszczański",
            "Powiat radziejowski",
            "Powiat radzyński",
            "Powiat rawicki",
            "Powiat rawski",
            "Powiat ropczycko-sędziszowski",
            "Powiat rybnicki",
            "Powiat rycki",
            "Powiat rypiński",
            "Powiat rzeszowski",
            "Powiat sandomierski",
            "Powiat sanocki",
            "Powiat sejneński",
            "Powiat sępoleński",
            "Powiat siedlecki",
            "Powiat siemiatycki",
            "Powiat sieradzki",
            "Powiat sierpecki",
            "Powiat skarżyski",
            "Powiat skierniewicki",
            "Powiat sławieński",
            "Powiat słubicki",
            "Powiat słupecki",
            "Powiat słupski",
            "Powiat sochaczewski",
            "Powiat sokólski",
            "Powiat sokołowski",
            "Powiat średzki",
            "Powiat średzki",
            "Powiat śremski",
            "Powiat stalowowolski",
            "Powiat starachowicki",
            "Powiat stargardzki",
            "Powiat starogardzki",
            "Powiat staszowski",
            "Powiat strzelecki",
            "Powiat strzelecko-drezdenecki",
            "Powiat strzeliński",
            "Powiat strzyżowski",
            "Powiat sulęciński",
            "Powiat suski",
            "Powiat suwalski",
            "Powiat świdnicki",
            "Powiat świdnicki",
            "Powiat świdwiński",
            "Powiat świebodziński",
            "Powiat świecki",
            "Powiat szamotulski",
            "Powiat szczecinecki",
            "Powiat szczycieński",
            "Powiat sztumski",
            "Powiat szydłowiecki",
            "Powiat tarnobrzeski",
            "Powiat tarnogórski",
            "Powiat tarnowski",
            "Powiat tatrzański",
            "Powiat tczewski",
            "Powiat tomaszowski",
            "Powiat tomaszowski",
            "Powiat toruński",
            "Powiat trzebnicki",
            "Powiat tucholski",
            "Powiat turecki",
            "Powiat wąbrzeski",
            "Powiat wadowicki",
            "Powiat wągrowiecki",
            "Powiat wałbrzyski",
            "Powiat wałecki",
            "Powiat warszawski zachodni",
            "Powiat węgorzewski",
            "Powiat węgrowski",
            "Powiat wejherowski",
            "Powiat wielicki",
            "Powiat wieluński",
            "Powiat wieruszowski",
            "Powiat włocławski",
            "Powiat włodawski",
            "Powiat włoszczowski",
            "Powiat wodzisławski",
            "Powiat wolsztyński",
            "Powiat wołomiński",
            "Powiat wołowski",
            "Powiat wrocławski",
            "Powiat wrzesiński",
            "Powiat wschowski",
            "Powiat wysokomazowiecki",
            "Powiat wyszkowski",
            "Powiat ząbkowicki",
            "Powiat żagański",
            "Powiat zambrowski",
            "Powiat zamojski",
            "Powiat żarski",
            "Powiat zawierciański",
            "Powiat zduńskowolski",
            "Powiat zgierski",
            "Powiat zgorzelecki",
            "Powiat zielonogórski",
            "Powiat złotoryjski",
            "Powiat złotowski",
            "Powiat żniński",
            "Powiat żuromiński",
            "Powiat zwoleński",
            "Powiat żyrardowski",
            "Powiat żywiecki",
            "Powidz",
            "Powstańców Śląskich Wschód",
            "Powstańców Śląskich Zachód-Centrum Południow",
            "Pozezdrze",
            "Poznań",
            "Prabuty",
            "Pracze Odrzańskie-Janówek",
            "Praga Północ",
            "Praga Południe",
            "Praszka",
            "Prażmów",
            "Prochowice",
            "Promna",
            "Prostki",
            "Prószków",
            "Proszowice",
            "Pruchna",
            "Pruchnik",
            "Prudnik",
            "Prusice",
            "Pruszcz",
            "Pruszcz Gdański",
            "Pruszków",
            "Przasnysz",
            "Przechlewo",
            "Przecieszyn",
            "Przeciszów",
            "Przecław",
            "Przecław",
            "Przedbórz",
            "Przedecz",
            "Przedmieście Dubieckie",
            "Przędzel",
            "Przeginia",
            "Przelewice",
            "Przemęt",
            "Przemków",
            "Przemyśl",
            "Przesmyki",
            "Przeworno",
            "Przeworsk",
            "Przewóz",
            "Przodkowo",
            "Przybiernów",
            "Przyborów",
            "Przyborów",
            "Przyborów",
            "Przybyszówka",
            "Przygodzice",
            "Przykona",
            "Przyłęk",
            "Przyrów",
            "Przystajń",
            "Przysucha",
            "Przyszowice",
            "Przytkowice",
            "Przytoczna",
            "Przytoczno",
            "Przytyk",
            "Przywidz",
            "Psary",
            "Psary",
            "Psary",
            "Psie Pole Południe-Kiełczów",
            "Pszczew",
            "Pszczółki",
            "Pszczyna",
            "Pszów",
            "Puchaczów",
            "Puck",
            "Puławy",
            "Pułtusk",
            "Puńców",
            "Puńsk",
            "Purda",
            "Puszcza Mariańska",
            "Puszczykowo",
            "Pyrzyce",
            "Pyskowice",
            "Pysznica",
            "Pyzdry",
            "Raba Wyżna",
            "Rąbino",
            "Rabka-Zdrój",
            "Rachanie",
            "Raciąż",
            "Raciążek",
            "Racibórz",
            "Racławice",
            "Racławice",
            "Racławice Śląskie",
            "Raczki",
            "Rączna",
            "Radecznica",
            "Radgoszcz",
            "Radków",
            "Radlin",
            "Radłów",
            "Radocza",
            "Radom",
            "Radomin",
            "Radomsko",
            "Radomyśl",
            "Radomyśl Wielki",
            "Radostowice",
            "Radoszyce",
            "Radowo Małe",
            "Radwanice",
            "Radymno",
            "Radzanów",
            "Radzanowo",
            "Radziechowy",
            "Radziejów",
            "Radziejowice",
            "Radziłów",
            "Radzionków",
            "Radziszów",
            "Radzymin",
            "Radzyń Chełmiński",
            "Radzyń Podlaski",
            "Rajcza",
            "Rajgród",
            "Rajsko",
            "Rakoniewice",
            "Raków",
            "Rakszawa",
            "Raniżów",
            "Raszczyce",
            "Raszków",
            "Raszowa",
            "Raszyn",
            "Ratowice",
            "Rawa Mazowiecka",
            "Rawicz",
            "Recz",
            "Ręczno",
            "Reda",
            "Rędziny",
            "Regimin",
            "Regnów",
            "Regulice",
            "Rejon alei Kromera",
            "Rejon placu Grunwaldzkiego",
            "Rejon placu Świętego Macieja",
            "Rejon ulicy Borowskiej-Południe",
            "Rejon ulicy Klęczkowskiej",
            "Rejon ulicy Mieleckiej",
            "Rejon ulicy Saperów",
            "Rejon ulicy Traugutta",
            "Rejowiec",
            "Rejowiec Fabryczny",
            "Rekowo Dolne",
            "Rembertów",
            "Reńska Wieś",
            "Repki",
            "Resko",
            "Reszel",
            "Rewal",
            "Roczyny",
            "Rogalinek",
            "Rogów",
            "Rogów",
            "Rogów",
            "Rogowo",
            "Rogoźnik",
            "Rogoźno",
            "Rogóźno",
            "Rogóźno",
            "Rogóźno",
            "Rokiciny",
            "Rokiciny",
            "Rokiciny-Kolonia",
            "Rokietnica",
            "Rokietnica",
            "Rokitno",
            "Rokitno Szlacheckie",
            "Ropa",
            "Ropczyce",
            "Rościszewo",
            "Rotmanka",
            "Różan",
            "Różanka-Polanka",
            "Rozbórz",
            "Rozdrażew",
            "Rozogi",
            "Rozprza",
            "Rozwadza",
            "Ruciane-Nida",
            "Ruda Maleniecka",
            "Ruda Śląska",
            "Ruda-Huta",
            "Rudawa",
            "Rudka",
            "Rudna",
            "Rudna Mała",
            "Rudna Wielka",
            "Rudnik",
            "Rudnik",
            "Rudnik",
            "Rudnik nad Sanem",
            "Rudniki",
            "Rudy",
            "Rudziczka",
            "Rudziniec",
            "Rumia",
            "Rusiec",
            "Rusinów",
            "Rusocice",
            "Rutki-Kossaki",
            "Rybarzowice",
            "Rybczewice",
            "Rybie",
            "Rybnik",
            "Rybno",
            "Rybno",
            "Rycerka Dolna",
            "Rycerka Górna",
            "Rychtal",
            "Rychwał",
            "Ryczów",
            "Ryczywół",
            "Ryczywół",
            "Rydułtowy",
            "Rydzyna",
            "Ryglice",
            "Ryjewo",
            "Ryki",
            "Rymań",
            "Rymanów",
            "Ryn",
            "Rypin",
            "Rytro",
            "Rytwiany",
            "Rząska",
            "Rząśnik",
            "Rzeczenica",
            "Rzeczniów",
            "Rzeczyca",
            "Rzeczyca",
            "Rzekuń",
            "Rzepedź",
            "Rzepiennik Strzyżewski",
            "Rzepin",
            "Rzeszotary",
            "Rzeszów",
            "Rzewnie",
            "Rzezawa",
            "Rzgów",
            "Rzgów Pierwszy",
            "Rzozów",
            "Rzyki",
            "Sabnie",
            "Sączów",
            "Sadki",
            "Sadkowice",
            "Sadlinki",
            "Sadowie",
            "Sadowne",
            "Samborzec",
            "Sandomierz",
            "Sanka",
            "Sanniki",
            "Sanok",
            "Santok",
            "Sarnaki",
            "Sarnów",
            "Sąspów",
            "Sawin",
            "Ścinawa",
            "Secemin",
            "Sędziejowice",
            "Sędziszów",
            "Sędziszów Małopolski",
            "Sejny",
            "Sękowa",
            "Sępolno",
            "Sępólno Krajeńskie",
            "Sępopol",
            "Serniki",
            "Serock",
            "Serokomla",
            "Sianów",
            "Sicienko",
            "Sidzina",
            "Sidzina",
            "Siechnice",
            "Siedlce",
            "Siedlec",
            "Siedleczka",
            "Siedliska",
            "Siedliska",
            "Siedlisko",
            "Siekierczyn",
            "Siemianowice Śląskie",
            "Siemiątkowo",
            "Siemiatycze",
            "Siemiechów",
            "Siemień",
            "Siemkowice",
            "Sieniawa",
            "Sieniawa",
            "Sieniawa",
            "Sieniawa Żarska",
            "Siennica",
            "Siennica Różana",
            "Sienno",
            "Siepraw",
            "Sieradz",
            "Sieradza",
            "Sieraków",
            "Sieraków Śląski",
            "Sierakowice",
            "Sieroszewice",
            "Sierpc",
            "Sietesz",
            "Siewierz",
            "Sitno",
            "Skalbmierz",
            "Skalmierzyce",
            "Skała",
            "Skąpe",
            "Skarbimierz Osiedle",
            "Skarszewy",
            "Skaryszew",
            "Skarżysko Kościelne",
            "Skarżysko-Kamienna",
            "Skawica",
            "Skawina",
            "Skawinki",
            "Skępe",
            "Skierbieszów",
            "Skierniewice",
            "Skoczów",
            "Skoki",
            "Skołyszyn",
            "Skomielna Czarna",
            "Skomlin",
            "Skórcz",
            "Skorogoszcz",
            "Skoroszyce",
            "Skórzec",
            "Skrwilno",
            "Skrzydlna",
            "Skrzyszów",
            "Skrzyszów",
            "Skulsk",
            "Skwierzyna",
            "Ślemień",
            "Ślesin",
            "Ślesin",
            "Śliwice",
            "Sława",
            "Sławatycze",
            "Sławięcice",
            "Sławków",
            "Sławno",
            "Sławno",
            "Sławoborze",
            "Słomniki",
            "Słońsk",
            "Słopnice",
            "Słotowa",
            "Słubice",
            "Słubice",
            "Słupca",
            "Słupia",
            "Słupia",
            "Słupia pod Kępnem",
            "Słupiec",
            "Słupno",
            "Słupsk",
            "Smęgorzów",
            "Smętowo Graniczne",
            "Śmigiel",
            "Śmiłowo",
            "Smolec",
            "Smołdzino",
            "Smyków",
            "Śniadowo",
            "Sobienie Jeziory",
            "Sobków",
            "Sobolew",
            "Sobótka",
            "Sochaczew",
            "Sochocin",
            "Sokolniki",
            "Sokolniki",
            "Sokółka",
            "Sokołów Małopolski",
            "Sokołów Podlaski",
            "Sokoły",
            "Sól",
            "Solec Kujawski",
            "Solec Nad Wisłą",
            "Solec-Zdrój",
            "Sołtysowice",
            "Somonino",
            "Sompolno",
            "Sonina",
            "Sońsk",
            "Sopot",
            "Sopotnia Wielka",
            "Sośnica",
            "Sośnicowice",
            "Sośnie",
            "Sośno",
            "Sosnowica",
            "Sosnowice",
            "Sosnowiec",
            "Sosnówka",
            "Sosnówka",
            "Spiczyn",
            "Spytkowice",
            "Śrem",
            "Środa Śląska",
            "Środa Wielkopolska",
            "Śródmieście",
            "Srokowo",
            "Stabłowice",
            "Stalowa Wola",
            "Stanisław Dolny",
            "Stanisław Górny",
            "Stanisławice",
            "Stanisławów",
            "Stanisławów Pierwszy",
            "Stanowice",
            "Stanowice",
            "Stąporków",
            "Stara Błotnica",
            "Stara Dąbrowa",
            "Stara Kamienica",
            "Stara Kiszewa",
            "Stara Kornica",
            "Stara Wieś",
            "Starachowice",
            "Starcza",
            "Stare Babice",
            "Stare Bogaczowice",
            "Stare Czarnowo",
            "Stare Juchy",
            "Stare Kurowo",
            "Stare Miasto",
            "Stare Miasto",
            "Stare Pole",
            "Stargard",
            "Starogard Gdański",
            "Starokrzepice",
            "Starowa Góra",
            "Staroźreby",
            "Stary Sącz",
            "Stary Targ",
            "Stary Wiśnicz",
            "Stary Zamość",
            "Staszów",
            "Stawiguda",
            "Stawiski",
            "Stawiszyn",
            "Stegna",
            "Stepnica",
            "Sterdyń",
            "Stęszew",
            "Stężyca",
            "Stężyca",
            "Stoczek",
            "Stoczek Łukowski",
            "Stopnica",
            "Stoszowice",
            "Strachocin-Wojnów",
            "Strachocina",
            "Strachówka",
            "Straszydle",
            "Straszyn",
            "Strawczyn",
            "Stromiec",
            "Stronie",
            "Stronie Śląskie",
            "Stróża",
            "Strumień",
            "Stryków",
            "Stryszawa",
            "Stryszów",
            "Strzałkowo",
            "Strzebiń",
            "Strzegom",
            "Strzegowo",
            "Strzelce",
            "Strzelce Krajeńskie",
            "Strzelce Opolskie",
            "Strzelce Wielkie",
            "Strzeleczki",
            "Strzelin",
            "Strzelno",
            "Strzelno",
            "Strzyżów",
            "Strzyżowice",
            "Strzyżowice",
            "Stubno",
            "Studzienice",
            "Studzionka",
            "Stupsk",
            "Subkowy",
            "Sucha",
            "Sucha Beskidzka",
            "Suchań",
            "Suchedniów",
            "Suchowola",
            "Suchożebry",
            "Suchy Dąb",
            "Suchy Las",
            "Sulbiny Górne",
            "Sulechów",
            "Sulęcin",
            "Sulęczyno",
            "Sulejów",
            "Sulejówek",
            "Sulików",
            "Sulmierzyce",
            "Sulmierzyce",
            "Sułkowice",
            "Sułkowice",
            "Sułoszowa",
            "Sułów",
            "Sułów",
            "Supraśl",
            "Suraż",
            "Susiec",
            "Susz",
            "Suszec",
            "Suwałki",
            "Swarzędz",
            "Świątniki Górne",
            "Świdnica",
            "Świdnica",
            "Świdnik",
            "Świdwin",
            "Świebodzice",
            "Świebodzin",
            "Świecie",
            "Świecie nad Osą",
            "Święciechowa",
            "Świedziebnia",
            "Świekatowo",
            "Świeradów-Zdrój",
            "Świercze",
            "Świerczów",
            "Świerklaniec",
            "Świerklany Dolne",
            "Świerklany Górne",
            "Świerzawa",
            "Świerzno",
            "Święta Katarzyna",
            "Swiętajno",
            "Świętochłowice",
            "Świlcza",
            "Świnice Warckie",
            "Świnna",
            "Świnoujście",
            "Swojczyce",
            "Syców",
            "Sypniewo",
            "Sypniewo",
            "Sypniewo",
            "Syrynia",
            "Szadek",
            "Szaflary",
            "Szamocin",
            "Szamotuły",
            "Szarów",
            "Szastarka",
            "Szczaniec",
            "Szczawnica",
            "Szczawno-Zdrój",
            "Szczebrzeszyn",
            "Szczecin",
            "Szczecinek",
            "Szczekociny",
            "Szczepanów",
            "Szczerbice",
            "Szczerców",
            "Szczucin",
            "Szczuczyn",
            "Szczurowa",
            "Szczutowo",
            "Szczyrk",
            "Szczytna",
            "Szczytno",
            "Szelków",
            "Szemud",
            "Szepietowo",
            "Szerzyny",
            "Szklarska Poręba",
            "Szlachta",
            "Szlichtyngowa",
            "Szprotawa",
            "Szreńsk",
            "Sztum",
            "Sztutowo",
            "Szubin",
            "Szumowo",
            "Szydłów",
            "Szydłowiec",
            "Szydłowo",
            "Szydłowo",
            "Szynwałd",
            "Tarczyn",
            "Targanice",
            "Targówek",
            "Targowisko",
            "Tarłów",
            "Tarnawatka",
            "Tarnobrzeg",
            "Tarnogród",
            "Tarnów",
            "Tarnów Opolski",
            "Tarnowiec",
            "Tarnowiec",
            "Tarnówka",
            "Tarnowo Podgórne",
            "Tarnowskie Góry",
            "Tczew",
            "Tczów",
            "Telatyn",
            "Tenczynek",
            "Teresin",
            "Terespol",
            "Tereszpol",
            "Tłuchowo",
            "Tłuszcz",
            "Tokarnia",
            "Tolkmicko",
            "Tomaszkowice",
            "Tomaszów Lubelski",
            "Tomaszów Mazowiecki",
            "Tomice",
            "Topólka",
            "Toporzysko",
            "Toruń",
            "Torzym",
            "Toszek",
            "Trąbki",
            "Trąbki",
            "Trąbki Wielkie",
            "Trablice",
            "Trawniki",
            "Trojanów",
            "Troszyn",
            "Truskaw",
            "Truskolasy",
            "Tryńcza",
            "Trzciana",
            "Trzciana",
            "Trzcianka",
            "Trzciel",
            "Trzcinica",
            "Trzcinica",
            "Trzcińsko Zdrój",
            "Trzebiatów",
            "Trzebiechów",
            "Trzebiel",
            "Trzebielino",
            "Trzebieszów",
            "Trzebinia",
            "Trzebinia",
            "Trzebnica",
            "Trzebownisko",
            "Trzebunia",
            "Trzemeśnia",
            "Trzemeszno",
            "Trzydnik Duży",
            "Tuchola",
            "Tuchomie",
            "Tuchów",
            "Tuczępy",
            "Tuczno",
            "Tuliszków",
            "Tułowice",
            "Tuplice",
            "Turek",
            "Turobin",
            "Turośń Kościelna",
            "Turza Śląska",
            "Turze Pole",
            "Tuszyn",
            "Twardawa",
            "Twardogóra",
            "Tworków",
            "Tworóg",
            "Tychowo",
            "Tychy",
            "Tyczyn",
            "Tykocin",
            "Tylicz",
            "Tymbark",
            "Tyniec Mały",
            "Tyrawa Wołoska",
            "Tyszowce",
            "Uciechów",
            "Udanin",
            "Uherce Mineralne",
            "Ujazd",
            "Ujazd",
            "Ujście",
            "Ujsoły",
            "Ulanów",
            "Ulhówek",
            "Ułęż",
            "Uniejów",
            "Unisław",
            "Ursus",
            "Ursynów",
            "Urszulin",
            "Urzędów",
            "Urzejowice",
            "Uście Gorlickie",
            "Uścimów Stary",
            "Ustka",
            "Ustroń",
            "Ustronie Morskie",
            "Ustrzyki Dolne",
            "Wąbrzeźno",
            "Wąchock",
            "Wadowice",
            "Wadowice Górne",
            "Waganiec",
            "Wągrowiec",
            "Waksmund",
            "Walce",
            "Walichnowy",
            "Walim",
            "Wałbrzych",
            "Wałcz",
            "Wąpielsk",
            "Wapno",
            "Warka",
            "Warlubie",
            "Warnice",
            "Warsaw",
            "Warszawa",
            "Warta",
            "Wartkowice",
            "Wasilków",
            "Waśniów",
            "Wąsosz",
            "Wąsosz",
            "Wawer",
            "Wąwolnica",
            "Wawrzeńczyce",
            "Węgierska Górka",
            "Węgliniec",
            "Węglówka",
            "Węgorzewo",
            "Węgorzyno",
            "Węgrów",
            "Węgry",
            "Węgrzce Wielkie",
            "Wejherowo",
            "Werbkowice",
            "Wesoła",
            "Wesoła",
            "Wiązów",
            "Wiązownica",
            "Widawa",
            "Widawa-Lipa Piotrowska-Polanowice",
            "Widuchowa",
            "Więcbork",
            "Wieczfnia Kościelna",
            "Większyce",
            "Wielbark",
            "Wieleń",
            "Wielgie",
            "Wielichowo",
            "Wieliczka",
            "Wieliszew",
            "Wielka Nieszawka",
            "Wielka Wieś",
            "Wielka Wieś",
            "Wielki Kack",
            "Wielkie Oczy",
            "Wielopole Skrzyńskie",
            "Wielowieś",
            "Wieluń",
            "Wieniawa",
            "Wieprz",
            "Wieprz",
            "Wieruszów",
            "Wierzawice",
            "Wierzbica",
            "Wierzbinek",
            "Wierzbna",
            "Wierzbno",
            "Wierzbno",
            "Wierzchlas",
            "Wierzchosławice",
            "Wierzchosławice",
            "Wierzchowo",
            "Wierzchucino",
            "Wieszowa",
            "Wietrzychowice",
            "Wijewo",
            "Wilamowice",
            "Wilanów",
            "Wilcza",
            "Wilczogóra",
            "Wilczyce",
            "Wilczyce",
            "Wilczyn",
            "Wilga",
            "Wilkołaz",
            "Wilków",
            "Wilków",
            "Wilków",
            "Wilkowice",
            "Wilkowice",
            "Winnica",
            "Wińsko",
            "Wiskitki",
            "Wiślica",
            "Wisła",
            "Wiśniew",
            "Wiśniewo",
            "Wiśniowa",
            "Wiśniowa",
            "Wisznice",
            "Witanowice",
            "Witaszyce",
            "Witkowice",
            "Witkowo",
            "Witnica",
            "Witonia",
            "Wizna",
            "Wleń",
            "Władysławów",
            "Władysławowo",
            "Włochy",
            "Włocławek",
            "Włodawa",
            "Włodowice",
            "Włosienica",
            "Włoszakowice",
            "Włoszczowa",
            "Wodynie",
            "Wodzierady",
            "Wodzisław",
            "Wodzisław Śląski",
            "Wohyń",
            "Wojaszówka",
            "Wojciechów",
            "Wojciechowice",
            "Wojcieszków",
            "Wojcieszów",
            "Wojkowice",
            "Wojnicz",
            "Wojsławice",
            "Wojszyce",
            "Wola",
            "Wola",
            "Wola Batorska",
            "Wola Dębińska",
            "Wola Filipowska",
            "Wola Jachowa",
            "Wola Krzysztoporska",
            "Wola Radziszowska",
            "Wola Rębkowska",
            "Wola Sernicka",
            "Wola Uhruska",
            "Wola Zabierzowska",
            "Wola Żarczycka",
            "Wolanów",
            "Wolbórz",
            "Wolbrom",
            "Wolin",
            "Wólka Niedźwiedzka",
            "Wólka Pełkińska",
            "Wólka Podleśna",
            "Wólka Tanewska",
            "Wolsztyn",
            "Wołczyn",
            "Wołomin",
            "Wołów",
            "Wołowice",
            "Woźniki",
            "Wożniki",
            "Wręczyca Wielka",
            "Wróblew",
            "Wrocław",
            "Wronki",
            "Wrząsowice",
            "Września",
            "Wschowa",
            "Wydminy",
            "Wymiarki",
            "Wyry",
            "Wyrzysk",
            "Wyśmierzyce",
            "Wysoka",
            "Wysoka",
            "Wysoka",
            "Wysoka",
            "Wysoka Głogowska",
            "Wysoka Strzyżowska",
            "Wysokie",
            "Wysokie Mazowieckie",
            "Wyszki",
            "Wyszków",
            "Wyszogród",
            "Żabia Wola",
            "Żabieniec",
            "Zabierzów",
            "Zabierzów Bocheński",
            "Ząbki",
            "Ząbkowice Śląskie",
            "Zabłocie",
            "Zabłudów",
            "Żabnica",
            "Żabno",
            "Zabór",
            "Zaborze",
            "Zaborze",
            "Zabrze",
            "Zabrzeg",
            "Zacisze",
            "Zadzim",
            "Żagań",
            "Zagnańsk",
            "Zagórnik",
            "Zagórów",
            "Zagórz",
            "Zagórze",
            "Zagórzyce",
            "Zagrodno",
            "Zakliczyn",
            "Zaklików",
            "Zakopane",
            "Zakroczym",
            "Zakrzew",
            "Zakrzew",
            "Zakrzewo",
            "Zakrzów",
            "Zakrzów",
            "Zakrzówek",
            "Zalas",
            "Zalesie",
            "Zalesie Górne",
            "Zalesie i Stadion",
            "Zaleszany",
            "Zalewo",
            "Załuski",
            "Zamarski",
            "Zambrów",
            "Zamch",
            "Zamość",
            "Zaniemyśl",
            "Zapolice",
            "Żarki",
            "Żarki",
            "Żarki-Letnisko",
            "Żarnów",
            "Żarów",
            "Zarszyn",
            "Żary",
            "Zarzecze",
            "Zarzecze",
            "Zarzecze",
            "Zator",
            "Zatory",
            "Zawadzkie",
            "Zawichost",
            "Zawidów",
            "Zawidz",
            "Zawiercie",
            "Zawoja",
            "Zbąszyń",
            "Zbąszynek",
            "Zblewo",
            "Zbójna",
            "Zbójno",
            "Zbrosławice",
            "Zbuczyn",
            "Zbytków",
            "Zduńska Wola",
            "Zduny",
            "Zdziechowice Drugie",
            "Zdzieszowice",
            "Zębowice",
            "Zebrzydowice",
            "Zebrzydowice",
            "Żegocina",
            "Żelazków",
            "Żelechlinek",
            "Żelechów",
            "Żelistrzewo",
            "Zelów",
            "Zembrzyce",
            "Żerków",
            "Żernica",
            "Żerniki Wrocławskie",
            "Zgierz",
            "Zgłobień",
            "Zgorzelec",
            "Zgorzelisko",
            "Ziębice",
            "Zielona Góra",
            "Zielonka",
            "Zielonki",
            "Zławieś Wielka",
            "Złocieniec",
            "Złoczew",
            "Złota",
            "Złota",
            "Złotniki",
            "Złotniki",
            "Złotniki Kujawskie",
            "Złotoryja",
            "Złotów",
            "Złoty Stok",
            "Zmiennica",
            "Żmigród",
            "Żnin",
            "Żoliborz",
            "Żółkiewka",
            "Żołynia",
            "Żórawina",
            "Żory",
            "Zubrzyca Dolna",
            "Zubrzyca Górna",
            "Żukowo",
            "Żurawica",
            "Żurawiczki",
            "Żuromin",
            "Żurowa",
            "Zwierzyń",
            "Zwierzyniec",
            "Zwoleń",
            "Żychlin",
            "Żyraków",
            "Żyrardów",
            "Żyrzyn",
            "Żywiec"
        ]
    },
    {
        "label": "Portugal",
        "code": "PT",
        "phone": "351",
        "cities": [
            "A dos Cunhados",
            "A dos Francos",
            "Abrantes",
            "Abraveses",
            "Abrigada",
            "Abrigada",
            "Açores",
            "Adão",
            "Adaúfe",
            "Água de Pau",
            "Água de Pena",
            "Água Longa",
            "Aguada de Cima",
            "Agualva",
            "Águas Belas",
            "Águas Santas",
            "Aguçadoura",
            "Águeda",
            "Aguiar da Beira",
            "Ajuda",
            "Alandroal",
            "Albardo",
            "Albergaria-a-Velha",
            "Albufeira",
            "Alcabideche",
            "Alcabideche",
            "Alcácer do Sal",
            "Alcains",
            "Alcanede",
            "Alcanena",
            "Alcanhões",
            "Alcântara",
            "Alcantarilha",
            "Alcobaça",
            "Alcochete",
            "Alcoentre",
            "Alcoentre",
            "Alcoutim",
            "Aldeia da Ponte",
            "Aldeia da Ribeira",
            "Aldeia de Joanes",
            "Aldeia de Paio Pires",
            "Aldeia de Santo António",
            "Aldeia do Bispo",
            "Aldeia Galega da Merceana",
            "Aldeia Gavinha",
            "Aldeia Nova de São Bento",
            "Aldeia Nova Miranda do Douro",
            "Aldeia Velha",
            "Aldeia Viçosa",
            "Aldeias",
            "Alenquer",
            "Alenquer",
            "Alfaiates",
            "Alfândega da Fé",
            "Alfarelos",
            "Alfeizerão",
            "Alfena",
            "Alferrarede",
            "Alfornelos",
            "Alfragide",
            "Alfragide",
            "Algés",
            "Algés",
            "Algodres",
            "Algoz",
            "Alguber",
            "Algueirão",
            "Algueirão",
            "Algueirão–Mem Martins",
            "Alhadas",
            "Alhandra",
            "Alhandra",
            "Alhos Vedros",
            "Alijó",
            "Aljezur",
            "Aljubarrota",
            "Aljustrel",
            "Almada",
            "Almancil",
            "Almargem",
            "Almargem",
            "Almargem do Bispo",
            "Almeida",
            "Almeirim",
            "Almendra",
            "Almodôvar",
            "Almofala",
            "Almograve",
            "Alpendurada",
            "Alpiarça",
            "Alter do Chão",
            "Alto do Pina",
            "Altura",
            "Alvaiázere",
            "Alvalade",
            "Alvalade",
            "Alvendre",
            "Alverca da Beira",
            "Alverca do Ribatejo",
            "Alvito",
            "Alvoco da Serra",
            "Alvor",
            "Alvorninha",
            "Amadora",
            "Amadora",
            "Amarante",
            "Amares",
            "Ameixoeira",
            "Amiães de Baixo",
            "Amor",
            "Amora",
            "Amoreira",
            "Amorim",
            "Anadia",
            "Ançã",
            "Angra do Heroísmo",
            "Angústias",
            "Anjos",
            "Ansião",
            "Anta",
            "Apelação",
            "Apelação",
            "Apúlia",
            "Apúlia e Fão",
            "Aradas",
            "Arazede",
            "Arco da Calheta",
            "Arcos",
            "Arcos",
            "Arcos de Valdevez",
            "Arcozelo",
            "Arcozelo",
            "Arganil",
            "Argivai",
            "Argoncilhe",
            "Armação de Pêra",
            "Armamar",
            "Arões",
            "Arouca",
            "Arraiolos",
            "Arranhó",
            "Arranhó",
            "Arrentela",
            "Arrifana",
            "Arrifana",
            "Arrifes",
            "Arronches",
            "Arruda dos Vinhos",
            "Arruda Dos Vinhos",
            "Árvore",
            "Assafarge",
            "Atalaia",
            "Atalaia",
            "Atouguia da Baleia",
            "Avanca",
            "Aveiras de Baixo",
            "Aveiras de Cima",
            "Aveiras de Cima",
            "Aveiro",
            "Avelãs da Ribeira",
            "Aveleda",
            "Aveloso",
            "Aver-o-Mar",
            "Aves",
            "Avintes",
            "Avis",
            "Azambuja",
            "Azambuja",
            "Azambuja (town)",
            "Azeitão",
            "Azenha",
            "Azenhas do Mar",
            "Azevo",
            "Azinhaga",
            "Azueira",
            "Baguim do Monte",
            "Baião",
            "Balazar",
            "Baltar",
            "Baraçal",
            "Barcarena",
            "Barcarena",
            "Barcelos",
            "Barqueiros",
            "Barrancos",
            "Barreira",
            "Barreiro",
            "Barrosas",
            "Batalha",
            "Beato",
            "Beato António",
            "Beduido",
            "Beiriz de Baixo",
            "Beja",
            "Belas",
            "Belas",
            "Belmonte",
            "Belver",
            "Bemfica",
            "Benavente",
            "Bendada",
            "Benedita",
            "Benespera",
            "Benfica",
            "Benfica",
            "Bensafrim",
            "Beringel",
            "Biscoitos",
            "Bismula",
            "Boaventura",
            "Boavista dos Pinheiros",
            "Bobadela",
            "Bobadela",
            "Boliqueime",
            "Bombarral",
            "Borba",
            "Boticas",
            "Bouça Cova",
            "Bougado",
            "Braga",
            "Bragança Municipality",
            "Branca",
            "Brandoa",
            "Brito",
            "Buarcos",
            "Bucelas",
            "Bucelas",
            "Buraca",
            "Cabanas de Tavira",
            "Cabanas de Torres",
            "Cabanas de Torres",
            "Cabanas de Viriato",
            "Cabeça",
            "Cabeça Gorda",
            "Cabeceiras de Basto",
            "Cabouco",
            "Cacem",
            "Cacém",
            "Cachoeiras",
            "Cacilhas",
            "Cadafais",
            "Cadafais",
            "Cadafaz",
            "Cadaval",
            "Cadaval",
            "Cais do Pico",
            "Caldas da Rainha",
            "Caldas das Taipas",
            "Caldas de Vizela",
            "Caldelas",
            "Calendário",
            "Calhandriz",
            "Calheta",
            "Calheta",
            "Calheta de São Jorge",
            "Camacha",
            "Câmara de Lobos",
            "Camarate",
            "Camarate",
            "Caminha",
            "Campanário",
            "Campelos",
            "Campelos",
            "Campo",
            "Campo",
            "Campo de Besteiros",
            "Campo Grande",
            "Campo Maior",
            "Campolide",
            "Canas de Senhorim",
            "Candoso",
            "Caneças",
            "Caneças",
            "Canelas",
            "Canhas",
            "Caniçal",
            "Caniço",
            "Canidelo",
            "Cantanhede",
            "Caparica",
            "Caranguejeira",
            "Carapinheira",
            "Carapito",
            "Carcavelos",
            "Carcavelos",
            "Cardosas",
            "Carmões",
            "Carnaxide",
            "Carnaxide",
            "Carnicães",
            "Carnide",
            "Carnota",
            "Carragozela",
            "Carrapichana",
            "Carrazeda de Anciães",
            "Carrazeda de Ansiães",
            "Carregado",
            "Carregado",
            "Carregal do Sal",
            "Cartaxo",
            "Carvalhal",
            "Carvalhosa",
            "Carvoeira",
            "Carvoeiro",
            "Casal de Cambra",
            "Casal de Cinza",
            "Casal Vasco",
            "Casas do Soeiro",
            "Cascais",
            "Cascais",
            "Castanheira",
            "Castanheira de Pêra",
            "Castanheira do Ribatejo",
            "Castanheira do Ribatejo",
            "Casteição",
            "Casteleiro",
            "Castelo (Lisbon)",
            "Castelo Bom",
            "Castelo Branco",
            "Castelo Branco",
            "Castelo de Paiva",
            "Castelo de Vide",
            "Castelo Melhor",
            "Castelo Rodrigo",
            "Castelões de Cepeda",
            "Castro Daire",
            "Castro Marim",
            "Castro Verde",
            "Cativelos",
            "Cavadoude",
            "Caxias",
            "Caxias",
            "Cedovim",
            "Cela",
            "Celorico da Beira",
            "Celorico de Basto",
            "Cercal",
            "Cercal",
            "Cerdeira",
            "Cerejo",
            "Chamusca",
            "Charneca",
            "Charneca",
            "Charneca de Caparica",
            "Chãs",
            "Chavão",
            "Chaves",
            "Cheleiros",
            "Cinfães",
            "Codesseiro",
            "Cogula",
            "Coimbra",
            "Colares",
            "Colares",
            "Comporta",
            "Conceição",
            "Condeixa-a-Nova",
            "Constância",
            "Coração de Jesus",
            "Coriscada",
            "Corroios",
            "Cortegaça",
            "Cortiçada",
            "Cortiçô",
            "Cortiçô da Serra",
            "Coruche",
            "Coruche",
            "Corujeira",
            "Corvo",
            "Costa de Caparica",
            "Cótimos",
            "Covilhã",
            "Crato",
            "Cristelo",
            "Cruz Quebrada - Dafundo",
            "Cuba",
            "Cucujães",
            "Curral das Freiras",
            "Custoias",
            "Damaia",
            "Dois Portos",
            "Dornelas",
            "Eirado",
            "Eixo",
            "Elvas",
            "Encarnação",
            "Entroncamento",
            "Enxara do Bispo",
            "Ericeira",
            "Ericeira",
            "Ermesinde",
            "Ervas Tenras",
            "Ervedosa",
            "Ervedosa do Douro",
            "Escalhão",
            "Esgueira",
            "Esmoriz",
            "Espargo",
            "Espinho",
            "Esporões",
            "Esposende",
            "Estarreja",
            "Estela",
            "Estói",
            "Estômbar",
            "Estoril",
            "Estoril",
            "Estreito da Calheta",
            "Estremoz",
            "Évora",
            "Fafe",
            "Faia",
            "Faial",
            "Fajã da Ovelha",
            "Fajã de Baixo",
            "Falagueira",
            "Famalicão",
            "Famalicão",
            "Famões",
            "Famões",
            "Fanhões",
            "Fânzeres",
            "Faria",
            "Faro",
            "Fátima",
            "Favaios",
            "Fazendas de Almeirim",
            "Feira",
            "Felgueiras",
            "Fenais da Ajuda",
            "Fenais da Luz",
            "Fernão Joanes",
            "Ferragudo",
            "Ferreira",
            "Ferreira do Alentejo",
            "Ferreira do Zêzere",
            "Ferreiras",
            "Ferreiros",
            "Ferrel",
            "Feteira",
            "Fiães",
            "Fiães",
            "Figueira da Foz",
            "Figueira de Castelo Rodrigo",
            "Figueira do Guincho",
            "Figueiró",
            "Figueiró da Granja",
            "Figueiró da Serra",
            "Figueiró Dos Vinhos",
            "Figueiros",
            "Fóios",
            "Folgosinho",
            "Folhadosa",
            "Fontanelas",
            "Fontanelas",
            "Fonte Bastardo",
            "Fonte Longa",
            "Forninhos",
            "Forno Telheiro",
            "Fornos de Algodres",
            "Forte da Casa",
            "Foz do Arelho",
            "Foz do Douro",
            "Foz do Sousa",
            "Fradelos",
            "Frazão",
            "Freamunde",
            "Freches",
            "Freineda",
            "Freiria",
            "Freixeda do Torrão",
            "Freixedas",
            "Freixo",
            "Freixo de Espada à Cinta",
            "Freixo de Numão",
            "Frielas",
            "Fronteira",
            "Frossos",
            "Funchal",
            "Fundão",
            "Furnas",
            "Fuzeta",
            "Gafanha da Encarnação",
            "Gafanha da Nazaré",
            "Gagos",
            "Galegos",
            "Gandra",
            "Gandra",
            "Gavião",
            "Gemunde",
            "Girabolhos",
            "Godim",
            "Góis",
            "Golegã",
            "Gonçalo",
            "Gonçalo Bocas",
            "Gondizalves",
            "Gondomar",
            "Gouveia",
            "Graça",
            "Gradil",
            "Gradiz",
            "Grândola",
            "Granja",
            "Grijó",
            "Guarda",
            "Gueral",
            "Guia",
            "Guifões",
            "Guilheiro",
            "Guimarães",
            "Guisande",
            "Gulpilhares",
            "Horta",
            "Horta",
            "Idanha-A-Nova",
            "Igreja Nova",
            "Ílhavo",
            "Infias",
            "Jarmelo São Miguel",
            "Jarmelo São Pedro",
            "Joane",
            "João Antão",
            "Jovim",
            "Junça",
            "Juncais",
            "Lagarinhos",
            "Lago",
            "Lagoa",
            "Lagoa",
            "Lagos",
            "Lajeosa",
            "Lajeosa do Mondego",
            "Lajes",
            "Lajes",
            "Lajes das Flores",
            "Lajes do Pico",
            "Lamas",
            "Lamas",
            "Lamegal",
            "Lamego",
            "Lameiras",
            "Lapa",
            "Lapa Dos Dinheiros",
            "Laranjeira",
            "Laranjeiro",
            "Lavos",
            "Lavra",
            "Lavradio",
            "Leça da Palmeira",
            "Leça do Bailio",
            "Leiria",
            "Leomil",
            "Linda-a-Velha",
            "Linda-a-Velha",
            "Linhares (Celorico da Beira)",
            "Lisbon",
            "Lisbon",
            "Lobão",
            "Longroiva",
            "Lordelo",
            "Lordelo",
            "Loriga",
            "Lorvão",
            "Loulé",
            "Loures",
            "Loures",
            "Louriçal",
            "Lourinhã",
            "Lourinhã",
            "Lourosa",
            "Lousa",
            "Lousã",
            "Lousada",
            "Lumiar",
            "Luso",
            "Lustosa",
            "Luz",
            "Maçainhas",
            "Maçal do Chão",
            "Mação",
            "Macedo de Cavaleiros",
            "Maceira",
            "Maceira",
            "Machico",
            "Macieira de Cambra",
            "Macieira de Rates",
            "Maçussa",
            "Madalena",
            "Madalena",
            "Madalena",
            "Madalena",
            "Mafra",
            "Mafra",
            "Maia",
            "Maia",
            "Malcata",
            "Malhada Sorda",
            "Malpartida",
            "Malveira",
            "Malveira",
            "Mangualde",
            "Mangualde da Serra",
            "Manigoto",
            "Manique do Intendente",
            "Manta Rota",
            "Manteigas",
            "Manteigas (São Pedro)",
            "Marco de Canaveses",
            "Marco de Canavezes",
            "Margaride",
            "Marialva",
            "Marinha Grande",
            "Marinhais",
            "Marinhas",
            "Mariz",
            "Marmeleiro",
            "Marmelete",
            "Marteleira",
            "Mártires",
            "Marvão",
            "Marvila",
            "Massamá",
            "Massamá",
            "Mata de Lobos",
            "Matacães",
            "Matança",
            "Matosinhos",
            "Maxial",
            "Mealhada",
            "Meca",
            "Meca",
            "Mêda",
            "Meinedo",
            "Meios",
            "Melgaço",
            "Melo",
            "Melres",
            "Mem Martins",
            "Mem Martins",
            "Mercês",
            "Merelim",
            "Mértola",
            "Mesão Frio",
            "Mesquitela",
            "Mexilhoeira Grande",
            "Milharado",
            "Milharado",
            "Milheirós",
            "Milheirós de Poiares",
            "Mina",
            "Minas de São Domingos",
            "Minde",
            "Mindelo",
            "Minhocal",
            "Mira",
            "Mira",
            "Mira-Sintra",
            "Miragaia",
            "Miranda do Corvo",
            "Miranda do Douro",
            "Mirandela",
            "Miuzela",
            "Mizarela",
            "Mogadouro",
            "Moimenta da Beira",
            "Moimenta da Serra",
            "Moimentinha",
            "Moita",
            "Moita",
            "Moita dos Ferreiros",
            "Moita dos Ferreiros",
            "Moledo",
            "Monção",
            "Moncarapacho",
            "Monchique",
            "Mondim de Basto",
            "Monforte",
            "Monsanto",
            "Montalegre",
            "Montalvinho",
            "Montalvo",
            "Montargil",
            "Monte Abraão",
            "Monte Estoril",
            "Monte Estoril",
            "Monte Gordo",
            "Monte Real",
            "Monte Redondo",
            "Monte Redondo",
            "Montelavar",
            "Montemor-o-Novo",
            "Montemor-o-Velho",
            "Montijo",
            "Mora",
            "Moreira",
            "Moreira de Conegos",
            "Moreira de Rei",
            "Mortágua",
            "Mós",
            "Moscavide",
            "Moscavide e Portela",
            "Mosteirô",
            "Mosteiros",
            "Moura",
            "Mourão",
            "Murça",
            "Murça",
            "Murtosa",
            "Muxagata",
            "Nabais",
            "Nadadouro",
            "Nave",
            "Nave de Haver",
            "Nazaré",
            "Negreiros",
            "Negrelos",
            "Nelas",
            "Nespereira",
            "Neves",
            "Nisa",
            "Nogueira",
            "Nogueira da Regedoura",
            "Nordeste",
            "Nossa Senhora de Fátima",
            "Nossa Senhora do Monte",
            "Numão",
            "Óbidos",
            "Odemira",
            "Odivelas",
            "Odivelas",
            "Odivelas Municipality",
            "Oeiras",
            "Oeiras",
            "Oeiras e São Julião da Barra",
            "Oiã",
            "Oleiros",
            "Olhalvo",
            "Olhão",
            "Olhos de Água",
            "Olivais",
            "Olival",
            "Olival de Basto",
            "Olival do Basto",
            "Olival do Basto",
            "Oliveira",
            "Oliveira de Azemeis",
            "Oliveira de Frades",
            "Oliveira do Bairro",
            "Oliveira do Douro",
            "Oliveira do Hospital",
            "Oliveira do Mondego",
            "Oliveirinha",
            "Ota",
            "Ota",
            "Ourém",
            "Ourique",
            "Outeiro da Cabeça",
            "Outeiro de Gatos",
            "Ovar",
            "Paço de Arcos",
            "Paço de Arcos",
            "Paços da Serra",
            "Paços de Brandão",
            "Paços de Ferreira",
            "Paderne",
            "Paialvo",
            "Painho",
            "Pala",
            "Palhais",
            "Palmela",
            "Pampilhosa da Serra",
            "Pampilhosa do Botão",
            "Panoias de Cima",
            "Parada",
            "Paranhos",
            "Parchal",
            "Pardilhó",
            "Parede",
            "Parede",
            "Paredes",
            "Paredes de Coura",
            "Pataias",
            "Pedrógão Grande",
            "Pedroso",
            "Pedrouços",
            "Pega",
            "Pego",
            "Pena",
            "Pena Lobo",
            "Pena Verde",
            "Penacova",
            "Penafiel",
            "Penalva do Castelo",
            "Penamacor",
            "Penedo Gordo",
            "Penedono",
            "Penela",
            "Penha de Águia",
            "Penha de França",
            "Peniche",
            "Pêra",
            "Pêra do Moço",
            "Perafita",
            "Peral",
            "Pereiro",
            "Pereiro de Palhacana",
            "Perelhal",
            "Pêro Moniz",
            "Pero Pinheiro",
            "Pero Pinheiro",
            "Perozinho",
            "Peso da Régua",
            "Peva",
            "Piedade",
            "Pinhal Novo",
            "Pinhanços",
            "Pinheiro",
            "Pinheiro Grande",
            "Pinhel",
            "Pínzio",
            "Poceirão",
            "Poço do Canto",
            "Poiares",
            "Pomares",
            "Pombal",
            "Ponta Delgada",
            "Ponta do Sol",
            "Ponta Garça",
            "Ponte",
            "Ponte da Barca",
            "Ponte de Lima",
            "Ponte de Sor",
            "Ponte do Rol",
            "Pontével",
            "Pontinha",
            "Pontinha",
            "Porches",
            "Portalegre",
            "Portel",
            "Portela",
            "Portimão",
            "Porto",
            "Porto Covo",
            "Porto da Carne",
            "Porto da Cruz",
            "Porto de Mós",
            "Porto Judeu",
            "Porto Martins",
            "Porto Moniz",
            "Porto Salvo",
            "Porto Salvo",
            "Porto Santo",
            "Pousada",
            "Pousafoles do Bispo",
            "Póvoa de Lanhoso",
            "Póvoa de Santa Iria",
            "Póvoa de Santa Iria",
            "Póvoa de Santo Adrião",
            "Póvoa de Santo Adrião",
            "Póvoa de Varzim",
            "Póvoa do Concelho",
            "Povoação",
            "Prado",
            "Prados",
            "Pragal",
            "Praia da Vitória",
            "Praia do Ribatejo",
            "Prazeres",
            "Prior Velho",
            "Prior Velho",
            "Proença-a-Nova",
            "Prova",
            "Quadrazais",
            "Quarteira",
            "Queijas",
            "Queijas",
            "Queiriz",
            "Queluz",
            "Queluz",
            "Quintã de Pêro Martins",
            "Quinta do Anjo",
            "Quinta Do Conde",
            "Quintas de São Bartolomeu",
            "Rabaçal",
            "Rabo de Peixe",
            "Ramada",
            "Ramada",
            "Ramalhal",
            "Ramela",
            "Ranhados",
            "Rapa",
            "Rapoula do Côa",
            "Ratoeira",
            "Real",
            "Reboleira",
            "Reboleiro",
            "Rebolosa",
            "Rebordões",
            "Rebordosa",
            "Recarei",
            "Redondo",
            "Refojos de Basto",
            "Reguengo Grande",
            "Reguengos de Monsaraz",
            "Reigada",
            "Relva",
            "Rendo",
            "Rendufe",
            "Resende",
            "Riachos",
            "Riba de Ave",
            "Ribafria",
            "Ribamar",
            "Ribamondego",
            "Ribeira Brava",
            "Ribeira de Pena",
            "Ribeira Dos Carinhos",
            "Ribeira Grande",
            "Ribeira Seca",
            "Ribeirão",
            "Ribeirinha",
            "Rio de Loba",
            "Rio de Mel",
            "Rio de Mouro",
            "Rio de Mouro",
            "Rio Maior",
            "Rio Mau",
            "Rio Meão",
            "Rio Tinto",
            "Rio Torto",
            "Rochoso",
            "Ronfe",
            "Rosairinho",
            "Rosto de Cão",
            "Runa",
            "Ruvina",
            "Sabrosa",
            "Sabugal",
            "Sabugueiro",
            "Sacavém",
            "Sacavém",
            "Sacramento",
            "Safurdão",
            "Sagres",
            "Salgueirais",
            "Salir de Matos",
            "Salreu",
            "Salvada",
            "Salvaterra de Magos",
            "Sameice",
            "Sameiro",
            "Samil",
            "Samora Correia",
            "Samouco",
            "Sande",
            "Sandim",
            "Sandomil",
            "Sanfins",
            "Sangalhos",
            "Santa Bárbara",
            "Santa Bárbara",
            "Santa Bárbara de Nexe",
            "Santa Catarina",
            "Santa Catarina da Serra",
            "Santa Clara",
            "Santa Comba",
            "Santa Comba Dão",
            "Santa Cruz",
            "Santa Cruz",
            "Santa Cruz da Graciosa",
            "Santa Cruz das Flores",
            "Santa Cruz do Bispo",
            "Santa Engrácia",
            "Santa Eufémia",
            "Santa Eulália",
            "Santa Iria da Azóia",
            "Santa Iria da Azóia",
            "Santa Iria de Azoia",
            "Santa Isabel",
            "Santa Justa",
            "Santa Luzia",
            "Santa Margarida da Coutada",
            "Santa Maria",
            "Santa Maria da Feira",
            "Santa Maria de Belém",
            "Santa Maria do Castelo e São Miguel",
            "Santa Maria dos Olivais",
            "Santa Maria e São Miguel",
            "Santa Marinha",
            "Santa Marta de Penaguião",
            "Santana",
            "Santana da Azinha",
            "Santarém",
            "Santiago",
            "Santiago do Cacém",
            "Santiago dos Velhos",
            "Santo André",
            "Santo André",
            "Santo Antão do Tojal",
            "Santo Antão do Tojal",
            "Santo António da Charneca",
            "Santo António dos Cavaleiros",
            "Santo António dos Olivais",
            "Santo Condestável",
            "Santo Estêvão",
            "Santo Estêvão das Galés",
            "Santo Isidoro",
            "Santo Isidoro",
            "Santo Izidoro",
            "Santo Quintino",
            "Santo Tirso",
            "Santos-o-Velho",
            "Santos-o-Velho",
            "São Bartolomeu",
            "São Bartolomeu",
            "São Bartolomeu",
            "São Bartolomeu de Messines",
            "São Bartolomeu dos Galegos",
            "São Brás",
            "São Brás de Alportel",
            "São Cristóvão e São Lourenço",
            "São Domingos de Benfica",
            "São Domingos de Rana",
            "São Domingos de Rana",
            "São Félix da Marinha",
            "São Francisco Xavier",
            "São João",
            "São João",
            "São João da Madeira",
            "São João da Pesqueira",
            "São João da Talha",
            "São João da Talha",
            "São João das Lampas",
            "São João das Lampas",
            "São João de Areias",
            "São João de Brito",
            "São João de Deus",
            "São João de Ver",
            "São João Dos Montes",
            "São João dos Montes",
            "São Jorge",
            "São Jorge de Arroios",
            "São José",
            "São Julião",
            "São Julião do Tojal",
            "São Luis",
            "São Mamede",
            "São Mamede de Infesta",
            "São Marcos",
            "São Marcos da Serra",
            "São Martinho",
            "São Martinho",
            "São Martinho do Porto",
            "São Mateus",
            "São Miguel",
            "São Miguel da Guarda",
            "São Miguel de Alcainça",
            "São Miguel de Rio Torto",
            "São Miguel do Couto",
            "São Nicolau",
            "São Paio",
            "São Paulo",
            "São Pedro",
            "São Pedro da Cadeira",
            "São Pedro da Cadeira",
            "São Pedro da Cova",
            "São Pedro de Alva",
            "São Pedro de Penaferrim",
            "São Pedro de Rio Seco",
            "São Pedro do Sul",
            "São Romão",
            "São Romão do Coronado",
            "São Roque",
            "São Roque",
            "São Roque",
            "São Roque do Pico",
            "São Sebastião",
            "São Sebastião da Pedreira",
            "São Teotónio",
            "São Vicente",
            "São Vicente",
            "São Vicente",
            "São Vicente de Fora",
            "São Vicente do Paul",
            "Sapataria",
            "Sardoal",
            "Sarilhos Pequenos",
            "Sátão",
            "Sazes da Beira",
            "Sé",
            "Sebadelhe",
            "Sebadelhe da Serra",
            "Seia",
            "Seixal",
            "Seixas",
            "Seixo do Côa",
            "Selho",
            "Semelhe",
            "Sendim",
            "Senhora da Hora",
            "Senhora da Luz",
            "Senhora do Rosário",
            "Sequeira",
            "Sequeiros",
            "Sermonde",
            "Sernancelhe",
            "Seroa",
            "Serpa",
            "Serra de El-Rei",
            "Sertã",
            "Serzedelo",
            "Serzedo",
            "Serzedo",
            "Sesimbra",
            "Setúbal",
            "Sever do Vouga",
            "Silvalde",
            "Silveira",
            "Silveira",
            "Silves",
            "Sines",
            "Sintra",
            "Sintra",
            "Sintra (town)",
            "Sobrado",
            "Sobral da Abelheira",
            "Sobral da Serra",
            "Sobral de Monte Agraço",
            "Sobral de Monte Agraço",
            "Sobral Pichorro",
            "Sobralinho",
            "Sobralinho",
            "Sobreda",
            "Sobreira",
            "Socorro",
            "Sortelha",
            "Soure",
            "Souro Pires",
            "Sousel",
            "Souto",
            "Souto",
            "Souto da Carpalhosa",
            "Souto de Aguiar da Beira",
            "Souto Maior",
            "Tábua",
            "Tabuaço",
            "Tamanhos",
            "Tarouca",
            "Tavarede",
            "Tavira",
            "Tebosa",
            "Teixeira",
            "Teixoso",
            "Telões",
            "Terras de Bouro",
            "Terrenho",
            "Terrugem",
            "Terrugem",
            "Tocha",
            "Tomar",
            "Tondela",
            "Torre de Moncorvo",
            "Torre do Terrenho",
            "Torres",
            "Torres Novas",
            "Torres Vedras",
            "Torres Vedras",
            "Torrozelo",
            "Touça",
            "Tourais",
            "Trafaria",
            "Tramagal",
            "Trancoso",
            "Travanca",
            "Travancinha",
            "Treixedo",
            "Triana",
            "Trinta",
            "Trofa",
            "Tunes",
            "Turcifal",
            "Turquel",
            "Unhos",
            "Unhos",
            "Urgeses",
            "Vagos",
            "Vairão",
            "Valadares",
            "Valado de Frades",
            "Valbom",
            "Valbom",
            "Valdujo",
            "Vale da Amoreira",
            "Vale da Mula",
            "Vale de Amoreira",
            "Vale de Azares",
            "Vale de Cambra",
            "Vale de Espinho",
            "Vale de Estrela",
            "Vale de Figueira",
            "Vale de Santarém",
            "Vale do Paraíso",
            "Vale do Seixo",
            "Vale Flor",
            "Válega",
            "Valença",
            "Valezim",
            "Valhelhas",
            "Valongo",
            "Valongo",
            "Valpaços",
            "Valverde",
            "Várzea",
            "Várzea de Meruge",
            "Vascoveiro",
            "Vela",
            "Velas",
            "Velosa",
            "Venda do Pinheiro",
            "Venda do Pinheiro",
            "Venda Nova",
            "Vendas Novas",
            "Venteira",
            "Ventosa",
            "Ventosa",
            "Vermelha",
            "Vermiosa",
            "Vestiaria",
            "Vialonga",
            "Vialonga",
            "Viana do Alentejo",
            "Viana do Castelo",
            "Vidais",
            "Vide",
            "Vide Entre Vinhas",
            "Videmonte",
            "Vidigueira",
            "Vieira de Leiria",
            "Vieira do Minho",
            "Vila Boa",
            "Vila Boa do Mondego",
            "Vila Chã",
            "Vila Chã de Ourique",
            "Vila Cortês da Serra",
            "Vila Cortês do Mondego",
            "Vila Cova",
            "Vila Cova à Coelheira",
            "Vila de Rei",
            "Vila do Bispo",
            "Vila do Conde",
            "Vila do Porto",
            "Vila do Touro",
            "Vila Fernando",
            "Vila Flor",
            "Vila Franca da Serra",
            "Vila Franca das Naves",
            "Vila Franca de Xira",
            "Vila Franca de Xira",
            "Vila Franca do Campo",
            "Vila Franca do Deão",
            "Vila Franca do Rosário",
            "Vila Frescainha",
            "Vila Garcia",
            "Vila Meã",
            "Vila Nova da Barquinha",
            "Vila Nova da Rainha",
            "Vila Nova da Telha",
            "Vila Nova De Cacela",
            "Vila Nova de Famalicão",
            "Vila Nova de Foz Côa",
            "Vila Nova de Gaia",
            "Vila Nova de Milfontes",
            "Vila Nova de Paiva",
            "Vila Nova de Poiares",
            "Vila Nova de São Pedro",
            "Vila Nova de Tazem",
            "Vila Pouca de Aguiar",
            "Vila Real",
            "Vila Real de Santo António",
            "Vila Ruiva",
            "Vila Seca",
            "Vila Soeiro do Chão",
            "Vila Velha de Ródão",
            "Vila Verde",
            "Vila Verde",
            "Vila Verde",
            "Vila Verde dos Francos",
            "Vila Viçosa",
            "Vilaça",
            "Vilamoura",
            "Vilar",
            "Vilar de Amargo",
            "Vilar de Andorinho",
            "Vilar de Figos",
            "Vilar do Paraíso",
            "Vilar Formoso",
            "Vilar Maior",
            "Vilar Torpim",
            "Vilares",
            "Vilarinho",
            "Vilela",
            "Vimeiro",
            "Vimeiro",
            "Vimioso",
            "Vinhais",
            "Vinhó",
            "Viseu",
            "Vizela",
            "Vizela",
            "Vouzela",
            "Zambujeira do Mar"
        ]
    },
    {
        "label": "Puerto Rico",
        "code": "PR",
        "phone": "+1-787 and 1-939",
        "cities": [
            "Adjuntas",
            "Aguada",
            "Aguadilla",
            "Aguas Buenas",
            "Aibonito",
            "Añasco",
            "Arecibo",
            "Arroyo",
            "Barceloneta",
            "Barranquitas",
            "Bayamón",
            "Cabo Rojo",
            "Caguas",
            "Camuy",
            "Canóvanas",
            "Carolina",
            "Cataño",
            "Cayey",
            "Ceiba",
            "Ciales",
            "Cidra",
            "Coamo",
            "Comerío",
            "Corozal",
            "Culebra",
            "Dorado",
            "Fajardo",
            "Florida",
            "Guánica",
            "Guayama",
            "Guayanilla",
            "Guaynabo",
            "Gurabo",
            "Hatillo",
            "Hormigueros",
            "Humacao",
            "Isabela",
            "Jayuya",
            "Juana Díaz",
            "Juncos",
            "Lajas",
            "Lares",
            "Las Marías",
            "Las Piedras",
            "Loíza",
            "Luquillo",
            "Manatí",
            "Maricao",
            "Maunabo",
            "Mayagüez",
            "Moca",
            "Morovis",
            "Naguabo",
            "Naranjito",
            "Orocovis",
            "Patillas",
            "Peñuelas",
            "Ponce",
            "Quebradillas",
            "Rincón",
            "Río Grande",
            "Sabana Grande",
            "Salinas",
            "San Germán",
            "San Juan",
            "San Lorenzo",
            "San Sebastián",
            "Santa Isabel",
            "Toa Alta",
            "Toa Baja",
            "Trujillo Alto",
            "Utuado",
            "Vega Alta",
            "Vega Baja",
            "Vieques",
            "Villalba",
            "Yabucoa",
            "Yauco"
        ]
    },
    {
        "label": "Qatar",
        "code": "QA",
        "phone": "974",
        "cities": [
            "Al Ghuwayrīyah",
            "Al Jumaylīyah",
            "Al Khawr",
            "Al Wakrah",
            "Al Wukayr",
            "Ar Rayyān",
            "Ar Ruways",
            "Ash Shīḩānīyah",
            "Doha",
            "Dukhān",
            "Fuwayriţ",
            "Madīnat ash Shamāl",
            "Musay‘īd",
            "Umm Bāb",
            "Umm Şalāl Muḩammad"
        ]
    },
    {
        "label": "Reunion",
        "code": "RE",
        "phone": "262",
        "cities": []
    },
    {
        "label": "Romania",
        "code": "RO",
        "phone": "40",
        "cities": [
            "1 Decembrie",
            "23 August",
            "Abram",
            "Abrămuţ",
            "Abrud",
            "Abrud-Sat",
            "Abuș",
            "Acâş",
            "Acățari",
            "Acriș",
            "Adamclisi",
            "Adămuș",
            "Adânca",
            "Adâncata",
            "Adâncata",
            "Adășeni",
            "Adea",
            "Adjud",
            "Adjudeni",
            "Adjudu Vechi",
            "Adrian",
            "Adrianu Mare",
            "Adrianu Mic",
            "Adunaţi",
            "Adunații-Copăceni",
            "Afumaţi",
            "Afumaţi",
            "Agapia",
            "Agârbiciu",
            "Agăş",
            "Aghireș",
            "Aghireșu",
            "Aghireșu-Fabrici",
            "Agigea",
            "Agighiol",
            "Agnita",
            "Agrieș",
            "Agrij",
            "Agriș",
            "Agrișteu",
            "Agrișu Mare",
            "Aita Mare",
            "Aiton",
            "Aiud",
            "Aiudul de Sus",
            "Alămor",
            "Alba",
            "Alba Iulia",
            "Albac",
            "Albeni",
            "Albești",
            "Albeşti",
            "Albeşti",
            "Albeşti",
            "Albeşti",
            "Albeşti",
            "Albești-Muru",
            "Albeşti-Paleologu",
            "Albeştii Pământeni",
            "Albeștii Ungureni",
            "Albiș",
            "Albota",
            "Albota de Jos",
            "Aleşd",
            "Alexandria",
            "Alexandru I. Cuza",
            "Alexandru Odobescu",
            "Alexandru Vlăhuţă",
            "Alexeni",
            "Aliman",
            "Alimănești",
            "Alimpeşti",
            "Alma",
            "Almăj",
            "Almaş",
            "Almaşu",
            "Almaşu Mare",
            "Alparea",
            "Alțâna",
            "Aluniș",
            "Aluniş",
            "Aluniş",
            "Alunișu",
            "Alunișu",
            "Alunu",
            "Amara",
            "Amara",
            "Amărăşti",
            "Amărăştii de Jos",
            "Amărăştii de Sus",
            "Amaru",
            "Amzacea",
            "Andrăşeşti",
            "Andreești",
            "Andrei Șaguna",
            "Andreiaşu de Jos",
            "Andreneasa",
            "Andrid",
            "Andrieşeni",
            "Anghelești",
            "Angofa",
            "Anieș",
            "Anina",
            "Aninoasa",
            "Aninoasa",
            "Aninoasa",
            "Aninoasa",
            "Apa",
            "Apa Asău",
            "Apahida",
            "Apalina",
            "Apaţa",
            "Apateu",
            "Apele Vii",
            "Apold",
            "Apoldu de Jos",
            "Apostolache",
            "Aprozi",
            "Araci",
            "Arad",
            "Arbore",
            "Arcani",
            "Archiş",
            "Archita",
            "Arcuș",
            "Ardeoani",
            "Ardud",
            "Ardusat",
            "Arduzel",
            "Arefu",
            "Argel",
            "Argeșelu",
            "Argetoaia",
            "Arghira",
            "Ariceștii Zeletin",
            "Ariceştii-Rahtivani",
            "Arieşeni",
            "Arieșu de Câmp",
            "Arieșu de Pădure",
            "Arini",
            "Ariniş",
            "Armăşeşti",
            "Armeniş",
            "Aroneanu",
            "Arpaşu de Jos",
            "Arpașu de Sus",
            "Arșița",
            "Arsura",
            "Asău",
            "Aşchileu Dorna",
            "Aspra",
            "Aştileu",
            "Asuaju de Jos",
            "Asuaju de Sus",
            "Aţel",
            "Atid",
            "Ațintiș",
            "Augustin",
            "Aurel Vlaicu",
            "Auşeu",
            "Avram Iancu",
            "Avram Iancu",
            "Avrămeni",
            "Avrămești",
            "Avrămeşti",
            "Avrig",
            "Axente Sever",
            "Axintele",
            "Azuga",
            "Baba",
            "Baba Ana",
            "Babadag",
            "Băbăiţa",
            "Băbana",
            "Băbeni",
            "Băbeni",
            "Băbeni",
            "Băbeni-Oltețu",
            "Babeţi",
            "Băbiciu",
            "Babța",
            "Băcălești",
            "Băcani",
            "Bacău",
            "Bacea",
            "Băceşti",
            "Băcia",
            "Băcioiu",
            "Baciu",
            "Baciu",
            "Bâcleș",
            "Bacova",
            "Bâcu",
            "Bădeana",
            "Bădeni",
            "Bădeni",
            "Bădești",
            "Bădeuți",
            "Bădoși",
            "Băduleasa",
            "Băgaciu",
            "Bahna",
            "Bahnea",
            "Baia",
            "Baia",
            "Baia de Aramă",
            "Baia de Arieş",
            "Baia de Criş",
            "Baia de Fier",
            "Baia Mare",
            "Baia Sprie",
            "Băicoi",
            "Băiculeşti",
            "Băile Borșa",
            "Băile Drânceni",
            "Băile Govora",
            "Băile Herculane",
            "Băile Olăneşti",
            "Băile Tuşnad",
            "Băileşti",
            "Băișești",
            "Băişoara",
            "Băița",
            "Băiţa",
            "Băița",
            "Băiţa de sub Codru",
            "Băiuţ",
            "Băjești",
            "Bajura",
            "Băla",
            "Bala",
            "Bălăbănești",
            "Bălăceana",
            "Bălăceanca",
            "Bălăceanu",
            "Balaci",
            "Bălăciţa",
            "Balaciu",
            "Bălan",
            "Bălan",
            "Bălănești",
            "Bălăşeşti",
            "Bălăușeri",
            "Balc",
            "Bălcaciu",
            "Balcani",
            "Bălcăuţi",
            "Bălceşti",
            "Bălcești",
            "Balda",
            "Bâldana",
            "Baldovineşti",
            "Baldovinești",
            "Băleni",
            "Băleni Sârbi",
            "Băleşti",
            "Băleşti",
            "Bălileşti",
            "Balinţ",
            "Balintești",
            "Bălnaca",
            "Baloteşti",
            "Balş",
            "Balș",
            "Balşa",
            "Bâlta",
            "Bâlta",
            "Balta",
            "Balta Albă",
            "Balta Doamnei",
            "Balta Verde",
            "Bălţăteşti",
            "Bălţaţi",
            "Bâlteni",
            "Bălteni",
            "Bălteni",
            "Bălteni",
            "Bălțești",
            "Băluşeni",
            "Bălușești",
            "Bâlvăneşti",
            "Banca",
            "Bancu",
            "Band",
            "Băneasa",
            "Băneasa",
            "Băneasa",
            "Băneasa",
            "Băneşti",
            "Bănești",
            "Bănia",
            "Bănişor",
            "Băniţa",
            "Banloc",
            "Bâra",
            "Bâra",
            "Bara",
            "Bărăbanț",
            "Bărăganu",
            "Bărăganul",
            "Baranca",
            "Baraolt",
            "Bărăști",
            "Bărăștii de Vede",
            "Barați",
            "Bărbăteşti",
            "Bărbăteşti",
            "Bărboși",
            "Bărbulești",
            "Bărbuleţu",
            "Bârca",
            "Bărcăneşti",
            "Bărcăneşti",
            "Bărcăneşti",
            "Barcani",
            "Barcea",
            "Bărdești",
            "Bârgău",
            "Bârgăuani",
            "Bârghiş",
            "Bârla",
            "Bârlad",
            "Bârlibaș",
            "Bârlibășoaia",
            "Bârlogu",
            "Bârna",
            "Bârnova",
            "Bârsa",
            "Bârsana",
            "Bârsăneşti",
            "Bârsău de Sus",
            "Bârseşti",
            "Barticești",
            "Baru",
            "Baru Mic",
            "Bârza",
            "Barza",
            "Barza",
            "Bârzava",
            "Bârzești",
            "Basarabi",
            "Basarabi",
            "Bâsca Chiojdului",
            "Bâsca Rozilei",
            "Bâscenii de Jos",
            "Bâscenii de Sus",
            "Başcov",
            "Bâscoveni",
            "Băseşti",
            "Bata",
            "Băţanii Mari",
            "Batăr",
            "Batârăşti",
            "Bătarci",
            "Bătești",
            "Batoș",
            "Bătrâna",
            "Bătrâni",
            "Băuţar",
            "Bazna",
            "Beba Veche",
            "Beceni",
            "Bechet",
            "Becicherecu Mic",
            "Beciu",
            "Beclean",
            "Beclean",
            "Bedeni",
            "Beica de Jos",
            "Beica de Sus",
            "Beidaud",
            "Beiu",
            "Beiuş",
            "Belceşti",
            "Belciugatele",
            "Beleţi",
            "Belin",
            "Belin-Vale",
            "Belinţ",
            "Beliş",
            "Beliu",
            "Beltiug",
            "Benesat",
            "Bengești",
            "Berbeşti",
            "Berbești",
            "Berca",
            "Berceni",
            "Berceni",
            "Berchez",
            "Berchișești",
            "Beregsău Mare",
            "Bereni",
            "Berești",
            "Berești",
            "Bereşti-Bistriţa",
            "Bereşti-Sat",
            "Bereşti-Tazlău",
            "Berevoeşti",
            "Berezeni",
            "Berghia",
            "Berghin",
            "Berința",
            "Berislăveşti",
            "Beriu",
            "Berleşti",
            "Berlişte",
            "Bernadea",
            "Bertea",
            "Berteştii de Jos",
            "Berveni",
            "Berzasca",
            "Berzovia",
            "Berzunţi",
            "Beștepe",
            "Bethausen",
            "Beu",
            "Beuca",
            "Bezdead",
            "Bezid",
            "Beznea",
            "Bicașu",
            "Bicaz",
            "Bicaz",
            "Bicaz-Chei",
            "Bicazu Ardelean",
            "Bichigiu",
            "Bichiș",
            "Biertan",
            "Biharia",
            "Biia",
            "Bijghir",
            "Bilbor",
            "Bilca",
            "Bilciureşti",
            "Biled",
            "Biliești",
            "Birchiş",
            "Bircii",
            "Birda",
            "Bisoca",
            "Bistra",
            "Bistra",
            "Bistra",
            "Bistra Mureșului",
            "Bistreț",
            "Bistriţa",
            "Bistrița",
            "Bistrița",
            "Bistrița",
            "Bistriţa Bârgăului",
            "Bivolari",
            "Bivolărie",
            "Bixad",
            "Bixad",
            "Blăgeşti",
            "Blăgeşti",
            "Blaj",
            "Blăjani",
            "Blăjel",
            "Blăjeni",
            "Blândeşti",
            "Blândiana",
            "Blânzi",
            "Blejeşti",
            "Blejoi",
            "Blidari",
            "Blideşti",
            "Blidireasa",
            "Bobâlna",
            "Bobiceşti",
            "Boboc",
            "Bobohalma",
            "Boboiești",
            "Bobolia",
            "Bobota",
            "Bobulești",
            "Bocicoel",
            "Bocicoiu Mare",
            "Bocşa",
            "Bocşa",
            "Bocsig",
            "Bod",
            "Bodeşti",
            "Bodești",
            "Bodeștii de Jos",
            "Bodoc",
            "Bogata",
            "Bogata",
            "Bogaţi",
            "Bogda",
            "Bogdan Vodă",
            "Bogdana",
            "Bogdana",
            "Bogdand",
            "Bogdăneşti",
            "Bogdăneşti",
            "Bogdăneşti",
            "Bogdănești",
            "Bogdăniţa",
            "Bogei",
            "Bogheşti",
            "Boghicea",
            "Boghiș",
            "Bogza",
            "Bohotin",
            "Boian",
            "Boianu Mare",
            "Boiereni",
            "Boinești",
            "Boişoara",
            "Boița",
            "Boiu",
            "Boiu Mare",
            "Bolătău",
            "Bolboşi",
            "Boldeşti",
            "Boldeşti-Scăeni",
            "Boldu",
            "Boldur",
            "Bolintin Deal",
            "Bolintin Vale",
            "Bolintineni",
            "Bologaia",
            "Boloteşti",
            "Bolovăniș",
            "Bolvașnița",
            "Bontăieni",
            "Bonțida",
            "Borănești",
            "Borăscu",
            "Borca",
            "Borcea",
            "Borcut",
            "Bord",
            "Bordeasca Veche",
            "Bordei Verde",
            "Bordenii Mari",
            "Bordeşti",
            "Bordoșiu",
            "Borduşani",
            "Borleşti",
            "Borlova",
            "Boroaia",
            "Borod",
            "Boroşneu Mare",
            "Borosoaia",
            "Borş",
            "Borşa",
            "Borşa",
            "Borsec",
            "Borzia",
            "Bosanci",
            "Bosia",
            "Boşorod",
            "Botei",
            "Boteni",
            "Botești",
            "Boţeşti",
            "Boţeşti",
            "Botez",
            "Botiz",
            "Botiza",
            "Botorca",
            "Botoroaga",
            "Botoşana",
            "Botoşani",
            "Botoşeşti-Paia",
            "Boureni",
            "Boureni",
            "Bozânta Mare",
            "Bozânta Mică",
            "Bozed",
            "Bozeni",
            "Bozieni",
            "Bozioru",
            "Bozovici",
            "Brabeți",
            "Brabova",
            "Brad",
            "Brădeanu",
            "Brădeni",
            "Brădeşti",
            "Brădeşti",
            "Brădețelu",
            "Brădicești",
            "Bradu",
            "Bradu",
            "Brăduleţ",
            "Brăduţ",
            "Brăeşti",
            "Brăeşti",
            "Brăeşti",
            "Bragadiru",
            "Bragadiru",
            "Brăhășești",
            "Brăhăşeştii de Sus",
            "Brăiești",
            "Brăila",
            "Braloştiţa",
            "Bran",
            "Brânceni",
            "Brâncoveanca",
            "Brâncovenești",
            "Brâncoveni",
            "Brăneşti",
            "Brăneşti",
            "Brăneşti",
            "Braneț",
            "Brănişca",
            "Brăniștari",
            "Braniştea",
            "Braniştea",
            "Braniştea",
            "Braniștea",
            "Braniștea",
            "Braşov",
            "Brastavățu",
            "Bratca",
            "Brateiu",
            "Brateş",
            "Brătești",
            "Brătești",
            "Brătila",
            "Bratovoești",
            "Brazi",
            "Brazii",
            "Brazii de Sus",
            "Breasta",
            "Breaza",
            "Breaza",
            "Breaza",
            "Breaza",
            "Breaza",
            "Breaza de Jos",
            "Breaza de Sus",
            "Breazu",
            "Breb",
            "Brebeni",
            "Brebeni",
            "Brebu",
            "Brebu Mânăstirei",
            "Brebu Megieșesc",
            "Brebu Nou",
            "Brehuiești",
            "Brestovăț",
            "Breţcu",
            "Bretea Română",
            "Brezniţa Ocol",
            "Brezniţa-Motru",
            "Brezoaele",
            "Brezoaia",
            "Brezoi",
            "Brodina",
            "Brodoc",
            "Broscăuţi",
            "Broşteni",
            "Broşteni",
            "Broşteni",
            "Broșteni",
            "Broșteni",
            "Bruiu",
            "Brusturi",
            "Brusturi",
            "Brusturoasa",
            "Bucecea",
            "Bucerdea-Grânoasă",
            "Buceş",
            "Bucești",
            "Bucharest",
            "Buchin",
            "Bucinişu",
            "Bucium",
            "Bucium-Orlea",
            "Buciumeni",
            "Buciumeni",
            "Buciumeni",
            "Buciumi",
            "Buciumi",
            "Buciumi",
            "Bucoşniţa",
            "Bucov",
            "Bucova",
            "Bucovăţ",
            "Bucovăț",
            "Bucovineni",
            "Bucşani",
            "Bucşani",
            "Bucșenești",
            "Bucșenești-Lotași",
            "Bucșești",
            "Bucșoaia",
            "Bucu",
            "Bucureşci",
            "Buda",
            "Buda",
            "Buda",
            "Buda",
            "Buda",
            "Budacu de Jos",
            "Budacu de Sus",
            "Budăi",
            "Budeasa Mică",
            "Budeni",
            "Budeşti",
            "Budeşti",
            "Budeşti",
            "Budeşti",
            "Budești",
            "Budești",
            "Budila",
            "Budiu Mic",
            "Budureasa",
            "Buduslău",
            "Buești",
            "Buftea",
            "Bughea de Jos",
            "Bughea de Jos",
            "Bughea de Sus",
            "Buhalnița",
            "Buhoci",
            "Buhuşi",
            "Bujor",
            "Bujor-Hodaie",
            "Bujoreni",
            "Bujoreni",
            "Bujoru",
            "Bulbucata",
            "Bulgăruș",
            "Buliga",
            "Bulz",
            "Bulzeşti",
            "Bulzeștii de Sus",
            "Bumbeşti-Jiu",
            "Buneşti",
            "Buneşti",
            "Buneşti",
            "Bunești",
            "Bungetu",
            "Bunila",
            "Bunteşti",
            "Burca",
            "Buriaș",
            "Burila Mare",
            "Burjuc",
            "Burla",
            "Bursuceni",
            "Burueneşti",
            "Buruienești",
            "Burzuc",
            "Bușag",
            "Bușca",
            "Buşteni",
            "Bustuchin",
            "Butea",
            "Buteasa",
            "Buteni",
            "Butimanu",
            "Butoieşti",
            "Buturugeni",
            "Buza",
            "Buzău",
            "Buzescu",
            "Buzești",
            "Buziaş",
            "Buznea",
            "Buzoeşti",
            "C.A. Rosetti",
            "C.a. Rosetti",
            "Căbeşti",
            "Cacica",
            "Căciulata",
            "Căciulați",
            "Căcuciu",
            "Cadea",
            "Căianu",
            "Căianu Mic",
            "Căianu Mic",
            "Câinenii Mici",
            "Căiuți",
            "Căiuţi-Sat",
            "Cajvana",
            "Călacea",
            "Calafat",
            "Calafindeşti",
            "Călan",
            "Călăraşi",
            "Călăraşi",
            "Călăraşi",
            "Călăraşi",
            "Călăţele",
            "Căldăraru",
            "Căldăraru",
            "Căldărăști",
            "Călimăneşti",
            "Călimănești",
            "Călina",
            "Călineşti",
            "Călineşti",
            "Călineşti",
            "Călinești",
            "Călinești",
            "Călineşti-Oaş",
            "Călmăţuiu",
            "Călmățuiu de Sus",
            "Câlnic",
            "Câlnic",
            "Câlnic",
            "Calomfirești",
            "Calopăr",
            "Călugăreni",
            "Călugăreni",
            "Călugăreni",
            "Călui",
            "Călușeri",
            "Calvini",
            "Camăr",
            "Cămăraşu",
            "Cămărzana",
            "Cămin",
            "Câmpani",
            "Câmpani de Pomezeu",
            "Câmpeni",
            "Câmpenița",
            "Câmpia Turzii",
            "Câmpina",
            "Câmpineanca",
            "Câmpu Cetății",
            "Câmpulung",
            "Câmpulung la Tisa",
            "Câmpulung Moldovenesc",
            "Câmpurelu",
            "Câmpuri",
            "Câmpurile de Jos",
            "Cândeşti",
            "Cândeşti",
            "Cândeşti Vale",
            "Cându",
            "Căneşti",
            "Căpâlna",
            "Căpâlna de Sus",
            "Căpâlniţa",
            "Căpățânenii Pământeni",
            "Căpățânești",
            "Căpeni",
            "Căpeți",
            "Căpleni",
            "Caporal Alexa",
            "Căpreni",
            "Căprioru",
            "Capu Câmpului",
            "Capu Codrului",
            "Capu Piscului",
            "Căpușu de Câmpie",
            "Căpuşu Mare",
            "Caracal",
            "Caraclău",
            "Cărand",
            "Carani",
            "Caransebeş",
            "Cărășeu",
            "Caraşova",
            "Carastelec",
            "Caraula",
            "Cărbunari",
            "Cărbunari",
            "Cărbuneşti",
            "Carcaliu",
            "Cârcea",
            "Carei",
            "Cârja",
            "Cârjiţi",
            "Cârjoaia",
            "Cârlibaba",
            "Cârligele",
            "Cârligi",
            "Cârlogani",
            "Cârna",
            "Cârniceni",
            "Carpen",
            "Cărpinet",
            "Cărpiniș",
            "Cărpiniş",
            "Cărpiniș",
            "Cârţa",
            "Cârţa",
            "Cârţişoara",
            "Cartojani",
            "Căscioarele",
            "Căscioarele",
            "Căşeiu",
            "Casimcea",
            "Caşin",
            "Cașoca",
            "Căstău",
            "Castelu",
            "Castranova",
            "Castrele Traiane",
            "Cașva",
            "Caţa",
            "Cătălina",
            "Catalina",
            "Cataloi",
            "Cătămărești-Deal",
            "Catane",
            "Catanele",
            "Câţcău",
            "Căteasca",
            "Cățelu",
            "Cătina",
            "Cătina",
            "Cătina",
            "Cătunele",
            "Căuaş",
            "Cavadineşti",
            "Cavnic",
            "Cazaci",
            "Căzăneşti",
            "Căzăneşti",
            "Cazasu",
            "Ceacu",
            "Ceahlău",
            "Ceamurlia de Jos",
            "Ceamurlia de Sus",
            "Ceanu Mare",
            "Ceardac",
            "Ceatalchioi",
            "Ceauru",
            "Cecălaca",
            "Cefa",
            "Cegani",
            "Cehal",
            "Cehei",
            "Cehu Silvaniei",
            "Ceica",
            "Ceie",
            "Celaru",
            "Cenad",
            "Cenade",
            "Cenei",
            "Cepari",
            "Ceparii Pământeni",
            "Cepleniţa",
            "Ceptura de Jos",
            "Ceptura de Sus",
            "Ceraşu",
            "Cerăt",
            "Cerbăl",
            "Cerchezu",
            "Cerdac",
            "Cergău Mare",
            "Cergău Mic",
            "Cerghid",
            "Cerghizel",
            "Cermei",
            "Cerna",
            "Cernat",
            "Cernăteşti",
            "Cernăteşti",
            "Cernavodă",
            "Cernele",
            "Cerneşti",
            "Cerneți",
            "Cernetu",
            "Cernica",
            "Cernişoara",
            "Cernu",
            "Certeju de Sus",
            "Cerţeşti",
            "Certeze",
            "Ceru-Băcăinţi",
            "Cervenia",
            "Cetariu",
            "Cetate",
            "Cetatea",
            "Cetatea de Baltă",
            "Cetățele",
            "Cetăţeni",
            "Ceuaș",
            "Ceuașu de Câmpie",
            "Cezieni",
            "Checea",
            "Chechiș",
            "Cheia",
            "Chelința",
            "Chendrea",
            "Chendu",
            "Cherechiu",
            "Chereluș",
            "Cheșereu",
            "Chesinț",
            "Cheț",
            "Chețani",
            "Chevereşu Mare",
            "Chiajna",
            "Chibed",
            "Chichiş",
            "Chier",
            "Chieşd",
            "Chiheru de Jos",
            "Chiheru de Sus",
            "Chilia Veche",
            "Chilii",
            "Chiliile",
            "Chinari",
            "Chinciuș",
            "Chinteni",
            "Chintinici",
            "Chiochiş",
            "Chiojdeanca",
            "Chiojdeni",
            "Chiojdu",
            "Chiraftei",
            "Chircești",
            "Chiriacu",
            "Chirileu",
            "Chirnogeni",
            "Chirnogi",
            "Chirpăr",
            "Chisălița",
            "Chiscani",
            "Chișcăreni",
            "Chiselet",
            "Chisindia",
            "Chişineu-Criş",
            "Chişlaz",
            "Chișoda",
            "Chitila",
            "Chițoc",
            "Chițorani",
            "Chiuiești",
            "Chiuza",
            "Chiuzbaia",
            "Ciacova",
            "Ciba",
            "Cibu",
            "Cicănești",
            "Cicârlău",
            "Ciceu",
            "Ciceu-Giurgeşti",
            "Ciceu-Mihăiești",
            "Ciclova-Română",
            "Cidreag",
            "Cilibia",
            "Cilieni",
            "Cincu",
            "Cinta",
            "Cintei",
            "Cioarga",
            "Ciobanu",
            "Ciobotani",
            "Ciocănari",
            "Ciocăneşti",
            "Ciocăneşti",
            "Ciocănești",
            "Ciocani",
            "Ciocârlia",
            "Ciocârlia",
            "Ciocârlia de Sus",
            "Ciochina",
            "Ciochiuța",
            "Ciocile",
            "Ciocotiș",
            "Ciofliceni",
            "Ciofrângeni",
            "Ciohorăni",
            "Ciolpani",
            "Ciolt",
            "Ciomăgeşti",
            "Ciorani",
            "Cioranii de Jos",
            "Cioranii de Sus",
            "Ciorăşti",
            "Ciorăști",
            "Cioroboreni",
            "Ciorogârla",
            "Cioroiași",
            "Ciorteşti",
            "Cipaieni",
            "Cipău",
            "Ciprian Porumbescu",
            "Cireșoaia",
            "Cireșoaia",
            "Cireşu",
            "Cireşu",
            "Ciretea",
            "Cirhagău",
            "Cislău",
            "Cișmele",
            "Cisnădie",
            "Ciuani",
            "Ciucani",
            "Ciucea",
            "Ciuchici",
            "Ciucsângeorgiu",
            "Ciucurova",
            "Ciudanoviţa",
            "Ciugheș",
            "Ciugud",
            "Ciulea",
            "Ciulniţa",
            "Ciulnița",
            "Ciumani",
            "Ciumbrud",
            "Ciumeghiu",
            "Ciumești",
            "Ciuperceni",
            "Ciuperceni",
            "Ciupercenii Noi",
            "Ciupercenii Vechi",
            "Ciurea",
            "Ciurgău",
            "Ciurila",
            "Ciuruleasa",
            "Ciușlea",
            "Ciuta",
            "Ciutelec",
            "Cizer",
            "Cleanov",
            "Cleja",
            "Clejani",
            "Clinceni",
            "Clit",
            "Cloașterf",
            "Clocotici",
            "Cloșani",
            "Cluj-Napoca",
            "Coada Izvorului",
            "Coarnele Caprei",
            "Coaș",
            "Coasta Grindului",
            "Coasta Mare",
            "Cobadin",
            "Cochirleanca",
            "Cociu",
            "Cociuba Mare",
            "Coconi",
            "Cocora",
            "Cocorăștii Colț",
            "Cocorăștii Mislii",
            "Cocoreni",
            "Cocu",
            "Codăeşti",
            "Codlea",
            "Codru Butesii",
            "Cogealac",
            "Cogeasca",
            "Cojasca",
            "Cojocna",
            "Colacu",
            "Colacu",
            "Colceag",
            "Colelia",
            "Colibaşi",
            "Colibași",
            "Coloneşti",
            "Coloneşti",
            "Colonia Bod",
            "Colonia Fabricii",
            "Coltău",
            "Colţi",
            "Colțirea",
            "Comana",
            "Comana",
            "Comana de Jos",
            "Comana de Sus",
            "Comanca",
            "Comanda",
            "Comandău",
            "Comăneşti",
            "Comănești",
            "Comani",
            "Comarna",
            "Comarnic",
            "Comişani",
            "Comloşu Mare",
            "Comori",
            "Comoșteni",
            "Comuna 1 Decembrie",
            "Comuna 23 August",
            "Comuna Abram",
            "Comuna Abrămuţ",
            "Comuna Acâş",
            "Comuna Adamclisi",
            "Comuna Adâncata",
            "Comuna Adâncata",
            "Comuna Adăşeni",
            "Comuna Adunaţi",
            "Comuna Adunaţii-Copăceni",
            "Comuna Afumaţi",
            "Comuna Afumaţi",
            "Comuna Agapia",
            "Comuna Agăş",
            "Comuna Aghireşu",
            "Comuna Agigea",
            "Comuna Agrij",
            "Comuna Agriş",
            "Comuna Aita Mare",
            "Comuna Aiton",
            "Comuna Albac",
            "Comuna Albeni",
            "Comuna Albeşti",
            "Comuna Albeşti",
            "Comuna Albeşti",
            "Comuna Albeşti",
            "Comuna Albeşti-Paleologu",
            "Comuna Albeștii de Argeș",
            "Comuna Albeștii de Muscel",
            "Comuna Albota",
            "Comuna Alexandru Cel Bun",
            "Comuna Alexandru I. Cuza",
            "Comuna Alexandru Odobescu",
            "Comuna Alexandru Vlăhuţă",
            "Comuna Alexeni",
            "Comuna Aliman",
            "Comuna Alimpeşti",
            "Comuna Alma",
            "Comuna Almãj",
            "Comuna Almaş",
            "Comuna Almaşu",
            "Comuna Almaşu Mare",
            "Comuna Alțâna",
            "Comuna Aluniş",
            "Comuna Aluniş",
            "Comuna Alunu",
            "Comuna Amărăşti",
            "Comuna Amărăştii de Jos",
            "Comuna Amărăştii de Sus",
            "Comuna Amaru",
            "Comuna Amzacea",
            "Comuna Andrăşeşti",
            "Comuna Andreiaşu de Jos",
            "Comuna Andrid",
            "Comuna Andrieşeni",
            "Comuna Aninoasa",
            "Comuna Aninoasa",
            "Comuna Aninoasa",
            "Comuna Apa",
            "Comuna Apahida",
            "Comuna Apaţa",
            "Comuna Apateu",
            "Comuna Apele Vii",
            "Comuna Apoldu de Jos",
            "Comuna Apostolache",
            "Comuna Arbore",
            "Comuna Arcani",
            "Comuna Archiş",
            "Comuna Arcuş",
            "Comuna Ardeoani",
            "Comuna Ardusat",
            "Comuna Arefu",
            "Comuna Argetoaia",
            "Comuna Ariceştii Zeletin",
            "Comuna Ariceştii-Rahtivani",
            "Comuna Arieşeni",
            "Comuna Ariniş",
            "Comuna Armăşeşti",
            "Comuna Armeniş",
            "Comuna Aroneanu",
            "Comuna Arpaşu de Jos",
            "Comuna Arsura",
            "Comuna Asău",
            "Comuna Aşchileu",
            "Comuna Aştileu",
            "Comuna Asuaju de Sus",
            "Comuna Aţel",
            "Comuna Atid",
            "Comuna Augustin",
            "Comuna Auşeu",
            "Comuna Avram Iancu",
            "Comuna Avram Iancu",
            "Comuna Avrămeni",
            "Comuna Avrămeşti",
            "Comuna Axente Sever",
            "Comuna Axintele",
            "Comuna Baba Ana",
            "Comuna Băbăiţa",
            "Comuna Băbana",
            "Comuna Băbeni",
            "Comuna Băbiciu",
            "Comuna Băcani",
            "Comuna Băceşti",
            "Comuna Băcia",
            "Comuna Baciu",
            "Comuna Bâcleş",
            "Comuna Bahna",
            "Comuna Baia",
            "Comuna Baia",
            "Comuna Baia de Criş",
            "Comuna Baia de Fier",
            "Comuna Băiculeşti",
            "Comuna Băişoara",
            "Comuna Băiţa",
            "Comuna Băiţa de sub Codru",
            "Comuna Băiuţ",
            "Comuna Bala",
            "Comuna Bălăbăneşti",
            "Comuna Bălăceana",
            "Comuna Bălăceanu",
            "Comuna Balaci",
            "Comuna Bălăciţa",
            "Comuna Balaciu",
            "Comuna Bălan",
            "Comuna Bălăneşti",
            "Comuna Bălăşeşti",
            "Comuna Balc",
            "Comuna Balcani",
            "Comuna Bălcăuţi",
            "Comuna Baldovineşti",
            "Comuna Băleni",
            "Comuna Băleni Sârbi",
            "Comuna Băleşti",
            "Comuna Băleşti",
            "Comuna Bălileşti",
            "Comuna Balinţ",
            "Comuna Baloteşti",
            "Comuna Balş",
            "Comuna Balşa",
            "Comuna Balta",
            "Comuna Balta Albă",
            "Comuna Balta Doamnei",
            "Comuna Bălţăteşti",
            "Comuna Bălţaţi",
            "Comuna Bâlteni",
            "Comuna Bălteni",
            "Comuna Bălteni",
            "Comuna Bălţeşti",
            "Comuna Băluşeni",
            "Comuna Bâlvăneşti",
            "Comuna Banca",
            "Comuna Băneasa",
            "Comuna Băneasa",
            "Comuna Băneşti",
            "Comuna Bănia",
            "Comuna Bănişor",
            "Comuna Băniţa",
            "Comuna Banloc",
            "Comuna Bâra",
            "Comuna Bara",
            "Comuna Bărăganu",
            "Comuna Bărăganul",
            "Comuna Bãrãşti",
            "Comuna Bărbăteşti",
            "Comuna Bărbăteşti",
            "Comuna Bărbuleşti",
            "Comuna Bărbuleţu",
            "Comuna Bârca",
            "Comuna Bărcăneşti",
            "Comuna Bărcăneşti",
            "Comuna Barcani",
            "Comuna Barcea",
            "Comuna Bârgãuani",
            "Comuna Bârghiş",
            "Comuna Bârla",
            "Comuna Bârna",
            "Comuna Bârnova",
            "Comuna Bârsa",
            "Comuna Bârsana",
            "Comuna Bârsăneşti",
            "Comuna Bârsău",
            "Comuna Bârseşti",
            "Comuna Baru",
            "Comuna Bârza",
            "Comuna Bârzava",
            "Comuna Başcov",
            "Comuna Băseşti",
            "Comuna Bata",
            "Comuna Băţani",
            "Comuna Batăr",
            "Comuna Bătarci",
            "Comuna Bătrâna",
            "Comuna Bătrâni",
            "Comuna Băuţar",
            "Comuna Bazna",
            "Comuna Beba Veche",
            "Comuna Beceni",
            "Comuna Becicherecu Mic",
            "Comuna Beciu",
            "Comuna Beclean",
            "Comuna Beidaud",
            "Comuna Belceşti",
            "Comuna Belciugatele",
            "Comuna Beleţi-Negreşti",
            "Comuna Belin",
            "Comuna Belinţ",
            "Comuna Beliş",
            "Comuna Beliu",
            "Comuna Beltiug",
            "Comuna Benesat",
            "Comuna Bengeşti-Ciocadia",
            "Comuna Berca",
            "Comuna Berceni",
            "Comuna Berceni",
            "Comuna Berchişeşti",
            "Comuna Bereşti-Bistriţa",
            "Comuna Bereşti-Meria",
            "Comuna Bereşti-Tazlău",
            "Comuna Berevoeşti",
            "Comuna Berezeni",
            "Comuna Berghin",
            "Comuna Berislăveşti",
            "Comuna Beriu",
            "Comuna Berlişte",
            "Comuna Bertea",
            "Comuna Berteştii de Jos",
            "Comuna Berveni",
            "Comuna Berzasca",
            "Comuna Berzovia",
            "Comuna Berzunţi",
            "Comuna Beştepe",
            "Comuna Bethausen",
            "Comuna Beuca",
            "Comuna Bezdead",
            "Comuna Bicaz",
            "Comuna Bicaz Chei",
            "Comuna Bicazu Ardelean",
            "Comuna Biertan",
            "Comuna Biharia",
            "Comuna Bilbor",
            "Comuna Bilca",
            "Comuna Bilciureşti",
            "Comuna Biled",
            "Comuna Bilieşti",
            "Comuna Birchiş",
            "Comuna Birda",
            "Comuna Bisoca",
            "Comuna Bistra",
            "Comuna Bistra",
            "Comuna Bistreţ",
            "Comuna Bistriţa Bârgăului",
            "Comuna Bivolari",
            "Comuna Bixad",
            "Comuna Bixad",
            "Comuna Blăgeşti",
            "Comuna Blăgeşti",
            "Comuna Blăjani",
            "Comuna Blăjel",
            "Comuna Blăjeni",
            "Comuna Blândeşti",
            "Comuna Blândiana",
            "Comuna Blejeşti",
            "Comuna Blejoi",
            "Comuna Bobâlna",
            "Comuna Bobiceşti",
            "Comuna Bobota",
            "Comuna Bocicoiu Mare",
            "Comuna Bocşa",
            "Comuna Bocsig",
            "Comuna Bod",
            "Comuna Bodeşti-Precista",
            "Comuna Bodoc",
            "Comuna Bogaţi",
            "Comuna Bogda",
            "Comuna Bogdan Vodă",
            "Comuna Bogdana",
            "Comuna Bogdana",
            "Comuna Bogdand",
            "Comuna Bogdăneşti",
            "Comuna Bogdăneşti",
            "Comuna Bogdăneşti",
            "Comuna Bogdăniţa",
            "Comuna Bogheşti",
            "Comuna Boghicea",
            "Comuna Boghiş",
            "Comuna Boianu Mare",
            "Comuna Boişoara",
            "Comuna Boiţa",
            "Comuna Boiu Mare",
            "Comuna Bolboşi",
            "Comuna Boldeşti-Gradiştea",
            "Comuna Boldu",
            "Comuna Boldur",
            "Comuna Bolintin Deal",
            "Comuna Boloteşti",
            "Comuna Bolvaşniţa",
            "Comuna Bonţida",
            "Comuna Borăneşti",
            "Comuna Borăscu",
            "Comuna Borca",
            "Comuna Borcea",
            "Comuna Bordei Verde",
            "Comuna Bordeşti",
            "Comuna Borduşani",
            "Comuna Borleşti",
            "Comuna Boroaia",
            "Comuna Borod",
            "Comuna Boroşneu Mare",
            "Comuna Borş",
            "Comuna Borşa",
            "Comuna Bosanci",
            "Comuna Boşorod",
            "Comuna Boteni",
            "Comuna Boteşti",
            "Comuna Boţeşti",
            "Comuna Boţeşti",
            "Comuna Botiz",
            "Comuna Botiza",
            "Comuna Botoroaga",
            "Comuna Botoşana",
            "Comuna Botoşeşti-Paia",
            "Comuna Bozieni",
            "Comuna Bozioru",
            "Comuna Bozovici",
            "Comuna Brabova",
            "Comuna Brădeanu",
            "Comuna Brădeni",
            "Comuna Brădeşti",
            "Comuna Brădeşti",
            "Comuna Bradu",
            "Comuna Brăduleţ",
            "Comuna Brăduţ",
            "Comuna Brăeşti",
            "Comuna Brăeşti",
            "Comuna Brăeşti",
            "Comuna Bragadiru",
            "Comuna Brăhăşeşti",
            "Comuna Braloştiţa",
            "Comuna Bran",
            "Comuna Brânceni",
            "Comuna Brâncoveni",
            "Comuna Brăneşti",
            "Comuna Brăneşti",
            "Comuna Brăneşti",
            "Comuna Brănişca",
            "Comuna Braniştea",
            "Comuna Braniştea",
            "Comuna Braniştea",
            "Comuna Braniştea",
            "Comuna Brastavăţu",
            "Comuna Bratca",
            "Comuna Brateiu",
            "Comuna Brateş",
            "Comuna Bratovoeşti",
            "Comuna Brazi",
            "Comuna Brazii",
            "Comuna Breasta",
            "Comuna Breaza",
            "Comuna Breaza",
            "Comuna Brebeni",
            "Comuna Brebu",
            "Comuna Brebu",
            "Comuna Brebu Nou",
            "Comuna Brestovăţ",
            "Comuna Breţcu",
            "Comuna Bretea Română",
            "Comuna Brezniţa Ocol",
            "Comuna Brezniţa-Motru",
            "Comuna Brezoaele",
            "Comuna Brodina",
            "Comuna Broscăuţi",
            "Comuna Broşteni",
            "Comuna Broşteni",
            "Comuna Bruiu",
            "Comuna Brusturi",
            "Comuna Brusturi-Drăgăneşti",
            "Comuna Brusturoasa",
            "Comuna Bucerdea-Grânoasă",
            "Comuna Buceş",
            "Comuna Buchin",
            "Comuna Bucinişu",
            "Comuna Bucium",
            "Comuna Buciumeni",
            "Comuna Buciumeni",
            "Comuna Buciumi",
            "Comuna Buciumi",
            "Comuna Bucoşniţa",
            "Comuna Bucov",
            "Comuna Bucovăţ",
            "Comuna Bucovăţ",
            "Comuna Bucşani",
            "Comuna Bucşani",
            "Comuna Bucu",
            "Comuna Bucureşci",
            "Comuna Buda",
            "Comuna Budacu de Jos",
            "Comuna Budeasa",
            "Comuna Budeşti",
            "Comuna Budeşti",
            "Comuna Budeşti",
            "Comuna Budila",
            "Comuna Budureasa",
            "Comuna Buduslău",
            "Comuna Bueşti",
            "Comuna Bughea de Jos",
            "Comuna Bughea de Sus",
            "Comuna Buhoci",
            "Comuna Bujoreni",
            "Comuna Bujoreni",
            "Comuna Bujoru",
            "Comuna Bulbucata",
            "Comuna Bulz",
            "Comuna Bulzeşti",
            "Comuna Bulzeştii De Sus",
            "Comuna Bumbeşti-Piţic",
            "Comuna Buneşti",
            "Comuna Buneşti",
            "Comuna Buneşti",
            "Comuna Bunești Averești",
            "Comuna Bunila",
            "Comuna Bunteşti",
            "Comuna Burila Mare",
            "Comuna Burjuc",
            "Comuna Burla",
            "Comuna Bustuchin",
            "Comuna Butea",
            "Comuna Buteni",
            "Comuna Butimanu",
            "Comuna Butoieşti",
            "Comuna Buturugeni",
            "Comuna Buza",
            "Comuna Buzescu",
            "Comuna Buzoeşti",
            "Comuna C.A. Rosetti",
            "Comuna C.A. Rosetti",
            "Comuna Căbeşti",
            "Comuna Cacica",
            "Comuna Căianu",
            "Comuna Căianu",
            "Comuna Câineni",
            "Comuna Căiuţi",
            "Comuna Calafindeşti",
            "Comuna Călăraşi",
            "Comuna Călăraşi",
            "Comuna Călăraşi",
            "Comuna Călăţele",
            "Comuna Căldăraru",
            "Comuna Călineşti",
            "Comuna Călineşti",
            "Comuna Călineşti",
            "Comuna Cãlineşti-Oaş",
            "Comuna Cãlmãţuiu",
            "Comuna Cãlmãţuiu De Sus",
            "Comuna Câlnic",
            "Comuna Câlnic",
            "Comuna Calopăru",
            "Comuna Călugăreni",
            "Comuna Călugăreni",
            "Comuna Cãlui",
            "Comuna Calvini",
            "Comuna Camăr",
            "Comuna Cămăraşu",
            "Comuna Cămărzana",
            "Comuna Cãmin",
            "Comuna Câmpani",
            "Comuna Câmpineanca",
            "Comuna Câmpulung la Tisa",
            "Comuna Câmpuri",
            "Comuna Cândeşti",
            "Comuna Cândeşti",
            "Comuna Cândeşti Vale",
            "Comuna Căneşti",
            "Comuna Cãpâlna",
            "Comuna Căpâlniţa",
            "Comuna Căpleni",
            "Comuna Căpreni",
            "Comuna Capu Câmpului",
            "Comuna Cãpuşu Mare",
            "Comuna Cărand",
            "Comuna Caraşova",
            "Comuna Carastelec",
            "Comuna Caraula",
            "Comuna Cărbunari",
            "Comuna Cărbuneşti",
            "Comuna Carcaliu",
            "Comuna Cârcea",
            "Comuna Cârjiţi",
            "Comuna Cârlibaba",
            "Comuna Cârligele",
            "Comuna Cârlogani",
            "Comuna Cârna",
            "Comuna Carpen",
            "Comuna Cărpinet",
            "Comuna Cărpiniş",
            "Comuna Cârţa",
            "Comuna Cârţa",
            "Comuna Cârţişoara",
            "Comuna Căscioarele",
            "Comuna Căşeiu",
            "Comuna Casimcea",
            "Comuna Castelu",
            "Comuna Castranova",
            "Comuna Caţa",
            "Comuna Catalina",
            "Comuna Catane",
            "Comuna Câţcău",
            "Comuna Căteasca",
            "Comuna Cătina",
            "Comuna Cătina",
            "Comuna Cătunele",
            "Comuna Căuaş",
            "Comuna Cavadineşti",
            "Comuna Căzăneşti",
            "Comuna Cazasu",
            "Comuna Ceahlău",
            "Comuna Ceamurlia de Jos",
            "Comuna Ceanu Mare",
            "Comuna Ceatalchioi",
            "Comuna Cefa",
            "Comuna Cehal",
            "Comuna Cehu Silvaniei",
            "Comuna Ceica",
            "Comuna Celaru",
            "Comuna Cenad",
            "Comuna Cenade",
            "Comuna Cenei",
            "Comuna Cepari",
            "Comuna Cepleniţa",
            "Comuna Ceptura",
            "Comuna Ceraşu",
            "Comuna Cerãt",
            "Comuna Cerbăl",
            "Comuna Cerchezu",
            "Comuna Cergău",
            "Comuna Cermei",
            "Comuna Cerna",
            "Comună Cernat",
            "Comuna Cernăteşti",
            "Comuna Cernăteşti",
            "Comuna Cerneşti",
            "Comuna Cernica",
            "Comuna Cernişoara",
            "Comuna Certeju de Sus",
            "Comuna Cerţeşti",
            "Comuna Certeze",
            "Comuna Ceru-Băcăinţi",
            "Comuna Cervenia",
            "Comuna Cetariu",
            "Comuna Cetate",
            "Comuna Cetatea de Baltă",
            "Comuna Cetăţeni",
            "Comuna Cezieni",
            "Comuna Checea",
            "Comuna Cherechiu",
            "Comuna Chevereşu Mare",
            "Comuna Chiajna",
            "Comuna Chichiş",
            "Comuna Chieşd",
            "Comuna Chilia Veche",
            "Comuna Chiliile",
            "Comuna Chinteni",
            "Comuna Chiochiş",
            "Comuna Chiojdeanca",
            "Comuna Chiojdeni",
            "Comuna Chiojdu",
            "Comuna Chirnogeni",
            "Comuna Chirnogi",
            "Comuna Chirpăr",
            "Comuna Chiscani",
            "Comuna Chiselet",
            "Comuna Chisindia",
            "Comuna Chişlaz",
            "Comuna Chiuieşti",
            "Comuna Chiuza",
            "Comuna Cicănești",
            "Comuna Cicârlău",
            "Comuna Ciceu",
            "Comuna Ciceu-Giurgeşti",
            "Comuna Ciceu-Mihăieşti",
            "Comuna Ciclova Română",
            "Comuna Cilibia",
            "Comuna Cilieni",
            "Comuna Cincu",
            "Comuna Ciobanu",
            "Comuna Ciocăneşti",
            "Comuna Ciocăneşti",
            "Comuna Ciocăneşti",
            "Comuna Ciocani",
            "Comuna Ciocârlia",
            "Comuna Ciocârlia",
            "Comuna Ciochina",
            "Comuna Ciocile",
            "Comuna Ciofrângeni",
            "Comuna Ciohorãni",
            "Comuna Ciolăneşti",
            "Comuna Ciolpani",
            "Comuna Ciomăgeşti",
            "Comuna Ciorani",
            "Comuna Ciorăşti",
            "Comuna Ciorogârla",
            "Comuna Cioroiaşi",
            "Comuna Ciorteşti",
            "Comuna Ciprian Porumbescu",
            "Comuna Cireşu",
            "Comuna Cireşu",
            "Comuna Cislău",
            "Comuna Ciucea",
            "Comuna Ciuchici",
            "Comuna Ciucsângeorgiu",
            "Comuna Ciucurova",
            "Comuna Ciudanoviţa",
            "Comuna Ciugud",
            "Comuna Ciulniţa",
            "Comuna Ciumani",
            "Comuna Ciumeghiu",
            "Comuna Ciumeşti",
            "Comuna Ciuperceni",
            "Comuna Ciuperceni",
            "Comuna Ciupercenii Noi",
            "Comuna Ciurea",
            "Comuna Ciurila",
            "Comuna Ciuruleasa",
            "Comuna Cizer",
            "Comuna Cleja",
            "Comuna Clejani",
            "Comuna Clinceni",
            "Comuna Coarnele Caprei",
            "Comuna Coaş",
            "Comuna Cobadin",
            "Comuna Cobia de Sus",
            "Comuna Cochirleanca",
            "Comuna Cociuba Mare",
            "Comuna Cocora",
            "Comuna Cocorãştii Mislii",
            "Comuna Cocorăştii-Colţ",
            "Comuna Cocu",
            "Comuna Codăeşti",
            "Comuna Cogealac",
            "Comuna Cojasca",
            "Comuna Cojocna",
            "Comuna Colceag",
            "Comuna Colelia",
            "Comuna Colibaşi",
            "Comuna Coloneşti",
            "Comuna Coloneşti",
            "Comuna Coltãu",
            "Comuna Colţi",
            "Comuna Comana",
            "Comuna Comana",
            "Comuna Comana",
            "Comuna Comandău",
            "Comuna Comăneşti",
            "Comuna Comarna",
            "Comuna Comişani",
            "Comuna Comloşu Mare",
            "Comuna Conceşti",
            "Comuna Conop",
            "Comuna Constantin Daicoviciu",
            "Comuna Conţeşti",
            "Comuna Conţeşti",
            "Comuna Copăcel",
            "Comuna Copăcele",
            "Comuna Copăceni",
            "Comuna Copăceni",
            "Comuna Copălău",
            "Comuna Copalnic Mănăştur",
            "Comuna Corbasca",
            "Comuna Corbeanca",
            "Comuna Corbeni",
            "Comuna Corbi",
            "Comuna Corbii Mari",
            "Comuna Corbiţa",
            "Comuna Corbu",
            "Comuna Corbu",
            "Comuna Corbu",
            "Comuna Corcova",
            "Comună Cordăreni",
            "Comuna Cordun",
            "Comuna Corlăţel",
            "Comuna Corlăteni",
            "Comuna Cornăţelu",
            "Comuna Cornea",
            "Comuna Cornereva",
            "Comuna Corneşti",
            "Comuna Corneşti",
            "Comuna Cornetu",
            "Comuna Corni",
            "Comuna Corni",
            "Comuna Cornu",
            "Comuna Cornu Luncii",
            "Comuna Corod",
            "Comuna Coroieni",
            "Comuna Coroieşti",
            "Comuna Coroisânmartin",
            "Comuna Corund",
            "Comuna Cosâmbeşti",
            "Comuna Coşbuc",
            "Comuna Coşeiu",
            "Comuna Coşereni",
            "Comuna Coşeşti",
            "Comuna Cosmeşti",
            "Comuna Cosmeşti",
            "Comuna Cosminele",
            "Comuna Coşna",
            "Comuna Cosoba",
            "Comuna Coşoveni",
            "Comuna Costache Negri",
            "Comuna Coşteiu",
            "Comuna Costeşti",
            "Comuna Costeşti",
            "Comuna Costeşti",
            "Comuna Costeşti",
            "Comuna Costeştii din Vale",
            "Comuna Costineşti",
            "Comuna Costişa",
            "Comuna Costuleni",
            "Comuna Coşula",
            "Comuna Coteana",
            "Comuna Coteşti",
            "Comuna Cotmeana",
            "Comuna Cotnari",
            "Comuna Coţofăneşti",
            "Comuna Coţofenii din Dos",
            "Comuna Coţofenii din Faţă",
            "Comuna Coţuşca",
            "Comuna Covăsinţ",
            "Comuna Cozieni",
            "Comuna Cozmeni",
            "Comuna Cozmeşti",
            "Comuna Cozmeşti",
            "Comuna Crăcăoani",
            "Comuna Crăciunelu de Jos",
            "Comuna Craidorolţ",
            "Comuna Craiva",
            "Comuna Crâmpoia",
            "Comuna Crângeni",
            "Comuna Crângu",
            "Comuna Crângurile",
            "Comuna Crasna",
            "Comuna Crasna",
            "Comuna Creaca",
            "Comuna Creţeni",
            "Comuna Creţeşti",
            "Comuna Crevedia",
            "Comuna Crevedia Mare",
            "Comuna Crevenicu",
            "Comuna Cricău",
            "Comuna Criciova",
            "Comuna Crişan",
            "Comuna Crişcior",
            "Comuna Crişeni",
            "Comuna Cristeşti",
            "Comuna Cristeşti",
            "Comuna Cristian",
            "Comuna Cristian",
            "Comuna Cristineşti",
            "Comuna Criștioru de Jos",
            "Comuna Cristolţ",
            "Comuna Crivăţ",
            "Comuna Crizbav",
            "Comuna Crucea",
            "Comuna Crucea",
            "Comuna Crucişor",
            "Comuna Crușeț",
            "Comuna Cuca",
            "Comuna Cuca",
            "Comuna Cucuteni",
            "Comuna Cudalbi",
            "Comuna Cujmir",
            "Comuna Culciu",
            "Comuna Cumpăna",
            "Comuna Cungrea",
            "Comuna Cupşeni",
            "Comuna Curăţele",
            "Comuna Curcani",
            "Comuna Curtea",
            "Comuna Curteşti",
            "Comuna Curtişoara",
            "Comuna Curtuişeni",
            "Comuna Cut",
            "Comuna Cuza Voda",
            "Comuna Cuza Vodă",
            "Comuna Cuza Vodă",
            "Comuna Cuzăplac",
            "Comuna Cuzdrioara",
            "Comuna Dăbâca",
            "Comuna Dăeni",
            "Comuna Dăeşti",
            "Comuna Dagâţa",
            "Comuna Daia",
            "Comuna Daia Română",
            "Comuna Dalboşeţ",
            "Comuna Dalnic",
            "Comuna Dâmbovicioara",
            "Comuna Dămieneşti",
            "Comuna Dămuc",
            "Comuna Dănciuleşti",
            "Comuna Dăneasa",
            "Comuna Dăneşti",
            "Comuna Dăneşti",
            "Comuna Dăneşti",
            "Comuna Dăneţi",
            "Comuna Dângeni",
            "Comuna Dănicei",
            "Comuna Dărăşti-Ilfov",
            "Comuna Dârjiu",
            "Comuna Dârlos",
            "Comuna Dărmăneşti",
            "Comuna Dărmăneşti",
            "Comuna Dărmăneşti",
            "Comuna Darova",
            "Comuna Dârvari",
            "Comuna Dascălu Creaţa",
            "Comuna Davideşti",
            "Comuna Dealu",
            "Comuna Dealu Morii",
            "Comuna Deleni",
            "Comuna Deleni",
            "Comuna Deleni",
            "Comuna Deleşti",
            "Comuna Densuş",
            "Comuna Denta",
            "Comuna Derna",
            "Comuna Dersca",
            "Comuna Desa",
            "Comuna Deseşti",
            "Comuna Devesel",
            "Comuna Deveselu",
            "Comuna Dezna",
            "Comuna Dichiseni",
            "Comuna Diculeşti",
            "Comuna Dideşti",
            "Comuna Dieci",
            "Comuna Dimăcheni",
            "Comuna Dimitrie Cantemir",
            "Comuna Diosig",
            "Comuna Dioşti",
            "Comuna Ditrău",
            "Comuna Doba",
            "Comuna Dobârceni",
            "Comuna Dobârlău",
            "Comuna Dobra",
            "Comuna Dobra",
            "Comuna Dobreni",
            "Comuna Dobreşti",
            "Comuna Dobreşti",
            "Comuna Dobreşti",
            "Comuna Dobreţu",
            "Comuna Dobrin",
            "Comună Dobromir",
            "Comuna Dobrosloveni",
            "Comuna Dobroteasa",
            "Comuna Dobroteşti",
            "Comuna Dobroteşti",
            "Comuna Dobrovăţ",
            "Comuna Dobrun",
            "Comuna Dochia",
            "Comuna Doclin",
            "Comuna Dodeşti",
            "Comuna Dofteana",
            "Comuna Dognecea",
            "Comuna Doiceşti",
            "Comuna Dolheşti",
            "Comuna Dolheşti",
            "Comuna Doljeşti",
            "Comuna Domaşnea",
            "Comuna Domneşti",
            "Comuna Domneşti",
            "Comuna Dor Mărunt",
            "Comuna Dorna Cândrenilor",
            "Comuna Dorna-Arini",
            "Comuna Dorneşti",
            "Comuna Dorobanți",
            "Comuna Dorobanţu",
            "Comuna Dorobanţu",
            "Comuna Dorolţ",
            "Comuna Doştat",
            "Comuna Dracea",
            "Comuna Drãcşenei",
            "Comuna Dragalina",
            "Comuna Drăgăneşti",
            "Comuna Drăgăneşti",
            "Comuna Drăgăneşti",
            "Comuna Drăgăneşti",
            "Comuna Drăgăneşti de Vede",
            "Comuna Drăgăneşti-Vlaşca",
            "Comuna Drăganu",
            "Comuna Drăgeşti",
            "Comuna Drăghiceni",
            "Comuna Dragodana",
            "Comuna Drăgoeşti",
            "Comuna Drăgoeşti",
            "Comuna Drãgoieşti",
            "Comuna Dragomireşti",
            "Comuna Dragomireşti",
            "Comuna Dragomireşti",
            "Comuna Dragomireşti-Vale",
            "Comuna Dragoş Vodă",
            "Comuna Dragoslavele",
            "Comuna Drăgoteşti",
            "Comuna Drăgoteşti",
            "Comuna Dragu",
            "Comuna Drăguş",
            "Comuna Drăguşeni",
            "Comuna Drăguşeni",
            "Comuna Drăguşeni",
            "Comuna Drăguşeni",
            "Comuna Drãguţeşti",
            "Comuna Drajna de Sus",
            "Comuna Drânceni",
            "Comuna Drãnic",
            "Comuna Dridu",
            "Comuna Dubova",
            "Comuna Duda  Epureni",
            "Comuna Dudeşti",
            "Comuna Dudeştii Noi",
            "Comuna Dudeştii Vechi",
            "Comuna Dulceşti",
            "Comuna Dumbrava",
            "Comuna Dumbrava",
            "Comuna Dumbrava",
            "Comuna Dumbrava Roşie",
            "Comuna Dumbrăveni",
            "Comuna Dumbrăveni",
            "Comună Dumbrăveni",
            "Comuna Dumbrãveşti",
            "Comuna Dumbrăviţa",
            "Comuna Dumbrăviţa",
            "Comuna Dumbrăviţa",
            "Comuna Dumeşti",
            "Comuna Dumeşti",
            "Comuna Dumitra",
            "Comuna Dumitreşti",
            "Comuna Dumitriţa",
            "Comuna Durneşti",
            "Comuna Eftimie Murgu",
            "Comuna Epureni",
            "Comuna Erbiceni",
            "Comuna Eşelniţa",
            "Comuna Estelnic",
            "Comuna Ezeriş",
            "Comuna Făcăeni",
            "Comuna Făgeţelu",
            "Comuna Fălciu",
            "Comuna Fălcoiu",
            "Comuna Fântâna Mare",
            "Comuna Fântânele",
            "Comuna Fântânele",
            "Comuna Fântânele",
            "Comuna Fântânele",
            "Comuna Fântânele",
            "Comuna Fântânele",
            "Comuna Faraoani",
            "Comuna Fărău",
            "Comuna Fãrcaş",
            "Comuna Fărcaşa",
            "Comuna Fărcaşa",
            "Comuna Fărcaşele",
            "Comuna Fãrcãşeşti",
            "Comuna Fârdea",
            "Comuna Fârliug",
            "Comuna Fârţăneşti",
            "Comuna Fârtãţeşti",
            "Comuna Fãurei",
            "Comuna Făureşti",
            "Comuna Feldioara",
            "Comuna Feldru",
            "Comuna Feleacu",
            "Comuna Feliceni",
            "Comuna Felnac",
            "Comuna Fereşti",
            "Comuna Fibiş",
            "Comuna Fildu De Jos",
            "Comuna Filipeni",
            "Comuna Filipeşti",
            "Comuna Filipeştii de Pădure",
            "Comuna Filipeştii de Târg",
            "Comuna Finiş",
            "Comuna Finta",
            "Comuna Fitioneşti",
            "Comuna Fizeşu Gherlii",
            "Comuna Floreşti",
            "Comuna Floreşti",
            "Comuna Floreşti",
            "Comună Floreşti-Stoeneşti",
            "Comuna Florica",
            "Comuna Focuri",
            "Comuna Foeni",
            "Comuna Foieni",
            "Comuna Folteşti",
            "Comuna Forăşti",
            "Comuna Forotic",
            "Comuna Frânceşti-Mânăstireni",
            "Comuna Frăsinet",
            "Comuna Frăsinet",
            "Comuna Frata",
            "Comuna Frătăuţii Noi",
            "Comuna Frătăuţii Vechi",
            "Comuna Frăteşti",
            "Comuna Frecãţei",
            "Comuna Frecăţei",
            "Comuna Frumoasa",
            "Comuna Frumoasa",
            "Comuna Frumosu",
            "Comuna Frumuşani",
            "Comuna Frumuşeni",
            "Comuna Frumuşica",
            "Comuna Frumuşiţa",
            "Comuna Fruntişeni",
            "Comuna Fulga",
            "Comuna Fundata",
            "Comuna Fundeni",
            "Comuna Fundeni",
            "Comuna Fundeni-Dobroeşti",
            "Comuna Fundu Moldovei",
            "Comuna Furculeşti",
            "Comuna Gâdinţi",
            "Comuna Găgeşti",
            "Comuna Găiceana",
            "Comuna Gãiseni",
            "Comuna Gălăneşti",
            "Comuna Gălăţeni",
            "Comuna Galaţii Bistriţei",
            "Comuna Gălăuţaş",
            "Comuna Galbenu",
            "Comuna Gălbinaşi",
            "Comuna Gălbinaşi",
            "Comuna Galda de Jos",
            "Comuna Gâlgău",
            "Comuna Galicea",
            "Comuna Galicea Mare",
            "Comuna Galiciuica",
            "Comuna Găneasa",
            "Comuna Găneasa",
            "Comuna Gângiova",
            "Comuna Gârbãu",
            "Comuna Gârbou",
            "Comuna Gârbova",
            "Comuna Gârbovi",
            "Comuna Gârceni",
            "Comuna Gârcina",
            "Comuna Gârcov",
            "Comuna Gârda de Sus",
            "Comuna Gârdani",
            "Comuna Gârla Mare",
            "Comuna Gârleni",
            "Comuna Gârliciu",
            "Comuna Gârnic",
            "Comuna Garoafa",
            "Comuna Găujani",
            "Comuna Găvăneşti",
            "Comuna Gavojdia",
            "Comuna Geaca",
            "Comuna Gemenele",
            "Comuna General Berthelot",
            "Comuna George Enescu",
            "Comuna Gepiu",
            "Comuna Ghelari",
            "Comuna Ghelinţa",
            "Comuna Gheorghe Doja",
            "Comuna Gheorghe Lazăr",
            "Comuna Gherăeşti",
            "Comuna Gherăseni",
            "Comuna Gherceşti",
            "Comuna Ghergheasa",
            "Comuna Ghergheşti",
            "Comuna Gherghiţa",
            "Comuna Gherţa Mică",
            "Comuna Ghidfalău",
            "Comuna Ghidici",
            "Comuna Ghidigeni",
            "Comuna Ghilad",
            "Comuna Ghimeş-Făget",
            "Comuna Ghimpaţi",
            "Comuna Ghimpeţeni",
            "Comuna Ghindăoani",
            "Comuna Ghindăreşti",
            "Comuna Ghindeni",
            "Comuna Ghioroc",
            "Comuna Ghioroiu",
            "Comuna Ghiroda",
            "Comuna Ghizela",
            "Comuna Giarmata",
            "Comuna Giera",
            "Comuna Gighera",
            "Comuna Gilău",
            "Comuna Gioseni",
            "Comuna Girişu de Criş",
            "Comuna Giroc",
            "Comuna Girov",
            "Comuna Giubega",
            "Comuna Giuleşti",
            "Comuna Giulvăz",
            "Comuna Giurgeni",
            "Comuna Giurgiţa",
            "Comuna Giuvărăşti",
            "Comuna Glăvăneşti",
            "Comuna Glăvile",
            "Comuna Glimboca",
            "Comuna Glina",
            "Comuna Glodeanu-Sărat",
            "Comuna Glodeanu-Siliştea",
            "Comuna Glodeni",
            "Comuna Glogova",
            "Comuna Godeanu",
            "Comuna Godeni",
            "Comuna Godineşti",
            "Comuna Gogoşari",
            "Comuna Gogoşu",
            "Comuna Gogoşu",
            "Comuna Gohor",
            "Comuna Goicea",
            "Comuna Goieşti",
            "Comuna Golãieşti",
            "Comuna Goleşti",
            "Comuna Goleşti",
            "Comuna Gologanu",
            "Comuna Gorban",
            "Comuna Gorbăneşti",
            "Comuna Gorgota",
            "Comuna Gornet",
            "Comuna Gornet-Cricov",
            "Comuna Goruia",
            "Comuna Gostavăţu",
            "Comuna Goştinari",
            "Comuna Gostinu",
            "Comuna Gottlob",
            "Comuna Grădina",
            "Comuna Grãdinari",
            "Comuna Grădinari",
            "Comuna Grădinari",
            "Comuna Grãdinile",
            "Comuna Grădiştea",
            "Comuna Grădiştea",
            "Comuna Grădiştea",
            "Comuna Grădiştea",
            "Comuna Grajduri",
            "Comuna Grămeşti",
            "Comuna Grăniceri",
            "Comuna Grăniceşti",
            "Comuna Gratia",
            "Comuna Greabănu",
            "Comuna Greaca",
            "Comuna Greceşti",
            "Comuna Greci",
            "Comuna Greci",
            "Comuna Grindu",
            "Comuna Grindu",
            "Comuna Grinţieş",
            "Comuna Griviţa",
            "Comuna Griviţa",
            "Comuna Griviţa",
            "Comuna Grojdibodu",
            "Comuna Gropeni",
            "Comuna Gropniţa",
            "Comuna Groşi",
            "Comuna Groşii Ţibleşului",
            "Comuna Grozeşti",
            "Comuna Grozeşti",
            "Comuna Gruia",
            "Comuna Gruiu",
            "Comuna Grumăzeşti",
            "Comuna Gugeşti",
            "Comuna Gura Caliţei",
            "Comuna Gura Foii",
            "Comuna Gura Ialomiţei",
            "Comuna Gura Ocniţei",
            "Comuna Gura Padinii",
            "Comuna Gura Râului",
            "Comuna Gura Şuţii",
            "Comuna Gura Teghii",
            "Comuna Gura Vadului",
            "Comuna Gura Văii",
            "Comuna Gura Vitioarei",
            "Comuna Gurahonţ",
            "Comuna Gurasada",
            "Comuna Gurbăneşti",
            "Comuna Guşoeni",
            "Comuna Hăghig",
            "Comuna Hălăuceşti",
            "Comuna Hălchiu",
            "Comuna Hălmăgel",
            "Comuna Hălmagiu",
            "Comuna Halmăşd",
            "Comuna Halmeu",
            "Comuna Hamcearca",
            "Comuna Hăneşti",
            "Comuna Hangu",
            "Comuna Hănţeşti",
            "Comuna Hărău",
            "Comuna Hărman",
            "Comuna Hărmăneşti",
            "Comuna Hârşeni",
            "Comuna Hârseşti",
            "Comuna Hârtieşti",
            "Comuna Hârtop",
            "Comuna Hăşmaş",
            "Comuna Havârna",
            "Comuna Helegiu",
            "Comuna Heleşteni",
            "Comuna Hemeiuşi",
            "Comuna Herăşti",
            "Comuna Hereclean",
            "Comuna Hida",
            "Comuna Hidişelu De Sus",
            "Comuna Hilişeu-Horia",
            "Comuna Hinova",
            "Comuna Hlipiceni",
            "Comuna Hoceni",
            "Comuna Hodod",
            "Comuna Hoghilag",
            "Comuna Hoghiz",
            "Comuna Holbav",
            "Comuna Holboca",
            "Comuna Holod",
            "Comuna Homocea",
            "Comuna Homoroade",
            "Comuna Homorod",
            "Comuna Hopârta",
            "Comuna Horea",
            "Comuna Horgeşti",
            "Comuna Horia",
            "Comuna Horia",
            "Comuna Horia",
            "Comuna Horleşti",
            "Comuna Horoatu Crasnei",
            "Comuna Horodnic De Jos",
            "Comuna Horodnic De Sus",
            "Comuna Horodniceni",
            "Comuna Hotarele",
            "Comuna Hudeşti",
            "Comuna Hulubeşti",
            "Comuna Hurezani",
            "Comuna Huruieşti",
            "Comuna Husasău de Tinca",
            "Comuna Husnicioara",
            "Comuna I. L. Caragiale",
            "Comuna I.C.Brãtianu",
            "Comuna Iablaniţa",
            "Comuna Iacobeni",
            "Comuna Iacobeni",
            "Comuna Iana",
            "Comuna Ianca",
            "Comuna Iancu Jianu",
            "Comuna Iara",
            "Comuna Iaslovăţ",
            "Comuna Ibăneşti",
            "Comuna Ibăneşti",
            "Comuna Ibănești Pădure",
            "Comuna Iclod",
            "Comuna Icoana",
            "Comuna Icuseşti",
            "Comuna Iecea Mare",
            "Comuna Iedera",
            "Comuna Iepureşti",
            "Comuna Ieud",
            "Comuna Ighiu",
            "Comuna Igneşti",
            "Comuna Ileana",
            "Comuna Ileanda",
            "Comuna Ilia",
            "Comuna Ilieni",
            "Comuna Ilişeşti",
            "Comuna Ilovăt",
            "Comuna Iloviţa",
            "Comuna Ilva Mare",
            "Comuna Ilva Mică",
            "Comuna Independenţa",
            "Comuna Independenţa",
            "Comuna Independenţa",
            "Comuna Ineu",
            "Comuna Întorsura",
            "Comuna Întregalde",
            "Comuna Ion Corvin",
            "Comuna Ion Creangă",
            "Comuna Ion Neculce",
            "Comuna Ion Roată",
            "Comuna Ioneşti",
            "Comuna Ioneşti",
            "Comuna Iordãcheanu",
            "Comuna Ip",
            "Comuna Ipatele",
            "Comuna Ipotesti",
            "Comuna Ipoteşti",
            "Comuna Iratoşu",
            "Comuna Işalniţa",
            "Comuna Islaz",
            "Comuna Istria",
            "Comuna Isverna",
            "Comuna Isvoarele",
            "Comuna Iteşti",
            "Comuna Ivăneşti",
            "Comuna Iveşti",
            "Comuna Iveşti",
            "Comuna Izbiceni",
            "Comuna Izvoare",
            "Comuna Izvoarele",
            "Comuna Izvoarele",
            "Comuna Izvoarele",
            "Comuna Izvoarele",
            "Comuna Izvoarele",
            "Comuna Izvoarele Sucevei",
            "Comuna Izvoru",
            "Comuna Izvoru Bârzii",
            "Comuna Izvoru Berheciului",
            "Comuna Izvoru Crişului",
            "Comuna Jamu Mare",
            "Comuna Jariştea",
            "Comuna Jebel",
            "Comuna Jegălia",
            "Comuna Jiana",
            "Comuna Jibert",
            "Comuna Jichişu De Jos",
            "Comuna Jidvei",
            "Comuna Jijila",
            "Comuna Jilava",
            "Comuna Jilavele",
            "Comuna Jina",
            "Comuna Jirlău",
            "Comuna Jitia",
            "Comuna Joiţa",
            "Comuna Jorăşti",
            "Comuna Joseni",
            "Comuna Josenii Bârgăului",
            "Comuna Jucu",
            "Comuna Jugureni",
            "Comuna Jupâneşti",
            "Comuna Jurilovca",
            "Comuna Lăcusteni",
            "Comuna Lădeşti",
            "Comuna Laloşu",
            "Comuna Lapoş",
            "Comuna Lãpugiu De Jos",
            "Comuna Lăpuş",
            "Comuna Lăpuşata",
            "Comuna Lăpuşnicel",
            "Comuna Lăpuşnicu Mare",
            "Comuna Largu",
            "Comuna Laslea",
            "Comuna Laza",
            "Comuna Lăzarea",
            "Comuna Lăzăreni",
            "Comuna Lazuri",
            "Comuna Lazuri de Beiuş",
            "Comuna Lechinţa",
            "Comuna Lehliu",
            "Comuna Leleasca",
            "Comuna Lelese",
            "Comuna Leleşti",
            "Comuna Leliceni",
            "Comuna Lemnia",
            "Comuna Lenauheim",
            "Comuna Leorda",
            "Comuna Leordeni",
            "Comuna Leordina",
            "Comuna Lereşti",
            "Comuna Lespezi",
            "Comuna Leşu",
            "Comuna Letca",
            "Comuna Letca Nouă",
            "Comuna Leţcani",
            "Comuna Letea Veche",
            "Comuna Leu",
            "Comuna Licurici",
            "Comuna Liebling",
            "Comuna Lieşti",
            "Comuna Limanu",
            "Comuna Lipăneşti",
            "Comuna Lipniţa",
            "Comuna Lipova",
            "Comuna Lipovăţ",
            "Comuna Lipovu",
            "Comuna Lisa",
            "Comuna Lisa",
            "Comuna Liţa",
            "Comuna Livada",
            "Comuna Livezi",
            "Comuna Livezi",
            "Comuna Livezile",
            "Comuna Livezile",
            "Comuna Livezile",
            "Comuna Livezile",
            "Comuna Loamneş",
            "Comuna Logreşti-Moşteni",
            "Comuna Lopadea Nouă",
            "Comuna Lopătari",
            "Comuna Lovrin",
            "Comuna Lozna",
            "Comuna Lozna",
            "Comuna Lucieni",
            "Comuna Luciu",
            "Comuna Ludeşti",
            "Comuna Ludoş",
            "Comuna Lueta",
            "Comuna Lugaşu De Jos",
            "Comuna Luica",
            "Comuna Luizi-Cãlugãra",
            "Comuna Lumina",
            "Comuna Luna",
            "Comuna Lunca",
            "Comuna Lunca",
            "Comuna Lunca",
            "Comuna Lunca Banului",
            "Comuna Lunca Cernii De Jos",
            "Comuna Lunca Corbului",
            "Comuna Lunca de Jos",
            "Comuna Lunca de Sus",
            "Comuna Lunca Ilvei",
            "Comuna Lunca Mureşului",
            "Comuna Luncaviţa",
            "Comuna Luncaviţa",
            "Comuna Luncoiu De Jos",
            "Comuna Lungani",
            "Comuna Lungeşti",
            "Comuna Lunguleţu",
            "Comuna Lupac",
            "Comuna Lupeni",
            "Comuna Lupşa",
            "Comuna Lupşanu",
            "Comuna Macea",
            "Comuna Măceşu de Jos",
            "Comuna Măceşu de Sus",
            "Comuna Măciuca",
            "Comuna Mădăraş",
            "Comuna Mădăraş",
            "Comuna Mădârjac",
            "Comuna Mădulari-Beica",
            "Comuna Măerişte",
            "Comuna Măgeşti",
            "Comuna Măgireşti",
            "Comuna Maglavit",
            "Comuna Măgura",
            "Comuna Măgura",
            "Comuna Măgura",
            "Comuna Măgura Ilvei",
            "Comuna Măgurele",
            "Comuna Măgureni",
            "Comuna Mãguri-Rãcãtãu",
            "Comuna Mahmudia",
            "Comuna Maia",
            "Comuna Măicăneşti",
            "Comuna Măieru",
            "Comuna Mãieruş",
            "Comuna Malaia",
            "Comuna Măldăeni",
            "Comuna Măldăreşti",
            "Comuna Mălini",
            "Comuna Maliuc",
            "Comuna Malnaş",
            "Comuna Malovãţ",
            "Comuna Malu",
            "Comuna Malu cu Flori",
            "Comuna Malu Mare",
            "Comuna Mălureni",
            "Comuna Măluşteni",
            "Comuna Manasia",
            "Comuna Mânăstirea",
            "Comuna Mânăstirea Caşin",
            "Comuna Mânăstirea Humorului",
            "Comuna Mânăstireni",
            "Comuna Mănăştiur",
            "Comuna Mândra",
            "Comuna Măneciu",
            "Comuna Măneşti",
            "Comuna Măneşti",
            "Comuna Manoleasa",
            "Comuna Mânzăleşti",
            "Comuna Mărăcineni",
            "Comuna Mărăcineni",
            "Comuna Măraşu",
            "Comuna Marca",
            "Comuna Mărculeşti",
            "Comuna Marga",
            "Comuna Mărgăriteşti",
            "Comuna Mărgău",
            "Comuna Margina",
            "Comuna Marginea",
            "Comuna Mărgineni",
            "Comuna Mărgineni",
            "Comuna Mărişel",
            "Comuna Mărişelu",
            "Comuna Marpod",
            "Comuna Mârşa",
            "Comuna Mârşani",
            "Comuna Mărtineşti",
            "Comuna Mărtiniş",
            "Comuna Mărunţei",
            "Comuna Mârzãneşti",
            "Comuna Maşloc",
            "Comuna Măstăcani",
            "Comuna Mătăsari",
            "Comuna Mătăsaru",
            "Comuna Matca",
            "Comuna Mateeşti",
            "Comuna Matei",
            "Comuna Măureni",
            "Comuna Mavrodin",
            "Comuna Măxineni",
            "Comuna Medieşu Aurit",
            "Comuna Mehadia",
            "Comuna Mehadica",
            "Comuna Melineşti",
            "Comuna Mera",
            "Comuna Merei",
            "Comuna Mereni",
            "Comuna Mereni",
            "Comuna Mereni",
            "Comuna Mereşti",
            "Comuna Merghindeal",
            "Comuna Merișani",
            "Comună Meseşenii de Jos",
            "Comuna Meteş",
            "Comuna Mica",
            "Comuna Micăsasa",
            "Comuna Miceşti",
            "Comuna Miceştii de Câmpie",
            "Comuna Micfalău",
            "Comuna Micleşti",
            "Comuna Micula",
            "Comuna Mihăeşti",
            "Comuna Mihăeşti",
            "Comuna Mihăeşti",
            "Comuna Mihai Bravu",
            "Comuna Mihai Bravu",
            "Comuna Mihai Eminescu",
            "Comuna Mihai Viteazu",
            "Comuna Mihai Viteazu",
            "Comuna Mihail Kogălniceanu",
            "Comuna Mihail Kogălniceanu",
            "Comuna Mihail Kogălniceanu",
            "Comuna Mihăileni",
            "Comuna Mihăileni",
            "Comuna Mihăileni",
            "Comuna Mihăileşti",
            "Comuna Mihălăşeni",
            "Comuna Mihalţ",
            "Comuna Milas",
            "Comuna Milcoiu",
            "Comuna Milcov",
            "Comuna Milcovul",
            "Comuna Mileanca",
            "Comuna Miloşeşti",
            "Comuna Mintiu Gherlii",
            "Comuna Mioarele",
            "Comuna Mirăslău",
            "Comuna Mircea Vodă",
            "Comuna Mircea Vodă",
            "Comuna Mirceşti",
            "Comuna Mireşu Mare",
            "Comuna Mironeasa",
            "Comuna Miroşi",
            "Comuna Miroslava",
            "Comuna Mirosloveşti",
            "Comuna Mirşid",
            "Comuna Mişca",
            "Comuna Mischii",
            "Comuna Mitoc",
            "Comuna Mitocu Dragomirnei",
            "Comuna Mitreni",
            "Comuna Mitrofani",
            "Comuna Moacşa",
            "Comuna Moara",
            "Comuna Moara Vlãsiei",
            "Comuna Mociu",
            "Comuna Modelu",
            "Comuna Moftinu",
            "Comuna Mogoş",
            "Comuna Mogoşani",
            "Comuna Mogoşeşti",
            "Comuna Mogoşeşti-Siret",
            "Comuna Mogoşoaia",
            "Comuna Moieciu",
            "Comuna Moisei",
            "Comuna Moldova Suliţa",
            "Comuna Moldoveneşti",
            "Comuna Moldoveni",
            "Comuna Moldoveni",
            "Comuna Moldoviţa",
            "Comuna Moneasa",
            "Comuna Monor",
            "Comuna Morărești",
            "Comuna Moraviţa",
            "Comuna Moroeni",
            "Comuna Morteni",
            "Comuna Morunglav",
            "Comuna Moşna",
            "Comuna Moşna",
            "Comuna Moşniţa Nouã",
            "Comuna Moşoaia",
            "Comuna Moşteni",
            "Comuna Moţãieni",
            "Comuna Moţăţei",
            "Comuna Moţca",
            "Comuna Motoşeni",
            "Comuna Movila",
            "Comuna Movila Banului",
            "Comuna Movila Miresii",
            "Comuna Movileni",
            "Comuna Movileni",
            "Comuna Movileni",
            "Comuna Moviliţa",
            "Comuna Moviliţa",
            "Comuna Mozăceni",
            "Comuna Muereasca",
            "Comuna Mugeni",
            "Comuna Munteni",
            "Comuna Munteni Buzău",
            "Comuna Muntenii de Jos",
            "Comuna Muntenii de Sus",
            "Comuna Murgaşi",
            "Comuna Murgeşti",
            "Comuna Murighiol",
            "Comuna Mușătești",
            "Comuna Muşeniţa",
            "Comuna Muşeteşti",
            "Comuna Nădrag",
            "Comuna Năeni",
            "Comuna Naidaş",
            "Comuna Nalbant",
            "Comuna Nămoloasa",
            "Comuna Nana",
            "Comuna Năneşti",
            "Comuna Nanov",
            "Comuna Năpradea",
            "Comuna Năruja",
            "Comuna Năsturelu",
            "Comuna Necşeşti",
            "Comuna Negoi",
            "Comuna Negomir",
            "Comuna Negraşi",
            "Comuna Negreni",
            "Comuna Negreşti",
            "Comuna Negri",
            "Comuna Negrileşti",
            "Comuna Negrileşti",
            "Comuna Negrileşti",
            "Comuna Nenciuleşti",
            "Comuna Nereju",
            "Comuna Nicolae Bãlcescu",
            "Comuna Nicolae Bălcescu",
            "Comuna Nicolae Bălcescu",
            "Comuna Nicolae Bălcescu",
            "Comuna Nicolae Titulescu",
            "Comuna Nicoreşti",
            "Comuna Nicşeni",
            "Comuna Niculeşti",
            "Comuna Niculiţel",
            "Comuna Nimigea",
            "Comuna Nistoreşti",
            "Comuna Niţchidorf",
            "Comuna Nocrich",
            "Comuna Nojorid",
            "Comuna Noşlac",
            "Comuna Nucet",
            "Comuna Nuci",
            "Comuna Nucşoara",
            "Comuna Nufăru",
            "Comuna Nuşeni",
            "Comuna Nuşfalău",
            "Comuna Oancea",
            "Comuna Oarja",
            "Comuna Oarţa De Jos",
            "Comuna Obârşia",
            "Comuna Obârşia De Câmp",
            "Comuna Obârşia-Cloşani",
            "Comuna Oboga",
            "Comuna Obreja",
            "Comuna Obrejiţa",
            "Comuna Ocland",
            "Comuna Ocna De Fier",
            "Comuna Ocna Şugatag",
            "Comuna Ocniţa",
            "Comuna Ocoliş",
            "Comuna Odăile",
            "Comuna Odobeşti",
            "Comuna Odobeşti",
            "Comuna Odoreu",
            "Comuna Ograda",
            "Comuna Ogrezeni",
            "Comuna Ohaba",
            "Comuna Ohaba Lungă",
            "Comuna Oinacu",
            "Comuna Oituz",
            "Comuna Ojdula",
            "Comuna Olanu",
            "Comuna Olari",
            "Comuna Olari",
            "Comuna Olcea",
            "Comuna Olteneşti",
            "Comuna Olteni",
            "Comuna Oltina",
            "Comuna Onceşti",
            "Comuna Onceşti",
            "Comuna Oniceni",
            "Comuna Oporelu",
            "Comuna Oprişor",
            "Comuna Optaşi-Mãgura",
            "Comuna Orăştioara de Sus",
            "Comuna Oraşu Nou",
            "Comuna Orbeasca",
            "Comuna Orbeni",
            "Comuna Orlat",
            "Comuna Orlea",
            "Comuna Orleşti",
            "Comuna Ormeniş",
            "Comuna Orodel",
            "Comuna Orţişoara",
            "Comuna Oşeşti",
            "Comuna Osica de Jos",
            "Comuna Osica de Sus",
            "Comuna Oşorhei",
            "Comuna Ostra",
            "Comuna Ostrov",
            "Comuna Ostrov",
            "Comuna Ostroveni",
            "Comuna Otelec",
            "Comuna Oţeleni",
            "Comuna Oteşani",
            "Comuna Ozun",
            "Comuna Păcureţi",
            "Comuna Padeş",
            "Comuna Padina",
            "Comuna Padina",
            "Comuna Pădureni",
            "Comuna Pădureni",
            "Comuna Palanca",
            "Comuna Pãlatca",
            "Comuna Paleu",
            "Comuna Paltin",
            "Comuna Pãltiniş",
            "Comuna Păltiniş",
            "Comuna Păltinoasa",
            "Comuna Panaci",
            "Comuna Pănătău",
            "Comuna Pânceşti",
            "Comuna Pănceşti",
            "Comuna Pângăraţi",
            "Comuna Pantelimon",
            "Comuna Panticeu",
            "Comuna Pârâu",
            "Comuna Parava",
            "Comuna Pardina",
            "Comuna Pardoşi",
            "Comuna Pârgăreşti",
            "Comuna Parincea",
            "Comuna Pârjol",
            "Comuna Pârscov",
            "Comuna Pârşcoveni",
            "Comuna Parţa",
            "Comuna Părteştii de Jos",
            "Comuna Parva",
            "Comuna Păstrăveni",
            "Comuna Pătrăuţi",
            "Comuna Pătulele",
            "Comuna Păuca",
            "Comuna Pãuleni-Ciuc",
            "Comuna Pãuleşti",
            "Comuna Păuleşti",
            "Comuna Păuleşti",
            "Comuna Păuliş",
            "Comuna Păuneşti",
            "Comuna Păuşeşti",
            "Comuna Păuşeşti-Măglaşi",
            "Comuna Peceneaga",
            "Comuna Pechea",
            "Comuna Pecineaga",
            "Comuna Peciu Nou",
            "Comuna Peregu Mare",
            "Comuna Peretu",
            "Comuna Periam",
            "Comuna Pericei",
            "Comuna Perieni",
            "Comuna Perieţi",
            "Comuna Perieţi",
            "Comuna Periş",
            "Comuna Perişani",
            "Comuna Perişor",
            "Comuna Perişoru",
            "Comuna Perşinari",
            "Comuna Pesac",
            "Comuna Pescari",
            "Comuna Pesceana",
            "Comuna Peştera",
            "Comuna Peştişani",
            "Comuna Pestişu Mic",
            "Comuna Petrăchioaia",
            "Comuna Petreşti",
            "Comuna Petreşti",
            "Comuna Petreştii De Jos",
            "Comuna Petricani",
            "Comuna Petriş",
            "Comuna Petrova",
            "Comuna Petru Rareş",
            "Comuna Pianu",
            "Comuna Piatra",
            "Comuna Piatra Şoimului",
            "Comuna Pieleşti",
            "Comuna Pietrari",
            "Comuna Pietrari",
            "Comuna Pietroasa",
            "Comuna Pietroasa",
            "Comuna Pietroasele",
            "Comuna Pietroşani",
            "Comuna Pietroşani",
            "Comuna Pietroşiţa",
            "Comuna Pilu",
            "Comuna Pipirig",
            "Comuna Pir",
            "Comuna Pişchia",
            "Comuna Pişcolt",
            "Comuna Piscu",
            "Comuna Piscu Vechi",
            "Comuna Plãieşii De Jos",
            "Comuna Plătăreşti",
            "Comuna Platoneşti",
            "Comuna Pleniţa",
            "Comuna Pleşcuţa",
            "Comuna Pleşoi",
            "Comuna Pleşoiu",
            "Comuna Plopana",
            "Comuna Plopii Slăviţeşti",
            "Comuna Plopiş",
            "Comuna Plopşoru",
            "Comuna Plopu",
            "Comuna Plosca",
            "Comuna Ploscoş",
            "Comuna Ploscuţeni",
            "Comuna Plugari",
            "Comuna Poarta Albă",
            "Comuna Poboru",
            "Comuna Pochidia",
            "Comuna Pocola",
            "Comuna Podari",
            "Comuna Podeni",
            "Comuna Podenii Noi",
            "Comuna Podgoria",
            "Comuna Podoleni",
            "Comuna Podu Turcului",
            "Comuna Poduri",
            "Comuna Poeni",
            "Comuna Pogana",
            "Comuna Pogoneşti",
            "Comuna Poian",
            "Comuna Poiana",
            "Comuna Poiana",
            "Comuna Poiana  Ilvei",
            "Comuna Poiana Blenchii",
            "Comuna Poiana Câmpina",
            "Comuna Poiana Cristei",
            "Comuna Poiana Lacului",
            "Comuna Poiana Mare",
            "Comuna Poiana Mărului (Brașov)",
            "Comuna Poiana Sibiului",
            "Comuna Poiana Stampei",
            "Comuna Poiana Teiului",
            "Comuna Poiana Vadului",
            "Comuna Poienari",
            "Comuna Poienarii Burchii",
            "Comuna Poienarii de Argeş",
            "Comuna Poienarii de Muscel",
            "Comuna Poieneşti",
            "Comuna Poieni",
            "Comuna Poieni-Solca",
            "Comuna Poienile de sub Munte",
            "Comuna Poienile Izei",
            "Comuna Pojejena",
            "Comuna Pojorâta",
            "Comuna Polovragi",
            "Comuna Pomârla",
            "Comuna Pomezeu",
            "Comuna Pomi",
            "Comuna Ponoarele",
            "Comuna Ponor",
            "Comuna Popeşti",
            "Comuna Popeşti",
            "Comuna Popeşti",
            "Comuna Popeşti",
            "Comuna Popeşti",
            "Comuna Poplaca",
            "Comuna Popricani",
            "Comuna Poroina Mare",
            "Comuna Poroschia",
            "Comuna Porumbacu de Jos",
            "Comuna Porumbeni",
            "Comuna Porumbeşti",
            "Comuna Poşaga",
            "Comuna Poseşti",
            "Comuna Poşta Câlnãu",
            "Comuna Potlogi",
            "Comuna Praid",
            "Comuna Prăjeni",
            "Comuna Prăjeşti",
            "Comuna Predeal-Sărari",
            "Comuna Predeşti",
            "Comuna Prejmer",
            "Comuna Preuteşti",
            "Comuna Priboieni",
            "Comuna Prigor",
            "Comuna Prigoria",
            "Comuna Priponeşti",
            "Comuna Prisăcani",
            "Comuna Priseaca",
            "Comuna Pristol",
            "Comuna Probota",
            "Comuna Produleşti",
            "Comuna Proviţa de Jos",
            "Comuna Proviţa de Sus",
            "Comuna Prundeni",
            "Comuna Prundu",
            "Comuna Prundu Bârgăului",
            "Comuna Prunişor",
            "Comuna Pucheni",
            "Comuna Puchenii Mari",
            "Comuna Pufeşti",
            "Comuna Pui",
            "Comuna Puieşti",
            "Comuna Puieşti",
            "Comuna Pungeşti",
            "Comuna Punghina",
            "Comuna Purani",
            "Comuna Puşcaşi",
            "Comuna Putineiu",
            "Comuna Putineiu",
            "Comuna Putna",
            "Comuna Răbăgani",
            "Comuna Râca",
            "Comuna Răcăciuni",
            "Comuna Răcăşdia",
            "Comuna Răchiţeni",
            "Comuna Răchiţi",
            "Comuna Răchitoasa",
            "Comuna Răchitova",
            "Comuna Raciu",
            "Comuna Răcoasa",
            "Comuna Racoş",
            "Comuna Racova",
            "Comuna Racoviţa",
            "Comuna Racoviţa",
            "Comuna Racoviţa",
            "Comuna Racoviţa",
            "Comuna Racoviţeni",
            "Comuna Racşa",
            "Comuna Racu",
            "Comuna Rădăşeni",
            "Comuna Rãdãuţi-Prut",
            "Comuna Rădeşti",
            "Comuna Rădeşti",
            "Comuna Rãdoieşti",
            "Comuna Radomireşti",
            "Comuna Radovan",
            "Comuna Radovanu",
            "Comuna Răducăneni",
            "Comuna Răduleşti",
            "Comuna Rafaila",
            "Comuna Râfov",
            "Comuna Râmeţ",
            "Comuna Râmetea",
            "Comuna Ramna",
            "Comuna Râmnicelu",
            "Comuna Râmnicelu",
            "Comuna Rapoltu Mare",
            "Comuna Râşca",
            "Comuna Râșca",
            "Comuna Răscăeţi",
            "Comuna Răşinari",
            "Comuna Răsmireşti",
            "Comuna Rasova",
            "Comuna Rast",
            "Comuna Răstoaca",
            "Comuna Răsuceni",
            "Comuna Răteşti",
            "Comuna Râu Alb",
            "Comuna Râu de Mori",
            "Comuna Râu Sadului",
            "Comuna Răuceşti",
            "Comuna Răuseni",
            "Comuna Războeni-Cetate",
            "Comuna Rãzboieni",
            "Comuna Rãzvad",
            "Comuna Rebra",
            "Comuna Rebricea",
            "Comuna Rebrişoara",
            "Comuna Recea",
            "Comuna Recea",
            "Comuna Recea",
            "Comuna Recea Cristur",
            "Comuna Reci",
            "Comuna Redea",
            "Comuna Rediu",
            "Comuna Rediu",
            "Comuna Rediu-Tătar",
            "Comuna Reghiu",
            "Comuna Remetea",
            "Comuna Remetea",
            "Comuna Remetea Chioarului",
            "Comuna Remetea Mare",
            "Comuna Remeţi",
            "Comuna Repedea",
            "Comuna Reviga",
            "Comuna Ribiţa",
            "Comuna Rieni",
            "Comuna Ripiceni",
            "Comuna Roata De Jos",
            "Comuna Robăneşti",
            "Comuna Robeasca",
            "Comuna Rociu",
            "Comuna Rodna",
            "Comuna Roeşti",
            "Comuna Rogova",
            "Comuna Rojişte",
            "Comuna Roma",
            "Comuna Românaşi",
            "Comuna Româneşti",
            "Comuna Româneşti",
            "Comuna Români",
            "Comuna Romanu",
            "Comuna Romos",
            "Comuna Romuli",
            "Comuna Rona de Jos",
            "Comuna Rona de Sus",
            "Comuna Roşcani",
            "Comuna Roseţi",
            "Comuna Roşia",
            "Comuna Roşia",
            "Comuna Roşia De Amaradia",
            "Comuna Roşia de Secaş",
            "Comuna Roşia Montană",
            "Comuna Roşieşti",
            "Comuna Roşiile",
            "Comuna Roşiori",
            "Comuna Roşiori",
            "Comuna Roşiori",
            "Comuna Roşiori",
            "Comuna Rotunda",
            "Comuna Rozavlea",
            "Comuna Rucăr",
            "Comuna Rugineşti",
            "Comuna Ruginoasa",
            "Comuna Ruginoasa",
            "Comuna Runcu",
            "Comuna Runcu",
            "Comuna Runcu",
            "Comuna Runcu Salvei",
            "Comuna Rus",
            "Comuna Rusăneşti",
            "Comuna Rusca Montană",
            "Comuna Ruşcova",
            "Comuna Ruşeţu",
            "Comuna Rușii Munți",
            "Comuna Săbăoani",
            "Comuna Săbăreni",
            "Comuna Săcădat",
            "Comuna Săcălăşeni",
            "Comuna Săcălaz",
            "Comuna Săcăşeni",
            "Comuna Săcel",
            "Comuna Săcel",
            "Comuna Săcele",
            "Comuna Săcelu",
            "Comuna Săceni",
            "Comuna Sacoşu Turcesc",
            "Comuna Sacu",
            "Comuna Sãcuieu",
            "Comuna Sadova",
            "Comuna Sadova",
            "Comuna Sadu",
            "Comuna Saelele",
            "Comuna Şag",
            "Comuna Sâg",
            "Comuna Săgeata",
            "Comuna Sagna",
            "Comuna Şagu",
            "Comuna Săhăteni",
            "Comuna Sălacea",
            "Comuna Sălard",
            "Comuna Sãlaşu De Sus",
            "Comuna Sălăţig",
            "Comuna Sălătrucel",
            "Comuna Sălătrucu",
            "Comuna Salcia",
            "Comuna Salcia",
            "Comuna Salcia",
            "Comuna Salcia Tudor",
            "Comuna Sălciile",
            "Comuna Sălcioara",
            "Comuna Sălcioara",
            "Comuna Sălciua",
            "Comuna Sălcuţa",
            "Comuna Saligny",
            "Comuna Sãliştea",
            "Comuna Sălsig",
            "Comuna Salva",
            "Comuna Samarineşti",
            "Comuna Sâmbăta",
            "Comuna Sâmbăta de Sus",
            "Comuna Sâmbureşti",
            "Comuna Şamşud",
            "Comuna Sânandrei",
            "Comuna Sâncel",
            "Comuna Sâncrăieni",
            "Comuna Sâncraiu",
            "Comuna Sândominic",
            "Comuna Şandra",
            "Comuna Sănduleni",
            "Comuna Sănduleşti",
            "Comuna Sângeru",
            "Comuna Sâniob",
            "Comuna Sanislău",
            "Comuna Sânmartin",
            "Comuna Sânmartin",
            "Comuna Sânmărtin",
            "Comuna Sânmihaiu Almaşului",
            "Comuna Sânmihaiu de Câmpie",
            "Comuna Sânmihaiu Român",
            "Comuna Sânnicolau-Român",
            "Comuna Sânpaul",
            "Comuna Sânpetru",
            "Comuna Sânpetru Mare",
            "Comuna Sânsimion",
            "Comuna Şanţ",
            "Comuna Santa Mare",
            "Comuna Sântămăria-Orlea",
            "Comuna Sântandrei",
            "Comuna Santău",
            "Comuna Sântimbru",
            "Comuna Sântimbru",
            "Comuna Sânzieni",
            "Comuna Sãpânţa",
            "Comuna Săpata",
            "Comuna Săpoca",
            "Comuna Saraiu",
            "Comuna Sărăsău",
            "Comuna Sărata",
            "Comuna Sărăţeni",
            "Comuna Saravale",
            "Comuna Sârbeni",
            "Comuna Sârbi",
            "Comuna Sârbii-Măgura",
            "Comuna Sarichioi",
            "Comuna Sărmaş",
            "Comuna Şărmăşag",
            "Comuna Sarmizegetusa",
            "Comuna Şaru Dornei",
            "Comuna Săruleşti",
            "Comuna Săruleşti",
            "Comuna Sasca Montană",
            "Comuna Săsciori",
            "Comuna Sascut",
            "Comuna Satchinez",
            "Comuna Satu Mare",
            "Comuna Satu Mare",
            "Comuna Satulung",
            "Comuna Săuca",
            "Comuna Săuceşti",
            "Comuna Săuleşti",
            "Comuna Săvădisla",
            "Comuna Săvârşin",
            "Comuna Săveni",
            "Comuna Săvineşti",
            "Comuna Scăeşti",
            "Comuna Scânteia",
            "Comuna Scânteia",
            "Comuna Scânteieşti",
            "Comuna Scărişoara",
            "Comuna Scărişoara",
            "Comuna Scheia",
            "Comuna Scheia",
            "Comuna Schela",
            "Comuna Schela",
            "Comuna Schitu",
            "Comuna Schitu",
            "Comuna Schitu-Duca",
            "Comuna Schitu-Goleşti",
            "Comuna Scoarţa",
            "Comuna Scobinţi",
            "Comuna Scorţaru Nou",
            "Comuna Scorţeni",
            "Comuna Scorţeni",
            "Comuna Scorţoasa",
            "Comuna Scrioaştea",
            "Comuna Scundu",
            "Comuna Scurtu Mare",
            "Comuna Scutelnici",
            "Comuna Seaca",
            "Comuna Seaca",
            "Comuna Seaca de Câmp",
            "Comuna Seaca de Pădure",
            "Comuna Secăria",
            "Comuna Secaş",
            "Comuna Secu",
            "Comuna Secuieni",
            "Comuna Secuieni",
            "Comuna Secuieni",
            "Comuna Secusigiu",
            "Comuna Segarcea Vale",
            "Comuna Şeica Mare",
            "Comuna Şeica Mică",
            "Comuna Seimeni",
            "Comuna Şeitin",
            "Comuna Şelaru",
            "Comuna Seleuş",
            "Comuna Şelimbăr",
            "Comuna Semlac",
            "Comuna Şendreni",
            "Comuna Şendriceni",
            "Comuna Şepreuş",
            "Comuna Şerbăneşti",
            "Comuna Şerbăuţi",
            "Comuna Şercaia",
            "Comuna Sfântu Gheorghe",
            "Comuna Sfântu Gheorghe",
            "Comuna Sfinţeşti",
            "Comuna Şibot",
            "Comuna Sic",
            "Comuna Sicheviţa",
            "Comuna Şicula",
            "Comuna Siculeni",
            "Comuna Şieu",
            "Comuna Şieu",
            "Comuna Şieu-Măgheruş",
            "Comuna Şieu-Odorhei",
            "Comuna Şieuţ",
            "Comuna Sihlea",
            "Comuna Şilindia",
            "Comuna Siliştea",
            "Comuna Siliştea",
            "Comuna Siliştea",
            "Comuna Siliştea Crucii",
            "Comuna Siliștea Gumești",
            "Comuna Silivaşu De Câmpie",
            "Comuna Şimand",
            "Comuna Simian",
            "Comuna Şimian",
            "Comuna Siminicea",
            "Comuna Şimişna",
            "Comuna Şimnicu de Sus",
            "Comuna Simoneşti",
            "Comuna Şinca Nouă",
            "Comuna Şinca Veche",
            "Comuna Sineşti",
            "Comuna Sineşti",
            "Comuna Singureni",
            "Comuna Sintea Mare",
            "Comuna Şintereag",
            "Comuna Sinteşti",
            "Comuna Şinteu",
            "Comuna Şipote",
            "Comuna Sireţel",
            "Comuna Şiria",
            "Comuna Şirineasa",
            "Comuna Siriu",
            "Comuna Şirna",
            "Comuna Siseşti",
            "Comuna Şişeşti",
            "Comuna Şiştarovăţ",
            "Comuna Sita Buzăului",
            "Comuna Slatina",
            "Comuna Slatina-Timiş",
            "Comuna Slătioara",
            "Comuna Slătioara",
            "Comuna Slava Cercheză",
            "Comuna Slimnic",
            "Comuna Slivileşti",
            "Comuna Slobozia",
            "Comuna Slobozia",
            "Comuna Slobozia",
            "Comuna Slobozia Bradului",
            "Comuna Slobozia Conachi",
            "Comuna Slobozia Moara",
            "Comuna Slobozia-Ciorăşti",
            "Comuna Slobozia-Mândra",
            "Comuna Smârdan",
            "Comuna Smârdan",
            "Comuna Smârdioasa",
            "Comuna Smeeni",
            "Comuna Smulţi",
            "Comuna Snagov",
            "Comuna Şoarş",
            "Comuna Socodor",
            "Comuna Socol",
            "Comuna Socond",
            "Comuna Şofronea",
            "Comuna Sohatu",
            "Comuna Sohodol",
            "Comuna Şoimari",
            "Comuna Şoimi",
            "Comuna Şoimuş",
            "Comuna Şoldanu",
            "Comuna Soleşti",
            "Comuna Solonţ",
            "Comuna Someş-Odorhei",
            "Comuna Somova",
            "Comuna Şona",
            "Comuna Şopârliţa",
            "Comuna Sopot",
            "Comuna Şopotu Nou",
            "Comuna Şotânga",
            "Comuna Şotrile",
            "Comuna Sovarna",
            "Comuna Soveja",
            "Comuna Spanţov",
            "Comuna Spermezeu",
            "Comuna Spineni",
            "Comuna Spinuş",
            "Comuna Sprâncenata",
            "Comuna Şpring",
            "Comuna Spulber",
            "Comuna Stâlpeni",
            "Comuna Stâlpu",
            "Comuna Stăncuţa",
            "Comuna Stăneşti",
            "Comuna Stăneşti",
            "Comuna Stăneşti",
            "Comuna Stângăceaua",
            "Comuna Stănileşti",
            "Comuna Stănişeşti",
            "Comuna Stăniţa",
            "Comuna Starchiojd",
            "Comuna Stăuceni",
            "Comuna Ştefan Cel Mare",
            "Comuna Ştefan Cel Mare",
            "Comuna Ştefan cel Mare",
            "Comuna Ştefan cel Mare",
            "Comuna Ştefan cel Mare",
            "Comuna Ştefan cel Mare",
            "Comuna Ştefan Vodă",
            "Comuna Ştefăneşti",
            "Comuna Ştefãneştii De Jos",
            "Comuna Ştefeşti",
            "Comuna Stejari",
            "Comuna Stejaru",
            "Comuna Stejaru",
            "Comuna Stelnica",
            "Comuna Ştiubieni",
            "Comuna Ştiuca",
            "Comuna Stoeneşti",
            "Comuna Stoeneşti",
            "Comuna Stoeneşti",
            "Comuna Stoeneşti",
            "Comuna Stoicăneşti",
            "Comuna Stoileşti",
            "Comuna Stoina",
            "Comuna Stolniceni-Prăjescu",
            "Comuna Stolnici",
            "Comuna Storobăneasa",
            "Comuna Straja",
            "Comuna Strâmtura",
            "Comuna Străoane",
            "Comuna Strejeşti",
            "Comuna Stremţ",
            "Comuna Stroeşti",
            "Comuna Stroieşti",
            "Comuna Strugari",
            "Comuna Strunga",
            "Comuna Studina",
            "Comuna Stulpicani",
            "Comuna Suatu",
            "Comuna Subcetate",
            "Comuna Suceveni",
            "Comuna Suceviţa",
            "Comuna Suciu de Sus",
            "Comuna Sudiţi",
            "Comuna Şugag",
            "Comuna Suhaia",
            "Comuna Suharău",
            "Comuna Suhurlui",
            "Comuna Şuici",
            "Comuna Şuletea",
            "Comuna Suliţa",
            "Comuna Şuncuiuş",
            "Comuna Suplacu de Barcău",
            "Comuna Supur",
            "Comuna Şura Mare",
            "Comuna Şura Mică",
            "Comuna Suraia",
            "Comuna Surani",
            "Comuna Surdila-Găiseanca",
            "Comuna Surdila-Greci",
            "Comuna Surduc",
            "Comuna Şuşani",
            "Comuna Suseni",
            "Comuna Suseni",
            "Comuna Suteşti",
            "Comuna Şuţeşti",
            "Comuna Sviniţa",
            "Comuna Tăcuta",
            "Comuna Ţaga",
            "Comuna Talea",
            "Comuna Talpa",
            "Comuna Tãlpaş",
            "Comuna Tãmãdãu Mare",
            "Comuna Tămăşeni",
            "Comuna Tãmãşeu",
            "Comuna Tamaşi",
            "Comuna Tâmboeşti",
            "Comuna Tâmna",
            "Comuna Tanacu",
            "Comuna Tănăsoaia",
            "Comuna Tansa",
            "Comuna Ţânţăreni",
            "Comuna Tărcaia",
            "Comuna Tarcău",
            "Comuna Tarcea",
            "Comuna Târgşoru Vechi",
            "Comuna Târgu Trotuş",
            "Comuna Târguşor",
            "Comuna Târlişua",
            "Comuna Tărlungeni",
            "Comuna Tarna Mare",
            "Comuna Târnava",
            "Comuna Târnova",
            "Comuna Târnova",
            "Comuna Târşolţ",
            "Comuna Tărtăşeşti",
            "Comuna Taşca",
            "Comuna Tătărani",
            "Comuna Tătărăni",
            "Comuna Tãtãranu",
            "Comuna Tătărăşti",
            "Comuna Tătărăştii de Jos",
            "Comuna Tătărăştii de Sus",
            "Comuna Tătaru",
            "Comuna Tătăruşi",
            "Comuna Tătuleşti",
            "Comuna Tauţ",
            "Comuna Tăuteu",
            "Comuna Tazlău",
            "Comuna Teaca",
            "Comuna Teasc",
            "Comuna Teişani",
            "Comuna Teiu",
            "Comuna Telciu",
            "Comuna Telega",
            "Comuna Teleşti",
            "Comuna Teliu",
            "Comuna Teliucu Inferior",
            "Comuna Ţepu",
            "Comuna Terebeşti",
            "Comuna Teregova",
            "Comuna Teremia Mare",
            "Comuna Terpeziţa",
            "Comuna Teslui",
            "Comuna Teslui",
            "Comuna Ţeţchea",
            "Comuna Tetoiu",
            "Comuna Tia Mare",
            "Comuna Ţibana",
            "Comuna Ţibăneşti",
            "Comuna Ţibucani",
            "Comuna Tichileşti",
            "Comuna Ticuşu Vechi",
            "Comuna Ticvaniu Mare",
            "Comuna Ţifeşti",
            "Comuna Ţigănaşi",
            "Comuna Ţigăneşti",
            "Comuna Tigveni",
            "Comuna Tiha Bârgăului",
            "Comuna Tileagd",
            "Comuna Tilişca",
            "Comuna Timişeşti",
            "Comuna Tinca",
            "Comuna Tinosu",
            "Comuna Ţinteşti",
            "Comuna Tiream",
            "Comuna Tisãu",
            "Comuna Țițești",
            "Comuna Titeşti",
            "Comuna Toboliu",
            "Comuna Todireni",
            "Comuna Todireşti",
            "Comuna Todireşti",
            "Comuna Todireşti",
            "Comuna Tomeşti",
            "Comuna Tomeşti",
            "Comuna Tomeşti",
            "Comuna Tomeşti",
            "Comuna Tomnatic",
            "Comuna Tomşani",
            "Comuna Tomşani",
            "Comuna Topalu",
            "Comuna Topana",
            "Comuna Topleţ",
            "Comuna Topliceni",
            "Comuna Topliţa",
            "Comuna Topolog",
            "Comuna Topolovãţu Mare",
            "Comuna Toporu",
            "Comuna Topraisar",
            "Comuna Tormac",
            "Comuna Tortoman",
            "Comuna Toteşti",
            "Comuna Traian",
            "Comuna Traian",
            "Comuna Traian",
            "Comuna Traian",
            "Comuna Traian",
            "Comuna Traian Vuia",
            "Comuna Treznea",
            "Comuna Trifeşti",
            "Comuna Trifeşti",
            "Comuna Tritenii De Jos",
            "Comuna Trivalea-Moşteni",
            "Comuna Troianul",
            "Comuna Truşeşti",
            "Comuna Tudor Vladimirescu",
            "Comuna Tudor Vladimirescu",
            "Comuna Tudora",
            "Comuna Tufeni",
            "Comuna Tufeşti",
            "Comuna Ţuglui",
            "Comuna Tulca",
            "Comuna Tulgheş",
            "Comuna Tulnici",
            "Comuna Tuluceşti",
            "Comuna Tunari",
            "Comuna Tupilaţi",
            "Comuna Turburea",
            "Comuna Turcineşti",
            "Comuna Turcoaia",
            "Comuna Turdaş",
            "Comuna Tureni",
            "Comuna Turia",
            "Comuna Turnu Roşu",
            "Comuna Turnu Rueni",
            "Comuna Turţ",
            "Comuna Turulung",
            "Comuna Tuşnad",
            "Comuna Ţuţora",
            "Comuna Tutova",
            "Comuna Tuzla",
            "Comuna Ucea de Jos",
            "Comuna Uda",
            "Comuna Uda-Clocociov",
            "Comuna Udeşti",
            "Comuna Uileacu de Beiuş",
            "Comuna Uivar",
            "Comuna Ulieş",
            "Comuna Ulieşti",
            "Comuna Ulma",
            "Comuna Ulmeni",
            "Comuna Ulmeni",
            "Comuna Ulmi",
            "Comuna Ulmi",
            "Comuna Ulmu",
            "Comuna Ulmu",
            "Comuna Umbrăreşti",
            "Comuna Ungheni",
            "Comuna Ungheni",
            "Comuna Ungra",
            "Comuna Unguraş",
            "Comuna Ungureni",
            "Comuna Ungureni",
            "Comuna Unguriu",
            "Comuna Unirea",
            "Comuna Unirea",
            "Comuna Unirea",
            "Comuna Unirea",
            "Comuna Unţeni",
            "Comuna Urdari",
            "Comuna Urecheni",
            "Comuna Urecheşti",
            "Comuna Urecheşti",
            "Comuna Uriu",
            "Comuna Urmeniş",
            "Comuna Urzica",
            "Comuna Urziceni",
            "Comuna Urzicuţa",
            "Comuna Ususău",
            "Comuna Văcăreni",
            "Comuna Văcăreşti",
            "Comuna Văculeşti",
            "Comuna Vad",
            "Comuna Vădastra",
            "Comuna Vădăstriţa",
            "Comuna Vădeni",
            "Comuna Vadu Crişului",
            "Comuna Vadu Izei",
            "Comuna Vadu Moldovei",
            "Comuna Vadu Moţilor",
            "Comuna Vadu Paşii",
            "Comuna Vadu Săpat",
            "Comuna Văgiuleşti",
            "Comuna Vaideeni",
            "Comuna Vâlcăneşti",
            "Comuna Vălcani",
            "Comuna Valcãu De Jos",
            "Comuna Vâlcele",
            "Comuna Vâlcele",
            "Comuna Vâlcelele",
            "Comuna Vâlcelele",
            "Comuna Valea Argovei",
            "Comuna Valea Călugărească",
            "Comuna Valea Chioarului",
            "Comuna Valea Ciorii",
            "Comuna Valea Crişului",
            "Comuna Valea Danului",
            "Comuna Valea Doftanei",
            "Comuna Valea Dragului",
            "Comuna Valea Iaşului",
            "Comuna Valea Ierii",
            "Comuna Valea Lungă",
            "Comuna Valea Lungă",
            "Comuna Valea Lupului",
            "Comuna Valea Măcrişului",
            "Comuna Valea Mare",
            "Comuna Valea Mare",
            "Comuna Valea Mare",
            "Comuna Valea Mare",
            "Comuna Valea Mare-Pravăţ",
            "Comuna Valea Mãrului",
            "Comuna Valea Moldovei",
            "Comuna Valea Nucarilor",
            "Comuna Valea Râmnicului",
            "Comuna Valea Sălciei",
            "Comuna Valea Sării",
            "Comuna Valea Seacă",
            "Comuna Valea Seacă",
            "Comuna Valea Stanciului",
            "Comuna Valea Teilor",
            "Comuna Valea Ursului",
            "Comuna Valea Viilor",
            "Comuna Valea Vinului",
            "Comuna Vãleni",
            "Comuna Văleni",
            "Comuna Văleni",
            "Comuna Văleni-Dâmboviţa",
            "Comuna Vălişoara",
            "Comuna Văliug",
            "Comuna Valu lui Traian",
            "Comuna Vama",
            "Comuna Vama",
            "Comuna Vama Buzăului",
            "Comuna Vânãtori",
            "Comuna Vânători",
            "Comuna Vânători",
            "Comuna Vânători",
            "Comuna Vânători Neamţ",
            "Comuna Vânătorii Mici",
            "Comuna Vânjuleţ",
            "Comuna Vărădia",
            "Comuna Vărădia de Mureş",
            "Comuna Vărăşti",
            "Comuna Vărbilău",
            "Comuna Vârciorog",
            "Comuna Vârfu Câmpului",
            "Comuna Vârfuri",
            "Comuna Vârfurile",
            "Comuna Vârghiş",
            "Comuna Variaş",
            "Comuna Vârlezi",
            "Comuna Vărşag",
            "Comuna Vârşolţ",
            "Comuna Vârteşcoiu",
            "Comuna Vârtoape",
            "Comuna Vârtop",
            "Comuna Vârvoru de Jos",
            "Comuna Vasilaţi",
            "Comuna Vaţa De Jos",
            "Comuna Vatra Moldoviţei",
            "Comuna Vedea",
            "Comuna Vedea",
            "Comuna Vedea",
            "Comuna Vela",
            "Comuna Verbiţa",
            "Comuna Vereşti",
            "Comuna Verguleasa",
            "Comuna Vermeş",
            "Comuna Verneşti",
            "Comuna Veţel",
            "Comuna Vetiş",
            "Comuna Vetrişoaia",
            "Comuna Vicovu de Jos",
            "Comuna Victor Vlad Delamarina",
            "Comuna Victoria",
            "Comuna Victoria",
            "Comuna Vidra",
            "Comuna Vidra",
            "Comuna Vidra",
            "Comuna Viile Satu Mare",
            "Comuna Viişoara",
            "Comuna Viişoara",
            "Comuna Viişoara",
            "Comuna Viişoara",
            "Comuna Viişoara",
            "Comuna Vima Micã",
            "Comuna Vinderei",
            "Comuna Vinga",
            "Comuna Vintilă Vodă",
            "Comuna Vintileasca",
            "Comuna Vinţu de Jos",
            "Comuna Vipereşti",
            "Comuna Vişani",
            "Comuna Vişeu de Jos",
            "Comuna Vişina",
            "Comuna Vişina",
            "Comuna Vişina Nouă",
            "Comuna Vişineşti",
            "Comuna Viştea de Jos",
            "Comuna Vităneşti",
            "Comuna Vitomireşti",
            "Comuna Vizantea-Livezi",
            "Comuna Viziru",
            "Comuna Vlad Ţepeş",
            "Comuna Vlădaia",
            "Comuna Vlădeni",
            "Comuna Vlădeni",
            "Comuna Vlădeni",
            "Comuna Vlădeni",
            "Comuna Vlădeşti",
            "Comuna Vlădeşti",
            "Comuna Vlădeşti",
            "Comuna Vlădila",
            "Comuna Vladimir",
            "Comuna Vladimirescu",
            "Comuna Vlăsineşti",
            "Comuna Voiceşti",
            "Comuna Voila",
            "Comuna Voineasa",
            "Comuna Voineasa",
            "Comuna Voineşti",
            "Comuna Voineşti",
            "Comuna Voineşti",
            "Comuna Voiteg",
            "Comuna Voitinel",
            "Comuna Voloiac",
            "Comuna Volovăţ",
            "Comuna Vorniceni",
            "Comuna Vorona",
            "Comuna Vorţa",
            "Comuna Voşlãbeni",
            "Comuna Vrâncioaia",
            "Comuna Vrani",
            "Comuna Vrata",
            "Comuna Vulcan",
            "Comuna Vulcana Băi",
            "Comuna Vulcana-Pandele",
            "Comuna Vulpeni",
            "Comuna Vultureni",
            "Comuna Vultureni",
            "Comuna Vultureşti",
            "Comuna Vultureşti",
            "Comuna Vultureşti",
            "Comuna Vultureşti",
            "Comuna Vulturu",
            "Comuna Vulturu",
            "Comuna Vurpăr",
            "Comuna Vutcani",
            "Comuna Zăbala",
            "Comuna Zăbrani",
            "Comuna Zădăreni",
            "Comuna Zagon",
            "Comuna Zagra",
            "Comuna Zalha",
            "Comuna Zam",
            "Comuna Zâmbreasca",
            "Comuna Zamostea",
            "Comuna Zăneşti",
            "Comuna Zăpodeni",
            "Comuna Zărand",
            "Comuna Zărneşti",
            "Comuna Zătreni",
            "Comuna Zăvoaia",
            "Comuna Zăvoi",
            "Comuna Zemeş",
            "Comuna Zerind",
            "Comuna Zetea",
            "Comuna Ziduri",
            "Comuna Zimandu Nou",
            "Comuna Zimbor",
            "Comuna Zorleni",
            "Comuna Zorlenţu Mare",
            "Comuna Zvoriştea",
            "Conceşti",
            "Condeești",
            "Conduratu",
            "Conop",
            "Constanţa",
            "Constantin Brâncoveanu",
            "Constantin Daicoviciu",
            "Conţeşti",
            "Conțești",
            "Conțești",
            "Conțești",
            "Copăcel",
            "Copăcele",
            "Copăcelu",
            "Copăceni",
            "Copăceni",
            "Copăceni",
            "Copaciu",
            "Copălău",
            "Copalnic",
            "Copalnic Mănăştur",
            "Copşa Mică",
            "Corabia",
            "Corbasca",
            "Corbeanca",
            "Corbeni",
            "Corbești",
            "Corbi",
            "Corbii Mari",
            "Corbiţa",
            "Corbu",
            "Corbu",
            "Corbu",
            "Corbu",
            "Corbu Nou",
            "Corcova",
            "Cordăreni",
            "Cordău",
            "Cordoș",
            "Cordun",
            "Corlăţel",
            "Corlăteni",
            "Corlătești",
            "Cormaia",
            "Cornățel",
            "Cornăţelu",
            "Cornea",
            "Cornereva",
            "Cornești",
            "Cornești",
            "Corneşti",
            "Corneşti",
            "Cornetu",
            "Cornetu",
            "Corni",
            "Corni",
            "Corni",
            "Corni-Albești",
            "Cornu de Jos",
            "Cornu de Sus",
            "Cornu Luncii",
            "Cornuțel",
            "Corocăiești",
            "Corod",
            "Coroi",
            "Coroieni",
            "Coroieşti",
            "Coronini",
            "Coropceni",
            "Coruia",
            "Corunca",
            "Corund",
            "Cosâmbeşti",
            "Coșani",
            "Coşbuc",
            "Coşeiu",
            "Coşereni",
            "Coşeşti",
            "Cosmeşti",
            "Cosmeşti",
            "Cosmeștii-Vale",
            "Cosmina de Jos",
            "Coșna",
            "Cosoba",
            "Coşoveni",
            "Costache Negri",
            "Costâna",
            "Coşteiu",
            "Costeni",
            "Costeşti",
            "Costeşti",
            "Costeşti",
            "Costeşti",
            "Costești",
            "Costești",
            "Costești",
            "Costeștii din Deal",
            "Costeştii din Vale",
            "Costineşti",
            "Costişa",
            "Coștiui",
            "Costuleni",
            "Coșula",
            "Coțatcu",
            "Coteana",
            "Cotenești",
            "Coteşti",
            "Cotmeana",
            "Cotnari",
            "Coţofăneşti",
            "Coţofenii din Dos",
            "Coțofenii din Față",
            "Cotorinau",
            "Cotu Vameș",
            "Cotumba",
            "Cotuș",
            "Coţuşca",
            "Covăsinţ",
            "Covasna",
            "Covasna",
            "Covrigi",
            "Cozia",
            "Cozieni",
            "Cozma",
            "Cozmeni",
            "Cozmeşti",
            "Cozmești",
            "Crăcăoani",
            "Cracăul Negru",
            "Crăciunei",
            "Crăciunelu de Jos",
            "Crăciunești",
            "Crăciunești",
            "Craidorolţ",
            "Crăiești",
            "Crăiești",
            "Craiova",
            "Craiva",
            "Crâmpoia",
            "Crângeni",
            "Crângu",
            "Crângurile de Sus",
            "Crasna",
            "Crasna",
            "Crasna Vișeului",
            "Creaca",
            "Creţeni",
            "Crețești",
            "Crețești",
            "Crevedia",
            "Crevedia Mare",
            "Crevenicu",
            "Cricău",
            "Criciova",
            "Criș",
            "Crişan",
            "Crişcior",
            "Crişeni",
            "Cristeşti",
            "Cristeşti",
            "Cristești",
            "Cristeștii Ciceului",
            "Cristian",
            "Cristian",
            "Cristineşti",
            "Criştioru de Jos",
            "Cristolţ",
            "Cristur",
            "Cristuru Secuiesc",
            "Crivăț",
            "Crivești",
            "Crivina",
            "Crivina",
            "Crizbav",
            "Croitori",
            "Crovu",
            "Crucea",
            "Crucea",
            "Crucea",
            "Crucişor",
            "Crușeț",
            "Crușovu",
            "Cubulcut",
            "Cuca",
            "Cuca",
            "Cucerdea",
            "Cuci",
            "Cuciulata",
            "Cucueți",
            "Cucuieți",
            "Cucuruzu",
            "Cucuteni",
            "Cudalbi",
            "Cuejdiu",
            "Cufoaia",
            "Cugir",
            "Cuieșd",
            "Cujmir",
            "Culcea",
            "Culciu Mic",
            "Culmea",
            "Culpiu",
            "Cumpăna",
            "Cund",
            "Cungrea",
            "Cupşeni",
            "Curăţele",
            "Curcani",
            "Curețe",
            "Curtea",
            "Curtea de Argeş",
            "Curteni",
            "Curteşti",
            "Curtici",
            "Curtişoara",
            "Curtuișeni",
            "Curtuiușu Mare",
            "Curtuiușu Mic",
            "Cuștelnic",
            "Cut",
            "Cut",
            "Cuvin",
            "Cuza Vodă",
            "Cuza Vodă",
            "Cuza Vodă",
            "Cuza Vodă",
            "Cuzap",
            "Cuzăplac",
            "Cuzdrioara",
            "Dăbâca",
            "Dăbuleni",
            "Dăeni",
            "Dăeşti",
            "Dagâţa",
            "Daia",
            "Daia",
            "Daia Română",
            "Dalboșeț",
            "Dâlga-Gară",
            "Dalnic",
            "Dalu",
            "Dămăcușeni",
            "Dâmbău",
            "Dâmbovicioara",
            "Dâmbovicioara",
            "Dâmbroca",
            "Dâmbu",
            "Dâmbu",
            "Dămieneşti",
            "Dămieni",
            "Dămoc",
            "Dămuc",
            "Dănceu",
            "Dănciuleşti",
            "Dancu",
            "Dăneasa",
            "Daneș",
            "Dănești",
            "Dăneşti",
            "Dăneşti",
            "Dăneşti",
            "Dăneștii Chioarului",
            "Dăneţi",
            "Dângeni",
            "Dara",
            "Darabani",
            "Dărăşti-Ilfov",
            "Dărăști-Vlașca",
            "Dârjiu",
            "Dârlos",
            "Dărmăneşti",
            "Dărmăneşti",
            "Dărmăneşti",
            "Dărmăneşti",
            "Darova",
            "Darova Nouă",
            "Dârvari",
            "Dârvari",
            "Dârza",
            "Dăscălești",
            "Dascălu",
            "Dătășeni",
            "Davideşti",
            "Deag",
            "Deaj",
            "Dealu",
            "Dealu",
            "Dealu Aluniș",
            "Dealu Corbului",
            "Dealu Dănicei",
            "Dealu Frumos",
            "Dealu Mare",
            "Dealu Mare",
            "Dealu Morii",
            "Decebal",
            "Decindeni",
            "Deda",
            "Dedrad",
            "Dedulești",
            "Dedulești",
            "Dej",
            "Deja",
            "Dejești",
            "Deleni",
            "Deleni",
            "Deleni",
            "Deleni",
            "Deleni",
            "Deleşti",
            "Demacușa",
            "Densuş",
            "Denta",
            "Deparați",
            "Derna",
            "Dersca",
            "Derșida",
            "Desa",
            "Deseşti",
            "Deta",
            "Deva",
            "Devesel",
            "Deveselu",
            "Dezmir",
            "Dezna",
            "Dichiseni",
            "Diculești",
            "Didești",
            "Dieci",
            "Dileu Nou",
            "Dileu Vechi",
            "Dimăcheni",
            "Diosig",
            "Dioșod",
            "Dioşti",
            "Dițești",
            "Ditrău",
            "Doanca",
            "Doba",
            "Dobârceni",
            "Dobârlău",
            "Doboșeni",
            "Dobra",
            "Dobra",
            "Dobra",
            "Dobra",
            "Dobra",
            "Dobreni",
            "Dobreni",
            "Dobreni",
            "Dobreşti",
            "Dobreşti",
            "Dobreşti",
            "Dobreţu",
            "Dobric",
            "Dobricu Lăpușului",
            "Dobridor",
            "Dobrin",
            "Dobrița",
            "Dobroeşti",
            "Dobromir",
            "Dobrosloveni",
            "Dobroteasa",
            "Dobroteşti",
            "Dobrotești",
            "Dobrotinet",
            "Dobrotu",
            "Dobrovăţ",
            "Dobrun",
            "Dobrușa",
            "Dochia",
            "Doclin",
            "Dodeni",
            "Dodești",
            "Doftana",
            "Dofteana",
            "Dognecea",
            "Doiceşti",
            "Dolhasca",
            "Dolheşti",
            "Dolhești",
            "Dolheștii Mici",
            "Dolheștii-Mari",
            "Doljeşti",
            "Domănești",
            "Domaşnea",
            "Domneşti",
            "Domnești",
            "Domneşti-Sârbi",
            "Domnești-Târg",
            "Domnița",
            "Dor Mărunt",
            "Dorgoş",
            "Dorna Cândrenilor",
            "Dorna-Arini",
            "Dorneşti",
            "Dorobanți",
            "Dorobanți",
            "Dorobanţu",
            "Dorobanţu",
            "Dorobanțu",
            "Dorohoi",
            "Dorolţ",
            "Doştat",
            "Dracea",
            "Dracșani",
            "Drăcşani",
            "Drăcșenei",
            "Drăculea Bandului",
            "Drăgăești Ungureni",
            "Dragalina",
            "Drăgăneasa",
            "Drăgăneşti",
            "Drăgăneşti",
            "Drăgăneşti",
            "Drăgănești",
            "Drăgăneşti de Vede",
            "Drăgăneşti-Olt",
            "Drăgăneşti-Vlaşca",
            "Drăganu-Olteni",
            "Drăgăşani",
            "Drăgeşti",
            "Drăghia",
            "Drăghiceni",
            "Drăghici",
            "Drăghinești",
            "Dragodana",
            "Dragodănești",
            "Drăgoeşti",
            "Drăgoești",
            "Drăgoeşti-Snagov",
            "Drăgoiești",
            "Dragomir",
            "Dragomireşti",
            "Dragomireşti",
            "Dragomireşti",
            "Dragomireşti",
            "Dragomirești-Deal",
            "Dragomireşti-Vale",
            "Dragoș Vodă",
            "Dragoslavele",
            "Dragosloveni",
            "Drăgoteşti",
            "Drăgoteşti",
            "Dragu",
            "Drăgugești",
            "Drăguș",
            "Drăguşeni",
            "Drăguşeni",
            "Drăguşeni",
            "Drăgușeni",
            "Drăgușeni",
            "Drăguţeşti",
            "Drajna de Jos",
            "Drajna de Sus",
            "Drajna Nouă",
            "Drânceni",
            "Drănic",
            "Dranovățu",
            "Drauț",
            "Draxini",
            "Dridu",
            "Drobeta-Turnu Severin",
            "Drojdii",
            "Dubiștea de Pădure",
            "Dubova",
            "Duda",
            "Dudașu",
            "Dudeşti",
            "Dudeștii Noi",
            "Dudeştii Vechi",
            "Dudu",
            "Dulcea",
            "Dulceşti",
            "Dulcești",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava",
            "Dumbrava de Sus",
            "Dumbrava Roşie",
            "Dumbrăveni",
            "Dumbrăveni",
            "Dumbrăveni",
            "Dumbrăveni",
            "Dumbrăveşti",
            "Dumbrăvioara",
            "Dumbrăviţa",
            "Dumbrăviţa",
            "Dumbrăviţa",
            "Dumbrăvița",
            "Dumbrăvița",
            "Dumbrăvița",
            "Dumeni",
            "Dumeşti",
            "Dumeşti",
            "Dumitra",
            "Dumitreni",
            "Dumitrești",
            "Dumitrești",
            "Dumitreștii",
            "Dumitrița",
            "Dunăreni",
            "După Deal",
            "Durneşti",
            "Durușa",
            "Eforie Nord",
            "Eforie Sud",
            "Eftimie Murgu",
            "Eliseni",
            "Emil Racoviță",
            "Enisala",
            "Epureni",
            "Erbiceni",
            "Ercea",
            "Eremieni",
            "Eremitu",
            "Ernei",
            "Eşelniţa",
            "Estelnic",
            "Ezeriş",
            "Făcăeni",
            "Făcăi",
            "Făclia",
            "Făgăraș",
            "Făget",
            "Făget",
            "Făgeţelu",
            "Făgetu",
            "Făgetu",
            "Falaștoaca",
            "Falcău",
            "Fălciu",
            "Fălcoiu",
            "Fălticeni",
            "Fânațe",
            "Fânațe",
            "Fânațele Mădărașului",
            "Fânațele Socolului",
            "Fântâna Babii",
            "Fântâna Mare",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fântânele",
            "Fărăgău",
            "Faraoaní",
            "Fărău",
            "Fărcaș",
            "Fărcaşa",
            "Fărcaşa",
            "Fărcaşele",
            "Fărcășeni",
            "Fărcăşeşti",
            "Fărcașu de Jos",
            "Fârdea",
            "Fârliug",
            "Fârţăneşti",
            "Fărtăţeşti",
            "Fâstâci",
            "Făurei",
            "Făurei",
            "Făureşti",
            "Făurești",
            "Feisa",
            "Feldioara",
            "Feldru",
            "Feleacu",
            "Feleag",
            "Feliceni",
            "Felnac",
            "Feneș",
            "Ferești",
            "Ferești",
            "Ferestrău-Oituz",
            "Fericea",
            "Fersig",
            "Feteşti",
            "Fetești",
            "Fetești-Gară",
            "Fibiș",
            "Fieni",
            "Fierbinți",
            "Fierbinţi-Târg",
            "Fierbinții de Jos",
            "Fierbinții de Sus",
            "Fildu de Jos",
            "Filea",
            "Filia",
            "Filiaș",
            "Filiaşi",
            "Filioara",
            "Filipeni",
            "Filipeşti",
            "Filipeştii de Pădure",
            "Filipeştii de Târg",
            "Filitelnic",
            "Filpișu Mare",
            "Filpișu Mic",
            "Finiş",
            "Finta Mare",
            "Finteușu Mare",
            "Finteușu Mic",
            "Firiza",
            "Fișer",
            "Fițcău",
            "Fitioneşti",
            "Fizeș",
            "Fizeşu Gherlii",
            "Flămânzi",
            "Floreşti",
            "Floreşti",
            "Floreşti",
            "Florești",
            "Florești",
            "Florica",
            "Floroaia",
            "Focșani",
            "Focuri",
            "Foeni",
            "Foglaş",
            "Foi",
            "Foieni",
            "Folteşti",
            "Forăşti",
            "Forău",
            "Forotic",
            "Frâncenii Boiului",
            "Frasin",
            "Frăsinet",
            "Frăsinet",
            "Frata",
            "Frătăuţii Noi",
            "Frătăuţii Vechi",
            "Frăteşti",
            "Fratoștița",
            "Frecăţei",
            "Frecăţei",
            "Frumoasa",
            "Frumoasa",
            "Frumoasa",
            "Frumosu",
            "Frumuşani",
            "Frumușelu",
            "Frumușeni",
            "Frumuşica",
            "Frumuşiţa",
            "Fruntișeni",
            "Frunzeni",
            "Frunzi",
            "Fulga de Jos",
            "Fulga de Sus",
            "Fundata",
            "Fundătura",
            "Fundeni",
            "Fundeni",
            "Fundeni",
            "Fundeni",
            "Fundoaia",
            "Fundu Moldovei",
            "Fundu Răcăciuni",
            "Fundulea",
            "Furcenii Noi",
            "Furculești",
            "Furtunești",
            "Gâdinţi",
            "Găeşti",
            "Găgeni",
            "Găgeşti",
            "Găgești",
            "Găiceana",
            "Găiești",
            "Găinești",
            "Găiseni",
            "Gălăneşti",
            "Gălăoaia",
            "Gălășești",
            "Gălăţeni",
            "Gălățeni",
            "Galaţi",
            "Galaţii Bistriţei",
            "Gălăuţaş",
            "Galbeni",
            "Galbenu",
            "Gălbinaşi",
            "Gălbinași",
            "Galda de Jos",
            "Gâldău",
            "Gălești",
            "Gâlgău",
            "Gâlgău Almaşului",
            "Galicea",
            "Galicea Mare",
            "Galiciuica",
            "Galoșpetreu",
            "Galșa",
            "Gâmbuț",
            "Găneasa",
            "Găneasa",
            "Gănești",
            "Gănești",
            "Gângiova",
            "Gărăgău",
            "Gârbău",
            "Gârbești",
            "Gârbou",
            "Gârbova",
            "Gârbovi",
            "Gârceni",
            "Gârcina",
            "Gârcov",
            "Gârda de Sus",
            "Gârdani",
            "Gârla-Mare",
            "Gârleni",
            "Gârlenii de Sus",
            "Gârliciu",
            "Gârnic",
            "Garoafa",
            "Garvăn",
            "Gătaia",
            "Găujani",
            "Gaura Sângerului",
            "Găvănești",
            "Găvănești",
            "Gavojdia",
            "Găzărie",
            "Geaca",
            "Geamăna",
            "Gelu",
            "Gemenea Brătulești",
            "Gemenele",
            "Gemeni",
            "General Berthelot",
            "Geoagiu",
            "George Enescu",
            "Gepiu",
            "Gheaba",
            "Gheboaia",
            "Gheboieni",
            "Gheja",
            "Ghelari",
            "Ghelinţa",
            "Gheorghe Doja",
            "Gheorghe Doja",
            "Gheorghe Doja",
            "Gheorghe Lazăr",
            "Gheorgheni",
            "Gheorghieni",
            "Gherăeşti",
            "Gherăseni",
            "Ghergheasa",
            "Ghergheşti",
            "Gherghiţa",
            "Gherla",
            "Ghermănești",
            "Ghermănești",
            "Gherța Mare",
            "Gherţa Mică",
            "Gherteniș",
            "Ghidașteu",
            "Ghidfalău",
            "Ghidici",
            "Ghidigeni",
            "Ghighișeni",
            "Ghilad",
            "Ghimbav",
            "Ghimeș",
            "Ghimeş-Făget",
            "Ghimpați",
            "Ghimpați",
            "Ghimpețeni",
            "Ghindăoani",
            "Ghindăreşti",
            "Ghindari",
            "Ghindeni",
            "Ghinești",
            "Ghinești",
            "Ghioca",
            "Ghiorac",
            "Ghioroc",
            "Ghioroiu",
            "Ghioșești",
            "Ghirdoveni",
            "Ghiroda",
            "Ghizdăvești",
            "Ghizela",
            "Giarmata",
            "Giarmata-Vii",
            "Giera",
            "Gighera",
            "Gilău",
            "Gioseni",
            "Girișu de Criș",
            "Giroc",
            "Girov",
            "Giubega",
            "Giuleşti",
            "Giuluș",
            "Giulvăz",
            "Giurgeni",
            "Giurgiș",
            "Giurgiţa",
            "Giurgiu",
            "Giurtelecu Șimleului",
            "Giuvărăşti",
            "Glăjărie",
            "Glăvăneşti",
            "Glăvile",
            "Glimboca",
            "Glina",
            "Glod",
            "Glod",
            "Glodeanu-Sărat",
            "Glodeanu-Siliştea",
            "Glodeni",
            "Glodeni",
            "Glodenii Gândului",
            "Glogova",
            "Godeanu",
            "Godeni",
            "Godineşti",
            "Goești",
            "Gogan",
            "Gogoşari",
            "Gogoşu",
            "Gogoşu",
            "Gohor",
            "Goicea",
            "Goiești",
            "Golăiești",
            "Goleşti",
            "Golești",
            "Gologanu",
            "Goranu",
            "Gorban",
            "Gorbăneşti",
            "Goreni",
            "Gorganu",
            "Gorgota",
            "Gorgota",
            "Gornești",
            "Gornet",
            "Gornet-Cricov",
            "Goruia",
            "Goruna",
            "Goruni",
            "Goșmani",
            "Gostavăţu",
            "Gostinari",
            "Goştinari-Văcăreşti",
            "Gostinu",
            "Gottlob",
            "Grabăț",
            "Grădina",
            "Grădinari",
            "Grădinari",
            "Grădinari",
            "Grădini",
            "Gradiștea",
            "Grădiştea",
            "Grădiştea",
            "Grădiştea",
            "Grădiştea",
            "Grădiștea",
            "Grăjdana",
            "Grajduri",
            "Grămeşti",
            "Grăniceri",
            "Grăniceşti",
            "Gratia",
            "Grâușorul",
            "Greaca",
            "Grebănu",
            "Grebenișu de Câmpie",
            "Greblești",
            "Greceanca",
            "Greceşti",
            "Greci",
            "Greci",
            "Greci",
            "Greci",
            "Greoni",
            "Grindeni",
            "Grindu",
            "Grindu",
            "Grinţieş",
            "Griviţa",
            "Griviţa",
            "Griviţa",
            "Groapa Rădăii",
            "Groape",
            "Grojdibodu",
            "Gropeni",
            "Gropniţa",
            "Gropșani",
            "Groșani",
            "Groşi",
            "Groși",
            "Groșii Țibleșului",
            "Grozăvești",
            "Grozeşti",
            "Grozeşti",
            "Gruia",
            "Gruișor",
            "Gruiu",
            "Grumăzeşti",
            "Grunji",
            "Gugeşti",
            "Gugești",
            "Gulia",
            "Gulia",
            "Gura Beliei",
            "Gura Caliţei",
            "Gura Căluiu",
            "Gura Foii",
            "Gura Humorului",
            "Gura Ialomiței",
            "Gura Ocniței",
            "Gura Padinii",
            "Gura Putnei",
            "Gura Râului",
            "Gura Suhașului",
            "Gura Şuţii",
            "Gura Teghii",
            "Gura Vadului",
            "Gura Văii",
            "Gura Văii",
            "Gura Viţioarei",
            "Gura Vulcanei",
            "Gurahonţ",
            "Gurasada",
            "Gurba",
            "Gurbăneşti",
            "Gurbediu",
            "Gurghiu",
            "Guruieni",
            "Guşoeni",
            "Gvardinița",
            "Hăbeni",
            "Habic",
            "Hădăreni",
            "Hagău",
            "Hăghiac",
            "Hăghig",
            "Halânga",
            "Hălărești",
            "Hălăuceşti",
            "Hălchiu",
            "Hălmăgel",
            "Hălmagiu",
            "Halmăşd",
            "Halmeu",
            "Hamcearca",
            "Handalu Ilbei",
            "Hăneşti",
            "Hangu",
            "Hănțești",
            "Hanu Conachi",
            "Hărănglab",
            "Hărău",
            "Hârlău",
            "Hărman",
            "Hărmăneasa",
            "Hărmăneștii Vechi",
            "Hărnicești",
            "Hârşeni",
            "Hârseşti",
            "Hârşova",
            "Hărțăgani",
            "Hărțău",
            "Hârtieşti",
            "Hârtoape",
            "Hârtop",
            "Hășmaș",
            "Haţeg",
            "Havârna",
            "Heci",
            "Helegiu",
            "Heleșteni",
            "Hemeiuș",
            "Herăști",
            "Herculian",
            "Hereclean",
            "Herepea",
            "Herghelia",
            "Herla",
            "Hetiur",
            "Hida",
            "Hideaga",
            "Hidişelu de Sus",
            "Hilişeu-Horia",
            "Hinova",
            "Hlăpești",
            "Hlipiceni",
            "Hoceni",
            "Hociungi",
            "Hodac",
            "Hodaia",
            "Hodod",
            "Hodoni",
            "Hodora",
            "Hodoșa",
            "Hodoșa",
            "Hoghilag",
            "Hoghiz",
            "Holbav",
            "Holboca",
            "Holod",
            "Holt",
            "Homocea",
            "Homorâciu",
            "Homorod",
            "Homorodu de Jos",
            "Hopârta",
            "Horea",
            "Horezu",
            "Horgeşti",
            "Horia",
            "Horia",
            "Horia",
            "Horia",
            "Horleşti",
            "Horoatu Crasnei",
            "Horodnic de Jos",
            "Horodnic de Sus",
            "Horodniceni",
            "Hotar",
            "Hotarele",
            "Hoteni",
            "Hovrila",
            "Hudeşti",
            "Huedin",
            "Hulubeşti",
            "Hulubești",
            "Humele",
            "Humoreni",
            "Humulești",
            "Hunedoara",
            "Hunia",
            "Hurdugi",
            "Hurezani",
            "Hurjuieni",
            "Huruiești",
            "Husasău de Tinca",
            "Huşi",
            "Husnicioara",
            "Huta Certeze",
            "I. C. Brătianu",
            "I. L. Caragiale",
            "Iablaniţa",
            "Iacobeni",
            "Iacobeni",
            "Iacobeni",
            "Iadăra",
            "Iana",
            "Ianca",
            "Ianca",
            "Iancu Jianu",
            "Ianoșda",
            "Iapa",
            "Iara",
            "Iara de Mureș",
            "Iaşi",
            "Iaslovăț",
            "Iazu",
            "Iazu",
            "Iazu Nou",
            "Iazurile",
            "Ibăneşti",
            "Ibănești",
            "Ibănești-Pădure",
            "Ibrianu",
            "Icland",
            "Iclandu Mare",
            "Iclănzel",
            "Iclod",
            "Icoana",
            "Icoana",
            "Icușeni",
            "Icuseşti",
            "Ideciu de Jos",
            "Idicel-Pădure",
            "Idiciu",
            "Idrifaia",
            "Iecea Mare",
            "Iecea Mică",
            "Iedera de Jos",
            "Iedera de Sus",
            "Iepureşti",
            "Iernut",
            "Iernuțeni",
            "Ieud",
            "Iezeru",
            "Igești",
            "Ighiel",
            "Ighișu Nou",
            "Ighiu",
            "Igneşti",
            "Igoiu",
            "Igriș",
            "Ihod",
            "Ilba",
            "Ileana",
            "Ileanda",
            "Ilia",
            "Ilieni",
            "Ilieni",
            "Ilieși",
            "Ilioara",
            "Ilișești",
            "Ilovăț",
            "Iloviţa",
            "Ilva Mare",
            "Ilva Mică",
            "Imper",
            "Inău",
            "Independenţa",
            "Independenţa",
            "Independenţa",
            "Ineu",
            "Ineu",
            "Ineu",
            "Inotești",
            "Însurăţei",
            "Întorsura",
            "Întorsura Buzăului",
            "Intregalde",
            "Întrerâuri",
            "Iod",
            "Iojib",
            "Ion Corvin",
            "Ion Creangă",
            "Ion Neculce",
            "Ion Roată",
            "Ionășeni",
            "Ioneşti",
            "Ioneşti",
            "Ionești",
            "Iordăcheanu",
            "Ip",
            "Ipatele",
            "Ipoteşti",
            "Ipotești",
            "Iratoşu",
            "Irești",
            "Isaccea",
            "Işalniţa",
            "Iscroni",
            "Isla",
            "Islaz",
            "Islaz",
            "Iștan-Tău",
            "Iștihaza",
            "Istria",
            "Isverna",
            "Iteşti",
            "Iugani",
            "Ivănești",
            "Ivănești",
            "Iveşti",
            "Iveşti",
            "Izbiceni",
            "Izimșa",
            "Izvin",
            "Izvoare",
            "Izvoare",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele",
            "Izvoarele Sucevei",
            "Izvoru",
            "Izvoru",
            "Izvoru Bârzii",
            "Izvoru Berheciului",
            "Izvoru Crişului",
            "Izvoru de Sus",
            "Izvoru Dulce",
            "Jabenița",
            "Jacodu",
            "Jacu",
            "Jamu Mare",
            "Jariştea",
            "Jebel",
            "Jegălia",
            "Jiana",
            "Jiana Veche",
            "Jibert",
            "Jiblea Veche",
            "Jibou",
            "Jichişu de Jos",
            "Jidoștița",
            "Jidvei",
            "Jieni",
            "Jijila",
            "Jilava",
            "Jilavele",
            "Jimbolia",
            "Jina",
            "Jirlău",
            "Jirov",
            "Jitia",
            "Jiu-Paroșeni",
            "Joiţa",
            "Joldești",
            "Jorăşti",
            "Jorăști",
            "Joseni",
            "Josenii Bârgăului",
            "Jugăstreni",
            "Jugur",
            "Jugureanu",
            "Jugureni",
            "Jupâneşti",
            "Jupânești",
            "Jurilovca",
            "Lăceni",
            "Lacu Sărat",
            "Lăculețe",
            "Lăcusteni",
            "Lăcusteni",
            "Lădeşti",
            "Laloşu",
            "Lămășeni",
            "Lancrăm",
            "Lanurile",
            "Lapoș",
            "Lăpoș",
            "Lăpugiu de Jos",
            "Lăpuş",
            "Lăpușel",
            "Lăpușna",
            "Lăpuşnicel",
            "Lăpuşnicu Mare",
            "Larga",
            "Larga",
            "Larga",
            "Largu",
            "Lăschia",
            "Lăscud",
            "Laslău Mare",
            "Laslău Mic",
            "Laslea",
            "Lăureni",
            "Laza",
            "Lăzarea",
            "Lăzăreni",
            "Lăzărești",
            "Lazu Baciului",
            "Lazuri",
            "Lazuri",
            "Lazuri de Beiuş",
            "Lechincioara",
            "Lechința",
            "Lechinţa",
            "Lefaia",
            "Lehliu",
            "Lehliu-Gară",
            "Leicești",
            "Leleasca",
            "Lelese",
            "Leleşti",
            "Leliceni",
            "Lemnia",
            "Lenauheim",
            "Leniș",
            "Leorda",
            "Leordeni",
            "Leordeni",
            "Leordina",
            "Leorința",
            "Leorința-Șăulia",
            "Lepindea",
            "Lereşti",
            "Lespezi",
            "Lespezi",
            "Lespezi",
            "Leşu",
            "Letca",
            "Letca Nouă",
            "Letca Veche",
            "Leţcani",
            "Letea Veche",
            "Leu",
            "Libotin",
            "Licurici",
            "Liebling",
            "Lieşti",
            "Lilieci",
            "Liliești",
            "Limanu",
            "Limpeziș",
            "Linț",
            "Lipăneşti",
            "Lipia",
            "Lipia",
            "Lipniţa",
            "Lipova",
            "Lipova",
            "Lipovăţ",
            "Lipovu",
            "Lisa",
            "Lisa",
            "Lișteava",
            "Liţa",
            "Liteni",
            "Liteni",
            "Liubcova",
            "Livada",
            "Livada",
            "Livada de Bihor",
            "Livadea",
            "Livadia",
            "Livezeni",
            "Livezeni",
            "Livezi",
            "Livezi",
            "Livezi-Vale",
            "Livezile",
            "Livezile",
            "Livezile",
            "Livezile",
            "Loamneş",
            "Logig",
            "Logreşti",
            "Logrești Moșteni",
            "Loloiasca",
            "Lopadea Nouă",
            "Lopătari",
            "Loțu",
            "Lovrin",
            "Lozna",
            "Lozna",
            "Lucăceni",
            "Lucăcești",
            "Lucieni",
            "Luciu",
            "Luciu",
            "Ludași",
            "Ludeşti",
            "Ludoş",
            "Luduș",
            "Lueta",
            "Lugaşu de Jos",
            "Lugoj",
            "Luica",
            "Luieriu",
            "Luizi-Călugăra",
            "Lumina",
            "Luminiș",
            "Luna",
            "Luna de Sus",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca",
            "Lunca Banului",
            "Lunca Bradului",
            "Lunca Cernii de Jos",
            "Lunca Cetățuii",
            "Lunca Corbului",
            "Lunca de Jos",
            "Lunca de Sus",
            "Lunca Ilvei",
            "Lunca Jariștei",
            "Lunca la Tisa",
            "Lunca Leșului",
            "Lunca Mureșului",
            "Lunca Mureşului",
            "Lunca Prahovei",
            "Lunca Priporului",
            "Luncani",
            "Luncaviţa",
            "Luncaviţa",
            "Luncoiu de Jos",
            "Luncșoara",
            "Lunga",
            "Lungani",
            "Lungeşti",
            "Lunguleţu",
            "Lupac",
            "Lupeni",
            "Lupeni",
            "Lupşa",
            "Lupşanu",
            "Macea",
            "Măceşu de Jos",
            "Măceşu de Sus",
            "Măcicășești",
            "Măcin",
            "Mădăras",
            "Mădăraş",
            "Mădăraș",
            "Mădăraș",
            "Mădărășeni",
            "Mădârjac",
            "Măderat",
            "Mădulari",
            "Măerişte",
            "Măgeşti",
            "Măgherani",
            "Măgheruș",
            "Măgireşti",
            "Maglavit",
            "Magula",
            "Măgura",
            "Măgura",
            "Măgura",
            "Măgura Ilvei",
            "Măgurele",
            "Măgurele",
            "Măgureni",
            "Măgureni",
            "Măguri",
            "Măguri-Răcătău",
            "Mahmudia",
            "Maia",
            "Maia",
            "Maiad",
            "Măicăneşti",
            "Măieru",
            "Măieruş",
            "Mailat",
            "Maiorești",
            "Mal",
            "Mălăești",
            "Malaia",
            "Mălâncrav",
            "Malcoci",
            "Măldăeni",
            "Maldaoci",
            "Măldăreşti",
            "Malea",
            "Mălini",
            "Maliuc",
            "Malnaş",
            "Malovăţ",
            "Malu",
            "Malu cu Flori",
            "Malu Mare",
            "Malu Roșu",
            "Malu Spart",
            "Mălureni",
            "Măluşteni",
            "Mamaia-Sat",
            "Mănăilești",
            "Manasia",
            "Mănăstirea",
            "Mănăstirea",
            "Mânăstirea",
            "Mânăstirea Caşin",
            "Mănăstirea Humorului",
            "Mânăstireni",
            "Mănăştiur",
            "Mănăștur",
            "Mânău",
            "Mândra",
            "Mândrești",
            "Mândrești",
            "Mândruloc",
            "Măneciu-Ungureni",
            "Măneşti",
            "Măneşti",
            "Mangalia",
            "Manoleasa",
            "Mânzăleşti",
            "Mara",
            "Mărăcineni",
            "Mărăcineni",
            "Mărășești",
            "Mărășești",
            "Măraşu",
            "Marca",
            "Mărcești",
            "Mărculeni",
            "Mărculești",
            "Mareș",
            "Marga",
            "Mărgăriteşti",
            "Mărgău",
            "Marghita",
            "Margina",
            "Marginea",
            "Mărgineni",
            "Mărgineni",
            "Mărgineni Slobozia",
            "Mărgineni-Munteni",
            "Mărginenii de Jos",
            "Mărginenii de Sus",
            "Marin",
            "Mărişel",
            "Mărişelu",
            "Măriței",
            "Marpod",
            "Mârşa",
            "Mârșa",
            "Mârşani",
            "Mârţeşti",
            "Mărtineşti",
            "Mărtiniş",
            "Măru",
            "Mărunţei",
            "Mărunțișu",
            "Mârzăneşti",
            "Mâsca",
            "Maşloc",
            "Mastacăn",
            "Măstăcani",
            "Mătăcina",
            "Mătăsari",
            "Mătăsaru",
            "Mățău",
            "Matca",
            "Mateeşti",
            "Matei",
            "Mătești",
            "Mătrici",
            "Măureni",
            "Mavrodin",
            "Mavrodin",
            "Maxenu",
            "Măxineni",
            "Maxut",
            "Măzănăești",
            "Medgidia",
            "Mediaş",
            "Medieşu Aurit",
            "Mehadia",
            "Mehadica",
            "Melineşti",
            "Mera",
            "Mera",
            "Merei",
            "Mereni",
            "Mereni",
            "Merenii de Jos",
            "Merenii de Sus",
            "Mereşti",
            "Merghindeal",
            "Meri",
            "Merii Petchii",
            "Merişani",
            "Merișani",
            "Merișor",
            "Merișor",
            "Merișoru",
            "Meseşenii de Jos",
            "Mesteacăn",
            "Meștera",
            "Meteş",
            "Meziad",
            "Mica",
            "Mica",
            "Micăsasa",
            "Miceşti",
            "Micești",
            "Miceştii de Câmpie",
            "Micfalău",
            "Micleşti",
            "Micula",
            "Miercurea Nirajului",
            "Miercurea Sibiului",
            "Miercurea-Ciuc",
            "Mihăeşti",
            "Mihăeşti",
            "Mihăeşti",
            "Mihai Bravu",
            "Mihai Bravu",
            "Mihai Bravu",
            "Mihai Bravu",
            "Mihai Viteazu",
            "Mihai Viteazu",
            "Mihai Viteazu",
            "Mihai Viteazu",
            "Mihai Vodă",
            "Mihăiești",
            "Mihail Kogălniceanu",
            "Mihail Kogălniceanu",
            "Mihail Kogălniceanu",
            "Mihăileni",
            "Mihăileni",
            "Mihăileni",
            "Mihăileşti",
            "Mihăileşti",
            "Mihălăşeni",
            "Mihălceni",
            "Mihalţ",
            "Miheșu de Câmpie",
            "Mihoveni",
            "Mija",
            "Mijlocenii Bârgăului",
            "Milaş",
            "Milășel",
            "Milcoiu",
            "Milcovățu",
            "Milcovul",
            "Mileanca",
            "Milişăuţi",
            "Miloşeşti",
            "Mineri",
            "Minieri",
            "Mintiu Gherlii",
            "Miorcani",
            "Mioveni",
            "Mirăslău",
            "Mircea Vodă",
            "Mircea Vodă",
            "Mirceşti",
            "Mirceștii Noi",
            "Mireşu Mare",
            "Mireșu Mare",
            "Mirigioaia",
            "Miron Costin",
            "Mironeasa",
            "Mironu",
            "Miroşi",
            "Miroslava",
            "Miroslăvești",
            "Miroslovești",
            "Mirşid",
            "Mișca",
            "Mișca",
            "Mischii",
            "Misentea",
            "Mislea",
            "Mitoc",
            "Mitocu Dragomirnei",
            "Mitreni",
            "Mitrești",
            "Mitrofani",
            "Mizil",
            "Mlenăuți",
            "Moacşa",
            "Moara Carp",
            "Moara de Jos",
            "Moara Domnească",
            "Moara Grecilor",
            "Moara Nica",
            "Moara Vlăsiei",
            "Moceşti",
            "Mocira",
            "Mociu",
            "Modelu",
            "Moeciu de Jos",
            "Mofleni",
            "Moftinu Mare",
            "Moftinu Mic",
            "Mogești",
            "Mogoaia",
            "Mogoș",
            "Mogoşani",
            "Mogoşeşti",
            "Mogoșești",
            "Mogoşeşti-Siret",
            "Mogoşoaia",
            "Moieciu de Jos",
            "Moineşti",
            "Moișa",
            "Moisei",
            "Moișeni",
            "Moldova Nouă",
            "Moldova Suliţa",
            "Moldoveneşti",
            "Moldoveni",
            "Moldoveni",
            "Moldoveni",
            "Moldoviţa",
            "Moneasa",
            "Monor",
            "Morăreni",
            "Morăreşti",
            "Moraviţa",
            "Moreni",
            "Morești",
            "Moroeni",
            "Morteni",
            "Morunglav",
            "Moruț",
            "Moscu",
            "Moşna",
            "Moşna",
            "Moșneni",
            "Moşniţa Nouă",
            "Moșnița Veche",
            "Moşoaia",
            "Moșteni",
            "Moșuni",
            "Moțăieni",
            "Moţăţei",
            "Moţca",
            "Motoşeni",
            "Motru",
            "Movila",
            "Movila Banului",
            "Movila Miresii",
            "Movileni",
            "Movileni",
            "Movileni",
            "Moviliţa",
            "Moviliţa",
            "Mozăceni",
            "Muereasca",
            "Mugeni",
            "Muncelu de Sus",
            "Municipiul  Adjud",
            "Municipiul  Codlea",
            "Municipiul  Lupeni",
            "Municipiul  Topliţa",
            "Municipiul  Vulcan",
            "Municipiul Aiud",
            "Municipiul Alba Iulia",
            "Municipiul Alexandria",
            "Municipiul Arad",
            "Municipiul Bacãu",
            "Municipiul Bãileşti",
            "Municipiul Bârlad",
            "Municipiul Beiuş",
            "Municipiul Bistriţa",
            "Municipiul Blaj",
            "Municipiul Botoşani",
            "Municipiul Brad",
            "Municipiul Brãila",
            "Municipiul Braşov",
            "Municipiul Buzău",
            "Municipiul Calafat",
            "Municipiul Călăraşi",
            "Municipiul Câmpia Turzii",
            "Municipiul Câmpina",
            "Municipiul Câmpulung",
            "Municipiul Câmpulung Moldovenesc",
            "Municipiul Caracal",
            "Municipiul Caransebeş",
            "Municipiul Carei",
            "Municipiul Cluj-Napoca",
            "Municipiul Constanţa",
            "Municipiul Craiova",
            "Municipiul Curtea de Argeș",
            "Municipiul Dej",
            "Municipiul Deva",
            "Municipiul Dorohoi",
            "Municipiul Drãgãşani",
            "Municipiul Drobeta-Turnu Severin",
            "Municipiul Fãgãraş",
            "Municipiul Fãlticeni",
            "Municipiul Feteşti",
            "Municipiul Focşani",
            "Municipiul Galaţi",
            "Municipiul Gheorgheni",
            "Municipiul Gherla",
            "Municipiul Giurgiu",
            "Municipiul Hunedoara",
            "Municipiul Huşi",
            "Municipiul Iaşi",
            "Municipiul Lugoj",
            "Municipiul Mangalia",
            "Municipiul Marghita",
            "Municipiul Medgidia",
            "Municipiul Mediaş",
            "Municipiul Miercurea Ciuc",
            "Municipiul Moineşti",
            "Municipiul Moreni",
            "Municipiul Motru",
            "Municipiul Odorheiu Secuiesc",
            "Municipiul Olteniţa",
            "Municipiul Oneşti",
            "Municipiul Oradea",
            "Municipiul Orãştie",
            "Municipiul Orşova",
            "Municipiul Paşcani",
            "Municipiul Petroşani",
            "Municipiul Piatra-Neamţ",
            "Municipiul Piteşti",
            "Municipiul Ploieşti",
            "Municipiul Rãdãuţi",
            "Municipiul Râmnicu Sãrat",
            "Municipiul Râmnicu Vâlcea",
            "Municipiul Reşiţa",
            "Municipiul Roman",
            "Municipiul Roșiorii de Vede",
            "Municipiul Sãcele",
            "Municipiul Salonta",
            "Municipiul Satu Mare",
            "Municipiul Sebeş",
            "Municipiul Sfântu Gheorghe",
            "Municipiul Sibiu",
            "Municipiul Slatina",
            "Municipiul Suceava",
            "Municipiul Târgovişte",
            "Municipiul Târgu Jiu",
            "Municipiul Târgu Secuiesc",
            "Municipiul Tecuci",
            "Municipiul Timişoara",
            "Municipiul Tulcea",
            "Municipiul Turda",
            "Municipiul Turnu Magurele",
            "Municipiul Urziceni",
            "Municipiul Vaslui",
            "Municipiul Vatra Dornei",
            "Municipiul Zalãu",
            "Muntele Mic",
            "Munteni",
            "Munteni",
            "Munteni Buzău",
            "Muntenii de Jos",
            "Muntenii de Sus",
            "Mura Mare",
            "Mura Mică",
            "Mureni",
            "Mureșeni",
            "Murfatlar",
            "Murgași",
            "Murgeni",
            "Murgești",
            "Murgeşti",
            "Murighiol",
            "Mușătești",
            "Muşeniţa",
            "Muşeteşti",
            "Nadab",
            "Nadăș",
            "Nadășa",
            "Nadeș",
            "Nădlac",
            "Nădrag",
            "Năeni",
            "Naidăș",
            "Naipu",
            "Nalbant",
            "Nămoloasa",
            "Nămoloasa-Sat",
            "Nana",
            "Nandra",
            "Nănești",
            "Năneşti",
            "Nanov",
            "Năpradea",
            "Năruja",
            "Năsăud",
            "Năsturelu",
            "Năvodari",
            "Năvodari",
            "Nazna",
            "Neagra",
            "Neagra Șarului",
            "Neaua",
            "Necşeşti",
            "Nedeia",
            "Nedelea",
            "Negoești",
            "Negoi",
            "Negoiești",
            "Negoiești",
            "Negomir",
            "Negostina",
            "Negraşi",
            "Negreia",
            "Negreni",
            "Negreni",
            "Negrenii de Câmpie",
            "Negreşti",
            "Negrești",
            "Negreşti-Oaş",
            "Negri",
            "Negrileasa",
            "Negrilești",
            "Negrilești",
            "Negrilești",
            "Negru Vodă",
            "Nehoiu",
            "Nemțișor",
            "Nenciulești",
            "Nepos",
            "Nerău",
            "Nereju",
            "Nereju Mic",
            "Neudorf",
            "Nicolae Bălcescu",
            "Nicolae Bălcescu",
            "Nicolae Bălcescu",
            "Nicolae Bălcescu",
            "Nicolae Bălcescu",
            "Nicolae Bălcescu",
            "Nicolae Titulescu",
            "Nicolești",
            "Nicolești",
            "Nicoreşti",
            "Nicşeni",
            "Niculești",
            "Niculiţel",
            "Nima Milășelului",
            "Nima Râciului",
            "Nimigea de Jos",
            "Nimigea de Sus",
            "Nireș",
            "Nisipari",
            "Nisiporești",
            "Nistoreşti",
            "Nistru",
            "Niţchidorf",
            "Nocrich",
            "Nojorid",
            "Noşlac",
            "Nou",
            "Novaci",
            "Novaci",
            "Nucet",
            "Nucet",
            "Nuci",
            "Nucşoara",
            "Nufăru",
            "Nuntași",
            "Nuşeni",
            "Nuşfalău",
            "Oancea",
            "Oanțu",
            "Oar",
            "Oarba de Mureș",
            "Oarda",
            "Oarja",
            "Oarja Sat",
            "Oarţa de Jos",
            "Oarța de Sus",
            "Obârşia",
            "Obârșia de Câmp",
            "Obârşia-Cloşani",
            "Obârșie",
            "Oboga",
            "Obreja",
            "Obrejița",
            "Ocina de Sus",
            "Ocland",
            "Ocna de Jos",
            "Ocna de Sus",
            "Ocna Dejului",
            "Ocna Mureş",
            "Ocna Sibiului",
            "Ocna Şugatag",
            "Ocniţa",
            "Ocnița",
            "Ocoliș",
            "Ocoliş",
            "Ocolna",
            "Odaia Manolache",
            "Odaia Turcului",
            "Odăile",
            "Odești",
            "Odobeşti",
            "Odobeşti",
            "Odobești",
            "Odoreu",
            "Odorheiu Secuiesc",
            "Odrihei",
            "Oeștii Pământeni",
            "Oglinzi",
            "Ogra",
            "Ograda",
            "Ogretin",
            "Ogrezeni",
            "Ohaba",
            "Ohaba Lungă",
            "Oinacu",
            "Oituz",
            "Ojdula",
            "Olănești",
            "Olanu",
            "Olari",
            "Olari",
            "Olari",
            "Olcea",
            "Oleșești",
            "Ologeni",
            "Olteanca",
            "Olteneşti",
            "Olteni",
            "Olteni",
            "Olteniţa",
            "Oltina",
            "Onceşti",
            "Oncești",
            "Oneaga",
            "Onesti",
            "Oniceni",
            "Oniceni",
            "Onuca",
            "Oporelu",
            "Oprişor",
            "Optaşi",
            "Oradea",
            "Oraş abrud",
            "Oraş Agnita",
            "Oraș Aleşd",
            "Oraş Amara",
            "Oraş Anina",
            "Oraş Aninoasa",
            "Oraș Ardud",
            "Oraş Avrig",
            "Oraş Azuga",
            "Oraş Babadag",
            "Oraş Bãbeni",
            "Oraş Baia De Aramã",
            "Oraş Baia de Arieş",
            "Oraş Bãicoi",
            "Oraş Bãile Govora",
            "Oraş Bãile Herculane",
            "Oraş Bãile Olãneşti",
            "Oraş Bãile Tuşnad",
            "Oraş Bãlan",
            "Oraş Bãlceşti",
            "Oraş Balş",
            "Oraş Bãneasa",
            "Oraş Baraolt",
            "Oraş Bechet",
            "Oraş Beclean",
            "Oraş Berbeşti",
            "Oraş Bereşti",
            "Oraş Bicaz",
            "Oraş Bocşa",
            "Oraş Boldeşti-Scãeni",
            "Oraş Bolintin-Vale",
            "Oraş Borsec",
            "Oraş Bragadiru",
            "Oraş Breaza",
            "Oraş Brezoi",
            "Oraş Broşteni",
            "Oraş Bucecea",
            "Oraş Budeşti",
            "Oraş Buftea",
            "Oraş Buhuşi",
            "Oraş Bumbeşti-Jiu",
            "Oraş Buşteni",
            "Oraş Buziaş",
            "Oraş Cajvana",
            "Oraş Cãlan",
            "Oraş Cãlimãneşti",
            "Oraş Câmpeni",
            "Oraş Cãzãneşti",
            "Oraş Cernavodã",
            "Oraş Chişineu-Criş",
            "Oraş Chitila",
            "Oraş Ciacova",
            "Oraş Cisnãdie",
            "Oraş Comãneşti",
            "Oraş Comarnic",
            "Oraş Copşa Micã",
            "Oraş Corabia",
            "Oraș Costeşti",
            "Oraş Covasna",
            "Oraş Cristuru Secuiesc",
            "Oraş Cugir",
            "Oraş Curtici",
            "Oraş Dãbuleni",
            "Oraş Darabani",
            "Oraş Dãrmãneşti",
            "Oraş Deta",
            "Oraş Dolhasca",
            "Oraş Drãgãneşti-Olt",
            "Oraş Dumbrãveni",
            "Oraş Eforie",
            "Oraş Fãget",
            "Oraş Fãurei",
            "Oraş Fieni",
            "Oraş Fierbinţi-Târg",
            "Oraş Filiaşi",
            "Oraş Flãmânzi",
            "Oraş Frasin",
            "Oraş Fundulea",
            "Oraş Gãeşti",
            "Oraş Gãtaia",
            "Oraş Geoagiu",
            "Oraş Ghimbav",
            "Oraş Gura Humorului",
            "Oraş Hârlãu",
            "Oraş Hârşova",
            "Oraş Haţeg",
            "Oraş Horezu",
            "Oraş Huedin",
            "Oraş Ianca",
            "Oraş Ineu",
            "Oraş Însurãţei",
            "Oraş Întorsura Buzãului",
            "Oraş Isaccea",
            "Oraș Jibou",
            "Oraş Jimbolia",
            "Oraş Lehliu Garã",
            "Oraş Lipova",
            "Oraş Liteni",
            "Oraș Livada",
            "Oraş Mãcin",
            "Oraş Mãgurele",
            "Oraş Mãrãşeşti",
            "Oraş Miercurea Sibiului",
            "Oraş Mihãileşti",
            "Oraş Milişãuţi",
            "Oraș Mioveni",
            "Oraş Mizil",
            "Oraş Murfatlar",
            "Oraș Murgeni",
            "Oraş Nãdlac",
            "Oraş Nãsãud",
            "Oraş Nãvodari",
            "Oraş Negreşti",
            "Oraş Negreşti-Oaş",
            "Oraş Negru Vodã",
            "Oraş Nehoiu",
            "Oraş Nucet",
            "Oraş Ocna Mureş",
            "Oraş Ocna Sibiului",
            "Oraş Ocnele Mari",
            "Oraş Odobeşti",
            "Oraş Oraviţa",
            "Oraş Oţelu Roşu",
            "Oraş Otopeni",
            "Oraş Ovidiu",
            "Oraş Panciu",
            "Oraş Pâncota",
            "Oraş Pantelimon",
            "Oraş Pãtârlagele",
            "Oraş Petrila",
            "Oraş Piatra-Olt",
            "Oraş Plopeni",
            "Oraş Podu Iloaiei",
            "Oraş Pogoanele",
            "Oraş Popeşti Leordeni",
            "Oraş Potcoava",
            "Oraş Predeal",
            "Oraş Pucioasa",
            "Oraş Rãcari",
            "Oraş Râşnov",
            "Oraş Recaş",
            "Oraş Rovinari",
            "Oraş Roznov",
            "Oraş Rupea",
            "Oraş Sãcueni",
            "Oraş Salcea",
            "Oraş Sãlişte",
            "Oraş Sângeorz-Bãi",
            "Oraş Sânnicolau Mare",
            "Oraş Sântana",
            "Oraş Sãveni",
            "Oraş Scorniceşti",
            "Oraş Sebiş",
            "Oraş Segarcea",
            "Oraş Simeria",
            "Oraş Şimleu Silvaniei",
            "Oraş Sinaia",
            "Oraş Siret",
            "Oraş Slãnic",
            "Oraş Slãnic-Moldova",
            "Oraş Solca",
            "Oraş Ştefãneşti",
            "Oraș Ştefăneşti",
            "Oraş Ştei",
            "Oraş Strehaia",
            "Oraş Sulina",
            "Oraş Tãlmaciu",
            "Oraş Ţãndãrei",
            "Oraș Târgu Bujor",
            "Oraş Târgu Cãrbuneşti",
            "Oraş Târgu Frumos",
            "Oraş Târgu Neamţ",
            "Oraş Târgu Ocna",
            "Oraș Tăşnad",
            "Oraş Techirghiol",
            "Oraş Teiuş",
            "Oraş Ţicleni",
            "Oraş Tismana",
            "Oraş Titu",
            "Oraș Topoloveni",
            "Oraş Turceni",
            "Oraş Uricani",
            "Oraş Urlaţi",
            "Oraş Valea Lui Mihai",
            "Oraş Vãlenii De Munte",
            "Oraş Vânju Mare",
            "Oraş Vaşcãu",
            "Oraş Vicovu De Sus",
            "Oraş Victoria",
            "Oraş Videle",
            "Oraş Vlãhiţa",
            "Oraş Zãrneşti",
            "Oraş Zimnicea",
            "Oraş Zlatna",
            "Orășa",
            "Orășeni-Deal",
            "Orăştie",
            "Orăştioara de Sus",
            "Oraşu Nou",
            "Oraviţa",
            "Orbeasca de Jos",
            "Orbeasca de Sus",
            "Orbeni",
            "Oreavu",
            "Orevița Mare",
            "Orlat",
            "Orlea",
            "Orlea Nouă",
            "Orleşti",
            "Ormeniș",
            "Ormeniş",
            "Orodel",
            "Oroftiana",
            "Oroiu",
            "Orosia",
            "Orșova",
            "Orşova",
            "Orţişoara",
            "Orțița",
            "Osebiți",
            "Oşeşti",
            "Osica de Jos",
            "Osica de Sus",
            "Osoi",
            "Oșorhei",
            "Ostra",
            "Ostrov",
            "Ostrov",
            "Ostroveni",
            "Ostrovu",
            "Ostrovu Mare",
            "Otelec",
            "Oţeleni",
            "Oţelu Roşu",
            "Oteşani",
            "Otetelișu",
            "Otopeni",
            "Oveselu",
            "Ovidiu",
            "Ozd",
            "Ozun",
            "Păcureni",
            "Păcureţi",
            "Padea",
            "Padeş",
            "Padina",
            "Padina Matei",
            "Padina Mică",
            "Pădurea",
            "Pădurea Neagră",
            "Pădureni",
            "Pădureni",
            "Pădureni",
            "Pădureni",
            "Pădureni",
            "Pădureni",
            "Păingeni",
            "Palanca",
            "Palanca",
            "Palanca",
            "Pălatca",
            "Palazu Mare",
            "Paleu",
            "Paltin",
            "Păltineni",
            "Păltiniş",
            "Păltiniş",
            "Păltinoasa",
            "Panaci",
            "Pănătău",
            "Pănceşti",
            "Pânceşti",
            "Panciu",
            "Pâncota",
            "Pănet",
            "Pângărăcior",
            "Pângăraţi",
            "Pantazi",
            "Pantelimon",
            "Pantelimon",
            "Pantelimon de Jos",
            "Panticeu",
            "Păpăuți",
            "Papiu Ilarian",
            "Pâraie",
            "Pârâu",
            "Pârâu Crucii",
            "Pârâu Mare",
            "Parava",
            "Pârcovaci",
            "Pardina",
            "Pardoşi",
            "Parepa-Rușani",
            "Pârgăreşti",
            "Părhăuți",
            "Parincea",
            "Pârjol",
            "Pârscov",
            "Pârşcoveni",
            "Parța",
            "Părteştii de Jos",
            "Parva",
            "Păsăreni",
            "Paşcani",
            "Păstrăveni",
            "Pătârlagele",
            "Pătrăuţi",
            "Patru Frați",
            "Pătulele",
            "Pătuleni",
            "Păuca",
            "Păucișoara",
            "Păuleni-Ciuc",
            "Păuleşti",
            "Păuleşti",
            "Păulești",
            "Păuliş",
            "Păuloaia",
            "Păuneşti",
            "Păuşeşti",
            "Păușești",
            "Păuşeşti-Măglaşi",
            "Peceiu",
            "Peceneaga",
            "Pechea",
            "Pecica",
            "Pecineaga",
            "Peciu Nou",
            "Peregu Mare",
            "Peregu Mic",
            "Peretu",
            "Periam",
            "Pericei",
            "Perieni",
            "Perieni",
            "Perieţi",
            "Perieţi",
            "Perii Broșteni",
            "Periș",
            "Periş",
            "Perişani",
            "Perișor",
            "Perişoru",
            "Perișoru",
            "Perșani",
            "Perșinari",
            "Perșunari",
            "Pesac",
            "Pesceana",
            "Peșteana de Jos",
            "Peșteana Jiu",
            "Peştera",
            "Peștiș",
            "Peştişani",
            "Peștișu Mare",
            "Peştişu Mic",
            "Petea",
            "Petelea",
            "Peteritea",
            "Petrăchioaia",
            "Petreşti",
            "Petreşti",
            "Petrești",
            "Petrești",
            "Petreştii de Jos",
            "Petreu",
            "Petricani",
            "Petrila",
            "Petrilaca",
            "Petrilaca de Mureș",
            "Petriş",
            "Petriș",
            "Petroşani",
            "Petroșnița",
            "Petrova",
            "Pianu de Jos",
            "Pianu de Sus",
            "Piatra",
            "Piatra",
            "Piatra",
            "Piatra",
            "Piatra Neamţ",
            "Piatra Olt",
            "Piatra Şoimului",
            "Picior de Munte",
            "Pieleştí",
            "Pietrari",
            "Pietrari",
            "Pietrarii de Sus",
            "Pietrele",
            "Pietreni",
            "Pietriceaua",
            "Pietriș",
            "Pietrișu",
            "Pietroasa",
            "Pietroasa",
            "Pietroasele",
            "Pietroşani",
            "Pietroşani",
            "Pietroșani",
            "Pietroşiţa",
            "Pietrosu",
            "Pietrosu",
            "Pildești",
            "Pilu",
            "Pipea",
            "Pipirig",
            "Pir",
            "Pişchia",
            "Piscoiu",
            "Pişcolt",
            "Piscu",
            "Piscu Nou",
            "Piscu Sadovei",
            "Piscu Vechi",
            "Pitaru",
            "Piteasca",
            "Piteşti",
            "Plătăreşti",
            "Platonești",
            "Plăvălari",
            "Pleașa",
            "Pleașov",
            "Pleniţa",
            "Pleşcuţa",
            "Pleșoi",
            "Pleşoiu",
            "Plevna",
            "Ploieşti",
            "Ploieștiori",
            "Plopana",
            "Plopeni",
            "Plopeni",
            "Plopeni",
            "Plopii Slăviţeştí",
            "Plopiş",
            "Plopiș",
            "Plopşoru",
            "Plopșoru",
            "Plopu",
            "Plopu",
            "Plopu",
            "Plosca",
            "Ploscoș",
            "Ploscuțeni",
            "Ploștina",
            "Plugari",
            "Plugova",
            "Plutonița",
            "Poarta",
            "Poarta Albă",
            "Poboru",
            "Pochidia",
            "Pociovaliștea",
            "Pocola",
            "Pocruia",
            "Podari",
            "Podeni",
            "Podenii Noi",
            "Podenii Vechi",
            "Podgoria",
            "Podoleni",
            "Podoleni",
            "Podolenii de Sus",
            "Podu Iloaiei",
            "Podu Turcului",
            "Poduri",
            "Poduri",
            "Poeni",
            "Pogăceaua",
            "Pogana",
            "Pogănești",
            "Pogoanele",
            "Pogonele",
            "Pogonești",
            "Poian",
            "Poiana",
            "Poiana",
            "Poiana",
            "Poiana",
            "Poiana",
            "Poiana",
            "Poiana",
            "Poiana",
            "Poiana Blenchii",
            "Poiana Botizii",
            "Poiana Câmpina",
            "Poiana Codrului",
            "Poiana Copăceni",
            "Poiana Crăcăoani",
            "Poiana Cristei",
            "Poiana Ilvei",
            "Poiana Lacului",
            "Poiana Mare",
            "Poiana Mărului",
            "Poiana Mărului",
            "Poiana Mărului (Brașov)",
            "Poiana Sibiului",
            "Poiana Stampei",
            "Poiana Țapului",
            "Poiana Teiului",
            "Poiana Vadului",
            "Poienari",
            "Poienari",
            "Poienarii Apostoli",
            "Poienarii Burchii",
            "Poieneşti",
            "Poieni",
            "Poieni",
            "Poieni-Solca",
            "Poienile",
            "Poienile de sub Munte",
            "Poienile Izei",
            "Poienile-Mogoş",
            "Poienița",
            "Pojejena",
            "Pojogeni",
            "Pojorâta",
            "Poloboc",
            "Polovragi",
            "Pomârla",
            "Pomezeu",
            "Pomi",
            "Ponoarele",
            "Ponor",
            "Popeni",
            "Popeşti",
            "Popeşti",
            "Popeşti",
            "Popeşti",
            "Popești",
            "Popești",
            "Popești",
            "Popeşti-Leordeni",
            "Poplaca",
            "Poporogi",
            "Popoveni",
            "Popricani",
            "Poroina Mare",
            "Poroschia",
            "Porumbac",
            "Porumbacu de Jos",
            "Porumbeni",
            "Porumbenii Mari",
            "Porumbești",
            "Posada",
            "Poşaga de Sus",
            "Poseștii-Pământeni",
            "Posta",
            "Poşta Câlnău",
            "Potău",
            "Potcoava",
            "Potcoava",
            "Potcoava Fălcoeni",
            "Potelu",
            "Potigrafu",
            "Potlogeni",
            "Potlogi",
            "Potoceni",
            "Praid",
            "Prăjani",
            "Prăjeni",
            "Prăjești",
            "Prajila",
            "Prapor",
            "Preajba",
            "Predeal",
            "Predeşti",
            "Prejmer",
            "Prelipca",
            "Preluca Nouă",
            "Preluca Veche",
            "Preuteşti",
            "Pribești",
            "Pribilești",
            "Priboieni",
            "Priboiu",
            "Pricaz",
            "Prigor",
            "Prigoria",
            "Pripoare",
            "Priponeşti",
            "Prisăcani",
            "Priseaca",
            "Prislop",
            "Pristol",
            "Probota",
            "Probota",
            "Produleşti",
            "Progresu",
            "Prohozești",
            "Proviţa de Jos",
            "Proviţa de Sus",
            "Prundeni",
            "Prundu",
            "Prundu",
            "Prundu Bârgăului",
            "Prunişor",
            "Pucheni",
            "Puchenii Mari",
            "Puchenii Moșneni",
            "Pucioasa",
            "Pufeşti",
            "Pui",
            "Puieni",
            "Puieşti",
            "Puieștii de Jos",
            "Pungeşti",
            "Punghina",
            "Purani",
            "Purcăreni",
            "Purcăreni",
            "Pușcași",
            "Pusta",
            "Pusta",
            "Pustiana",
            "Putineiu",
            "Putineiu",
            "Putna",
            "Puțuri",
            "Răbăgani",
            "Râca",
            "Răcăciuni",
            "Racameț",
            "Răcari",
            "Răcarii de Sus",
            "Răcăşdia",
            "Răcăuți",
            "Rachelu",
            "Rachieri",
            "Răchita",
            "Răchiteni",
            "Răchiţi",
            "Răchitoasa",
            "Răchitova",
            "Raciu",
            "Râciu",
            "Răcoasa",
            "Racoș",
            "Racova",
            "Racoviţa",
            "Racoviţa",
            "Racoviţa",
            "Racoviţa",
            "Racovița",
            "Racovița",
            "Racoviţeni",
            "Racşa",
            "Racu",
            "Rădăşeni",
            "Rădăuți",
            "Rădăuți-Prut",
            "Rădeni",
            "Rădeni",
            "Rădeşti",
            "Rădești",
            "Rădești",
            "Radna",
            "Rădoiești-Deal",
            "Radomir",
            "Radomireşti",
            "Radovan",
            "Radovanu",
            "Radu Vodă",
            "Răducăneni",
            "Rădulești",
            "Rafaila",
            "Râfov",
            "Râmești",
            "Ramna",
            "Râmnicelu",
            "Râmnicelu",
            "Râmniceni",
            "Râmnicu Sărat",
            "Râmnicu Vâlcea",
            "Râncăciov",
            "Ranta",
            "Rânzești",
            "Râpa de Jos",
            "Răpănaşu",
            "Rapoltu Mare",
            "Rasa",
            "Râşca",
            "Râșca",
            "Răscăeți",
            "Răscruci",
            "Râșești",
            "Rași",
            "Răşinari",
            "Răsmireşti",
            "Râşnov",
            "Rasova",
            "Rast",
            "Răstoaca",
            "Răstolița",
            "Răsuceni",
            "Răteşti",
            "Râu Alb de Jos",
            "Râu de Mori",
            "Râu Sadului",
            "Răuceşti",
            "Răuseni",
            "Războieni",
            "Războieni-Cetate",
            "Războienii de Jos",
            "Răzoare",
            "Răzoare",
            "Răzvad",
            "Răzvani",
            "Rebra",
            "Rebricea",
            "Rebrişoara",
            "Recaş",
            "Recea",
            "Recea",
            "Recea",
            "Recea",
            "Recea",
            "Recea Cristur",
            "Reci",
            "Redea",
            "Rediu",
            "Rediu",
            "Rediu",
            "Rediu",
            "Rediu",
            "Reghin",
            "Reghiu",
            "Remecioara",
            "Remetea",
            "Remetea",
            "Remetea",
            "Remetea Chioarului",
            "Remetea Mare",
            "Remeţi",
            "Remeți pe Someș",
            "Remuș",
            "Repedea",
            "Reşiţa",
            "Reteag",
            "Retevoiești",
            "Reviga",
            "Ribiţa",
            "Rieni",
            "Rigmani",
            "Rimetea",
            "Ripiceni",
            "Roata de Jos",
            "Robeasca",
            "Rociu",
            "Rodina",
            "Rodna",
            "Roeşti",
            "Rogova",
            "Rogoz",
            "Rohia",
            "Rojiște",
            "Roma",
            "Roman",
            "Românaşi",
            "Româneşti",
            "Românești",
            "Românești",
            "Românești",
            "Românești",
            "Români",
            "Români",
            "Romanu",
            "Romos",
            "Romuli",
            "Rona de Jos",
            "Rona de Sus",
            "Rora",
            "Roșcani",
            "Roșcani",
            "Roseţi",
            "Roşia",
            "Roşia",
            "Roşia de Amaradia",
            "Roşia de Secaş",
            "Roșia Montană",
            "Roşieşti",
            "Roşiile",
            "Roşiori",
            "Roşiori",
            "Roșiori",
            "Roșiori",
            "Roșiori",
            "Roșiori",
            "Roșiori",
            "Roșiorii de Vede",
            "Roșu",
            "Roteni",
            "Rotunda",
            "Rotunda",
            "Rotunda",
            "Roua",
            "Rovinari",
            "Rovine",
            "Rozavlea",
            "Roznov",
            "Rubla",
            "Rucăr",
            "Rucăreni",
            "Rudeni",
            "Rugășești",
            "Rugineşti",
            "Ruginoasa",
            "Ruginoasa",
            "Ruja",
            "Runcu",
            "Runcu",
            "Runcu",
            "Runcu Salvei",
            "Rupea",
            "Rus",
            "Rus",
            "Rusăneşti",
            "Rusca",
            "Rusca Montană",
            "Ruscova",
            "Ruseni",
            "Ruşeţu",
            "Rușii-Munți",
            "Rușor",
            "Săbăoani",
            "Săbăreni",
            "Sabasa",
            "Săbed",
            "Săbișa",
            "Săcădat",
            "Săcădat",
            "Săcălășeni",
            "Săcălaz",
            "Săcalu de Pădure",
            "Săcăreni",
            "Săcăşeni",
            "Săcel",
            "Săcel",
            "Săcele",
            "Săcele",
            "Săcelu",
            "Săceni",
            "Sacoșu Mare",
            "Sacoşu Turcesc",
            "Sacu",
            "Săcueni",
            "Săcueni",
            "Săcuieu",
            "Sadina",
            "Sadova",
            "Sadova",
            "Sadu",
            "Saelele",
            "Șaeș",
            "Şag",
            "Sâg",
            "Săgeata",
            "Sagna",
            "Şagu",
            "Săhăteni",
            "Sălacea",
            "Sălard",
            "Sălard",
            "Sălaşu de Sus",
            "Sălașuri",
            "Sălăţig",
            "Sălătrucel",
            "Sălătrucu",
            "Salcea",
            "Salcia",
            "Salcia",
            "Salcia",
            "Salcia",
            "Salcia Tudor",
            "Sălciile",
            "Sălcioara",
            "Sălcioara",
            "Sălcioara",
            "Sălcioara",
            "Sălciua de Sus",
            "Sălcud",
            "Sălcuţa",
            "Sălcuța",
            "Saligny",
            "Sălişte",
            "Săliște",
            "Săliștea",
            "Săliştea de Sus",
            "Săliştea-Deal",
            "Sălnița",
            "Salonta",
            "Sălsig",
            "Sâlța",
            "Salva",
            "Samarineşti",
            "Sâmbăta",
            "Sâmbăta de Sus",
            "Sâmbăteni",
            "Sâmbotin",
            "Sâmbriaș",
            "Sâmbureşti",
            "Şamşud",
            "Sânandrei",
            "Sâncel",
            "Sâncrai",
            "Sâncrai",
            "Sâncrăieni",
            "Sâncraiu",
            "Sâncraiu de Mureș",
            "Sândominic",
            "Şandra",
            "Șandru",
            "Sănduleni",
            "Sănduleşti",
            "Sângeorgiu de Câmpie",
            "Sângeorgiu de Mureș",
            "Sângeorgiu de Pădure",
            "Sângeorz-Băi",
            "Sânger",
            "Sângeru",
            "Sângeru de Pădure",
            "Sâniacob",
            "Sâniob",
            "Sanislău",
            "Sânișor",
            "Sânmărghita",
            "Sânmărghita",
            "Sânmartin",
            "Sânmartin",
            "Sânmărtin",
            "Sânmărtinu de Câmpie",
            "Sânmihai de Pădure",
            "Sânmihaiu Almaşului",
            "Sânmihaiu de Câmpie",
            "Sânmihaiu Român",
            "Sânnicoară",
            "Sânnicolau Mare",
            "Sânnicolau Român",
            "Sânpaul",
            "Sânpaul",
            "Sânpetru",
            "Sânpetru de Câmpie",
            "Sânpetru German",
            "Sânpetru Mare",
            "Sânsimion",
            "Sânsimion",
            "Șanț",
            "Santa Mare",
            "Sântămăria-Orlea",
            "Sântana",
            "Sântana de Mureș",
            "Sântandrei",
            "Santău",
            "Sântimbru",
            "Sântimbru",
            "Sântioana",
            "Sântioana de Mureș",
            "Sântion",
            "Sântu",
            "Sânvăsii",
            "Sânzieni",
            "Săpânţa",
            "Șapartoc",
            "Săpoca",
            "Săpunari",
            "Sarafinești",
            "Saraiu",
            "Sarasău",
            "Sărata",
            "Sărata",
            "Sărățeni",
            "Sărățeni",
            "Sărăuad",
            "Saravale",
            "Sârbeni",
            "Sârbi",
            "Sârbi",
            "Sârbi",
            "Șard",
            "Șardu Nirajului",
            "Sarichioi",
            "Sărmaş",
            "Şărmăşag",
            "Sărmășel",
            "Sărmașu",
            "Sarmizegetusa",
            "Șaroș pe Târnave",
            "Şaru Dornei",
            "Săruleşti",
            "Săruleşti",
            "Sărulești-Gară",
            "Săsar",
            "Sasca Montană",
            "Saschiz",
            "Săsciori",
            "Sascut",
            "Sascut-Sat",
            "Sat-Șugatag",
            "Satchinez",
            "Săteni",
            "Sătmărel",
            "Satu Mare",
            "Satu Mare",
            "Satu Mare",
            "Satu Mare",
            "Satu Nou",
            "Satu Nou",
            "Satu Nou",
            "Satu Nou",
            "Satu Nou",
            "Satu Nou Calopăr",
            "Satu Nou de Jos",
            "Satu Nou de Sus",
            "Satulung",
            "Sauca",
            "Săuca",
            "Săucești",
            "Săuleşti",
            "Șăulia",
            "Șăulița",
            "Șăușa",
            "Săvădisla",
            "Săvârşin",
            "Săveni",
            "Săveni",
            "Săvineşti",
            "Scăeşti",
            "Scânteia",
            "Scânteia",
            "Scânteiești",
            "Scărişoara",
            "Scărişoara",
            "Scheia",
            "Scheia",
            "Scheiu de Jos",
            "Scheiu de Sus",
            "Schela",
            "Schitu",
            "Schitu",
            "Schitu",
            "Schitu Frumoasa",
            "Schitu-Duca",
            "Schitu-Goleşti",
            "Schiulești",
            "Scoarţa",
            "Scobinţi",
            "Scorniceşti",
            "Scorţaru Nou",
            "Scorțaru Vechi",
            "Scorţeni",
            "Scorţeni",
            "Scorţoasa",
            "Scrioaştea",
            "Sculia",
            "Scundu",
            "Scurta",
            "Scurta",
            "Scurtești",
            "Scurtești",
            "Scurtu Mare",
            "Scutelnici",
            "Seaca",
            "Seaca",
            "Seaca de Câmp",
            "Seaca de Pădure",
            "Sebeş",
            "Sebeș",
            "Sebeșel",
            "Sebiş",
            "Secăria",
            "Secaş",
            "Seciu",
            "Sector 1",
            "Sector 2",
            "Sector 3",
            "Sector 4",
            "Sector 5",
            "Sector 6",
            "Secu",
            "Secui",
            "Secuieni",
            "Secuieni",
            "Secusigiu",
            "Segarcea",
            "Segarcea Vale",
            "Segarcea-Deal",
            "Şeíca Mare",
            "Şeica Mică",
            "Seimeni",
            "Seini",
            "Şeitin",
            "Şelaru",
            "Seleuş",
            "Seleuș",
            "Şelimbăr",
            "Semlac",
            "Şendreni",
            "Şendriceni",
            "Senereuș",
            "Șepreuș",
            "Şerbăneşti",
            "Șerbănești",
            "Șerbănești",
            "Șerbăuți",
            "Șerbeni",
            "Șerboeni",
            "Şercaia",
            "Serdanu",
            "Seuca",
            "Șeulia de Mureș",
            "Sfântu Gheorghe",
            "Sfântu Gheorghe",
            "Sfântu Gheorghe",
            "Sfântu Gheorghe",
            "Sfârcea",
            "Sfinţeşti",
            "Sibiu",
            "Şibot",
            "Sic",
            "Sicele",
            "Sicheviţa",
            "Șiclău",
            "Şicula",
            "Siculeni",
            "Şieu",
            "Șieu",
            "Şieu-Măgheruş",
            "Şieu-Odorhei",
            "Şieuţ",
            "Sighetu Marmaţiei",
            "Sighișoara",
            "Sihlea",
            "Șilea Nirajului",
            "Şilindia",
            "Șilindru",
            "Siliştea",
            "Siliştea",
            "Siliştea",
            "Siliștea",
            "Siliştea Crucii",
            "Siliștea Gumești",
            "Siliștea Snagovului",
            "Silivașu de Câmpie",
            "Şimand",
            "Simeria",
            "Şimian",
            "Şimian",
            "Siminicea",
            "Siminoc",
            "Șimișna",
            "Şimleu Silvaniei",
            "Şimnicu de Sus",
            "Șimon",
            "Simoneşti",
            "Sinaia",
            "Șinca Nouă",
            "Şinca Veche",
            "Șincai",
            "Șincai-Fânațe",
            "Șindrești",
            "Șindrilari",
            "Sineşti",
            "Sineşti",
            "Sineşti",
            "Sinești",
            "Singureni",
            "Sînmartin",
            "Sintea Mare",
            "Şintereag",
            "Sintești",
            "Şinteu",
            "Şipote",
            "Șipotu",
            "Siret",
            "Sireţel",
            "Şiria",
            "Şirineasa",
            "Şirna",
            "Şişeşti",
            "Șisești",
            "Şiştarovăţ",
            "Sita Buzăului",
            "Șivița",
            "Slănic",
            "Slănic",
            "Slănic-Moldova",
            "Slatina",
            "Slatina",
            "Slatina-Timiş",
            "Slătioara",
            "Slătioara",
            "Slava Cercheză",
            "Slava Rusă",
            "Slăveni",
            "Slimnic",
            "Slivileşti",
            "Slobozia",
            "Slobozia",
            "Slobozia",
            "Slobozia",
            "Slobozia",
            "Slobozia",
            "Slobozia Bradului",
            "Slobozia Conachi",
            "Slobozia Moara",
            "Slobozia-Câmpineanca",
            "Slobozia-Ciorăşti",
            "Slobozia-Mândra",
            "Slon",
            "Smârdan",
            "Smârdan",
            "Smârdan",
            "Smârdioasa",
            "Smeeni",
            "Smirna",
            "Smulţi",
            "Snagov",
            "Șoard",
            "Şoarş",
            "Socetu",
            "Soci",
            "Socodor",
            "Socol",
            "Socolu de Câmpie",
            "Socond",
            "Sodomeni",
            "Şofronea",
            "Sohatu",
            "Sohodol",
            "Sohodol",
            "Sohodol",
            "Şoimari",
            "Şoimi",
            "Șoimuș",
            "Şoimuş",
            "Solca",
            "Şoldanu",
            "Soleşti",
            "Solocma",
            "Soloneț",
            "Solonţ",
            "Solovăstru",
            "Şomcuta Mare",
            "Someş-Odorhei",
            "Someș-Uileac",
            "Șomoștelnic",
            "Somova",
            "Somușca",
            "Şona",
            "Şopârliţa",
            "Soporu de Câmpie",
            "Sopot",
            "Şopotu Nou",
            "Șoromiclea",
            "Şotânga",
            "Şotrile",
            "Şovarna",
            "Sovata",
            "Spanţov",
            "Spătărei",
            "Speriețeni",
            "Spermezeu",
            "Spinuş",
            "Spiru Haret",
            "Sprâncenata",
            "Şpring",
            "Spulber",
            "Stâlpeni",
            "Stâlpu",
            "Stamate",
            "Stâna",
            "Stânca",
            "Stancea",
            "Stânceni",
            "Stâncești",
            "Stăncești",
            "Stăncuţa",
            "Stăneşti",
            "Stăneşti",
            "Stănești",
            "Stângăceaua",
            "Stănileşti",
            "Stănişeşti",
            "Stăniţa",
            "Starchiojd",
            "Stârciu",
            "Stăuceni",
            "Stăvaru",
            "Ştefan Cel Mare",
            "Ştefan cel Mare",
            "Ştefan cel Mare",
            "Ştefan cel Mare",
            "Ştefan cel Mare",
            "Ștefan cel Mare",
            "Ştefan Vodă",
            "Ștefan Vodă",
            "Ștefanca",
            "Ștefăneaca",
            "Ştefăneşti",
            "Ştefăneşti",
            "Ștefănești",
            "Ştefăneşti-Sat",
            "Ştefăneştii de Jos",
            "Ştefeşti",
            "Ștei",
            "Steierdorf",
            "Stejărenii",
            "Stejari",
            "Stejaru",
            "Stejaru",
            "Stejaru",
            "Stejera",
            "Stejeriș",
            "Stelnica",
            "Sterpoaia",
            "Sticlăria",
            "Ştiubieni",
            "Ştiuca",
            "Stoeneşti",
            "Stoeneşti",
            "Stoeneşti",
            "Stoeneşti",
            "Stoenești",
            "Stoicăneşti",
            "Stoiceni",
            "Stoileşti",
            "Stoina",
            "Stolniceni",
            "Stolniceni-Prăjescu",
            "Stolnici",
            "Storeşti",
            "Stornești",
            "Storobăneasa",
            "Straja",
            "Straja",
            "Strâmbeni",
            "Strâmbu-Băiuț",
            "Strâmtura",
            "Strâmtura",
            "Străoane",
            "Străteni",
            "Strehaia",
            "Strejeşti",
            "Strejeștii de Sus",
            "Strejnicu",
            "Stremț",
            "Stremţ",
            "Stroeşti",
            "Stroești",
            "Stroiești",
            "Stroiești",
            "Strugari",
            "Strunga",
            "Studina",
            "Studinița",
            "Stulpicani",
            "Suatu",
            "Sub Pădure",
            "Subcetate",
            "Suceagu",
            "Suceava",
            "Suceveni",
            "Suceviţa",
            "Suciu de Jos",
            "Suciu de Sus",
            "Sudiţi",
            "Sudiți",
            "Şugag",
            "Șugău",
            "Suhaia",
            "Suharău",
            "Suhuleț",
            "Suhurlui",
            "Şuici",
            "Şuletea",
            "Sulina",
            "Sulița",
            "Sultana",
            "Şuncuiuş",
            "Șupitca",
            "Suplac",
            "Suplacu de Barcău",
            "Supuru de Jos",
            "Şura Mare",
            "Şura Mică",
            "Suraia",
            "Surani",
            "Șurdești",
            "Surdila-Găiseanca",
            "Surdila-Greci",
            "Surduc",
            "Surdulești",
            "Şuşani",
            "Suseni",
            "Suseni",
            "Suseni",
            "Suseni Socetu",
            "Susenii Bârgăului",
            "Sutești",
            "Şuţeşti",
            "Suveica",
            "Sviniţa",
            "Tabăra",
            "Tăbărăști",
            "Tăblășeni",
            "Tăcuta",
            "Ţaga",
            "Talea",
            "Tălmăcel",
            "Tălmaciu",
            "Talpa-Ogrăzile",
            "Talpa-Trivalea",
            "Tălpaș",
            "Tălpigi",
            "Talpoș",
            "Tămădău Mare",
            "Tămaia",
            "Tămașda",
            "Tămăşeni",
            "Tămășești",
            "Tămășeu",
            "Tamaşi",
            "Tamași",
            "Tâmboeşti",
            "Tâmna",
            "Tâmpa",
            "Tanacu",
            "Tănăsoaia",
            "Ţăndărei",
            "Tânganu",
            "Tansa",
            "Ţânţăreni",
            "Tântava",
            "Tărcaia",
            "Tarcău",
            "Tarcea",
            "Târgovişte",
            "Târgşoru Vechi",
            "Târgu Bujor",
            "Târgu Cărbuneşti",
            "Târgu Frumos",
            "Târgu Jiu",
            "Târgu Lăpuş",
            "Târgu Mureș",
            "Târgu Neamţ",
            "Târgu Ocna",
            "Târgu Secuiesc",
            "Târgu Trotuş",
            "Târguşor",
            "Tăriceni",
            "Tariverde",
            "Târlişua",
            "Tărlungeni",
            "Tarna Mare",
            "Târnava",
            "Târnava",
            "Târnăveni",
            "Târnova",
            "Târnova",
            "Târşolţ",
            "Târşolţel",
            "Tărtăşeşti",
            "Tășad",
            "Taşca",
            "Tăşnad",
            "Tătărani",
            "Tătărani",
            "Tătărăni",
            "Tătăranu",
            "Tătărăști",
            "Tătărăștii de Jos",
            "Tătărăștii de Sus",
            "Tătaru",
            "Tătaru",
            "Tătăruşi",
            "Tătuleşti",
            "Tău",
            "Tăureni",
            "Tăureni",
            "Tauţ",
            "Tăut",
            "Tăuteu",
            "Tăuții de Sus",
            "Tăuţii-Măgherăuş",
            "Tazlău",
            "Teaca",
            "Teasc",
            "Teceu Mic",
            "Techirghiol",
            "Tecuci",
            "Tecuci",
            "Teiș",
            "Teişani",
            "Teiu",
            "Teiuş",
            "Telcișor",
            "Telciu",
            "Teleac",
            "Telec",
            "Telega",
            "Teleormanu",
            "Teleşti",
            "Teliu",
            "Teliucu Inferior",
            "Țelna",
            "Ţepu",
            "Terebeşti",
            "Teregova",
            "Teremia Mare",
            "Terpeziţa",
            "Teșila",
            "Teslui",
            "Teslui",
            "Tețcoiu",
            "Tetoiu",
            "Tia Mare",
            "Ţibana",
            "Ţibăneşti",
            "Țibeni",
            "Ţibucani",
            "Țicău",
            "Tichileşti",
            "Ţicleni",
            "Ticuşu Vechi",
            "Ticvaniu Mare",
            "Ţifeşti",
            "Ţigănaşi",
            "Ţigăneşti",
            "Țigmandru",
            "Tigveni",
            "Tiha Bârgăului",
            "Tihău",
            "Tileagd",
            "Tilişca",
            "Timişeşti",
            "Timişoara",
            "Tinca",
            "Tinosu",
            "Țintea",
            "Ţinteşti",
            "Țipar",
            "Țiptelnic",
            "Tiream",
            "Tireu",
            "Tirimia",
            "Tirimioara",
            "Tisa",
            "Tisieu",
            "Tismana",
            "Titeşti",
            "Titești",
            "Titiana",
            "Titu",
            "Tiur",
            "Toaca",
            "Toboliu",
            "Tocileni",
            "Todireni",
            "Todireşti",
            "Todireşti",
            "Todireşti",
            "Tofalău",
            "Toflea",
            "Tohanu Nou",
            "tohat",
            "Toldal",
            "Țolici",
            "Tomeşti",
            "Tomeşti",
            "Tomeşti",
            "Tomești",
            "Tomnatic",
            "Tomşani",
            "Tomşani",
            "Tonciu",
            "Țopa",
            "Topalu",
            "Topana",
            "Topile",
            "Topleţ",
            "Topliceni",
            "Topliţa",
            "Toplița",
            "Topolița",
            "Topolog",
            "Topolovăţu Mare",
            "Topoloveni",
            "Toporu",
            "Topraisar",
            "Torba",
            "Tormac",
            "Tortoman",
            "Toteşti",
            "Totoești",
            "Traian",
            "Traian",
            "Traian",
            "Traian",
            "Traian",
            "Traian",
            "Traian",
            "Traian Vuia",
            "Trăisteni",
            "Trei Sate",
            "Trestia",
            "Trestiana",
            "Trestieni",
            "Treznea",
            "Trifeşti",
            "Trifeşti",
            "Trip",
            "Tritenii de Jos",
            "Tritenii de Sus",
            "Trivalea-Moşteni",
            "Troianul",
            "Troița",
            "Truşeşti",
            "Tudor Vladimirescu",
            "Tudor Vladimirescu",
            "Tudor Vladimirescu",
            "Tudor Vladimirescu",
            "Tudora",
            "Tufeni",
            "Tufeni",
            "Tufeşti",
            "Ţuglui",
            "Tulca",
            "Tulcea",
            "Tulgheş",
            "Tulghieș",
            "Tulnici",
            "Tuluceşti",
            "Tunari",
            "Tunelu-Teliu",
            "Tupilaţi",
            "Tur",
            "Turburea",
            "Turceni",
            "Turcești",
            "Turcineşti",
            "Turcoaia",
            "Turda",
            "Turda",
            "Turdaş",
            "Tureac",
            "Tureni",
            "Turia",
            "Turluianu",
            "Turnu",
            "Turnu Măgurele",
            "Turnu Roşu",
            "Turnu Ruieni",
            "Turţ",
            "Turulung",
            "Tușinu",
            "Tuşnad",
            "Tușnadu Nou",
            "Tuta",
            "Tutana",
            "Ţuţora",
            "Tutova",
            "Tuzla",
            "Ucea de Jos",
            "Ucea de Sus",
            "Uda",
            "Uda",
            "Uda-Clocociov",
            "Uda-Paciurea",
            "Udeşti",
            "Udupu",
            "Uila",
            "Uileacu de Beiuș",
            "Uioara de Jos",
            "Uivar",
            "Ulieș",
            "Ulieşti",
            "Ulma",
            "Ulmeni",
            "Ulmeni",
            "Ulmeni",
            "Ulmi",
            "Ulmi",
            "Ulmoasa",
            "Ulmu",
            "Ulmu",
            "Umbrăreşti",
            "Umbrărești-Deal",
            "Ungheni",
            "Ungheni",
            "Ungra",
            "Unguraş",
            "Unguraș",
            "Ungureni",
            "Ungureni",
            "Ungureni",
            "Ungureni",
            "Ungureni",
            "Ungureni-Jianu",
            "Unguriu",
            "Unirea",
            "Unirea",
            "Unirea",
            "Unirea",
            "Unirea",
            "Unirea",
            "Unţeni",
            "Urca",
            "Urdari",
            "Urecheni",
            "Urecheşti",
            "Urecheşti",
            "Uricani",
            "Uricea",
            "Urisiu de Jos",
            "Urisiu de Sus",
            "Uriu",
            "Urlaţi",
            "Urleasca",
            "Urleta",
            "Urmeniș",
            "Urmeniş",
            "Ursa",
            "Urseni",
            "Urși",
            "Ursoaia",
            "Urzica",
            "Urziceanca",
            "Urziceni",
            "Urziceni",
            "Urzicuţa",
            "Ususău",
            "Utvin",
            "Uzunu",
            "Văcăreni",
            "Văcăreşti",
            "Văculeşti",
            "Vad",
            "Vad",
            "Vădaș",
            "Vădastra",
            "Vădăstriţa",
            "Vădeni",
            "Vadu",
            "Vadu Crişului",
            "Vadu Izei",
            "Vadu Moldovei",
            "Vadu Moţilor",
            "Vadu Părului",
            "Vadu Paşii",
            "Vadu Roșca",
            "Vadu Săpat",
            "Vadu Sorești",
            "Vădurele",
            "Văgiuleşti",
            "Vaida-Cămăraș",
            "Vaidacuta",
            "Vaideeni",
            "Vaideiu",
            "Vâlcăneşti",
            "Vălcani",
            "Valcău de Jos",
            "Vâlcele",
            "Vâlcele",
            "Vâlcele",
            "Vâlcele",
            "Vâlcelele",
            "Vâlcelele",
            "Vâlcelele de Sus",
            "Vale",
            "Valea",
            "Valea Albeștiului",
            "Valea Argovei",
            "Valea Arini",
            "Valea Arinilor",
            "Valea Bolvașnița",
            "Valea Borcutului",
            "Valea Călugărească",
            "Valea Cânepii",
            "Valea Caselor",
            "Valea Chioarului",
            "Valea Ciorii",
            "Valea Cireșului",
            "Valea Crişului",
            "Valea Cucului",
            "Valea Cufundoasă",
            "Valea Dacilor",
            "Valea Dăii",
            "Valea Danului",
            "Valea Dragului",
            "Valea Dulce",
            "Valea Frăției",
            "Valea Glodului",
            "Valea Grecului",
            "Valea Hotarului",
            "Valea Iaşului",
            "Valea Iclandului",
            "Valea Ierii",
            "Valea Izvoarelor",
            "Valea Largă",
            "Valea Leurzii",
            "Valea lui Ion",
            "Valea lui Mihai",
            "Valea Lungă Alba Romania",
            "Valea Lungă Ogrea",
            "Valea Lungă-Cricov",
            "Valea Lupului",
            "Valea Măcrișului",
            "Valea Mare",
            "Valea Mare",
            "Valea Mare",
            "Valea Mare",
            "Valea Mare",
            "Valea Mare",
            "Valea Mare Pravăț",
            "Valea Mare-Podgoria",
            "Valea Mărului",
            "Valea Merilor",
            "Valea Moldovei",
            "Valea Neagră",
            "Valea Nucarilor",
            "Valea Pădurii",
            "Valea Părului",
            "Valea Părului",
            "Valea Popii",
            "Valea Râmnicului",
            "Valea Rece",
            "Valea Rece",
            "Valea Roșie",
            "Valea Salciei",
            "Valea Sânmărtinului",
            "Valea Sânpetrului",
            "Valea Șapartocului",
            "Valea Sării",
            "Valea Sasului",
            "Valea Seacă",
            "Valea Seacă",
            "Valea Seacă",
            "Valea Seacă",
            "Valea Seacă",
            "Valea Șoșii",
            "Valea Stanciului",
            "Valea Stejarului",
            "Valea Strâmbă",
            "Valea Șurii",
            "Valea Târsei",
            "Valea Teilor",
            "Valea Ulieșului",
            "Valea Ungurului",
            "Valea Urieșului",
            "Valea Ursului",
            "Valea Viilor",
            "Valea Vinului",
            "Valea Vișeului",
            "Valea Voievozilor",
            "Văleni",
            "Văleni",
            "Văleni",
            "Văleni",
            "Văleni",
            "Văleni-Dâmbovița",
            "Văleni-Podgoria",
            "Vălenii",
            "Vălenii de Munte",
            "Vălenii de Mureș",
            "Vălenii Lăpușului",
            "Vălenii Șomcutei",
            "Vălișoara",
            "Vălişoara",
            "Văliug",
            "Valu lui Traian",
            "Vălureni",
            "Vama",
            "Vama",
            "Vama Buzăului",
            "Vânători",
            "Vânători",
            "Vânători",
            "Vânători",
            "Vânători",
            "Vânători",
            "Vânători-Neamţ",
            "Vânătorii Mari",
            "Vânătorii Mici",
            "Vânju-Mare",
            "Vânjuleţ",
            "Vărădia",
            "Vărădia de Mureş",
            "Vărai",
            "Vărăşti",
            "Vărăști",
            "Văratec",
            "Vărbila",
            "Vărbilău",
            "Vârciorog",
            "Vârciorova",
            "Vârfu Câmpului",
            "Vârfuri",
            "Vârfurile",
            "Vărgata",
            "Vârghiş",
            "Variaş",
            "Vârlezi",
            "Varnița",
            "Vărşag",
            "Vârşolţ",
            "Vârteju",
            "Vârteşcoiu",
            "Vârtoapele de Jos",
            "Vârtoapele de Sus",
            "Vârtop",
            "Vârvoru de Jos",
            "Vășad",
            "Vaşcău",
            "Vasilaţi",
            "Vaslui",
            "Vaţa de Jos",
            "Vătava",
            "Vatra Dornei",
            "Vatra Moldoviţei",
            "Vedea",
            "Vedea",
            "Vedea",
            "Vela",
            "Venchi",
            "Verbiţa",
            "Verendin",
            "Vereşti",
            "Verguleasa",
            "Vermeş",
            "Vermești",
            "Verneşti",
            "Verșeni",
            "Verșești",
            "Veseuș",
            "Veștem",
            "Veța",
            "Vețca",
            "Veţel",
            "Vetiş",
            "Vetrişoaia",
            "Veza",
            "Vicea",
            "Vicovu de Jos",
            "Vicovu de Sus",
            "Victor Vlad Delamarina",
            "Victoria",
            "Victoria",
            "Victoria",
            "Victoria",
            "Videle",
            "Vidra",
            "Vidra",
            "Vidra",
            "Vidrasău",
            "Vierșani",
            "Vieru",
            "Viforâta",
            "Viforoasa",
            "Viile",
            "Viile",
            "Viile Apei",
            "Viile Satu Mare",
            "Viile Tecii",
            "Viilor",
            "Viişoara",
            "Viişoara",
            "Viişoara",
            "Viişoara",
            "Viişoara",
            "Viişoara",
            "Viișoara",
            "Viișoara",
            "Viișoara",
            "Viișoara",
            "Viișoara",
            "Viișoara",
            "Vima Mare",
            "Vima Mică",
            "Vinderei",
            "Vinerea",
            "Vinga",
            "Vintere",
            "Vintilă Vodă",
            "Vintileanca",
            "Vintileasca",
            "Vințu de Jos",
            "Vipereşti",
            "Vişani",
            "Viscri",
            "Vişeu de Jos",
            "Vișeu de Mijloc",
            "Vişeu De Sus",
            "Vişina",
            "Vişina",
            "Vișina Nouă",
            "Vișinelu",
            "Vişineşti",
            "Viștea de Jos",
            "Vităneşti",
            "Vitănești",
            "Vitomireşti",
            "Vizantea-Mânăstirească",
            "Viziru",
            "Vizurești",
            "Vlad Ţepeş",
            "Vlad Țepeș",
            "Vlădaia",
            "Vlădeni",
            "Vlădeni",
            "Vlădeni",
            "Vlădeni",
            "Vlădeni-Deal",
            "Vlădeşti",
            "Vlădeşti",
            "Vlădești",
            "Vlădila",
            "Vladimirescu",
            "Vlăduleni",
            "Vlăhiţa",
            "Vlașca",
            "Vlăsceni",
            "Vlăsineşti",
            "Voetin",
            "Voiceşti",
            "Voievodeasa",
            "Voila",
            "Voineasa",
            "Voineasa",
            "Voineşti",
            "Voineşti",
            "Voineşti",
            "Voinești",
            "Voiniceni",
            "Voiteg",
            "Voiteștii din Vale",
            "Voitinel",
            "Voivodeni",
            "Voivozi",
            "Voloiac",
            "Volovăţ",
            "Voluntari",
            "Voluntari City",
            "Vorniceni",
            "Vorona",
            "Vorona Teodoru",
            "Vorţa",
            "Voșlăbeni",
            "Vrâncioaia",
            "Vrănești",
            "Vrani",
            "Vrata",
            "Vulcan",
            "Vulcan",
            "Vulcan",
            "Vulcana Băi",
            "Vulcana de Sus",
            "Vulcana-Pandele",
            "Vulcăneasa",
            "Vulpeni",
            "Vultureni",
            "Vultureni",
            "Vultureşti",
            "Vultureşti",
            "Vultureşti",
            "Vulturești",
            "Vulturu",
            "Vulturu",
            "Vurpăr",
            "Vurpăr",
            "Vutcani",
            "Zăbala",
            "Zăbrani",
            "Zădăreni",
            "Zagăr",
            "Zagavia",
            "Zagon",
            "Zagra",
            "Zalău",
            "Zalha",
            "Zam",
            "Zâmbreasca",
            "Zamostea",
            "Zăneşti",
            "Zănoaga",
            "Zăpodea",
            "Zăpodeni",
            "Zărand",
            "Zărneşti",
            "Zărnești",
            "Zărneștii de Slănic",
            "Zătreni",
            "Zau de Câmpie",
            "Zăuan",
            "Zăval",
            "Zăvoaia",
            "Zăvoi",
            "Zăvoiu",
            "Zdrapți",
            "Zebil",
            "Zemeş",
            "Zencani",
            "Zerind",
            "Zetea",
            "Ziduri",
            "Zidurile",
            "Zimandu Nou",
            "Zimbor",
            "Zimnicea",
            "Zimnicele",
            "Zimți",
            "Zizin",
            "Zlătărei",
            "Zlatna",
            "Zlătunoaia",
            "Zmeu",
            "Zorești",
            "Zorile",
            "Zorleni",
            "Zorlenţu Mare",
            "Zvoriştea"
        ]
    },
    {
        "label": "Russia",
        "code": "RU",
        "phone": "7",
        "cities": [
            "Abadzekhskaya",
            "Abagur",
            "Abakan",
            "Abakan Gorod",
            "Abalak",
            "Aban",
            "Abanskiy Rayon",
            "Abatskoye",
            "Abaza",
            "Abdulino",
            "Abinsk",
            "Abinskiy Rayon",
            "Abram Mys",
            "Abramovka",
            "Abrau-Dyurso",
            "Abyysky District",
            "Abzakovo",
            "Abzelilovskiy Rayon",
            "Achikulak",
            "Achinsk",
            "Achinskiy Rayon",
            "Achisu",
            "Achit",
            "Achkhoy-Martan",
            "Achkhoy-Martanovskiy Rayon",
            "Adamovka",
            "Adil’-Yangiyurt",
            "Adler",
            "Admiralteisky",
            "Adyge-Khabl’",
            "Adygeysk",
            "Adygeysk Republican Urban Okrug",
            "Afanas’yevskiy Rayon",
            "Afipskiy",
            "Afonino",
            "Afrikanda",
            "Agalatovo",
            "Agapovka",
            "Agapovskiy Rayon",
            "Ageyevo",
            "Agidel’",
            "Aginskoye",
            "Aginskoye",
            "Agirish",
            "Agoy",
            "Agronom",
            "Agryz",
            "Agryzskiy Rayon",
            "Agul’skiy Rayon",
            "Agvali",
            "Ak-Dovurak",
            "Ak”yar",
            "Akademgorodok",
            "Akademicheskoe",
            "Akhtanizovskaya",
            "Akhtubinsk",
            "Akhtubinskiy Rayon",
            "Akhty",
            "Akhtynskiy Rayon",
            "Akhtyrskiy",
            "Akhunovo",
            "Akhvakhskiy Rayon",
            "Aknada",
            "Aksakovo",
            "Aksarayskiy",
            "Aksarka",
            "Aksay",
            "Aksay",
            "Aksha",
            "Akshinskiy Rayon",
            "Aksubayevskiy Rayon",
            "Aktanysh",
            "Aktanyshskiy Rayon",
            "Aktash",
            "Aktyubinskiy",
            "Akusha",
            "Akushinskiy Rayon",
            "Al’keyevskiy Rayon",
            "Al’met’yevsk",
            "Al’met’yevskiy Rayon",
            "Al’sheyevskiy Rayon",
            "Alabushevo",
            "Alagir",
            "Alagirskiy Rayon",
            "Alakurtti",
            "Alandskoye",
            "Alapayevsk",
            "Alatyr’",
            "Alatyrskiy Rayon",
            "Aldan",
            "Aleksandriya",
            "Aleksandriyskaya",
            "Aleksandro-Nevskiy",
            "Aleksandrov",
            "Aleksandrov Gay",
            "Aleksandrovo-Zavodskiy Rayon",
            "Aleksandrovsk",
            "Aleksandrovsk-Sakhalinskiy",
            "Aleksandrovskaya",
            "Aleksandrovskaya",
            "Aleksandrovskiy Rayon",
            "Aleksandrovskiy Zavod",
            "Aleksandrovskoye",
            "Aleksandrovskoye",
            "Alekseyevka",
            "Alekseyevka",
            "Alekseyevka",
            "Alekseyevka",
            "Alekseyevskaya",
            "Alekseyevskiy Rayon",
            "Alekseyevskoye",
            "Aleksin",
            "Aleksinskiy Rayon",
            "Aleutskiy Rayon",
            "Aleysk",
            "Aleyskiy Rayon",
            "Ali-Berdukovskiy",
            "Ali-Yurt",
            "Alikovo",
            "Alikovskiy Rayon",
            "Alimov-Lyubimovsky",
            "Alkhan-Churt",
            "Alkhan-Kala",
            "Alkhan-Yurt",
            "Alkhazurovo",
            "Allaikhovskiy Rayon",
            "Alleroy",
            "Almaznyy",
            "Almaznyy",
            "Alnashi",
            "Alpatovo",
            "Altayskiy Rayon",
            "Altayskoye",
            "Altud",
            "Altuf’yevskiy",
            "Altukhovo",
            "Amazar",
            "Amga",
            "Amin’yevo",
            "Amochayevsky",
            "Amursk",
            "Amurzet",
            "Amzya",
            "Anabarskiy Rayon",
            "Anadyr",
            "Anadyrskiy Rayon",
            "Anapa",
            "Anapskaya",
            "Anastasiyevka",
            "Anastasiyevskaya",
            "Andi",
            "Andra",
            "Andreapol’",
            "Andreapol’skiy Rayon",
            "Andreyevka",
            "Andreyevo",
            "Andreyevskoye",
            "Andzhiyevskiy",
            "Aniva",
            "Anna",
            "Annino",
            "Annino",
            "Annino",
            "Anninskiy Rayon",
            "Anopino",
            "Ansalta",
            "Antipino",
            "Antipovka",
            "Anton’yevka",
            "Antropovo",
            "Antropovskiy Rayon",
            "Anuchino",
            "Anuchinskiy Rayon",
            "Anzhero-Sudzhensk",
            "Apastovo",
            "Apastovskiy Rayon",
            "Apatity",
            "Aprelevka",
            "Apsheronsk",
            "Ar’ya",
            "Aramil",
            "Arbazh",
            "Archedino-Chernushinsky",
            "Archedinskaya",
            "Ardatov",
            "Ardatov",
            "Ardatovskiy Rayon",
            "Ardatovskiy Rayon",
            "Ardon",
            "Ardon’",
            "Argayash",
            "Argudan",
            "Argun",
            "Arkadak",
            "Arkhangel’sk",
            "Arkhangel’skoye",
            "Arkhara",
            "Arkharinskiy Rayon",
            "Arkhipo-Osipovka",
            "Arkhipovka",
            "Arkhonskaya",
            "Arkul’",
            "Arman’",
            "Armavir",
            "Armizonskiy Rayon",
            "Armizonskoye",
            "Aromashevo",
            "Arsaki",
            "Arsen’yev",
            "Arsen’yevo",
            "Arshan’",
            "Arsk",
            "Arskiy Rayon",
            "Artëm",
            "Artëmovskiy",
            "Arti",
            "Artybash",
            "Artyshta",
            "Arzamas",
            "Arzamasskiy Rayon",
            "Arzgir",
            "Asanovo",
            "Asbest",
            "Asbestovskiy",
            "Asekeyevo",
            "Asha",
            "Ashil’ta",
            "Ashinskiy Rayon",
            "Ashitkovo",
            "Ashukino",
            "Asino",
            "Askino",
            "Askinskiy Rayon",
            "Askiz",
            "Assinovskaya",
            "Astrakhan",
            "Atamanovka",
            "Atamanskaya",
            "Atemar",
            "Ateptsevo",
            "Atig",
            "Atkarsk",
            "Atkarskiy Rayon",
            "Atlashevo",
            "Atlasovo",
            "Atninskiy Rayon",
            "Atyashevo",
            "Atyashevskiy Rayon",
            "Atyur’yevskiy Rayon",
            "Aushiger",
            "Avdon",
            "Avsyunino",
            "Avtopoligon",
            "Avtovo",
            "Avtury",
            "Aya",
            "Ayan",
            "Aykhal",
            "Aykino",
            "Aysha",
            "Ayutinskiy",
            "Azanka",
            "Aznakayevskiy Rayon",
            "Azov",
            "Azovo",
            "Azovskaya",
            "Babayevo",
            "Babayevskiy Rayon",
            "Babayurt",
            "Babayurtovskiy Rayon",
            "Babino",
            "Babstovo",
            "Babugent",
            "Babushkin",
            "Babushkin",
            "Babynino",
            "Babyninskiy Rayon",
            "Bachatskiy",
            "Bachi-Yurt",
            "Bada",
            "Bagan",
            "Bagayevskaya",
            "Bagdarin",
            "Bagramovo",
            "Bagrationovsk",
            "Bakal",
            "Bakalinskiy Rayon",
            "Bakaly",
            "Bakchar",
            "Bakhchivandzhi",
            "Bakhilovo",
            "Baksan",
            "Baksanenok",
            "Baksanskiy Rayon",
            "Baksheyevo",
            "Balabanovo",
            "Balakhonovskoye",
            "Balakhta",
            "Balakhtinskiy Rayon",
            "Balakirevo",
            "Balakovo",
            "Balasheyka",
            "Balashikha",
            "Balashikha Urban Okrug",
            "Balashov",
            "Baley",
            "Baleyskiy Rayon",
            "Balezino",
            "Balgazyn",
            "Baltasi",
            "Baltasinskiy Rayon",
            "Baltay",
            "Baltayskiy Rayon",
            "Baltiysk",
            "Balyaga",
            "Bammatyurt",
            "Bamut",
            "Baraba",
            "Barabash",
            "Barabinsk",
            "Baranchinskiy",
            "Barashevo",
            "Barda",
            "Barguzin",
            "Barnaul",
            "Barnaul Urban Okrug",
            "Barsovo",
            "Barsuki",
            "Barsukovskaya",
            "Barvikha",
            "Baryatino",
            "Baryatinskiy Rayon",
            "Barybino",
            "Barysh",
            "Baryshevo",
            "Barzas",
            "Bashmakovo",
            "Bastan",
            "Batagay",
            "Batagay-Alyta",
            "Bataysk",
            "Batetskiy",
            "Batetskiy Rayon",
            "Baturinskaya",
            "Batyrevo",
            "Batyrevskiy Rayon",
            "Bavleny",
            "Bavlinskiy Rayon",
            "Bavly",
            "Bavtugay",
            "Bay-Khaak",
            "Bayangol",
            "Bayevo",
            "Baykalovo",
            "Baykit",
            "Baymak",
            "Bayunovskiye Klyuchi",
            "Bazarno-Karabulakskiy Rayon",
            "Bazarnyy Karabulak",
            "Bazarnyy Syzgan",
            "Bazarnyye Mataki",
            "Bazhovo",
            "Bedeyeva Polyana",
            "Begichevskiy",
            "Begunitsy",
            "Bekeshevskaya",
            "Bekhteyevka",
            "Bekovo",
            "Bel’skiy Rayon",
            "Belaya",
            "Belaya Glina",
            "Belaya Gora",
            "Belaya Kalitva",
            "Belaya Kholunitsa",
            "Belaya Rechka",
            "Belaya Rechka",
            "Belebey",
            "Belëv",
            "Belgatoy",
            "Belgorod",
            "Belgorodskiy Rayon",
            "Belidzhi",
            "Belinskiy",
            "Belogor’ye",
            "Belogorsk",
            "Belogorsk",
            "Belogorskiy Rayon",
            "Belokurikha",
            "Belomorsk",
            "Beloomut",
            "Beloostrov",
            "Beloozërskiy",
            "Belorechensk",
            "Belorechensk",
            "Belorechenskiy Rayon",
            "Beloretsk",
            "Beloretskiy Rayon",
            "Belousovo",
            "Belovo",
            "Beloyarsk",
            "Beloyarskiy",
            "Beloyarskiy",
            "Beloye",
            "Belozërnyy",
            "Belozërsk",
            "Belozërskiy Rayon",
            "Belozërskoye",
            "Beluzhino-Koldairov",
            "Belyashi",
            "Belyayevka",
            "Belyy",
            "Belyy Gorodok",
            "Belyy Yar",
            "Belyy Yar",
            "Belyye Berega",
            "Belyye Stolby",
            "Benoy-Yurt",
            "Berdigestyakh",
            "Berdsk",
            "Berdyaush",
            "Berdyuzhskiy Rayon",
            "Beregovoy",
            "Berendeyevo",
            "Bereslavka",
            "Berëza",
            "Berezanskaya",
            "Berezayka",
            "Berëznik",
            "Berezniki",
            "Berëzovka",
            "Berëzovka",
            "Berëzovo",
            "Berëzovskiy",
            "Berezovskiy rayon",
            "Berëzovyy",
            "Beringovskiy",
            "Berkakit",
            "Bernovo",
            "Bershet’",
            "Beryozovsky",
            "Beshpagir",
            "Beslan",
            "Besleney",
            "Besleneyevskaya",
            "Besskorbnaya",
            "Bessonovka",
            "Bessonovka",
            "Betlitsa",
            "Beya",
            "Bezenchuk",
            "Bezhanitsy",
            "Bezhetsk",
            "Bezhetskiy Rayon",
            "Bezhta",
            "Bezopasnoye",
            "Bibirevo",
            "Bichura",
            "Bikin",
            "Bikinskiy Rayon",
            "Bilibino",
            "Bilimbay",
            "Bilyarsk",
            "Biorki",
            "Bira",
            "Birakan",
            "Birilyusskiy Rayon",
            "Birobidzhan",
            "Birobidzhanskiy Rayon",
            "Birsk",
            "Biryulëvo",
            "Biryulëvo Zapadnoye",
            "Bisert’",
            "Biskamzha",
            "Biysk",
            "Bizhbulyak",
            "Blagodarnyy",
            "Blagodatnoye",
            "Blagodatnoye",
            "Blagoveshchenka",
            "Blagoveshchensk",
            "Blagoveshchensk",
            "Blagoveshchenskiy Rayon",
            "Blagovetschenskaya",
            "Blagoyevo",
            "Blechepsin",
            "Blizhne-Pesochnoye",
            "Bobrov",
            "Bobrovka",
            "Bobrovka",
            "Bobrovskiy",
            "Bobrovskiy Rayon",
            "Bobrovsky 2-y",
            "Bogandinskiy",
            "Bogashevo",
            "Bogatoye",
            "Bogatyr’",
            "Bogatyye Saby",
            "Bogdanovich",
            "Bogolyubovo",
            "Bogoroditsk",
            "Bogorodsk",
            "Bogorodskiy Rayon",
            "Bogorodskoye",
            "Bogorodskoye",
            "Bogorodskoye",
            "Bogorodskoye",
            "Bogorodskoye",
            "Bogoslovka",
            "Bogotol",
            "Bogotol’skiy Rayon",
            "Bogovarovo",
            "Bograd",
            "Bogradskiy Rayon",
            "Boguchanskiy Rayon",
            "Boguchany",
            "Boguchar",
            "Bokino",
            "Bokovskaya",
            "Boksitogorsk",
            "Boksitogorskiy Rayon",
            "Bol'shiye Vyazëmy",
            "Bol'shoe Isakovo",
            "Bol’shaya Izhora",
            "Bol’shaya Setun’",
            "Bol’shaya Tura",
            "Bol’shebereznikovskiy Rayon",
            "Bol’sheboldinskiy Rayon",
            "Bol’shemurtinskiy Rayon",
            "Bol’sherech’ye",
            "Bol’shesel’skiy Rayon",
            "Bol’sheuluyskiy Rayon",
            "Bol’shoy Kamen’",
            "Bol’shoy Karay",
            "Bol’shoy Khomutets",
            "Bol’shoy Kunaley",
            "Bol’shoye Boldino",
            "Bol’shoye Gryzlovo",
            "Bol’shoye Kozino",
            "Bol’shoye Murashkino",
            "Bol’shoye Selo",
            "Bol’shoye Skuratovo",
            "Bolgar",
            "Bolkhov",
            "Bolkhovskiy Rayon",
            "Bologovskiy Rayon",
            "Bologoye",
            "Bologoye-4",
            "Bolokhovo",
            "Bolotnoye",
            "Bolshevo",
            "Bondarskiy Rayon",
            "Bor",
            "Bor",
            "Bor Urban Okrug",
            "Borgustanskaya",
            "Borinskoye",
            "Borisoglebsk",
            "Borisoglebskiy",
            "Borisoglebskiy Rayon",
            "Borisova Griva",
            "Borisovka",
            "Borisovskiy Rayon",
            "Borodino",
            "Borodinskiy",
            "Borogontsy",
            "Borok",
            "Borovichi",
            "Borovichskiy Rayon",
            "Borovikha",
            "Borovoy",
            "Borovoy",
            "Borovoy",
            "Borovsk",
            "Borovskiy",
            "Borovskiy Rayon",
            "Borskoye",
            "Borzinskiy Rayon",
            "Borzoy",
            "Borzya",
            "Boshnyakovo",
            "Botashyurt",
            "Botayurt",
            "Botlikhskiy Rayon",
            "Brasovskiy Rayon",
            "Brateyevo",
            "Bratovshchina",
            "Bredy",
            "Breytovo",
            "Breytovskiy Rayon",
            "Brodokalmak",
            "Bronnitsy",
            "Brusyanka",
            "Bryansk",
            "Bryanskiy Rayon",
            "Bryukhovetskaya",
            "Budënnovsk",
            "Budenovetc",
            "Budogoshch’",
            "Bugry",
            "Bugul’ma",
            "Bugul’minskiy Rayon",
            "Buguruslan",
            "Buinsk",
            "Buinsk",
            "Buinskiy Rayon",
            "Bukachacha",
            "Bulanash",
            "Bulgakovo",
            "Bulunskiy Rayon",
            "Burayevo",
            "Burevestnik",
            "Bureya",
            "Bureyskiy Rayon",
            "Buribay",
            "Burla",
            "Burlatskoye",
            "Burmakino",
            "Burnatsky",
            "Burtunay",
            "Butka",
            "Buturlino",
            "Buturlinovka",
            "Buturlinovskiy Rayon",
            "Buy",
            "Buyerak-Popovsky",
            "Buynaksk",
            "Buynakskiy Rayon",
            "Buyskiy Rayon",
            "Buzdyak",
            "Buzdyakskiy Rayon",
            "Buzuluk",
            "Bykov",
            "Bykovo",
            "Bykovo Volgograd Oblast",
            "Bylym",
            "Bystrinskiy Rayon",
            "Bystrogorskiy",
            "Bystryanka",
            "Bystryy Istok",
            "Bytosh’",
            "Centralniy",
            "Chaadayevka",
            "Chadan",
            "Chagoda",
            "Chagodoshchenskiy Rayon",
            "Chaltyr",
            "Chamlykskaya",
            "Chamzinka",
            "Chamzinskiy Rayon",
            "Chany",
            "Chapayevsk",
            "Chaplygin",
            "Chaplyginskiy Rayon",
            "Chara",
            "Charodinskiy Rayon",
            "Charyshskoye",
            "Chashnikovo",
            "Chastyye",
            "Chaykovskaya",
            "Chaykovskiy",
            "Chebarkul’",
            "Chebarkul’skiy Rayon",
            "Cheboksarskiy Rayon",
            "Cheboksary",
            "Chebsara",
            "Chechen-Aul",
            "Chegdomyn",
            "Chegem",
            "Chegem Vtoroy",
            "Chekalin",
            "Chekhov",
            "Chekhov",
            "Chekmagush",
            "Chelbasskaya",
            "Chelno-Vershiny",
            "Chelyabinsk",
            "Chemal",
            "Chemodanovka",
            "Cherdaklinskiy Rayon",
            "Cherdakly",
            "Cherdyn’",
            "Cherekskiy Rayon",
            "Cheremisinovo",
            "Cheremisinovskiy Rayon",
            "Cheremnoye",
            "Cheremshan",
            "Cheremshanka",
            "Cheremshanskiy Rayon",
            "Cherëmukhovo",
            "Cherëmushki",
            "Cherëmushki",
            "Cheremushskiy",
            "Cherepanovo",
            "Cherepet’",
            "Cherepovets",
            "Cherepovetskiy Rayon",
            "Cherga",
            "Cherkasskoye",
            "Cherkessk",
            "Cherkizovo",
            "Cherlak",
            "Chermen",
            "Chermoz",
            "Chern’",
            "Chërnaya Kholunitsa",
            "Chernaya Rechka",
            "Chernigovka",
            "Chernigovskiy Rayon",
            "Chernitsyno",
            "Chernogolovka",
            "Chernogorsk",
            "Chernoistochinsk",
            "Chernolesskoye",
            "Chernomorskiy",
            "Chernoyerkovskaya",
            "Chernukha",
            "Chernushka",
            "Chernyakhovsk",
            "Chernyanka",
            "Chernyanskiy Rayon",
            "Chernyshëvka",
            "Chernyshevsk",
            "Chernyshevskiy",
            "Chernyshkovsky",
            "Cherskiy",
            "Chertanovo Yuzhnoye",
            "Chertkovo",
            "Cherusti",
            "Chervishevo",
            "Chesma",
            "Chesnokovka",
            "Chiganaki 1-ye",
            "Chiganaki 2-ye",
            "Chikola",
            "Chinar",
            "Chiri-Yurt",
            "Chirkey",
            "Chishmy",
            "Chistogorskiy",
            "Chistopol’",
            "Chistopol’skiy Rayon",
            "Chistoye",
            "Chistyye Bory",
            "Chita",
            "Chitinskiy Rayon",
            "Chkalovsk",
            "Chkalovskiy",
            "Chkalovskoye",
            "Chokurdakh",
            "Chontaul",
            "Chornoye",
            "Choya",
            "Chuchkovo",
            "Chudovo",
            "Chudovskiy Rayon",
            "Chufarovo",
            "Chuguyevka",
            "Chukhloma",
            "Chul’man",
            "Chulym",
            "Chumikan",
            "Chunoyar",
            "Chupa",
            "Chupryakovo",
            "Churapcha",
            "Churovichi",
            "Chusovoy",
            "Chyorny Yar",
            "Dachnoye",
            "Dachnoye",
            "Dagestanskiye Ogni",
            "Dagomys",
            "Dakhadayevskiy Rayon",
            "Dal’nekonstantinovskiy Rayon",
            "Dal’neye Konstantinovo",
            "Dalakovo",
            "Dalmatovo",
            "Dalnegorsk",
            "Dalnerechensk",
            "Danilov",
            "Danilovka Volgograd Oblast",
            "Danilovskiy Rayon",
            "Danki",
            "Dankov",
            "Dankovskiy Rayon",
            "Darasun",
            "Darovskoy",
            "Dauriya",
            "Davlekanovo",
            "Davydkovo",
            "Davydovka",
            "Davydovo",
            "De-Kastri",
            "Debesy",
            "Dedenëvo",
            "Dedovichi",
            "Dedovsk",
            "Degtyarsk",
            "Dem’yanovo",
            "Demidov",
            "Demikhovo",
            "Demyansk",
            "Demyanskiy Rayon",
            "Deputatsky",
            "Derbent",
            "Derbentskiy Rayon",
            "Dergachi",
            "Deshovki",
            "Desnogorsk",
            "Detchino",
            "Devitsa",
            "Deyskoye",
            "Digora",
            "Digorskiy Rayon",
            "Dikson",
            "Dimitrovgrad",
            "Dinskaya",
            "Diveyevo",
            "Divnogorsk",
            "Divnomorskoye",
            "Divnoye",
            "Dmitriyevka",
            "Dmitriyevskaya",
            "Dmitriyevskoye",
            "Dmitrov",
            "Dmitrovsk-Orlovskiy",
            "Dmitrovskiy Pogost",
            "Dmitrovskiy Rayon",
            "Dneprovskaya",
            "Dno",
            "Dobrinka",
            "Dobrinskiy Rayon",
            "Dobrovskiy Rayon",
            "Dobroye",
            "Dobrun’",
            "Dobryanka",
            "Dobryatino",
            "Dokuzparinskiy Rayon",
            "Dolgoderevenskoye",
            "Dolgoprudnyy",
            "Dolgorukovo",
            "Dolgorukovskiy Rayon",
            "Dolinsk",
            "Dolzhanskaya",
            "Dombarovskiy",
            "Domna",
            "Domodedovo",
            "Domodedovskiy Rayon",
            "Dondukovskaya",
            "Donetsk",
            "Donskoy",
            "Donskoy",
            "Donskoye",
            "Donskoye",
            "Donskoye",
            "Donskoye",
            "Dorgeli",
            "Dorogino",
            "Dorogobuzh",
            "Dorogomilovo",
            "Dorokhovo",
            "Doschatoye",
            "Dostoyevka",
            "Drakino",
            "Drezna",
            "Drovyanaya",
            "Drozhzhanovskiy Rayon",
            "Druzhba",
            "Druzhba",
            "Druzhba",
            "Druzhinino",
            "Druzhnaya Gorka",
            "Duba-Yurt",
            "Dubinino",
            "Dubki",
            "Dubki",
            "Dubna",
            "Dubna",
            "Dubovka",
            "Dubovka",
            "Dubovskoye",
            "Dubovyy Umët",
            "Dubrovino",
            "Dubrovitsy",
            "Dubrovka",
            "Dubrovka",
            "Dubrovskiy Rayon",
            "Dudinka",
            "Dugulubgey",
            "Dukat",
            "Dukhovnitskoye",
            "Dukhovshchina",
            "Dul’durga",
            "Dulyapino",
            "Duminichi",
            "Duminichskiy Rayon",
            "Dunay",
            "Duvan",
            "Dvinskoy",
            "Dvortsy",
            "Dvubratskiy",
            "Dvurechensk",
            "Dyat’kovo",
            "Dyat’kovskiy Rayon",
            "Dylym",
            "Dyshne-Vedeno",
            "Dyurtyuli",
            "Dzerzhinsk",
            "Dzerzhinskiy",
            "Dzerzhinskiy Rayon",
            "Dzerzhinskoye",
            "Dzerzhinsky",
            "Dzhalil’",
            "Dzhalka",
            "Dzhayrakh",
            "Dzhayrakhskiy Rayon",
            "Dzhebariki-Khaya",
            "Dzhida",
            "Dzhiginka",
            "Dzhubga",
            "Edissiya",
            "Egvekinot",
            "Ekazhevo",
            "Ekimchan",
            "El’brus",
            "El’brusskiy Rayon",
            "Elekmonar",
            "Elektrogorsk",
            "Elektrostal’",
            "Elektrougli",
            "Elin-Yurt",
            "Elista",
            "Elton",
            "Emar",
            "Emmaus",
            "Endirey",
            "Enem",
            "Energetik",
            "Engel’-Yurt",
            "Engel’s",
            "Engel’sskiy Rayon",
            "Erpeli",
            "Ertil’",
            "Ertil’skiy Rayon",
            "Erzin",
            "Esso",
            "Essoyla",
            "Etoka",
            "Evenkiyskiy District",
            "Eveno-Bytantaysky National District",
            "Evensk",
            "Ezhva",
            "Fakel",
            "Falënki",
            "Falënskiy Rayon",
            "Fastov",
            "Fastovetskaya",
            "Fatezh",
            "Fatezhskiy Rayon",
            "Fedorovskiy",
            "Fëdorovskiy Rayon",
            "Fëdorovskoye",
            "Fedurnovo",
            "Ferapontovo",
            "Ferma",
            "Fershampenuaz",
            "Ferzikovo",
            "Ferzikovskiy Rayon",
            "Fili",
            "Filimonki",
            "Filimonovo",
            "Filippovka",
            "Finlyandskiy",
            "Firovo",
            "Firovskiy Rayon",
            "Firsanovka",
            "Foki",
            "Fokino",
            "Fornosovo",
            "Fosforitnyy",
            "Frolishchi",
            "Frolovo",
            "Froly",
            "Frunzenskiy Rayon",
            "Fryanovo",
            "Fryazevo",
            "Fryazino",
            "Furmanov",
            "Gadzhiyevo",
            "Gagarin",
            "Gagatli",
            "Gagino",
            "Gal’bshtadt",
            "Galashki",
            "Galich",
            "Galichskiy Rayon",
            "Galitsy",
            "Galyugayevskaya",
            "Gamovo",
            "Gaptsakh",
            "Garbolovo",
            "Gari",
            "Gastello",
            "Gatchina",
            "Gavrilov Posad",
            "Gavrilov-Yam",
            "Gavrilov-Yamskiy Rayon",
            "Gavrilovka Vtoraya",
            "Gay",
            "Gayduk",
            "Gayny",
            "Gazimuro-Zavodskiy Rayon",
            "Gazimurskiy Zavod",
            "Gdov",
            "Gdovskiy Rayon",
            "Gedzhukh",
            "Gekhi",
            "Gelendzhik",
            "Georgiyevka",
            "Georgiyevsk",
            "Georgiyevskaya",
            "Georgiyevskoye",
            "Gereykhanovskoye",
            "Gerga",
            "Gergebil’",
            "Gergebil’skiy Rayon",
            "Germenchik",
            "Germenchuk",
            "Gerzel’-Aul",
            "Giaginskaya",
            "Giaginskiy Rayon",
            "Gidrotorf",
            "Gigant",
            "Gimry",
            "Girey",
            "Girvas",
            "Gizel’",
            "Glafirovka",
            "Glazov",
            "Glazunovka",
            "Glebychevo",
            "Glinishchevo",
            "Glinka",
            "Glotovka",
            "Glubokiy",
            "Glubokiy",
            "Glubokovsky",
            "Glushkovo",
            "Glyadyanskoye",
            "Gofitskoye",
            "Gol’yanovo",
            "Golitsyno",
            "Golovchino",
            "Golovino",
            "Golovinskiy",
            "Golubitskaya",
            "Golynki",
            "Golyshmanovo",
            "Golyshmanovskiy Rayon",
            "Gon’ba",
            "Goragorskiy",
            "Gorbatov",
            "Gorbatovka",
            "Gorbatovsky",
            "Gorbunki",
            "Gordeyevka",
            "Gordeyevskiy Rayon",
            "Gorelki",
            "Gorelovo",
            "Goreloye",
            "Gorin",
            "Goritsy",
            "Gorki Vtoryye",
            "Gorki-Leninskiye",
            "Gornaya Polyana",
            "Gorno-Altaysk",
            "Gornomariyskiy Rayon",
            "Gornopravdinsk",
            "Gornorechenskiy",
            "Gornoye Loo",
            "Gornozavodsk",
            "Gornozavodsk",
            "Gornyak",
            "Gornyak",
            "Gornyatskiy",
            "Gornyy",
            "Gornyy",
            "Gornyy",
            "Gornyy",
            "Gornyy Rayon",
            "Gornyy Shchit",
            "Gornyye Klyuchi",
            "Gorod Blagoveshchensk",
            "Gorod Bryansk",
            "Gorod Buinsk",
            "Gorod Chelyabinsk",
            "Gorod Dyat’kovo",
            "Gorod Kazan’",
            "Gorod Magadan",
            "Gorod Nizhnekamsk",
            "Gorod Raychikhinsk",
            "Gorod Yelabuga",
            "Gorod Zainsk",
            "Gorod Zelënodol’sk",
            "Gorodets",
            "Gorodetskiy Rayon",
            "Gorodishche",
            "Gorodishche Volgograd Oblast",
            "Gorodnya",
            "Gorodoviki",
            "Gorodovikovsk",
            "Gorokhovets",
            "Gorshechenskiy Rayon",
            "Gorshechnoye",
            "Gorshkovo",
            "Gorskaya",
            "Goryachevodskiy",
            "Goryachiy Klyuch",
            "Gostagayevskaya",
            "Gostilitsy",
            "Goyty",
            "Grabovo",
            "Grakhovo",
            "Gramoteino",
            "Grayvoron",
            "Grayvoronskiy Rayon",
            "Grazhdanka",
            "Grazhdanka",
            "Grazhdanskoye",
            "Grebenskaya",
            "Grebnevo",
            "Grecheskoye",
            "Gremyachevo",
            "Gremyachinsk",
            "Gribanovskiy",
            "Gribanovskiy Rayon",
            "Grigoropolisskaya",
            "Grishkovka",
            "Gritsovskiy",
            "Grivenskaya",
            "Groznenskiy Rayon",
            "Groznyy",
            "Grushevskaya",
            "Gryazi",
            "Gryazinskiy Rayon",
            "Gryaznovskoye",
            "Gryazovets",
            "Gryazovetskiy Rayon",
            "Gubakha",
            "Gubden",
            "Gubkin",
            "Gubkinskiy",
            "Gubskaya",
            "Gudermes",
            "Gudermesskiy Rayon",
            "Gukovo",
            "Gul’kevichi",
            "Gumbetovskiy Rayon",
            "Gundelen",
            "Gundorovskiy",
            "Gunib",
            "Gunibskiy Rayon",
            "Gur’yevsk",
            "Gur’yevsk",
            "Gurbuki",
            "Gus’-Khrustal’nyy",
            "Gus’-Zheleznyy",
            "Gusev",
            "Gusevskiy",
            "Gusinoozyorsk",
            "Gusinoye Ozero",
            "Gvardeysk",
            "Gzhel’",
            "Ibresi",
            "Ibresinskiy Rayon",
            "Idrinskiy Rayon",
            "Idrinskoye",
            "Idritsa",
            "Igarka",
            "Iglino",
            "Ignatovka",
            "Igra",
            "Igrim",
            "Iki-Burul",
            "Iki-Burul’skiy Rayon",
            "Ikon-Khalk",
            "Ikovka",
            "Ikryanoye",
            "Iksha",
            "Il’ich",
            "Il’ichevo",
            "Il’ichëvo",
            "Il’inka",
            "Il’inskiy",
            "Il’inskiy Pogost",
            "Il’insko-Podomskoye",
            "Il’inskoye",
            "Il’inskoye",
            "Il’inskoye-Khovanskoye",
            "Il’ka",
            "Il’skiy",
            "Ilanskiy",
            "Ilanskiy Rayon",
            "Ilaskhan-Yurt",
            "Ilek",
            "Ileza",
            "Ilovka",
            "Ilovlya",
            "Ilyinogorsk",
            "Imeni Babushkina",
            "Imeni M. I. Kalinina",
            "Imeni Morozova",
            "Imeni Pervogo Maya",
            "Imeni Poliny Osipenko",
            "Imeni Stepana Razina",
            "Imeni Sverdlova",
            "Imeni Tsyurupy",
            "Imeni Vladimira Il’icha Lenina",
            "Imeni Vorovskogo",
            "Imeni Vorovskogo",
            "Imeni Zhelyabova",
            "Inderka",
            "Inozemtsevo",
            "Insar",
            "Insarskiy Rayon",
            "Inskoy",
            "Inta",
            "Inya",
            "Inya",
            "Inza",
            "Inzer",
            "Inzhavino",
            "Iogach",
            "Ipatovo",
            "Irafskiy Rayon",
            "Irbeyskiy Rayon",
            "Irbeyskoye",
            "Irbit",
            "Irgakly",
            "Irganay",
            "Irkliyevskaya",
            "Irsha",
            "Irtyshskiy",
            "Irtyshskiy",
            "Is",
            "Isakly",
            "Isakogorka",
            "Iset’",
            "Isetskiy Rayon",
            "Isetskoye",
            "Ishcherskaya",
            "Isheyevka",
            "Ishim",
            "Ishimbay",
            "Ishimskiy Rayon",
            "Ishkhoy-Yurt",
            "Ishley",
            "Ishnya",
            "Iskateley",
            "Iskitim",
            "Iskitimskiy Rayon",
            "Islamey",
            "Ispravnaya",
            "Issa",
            "Issad",
            "Issinskiy Rayon",
            "Istok",
            "Istok",
            "Istra",
            "Istrinskiy Rayon",
            "Isyangulovo",
            "Itatskiy",
            "Itum-Kali",
            "Itum-Kalinskiy Rayon",
            "Ivangorod",
            "Ivanino",
            "Ivanishchi",
            "Ivanovka",
            "Ivanovka",
            "Ivanovo",
            "Ivanovskiy Rayon",
            "Ivanovskiy Rayon",
            "Ivanovskoye",
            "Ivanovskoye",
            "Ivanteyevka",
            "Ivanteyevka",
            "Ivdel’",
            "Ivnya",
            "Ivnyanskiy Rayon",
            "Ivolginsk",
            "Ivolginskiy Rayon",
            "Ivot",
            "Izberbash",
            "Izborsk",
            "Izhevsk",
            "Izhevskoye",
            "Izhma",
            "Izhmorskiy",
            "Izluchinsk",
            "Izmalkovo",
            "Izmalkovskiy Rayon",
            "Izmaylovo",
            "Izmaylovo",
            "Iznoski",
            "Iznoskovskiy rayon",
            "Izobil’nyy",
            "Izoplit",
            "Izumrud",
            "Izvestkovyy",
            "Kaa-Khem",
            "Kabakovo",
            "Kabanovo",
            "Kabansk",
            "Kabardinka",
            "Kachkanar",
            "Kadaya",
            "Kadnikov",
            "Kadom",
            "Kadoshkino",
            "Kaduy",
            "Kaduyskiy Rayon",
            "Kadyy",
            "Kadyyskiy Rayon",
            "Kafyr-Kumukh",
            "Kagal’nitskaya",
            "Kakhun",
            "Kalach",
            "Kalach-na-Donu",
            "Kalachinsk",
            "Kalanguy",
            "Kalashnikovo",
            "Kalevala",
            "Kalga",
            "Kalikino",
            "Kalinin",
            "Kalininaul",
            "Kaliningrad",
            "Kalininsk",
            "Kalininskaya",
            "Kalininskiy",
            "Kalininskiy Rayon",
            "Kalino",
            "Kalinovo",
            "Kalinovskaya",
            "Kalinovskoye",
            "Kalmanka",
            "Kalmanskiy Rayon",
            "Kalnibolotskaya",
            "Kaltan",
            "Kaltasy",
            "Kaltay",
            "Kaluga",
            "Kalyazin",
            "Kalyazinskiy Rayon",
            "Kama",
            "Kambarka",
            "Kambileyevskoye",
            "Kamen’-na-Obi",
            "Kamen’-Rybolov",
            "Kamenetskiy",
            "Kamenka",
            "Kamenka",
            "Kamenka",
            "Kamenka",
            "Kamenka",
            "Kamenka",
            "Kamennogorsk",
            "Kamennomostskiy",
            "Kamennomostskoye",
            "Kamenolomni",
            "Kamensk-Shakhtinskiy",
            "Kamensk-Ural’skiy",
            "Kamenskiy",
            "Kamenskiy Rayon",
            "Kameshkovo",
            "Kaminskiy",
            "Kamsko-Ust’inskiy Rayon",
            "Kamyshevatskaya",
            "Kamyshin",
            "Kamyshla",
            "Kamyshlov",
            "Kamyzyak",
            "Kanadey",
            "Kanash",
            "Kanashevo",
            "Kanashskiy Rayon",
            "Kanayevka",
            "Kandalaksha",
            "Kandalakshskiy rayon",
            "Kandry",
            "Kanelovskaya",
            "Kanevskaya",
            "Kangalassy",
            "Kangly",
            "Kansk",
            "Kanskiy Rayon",
            "Kantemirovka",
            "Kantyshevo",
            "Kapotnya",
            "Kapustin Yar",
            "Kara-Tyube",
            "Karabanovo",
            "Karabash",
            "Karabash",
            "Karabudakhkent",
            "Karabudakhkentskiy Rayon",
            "Karabulak",
            "Karachayevsk",
            "Karachayevskiy Rayon",
            "Karachev",
            "Karagach",
            "Karagay",
            "Karagayla",
            "Karaginsky District",
            "Karaidel’",
            "Karakulino",
            "Karakulinskiy Rayon",
            "Karalat",
            "Karamakhi",
            "Karasuk",
            "Karata",
            "Karatuzskoye",
            "Kardailovo",
            "Kardonikskaya",
            "Kardymovo",
            "Kardzhin",
            "Kargalinskaya",
            "Kargasok",
            "Kargat",
            "Kargopol’",
            "Kargopol’skiy Rayon",
            "Karinskoye",
            "Karmaskaly",
            "Karpinsk",
            "Karpogory",
            "Karpovsky",
            "Karpushikha",
            "Karsun",
            "Kartalinskiy Rayon",
            "Kartaly",
            "Karymskoye",
            "Kashary",
            "Kashin",
            "Kashinskiy Rayon",
            "Kashira",
            "Kashirskiy Rayon",
            "Kashirskoye",
            "Kashkhatau",
            "Kasimov",
            "Kasimovskiy Rayon",
            "Kasli",
            "Kaspiysk",
            "Kaspiyskiy",
            "Kastanayevo",
            "Kastorenskiy Rayon",
            "Kastornoye",
            "Kasumkent",
            "Katav-Ivanovsk",
            "Katav-Ivanovskiy Rayon",
            "Kataysk",
            "Katunino",
            "Katyn'",
            "Katyr-Yurt",
            "Kavalerovo",
            "Kavkazskaya",
            "Kavkazskiy",
            "Kayakent",
            "Kayakentskiy Rayon",
            "Kayasula",
            "Kaybitskiy Rayon",
            "Kaytagskiy Rayon",
            "Kayyerkan",
            "Kaz",
            "Kazachinskiy Rayon",
            "Kazachinskoye",
            "Kazachka",
            "Kazaki",
            "Kazan",
            "Kazanskaya",
            "Kazanskaya",
            "Kazanskiy Rayon",
            "Kazanskoye",
            "Kazbekovskiy Rayon",
            "Kazinka",
            "Kedrovka",
            "Kedrovoye",
            "Kedrovyy",
            "Kedrovyy",
            "Kem’",
            "Kemerovo",
            "Kemlya",
            "Kenzhe",
            "Kerchevskiy",
            "Kerva",
            "Kesova Gora",
            "Kesovogorskiy Rayon",
            "Ketovo",
            "Kevsala",
            "Kez",
            "Khabarovsk",
            "Khabarovsk Vtoroy",
            "Khabarskiy Rayon",
            "Khabary",
            "Khabez",
            "Khadyzhensk",
            "Khadzhalmakhi",
            "Khamamatyurt",
            "Khambi-Irze",
            "Khandagayty",
            "Khandyga",
            "Khanino",
            "Khankala",
            "Khanskaya",
            "Khanty-Mansiysk",
            "Kharabali",
            "Kharitonovo",
            "Kharlu",
            "Kharovsk",
            "Kharovskiy Rayon",
            "Kharp",
            "Khasanskiy Rayon",
            "Khasavyurt",
            "Khasavyurtovskiy Rayon",
            "Khatanga",
            "Khatassy",
            "Khatukay",
            "Khaybullinskiy Rayon",
            "Khazar",
            "Khebda",
            "Khelyulya",
            "Khilok",
            "Khimki",
            "Khimki Urban Okrug",
            "Khingansk",
            "Khislavichi",
            "Khiv",
            "Khivskiy Rayon",
            "Khlevenskiy Rayon",
            "Khlevnoye",
            "Khodz’",
            "Khokhlovo",
            "Khokhryaki",
            "Kholbon",
            "Kholm",
            "Kholm-Zhirkovskiy",
            "Kholmogorskiy Rayon",
            "Kholmogory",
            "Kholmsk",
            "Kholmskiy",
            "Kholmskiy Rayon",
            "Kholodnyy",
            "Kholtoson",
            "Khomutovka",
            "Khomutovo",
            "Khonkholoy",
            "Khor",
            "Khorinsk",
            "Khorlovo",
            "Khorol’",
            "Khoroshëvo-Mnevniki",
            "Khosta",
            "Khot'kovo",
            "Khotynets",
            "Khovu-Aksy",
            "Khrabrovo",
            "Khrenovoye",
            "Khryashchevka",
            "Khuchni",
            "Khulimsunt",
            "Khumalag",
            "Khunzakh",
            "Khunzakhskiy Rayon",
            "Khurba",
            "Khvalovo",
            "Khvalynsk",
            "Khvalynskiy Rayon",
            "Khvastovichi",
            "Khvastovichskiy Rayon",
            "Khvatovka",
            "Khvorostyanka",
            "Khvoynaya",
            "Khvoyninskiy Rayon",
            "Kichera",
            "Kichmengskiy Gorodok",
            "Kichmengsko-Gorodetskiy Rayon",
            "Kideksha",
            "Kievskiy",
            "Kikerino",
            "Kiknur",
            "Kiknurskiy Rayon",
            "Kil’mez’",
            "Kilemarskiy Rayon",
            "Kilemary",
            "Kimovsk",
            "Kimrskiy Rayon",
            "Kimry",
            "Kinel’",
            "Kinel’-Cherkassy",
            "Kinel’skiy Rayon",
            "Kineshemskiy Rayon",
            "Kineshma",
            "Kingisepp",
            "Kingiseppskiy Rayon",
            "Kipen’",
            "Kireyevsk",
            "Kirgiz-Miyaki",
            "Kirillov",
            "Kirillovskiy Rayon",
            "Kirishi",
            "Kiritsy",
            "Kirov",
            "Kirov",
            "Kirovgrad",
            "Kirovo-Chepetsk",
            "Kirovo-Chepetskiy Rayon",
            "Kirovsk",
            "Kirovsk",
            "Kirovskaya",
            "Kirovskiy",
            "Kirovskiy",
            "Kirovskiy",
            "Kirovskiy Rayon",
            "Kirovskiy Rayon",
            "Kirovskiy Rayon",
            "Kirs",
            "Kirsanov",
            "Kirsanovskiy Rayon",
            "Kirya",
            "Kirzhach",
            "Kiselëvsk",
            "Kishcha",
            "Kishpek",
            "Kislovodsk",
            "Kislyakovskaya",
            "Kitovo",
            "Kiyasovo",
            "Kiyasovskiy Rayon",
            "Kiyevskoye",
            "Kizel",
            "Kizema",
            "Kizhinga",
            "Kizil’skiy Rayon",
            "Kizilyurt",
            "Kizilyurtovskiy Rayon",
            "Kizlyar",
            "Kizlyar",
            "Kizlyarskiy Rayon",
            "Kizner",
            "Klepikovskiy Rayon",
            "Kletnya",
            "Kletnyanskiy Rayon",
            "Kletskaya",
            "Klichka",
            "Klimovo",
            "Klimovsk",
            "Klimovskiy Rayon",
            "Klin",
            "Klinskiy Rayon",
            "Klintsovskiy Rayon",
            "Klintsy",
            "Klyavlino",
            "Klyazminskoe Reservoir",
            "Klyuchevsk",
            "Klyuchevskiy",
            "Klyuchi",
            "Klyuchi",
            "Knevichi",
            "Knyaginino",
            "Knyaze-Volkonskoye",
            "Knyazhpogostskiy Rayon",
            "Kobrinskoye",
            "Kobyayskiy Rayon",
            "Kochenëvo",
            "Kochevo",
            "Kochki",
            "Kochkurovo",
            "Kochkurovskiy Rayon",
            "Kochubey",
            "Kochubeyevskoye",
            "Kodino",
            "Kodinsk",
            "Kogalym",
            "Kokhma",
            "Kokino",
            "Kokorevka",
            "Kokoshkino",
            "Kokrek",
            "Koksovyy",
            "Kokuy",
            "Kol’chugino",
            "Kol’chuginskiy Rayon",
            "Kol’skiy Rayon",
            "Kol’tsovo",
            "Kola",
            "Kolchanovo",
            "Kolobovo",
            "Kolodeznyy",
            "Kologriv",
            "Kolomenskiy Rayon",
            "Kolomenskoye",
            "Kolomna",
            "Kolomyagi",
            "Kolosovka",
            "Kolotsky",
            "Kolpashevo",
            "Kolpino",
            "Kolpny",
            "Koltubanovskiy",
            "Koltushi",
            "Kolyshley",
            "Kolyubakino",
            "Kolyvan’",
            "Komarichi",
            "Komarichskiy Rayon",
            "Komarikhinskiy",
            "Komarovo",
            "Komendantsky aerodrom",
            "Kommunar",
            "Kommunar",
            "Kommunar",
            "Kommunarka",
            "Kommunisticheskiy",
            "Komsomol'skoye",
            "Komsomol’sk",
            "Komsomol’skiy",
            "Komsomol’skiy",
            "Komsomol’skiy Rayon",
            "Komsomol’skiy Rayon",
            "Komsomolets",
            "Komsomolets",
            "Komsomolsk-on-Amur",
            "Konakovo",
            "Konakovskiy Rayon",
            "Kondinskoye",
            "Kondol’",
            "Kondopoga",
            "Kondratovo",
            "Kondrovo",
            "Konëvo",
            "Konobeyevo",
            "Konokovo",
            "Konosha",
            "Konstantinovka",
            "Konstantinovo",
            "Konstantinovsk",
            "Konstantinovskaya",
            "Konstantinovskaya",
            "Konstantinovskiy",
            "Konstantinovskiy Rayon",
            "Konstantinovskoye",
            "Kontenko",
            "Konyshevka",
            "Kopanskaya",
            "Kopeysk",
            "Kopor’ye",
            "Koptëvo",
            "Korablino",
            "Korenëvo",
            "Korenëvo",
            "Korenovsk",
            "Korfovskiy",
            "Korkino",
            "Korkmaskala",
            "Kormëzhka",
            "Kormilovka",
            "Korobitsyno",
            "Korocha",
            "Korochanskiy Rayon",
            "Korolev",
            "Korolyov",
            "Korotchaevo",
            "Korotoyak",
            "Korsakov",
            "Korsakovo",
            "Kortkeros",
            "Koryazhma",
            "Korzhevskiy",
            "Korzhovka-Golubovka",
            "Kosa",
            "Kosaya Gora",
            "Kosh-Agach",
            "Koshekhabl’",
            "Koshekhabl’skiy Rayon",
            "Koshki",
            "Koshurnikovo",
            "Kosikha",
            "Kosikhinskiy Rayon",
            "Kosino",
            "Koslan",
            "Kosmynino",
            "Kostek",
            "Kosterevo",
            "Kostino",
            "Kostino",
            "Kostomuksha",
            "Kostroma",
            "Kostromskoy Rayon",
            "Kostrovo",
            "Kotel’nich",
            "Kotel’niki",
            "Kotelnikovo",
            "Kotlas",
            "Kotlasskiy Rayon",
            "Kotlovka",
            "Kotlyarevskaya",
            "Kotovo",
            "Kotovsk",
            "Kovalevskoye",
            "Kovdor",
            "Kovdorskiy Rayon",
            "Kovernino",
            "Kovrov",
            "Kovylkino",
            "Koyelga",
            "Koygorodok",
            "Koygorodskiy Rayon",
            "Koz’modem’yansk",
            "Kozel’sk",
            "Kozel’skiy Rayon",
            "Kozeyevo",
            "Kozhevnikovo",
            "Kozhukhovo",
            "Kozhva",
            "Kozlovka",
            "Kozlovka",
            "Kozlovo",
            "Kozlovskiy Rayon",
            "Kozyrëvsk",
            "Krapivinskiy",
            "Krapivna",
            "Krasavino",
            "Kraskino",
            "Kraskovo",
            "Krasnaya Gora",
            "Krasnaya Gorbatka",
            "Krasnaya Gorka",
            "Krasnaya Gorka",
            "Krasnaya Pahra",
            "Krasnaya Polyana",
            "Krasnaya Polyana",
            "Krasnaya Polyana",
            "Krasnaya Poyma",
            "Krasnaya Yaruga",
            "Krasnaya Zarya",
            "Krasnenskiy Rayon",
            "Krasninskiy Rayon",
            "Krasnoarmeysk",
            "Krasnoarmeysk",
            "Krasnoarmeyskaya",
            "Krasnoarmeyskiy",
            "Krasnoarmeyskiy Rayon",
            "Krasnoarmeyskoye",
            "Krasnoarmeyskoye",
            "Krasnoborsk",
            "Krasnobrodskiy",
            "Krasnochetayskiy Rayon",
            "Krasnodar",
            "Krasnofarfornyy",
            "Krasnogorodsk",
            "Krasnogorsk",
            "Krasnogorsk",
            "Krasnogorskiy",
            "Krasnogorskiy",
            "Krasnogorskiy",
            "Krasnogorskiy Rayon",
            "Krasnogorskiy Rayon",
            "Krasnogorskoye",
            "Krasnogorskoye",
            "Krasnogvardeyets",
            "Krasnogvardeyskiy",
            "Krasnogvardeyskiy Rayon",
            "Krasnogvardeyskiy Rayon",
            "Krasnogvardeyskoye",
            "Krasnogvardeyskoye",
            "Krasnogvardeyskoye",
            "Krasnogvargeisky",
            "Krasnokamensk",
            "Krasnokamensk",
            "Krasnokamsk",
            "Krasnokholm",
            "Krasnokholmskiy",
            "Krasnokholmskiy Rayon",
            "Krasnokumskoye",
            "Krasnolesnyy",
            "Krasnomayskiy",
            "Krasnoobsk",
            "Krasnooktyabr’skiy Rayon",
            "Krasnorechenskiy",
            "Krasnosel’skiy Rayon",
            "Krasnoshchekovo",
            "Krasnoslobodsk",
            "Krasnoslobodsk",
            "Krasnoslobodskiy Rayon",
            "Krasnotur’insk",
            "Krasnoturansk",
            "Krasnoufimsk",
            "Krasnoural’sk",
            "Krasnovishersk",
            "Krasnovka",
            "Krasnoyarka",
            "Krasnoyarsk",
            "Krasnoyarskaya",
            "Krasnoyarskiy",
            "Krasnoyarskiy Rayon",
            "Krasnoyarskiy Rayon",
            "Krasnoyaruzhskiy Rayon",
            "Krasnoye",
            "Krasnoye",
            "Krasnoye",
            "Krasnoye",
            "Krasnoye Selo",
            "Krasnoye-na-Volge",
            "Krasnozavodsk",
            "Krasnozërskoye",
            "Krasnoznamensk",
            "Krasnoznamensk",
            "Krasny Yar Volgograd Oblast",
            "Krasnyy Bogatyr’",
            "Krasnyy Bor",
            "Krasnyy Chikoy",
            "Krasnyy Gulyay",
            "Krasnyy Kholm",
            "Krasnyy Klyuch",
            "Krasnyy Kommunar",
            "Krasnyy Kurgan",
            "Krasnyy Kut",
            "Krasnyy Luch",
            "Krasnyy Oktyabr’",
            "Krasnyy Oktyabr’",
            "Krasnyy Oktyabr’",
            "Krasnyy Profintern",
            "Krasnyy Sulin",
            "Krasnyy Tkach",
            "Krasnyy Yar",
            "Krasnyy Yar",
            "Krasnyy Yar",
            "Krasnyy Yar",
            "Krasnyy Yar",
            "Krasnyy Yar",
            "Krasnyye Baki",
            "Krasnyye Barrikady",
            "Krasnyye Chetai",
            "Krasnyye Tkachi",
            "Krasyukovskaya",
            "Kratovo",
            "Krechevitsy",
            "Kremenki",
            "Krestetskiy Rayon",
            "Krestovskiy ostrov",
            "Kresttsy",
            "Krivodanovka",
            "Krivosheino",
            "Krivtsovo",
            "Krivyanskaya",
            "Kromy",
            "Kronshtadt",
            "Kropachëvo",
            "Kropotkin",
            "Krotovka",
            "Kruglolesskoye",
            "Krutaya Gorka",
            "Krutikha",
            "Krutinka",
            "Krylovskaya",
            "Krylovskiy Rayon",
            "Krym",
            "Krymsk",
            "Krymskiy Rayon",
            "Kshenskiy",
            "Kstovo",
            "Kuanda",
            "Kuba-Taba",
            "Kubachi",
            "Kubanskiy",
            "Kubinka",
            "Kuchugury",
            "Kudara-Somon",
            "Kudepsta",
            "Kudeyevskiy",
            "Kudinovo",
            "Kudryashovskiy",
            "Kudymkar",
            "Kugesi",
            "Kukmor",
            "Kukmorskiy Rayon",
            "Kukoboy",
            "Kukushtan",
            "Kul’dur",
            "Kulary",
            "Kulebaki",
            "Kuleshovka",
            "Kuli",
            "Kulinskiy Rayon",
            "Kulotino",
            "Kuloy",
            "Kultayevo",
            "Kulunda",
            "Kulundinskiy Rayon",
            "Kumëny",
            "Kumertau",
            "Kuminskiy",
            "Kumukh",
            "Kumylzhenskaya",
            "Kumysh",
            "Kun’inskiy Rayon",
            "Kunashak",
            "Kungur",
            "Kungurtug",
            "Kupavna",
            "Kupchino",
            "Kupino",
            "Kur’inskiy Rayon",
            "Kur’yanovo",
            "Kuragino",
            "Kurakh",
            "Kurakhskiy Rayon",
            "Kurba",
            "Kurchaloy",
            "Kurchatov",
            "Kurdzhinovo",
            "Kurgan",
            "Kurganinsk",
            "Kuril’sk",
            "Kurilovo",
            "Kurilsky District",
            "Kurkent",
            "Kurkino",
            "Kurlovo",
            "Kurmanayevka",
            "Kurort-Darasun",
            "Kurortnyy",
            "Kurortnyy Rayon",
            "Kurovskoye",
            "Kurovskoye",
            "Kursavka",
            "Kursk",
            "Kurskaya",
            "Kurskiy Rayon",
            "Kurtamysh",
            "Kurumkan",
            "Kurumoch",
            "Kurush",
            "Kusa",
            "Kusak",
            "Kushchëvskaya",
            "Kushelevka",
            "Kushnarënkovo",
            "Kushva",
            "Kuskovo",
            "Kuvandyk",
            "Kuvshinovo",
            "Kuvshinovskiy Rayon",
            "Kuybyshev",
            "Kuybyshevo",
            "Kuybyshevskiy Rayon",
            "Kuybyshevskiy Zaton",
            "Kuyeda",
            "Kuyvozi",
            "Kuz’minki",
            "Kuz’minskiye Otverzhki",
            "Kuzedeyevo",
            "Kuzhener",
            "Kuzhenerskiy Rayon",
            "Kuzhenkino",
            "Kuzhorskaya",
            "Kuzino",
            "Kuzino",
            "Kuznechikha",
            "Kuznechnoye",
            "Kuznetsk",
            "Kuznetskiy Rayon",
            "Kuzovatovo",
            "Kvarkeno",
            "Kvarkenskiy Rayon",
            "Kvartsitnyy",
            "Kyakhta",
            "Kyakhulay",
            "Kyra",
            "Kyren",
            "Kyshtovka",
            "Kyshtym",
            "Kysyl-Syr",
            "Kytmanovo",
            "Kyzburun Pervyy",
            "Kyzyl",
            "Kyzyl-Khaya",
            "Kyzyl-Mazhalyk",
            "Kyzyl-Oktyabr’skiy",
            "L’govskiy",
            "L’govskiy Rayon",
            "L’vovskiy",
            "Labinsk",
            "Labytnangi",
            "Ladovskaya Balka",
            "Ladozhskaya",
            "Ladushkin",
            "Laishevo",
            "Laishevskiy Rayon",
            "Lakha Nëvre",
            "Lakhdenpokh’ya",
            "Lakhtinskiy",
            "Lakinsk",
            "Lakkha Nëvre",
            "Lakskiy Rayon",
            "Lal’sk",
            "Langepas",
            "Lashma",
            "Latnaya",
            "Lavrentiya",
            "Lazarev",
            "Lazarevskoye",
            "Lazo",
            "Lazovskiy Rayon",
            "Lebedinyy",
            "Lebedyan’",
            "Lebedyanskiy Rayon",
            "Lebyazh’ye",
            "Lebyazh’ye",
            "Lebyazh’ye",
            "Lebyazh’yevskiy Rayon",
            "Lechinkay",
            "Ledmozero",
            "Lefortovo",
            "Lenina",
            "Leninaul",
            "Leningradskaya",
            "Leninkent",
            "Leninogorsk",
            "Leninogorskiy Rayon",
            "Leninsk",
            "Leninsk",
            "Leninsk-Kuznetskiy Rayon",
            "Leninsk-Kuznetsky",
            "Leninskiy",
            "Leninskiy",
            "Leninskiy Rayon",
            "Leninskiy Rayon",
            "Leninskiye Gory",
            "Leninskoye",
            "Leninskoye",
            "Leninskoye",
            "Lensk",
            "Lenskiy Rayon",
            "Leonidovo",
            "Leonovo",
            "Lepley",
            "Lermontov",
            "Lermontovka",
            "Lermontovo",
            "Lermontovo",
            "Leshukonskiy Rayon",
            "Leshukonskoye",
            "Leskenskiy rayon",
            "Leskolovo",
            "Lesnikovo",
            "Lesnoy",
            "Lesnoy",
            "Lesnoy",
            "Lesnoy",
            "Lesnoy Gorodok",
            "Lesnoy Rayon",
            "Lesnoye",
            "Lesnoye",
            "Lesnyye Polyany",
            "Lesnyye Polyany",
            "Lesogorskiy",
            "Lesosibirsk",
            "Lesozavodsk",
            "Letka",
            "Letnik",
            "Letnyaya Stavka",
            "Lev Tolstoy",
            "Lev-Tolstovskiy Rayon",
            "Levashevo",
            "Levashi",
            "Levashinskiy Rayon",
            "Levashovo",
            "Levikha",
            "Levoberezhnyy",
            "Levokumka",
            "Levokumskoye",
            "Lezhnevo",
            "Lezhnevskiy Rayon",
            "Lianozovo",
            "Likhobory",
            "Likhoslavl’",
            "Likhoslavl’skiy Rayon",
            "Likhovskoy",
            "Likhoy",
            "Likino-Dulevo",
            "Liman",
            "Limanskiy Rayon",
            "Linda",
            "Linëvo",
            "Lipetsk",
            "Lipetskiy Rayon",
            "Lipin Bor",
            "Lipovtsy",
            "Lisiy Nos",
            "Liski",
            "Liskinskiy Rayon",
            "Listvyagi",
            "Listvyanskiy",
            "Litovko",
            "Livadiya",
            "Livenka",
            "Livny",
            "LMS",
            "Lobanovo",
            "Lobnya",
            "Lobva",
            "Lodeynopol’skiy Rayon",
            "Lodeynoye Pole",
            "Log",
            "Logovskoye",
            "Loknya",
            "Lokomotivnyy",
            "Lokosovo",
            "Lokot’",
            "Loktevskiy Rayon",
            "Lomintsevskiy",
            "Lomonosov",
            "Lomonosovskiy Rayon",
            "Lomovka",
            "Londoko",
            "Lopandino",
            "Lopatino",
            "Lopatinskiy",
            "Lorino",
            "Losevo",
            "Losino-Petrovskiy",
            "Losinyy",
            "Lososina",
            "Lotoshino",
            "Lotoshinskiy Rayon",
            "Loukhi",
            "Lovlinskaya",
            "Lovozero",
            "Lovozerskiy Rayon",
            "Loyga",
            "Lozhki",
            "Lubyany",
            "Luchegorsk",
            "Luchki",
            "Luga",
            "Lugovaya",
            "Lugovskoy",
            "Lukh",
            "Lukhovitsy",
            "Lukhovka",
            "Lukhskiy Rayon",
            "Lukino",
            "Luknovo",
            "Lukovetskiy",
            "Lukoyanov",
            "Lukoyanovskiy Rayon",
            "Lunino",
            "Luppolovo",
            "Lutkun",
            "Luza",
            "Luzhniki",
            "Luzhskiy Rayon",
            "Luzino",
            "Luzskiy Rayon",
            "Lyalichi",
            "Lyambir’",
            "Lyambirskiy Rayon",
            "Lyamino",
            "Lyangasovo",
            "Lyantor",
            "Lyaskelya",
            "Lys’va",
            "Lyskovo",
            "Lysogorskaya",
            "Lysyye Gory",
            "Lytkarino",
            "Lyuban’",
            "Lyuberetskiy Rayon",
            "Lyubertsy",
            "Lyubim",
            "Lyubimskiy Rayon",
            "Lyubinskiy",
            "Lyublino",
            "Lyubokhna",
            "Lyubovsho",
            "Lyubuchany",
            "Lyubytino",
            "Lyubytinskiy Rayon",
            "Lyudinovo",
            "Lyudinovskiy Rayon",
            "Madzhalis",
            "Magadan",
            "Magan",
            "Magaramkent",
            "Magaramkentskiy Rayon",
            "Magas",
            "Magdagachi",
            "Magdagachinskiy Rayon",
            "Maginsk",
            "Magnitka",
            "Magnitogorsk",
            "Mago",
            "Makar’yev",
            "Makarov",
            "Makhachkala",
            "Makhalino",
            "Makkaveyevo",
            "Maksatikha",
            "Maksatikhinskiy Rayon",
            "Makushino",
            "Malakhovka",
            "Malaya Dubna",
            "Malaya Purga",
            "Malaya Serdoba",
            "Malaya Vishera",
            "Malgobek",
            "Malgobekskiy Rayon",
            "Malino",
            "Malinovoye Ozero",
            "Malinovskiy",
            "Malka",
            "Malmyzh",
            "Malmyzhskiy Rayon",
            "Maloarkhangel’sk",
            "Malokarachayevskiy Rayon",
            "Malopurginskiy Rayon",
            "Malougrenevo",
            "Malovisherskiy Rayon",
            "Maloyaroslavets",
            "Maloyaroslavetskiy Rayon",
            "Maloye Isakovo",
            "Maloye Verevo",
            "Malysheva",
            "Malyshevo",
            "Malyye Derbety",
            "Mamadysh",
            "Mamadyshskiy Rayon",
            "Mamedkala",
            "Mamonovo",
            "Mamontovka",
            "Mamontovo",
            "Mamontovskiy Rayon",
            "Manas",
            "Manaskent",
            "Manskiy Rayon",
            "Manturovo",
            "Manturovo",
            "Manturovskiy Rayon",
            "Manzherok",
            "Manzya",
            "Mar’ino",
            "Marevo",
            "Marevskiy Rayon",
            "Marfino",
            "Marfino",
            "Mari-Turek",
            "Mari-Turekskiy Rayon",
            "Mariinsk",
            "Mariinskiy Posad",
            "Mariinsko-Posadskiy Rayon",
            "Mariyenburg",
            "Markha",
            "Markovo",
            "Marks",
            "Marksovskiy Rayon",
            "Maromitsa",
            "Martan-Chu",
            "Martynovo",
            "Martynovskiy Rayon",
            "Martyush",
            "Masalovka",
            "Maslova Pristan’",
            "Maslovka",
            "Maslyanino",
            "Maslyaninskiy Rayon",
            "Matrosy",
            "Matveyev Kurgan",
            "Matveyevka",
            "Matveyevskoye",
            "Mayachnyy",
            "Maykop",
            "Maykop Republican Urban Okrug",
            "Maykopskiy Rayon",
            "Maykopskoye",
            "Maykor",
            "Mayma",
            "Mayminskiy Rayon",
            "Mayna",
            "Mayna",
            "Maynskiy Rayon",
            "Mayrtup",
            "Mayskiy",
            "Mayskiy",
            "Mayskiy",
            "Mayskiy",
            "Mayskiy Rayon",
            "Mayskoye",
            "Mayya",
            "Mazanovskiy Rayon",
            "Mechetinskaya",
            "Mechetlinskiy Rayon",
            "Mednogorsk",
            "Mednogorskiy",
            "Medvedevo",
            "Medvedevskiy Rayon",
            "Medveditsky",
            "Medvedok",
            "Medvedovskaya",
            "Medvenskiy Rayon",
            "Medvezh’i Ozëra",
            "Medvezh’yegorsk",
            "Medyn’",
            "Medynskiy Rayon",
            "Megion",
            "Mel’nikovo",
            "Melekhovo",
            "Melenki",
            "Melenkovskiy Rayon",
            "Meleuz",
            "Melikhovo",
            "Melikhovskaya",
            "Melioratorov",
            "Mendeleyevo",
            "Mendeleyevo",
            "Mendeleyevo",
            "Mendeleyevsk",
            "Mendeleyevskiy",
            "Mendeleyevskiy Rayon",
            "Menzelinsk",
            "Menzelinskiy Rayon",
            "Meshcherino",
            "Meshchovsk",
            "Meshchovskiy Rayon",
            "Mesker-Yurt",
            "Mesyagutovo",
            "Metallostroy",
            "Metrogorodok",
            "Mezen’",
            "Mezenskiy Rayon",
            "Mezgor'e",
            "Mezhdurechensk",
            "Mezhdurechensk",
            "Mezhdurechensk",
            "Mezhdurechenskiy",
            "Mezhdurechenskiy Rayon",
            "Mezhevoy",
            "Mezinovskiy",
            "Mga",
            "Mgachi",
            "Mglin",
            "Mglinskiy Rayon",
            "Miass",
            "Miasskoye",
            "Miatli",
            "Michurinsk",
            "Michurinskoye",
            "Mikhalkovo",
            "Mikhaylov",
            "Mikhaylovka",
            "Mikhaylovka",
            "Mikhaylovka",
            "Mikhaylovka",
            "Mikhaylovka Urban Okrug",
            "Mikhaylovsk",
            "Mikhaylovsk",
            "Mikhaylovskaya",
            "Mikhaylovskiy Rayon",
            "Mikhaylovskiy Rayon",
            "Mikhaylovskiy Rayon",
            "Mikhaylovskoye",
            "Mikhaylovskoye",
            "Mikhnëvo",
            "Mikulino",
            "Mikun’",
            "Mil’kovo",
            "Mil’kovskiy Rayon",
            "Millerovo",
            "Miloslavskoye",
            "Milyutinskaya",
            "Milyutinskiy Rayon",
            "Mindyak",
            "Mineralnye Vody",
            "Minusinsk",
            "Minusinskiy Rayon",
            "Mirninskiy Rayon",
            "Mirny",
            "Mirnyy",
            "Mirnyy",
            "Mirnyy",
            "Mirnyy",
            "Mirskoy",
            "Misheronskiy",
            "Mishkino",
            "Mishkino",
            "Mishkinskiy Rayon",
            "Miskindzha",
            "Mitkin",
            "Mitrofanovka",
            "Miyakinskiy Rayon",
            "Mizur",
            "Mnogoudobnoye",
            "Mnogovershinnyy",
            "Mochalishche",
            "Mochishche",
            "Mogocha",
            "Mogoytuy",
            "Mogzon",
            "Mokhsogollokh",
            "Mokrous",
            "Mokrousovo",
            "Mokrousovskiy Rayon",
            "Mokshan",
            "Molchanovo",
            "Molchanovskiy Rayon",
            "Molochnoye",
            "Molochnyy",
            "Molodezhnoye",
            "Molokovo",
            "Molokovo",
            "Momsky District",
            "Monastyrishche",
            "Monastyrshchina",
            "Monchegorsk",
            "Monetnyy",
            "Monino",
            "Mordino",
            "Mordovo",
            "Morgaushi",
            "Morgaushskiy Rayon",
            "Morki",
            "Morkinskiy Rayon",
            "Morozovsk",
            "Morshansk",
            "Mortka",
            "Moryakovskiy Zaton",
            "Mosal’sk",
            "Mosal’skiy Rayon",
            "Moscow",
            "Moshenskoy Rayon",
            "Moshenskoye",
            "Moshkovo",
            "Moshkovskiy Rayon",
            "Moskovskiy",
            "Moskovskiy",
            "Moskovskoye",
            "Mosrentgen",
            "Mostovskiy Rayon",
            "Mostovskoy",
            "Motygino",
            "Mozdok",
            "Mozhaysk",
            "Mozhayskiy Rayon",
            "Mozhga",
            "Mrakovo",
            "Mshinskaya",
            "Mstera",
            "Mstikhino",
            "Mtsensk",
            "Muchkapskiy",
            "Mugi",
            "Mugur-Aksy",
            "Mukhen",
            "Mukhorshibir’",
            "Mukhorshibirskiy Rayon",
            "Mukhtolovo",
            "Mulino",
            "Mullovka",
            "Mumra",
            "Mundybash",
            "Muranovo",
            "Murashi",
            "Murashinskiy Rayon",
            "Muravlenko",
            "Murino",
            "Murmansk",
            "Murmashi",
            "Murmino",
            "Murom",
            "Muromskiy Rayon",
            "Muromtsevo",
            "Muromtsevo",
            "Muromtsevskiy Rayon",
            "Mursalimkino",
            "Murygino",
            "Muslyumovo",
            "Muslyumovo",
            "Muslyumovskiy Rayon",
            "Mutsalaul",
            "Muyezerskiy",
            "Muyskiy Rayon",
            "Muzhi",
            "Myaksa",
            "Myatlevo",
            "Myaundzha",
            "Mys-Kamennyy",
            "Myshkin",
            "Myshkinskiy Rayon",
            "Myskhako",
            "Myski",
            "Mytishchi",
            "Mytishchi Urban Okrug",
            "Myurego",
            "Naberezhnyye Chelny",
            "Nachalovo",
            "Nadezhda",
            "Nadezhdinskiy Rayon",
            "Nadterechnyy Rayon",
            "Nadvoitsy",
            "Nadym",
            "Nadymskiy Rayon",
            "Nagaybakskiy Rayon",
            "Nagornyy",
            "Nagornyy",
            "Nagorsk",
            "Nagorskiy Rayon",
            "Nagutskoye",
            "Nakhabino",
            "Nakhodka",
            "Nal’chik",
            "Nalobikha",
            "Namskiy Rayon",
            "Namtsy",
            "Nar'yan-Mar",
            "Narimanov",
            "Naro-Fominsk",
            "Narovchat",
            "Nartan",
            "Nartkala",
            "Naryshkino",
            "Natsional’nyy Rayon Nemetskiy",
            "Natyrbovo",
            "Nauchnyy Gorodok",
            "Naurskaya",
            "Naurskiy Rayon",
            "Naushki",
            "Navashino",
            "Navlinskiy Rayon",
            "Navlya",
            "Navoloki",
            "Nazar'yevo",
            "Nazarovo",
            "Nazarovskiy Rayon",
            "Nazran’",
            "Nazranovskiy Rayon",
            "Nazyvayevsk",
            "Nebolchi",
            "Nebug",
            "Nedvigovka",
            "Neftegorsk",
            "Neftegorsk",
            "Neftegorskiy Rayon",
            "Neftekamsk",
            "Neftekumsk",
            "Nefteyugansk",
            "Nekhayevskaya",
            "Neklyudovo",
            "Nekouzskiy Rayon",
            "Nekrasovka",
            "Nekrasovka",
            "Nekrasovskaya",
            "Nekrasovskiy",
            "Nekrasovskiy Rayon",
            "Nekrasovskoye",
            "Nelazskoye",
            "Nelidovo",
            "Nema",
            "Neman",
            "Nemanskiy Rayon",
            "Nemchinovka",
            "Nemskiy Rayon",
            "Nerchinsk",
            "Nerchinskiy Zavod",
            "Nerchinsko-Zavodskiy Rayon",
            "Nerekhta",
            "Nerekhtskiy Rayon",
            "Nerl’",
            "Neryungri",
            "Nesterov",
            "Nesterovskaya",
            "Nesterovskiy Rayon",
            "Net’inka",
            "Nev’yansk",
            "Nevel’",
            "Nevel’sk",
            "Never",
            "Neverkino",
            "Neverkinskiy Rayon",
            "Nevinnomyssk",
            "Neya",
            "Neyskiy Rayon",
            "Neyvo-Rudyanka",
            "Neyvo-Shaytanskiy",
            "Nezhdaninskoe",
            "Nezhinka",
            "Nezhinskiy",
            "Nezlobnaya",
            "Nikel",
            "Nikitinskiy",
            "Nikol’sk",
            "Nikol’sk",
            "Nikol’sk",
            "Nikol’skoye",
            "Nikol’skoye",
            "Nikola-Lenivets",
            "Nikolayevka",
            "Nikolayevka",
            "Nikolayevka",
            "Nikolayevsk",
            "Nikolayevsk-on-Amure",
            "Nikolayevskaya",
            "Nikolayevskiy",
            "Nikolina Gora",
            "Nikolo-Berëzovka",
            "Nikolo-Pavlovskoye",
            "Nikologory",
            "Nikulino",
            "Niny",
            "Nizhneangarsk",
            "Nizhnedevitsk",
            "Nizhnedevitskiy Rayon",
            "Nizhneingashskiy Rayon",
            "Nizhneivkino",
            "Nizhnekamsk",
            "Nizhnekamskiy Rayon",
            "Nizhnepavlovka",
            "Nizhnesortymskiy",
            "Nizhnetroitskiy",
            "Nizhnevartovsk",
            "Nizhneye Kazanishche",
            "Nizhniy Arkhyz",
            "Nizhniy Baskunchak",
            "Nizhniy Bestyakh",
            "Nizhniy Cherek",
            "Nizhniy Dzhengutay",
            "Nizhniy Ingash",
            "Nizhniy Kislyay",
            "Nizhniy Kuranakh",
            "Nizhniy Kurkuzhin",
            "Nizhniy Lomov",
            "Nizhniy Mamon",
            "Nizhniy Novgorod",
            "Nizhniy Odes",
            "Nizhniy Sayantuy",
            "Nizhniy Tsasuchey",
            "Nizhniy Ufaley",
            "Nizhniye Achaluki",
            "Nizhniye Sergi",
            "Nizhniye Vyazovyye",
            "Nizhny Chir",
            "Nizhny Tagil",
            "Nizhnyaya Irga",
            "Nizhnyaya Maktama",
            "Nizhnyaya Omka",
            "Nizhnyaya Poyma",
            "Nizhnyaya Salda",
            "Nizhnyaya Tavda",
            "Nizhnyaya Tura",
            "Nogamerzin-Yurt",
            "Nogayskiy Rayon",
            "Noginsk",
            "Noginsk-9",
            "Noginskiy Rayon",
            "Nogir",
            "Nogliki",
            "Nolinsk",
            "Nolinskiy Rayon",
            "Norilsk",
            "Novaya Balakhna",
            "Novaya Bryan’",
            "Novaya Chara",
            "Novaya Chigla",
            "Novaya Derevnya",
            "Novaya Ladoga",
            "Novaya Lyada",
            "Novaya Lyalya",
            "Novaya Maka",
            "Novaya Malykla",
            "Novaya Mayna",
            "Novaya Tavolzhanka",
            "Novaya Usman’",
            "Novaya Zaimka",
            "Novgorodskiy Rayon",
            "Novichikha",
            "Novichikhinskiy Rayon",
            "Novikovo",
            "Novki",
            "Novo-Nikol’skoye",
            "Novo-Peredelkino",
            "Novo-Talitsy",
            "Novoagansk",
            "Novoaleksandrovsk",
            "Novoalekseyevskaya",
            "Novoaltaysk",
            "Novoanninsky",
            "Novoasbest",
            "Novobataysk",
            "Novobelokatay",
            "Novobessergenovka",
            "Novobeysugskaya",
            "Novobirilyussy",
            "Novoblagodarnoye",
            "Novobureyskiy",
            "Novocheboksarsk",
            "Novocheremshansk",
            "Novocherkassk",
            "Novochernorechenskiy",
            "Novoderevyankovskaya",
            "Novodmitriyevskaya",
            "Novodugino",
            "Novodvinsk",
            "Novodzhereliyevskaya",
            "Novogagatli",
            "Novogireyevo",
            "Novogornyy",
            "Novogurovskiy",
            "Novoivanovskoye",
            "Novokayakent",
            "Novokhopërsk",
            "Novokhoperskiy Rayon",
            "Novokhovrino",
            "Novokizhinginsk",
            "Novokorsunskaya",
            "Novokruchininskiy",
            "Novokubansk",
            "Novokubanskiy Rayon",
            "Novokuybyshevsk",
            "Novokuz’minki",
            "Novokuznetsk",
            "Novokuznetskiy Rayon",
            "Novolabinskaya",
            "Novolakskiy Rayon",
            "Novolakskoye",
            "Novoleushkovskaya",
            "Novolugovoye",
            "Novomalorossiyskaya",
            "Novomichurinsk",
            "Novomikhaylovskiy",
            "Novominskaya",
            "Novomoskovsk",
            "Novomyshastovskaya",
            "Novonikol’sk",
            "Novonikolayevsky Volgograd Oblast",
            "Novonikolskoe",
            "Novoomskiy",
            "Novoorlovsk",
            "Novoorsk",
            "Novoorskiy Rayon",
            "Novooskol’skiy Rayon",
            "Novopavlovka",
            "Novopavlovsk",
            "Novopesterevo",
            "Novopetrovskoye",
            "Novopistsovo",
            "Novoplatnirovskaya",
            "Novopodrezkovo",
            "Novopokrovka",
            "Novopokrovka",
            "Novopokrovskaya",
            "Novoraychikhinsk",
            "Novorossiysk",
            "Novorozhdestvenskaya",
            "Novorudnyy",
            "Novorzhev",
            "Novorzhevskiy Rayon",
            "Novoseleznëvo",
            "Novoselitskoye",
            "Novosemeykino",
            "Novosergiyevka",
            "Novoshakhtinsk",
            "Novoshakhtinskiy",
            "Novoshcherbinovskaya",
            "Novosheshminsk",
            "Novosheshminskiy Rayon",
            "Novosibirsk",
            "Novosibirskiy Rayon",
            "Novosil’",
            "Novosilikatnyy",
            "Novosin’kovo",
            "Novosineglazovskiy",
            "Novosmolinskiy",
            "Novosokol’nicheskiy Rayon",
            "Novosokol’niki",
            "Novospasskoye",
            "Novosysoyevka",
            "Novoterskiy",
            "Novotitarovskaya",
            "Novotor”yal’skiy Rayon",
            "Novotroitsk",
            "Novotroitskaya",
            "Novotyryshkino",
            "Novoukrainskiy",
            "Novoukrainskoye",
            "Novoul’yanovsk",
            "Novoural’sk",
            "Novousmanskiy Rayon",
            "Novoutkinsk",
            "Novouzensk",
            "Novovarshavka",
            "Novovelichkovskaya",
            "Novovladykino",
            "Novovoronezh",
            "Novovyazniki",
            "Novoye",
            "Novoye Devyatkino",
            "Novoye Leushino",
            "Novozavedennoye",
            "Novozavidovskiy",
            "Novozhivotinnoye",
            "Novozybkov",
            "Novozybkovskiy Rayon",
            "Novy Rogachik",
            "Novyy",
            "Novyy Buyan",
            "Novyy Byt",
            "Novyy Chirkey",
            "Novyy Izborsk",
            "Novyy Karachay",
            "Novyy Khushet",
            "Novyy Kostek",
            "Novyy Nekouz",
            "Novyy Oskol",
            "Novyy Ropsk",
            "Novyy Sulak",
            "Novyy Uoyan",
            "Novyy Urengoy",
            "Novyy Urgal",
            "Novyy Yegorlyk",
            "Novyy Zagan",
            "Novyye Atagi",
            "Novyye Burasy",
            "Novyye Cherëmushki",
            "Novyye Gorki",
            "Novyye Kuz’minki",
            "Novyye Lapsary",
            "Novyye Lyady",
            "Novyye Zori",
            "Noyabrsk",
            "Nozhay-Yurt",
            "Nozhay-Yurtovskiy Rayon",
            "Nudol’",
            "Nuradilovo",
            "Nurimanovskiy Rayon",
            "Nurlat",
            "Nurlatskiy rayon",
            "Nurma",
            "Nyagan",
            "Nyandoma",
            "Nyandomskiy Rayon",
            "Nyazepetrovsk",
            "Nyazepetrovskiy Rayon",
            "Nyda",
            "Nyrob",
            "Nytva",
            "Nyuksenitsa",
            "Nyurba",
            "Nyurbinsky District",
            "Ob’",
            "Oblivskaya",
            "Obluchenskiy Rayon",
            "Obninsk",
            "Obolensk",
            "Oboyan’",
            "Oboyanskiy Rayon",
            "Obsharovka",
            "Obukhovo",
            "Obukhovo",
            "Obukhovskoye",
            "Ochakovo-Matveyevskoye",
            "Ochër",
            "Odesskoye",
            "Odintsovo",
            "Odintsovskiy Rayon",
            "Odoyev",
            "Okha",
            "Okhansk",
            "Okhotsk",
            "Okino-Klyuchi",
            "Okinskiy Rayon",
            "Okoneshnikovo",
            "Oksovskiy",
            "Oktyabr’sk",
            "Oktyabr’skiy",
            "Oktyabr’skiy",
            "Oktyabr’skiy",
            "Oktyabr’skiy",
            "Oktyabr’skiy Rayon",
            "Oktyabr’skiy Rayon",
            "Oktyabr’skiy Rayon",
            "Oktyabrsky",
            "Okulovka",
            "Okulovskiy Rayon",
            "Ol'yavidovo",
            "Ol’ga",
            "Ol’ginka",
            "Ol’gino",
            "Ol’gino",
            "Ol’ginskaya",
            "Ol’ginskaya",
            "Ol’khovatka",
            "Ol’khovatskiy Rayon",
            "Ol’skiy Rayon",
            "Ola",
            "Olenegorsk",
            "Olenino",
            "Olenyok",
            "Olenyoksky District",
            "Olkhovka Volgograd Oblast",
            "Olonets",
            "Olovyannaya",
            "Olovyanninskiy Rayon",
            "Olym",
            "Olyokminsk",
            "Olyokminsky District",
            "Olyutorskiy Rayon",
            "Omsk",
            "Omsukchan",
            "Omsukchanskiy Rayon",
            "Omutinskiy",
            "Omutinskiy Rayon",
            "Omutninsk",
            "Onega",
            "Onezhskiy Rayon",
            "Onguday",
            "Ongudayskiy Rayon",
            "Onokhino",
            "Onokhoy",
            "Opalikha",
            "Oparino",
            "Opechenskiy Posad",
            "Opochka",
            "Oranzherei",
            "Orda",
            "Ordynskiy Rayon",
            "Ordynskoye",
            "Ordzhonikidzevskiy",
            "Orekhovo-Borisovo",
            "Orekhovo-Borisovo Severnoye",
            "Orekhovo-Zuyevo",
            "Orekhovo-Zuyevskiy Rayon",
            "Orël",
            "Orël-Izumrud",
            "Orenburg",
            "Orgtrud",
            "Orichevskiy Rayon",
            "Orichi",
            "Orlik",
            "Orlov",
            "Orlovka",
            "Orlovo",
            "Orlovskiy",
            "Orlovskiy",
            "Orlovskiy Rayon",
            "Orotukan",
            "Orsha",
            "Orshanka",
            "Orshanskiy Rayon",
            "Orsk",
            "Orud’yevo",
            "Osa",
            "Osel’ki",
            "Osinki",
            "Osinniki",
            "Osinovo",
            "Ossora",
            "Ostankinskiy",
            "Ostashëvo",
            "Ostashkov",
            "Ostashkovskiy Rayon",
            "Oster",
            "Ostrogozhsk",
            "Ostrov",
            "Ostrovnoy",
            "Ostrovskiy Rayon",
            "Ostrovskiy Rayon",
            "Ostrovskoye",
            "Ostrovtsy",
            "Otkaznoye",
            "Otrada",
            "Otradnaya",
            "Otradnoye",
            "Otradnoye",
            "Otradnoye",
            "Otradnyy",
            "Otrado-Kubanskoye",
            "Otruba",
            "Ous",
            "Overyata",
            "Ovoshchi",
            "Ovsyanka",
            "Ovsyanka",
            "Oymyakonskiy District",
            "Oyskhara",
            "Ozerki",
            "Ozernovskiy",
            "Ozërnyy",
            "Ozersk",
            "Ozërsk",
            "Ozerskiy Rayon",
            "Ozëry",
            "Ozherel’ye",
            "Ozyory Urban Okrug",
            "Pachelma",
            "Palana",
            "Palatka",
            "Palekh",
            "Palekhskiy Rayon",
            "Palkino",
            "Palkinskiy Rayon",
            "Pallasovka",
            "Pamyat’ Parizhskoy Kommuny",
            "Pangody",
            "Panino",
            "Paninskiy Rayon",
            "Pankovka",
            "Pankrushikha",
            "Pankrushikhinskiy Rayon",
            "Parabel’",
            "Paran’ga",
            "Paran’ginskiy Rayon",
            "Paratunka",
            "Paraul",
            "Parfen’yevo",
            "Parfen’yevskiy Rayon",
            "Parfino",
            "Parfinskiy Rayon",
            "Pargolovo",
            "Parizh",
            "Parkovyy",
            "Parma",
            "Parnas",
            "Partizan",
            "Partizansk",
            "Partizanskiy Rayon",
            "Partizanskoye",
            "Partsa",
            "Pasegovo",
            "Pasha",
            "Pashiya",
            "Pashkovskiy",
            "Pavelets",
            "Pavino",
            "Pavlodol’skaya",
            "Pavlogradka",
            "Pavlovka",
            "Pavlovka",
            "Pavlovo",
            "Pavlovo",
            "Pavlovsk",
            "Pavlovsk",
            "Pavlovsk",
            "Pavlovskaya",
            "Pavlovskaya Sloboda",
            "Pavlovskiy",
            "Pavlovskiy Posad",
            "Pavlovskiy Rayon",
            "Pavlovskiy Rayon",
            "Pavlovskiy Rayon",
            "Pavlovskiy Rayon",
            "Pavlovskiy Rayon",
            "Pavshino",
            "Pechenga",
            "Pechengskiy Rayon",
            "Pechersk",
            "Pechora",
            "Pechorskiy Rayon",
            "Pechory",
            "Pelagiada",
            "Peleduy",
            "Pelym",
            "Peno",
            "Penovskiy Rayon",
            "Penza",
            "Penzhinskiy Rayon",
            "Peredovaya",
            "Pereleshino",
            "Pereleshinskiy",
            "Perelyub",
            "Peremyshl’",
            "Peremyshl’skiy Rayon",
            "Perepravnaya",
            "Pereslavl’-Zalesskiy",
            "Pereslavskiy Rayon",
            "Peresvet",
            "Perevoloki",
            "Perevolotskiy",
            "Perevoz",
            "Pereyaslovskaya",
            "Perm",
            "Persianovka",
            "Pervoavgustovskiy",
            "Pervomaysk",
            "Pervomayskaya",
            "Pervomayskiy",
            "Pervomayskiy",
            "Pervomayskiy",
            "Pervomayskiy",
            "Pervomayskiy",
            "Pervomayskiy",
            "Pervomayskiy Rayon",
            "Pervomayskiy Rayon",
            "Pervomayskoye",
            "Pervomayskoye",
            "Pervomayskoye",
            "Pervomayskoye",
            "Pervomayskoye",
            "Pervoural’sk",
            "Pervoye Maya",
            "Peschanokopskoye",
            "Peshki",
            "Peshkovo",
            "Peski",
            "Peskovka",
            "Pesochnoye",
            "Pesochnyy",
            "Pestovo",
            "Pestovskiy Rayon",
            "Pestravka",
            "Pestrechinskiy Rayon",
            "Pestretsy",
            "Pestyaki",
            "Pestyakovskiy Rayon",
            "Peterhof",
            "Petra-Dubrava",
            "Petro-Slavyanka",
            "Petrodvorets",
            "Petrogradka",
            "Petrokamenskoye",
            "Petropavlovka",
            "Petropavlovsk-Kamchatsky",
            "Petropavlovskaya",
            "Petropavlovskaya",
            "Petropavlovskiy Rayon",
            "Petropavlovskoye",
            "Petrov Val",
            "Petrovo-Dal’neye",
            "Petrovsk",
            "Petrovsk",
            "Petrovsk-Zabaykal’skiy",
            "Petrovskaya",
            "Petrovskaya",
            "Petrovskiy",
            "Petrovskiy",
            "Petrozavodsk",
            "Petukhovo",
            "Petushinskiy Rayon",
            "Petushki",
            "Pevek",
            "Pichayevo",
            "Pikalëvo",
            "Pil’na",
            "Pil’ninskiy Rayon",
            "Pindushi",
            "Pinega",
            "Pinerovka",
            "Pinezhskiy Rayon",
            "Pinyug",
            "Pioner",
            "Pionerskiy",
            "Pionerskiy",
            "Pirogovo",
            "Pirogovskiy",
            "Pirovskoye",
            "Pistsovo",
            "Pitelino",
            "Piterka",
            "Pitkyaranta",
            "Pitkyarantskiy Rayon",
            "Pizhanka",
            "Pizhanskiy Rayon",
            "Pizhma",
            "Planovskoye",
            "Plast",
            "Plastun",
            "Plastunovskaya",
            "Platnirovskaya",
            "Platonovka",
            "Plavsk",
            "Plekhanovo",
            "Plës",
            "Plesetsk",
            "Pleshanovo",
            "Plotnikovo",
            "Plyussa",
            "Pochep",
            "Pochepskiy Rayon",
            "Pochinki",
            "Pochinkovskiy Rayon",
            "Pochinok",
            "Poddor’ye",
            "Poddorskiy Rayon",
            "Podgorenskiy",
            "Podgorenskiy Rayon",
            "Podgornaya",
            "Podgornoye",
            "Podgornoye",
            "Podgorodnyaya Pokrovka",
            "Podkletnoye",
            "Podkumskiy",
            "Podlesnoye",
            "Podlesnyy",
            "Podnov’ye",
            "Podolsk",
            "Podosinki",
            "Podosinovets",
            "Podosinovskiy Rayon",
            "Podporozh’ye",
            "Podporozhskiy Rayon",
            "Podsineye",
            "Podsolnechnoye",
            "Podsosnovo",
            "Podstepki",
            "Podyuga",
            "Pogar",
            "Pogarskiy Rayon",
            "Pogranichnyy",
            "Poim",
            "Pokachi",
            "Pokhvistnevo",
            "Pokoynoye",
            "Pokrov",
            "Pokrovka",
            "Pokrovo-Prigorodnoye",
            "Pokrovsk",
            "Pokrovskoye",
            "Pokrovskoye",
            "Pokrovskoye",
            "Pokrovskoye",
            "Pokrovskoye-Streshnëvo",
            "Polazna",
            "Poldnëvoye",
            "Polessk",
            "Poletayevo",
            "Polevskoy",
            "Polotnyanyy Zavod",
            "Polovinnoye",
            "Polovinnoye",
            "Poltavka",
            "Poltavsky",
            "Polunochnoye",
            "Polyany",
            "Polyarnyy",
            "Polyarnyye Zori",
            "Polysayevo",
            "Pomary",
            "Ponazyrevo",
            "Ponazyrevskiy Rayon",
            "Ponezhukay",
            "Pontonnyy",
            "Ponyri Vtoryye",
            "Popova",
            "Popovo",
            "Popovsky",
            "Poputnaya",
            "Porech’ye-Rybnoye",
            "Poretskiy Rayon",
            "Poretskoye",
            "Porkhov",
            "Porkhovskiy Rayon",
            "Poronaysk",
            "Porosozero",
            "Port-Katon",
            "Poselki",
            "Posëlok Mar’ino",
            "Poshekhon’ye",
            "Poshekhonskiy Rayon",
            "Pospelikha",
            "Pospelikhinskiy Rayon",
            "Potanino",
            "Povarovo",
            "Povedniki",
            "Povenets",
            "Povolzhskiy",
            "Povorino",
            "Povorinskiy Rayon",
            "Poyakonda",
            "Poyarkovo",
            "Poykovskiy",
            "Pozhva",
            "Praskoveya",
            "Pravda",
            "Pravdinsk",
            "Pravdinskiy",
            "Pravoberezhnyy Rayon",
            "Pravokubanskiy",
            "Pravokumskoye",
            "Prechistoye",
            "Predivinsk",
            "Pregradnaya",
            "Pregradnoye",
            "Preobrazheniye",
            "Preobrazhenskaya Volgograd Oblast",
            "Presnenskiy",
            "Priamurskiy",
            "Priargunsk",
            "Priargunskiy Rayon",
            "Pribaykal’skiy Rayon",
            "Pribrezhnyy",
            "Pridonskoy",
            "Prigorodka",
            "Prigorodnoye",
            "Prigorodnyy",
            "Prigorodnyy Rayon",
            "Priiskovyy",
            "Prikubanskiy",
            "Prikubanskiy Rayon",
            "Priladozhskiy",
            "Priluzskiy Rayon",
            "Primalkinskoye",
            "Primorka",
            "Primorsk",
            "Primorsk",
            "Primorsk",
            "Primorskiy",
            "Primorskiy Rayon",
            "Primorskiy Rayon",
            "Primorsko-Akhtarsk",
            "Priob’ye",
            "Prionezhskiy Rayon",
            "Priozërsk",
            "Priozersky",
            "Pristen’",
            "Pristenskiy Rayon",
            "Pritomskiy",
            "Priupskiy",
            "Priural’skiy Rayon",
            "Privodino",
            "Privolzhsk",
            "Privolzhskiy",
            "Privolzhskiy",
            "Privolzhskiy Rayon",
            "Privolzhskiy Rayon",
            "Privolzhskoye",
            "Priyutnoye",
            "Priyutovo",
            "Prochnookopskaya",
            "Prokhladnenskiy Rayon",
            "Prokhladnyy",
            "Prokhorovka",
            "Prokhorovskiy Rayon",
            "Prokop’yevsk",
            "Prokop’yevskiy Rayon",
            "Prokudskoye",
            "Proletariy",
            "Proletarsk",
            "Proletarskiy",
            "Proletarskiy",
            "Promyshlennaya",
            "Promyshlennovskiy",
            "Promyshlennyy",
            "Pronsk",
            "Prosvet",
            "Protvino",
            "Provideniya",
            "Providenskiy Rayon",
            "Prudy",
            "Pryamitsyno",
            "Pryazha",
            "Psebay",
            "Psedakh",
            "Pshada",
            "Pshekhskaya",
            "Pskov",
            "Pskovskiy Rayon",
            "Psygansu",
            "Psyzh",
            "Puchezh",
            "Puchezhskiy Rayon",
            "Pudem",
            "Pudozh",
            "Pugachev",
            "Puksoozero",
            "Purekh",
            "Purovskiy Rayon",
            "Purpe",
            "Purshevo",
            "Pushchino",
            "Pushkin",
            "Pushkino",
            "Pushkino",
            "Pushkino-Gorskiy Rayon",
            "Pushkinskiy Rayon",
            "Pushkinskiye Gory",
            "Pustoshka",
            "Pustoshkinskiy Rayon",
            "Putëvka",
            "Puteyets",
            "Putyatin",
            "Putyatino",
            "Pyaozerskiy",
            "Pyatigorsk",
            "Pyatigorskiy",
            "Pyatnitskoye",
            "Pyatovskiy",
            "Pychas",
            "Pyra",
            "Pyshchug",
            "Pyshma",
            "Pyt-Yakh",
            "Pytalovo",
            "Pytalovskiy Rayon",
            "Rabocheostrovsk",
            "Rabotki",
            "Radchenko",
            "Radishchevo",
            "Raditsa-Krylovka",
            "Radovitskiy",
            "Radumlya",
            "Raduzhny",
            "Raduzhnyy",
            "Raduzhnyy",
            "Raduzhnyy",
            "Rakhmanovo",
            "Rakitnoye",
            "Rakityanskiy Rayon",
            "Ramenki",
            "Ramenskiy Rayon",
            "Ramenskoye",
            "Rameshki",
            "Rameshkovskiy Rayon",
            "Ramon’",
            "Ramonskiy Rayon",
            "Rasshevatskaya",
            "Rasskazovo",
            "Rasskazovskiy Rayon",
            "Rassvet",
            "Rassvet",
            "Raychikhinsk",
            "Rayevskaya",
            "Rayevskiy",
            "Razdol’noye",
            "Razdolinsk",
            "Razliv",
            "Razumnoye",
            "Razvilka",
            "Rebrikha",
            "Rebrikhinskiy Rayon",
            "Rechitsy",
            "Rechka-Vydrino",
            "Redkino",
            "Reftinskiy",
            "Remontnoye",
            "Rep’yevskiy Rayon",
            "Repino",
            "Repino",
            "Repnoye",
            "Reshetikha",
            "Reshetnikovo",
            "Rettikhovka",
            "Reutov",
            "Revda",
            "Revda",
            "Revyakino",
            "Rezh",
            "Rochegda",
            "Rodino",
            "Rodinskiy Rayon",
            "Rodionovo-Nesvetaiskoye",
            "Rodionovo-Nesvetayskaya",
            "Rodniki",
            "Rodniki",
            "Rodnikovskaya",
            "Rodnikovskiy Rayon",
            "Rogachev",
            "Rogachëvo",
            "Rognedino",
            "Rognedinskiy Rayon",
            "Rogovatoye",
            "Rogovskaya",
            "Romanovka",
            "Romanovka",
            "Romanovka",
            "Romanovo",
            "Romanovskaya",
            "Romanovskiy Rayon",
            "Romnenskiy Rayon",
            "Romny",
            "Romodanovo",
            "Romodanovskiy Rayon",
            "Roschinskiy",
            "Roshal’",
            "Roshchino",
            "Roshchino",
            "Roshni-Chu",
            "Roslavl’",
            "Roslyakovo",
            "Rossosh’",
            "Rossoshanskiy Rayon",
            "Rostokino",
            "Rostov",
            "Rostov-na-Donu",
            "Rostovka",
            "Rostovskiy Rayon",
            "Roven’skiy Rayon",
            "Rovnoye",
            "Roza",
            "Rozhdestveno",
            "Rozhdestveno",
            "Rozhdestveno",
            "Rozhdestvenskiy",
            "Rtishchevo",
            "Rublëvo",
            "Rubtsovsk",
            "Rubtsovskiy Rayon",
            "Rubyozhny",
            "Rudnaya Pristan’",
            "Rudnichnyy",
            "Rudnichnyy",
            "Rudnichnyy",
            "Rudnya",
            "Rudnya Volgograd Oblast",
            "Rudnyy",
            "Rumyantsevo",
            "Ruskeala",
            "Russkaya Polyana",
            "Russkinskiye",
            "Russkiy",
            "Russkiy Aktash",
            "Russkiy Kameshkir",
            "Russko-Osinovsky",
            "Russko-Vysotskoye",
            "Rutul",
            "Rutul’skiy Rayon",
            "Ruza",
            "Ruzayevka",
            "Ruzayevskiy Rayon",
            "Ruzskiy Rayon",
            "Ryabovo",
            "Ryazan’",
            "Ryazanskaya",
            "Ryazanskiy",
            "Ryazanskiy Rayon",
            "Ryazhsk",
            "Rybatskoye",
            "Rybinsk",
            "Rybinskiy Rayon",
            "Rybnaya Sloboda",
            "Rybno-Slobodskiy Rayon",
            "Rybnovskiy Rayon",
            "Rybnoye",
            "Rybnoye",
            "Ryl’sk",
            "Ryzdvyanyy",
            "Rzhaksa",
            "Rzhanitsa",
            "Rzhavki",
            "Rzhev",
            "Sabinskiy Rayon",
            "Sabnova",
            "Sachkovichi",
            "Sadovoye",
            "Sadovoye",
            "Sadovyy",
            "Safakulevo",
            "Safakulevskiy Rayon",
            "Safonovo",
            "Safonovo",
            "Safonovskiy Rayon",
            "Sagan-Nur",
            "Sagopshi",
            "Saint Petersburg",
            "Sakharovo",
            "Sakmara",
            "Sal’sk",
            "Salair",
            "Salavat",
            "Salekhard",
            "Salmi",
            "Saltykovka",
            "Salym",
            "Samagaltay",
            "Samara",
            "Samarskoye",
            "Samashki",
            "Sambek",
            "Samoded",
            "Samoylovka",
            "Samoylovsky",
            "Sampsonievskiy",
            "Sampurskiy Rayon",
            "Samur",
            "Samus’",
            "Sanchursk",
            "Sanchurskiy Rayon",
            "Sandata",
            "Sandovo",
            "Sandovskiy Rayon",
            "Sangar",
            "Sannikovo",
            "Sapernoye",
            "Sapërnyy",
            "Sapozhok",
            "Sarai",
            "Saraktash",
            "Saraktashskiy Rayon",
            "Sarana",
            "Saranpaul’",
            "Saransk",
            "Sarany",
            "Sarapul",
            "Sarapul’skiy Rayon",
            "Saratov",
            "Saratovskaya",
            "Saratovskiy Rayon",
            "Sargatskoye",
            "Sargazy",
            "Sarmakovo",
            "Sarmanovo",
            "Sarmanovskiy Rayon",
            "Sarov",
            "Sarpinskiy Rayon",
            "Sars",
            "Sary-Tyuz",
            "Saryg-Sep",
            "Saskylakh",
            "Sasovo",
            "Sasykoli",
            "Satarovsky",
            "Satinka",
            "Satis",
            "Satka",
            "Satkinskiy Rayon",
            "Savasleyka",
            "Savinka",
            "Savino",
            "Savinskaya",
            "Savinskiy Rayon",
            "Savvinskaya Sloboda",
            "Sayanogorsk",
            "Sayansk",
            "Sayanskiy Rayon",
            "Sazonovo",
            "Sebezh",
            "Sebezhskiy Rayon",
            "Sebrovo",
            "Sechenovo",
            "Sechenovskiy Rayon",
            "Sedel’nikovskiy Rayon",
            "Sedkyrkeshch",
            "Segezha",
            "Sel’tso",
            "Selemdzhinskiy Rayon",
            "Selenduma",
            "Selenginskiy Rayon",
            "Selezni",
            "Selezyan",
            "Selikhino",
            "Selishche",
            "Selizharovo",
            "Selizharovskiy Rayon",
            "Selty",
            "Selyatino",
            "Semënov",
            "Semënovskoye",
            "Semibratovo",
            "Semikarakorsk",
            "Semiletka",
            "Semiluki",
            "Semilukskiy Rayon",
            "Semiozerje",
            "Semkhoz",
            "Sengiley",
            "Sennoy",
            "Serafimovich",
            "Serafimovskiy",
            "Serdobsk",
            "Serebryano-Prudskiy Rayon",
            "Serebryanyy Bor",
            "Serebryanyye Prudy",
            "Seredeyskiy",
            "Serëdka",
            "Sergach",
            "Sergachskiy Rayon",
            "Sergeyevka",
            "Sergiyev Posad",
            "Sergiyevo-Posadskiy Rayon",
            "Sergokala",
            "Sergokalinskiy Rayon",
            "Sernovodsk",
            "Sernovodsk",
            "Sernur",
            "Sernurskiy Rayon",
            "Serov",
            "Serpukhov",
            "Serpukhovskiy Rayon",
            "Sertolovo",
            "Seryshevo",
            "Seryshevskiy Rayon",
            "Serzhen’-Yurt",
            "Seshcha",
            "Sestroretsk",
            "Setun’",
            "Severka",
            "Severka",
            "Severnoye",
            "Severnoye",
            "Severnoye",
            "Severnyy",
            "Severnyy",
            "Severnyy",
            "Severnyy Rayon",
            "Severnyy-Kospashskiy",
            "Severo-Baykal’skiy Rayon",
            "Severo-Kuril’sk",
            "Severo-Yeniseyskiy",
            "Severo-Yeniseyskiy Rayon",
            "Severo-Zadonsk",
            "Severobaykal’sk",
            "Severodvinsk",
            "Severomorsk",
            "Severomorsk-3",
            "Severomuysk",
            "Severoural’sk",
            "Seversk",
            "Severskaya",
            "Sevsk",
            "Sevskiy Rayon",
            "Seymchan",
            "Shaami-Yurt",
            "Shabalinskiy Rayon",
            "Shabel’skoye",
            "Shablykino",
            "Shabrovskiy",
            "Shaburnovo",
            "Shadrinsk",
            "Shafranovo",
            "Shagonar",
            "Shakhi",
            "Shakhovskaya",
            "Shakhovskoy Rayon",
            "Shakhta",
            "Shakhtersk",
            "Shakhty",
            "Shakhun’ya",
            "Shal’skiy",
            "Shalakusha",
            "Shalazhi",
            "Shali",
            "Shalinskiy Rayon",
            "Shalinskoye",
            "Shalushka",
            "Shalya",
            "Shamary",
            "Shamil’kala",
            "Shamil’skiy Rayon",
            "Shamkhal",
            "Shamkhal-Termen",
            "Shangaly",
            "Shar’inskiy Rayon",
            "Shar’ya",
            "Sharalday",
            "Sharan",
            "Sharanga",
            "Sharangskiy Rayon",
            "Sharkan",
            "Sharlyk",
            "Sharypovo",
            "Sharypovskiy Rayon",
            "Shatalovo",
            "Shatki",
            "Shatoy",
            "Shatrovo",
            "Shatsk",
            "Shatura",
            "Shaturtorf",
            "Shcheglovo",
            "Shchëkino",
            "Shchelkovo",
            "Shchëlkovskiy Rayon",
            "Shchelkun",
            "Shcherbinka",
            "Shchigry",
            "Shchukino",
            "Shebalino",
            "Shebalinskiy Rayon",
            "Shebekino",
            "Shebekinskiy Rayon",
            "Shebunino",
            "Shedok",
            "Sheksna",
            "Sheksninskiy Rayon",
            "Shelabolikha",
            "Shelabolikhinskiy Rayon",
            "Shëlkovskaya",
            "Shelkovskiy Rayon",
            "Shelopugino",
            "Sheltozero",
            "Shemëtovo",
            "Shemordan",
            "Shemursha",
            "Shemurshinskiy Rayon",
            "Shemysheyka",
            "Shenkursk",
            "Shenkurskiy Rayon",
            "Shentala",
            "Shepsi",
            "Sherbakul’",
            "Sheregesh",
            "Sheremet’yevskiy",
            "Sherkaly",
            "Shevlyakovo",
            "Shigony",
            "Shikhany",
            "Shikhazany",
            "Shikotan",
            "Shil’da",
            "Shilka",
            "Shilkinskiy Rayon",
            "Shilovo",
            "Shilovo",
            "Shimanovsk",
            "Shimanovskiy Rayon",
            "Shimorskoye",
            "Shimsk",
            "Shimskiy Rayon",
            "Shipitsyno",
            "Shipunovo",
            "Shipunovskiy Rayon",
            "Shira",
            "Shiringushi",
            "Shirochanka",
            "Shirokaya Rechka",
            "Shirokiy",
            "Shirokov",
            "Shirokovskiy",
            "Shishkin Les",
            "Shkotovo",
            "Shkotovskiy Rayon",
            "Shkurinskaya",
            "Shlissel’burg",
            "Sholokhovskiy",
            "Shonguy",
            "Shovgenovskiy",
            "Shovgenovskiy Rayon",
            "Shtykovo",
            "Shubenka",
            "Shudayag",
            "Shugurovo",
            "Shul’gin Log",
            "Shumerlinskiy Rayon",
            "Shumerlya",
            "Shumeyka",
            "Shumikha",
            "Shumikhinskiy",
            "Shumikhinskiy Rayon",
            "Shumyachi",
            "Shun’ga",
            "Shuryshkarskiy Rayon",
            "Shushary",
            "Shushenskiy Rayon",
            "Shushenskoye",
            "Shuvakish",
            "Shuvalovo",
            "Shuya",
            "Shuya",
            "Shuyskiy Rayon",
            "Shuyskoye",
            "Shvartsevskiy",
            "Sibay",
            "Sibirskiy",
            "Sigayevo",
            "Silikatnyy",
            "Sim",
            "Sindor",
            "Sinegor'ye",
            "Sinegorsk",
            "Sinegorskiy",
            "Singapay",
            "Sinodskoye",
            "Sinyavino",
            "Sinyavskoye",
            "Sista-Palkino",
            "Sitniki",
            "Siukh",
            "Siva",
            "Sivaki",
            "Sivakovka",
            "Siverskiy",
            "Sizyy Bugor",
            "Skhodnya",
            "Skolkovo",
            "Skopin",
            "Skorodnoye",
            "Skoropuskovskiy",
            "Skovorodino",
            "Skovorodinskiy Rayon",
            "Skuratovskiy",
            "Sladkovo",
            "Sladkovskiy Rayon",
            "Slantsevskiy Rayon",
            "Slantsy",
            "Slashchyovskaya",
            "Slavgorod",
            "Slavgorodskoye",
            "Slavniy",
            "Slavsk",
            "Slavskiy Rayon",
            "Slavyanka",
            "Slavyansk-na-Kubani",
            "Slavyansky rayon",
            "Sloboda",
            "Slobodka",
            "Slobodskoy",
            "Slobodskoy Rayon",
            "Smidovich",
            "Smidovichskiy Rayon",
            "Smirnykh",
            "Smolenka",
            "Smolensk",
            "Smolenskaya",
            "Smolenskiy Rayon",
            "Smolenskoye",
            "Smolino",
            "Smolyaninovo",
            "Smyshlyayevka",
            "Snegiri",
            "Snezhinsk",
            "Snezhnogorsk",
            "Snezhnogorsk",
            "Sobinka",
            "Sobinskiy Rayon",
            "Sobolevskiy Rayon",
            "Sochi",
            "Sofrino",
            "Sokol",
            "Sokol",
            "Sokol",
            "Sokol",
            "Sokol",
            "Sokol",
            "Sokol’niki",
            "Sokol’skiy Rayon",
            "Sokol’skoye",
            "Sokolovo",
            "Sokolovo-Kundryuchenskiy",
            "Sokolovskoye",
            "Sokolovyy",
            "Sokur",
            "Sol’-Iletsk",
            "Sol’tsy",
            "Sol’vychegodsk",
            "Soldato-Aleksandrovskoye",
            "Soldatskaya",
            "Soletskiy Rayon",
            "Solginskiy",
            "Soligalich",
            "Soligalichskiy Rayon",
            "Solikamsk",
            "Solnechniy",
            "Solnechnogorsk",
            "Solnechnogorskiy Rayon",
            "Solnechnoye",
            "Solnechnoye",
            "Solnechnyy",
            "Solnechnyy",
            "Solnechnyy",
            "Solntsevo",
            "Solntsevo",
            "Solntsevskiy Rayon",
            "Solomenskoye",
            "Soloneshenskiy Rayon",
            "Soloneshnoye",
            "Solotcha",
            "Solovjevsk",
            "Solton",
            "Soltonskiy Rayon",
            "Soluno-Dmitriyevskoye",
            "Solyanka",
            "Somovo",
            "Sonkovo",
            "Sorochinsk",
            "Sorokino",
            "Sorsk",
            "Sortaval’skiy Rayon",
            "Sortavala",
            "Sos’va",
            "Sosenskiy",
            "Soskovo",
            "Sosnogorsk",
            "Sosnovaya Polyana",
            "Sosnovka",
            "Sosnovka",
            "Sosnovka",
            "Sosnovka",
            "Sosnovka",
            "Sosnovo",
            "Sosnovo-Ozerskoye",
            "Sosnovoborsk",
            "Sosnovoborsk",
            "Sosnovskiy Rayon",
            "Sosnovskiy Rayon",
            "Sosnovskoye",
            "Sosnovyy Bor",
            "Sotnikovo",
            "Sotnikovskoye",
            "Souzga",
            "Sovetsk",
            "Sovetsk",
            "Sovetskaya",
            "Sovetskaya",
            "Sovetskaya",
            "Sovetskaya Gavan’",
            "Sovetskiy",
            "Sovetskiy",
            "Sovetskiy",
            "Sovetskiy",
            "Sovetskiy Rayon",
            "Sovetskiy Rayon",
            "Sovetskiy Rayon",
            "Sovetskoye",
            "Sovetskoye",
            "Sovetskoye",
            "Sovetsky District",
            "Sovkhoznyy",
            "Sovkhoznyy",
            "Sozimskiy",
            "Spas-Demensk",
            "Spas-Demenskiy Rayon",
            "Spas-Klepiki",
            "Spas-Zaulok",
            "Spassk",
            "Spassk",
            "Spassk-Dal’niy",
            "Spassk-Ryazanskiy",
            "Spasskiy Rayon",
            "Spasskiy Rayon",
            "Spasskiy Rayon",
            "Spasskoye",
            "Spasskoye",
            "Spasskoye-Lutovinovo",
            "Spiridonovka",
            "Spirovo",
            "Spirovskiy Rayon",
            "Spitsevka",
            "Spokoynaya",
            "Srednebelaya",
            "Srednekanskiy Rayon",
            "Srednekolymsk",
            "Srednekolymsky District",
            "Sredneuralsk",
            "Sredniy Ikorets",
            "Srednyaya Akhtuba",
            "Srednyaya Yelyuzan’",
            "Sretensk",
            "Sretenskiy Rayon",
            "Srostki",
            "Stan-Bekhtemir",
            "Stanovlyanskiy Rayon",
            "Stanovoye",
            "Stantsia Staritsa",
            "Stantsionno-Oyashinskiy",
            "Star’",
            "Staraya",
            "Staraya Akkermanovka",
            "Staraya Derevnya",
            "Staraya Kulatka",
            "Staraya Kupavna",
            "Staraya Ladoga",
            "Staraya Mayna",
            "Staraya Poltavka",
            "Staraya Russa",
            "Staraya Stanitsa",
            "Staraya Stanitsa",
            "Staraya Sunzha",
            "Staraya Toropa",
            "Staraya Vichuga",
            "Staritsa",
            "Staritskiy Rayon",
            "Staroaleyskoye",
            "Starobachaty",
            "Starobaltachevo",
            "Starobelokurikha",
            "Starocherkasskaya",
            "Staroderevyankovskaya",
            "Starodub",
            "Starodubskiy Rayon",
            "Starodubskoye",
            "Starodzhereliyevskaya",
            "Starokamyshinsk",
            "Starokorsunskaya",
            "Starokucherganovka",
            "Starokulatkinskiy Rayon",
            "Staroleushkovskaya",
            "Starominskaya",
            "Staromyshastovskaya",
            "Staronizhestebliyevskaya",
            "Staropavlovskaya",
            "Staropesterevo",
            "Staropyshminsk",
            "Starorusskiy Rayon",
            "Staroshaygovskiy Rayon",
            "Staroshcherbinovskaya",
            "Starosubkhangulovo",
            "Starotimoshkino",
            "Starotitarovskaya",
            "Staroutkinsk",
            "Starovelichkovskaya",
            "Staroye Arakchino",
            "Staroye Drozhzhanoye",
            "Staroyur’yevo",
            "Starozhilovo",
            "Staryy Cherek",
            "Staryy Malgobek",
            "Staryy Nadym",
            "Staryy Oskol",
            "Staryy Togul",
            "Staryy Urukh",
            "Staryye Atagi",
            "Staryye Ozinki",
            "Stavropol’",
            "Stavrovo",
            "Stepantsevo",
            "Stepnoye",
            "Stepnoye",
            "Sterlibashevo",
            "Sterlitamak",
            "Stodolishche",
            "Stolbishchi",
            "Stolbovaya",
            "Storozhevaya",
            "Strel'na",
            "Strelitsa",
            "Strelka",
            "Strelka",
            "Strezhevoy",
            "Strizhi",
            "Strogino",
            "Stroitel’",
            "Stromyn’",
            "Strugi-Krasnyye",
            "Strugo-Krasnenskiy Rayon",
            "Strunino",
            "Stukovo",
            "Stulovo",
            "Stupino",
            "Stupinskiy Rayon",
            "Subkhankulovo",
            "Suda",
            "Sudislavl’",
            "Sudislavskiy Rayon",
            "Sudogda",
            "Sudogodskiy Rayon",
            "Sudoverf’",
            "Sudzha",
            "Sukhaya Buyvola",
            "Sukhinichi",
            "Sukhinichskiy Rayon",
            "Sukhobezvodnoye",
            "Sukhobuzimskoye",
            "Sukhodol",
            "Sukhoy Log",
            "Sukko",
            "Sukkozero",
            "Sukpak",
            "Suksun",
            "Sulak",
            "Suleyman-Stal’skiy Rayon",
            "Sumkino",
            "Suna",
            "Sunskiy Rayon",
            "Suntar",
            "Suntarskiy District",
            "Sunzha",
            "Sunzha",
            "Sunzhenskiy Rayon",
            "Suoyarvi",
            "Suoyarvskiy Rayon",
            "Suponevo",
            "Sura",
            "Surazh",
            "Surazhskiy Rayon",
            "Surgut",
            "Surgut",
            "Surkhakhi",
            "Surok",
            "Surovatikha",
            "Surovikino",
            "Sursk",
            "Surskoye",
            "Susanino",
            "Suslonger",
            "Suslovo",
            "Susuman",
            "Sut-Khol’",
            "Suvorov",
            "Suvorovskaya",
            "Suvorovskiy Rayon",
            "Suzdal’",
            "Suzdal’skiy Rayon",
            "Suzëmka",
            "Suzemskiy Rayon",
            "Suzun",
            "Svatkovo",
            "Svecha",
            "Sven’",
            "Sverdlovskiy",
            "Svetlanovskiy",
            "Svetlaya",
            "Svetlogorsk",
            "Svetlogorsk",
            "Svetlograd",
            "Svetlopolyansk",
            "Svetly Yar",
            "Svetlyy",
            "Svetlyy",
            "Svetlyy",
            "Svetlyy",
            "Svetogorsk",
            "Sviblovo",
            "Sviyazhsk",
            "Svobodnenskiy Rayon",
            "Svobodnyy",
            "Svobodnyy",
            "Svobodnyy",
            "Svobody",
            "Syamzha",
            "Syamzhenskiy Rayon",
            "Syas’stroy",
            "Syava",
            "Sychëvka",
            "Sychëvka",
            "Sychëvo",
            "Syktyvkar",
            "Sylva",
            "Sylva",
            "Syntul",
            "Synya",
            "Syrostan",
            "Syrskoye",
            "Syrtych",
            "Sysert’",
            "Sysertskiy Rayon",
            "Syumsi",
            "Syzran’",
            "Tabaga",
            "Tabasaranskiy Rayon",
            "Tabory",
            "Tabuny",
            "Taganrog",
            "Taganskiy",
            "Takhtamukay",
            "Takhtamukayskiy Rayon",
            "Takhtamygda",
            "Taksimo",
            "Tal’menka",
            "Tal’menskiy Rayon",
            "Talakan",
            "Talashkino",
            "Talaya",
            "Taldan",
            "Taldom",
            "Taldomskiy Rayon",
            "Talitsa",
            "Talitsy",
            "Talnakh",
            "Talovaya",
            "Talovyy",
            "Tamala",
            "Taman’",
            "Tambov",
            "Tambovka",
            "Tambovka",
            "Tambovskiy Rayon",
            "Tanais",
            "Tankhoy",
            "Tanzybey",
            "Tara",
            "Tarasovka",
            "Tarasovskiy",
            "Tarbagatay",
            "Tarbagatay",
            "Taremskoye",
            "Tarkhovka",
            "Tarki",
            "Tarko-Sale",
            "Tarnogskiy Gorodok",
            "Tarnogskiy Rayon",
            "Tarskoye",
            "Tarumovka",
            "Tarumovskiy Rayon",
            "Tarusa",
            "Tarusskiy Rayon",
            "Taseyevo",
            "Tashanta",
            "Tashara",
            "Tashla",
            "Tashtagol",
            "Tashtagol’skiy Rayon",
            "Tashtyp",
            "Tatarka",
            "Tatarsk",
            "Tatarskaya Kargala",
            "Tatarskaya Pishlya",
            "Tatarskiy Rayon",
            "Tataurovo",
            "Tatishchevo",
            "Tatsinskiy",
            "Tavda",
            "Tavrichanka",
            "Tavricheskoye",
            "Tayga",
            "Tayginka",
            "Taymyrsky Dolgano-Nenetsky District",
            "Taytsy",
            "Tayzhina",
            "Tazovskiy",
            "Tbilisskaya",
            "Teeli",
            "Tegul’det",
            "Tegul’detskiy Rayon",
            "Tekstil’shchiki",
            "Temiraul",
            "Temirgoyevskaya",
            "Temirtau",
            "Temizhbekskaya",
            "Temizhbekskaya",
            "Tëmkino",
            "Temnikov",
            "Temnikovskiy Rayon",
            "Temruksky rayon",
            "Temryuk",
            "Temyasovo",
            "Ten’gushevo",
            "Ten’gushevskiy Rayon",
            "Tëplaya Gora",
            "Tëplo-Ogarëvskiy Rayon",
            "Tëploye",
            "Terbunskiy Rayon",
            "Terbuny",
            "Terek",
            "Terekli-Mekteb",
            "Teren’gul’skiy Rayon",
            "Tereze",
            "Teribërka",
            "Ternevskaya",
            "Terney",
            "Ternovka",
            "Ternovskiy Rayon",
            "Tersa",
            "Terskaya",
            "Terskiy Rayon",
            "Terskiy Rayon",
            "Terskol",
            "Teryayevo",
            "Tetyushi",
            "Tetyushskiy Rayon",
            "Teuchezhskiy Rayon",
            "Tevriz",
            "Teya",
            "Teykovo",
            "Teykovskiy Rayon",
            "Tigil’",
            "Tigil’skiy Rayon",
            "Tikhoretsk",
            "Tikhoretskiy Rayon",
            "Tikhvin",
            "Tikhvinskiy Rayon",
            "Tiksi",
            "Tilichiki",
            "Tim",
            "Timashëvsk",
            "Timiryazevskiy",
            "Timiryazevskoye",
            "Tinskoy",
            "Tirlyanskiy",
            "Tishchenskoye",
            "Tisul’",
            "Tlyarata",
            "Tlyaratinskiy Rayon",
            "Tlyustenkhabl’",
            "Tobol’sk",
            "Tochilkin",
            "Toguchin",
            "Togul",
            "Togul’skiy Rayon",
            "Togur",
            "Tokarevskiy Rayon",
            "Toksovo",
            "Tokur",
            "Tol’yatti",
            "Tolbazy",
            "Tolmachevo",
            "Tolmachëvo",
            "Tolstopal’tsevo",
            "Tolstoy-Yurt",
            "Tomari",
            "Tomarovka",
            "Tomilino",
            "Tommot",
            "Tomponskiy District",
            "Tomsk",
            "Tomskiy Rayon",
            "Tonkino",
            "Tonshalovo",
            "Tonshayevo",
            "Toora-Khem",
            "Topchikha",
            "Topki",
            "Topol’noye",
            "Topolëvo",
            "Torbeyevo",
            "Torfyanoy",
            "Toropets",
            "Toropetskiy Rayon",
            "Torzhok",
            "Torzhokskiy Rayon",
            "Tosnenskiy Rayon",
            "Tosno",
            "Tot’ma",
            "Totemskiy Rayon",
            "Totskoye",
            "Tovarkovo",
            "Tovarkovskiy",
            "Tpig",
            "Trëkhgornyy",
            "Tretiy Severnyy",
            "Troitsk",
            "Troitsk",
            "Troitsk",
            "Troitskaya",
            "Troitskaya",
            "Troitskiy",
            "Troitskiy",
            "Troitsko-Pechorsk",
            "Troitsko-Pechorskiy Rayon",
            "Troitskoye",
            "Troitskoye",
            "Troitskoye",
            "Troitskoye",
            "Troitskoye",
            "Troparëvo",
            "Trosna",
            "Trubchevsk",
            "Trubchevskiy Rayon",
            "Trudarmeyskiy",
            "Trudfront",
            "Trudobelikovskiy",
            "Trudovoye",
            "Trunovskoye",
            "Tsagan Aman",
            "Tsaritsyno",
            "Tselina",
            "Tselinnoye",
            "Tselinnoye",
            "Tsementnozavodskiy",
            "Tsementnyy",
            "Tsentoroy",
            "Tsentral’nyy",
            "Tsentral’nyy Administrativnyy Okrug",
            "Tsibanobalka",
            "Tsil’na",
            "Tsil’ninskiy Rayon",
            "Tsimlyansk",
            "Tsiolkovskiy",
            "Tsivil’sk",
            "Tsivil’skiy Rayon",
            "Tsotsin-Yurt",
            "Tsumadinskiy Rayon",
            "Tsuntinskiy Rayon",
            "Tsurib",
            "Tuapse",
            "Tuapsinskiy Rayon",
            "Tubinskiy",
            "Tuchkovo",
            "Tugolesskiy Bor",
            "Tugulym",
            "Tuim",
            "Tukan",
            "Tukayevskiy Rayon",
            "Tula",
            "Tuma",
            "Tumak",
            "Tumbotino",
            "Tungor",
            "Tunoshna",
            "Tura",
            "Turan",
            "Turgenevo",
            "Turgoyak",
            "Turinsk",
            "Turinskaya Sloboda",
            "Turka",
            "Turki",
            "Turochak",
            "Turtas",
            "Turukhansk",
            "Turuntayevo",
            "Tutayev",
            "Tutayevskiy Rayon",
            "Tuymazy",
            "Tuzha",
            "Tver",
            "Tyarlevo",
            "Tyazhinskiy",
            "Tygda",
            "Tymovskoye",
            "Tynda",
            "Tyndinskiy Rayon",
            "Tyoply Stan",
            "Tyrma",
            "Tyrnyauz",
            "Tyube",
            "Tyubuk",
            "Tyukalinsk",
            "Tyukhtet",
            "Tyul’gan",
            "Tyulyachi",
            "Tyulyachinskiy Rayon",
            "Tyumen",
            "Tyumentsevo",
            "Tyumentsevskiy Rayon",
            "Ubinskoye",
            "Uchaly",
            "Uchkeken",
            "Uchkent",
            "Uchkulan",
            "Udachny",
            "Udarnyy",
            "Udarnyy",
            "Udel’naya",
            "Udel’naya",
            "Udimskiy",
            "Udobnaya",
            "Udomlya",
            "Ufa",
            "Ufimskiy",
            "Uglegorsk",
            "Uglegorskiy",
            "Uglegorskiy Rayon",
            "Uglekamensk",
            "Uglerodovskiy",
            "Ugleural’skiy",
            "Uglezavodsk",
            "Uglich",
            "Uglichskiy Rayon",
            "Uglovka",
            "Uglovoye",
            "Uglovskoye",
            "Uglyanets",
            "Ugra",
            "Uinskoye",
            "Ukholovo",
            "Ukhta",
            "Ul’yanovka",
            "Ul’yanovskiy Rayon",
            "Ulan-Ude",
            "Uletovskiy Rayon",
            "Ulety",
            "Ulluaya",
            "Ullubiyaul",
            "Ulu-Telyak",
            "Ulukulevo",
            "Ulyanovsk",
            "Ulyanovskiy Rayon",
            "Umba",
            "Umet",
            "Undory",
            "Unecha",
            "Unechskiy Rayon",
            "Uni",
            "Uninskiy Rayon",
            "Untolovo",
            "Untsukul’",
            "Untsukul’skiy Rayon",
            "Upornaya",
            "Uporovo",
            "Uporovskiy Rayon",
            "Uptar",
            "Ural",
            "Uralets",
            "Uray",
            "Urazovka",
            "Urazovo",
            "Urdoma",
            "Uren’",
            "Urengoy",
            "Uritsk",
            "Urkarakh",
            "Urma",
            "Urman",
            "Urmarskiy Rayon",
            "Urmary",
            "Urozhaynoye",
            "Urshel’skiy",
            "Ursk",
            "Urukh",
            "Urus-Martan",
            "Urus-Martanovskiy Rayon",
            "Urusha",
            "Urussu",
            "Urvan’",
            "Urvanskiy Rayon",
            "Uryupinsk",
            "Uryv-Pokrovka",
            "Urzhum",
            "Ushumun",
            "Usinsk",
            "Usisha",
            "Usman’",
            "Usmanskiy Rayon",
            "Usogorsk",
            "Usol’ye",
            "Uspenka",
            "Uspenskaya",
            "Uspenskoye",
            "Ussuriysk",
            "Ust-Aldansky District",
            "Ust-Kuyga",
            "Ust-Maya",
            "Ust-Nera",
            "Ust-Tsilma",
            "Ust’-Abakan",
            "Ust’-Bagaryak",
            "Ust’-Barguzin",
            "Ust’-Bol’sheretskiy Rayon",
            "Ust’-Charyshskaya Pristan’",
            "Ust’-Donetskiy",
            "Ust’-Dzheguta",
            "Ust’-Isha",
            "Ust’-Ishim",
            "Ust’-Izhora",
            "Ust’-Kachka",
            "Ust’-Kalmanka",
            "Ust’-Kamchatsk Staryy",
            "Ust’-Kamchatskiy Rayon",
            "Ust’-Kan",
            "Ust’-Karsk",
            "Ust’-Katav",
            "Ust’-Kishert’",
            "Ust’-Koksa",
            "Ust’-Koksinskiy Rayon",
            "Ust’-Kubinskiy Rayon",
            "Ust’-Kulom",
            "Ust’-Labinsk",
            "Ust’-Luga",
            "Ust’-Omchug",
            "Ust’-Shonosha",
            "Ust’-Tarka",
            "Ust’-Tsilemskiy Rayon",
            "Ust’-Ulagan",
            "Ust’-Yanskiy Rayon",
            "Ust’yanka",
            "Ust’yanskiy Rayon",
            "Ust’ye",
            "Ustyuzhenskiy Rayon",
            "Ustyuzhna",
            "Usukhchay",
            "Usvyaty",
            "Usyatskoye",
            "Utamysh",
            "Utevka",
            "Utorgosh",
            "Utsmiyurt",
            "Uva",
            "Uvarovka",
            "Uvarovo",
            "Uvary",
            "Uvat",
            "Uvel’skiy",
            "Uvel’skiy Rayon",
            "Uyar",
            "Uyarskiy Rayon",
            "Uyemskiy",
            "Uyskiy Rayon",
            "Uyskoye",
            "Uzhur",
            "Uzlovaya",
            "Uzunovo",
            "Vacha",
            "Vachskiy Rayon",
            "Vad",
            "Vadinsk",
            "Vagay",
            "Vagayskiy Rayon",
            "Vagonoremont",
            "Vakhrushev",
            "Vakhrushevo",
            "Vakhrushi",
            "Vakhtan",
            "Valday",
            "Valdayskiy Rayon",
            "Valerianovsk",
            "Valerik",
            "Valuyki",
            "Valuyskiy Rayon",
            "Vanavara",
            "Vanino",
            "Vardane",
            "Varenikovskaya",
            "Vargashi",
            "Vargashinskiy Rayon",
            "Varlamovo",
            "Varna",
            "Varnavino",
            "Varnavinskiy Rayon",
            "Varnenskiy Rayon",
            "Vas’kovo",
            "Vasil’sursk",
            "Vasil’yevo",
            "Vasil’yevskiy Mokh",
            "Vasileostrovskiy Rayon",
            "Vaskelovo",
            "Vasyl'evsky Ostrov",
            "Vasyurinskaya",
            "Vatutinki",
            "Vatutino",
            "Vavozh",
            "Vazhiny",
            "Vedeno",
            "Vedenskiy Rayon",
            "Vel’sk",
            "Vel’skiy Rayon",
            "Velikent",
            "Velikiy Novgorod",
            "Velikiy Ustyug",
            "Velikiye Luki",
            "Velikodvorskiy",
            "Velikolukskiy Rayon",
            "Velikooktyabr’skiy",
            "Velikopetrovka",
            "Velikoustyugskiy Rayon",
            "Velikovechnoye",
            "Velizh",
            "Venëv",
            "Venevskiy Rayon",
            "Vengerovo",
            "Ventsy",
            "Verbilki",
            "Vereshchagino",
            "Vereya",
            "Verkh-Chebula",
            "Verkh-Katunskoye",
            "Verkh-Neyvinskiy",
            "Verkh-Suetka",
            "Verkh-Tula",
            "Verkh-Usugli",
            "Verkhnebakanskiy",
            "Verkhnechusovskiye Gorodki",
            "Verkhnedneprovskiy",
            "Verkhnekolymsky District",
            "Verkhnelandekhovskiy Rayon",
            "Verkhnetulomskiy",
            "Verkhneural’sk",
            "Verkhneuslonskiy Rayon",
            "Verkhnevilyuysk",
            "Verkhnevilyuysky District",
            "Verkhneyarkeyevo",
            "Verkhneye Dubrovo",
            "Verkhneye Kazanishche",
            "Verkhniy Avzyan",
            "Verkhniy Baskunchak",
            "Verkhniy Bekhtemir",
            "Verkhniy Fiagdon",
            "Verkhniy Kurkuzhin",
            "Verkhniy Landekh",
            "Verkhniy Lomov",
            "Verkhniy Mamon",
            "Verkhniy Tagil",
            "Verkhniy Ufaley",
            "Verkhniy Uslon",
            "Verkhniye Achaluki",
            "Verkhniye Kigi",
            "Verkhniye Osel’ki",
            "Verkhniye Sergi",
            "Verkhniye Tatyshly",
            "Verkhnyaya Balkariya",
            "Verkhnyaya Inta",
            "Verkhnyaya Khava",
            "Verkhnyaya Maksakovka",
            "Verkhnyaya Pyshma",
            "Verkhnyaya Salda",
            "Verkhnyaya Sinyachikha",
            "Verkhnyaya Sysert’",
            "Verkhnyaya Tishanka",
            "Verkhnyaya Toyma",
            "Verkhnyaya Tura",
            "Verkhoshizhem’ye",
            "Verkhotur’ye",
            "Verkhov’ye",
            "Verkhovazh’ye",
            "Verkhovazhskiy Rayon",
            "Verkhoyansk",
            "Verkhoyansky District",
            "Verkhozim",
            "Vershina Tei",
            "Vershino-Darasunskiy",
            "Vershino-Shakhtaminskiy",
            "Ves’yegonsk",
            "Ves’yegonskiy Rayon",
            "Veseloyarsk",
            "Vesëlyy",
            "Veshchevo",
            "Veshenskaya",
            "Veshkayma",
            "Veshnyaki",
            "Vetluga",
            "Vetluzhskiy",
            "Vetluzhskiy",
            "Vetluzhskiy Rayon",
            "Veydelevka",
            "Vichuga",
            "Vichugskiy Rayon",
            "Vidnoye",
            "Vidyayevo",
            "Vikulovo",
            "Vilenka",
            "Vilovatovo",
            "Vilya",
            "Vilyuchinsk",
            "Vilyuysk",
            "Vilyuyskiy Rayon",
            "Vilyuysky District",
            "Vinnitsy",
            "Vinogradnyy",
            "Vinogradovo",
            "Vinsady",
            "Vinzili",
            "Vishnëvogorsk",
            "Vishnyakovskiye Dachi",
            "Visim",
            "Vistino",
            "Vitim",
            "Vityazevo",
            "Vizinga",
            "Vkhodnoy",
            "Vladikavkaz",
            "Vladimir",
            "Vladimiro-Aleksandrovskoye",
            "Vladimirskaya",
            "Vladimirskoye",
            "Vladivostok",
            "Vlasikha",
            "Vniissok",
            "Vnukovo",
            "Vodnyy",
            "Vodstroy",
            "Vokhma",
            "Vokhomskiy Rayon",
            "Vokhtoga",
            "Vol’no-Nadezhdinskoye",
            "Vol’nyy Aul",
            "Vol’sk",
            "Vol’skiy Rayon",
            "Volchanets",
            "Volchansk",
            "Volchikha",
            "Volchikhinskiy Rayon",
            "Volga",
            "Volgo-Kaspiyskiy",
            "Volgodonsk",
            "Volgograd",
            "Volgorechensk",
            "Volkhov",
            "Volkhovskiy",
            "Volkhovskiy rayon",
            "Volochayevka Vtoraya",
            "Volochayevskoye",
            "Volodarsk",
            "Volodarskiy",
            "Volodarskogo",
            "Vologda",
            "Vologodskiy Rayon",
            "Volokolamsk",
            "Volokolamskiy Rayon",
            "Volokonovka",
            "Volokonovskiy Rayon",
            "Voloshka",
            "Volosovo",
            "Volosovskiy Rayon",
            "Volot",
            "Volotovskiy Rayon",
            "Volovo",
            "Volovo",
            "Volovskiy Rayon",
            "Volya",
            "Volzhsk",
            "Volzhskiy",
            "Volzhskiy Rayon",
            "Volzhsky",
            "Vorgashor",
            "Vorkuta",
            "Vorob’yevskiy Rayon",
            "Vorob’yovo",
            "Voronezh",
            "Vorontsovka",
            "Vorontsovka",
            "Voroshilovskiy District Volgograd",
            "Voroshnëvo",
            "Vorotynets",
            "Vorotynsk",
            "Vorsha",
            "Vorsma",
            "Voskresensk",
            "Voskresenskiy Rayon",
            "Voskresenskiy Rayon",
            "Voskresenskiy Rayon",
            "Voskresenskoye",
            "Voskresenskoye",
            "Voskresenskoye",
            "Vostochnoe Degunino",
            "Vostochny",
            "Vostochnyy",
            "Vostochnyy",
            "Vostochnyy",
            "Vostok",
            "Vostok",
            "Vostryakovo",
            "Votkinsk",
            "Voyskovitsy",
            "Voyvozh",
            "Vozdvizhenka",
            "Vozhega",
            "Voznesen’ye",
            "Voznesenskaya",
            "Voznesenskaya",
            "Voznesenskoye",
            "Vrangel’",
            "Vsevolozhsk",
            "Vsevolozhskiy Rayon",
            "Vuktyl",
            "Vurnarskiy Rayon",
            "Vurnary",
            "Vvedenskoye",
            "Vyartsilya",
            "Vyatskiye Polyany",
            "Vyaz’ma",
            "Vyazemskiy",
            "Vyazniki",
            "Vyazovaya",
            "Vybor",
            "Vyborg",
            "Vychegodskiy",
            "Vydrino",
            "Vydropuzhsk",
            "Vygonichi",
            "Vygonichskiy Rayon",
            "Vykhino-Zhulebino",
            "Vyksa",
            "Vypolzovo",
            "Vyritsa",
            "Vyselki",
            "Vyselkovskiy Rayon",
            "Vysha",
            "Vyshestebliyevskaya",
            "Vyshkov",
            "Vyshnevolotskiy Rayon",
            "Vyshneye Dolgoye",
            "Vyshniy Volochëk",
            "Vysokaya Gora",
            "Vysokinichi",
            "Vysokiy",
            "Vysokogornyy",
            "Vysokogorskiy Rayon",
            "Vysokovsk",
            "Vysokoye",
            "Vysotsk",
            "Vysotskoye",
            "Vytegorskiy Rayon",
            "Vytegra",
            "Vyyezdnoye",
            "Vzmorye",
            "Yablochnyy",
            "Yablonovskiy",
            "Yadrin",
            "Yadrinskiy Rayon",
            "Yagodnoye",
            "Yagry",
            "Yagunovo",
            "Yagunovskiy",
            "Yakhroma",
            "Yakovlevka",
            "Yakovlevo",
            "Yakovlevo",
            "Yakovlevskiy Rayon",
            "Yakovlevskiy Rayon",
            "Yakovlevskoye",
            "Yaksatovo",
            "Yakutsk",
            "Yal’chikskiy Rayon",
            "Yalkhoy-Mokhk",
            "Yalutorovsk",
            "Yam",
            "Yambirno",
            "Yamkino",
            "Yanaul",
            "Yanaul’skiy Rayon",
            "Yandyki",
            "Yanino-1",
            "Yanis’yarvi",
            "Yanishpole",
            "Yantarnyy",
            "Yantikovo",
            "Yantikovskiy Rayon",
            "Yar",
            "Yar-Sale",
            "Yaransk",
            "Yaranskiy Rayon",
            "Yarega",
            "Yarensk",
            "Yarkovo",
            "Yarkovo",
            "Yaropolets",
            "Yaroslavichi",
            "Yaroslavl",
            "Yaroslavskaya",
            "Yaroslavskiy",
            "Yaroslavskiy",
            "Yaroslavskiy Rayon",
            "Yarovoye",
            "Yartsevo",
            "Yasenevo",
            "Yasenskaya",
            "Yashalta",
            "Yashaltinskiy Rayon",
            "Yashkino",
            "Yashkul’",
            "Yasnaya Polyana",
            "Yasnogorsk",
            "Yasnogorskiy Rayon",
            "Yasnyy",
            "Yavas",
            "Yaya",
            "Yayva",
            "Yazykovo",
            "Yazykovo",
            "Yedrovo",
            "Yefimovskiy",
            "Yefremov",
            "Yegor’yevsk",
            "Yegor’yevskiy Rayon",
            "Yegorlykskaya",
            "Yekaterinburg",
            "Yekaterinoslavka",
            "Yekaterinovka",
            "Yekaterinovka",
            "Yekimovichi",
            "Yel’digino",
            "Yel’nikovskiy Rayon",
            "Yel’nya",
            "Yel’tsovskiy Rayon",
            "Yelabuga",
            "Yelabuzhskiy Rayon",
            "Yelan",
            "Yelan’-Koleno",
            "Yelan’-Kolenovskiy",
            "Yelanskiy",
            "Yelat’ma",
            "Yelets",
            "Yeletskiy Rayon",
            "Yelizavetinka",
            "Yelizavetino",
            "Yelizavetinskaya",
            "Yelizavetinskoye",
            "Yelizovo",
            "Yelizovskiy Rayon",
            "Yelkhovka",
            "Yëlkino",
            "Yelovo",
            "Yelshanka",
            "Yemanzhelinka",
            "Yemanzhelinsk",
            "Yemel’yanovo",
            "Yemel’yanovskiy Rayon",
            "Yemetsk",
            "Yemtsa",
            "Yemva",
            "Yeniseysk",
            "Yeniseyskiy Rayon",
            "Yenotayevka",
            "Yepifan’",
            "Yermakovskoye",
            "Yermekeyevo",
            "Yermish’",
            "Yermolayevo",
            "Yermolino",
            "Yermolino",
            "Yerofey Pavlovich",
            "Yershichi",
            "Yershov",
            "Yershovo",
            "Yertarskiy",
            "Yertsevo",
            "Yessentuki",
            "Yessentukskaya",
            "Yetkul’",
            "Yeysk",
            "Yeyskoye Ukrepleniye",
            "Yoshkar-Ola",
            "Ytyk-Kyuyël’",
            "Yubileyny",
            "Yubileynyy",
            "Yug",
            "Yuganets",
            "Yugo-Kamskiy",
            "Yugorsk",
            "Yukamenskoye",
            "Yukhnov",
            "Yukhnovskiy Rayon",
            "Yumaguzino",
            "Yur’yanskiy Rayon",
            "Yur’yev-Pol’skiy",
            "Yur’yev-Pol’skiy Rayon",
            "Yur’yevets",
            "Yur’yevetskiy Rayon",
            "Yurga",
            "Yurgamysh",
            "Yurginskiy Rayon",
            "Yurginskoye",
            "Yurino",
            "Yurinskiy Rayon",
            "Yurla",
            "Yurovka",
            "Yuryuzan’",
            "Yus’vinskiy Rayon",
            "Yushala",
            "Yushkozero",
            "Yutazinskiy Rayon",
            "Yutsa",
            "Yuzha",
            "Yuzhno-Kurilsk",
            "Yuzhno-Kurilsky District",
            "Yuzhno-Sakhalinsk",
            "Yuzhno-Sukhokumsk",
            "Yuzhnoural’sk",
            "Yuzhnyy",
            "Yuzhnyy",
            "Yuzhnyy",
            "Yuzhnyy-Kospashskiy",
            "Yuzhskiy Rayon",
            "Zabaykal’sk",
            "Zabolotovka",
            "Zadonsk",
            "Zadonskiy Rayon",
            "Zagor’ye",
            "Zagoryanskiy",
            "Zaigrayevo",
            "Zainsk",
            "Zainskiy Rayon",
            "Zakamensk",
            "Zakan-Yurt",
            "Zakharovo",
            "Zakharovskiy Rayon",
            "Zalegoshch’",
            "Zalesovo",
            "Zalukokoazhe",
            "Zamishevo",
            "Zamoskvorech’ye",
            "Zandak",
            "Zaokskiy",
            "Zaokskiy Rayon",
            "Zaozërsk",
            "Zapadnaya Dvina",
            "Zaplavnoye",
            "Zapolyarnyy",
            "Zapolyarnyy",
            "Zaprudnya",
            "Zaraysk",
            "Zarech’ye",
            "Zarech’ye",
            "Zarechnyy",
            "Zarechnyy",
            "Zarechnyy",
            "Zarechnyy",
            "Zarechnyy",
            "Zarinsk",
            "Zarinskiy Rayon",
            "Zarubino",
            "Zarubino",
            "Zarya",
            "Zarya",
            "Zasechnoye",
            "Zasosna",
            "Zaterechnyy",
            "Zaton",
            "Zav’yalovo",
            "Zav’yalovskiy Rayon",
            "Zav’yalovskiy Rayon",
            "Zavetnoye",
            "Zavetnoye",
            "Zavetnyy",
            "Zavety Il’icha",
            "Zavidovo",
            "Zavitinsk",
            "Zavitinskiy Rayon",
            "Zavodoukovsk",
            "Zavodouspenskoye",
            "Zavodskoy",
            "Zavolzh’ye",
            "Zavolzhsk",
            "Zavolzhskiy Rayon",
            "Zavoronezhskoye",
            "Zaykovo",
            "Zaymishche",
            "Zayukovo",
            "Zdvinsk",
            "Zelenchukskaya",
            "Zelenets",
            "Zelenoborsk",
            "Zelenoborskiy",
            "Zelenodol’skiy Rayon",
            "Zelenodolsk",
            "Zelenogorsk",
            "Zelenogorsk",
            "Zelenogorskiy",
            "Zelenograd",
            "Zelenogradsk",
            "Zelenogradskiy",
            "Zelenokumsk",
            "Zelënyy Bor",
            "Zelyony Gorod",
            "Zemetchino",
            "Zemlyansk",
            "Zenzeli",
            "Zernograd",
            "Zeya",
            "Zeyskiy Rayon",
            "Zhadovka",
            "Zhankhoteko",
            "Zharikovo",
            "Zharkovskiy",
            "Zhatay",
            "Zhavoronki",
            "Zhdankovskiy",
            "Zheleznodorozhnyy",
            "Zheleznodorozhnyy",
            "Zheleznodorozhnyy",
            "Zheleznogorsk",
            "Zheleznogorsk",
            "Zheleznovodsk",
            "Zhemchug",
            "Zhemtala",
            "Zherdevka",
            "Zheshart",
            "Zhigansk",
            "Zhigansky District",
            "Zhigulevsk",
            "Zhiguli",
            "Zhiletovo",
            "Zhilëvo",
            "Zhireken",
            "Zhirnov",
            "Zhirnovsk",
            "Zhiryatino",
            "Zhiryatinskiy Rayon",
            "Zhitnevo",
            "Zhizdra",
            "Zhizdrinskiy Rayon",
            "Zhukovka",
            "Zhukovka",
            "Zhukovo",
            "Zhukovskiy",
            "Zhukovskiy Rayon",
            "Zhukovskiy Rayon",
            "Zhulebino",
            "Zilair",
            "Zimovniki",
            "Zirgan",
            "Zlatoust",
            "Zlynka",
            "Zmeinogorsk",
            "Zmeyka",
            "Zmeyskaya",
            "Zmiyëvka",
            "Znamenka",
            "Znamenka",
            "Znamensk",
            "Znamensk",
            "Znamenskiy Rayon",
            "Znamenskoye",
            "Znamenskoye",
            "Znamenskoye",
            "Zol’noye",
            "Zol’skiy Rayon",
            "Zolotkovo",
            "Zolotukhino",
            "Zolotukhinskiy Rayon",
            "Zonal’noye",
            "Zubova Polyana",
            "Zubovo",
            "Zubovo-Polyanskiy Rayon",
            "Zubtsov",
            "Zubtsovskiy Rayon",
            "Zubutli-Miatli",
            "Zudilovo",
            "Zuyevka",
            "Zvenigorod",
            "Zvenigovo",
            "Zvenigovskiy Rayon",
            "Zverevo",
            "Zverinogolovskoye",
            "Zvëzdnyy",
            "Zyablikovo",
            "Zykovo",
            "Zykovo",
            "Zyryanka",
            "Zyryanskoye",
            "Zyukayka",
            "Zyuzel’skiy",
            "Zyuzino"
        ]
    },
    {
        "label": "Rwanda",
        "code": "RW",
        "phone": "250",
        "cities": [
            "Butare",
            "Byumba",
            "Cyangugu",
            "Eglise Catholique Centrale GIKO",
            "Gisenyi",
            "Gitarama",
            "Kibungo",
            "Kibuye",
            "Kigali",
            "Musanze",
            "Nzega",
            "Rwamagana"
        ]
    },
    {
        "label": "Saint Helena",
        "code": "SH",
        "phone": "290",
        "cities": []
    },
    {
        "label": "Saint Kitts And Nevis",
        "code": "KN",
        "phone": "+1-869",
        "cities": [
            "Cayon",
            "Charlestown",
            "Cotton Ground",
            "Dieppe Bay Town",
            "Fig Tree",
            "Market Shop",
            "Middle Island",
            "Monkey Hill",
            "Newcastle",
            "Nicola Town",
            "Saint Paul’s",
            "Sandy Point Town",
            "Trinity"
        ]
    },
    {
        "label": "Saint Lucia",
        "code": "LC",
        "phone": "+1-758",
        "cities": [
            "Active Hill",
            "Agard Lands/Morne Dudon",
            "Almondale",
            "Annus",
            "Anse Canot",
            "Anse Chastanet",
            "Anse Cochon",
            "Anse Galet",
            "Anse Ger",
            "Anse La Raye",
            "Anse La Verdue",
            "Athens",
            "Au Leon",
            "Au Tabor",
            "Au Tabor Hill",
            "Augier",
            "Aurendel Hill",
            "Babonneau Proper",
            "Bagatelle",
            "Balata",
            "Balca",
            "Balca/En Leur Ba",
            "Balembouche",
            "Banannes Bay",
            "Banse",
            "Banse La Grace",
            "Bara Bara",
            "Barnard Hill",
            "Barons Drive/Coin De L'Anse",
            "Barre Denis",
            "Barre Duchaussee",
            "Barre St.Joseph",
            "Bazile",
            "Beane Field",
            "Beasejour/Myers Bridge",
            "Beauchamp",
            "Beausejour",
            "Beausejour",
            "Beausejour/Fostin'S Development",
            "Beausejour/Ndc",
            "Belair",
            "Bella Rosa",
            "Bella Rosa",
            "Belle Plaine",
            "Belle Vue",
            "Belle Vue",
            "Belle Vue",
            "Belle Vue Estate",
            "Bellefond",
            "Belmont",
            "Belvedere",
            "Belvedere",
            "Bexon",
            "Bisee",
            "Bishop'S Gap",
            "Bissee",
            "Black Bay",
            "Black Mallet",
            "Blanchard",
            "Bocage",
            "Boguis",
            "Boguis/Desa Blond",
            "Bois Catchet",
            "Bois D'Inde",
            "Bois D'Inde",
            "Bois D'Orange",
            "Bois D'Ornange/Trouya",
            "Bois Dinde",
            "Bois Joli",
            "Bois Patat",
            "Bongalo",
            "Bonneterre",
            "Bonneterre Gardens",
            "Bordelais",
            "Bosquet D'Or",
            "Bouton",
            "Bruceville/Shanty Town",
            "Cabiche/Babonneau",
            "Cacoa",
            "Cacoa/Babonneau",
            "Caffiere",
            "Caico/Millet",
            "Calvary",
            "Calvary/Calvaire",
            "Canaries",
            "Canelles",
            "Cantonement",
            "Cap Estate",
            "Cap Estate/Becune Point",
            "Cap Estate/Caribbean Park",
            "Cap Estate/Golf Park",
            "Cap Estate/Lower Saline Point",
            "Cap Estate/Mon Du Cap",
            "Cap Estate/Ranch Site",
            "Cap Estate/Saddlec Back",
            "Cap Estate/Upper Saline Point",
            "Capital Hill",
            "Carellie",
            "Carierre",
            "Cas En Bas",
            "Castries",
            "Catin",
            "Caye Manje'",
            "Cazuca",
            "Cedars",
            "Cedars/Chu Tigre",
            "Cenac",
            "Chalon",
            "Champen Estate",
            "Chase Gardens",
            "Chassin/Babonneau",
            "Chateau Belair",
            "Chique/Blanchard",
            "Choiseul",
            "Choppin Ridge/Sarot",
            "Christian Hill",
            "Ciceron",
            "City",
            "City Gate/La Clery",
            "Cocoa Dan",
            "Colombette",
            "Compar",
            "Conway/Waterfront",
            "Coolie Town",
            "Coolie Town",
            "Coolie Town",
            "Corinth",
            "Corinth Estate",
            "Corinth/La Bel Lair",
            "Coubaril",
            "Cresslands",
            "Crownlands/Marc",
            "Cul De Sac",
            "Daban",
            "Dacretin",
            "Darling Road",
            "Dauphin",
            "De Mailly",
            "Debreuil",
            "Deglos",
            "Delaide",
            "Delcer",
            "Delomel",
            "Dennery",
            "Dennery By Pass",
            "Dennery By Pass/Green Mountain",
            "Dennery By Pass/Rocky Lane",
            "Dennery By Pass/White Rock Gardens",
            "Dennery Village",
            "Derierre Bois",
            "Derierre Fort/Old Victoria Road",
            "Derierre Morne",
            "Derniere Riviere",
            "Derniere Riviere/Fond Maricient",
            "Derniere Riviere/Mardi Gras/Morne Caca Cochon",
            "Derniere Riviere/Morne Panache",
            "Derriere Lagoon",
            "Derriere Morne",
            "Des Barras",
            "Des Barras/Cacolie",
            "Des Blanchard",
            "Despinoze",
            "Desrameaux",
            "Desruisseaux",
            "Deville",
            "Diamond/Diamond Estate",
            "Docamel/La Resource",
            "Dubonnaire",
            "Dubrassay",
            "Dugard",
            "Dugard",
            "Dupre",
            "Durandeau",
            "Eau Piquant/St Urbain",
            "En Bamboo",
            "En Pois Doux/Babonneau",
            "Enbar Pwin",
            "Entrepot",
            "Escap",
            "Esperance",
            "Esperance",
            "Esperance",
            "Etangs",
            "Faux A Chaud",
            "Ferrand",
            "Floissac/Marc",
            "Fond Assau/Babonneau",
            "Fond Berange",
            "Fond Bernier",
            "Fond Cacoa",
            "Fond Canie",
            "Fond Capeche",
            "Fond D'Lor/Dugard",
            "Fond Doux",
            "Fond Estate",
            "Fond Gens Libre",
            "Fond Sabot",
            "Fond/Desruisseaux",
            "Forestiere",
            "Franciou",
            "Gadette",
            "Garrand",
            "Gayabois",
            "Gentil",
            "George Charles Boulevard",
            "Getrine",
            "Girard",
            "Giraud",
            "Goergeville",
            "Gomier",
            "Goodlands",
            "Gouette",
            "Grace",
            "Grand Riviere",
            "Grande Ravine",
            "Grande Riviere",
            "Grande Riviere/Assou Canal",
            "Grande Riviere/Des Branch",
            "Grande Riviere/En Leur Morne/Discompere",
            "Grande Riviere/Funier",
            "Grande Riviere/Ingle Woods",
            "Grande Riviere/Morne Caca Cochon",
            "Grande Riviere/Morne Serpent",
            "Grande Riviere/Norbert",
            "Grande Riviere/Piat",
            "Grande Riviere/White Rock",
            "Grass Street",
            "Green Gold",
            "Gros Islet",
            "Gros Islet Town",
            "Gros Islet/Edge Water",
            "Guesneau",
            "H'Erelle",
            "Hewanorra Orchard",
            "Hill 20/Babonneau",
            "Hillcrest Gardens",
            "Honeymoon Beach",
            "Hope Estate",
            "Hospital Road",
            "Independence City",
            "Industrial Estate",
            "Industry",
            "Jacmel",
            "Jalousie",
            "Jean Baptiste",
            "John Compton Highway",
            "Joyeux",
            "Kennedy Highway/Laborie Bypass",
            "L'Anse Road",
            "L'Eau Mineau",
            "L'Hermitage",
            "La Carierre",
            "La Caye",
            "La Clery",
            "La Courville",
            "La Croix Chabourgh",
            "La Croix Maingot",
            "La Fargue",
            "La Guerre",
            "La Guerre/Chicken Back Street",
            "La Haut",
            "La Haut",
            "La Pansee",
            "La Pearle",
            "La Pelle",
            "La Perle",
            "La Pointe",
            "La Pointe",
            "La Pointe",
            "La Resource",
            "La Ressource",
            "La Retraite",
            "La Tille",
            "La Toc",
            "La Tourney/Cedar Heights",
            "La Trielle",
            "Labayee",
            "Laborie",
            "Lamaze",
            "Lastic Hill",
            "Le Riche",
            "Lenny Hill",
            "Leslie Land",
            "Lezy",
            "Lombard",
            "Londonderry",
            "Lumiere",
            "Macdomel",
            "Maganier",
            "Mahaut",
            "Mailly Motete",
            "Malgretoute",
            "Malgretoute",
            "Mamiku",
            "Marc",
            "Marchand",
            "Marigot",
            "Marisule",
            "Marisule/Bon Air",
            "Marisule/East Winds",
            "Marisule/La Brellotte",
            "Marisule/Top Of The World",
            "Marquis Estate",
            "Martin",
            "Massacre",
            "Massade",
            "Maynard Hill",
            "Miami/Bexon",
            "Micoud",
            "Migny",
            "Millet",
            "Mocha",
            "Mon Repos",
            "Monchy",
            "Monchy/Careffe",
            "Monchy/Cletus Village",
            "Monchy/La Borne",
            "Monchy/La Borne/Sans Souci",
            "Monchy/La Retraite",
            "Monchy/Lafeuillee",
            "Monchy/Lawi Fwen",
            "Monchy/Malgretoute",
            "Monchy/Moulin A Vent",
            "Monchy/Ravine Macock",
            "Monchy/Riviere Mitan",
            "Monchy/Ti Dauphin",
            "Monchy/Vieux Sucreic",
            "Monchy/Vieux Sucreic/Bois D'Inde",
            "Monchy/Vieux Sucreic/Careffe",
            "Mongiraud",
            "Mongouge",
            "Monier",
            "Monkey Town Ciceron",
            "Monzie",
            "Moreau",
            "Morne Assau/Babonneau",
            "Morne Bonin",
            "Morne Cayenne",
            "Morne Ciseaux",
            "Morne Citon",
            "Morne D'Or",
            "Morne Dudon",
            "Morne Gomier",
            "Morne Jacques",
            "Morne La Croix",
            "Morne Lastic/Desruisseaux",
            "Morne Le Blanc",
            "Morne Panache",
            "Morne Road",
            "Morne Rouge/Marc",
            "Morne Sion",
            "Morne Vert",
            "Morne Vient",
            "Motete",
            "Moule A Chique",
            "Mount Pleasant",
            "Myette Gardens",
            "New Development",
            "New Field/Fiette",
            "New Village",
            "New Village",
            "Obrier",
            "Odsan",
            "Olibo",
            "Paix Bouche",
            "Paix Bouche",
            "Palmiste",
            "Palmiste Estate",
            "Parc Estate",
            "Parker'S Hill",
            "Patience",
            "Patterson'S Gap",
            "Pavee",
            "Peart'S Gap",
            "Perou",
            "Piaye",
            "Pierrot",
            "Pigeon Island",
            "Planard",
            "Plat Pays",
            "Plateau",
            "Plut",
            "Pointe Seraphine",
            "Pomme",
            "Ponyon",
            "Praslin",
            "Quarte Chemins",
            "Rabot",
            "Rameau",
            "Raveneau",
            "Ravine Chabot",
            "Ravine Claire",
            "Ravine Poisson",
            "Ravine Touterelle",
            "Reduit",
            "Reduit Orchard",
            "Reduit Park",
            "Resinard/Babonneau",
            "Reunion",
            "Riche Fond",
            "Riche Fond/La Belle Vie",
            "Riche Fond/New Village",
            "River Doree",
            "Riverside Road",
            "Roblot",
            "Rock Hall",
            "Rodney Bay",
            "Rodney Heights",
            "Rose Hill",
            "Roseau Valley",
            "Ruby Estate",
            "Saint Marie Road",
            "Saltibus",
            "San Soucis",
            "Sand De Feu",
            "Saphire",
            "Sarot",
            "Saut",
            "Sauzay",
            "Savannes",
            "Savannesgeorge/Constitution",
            "Soufrière",
            "Soufriere Estate",
            "St Helen Estate",
            "St Jude'S Highway",
            "St Lawrence",
            "St Lawrence Estate",
            "St Phillip",
            "St. Joseph Estate",
            "Stonefield",
            "Sulphur Springs",
            "Summersdale",
            "Sunbilt",
            "Sunny Acres",
            "Talvern",
            "Tapion",
            "Tete Chemin",
            "Tete Morne",
            "Tete Morne/Morne Andrew",
            "Thamazo",
            "The Morne",
            "Theodrine",
            "Ti Boug",
            "Ti Colon",
            "Ti Delcer",
            "Ti Riviere",
            "Ti Rocher",
            "Ti Rocher",
            "Toraille",
            "Town",
            "Town",
            "Trois Piton",
            "Trou Cochan/Marc",
            "Trou Florent/Marc",
            "Trou Rouge",
            "Troumassee",
            "Union",
            "Union Terrace",
            "Union Vale",
            "Union/Ti Morne",
            "Vanard",
            "Venus",
            "Victoria",
            "Vide Bouteille",
            "Vieux Fort",
            "Vieux Fort/Laborie Highway",
            "Vige'",
            "Vigie",
            "Village",
            "Village",
            "Village",
            "Village",
            "Village",
            "Village/Petite Bourgh",
            "Volet",
            "Warwick/Daban",
            "Waterworks",
            "Westall Group/The Mangue",
            "Wilton'S Yard/Grave Yard",
            "Yorke Hill",
            "Zenon"
        ]
    },
    {
        "label": "Saint Pierre and Miquelon",
        "code": "PM",
        "phone": "508",
        "cities": []
    },
    {
        "label": "Saint Vincent And The Grenadines",
        "code": "VC",
        "phone": "+1-784",
        "cities": [
            "Barrouallie",
            "Biabou",
            "Byera Village",
            "Chateaubelair",
            "Georgetown",
            "Kingstown",
            "Kingstown Park",
            "Layou",
            "Port Elizabeth"
        ]
    },
    {
        "label": "Saint-Barthelemy",
        "code": "BL",
        "phone": "590",
        "cities": []
    },
    {
        "label": "Saint-Martin (French part)",
        "code": "MF",
        "phone": "590",
        "cities": []
    },
    {
        "label": "Samoa",
        "code": "WS",
        "phone": "685",
        "cities": [
            "Afega",
            "Apia",
            "Asau",
            "Falefa",
            "Fasito‘outa",
            "Gataivai",
            "Leulumoega",
            "Lotofagā",
            "Lufilufi",
            "Malie",
            "Matavai",
            "Mulifanua",
            "Nofoali‘i",
            "Safotu",
            "Samamea",
            "Satapuala",
            "Solosolo",
            "Vailima",
            "Vailoa",
            "Vaiusu"
        ]
    },
    {
        "label": "San Marino",
        "code": "SM",
        "phone": "378",
        "cities": [
            "Acquaviva",
            "Borgo Maggiore",
            "Domagnano",
            "Faetano",
            "Fiorentino",
            "Monte Giardino",
            "Poggio di Chiesanuova",
            "San Marino",
            "Serravalle"
        ]
    },
    {
        "label": "Sao Tome and Principe",
        "code": "ST",
        "phone": "239",
        "cities": [
            "Cantagalo District",
            "Caué District",
            "Santo António",
            "São Tomé",
            "Trindade"
        ]
    },
    {
        "label": "Saudi Arabia",
        "code": "SA",
        "phone": "966",
        "cities": [
            "`Ajmiyah",
            "`Alya'",
            "`Ushash",
            "`Ushayrah",
            "Abha",
            "Abqaiq",
            "Abu `Urwah",
            "Abū ‘Arīsh",
            "Abu Hisani",
            "Abu Qirfah",
            "Abu Radif",
            "Abū Shayţānah",
            "Abu Shu`ayb",
            "Abyar 'Ali",
            "Ad Dabbah",
            "Ad Darb",
            "Ad Dawādimī",
            "Ad Dawh",
            "Ad Dilam",
            "Ad Dulu`",
            "Ad Dur",
            "Ad Dur`iyah",
            "Adh Dhagharir",
            "Adh Dhibiyah",
            "Afif",
            "Ain AlBaraha",
            "Al `Awali",
            "Al `Ulayin",
            "Al `Uqul",
            "Al `Usaylah",
            "Al Ābār",
            "Al Adl",
            "Al Akhal",
            "Al Arţāwīyah",
            "Al Ashraf",
            "Al Awjām",
            "Al Badawi",
            "Al Bahah",
            "Al Balad",
            "Al Barabir",
            "Al Bardiyah",
            "Al Baţţālīyah",
            "Al Bi'ar",
            "Al Biqa'",
            "Al Bir",
            "Al Birk",
            "Al Bukayrīyah",
            "Al Buraykah",
            "Al Bustan",
            "Al Faqirah",
            "Al Fawwarah",
            "Al Faydah",
            "Al Fazz",
            "Al Furaysh",
            "Al Fuwayliq",
            "Al Gharith",
            "Al Ghassalah",
            "Al Ghulah",
            "Al Hadā",
            "Al Hadrur",
            "Al Hair",
            "Al Halaqah",
            "Al Hamimah",
            "Al Hanashah",
            "Al Harani",
            "Al Harra' Makkah",
            "Al Hasamah",
            "Al Hawiyah",
            "Al Hijfar",
            "Al Hufūf",
            "Al Isawiyah",
            "Al Iskan",
            "Al Jabriyah",
            "Al Jadi",
            "Al Jadidah",
            "Al Jafr",
            "Al Jami`ah",
            "Al Jarādīyah",
            "Al Jawah",
            "Al Jid`",
            "Al Jirbah",
            "Al Jissah",
            "Al Ju`ranah",
            "Al Jubayl",
            "Al Jumūm",
            "Al Jurayfah",
            "Al Karbus",
            "Al Kawahilah",
            "Al Khadra' Jizan",
            "Al Khadra' Makkah",
            "Al Khafjī",
            "Al Khalas",
            "Al Khamrah",
            "Al Kharabah Jizan",
            "Al Kharadilah",
            "Al Kharj",
            "Al Kharma'",
            "Al Khashabiyah",
            "Al Khaydar",
            "Al Khayf",
            "Al Khubah",
            "Al Khulasah",
            "Al Kidwah",
            "Al Kirs",
            "Al Kura`",
            "Al Luqiyah",
            "Al Ma`ayin",
            "Al Ma`rash",
            "Al Madaya",
            "Al Madiq Makkah",
            "Al Maghal",
            "Al Mahjar",
            "Al Majāridah",
            "Al Malbanah",
            "Al Mali",
            "Al Maqrah",
            "Al Markaz",
            "Al Masarrah",
            "Al Masfalah",
            "Al Mashayikh",
            "Al Mathnah",
            "Al Mayasam",
            "Al Mindak",
            "Al Mithnab",
            "Al Mubarak",
            "Al Mubarraz",
            "Al Mudawwarah",
            "Al Mufrihat",
            "Al Mulayha'",
            "Al Multasa",
            "Al Munayzilah",
            "Al Mundassah",
            "Al Muqayti`",
            "Al Muqr",
            "Al Musayjid",
            "Al Muţayrifī",
            "Al Muwayh",
            "Al Qa'im",
            "Al Qadimah",
            "Al Qahab",
            "Al Qārah",
            "Al Qararah",
            "Al Qaryat",
            "Al Qaţīf",
            "Al Qawba`iyah",
            "Al Qirshan",
            "Al Qu`tubah",
            "Al Qufayf",
            "Al Quful",
            "Al Qurayb",
            "Al Qurayn",
            "Al Qushashiyah",
            "Al Quwah",
            "Al Thybiyah",
            "Al Ubaylah",
            "Al Ukhaydir",
            "Al Waht",
            "Al Wajh",
            "Al Wasili",
            "Al Wuday",
            "Al-Awamiyah",
            "Al-Awjam",
            "Al-Haditha",
            "Al-Jafr",
            "Al-Mubarraz",
            "Al-Ula",
            "An Najamiyah",
            "Ar Rabwah as Sufla",
            "Ar Rafah",
            "Ar Rass",
            "Ar Rawdah ash Shamaliyah",
            "Ar Rayn",
            "Ar Rudaymah",
            "Ar Rukubah",
            "Arar",
            "Arya`",
            "As Sadayir",
            "As Sadr",
            "As Saffānīyah",
            "As Safra'",
            "As Salamiyah",
            "As Samd ash Shamali",
            "As Sayl al Kabir",
            "As Sayl as Saghir",
            "As Sifyani",
            "As Sudayrah Makkah",
            "As Sulayyil",
            "As Sumariyah",
            "As Suwadah",
            "As Suwayriqiyah",
            "Ash Shafā",
            "Ash Shajwah",
            "Ash Shamiyah",
            "Ash Shara'i`",
            "Ash Shaybi",
            "Ash Shi`b",
            "Ash Shishah",
            "Ash Shufayyah",
            "Ash Shumaysi",
            "Ash Shuqayq",
            "Ash Shuwaybit",
            "Asira",
            "At Tan`im",
            "Aţ Ţaraf",
            "At Tarfa'",
            "At Tūbī",
            "At Turqi",
            "Az Zaymah",
            "Az Zilal",
            "Az Zughbah",
            "Az Zulfī",
            "Az Zurra`",
            "Az Zuwayb",
            "Bahrat al Qadimah",
            "Bahwil",
            "Bakhshat Yamani",
            "Baq`a'",
            "Baranah",
            "Bartiyah",
            "Barzah",
            "Bashm",
            "Bi'r al Mashi",
            "Birkah",
            "Buraidah",
            "Buraydah",
            "Buraykah",
            "Burayman",
            "CITY GHRAN",
            "Dabyah",
            "Dahaban",
            "Dammam",
            "Dawadmi",
            "Dhahran",
            "Diriyah",
            "Duba",
            "Dughaybjah",
            "Dukhnah",
            "Far`",
            "Farasān",
            "Fayd",
            "Fiji",
            "Ghawiyah",
            "Ghran",
            "Ha'il ",
            "Hadda'",
            "Haddat ash Sham",
            "Hadhah",
            "Hafar Al-Batin",
            "Hajrah",
            "Hajur",
            "Halamah",
            "Halat Ammar",
            "Hamayyah",
            "Hamdah",
            "Haradh",
            "Harmah",
            "Harthiyah",
            "Haylat Radi al Baham",
            "Husayniyah",
            "Husnah",
            "Jadidah",
            "Jalajil",
            "Jarwal",
            "Jeddah",
            "Jizan",
            "Jubbah",
            "Juha Saudi Arabia",
            "Julayjilah",
            "Julayyil",
            "Ka`lul",
            "Khabath Sa`id",
            "Khalfah",
            "Khamis Mushait",
            "Khatib Saudi Arabia",
            "Khayf Fadil",
            "Khobar",
            "Khumrah",
            "Khumsiyah",
            "Khushaym",
            "Kulakh",
            "Layla",
            "Ma`riyah",
            "Madrakah",
            "Madsus",
            "Mafruq",
            "Mahatah",
            "Mahattat al Hafah",
            "Malakan",
            "Malgocta",
            "Manfuha",
            "Maqrah",
            "Maqshush",
            "Marāt",
            "Masahili",
            "Mashajji",
            "Masihat Mahd al Hayl",
            "Maskar",
            "Matiyah",
            "Mawarah",
            "Mawqaq",
            "Mecca",
            "Medina",
            "Mifa",
            "Milhah",
            "Mina",
            "Mislīyah",
            "Mizhirah",
            "Mukambal",
            "Mulayjah",
            "Munayzir",
            "Mundaraq",
            "Murshidiyah",
            "Mushrif",
            "Muwassam",
            "Najan",
            "Najrān",
            "Nariyah",
            "Nisab",
            "Nughayshiyah",
            "Nujayl",
            "Nuzlat al Faqin",
            "Qaba'",
            "Qaisumah",
            "Qiba",
            "Qitabir",
            "Qiya",
            "Qufar",
            "Qurayyat",
            "Quwayda'",
            "Quwayzah",
            "Rābigh",
            "Rabwah Ghran",
            "Raḩīmah",
            "Rahwan",
            "Raqiyah",
            "Rawkhah",
            "Rayyis",
            "Riyadh",
            "Sabuhah",
            "Şabyā",
            "Sadiliyah",
            "Şafwá",
            "Sājir",
            "Sakakah",
            "Salamah",
            "Şāmitah",
            "Sayhāt",
            "Sha`tha'",
            "Shi`b `amir",
            "Shira`ayn",
            "shokhaibٍ",
            "Sidi Hamzah",
            "Simira",
            "Sulaym",
            "Sumaymah",
            "Suq Suwayq",
            "Suqubiya",
            "Suwadah",
            "Şuwayr",
            "Suways",
            "Ta'if",
            "Tabālah",
            "Tabarjal",
            "Tabuk",
            "Tanūmah",
            "Tārūt",
            "Tharwah",
            "Thuwal",
            "Ţubarjal",
            "Tumayr",
            "Turabah",
            "Turaif",
            "Udhailiyah",
            "Umm as Sāhik",
            "Umm Lajj",
            "Umm Radamah",
            "Uqair",
            "Usfan",
            "Wadi al Jalil",
            "Wasitah",
            "Wed Alnkil",
            "Yanbu"
        ]
    },
    {
        "label": "Senegal",
        "code": "SN",
        "phone": "221",
        "cities": [
            "Adéane",
            "Bignona",
            "Dakar",
            "Dakar Department",
            "Dara",
            "Département de Salémata",
            "Diawara",
            "Diofior",
            "Fatick Department",
            "Foundiougne",
            "Gandiaye",
            "Goléré",
            "Goudomp Department",
            "Guédiawaye Department",
            "Guéoul",
            "Guinguinéo",
            "Joal-Fadiout",
            "Kaffrine",
            "Kanel",
            "Kaolack",
            "Kayar",
            "Kédougou",
            "Kédougou Department",
            "Khombole",
            "Kolda",
            "Kolda Department",
            "Koungheul",
            "Linguere Department",
            "Louga",
            "Marsassoum",
            "Matam",
            "Matam Department",
            "Mbacké",
            "Mbaké",
            "Mbour",
            "Mékhé",
            "Mermoz Boabab",
            "N’diareme limamoulaye",
            "Ndibène Dahra",
            "Ndioum",
            "Ndofane",
            "Nguékhokh",
            "Nioro du Rip",
            "Ouro Sogui",
            "Oussouye",
            "Passi",
            "Pikine",
            "Pikine Department",
            "Polel Diaoubé",
            "Pourham",
            "Pout",
            "Ranérou",
            "Richard-Toll",
            "Rosso",
            "Rufisque Department",
            "Saint-Louis",
            "Saraya",
            "Sédhiou",
            "Sémé",
            "Sokone",
            "Tambacounda",
            "Tambacounda Department",
            "Thiès",
            "Thiès Nones",
            "Tiadiaye",
            "Tiébo",
            "Tionk Essil",
            "Tivaouane",
            "Touba",
            "Vélingara",
            "Waoundé",
            "Warang",
            "Ziguinchor"
        ]
    },
    {
        "label": "Serbia",
        "code": "RS",
        "phone": "381",
        "cities": [
            "Adorjan",
            "Aleksandrovo",
            "Alibunar",
            "Apatin",
            "Aradac",
            "Aranđelovac",
            "Arilje",
            "Bačka Palanka",
            "Bačka Topola",
            "Bački Breg",
            "Bački Petrovac",
            "Bačko Gradište",
            "Bačko Petrovo Selo",
            "Badovinci",
            "Bajina Bašta",
            "Banatska Topola",
            "Banatski Despotovac",
            "Banatski Dvor",
            "Banatski Karlovac",
            "Banatsko Karađorđevo",
            "Banatsko Veliko Selo",
            "Banovo Polje",
            "Baranda",
            "Barič",
            "Barice",
            "Bašaid",
            "Bečej",
            "Bečmen",
            "Bela Crkva",
            "Belegiš",
            "Belgrade",
            "Belo Blato",
            "Belotić",
            "Beška",
            "Biljača",
            "Bočar",
            "Bogatić",
            "Bogojevo",
            "Bogosavac",
            "Boka",
            "Boljevci",
            "Bor",
            "Bosut",
            "Botoš",
            "Brdarica",
            "Buđanovci",
            "Bukor",
            "Čačak",
            "Čajetina",
            "Čelarevo",
            "Čenta",
            "Čestereg",
            "Ćićevac",
            "Čoka",
            "Čokešina",
            "Čortanovci",
            "Crepaja",
            "Crna Bara",
            "Ćuprija",
            "Čurug",
            "Debeljača",
            "Despotovo",
            "Dobanovci",
            "Dobrić",
            "Dobrica",
            "Donji Dobrić",
            "Donji Milanovac",
            "Doroslovo",
            "Draginje",
            "Drenovac",
            "Dublje",
            "Duboka",
            "Ðurđevo",
            "Ðurići",
            "Ečka",
            "Elemir",
            "Farkaždin",
            "Gakovo",
            "Gardinovci",
            "Gložan",
            "Glušci",
            "Golubac",
            "Golubinci",
            "Gornja Bukovica",
            "Gornji Breg",
            "Gornji Milanovac",
            "Grabovac",
            "Grabovci",
            "Gudurica",
            "Hajdučica",
            "Hetin",
            "Hrtkovci",
            "Idvor",
            "Ilandža",
            "Inđija",
            "Irig",
            "Izbište",
            "Jablanica",
            "Jadranska Lešnica",
            "Jagodina",
            "Janošik",
            "Jarak",
            "Jarebice",
            "Jarkovac",
            "Jaša Tomić",
            "Jazovo",
            "Jelenča",
            "Jermenovci",
            "Jevremovac",
            "Joševa",
            "Kamenica",
            "Kanjiža",
            "Kikinda",
            "Kisač",
            "Klek",
            "Klenak",
            "Klenje",
            "Knićanin",
            "Knjazevac",
            "Kolubara",
            "Kolut",
            "Konak",
            "Kovačica",
            "Kovilj",
            "Kovin",
            "Kozjak",
            "Kozjak",
            "Kragujevac",
            "Krajišnik",
            "Kraljevo",
            "Krčedin",
            "Krivaja",
            "Krupanj",
            "Kruševac",
            "Kulpin",
            "Kumane",
            "Kupinovo",
            "Kupusina",
            "Kuštilj",
            "Lagja e Poshtme",
            "Lapovo",
            "Lazarevac",
            "Lazarevo",
            "Leskovac",
            "Lešnica",
            "Lipnički Šor",
            "Lipolist",
            "Ljig",
            "Ljubovija",
            "Ljukovo",
            "Lok",
            "Lokve",
            "Lugavčina",
            "Lukićevo",
            "Mačva",
            "Mačvanska Mitrovica",
            "Maglić",
            "Majdanpek",
            "Majur",
            "Mala Moštanica",
            "Mali Zvornik",
            "Margita",
            "Međa",
            "Melenci",
            "Metković",
            "Mihajlovo",
            "Miratovac",
            "Mokrin",
            "Mol",
            "Morava",
            "Mošorin",
            "Nakovo",
            "Negotin",
            "Neuzina",
            "Nikinci",
            "Nikolinci",
            "Niš",
            "Nišava",
            "Nova Crnja",
            "Nova Pazova",
            "Novi Banovci",
            "Novi Itebej",
            "Novi Karlovci",
            "Novi Kneževac",
            "Novi Kozarci",
            "Novi Pazar",
            "Novi Sad",
            "Novi Slankamen",
            "Novo Miloševo",
            "Novo Selo",
            "Obrenovac",
            "Obrovac",
            "Opovo",
            "Orlovat",
            "Osečina",
            "Osječenik",
            "Ostojićevo",
            "Ostružnica",
            "Ovča",
            "Padej",
            "Padina",
            "Pančevo",
            "Paraćin",
            "Pavliš",
            "Pčinja",
            "Pećinci",
            "Perlez",
            "Petkovica",
            "Petrovaradin",
            "Pirot",
            "Plandište",
            "Platičevo",
            "Pocerski Pričinović",
            "Podunavlje District",
            "Pomoravlje",
            "Požarevac",
            "Priboj",
            "Prigrevica",
            "Prijepolje",
            "Prislonica",
            "Prnjavor",
            "Prokuplje",
            "Putinci",
            "Radenka",
            "Radenković",
            "Radojevo",
            "Radovnica",
            "Rajince",
            "Rasina",
            "Raška",
            "Ravni Topolovac",
            "Ravnje",
            "Ravno Selo",
            "Ribari",
            "Riđica",
            "Ruma",
            "Rumenka",
            "Rumska",
            "Rušanj",
            "Rusko Selo",
            "Šabac",
            "Sajan",
            "Šajkaš",
            "Sakule",
            "Salaš Crnobarski",
            "Salaš Noćajski",
            "Samoljica",
            "Samoš",
            "Sanad",
            "Šašinci",
            "Sečanj",
            "Sefkerin",
            "Seleuš",
            "Senta",
            "Ševarice",
            "Ševica",
            "Šimanovci",
            "Sinošević",
            "Sjenica",
            "Smederevo",
            "Smederevska Palanka",
            "Sokolovica",
            "Sokolovo Brdo",
            "Sombor",
            "Sonta",
            "Sremčica",
            "Sremska Mitrovica",
            "Sremski Karlovci",
            "Srpska Crnja",
            "Srpski Itebej",
            "Stajićevo",
            "Stanišić",
            "Stara Pazova",
            "Stari Banovci",
            "Stari Lec",
            "Stepanovićevo",
            "Stepojevac",
            "Štitar",
            "Stubline",
            "Subotica",
            "Šumadija",
            "Sumulicë",
            "Surčin",
            "Surduk",
            "Sutjeska",
            "Tabanović",
            "Taraš",
            "Tiszahegyes",
            "Titel",
            "Toba",
            "Tomaševac",
            "Toplica",
            "Torak",
            "Torda",
            "Tršić",
            "Trstenik",
            "Turija",
            "Tutin",
            "Ugrinovci",
            "Uljma",
            "Umka",
            "Uzdin",
            "Užice",
            "Uzveće",
            "Valjevo",
            "Varna",
            "Velika Greda",
            "Velika Moštanica",
            "Veliki Gaj",
            "Veliko Središte",
            "Veternik",
            "Vilovo",
            "Višnjićevo",
            "Vladimirci",
            "Vladimirovac",
            "Vlajkovac",
            "Vojka",
            "Vojvoda Stepa",
            "Vranić",
            "Vranje",
            "Vrbas",
            "Vrdnik",
            "Vrnjačka Banja",
            "Vršac",
            "Žabalj",
            "Zaječar",
            "Zemun",
            "Žitište",
            "Zlatibor",
            "Zmajevo",
            "Zminjak",
            "Zrenjanin",
            "Žujince",
            "Zvečka"
        ]
    },
    {
        "label": "Seychelles",
        "code": "SC",
        "phone": "248",
        "cities": [
            "Anse Boileau",
            "Anse Royale",
            "Beau Vallon",
            "Bel Ombre",
            "Cascade",
            "Port Glaud",
            "Takamaka",
            "Victoria"
        ]
    },
    {
        "label": "Sierra Leone",
        "code": "SL",
        "phone": "232",
        "cities": [
            "Alikalia",
            "Baiima",
            "Baoma",
            "Barma",
            "Bindi",
            "Binkolo",
            "Blama",
            "Bo",
            "Bo District",
            "Boajibu",
            "Bombali District",
            "Bomi",
            "Bonthe",
            "Bonthe District",
            "Buedu",
            "Bumbuna",
            "Bumpe",
            "Bunumbu",
            "Daru",
            "Foindu",
            "Freetown",
            "Gandorhun",
            "Gberia Fotombu",
            "Gbewebu",
            "Giehun",
            "Gorahun",
            "Hangha",
            "Hastings",
            "Jojoima",
            "Kabala",
            "Kailahun",
            "Kailahun District",
            "Kamakwie",
            "Kambia",
            "Kassiri",
            "Kayima",
            "Kenema",
            "Kenema District",
            "Kent",
            "Koidu",
            "Koinadugu District",
            "Konakridee",
            "Kono District",
            "Koribundu",
            "Koyima",
            "Kukuna",
            "Largo",
            "Loma",
            "Lunsar",
            "Magburaka",
            "Makali",
            "Makeni",
            "Mambolo",
            "Mamboma",
            "Mange",
            "Manowa",
            "Masaka",
            "Masingbi",
            "Masoyila",
            "Mobai",
            "Mogbwemo",
            "Motema",
            "Moyamba",
            "Moyamba District",
            "Palima",
            "Panguma",
            "Pendembu",
            "Pepel",
            "Potoru",
            "Pujehun",
            "Pujehun District",
            "Rokupr",
            "Rotifunk",
            "Sawkta",
            "Segbwema",
            "Seidu",
            "Serabu",
            "Simbakoro",
            "Sumbuya",
            "Tefeya",
            "Tintafor",
            "Tombodu",
            "Tombu",
            "Tongole",
            "Tonkolili District",
            "Waterloo",
            "Wima",
            "Yengema",
            "Yonibana",
            "Zimmi"
        ]
    },
    {
        "label": "Singapore",
        "code": "SG",
        "phone": "65",
        "cities": [
            "Ang Mo Kio",
            "Bedok",
            "Bukit Batok",
            "Bukit Panjang",
            "Bukit Timah",
            "Downtown Core",
            "Geylang",
            "Hougang",
            "Jurong West",
            "Kallang",
            "Marine Parade",
            "Novena",
            "Orchard Road",
            "Outram",
            "Pasir Ris",
            "Punggol",
            "Rochor",
            "Sembawang",
            "Sengkang",
            "Serangoon",
            "Singapore",
            "Tampines",
            "Tanjong Pagar",
            "Toa Payoh",
            "Woodlands",
            "Yishun"
        ]
    },
    {
        "label": "Sint Maarten (Dutch part)",
        "code": "SX",
        "phone": "1721",
        "cities": []
    },
    {
        "label": "Slovakia",
        "code": "SK",
        "phone": "421",
        "cities": [
            "Bánovce nad Bebravou",
            "Banská Bystrica",
            "Banská Štiavnica",
            "Bardejov",
            "Bojnice",
            "Bratislava",
            "Bratislava - Vajnory",
            "Brezno",
            "Brezová pod Bradlom",
            "Bytča",
            "Čachtice",
            "Čadca",
            "Chlmec",
            "Čierna nad Tisou",
            "Čierny Balog",
            "Detva",
            "Dobšiná",
            "Dolný Kubín",
            "Dubnica nad Váhom",
            "Dudince",
            "Dunajská Lužná",
            "Dunajská Streda",
            "Fiľakovo",
            "Gabčíkovo",
            "Galanta",
            "Gbely",
            "Gelnica",
            "Giraltovce",
            "Handlová",
            "Hlohovec",
            "Holíč",
            "Hriňová",
            "HrochoťSlovakia",
            "Humenné",
            "Hurbanovo",
            "Hybe",
            "Ilava",
            "Ivanka pri Dunaji",
            "Kavečany",
            "Kežmarok",
            "Kolárovo",
            "Komárno",
            "Košice",
            "Košice I",
            "Košice II",
            "Košice III",
            "Košice IV",
            "Kováčová",
            "Krasňany",
            "Kremnica",
            "Krompachy",
            "Krupina",
            "Kysucké Nové Mesto",
            "Lehota pod Vtáčnikom",
            "Leopoldov",
            "Levice",
            "Levoča",
            "Lipany",
            "Liptovský Hrádok",
            "Liptovský Mikuláš",
            "Ľubica",
            "Lučenec",
            "Lúčky",
            "Malacky",
            "Marianka",
            "Martin",
            "Medzev",
            "Medzilaborce",
            "Michalovce",
            "Modra",
            "Moldava nad Bodvou",
            "Myjava",
            "Námestovo",
            "Nemšová",
            "Nitra",
            "Nižná",
            "Nová Baňa",
            "Nová Dubnica",
            "Nová Lesná",
            "Nováky",
            "Nové Mesto nad Váhom",
            "Nové Zámky",
            "Okres Bánovce nad Bebravou",
            "Okres Banská Bystrica",
            "Okres Banská Štiavnica",
            "Okres Bardejov",
            "Okres Bratislava I",
            "Okres Bratislava II",
            "Okres Bratislava III",
            "Okres Bratislava IV",
            "Okres Bratislava V",
            "Okres Brezno",
            "Okres Bytča",
            "Okres Čadca",
            "Okres Detva",
            "Okres Dolný Kubín",
            "Okres Dunajská Streda",
            "Okres Galanta",
            "Okres Gelnica",
            "Okres Hlohovec",
            "Okres Humenné",
            "Okres Ilava",
            "Okres Kežmarok",
            "Okres Komárno",
            "Okres Kosice-okolie",
            "Okres Krupina",
            "Okres Kysucké Nové Mesto",
            "Okres Levice",
            "Okres Levoča",
            "Okres Liptovský Mikuláš",
            "Okres Lučenec",
            "Okres Malacky",
            "Okres Martin",
            "Okres Medzilaborce",
            "Okres Michalovce",
            "Okres Myjava",
            "Okres Namestovo",
            "Okres Nitra",
            "Okres Nové Mesto nad Váhom",
            "Okres Nové Zámky",
            "Okres Partizánske",
            "Okres Pezinok",
            "Okres Piešťany",
            "Okres Poltár",
            "Okres Poprad",
            "Okres Považská Bystrica",
            "Okres Prešov",
            "Okres Prievidza",
            "Okres Púchov",
            "Okres Revúca",
            "Okres Rožňava",
            "Okres Ružomberok",
            "Okres Sabinov",
            "Okres Šaľa",
            "Okres Senec",
            "Okres Senica",
            "Okres Skalica",
            "Okres Snina",
            "Okres Sobrance",
            "Okres Spišská Nová Ves",
            "Okres Stará Ĺubovňa",
            "Okres Stropkov",
            "Okres Svidník",
            "Okres Topoľčany",
            "Okres Trebišov",
            "Okres Trenčín",
            "Okres Trnava",
            "Okres Turčianske Teplice",
            "Okres Tvrdošín",
            "Okres Veľký Krtíš",
            "Okres Vranov nad Topľou",
            "Okres Žarnovica",
            "Okres Žiar nad Hronom",
            "Okres Žilina",
            "Okres Zlaté Moravce",
            "Okres Zvolen",
            "Oravská Lesná",
            "Oravský Podzámok",
            "Partizánske",
            "Pezinok",
            "Piešťany",
            "Podolínec",
            "Poltár",
            "Poprad",
            "Považská Bystrica",
            "Prešov",
            "Pribylina",
            "Prievidza",
            "Púchov",
            "Rajec",
            "Revúca",
            "Rimavská Sobota",
            "Rožňava",
            "Ružomberok",
            "Sabinov",
            "Šahy",
            "Šaľa",
            "Šamorín",
            "Sečovce",
            "Senec",
            "Senica",
            "Skalica",
            "Sládkovičovo",
            "Smolenice",
            "Snina",
            "Sobrance",
            "Spišská Belá",
            "Spišská Nová Ves",
            "Spišské Podhradie",
            "Stará Ľubovňa",
            "Stará Turá",
            "Strážske",
            "Štrba",
            "Stropkov",
            "Stupava",
            "Štúrovo",
            "Šurany",
            "Svätý Anton",
            "Svätý Jur",
            "Svidník",
            "Svit",
            "Svodín",
            "Terchová",
            "Tisovec",
            "Tlmače",
            "Topoľčany",
            "Trebišov",
            "Trenčianske Teplice",
            "Trenčín",
            "Trnava",
            "Trstená",
            "Turčianske Teplice",
            "Turzovka",
            "Tvrdošín",
            "Veľký Krtíš",
            "Veľký Meder",
            "Vinné",
            "Vinosady",
            "Vráble",
            "Vranov nad Topľou",
            "Vrbov",
            "Vrbové",
            "Vrútky",
            "Vyšné Ružbachy",
            "Vysoké Tatry",
            "Žarnovica",
            "Ždiar",
            "Žehra",
            "Želiezovce",
            "Žiar nad Hronom",
            "Žilina",
            "Zlaté Moravce",
            "Zvolen"
        ]
    },
    {
        "label": "Slovenia",
        "code": "SI",
        "phone": "386",
        "cities": [
            "Ajdovščina",
            "Ankaran",
            "Apače",
            "Bakovci",
            "Beltinci",
            "Benedikt",
            "Bilje",
            "Bistrica ob Dravi",
            "Bistrica ob Sotli",
            "Bistrica pri Tržiču",
            "Bled",
            "Bohinjska Bistrica",
            "Borovnica",
            "Bovec",
            "Braslovče",
            "Bresternica",
            "Brežice",
            "Brezovica pri Ljubljani",
            "Britof",
            "Cankova",
            "Celje",
            "Cerklje na Gorenjskem",
            "Cerknica",
            "Cerkno",
            "Cerkvenjak",
            "Černelavci",
            "Cirkulane",
            "Črenšovci",
            "Črna na Koroškem",
            "Črnomelj",
            "Črnuče District",
            "Dekani",
            "Deskle",
            "Destrnik",
            "Divača",
            "Dob",
            "Dobje pri Planini",
            "Dobrna",
            "Dobrova",
            "Dobrovnik",
            "Dobrovo",
            "Dol pri Hrastniku",
            "Dol pri Ljubljani",
            "Dolenjske Toplice",
            "Domžale",
            "Dornava",
            "Dragomer",
            "Dravlje District",
            "Dravograd",
            "Fram",
            "Gančani",
            "Golnik",
            "Gorenja Vas",
            "Gorišnica",
            "Gornja Radgona",
            "Gornji Grad",
            "Gornji Petrovci",
            "Gotovlje",
            "Grad",
            "Grosuplje",
            "Hodoš",
            "Horjul",
            "Hotinja Vas",
            "Hrastje",
            "Hrastnik",
            "Hrib-Loški Potok",
            "Hrušica",
            "Hrvatini",
            "Idrija",
            "Ig",
            "Ilirska Bistrica",
            "Ivančna Gorica",
            "Izlake",
            "Izola",
            "Jagodje",
            "Jarše District",
            "Jesenice",
            "Juršinci",
            "Kamnica",
            "Kamnik",
            "Kanal",
            "Kidričevo",
            "Kisovec",
            "Kobarid",
            "Kobilje",
            "Kočevje",
            "Kokrica",
            "Komen",
            "Komenda",
            "Koper",
            "Koroška Bela",
            "Kostanjevica na Krki",
            "Kostel",
            "Kotlje",
            "Kozina",
            "Kozje",
            "Kranj",
            "Kranjska Gora",
            "Križevci pri Ljutomeru",
            "Krog",
            "Kromberk",
            "Krško",
            "Kuzma",
            "Laško",
            "Lavrica",
            "Legen",
            "Lenart v Slov. Goricah",
            "Lendava",
            "Lesce",
            "Leskova Dolina",
            "Leskovec pri Krškem",
            "Limbuš",
            "Lipovci",
            "Litija",
            "Ljubečna",
            "Ljubljana",
            "Ljubno ob Savinji",
            "Ljutomer",
            "Log pri Brezovici",
            "Logatec",
            "Lokavec",
            "Lovrenc na Pohorju",
            "Luče",
            "Lucija",
            "Lukovica pri Domžalah",
            "Majšperk",
            "Makole",
            "Maribor",
            "Markovci",
            "Medvode",
            "Mekinje",
            "Mengeš",
            "Metlika",
            "Mežica",
            "Miklavž na Dravskem Polju",
            "Miren",
            "Mirna",
            "Mirna Peč",
            "Mislinja",
            "Mlaka pri Kranju",
            "Mojstrana",
            "Mokronog",
            "Moravče",
            "Moravske Toplice",
            "Morje",
            "Moste",
            "Mozirje",
            "Murska Sobota",
            "Muta",
            "Naklo",
            "Nazarje",
            "Notranje Gorice",
            "Nova Gorica",
            "Nova Vas",
            "Novo Mesto",
            "Odranci",
            "Opčina Ljubljana-Bežigrad",
            "Oplotnica",
            "Opština [historical] Ljubljana-Šiška",
            "Opština Ljubljana-Center",
            "Opština Ljubljana-Moste-Polje",
            "Opština Ljubljana-Vič-Rudnik",
            "Ormož",
            "Osilnica",
            "Pameče",
            "Pekre",
            "Pesnica pri Mariboru",
            "Piran",
            "Pivka",
            "Pobegi",
            "Podčetrtek",
            "Podlehnik",
            "Podvelka",
            "Poljčane",
            "Polzela",
            "Portorož",
            "Postojna",
            "Prade",
            "Pragersko",
            "Prebold",
            "Preddvor",
            "Preserje pri Radomljah",
            "Prevalje",
            "Prvačina",
            "Ptuj",
            "Puconci",
            "Rače",
            "Radeče",
            "Radenci",
            "Radizel",
            "Radlje ob Dravi",
            "Radomlje",
            "Radovljica",
            "Rakek",
            "Rakičan",
            "Ravne",
            "Ravne na Koroškem",
            "Razkrižje",
            "Razvanje",
            "Rečica ob Savinji",
            "Renče",
            "Ribnica",
            "Ribnica na Pohorju",
            "Rogaška Slatina",
            "Rogašovci",
            "Rogatec",
            "Rogoza",
            "Rožna Dolina",
            "Rožnik District",
            "Ruše",
            "Šalovci",
            "Seča",
            "Selnica ob Dravi",
            "Selnica ob Muri",
            "Semič",
            "Šempas",
            "Šempeter pri Gorici",
            "Šempeter v Savinj. Dolini",
            "Šenčur",
            "Senovo",
            "Šentilj v Slov. Goricah",
            "Šentjernej",
            "Šentjur",
            "Šentrupert",
            "Šentvid District",
            "Šentvid pri Stični",
            "Sevnica",
            "Sežana",
            "Škocjan",
            "Škofja Loka",
            "Škofljica",
            "Slovenj Gradec",
            "Slovenska Bistrica",
            "Slovenske Konjice",
            "Slovenski Javornik",
            "Šmarca",
            "Šmarje pri Jelšah",
            "Šmarje-Sap",
            "Šmarjeta",
            "Šmartno ob Paki",
            "Šmartno pri Litiji",
            "Šmartno pri Slovenj Gradcu",
            "Sodražica",
            "Solčava",
            "Solkan",
            "Šoštanj",
            "Sostro District",
            "Spodnja Hajdina",
            "Spodnja Idrija",
            "Spodnje Hoče",
            "Spodnje Škofije",
            "Spodnji Duplek",
            "Središče ob Dravi",
            "Starše",
            "Štore",
            "Straža",
            "Sv. Ana v Slov. Goricah",
            "Sv. Anton",
            "Sv. Duh",
            "Sv. Trojica v Slov. Goricah",
            "Sveti Jurij ob Ščavnici",
            "Sveti Tomaž",
            "Tabor",
            "Tišina",
            "Tolmin",
            "Topolšica",
            "Trbovlje",
            "Trebnje",
            "Trnovlje pri Celju",
            "Trnovo District",
            "Trnovska Vas",
            "Tržič",
            "Trzin",
            "Turnišče",
            "Velika Polana",
            "Velike Lašče",
            "Verd",
            "Veržej",
            "Vič District",
            "Videm",
            "Videm pri Ptuju",
            "Vipava",
            "Vir",
            "Vitanje",
            "Vitomarci",
            "Vnanje Gorice",
            "Vodice",
            "Vojnik",
            "Volčja Draga",
            "Vransko",
            "Vrhnika",
            "Vrtojba",
            "Vuzenica",
            "Zabukovica",
            "Zagorje ob Savi",
            "Žalec",
            "Zasip",
            "Zavrč",
            "Železniki",
            "Žetale",
            "Zgornja Kungota",
            "Zgornja Polskava",
            "Zgornje Bitnje",
            "Zgornje Gorje",
            "Zgornje Jezersko",
            "Zgornje Pirniče",
            "Zgornji Duplek",
            "Žiri",
            "Žirovnica",
            "Zreče",
            "Žužemberk"
        ]
    },
    {
        "label": "Solomon Islands",
        "code": "SB",
        "phone": "677",
        "cities": [
            "Auki",
            "Buala",
            "Gizo",
            "Honiara",
            "Kirakira",
            "Lata",
            "Tulagi"
        ]
    },
    {
        "label": "Somalia",
        "code": "SO",
        "phone": "252",
        "cities": [
            "Afgooye",
            "Baardheere",
            "Baidoa",
            "Bandarbeyla",
            "Bargaal",
            "Beledweyne",
            "Bereeda",
            "Bosaso",
            "Burao",
            "Buulobarde",
            "Buur Gaabo",
            "Buurhakaba",
            "Cadale",
            "Caluula",
            "Ceek",
            "Ceelbuur",
            "Ceeldheer",
            "Ceerigaabo",
            "Dhuusamarreeb",
            "Dujuuma",
            "Eyl",
            "Gaalkacyo",
            "Garbahaarrey",
            "Garoowe",
            "Hobyo",
            "Iskushuban",
            "Jalalaqsi",
            "Jamaame",
            "Jawhar",
            "Jilib",
            "Kismayo",
            "Las Khorey",
            "Luuq",
            "Mahaddayweyne",
            "Marka",
            "Mogadishu",
            "Oodweyne",
            "Qandala",
            "Qoryooley",
            "Saacow",
            "Tayeeglow",
            "Waajid",
            "Wanlaweyn",
            "Xarardheere",
            "Xuddur",
            "Yeed"
        ]
    },
    {
        "label": "South Africa",
        "code": "ZA",
        "phone": "27",
        "cities": [
            "Adelaide",
            "Albertina",
            "Alberton",
            "Alfred Nzo District Municipality",
            "Alice",
            "Aliwal North",
            "Allanridge",
            "Amajuba District Municipality",
            "Amathole District Municipality",
            "Arniston",
            "Atlantis",
            "Balfour",
            "Ballito",
            "Barberton",
            "Barkly West",
            "Beaufort West",
            "Belfast",
            "Benoni",
            "Berea",
            "Bergvliet",
            "Bethal",
            "Bethlehem",
            "Bhisho",
            "Bloemfontein",
            "Bloemhof",
            "Bochum",
            "Bojanala Platinum District Municipality",
            "Boksburg",
            "Bothaville",
            "Botshabelo",
            "Brakpan",
            "Brandfort",
            "Brandvlei",
            "Bredasdorp",
            "Breyten",
            "Brits",
            "Bronkhorstspruit",
            "Buffalo City Metropolitan Municipality",
            "Burgersdorp",
            "Butterworth",
            "Cacadu District Municipality",
            "Caledon",
            "Calitzdorp",
            "Calvinia",
            "Cape Town",
            "Cape Winelands District Municipality",
            "Capricorn District Municipality",
            "Carletonville",
            "Carnarvon",
            "Carolina",
            "Central Karoo District Municipality",
            "Centurion",
            "Ceres",
            "Chris Hani District Municipality",
            "Christiana",
            "City of Cape Town",
            "City of Johannesburg Metropolitan Municipality",
            "City of Tshwane Metropolitan Municipality",
            "Clanwilliam",
            "Claremont",
            "Clocolan",
            "Colesberg",
            "Constantia",
            "Cradock",
            "Cullinan",
            "Daniëlskuil",
            "De Aar",
            "De Rust",
            "Delmas",
            "Deneysville",
            "Diepsloot",
            "Dordrecht",
            "Dr Kenneth Kaunda District Municipality",
            "Dr Ruth Segomotsi Mompati District Municipality",
            "Driefontein",
            "Duiwelskloof",
            "Dundee",
            "Durban",
            "East London",
            "Eastleigh",
            "Eden District Municipality",
            "Eden Glen",
            "Eden Glen Ext 60",
            "Edenvale",
            "Ehlanzeni District",
            "Ekangala",
            "Ekurhuleni Metropolitan Municipality",
            "Ekuvukeni",
            "Elliot",
            "eMbalenhle",
            "eMkhomazi",
            "Empangeni",
            "Ermelo",
            "Eshowe",
            "eSikhaleni",
            "eThekwini Metropolitan Municipality",
            "Fezile Dabi District Municipality",
            "Fochville",
            "Fort Beaufort",
            "Frances Baard District Municipality",
            "Frankfort",
            "Fraserburg",
            "Ga-Kgapane",
            "Ga-Rankuwa",
            "George",
            "Gert Sibande District Municipality",
            "Giyani",
            "Glencoe",
            "Graaff-Reinet",
            "Grabouw",
            "Grahamstown",
            "Greytown",
            "Hardys Memories of Africa",
            "Harrismith",
            "Heidelberg",
            "Heilbron",
            "Hendrina",
            "Hennenman",
            "Hermanus",
            "Hluhluwe",
            "Hoopstad",
            "Howick",
            "iLembe District Municipality",
            "Ilinge",
            "Jan Kempdorp",
            "Joe Gqabi District Municipality",
            "Johannesburg",
            "John Taolo Gaetsewe District Municipality",
            "Kathu",
            "Kenhardt",
            "Kimberley",
            "Kirkwood",
            "Klerksdorp",
            "Knysna",
            "Kokstad",
            "Komatipoort",
            "Koppies",
            "Koster",
            "Kraaifontein",
            "Kriel",
            "Kroonstad",
            "Krugersdorp",
            "Kruisfontein",
            "Kuruman",
            "Kutloanong",
            "KwaDukuza",
            "Ladismith",
            "Lady Frere",
            "Ladybrand",
            "Lansdowne",
            "Lebowakgomo",
            "Lejweleputswa District Municipality",
            "Lichtenburg",
            "Lindley",
            "Louis Trichardt",
            "Lydenburg",
            "Mabopane",
            "Mahikeng",
            "Maile",
            "Malmesbury",
            "Mangaung Metropolitan Municipality",
            "Mankoeng",
            "Margate",
            "Marquard",
            "Middelburg",
            "Middelburg",
            "Midrand",
            "Midstream",
            "Mmabatho",
            "Modderfontein",
            "Modimolle",
            "Mokopane",
            "Molteno",
            "Mondlo",
            "Montagu",
            "Mooirivier",
            "Moorreesburg",
            "Mopani District Municipality",
            "Mossel Bay",
            "Mpophomeni",
            "Mpumalanga",
            "Mthatha",
            "Mtubatuba",
            "Muldersdriseloop",
            "Musina",
            "Namakwa District Municipality",
            "Ndwedwe",
            "Nelson Mandela Bay Metropolitan Municipality",
            "Nelspruit",
            "Newcastle",
            "Newlands",
            "Ngaka Modiri Molema District Municipality",
            "Nigel",
            "Nkangala District Municipality",
            "Nkowakowa",
            "Noupoort",
            "OR Tambo District Municipality",
            "Orange Farm",
            "Orania",
            "Orkney",
            "Oudtshoorn",
            "Overberg District Municipality",
            "Paarl",
            "Pampierstad",
            "Parys",
            "Phalaborwa",
            "Phuthaditjhaba",
            "Piet Retief",
            "Pietermaritzburg",
            "Piketberg",
            "Pixley ka Seme District Municipality",
            "Plettenberg Bay",
            "Pofadder",
            "Polokwane",
            "Port Alfred",
            "Port Elizabeth",
            "Port Saint John’s",
            "Port Shepstone",
            "Postmasburg",
            "Potchefstroom",
            "Pretoria",
            "Prieska",
            "Prince Albert",
            "Queensdale",
            "Queenstown",
            "Randburg",
            "Randfontein",
            "Reitz",
            "Retreat",
            "Richards Bay",
            "Richmond",
            "Ritchie",
            "Riversdale",
            "Robertson",
            "Rondebosch",
            "Roodepoort",
            "Rosebank",
            "Rustenburg",
            "Saldanha",
            "Sasolburg",
            "Schweizer-Reneke",
            "Scottburgh",
            "Secunda",
            "Sedibeng District Municipality",
            "Sekhukhune District Municipality",
            "Senekal",
            "Sisonke District Municipality",
            "Siyabuswa",
            "Siyanda District Municipality",
            "Somerset East",
            "Soweto",
            "Springbok",
            "Springs",
            "Standerton",
            "Stellenbosch",
            "Stilfontein",
            "Stutterheim",
            "Sundumbili",
            "Sunset Beach",
            "Swellendam",
            "Tembisa",
            "Thaba Nchu",
            "Thabazimbi",
            "Thabo Mofutsanyana District Municipality",
            "Theunissen",
            "Thohoyandou",
            "Thulamahashi",
            "Tzaneen",
            "Ugu District Municipality",
            "Uitenhage",
            "Ulundi",
            "uMgungundlovu",
            "uMkhanyakude District Municipality",
            "uMzinyathi District Municipality",
            "Upington",
            "uThukela District Municipality",
            "uThungulu District Municipality",
            "Utrecht",
            "Van Wyksvlei",
            "Vanderbijlpark",
            "Ventersburg",
            "Vereeniging",
            "Vhembe District Municipality",
            "Viljoenskroon",
            "Villiers",
            "Virginia",
            "Volksrust",
            "Vrede",
            "Vredefort",
            "Vredenburg",
            "Vredendal",
            "Vryburg",
            "Vryheid",
            "Warmbaths",
            "Warrenton",
            "Waterberg District Municipality",
            "Welkom",
            "Wellington",
            "Wesselsbron",
            "West Coast District Municipality",
            "West Rand District Municipality",
            "Westonaria",
            "White River",
            "Whittlesea",
            "Willowmore",
            "Winburg",
            "Witbank",
            "Wolmaransstad",
            "Worcester",
            "Xhariep District Municipality",
            "Zastron",
            "Zeerust",
            "Zoar",
            "Zululand District Municipality"
        ]
    },
    {
        "label": "South Georgia",
        "code": "GS",
        "phone": "500",
        "cities": []
    },
    {
        "label": "South Korea",
        "code": "KR",
        "phone": "82",
        "cities": [
            "Andong",
            "Andong-si",
            "Ansan-si",
            "Anseong",
            "Anyang-si",
            "Asan",
            "Asan-si",
            "Beolgyo",
            "Boeun-gun",
            "Bonghwa-gun",
            "Boryeong",
            "Boryeong-si",
            "Boseong-gun",
            "Buan-gun",
            "Bucheon-si",
            "Buk-gu",
            "Buk-gu",
            "Bupyeong-gu",
            "Busan",
            "Buyeo",
            "Buyeo-gun",
            "Changnyeong",
            "Changnyeong-gun",
            "Changsu",
            "Changwon",
            "Changwon-si",
            "Cheonan",
            "Cheonan-si",
            "Cheongdo-gun",
            "Cheongju-si",
            "Cheongsong gun",
            "Cheongsong-gun",
            "Cheongyang-gun",
            "Cheorwon-gun",
            "Chilgok-gun",
            "Chinch'ŏn",
            "Chinju",
            "Chuncheon",
            "Chuncheon-si",
            "Chungju-si",
            "Daegu",
            "Daejeon",
            "Dalseo-gu",
            "Dalseong-gun",
            "Damyang-gun",
            "Danyang-gun",
            "Dobong-gu",
            "Dong-gu",
            "Dong-gu",
            "Dongdaemun-gu",
            "Dongducheon",
            "Donghae-si",
            "Dongjak-gu",
            "Dongnae-gu",
            "Eisen",
            "Eumseong-gun",
            "Eunpyeong-gu",
            "Gaigeturi",
            "Gangbuk-gu",
            "Gangdong-gu",
            "Gangjin-gun",
            "Gangnam-gu",
            "Gangneung",
            "Gangneung-si",
            "Gangseo-gu",
            "Gangseo-gu",
            "Gapyeong",
            "Gapyeong-gun",
            "Geochang-gun",
            "Geoje-si",
            "Geumcheon-gu",
            "Geumjeong-gu",
            "Geumsan-gun",
            "Gijang",
            "Gijang-gun",
            "Gimcheon",
            "Gimcheon-si",
            "Gimhae-si",
            "Gimje-si",
            "Gimpo-si",
            "Gochang-gun",
            "Goesan-gun",
            "Goheung-gun",
            "Gokseong-gun",
            "Gongju",
            "Gongju-si",
            "Goryeong-gun",
            "Goseong",
            "Goseong-gun",
            "Goseong-gun",
            "Goyang-si",
            "Gumi-si",
            "Gunpo-si",
            "Gunsan",
            "Gunsan-si",
            "Gunwi-gun",
            "Guri-si",
            "Guro-gu",
            "Gurye-gun",
            "Gwacheon-si",
            "Gwanak-gu",
            "Gwangjin-gu",
            "Gwangju",
            "Gwangju",
            "Gwangju-si",
            "Gwangmyeong-si",
            "Gwangsan-gu",
            "Gwangyang",
            "Gwangyang-si",
            "Gyeongju",
            "Gyeongsan-si",
            "Gyeryong-si",
            "Hadong-gun",
            "Haenam",
            "Haenam-gun",
            "Haeundae-gu",
            "Haman-gun",
            "Hampyeong-gun",
            "Hanam",
            "Hanam-si",
            "Hapcheon-gun",
            "Hayang",
            "Heunghae",
            "Hoengseong-gun",
            "Hongch’ŏn",
            "Hongcheon-gun",
            "Hongseong",
            "Hongseong-gun",
            "Hwacheon",
            "Hwacheon-gun",
            "Hwado",
            "Hwaseong-si",
            "Hwasun",
            "Hwasun-gun",
            "Hwawŏn",
            "Icheon-si",
            "Iksan",
            "Iksan-si",
            "Imsil",
            "Imsil-gun",
            "Incheon",
            "Inje-gun",
            "Jangheung-gun",
            "Jangseong-gun",
            "Jangsu-gun",
            "Jecheon-si",
            "Jeju City",
            "Jeju-si",
            "Jenzan",
            "Jeongeup",
            "Jeongeup-si",
            "Jeongseon-gun",
            "Jeonju",
            "Jeonju-si",
            "Jeungpyeong-gun",
            "Jinan-gun",
            "Jincheon-gun",
            "Jindo-gun",
            "Jinju-si",
            "Jongno-gu",
            "Jung-gu",
            "Jung-gu",
            "Jung-gu",
            "Jung-gu",
            "Jungnang-gu",
            "Kimhae",
            "Kimje",
            "Kinzan",
            "Koch'ang",
            "Koesan",
            "Kosong",
            "Kunwi",
            "Kurye",
            "Kwangmyŏng",
            "Kwangyang",
            "Kyosai",
            "Mapo-gu",
            "Masan",
            "Michuhol",
            "Miryang",
            "Miryang-si",
            "Mokpo",
            "Mokpo-si",
            "Muan",
            "Muan-gun",
            "Muju-gun",
            "Mungyeong",
            "Mungyeong-si",
            "Munsan",
            "Naesŏ",
            "Naju",
            "Naju-si",
            "Nam-gu",
            "Nam-gu",
            "Namhae-gun",
            "Namwon",
            "Namyangju",
            "Namyangju-si",
            "Nangen",
            "Neietsu",
            "Nonsan",
            "Nonsan-si",
            "Nowon-gu",
            "Okcheon",
            "Okcheon-gun",
            "Osan",
            "Osan-si",
            "Paju-si",
            "Pocheon-si",
            "Pohang",
            "Pohang-si",
            "Puan",
            "Pubal",
            "Pyeongchang",
            "Pyeongchang-gun",
            "Pyeongtaek",
            "Sacheon-si",
            "Saha-gu",
            "Samcheok-si",
            "Sancheong-gun",
            "Sangju",
            "Santyoku",
            "Sasang-gu",
            "Sejong",
            "Seo-gu",
            "Seo-gu",
            "Seo-gu",
            "Seocheon-gun",
            "Seocho-gu",
            "Seodaemun-gu",
            "Seogwipo",
            "Seongbuk-gu",
            "Seongdong-gu",
            "Seonghwan",
            "Seongju-gun",
            "Seongnam-si",
            "Seosan",
            "Seosan-si",
            "Seoul",
            "Siheung",
            "Sinan",
            "Sinan-gun",
            "Sinhyeon",
            "Sintansin",
            "Sokcho",
            "Sokcho-si",
            "Songgangdong",
            "Songpa-gu",
            "Sunchang-gun",
            "Suncheon",
            "Suncheon-si",
            "Suseong-gu",
            "Suwon",
            "Suyeong-gu",
            "T’aebaek",
            "Taean-gun",
            "Taebaek-si",
            "Taesal-li",
            "Tangjin",
            "Tonghae",
            "Uijeongbu-si",
            "Uiryeong-gun",
            "Uiseong-gun",
            "Uiwang-si",
            "Uljin-gun",
            "Ulju-gun",
            "Ulleung-gun",
            "Ulsan",
            "Ungsang",
            "Wabu",
            "Waegwan",
            "Wando-gun",
            "Wanju",
            "Wŏnju",
            "Wonju-si",
            "Yangcheon-gu",
            "Yanggu",
            "Yanggu-gun",
            "Yangju",
            "Yangju-si",
            "Yangp'yŏng",
            "Yangpyeong-gun",
            "Yangsan",
            "Yangyang-gun",
            "Yecheon-gun",
            "Yeoju-si",
            "Yeoncheon-gun",
            "Yeongam",
            "Yeongam-gun",
            "Yeongcheon-si",
            "Yeongdeok-gun",
            "Yeongdeungpo-gu",
            "Yeongdo-gu",
            "Yeongdong",
            "Yeongdong-gun",
            "Yeonggwang",
            "Yeonggwang-gun",
            "Yeongju-si",
            "Yeongyang-gun",
            "Yeonil",
            "Yeonsu-gu",
            "Yeosu",
            "Yesan",
            "Yesan-gun",
            "Yongin-si",
            "Yongsan-dong",
            "Yongsan-gu",
            "Yŏnmu",
            "Yuseong-gu"
        ]
    },
    {
        "label": "South Sudan",
        "code": "SS",
        "phone": "211",
        "cities": [
            "Yirol"
        ]
    },
    {
        "label": "Spain",
        "code": "ES",
        "phone": "34",
        "cities": [
            "Ababuj",
            "Abades",
            "Abadía",
            "Abadín",
            "Abadiño",
            "Abáigar",
            "Abajas",
            "Ábalos",
            "Abaltzisketa",
            "Abánades",
            "Abanilla",
            "Abanto",
            "Abanto y Ciérvana-Abanto Zierbena",
            "Abarán",
            "Abarca de Campos",
            "Abárzuza/Abartzuza",
            "Abaurregaina/Abaurrea Alta",
            "Abaurrepea/Abaurrea Baja",
            "Abegondo",
            "Abejar",
            "Abejuela",
            "Abella de la Conca",
            "Abengibre",
            "Abenójar",
            "Aberin",
            "Abertura",
            "Abezames",
            "Abia de la Obispalía",
            "Abia de las Torres",
            "Abiego",
            "Abizanda",
            "Abla",
            "Ablanque",
            "Ablitas",
            "Abrera",
            "Abrucena",
            "Abusejo",
            "Acebeda La",
            "Acebedo",
            "Acebo",
            "Acebrón El",
            "Acedera",
            "Acehúche",
            "Aceituna",
            "Acered",
            "Aceuchal",
            "Adahuesca",
            "Adalia",
            "Adamuz",
            "Adanero",
            "Adeje",
            "Ademuz",
            "Adiós",
            "Adobes",
            "Ador",
            "Adra",
            "Adrada de Haza",
            "Adrada de Pirón",
            "Adrada La",
            "Adradas",
            "Adrados",
            "Adsubia",
            "Aduna",
            "Adzaneta",
            "Agaete",
            "Agallas",
            "Àger",
            "Agolada",
            "Agón",
            "Agoncillo",
            "Agost",
            "Agramunt",
            "Ágreda",
            "Agres",
            "Agrón",
            "Aguadulce",
            "Aguarón",
            "Aguas Cándidas",
            "Aguasal",
            "Aguatón",
            "Aguaviva",
            "Agudo",
            "Agüero",
            "Aguilafuente",
            "Aguilar de Bureba",
            "Aguilar de Campoo",
            "Aguilar de Campos",
            "Aguilar de Codés",
            "Aguilar de la Frontera",
            "Aguilar de Segarra",
            "Aguilar del Alfambra",
            "Aguilar del Río Alhama",
            "Águilas",
            "Aguilón",
            "Agüimes",
            "Agullana",
            "Agullent",
            "Agulo",
            "Ahigal",
            "Ahigal de los Aceiteros",
            "Ahigal de Villarino",
            "Ahillones",
            "Aia",
            "Aibar/Oibar",
            "Aielo de Malferit",
            "Aielo de Rugat",
            "Aiguafreda",
            "Aiguamúrcia",
            "Aiguaviva",
            "Aigües",
            "Aín",
            "Aínsa",
            "Aínsa-Sobrarbe",
            "Ainzón",
            "Aisa",
            "Aitona",
            "Aizarnazabal",
            "Ajalvir",
            "Ajamil",
            "Ajamil de Cameros",
            "Ajangiz",
            "Ajofrín",
            "Alacón",
            "Aladrén",
            "Alaejos",
            "Alagón",
            "Alagón del Río",
            "Alaior",
            "Alájar",
            "Alajeró",
            "Alameda",
            "Alameda de Gardón La",
            "Alameda de la Sagra",
            "Alameda del Valle",
            "Alamedilla",
            "Alamedilla La",
            "Alamillo",
            "Alaminos",
            "Álamo El",
            "Alamús Els",
            "Alange",
            "Alanís",
            "Alaquàs",
            "Alar del Rey",
            "Alaraz",
            "Alarba",
            "Alarcón",
            "Alarilla",
            "Alaró",
            "Alàs i Cerc",
            "Alatoz",
            "Alba",
            "Alba de Cerrato",
            "Alba de Tormes",
            "Alba de Yeltes",
            "Albacete",
            "Albagés L",
            "Albaida",
            "Albaida del Aljarafe",
            "Albal",
            "Albalá",
            "Albaladejo",
            "Albaladejo del Cuende",
            "Albalat de la Ribera",
            "Albalat dels Sorells",
            "Albalat dels Tarongers",
            "Albalate de Cinca",
            "Albalate de las Nogueras",
            "Albalate de Zorita",
            "Albalate del Arzobispo",
            "Albalatillo",
            "Albánchez",
            "Albanchez de Mágina",
            "Albanyà",
            "Albares",
            "Albarracín",
            "Albarreal de Tajo",
            "Albatana",
            "Albatàrrec",
            "Albatera",
            "Albelda",
            "Albelda de Iregua",
            "Albendea",
            "Albendiego",
            "Albentosa",
            "Alberca de Záncara La",
            "Alberca La",
            "Alberguería de Argañán La",
            "Alberic",
            "Alberite",
            "Alberite de San Juan",
            "Albero Alto",
            "Albero Bajo",
            "Alberuela de Tubo",
            "Albesa",
            "Albeta",
            "Albi L",
            "Albillos",
            "Albinyana",
            "Albiol",
            "Albiztur",
            "Albocàsser",
            "Alboloduy",
            "Albolote",
            "Albondón",
            "Albons",
            "Alborache",
            "Alboraya",
            "Alborea",
            "Alborge",
            "Albornos",
            "Albox",
            "Albudeite",
            "Albuera La",
            "Albuixech",
            "Albuñán",
            "Albuñol",
            "Albuñuelas",
            "Alburquerque",
            "Alcabón",
            "Alcadozo",
            "Alcaine",
            "Alcalá de Ebro",
            "Alcalá de Guadaira",
            "Alcalá de Gurrea",
            "Alcalá de Henares",
            "Alcalá de la Selva",
            "Alcalá de la Vega",
            "Alcalá de los Gazules",
            "Alcalá de Moncayo",
            "Alcalà de Xivert",
            "Alcalá del Júcar",
            "Alcalá del Obispo",
            "Alcalá del Río",
            "Alcalá del Valle",
            "Alcalá la Real",
            "Alcalalí",
            "Alcampell",
            "Alcanadre",
            "Alcanar",
            "Alcañices",
            "Alcañiz",
            "Alcañizo",
            "Alcanó",
            "Alcántara",
            "Alcantarilla",
            "Alcàntera de Xúquer",
            "Alcantud",
            "Alcaracejos",
            "Alcaraz",
            "Alcarràs",
            "Alcàsser",
            "Alcaucín",
            "Alcaudete",
            "Alcaudete de la Jara",
            "Alcázar de San Juan",
            "Alcázar del Rey",
            "Alcazarén",
            "Alcázares Los",
            "Alcoba",
            "Alcobendas",
            "Alcocéber",
            "Alcocer",
            "Alcocer de Planes",
            "Alcocero de Mola",
            "Alcohujate",
            "Alcolea",
            "Alcolea de Calatrava",
            "Alcolea de Cinca",
            "Alcolea de las Peñas",
            "Alcolea de Tajo",
            "Alcolea del Pinar",
            "Alcolea del Río",
            "Alcoleja",
            "Alcoletge",
            "Alcollarín",
            "Alconaba",
            "Alconada",
            "Alconada de Maderuelo",
            "Alconchel",
            "Alconchel de Ariza",
            "Alconchel de la Estrella",
            "Alconera",
            "Alcóntar",
            "Alcora l",
            "Alcorcón",
            "Alcorisa",
            "Alcoroches",
            "Alcover",
            "Alcoy",
            "Alcoy/Alcoi",
            "Alcubierre",
            "Alcubilla de Avellaneda",
            "Alcubilla de las Peñas",
            "Alcubilla de Nogales",
            "Alcubillas",
            "Alcublas",
            "Alcúdia",
            "Alcúdia de Crespins l",
            "Alcudia de Monteagud",
            "Alcudia de Veo",
            "Alcúdia l",
            "Alcuéscar",
            "Aldaia",
            "Aldea de San Miguel",
            "Aldea de San Nicolás La",
            "Aldea del Cano",
            "Aldea del Fresno",
            "Aldea del Obispo",
            "Aldea del Obispo La",
            "Aldea del Rey",
            "Aldea en Cabo",
            "Aldea L",
            "Aldea Real",
            "Aldeacentenera",
            "Aldeacipreste",
            "Aldeadávila de la Ribera",
            "Aldealafuente",
            "Aldealcorvo",
            "Aldealengua",
            "Aldealengua de Pedraza",
            "Aldealengua de Santa María",
            "Aldealices",
            "Aldealpozo",
            "Aldealseñor",
            "Aldeamayor de San Martín",
            "Aldeanueva de Barbarroya",
            "Aldeanueva de Ebro",
            "Aldeanueva de Figueroa",
            "Aldeanueva de Guadalajara",
            "Aldeanueva de la Serrezuela",
            "Aldeanueva de la Sierra",
            "Aldeanueva de la Vera",
            "Aldeanueva de San Bartolomé",
            "Aldeanueva de Santa Cruz",
            "Aldeanueva del Camino",
            "Aldeanueva del Codonal",
            "Aldeaquemada",
            "Aldearrodrigo",
            "Aldearrubia",
            "Aldeaseca",
            "Aldeaseca de Alba",
            "Aldeaseca de la Frontera",
            "Aldeasoña",
            "Aldeatejada",
            "Aldeavieja de Tormes",
            "Aldehorno",
            "Aldehuela de Jerte",
            "Aldehuela de la Bóveda",
            "Aldehuela de Liestos",
            "Aldehuela de Periáñez",
            "Aldehuela de Yeltes",
            "Aldehuela del Codonal",
            "Aldehuela La",
            "Aldehuelas Las",
            "Aldeire",
            "Aldeonte",
            "Aldover",
            "Aledo",
            "Alegia",
            "Alegría-Dulantzi",
            "Aleixar",
            "Alella",
            "Alentisque",
            "Alerre",
            "Alesanco",
            "Alesón",
            "Alfacar",
            "Alfafar",
            "Alfafara",
            "Alfajarín",
            "Alfambra",
            "Alfamén",
            "Alfántega",
            "Alfara de Algimia",
            "Alfara de Carles",
            "Alfara de la Baronia",
            "Alfara del Patriarca",
            "Alfaraz de Sayago",
            "Alfarnate",
            "Alfarnatejo",
            "Alfaro",
            "Alfarp",
            "Alfarràs",
            "Alfarrasí",
            "Alfàs del Pi l",
            "Alfauir",
            "Alfés",
            "Alfondeguilla",
            "Alforja",
            "Alforque",
            "Alfoz",
            "Alfoz de Bricia",
            "Alfoz de Lloredo",
            "Alfoz de Quintanadueñas",
            "Alfoz de Santa Gadea",
            "Algaba La",
            "Algadefe",
            "Algaida",
            "Algámitas",
            "Algar",
            "Algar de Mesa",
            "Algar de Palancia",
            "Algarinejo",
            "Algarra",
            "Algarrobo",
            "Algatocín",
            "Algeciras",
            "Algemesí",
            "Algerri",
            "Algete",
            "Algimia de Alfara",
            "Algimia de Almonacid",
            "Alginet",
            "Algodonales",
            "Algodre",
            "Algora",
            "Algorfa",
            "Alguaire",
            "Alguazas",
            "Algueña",
            "Alhabia",
            "Alhama de Almería",
            "Alhama de Aragón",
            "Alhama de Granada",
            "Alhama de Murcia",
            "Alhambra",
            "Alhaurín de la Torre",
            "Alhaurín el Grande",
            "Alhendín",
            "Alhóndiga",
            "Alía",
            "Aliaga",
            "Aliaguilla",
            "Alicante",
            "Alicante/Alacant",
            "Alicún",
            "Alicún de Ortega",
            "Alija del Infantado",
            "Alins",
            "Alió",
            "Alique",
            "Aliseda",
            "Aliud",
            "Aljaraque",
            "Aljucén",
            "Alkiza",
            "Allande",
            "Allariz",
            "Allepuz",
            "Aller",
            "Allín/Allin",
            "Allo",
            "Alloza",
            "Allueva",
            "Almacelles",
            "Almáchar",
            "Almadén",
            "Almadén de la Plata",
            "Almadenejos",
            "Almadrones",
            "Almagro",
            "Almajano",
            "Almaluez",
            "Almansa",
            "Almanza",
            "Almaraz",
            "Almaraz de Duero",
            "Almarcha La",
            "Almargen",
            "Almarza",
            "Almarza de Cameros",
            "Almàssera",
            "Almassora",
            "Almatret",
            "Almazán",
            "Almazora/Almassora",
            "Almazul",
            "Almedíjar",
            "Almedina",
            "Almedinilla",
            "Almegíjar",
            "Almeida de Sayago",
            "Almenar",
            "Almenar de Soria",
            "Almenara",
            "Almenara de Adaja",
            "Almenara de Tormes",
            "Almendra",
            "Almendral",
            "Almendral de la Cañada",
            "Almendralejo",
            "Almendro El",
            "Almendros",
            "Almensilla",
            "Almería",
            "Almiserà",
            "Almochuel",
            "Almócita",
            "Almodóvar del Campo",
            "Almodóvar del Pinar",
            "Almodóvar del Río",
            "Almogía",
            "Almoguera",
            "Almohaja",
            "Almoharín",
            "Almoines",
            "Almolda La",
            "Almonacid de la Cuba",
            "Almonacid de la Sierra",
            "Almonacid de Toledo",
            "Almonacid de Zorita",
            "Almonacid del Marquesado",
            "Almonaster la Real",
            "Almonte",
            "Almoradí",
            "Almorox",
            "Almoster",
            "Almozara",
            "Almudaina",
            "Almudévar",
            "Almuñécar",
            "Almunia de Doña Godina La",
            "Almunia de San Juan",
            "Almuniente",
            "Almuradiel",
            "Almussafes",
            "Alobras",
            "Alocén",
            "Alonsotegi",
            "Alora",
            "Alòs de Balaguer",
            "Alosno",
            "Alovera",
            "Alozaina",
            "Alp",
            "Alpandeire",
            "Alpanseque",
            "Alpartir",
            "Alpedrete",
            "Alpeñés",
            "Alpens",
            "Alpera",
            "Alpicat",
            "Alpuente",
            "Alpujarra de la Sierra",
            "Alqueria dAsnar l",
            "Alqueria de la Comtessa l",
            "Alquerías del Niño Perdido",
            "Alquézar",
            "Alquife",
            "Alsodux",
            "Alt Àneu",
            "Altable",
            "Altafulla",
            "Altarejos",
            "Altea",
            "Altorricón",
            "Altos Los",
            "Altsasu",
            "Altsasu/Alsasua",
            "Altura",
            "Altzaga",
            "Altzo",
            "Alustante",
            "Alzira",
            "Amavida",
            "Amayuelas de Arriba",
            "Ambel",
            "Ambía",
            "Ambite",
            "Amer",
            "Amés",
            "Améscoa Baja",
            "Ametlla de Mar",
            "Ametlla del Vallès L",
            "Ameyugo",
            "Amezketa",
            "Amieva",
            "Amoeiro",
            "Amorebieta-Etxano",
            "Amoroto",
            "Ampolla L",
            "Amposta",
            "Ampudia",
            "Ampuero",
            "Amurrio",
            "Amusco",
            "Amusquillo",
            "Anadón",
            "Añana",
            "Anaya",
            "Anaya de Alba",
            "Anchuelo",
            "Anchuras",
            "Ancín",
            "Ancín/Antzin",
            "Andavías",
            "Andilla",
            "Andoain",
            "Andorra",
            "Andosilla",
            "Andratx",
            "Andújar",
            "Añe",
            "Anento",
            "Anglès",
            "Anglesola",
            "Angón",
            "Anguciana",
            "Angüés",
            "Anguiano",
            "Anguita",
            "Anguix",
            "Anievas",
            "Aniñón",
            "Anna",
            "Anoeta",
            "Añón de Moncayo",
            "Añora",
            "Añorbe",
            "Añover de Tajo",
            "Añover de Tormes",
            "Anquela del Ducado",
            "Anquela del Pedregal",
            "Ansó",
            "Ansoáin/Antsoain",
            "Antas",
            "Antas de Ulla",
            "Antella",
            "Antequera",
            "Antigua",
            "Antigua La",
            "Antigüedad",
            "Antillón",
            "Antzuola",
            "Anue",
            "Aoiz",
            "Aoiz/Agoitz",
            "Arabayona de Mógica",
            "Aracena",
            "Arafo",
            "Aragüés del Puerto",
            "Arahal",
            "Arahuetes",
            "Araitz",
            "Arakaldo",
            "Arakil",
            "Arama",
            "Aramaio",
            "Aranarache",
            "Aranarache/Aranaratxe",
            "Arancón",
            "Aranda de Duero",
            "Aranda de Moncayo",
            "Arándiga",
            "Arandilla",
            "Arandilla del Arroyo",
            "Aranga",
            "Aranguren",
            "Aranjuez",
            "Arano",
            "Arantza",
            "Arantzazu",
            "Arañuel",
            "Aranzueque",
            "Arapiles",
            "Aras",
            "Aras de los Olmos",
            "Arauzo de Miel",
            "Arauzo de Salce",
            "Arauzo de Torre",
            "Arbancón",
            "Arbeca",
            "Arbeteta",
            "Arbizu",
            "Arbo",
            "Arboç",
            "Arboleas",
            "Arbolí",
            "Arbúcies",
            "Arcas del Villar",
            "Arce/Artzi",
            "Arcediano",
            "Arcenillas",
            "Archena",
            "Árchez",
            "Archidona",
            "Arcicóllar",
            "Arco El",
            "Arconada",
            "Arcones",
            "Arcos",
            "Arcos de Jalón",
            "Arcos de la Frontera",
            "Arcos de la Polvorosa",
            "Arcos de la Sierra",
            "Arcos de las Salinas",
            "Arcos Los",
            "Ardales",
            "Ardisa",
            "Ardón",
            "Areatza",
            "Arellano",
            "Arén",
            "Arenal El",
            "Arenales de San Gregorio",
            "Arenas",
            "Arenas de Iguña",
            "Arenas de San Juan",
            "Arenas de San Pedro",
            "Arenas del Rey",
            "Arenillas",
            "Arenillas de Riopisuerga",
            "Arens de Lledó",
            "Arenys de Lledó / Arens de Lledó",
            "Arenys de Mar",
            "Arenys de Munt",
            "Arenzana de Abajo",
            "Arenzana de Arriba",
            "Ares",
            "Ares del Maestrat",
            "Ares del Maestre",
            "Areso",
            "Aretxabaleta",
            "Arevalillo",
            "Arevalillo de Cega",
            "Arévalo",
            "Arévalo de la Sierra",
            "Argamasilla de Alba",
            "Argamasilla de Calatrava",
            "Arganda",
            "Arganda del Rey",
            "Argañín",
            "Arganza",
            "Arganzuela",
            "Argavieso",
            "Argecilla",
            "Argelaguer",
            "Argelita",
            "Argençola",
            "Argente",
            "Argentera",
            "Argentona",
            "Argés",
            "Argoños",
            "Arguedas",
            "Arguis",
            "Arguisuelas",
            "Argujillo",
            "Aria",
            "Ariany",
            "Aribe",
            "Arico",
            "Arija",
            "Ariño",
            "Ariza",
            "Arjona",
            "Arjonilla",
            "Arlanzón",
            "Armallones",
            "Armañanzas",
            "Armentera L",
            "Armenteros",
            "Armilla",
            "Armiñón",
            "Armuña",
            "Armuña de Almanzora",
            "Armuña de Tajuña",
            "Arnedillo",
            "Arnedo",
            "Arnes",
            "Arnoia A",
            "Arnuero",
            "Aroche",
            "Arona",
            "Arquillinos",
            "Arquillos",
            "Arrabalde",
            "Arraia-Maeztu",
            "Arrancacepas",
            "Arrankudiaga",
            "Arrasate/Mondragón",
            "Arratzu",
            "Arraya de Oca",
            "Arrazua-Ubarrundia",
            "Arrecife",
            "Arrecife",
            "Arredondo",
            "Arres",
            "Arriate",
            "Arrieta",
            "Arrigorriaga",
            "Arroba de los Montes",
            "Arróniz",
            "Arroyo de la Encomienda",
            "Arroyo de la Luz",
            "Arroyo de las Fraguas",
            "Arroyo de San Serván",
            "Arroyo del Ojanco",
            "Arroyomolinos",
            "Arroyomolinos de la Vera",
            "Arroyomolinos de León",
            "Arruazu",
            "Arrúbal",
            "Arsèguel",
            "Artà",
            "Artajona",
            "Artana",
            "Artazu",
            "Artea",
            "Arteixo",
            "Artenara",
            "Artés",
            "Artesa de Lleida",
            "Artesa de Segre",
            "Artieda",
            "Artzentales",
            "Artziniega",
            "Arucas",
            "Arzúa",
            "As Pontes de García Rodríguez",
            "Ascó",
            "Asín",
            "Aspa",
            "Aspariegos",
            "Asparrena",
            "Aspe",
            "Asteasu",
            "Astigarraga",
            "Astillero El",
            "Astorga",
            "Astudillo",
            "Asturianos",
            "Atajate",
            "Atalaya",
            "Atalaya del Cañavate",
            "Atalaya La",
            "Atamaría",
            "Atanzón",
            "Atapuerca",
            "Ataquines",
            "Atarfe",
            "Atarrabia",
            "Ataun",
            "Atazar El",
            "Atea",
            "Ateca",
            "Atez",
            "Atienza",
            "Atxondo",
            "Atzeneta d'Albaida",
            "Atzeneta dAlbaida",
            "Atzeneta del Maestrat",
            "Aulesti",
            "Auñón",
            "Auritz/Burguete",
            "Ausejo",
            "Ausejo de la Sierra",
            "Ausines Los",
            "Autilla del Pino",
            "Autillo de Campos",
            "Autol",
            "Aveinte",
            "Avellaneda",
            "Avellanes i Santa Linya Les",
            "Avellanosa de Muñó",
            "Avià",
            "Ávila",
            "Avilés",
            "Avinyó",
            "Avinyonet de Puigventós",
            "Avinyonet del Penedès",
            "Avión",
            "Ayala/Aiara",
            "Ayamonte",
            "Ayegui",
            "Ayegui/Aiegi",
            "Ayerbe",
            "Ayllón",
            "Ayna",
            "Ayódar",
            "Ayoó de Vidriales",
            "Ayora",
            "Ayuela",
            "Azagra",
            "Azaila",
            "Azanuy-Alins",
            "Azara",
            "Azkoitia",
            "Azlor",
            "Aznalcázar",
            "Aznalcóllar",
            "Azofra",
            "Azpeitia",
            "Azuaga",
            "Azuara",
            "Azuébar",
            "Azuelo",
            "Azuqueca de Henares",
            "Azután",
            "Babilafuente",
            "Bacares",
            "Badajoz",
            "Badalona",
            "Badarán",
            "Bádenas",
            "Badia del Vallès",
            "Badolatosa",
            "Badules",
            "Baells",
            "Baena",
            "Baeza",
            "Bagà",
            "Báguena",
            "Bagüés",
            "Bahabón",
            "Bahabón de Esgueva",
            "Baides",
            "Bailén",
            "Bailo",
            "Baiona",
            "Baix Pallars",
            "Bakaiku",
            "Bakio",
            "Balaguer",
            "Balazote",
            "Balbases Los",
            "Balboa",
            "Balconchán",
            "Baldellou",
            "Baleira",
            "Balenyà",
            "Baliarrain",
            "Ballestero El",
            "Ballesteros de Calatrava",
            "Ballobar",
            "Balmaseda",
            "Balones",
            "Balsa de Ves",
            "Balsareny",
            "Baltanás",
            "Baltar",
            "Baña A",
            "Bañares",
            "Banastás",
            "Bande",
            "Bañeza La",
            "Bañobárez",
            "Bañón",
            "Baños de Ebro/Mañueta",
            "Baños de la Encina",
            "Baños de Molgas",
            "Baños de Montemayor",
            "Baños de Río Tobía",
            "Baños de Rioja",
            "Baños de Tajo",
            "Baños de Valdearados",
            "Bañuelos",
            "Bañuelos de Bureba",
            "Banyalbufar",
            "Banyeres de Mariola",
            "Banyeres del Penedès",
            "Banyoles",
            "Baquerín de Campos",
            "Barajas de Madrid",
            "Barajas de Melo",
            "Barakaldo",
            "Baralla",
            "Barañáin",
            "Baraona",
            "Barásoain",
            "Barbadás",
            "Barbadillo",
            "Barbadillo de Herreros",
            "Barbadillo del Mercado",
            "Barbadillo del Pez",
            "Barbalos",
            "Barbarin",
            "Barbastro",
            "Barbate",
            "Barbens",
            "Barberà de la Conca",
            "Barberà del Vallès",
            "Bárboles",
            "Barbolla",
            "Barbués",
            "Barbuñales",
            "Barca",
            "Bárcabo",
            "Barcarrota",
            "Barcelona",
            "Bárcena de Campos",
            "Bárcena de Cicero",
            "Bárcena de Pie de Concha",
            "Barceo",
            "Barchín del Hoyo",
            "Barcial de la Loma",
            "Barcial del Barco",
            "Barcience",
            "Barco de Ávila El",
            "Barco de Valdeorras O",
            "Barcones",
            "Bardallur",
            "Bareyo",
            "Bargas",
            "Bargota",
            "Barillas",
            "Barjas",
            "Barlovento",
            "Baronia de Rialb La",
            "Barracas",
            "Barrachina",
            "Barraco",
            "Barraco El",
            "Barrado",
            "Barrax",
            "Barreiros",
            "Barrika",
            "Barrio de la Concepción",
            "Barrio de Muñó",
            "Barriopedro",
            "Barrios de Bureba Los",
            "Barrios de Colina",
            "Barrios de Luna Los",
            "Barrios Los",
            "Barro",
            "Barromán",
            "Barruecopardo",
            "Barruelo de Santullán",
            "Barruelo del Valle",
            "Barrundia",
            "Barx",
            "Barxeta",
            "Basaburua",
            "Basardilla",
            "Basauri",
            "Bàscara",
            "Basconcillos del Tozo",
            "Báscones de Ojeda",
            "Bascuñana",
            "Bascuñana de San Pedro",
            "Bassella",
            "Bastida La",
            "Batea",
            "Baterno",
            "Batres",
            "Bausen",
            "Bayárcal",
            "Bayarque",
            "Bayubas de Abajo",
            "Bayubas de Arriba",
            "Baza",
            "Baztan",
            "Bea",
            "Beade",
            "Beamud",
            "Beariz",
            "Beas",
            "Beas de Granada",
            "Beas de Guadix",
            "Beas de Segura",
            "Beasain",
            "Becedas",
            "Becedillas",
            "Beceite",
            "Becerreá",
            "Becerril de Campos",
            "Becerril de la Sierra",
            "Becilla de Valderaduey",
            "Bédar",
            "Bedia",
            "Bedmar y Garcíez",
            "Begíjar",
            "Begonte",
            "Begues",
            "Begur",
            "Beintza-Labaien",
            "Beire",
            "Beires",
            "Beizama",
            "Béjar",
            "Bejís",
            "Belalcázar",
            "Belascoáin",
            "Belauntza",
            "Belbimbre",
            "Belchite",
            "Beleña",
            "Bèlgida",
            "Belianes",
            "Belinchón",
            "Bell-lloc dUrgell",
            "Bellaguarda",
            "Bellcaire dEmpordà",
            "Bellcaire dUrgell",
            "Bellmunt del Priorat",
            "Bellmunt dUrgell",
            "Bello",
            "Bellprat",
            "Bellpuig",
            "Bellreguard",
            "Bellús",
            "Bellvei",
            "Bellver de Cerdanya",
            "Bellvís",
            "Bélmez",
            "Bélmez de la Moraleda",
            "Belmonte",
            "Belmonte de Campos",
            "Belmonte de Gracián",
            "Belmonte de Miranda",
            "Belmonte de San José",
            "Belmonte de Tajo",
            "Belmontejo",
            "Belorado",
            "Belver de Cinca",
            "Belver de los Montes",
            "Belvis de la Jara",
            "Belvís de Monroy",
            "Bembibre",
            "Benabarre",
            "Benacazón",
            "Benadalid",
            "Benafarces",
            "Benafer",
            "Benafigos",
            "Benagéber",
            "Benaguasil",
            "Benahadux",
            "Benahavís",
            "Benalauría",
            "Benalmádena",
            "Benalúa",
            "Benalúa de las Villas",
            "Benalup-Casas Viejas",
            "Benamargosa",
            "Benamaurel",
            "Benamejí",
            "Benamocarra",
            "Benaocaz",
            "Benaoján",
            "Benarrabá",
            "Benasal",
            "Benasau",
            "Benasque",
            "Benassal",
            "Benatae",
            "Benavarri / Benabarre",
            "Benavent de Segrià",
            "Benavente",
            "Benavides",
            "Benavites",
            "Benegiles",
            "Beneixama",
            "Beneixida",
            "Benejúzar",
            "Benetússer",
            "Benferri",
            "Beniarbeig",
            "Beniardá",
            "Beniarjó",
            "Beniarrés",
            "Beniatjar",
            "Benicarló",
            "Benicasim/Benicàssim",
            "Benicàssim",
            "Benicolet",
            "Benicull de Xúquer",
            "Benidoleig",
            "Benidorm",
            "Beniel",
            "Benifaió",
            "Benifairó de la Valldigna",
            "Benifairó de les Valls",
            "Benifallet",
            "Benifallim",
            "Benifato",
            "Beniflá",
            "Benigànim",
            "Benigembla",
            "Benijofar",
            "Benilloba",
            "Benillup",
            "Benimantell",
            "Benimarfull",
            "Benimassot",
            "Benimeli",
            "Benimodo",
            "Benimuslem",
            "Beniparrell",
            "Benirredrà",
            "Benisanó",
            "Benissa",
            "Benissanet",
            "Benissoda",
            "Benisuera",
            "Benitachell",
            "Benitachell/Poble Nou de Benitatxell el",
            "Benitagla",
            "Benizalón",
            "Benlloch",
            "Benquerencia",
            "Benquerencia de la Serena",
            "Bentarique",
            "Benuza",
            "Bera",
            "Berango",
            "Berantevilla",
            "Beranuy",
            "Berastegi",
            "Beratón",
            "Berbegal",
            "Berberana",
            "Berbinzana",
            "Berceo",
            "Bercero",
            "Berceruelo",
            "Bérchules",
            "Bercial",
            "Bercial de Zapardiel",
            "Bercianos del Páramo",
            "Bercianos del Real Camino",
            "Bercimuel",
            "Berdejo",
            "Berga",
            "Bergara",
            "Bergasa",
            "Bergasillas Bajera",
            "Berge",
            "Bergondo",
            "Beriáin",
            "Berja",
            "Berlanas Las",
            "Berlanga",
            "Berlanga de Duero",
            "Berlanga del Bierzo",
            "Berlangas de Roa",
            "Bermellar",
            "Bermeo",
            "Bermillo de Sayago",
            "Bernardos",
            "Bernedo",
            "Berninches",
            "Bernuy de Porreros",
            "Bernuy-Zapardiel",
            "Berriatua",
            "Berriobeiti",
            "Berrioplano/Berriobeiti",
            "Berriozar",
            "Berriz",
            "Berrobi",
            "Berrocal",
            "Berrocal de Huebra",
            "Berrocal de Salvatierra",
            "Berrocalejo",
            "Berrocalejo de Aragona",
            "Berrueces",
            "Berrueco",
            "Berrueco El",
            "Bertizarana",
            "Berzocana",
            "Berzosa de Bureba",
            "Berzosa del Lozoya",
            "Berzosilla",
            "Besalú",
            "Bescanó",
            "Betancuria",
            "Betanzos",
            "Betelu",
            "Bétera",
            "Beteta",
            "Betxí",
            "Beuda",
            "Bezares",
            "Bezas",
            "Biar",
            "Bicorp",
            "Bidaurreta",
            "Bidegoian",
            "Biel",
            "Bielsa",
            "Bienservida",
            "Bienvenida",
            "Bierge",
            "Biescas",
            "Bigastro",
            "Bigues i Riells",
            "Bijuesca",
            "Bilbao",
            "Bimenes",
            "Binaced",
            "Binéfar",
            "Binissalem",
            "Biosca",
            "Biota",
            "Bisaurri",
            "Bisbal de Falset",
            "Bisbal del Penedès",
            "Bisbal dEmpordà La",
            "Biscarrués",
            "Bisimbre",
            "Biure",
            "Biurrun-Olcoz",
            "Blacos",
            "Blanca",
            "Blancafort",
            "Blancas",
            "Blancos Os",
            "Blanes",
            "Blascomillán",
            "Blasconuño de Matacabras",
            "Blascosancho",
            "Blázquez Los",
            "Blecua y Torres",
            "Blesa",
            "Bliecos",
            "Boada",
            "Boada de Campos",
            "Boadella i les Escaules",
            "Boadilla de Rioseco",
            "Boadilla del Camino",
            "Boadilla del Monte",
            "Boal",
            "Boalo",
            "Boalo El",
            "Bobadilla",
            "Bobadilla del Campo",
            "Boborás",
            "Boca de Huérgano",
            "Bocairent",
            "Boceguillas",
            "Bocigas",
            "Bocos de Duero",
            "Bodera La",
            "Bodón El",
            "Bodonal de la Sierra",
            "Boecillo",
            "Bogajo",
            "Bogarra",
            "Bohodón El",
            "Bohonal de Ibor",
            "Bohoyo",
            "Boimorto",
            "Boiro",
            "Bola A",
            "Bolaños de Calatrava",
            "Bolaños de Campos",
            "Bolbaite",
            "Bollullos de la Mitación",
            "Bollullos par del Condado",
            "Bolo O",
            "Boltaña",
            "Bolulla",
            "Bolvir",
            "Bonansa",
            "Boñar",
            "Bonares",
            "Bonastre",
            "Bonete",
            "Boniches",
            "Bonilla de la Sierra",
            "Bonillo El",
            "Bonrepòs i Mirambell",
            "Boqueixón",
            "Boquiñeni",
            "Borau",
            "Bordalba",
            "Bòrdes Es",
            "Bordils",
            "Bordón",
            "Borge El",
            "Borges Blanques Les",
            "Borges del Camp",
            "Borja",
            "Borjabad",
            "Bormujos",
            "Bornos",
            "Borobia",
            "Borox",
            "Borrassà",
            "Borredà",
            "Borrenes",
            "Borriana/Burriana",
            "Borriol",
            "Bosque El",
            "Bossòst",
            "Bot",
            "Botarell",
            "Botija",
            "Botorrita",
            "Bouza La",
            "Bóveda",
            "Bóveda de Toro La",
            "Bóveda del Río Almar",
            "Bovera",
            "Bozoó",
            "Brabos",
            "Bràfim",
            "Brahojos de Medina",
            "Brañosera",
            "Braojos",
            "Brazacorta",
            "Brazatortas",
            "Brazuelo",
            "Brea de Aragón",
            "Brea de Tajo",
            "Breda",
            "Breña Alta",
            "Breña Baja",
            "Brenes",
            "Bretó",
            "Bretocino",
            "Brieva",
            "Brieva de Cameros",
            "Brihuega",
            "Brime de Sog",
            "Brime de Urz",
            "Briñas",
            "Brincones",
            "Brión",
            "Briones",
            "Briviesca",
            "Bronchales",
            "Broto",
            "Brozas",
            "Bruc El",
            "Brull El",
            "Brunete",
            "Brunyola",
            "Buberos",
            "Bubierca",
            "Bubión",
            "Buciegas",
            "Budia",
            "Bueña",
            "Buenache de Alarcón",
            "Buenache de la Sierra",
            "Buenamadre",
            "Buenaventura",
            "Buenavista",
            "Buenavista de Valdavia",
            "Buenavista del Norte",
            "Buendía",
            "Bueu",
            "Bufali",
            "Bugarra",
            "Bugedo",
            "Búger",
            "Buitrago",
            "Buitrago del Lozoya",
            "Bujalance",
            "Bujalaro",
            "Bujaraloz",
            "Bularros",
            "Bulbuente",
            "Bullas",
            "Buniel",
            "Buñol",
            "Buñuel",
            "Bunyola",
            "Burbáguena",
            "Burela",
            "Bureta",
            "Burganes de Valverde",
            "Burgo de Ebro El",
            "Burgo de Osma-Ciudad de Osma",
            "Burgo El",
            "Burgo Ranero El",
            "Burgohondo",
            "Burgos",
            "Burgui/Burgi",
            "Burguillos",
            "Burguillos de Toledo",
            "Burguillos del Cerro",
            "Burjassot",
            "Burlada/Burlata",
            "Burlata",
            "Burón",
            "Burriana",
            "Burujón",
            "Busot",
            "Busquístar",
            "Bustares",
            "Bustarviejo",
            "Buste El",
            "Bustillo de Chaves",
            "Bustillo de la Vega",
            "Bustillo del Oro",
            "Bustillo del Páramo",
            "Bustillo del Páramo de Carrión",
            "Busto de Bureba",
            "Busto El",
            "Busturia",
            "Cabacés",
            "Cabaco El",
            "Caballar",
            "Cabana de Bergantiños",
            "Cabanabona",
            "Cabanas",
            "Cabañas de Ebro",
            "Cabañas de la Sagra",
            "Cabañas de Polendos",
            "Cabañas de Sayago",
            "Cabañas de Yepes",
            "Cabañas del Castillo",
            "Cabañas Raras",
            "Cabanelles",
            "Cabanes",
            "Cabañes de Esgueva",
            "Cabanillas",
            "Cabanillas de la Sierra",
            "Cabanillas del Campo",
            "Cabanyes Les",
            "Cabeza de Béjar La",
            "Cabeza del Buey",
            "Cabeza del Caballo",
            "Cabeza la Vaca",
            "Cabezabellosa",
            "Cabezabellosa de la Calzada",
            "Cabezamesada",
            "Cabezarados",
            "Cabezarrubias del Puerto",
            "Cabezas de Alambre",
            "Cabezas de San Juan Las",
            "Cabezas del Pozo",
            "Cabezas del Villar",
            "Cabezas Rubias",
            "Cabezón",
            "Cabezón de Cameros",
            "Cabezón de la Sal",
            "Cabezón de la Sierra",
            "Cabezón de Liébana",
            "Cabezón de Pisuerga",
            "Cabezón de Valderaduey",
            "Cabezuela",
            "Cabezuela del Valle",
            "Cabizuela",
            "Cabó",
            "Cabolafuente",
            "Cabra",
            "Cabra de Mora",
            "Cabra del Camp",
            "Cabra del Santo Cristo",
            "Cabrales",
            "Cabranes",
            "Cabredo",
            "Cabrejas del Campo",
            "Cabrejas del Pinar",
            "Cabrera dAnoia",
            "Cabrera de Mar",
            "Cabrera La",
            "Cabrerizos",
            "Cabrero",
            "Cabreros del Monte",
            "Cabreros del Río",
            "Cabrillanes",
            "Cabrillas",
            "Cabrils",
            "Cabuérniga",
            "Cacabelos",
            "Cáceres",
            "Cachorrilla",
            "Cacín",
            "Cadalso",
            "Cadalso de los Vidrios",
            "Cadaqués",
            "Cádiar",
            "Cadiz",
            "Cadreita",
            "Cadrete",
            "Cájar",
            "Cala",
            "Cala d'Or",
            "Cala Rajada",
            "Calabazas de Fuentidueña",
            "Calaceite",
            "Calaf",
            "Calafell",
            "Calahorra",
            "Calahorra de Boedo",
            "Calahorra La",
            "Calamocha",
            "Calamonte",
            "Calañas",
            "Calanda",
            "Calasparra",
            "Calatañazor",
            "Calatayud",
            "Calatorao",
            "Calcena",
            "Caldas de Reis",
            "Caldearenas",
            "Calders",
            "Caldes de Malavella",
            "Caldes de Montbui",
            "Caldes dEstrac",
            "Calella",
            "Calera de León",
            "Calera y Chozas",
            "Caleruega",
            "Caleruela",
            "Calicasas",
            "Càlig",
            "Calldetenes",
            "Calles",
            "Callosa de Segura",
            "Callosa dEn Sarrià",
            "Callús",
            "Calmarza",
            "Calomarde",
            "Calonge",
            "Calonge",
            "Calonge de Segarra",
            "Calp",
            "Caltojar",
            "Calvarrasa de Abajo",
            "Calvarrasa de Arriba",
            "Calvià",
            "Calvos de Randín",
            "Calzada de Béjar La",
            "Calzada de Calatrava",
            "Calzada de Don Diego",
            "Calzada de los Molinos",
            "Calzada de Oropesa",
            "Calzada de Valdunciel",
            "Calzada del Coto",
            "Calzadilla",
            "Calzadilla de los Barros",
            "Calzadilla de Tera",
            "Camaleño",
            "Camañas",
            "Camarasa",
            "Camarena",
            "Camarena de la Sierra",
            "Camarenilla",
            "Camargo",
            "Camarillas",
            "Camariñas",
            "Camarles",
            "Camarma de Esteruelas",
            "Camarzana de Tera",
            "Camas",
            "Cambados",
            "Cambil",
            "Cambre",
            "Cambrils",
            "Caminomorisco",
            "Caminreal",
            "Camós",
            "Camp de Mar",
            "Campana La",
            "Campanario",
            "Campanet",
            "Campaspero",
            "Campazas",
            "Campdevànol",
            "Campelles",
            "Campello el",
            "Campezo/Kanpezu",
            "Campillo de Altobuey",
            "Campillo de Aragón",
            "Campillo de Aranda",
            "Campillo de Arenas",
            "Campillo de Azaba",
            "Campillo de Deleitosa",
            "Campillo de Dueñas",
            "Campillo de la Jara El",
            "Campillo de Llerena",
            "Campillo de Ranas",
            "Campillo El",
            "Campillo El",
            "Campillos",
            "Campillos-Paravientos",
            "Campillos-Sierra",
            "Campins",
            "Campisábalos",
            "Campllong",
            "Campo",
            "Campo de Criptana",
            "Campo de Mirra/Camp de Mirra el",
            "Campo de Peñaranda El",
            "Campo de San Pedro",
            "Campo de Villavidel",
            "Campo Lameiro",
            "Campo Lugar",
            "Campo Real",
            "Campofrío",
            "Campolara",
            "Camponaraya",
            "Campoo de Enmedio",
            "Campoo de Yuso",
            "Camporredondo",
            "Camporrells",
            "Camporrobles",
            "Campos",
            "Campos del Paraíso",
            "Campos del Río",
            "Campotéjar",
            "Camprodon",
            "Camprovín",
            "Camuñas",
            "Can Pastilla",
            "Can Picafort",
            "Cañada",
            "Cañada de Benatanduz",
            "Cañada de Calatrava",
            "Cañada de Verich La",
            "Cañada del Hoyo",
            "Cañada Juncosa",
            "Cañada Rosal",
            "Cañada Vellida",
            "Canal de Berdún",
            "Canalejas de Peñafiel",
            "Canalejas del Arroyo",
            "Canales",
            "Canales de la Sierra",
            "Canals",
            "Cañamaque",
            "Cañamares",
            "Cañamero",
            "Cáñar",
            "Cañas",
            "Cañavate El",
            "Cañaveral",
            "Cañaveral de León",
            "Cañaveras",
            "Cañaveruelas",
            "Candamo",
            "Candasnos",
            "Candelaria",
            "Candelario",
            "Candeleda",
            "Candilichera",
            "Candín",
            "Canejan",
            "Canena",
            "Canencia",
            "Canet d'En Berenguer",
            "Canet dAdri",
            "Canet de Mar",
            "Canet dEn Berenguer",
            "Canet lo Roig",
            "Cañete",
            "Cañete de las Torres",
            "Cañete la Real",
            "Canfranc",
            "Cangas",
            "Cangas de Onís",
            "Cangas del Narcea",
            "Canicosa de la Sierra",
            "Caniles",
            "Canillas de Abajo",
            "Canillas de Aceituno",
            "Canillas de Albaida",
            "Canillas de Esgueva",
            "Canillas de Río Tuerto",
            "Cañiza A",
            "Cañizal",
            "Cañizar",
            "Cañizar del Olivar",
            "Cañizares",
            "Cañizo",
            "Canjáyar",
            "Canonja La",
            "Canovelles",
            "Cànoves i Samalús",
            "Canredondo",
            "Cantabrana",
            "Cantagallo",
            "Cantalapiedra",
            "Cantalejo",
            "Cantallops",
            "Cantalojas",
            "Cantalpino",
            "Cantaracillo",
            "Cantavieja",
            "Cantillana",
            "Cantimpalos",
            "Cantiveros",
            "Cantoria",
            "Canyelles",
            "Capafonts",
            "Caparroso",
            "Capçanes",
            "Capdepera",
            "Capdesaso",
            "Capela A",
            "Capella",
            "Capellades",
            "Capileira",
            "Capilla",
            "Capillas",
            "Capmany",
            "Capolat",
            "Carabaña",
            "Carabanchel",
            "Carabantes",
            "Caracena",
            "Caracuel de Calatrava",
            "Carataunas",
            "Caravaca",
            "Caravaca de la Cruz",
            "Caravia",
            "Carazo",
            "Carbajales de Alba",
            "Carbajo",
            "Carbajosa de la Sagrada",
            "Carballeda de Avia",
            "Carballeda de Valdeorras",
            "Carballedo",
            "Carballiño O",
            "Carballo",
            "Carbellino",
            "Carboneras",
            "Carboneras de Guadazaón",
            "Carbonero el Mayor",
            "Carboneros",
            "Carcaboso",
            "Carcabuey",
            "Carcaixent",
            "Cárcar",
            "Carcastillo",
            "Carcedo de Bureba",
            "Carcedo de Burgos",
            "Carcelén",
            "Càrcer",
            "Cárcheles",
            "Cardedeu",
            "Cardeña",
            "Cardeñadijo",
            "Cardeñajimeno",
            "Cárdenas",
            "Cardenete",
            "Cardeñosa",
            "Cardeñosa de Volpejera",
            "Cardeñuela Riopico",
            "Cardiel de los Montes",
            "Cardona",
            "Cardoso de la Sierra El",
            "Carenas",
            "Cariñena",
            "Cariño",
            "Carlet",
            "Carlota La",
            "Carme",
            "Carmena",
            "Cármenes",
            "Carmona",
            "Carmonita",
            "Carnota",
            "Carolina La",
            "Carpio",
            "Carpio de Azaba",
            "Carpio de Tajo El",
            "Carpio El",
            "Carracedelo",
            "Carral",
            "Carranque",
            "Carrascal de Barregas",
            "Carrascal del Obispo",
            "Carrascal del Río",
            "Carrascalejo",
            "Carrascalejo El",
            "Carrascosa",
            "Carrascosa de Abajo",
            "Carrascosa de Haro",
            "Carrascosa de la Sierra",
            "Carratraca",
            "Carreño",
            "Carrera La",
            "Carrias",
            "Carriches",
            "Carrícola",
            "Carrión de Calatrava",
            "Carrión de los Céspedes",
            "Carrión de los Condes",
            "Carrizal",
            "Carrizo",
            "Carrizo de la Ribera",
            "Carrizosa",
            "Carrocera",
            "Cartagena",
            "Cartajima",
            "Cártama",
            "Cartaya",
            "Cartelle",
            "Cartes",
            "Carucedo",
            "Casa de Uceda",
            "Casabermeja",
            "Casafranca",
            "Casalarreina",
            "Casar de Cáceres",
            "Casar de Escalona El",
            "Casar de Palomero",
            "Casar El",
            "Casarabonela",
            "Casarejos",
            "Casares",
            "Casares de las Hurdes",
            "Casariche",
            "Casarrubios del Monte",
            "Casarrubuelos",
            "Casas Altas",
            "Casas Bajas",
            "Casas de Benítez",
            "Casas de Don Antonio",
            "Casas de Don Gómez",
            "Casas de Don Pedro",
            "Casas de Fernando Alonso",
            "Casas de Garcimolina",
            "Casas de Guijarro",
            "Casas de Haro",
            "Casas de Juan Núñez",
            "Casas de Lázaro",
            "Casas de los Pinos",
            "Casas de Millán",
            "Casas de Miravete",
            "Casas de Reina",
            "Casas de San Galindo",
            "Casas de Ves",
            "Casas del Castañar",
            "Casas del Conde Las",
            "Casas del Monte",
            "Casas del Puerto",
            "Casas-Ibáñez",
            "Casasbuenas",
            "Casaseca de Campeán",
            "Casaseca de las Chanas",
            "Casasimarro",
            "Casasola",
            "Casasola de Arión",
            "Casatejada",
            "Casavieja",
            "Casbas de Huesca",
            "Cascajares de Bureba",
            "Cascajares de la Sierra",
            "Cascante",
            "Cascante del Río",
            "Cáseda",
            "Caseres",
            "Casillas",
            "Casillas de Coria",
            "Casillas de Flores",
            "Casinos",
            "Casla",
            "Caso",
            "Caspe",
            "Caspueñas",
            "Cassà de la Selva",
            "Casserres",
            "Castalla",
            "Castañar de Ibor",
            "Castañares de Rioja",
            "Castañeda",
            "Castaño del Robledo",
            "Cástaras",
            "Castejón",
            "Castejón de Alarba",
            "Castejón de Henares",
            "Castejón de las Armas",
            "Castejón de Monegros",
            "Castejón de Sos",
            "Castejón de Tornos",
            "Castejón de Valdejasa",
            "Castejón del Puente",
            "Castel de Cabra",
            "Castelflorite",
            "Castell de Cabres",
            "Castell de Castells",
            "Castell de Guadalest el",
            "Castell de lAreny",
            "Castell de Mur",
            "Castell Es",
            "Castell-Platja dAro",
            "Castellanos de Castro",
            "Castellanos de Moriscos",
            "Castellanos de Villiquera",
            "Castellanos de Zapardiel",
            "Castellar",
            "Castellar de la Frontera",
            "Castellar de la Muela",
            "Castellar de la Ribera",
            "Castellar de nHug",
            "Castellar de Santiago",
            "Castellar del Riu",
            "Castellar del Vallès",
            "Castellar El",
            "Castellbell i el Vilar",
            "Castellbisbal",
            "Castellcir",
            "Castelldans",
            "Castelldefels",
            "Castellet i la Gornal",
            "Castellfollit de la Roca",
            "Castellfollit de Riubregós",
            "Castellfollit del Boix",
            "Castellfort",
            "Castellgalí",
            "Castellnou de Bages",
            "Castellnou de Seana",
            "Castellnovo",
            "Castelló de Farfanya",
            "Castelló de la Plana",
            "Castelló de Rugat",
            "Castelló dEmpúries",
            "Castellolí",
            "Castellón de la Plana/Castelló de la Plana",
            "Castellonet de la Conquesta",
            "Castellote",
            "Castellserà",
            "Castellterçol",
            "Castellvell del Camp",
            "Castellví de la Marca",
            "Castellví de Rosanes",
            "Castelnou",
            "Castelserás",
            "Castielfabib",
            "Castiello de Jaca",
            "Castigaleu",
            "Castil de Peones",
            "Castil de Vela",
            "Castilblanco",
            "Castilblanco de los Arroyos",
            "Castildelgado",
            "Castilfalé",
            "Castilforte",
            "Castilfrío de la Sierra",
            "Castiliscar",
            "Castillazuelo",
            "Castilleja de Guzmán",
            "Castilleja de la Cuesta",
            "Castilleja del Campo",
            "Castilléjar",
            "Castillejo de Iniesta",
            "Castillejo de Martín Viejo",
            "Castillejo de Mesleón",
            "Castillejo de Robledo",
            "Castillejo-Sierra",
            "Castillo de Bayuela",
            "Castillo de Garcimuñoz",
            "Castillo de las Guardas El",
            "Castillo de Locubín",
            "Castillo de Villamalefa",
            "Castillo-Albaráñez",
            "Castillonroy",
            "Castillonuevo",
            "Castilnuevo",
            "Castilruiz",
            "Castraz",
            "Castrejón de la Peña",
            "Castrejón de Trabancos",
            "Castrelo de Miño",
            "Castrelo do Val",
            "Castril",
            "Castrillo de Cabrera",
            "Castrillo de Don Juan",
            "Castrillo de Duero",
            "Castrillo de la Guareña",
            "Castrillo de la Reina",
            "Castrillo de la Valduerna",
            "Castrillo de la Vega",
            "Castrillo de Onielo",
            "Castrillo de Riopisuerga",
            "Castrillo de Villavega",
            "Castrillo del Val",
            "Castrillo Matajudíos",
            "Castrillo-Tejeriego",
            "Castrillón",
            "Castro Caldelas",
            "Castro de Filabres",
            "Castro de Fuentidueña",
            "Castro de Rei",
            "Castro del Río",
            "Castro-Urdiales",
            "Castrobol",
            "Castrocalbón",
            "Castrocontrigo",
            "Castrodeza",
            "Castrogonzalo",
            "Castrojeriz",
            "Castrojimeno",
            "Castromembibre",
            "Castromocho",
            "Castromonte",
            "Castronuevo",
            "Castronuevo de Esgueva",
            "Castronuño",
            "Castropodame",
            "Castropol",
            "Castroponce",
            "Castroserna de Abajo",
            "Castroserracín",
            "Castrotierra de Valmadrigal",
            "Castroverde",
            "Castroverde de Campos",
            "Castroverde de Cerrato",
            "Castroviejo",
            "Castuera",
            "Catadau",
            "Catarroja",
            "Catí",
            "Catllar",
            "Catoira",
            "Catral",
            "Caudete",
            "Caudete de las Fuentes",
            "Caudiel",
            "Cava",
            "Cavia",
            "Cayuela",
            "Cazalegas",
            "Cazalilla",
            "Cazalla de la Sierra",
            "Cazorla",
            "Cazurra",
            "Cea",
            "Cebanico",
            "Cebolla",
            "Cebrecos",
            "Cebreros",
            "Cebrones del Río",
            "Ceclavín",
            "Cedeira",
            "Cedillo",
            "Cedillo de la Torre",
            "Cedillo del Condado",
            "Cedrillas",
            "Cee",
            "Cehegín",
            "Ceinos de Campos",
            "Celada del Camino",
            "Celadas",
            "Celanova",
            "Cella",
            "Cellera de Ter La",
            "Cellorigo",
            "Celrà",
            "Cendea de Olza/Oltza Zendea",
            "Cendejas de Enmedio",
            "Cendejas de la Torre",
            "Cenes de la Vega",
            "Cenicero",
            "Cenicientos",
            "Cenizate",
            "Cenlle",
            "Centelles",
            "Centenera",
            "Centenera de Andaluz",
            "Cepeda",
            "Cepeda la Mora",
            "Cerbón",
            "Cerceda",
            "Cercedilla",
            "Cercs",
            "Cerdà",
            "Cerdanyola del Vallès",
            "Cerdedo",
            "Cerdido",
            "Cereceda de la Sierra",
            "Cerecinos de Campos",
            "Cerecinos del Carrizal",
            "Cerezal de Peñahorcada",
            "Cerezo",
            "Cerezo de Abajo",
            "Cerezo de Arriba",
            "Cerezo de Río Tirón",
            "Cernadilla",
            "Cerollera La",
            "Cerralbo",
            "Cerralbos Los",
            "Cerratón de Juarros",
            "Cerro de Andévalo El",
            "Cerro El",
            "Cervantes",
            "Cervatos de la Cueza",
            "Cervelló",
            "Cervera",
            "Cervera de Buitrago",
            "Cervera de la Cañada",
            "Cervera de los Montes",
            "Cervera de Pisuerga",
            "Cervera del Llano",
            "Cervera del Maestre",
            "Cervera del Río Alhama",
            "Cerveruela",
            "Cervià de les Garrigues",
            "Cervià de Ter",
            "Cervillego de la Cruz",
            "Cervo",
            "Cespedosa de Tormes",
            "Cesuras",
            "Cetina",
            "Ceuti",
            "Cevico de la Torre",
            "Cevico Navero",
            "Chagarcía Medianero",
            "Chalamera",
            "Chamartín",
            "Chamartín",
            "Chamberí",
            "Chandrexa de Queixa",
            "Chañe",
            "Chantada",
            "Chapinería",
            "Chauchina",
            "Checa",
            "Cheles",
            "Chella",
            "Chelva",
            "Chequilla",
            "Chera",
            "Chercos",
            "Chert/Xert",
            "Cheste",
            "Chía",
            "Chiclana de la Frontera",
            "Chiclana de Segura",
            "Chilches",
            "Chilches/Xilxes",
            "Chillarón de Cuenca",
            "Chillarón del Rey",
            "Chillón",
            "Chilluévar",
            "Chiloeches",
            "Chimeneas",
            "Chimillas",
            "Chinchilla de Monte-Aragón",
            "Chinchón",
            "Chipiona",
            "Chiprana",
            "Chirivel",
            "Chiva",
            "Chodes",
            "Chodos/Xodos",
            "Chóvar",
            "Chozas de Abajo",
            "Chozas de Canales",
            "Chucena",
            "Chueca",
            "Chulilla",
            "Chumillas",
            "Churriana de la Vega",
            "Ciadoncha",
            "Cidamón",
            "Cidones",
            "Ciempozuelos",
            "Cierva La",
            "Cieza",
            "Cifuentes",
            "Cigales",
            "Cigudosa",
            "Ciguñuela",
            "Cihuela",
            "Cihuri",
            "Cijuela",
            "Cillán",
            "Cillaperlata",
            "Cilleros",
            "Cilleros de la Bastida",
            "Cilleruelo de Abajo",
            "Cilleruelo de Arriba",
            "Cilleruelo de San Mamés",
            "Cillorigo de Liébana",
            "Cimanes de la Vega",
            "Cimanes del Tejar",
            "Cimballa",
            "Cinco Olivas",
            "Cincovillas",
            "Cinctorres",
            "Cintruénigo",
            "Cipérez",
            "Cirat",
            "Cirauqui",
            "Cirauqui/Zirauki",
            "Ciria",
            "Ciriza",
            "Ciriza/Ziritza",
            "Ciruelas",
            "Ciruelos",
            "Ciruelos de Cervera",
            "Ciruelos del Pinar",
            "Cirueña",
            "Cirujales del Río",
            "Cisla",
            "Cisneros",
            "Cistella",
            "Cistérniga",
            "Cistierna",
            "City Center",
            "Ciudad Lineal",
            "Ciudad Real",
            "Ciudad Rodrigo",
            "Ciutadella",
            "Ciutadella de Menorca",
            "Ciutadilla",
            "Cizur",
            "Cizur Mayor",
            "Clarés de Ribota",
            "Clariana de Cardener",
            "Clavijo",
            "Coaña",
            "Cobatillas",
            "Cóbdar",
            "Cobeja",
            "Cobeña",
            "Cobeta",
            "Cobisa",
            "Cobos de Cerrato",
            "Cobos de Fuentidueña",
            "Cobreros",
            "Coca",
            "Coca de Alba",
            "Cocentaina",
            "Codo",
            "Codoñera La",
            "Codorniz",
            "Codos",
            "Codosera La",
            "Cofrentes",
            "Cogeces de Íscar",
            "Cogeces del Monte",
            "Cogollor",
            "Cogollos",
            "Cogollos de Guadix",
            "Cogollos de la Vega",
            "Cogolludo",
            "Cogul El",
            "Coín",
            "Coirós",
            "Colera",
            "Coles",
            "Colilla La",
            "Colindres",
            "Coll d'en Rabassa",
            "Coll de Nargó",
            "Collado",
            "Collado de Contreras",
            "Collado del Mirón",
            "Collado Hermoso",
            "Collado Mediano",
            "Collado Villalba",
            "Collado-Villalba",
            "Collazos de Boedo",
            "Collbató",
            "Colldejou",
            "Collsuspina",
            "Colmenar",
            "Colmenar de Montemayor",
            "Colmenar de Oreja",
            "Colmenar del Arroyo",
            "Colmenar Viejo",
            "Colmenarejo",
            "Colomera",
            "Colomers",
            "Colònia de Sant Jordi",
            "Colunga",
            "Colungo",
            "Coma i la Pedra La",
            "Comares",
            "Comillas",
            "Cómpeta",
            "Conca de Dalt",
            "Condado de Castilnovo",
            "Condado de Treviño",
            "Condemios de Abajo",
            "Condemios de Arriba",
            "Conesa",
            "Confrides",
            "Congosto",
            "Congosto de Valdavia",
            "Congostrina",
            "Conil de la Frontera",
            "Conquista",
            "Conquista de la Sierra",
            "Consell",
            "Constantí",
            "Constantina",
            "Constanzana",
            "Consuegra",
            "Contamina",
            "Contreras",
            "Coomonte",
            "Copernal",
            "Copons",
            "Corbalán",
            "Corbera",
            "Corbera de Llobregat",
            "Corbera dEbre",
            "Corbillos de los Oteros",
            "Corbins",
            "Corçà",
            "Corcos",
            "Corcubión",
            "Córdoba",
            "Cordobilla de Lácara",
            "Cordovilla",
            "Cordovilla la Real",
            "Cordovín",
            "Corduente",
            "Corella",
            "Corera",
            "Coreses",
            "Corgo O",
            "Coria",
            "Coria del Río",
            "Coripe",
            "Coristanco",
            "Cornago",
            "Cornellà de Llobregat",
            "Cornellà del Terri",
            "Cornudella de Montsant",
            "Coronada La",
            "Coronil El",
            "Corpa",
            "Corporales",
            "Corral de Almaguer",
            "Corral de Ayllón",
            "Corral de Calatrava",
            "Corral-Rubio",
            "Corralejo",
            "Corrales",
            "Corrales de Buelna Los",
            "Corrales de Duero",
            "Corrales del Vino",
            "Corrales Los",
            "Corte de Peleas",
            "Corteconcepción",
            "Cortegada",
            "Cortegana",
            "Cortelazor",
            "Cortes",
            "Cortes de Aragón",
            "Cortes de Arenoso",
            "Cortes de Baza",
            "Cortes de la Frontera",
            "Cortes de Pallás",
            "Cortes y Graena",
            "Cortijos Los",
            "Corullón",
            "Coruña A",
            "Coruña del Conde",
            "Corvera de Asturias",
            "Corvera de Toranzo",
            "Cosa",
            "Coscurita",
            "Coslada",
            "Cospeito",
            "Costa Calma",
            "Costa Teguise",
            "Costitx",
            "Costur",
            "Cosuenda",
            "Cotanes del Monte",
            "Cotes",
            "Cotillas",
            "Cotobade",
            "Covaleda",
            "Covarrubias",
            "Covelo",
            "Coves de Vinromà les",
            "Cox",
            "Cózar",
            "Cozuelos de Fuentidueña",
            "Crecente",
            "Creixell",
            "Crémenes",
            "Crespià",
            "Crespos",
            "Cretas",
            "Crevillent",
            "Crevillente",
            "Cristina",
            "Cristóbal",
            "Crivillén",
            "Cruce de Arinaga",
            "Cruïlles  Monells i Sant Sadurní de lHeura",
            "Cuacos de Yuste",
            "Cuadros",
            "Cualedro",
            "Cuarte de Huerva",
            "Cuba La",
            "Cubas",
            "Cubas de la Sagra",
            "Cubel",
            "Cubelles",
            "Cubells",
            "Cubilla",
            "Cubillas de Cerrato",
            "Cubillas de los Oteros",
            "Cubillas de Rueda",
            "Cubillas de Santa Marta",
            "Cubillo",
            "Cubillo de Uceda El",
            "Cubillo del Campo",
            "Cubillos",
            "Cubillos del Sil",
            "Cubla",
            "Cubo de Benavente",
            "Cubo de Bureba",
            "Cubo de Don Sancho El",
            "Cubo de la Solana",
            "Cubo de Tierra del Vino El",
            "Cucalón",
            "Cudillero",
            "Cuelgamures",
            "Cuéllar",
            "Cuenca",
            "Cuenca de Campos",
            "Cuerlas Las",
            "Cuerva",
            "Cuervo de Sevilla El",
            "Cuervo El",
            "Cueva de Ágreda",
            "Cueva de Roa La",
            "Cueva del Hierro",
            "Cuevas Bajas",
            "Cuevas de Almudén",
            "Cuevas de Provanco",
            "Cuevas de San Clemente",
            "Cuevas de San Marcos",
            "Cuevas de Vinromá",
            "Cuevas del Almanzora",
            "Cuevas del Becerro",
            "Cuevas del Campo",
            "Cuevas del Valle",
            "Cuevas Labradas",
            "Culla",
            "Cúllar",
            "Cúllar Vega",
            "Cullera",
            "Culleredo",
            "Cumbre La",
            "Cumbres de Enmedio",
            "Cumbres de San Bartolomé",
            "Cumbres Mayores",
            "Cunit",
            "Cuntis",
            "Curiel de Duero",
            "Curtis",
            "Cútar",
            "Cuzcurrita de Río Tirón",
            "Daganzo de Arriba",
            "Daimiel",
            "Daimús",
            "Dalías",
            "Darnius",
            "Daroca",
            "Daroca de Rioja",
            "Darro",
            "Das",
            "Daya Nueva",
            "Daya Vieja",
            "Deba",
            "Degaña",
            "Dehesa de Montejo",
            "Dehesa de Romanos",
            "Dehesas de Guadix",
            "Deià",
            "Deifontes",
            "Deleitosa",
            "Delicias",
            "Deltebre",
            "Denia",
            "Derio",
            "Descargamaría",
            "Desojo",
            "Destriana",
            "Dévanos",
            "Deyá",
            "Deza",
            "Dicastillo",
            "Diego del Carpio",
            "Diezma",
            "Dílar",
            "Dima",
            "Dios le Guarde",
            "Dodro",
            "Dólar",
            "Dolores",
            "Domeño",
            "Domingo García",
            "Domingo Pérez",
            "Don Álvaro",
            "Don Benito",
            "Doña Mencía",
            "Donamaria",
            "Doneztebe",
            "Doneztebe/Santesteban",
            "Donhierro",
            "Doñinos de Ledesma",
            "Doñinos de Salamanca",
            "Donjimeno",
            "Donostia-San Sebastián",
            "Donvidas",
            "Dos Aguas",
            "Dos Hermanas",
            "Dos Torres",
            "Dosbarrios",
            "Dosrius",
            "Dozón",
            "Driebes",
            "Dúdar",
            "Dueñas",
            "Duesaigües",
            "Dumbría",
            "Durango",
            "Dúrcal",
            "Durón",
            "Duruelo",
            "Duruelo de la Sierra",
            "Ea",
            "Echarri",
            "Echarri-Aranaz",
            "Echo",
            "Écija",
            "Egüés",
            "Eibar",
            "Eivissa",
            "Ejea de los Caballeros",
            "Ejeme",
            "Ejido El",
            "Ejulve",
            "El Álamo",
            "El Astillero",
            "El Barco de Ávila",
            "El Burgo de Ebro",
            "El Burgo de Osma",
            "El Campello",
            "El Castellar",
            "El Cotillo",
            "El Escorial",
            "El Grao",
            "El Hoyo de Pinares",
            "El Molar",
            "El Pardo",
            "El Paso",
            "El Perelló",
            "El Plan",
            "El Tiemblo",
            "El Toro",
            "el Torricó / Altorricon",
            "El Vellón",
            "Elantxobe",
            "Elburgo/Burgelu",
            "Elche",
            "Elche de la Sierra",
            "Elche/Elx",
            "Elciego",
            "Elda",
            "Elduain",
            "Elgeta",
            "Elgoibar",
            "Elgorriaga",
            "Eliana l",
            "Eljas",
            "Elorrio",
            "Elorz",
            "els Poblets",
            "Elvillar/Bilar",
            "Embid",
            "Embid de Ariza",
            "Emperador",
            "Encina de San Silvestre",
            "Encina La",
            "Encinacorba",
            "Encinas",
            "Encinas de Abajo",
            "Encinas de Arriba",
            "Encinas de Esgueva",
            "Encinas Reales",
            "Encinasola",
            "Encinasola de los Comendadores",
            "Encinedo",
            "Encinillas",
            "Encío",
            "Enciso",
            "Endrinal",
            "Enériz",
            "Enériz/Eneritz",
            "Enguera",
            "Enguídanos",
            "Enix",
            "Ènova l",
            "Entrala",
            "Entrambasaguas",
            "Entrena",
            "Entrimo",
            "Entrín Bajo",
            "Épila",
            "Erandio",
            "Eratsun",
            "Ercina La",
            "Ereño",
            "Ergoiena",
            "Erla",
            "Ermitagaña",
            "Ermua",
            "Errenteria",
            "Errezil",
            "Erriberagoitia/Ribera Alta",
            "Errigoiti",
            "Erro",
            "Erustes",
            "Es Castell",
            "Es Molinar",
            "Escacena del Campo",
            "Escala L",
            "Escalante",
            "Escalona",
            "Escalona del Prado",
            "Escalonilla",
            "Escamilla",
            "Escañuela",
            "Escarabajosa de Cabezas",
            "Escariche",
            "Escatrón",
            "Escobar de Campos",
            "Escobar de Polendos",
            "Escobosa de Almazán",
            "Escopete",
            "Escorca",
            "Escorial El",
            "Escorihuela",
            "Escucha",
            "Escurial",
            "Escurial de la Sierra",
            "Escúzar",
            "Esgos",
            "Esguevillas de Esgueva",
            "Eskoriatza",
            "Eslava",
            "Eslida",
            "Espadaña",
            "Espadañedo",
            "Espadilla",
            "Esparragal",
            "Esparragalejo",
            "Esparragosa de la Serena",
            "Esparragosa de Lares",
            "Esparreguera",
            "Espartinas",
            "Esparza de Salazar/Espartza Zaraitzu",
            "Espeja",
            "Espeja de San Marcelino",
            "Espejo",
            "Espejón",
            "Espelúy",
            "Espera",
            "Espiel",
            "Espinar El",
            "Espinelves",
            "Espino de la Orbada",
            "Espinosa de Cerrato",
            "Espinosa de Cervera",
            "Espinosa de Henares",
            "Espinosa de los Caballeros",
            "Espinosa de los Monteros",
            "Espinosa de Villagonzalo",
            "Espinosa del Camino",
            "Espinoso del Rey",
            "Espirdo",
            "Esplegares",
            "Espluga Calba L",
            "Espluga de Francolí",
            "Esplugues de Llobregat",
            "Esplús",
            "Espolla",
            "Esponellà",
            "Esporles",
            "Espot",
            "Espronceda",
            "Espunyola L",
            "Esquivias",
            "Establés",
            "Estada",
            "Estadilla",
            "Estamariu",
            "Estany L",
            "Estaràs",
            "Estella-Lizarra",
            "Estellencs",
            "Estepa",
            "Estepa de San Juan",
            "Estépar",
            "Estepona",
            "Estercuel",
            "Esteribar",
            "Esterri dÀneu",
            "Esterri de Cardós",
            "Estivella",
            "Estollo",
            "Estopiñán del Castillo",
            "Estrada A",
            "Estrella La",
            "Estremera",
            "Estriégana",
            "Estubeny",
            "Etayo",
            "Etxalar",
            "Etxarri-Aranatz",
            "Etxauri",
            "Etxebarri",
            "Etxebarria",
            "Eulate",
            "Ezcabarte",
            "Ezcaray",
            "Ezcároz/Ezkaroze",
            "Ezkio-Itsaso",
            "Ezkurra",
            "Ezprogui",
            "Fabara",
            "Fabero",
            "Facheca",
            "Fago",
            "Falces",
            "Falset",
            "Famorca",
            "Fanlo",
            "Fanzara",
            "Far dEmpordà El",
            "Faraján",
            "Faramontanos de Tábara",
            "Fariza",
            "Farlete",
            "Farrera",
            "Fasnia",
            "Fatarella",
            "Faura",
            "Favara",
            "Fayón",
            "Fayos Los",
            "Febró",
            "Felanitx",
            "Felix",
            "Fene",
            "Férez",
            "Feria",
            "Fermoselle",
            "Fernán Caballero",
            "Fernán-Núñez",
            "Ferreira",
            "Ferreras de Abajo",
            "Ferreras de Arriba",
            "Ferreries",
            "Ferreruela",
            "Ferreruela de Huerva",
            "Ferrol",
            "Figaró-Montmany",
            "Fígols",
            "Fígols i Alinyà",
            "Figuera",
            "Figueres",
            "Figuerola del Camp",
            "Figueroles",
            "Figueruela de Arriba",
            "Figueruelas",
            "Fiñana",
            "Fines",
            "Finestrat",
            "Firgas",
            "Fiscal",
            "Fisterra",
            "Fitero",
            "Flaçà",
            "Flix",
            "Flores de Ávila",
            "Floresta La",
            "Florida de Liébana",
            "Fogars de la Selva",
            "Fogars de Montclús",
            "Foios",
            "Foixà",
            "Folgoso de la Ribera",
            "Folgoso do Courel",
            "Folgueroles",
            "Fombellida",
            "Fombuena",
            "Fompedraza",
            "Foncea",
            "Fondarella",
            "Fondó de les Neus el/Hondón de las Nieves",
            "Fondón",
            "Fonelas",
            "Fonfría",
            "Fonfría",
            "Fonollosa",
            "Fonsagrada A",
            "Font de la Figuera la",
            "Font dEn Carròs la",
            "Font-rubí",
            "Fontanals de Cerdanya",
            "Fontanar",
            "Fontanarejo",
            "Fontanars dels Alforins",
            "Fontanilles",
            "Fontcoberta",
            "Fontellas",
            "Fontihoyuelo",
            "Fontioso",
            "Fontiveros",
            "Fonz",
            "Fonzaleche",
            "Foradada",
            "Foradada del Toscar",
            "Forallac",
            "Forcall",
            "Forcarei",
            "Forès",
            "Forfoleda",
            "Formentera",
            "Formentera de Segura",
            "Formentera del Segura",
            "Formiche Alto",
            "Fornalutx",
            "Fornells de la Selva",
            "Fornelos de Montes",
            "Fórnoles",
            "Fortaleny",
            "Fortanete",
            "Fortià",
            "Fortuna",
            "Forua",
            "Foz",
            "Foz-Calanda",
            "Frades",
            "Frades de la Sierra",
            "Fraga",
            "Frago El",
            "Frailes",
            "Franco El",
            "Frandovínez",
            "Franqueses del Vallès Les",
            "Frasno El",
            "Frechilla",
            "Frechilla de Almazán",
            "Fregenal de la Sierra",
            "Fregeneda La",
            "Freginals",
            "Freila",
            "Fréscano",
            "Fresneda de Altarejos",
            "Fresneda de Cuéllar",
            "Fresneda de la Sierra",
            "Fresneda de la Sierra Tirón",
            "Fresneda La",
            "Fresnedilla",
            "Fresnedillas",
            "Fresnedillas de la Oliva",
            "Fresnedoso",
            "Fresnedoso de Ibor",
            "Fresneña",
            "Fresnillo de las Dueñas",
            "Fresno Alhándiga",
            "Fresno de Cantespino",
            "Fresno de Caracena",
            "Fresno de la Fuente",
            "Fresno de la Polvorosa",
            "Fresno de la Ribera",
            "Fresno de la Vega",
            "Fresno de Río Tirón",
            "Fresno de Rodilla",
            "Fresno de Sayago",
            "Fresno de Torote",
            "Fresno del Río",
            "Fresno El",
            "Fresno El Viejo",
            "Frías",
            "Frías de Albarracín",
            "Friera de Valverde",
            "Frigiliana",
            "Friol",
            "Frómista",
            "Frontera",
            "Frontera La",
            "Fruiz",
            "Frumales",
            "Fuembellida",
            "Fuencaliente",
            "Fuencaliente de la Palma",
            "Fuencarral-El Pardo",
            "Fuencemillán",
            "Fuendejalón",
            "Fuendetodos",
            "Fuenferrada",
            "Fuengirola",
            "Fuenlabrada",
            "Fuenlabrada de los Montes",
            "Fuenllana",
            "Fuenmayor",
            "Fuensaldaña",
            "Fuensalida",
            "Fuensanta",
            "Fuensanta de Martos",
            "Fuente Álamo de Murcia",
            "Fuente de Cantos",
            "Fuente de Pedro Naharro",
            "Fuente de Piedra",
            "Fuente de San Esteban La",
            "Fuente de Santa Cruz",
            "Fuente del Arco",
            "Fuente del Maestre",
            "Fuente el Fresno",
            "Fuente el Olmo de Fuentidueña",
            "Fuente el Olmo de Íscar",
            "Fuente el Saúz",
            "Fuente el Saz",
            "Fuente el Saz de Jarama",
            "Fuente el Sol",
            "Fuente Encalada",
            "Fuente la Lancha",
            "Fuente la Reina",
            "Fuente Obejuna",
            "Fuente Palmera",
            "Fuente Vaqueros",
            "Fuente-Álamo",
            "Fuente-Álamo de Murcia",
            "Fuente-Olmedo",
            "Fuente-Tójar",
            "Fuentealbilla",
            "Fuentearmegil",
            "Fuentebureba",
            "Fuentecambrón",
            "Fuentecantos",
            "Fuentecén",
            "Fuenteguinaldo",
            "Fuenteheridos",
            "Fuentelahiguera de Albatages",
            "Fuentelapeña",
            "Fuentelcésped",
            "Fuentelencina",
            "Fuentelespino de Haro",
            "Fuentelespino de Moya",
            "Fuenteliante",
            "Fuentelisendo",
            "Fuentelmonge",
            "Fuentelsaz",
            "Fuentelsaz de Soria",
            "Fuentelviejo",
            "Fuentemolinos",
            "Fuentenava de Jábaga",
            "Fuentenebro",
            "Fuentenovilla",
            "Fuentepelayo",
            "Fuentepiñel",
            "Fuentepinilla",
            "Fuenterrebollo",
            "Fuenterroble de Salvatierra",
            "Fuenterrobles",
            "Fuentes",
            "Fuentes Calientes",
            "Fuentes Claras",
            "Fuentes de Andalucía",
            "Fuentes de Año",
            "Fuentes de Ayódar",
            "Fuentes de Béjar",
            "Fuentes de Carbajal",
            "Fuentes de Ebro",
            "Fuentes de Jiloca",
            "Fuentes de León",
            "Fuentes de Magaña",
            "Fuentes de Nava",
            "Fuentes de Oñoro",
            "Fuentes de Ropel",
            "Fuentes de Rubielos",
            "Fuentes de Valdepero",
            "Fuentesaúco",
            "Fuentesaúco de Fuentidueña",
            "Fuentesecas",
            "Fuentesoto",
            "Fuentespalda",
            "Fuentespina",
            "Fuentespreadas",
            "Fuentestrún",
            "Fuentidueña",
            "Fuentidueña de Tajo",
            "Fuerte del Rey",
            "Fuertescusa",
            "Fueva La",
            "Fuliola La",
            "Fulleda",
            "Funes",
            "Fustiñana",
            "Gabaldón",
            "Gabias Las",
            "Gabiria",
            "Gádor",
            "Gaià",
            "Gaianes",
            "Gaibiel",
            "Gaintza",
            "Gajanejos",
            "Gajates",
            "Galapagar",
            "Galápagos",
            "Galar",
            "Galaroza",
            "Galbarros",
            "Galbárruli",
            "Galdakao",
            "Galdames",
            "Gáldar",
            "Galende",
            "Galera",
            "Galera",
            "Galilea",
            "Galindo y Perahuy",
            "Galinduste",
            "Galisancho",
            "Galisteo",
            "Gallardos Los",
            "Gallega La",
            "Gallegos",
            "Gallegos de Altamiros",
            "Gallegos de Argañán",
            "Gallegos de Hornija",
            "Gallegos de Sobrinos",
            "Gallegos de Solmirón",
            "Gallegos del Pan",
            "Gallegos del Río",
            "Gallifa",
            "Gallinero de Cameros",
            "Gallipienzo",
            "Gallipienzo/Galipentzu",
            "Gallocanta",
            "Gallués/Galoze",
            "Gallur",
            "Galve",
            "Galve de Sorbe",
            "Gálvez",
            "Gamiz-Fika",
            "Gamonal",
            "Gamones",
            "Gandesa",
            "Gandia",
            "Garaballa",
            "Garachico",
            "Garafía",
            "Garai",
            "Garaioa",
            "Garbayuela",
            "Garcia",
            "Garciaz",
            "Garcibuey",
            "Garcihernández",
            "Garcillán",
            "Garciotum",
            "Garcirrey",
            "Garde",
            "Gargallo",
            "Garganta de los Montes",
            "Garganta del Villar",
            "Garganta La",
            "Garganta la Olla",
            "Gargantilla",
            "Gargantilla del Lozoya y Pinilla de Buitrago",
            "Gargüera",
            "Garidells",
            "Garínoain",
            "Garlitos",
            "Garrafe de Torío",
            "Garralda",
            "Garray",
            "Garriga La",
            "Garrigàs",
            "Garrigoles",
            "Garriguella",
            "Garrobo El",
            "Garrovilla La",
            "Garrovillas de Alconétar",
            "Garrucha",
            "Garvín",
            "Gascones",
            "Gascueña",
            "Gascueña de Bornova",
            "Gastor El",
            "Gata",
            "Gata de Gorgos",
            "Gatika",
            "Gatón de Campos",
            "Gátova",
            "Gaucín",
            "Gautegiz Arteaga",
            "Gavà",
            "Gavarda",
            "Gavet de la Conca",
            "Gavilanes",
            "Gaztelu",
            "Gea de Albarracín",
            "Gejuelo del Barro",
            "Geldo",
            "Gelida",
            "Gelsa",
            "Gelves",
            "Gema",
            "Gemuño",
            "Genalguacil",
            "Génave",
            "Genevilla",
            "Genovés",
            "Ger",
            "Gerena",
            "Gérgal",
            "Geria",
            "Gerindote",
            "Gernika-Lumo",
            "Gestalgar",
            "Getafe",
            "Getaria",
            "Getxo",
            "Gibraleón",
            "Gijón",
            "Gil García",
            "Gilbuena",
            "Gilena",
            "Gilet",
            "Gimenells i el Pla de la Font",
            "Gimialcón",
            "Gimileo",
            "Ginebrosa La",
            "Gines",
            "Ginestar",
            "Gineta La",
            "Girona",
            "Gironella",
            "Gisclareny",
            "Gistaín",
            "Gizaburuaga",
            "Gobernador",
            "Godall",
            "Godella",
            "Godelleta",
            "Godojos",
            "Goizueta",
            "Gójar",
            "Golmayo",
            "Golmés",
            "Golosalvo",
            "Golpejas",
            "Gómara",
            "Gombrèn",
            "Gomecello",
            "Gomesende",
            "Gomezserracín",
            "Gondomar",
            "Goñi",
            "Gor",
            "Gorafe",
            "Gordaliza del Pino",
            "Gordexola",
            "Gordo El",
            "Gordoncillo",
            "Gorga",
            "Gorliz",
            "Gormaz",
            "Gósol",
            "Gotarrendura",
            "Gotor",
            "Gozón",
            "Gradefes",
            "Grado",
            "Grado El",
            "Graja de Campalbo",
            "Graja de Iniesta",
            "Grajal de Campos",
            "Grajera",
            "Granada",
            "Granada de Río-Tinto La",
            "Granada La",
            "Granadella La",
            "Granadilla de Abona",
            "Granado El",
            "Granátula de Calatrava",
            "Grandas de Salime",
            "Grandes y San Martín",
            "Grañén",
            "Granera",
            "Granja de la Costera la",
            "Granja de Moreruela",
            "Granja de Rocamora",
            "Granja de Torrehermosa",
            "Granja dEscarp La",
            "Granja La",
            "Granjuela La",
            "Granollers",
            "Grañón",
            "Granucillo",
            "Granyanella",
            "Granyena de les Garrigues",
            "Granyena de Segarra",
            "Grao de Murviedro",
            "Gratallops",
            "Graus",
            "Grávalos",
            "Grazalema",
            "Griegos",
            "Grijalba",
            "Grijota",
            "Griñón",
            "Grisaleña",
            "Grisel",
            "Grisén",
            "Grove O",
            "Guadahortuna",
            "Guadalajara",
            "Guadalaviar",
            "Guadalcanal",
            "Guadalcázar",
            "Guadalix de la Sierra",
            "Guadalmez",
            "Guadalupe",
            "Guadamur",
            "Guadarrama",
            "Guadasequies",
            "Guadasséquies",
            "Guadassuar",
            "Guadix",
            "Guadramiro",
            "Guajares Los",
            "Gualba",
            "Gualchos",
            "Gualta",
            "Guancha La",
            "Guarda A",
            "Guardamar de la Safor",
            "Guardamar del Segura",
            "Guardia de Jaén La",
            "Guardia La",
            "Guardiola de Berguedà",
            "Guardo",
            "Guareña",
            "Guaro",
            "Guarrate",
            "Guarromán",
            "Guaza de Campos",
            "Gúdar",
            "Gudiña A",
            "Güejar Sierra",
            "Güeñes",
            "Güesa/Gorza",
            "Guesálaz/Gesalatz",
            "Güevéjar",
            "Guia",
            "Guía de Isora",
            "Guiamets",
            "Guijo de Ávila",
            "Guijo de Coria",
            "Guijo de Galisteo",
            "Guijo de Granadilla",
            "Guijo de Santa Bárbara",
            "Guijo El",
            "Guijuelo",
            "Guillena",
            "Guils de Cerdanya",
            "Güimar",
            "Guimerà",
            "Guingueta dÀneu La",
            "Guirguillano",
            "Guisando",
            "Guissona",
            "Guitiriz",
            "Guixers",
            "Gumiel de Izán",
            "Gumiel de Mercado",
            "Guntín",
            "Gurb",
            "Guriezo",
            "Gurrea de Gállego",
            "Gusendos de los Oteros",
            "Gutierre-Muñoz",
            "Haba La",
            "Hacinas",
            "Harana/Valle de Arana",
            "Haría",
            "Haro",
            "Haza",
            "Hazas de Cesto",
            "Helechosa de los Montes",
            "Hellín",
            "Henarejos",
            "Henche",
            "Heras de Ayuso",
            "Herbés",
            "Herce",
            "Herencia",
            "Herencias Las",
            "Herguijuela",
            "Herguijuela de Ciudad Rodrigo",
            "Herguijuela de la Sierra",
            "Herguijuela del Campo",
            "Hermandad de Campoo de Suso",
            "Hérmedes de Cerrato",
            "Hermigua",
            "Hermisende",
            "Hernán-Pérez",
            "Hernani",
            "Hernansancho",
            "Hernialde",
            "Herradón de Pinares",
            "Herramélluri",
            "Herrera",
            "Herrera de Alcántara",
            "Herrera de los Navarros",
            "Herrera de Pisuerga",
            "Herrera de Soria",
            "Herrera de Valdecañas",
            "Herrera del Duque",
            "Herrera La",
            "Herrería",
            "Herrerías",
            "Herreros de Suso",
            "Herreruela",
            "Herreruela de Oropesa",
            "Herrín de Campos",
            "Herrumblar El",
            "Hervás",
            "Hervías",
            "Hiendelaencina",
            "Higuera",
            "Higuera de Calatrava",
            "Higuera de la Serena",
            "Higuera de la Sierra",
            "Higuera de las Dueñas",
            "Higuera de Llerena",
            "Higuera de Vargas",
            "Higuera la Real",
            "Higueras",
            "Higueruela",
            "Higueruelas",
            "Hija de Dios La",
            "Híjar",
            "Hijes",
            "Hiniesta La",
            "Hinojal",
            "Hinojales",
            "Hinojares",
            "Hinojos",
            "Hinojosa de Duero",
            "Hinojosa de Jarque",
            "Hinojosa de San Vicente",
            "Hinojosa del Campo",
            "Hinojosa del Duque",
            "Hinojosa del Valle",
            "Hinojosa La",
            "Hinojosas de Calatrava",
            "Hinojosos Los",
            "Hiriberri/Villanueva de Aezkoa",
            "Hiruela La",
            "Hita",
            "Hito El",
            "Holguera",
            "Hombrados",
            "Hondarribia",
            "Hondón de las Nieves",
            "Hondón de los Frailes",
            "Honrubia",
            "Honrubia de la Cuesta",
            "Hontalbilla",
            "Hontanar",
            "Hontanares de Eresma",
            "Hontanas",
            "Hontanaya",
            "Hontangas",
            "Hontecillas",
            "Hontoba",
            "Hontoria de Cerrato",
            "Hontoria de la Cantera",
            "Hontoria de Valdearados",
            "Hontoria del Pinar",
            "Horcajada La",
            "Horcajo de la Sierra",
            "Horcajo de la Sierra-Aoslos",
            "Horcajo de las Torres",
            "Horcajo de los Montes",
            "Horcajo de Montemayor",
            "Horcajo de Santiago",
            "Horcajo Medianero",
            "Horcajuelo de la Sierra",
            "Horche",
            "Hormazas Las",
            "Hormigos",
            "Hormilla",
            "Hormilleja",
            "Hornachos",
            "Hornachuelos",
            "Hornillo El",
            "Hornillos de Cameros",
            "Hornillos de Cerrato",
            "Hornillos de Eresma",
            "Hornillos del Camino",
            "Hornos",
            "Hornos de Moncalvillo",
            "Horra La",
            "Horta de Sant Joan",
            "Hortaleza",
            "Hortezuela de Océn",
            "Hortigüela",
            "Hospital de Órbigo",
            "Hospitalet de Llobregat L",
            "Hostalets de Pierola Els",
            "Hostalric",
            "Hoya La",
            "Hoya-Gonzalo",
            "Hoyales de Roa",
            "Hoyo de Manzanares",
            "Hoyo de Pinares El",
            "Hoyocasero",
            "Hoyorredondo",
            "Hoyos",
            "Hoyos de Miguel Muñoz",
            "Hoyos del Collado",
            "Hoyos del Espino",
            "Hoz de Jaca",
            "Hoz de la Vieja La",
            "Hoz y Costean",
            "Huarte-Uharte",
            "Huarte/Uharte",
            "Huecas",
            "Huécija",
            "Huélaga",
            "Huélago",
            "Huélamo",
            "Huelma",
            "Huelva",
            "Huelves",
            "Huéneja",
            "Huércal de Almería",
            "Huércal-Overa",
            "Huércanos",
            "Huerce La",
            "Huérguina",
            "Huérmeces",
            "Huérmeces del Cerro",
            "Huerta",
            "Huerta de Arriba",
            "Huerta de la Obispalía",
            "Huerta de Rey",
            "Huerta de Valdecarábanos",
            "Huerta del Marquesado",
            "Huerta del Rey",
            "Huertahernando",
            "Huerto",
            "Huertos Los",
            "Huesa",
            "Huesa del Común",
            "Huesca",
            "Huéscar",
            "Huete",
            "Huétor de Santillán",
            "Huétor Tájar",
            "Huétor Vega",
            "Hueva",
            "Huévar del Aljarafe",
            "Humada",
            "Humanes",
            "Humanes de Madrid",
            "Humilladero",
            "Hurones",
            "Hurtumpascual",
            "Husillos",
            "Ibahernando",
            "Ibargoiti",
            "Ibarra",
            "Ibarrangelu",
            "Ibdes",
            "Ibeas de Juarros",
            "Ibi",
            "Ibias",
            "Ibieca",
            "Ibiza",
            "Ibrillos",
            "Ibros",
            "Icod de los Vinos",
            "Idiazabal",
            "Igantzi",
            "Igea",
            "Iglesiarrubia",
            "Iglesias",
            "Iglesuela del Cid La",
            "Iglesuela La",
            "Igorre",
            "Igriés",
            "Igualada",
            "Igualeja",
            "Igüeña",
            "Igúzquiza",
            "Ikaztegieta",
            "Ilche",
            "Illa de Arousa A",
            "Illán de Vacas",
            "Illana",
            "Illano",
            "Illar",
            "Illas",
            "Illes Balears",
            "Illescas",
            "Illora",
            "Illueca",
            "Imotz",
            "Inca",
            "Incio O",
            "Ingenio",
            "Iniesta",
            "Iniéstola",
            "Instinción",
            "Inviernas Las",
            "Irañeta",
            "Irixo O",
            "Irixoa",
            "Iruela La",
            "Iruelos",
            "Irueste",
            "Irun",
            "Iruña Oka/Iruña de Oca",
            "Irura",
            "Iruraiz-Gauna",
            "Irurtzun",
            "Isaba/Izaba",
            "Isábena",
            "Isar",
            "Iscar",
            "Isla Cristina",
            "Isla Mayor",
            "Isona i Conca Dellà",
            "Isòvol",
            "Ispaster",
            "Istán",
            "Isuerre",
            "Itero de la Vega",
            "Itero del Castillo",
            "Ítrabo",
            "Itsasondo",
            "Ituero de Azaba",
            "Ituero y Lama",
            "Ituren",
            "Iturmendi",
            "Iturrama",
            "Iurreta",
            "Ivars de Noguera",
            "Ivars dUrgell",
            "Ivorra",
            "Iza/Itza",
            "Izagaondoa",
            "Izagre",
            "Izalzu/Itzaltzu",
            "Iznájar",
            "Iznalloz",
            "Iznate",
            "Iznatoraf",
            "Izurtza",
            "Jabaloyas",
            "Jabalquinto",
            "Jabugo",
            "Jaca",
            "Jacarilla",
            "Jadraque",
            "Jaén",
            "Jafre",
            "Jalance",
            "Jalón",
            "Jalón de Cameros",
            "Jambrina",
            "Jamilena",
            "Jana la",
            "Jaraba",
            "Jarafuel",
            "Jaraicejo",
            "Jaraíz de la Vera",
            "Jaramillo de la Fuente",
            "Jaramillo Quemado",
            "Jarandilla de la Vera",
            "Jarilla",
            "Jarque",
            "Jarque de la Val",
            "Jasa",
            "Jatiel",
            "Jaulín",
            "Jaurrieta",
            "Javea",
            "Jávea/Xàbia",
            "Javier",
            "Jayena",
            "Jerez de la Frontera",
            "Jerez de los Caballeros",
            "Jérez del Marquesado",
            "Jérica",
            "Jerte",
            "Jete",
            "Jijona",
            "Jijona/Xixona",
            "Jimena",
            "Jimena de la Frontera",
            "Jimera de Líbar",
            "Jirueque",
            "Joarilla de las Matas",
            "Jódar",
            "Jonquera La",
            "Jorba",
            "Jorcas",
            "Jorquera",
            "Josa",
            "Josa i Tuixén",
            "Joyosa La",
            "Juarros de Riomoros",
            "Juarros de Voltoya",
            "Jubrique",
            "Juià",
            "Jumilla",
            "Jun",
            "Junciana",
            "Juncosa",
            "Juneda",
            "Junta de Traslaloma",
            "Junta de Villalba de Losa",
            "Jurisdicción de Lara",
            "Jurisdicción de San Zadornil",
            "Juslapeña",
            "Justel",
            "Juviles",
            "Juzbado",
            "Júzcar",
            "Karrantza Harana/Valle de Carranza",
            "Kortezubi",
            "Kripan",
            "Kuartango",
            "l'Alcora",
            "L'Alcúdia",
            "L'Alcúdia de Crespìns",
            "l'Alfàs del Pi",
            "L'Alqueria de la Comtessa",
            "L'Eliana",
            "L'Olleria",
            "La Adrada",
            "La Alberca",
            "La Almunia de Doña Godina",
            "La Bañeza",
            "La Bouza",
            "La Cabrera",
            "La Carrera",
            "La Cuesta",
            "La Font de la Figuera",
            "La Fuente de San Esteban",
            "La Ginebrosa",
            "La Guancha",
            "La Iglesuela del Cid",
            "La Laguna",
            "La Lastrilla",
            "La Manga del Mar Menor",
            "La Matanza de Acentejo",
            "la Nucia",
            "La Oliva",
            "La Orotava",
            "La Pedraja de Portillo",
            "La Pobla de Farnals",
            "La Pobla de Vallbona",
            "La Pobla Llarga",
            "La Pola de Gordón",
            "La Robla",
            "La Romana",
            "La Seca",
            "La Torrecilla",
            "La Unión",
            "La Victoria de Acentejo",
            "La Yesa",
            "Labajos",
            "Labastida/Bastida",
            "Labores Las",
            "Labuerda",
            "Láchar",
            "Ladrillar",
            "Lagartera",
            "Lagartos",
            "Lagata",
            "Lagrán",
            "Laguardia",
            "Lagueruela",
            "Laguna Dalga",
            "Laguna de Cameros",
            "Laguna de Contreras",
            "Laguna de Duero",
            "Laguna de Negrillos",
            "Laguna del Marquesado",
            "Lagunaseca",
            "Lagunilla",
            "Lagunilla del Jubera",
            "Lahiguera",
            "Lakuntza",
            "Lalín",
            "Laluenga",
            "Lalueza",
            "Lama A",
            "Lamasón",
            "Lana",
            "Lanaja",
            "Láncara",
            "Lanciego/Lantziego",
            "Landete",
            "Lanestosa",
            "Langa",
            "Langa de Duero",
            "Langa del Castillo",
            "Langayo",
            "Langreo",
            "Languilla",
            "Lanjarón",
            "Lantadilla",
            "Lantarón",
            "Lanteira",
            "Lantejuela La",
            "Lantz",
            "Lanzahíta",
            "Lanzuela",
            "Lapa La",
            "Laperdiguera",
            "Lapoblación",
            "Lapuebla de Labarca",
            "Laracha A",
            "Lardero",
            "Laredo",
            "Larouco",
            "Laroya",
            "Larrabetzu",
            "Larraga",
            "Larraona",
            "Larraul",
            "Larraun",
            "Larrodrigo",
            "Larva",
            "Las Matas",
            "Las Navas del Marqués",
            "Las Palmas de Gran Canaria",
            "Las Palmas de Gran Canaria",
            "Las Rosas",
            "Las Rozas de Madrid",
            "Las Tablas",
            "Las Torres de Cotillas",
            "Lasarte-Oria",
            "Lascellas-Ponzano",
            "Lascuarre",
            "Laspaúles",
            "Laspuña",
            "Lastras de Cuéllar",
            "Lastras del Pozo",
            "Lastrilla La",
            "Latina",
            "Laudio/Llodio",
            "Laujar de Andarax",
            "Laukiz",
            "Laviana",
            "Laxe",
            "Layana",
            "Layos",
            "Laza",
            "Lazagurría",
            "Lazkao",
            "Leaburu",
            "Leache",
            "Lebrija",
            "Lécera",
            "Lechón",
            "Leciñena",
            "Lecrín",
            "Ledaña",
            "Ledanca",
            "Ledesma",
            "Ledesma de la Cogolla",
            "Ledigos",
            "Ledrada",
            "Leganés",
            "Leganiel",
            "Legarda",
            "Legaria",
            "Legazpi",
            "Legorreta",
            "Legutio",
            "Leintz-Gatzaga",
            "Leioa",
            "Leiro",
            "Leitza",
            "Leiva",
            "Lekeitio",
            "Lekunberri",
            "Lemoa",
            "Lemoiz",
            "Lena",
            "Lentegí",
            "León",
            "Leoz/Leotz",
            "Lepe",
            "Lerga",
            "Lerín",
            "Lerma",
            "Les",
            "Lesaka",
            "Letur",
            "Letux",
            "Leza",
            "Leza de Río Leza",
            "Lezama",
            "Lezáun",
            "Lezo",
            "Lezuza",
            "Librilla",
            "Libros",
            "Liceras",
            "Lidón",
            "Liédena",
            "Liendo",
            "Liérganes",
            "Liétor",
            "Líjar",
            "Lillo",
            "Limpias",
            "Linares",
            "Linares de la Sierra",
            "Linares de Mora",
            "Linares de Riofrío",
            "Línea de la Concepción La",
            "Linyola",
            "Litago",
            "Lituénigo",
            "Lizartza",
            "Lizoáin",
            "Lizoáin-Arriasgoiti",
            "Llacuna La",
            "Lladó",
            "Lladorre",
            "Lladurs",
            "Llagosta La",
            "Llagostera",
            "Llamas de la Ribera",
            "Llambilles",
            "Llanars",
            "Llançà",
            "Llanera",
            "Llanera de Ranes",
            "Llanes",
            "Llano de Bureba",
            "Llano de Olmedo",
            "Llanos de Aridane Los",
            "Llanos de Tormes Los",
            "Llanos del Caudillo",
            "Llardecans",
            "Llaurí",
            "Llavorsí",
            "Lledó",
            "Lleida",
            "Llera",
            "Llerena",
            "Llers",
            "Lles de Cerdanya",
            "Llíber",
            "Lliçà dAmunt",
            "Lliçà de Vall",
            "Llimiana",
            "Llinars del Vallès",
            "Llíria",
            "Llívia",
            "Lloar",
            "Llobera",
            "Llocnou de la Corona",
            "Llocnou de Sant Jeroni",
            "Llocnou dEn Fenollet",
            "Llombai",
            "Llorac",
            "Llorenç del Penedès",
            "Lloret de Mar",
            "Lloret de Vistalegre",
            "Llosa de Ranes",
            "Llosa de Ranes la",
            "Llosa la",
            "Lloseta",
            "Llosses Les",
            "Llubí",
            "Lluçà",
            "Llucmajor",
            "Llutxent",
            "Lo Pagán",
            "Loarre",
            "Lobeira",
            "Lobera de Onsella",
            "Lobios",
            "Lobón",
            "Lobras",
            "Lodosa",
            "Loeches",
            "Logroño",
            "Logrosán",
            "Loiu",
            "Loja",
            "Loma de Ucieza",
            "Lomas",
            "Lominchar",
            "Lomo de Arico",
            "Lomoviejo",
            "Longares",
            "Longás",
            "Lónguida/Longida",
            "Lopera",
            "Loporzano",
            "Lora de Estepa",
            "Lora del Río",
            "Loranca de Tajuña",
            "Lorca",
            "Lorcha/Orxa l",
            "Loriguilla",
            "Lorquí",
            "Los Alcázares",
            "Los Arcos",
            "Los Corrales de Buelna",
            "Los Gabatos",
            "Los Gigantes",
            "Los Llanos de Aridane",
            "Los Martínez",
            "Los Molinos",
            "Los Montesinos",
            "Los Realejos",
            "Los Santos de la Humosa",
            "Los Silos",
            "Losa del Obispo",
            "Losa La",
            "Losacino",
            "Losacio",
            "Losar de la Vera",
            "Losar del Barco El",
            "Loscorrales",
            "Loscos",
            "Losilla La",
            "Lourenzá",
            "Lousame",
            "Lozoya",
            "Lozoyuela-Navas-Sieteiglesias",
            "Lubián",
            "Lubrín",
            "Lucainena de las Torres",
            "Lúcar",
            "Lucena",
            "Lucena de Jalón",
            "Lucena del Cid",
            "Lucena del Puerto",
            "Luceni",
            "Luciana",
            "Lucillo",
            "Lucillos",
            "Ludiente",
            "Luelmo",
            "Luena",
            "Luesia",
            "Luesma",
            "Lugo",
            "Lugros",
            "Luintra",
            "Luisiana La",
            "Lújar",
            "Lumbier",
            "Lumbrales",
            "Lumbreras",
            "Lumpiaque",
            "Luna",
            "Lupiana",
            "Lupiñén-Ortilla",
            "Lupión",
            "Luque",
            "Luquin",
            "Luyego",
            "Luzaga",
            "Luzaide/Valcarlos",
            "Luzmela",
            "Luzón",
            "Macael",
            "Maçanet de Cabrenys",
            "Maçanet de la Selva",
            "Macastre",
            "Maceda",
            "Machacón",
            "Macharaviaya",
            "Mácher",
            "Macotera",
            "Madarcos",
            "Maderal El",
            "Maderuelo",
            "Madremanya",
            "Madrid",
            "Madridanos",
            "Madridejos",
            "Madrigal de la Vera",
            "Madrigal de las Altas Torres",
            "Madrigal del Monte",
            "Madrigalejo",
            "Madrigalejo del Monte",
            "Madrigueras",
            "Madroñal",
            "Madroñera",
            "Madroño El",
            "Maella",
            "Maello",
            "Magacela",
            "Magallón",
            "Magaluf",
            "Magán",
            "Magaña",
            "Magaz de Cepeda",
            "Magaz de Pisuerga",
            "Maguilla",
            "Mahamud",
            "Mahide",
            "Mahora",
            "Maià de Montcal",
            "Maials",
            "Maicas",
            "Maíllo El",
            "Mainar",
            "Maire de Castroponce",
            "Mairena del Alcor",
            "Mairena del Aljarafe",
            "Majadahonda",
            "Majadas",
            "Majadas Las",
            "Majaelrayo",
            "Maján",
            "Málaga",
            "Málaga del Fresno",
            "Malagón",
            "Malaguilla",
            "Malahá La",
            "Malanquilla",
            "Malcocinado",
            "Maldà",
            "Maleján",
            "Malgrat de Mar",
            "Malla",
            "Mallabia",
            "Mallén",
            "Malón",
            "Malpartida",
            "Malpartida de Cáceres",
            "Malpartida de Corneja",
            "Malpartida de la Serena",
            "Malpartida de Plasencia",
            "Malpica de Bergantiños",
            "Malpica de Tajo",
            "Maluenda",
            "Malva",
            "Mamblas",
            "Mambrilla de Castrejón",
            "Mambrillas de Lara",
            "Mamolar",
            "Manacor",
            "Mañaria",
            "Mancera de Abajo",
            "Mancera de Arriba",
            "Mancha Real",
            "Manchita",
            "Manchones",
            "Manciles",
            "Mancor de la Vall",
            "Mandayona",
            "Mañeru",
            "Manganeses de la Lampreana",
            "Manganeses de la Polvorosa",
            "Manilva",
            "Manises",
            "Manjabálago",
            "Manjarrés",
            "Manlleu",
            "Mañón",
            "Manquillos",
            "Manresa",
            "Mansilla de la Sierra",
            "Mansilla de las Mulas",
            "Mansilla Mayor",
            "Mantiel",
            "Mantinos",
            "Manuel",
            "Manzanal de Arriba",
            "Manzanal de los Infantes",
            "Manzanal del Barco",
            "Manzanares",
            "Manzanares de Rioja",
            "Manzanares el Real",
            "Manzaneda",
            "Manzaneque",
            "Manzanera",
            "Manzanilla",
            "Manzanillo",
            "Manzano El",
            "Maó",
            "Maqueda",
            "Mara",
            "Maracena",
            "Maraña",
            "Maranchón",
            "Marañón",
            "Marazoleja",
            "Marazuela",
            "Marbella",
            "Marçà",
            "Marchagaz",
            "Marchal",
            "Marchamalo",
            "Marchena",
            "Marcilla",
            "Marcilla de Campos",
            "Margalef",
            "Marganell",
            "María",
            "María de Huerva",
            "Maria de la Salut",
            "Mariana",
            "Marín",
            "Marina de Cudeyo",
            "Marinaleda",
            "Marines",
            "Marines Los",
            "Marjaliza",
            "Markina-Xemein",
            "Marlín",
            "Marmolejo",
            "Marracos",
            "Marratxí",
            "Marrupe",
            "Martiago",
            "Martiherrero",
            "Martín de la Jara",
            "Martín de Yeltes",
            "Martín del Río",
            "Martín Miguel",
            "Martín Muñoz de la Dehesa",
            "Martín Muñoz de las Posadas",
            "Martinamor",
            "Martínez",
            "Martorell",
            "Martorelles",
            "Martos",
            "Marugán",
            "Maruri-Jatabe",
            "Marzales",
            "Mas de Barberans",
            "Mas de las Matas",
            "Masalavés",
            "Masarac",
            "Mascaraque",
            "Masdenverge",
            "Masegosa",
            "Masegoso",
            "Masegoso de Tajuña",
            "Maside",
            "Masies de Roda Les",
            "Masies de Voltregà Les",
            "Masllorenç",
            "Masnou El",
            "Masó",
            "Maspalomas",
            "Maspujols",
            "Masquefa",
            "Masroig",
            "Massalavés",
            "Massalcoreig",
            "Massalfassar",
            "Massamagrell",
            "Massanassa",
            "Massanes",
            "Massoteres",
            "Masueco",
            "Mata de Alcántara",
            "Mata de Cuéllar",
            "Mata de Ledesma La",
            "Mata de los Olmos La",
            "Mata de Morella la",
            "Mata La",
            "Matabuena",
            "Matadeón de los Oteros",
            "Matadepera",
            "Matalebreras",
            "Matallana de Torío",
            "Matamala de Almazán",
            "Matanza",
            "Matanza de Acentejo La",
            "Mataporquera",
            "Matapozuelos",
            "Mataró",
            "Matarrubia",
            "Matet",
            "Matilla de Arzón",
            "Matilla de los Caños",
            "Matilla de los Caños del Río",
            "Matilla La",
            "Matilla la Seca",
            "Matillas",
            "Matute",
            "Maya La",
            "Mayalde",
            "Mayorga",
            "Mazaleón",
            "Mazarambroz",
            "Mazarete",
            "Mazaricos",
            "Mazariegos",
            "Mazarrón",
            "Mazcuerras",
            "Mazo",
            "Mazuecos",
            "Mazuecos de Valdeginate",
            "Mazuela",
            "Meaño",
            "Mecerreyes",
            "Meco",
            "Medellín",
            "Mediana de Aragón",
            "Mediana de Voltoya",
            "Medina de las Torres",
            "Medina de Pomar",
            "Medina de Ríoseco",
            "Medina del Campo",
            "Medina-Sidonia",
            "Medinaceli",
            "Medinilla",
            "Medio Cudeyo",
            "Mediona",
            "Medranda",
            "Medrano",
            "Megeces",
            "Megina",
            "Meira",
            "Meis",
            "Mejorada",
            "Mejorada del Campo",
            "Melgar de Abajo",
            "Melgar de Arriba",
            "Melgar de Fernamental",
            "Melgar de Tera",
            "Melgar de Yuso",
            "Meliana",
            "Mélida",
            "Melide",
            "Melón",
            "Melque de Cercos",
            "Membibre de la Hoz",
            "Membribe de la Sierra",
            "Membrilla",
            "Membrillera",
            "Membrío",
            "Meñaka",
            "Menàrguens",
            "Menasalbas",
            "Mendaro",
            "Mendata",
            "Mendavia",
            "Mendaza",
            "Mendexa",
            "Mendigorría",
            "Meneses de Campos",
            "Mengabril",
            "Mengamuñoz",
            "Mengibar",
            "Méntrida",
            "Mequinensa / Mequinenza",
            "Mequinenza",
            "Meranges",
            "Merca A",
            "Mercadal",
            "Mercadal Es",
            "Mérida",
            "Merindad de Cuesta-Urria",
            "Merindad de Montija",
            "Merindad de Río Ubierna",
            "Merindad de Sotoscueva",
            "Merindad de Valdeporres",
            "Merindad de Valdivielso",
            "Meruelo",
            "Mesas de Ibor",
            "Mesas Las",
            "Mesegar de Corneja",
            "Mesegar de Tajo",
            "Mesia",
            "Mesones de Isuela",
            "Mestanza",
            "Metauten",
            "Mezalocha",
            "Mezquita A",
            "Mezquita de Jarque",
            "Miajadas",
            "Mianos",
            "Micereces de Tera",
            "Micieces de Ojeda",
            "Miedes de Aragón",
            "Miedes de Atienza",
            "Miengo",
            "Miera",
            "Mieres",
            "Mierla La",
            "Mieza",
            "Migjorn Gran Es",
            "Miguel Esteban",
            "Migueláñez",
            "Miguelturra",
            "Mijares",
            "Mijas",
            "Milà",
            "Milagro",
            "Milagros",
            "Milano El",
            "Millana",
            "Millanes",
            "Millares",
            "Millena",
            "Milles de la Polvorosa",
            "Milmarcos",
            "Minas de Riotinto",
            "Minaya",
            "Minglanilla",
            "Mingorría",
            "Miño",
            "Miño de Medinaceli",
            "Miño de San Esteban",
            "Miñosa La",
            "Mira",
            "Mirabel",
            "Mirabueno",
            "Miraflores de la Sierra",
            "Mirafuentes",
            "Miralcamp",
            "Miralrío",
            "Miramar",
            "Mirambel",
            "Miranda de Arga",
            "Miranda de Azán",
            "Miranda de Ebro",
            "Miranda del Castañar",
            "Mirandilla",
            "Miraveche",
            "Miravet",
            "Miravete de la Sierra",
            "Mirón El",
            "Mironcillo",
            "Mirueña de los Infanzones",
            "Mislata",
            "Moaña",
            "Mocejón",
            "Mochales",
            "Moclín",
            "Moclinejo",
            "Modúbar de la Emparedada",
            "Moeche",
            "Mogán",
            "Mogarraz",
            "Mogente",
            "Mogente/Moixent",
            "Moguer",
            "Mohedas de Granadilla",
            "Mohedas de la Jara",
            "Mohernando",
            "Moià",
            "Mojacar",
            "Mojados",
            "Mojonera La",
            "Molacillos",
            "Molar",
            "Molar El",
            "Molares Los",
            "Molezuelas de la Carballeda",
            "Molina de Aragón",
            "Molina de Segura",
            "Molinaseca",
            "Molinicos",
            "Molinillo",
            "Molinos",
            "Molinos de Duero",
            "Molinos Los",
            "Molins de Rei",
            "Molledo",
            "Mollerussa",
            "Mollet de Peralada",
            "Mollet del Vallès",
            "Mollina",
            "Molló",
            "Molsosa La",
            "Molvízar",
            "Mombeltrán",
            "Momblona",
            "Mombuey",
            "Monachil",
            "Monasterio",
            "Monasterio de la Sierra",
            "Monasterio de Rodilla",
            "Monasterio de Vega",
            "Moncada",
            "Moncalvillo",
            "Moncloa-Aravaca",
            "Moncofa",
            "Monda",
            "Mondariz",
            "Mondariz-Balneario",
            "Mondéjar",
            "Mondoñedo",
            "Monegrillo",
            "Monesma y Cajigar",
            "Monesterio",
            "Moneva",
            "Monfarracinos",
            "Monfero",
            "Monflorite-Lascasas",
            "Monforte de la Sierra",
            "Monforte de Lemos",
            "Monforte de Moyuela",
            "Monforte del Cid",
            "Monistrol de Calders",
            "Monistrol de Montserrat",
            "Monleón",
            "Monleras",
            "Monóvar",
            "Monóvar/Monòver",
            "Monreal",
            "Monreal de Ariza",
            "Monreal del Campo",
            "Monreal del Llano",
            "Monreal/Elo",
            "Monroy",
            "Monroyo",
            "Monsagro",
            "Monsalupe",
            "Monserrat",
            "Mont-ral",
            "Mont-ras",
            "Mont-roig del Camp",
            "Montaberner",
            "Montagut i Oix",
            "Montalbán",
            "Montalbán de Córdoba",
            "Montalbanejo",
            "Montalbo",
            "Montalvos",
            "Montamarta",
            "Montán",
            "Montánchez",
            "Montanejos",
            "Montanuy",
            "Montarrón",
            "Montaverner",
            "Montblanc",
            "Montbrió del Camp",
            "Montcada i Reixac",
            "Montclar",
            "Monteagudo",
            "Monteagudo de las Salinas",
            "Monteagudo de las Vicarías",
            "Monteagudo del Castillo",
            "Montealegre de Campos",
            "Montealegre del Castillo",
            "Montearagón",
            "Montecanal",
            "Montederramo",
            "Montefrío",
            "Montehermoso",
            "Montejaque",
            "Montejicar",
            "Montejo",
            "Montejo de Arévalo",
            "Montejo de la Sierra",
            "Montejo de la Vega de la Serrezuela",
            "Montejo de Tiermes",
            "Montellà i Martinet",
            "Montellano",
            "Montemayor",
            "Montemayor de Pililla",
            "Montemayor del Río",
            "Montemolín",
            "Montenegro de Cameros",
            "Monterde",
            "Monterde de Albarracín",
            "Monterrei",
            "Monterroso",
            "Monterrubio",
            "Monterrubio de Armuña",
            "Monterrubio de la Demanda",
            "Monterrubio de la Serena",
            "Monterrubio de la Sierra",
            "Montesa",
            "Montesclaros",
            "Montesinos Los",
            "Montesquiu",
            "Montferrer i Castellbò",
            "Montferri",
            "Montgai",
            "Montgat",
            "Montichelvo",
            "Montiel",
            "Montijo",
            "Montilla",
            "Montillana",
            "Montitxelvo/Montichelvo",
            "Montizón",
            "Montmajor",
            "Montmaneu",
            "Montmell",
            "Montmeló",
            "Montoliu de Lleida",
            "Montoliu de Segarra",
            "Montón",
            "Montorio",
            "Montornès de Segarra",
            "Montornès del Vallès",
            "Montoro",
            "Montroy",
            "Montseny",
            "Montserrat",
            "Montuïri",
            "Monturque",
            "Monzón",
            "Monzón de Campos",
            "Mora",
            "Mora de Rubielos",
            "Móra dEbre",
            "Móra la Nova",
            "Moradillo de Roa",
            "Moraira",
            "Moral de Calatrava",
            "Moral de Hornuez",
            "Moral de la Reina",
            "Moral de Sayago",
            "Moraleda de Zafayona",
            "Moraleja",
            "Moraleja de Enmedio",
            "Moraleja de las Panaderas",
            "Moraleja de Matacabras",
            "Moraleja de Sayago",
            "Moraleja del Vino",
            "Morales de Campos",
            "Morales de Rey",
            "Morales de Toro",
            "Morales de Valverde",
            "Morales del Vino",
            "Moralina",
            "Moralzarzal",
            "Moraña",
            "Morasverdes",
            "Morata de Jalón",
            "Morata de Jiloca",
            "Morata de Tajuña",
            "Moratalaz",
            "Moratalla",
            "Moratilla de los Meleros",
            "Moratinos",
            "Morcillo",
            "Morcín",
            "Moreda de Álava/Moreda Araba",
            "Morelábor",
            "Morell",
            "Morella",
            "Morenilla",
            "Morentin",
            "Morera de Montsant",
            "Morera La",
            "Moreruela de los Infanzones",
            "Moreruela de Tábara",
            "Morés",
            "Morga",
            "Moriles",
            "Morille",
            "Moríñigo",
            "Moriscos",
            "Morón de Almazán",
            "Morón de la Frontera",
            "Moronta",
            "Moros",
            "Mos",
            "Moscardón",
            "Mosqueruela",
            "Móstoles",
            "Mota de Altarejos",
            "Mota del Cuervo",
            "Mota del Marqués",
            "Motilla del Palancar",
            "Motilleja",
            "Motril",
            "Moya",
            "Moya",
            "Moyuela",
            "Mozárbez",
            "Mozoncillo",
            "Mozota",
            "Mucientes",
            "Mudá",
            "Mudarra La",
            "Muduex",
            "Muel",
            "Muela La",
            "Muelas de los Caballeros",
            "Muelas del Pan",
            "Mues",
            "Muga de Sayago",
            "Mugardos",
            "Mugia",
            "Muiños",
            "Mula",
            "Muñana",
            "Mundaka",
            "Munébrega",
            "Munera",
            "Mungia",
            "Muñico",
            "Muniesa",
            "Munilla",
            "Munitibar-Arbatzegi Gerrikaitz",
            "Muñogalindo",
            "Muñogrande",
            "Muñomer del Peco",
            "Muñopedro",
            "Muñopepe",
            "Muñosancho",
            "Muñotello",
            "Muñoveros",
            "Muntanyola",
            "Mura",
            "Muras",
            "Murchante",
            "Murcia",
            "Murero",
            "Murias de Paredes",
            "Muriel",
            "Muriel de la Fuente",
            "Muriel Viejo",
            "Murieta",
            "Murillo de Gállego",
            "Murillo de Río Leza",
            "Murillo el Cuende",
            "Murillo el Fruto",
            "Murla",
            "Muro",
            "Muro de Aguas",
            "Muro de Alcoy",
            "Muro del Alcoy",
            "Muro en Cameros",
            "Muros",
            "Muros de Nalón",
            "Murtas",
            "Murueta",
            "Muruzábal",
            "Museros",
            "Muskiz",
            "Mutiloa",
            "Mutriku",
            "Mutxamel",
            "Muxía",
            "Muxika",
            "Nabarniz",
            "Nacimiento",
            "Nafría de Ucero",
            "Nájera",
            "Nalda",
            "Nalec",
            "Nambroca",
            "Náquera",
            "Narboneta",
            "Narón",
            "Narrillos del Álamo",
            "Narrillos del Rebollar",
            "Narros",
            "Narros de Matalayegua",
            "Narros de Saldueña",
            "Narros del Castillo",
            "Narros del Puerto",
            "Naut Aran",
            "Nava",
            "Nava de Arévalo",
            "Nava de Béjar",
            "Nava de Francia",
            "Nava de la Asunción",
            "Nava de Ricomalillo La",
            "Nava de Roa",
            "Nava de Santiago La",
            "Nava de Sotrobal",
            "Nava del Barco",
            "Nava del Rey",
            "Nava La",
            "Navacarros",
            "Navacepedilla de Corneja",
            "Navacerrada",
            "Navaconcejo",
            "Navadijos",
            "Navaescurial",
            "Navafría",
            "Navahermosa",
            "Navahondilla",
            "Navajas",
            "Navajún",
            "Naval",
            "Navalacruz",
            "Navalafuente",
            "Navalagamella",
            "Navalcán",
            "Navalcarnero",
            "Navaleno",
            "Navales",
            "Navalilla",
            "Navalmanzano",
            "Navalmoral",
            "Navalmoral de Béjar",
            "Navalmoral de la Mata",
            "Navalmoralejo",
            "Navalmorales Los",
            "Navalonguilla",
            "Navalosa",
            "Navalperal de Pinares",
            "Navalperal de Tormes",
            "Navalpino",
            "Navalquejigo",
            "Navalucillos Los",
            "Navaluenga",
            "Navalvillar de Ibor",
            "Navalvillar de Pela",
            "Navamorales",
            "Navamorcuende",
            "Navaquesera",
            "Navarcles",
            "Navardún",
            "Navares de Ayuso",
            "Navares de Enmedio",
            "Navares de las Cuevas",
            "Navaridas",
            "Navarredonda de Gredos",
            "Navarredonda de la Rinconada",
            "Navarredonda y San Mamés",
            "Navarredondilla",
            "Navarrés",
            "Navarrete",
            "Navarrevisca",
            "Navas",
            "Navas de Bureba",
            "Navas de Estena",
            "Navas de Jadraque Las",
            "Navas de Jorquera",
            "Navas de la Concepción Las",
            "Navas de Oro",
            "Navas de Riofrío",
            "Navas de San Antonio",
            "Navas de San Juan",
            "Navas del Madroño",
            "Navas del Marqués Las",
            "Navas del Rey",
            "Navascués",
            "Navascués/Nabaskoze",
            "Navasfrías",
            "Navata",
            "Navatalgordo",
            "Navatejares",
            "Navès",
            "Navezuelas",
            "Navia",
            "Navia de Suarna",
            "Navianos de Valverde",
            "Nazar",
            "Nazaret",
            "Nebreda",
            "Neda",
            "Negredo",
            "Negreira",
            "Negrilla de Palencia",
            "Negueira de Muñiz",
            "Neila",
            "Neila de San Miguel",
            "Nepas",
            "Nerja",
            "Nerpio",
            "Nerva",
            "Nestares",
            "Nevada",
            "Neves As",
            "Niebla",
            "Nieva",
            "Nieva de Cameros",
            "Nigrán",
            "Nigüelas",
            "Nigüella",
            "Niharra",
            "Níjar",
            "Nívar",
            "Noáin (Valle de Elorz)/Noain (Elortzibar)",
            "Noalejo",
            "Noblejas",
            "Noceda del Bierzo",
            "Noez",
            "Nogais As",
            "Nogal de las Huertas",
            "Nogales",
            "Nogueira de Ramuín",
            "Noguera de Albarracín",
            "Nogueras",
            "Nogueruelas",
            "Noia",
            "Noja",
            "Nolay",
            "Nombela",
            "Nombrevilla",
            "Nonaspe",
            "Noreña",
            "Nou de Berguedà La",
            "Nou de Gaià",
            "Novales",
            "Novallas",
            "Novelda",
            "Novelé/Novetlè",
            "Novés",
            "Noviercas",
            "Novillas",
            "Nucia la",
            "Nueno",
            "Nueva Carteya",
            "Nueva Villa de las Torres",
            "Nuévalos",
            "Nuevo Baztán",
            "Nuez de Ebro",
            "Nules",
            "Nulles",
            "Numancia de la Sagra",
            "Nuño Gómez",
            "Nuñomoral",
            "O Barco de Valdeorras",
            "O Carballiño",
            "O Grove",
            "O Incio",
            "O Páramo",
            "O Rosal",
            "Obanos",
            "Obejo",
            "Obón",
            "Ocaña",
            "Ocentejo",
            "Ochagavía/Otsagabia",
            "Ochánduri",
            "Oco",
            "Ocón",
            "Odèn",
            "Òdena",
            "Odieta",
            "Odón",
            "Oencia",
            "Ogassa",
            "Ogíjares",
            "Ohanes",
            "Oia",
            "Oiartzun",
            "Oimbra",
            "Oitz",
            "Ojacastro",
            "Ojén",
            "Ojós",
            "Ojos Negros",
            "Ojos-Albos",
            "Okondo",
            "Olaberria",
            "Olaibar",
            "Olazagutía",
            "Olazti/Olazagutía",
            "Olba",
            "Olea de Boedo",
            "Oleiros",
            "Olejua",
            "Olèrdola",
            "Olesa de Bonesvalls",
            "Olesa de Montserrat",
            "Oliana",
            "Olías del Rey",
            "Oliete",
            "Oliola",
            "Olite",
            "Olite/Erriberri",
            "Olius",
            "Oliva",
            "Oliva de la Frontera",
            "Oliva de Mérida",
            "Oliva de Plasencia",
            "Oliva La",
            "Olivar El",
            "Olivares",
            "Olivares de Duero",
            "Olivares de Júcar",
            "Olivella",
            "Olivenza",
            "Oliver-Valdefierro",
            "Ollauri",
            "Olleria l",
            "Ollo",
            "Olmeda de Cobeta",
            "Olmeda de Jadraque La",
            "Olmeda de la Cuesta",
            "Olmeda de las Fuentes",
            "Olmeda del Rey",
            "Olmedilla de Alarcón",
            "Olmedilla de Eliz",
            "Olmedillo de Roa",
            "Olmedo",
            "Olmedo de Camaces",
            "Olmillos de Castro",
            "Olmillos de Muñó",
            "Olmos de Esgueva",
            "Olmos de Ojeda",
            "Olmos de Peñafiel",
            "Olmos Los",
            "Olocau",
            "Olocau del Rey",
            "Olombrada",
            "Olóriz",
            "Olóriz/Oloritz",
            "Olost",
            "Olot",
            "Oltza",
            "Oluges Les",
            "Olula de Castro",
            "Olula del Río",
            "Olvan",
            "Olvega",
            "Olvena",
            "Olvera",
            "Olvés",
            "Omañas Las",
            "Omellons Els",
            "Omells de na Gaia Els",
            "Oña",
            "Oñati",
            "Oncala",
            "Onda",
            "Ondara",
            "Ondarroa",
            "Onil",
            "Onís",
            "Ontígola",
            "Ontiñena",
            "Ontinyent",
            "Ontur",
            "Onzonilla",
            "Oquillas",
            "Orba",
            "Orbada La",
            "Orbaizeta",
            "Orbaneja Riopico",
            "Orbara",
            "Orbita",
            "Orcajo",
            "Orce",
            "Orcera",
            "Orcheta",
            "Ordes",
            "Ordial El",
            "Ordis",
            "Ordizia",
            "Orea",
            "Orejana",
            "Orellana de la Sierra",
            "Orellana la Vieja",
            "Orendain",
            "Orera",
            "Orés",
            "Orexa",
            "Organyà",
            "Orgaz",
            "Órgiva",
            "Oria",
            "Orihuela",
            "Orihuela del Tremedal",
            "Orio",
            "Orís",
            "Orísoain",
            "Oristà",
            "Orkoien",
            "Ormaiztegi",
            "Oronz",
            "Oronz/Orontze",
            "Oropesa",
            "Oropesa del Mar",
            "Oropesa del Mar/Orpesa",
            "Oroso",
            "Orotava La",
            "Oroz-Betelu/Orotz-Betelu",
            "Orozko",
            "Orpí",
            "Orreaga/Roncesvalles",
            "Orrios",
            "Òrrius",
            "Ortigosa de Cameros",
            "Ortigosa de Pestaño",
            "Ortigosa del Monte",
            "Ortigueira",
            "Ortuella",
            "Orusco de Tajuña",
            "Orxeta",
            "Os de Balaguer",
            "Osa de la Vega",
            "Oseja",
            "Oseja de Sajambre",
            "Osera de Ebro",
            "Oso El",
            "Osor",
            "Osornillo",
            "Osorno la Mayor",
            "Ossa de Montiel",
            "Osso de Cinca",
            "Ossó de Sió",
            "Osuna",
            "Oteiza",
            "Otero",
            "Otero de Bodas",
            "Otero de Herreros",
            "Otívar",
            "Otos",
            "Otura",
            "Otxandio",
            "Ourense",
            "Ourol",
            "Outeiro de Rei",
            "Outes",
            "Oviedo",
            "Oyón-Oion",
            "Oza dos Ríos",
            "Pacs del Penedès",
            "Paderne",
            "Paderne de Allariz",
            "Padiernos",
            "Padilla de Abajo",
            "Padilla de Arriba",
            "Padrenda",
            "Padrón",
            "Padrones de Bureba",
            "Padul",
            "Padules",
            "Paiporta",
            "Pájara",
            "Pajarejos",
            "Pajares de Adaja",
            "Pajares de la Laguna",
            "Pajares de la Lampreana",
            "Pajares de los Oteros",
            "Pajarón",
            "Pajaroncillo",
            "Palacios de Goda",
            "Palacios de la Sierra",
            "Palacios de la Valduerna",
            "Palacios de Riopisuerga",
            "Palacios de Sanabria",
            "Palacios del Arzobispo",
            "Palacios del Pan",
            "Palacios del Sil",
            "Palacios y Villafranca Los",
            "Palaciosrubios",
            "Palafolls",
            "Palafrugell",
            "Palamós",
            "Palanques",
            "Palas de Rei",
            "Palau dAnglesola El",
            "Palau de Santa Eulàlia",
            "Palau-sator",
            "Palau-saverdera",
            "Palau-solità i Plegamans",
            "Palazuelo de Vedija",
            "Palazuelos de Eresma",
            "Palazuelos de la Sierra",
            "Palazuelos de Muñó",
            "Palencia",
            "Palencia de Negrilla",
            "Palenciana",
            "Palenzuela",
            "Pallaresos",
            "Pallejà",
            "Palma",
            "Palma de Cervelló La",
            "Palma de Gandía",
            "Palma dEbre",
            "Palma del Condado La",
            "Palma del Río",
            "Pálmaces de Jadraque",
            "Palmanova",
            "Palmas de Gran Canaria Las",
            "Palmera",
            "Palo",
            "Palol de Revardit",
            "Palomar de Arroyos",
            "Palomar el",
            "Palomares del Campo",
            "Palomares del Río",
            "Palomas",
            "Palomeque",
            "Palomera",
            "Palomero",
            "Palos de la Frontera",
            "Pals",
            "Pampaneira",
            "Pampliega",
            "Pamplona",
            "Pamplona/Iruña",
            "Pancorbo",
            "Pancrudo",
            "Paniza",
            "Panticosa",
            "Pantoja",
            "Pantón",
            "Papatrigo",
            "Papiol El",
            "Paracuellos",
            "Paracuellos de Jarama",
            "Paracuellos de Jiloca",
            "Paracuellos de la Ribera",
            "Parada de Arriba",
            "Parada de Rubiales",
            "Parada de Sil",
            "Paradas",
            "Paradela",
            "Paradinas de San Juan",
            "Páramo de Boedo",
            "Páramo del Sil",
            "Páramo O",
            "Parauta",
            "Parcent",
            "Pardilla",
            "Pardines",
            "Pardos",
            "Paredes",
            "Paredes de Escalona",
            "Paredes de Nava",
            "Paredes de Sigüenza",
            "Pareja",
            "Parets del Vallès",
            "Parla",
            "Parlavà",
            "Parra de las Vegas La",
            "Parra La",
            "Parral El",
            "Parras de Castellote Las",
            "Parres",
            "Parrilla La",
            "Parrillas",
            "Partaloa",
            "Partido de la Sierra en Tobalina",
            "Pasaia",
            "Pasarón de la Vera",
            "Pascualcobo",
            "Paso El",
            "Passanant i Belltall",
            "Pastores",
            "Pastoriza A",
            "Pastrana",
            "Pastriz",
            "Paterna",
            "Paterna de Rivera",
            "Paterna del Campo",
            "Paterna del Madera",
            "Paterna del Río",
            "Patones",
            "Pau",
            "Paüls",
            "Pavías",
            "Paymogo",
            "Payo de Ojeda",
            "Payo El",
            "Pazos de Borbén",
            "Pazuengos",
            "Peal de Becerro",
            "Pechina",
            "Pedernoso El",
            "Pedrafita do Cebreiro",
            "Pedraja de Portillo La",
            "Pedrajas de San Esteban",
            "Pedralba",
            "Pedralba de la Pradería",
            "Pedraza",
            "Pedraza de Alba",
            "Pedraza de Campos",
            "Pedregal El",
            "Pedreguer",
            "Pedrera",
            "Pedret i Marzà",
            "Pedrezuela",
            "Pedro Abad",
            "Pedro Bernardo",
            "Pedro Martínez",
            "Pedro Muñoz",
            "Pedro-Rodríguez",
            "Pedroche",
            "Pedrola",
            "Pedroñeras Las",
            "Pedrosa de Duero",
            "Pedrosa de la Vega",
            "Pedrosa de Río Úrbel",
            "Pedrosa del Páramo",
            "Pedrosa del Príncipe",
            "Pedrosa del Rey",
            "Pedrosas Las",
            "Pedrosillo de Alba",
            "Pedrosillo de los Aires",
            "Pedrosillo el Ralo",
            "Pedroso",
            "Pedroso de Acim",
            "Pedroso de la Armuña El",
            "Pedroso El",
            "Pegalajar",
            "Pego",
            "Pego El",
            "Peguera",
            "Peguerinos",
            "Pelabravo",
            "Pelahustán",
            "Pelarrodríguez",
            "Pelayos",
            "Pelayos de la Presa",
            "Pelayos del Arroyo",
            "Peleagonzalo",
            "Peleas de Abajo",
            "Peligros",
            "Peña La",
            "Peñacaballera",
            "Peñacerrada-Urizaharra",
            "Peñafiel",
            "Peñaflor",
            "Peñaflor de Hornija",
            "Penagos",
            "Penàguila",
            "Peñalba",
            "Peñalba de Ávila",
            "Peñalén",
            "Peñalsordo",
            "Peñalver",
            "Peñamellera Alta",
            "Peñamellera Baja",
            "Peñaparda",
            "Peñaranda de Bracamonte",
            "Peñaranda de Duero",
            "Peñarandilla",
            "Peñarroya de Tastavins",
            "Peñarroya-Pueblonuevo",
            "Peñarrubia",
            "Peñas de Riglos Las",
            "Peñas de San Pedro",
            "Peñascosa",
            "Peñausende",
            "Penelles",
            "Peníscola",
            "Peníscola/Peñíscola",
            "Pepino",
            "Peque",
            "Pera La",
            "Peracense",
            "Perafita",
            "Perafort",
            "Peral de Arlanza",
            "Peral El",
            "Peralada",
            "Peraleda de la Mata",
            "Peraleda de San Román",
            "Peraleda del Zaucejo",
            "Peraleja La",
            "Peralejos",
            "Peralejos de Abajo",
            "Peralejos de Arriba",
            "Peralejos de las Truchas",
            "Perales",
            "Perales de Tajuña",
            "Perales del Alfambra",
            "Perales del Puerto",
            "Peralta",
            "Peralta de Alcofea",
            "Peralta de Calasanz",
            "Peralta/Azkoien",
            "Peraltilla",
            "Peralveche",
            "Peramola",
            "Peranzanes",
            "Perarrúa",
            "Perdigón El",
            "Perdiguera",
            "Pereiro de Aguiar",
            "Pereiro de Aguiar O",
            "Perelló El",
            "Pereña de la Ribera",
            "Pereruela",
            "Periana",
            "Perilla de Castro",
            "Pernía La",
            "Peromingo",
            "Perosillo",
            "Peroxa A",
            "Pertusa",
            "Pesaguero",
            "Pescueza",
            "Pesga La",
            "Pesoz",
            "Pesquera",
            "Pesquera de Duero",
            "Pesquera La",
            "Petilla de Aragón",
            "Petín",
            "Petra",
            "Petrer",
            "Petrés",
            "Pétrola",
            "Peza La",
            "Pezuela de las Torres",
            "Pías",
            "Picanya",
            "Picassent",
            "Picazo El",
            "Picón",
            "Piedrabuena",
            "Piedrahíta",
            "Piedrahita de Castro",
            "Piedralaves",
            "Piedramillera",
            "Piedras Albas",
            "Piedratajada",
            "Piélagos",
            "Piera",
            "Piérnigas",
            "Pilar de la Horadada",
            "Pilas",
            "Piles",
            "Piles Les",
            "Piloña",
            "Piña de Campos",
            "Pina de Ebro",
            "Piña de Esgueva",
            "Pina de Montalgrao",
            "Piñar",
            "Pinar de Chamartín",
            "Pinar de El Hierro El",
            "Pinar El",
            "Pinarejo",
            "Pinarejos",
            "Pinarnegrillo",
            "Pineda de Gigüela",
            "Pineda de la Sierra",
            "Pineda de Mar",
            "Pineda Trasmonte",
            "Pinedas",
            "Piñel de Abajo",
            "Piñel de Arriba",
            "Pinell de Brai",
            "Pinell de Solsonès",
            "Piñero El",
            "Pinet",
            "Pinilla de Jadraque",
            "Pinilla de los Barruecos",
            "Pinilla de los Moros",
            "Pinilla de Molina",
            "Pinilla de Toro",
            "Pinilla del Campo",
            "Pinilla del Valle",
            "Pinilla Trasmonte",
            "Pinillos",
            "Pino de Tormes El",
            "Pino del Oro",
            "Pino del Río",
            "Pino O",
            "Pinofranqueado",
            "Piñor",
            "Pinós",
            "Pinós el/Pinoso",
            "Pinos Genil",
            "Pinos Puente",
            "Pinoso",
            "Pinseque",
            "Pintanos Los",
            "Pinto",
            "Piñuécar-Gandullas",
            "Piornal",
            "Pioz",
            "Piqueras",
            "Piqueras del Castillo",
            "Pira",
            "Piracés",
            "Pitarque",
            "Pitiegua",
            "Pitillas",
            "Pizarra",
            "Pizarral",
            "Pla de Santa Maria",
            "Pla del Penedès El",
            "Plan",
            "Planes",
            "Planes dHostoles Les",
            "Planoles",
            "Plans de Sió Els",
            "Plasencia",
            "Plasencia de Jalón",
            "Plasenzuela",
            "Playa Blanca",
            "Playa de las Américas",
            "Playa del Ingles",
            "Pleitas",
            "Plenas",
            "Plentzia",
            "Pliego",
            "Plou",
            "Poal El",
            "Pobla de Benifassà la",
            "Pobla de Cérvoles La",
            "Pobla de Claramunt La",
            "Pobla de Farnals la",
            "Pobla de Lillet La",
            "Pobla de Mafumet",
            "Pobla de Massaluc",
            "Pobla de Montornès",
            "Pobla de Segur La",
            "Pobla de Vallbona la",
            "Pobla del Duc la",
            "Pobla Llarga la",
            "Pobla Sa",
            "Pobla Tornesa la",
            "Población de Arroyo",
            "Población de Campos",
            "Población de Cerrato",
            "Pobladura de Pelayo García",
            "Pobladura de Valderaduey",
            "Pobladura del Valle",
            "Poblete",
            "Poblets els",
            "Pobo de Dueñas El",
            "Pobo El",
            "Poboleda",
            "Pobra de Trives A",
            "Pobra do Brollón A",
            "Pobra do Caramiñal A",
            "Poio",
            "Pol",
            "Pola de Gordón La",
            "Polaciones",
            "Polán",
            "Polanco",
            "Poleñino",
            "Polentinos",
            "Polícar",
            "Polinyà",
            "Polinyà de Xúquer",
            "Pollença",
            "Pollos",
            "Polop",
            "Polopos",
            "Pomar de Valdivia",
            "Pomer",
            "Ponferrada",
            "Ponga",
            "Pont dArmentera",
            "Pont de Bar El",
            "Pont de Molins",
            "Pont de Suert El",
            "Pont de Vilomara i Rocafort El",
            "Ponte Caldelas",
            "Ponteareas",
            "Ponteceso",
            "Pontecesures",
            "Pontedeume",
            "Pontedeva",
            "Pontenova A",
            "Pontes de García Rodríguez As",
            "Pontevedra",
            "Pontils",
            "Pontons",
            "Pontós",
            "Ponts",
            "Porcuna",
            "Porqueira",
            "Porqueres",
            "Porrera",
            "Porreres",
            "Porriño O",
            "Port d'Alcúdia",
            "Port de la Selva El",
            "Port de Pollença",
            "Port de Sóller",
            "Portaje",
            "Portalrubio de Guadamejud",
            "Portas",
            "Portbou",
            "Portell de Morella",
            "Portella La",
            "Portellada La",
            "Portezuelo",
            "Portilla",
            "Portillo",
            "Portillo de Soria",
            "Portillo de Toledo",
            "Porto",
            "Porto Cristo",
            "Porto do Son",
            "Portocolom",
            "Portomarín",
            "Portugalete",
            "Pórtugos",
            "Porzuna",
            "Posada de Valdeón",
            "Posadas",
            "Potes",
            "Potríes",
            "Poveda",
            "Poveda de la Sierra",
            "Poveda de las Cintas",
            "Póveda de Soria La",
            "Povedilla",
            "Poyales del Hoyo",
            "Poyatos",
            "Poza de la Sal",
            "Poza de la Vega",
            "Pozal de Gallinas",
            "Pozaldez",
            "Pozalmuro",
            "Pozán de Vero",
            "Pozanco",
            "Pozo Alcón",
            "Pozo Cañada",
            "Pozo de Almoguera",
            "Pozo de Guadalajara",
            "Pozo de Urama",
            "Pozo-Lorente",
            "Pozoamargo",
            "Pozoantiguo",
            "Pozoblanco",
            "Pozohondo",
            "Pozondón",
            "Pozorrubielos de la Mancha",
            "Pozorrubio",
            "Pozos de Hinojo",
            "Pozuel de Ariza",
            "Pozuel del Campo",
            "Pozuelo",
            "Pozuelo de Alarcón",
            "Pozuelo de Aragón",
            "Pozuelo de Calatrava",
            "Pozuelo de la Orden",
            "Pozuelo de Tábara",
            "Pozuelo de Zarzón",
            "Pozuelo del Páramo",
            "Pozuelo del Rey",
            "Pozuelo El",
            "Pozuelos de Calatrava Los",
            "Pradales",
            "Prádanos de Bureba",
            "Prádanos de Ojeda",
            "Pradejón",
            "Pradell de la Teixeta",
            "Prádena",
            "Prádena de Atienza",
            "Prádena del Rincón",
            "Prades",
            "Pradilla de Ebro",
            "Pradillo",
            "Prado",
            "Prado de la Guzpeña",
            "Prado del Rey",
            "Pradoluengo",
            "Prados Redondos",
            "Pradosegar",
            "Prat de Comte",
            "Prat de Llobregat El",
            "Pratdip",
            "Prats de Lluçanès",
            "Prats de Rei Els",
            "Prats i Sansor",
            "Pravia",
            "Preixana",
            "Preixens",
            "Préjano",
            "Premià de Dalt",
            "Premià de Mar",
            "Presencio",
            "Preses Les",
            "Priaranza del Bierzo",
            "Priego",
            "Priego de Córdoba",
            "Primer Ensanche",
            "Prioro",
            "Proaza",
            "Provencio El",
            "Provincia de Alicante",
            "Provincia de Ávila",
            "Provincia de Burgos",
            "Provincia de Cantabria",
            "Província de Castelló",
            "Provincia de Huesca",
            "Provincia de La Rioja",
            "Provincia de Las Palmas",
            "Provincia de León",
            "Provincia de Madrid",
            "Provincia de Navarra",
            "Provincia de Palencia",
            "Provincia de Salamanca",
            "Provincia de Santa Cruz de Tenerife",
            "Provincia de Segovia",
            "Provincia de Soria",
            "Provincia de Teruel",
            "Província de València",
            "Provincia de Valladolid",
            "Provincia de Zamora",
            "Provincia de Zaragoza",
            "Prullans",
            "Pruna",
            "Puçol",
            "Puebla de Albortón",
            "Puebla de Alcocer",
            "Puebla de Alfindén",
            "Puebla de Alfindén La",
            "Puebla de Almenara",
            "Puebla de Almoradiel La",
            "Puebla de Arenoso",
            "Puebla de Arganzón La",
            "Puebla de Azaba",
            "Puebla de Beleña",
            "Puebla de Castro La",
            "Puebla de Cazalla La",
            "Puebla de Don Fadrique",
            "Puebla de Don Rodrigo",
            "Puebla de Guzmán",
            "Puebla de Híjar La",
            "Puebla de la Calzada",
            "Puebla de la Reina",
            "Puebla de la Sierra",
            "Puebla de Lillo",
            "Puebla de los Infantes La",
            "Puebla de Montalbán La",
            "Puebla de Obando",
            "Puebla de Pedraza",
            "Puebla de San Medel",
            "Puebla de San Miguel",
            "Puebla de Sanabria",
            "Puebla de Sancho Pérez",
            "Puebla de Valdavia La",
            "Puebla de Valles",
            "Puebla de Valverde La",
            "Puebla de Yeltes",
            "Puebla del Maestre",
            "Puebla del Príncipe",
            "Puebla del Prior",
            "Puebla del Río La",
            "Puebla del Salvador",
            "Pueblanueva La",
            "Pueblica de Valverde",
            "Pueblonuevo del Guadiana",
            "Puendeluna",
            "Puente de Domingo Flórez",
            "Puente de Génave",
            "Puente de Montañana",
            "Puente de Vallecas",
            "Puente del Arzobispo El",
            "Puente del Congosto",
            "Puente Genil",
            "Puente la Reina",
            "Puente la Reina de Jaca",
            "Puente la Reina/Gares",
            "Puente Viesgo",
            "Puentedura",
            "Puentes Viejas",
            "Puerta de Segura La",
            "Puertas",
            "Puerto Castilla",
            "Puerto de Béjar",
            "Puerto de la Cruz",
            "Puerto de San Vicente",
            "Puerto de Santa Cruz",
            "Puerto de Santa María El",
            "Puerto del Carmen",
            "Puerto del Rosario",
            "Puerto del Rosario",
            "Puerto Lápice",
            "Puerto Lumbreras",
            "Puerto Moral",
            "Puerto Real",
            "Puerto Rico",
            "Puerto Seguro",
            "Puerto Serrano",
            "Puértolas",
            "Puertollano",
            "Puertomingalvo",
            "Pueyo",
            "Pueyo de Araguás El",
            "Pueyo de Santa Cruz",
            "Puig",
            "Puig-reig",
            "Puigcerdà",
            "Puigdàlber",
            "Puiggròs",
            "Puigpelat",
            "Puigpunyent",
            "Puigverd dAgramunt",
            "Puigverd de Lleida",
            "Pujalt",
            "Pujerra",
            "Pulgar",
            "Pulianas",
            "Pulpí",
            "Punta de Mujeres",
            "Punta Umbría",
            "Puntagorda",
            "Puntallana",
            "Punxín",
            "Puras",
            "Purchena",
            "Purujosa",
            "Purullena",
            "Quar La",
            "Quart",
            "Quart de les Valls",
            "Quart de Poblet",
            "Quartell",
            "Quatretonda",
            "Quatretondeta",
            "Quel",
            "Quemada",
            "Quéntar",
            "Quer",
            "Queralbs",
            "Quero",
            "Querol",
            "Quesa",
            "Quesada",
            "Quicena",
            "Quijorna",
            "Quiñonería",
            "Quintana de la Serena",
            "Quintana del Castillo",
            "Quintana del Marco",
            "Quintana del Pidio",
            "Quintana del Puente",
            "Quintana Redonda",
            "Quintana y Congosto",
            "Quintanabureba",
            "Quintanaélez",
            "Quintanaortuño",
            "Quintanapalla",
            "Quintanar de la Orden",
            "Quintanar de la Sierra",
            "Quintanar del Rey",
            "Quintanas de Gormaz",
            "Quintanavides",
            "Quintanilla de Arriba",
            "Quintanilla de la Mata",
            "Quintanilla de Onésimo",
            "Quintanilla de Onsoña",
            "Quintanilla de Trigueros",
            "Quintanilla de Urz",
            "Quintanilla del Agua y Tordueles",
            "Quintanilla del Coco",
            "Quintanilla del Molar",
            "Quintanilla del Monte",
            "Quintanilla del Olmo",
            "Quintanilla San García",
            "Quintanilla Vivar",
            "Quintanillas Las",
            "Quintela de Leirado",
            "Quinto",
            "Quiroga",
            "Quirós",
            "Quiruelas de Vidriales",
            "Quismondo",
            "Rábade",
            "Rabanales",
            "Rabanera",
            "Rabanera del Pinar",
            "Rábano",
            "Rábano de Aliste",
            "Rábanos",
            "Rábanos Los",
            "Rabé de las Calzadas",
            "Rabós",
            "Rada de Haro",
            "Rafal",
            "Ráfales",
            "Rafelbuñol/Rafelbunyol",
            "Rafelcofer",
            "Rafelguaraf",
            "Ràfol dAlmúnia El",
            "Ráfol de Salem",
            "Rágama",
            "Rágol",
            "Rairiz de Veiga",
            "Rajadell",
            "Ramales de la Victoria",
            "Rambla La",
            "Ramirás",
            "Ramiro",
            "Rapariegos",
            "Rascafría",
            "Rasillo de Cameros El",
            "Rasines",
            "Rasquera",
            "Rasueros",
            "Real",
            "Real de Gandía",
            "Real de la Jara El",
            "Real de Montroi",
            "Real de San Vicente El",
            "Real Sitio de San Ildefonso",
            "Realejo Alto",
            "Realejos Los",
            "Rebollar",
            "Rebolledo de la Torre",
            "Rebollo",
            "Rebollosa de Jadraque",
            "Recas",
            "Recueja La",
            "Recuenco El",
            "Recuerda",
            "Redal El",
            "Redecilla del Camino",
            "Redecilla del Campo",
            "Redonda La",
            "Redondela",
            "Redován",
            "Redueña",
            "Regencós",
            "Regueras de Arriba",
            "Regueras Las",
            "Regumiel de la Sierra",
            "Reíllo",
            "Reina",
            "Reinosa",
            "Reinoso",
            "Reinoso de Cerrato",
            "Relleu",
            "Rellinars",
            "Rello",
            "Remolinos",
            "Remondo",
            "Rena",
            "Renau",
            "Renedo de Esgueva",
            "Renedo de la Vega",
            "Renera",
            "Renieblas",
            "Reocín",
            "Requejo",
            "Requena",
            "Requena de Campos",
            "Respenda de la Peña",
            "Retamal de Llerena",
            "Retamoso de la Jara",
            "Retascón",
            "Retiendas",
            "Retiro",
            "Retortillo",
            "Retortillo de Soria",
            "Retuerta",
            "Retuerta del Bullaque",
            "Reus",
            "Revellinos",
            "Revenga de Campos",
            "Revilla de Collazos",
            "Revilla del Campo",
            "Revilla Vallejera",
            "Revilla y Ahedo La",
            "Revillarruz",
            "Reyero",
            "Rezmondo",
            "Reznos",
            "Riaguas de San Bartolomé",
            "Rialp",
            "Riaño",
            "Rianxo",
            "Riaza",
            "Riba de Escalote La",
            "Riba de Saelices",
            "Riba La",
            "Riba-roja de Túria",
            "Riba-roja dEbre",
            "Ribadavia",
            "Ribadedeva",
            "Ribadeo",
            "Ribadesella",
            "Ribadumia",
            "Ribaforada",
            "Ribafrecha",
            "Ribamontán al Mar",
            "Ribamontán al Monte",
            "Ribarroja del Turia",
            "Ribas de Campos",
            "Ribas de Sil",
            "Ribatejada",
            "Ribeira",
            "Ribeira de Piquín",
            "Ribera Baja/Erribera Beitia",
            "Ribera de Arriba",
            "Ribera del Fresno",
            "Ribera dOndara",
            "Ribera dUrgellet",
            "Riberos de la Cueza",
            "Ribes de Freser",
            "Ribesalbes",
            "Ribota",
            "Ricla",
            "Ricote",
            "Riego de la Vega",
            "Riello",
            "Riells i Viabrea",
            "Rielves",
            "Riera de Gaià La",
            "Rillo",
            "Rillo de Gallo",
            "Rincón de la Victoria",
            "Rincón de Soto",
            "Rinconada de la Sierra La",
            "Rinconada La",
            "Riner",
            "Riocabado",
            "Riocavado de la Sierra",
            "Riodeva",
            "Riofrío",
            "Ríofrío de Aliste",
            "Riofrío de Riaza",
            "Riofrío del Llano",
            "Ríogordo",
            "Rioja",
            "Riola",
            "Ríolobos",
            "Rionansa",
            "Rionegro del Puente",
            "Riópar",
            "Riós",
            "Riosa",
            "Rioseco de Soria",
            "Rioseco de Tapia",
            "Ríotorto",
            "Riotuerto",
            "Ripoll",
            "Ripollet",
            "Risco",
            "Riu de Cerdanya",
            "Riudarenes",
            "Riudaura",
            "Riudecanyes",
            "Riudecols",
            "Riudellots de la Selva",
            "Riudoms",
            "Riumors",
            "Rivas-Vaciamadrid",
            "Rivilla de Barajas",
            "Roa",
            "Roales",
            "Roales de Campos",
            "Robla La",
            "Robladillo",
            "Robleda",
            "Robleda-Cervantes",
            "Robledillo de Gata",
            "Robledillo de la Jara",
            "Robledillo de la Vera",
            "Robledillo de Mohernando",
            "Robledillo de Trujillo",
            "Robledo",
            "Robledo de Chavela",
            "Robledo de Corpes",
            "Robledo del Mazo",
            "Robledo El",
            "Robledollano",
            "Robliza de Cojos",
            "Robregordo",
            "Robres",
            "Robres del Castillo",
            "Roca de la Sierra La",
            "Roca del Vallès La",
            "Rocafort",
            "Rocafort de Queralt",
            "Rociana del Condado",
            "Roda de Andalucía La",
            "Roda de Barà",
            "Roda de Eresma",
            "Roda de Ter",
            "Roda La",
            "Rodeiro",
            "Ródenas",
            "Rodezno",
            "Rodonyà",
            "Roelos de Sayago",
            "Rois",
            "Rojales",
            "Rojas",
            "Rollamienta",
            "Rollán",
            "Romana la",
            "Romangordo",
            "Romanillos de Atienza",
            "Romanones",
            "Romanos",
            "Romanzado",
            "Romeral El",
            "Roncal/Erronkari",
            "Ronda",
            "Ronquillo El",
            "Roperuelos del Páramo",
            "Roquetas de Mar",
            "Roquetes",
            "Rosal de la Frontera",
            "Rosal O",
            "Rosalejo",
            "Rosario El",
            "Rosell",
            "Roses",
            "Rosinos de la Requejada",
            "Rossell",
            "Rosselló",
            "Rota",
            "Rotglà i Corberà",
            "Rotglá y Corbera",
            "Rótova",
            "Roturas",
            "Rourell",
            "Royo El",
            "Royuela",
            "Royuela de Río Franco",
            "Rozalén del Monte",
            "Rozas de Madrid Las",
            "Rozas de Puerto Real",
            "Rozas de Valdearroyo Las",
            "Rúa A",
            "Ruanes",
            "Rubena",
            "Rubí",
            "Rubí de Bracamonte",
            "Rubiá",
            "Rubiales",
            "Rubielos de la Cérida",
            "Rubielos de Mora",
            "Rubió",
            "Rubio El",
            "Rubite",
            "Rublacedo de Abajo",
            "Rucandio",
            "Rueda",
            "Rueda de Jalón",
            "Rueda de la Sierra",
            "Ruente",
            "Ruesca",
            "Ruesga",
            "Rugat",
            "Ruidera",
            "Ruiloba",
            "Rupià",
            "Rupit i Pruit",
            "Rus",
            "Rute",
            "s'Arenal",
            "sa Pobla",
            "Sabadell",
            "Sabero",
            "Sabiñán",
            "Sabiñánigo",
            "Sabiote",
            "Sacañet",
            "Sacecorbo",
            "Saceda-Trasierra",
            "Sacedón",
            "Saceruela",
            "Sacramenia",
            "Sada",
            "Sádaba",
            "Saelices",
            "Saelices de la Sal",
            "Saelices de Mayorga",
            "Saelices el Chico",
            "Sagàs",
            "Sagra",
            "Sagrada La",
            "Sagunto",
            "Sagunto/Sagunt",
            "Sahagún",
            "Sahugo El",
            "Sahún",
            "Sajazarra",
            "Salamanca",
            "Salamanca",
            "Salar",
            "Salares",
            "Salas",
            "Salas Altas",
            "Salas Bajas",
            "Salas de Bureba",
            "Salas de los Infantes",
            "Salàs de Pallars",
            "Salce",
            "Salceda de Caselas",
            "Salcedillo",
            "Saldaña",
            "Saldaña de Burgos",
            "Saldeana",
            "Saldes",
            "Saldías",
            "Saldón",
            "Salduero",
            "Salem",
            "Sales de Llierca",
            "Salillas",
            "Salillas de Jalón",
            "Salinas",
            "Salinas de Oro",
            "Salinas de Oro/Jaitz",
            "Salinas de Pisuerga",
            "Salinas del Manzano",
            "Salines Ses",
            "Salinillas de Bureba",
            "Sallent",
            "Sallent de Gállego",
            "Salmerón",
            "Salmeroncillos",
            "Salmoral",
            "Salobral",
            "Salobre",
            "Salobreña",
            "Salomó",
            "Salorino",
            "Salou",
            "Salt",
            "Salteras",
            "Salvacañete",
            "Salvadiós",
            "Salvador de Zapardiel",
            "Salvaleón",
            "Salvaterra de Miño",
            "Salvatierra de Esca",
            "Salvatierra de los Barros",
            "Salvatierra de Santiago",
            "Salvatierra de Tormes",
            "Salvatierra/Agurain",
            "Salzadella la",
            "Samaniego",
            "Samboal",
            "Samir de los Caños",
            "Samos",
            "Samper de Calanda",
            "Samper del Salz",
            "San Adrián",
            "San Adrián de Juarros",
            "San Adrián del Valle",
            "San Agustín",
            "San Agustín del Guadalix",
            "San Agustín del Pozo",
            "San Amaro",
            "San Andrés del Congosto",
            "San Andrés del Rabanedo",
            "San Andrés del Rey",
            "San Andrés y Sauces",
            "San Antonio de Benagéber",
            "San Asensio",
            "San Bartolomé",
            "San Bartolomé de Béjar",
            "San Bartolomé de Corneja",
            "San Bartolomé de la Torre",
            "San Bartolomé de las Abiertas",
            "San Bartolomé de Pinares",
            "San Bartolomé de Tirajana",
            "San Blas-Canillejas",
            "San Carlos del Valle",
            "San Cebrián de Campos",
            "San Cebrián de Castro",
            "San Cebrián de Mazote",
            "San Cebrián de Mudá",
            "San Cibrao das Viñas",
            "San Clemente",
            "San Cristóbal de Boedo",
            "San Cristóbal de Cuéllar",
            "San Cristóbal de Entreviñas",
            "San Cristóbal de la Cuesta",
            "San Cristóbal de La Laguna",
            "San Cristóbal de la Polantera",
            "San Cristóbal de la Vega",
            "San Cristóbal de Segovia",
            "San Cristovo de Cea",
            "San Emiliano",
            "San Esteban de Gormaz",
            "San Esteban de la Sierra",
            "San Esteban de Litera",
            "San Esteban de los Patos",
            "San Esteban de Nogales",
            "San Esteban de Zapardiel",
            "San Esteban del Molar",
            "San Esteban del Valle",
            "San Felices",
            "San Felices de Buelna",
            "San Felices de los Gallegos",
            "San Fernando",
            "San Fernando de Henares",
            "San Fulgencio",
            "San García de Ingelmos",
            "San Ildefonso",
            "San Isidro",
            "San Javier",
            "San José del Valle",
            "San Juan de Alicante",
            "San Juan de Aznalfarache",
            "San Juan de Énova",
            "San Juan de Gredos",
            "San Juan de la Encinilla",
            "San Juan de la Nava",
            "San Juan de la Rambla",
            "San Juan de Moró",
            "San Juan de Plan",
            "San Juan del Molinillo",
            "San Juan del Monte",
            "San Juan del Olmo",
            "San Juan del Puerto",
            "San Justo",
            "San Justo de la Vega",
            "San Leonardo de Yagüe",
            "San Llorente",
            "San Lorenzo de Calatrava",
            "San Lorenzo de El Escorial",
            "San Lorenzo de la Parrilla",
            "San Lorenzo de Tormes",
            "San Mamés de Burgos",
            "San Mamés de Campos",
            "San Martín de Boniches",
            "San Martín de Elines",
            "San Martín de la Vega",
            "San Martín de la Vega del Alberche",
            "San Martín de la Virgen de Moncayo",
            "San Martín de Montalbán",
            "San Martín de Oscos",
            "San Martín de Pusa",
            "San Martín de Rubiales",
            "San Martín de Trevejo",
            "San Martín de Unx",
            "San Martín de Valdeiglesias",
            "San Martín de Valderaduey",
            "San Martín de Valvení",
            "San Martín del Castañar",
            "San Martín del Pimpollar",
            "San Martín del Rey Aurelio",
            "San Martín del Río",
            "San Martín y Mudrián",
            "San Mateo de Gállego",
            "San Miguel De Abona",
            "San Miguel de Aguayo",
            "San Miguel de Bernuy",
            "San Miguel de Corneja",
            "San Miguel de la Ribera",
            "San Miguel de Meruelo",
            "San Miguel de Salinas",
            "San Miguel de Serrezuela",
            "San Miguel de Valero",
            "San Miguel del Arroyo",
            "San Miguel del Cinca",
            "San Miguel del Pino",
            "San Miguel del Robledo",
            "San Miguel del Valle",
            "San Millán de la Cogolla",
            "San Millán de Lara",
            "San Millán de los Caballeros",
            "San Millán de Yécora",
            "San Millán/Donemiliaga",
            "San Morales",
            "San Muñoz",
            "San Nicolás",
            "San Nicolás del Puerto",
            "San Pablo de la Moraleja",
            "San Pablo de los Montes",
            "San Pascual",
            "San Pedro",
            "San Pedro Bercianos",
            "San Pedro de Ceque",
            "San Pedro de Gaíllos",
            "San Pedro de la Nave-Almendra",
            "San Pedro de Latarce",
            "San Pedro de Mérida",
            "San Pedro de Rozados",
            "San Pedro del Arroyo",
            "San Pedro del Pinatar",
            "San Pedro del Romeral",
            "San Pedro del Valle",
            "San Pedro Manrique",
            "San Pedro Palmiches",
            "San Pelayo",
            "San Pelayo de Guareña",
            "San Rafael del Río",
            "San Román de Cameros",
            "San Román de Hornija",
            "San Román de la Cuba",
            "San Román de los Montes",
            "San Roque",
            "San Roque de Riomiera",
            "San Sadurniño",
            "San Salvador",
            "San Sebastián de la Gomera",
            "San Sebastián de La Gomera",
            "San Sebastián de los Ballesteros",
            "San Sebastián de los Reyes",
            "San Silvestre de Guzmán",
            "San Tirso de Abres",
            "San Torcuato",
            "San Vicent del Raspeig",
            "San Vicente de Alcántara",
            "San Vicente de Arévalo",
            "San Vicente de la Barquera",
            "San Vicente de la Cabeza",
            "San Vicente de la Sonsierra",
            "San Vicente del Palacio",
            "San Vicente del Raspeig/Sant Vicent del Raspeig",
            "San Vicente del Valle",
            "San Vitero",
            "San Xoán de Río",
            "Sanaüja",
            "Sancedo",
            "Sanchidrián",
            "Sanchón de la Ribera",
            "Sanchón de la Sagrada",
            "Sanchonuño",
            "Sanchorreja",
            "Sanchotello",
            "Sancti-Spíritus",
            "Sandiás",
            "Sando",
            "Sanet y Negrals",
            "Sangarcía",
            "Sangarrén",
            "Sangonera la Verde",
            "Sangüesa/Zangoza",
            "Sanlúcar de Barrameda",
            "Sanlúcar de Guadiana",
            "Sanlúcar la Mayor",
            "Sansol",
            "Sant Adrià de Besòs",
            "Sant Agustí de Lluçanès",
            "Sant Andreu de la Barca",
            "Sant Andreu de Llavaneres",
            "Sant Andreu Salou",
            "Sant Aniol de Finestres",
            "Sant Antoni de Portmany",
            "Sant Antoni de Vilamajor",
            "Sant Bartomeu del Grau",
            "Sant Boi de Llobregat",
            "Sant Boi de Lluçanès",
            "Sant Carles de la Ràpita",
            "Sant Cebrià de Vallalta",
            "Sant Celoni",
            "Sant Climent de Llobregat",
            "Sant Climent Sescebes",
            "Sant Cugat del Vallès",
            "Sant Cugat Sesgarrigues",
            "Sant Esteve de la Sarga",
            "Sant Esteve de Palautordera",
            "Sant Esteve Sesrovires",
            "Sant Feliu de Buixalleu",
            "Sant Feliu de Codines",
            "Sant Feliu de Guíxols",
            "Sant Feliu de Llobregat",
            "Sant Feliu de Pallerols",
            "Sant Feliu Sasserra",
            "Sant Ferriol",
            "Sant Fost de Campsentelles",
            "Sant Francesc de Formentera",
            "Sant Fruitós de Bages",
            "Sant Gregori",
            "Sant Guim de Freixenet",
            "Sant Guim de la Plana",
            "Sant Hilari Sacalm",
            "Sant Hipòlit de Voltregà",
            "Sant Iscle de Vallalta",
            "Sant Jaume de Frontanyà",
            "Sant Jaume de Llierca",
            "Sant Jaume dels Domenys",
            "Sant Jaume dEnveja",
            "Sant Joan",
            "Sant Joan dAlacant",
            "Sant Joan de Labritja",
            "Sant Joan de les Abadesses",
            "Sant Joan de Mollet",
            "Sant Joan de Moró",
            "Sant Joan de Vilatorrada",
            "Sant Joan Despí",
            "Sant Joan les Fonts",
            "Sant Joanet",
            "Sant Jordi",
            "Sant Jordi Desvalls",
            "Sant Jordi/San Jorge",
            "Sant Josep de sa Talaia",
            "Sant Julià de Cerdanyola",
            "Sant Julià de Ramis",
            "Sant Julià de Vilatorta",
            "Sant Julià del Llor i Bonmatí",
            "Sant Just Desvern",
            "Sant Llorenç de la Muga",
            "Sant Llorenç de Morunys",
            "Sant Llorenç des Cardassar",
            "Sant Llorenç dHortons",
            "Sant Llorenç Savall",
            "Sant Lluís",
            "Sant Martí dAlbars",
            "Sant Martí de Centelles",
            "Sant Martí de Llémena",
            "Sant Martí de Riucorb",
            "Sant Martí de Tous",
            "Sant Martí Sarroca",
            "Sant Martí Sesgueioles",
            "Sant Martí Vell",
            "Sant Mateu",
            "Sant Mateu de Bages",
            "Sant Miquel de Campmajor",
            "Sant Miquel de Fluvià",
            "Sant Mori",
            "Sant Pau de Segúries",
            "Sant Pere de Ribes",
            "Sant Pere de Riudebitlles",
            "Sant Pere de Torelló",
            "Sant Pere de Vilamajor",
            "Sant Pere Pescador",
            "Sant Pere Sallavinera",
            "Sant Pol de Mar",
            "Sant Quintí de Mediona",
            "Sant Quirze de Besora",
            "Sant Quirze del Vallès",
            "Sant Quirze Safaja",
            "Sant Ramon",
            "Sant Sadurní dAnoia",
            "Sant Sadurní dOsormort",
            "Sant Salvador de Guardiola",
            "Sant Vicenç de Castellet",
            "Sant Vicenç de Montalt",
            "Sant Vicenç de Torelló",
            "Sant Vicenç dels Horts",
            "Santa Amalia",
            "Santa Ana",
            "Santa Ana de Pusa",
            "Santa Ana la Real",
            "Santa Bárbara",
            "Santa Bárbara de Casa",
            "Santa Brígida",
            "Santa Cecilia",
            "Santa Cecília de Voltregà",
            "Santa Cecilia del Alcor",
            "Santa Cilia",
            "Santa Clara de Avedillo",
            "Santa Coloma",
            "Santa Coloma de Cervelló",
            "Santa Coloma de Farners",
            "Santa Coloma de Gramenet",
            "Santa Coloma de Queralt",
            "Santa Colomba de Curueño",
            "Santa Colomba de las Monjas",
            "Santa Colomba de Somoza",
            "Santa Comba",
            "Santa Cristina dAro",
            "Santa Cristina de la Polvorosa",
            "Santa Cristina de Valmadrigal",
            "Santa Croya de Tera",
            "Santa Cruz",
            "Santa Cruz de Bezana",
            "Santa Cruz de Boedo",
            "Santa Cruz de Grío",
            "Santa Cruz de la Palma",
            "Santa Cruz de La Palma",
            "Santa Cruz de la Salceda",
            "Santa Cruz de la Serós",
            "Santa Cruz de la Sierra",
            "Santa Cruz de la Zarza",
            "Santa Cruz de los Cáñamos",
            "Santa Cruz de Marchena",
            "Santa Cruz de Moncayo",
            "Santa Cruz de Moya",
            "Santa Cruz de Mudela",
            "Santa Cruz de Nogueras",
            "Santa Cruz de Paniagua",
            "Santa Cruz de Pinares",
            "Santa Cruz de Tenerife",
            "Santa Cruz de Tenerife",
            "Santa Cruz de Yanguas",
            "Santa Cruz del Comercio",
            "Santa Cruz del Retamar",
            "Santa Cruz del Valle",
            "Santa Cruz del Valle Urbión",
            "Santa Elena",
            "Santa Elena de Jamuz",
            "Santa Engracia del Jubera",
            "Santa Eufemia",
            "Santa Eufemia del Arroyo",
            "Santa Eufemia del Barco",
            "Santa Eugènia",
            "Santa Eugènia de Berga",
            "Santa Eulalia",
            "Santa Eulalia Bajera",
            "Santa Eulalia de Gállego",
            "Santa Eulalia de Oscos",
            "Santa Eulàlia de Riuprimer",
            "Santa Eulàlia de Ronçana",
            "Santa Eulalia del Río",
            "Santa Eulària des Riu",
            "Santa Fe",
            "Santa Fe de Mondújar",
            "Santa Fe del Penedès",
            "Santa Gadea del Cid",
            "Santa Inés",
            "Santa Llogaia dÀlguema",
            "Santa Lucía",
            "Santa Lucía de Tirajana",
            "Santa Magdalena de Pulpis",
            "Santa Margalida",
            "Santa Margarida de Montbui",
            "Santa Margarida i els Monjos",
            "Santa Maria de Besora",
            "Santa María de Cayón",
            "Santa Maria de Corcó",
            "Santa María de Dulcis",
            "Santa María de Guía de Gran Canaria",
            "Santa María de Huerta",
            "Santa María de la Alameda",
            "Santa María de la Isla",
            "Santa María de la Vega",
            "Santa María de las Hoyas",
            "Santa María de los Caballeros",
            "Santa María de los Llanos",
            "Santa Maria de Martorelles",
            "Santa Maria de Merlès",
            "Santa Maria de Miralles",
            "Santa María de Ordás",
            "Santa Maria de Palautordera",
            "Santa María de Sando",
            "Santa María de Valverde",
            "Santa María del Arroyo",
            "Santa María del Berrocal",
            "Santa María del Camí",
            "Santa María del Campo",
            "Santa María del Campo Rus",
            "Santa María del Cubillo",
            "Santa María del Invierno",
            "Santa María del Mercadillo",
            "Santa María del Monte de Cea",
            "Santa María del Páramo",
            "Santa María del Tiétar",
            "Santa María del Val",
            "Santa Maria dOló",
            "Santa María la Real de Nieva",
            "Santa María Rivarredonda",
            "Santa Marina del Rey",
            "Santa Marta",
            "Santa Marta de Magasca",
            "Santa Marta de Tormes",
            "Santa Marta del Cerro",
            "Santa Olalla",
            "Santa Olalla de Bureba",
            "Santa Olalla del Cala",
            "Santa Oliva",
            "Santa Pau",
            "Santa Perpètua de Mogoda",
            "Santa Pola",
            "Santa Ponsa",
            "Santa Susanna",
            "Santa Úrsula",
            "Santacara",
            "Santaella",
            "Santaliestra y San Quílez",
            "Santander",
            "Santanyí",
            "Santas Martas",
            "Santed",
            "Santervás de Campos",
            "Santervás de la Vega",
            "Santiago de Alcántara",
            "Santiago de Calatrava",
            "Santiago de Compostela",
            "Santiago de la Puebla",
            "Santiago de la Ribera",
            "Santiago del Campo",
            "Santiago del Collado",
            "Santiago del Teide",
            "Santiago del Tormes",
            "Santiago Millas",
            "Santiago-Pontones",
            "Santibáñez de Béjar",
            "Santibáñez de Ecla",
            "Santibáñez de Esgueva",
            "Santibáñez de la Peña",
            "Santibáñez de la Sierra",
            "Santibáñez de Tera",
            "Santibáñez de Valcorba",
            "Santibáñez de Vidriales",
            "Santibáñez del Val",
            "Santibáñez el Alto",
            "Santibáñez el Bajo",
            "Santillana",
            "Santillana del Mar",
            "Santiponce",
            "Santiso",
            "Santisteban del Puerto",
            "Santiurde de Reinosa",
            "Santiurde de Toranzo",
            "Santiuste",
            "Santiuste de Pedraza",
            "Santiuste de San Juan Bautista",
            "Santiz",
            "Santo Adriano",
            "Santo Domingo de la Calzada",
            "Santo Domingo de las Posadas",
            "Santo Domingo de Pirón",
            "Santo Domingo de Silos",
            "Santo Domingo-Caudilla",
            "Santo Tomé",
            "Santo Tomé de Zabarcos",
            "Santo Tomé del Puerto",
            "Santomera",
            "Santoña",
            "Santorcaz",
            "Santos de la Humosa Los",
            "Santos de Maimona Los",
            "Santos Los",
            "Santovenia",
            "Santovenia de la Valdoncina",
            "Santovenia de Pisuerga",
            "Santoyo",
            "Santpedor",
            "Santurde de Rioja",
            "Santurdejo",
            "Santurtzi",
            "Sanxenxo",
            "Sanzoles",
            "Sardón de Duero",
            "Sardón de los Frailes",
            "Sargentes de la Lora",
            "Sariego",
            "Sariegos",
            "Sariñena",
            "Saro",
            "Sarracín",
            "Sarral",
            "Sarratella",
            "Sarreaus",
            "Sarria",
            "Sarrià de Ter",
            "Sarriés/Sartze",
            "Sarriguren",
            "Sarrión",
            "Sarroca de Bellera",
            "Sarroca de Lleida",
            "Sartaguda",
            "Sartajada",
            "Sasamón",
            "Sástago",
            "Saúca",
            "Saucedilla",
            "Saucejo El",
            "Saucelle",
            "Sauquillo de Cabezas",
            "Saus  Camallera i Llampaies",
            "Sauzal",
            "Sauzal El",
            "Savallà del Comtat",
            "Saviñao O",
            "Sax",
            "Sayalonga",
            "Sayatón",
            "Sebúlcor",
            "Seca La",
            "Secastilla",
            "Secuita",
            "Sedaví",
            "Sedella",
            "Sediles",
            "Segart",
            "Segorbe",
            "Segovia",
            "Segundo Ensanche",
            "Segura",
            "Segura de la Sierra",
            "Segura de León",
            "Segura de los Baños",
            "Segura de Toro",
            "Segurilla",
            "Seira",
            "Selas",
            "Selaya",
            "Sella",
            "Sellent",
            "Selva",
            "Selva de Mar La",
            "Selva del Camp",
            "Semillas",
            "Sempere",
            "Sena",
            "Sena de Luna",
            "Senan",
            "Sencelles",
            "Senés",
            "Senés de Alcubierre",
            "Sénia",
            "Senija",
            "Seno",
            "Senterada",
            "Sentiu de Sió La",
            "Sentmenat",
            "Senyera",
            "Sepulcro-Hilario",
            "Sepúlveda",
            "Sequera de Fresno",
            "Sequera de Haza La",
            "Sequeros",
            "Serinyà",
            "Serna del Monte La",
            "Serna La",
            "Serón",
            "Serón de Nágima",
            "Seròs",
            "Serra",
            "Serra de Daró",
            "Serrada",
            "Serrada La",
            "Serradilla",
            "Serradilla del Arroyo",
            "Serradilla del Llano",
            "Serranillos",
            "Serranillos del Valle",
            "Serrejón",
            "Ses Salines",
            "Sesa",
            "Seseña",
            "Sesma",
            "Sestao",
            "Sestrica",
            "Sesué",
            "Setcases",
            "Setenil de las Bodegas",
            "Setiles",
            "Seu dUrgell La",
            "Seva",
            "Sevilla",
            "Sevilla La Nueva",
            "Sevilleja de la Jara",
            "Sidamon",
            "Sienes",
            "Siero",
            "Sierpe La",
            "Sierra de Fuentes",
            "Sierra de Luna",
            "Sierra de Yeguas",
            "Sierra Engarcerán",
            "Sierra-Engarcerán",
            "Sierro",
            "Siétamo",
            "Siete Aguas",
            "Siete Iglesias de Trabancos",
            "Sieteiglesias de Tormes",
            "Sigeres",
            "Sigüenza",
            "Sigüés",
            "Siles",
            "Silla",
            "Silleda",
            "Silos Los",
            "Sils",
            "Simancas",
            "Simat de la Valldigna",
            "Sinarcas",
            "Sineu",
            "Singra",
            "Sinlabajos",
            "Siruela",
            "Sisamón",
            "Sisante",
            "Sitges",
            "Siurana",
            "Soba",
            "Sober",
            "Sobradiel",
            "Sobradillo",
            "Sobrado",
            "Sobrado",
            "Sobremunt",
            "Sobrescobio",
            "Socovos",
            "Socuéllamos",
            "Sojuela",
            "Solana de Ávila",
            "Solana de los Barros",
            "Solana de Rioalmar",
            "Solana del Pino",
            "Solana La",
            "Solanillos del Extremo",
            "Solarana",
            "Solera de Gabaldón",
            "Soleràs El",
            "Soliedra",
            "Solivella",
            "Sollana",
            "Sóller",
            "Solórzano",
            "Solosancho",
            "Solsona",
            "Somiedo",
            "Somolinos",
            "Somontín",
            "Somosierra",
            "Somozas As",
            "Son Ferrer",
            "Son Servera",
            "Sondika",
            "Soneja",
            "Sonseca",
            "Sopeira",
            "Sopelana",
            "Soportújar",
            "Sopuerta",
            "Sora",
            "Soraluze/Placencia de las Armas",
            "Sorbas",
            "Sordillos",
            "Soria",
            "Soriguera",
            "Sorihuela",
            "Sorihuela del Guadalimar",
            "Sorlada",
            "Sort",
            "Sorvilán",
            "Sorzano",
            "Sos del Rey Católico",
            "Soses",
            "Sot de Chera",
            "Sot de Ferrer",
            "Sotalbo",
            "Sotés",
            "Sotillo",
            "Sotillo de la Adrada",
            "Sotillo de la Ribera",
            "Sotillo de las Palomas",
            "Sotillo del Rincón",
            "Sotillo El",
            "Soto de Cerrato",
            "Soto de la Vega",
            "Soto del Barco",
            "Soto del Real",
            "Soto en Cameros",
            "Soto y Amío",
            "Sotobañado y Priorato",
            "Sotodosos",
            "Sotonera La",
            "Sotorribas",
            "Sotosalbos",
            "Sotoserrano",
            "Sotragero",
            "Sotresgudo",
            "Soutomaior",
            "Suances",
            "Subirats",
            "Sudanell",
            "Sueca",
            "Suellacabras",
            "Sueras/Suera",
            "Suflí",
            "Sukarrieta",
            "Sumacàrcer",
            "Sunbilla",
            "Sunyer",
            "Súria",
            "Susinos del Páramo",
            "Susqueda",
            "Tabanera de Cerrato",
            "Tabanera de Valdavia",
            "Tabanera la Luenga",
            "Tábara",
            "Tabera de Abajo",
            "Tabernas",
            "Taberno",
            "Taboada",
            "Taboadela",
            "Tabuenca",
            "Tacoronte",
            "Tafalla",
            "Tagamanent",
            "Taha La",
            "Tahal",
            "Tajahuerce",
            "Tajueco",
            "Tala La",
            "Talamanca",
            "Talamanca de Jarama",
            "Talamantes",
            "Talarn",
            "Talarrubias",
            "Talaván",
            "Talavera",
            "Talavera de la Reina",
            "Talavera La Real",
            "Talaveruela de la Vera",
            "Talayuela",
            "Talayuelas",
            "Tales",
            "Táliga",
            "Tallada dEmpordà La",
            "Talveila",
            "Tamajón",
            "Tamames",
            "Támara de Campos",
            "Tamarit de Llitera / Tamarite de Litera",
            "Tamarite de Litera",
            "Tamariz de Campos",
            "Tamarón",
            "Tamurejo",
            "Tanque",
            "Tanque El",
            "Tapia de Casariego",
            "Tapioles",
            "Taradell",
            "Taragudo",
            "Taramundi",
            "Tarancón",
            "Taravilla",
            "Tarazona",
            "Tarazona de Guareña",
            "Tarazona de la Mancha",
            "Tàrbena",
            "Tardáguila",
            "Tardajos",
            "Tardelcuende",
            "Tardienta",
            "Tariego de Cerrato",
            "Tarifa",
            "Taroda",
            "Tarragona",
            "Tàrrega",
            "Tarrés",
            "Tarroja de Segarra",
            "Tartanedo",
            "Tauste",
            "Tavernes Blanques",
            "Tavernes de la Valldigna",
            "Tavèrnoles",
            "Tavertet",
            "Tazacorte",
            "Teba",
            "Tébar",
            "Tegueste",
            "Teguise",
            "Teià",
            "Teixeira A",
            "Tejada",
            "Tejadillos",
            "Tejado",
            "Tejado El",
            "Tejeda",
            "Tejeda de Tiétar",
            "Tejeda y Segoyuela",
            "Telde",
            "Tella-Sin",
            "Tembleque",
            "Tendilla",
            "Tenebrón",
            "Teo",
            "Teresa",
            "Teresa de Cofrentes",
            "Térmens",
            "Teror",
            "Terque",
            "Terrades",
            "Terradillos",
            "Terradillos de Esgueva",
            "Terrassa",
            "Terrateig",
            "Terrer",
            "Terriente",
            "Terrinches",
            "Terroba",
            "Teruel",
            "Terzaga",
            "Tetuán de las Victorias",
            "Teulada",
            "Teverga",
            "Tiana",
            "Tías",
            "Tibi",
            "Tiebas-Muruarte de Reta",
            "Tiedra",
            "Tielmes",
            "Tiemblo El",
            "Tierga",
            "Tierz",
            "Tierzo",
            "Tijarafe",
            "Tíjola",
            "Tinajas",
            "Tinajo",
            "Tineo",
            "Tinieblas de la Sierra",
            "Tiñosillos",
            "Tirapu",
            "Tirgo",
            "Tírig",
            "Tírvia",
            "Titaguas",
            "Titulcia",
            "Tiurana",
            "Tivenys",
            "Tivissa",
            "Toba La",
            "Tobar",
            "Tobarra",
            "Tobed",
            "Tobía",
            "Toboso El",
            "Tocina",
            "Todolella",
            "Toén",
            "Toga",
            "Tojos Los",
            "Tolbaños",
            "Toledo",
            "Tollos",
            "Tolocirio",
            "Tolosa",
            "Tolox",
            "Tolva",
            "Tomares",
            "Tomelloso",
            "Tomiño",
            "Tona",
            "Topas",
            "Toques",
            "Torà",
            "Toral de los Guzmanes",
            "Toral de los Vados",
            "Torás",
            "Tordehumos",
            "Tordellego",
            "Tordelrábano",
            "Tordera",
            "Tordesillas",
            "Tordesilos",
            "Tordillos",
            "Tordoia",
            "Tordómar",
            "Torelló",
            "Toreno",
            "Torija",
            "Toril",
            "Toril y Masegoso",
            "Torla",
            "Torlengua",
            "Tormantos",
            "Tormellas",
            "Tormón",
            "Tormos",
            "Torms Els",
            "Tornabous",
            "Tornadizo El",
            "Tornadizos de Ávila",
            "Tornavacas",
            "Torno El",
            "Tornos",
            "Toro",
            "Toro El",
            "Torquemada",
            "Torralba",
            "Torralba de Aragón",
            "Torralba de Calatrava",
            "Torralba de los Frailes",
            "Torralba de los Sisones",
            "Torralba de Oropesa",
            "Torralba de Ribota",
            "Torralba del Pinar",
            "Torralba del Río",
            "Torralbilla",
            "Torre Alháquime",
            "Torre de Arcas",
            "Torre de Cabdella La",
            "Torre de Claramunt La",
            "Torre de Don Miguel",
            "Torre de Esgueva",
            "Torre de Espanyol",
            "Torre de Esteban Hambrán La",
            "Torre de Fontaubella",
            "Torre de Juan Abad",
            "Torre de la Horadada",
            "Torre de las Arcas",
            "Torre de Miguel Sesmero",
            "Torre de Peñafiel",
            "Torre de Santa María",
            "Torre del Bierzo",
            "Torre del Burgo",
            "Torre del Campo",
            "Torre del Compte",
            "Torre del Valle La",
            "Torre dEn Besora la",
            "Torre den Doménec la",
            "Torre en Cameros",
            "Torre La",
            "Torre la Ribera",
            "Torre los Negros",
            "Torre Val de San Pedro",
            "Torre-Cardela",
            "Torre-Pacheco",
            "Torre-serona",
            "Torreadrada",
            "Torrebaja",
            "Torrebesses",
            "Torreblacos",
            "Torreblanca",
            "Torreblascopedro",
            "Torrecaballeros",
            "Torrecampo",
            "Torrechiva",
            "Torrecilla de Alcañiz",
            "Torrecilla de la Abadesa",
            "Torrecilla de la Jara",
            "Torrecilla de la Orden",
            "Torrecilla de la Torre",
            "Torrecilla de los Ángeles",
            "Torrecilla del Monte",
            "Torrecilla del Pinar",
            "Torrecilla del Rebollar",
            "Torrecilla en Cameros",
            "Torrecilla sobre Alesanco",
            "Torrecillas de la Tiesa",
            "Torrecuadrada de Molina",
            "Torrecuadradilla",
            "Torredembarra",
            "Torredonjimeno",
            "Torrefarrera",
            "Torrefeta i Florejacs",
            "Torregalindo",
            "Torregamones",
            "Torregrossa",
            "Torrehermosa",
            "Torreiglesias",
            "Torrejón de Ardoz",
            "Torrejón de la Calzada",
            "Torrejón de Velasco",
            "Torrejón del Rey",
            "Torrejón el Rubio",
            "Torrejoncillo",
            "Torrejoncillo del Rey",
            "Torrelacárcel",
            "Torrelaguna",
            "Torrelameu",
            "Torrelapaja",
            "Torrelara",
            "Torrelavega",
            "Torrelavit",
            "Torrella",
            "Torrellas",
            "Torrelles de Foix",
            "Torrelles de Llobregat",
            "Torrelobatón",
            "Torrelodones",
            "Torremanzanas/Torre de les Maçanes la",
            "Torremayor",
            "Torremejía",
            "Torremenga",
            "Torremocha",
            "Torremocha de Jadraque",
            "Torremocha de Jarama",
            "Torremocha de Jiloca",
            "Torremocha del Campo",
            "Torremocha del Pinar",
            "Torremochuela",
            "Torremolinos",
            "Torremontalbo",
            "Torremormojón",
            "Torrent",
            "Torrent",
            "Torrente de Cinca",
            "Torrenueva",
            "Torreorgaz",
            "Torrepadre",
            "Torreperogil",
            "Torrequemada",
            "Torres",
            "Torres de Albánchez",
            "Torres de Albarracín",
            "Torres de Alcanadre",
            "Torres de Barbués",
            "Torres de Berrellén",
            "Torres de Cotillas Las",
            "Torres de la Alameda",
            "Torres de Segre",
            "Torres del Carrizal",
            "Torres del Río",
            "Torres Torres",
            "Torresandino",
            "Torrescárcela",
            "Torresmenudas",
            "Torrevelilla",
            "Torrevieja",
            "Torrico",
            "Torrijas",
            "Torrijo de la Cañada",
            "Torrijo del Campo",
            "Torrijos",
            "Torroella de Fluvià",
            "Torroella de Montgrí",
            "Torroja del Priorat",
            "Torrox",
            "Torrubia",
            "Torrubia de Soria",
            "Torrubia del Campo",
            "Torrubia del Castillo",
            "Tortellà",
            "Tórtola de Henares",
            "Tórtoles",
            "Tórtoles de Esgueva",
            "Tortosa",
            "Tortuera",
            "Tortuero",
            "Torvizcón",
            "Tosantos",
            "Toses",
            "Tosos",
            "Tossa de Mar",
            "Totalán",
            "Totana",
            "Totanés",
            "Touro",
            "Tous",
            "Trabada",
            "Trabadelo",
            "Trabanca",
            "Trabazos",
            "Tragacete",
            "Traíd",
            "Traiguera",
            "Tramacastiel",
            "Tramacastilla",
            "Tramaced",
            "Trasierra",
            "Trasmiras",
            "Trasmoz",
            "Trasobares",
            "Traspinedo",
            "Trazo",
            "Trebujena",
            "Trefacio",
            "Tremedal de Tormes",
            "Tremp",
            "Tres Cantos",
            "Tres Villas Las",
            "Trescasas",
            "Tresjuncos",
            "Trespaderne",
            "Tresviso",
            "Trévago",
            "Trevélez",
            "Treviana",
            "Triacastela",
            "Tribaldos",
            "Tricio",
            "Trigueros",
            "Trigueros del Valle",
            "Trijueque",
            "Trillo",
            "Triollo",
            "Tronchón",
            "Truchas",
            "Trucios-Turtzioz",
            "Trujillanos",
            "Trujillo",
            "Tubilla del Agua",
            "Tubilla del Lago",
            "Tudanca",
            "Tudela",
            "Tudela de Duero",
            "Tudelilla",
            "Tuéjar",
            "Tui",
            "Tuineje",
            "Tulebras",
            "Turcia",
            "Turégano",
            "Turís",
            "Turleque",
            "Turón",
            "Turre",
            "Turrillas",
            "Úbeda",
            "Ubide",
            "Ubrique",
            "Ucar",
            "Uceda",
            "Ucero",
            "Uclés",
            "Udías",
            "Ugao-Miraballes",
            "Ugena",
            "Ugíjar",
            "Uharte-Arakil",
            "Ujados",
            "Ujué",
            "Ulea",
            "Uleila del Campo",
            "Ullà",
            "Ullastrell",
            "Ullastret",
            "Ulldecona",
            "Ulldemolins",
            "Ultramort",
            "Ultzama",
            "Umbrete",
            "Umbrías",
            "Uña",
            "Uña de Quintana",
            "Uncastillo",
            "Unciti",
            "Undués de Lerda",
            "Unión de Campos La",
            "Unión La",
            "Unzué",
            "Unzué/Untzue",
            "Úrbel del Castillo",
            "Urda",
            "Urdazubi/Urdax",
            "Urdiain",
            "Urdiales del Páramo",
            "Urduliz",
            "Urduña/Orduña",
            "Urkabustaiz",
            "Urnieta",
            "Urones de Castroponce",
            "Urrácal",
            "Urraul Alto",
            "Urraul Bajo",
            "Urrea de Gaén",
            "Urrea de Jalón",
            "Urretxu",
            "Urriés",
            "Urrotz",
            "Urroz-Villa",
            "Urueña",
            "Urueñas",
            "Uruñuela",
            "Urús",
            "Urzainqui",
            "Urzainqui/Urzainki",
            "Usagre",
            "Used",
            "Usera",
            "Useras/Useres les",
            "Usurbil",
            "Utande",
            "Utebo",
            "Uterga",
            "Utiel",
            "Utrera",
            "Utrillas",
            "Uztárroz/Uztarroze",
            "Vacarisses",
            "Vadillo",
            "Vadillo de la Guareña",
            "Vadillo de la Sierra",
            "Vadocondes",
            "Vajol La",
            "Val de San Lorenzo",
            "Val de San Martín",
            "Val de San Vicente",
            "Val do Dubra",
            "Valacloche",
            "Valadouro O",
            "Valbona",
            "Valbuena de Duero",
            "Valbuena de Pisuerga",
            "Valcabado",
            "Valdáliga",
            "Valdaracete",
            "Valdarachas",
            "Valdastillas",
            "Valde-Ucieza",
            "Valdealgorfa",
            "Valdeande",
            "Valdearcos de la Vega",
            "Valdearenas",
            "Valdeavellano",
            "Valdeavellano de Tera",
            "Valdeavero",
            "Valdeaveruelo",
            "Valdecaballeros",
            "Valdecañas de Tajo",
            "Valdecarros",
            "Valdecasa",
            "Valdecolmenas Los",
            "Valdeconcha",
            "Valdecuenca",
            "Valdefinjas",
            "Valdefresno",
            "Valdefuentes",
            "Valdefuentes de Sangusín",
            "Valdefuentes del Páramo",
            "Valdeganga",
            "Valdegeña",
            "Valdegovía/Gaubea",
            "Valdegrudas",
            "Valdehijaderos",
            "Valdehorna",
            "Valdehúncar",
            "Valdelacalzada",
            "Valdelacasa",
            "Valdelacasa de Tajo",
            "Valdelageve",
            "Valdelagua del Cerro",
            "Valdelaguna",
            "Valdelarco",
            "Valdelcubo",
            "Valdelinares",
            "Valdelosa",
            "Valdeltormo",
            "Valdelugueros",
            "Valdemadera",
            "Valdemaluque",
            "Valdemanco",
            "Valdemanco del Esteras",
            "Valdemaqueda",
            "Valdemeca",
            "Valdemierque",
            "Valdemora",
            "Valdemorales",
            "Valdemorillo",
            "Valdemorillo de la Sierra",
            "Valdemoro",
            "Valdemoro-Sierra",
            "Valdenebro",
            "Valdenebro de los Valles",
            "Valdenuño Fernández",
            "Valdeobispo",
            "Valdeolea",
            "Valdeolivas",
            "Valdeolmillos",
            "Valdeolmos",
            "Valdeolmos-Alalpardo",
            "Valdepeñas",
            "Valdepeñas de Jaén",
            "Valdepeñas de la Sierra",
            "Valdepiélago",
            "Valdepiélagos",
            "Valdepolo",
            "Valdeprado",
            "Valdeprado del Río",
            "Valdeprados",
            "Valderas",
            "Valderrábano",
            "Valderrebollo",
            "Valderredible",
            "Valderrey",
            "Valderrobres",
            "Valderrodilla",
            "Valderrodrigo",
            "Valderrueda",
            "Valdés",
            "Valdesamario",
            "Valdescorriel",
            "Valdesotos",
            "Valdestillas",
            "Valdetorres",
            "Valdetorres de Jarama",
            "Valdetórtola",
            "Valdevacas de Montejo",
            "Valdevacas y Guijar",
            "Valdeverdeja",
            "Valdevimbre",
            "Valdezate",
            "Valdilecha",
            "Valdorros",
            "Valdoviño",
            "Valdunciel",
            "Valdunquillo",
            "Valencia",
            "Valencia de Alcántara",
            "Valencia de Don Juan",
            "Valencia de las Torres",
            "Valencia del Mombuey",
            "Valencia del Ventoso",
            "Valencina de la Concepción",
            "Valenzuela",
            "Valenzuela de Calatrava",
            "Valeras Las",
            "Valero",
            "Valfarta",
            "Valfermoso de Tajuña",
            "Valga",
            "Valgañón",
            "Valhermoso",
            "Valhermoso de la Fuente",
            "Valjunquera",
            "Vall dAlba",
            "Vall dAlcalà la",
            "Vall de Almonacid",
            "Vall de Bianya La",
            "Vall de Boí La",
            "Vall de Cardós",
            "Vall de Ebo",
            "Vall de Gallinera",
            "Vall de Laguar la",
            "Vall dEbo la",
            "Vall den Bas La",
            "Vall dUixó la",
            "Vall-llobrega",
            "Vallada",
            "Valladolid",
            "Vallanca",
            "Vallarta de Bureba",
            "Vallat",
            "Vallbona dAnoia",
            "Vallbona de les Monges",
            "Vallcebre",
            "Vallclara",
            "Valldemossa",
            "Valle de Abdalajís",
            "Valle de Altomira El",
            "Valle de Bardají",
            "Valle de Cerrato",
            "Valle de Hecho",
            "Valle de la Serena",
            "Valle de las Navas",
            "Valle de Lierp",
            "Valle de Losa",
            "Valle de Manzanedo",
            "Valle de Matamoros",
            "Valle de Mena",
            "Valle de Oca",
            "Valle de Santa Ana",
            "Valle de Santibáñez",
            "Valle de Sedano",
            "Valle de Tabladillo",
            "Valle de Tobalina",
            "Valle de Trápaga-Trapagaran",
            "Valle de Valdebezana",
            "Valle de Valdelaguna",
            "Valle de Valdelucio",
            "Valle de Villaverde",
            "Valle de Yerri/Deierri",
            "Valle de Zamanzas",
            "Valle del Retortillo",
            "Valle del Zalabí",
            "Valle El",
            "Valle Gran Rey",
            "Vallecillo",
            "Vallecillo El",
            "Vallehermosa",
            "Vallehermoso",
            "Vallejera",
            "Vallejera de Riofrío",
            "Vallelado",
            "Valleruela de Pedraza",
            "Valleruela de Sepúlveda",
            "Vallés",
            "Valles de Palenzuela",
            "Vallesa de la Guareña",
            "Valleseco",
            "Vallfogona de Balaguer",
            "Vallfogona de Ripollès",
            "Vallfogona de Riucorb",
            "Vallgorguina",
            "Vallibona",
            "Vallirana",
            "Vallmoll",
            "Vallromanes",
            "Valls",
            "Valls dAguilar Les",
            "Valls de Valira Les",
            "Valluércanes",
            "Valmadrid",
            "Valmala",
            "Valmojado",
            "Válor",
            "Valoria la Buena",
            "Valpalmas",
            "Valsalabroso",
            "Valsalobre",
            "Valseca",
            "Valsequillo",
            "Valsequillo de Gran Canaria",
            "Valtablado del Río",
            "Valtajeros",
            "Valtiendas",
            "Valtierra",
            "Valtorres",
            "Valverde",
            "Valverde",
            "Valverde de Alcalá",
            "Valverde de Burguillos",
            "Valverde de Campos",
            "Valverde de Júcar",
            "Valverde de la Vera",
            "Valverde de la Virgen",
            "Valverde de Leganés",
            "Valverde de Llerena",
            "Valverde de los Arroyos",
            "Valverde de Mérida",
            "Valverde de Valdelacasa",
            "Valverde del Camino",
            "Valverde del Fresno",
            "Valverde del Majano",
            "Valverde-Enrique",
            "Valverdejo",
            "Valverdón",
            "Vandellòs i Hospitalet de Infant",
            "Vansa i Fórnols La",
            "Vara de Rey",
            "Veciana",
            "Vecilla La",
            "Vecindario",
            "Vecinos",
            "Vedra",
            "Vega de Espinareda",
            "Vega de Infanzones",
            "Vega de Liébana",
            "Vega de Pas",
            "Vega de Ruiponce",
            "Vega de San Mateo",
            "Vega de Santa María",
            "Vega de Tera",
            "Vega de Tirados",
            "Vega de Valcarce",
            "Vega de Valdetronco",
            "Vega de Villalobos",
            "Vega del Codorno",
            "Vegacervera",
            "Vegadeo",
            "Vegalatrave",
            "Veganzones",
            "Vegaquemada",
            "Vegas de Matute",
            "Vegas del Condado",
            "Vegas del Genil",
            "Vegaviana",
            "Veguillas de la Sierra",
            "Veguillas Las",
            "Veiga A",
            "Vejer de la Frontera",
            "Velada",
            "Velamazán",
            "Velascálvaro",
            "Velayos",
            "Velefique",
            "Vélez de Benaudalla",
            "Vélez-Blanco",
            "Vélez-Málaga",
            "Vélez-Rubio",
            "Velilla",
            "Velilla de Cinca",
            "Velilla de Ebro",
            "Velilla de Jiloca",
            "Velilla de la Sierra",
            "Velilla de los Ajos",
            "Velilla de San Antonio",
            "Velilla del Río Carrión",
            "Vellés La",
            "Vellisca",
            "Velliza",
            "Vellón El",
            "Vencillón",
            "Vendrell",
            "Venialbo",
            "Venta de Baños",
            "Venta del Moro",
            "Ventalló",
            "Ventas con Peña Aguilera Las",
            "Ventas de Huelma",
            "Ventas de Retamosa Las",
            "Ventas de San Julián Las",
            "Ventosa",
            "Ventosa de la Cuesta",
            "Ventosa del Río Almar",
            "Ventosilla y Tejadilla",
            "Ventrosa",
            "Venturada",
            "Vera",
            "Vera de Moncayo",
            "Verdú",
            "Verea",
            "Vergel",
            "Verger el",
            "Verges",
            "Verín",
            "Vertavillo",
            "Vespella de Gaià",
            "Vezdemarbán",
            "Viacamp y Litera",
            "Viana",
            "Viana de Cega",
            "Viana de Duero",
            "Viana de Jadraque",
            "Viana do Bolo",
            "Viandar de la Vera",
            "Vianos",
            "Viator",
            "Vic",
            "Vicálvaro",
            "Vícar",
            "Vicedo O",
            "Vicién",
            "Victoria de Acentejo La",
            "Victoria La",
            "Vid de Bureba La",
            "Vid de Ojeda La",
            "Vid y Barrios La",
            "Vidángoz/Bidankoze",
            "Vidayanes",
            "Videmala",
            "Vídola La",
            "Vidrà",
            "Vidreres",
            "Vielha e Mijaran",
            "Vierlas",
            "Vigo",
            "Viguera",
            "Vila de Cruces",
            "Vila-real",
            "Vila-rodona",
            "Vila-sacra",
            "Vila-sana",
            "Vila-seca",
            "Vilabella",
            "Vilabertran",
            "Vilablareix",
            "Vilaboa",
            "Vilada",
            "Viladamat",
            "Viladasens",
            "Viladecans",
            "Viladecavalls",
            "Vilademuls",
            "Viladrau",
            "Vilafamés",
            "Vilafant",
            "Vilaflor",
            "Vilafranca de Bonany",
            "Vilafranca del Penedès",
            "Vilagarcía de Arousa",
            "Vilagrassa",
            "Vilajuïga",
            "Vilalba",
            "Vilalba dels Arcs",
            "Vilalba Sasserra",
            "Vilaller",
            "Vilallonga de Ter",
            "Vilallonga del Camp",
            "Vilamacolum",
            "Vilamalla",
            "Vilamaniscle",
            "Vilamarín",
            "Vilamartín de Valdeorras",
            "Vilamarxant",
            "Vilamòs",
            "Vilanant",
            "Vilanova dAlcolea",
            "Vilanova de Arousa",
            "Vilanova de Bellpuig",
            "Vilanova de la Barca",
            "Vilanova de lAguda",
            "Vilanova de Meià",
            "Vilanova de Prades",
            "Vilanova de Sau",
            "Vilanova de Segrià",
            "Vilanova del Camí",
            "Vilanova del Vallès",
            "Vilanova dEscornalbou",
            "Vilanova i la Geltrú",
            "Vilaplana",
            "Vilar de Barrio",
            "Vilar de Canes",
            "Vilar de Santos",
            "Vilardevós",
            "Vilariño de Conso",
            "Vilarmaior",
            "Vilasantar",
            "Vilassar de Dalt",
            "Vilassar de Mar",
            "Vilaür",
            "Vilavella la",
            "Vilaverd",
            "Vilaxoán",
            "Vilches",
            "Vilella Alta La",
            "Vilella Baixa La",
            "Vileña",
            "Villa de Don Fadrique La",
            "Villa de Mazo",
            "Villa de Vallecas",
            "Villa de Ves",
            "Villa del Campo",
            "Villa del Prado",
            "Villa del Rey",
            "Villa del Río",
            "Villabáñez",
            "Villabaruz de Campos",
            "Villabasta de Valdavia",
            "Villablanca",
            "Villablino",
            "Villabona",
            "Villabrágima",
            "Villabraz",
            "Villabrázaro",
            "Villabuena de Álava/Eskuernaga",
            "Villabuena del Puente",
            "Villacañas",
            "Villacarralón",
            "Villacarriedo",
            "Villacarrillo",
            "Villacastín",
            "Villacid de Campos",
            "Villacidaler",
            "Villaciervos",
            "Villaco",
            "Villaconancio",
            "Villaconejos",
            "Villaconejos de Trabaque",
            "Villada",
            "Villadangos del Páramo",
            "Villadecanes",
            "Villademor de la Vega",
            "Villadepera",
            "Villadiego",
            "Villadoz",
            "Villaeles de Valdavia",
            "Villaescusa",
            "Villaescusa",
            "Villaescusa de Haro",
            "Villaescusa de Roa",
            "Villaescusa la Sombría",
            "Villaespasa",
            "Villafáfila",
            "Villafamés",
            "Villafeliche",
            "Villaferrueña",
            "Villaflor",
            "Villaflores",
            "Villafrades de Campos",
            "Villafranca",
            "Villafranca de Córdoba",
            "Villafranca de Duero",
            "Villafranca de Ebro",
            "Villafranca de la Sierra",
            "Villafranca de los Barros",
            "Villafranca de los Caballeros",
            "Villafranca del Bierzo",
            "Villafranca del Campo",
            "Villafranca del Cid",
            "Villafranca del Cid/Vilafranca",
            "Villafranca Montes de Oca",
            "Villafrechós",
            "Villafruela",
            "Villafuerte",
            "Villafufre",
            "Villagalijo",
            "Villagarcía de Campos",
            "Villagarcía de la Torre",
            "Villagarcía del Llano",
            "Villagatón",
            "Villageriz",
            "Villagómez la Nueva",
            "Villagonzalo",
            "Villagonzalo de Tormes",
            "Villagonzalo Pedernales",
            "Villagonzalo-Pedernales",
            "Villahán",
            "Villaharta",
            "Villahermosa",
            "Villahermosa del Campo",
            "Villahermosa del Río",
            "Villaherreros",
            "Villahoz",
            "Villajoyosa",
            "Villajoyosa/Vila Joiosa la",
            "Villalaco",
            "Villalán de Campos",
            "Villalar de los Comuneros",
            "Villalazán",
            "Villalba de Duero",
            "Villalba de Guardo",
            "Villalba de la Lampreana",
            "Villalba de la Loma",
            "Villalba de la Sierra",
            "Villalba de los Alcores",
            "Villalba de los Barros",
            "Villalba de los Llanos",
            "Villalba de Perejil",
            "Villalba de Rioja",
            "Villalba del Alcor",
            "Villalba del Rey",
            "Villalbarba",
            "Villalbilla",
            "Villalbilla de Burgos",
            "Villalbilla de Gumiel",
            "Villalcampo",
            "Villalcázar de Sirga",
            "Villalcón",
            "Villaldemiro",
            "Villalengua",
            "Villalgordo del Júcar",
            "Villalgordo del Marquesado",
            "Villalmanzo",
            "Villalobar de Rioja",
            "Villalobón",
            "Villalobos",
            "Villalón de Campos",
            "Villalonga",
            "Villalonso",
            "Villalpando",
            "Villalpardo",
            "Villalube",
            "Villaluenga de la Sagra",
            "Villaluenga de la Vega",
            "Villaluenga del Rosario",
            "Villamalea",
            "Villamalur",
            "Villamañán",
            "Villamandos",
            "Villamanín",
            "Villamanrique",
            "Villamanrique de la Condesa",
            "Villamanrique de Tajo",
            "Villamanta",
            "Villamantilla",
            "Villamartín",
            "Villamartín de Campos",
            "Villamartín de Don Sancho",
            "Villamayor",
            "Villamayor de Calatrava",
            "Villamayor de Campos",
            "Villamayor de Gállego",
            "Villamayor de los Montes",
            "Villamayor de Monjardín",
            "Villamayor de Santiago",
            "Villamayor de Treviño",
            "Villambistia",
            "Villamediana",
            "Villamediana de Iregua",
            "Villamedianilla",
            "Villamejil",
            "Villamena",
            "Villameriel",
            "Villamesías",
            "Villamiel",
            "Villamiel de la Sierra",
            "Villamiel de Toledo",
            "Villaminaya",
            "Villamol",
            "Villamontán de la Valduerna",
            "Villamor de los Escuderos",
            "Villamoratiel de las Matas",
            "Villamoronta",
            "Villamuelas",
            "Villamuera de la Cueza",
            "Villamuriel de Campos",
            "Villamuriel de Cerrato",
            "Villán de Tordesillas",
            "Villanázar",
            "Villangómez",
            "Villanova",
            "Villanúa",
            "Villanubla",
            "Villanueva de Alcardete",
            "Villanueva de Alcorón",
            "Villanueva de Algaidas",
            "Villanueva de Argaño",
            "Villanueva de Argecilla",
            "Villanueva de Ávila",
            "Villanueva de Azoague",
            "Villanueva de Bogas",
            "Villanueva de Cameros",
            "Villanueva de Campeán",
            "Villanueva de Carazo",
            "Villanueva de Castellón",
            "Villanueva de Córdoba",
            "Villanueva de Duero",
            "Villanueva de Gállego",
            "Villanueva de Gómez",
            "Villanueva de Gormaz",
            "Villanueva de Guadamejud",
            "Villanueva de Gumiel",
            "Villanueva de Huerva",
            "Villanueva de Jiloca",
            "Villanueva de la Cañada",
            "Villanueva de la Concepción",
            "Villanueva de la Condesa",
            "Villanueva de la Fuente",
            "Villanueva de la Jara",
            "Villanueva de la Reina",
            "Villanueva de la Serena",
            "Villanueva de la Sierra",
            "Villanueva de la Torre",
            "Villanueva de la Vera",
            "Villanueva de las Cruces",
            "Villanueva de las Manzanas",
            "Villanueva de las Peras",
            "Villanueva de las Torres",
            "Villanueva de los Caballeros",
            "Villanueva de los Castillejos",
            "Villanueva de los Infantes",
            "Villanueva de Oscos",
            "Villanueva de Perales",
            "Villanueva de San Carlos",
            "Villanueva de San Juan",
            "Villanueva de San Mancio",
            "Villanueva de Sigena",
            "Villanueva de Tapia",
            "Villanueva de Teba",
            "Villanueva de Viver",
            "Villanueva del Aceral",
            "Villanueva del Ariscal",
            "Villanueva del Arzobispo",
            "Villanueva del Campillo",
            "Villanueva del Campo",
            "Villanueva del Conde",
            "Villanueva del Duque",
            "Villanueva del Fresno",
            "Villanueva del Pardillo",
            "Villanueva del Rebollar",
            "Villanueva del Rebollar de la Sierra",
            "Villanueva del Rey",
            "Villanueva del Río Segura",
            "Villanueva del Río y Minas",
            "Villanueva del Rosario",
            "Villanueva del Trabuco",
            "Villanueva Mesía",
            "Villanuño de Valdavia",
            "Villaobispo de Otero",
            "Villaornate y Castro",
            "Villapalacios",
            "Villaprovedo",
            "Villaquejida",
            "Villaquilambre",
            "Villaquirán de la Puebla",
            "Villaquirán de los Infantes",
            "Villar de Argañán",
            "Villar de Arnedo El",
            "Villar de Cañas",
            "Villar de Ciervo",
            "Villar de Corneja",
            "Villar de Domingo García",
            "Villar de Fallaves",
            "Villar de Gallimazo",
            "Villar de la Encina",
            "Villar de la Yegua",
            "Villar de los Navarros",
            "Villar de Olalla",
            "Villar de Peralonso",
            "Villar de Plasencia",
            "Villar de Rena",
            "Villar de Samaniego",
            "Villar de Torre",
            "Villar del Ala",
            "Villar del Arzobispo",
            "Villar del Buey",
            "Villar del Campo",
            "Villar del Cobo",
            "Villar del Humo",
            "Villar del Infantado",
            "Villar del Olmo",
            "Villar del Pedroso",
            "Villar del Pozo",
            "Villar del Rey",
            "Villar del Río",
            "Villar del Salz",
            "Villar y Velasco",
            "Villaralbo",
            "Villaralto",
            "Villarcayo de Merindad de Castilla la Vieja",
            "Villardeciervos",
            "Villardefrades",
            "Villardiegua de la Ribera",
            "Villárdiga",
            "Villardompardo",
            "Villardondiego",
            "Villarejo",
            "Villarejo de Fuentes",
            "Villarejo de la Peñuela",
            "Villarejo de Montalbán",
            "Villarejo de Órbigo",
            "Villarejo de Salvanés",
            "Villarejo del Valle",
            "Villarejo-Periesteban",
            "Villares de Jadraque",
            "Villares de la Reina",
            "Villares de Órbigo",
            "Villares de Soria Los",
            "Villares de Yeltes",
            "Villares del Saz",
            "Villares Los",
            "Villargordo del Cabriel",
            "Villariezo",
            "Villarino de los Aires",
            "Villarluengo",
            "Villarmayor",
            "Villarmentero de Campos",
            "Villarmentero de Esgueva",
            "Villarmuerto",
            "Villarquemado",
            "Villarrabé",
            "Villarramiel",
            "Villarrasa",
            "Villarreal de Huerva",
            "Villarrín de Campos",
            "Villarrobledo",
            "Villarrodrigo",
            "Villarroya",
            "Villarroya de la Sierra",
            "Villarroya de los Pinares",
            "Villarroya del Campo",
            "Villarrubia de los Ojos",
            "Villarrubia de Santiago",
            "Villarrubio",
            "Villarta",
            "Villarta de los Montes",
            "Villarta de San Juan",
            "Villarta-Quintana",
            "Villas de la Ventosa",
            "Villasabariego",
            "Villasana de Mena",
            "Villasandino",
            "Villasarracino",
            "Villasayas",
            "Villasbuenas",
            "Villasbuenas de Gata",
            "Villasdardo",
            "Villaseca de Arciel",
            "Villaseca de Henares",
            "Villaseca de la Sagra",
            "Villaseca de Uceda",
            "Villaseco de los Gamitos",
            "Villaseco de los Reyes",
            "Villaseco del Pan",
            "Villaselán",
            "Villasequilla",
            "Villasexmir",
            "Villasila de Valdavia",
            "Villasrubias",
            "Villastar",
            "Villasur de Herreros",
            "Villatobas",
            "Villatoro",
            "Villatorres",
            "Villatoya",
            "Villatuelda",
            "Villatuerta",
            "Villaturde",
            "Villaturiel",
            "Villaumbrales",
            "Villava/Atarrabia",
            "Villavaliente",
            "Villavaquerín",
            "Villavelayo",
            "Villavellid",
            "Villavendimio",
            "Villaverde",
            "Villaverde de Guadalimar",
            "Villaverde de Guareña",
            "Villaverde de Íscar",
            "Villaverde de Medina",
            "Villaverde de Montejo",
            "Villaverde de Rioja",
            "Villaverde del Monte",
            "Villaverde del Río",
            "Villaverde y Pasaconsol",
            "Villaverde-Mogina",
            "Villaveza de Valverde",
            "Villaveza del Agua",
            "Villavicencio de los Caballeros",
            "Villaviciosa",
            "Villaviciosa de Córdoba",
            "Villaviciosa de Odón",
            "Villavieja",
            "Villavieja de Yeltes",
            "Villavieja del Lozoya",
            "Villaviudas",
            "Villayerno Morquillas",
            "Villayón",
            "Villazala",
            "Villazanzo de Valderaduey",
            "Villazopeque",
            "Villegas",
            "Villeguillo",
            "Villel",
            "Villel de Mesa",
            "Villena",
            "Villerías de Campos",
            "Villodre",
            "Villodrigo",
            "Villoldo",
            "Víllora",
            "Villores",
            "Villoría",
            "Villoruebo",
            "Villoruela",
            "Villoslada de Cameros",
            "Villota del Páramo",
            "Villovieco",
            "Vilobí del Penedès",
            "Vilobí dOnyar",
            "Vilopriu",
            "Viloria",
            "Viloria de Rioja",
            "Vilosell El",
            "Vilueña La",
            "Vilvestre",
            "Vilviestre del Pinar",
            "Vimbodí i Poblet",
            "Vimianzo",
            "Vinaceite",
            "Vinaixa",
            "Vinalesa",
            "Vinaròs",
            "Viñas",
            "Vindel",
            "Vinebre",
            "Viñegra de Moraña",
            "Viniegra de Abajo",
            "Viniegra de Arriba",
            "Viñuela",
            "Viñuelas",
            "Vinuesa",
            "Vinyols i els Arcs",
            "Visiedo",
            "Viso de San Juan El",
            "Viso del Alcor El",
            "Viso del Marqués",
            "Viso El",
            "Vistabella",
            "Vistabella del Maestrazgo",
            "Vita",
            "Vitigudino",
            "Vitoria-Gasteiz",
            "Viveiro",
            "Vivel del Río Martín",
            "Viver",
            "Viver i Serrateix",
            "Viveros",
            "Vizcaínos",
            "Vizmanos",
            "Víznar",
            "Voto",
            "Vozmediano",
            "Wamba",
            "Xaló",
            "Xàtiva",
            "Xeraco",
            "XeracoJaraco",
            "Xeresa",
            "Xermade",
            "Xerta",
            "Xinzo de Limia",
            "Xirivella",
            "Xove",
            "Xunqueira de Ambía",
            "Xunqueira de Espadanedo",
            "Yaiza",
            "Yanguas",
            "Yanguas de Eresma",
            "Yátova",
            "Yébenes Los",
            "Yebes",
            "Yebra",
            "Yebra de Basa",
            "Yecla",
            "Yecla de Yeltes",
            "Yécora/Iekora",
            "Yélamos de Abajo",
            "Yélamos de Arriba",
            "Yeles",
            "Yelo",
            "Yémeda",
            "Yepes",
            "Yernes y Tameza",
            "Yerri",
            "Yesa",
            "Yesa La",
            "Yésero",
            "Yeste",
            "Yuncler",
            "Yunclillos",
            "Yuncos",
            "Yunquera",
            "Yunquera de Henares",
            "Yunta La",
            "Zabalza/Zabaltza",
            "Zael",
            "Zafarraya",
            "Zafra",
            "Zafra de Záncara",
            "Zafrilla",
            "Zagra",
            "Zahara",
            "Zahinos",
            "Zaida La",
            "Zaidín",
            "Zalamea de la Serena",
            "Zalamea la Real",
            "Zaldibar",
            "Zaldibia",
            "Zalduondo",
            "Zalla",
            "Zamarra",
            "Zamayón",
            "Zambrana",
            "Zamora",
            "Zamudio",
            "Zaorejas",
            "Zapardiel de la Cañada",
            "Zapardiel de la Ribera",
            "Zaragoza",
            "Zarapicos",
            "Zaratamo",
            "Zaratán",
            "Zarautz",
            "Zarra",
            "Zarratón",
            "Zarza de Granadilla",
            "Zarza de Montánchez",
            "Zarza de Pumareda La",
            "Zarza de Tajo",
            "Zarza La",
            "Zarza La",
            "Zarza la Mayor",
            "Zarza-Capilla",
            "Zarzalejo",
            "Zarzosa",
            "Zarzosa de Río Pisuerga",
            "Zarzuela",
            "Zarzuela de Jadraque",
            "Zarzuela del Monte",
            "Zarzuela del Pinar",
            "Zas",
            "Zazuar",
            "Zeanuri",
            "Zeberio",
            "Zegama",
            "Zerain",
            "Zestoa",
            "Zierbena",
            "Zigoitia",
            "Ziordia",
            "Ziortza-Bolibar",
            "Zizur Mayor/Zizur Nagusia",
            "Zizurkil",
            "Zoma La",
            "Zorita",
            "Zorita de la Frontera",
            "Zorita de los Canes",
            "Zorita del Maestrazgo",
            "Zorraquín",
            "Zotes del Páramo",
            "Zubia La",
            "Zubieta",
            "Zucaina",
            "Zuera",
            "Zufre",
            "Zugarramurdi",
            "Zuheros",
            "Zuia",
            "Zújar",
            "Zumaia",
            "Zumarraga",
            "Zuñeda",
            "Zúñiga",
            "Zurgena"
        ]
    },
    {
        "label": "Sri Lanka",
        "code": "LK",
        "phone": "94",
        "cities": [
            "Akurana",
            "Alawatugoda",
            "Ambalangoda",
            "Ambatenna",
            "Ampara",
            "Ampara District",
            "Anuradhapura",
            "Anuradhapura District",
            "Badulla",
            "Badulla District",
            "Battaramulla South",
            "Batticaloa",
            "Batticaloa District",
            "Bentota",
            "Beruwala",
            "Chilaw",
            "Colombo",
            "Colombo District",
            "Dambulla",
            "Daskara",
            "Daulagala",
            "Dehiwala-Mount Lavinia",
            "Delthota",
            "Devinuwara",
            "Doluwa",
            "Ella Town",
            "Eravur Town",
            "Galagedara",
            "Galhinna",
            "Galle",
            "Galle District",
            "Gampaha",
            "Gampaha District",
            "Gampola",
            "Ganga Ihala Korale",
            "Hambantota District",
            "Hanguranketa",
            "Hanwella Ihala",
            "Hapugastalawa",
            "Haputale",
            "Harispattuwa",
            "Hatharaliyadda",
            "Hatton",
            "Hendala",
            "Hikkaduwa",
            "Homagama",
            "Horana South",
            "Horawala Junction",
            "Ja Ela",
            "Jaffna",
            "Jaffna District",
            "Kadugannawa",
            "Kaduwela",
            "Kalmunai",
            "Kalutara",
            "Kandana",
            "Kandy",
            "Kandy District",
            "Kandy Four Gravets",
            "Kataragama",
            "Katugastota",
            "Katunayaka",
            "Kegalle",
            "Kegalle District",
            "Kelaniya",
            "Kesbewa",
            "Kilinochchi",
            "Kilinochchi District",
            "Koggala",
            "Kolonnawa",
            "Kotikawatta",
            "Kotte",
            "Kuliyapitiya",
            "Kundasale",
            "Kurunegala",
            "Kurunegala District",
            "Madawala",
            "Maharagama",
            "Matale",
            "Matale District",
            "Matara",
            "Matara District",
            "Medadumbara",
            "Menikdiwela",
            "Mihintale",
            "Minipe",
            "Minuwangoda",
            "Mirissa city",
            "Monaragala",
            "Moneragala District",
            "Moratuwa",
            "Mulleriyawa",
            "Nawalapitiya",
            "Negombo",
            "Nuwara Eliya",
            "Nuwara Eliya District",
            "Padukka",
            "Pallekele",
            "Panadura",
            "Panvila",
            "Pasbage Korale",
            "Pathadumbara",
            "Pathahewaheta",
            "Peliyagoda",
            "Peradeniya",
            "Pilimathalawa",
            "Pita Kotte",
            "Point Pedro",
            "Polonnaruwa",
            "Polonnaruwa District",
            "Poojapitiya",
            "Pussellawa",
            "Puttalam",
            "Puttalam District",
            "Ratmalana",
            "Ratnapura",
            "Ratnapura District",
            "Seethawaka",
            "Sigiriya",
            "Sri Jayewardenepura Kotte",
            "Talatuoya",
            "Talawakele",
            "Talpe",
            "Tangalle",
            "Teldeniya",
            "Thimbirigasyaya",
            "Thumpane",
            "Trincomalee",
            "Trincomalee District",
            "Udadumbara",
            "Udapalatha",
            "Ududumbara",
            "Udunuwara",
            "Ulapane",
            "Unawatuna",
            "Vakarai",
            "Valvedditturai",
            "Vavuniya",
            "Vavuniya District",
            "Watadeniya",
            "Wattala",
            "Wattegama",
            "Welamboda",
            "Weligama",
            "Welisara",
            "Wellawaya",
            "Yatinuwara"
        ]
    },
    {
        "label": "Sudan",
        "code": "SD",
        "phone": "249",
        "cities": [
            "Abu Jibeha",
            "Abū Zabad",
            "Ad Dabbah",
            "Ad Dindar",
            "Ad Douiem",
            "Ad-Damazin",
            "Al Fūlah",
            "Al Hasaheisa",
            "Al Ḩawātah",
            "Al Hilāliyya",
            "Al Kawa",
            "Al Kiremit al ‘Arakiyyīn",
            "Al Lagowa",
            "Al Manāqil",
            "Al Masallamiyya",
            "Al Mijlad",
            "Al Qadarif",
            "Al Qiţena",
            "An Nuhūd",
            "Ar Rahad",
            "Ar Ruseris",
            "Argo",
            "Aroma",
            "As Sūkī",
            "Atbara",
            "Bārah",
            "Berber",
            "Dilling",
            "Doka",
            "Dongola",
            "Ed Damer",
            "El Bauga",
            "El Daein",
            "El Fasher",
            "El Matama",
            "El Obeid",
            "Gebeit",
            "Geneina",
            "Gereida",
            "Jalqani",
            "Kadugli",
            "Karmah an Nuzul",
            "Kassala",
            "Khartoum",
            "Kināna",
            "Kosti",
            "Kuraymah",
            "Kurmuk",
            "Kutum",
            "Maiurno",
            "Marabba",
            "Merowe",
            "Nyala",
            "Omdurman",
            "Port Sudan",
            "Rabak",
            "Sawākin",
            "Shendi",
            "Singa",
            "Sinnar",
            "Talodi",
            "Tandaltī",
            "Tokār",
            "Um Jar Al Gharbiyya",
            "Umm Kaddadah",
            "Umm Ruwaba",
            "Wad az Zāki",
            "Wad Medani",
            "Wad Rāwah",
            "Wagar",
            "Zalingei"
        ]
    },
    {
        "label": "Suriname",
        "code": "SR",
        "phone": "597",
        "cities": [
            "Albina",
            "Brokopondo",
            "Brownsweg",
            "Groningen",
            "Lelydorp",
            "Mariënburg",
            "Moengo",
            "Nieuw Amsterdam",
            "Nieuw Nickerie",
            "Onverwacht",
            "Paramaribo",
            "Totness",
            "Wageningen"
        ]
    },
    {
        "label": "Svalbard And Jan Mayen Islands",
        "code": "SJ",
        "phone": "47",
        "cities": []
    },
    {
        "label": "Swaziland",
        "code": "SZ",
        "phone": "268",
        "cities": [
            "Bhunya",
            "Big Bend",
            "Bulembu",
            "Dvokodvweni Inkhundla",
            "Ekukhanyeni",
            "Hhukwini",
            "Hlatikulu",
            "Hluti",
            "Kubuta",
            "Kwaluseni",
            "Lavumisa",
            "Lobamba",
            "Lomashasha",
            "Malkerns",
            "Manzini",
            "Manzini South",
            "Matsanjeni",
            "Mbabane",
            "Mhlambanyatsi",
            "Mhlume",
            "Ngudzeni",
            "Ngwempisi",
            "Nhlangano",
            "Nkhaba",
            "Nkwene",
            "Nsoko",
            "Ntondozi",
            "Piggs Peak",
            "Sidvokodvo",
            "Sigwe Inkhundla",
            "Siteki",
            "Tshaneni",
            "Vuvulane",
            "Zombodze Ikhundla"
        ]
    },
    {
        "label": "Sweden",
        "code": "SE",
        "phone": "46",
        "cities": [
            "Abborrberget",
            "Åby",
            "Åhus",
            "Akalla",
            "Åkarp",
            "Åkarp",
            "Åkers Styckebruk",
            "Åkersberga",
            "Alafors",
            "Alby",
            "Åled",
            "Alfta",
            "Alingsås",
            "Älmhult",
            "Älmsta",
            "Alsike",
            "Älta",
            "Alunda",
            "Älvängen",
            "Älvdalen",
            "Alvesta",
            "Älvkarleby",
            "Älvsbyn",
            "Åmål",
            "Åmotfors",
            "Andalen",
            "Anderslöv",
            "Anderstorp",
            "Anderstorp",
            "Aneby",
            "Ånge",
            "Ängelholm",
            "Angered",
            "Ankarsrum",
            "Anneberg",
            "Annelund",
            "Arboga",
            "Arbrå",
            "Årjäng",
            "Arjeplog",
            "Ärla",
            "Arlöv",
            "Arnö",
            "Arnö",
            "Årsta",
            "Årsunda",
            "Arvidsjaur",
            "Arvika",
            "Ås",
            "Åsa",
            "Åsbro",
            "Åseda",
            "Åsele",
            "Askersund",
            "Asmundtorp",
            "Åstorp",
            "Åtvidaberg",
            "Avesta",
            "Axvall",
            "Backa",
            "Bälinge",
            "Bålsta",
            "Bankeryd",
            "Bara",
            "Bara",
            "Barkarö",
            "Bårslöv",
            "Båstad",
            "Bengtsfors",
            "Berg",
            "Bergby",
            "Bergeforsen",
            "Bergkvara",
            "Bergnäset",
            "Bergshamra",
            "Bergsjö",
            "Bergsviken",
            "Billdal",
            "Billeberga",
            "Billesholm",
            "Billingsfors",
            "Bjärnum",
            "Bjärred",
            "Bjästa",
            "Björboholm",
            "Björklinge",
            "Björkö",
            "Björkskatan",
            "Björlanda",
            "Björneborg",
            "Bjurholm",
            "Bjursås",
            "Bjuv",
            "Blentarp",
            "Blomstermåla",
            "Bodafors",
            "Boden",
            "Boliden",
            "Bollebygd",
            "Bollmora",
            "Bollnäs",
            "Bollstabruk",
            "Boo",
            "Bor",
            "Borås",
            "Borensberg",
            "Borgholm",
            "Borlänge",
            "Boxholm",
            "Braås",
            "Bräkne-Hoby",
            "Brålanda",
            "Brastad",
            "Bredaryd",
            "Bredbyn",
            "Brevik",
            "Bro",
            "Broby",
            "Bromma",
            "Bromölla",
            "Brunn",
            "Brunna",
            "Brunna",
            "Bua",
            "Bunkeflostrand",
            "Bureå",
            "Burträsk",
            "Byske",
            "Charlottenberg",
            "Dalarö",
            "Dalby",
            "Dals Långed",
            "Dalsjöfors",
            "Degeberga",
            "Degerfors",
            "Deje",
            "Delsbo",
            "Dingtuna",
            "Diseröd",
            "Djurås",
            "Djurö",
            "Djursholm",
            "Donsö",
            "Dorotea",
            "Ed",
            "Edsbyn",
            "Ekängen",
            "Ekeby",
            "Ekeby-Almby",
            "Ekenässjön",
            "Ekerö",
            "Ekshärad",
            "Eksjö",
            "Ellös",
            "Emmaboda",
            "Enbacka",
            "Enebyberg",
            "Enhagen-Ekbacken",
            "Enköping",
            "Eriksberg",
            "Eriksbo",
            "Ersmark",
            "Eskilstuna",
            "Eslöv",
            "Fagersta",
            "Falkenberg",
            "Falköping",
            "Falun",
            "Färgelanda",
            "Färila",
            "Färjestaden",
            "Färlöv",
            "Fellingsbro",
            "Filipstad",
            "Finspång",
            "Fisksätra",
            "Fittja",
            "Fjälkinge",
            "Fjärås kyrkby",
            "Fjugesta",
            "Flen",
            "Floby",
            "Forsbacka",
            "Forsbacka",
            "Forserum",
            "Forshaga",
            "Forsheda",
            "Förslöv",
            "Fränsta",
            "Frillesås",
            "Fristad",
            "Fritsla",
            "Frösakull",
            "Frövi",
            "Furulund",
            "Furulund",
            "Fyllinge",
            "Gagnef",
            "Gällivare",
            "Gamla Stan",
            "Gamla Uppsala",
            "Gamleby",
            "Gammelstad",
            "Gånghester",
            "Gantofta",
            "Gårdsten",
            "Garphyttan",
            "Gärsnäs",
            "Gävle",
            "Gemla",
            "Genarp",
            "Getinge",
            "Gimo",
            "Gislaved",
            "Glimåkra",
            "Glommen",
            "Glumslöv",
            "Gnarp",
            "Gnesta",
            "Gnosjö",
            "Göta",
            "Göteborg",
            "Göteborgs stad",
            "Götene",
            "Gotland",
            "Gråbo",
            "Grängesberg",
            "Gränna",
            "Grästorp",
            "Grebbestad",
            "Grebo",
            "Grillby",
            "Grums",
            "Grycksbo",
            "Gullbrandstorp",
            "Gullspång",
            "Gunnebo",
            "Gustavsberg",
            "Gusum",
            "Habo",
            "Hagfors",
            "Häljarp",
            "Hällabrottet",
            "Hällbybrunn",
            "Hällefors",
            "Hälleforsnäs",
            "Hällevik",
            "Hallsberg",
            "Hallstahammar",
            "Hallstavik",
            "Halmstad",
            "Hammar",
            "Hammarkullen",
            "Hanaskog",
            "Handen",
            "Haninge",
            "Haparanda",
            "Härnösand",
            "Harplinge",
            "Hässleholm",
            "Hasslö",
            "Hästveda",
            "Haverdal",
            "Heby",
            "Hedemora",
            "Hedesunda",
            "Helsingborg",
            "Hemmesta",
            "Hemse",
            "Henån",
            "Herrestad",
            "Herrljunga",
            "Hestra",
            "Hillerstorp",
            "Hindås",
            "Hittarp",
            "Hjärnarp",
            "Hjärup",
            "Hjo",
            "Hjuvik",
            "Hofors",
            "Hofterup",
            "Höganäs",
            "Högsby",
            "Hökåsen",
            "Hököpinge",
            "Höllviken",
            "Holmsund",
            "Hölö",
            "Hönö",
            "Höör",
            "Hörby",
            "Horndal",
            "Hörnefors",
            "Horred",
            "Hortlax",
            "Hova",
            "Höviksnäs",
            "Hovmantorp",
            "Hovsta",
            "Huddinge",
            "Hudiksvall",
            "Hultsfred",
            "Hunnebostrand",
            "Huskvarna",
            "Husum",
            "Hyllinge",
            "Hyltebruk",
            "Iggesund",
            "Ingared",
            "Ingelstad",
            "Insjön",
            "Insjön",
            "Irsta",
            "Irsta",
            "Jakobsberg",
            "Jämjö",
            "Jämshög",
            "Järbo",
            "Järna",
            "Järna",
            "Järvsö",
            "Johannedal",
            "Jokkmokk",
            "Jönköping",
            "Jonstorp",
            "Jonstorp",
            "Jordbro",
            "Jörlanda",
            "Jursla",
            "Kåge",
            "Kågeröd",
            "Kalix",
            "Källby",
            "Kållered",
            "Kallinge",
            "Kalmar",
            "Karlholmsbruk",
            "Karlsborg",
            "Karlshamn",
            "Karlskoga",
            "Karlskrona",
            "Karlstad",
            "Katrineholm",
            "Kävlinge",
            "Kaxholmen",
            "Kil",
            "Kilafors",
            "Kimstad",
            "Kinna",
            "Kiruna",
            "Kisa",
            "Kista",
            "Klågerup",
            "Klintehamn",
            "Klippan",
            "Knäred",
            "Knislinge",
            "Knivsta",
            "Kode",
            "Kolbäck",
            "Kolsva",
            "Köping",
            "Köpingebro",
            "Köpmanholmen",
            "Kopparberg",
            "Kopparmora",
            "Kramfors",
            "Kristdala",
            "Kristianstad",
            "Kristinehamn",
            "Krokek",
            "Krylbo",
            "Kumla",
            "Kummelnäs",
            "Kungälv",
            "Kungsängen",
            "Kungsbacka",
            "Kungshamn",
            "Kungsholmen",
            "Kungsör",
            "Kvänum",
            "Kvicksund",
            "Kvidinge",
            "Kvissleby",
            "Lagan",
            "Laholm",
            "Lammhult",
            "Landsbro",
            "Landskrona",
            "Landvetter",
            "Länghem",
            "Långsele",
            "Långsele",
            "Långshyttan",
            "Långvik",
            "Laxå",
            "Leksand",
            "Lenhovda",
            "Lerum",
            "Lessebo",
            "Lidingö",
            "Lidköping",
            "Lilla Edet",
            "Limmared",
            "Lindesberg",
            "Lindholmen",
            "Lindö",
            "Lindome",
            "Lindsdal",
            "Linghem",
            "Linköping",
            "Ljungby",
            "Ljungbyhed",
            "Ljungbyholm",
            "Ljunghusen",
            "Ljungsbro",
            "Ljungskile",
            "Ljusdal",
            "Ljusne",
            "Löberöd",
            "Löddeköpinge",
            "Lödöse",
            "Lomma",
            "Lönsboda",
            "Lövgärdet",
            "Lövstalöt",
            "Ludvika",
            "Luleå",
            "Lund",
            "Lycksele",
            "Lysekil",
            "Majorna",
            "Malå",
            "Målilla",
            "Malmbäck",
            "Malmberget",
            "Malmköping",
            "Malmö",
            "Malmslätt",
            "Malung",
            "Mantorp",
            "Mariannelund",
            "Marieberg",
            "Mariefred",
            "Marieholm",
            "Marieholm",
            "Marielund",
            "Mariestad",
            "Markaryd",
            "Märsta",
            "Matfors",
            "Mellbystrand",
            "Mellerud",
            "Mjällby",
            "Mjällby",
            "Mjölby",
            "Mockfjärd",
            "Moheda",
            "Molkom",
            "Mölltorp",
            "Mölnbo",
            "Mölndal",
            "Mölnlycke",
            "Mönsterås",
            "Mora",
            "Mörarp",
            "Mörbylånga",
            "Morgongåva",
            "Mörrum",
            "Mörtnäs",
            "Motala",
            "Mullsjö",
            "Munka-Ljungby",
            "Munkedal",
            "Munkfors",
            "Myggenäs",
            "Nacka",
            "Nässjö",
            "Näsum",
            "Nättraby",
            "Njurundabommen",
            "Nolby",
            "Nolvik",
            "Nora",
            "Norberg",
            "Nordmaling",
            "Norra Åsum",
            "Norrfjärden",
            "Norrköping",
            "Norrtälje",
            "Norsjö",
            "Nossebro",
            "Nybro",
            "Nyhammar",
            "Nyköping",
            "Nykvarn",
            "Nynäshamn",
            "Obbola",
            "Ockelbo",
            "Öckerö",
            "Ödåkra",
            "Odensbacken",
            "Odensjö",
            "Ödeshög",
            "Ödsmål",
            "Öjersjö",
            "Olofstorp",
            "Olofström",
            "Olstorp",
            "Olstorp",
            "Önnestad",
            "Onsala",
            "Örbyhus",
            "Örebro",
            "Öregrund",
            "Örkelljunga",
            "Ornäs",
            "Örnsköldsvik",
            "Orsa",
            "Örsundsbro",
            "Orust",
            "Osby",
            "Oskarshamn",
            "Oskarström",
            "Ösmo",
            "Österbybruk",
            "Österbymo",
            "Östermalm",
            "Östervåla",
            "Östhammar",
            "Östra Ljungby",
            "Överkalix",
            "Övertorneå",
            "Överum",
            "Oxelösund",
            "Oxie",
            "Påarp",
            "Pajala",
            "Pålsboda",
            "Partille",
            "Påskallavik",
            "Pershagen",
            "Perstorp",
            "Piteå",
            "Ramnäs",
            "Råneå",
            "Rannebergen",
            "Råsunda",
            "Råtorp",
            "Rättvik",
            "Rävlanda",
            "Reftele",
            "Resarö",
            "Rimbo",
            "Rimforsa",
            "Rindö",
            "Rinkabyholm",
            "Röbäck",
            "Robertsfors",
            "Rödeby",
            "Roknäs",
            "Rolfs",
            "Romme",
            "Rönnäng",
            "Ronneby",
            "Rönninge",
            "Rosersberg",
            "Rosvik",
            "Rottne",
            "Ryd",
            "Ryd",
            "Rydaholm",
            "Rydebäck",
            "Rydsgård",
            "Säffle",
            "Sala",
            "Sålanda",
            "Saltsjöbaden",
            "Sandared",
            "Sandarne",
            "Sandviken",
            "Särö",
            "Säter",
            "Sätila",
            "Sätofta",
            "Sävar",
            "Sävast",
            "Sävja",
            "Sävsjö",
            "Saxtorpsskogen",
            "Segeltorp",
            "Sibbhult",
            "Sigtuna",
            "Siljansnäs",
            "Simrishamn",
            "Sjöbo",
            "Sjömarken",
            "Sjuntorp",
            "Skänninge",
            "Skänninge",
            "Skanör med Falsterbo",
            "Skara",
            "Skärblacka",
            "Skåre",
            "Skåre",
            "Skärhamn",
            "Skattkärr",
            "Skegrie",
            "Skellefteå",
            "Skelleftehamn",
            "Skepplanda",
            "Skillingaryd",
            "Skinnskatteberg",
            "Skivarp",
            "Skoghall",
            "Skogstorp",
            "Sköllersta",
            "Skottsund",
            "Skövde",
            "Skrea",
            "Skultorp",
            "Skultuna",
            "Skurup",
            "Skutskär",
            "Slottsbron",
            "Smålandsstenar",
            "Smedby",
            "Smedby",
            "Smedjebacken",
            "Smögen",
            "Smygehamn",
            "Söderfors",
            "Söderfors",
            "Söderhamn",
            "Söderköping",
            "Södermalm",
            "Södertälje",
            "Södra Sandby",
            "Södra Sandby",
            "Södra Sunderbyn",
            "Södra Vi",
            "Sollebrunn",
            "Sollefteå",
            "Sollentuna",
            "Solna",
            "Sölvesborg",
            "Söråker",
            "Sörforsa",
            "Sorsele",
            "Sösdala",
            "Staffanstorp",
            "Stallarholmen",
            "Stångby",
            "Stavsnäs",
            "Stehag",
            "Stenhamra",
            "Steninge",
            "Stenkullen",
            "Stenstorp",
            "Stenstorp",
            "Stenungsund",
            "Stigtomta",
            "Stockaryd",
            "Stockholm",
            "Stockvik",
            "Stöpen",
            "Storå",
            "Stora Höga",
            "Storebro",
            "Storfors",
            "Storuman",
            "Storvik",
            "Storvik",
            "Storvreta",
            "Strängnäs",
            "Strömma",
            "Strömsnäsbruk",
            "Strömstad",
            "Strövelstorp",
            "Sturefors",
            "Sturkö",
            "Styrsö",
            "Sundbyberg",
            "Sundsbruk",
            "Sundsvall",
            "Sunne",
            "Surahammar",
            "Surte",
            "Svalöv",
            "Svalsta",
            "Svanesund",
            "Svängsta",
            "Svärdsjö",
            "Svärtinge",
            "Svedala",
            "Svenljunga",
            "Taberg",
            "Täfteå",
            "Tallboda",
            "Tanumshede",
            "Tärnsjö",
            "Teckomatorp",
            "Tenhult",
            "Tensta",
            "Tibro",
            "Tidaholm",
            "Tierp",
            "Tillberga",
            "Timmernabben",
            "Timrå",
            "Tingsryd",
            "Tjuvkil",
            "Töcksfors",
            "Tollarp",
            "Tollarp1",
            "Tomelilla",
            "Töre",
            "Töreboda",
            "Torekov",
            "Tormestorp",
            "Torsås",
            "Torsby",
            "Torshälla",
            "Torslanda",
            "Torup",
            "Tranås",
            "Tranemo",
            "Träslövsläge",
            "Trekanten",
            "Trelleborg",
            "Trollhättan",
            "Trönninge",
            "Trosa",
            "Tullinge",
            "Tumba",
            "Tvååker",
            "Tygelsjö",
            "Tyringe",
            "Uddevalla",
            "Ulricehamn",
            "Umeå",
            "Upplands Väsby",
            "Uppsala",
            "Ursviken",
            "Vadstena",
            "Vaggeryd",
            "Vagnhärad",
            "Vålberg",
            "Valbo",
            "Valdemarsvik",
            "Valje",
            "Valla",
            "Vallda",
            "Vallentuna",
            "Vänersborg",
            "Vännäs",
            "Vännäsby",
            "Vansbro",
            "Vara",
            "Varberg",
            "Vårby",
            "Vårgårda",
            "Vargön",
            "Värnamo",
            "Vårsta",
            "Vasastan",
            "Västerås",
            "Västervik",
            "Västra Frölunda",
            "Västra Hagen",
            "Vattholma",
            "Vaxholm",
            "Växjö",
            "Veberöd",
            "Veddige",
            "Veinge",
            "Vejbystrand",
            "Vellinge",
            "Vetlanda",
            "Vi",
            "Vibble",
            "Vikarbyn",
            "Viken",
            "Viken",
            "Vikingstad",
            "Vilhelmina",
            "Vimmerby",
            "Vindeln",
            "Vingåker",
            "Vinninga",
            "Vinslöv",
            "Vintrosa",
            "Virserum",
            "Visby",
            "Viskafors",
            "Vislanda",
            "Vittsjö",
            "Vrigstad",
            "Ystad"
        ]
    },
    {
        "label": "Switzerland",
        "code": "CH",
        "phone": "41",
        "cities": [
            "Aadorf",
            "Aarau",
            "Aarberg",
            "Aarburg",
            "Aarwangen",
            "Acquarossa",
            "Adelboden",
            "Adligenswil",
            "Adliswil",
            "Adliswil / Adliswil (Stadtkern)",
            "Adliswil / Hündli-Zopf",
            "Adliswil / Oberleimbach",
            "Adliswil / Sonnenberg",
            "Adliswil / Sood",
            "Adliswil / Tal",
            "Aesch",
            "Aesch",
            "Aeschi b. Spiez",
            "Affeltrangen",
            "Affoltern / Hasenbüel",
            "Affoltern / Oberdorf",
            "Affoltern / Sonnenberg",
            "Affoltern / Unterdorf",
            "Affoltern am Albis",
            "Agno",
            "Aigle",
            "Aigle District",
            "Aire-la-Ville",
            "Airolo",
            "Alle",
            "Allschwil",
            "Alpnach",
            "Altdorf",
            "Altendorf",
            "Alterswil",
            "Altishofen",
            "Altnau",
            "Altstätten",
            "Amden",
            "Amriswil",
            "Andelfingen",
            "Andermatt",
            "Andwil",
            "Anières",
            "Appenzell",
            "Apples",
            "Arbon",
            "Arbon District",
            "Arch",
            "Ardon",
            "Arisdorf",
            "Aristau",
            "Arlesheim",
            "Arosa",
            "Arth",
            "Arvigo",
            "Arzier",
            "Arzo",
            "Ascona",
            "Attalens",
            "Attinghausen",
            "Attiswil",
            "Au",
            "Au",
            "Au / Mittel-Dorf",
            "Au / Unter-Dorf",
            "Aubonne",
            "Auvernier",
            "Auw",
            "Avenches",
            "Avry-sur-Matran",
            "Ayent",
            "Baar",
            "Bäch",
            "Bachenbülach",
            "Bad Ragaz",
            "Bad Zurzach",
            "Baden",
            "Bagnes",
            "Balerna",
            "Balgach",
            "Ballens",
            "Ballwil",
            "Balsthal",
            "Baltschieder",
            "Bäretswil",
            "Bäriswil",
            "Bas-Vully",
            "Basel",
            "Basse-Nendaz",
            "Bassecourt",
            "Bassersdorf",
            "Bätterkinden",
            "Bauen",
            "Bauma",
            "Bavois",
            "Beatenberg",
            "Beckenried",
            "Begnins",
            "Belfaux",
            "Bellevue",
            "Bellinzona",
            "Bellinzona District",
            "Bellmund",
            "Belmont-sur-Lausanne",
            "Belp",
            "Benglen",
            "Benken",
            "Berg",
            "Berikon",
            "Beringen",
            "Bern",
            "Bern-Mittelland District",
            "Bernex",
            "Berolle",
            "Beromünster",
            "Besenbüren",
            "Bettingen",
            "Bettlach",
            "Bevaix",
            "Bévilard",
            "Bex",
            "Bezirk Aarau",
            "Bezirk Affoltern",
            "Bezirk Andelfingen",
            "Bezirk Arlesheim",
            "Bezirk Baden",
            "Bezirk Bremgarten",
            "Bezirk Brugg",
            "Bezirk Bucheggberg",
            "Bezirk Bülach",
            "Bezirk Dielsdorf",
            "Bezirk Dietikon",
            "Bezirk Dorneck",
            "Bezirk Einsiedeln",
            "Bezirk Gäu",
            "Bezirk Gersau",
            "Bezirk Gösgen",
            "Bezirk Hinterland",
            "Bezirk Hinwil",
            "Bezirk Höfe",
            "Bezirk Horgen",
            "Bezirk Kulm",
            "Bezirk Küssnacht",
            "Bezirk Laufen",
            "Bezirk Laufenburg",
            "Bezirk Lebern",
            "Bezirk Lenzburg",
            "Bezirk Liestal",
            "Bezirk March",
            "Bezirk Meilen",
            "Bezirk Mittelland",
            "Bezirk Muri",
            "Bezirk Oberklettgau",
            "Bezirk Olten",
            "Bezirk Pfäffikon",
            "Bezirk Reiat",
            "Bezirk Rheinfelden",
            "Bezirk Schaffhausen",
            "Bezirk Schleitheim",
            "Bezirk Schwyz",
            "Bezirk Sissach",
            "Bezirk Solothurn",
            "Bezirk Stein",
            "Bezirk Thal",
            "Bezirk Thierstein",
            "Bezirk Unterklettgau",
            "Bezirk Uster",
            "Bezirk Vorderland",
            "Bezirk Waldenburg",
            "Bezirk Wasseramt",
            "Bezirk Winterthur",
            "Bezirk Zofingen",
            "Bezirk Zürich",
            "Bezirk Zurzach",
            "Biasca",
            "Biberist",
            "Biel/Bienne",
            "Biel/Bienne District",
            "Bière",
            "Biglen",
            "Bilten",
            "Binningen",
            "Binz",
            "Binzikon",
            "Bioggio",
            "Birchwil",
            "Birmensdorf",
            "Birmenstorf",
            "Birr",
            "Birsfelden",
            "Blécherette",
            "Blenio District",
            "Blonay",
            "Blumenstein",
            "Bodio",
            "Bolligen",
            "Boltigen",
            "Bonaduz",
            "Boncourt",
            "Bönigen",
            "Boniswil",
            "Bonstetten",
            "Bösingen",
            "Bottens",
            "Bottighofen",
            "Bottmingen",
            "Boudry",
            "Boudry District",
            "Bowil",
            "Breíl",
            "Breitenbach",
            "Bremgarten",
            "Brienz",
            "Brig",
            "Brig District",
            "Brislach",
            "Brissago",
            "Brittnau",
            "Broc",
            "Bronschhofen",
            "Broye District",
            "Broye-Vully District",
            "Brugg",
            "Brügg",
            "Brusio",
            "Brütten",
            "Brüttisellen",
            "Bubendorf",
            "Bubikon",
            "Buchholterberg",
            "Buchrain",
            "Buchs",
            "Buchs",
            "Bühler",
            "Bülach",
            "Bülach / Gstückt",
            "Bülach / Seematt",
            "Bülach / Soligänter",
            "Bulle",
            "Buochs",
            "Burgdorf",
            "Burgistein",
            "Bürglen",
            "Bürglen",
            "Büron",
            "Buseno",
            "Büsserach",
            "Bussigny",
            "Bütschwil",
            "Buttisholz",
            "Buttwil",
            "Buus",
            "Cadempino",
            "Cadenazzo",
            "Cadro",
            "Camorino",
            "Canobbio",
            "Carouge",
            "Caslano",
            "Castel San Pietro",
            "Cauco",
            "Caux",
            "Cazis",
            "Celerina",
            "Cernier",
            "Cevio",
            "Chalais",
            "Cham",
            "Chamoson",
            "Champéry",
            "Chancy",
            "Chardonne",
            "Charmey",
            "Charrat",
            "Château-d'Oex",
            "Châtel-Saint-Denis",
            "Chavannes",
            "Chavannes-le-Veyron",
            "Chavornay",
            "Chêne-Bougeries",
            "Chêne-Bourg",
            "Chermignon-d’en Haut",
            "Cheseaux",
            "Chevilly",
            "Chexbres",
            "Chézard-Saint-Martin",
            "Chiasso",
            "Chippis",
            "Chur",
            "Churwalden",
            "Claro",
            "Collombey",
            "Colombier",
            "Comano",
            "Confignon",
            "Conthey",
            "Conthey District",
            "Coppet",
            "Corgémont",
            "Corminboeuf",
            "Cornaux",
            "Corsier",
            "Cortaillod",
            "Cossonay",
            "Courfaivre",
            "Courgenay",
            "Courgevaux",
            "Courrendlin",
            "Courroux",
            "Court",
            "Courtelary",
            "Courtételle",
            "Couvet",
            "Crissier",
            "Cuarnens",
            "Cugnasco",
            "Cugy",
            "Cully",
            "Dachsen",
            "Dagmersellen",
            "Dallenwil",
            "Dällikon / Dällikon (Dorf)",
            "Dänikon",
            "Dardagny",
            "Därligen",
            "Davos",
            "Degersheim",
            "Deitingen",
            "Delémont",
            "Delémont District",
            "Densbüren",
            "Derendingen",
            "Diegten",
            "Dielsdorf",
            "Diemtigen",
            "Diepoldsau",
            "Diessenhofen",
            "Dietikon",
            "Dietikon / Almend",
            "Dietikon / Guggenbühl",
            "Dietikon / Hofacker",
            "Dietikon / Kreuzacker",
            "Dietikon / Oberdorf",
            "Dietikon / Schönenwerd",
            "Dietikon / Vorstadt",
            "Dietlikon / Dietlikon (Dorf)",
            "Dietlikon / Eichwiesen",
            "Disentis",
            "Domat",
            "Dombresson",
            "Domdidier",
            "Dorf",
            "Dornach",
            "Dottikon",
            "Dübendorf",
            "Dübendorf / Kunklerstrasse",
            "Dübendorf / Sonnenberg",
            "Dübendorf / Vogelquartier",
            "Dübendorf / Wasserfurren",
            "Düdingen",
            "Dürnten",
            "Dürrenäsch",
            "Dürrenroth",
            "Ebikon",
            "Ebmatingen",
            "Ebnat-Kappel",
            "Echallens",
            "Ecublens",
            "Ecublens",
            "Effretikon",
            "Effretikon / Rappenhalde-Bannhalde",
            "Effretikon / Rikon",
            "Effretikon / Watt",
            "Egerkingen",
            "Egg",
            "Eggersriet",
            "Eggiwil",
            "Egliswil",
            "Egnach",
            "Ehrendingen",
            "Eich",
            "Eichberg",
            "Eiken",
            "Einsiedeln",
            "Elgg",
            "Elgg / Städtchen und Umgebung",
            "Elsau-Räterschen",
            "Elsau-Räterschen / Räterschen",
            "Embrach",
            "Embrach / Embrach (Dorfkern)",
            "Embrach / Kellersacker",
            "Emmen",
            "Emmental District",
            "Emmetten",
            "Endingen",
            "Engelberg",
            "Ennenda",
            "Ennetbürgen",
            "Entlebuch",
            "Entlebuch District",
            "Entremont District",
            "Epalinges",
            "Ependes",
            "Eriswil",
            "Erlach",
            "Erlen",
            "Erlenbach",
            "Erlenbach / links des Dorfbachs oberhalb Bahnlinie",
            "Erlenbach / rechts des Dorfbachs oberhalb Bahnlinie",
            "Erlenbach im Simmental",
            "Erlinsbach",
            "Ermatingen",
            "Ersigen",
            "Erstfeld",
            "Eschenbach",
            "Eschenz",
            "Eschlikon",
            "Escholzmatt",
            "Esslingen",
            "Estavayer-le-Lac",
            "Ettingen",
            "Ettiswil",
            "Evilard",
            "Evionnaz",
            "Evolène",
            "Faido",
            "Fällanden",
            "Fehraltorf",
            "Feldmeilen",
            "Felsberg",
            "Ferenbalm",
            "Ferreyres",
            "Feuerthalen",
            "Feusisberg",
            "Fiesch",
            "Fischingen",
            "Flamatt",
            "Flawil",
            "Fleurier",
            "Flims",
            "Flüelen",
            "Flühli",
            "Flums",
            "Flurlingen",
            "Fontainemelon",
            "Fontenais",
            "Founex",
            "Franches-Montagnes District",
            "Fraubrunnen",
            "Frauenfeld",
            "Frauenfeld District",
            "Frauenkappelen",
            "Freienbach",
            "Freienstein",
            "Frenkendorf",
            "Fribourg",
            "Frick",
            "Froideville",
            "Frutigen",
            "Frutigen-Niedersimmental District",
            "Fulenbach",
            "Füllinsdorf",
            "Fully",
            "Gachnang",
            "Gais",
            "Gampel",
            "Gams",
            "Ganterschwil",
            "Gattikon",
            "Gebenstorf",
            "Gelterkinden",
            "Genève",
            "Genolier",
            "Gerlafingen",
            "Geroldswil",
            "Gersau",
            "Geuensee",
            "Giffers",
            "Gimel",
            "Gipf-Oberfrick",
            "Giswil",
            "Giubiasco",
            "Givisiez",
            "Gland",
            "Glâne District",
            "Glarus",
            "Glattbrugg",
            "Glattbrugg / Rohr/Platten-Balsberg",
            "Glattbrugg / Wydacker/Bettacker/Lättenwiesen",
            "Glattfelden",
            "Glovelier",
            "Gockhausen",
            "Goldach",
            "Goldau",
            "Goldingen",
            "Gommiswald",
            "Goms District",
            "Gonten",
            "Gontenschwil",
            "Gordola",
            "Gorgier",
            "Gossau",
            "Gossau",
            "Gottlieben",
            "Grabs",
            "Grächen",
            "Grancy",
            "Grand-Savagnier",
            "Grandson",
            "Gränichen",
            "Gravesano",
            "Greifensee",
            "Greifensee / Müllerwis / Seilerwis",
            "Greifensee / Pfisterhölzli",
            "Grellingen",
            "Grenchen",
            "Grimisuat",
            "Grindelwald",
            "Grolley",
            "Grône",
            "Gros-de-Vaud District",
            "Grossacker/Opfikon",
            "Grossaffoltern",
            "Grosswangen",
            "Grüsch",
            "Grüt",
            "Gruyère District",
            "Gruyères",
            "Gryon",
            "Gstaad",
            "Guggisberg",
            "Gunzgen",
            "Gunzwil",
            "Gutenswil",
            "Güttingen",
            "Haag (Rheintal)",
            "Hadlikon",
            "Hägendorf",
            "Häggenschwil",
            "Hägglingen",
            "Hallau",
            "Hasle",
            "Haslen",
            "Hausen",
            "Hausen am Albis / Hausen (Dorf)",
            "Hedingen",
            "Hegnau",
            "Hegnau / Dammboden-Grindel",
            "Hegnau / Sunnebüel-Eich",
            "Heiden",
            "Heimberg",
            "Heimiswil",
            "Heitenried",
            "Henggart",
            "Hérémence",
            "Hérens District",
            "Hergiswil",
            "Herisau",
            "Hermiswil",
            "Herrliberg",
            "Herzogenbuchsee",
            "Hettlingen",
            "Hildisrieden",
            "Hilterfingen",
            "Himmelried",
            "Hindelbank",
            "Hinteregg",
            "Hinterrhein",
            "Hinwil",
            "Hittnau / Hittnau (Dorf)",
            "Hitzkirch",
            "Hochdorf",
            "Hochdorf District",
            "Hochfelden",
            "Hochwald",
            "Hofstetten",
            "Hohenrain",
            "Hölstein",
            "Hombrechtikon",
            "Homburg",
            "Horgen",
            "Horgen / Allmend",
            "Horgen / Horgen (Dorfkern)",
            "Horgen / Oberdorf",
            "Horgen / Scheller-Stockerstrasse",
            "Höri",
            "Horn",
            "Horw",
            "Hünenberg",
            "Huttwil",
            "Hüttwilen",
            "Ibach",
            "Igis",
            "Ilanz",
            "Illnau",
            "Illnau / Unter-Illnau",
            "Ingenbohl",
            "Ins",
            "Interlaken",
            "Interlaken-Oberhasli District",
            "Inwil",
            "Itingen",
            "Jegenstorf",
            "Jenaz",
            "Jona",
            "Jonen",
            "Jongny",
            "Jonschwil",
            "Jura bernois",
            "Jura-Nord vaudois District",
            "Jussy",
            "Kaiseraugst",
            "Kaisten",
            "Kallnach",
            "Kaltbrunn",
            "Kandersteg",
            "Kappelen",
            "Kehrsatz",
            "Kerns",
            "Kerzers",
            "Kilchberg",
            "Kilchberg / Bächler-Stocken",
            "Kilchberg / Kilchberg (Dorfkern)",
            "Killwangen",
            "Kindhausen / Kindhausen (Dorf)",
            "Kirchberg",
            "Kirchberg",
            "Kirchlindach",
            "Kleinandelfingen",
            "Kleinlützel",
            "Klingnau",
            "Klosters Serneus",
            "Kloten",
            "Kloten / Balsberg",
            "Kloten / Freienberg (Chanzler-Chlini Chaseren)",
            "Kloten / Geissberg",
            "Kloten / Holberg",
            "Kloten / Horainli",
            "Kloten / Hostrass",
            "Kloten / Kloten (Zentrum)",
            "Kloten / Rütlen",
            "Kloten / Spitz",
            "Knonau",
            "Knutwil",
            "Koblenz",
            "Kollbrunn",
            "Kollbrunn / Kollbrunn (Dorfkern)",
            "Kölliken",
            "Köniz",
            "Konolfingen",
            "Koppigen",
            "Krauchthal",
            "Kreuzlingen",
            "Kreuzlingen District",
            "Kriegstetten",
            "Kriens",
            "Krummenau",
            "Künten",
            "Küsnacht",
            "Küsnacht / Dorf",
            "Küsnacht / Goldbach",
            "Küsnacht / Heslibach",
            "Küsnacht / Itschnach",
            "Küsnacht / Schiedhalden",
            "Küssnacht",
            "Küttigen",
            "La Chaux-de-Fonds",
            "La Chaux-de-Fonds District",
            "La Neuveville",
            "La Punt Chamues-ch",
            "La Roche",
            "La Sarraz",
            "La Tour-de-Peilz",
            "La Tour-de-Trême",
            "Laax",
            "Lachen",
            "Lake District",
            "Lancy",
            "Landquart",
            "Langenbruck",
            "Langendorf",
            "Langenthal",
            "Langnau",
            "Langnau / Langnau (Dorf)",
            "Langnau / Vitaquartier",
            "Langnau am Albis",
            "Langrickenbach",
            "Läufelfingen",
            "Laufen",
            "Laufenburg",
            "Lauffohr (Brugg)",
            "Laupen",
            "Laupersdorf",
            "Lauperswil",
            "Lausanne",
            "Lausanne District",
            "Lausen",
            "Lauterbrunnen",
            "Lavaux-Oron District",
            "Lavertezzo",
            "Le Chenit",
            "Le Grand-Saconnex",
            "Le Landeron",
            "Le Locle",
            "Le Locle District",
            "Le Mont-sur-Lausanne",
            "Le Noirmont",
            "Le Vaud",
            "Leibstadt",
            "Lengnau",
            "Lenk",
            "Lens",
            "Lenzburg",
            "Lenzerheide",
            "Les Avanchets",
            "Les Bois",
            "Les Brenets",
            "Les Breuleux",
            "Les Geneveys-sur-Coffrane",
            "Les Ponts-de-Martel",
            "Leuk",
            "Leuk District",
            "Leukerbad",
            "Leuzigen",
            "Leventina District",
            "Leysin",
            "Leytron",
            "Lichtensteig",
            "Liesberg",
            "Liestal",
            "Ligornetto",
            "Linden",
            "Linthal",
            "Littau",
            "Locarno",
            "Locarno District",
            "Lodrino",
            "Löhningen",
            "Losone",
            "Lostorf",
            "Lotzwil",
            "Lucens",
            "Lucerne-Land District",
            "Lucerne-Stadt District",
            "Luchsingen",
            "Lugano",
            "Lugano District",
            "Lumino",
            "Lungern",
            "Luterbach",
            "Luthern",
            "Lütisburg",
            "Lutry",
            "Lützelflüh",
            "Luzein",
            "Luzern",
            "Lyss",
            "Lyssach",
            "Madiswil",
            "Magadino",
            "Magden",
            "Maienfeld",
            "Malans",
            "Malleray",
            "Malters",
            "Malvaglia",
            "Männedorf",
            "Männedorf / Ausserfeld",
            "Männedorf / Dorfkern",
            "Marin-Epagnier",
            "Marly",
            "Marsens",
            "Märstetten-Dorf",
            "Marthalen",
            "Martigny District",
            "Martigny-Combe",
            "Martigny-Ville",
            "Massagno",
            "Matten",
            "Mattenbach (Kreis 7)",
            "Mattenbach (Kreis 7) / Deutweg",
            "Mattenbach (Kreis 7) / Endliker",
            "Mattenbach (Kreis 7) / Gutschick",
            "Matzendorf",
            "Matzingen",
            "Mauensee",
            "Mauraz",
            "Meggen",
            "Meierskappel",
            "Meilen",
            "Meinier",
            "Meinisberg",
            "Meiringen",
            "Meisterschwanden",
            "Melano",
            "Melchnau",
            "Melide",
            "Mellingen",
            "Mendrisio",
            "Mendrisio District",
            "Menziken",
            "Menzingen",
            "Menznau",
            "Merenschwand",
            "Mesocco",
            "Messen",
            "Mettmenstetten",
            "Meyrin",
            "Mézières",
            "Minusio",
            "Mogelsberg",
            "Möhlin",
            "Mollens",
            "Mollis",
            "Mönchaltorf",
            "Mönchaltorf / Dorf",
            "Montagnola",
            "Montagny",
            "Montana",
            "Monte Carasso",
            "Monthey",
            "Monthey District",
            "Montreux",
            "Morbio Inferiore",
            "Morges",
            "Morges District",
            "Mörschwil",
            "Mosnang",
            "Moudon",
            "Moutier",
            "Muhen",
            "Mühleberg",
            "Mühlehalde",
            "Mühlethurnen",
            "Müllheim",
            "Mumpf",
            "Münchenbuchsee",
            "Münchenstein",
            "Münchenwiler",
            "Münchwilen",
            "Münchwilen District",
            "Münsingen",
            "Münsterlingen",
            "Müntschemier",
            "Muolen",
            "Muotathal",
            "Muralto",
            "Murgenthal",
            "Muri",
            "Muri",
            "Murten/Morat",
            "Muttenz",
            "Näfels",
            "Nänikon",
            "Nänikon / Nänikon (Dorfkern)",
            "Naters",
            "Nebikon",
            "Neerach",
            "Neftenbach / Dorf Neftenbach",
            "Netstal",
            "Neuchâtel",
            "Neuchâtel District",
            "Neudorf",
            "Neuenhof",
            "Neuenkirch",
            "Neuhausen",
            "Neunkirch",
            "Nidau",
            "Niederbipp",
            "Niederbüren",
            "Niederglatt",
            "Niederglatt / Niederglatt (Dorfkern)",
            "Niedergösgen",
            "Niederhasli",
            "Niederhelfenschwil",
            "Niederlenz",
            "Niederrohrdorf",
            "Niederurnen",
            "Nottwil",
            "Novazzano",
            "Nunningen",
            "Nürensdorf",
            "Nyon",
            "Nyon District",
            "Oberaargau",
            "Oberbipp",
            "Oberbuchsiten",
            "Oberburg",
            "Oberdiessbach",
            "Oberdorf",
            "Oberegg",
            "Oberengstringen",
            "Oberengstringen / Rauchacher",
            "Oberengstringen / Sonnenberg",
            "Oberengstringen / Zentrum",
            "Oberentfelden",
            "Oberglatt",
            "Oberglatt / Bahnhofquartier",
            "Oberglatt / Oberglatt (Dorfkern)",
            "Obergösgen",
            "Oberhelfenschwil",
            "Oberkirch",
            "Oberlunkhofen",
            "Obermeilen",
            "Oberrieden",
            "Oberrieden / Berg",
            "Oberrieden / Mitte",
            "Oberriet",
            "Oberrohrdorf",
            "Oberrüti",
            "Oberschrot",
            "Obersiggenthal",
            "Obersimmental-Saanen District",
            "Oberurnen",
            "Oberuzwil",
            "Oberwil",
            "Oberwinterthur (Kreis 2)",
            "Oberwinterthur (Kreis 2) / Guggenbühl",
            "Oberwinterthur (Kreis 2) / Hegi",
            "Oberwinterthur (Kreis 2) / Talacker",
            "Oberwinterthur (Kreis 2) / Zinzikon",
            "Obfelden",
            "Obfelden / Oberlunnern",
            "Obfelden / Toussen",
            "Oensingen",
            "Oetwil",
            "Oftringen",
            "Ollon",
            "Olten",
            "Onex",
            "Opfikon",
            "Orbe",
            "Ormalingen",
            "Oron-la-Ville",
            "Orpund",
            "Orsières",
            "Orvin",
            "Otelfingen",
            "Othmarsingen",
            "Ottenbach",
            "Ouest Lausannois District",
            "Pailly",
            "Palézieux",
            "Pampigny",
            "Paudex",
            "Payerne",
            "Penthalaz",
            "Penthéréaz",
            "Perroy",
            "Péry",
            "Peseux",
            "Pfaffhausen",
            "Pfäffikon",
            "Pfäffikon",
            "Pfäffikon / Irgenhausen",
            "Pfäffikon / Pfäffikon (Dorfkern)",
            "Pfaffnau",
            "Pfeffingen",
            "Pfungen",
            "Pfyn",
            "Pieterlen",
            "Plaffeien",
            "Plan-les-Ouates",
            "Pompaples",
            "Pontresina",
            "Porrentruy",
            "Porrentruy District",
            "Poschiavo",
            "Prangins",
            "Praroman",
            "Pratteln",
            "Pregassona",
            "Préverenges",
            "Prilly",
            "Puidoux",
            "Pully",
            "Puplinge",
            "Pura",
            "Quarten",
            "Radelfingen",
            "Rafz",
            "Ramsen",
            "Randogne",
            "Rapperswil",
            "Raron",
            "Raron District",
            "Rebstein",
            "Rechthalten",
            "Reconvilier",
            "Regensdorf",
            "Regensdorf / Feldblumen-Riedthofstrasse",
            "Regensdorf / Hofacher-Geeren",
            "Regensdorf / Obstgarten",
            "Region Albula",
            "Region Bernina",
            "Region Engiadina Bassa / Val Müstair",
            "Region Imboden",
            "Region Landquart",
            "Region Maloja",
            "Region Moesa",
            "Region Plessur",
            "Region Prättigau / Davos",
            "Region Surselva",
            "Region Viamala",
            "Rehetobel",
            "Reichenburg",
            "Reiden",
            "Reigoldswil",
            "Reinach",
            "Renens",
            "Rhäzüns",
            "Rheineck",
            "Rheinfelden",
            "Riaz",
            "Richterswil",
            "Richterswil / Burghalde",
            "Richterswil / Dorfkern",
            "Richterswil / Richterswil (Dorfkern)",
            "Rickenbach bei Wil",
            "Riddes",
            "Riedholz",
            "Riehen",
            "Riggisberg",
            "Ringgenberg",
            "Riva San Vitale",
            "Riviera District",
            "Riviera-Pays-d'Enhaut District",
            "Rodersdorf",
            "Roggwil",
            "Roggwil",
            "Rohr",
            "Rohrbach",
            "Rolle",
            "Romanel-sur-Lausanne",
            "Romanshorn",
            "Römerswil",
            "Romont",
            "Root",
            "Rorbas",
            "Rorschach",
            "Röschenz",
            "Rothenthurm",
            "Rothrist",
            "Rotkreuz",
            "Rubigen",
            "Rüderswil",
            "Rudolfstetten",
            "Rue",
            "Rüeggisberg",
            "Rümlang",
            "Rümlang / Rümlang (Dorfkern)",
            "Rupperswil",
            "Rüschegg",
            "Rüschlikon",
            "Russikon",
            "Ruswil",
            "Rüthi",
            "Rüti",
            "Rüti / Dorfzentrum Südl. Teil",
            "Rüti / Oberdorf",
            "Rüti / Westlicher Dorfteil",
            "Saanen",
            "Saas-Fee",
            "Saas-Grund",
            "Sachseln",
            "Safenwil",
            "Saignelégier",
            "Saillon",
            "Saint-Aubin-Sauges",
            "Saint-Blaise",
            "Saint-Cergue",
            "Saint-Imier",
            "Saint-Léonard",
            "Saint-Livres",
            "Saint-Maurice",
            "Saint-Maurice District",
            "Saint-Prex",
            "Sainte-Croix",
            "Sâles",
            "Salgesch",
            "Salmsach",
            "Salvan",
            "Samedan",
            "Samstagern",
            "Sankt Antoni",
            "Sankt Gallen",
            "Sankt Gallenkappel",
            "Sankt Margrethen",
            "Sankt Niklaus",
            "Sankt Peterzell",
            "Sankt Stephan",
            "Sargans",
            "Sarine District",
            "Sarmenstorf",
            "Sarnen",
            "Satigny",
            "Sattel",
            "Saubraz",
            "Savièse",
            "Savigny",
            "Saxon",
            "Schachen",
            "Schaffhausen",
            "Schafisheim",
            "Schänis",
            "Scharans",
            "Schattdorf",
            "Schenkon",
            "Schiers",
            "Schindellegi",
            "Schinznach Bad",
            "Schinznach Dorf",
            "Schleitheim",
            "Schlieren",
            "Schlieren / Boden",
            "Schlieren / Engstingerquartier",
            "Schlieren / Freiestrasse",
            "Schlieren / Kamp",
            "Schlieren / Spital",
            "Schlieren / Zentrum",
            "Schmerikon",
            "Schmitten",
            "Schöftland",
            "Schönenbuch",
            "Schönenwerd",
            "Schötz",
            "Schübelbach",
            "Schüpfen",
            "Schüpfheim",
            "Schwaderloch",
            "Schwanden",
            "Schwarzenberg",
            "Schwellbrunn",
            "Schwerzenbach",
            "Schwerzenbach / Blatten",
            "Schwerzenbach / Chimli",
            "Schwyz",
            "Scuol",
            "Seeberg",
            "Seedorf",
            "Seedorf",
            "Seeland District",
            "Seen (Kreis 3)",
            "Seen (Kreis 3) / Büelwiesen",
            "Seen (Kreis 3) / Ganzenbühl",
            "Seen (Kreis 3) / Oberseen",
            "Seen (Kreis 3) / Waldegg",
            "Seen (Kreis 3) / Waser",
            "Seengen",
            "Seftigen",
            "Seglingen",
            "Sellenbüren",
            "Seltisberg",
            "Selzach",
            "Sementina",
            "Sempach",
            "Sennwald",
            "Sense District",
            "Seon",
            "Seuzach / Seuzach (Dorf)",
            "Seuzach Dorf",
            "Seuzach Dorf / Breite-Weid",
            "Sevelen",
            "Siebnen",
            "Sierre",
            "Sierre District",
            "Signau",
            "Sigriswil",
            "Silenen",
            "Silvaplana",
            "Sins",
            "Sion District",
            "Sirnach",
            "Sissach",
            "Sitten",
            "Solothurn",
            "Sonnhalde",
            "Sorengo",
            "Sottens",
            "Speicher",
            "Spiez",
            "Spreitenbach",
            "St. Moritz",
            "Stabio",
            "Stadt Winterthur (Kreis 1)",
            "Stadt Winterthur (Kreis 1) / Altstadt",
            "Stadt Winterthur (Kreis 1) / Brühlberg",
            "Stadt Winterthur (Kreis 1) / Heiligberg",
            "Stadt Winterthur (Kreis 1) / Lind",
            "Stadt Winterthur (Kreis 1) / Neuwiesen",
            "Stadt Winterthur (Kreis 1) / Tössfeld",
            "Stäfa",
            "Staffelbach",
            "Stalden",
            "Stans",
            "Stansstad",
            "Staufen",
            "Steckborn",
            "Steffisburg",
            "Stein",
            "Stein am Rhein",
            "Steinen",
            "Steinhausen",
            "Steinmaur",
            "Stetten",
            "Stettfurt",
            "Stettlen",
            "Strengelbach",
            "Subingen",
            "Suhr",
            "Sulgen",
            "Sulz",
            "Sulz",
            "Sumiswald",
            "Sursee",
            "Sursee District",
            "Tafers",
            "Tagelswangen",
            "Tägerwilen",
            "Tamins",
            "Tanay",
            "Tann",
            "Tann / Tann (Dorfkern)",
            "Täuffelen",
            "Tavannes",
            "Tegerfelden",
            "Tesserete",
            "Teufen",
            "Teufenthal",
            "Thal",
            "Thalwil",
            "Thalwil / Berg",
            "Thalwil / Dorfkern",
            "Thalwil / Nord",
            "Thalwil / See",
            "Thalwil / Süd",
            "Thayngen",
            "Therwil",
            "Thierachern",
            "Thônex",
            "Thun",
            "Thun District",
            "Thundorf",
            "Thusis",
            "Tiefencastel",
            "Toffen",
            "Töss (Kreis 4)",
            "Töss (Kreis 4) / Eichliacker",
            "Töss (Kreis 4) / Schlosstal",
            "Töss (Kreis 4) / Vorder-Dättnau",
            "Trachselwald",
            "Tramelan",
            "Travers",
            "Trélex",
            "Treyvaux",
            "Triengen",
            "Trimbach",
            "Trimmis",
            "Trogen",
            "Troinex",
            "Troistorrents",
            "Trub",
            "Trubschachen",
            "Trun",
            "Tuggen",
            "Turbenthal",
            "Turgi",
            "Turtmann",
            "Udligenswil",
            "Ueberstorf",
            "Uerikon",
            "Uerkheim",
            "Uetendorf",
            "Uetikon",
            "Uetikon / Grossdorf",
            "Uhwiesen",
            "Uitikon",
            "Unterägeri",
            "Unterengstringen",
            "Unteriberg",
            "Unterkulm",
            "Unterlunkhofen",
            "Unterseen",
            "Untersiggenthal",
            "Untervaz",
            "Urdorf",
            "Urdorf / Bodenfeld",
            "Urdorf / Moos",
            "Urdorf / Oberurdorf",
            "Urnäsch",
            "Urtenen",
            "Uster",
            "Uster / Gschwader",
            "Uster / Kirch-Uster",
            "Uster / Nieder-Uster",
            "Uster / Nossikon",
            "Uster / Ober-Uster",
            "Uttigen",
            "Uttwil",
            "Utzenstorf",
            "Uznach",
            "Uzwil",
            "Val-de-Ruz District",
            "Val-de-Travers District",
            "Vallemaggia District",
            "Vallorbe",
            "Vals Platz",
            "Vandœuvres",
            "Varen",
            "Vechigen",
            "Veltheim",
            "Veltheim (Kreis 5)",
            "Veltheim (Kreis 5) / Blumenau",
            "Veltheim (Kreis 5) / Rosenberg",
            "Verbier",
            "Vernayaz",
            "Vernier",
            "Verscio",
            "Versoix",
            "Vétroz",
            "Vevey",
            "Veveyse District",
            "Vex",
            "Veyrier",
            "Vicques",
            "Viganello",
            "Villars-sur-Glâne",
            "Villars-sur-Ollon",
            "Villaz-Saint-Pierre",
            "Villeneuve",
            "Villigen",
            "Villmergen",
            "Villnachern",
            "Vionnaz",
            "Visp",
            "Visp District",
            "Visperterminen",
            "Vitznau",
            "Volketswil / Volketswil (Dorf)",
            "Vordemwald",
            "Vorderthal",
            "Vouvry",
            "Vuadens",
            "Vuarrens",
            "Wädenswil",
            "Wädenswil / Boller-Giessen",
            "Wädenswil / Büelen",
            "Wädenswil / Dorf (Wädenswil)",
            "Wädenswil / Eichweid",
            "Wädenswil / Hangenmoos",
            "Wädenswil / Leihof-Mühlebach",
            "Wädenswil / Untermosen-Fuhr",
            "Wagenhausen",
            "Wahlen",
            "Wahlkreis Rheintal",
            "Wahlkreis Rorschach",
            "Wahlkreis Sarganserland",
            "Wahlkreis See-Gaster",
            "Wahlkreis St. Gallen",
            "Wahlkreis Toggenburg",
            "Wahlkreis Werdenberg",
            "Wahlkreis Wil",
            "Walchwil",
            "Wald",
            "Waldenburg",
            "Waldkirch",
            "Waldstatt",
            "Walenstadt",
            "Walkringen",
            "Wallisellen",
            "Wallisellen / Rieden",
            "Wallisellen / Wallisellen-Ost",
            "Wallisellen / Wallisellen-West",
            "Waltenschwil",
            "Walzenhausen",
            "Wangen",
            "Wangen",
            "Wangen an der Aare",
            "Wängi",
            "Wattenwil",
            "Wattwil",
            "Wauwil",
            "Weesen",
            "Wegenstetten",
            "Weggis",
            "Weinfelden",
            "Weinfelden District",
            "Weiningen",
            "Weisslingen",
            "Welschenrohr",
            "Werthenstein",
            "Wettingen",
            "Wettswil / Ausser-Dorf",
            "Wettswil / Wettswil (Dorf)",
            "Wetzikon",
            "Wetzikon / Kempten",
            "Wetzikon / Ober-Wetzikon",
            "Wetzikon / Robenhausen",
            "Wetzikon / Unter-Wetzikon",
            "Wichtrach",
            "Wiedlisbach",
            "Wiesendangen / Wiesendangen (Dorf)",
            "Wigoltingen",
            "Wikon",
            "Wil",
            "Wila",
            "Wilchingen",
            "Wilderswil",
            "Wildhaus",
            "Willisau",
            "Willisau District",
            "Wimmis",
            "Windisch",
            "Winterthur",
            "Wittenbach",
            "Wittnau",
            "Wohlen",
            "Wohlen",
            "Wolfenschiessen",
            "Wolfhausen",
            "Wölflinswil",
            "Wolfwil",
            "Wolhusen",
            "Wollerau",
            "Worb",
            "Worben",
            "Wülflingen (Kreis 6)",
            "Wülflingen (Kreis 6) / Härti",
            "Wülflingen (Kreis 6) / Lindenplatz",
            "Wülflingen (Kreis 6) / Niederfeld",
            "Wülflingen (Kreis 6) / Oberfeld",
            "Würenlingen",
            "Würenlos",
            "Wynau",
            "Wynigen",
            "Wyssachen",
            "Yverdon-les-Bains",
            "Yvonand",
            "Zäziwil",
            "Zell",
            "Zermatt",
            "Zernez",
            "Zizers",
            "Zofingen",
            "Zollikerberg",
            "Zollikofen",
            "Zollikon",
            "Zuchwil",
            "Zug",
            "Zumikon",
            "Zunzgen",
            "Zuoz",
            "Zürich",
            "Zürich (Kreis 1)",
            "Zürich (Kreis 1) / City",
            "Zürich (Kreis 1) / Lindenhof",
            "Zürich (Kreis 1) / Rathaus",
            "Zürich (Kreis 10)",
            "Zürich (Kreis 10) / Höngg",
            "Zürich (Kreis 10) / Rütihof",
            "Zürich (Kreis 10) / Wipkingen",
            "Zürich (Kreis 11)",
            "Zürich (Kreis 11) / Affoltern",
            "Zürich (Kreis 11) / Oerlikon",
            "Zürich (Kreis 11) / Schwandenholz",
            "Zürich (Kreis 11) / Seebach",
            "Zürich (Kreis 12)",
            "Zürich (Kreis 12) / Auzelg",
            "Zürich (Kreis 12) / Hirzenbach",
            "Zürich (Kreis 12) / Saatlen",
            "Zürich (Kreis 12) / Schwamendingen-Mitte",
            "Zürich (Kreis 2)",
            "Zürich (Kreis 2) / Enge",
            "Zürich (Kreis 2) / Mittel-Leimbach",
            "Zürich (Kreis 2) / Unter-Leimbach",
            "Zürich (Kreis 2) / Wollishofen",
            "Zürich (Kreis 3)",
            "Zürich (Kreis 3) / Alt-Wiedikon",
            "Zürich (Kreis 3) / Friesenberg",
            "Zürich (Kreis 3) / Sihlfeld",
            "Zürich (Kreis 4) / Aussersihl",
            "Zürich (Kreis 4) / Hard",
            "Zürich (Kreis 4) / Langstrasse",
            "Zürich (Kreis 4) / Werd",
            "Zürich (Kreis 5)",
            "Zürich (Kreis 5) / Escher-Wyss",
            "Zürich (Kreis 5) / Gewerbeschule",
            "Zürich (Kreis 6)",
            "Zürich (Kreis 6) / Oberstrass",
            "Zürich (Kreis 6) / Unterstrass",
            "Zürich (Kreis 7)",
            "Zürich (Kreis 7) / Fluntern",
            "Zürich (Kreis 7) / Hirslanden",
            "Zürich (Kreis 7) / Hottingen",
            "Zürich (Kreis 7) / Witikon",
            "Zürich (Kreis 8)",
            "Zürich (Kreis 8) / Mühlebach",
            "Zürich (Kreis 8) / Seefeld",
            "Zürich (Kreis 8) / Weinegg",
            "Zürich (Kreis 9)",
            "Zürich (Kreis 9) / Albisrieden",
            "Zürich (Kreis 9) / Altstetten",
            "Zuzwil",
            "Zweisimmen",
            "Zwingen"
        ]
    },
    {
        "label": "Syria",
        "code": "SY",
        "phone": "963",
        "cities": [
            "‘Afrīn",
            "‘Ayn al ‘Arab",
            "‘Irbīn",
            "Ad Dānā",
            "Ad Darbāsīyah",
            "Ad Duraykīsh",
            "Afrin District",
            "Al Atārib",
            "Al Bāb",
            "Al Ghanţū",
            "Al Ḩarāk",
            "Al Ḩasakah",
            "Al Kiswah",
            "Al Mālikīyah",
            "Al Mayādīn",
            "Al Muzayrīb",
            "Al Qāmishlī",
            "Al Qaryatayn",
            "Al Qunayţirah",
            "Al Quşayr",
            "Al Quţayfah",
            "Al-Bab District",
            "Al-Haffah District",
            "Al-Malikiyah District",
            "Al-Rastan District",
            "Al-Salamiyah District",
            "Al-Sanamayn District",
            "Al-Thawrah District",
            "Al-Zabadani District",
            "Ālbū Kamāl",
            "Aleppo",
            "Amude",
            "An Nabk",
            "Ar Raqqah",
            "Ar Rastan",
            "Ar-Raqqah District",
            "Arīḩā",
            "Armanāz",
            "As Safīrah",
            "As Salamīyah",
            "Aş Şanamayn",
            "As Suqaylibīyah",
            "As-Suwayda",
            "As-Suwayda District",
            "Ash Shaykh Miskīn",
            "At Tall",
            "Ath Thawrah",
            "Az Zabadānī",
            "Azaz District",
            "Bāniyās",
            "Binnish",
            "Buşrá ash Shām",
            "Damascus",
            "Dar‘ā",
            "Dārayyā",
            "Darkūsh",
            "Dayr al ‘Aşāfīr",
            "Dayr Ḩāfir",
            "Deir ez-Zor",
            "Douma",
            "Ghabāghib",
            "Hajīn",
            "Ḩalfāyā",
            "Hama District",
            "Ḩamāh",
            "Ḩarastā",
            "Harem District",
            "Ḩārim",
            "Hisya",
            "Homs",
            "I‘zāz",
            "Idlib",
            "Inkhil",
            "Izra District",
            "Izra‘",
            "Jablah",
            "Jableh District",
            "Jarābulus",
            "Jaramānā",
            "Jāsim",
            "Jayrūd",
            "Jisr al-Shughur District",
            "Jisr ash Shughūr",
            "Kaff al-Jaa",
            "Kafr Lāhā",
            "Kafr Şaghīr",
            "Kafr Takhārīm",
            "Kafr Zaytā",
            "Kafranbel",
            "Kassab",
            "Khān Shaykhūn",
            "Khanāşir",
            "Latakia",
            "Latakia District",
            "Ma‘arratmişrīn",
            "Ma‘lūlā",
            "Maarrat al-Nu'man District",
            "Manbij",
            "Manbij District",
            "Maşyāf",
            "Masyaf District",
            "Medaya",
            "Mount Simeon District",
            "Mukharram al Fawqānī",
            "Mūrak",
            "Nawá",
            "Nubl",
            "Qārah",
            "Qardaha District",
            "Qaţanā",
            "Şadad",
            "Safita District",
            "Şalākhid",
            "Şalkhad",
            "Salkhad District",
            "Salqīn",
            "Sarāqib",
            "Sarmīn",
            "Satita",
            "Şaydnāyā",
            "Shahbā",
            "Shahba District",
            "Şlinfah",
            "Souran",
            "Subaykhān",
            "Şūrān",
            "Tādif",
            "Tadmur",
            "Tadmur District",
            "Ţafas",
            "Taftanāz",
            "Tall Abyaḑ",
            "Tall Rif‘at",
            "Tall Salḩab",
            "Tallbīsah",
            "Tallkalakh",
            "Tartouss",
            "Tasīl",
            "Ţayyibat al Imām",
            "Tell Abyad District",
            "Tremseh",
            "Yabrūd"
        ]
    },
    {
        "label": "Taiwan",
        "code": "TW",
        "phone": "886",
        "cities": [
            "Banqiao",
            "Changhua",
            "Chiayi",
            "Chiayi County",
            "Daxi",
            "Donggang",
            "Douliu",
            "Hengchun",
            "Hsinchu",
            "Hsinchu County",
            "Hualien",
            "Hualien City",
            "Jincheng",
            "Jiufen",
            "Kaohsiung",
            "Kinmen County",
            "Lienchiang",
            "Lugu",
            "Magong",
            "Miaoli",
            "Nangan",
            "Nantou",
            "Penghu County",
            "Pingtung",
            "Pizitou",
            "Puli",
            "Taichung",
            "Taichung City",
            "Tainan",
            "Taipei",
            "Taipei City",
            "Taitung",
            "Taitung City",
            "Taoyuan",
            "Taoyuan City",
            "Yilan",
            "Yuanlin",
            "Yujing",
            "Yunlin",
            "Zhongxing New Village"
        ]
    },
    {
        "label": "Tajikistan",
        "code": "TJ",
        "phone": "992",
        "cities": [
            "Abdurahmoni Jomí",
            "Adrasmon",
            "Ayní",
            "Boshchorbogh",
            "Bŭston",
            "Bŭstonqal’a",
            "Chkalov",
            "Chubek",
            "Danghara",
            "Darband",
            "Dŭstí",
            "Farkhor",
            "Ghafurov",
            "Gharavŭtí",
            "Hisor",
            "Isfara",
            "Ishqoshim",
            "Istaravshan",
            "Jilikŭl",
            "Karakenja",
            "Khodzha-Maston",
            "Khorugh",
            "Khŭjand",
            "Kim",
            "Kirov",
            "Kolkhozobod",
            "Konibodom",
            "Konsoy",
            "Kŭlob",
            "Moskovskiy",
            "Mŭ’minobod",
            "Murghob",
            "Neftobod",
            "Nohiyai Kolkhozobod",
            "Nohiyai Konibodom",
            "Nohiyai Panj",
            "Nohiyai Shughnon",
            "Nohiyai Vakhsh",
            "Norak",
            "Nov",
            "Novobod",
            "Obigarm",
            "Oltintopkan",
            "Orzu",
            "Pakhtakoron",
            "Palos",
            "Panj",
            "Panjakent",
            "Proletar",
            "Qŭrghonteppa",
            "Quruqsoy",
            "Rasht",
            "Roghun",
            "Shahrinav",
            "Shahritus",
            "Shaydon",
            "Shŭrob",
            "Sovet",
            "Taboshar",
            "Tagob",
            "Tartiki",
            "Tursunzoda",
            "Vahdat",
            "Vahdat District",
            "Vakhsh",
            "Varzob",
            "Varzob District",
            "Vorukh",
            "Vose’",
            "Yovon"
        ]
    },
    {
        "label": "Tanzania",
        "code": "TZ",
        "phone": "255",
        "cities": [
            "Arusha",
            "Babati",
            "Bagamoyo",
            "Bariadi",
            "Bashanet",
            "Basotu",
            "Biharamulo",
            "Bugarama",
            "Bugene",
            "Bukene",
            "Bukoba",
            "Bukonyo",
            "Bungu",
            "Buseresere",
            "Butiama",
            "Chake Chake",
            "Chala",
            "Chalinze",
            "Chanika",
            "Chato",
            "Chimala",
            "Chiungutwa",
            "Dar es Salaam",
            "Dareda",
            "Dodoma",
            "Dongobesh",
            "Endasak",
            "Galappo",
            "Gamba",
            "Geiro",
            "Geita",
            "Hedaru",
            "Hedaru",
            "Ibaba",
            "Ifakara",
            "Igugunu",
            "Igurubi",
            "Ikama",
            "Ikinga",
            "Ikolo",
            "Ikungi",
            "Ikuti",
            "Ikwiriri",
            "Ilembo",
            "Ilembula",
            "Ilemela District",
            "Ilongero",
            "Ilula",
            "Inyonga",
            "Ipinda",
            "Iringa",
            "Isaka",
            "Issenye",
            "Itigi",
            "Izazi",
            "Kabanga",
            "Kabula",
            "Kahama",
            "Kahe Tanzania",
            "Kakonko",
            "Kaliua",
            "Kamachumu",
            "Kandete",
            "Karema",
            "Kasamwa",
            "Kasulu",
            "Katerero",
            "Katesh",
            "Katoro",
            "Katoro",
            "Katumba",
            "Kibaha",
            "Kibakwe",
            "Kibara",
            "Kibaya",
            "Kibiti",
            "Kibondo",
            "Kidatu",
            "Kidodi",
            "Kigoma",
            "Kigonsera",
            "Kihangara",
            "Kihurio",
            "Kijini",
            "Kilimatinde",
            "Kilindoni",
            "Kilosa",
            "Kimamba",
            "Kingori",
            "Kintinku",
            "Kiomboi",
            "Kirando",
            "Kiratu",
            "Kirya",
            "Kisanga",
            "Kisarawe",
            "Kisasa",
            "Kisesa",
            "Kishapu",
            "Kisiwani",
            "Kitama",
            "Kitangari",
            "Kiwengwa",
            "Kiwira",
            "Koani",
            "Koani Ndogo",
            "Konde",
            "Kondoa",
            "Kongwa",
            "Kwakoa",
            "Kyaka",
            "Laela",
            "Lalago",
            "Lembeni",
            "Lindi",
            "Liuli",
            "Longido",
            "Luchingu",
            "Lugoba",
            "Lukuledi",
            "Lulindi",
            "Lupata",
            "Lupiro",
            "Lushoto",
            "Lusungo",
            "Mabama",
            "Mabamba",
            "Machame",
            "Machame",
            "Madimba",
            "Mafinga",
            "Magole",
            "Magomeni",
            "Magomeni",
            "Magugu",
            "Mahanje",
            "Mahenge",
            "Mahonda",
            "Mahuta",
            "Majengo",
            "Makanya",
            "Makumbako",
            "Makungu",
            "Makuyuni",
            "Malampaka",
            "Malangali",
            "Malinyi",
            "Malya",
            "Manda",
            "Maneromango",
            "Maposeni",
            "Maramba",
            "Marangu",
            "Marangu",
            "Masasi",
            "Masuguru",
            "Masukulu",
            "Masumbwe",
            "Maswa",
            "Matai",
            "Matamba",
            "Matiri",
            "Matonga",
            "Matui",
            "Mazinde",
            "Mbamba Bay",
            "Mbekenyera",
            "Mbeya",
            "Mbinga",
            "Mbuguni",
            "Mbulu",
            "Mbumi",
            "Merelani",
            "Meru",
            "Mgandu",
            "Mhango",
            "Micheweni",
            "Mikumi",
            "Mingoyo",
            "Misasi",
            "Misungwi",
            "Mkokotoni",
            "Mkuranga",
            "Mlalo",
            "Mlandizi",
            "Mlangali",
            "Mlimba",
            "Mlowo",
            "Monduli",
            "Morogoro",
            "Moshi",
            "Moshi",
            "Mpanda",
            "Mpuguso",
            "Mpwapwa",
            "Msanga",
            "Msowero",
            "Mtama",
            "Mtambile",
            "Mtimbira",
            "Mtinko",
            "Mto wa Mbu",
            "Mtwango",
            "Mtwara",
            "Mugango",
            "Mugumu",
            "Muheza",
            "Mungaa",
            "Muriti",
            "Musoma",
            "Mvomero",
            "Mvomero District",
            "Mwadui",
            "Mwandiga",
            "Mwanga",
            "Mwansanga",
            "Mwanza",
            "Mwaya",
            "Mwembe",
            "Naberera",
            "Nachingwea",
            "Nakatunguru",
            "Namalenga",
            "Namanga",
            "Namanyere",
            "Namikupa",
            "Nanganga",
            "Nangomba",
            "Nangwa",
            "Nanhyanga",
            "Nansio",
            "Nanyamba",
            "Ndago",
            "Newala Kisimani",
            "Nganane",
            "Ngara",
            "Ngerengere",
            "Ngorongoro",
            "Ngudu",
            "Nguruka",
            "Njombe",
            "Nkoaranga",
            "Nkove",
            "Nshamba",
            "Nsunga",
            "Nungwi",
            "Nyakabindi",
            "Nyakahanga",
            "Nyalikungu",
            "Nyamuswa",
            "Nyangao",
            "Nyanguge",
            "Old Shinyanga",
            "Pangani",
            "Poli",
            "Puma",
            "Ruangwa",
            "Rulenge",
            "Same",
            "Same",
            "Sepuka",
            "Shelui",
            "Shinyanga",
            "Sikonge",
            "Singida",
            "Sirari",
            "Sokoni",
            "Somanda",
            "Songea",
            "Songwa",
            "Soni",
            "Sumbawanga",
            "Tabora",
            "Tandahimba",
            "Tanga",
            "Tinde",
            "Tingi",
            "Tukuyu",
            "Tumbi",
            "Tunduma",
            "Ugweno",
            "Ulanga",
            "Usa River",
            "Usagara",
            "Usevia",
            "Ushirombo",
            "Usoke",
            "Utete",
            "Uvinza",
            "Uwelini",
            "Uyovu",
            "Vikindu",
            "Wete",
            "Zanzibar"
        ]
    },
    {
        "label": "Thailand",
        "code": "TH",
        "phone": "66",
        "cities": [
            "Amnat Charoen",
            "Amphoe Akat Amnuai",
            "Amphoe Amphawa",
            "Amphoe Ao Luek",
            "Amphoe Aranyaprathet",
            "Amphoe At Samat",
            "Amphoe Ba Cho",
            "Amphoe Ban Bueng",
            "Amphoe Ban Chang",
            "Amphoe Ban Dan",
            "Amphoe Ban Dan Lan Hoi",
            "Amphoe Ban Dung",
            "Amphoe Ban Fang",
            "Amphoe Ban Haet",
            "Amphoe Ban Hong",
            "Amphoe Ban Kha",
            "Amphoe Ban Khai",
            "Amphoe Ban Khok",
            "Amphoe Ban Kruat",
            "Amphoe Ban Laem",
            "Amphoe Ban Lat",
            "Amphoe Ban Luang",
            "Amphoe Ban Lueam",
            "Amphoe Ban Mai Chaiyaphot",
            "Amphoe Ban Mi",
            "Amphoe Ban Mo",
            "Amphoe Ban Muang",
            "Amphoe Ban Na",
            "Amphoe Ban Na Doem",
            "Amphoe Ban Na San",
            "Amphoe Ban Phaeng",
            "Amphoe Ban Phaeo",
            "Amphoe Ban Phai",
            "Amphoe Ban Pho",
            "Amphoe Ban Phraek",
            "Amphoe Ban Phue",
            "Amphoe Ban Pong",
            "Amphoe Ban Rai",
            "Amphoe Ban Sang",
            "Amphoe Ban Tak",
            "Amphoe Ban Takhun",
            "Amphoe Ban Thi",
            "Amphoe Bang Ban",
            "Amphoe Bang Bo",
            "Amphoe Bang Bua Thong",
            "Amphoe Bang Kaeo",
            "Amphoe Bang Khan",
            "Amphoe Bang Khla",
            "Amphoe Bang Khonthi",
            "Amphoe Bang Klam",
            "Amphoe Bang Krathum",
            "Amphoe Bang Kruai",
            "Amphoe Bang Len",
            "Amphoe Bang Mun Nak",
            "Amphoe Bang Nam Priao",
            "Amphoe Bang Pa-in",
            "Amphoe Bang Pahan",
            "Amphoe Bang Pakong",
            "Amphoe Bang Phae",
            "Amphoe Bang Phli",
            "Amphoe Bang Pla Ma",
            "Amphoe Bang Rachan",
            "Amphoe Bang Rakam",
            "Amphoe Bang Sai",
            "Amphoe Bang Sao Thong",
            "Amphoe Bang Saphan",
            "Amphoe Bang Saphan Noi",
            "Amphoe Bang Yai",
            "Amphoe Bannang Sata",
            "Amphoe Banphot Phisai",
            "Amphoe Benchalak",
            "Amphoe Betong",
            "Amphoe Bo Kluea",
            "Amphoe Bo Phloi",
            "Amphoe Bo Rai",
            "Amphoe Bo Thong",
            "Amphoe Borabue",
            "Amphoe Bua Chet",
            "Amphoe Bua Lai",
            "Amphoe Bua Yai",
            "Amphoe Bueng Bun",
            "Amphoe Bueng Khong Long",
            "Amphoe Bueng Na Rang",
            "Amphoe Bueng Sam Phan",
            "Amphoe Bueng Samakkhi",
            "Amphoe Bung Khla",
            "Amphoe Buntharik",
            "Amphoe Cha-am",
            "Amphoe Cha-uat",
            "Amphoe Chae Hom",
            "Amphoe Chai Badan",
            "Amphoe Chai Buri",
            "Amphoe Chai Prakan",
            "Amphoe Chai Wan",
            "Amphoe Chaiya",
            "Amphoe Chaiyo",
            "Amphoe Chakkarat",
            "Amphoe Chaloem Phra Kiat",
            "Amphoe Chaloem Phra Kiat",
            "Amphoe Chaloem Phra Kiat",
            "Amphoe Chaloem Phra Kiat",
            "Amphoe Chaloem Phra Kiat",
            "Amphoe Chamni",
            "Amphoe Chana",
            "Amphoe Chanae",
            "Amphoe Chang Klang",
            "Amphoe Changhan",
            "Amphoe Chanuman",
            "Amphoe Charoen Sin",
            "Amphoe Chat Trakan",
            "Amphoe Chaturaphak Phiman",
            "Amphoe Chawang",
            "Amphoe Chian Yai",
            "Amphoe Chiang Dao",
            "Amphoe Chiang Kham",
            "Amphoe Chiang Khan",
            "Amphoe Chiang Khong",
            "Amphoe Chiang Khwan",
            "Amphoe Chiang Klang",
            "Amphoe Chiang Muan",
            "Amphoe Chiang Saen",
            "Amphoe Chiang Yuen",
            "Amphoe Cho-airong",
            "Amphoe Chok Chai",
            "Amphoe Chom Bueng",
            "Amphoe Chom Phra",
            "Amphoe Chom Thong",
            "Amphoe Chon Daen",
            "Amphoe Chonnabot",
            "Amphoe Chuen Chom",
            "Amphoe Chulabhorn",
            "Amphoe Chum Phae",
            "Amphoe Chum Phuang",
            "Amphoe Chum Ta Bong",
            "Amphoe Chumphon Buri",
            "Amphoe Chumsaeng",
            "Amphoe Chun",
            "Amphoe Damnoen Saduak",
            "Amphoe Dan Chang",
            "Amphoe Dan Khun Thot",
            "Amphoe Dan Makham Tia",
            "Amphoe Dan Sai",
            "Amphoe Den Chai",
            "Amphoe Det Udom",
            "Amphoe Doembang Nangbuat",
            "Amphoe Doi Lo",
            "Amphoe Doi Luang",
            "Amphoe Doi Saket",
            "Amphoe Doi Tao",
            "Amphoe Dok Kham Tai",
            "Amphoe Don Chan",
            "Amphoe Don Chedi",
            "Amphoe Don Mot Daeng",
            "Amphoe Don Phut",
            "Amphoe Don Sak",
            "Amphoe Don Tan",
            "Amphoe Don Tum",
            "Amphoe Dong Charoen",
            "Amphoe Dong Luang",
            "Amphoe Erawan",
            "Amphoe Fak Tha",
            "Amphoe Fang",
            "Amphoe Fao Rai",
            "Amphoe Galyani Vadhana",
            "Amphoe Hang Chat",
            "Amphoe Hang Dong",
            "Amphoe Hankha",
            "Amphoe Hat Samran",
            "Amphoe Hat Yai",
            "Amphoe Hot",
            "Amphoe Hua Hin",
            "Amphoe Hua Sai",
            "Amphoe Hua Taphan",
            "Amphoe Huai Khot",
            "Amphoe Huai Krachao",
            "Amphoe Huai Mek",
            "Amphoe Huai Phueng",
            "Amphoe Huai Rat",
            "Amphoe Huai Thalaeng",
            "Amphoe Huai Thap Than",
            "Amphoe Huai Yot",
            "Amphoe In Buri",
            "Amphoe Kabang",
            "Amphoe Kabin Buri",
            "Amphoe Kae Dam",
            "Amphoe Kaeng Hang Maeo",
            "Amphoe Kaeng Khoi",
            "Amphoe Kaeng Krachan",
            "Amphoe Kaeng Sanam Nang",
            "Amphoe Kamalasai",
            "Amphoe Kanchanadit",
            "Amphoe Kantang",
            "Amphoe Kantharalak",
            "Amphoe Kanthararom",
            "Amphoe Kantharawichai",
            "Amphoe Kao Liao",
            "Amphoe Kap Choeng",
            "Amphoe Kapho",
            "Amphoe Kapoe",
            "Amphoe Kapong",
            "Amphoe Kaset Wisai",
            "Amphoe Kathu",
            "Amphoe Khaen Dong",
            "Amphoe Khai Bang Rachan",
            "Amphoe Kham Khuan Kaeo",
            "Amphoe Kham Muang",
            "Amphoe Kham Sakae Saeng",
            "Amphoe Kham Ta Kla",
            "Amphoe Kham Thale So",
            "Amphoe Khamcha-i",
            "Amphoe Khanom",
            "Amphoe Khanu Woralaksaburi",
            "Amphoe Khao Chaison",
            "Amphoe Khao Chakan",
            "Amphoe Khao Chamao",
            "Amphoe Khao Khitchakut",
            "Amphoe Khao Kho",
            "Amphoe Khao Phanom",
            "Amphoe Khao Saming",
            "Amphoe Khao Suan Kwang",
            "Amphoe Khao Wong",
            "Amphoe Khao Yoi",
            "Amphoe Khemmarat",
            "Amphoe Khian Sa",
            "Amphoe Khiri Mat",
            "Amphoe Khiri Rat Nikhom",
            "Amphoe Khlong Hat",
            "Amphoe Khlong Hoi Khong",
            "Amphoe Khlong Khlung",
            "Amphoe Khlong Khuean",
            "Amphoe Khlong Lan",
            "Amphoe Khlong Luang",
            "Amphoe Khlong Thom",
            "Amphoe Khlong Yai",
            "Amphoe Khlung",
            "Amphoe Kho Wang",
            "Amphoe Khok Charoen",
            "Amphoe Khok Pho",
            "Amphoe Khok Pho Chai",
            "Amphoe Khok Samrong",
            "Amphoe Khok Si Suphan",
            "Amphoe Khok Sung",
            "Amphoe Khon Buri",
            "Amphoe Khong",
            "Amphoe Khong Chai",
            "Amphoe Khong Chiam",
            "Amphoe Khu Mueang",
            "Amphoe Khuan Don",
            "Amphoe Khuan Kalong",
            "Amphoe Khuan Khanun",
            "Amphoe Khuan Niang",
            "Amphoe Khueang Nai",
            "Amphoe Khukhan",
            "Amphoe Khun Han",
            "Amphoe Khun Tan",
            "Amphoe Khun Yuam",
            "Amphoe Khura Buri",
            "Amphoe Khwao Sinarin",
            "Amphoe Klaeng",
            "Amphoe Ko Chan",
            "Amphoe Ko Chang",
            "Amphoe Ko Kha",
            "Amphoe Ko Kut",
            "Amphoe Ko Lanta",
            "Amphoe Ko Pha-ngan",
            "Amphoe Ko Samui",
            "Amphoe Ko Si Chang",
            "Amphoe Ko Yao",
            "Amphoe Kong Krailat",
            "Amphoe Kong Ra",
            "Amphoe Kosamphi Nakhon",
            "Amphoe Kosum Phisai",
            "Amphoe Kra Buri",
            "Amphoe Kranuan",
            "Amphoe Krasae Sin",
            "Amphoe Krasang",
            "Amphoe Krathum Baen",
            "Amphoe Krok Phra",
            "Amphoe Krong Pinang",
            "Amphoe Ku Kaeo",
            "Amphoe Kuchinarai",
            "Amphoe Kui Buri",
            "Amphoe Kumphawapi",
            "Amphoe Kusuman",
            "Amphoe Kut Bak",
            "Amphoe Kut Chap",
            "Amphoe Kut Chum",
            "Amphoe Kut Khaopun",
            "Amphoe Kut Rang",
            "Amphoe La-Ngu",
            "Amphoe La-un",
            "Amphoe Laem Ngop",
            "Amphoe Laem Sing",
            "Amphoe Lahan Sai",
            "Amphoe Lam Luk Ka",
            "Amphoe Lam Plai Mat",
            "Amphoe Lam Sonthi",
            "Amphoe Lam Thamen Chai",
            "Amphoe Lam Thap",
            "Amphoe Lamae",
            "Amphoe Lamduan",
            "Amphoe Lan Krabue",
            "Amphoe Lan Sak",
            "Amphoe Lan Saka",
            "Amphoe Lang Suan",
            "Amphoe Lao Khwan",
            "Amphoe Lao Suea Kok",
            "Amphoe Lap Lae",
            "Amphoe Lat Bua Luang",
            "Amphoe Lat Lum Kaeo",
            "Amphoe Lat Yao",
            "Amphoe Li",
            "Amphoe Loeng Nok Tha",
            "Amphoe Lom Kao",
            "Amphoe Lom Sak",
            "Amphoe Long",
            "Amphoe Lue Amnat",
            "Amphoe Mae Ai",
            "Amphoe Mae Chaem",
            "Amphoe Mae Chai",
            "Amphoe Mae Chan",
            "Amphoe Mae Charim",
            "Amphoe Mae Fa Luang",
            "Amphoe Mae La Noi",
            "Amphoe Mae Lan",
            "Amphoe Mae Lao",
            "Amphoe Mae Mo",
            "Amphoe Mae On",
            "Amphoe Mae Phrik",
            "Amphoe Mae Poen",
            "Amphoe Mae Ramat",
            "Amphoe Mae Rim",
            "Amphoe Mae Sai",
            "Amphoe Mae Sariang",
            "Amphoe Mae Sot",
            "Amphoe Mae Suai",
            "Amphoe Mae Taeng",
            "Amphoe Mae Tha",
            "Amphoe Mae Tha",
            "Amphoe Mae Wang",
            "Amphoe Mae Wong",
            "Amphoe Maha Chana Chai",
            "Amphoe Maha Rat",
            "Amphoe Mai Kaen",
            "Amphoe Makham",
            "Amphoe Manang",
            "Amphoe Mancha Khiri",
            "Amphoe Manorom",
            "Amphoe Mayo",
            "Amphoe Moeiwadi",
            "Amphoe Muak Lek",
            "Amphoe Muang Sam Sip",
            "Amphoe Mueang Amnat Charoen",
            "Amphoe Mueang Ang Thong",
            "Amphoe Mueang Bueng Kan",
            "Amphoe Mueang Buriram",
            "Amphoe Mueang Chachoengsao",
            "Amphoe Mueang Chainat",
            "Amphoe Mueang Chan",
            "Amphoe Mueang Chanthaburi",
            "Amphoe Mueang Chiang Mai",
            "Amphoe Mueang Chiang Rai",
            "Amphoe Mueang Chumphon",
            "Amphoe Mueang Kalasin",
            "Amphoe Mueang Kamphaeng Phet",
            "Amphoe Mueang Kanchanaburi",
            "Amphoe Mueang Khon Kaen",
            "Amphoe Mueang Krabi",
            "Amphoe Mueang Lampang",
            "Amphoe Mueang Lamphun",
            "Amphoe Mueang Loei",
            "Amphoe Mueang Mae Hong Son",
            "Amphoe Mueang Maha Sarakham",
            "Amphoe Mueang Mukdahan",
            "Amphoe Mueang Nakhon Nayok",
            "Amphoe Mueang Nakhon Pathom",
            "Amphoe Mueang Nakhon Phanom",
            "Amphoe Mueang Nakhon Ratchasima",
            "Amphoe Mueang Nakhon Sawan",
            "Amphoe Mueang Nakhon Si Thammarat",
            "Amphoe Mueang Nan",
            "Amphoe Mueang Narathiwat",
            "Amphoe Mueang Nong Bua Lamphu",
            "Amphoe Mueang Nong Khai",
            "Amphoe Mueang Nonthaburi",
            "Amphoe Mueang Pan",
            "Amphoe Mueang Pathum Thani",
            "Amphoe Mueang Pattani",
            "Amphoe Mueang Phangnga",
            "Amphoe Mueang Phatthalung",
            "Amphoe Mueang Phayao",
            "Amphoe Mueang Phetchabun",
            "Amphoe Mueang Phetchaburi",
            "Amphoe Mueang Phichit",
            "Amphoe Mueang Phitsanulok",
            "Amphoe Mueang Phrae",
            "Amphoe Mueang Phuket",
            "Amphoe Mueang Prachin Buri",
            "Amphoe Mueang Prachuap Khiri Khan",
            "Amphoe Mueang Ranong",
            "Amphoe Mueang Ratchaburi",
            "Amphoe Mueang Rayong",
            "Amphoe Mueang Roi Et",
            "Amphoe Mueang Sa Kaeo",
            "Amphoe Mueang Sakon Nakhon",
            "Amphoe Mueang Samut Prakan",
            "Amphoe Mueang Samut Sakhon",
            "Amphoe Mueang Samut Songkhram",
            "Amphoe Mueang Sara Buri",
            "Amphoe Mueang Satun",
            "Amphoe Mueang Sing Buri",
            "Amphoe Mueang Sisaket",
            "Amphoe Mueang Songkhla",
            "Amphoe Mueang Suang",
            "Amphoe Mueang Sukhothai",
            "Amphoe Mueang Suphan Buri",
            "Amphoe Mueang Surat Thani",
            "Amphoe Mueang Surin",
            "Amphoe Mueang Tak",
            "Amphoe Mueang Trang",
            "Amphoe Mueang Trat",
            "Amphoe Mueang Ubon Ratchathani",
            "Amphoe Mueang Udon Thani",
            "Amphoe Mueang Uthai Thani",
            "Amphoe Mueang Uttaradit",
            "Amphoe Mueang Yala",
            "Amphoe Mueang Yang",
            "Amphoe Mueang Yasothon",
            "Amphoe Na Bon",
            "Amphoe Na Chaluai",
            "Amphoe Na Chueak",
            "Amphoe Na Di",
            "Amphoe Na Duang",
            "Amphoe Na Dun",
            "Amphoe Na Haeo",
            "Amphoe Na Kae",
            "Amphoe Na Khu",
            "Amphoe Na Klang",
            "Amphoe Na Mom",
            "Amphoe Na Mon",
            "Amphoe Na Muen",
            "Amphoe Na Noi",
            "Amphoe Na Pho",
            "Amphoe Na Tan",
            "Amphoe Na Thawi",
            "Amphoe Na Thom",
            "Amphoe Na Wa",
            "Amphoe Na Wang",
            "Amphoe Na Yai Am",
            "Amphoe Na Yia",
            "Amphoe Na Yong",
            "Amphoe Na Yung",
            "Amphoe Nakhon Chai Si",
            "Amphoe Nakhon Luang",
            "Amphoe Nakhon Thai",
            "Amphoe Nam Khun",
            "Amphoe Nam Kliang",
            "Amphoe Nam Nao",
            "Amphoe Nam Pat",
            "Amphoe Nam Phong",
            "Amphoe Nam Som",
            "Amphoe Nam Yuen",
            "Amphoe Nang Rong",
            "Amphoe Ngao",
            "Amphoe Nikhom Kham Soi",
            "Amphoe Nikhom Nam Un",
            "Amphoe Nikhom Phattana",
            "Amphoe Noen Kham",
            "Amphoe Noen Maprang",
            "Amphoe Non Daeng",
            "Amphoe Non Din Daeng",
            "Amphoe Non Khun",
            "Amphoe Non Narai",
            "Amphoe Non Sa-at",
            "Amphoe Non Sang",
            "Amphoe Non Sila",
            "Amphoe Non Sung",
            "Amphoe Non Suwan",
            "Amphoe Non Thai",
            "Amphoe Nong Bua",
            "Amphoe Nong Bun Mak",
            "Amphoe Nong Chang",
            "Amphoe Nong Chik",
            "Amphoe Nong Don",
            "Amphoe Nong Han",
            "Amphoe Nong Hi",
            "Amphoe Nong Hin",
            "Amphoe Nong Hong",
            "Amphoe Nong Khae",
            "Amphoe Nong Khayang",
            "Amphoe Nong Ki",
            "Amphoe Nong Kung Si",
            "Amphoe Nong Mamong",
            "Amphoe Nong Muang",
            "Amphoe Nong Muang Khai",
            "Amphoe Nong Na Kham",
            "Amphoe Nong Phai",
            "Amphoe Nong Phok",
            "Amphoe Nong Prue",
            "Amphoe Nong Ruea",
            "Amphoe Nong Saeng",
            "Amphoe Nong Saeng",
            "Amphoe Nong Song Hong",
            "Amphoe Nong Suea",
            "Amphoe Nong Sung",
            "Amphoe Nong Wua So",
            "Amphoe Nong Ya Plong",
            "Amphoe Nong Yai",
            "Amphoe Nong Yasai",
            "Amphoe Nopphitam",
            "Amphoe Nuea Khlong",
            "Amphoe Om Koi",
            "Amphoe Ongkharak",
            "Amphoe Pa Bon",
            "Amphoe Pa Daet",
            "Amphoe Pa Kham",
            "Amphoe Pa Mok",
            "Amphoe Pa Phayom",
            "Amphoe Pa Sang",
            "Amphoe Pa Tio",
            "Amphoe Pai",
            "Amphoe Pak Chom",
            "Amphoe Pak Chong",
            "Amphoe Pak Khat",
            "Amphoe Pak Kret",
            "Amphoe Pak Phanang",
            "Amphoe Pak Phayun",
            "Amphoe Pak Phli",
            "Amphoe Pak Tho",
            "Amphoe Pak Thong Chai",
            "Amphoe Palian",
            "Amphoe Panare",
            "Amphoe Pang Mapha",
            "Amphoe Pang Sila Thong",
            "Amphoe Pathio",
            "Amphoe Pathum Rat",
            "Amphoe Pathum Ratchawongsa",
            "Amphoe Pha Khao",
            "Amphoe Phachi",
            "Amphoe Phai Sali",
            "Amphoe Phak Hai",
            "Amphoe Phan",
            "Amphoe Phan Thong",
            "Amphoe Phana",
            "Amphoe Phanat Nikhom",
            "Amphoe Phang Khon",
            "Amphoe Phanna Nikhom",
            "Amphoe Phanom",
            "Amphoe Phanom Dong Rak",
            "Amphoe Phanom Phrai",
            "Amphoe Phanom Sarakham",
            "Amphoe Phanom Thuan",
            "Amphoe Phato",
            "Amphoe Phatthana Nikhom",
            "Amphoe Phaya Mengrai",
            "Amphoe Phayakkhaphum Phisai",
            "Amphoe Phayu",
            "Amphoe Phayuha Khiri",
            "Amphoe Phen",
            "Amphoe Phibun Mangsahan",
            "Amphoe Phibun Rak",
            "Amphoe Phichai",
            "Amphoe Phimai",
            "Amphoe Phipun",
            "Amphoe Phlapphla Chai",
            "Amphoe Pho Chai",
            "Amphoe Pho Prathap Chang",
            "Amphoe Pho Sai",
            "Amphoe Pho Si Suwan",
            "Amphoe Pho Tak",
            "Amphoe Pho Thale",
            "Amphoe Pho Thong",
            "Amphoe Phon",
            "Amphoe Phon Charoen",
            "Amphoe Phon Na Kaeo",
            "Amphoe Phon Sai",
            "Amphoe Phon Sawan",
            "Amphoe Phon Thong",
            "Amphoe Phonphisai",
            "Amphoe Phop Phra",
            "Amphoe Photharam",
            "Amphoe Phra Nakhon Si Ayutthaya",
            "Amphoe Phra Phrom",
            "Amphoe Phra Phutthabat",
            "Amphoe Phra Pradaeng",
            "Amphoe Phra Samut Chedi",
            "Amphoe Phra Thong Kham",
            "Amphoe Phra Yuen",
            "Amphoe Phrai Bueng",
            "Amphoe Phran Kratai",
            "Amphoe Phrao",
            "Amphoe Phrasaeng",
            "Amphoe Phrom Buri",
            "Amphoe Phrom Khiri",
            "Amphoe Phrom Phiram",
            "Amphoe Phu Kam Yao",
            "Amphoe Phu Kradueng",
            "Amphoe Phu Luang",
            "Amphoe Phu Pha Man",
            "Amphoe Phu Phan",
            "Amphoe Phu Phiang",
            "Amphoe Phu Ruea",
            "Amphoe Phu Sang",
            "Amphoe Phu Sing",
            "Amphoe Phu Wiang",
            "Amphoe Phunphin",
            "Amphoe Phutthaisong",
            "Amphoe Phutthamonthon",
            "Amphoe Pla Pak",
            "Amphoe Plaeng Yao",
            "Amphoe Plai Phraya",
            "Amphoe Pluak Daeng",
            "Amphoe Pong",
            "Amphoe Pong Nam Ron",
            "Amphoe Prachak Sinlapakhom",
            "Amphoe Prachantakham",
            "Amphoe Prakhon Chai",
            "Amphoe Pran Buri",
            "Amphoe Prang Ku",
            "Amphoe Prasat",
            "Amphoe Prathai",
            "Amphoe Pua",
            "Amphoe Pueai Noi",
            "Amphoe Ra-ngae",
            "Amphoe Raman",
            "Amphoe Ranot",
            "Amphoe Rasi Salai",
            "Amphoe Ratchasan",
            "Amphoe Ratsada",
            "Amphoe Rattanaburi",
            "Amphoe Rattanawapi",
            "Amphoe Rattaphum",
            "Amphoe Renu Nakhon",
            "Amphoe Ron Phibun",
            "Amphoe Rong Kham",
            "Amphoe Rong Kwang",
            "Amphoe Rueso",
            "Amphoe Sa Bot",
            "Amphoe Sa Khrai",
            "Amphoe Saba Yoi",
            "Amphoe Sadao",
            "Amphoe Sahatsakhan",
            "Amphoe Sai Buri",
            "Amphoe Sai Mun",
            "Amphoe Sai Ngam",
            "Amphoe Sai Noi",
            "Amphoe Sai Thong Watthana",
            "Amphoe Sai Yok",
            "Amphoe Sak Lek",
            "Amphoe Sam Chai",
            "Amphoe Sam Phran",
            "Amphoe Sam Roi Yot",
            "Amphoe Sam Sung",
            "Amphoe Samoeng",
            "Amphoe Samrong",
            "Amphoe Samrong Thap",
            "Amphoe San Kamphaeng",
            "Amphoe San Pa Tong",
            "Amphoe San Sai",
            "Amphoe Sanam Chai Khet",
            "Amphoe Sang Khom",
            "Amphoe Sangkha",
            "Amphoe Sangkhla Buri",
            "Amphoe Sangkhom",
            "Amphoe Sankhaburi",
            "Amphoe Sanom",
            "Amphoe Santi Suk",
            "Amphoe Sao Hai",
            "Amphoe Sapphaya",
            "Amphoe Saraphi",
            "Amphoe Sathing Phra",
            "Amphoe Sattahip",
            "Amphoe Satuek",
            "Amphoe Sawaeng Ha",
            "Amphoe Sawang Arom",
            "Amphoe Sawang Daen Din",
            "Amphoe Sawang Wirawong",
            "Amphoe Sawankhalok",
            "Amphoe Sawi",
            "Amphoe Seka",
            "Amphoe Selaphum",
            "Amphoe Sena",
            "Amphoe Senangkhanikhom",
            "Amphoe Si Banphot",
            "Amphoe Si Bun Rueang",
            "Amphoe Si Chiang Mai",
            "Amphoe Si Chom Phu",
            "Amphoe Si Khoraphum",
            "Amphoe Si Maha Phot",
            "Amphoe Si Mahosot",
            "Amphoe Si Mueang Mai",
            "Amphoe Si Nakhon",
            "Amphoe Si Narong",
            "Amphoe Si Prachan",
            "Amphoe Si Racha",
            "Amphoe Si Rattana",
            "Amphoe Si Sakhon",
            "Amphoe Si Samrong",
            "Amphoe Si Satchanalai",
            "Amphoe Si Sawat",
            "Amphoe Si Somdet",
            "Amphoe Si Songkhram",
            "Amphoe Si That",
            "Amphoe Si Thep",
            "Amphoe Si Wilai",
            "Amphoe Sichon",
            "Amphoe Sida",
            "Amphoe Sikao",
            "Amphoe Sikhio",
            "Amphoe Sikhiu",
            "Amphoe Sila Lat",
            "Amphoe Singhanakhon",
            "Amphoe Sirindhorn",
            "Amphoe So Phisai",
            "Amphoe Soem Ngam",
            "Amphoe Soeng Sang",
            "Amphoe Soi Dao",
            "Amphoe Somdet",
            "Amphoe Song",
            "Amphoe Song Dao",
            "Amphoe Song Khwae",
            "Amphoe Song Phi Nong",
            "Amphoe Sop Moei",
            "Amphoe Sop Prap",
            "Amphoe Srinagarindra",
            "Amphoe Su-ngai Kolok",
            "Amphoe Su-ngai Padi",
            "Amphoe Suan Phueng",
            "Amphoe Suk Samran",
            "Amphoe Sukhirin",
            "Amphoe Sung Men",
            "Amphoe Sung Noen",
            "Amphoe Suwannakhuha",
            "Amphoe Suwannaphum",
            "Amphoe Ta Phraya",
            "Amphoe Tak Bai",
            "Amphoe Tak Fa",
            "Amphoe Takhli",
            "Amphoe Takua Pa",
            "Amphoe Takua Thung",
            "Amphoe Tamot",
            "Amphoe Tan Sum",
            "Amphoe Tao Ngoi",
            "Amphoe Taphan Hin",
            "Amphoe Tha Bo",
            "Amphoe Tha Chana",
            "Amphoe Tha Chang",
            "Amphoe Tha Chang",
            "Amphoe Tha Khantho",
            "Amphoe Tha Li",
            "Amphoe Tha Luang",
            "Amphoe Tha Mai",
            "Amphoe Tha Maka",
            "Amphoe Tha Muang",
            "Amphoe Tha Phae",
            "Amphoe Tha Pla",
            "Amphoe Tha Ruea",
            "Amphoe Tha Sae",
            "Amphoe Tha Sala",
            "Amphoe Tha Song Yang",
            "Amphoe Tha Takiap",
            "Amphoe Tha Tako",
            "Amphoe Tha Tum",
            "Amphoe Tha Uthen",
            "Amphoe Tha Wang Pha",
            "Amphoe Tha Wung",
            "Amphoe Tha Yang",
            "Amphoe Thai Charoen",
            "Amphoe Thai Mueang",
            "Amphoe Thalang",
            "Amphoe Tham Phannara",
            "Amphoe Than To",
            "Amphoe Thanyaburi",
            "Amphoe Thap Khlo",
            "Amphoe Thap Put",
            "Amphoe Thap Sakae",
            "Amphoe Thap Than",
            "Amphoe That Phanom",
            "Amphoe Thawatchaburi",
            "Amphoe Thepha",
            "Amphoe Thepharak",
            "Amphoe Thoen",
            "Amphoe Thoeng",
            "Amphoe Thong Pha Phum",
            "Amphoe Thong Saen Khan",
            "Amphoe Thung Chang",
            "Amphoe Thung Fon",
            "Amphoe Thung Hua Chang",
            "Amphoe Thung Khao Luang",
            "Amphoe Thung Saliam",
            "Amphoe Thung Si Udom",
            "Amphoe Thung Song",
            "Amphoe Thung Tako",
            "Amphoe Thung Wa",
            "Amphoe Thung Yai",
            "Amphoe Thung Yang Daeng",
            "Amphoe Trakan Phuet Phon",
            "Amphoe Tron",
            "Amphoe U Thong",
            "Amphoe Ubolratana",
            "Amphoe Umphang",
            "Amphoe Uthai",
            "Amphoe Uthumphon Phisai",
            "Amphoe Wachira Barami",
            "Amphoe Waeng",
            "Amphoe Waeng Noi",
            "Amphoe Waeng Yai",
            "Amphoe Wan Yai",
            "Amphoe Wang Chan",
            "Amphoe Wang Chao",
            "Amphoe Wang Chin",
            "Amphoe Wang Hin",
            "Amphoe Wang Muang",
            "Amphoe Wang Nam Khiao",
            "Amphoe Wang Nam Yen",
            "Amphoe Wang Noi",
            "Amphoe Wang Nuea",
            "Amphoe Wang Pong",
            "Amphoe Wang Sai Phun",
            "Amphoe Wang Sam Mo",
            "Amphoe Wang Saphung",
            "Amphoe Wang Sombun",
            "Amphoe Wang Thong",
            "Amphoe Wang Wiset",
            "Amphoe Wangyang",
            "Amphoe Wanon Niwat",
            "Amphoe Wapi Pathum",
            "Amphoe Warin Chamrap",
            "Amphoe Waritchaphum",
            "Amphoe Wat Bot",
            "Amphoe Wat Phleng",
            "Amphoe Wat Sing",
            "Amphoe Watthana Nakhon",
            "Amphoe Wiang Chai",
            "Amphoe Wiang Chiang Rung",
            "Amphoe Wiang Haeng",
            "Amphoe Wiang Kaen",
            "Amphoe Wiang Kao",
            "Amphoe Wiang Nong Long",
            "Amphoe Wiang Pa Pao",
            "Amphoe Wiang Sa",
            "Amphoe Wiang Sa",
            "Amphoe Wichian Buri",
            "Amphoe Wihan Daeng",
            "Amphoe Wiphawadi",
            "Amphoe Wiset Chai Chan",
            "Amphoe Yaha",
            "Amphoe Yan Ta Khao",
            "Amphoe Yang Chum Noi",
            "Amphoe Yang Si Surat",
            "Amphoe Yang Talat",
            "Amphoe Yarang",
            "Amphoe Yaring",
            "Amphoe Yi-ngo",
            "Ang Thong",
            "Ao Luek",
            "Aranyaprathet",
            "Bamnet Narong",
            "Ban Ao Nang",
            "Ban Bang Kadi Pathum Thani",
            "Ban Bueng",
            "Ban Chalong",
            "Ban Chang",
            "Ban Dan Lan Hoi",
            "Ban Dung",
            "Ban Huai Thalaeng",
            "Ban Karon",
            "Ban Kata",
            "Ban Khao Lak",
            "Ban Khlong Bang Sao Thong",
            "Ban Ko Kaeo",
            "Ban Ko Lan",
            "Ban Lam Luk Ka",
            "Ban Mai",
            "Ban Mai Khao",
            "Ban Mo",
            "Ban Na",
            "Ban Na San",
            "Ban Nam Yuen",
            "Ban Nong Wua So",
            "Ban Phaeo",
            "Ban Phai",
            "Ban Phan Don",
            "Ban Phe",
            "Ban Phru Nai",
            "Ban Pong",
            "Ban Ratsada",
            "Ban Sam Chuk",
            "Ban Selaphum",
            "Ban Su-ngai Pa Di",
            "Ban Tak",
            "Ban Talat Bueng",
            "Ban Talat Nua",
            "Ban Talat Yai",
            "Ban Thai Tan",
            "Bang Ban",
            "Bang Bo District",
            "Bang Bon",
            "Bang Bua Thong",
            "Bang Kapi",
            "Bang Khae",
            "Bang Khen",
            "Bang Khla",
            "Bang Kho laen",
            "Bang Khun thain",
            "Bang Krathum",
            "Bang Kruai",
            "Bang Lamung",
            "Bang Lamung District",
            "Bang Len",
            "Bang Mun Nak",
            "Bang Na",
            "Bang Nam Priao",
            "Bang Pa-in",
            "Bang Pakong",
            "Bang Phae",
            "Bang Phlat",
            "Bang Racham",
            "Bang Rak",
            "Bang Rakam",
            "Bang Saphan",
            "Bang Sue",
            "Bang Yai",
            "Bangkok",
            "Bangkok Noi",
            "Bangkok Yai",
            "Betong",
            "Bo Phloi",
            "Bo Rai",
            "Bua Yai",
            "Bueng Kan",
            "Bueng Kum",
            "Bueng Na Rang",
            "Buri Ram",
            "Cha-am",
            "Chachoengsao",
            "Chai Badan",
            "Chai Buri",
            "Chai Nat",
            "Chai Prakan",
            "Chaiya",
            "Chaiyaphum",
            "Chaiyo",
            "Chanthaburi",
            "Chat Trakan",
            "Chatu Chak",
            "Chawang",
            "Chiang Kham",
            "Chiang Khong",
            "Chiang Klang",
            "Chiang Mai",
            "Chiang Rai",
            "Chiang Saen",
            "Chok Chai",
            "Chom Bueng",
            "Chom Thong",
            "Chom Thong",
            "Chon Buri",
            "Chon Daen",
            "Chum Phae",
            "Chumphon",
            "Damnoen Saduak",
            "Dan Khun Thot",
            "Den Chai",
            "Det Udom",
            "Din Daeng",
            "Doembang Nangbuat",
            "Dok Kham Tai",
            "Don Mueang",
            "Don Sak",
            "Dusit",
            "Fang",
            "Hang Dong",
            "Hat Yai",
            "Hua Hin",
            "Huai Khot",
            "Huai Khwang",
            "Huai Mek",
            "Huai Yot",
            "In Buri",
            "Kabin Buri",
            "Kaeng Khoi",
            "Kaeng Khro",
            "Kalasin",
            "Kamalasai",
            "Kamphaeng Phet",
            "Kamphaeng Saen District",
            "Kanchanaburi",
            "Kanchanadit",
            "Kantang",
            "Kantharalak",
            "Kao Liao",
            "Kaset Sombun",
            "Kaset Wisai",
            "Kathu",
            "Kham Sakae Saeng",
            "Khan Na Yao",
            "Khanu Woralaksaburi",
            "Khao Wong",
            "Khao Yoi",
            "Khiri Mat",
            "Khlong Khuean",
            "Khlong Luang",
            "Khlong Sam Wa",
            "Khlong San",
            "Khlong Toei",
            "Khlong Yai",
            "Khlung",
            "Khon Buri",
            "Khon Kaen",
            "Klaeng",
            "Ko Chang Tai",
            "Ko Lanta",
            "Ko Pha Ngan",
            "Ko Samui",
            "Ko Si Chang",
            "Koh Tao",
            "Kosum Phisai",
            "Krabi",
            "Kranuan",
            "Krathum Baen",
            "Kuchinarai",
            "Kui Buri",
            "Kumphawapi",
            "Kut Chap",
            "Laem Sing",
            "Lak Si",
            "Lampang",
            "Lamphun",
            "Lan Krabue",
            "Lan Sak",
            "Lang Suan",
            "Lat Krabang",
            "Lat Phrao",
            "Lat Yao",
            "Loei",
            "Lom Sak",
            "Long",
            "Lop Buri",
            "Mae Chai",
            "Mae Chan",
            "Mae Hong Son",
            "Mae Ramat",
            "Mae Sai",
            "Mae Sot",
            "Mae Tha",
            "Maha Sarakham",
            "Min Buri",
            "Moeiwadi",
            "Muang Lop Buri District",
            "Mueang Chonburi District",
            "Mueang Nonthaburi",
            "Mukdahan",
            "Na Klang",
            "Na Mom",
            "Na Wa",
            "Nai Harn",
            "Nakhon Luang",
            "Nakhon Nayok",
            "Nakhon Nayok",
            "Nakhon Pathom",
            "Nakhon Phanom",
            "Nakhon Ratchasima",
            "Nakhon Sawan",
            "Nakhon Si Thammarat",
            "Nakhon Thai",
            "Nam Som",
            "Nan",
            "Nang Rong",
            "Narathiwat",
            "Non Sang",
            "Non Sung",
            "Non Thai",
            "Nong Bua",
            "Nong Bua Lamphu",
            "Nong Chik",
            "Nong Chok",
            "Nong Khae",
            "Nong Khaem",
            "Nong Khai",
            "Nong Ki",
            "Nong Kung Si",
            "Nong Phai",
            "Nong Wua So",
            "Nuea Khlong",
            "Pa Daet",
            "Pa Sang",
            "Pai",
            "Pak Chong",
            "Pak Kret",
            "Pak Phanang",
            "Pak Thong Chai",
            "Parthum Wan",
            "Pathum Thani",
            "Patong",
            "Pattani",
            "Pattaya",
            "Phai Sali",
            "Phak Hai",
            "Phan Thong",
            "Phanat Nikhom",
            "Phang Nga",
            "Phanom Sarakham",
            "Phanom Thuan",
            "Phasi Charoen",
            "Phatthalung",
            "Phaya Thai",
            "Phayakkhaphum Phisai",
            "Phayao",
            "Phayuha Khiri",
            "Phetchabun",
            "Phetchaburi",
            "Phibun Mangsahan",
            "Phichit",
            "Phimai",
            "Phitsanulok",
            "Pho Chai",
            "Pho Thong",
            "Phon",
            "Phon Charoen",
            "Photharam",
            "Phra Khanong",
            "Phra Nakhon",
            "Phra Nakhon Si Ayutthaya",
            "Phra Phutthabat",
            "Phra Pradaeng",
            "Phrae",
            "Phrai Bueng",
            "Phu Khiao",
            "Phu Kradueng",
            "Phuket",
            "Pom Prap Sattru Phai",
            "Pong",
            "Pong Nam Ron",
            "Pra Wet",
            "Prachin Buri",
            "Prachuap Khiri Khan",
            "Prakhon Chai",
            "Pran Buri",
            "Pueai Noi",
            "Ra-ngae",
            "Ranong",
            "Ranot",
            "Rat Burana",
            "Ratchaburi",
            "Ratchathewi",
            "Rawai",
            "Rayong",
            "Roi Et",
            "Ron Phibun",
            "Rong Kwang",
            "Sa Kaeo",
            "Sadao",
            "Sai Buri",
            "Sai Mai",
            "Sakon Nakhon",
            "Saladan",
            "Sam Phran",
            "Sam Roi Yot",
            "Samphanthawong",
            "Samut Prakan",
            "Samut Sakhon",
            "Samut Songkhram",
            "San Kamphaeng",
            "San Pa Tong",
            "Sangkhla Buri",
            "Sangkhom",
            "Saphan Sung",
            "Saraburi",
            "Sathon",
            "Sattahip",
            "Satuek",
            "Satun",
            "Sawang Daen Din",
            "Sawang Wirawong",
            "Sawankhalok",
            "Seka",
            "Si Bun Rueang",
            "Si Chiang Mai",
            "Si Racha",
            "Si Sa Ket",
            "Si Satchanalai",
            "Sing Buri",
            "Soeng Sang",
            "Songkhla",
            "Su-ngai Kolok",
            "Suanluang",
            "Sukhothai",
            "Sung Noen",
            "Suphan Buri",
            "Surat Thani",
            "Surin",
            "Suwannaphum",
            "Tak",
            "Tak Bai",
            "Takhli",
            "Taling Chan",
            "Taphan Hin",
            "Tha Bo",
            "Tha Kham",
            "Tha Khanon",
            "Tha Mai",
            "Tha Maka",
            "Tha Muang",
            "Tha Ruea",
            "Tha Yang",
            "Tham Phannara",
            "Thap Khlo",
            "Thap Than",
            "That Phanom",
            "Thawi Watthana",
            "Thoen",
            "Thon buri",
            "Thung khru",
            "Thung Song",
            "Trang",
            "Trat",
            "Ubon Ratchathani",
            "Udon Thani",
            "Uthai Thani",
            "Uttaradit",
            "Vadhana",
            "Waeng",
            "Wang Nam Yen",
            "Wang Noi",
            "Wang Nuea",
            "Wang Sai Phun",
            "Wang Saphung",
            "Wang Thong Lang",
            "Wapi Pathum",
            "Warin Chamrap",
            "Wiang Sa",
            "Wichian Buri",
            "Wichit",
            "Wiset Chaichan",
            "Yala",
            "Yan na wa",
            "Yang Talat",
            "Yaring",
            "Yasothon"
        ]
    },
    {
        "label": "The Bahamas",
        "code": "BS",
        "phone": "+1-242",
        "cities": [
            "Abraham’s Bay",
            "Alice Town",
            "Andros Town",
            "Arthur’s Town",
            "Clarence Town",
            "Cockburn Town",
            "Colonel Hill",
            "Cooper’s Town",
            "Duncan Town",
            "Dunmore Town",
            "Freeport",
            "George Town",
            "High Rock",
            "Lucaya",
            "Marsh Harbour",
            "Matthew Town",
            "Nassau",
            "Port Nelson",
            "San Andros",
            "Spanish Wells",
            "West End"
        ]
    },
    {
        "label": "Togo",
        "code": "TG",
        "phone": "228",
        "cities": [
            "Amlamé",
            "Aného",
            "Atakpamé",
            "Badou",
            "Bafilo",
            "Bassar",
            "Dapaong",
            "Kandé",
            "Kara",
            "Kpalimé",
            "Lomé",
            "Niamtougou",
            "Notsé",
            "Pagouda",
            "Préfecture de Bassar",
            "Sansanné-Mango",
            "Sokodé",
            "Sotouboua",
            "Tabligbo",
            "Tchamba",
            "Tsévié",
            "Vogan"
        ]
    },
    {
        "label": "Tokelau",
        "code": "TK",
        "phone": "690",
        "cities": []
    },
    {
        "label": "Tonga",
        "code": "TO",
        "phone": "676",
        "cities": [
            "‘Ohonua",
            "Haveluloto",
            "Hihifo",
            "Kolonga",
            "Neiafu",
            "Nuku‘alofa",
            "Pangai",
            "Vaini"
        ]
    },
    {
        "label": "Trinidad And Tobago",
        "code": "TT",
        "phone": "+1-868",
        "cities": [
            "Arima",
            "Arouca",
            "Chaguanas",
            "Couva",
            "Debe",
            "Laventille",
            "Marabella",
            "Mon Repos",
            "Mucurapo",
            "Paradise",
            "Peñal",
            "Petit Valley",
            "Point Fortin",
            "Port of Spain",
            "Princes Town",
            "Rio Claro",
            "San Fernando",
            "Sangre Grande",
            "Scarborough",
            "Siparia",
            "Tabaquite",
            "Tunapuna",
            "Ward of Chaguanas",
            "Ward of Diego Martin",
            "Ward of Siparia"
        ]
    },
    {
        "label": "Tunisia",
        "code": "TN",
        "phone": "216",
        "cities": [
            "Agareb",
            "Akouda",
            "Al Marsá",
            "Al Matlīn",
            "Amdoun",
            "Ar Rudayyif",
            "Ariana",
            "As Sanad",
            "As Sars",
            "Azmour",
            "Banbalah",
            "Béja",
            "Bekalta",
            "Ben Arous",
            "Ben Gardane",
            "Beni Hassane",
            "Beni Khalled",
            "Beni Kheddache",
            "Béni Khiar",
            "Bir Ali Ben Khalifa",
            "Bir el Hafey",
            "Bizerte",
            "Bizerte Sud",
            "Bou Arada",
            "Bou Argoub",
            "Bou Attouche",
            "Carthage",
            "Chebba",
            "Chebika",
            "Chorbane",
            "Dar Chaabane",
            "Degache",
            "Djebeniana",
            "Djemmal",
            "Douar Tindja",
            "Douz",
            "El Alia",
            "El Battan",
            "El Fahs",
            "El Golaa",
            "El Hamma",
            "El Haouaria",
            "El Jem",
            "El Kef",
            "El Ksour",
            "El Maâmoura",
            "El Mida",
            "El Mrezga",
            "Er Regueb",
            "Erriadh",
            "Ettadhamen-Mnihla",
            "Fernana",
            "Gabès",
            "Gafour",
            "Gafsa",
            "Goubellat",
            "Gremda",
            "Grombalia",
            "Haffouz",
            "Hammam Lif",
            "Hammam Sousse",
            "Hammamet",
            "Harqalah",
            "Houmt El Souk",
            "Jemna",
            "Jendouba",
            "Jerba Midoun",
            "Jilma",
            "Kairouan",
            "Kalaat el-Andalous",
            "Kasserine",
            "Kebili",
            "Kelibia",
            "Kesra",
            "Korba",
            "Korbous",
            "Ksar Hellal",
            "Ksibet el Mediouni",
            "Ksour Essaf",
            "La Goulette",
            "La Mohammedia",
            "La Soukra",
            "Le Krib",
            "Lemta",
            "Mahdia",
            "Maktar",
            "Manouba",
            "Mateur",
            "Matmata",
            "Medenine",
            "Medjez el Bab",
            "Melloulèche",
            "Menzel Abderhaman",
            "Menzel Bourguiba",
            "Menzel Bouzelfa",
            "Menzel Horr",
            "Menzel Jemil",
            "Menzel Kamel",
            "Menzel Salem",
            "Menzel Temime",
            "Mesdour",
            "Metlaoui",
            "Mezzouna",
            "Midoun",
            "Mnihla",
            "Monastir",
            "Mornag",
            "Mornaguia",
            "Msaken",
            "Mu‘tamadīyat ar Rudayyif",
            "Mu‘tamadīyat Manūbah",
            "Nabeul",
            "Nefta",
            "Nefza",
            "Nibbar",
            "Ouardenine",
            "Oued Lill",
            "Oued Meliz",
            "Port el Kantaoui",
            "Radès",
            "Rafrāf",
            "Raoued",
            "Remada",
            "Rhar el Melah",
            "Rohia",
            "Sahline",
            "Sakiet Sidi Youssef",
            "Salakta",
            "Sbiba",
            "Sbikha",
            "Seïada",
            "Sejenane",
            "Sfax",
            "Sidi Alouane",
            "Sidi Ben Nour",
            "Sidi Bou Ali",
            "Sidi Bou Saïd",
            "Sidi Bouzid",
            "Sidi el Hani",
            "Sidi Thabet",
            "Siliana",
            "Skanes",
            "Skhira",
            "Soliman",
            "Sousse",
            "Tabarka",
            "Tabursuq",
            "Tajerouine",
            "Takelsa",
            "Tamaghzah",
            "Tataouine",
            "Tazerka",
            "Téboursouk",
            "Testour",
            "Thala",
            "Thibar",
            "Touza",
            "Tozeur",
            "Tunis",
            "Zaghouan",
            "Zahānah",
            "Zaouiet Djedidi",
            "Zarzis",
            "Zouila",
            "Zriba"
        ]
    },
    {
        "label": "Turkey",
        "code": "TR",
        "phone": "90",
        "cities": [
            "Abana",
            "Acıgöl",
            "Acıpayam",
            "Adaklı",
            "Adalar",
            "Adapazarı",
            "Adilcevaz",
            "Afşin",
            "Ağaçören",
            "Ağın",
            "Ağlasun",
            "Ağlı",
            "Ahırlı",
            "Ahlat",
            "Ahmetli",
            "Akçaabat",
            "Akçadağ",
            "Akçakale",
            "Akçakent",
            "Akçakoca",
            "Akdağmadeni",
            "Akdeniz",
            "Akhisar",
            "Akıncılar",
            "Akkışla",
            "Akkuş",
            "Akören",
            "Akpınar",
            "Akşehir",
            "Akseki",
            "Aksu",
            "Aksu",
            "Akyaka",
            "Akyazı",
            "Akyurt",
            "Alaca",
            "Alacakaya",
            "Alaçam",
            "Aladağ",
            "Alanya",
            "Alaplı",
            "Alaşehir",
            "Aliağa",
            "Almus",
            "Alpu",
            "Altıeylül",
            "Altındağ",
            "Altınekin",
            "Altınordu",
            "Altınova",
            "Altınözü",
            "Altıntaş",
            "Altınyayla",
            "Altınyayla",
            "Altunhisar",
            "Alucra",
            "Amasra",
            "Anamur",
            "Andırın",
            "Antakya",
            "Araban",
            "Araç",
            "Araklı",
            "Aralık",
            "Arapgir",
            "Ardanuç",
            "Ardeşen",
            "Arguvan",
            "Arhavi",
            "Arifiye",
            "Arıcak",
            "Armutlu",
            "Arnavutköy",
            "Arpaçay",
            "Arsin",
            "Arsuz",
            "Artova",
            "Artuklu",
            "Asarcık",
            "Aşkale",
            "Aslanapa",
            "Atabey",
            "Atakum",
            "Ataşehir",
            "Atkaracalar",
            "Avanos",
            "Avcılar",
            "Ayancık",
            "Ayaş",
            "Aybastı",
            "Aydıncık",
            "Aydıncık",
            "Aydıntepe",
            "Ayrancı",
            "Ayvacık",
            "Ayvacık",
            "Ayvalık",
            "Azdavay",
            "Aziziye",
            "Babadağ",
            "Babaeski",
            "Bafra",
            "Bağcılar",
            "Bağlar",
            "Bahçe",
            "Bahçelievler",
            "Bahçesaray",
            "Bahşılı",
            "Bakırköy",
            "Baklan",
            "Bala",
            "Balçova",
            "Balışeyh",
            "Balya",
            "Banaz",
            "Bandırma",
            "Başakşehir",
            "Başçiftlik",
            "Başiskele",
            "Başkale",
            "Baskil",
            "Başmakçı",
            "Başyayla",
            "Battalgazi",
            "Bayat",
            "Bayat",
            "Bayındır",
            "Baykan",
            "Bayraklı",
            "Bayramiç",
            "Bayramören",
            "Bayrampaşa",
            "Bekilli",
            "Belen",
            "Bergama",
            "Beşikdüzü",
            "Beşiktaş",
            "Beşiri",
            "Besni",
            "Beyağaç",
            "Beydağ",
            "Beykoz",
            "Beylikdüzü",
            "Beylikova",
            "Beyoğlu",
            "Beypazarı",
            "Beyşehir",
            "Beytüşşebap",
            "Biga",
            "Bigadiç",
            "Birecik",
            "Bismil",
            "Bodrum",
            "Boğazkale",
            "Boğazlıyan",
            "Bolvadin",
            "Bor",
            "Borçka",
            "Bornova",
            "Boyabat",
            "Bozcaada",
            "Bozdoğan",
            "Bozkır",
            "Bozkurt",
            "Bozkurt",
            "Bozova",
            "Boztepe",
            "Bozüyük",
            "Bozyazı",
            "Buca",
            "Bucak",
            "Buharkent",
            "Bulancak",
            "Bulanık",
            "Buldan",
            "Bünyan",
            "Burhaniye",
            "Büyükçekmece",
            "Büyükorhan",
            "Çağlayancerit",
            "Çal",
            "Çaldıran",
            "Çamardı",
            "Çamaş",
            "Çameli",
            "Çamlıdere",
            "Çamlıhemşin",
            "Çamlıyayla",
            "Çamoluk",
            "Çan",
            "Çanakçı",
            "Çandır",
            "Canik",
            "Çankaya",
            "Çardak",
            "Çarşamba",
            "Çarşıbaşı",
            "Çat",
            "Çatak",
            "Çatalca",
            "Çatalpınar",
            "Çatalzeytin",
            "Çavdarhisar",
            "Çavdır",
            "Çay",
            "Çaybaşı",
            "Çaycuma",
            "Çayeli",
            "Çayıralan",
            "Çayırlı",
            "Çayırova",
            "Çaykara",
            "Çekerek",
            "Çekmeköy",
            "Çelebi",
            "Çelikhan",
            "Çeltik",
            "Çeltikçi",
            "Çemişgezek",
            "Çerkeş",
            "Çerkezköy",
            "Çermik",
            "Çeşme",
            "Ceyhan",
            "Ceylanpınar",
            "Çiçekdağı",
            "Cide",
            "Çifteler",
            "Çiftlik",
            "Çiftlikköy",
            "Çiğli",
            "Cihanbeyli",
            "Çilimli",
            "Çine",
            "Çivril",
            "Cizre",
            "Çıldır",
            "Çınar",
            "Çınarcık",
            "Çobanlar",
            "Çorlu",
            "Çubuk",
            "Çukurca",
            "Çukurova",
            "Cumayeri",
            "Çumra",
            "Çüngüş",
            "Daday",
            "Dalaman",
            "Damal",
            "Darende",
            "Dargeçit",
            "Darıca",
            "Datça",
            "Dazkırı",
            "Defne",
            "Delice",
            "Demirci",
            "Demirköy",
            "Demirözü",
            "Demre",
            "Derbent",
            "Derebucak",
            "Derecik",
            "Dereli",
            "Derepazarı",
            "Derik",
            "Derince",
            "Derinkuyu",
            "Dernekpazarı",
            "Develi",
            "Devrek",
            "Devrekani",
            "Dicle",
            "Didim",
            "Digor",
            "Dikili",
            "Dikmen",
            "Dilovası",
            "Dinar",
            "Divriği",
            "Diyadin",
            "Dodurga",
            "Doğanhisar",
            "Doğankent",
            "Doğanşar",
            "Doğanşehir",
            "Doğanyol",
            "Doğanyurt",
            "Doğubayazıt",
            "Domaniç",
            "Dörtdivan",
            "Dörtyol",
            "Döşemealtı",
            "Dulkadiroğlu",
            "Dumlupınar",
            "Durağan",
            "Dursunbey",
            "Düziçi",
            "Düzköy",
            "Eceabat",
            "Edremit",
            "Edremit",
            "Efeler",
            "Eflani",
            "Eğil",
            "Eğirdir",
            "Ekinözü",
            "Elbeyli",
            "Elbistan",
            "Eldivan",
            "Eleşkirt",
            "Elmadağ",
            "Elmalı",
            "Emet",
            "Emirdağ",
            "Emirgazi",
            "Enez",
            "Erbaa",
            "Erciş",
            "Erdek",
            "Erdemli",
            "Ereğli",
            "Ereğli",
            "Erenler",
            "Erfelek",
            "Ergani",
            "Ergene",
            "Ermenek",
            "Eruh",
            "Erzin",
            "Esenler",
            "Esenyurt",
            "Eskil",
            "Eskipazar",
            "Eşme",
            "Espiye",
            "Etimesgut",
            "Evciler",
            "Evren",
            "Eynesil",
            "Eyüpsultan",
            "Eyyübiye",
            "Ezine",
            "Fatih",
            "Fatsa",
            "Feke",
            "Felahiye",
            "Ferizli",
            "Fethiye",
            "Finike",
            "Fındıklı",
            "Foça",
            "Gaziemir",
            "Gaziosmanpaşa",
            "Gazipaşa",
            "Gebze",
            "Gediz",
            "Gelendost",
            "Gelibolu",
            "Gemerek",
            "Gemlik",
            "Genç",
            "Gercüş",
            "Gerede",
            "Gerger",
            "Germencik",
            "Gerze",
            "Gevaş",
            "Geyve",
            "Gökçeada",
            "Gökçebey",
            "Göksun",
            "Gölbaşı",
            "Gölbaşı",
            "Gölcük",
            "Göle",
            "Gölhisar",
            "Gölköy",
            "Gölmarmara",
            "Gölova",
            "Gölpazarı",
            "Gölyaka",
            "Gömeç",
            "Gönen",
            "Gönen",
            "Gördes",
            "Görele",
            "Göynücek",
            "Göynük",
            "Güce",
            "Güçlükonak",
            "Güdül",
            "Gülağaç",
            "Gülnar",
            "Gülşehir",
            "Gülyalı",
            "Gümüşhacıköy",
            "Gümüşova",
            "Gündoğmuş",
            "Güney",
            "Güneysınır",
            "Güneysu",
            "Güngören",
            "Günyüzü",
            "Gürgentepe",
            "Güroymak",
            "Gürpınar",
            "Gürsu",
            "Gürün",
            "Güzelbahçe",
            "Güzelyurt",
            "Hacıbektaş",
            "Hacılar",
            "Hadim",
            "Hafik",
            "Halfeti",
            "Haliliye",
            "Halkapınar",
            "Hamamözü",
            "Hamur",
            "Han",
            "Hanak",
            "Hani",
            "Hanönü",
            "Harmancık",
            "Harran",
            "Hasanbeyli",
            "Hasankeyf",
            "Hasköy",
            "Hassa",
            "Havran",
            "Havsa",
            "Havza",
            "Haymana",
            "Hayrabolu",
            "Hayrat",
            "Hazro",
            "Hekimhan",
            "Hemşin",
            "Hendek",
            "Hilvan",
            "Hisarcık",
            "Hizan",
            "Hınıs",
            "Hocalar",
            "Honaz",
            "Hopa",
            "Horasan",
            "Hozat",
            "Hüyük",
            "İbradı",
            "İdil",
            "İhsangazi",
            "İhsaniye",
            "İkizce",
            "İkizdere",
            "Ilgaz",
            "Ilgın",
            "İliç",
            "İlkadım",
            "İmamoğlu",
            "İmranlı",
            "İncesu",
            "İncirliova",
            "İnebolu",
            "İnegöl",
            "İnhisar",
            "İnönü",
            "İpekyolu",
            "İpsala",
            "İscehisar",
            "İskenderun",
            "İskilip",
            "İslahiye",
            "İspir",
            "İvrindi",
            "İyidere",
            "İzmit",
            "İznik",
            "Kabadüz",
            "Kabataş",
            "Kadirli",
            "Kadıköy",
            "Kadınhanı",
            "Kadışehri",
            "Kâğıthane",
            "Kağızman",
            "Kahramankazan",
            "Kâhta",
            "Kale",
            "Kale",
            "Kalecik",
            "Kalkandere",
            "Kaman",
            "Kandıra",
            "Kangal",
            "Kapaklı",
            "Karabağlar",
            "Karaburun",
            "Karacabey",
            "Karacasu",
            "Karaçoban",
            "Karahallı",
            "Karaisalı",
            "Karakeçili",
            "Karakoçan",
            "Karaköprü",
            "Karakoyunlu",
            "Karamanlı",
            "Karamürsel",
            "Karapınar",
            "Karapürçek",
            "Karasu",
            "Karataş",
            "Karatay",
            "Karayazı",
            "Karesi",
            "Kargı",
            "Karkamış",
            "Karlıova",
            "Karpuzlu",
            "Karşıyaka",
            "Kartal",
            "Kartepe",
            "Kaş",
            "Kavak",
            "Kavaklıdere",
            "Kayapınar",
            "Kaynarca",
            "Kaynaşlı",
            "Kazımkarabekir",
            "Keban",
            "Keçiborlu",
            "Keçiören",
            "Keles",
            "Kelkit",
            "Kemah",
            "Kemaliye",
            "Kemalpaşa",
            "Kemalpaşa",
            "Kemer",
            "Kemer",
            "Kepez",
            "Kepsut",
            "Keşan",
            "Keşap",
            "Keskin",
            "Kestel",
            "Kiğı",
            "Kilimli",
            "Kiraz",
            "Kıbrıscık",
            "Kınık",
            "Kırıkhan",
            "Kırkağaç",
            "Kızılcahamam",
            "Kızılırmak",
            "Kızılören",
            "Kızıltepe",
            "Kocaali",
            "Kocaköy",
            "Koçarlı",
            "Kocasinan",
            "Kofçaz",
            "Konak",
            "Konyaaltı",
            "Köprübaşı",
            "Köprübaşı",
            "Köprüköy",
            "Körfez",
            "Korgan",
            "Korgun",
            "Korkut",
            "Korkuteli",
            "Köse",
            "Köşk",
            "Kovancılar",
            "Köyceğiz",
            "Koyulhisar",
            "Kozaklı",
            "Kozan",
            "Kozlu",
            "Kozluk",
            "Küçükçekmece",
            "Kula",
            "Kulp",
            "Kulu",
            "Kuluncak",
            "Kumlu",
            "Kumluca",
            "Kumru",
            "Küre",
            "Kurşunlu",
            "Kurtalan",
            "Kürtün",
            "Kurucaşile",
            "Kuşadası",
            "Kuyucak",
            "Laçin",
            "Ladik",
            "Lâlapaşa",
            "Lapseki",
            "Lice",
            "Lüleburgaz",
            "Maçka",
            "Maden",
            "Mahmudiye",
            "Malazgirt",
            "Malkara",
            "Maltepe",
            "Mamak",
            "Manavgat",
            "Manyas",
            "Marmara",
            "Marmaraereğlisi",
            "Marmaris",
            "Mazgirt",
            "Mazıdağı",
            "Mecitözü",
            "Melikgazi",
            "Menderes",
            "Menemen",
            "Mengen",
            "Menteşe",
            "Meram",
            "Meriç",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkez",
            "Merkezefendi",
            "Merzifon",
            "Mesudiye",
            "Mezitli",
            "Midyat",
            "Mihalgazi",
            "Mihalıççık",
            "Milas",
            "Mucur",
            "Mudanya",
            "Mudurnu",
            "Muradiye",
            "Muratlı",
            "Muratpaşa",
            "Murgul",
            "Musabeyli",
            "Mustafakemalpaşa",
            "Mut",
            "Mutki",
            "Nallıhan",
            "Narlıdere",
            "Narman",
            "Nazilli",
            "Nazımiye",
            "Niksar",
            "Nilüfer",
            "Nizip",
            "Nurdağı",
            "Nurhak",
            "Nusaybin",
            "Ödemiş",
            "Odunpazarı",
            "Of",
            "Oğuzeli",
            "Oğuzlar",
            "Oltu",
            "Olur",
            "Ömerli",
            "Ondokuzmayıs",
            "Onikişubat",
            "Orhaneli",
            "Orhangazi",
            "Orta",
            "Ortaca",
            "Ortahisar",
            "Ortaköy",
            "Ortaköy",
            "Osmancık",
            "Osmaneli",
            "Osmangazi",
            "Otlukbeli",
            "Ovacık",
            "Ovacık",
            "Özalp",
            "Özvatan",
            "Palandöken",
            "Palu",
            "Pamukkale",
            "Pamukova",
            "Pasinler",
            "Patnos",
            "Payas",
            "Pazar",
            "Pazar",
            "Pazarcık",
            "Pazarlar",
            "Pazaryeri",
            "Pazaryolu",
            "Pehlivanköy",
            "Pendik",
            "Perşembe",
            "Pertek",
            "Pervari",
            "Piraziz",
            "Pınarbaşı",
            "Pınarbaşı",
            "Pınarhisar",
            "Polateli",
            "Polatlı",
            "Posof",
            "Pozantı",
            "Pülümür",
            "Pursaklar",
            "Pütürge",
            "Refahiye",
            "Reşadiye",
            "Reyhanlı",
            "Şabanözü",
            "Safranbolu",
            "Şahinbey",
            "Saimbeyli",
            "Salihli",
            "Salıpazarı",
            "Şalpazarı",
            "Samandağ",
            "Samsat",
            "Sancaktepe",
            "Sandıklı",
            "Sapanca",
            "Şaphane",
            "Saray",
            "Saray",
            "Saraydüzü",
            "Saraykent",
            "Sarayköy",
            "Sarayönü",
            "Sarıcakaya",
            "Sarıçam",
            "Sarıgöl",
            "Sarıkamış",
            "Sarıkaya",
            "Sarıoğlan",
            "Sarıveliler",
            "Sarıyahşi",
            "Sarıyer",
            "Sarız",
            "Şarkikaraağaç",
            "Şarkışla",
            "Şarköy",
            "Saruhanlı",
            "Sason",
            "Savaştepe",
            "Şavşat",
            "Savur",
            "Seben",
            "Şebinkarahisar",
            "Şefaatli",
            "Seferihisar",
            "Şehitkamil",
            "Şehzadeler",
            "Selçuk",
            "Selçuklu",
            "Selendi",
            "Selim",
            "Şemdinli",
            "Senirkent",
            "Şenkaya",
            "Şenpazar",
            "Serdivan",
            "Şereflikoçhisar",
            "Serik",
            "Serinhisar",
            "Seydikemer",
            "Seydiler",
            "Seydişehir",
            "Seyhan",
            "Seyitgazi",
            "Şile",
            "Silifke",
            "Silivri",
            "Silopi",
            "Silvan",
            "Simav",
            "Sinanpaşa",
            "Sincan",
            "Sincik",
            "Şiran",
            "Şirvan",
            "Şişli",
            "Sivaslı",
            "Siverek",
            "Sivrice",
            "Sivrihisar",
            "Sındırgı",
            "Söğüt",
            "Söğütlü",
            "Söke",
            "Solhan",
            "Soma",
            "Sorgun",
            "Şuhut",
            "Sulakyurt",
            "Süleymanpaşa",
            "Süloğlu",
            "Sultanbeyli",
            "Sultandağı",
            "Sultangazi",
            "Sultanhanı",
            "Sultanhisar",
            "Suluova",
            "Sulusaray",
            "Sumbas",
            "Sungurlu",
            "Sur",
            "Sürmene",
            "Suruç",
            "Suşehri",
            "Susurluk",
            "Susuz",
            "Sütçüler",
            "Talas",
            "Taraklı",
            "Tarsus",
            "Taşkent",
            "Taşköprü",
            "Taşlıçay",
            "Taşova",
            "Tatvan",
            "Tavas",
            "Tavşanlı",
            "Tefenni",
            "Tekkeköy",
            "Tekman",
            "Tepebaşı",
            "Tercan",
            "Termal",
            "Terme",
            "Tillo",
            "Tire",
            "Tirebolu",
            "Tomarza",
            "Tonya",
            "Toprakkale",
            "Torbalı",
            "Toroslar",
            "Tortum",
            "Torul",
            "Tosya",
            "Tufanbeyli",
            "Turgutlu",
            "Turhal",
            "Türkeli",
            "Türkoğlu",
            "Tuşba",
            "Tut",
            "Tutak",
            "Tuzla",
            "Tuzluca",
            "Tuzlukçu",
            "Uğurludağ",
            "Ula",
            "Ulaş",
            "Ulubey",
            "Ulubey",
            "Uluborlu",
            "Uludere",
            "Ulukışla",
            "Ulus",
            "Ümraniye",
            "Ünye",
            "Ürgüp",
            "Urla",
            "Üsküdar",
            "Üzümlü",
            "Uzundere",
            "Uzunköprü",
            "Vakfıkebir",
            "Varto",
            "Vezirköprü",
            "Viranşehir",
            "Vize",
            "Yağlıdere",
            "Yahşihan",
            "Yahyalı",
            "Yakakent",
            "Yakutiye",
            "Yalıhüyük",
            "Yalvaç",
            "Yapraklı",
            "Yatağan",
            "Yavuzeli",
            "Yayladağı",
            "Yayladere",
            "Yazıhan",
            "Yedisu",
            "Yeniçağa",
            "Yenice",
            "Yenice",
            "Yenifakılı",
            "Yenimahalle",
            "Yenipazar",
            "Yenipazar",
            "Yenişarbademli",
            "Yenişehir",
            "Yenişehir",
            "Yenişehir",
            "Yerköy",
            "Yeşilhisar",
            "Yeşilli",
            "Yeşilova",
            "Yeşilyurt",
            "Yeşilyurt",
            "Yığılca",
            "Yıldırım",
            "Yıldızeli",
            "Yomra",
            "Yüksekova",
            "Yumurtalık",
            "Yunak",
            "Yunusemre",
            "Yüreğir",
            "Yusufeli",
            "Zara",
            "Zeytinburnu",
            "Zile"
        ]
    },
    {
        "label": "Turkmenistan",
        "code": "TM",
        "phone": "993",
        "cities": [
            "Abadan",
            "Akdepe",
            "Annau",
            "Arçabil",
            "Ashgabat",
            "Atamyrat",
            "Baharly",
            "Balkanabat",
            "Bayramaly",
            "Bereket",
            "Boldumsaz",
            "Daşoguz",
            "Farap",
            "Gazojak",
            "Gowurdak",
            "Gumdag",
            "Kaka",
            "Köneürgench",
            "Magtymguly",
            "Mary",
            "Saýat",
            "Serdar",
            "Serhetabat",
            "Seydi",
            "Tagta",
            "Tejen",
            "Türkmenabat",
            "Türkmenbaşy",
            "Yolöten",
            "Yylanly"
        ]
    },
    {
        "label": "Turks And Caicos Islands",
        "code": "TC",
        "phone": "+1-649",
        "cities": []
    },
    {
        "label": "Tuvalu",
        "code": "TV",
        "phone": "688",
        "cities": [
            "Alapi Village",
            "Asau Village",
            "Fakaifou Village",
            "Funafuti",
            "Kulia Village",
            "Niulakita",
            "Savave Village",
            "Tanrake Village",
            "Toga Village"
        ]
    },
    {
        "label": "Uganda",
        "code": "UG",
        "phone": "256",
        "cities": [
            "Adjumani",
            "Amudat",
            "Apac",
            "Arua",
            "Bugembe",
            "Bugiri",
            "Bukomansimbi District",
            "Bukwa District",
            "Bulambuli District",
            "Bundibugyo",
            "Busembatia",
            "Busia",
            "Buvuma District",
            "Buwenge",
            "Bweyogerere",
            "Bwizibwera",
            "Byakabanda",
            "Entebbe",
            "Fort Portal",
            "Gomba District",
            "Gulu",
            "Hoima",
            "Ibanda",
            "Ibanda District",
            "Iganga",
            "Jinja",
            "Kabale",
            "Kagadi",
            "Kajansi",
            "Kampala",
            "Kampala District",
            "Kamuli",
            "Kamwenge",
            "Kanoni",
            "Kanungu",
            "Kapchorwa",
            "Kasese",
            "Kayunga",
            "Kibale",
            "Kiboga",
            "Kibuku District",
            "Kigorobya",
            "Kilembe",
            "Kireka",
            "Kiruhura",
            "Kisoro",
            "Kitgum",
            "Kotido",
            "Kumi",
            "Kyenjojo",
            "Kyotera",
            "Lira",
            "Lugazi",
            "Luwero",
            "Lyantonde",
            "Margherita",
            "Masaka",
            "Masindi",
            "Masindi Port",
            "Mayuge",
            "Mbale",
            "Mbarara",
            "Mityana",
            "Moroto",
            "Moyo",
            "Mpigi",
            "Mubende",
            "Mubende District",
            "Muhororo",
            "Mukono",
            "Nakasongola",
            "Namasuba",
            "Nebbi",
            "Njeru",
            "Ntungamo",
            "Nyachera",
            "Otuke District",
            "Oyam District",
            "Pader",
            "Pader Palwo",
            "Paidha",
            "Pallisa",
            "Rukungiri",
            "Sembabule",
            "Sironko",
            "Soroti",
            "Tororo",
            "Wakiso",
            "Wakiso District",
            "Wobulenzi",
            "Yumbe"
        ]
    },
    {
        "label": "Ukraine",
        "code": "UA",
        "phone": "380",
        "cities": [
            "Abramok",
            "Abrikosovka",
            "Abrikosovo",
            "Adamivka",
            "Adamove",
            "Adzhamka",
            "Aeroflotskiy",
            "Afanas’yeva",
            "Agativka",
            "Agrarnoye",
            "Akimovka",
            "Alarskiy Rayon",
            "Alchevs’k",
            "Alchevs’ka Mis’krada",
            "Aleksandrovka",
            "Alekseyevka",
            "Alekseyevskaya",
            "Algatuy",
            "Alupka",
            "Alushta",
            "Alzamay",
            "Amurskoye",
            "Amvrosiivka Raion",
            "Amvrosiyivka",
            "Andreyevka",
            "Andriyivka",
            "Andrushivka",
            "Andrushivka",
            "Andrushivs’kyy Rayon",
            "Angarsk",
            "Angarskiy Rayon",
            "Antoniny",
            "Antratsyt",
            "Antratsytivs’kyy Rayon",
            "Apostolove",
            "Arbuzynka",
            "Armyansk",
            "Aromatnoye",
            "Artemivs’k",
            "Artsyz",
            "Askaniya-Nova",
            "Atagay",
            "Auly",
            "Autozavodskoy Raion",
            "Avdiyivka",
            "Avdiyivka",
            "Aviatorske",
            "Avratin",
            "Azovskoye",
            "Babanka",
            "Badar",
            "Baherove",
            "Bairak",
            "Bakhchysarai",
            "Bakhchysarai Raion",
            "Bakhmach",
            "Bakhmut",
            "Bakhmut Raion",
            "Baklashi",
            "Balagansk",
            "Balaganskiy Rayon",
            "Balakhninskiy",
            "Balaklava",
            "Balaklava District",
            "Balakliya",
            "Baliasne",
            "Balky",
            "Balta",
            "Balts’kyy Rayon",
            "Banyliv",
            "Bar",
            "Baranivka",
            "Baranivka",
            "Baranivka",
            "Barashi",
            "Barskiy Rayon",
            "Barvinkove",
            "Baryshivka",
            "Baryshivs’kyy Rayon",
            "Bashtanka",
            "Batal’noye",
            "Batiovo",
            "Baturyn",
            "Bayanday",
            "Bayandayevskiy Rayon",
            "Baykal’sk",
            "Bayrachky",
            "Bazaliya",
            "Bazar",
            "Bekhi",
            "Bekhtery",
            "Belaya",
            "Belinskoye",
            "Beloglinka",
            "Belogorskiy rayon",
            "Belz",
            "Berdiansk Raion",
            "Berdyans’ka Mis’krada",
            "Berdyansk",
            "Berdychiv",
            "Berdychiv",
            "Berdychivskyy Rayon",
            "Beregovoye",
            "Berehivs’ka Mis’krada",
            "Berehomet",
            "Berehove",
            "Berehove Raion",
            "Berestechko",
            "Berestivka",
            "Berestovets",
            "Berezanka",
            "Berezianka",
            "Berezivka",
            "Berezivka",
            "Berezna",
            "Bereznehuvate",
            "Bereznyaki",
            "Bereznyky",
            "Berezovii Grud",
            "Berezovka",
            "Berezovo",
            "Bereztsi",
            "Berezyne",
            "Bershad",
            "Beryslav",
            "Bezimenne",
            "Bezlyudivka",
            "Bibrka",
            "Bicheva",
            "Bigun",
            "Bikiv",
            "Bila Krynytsia",
            "Bila Krynytsya",
            "Bila Tserkva",
            "Bila Tserkva Raion",
            "Bile",
            "Bilhorod-Dnistrovskyi",
            "Bili Oslavy",
            "Bilii Bereg",
            "Bilka",
            "Bilky",
            "Bilohirsk",
            "Bilohorivka",
            "Bilokurakyne",
            "Bilopillya",
            "Bilovods'k",
            "Bilozerka",
            "Bilshivtsi",
            "Bilyayivka",
            "Bilylivka",
            "Bilyts’ke",
            "Biryukove",
            "Biryusinsk",
            "Bistrik",
            "Bistriyivka",
            "Blahodatne",
            "Blahodatne",
            "Blahovishchenske Raion",
            "Blizhneye",
            "Blyznyuky",
            "Bobovo",
            "Bobrovytsya",
            "Bobrynets",
            "Bodaybo",
            "Bogatoye",
            "Bohdan",
            "Bohodukhiv",
            "Bohorodchans’kyy Rayon",
            "Bohorodchany",
            "Bohuslav",
            "Bokhan",
            "Bokhanskiy Rayon",
            "Bol’shaya Rechka",
            "Bolekhiv",
            "Bolekhivs’ka Mis’krada",
            "Bolhrad",
            "Borisivka",
            "Borispol’skiy Rayon",
            "Borodianka",
            "Borodino",
            "Borodyans’kyy Rayon",
            "Boromlya",
            "Borova",
            "Borova",
            "Borshchevskiy Rayon",
            "Borshchiv",
            "Boryslav",
            "Boryspil’",
            "Borzna",
            "Botanicheskoye",
            "Boyany",
            "Boyarka",
            "Boykivske",
            "Boykivske Raion",
            "Brailiv",
            "Bratsk",
            "Bratskiy Rayon",
            "Bratskoye",
            "Bratslav",
            "Brody",
            "Broniki",
            "Bronitsia",
            "Bronitska Guta",
            "Broshniv-Osada",
            "Broska",
            "Brovary",
            "Brovary Raion",
            "Brovki Pershi",
            "Brusyliv",
            "Brusyliv",
            "Brusylivs’kyy Rayon",
            "Bryanka",
            "Brylivka",
            "Bryukhovychi",
            "Bucha",
            "Buchach",
            "Buchmany",
            "Budagovo",
            "Budy",
            "Buki",
            "Buky",
            "Buldichiv",
            "Buran",
            "Buriaki",
            "Burkivtsi",
            "Burshtyn",
            "Burshtyns’ka Mis’krada",
            "Buryn’",
            "Bushtyno",
            "Bykivka",
            "Bykivka",
            "Bystrytsya",
            "Bytkiv",
            "Chabany",
            "Chapayeve",
            "Chapayevka",
            "Chapayevka",
            "Chaplynka",
            "Chasiv Yar",
            "Chaykino",
            "Chechelnyk",
            "Chelyadinovo",
            "Cheremkhovo",
            "Cheremkhovskiy Rayon",
            "Cherkasy",
            "Chernechii Iar",
            "Chernelytsya",
            "Cherniakhiv",
            "Cherniakhiv",
            "Chernihiv",
            "Chernihiv Raion",
            "Chernihivka Raion",
            "Chernihivs’ka Mis’krada",
            "Chernivets’kyy Rayon",
            "Chernivtsi",
            "Chernivtsi",
            "Chernivtsi Municipality",
            "Cherniyiv",
            "Chernomorskiy rayon",
            "Chernomorskoye",
            "Chernopolye",
            "Chernovo",
            "Chernozemnoye",
            "Chernyakhivka",
            "Chernyakhivskyy Rayon",
            "Chernyatyn",
            "Chernyshevo",
            "Chervona Sloboda",
            "Chervone",
            "Chervone",
            "Chervonohrad",
            "Chervonohryhorivka",
            "Chervonopartyzans’k",
            "Chervonoye",
            "Chervonyy Oskil",
            "Chisten’koye",
            "Chistopolye",
            "Chkalovo",
            "Chop",
            "Chopovychi",
            "Chopovychi",
            "Chornobay",
            "Chornobayivka",
            "Chornoliztsi",
            "Chornomors’k",
            "Chornorudka",
            "Chornukhy",
            "Chornukhyne",
            "Chornyanka",
            "Chortkiv",
            "Chortomlyk",
            "Chudey",
            "Chudniv",
            "Chudniv",
            "Chudniv Raion",
            "Chuhuyiv",
            "Chulakivka",
            "Chunskiy",
            "Chutove",
            "Chyhyryn",
            "Chynadiyovo",
            "Chystyakove",
            "Dachnoye",
            "Dalekoye",
            "Darnytsia Raion",
            "Dashiv",
            "Dashynka",
            "Davidivka",
            "Davydivka",
            "Davydky",
            "Debal’tseve",
            "Delyatyn",
            "Demnya",
            "Demydivs’kyy Rayon",
            "Denyshi",
            "Denyshi",
            "Derazhnya",
            "Derazhnyans’kyy Rayon",
            "Derhachi",
            "Derhanivka",
            "Desna",
            "Desnyans’kyy Rayon",
            "Dibrova",
            "Didkovichi",
            "Didovichi",
            "Dihtyari",
            "Divochki",
            "Dmitrovka",
            "Dmytrivka",
            "Dmytrivka",
            "Dmytrivka",
            "Dneprovka",
            "Dnipro",
            "Dnipro Raion",
            "Dnipro Raion",
            "Dniprorudne",
            "Dniprovka",
            "Dniprovs’ka Mis’ka Rada",
            "Dnipryany",
            "Dobromyl’",
            "Dobropillia Raion",
            "Dobropillya",
            "Dobrovelychkivka",
            "Dobrovelychkivs’kyy Rayon",
            "Dobroye",
            "Dobrushino",
            "Dobryanka",
            "Dokuchayevs’k",
            "Dolinnoye",
            "Dolyna",
            "Dolyns'ka",
            "Dolyns’kyy Rayon",
            "Dolyns’kyy Rayon",
            "Domanivka",
            "Donetsk",
            "Donskoye",
            "Dovbysh",
            "Dovbysh",
            "Dovhe",
            "Dovzhanskyy Rayon",
            "Drabiv",
            "Drahovo",
            "Drofino",
            "Drohobych",
            "Druzhba",
            "Druzhba",
            "Druzhba",
            "Druzhkivka",
            "Dubivka",
            "Dubki",
            "Dublyany",
            "Dubno",
            "Dubnyki",
            "Dubove",
            "Dubrivka",
            "Dubrovytsya",
            "Dudchany",
            "Duliby",
            "Dunaivtsi",
            "Dvorichna",
            "Dvorishche",
            "Dykanka",
            "Dymer",
            "Dzerzhinsk",
            "Dzhankoy",
            "Dzhankoyskiy rayon",
            "Dzvinogrud",
            "Ekhirit-Bulagatskiy Rayon",
            "Elivka",
            "Energodar",
            "Eskhar",
            "Fastiv",
            "Fastiv Raion",
            "Fedorivka",
            "Feodosiya",
            "Filatovka",
            "Filenkove",
            "Fontanka",
            "Fontany",
            "Foros",
            "Frontovoye",
            "Frunze",
            "Gadaley",
            "Gaspra",
            "Geroyskoye",
            "Glazovka",
            "Glinivtsi",
            "Godikha",
            "Golovenka",
            "Golovki",
            "Golubinka",
            "Golubyatin",
            "Gorbuliv",
            "Gordiyivka",
            "Gorianshchina",
            "Gornostayevka",
            "Gorodkivka",
            "Gorodok",
            "Gorodske",
            "Gorodskoy okrug Alushta",
            "Gorodskoy okrug Armyansk",
            "Gorodskoy okrug Dzhankoy",
            "Gorodskoy okrug Feodosiya",
            "Gorodskoy okrug Krasnoperekopsk",
            "Gorodskoy okrug Saki",
            "Gorodskoy okrug Simferopol",
            "Gorodskoy okrug Sudak",
            "Gorodskoy okrug Yalta",
            "Gorodskoy okrug Yevpatoriya",
            "Goropayi",
            "Goshiv",
            "Gresovskiy",
            "Grishino",
            "Gromada",
            "Grozyne",
            "Grushevka",
            "Grushki",
            "Gubske",
            "Gulsk",
            "Gumenniki",
            "Guran",
            "Gurzuf",
            "Guta-Potiyivka",
            "Guto-Mariatin",
            "Hadiach",
            "Haharinskyi District",
            "Haisyn",
            "Halych",
            "Halyts’kyy Rayon",
            "Hayvoron",
            "Heniches’k",
            "Heniches’kyy Rayon",
            "Hirs’ke",
            "Hlevakha",
            "Hlobyne",
            "Hlukhiv",
            "Hlyboka",
            "Hlyns'k",
            "Hnidyn",
            "Hola Prystan’",
            "Holosiiv Raion",
            "Holovyne",
            "Holovyne",
            "Holubivka",
            "Hołoby",
            "Horenka",
            "Horinchovo",
            "Horishni Plavni",
            "Horlivka",
            "Hornostayivka",
            "Horodenka",
            "Horodenkivs’kyy Rayon",
            "Horodets",
            "Horodnya",
            "Horodnye",
            "Horodnytsia",
            "Horodnytsya",
            "Horodok",
            "Horodok",
            "Horodok",
            "Horodyshche",
            "Horodyshche",
            "Horokhiv",
            "Hoshcha",
            "Hostomel",
            "Hradyzk",
            "Hranitne",
            "Hrebinka",
            "Hrymayliv",
            "Hryshkivtsi",
            "Hryshkivtsi",
            "Hubynykha",
            "Hulyaypole",
            "Hupalivka",
            "Husiatyn",
            "Huty",
            "Hvardiiske",
            "Hvizd",
            "Ichnya",
            "Iemilivka",
            "Ievgenivka",
            "Ikey",
            "Ilir",
            "Illintsi",
            "Ilovays’k",
            "Ilychyovo",
            "Ilyichevo",
            "Ilyinka",
            "Inkerman",
            "Irkutsk",
            "Irkutskiy Rayon",
            "Irpin",
            "Irpins’ka Mis’krada",
            "Irshansk",
            "Irshava",
            "Irshava Raion",
            "Ishun’",
            "Ispas",
            "Ivanivka",
            "Ivanivka",
            "Ivanivka",
            "Ivankiv",
            "Ivankiv",
            "Ivankiv Raion",
            "Ivano-Frankivs’ka Mis’krada",
            "Ivano-Frankivsk",
            "Ivano-Frankove",
            "Ivanopil",
            "Ivanovka",
            "Iza",
            "Izmayil",
            "Izobil’noye",
            "Izumrudnoye",
            "Izyaslav",
            "Izyum",
            "Izyumovka",
            "Kacha",
            "Kachug",
            "Kachugskiy Rayon",
            "Kadiyivka",
            "Kadubivtsi",
            "Kaharlyk",
            "Kaharlyk Raion",
            "Kakhovka",
            "Kalanchak",
            "Kalcheva",
            "Kalinino",
            "Kalinovka",
            "Kalus’kyy Rayon",
            "Kalush",
            "Kalynivka",
            "Kalynove",
            "Kalyny",
            "Kamenolomnya",
            "Kamianets-Podilskyi",
            "Kamiani Potoky",
            "Kamianka",
            "Kamianske",
            "Kamianytsia",
            "Kamin-Kashyrskyi",
            "Kamyshevka Vtoraya",
            "Kaniv",
            "Kapitanivka",
            "Karapyshi",
            "Kardashivka",
            "Karlivka",
            "Karolino-Buhaz",
            "Kashtanovoye",
            "Kashtany",
            "Katangskiy Rayon",
            "Katyuzhanka",
            "Kaydaki",
            "Kayiry",
            "Kazachinsko-Lenskiy Rayon",
            "Kazachinskoye",
            "Kazanka",
            "Kehychivka",
            "Kerch",
            "Kerchens'ka Mis'ka Rada",
            "Kharkiv",
            "Khartsyz’k",
            "Kherson",
            "Khmel’nyts’kyy Rayon",
            "Khmelnytskyi",
            "Khmilnyk",
            "Khodaky",
            "Khodoriv",
            "Kholmovka",
            "Kholmy",
            "Khomutovo",
            "Khorol",
            "Khorosheve",
            "Khoroshiv",
            "Khoroshiv Raion",
            "Khorostkiv",
            "Khotiv",
            "Khotyn",
            "Khrebtovaya",
            "Khrystynivka",
            "Khudoyelanskoye",
            "Khust",
            "Khust Raion",
            "Khusts’ka Mis’krada",
            "Khuzhir",
            "Khymchyn",
            "Khyriv",
            "Kiliya",
            "Kimil’tey",
            "Kirensk",
            "Kirenskiy Rayon",
            "Kirove",
            "Kirove",
            "Kirovo",
            "Kirovohrads’ka Mis’krada",
            "Kirovs’k",
            "Kirovs’ka Mis’krada",
            "Kirovske Raion",
            "Kirovskoye",
            "Kitoy",
            "Kivertsi",
            "Kivsharivka",
            "Klavdiyevo-Tarasove",
            "Klembivka",
            "Klenovyy",
            "Klepinino",
            "Klevan",
            "Klishkivtsi",
            "Knyazhichi",
            "Kobeliaky",
            "Kobleve",
            "Kobyzhcha",
            "Kochetok",
            "Kodra",
            "Kodyma",
            "Kodyms’kyy Rayon",
            "Koktebel",
            "Kol’chugino",
            "Kol’tsovo",
            "Kolochava",
            "Kolodenka",
            "Kolodeznoye",
            "Kolomak",
            "Kolomyia",
            "Kolomyys’kyy Rayon",
            "Koloniya Zastav’ye",
            "Koloski",
            "Komarno",
            "Kompaniyivka",
            "Komsomol’skoye",
            "Komysh-Zorya",
            "Komyshany",
            "Komyshuvakha",
            "Kondratyevo",
            "Kononenki",
            "Konotop",
            "Konstantinovka",
            "Kopashnovo",
            "Kopayhorod",
            "Kopychyntsi",
            "Korchak",
            "Koreiz",
            "Korets",
            "Kormovoye",
            "Korniyivka",
            "Kornyn",
            "Kornyn",
            "Korolevo",
            "Korop",
            "Korosten",
            "Korosten’",
            "Korostyshiv",
            "Korostyshiv",
            "Korostyshiv Raion",
            "Korotych",
            "Korsun-Shevchenkivskyi",
            "Koryukivka",
            "Kosiv",
            "Kosivs’kyy Rayon",
            "Kosmach",
            "Koson’",
            "Kostiantynivka Raion",
            "Kostochkovka",
            "Kostopil’",
            "Kostryzhivka",
            "Kostyantynivka",
            "Kostyantynivka",
            "Kostyantynivka",
            "Kotel’nikovo",
            "Kotelva",
            "Kotik",
            "Kotsyubyns’ke",
            "Kovel",
            "Kovel’s’ka Mis’krada",
            "Kovyl’noye",
            "Kozachi Laheri",
            "Kozelets’",
            "Kozelshchyna",
            "Kozhanka",
            "Kozova",
            "Kozyatyn",
            "Kramators’k",
            "Krasna",
            "Krasna Zor'ka",
            "Krasnaya Polyana",
            "Krasne",
            "Krasne",
            "Krasnoarmeyskoye",
            "Krasnoflotskoye",
            "Krasnogorka",
            "Krasnogvardeyskoye",
            "Krasnohrad",
            "Krasnohvardiiske Raion",
            "Krasnokamenka",
            "Krasnokuts’k",
            "Krasnolesye",
            "Krasnopavlivka",
            "Krasnoperekops’k",
            "Krasnoperekopsk Raion",
            "Krasnopillya",
            "Krasnosilka",
            "Krasnotorka",
            "Krasnoyarskoye",
            "Krasnoye",
            "Krasnoznamenka",
            "Krasnyi Mak",
            "Krasnyy Kut",
            "Krasnyy Luch",
            "Krasyatychi",
            "Krasyliv",
            "Kratova Govtva",
            "Krayneye",
            "Kremenchuk",
            "Kremenets",
            "Kremidivka",
            "Kreminna",
            "Krest’yanovka",
            "Krinichnaya",
            "Krinichnoye",
            "Kripens’kyy",
            "Kriukivschina",
            "Krolevets’",
            "Kropotkin",
            "Kropyvnytskyi",
            "Krukovskiy Raion",
            "Kruty",
            "Krymka",
            "Krymskaya Roza",
            "Krymskoye",
            "Krynychky",
            "Kryva",
            "Kryva Hora",
            "Kryve Ozero",
            "Kryvoriz’ka Mis’krada",
            "Kryvyi Rih",
            "Kryzhanivka",
            "Kryzhopil’",
            "Kucherivka",
            "Kuchurhan",
            "Kuibyshevo",
            "Kukushkino",
            "Kulevcha",
            "Kultuk",
            "Kulykiv",
            "Kulykivka",
            "Kulynychi",
            "Kupech",
            "Kupjansk",
            "Kurakhovo",
            "Kurisove",
            "Kurskoye",
            "Kushnytsya",
            "Kushuhum",
            "Kuteynykove",
            "Kutulik",
            "Kuty",
            "Kuybysheve",
            "Kuytun",
            "Kuytunskiy Rayon",
            "Kvasy",
            "Kvitneve",
            "Kvitok",
            "Kyiv",
            "Kyrykivka",
            "Kyrylivka",
            "Kyyevo-Svyatoshyns’kyy Rayon",
            "L’govskoye",
            "Ladan",
            "Ladyzhyn",
            "Lanchyn",
            "Landari",
            "Lani",
            "Lanivtsi",
            "Lasky",
            "Lazarivka",
            "Lazeshchyna",
            "Lazi",
            "Lazurne",
            "Lebedyn",
            "Lebedyn",
            "Lekarstvennoye",
            "Lenin District",
            "Lenina",
            "Lenine Raion",
            "Lenino",
            "Leninskoye",
            "Lesnovka",
            "Lesogorsk",
            "Letychiv",
            "Levadki",
            "Lisogirka",
            "Lisovi Sorochyntsi",
            "Listvennoye",
            "Listvyanka",
            "Litvinenkovo",
            "Lityn",
            "Lityns’kyy Rayon",
            "Liubar",
            "Liubar",
            "Liuboml",
            "Liutizh",
            "Livadia",
            "Lobanovo",
            "Lokachi",
            "Lokhvytsia",
            "Lopatyn",
            "Lopukhiv",
            "Lozno-Oleksandrivka",
            "Lozova",
            "Lozovoye",
            "Lozuvatka",
            "Lubny",
            "Luchistoye",
            "Luganskoye",
            "Lugovoye",
            "Luhans’ka Mis’krada",
            "Luhans’ke",
            "Luhansk",
            "Luhyny",
            "Luhyny",
            "Luhyny Raion",
            "Lukiv",
            "Lutovynivka",
            "Lutsk",
            "Lutuhyne",
            "Lutuhyns’kyy Rayon",
            "Luzhany",
            "Lviv",
            "Lykhivka",
            "Lyman",
            "Lyman",
            "Lyman Raion",
            "Lymanske",
            "Lypcha",
            "Lypnyazhka",
            "Lypova Dolyna",
            "Lypovets’kyy Rayon",
            "Lysa Hora",
            "Lysianka",
            "Lysychans’k",
            "Lysychovo",
            "Lyubashivka",
            "Lyubech",
            "Lyubeshivs’kyy Rayon",
            "Lyuboml’s’kyy Rayon",
            "Lyubotyn",
            "Lyubymivka",
            "Magazinka",
            "Magistral’nyy",
            "Mahdalynivka",
            "Mahdalynivs’kyy Rayon",
            "Makariv",
            "Makariv Raion",
            "Makariv Yar",
            "Makiv",
            "Makiyivka",
            "Makiyivs’ka Mis’krada",
            "Mala Bilozerka",
            "Mala Danylivka",
            "Mala Rudka",
            "Mala Tokmachka",
            "Mala Vyska",
            "Malen’koye",
            "Malokakhovka",
            "Malokaterynivka",
            "Malorechenskoye",
            "Malovyskivs’kyy Rayon",
            "Maly Mayak",
            "Malyn",
            "Malyn",
            "Malyn Raion",
            "Malynivka",
            "Mama",
            "Mamakan",
            "Mamsko-Chuyskiy Rayon",
            "Manchenky",
            "Manevychi",
            "Manevyts’kyy Rayon",
            "Manhush",
            "Mankivka",
            "Manyava",
            "Manzurka",
            "Mar'yinka",
            "Mar’yanovka",
            "Marchenki",
            "Marfovka",
            "Marhanets’",
            "Mariupol",
            "Markivka",
            "Markivka",
            "Markova",
            "Markova",
            "Markovo",
            "Marshintsy",
            "Maryevka",
            "Maschiwka",
            "Maslovo",
            "Massandra",
            "Matviyivka",
            "Mayaky",
            "Mayskoye",
            "Mazanka",
            "Medenychi",
            "Medvedevka",
            "Medvedevo",
            "Medzhybizh",
            "Meget",
            "Mel’nichnoye",
            "Melekyne",
            "Melioratyvne",
            "Melitopol",
            "Melitopol’s’kyy Rayon",
            "Mena",
            "Mens’kyy Rayon",
            "Merefa",
            "Mezenivka",
            "Mezhova",
            "Mezhvodnoye",
            "Michurinskoye",
            "Mikhaylovka",
            "Mikulintsy",
            "Millerovo",
            "Milove",
            "Mirnovka",
            "Mirnoye",
            "Mirny",
            "Mirovka",
            "Mishelevka",
            "Mitrofanovka",
            "Mityayevo",
            "Miusyns’k",
            "Mizhhirya",
            "Mizhhirya Raion",
            "Mizoch",
            "Mliiv",
            "Mlyniv",
            "Mlynivs’kyy Rayon",
            "Mohyliv-Podilskyi",
            "Molochnoye",
            "Molodiya",
            "Molodizhne",
            "Molodohvardiys’k",
            "Molodyozhnoye",
            "Monastyryshche",
            "Morozivka",
            "Morshyn",
            "Morskoye",
            "Moshny",
            "Mospyne",
            "Mostys'ka",
            "Motovylivka",
            "Mugun",
            "Mukacheve",
            "Mukachevo Raion",
            "Murafa",
            "Muromskoye",
            "Murovane",
            "Murovani Kurylivtsi",
            "Muskatnoye",
            "Muzhiyevo",
            "Myhiya",
            "Myhove",
            "Mykhaylivka",
            "Mykhaylivs’kyy Rayon",
            "Mykolaiv",
            "Mykolayiv",
            "Mykolayivka",
            "Mykolayivs’kyy Rayon",
            "Mynay",
            "Myrhorod",
            "Myrnohrad",
            "Myronivka",
            "Myropil",
            "Mysovoe",
            "Nadvirna",
            "Nadvirnyans’kyy Rayon",
            "Nakhimovskiy rayon",
            "Narkevychi",
            "Narodychi",
            "Narodychi",
            "Narodychi Raion",
            "Nasypnoe",
            "Navaria",
            "Naydenovka",
            "Nebyliv",
            "Nedoharky",
            "Nedryhayliv",
            "Nekrasovka",
            "Nekrasovo",
            "Nelipyno",
            "Nemyriv",
            "Nemyryntsi",
            "Neresnytsya",
            "Netishyn",
            "Nevyts’ke",
            "Nikita",
            "Nikolayevka",
            "Nikolske Raion",
            "Nikopol",
            "Nizhnegorskiy",
            "Nizhnegorskiy rayon",
            "Nizhneilimskiy Rayon",
            "Nizhneudinsk",
            "Nizhneudinskiy Rayon",
            "Nizhyn",
            "Nosivka",
            "Nova Borova",
            "Nova Borova",
            "Nova Chortoriia",
            "Nova Kakhovka",
            "Nova Mayachka",
            "Nova Odesa",
            "Nova Praha",
            "Nova Pryluka",
            "Nova Ushytsya",
            "Nova Vodolaha",
            "Novaya Igirma",
            "Nove Davydkovo",
            "Nove-Misto",
            "Novhorod-Sivers’kyy",
            "Novhorodka",
            "Novi Bilokorovychi",
            "Novi Bilokorovychi",
            "Novi Petrivtsi",
            "Novi Sanzhary",
            "Novi Troyany",
            "Novi Vorobyi",
            "Novoandreyevka",
            "Novoarkhanhel’s’k",
            "Novoazovs'k",
            "Novobiryusinskiy",
            "Novobohdanivka",
            "Novodnistrovs’k",
            "Novodonets’ke",
            "Novoestoniya",
            "Novofedorivka",
            "Novofedorovka",
            "Novogrigoryevka",
            "Novohrad-Volynskyi",
            "Novohrad-Volynskyi",
            "Novoivanovka",
            "Novokrymskoye",
            "Novomoskovs’k",
            "Novomoskovsk Raion",
            "Novomykolayivka",
            "Novomykolayivka",
            "Novomyrhorod",
            "Novonikolayevka",
            "Novonukutskiy",
            "Novooleksiyivka",
            "Novoozerianka",
            "Novoozyornoye",
            "Novopavlovka",
            "Novopokrovka",
            "Novopokrovka",
            "Novopokrovka",
            "Novopskov",
            "Novosel’skoye",
            "Novoselovskoye",
            "Novoselytsya",
            "Novoselytsya",
            "Novostepnoye",
            "Novosyolovka",
            "Novoukrayinka",
            "Novovasylivka",
            "Novovolyns’k",
            "Novovorontsovka",
            "Novoyavorivs'k",
            "Novozhilovka",
            "Novy Svet",
            "Novyi Yarychiv",
            "Novytsya",
            "Novyy Buh",
            "Novyy Starodub",
            "Novyy Svit",
            "Nuyno",
            "Nyrkiv",
            "Nyvky",
            "Nyzhni Petrivtsi",
            "Nyzhni Sirohozy",
            "Nyzhnya Duvanka",
            "Nyzhnya Krynka",
            "Nyzhnye Selyshche",
            "Nyzy",
            "Obertyn",
            "Obodivka",
            "Obroshyne",
            "Obukhiv",
            "Obukhiv Raion",
            "Obukhivs’ka Mis’krada",
            "Ochakiv",
            "Odariukivka",
            "Odesa",
            "Okhotnikovo",
            "Okhotskoye",
            "Okhtyrka",
            "Okny",
            "Oktyabr'skoye",
            "Oktyabr’skoye",
            "Okunevka",
            "Ol'shanskoye",
            "Ol’khonskiy Rayon",
            "Olefirshchina",
            "Oleksandrivka",
            "Oleksandrivka",
            "Oleksandrivka",
            "Oleksandriya",
            "Oleksandriys’ke",
            "Olenevka",
            "Oleshnyk",
            "Olesko",
            "Olevs’k",
            "Olevsk",
            "Olyka",
            "Olyshivka",
            "Onatski",
            "Onokivtsi",
            "Onufriyivka",
            "Opishnia",
            "Orativ",
            "Ordzhonikidze",
            "Ordzhonikidze",
            "Orekhovo",
            "Orikhiv",
            "Orlinoye",
            "Orlovskoye",
            "Orzhiv",
            "Orzhytsia",
            "Osa",
            "Ostanino",
            "Oster",
            "Ostroh",
            "Ostrovskoye",
            "Ostroz’ka Mis’krada",
            "Osypenko",
            "Otyniya",
            "Ovidiopol",
            "Ovruch",
            "Ovruch",
            "Ovruch Raion",
            "Oyëk",
            "Ozerna",
            "Ozerne",
            "Ozerne",
            "Ozerne",
            "P'yatypill'ya",
            "Pad’ Mel’nichnaya",
            "Pakharevka",
            "Pantayivka",
            "Panyutyne",
            "Partenit",
            "Partizanskoye",
            "Partizany",
            "Partyzans’ke",
            "Partyzany",
            "Parutyne",
            "Pasichna",
            "Pavlivka",
            "Pavlivka",
            "Pavlohrad",
            "Pavlysh",
            "Pavoloch",
            "Pechenizhyn",
            "Pechersk Raion",
            "Perechyn",
            "Perechyn Raion",
            "Perehonivka",
            "Pereiaslav-Khmelnytskyi",
            "Pereiaslav-Khmelnytskyi Raion",
            "Peremyshlyany",
            "Peresichna",
            "Pereval'noye",
            "Pereval’s’k",
            "Perfilovo",
            "Perovo",
            "Pershotravensk",
            "Pershotravneve",
            "Pershotravneve",
            "Pervomaiskyi Raion",
            "Pervomays’k",
            "Pervomays’k",
            "Pervomays’kyy",
            "Pervomayskiy rayon",
            "Pervomayskoye",
            "Peschana",
            "Peschanoye",
            "Petranka",
            "Petrenki",
            "Petrikov",
            "Petrivs’ke",
            "Petropavlivka",
            "Petrove",
            "Petrovka",
            "Petrykivka",
            "Petrykivs’kyy Rayon",
            "Piatykhatky",
            "Pidbuzh",
            "Pidhaytsi",
            "Pidhirtsi",
            "Pidhorodna",
            "Pidhorodne",
            "Pidkamin’",
            "Pidvolochysk",
            "Pidvynohradiv",
            "Pionerskoye",
            "Pisarivshchina",
            "Pishcha",
            "Pishchiv",
            "Piskivka",
            "Pivdenne",
            "Pivovarikha",
            "Plakhtiyivka",
            "Plodovoye",
            "Pnikut",
            "Pniv",
            "Pobednoye",
            "Pobugskoye",
            "Pochaiv",
            "Pochetnoye",
            "Podil’s’k",
            "Podil’s’kyy Rayon",
            "Podilsk Raion",
            "Pohreby",
            "Pohrebyshche",
            "Pohrebyshchens’kyy Rayon",
            "Pokosnoye",
            "Pokotylivka",
            "Pokrovsk",
            "Pokrovsk Raion",
            "Polianka",
            "Polohy",
            "Polonne",
            "Poltava",
            "Poltavka",
            "Polyanka",
            "Polyushko",
            "Pomichna",
            "Pomoriany",
            "Poninka",
            "Popasna",
            "Popilnia",
            "Popilnia",
            "Popilnia Raion",
            "Popivka",
            "Porohy",
            "Poroshkovo",
            "Poshtove",
            "Potelych",
            "Potiivka",
            "Pozharskoye",
            "Pravda",
            "Preobrazhenka",
            "Primorskiy",
            "Priozyornoye",
            "Privetnoye",
            "Prolisky",
            "Proni",
            "Prostornoye",
            "Prudovoye",
            "Prudy",
            "Prudyanka",
            "Pryazovske Raion",
            "Prykolotne",
            "Pryluky",
            "Prymors’k",
            "Prymorsk Raion",
            "Pryshyb",
            "Pryvillya",
            "Pryyutivka",
            "Pshenichnoye",
            "Pukhivka",
            "Pulyny Raion",
            "Pushkino",
            "Pustomyty",
            "Putyla Raion",
            "Putyvl’",
            "Pyatikhatka",
            "Pylypets",
            "Pyriatyn",
            "Radcha",
            "Radens’k",
            "Radomyshl",
            "Radomyshl",
            "Radomyshl Raion",
            "Radyvyliv",
            "Rakhiv",
            "Rakhiv Raion",
            "Rakiv Lis",
            "Rakoshyno",
            "Ralivka",
            "Ratne",
            "Ratnivs’kyy Rayon",
            "Rava-Rus’ka",
            "Razdol’nenskiy rayon",
            "Razdol’noye",
            "Rechka",
            "Reni",
            "Reshetylivka",
            "Richky",
            "Ripky",
            "Rivne",
            "Rivne",
            "Rivnens’ka Mis’krada",
            "Rodatychi",
            "Rodnikovo",
            "Rodyns’ke",
            "Rohachi",
            "Rohatyn",
            "Rohatyns’kyy Rayon",
            "Rokosovo",
            "Rokytne",
            "Rokytne",
            "Rokytne Raion",
            "Romaniv",
            "Romaniv",
            "Romashkino",
            "Romens’ka Mis’krada",
            "Romny",
            "Romodan",
            "Ropcha",
            "Roshchino",
            "Roskoshnoye",
            "Roskoshnyy",
            "Roven’ky",
            "Rovnoye",
            "Rozdil",
            "Rozdil’na",
            "Rozhniativ",
            "Rozhniv",
            "Rozhnyativs’kyy Rayon",
            "Rozhyshche",
            "Rozivka",
            "Rozivs’kyy Rayon",
            "Rozkishne",
            "Rozsypne",
            "Rozvadiv",
            "Rubizhans’ka Mis’krada",
            "Rubizhne",
            "Ruch’i",
            "Rudky",
            "Rudne",
            "Rudnogorsk",
            "Rusakovka",
            "Ruska Poliana",
            "Ruzhyn",
            "Ruzhyn (settlement)",
            "Rybach'e",
            "Rybakivka",
            "Rykhtychi",
            "Rzhyshchiv",
            "Sadovoye",
            "Sadzhavka",
            "Sakharnaya Golovka",
            "Sakhnovshchyna",
            "Saki",
            "Sakskiy rayon",
            "Salhany",
            "Sambir",
            "Sarata",
            "Sarny",
            "Sartana",
            "Sary-Bash",
            "Savran",
            "Savyntsi",
            "Sayansk",
            "Schyschaky",
            "Sedniv",
            "Selydivs’ka Mis’krada",
            "Selydove",
            "Semenivka",
            "Semenivka",
            "Semisotka",
            "Semypolky",
            "Sencha",
            "Senokosnoye",
            "Serebriya",
            "Serebryanka",
            "Serebryanka",
            "Serednye",
            "Serednye Vodyane",
            "Seredyna-Buda",
            "Serhiyivka",
            "Serpneve",
            "Sevastopol",
            "Shabo",
            "Shakhtars’k",
            "Shalyhyne",
            "Shamanka",
            "Sharhorod",
            "Shats’k",
            "Shats’kyy Rayon",
            "Shchastya",
            "Shchebetovka",
            "Shcherbynivka",
            "Shchors",
            "Shchyolkino",
            "Shchyrets",
            "Sheberta",
            "Shelekhov",
            "Shelkovichnoye",
            "Shepetivka",
            "Sheragul",
            "Sheshory",
            "Shestakovo",
            "Shevchenkivs’kyy Rayon",
            "Shevchenkove",
            "Shevchenkove",
            "Shevchenkove",
            "Shirokoye",
            "Shitkino",
            "Shklo",
            "Shkol’noye",
            "Sholokhove",
            "Shostka",
            "Shpola",
            "Shtormovoye",
            "Shturmovoye",
            "Shumsk",
            "Shumskiy",
            "Shypyntsi",
            "Shyroke",
            "Shyroke",
            "Shyroke Raion",
            "Shyryayeve",
            "Silets’",
            "Simeiz",
            "Simeykyne",
            "Simferopol",
            "Simferopol Raion",
            "Sinitsyno",
            "Sivers’k",
            "Sizovka",
            "Skadovs’k",
            "Skala-Podil’s’ka",
            "Skalat",
            "Skalistoye",
            "Skelivka",
            "Skhidnytsya",
            "Skole",
            "Skvortsovo",
            "Skvyra",
            "Skvyrs’kyy Rayon",
            "Slatyne",
            "Slavnoye",
            "Slavske",
            "Slavuta",
            "Slavutych",
            "Slavyanskoye",
            "Slobidka",
            "Sloboda",
            "Slobozhans’ke",
            "Slov`yanoserbsk",
            "Slovechne",
            "Sloviansk",
            "Sloviansk Raion",
            "Slyudyanka",
            "Slyudyanskiy Rayon",
            "Smila",
            "Smolenshchina",
            "Smolovivshchina",
            "Smotrych",
            "Smyga",
            "Sniatyn",
            "Snihurivka",
            "Snizhne",
            "Snovs'kyy Rayon",
            "Snyatyns’kyy Rayon",
            "Sobolivka",
            "Sofiivka",
            "Sofiyivka",
            "Sofiyivska Borschagivka",
            "Sokal’",
            "Sokhatska Balka",
            "Sokolinoye",
            "Sokyryany",
            "Soledar",
            "Solnechnaya Dolina",
            "Solnechniy",
            "Solnechnogorskoye",
            "Solnechnoye",
            "Solomianka Rayon",
            "Solone",
            "Solone Raion",
            "Solonka",
            "Solonytsivka",
            "Solotvyn",
            "Solotvyno",
            "Sorokyne",
            "Sorokyns'kyi Rayon",
            "Sosnytsya",
            "Sovetskiy",
            "Sovietskyi Raion",
            "Sovkhoznoye",
            "Spas",
            "Spas’ke",
            "Sredniy",
            "Sribne",
            "Stakhanovka",
            "Stal’noye",
            "Stanislav",
            "Stanytsya Luhans’ka",
            "Stara Syniava",
            "Stara Vyzhivka",
            "Stari Bohorodchany",
            "Stari Kuty",
            "Starobesheve",
            "Starobil’s’k",
            "Starokostiantyniv",
            "Starokozache",
            "Staroye Selo",
            "Stary Krym",
            "Starychi",
            "Staryy Dobrotvir",
            "Staryy Krym",
            "Staryy Merchyk",
            "Staryy Saltiv",
            "Staryy Sambir",
            "Stavyshche",
            "Stavyshche",
            "Stayki",
            "Stebliv",
            "Stebnyk",
            "Stepanivka",
            "Stepanivka",
            "Stepnoye",
            "Stepove",
            "Stetseva",
            "Stizhkivs’ke",
            "Stolbovoye",
            "Storozhnytsya",
            "Strabychovo",
            "Strogonovka",
            "Stryi",
            "Studenok",
            "Subottsi",
            "Sudak",
            "Sudivka",
            "Sudova Vyshnya",
            "Sukhovolya",
            "Sumy",
            "Surs’ko-Mykhaylivka",
            "Susanino",
            "Sutysky",
            "Suvorovo",
            "Suvorovskoye",
            "Svaliava Raion",
            "Svalyava",
            "Svarychiv",
            "Svatove",
            "Sverdlovs’k",
            "Sverdlovs’ka Mis’krada",
            "Svetlodarsk",
            "Svetloye",
            "Svirsk",
            "Svitlovods’k",
            "Svityaz’",
            "Svyatogorsk",
            "Svyatoshyns’kyy Rayon",
            "Syedove",
            "Syevyerodonets’k",
            "Synel’nykove",
            "Synevyr",
            "Synevyrska Poliana",
            "Tabachnoye",
            "Tabory",
            "Talne",
            "Tankovoye",
            "Tarakaniv",
            "Taraschanka",
            "Tarashcha",
            "Tarutyne",
            "Tatarbunary",
            "Tatariv",
            "Tayirove",
            "Tayshet",
            "Tayshetskiy Rayon",
            "Tayturka",
            "Tel’ma",
            "Temriuk",
            "Tenistoye",
            "Teple",
            "Teplodar",
            "Teplovka",
            "Teplyk",
            "Terebovlya",
            "Ternopil",
            "Ternovka",
            "Ternuvate",
            "Terny",
            "Terpinnya",
            "Tetiiv",
            "Tiachiv Raion",
            "Tlumach",
            "Tlumats’kyy Rayon",
            "Tokarevo",
            "Tokmak",
            "Tokmak Raion",
            "Tomakivka",
            "Tomashpil’",
            "Topory",
            "Topory",
            "Toretsk",
            "Torkanivka",
            "Toshkivka",
            "Tovste",
            "Tr’okhizbenka",
            "Travneve",
            "Trebukhiv",
            "Troiany",
            "Trostyanets’",
            "Trudovoye",
            "Truskavets’",
            "Trypillia",
            "Tsarychanka",
            "Tsarychans’kyy Rayon",
            "Tselinnoye",
            "Tsibulev",
            "Tsvetochnoye",
            "Tsvitkove",
            "Tsybli",
            "Tsybulevka",
            "Tsyurupyns’k",
            "Tukhlia",
            "Tulchyn",
            "Tulun",
            "Tulunskiy Rayon",
            "Tulyushka",
            "Turbiv",
            "Turgenevka",
            "Turka",
            "Tyachiv",
            "Tymoshivka",
            "Tynne",
            "Tyret’ Pervaya",
            "Tyshkivka",
            "Tysmenychany",
            "Tysmenyts’kyy Rayon",
            "Tysmenytsya",
            "Tyvriv",
            "Uglovoye",
            "Uhniv",
            "Uhryniv",
            "Uk",
            "Ukrainka",
            "Ukrainka",
            "Ukromnoye",
            "Ulaniv",
            "Ulychne",
            "Uman",
            "Uralo-Kavkaz",
            "Urik",
            "Urozhaynoye",
            "Urzuf",
            "Usatove",
            "Usol’skiy Rayon",
            "Usol’ye-Sibirskoye",
            "Uspenka",
            "Ust’-Ilimsk",
            "Ust’-Kut",
            "Ust’-Kutskiy Rayon",
            "Ust’-Ordynskiy",
            "Ust’-Uda",
            "Ust’-Udinskiy Rayon",
            "Ustynivka",
            "Utkivka",
            "Uvarovka",
            "Uvarovo",
            "Uyutnoye",
            "Uzhgorod",
            "Uzhhorod Raion",
            "Uzyn",
            "Valky",
            "Vapnyarka",
            "Varash",
            "Varva",
            "Vary",
            "Vashkivtsi",
            "Vasilivka",
            "Vasilyevka",
            "Vasylivka",
            "Vasylkiv",
            "Vasylkiv Raion",
            "Vasyshcheve",
            "Vatutine",
            "Velika Rudka",
            "Veliki Budishcha",
            "Velikiye Borki",
            "Velyka Bahachka",
            "Velyka Bilozerka",
            "Velyka Dymerka",
            "Velyka Lepetykha",
            "Velyka Mykhaylivka",
            "Velyka Oleksandrivka",
            "Velyka Pysarivka",
            "Velyki Berehy",
            "Velyki Kopani",
            "Velyki Korovyntsi",
            "Velyki Mezhyrichi",
            "Velyki Sorochyntsi",
            "Velykobilozers’kyy Rayon",
            "Velykoploske",
            "Velykyi Bereznyi",
            "Velykyi Bereznyi Raion",
            "Velykyy Burluk",
            "Velykyy Klyuchiv",
            "Vendychany",
            "Verbivka",
            "Verblyany",
            "Verchnia Rozhanka",
            "Verenchanka",
            "Veresayevo",
            "Veresna",
            "Verkhn’odniprovs’k",
            "Verkhnesadovoye",
            "Verkhniachka",
            "Verkhnie Synevydne",
            "Verkhniy Rohachyk",
            "Verkhniy Yasenov",
            "Verkhnya Syrovatka",
            "Verkhnyaya Belka",
            "Verkhorechye",
            "Verkhovyna",
            "Vesele",
            "Vesele",
            "Veselivka",
            "Veselovka",
            "Veselynove",
            "Vidim",
            "Vikhorevka",
            "Vil’ne",
            "Vilino",
            "Vilniansk Raion",
            "Vinnitskiy Rayon",
            "Vinnitskoye",
            "Vinnytsia",
            "Vinogradnoye",
            "Vinogradovo",
            "Virlya",
            "Vishennoye",
            "Vishnyovka",
            "Viytivka",
            "Vladimirovka",
            "Vladislavovka",
            "Vodiana Balka",
            "Vodyane",
            "Voinka",
            "Vol’noye",
            "Volnovakha",
            "Volodarka",
            "Volodymyr-Volynskyi",
            "Volodymyrets’",
            "Voloka",
            "Volovets",
            "Volovets Raion",
            "Vorobyovo",
            "Vorokhta",
            "Voronkov",
            "Voronovytsya",
            "Vorozhba",
            "Vorzel’",
            "Voskhod",
            "Vovchans’k",
            "Voykovo",
            "Voyutychi",
            "Voznesenka",
            "Voznesensk",
            "Voznesenskiy",
            "Vradiyivka",
            "Vuhlehirs’k",
            "Vvedenka",
            "Vylkove",
            "Vylok",
            "Vynohradiv",
            "Vynohradiv Raion",
            "Vynohradivka",
            "Vynohradove",
            "Vyshchetarasivka",
            "Vyshgorodskiy Rayon",
            "Vyshhorod",
            "Vyshkovo",
            "Vyshneve",
            "Vyshneve",
            "Vysokopillya",
            "Vysokyy",
            "Vyzhnytsya",
            "Yablunets",
            "Yabluniv",
            "Yablunytsya",
            "Yahil’nytsya",
            "Yahotyn",
            "Yahotyns’kyy Rayon",
            "Yakymivka",
            "Yakymivka Raion",
            "Yalta",
            "Yalta",
            "Yamnytsya",
            "Yampil’",
            "Yampil’",
            "Yantal’",
            "Yantarnoye",
            "Yaremchans’ks Mis’krada",
            "Yaremche",
            "Yarkoye",
            "Yarkoye Pole",
            "Yarmolyntsi",
            "Yarun",
            "Yasinya",
            "Yasnopolyanskoye",
            "Yasynuvata",
            "Yasynuvata Raion",
            "Yavoriv",
            "Yedogon",
            "Yelantsy",
            "Yelyzavethradka",
            "Yemelyanovka",
            "Yemil’chyne",
            "Yemilchyne",
            "Yenakiyeve",
            "Yerbogachen",
            "Yerky",
            "Yermakovo",
            "Yesman’",
            "Yevpatoriya",
            "Yizhivtsi",
            "Yur”yivs’kyy Rayon",
            "Yurkivka",
            "Yurty",
            "Yuzhne",
            "Yuzhnoukrains'k",
            "Z·hurivs’kyy Rayon",
            "Zabaro-Davidivka",
            "Zabolotiv",
            "Zachepylivka",
            "Zadovga",
            "Zagnitkiv",
            "Zahvizdya",
            "Zakharivka",
            "Zakrinichchia",
            "Zakupne",
            "Zalari",
            "Zalarinskiy Rayon",
            "Zalesnoye",
            "Zalesye",
            "Zalishchyky",
            "Zalissia",
            "Zaliznia",
            "Zaliznychne",
            "Zaliznychne",
            "Zaliznyy Port",
            "Zaliztsi",
            "Zaluzhne",
            "Zaozyornoye",
            "Zapadnia",
            "Zaporiz’ka Mis’krada",
            "Zaporizhia",
            "Zarechnoye",
            "Zarichchia",
            "Zarichchya",
            "Zarichne",
            "Zarudintsi",
            "Zastavna",
            "Zatoka",
            "Zatyshshya",
            "Zavallya",
            "Zavet-Leninskiy",
            "Zavetnoye",
            "Zavodske",
            "Zbarazh",
            "Zboriv",
            "Zbranki",
            "Zdolbuniv",
            "Zdorovets",
            "Zdovbytsya",
            "Zelenogorskoye",
            "Zelyonoye",
            "Zemlyanichnoye",
            "Zernovoye",
            "Zgurovka",
            "Zhashkiv",
            "Zheleznodorozhnoye",
            "Zheleznodorozhnyy",
            "Zheleznogorsk-Ilimskiy",
            "Zhelyabovka",
            "Zhemchuzhina",
            "Zherdeli",
            "Zhigalovo",
            "Zhigalovskiy Rayon",
            "Zhitintsi",
            "Zhmerynka",
            "Zhovkva",
            "Zhovte",
            "Zhovti Vody",
            "Zhovtii Brid",
            "Zhupanivka",
            "Zhuravka",
            "Zhuravki",
            "Zhuravli",
            "Zhuravlyovka",
            "Zhurbintsi",
            "Zhvanets",
            "Zhydachiv",
            "Zhytomyr",
            "Zhytomyr",
            "Zhytomyr Raion",
            "Zima",
            "Zimino",
            "Ziminskiy Rayon",
            "Zinkiv",
            "Zlobichi",
            "Zlynka",
            "Zmiyiv",
            "Znomenka",
            "Zolochiv",
            "Zolochiv",
            "Zolotonosha",
            "Zolotoye Pole",
            "Zolotyy Potik",
            "Zoriane",
            "Zorkino",
            "Zorokiv",
            "Zorya",
            "Zoryns’k",
            "Zosimivka",
            "Zubivshchina",
            "Zuhres",
            "Zuya",
            "Zvenihorodka",
            "Zvëzdnyy",
            "Zvizdal",
            "Zybiny",
            "Zymohiria",
            "Бережани"
        ]
    },
    {
        "label": "United Arab Emirates",
        "code": "AE",
        "phone": "971",
        "cities": [
            "Abu Dhabi Island and Internal Islands City",
            "Abu Dhabi Municipality",
            "Adh Dhayd",
            "Ajman",
            "Ajman City",
            "Al Ain City",
            "Al Ain Municipality",
            "Al Batayih",
            "Al Dhafra",
            "Al Fujairah City",
            "Al Fujairah Municipality",
            "Al Hamriyah",
            "Al Madam",
            "Al Shamkhah City",
            "Ar Ruways",
            "Bani Yas City",
            "Dhaid",
            "Dibba Al Fujairah Municipality",
            "Dibba Al Hesn",
            "Dibba Al-Fujairah",
            "Dibba Al-Hisn",
            "Dubai",
            "Kalba",
            "Khalifah A City",
            "Khawr Fakkān",
            "Khor Fakkan",
            "Manama",
            "Masfout",
            "Milehah",
            "Murbaḩ",
            "Musaffah",
            "Muzayri‘",
            "Ras Al Khaimah",
            "Ras Al Khaimah City",
            "Reef Al Fujairah City",
            "Sharjah",
            "Umm AL Quwain",
            "Umm Al Quwain City",
            "Zayed City"
        ]
    },
    {
        "label": "United Kingdom",
        "code": "GB",
        "phone": "44",
        "cities": [
            "Abbey Wood",
            "Abbots Bromley",
            "Abbots Langley",
            "Abbotskerswell",
            "Abbotts Ann",
            "Aberaeron",
            "Abercanaid",
            "Abercarn",
            "Aberchirder",
            "Abercynon",
            "Aberdare",
            "Aberdeen",
            "Aberdeen City",
            "Aberdeenshire",
            "Aberdour",
            "Aberfan",
            "Aberfeldy",
            "Aberford",
            "Abergavenny",
            "Abergele",
            "Aberkenfig",
            "Aberlady",
            "Abernethy",
            "Aberporth",
            "Abertillery",
            "Abertridwr",
            "Aberystwyth",
            "Abingdon",
            "Aboyne",
            "Abram",
            "Abridge",
            "Accrington",
            "Acklington",
            "Acle",
            "Acocks Green",
            "Acomb",
            "Acton",
            "Adderbury",
            "Addiebrownhill",
            "Addingham",
            "Addlestone",
            "Adlington",
            "Adwick le Street",
            "Ahoghill",
            "Airdrie",
            "Airmyn",
            "Airth",
            "Albrighton",
            "Alcester",
            "Alconbury",
            "Aldbourne",
            "Aldbrough",
            "Aldeburgh",
            "Alderbury",
            "Alderholt",
            "Alderley Edge",
            "Aldershot",
            "Aldford",
            "Aldridge",
            "Alexandria",
            "Alford",
            "Alford",
            "Alfreton",
            "Allanton",
            "Allhallows",
            "Alloa",
            "Almondbank",
            "Almondsbury",
            "Alness",
            "Alnwick",
            "Alresford",
            "Alrewas",
            "Alsager",
            "Alston",
            "Althorne",
            "Alton",
            "Altrincham",
            "Alva",
            "Alvechurch",
            "Alveley",
            "Alveston",
            "Alyth",
            "Amble",
            "Ambleside",
            "Ambrosden",
            "Amersham",
            "Amersham on the Hill",
            "Amesbury",
            "Amlwch",
            "Ammanford",
            "Ampthill",
            "Ancaster",
            "Andover",
            "Anglesey",
            "Angus",
            "Anna Valley",
            "Annahilt",
            "Annalong",
            "Annan",
            "Annfield Plain",
            "Anstey",
            "Anstruther",
            "Antrim",
            "Antrim and Newtownabbey",
            "Appleby",
            "Appleby-in-Westmorland",
            "Appledore",
            "Appleton Thorn",
            "Appley Bridge",
            "Arbroath",
            "Archway",
            "Ardersier",
            "Ardglass",
            "Ardingly",
            "Ardrishaig",
            "Ardrossan",
            "Ards and North Down",
            "Argyll and Bute",
            "Arlesey",
            "Armadale",
            "Armagh",
            "Armagh City Banbridge and Craigavon",
            "Armitage",
            "Armthorpe",
            "Arnold",
            "Arnside",
            "Arundel",
            "Ascot",
            "Asfordby",
            "Ash",
            "Ashbourne",
            "Ashburton",
            "Ashby de la Zouch",
            "Ashford",
            "Ashgill",
            "Ashill",
            "Ashington",
            "Ashtead",
            "Ashton in Makerfield",
            "Ashton Keynes",
            "Ashton-under-Lyne",
            "Ashurst",
            "Ashwell",
            "Askam in Furness",
            "Askern",
            "Aslockton",
            "Aspatria",
            "Aston",
            "Aston Clinton",
            "Aston-on-Trent",
            "Astwood Bank",
            "Atherstone",
            "Atherton",
            "Attleborough",
            "Atworth",
            "Auchinleck",
            "Auchterarder",
            "Auchtermuchty",
            "Auckley",
            "Audlem",
            "Audley",
            "Aveley",
            "Avening",
            "Aviemore",
            "Awsworth",
            "Axbridge",
            "Axminster",
            "Aylesbury",
            "Aylesford",
            "Aylesham",
            "Aylsham",
            "Ayr",
            "Babworth",
            "Backworth",
            "Bacton",
            "Bacup",
            "Badsey",
            "Badsworth",
            "Bagillt",
            "Bagshot",
            "Bagworth",
            "Baildon",
            "Bakewell",
            "Bala",
            "Balcombe",
            "Baldock",
            "Balerno",
            "Balfron",
            "Balintore",
            "Ballater",
            "Ballinamallard",
            "Ballingry",
            "Ballintoy Harbour",
            "Balloch",
            "Ballycastle",
            "Ballyclare",
            "Ballygowan",
            "Ballykelly",
            "Ballymena",
            "Ballymoney",
            "Ballynahinch",
            "Ballypatrick",
            "Ballywalter",
            "Balmedie",
            "Balmullo",
            "Balsall Common",
            "Balsham",
            "Bamburgh",
            "Bampton",
            "Banbridge",
            "Banbury",
            "Banchory",
            "Banff",
            "Bangor",
            "Bangor",
            "Banham",
            "Bankfoot",
            "Banknock",
            "Banks",
            "Bannockburn",
            "Banstead",
            "Banwell",
            "Bar Hill",
            "Barbican",
            "Bardney",
            "Bardsey",
            "Bargeddie",
            "Bargoed",
            "Barham",
            "Barking",
            "Barkisland",
            "Barlaston",
            "Barlborough",
            "Barlby",
            "Barlestone",
            "Barmby on the Marsh",
            "Barmouth",
            "Barnack",
            "Barnard Castle",
            "Barnburgh",
            "Barnet",
            "Barnetby le Wold",
            "Barnham",
            "Barnoldswick",
            "Barnsbury",
            "Barnsley",
            "Barnstaple",
            "Barnt Green",
            "Barnwood",
            "Barra",
            "Barrhead",
            "Barrow in Furness",
            "Barrow upon Humber",
            "Barrow upon Soar",
            "Barrowby",
            "Barrowford",
            "Barry",
            "Bartley Green",
            "Barton under Needwood",
            "Barton upon Humber",
            "Barton-le-Clay",
            "Baschurch",
            "Basford",
            "Basildon",
            "Basingstoke",
            "Baslow",
            "Bassingbourn",
            "Bassingham",
            "Baston",
            "Bath",
            "Bath and North East Somerset",
            "Bathgate",
            "Batley",
            "Battersea",
            "Battle",
            "Bawtry",
            "Bay Horse",
            "Bayston Hill",
            "Bayswater",
            "Beaconsfield",
            "Beadnell",
            "Beaminster",
            "Bearsden",
            "Beauly",
            "Beaumaris",
            "Bebington",
            "Beccles",
            "Beckenham",
            "Beckingham",
            "Becontree",
            "Bedale",
            "Beddau",
            "Bedford",
            "Bedlington",
            "Bedlinog",
            "Bedwas",
            "Bedworth",
            "Beeford",
            "Beighton",
            "Beith",
            "Belbroughton",
            "Belfast",
            "Belford",
            "Bellaghy",
            "Bellingham",
            "Bellsbank",
            "Bellshill",
            "Belmont",
            "Belper",
            "Belsize Park",
            "Belton",
            "Belvedere",
            "Bembridge",
            "Bempton",
            "Benbecula",
            "Benllech",
            "Benson",
            "Bentley",
            "Benwell",
            "Bere Alston",
            "Bere Regis",
            "Berkeley",
            "Berkhamsted",
            "Berwick-Upon-Tweed",
            "Bethesda",
            "Bethnal Green",
            "Betley",
            "Betws",
            "Betws-y-Coed",
            "Beverley",
            "Bewbush",
            "Bewdley",
            "Bexhill-on-Sea",
            "Bexley",
            "Bicester",
            "Bicknacre",
            "Bicton",
            "Biddenden",
            "Biddestone",
            "Biddulph",
            "Bideford",
            "Bidford-on-Avon",
            "Biggar",
            "Biggin Hill",
            "Biggleswade",
            "Bildeston",
            "Billericay",
            "Billingborough",
            "Billinge",
            "Billingham",
            "Billinghay",
            "Billingshurst",
            "Billington",
            "Bilsdale",
            "Bilsthorpe",
            "Bilston",
            "Bilston",
            "Bingham",
            "Bingley",
            "Birchington-on-Sea",
            "Bircotes",
            "Birdham",
            "Birdwell",
            "Birkenhead",
            "Birmingham",
            "Bishop Auckland",
            "Bishop Middleham",
            "Bishop Sutton",
            "Bishop's Castle",
            "Bishopbriggs",
            "Bishops Cleeve",
            "Bishops Lydeard",
            "Bishops Stortford",
            "Bishops Waltham",
            "Bishopsteignton",
            "Bishopstoke",
            "Bishopston",
            "Bishopstone",
            "Bishopsworth",
            "Bishopthorpe",
            "Bishopton",
            "Bishopton",
            "Bitton",
            "Blaby",
            "Black Notley",
            "Blackburn",
            "Blackburn",
            "Blackburn with Darwen",
            "Blackheath",
            "Blackley",
            "Blackmoorfoot",
            "Blackpool",
            "Blackridge",
            "Blackrod",
            "Blackwell",
            "Blackwood",
            "Blackwood",
            "Blacon",
            "Blaenau Gwent",
            "Blaenau-Ffestiniog",
            "Blaenavon",
            "Blaengwynfi",
            "Blagdon",
            "Blairgowrie",
            "Blandford Forum",
            "Blantyre",
            "Blaydon-on-Tyne",
            "Bleadon",
            "Blean",
            "Bletchingley",
            "Bletchley",
            "Blewbury",
            "Blidworth",
            "Blindley Heath",
            "Blisworth",
            "Blockley",
            "Bloxham",
            "Bloxwich",
            "Blunham",
            "Bluntisham",
            "Blyth",
            "Blyton",
            "Bo’ness",
            "Boddam",
            "Bodedern",
            "Bodelwyddan",
            "Bodle Street",
            "Bodmin",
            "Bognor Regis",
            "Bollington",
            "Bolsover",
            "Bolton",
            "Bolton le Sands",
            "Bolton upon Dearne",
            "Bonhill",
            "Bonnybridge",
            "Bonnyrigg",
            "Boosbeck",
            "Bootle",
            "Bordon",
            "Boreham",
            "Borehamwood",
            "Borough Green",
            "Borough of Bolton",
            "Borough of Bury",
            "Borough of Halton",
            "Borough of North Tyneside",
            "Borough of Oldham",
            "Borough of Rochdale",
            "Borough of Stockport",
            "Borough of Swindon",
            "Borough of Tameside",
            "Borough of Thurrock",
            "Borough of Torbay",
            "Borough of Wigan",
            "Boroughbridge",
            "Borrowash",
            "Borth",
            "Bosham",
            "Boston",
            "Boston Spa",
            "Botesdale",
            "Bothwell",
            "Botley",
            "Bottesford",
            "Bottisham",
            "Boughton",
            "Bourne",
            "Bourne End",
            "Bournemouth",
            "Bourton",
            "Bourton on the Water",
            "Bovey Tracey",
            "Bovingdon",
            "Bovington Camp",
            "Bow",
            "Bow Brickhill",
            "Bow Street",
            "Bowburn",
            "Bowdon",
            "Bowthorpe",
            "Box",
            "Boxgrove",
            "Boxted",
            "Bozeat",
            "Bracebridge Heath",
            "Brackley",
            "Bracknell",
            "Bracknell Forest",
            "Bradfield",
            "Bradford",
            "Bradford-on-Avon",
            "Brading",
            "Bradley Cross",
            "Bradninch",
            "Bradwell",
            "Braintree",
            "Bramford",
            "Bramhall",
            "Bramham",
            "Bramhope",
            "Bramley",
            "Brampton",
            "Brandesburton",
            "Brandon",
            "Bransgore",
            "Branston",
            "Bratton",
            "Braunston",
            "Braunton",
            "Brayton",
            "Bream",
            "Brechin",
            "Brecon",
            "Bredbury",
            "Bredon",
            "Brenchley",
            "Brent",
            "Brent Knoll",
            "Brentford",
            "Brentwood",
            "Brewood",
            "Bridge",
            "Bridge of Allan",
            "Bridge of Earn",
            "Bridge of Weir",
            "Bridgend",
            "Bridgend county borough",
            "Bridgnorth",
            "Bridgwater",
            "Bridlington",
            "Bridport",
            "Brierfield",
            "Brierley Hill",
            "Brigg",
            "Brighouse",
            "Brighstone",
            "Brightlingsea",
            "Brighton",
            "Brighton and Hove",
            "Brightons",
            "Brigstock",
            "Brill",
            "Brimscombe",
            "Brinklow",
            "Brinscall",
            "Bristol",
            "Briston",
            "Briton Ferry",
            "Brixham",
            "Brixton",
            "Brixton Hill",
            "Brixworth",
            "Broad Blunsdon",
            "Broadfield",
            "Broadstairs",
            "Broadstone",
            "Broadwater",
            "Broadway",
            "Brockenhurst",
            "Brockley",
            "Bromborough",
            "Bromham",
            "Brompton",
            "Bromsgrove",
            "Bromyard",
            "Brooke",
            "Brora",
            "Broseley",
            "Brotton",
            "Brough",
            "Broughshane",
            "Broughton",
            "Broughton",
            "Broughton Astley",
            "Brownhills",
            "Broxbourne",
            "Broxburn",
            "Brundall",
            "Bruton",
            "Brymbo",
            "Bryn",
            "Brynamman",
            "Brynmawr",
            "Brynna",
            "Bubwith",
            "Buckden",
            "Buckfastleigh",
            "Buckhaven",
            "Buckhurst Hill",
            "Buckie",
            "Buckingham",
            "Buckinghamshire",
            "Buckley",
            "Bucknell",
            "Bude",
            "Budleigh Salterton",
            "Bugbrooke",
            "Bugle",
            "Builth Wells",
            "Bulford",
            "Bulkington",
            "Bulphan",
            "Bunbury",
            "Bungay",
            "Buntingford",
            "Burbage",
            "Bures Saint Mary",
            "Burford",
            "Burgess Hill",
            "Burgh le Marsh",
            "Burghead",
            "Burham",
            "Burley",
            "Burley in Wharfedale",
            "Burnage",
            "Burneside",
            "Burngreave",
            "Burnham-on-Crouch",
            "Burnham-on-Sea",
            "Burniston",
            "Burnley",
            "Burnopfield",
            "Burntisland",
            "Burntwood",
            "Burringham",
            "Burrington",
            "Burry Port",
            "Burscough",
            "Bursledon",
            "Burstwick",
            "Burton",
            "Burton Joyce",
            "Burton Latimer",
            "Burton on the Wolds",
            "Burton Pidsea",
            "Burton upon Stather",
            "Burton upon Trent",
            "Burtonwood",
            "Burwash",
            "Burwell",
            "Bury",
            "Bury St Edmunds",
            "Busby",
            "Bushey",
            "Bushmills",
            "Butterwick",
            "Buxted",
            "Buxton",
            "Byfield",
            "Byram",
            "Caddington",
            "Cadnam",
            "Caergwrle",
            "Caerleon",
            "Caernarfon",
            "Caerphilly",
            "Caerphilly County Borough",
            "Caerwent",
            "Cairneyhill",
            "Cairnryan",
            "Caister-on-Sea",
            "Caistor",
            "Calcot",
            "Calderbank",
            "Caldercruix",
            "Calderdale",
            "Caldicot",
            "Callander",
            "Callington",
            "Calne",
            "Calverton",
            "Camber",
            "Camberley",
            "Camblesforth",
            "Camborne",
            "Cambourne",
            "Cambridge",
            "Cambridgeshire",
            "Cambuslang",
            "Camden Town",
            "Cameley",
            "Camelford",
            "Campbeltown",
            "Campsall",
            "Canary Wharf",
            "Canewdon",
            "Canford Heath",
            "Cannock",
            "Canterbury",
            "Canvey Island",
            "Caol",
            "Capel",
            "Capel le Ferne",
            "Capel Saint Mary",
            "Carcroft",
            "Cardenden",
            "Cardiff",
            "Cardigan",
            "Cardington",
            "Cardross",
            "Carfin",
            "Carlisle",
            "Carlton",
            "Carluke",
            "Carmarthen",
            "Carmarthenshire",
            "Carmunnock",
            "Carnforth",
            "Carnlough",
            "Carnmoney",
            "Carnoustie",
            "Carnwath",
            "Carrickfergus",
            "Carron",
            "Carronshore",
            "Carryduff",
            "Carshalton",
            "Carterton",
            "Castle Cary",
            "Castle Donington",
            "Castle Douglas",
            "Castle Hedingham",
            "Castle Vale",
            "Castledawson",
            "Castlederg",
            "Castleford",
            "Castlereagh",
            "Castlerock",
            "Castleside",
            "Castlewellan",
            "Castor",
            "Catcliffe",
            "Caterham",
            "Caton",
            "Catrine",
            "Catterall",
            "Catterick",
            "Catterick Garrison",
            "Causeway Coast and Glens",
            "Cawood",
            "Cawston",
            "Cawthorne",
            "Caythorpe",
            "Cefn Cribwr",
            "Cemaes Bay",
            "Central Bedfordshire",
            "Chacewater",
            "Chadwell Heath",
            "Chadwell St Mary",
            "Chafford Hundred",
            "Chagford",
            "Chalfont Saint Peter",
            "Chalfont St Giles",
            "Chalford",
            "Chalgrove",
            "Chalton",
            "Chapel Allerton",
            "Chapel en le Frith",
            "Chapel Saint Leonards",
            "Chapelhall",
            "Chapeltown",
            "Chapmanslade",
            "Chard",
            "Charfield",
            "Charing",
            "Charlbury",
            "Charlestown of Aberlour",
            "Charlton Kings",
            "Charlton Marshall",
            "Charminster",
            "Charmouth",
            "Chartham",
            "Charvil",
            "Chasetown",
            "Chatburn",
            "Chatham",
            "Chatteris",
            "Cheadle",
            "Cheadle Heath",
            "Cheadle Hulme",
            "Cheam",
            "Cheddar",
            "Cheddington",
            "Cheddleton",
            "Cheetham Hill",
            "Chelford",
            "Chelmsford",
            "Chelmsley Wood",
            "Chelsea",
            "Cheltenham",
            "Chepstow",
            "Cherry Burton",
            "Chertsey",
            "Chesham",
            "Cheshire East",
            "Cheshire West and Chester",
            "Cheshunt",
            "Chessington",
            "Chester",
            "Chester-le-Street",
            "Chesterfield",
            "Chew Magna",
            "Chichester",
            "Chickerell",
            "Chicksands",
            "Chiddingfold",
            "Chigwell",
            "Chilcompton",
            "Child Okeford",
            "Chilton Foliat",
            "Chilworth",
            "Chinley",
            "Chinnor",
            "Chippenham",
            "Chipping Campden",
            "Chipping Norton",
            "Chipping Ongar",
            "Chipping Sodbury",
            "Chirk",
            "Chirnside",
            "Chiseldon",
            "Chislehurst",
            "Chobham",
            "Cholsey",
            "Choppington",
            "Chopwell",
            "Chorley",
            "Chorleywood",
            "Chorlton",
            "Chorlton cum Hardy",
            "Christchurch",
            "Chryston",
            "Chudleigh",
            "Chudleigh Knighton",
            "Chulmleigh",
            "Church",
            "Church Fenton",
            "Church Stretton",
            "Churchdown",
            "Churchill",
            "Churt",
            "Cinderford",
            "Cirencester",
            "City and Borough of Birmingham",
            "City and Borough of Leeds",
            "City and Borough of Salford",
            "City and Borough of Wakefield",
            "City and County of Swansea",
            "City of Belfast",
            "City of Bristol",
            "City of Edinburgh",
            "City of Kingston upon Hull",
            "City of Leicester",
            "City of London",
            "City of Westminster",
            "City of York",
            "Clackmannan",
            "Clackmannanshire",
            "Clacton-on-Sea",
            "Clapham",
            "Clarborough",
            "Clare",
            "Clarkston",
            "Claydon",
            "Claypole",
            "Clayton le Moors",
            "Clayton West",
            "Clayton-le-Woods",
            "Cleator Moor",
            "Cleckheaton",
            "Cleethorpes",
            "Cleland",
            "Clenchwarton",
            "Cleobury Mortimer",
            "Clerkenwell",
            "Clevedon",
            "Cleveleys",
            "Cliffe",
            "Clifton",
            "Clitheroe",
            "Clive",
            "Clophill",
            "Clowne",
            "Clutton",
            "Clydach",
            "Clydebank",
            "Coalburn",
            "Coalisland",
            "Coaltown of Balgonie",
            "Coalville",
            "Coatbridge",
            "Coates",
            "Cobham",
            "Cockenzie",
            "Cockermouth",
            "Cockfield",
            "Cockington",
            "Codicote",
            "Codsall",
            "Coedpoeth",
            "Cogenhoe",
            "Coggeshall",
            "Coity",
            "Colchester",
            "Cold Ash",
            "Cold Norton",
            "Colden Common",
            "Coldstream",
            "Coleford",
            "Coleraine",
            "Colerne",
            "Colinton",
            "Collier Row",
            "Collingbourne Kingston",
            "Collingham",
            "Colnbrook",
            "Colne",
            "Colsterworth",
            "Coltishall",
            "Colwich",
            "Colwyn Bay",
            "Colyton",
            "Combe Martin",
            "Comber",
            "Comberton",
            "Compton",
            "Compton Martin",
            "Comrie",
            "Congleton",
            "Congresbury",
            "Coningsby",
            "Conisbrough",
            "Connor",
            "Conon Bridge",
            "Consett",
            "Conwy",
            "Cookham",
            "Cookley",
            "Cookstown",
            "Cople",
            "Copmanthorpe",
            "Copplestone",
            "Coppull",
            "Copthorne",
            "Corbridge",
            "Corby",
            "Corby Glen",
            "Corfe Castle",
            "Cornholme",
            "Cornwall",
            "Corse",
            "Corsham",
            "Corston",
            "Cosby",
            "Cosham",
            "Costessey",
            "Cotgrave",
            "Cottenham",
            "Cottesmore",
            "Cottingham",
            "Coulsdon",
            "Coundon",
            "Countesthorpe",
            "County Durham",
            "County of Ceredigion",
            "County of Flintshire",
            "Coupar Angus",
            "Cove",
            "Coven",
            "Coventry",
            "Cowbit",
            "Cowbridge",
            "Cowdenbeath",
            "Cowes",
            "Cowfold",
            "Cowie",
            "Cowley",
            "Cowplain",
            "Coxhoe",
            "Coylton",
            "Cradley Heath",
            "Craigavon",
            "Crail",
            "Cramlington",
            "Cranbrook",
            "Cranfield",
            "Cranham",
            "Cranleigh",
            "Cranwell",
            "Craven Arms",
            "Crawley",
            "Crawley Down",
            "Credenhill",
            "Crediton",
            "Creech Saint Michael",
            "Creswell",
            "Crewe",
            "Crewkerne",
            "Criccieth",
            "Crick",
            "Crickhowell",
            "Cricklade",
            "Crieff",
            "Cringleford",
            "Croeserw",
            "Croft",
            "Crofton",
            "Crofty",
            "Cromer",
            "Cromford",
            "Crondall",
            "Crook",
            "Cropwell Bishop",
            "Crosby",
            "Cross Hands",
            "Cross Hills",
            "Crossford",
            "Crossgar",
            "Crossgates",
            "Crosshouse",
            "Crossmaglen",
            "Croston",
            "Crouch End",
            "Crowborough",
            "Crowland",
            "Crowle",
            "Crowthorne",
            "Croy",
            "Croydon",
            "Cruden Bay",
            "Crumlin",
            "Crumlin",
            "Crumpsall",
            "Crymych",
            "Crynant",
            "Cuckfield",
            "Cuddington",
            "Cudworth",
            "Cuffley",
            "Culcheth",
            "Cullen",
            "Cullingworth",
            "Culloden",
            "Cullompton",
            "Cullybackey",
            "Culmore",
            "Cults",
            "Culverstone Green",
            "Cumbernauld",
            "Cumbria",
            "Cumnock",
            "Cupar",
            "Curdworth",
            "Currie",
            "Curry Rivel",
            "Cushendall",
            "Cuxton",
            "Cwm",
            "Cwmafan",
            "Cwmbach",
            "Cwmbran",
            "Dagenham",
            "Dalbeattie",
            "Dalgety Bay",
            "Dalkeith",
            "Dalmellington",
            "Dalry",
            "Dalrymple",
            "Dalserf",
            "Dalston",
            "Dalton in Furness",
            "Danbury",
            "Danby",
            "Danderhall",
            "Darenth",
            "Daresbury",
            "Darfield",
            "Darlaston",
            "Darlington",
            "Darras Hall",
            "Darrington",
            "Dartford",
            "Dartmouth",
            "Darton",
            "Darvel",
            "Darwen",
            "Datchet",
            "Datchworth",
            "Daventry",
            "Dawlish",
            "Deal",
            "Deanshanger",
            "Dearham",
            "Debenham",
            "Deddington",
            "Dedham",
            "Deeside",
            "Deganwy",
            "Deiniolen",
            "Delabole",
            "Delph",
            "Denbigh",
            "Denbighshire",
            "Denby Dale",
            "Denham",
            "Denholme",
            "Denmead",
            "Denny",
            "Dennyloanhead",
            "Denton",
            "Denton Holme",
            "Derby",
            "Derbyshire",
            "Deri",
            "Derry",
            "Derry City and Strabane",
            "Dersingham",
            "Desborough",
            "Desford",
            "Devizes",
            "Devon",
            "Dewsbury",
            "Dickens Heath",
            "Dickleburgh",
            "Didcot",
            "Didsbury",
            "Diggle",
            "Dinas Powys",
            "Dingwall",
            "Dinnington",
            "Dinton",
            "Disley",
            "Diss",
            "Distington",
            "District of Rutland",
            "Ditchingham",
            "Ditchling",
            "Ditton Hill",
            "Doagh",
            "Dobwalls",
            "Doddington",
            "Dodworth",
            "Dolgellau",
            "Dollar",
            "Donaghadee",
            "Doncaster",
            "Donington",
            "Donisthorpe",
            "Donnington",
            "Dorchester",
            "Dorking",
            "Dormansland",
            "Dornoch",
            "Dorridge",
            "Dorset",
            "Dorstone",
            "Douglas",
            "Doune",
            "Dove Holes",
            "Dover",
            "Dovercourt",
            "Doveridge",
            "Downham Market",
            "Downpatrick",
            "Downton",
            "Draperstown",
            "Draycott",
            "Dreghorn",
            "Driffield",
            "Droitwich",
            "Dromore",
            "Dronfield",
            "Drongan",
            "Droylsden",
            "Drumnadrochit",
            "Drybrook",
            "Ducklington",
            "Dudley",
            "Duffield",
            "Dufftown",
            "Dukinfield",
            "Dulverton",
            "Dumbarton",
            "Dumfries",
            "Dumfries and Galloway",
            "Dunbar",
            "Dunblane",
            "Dunchurch",
            "Dundee",
            "Dundee City",
            "Dundonald",
            "Dundonald",
            "Dundrum",
            "Dundry",
            "Dunfermline",
            "Dungannon",
            "Dungiven",
            "Dunholme",
            "Dunipace",
            "Dunkeswell",
            "Dunlop",
            "Dunloy",
            "Dunnington",
            "Dunoon",
            "Duns",
            "Dunstable",
            "Dunswell",
            "Duntocher",
            "Durham",
            "Dursley",
            "Duxford",
            "Dyce",
            "Dyffryn Ardudwy",
            "Dymchurch",
            "Dyserth",
            "Eaglescliffe",
            "Eaglesham",
            "Earby",
            "Earith",
            "Earl Shilton",
            "Earls Barton",
            "Earls Colne",
            "Earlsfield",
            "Earlston",
            "Easington",
            "Easingwold",
            "East Ayrshire",
            "East Ayton",
            "East Bergholt",
            "East Boldon",
            "East Bridgford",
            "East Calder",
            "East Chevington",
            "East Cowes",
            "East Dean",
            "East Dereham",
            "East Dunbartonshire",
            "East Grinstead",
            "East Hanney",
            "East Harling",
            "East Harptree",
            "East Horsley",
            "East Keswick",
            "East Kilbride",
            "East Leake",
            "East Linton",
            "East Lothian",
            "East Markham",
            "East Molesey",
            "East Peckham",
            "East Rainton",
            "East Renfrewshire",
            "East Riding of Yorkshire",
            "East Sussex",
            "East Tilbury",
            "East Wemyss",
            "East Whitburn",
            "East Wittering",
            "Eastbourne",
            "Eastchurch",
            "Eastington",
            "Eastleigh",
            "Eastoft",
            "Easton",
            "Easton on the Hill",
            "Easton-in-Gordano",
            "Eastriggs",
            "Eastrington",
            "Eastry",
            "Eastwood",
            "Eaton Bray",
            "Eaton Socon",
            "Eattington",
            "Ebbw Vale",
            "Eccles",
            "Eccleshall",
            "Eccleston",
            "Eckington",
            "Edenbridge",
            "Edenfield",
            "Edgmond",
            "Edgware",
            "Edgworth",
            "Edinburgh",
            "Edington",
            "Edith Weston",
            "Edwinstowe",
            "Egham",
            "Eglinton",
            "Egremont",
            "Eight Ash Green",
            "Eilean Siar",
            "Elderslie",
            "Elgin",
            "Elland",
            "Ellerker",
            "Ellerton",
            "Ellesmere",
            "Ellesmere Port",
            "Ellon",
            "Elm Park",
            "Elmstead Market",
            "Elmswell",
            "Elsenham",
            "Elstead",
            "Elstree",
            "Elswick",
            "Elvington",
            "Elwick",
            "Ely",
            "Emberton",
            "Embleton",
            "Embsay",
            "Emley",
            "Emneth",
            "Empingham",
            "Emsworth",
            "Enderby",
            "Enfield Town",
            "Enniskillen",
            "Epping",
            "Epsom",
            "Epworth",
            "Erith",
            "Errol",
            "Erskine",
            "Esher",
            "Essendine",
            "Essex",
            "Essington",
            "Eton",
            "Eton Wick",
            "Etton",
            "Etwall",
            "Euxton",
            "Evanton",
            "Evenwood",
            "Evercreech",
            "Eversholt",
            "Eversley",
            "Everton",
            "Evesham",
            "Ewell",
            "Ewhurst",
            "Ewyas Harold",
            "Exeter",
            "Exhall",
            "Exminster",
            "Exmouth",
            "Exning",
            "Exton",
            "Eye",
            "Eyemouth",
            "Eynsford",
            "Eynsham",
            "Eythorne",
            "Faifley",
            "Failsworth",
            "Fairford",
            "Fairlands",
            "Fairlie",
            "Fairlight",
            "Fakenham",
            "Falkirk",
            "Falkland",
            "Fallin",
            "Fallowfield",
            "Falmouth",
            "Fareham",
            "Faringdon",
            "Farnborough",
            "Farndon",
            "Farnham",
            "Farnham Royal",
            "Farnsfield",
            "Farnworth",
            "Fauldhouse",
            "Faversham",
            "Fazeley",
            "Featherstone",
            "Felixstowe",
            "Felling",
            "Feltham",
            "Felton",
            "Feltwell",
            "Fenstanton",
            "Fenwick",
            "Fermanagh and Omagh",
            "Ferndale",
            "Ferndown",
            "Fernhill Heath",
            "Fernhurst",
            "Ferrybridge",
            "Ferryhill",
            "Fife",
            "Filey",
            "Finchampstead",
            "Findern",
            "Findochty",
            "Findon",
            "Finedon",
            "Finningley",
            "Fintona",
            "Fishburn",
            "Fishguard",
            "Fishtoft",
            "Fitzwilliam",
            "Five Oak Green",
            "Fivemiletown",
            "Flamborough",
            "Fleckney",
            "Fleet",
            "Fleetwood",
            "Flexbury",
            "Flimby",
            "Flimwell",
            "Flint",
            "Flitwick",
            "Flockton",
            "Flookburgh",
            "Flore",
            "Fochabers",
            "Fochriw",
            "Folkestone",
            "Fontwell",
            "Ford",
            "Fordham",
            "Fordingbridge",
            "Forest Row",
            "Forfar",
            "Formby",
            "Forres",
            "Fort William",
            "Forth",
            "Fortrose",
            "Fortuneswell",
            "Foulridge",
            "Foulsham",
            "Four Crosses",
            "Four Lanes",
            "Four Marks",
            "Fowey",
            "Fowlmere",
            "Framlingham",
            "Frampton on Severn",
            "Fraserburgh",
            "Freckleton",
            "Fremington",
            "Freshwater",
            "Freuchie",
            "Frimley",
            "Frinton-on-Sea",
            "Friockheim",
            "Friston",
            "Frizington",
            "Frodsham",
            "Frome",
            "Fulbourn",
            "Full Sutton",
            "Furnace Green",
            "Fylde",
            "Gaerwen",
            "Gainford",
            "Gainsborough",
            "Galashiels",
            "Galgate",
            "Galston",
            "Gamlingay",
            "Garelochhead",
            "Garforth",
            "Gargrave",
            "Garsington",
            "Garstang",
            "Gartcosh",
            "Garvagh",
            "Gateshead",
            "Geddington",
            "Gedney Hill",
            "Gelligaer",
            "Germoe",
            "Gerrards Cross",
            "Giffnock",
            "Gilberdyke",
            "Gilfach Goch",
            "Gilford",
            "Gillingham",
            "Gilwern",
            "Girton",
            "Girvan",
            "Glanamman",
            "Glandwr",
            "Glapwell",
            "Glasgow",
            "Glasgow City",
            "Glastonbury",
            "Glazebury",
            "Glemsford",
            "Glenariff",
            "Glenavy",
            "Glenboig",
            "Glenfield",
            "Glenmavis",
            "Glenrothes",
            "Glinton",
            "Glossop",
            "Gloucester",
            "Gloucestershire",
            "Glusburn",
            "Glyn-neath",
            "Glyncorrwg",
            "Gnosall",
            "Gobowen",
            "Godalming",
            "Godmanchester",
            "Godshill",
            "Godstone",
            "Golborne",
            "Golspie",
            "Goodwick",
            "Goole",
            "Goosnargh",
            "Goostrey",
            "Gorebridge",
            "Goring",
            "Goring-by-Sea",
            "Gorleston-on-Sea",
            "Gorseinon",
            "Gosberton",
            "Gosfield",
            "Gosforth",
            "Gosport",
            "Gossops Green",
            "Gotham",
            "Goudhurst",
            "Gourock",
            "Govilon",
            "Goxhill",
            "Grain",
            "Grange Hill",
            "Grange-over-Sands",
            "Grangemouth",
            "Grantham",
            "Grantown on Spey",
            "Grappenhall",
            "Grassington",
            "Gravesend",
            "Grays",
            "Greasby",
            "Great Amwell",
            "Great Ayton",
            "Great Bardfield",
            "Great Barford",
            "Great Barton",
            "Great Bedwyn",
            "Great Bentley",
            "Great Bookham",
            "Great Chesterford",
            "Great Dunmow",
            "Great Eccleston",
            "Great Glen",
            "Great Gonerby",
            "Great Gransden",
            "Great Hanwood",
            "Great Harwood",
            "Great Haywood",
            "Great Horkesley",
            "Great Horwood",
            "Great Houghton",
            "Great Leighs",
            "Great Malvern",
            "Great Marton",
            "Great Missenden",
            "Great Paxton",
            "Great Sankey",
            "Great Torrington",
            "Great Wakering",
            "Great Waldingfield",
            "Great Wyrley",
            "Great Yarmouth",
            "Great Yeldham",
            "Greater London",
            "Greatham",
            "Greenfield",
            "Greenfield",
            "Greenford",
            "Greenhill",
            "Greenhill",
            "Greenhithe",
            "Greenisland",
            "Greenock",
            "Greetham",
            "Gresford",
            "Gretna",
            "Greyabbey",
            "Grimethorpe",
            "Grimsby",
            "Grimston",
            "Griston",
            "Gronant",
            "Groombridge",
            "Grove",
            "Grovesend",
            "Grundisburgh",
            "Guilden Sutton",
            "Guildford",
            "Guilsfield",
            "Guisborough",
            "Guiseley",
            "Gullane",
            "Gunness",
            "Gunnislake",
            "Gwynedd",
            "Hackleton",
            "Haddenham",
            "Haddington",
            "Hadleigh",
            "Hadley",
            "Hadley Wood",
            "Hadlow",
            "Hadston",
            "Hagley",
            "Hailsham",
            "Hainault",
            "Hale",
            "Halesowen",
            "Halesworth",
            "Halifax",
            "Halkirk",
            "Hallglen",
            "Halling",
            "Hallow",
            "Halstead",
            "Halton",
            "Haltwhistle",
            "Hamble-le-Rice",
            "Hambleton",
            "Hameldon Hill",
            "Hamilton",
            "Hampshire",
            "Hampton",
            "Hampton in Arden",
            "Handcross",
            "Hannington",
            "Hanslope",
            "Hapton",
            "Harbury",
            "Hardingstone",
            "Hardwick Village",
            "Harefield",
            "Harlech",
            "Harleston",
            "Harlington",
            "Harlow",
            "Harold Wood",
            "Harpenden",
            "Harpole",
            "Harrietsham",
            "Harringay",
            "Harrogate",
            "Harrold",
            "Harrow on the Hill",
            "Harston",
            "Harthill",
            "Harthill",
            "Hartlebury",
            "Hartlepool",
            "Hartley",
            "Hartley Wintney",
            "Hartshill",
            "Hartwell",
            "Harvington",
            "Harwell",
            "Harwich",
            "Haslemere",
            "Haslingden",
            "Haslingfield",
            "Hassocks",
            "Hastings",
            "Haswell",
            "Hatfield",
            "Hatfield Heath",
            "Hatfield Peverel",
            "Hatherleigh",
            "Hathern",
            "Hathersage",
            "Hatton",
            "Haughley",
            "Haughton Green",
            "Havant",
            "Haverfordwest",
            "Haverhill",
            "Haverigg",
            "Hawarden",
            "Hawick",
            "Hawkhurst",
            "Hawkinge",
            "Haworth",
            "Hawthorn",
            "Haxby",
            "Haxey",
            "Hay",
            "Haydock",
            "Haydon Bridge",
            "Hayes",
            "Hayfield",
            "Hayle",
            "Hayling Island",
            "Haynes",
            "Hayton",
            "Haywards Heath",
            "Hazel Grove",
            "Hazlerigg",
            "Heacham",
            "Head of Muir",
            "Headcorn",
            "Heage",
            "Healing",
            "Heanor",
            "Heath and Reach",
            "Heathfield",
            "Heaton Chapel",
            "Heavitree",
            "Hebburn",
            "Hebden Bridge",
            "Heckington",
            "Heckmondwike",
            "Heddon on the Wall",
            "Hedge End",
            "Hedon",
            "Heighington",
            "Helensburgh",
            "Hellaby",
            "Hellifield",
            "Helmsley",
            "Helpston",
            "Helsby",
            "Helston",
            "Hemel Hempstead",
            "Hemingbrough",
            "Hemingford Grey",
            "Hemsby",
            "Hemsworth",
            "Hemyock",
            "Henfield",
            "Hengoed",
            "Henley in Arden",
            "Henley-on-Thames",
            "Henllan",
            "Henlow",
            "Henstridge",
            "Hereford",
            "Herefordshire",
            "Hermitage",
            "Herne Bay",
            "Herstmonceux",
            "Hertford",
            "Hertfordshire",
            "Heswall",
            "Hethersett",
            "Hetton-Le-Hole",
            "Hexham",
            "Heysham",
            "Heywood",
            "Hibaldstow",
            "High Barnet",
            "High Bentham",
            "High Blantyre",
            "High Etherley",
            "High Halden",
            "High Halstow",
            "High Legh",
            "High Ongar",
            "High Peak",
            "High Valleyfield",
            "High Wycombe",
            "Higham Ferrers",
            "Highbridge",
            "Highclere",
            "Highland",
            "Highley",
            "Hightown",
            "Highworth",
            "Hillsborough",
            "Hillside",
            "Hilton",
            "Hinchley Wood",
            "Hinckley",
            "Hindhead",
            "Hindley",
            "Hindon",
            "Hingham",
            "Hinton",
            "Hirwaun",
            "Histon",
            "Hitchin",
            "Hockley",
            "Hockley Heath",
            "Hockliffe",
            "Hockwold cum Wilton",
            "Hoddesdon",
            "Holbeach",
            "Holbeck",
            "Holbrook",
            "Hollingworth",
            "Holloway",
            "Hollym",
            "Holmes Chapel",
            "Holmfirth",
            "Holmwood",
            "Holsworthy",
            "Holt",
            "Holtby",
            "Holton le Clay",
            "Holyhead",
            "Holytown",
            "Holywell",
            "Holywell Green",
            "Holywood",
            "Honeybourne",
            "Honiton",
            "Hoo",
            "Hook",
            "Hook",
            "Hook Norton",
            "Hoole",
            "Hope",
            "Hope Valley",
            "Hopeman",
            "Hopton on Sea",
            "Horam",
            "Horbury",
            "Horley",
            "Horncastle",
            "Hornchurch",
            "Horndon on the Hill",
            "Horning",
            "Hornsea",
            "Horrabridge",
            "Horsford",
            "Horsforth",
            "Horsham",
            "Horsmonden",
            "Horsted Keynes",
            "Horton",
            "Horton Kirby",
            "Horwich",
            "Hotham",
            "Houghton",
            "Houghton Conquest",
            "Houghton on the Hill",
            "Houghton-Le-Spring",
            "Hounslow",
            "Houston",
            "Hove",
            "Hoveton",
            "Howden",
            "Howwood",
            "Hoylake",
            "Hoyland Nether",
            "Hucknall",
            "Huddersfield",
            "Hugh Town",
            "Hulme",
            "Humberston",
            "Hundleton",
            "Hungerford",
            "Hunmanby",
            "Hunstanton",
            "Huntingdon",
            "Huntley",
            "Huntly",
            "Huntspill",
            "Hunwick",
            "Hurst",
            "Hurstpierpoint",
            "Husbands Bosworth",
            "Husborne Crawley",
            "Huyton",
            "Hyde",
            "Hyde Heath",
            "Hythe",
            "Ibstock",
            "Ilchester",
            "Ilfracombe",
            "Ilkeston",
            "Ilkley",
            "Ilminster",
            "Immingham",
            "Ince Blundell",
            "Ince-in-Makerfield",
            "Inchinnan",
            "Inchture",
            "Ingatestone",
            "Ingleby Greenhow",
            "Ingleton",
            "Ingoldmells",
            "Ingrave",
            "Inkberrow",
            "Innerleithen",
            "Insch",
            "Inverbervie",
            "Inverclyde",
            "Invergordon",
            "Invergowrie",
            "Inverkeithing",
            "Inverkip",
            "Inverness",
            "Inverurie",
            "Ipplepen",
            "Ipswich",
            "Irchester",
            "Irlam",
            "Ironbridge",
            "Irthlingborough",
            "Irvine",
            "Irvinestown",
            "Isle of Arran",
            "Isle of Bute",
            "Isle of Cumbrae",
            "Isle of Islay",
            "Isle of Lewis",
            "Isle Of Mull",
            "Isle of North Uist",
            "Isle of South Uist",
            "Isle of Wight",
            "Isleham",
            "Isles of Scilly",
            "Isleworth",
            "Islington",
            "Iver",
            "Iver Heath",
            "Ivinghoe",
            "Ivybridge",
            "Iwade",
            "Ixworth",
            "Jarrow",
            "Jedburgh",
            "Johnston",
            "Johnstone",
            "Jordanstown",
            "Keadby",
            "Keady",
            "Kearsley",
            "Kedington",
            "Keelby",
            "Keele",
            "Kegworth",
            "Keighley",
            "Keith",
            "Kelloe",
            "Kelsall",
            "Kelso",
            "Kelty",
            "Kelvedon",
            "Kelvedon Hatch",
            "Kemnay",
            "Kempsey",
            "Kempston",
            "Kempston Hardwick",
            "Kemsing",
            "Kendal",
            "Kenilworth",
            "Kennington",
            "Kennoway",
            "Kensworth",
            "Kent",
            "Kenton",
            "Keresley",
            "Kesgrave",
            "Kessingland",
            "Keswick",
            "Kettering",
            "Ketton",
            "Keyingham",
            "Keynsham",
            "Keyworth",
            "Kibworth Harcourt",
            "Kidderminster",
            "Kidlington",
            "Kidsgrove",
            "Kidwelly",
            "Kilbarchan",
            "Kilbirnie",
            "Kilburn",
            "Kilcreggan",
            "Kilgetty",
            "Kilham",
            "Kilkeel",
            "Killamarsh",
            "Killearn",
            "Killyleagh",
            "Kilmacolm",
            "Kilmarnock",
            "Kilmaurs",
            "Kilrea",
            "Kilsby",
            "Kilsyth",
            "Kilwinning",
            "Kimberley",
            "Kimbolton",
            "Kimpton",
            "Kincardine",
            "Kineton",
            "King's Clipstone",
            "King's Lynn",
            "Kinghorn",
            "Kinglassie",
            "Kings Hill",
            "Kings Langley",
            "Kings Sutton",
            "Kings Worthy",
            "Kingsbridge",
            "Kingsbury",
            "Kingsclere",
            "Kingskerswell",
            "Kingskettle",
            "Kingsland",
            "Kingsley",
            "Kingsteignton",
            "Kingston Bagpuize",
            "Kingston Seymour",
            "Kingston upon Hull",
            "Kingston upon Thames",
            "Kingswells",
            "Kingswinford",
            "Kingswood",
            "Kington",
            "Kingussie",
            "Kinloss",
            "Kinross",
            "Kintbury",
            "Kintore",
            "Kinvere",
            "Kippax",
            "Kippen",
            "Kirby Muxloe",
            "Kircubbin",
            "Kirk Sandall",
            "Kirkburton",
            "Kirkby",
            "Kirkby in Ashfield",
            "Kirkby Lonsdale",
            "Kirkby Stephen",
            "Kirkbymoorside",
            "Kirkcaldy",
            "Kirkconnel",
            "Kirkcudbright",
            "Kirkham",
            "Kirkintilloch",
            "Kirklees",
            "Kirkliston",
            "Kirknewton",
            "Kirkwall",
            "Kirriemuir",
            "Kirton",
            "Kirton in Lindsey",
            "Kislingbury",
            "Kiveton Park",
            "Knaphill",
            "Knaresborough",
            "Knebworth",
            "Knighton",
            "Knottingley",
            "Knowle",
            "Knowsley",
            "Knutsford",
            "Laceby",
            "Lacock",
            "Ladybank",
            "Lakenheath",
            "Lamberhurst",
            "Lambeth",
            "Lambourn",
            "Lamesley",
            "Lamlash",
            "Lampeter",
            "Lanark",
            "Lancashire",
            "Lancaster",
            "Lanchester",
            "Lancing",
            "Landrake",
            "Langford",
            "Langham",
            "Langho",
            "Langholm",
            "Langley Green",
            "Langley Park",
            "Langport",
            "Langtoft",
            "Larbert",
            "Largs",
            "Lark Hill",
            "Larkfield",
            "Larkhall",
            "Larne",
            "Latchingdon and Snoreham",
            "Lauder",
            "Launceston",
            "Laurencekirk",
            "Laurieston",
            "Lavendon",
            "Lavenham",
            "Law",
            "Layer de la Haye",
            "Leasingham",
            "Leatherhead",
            "Lechlade",
            "Leconfield",
            "Ledbury",
            "Ledsham",
            "Lee-on-the-Solent",
            "Leeds",
            "Leek",
            "Leek Wootton",
            "Leeswood",
            "Leicester",
            "Leicestershire",
            "Leigh",
            "Leighton Buzzard",
            "Leiston",
            "Lenham",
            "Lennoxtown",
            "Lenzie",
            "Leominster",
            "Lerwick",
            "Lesbury",
            "Leslie",
            "Lesmahagow",
            "Letchworth",
            "Letchworth Garden City",
            "Letham",
            "Letterston",
            "Leuchars",
            "Leven",
            "Leven",
            "Lewes",
            "Leyburn",
            "Leyland",
            "Leysdown-on-Sea",
            "Lhanbryde",
            "Lichfield",
            "Lidlington",
            "Lifton",
            "Lightwater",
            "Limavady",
            "Limekilns",
            "Limpley Stoke",
            "Lincoln",
            "Lincolnshire",
            "Lingdale",
            "Lingfield",
            "Lingwood",
            "Linlithgow",
            "Linthwaite",
            "Linton",
            "Linton upon Ouse",
            "Linwood",
            "Liphook",
            "Lisburn",
            "Lisburn and Castlereagh",
            "Liskeard",
            "Lisnaskea",
            "Liss",
            "Litherland",
            "Little Amwell",
            "Little Chalfont",
            "Little Clacton",
            "Little Dunmow",
            "Little Eaton",
            "Little Hallingbury",
            "Little Hulton",
            "Little Lever",
            "Little Paxton",
            "Little Weighton",
            "Littleborough",
            "Littlebourne",
            "Littlehampton",
            "Littleport",
            "Liverpool",
            "Liversedge",
            "Livingston",
            "Llanarth",
            "Llanbedr",
            "Llanberis",
            "Llanbradach",
            "Llandeilo",
            "Llandovery",
            "Llandrindod Wells",
            "Llandudno",
            "Llandybie",
            "Llandysul",
            "Llanelli",
            "Llanerchymedd",
            "Llanfair Caereinion",
            "Llanfairfechan",
            "Llanfairpwllgwyngyll",
            "Llanfyllin",
            "Llangefni",
            "Llangoed",
            "Llangollen",
            "Llangwm",
            "Llangybi",
            "Llangynidr",
            "Llanharan",
            "Llanharry",
            "Llanidloes",
            "Llanilar",
            "Llanrhaeadr-ym-Mochnant",
            "Llanrug",
            "Llanrwst",
            "Llansantffraid Glan Conwy",
            "Llansteffan",
            "Llantrisant",
            "Llantwit Fardre",
            "Llantwit Major",
            "Llwynypia",
            "Loanhead",
            "Locharbriggs",
            "Lochgelly",
            "Lochgilphead",
            "Lochmaben",
            "Lochwinnoch",
            "Lockerbie",
            "Locking",
            "Lockington",
            "Loddon",
            "Lofthouse",
            "Loftus",
            "Logan",
            "London",
            "Londonderry County Borough",
            "Long Ashton",
            "Long Bennington",
            "Long Buckby",
            "Long Clawson",
            "Long Crendon",
            "Long Eaton",
            "Long Itchington",
            "Long Lawford",
            "Long Melford",
            "Long Stratton",
            "Long Sutton",
            "Long Whatton",
            "Longdendale",
            "Longfield",
            "Longforgan",
            "Longhope",
            "Longhorsley",
            "Longhoughton",
            "Longniddry",
            "Longridge",
            "Longsight",
            "Longstanton",
            "Longton",
            "Longtown",
            "Longwick",
            "Looe",
            "Lossiemouth",
            "Lostwithiel",
            "Loughborough",
            "Louth",
            "Low Ackworth",
            "Low Bradley",
            "Low Etherley",
            "Lowdham",
            "Lower Brailes",
            "Lower Broadheath",
            "Lower Earley",
            "Lower Halstow",
            "Lower Kingswood",
            "Lowestoft",
            "Lowick",
            "Luckington",
            "Luddenden Foot",
            "Ludgershall",
            "Ludlow",
            "Lugwardine",
            "Luncarty",
            "Lund",
            "Lundin Links",
            "Luton",
            "Lutterworth",
            "Lydbrook",
            "Lydd",
            "Lydiard Millicent",
            "Lydney",
            "Lyme Regis",
            "Lyminge",
            "Lymington",
            "Lymm",
            "Lympne",
            "Lympstone",
            "Lyndhurst",
            "Lyneham",
            "Lynemouth",
            "Lynton",
            "Lytchett Matravers",
            "Lytham St Annes",
            "Mablethorpe",
            "Macclesfield",
            "Macduff",
            "Machen",
            "Machynlleth",
            "Macmerry",
            "Maddiston",
            "Madeley",
            "Maesteg",
            "Maghera",
            "Magherafelt",
            "Magheralin",
            "Maghull",
            "Magor",
            "Maiden Newton",
            "Maidenbower",
            "Maidenhead",
            "Maidstone",
            "Maldon",
            "Malmesbury",
            "Malpas",
            "Maltby",
            "Malton",
            "Manby",
            "Manchester",
            "Manea",
            "Mangotsfield",
            "Manningtree",
            "Mansfield",
            "Mansfield Woodhouse",
            "Manston",
            "Manton",
            "Maple Cross",
            "Marazion",
            "March",
            "Marcham",
            "Marchwiel",
            "Marchwood",
            "Marden",
            "Margate",
            "Market Bosworth",
            "Market Deeping",
            "Market Drayton",
            "Market Harborough",
            "Market Lavington",
            "Market Overton",
            "Market Rasen",
            "Market Warsop",
            "Market Weighton",
            "Markfield",
            "Markinch",
            "Marks Tey",
            "Markyate",
            "Marlborough",
            "Marldon",
            "Marlow",
            "Marnhull",
            "Marple",
            "Marr",
            "Marsden",
            "Marshfield",
            "Marshfield",
            "Marske-by-the-Sea",
            "Marston Moretaine",
            "Martham",
            "Martock",
            "Maryburgh",
            "Maryport",
            "Masham",
            "Matlock",
            "Mattishall",
            "Mauchline",
            "Maulden",
            "Maxwellheugh",
            "Maybole",
            "Mayfield",
            "Mayfield",
            "Mayland",
            "Measham",
            "Medway",
            "Melbourn",
            "Melbourne",
            "Meldreth",
            "Melksham",
            "Melrose",
            "Meltham",
            "Meltham Mills",
            "Melton Mowbray",
            "Menai Bridge",
            "Mendip",
            "Menston",
            "Menstrie",
            "Meopham",
            "Meppershall",
            "Mere",
            "Meriden",
            "Merriott",
            "Merthyr Tydfil",
            "Merthyr Tydfil County Borough",
            "Messingham",
            "Metheringham",
            "Methil",
            "Methley",
            "Methven",
            "Metropolitan Borough of Wirral",
            "Mevagissey",
            "Mexborough",
            "Mickle Trafford",
            "Mickleton",
            "Mid and East Antrim",
            "Mid Calder",
            "Mid Ulster",
            "Middle Rasen",
            "Middle Winterslow",
            "Middlesbrough",
            "Middlestown",
            "Middleton",
            "Middlewich",
            "Midhurst",
            "Midlothian",
            "Midsomer Norton",
            "Milborne Port",
            "Milborne St Andrew",
            "Mildenhall",
            "Milford",
            "Milford Haven",
            "Milford on Sea",
            "Millbrook",
            "Millisle",
            "Millom",
            "Millport",
            "Milltimber",
            "Milnathort",
            "Milngavie",
            "Milnrow",
            "Milnthorpe",
            "Milton Keynes",
            "Milton of Campsie",
            "Milton of Leys",
            "Milverton",
            "Minchinhampton",
            "Minehead",
            "Minety",
            "Minster Lovell",
            "Minsterley",
            "Mintlaw",
            "Mirfield",
            "Misterton",
            "Mistley",
            "Mitcham",
            "Mitcheldean",
            "Mobberley",
            "Modbury",
            "Moelfre",
            "Moffat",
            "Moira",
            "Moira",
            "Mold",
            "Mollington",
            "Moneymore",
            "Monifieth",
            "Monk Fryston",
            "Monmouth",
            "Monmouthshire",
            "Montrose",
            "Moodiesburn",
            "Moray",
            "Morchard Bishop",
            "Morcott",
            "Morden",
            "Morecambe",
            "Moreton",
            "Moreton in Marsh",
            "Moretonhampstead",
            "Morley",
            "Morpeth",
            "Moss",
            "Mossblown",
            "Mossley",
            "Mostyn",
            "Motcombe",
            "Motherwell",
            "Mouldsworth",
            "Moulton Chapel",
            "Mount Hawke",
            "Mountain Ash",
            "Mountsorrel",
            "Moy",
            "Much Hadham",
            "Much Wenlock",
            "Muir of Ord",
            "Muirhead",
            "Muirkirk",
            "Mulbarton",
            "Mullion",
            "Mundesley",
            "Mundford",
            "Murton",
            "Musselburgh",
            "Mylor Bridge",
            "Mytholmroyd",
            "Nafferton",
            "Nailsea",
            "Nailsworth",
            "Nairn",
            "Nanpean",
            "Nantwich",
            "Narberth",
            "Narborough",
            "Navenby",
            "Neath",
            "Neath Port Talbot",
            "Necton",
            "Needham Market",
            "Needingworth",
            "Nefyn",
            "Neilston",
            "Nelson",
            "Nelson",
            "Neston",
            "Nether Heyford",
            "Nether Poppleton",
            "Nether Stowey",
            "Netheravon",
            "Netherlee",
            "Netherton",
            "Netley",
            "Nettleham",
            "New Alresford",
            "New Basford",
            "New Cumnock",
            "New Ferry",
            "New Malden",
            "New Marske",
            "New Mills",
            "New Milton",
            "New Pitsligo",
            "New Quay",
            "New Romney",
            "New Stevenston",
            "New Tredegar",
            "Newark on Trent",
            "Newarthill",
            "Newbiggin-by-the-Sea",
            "Newbold Verdon",
            "Newbridge",
            "Newbridge",
            "Newburgh",
            "Newburn",
            "Newbury",
            "Newcastle",
            "Newcastle Emlyn",
            "Newcastle under Lyme",
            "Newcastle upon Tyne",
            "Newchurch",
            "Newent",
            "Newhaven",
            "Newick",
            "Newington",
            "Newmacher",
            "Newmains",
            "Newmarket",
            "Newmilns",
            "Newport",
            "Newport",
            "Newport Pagnell",
            "Newport-on-Tay",
            "Newquay",
            "Newry",
            "Newry Mourne and Down",
            "Newton Abbot",
            "Newton Aycliffe",
            "Newton Ferrers",
            "Newton Longville",
            "Newton Mearns",
            "Newton Poppleford",
            "Newton Stewart",
            "Newton-le-Willows",
            "Newtonhill",
            "Newtonmore",
            "Newtown",
            "Newtown St Boswells",
            "Newtownabbey",
            "Newtownards",
            "Newtownstewart",
            "Neyland",
            "Ninfield",
            "Niton",
            "Norfolk",
            "Normandy",
            "North Ayrshire",
            "North Baddesley",
            "North Berwick",
            "North Cave",
            "North Collingham",
            "North Duffield",
            "North East Lincolnshire",
            "North Elmham",
            "North Ferriby",
            "North Lanarkshire",
            "North Leigh",
            "North Lincolnshire",
            "North Luffenham",
            "North Newbald",
            "North Petherton",
            "North Queensferry",
            "North Shields",
            "North Somercotes",
            "North Somerset",
            "North Sunderland",
            "North Tawton",
            "North Thoresby",
            "North Walsham",
            "North Yorkshire",
            "Northallerton",
            "Northam",
            "Northampton",
            "Northamptonshire",
            "Northborough",
            "Northiam",
            "Northleach",
            "Northolt",
            "Northop",
            "Northorpe",
            "Northumberland",
            "Northwich",
            "Norton",
            "Norton Canes",
            "Norwich",
            "Nottingham",
            "Nottinghamshire",
            "Notton",
            "Nuneaton",
            "Oadby",
            "Oakengates",
            "Oakham",
            "Oakington",
            "Oakley",
            "Oakley",
            "Oban",
            "Ochiltree",
            "Odiham",
            "Ogmore Vale",
            "Okehampton",
            "Old Basing",
            "Old Harlow",
            "Old Kilpatrick",
            "Old Leake",
            "Old Windsor",
            "Oldbury",
            "Oldham",
            "Oldmeldrum",
            "Olney",
            "Olveston",
            "Omagh",
            "Orkney",
            "Orkney Islands",
            "Orleton",
            "Ormesby St Margaret",
            "Ormiston",
            "Ormskirk",
            "Orpington",
            "Orsett",
            "Orwell",
            "Ossett",
            "Oswestry",
            "Otford",
            "Otley",
            "Otterburn",
            "Ottershaw",
            "Ottery St Mary",
            "Ottringham",
            "Oughtibridge",
            "Oundle",
            "Outwell",
            "Over",
            "Overcombe",
            "Overstrand",
            "Overton",
            "Overton",
            "Overtown",
            "Owston Ferry",
            "Oxenhope",
            "Oxford",
            "Oxfordshire",
            "Oxted",
            "Paddock Wood",
            "Padiham",
            "Padstow",
            "Paignton",
            "Painswick",
            "Paisley",
            "Pangbourne",
            "Pannal",
            "Pant",
            "Papworth Everard",
            "Par",
            "Parbold",
            "Parkstone",
            "Partington",
            "Partridge Green",
            "Pateley Bridge",
            "Patna",
            "Patrington",
            "Pattingham",
            "Paulton",
            "Peacehaven",
            "Peasedown Saint John",
            "Peaslake",
            "Peckham",
            "Peebles",
            "Pegswood",
            "Pelsall",
            "Pelton",
            "Pembroke",
            "Pembroke Dock",
            "Pembrokeshire",
            "Pembury",
            "Pen-clawdd",
            "Penally",
            "Penarth",
            "Pencader",
            "Pencaitland",
            "Pencoed",
            "Penicuik",
            "Penistone",
            "Penkridge",
            "Penmaenmawr",
            "Penparcau",
            "Penrhyndeudraeth",
            "Penrith",
            "Penryn",
            "Pensilva",
            "Pentre",
            "Pentyrch",
            "Penyffordd",
            "Penygroes",
            "Penzance",
            "Perranporth",
            "Perranwell",
            "Pershore",
            "Perth",
            "Perth and Kinross",
            "Peterborough",
            "Peterculter",
            "Peterhead",
            "Peterlee",
            "Petersfield",
            "Petworth",
            "Pevensey",
            "Pevensey Bay",
            "Pewsey",
            "Pickering",
            "Pilning",
            "Pilsley",
            "Pimperne",
            "Pinchbeck",
            "Pinner",
            "Pinxton",
            "Pirton",
            "Pitlochry",
            "Pitmedden",
            "Pitsea",
            "Pitstone",
            "Pittenweem",
            "Plains",
            "Plean",
            "Plumpton Green",
            "Plymouth",
            "Plympton",
            "Plymstock",
            "Pocklington",
            "Podington",
            "Polbeth",
            "Polesworth",
            "Pollington",
            "Polmont",
            "Polperro",
            "Polzeath",
            "Pont Rhyd-y-cyff",
            "Pontarddulais",
            "Pontefract",
            "Ponteland",
            "Pontesbury",
            "Pontlliw",
            "Pontyates",
            "Pontyberem",
            "Pontyclun",
            "Pontycymer",
            "Pontypool",
            "Pontypridd",
            "Pool",
            "Poole",
            "Poringland",
            "Porlock",
            "Port Bannatyne",
            "Port Erroll",
            "Port Glasgow",
            "Port Talbot",
            "Portadown",
            "Portaferry",
            "Portavogie",
            "Portglenone",
            "Porthcawl",
            "Porthleven",
            "Porthmadog",
            "Portishead",
            "Portknockie",
            "Portland",
            "Portlethen",
            "Portree",
            "Portrush",
            "Portscatho",
            "Portslade",
            "Portsmouth",
            "Portsoy",
            "Portstewart",
            "Potterne",
            "Potters Bar",
            "Potterspury",
            "Potton",
            "Poulton-le-Fylde",
            "Poynton",
            "Prees",
            "Preesall",
            "Prenton",
            "Prescot",
            "Prestatyn",
            "Prestbury",
            "Presteigne",
            "Preston",
            "Prestonpans",
            "Prestwich",
            "Prestwick",
            "Price Town",
            "Princes Risborough",
            "Princetown",
            "Priston",
            "Probus",
            "Prudhoe",
            "Publow",
            "Puckeridge",
            "Pucklechurch",
            "Puddletown",
            "Pudsey",
            "Pulborough",
            "Pulloxhill",
            "Purfleet",
            "Puriton",
            "Purley",
            "Purton",
            "Pwllheli",
            "Pyle",
            "Queenborough",
            "Queensbury",
            "Queensferry",
            "Queniborough",
            "Quorndon",
            "Rackheath",
            "Radcliffe",
            "Radcliffe on Trent",
            "Radlett",
            "Radley",
            "Radstock",
            "Radyr",
            "Raglan",
            "Rainford",
            "Rainham",
            "Rainworth",
            "Ramsbottom",
            "Ramsbury",
            "Ramsey",
            "Ramsgate",
            "Randalstown",
            "Ranskill",
            "Rastrick",
            "Ratby",
            "Rathfriland",
            "Ratho",
            "Ratho Station",
            "Raunds",
            "Ravenshead",
            "Ravenstone",
            "Rawcliffe",
            "Rawmarsh",
            "Rawtenstall",
            "Rayleigh",
            "Rayne",
            "Reading",
            "Redbourn",
            "Redbourne",
            "Redcar",
            "Redcar and Cleveland",
            "Redding",
            "Reddingmuirhead",
            "Redditch",
            "Redhill",
            "Redlynch",
            "Redruth",
            "Reedham",
            "Reepham",
            "Reigate",
            "Rendlesham",
            "Renfrew",
            "Renfrewshire",
            "Renton",
            "Repton",
            "Resolven",
            "Retford",
            "Rhayader",
            "Rhondda",
            "Rhondda Cynon Taf",
            "Rhoose",
            "Rhosllanerchrugog",
            "Rhosneigr",
            "Rhu",
            "Rhuddlan",
            "Rhyl",
            "Rhymney",
            "Riccall",
            "Richmond",
            "Rickinghall",
            "Rickmansworth",
            "Ridgmont",
            "Rillington",
            "Ringmer",
            "Ringstead",
            "Ringwood",
            "Ripley",
            "Ripon",
            "Ripponden",
            "Risca",
            "Riseley",
            "Rishton",
            "Roade",
            "Robertsbridge",
            "Rochdale",
            "Roche",
            "Rochester",
            "Rochford",
            "Rock",
            "Rode",
            "Rode Heath",
            "Rogiet",
            "Romford",
            "Romney Marsh",
            "Romsey",
            "Romsley",
            "Roos",
            "Rosehearty",
            "Rosewell",
            "Roslin",
            "Rosneath",
            "Ross on Wye",
            "Rossendale",
            "Rossett",
            "Rostrevor",
            "Rosyth",
            "Rothbury",
            "Rotherfield Peppard",
            "Rotherham",
            "Rothes",
            "Rothesay",
            "Rothienorman",
            "Rothley",
            "Rothwell",
            "Rottingdean",
            "Rowde",
            "Rowhedge",
            "Rowlands Gill",
            "Rowley Regis",
            "Roxton",
            "Royal Borough of Windsor and Maidenhead",
            "Royal Leamington Spa",
            "Royal Tunbridge Wells",
            "Royal Wootton Bassett",
            "Roydon",
            "Royston",
            "Royton",
            "Ruabon",
            "Ruardean",
            "Ruddington",
            "Rudgwick",
            "Rufford",
            "Rugby",
            "Rugeley",
            "Ruislip",
            "Runcorn",
            "Rushden",
            "Ruskington",
            "Rusthall",
            "Rustington",
            "Rutherglen",
            "Ruthin",
            "Ruyton-XI-Towns",
            "Ryde",
            "Rye",
            "Ryhall",
            "Ryhill",
            "Ryhope",
            "Ryton",
            "Ryton on Dunsmore",
            "Sabden",
            "Sacriston",
            "Saffron Walden",
            "Saint Agnes",
            "Saint Andrews",
            "Saint Asaph",
            "Saint Bees",
            "Saint Boswells",
            "Saint Clears",
            "Saint Columb Major",
            "Saint Cyrus",
            "Saint Davids",
            "Saint Dennis",
            "Saint Leonards-on-Sea",
            "Saint Monans",
            "Saint Neots",
            "Saint Osyth",
            "Saint Peters",
            "Saint Stephen",
            "Saintfield",
            "Salcombe",
            "Sale",
            "Salford",
            "Salfords",
            "Saline",
            "Salisbury",
            "Salsburgh",
            "Saltash",
            "Saltburn-by-the-Sea",
            "Saltcoats",
            "Saltford",
            "Sampford Peverell",
            "Sandbach",
            "Sandbank",
            "Sandford",
            "Sandown",
            "Sandwell",
            "Sandwich",
            "Sandwick",
            "Sandy",
            "Sanquhar",
            "Sapcote",
            "Saughall",
            "Saundersfoot",
            "Sawbridgeworth",
            "Sawston",
            "Sawtry",
            "Saxilby",
            "Saxmundham",
            "Scalby",
            "Scalloway",
            "Scarborough",
            "Scarcroft",
            "Scawby",
            "Scholes",
            "Scole",
            "Scone",
            "Scorton",
            "Scotby",
            "Scotter",
            "Scunthorpe",
            "Seafield",
            "Seaford",
            "Seaham",
            "Seahouses",
            "Seascale",
            "Seaton",
            "Seaton Delaval",
            "Seaview",
            "Sedbergh",
            "Sedgefield",
            "Seend",
            "Seer Green",
            "Sefton",
            "Seghill",
            "Selby",
            "Selkirk",
            "Selsey",
            "Send",
            "Settle",
            "Seven Sisters",
            "Sevenoaks",
            "Severn Beach",
            "Shadoxhurst",
            "Shadwell",
            "Shaftesbury",
            "Shalbourne",
            "Shalfleet",
            "Shanklin",
            "Shap",
            "Sharlston",
            "Sharnbrook",
            "Sharpness",
            "Shaw",
            "Shawbury",
            "Sheerness",
            "Sheffield",
            "Shefford",
            "Shelley",
            "Shenley",
            "Shenstone",
            "Shepherdswell",
            "Shepley",
            "Shepperton",
            "Shepshed",
            "Shepton Mallet",
            "Sherborne",
            "Sherborne St John",
            "Sherburn",
            "Sherburn Hill",
            "Sherburn in Elmet",
            "Sheriff Hutton",
            "Sheringham",
            "Sherington",
            "Sherston",
            "Shetland Islands",
            "Shevington",
            "Shieldhill",
            "Shifnal",
            "Shilbottle",
            "Shildon",
            "Shillingstone",
            "Shillington",
            "Shipdham",
            "Shipham",
            "Shipley",
            "Shipston on Stour",
            "Shipton under Wychwood",
            "Shirebrook",
            "Shiremoor",
            "Shirland",
            "Shirley",
            "Shoreham-by-Sea",
            "Shortlands",
            "Shotley Gate",
            "Shotts",
            "Shrewsbury",
            "Shrewton",
            "Shrivenham",
            "Shropshire",
            "Shurdington",
            "Sible Hedingham",
            "Sibsey",
            "Sidcup",
            "Sidmouth",
            "Sileby",
            "Silkstone",
            "Silloth",
            "Silsden",
            "Silsoe",
            "Silver End",
            "Silverdale",
            "Silverstone",
            "Silverton",
            "Sinfin",
            "Sion Mills",
            "Sir Powys",
            "Sissinghurst",
            "Sittingbourne",
            "Skegness",
            "Skellingthorpe",
            "Skelmersdale",
            "Skelmorlie",
            "Skelton",
            "Skidby",
            "Skinningrove",
            "Skipsea",
            "Skipton",
            "Slaley",
            "Slamannan",
            "Sleaford",
            "Sleights",
            "Slinfold",
            "Slough",
            "Smethwick",
            "Smithton",
            "Snaith",
            "Snettisham",
            "Snodland",
            "Soham",
            "Solihull",
            "Somerset",
            "Somersham",
            "Somerton",
            "Sonning Common",
            "South Ayrshire",
            "South Benfleet",
            "South Brent",
            "South Cave",
            "South Cerney",
            "South Chailey",
            "South Collingham",
            "South Croydon",
            "South Elmsall",
            "South Gloucestershire",
            "South Harting",
            "South Hayling",
            "South Hetton",
            "South Lanarkshire",
            "South Littleton",
            "South Luffenham",
            "South Milford",
            "South Molton",
            "South Nutfield",
            "South Ockendon",
            "South Petherton",
            "South Shields",
            "South Tyneside",
            "South Wingfield",
            "South Witham",
            "Southall",
            "Southam",
            "Southampton",
            "Southchurch Village",
            "Southend-on-Sea",
            "Southery",
            "Southgate",
            "Southminster",
            "Southowram",
            "Southport",
            "Southsea",
            "Southwater",
            "Southwell",
            "Southwick",
            "Southwold",
            "Sowerby Bridge",
            "Spalding",
            "Speldhurst",
            "Spennymoor",
            "Spilsby",
            "Spittal",
            "Spixworth",
            "Spofforth",
            "Spratton",
            "Springside",
            "Sproatley",
            "St Albans",
            "St Austell",
            "St Helens",
            "St Ives",
            "St Just",
            "St Leonards",
            "St Mary's",
            "St Mary's Bay",
            "St. Day",
            "St. Helens",
            "Stafford",
            "Staffordshire",
            "Stagsden",
            "Stainburn",
            "Staindrop",
            "Staines",
            "Stainforth",
            "Stakeford",
            "Stalbridge",
            "Stalham",
            "Stallingborough",
            "Stalybridge",
            "Stamford",
            "Stamford Bridge",
            "Stanbridge",
            "Standlake",
            "Standon",
            "Stanford in the Vale",
            "Stanford-le-Hope",
            "Stanhope",
            "Stanley",
            "Stanley",
            "Stanmore",
            "Stannington",
            "Stansted Mountfitchet",
            "Stanwick",
            "Staplehurst",
            "Starcross",
            "Startforth",
            "Staveley",
            "Steeple Bumpstead",
            "Steeple Claydon",
            "Steeton",
            "Stenhousemuir",
            "Stepps",
            "Stevenage",
            "Stevenston",
            "Steventon",
            "Stevington",
            "Stewartby",
            "Stewarton",
            "Stewkley",
            "Steyning",
            "Steynton",
            "Stickney",
            "Stillington",
            "Stilton",
            "Stirling",
            "Stithians",
            "Stock",
            "Stockport",
            "Stocksbridge",
            "Stocksfield",
            "Stockton",
            "Stockton Heath",
            "Stockton-on-Tees",
            "Stoke Ferry",
            "Stoke Gabriel",
            "Stoke Gifford",
            "Stoke Golding",
            "Stoke Goldington",
            "Stoke Poges",
            "Stoke Prior",
            "Stoke-on-Trent",
            "Stoke-sub-Hamdon",
            "Stokenchurch",
            "Stokesley",
            "Stone",
            "Stonehaven",
            "Stonehouse",
            "Stonehouse",
            "Stonesfield",
            "Stoney Stanton",
            "Stoneyburn",
            "Stornoway",
            "Storrington",
            "Stotfold",
            "Stourbridge",
            "Stourport-on-Severn",
            "Stow on the Wold",
            "Stowmarket",
            "Strabane",
            "Stradbroke",
            "Stranraer",
            "Stratfield Mortimer",
            "Stratford-upon-Avon",
            "Strathaven",
            "Strathblane",
            "Strathpeffer",
            "Stratton",
            "Streatley",
            "Street",
            "Streetly",
            "Strensall",
            "Stretford",
            "Stretham",
            "Stromness",
            "Strood",
            "Stroud",
            "Studley",
            "Sturminster Marshall",
            "Sturminster Newton",
            "Sturry",
            "Sudbrooke",
            "Sudbury",
            "Suffolk",
            "Sunbury-on-Thames",
            "Sunderland",
            "Sundridge",
            "Surbiton",
            "Surrey",
            "Sutterton",
            "Sutton",
            "Sutton Benger",
            "Sutton Bonington",
            "Sutton Bridge",
            "Sutton Coldfield",
            "Sutton Courtenay",
            "Sutton in Ashfield",
            "Sutton on Trent",
            "Sutton upon Derwent",
            "Swadlincote",
            "Swaffham",
            "Swanage",
            "Swanley",
            "Swanmore",
            "Swanscombe",
            "Swansea",
            "Swarthmoor",
            "Swavesey",
            "Sway",
            "Swillington",
            "Swindon",
            "Swineshead",
            "Swinton",
            "Symington",
            "Syston",
            "Sywell",
            "Tadcaster",
            "Tadley",
            "Tadworth",
            "Taibach",
            "Tain",
            "Takeley",
            "Tal-y-bont",
            "Talgarth",
            "Talysarn",
            "Tamworth",
            "Tandragee",
            "Tanfield",
            "Tangmere",
            "Tankerton",
            "Tansley",
            "Tarbert",
            "Tarbolton",
            "Tarleton",
            "Tarporley",
            "Tarvin",
            "Tattenhall",
            "Taunton",
            "Tavistock",
            "Tayport",
            "Teddington",
            "Teignmouth",
            "Telford",
            "Telford and Wrekin",
            "Templecombe",
            "Templepatrick",
            "Templeton",
            "Tempsford",
            "Tenbury Wells",
            "Tenby",
            "Tenterden",
            "Terrington Saint John",
            "Terrington St Clement",
            "Tetbury",
            "Tetney",
            "Tewkesbury",
            "Teynham",
            "Thame",
            "Thames Ditton",
            "Thatcham",
            "Thaxted",
            "The Boldons",
            "The Scottish Borders",
            "Theale",
            "Thetford",
            "Theydon Bois",
            "Thirsk",
            "Thornaby-on-Tees",
            "Thornbury",
            "Thorne",
            "Thorner",
            "Thorney",
            "Thorngumbald",
            "Thornhill",
            "Thornley",
            "Thornliebank",
            "Thornton",
            "Thornton Dale",
            "Thornton Heath",
            "Thornton-Cleveleys",
            "Thorp Arch",
            "Thorpe Hamlet",
            "Thorpe le Soken",
            "Thrapston",
            "Three Crosses",
            "Three Legged Cross",
            "Thurlby",
            "Thurlton",
            "Thurso",
            "Thurston",
            "Tibshelf",
            "Ticehurst",
            "Tickhill",
            "Tickton",
            "Tideswell",
            "Tidworth",
            "Tilbury",
            "Tillicoultry",
            "Timperley",
            "Timsbury",
            "Tingewick",
            "Tintagel",
            "Tintern",
            "Tipton",
            "Tiptree",
            "Tisbury",
            "Tiverton",
            "Tobermory",
            "Toddington",
            "Todmorden",
            "Todwick",
            "Tollesbury",
            "Tolleshunt Knights",
            "Tonbridge",
            "Tonypandy",
            "Tonyrefail",
            "Topsham",
            "Torfaen County Borough",
            "Torphins",
            "Torpoint",
            "Torquay",
            "Torrance",
            "Totnes",
            "Totternhoe",
            "Tottington",
            "Totton",
            "Tow Law",
            "Towcester",
            "Town Row",
            "Townhill",
            "Trafford",
            "Trafford Park",
            "Tranent",
            "Trawsfynydd",
            "Tredegar",
            "Treeton",
            "Trefnant",
            "Tregaron",
            "Tregarth",
            "Treharris",
            "Treorchy",
            "Treuddyn",
            "Trimdon",
            "Trimdon Grange",
            "Trimsaran",
            "Tring",
            "Troon",
            "Trowbridge",
            "Truro",
            "Tullibody",
            "Tumble",
            "Tunstall",
            "Turriff",
            "Tuxford",
            "Twechar",
            "Tweedbank",
            "Twyford",
            "Tycroes",
            "Tyldesley",
            "Tynemouth",
            "Tytherington",
            "Tywyn",
            "Uckfield",
            "Uddingston",
            "Uffculme",
            "Ulceby",
            "Ullapool",
            "Ulrome",
            "Ulverston",
            "Undy",
            "Uny Lelant",
            "Upchurch",
            "Upminster",
            "Upper Basildon",
            "Upper Langwith",
            "Upper Poppleton",
            "Uppingham",
            "Upton",
            "Upton Scudamore",
            "Upton upon Severn",
            "Upwell",
            "Urmston",
            "Ushaw Moor",
            "Usk",
            "Uttoxeter",
            "Vale of Glamorgan",
            "Vale of Leven",
            "Valley",
            "Ventnor",
            "Verwood",
            "Victoria",
            "Viewpark",
            "Virginia Water",
            "Waddesdon",
            "Waddington",
            "Wadebridge",
            "Wadhurst",
            "Wadworth",
            "Wainfleet All Saints",
            "Wakefield",
            "Walberton",
            "Wales",
            "Walkden",
            "Walkern",
            "Walkington",
            "Wallasey",
            "Wallingford",
            "Wallsend",
            "Walsall",
            "Walsden",
            "Waltham",
            "Waltham Abbey",
            "Waltham Cross",
            "Walton",
            "Walton-on-Thames",
            "Walton-on-the-Naze",
            "Wanborough",
            "Wansford",
            "Wantage",
            "Warboys",
            "Wardle",
            "Ware",
            "Wareham",
            "Wargrave",
            "Waringstown",
            "Warkworth",
            "Warlingham",
            "Warminster",
            "Warnham",
            "Warrenpoint",
            "Warrington",
            "Warsop",
            "Warton",
            "Warwick",
            "Warwickshire",
            "Washingborough",
            "Washington",
            "Washwood Heath",
            "Watchet",
            "Water Eaton",
            "Water Orton",
            "Waterbeach",
            "Waterfoot",
            "Wateringbury",
            "Waterloo",
            "Waterlooville",
            "Watford",
            "Wath upon Dearne",
            "Watlington",
            "Wattisham",
            "Watton",
            "Watton at Stone",
            "Weaverham",
            "Wedmore",
            "Wednesbury",
            "Wednesfield",
            "Weedon Beck",
            "Weeting",
            "Welford",
            "Wellesbourne",
            "Wellesbourne Mountford",
            "Welling",
            "Wellingborough",
            "Wellington",
            "Wellow",
            "Wells",
            "Wells-next-the-Sea",
            "Welshpool",
            "Welwyn",
            "Welwyn Garden City",
            "Wem",
            "Wembley",
            "Wembury",
            "Wemyss Bay",
            "Wendover",
            "Wentworth",
            "Wenvoe",
            "Weobley",
            "West Bergholt",
            "West Berkshire",
            "West Bridgford",
            "West Bromwich",
            "West Byfleet",
            "West Calder",
            "West Clandon",
            "West Coker",
            "West Cornforth",
            "West Drayton",
            "West Dunbartonshire",
            "West End",
            "West End of London",
            "West Haddon",
            "West Hallam",
            "West Horsley",
            "West Ilsley",
            "West Kilbride",
            "West Kingsdown",
            "West Kirby",
            "West Linton",
            "West Lothian",
            "West Malling",
            "West Mersea",
            "West Molesey",
            "West Rainton",
            "West Sussex",
            "West Thurrock",
            "West Walton",
            "West Wellow",
            "West Wickham",
            "Westbury",
            "Westcliff-on-Sea",
            "Westcott",
            "Westergate",
            "Westerham",
            "Westfield",
            "Westgate on Sea",
            "Westhill",
            "Westhoughton",
            "Weston",
            "Weston Turville",
            "Weston-super-Mare",
            "Westoning",
            "Westonzoyland",
            "Westquarter",
            "Westwood",
            "Wetheral",
            "Wetherby",
            "Wetwang",
            "Weybridge",
            "Weymouth",
            "Whaley Bridge",
            "Whalley",
            "Whaplode",
            "Wheathampstead",
            "Wheatley",
            "Wheaton Aston",
            "Wheldrake",
            "Whickham",
            "Whimple",
            "Whitburn",
            "Whitburn",
            "Whitby",
            "Whitchurch",
            "White Waltham",
            "Whitecraig",
            "Whitefield",
            "Whitehaven",
            "Whitehead",
            "Whitehills",
            "Whiteparish",
            "Whitland",
            "Whitley",
            "Whitley Bay",
            "Whitstable",
            "Whittingham",
            "Whittington",
            "Whittlesey",
            "Whittlesford",
            "Whitwell",
            "Whitworth",
            "Whyteleafe",
            "Wick",
            "Wick",
            "Wick",
            "Wickford",
            "Wickham",
            "Wickham Bishops",
            "Wickham Market",
            "Wickwar",
            "Wideopen",
            "Widnes",
            "Wigan",
            "Wigmore",
            "Wigston Magna",
            "Wigton",
            "Wilberfoss",
            "Wilburton",
            "Willand",
            "Willaston",
            "Willenhall",
            "Willingham",
            "Willington",
            "Williton",
            "Wilmcote",
            "Wilmslow",
            "Wilsden",
            "Wilstead",
            "Wilton",
            "Wiltshire",
            "Wimblington",
            "Wimborne Minster",
            "Wincanton",
            "Winchburgh",
            "Winchcombe",
            "Winchelsea Beach",
            "Winchester",
            "Windermere",
            "Windlesham",
            "Windsor",
            "Windygates",
            "Winford",
            "Wing",
            "Wingate",
            "Wingerworth",
            "Wingham",
            "Wingrave",
            "Winkleigh",
            "Winscombe",
            "Winsford",
            "Winslow",
            "Winterbourne",
            "Winterton",
            "Winwick",
            "Wirksworth",
            "Wisbech",
            "Wishaw",
            "Witchford",
            "Witham",
            "Witheridge",
            "Withernsea",
            "Witley",
            "Witney",
            "Wittering",
            "Witton Gilbert",
            "Wiveliscombe",
            "Wivelsfield Green",
            "Wivenhoe",
            "Woburn",
            "Woburn Sands",
            "Woking",
            "Wokingham",
            "Wold Newton",
            "Woldingham",
            "Wollaston",
            "Wolsingham",
            "Wolston",
            "Wolvercote",
            "Wolverhampton",
            "Wombourn",
            "Wombwell",
            "Wood Street Village",
            "Woodborough",
            "Woodbridge",
            "Woodbury",
            "Woodchurch",
            "Woodcote",
            "Woodford",
            "Woodford Green",
            "Woodhall Spa",
            "Woodsetts",
            "Woodstock",
            "Wool",
            "Woolavington",
            "Wooler",
            "Woolley",
            "Woolpit",
            "Wootton",
            "Worcester",
            "Worcester Park",
            "Worcestershire",
            "Workington",
            "Worksop",
            "Worlaby",
            "Wormley",
            "Worthing",
            "Worton",
            "Wotton-under-Edge",
            "Wouldham",
            "Wragby",
            "Wrawby",
            "Wrea Green",
            "Wrexham",
            "Wrington",
            "Writtle",
            "Wrotham",
            "Wroughton",
            "Wroxall",
            "Wychbold",
            "Wye",
            "Wylam",
            "Wymeswold",
            "Y Felinheli",
            "Yalding",
            "Yapton",
            "Yarm",
            "Yarmouth",
            "Yarnton",
            "Yate",
            "Yateley",
            "Yatton",
            "Yaxley",
            "Yeadon",
            "Yealmpton",
            "Yelverton",
            "Yeovil",
            "Yetminster",
            "Ynysybwl",
            "York",
            "Youlgreave",
            "Yoxall",
            "Ystalyfera"
        ]
    },
    {
        "label": "United States",
        "code": "US",
        "phone": "1",
        "cities": [
            "‘Āhuimanu",
            "‘Aiea",
            "‘Ele‘ele",
            "‘Ewa Beach",
            "‘Ewa Gentry",
            "‘Ewa Villages",
            "‘Ōma‘o",
            "Abbeville",
            "Abbeville",
            "Abbeville",
            "Abbeville",
            "Abbeville County",
            "Abbotsford",
            "Abbottstown",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen",
            "Aberdeen Proving Ground",
            "Abernathy",
            "Abilene",
            "Abilene",
            "Abingdon",
            "Abingdon",
            "Abington",
            "Abita Springs",
            "Abram",
            "Absarokee",
            "Absecon",
            "Acadia Parish",
            "Acalanes Ridge",
            "Accokeek",
            "Accomac",
            "Accomack County",
            "Ackerman",
            "Ackley",
            "Acres Green",
            "Acton",
            "Acton",
            "Acton",
            "Acushnet",
            "Acushnet Center",
            "Acworth",
            "Ada",
            "Ada",
            "Ada",
            "Ada County",
            "Adair County",
            "Adair County",
            "Adair County",
            "Adair County",
            "Adairsville",
            "Adams",
            "Adams",
            "Adams",
            "Adams Center",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams County",
            "Adams Morgan",
            "Adamstown",
            "Adamstown",
            "Adamsville",
            "Adamsville",
            "Addis",
            "Addison",
            "Addison",
            "Addison",
            "Addison",
            "Addison",
            "Addison",
            "Addison County",
            "Adel",
            "Adel",
            "Adelanto",
            "Adelphi",
            "Adjuntas",
            "Adrian",
            "Adrian",
            "Adrian",
            "Advance",
            "Advance",
            "Adwolf",
            "Affton",
            "Afton",
            "Afton",
            "Afton",
            "Agawam",
            "Agoura",
            "Agoura Hills",
            "Agua Dulce",
            "Agua Dulce",
            "Agua Fria",
            "Aguada",
            "Aguadilla",
            "Aguanga",
            "Aguas Buenas",
            "Aguas Claras",
            "Aguilita",
            "Ahoskie",
            "Ahtanum",
            "Ahwahnee",
            "Ahwatukee Foothills",
            "Aibonito",
            "Aiken",
            "Aiken County",
            "Ainaloa",
            "Ainsworth",
            "Air Force Academy",
            "Airmont",
            "Airway Heights",
            "Aitkin",
            "Aitkin County",
            "Ajo",
            "Akron",
            "Akron",
            "Akron",
            "Akron",
            "Akron",
            "Akron",
            "Akutan",
            "Alabama",
            "Alabaster",
            "Alachua",
            "Alachua County",
            "Alafaya",
            "Alamance County",
            "Alameda",
            "Alameda County",
            "Alamo",
            "Alamo",
            "Alamo",
            "Alamo",
            "Alamo",
            "Alamo",
            "Alamo Heights",
            "Alamogordo",
            "Alamosa",
            "Alamosa County",
            "Alamosa East",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany",
            "Albany County",
            "Albany County",
            "Albany Park",
            "Albemarle",
            "Albemarle County",
            "Albers",
            "Albert Lea",
            "Albertson",
            "Albertville",
            "Albertville",
            "Albia",
            "Albion",
            "Albion",
            "Albion",
            "Albion",
            "Albion",
            "Albion",
            "Albion",
            "Albuquerque",
            "Alburtis",
            "Alcoa",
            "Alcona County",
            "Alcorn County",
            "Aldan",
            "Alden",
            "Alderson",
            "Alderton",
            "Alderwood Manor",
            "Aldine",
            "Aledo",
            "Aledo",
            "Aleutians East Borough",
            "Aleutians West Census Area",
            "Alexander",
            "Alexander City",
            "Alexander County",
            "Alexander County",
            "Alexandria",
            "Alexandria",
            "Alexandria",
            "Alexandria",
            "Alexandria",
            "Alexandria",
            "Alexandria",
            "Alexandria",
            "Alexandria Bay",
            "Alfalfa County",
            "Alfred",
            "Alfred",
            "Alger County",
            "Algoma",
            "Algona",
            "Algona",
            "Algonac",
            "Algonquin",
            "Algonquin",
            "Algood",
            "Alhambra",
            "Alhambra",
            "Alice",
            "Aliceville",
            "Alief",
            "Aliquippa",
            "Aliso Viejo",
            "Allamakee County",
            "Allapattah",
            "Allegan",
            "Allegan County",
            "Allegany",
            "Allegany County",
            "Allegany County",
            "Alleghany County",
            "Alleghany County",
            "Allegheny County",
            "Alleghenyville",
            "Allen",
            "Allen County",
            "Allen County",
            "Allen County",
            "Allen County",
            "Allen Parish",
            "Allen Park",
            "Allendale",
            "Allendale",
            "Allendale",
            "Allendale",
            "Allendale County",
            "Allentown",
            "Allentown",
            "Alliance",
            "Alliance",
            "Allison",
            "Allison Park",
            "Allouez",
            "Alloway",
            "Allyn",
            "Alma",
            "Alma",
            "Alma",
            "Alma",
            "Alma",
            "Alma",
            "Almedia",
            "Almont",
            "Aloha",
            "Alondra Park",
            "Alorton",
            "Alpaugh",
            "Alpena",
            "Alpena County",
            "Alpha",
            "Alpharetta",
            "Alpine",
            "Alpine",
            "Alpine",
            "Alpine",
            "Alpine County",
            "Alsip",
            "Alstead",
            "Alta",
            "Alta Sierra",
            "Altadena",
            "Altamont",
            "Altamont",
            "Altamont",
            "Altamont",
            "Altamont",
            "Altamonte Springs",
            "Altavista",
            "Alto",
            "Alto",
            "Alto",
            "Alton",
            "Alton",
            "Alton",
            "Alton",
            "Alton North (historical)",
            "Altoona",
            "Altoona",
            "Altoona",
            "Alturas",
            "Alturas",
            "Altus",
            "Alum Creek",
            "Alum Rock",
            "Alva",
            "Alva",
            "Alvarado",
            "Alvin",
            "Alvord",
            "Ama",
            "Amador County",
            "Amagansett",
            "Amarillo",
            "Amberley",
            "Ambler",
            "Amboy",
            "Amboy",
            "Ambridge",
            "Amelia",
            "Amelia",
            "Amelia County",
            "Amelia Court House",
            "American Canyon",
            "American Falls",
            "American Fork",
            "Americus",
            "Amery",
            "Ames",
            "Ames",
            "Ames Lake",
            "Amesbury",
            "Amesti",
            "Amherst",
            "Amherst",
            "Amherst",
            "Amherst",
            "Amherst",
            "Amherst Center",
            "Amherst County",
            "Amidon",
            "Amite",
            "Amite County",
            "Amity",
            "Amity Gardens",
            "Amityville",
            "Ammon",
            "Amory",
            "Amsterdam",
            "Anaconda",
            "Anacortes",
            "Anadarko",
            "Anaheim",
            "Anahola",
            "Anahuac",
            "Anamosa",
            "Añasco",
            "Anchor Point",
            "Anchorage",
            "Anchorage",
            "Anchorage Municipality",
            "Ancient Oaks",
            "Andalusia",
            "Andalusia",
            "Anderson",
            "Anderson",
            "Anderson",
            "Anderson",
            "Anderson",
            "Anderson County",
            "Anderson County",
            "Anderson County",
            "Anderson County",
            "Anderson County",
            "Anderson Mill",
            "Andover",
            "Andover",
            "Andover",
            "Andover",
            "Andover",
            "Andover",
            "Andover",
            "Andrew County",
            "Andrews",
            "Andrews",
            "Andrews",
            "Andrews",
            "Andrews AFB",
            "Andrews County",
            "Androscoggin County",
            "Angel Fire",
            "Angelina County",
            "Angels Camp",
            "Angier",
            "Angleton",
            "Angola",
            "Angola",
            "Angola on the Lake",
            "Angwin",
            "Animas",
            "Ankeny",
            "Ann Arbor",
            "Anna",
            "Anna",
            "Anna",
            "Anna Maria",
            "Annandale",
            "Annandale",
            "Annandale",
            "Annapolis",
            "Anne Arundel County",
            "Annetta",
            "Anniston",
            "Annville",
            "Annville",
            "Anoka",
            "Anoka County",
            "Anson",
            "Anson County",
            "Ansonia",
            "Ansonia",
            "Ansted",
            "Antelope",
            "Antelope County",
            "Antelope Valley-Crestview",
            "Anthem",
            "Anthony",
            "Anthony",
            "Anthony",
            "Antigo",
            "Antioch",
            "Antioch",
            "Antlers",
            "Anton",
            "Antón Ruiz",
            "Antrim",
            "Antrim County",
            "Antwerp",
            "Anza",
            "Apache",
            "Apache County",
            "Apache Junction",
            "Apalachicola",
            "Apalachin",
            "Apex",
            "Apison",
            "Aplington",
            "Apollo",
            "Apollo Beach",
            "Apopka",
            "Appalachia",
            "Appanoose County",
            "Apple Creek",
            "Apple Mountain Lake",
            "Apple Valley",
            "Apple Valley",
            "Apple Valley",
            "Appleton",
            "Appleton",
            "Appleton",
            "Appleton City",
            "Applewood",
            "Appling County",
            "Appomattox",
            "Appomattox County",
            "Aptos",
            "Aptos Hills-Larkin Valley",
            "Aquebogue",
            "Aquia Harbour",
            "Arab",
            "Arabi",
            "Aragon",
            "Aransas County",
            "Aransas Pass",
            "Arapaho",
            "Arapahoe",
            "Arapahoe",
            "Arapahoe County",
            "Arbuckle",
            "Arbutus",
            "Arcade",
            "Arcade",
            "Arcadia",
            "Arcadia",
            "Arcadia",
            "Arcadia",
            "Arcadia",
            "Arcadia",
            "Arcanum",
            "Arcata",
            "Archbald",
            "Archbold",
            "Archdale",
            "Archer",
            "Archer City",
            "Archer County",
            "Archer Lodge",
            "Archie",
            "Archuleta County",
            "Arco",
            "Arcola",
            "Arcola",
            "Arden Hills",
            "Arden on the Severn",
            "Arden-Arcade",
            "Ardmore",
            "Ardmore",
            "Ardmore",
            "Ardsley",
            "Arecibo",
            "Arenac County",
            "Arenas Valley",
            "Argentine",
            "Argo",
            "Argos",
            "Argyle",
            "Arial",
            "Aristocrat Ranchettes",
            "Arivaca Junction",
            "Arizona City",
            "Arkadelphia",
            "Arkansas City",
            "Arkansas City",
            "Arkansas County",
            "Arkoma",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington",
            "Arlington County",
            "Arlington Heights",
            "Arlington Heights",
            "Arlington Heights",
            "Arma",
            "Armada",
            "Armona",
            "Armonk",
            "Armour",
            "Armstrong County",
            "Armstrong County",
            "Arnaudville",
            "Arnett",
            "Arnold",
            "Arnold",
            "Arnold",
            "Arnold",
            "Arnold",
            "Arnold Line",
            "Arnolds Park",
            "Aromas",
            "Aroostook County",
            "Arrochar",
            "Arroyo",
            "Arroyo Grande",
            "Arroyo Seco",
            "Artesia",
            "Artesia",
            "Arthur",
            "Arthur",
            "Arthur County",
            "Artondale",
            "Arundel",
            "Arvada",
            "Arverne",
            "Arvin",
            "Asbury",
            "Asbury Lake",
            "Asbury Park",
            "Ascension Parish",
            "Ash Flat",
            "Ash Grove",
            "Ashaway",
            "Ashburn",
            "Ashburn",
            "Ashburn",
            "Ashburnham",
            "Ashby",
            "Ashdown",
            "Ashe County",
            "Asheboro",
            "Asherton",
            "Asheville",
            "Ashfield",
            "Ashford",
            "Ashford",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland",
            "Ashland City",
            "Ashland County",
            "Ashland County",
            "Ashley",
            "Ashley",
            "Ashley",
            "Ashley County",
            "Ashtabula",
            "Ashtabula County",
            "Ashton",
            "Ashton-Sandy Spring",
            "Ashville",
            "Ashville",
            "Ashwaubenon",
            "Asotin",
            "Asotin County",
            "Aspen",
            "Aspen Hill",
            "Aspermont",
            "Aspinwall",
            "Assonet",
            "Assumption",
            "Assumption Parish",
            "Astatula",
            "Astor",
            "Astoria",
            "Astoria",
            "Astoria",
            "Atascadero",
            "Atascocita",
            "Atascosa County",
            "Atchison",
            "Atchison County",
            "Atchison County",
            "Atco",
            "Atglen",
            "Athena",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens",
            "Athens County",
            "Atherton",
            "Athol",
            "Atkins",
            "Atkins",
            "Atkins",
            "Atkinson",
            "Atkinson",
            "Atkinson County",
            "Atlanta",
            "Atlanta",
            "Atlanta",
            "Atlanta",
            "Atlantic",
            "Atlantic Beach",
            "Atlantic Beach",
            "Atlantic Beach",
            "Atlantic City",
            "Atlantic County",
            "Atlantic Highlands",
            "Atlantis",
            "Atmore",
            "Atoka",
            "Atoka",
            "Atoka",
            "Atoka County",
            "Attala County",
            "Attalla",
            "Attica",
            "Attica",
            "Attleboro",
            "Atwater",
            "Atwater",
            "Atwood",
            "Atwood",
            "Au Sable",
            "Auberry",
            "Aubrey",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn",
            "Auburn Gresham",
            "Auburn Hills",
            "Auburn Lake Trails",
            "Auburndale",
            "Audrain County",
            "Audubon",
            "Audubon",
            "Audubon",
            "Audubon County",
            "Audubon Park",
            "Audubon Park",
            "Auglaize County",
            "August",
            "Augusta",
            "Augusta",
            "Augusta",
            "Augusta",
            "Augusta",
            "Augusta",
            "Augusta",
            "Augusta County",
            "Ault",
            "Ault Field",
            "Aumsville",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora",
            "Aurora County",
            "Austell",
            "Austin",
            "Austin",
            "Austin",
            "Austin",
            "Austin County",
            "Austintown",
            "Autauga County",
            "Ava",
            "Avalon",
            "Avalon",
            "Avalon",
            "Avenal",
            "Avenel",
            "Aventura",
            "Avenue B and C",
            "Averill Park",
            "Avery County",
            "Avery Creek",
            "Avila Beach",
            "Avilla",
            "Avis",
            "Aviston",
            "Avoca",
            "Avoca",
            "Avocado Heights",
            "Avon",
            "Avon",
            "Avon",
            "Avon",
            "Avon",
            "Avon",
            "Avon",
            "Avon Center",
            "Avon Lake",
            "Avon Park",
            "Avon-by-the-Sea",
            "Avondale",
            "Avondale",
            "Avondale",
            "Avondale",
            "Avondale Estates",
            "Avonia",
            "Avoyelles Parish",
            "Avra Valley",
            "Awendaw",
            "Ayden",
            "Ayer",
            "Azalea Park",
            "Azle",
            "Aztalan",
            "Aztec",
            "Azusa",
            "Babbitt",
            "Babson Park",
            "Babylon",
            "Baca County",
            "Back Mountain",
            "Bacliff",
            "Bacon County",
            "Bad Axe",
            "Baden",
            "Baden",
            "Badger",
            "Badin",
            "Bagdad",
            "Bagdad",
            "Bagley",
            "Baidland",
            "Bailey County",
            "Baileys Crossroads",
            "Bainbridge",
            "Bainbridge",
            "Bainbridge",
            "Bainbridge",
            "Bainbridge Island",
            "Baird",
            "Bairoa",
            "Baiting Hollow",
            "Bajadero",
            "Bajandas",
            "Baker",
            "Baker",
            "Baker City",
            "Baker County",
            "Baker County",
            "Baker County",
            "Bakersfield",
            "Bakerstown",
            "Bakersville",
            "Bal Harbour",
            "Bala-Cynwyd",
            "Balch Springs",
            "Balcones Heights",
            "Bald Knob",
            "Baldwin",
            "Baldwin",
            "Baldwin",
            "Baldwin",
            "Baldwin",
            "Baldwin",
            "Baldwin",
            "Baldwin City",
            "Baldwin County",
            "Baldwin County",
            "Baldwin Harbor",
            "Baldwin Park",
            "Baldwinsville",
            "Baldwinville",
            "Baldwyn",
            "Balfour",
            "Ball",
            "Ball Ground",
            "Ballard",
            "Ballard County",
            "Ballenger Creek",
            "Ballinger",
            "Ballplay",
            "Ballston Spa",
            "Ballville",
            "Ballwin",
            "Bally",
            "Balm",
            "Balmville",
            "Balsam Lake",
            "Baltic",
            "Baltic",
            "Baltimore",
            "Baltimore",
            "Baltimore County",
            "Baltimore Highlands",
            "Bamberg",
            "Bamberg County",
            "Bandera",
            "Bandera County",
            "Bandon",
            "Bangor",
            "Bangor",
            "Bangor",
            "Bangor",
            "Bangor Trident Base",
            "Bangs",
            "Banks",
            "Banks County",
            "Banks Springs",
            "Banner County",
            "Banner Elk",
            "Banner Hill",
            "Banning",
            "Bannock County",
            "Bannockburn",
            "Bar Harbor",
            "Bar Nunn",
            "Baraboo",
            "Baraga",
            "Baraga County",
            "Barahona",
            "Barataria",
            "Barber County",
            "Barberton",
            "Barberton",
            "Barbour County",
            "Barbour County",
            "Barbourmeade",
            "Barboursville",
            "Barbourville",
            "Barceloneta",
            "Bardonia",
            "Bardstown",
            "Bardwell",
            "Bargersville",
            "Barker Heights",
            "Barling",
            "Barnegat",
            "Barnes County",
            "Barnes Lake-Millers Lake",
            "Barnesboro",
            "Barnesville",
            "Barnesville",
            "Barnesville",
            "Barneveld",
            "Barnhart",
            "Barnsdall",
            "Barnstable",
            "Barnstable County",
            "Barnstead",
            "Barnum Island",
            "Barnwell",
            "Barnwell County",
            "Barrackville",
            "Barranquitas",
            "Barre",
            "Barre",
            "Barren County",
            "Barrett",
            "Barrington",
            "Barrington",
            "Barrington",
            "Barrington",
            "Barrington Hills",
            "Barron",
            "Barron County",
            "Barrow",
            "Barrow County",
            "Barry",
            "Barry County",
            "Barry County",
            "Barstow",
            "Barstow Heights",
            "Bartholomew County",
            "Bartlesville",
            "Bartlett",
            "Bartlett",
            "Bartlett",
            "Bartlett",
            "Bartolo",
            "Barton",
            "Barton County",
            "Barton County",
            "Barton Creek",
            "Bartonsville",
            "Bartonville",
            "Bartonville",
            "Bartow",
            "Bartow County",
            "Barview",
            "Basalt",
            "Basehor",
            "Basile",
            "Basin",
            "Basin City",
            "Bass Lake",
            "Bassett",
            "Bassett",
            "Bastrop",
            "Bastrop",
            "Bastrop County",
            "Basye",
            "Batavia",
            "Batavia",
            "Batavia",
            "Bates County",
            "Batesburg",
            "Batesburg-Leesville",
            "Batesville",
            "Batesville",
            "Batesville",
            "Batesville",
            "Bath",
            "Bath",
            "Bath",
            "Bath",
            "Bath Beach",
            "Bath County",
            "Bath County",
            "Baton Rouge",
            "Battle Creek",
            "Battle Creek",
            "Battle Ground",
            "Battle Ground",
            "Battle Mountain",
            "Battlefield",
            "Battlement Mesa",
            "Baudette",
            "Bawcomville",
            "Baxley",
            "Baxter",
            "Baxter",
            "Baxter",
            "Baxter County",
            "Baxter Estates",
            "Baxter Springs",
            "Bay",
            "Bay City",
            "Bay City",
            "Bay City",
            "Bay County",
            "Bay County",
            "Bay Harbor",
            "Bay Harbor Islands",
            "Bay Hill",
            "Bay Minette",
            "Bay Park",
            "Bay Pines",
            "Bay Point",
            "Bay Saint Louis",
            "Bay Shore",
            "Bay Springs",
            "Bay Village",
            "Bay Wood",
            "Bayamon",
            "Bayard",
            "Bayard",
            "Bayboro",
            "Baychester",
            "Bayfield",
            "Bayfield County",
            "Baylor County",
            "Bayonet Point",
            "Bayonne",
            "Bayou Cane",
            "Bayou Gauche",
            "Bayou La Batre",
            "Bayou Vista",
            "Bayou Vista",
            "Bayport",
            "Bayport",
            "Bayshore",
            "Bayshore Gardens",
            "Bayside",
            "Bayside",
            "Bayside",
            "Baytown",
            "Bayview",
            "Bayville",
            "Bayville",
            "Beach",
            "Beach City",
            "Beach City",
            "Beach Haven",
            "Beach Haven West",
            "Beach Park",
            "Beachwood",
            "Beachwood",
            "Beacon",
            "Beacon Square",
            "Beadle County",
            "Beale Air Force Base",
            "Bealeton",
            "Bean Station",
            "Bear",
            "Bear Creek",
            "Bear Creek",
            "Bear Lake County",
            "Bear Rocks",
            "Bear Valley Springs",
            "Beardstown",
            "Beatrice",
            "Beatty",
            "Beattystown",
            "Beattyville",
            "Beaufort",
            "Beaufort",
            "Beaufort County",
            "Beaufort County",
            "Beaumont",
            "Beaumont",
            "Beauregard Parish",
            "Beaver",
            "Beaver",
            "Beaver",
            "Beaver",
            "Beaver City",
            "Beaver County",
            "Beaver County",
            "Beaver County",
            "Beaver Dam",
            "Beaver Dam",
            "Beaver Dam",
            "Beaver Dam Lake",
            "Beaver Falls",
            "Beavercreek",
            "Beavercreek",
            "Beaverdale",
            "Beaverdam Lake-Salisbury Mills",
            "Beaverhead County",
            "Beaverton",
            "Beaverton",
            "Beckemeyer",
            "Becker",
            "Becker County",
            "Becket",
            "Beckett",
            "Beckett Ridge",
            "Beckham County",
            "Beckley",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford",
            "Bedford County",
            "Bedford County",
            "Bedford County",
            "Bedford Heights",
            "Bedford Hills",
            "Bedminster",
            "Bedminster",
            "Bee Cave",
            "Bee County",
            "Bee Ridge",
            "Beebe",
            "Beech Grove",
            "Beech Mountain Lakes",
            "Beecher",
            "Beecher",
            "Beechwood",
            "Beechwood",
            "Beechwood Trails",
            "Beechwood Village",
            "Beeville",
            "Beggs",
            "Bel Air",
            "Bel Air North",
            "Bel Air South",
            "Bel-Nor",
            "Bel-Ridge",
            "Belchertown",
            "Belcourt",
            "Belding",
            "Belen",
            "Belfair",
            "Belfast",
            "Belfast",
            "Belfield",
            "Belford",
            "Belgium",
            "Belgrade",
            "Belgrade",
            "Belhaven",
            "Belington",
            "Belknap County",
            "Bell",
            "Bell Acres",
            "Bell County",
            "Bell County",
            "Bell Gardens",
            "Bella Vista",
            "Bella Vista",
            "Bellair-Meadowbrook Terrace",
            "Bellaire",
            "Bellaire",
            "Bellaire",
            "Bellaire",
            "Bellaire",
            "Bellbrook",
            "Belle",
            "Belle",
            "Belle Chasse",
            "Belle Fourche",
            "Belle Glade",
            "Belle Glade Camp",
            "Belle Harbor",
            "Belle Haven",
            "Belle Isle",
            "Belle Meade",
            "Belle Plaine",
            "Belle Plaine",
            "Belle Plaine",
            "Belle Plaine",
            "Belle Rose",
            "Belle Vernon",
            "Belleair",
            "Belleair Beach",
            "Belleair Bluffs",
            "Bellefontaine",
            "Bellefontaine Neighbors",
            "Bellefonte",
            "Bellefonte",
            "Bellerose",
            "Bellerose Terrace",
            "Belleview",
            "Belleville",
            "Belleville",
            "Belleville",
            "Belleville",
            "Belleville",
            "Belleville",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellevue",
            "Bellflower",
            "Bellingham",
            "Bellingham",
            "Bellmawr",
            "Bellmead",
            "Bellmore",
            "Bellows Falls",
            "Bellport",
            "Bells",
            "Bells",
            "Bellview",
            "Bellville",
            "Bellville",
            "Bellwood",
            "Bellwood",
            "Bellwood",
            "Belmar",
            "Belmond",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont",
            "Belmont County",
            "Belmont Cragin",
            "Belmont Estates",
            "Beloit",
            "Beloit",
            "Belpre",
            "Belton",
            "Belton",
            "Belton",
            "Beltrami County",
            "Beltsville",
            "Belvedere",
            "Belvedere",
            "Belvedere Park",
            "Belvidere",
            "Belvidere",
            "Belville",
            "Belzoni",
            "Bement",
            "Bemidji",
            "Ben Avon",
            "Ben Hill County",
            "Ben Lomond",
            "Benavides",
            "Benbrook",
            "Bend",
            "Benewah County",
            "Benicia",
            "Benitez",
            "Benjamin",
            "Benjamin",
            "Benkelman",
            "Benld",
            "Bennett",
            "Bennett County",
            "Bennettsville",
            "Bennington",
            "Bennington",
            "Bennington County",
            "Bennsville",
            "Bensenville",
            "Bensley",
            "Benson",
            "Benson",
            "Benson",
            "Benson",
            "Benson County",
            "Bensonhurst",
            "Bent County",
            "Bent Creek",
            "Bentleyville",
            "Benton",
            "Benton",
            "Benton",
            "Benton",
            "Benton",
            "Benton",
            "Benton",
            "Benton City",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton County",
            "Benton Harbor",
            "Benton Heights",
            "Bentonville",
            "Benwood",
            "Benzie County",
            "Berea",
            "Berea",
            "Berea",
            "Beresford",
            "Bergen",
            "Bergen Beach",
            "Bergen County",
            "Bergenfield",
            "Berino",
            "Berkeley",
            "Berkeley",
            "Berkeley",
            "Berkeley County",
            "Berkeley County",
            "Berkeley Heights",
            "Berkeley Lake",
            "Berkeley Springs",
            "Berkley",
            "Berkley",
            "Berkley",
            "Berks County",
            "Berkshire County",
            "Berlin",
            "Berlin",
            "Berlin",
            "Berlin",
            "Berlin",
            "Berlin",
            "Bermuda Dunes",
            "Bermuda Run",
            "Bernalillo",
            "Bernalillo County",
            "Bernardston",
            "Bernardsville",
            "Berne",
            "Bernice",
            "Bernie",
            "Berrien County",
            "Berrien County",
            "Berrien Springs",
            "Berry",
            "Berry Creek",
            "Berryville",
            "Berryville",
            "Berryville",
            "Berthoud",
            "Bertie County",
            "Bertram",
            "Bertsch-Oceanview",
            "Berwick",
            "Berwick",
            "Berwick",
            "Berwyn",
            "Berwyn",
            "Berwyn Heights",
            "Bessemer",
            "Bessemer",
            "Bessemer",
            "Bessemer City",
            "Betances",
            "Bethalto",
            "Bethany",
            "Bethany",
            "Bethany",
            "Bethany",
            "Bethany",
            "Bethany Beach",
            "Bethel",
            "Bethel",
            "Bethel",
            "Bethel",
            "Bethel",
            "Bethel",
            "Bethel Acres",
            "Bethel Census Area",
            "Bethel Heights",
            "Bethel Island",
            "Bethel Park",
            "Bethesda",
            "Bethesda",
            "Bethlehem",
            "Bethlehem",
            "Bethlehem",
            "Bethlehem Village",
            "Bethpage",
            "Bettendorf",
            "Beulah",
            "Beulah",
            "Beulaville",
            "Bevent",
            "Beverly",
            "Beverly",
            "Beverly",
            "Beverly",
            "Beverly Cove",
            "Beverly Hills",
            "Beverly Hills",
            "Beverly Hills",
            "Beverly Hills",
            "Bevil Oaks",
            "Bexar County",
            "Bexley",
            "Bibb County",
            "Bibb County",
            "Bicknell",
            "Biddeford",
            "Bienville Parish",
            "Big Bass Lake",
            "Big Bear City",
            "Big Bear Lake",
            "Big Beaver",
            "Big Bend",
            "Big Coppitt Key",
            "Big Flats",
            "Big Horn County",
            "Big Horn County",
            "Big Lake",
            "Big Lake",
            "Big Lake",
            "Big Lake",
            "Big Park",
            "Big Pine",
            "Big Pine Key",
            "Big Rapids",
            "Big River",
            "Big Rock",
            "Big Sandy",
            "Big Sky",
            "Big Spring",
            "Big Stone County",
            "Big Stone Gap",
            "Big Timber",
            "Bigfork",
            "Biggs",
            "Biglerville",
            "Billerica",
            "Billings",
            "Billings",
            "Billings County",
            "Billington Heights",
            "Biloxi",
            "Biltmore Forest",
            "Bingham County",
            "Bingham Farms",
            "Binghamton",
            "Biola",
            "Birch Bay",
            "Birch Run",
            "Birchwood",
            "Birchwood Lakes",
            "Birdsboro",
            "Birmingham",
            "Birmingham",
            "Bisbee",
            "Biscayne Park",
            "Biscoe",
            "Bishop",
            "Bishop",
            "Bishopville",
            "Bismarck",
            "Bismarck",
            "Bison",
            "Bithlo",
            "Bixby",
            "Black Canyon City",
            "Black Creek",
            "Black Diamond",
            "Black Diamond",
            "Black Earth",
            "Black Forest",
            "Black Hawk County",
            "Black Jack",
            "Black Lick",
            "Black Mountain",
            "Black Point-Green Point",
            "Black River",
            "Black River Falls",
            "Black Rock",
            "Blackfoot",
            "Blackford County",
            "Blackhawk",
            "Blackhawk",
            "Blacklick Estates",
            "Blacksburg",
            "Blacksburg",
            "Blackshear",
            "Blackstone",
            "Blackstone",
            "Blackville",
            "Blackwater",
            "Blackwell",
            "Blackwood",
            "Bladen County",
            "Bladenboro",
            "Bladensburg",
            "Blades",
            "Blaine",
            "Blaine",
            "Blaine",
            "Blaine County",
            "Blaine County",
            "Blaine County",
            "Blaine County",
            "Blair",
            "Blair",
            "Blair County",
            "Blairsville",
            "Blairsville",
            "Blakely",
            "Blakely",
            "Blanchard",
            "Blanchard",
            "Blanchester",
            "Blanco",
            "Blanco County",
            "Bland",
            "Bland County",
            "Blanding",
            "Blandon",
            "Blasdell",
            "Blauvelt",
            "Blawnox",
            "Bleckley County",
            "Bledsoe County",
            "Blennerhassett",
            "Bliss Corner",
            "Blissfield",
            "Bloomer",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield",
            "Bloomfield Hills",
            "Blooming Prairie",
            "Bloomingdale",
            "Bloomingdale",
            "Bloomingdale",
            "Bloomingdale",
            "Bloomingdale",
            "Bloomingdale",
            "Bloomington",
            "Bloomington",
            "Bloomington",
            "Bloomington",
            "Bloomington",
            "Bloomsburg",
            "Blossburg",
            "Blossom",
            "Blount County",
            "Blount County",
            "Blountstown",
            "Blountsville",
            "Blountville",
            "Blowing Rock",
            "Blue Ash",
            "Blue Ball",
            "Blue Bell",
            "Blue Earth",
            "Blue Earth County",
            "Blue Grass",
            "Blue Hills",
            "Blue Island",
            "Blue Lake",
            "Blue Mound",
            "Blue Mound",
            "Blue Point",
            "Blue Ridge",
            "Blue Ridge",
            "Blue Ridge",
            "Blue Springs",
            "Bluefield",
            "Bluefield",
            "Bluewell",
            "Bluff City",
            "Bluffdale",
            "Bluffton",
            "Bluffton",
            "Bluffton",
            "Blythe",
            "Blytheville",
            "Blythewood",
            "Boalsburg",
            "Boardman",
            "Boardman",
            "Boaz",
            "Boaz",
            "Boca Del Mar",
            "Boca Pointe",
            "Boca Raton",
            "Bodega Bay",
            "Bodfish",
            "Boerne",
            "Bogalusa",
            "Bogart",
            "Bogata",
            "Bogota",
            "Bohemia",
            "Bohners Lake",
            "Boiling Spring Lakes",
            "Boiling Springs",
            "Boiling Springs",
            "Boiling Springs",
            "Boise",
            "Boise City",
            "Boise County",
            "Bokeelia",
            "Boles Acres",
            "Boley",
            "Bolinas",
            "Bolindale",
            "Boling",
            "Bolingbrook",
            "Bolivar",
            "Bolivar",
            "Bolivar",
            "Bolivar",
            "Bolivar County",
            "Bolivar Peninsula",
            "Bolivia",
            "Bollinger County",
            "Bolton",
            "Bon Air",
            "Bon Aqua Junction",
            "Bon Homme County",
            "Bonadelle Ranchos-Madera Ranchos",
            "Bonanza",
            "Bond County",
            "Bondsville",
            "Bonduel",
            "Bondurant",
            "Bonham",
            "Bonifay",
            "Bonita",
            "Bonita Springs",
            "Bonne Terre",
            "Bonneau Beach",
            "Bonneauville",
            "Bonner County",
            "Bonner Springs",
            "Bonner-West Riverside",
            "Bonners Ferry",
            "Bonneville County",
            "Bonney Lake",
            "Bonny Doon",
            "Bono",
            "Bonsall",
            "Booker",
            "Boone",
            "Boone",
            "Boone County",
            "Boone County",
            "Boone County",
            "Boone County",
            "Boone County",
            "Boone County",
            "Boone County",
            "Boone County",
            "Booneville",
            "Booneville",
            "Booneville",
            "Boonsboro",
            "Boonton",
            "Boonville",
            "Boonville",
            "Boonville",
            "Boonville",
            "Boonville",
            "Boothbay",
            "Boothbay Harbor",
            "Boothwyn",
            "Boqueron",
            "Boquerón Cabo Rojo",
            "Borden County",
            "Bordentown",
            "Borger",
            "Boron",
            "Boronda",
            "Borough Park",
            "Borrego Springs",
            "Boscawen",
            "Boscobel",
            "Bosque County",
            "Bosque Farms",
            "Bossier City",
            "Bossier Parish",
            "Boston",
            "Boston",
            "Boston",
            "Boston Heights",
            "Bostonia",
            "Boswell",
            "Boswell's Corner",
            "Botetourt County",
            "Bothell",
            "Bothell East",
            "Bothell West",
            "Botkins",
            "Bottineau",
            "Bottineau County",
            "Boulder",
            "Boulder",
            "Boulder City",
            "Boulder County",
            "Boulder Creek",
            "Boulder Hill",
            "Boulevard Gardens",
            "Boulevard Park",
            "Bound Brook",
            "Boundary County",
            "Bountiful",
            "Bourbon",
            "Bourbon",
            "Bourbon County",
            "Bourbon County",
            "Bourbonnais",
            "Bourg",
            "Bourne",
            "Boutte",
            "Bovina",
            "Bow Bog",
            "Bowbells",
            "Bowdon",
            "Bowie",
            "Bowie",
            "Bowie County",
            "Bowleys Quarters",
            "Bowling Green",
            "Bowling Green",
            "Bowling Green",
            "Bowling Green",
            "Bowling Green",
            "Bowling Green",
            "Bowman",
            "Bowman County",
            "Bowmansville",
            "Box Butte County",
            "Box Elder",
            "Box Elder County",
            "Boxborough",
            "Boxford",
            "Boyceville",
            "Boyd",
            "Boyd County",
            "Boyd County",
            "Boydton",
            "Boyertown",
            "Boyes Hot Springs",
            "Boyette",
            "Boyle County",
            "Boyle Heights",
            "Boylston",
            "Boyne City",
            "Boynton Beach",
            "Bozeman",
            "Bracey",
            "Bracken County",
            "Brackenridge",
            "Brackettville",
            "Bradbury",
            "Braddock",
            "Braddock Heights",
            "Braddock Hills",
            "Bradenton",
            "Bradenton Beach",
            "Bradford",
            "Bradford",
            "Bradford",
            "Bradford",
            "Bradford",
            "Bradford County",
            "Bradford County",
            "Bradford Woods",
            "Bradley",
            "Bradley",
            "Bradley",
            "Bradley Beach",
            "Bradley County",
            "Bradley County",
            "Bradley Gardens",
            "Bradner",
            "Brady",
            "Braham",
            "Braidwood",
            "Brainerd",
            "Braintree",
            "Brambleton",
            "Branch",
            "Branch County",
            "Brandenburg",
            "Brandermill",
            "Brandon",
            "Brandon",
            "Brandon",
            "Brandon",
            "Brandywine",
            "Branford",
            "Branford Center",
            "Branson",
            "Brantley County",
            "Braselton",
            "Brass Castle",
            "Bratenahl",
            "Brattleboro",
            "Brawley",
            "Braxton County",
            "Bray",
            "Brazil",
            "Brazoria",
            "Brazoria County",
            "Brazos County",
            "Brea",
            "Breathitt County",
            "Breaux Bridge",
            "Breckenridge",
            "Breckenridge",
            "Breckenridge",
            "Breckenridge",
            "Breckenridge Hills",
            "Breckinridge Center",
            "Breckinridge County",
            "Brecksville",
            "Breese",
            "Breezy Point",
            "Breinigsville",
            "Bremen",
            "Bremen",
            "Bremen",
            "Bremer County",
            "Bremerton",
            "Brenas",
            "Brenham",
            "Brent",
            "Brent",
            "Brentwood",
            "Brentwood",
            "Brentwood",
            "Brentwood",
            "Brentwood",
            "Brentwood",
            "Brentwood",
            "Brentwood Estates",
            "Bressler",
            "Bret Harte",
            "Brevard",
            "Brevard County",
            "Brewer",
            "Brewerton",
            "Brewster",
            "Brewster",
            "Brewster",
            "Brewster",
            "Brewster",
            "Brewster County",
            "Brewster Hill",
            "Brewton",
            "Briar",
            "Briarcliff",
            "Briarcliff Manor",
            "Briarwood",
            "Brice Prairie",
            "Brices Creek",
            "Brickerville",
            "Bridge City",
            "Bridge City",
            "Bridgehampton",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeport",
            "Bridgeton",
            "Bridgeton",
            "Bridgetown",
            "Bridgeview",
            "Bridgeville",
            "Bridgeville",
            "Bridgewater",
            "Bridgewater",
            "Bridgewater",
            "Bridgewater",
            "Bridgman",
            "Bridgton",
            "Bridport",
            "Brielle",
            "Brier",
            "Brigantine",
            "Brigham City",
            "Bright",
            "Brighton",
            "Brighton",
            "Brighton",
            "Brighton",
            "Brighton",
            "Brighton",
            "Brighton Beach",
            "Brighton Park",
            "Brightwaters",
            "Brightwood",
            "Brilliant",
            "Brillion",
            "Brimfield",
            "Brimfield",
            "Brinckerhoff",
            "Brinkley",
            "Brisbane",
            "Briscoe County",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol",
            "Bristol Bay Borough",
            "Bristol County",
            "Bristol County",
            "Bristow",
            "Britt",
            "Brittany Farms-Highlands",
            "Britton",
            "Broad Channel",
            "Broad Creek",
            "Broad Ripple",
            "Broadalbin",
            "Broadlands",
            "Broadmoor",
            "Broadus",
            "Broadview",
            "Broadview Heights",
            "Broadview Park",
            "Broadwater County",
            "Broadway",
            "Broadway",
            "Brock Hall",
            "Brockport",
            "Brockton",
            "Brockway",
            "Brocton",
            "Brodhead",
            "Brodhead",
            "Brodheadsville",
            "Brogden",
            "Broken Arrow",
            "Broken Bow",
            "Broken Bow",
            "Bronson",
            "Bronson",
            "Bronx",
            "Bronxville",
            "Brook Highland",
            "Brook Park",
            "Brookdale",
            "Brookdale",
            "Brookdale",
            "Brooke County",
            "Brookfield",
            "Brookfield",
            "Brookfield",
            "Brookfield Center",
            "Brookhaven",
            "Brookhaven",
            "Brookhaven",
            "Brookhaven",
            "Brookhaven",
            "Brookings",
            "Brookings",
            "Brookings County",
            "Brookland",
            "Brooklawn",
            "Brooklet",
            "Brookline",
            "Brookline",
            "Brooklyn",
            "Brooklyn",
            "Brooklyn",
            "Brooklyn",
            "Brooklyn",
            "Brooklyn",
            "Brooklyn Center",
            "Brooklyn Heights",
            "Brooklyn Heights",
            "Brooklyn Park",
            "Brooklyn Park",
            "Brookmont",
            "Brookneal",
            "Brookridge",
            "Brooks",
            "Brooks",
            "Brooks County",
            "Brooks County",
            "Brookshire",
            "Brookside",
            "Brookside",
            "Brookside Village",
            "Brookston",
            "Brooksville",
            "Brooksville",
            "Brooksville",
            "Brooktrails",
            "Brookville",
            "Brookville",
            "Brookville",
            "Brookville",
            "Brookwood",
            "Broomall",
            "Broome County",
            "Broomfield",
            "Broomfield County",
            "Brothertown",
            "Broussard",
            "Broward County",
            "Broward Estates",
            "Brown City",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown County",
            "Brown Deer",
            "Brownfield",
            "Brownfield",
            "Browning",
            "Brownlee Park",
            "Browns Lake",
            "Browns Mills",
            "Browns Point",
            "Brownsboro",
            "Brownsburg",
            "Brownsfield",
            "Brownstown",
            "Brownstown",
            "Brownsville",
            "Brownsville",
            "Brownsville",
            "Brownsville",
            "Brownsville",
            "Brownsville",
            "Brownsville",
            "Brownsville",
            "Browntown",
            "Brownville",
            "Brownville",
            "Brownville",
            "Brownwood",
            "Broxton",
            "Bruce",
            "Bruceton",
            "Bruceville-Eddy",
            "Brule County",
            "Brundidge",
            "Brunswick",
            "Brunswick",
            "Brunswick",
            "Brunswick",
            "Brunswick",
            "Brunswick County",
            "Brunswick County",
            "Brush",
            "Brush Fork",
            "Brush Prairie",
            "Brushy Creek",
            "Brusly",
            "Brussels",
            "Bryan",
            "Bryan",
            "Bryan County",
            "Bryan County",
            "Bryans Road",
            "Bryant",
            "Bryant",
            "Bryn Athyn",
            "Bryn Mawr",
            "Bryn Mawr-Skyway",
            "Bryson City",
            "Buchanan",
            "Buchanan",
            "Buchanan",
            "Buchanan",
            "Buchanan County",
            "Buchanan County",
            "Buchanan County",
            "Buchanan Dam",
            "Buckeye",
            "Buckeye Lake",
            "Buckeystown",
            "Buckfield",
            "Buckhall",
            "Buckhannon",
            "Buckhead Ridge",
            "Buckhorn",
            "Buckingham",
            "Buckingham",
            "Buckingham County",
            "Buckland",
            "Buckley",
            "Buckner",
            "Buckner",
            "Bucks County",
            "Bucksport",
            "Bucyrus",
            "Buda",
            "Budd Lake",
            "Bude",
            "Buechel",
            "Buellton",
            "Buena",
            "Buena Park",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista",
            "Buena Vista County",
            "Buenaventura Lakes",
            "Bufalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo",
            "Buffalo (historical)",
            "Buffalo County",
            "Buffalo County",
            "Buffalo County",
            "Buffalo Grove",
            "Buford",
            "Buhl",
            "Buhler",
            "Buies Creek",
            "Bull Run",
            "Bull Run Mountain Estates",
            "Bull Shoals",
            "Bull Valley",
            "Bullard",
            "Bullhead City",
            "Bullitt County",
            "Bulloch County",
            "Bullock County",
            "Bulverde",
            "Buna",
            "Bunche Park",
            "Buncombe County",
            "Bunk Foss",
            "Bunker Hill",
            "Bunker Hill",
            "Bunker Hill Village",
            "Bunkerville",
            "Bunkie",
            "Bunnell",
            "Burbank",
            "Burbank",
            "Burbank",
            "Bureau County",
            "Burgaw",
            "Burgettstown",
            "Burien",
            "Burkburnett",
            "Burke",
            "Burke",
            "Burke County",
            "Burke County",
            "Burke County",
            "Burkesville",
            "Burleigh County",
            "Burleson",
            "Burleson County",
            "Burley",
            "Burley",
            "Burlingame",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington",
            "Burlington County",
            "Burnet",
            "Burnet County",
            "Burnett County",
            "Burnettown",
            "Burney",
            "Burnham",
            "Burnham",
            "Burnham",
            "Burns",
            "Burns",
            "Burns Flat",
            "Burns Harbor",
            "Burnsville",
            "Burnsville",
            "Burnt Store Marina",
            "Burr Ridge",
            "Burt",
            "Burt County",
            "Burton",
            "Burton",
            "Burton",
            "Burtonsville",
            "Burwell",
            "Bushland",
            "Bushnell",
            "Bushnell",
            "Bushwick",
            "Bushyhead",
            "Butcher's Hill",
            "Butler",
            "Butler",
            "Butler",
            "Butler",
            "Butler",
            "Butler",
            "Butler",
            "Butler Beach",
            "Butler County",
            "Butler County",
            "Butler County",
            "Butler County",
            "Butler County",
            "Butler County",
            "Butler County",
            "Butler County",
            "Butner",
            "Butte",
            "Butte",
            "Butte",
            "Butte County",
            "Butte County",
            "Butte County",
            "Butte-Silver Bow (Balance)",
            "Buttonwillow",
            "Butts County",
            "Buxton",
            "Buxton",
            "Buzzards Bay",
            "Byers",
            "Byesville",
            "Byhalia",
            "Bylas",
            "Byng",
            "Bynum",
            "Byram",
            "Byram",
            "Byrdstown",
            "Byrnes Mill",
            "Byron",
            "Byron",
            "Byron",
            "Byron",
            "Byron Center",
            "Bystrom",
            "Caban",
            "Cabarrus County",
            "Cabazon",
            "Cabell County",
            "Cabin John",
            "Cabo Rojo",
            "Cabo Rojo Municipio",
            "Cabool",
            "Cabot",
            "Cacao",
            "Cache",
            "Cache County",
            "Cactus",
            "Cactus Flat",
            "Caddo",
            "Caddo County",
            "Caddo Mills",
            "Caddo Parish",
            "Cade",
            "Cadillac",
            "Cadiz",
            "Cadiz",
            "Cadott",
            "Caguas",
            "Cahaba Heights",
            "Cahokia",
            "Cairo",
            "Cairo",
            "Cairo",
            "Cajahs Mountain",
            "Calabasas",
            "Calabash",
            "Calais",
            "Calaveras County",
            "Calcasieu Parish",
            "Calcium",
            "Calcutta",
            "Caldwell",
            "Caldwell",
            "Caldwell",
            "Caldwell",
            "Caldwell",
            "Caldwell County",
            "Caldwell County",
            "Caldwell County",
            "Caldwell County",
            "Caldwell Parish",
            "Caledonia",
            "Caledonia",
            "Caledonia",
            "Caledonia",
            "Caledonia",
            "Caledonia County",
            "Calera",
            "Calera",
            "Calexico",
            "Calhoun",
            "Calhoun",
            "Calhoun City",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun County",
            "Calhoun Falls",
            "Calico Rock",
            "Caliente",
            "Califon",
            "California",
            "California",
            "California",
            "California City",
            "Calimesa",
            "Calipatria",
            "Calistoga",
            "Callahan",
            "Callahan County",
            "Callaway",
            "Callaway County",
            "Callender",
            "Calloway County",
            "Caln",
            "Calumet",
            "Calumet City",
            "Calumet County",
            "Calumet Park",
            "Calvert",
            "Calvert City",
            "Calvert County",
            "Calverton",
            "Calverton",
            "Calverton Park",
            "Camanche",
            "Camano",
            "Camargo",
            "Camarillo",
            "Camas",
            "Camas County",
            "Cambria",
            "Cambria",
            "Cambria County",
            "Cambria Heights",
            "Cambrian Park",
            "Cambridge",
            "Cambridge",
            "Cambridge",
            "Cambridge",
            "Cambridge",
            "Cambridge",
            "Cambridge",
            "Cambridge",
            "Cambridge City",
            "Cambridge Springs",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden",
            "Camden County",
            "Camden County",
            "Camden County",
            "Camden County",
            "Camdenton",
            "Cameron",
            "Cameron",
            "Cameron",
            "Cameron",
            "Cameron County",
            "Cameron County",
            "Cameron Parish",
            "Cameron Park",
            "Cameron Park",
            "Cameron Park Colonia",
            "Camilla",
            "Camillus",
            "Camino",
            "Camp County",
            "Camp Hill",
            "Camp Lake",
            "Camp Meeker",
            "Camp Pendleton North",
            "Camp Pendleton South",
            "Camp Point",
            "Camp Springs",
            "Camp Swift",
            "Camp Verde",
            "Campanilla",
            "Campbell",
            "Campbell",
            "Campbell",
            "Campbell",
            "Campbell County",
            "Campbell County",
            "Campbell County",
            "Campbell County",
            "Campbell County",
            "Campbellsport",
            "Campbellsville",
            "Campbelltown",
            "Campion",
            "Campo",
            "Campo Rico",
            "Campti",
            "Campton",
            "Camuy",
            "Cana",
            "Canaan",
            "Canaan",
            "Canadian",
            "Canadian County",
            "Canadian Lakes",
            "Canajoharie",
            "Canal Fulton",
            "Canal Winchester",
            "Canandaigua",
            "Canarsie",
            "Canastota",
            "Canby",
            "Canby",
            "Candelaria",
            "Candelaria Arenas",
            "Candelero Arriba",
            "Candia",
            "Candler County",
            "Candler-McAfee",
            "Cando",
            "Cane Savannah",
            "Caney",
            "Canfield",
            "Canisteo",
            "Cannelton",
            "Cannon Air Force Base",
            "Cannon Beach",
            "Cannon County",
            "Cannon Falls",
            "Canoga Park",
            "Cañon City",
            "Canonsburg",
            "Canóvanas",
            "Canterbury",
            "Canterwood",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton",
            "Canton Valley",
            "Cantonment",
            "Canutillo",
            "Canyon",
            "Canyon City",
            "Canyon County",
            "Canyon Day",
            "Canyon Lake",
            "Canyon Lake",
            "Canyon Rim",
            "Canyonville",
            "Capac",
            "Cape Canaveral",
            "Cape Carteret",
            "Cape Charles",
            "Cape Coral",
            "Cape Girardeau",
            "Cape Girardeau County",
            "Cape May",
            "Cape May County",
            "Cape May Court House",
            "Cape Neddick",
            "Cape Saint Claire",
            "Capitan",
            "Capitanejo",
            "Capitol Heights",
            "Capitola",
            "Capron",
            "Captain Cook",
            "Captains Cove",
            "Caraway",
            "Carbon Cliff",
            "Carbon County",
            "Carbon County",
            "Carbon County",
            "Carbon County",
            "Carbon Hill",
            "Carbondale",
            "Carbondale",
            "Carbondale",
            "Carbondale",
            "Carbonville",
            "Cardington",
            "Carefree",
            "Carencro",
            "Carey",
            "Caribou",
            "Caribou County",
            "Carl Junction",
            "Carle Place",
            "Carleton",
            "Carlin",
            "Carlinville",
            "Carlisle",
            "Carlisle",
            "Carlisle",
            "Carlisle",
            "Carlisle",
            "Carlisle",
            "Carlisle County",
            "Carlisle-Rockledge",
            "Carlsbad",
            "Carlsbad",
            "Carlstadt",
            "Carlton",
            "Carlton",
            "Carlton County",
            "Carlyle",
            "Carlyss",
            "Carmel",
            "Carmel",
            "Carmel",
            "Carmel Hamlet",
            "Carmel Valley Village",
            "Carmel-by-the-Sea",
            "Carmi",
            "Carmichael",
            "Carnation",
            "Carnegie",
            "Carnegie",
            "Carnesville",
            "Carney",
            "Carneys Point",
            "Carnot-Moon",
            "Carnuel",
            "Caro",
            "Carol City",
            "Carol Stream",
            "Carolina",
            "Carolina Beach",
            "Carolina Shores",
            "Caroline County",
            "Caroline County",
            "Carpentersville",
            "Carpinteria",
            "Carrabelle",
            "Carrboro",
            "Carriage Club",
            "Carrier Mills",
            "Carriere",
            "Carrington",
            "Carrizales",
            "Carrizo Springs",
            "Carrizozo",
            "Carroll",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll County",
            "Carroll Valley",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollton",
            "Carrollwood",
            "Carrollwood Village",
            "Carson",
            "Carson",
            "Carson",
            "Carson City",
            "Carson City",
            "Carson County",
            "Carter County",
            "Carter County",
            "Carter County",
            "Carter County",
            "Carter County",
            "Carter Lake",
            "Carteret",
            "Carteret County",
            "Cartersville",
            "Carterville",
            "Carterville",
            "Carthage",
            "Carthage",
            "Carthage",
            "Carthage",
            "Carthage",
            "Carthage",
            "Carthage",
            "Caruthers",
            "Caruthersville",
            "Carver",
            "Carver",
            "Carver County",
            "Carver Ranches",
            "Carville",
            "Cary",
            "Cary",
            "Caryville",
            "Casa Blanca",
            "Casa Conejo",
            "Casa de Oro-Mount Helix",
            "Casa Grande",
            "Casas Adobes",
            "Cascade",
            "Cascade",
            "Cascade County",
            "Cascade Locks",
            "Cascade Valley",
            "Cascade-Chipita Park",
            "Casey",
            "Casey County",
            "Caseyville",
            "Cashmere",
            "Cashton",
            "Casper",
            "Cass City",
            "Cass County",
            "Cass County",
            "Cass County",
            "Cass County",
            "Cass County",
            "Cass County",
            "Cass County",
            "Cass County",
            "Cass County",
            "Casselberry",
            "Casselton",
            "Cassia County",
            "Cassopolis",
            "Cassville",
            "Castaic",
            "Castanea",
            "Castine",
            "Castle Dale",
            "Castle Hayne",
            "Castle Hills",
            "Castle Pines",
            "Castle Pines North",
            "Castle Point",
            "Castle Rock",
            "Castle Rock",
            "Castle Shannon",
            "Castleton",
            "Castleton-on-Hudson",
            "Castlewood",
            "Castlewood",
            "Castro County",
            "Castro Valley",
            "Castroville",
            "Castroville",
            "Caswell County",
            "Catahoula",
            "Catahoula Parish",
            "Catalina",
            "Catalina Foothills",
            "Cataño",
            "Catasauqua",
            "Catawba",
            "Catawba County",
            "Catawissa",
            "Cathcart",
            "Cathedral City",
            "Cathlamet",
            "Catlettsburg",
            "Catlin",
            "Cato",
            "Catonsville",
            "Catoosa",
            "Catoosa County",
            "Catron County",
            "Catskill",
            "Cattaraugus County",
            "Cavalier",
            "Cavalier County",
            "Cave City",
            "Cave City",
            "Cave Creek",
            "Cave Junction",
            "Cave Spring",
            "Cave Spring",
            "Cave Springs",
            "Cavetown",
            "Cayce",
            "Cayey",
            "Cayuco",
            "Cayucos",
            "Cayuga",
            "Cayuga County",
            "Cayuga Heights",
            "Cazenovia",
            "Cecil County",
            "Cecil-Bishop",
            "Cecilia",
            "Cedar Bluff",
            "Cedar Bluff",
            "Cedar City",
            "Cedar County",
            "Cedar County",
            "Cedar County",
            "Cedar Falls",
            "Cedar Glen Lakes",
            "Cedar Glen West",
            "Cedar Grove",
            "Cedar Grove",
            "Cedar Grove",
            "Cedar Hill",
            "Cedar Hill",
            "Cedar Hills",
            "Cedar Hills",
            "Cedar Lake",
            "Cedar Mill",
            "Cedar Park",
            "Cedar Point",
            "Cedar Rapids",
            "Cedar Ridge",
            "Cedar Springs",
            "Cedarburg",
            "Cedaredge",
            "Cedarhurst",
            "Cedartown",
            "Cedarville",
            "Cedarville",
            "Ceiba",
            "Ceiba Municipio",
            "Celada",
            "Celebration",
            "Celina",
            "Celina",
            "Celina",
            "Celoron",
            "Cementon",
            "Centennial",
            "Centennial Park",
            "Center",
            "Center",
            "Center",
            "Center",
            "Center City",
            "Center City",
            "Center Harbor",
            "Center Hill",
            "Center Line",
            "Center Moriches",
            "Center Point",
            "Center Point",
            "Centerburg",
            "Centereach",
            "Centerfield",
            "Centerport",
            "Centerton",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Centerville",
            "Central",
            "Central",
            "Central",
            "Central Aguirre",
            "Central City",
            "Central City",
            "Central City",
            "Central City",
            "Central City",
            "Central City",
            "Central City",
            "Central Falls",
            "Central Garage",
            "Central Gardens",
            "Central Heights-Midland City",
            "Central High",
            "Central Islip",
            "Central Park",
            "Central Point",
            "Central Square",
            "Central Valley",
            "Central Valley (historical)",
            "Central Waterford",
            "Centralia",
            "Centralia",
            "Centralia",
            "Centre",
            "Centre County",
            "Centre Hall",
            "Centreville",
            "Centreville",
            "Centreville",
            "Centreville",
            "Centreville",
            "Centreville",
            "Century",
            "Century City",
            "Ceredo",
            "Ceres",
            "Cerritos",
            "Cerro Gordo",
            "Cerro Gordo County",
            "César Chávez",
            "Cetronia",
            "Chackbay",
            "Chadbourn",
            "Chadron",
            "Chadwicks",
            "Chaffee",
            "Chaffee County",
            "Chagrin Falls",
            "Chalco",
            "Chalfont",
            "Chalkville",
            "Challenge-Brownsville",
            "Challis",
            "Chalmette",
            "Chama",
            "Chamberlain",
            "Chamberlayne",
            "Chambers County",
            "Chambers County",
            "Chambersburg",
            "Chamblee",
            "Champaign",
            "Champaign County",
            "Champaign County",
            "Champion Heights",
            "Champlain",
            "Champlin",
            "Chandler",
            "Chandler",
            "Chandler",
            "Chandler",
            "Chanhassen",
            "Channahon",
            "Channel Islands Beach",
            "Channel Lake",
            "Channelview",
            "Channing",
            "Chantilly",
            "Chanute",
            "Chaparral",
            "Chapel Hill",
            "Chapel Hill",
            "Chapin",
            "Chapman",
            "Chapmanville",
            "Chappaqua",
            "Chappell",
            "Chardon",
            "Charenton",
            "Chariton",
            "Chariton County",
            "Charlack",
            "Charlemont",
            "Charleroi",
            "Charles City",
            "Charles City",
            "Charles City County",
            "Charles County",
            "Charles Mix County",
            "Charles Town",
            "Charles Village",
            "Charleston",
            "Charleston",
            "Charleston",
            "Charleston",
            "Charleston",
            "Charleston",
            "Charleston",
            "Charleston",
            "Charleston County",
            "Charlestown",
            "Charlestown",
            "Charlestown",
            "Charlestown",
            "Charlevoix",
            "Charlevoix County",
            "Charlotte",
            "Charlotte",
            "Charlotte",
            "Charlotte",
            "Charlotte",
            "Charlotte County",
            "Charlotte County",
            "Charlotte Court House",
            "Charlotte Hall",
            "Charlotte Harbor",
            "Charlotte Park",
            "Charlottesville",
            "Charlton",
            "Charlton County",
            "Charter Oak",
            "Chase City",
            "Chase County",
            "Chase County",
            "Chaska",
            "Chatfield",
            "Chatham",
            "Chatham",
            "Chatham",
            "Chatham",
            "Chatham",
            "Chatham County",
            "Chatham County",
            "Chatmoss",
            "Chatom",
            "Chatsworth",
            "Chatsworth",
            "Chatsworth",
            "Chattahoochee",
            "Chattahoochee County",
            "Chattahoochee Hills",
            "Chattanooga",
            "Chattanooga Valley",
            "Chattooga County",
            "Chauncey",
            "Chautauqua County",
            "Chautauqua County",
            "Chauvin",
            "Chaves County",
            "Cheat Lake",
            "Cheatham County",
            "Chebanse",
            "Cheboygan",
            "Cheboygan County",
            "Checotah",
            "Cheektowaga",
            "Chehalis",
            "Chelan",
            "Chelan County",
            "Chelmsford",
            "Chelsea",
            "Chelsea",
            "Chelsea",
            "Chelsea",
            "Chelsea",
            "Chelsea",
            "Chelsea",
            "Chemung County",
            "Chenango Bridge",
            "Chenango County",
            "Cheney",
            "Cheney",
            "Chenoa",
            "Chenoweth",
            "Chepachet",
            "Cheraw",
            "Cherokee",
            "Cherokee",
            "Cherokee",
            "Cherokee",
            "Cherokee County",
            "Cherokee County",
            "Cherokee County",
            "Cherokee County",
            "Cherokee County",
            "Cherokee County",
            "Cherokee County",
            "Cherokee County",
            "Cherokee Village",
            "Cherry County",
            "Cherry Creek",
            "Cherry Grove",
            "Cherry Hill",
            "Cherry Hill",
            "Cherry Hill Mall",
            "Cherry Hills Village",
            "Cherry Valley",
            "Cherry Valley",
            "Cherryfield",
            "Cherryland",
            "Cherryvale",
            "Cherryvale",
            "Cherryville",
            "Cherryville",
            "Chesaning",
            "Chesapeake",
            "Chesapeake",
            "Chesapeake Beach",
            "Chesapeake Ranch Estates",
            "Cheshire",
            "Cheshire County",
            "Cheshire Village",
            "Chesilhurst",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester",
            "Chester Center",
            "Chester County",
            "Chester County",
            "Chester County",
            "Chester Heights",
            "Chester Springs",
            "Chesterbrook",
            "Chesterfield",
            "Chesterfield",
            "Chesterfield",
            "Chesterfield",
            "Chesterfield",
            "Chesterfield County",
            "Chesterfield County",
            "Chesterfield Court House",
            "Chesterland",
            "Chesterton",
            "Chestertown",
            "Chesterville",
            "Chestnut Ridge",
            "Cheswick",
            "Cheswold",
            "Chetek",
            "Chetopa",
            "Chevak",
            "Cheval",
            "Cheverly",
            "Cheviot",
            "Chevy Chase",
            "Chevy Chase",
            "Chevy Chase Heights",
            "Chevy Chase Village",
            "Chewelah",
            "Cheyenne",
            "Cheyenne",
            "Cheyenne County",
            "Cheyenne County",
            "Cheyenne County",
            "Cheyenne Wells",
            "Chicago",
            "Chicago Heights",
            "Chicago Lawn",
            "Chicago Loop",
            "Chicago Ridge",
            "Chichester",
            "Chickamauga",
            "Chickasaw",
            "Chickasaw County",
            "Chickasaw County",
            "Chickasha",
            "Chico",
            "Chico",
            "Chico",
            "Chicopee",
            "Chicora",
            "Chicot County",
            "Chiefland",
            "Childersburg",
            "Childress",
            "Childress County",
            "Chilhowie",
            "Chillicothe",
            "Chillicothe",
            "Chillicothe",
            "Chillum",
            "Chilton",
            "Chilton County",
            "Chimayo",
            "China",
            "China",
            "China Grove",
            "China Grove",
            "China Lake Acres",
            "Chinatown",
            "Chinchilla",
            "Chincoteague",
            "Chinle",
            "Chino",
            "Chino Hills",
            "Chino Valley",
            "Chinook",
            "Chipley",
            "Chippewa County",
            "Chippewa County",
            "Chippewa County",
            "Chippewa Falls",
            "Chisago City",
            "Chisago County",
            "Chisholm",
            "Chisholm",
            "Chittenango",
            "Chittenden",
            "Chittenden County",
            "Choccolocco",
            "Choctaw",
            "Choctaw County",
            "Choctaw County",
            "Choctaw County",
            "Choctaw Lake",
            "Choteau",
            "Chouteau",
            "Chouteau County",
            "Chowan County",
            "Chowchilla",
            "Chrisman",
            "Christian County",
            "Christian County",
            "Christian County",
            "Christiana",
            "Christiana",
            "Christiansburg",
            "Christmas",
            "Christopher",
            "Chualar",
            "Chubbuck",
            "Chula Vista",
            "Chuluota",
            "Church Hill",
            "Church Hill",
            "Church Point",
            "Church Rock",
            "Churchill",
            "Churchill",
            "Churchill County",
            "Churchville",
            "Churchville",
            "Churubusco",
            "Ciales",
            "Ciales Municipio",
            "Cibecue",
            "Cibola County",
            "Cibolo",
            "Cicero",
            "Cicero",
            "Cidra",
            "Cienega Springs",
            "Cienegas Terrace",
            "Cimarron",
            "Cimarron County",
            "Cimarron Hills",
            "Cincinnati",
            "Cinco Ranch",
            "Cinnaminson",
            "Circle",
            "Circle D-KC Estates",
            "Circle Pines",
            "Circleville",
            "Cisco",
            "Citra",
            "Citronelle",
            "Citrus",
            "Citrus City",
            "Citrus County",
            "Citrus Heights",
            "Citrus Hills",
            "Citrus Park",
            "Citrus Park",
            "Citrus Ridge",
            "Citrus Springs",
            "City and Borough of Wrangell",
            "City and County of San Francisco",
            "City Island",
            "City of Alexandria",
            "City of Baltimore",
            "City of Bedford",
            "City of Bristol",
            "City of Buena Vista",
            "City of Charlottesville",
            "City of Chesapeake",
            "City of Colonial Heights",
            "City of Covington",
            "City of Danville",
            "City of Emporia",
            "City of Fairfax",
            "City of Falls Church",
            "City of Franklin",
            "City of Fredericksburg",
            "City of Galax",
            "City of Hampton",
            "City of Harrisonburg",
            "City of Hopewell",
            "City of Industry",
            "City of Lexington",
            "City of Lynchburg",
            "City of Manassas",
            "City of Manassas Park",
            "City of Martinsville",
            "City of Milford (balance)",
            "City of Newport News",
            "City of Norfolk",
            "City of Norton",
            "City of Petersburg",
            "City of Poquoson",
            "City of Portsmouth",
            "City of Radford",
            "City of Richmond",
            "City of Roanoke",
            "City of Saint Louis",
            "City of Salem",
            "City of Sammamish",
            "City of Staunton",
            "City of Suffolk",
            "City of Virginia Beach",
            "City of Waynesboro",
            "City of Williamsburg",
            "City of Winchester",
            "City View",
            "Clackamas",
            "Clackamas County",
            "Claiborne",
            "Claiborne County",
            "Claiborne County",
            "Claiborne Parish",
            "Clairton",
            "Clallam County",
            "Clancy",
            "Clanton",
            "Clara City",
            "Clarcona",
            "Clare",
            "Clare County",
            "Claremont",
            "Claremont",
            "Claremont",
            "Claremore",
            "Clarence",
            "Clarence Center",
            "Clarendon",
            "Clarendon",
            "Clarendon",
            "Clarendon County",
            "Clarendon Hills",
            "Clarinda",
            "Clarion",
            "Clarion",
            "Clarion County",
            "Clark",
            "Clark",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark County",
            "Clark Mills",
            "Clark-Fulton",
            "Clarkdale",
            "Clarke County",
            "Clarke County",
            "Clarke County",
            "Clarke County",
            "Clarke County",
            "Clarkesville",
            "Clarks",
            "Clarks Green",
            "Clarks Summit",
            "Clarksburg",
            "Clarksburg",
            "Clarksdale",
            "Clarkson",
            "Clarkson Valley",
            "Clarkston",
            "Clarkston",
            "Clarkston",
            "Clarkston Heights-Vineland",
            "Clarksville",
            "Clarksville",
            "Clarksville",
            "Clarksville",
            "Clarksville",
            "Clarksville",
            "Clarkton",
            "Claryville",
            "Clatskanie",
            "Clatsop County",
            "Claude",
            "Clawson",
            "Claxton",
            "Clay",
            "Clay",
            "Clay",
            "Clay",
            "Clay",
            "Clay Center",
            "Clay Center",
            "Clay City",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Clay County",
            "Claycomo",
            "Claymont",
            "Claypool",
            "Claypool Hill",
            "Claysburg",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton",
            "Clayton County",
            "Clayton County",
            "Cle Elum",
            "Clear Creek County",
            "Clear Lake",
            "Clear Lake",
            "Clear Lake",
            "Clear Lake",
            "Clear Lake Riviera",
            "Clear Lake Shores",
            "Clearbrook Park",
            "Clearfield",
            "Clearfield",
            "Clearfield County",
            "Clearlake",
            "Clearlake Oaks",
            "Clearview",
            "Clearwater",
            "Clearwater",
            "Clearwater",
            "Clearwater",
            "Clearwater County",
            "Clearwater County",
            "Cleary",
            "Cleburne",
            "Cleburne County",
            "Cleburne County",
            "Clementon",
            "Clemmons",
            "Clemson",
            "Clendenin",
            "Cleona",
            "Cleora",
            "Clermont",
            "Clermont",
            "Clermont County",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland",
            "Cleveland County",
            "Cleveland County",
            "Cleveland County",
            "Cleveland Heights",
            "Clever",
            "Cleves",
            "Clewiston",
            "Cliffside Park",
            "Cliffwood Beach",
            "Clifton",
            "Clifton",
            "Clifton",
            "Clifton",
            "Clifton",
            "Clifton",
            "Clifton",
            "Clifton Forge",
            "Clifton Heights",
            "Clifton Springs",
            "Clinch County",
            "Clint",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton County",
            "Clinton Township",
            "Clintondale",
            "Clintonville",
            "Clintwood",
            "Clio",
            "Clio",
            "Clive",
            "Cloquet",
            "Closter",
            "Cloud County",
            "Clover",
            "Clover Hill",
            "Cloverdale",
            "Cloverdale",
            "Cloverdale",
            "Cloverleaf",
            "Cloverly",
            "Cloverport",
            "Clovis",
            "Clovis",
            "Clute",
            "Clyde",
            "Clyde",
            "Clyde",
            "Clyde",
            "Clyde Hill",
            "Clymer",
            "Clymer",
            "Coachella",
            "Coahoma County",
            "Coal City",
            "Coal City",
            "Coal County",
            "Coal Creek",
            "Coal Fork",
            "Coal Grove",
            "Coal Hill",
            "Coal Run Village",
            "Coal Valley",
            "Coaldale",
            "Coalfield",
            "Coalgate",
            "Coaling",
            "Coalinga",
            "Coalville",
            "Coamo",
            "Coarsegold",
            "Coatesville",
            "Coats",
            "Cobb",
            "Cobb County",
            "Cobb Island",
            "Cobden",
            "Cobleskill",
            "Coburg",
            "Cochise County",
            "Cochituate",
            "Cochran",
            "Cochran County",
            "Cochranton",
            "Cocke County",
            "Cockeysville",
            "Cockrell Hill",
            "Coco",
            "Cocoa",
            "Cocoa Beach",
            "Cocoa West",
            "Coconino County",
            "Coconut Creek",
            "Coconut Grove",
            "Codington County",
            "Cody",
            "Coeburn",
            "Coeur d'Alene",
            "Coffee County",
            "Coffee County",
            "Coffee County",
            "Coffey County",
            "Coffeyville",
            "Cohasset",
            "Cohasset",
            "Cohoe",
            "Cohoes",
            "Cokato",
            "Coke County",
            "Colbert",
            "Colbert County",
            "Colby",
            "Colby",
            "Colchester",
            "Colchester",
            "Colchester",
            "Cold Spring",
            "Cold Spring",
            "Cold Spring",
            "Cold Spring Harbor",
            "Cold Springs",
            "Coldspring",
            "Coldstream",
            "Coldwater",
            "Coldwater",
            "Coldwater",
            "Coldwater",
            "Cole Camp",
            "Cole County",
            "Colebrook",
            "Coleman",
            "Coleman",
            "Coleman County",
            "Coleraine",
            "Coles County",
            "Colesville",
            "Colfax",
            "Colfax",
            "Colfax",
            "Colfax",
            "Colfax",
            "Colfax",
            "Colfax County",
            "Colfax County",
            "College",
            "College Park",
            "College Park",
            "College Place",
            "College Point",
            "College Station",
            "Collegedale",
            "Collegeville",
            "Collegeville",
            "Colleton County",
            "Colleyville",
            "Collier County",
            "Collierville",
            "Collierville",
            "Collin County",
            "Collingdale",
            "Collings Lakes",
            "Collingswood",
            "Collingsworth County",
            "Collins",
            "Collinsburg",
            "Collinsville",
            "Collinsville",
            "Collinsville",
            "Collinsville",
            "Collinsville",
            "Collinsville",
            "Collinsville",
            "Collinwood",
            "Colma",
            "Colmar Manor",
            "Cologne",
            "Coloma",
            "Colon",
            "Colona",
            "Colonia",
            "Colonial Beach",
            "Colonial Heights",
            "Colonial Heights",
            "Colonial Park",
            "Colonial Pine Hills",
            "Colonie",
            "Colony Park",
            "Colorado City",
            "Colorado City",
            "Colorado City",
            "Colorado County",
            "Colorado Springs",
            "Colquitt",
            "Colquitt County",
            "Colrain",
            "Colstrip",
            "Colton",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia",
            "Columbia City",
            "Columbia City",
            "Columbia County",
            "Columbia County",
            "Columbia County",
            "Columbia County",
            "Columbia County",
            "Columbia County",
            "Columbia County",
            "Columbia County",
            "Columbia Falls",
            "Columbia Heights",
            "Columbiana",
            "Columbiana",
            "Columbiana County",
            "Columbine",
            "Columbine Valley",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus",
            "Columbus Air Force Base",
            "Columbus County",
            "Columbus Grove",
            "Columbus Junction",
            "Colusa",
            "Colusa County",
            "Colville",
            "Colwich",
            "Colwyn",
            "Comal County",
            "Comanche",
            "Comanche",
            "Comanche County",
            "Comanche County",
            "Comanche County",
            "Combee Settlement",
            "Combes",
            "Combine",
            "Combined Locks",
            "Comer",
            "Comerío",
            "Comerío Municipio",
            "Comfort",
            "Commack",
            "Commerce",
            "Commerce",
            "Commerce",
            "Commerce",
            "Commerce City",
            "Commercial Point",
            "Como",
            "Como",
            "Compton",
            "Comstock Northwest",
            "Comstock Park",
            "Comunas",
            "Conashaugh Lakes",
            "Concho County",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concord",
            "Concordia",
            "Concordia",
            "Concordia",
            "Concordia Parish",
            "Condon",
            "Condon",
            "Conecuh County",
            "Conehatta",
            "Conejos",
            "Conejos County",
            "Conemaugh",
            "Conestoga",
            "Coney Island",
            "Congers",
            "Congress",
            "Conley",
            "Conneaut",
            "Conneaut Lakeshore",
            "Connell",
            "Connellsville",
            "Connelly Springs",
            "Connersville",
            "Connerton",
            "Conning Towers-Nautilus Park",
            "Conover",
            "Conrad",
            "Conrad",
            "Conroe",
            "Conshohocken",
            "Constantia",
            "Constantine",
            "Continental",
            "Contoocook",
            "Contra Costa Centre",
            "Contra Costa County",
            "Convent",
            "Converse",
            "Converse",
            "Converse County",
            "Convoy",
            "Conway",
            "Conway",
            "Conway",
            "Conway",
            "Conway",
            "Conway",
            "Conway County",
            "Conway Springs",
            "Conyers",
            "Conyngham",
            "Cook County",
            "Cook County",
            "Cook County",
            "Cooke County",
            "Cookeville",
            "Cool",
            "Cool Valley",
            "Coolidge",
            "Coon Rapids",
            "Coon Rapids",
            "Cooper",
            "Cooper City",
            "Cooper County",
            "Coopersburg",
            "Cooperstown",
            "Cooperstown",
            "Cooperstown",
            "Coopersville",
            "Coopertown",
            "Coos Bay",
            "Coos County",
            "Coos County",
            "Coosa County",
            "Coosada",
            "Copeland",
            "Copiague",
            "Copiah County",
            "Coplay",
            "Copley",
            "Coppell",
            "Copper Canyon",
            "Copperas Cove",
            "Copperopolis",
            "Coquí",
            "Coquille",
            "Coral Gables",
            "Coral Hills",
            "Coral Springs",
            "Coral Terrace",
            "Coralville",
            "Coram",
            "Coraopolis",
            "Corazón",
            "Corbin",
            "Corcoran",
            "Corcoran",
            "Corcovado",
            "Cordaville",
            "Cordele",
            "Cordell",
            "Cordes Lakes",
            "Cordova",
            "Cordova",
            "Cordova",
            "Cordry Sweetwater Lakes",
            "Corinna",
            "Corinth",
            "Corinth",
            "Corinth",
            "Cornelia",
            "Cornelius",
            "Cornelius",
            "Cornell",
            "Cornersville",
            "Corning",
            "Corning",
            "Corning",
            "Corning",
            "Cornish",
            "Cornville",
            "Cornville",
            "Cornwall",
            "Cornwall",
            "Cornwells Heights",
            "Corona",
            "Corona",
            "Corona de Tucson",
            "Coronado",
            "Corozal",
            "Corozal Municipio",
            "Corpus Christi",
            "Corrales",
            "Corralitos",
            "Corrigan",
            "Corry",
            "Corsicana",
            "Corson County",
            "Corte Madera",
            "Cortez",
            "Cortez",
            "Cortland",
            "Cortland",
            "Cortland",
            "Cortland County",
            "Cortland West",
            "Corunna",
            "Corvallis",
            "Corydon",
            "Corydon",
            "Coryell County",
            "Cos Cob",
            "Coshocton",
            "Coshocton County",
            "Cosmopolis",
            "Costa Mesa",
            "Costilla County",
            "Cotati",
            "Coto De Caza",
            "Coto Laurel",
            "Coto Norte",
            "Cottage City",
            "Cottage Grove",
            "Cottage Grove",
            "Cottage Grove",
            "Cottage Lake",
            "Cottle County",
            "Cottleville",
            "Cotton County",
            "Cottonport",
            "Cottonwood",
            "Cottonwood",
            "Cottonwood",
            "Cottonwood",
            "Cottonwood County",
            "Cottonwood Falls",
            "Cottonwood Heights",
            "Cottonwood Shores",
            "Cotuit",
            "Cotulla",
            "Coudersport",
            "Coulee Dam",
            "Council",
            "Council Bluffs",
            "Council Grove",
            "Country Club",
            "Country Club",
            "Country Club Estates",
            "Country Club Hills",
            "Country Club Hills",
            "Country Club Village",
            "Country Homes",
            "Country Knolls",
            "Country Lake Estates",
            "Country Squire Lakes",
            "Country Walk",
            "Countryside",
            "Countryside",
            "Coupeville",
            "Courtland",
            "Coushatta",
            "Cove Creek",
            "Covedale",
            "Covelo",
            "Coventry",
            "Coventry Lake",
            "Covina",
            "Covington",
            "Covington",
            "Covington",
            "Covington",
            "Covington",
            "Covington",
            "Covington",
            "Covington",
            "Covington County",
            "Covington County",
            "Cowan",
            "Cowarts",
            "Coweta",
            "Coweta County",
            "Cowley County",
            "Cowlitz County",
            "Cowpens",
            "Coxsackie",
            "Cozad",
            "Crab Orchard",
            "Crafton",
            "Craig",
            "Craig",
            "Craig Beach",
            "Craig County",
            "Craig County",
            "Craighead County",
            "Craigsville",
            "Crainville",
            "Cramerton",
            "Cranberry Township",
            "Cranbury",
            "Crandall",
            "Crandon",
            "Crandon Lakes",
            "Crane",
            "Crane",
            "Crane County",
            "Cranford",
            "Cranston",
            "Craven County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawford County",
            "Crawfordsville",
            "Crawfordville",
            "Crawfordville",
            "Creede",
            "Creedmoor",
            "Creek County",
            "Creighton",
            "Crenshaw County",
            "Creola",
            "Cresaptown",
            "Crescent",
            "Crescent City",
            "Crescent City",
            "Crescent Springs",
            "Cresco",
            "Cresskill",
            "Cresson",
            "Cressona",
            "Crest",
            "Crest Hill",
            "Crested Butte",
            "Crestline",
            "Crestline",
            "Creston",
            "Creston",
            "Crestview",
            "Crestview Hills",
            "Crestwood",
            "Crestwood",
            "Crestwood",
            "Crestwood Village",
            "Creswell",
            "Crete",
            "Crete",
            "Creve Coeur",
            "Creve Coeur",
            "Crewe",
            "Cricket",
            "Cridersville",
            "Crimora",
            "Cripple Creek",
            "Crisfield",
            "Crisp County",
            "Crittenden",
            "Crittenden County",
            "Crittenden County",
            "Crocker",
            "Crocker",
            "Crockett",
            "Crockett",
            "Crockett County",
            "Crockett County",
            "Crofton",
            "Crompond",
            "Cromwell",
            "Crook County",
            "Crook County",
            "Crooked Lake Park",
            "Crooks",
            "Crookston",
            "Crooksville",
            "Croom",
            "Crosby",
            "Crosby",
            "Crosby",
            "Crosby County",
            "Crosbyton",
            "Cross City",
            "Cross County",
            "Cross Lake",
            "Cross Lanes",
            "Cross Mountain",
            "Cross Plains",
            "Cross Plains",
            "Crossett",
            "Crossville",
            "Crossville",
            "Croswell",
            "Crothersville",
            "Croton-on-Hudson",
            "Crow Agency",
            "Crow Wing County",
            "Crowell",
            "Crowley",
            "Crowley",
            "Crowley County",
            "Crown Heights",
            "Crown Point",
            "Crownpoint",
            "Crownsville",
            "Croydon",
            "Crozet",
            "Crugers",
            "Crump",
            "Crystal",
            "Crystal City",
            "Crystal City",
            "Crystal Falls",
            "Crystal Lake",
            "Crystal Lake",
            "Crystal Lake",
            "Crystal Lakes",
            "Crystal Lawns",
            "Crystal River",
            "Crystal Springs",
            "Crystal Springs",
            "Cuba",
            "Cuba",
            "Cuba",
            "Cuba City",
            "Cudahy",
            "Cudahy",
            "Cudjoe Key",
            "Cuero",
            "Culberson County",
            "Culebra",
            "Culebra barrio-pueblo",
            "Cullen",
            "Cullman",
            "Cullman County",
            "Culloden",
            "Cullowhee",
            "Culp Creek",
            "Culpeper",
            "Culpeper County",
            "Culver",
            "Culver",
            "Culver City",
            "Cumberland",
            "Cumberland",
            "Cumberland",
            "Cumberland",
            "Cumberland",
            "Cumberland",
            "Cumberland Center",
            "Cumberland County",
            "Cumberland County",
            "Cumberland County",
            "Cumberland County",
            "Cumberland County",
            "Cumberland County",
            "Cumberland County",
            "Cumberland County",
            "Cumberland Head",
            "Cumberland Hill",
            "Cuming County",
            "Cumming",
            "Cupertino",
            "Currituck",
            "Currituck County",
            "Curry County",
            "Curry County",
            "Curtice",
            "Curtisville",
            "Curwensville",
            "Cushing",
            "Cushing",
            "Cusseta",
            "Custer",
            "Custer County",
            "Custer County",
            "Custer County",
            "Custer County",
            "Custer County",
            "Custer County",
            "Cut and Shoot",
            "Cut Bank",
            "Cut Off",
            "Cutchogue",
            "Cuthbert",
            "Cutler",
            "Cutler",
            "Cutler Bay",
            "Cutler Ridge",
            "Cutlerville",
            "Cutten",
            "Cuyahoga County",
            "Cuyahoga Falls",
            "Cynthiana",
            "Cypress",
            "Cypress",
            "Cypress Gardens",
            "Cypress Hills",
            "Cypress Lake",
            "Cypress Quarters",
            "Cyril",
            "D'Iberville",
            "Dacono",
            "Dacula",
            "Dade City",
            "Dade City North",
            "Dade County",
            "Dade County",
            "Dadeville",
            "Daggett County",
            "Daguao",
            "Dahlgren",
            "Dahlonega",
            "Daingerfield",
            "Dakota",
            "Dakota City",
            "Dakota City",
            "Dakota County",
            "Dakota County",
            "Dakota Dunes",
            "Dale",
            "Dale",
            "Dale City",
            "Dale County",
            "Daleville",
            "Daleville",
            "Daleville",
            "Dalhart",
            "Dallam County",
            "Dallas",
            "Dallas",
            "Dallas",
            "Dallas",
            "Dallas",
            "Dallas Center",
            "Dallas County",
            "Dallas County",
            "Dallas County",
            "Dallas County",
            "Dallas County",
            "Dallastown",
            "Dallesport",
            "Dalton",
            "Dalton",
            "Dalton",
            "Dalton",
            "Dalton Gardens",
            "Dalworthington Gardens",
            "Daly City",
            "Dalzell",
            "Damariscotta",
            "Damascus",
            "Damascus",
            "Dana",
            "Dana Point",
            "Danbury",
            "Danbury",
            "Danbury",
            "Danbury",
            "Danby",
            "Dandridge",
            "Dane",
            "Dane County",
            "Dania Beach",
            "Daniel",
            "Daniels",
            "Daniels County",
            "Danielson",
            "Danielsville",
            "Dannemora",
            "Dansville",
            "Danvers",
            "Danvers",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Danville",
            "Daphne",
            "Darby",
            "Dardanelle",
            "Dardenne Prairie",
            "Dare County",
            "Darien",
            "Darien",
            "Darien",
            "Darien",
            "Darke County",
            "Darlington",
            "Darlington",
            "Darlington County",
            "Darmstadt",
            "Darnestown",
            "Darrington",
            "Dartmouth",
            "Dassel",
            "Dauphin County",
            "Dauphin Island",
            "Davenport",
            "Davenport",
            "Davenport",
            "David City",
            "Davidson",
            "Davidson County",
            "Davidson County",
            "Davidsonville",
            "Davidsville",
            "Davie",
            "Davie County",
            "Daviess County",
            "Daviess County",
            "Daviess County",
            "Davis",
            "Davis",
            "Davis County",
            "Davis County",
            "Davis Junction",
            "Davisboro",
            "Davison",
            "Davison County",
            "Dawes County",
            "Dawson",
            "Dawson",
            "Dawson County",
            "Dawson County",
            "Dawson County",
            "Dawson County",
            "Dawson Springs",
            "Dawsonville",
            "Day County",
            "Day Heights",
            "Day Valley",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Dayton",
            "Daytona Beach",
            "Daytona Beach Shores",
            "De Baca County",
            "De Forest",
            "De Graff",
            "De Kalb",
            "De Kalb",
            "De Land Southwest",
            "De Leon",
            "De Leon Springs",
            "De Lisle",
            "De Pere",
            "De Queen",
            "De Smet",
            "De Soto",
            "De Soto",
            "De Soto",
            "De Soto",
            "De Soto County",
            "De Soto Parish",
            "De Witt",
            "De Witt",
            "De Witt County",
            "Deadwood",
            "Deaf Smith County",
            "Deale",
            "Dearborn",
            "Dearborn County",
            "Dearborn Heights",
            "Deatsville",
            "DeBary",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur",
            "Decatur County",
            "Decatur County",
            "Decatur County",
            "Decatur County",
            "Decatur County",
            "Decaturville",
            "Decherd",
            "Decorah",
            "DeCordova",
            "Dedham",
            "Dedham",
            "Deenwood",
            "Deep River Center",
            "Deephaven",
            "Deer Isle",
            "Deer Lodge",
            "Deer Lodge County",
            "Deer Park",
            "Deer Park",
            "Deer Park",
            "Deer Park",
            "Deer Park",
            "Deer Park",
            "Deer Valley",
            "Deerfield",
            "Deerfield",
            "Deerfield",
            "Deerfield Beach",
            "Deering",
            "Defiance",
            "Defiance County",
            "DeFuniak Springs",
            "DeKalb",
            "DeKalb County",
            "DeKalb County",
            "DeKalb County",
            "DeKalb County",
            "DeKalb County",
            "DeKalb County",
            "Del Aire",
            "Del City",
            "Del Mar",
            "Del Monte Forest",
            "Del Norte",
            "Del Norte County",
            "Del Rey",
            "Del Rey Oaks",
            "Del Rio",
            "Del Rio",
            "Delafield",
            "Delanco",
            "DeLand",
            "Delano",
            "Delano",
            "Delavan",
            "Delavan",
            "Delavan Lake",
            "Delaware",
            "Delaware City",
            "Delaware County",
            "Delaware County",
            "Delaware County",
            "Delaware County",
            "Delaware County",
            "Delaware County",
            "Delcambre",
            "Delevan",
            "Delhi",
            "Delhi",
            "Delhi",
            "Delhi Hills",
            "Dell Rapids",
            "Dellwood",
            "Dellwood",
            "Delmar",
            "Delmar",
            "Delmont",
            "Delphi",
            "Delphos",
            "Delray Beach",
            "Delta",
            "Delta",
            "Delta",
            "Delta County",
            "Delta County",
            "Delta County",
            "Deltana",
            "Deltaville",
            "Deltona",
            "Demarest",
            "Deming",
            "Demopolis",
            "Demorest",
            "DeMotte",
            "Denair",
            "Denali Borough",
            "Denham Springs",
            "Denison",
            "Denison",
            "Denmark",
            "Denmark",
            "Denmark",
            "Dennis",
            "Dennis Port",
            "Dennison",
            "Dent",
            "Dent County",
            "Denton",
            "Denton",
            "Denton",
            "Denton County",
            "Dentsville",
            "Denver",
            "Denver",
            "Denver",
            "Denver",
            "Denver City",
            "Denver County",
            "Depew",
            "Depoe Bay",
            "Deposit",
            "Depue",
            "DeQuincy",
            "Derby",
            "Derby",
            "Derby",
            "DeRidder",
            "Derma",
            "Dermott",
            "Derry",
            "Derry",
            "Derry Village",
            "Derwood",
            "Des Allemands",
            "Des Arc",
            "Des Moines",
            "Des Moines",
            "Des Moines County",
            "Des Peres",
            "Des Plaines",
            "Descanso",
            "Deschutes County",
            "Deschutes River Woods",
            "Desert Aire",
            "Desert Edge",
            "Desert Hills",
            "Desert Hot Springs",
            "Desert Shores",
            "Desert View Highlands",
            "Desha County",
            "Deshler",
            "Desloge",
            "DeSoto",
            "DeSoto County",
            "Desoto Lakes",
            "Despard",
            "Destin",
            "Destrehan",
            "Detroit",
            "Detroit Beach",
            "Detroit Lakes",
            "Detroit-Shoreway",
            "Deuel County",
            "Deuel County",
            "Devens",
            "Deville",
            "Devils Lake",
            "Devine",
            "Devola",
            "Devon",
            "Dewart",
            "Dewey",
            "Dewey County",
            "Dewey County",
            "Dewey-Humboldt",
            "Deweyville",
            "DeWitt",
            "DeWitt County",
            "Dexter",
            "Dexter",
            "Dexter",
            "Dexter",
            "Dexter",
            "Diablo",
            "Diamond",
            "Diamond Bar",
            "Diamond Ridge",
            "Diamond Springs",
            "Diamondhead",
            "Diaz",
            "Diboll",
            "Dickens",
            "Dickens County",
            "Dickenson County",
            "Dickey County",
            "Dickeyville",
            "Dickinson",
            "Dickinson",
            "Dickinson County",
            "Dickinson County",
            "Dickinson County",
            "Dickson",
            "Dickson",
            "Dickson City",
            "Dickson County",
            "Dierks",
            "Dighton",
            "Dighton",
            "Dike",
            "Dilkon",
            "Dilley",
            "Dillingham",
            "Dillingham Census Area",
            "Dillon",
            "Dillon",
            "Dillon County",
            "Dillonvale",
            "Dillsboro",
            "Dillsburg",
            "Dilworth",
            "Dimmit County",
            "Dimmitt",
            "Dimondale",
            "Dinuba",
            "Dinwiddie County",
            "Discovery Bay",
            "Dishman",
            "District Heights",
            "Divernon",
            "Divide County",
            "Dix Hills",
            "Dixfield",
            "Dixiana",
            "Dixie County",
            "Dixmont",
            "Dixmoor",
            "Dixon",
            "Dixon",
            "Dixon",
            "Dixon",
            "Dixon County",
            "Dixon Lane-Meadow Creek",
            "Dobbs Ferry",
            "Dobson",
            "Dock Junction",
            "Doctor Phillips",
            "Doddridge County",
            "Dodge Center",
            "Dodge City",
            "Dodge County",
            "Dodge County",
            "Dodge County",
            "Dodge County",
            "Dodgeville",
            "Dodson Branch",
            "Doe Valley",
            "Doffing",
            "Dogtown",
            "Dolan Springs",
            "Dolgeville",
            "Dollar Bay",
            "Dollar Corner",
            "Dollar Point",
            "Dolores County",
            "Dolton",
            "Doña Ana",
            "Doña Ana County",
            "Donald",
            "Donaldsonville",
            "Donalsonville",
            "Dongan Hills",
            "Doniphan",
            "Doniphan County",
            "Donley County",
            "Donna",
            "Donora",
            "Donovan Estates",
            "Doolittle",
            "Dooly County",
            "Dooms",
            "Door County",
            "Dora",
            "Dorado",
            "Dorado Municipio",
            "Doral",
            "Doraville",
            "Dorchester County",
            "Dorchester County",
            "Dormont",
            "Dorneyville",
            "Dos Palos",
            "Dothan",
            "Double Oak",
            "Double Springs",
            "Dougherty County",
            "Douglas",
            "Douglas",
            "Douglas",
            "Douglas",
            "Douglas",
            "Douglas",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglas County",
            "Douglass",
            "Douglass Hills",
            "Douglaston",
            "Douglasville",
            "Dousman",
            "Dove Creek",
            "Dove Valley",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover",
            "Dover Base Housing",
            "Dover Beaches North",
            "Dover Beaches South",
            "Dover Plains",
            "Dover-Foxcroft",
            "Dowagiac",
            "Downers Grove",
            "Downey",
            "Downieville",
            "Downingtown",
            "Doylestown",
            "Doylestown",
            "Dracut",
            "Drain",
            "Dranesville",
            "Draper",
            "Dravosburg",
            "Dresden",
            "Dresden",
            "Dresher",
            "Drew",
            "Drew County",
            "Drexel",
            "Drexel",
            "Drexel Heights",
            "Drexel Hill",
            "Driggs",
            "Dripping Springs",
            "Druid Hills",
            "Drum Point",
            "Drumright",
            "Dry Ridge",
            "Dry Ridge",
            "Dry Run",
            "Dryden",
            "Dryden",
            "Du Quoin",
            "Duarte",
            "Dublin",
            "Dublin",
            "Dublin",
            "Dublin",
            "Dublin",
            "Dublin",
            "Dublin",
            "DuBois",
            "Dubois",
            "Dubois County",
            "Duboistown",
            "Dubuque",
            "Dubuque County",
            "Duchesne",
            "Duchesne County",
            "Duck Hill",
            "Dudley",
            "Due West",
            "Duenweg",
            "Dukes County",
            "Dulac",
            "Dulce",
            "Dulles Town Center",
            "Duluth",
            "Duluth",
            "Dumas",
            "Dumas",
            "Dumbarton",
            "Dumfries",
            "Dumont",
            "Dunbar",
            "Dunbar",
            "Duncan",
            "Duncan",
            "Duncannon",
            "Duncansville",
            "Duncanville",
            "Dundalk",
            "Dundas",
            "Dundee",
            "Dundee",
            "Dundee",
            "Dundee",
            "Dundy County",
            "Dunean",
            "Dunedin",
            "Dunellen",
            "Dunes City",
            "Dunkirk",
            "Dunkirk",
            "Dunkirk",
            "Dunkirk Town Center",
            "Dunklin County",
            "Dunlap",
            "Dunlap",
            "Dunlap",
            "Dunlap",
            "Dunmore",
            "Dunn",
            "Dunn County",
            "Dunn County",
            "Dunn Loring",
            "Dunnellon",
            "Dunnigan",
            "Dunnstown",
            "Dunsmuir",
            "Dunstable",
            "Dunwoody",
            "DuPage County",
            "Duplin County",
            "Dupo",
            "DuPont",
            "Dupont",
            "Dupree",
            "Duquesne",
            "Duquesne",
            "Durand",
            "Durand",
            "Durand",
            "Durango",
            "Durant",
            "Durant",
            "Durant",
            "Durham",
            "Durham",
            "Durham",
            "Durham",
            "Durham",
            "Durham",
            "Durham County",
            "Duryea",
            "Duson",
            "Dutch Harbor",
            "Dutchess County",
            "Duval County",
            "Duval County",
            "Duvall",
            "Duxbury",
            "Dwight",
            "Dyer",
            "Dyer",
            "Dyer County",
            "Dyersburg",
            "Dyersville",
            "Dyker Heights",
            "Dysart",
            "Eads",
            "Eagan",
            "Eagar",
            "Eagle",
            "Eagle",
            "Eagle",
            "Eagle",
            "Eagle Butte",
            "Eagle County",
            "Eagle Grove",
            "Eagle Lake",
            "Eagle Lake",
            "Eagle Lake",
            "Eagle Lake",
            "Eagle Mountain",
            "Eagle Mountain",
            "Eagle Pass",
            "Eagle Point",
            "Eagle River",
            "Eagle River",
            "Eagle River",
            "Eagleton Village",
            "Eagleview",
            "Eagleville",
            "Earle",
            "Earlham",
            "Earlimart",
            "Earlington",
            "Earlston",
            "Earlville",
            "Early",
            "Early County",
            "Earth",
            "Easley",
            "East Alton",
            "East Atlantic Beach",
            "East Aurora",
            "East Bangor",
            "East Baton Rouge Parish",
            "East Berlin",
            "East Bernard",
            "East Berwick",
            "East Bethel",
            "East Brainerd",
            "East Brewton",
            "East Bridgewater",
            "East Bronson",
            "East Brookfield",
            "East Brooklyn",
            "East Brunswick",
            "East Canton",
            "East Carbon City",
            "East Carroll Parish",
            "East Chattanooga",
            "East Chicago",
            "East Cleveland",
            "East Cleveland",
            "East Concord",
            "East Conemaugh",
            "East Dennis",
            "East Douglas",
            "East Dublin",
            "East Dubuque",
            "East Dundee",
            "East Earl",
            "East Elmhurst",
            "East End",
            "East Falmouth",
            "East Farmingdale",
            "East Feliciana Parish",
            "East Flat Rock",
            "East Flatbush",
            "East Florence",
            "East Foothills",
            "East Franklin",
            "East Freehold",
            "East Gaffney",
            "East Garden City",
            "East Garfield Park",
            "East Glenville",
            "East Grand Forks",
            "East Grand Rapids",
            "East Greenbush",
            "East Greenville",
            "East Greenwich",
            "East Griffin",
            "East Gull Lake",
            "East Haddam",
            "East Hampton",
            "East Hampton",
            "East Hampton",
            "East Hampton North",
            "East Hanover",
            "East Harlem",
            "East Hartford",
            "East Harwich",
            "East Haven",
            "East Hazel Crest",
            "East Helena",
            "East Hemet",
            "East Highland Park",
            "East Hill-Meridian",
            "East Hills",
            "East Honolulu",
            "East Independence",
            "East Islip",
            "East Ithaca",
            "East Jordan",
            "East Kingston",
            "East La Mirada",
            "East Lake",
            "East Lake-Orient Park",
            "East Lansdowne",
            "East Lansing",
            "East Lexington",
            "East Liverpool",
            "East Longmeadow",
            "East Los Angeles",
            "East Machias",
            "East Massapequa",
            "East McKeesport",
            "East Meadow",
            "East Merrimack",
            "East Millcreek",
            "East Millinocket",
            "East Milton",
            "East Missoula",
            "East Moline",
            "East Moriches",
            "East Naples",
            "East New York",
            "East Newark",
            "East Newnan",
            "East Northport",
            "East Norwalk",
            "East Norwich",
            "East Oakdale",
            "East Orange",
            "East Palatka",
            "East Palestine",
            "East Palo Alto",
            "East Pasadena",
            "East Patchogue",
            "East Pensacola Heights",
            "East Peoria",
            "East Pepperell",
            "East Perrine",
            "East Petersburg",
            "East Pittsburgh",
            "East Point",
            "East Port Orchard",
            "East Porterville",
            "East Prairie",
            "East Providence",
            "East Quincy",
            "East Quogue",
            "East Rancho Dominguez",
            "East Renton Highlands",
            "East Richmond Heights",
            "East Ridge",
            "East Riverdale",
            "East Rochester",
            "East Rockaway",
            "East Rockingham",
            "East Rutherford",
            "East Sahuarita",
            "East Saint Louis",
            "East San Gabriel",
            "East Sandwich",
            "East Setauket",
            "East Shoreham",
            "East Sonora",
            "East Spencer",
            "East Stroudsburg",
            "East Sumter",
            "East Syracuse",
            "East Tawas",
            "East Tremont",
            "East Troy",
            "East Uniontown",
            "East Valley",
            "East Village",
            "East Washington",
            "East Wenatchee",
            "East Wenatchee Bench",
            "East Williston",
            "East Windsor",
            "East York",
            "Eastchester",
            "Eastgate",
            "Eastham",
            "Easthampton",
            "Eastlake",
            "Eastland",
            "Eastland County",
            "Eastlawn Gardens",
            "Eastman",
            "Eastmont",
            "Easton",
            "Easton",
            "Easton",
            "Easton",
            "Easton",
            "Easton",
            "Easton",
            "Eastover",
            "Eastpoint",
            "Eastpointe",
            "Eastport",
            "Eastport",
            "Eastvale",
            "Eastville",
            "Eastwood",
            "Eastwood",
            "Eaton",
            "Eaton",
            "Eaton",
            "Eaton County",
            "Eaton Estates",
            "Eaton Rapids",
            "Eatons Neck",
            "Eatonton",
            "Eatontown",
            "Eatonville",
            "Eatonville",
            "Eau Claire",
            "Eau Claire County",
            "Ebensburg",
            "Echelon",
            "Echo Park",
            "Echols County",
            "Eclectic",
            "Economy",
            "Ecorse",
            "Ector County",
            "Edcouch",
            "Eddington",
            "Eddington",
            "Eddy County",
            "Eddy County",
            "Eddystone",
            "Eddyville",
            "Eddyville",
            "Eden",
            "Eden",
            "Eden",
            "Eden Isle",
            "Eden Prairie",
            "Eden Valley",
            "Edenton",
            "Edgar",
            "Edgar County",
            "Edgard",
            "Edgartown",
            "Edgecliff Village",
            "Edgecomb",
            "Edgecombe County",
            "Edgefield",
            "Edgefield County",
            "Edgemere",
            "Edgemere",
            "Edgemont Park",
            "Edgemoor",
            "Edgerton",
            "Edgerton",
            "Edgerton",
            "Edgerton",
            "Edgewater",
            "Edgewater",
            "Edgewater",
            "Edgewater",
            "Edgewater",
            "Edgewater Park",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgewood",
            "Edgeworth",
            "Edina",
            "Edina",
            "Edinboro",
            "Edinburg",
            "Edinburg",
            "Edinburg",
            "Edinburg",
            "Edinburgh",
            "Edison",
            "Edison",
            "Edisto",
            "Edmond",
            "Edmonds",
            "Edmonson County",
            "Edmonston",
            "Edmonton",
            "Edmore",
            "Edmunds County",
            "Edna",
            "Edneyville",
            "Edwards",
            "Edwards",
            "Edwards Air Force Base",
            "Edwards County",
            "Edwards County",
            "Edwards County",
            "Edwardsburg",
            "Edwardsville",
            "Edwardsville",
            "Edwardsville",
            "Effingham",
            "Effingham",
            "Effingham County",
            "Effingham County",
            "Effort",
            "Egg Harbor City",
            "Eggertsville",
            "Eglin Air Force Base",
            "Eglin Village",
            "Egypt",
            "Egypt Lake-Leto",
            "Ehrenberg",
            "Eidson Road",
            "Eielson Air Force Base",
            "Ekalaka",
            "El Cajon",
            "El Campo",
            "El Cenizo",
            "El Centro",
            "El Cerrito",
            "El Cerrito Corona",
            "El Cerro",
            "El Cerro Mission",
            "El Dorado",
            "El Dorado",
            "El Dorado County",
            "El Dorado Hills",
            "El Dorado Springs",
            "El Granada",
            "El Jebel",
            "El Lago",
            "El Mangó",
            "El Mirage",
            "El Monte",
            "El Negro",
            "El Ojo",
            "El Paso",
            "El Paso",
            "El Paso County",
            "El Paso County",
            "El Portal",
            "El Rancho",
            "El Reno",
            "El Rio",
            "El Segundo",
            "El Sobrante",
            "El Valle de Arroyo Seco",
            "El Verano",
            "Elba",
            "Elbert County",
            "Elbert County",
            "Elberta",
            "Elberton",
            "Elbow Lake",
            "Elbridge",
            "Elburn",
            "Eldersburg",
            "Eldon",
            "Eldora",
            "Eldorado",
            "Eldorado",
            "Eldorado",
            "Eldorado at Santa Fe",
            "Eldridge",
            "Eldridge",
            "Eleanor",
            "Electra",
            "Electric City",
            "Elephant Butte",
            "Elfers",
            "Elgin",
            "Elgin",
            "Elgin",
            "Elgin",
            "Elgin",
            "Elgin",
            "Elida",
            "Elim",
            "Eliot",
            "Elizabeth",
            "Elizabeth",
            "Elizabeth",
            "Elizabeth",
            "Elizabeth City",
            "Elizabethton",
            "Elizabethtown",
            "Elizabethtown",
            "Elizabethtown",
            "Elizabethtown",
            "Elizabethtown",
            "Elizabethville",
            "Elk City",
            "Elk County",
            "Elk County",
            "Elk Creek",
            "Elk Grove",
            "Elk Grove Village",
            "Elk Plain",
            "Elk Point",
            "Elk Rapids",
            "Elk Ridge",
            "Elk River",
            "Elk Run Heights",
            "Elkader",
            "Elkfork",
            "Elkhart",
            "Elkhart",
            "Elkhart",
            "Elkhart County",
            "Elkhorn",
            "Elkhorn",
            "Elkhorn",
            "Elkin",
            "Elkins",
            "Elkins",
            "Elkland",
            "Elko",
            "Elko County",
            "Elko New Market",
            "Elkridge",
            "Elkton",
            "Elkton",
            "Elkton",
            "Elkview",
            "Ellaville",
            "Ellendale",
            "Ellensburg",
            "Ellenton",
            "Ellenville",
            "Ellerbe",
            "Ellettsville",
            "Ellicott",
            "Ellicott City",
            "Ellijay",
            "Ellington",
            "Ellinwood",
            "Elliott County",
            "Ellis",
            "Ellis County",
            "Ellis County",
            "Ellis County",
            "Ellisburg",
            "Ellisville",
            "Ellisville",
            "Ellport",
            "Ellsworth",
            "Ellsworth",
            "Ellsworth",
            "Ellsworth County",
            "Ellwood City",
            "Elm City",
            "Elm Creek",
            "Elm Grove",
            "Elm Springs",
            "Elma",
            "Elma Center",
            "Elmendorf",
            "Elmendorf Air Force Base",
            "Elmer",
            "Elmhurst",
            "Elmhurst",
            "Elmira",
            "Elmira Heights",
            "Elmont",
            "Elmore",
            "Elmore",
            "Elmore County",
            "Elmore County",
            "Elmsford",
            "Elmwood",
            "Elmwood",
            "Elmwood Park",
            "Elmwood Park",
            "Elmwood Place",
            "Elon",
            "Eloy",
            "Elroy",
            "Elroy",
            "Elsa",
            "Elsberry",
            "Elsmere",
            "Elsmere",
            "Eltingville",
            "Elton",
            "Elverson",
            "Elverta",
            "Elvins",
            "Elwood",
            "Elwood",
            "Elwood",
            "Elwood",
            "Elwood",
            "Elwood",
            "Elwood",
            "Ely",
            "Ely",
            "Ely",
            "Elyria",
            "Elysburg",
            "Emajagua",
            "Emanuel County",
            "Emerald Isle",
            "Emerald Lake Hills",
            "Emerald Lakes",
            "Emerald Mountain",
            "Emerson",
            "Emerson",
            "Emerson Hill",
            "Emery County",
            "Emeryville",
            "Emigsville",
            "Eminence",
            "Eminence",
            "Emmaus",
            "Emmet County",
            "Emmet County",
            "Emmetsburg",
            "Emmett",
            "Emmitsburg",
            "Emmons County",
            "Emory",
            "Emory",
            "Empire",
            "Emporia",
            "Emporia",
            "Emporium",
            "Emsworth",
            "Encantada-Ranchito-El Calaboz",
            "Encanto",
            "Enchanted Hills",
            "Encinitas",
            "Encino",
            "Endicott",
            "Endwell",
            "Energy",
            "Enetai",
            "Enfield",
            "Enfield",
            "Enfield",
            "Enfield",
            "England",
            "Englewood",
            "Englewood",
            "Englewood",
            "Englewood",
            "Englewood",
            "Englewood",
            "Englewood Cliffs",
            "English",
            "Englishtown",
            "Enhaut",
            "Enid",
            "Enigma",
            "Enlow",
            "Ennis",
            "Enoch",
            "Enochville",
            "Enola",
            "Enon",
            "Enon",
            "Enosburg Falls",
            "Ensley",
            "Enterprise",
            "Enterprise",
            "Enterprise",
            "Enterprise",
            "Entiat",
            "Enumclaw",
            "Ephraim",
            "Ephrata",
            "Ephrata",
            "Epping",
            "Epsom",
            "Epworth",
            "Erath",
            "Erath County",
            "Erda",
            "Erick",
            "Erie",
            "Erie",
            "Erie",
            "Erie",
            "Erie County",
            "Erie County",
            "Erie County",
            "Erin",
            "Erlands Point-Kitsap Lake",
            "Erlanger",
            "Erma",
            "Erving",
            "Erwin",
            "Erwin",
            "Erwinville",
            "Escalon",
            "Escambia County",
            "Escambia County",
            "Escanaba",
            "Escatawpa",
            "Escobares",
            "Escondido",
            "Esko",
            "Esmeralda County",
            "Española",
            "Esparto",
            "Esperance",
            "Esperanza",
            "Espino",
            "Espy",
            "Essex",
            "Essex",
            "Essex County",
            "Essex County",
            "Essex County",
            "Essex County",
            "Essex County",
            "Essex Fells",
            "Essex Junction",
            "Essex Village",
            "Essexville",
            "Estacada",
            "Estancia",
            "Estancias de Florida",
            "Estell Manor",
            "Estelle",
            "Ester",
            "Estero",
            "Estes Park",
            "Esther",
            "Estherville",
            "Estill",
            "Estill County",
            "Estill Springs",
            "Ethete",
            "Etna",
            "Etna",
            "Etna",
            "Etowah",
            "Etowah",
            "Etowah County",
            "Ettrick",
            "Eucalyptus Hills",
            "Euclid",
            "Eudora",
            "Eudora",
            "Eufaula",
            "Eufaula",
            "Eugene",
            "Euharlee",
            "Euless",
            "Eunice",
            "Eunice",
            "Eupora",
            "Eureka",
            "Eureka",
            "Eureka",
            "Eureka",
            "Eureka",
            "Eureka",
            "Eureka County",
            "Eureka Mill",
            "Eureka Springs",
            "Eustis",
            "Eutaw",
            "Evadale",
            "Evangeline Parish",
            "Evans",
            "Evans",
            "Evans City",
            "Evans County",
            "Evansburg",
            "Evansdale",
            "Evanston",
            "Evanston",
            "Evansville",
            "Evansville",
            "Evansville",
            "Evart",
            "Eveleth",
            "Evendale",
            "Everett",
            "Everett",
            "Everett",
            "Evergreen",
            "Evergreen",
            "Evergreen",
            "Evergreen",
            "Evergreen Park",
            "Everman",
            "Everson",
            "Ewing",
            "Excelsior",
            "Excelsior Springs",
            "Exeter",
            "Exeter",
            "Exeter",
            "Exeter",
            "Exmore",
            "Experiment",
            "Exton",
            "Eyota",
            "Fabens",
            "Factoryville",
            "Fair Grove",
            "Fair Haven",
            "Fair Haven",
            "Fair Lawn",
            "Fair Oaks",
            "Fair Oaks",
            "Fair Oaks Ranch",
            "Fair Plain",
            "Fairbank",
            "Fairbanks",
            "Fairbanks North Star Borough",
            "Fairbanks Ranch",
            "Fairborn",
            "Fairburn",
            "Fairbury",
            "Fairbury",
            "Fairchance",
            "Fairchild Air Force Base",
            "Fairchilds",
            "Fairdale",
            "Fairdale",
            "Fairfax",
            "Fairfax",
            "Fairfax",
            "Fairfax",
            "Fairfax",
            "Fairfax",
            "Fairfax",
            "Fairfax County",
            "Fairfax Station",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield",
            "Fairfield Bay",
            "Fairfield Beach",
            "Fairfield County",
            "Fairfield County",
            "Fairfield County",
            "Fairfield Glade",
            "Fairfield Harbour",
            "Fairfield Heights",
            "Fairforest",
            "Fairhaven",
            "Fairhope",
            "Fairhope",
            "Fairland",
            "Fairland",
            "Fairlawn",
            "Fairlawn",
            "Fairlea",
            "Fairless Hills",
            "Fairmead",
            "Fairmont",
            "Fairmont",
            "Fairmont",
            "Fairmont",
            "Fairmont City",
            "Fairmount",
            "Fairmount",
            "Fairmount",
            "Fairmount Heights",
            "Fairplains",
            "Fairplay",
            "Fairport",
            "Fairport Harbor",
            "Fairton",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview",
            "Fairview Heights",
            "Fairview Park",
            "Fairview Park",
            "Fairview Shores",
            "Fairview-Ferndale",
            "Fairway",
            "Fairwood",
            "Fairwood",
            "Fajardo",
            "Fajardo Municipio",
            "Falcon Heights",
            "Falcon Lake Estates",
            "Falconer",
            "Falfurrias",
            "Falkville",
            "Fall Branch",
            "Fall City",
            "Fall Creek",
            "Fall River",
            "Fall River",
            "Fall River County",
            "Fallbrook",
            "Falling Water",
            "Fallon",
            "Fallon County",
            "Falls Church",
            "Falls City",
            "Falls County",
            "Falls Creek",
            "Fallsburg",
            "Fallston",
            "Falmouth",
            "Falmouth",
            "Falmouth",
            "Falmouth",
            "Falmouth Foreside",
            "Fannett",
            "Fannin County",
            "Fannin County",
            "Fanwood",
            "Far Rockaway",
            "Fargo",
            "Faribault",
            "Faribault County",
            "Farley",
            "Farley",
            "Farm Loop",
            "Farmer City",
            "Farmers Branch",
            "Farmers Loop",
            "Farmersburg",
            "Farmersville",
            "Farmersville",
            "Farmersville",
            "Farmerville",
            "Farmingdale",
            "Farmingdale",
            "Farmingdale",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington",
            "Farmington Hills",
            "Farmingville",
            "Farmland",
            "Farmville",
            "Farmville",
            "Farr West",
            "Farragut",
            "Farrell",
            "Farwell",
            "Fate",
            "Faulk County",
            "Faulkner County",
            "Faulkton",
            "Fauquier County",
            "Faxon",
            "Fayette",
            "Fayette",
            "Fayette",
            "Fayette",
            "Fayette",
            "Fayette",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayette County",
            "Fayetteville",
            "Fayetteville",
            "Fayetteville",
            "Fayetteville",
            "Fayetteville",
            "Fayetteville",
            "Fayetteville",
            "Fayetteville",
            "Fearrington Village",
            "Feasterville",
            "Feather Sound",
            "Federal Heights",
            "Federal Way",
            "Federalsburg",
            "Felida",
            "Fellsburg",
            "Fellsmere",
            "Felton",
            "Felton",
            "Fennimore",
            "Fennville",
            "Fenton",
            "Fenton",
            "Fentress County",
            "Ferdinand",
            "Fergus County",
            "Fergus Falls",
            "Ferguson",
            "Fern Acres",
            "Fern Creek",
            "Fern Park",
            "Fern Prairie",
            "Fernandina Beach",
            "Ferndale",
            "Ferndale",
            "Ferndale",
            "Ferndale",
            "Ferndale",
            "Fernley",
            "Fernway",
            "Ferriday",
            "Ferris",
            "Ferrisburgh",
            "Ferron",
            "Ferrum",
            "Ferry County",
            "Ferry Pass",
            "Ferrysburg",
            "Fessenden",
            "Festus",
            "Fetters Hot Springs-Agua Caliente",
            "Fife",
            "Fife Heights",
            "Fifth Street",
            "Filer",
            "Fillmore",
            "Fillmore",
            "Fillmore County",
            "Fillmore County",
            "Fincastle",
            "Fincastle",
            "Finderne",
            "Findlay",
            "Finley",
            "Finley",
            "Finney County",
            "Finneytown",
            "Fircrest",
            "Firebaugh",
            "Firestone",
            "Firing Range",
            "First Mesa",
            "Firthcliffe",
            "Fish Hawk",
            "Fish Lake",
            "Fisher",
            "Fisher County",
            "Fishers",
            "Fishersville",
            "Fishhook",
            "Fishkill",
            "Fiskdale",
            "Fitchburg",
            "Fitchburg",
            "Fitzgerald",
            "Fitzwilliam",
            "Five Corners",
            "Five Forks",
            "Five Points",
            "Five Points",
            "Fivepointville",
            "Flagami",
            "Flagler Beach",
            "Flagler County",
            "Flagler Estates",
            "Flagstaff",
            "Flanagan",
            "Flanders",
            "Flandreau",
            "Flat River",
            "Flat Rock",
            "Flat Rock",
            "Flatbush",
            "Flathead County",
            "Flatlands",
            "Flatonia",
            "Flatwoods",
            "Fleetwood",
            "Fleming County",
            "Fleming Island",
            "Flemingsburg",
            "Flemington",
            "Flemington",
            "Fletcher",
            "Fletcher",
            "Flint",
            "Flint City",
            "Flippin",
            "Flomaton",
            "Flora",
            "Flora",
            "Flora",
            "Flora Vista",
            "Floral City",
            "Floral Park",
            "Florala",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence",
            "Florence County",
            "Florence County",
            "Florence-Graham",
            "Floresville",
            "Florham Park",
            "Florida",
            "Florida",
            "Florida City",
            "Florida Ridge",
            "Florin",
            "Floris",
            "Florissant",
            "Flossmoor",
            "Flourtown",
            "Flower Hill",
            "Flower Mound",
            "Flowery Branch",
            "Flowing Wells",
            "Flowood",
            "Floyd",
            "Floyd County",
            "Floyd County",
            "Floyd County",
            "Floyd County",
            "Floyd County",
            "Floyd County",
            "Floydada",
            "Flushing",
            "Fluvanna County",
            "Flying Hills",
            "Foard County",
            "Fobes Hill",
            "Folcroft",
            "Foley",
            "Foley",
            "Folkston",
            "Follansbee",
            "Folly Beach",
            "Folsom",
            "Folsom",
            "Folsom",
            "Fond du Lac",
            "Fond du Lac County",
            "Fonda",
            "Fontana",
            "Fontana",
            "Foothill Farms",
            "Foothill Ranch",
            "Ford City",
            "Ford City",
            "Ford County",
            "Ford County",
            "Ford Heights",
            "Fordham",
            "Fords",
            "Fords Prairie",
            "Fordyce",
            "Forest",
            "Forest",
            "Forest",
            "Forest Acres",
            "Forest City",
            "Forest City",
            "Forest City",
            "Forest City",
            "Forest County",
            "Forest County",
            "Forest Glen",
            "Forest Grove",
            "Forest Heights",
            "Forest Hill",
            "Forest Hills",
            "Forest Hills",
            "Forest Hills",
            "Forest Hills",
            "Forest Lake",
            "Forest Lake",
            "Forest Meadows",
            "Forest Oaks",
            "Forest Park",
            "Forest Park",
            "Forest Park",
            "Forest Park",
            "Forest Ranch",
            "Forestbrook",
            "Forestdale",
            "Forestdale",
            "Foresthill",
            "Forestville",
            "Forestville",
            "Forestville",
            "Forked River",
            "Forks",
            "Forman",
            "Forney",
            "Forrest",
            "Forrest City",
            "Forrest County",
            "Forreston",
            "Forsyth",
            "Forsyth",
            "Forsyth",
            "Forsyth",
            "Forsyth County",
            "Forsyth County",
            "Fort Ashby",
            "Fort Atkinson",
            "Fort Belknap Agency",
            "Fort Belvoir",
            "Fort Bend County",
            "Fort Benton",
            "Fort Bliss",
            "Fort Bragg",
            "Fort Bragg",
            "Fort Branch",
            "Fort Campbell North",
            "Fort Carson",
            "Fort Clark Springs",
            "Fort Collins",
            "Fort Covington Hamlet",
            "Fort Davis",
            "Fort Defiance",
            "Fort Deposit",
            "Fort Dix",
            "Fort Dodge",
            "Fort Drum",
            "Fort Edward",
            "Fort Fairfield",
            "Fort Gaines",
            "Fort George G Mead Junction",
            "Fort Gibson",
            "Fort Hall",
            "Fort Hamilton",
            "Fort Hancock",
            "Fort Hood",
            "Fort Hunt",
            "Fort Irwin",
            "Fort Kent",
            "Fort Knox",
            "Fort Lauderdale",
            "Fort Lee",
            "Fort Lee",
            "Fort Leonard Wood",
            "Fort Loramie",
            "Fort Lupton",
            "Fort Madison",
            "Fort McKinley",
            "Fort Meade",
            "Fort Meade",
            "Fort Mill",
            "Fort Mitchell",
            "Fort Montgomery",
            "Fort Morgan",
            "Fort Myers",
            "Fort Myers Beach",
            "Fort Myers Shores",
            "Fort Oglethorpe",
            "Fort Payne",
            "Fort Pierce",
            "Fort Pierce North",
            "Fort Pierce South",
            "Fort Pierre",
            "Fort Plain",
            "Fort Polk North",
            "Fort Polk South",
            "Fort Recovery",
            "Fort Riley North",
            "Fort Rucker",
            "Fort Salonga",
            "Fort Scott",
            "Fort Shawnee",
            "Fort Smith",
            "Fort Stewart",
            "Fort Stockton",
            "Fort Sumner",
            "Fort Thomas",
            "Fort Thompson",
            "Fort Totten",
            "Fort Valley",
            "Fort Wadsworth",
            "Fort Walton Beach",
            "Fort Washakie",
            "Fort Washington",
            "Fort Washington",
            "Fort Wayne",
            "Fort Worth",
            "Fort Wright",
            "Fort Yates",
            "Fortuna",
            "Fortuna Foothills",
            "Fortville",
            "Forty Fort",
            "Foscoe",
            "Fossil",
            "Fosston",
            "Foster",
            "Foster Brook",
            "Foster City",
            "Foster County",
            "Fostoria",
            "Fountain",
            "Fountain County",
            "Fountain Green",
            "Fountain Hill",
            "Fountain Hills",
            "Fountain Inn",
            "Fountain Valley",
            "Fountainebleau",
            "Fountainhead-Orchard Hills",
            "Four Corners",
            "Four Corners",
            "Four Corners",
            "Four Corners",
            "Four Corners",
            "Four Oaks",
            "Four Seasons",
            "Fowler",
            "Fowler",
            "Fowler",
            "Fowler",
            "Fowlerville",
            "Fox Chapel",
            "Fox Chase",
            "Fox Farm-College",
            "Fox Island",
            "Fox Lake",
            "Fox Lake",
            "Fox Lake Hills",
            "Fox Point",
            "Fox River Grove",
            "Fox Run",
            "Foxborough",
            "Frackville",
            "Framingham",
            "Framingham Center",
            "Francestown",
            "Francis",
            "Francisville",
            "Franconia",
            "Frankenmuth",
            "Frankfort",
            "Frankfort",
            "Frankfort",
            "Frankfort",
            "Frankfort",
            "Frankfort",
            "Frankfort",
            "Frankfort Square",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin",
            "Franklin Center",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin County",
            "Franklin Furnace",
            "Franklin Lakes",
            "Franklin Parish",
            "Franklin Park",
            "Franklin Park",
            "Franklin Park",
            "Franklin Springs",
            "Franklin Square",
            "Franklinton",
            "Franklinton",
            "Franklinville",
            "Franklinville",
            "Frankston",
            "Franksville",
            "Frankton",
            "Franquez",
            "Fraser",
            "Fraser",
            "Frazee",
            "Frazeysburg",
            "Frazier Park",
            "Frederic",
            "Frederick",
            "Frederick",
            "Frederick",
            "Frederick County",
            "Frederick County",
            "Fredericksburg",
            "Fredericksburg",
            "Fredericksburg",
            "Frederickson",
            "Fredericktown",
            "Fredericktown",
            "Fredonia",
            "Fredonia",
            "Fredonia",
            "Fredonia",
            "Freeborn County",
            "Freeburg",
            "Freedom",
            "Freedom",
            "Freedom",
            "Freehold",
            "Freeland",
            "Freeland",
            "Freeland",
            "Freeman",
            "Freemansburg",
            "Freeport",
            "Freeport",
            "Freeport",
            "Freeport",
            "Freeport",
            "Freeport",
            "Freer",
            "Freestone County",
            "Freetown",
            "Fremont",
            "Fremont",
            "Fremont",
            "Fremont",
            "Fremont",
            "Fremont",
            "Fremont",
            "Fremont County",
            "Fremont County",
            "Fremont County",
            "Fremont County",
            "French Camp",
            "French Island",
            "French Lick",
            "French Settlement",
            "Frenchburg",
            "Frenchtown",
            "Frenchtown",
            "Frenchville",
            "Fresh Meadows",
            "Fresno",
            "Fresno",
            "Fresno County",
            "Frewsburg",
            "Friars Point",
            "Friday Harbor",
            "Fridley",
            "Friedens",
            "Friend",
            "Friendly",
            "Friendship",
            "Friendship",
            "Friendship",
            "Friendship Village",
            "Friendswood",
            "Frio County",
            "Friona",
            "Frisco",
            "Frisco",
            "Frisco City",
            "Fritch",
            "Fritz Creek",
            "Front Royal",
            "Frontenac",
            "Frontenac",
            "Frontier County",
            "Frostburg",
            "Frostproof",
            "Fruit Cove",
            "Fruit Heights",
            "Fruit Hill",
            "Fruita",
            "Fruitdale",
            "Fruitland",
            "Fruitland",
            "Fruitland",
            "Fruitland Park",
            "Fruitport",
            "Fruitridge Pocket",
            "Fruitvale",
            "Fruitville",
            "Fryeburg",
            "Fuig",
            "Fulda",
            "Fuller Heights",
            "Fullerton",
            "Fullerton",
            "Fullerton",
            "Fulshear",
            "Fulton",
            "Fulton",
            "Fulton",
            "Fulton",
            "Fulton",
            "Fulton",
            "Fulton",
            "Fulton County",
            "Fulton County",
            "Fulton County",
            "Fulton County",
            "Fulton County",
            "Fulton County",
            "Fulton County",
            "Fulton County",
            "Fultondale",
            "Fuquay-Varina",
            "Furnas County",
            "Fussels Corner",
            "Fyffe",
            "G. L. Garcia",
            "Gadsden",
            "Gadsden",
            "Gadsden County",
            "Gaffney",
            "Gage County",
            "Gage Park",
            "Gages Lake",
            "Gahanna",
            "Gail",
            "Gaines County",
            "Gainesboro",
            "Gainesville",
            "Gainesville",
            "Gainesville",
            "Gainesville",
            "Gainesville",
            "Gaithersburg",
            "Galateo",
            "Galax",
            "Galena",
            "Galena",
            "Galena",
            "Galena",
            "Galena Park",
            "Gales Ferry",
            "Galesburg",
            "Galesburg",
            "Galesville",
            "Galeton",
            "Galeville",
            "Galion",
            "Gallatin",
            "Gallatin",
            "Gallatin County",
            "Gallatin County",
            "Gallatin County",
            "Gallia County",
            "Galliano",
            "Gallipolis",
            "Gallitzin",
            "Gallup",
            "Galt",
            "Galva",
            "Galveston",
            "Galveston",
            "Galveston County",
            "Gambier",
            "Gambrills",
            "Gamewell",
            "Ganado",
            "Ganado",
            "Gandy",
            "Gang Mills",
            "Gantt",
            "Gap",
            "Garden Acres",
            "Garden City",
            "Garden City",
            "Garden City",
            "Garden City",
            "Garden City",
            "Garden City",
            "Garden City",
            "Garden City",
            "Garden City Park",
            "Garden City South",
            "Garden County",
            "Garden Grove",
            "Garden Home-Whitford",
            "Garden Ridge",
            "Garden View",
            "Gardena",
            "Gardendale",
            "Gardendale",
            "Gardere",
            "Gardiner",
            "Gardner",
            "Gardner",
            "Gardner",
            "Gardnertown",
            "Gardnerville",
            "Gardnerville Ranchos",
            "Garfield",
            "Garfield",
            "Garfield County",
            "Garfield County",
            "Garfield County",
            "Garfield County",
            "Garfield County",
            "Garfield County",
            "Garfield Heights",
            "Garland",
            "Garland",
            "Garland",
            "Garland County",
            "Garner",
            "Garner",
            "Garnet",
            "Garnett",
            "Garrard County",
            "Garretson",
            "Garrett",
            "Garrett",
            "Garrett County",
            "Garrett Park",
            "Garrettsville",
            "Garrison",
            "Garrison",
            "Garrochales",
            "Garvin County",
            "Garwood",
            "Gary",
            "Garyville",
            "Garza County",
            "Gas City",
            "Gasconade County",
            "Gasport",
            "Gassville",
            "Gaston",
            "Gaston",
            "Gaston County",
            "Gastonia",
            "Gastonville",
            "Gate City",
            "Gates County",
            "Gates Mills",
            "Gates-North Gates",
            "Gatesville",
            "Gatesville",
            "Gateway",
            "Gateway",
            "Gatlinburg",
            "Gautier",
            "Gaylord",
            "Gaylord",
            "Gearhart",
            "Geary",
            "Geary County",
            "Geauga County",
            "Geistown",
            "Gem County",
            "Genesee",
            "Genesee County",
            "Genesee County",
            "Geneseo",
            "Geneseo",
            "Geneva",
            "Geneva",
            "Geneva",
            "Geneva",
            "Geneva",
            "Geneva",
            "Geneva",
            "Geneva",
            "Geneva County",
            "Geneva-on-the-Lake",
            "Genoa",
            "Genoa",
            "Genoa City",
            "Genola",
            "Gentry",
            "Gentry County",
            "George",
            "George County",
            "George West",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown",
            "Georgetown County",
            "Georgiana",
            "Gerald",
            "Gerber",
            "Gering",
            "Germantown",
            "Germantown",
            "Germantown",
            "Germantown",
            "Germantown",
            "Germantown Hills",
            "Geronimo",
            "Geronimo",
            "Gervais",
            "Gettysburg",
            "Gettysburg",
            "Gholson",
            "Gibbon",
            "Gibbsboro",
            "Gibbstown",
            "Gibraltar",
            "Gibson",
            "Gibson",
            "Gibson City",
            "Gibson County",
            "Gibson County",
            "Gibsonburg",
            "Gibsonia",
            "Gibsonia",
            "Gibsonton",
            "Gibsonville",
            "Giddings",
            "Gideon",
            "Gifford",
            "Gifford",
            "Gig Harbor",
            "Gila Bend",
            "Gila County",
            "Gilbert",
            "Gilbert",
            "Gilbert",
            "Gilbert Creek",
            "Gilberts",
            "Gilbertsville",
            "Gilchrist County",
            "Gilcrest",
            "Giles County",
            "Giles County",
            "Gilford",
            "Gill",
            "Gillespie",
            "Gillespie County",
            "Gillett",
            "Gillette",
            "Gilliam County",
            "Gilman",
            "Gilmanton",
            "Gilmer",
            "Gilmer County",
            "Gilmer County",
            "Gilpin County",
            "Gilroy",
            "Girard",
            "Girard",
            "Girard",
            "Girard",
            "Girardville",
            "Girdwood",
            "Glacier County",
            "Glade Spring",
            "Glades County",
            "Gladeview",
            "Gladewater",
            "Gladstone",
            "Gladstone",
            "Gladstone",
            "Gladstone",
            "Gladwin",
            "Gladwin County",
            "Glandorf",
            "Glasco",
            "Glascock County",
            "Glasford",
            "Glasgow",
            "Glasgow",
            "Glasgow",
            "Glasgow",
            "Glasgow",
            "Glasgow Village",
            "Glassboro",
            "Glasscock County",
            "Glassmanor",
            "Glassport",
            "Glastonbury",
            "Glastonbury Center",
            "Gleason",
            "Gleed",
            "Glen Allen",
            "Glen Alpine",
            "Glen Avon",
            "Glen Burnie",
            "Glen Carbon",
            "Glen Cove",
            "Glen Ellyn",
            "Glen Gardner",
            "Glen Head",
            "Glen Lyon",
            "Glen Oaks",
            "Glen Raven",
            "Glen Ridge",
            "Glen Rock",
            "Glen Rock",
            "Glen Rose",
            "Glenarden",
            "Glencoe",
            "Glencoe",
            "Glencoe",
            "Glencoe",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale",
            "Glendale Heights",
            "Glendive",
            "Glendora",
            "Glendora",
            "Gleneagle",
            "Glenmont",
            "Glenmoor",
            "Glenmora",
            "Glenmore",
            "Glenn County",
            "Glenn Dale",
            "Glenn Heights",
            "Glenns Ferry",
            "Glennville",
            "Glenolden",
            "Glenpool",
            "Glenrock",
            "Glens Falls",
            "Glens Falls North",
            "Glenshaw",
            "Glenside",
            "Glenvar Heights",
            "Glenview",
            "Glenville",
            "Glenville",
            "Glenville",
            "Glenwood",
            "Glenwood",
            "Glenwood",
            "Glenwood",
            "Glenwood City",
            "Glenwood Landing",
            "Glenwood Springs",
            "Glidden",
            "Glide",
            "Globe",
            "Gloucester",
            "Gloucester City",
            "Gloucester County",
            "Gloucester County",
            "Gloucester Courthouse",
            "Gloucester Point",
            "Glouster",
            "Gloversville",
            "Gloverville",
            "Glyndon",
            "Glynn County",
            "Gnadenhutten",
            "Goddard",
            "Goddard",
            "Godfrey",
            "Godley",
            "Goffstown",
            "Gogebic County",
            "Golconda",
            "Gold Bar",
            "Gold Beach",
            "Gold Camp",
            "Gold Canyon",
            "Gold Hill",
            "Gold Key Lake",
            "Gold River",
            "Golden",
            "Golden Beach",
            "Golden Gate",
            "Golden Glades",
            "Golden Grove",
            "Golden Hills",
            "Golden Meadow",
            "Golden Shores",
            "Golden Triangle",
            "Golden Valley",
            "Golden Valley",
            "Golden Valley",
            "Golden Valley County",
            "Golden Valley County",
            "Goldendale",
            "Goldenrod",
            "Goldens Bridge",
            "Goldfield",
            "Goldsboro",
            "Goldsby",
            "Goldthwaite",
            "Goleta",
            "Golf Manor",
            "Goliad",
            "Goliad County",
            "Gonzales",
            "Gonzales",
            "Gonzales",
            "Gonzales County",
            "Gonzalez",
            "Goochland",
            "Goochland County",
            "Good Hope",
            "Good Hope",
            "Goodhue",
            "Goodhue County",
            "Gooding",
            "Gooding County",
            "Goodings Grove",
            "Goodland",
            "Goodland",
            "Goodlettsville",
            "Goodman",
            "Goodman",
            "Goodrich",
            "Goodview",
            "Goodwater",
            "Goodwell",
            "Goodyear",
            "Goose Creek",
            "Gordo",
            "Gordon",
            "Gordon",
            "Gordon County",
            "Gordon Heights",
            "Gordonsville",
            "Gordonsville",
            "Goreville",
            "Gorham",
            "Gorham",
            "Gorman",
            "Gorman",
            "Goshen",
            "Goshen",
            "Goshen",
            "Goshen",
            "Goshen",
            "Goshen County",
            "Gosnell",
            "Gosper County",
            "Gotha",
            "Gothenburg",
            "Goulding",
            "Goulds",
            "Gouldsboro",
            "Gouverneur",
            "Gove",
            "Gove County",
            "Gowanda",
            "Gower",
            "Grabill",
            "Graceville",
            "Grady County",
            "Grady County",
            "Grafton",
            "Grafton",
            "Grafton",
            "Grafton",
            "Grafton",
            "Grafton",
            "Grafton County",
            "Graham",
            "Graham",
            "Graham",
            "Graham County",
            "Graham County",
            "Graham County",
            "Grain Valley",
            "Grainger County",
            "Grambling",
            "Gramercy",
            "Granbury",
            "Granby",
            "Granby",
            "Granby",
            "Grand Bay",
            "Grand Blanc",
            "Grand Boulevard",
            "Grand Canyon",
            "Grand Canyon Village",
            "Grand Coulee",
            "Grand County",
            "Grand County",
            "Grand Forks",
            "Grand Forks Air Force Base",
            "Grand Forks County",
            "Grand Haven",
            "Grand Island",
            "Grand Island",
            "Grand Isle",
            "Grand Isle County",
            "Grand Junction",
            "Grand Ledge",
            "Grand Marais",
            "Grand Meadow",
            "Grand Mound",
            "Grand Point",
            "Grand Prairie",
            "Grand Rapids",
            "Grand Rapids",
            "Grand Ronde",
            "Grand Saline",
            "Grand Terrace",
            "Grand Traverse County",
            "Grandview",
            "Grandview",
            "Grandview",
            "Grandview",
            "Grandview",
            "Grandview Heights",
            "Grandview Plaza",
            "Grandville",
            "Grandwood Park",
            "Grandyle Village",
            "Granger",
            "Granger",
            "Granger",
            "Granger",
            "Grangeville",
            "Granite",
            "Granite",
            "Granite Bay",
            "Granite City",
            "Granite County",
            "Granite Falls",
            "Granite Falls",
            "Granite Falls",
            "Granite Hills",
            "Granite Quarry",
            "Granite Shoals",
            "Graniteville",
            "Graniteville",
            "Grant",
            "Grant",
            "Grant City",
            "Grant City",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant County",
            "Grant Parish",
            "Grant Park",
            "Grant-Valkaria",
            "Grantham",
            "Grantley",
            "Grants",
            "Grants Pass",
            "Grantsburg",
            "Grantsville",
            "Grantsville",
            "Grantville",
            "Granville",
            "Granville",
            "Granville",
            "Granville",
            "Granville",
            "Granville County",
            "Granville South",
            "Grape Creek",
            "Grapeland",
            "Grapevine",
            "Grasonville",
            "Grass Lake",
            "Grass Valley",
            "Gratiot County",
            "Graton",
            "Gravel Ridge",
            "Graves County",
            "Gravesend",
            "Gravette",
            "Gray",
            "Gray",
            "Gray",
            "Gray County",
            "Gray County",
            "Gray Summit",
            "Grayling",
            "Graymoor-Devondale",
            "Grays Harbor County",
            "Grayslake",
            "Grayson",
            "Grayson",
            "Grayson County",
            "Grayson County",
            "Grayson County",
            "Grayson Valley",
            "Graysville",
            "Graysville",
            "Grayville",
            "Great Barrington",
            "Great Bend",
            "Great Falls",
            "Great Falls",
            "Great Falls",
            "Great Kills",
            "Great Neck",
            "Great Neck Estates",
            "Great Neck Gardens",
            "Great Neck Plaza",
            "Great River",
            "Greater Grand Crossing",
            "Greater Northdale",
            "Greater Upper Marlboro",
            "Greatwood",
            "Greece",
            "Greeley",
            "Greeley",
            "Greeley County",
            "Greeley County",
            "Green",
            "Green",
            "Green Acres",
            "Green Bay",
            "Green County",
            "Green County",
            "Green Cove Springs",
            "Green Forest",
            "Green Harbor-Cedar Crest",
            "Green Haven",
            "Green Hill",
            "Green Island",
            "Green Knoll",
            "Green Lake",
            "Green Lake County",
            "Green Level",
            "Green Meadows",
            "Green Oaks",
            "Green Park",
            "Green River",
            "Green Rock",
            "Green Springs",
            "Green Tree",
            "Green Valley",
            "Green Valley",
            "Green Valley",
            "Green Valley Farms",
            "Greenacres",
            "Greenacres City",
            "Greenback",
            "Greenbelt",
            "Greenbriar",
            "Greenbriar",
            "Greenbrier",
            "Greenbrier",
            "Greenbrier County",
            "Greenburgh",
            "Greenbush",
            "Greencastle",
            "Greencastle",
            "Greendale",
            "Greendale",
            "Greene",
            "Greene",
            "Greene",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greene County",
            "Greeneville",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfield",
            "Greenfields",
            "Greenhills",
            "Greenland",
            "Greenland",
            "Greenlawn",
            "Greenlee County",
            "Greenock",
            "Greenpoint",
            "Greenport",
            "Greenport West",
            "Greensboro",
            "Greensboro",
            "Greensboro",
            "Greensboro",
            "Greensburg",
            "Greensburg",
            "Greensburg",
            "Greensburg",
            "Greensburg",
            "Greensburg",
            "Greensville County",
            "Greentown",
            "Greentown",
            "Greentree",
            "Greenup",
            "Greenup",
            "Greenup County",
            "Greenvale",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville",
            "Greenville County",
            "Greenwich",
            "Greenwich",
            "Greenwich",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood",
            "Greenwood County",
            "Greenwood County",
            "Greenwood Lake",
            "Greenwood Village",
            "Greer",
            "Greer County",
            "Gregg County",
            "Gregory",
            "Gregory",
            "Gregory County",
            "Greilickville",
            "Grenada",
            "Grenada County",
            "Gresham",
            "Gresham Park",
            "Gretna",
            "Gretna",
            "Gretna",
            "Gretna",
            "Greybull",
            "Gridley",
            "Gridley",
            "Griffin",
            "Griffith",
            "Grifton",
            "Griggs County",
            "Griggsville",
            "Grill",
            "Grimes",
            "Grimes County",
            "Grimsley",
            "Grinnell",
            "Grissom Air Force Base",
            "Groesbeck",
            "Groesbeck",
            "Grosse Ile",
            "Grosse Pointe",
            "Grosse Pointe Farms",
            "Grosse Pointe Park",
            "Grosse Pointe Shores",
            "Grosse Pointe Woods",
            "Groton",
            "Groton",
            "Groton",
            "Groton",
            "Grottoes",
            "Grove",
            "Grove City",
            "Grove City",
            "Grove City",
            "Grove Hill",
            "Groveland",
            "Groveland",
            "Groveport",
            "Grover Beach",
            "Groves",
            "Groveton",
            "Groveton",
            "Groveton",
            "Grovetown",
            "Groveville",
            "Gruetli-Laager",
            "Grundy",
            "Grundy Center",
            "Grundy County",
            "Grundy County",
            "Grundy County",
            "Grundy County",
            "Gruver",
            "Grymes Hill",
            "Guadalupe",
            "Guadalupe",
            "Guadalupe County",
            "Guadalupe County",
            "Guánica",
            "Guánica Municipio",
            "Guayabal",
            "Guayama",
            "Guayama Municipio",
            "Guayanilla",
            "Guaynabo",
            "Guerneville",
            "Guernsey",
            "Guernsey County",
            "Gueydan",
            "Guildhall",
            "Guilford",
            "Guilford",
            "Guilford Center",
            "Guilford County",
            "Guilford Siding",
            "Guin",
            "Gulf Breeze",
            "Gulf County",
            "Gulf Gate Estates",
            "Gulf Hills",
            "Gulf Park Estates",
            "Gulf Shores",
            "Gulfport",
            "Gulfport",
            "Gulivoire Park",
            "Gumlog",
            "Gun Barrel City",
            "Gunbarrel",
            "Gunnison",
            "Gunnison",
            "Gunnison County",
            "Gunter",
            "Guntersville",
            "Guntown",
            "Gurabo",
            "Gurabo Municipio",
            "Gurdon",
            "Gurnee",
            "Gustine",
            "Guthrie",
            "Guthrie",
            "Guthrie",
            "Guthrie Center",
            "Guthrie County",
            "Guttenberg",
            "Guttenberg",
            "Guymon",
            "Guyton",
            "Gwinn",
            "Gwinnett County",
            "Gypsum",
            "H. Rivera Colon",
            "Haakon County",
            "Habersham County",
            "Hacienda Heights",
            "Hackberry",
            "Hackberry",
            "Hackensack",
            "Hackettstown",
            "Hackleburg",
            "Haddon Heights",
            "Haddonfield",
            "Hadley",
            "Hadley",
            "Hagaman",
            "Hagerman",
            "Hagerstown",
            "Hagerstown",
            "Hahira",
            "Hahnville",
            "Haiku-Pauwela",
            "Hailey",
            "Haines",
            "Haines Borough",
            "Haines City",
            "Hainesville",
            "Hālawa",
            "Hālawa Heights",
            "Hale Center",
            "Hale County",
            "Hale County",
            "Hale‘iwa",
            "Haledon",
            "Hales Corners",
            "Halesite",
            "Haleyville",
            "Half Moon",
            "Half Moon Bay",
            "Halfway",
            "Halfway House",
            "Halifax",
            "Halifax",
            "Halifax",
            "Halifax County",
            "Halifax County",
            "Hall County",
            "Hall County",
            "Hall County",
            "Hall Park",
            "Hallam",
            "Hallandale Beach",
            "Hallettsville",
            "Hallock",
            "Hallowell",
            "Halls",
            "Hallstead",
            "Hallsville",
            "Hallsville",
            "Halstead",
            "Haltom City",
            "Ham Lake",
            "Hamblen County",
            "Hamburg",
            "Hamburg",
            "Hamburg",
            "Hamburg",
            "Hamburg",
            "Hamden",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton",
            "Hamilton City",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton County",
            "Hamilton Square",
            "Hamilton Worcester",
            "Hamlet",
            "Hamlin",
            "Hamlin",
            "Hamlin",
            "Hamlin County",
            "Hammond",
            "Hammond",
            "Hammond",
            "Hammonton",
            "Hampden",
            "Hampden",
            "Hampden County",
            "Hampden Sydney",
            "Hampshire",
            "Hampshire County",
            "Hampshire County",
            "Hampstead",
            "Hampstead",
            "Hampstead",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton",
            "Hampton Bays",
            "Hampton Beach",
            "Hampton County",
            "Hampton Falls",
            "Hampton Manor",
            "Hamtramck",
            "Hana",
            "Hanahan",
            "Hanamā‘ulu",
            "Hanapēpē",
            "Hanapēpē Heights",
            "Hanceville",
            "Hancock",
            "Hancock",
            "Hancock",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hancock County",
            "Hand County",
            "Hanford",
            "Hanley Hills",
            "Hanna City",
            "Hannahs Mill",
            "Hannawa Falls",
            "Hannibal",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover",
            "Hanover County",
            "Hanover Park",
            "Hansen",
            "Hansford County",
            "Hanson",
            "Hanson County",
            "Hansville",
            "Hapeville",
            "Happy Camp",
            "Happy Valley",
            "Harahan",
            "Haralson County",
            "Harbison Canyon",
            "Harbor",
            "Harbor Beach",
            "Harbor Bluffs",
            "Harbor Hills",
            "Harbor Isle",
            "Harbor Springs",
            "Harbour Heights",
            "Hardee County",
            "Hardeeville",
            "Hardeman County",
            "Hardeman County",
            "Hardin",
            "Hardin",
            "Hardin County",
            "Hardin County",
            "Hardin County",
            "Hardin County",
            "Hardin County",
            "Hardin County",
            "Harding County",
            "Harding County",
            "Hardinsburg",
            "Hardwick",
            "Hardwick",
            "Hardwick",
            "Hardwick",
            "Hardy County",
            "Harford County",
            "Harker Heights",
            "Harkers Island",
            "Harlan",
            "Harlan",
            "Harlan",
            "Harlan County",
            "Harlan County",
            "Harleigh",
            "Harlem",
            "Harlem",
            "Harlem",
            "Harlem Heights",
            "Harleysville",
            "Harlingen",
            "Harlowton",
            "Harmon County",
            "Harnett County",
            "Harney County",
            "Harper",
            "Harper",
            "Harper County",
            "Harper County",
            "Harper Woods",
            "Harpersville",
            "Harpswell Center",
            "Harrah",
            "Harriman",
            "Harriman",
            "Harrington",
            "Harrington Park",
            "Harris",
            "Harris County",
            "Harris County",
            "Harris Hill",
            "Harrisburg",
            "Harrisburg",
            "Harrisburg",
            "Harrisburg",
            "Harrisburg",
            "Harrisburg",
            "Harrisburg",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison",
            "Harrison County",
            "Harrison County",
            "Harrison County",
            "Harrison County",
            "Harrison County",
            "Harrison County",
            "Harrison County",
            "Harrison County",
            "Harrisonburg",
            "Harrisonburg",
            "Harrisonville",
            "Harristown",
            "Harrisville",
            "Harrisville",
            "Harrisville",
            "Harrisville",
            "Harrisville",
            "Harrodsburg",
            "Harrogate",
            "Hart",
            "Hart",
            "Hart County",
            "Hart County",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford",
            "Hartford City",
            "Hartford County",
            "Hartington",
            "Hartland",
            "Hartley",
            "Hartley",
            "Hartley County",
            "Hartsdale",
            "Hartselle",
            "Hartshorne",
            "Hartsville",
            "Hartsville",
            "Hartville",
            "Hartville",
            "Hartwell",
            "Harvard",
            "Harvard",
            "Harvest",
            "Harvey",
            "Harvey",
            "Harvey",
            "Harvey",
            "Harvey County",
            "Harveys Lake",
            "Harwich",
            "Harwich Center",
            "Harwich Port",
            "Harwood Heights",
            "Hasbrouck Heights",
            "Haskell",
            "Haskell",
            "Haskell",
            "Haskell County",
            "Haskell County",
            "Haskell County",
            "Haskins",
            "Haslet",
            "Haslett",
            "Hasson Heights",
            "Hastings",
            "Hastings",
            "Hastings",
            "Hastings",
            "Hastings-on-Hudson",
            "Hatboro",
            "Hatch",
            "Hatfield",
            "Hatfield",
            "Hatillo",
            "Hatillo Municipio",
            "Hato Arriba",
            "Hato Candal",
            "Hattiesburg",
            "Hau‘ula",
            "Haubstadt",
            "Haughton",
            "Hauppauge",
            "Havana",
            "Havana",
            "Havelock",
            "Haven",
            "Haverhill",
            "Haverhill",
            "Haverhill",
            "Haverstraw",
            "Haviland",
            "Havre",
            "Havre de Grace",
            "Haw River",
            "Hawaii County",
            "Hawaiian Acres",
            "Hawaiian Beaches",
            "Hawaiian Gardens",
            "Hawaiian Ocean View",
            "Hawaiian Paradise Park",
            "Hawarden",
            "Hawesville",
            "Hāwī",
            "Hawkins",
            "Hawkins County",
            "Hawkinsville",
            "Hawley",
            "Hawley",
            "Haworth",
            "Hawthorn Woods",
            "Hawthorne",
            "Hawthorne",
            "Hawthorne",
            "Hawthorne",
            "Hawthorne",
            "Hayden",
            "Hayden",
            "Hayden",
            "Hayes Center",
            "Hayes County",
            "Hayesville",
            "Hayesville",
            "Hayfield",
            "Hayfield",
            "Hayfork",
            "Haymarket",
            "Haynesville",
            "Hayneville",
            "Hays",
            "Hays",
            "Hays County",
            "Haysville",
            "Hayti",
            "Hayti",
            "Hayward",
            "Hayward",
            "Haywood County",
            "Haywood County",
            "Hazard",
            "Hazardville",
            "Hazel Crest",
            "Hazel Dell",
            "Hazel Green",
            "Hazel Green",
            "Hazel Park",
            "Hazelwood",
            "Hazelwood",
            "Hazen",
            "Hazen",
            "Hazlehurst",
            "Hazlehurst",
            "Hazlet Township",
            "Hazleton",
            "He‘eia",
            "Head of the Harbor",
            "Head of Westport",
            "Headland",
            "Healdsburg",
            "Healdton",
            "Healy",
            "Heard County",
            "Hearne",
            "Heath",
            "Heath",
            "Heathcote",
            "Heathrow",
            "Heathsville",
            "Heavener",
            "Hebbronville",
            "Heber",
            "Heber City",
            "Heber Springs",
            "Heber-Overgaard",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron",
            "Hebron Estates",
            "Hector",
            "Hedwig Village",
            "Heflin",
            "Heidelberg",
            "Heidelberg",
            "Helena",
            "Helena",
            "Helena",
            "Helena",
            "Helena",
            "Helena",
            "Helena Valley Northeast",
            "Helena Valley Northwest",
            "Helena Valley Southeast",
            "Helena Valley West Central",
            "Helena West Side",
            "Helena-West Helena",
            "Hellertown",
            "Helmetta",
            "Helotes",
            "Helper",
            "Hemby Bridge",
            "Hemet",
            "Hemlock",
            "Hemlock Farms",
            "Hemphill",
            "Hemphill County",
            "Hempstead",
            "Hempstead",
            "Hempstead County",
            "Henagar",
            "Henderson",
            "Henderson",
            "Henderson",
            "Henderson",
            "Henderson",
            "Henderson",
            "Henderson",
            "Henderson County",
            "Henderson County",
            "Henderson County",
            "Henderson County",
            "Henderson County",
            "Hendersonville",
            "Hendersonville",
            "Hendricks County",
            "Hendron",
            "Hendry County",
            "Hennepin",
            "Hennepin County",
            "Hennessey",
            "Henniker",
            "Henrico County",
            "Henrietta",
            "Henry",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry County",
            "Henry Fork",
            "Henryetta",
            "Henryville",
            "Hephzibah",
            "Heppner",
            "Herald",
            "Herald Harbor",
            "Herculaneum",
            "Hercules",
            "Hereford",
            "Herington",
            "Heritage Creek",
            "Heritage Hills",
            "Heritage Lake",
            "Heritage Lake",
            "Heritage Pines",
            "Heritage Village",
            "Herkimer",
            "Herkimer County",
            "Hermann",
            "Hermantown",
            "Hermiston",
            "Hermitage",
            "Hermitage",
            "Hermon",
            "Hermosa Beach",
            "Hernando",
            "Hernando",
            "Hernando Beach",
            "Hernando County",
            "Herndon",
            "Herricks",
            "Herriman",
            "Herrin",
            "Herscher",
            "Hershey",
            "Hertford",
            "Hertford County",
            "Hesperia",
            "Hesston",
            "Hettinger",
            "Hettinger County",
            "Hewitt",
            "Hewlett",
            "Hewlett Harbor",
            "Heyburn",
            "Heyworth",
            "Hialeah",
            "Hialeah Gardens",
            "Hiawassee",
            "Hiawatha",
            "Hiawatha",
            "Hibbing",
            "Hickam Field",
            "Hickman",
            "Hickman",
            "Hickman County",
            "Hickman County",
            "Hickory",
            "Hickory County",
            "Hickory Creek",
            "Hickory Hills",
            "Hickory Hills",
            "Hickory Withe",
            "Hicksville",
            "Hicksville",
            "Hico",
            "Hidalgo",
            "Hidalgo County",
            "Hidalgo County",
            "Hidden Hills",
            "Hidden Meadows",
            "Hidden Spring",
            "Hidden Valley",
            "Hidden Valley Lake",
            "Hide-A-Way Lake",
            "Hideaway",
            "Higganum",
            "Higginsville",
            "High Bridge",
            "High Point",
            "High Point",
            "High Ridge",
            "High Springs",
            "Highfield-Cascade",
            "Highgrove",
            "Highland",
            "Highland",
            "Highland",
            "Highland",
            "Highland",
            "Highland",
            "Highland",
            "Highland",
            "Highland",
            "Highland Acres",
            "Highland Beach",
            "Highland City",
            "Highland County",
            "Highland County",
            "Highland Falls",
            "Highland Heights",
            "Highland Heights",
            "Highland Lake",
            "Highland Lakes",
            "Highland Mills",
            "Highland Park",
            "Highland Park",
            "Highland Park",
            "Highland Park",
            "Highland Park",
            "Highland Springs",
            "Highland Village",
            "Highlands",
            "Highlands",
            "Highlands County",
            "Highlands Ranch",
            "Highlands-Baywood Park",
            "Highmore",
            "Highpoint",
            "Highspire",
            "Hightstown",
            "Highview",
            "Highwood",
            "Hiland Park",
            "Hilbert",
            "Hildale",
            "Hildebran",
            "Hill",
            "Hill 'n Dale",
            "Hill Air Force Base",
            "Hill City",
            "Hill Country Village",
            "Hill County",
            "Hill County",
            "Hillandale",
            "Hillcrest",
            "Hillcrest",
            "Hillcrest Heights",
            "Hilldale",
            "Hiller",
            "Hilliard",
            "Hilliard",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro",
            "Hillsboro Beach",
            "Hillsborough",
            "Hillsborough",
            "Hillsborough",
            "Hillsborough",
            "Hillsborough County",
            "Hillsborough County",
            "Hillsdale",
            "Hillsdale",
            "Hillsdale",
            "Hillsdale County",
            "Hillside",
            "Hillside",
            "Hillside",
            "Hillside Lake",
            "Hillsmere Shores",
            "Hillsville",
            "Hilltop Lakes",
            "Hillview",
            "Hilmar-Irwin",
            "Hilo",
            "Hilton",
            "Hilton Head",
            "Hilton Head Island",
            "Hinckley",
            "Hinckley",
            "Hindman",
            "Hinds County",
            "Hines",
            "Hinesburg",
            "Hinesville",
            "Hingham",
            "Hinsdale",
            "Hinsdale",
            "Hinsdale",
            "Hinsdale County",
            "Hinton",
            "Hinton",
            "Hiram",
            "Hiram",
            "Hiram",
            "Hitchcock",
            "Hitchcock County",
            "Ho-Ho-Kus",
            "Hoback",
            "Hobart",
            "Hobart",
            "Hobart",
            "Hobart",
            "Hobbs",
            "Hobe Sound",
            "Hoboken",
            "Hockessin",
            "Hocking County",
            "Hockinson",
            "Hockley County",
            "Hodgdon",
            "Hodgeman County",
            "Hodgenville",
            "Hodgkins",
            "Hoffman Estates",
            "Hogansville",
            "Hohenwald",
            "Hoisington",
            "Hoke County",
            "Hokendauqua",
            "Hokes Bluff",
            "Holbrook",
            "Holbrook",
            "Holbrook",
            "Holcomb",
            "Holcomb",
            "Holden",
            "Holden",
            "Holden",
            "Holden Heights",
            "Holdenville",
            "Holderness",
            "Holdrege",
            "Holgate",
            "Holiday",
            "Holiday City South",
            "Holiday City-Berkeley",
            "Holiday Heights",
            "Holiday Island",
            "Holiday Lakes",
            "Holiday Shores",
            "Holiday Valley",
            "Holladay",
            "Holland",
            "Holland",
            "Holland",
            "Holland",
            "Holland",
            "Hollandale",
            "Holley",
            "Holley",
            "Holliday",
            "Hollidaysburg",
            "Hollins",
            "Hollis",
            "Hollis",
            "Hollis",
            "Hollis Center",
            "Hollister",
            "Hollister",
            "Holliston",
            "Holloman Air Force Base",
            "Holly",
            "Holly Hill",
            "Holly Hill",
            "Holly Hills",
            "Holly Lake Ranch",
            "Holly Ridge",
            "Holly Springs",
            "Holly Springs",
            "Holly Springs",
            "Hollymead",
            "Hollywood",
            "Hollywood",
            "Hollywood",
            "Hollywood Park",
            "Holmen",
            "Holmes Beach",
            "Holmes County",
            "Holmes County",
            "Holmes County",
            "Holstein",
            "Holt",
            "Holt",
            "Holt County",
            "Holt County",
            "Holton",
            "Holts Summit",
            "Holtsville",
            "Holtville",
            "Holtville",
            "Hōlualoa",
            "Holyoke",
            "Holyoke",
            "Home",
            "Home Garden",
            "Home Gardens",
            "Homeacre-Lyndora",
            "Homedale",
            "Homeland",
            "Homeland Park",
            "Homer",
            "Homer",
            "Homer",
            "Homer",
            "Homer",
            "Homer",
            "Homer City",
            "Homer Glen",
            "Homerville",
            "Homestead",
            "Homestead",
            "Homestead Meadows North",
            "Homestead Meadows South",
            "Hometown",
            "Hometown",
            "Homewood",
            "Homewood",
            "Hominy",
            "Homosassa",
            "Homosassa Springs",
            "Honaker",
            "Honalo",
            "Honaunau-Napoopoo",
            "Hondo",
            "Honea Path",
            "Honeoye Falls",
            "Honesdale",
            "Honey Brook",
            "Honey Grove",
            "Honeyville",
            "Honoka‘a",
            "Honolulu",
            "Honolulu County",
            "Hood County",
            "Hood River",
            "Hood River County",
            "Hooker",
            "Hooker County",
            "Hooks",
            "Hooksett",
            "Hoonah-Angoon Census Area",
            "Hooper",
            "Hooper Bay",
            "Hoopers Creek",
            "Hoopeston",
            "Hoosick Falls",
            "Hoover",
            "Hooverson Heights",
            "Hopatcong",
            "Hopatcong Hills",
            "Hope",
            "Hope",
            "Hope",
            "Hope Mills",
            "Hope Valley",
            "Hopedale",
            "Hopewell",
            "Hopewell",
            "Hopewell",
            "Hopkins",
            "Hopkins",
            "Hopkins County",
            "Hopkins County",
            "Hopkinsville",
            "Hopkinton",
            "Hopkinton",
            "Hopkinton",
            "Hopwood",
            "Hoquiam",
            "Horace",
            "Horatio",
            "Horicon",
            "Horizon City",
            "Horizon West",
            "Hormigueros",
            "Hormigueros Municipio",
            "Horn Lake",
            "Hornell",
            "Hornsby Bend",
            "Horry County",
            "Horse Cave",
            "Horse Pasture",
            "Horse Shoe",
            "Horseheads",
            "Horseheads North",
            "Horseshoe Bay",
            "Horseshoe Bend",
            "Horsham",
            "Horton",
            "Hortonville",
            "Hoschton",
            "Hot Spring County",
            "Hot Springs",
            "Hot Springs",
            "Hot Springs County",
            "Hot Springs National Park",
            "Hot Springs Village",
            "Hot Sulphur Springs",
            "Houck",
            "Hough",
            "Houghton",
            "Houghton",
            "Houghton County",
            "Houghton Lake",
            "Houlton",
            "Houma",
            "Housatonic",
            "Houserville",
            "Houston",
            "Houston",
            "Houston",
            "Houston",
            "Houston",
            "Houston County",
            "Houston County",
            "Houston County",
            "Houston County",
            "Houston County",
            "Howard",
            "Howard",
            "Howard",
            "Howard Beach",
            "Howard City",
            "Howard County",
            "Howard County",
            "Howard County",
            "Howard County",
            "Howard County",
            "Howard County",
            "Howard County",
            "Howard Lake",
            "Howards Grove",
            "Howe",
            "Howell",
            "Howell County",
            "Howey-in-the-Hills",
            "Howland",
            "Howland Center",
            "Hoxie",
            "Hoxie",
            "Hoyt Lakes",
            "Huachuca City",
            "Hubbard",
            "Hubbard",
            "Hubbard",
            "Hubbard County",
            "Hubbard Lake",
            "Hubbardston",
            "Huber Heights",
            "Huber Ridge",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson",
            "Hudson Bend",
            "Hudson County",
            "Hudson Falls",
            "Hudson Lake",
            "Hudson Oaks",
            "Hudsonville",
            "Hudspeth County",
            "Huerfano County",
            "Hueytown",
            "Hughes",
            "Hughes County",
            "Hughes County",
            "Hughes Springs",
            "Hughestown",
            "Hughesville",
            "Hughesville",
            "Hughson",
            "Hugo",
            "Hugo",
            "Hugo",
            "Hugoton",
            "Huguenot",
            "Huguley",
            "Hull",
            "Hull",
            "Humacao",
            "Humansville",
            "Humble",
            "Humboldt",
            "Humboldt",
            "Humboldt",
            "Humboldt County",
            "Humboldt County",
            "Humboldt County",
            "Humboldt Hill",
            "Hummels Wharf",
            "Hummelstown",
            "Humphreys County",
            "Humphreys County",
            "Hunt County",
            "Hunt Valley",
            "Hunter",
            "Hunter",
            "Hunterdon County",
            "Hunters Creek",
            "Hunters Creek Village",
            "Huntersville",
            "Huntertown",
            "Huntingburg",
            "Huntingdon",
            "Huntingdon",
            "Huntingdon County",
            "Huntington",
            "Huntington",
            "Huntington",
            "Huntington",
            "Huntington",
            "Huntington",
            "Huntington Bay",
            "Huntington Beach",
            "Huntington County",
            "Huntington Park",
            "Huntington Station",
            "Huntington Woods",
            "Huntingtown",
            "Huntingtown Town Center",
            "Huntley",
            "Hunts Point",
            "Huntsville",
            "Huntsville",
            "Huntsville",
            "Huntsville",
            "Huntsville",
            "Hurley",
            "Hurley",
            "Hurley",
            "Hurley",
            "Hurlock",
            "Huron",
            "Huron",
            "Huron",
            "Huron County",
            "Huron County",
            "Hurricane",
            "Hurricane",
            "Hurst",
            "Hurstbourne",
            "Hurstbourne Acres",
            "Hurt",
            "Hustisford",
            "Hutchins",
            "Hutchinson",
            "Hutchinson",
            "Hutchinson County",
            "Hutchinson County",
            "Hutchinson Island South",
            "Hutto",
            "Huxley",
            "Hyannis",
            "Hyannis",
            "Hyattsville",
            "Hybla Valley",
            "Hyde",
            "Hyde County",
            "Hyde County",
            "Hyde Park",
            "Hyde Park",
            "Hyde Park",
            "Hyde Park",
            "Hyde Park",
            "Hyden",
            "Hydesville",
            "Hypoluxo",
            "Hyrum",
            "Hysham",
            "Iberia Parish",
            "Iberville Parish",
            "Icard",
            "Ida County",
            "Ida Grove",
            "Idabel",
            "Idaho City",
            "Idaho County",
            "Idaho Falls",
            "Idaho Springs",
            "Idalou",
            "Idyllwild",
            "Idyllwild-Pine Cove",
            "Idylwood",
            "Ilchester",
            "Ilion",
            "Imbery",
            "Imlay City",
            "Immokalee",
            "Imperial",
            "Imperial",
            "Imperial",
            "Imperial",
            "Imperial Beach",
            "Imperial County",
            "Ina",
            "Incline Village",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence",
            "Independence County",
            "Independent Hill",
            "India Hook",
            "Indialantic",
            "Indian Harbour Beach",
            "Indian Head",
            "Indian Head Park",
            "Indian Heights",
            "Indian Hills",
            "Indian Hills",
            "Indian Hills",
            "Indian Hills",
            "Indian Hills Cherokee Section",
            "Indian Mountain Lake",
            "Indian River",
            "Indian River County",
            "Indian River Estates",
            "Indian River Shores",
            "Indian Rocks Beach",
            "Indian Shores",
            "Indian Springs",
            "Indian Springs Village",
            "Indian Trail",
            "Indian Wells",
            "Indiana",
            "Indiana County",
            "Indianapolis",
            "Indianola",
            "Indianola",
            "Indianola",
            "Indiantown",
            "Indio",
            "Indios",
            "Industry",
            "Inez",
            "Inez",
            "Ingalls",
            "Ingalls Park",
            "Ingenio",
            "Ingham County",
            "Ingleside",
            "Inglewood",
            "Inglewood-Finn Hill",
            "Inglis",
            "Ingram",
            "Ingram",
            "Inkerman",
            "Inkster",
            "Inman",
            "Inman",
            "Inman Mills",
            "Inniswold",
            "Inola",
            "Intercourse",
            "Interlachen",
            "Interlaken",
            "International Falls",
            "Inver Grove Heights",
            "Inverness",
            "Inverness",
            "Inverness",
            "Inverness",
            "Inverness",
            "Inverness Highlands North",
            "Inverness Highlands South",
            "Inwood",
            "Inwood",
            "Inwood",
            "Inyo County",
            "Inyokern",
            "Iola",
            "Iola",
            "Iona",
            "Iona",
            "Ione",
            "Ionia",
            "Ionia County",
            "Iosco County",
            "Iota",
            "Iowa",
            "Iowa City",
            "Iowa Colony",
            "Iowa County",
            "Iowa County",
            "Iowa Falls",
            "Iowa Park",
            "Ipswich",
            "Ipswich",
            "Iraan",
            "Iredell County",
            "Irion County",
            "Irmo",
            "Iron County",
            "Iron County",
            "Iron County",
            "Iron County",
            "Iron Mountain",
            "Iron River",
            "Irondale",
            "Irondale",
            "Irondequoit",
            "Ironton",
            "Ironton",
            "Ironville",
            "Ironwood",
            "Iroquois County",
            "Iroquois Point",
            "Irrigon",
            "Irvine",
            "Irvine",
            "Irving",
            "Irving Park",
            "Irvington",
            "Irvington",
            "Irvington",
            "Irvington",
            "Irwin",
            "Irwin",
            "Irwin County",
            "Irwindale",
            "Irwinton",
            "Isabel Segunda",
            "Isabela",
            "Isabella County",
            "Isanti",
            "Isanti County",
            "Iselin",
            "Ishpeming",
            "Isla Vista",
            "Islamorada",
            "Island City",
            "Island County",
            "Island Heights",
            "Island Lake",
            "Island Park",
            "Island Walk",
            "Islandia",
            "Isle of Hope",
            "Isle of Normandy",
            "Isle of Palms",
            "Isle of Wight County",
            "Islip",
            "Islip Terrace",
            "Issaquah",
            "Issaquena County",
            "Italy",
            "Itasca",
            "Itasca",
            "Itasca County",
            "Itawamba County",
            "Ithaca",
            "Ithaca",
            "Itta Bena",
            "Iuka",
            "Iva",
            "Ivanhoe",
            "Ivanhoe",
            "Ives Estates",
            "Ivins",
            "Ivyland",
            "Ixonia",
            "Izard County",
            "Jacinto City",
            "Jack County",
            "Jackpot",
            "Jacksboro",
            "Jacksboro",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson",
            "Jackson Center",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson County",
            "Jackson Heights",
            "Jackson Parish",
            "Jacksonville",
            "Jacksonville",
            "Jacksonville",
            "Jacksonville",
            "Jacksonville",
            "Jacksonville",
            "Jacksonville",
            "Jacksonville Beach",
            "Jacksonwald",
            "Jacobus",
            "Jaffrey",
            "Jagual",
            "Jal",
            "Jamaica",
            "Jamaica",
            "Jamaica Beach",
            "Jamaica Plain",
            "James City",
            "James City County",
            "James Island",
            "Jamesburg",
            "Jamesport",
            "Jamestown",
            "Jamestown",
            "Jamestown",
            "Jamestown",
            "Jamestown",
            "Jamestown",
            "Jamestown",
            "Jamestown",
            "Jamestown West",
            "Jamul",
            "Jan-Phyl Village",
            "Janesville",
            "Janesville",
            "Janesville",
            "Jarales",
            "Jarrell",
            "Jarrettsville",
            "Jasmine Estates",
            "Jasonville",
            "Jasper",
            "Jasper",
            "Jasper",
            "Jasper",
            "Jasper",
            "Jasper",
            "Jasper",
            "Jasper County",
            "Jasper County",
            "Jasper County",
            "Jasper County",
            "Jasper County",
            "Jasper County",
            "Jasper County",
            "Jasper County",
            "Jauca",
            "Jay",
            "Jay",
            "Jay County",
            "Jayton",
            "Jayuya",
            "Jean Lafitte",
            "Jeanerette",
            "Jeannette",
            "Jeff Davis County",
            "Jeff Davis County",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson",
            "Jefferson City",
            "Jefferson City",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson County",
            "Jefferson Davis County",
            "Jefferson Davis Parish",
            "Jefferson Heights",
            "Jefferson Hills",
            "Jefferson Parish",
            "Jefferson Valley-Yorktown",
            "Jeffersontown",
            "Jeffersonville",
            "Jeffersonville",
            "Jeffersonville",
            "Jeffersonville",
            "Jellico",
            "Jemez Pueblo",
            "Jemison",
            "Jena",
            "Jenison",
            "Jenkins",
            "Jenkins County",
            "Jenkintown",
            "Jenks",
            "Jennings",
            "Jennings",
            "Jennings County",
            "Jennings Lodge",
            "Jensen Beach",
            "Jerauld County",
            "Jericho",
            "Jericho",
            "Jermyn",
            "Jerome",
            "Jerome",
            "Jerome",
            "Jerome County",
            "Jersey City",
            "Jersey County",
            "Jersey Shore",
            "Jersey Village",
            "Jerseyville",
            "Jessamine County",
            "Jessup",
            "Jessup",
            "Jesup",
            "Jesup",
            "Jetmore",
            "Jewell",
            "Jewell County",
            "Jewett",
            "Jewett City",
            "Jim Hogg County",
            "Jim Thorpe",
            "Jim Wells County",
            "Jo Daviess County",
            "Joanna",
            "Jobos",
            "John Day",
            "Johns Creek",
            "Johnsburg",
            "Johnson",
            "Johnson",
            "Johnson",
            "Johnson City",
            "Johnson City",
            "Johnson City",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson County",
            "Johnson Creek",
            "Johnson Lane",
            "Johnsonburg",
            "Johnsonville",
            "Johnsonville",
            "Johnston",
            "Johnston",
            "Johnston",
            "Johnston City",
            "Johnston County",
            "Johnston County",
            "Johnstonville",
            "Johnstown",
            "Johnstown",
            "Johnstown",
            "Johnstown",
            "Joint Base Lewis McChord",
            "Joliet",
            "Jolivue",
            "Jollyville",
            "Jones",
            "Jones County",
            "Jones County",
            "Jones County",
            "Jones County",
            "Jones County",
            "Jones County",
            "Jones Creek",
            "Jonesboro",
            "Jonesboro",
            "Jonesboro",
            "Jonesboro",
            "Jonesboro",
            "Jonesborough",
            "Jonesport",
            "Jonestown",
            "Jonestown",
            "Jonestown",
            "Jonesville",
            "Jonesville",
            "Jonesville",
            "Jonesville",
            "Joplin",
            "Joppatowne",
            "Jordan",
            "Jordan",
            "Jordan",
            "Joseph",
            "Joseph City",
            "Josephine",
            "Josephine County",
            "Joshua",
            "Joshua Tree",
            "Jourdanton",
            "Juab County",
            "Juana Díaz",
            "Juana Díaz Municipio",
            "Judith Basin County",
            "Judson",
            "Judsonia",
            "Julesburg",
            "Julian",
            "Juncal",
            "Juncos",
            "Junction",
            "Junction",
            "Junction City",
            "Junction City",
            "Junction City",
            "June Park",
            "Juneau",
            "Juneau",
            "Juneau County",
            "Juniata County",
            "Juno Beach",
            "Jupiter",
            "Jurupa Valley",
            "Justice",
            "Justice",
            "Justin",
            "K. I. Sawyer Air Force Base",
            "Ka‘a‘awa",
            "Kā‘anapali",
            "Kaanapali Landing",
            "Kachina Village",
            "Kadoka",
            "Kahalu‘u",
            "Kahaluu-Keauhou",
            "Kahoka",
            "Kahuku",
            "Kahului",
            "Kaibito",
            "Kailua",
            "Kailua-Kona",
            "Kalāheo",
            "Kalama",
            "Kalamazoo",
            "Kalamazoo County",
            "Kalaoa",
            "Kalawao County",
            "Kalida",
            "Kalifornsky",
            "Kalispell",
            "Kalkaska",
            "Kalkaska County",
            "Kalona",
            "Kamas",
            "Kamiah",
            "Kanab",
            "Kanabec County",
            "Kanawha County",
            "Kandiyohi County",
            "Kane",
            "Kane County",
            "Kane County",
            "Kaneohe",
            "Kankakee",
            "Kankakee County",
            "Kannapolis",
            "Kansas City",
            "Kansas City",
            "Kapa‘a",
            "Kapaau",
            "Kaplan",
            "Kapolei",
            "Karnes City",
            "Karnes County",
            "Kaser",
            "Kasson",
            "Kathleen",
            "Katonah",
            "Katy",
            "Kauai County",
            "Kaufman",
            "Kaufman County",
            "Kaukauna",
            "Kaunakakai",
            "Kay County",
            "Kayenta",
            "Kaysville",
            "Kea‘au",
            "Kealakekua",
            "Keansburg",
            "Kearney",
            "Kearney",
            "Kearney County",
            "Kearney Park",
            "Kearns",
            "Kearny",
            "Kearny",
            "Kearny County",
            "Kechi",
            "Keedysville",
            "Keego Harbor",
            "Keeler Farm",
            "Keene",
            "Keene",
            "Keenesburg",
            "Keeseville",
            "Keewatin",
            "Keith County",
            "Keizer",
            "Kekaha",
            "Keller",
            "Kellogg",
            "Kellyville",
            "Kelseyville",
            "Kelso",
            "Kemah",
            "Kemmerer",
            "Kemp",
            "Kemp Mill",
            "Kemper County",
            "Kempner",
            "Ken Caryl",
            "Kenai",
            "Kenai Peninsula Borough",
            "Kenansville",
            "Kenbridge",
            "Kendale Lakes",
            "Kendall",
            "Kendall County",
            "Kendall County",
            "Kendall Green",
            "Kendall Park",
            "Kendall West",
            "Kendallville",
            "Kenduskeag",
            "Kenedy",
            "Kenedy County",
            "Kenhorst",
            "Kenilworth",
            "Kenilworth",
            "Kenilworth",
            "Kenly",
            "Kenmar",
            "Kenmare",
            "Kenmore",
            "Kenmore",
            "Kennebec",
            "Kennebec County",
            "Kennebunk",
            "Kennebunkport",
            "Kennedale",
            "Kennedy",
            "Kenner",
            "Kennesaw",
            "Kenneth City",
            "Kennett",
            "Kennett Square",
            "Kennewick",
            "Kenosha",
            "Kenosha County",
            "Kenova",
            "Kensett",
            "Kensington",
            "Kensington",
            "Kensington",
            "Kensington",
            "Kensington",
            "Kensington Park",
            "Kent",
            "Kent",
            "Kent",
            "Kent Acres",
            "Kent City",
            "Kent County",
            "Kent County",
            "Kent County",
            "Kent County",
            "Kent County",
            "Kentfield",
            "Kentland",
            "Kenton",
            "Kenton",
            "Kenton",
            "Kenton County",
            "Kentwood",
            "Kentwood",
            "Kenvil",
            "Kenwood",
            "Kenwood",
            "Kenwood",
            "Kenwood",
            "Kenyon",
            "Kēōkea",
            "Keokuk",
            "Keokuk County",
            "Keosauqua",
            "Kerens",
            "Kerhonkson",
            "Kerman",
            "Kermit",
            "Kern County",
            "Kernersville",
            "Kernville",
            "Kerr County",
            "Kerrville",
            "Kersey",
            "Kershaw",
            "Kershaw County",
            "Keshena",
            "Ketchikan",
            "Ketchikan Gateway Borough",
            "Ketchum",
            "Kettering",
            "Kettering",
            "Kettle Falls",
            "Kettleman City",
            "Keuka Park",
            "Kew Gardens",
            "Kew Gardens Hills",
            "Kewanee",
            "Kewaskum",
            "Kewaunee",
            "Kewaunee County",
            "Keweenaw County",
            "Key Biscayne",
            "Key Center",
            "Key Largo",
            "Key Vista",
            "Key West",
            "Keya Paha County",
            "Keyes",
            "Keyport",
            "Keyser",
            "Keystone",
            "Keystone",
            "Keystone Heights",
            "Keytesville",
            "Kiantone",
            "Kiawah Island",
            "Kidder County",
            "Kiefer",
            "Kiel",
            "Kīhei",
            "Kīlauea",
            "Kildeer",
            "Kilgore",
            "Kill Devil Hills",
            "Killdeer",
            "Killeen",
            "Killian",
            "Killingly Center",
            "Kilmanagh",
            "Kilmarnock",
            "Kiln",
            "Kimball",
            "Kimball",
            "Kimball County",
            "Kimberling City",
            "Kimberly",
            "Kimberly",
            "Kimberly",
            "Kimble County",
            "Kincaid",
            "Kinder",
            "Kinderhook",
            "King",
            "King",
            "King and Queen County",
            "King and Queen Court House",
            "King City",
            "King City",
            "King City",
            "King County",
            "King County",
            "King Cove",
            "King George",
            "King George County",
            "King of Prussia",
            "King William",
            "King William County",
            "Kingfield",
            "Kingfisher",
            "Kingfisher County",
            "Kingman",
            "Kingman",
            "Kingman County",
            "Kings Bay Base",
            "Kings Beach",
            "Kings Bridge",
            "Kings County",
            "Kings County",
            "Kings Grant",
            "Kings Mills",
            "Kings Mountain",
            "Kings Park",
            "Kings Park",
            "Kings Park West",
            "Kings Point",
            "Kings Point",
            "Kingsburg",
            "Kingsbury",
            "Kingsbury County",
            "Kingsford",
            "Kingsford Heights",
            "Kingsgate",
            "Kingsland",
            "Kingsland",
            "Kingsley",
            "Kingsley",
            "Kingsport",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston",
            "Kingston Estates",
            "Kingston Springs",
            "Kingstown",
            "Kingstree",
            "Kingsville",
            "Kingsville",
            "Kingwood",
            "Kingwood Area",
            "Kinnelon",
            "Kinney County",
            "Kinsey",
            "Kinsley",
            "Kinston",
            "Kiowa",
            "Kiowa",
            "Kiowa County",
            "Kiowa County",
            "Kiowa County",
            "Kirby",
            "Kirbyville",
            "Kirkland",
            "Kirkland",
            "Kirksville",
            "Kirkwood",
            "Kirtland",
            "Kirtland",
            "Kiryas Joel",
            "Kissee Mills",
            "Kissimmee",
            "Kit Carson County",
            "Kitsap County",
            "Kittanning",
            "Kittery",
            "Kittery Point",
            "Kittitas",
            "Kittitas County",
            "Kittredge",
            "Kittson County",
            "Kitty Hawk",
            "Klahanie",
            "Klamath County",
            "Klamath Falls",
            "Kleberg County",
            "Klickitat County",
            "Knightdale",
            "Knightsen",
            "Knightstown",
            "Knik-Fairview",
            "Knob Noster",
            "Knollwood",
            "Knott County",
            "Knottsville",
            "Knox",
            "Knox",
            "Knox City",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knox County",
            "Knoxville",
            "Knoxville",
            "Knoxville",
            "Knoxville",
            "Ko Olina",
            "Kodiak",
            "Kodiak Island Borough",
            "Kodiak Station",
            "Kohler",
            "Kokomo",
            "Koloa",
            "Konawa",
            "Koochiching County",
            "Koontz Lake",
            "Kootenai County",
            "Koreatown",
            "Kosciusko",
            "Kosciusko County",
            "Kossuth County",
            "Kotzebue",
            "Kountze",
            "Kouts",
            "Krebs",
            "Kremmling",
            "Kronenwetter",
            "Krotz Springs",
            "Krugerville",
            "Krum",
            "Kualapu‘u",
            "Kula",
            "Kulpmont",
            "Kulpsville",
            "Kuna",
            "Kure Beach",
            "Kurtistown",
            "Kutztown",
            "Kyle",
            "L'Anse",
            "La Alianza",
            "La Blanca",
            "La Cañada Flintridge",
            "La Center",
            "La Center",
            "La Cienega",
            "La Coste",
            "La Crescent",
            "La Crescenta-Montrose",
            "La Croft",
            "La Crosse",
            "La Crosse",
            "La Crosse County",
            "La Cygne",
            "La Dolores",
            "La Feria",
            "La Fermina",
            "La Grande",
            "La Grange",
            "La Grange",
            "La Grange",
            "La Grange",
            "La Grange Park",
            "La Grulla",
            "La Habra",
            "La Habra Heights",
            "La Harpe",
            "La Homa",
            "La Huerta",
            "La Jolla",
            "La Joya",
            "La Junta",
            "La Luisa",
            "La Luz",
            "La Marque",
            "La Mesa",
            "La Mesilla",
            "La Mirada",
            "La Monte",
            "La Palma",
            "La Paloma",
            "La Parguera",
            "La Paz County",
            "La Pine",
            "La Plata",
            "La Plata",
            "La Plata County",
            "La Playa",
            "La Plena",
            "La Porte",
            "La Porte",
            "La Porte City",
            "La Presa",
            "La Pryor",
            "La Puebla",
            "La Puente",
            "La Quinta",
            "La Riviera",
            "La Salle",
            "La Salle",
            "La Salle County",
            "La Salle Parish",
            "La Selva Beach",
            "La Union",
            "La Vale",
            "La Vergne",
            "La Verne",
            "La Vernia",
            "La Villa",
            "La Vista",
            "Lā‘ie",
            "Labadieville",
            "LaBarque Creek",
            "LaBelle",
            "Labette County",
            "Lac du Flambeau",
            "Lac qui Parle County",
            "Lacey",
            "Lackawanna",
            "Lackawanna County",
            "Lackland Air Force Base",
            "Laclede County",
            "Lacombe",
            "Lacon",
            "Laconia",
            "Lacoochee",
            "Lacy-Lakeview",
            "Ladd",
            "Ladera",
            "Ladera Heights",
            "Ladera Ranch",
            "Ladonia",
            "Ladson",
            "Ladue",
            "Lady Lake",
            "Ladysmith",
            "Lafayette",
            "Lafayette",
            "Lafayette",
            "Lafayette",
            "Lafayette",
            "Lafayette",
            "Lafayette",
            "LaFayette",
            "Lafayette County",
            "Lafayette County",
            "Lafayette County",
            "Lafayette County",
            "Lafayette County",
            "Lafayette Hill",
            "Lafayette Parish",
            "Laflin",
            "LaFollette",
            "Lafourche Parish",
            "Lago Vista",
            "Lagrange",
            "Lagrange",
            "LaGrange",
            "LaGrange County",
            "Laguna",
            "Laguna",
            "Laguna Beach",
            "Laguna Beach",
            "Laguna Heights",
            "Laguna Hills",
            "Laguna Niguel",
            "Laguna Park",
            "Laguna Vista",
            "Laguna Woods",
            "Lagunitas-Forest Knolls",
            "Lahaina",
            "Laingsburg",
            "Lajas",
            "Lajas Municipio",
            "Lake Alfred",
            "Lake and Peninsula Borough",
            "Lake Andes",
            "Lake Arbor",
            "Lake Arrowhead",
            "Lake Arrowhead",
            "Lake Arthur",
            "Lake Barcroft",
            "Lake Barrington",
            "Lake Belvedere Estates",
            "Lake Bluff",
            "Lake Brownwood",
            "Lake Butler",
            "Lake Camelot",
            "Lake Carmel",
            "Lake Catherine",
            "Lake Charles",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake City",
            "Lake Clarke Shores",
            "Lake Como",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake County",
            "Lake Crystal",
            "Lake Dalecarlia",
            "Lake Dallas",
            "Lake Darby",
            "Lake Delton",
            "Lake Dunlap",
            "Lake Elmo",
            "Lake Elsinore",
            "Lake Erie Beach",
            "Lake Fenton",
            "Lake Forest",
            "Lake Forest",
            "Lake Forest",
            "Lake Forest Park",
            "Lake Geneva",
            "Lake Grove",
            "Lake Hallie",
            "Lake Hamilton",
            "Lake Hamilton",
            "Lake Havasu City",
            "Lake Helen",
            "Lake Heritage",
            "Lake Holiday",
            "Lake in the Hills",
            "Lake Isabella",
            "Lake Isabella",
            "Lake Jackson",
            "Lake Junaluska",
            "Lake Katrine",
            "Lake Kiowa",
            "Lake Koshkonong",
            "Lake Lakengren",
            "Lake Latonka",
            "Lake Lorraine",
            "Lake Los Angeles",
            "Lake Lotawana",
            "Lake Lucerne",
            "Lake Lure",
            "Lake Luzerne",
            "Lake Mack-Forest Hills",
            "Lake Magdalene",
            "Lake Marcel-Stillwater",
            "Lake Mary",
            "Lake Meade",
            "Lake Michigan Beach",
            "Lake Mills",
            "Lake Mills",
            "Lake Mohawk",
            "Lake Mohawk",
            "Lake Mohegan",
            "Lake Montezuma",
            "Lake Monticello",
            "Lake Morton-Berrydale",
            "Lake Murray of Richland",
            "Lake Nacimiento",
            "Lake Nebagamon",
            "Lake Norman of Catawba",
            "Lake Odessa",
            "Lake of the Pines",
            "Lake of the Woods",
            "Lake of the Woods",
            "Lake of the Woods County",
            "Lake Orion",
            "Lake Oswego",
            "Lake Ozark",
            "Lake Panasoffkee",
            "Lake Panorama",
            "Lake Park",
            "Lake Park",
            "Lake Park",
            "Lake Placid",
            "Lake Placid",
            "Lake Pleasant",
            "Lake Pocotopaug",
            "Lake Providence",
            "Lake Purdy",
            "Lake Ridge",
            "Lake Ripley",
            "Lake Ronkonkoma",
            "Lake Saint Croix Beach",
            "Lake Saint Louis",
            "Lake San Marcos",
            "Lake Sarasota",
            "Lake Secession",
            "Lake Shore",
            "Lake Shore",
            "Lake Shore",
            "Lake Station",
            "Lake Stevens",
            "Lake Stickney",
            "Lake Success",
            "Lake Summerset",
            "Lake Tansi",
            "Lake Telemark",
            "Lake View",
            "Lake View",
            "Lake Villa",
            "Lake Village",
            "Lake Waccamaw",
            "Lake Wales",
            "Lake Wazeecha",
            "Lake Wildwood",
            "Lake Winnebago",
            "Lake Wisconsin",
            "Lake Wissota",
            "Lake Worth",
            "Lake Worth",
            "Lake Worth Corridor",
            "Lake Wylie",
            "Lake Wynonah",
            "Lake Zurich",
            "Lakefield",
            "Lakehills",
            "Lakehurst",
            "Lakeland",
            "Lakeland",
            "Lakeland",
            "Lakeland",
            "Lakeland",
            "Lakeland Highlands",
            "Lakeland North",
            "Lakeland South",
            "Lakeland Village",
            "Lakemont",
            "Lakemoor",
            "Lakemore",
            "Lakeport",
            "Lakes",
            "Lakes by the Bay",
            "Lakes of the Four Seasons",
            "Lakeshire",
            "Lakeshore",
            "Lakeside",
            "Lakeside",
            "Lakeside",
            "Lakeside",
            "Lakeside",
            "Lakeside",
            "Lakeside Park",
            "Lakesite",
            "Lakeview",
            "Lakeview",
            "Lakeview",
            "Lakeview",
            "Lakeview",
            "Lakeview",
            "Lakeview Estates",
            "Lakeville",
            "Lakeway",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood",
            "Lakewood Club",
            "Lakewood Park",
            "Lakewood Shores",
            "Lakin",
            "Lakota",
            "Lamar",
            "Lamar",
            "Lamar",
            "Lamar County",
            "Lamar County",
            "Lamar County",
            "Lamar County",
            "Lamartine",
            "Lamb County",
            "Lambert",
            "Lambertville",
            "Lambertville",
            "Lamboglia",
            "Lame Deer",
            "Lamesa",
            "Lamoille County",
            "Lamoni",
            "Lamont",
            "LaMoure County",
            "Lampasas",
            "Lampasas County",
            "Lampeter",
            "Lanai City",
            "Lanark",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster",
            "Lancaster County",
            "Lancaster County",
            "Lancaster County",
            "Lancaster County",
            "Lancaster Mill",
            "Land O' Lakes",
            "Landen",
            "Lander",
            "Lander County",
            "Landing",
            "Landis",
            "Landisville",
            "Landmark",
            "Landover",
            "Landover Hills",
            "Landrum",
            "Lane County",
            "Lane County",
            "Lanesborough",
            "Lanett",
            "Langdon",
            "Langhorne",
            "Langhorne Manor",
            "Langlade County",
            "Langley",
            "Langley",
            "Langley Park",
            "Langston",
            "Lanham",
            "Lanham-Seabrook",
            "Lanier County",
            "Lannon",
            "Lansdale",
            "Lansdowne",
            "Lansdowne",
            "Lansford",
            "Lansing",
            "Lansing",
            "Lansing",
            "Lansing",
            "Lantana",
            "Lantana",
            "Lapeer",
            "Lapeer County",
            "Lapel",
            "Laplace",
            "Laporte",
            "Laporte",
            "LaPorte",
            "LaPorte County",
            "Lapwai",
            "Laramie",
            "Laramie County",
            "Larch Way",
            "Larchmont",
            "Laredo",
            "Lares",
            "Largo",
            "Largo",
            "Larimer County",
            "Larimore",
            "Larkfield-Wikiup",
            "Larkspur",
            "Larksville",
            "Larned",
            "Larose",
            "Larue County",
            "Las Animas",
            "Las Animas County",
            "Las Cruces",
            "Las Flores",
            "Las Lomas",
            "Las Lomas",
            "Las Maravillas",
            "Las Marias",
            "Las Marías Municipio",
            "Las Ochenta",
            "Las Ollas",
            "Las Palmas II",
            "Las Piedras",
            "Las Piedras Municipio",
            "Las Quintas Fronterizas",
            "Las Quintas Fronterizas Colonia",
            "Las Vegas",
            "Las Vegas",
            "LaSalle County",
            "Lasara",
            "Lassen County",
            "Latah County",
            "Latham",
            "Lathrop",
            "Lathrop",
            "Lathrup Village",
            "Latimer",
            "Latimer County",
            "Laton",
            "Latrobe",
            "Latta",
            "Lattingtown",
            "Lauderdale",
            "Lauderdale County",
            "Lauderdale County",
            "Lauderdale County",
            "Lauderdale Lakes",
            "Lauderdale-by-the-Sea",
            "Lauderhill",
            "Laughlin",
            "Laughlin Air Force Base",
            "Laurel",
            "Laurel",
            "Laurel",
            "Laurel",
            "Laurel",
            "Laurel",
            "Laurel",
            "Laurel Bay",
            "Laurel County",
            "Laurel Hill",
            "Laurel Hill",
            "Laurel Hollow",
            "Laurel Lake",
            "Laurel Park",
            "Laurel Springs",
            "Laureldale",
            "Laureles",
            "Laurelton",
            "Laurence Harbor",
            "Laurens",
            "Laurens",
            "Laurens County",
            "Laurens County",
            "Laurinburg",
            "Laurium",
            "Laurys Station",
            "Lavaca",
            "Lavaca County",
            "Lavalette",
            "Lavallette",
            "Laveen",
            "LaVerkin",
            "Laverne",
            "Lavon",
            "Lavonia",
            "Lawai",
            "Lawndale",
            "Lawnside",
            "Lawnton",
            "Lawrence",
            "Lawrence",
            "Lawrence",
            "Lawrence",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence County",
            "Lawrence Park",
            "Lawrenceburg",
            "Lawrenceburg",
            "Lawrenceburg",
            "Lawrenceville",
            "Lawrenceville",
            "Lawrenceville",
            "Lawrenceville",
            "Lawson",
            "Lawson Heights",
            "Lawtell",
            "Lawton",
            "Lawton",
            "Layhill",
            "Laymantown",
            "Layton",
            "Laytonville",
            "Lazy Mountain",
            "Le Center",
            "Le Claire",
            "Le Flore County",
            "Le Grand",
            "Le Mars",
            "Le Roy",
            "Le Roy",
            "Le Sueur",
            "Le Sueur County",
            "Lea County",
            "Lea Hill",
            "Leachville",
            "Lead",
            "Leadville",
            "Leadville North",
            "Leadwood",
            "League City",
            "Leake County",
            "Leakesville",
            "Leakey",
            "Lealman",
            "Leander",
            "Leavenworth",
            "Leavenworth",
            "Leavenworth County",
            "Leavittsburg",
            "Leawood",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon",
            "Lebanon County",
            "Lebanon Junction",
            "Lebanon South",
            "Lebec",
            "Lecanto",
            "LeChee",
            "Lecompte",
            "Ledbetter",
            "Ledyard",
            "Lee",
            "Lee",
            "Lee Acres",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee County",
            "Lee's Summit",
            "Leechburg",
            "Leeds",
            "Leeds",
            "Leelanau County",
            "Leesburg",
            "Leesburg",
            "Leesburg",
            "Leesburg",
            "Leesburg",
            "Leesport",
            "Leesville",
            "Leesville",
            "Leetonia",
            "Leetsdale",
            "Leflore County",
            "Legend Lake",
            "Lehi",
            "Lehigh Acres",
            "Lehigh County",
            "Lehighton",
            "Leicester",
            "Leicester",
            "Leilani Estates",
            "Leipsic",
            "Leisure City",
            "Leisure Knoll",
            "Leisure Village",
            "Leisure Village East",
            "Leisure Village West-Pine Lake Park",
            "Leisure World",
            "Leisuretowne",
            "Leitchfield",
            "Leith-Hatfield",
            "Leland",
            "Leland",
            "Leland",
            "Leland Grove",
            "Lely",
            "Lely Resort",
            "Lemay",
            "Lemhi County",
            "Lemmon",
            "Lemmon Valley",
            "Lemon Grove",
            "Lemont",
            "Lemont",
            "Lemoore",
            "Lemoore Station",
            "Lemoyne",
            "Lempster",
            "Lena",
            "Lenape Heights",
            "Lenawee County",
            "Lenexa",
            "Lennox",
            "Lennox",
            "Lenoir",
            "Lenoir City",
            "Lenoir County",
            "Lenox",
            "Lenox",
            "Lents",
            "Lenwood",
            "Leo-Cedarville",
            "Leola",
            "Leola",
            "Leominster",
            "Leon",
            "Leon County",
            "Leon County",
            "Leon Valley",
            "Leona Valley",
            "Leonard",
            "Leonardo",
            "Leonardtown",
            "Leonia",
            "Leonville",
            "Leoti",
            "Lepanto",
            "Lesage",
            "Leslie",
            "Leslie County",
            "Lesslie",
            "Lester Prairie",
            "Letcher County",
            "Levant",
            "Level Green",
            "Level Park-Oak Park",
            "Level Plains",
            "Levelland",
            "Leverett",
            "Levittown",
            "Levittown",
            "Levittown",
            "Levy County",
            "Lewes",
            "Lewis and Clark County",
            "Lewis Center",
            "Lewis County",
            "Lewis County",
            "Lewis County",
            "Lewis County",
            "Lewis County",
            "Lewis County",
            "Lewis County",
            "Lewisburg",
            "Lewisburg",
            "Lewisburg",
            "Lewisburg",
            "Lewisport",
            "Lewiston",
            "Lewiston",
            "Lewiston",
            "Lewiston",
            "Lewiston",
            "Lewiston",
            "Lewiston",
            "Lewiston Orchards",
            "Lewistown",
            "Lewistown",
            "Lewistown",
            "Lewisville",
            "Lewisville",
            "Lewisville",
            "Lewisville",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington",
            "Lexington County",
            "Lexington Hills",
            "Lexington Park",
            "Lexington-Fayette",
            "Libby",
            "Liberal",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty",
            "Liberty Center",
            "Liberty City",
            "Liberty County",
            "Liberty County",
            "Liberty County",
            "Liberty County",
            "Liberty Hill",
            "Liberty Lake",
            "Libertyville",
            "Liborio Negron Torres",
            "Licking",
            "Licking County",
            "Lido Beach",
            "Light Street",
            "Lighthouse Point",
            "Ligonier",
            "Ligonier",
            "Lihue",
            "Lilbourn",
            "Lilburn",
            "Lillington",
            "Lily Lake",
            "Lima",
            "Lima",
            "Lima",
            "Limerick",
            "Limerick",
            "Limestone",
            "Limestone",
            "Limestone County",
            "Limestone County",
            "Limestone Creek",
            "Limington",
            "Limon",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln",
            "Lincoln Beach",
            "Lincoln City",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln County",
            "Lincoln Heights",
            "Lincoln Parish",
            "Lincoln Park",
            "Lincoln Park",
            "Lincoln Park",
            "Lincoln Park",
            "Lincoln Park",
            "Lincoln Park",
            "Lincoln Square",
            "Lincoln Village",
            "Lincoln Village",
            "Lincolndale",
            "Lincolnia",
            "Lincolnshire",
            "Lincolnton",
            "Lincolnton",
            "Lincolnville",
            "Lincolnville",
            "Lincolnwood",
            "Lincroft",
            "Linda",
            "Lindale",
            "Lindale",
            "Linden",
            "Linden",
            "Linden",
            "Linden",
            "Linden",
            "Linden",
            "Linden",
            "Lindenhurst",
            "Lindenhurst",
            "Lindenwold",
            "Lindley",
            "Lindon",
            "Lindsay",
            "Lindsay",
            "Lindsay",
            "Lindsborg",
            "Lindstrom",
            "Lineville",
            "Linganore",
            "Linglestown",
            "Linn",
            "Linn County",
            "Linn County",
            "Linn County",
            "Linn County",
            "Linneus",
            "Linntown",
            "Lino Lakes",
            "Linthicum",
            "Linton",
            "Linton",
            "Linton Hall",
            "Linwood",
            "Linwood",
            "Lionville",
            "Lipscomb",
            "Lipscomb County",
            "Lisbon",
            "Lisbon",
            "Lisbon",
            "Lisbon",
            "Lisbon",
            "Lisbon Falls",
            "Lisle",
            "Litchfield",
            "Litchfield",
            "Litchfield",
            "Litchfield",
            "Litchfield",
            "Litchfield County",
            "Litchfield Park",
            "Lithia Springs",
            "Lithonia",
            "Lithopolis",
            "Lititz",
            "Little Canada",
            "Little Chute",
            "Little Cottonwood Creek Valley",
            "Little Elm",
            "Little Falls",
            "Little Falls",
            "Little Falls",
            "Little Ferry",
            "Little Flock",
            "Little Neck",
            "Little River",
            "Little River County",
            "Little River-Academy",
            "Little Rock",
            "Little Rock",
            "Little Rock Air Force Base",
            "Little Round Lake",
            "Little Silver",
            "Little Valley",
            "Littlefield",
            "Littlerock",
            "Littlestown",
            "Littleton",
            "Littleton",
            "Littleton Common",
            "Live Oak",
            "Live Oak",
            "Live Oak",
            "Live Oak County",
            "Livermore",
            "Livermore",
            "Livermore",
            "Livermore Falls",
            "Liverpool",
            "Livingston",
            "Livingston",
            "Livingston",
            "Livingston",
            "Livingston",
            "Livingston",
            "Livingston",
            "Livingston County",
            "Livingston County",
            "Livingston County",
            "Livingston County",
            "Livingston County",
            "Livingston Manor",
            "Livingston Parish",
            "Livonia",
            "Livonia",
            "Livonia",
            "Llano",
            "Llano County",
            "Llano Grande",
            "Lloyd Harbor",
            "Lluveras",
            "Loa",
            "Loch Lomond",
            "Lochbuie",
            "Lochearn",
            "Lochmoor Waterway Estates",
            "Lochsloy",
            "Lock Haven",
            "Lockeford",
            "Lockhart",
            "Lockhart",
            "Lockland",
            "Lockney",
            "Lockport",
            "Lockport",
            "Lockport",
            "Lockport Heights",
            "Lockwood",
            "Locust",
            "Locust Fork",
            "Locust Grove",
            "Locust Grove",
            "Locust Valley",
            "Lodi",
            "Lodi",
            "Lodi",
            "Lodi",
            "Lofall",
            "Logan",
            "Logan",
            "Logan",
            "Logan",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan County",
            "Logan Elm Village",
            "Logan Square",
            "Logansport",
            "Logansport",
            "Loganville",
            "Loganville",
            "Loíza",
            "Lolo",
            "Loma",
            "Loma Linda",
            "Loma Rica",
            "Lomas",
            "Lombard",
            "Lomira",
            "Lomita",
            "Lompico",
            "Lompoc",
            "Lonaconing",
            "London",
            "London",
            "London",
            "London",
            "Londonderry",
            "Londonderry",
            "Londontowne",
            "Lone Grove",
            "Lone Jack",
            "Lone Oak",
            "Lone Pine",
            "Lone Star",
            "Lone Tree",
            "Lone Tree",
            "Long Beach",
            "Long Beach",
            "Long Beach",
            "Long Beach",
            "Long Beach",
            "Long Beach",
            "Long Beach",
            "Long Branch",
            "Long County",
            "Long Creek",
            "Long Grove",
            "Long Hill",
            "Long Island City",
            "Long Lake",
            "Long Lake",
            "Long Neck",
            "Long Prairie",
            "Long Valley",
            "Longboat Key",
            "Longbranch",
            "Longmeadow",
            "Longmont",
            "Longtown",
            "Longview",
            "Longview",
            "Longview",
            "Longview Heights",
            "Longwood",
            "Lonoke",
            "Lonoke County",
            "Lonsdale",
            "Loogootee",
            "Lookout Mountain",
            "Lookout Mountain",
            "Loomis",
            "Lopezville",
            "Lorain",
            "Lorain County",
            "Lorane",
            "Lordsburg",
            "Lordstown",
            "Lorena",
            "Lorenz Park",
            "Lorenzo",
            "Loretto",
            "Loretto",
            "Loris",
            "Lorton",
            "Los Alamitos",
            "Los Alamos",
            "Los Alamos",
            "Los Alamos County",
            "Los Altos",
            "Los Altos Hills",
            "Los Angeles",
            "Los Angeles County",
            "Los Banos",
            "Los Chavez",
            "Los Fresnos",
            "Los Gatos",
            "Los Indios",
            "Los Llanos",
            "Los Lunas",
            "Los Molinos",
            "Los Olivos",
            "Los Osos",
            "Los Ranchos de Albuquerque",
            "Los Serranos",
            "Lost Creek",
            "Lost Hills",
            "Loudon",
            "Loudon County",
            "Loudonville",
            "Loudoun County",
            "Loudoun Valley Estates",
            "Loughman",
            "Louisa",
            "Louisa",
            "Louisa County",
            "Louisa County",
            "Louisburg",
            "Louisburg",
            "Louisiana",
            "Louisville",
            "Louisville",
            "Louisville",
            "Louisville",
            "Louisville",
            "Louisville",
            "Louisville",
            "Louisville",
            "Loup City",
            "Loup County",
            "Love County",
            "Lovejoy",
            "Loveland",
            "Loveland",
            "Loveland Park",
            "Lovell",
            "Lovell",
            "Lovelock",
            "Loves Park",
            "Lovettsville",
            "Loving",
            "Loving County",
            "Lovingston",
            "Lovington",
            "Lovington",
            "Lowell",
            "Lowell",
            "Lowell",
            "Lowell",
            "Lowell",
            "Lowell",
            "Lowellville",
            "Lower Allen",
            "Lower Burrell",
            "Lower Grand Lagoon",
            "Lower Lake",
            "Lower West Side",
            "Lowes Island",
            "Lowesville",
            "Lowndes County",
            "Lowndes County",
            "Lowndes County",
            "Lowry Crossing",
            "Lowville",
            "Loxahatchee Groves",
            "Loxley",
            "Loyal",
            "Loyalhanna",
            "Loyola",
            "Lubbock",
            "Lubbock County",
            "Lubeck",
            "Lucama",
            "Lucas",
            "Lucas County",
            "Lucas County",
            "Lucas Valley-Marinwood",
            "Lucasville",
            "Luce County",
            "Lucedale",
            "Lucerne",
            "Lucerne Valley",
            "Luck",
            "Luckey",
            "Ludington",
            "Ludlow",
            "Ludlow",
            "Ludowici",
            "Lufkin",
            "Lugoff",
            "Luis Lloréns Torres",
            "Luis M. Cintron",
            "Lukachukai",
            "Lula",
            "Luling",
            "Luling",
            "Lumber City",
            "Lumberton",
            "Lumberton",
            "Lumberton",
            "Lumpkin",
            "Lumpkin County",
            "Luna County",
            "Luna Pier",
            "Lunenburg",
            "Lunenburg",
            "Lunenburg",
            "Lunenburg County",
            "Luquillo",
            "Luquillo Municipio",
            "Luray",
            "Lusby",
            "Lusk",
            "Lutcher",
            "Luther",
            "Lutherville",
            "Lutherville-Timonium",
            "Luttrell",
            "Lutz",
            "Luverne",
            "Luverne",
            "Luxemburg",
            "Luxora",
            "Luyando",
            "Luzerne",
            "Luzerne County",
            "Lycoming County",
            "Lyford",
            "Lykens",
            "Lyman",
            "Lyman",
            "Lyman",
            "Lyman County",
            "Lyme",
            "Lynbrook",
            "Lynchburg",
            "Lynchburg",
            "Lynchburg",
            "Lynchburg",
            "Lyncourt",
            "Lyndeborough",
            "Lynden",
            "Lyndhurst",
            "Lyndhurst",
            "Lyndhurst",
            "Lyndon",
            "Lyndon",
            "Lyndon",
            "Lyndonville",
            "Lynn",
            "Lynn",
            "Lynn County",
            "Lynn Haven",
            "Lynnfield",
            "Lynnwood",
            "Lynnwood-Pricedale",
            "Lynwood",
            "Lynwood",
            "Lyon County",
            "Lyon County",
            "Lyon County",
            "Lyon County",
            "Lyon County",
            "Lyons",
            "Lyons",
            "Lyons",
            "Lyons",
            "Lyons",
            "Lyons",
            "Lytle",
            "Mā‘ili",
            "Mabank",
            "Mableton",
            "Mabscott",
            "Mabton",
            "MacArthur",
            "Macclenny",
            "Macedon",
            "Macedonia",
            "Machesney Park",
            "Machias",
            "Machias",
            "Machiasport",
            "Mack",
            "Mackinac County",
            "Mackinaw",
            "Macomb",
            "Macomb County",
            "Macon",
            "Macon",
            "Macon",
            "Macon",
            "Macon County",
            "Macon County",
            "Macon County",
            "Macon County",
            "Macon County",
            "Macon County",
            "Macoupin County",
            "Macungie",
            "Macy",
            "Madawaska",
            "Madbury",
            "Madeira",
            "Madeira Beach",
            "Madelia",
            "Madera",
            "Madera Acres",
            "Madera County",
            "Madill",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison",
            "Madison Center",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison County",
            "Madison Heights",
            "Madison Heights",
            "Madison Lake",
            "Madison Parish",
            "Madison Park",
            "Madisonville",
            "Madisonville",
            "Madisonville",
            "Madras",
            "Madrid",
            "Maeser",
            "Magalia",
            "Magas Arriba",
            "Magee",
            "Maggie Valley",
            "Magna",
            "Magnolia",
            "Magnolia",
            "Magnolia",
            "Magnolia",
            "Magoffin County",
            "Mahanoy City",
            "Maharishi Vedic City",
            "Mahaska County",
            "Mahnomen",
            "Mahnomen County",
            "Mahomet",
            "Mahoning County",
            "Mahopac",
            "Mahtomedi",
            "Mahwah",
            "Maiden",
            "Maine",
            "Maitland",
            "Maize",
            "Major County",
            "Mākaha",
            "Mākaha Valley",
            "Makakilo City",
            "Makawao",
            "Malabar",
            "Malad City",
            "Malakoff",
            "Malden",
            "Malden",
            "Malheur County",
            "Malibu",
            "Mallory",
            "Malmstrom Air Force Base",
            "Malone",
            "Malone",
            "Malta",
            "Malta",
            "Maltby",
            "Malvern",
            "Malvern",
            "Malvern",
            "Malvern",
            "Malvern",
            "Malverne",
            "Mamaroneck",
            "Mammoth",
            "Mammoth Lakes",
            "Mamou",
            "Manahawkin",
            "Manasota Key",
            "Manasquan",
            "Manassas",
            "Manassas Park",
            "Manatee County",
            "Manatee Road",
            "Manatí",
            "Manawa",
            "Mancelona",
            "Manchaca",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester",
            "Manchester Center",
            "Manchester-by-the-Sea",
            "Mancos",
            "Mandan",
            "Mandeville",
            "Mango",
            "Mangonia Park",
            "Mangum",
            "Manhasset",
            "Manhasset Hills",
            "Manhattan",
            "Manhattan",
            "Manhattan",
            "Manhattan",
            "Manhattan Beach",
            "Manheim",
            "Manila",
            "Manila",
            "Manistee",
            "Manistee County",
            "Manistique",
            "Manito",
            "Manitou Beach-Devils Lake",
            "Manitou Springs",
            "Manitowoc",
            "Manitowoc County",
            "Mankato",
            "Mankato",
            "Manlius",
            "Manly",
            "Mannford",
            "Manning",
            "Manning",
            "Manning",
            "Mannington",
            "Manor",
            "Manor",
            "Manorhaven",
            "Manorville",
            "Mansfield",
            "Mansfield",
            "Mansfield",
            "Mansfield",
            "Mansfield",
            "Mansfield",
            "Mansfield",
            "Mansfield Center",
            "Mansfield City",
            "Manson",
            "Manson",
            "Mansura",
            "Mantachie",
            "Manteca",
            "Manteno",
            "Manteo",
            "Manti",
            "Manton",
            "Mantorville",
            "Mantua",
            "Mantua",
            "Mantua Township",
            "Manvel",
            "Manville",
            "Many",
            "Many Farms",
            "Maple Bluff",
            "Maple Glen",
            "Maple Grove",
            "Maple Heights",
            "Maple Heights-Lake Desire",
            "Maple Lake",
            "Maple Park",
            "Maple Plain",
            "Maple Shade",
            "Maple Valley",
            "Mapleton",
            "Mapleton",
            "Mapleton",
            "Maplewood",
            "Maplewood",
            "Maplewood",
            "Maplewood",
            "Maquoketa",
            "Mar-Mac",
            "Marana",
            "Marathon",
            "Marathon",
            "Marathon County",
            "Marble Falls",
            "Marble Hill",
            "Marblehead",
            "Marbleton",
            "Marbletown",
            "Marbury",
            "Marceline",
            "Marcellus",
            "Marcellus",
            "March Air Force Base",
            "Marco",
            "Marco Island",
            "Marcus",
            "Marcus Hook",
            "Marengo",
            "Marengo",
            "Marengo County",
            "Marfa",
            "Margaret",
            "Margate",
            "Margate City",
            "Maria Antonia",
            "Marianna",
            "Marianna",
            "Marianne",
            "Mariano Colón",
            "Maricao",
            "Maricao Municipio",
            "Maricopa",
            "Maricopa",
            "Maricopa County",
            "Mariemont",
            "Marienville",
            "Maries County",
            "Marietta",
            "Marietta",
            "Marietta",
            "Marietta",
            "Marietta",
            "Marietta-Alderwood",
            "Marin City",
            "Marin County",
            "Marina",
            "Marina del Rey",
            "Marine City",
            "Marine Corps Base Hawaii - MCBH",
            "Mariners Harbor",
            "Marinette",
            "Marinette County",
            "Maringouin",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion",
            "Marion Center",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marion County",
            "Marionville",
            "Mariposa",
            "Mariposa County",
            "Marissa",
            "Marked Tree",
            "Markesan",
            "Markham",
            "Markham",
            "Markle",
            "Marks",
            "Marksville",
            "Marlboro",
            "Marlboro",
            "Marlboro County",
            "Marlboro Meadows",
            "Marlboro Village",
            "Marlborough",
            "Marlborough",
            "Marlborough",
            "Marlette",
            "Marlin",
            "Marlinton",
            "Marlow",
            "Marlow Heights",
            "Marlton",
            "Marlton",
            "Marmaduke",
            "Marmet",
            "Maroa",
            "Marquette",
            "Marquette County",
            "Marquette County",
            "Marquette Heights",
            "Marrero",
            "Marriott-Slaterville",
            "Mars",
            "Mars Hill",
            "Marseilles",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshall County",
            "Marshallton",
            "Marshalltown",
            "Marshallville",
            "Marshfield",
            "Marshfield",
            "Marshfield",
            "Marshfield Hills",
            "Marshville",
            "Marsing",
            "Marstons Mills",
            "Mart",
            "Martha Lake",
            "Marthasville",
            "Martin",
            "Martin",
            "Martin County",
            "Martin County",
            "Martin County",
            "Martin County",
            "Martin County",
            "Martin County",
            "Martindale",
            "Martinez",
            "Martinez",
            "Martins Ferry",
            "Martinsburg",
            "Martinsburg",
            "Martinsville",
            "Martinsville",
            "Martinsville",
            "Martinsville",
            "Martorell",
            "Marueño",
            "Marvell",
            "Marvin",
            "Mary Esther",
            "Maryland City",
            "Maryland Heights",
            "Marysville",
            "Marysville",
            "Marysville",
            "Marysville",
            "Marysville",
            "Marysville",
            "Maryvale",
            "Maryville",
            "Maryville",
            "Maryville",
            "Masaryktown",
            "Mascot",
            "Mascotte",
            "Mascoutah",
            "Mashpee",
            "Mason",
            "Mason",
            "Mason",
            "Mason",
            "Mason",
            "Mason City",
            "Mason City",
            "Mason County",
            "Mason County",
            "Mason County",
            "Mason County",
            "Mason County",
            "Mason County",
            "Masonboro",
            "Masontown",
            "Masonville",
            "Maspeth",
            "Massac",
            "Massac County",
            "Massanetta Springs",
            "Massanutten",
            "Massapequa",
            "Massapequa Park",
            "Massena",
            "Massillon",
            "Mastic",
            "Mastic Beach",
            "Masury",
            "Matador",
            "Matagorda County",
            "Matamoras",
            "Matanuska-Susitna Borough",
            "Matawan",
            "Matheny",
            "Mathews",
            "Mathews",
            "Mathews County",
            "Mathis",
            "Matoaca",
            "Mattapoisett",
            "Mattapoisett Center",
            "Mattawa",
            "Mattawan",
            "Matteson",
            "Matthews",
            "Mattituck",
            "Mattoon",
            "Mattydale",
            "Maud",
            "Maud",
            "Maugansville",
            "Maui County",
            "Mauldin",
            "Maumee",
            "Maumelle",
            "Maunabo",
            "Maunabo Municipio",
            "Maunawili",
            "Maurice",
            "Mauriceville",
            "Maury",
            "Maury County",
            "Mauston",
            "Maverick County",
            "Maxton",
            "Maxwell",
            "Mayagüez",
            "Maybrook",
            "Mayer",
            "Mayer",
            "Mayersville",
            "Mayes County",
            "Mayfield",
            "Mayfield",
            "Mayfield",
            "Mayfield Heights",
            "Mayflower",
            "Mayflower Village",
            "Maynard",
            "Maynardville",
            "Mayo",
            "Mayo",
            "Mayo",
            "Mayodan",
            "Mays Chapel",
            "Mays Landing",
            "Maysville",
            "Maysville",
            "Maysville",
            "Maysville",
            "Maysville",
            "Maytown",
            "Mayville",
            "Mayville",
            "Mayville",
            "Maywood",
            "Maywood",
            "Maywood",
            "Mazomanie",
            "McAdoo",
            "McAlester",
            "McAllen",
            "McAlmont",
            "McArthur",
            "McCall",
            "McCamey",
            "McCaysville",
            "McChord Air Force Base",
            "McClain County",
            "McCleary",
            "McCloud",
            "McClusky",
            "McColl",
            "McComb",
            "McComb",
            "McCone County",
            "McConnell AFB",
            "McConnellsburg",
            "McConnellstown",
            "McConnelsville",
            "McCook",
            "McCook County",
            "McCord",
            "McCordsville",
            "McCormick",
            "McCormick County",
            "McCracken County",
            "McCreary County",
            "McCrory",
            "McCulloch County",
            "McCullom Lake",
            "McCurtain County",
            "McDonald",
            "McDonald",
            "McDonald County",
            "McDonough",
            "McDonough County",
            "McDowell County",
            "McDowell County",
            "McDuffie County",
            "McEwen",
            "McFarland",
            "McFarland",
            "McGehee",
            "McGill",
            "McGovern",
            "McGraw",
            "McGregor",
            "McGregor",
            "McGuire AFB",
            "McHenry",
            "McHenry County",
            "McHenry County",
            "McIntosh",
            "McIntosh",
            "McIntosh County",
            "McIntosh County",
            "McIntosh County",
            "McKean County",
            "McKee",
            "McKees Rocks",
            "McKeesport",
            "McKenzie",
            "McKenzie County",
            "McKinley County",
            "McKinley Heights",
            "McKinley Park",
            "McKinleyville",
            "McKinney",
            "McKownville",
            "McLean",
            "McLean County",
            "McLean County",
            "McLean County",
            "McLeansboro",
            "McLeansville",
            "McLendon-Chisholm",
            "McLennan County",
            "McLeod County",
            "McLoud",
            "McMechen",
            "McMillin",
            "McMinn County",
            "McMinnville",
            "McMinnville",
            "McMullen County",
            "McMurray",
            "McNairy County",
            "McPherson",
            "McPherson County",
            "McPherson County",
            "McPherson County",
            "McQueeney",
            "McRae",
            "McSherrystown",
            "Mead",
            "Mead",
            "Mead Valley",
            "Meade",
            "Meade County",
            "Meade County",
            "Meade County",
            "Meadow Glade",
            "Meadow Lake",
            "Meadow Lakes",
            "Meadow Oaks",
            "Meadow Vista",
            "Meadow Woods",
            "Meadowbrook",
            "Meadowbrook",
            "Meadowbrook",
            "Meadowdale",
            "Meadowlakes",
            "Meadowood",
            "Meadows Place",
            "Meads",
            "Meadview",
            "Meadville",
            "Meadville",
            "Meagher County",
            "Mebane",
            "Mecca",
            "Mechanic Falls",
            "Mechanicsburg",
            "Mechanicsburg",
            "Mechanicstown",
            "Mechanicsville",
            "Mechanicsville",
            "Mechanicsville",
            "Mechanicsville",
            "Mechanicville",
            "Mecklenburg County",
            "Mecklenburg County",
            "Mecosta County",
            "Medfield",
            "Medford",
            "Medford",
            "Medford",
            "Medford",
            "Medford",
            "Medford",
            "Medford Lakes",
            "Media",
            "Mediapolis",
            "Medical Lake",
            "Medicine Lodge",
            "Medina",
            "Medina",
            "Medina",
            "Medina",
            "Medina",
            "Medina",
            "Medina County",
            "Medina County",
            "Medora",
            "Medulla",
            "Medway",
            "Medway",
            "Meeker",
            "Meeker",
            "Meeker County",
            "Meggett",
            "Mehlville",
            "Meigs",
            "Meigs County",
            "Meigs County",
            "Meiners Oaks",
            "Melbourne",
            "Melbourne",
            "Melbourne Beach",
            "Melcher-Dallas",
            "Melissa",
            "Mellette County",
            "Mellwood",
            "Melody Hill",
            "Melrose",
            "Melrose",
            "Melrose",
            "Melrose Park",
            "Melrose Park",
            "Melrose Park",
            "Melville",
            "Melville",
            "Melville",
            "Melvindale",
            "Memphis",
            "Memphis",
            "Memphis",
            "Memphis",
            "Memphis",
            "Mena",
            "Menahga",
            "Menands",
            "Menard",
            "Menard County",
            "Menard County",
            "Menasha",
            "Mendenhall",
            "Mendham",
            "Mendocino County",
            "Mendon",
            "Mendon",
            "Mendon",
            "Mendota",
            "Mendota",
            "Mendota Heights",
            "Menifee",
            "Menifee County",
            "Menlo Park",
            "Menominee",
            "Menominee County",
            "Menominee County",
            "Menomonee Falls",
            "Menomonie",
            "Mentone",
            "Mentone",
            "Mentor",
            "Mentor-on-the-Lake",
            "Mequon",
            "Meraux",
            "Merced",
            "Merced County",
            "Mercedes",
            "Mercer",
            "Mercer County",
            "Mercer County",
            "Mercer County",
            "Mercer County",
            "Mercer County",
            "Mercer County",
            "Mercer County",
            "Mercer County",
            "Mercer Island",
            "Mercersburg",
            "Mercerville",
            "Mercerville-Hamilton Square",
            "Merchantville",
            "Meredith",
            "Meredosia",
            "Meriden",
            "Meridian",
            "Meridian",
            "Meridian",
            "Meridian",
            "Meridian",
            "Meridian",
            "Meridian Hills",
            "Meridian Station",
            "Meridianville",
            "Meriwether County",
            "Merkel",
            "Merlin",
            "Merriam",
            "Merriam Woods",
            "Merrick",
            "Merrick County",
            "Merrifield",
            "Merrill",
            "Merrillville",
            "Merrimac",
            "Merrimac",
            "Merrimack",
            "Merrimack County",
            "Merrionette Park",
            "Merritt Island",
            "Merritt Park",
            "Merrydale",
            "Merryville",
            "Merton",
            "Mertzon",
            "Mesa",
            "Mesa County",
            "Mesa Verde",
            "Mescal",
            "Mescalero",
            "Mesilla",
            "Mesquite",
            "Mesquite",
            "Mesquite",
            "Metairie",
            "Metairie Terrace",
            "Metamora",
            "Metcalfe",
            "Metcalfe County",
            "Methuen",
            "Metlakatla",
            "Metropolis",
            "Metter",
            "Metuchen",
            "Metzger",
            "Mexia",
            "Mexico",
            "Mexico",
            "Mexico",
            "Mexico Beach",
            "Meyersdale",
            "Miami",
            "Miami",
            "Miami",
            "Miami",
            "Miami Beach",
            "Miami County",
            "Miami County",
            "Miami County",
            "Miami Gardens",
            "Miami Heights",
            "Miami Lakes",
            "Miami Shores",
            "Miami Springs",
            "Miami-Dade County",
            "Miamisburg",
            "Miamitown",
            "Micco",
            "Michigan Center",
            "Michigan City",
            "Middle Island",
            "Middle River",
            "Middle Valley",
            "Middle Village",
            "Middleborough",
            "Middleborough Center",
            "Middlebourne",
            "Middleburg",
            "Middleburg",
            "Middleburg Heights",
            "Middleburgh",
            "Middlebury",
            "Middlebury",
            "Middlebury (village)",
            "Middlebush",
            "Middlefield",
            "Middleport",
            "Middleport",
            "Middlesboro",
            "Middlesex",
            "Middlesex County",
            "Middlesex County",
            "Middlesex County",
            "Middlesex County",
            "Middleton",
            "Middleton",
            "Middleton",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middletown",
            "Middleville",
            "Midfield",
            "Midland",
            "Midland",
            "Midland",
            "Midland",
            "Midland",
            "Midland Beach",
            "Midland City",
            "Midland County",
            "Midland County",
            "Midland Park",
            "Midlothian",
            "Midlothian",
            "Midpines",
            "Midtown",
            "Midvale",
            "Midway",
            "Midway",
            "Midway",
            "Midway",
            "Midway",
            "Midway",
            "Midway",
            "Midway",
            "Midway",
            "Midway City",
            "Midway North",
            "Midway South",
            "Midwest City",
            "Mifflin County",
            "Mifflinburg",
            "Mifflintown",
            "Mifflinville",
            "Mignon",
            "Mikoma",
            "Mila Doce",
            "Milaca",
            "Milam",
            "Milam County",
            "Milan",
            "Milan",
            "Milan",
            "Milan",
            "Milan",
            "Milan",
            "Milan",
            "Milan",
            "Milbank",
            "Milbridge",
            "Miles City",
            "Milesburg",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford",
            "Milford Mill",
            "Mililani Town",
            "Mill City",
            "Mill Creek",
            "Mill Creek East",
            "Mill Hall",
            "Mill Neck",
            "Mill Plain",
            "Mill Valley",
            "Millard County",
            "Millbourne",
            "Millbrae",
            "Millbrook",
            "Millbrook",
            "Millbury",
            "Millbury",
            "Millcreek",
            "Mille Lacs County",
            "Milledgeville",
            "Millen",
            "Miller",
            "Miller County",
            "Miller County",
            "Miller County",
            "Miller Place",
            "Millers Creek",
            "Millers Falls",
            "Millersburg",
            "Millersburg",
            "Millersburg",
            "Millersport",
            "Millersville",
            "Millersville",
            "Milliken",
            "Millington",
            "Millington",
            "Millinocket",
            "Millis",
            "Millis-Clicquot",
            "Mills",
            "Mills County",
            "Mills County",
            "Mills River",
            "Millsboro",
            "Millstadt",
            "Milltown",
            "Millvale",
            "Millville",
            "Millville",
            "Millville",
            "Millwood",
            "Milo",
            "Milpitas",
            "Milroy",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton",
            "Milton-Freewater",
            "Milwaukee",
            "Milwaukee County",
            "Milwaukie",
            "Mims",
            "Minco",
            "Minden",
            "Minden",
            "Minden",
            "Mineola",
            "Mineola",
            "Miner County",
            "Mineral County",
            "Mineral County",
            "Mineral County",
            "Mineral County",
            "Mineral Point",
            "Mineral Ridge",
            "Mineral Springs",
            "Mineral Springs",
            "Mineral Wells",
            "Mineral Wells",
            "Minersville",
            "Minerva",
            "Minerva Park",
            "Minetto",
            "Mineville",
            "Mingo County",
            "Mingo Junction",
            "Minidoka County",
            "Minier",
            "Minkler",
            "Minneapolis",
            "Minneapolis",
            "Minnehaha",
            "Minnehaha County",
            "Minneola",
            "Minneota",
            "Minnetonka",
            "Minnetonka Mills",
            "Minnetrista",
            "Minnewaukan",
            "Minoa",
            "Minonk",
            "Minooka",
            "Minor",
            "Minorca",
            "Minot",
            "Minot",
            "Minot Air Force Base",
            "Minster",
            "Mint Hill",
            "Minturn",
            "Mio",
            "Mira Mesa",
            "Mira Monte",
            "Miramar",
            "Miramar Beach",
            "Miranda",
            "Mirrormont",
            "Mishawaka",
            "Mishicot",
            "Missaukee County",
            "Mission",
            "Mission",
            "Mission",
            "Mission",
            "Mission Bend",
            "Mission Canyon",
            "Mission District",
            "Mission Hills",
            "Mission Hills",
            "Mission Viejo",
            "Mississippi County",
            "Mississippi County",
            "Missoula",
            "Missoula County",
            "Missouri City",
            "Missouri Valley",
            "Mitchell",
            "Mitchell",
            "Mitchell",
            "Mitchell",
            "Mitchell County",
            "Mitchell County",
            "Mitchell County",
            "Mitchell County",
            "Mitchell County",
            "Mitchellville",
            "Mitchellville",
            "Moab",
            "Moapa Town",
            "Moapa Valley",
            "Moberly",
            "Mobile",
            "Mobile County",
            "Mobridge",
            "Moca",
            "Moca Municipio",
            "Mocksville",
            "Modesto",
            "Modoc County",
            "Moffat County",
            "Mogadore",
            "Mogul",
            "Mohall",
            "Mohave County",
            "Mohave Valley",
            "Mohawk",
            "Mohnton",
            "Mojave",
            "Mokena",
            "Mokulēia",
            "Molalla",
            "Moline",
            "Moline Acres",
            "Molino",
            "Momence",
            "Mona",
            "Monaca",
            "Monahans",
            "Monarch Mill",
            "Moncks Corner",
            "Mondovi",
            "Monee",
            "Monessen",
            "Monett",
            "Monette",
            "Monfort Heights",
            "Moniteau County",
            "Monmouth",
            "Monmouth",
            "Monmouth",
            "Monmouth Beach",
            "Monmouth County",
            "Monmouth Junction",
            "Mono County",
            "Mono Vista",
            "Monon",
            "Monona",
            "Monona",
            "Monona County",
            "Monongah",
            "Monongahela",
            "Monongalia County",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe",
            "Monroe City",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe County",
            "Monroe North",
            "Monroeville",
            "Monroeville",
            "Monroeville",
            "Monroeville",
            "Monrovia",
            "Monrovia",
            "Monserrate",
            "Monsey",
            "Monson",
            "Monson Center",
            "Mont Alto",
            "Mont Belvieu",
            "Mont Vernon",
            "Montague",
            "Montague",
            "Montague",
            "Montague",
            "Montague County",
            "Montalvin",
            "Montana City",
            "Montara",
            "Montauk",
            "Montcalm County",
            "Montclair",
            "Montclair",
            "Montclair",
            "Monte Alto",
            "Monte Grande",
            "Monte Rio",
            "Monte Sereno",
            "Monte Vista",
            "Monteagle",
            "Montebello",
            "Montebello",
            "Montecito",
            "Montegut",
            "Montello",
            "Monterey",
            "Monterey",
            "Monterey",
            "Monterey County",
            "Monterey Park",
            "Monterey Park",
            "Montesano",
            "Montevallo",
            "Montevideo",
            "Montezuma",
            "Montezuma",
            "Montezuma County",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery",
            "Montgomery City",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery County",
            "Montgomery Village",
            "Montgomeryville",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Monticello",
            "Montmorency County",
            "Montour County",
            "Montour Falls",
            "Montoursville",
            "Montpelier",
            "Montpelier",
            "Montpelier",
            "Montpelier",
            "Montrose",
            "Montrose",
            "Montrose",
            "Montrose",
            "Montrose",
            "Montrose",
            "Montrose County",
            "Montrose-Ghent",
            "Montross",
            "Montvale",
            "Montverde",
            "Montville Center",
            "Montz",
            "Monument",
            "Monument Beach",
            "Monument Hills",
            "Moodus",
            "Moody",
            "Moody",
            "Moody County",
            "Moonachie",
            "Moorcroft",
            "Moore",
            "Moore County",
            "Moore County",
            "Moore County",
            "Moore Haven",
            "Moorefield",
            "Mooreland",
            "Moores Mill",
            "Moorestown-Lenola",
            "Mooresville",
            "Mooresville",
            "Moorhead",
            "Moorhead",
            "Moorpark",
            "Moose Lake",
            "Moose Wilson Road",
            "Moosic",
            "Moosup",
            "Mora",
            "Mora",
            "Mora",
            "Mora County",
            "Morada",
            "Moraga",
            "Moraine",
            "Moravia",
            "Moravian Falls",
            "Morehead",
            "Morehead City",
            "Morehouse Parish",
            "Moreland",
            "Moreland Hills",
            "Morenci",
            "Morenci",
            "Moreno Valley",
            "Moretown",
            "Morgan",
            "Morgan",
            "Morgan City",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan County",
            "Morgan Hill",
            "Morgan Park",
            "Morgandale",
            "Morganfield",
            "Morgans Point Resort",
            "Morganton",
            "Morgantown",
            "Morgantown",
            "Morgantown",
            "Morganville",
            "Moriarty",
            "Moriches",
            "Morningside",
            "Morningside",
            "Morningside Heights",
            "Moro",
            "Morocco",
            "Morongo Valley",
            "Moroni",
            "Morovis",
            "Morrill County",
            "Morrilton",
            "Morris",
            "Morris",
            "Morris",
            "Morris",
            "Morris County",
            "Morris County",
            "Morris County",
            "Morris Heights",
            "Morris Park",
            "Morris Plains",
            "Morrisania",
            "Morrison",
            "Morrison County",
            "Morrisonville",
            "Morrisonville",
            "Morristown",
            "Morristown",
            "Morristown",
            "Morristown",
            "Morrisville",
            "Morrisville",
            "Morrisville",
            "Morrisville",
            "Morro Bay",
            "Morrow",
            "Morrow",
            "Morrow County",
            "Morrow County",
            "Morton",
            "Morton",
            "Morton",
            "Morton",
            "Morton",
            "Morton County",
            "Morton County",
            "Morton Grove",
            "Moscow",
            "Moscow",
            "Moscow Mills",
            "Moses Lake",
            "Moses Lake North",
            "Mosheim",
            "Mosinee",
            "Mosquero",
            "Moss Beach",
            "Moss Bluff",
            "Moss Point",
            "Motley",
            "Motley County",
            "Mott",
            "Mott Haven",
            "Moulton",
            "Moultonborough",
            "Moultrie",
            "Moultrie County",
            "Mound",
            "Mound Bayou",
            "Mound City",
            "Mound City",
            "Mound City",
            "Mound City",
            "Moundridge",
            "Mounds",
            "Mounds View",
            "Moundsville",
            "Moundville",
            "Mount Airy",
            "Mount Airy",
            "Mount Airy",
            "Mount Angel",
            "Mount Arlington",
            "Mount Ayr",
            "Mount Carmel",
            "Mount Carmel",
            "Mount Carmel",
            "Mount Carmel",
            "Mount Carroll",
            "Mount Clemens",
            "Mount Cobb",
            "Mount Dora",
            "Mount Ephraim",
            "Mount Gay-Shamrock",
            "Mount Gilead",
            "Mount Gilead",
            "Mount Greenwood",
            "Mount Healthy",
            "Mount Healthy Heights",
            "Mount Hermon",
            "Mount Hermon",
            "Mount Holly",
            "Mount Holly",
            "Mount Holly",
            "Mount Holly Springs",
            "Mount Hood Village",
            "Mount Hope",
            "Mount Horeb",
            "Mount Ida",
            "Mount Ivy",
            "Mount Jackson",
            "Mount Joy",
            "Mount Juliet",
            "Mount Kisco",
            "Mount Laurel",
            "Mount Lebanon",
            "Mount Morris",
            "Mount Morris",
            "Mount Morris",
            "Mount Morris",
            "Mount Olive",
            "Mount Olive",
            "Mount Olive",
            "Mount Oliver",
            "Mount Olivet",
            "Mount Olympus",
            "Mount Orab",
            "Mount Penn",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Pleasant",
            "Mount Plymouth",
            "Mount Pocono",
            "Mount Prospect",
            "Mount Pulaski",
            "Mount Rainier",
            "Mount Repose",
            "Mount Shasta",
            "Mount Sinai",
            "Mount Sterling",
            "Mount Sterling",
            "Mount Sterling",
            "Mount Union",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vernon",
            "Mount Vista",
            "Mount Washington",
            "Mount Wolf",
            "Mount Zion",
            "Mount Zion",
            "Mountain Brook",
            "Mountain City",
            "Mountain City",
            "Mountain Green",
            "Mountain Grove",
            "Mountain Home",
            "Mountain Home",
            "Mountain Home",
            "Mountain House",
            "Mountain Iron",
            "Mountain Lake",
            "Mountain Lake Park",
            "Mountain Lakes",
            "Mountain Lodge Park",
            "Mountain Park",
            "Mountain Ranch",
            "Mountain Road",
            "Mountain Top",
            "Mountain View",
            "Mountain View",
            "Mountain View",
            "Mountain View",
            "Mountain View",
            "Mountain View",
            "Mountain View Acres",
            "Mountain Village",
            "Mountainaire",
            "Mountainhome",
            "Mountainside",
            "Mountlake Terrace",
            "Mountrail County",
            "Mountville",
            "Moville",
            "Mowbray Mountain",
            "Moweaqua",
            "Mower County",
            "Moyock",
            "Mucarabones",
            "Muenster",
            "Muhlenberg County",
            "Muhlenberg Park",
            "Mukilteo",
            "Mukwonago",
            "Mulberry",
            "Mulberry",
            "Mulberry",
            "Mulberry",
            "Mulberry",
            "Muldrow",
            "Muleshoe",
            "Mulino",
            "Mullen",
            "Mullens",
            "Mullica Hill",
            "Mullins",
            "Multnomah County",
            "Mulvane",
            "Muncie",
            "Muncy",
            "Munday",
            "Mundelein",
            "Mundys Corner",
            "Munford",
            "Munford",
            "Munfordville",
            "Munhall",
            "Munising",
            "Muniz",
            "Munroe Falls",
            "Munsey Park",
            "Munsons Corners",
            "Munster",
            "Murdo",
            "Murfreesboro",
            "Murfreesboro",
            "Murfreesboro",
            "Murillo Colonia",
            "Murphy",
            "Murphy",
            "Murphy",
            "Murphy",
            "Murphys",
            "Murphys Estates",
            "Murphysboro",
            "Murray",
            "Murray",
            "Murray County",
            "Murray County",
            "Murray County",
            "Murraysville",
            "Murrells Inlet",
            "Murrieta",
            "Murrieta Hot Springs",
            "Murrysville",
            "Muscatine",
            "Muscatine County",
            "Muscle Shoals",
            "Muscoda",
            "Muscogee County",
            "Muscoy",
            "Muse",
            "Muskego",
            "Muskegon",
            "Muskegon County",
            "Muskegon Heights",
            "Muskingum County",
            "Muskogee",
            "Muskogee County",
            "Musselshell County",
            "Mustang",
            "Muttontown",
            "Myers Corner",
            "Myerstown",
            "Myersville",
            "Myrtle Beach",
            "Myrtle Creek",
            "Myrtle Grove",
            "Myrtle Grove",
            "Myrtle Point",
            "Myrtletown",
            "Mystic",
            "Mystic Island",
            "Naco",
            "Nacogdoches",
            "Nacogdoches County",
            "Nags Head",
            "Naguabo",
            "Naguabo Municipio",
            "Nahant",
            "Nahunta",
            "Nambe",
            "Nampa",
            "Nānākuli",
            "Nanawale Estates",
            "Nance County",
            "Nanticoke",
            "Nantucket",
            "Nantucket County",
            "Nanty Glo",
            "Nanuet",
            "Napa",
            "Napa County",
            "Napanoch",
            "Napavine",
            "Naperville",
            "Napili-Honokowai",
            "Naples",
            "Naples",
            "Naples",
            "Naples",
            "Naples Manor",
            "Naples Park",
            "Napoleon",
            "Napoleon",
            "Napoleon",
            "Napoleonville",
            "Nappanee",
            "Naranja",
            "Naranjito",
            "Naranjito Municipio",
            "Narberth",
            "Narragansett",
            "Narragansett Pier",
            "Narrows",
            "Nash",
            "Nash County",
            "Nashotah",
            "Nashua",
            "Nashua",
            "Nashville",
            "Nashville",
            "Nashville",
            "Nashville",
            "Nashville",
            "Nashville",
            "Nashville",
            "Nashville",
            "Nassau",
            "Nassau Bay",
            "Nassau County",
            "Nassau County",
            "Nassau Village-Ratliff",
            "Natalbany",
            "Natalia",
            "Natchez",
            "Natchitoches",
            "Natchitoches Parish",
            "Natick",
            "National City",
            "National Harbor",
            "National Park",
            "Natrona County",
            "Naugatuck",
            "Nauvoo",
            "Navajo",
            "Navajo County",
            "Naval Academy",
            "Navarre",
            "Navarre",
            "Navarro County",
            "Navasota",
            "Navassa",
            "Navesink",
            "Navy Yard City",
            "Nazareth",
            "Near North Side",
            "Near South Side",
            "Nebraska City",
            "Nederland",
            "Nederland",
            "Nedrow",
            "Needham",
            "Needles",
            "Needville",
            "Neenah",
            "Negaunee",
            "Neillsville",
            "Nekoosa",
            "Neligh",
            "Nellieburg",
            "Nellis Air Force Base",
            "Nellysford",
            "Nelson",
            "Nelson",
            "Nelson County",
            "Nelson County",
            "Nelson County",
            "Nelsonville",
            "Nemaha County",
            "Nemaha County",
            "Neodesha",
            "Neoga",
            "Neosho",
            "Neosho County",
            "Nephi",
            "Neponsit",
            "Neptune Beach",
            "Neptune City",
            "Nesconset",
            "Nescopeck",
            "Neshoba County",
            "Nesquehoning",
            "Ness City",
            "Ness County",
            "Netcong",
            "Nettleton",
            "Neuse Forest",
            "Nevada",
            "Nevada",
            "Nevada",
            "Nevada City",
            "Nevada County",
            "Nevada County",
            "New Albany",
            "New Albany",
            "New Albany",
            "New Athens",
            "New Augusta",
            "New Baden",
            "New Baltimore",
            "New Baltimore",
            "New Beaver",
            "New Bedford",
            "New Berlin",
            "New Berlin",
            "New Berlinville",
            "New Bern",
            "New Bloomfield",
            "New Boston",
            "New Boston",
            "New Boston",
            "New Braunfels",
            "New Bremen",
            "New Brighton",
            "New Brighton",
            "New Brighton",
            "New Britain",
            "New Britain",
            "New Brockton",
            "New Brunswick",
            "New Buffalo",
            "New Burlington",
            "New California",
            "New Canaan",
            "New Carlisle",
            "New Carlisle",
            "New Carrollton",
            "New Cassel",
            "New Castle",
            "New Castle",
            "New Castle",
            "New Castle",
            "New Castle",
            "New Castle",
            "New Castle",
            "New Castle County",
            "New Castle Northwest",
            "New Century KS",
            "New Chicago",
            "New City",
            "New City",
            "New Columbia",
            "New Concord",
            "New Cumberland",
            "New Cumberland",
            "New Dorp",
            "New Dorp Beach",
            "New Durham",
            "New Eagle",
            "New Egypt",
            "New Ellenton",
            "New Fairfield",
            "New Franklin",
            "New Franklin",
            "New Freedom",
            "New Glarus",
            "New Gloucester",
            "New Hampton",
            "New Hanover County",
            "New Hartford",
            "New Hartford Center",
            "New Haven",
            "New Haven",
            "New Haven",
            "New Haven",
            "New Haven",
            "New Haven County",
            "New Hempstead",
            "New Holland",
            "New Holstein",
            "New Hope",
            "New Hope",
            "New Hope",
            "New Hope",
            "New Hope",
            "New Hope",
            "New Hyde Park",
            "New Iberia",
            "New Ipswich",
            "New Johnsonville",
            "New Kensington",
            "New Kent",
            "New Kent County",
            "New Kingman-Butler",
            "New Lebanon",
            "New Lenox",
            "New Lexington",
            "New Lisbon",
            "New Llano",
            "New London",
            "New London",
            "New London",
            "New London",
            "New London",
            "New London",
            "New London",
            "New London County",
            "New Madrid",
            "New Madrid County",
            "New Market",
            "New Market",
            "New Market",
            "New Marlborough",
            "New Martinsville",
            "New Matamoras",
            "New Miami",
            "New Middletown",
            "New Milford",
            "New Milford",
            "New Orleans",
            "New Oxford",
            "New Palestine",
            "New Paltz",
            "New Paris",
            "New Paris",
            "New Pekin",
            "New Philadelphia",
            "New Philadelphia",
            "New Plymouth",
            "New Port Richey",
            "New Port Richey East",
            "New Prague",
            "New Preston",
            "New Providence",
            "New Richland",
            "New Richmond",
            "New Richmond",
            "New River",
            "New Roads",
            "New Rochelle",
            "New Rockford",
            "New Sarpy",
            "New Sharon",
            "New Sharon",
            "New Shoreham",
            "New Smyrna Beach",
            "New South Memphis",
            "New Springville",
            "New Square",
            "New Stanton",
            "New Summerfield",
            "New Tazewell",
            "New Territory",
            "New Town",
            "New Ulm",
            "New Union",
            "New Vienna",
            "New Waterford",
            "New Waverly",
            "New Whiteland",
            "New Wilmington",
            "New Windsor",
            "New Windsor",
            "New York City",
            "New York Mills",
            "New York Mills",
            "Newark",
            "Newark",
            "Newark",
            "Newark",
            "Newark",
            "Newark",
            "Newark",
            "Newark",
            "Newaygo",
            "Newaygo County",
            "Newberg",
            "Newbern",
            "Newberry",
            "Newberry",
            "Newberry",
            "Newberry County",
            "Newburg",
            "Newburg",
            "Newburgh",
            "Newburgh",
            "Newburgh Heights",
            "Newbury",
            "Newburyport",
            "Newcastle",
            "Newcastle",
            "Newcastle",
            "Newcastle",
            "Newcomerstown",
            "Newell",
            "Newellton",
            "Newfane",
            "Newfane",
            "Newfield",
            "Newfield",
            "Newington",
            "Newington",
            "Newkirk",
            "Newland",
            "Newman",
            "Newmanstown",
            "Newmarket",
            "Newnan",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport",
            "Newport Beach",
            "Newport County",
            "Newport East",
            "Newport News",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton",
            "Newton County",
            "Newton County",
            "Newton County",
            "Newton County",
            "Newton County",
            "Newton County",
            "Newton Falls",
            "Newtown",
            "Newtown",
            "Newtown",
            "Newtown Grant",
            "Newville",
            "Nez Perce County",
            "Nezperce",
            "Niagara",
            "Niagara County",
            "Niagara Falls",
            "Niantic",
            "Nibley",
            "Nice",
            "Niceville",
            "Nicholas County",
            "Nicholas County",
            "Nicholasville",
            "Nicholls",
            "Nichols Hills",
            "Nicholson",
            "Nicholson",
            "Nickerson",
            "Nicollet",
            "Nicollet County",
            "Nicoma Park",
            "Nikiski",
            "Niland",
            "Niles",
            "Niles",
            "Niles",
            "Ninety Six",
            "Ninnekah",
            "Niobrara County",
            "Nipomo",
            "Niskayuna",
            "Nissequogue",
            "Nisswa",
            "Nitro",
            "Niverville",
            "Niwot",
            "Nixa",
            "Nixon",
            "Nixon",
            "Noank",
            "Noble",
            "Noble County",
            "Noble County",
            "Noble County",
            "Nobleboro",
            "Nobles County",
            "Noblesville",
            "Nocatee",
            "Nocona",
            "Nodaway County",
            "Noe Valley",
            "Noel",
            "Nogales",
            "Nokesville",
            "Nokomis",
            "Nokomis",
            "Nolan County",
            "Nolanville",
            "Nolensville",
            "Nome",
            "Nome Census Area",
            "Nooksack",
            "Nora Springs",
            "Norco",
            "Norco",
            "Norcross",
            "Norfolk",
            "Norfolk",
            "Norfolk",
            "Norfolk",
            "Norfolk County",
            "Norland",
            "Norlina",
            "Normal",
            "Norman",
            "Norman County",
            "Normandy",
            "Normandy Park",
            "Norridge",
            "Norridgewock",
            "Norris",
            "Norris City",
            "Norristown",
            "North Adams",
            "North Alamo",
            "North Amherst",
            "North Amityville",
            "North Andover",
            "North Andrews Gardens",
            "North Apollo",
            "North Arlington",
            "North Atlanta",
            "North Attleborough Center",
            "North Auburn",
            "North Augusta",
            "North Aurora",
            "North Babylon",
            "North Ballston Spa",
            "North Baltimore",
            "North Barrington",
            "North Bath",
            "North Bay Shore",
            "North Bay Village",
            "North Beach",
            "North Beach Haven",
            "North Bel Air",
            "North Belle Vernon",
            "North Bellmore",
            "North Bellport",
            "North Bend",
            "North Bend",
            "North Bend",
            "North Bennington",
            "North Bergen",
            "North Berwick",
            "North Bethesda",
            "North Bibb",
            "North Boston",
            "North Braddock",
            "North Branch",
            "North Branch",
            "North Branford",
            "North Brookfield",
            "North Brooksville",
            "North Browning",
            "North Caldwell",
            "North Canton",
            "North Cape May",
            "North Castle",
            "North Catasauqua",
            "North Center",
            "North Charleroi",
            "North Charleston",
            "North Chicago",
            "North Chicopee",
            "North College Hill",
            "North Collins",
            "North Conway",
            "North Corbin",
            "North Creek",
            "North Crossett",
            "North Decatur",
            "North DeLand",
            "North Druid Hills",
            "North Eagle Butte",
            "North East",
            "North East",
            "North Eastham",
            "North Edwards",
            "North El Monte",
            "North Elba",
            "North English",
            "North Fair Oaks",
            "North Falmouth",
            "North Fond du Lac",
            "North Fork",
            "North Fork Village",
            "North Fort Lewis",
            "North Fort Myers",
            "North Gates",
            "North Granby",
            "North Great River",
            "North Grosvenor Dale",
            "North Haledon",
            "North Hampton",
            "North Hartsville",
            "North Haven",
            "North Hero",
            "North Highlands",
            "North Hills",
            "North Hills",
            "North Hollywood",
            "North Hudson",
            "North Judson",
            "North Kansas City",
            "North Kensington",
            "North Key Largo",
            "North Kingstown",
            "North Kingsville",
            "North La Crosse",
            "North Lakeport",
            "North Lakeville",
            "North Las Vegas",
            "North Lauderdale",
            "North Laurel",
            "North Lawndale",
            "North Lewisburg",
            "North Liberty",
            "North Liberty",
            "North Lindenhurst",
            "North Little Rock",
            "North Logan",
            "North Madison",
            "North Madison",
            "North Manchester",
            "North Mankato",
            "North Massapequa",
            "North Merrick",
            "North Miami",
            "North Miami Beach",
            "North Middletown",
            "North Muskegon",
            "North Myrtle Beach",
            "North New Hyde Park",
            "North Newton",
            "North Oaks",
            "North Ogden",
            "North Olmsted",
            "North Palm Beach",
            "North Patchogue",
            "North Pekin",
            "North Pembroke",
            "North Peoria",
            "North Plainfield",
            "North Plains",
            "North Platte",
            "North Plymouth",
            "North Pole",
            "North Port",
            "North Portland",
            "North Potomac",
            "North Prairie",
            "North Providence",
            "North Puyallup",
            "North Randall",
            "North Reading",
            "North Redington Beach",
            "North Richland Hills",
            "North Richmond",
            "North Ridgeville",
            "North River Shores",
            "North Riverside",
            "North Rock Springs",
            "North Royalton",
            "North Saint Paul",
            "North Salt Lake",
            "North Sarasota",
            "North Scituate",
            "North Scituate",
            "North Sea",
            "North Seekonk",
            "North Shore",
            "North Sioux City",
            "North Slope Borough",
            "North Smithfield",
            "North Spearfish",
            "North Springfield",
            "North Stamford",
            "North Star",
            "North Syracuse",
            "North Terre Haute",
            "North Tonawanda",
            "North Tunica",
            "North Tustin",
            "North Vacherie",
            "North Valley",
            "North Valley Stream",
            "North Vernon",
            "North Versailles",
            "North Wales",
            "North Wantagh",
            "North Warren",
            "North Webster",
            "North Weeki Wachee",
            "North Westport",
            "North Wildwood",
            "North Wilkesboro",
            "North Windham",
            "North Yelm",
            "North York",
            "North Zanesville",
            "Northampton",
            "Northampton",
            "Northampton County",
            "Northampton County",
            "Northampton County",
            "Northborough",
            "Northbridge",
            "Northbrook",
            "Northbrook",
            "Northchase",
            "Northcliff",
            "Northcrest",
            "Northdale",
            "Northeast Ithaca",
            "Northern Cambria",
            "Northfield",
            "Northfield",
            "Northfield",
            "Northfield",
            "Northfield",
            "Northfield",
            "Northfield",
            "Northfield",
            "Northgate",
            "Northglenn",
            "Northlake",
            "Northlake",
            "Northlake",
            "Northlakes",
            "Northport",
            "Northport",
            "Northport",
            "Northridge",
            "Northridge",
            "Northumberland",
            "Northumberland",
            "Northumberland",
            "Northumberland County",
            "Northumberland County",
            "Northvale",
            "Northview",
            "Northville",
            "Northville",
            "Northwest Arctic Borough",
            "Northwest Harbor",
            "Northwest Harborcreek",
            "Northwest Harwich",
            "Northwest Harwinton",
            "Northwest Ithaca",
            "Northwood",
            "Northwood",
            "Northwood",
            "Northwoods",
            "Norton",
            "Norton",
            "Norton",
            "Norton",
            "Norton Center",
            "Norton County",
            "Norton Shores",
            "Nortonville",
            "Norwalk",
            "Norwalk",
            "Norwalk",
            "Norwalk",
            "Norway",
            "Norway",
            "Norwell",
            "Norwich",
            "Norwich",
            "Norwood",
            "Norwood",
            "Norwood",
            "Norwood",
            "Norwood",
            "Norwood",
            "Norwood (historical)",
            "Norwood Young America",
            "Notre Dame",
            "Nottingham",
            "Nottoway County",
            "Novato",
            "Novi",
            "Nowata",
            "Nowata County",
            "Nowthen",
            "Noxubee County",
            "Noyack",
            "Nuckolls County",
            "Nueces County",
            "Nuevo",
            "Nunda",
            "Nurillo",
            "Nutley",
            "Nutter Fort",
            "Nyack",
            "Nye County",
            "Nyssa",
            "O'Brien County",
            "O'Fallon",
            "O'Fallon",
            "O'Neill",
            "Oak Bluffs",
            "Oak Brook",
            "Oak Cliff Place",
            "Oak Creek",
            "Oak Forest",
            "Oak Grove",
            "Oak Grove",
            "Oak Grove",
            "Oak Grove",
            "Oak Grove",
            "Oak Grove",
            "Oak Grove",
            "Oak Grove",
            "Oak Harbor",
            "Oak Harbor",
            "Oak Hill",
            "Oak Hill",
            "Oak Hill",
            "Oak Hill",
            "Oak Hill",
            "Oak Hills",
            "Oak Hills",
            "Oak Hills",
            "Oak Hills Place",
            "Oak Island",
            "Oak Lawn",
            "Oak Leaf",
            "Oak Park",
            "Oak Park",
            "Oak Park",
            "Oak Park",
            "Oak Park Heights",
            "Oak Point",
            "Oak Ridge",
            "Oak Ridge",
            "Oak Ridge",
            "Oak Ridge North",
            "Oak Trail Shores",
            "Oak Valley",
            "Oak View",
            "Oakboro",
            "Oakbrook",
            "Oakbrook Terrace",
            "Oakdale",
            "Oakdale",
            "Oakdale",
            "Oakdale",
            "Oakdale",
            "Oakes",
            "Oakfield",
            "Oakfield",
            "Oakham",
            "Oakhurst",
            "Oakhurst",
            "Oakhurst",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland",
            "Oakland City",
            "Oakland County",
            "Oakland Park",
            "Oakleaf Plantation",
            "Oakley",
            "Oakley",
            "Oakley",
            "Oaklyn",
            "Oakmont",
            "Oakport",
            "Oakridge",
            "Oakton",
            "Oakville",
            "Oakville",
            "Oakwood",
            "Oakwood",
            "Oakwood",
            "Oakwood",
            "Oakwood",
            "Oakwood Hills",
            "Oasis",
            "Oatfield",
            "Oberlin",
            "Oberlin",
            "Oberlin",
            "Obetz",
            "Obion",
            "Obion County",
            "Oblong",
            "Ocala",
            "Occidental",
            "Occoquan",
            "Ocean Acres",
            "Ocean Bluff-Brant Rock",
            "Ocean City",
            "Ocean City",
            "Ocean City",
            "Ocean County",
            "Ocean Gate",
            "Ocean Grove",
            "Ocean Grove",
            "Ocean Park",
            "Ocean Pines",
            "Ocean Pointe",
            "Ocean Ridge",
            "Ocean Shores",
            "Ocean Springs",
            "Ocean View",
            "Oceana",
            "Oceana County",
            "Oceano",
            "Oceanport",
            "Oceanside",
            "Oceanside",
            "Ochiltree County",
            "Ocilla",
            "Ocoee",
            "Oconee County",
            "Oconee County",
            "Oconomowoc",
            "Oconto",
            "Oconto County",
            "Oconto Falls",
            "Odell",
            "Odem",
            "Odenton",
            "Odenville",
            "Odessa",
            "Odessa",
            "Odessa",
            "Odin",
            "Odon",
            "Oelwein",
            "Offutt Air Force Base",
            "Ogallala",
            "Ogden",
            "Ogden",
            "Ogden",
            "Ogden",
            "Ogden Dunes",
            "Ogdensburg",
            "Ogdensburg",
            "Ogemaw County",
            "Oglala",
            "Oglala Lakota County",
            "Ogle County",
            "Oglesby",
            "Oglethorpe",
            "Oglethorpe County",
            "Ogunquit",
            "Ohatchee",
            "Ohio County",
            "Ohio County",
            "Ohio County",
            "Ohioville",
            "Ohkay Owingeh",
            "Oil City",
            "Oildale",
            "Oilton",
            "Ojai",
            "Ojus",
            "Okaloosa County",
            "Okanogan",
            "Okanogan County",
            "Okarche",
            "Okauchee Lake",
            "Okawville",
            "Okeechobee",
            "Okeechobee County",
            "Okeene",
            "Okemah",
            "Okemos",
            "Okfuskee County",
            "Oklahoma City",
            "Oklahoma County",
            "Okmulgee",
            "Okmulgee County",
            "Okolona",
            "Okolona",
            "Oktibbeha County",
            "Ola",
            "Olathe",
            "Olathe",
            "Olcott",
            "Old Bethpage",
            "Old Bridge",
            "Old Brookville",
            "Old Fig Garden",
            "Old Forge",
            "Old Greenwich",
            "Old Jamestown",
            "Old Jefferson",
            "Old Mystic",
            "Old Orchard",
            "Old Orchard Beach",
            "Old River-Winfree",
            "Old Saybrook",
            "Old Saybrook Center",
            "Old Tappan",
            "Old Town",
            "Old Westbury",
            "Oldham County",
            "Oldham County",
            "Oldsmar",
            "Olean",
            "Oley",
            "Olga",
            "Olimpo",
            "Olivarez",
            "Olive Branch",
            "Olive Hill",
            "Olivehurst",
            "Oliver",
            "Oliver County",
            "Oliver Springs",
            "Olivet",
            "Olivet",
            "Olivet",
            "Olivet",
            "Olivette",
            "Olivia",
            "Olla",
            "Olmito",
            "Olmos Park",
            "Olmsted County",
            "Olmsted Falls",
            "Olney",
            "Olney",
            "Olney",
            "Olton",
            "Olympia",
            "Olympia Fields",
            "Olympia Heights",
            "Olyphant",
            "Omaha",
            "Omak",
            "Omega",
            "Omro",
            "Onalaska",
            "Onalaska",
            "Onancock",
            "Onarga",
            "Onawa",
            "Oneida",
            "Oneida",
            "Oneida",
            "Oneida County",
            "Oneida County",
            "Oneida County",
            "Oneonta",
            "Oneonta",
            "Onida",
            "Onion Creek",
            "Onondaga County",
            "Onset",
            "Onslow County",
            "Ontario",
            "Ontario",
            "Ontario",
            "Ontario",
            "Ontario County",
            "Ontonagon",
            "Ontonagon County",
            "Oolitic",
            "Oologah",
            "Oostburg",
            "Opa-locka",
            "Opal Cliffs",
            "Opelika",
            "Opelousas",
            "Opp",
            "Opportunity",
            "Oquawka",
            "Oquirrh",
            "Oracle",
            "Oradell",
            "Oran",
            "Orange",
            "Orange",
            "Orange",
            "Orange",
            "Orange",
            "Orange",
            "Orange",
            "Orange Beach",
            "Orange City",
            "Orange City",
            "Orange County",
            "Orange County",
            "Orange County",
            "Orange County",
            "Orange County",
            "Orange County",
            "Orange County",
            "Orange County",
            "Orange Cove",
            "Orange Grove",
            "Orange Lake",
            "Orange Park",
            "Orangeburg",
            "Orangeburg",
            "Orangeburg County",
            "Orangetree",
            "Orangevale",
            "Orangeville",
            "Orchard City",
            "Orchard Grass Hills",
            "Orchard Hills",
            "Orchard Homes",
            "Orchard Lake",
            "Orchard Mesa",
            "Orchard Park",
            "Orchards",
            "Orchidlands Estates",
            "Orcutt",
            "Ord",
            "Ordway",
            "Ore City",
            "Oregon",
            "Oregon",
            "Oregon",
            "Oregon",
            "Oregon City",
            "Oregon County",
            "Oreland",
            "Orem",
            "Orford",
            "Orfordville",
            "Orinda",
            "Oriole Beach",
            "Orion",
            "Oriskany",
            "Orland",
            "Orland",
            "Orland Hills",
            "Orland Park",
            "Orlando",
            "Orleans",
            "Orleans",
            "Orleans County",
            "Orleans County",
            "Orleans Parish",
            "Orlovista",
            "Ormond Beach",
            "Ormond-by-the-Sea",
            "Oro Valley",
            "Orocovis",
            "Orocovis Municipio",
            "Orofino",
            "Orono",
            "Orono",
            "Oronoco",
            "Oronogo",
            "Orosi",
            "Oroville",
            "Oroville",
            "Oroville East",
            "Orrington",
            "Orrville",
            "Orting",
            "Ortonville",
            "Ortonville",
            "Orwell",
            "Orwigsburg",
            "Osage",
            "Osage Beach",
            "Osage City",
            "Osage County",
            "Osage County",
            "Osage County",
            "Osakis",
            "Osawatomie",
            "Osborne",
            "Osborne County",
            "Osburn",
            "Osceola",
            "Osceola",
            "Osceola",
            "Osceola",
            "Osceola",
            "Osceola",
            "Osceola County",
            "Osceola County",
            "Osceola County",
            "Osceola Mills",
            "Oscoda County",
            "Osgood",
            "Oshkosh",
            "Oshkosh",
            "Oskaloosa",
            "Oskaloosa",
            "Osprey",
            "Osseo",
            "Osseo",
            "Ossian",
            "Ossining",
            "Ossipee",
            "Ossun",
            "Osterville",
            "Oswego",
            "Oswego",
            "Oswego",
            "Oswego County",
            "Otero County",
            "Otero County",
            "Othello",
            "Otis",
            "Otis Orchards-East Farms",
            "Otisville",
            "Otoe County",
            "Otsego",
            "Otsego",
            "Otsego County",
            "Otsego County",
            "Ottawa",
            "Ottawa",
            "Ottawa",
            "Ottawa County",
            "Ottawa County",
            "Ottawa County",
            "Ottawa County",
            "Ottawa Hills",
            "Otter Tail County",
            "Otterbein",
            "Ottumwa",
            "Ouachita County",
            "Ouachita Parish",
            "Ouray",
            "Ouray County",
            "Outagamie County",
            "Overbrook",
            "Overland",
            "Overland Park",
            "Overlea",
            "Overton",
            "Overton County",
            "Ovid",
            "Oviedo",
            "Ovilla",
            "Owasso",
            "Owatonna",
            "Owego",
            "Owen County",
            "Owen County",
            "Owens Cross Roads",
            "Owensboro",
            "Owensville",
            "Owensville",
            "Owenton",
            "Owings",
            "Owings Mills",
            "Owingsville",
            "Owls Head",
            "Owosso",
            "Owsley County",
            "Owyhee County",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford",
            "Oxford County",
            "Oxnard",
            "Oxoboxo River",
            "Oxon Hill",
            "Oxon Hill-Glassmanor",
            "Oyster Bay",
            "Oyster Bay Cove",
            "Oyster Creek",
            "Ozark",
            "Ozark",
            "Ozark",
            "Ozark County",
            "Ozaukee County",
            "Ozona",
            "Ozone Park",
            "Pablo",
            "Pace",
            "Pacheco",
            "Pacific",
            "Pacific",
            "Pacific City",
            "Pacific County",
            "Pacific Grove",
            "Pacifica",
            "Pacolet",
            "Paddock Lake",
            "Paden City",
            "Paducah",
            "Paducah",
            "Page",
            "Page County",
            "Page County",
            "Pagedale",
            "Pageland",
            "Pagosa Springs",
            "Pāhala",
            "Pahokee",
            "Pahrump",
            "Paia",
            "Painesville",
            "Paint Rock",
            "Painted Post",
            "Paintsville",
            "Pajaro",
            "Pájaros",
            "Pajonal",
            "Palacios",
            "Palatine",
            "Palatka",
            "Palenville",
            "Palermo",
            "Palermo",
            "Palestine",
            "Palestine",
            "Palisade",
            "Palisades Park",
            "Palm Aire",
            "Palm Bay",
            "Palm Beach",
            "Palm Beach County",
            "Palm Beach Gardens",
            "Palm Beach Shores",
            "Palm City",
            "Palm Coast",
            "Palm Desert",
            "Palm Harbor",
            "Palm River-Clair Mel",
            "Palm Springs",
            "Palm Springs",
            "Palm Springs North",
            "Palm Valley",
            "Palm Valley",
            "Palmarejo",
            "Palmas",
            "Palmdale",
            "Palmdale",
            "Palmer",
            "Palmer",
            "Palmer",
            "Palmer",
            "Palmer Heights",
            "Palmer Lake",
            "Palmerton",
            "Palmetto",
            "Palmetto",
            "Palmetto Bay",
            "Palmetto Estates",
            "Palmhurst",
            "Palmona Park",
            "Palmview",
            "Palmview South",
            "Palmyra",
            "Palmyra",
            "Palmyra",
            "Palmyra",
            "Palmyra",
            "Palmyra",
            "Palmyra",
            "Palo",
            "Palo Alto",
            "Palo Alto",
            "Palo Alto County",
            "Palo Cedro",
            "Palo Pinto",
            "Palo Pinto County",
            "Palo Seco",
            "Paloma Creek",
            "Paloma Creek South",
            "Palomas",
            "Palos Heights",
            "Palos Hills",
            "Palos Park",
            "Palos Verdes Estates",
            "Palouse",
            "Pamlico County",
            "Pampa",
            "Pamplico",
            "Pana",
            "Panama",
            "Panama City",
            "Panama City Beach",
            "Pandora",
            "Panguitch",
            "Panhandle",
            "Pannill Fork",
            "Panola County",
            "Panola County",
            "Panora",
            "Panorama Village",
            "Pantego",
            "Panthersville",
            "Pantops",
            "Paola",
            "Paoli",
            "Paoli",
            "Paonia",
            "Pāpa‘ikou",
            "Papillion",
            "Parachute",
            "Paradis",
            "Paradise",
            "Paradise",
            "Paradise",
            "Paradise Heights",
            "Paradise Hills",
            "Paradise Valley",
            "Paragould",
            "Paramount",
            "Paramount-Long Meadow",
            "Paramus",
            "Parcelas La Milagrosa",
            "Parcelas Nuevas",
            "Parcelas Peñuelas",
            "Parchment",
            "Pardeeville",
            "Paris",
            "Paris",
            "Paris",
            "Paris",
            "Paris",
            "Paris",
            "Paris",
            "Paris",
            "Park City",
            "Park City",
            "Park City",
            "Park City",
            "Park County",
            "Park County",
            "Park County",
            "Park Falls",
            "Park Forest",
            "Park Forest Village",
            "Park Hill",
            "Park Hills",
            "Park Hills",
            "Park Layne",
            "Park Rapids",
            "Park Ridge",
            "Park Ridge",
            "Park River",
            "Park Slope",
            "Park View",
            "Parkchester",
            "Parke County",
            "Parker",
            "Parker",
            "Parker",
            "Parker",
            "Parker",
            "Parker",
            "Parker City",
            "Parker County",
            "Parkers Prairie",
            "Parkersburg",
            "Parkersburg",
            "Parkesburg",
            "Parkin",
            "Parkland",
            "Parkland",
            "Parks",
            "Parksdale",
            "Parkside",
            "Parkston",
            "Parkville",
            "Parkville",
            "Parkville",
            "Parkville",
            "Parkway",
            "Parkwood",
            "Parkwood",
            "Parlier",
            "Parma",
            "Parma",
            "Parma Heights",
            "Parmer County",
            "Parole",
            "Parowan",
            "Parshall",
            "Parsippany",
            "Parsons",
            "Parsons",
            "Parsons",
            "Parsonsfield",
            "Pasadena",
            "Pasadena",
            "Pasadena",
            "Pasadena Hills",
            "Pasatiempo",
            "Pascagoula",
            "Pasco",
            "Pasco County",
            "Pascoag",
            "Paso Robles",
            "Pasquotank County",
            "Pass Christian",
            "Passaic",
            "Passaic County",
            "Passapatanzy",
            "Pastos",
            "Pataskala",
            "Patchogue",
            "Paterson",
            "Patillas",
            "Patillas Municipio",
            "Patrick County",
            "Patrick Springs",
            "Patten",
            "Patterson",
            "Patterson",
            "Patterson Tract",
            "Patton",
            "Patton Village",
            "Paul",
            "Paulden",
            "Paulding",
            "Paulding County",
            "Paulding County",
            "Paulina",
            "Paullina",
            "Pauls Valley",
            "Paulsboro",
            "Paw Paw",
            "Paw Paw Lake",
            "Pawcatuck",
            "Pawhuska",
            "Pawlet",
            "Pawling",
            "Pawnee",
            "Pawnee",
            "Pawnee City",
            "Pawnee County",
            "Pawnee County",
            "Pawnee County",
            "Pawtucket",
            "Paxtang",
            "Paxton",
            "Paxton",
            "Paxtonia",
            "Payette",
            "Payette County",
            "Payne",
            "Payne County",
            "Paynesville",
            "Payson",
            "Payson",
            "Payson",
            "Pea Ridge",
            "Pea Ridge",
            "Peabody",
            "Peabody",
            "Peaceful Valley",
            "Peach County",
            "Peach Lake",
            "Peach Springs",
            "Peachtree City",
            "Peachtree Corners",
            "Peapack",
            "Pearisburg",
            "Pearl",
            "Pearl Beach",
            "Pearl City",
            "Pearl River",
            "Pearl River",
            "Pearl River",
            "Pearl River County",
            "Pearland",
            "Pearlington",
            "Pearsall",
            "Pearson",
            "Pebble Creek",
            "Pecan Acres",
            "Pecan Grove",
            "Pecan Plantation",
            "Pecatonica",
            "Pecos",
            "Pecos",
            "Pecos County",
            "Peculiar",
            "Pedley",
            "Peebles",
            "Peekskill",
            "Pegram",
            "Pekin",
            "Pelahatchie",
            "Pelham",
            "Pelham",
            "Pelham",
            "Pelham",
            "Pelham",
            "Pelham Manor",
            "Pelican Bay",
            "Pelican Bay",
            "Pelican Rapids",
            "Pell City",
            "Pell Lake",
            "Pella",
            "Pemberton",
            "Pemberton Heights",
            "Pemberville",
            "Pemberwick",
            "Pembina County",
            "Pembroke",
            "Pembroke",
            "Pembroke",
            "Pembroke",
            "Pembroke Park",
            "Pembroke Pines",
            "Pemiscot County",
            "Pen Argyl",
            "Peña Pobre",
            "Penbrook",
            "Pend Oreille County",
            "Pender",
            "Pender County",
            "Pendleton",
            "Pendleton",
            "Pendleton",
            "Pendleton County",
            "Pendleton County",
            "Penitas",
            "Penn Estates",
            "Penn Hills",
            "Penn Valley",
            "Penn Wynne",
            "Penn Yan",
            "Penndel",
            "Penngrove",
            "Pennington",
            "Pennington County",
            "Pennington County",
            "Pennington Gap",
            "Penns Grove",
            "Pennsauken",
            "Pennsboro",
            "Pennsburg",
            "Pennside",
            "Pennsport",
            "Pennsville",
            "Pennville",
            "Penobscot",
            "Penobscot County",
            "Penrose",
            "Penryn",
            "Pensacola",
            "Peñuelas",
            "Peoria",
            "Peoria",
            "Peoria County",
            "Peoria Heights",
            "Peosta",
            "Peotone",
            "Pepeekeo",
            "Pepin County",
            "Pepper Pike",
            "Pepperell",
            "Peppermill Village",
            "Pequot Lakes",
            "Peralta",
            "Perezville",
            "Perham",
            "Peridot",
            "Perkasie",
            "Perkins",
            "Perkins County",
            "Perkins County",
            "Perquimans County",
            "Perris",
            "Perry",
            "Perry",
            "Perry",
            "Perry",
            "Perry",
            "Perry",
            "Perry",
            "Perry",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry County",
            "Perry Hall",
            "Perry Heights",
            "Perry Park",
            "Perryman",
            "Perryopolis",
            "Perrysburg",
            "Perryton",
            "Perryville",
            "Perryville",
            "Perryville",
            "Pershing County",
            "Person County",
            "Perth",
            "Perth Amboy",
            "Peru",
            "Peru",
            "Peru",
            "Peru",
            "Peshtigo",
            "Petal",
            "Petaluma",
            "Peterborough",
            "Petersburg",
            "Petersburg",
            "Petersburg",
            "Petersburg",
            "Petersburg",
            "Petersburg",
            "Petersburg",
            "Petersburg Borough",
            "Petoskey",
            "Petroleum County",
            "Pettis County",
            "Pevely",
            "Pewaukee",
            "Pewee Valley",
            "Pflugerville",
            "Pharr",
            "Phelan",
            "Phelps",
            "Phelps County",
            "Phelps County",
            "Phenix City",
            "Phil Campbell",
            "Philadelphia",
            "Philadelphia",
            "Philadelphia",
            "Philadelphia County",
            "Philip",
            "Philippi",
            "Philipsburg",
            "Philipsburg",
            "Phillips",
            "Phillips",
            "Phillips County",
            "Phillips County",
            "Phillips County",
            "Phillips County",
            "Phillipsburg",
            "Phillipsburg",
            "Phillipston",
            "Philmont",
            "Philo",
            "Philomath",
            "Phippsburg",
            "Phoenix",
            "Phoenix",
            "Phoenix",
            "Phoenix",
            "Phoenix Lake",
            "Phoenixville",
            "Piatt County",
            "Picayune",
            "Pickaway County",
            "Pickens",
            "Pickens",
            "Pickens County",
            "Pickens County",
            "Pickens County",
            "Pickerington",
            "Pickett County",
            "Picnic Point",
            "Picnic Point-North Lynnwood",
            "Pico Rivera",
            "Picture Rocks",
            "Piedmont",
            "Piedmont",
            "Piedmont",
            "Piedmont",
            "Piedmont",
            "Piedra Gorda",
            "Pierce",
            "Pierce City",
            "Pierce County",
            "Pierce County",
            "Pierce County",
            "Pierce County",
            "Pierce County",
            "Pierceton",
            "Piermont",
            "Pierre",
            "Pierre Part",
            "Pierson",
            "Pierz",
            "Pigeon",
            "Pigeon Forge",
            "Piggott",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike County",
            "Pike Creek",
            "Pike Creek Valley",
            "Pike Road",
            "Pikesville",
            "Piketon",
            "Pikeville",
            "Pikeville",
            "Pilot Mountain",
            "Pilot Point",
            "Pilot Rock",
            "Pima",
            "Pima County",
            "Pimmit Hills",
            "Pinal County",
            "Pinardville",
            "Pinch",
            "Pinckney",
            "Pinckneyville",
            "Pinconning",
            "Pine",
            "Pine Beach",
            "Pine Bluff",
            "Pine Bluffs",
            "Pine Bush",
            "Pine Castle",
            "Pine City",
            "Pine County",
            "Pine Crest",
            "Pine Grove",
            "Pine Grove",
            "Pine Grove Mills",
            "Pine Hill",
            "Pine Hills",
            "Pine Hills",
            "Pine Island",
            "Pine Island",
            "Pine Island Center",
            "Pine Island Ridge",
            "Pine Knoll Shores",
            "Pine Knot",
            "Pine Lake Park",
            "Pine Lawn",
            "Pine Level",
            "Pine Level",
            "Pine Manor",
            "Pine Mountain",
            "Pine Mountain Club",
            "Pine Plains",
            "Pine Prairie",
            "Pine Ridge",
            "Pine Ridge",
            "Pine Ridge",
            "Pine Ridge at Crestwood",
            "Pine Valley",
            "Pinebluff",
            "Pinecrest",
            "Pinedale",
            "Pinehurst",
            "Pinehurst",
            "Pinehurst",
            "Pinehurst",
            "Pinellas County",
            "Pinellas Park",
            "Pineridge",
            "Pinetop-Lakeside",
            "Pinetops",
            "Pineville",
            "Pineville",
            "Pineville",
            "Pineville",
            "Pineville",
            "Pinewood",
            "Pinewood Estates",
            "Piney",
            "Piney Green",
            "Piney Point Village",
            "Pingree Grove",
            "Pink",
            "Pinole",
            "Piñon Hills",
            "Pinson",
            "Pioche",
            "Pioneer",
            "Pioneer",
            "Pioneer Village",
            "Piperton",
            "Pipestone",
            "Pipestone County",
            "Piqua",
            "Pirtleville",
            "Piru",
            "Piscataquis County",
            "Piscataway",
            "Pismo Beach",
            "Pistakee Highlands",
            "Pitcairn",
            "Pitkin County",
            "Pitman",
            "Pitt County",
            "Pittsboro",
            "Pittsboro",
            "Pittsboro",
            "Pittsburg",
            "Pittsburg",
            "Pittsburg",
            "Pittsburg County",
            "Pittsburgh",
            "Pittsfield",
            "Pittsfield",
            "Pittsfield",
            "Pittsfield",
            "Pittsfield",
            "Pittsford",
            "Pittston",
            "Pittston",
            "Pittsville",
            "Pittsylvania County",
            "Piute County",
            "Pixley",
            "Placentia",
            "Placer County",
            "Placerville",
            "Placid Lakes",
            "Placitas",
            "Plain City",
            "Plain City",
            "Plain View",
            "Plainedge",
            "Plainfield",
            "Plainfield",
            "Plainfield",
            "Plainfield",
            "Plainfield Village",
            "Plains",
            "Plains",
            "Plains",
            "Plains",
            "Plainsboro Center",
            "Plainview",
            "Plainview",
            "Plainview",
            "Plainview",
            "Plainview",
            "Plainville",
            "Plainville",
            "Plainville",
            "Plainwell",
            "Plaistow",
            "Planada",
            "Plandome",
            "Plandome Heights",
            "Plankinton",
            "Plano",
            "Plano",
            "Plano",
            "Plant City",
            "Plantation",
            "Plantation Mobile Home Park",
            "Plantersville",
            "Plaquemine",
            "Plaquemines Parish",
            "Platte",
            "Platte City",
            "Platte County",
            "Platte County",
            "Platte County",
            "Plattekill",
            "Platteville",
            "Platteville",
            "Plattsburg",
            "Plattsburgh",
            "Plattsburgh West",
            "Plattsmouth",
            "Playa Fortuna",
            "Playita",
            "Playita Cortada",
            "Pleak",
            "Pleasant Gap",
            "Pleasant Garden",
            "Pleasant Grove",
            "Pleasant Grove",
            "Pleasant Grove",
            "Pleasant Hill",
            "Pleasant Hill",
            "Pleasant Hill",
            "Pleasant Hill",
            "Pleasant Hill",
            "Pleasant Hill",
            "Pleasant Hills",
            "Pleasant Hills",
            "Pleasant Prairie",
            "Pleasant Ridge",
            "Pleasant Run",
            "Pleasant Run Farm",
            "Pleasant Valley",
            "Pleasant Valley",
            "Pleasant Valley",
            "Pleasant View",
            "Pleasant View",
            "Pleasanton",
            "Pleasanton",
            "Pleasanton",
            "Pleasants County",
            "Pleasantville",
            "Pleasantville",
            "Pleasantville",
            "Pleasure Ridge Park",
            "Plentywood",
            "Plover",
            "Plum",
            "Plum Creek",
            "Plum Grove",
            "Plumas County",
            "Plumas Lake",
            "Plummer",
            "Plumsteadville",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth",
            "Plymouth County",
            "Plymouth County",
            "Plymouth Meeting",
            "Plympton",
            "Pocahontas",
            "Pocahontas",
            "Pocahontas County",
            "Pocahontas County",
            "Pocasset",
            "Pocatello",
            "Pocola",
            "Pocomoke City",
            "Pocono Pines",
            "Pocono Ranch Lands",
            "Poestenkill",
            "Poinciana",
            "Poinsett County",
            "Point Baker",
            "Point Clear",
            "Point Lookout",
            "Point Marion",
            "Point of Rocks",
            "Point Pleasant",
            "Point Pleasant",
            "Point Pleasant Beach",
            "Point Roberts",
            "Pointe Coupee Parish",
            "Pojoaque",
            "Poland",
            "Poland",
            "Pole Ojea",
            "Polk City",
            "Polk City",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polk County",
            "Polkton",
            "Pollock Pines",
            "Polo",
            "Polson",
            "Pomeroy",
            "Pomeroy",
            "Pomona",
            "Pomona",
            "Pomona",
            "Pompano Beach",
            "Pompano Beach Highlands",
            "Pompton Lakes",
            "Ponca",
            "Ponca City",
            "Ponce",
            "Ponce Inlet",
            "Ponchatoula",
            "Ponder",
            "Pondera County",
            "Ponderay",
            "Ponderosa Park",
            "Ponderosa Pine",
            "Ponte Vedra Beach",
            "Pontiac",
            "Pontiac",
            "Pontoon Beach",
            "Pontotoc",
            "Pontotoc County",
            "Pontotoc County",
            "Pooler",
            "Poolesville",
            "Pope County",
            "Pope County",
            "Pope County",
            "Poplar Bluff",
            "Poplar Grove",
            "Poplar-Cotton Center",
            "Poplarville",
            "Poquonock Bridge",
            "Poquoson",
            "Porcupine",
            "Port Allegany",
            "Port Allen",
            "Port Angeles",
            "Port Angeles East",
            "Port Aransas",
            "Port Arthur",
            "Port Barre",
            "Port Barrington",
            "Port Byron",
            "Port Byron",
            "Port Carbon",
            "Port Charlotte",
            "Port Chester",
            "Port Clinton",
            "Port Dickinson",
            "Port Edwards",
            "Port Ewen",
            "Port Gibson",
            "Port Hadlock-Irondale",
            "Port Henry",
            "Port Hueneme",
            "Port Huron",
            "Port Isabel",
            "Port Jefferson",
            "Port Jefferson Station",
            "Port Jervis",
            "Port LaBelle",
            "Port Lavaca",
            "Port Ludlow",
            "Port Monmouth",
            "Port Morris",
            "Port Neches",
            "Port Norris",
            "Port O'Connor",
            "Port Orange",
            "Port Orchard",
            "Port Orford",
            "Port Reading",
            "Port Republic",
            "Port Richey",
            "Port Richmond",
            "Port Royal",
            "Port Saint Joe",
            "Port Saint John",
            "Port Saint Lucie",
            "Port Salerno",
            "Port Sulphur",
            "Port Townsend",
            "Port Vue",
            "Port Washington",
            "Port Washington",
            "Port Washington North",
            "Port Wentworth",
            "Portage",
            "Portage",
            "Portage",
            "Portage",
            "Portage County",
            "Portage County",
            "Portage Lakes",
            "Portage Park",
            "Portageville",
            "Portales",
            "Porter",
            "Porter",
            "Porter County",
            "Porter Heights",
            "Porterdale",
            "Porterville",
            "Portland",
            "Portland",
            "Portland",
            "Portland",
            "Portland",
            "Portland",
            "Portland",
            "Portland",
            "Portland",
            "Portola",
            "Portola Hills",
            "Portola Valley",
            "Portsmouth",
            "Portsmouth",
            "Portsmouth",
            "Portsmouth",
            "Portsmouth Heights",
            "Posen",
            "Posey County",
            "Poseyville",
            "Post",
            "Post Falls",
            "Postville",
            "Potala Pastillo",
            "Poteau",
            "Poteet",
            "Poth",
            "Potomac",
            "Potomac Heights",
            "Potomac Mills",
            "Potomac Park",
            "Potosi",
            "Potosi",
            "Potsdam",
            "Pottawatomie County",
            "Pottawatomie County",
            "Pottawattamie County",
            "Potter County",
            "Potter County",
            "Potter County",
            "Potter Lake",
            "Potterville",
            "Pottsboro",
            "Pottsgrove",
            "Pottstown",
            "Pottsville",
            "Pottsville",
            "Poughkeepsie",
            "Poulsbo",
            "Poultney",
            "Pound Ridge",
            "Poway",
            "Powder River County",
            "Powder Springs",
            "Powderly",
            "Powdersville",
            "Powell",
            "Powell",
            "Powell County",
            "Powell County",
            "Powells Crossroads",
            "Power County",
            "Powers Lake",
            "Poweshiek County",
            "Powhatan County",
            "Powhatan Point",
            "Pownal",
            "Poydras",
            "Poynette",
            "Prague",
            "Prairie City",
            "Prairie County",
            "Prairie County",
            "Prairie Creek",
            "Prairie du Chien",
            "Prairie du Sac",
            "Prairie Grove",
            "Prairie Grove",
            "Prairie Heights",
            "Prairie Ridge",
            "Prairie View",
            "Prairie Village",
            "Prairieville",
            "Pratt",
            "Pratt County",
            "Prattville",
            "Preble County",
            "Premont",
            "Prentiss",
            "Prentiss County",
            "Prescott",
            "Prescott",
            "Prescott",
            "Prescott Valley",
            "Presidential Lakes Estates",
            "Presidio",
            "Presidio County",
            "Presque Isle",
            "Presque Isle County",
            "Presquille",
            "Prestbury",
            "Preston",
            "Preston",
            "Preston",
            "Preston",
            "Preston",
            "Preston City",
            "Preston County",
            "Preston Heights",
            "Prestonsburg",
            "Pretty Bayou",
            "Price",
            "Price County",
            "Prices Fork",
            "Priceville",
            "Prichard",
            "Prien",
            "Priest River",
            "Primera",
            "Primghar",
            "Prince Edward County",
            "Prince Frederick",
            "Prince George",
            "Prince George County",
            "Prince George's County",
            "Prince William County",
            "Princes Lakes",
            "Princess Anne",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton",
            "Princeton Junction",
            "Princeton Meadows",
            "Princeville",
            "Princeville",
            "Princeville",
            "Prineville",
            "Prior Lake",
            "Privateer",
            "Proctor",
            "Progreso",
            "Progress",
            "Progress Village",
            "Prophetstown",
            "Prospect",
            "Prospect",
            "Prospect",
            "Prospect",
            "Prospect Heights",
            "Prospect Park",
            "Prospect Park",
            "Prosper",
            "Prosperity",
            "Prosperity",
            "Prosser",
            "Providence",
            "Providence",
            "Providence",
            "Providence",
            "Providence County",
            "Provincetown",
            "Provo",
            "Prowers County",
            "Prudenville",
            "Prudhoe Bay",
            "Prunedale",
            "Pryor",
            "Pryor Creek",
            "Pueblito del Rio",
            "Pueblo",
            "Pueblo County",
            "Pueblo West",
            "Puerto Real",
            "Puhi",
            "Pukalani",
            "Pulaski",
            "Pulaski",
            "Pulaski",
            "Pulaski",
            "Pulaski County",
            "Pulaski County",
            "Pulaski County",
            "Pulaski County",
            "Pulaski County",
            "Pulaski County",
            "Pulaski County",
            "Pullman",
            "Pumphrey",
            "Pumpkin Center",
            "Punalu‘u",
            "Punta Gorda",
            "Punta Gorda Isles",
            "Punta Rassa",
            "Punta Santiago",
            "Punxsutawney",
            "Pupukea",
            "Purcell",
            "Purcellville",
            "Purchase",
            "Purdy",
            "Purdy",
            "Purvis",
            "Pushmataha County",
            "Putnam",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam County",
            "Putnam Lake",
            "Putney",
            "Puyallup",
            "Pymatuning Central",
            "Quail Creek",
            "Quail Ridge",
            "Quail Valley",
            "Quakertown",
            "Quanah",
            "Quantico Station",
            "Quarryville",
            "Quartz Hill",
            "Quartzsite",
            "Quay County",
            "Quebrada",
            "Quebradillas",
            "Quebradillas Municipio",
            "Queen Anne",
            "Queen Anne's County",
            "Queen City",
            "Queen Creek",
            "Queenland",
            "Queens",
            "Queens County",
            "Queens Village",
            "Queensbury",
            "Questa",
            "Quincy",
            "Quincy",
            "Quincy",
            "Quincy",
            "Quincy",
            "Quincy",
            "Quinebaug",
            "Quinlan",
            "Quinnesec",
            "Quinton",
            "Quitman",
            "Quitman",
            "Quitman",
            "Quitman County",
            "Quitman County",
            "Rabun County",
            "Raceland",
            "Raceland",
            "Racine",
            "Racine County",
            "Radcliff",
            "Radford",
            "Radium Springs",
            "Radnor",
            "Raeford",
            "Rafael Capo",
            "Rafael Gonzalez",
            "Rafael Hernandez",
            "Rafter J Ranch",
            "Ragland",
            "Rahway",
            "Rainbow",
            "Rainbow City",
            "Rainelle",
            "Rainier",
            "Rainier",
            "Rains County",
            "Rainsville",
            "Raleigh",
            "Raleigh",
            "Raleigh County",
            "Raleigh Hills",
            "Ralls",
            "Ralls County",
            "Ralston",
            "Ramblewood",
            "Ramona",
            "Ramos",
            "Ramseur",
            "Ramsey",
            "Ramsey",
            "Ramsey",
            "Ramsey County",
            "Ramsey County",
            "Ramtown",
            "Ranchettes",
            "Rancho Alegre",
            "Rancho Calaveras",
            "Rancho Cordova",
            "Rancho Cucamonga",
            "Rancho Mirage",
            "Rancho Murieta",
            "Rancho Palos Verdes",
            "Rancho Penasquitos",
            "Rancho San Diego",
            "Rancho Santa Fe",
            "Rancho Santa Margarita",
            "Rancho Tehama Reserve",
            "Rancho Viejo",
            "Ranchos de Taos",
            "Rand",
            "Randall County",
            "Randallstown",
            "Randleman",
            "Randolph",
            "Randolph",
            "Randolph",
            "Randolph",
            "Randolph",
            "Randolph",
            "Randolph",
            "Randolph County",
            "Randolph County",
            "Randolph County",
            "Randolph County",
            "Randolph County",
            "Randolph County",
            "Randolph County",
            "Randolph County",
            "Random Lake",
            "Rangely",
            "Ranger",
            "Rankin",
            "Rankin",
            "Rankin County",
            "Ranlo",
            "Ransom Canyon",
            "Ransom County",
            "Ransomville",
            "Ranson",
            "Rantoul",
            "Raoul",
            "Rapid City",
            "Rapid City",
            "Rapid Valley",
            "Rapides Parish",
            "Rapids",
            "Rappahannock County",
            "Raritan",
            "Rathdrum",
            "Raton",
            "Raubsville",
            "Ravalli County",
            "Raven",
            "Ravena",
            "Ravenel",
            "Ravenna",
            "Ravenna",
            "Ravenna",
            "Ravensdale",
            "Ravenswood",
            "Ravensworth",
            "Rawlins",
            "Rawlins County",
            "Rawls Springs",
            "Ray City",
            "Ray County",
            "Raymond",
            "Raymond",
            "Raymond",
            "Raymond",
            "Raymondville",
            "Raymore",
            "Rayne",
            "Raynham",
            "Raynham Center",
            "Raytown",
            "Rayville",
            "Readfield",
            "Reading",
            "Reading",
            "Reading",
            "Reading",
            "Reagan County",
            "Real County",
            "Reamstown",
            "Rector",
            "Red Bank",
            "Red Bank",
            "Red Bank",
            "Red Bay",
            "Red Bluff",
            "Red Boiling Springs",
            "Red Bud",
            "Red Chute",
            "Red Cloud",
            "Red Corral",
            "Red Hill",
            "Red Hill",
            "Red Hook",
            "Red Lake",
            "Red Lake County",
            "Red Lake Falls",
            "Red Lick",
            "Red Lion",
            "Red Lodge",
            "Red Oak",
            "Red Oak",
            "Red Oak",
            "Red Oaks Mill",
            "Red River County",
            "Red River Parish",
            "Red Springs",
            "Red Willow County",
            "Red Wing",
            "Redan",
            "Redby",
            "Redding",
            "Redfield",
            "Redfield",
            "Redford",
            "Redgranite",
            "Redington Beach",
            "Redington Shores",
            "Redkey",
            "Redland",
            "Redland",
            "Redlands",
            "Redlands",
            "Redmond",
            "Redmond",
            "Redondo Beach",
            "Redstone Arsenal",
            "Redwater",
            "Redway",
            "Redwood",
            "Redwood",
            "Redwood City",
            "Redwood County",
            "Redwood Falls",
            "Redwood Shores",
            "Redwood Valley",
            "Reed City",
            "Reed Creek",
            "Reedley",
            "Reedsburg",
            "Reedsport",
            "Reedsville",
            "Reese",
            "Reeves County",
            "Reform",
            "Refugio",
            "Refugio County",
            "Rego Park",
            "Rehobeth",
            "Rehoboth",
            "Rehoboth Beach",
            "Reidland",
            "Reidsville",
            "Reidsville",
            "Reiffton",
            "Reinbeck",
            "Reinholds",
            "Reisterstown",
            "Remerton",
            "Reminderville",
            "Remington",
            "Remsen",
            "Remsenburg-Speonk",
            "Rendon",
            "Rennerdale",
            "Reno",
            "Reno",
            "Reno",
            "Reno County",
            "Renovo",
            "Rensselaer",
            "Rensselaer",
            "Rensselaer County",
            "Renton",
            "Renville",
            "Renville County",
            "Renville County",
            "Republic",
            "Republic",
            "Republic",
            "Republic County",
            "Reserve",
            "Reserve",
            "Reston",
            "Revere",
            "Rexburg",
            "Reynolds",
            "Reynolds County",
            "Reynolds Heights",
            "Reynoldsburg",
            "Reynoldsville",
            "Rhea County",
            "Rheems",
            "Rhinebeck",
            "Rhinelander",
            "Rhodhiss",
            "Rhome",
            "Rialto",
            "Rib Mountain",
            "Ricardo",
            "Rice",
            "Rice County",
            "Rice County",
            "Rice Lake",
            "Rich County",
            "Rich Hill",
            "Richardson",
            "Richardson County",
            "Richboro",
            "Richfield",
            "Richfield",
            "Richfield",
            "Richfield",
            "Richfield Springs",
            "Richford",
            "Richgrove",
            "Richland",
            "Richland",
            "Richland",
            "Richland",
            "Richland",
            "Richland",
            "Richland Center",
            "Richland County",
            "Richland County",
            "Richland County",
            "Richland County",
            "Richland County",
            "Richland County",
            "Richland Hills",
            "Richland Parish",
            "Richlands",
            "Richlands",
            "Richlandtown",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond",
            "Richmond County",
            "Richmond County",
            "Richmond County",
            "Richmond County",
            "Richmond Heights",
            "Richmond Heights",
            "Richmond Heights",
            "Richmond Hill",
            "Richmond Hill",
            "Richmond West",
            "Richton",
            "Richton Park",
            "Richville",
            "Richwood",
            "Richwood",
            "Richwood",
            "Richwood",
            "Richwood",
            "Riddle",
            "Ridge",
            "Ridge Manor",
            "Ridge Wood Heights",
            "Ridgecrest",
            "Ridgecrest",
            "Ridgefield",
            "Ridgefield",
            "Ridgefield",
            "Ridgefield Park",
            "Ridgeland",
            "Ridgeland",
            "Ridgely",
            "Ridgely",
            "Ridgemark",
            "Ridgetop",
            "Ridgeville",
            "Ridgeway",
            "Ridgewood",
            "Ridgewood",
            "Ridgway",
            "Ridley Park",
            "Riesel",
            "Rifle",
            "Rigby",
            "Riley County",
            "Rincón",
            "Rincon",
            "Rincón Municipio",
            "Rindge",
            "Ringgold",
            "Ringgold",
            "Ringgold County",
            "Ringling",
            "Ringwood",
            "Rio",
            "Rio Arriba County",
            "Río Blanco",
            "Rio Blanco County",
            "Rio Bravo",
            "Río Cañas Abajo",
            "Rio Communities",
            "Rio Del Mar",
            "Rio Dell",
            "Río Grande",
            "Rio Grande",
            "Rio Grande City",
            "Rio Grande County",
            "Río Grande Municipio",
            "Rio Hondo",
            "Rio Lajas",
            "Rio Linda",
            "Río Piedras",
            "Rio Rancho",
            "Rio Rico",
            "Rio Verde",
            "Rio Vista",
            "Ripley",
            "Ripley",
            "Ripley",
            "Ripley",
            "Ripley County",
            "Ripley County",
            "Ripon",
            "Ripon",
            "Rising Sun",
            "Rising Sun",
            "Rising Sun-Lebanon",
            "Rison",
            "Ritchie County",
            "Rittman",
            "Ritzville",
            "Riva",
            "River Bend",
            "River Edge",
            "River Falls",
            "River Forest",
            "River Grove",
            "River Heights",
            "River Hills",
            "River Oaks",
            "River Park",
            "River Ridge",
            "River Road",
            "River Rouge",
            "River Vale",
            "River View Park",
            "Riverbank",
            "Riverbend",
            "Riverdale",
            "Riverdale",
            "Riverdale",
            "Riverdale",
            "Riverdale",
            "Riverdale",
            "Riverdale Park",
            "Riverdale Park",
            "Riverhead",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside",
            "Riverside County",
            "Riverton",
            "Riverton",
            "Riverton",
            "Riverton",
            "Riverton",
            "Riverview",
            "Riverview",
            "Riverview",
            "Riverview",
            "Riverwoods",
            "Riviera Beach",
            "Riviera Beach",
            "Roaming Shores",
            "Roan Mountain",
            "Roane County",
            "Roane County",
            "Roanoke",
            "Roanoke",
            "Roanoke",
            "Roanoke",
            "Roanoke",
            "Roanoke County",
            "Roanoke Rapids",
            "Roaring Spring",
            "Robbins",
            "Robbins",
            "Robbinsdale",
            "Robbinsville",
            "Robbinsville",
            "Robersonville",
            "Robert Lee",
            "Roberts",
            "Roberts County",
            "Roberts County",
            "Robertsdale",
            "Robertson County",
            "Robertson County",
            "Robertson County",
            "Robertsville",
            "Robeson County",
            "Robesonia",
            "Robins",
            "Robins Air Force Base",
            "Robinson",
            "Robinson",
            "Robinwood",
            "Robstown",
            "Roby",
            "Rochelle",
            "Rochelle",
            "Rochelle Park",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester",
            "Rochester Hills",
            "Rock County",
            "Rock County",
            "Rock County",
            "Rock Creek",
            "Rock Creek",
            "Rock Falls",
            "Rock Hall",
            "Rock Hill",
            "Rock Hill",
            "Rock Hill",
            "Rock Island",
            "Rock Island",
            "Rock Island County",
            "Rock Port",
            "Rock Rapids",
            "Rock Springs",
            "Rock Valley",
            "Rockaway",
            "Rockaway Beach",
            "Rockaway Point",
            "Rockbridge County",
            "Rockcastle County",
            "Rockcreek",
            "Rockdale",
            "Rockdale",
            "Rockdale County",
            "Rockfish",
            "Rockford",
            "Rockford",
            "Rockford",
            "Rockford",
            "Rockford",
            "Rockingham",
            "Rockingham",
            "Rockingham County",
            "Rockingham County",
            "Rockingham County",
            "Rockland",
            "Rockland",
            "Rockland County",
            "Rockledge",
            "Rockledge",
            "Rocklin",
            "Rockmart",
            "Rockport",
            "Rockport",
            "Rockport",
            "Rockport",
            "Rocksprings",
            "Rockton",
            "Rockville",
            "Rockville",
            "Rockville",
            "Rockville",
            "Rockville Centre",
            "Rockwall",
            "Rockwall County",
            "Rockwell",
            "Rockwell",
            "Rockwell",
            "Rockwell City",
            "Rockwood",
            "Rockwood",
            "Rocky Ford",
            "Rocky Mount",
            "Rocky Mount",
            "Rocky Point",
            "Rocky Point",
            "Rocky Point",
            "Rocky River",
            "Rocky Top",
            "Rodeo",
            "Rodney Village",
            "Roebling",
            "Roebuck",
            "Roeland Park",
            "Roessleville",
            "Roger Mills County",
            "Rogers",
            "Rogers",
            "Rogers",
            "Rogers City",
            "Rogers County",
            "Rogers Park",
            "Rogersville",
            "Rogersville",
            "Rogersville",
            "Rogue River",
            "Rohnert Park",
            "Roland",
            "Roland",
            "Rolesville",
            "Rolette County",
            "Rolla",
            "Rolla",
            "Rolling Fork",
            "Rolling Hills",
            "Rolling Hills Estates",
            "Rolling Meadows",
            "Rollingwood",
            "Rollingwood",
            "Rollinsford",
            "Roma",
            "Roma-Los Saenz",
            "Roman Forest",
            "Rome",
            "Rome",
            "Rome",
            "Rome",
            "Rome",
            "Rome City",
            "Romeo",
            "Romeoville",
            "Romney",
            "Romoland",
            "Romulus",
            "Ronan",
            "Ronceverte",
            "Ronkonkoma",
            "Roodhouse",
            "Rooks County",
            "Roosevelt",
            "Roosevelt",
            "Roosevelt County",
            "Roosevelt County",
            "Roosevelt Gardens",
            "Roosevelt Park",
            "Rosa Sanchez",
            "Rosamond",
            "Rosaryville",
            "Roscoe",
            "Roscoe",
            "Roscommon",
            "Roscommon County",
            "Rose Hill",
            "Rose Hill",
            "Rose Hill",
            "Rose Lodge",
            "Roseau",
            "Roseau County",
            "Rosebank",
            "Roseboro",
            "Rosebud",
            "Rosebud",
            "Rosebud County",
            "Roseburg",
            "Roseburg North",
            "Rosedale",
            "Rosedale",
            "Rosedale",
            "Rosedale",
            "Rosedale",
            "Roseland",
            "Roseland",
            "Roseland",
            "Roseland",
            "Roselawn",
            "Roselle",
            "Roselle",
            "Roselle Park",
            "Rosemead",
            "Rosemont",
            "Rosemont",
            "Rosemount",
            "Rosemount",
            "Rosenberg",
            "Rosendale",
            "Rosendale Village",
            "Rosenhayn",
            "Rosepine",
            "Roseto",
            "Roseville",
            "Roseville",
            "Roseville",
            "Roseville",
            "Rosewood Heights",
            "Rosharon",
            "Rosiclare",
            "Rosita North",
            "Rosita South",
            "Roslyn",
            "Roslyn Estates",
            "Roslyn Harbor",
            "Roslyn Heights",
            "Ross",
            "Ross",
            "Ross County",
            "Rossford",
            "Rosslyn",
            "Rossmoor",
            "Rossmoor",
            "Rossmoor",
            "Rossmoyne",
            "Rossville",
            "Rossville",
            "Rossville",
            "Rossville",
            "Rossville",
            "Rossville",
            "Roswell",
            "Roswell",
            "Rotan",
            "Rothschild",
            "Rothsville",
            "Rotonda West",
            "Rotterdam",
            "Round Lake",
            "Round Lake Beach",
            "Round Lake Heights",
            "Round Lake Park",
            "Round Rock",
            "Roundup",
            "Rouses Point",
            "Routt County",
            "Rowan County",
            "Rowan County",
            "Rowland",
            "Rowland Heights",
            "Rowlett",
            "Rowley",
            "Roxana",
            "Roxboro",
            "Roxborough Park",
            "Roxbury",
            "Roy",
            "Royal City",
            "Royal Kunia",
            "Royal Oak",
            "Royal Palm Beach",
            "Royal Palm Estates",
            "Royal Pines",
            "Royalston",
            "Royalton",
            "Royalton",
            "Royalton",
            "Royersford",
            "Royse City",
            "Royston",
            "Rubidoux",
            "Ruckersville",
            "Rugby",
            "Ruidoso",
            "Ruidoso Downs",
            "Ruleville",
            "Rumford",
            "Rumney",
            "Rumson",
            "Runaway Bay",
            "Runge",
            "Runnels County",
            "Runnemede",
            "Running Springs",
            "Rupert",
            "Rural Hall",
            "Rural Hill",
            "Rural Retreat",
            "Rush City",
            "Rush County",
            "Rush County",
            "Rush Springs",
            "Rushford",
            "Rushmere",
            "Rushville",
            "Rushville",
            "Rushville",
            "Rusk",
            "Rusk County",
            "Rusk County",
            "Ruskin",
            "Russell",
            "Russell",
            "Russell",
            "Russell",
            "Russell County",
            "Russell County",
            "Russell County",
            "Russell County",
            "Russell Springs",
            "Russells Point",
            "Russellton",
            "Russellville",
            "Russellville",
            "Russellville",
            "Russiaville",
            "Rustburg",
            "Ruston",
            "Rutherford",
            "Rutherford",
            "Rutherford",
            "Rutherford College",
            "Rutherford County",
            "Rutherford County",
            "Rutherfordton",
            "Rutland",
            "Rutland",
            "Rutland",
            "Rutland County",
            "Rutledge",
            "Rydal",
            "Rye",
            "Rye",
            "Rye Brook",
            "Ryegate",
            "Ryland Heights",
            "Sabana",
            "Sabana Eneas",
            "Sabana Grande",
            "Sabana Grande Municipio",
            "Sabana Hoyos",
            "Sabana Seca",
            "Sabattus",
            "Sabetha",
            "Sabina",
            "Sabinal",
            "Sabine County",
            "Sabine Parish",
            "Sac City",
            "Sac County",
            "Sacaton",
            "Sachse",
            "Sackets Harbor",
            "Saco",
            "Sacramento",
            "Sacramento County",
            "Saddle Brook",
            "Saddle Brooke",
            "Saddle River",
            "Safety Harbor",
            "Safford",
            "Sag Harbor",
            "Sagadahoc County",
            "Sagamore",
            "Saginaw",
            "Saginaw",
            "Saginaw County",
            "Saginaw Township North",
            "Saguache",
            "Saguache County",
            "Sahuarita",
            "Saint Albans",
            "Saint Albans",
            "Saint Albans",
            "Saint Andrews",
            "Saint Ann",
            "Saint Anne",
            "Saint Ansgar",
            "Saint Anthony",
            "Saint Anthony",
            "Saint Augusta",
            "Saint Augustine",
            "Saint Augustine Beach",
            "Saint Augustine Shores",
            "Saint Augustine South",
            "Saint Bernard",
            "Saint Bernard Parish",
            "Saint Bonaventure",
            "Saint Bonifacius",
            "Saint Charles",
            "Saint Charles",
            "Saint Charles",
            "Saint Charles",
            "Saint Charles",
            "Saint Charles County",
            "Saint Charles Parish",
            "Saint Clair",
            "Saint Clair",
            "Saint Clair",
            "Saint Clair County",
            "Saint Clair County",
            "Saint Clair County",
            "Saint Clair County",
            "Saint Clair Shores",
            "Saint Clairsville",
            "Saint Cloud",
            "Saint Cloud",
            "Saint Croix County",
            "Saint Croix Falls",
            "Saint David",
            "Saint Dennis",
            "Saint Elmo",
            "Saint Francis",
            "Saint Francis",
            "Saint Francis",
            "Saint Francis County",
            "Saint Francisville",
            "Saint Francois County",
            "Saint Gabriel",
            "Saint George",
            "Saint George",
            "Saint George",
            "Saint George",
            "Saint George",
            "Saint Hedwig",
            "Saint Helen",
            "Saint Helena",
            "Saint Helena Parish",
            "Saint Helens",
            "Saint Henry",
            "Saint Ignace",
            "Saint Jacob",
            "Saint James",
            "Saint James",
            "Saint James",
            "Saint James",
            "Saint James",
            "Saint James City",
            "Saint James Parish",
            "Saint Jo",
            "Saint John",
            "Saint John",
            "Saint John",
            "Saint John the Baptist Parish",
            "Saint Johns",
            "Saint Johns",
            "Saint Johns",
            "Saint Johns County",
            "Saint Johnsbury",
            "Saint Johnsville",
            "Saint Joseph",
            "Saint Joseph",
            "Saint Joseph",
            "Saint Joseph",
            "Saint Joseph",
            "Saint Joseph County",
            "Saint Joseph County",
            "Saint Landry Parish",
            "Saint Lawrence",
            "Saint Leo",
            "Saint Louis",
            "Saint Louis County",
            "Saint Louis County",
            "Saint Louis Park",
            "Saint Lucie County",
            "Saint Maries",
            "Saint Martin",
            "Saint Martin Parish",
            "Saint Martins",
            "Saint Martinville",
            "Saint Mary Parish",
            "Saint Mary's County",
            "Saint Marys",
            "Saint Marys",
            "Saint Marys",
            "Saint Marys",
            "Saint Matthews",
            "Saint Matthews",
            "Saint Michael",
            "Saint Michaels",
            "Saint Michaels",
            "Saint Paris",
            "Saint Paul",
            "Saint Paul",
            "Saint Paul",
            "Saint Paul",
            "Saint Paul",
            "Saint Paul Park",
            "Saint Pauls",
            "Saint Pete Beach",
            "Saint Peter",
            "Saint Peter",
            "Saint Peters",
            "Saint Regis Park",
            "Saint Robert",
            "Saint Rose",
            "Saint Simon Mills",
            "Saint Simons Island",
            "Saint Stephen",
            "Saint Stephens",
            "Saint Tammany Parish",
            "Sainte Genevieve",
            "Sainte Genevieve County",
            "Saks",
            "Salado",
            "Salamanca",
            "Salcha",
            "Sale Creek",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem",
            "Salem County",
            "Salem Heights",
            "Salida",
            "Salida",
            "Salina",
            "Salina",
            "Salina",
            "Salinas",
            "Salinas",
            "Saline",
            "Saline County",
            "Saline County",
            "Saline County",
            "Saline County",
            "Saline County",
            "Salineville",
            "Salisbury",
            "Salisbury",
            "Salisbury",
            "Salisbury",
            "Salisbury",
            "Salisbury",
            "Salisbury",
            "Salix",
            "Sallisaw",
            "Salmon",
            "Salmon Brook",
            "Salmon Creek",
            "Salome",
            "Salt Lake City",
            "Salt Lake County",
            "Saltillo",
            "Salton City",
            "Saltville",
            "Saluda",
            "Saluda",
            "Saluda County",
            "Salunga",
            "Salyersville",
            "Sam Rayburn",
            "Sammamish",
            "Samoset",
            "Sampson County",
            "Samson",
            "Samsula-Spruce Creek",
            "San Andreas",
            "San Angelo",
            "San Anselmo",
            "San Antonio",
            "San Antonio",
            "San Antonio",
            "San Antonio Heights",
            "San Augustine",
            "San Augustine County",
            "San Benito",
            "San Benito County",
            "San Bernardino",
            "San Bernardino County",
            "San Bruno",
            "San Carlos",
            "San Carlos",
            "San Carlos",
            "San Carlos Park",
            "San Clemente",
            "San Diego",
            "San Diego",
            "San Diego Country Estates",
            "San Diego County",
            "San Dimas",
            "San Elizario",
            "San Felipe Pueblo",
            "San Fernando",
            "San Francisco",
            "San Gabriel",
            "San Germán Municipio",
            "San Isidro",
            "San Jacinto",
            "San Jacinto County",
            "San Joaquin",
            "San Joaquin County",
            "San Joaquin Hills",
            "San José",
            "San Jose",
            "San Juan",
            "San Juan",
            "San Juan Bautista",
            "San Juan Capistrano",
            "San Juan County",
            "San Juan County",
            "San Juan County",
            "San Juan County",
            "San Leandro",
            "San Leon",
            "San Lorenzo",
            "San Lorenzo",
            "San Luis",
            "San Luis",
            "San Luis Obispo",
            "San Luis Obispo County",
            "San Manuel",
            "San Marcos",
            "San Marcos",
            "San Marino",
            "San Martin",
            "San Mateo",
            "San Mateo County",
            "San Miguel",
            "San Miguel",
            "San Miguel County",
            "San Miguel County",
            "San Pablo",
            "San Pasqual",
            "San Patricio County",
            "San Pedro",
            "San Rafael",
            "San Ramon",
            "San Saba",
            "San Saba County",
            "San Sebastián",
            "San Tan Valley",
            "San Ysidro",
            "Sanatoga",
            "Sanborn",
            "Sanborn",
            "Sanborn County",
            "Sanbornton",
            "Sanbornville",
            "Sand Hill",
            "Sand Lake",
            "Sand Point",
            "Sand Springs",
            "Sandalfoot Cove",
            "Sanders County",
            "Sanderson",
            "Sandersville",
            "Sandia Heights",
            "Sandia Knolls",
            "Sandoval",
            "Sandoval County",
            "Sandown",
            "Sandpoint",
            "Sands Point",
            "Sandston",
            "Sandstone",
            "Sandusky",
            "Sandusky",
            "Sandusky County",
            "Sandwich",
            "Sandwich",
            "Sandwich",
            "Sandy",
            "Sandy",
            "Sandy",
            "Sandy Hills",
            "Sandy Hook",
            "Sandy Springs",
            "Sandy Valley",
            "Sanford",
            "Sanford",
            "Sanford",
            "Sangamon County",
            "Sangaree",
            "Sanger",
            "Sanger",
            "Sangerville",
            "Sanibel",
            "Sanilac County",
            "Sanpete County",
            "Sans Souci",
            "Sansom Park",
            "Santa Ana",
            "Santa Anna",
            "Santa Barbara",
            "Santa Barbara",
            "Santa Barbara County",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara",
            "Santa Clara County",
            "Santa Clara Pueblo",
            "Santa Clarita",
            "Santa Claus",
            "Santa Cruz",
            "Santa Cruz County",
            "Santa Cruz County",
            "Santa Fe",
            "Santa Fe",
            "Santa Fe County",
            "Santa Fe Springs",
            "Santa Isabel",
            "Santa Isabel Municipio",
            "Santa Margarita",
            "Santa Maria",
            "Santa Monica",
            "Santa Paula",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa",
            "Santa Rosa County",
            "Santa Susana",
            "Santa Teresa",
            "Santa Venetia",
            "Santa Ynez",
            "Santaquin",
            "Santee",
            "Santo Domingo",
            "Santo Domingo Pueblo",
            "Santurce",
            "Sappington",
            "Sapulpa",
            "Saraland",
            "Saranac",
            "Saranac Lake",
            "Saranap",
            "Sarasota",
            "Sarasota County",
            "Sarasota Springs",
            "Saratoga",
            "Saratoga",
            "Saratoga County",
            "Saratoga Springs",
            "Saratoga Springs",
            "Sarcoxie",
            "Sardis",
            "Sardis",
            "Sardis City",
            "Sargent County",
            "Sarita",
            "Sarpy County",
            "Sartell",
            "Satanta",
            "Satellite Beach",
            "Saticoy",
            "Satsuma",
            "Saucier",
            "Saugerties",
            "Saugerties South",
            "Saugus",
            "Sauk Centre",
            "Sauk City",
            "Sauk County",
            "Sauk Rapids",
            "Sauk Village",
            "Saukville",
            "Sault Ste. Marie",
            "Saunders County",
            "Sausalito",
            "Savage",
            "Savage",
            "Savanna",
            "Savannah",
            "Savannah",
            "Savannah",
            "Savannah",
            "Savoy",
            "Saw Creek",
            "Sawgrass",
            "Sawmills",
            "Sawyer County",
            "Sawyerwood",
            "Saxapahaw",
            "Saxon",
            "Saxonburg",
            "Saybrook Manor",
            "Saylorsburg",
            "Saylorville",
            "Sayre",
            "Sayre",
            "Sayreville",
            "Sayreville Junction",
            "Sayville",
            "Scaggsville",
            "Scandia",
            "Scappoose",
            "Scarborough",
            "Scarsdale",
            "Scenic",
            "Scenic Oaks",
            "Schall Circle",
            "Schaumburg",
            "Schenectady",
            "Schenectady County",
            "Schererville",
            "Schertz",
            "Schiller Park",
            "Schleicher County",
            "Schley County",
            "Schlusser",
            "Schnecksville",
            "Schoeneck",
            "Schofield",
            "Schofield Barracks",
            "Schoharie",
            "Schoharie County",
            "Schoolcraft",
            "Schoolcraft County",
            "Schriever",
            "Schulenburg",
            "Schuyler",
            "Schuyler County",
            "Schuyler County",
            "Schuyler County",
            "Schuylerville",
            "Schuylkill County",
            "Schuylkill Haven",
            "Schwenksville",
            "Scioto County",
            "Sciotodale",
            "Scissors",
            "Scituate",
            "Scobey",
            "Scotch Plains",
            "Scotchtown",
            "Scotia",
            "Scotland",
            "Scotland County",
            "Scotland County",
            "Scotland Neck",
            "Scott",
            "Scott Air Force Base",
            "Scott City",
            "Scott City",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott County",
            "Scott Lake",
            "Scottdale",
            "Scottdale",
            "Scotts Bluff County",
            "Scotts Mill",
            "Scotts Valley",
            "Scottsbluff",
            "Scottsboro",
            "Scottsburg",
            "Scottsdale",
            "Scottsville",
            "Scottsville",
            "Scottville",
            "Scranton",
            "Screven County",
            "Scurry County",
            "Sea Breeze",
            "Sea Bright",
            "Sea Cliff",
            "Sea Girt",
            "Sea Isle City",
            "Sea Ranch",
            "Seabeck",
            "Seabrook",
            "Seabrook",
            "Seabrook",
            "Seabrook Farms",
            "Seabrook Island",
            "Seacliff",
            "Seadrift",
            "Seaford",
            "Seaford",
            "Seagate",
            "Seagoville",
            "Seagraves",
            "Seal Beach",
            "Sealy",
            "Searcy",
            "Searcy County",
            "Searingtown",
            "Searles Valley",
            "Searsmont",
            "Seaside",
            "Seaside",
            "Seaside",
            "Seaside",
            "Seaside Heights",
            "Seaside Park",
            "Seat Pleasant",
            "SeaTac",
            "Seattle",
            "Sebastian",
            "Sebastian",
            "Sebastian County",
            "Sebastopol",
            "Sebewaing",
            "Sebree",
            "Sebring",
            "Sebring",
            "Secaucus",
            "Security-Widefield",
            "Sedalia",
            "Sedan",
            "Sedco Hills",
            "Sedgwick",
            "Sedgwick",
            "Sedgwick County",
            "Sedgwick County",
            "Sedona",
            "Sedro-Woolley",
            "Seekonk",
            "Seeley",
            "Seeley Lake",
            "Seelyville",
            "Seffner",
            "Seguin",
            "Selah",
            "Selby",
            "Selby-on-the-Bay",
            "Selbyville",
            "Selden",
            "Selinsgrove",
            "Sellersburg",
            "Sellersville",
            "Sells",
            "Selma",
            "Selma",
            "Selma",
            "Selma",
            "Selmer",
            "Selmont-West Selmont",
            "Seminole",
            "Seminole",
            "Seminole",
            "Seminole County",
            "Seminole County",
            "Seminole County",
            "Seminole Manor",
            "Semmes",
            "Senath",
            "Senatobia",
            "Seneca",
            "Seneca",
            "Seneca",
            "Seneca",
            "Seneca",
            "Seneca County",
            "Seneca County",
            "Seneca Falls",
            "Seneca Knolls",
            "Senoia",
            "Sequatchie County",
            "Sequim",
            "Sequoyah County",
            "Serenada",
            "Sergeant Bluff",
            "Sesser",
            "Setauket-East Setauket",
            "Seth Ward",
            "Seven Corners",
            "Seven Fields",
            "Seven Hills",
            "Seven Lakes",
            "Seven Oaks",
            "Seven Points",
            "Seven Trees",
            "Severance",
            "Severn",
            "Severna Park",
            "Sevier County",
            "Sevier County",
            "Sevier County",
            "Sevierville",
            "Seville",
            "Sewall's Point",
            "Sewanee",
            "Seward",
            "Seward",
            "Seward County",
            "Seward County",
            "Sewaren",
            "Sewickley",
            "Seymour",
            "Seymour",
            "Seymour",
            "Seymour",
            "Seymour",
            "Seymour",
            "Shackelford",
            "Shackelford County",
            "Shackle Island",
            "Shadeland",
            "Shadow Hills",
            "Shady Cove",
            "Shady Hills",
            "Shady Hollow",
            "Shady Shores",
            "Shady Side",
            "Shady Spring",
            "Shadyside",
            "Shafer",
            "Shafter",
            "Shaker Heights",
            "Shakopee",
            "Shallotte",
            "Shallowater",
            "Shamokin",
            "Shamokin Dam",
            "Shamrock",
            "Shandon",
            "Shannon",
            "Shannon",
            "Shannon County",
            "Shannon Hills",
            "Shannondale",
            "Shanor-Northvue",
            "Shapleigh",
            "Shark River Hills",
            "Sharkey County",
            "Sharon",
            "Sharon",
            "Sharon",
            "Sharon",
            "Sharon Hill",
            "Sharon Springs",
            "Sharonville",
            "Sharp County",
            "Sharpes",
            "Sharpsburg",
            "Sharpsburg",
            "Sharpsville",
            "Shasta",
            "Shasta County",
            "Shasta Lake",
            "Shattuck",
            "Shavano Park",
            "Shavertown",
            "Shaw",
            "Shaw",
            "Shaw Heights",
            "Shawano",
            "Shawano County",
            "Shawnee",
            "Shawnee",
            "Shawnee County",
            "Shawnee Hills",
            "Shawnee Land",
            "Shawneetown",
            "Shawsville",
            "Sheboygan",
            "Sheboygan County",
            "Sheboygan Falls",
            "Sheepshead Bay",
            "Sheffield",
            "Sheffield",
            "Sheffield",
            "Sheffield",
            "Sheffield",
            "Sheffield Lake",
            "Shelbina",
            "Shelburn",
            "Shelburne",
            "Shelburne Falls",
            "Shelby",
            "Shelby",
            "Shelby",
            "Shelby",
            "Shelby",
            "Shelby",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelby County",
            "Shelbyville",
            "Shelbyville",
            "Shelbyville",
            "Shelbyville",
            "Shelbyville",
            "Sheldon",
            "Sheldon",
            "Sheldon",
            "Sheldon",
            "Shell Knob",
            "Shell Lake",
            "Shell Point",
            "Shell Rock",
            "Shell Valley",
            "Shelley",
            "Shelter Island",
            "Shelter Island Heights",
            "Shelton",
            "Shelton",
            "Shelton",
            "Shenandoah",
            "Shenandoah",
            "Shenandoah",
            "Shenandoah",
            "Shenandoah",
            "Shenandoah County",
            "Shenandoah Farms",
            "Shenandoah Heights",
            "Shenorock",
            "Shepherd",
            "Shepherd",
            "Shepherdstown",
            "Shepherdsville",
            "Sherborn",
            "Sherburn",
            "Sherburne",
            "Sherburne County",
            "Sheridan",
            "Sheridan",
            "Sheridan",
            "Sheridan",
            "Sheridan",
            "Sheridan",
            "Sheridan",
            "Sheridan County",
            "Sheridan County",
            "Sheridan County",
            "Sheridan County",
            "Sheridan County",
            "Sherman",
            "Sherman",
            "Sherman",
            "Sherman County",
            "Sherman County",
            "Sherman County",
            "Sherman County",
            "Sherman Oaks",
            "Sherrelwood",
            "Sherrill",
            "Sherrills Ford",
            "Sherwood",
            "Sherwood",
            "Sherwood",
            "Sherwood",
            "Sherwood Manor",
            "Sherwood Shores",
            "Shiawassee County",
            "Shields",
            "Shillington",
            "Shiloh",
            "Shiloh",
            "Shiloh",
            "Shiner",
            "Shingle Springs",
            "Shinglehouse",
            "Shingletown",
            "Shinnecock Hills",
            "Shinnston",
            "Ship Bottom",
            "Shippensburg",
            "Shiprock",
            "Shiremanstown",
            "Shirley",
            "Shirley",
            "Shively",
            "Shoal Creek",
            "Shoals",
            "Shoemakersville",
            "Shokan",
            "Shoreacres",
            "Shoreline",
            "Shoreview",
            "Shorewood",
            "Shorewood",
            "Shorewood",
            "Shorewood Forest",
            "Shorewood Hills",
            "Shorewood-Tower Hills-Harbert",
            "Short Hills",
            "Short Pump",
            "Shortsville",
            "Shoshone",
            "Shoshone County",
            "Show Low",
            "Shreve",
            "Shreveport",
            "Shrewsbury",
            "Shrewsbury",
            "Shrewsbury",
            "Shrewsbury",
            "Shrub Oak",
            "Shullsburg",
            "Shutesbury",
            "Sibley",
            "Sibley",
            "Sibley County",
            "Sicklerville",
            "Sidney",
            "Sidney",
            "Sidney",
            "Sidney",
            "Sidney",
            "Sidney",
            "Sidney",
            "Sienna Plantation",
            "Sierra Blanca",
            "Sierra County",
            "Sierra County",
            "Sierra Madre",
            "Sierra View",
            "Sierra Vista",
            "Sierra Vista Southeast",
            "Siesta Acres",
            "Siesta Key",
            "Siesta Shores",
            "Signal Hill",
            "Signal Mountain",
            "Sigourney",
            "Sikeston",
            "Siler City",
            "Siletz",
            "Siloam Springs",
            "Silsbee",
            "Silt",
            "Silver Bay",
            "Silver Bow County",
            "Silver City",
            "Silver Creek",
            "Silver Firs",
            "Silver Grove",
            "Silver Hill",
            "Silver Lake",
            "Silver Lake",
            "Silver Lake",
            "Silver Lake",
            "Silver Lake",
            "Silver Lake",
            "Silver Lakes",
            "Silver Ridge",
            "Silver Spring",
            "Silver Springs",
            "Silver Springs",
            "Silver Springs Shores",
            "Silver Summit",
            "Silverdale",
            "Silverthorne",
            "Silverton",
            "Silverton",
            "Silverton",
            "Silverton",
            "Silvis",
            "Simi Valley",
            "Simmesport",
            "Simonton Lake",
            "Simpson",
            "Simpson County",
            "Simpson County",
            "Simpsonville",
            "Simpsonville",
            "Simsbury Center",
            "Singac",
            "Sinking Spring",
            "Sinton",
            "Sioux Center",
            "Sioux City",
            "Sioux County",
            "Sioux County",
            "Sioux County",
            "Sioux Falls",
            "Siren",
            "Sisco Heights",
            "Siskiyou County",
            "Sisseton",
            "Sissonville",
            "Sisters",
            "Sistersville",
            "Sitka",
            "Sitka City and Borough",
            "Six Shooter Canyon",
            "Sixmile Run",
            "Sixteen Mile Stand",
            "Skagit County",
            "Skagway Municipality",
            "Skamania County",
            "Skaneateles",
            "Skiatook",
            "Skidaway Island",
            "Skidway Lake",
            "Skippack",
            "Skippers Corner",
            "Skokie",
            "Skowhegan",
            "Sky Lake",
            "Sky Valley",
            "Skyline Acres",
            "Skyline View",
            "Skyline-Ganipa",
            "Slater",
            "Slater",
            "Slater-Marietta",
            "Slatington",
            "Slaton",
            "Slaughterville",
            "Slayton",
            "Sleepy Eye",
            "Sleepy Hollow",
            "Sleepy Hollow",
            "Sleepy Hollow",
            "Sleepy Hollow",
            "Slidell",
            "Slinger",
            "Slippery Rock",
            "Sloan",
            "Sloatsburg",
            "Slocomb",
            "Slope County",
            "Smackover",
            "Smethport",
            "Smith",
            "Smith Center",
            "Smith County",
            "Smith County",
            "Smith County",
            "Smith County",
            "Smith Mills",
            "Smith Valley",
            "Smithfield",
            "Smithfield",
            "Smithfield",
            "Smithfield",
            "Smithland",
            "Smiths Station",
            "Smithsburg",
            "Smithton",
            "Smithtown",
            "Smithville",
            "Smithville",
            "Smithville",
            "Smithville",
            "Smithville",
            "Smithville-Sanders",
            "Smoke Rise",
            "Smokey Point",
            "Smyrna",
            "Smyrna",
            "Smyrna",
            "Smyth County",
            "Sneads",
            "Sneads Ferry",
            "Sneedville",
            "Snellville",
            "Snohomish",
            "Snohomish County",
            "Snoqualmie",
            "Snow Hill",
            "Snow Hill",
            "Snowflake",
            "Snowmass Village",
            "Snyder",
            "Snyder",
            "Snyder County",
            "Snyderville",
            "Soap Lake",
            "Socastee",
            "Social Circle",
            "Society Hill",
            "Socorro",
            "Socorro",
            "Socorro County",
            "Socorro Mission Number 1 Colonia",
            "Soda Bay",
            "Soda Springs",
            "Soddy-Daisy",
            "Sodus",
            "Solana Beach",
            "Solano County",
            "Soldotna",
            "Soledad",
            "Solomon",
            "Solomons",
            "Solon",
            "Solon",
            "Solvang",
            "Solvay",
            "Somerdale",
            "Somers",
            "Somers",
            "Somers",
            "Somers Point",
            "Somerset",
            "Somerset",
            "Somerset",
            "Somerset",
            "Somerset",
            "Somerset",
            "Somerset",
            "Somerset",
            "Somerset County",
            "Somerset County",
            "Somerset County",
            "Somerset County",
            "Somersworth",
            "Somerton",
            "Somervell County",
            "Somerville",
            "Somerville",
            "Somerville",
            "Somerville",
            "Somonauk",
            "Sonoma",
            "Sonoma County",
            "Sonora",
            "Sonora",
            "Soperton",
            "Sophia",
            "Soquel",
            "Sorrento",
            "Sorrento Valley",
            "Souderton",
            "Soulsbyville",
            "Sound Beach",
            "Sour Lake",
            "South Alamo",
            "South Amboy",
            "South Amherst",
            "South Amherst",
            "South Apopka",
            "South Ashburnham",
            "South Barre",
            "South Barrington",
            "South Bay",
            "South Beach",
            "South Beach",
            "South Bel Air",
            "South Belmar",
            "South Beloit",
            "South Bend",
            "South Bend",
            "South Berwick",
            "South Bloomfield",
            "South Blooming Grove",
            "South Boston",
            "South Boston",
            "South Bound Brook",
            "South Bradenton",
            "South Brooksville",
            "South Browning",
            "South Burlington",
            "South Canal",
            "South Carthage",
            "South Charleston",
            "South Charleston",
            "South Chicago",
            "South Chicago Heights",
            "South Cleveland",
            "South Coatesville",
            "South Congaree",
            "South Connellsville",
            "South Corning",
            "South Coventry",
            "South Daytona",
            "South Deerfield",
            "South Dennis",
            "South Dos Palos",
            "South Duxbury",
            "South El Monte",
            "South Elgin",
            "South Eliot",
            "South Euclid",
            "South Fallsburg",
            "South Farmingdale",
            "South Floral Park",
            "South Fulton",
            "South Gastonia",
            "South Gate",
            "South Gate",
            "South Gate Ridge",
            "South Glens Falls",
            "South Greeley",
            "South Greensburg",
            "South Gull Lake",
            "South Hadley",
            "South Haven",
            "South Haven",
            "South Hempstead",
            "South Henderson",
            "South Highpoint",
            "South Hill",
            "South Hill",
            "South Hill",
            "South Holland",
            "South Hooksett",
            "South Houston",
            "South Huntington",
            "South Hutchinson",
            "South Jacksonville",
            "South Jordan",
            "South Jordan Heights",
            "South Kensington",
            "South Kingstown",
            "South Lake Tahoe",
            "South Lancaster",
            "South Laurel",
            "South Lawndale",
            "South Lebanon",
            "South Lebanon",
            "South Lockport",
            "South Lyon",
            "South Miami",
            "South Miami Heights",
            "South Milwaukee",
            "South Monroe",
            "South Nyack",
            "South Ogden",
            "South Old Bridge",
            "South Orange",
            "South Oroville",
            "South Padre Island",
            "South Palm Beach",
            "South Paris",
            "South Park",
            "South Park Township",
            "South Pasadena",
            "South Pasadena",
            "South Patrick Shores",
            "South Peabody",
            "South Pekin",
            "South Pittsburg",
            "South Plainfield",
            "South Point",
            "South Point",
            "South Portland",
            "South Portland Gardens",
            "South Pottstown",
            "South Riding",
            "South River",
            "South Rockwood",
            "South Rosemary",
            "South Roxana",
            "South Russell",
            "South Saint Paul",
            "South Salt Lake",
            "South San Francisco",
            "South San Gabriel",
            "South San Jose Hills",
            "South Sanford",
            "South Sarasota",
            "South Shore",
            "South Shore",
            "South Sioux City",
            "South Suffolk",
            "South Sumter",
            "South Taft",
            "South Temple",
            "South Thomaston",
            "South Toms River",
            "South Tucson",
            "South Uniontown",
            "South Vacherie",
            "South Valley",
            "South Valley Stream",
            "South Venice",
            "South Vineland",
            "South Waverly",
            "South Weber",
            "South Wenatchee",
            "South Whitley",
            "South Whittier",
            "South Willard",
            "South Williamsport",
            "South Windham",
            "South Windham",
            "South Windsor",
            "South Woodstock",
            "South Yarmouth",
            "South Yuba City",
            "South Zanesville",
            "Southampton",
            "Southampton",
            "Southampton County",
            "Southaven",
            "Southborough",
            "Southbridge",
            "Southbury",
            "Southchase",
            "Southeast Arcadia",
            "Southeast Fairbanks Census Area",
            "Southern Gateway",
            "Southern Pines",
            "Southern Shops",
            "Southern Shores",
            "Southern View",
            "Southfield",
            "Southgate",
            "Southgate",
            "Southgate",
            "Southglenn",
            "Southlake",
            "Southmayd",
            "Southmont",
            "Southmont",
            "Southold",
            "Southport",
            "Southport",
            "Southport",
            "Southport",
            "Southside",
            "Southside",
            "Southside Place",
            "Southwest Greensburg",
            "Southwest Ranches",
            "Southwick",
            "Southwood Acres",
            "Southworth",
            "Spackenkill",
            "Spalding County",
            "Spanaway",
            "Spangler",
            "Spanish Fork",
            "Spanish Fort",
            "Spanish Lake",
            "Spanish Springs",
            "Sparkill",
            "Sparks",
            "Sparks",
            "Sparks",
            "Sparta",
            "Sparta",
            "Sparta",
            "Sparta",
            "Sparta",
            "Sparta",
            "Sparta",
            "Sparta",
            "Spartanburg",
            "Spartanburg County",
            "Spearfish",
            "Spearman",
            "Speedway",
            "Speers",
            "Spencer",
            "Spencer",
            "Spencer",
            "Spencer",
            "Spencer",
            "Spencer",
            "Spencer",
            "Spencer",
            "Spencer County",
            "Spencer County",
            "Spencerport",
            "Spencerville",
            "Spencerville",
            "Spencerville",
            "Sperry",
            "Spicer",
            "Spindale",
            "Spink County",
            "Spinnerstown",
            "Spirit Lake",
            "Spirit Lake",
            "Spiro",
            "Splendora",
            "Spokane",
            "Spokane County",
            "Spokane Valley",
            "Spooner",
            "Spotswood",
            "Spotsylvania County",
            "Spotsylvania Courthouse",
            "Spring",
            "Spring Arbor",
            "Spring City",
            "Spring City",
            "Spring City",
            "Spring Creek",
            "Spring Glen",
            "Spring Green",
            "Spring Grove",
            "Spring Grove",
            "Spring Grove",
            "Spring Hill",
            "Spring Hill",
            "Spring Hill",
            "Spring Hope",
            "Spring House",
            "Spring Lake",
            "Spring Lake",
            "Spring Lake",
            "Spring Lake Heights",
            "Spring Lake Park",
            "Spring Mount",
            "Spring Park",
            "Spring Ridge",
            "Spring Ridge",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley",
            "Spring Valley Lake",
            "Springboro",
            "Springdale",
            "Springdale",
            "Springdale",
            "Springdale",
            "Springdale",
            "Springdale",
            "Springerville",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield",
            "Springfield Gardens",
            "Springhill",
            "Springs",
            "Springtown",
            "Springvale",
            "Springview",
            "Springville",
            "Springville",
            "Springville",
            "Springville",
            "Springville",
            "Spruce Pine",
            "Spry",
            "Spur",
            "Spurgeon",
            "Spuyten Duyvil",
            "Squaw Valley",
            "St Johnsbury",
            "St. Johns",
            "St. Lawrence County",
            "St. Louis",
            "St. Marys",
            "St. Petersburg",
            "Stacy",
            "Stafford",
            "Stafford",
            "Stafford",
            "Stafford",
            "Stafford County",
            "Stafford County",
            "Stafford Springs",
            "Stagecoach",
            "Stallings",
            "Stallion Springs",
            "Stambaugh Iron River",
            "Stamford",
            "Stamford",
            "Stamford",
            "Stamps",
            "Stanaford",
            "Stanardsville",
            "Stanberry",
            "Standish",
            "Stanfield",
            "Stanfield",
            "Stanford",
            "Stanford",
            "Stanford",
            "Stanhope",
            "Stanislaus County",
            "Stanley",
            "Stanley",
            "Stanley",
            "Stanley",
            "Stanley County",
            "Stanleytown",
            "Stanly County",
            "Stansbury park",
            "Stanton",
            "Stanton",
            "Stanton",
            "Stanton",
            "Stanton",
            "Stanton",
            "Stanton County",
            "Stanton County",
            "Stanwood",
            "Staples",
            "Stapleton",
            "Stapleton",
            "Star",
            "Star City",
            "Star City",
            "Star Valley",
            "Star Valley Ranch",
            "Starbuck",
            "Stark County",
            "Stark County",
            "Stark County",
            "Starke",
            "Starke County",
            "Starksboro",
            "Starkville",
            "Starr County",
            "State Center",
            "State College",
            "State Line",
            "Stateburg",
            "Staten Island",
            "Statenville",
            "Statesboro",
            "Statesville",
            "Statham",
            "Staunton",
            "Staunton",
            "Stayton",
            "Steamboat Springs",
            "Stearns",
            "Stearns County",
            "Stedman",
            "Steele",
            "Steele",
            "Steele",
            "Steele County",
            "Steele County",
            "Steeleville",
            "Steelton",
            "Steelville",
            "Steep Falls",
            "Steger",
            "Steilacoom",
            "Steinhatchee",
            "Steinway",
            "Stella",
            "Stephens City",
            "Stephens County",
            "Stephens County",
            "Stephens County",
            "Stephenson County",
            "Stephenville",
            "Sterling",
            "Sterling",
            "Sterling",
            "Sterling",
            "Sterling",
            "Sterling",
            "Sterling City",
            "Sterling County",
            "Sterling Heights",
            "Sterlington",
            "Stetson",
            "Steuben",
            "Steuben County",
            "Steuben County",
            "Steubenville",
            "Stevens County",
            "Stevens County",
            "Stevens County",
            "Stevens Point",
            "Stevenson",
            "Stevenson",
            "Stevenson Ranch",
            "Stevensville",
            "Stevensville",
            "Stevensville",
            "Stewart County",
            "Stewart County",
            "Stewart Manor",
            "Stewartstown",
            "Stewartville",
            "Stewartville",
            "Stickney",
            "Stigler",
            "Stiles",
            "Stillman Valley",
            "Stillwater",
            "Stillwater",
            "Stillwater",
            "Stillwater County",
            "Stilwell",
            "Stinnett",
            "Stock Island",
            "Stockbridge",
            "Stockbridge",
            "Stockbridge",
            "Stockdale",
            "Stockton",
            "Stockton",
            "Stockton",
            "Stockton",
            "Stockton Springs",
            "Stockville",
            "Stoddard County",
            "Stokes County",
            "Stokesdale",
            "Stone County",
            "Stone County",
            "Stone County",
            "Stone Mountain",
            "Stone Park",
            "Stone Ridge",
            "Stoneboro",
            "Stonecrest",
            "Stonegate",
            "Stoneham",
            "Stoneville",
            "Stonewall",
            "Stonewall",
            "Stonewall County",
            "Stonewood",
            "Stonington",
            "Stony Brook",
            "Stony Creek Mills",
            "Stony Point",
            "Stony Point",
            "Stony Point",
            "Stony Prairie",
            "Stonybrook",
            "Storey County",
            "Storm Lake",
            "Stormstown",
            "Storrs",
            "Story City",
            "Story County",
            "Stottville",
            "Stoughton",
            "Stoughton",
            "Stover",
            "Stow",
            "Stow",
            "Stowe",
            "Stowe",
            "Stowell",
            "Strafford",
            "Strafford",
            "Strafford County",
            "Strasburg",
            "Strasburg",
            "Strasburg",
            "Strasburg",
            "Stratford",
            "Stratford",
            "Stratford",
            "Stratford",
            "Stratford",
            "Stratford",
            "Stratford",
            "Stratham Station",
            "Strathmore",
            "Strathmore",
            "Stratmoor",
            "Strawberry",
            "Strawberry Point",
            "Streamwood",
            "Streator",
            "Streetsboro",
            "Stromsburg",
            "Strong",
            "Strongsville",
            "Stroud",
            "Stroudsburg",
            "Strum",
            "Struthers",
            "Stryker",
            "Stuart",
            "Stuart",
            "Stuart",
            "Stuarts Draft",
            "Studio City",
            "Sturbridge",
            "Sturgeon",
            "Sturgeon Bay",
            "Sturgis",
            "Sturgis",
            "Sturgis",
            "Sturtevant",
            "Stutsman County",
            "Stuttgart",
            "Suamico",
            "Suárez",
            "Sublette",
            "Sublette County",
            "Sublimity",
            "Succasunna",
            "Sudbury",
            "Sudden Valley",
            "Sudley",
            "Suffern",
            "Suffield Depot",
            "Suffolk",
            "Suffolk County",
            "Suffolk County",
            "Sugar City",
            "Sugar Creek",
            "Sugar Grove",
            "Sugar Hill",
            "Sugar Land",
            "Sugarcreek",
            "Sugarcreek",
            "Sugarcreek Police Dept",
            "Sugarland Run",
            "Sugarmill Woods",
            "Suisun",
            "Suitland",
            "Suitland-Silver Hill",
            "Sulligent",
            "Sullivan",
            "Sullivan",
            "Sullivan",
            "Sullivan",
            "Sullivan City",
            "Sullivan County",
            "Sullivan County",
            "Sullivan County",
            "Sullivan County",
            "Sullivan County",
            "Sullivan County",
            "Sullivans Island",
            "Sully County",
            "Sulphur",
            "Sulphur",
            "Sulphur Springs",
            "Sulphur Springs",
            "Sultan",
            "Sumas",
            "Sumidero",
            "Sumiton",
            "Summerdale",
            "Summerfield",
            "Summerfield",
            "Summerland",
            "Summerlin South",
            "Summers County",
            "Summerset",
            "Summerside",
            "Summersville",
            "Summerville",
            "Summerville",
            "Summit",
            "Summit",
            "Summit",
            "Summit",
            "Summit",
            "Summit County",
            "Summit County",
            "Summit County",
            "Summit Hill",
            "Summit Park",
            "Summit View",
            "Sumner",
            "Sumner",
            "Sumner",
            "Sumner County",
            "Sumner County",
            "Sumrall",
            "Sumter",
            "Sumter County",
            "Sumter County",
            "Sumter County",
            "Sumter County",
            "Sun City",
            "Sun City",
            "Sun City Center",
            "Sun City West",
            "Sun Lakes",
            "Sun Prairie",
            "Sun Prairie",
            "Sun Valley",
            "Sun Valley",
            "Sun Valley",
            "Sun Valley",
            "Sun Village",
            "Sunapee",
            "Sunbury",
            "Sunbury",
            "Suncoast Estates",
            "Suncook",
            "Sundance",
            "Sunderland",
            "Sundown",
            "Sunflower",
            "Sunflower County",
            "Sunland",
            "Sunland Park",
            "Sunman",
            "Sunny Isles Beach",
            "Sunnyside",
            "Sunnyside",
            "Sunnyside",
            "Sunnyside",
            "Sunnyside-Tahoe City",
            "Sunnyslope",
            "Sunnyslope",
            "Sunnyvale",
            "Sunnyvale",
            "Sunray",
            "Sunrise",
            "Sunrise Lake",
            "Sunrise Manor",
            "Sunriver",
            "Sunset",
            "Sunset",
            "Sunset",
            "Sunset Beach",
            "Sunset Hills",
            "Sunset Park",
            "Sunshine Ranches",
            "Superior",
            "Superior",
            "Superior",
            "Superior",
            "Superior",
            "Supreme",
            "Suquamish",
            "Surf City",
            "Surf City",
            "Surfside",
            "Surfside Beach",
            "Surgoinsville",
            "Surprise",
            "Surrey",
            "Surry",
            "Surry",
            "Surry County",
            "Surry County",
            "Susanville",
            "Susquehanna",
            "Susquehanna County",
            "Susquehanna Trails",
            "Sussex",
            "Sussex",
            "Sussex",
            "Sussex County",
            "Sussex County",
            "Sussex County",
            "Sutherland",
            "Sutherlin",
            "Sutter",
            "Sutter County",
            "Sutter Creek",
            "Sutton",
            "Sutton",
            "Sutton",
            "Sutton",
            "Sutton County",
            "Sutton-Alpine",
            "Suwanee",
            "Suwannee County",
            "Swain County",
            "Swainsboro",
            "Swampscott",
            "Swannanoa",
            "Swanquarter",
            "Swansboro",
            "Swansea",
            "Swansea",
            "Swanton",
            "Swanton",
            "Swanville",
            "Swanzey",
            "Swarthmore",
            "Swartz",
            "Swartz Creek",
            "Swartzville",
            "Swedesboro",
            "Sweeny",
            "Sweet Grass County",
            "Sweet Home",
            "Sweet Springs",
            "Sweetser",
            "Sweetwater",
            "Sweetwater",
            "Sweetwater",
            "Sweetwater County",
            "Swepsonville",
            "Swift County",
            "Swift Trail Junction",
            "Swisher County",
            "Swissvale",
            "Switzerland County",
            "Swoyersville",
            "Sycamore",
            "Sykesville",
            "Sykesville",
            "Sylacauga",
            "Sylva",
            "Sylvan Beach",
            "Sylvan Lake",
            "Sylvan Springs",
            "Sylvania",
            "Sylvania",
            "Sylvania",
            "Sylvester",
            "Syosset",
            "Syracuse",
            "Syracuse",
            "Syracuse",
            "Syracuse",
            "Syracuse",
            "Tabor City",
            "Tacoma",
            "Tacony",
            "Taft",
            "Taft",
            "Taft",
            "Taft Heights",
            "Taft Mosswood",
            "Taft Southwest (historical)",
            "Tahlequah",
            "Tahoe Vista",
            "Tahoka",
            "Tahoma",
            "Tainter Lake",
            "Takoma Park",
            "Talbot County",
            "Talbot County",
            "Talbotton",
            "Talent",
            "Taliaferro County",
            "Talihina",
            "Tallaboa",
            "Tallaboa Alta",
            "Talladega",
            "Talladega County",
            "Tallahassee",
            "Tallahatchie County",
            "Tallapoosa",
            "Tallapoosa County",
            "Tallassee",
            "Tallmadge",
            "Tallulah",
            "Talmage",
            "Taloga",
            "Talty",
            "Tama",
            "Tama County",
            "Tamalpais Valley",
            "Tamalpais-Homestead Valley",
            "Tamaqua",
            "Tamarac",
            "Tamiami",
            "Tampa",
            "Tamworth",
            "Tanaina",
            "Taney County",
            "Taneytown",
            "Tangelo Park",
            "Tangent",
            "Tangerine",
            "Tangipahoa Parish",
            "Tanglewilde",
            "Tanglewilde-Thompson Place",
            "Tanner",
            "Tannersville",
            "Tanque Verde",
            "Taos",
            "Taos",
            "Taos County",
            "Taos Pueblo",
            "Tappahannock",
            "Tappan",
            "Tara Hills",
            "Tarboro",
            "Tarentum",
            "Tariffville",
            "Tarkio",
            "Tarpey Village",
            "Tarpon Springs",
            "Tarrant",
            "Tarrant County",
            "Tarrytown",
            "Tatamy",
            "Tate County",
            "Tattnall County",
            "Tatum",
            "Taunton",
            "Tavares",
            "Tavernier",
            "Tawas City",
            "Taylor",
            "Taylor",
            "Taylor",
            "Taylor",
            "Taylor",
            "Taylor",
            "Taylor County",
            "Taylor County",
            "Taylor County",
            "Taylor County",
            "Taylor County",
            "Taylor County",
            "Taylor County",
            "Taylor Creek",
            "Taylor Lake Village",
            "Taylor Mill",
            "Taylors",
            "Taylors Falls",
            "Taylorsville",
            "Taylorsville",
            "Taylorsville",
            "Taylorsville",
            "Taylorville",
            "Tazewell",
            "Tazewell",
            "Tazewell County",
            "Tazewell County",
            "Tchula",
            "Tea",
            "Teague",
            "Teaneck",
            "Teaticket",
            "Teays Valley",
            "Tecumseh",
            "Tecumseh",
            "Tecumseh",
            "Tedder",
            "Tega Cay",
            "Tehachapi",
            "Tehama County",
            "Tekamah",
            "Telfair County",
            "Telford",
            "Tell City",
            "Teller County",
            "Tellico Village",
            "Telluride",
            "Temecula",
            "Temelec",
            "Tempe",
            "Tempe Junction",
            "Temperance",
            "Temple",
            "Temple",
            "Temple",
            "Temple",
            "Temple City",
            "Temple Hills",
            "Temple Terrace",
            "Templeton",
            "Templeton",
            "Ten Mile Run",
            "Tenafly",
            "Tenaha",
            "Tenino",
            "Tennessee Ridge",
            "Tennille",
            "Tensas Parish",
            "Tequesta",
            "Terra Alta",
            "Terra Bella",
            "Terra Mar",
            "Terrace Heights",
            "Terrace Heights",
            "Terrace Park",
            "Terramuggus",
            "Terre du Lac",
            "Terre Haute",
            "Terre Haute",
            "Terre Hill",
            "Terrebonne",
            "Terrebonne Parish",
            "Terrell",
            "Terrell County",
            "Terrell County",
            "Terrell Hills",
            "Terry",
            "Terry",
            "Terry County",
            "Terrytown",
            "Terrytown",
            "Terryville",
            "Terryville",
            "Teton County",
            "Teton County",
            "Teton County",
            "Teutopolis",
            "Teviston",
            "Tewksbury",
            "Texanna",
            "Texarkana",
            "Texarkana",
            "Texas City",
            "Texas County",
            "Texas County",
            "Texico",
            "Thatcher",
            "Thayer",
            "Thayer County",
            "The Acreage",
            "The Bronx",
            "The Colony",
            "The Crossings",
            "The Dalles",
            "The Galena Territory",
            "The Hammocks",
            "The Hideout",
            "The Hills",
            "The Meadows",
            "The Pinery",
            "The Plains",
            "The Village",
            "The Village of Indian Hill",
            "The Villages",
            "The Woodlands",
            "Thedford",
            "Theodore",
            "Theresa",
            "Thermal",
            "Thermalito",
            "Thermopolis",
            "Thibodaux",
            "Thief River Falls",
            "Thiells",
            "Thiensville",
            "Third Lake",
            "Thomas",
            "Thomas County",
            "Thomas County",
            "Thomas County",
            "Thomasboro",
            "Thomaston",
            "Thomaston",
            "Thomaston",
            "Thomaston",
            "Thomasville",
            "Thomasville",
            "Thomasville",
            "Thompson",
            "Thompson",
            "Thompson Falls",
            "Thompson's Station",
            "Thompsonville",
            "Thompsonville",
            "Thomson",
            "Thonotosassa",
            "Thoreau",
            "Thorndale",
            "Thorndale",
            "Thornport",
            "Thornton",
            "Thornton",
            "Thornton",
            "Thornton",
            "Thorntown",
            "Thornwood",
            "Thorp",
            "Thorsby",
            "Thousand Oaks",
            "Thousand Palms",
            "Three Forks",
            "Three Lakes",
            "Three Lakes",
            "Three Oaks",
            "Three Oaks",
            "Three Points",
            "Three Rivers",
            "Three Rivers",
            "Three Rivers",
            "Three Rivers",
            "Three Rivers",
            "Three Way",
            "Throckmorton",
            "Throckmorton County",
            "Throgs Neck",
            "Throop",
            "Thunderbolt",
            "Thurmond",
            "Thurmont",
            "Thurston County",
            "Thurston County",
            "Tiburon",
            "Tiburones",
            "Tice",
            "Tichigan",
            "Ticonderoga",
            "Tierra Amarilla",
            "Tierra Buena",
            "Tierra Verde",
            "Tierras Nuevas Poniente",
            "Tieton",
            "Tiffin",
            "Tiffin",
            "Tift County",
            "Tifton",
            "Tigard",
            "Tiger Point",
            "Tigerville",
            "Tiki Island",
            "Tilden",
            "Tillamook",
            "Tillamook County",
            "Tillman County",
            "Tillmans Corner",
            "Tillson",
            "Tilton",
            "Tilton",
            "Tilton-Northfield",
            "Tiltonsville",
            "Timber Lake",
            "Timber Pines",
            "Timberlake",
            "Timberlane",
            "Timberville",
            "Timberwood Park",
            "Timmonsville",
            "Timonium",
            "Timpson",
            "Tinicum",
            "Tinley Park",
            "Tinton Falls",
            "Tioga",
            "Tioga County",
            "Tioga County",
            "Tionesta",
            "Tipp City",
            "Tippah County",
            "Tippecanoe County",
            "Tipton",
            "Tipton",
            "Tipton",
            "Tipton",
            "Tipton",
            "Tipton County",
            "Tipton County",
            "Tiptonville",
            "Tishomingo",
            "Tishomingo County",
            "Titus County",
            "Titusville",
            "Titusville",
            "Tiverton",
            "Tivoli",
            "Toa Alta",
            "Toa Alta Municipio",
            "Toa Baja",
            "Toast",
            "Tobaccoville",
            "Toccoa",
            "Todd County",
            "Todd County",
            "Todd County",
            "Todd Creek",
            "Toftrees",
            "Tok",
            "Toledo",
            "Toledo",
            "Toledo",
            "Toledo",
            "Tolland",
            "Tolland County",
            "Tolleson",
            "Tolono",
            "Toluca",
            "Tom Bean",
            "Tom Green County",
            "Tomah",
            "Tomahawk",
            "Tomball",
            "Tombstone",
            "Tome",
            "Tompkins County",
            "Tompkinsville",
            "Tompkinsville",
            "Toms River",
            "Tonasket",
            "Tonawanda",
            "Tonganoxie",
            "Tonka Bay",
            "Tonkawa",
            "Tonopah",
            "Tontitown",
            "Tonto Basin",
            "Tooele",
            "Tooele County",
            "Tool",
            "Toole County",
            "Toombs County",
            "Topanga",
            "Topaz Ranch Estates",
            "Topeka",
            "Topeka",
            "Toppenish",
            "Topsfield",
            "Topsham",
            "Topton",
            "Toquerville",
            "Tornado",
            "Tornillo",
            "Toro Canyon",
            "Toronto",
            "Torrance",
            "Torrance County",
            "Torrington",
            "Torrington",
            "Tortolita",
            "Totowa",
            "Toughkenamon",
            "Toulon",
            "Towamensing Trails",
            "Towanda",
            "Towanda",
            "Towaoc",
            "Tower City",
            "Tower Lake",
            "Town 'n' Country",
            "Town and Country",
            "Town and Country",
            "Town Creek",
            "Town Line",
            "Towner",
            "Towner County",
            "Towns County",
            "Townsend",
            "Townsend",
            "Townsend",
            "Townshend",
            "Towson",
            "Trabuco Canyon",
            "Tracy",
            "Tracy",
            "Tracy City",
            "Tracyton",
            "Traer",
            "Trafalgar",
            "Trafford",
            "Trail Creek",
            "Traill County",
            "Trainer",
            "Transylvania County",
            "Trappe",
            "Trappe",
            "Travelers Rest",
            "Traverse City",
            "Traverse County",
            "Travilah",
            "Travis County",
            "Travis Ranch",
            "Treasure County",
            "Treasure Island",
            "Treasure Lake",
            "Trego County",
            "Tremont",
            "Tremont",
            "Tremont",
            "Tremont",
            "Tremonton",
            "Trempealeau",
            "Trempealeau County",
            "Trent Woods",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trenton",
            "Trentwood",
            "Treutlen County",
            "Trevorton",
            "Trevose",
            "Trexlertown",
            "Tri-City",
            "Tri-Lakes",
            "Triangle",
            "Tribes Hill",
            "Tribune",
            "Trigg County",
            "Trimble County",
            "Trinidad",
            "Trinity",
            "Trinity",
            "Trinity",
            "Trinity",
            "Trinity County",
            "Trinity County",
            "Trion",
            "Tripoli",
            "Tripp County",
            "Trooper",
            "Trophy Club",
            "Trotwood",
            "Troup",
            "Troup County",
            "Trousdale County",
            "Troutdale",
            "Troutman",
            "Trowbridge Park",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Troy",
            "Truckee",
            "Trucksville",
            "Trujillo Alto",
            "Trujillo Alto Municipio",
            "Truman",
            "Trumann",
            "Trumansburg",
            "Trumbull",
            "Trumbull County",
            "Truro",
            "Trussville",
            "Truth or Consequences",
            "Tryon",
            "Tryon",
            "Tsaile",
            "Tualatin",
            "Tuba City",
            "Tubac",
            "Tuckahoe",
            "Tuckahoe",
            "Tucker",
            "Tucker County",
            "Tuckerman",
            "Tuckerton",
            "Tucson",
            "Tucson Estates",
            "Tucumcari",
            "Tuftonboro",
            "Tujunga",
            "Tukwila",
            "Tulalip",
            "Tulalip Bay",
            "Tulare",
            "Tulare County",
            "Tularosa",
            "Tulia",
            "Tullahoma",
            "Tullytown",
            "Tulsa",
            "Tulsa County",
            "Tumwater",
            "Tunica",
            "Tunica County",
            "Tunica Resorts",
            "Tunkhannock",
            "Tuolumne City",
            "Tuolumne County",
            "Tupelo",
            "Tupper Lake",
            "Turley",
            "Turlock",
            "Turner",
            "Turner",
            "Turner County",
            "Turner County",
            "Turners Falls",
            "Turnersville",
            "Turpin Hills",
            "Turtle Creek",
            "Turtle Lake",
            "Tuscaloosa",
            "Tuscaloosa County",
            "Tuscarawas",
            "Tuscarawas County",
            "Tuscola",
            "Tuscola County",
            "Tusculum",
            "Tuscumbia",
            "Tuscumbia",
            "Tuskegee",
            "Tustin",
            "Tuttle",
            "Tutwiler",
            "Twain Harte",
            "Twentynine Palms",
            "Twiggs County",
            "Twin City",
            "Twin Falls",
            "Twin Falls County",
            "Twin Grove",
            "Twin Lake",
            "Twin Lakes",
            "Twin Lakes",
            "Twin Lakes",
            "Twin Lakes",
            "Twin Lakes",
            "Twin Lakes",
            "Twin Rivers",
            "Twinsburg",
            "Two Harbors",
            "Two Rivers",
            "Tybee Island",
            "Tye",
            "Tyhee",
            "Tyler",
            "Tyler",
            "Tyler County",
            "Tyler County",
            "Tylertown",
            "Tyndall",
            "Tyndall Air Force Base",
            "Tyngsboro",
            "Tyro",
            "Tyrone",
            "Tyrone",
            "Tyrrell County",
            "Tysons Corner",
            "Ucon",
            "Uhland",
            "Uhrichsville",
            "Uinta County",
            "Uintah",
            "Uintah County",
            "Ukiah",
            "Ulster County",
            "Ulysses",
            "Umatilla",
            "Umatilla",
            "Umatilla County",
            "Unadilla",
            "Unadilla",
            "Unalaska",
            "Uncasville",
            "Underwood-Petersville",
            "Unicoi",
            "Unicoi County",
            "Union",
            "Union",
            "Union",
            "Union",
            "Union",
            "Union",
            "Union",
            "Union",
            "Union",
            "Union Beach",
            "Union City",
            "Union City",
            "Union City",
            "Union City",
            "Union City",
            "Union City",
            "Union City",
            "Union City",
            "Union City",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union County",
            "Union Gap",
            "Union Grove",
            "Union Hall",
            "Union Hill-Novelty Hill",
            "Union Parish",
            "Union Park",
            "Union Point",
            "Union Springs",
            "Union Springs",
            "Uniondale",
            "Unionport",
            "Uniontown",
            "Uniontown",
            "Uniontown",
            "Unionville",
            "Unionville",
            "Unionville",
            "Unionville",
            "Unity",
            "Universal City",
            "Universal City",
            "University",
            "University",
            "University Center",
            "University City",
            "University Gardens",
            "University Heights",
            "University Heights",
            "University Heights",
            "University Park",
            "University Park",
            "University Park",
            "University Park",
            "University Park",
            "University Place",
            "Upland",
            "Upland",
            "Upland",
            "Upper Alton",
            "Upper Arlington",
            "Upper Bear Creek",
            "Upper Brookville",
            "Upper Fruitland",
            "Upper Grand Lagoon",
            "Upper Lake",
            "Upper Marlboro",
            "Upper Montclair",
            "Upper Nyack",
            "Upper Pohatcong",
            "Upper Saddle River",
            "Upper Saint Clair",
            "Upper Sandusky",
            "Upshur County",
            "Upshur County",
            "Upson County",
            "Upton",
            "Upton",
            "Upton County",
            "Uptown",
            "Urania",
            "Urbana",
            "Urbana",
            "Urbana",
            "Urbana",
            "Urbancrest",
            "Urbandale",
            "Utah County",
            "Utica",
            "Utica",
            "Utica",
            "Utica",
            "Utuado",
            "Utuado barrio-pueblo",
            "Uvalde",
            "Uvalde County",
            "Uvalde Estates",
            "Uxbridge",
            "Vacaville",
            "Vadnais Heights",
            "Vado",
            "Vail",
            "Vail",
            "Vails Gate",
            "Val Verde",
            "Val Verde County",
            "Val Verde Park",
            "Valatie",
            "Valdese",
            "Valdez",
            "Valdez-Cordova Census Area",
            "Valdosta",
            "Vale",
            "Valencia",
            "Valencia",
            "Valencia County",
            "Valencia West",
            "Valentine",
            "Valhalla",
            "Valinda",
            "Valle Vista",
            "Valle Vista",
            "Vallejo",
            "Valley",
            "Valley",
            "Valley Center",
            "Valley Center",
            "Valley City",
            "Valley Cottage",
            "Valley County",
            "Valley County",
            "Valley County",
            "Valley Falls",
            "Valley Falls",
            "Valley Falls",
            "Valley Glen",
            "Valley Grande",
            "Valley Green",
            "Valley Hill",
            "Valley Mills",
            "Valley Park",
            "Valley Springs",
            "Valley Station",
            "Valley Stream",
            "Valley View",
            "Valley View",
            "Valmeyer",
            "Valparaiso",
            "Valparaiso",
            "Valrico",
            "Vamo",
            "Van",
            "Van Alstyne",
            "Van Buren",
            "Van Buren",
            "Van Buren",
            "Van Buren County",
            "Van Buren County",
            "Van Buren County",
            "Van Buren County",
            "Van Horn",
            "Van Lear",
            "Van Meter",
            "Van Nest",
            "Van Nuys",
            "Van Vleck",
            "Van Wert",
            "Van Wert County",
            "Van Zandt County",
            "Vance",
            "Vance County",
            "Vanceburg",
            "Vancleave",
            "Vancouver",
            "Vandalia",
            "Vandalia",
            "Vandalia",
            "Vandenberg Air Force Base",
            "Vandenberg Village",
            "Vander",
            "Vanderburgh County",
            "Vandercook Lake",
            "Vandergrift",
            "Vandiver",
            "Vardaman",
            "Varnell",
            "Varnville",
            "Vashon",
            "Vassalboro",
            "Vassar",
            "Vázquez",
            "Veazie",
            "Veedersburg",
            "Vega",
            "Vega Alta",
            "Vega Alta Municipio",
            "Vega Baja",
            "Velda Village",
            "Velda Village Hills",
            "Velva",
            "Venango County",
            "Venersborg",
            "Veneta",
            "Venetian Village",
            "Venice",
            "Venice",
            "Venice",
            "Venice Gardens",
            "Ventnor City",
            "Ventura",
            "Ventura County",
            "Venus",
            "Veradale",
            "Verde Village",
            "Verdi",
            "Verdigris",
            "Vergennes",
            "Vermilion",
            "Vermilion County",
            "Vermilion Parish",
            "Vermilion-on-the-Lake",
            "Vermillion",
            "Vermillion County",
            "Vernal",
            "Vernon",
            "Vernon",
            "Vernon",
            "Vernon Center",
            "Vernon County",
            "Vernon County",
            "Vernon Hills",
            "Vernon Parish",
            "Vernon Valley",
            "Vernonia",
            "Vero Beach",
            "Vero Beach South",
            "Verona",
            "Verona",
            "Verona",
            "Verona",
            "Verona",
            "Verona",
            "Verona Walk",
            "Verplanck",
            "Versailles",
            "Versailles",
            "Versailles",
            "Versailles",
            "Versailles",
            "Vestavia Hills",
            "Vevay",
            "Vian",
            "Vicksburg",
            "Vicksburg",
            "Victor",
            "Victor",
            "Victoria",
            "Victoria",
            "Victoria",
            "Victoria",
            "Victoria County",
            "Victorville",
            "Victory Gardens",
            "Victory Lakes",
            "Vidalia",
            "Vidalia",
            "Vidor",
            "Vienna",
            "Vienna",
            "Vienna",
            "Vienna",
            "Vienna",
            "Vienna Bend",
            "Vieques",
            "Vieques Municipality",
            "Viera East",
            "Viera West",
            "View Park-Windsor Hills",
            "Vigo County",
            "Vilas County",
            "Villa Grove",
            "Villa Hills",
            "Villa Park",
            "Villa Park",
            "Villa Rica",
            "Villa Ridge",
            "Village Green",
            "Village Green-Green Ridge",
            "Village of Campton Hills",
            "Village of Oak Creek (Big Park)",
            "Village of the Branch",
            "Village Park",
            "Village Saint George",
            "Village Shires",
            "Villages of Oriole",
            "Villalba",
            "Villalba Municipio",
            "Villano Beach",
            "Villas",
            "Villas",
            "Ville Platte",
            "Villisca",
            "Vilonia",
            "Vinalhaven",
            "Vincennes",
            "Vincent",
            "Vincent",
            "Vincentown",
            "Vinco",
            "Vine Grove",
            "Vine Hill",
            "Vineland",
            "Vineland",
            "Vineyard",
            "Vineyard",
            "Vineyard Haven",
            "Vineyards",
            "Vinings",
            "Vinita",
            "Vinita Park",
            "Vinton",
            "Vinton",
            "Vinton",
            "Vinton",
            "Vinton County",
            "Viola",
            "Violet",
            "Virden",
            "Virginia",
            "Virginia",
            "Virginia Beach",
            "Virginia City",
            "Virginia City",
            "Virginia Gardens",
            "Viroqua",
            "Visalia",
            "Visitacion Valley",
            "Vista",
            "Vista Center",
            "Vista Santa Rosa",
            "Vivian",
            "Volcano",
            "Volga",
            "Volney",
            "Volo",
            "Volusia County",
            "Von Ormy",
            "Vonore",
            "Voorhees",
            "Voorheesville",
            "Wabash",
            "Wabash County",
            "Wabash County",
            "Wabasha",
            "Wabasha County",
            "Wabasso Beach",
            "Wabaunsee County",
            "Waco",
            "Waconia",
            "Wacousta",
            "Wade",
            "Wade Hampton",
            "Wadena",
            "Wadena County",
            "Wadesboro",
            "Wading River",
            "Wadley",
            "Wadsworth",
            "Wadsworth",
            "Waggaman",
            "Wagner",
            "Wagoner",
            "Wagoner County",
            "Wahiawā",
            "Wahkiakum County",
            "Wahneta",
            "Wahoo",
            "Wahpeton",
            "Waialua",
            "Waianae",
            "Waihee-Waiehu",
            "Waikapū",
            "Waikoloa",
            "Wailea",
            "Wailua",
            "Wailua Homesteads",
            "Wailuku",
            "Waimalu",
            "Waimanalo",
            "Waimānalo Beach",
            "Waimea",
            "Wainaku",
            "Waipahu",
            "Waipi‘o Acres",
            "Waipio",
            "Waite Park",
            "Waitsburg",
            "Wakarusa",
            "Wake County",
            "Wake Forest",
            "Wake Village",
            "WaKeeney",
            "Wakefield",
            "Wakefield",
            "Wakefield",
            "Wakefield",
            "Wakefield",
            "Wakefield-Peacedale",
            "Wakeman",
            "Wakulla County",
            "Walbridge",
            "Walcott",
            "Walden",
            "Walden",
            "Walden",
            "Waldo",
            "Waldo",
            "Waldo County",
            "Waldoboro",
            "Waldon",
            "Waldorf",
            "Waldport",
            "Waldron",
            "Waldwick",
            "Wales",
            "Wales",
            "Walford",
            "Walhalla",
            "Walker",
            "Walker",
            "Walker",
            "Walker County",
            "Walker County",
            "Walker County",
            "Walker Mill",
            "Walkersville",
            "Walkerton",
            "Walkertown",
            "Walla Walla",
            "Walla Walla County",
            "Walla Walla East",
            "Wallace",
            "Wallace",
            "Wallace",
            "Wallace County",
            "Wallburg",
            "Walled Lake",
            "Wallenpaupack Lake Estates",
            "Waller",
            "Waller",
            "Waller County",
            "Wallingford",
            "Wallingford Center",
            "Wallington",
            "Wallis",
            "Wallkill",
            "Wallowa County",
            "Walls",
            "Walnut",
            "Walnut",
            "Walnut Cove",
            "Walnut Creek",
            "Walnut Grove",
            "Walnut Grove",
            "Walnut Grove",
            "Walnut Grove",
            "Walnut Hill",
            "Walnut Park",
            "Walnut Ridge",
            "Walnutport",
            "Walpole",
            "Walsenburg",
            "Walsh County",
            "Walterboro",
            "Walters",
            "Walthall",
            "Walthall County",
            "Waltham",
            "Walthourville",
            "Walton",
            "Walton",
            "Walton",
            "Walton County",
            "Walton County",
            "Walton Hills",
            "Walton Park",
            "Walworth",
            "Walworth County",
            "Walworth County",
            "Wamac",
            "Wamego",
            "Wampsville",
            "Wanakah",
            "Wanamassa",
            "Wanamingo",
            "Wanaque",
            "Wanatah",
            "Wanchese",
            "Wantagh",
            "Wapakoneta",
            "Wapato",
            "Wapello",
            "Wapello County",
            "Wappingers Falls",
            "Ward",
            "Ward County",
            "Ward County",
            "Warden",
            "Wardsville",
            "Ware",
            "Ware County",
            "Ware Shoals",
            "Wareham Center",
            "Waretown",
            "Warm Beach",
            "Warm Mineral Springs",
            "Warm Springs",
            "Warm Springs",
            "Warm Springs",
            "Warminster Heights",
            "Warner",
            "Warner Robins",
            "Warr Acres",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren County",
            "Warren Park",
            "Warren Township",
            "Warrensburg",
            "Warrensburg",
            "Warrensburg",
            "Warrensville Heights",
            "Warrenton",
            "Warrenton",
            "Warrenton",
            "Warrenton",
            "Warrenton",
            "Warrenville",
            "Warrenville",
            "Warrick County",
            "Warrington",
            "Warrior",
            "Warroad",
            "Warsaw",
            "Warsaw",
            "Warsaw",
            "Warsaw",
            "Warsaw",
            "Warsaw",
            "Warsaw",
            "Warson Woods",
            "Wartburg",
            "Warwick",
            "Warwick",
            "Wasatch County",
            "Wasco",
            "Wasco",
            "Wasco County",
            "Waseca",
            "Waseca County",
            "Washakie County",
            "Washburn",
            "Washburn",
            "Washburn",
            "Washburn County",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington County",
            "Washington Court House",
            "Washington D.C.",
            "Washington Heights",
            "Washington Mills",
            "Washington Parish",
            "Washington Park",
            "Washington Park",
            "Washington Terrace",
            "Washingtonville",
            "Washita County",
            "Washoe County",
            "Washougal",
            "Washtenaw County",
            "Wasilla",
            "Waskom",
            "Watauga",
            "Watauga County",
            "Watchtower",
            "Watchung",
            "Water Mill",
            "Water Valley",
            "Waterboro",
            "Waterbury",
            "Waterbury",
            "Waterflow",
            "Waterford",
            "Waterford",
            "Waterford",
            "Waterford",
            "Waterford",
            "Waterford",
            "Waterloo",
            "Waterloo",
            "Waterloo",
            "Waterloo",
            "Waterloo",
            "Waterloo",
            "Waterman",
            "Watertown",
            "Watertown",
            "Watertown",
            "Watertown",
            "Watertown",
            "Watertown",
            "Watertown",
            "Watertown",
            "Waterville",
            "Waterville",
            "Waterville",
            "Waterville",
            "Waterville",
            "Watervliet",
            "Watervliet",
            "Watford City",
            "Wathena",
            "Watkins Glen",
            "Watkinsville",
            "Watonga",
            "Watonwan County",
            "Watseka",
            "Watson",
            "Watsontown",
            "Watsonville",
            "Watterson Park",
            "Watts Mills",
            "Wattsville",
            "Wauchula",
            "Wauconda",
            "Waukee",
            "Waukegan",
            "Waukesha",
            "Waukesha County",
            "Waukomis",
            "Waukon",
            "Wauna",
            "Waunakee",
            "Waupaca",
            "Waupaca County",
            "Waupun",
            "Wauregan",
            "Waurika",
            "Wausau",
            "Wauseon",
            "Waushara County",
            "Wautoma",
            "Wauwatosa",
            "Waveland",
            "Waverly",
            "Waverly",
            "Waverly",
            "Waverly",
            "Waverly",
            "Waverly",
            "Waverly",
            "Waverly",
            "Waverly",
            "Wawarsing",
            "Waxahachie",
            "Waxhaw",
            "Waycross",
            "Wayland",
            "Wayland",
            "Wayland",
            "Waymart",
            "Wayne",
            "Wayne",
            "Wayne",
            "Wayne",
            "Wayne",
            "Wayne",
            "Wayne",
            "Wayne City",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne County",
            "Wayne Heights",
            "Waynesboro",
            "Waynesboro",
            "Waynesboro",
            "Waynesboro",
            "Waynesboro",
            "Waynesburg",
            "Waynesville",
            "Waynesville",
            "Waynesville",
            "Wayzata",
            "Weakley County",
            "Weare",
            "Weatherby Lake",
            "Weatherford",
            "Weatherford",
            "Weatherly",
            "Weatogue",
            "Weaver",
            "Weaverville",
            "Weaverville",
            "Webb",
            "Webb City",
            "Webb County",
            "Webberville",
            "Weber City",
            "Weber County",
            "Webster",
            "Webster",
            "Webster",
            "Webster",
            "Webster",
            "Webster City",
            "Webster County",
            "Webster County",
            "Webster County",
            "Webster County",
            "Webster County",
            "Webster County",
            "Webster County",
            "Webster Groves",
            "Webster Parish",
            "Webster Springs",
            "Weddington",
            "Wedgefield",
            "Wedgefield",
            "Wedgewood",
            "Wedowee",
            "Weed",
            "Weedpatch",
            "Weedsport",
            "Weehawken",
            "Weeki Wachee Gardens",
            "Weeping Water",
            "Weigelstown",
            "Weimar",
            "Weirton",
            "Weirton Heights",
            "Weiser",
            "Weissport East",
            "Wekiwa Springs",
            "Welby",
            "Welch",
            "Welcome",
            "Welcome",
            "Weld County",
            "Weldon",
            "Weldon",
            "Weldon Spring",
            "Wellborn",
            "Wellesley",
            "Wellfleet",
            "Wellford",
            "Wellington",
            "Wellington",
            "Wellington",
            "Wellington",
            "Wellington",
            "Wellington",
            "Wellman",
            "Wells",
            "Wells",
            "Wells Beach Station",
            "Wells Branch",
            "Wells County",
            "Wells County",
            "Wellsboro",
            "Wellsburg",
            "Wellston",
            "Wellston",
            "Wellsville",
            "Wellsville",
            "Wellsville",
            "Wellsville",
            "Wellsville",
            "Wellton",
            "Welsh",
            "Wenatchee",
            "Wendell",
            "Wendell",
            "Wendell",
            "Wendover",
            "Wenham",
            "Wenonah",
            "Wentworth",
            "Wentzville",
            "Wernersville",
            "Wescosville",
            "Weslaco",
            "Wesley Chapel",
            "Wesley Chapel",
            "Wesley Hills",
            "Wesleyville",
            "Wessington Springs",
            "Wesson",
            "West",
            "West Albany",
            "West Alexandria",
            "West Allis",
            "West and East Lealman",
            "West Athens",
            "West Babylon",
            "West Baraboo",
            "West Barnstable",
            "West Baton Rouge Parish",
            "West Bay Shore",
            "West Belmar",
            "West Bend",
            "West Bishop",
            "West Blocton",
            "West Bloomfield Township",
            "West Bountiful",
            "West Boylston",
            "West Bradenton",
            "West Branch",
            "West Branch",
            "West Brattleboro",
            "West Bridgewater",
            "West Brookfield",
            "West Buechel",
            "West Burlington",
            "West Canton",
            "West Cape May",
            "West Carroll Parish",
            "West Carrollton City",
            "West Carson",
            "West Carthage",
            "West Chatham",
            "West Chester",
            "West Chicago",
            "West Clarkston-Highland",
            "West Columbia",
            "West Columbia",
            "West Concord",
            "West Conshohocken",
            "West Coon Rapids",
            "West Covina",
            "West Crossett",
            "West DeLand",
            "West Dennis",
            "West Des Moines",
            "West Dundee",
            "West Easton",
            "West Elkridge",
            "West Elmira",
            "West Elsdon",
            "West End",
            "West End-Cobb Town",
            "West Englewood",
            "West Fairview",
            "West Falls Church",
            "West Falmouth",
            "West Fargo",
            "West Feliciana Parish",
            "West Ferriday",
            "West Fork",
            "West Frankfort",
            "West Freehold",
            "West Garfield Park",
            "West Gate",
            "West Gate",
            "West Glendive",
            "West Glens Falls",
            "West Greenwich",
            "West Grove",
            "West Gulfport",
            "West Hamburg",
            "West Hammond",
            "West Hartford",
            "West Hattiesburg",
            "West Haven",
            "West Haven",
            "West Haven",
            "West Haven-Sylvan",
            "West Haverstraw",
            "West Hazleton",
            "West Helena",
            "West Hempstead",
            "West Hill",
            "West Hills",
            "West Hills",
            "West Hills",
            "West Hollywood",
            "West Hollywood",
            "West Homestead",
            "West Hurley",
            "West Ishpeming",
            "West Islip",
            "West Jefferson",
            "West Jefferson",
            "West Jordan",
            "West Kennebunk",
            "West Kittanning",
            "West Lafayette",
            "West Lafayette",
            "West Lake Hills",
            "West Lake Sammamish",
            "West Lake Stevens",
            "West Laurel",
            "West Lawn",
            "West Lawn",
            "West Leechburg",
            "West Liberty",
            "West Liberty",
            "West Liberty",
            "West Liberty",
            "West Linn",
            "West Little River",
            "West Livingston",
            "West Long Branch",
            "West Longview",
            "West Lynchburg",
            "West Marion",
            "West Mayfield",
            "West Melbourne",
            "West Memphis",
            "West Menlo Park",
            "West Miami",
            "West Mifflin",
            "West Milford",
            "West Milton",
            "West Milwaukee",
            "West Modesto",
            "West Monroe",
            "West Monroe",
            "West Mountain",
            "West New York",
            "West Newbury",
            "West Newton",
            "West Norriton",
            "West Nyack",
            "West Ocean City",
            "West Odessa",
            "West Orange",
            "West Orange",
            "West Palm Beach",
            "West Paris",
            "West Park",
            "West Park",
            "West Pasco",
            "West Pensacola",
            "West Peoria",
            "West Perrine",
            "West Pittston",
            "West Plains",
            "West Pleasant View",
            "West Point",
            "West Point",
            "West Point",
            "West Point",
            "West Point",
            "West Point",
            "West Portsmouth",
            "West Puente Valley",
            "West Raleigh",
            "West Rancho Dominguez",
            "West Reading",
            "West Richland",
            "West Ridge",
            "West Rutland",
            "West Sacramento",
            "West Saint Paul",
            "West Salem",
            "West Salem",
            "West Samoset",
            "West Sand Lake",
            "West Sayville",
            "West Scarborough",
            "West Sedona",
            "West Seneca",
            "West Sharyland",
            "West Side Highway",
            "West Simsbury",
            "West Slope",
            "West Springfield",
            "West Springfield",
            "West Stockbridge",
            "West Swanzey",
            "West Tawakoni",
            "West Terre Haute",
            "West Tisbury",
            "West Torrington",
            "West Town",
            "West Union",
            "West Union",
            "West Union",
            "West Unity",
            "West University Place",
            "West Valley",
            "West Valley City",
            "West Vero Corridor",
            "West View",
            "West Wareham",
            "West Warrenton",
            "West Warwick",
            "West Wenatchee",
            "West Wendover",
            "West Whittier-Los Nietos",
            "West Wyoming",
            "West Wyomissing",
            "West Yarmouth",
            "West Yellowstone",
            "West York",
            "Westborough",
            "Westbrook",
            "Westbrook Center",
            "Westbury",
            "Westby",
            "Westchase",
            "Westchester",
            "Westchester",
            "Westchester County",
            "Westcliffe",
            "Westerleigh",
            "Westerly",
            "Western Lake",
            "Western Springs",
            "Westernport",
            "Westerville",
            "Westfield",
            "Westfield",
            "Westfield",
            "Westfield",
            "Westfield",
            "Westfield",
            "Westfield Center",
            "Westford",
            "Westhampton",
            "Westhampton",
            "Westhampton Beach",
            "Westhaven-Moonstone",
            "Westlake",
            "Westlake",
            "Westlake",
            "Westlake Village",
            "Westland",
            "Westmere",
            "Westminster",
            "Westminster",
            "Westminster",
            "Westminster",
            "Westminster",
            "Westminster",
            "Westmont",
            "Westmont",
            "Westmont",
            "Westmoreland",
            "Westmoreland",
            "Westmoreland",
            "Westmoreland County",
            "Westmoreland County",
            "Westmorland",
            "Weston",
            "Weston",
            "Weston",
            "Weston",
            "Weston",
            "Weston",
            "Weston",
            "Weston County",
            "Weston Lakes",
            "Weston Mills",
            "Westover",
            "Westover",
            "Westphalia",
            "Westport",
            "Westport",
            "Westport",
            "Westport",
            "Westvale",
            "Westview",
            "Westville",
            "Westville",
            "Westville",
            "Westville",
            "Westway",
            "Westwego",
            "Westwood",
            "Westwood",
            "Westwood",
            "Westwood",
            "Westwood",
            "Westwood",
            "Westwood Lake",
            "Westworth",
            "Wetherington",
            "Wethersfield",
            "Wetumka",
            "Wetumpka",
            "Wetzel County",
            "Wewahitchka",
            "Weweantic",
            "Wewoka",
            "Wexford County",
            "Weyauwega",
            "Weyers Cave",
            "Weymouth",
            "Wharton",
            "Wharton",
            "Wharton",
            "Wharton County",
            "Whatcom County",
            "Whately",
            "Wheat Ridge",
            "Wheatland",
            "Wheatland",
            "Wheatland County",
            "Wheatley Heights",
            "Wheaton",
            "Wheaton",
            "Wheaton",
            "Wheeler",
            "Wheeler County",
            "Wheeler County",
            "Wheeler County",
            "Wheeler County",
            "Wheelersburg",
            "Wheeling",
            "Wheeling",
            "Whetstone",
            "Whiskey Creek",
            "Whispering Pines",
            "Whitaker",
            "White Bear Lake",
            "White Bluff",
            "White Castle",
            "White Center",
            "White City",
            "White City",
            "White City",
            "White Cloud",
            "White County",
            "White County",
            "White County",
            "White County",
            "White County",
            "White Hall",
            "White Hall",
            "White Haven",
            "White Horse",
            "White House",
            "White Island Shores",
            "White Marsh",
            "White Meadow Lake",
            "White Mountain Lake",
            "White Oak",
            "White Oak",
            "White Oak",
            "White Oak",
            "White Pigeon",
            "White Pine",
            "White Pine County",
            "White Plains",
            "White Plains",
            "White River",
            "White River Junction",
            "White Rock",
            "White Salmon",
            "White Sands",
            "White Settlement",
            "White Sulphur Springs",
            "White Sulphur Springs",
            "Whitefield",
            "Whitefield",
            "Whitefish",
            "Whitefish Bay",
            "Whitehall",
            "Whitehall",
            "Whitehall",
            "Whitehall",
            "Whitehall",
            "Whitehall",
            "Whitehall Township",
            "Whitehouse",
            "Whitehouse",
            "Whitehouse Station",
            "Whiteland",
            "Whiteman Air Force Base",
            "Whitemarsh Island",
            "Whiteriver",
            "Whitesboro",
            "Whitesboro",
            "Whitesboro",
            "Whitesboro",
            "Whitesboro-Burleigh",
            "Whitesburg",
            "Whiteside County",
            "Whitestone",
            "Whitestown",
            "Whiteville",
            "Whiteville",
            "Whitewater",
            "Whitewright",
            "Whitfield",
            "Whitfield",
            "Whitfield County",
            "Whiting",
            "Whiting",
            "Whitinsville",
            "Whitley City",
            "Whitley County",
            "Whitley County",
            "Whitman",
            "Whitman",
            "Whitman County",
            "Whitmire",
            "Whitmore Lake",
            "Whitmore Village",
            "Whitney",
            "Whitney",
            "Whittier",
            "Whittingham",
            "Whitwell",
            "Wibaux",
            "Wibaux County",
            "Wichita",
            "Wichita County",
            "Wichita County",
            "Wichita Falls",
            "Wickenburg",
            "Wickerham Manor-Fisher",
            "Wickliffe",
            "Wickliffe",
            "Wicomico County",
            "Wiggins",
            "Wilbarger County",
            "Wilber",
            "Wilberforce",
            "Wilbraham",
            "Wilburton",
            "Wilcox County",
            "Wilcox County",
            "Wild Peach Village",
            "Wilder",
            "Wilder",
            "Wilder",
            "Wilderness Rim",
            "Wildomar",
            "Wildwood",
            "Wildwood",
            "Wildwood",
            "Wildwood",
            "Wildwood",
            "Wildwood Crest",
            "Wildwood Lake",
            "Wiley Ford",
            "Wilkes County",
            "Wilkes County",
            "Wilkes-Barre",
            "Wilkesboro",
            "Wilkin County",
            "Wilkinsburg",
            "Wilkinson County",
            "Wilkinson County",
            "Wilkinson Heights",
            "Will County",
            "Willacoochee",
            "Willacy County",
            "Willamina",
            "Willard",
            "Willard",
            "Willard",
            "Willcox",
            "Williams",
            "Williams",
            "Williams",
            "Williams Bay",
            "Williams County",
            "Williams County",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg",
            "Williamsburg County",
            "Williamson",
            "Williamson",
            "Williamson",
            "Williamson County",
            "Williamson County",
            "Williamson County",
            "Williamsport",
            "Williamsport",
            "Williamsport",
            "Williamsport",
            "Williamston",
            "Williamston",
            "Williamston",
            "Williamstown",
            "Williamstown",
            "Williamstown",
            "Williamstown",
            "Williamstown",
            "Williamstown",
            "Williamsville",
            "Williamsville",
            "Willimantic",
            "Willingboro",
            "Willis",
            "Williston",
            "Williston",
            "Williston",
            "Williston",
            "Williston Highlands",
            "Williston Park",
            "Willits",
            "Willmar",
            "Willoughby",
            "Willoughby Hills",
            "Willow",
            "Willow Creek",
            "Willow Grove",
            "Willow Oak",
            "Willow Park",
            "Willow Springs",
            "Willow Springs",
            "Willow Street",
            "Willow Valley",
            "Willowbrook",
            "Willowbrook",
            "Willowbrook",
            "Willowick",
            "Willows",
            "Wills Point",
            "Wilmer",
            "Wilmerding",
            "Wilmette",
            "Wilmington",
            "Wilmington",
            "Wilmington",
            "Wilmington",
            "Wilmington",
            "Wilmington Island",
            "Wilmington Manor",
            "Wilmore",
            "Wilmot",
            "Wilson",
            "Wilson",
            "Wilson",
            "Wilson",
            "Wilson",
            "Wilson County",
            "Wilson County",
            "Wilson County",
            "Wilson County",
            "Wilson-Conococheague",
            "Wilsons Mills",
            "Wilsonville",
            "Wilsonville",
            "Wilton",
            "Wilton",
            "Wilton",
            "Wilton",
            "Wilton",
            "Wilton Manors",
            "Wimauma",
            "Wimberley",
            "Winamac",
            "Winchendon",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester",
            "Winchester Center",
            "Wind Gap",
            "Wind Lake",
            "Wind Point",
            "Windber",
            "Windcrest",
            "Windemere",
            "Winder",
            "Windermere",
            "Windham",
            "Windham",
            "Windham",
            "Windham County",
            "Windham County",
            "Windom",
            "Window Rock",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor",
            "Windsor County",
            "Windsor Heights",
            "Windsor Locks",
            "Windy Hills",
            "Winfield",
            "Winfield",
            "Winfield",
            "Winfield",
            "Winfield",
            "Winfield",
            "Winfield",
            "Winfield",
            "Wingate",
            "Wink",
            "Winkler County",
            "Winlock",
            "Winn Parish",
            "Winnebago",
            "Winnebago",
            "Winnebago County",
            "Winnebago County",
            "Winnebago County",
            "Winneconne",
            "Winnemucca",
            "Winner",
            "Winneshiek County",
            "Winnetka",
            "Winnett",
            "Winnfield",
            "Winnie",
            "Winnsboro",
            "Winnsboro",
            "Winnsboro",
            "Winnsboro Mills",
            "Winona",
            "Winona",
            "Winona",
            "Winona County",
            "Winona Lake",
            "Winooski",
            "Winslow",
            "Winslow",
            "Winsted",
            "Winsted",
            "Winston",
            "Winston",
            "Winston County",
            "Winston County",
            "Winston-Salem",
            "Winter Beach",
            "Winter Garden",
            "Winter Gardens",
            "Winter Haven",
            "Winter Park",
            "Winter Springs",
            "Winterport",
            "Winters",
            "Winters",
            "Winterset",
            "Wintersville",
            "Winterville",
            "Winterville",
            "Winthrop",
            "Winthrop",
            "Winthrop",
            "Winthrop Harbor",
            "Winton",
            "Winton",
            "Wirt County",
            "Wiscasset",
            "Wisconsin Dells",
            "Wisconsin Rapids",
            "Wise",
            "Wise County",
            "Wise County",
            "Wisner",
            "Wister",
            "Withamsville",
            "Wittenberg",
            "Wixom",
            "Woburn",
            "Wofford Heights",
            "Wolcott",
            "Wolcott",
            "Wolcottville",
            "Wolf Creek",
            "Wolf Lake",
            "Wolf Point",
            "Wolf Trap",
            "Wolfdale",
            "Wolfe City",
            "Wolfe County",
            "Wolfeboro",
            "Wolfforth",
            "Wolfhurst",
            "Wollochet",
            "Wolverine Lake",
            "Womelsdorf",
            "Wonder Lake",
            "Wood County",
            "Wood County",
            "Wood County",
            "Wood County",
            "Wood Dale",
            "Wood River",
            "Wood River",
            "Wood Village",
            "Wood-Lynne",
            "Wood-Ridge",
            "Woodacre",
            "Woodbine",
            "Woodbine",
            "Woodbine",
            "Woodbourne",
            "Woodbranch",
            "Woodbridge",
            "Woodbridge",
            "Woodbridge",
            "Woodbridge",
            "Woodburn",
            "Woodburn",
            "Woodburn",
            "Woodbury",
            "Woodbury",
            "Woodbury",
            "Woodbury",
            "Woodbury",
            "Woodbury Center",
            "Woodbury County",
            "Woodbury Heights",
            "Woodcliff Lake",
            "Woodcreek",
            "Woodcrest",
            "Woodfield",
            "Woodfin",
            "Woodford County",
            "Woodford County",
            "Woodhaven",
            "Woodhaven",
            "Woodinville",
            "Woodlake",
            "Woodlake",
            "Woodland",
            "Woodland",
            "Woodland Beach",
            "Woodland Heights",
            "Woodland Hills",
            "Woodland Hills",
            "Woodland Park",
            "Woodland Park",
            "Woodlawn",
            "Woodlawn",
            "Woodlawn",
            "Woodlawn",
            "Woodlawn",
            "Woodlawn Beach",
            "Woodlyn",
            "Woodmere",
            "Woodmere",
            "Woodmont",
            "Woodmoor",
            "Woodmore",
            "Woodridge",
            "Woodrow",
            "Woodruff",
            "Woodruff County",
            "Woods County",
            "Woods Creek",
            "Woods Cross",
            "Woodsboro",
            "Woodsboro",
            "Woodsfield",
            "Woodside",
            "Woodside",
            "Woodside",
            "Woodside East",
            "Woodson County",
            "Woodson Terrace",
            "Woodstock",
            "Woodstock",
            "Woodstock",
            "Woodstock",
            "Woodstock",
            "Woodstock",
            "Woodstock",
            "Woodstock",
            "Woodstown",
            "Woodsville",
            "Woodville",
            "Woodville",
            "Woodville",
            "Woodville",
            "Woodville",
            "Woodville",
            "Woodward",
            "Woodward",
            "Woodward County",
            "Woodway",
            "Woodway",
            "Woodworth",
            "Woolwich",
            "Woonsocket",
            "Woonsocket",
            "Wooster",
            "Worcester",
            "Worcester",
            "Worcester County",
            "Worcester County",
            "Worden",
            "Worland",
            "Wormleysburg",
            "Worth",
            "Worth County",
            "Worth County",
            "Worth County",
            "Wortham",
            "Worthington",
            "Worthington",
            "Worthington",
            "Worthington",
            "Worthington Hills",
            "Woxall",
            "Wrangell",
            "Wray",
            "Wrens",
            "Wrentham",
            "Wright",
            "Wright",
            "Wright City",
            "Wright County",
            "Wright County",
            "Wright County",
            "Wright-Patterson AFB",
            "Wrightsboro",
            "Wrightstown",
            "Wrightsville",
            "Wrightsville",
            "Wrightsville",
            "Wrightsville Beach",
            "Wrightwood",
            "Wurtland",
            "Wurtsboro",
            "Wyandanch",
            "Wyandot County",
            "Wyandotte",
            "Wyandotte County",
            "Wyckoff",
            "Wykagyl",
            "Wyldwood",
            "Wylie",
            "Wymore",
            "Wynantskill",
            "Wyncote",
            "Wyndham",
            "Wyndmoor",
            "Wynne",
            "Wynnewood",
            "Wyoming",
            "Wyoming",
            "Wyoming",
            "Wyoming",
            "Wyoming",
            "Wyoming",
            "Wyoming County",
            "Wyoming County",
            "Wyoming County",
            "Wyomissing",
            "Wyomissing Hills",
            "Wythe County",
            "Wytheville",
            "Xenia",
            "Y",
            "Yabucoa",
            "Yabucoa Municipio",
            "Yacolt",
            "Yadkin County",
            "Yadkinville",
            "Yakima",
            "Yakima County",
            "Yakutat City and Borough",
            "Yalaha",
            "Yale",
            "Yale",
            "Yalobusha County",
            "Yamhill",
            "Yamhill County",
            "Yancey County",
            "Yanceyville",
            "Yankton",
            "Yankton County",
            "Yaphank",
            "Yardley",
            "Yardville",
            "Yarmouth",
            "Yarmouth",
            "Yarmouth Port",
            "Yarrow Point",
            "Yates Center",
            "Yates County",
            "Yauco",
            "Yaurel",
            "Yavapai County",
            "Yazoo City",
            "Yazoo County",
            "Yeadon",
            "Yeagertown",
            "Yell County",
            "Yellow Medicine County",
            "Yellow Springs",
            "Yellowstone County",
            "Yellville",
            "Yelm",
            "Yerington",
            "Yoakum",
            "Yoakum County",
            "Yoe",
            "Yolo County",
            "Yoncalla",
            "Yonkers",
            "Yorba Linda",
            "York",
            "York",
            "York",
            "York",
            "York Beach",
            "York County",
            "York County",
            "York County",
            "York County",
            "York County",
            "York Harbor",
            "Yorketown",
            "Yorklyn",
            "Yorkshire",
            "Yorkshire",
            "Yorktown",
            "Yorktown",
            "Yorktown",
            "Yorktown Heights",
            "Yorkville",
            "Yorkville",
            "Yorkville",
            "Yosemite Lakes",
            "Yosemite Valley",
            "Young America (historical)",
            "Young County",
            "Young Harris",
            "Youngstown",
            "Youngstown",
            "Youngstown",
            "Youngsville",
            "Youngsville",
            "Youngsville",
            "Youngtown",
            "Youngwood",
            "Yountville",
            "Ypsilanti",
            "Yreka",
            "Yuba City",
            "Yuba County",
            "Yucaipa",
            "Yucca Valley",
            "Yukon",
            "Yukon-Koyukuk Census Area",
            "Yulee",
            "Yuma",
            "Yuma",
            "Yuma County",
            "Yuma County",
            "Yutan",
            "Zachary",
            "Zanesville",
            "Zapata",
            "Zapata County",
            "Zavala County",
            "Zebulon",
            "Zebulon",
            "Zeeland",
            "Zeigler",
            "Zelienople",
            "Zellwood",
            "Zena",
            "Zephyrhills",
            "Zephyrhills North",
            "Zephyrhills South",
            "Zephyrhills West",
            "Ziebach County",
            "Zillah",
            "Zilwaukee",
            "Zimmerman",
            "Zion",
            "Zion",
            "Zionsville",
            "Zolfo Springs",
            "Zumbrota",
            "Zuni Pueblo",
            "Zwolle"
        ]
    },
    {
        "label": "United States Minor Outlying Islands",
        "code": "UM",
        "phone": "1",
        "cities": []
    },
    {
        "label": "Uruguay",
        "code": "UY",
        "phone": "598",
        "cities": [
            "25 de Agosto",
            "25 de Mayo",
            "Aceguá",
            "Aguas Corrientes",
            "Aiguá",
            "Alejandro Gallinal",
            "Artigas",
            "Atlántida",
            "Baltasar Brum",
            "Barra de Carrasco",
            "Barros Blancos",
            "Belén",
            "Bella Unión",
            "Bella Vista",
            "Belvedere",
            "Blanquillo",
            "Buceo",
            "Canelones",
            "Capurro (Montevideo)",
            "Cardal",
            "Cardona",
            "Carlos Reyles",
            "Carmelo",
            "Carrasco",
            "Casabó",
            "Castillos",
            "Casupá",
            "Cebollatí",
            "Centro",
            "Cerrito Montevideo",
            "Chui",
            "Colón Centro y Noroeste",
            "Colón Sudeste",
            "Colonia del Sacramento",
            "Colonia Nicolich",
            "Conciliación",
            "Cordón",
            "Curtina",
            "Delta del Tigre",
            "Dieciocho de Julio",
            "Dolores",
            "Durazno",
            "Ecilda Paullier",
            "Empalme Olmos",
            "Estación Porvenir",
            "Flor de Maroñas",
            "Florencio Sánchez",
            "Florida",
            "Fray Bentos",
            "Guichón",
            "Isidoro Noblía",
            "Ituzaingó",
            "Joaquín Suárez",
            "José Batlle y Ordóñez",
            "José Enrique Rodó",
            "José Pedro Varela",
            "Juan L. Lacaze",
            "Juanicó",
            "La Floresta",
            "La Paloma",
            "La Paloma",
            "La Paz",
            "La Unión",
            "Las Piedras",
            "Las Piedras",
            "Las Toscas",
            "Lascano",
            "Libertad",
            "Los Cerrillos",
            "Maldonado",
            "Malvín",
            "Manga",
            "Mariscala",
            "Maroñas",
            "Melo",
            "Mercedes",
            "Migues",
            "Minas",
            "Minas de Corrales",
            "Montes",
            "Montevideo",
            "Nueva Helvecia",
            "Nueva Palmira",
            "Nuevo Berlín",
            "Nuevo París",
            "Ombúes de Lavalle",
            "Pajas Blancas",
            "Palmitas",
            "Pan de Azúcar",
            "Pando",
            "Paso de Carrasco",
            "Paso de los Toros",
            "Paso del Molino",
            "Paysandú",
            "Peñarol",
            "Piedras Blancas",
            "Piedras Coloradas",
            "Piriápolis",
            "Pocitos",
            "Progreso",
            "Punta Carretas",
            "Punta del Este",
            "Punta Gorda",
            "Puntas de Valdéz",
            "Quebracho",
            "Rafael Perazza",
            "Reducto",
            "Río Branco",
            "Rivera",
            "Rocha",
            "Rodríguez",
            "Rosario",
            "Salto",
            "San Antonio",
            "San Bautista",
            "San Carlos",
            "San Félix",
            "San Jacinto",
            "San Javier",
            "San José de Mayo",
            "San Ramón",
            "Santa Bernardina",
            "Santa Catalina",
            "Santa Clara de Olimar",
            "Santa Lucía",
            "Santa Rosa",
            "Santiago Vázquez",
            "Sarandí del Yi",
            "Sarandí Grande",
            "Sauce",
            "Sayage",
            "Soca",
            "Solís de Mataojo",
            "Tacuarembó",
            "Tala",
            "Tarariras",
            "Toledo",
            "Tomás Gomensoro",
            "Tranqueras",
            "Treinta y Tres",
            "Tres Cruces",
            "Trinidad",
            "Tupambaé",
            "Velázquez",
            "Vergara",
            "Vichadero",
            "Villa Constitución",
            "Villa del Carmen",
            "Villa del Cerro",
            "Villa Española",
            "Villa García",
            "Villa Muñoz",
            "Villa Sara",
            "Villa Soriano",
            "Young"
        ]
    },
    {
        "label": "Uzbekistan",
        "code": "UZ",
        "phone": "998",
        "cities": [
            "Andijon",
            "Andijon Tumani",
            "Angren",
            "Asaka",
            "Asaka Tumani",
            "Baliqchi Tumani",
            "Bekobod",
            "Bektemir",
            "Beruniy",
            "Beshariq",
            "Beshkent",
            "Bo‘ka",
            "Bo‘z Tumani",
            "Boghot Tumani",
            "Boysun",
            "Bukhara",
            "Buloqboshi Tumani",
            "Bulung’ur",
            "Charxin",
            "Chelak",
            "Chinoz",
            "Chirchiq",
            "Chiroqchi",
            "Chortoq",
            "Chust",
            "Dashtobod",
            "Daxbet",
            "Denov",
            "Dŭstlik",
            "Fergana",
            "G‘azalkent",
            "G‘uzor",
            "Gagarin",
            "Galaosiyo",
            "Gazli",
            "Ghijduwon",
            "Guliston",
            "Gurlan",
            "Hamza",
            "Haqqulobod",
            "Hazorasp",
            "Ishtixon",
            "Iskandar",
            "Izboskan Tumani",
            "Jalolkuduk Tumani",
            "Jizzax",
            "Jomboy",
            "Juma",
            "Karakul’",
            "Kattaqo‘rg‘on",
            "Kegeyli Shahar",
            "Khiwa",
            "Khŭjaobod Tumani",
            "Khŭjayli",
            "Kirguli",
            "Kitob",
            "Kogon",
            "Koson",
            "Kosonsoy",
            "Kyzyldzhar",
            "Manghit",
            "Marg‘ilon",
            "Marhamat",
            "Marhamat Tumani",
            "Muborak",
            "Mŭynoq",
            "Namangan",
            "Navoiy",
            "Nishon Tumani",
            "Novyy Turtkul’",
            "Nukus",
            "Nurota",
            "Ohangaron",
            "Olmaliq",
            "Olot",
            "Oltiariq",
            "Oltinko‘l",
            "Oltinkŭl Tumani",
            "Oqtosh",
            "Pakhtaobod Tumani",
            "Parkent",
            "Paxtakor",
            "Paxtaobod",
            "Payshanba",
            "Peshku Tumani",
            "Piskent",
            "Pop",
            "Qarshi",
            "Qibray",
            "Qiziltepa",
            "Qo‘qon",
            "Qo‘rg‘ontepa",
            "Qorako’l",
            "Qorovulbozor",
            "Qŭrghontepa Tumani",
            "Qŭshkŭpir",
            "Quva",
            "Quvasoy",
            "Rishton",
            "Romiton",
            "Salor",
            "Samarkand",
            "Shahrikhon Tumani",
            "Shahrisabz",
            "Shahrixon",
            "Sho‘rchi",
            "Shofirkon",
            "Shohimardon",
            "Showot",
            "Sirdaryo",
            "So‘x Tumani",
            "Tashkent",
            "Tirmiz",
            "To‘rqao‘rg‘on",
            "Toshbuloq",
            "Toshloq",
            "Tŭytepa",
            "Uchqŭrghon Shahri",
            "Ulug‘nor Tumani",
            "Urganch",
            "Urgut",
            "Ŭrtaowul",
            "Uychi",
            "Wobkent",
            "Wobkent Tumani",
            "Xo‘jaobod",
            "Yangi Marg‘ilon",
            "Yangiobod",
            "Yangiqo‘rg‘on",
            "Yangirabot",
            "Yangiyer",
            "Yangiyŭl",
            "Yaypan",
            "Zafar",
            "Zomin"
        ]
    },
    {
        "label": "Vanuatu",
        "code": "VU",
        "phone": "678",
        "cities": [
            "Isangel",
            "Lakatoro",
            "Luganville",
            "Norsup",
            "Port-Olry",
            "Port-Vila",
            "Sola"
        ]
    },
    {
        "label": "Vatican City State (Holy See)",
        "code": "VA",
        "phone": "379",
        "cities": []
    },
    {
        "label": "Venezuela",
        "code": "VE",
        "phone": "58",
        "cities": [
            "Acarigua",
            "Altagracia de Orituco",
            "Alto Barinas",
            "Anaco",
            "Aragua de Barcelona",
            "Araure",
            "Barcelona",
            "Barinas",
            "Barinitas",
            "Barquisimeto",
            "Baruta",
            "Boconó",
            "Cabimas",
            "Cabudare",
            "Cagua",
            "Calabozo",
            "Cantaura",
            "Caraballeda",
            "Caracas",
            "Caripito",
            "Carora",
            "Carrizal",
            "Carúpano",
            "Catia La Mar",
            "Caucaguita",
            "Caucagüito",
            "Chacao",
            "Charallave",
            "Chichiriviche",
            "Chivacoa",
            "Ciudad Bolívar",
            "Ciudad Guayana",
            "Ciudad Ojeda",
            "Colón",
            "Coro",
            "Cúa",
            "Cumaná",
            "Ejido",
            "El Cafetal",
            "El Hatillo",
            "El Limón",
            "El Tigre",
            "El Tocuyo",
            "El Vigía",
            "Guacara",
            "Guanare",
            "Guarenas",
            "Guatire",
            "Güigüe",
            "Güiria",
            "Juan Griego",
            "La Asunción",
            "La Dolorita",
            "La Fría",
            "La Grita",
            "La Guaira",
            "La Victoria",
            "La Villa del Rosario",
            "Lagunillas",
            "Las Tejerías",
            "Los Dos Caminos",
            "Los Guayos",
            "Los Rastrojos",
            "Los Teques",
            "Machiques",
            "Maiquetía",
            "Maracaibo",
            "Maracay",
            "Mariara",
            "Maroa",
            "Maturín",
            "Mérida",
            "Morón",
            "Mucumpiz",
            "Municipio Autónomo Alto Orinoco",
            "Municipio Barinas",
            "Municipio Independencia",
            "Municipio José Gregorio Monagas",
            "Municipio José María Vargas",
            "Municipio Libertador",
            "Municipio Los Taques",
            "Municipio Maturín",
            "Municipio Miranda",
            "Municipio Padre Pedro Chien",
            "Municipio Pampanito",
            "Municipio San Rafael de Carvajal",
            "Municipio Uracoa",
            "Municipio Valdez",
            "Nirgua",
            "Ocumare del Tuy",
            "Onoto",
            "Palo Negro",
            "Petare",
            "Porlamar",
            "Puerto Ayacucho",
            "Puerto Cabello",
            "Puerto La Cruz",
            "Puerto Píritu",
            "Punta Cardón",
            "Punto Fijo",
            "Quíbor",
            "Rubio",
            "San Antonio de Los Altos",
            "San Antonio del Táchira",
            "San Carlos",
            "San Carlos de Río Negro",
            "San Carlos del Zulia",
            "San Cristóbal",
            "San Felipe",
            "San Fernando de Apure",
            "San Fernando de Atabapo",
            "San Joaquín",
            "San José de Guanipa",
            "San Juan de los Morros",
            "San Juan de Manapiare",
            "San Mateo",
            "Santa Elena de Uairén",
            "Santa Rita",
            "Santa Rita",
            "Santa Teresa del Tuy",
            "Tacarigua",
            "Táriba",
            "Tinaquillo",
            "Tocuyito",
            "Trujillo",
            "Tucacas",
            "Tucupita",
            "Turmero",
            "Upata",
            "Valencia",
            "Valera",
            "Valle de La Pascua",
            "Villa Bruzual",
            "Villa de Cura",
            "Yaritagua",
            "Zaraza"
        ]
    },
    {
        "label": "Vietnam",
        "code": "VN",
        "phone": "84",
        "cities": [
            "Ấp Tân Ngãi",
            "Bắc Giang",
            "Bắc Kạn",
            "Bạc Liêu",
            "Bắc Ninh",
            "Bảo Lộc",
            "Bến Tre",
            "Biên Hòa",
            "Bỉm Sơn",
            "Bình Long",
            "Buôn Ma Thuột",
            "Cà Mau",
            "Cẩm Phả",
            "Cẩm Phả Mines",
            "Cam Ranh",
            "Cần Giờ",
            "Cần Giuộc",
            "Cần Thơ",
            "Cao Bằng",
            "Cao Lãnh",
            "Cát Bà",
            "Cho Dok",
            "Cờ Đỏ",
            "Côn Sơn",
            "Củ Chi",
            "Cung Kiệm",
            "Da Nang",
            "Dĩ An",
            "Dien Bien Phu",
            "Don Luan",
            "Dương Đông",
            "Đam Rong",
            "Đinh Văn",
            "Đưc Trọng",
            "Ðà Lạt",
            "Ðông Hà",
            "Hà Đông",
            "Hà Giang",
            "Hạ Long",
            "Hà Tiên",
            "Hà Tĩnh",
            "Hải Dương",
            "Haiphong",
            "Hanoi",
            "Ho Chi Minh City",
            "Hòa Bình",
            "Hội An",
            "Huế",
            "Hưng Yên",
            "Huyện A Lưới",
            "Huyện An Biên",
            "Huyện An Lão",
            "Huyện An Minh",
            "Huyện An Phú",
            "Huyện Ân Thi",
            "Huyện Anh Sơn",
            "Huyện Ba Bể",
            "Huyện Ba Chẽ",
            "Huyện Bá Thước",
            "Huyện Ba Tơ",
            "Huyện Ba Tri",
            "Huyện Bắc Bình",
            "Huyện Bắc Hà",
            "Huyện Bắc Mê",
            "Huyện Bắc Quang",
            "Huyện Bắc Sơn",
            "Huyện Bắc Yên",
            "Huyện Bạch Thông",
            "Huyện Bảo Lac",
            "Huyện Bảo Lâm",
            "Huyện Bảo Lâm",
            "Huyện Bảo Yên",
            "Huyện Bát Xát",
            "Huyện Bến Cầu",
            "Huyện Bến Lức",
            "Huyện Bình Đại",
            "Huyện Bình Gia",
            "Huyện Bình Giang",
            "Huyện Bình Liêu",
            "Huyện Bình Lục",
            "Huyện Bình Sơn",
            "Huyện Bình Xuyên",
            "Huyện Bù Đốp",
            "Huyện Buôn Đôn",
            "Huyện Cái Bè",
            "Huyện Cai Lậy",
            "Huyện Cái Nước",
            "Huyện Cẩm Giàng",
            "Huyện Cẩm Khê",
            "Huyện Cam Lộ",
            "Huyện Cẩm Thủy",
            "Huyện Cẩm Xuyên",
            "Huyện Cần Đước",
            "Huyện Cần Giuộc",
            "Huyện Can Lộc",
            "Huyện Càng Long",
            "Huyện Cao Lộc",
            "Huyện Cao Phong",
            "Huyện Cầu Kè",
            "Huyện Cầu Ngang",
            "Huyện Châu Đức",
            "Huyện Châu Phú",
            "Huyện Châu Thành",
            "Huyện Châu Thành",
            "Huyện Châu Thành",
            "Huyện Châu Thành",
            "Huyện Châu Thành",
            "Huyện Châu Thành A",
            "Huyện Chi Lăng",
            "Huyện Chiêm Hóa",
            "Huyện Chợ Đồn",
            "Huyện Chợ Gạo",
            "Huyện Chợ Lách",
            "Huyện Chợ Mới",
            "Huyện Chơn Thành",
            "Huyện Chư Păh",
            "Huyện Chư Prông",
            "Huyện Chư Sê",
            "Huyện Cô Tô",
            "Huyện Con Cuông",
            "Huyện Cư Jút",
            "Huyện Diễn Châu",
            "Huyện Diên Khánh",
            "Huyện Dương Minh Châu",
            "Huyện Duy Tiên",
            "Huyện Duy Xuyên",
            "Huyện Đạ Huoai",
            "Huyện Đa Krông",
            "Huyện Đạ Tẻh",
            "Huyện Đại Lộc",
            "Huyện Đắk Glei",
            "Huyện Đắk Hà",
            "Huyện Đắk R’Lấp",
            "Huyện Đắk Tô",
            "Huyện Đầm Dơi",
            "Huyện Đầm Hà",
            "Huyện Đất Đỏ",
            "Huyện Điện Bàn",
            "Huyện Điện Biên Đông",
            "Huyện Đình Lập",
            "Huyện Định Quán",
            "Huyện Đô Lương",
            "Huyện Đoan Hùng",
            "Huyện Đơn Dương",
            "Huyện Đồng Phú",
            "Huyện Đông Sơn",
            "Huyện Đông Triều",
            "Huyện Đồng Văn",
            "Huyện Đức Cơ",
            "Huyện Đức Hòa",
            "Huyện Đức Huệ",
            "Huyện Đức Phổ",
            "Huyện Đức Trọng",
            "Huyện Ðông Hưng",
            "Huyện Ðức Thọ",
            "Huyện Ea H'Leo",
            "Huyện Ea Súp",
            "Huyện Gia Bình",
            "Huyện Gia Lộc",
            "Huyện Giá Rai",
            "Huyện Giao Thủy",
            "Huyện Gio Linh",
            "Huyện Giồng Riềng",
            "Huyện Giồng Trôm",
            "Huyện Gò Công Đông",
            "Huyện Gò Công Tây",
            "Huyện Gò Dầu",
            "Huyện Gò Quao",
            "Huyện Hạ Hòa",
            "Huyện Hạ Lang",
            "Huyện Hà Quảng",
            "Huyện Hà Trung",
            "Huyện Hải Hà",
            "Huyện Hải Hậu",
            "Huyện Hải Lăng",
            "Huyện Hàm Tân",
            "Huyện Hàm Thuận Bắc",
            "Huyện Hàm Thuận Nam",
            "Huyện Hàm Yên",
            "Huyện Hậu Lộc",
            "Huyện Hiệp Đức",
            "Huyện Hoa Lư",
            "Huyện Hòa Thành",
            "Huyện Hoài Nhơn",
            "Huyện Hoành Bồ",
            "Huyện Hòn Đất",
            "Huyện Hớn Quản",
            "Huyện Hồng Dân",
            "Huyện Hưng Hà",
            "Huyện Hưng Nguyên",
            "Huyện Hướng Hóa",
            "Huyện Hũu Lũng",
            "Huyện Ia Grai",
            "Huyện Ia H'Drai",
            "Huyện KBang",
            "Huyện Kế Sách",
            "Huyện Khánh Sơn",
            "Huyện Khánh Vĩnh",
            "Huyện Khoái Châu",
            "Huyện Kiên Hải",
            "Huyện Kiến Xương",
            "Huyện Kim Bảng",
            "Huyện Kim Động",
            "Huyện Kim Sơn",
            "Huyện Kinh Môn",
            "Huyện Kon Plông",
            "Huyện Kông Chro",
            "Huyện Krông Nô",
            "Huyện Krông Pa",
            "Huyện Kỳ Anh",
            "Huyện Kỳ Sơn",
            "Huyện Kỳ Sơn",
            "Huyện Lạc Thủy",
            "Huyện Lai Vung",
            "Huyện Lâm Bình",
            "Huyện Lâm Hà",
            "Huyen Lam Thao",
            "Huyện Lang Chánh",
            "Huyện Lấp Vò",
            "Huyện Lệ Thủy",
            "Huyện Long Hồ",
            "Huyện Long Phú",
            "Huyện Long Thành",
            "Huyện Lục Nam",
            "Huyện Lục Ngạn",
            "Huyện Lục Yên",
            "Huyện Lương Sơn",
            "Huyện Lý Nhân",
            "Huyện Lý Sơn",
            "Huyện Mai Sơn",
            "Huyện Mang Thít",
            "Huyện Mang Yang",
            "Huyện Minh Long",
            "Huyện Mộ Đức",
            "Huyện Mộc Châu",
            "Huyện Mộc Hóa",
            "Huyện Mù Cang Chải",
            "Huyện Mường Khương",
            "Huyện Mường La",
            "Huyện Mường Lát",
            "Huyện Mường Nhé",
            "Huyện Mưòng Tè",
            "Huyện Mỹ Hào",
            "Huyện Mỹ Lộc",
            "Huyện Mỹ Tú",
            "Huyện Mỹ Xuyên",
            "Huyện Na Hang",
            "Huyện Na Rì",
            "Huyen Nam Can",
            "Huyện Nam Đàn",
            "Huyện Nam Đông",
            "Huyện Nam Giang",
            "Huyện Nam Sách",
            "Huyện Nam Trực",
            "Huyen Nga Nam",
            "Huyện Nga Sơn",
            "Huyện Ngân Sơn",
            "Huyện Nghi Lộc",
            "Huyện Nghi Xuân",
            "Huyện Nghĩa Đàn",
            "Huyện Nghĩa Hành",
            "Huyện Nghĩa Hưng",
            "Huyện Ngọc Hồi",
            "Huyện Ngọc Lặc",
            "Huyện Nguyên Bình",
            "Huyện Nho Quan",
            "Huyện Như Thanh",
            "Huyện Như Xuân",
            "Huyện Nông Cống",
            "Huyện Núi Thành",
            "Huyện Phong Điền",
            "Huyện Phù Cừ",
            "Huyện Phú Lộc",
            "Huyện Phù Mỹ",
            "Huyện Phú Quốc",
            "Huyện Phú Tân",
            "Huyện Phú Vang",
            "Huyện Phù Yên",
            "Huyen Phuc Hoa",
            "Huyện Phước Sơn",
            "Huyện Quan Hóa",
            "Huyện Quan Sơn",
            "Huyện Quang Bình",
            "Huyện Quảng Ðiền",
            "Huyện Quảng Ninh",
            "Huyện Quảng Trạch",
            "Huyện Quảng Xương",
            "Huyện Quế Phong",
            "Huyện Quốc Oai",
            "Huyện Quỳ Châu",
            "Huyện Quỳ Hợp",
            "Huyện Quỳnh Lưu",
            "Huyện Quỳnh Nhai",
            "Huyện Quỳnh Phụ",
            "Huyện Sa Pa",
            "Huyện Sa Thầy",
            "Huyện Si Ma Cai",
            "Huyện Sơn Dương",
            "Huyện Sơn Hà",
            "Huyện Sơn Hòa",
            "Huyện Sơn Tây",
            "Huyện Sơn Tịnh",
            "Huyện Sông Hinh",
            "Huyện Sông Mã",
            "Huyện Tam Bình",
            "Huyện Tam Dương",
            "Huyện Tam Đảo",
            "Huyện Tam Đường",
            "Huyện Tam Nông",
            "Huyện Tân Châu",
            "Huyện Tân Hiệp",
            "Huyện Tân Hồng",
            "Huyện Tân Hưng",
            "Huyện Tân Lạc",
            "Huyện Tân Phú",
            "Huyện Tân Phước",
            "Huyện Tân Thành",
            "Huyện Tân Thạnh",
            "Huyện Tân Trụ",
            "Huyện Tánh Linh",
            "Huyện Tây Sơn",
            "Huyện Thạch An",
            "Huyện Thạch Hà",
            "Huyện Thái Thụy",
            "Huyện Than Uyên",
            "Huyện Thanh Ba",
            "Huyện Thanh Bình",
            "Huyện Thanh Chương",
            "Huyện Thạnh Hóa",
            "Huyện Thanh Liêm",
            "Huyện Thanh Miện",
            "Huyện Thạnh Phú",
            "Huyện Thạnh Trị",
            "Huyện Tháp Mười",
            "Huyện Thiệu Hóa",
            "Huyện Thọ Xuân",
            "Huyện Thới Bình",
            "Huyện Thống Nhất",
            "Huyện Thông Nông",
            "Huyện Thủ Thừa",
            "Huyện Thuận Châu",
            "Huyện Thuận Thành",
            "Huyện Thường Xuân",
            "Huyện Tiên Du",
            "Huyện Tiền Hải",
            "Huyện Tiên Lữ",
            "Huyện Tiên Phước",
            "Huyện Tiên Yên",
            "Huyện Tiểu Cần",
            "Huyện Trà Bồng",
            "Huyện Trà Cú",
            "Huyện Trà Lĩnh",
            "Huyện Trà My",
            "Huyện Trà Ôn",
            "Huyện Trạm Tấu",
            "Huyện Trấn Yên",
            "Huyện Trảng Bàng",
            "Huyện Trảng Bom",
            "Huyện Tri Tôn",
            "Huyện Triệu Phong",
            "Huyện Triệu Sơn",
            "Huyện Trực Ninh",
            "Huyện Trùng Khánh",
            "Huyện Tư Nghĩa",
            "Huyện Tủa Chùa",
            "Huyện Tuần Giáo",
            "Huyện Tương Dương",
            "Huyện Tuy Phong",
            "Huyện Tuy Phước",
            "Huyện Tuyên Hóa",
            "Huyện Văn Bàn",
            "Huyện Văn Chấn",
            "Huyện Vân Đồn",
            "Huyện Vạn Ninh",
            "Huyện Vặn Quan",
            "Huyện Văn Yên",
            "Huyện Vị Xuyên",
            "Huyện Vĩnh Cửu",
            "Huyện Vĩnh Hưng",
            "Huyện Vĩnh Linh",
            "Huyện Vĩnh Lộc",
            "Huyện Vĩnh Thạnh",
            "Huyện Vĩnh Thuận",
            "Huyện Vụ Bản",
            "Huyện Vũ Thư",
            "Huyện Vũng Liêm",
            "Huyện Xín Mần",
            "Huyện Xuyên Mộc",
            "Huyện Ý Yên",
            "Huyện Yên Châu",
            "Huyện Yên Định",
            "Huyện Yên Khánh",
            "Huyện Yên Lạc",
            "Huyện Yên Lập",
            "Huyện Yên Minh",
            "Huyện Yên Mô",
            "Huyện Yên Phong",
            "Huyện Yên Sơn",
            "Huyện Yên Thành",
            "Huyện Yên Thế",
            "Kien Luong Town",
            "Kon Tum",
            "Kwang Binh",
            "La Gi",
            "Lạng Sơn",
            "Lào Cai",
            "Lao Chải",
            "Long Xuyên",
            "Long Xuyên City",
            "Móng Cái",
            "Mỹ Tho",
            "Nam Định",
            "Nha Trang",
            "Ninh Bình",
            "Phan Rang-Tháp Chàm",
            "Phan Thiết",
            "Phú Khương",
            "Phủ Lý",
            "Pleiku",
            "Quận Ba Đình",
            "Quận Hà Đông",
            "Quảng Ngãi",
            "Qui Nhon",
            "Rạch Giá",
            "Sa Dec",
            "Sa Dec city",
            "Sa Pa",
            "Sóc Trăng",
            "Sơn La",
            "Sơn Tây",
            "Sông Cầu",
            "Tam Kỳ",
            "Tân An",
            "Tây Ninh",
            "Thái Bình",
            "Thái Nguyên",
            "Thanh Hóa",
            "Thành Phố Cam Ranh",
            "Thành Phố Mỹ Tho",
            "Thành Phố Nha Trang",
            "Thành Phố Phan Thiết",
            "Thành Phố Uông Bí",
            "Thành Phố Việt Trì",
            "Thành Phố Vũng Tàu",
            "Thị Trấn Tân Thành",
            "Thị Xã Bắc Kạn",
            "Thị Xã Chí Linh",
            "Thị Xã Gò Công",
            "Thị Xã Mưòng Lay",
            "Thị Xã Ninh Hòa",
            "Thị Xã Phú Mỹ",
            "Thị xã Phú Thọ",
            "Thị Xã Phước Long",
            "Thới Lai",
            "Thủ Dầu Một",
            "Trà Vinh",
            "Tuy Hòa",
            "Tuyên Quang",
            "Vị Thanh",
            "Việt Trì",
            "Vinh",
            "Vĩnh Long",
            "Vĩnh Thạnh",
            "Vĩnh Yên",
            "Vũng Tàu",
            "Yên Bái",
            "Yên Vinh"
        ]
    },
    {
        "label": "Virgin Islands (British)",
        "code": "VG",
        "phone": "+1-284",
        "cities": []
    },
    {
        "label": "Virgin Islands (US)",
        "code": "VI",
        "phone": "+1-340",
        "cities": [
            "Central",
            "Charlotte Amalie",
            "Christiansted",
            "Coral Bay",
            "Cruz Bay",
            "East End",
            "East End",
            "East End",
            "Frederiksted",
            "Good Hope",
            "Northcentral",
            "Northside",
            "Northwest",
            "Sion Farm",
            "Southcentral",
            "Southside",
            "Southwest",
            "Tutu",
            "Water Island",
            "West End"
        ]
    },
    {
        "label": "Wallis And Futuna Islands",
        "code": "WF",
        "phone": "681",
        "cities": []
    },
    {
        "label": "Western Sahara",
        "code": "EH",
        "phone": "212",
        "cities": []
    },
    {
        "label": "Yemen",
        "code": "YE",
        "phone": "967",
        "cities": [
            "‘Amrān",
            "‘Ans",
            "Abs",
            "Ad Dahi",
            "Ad Dis",
            "Ad Dīs ash Sharqīyah",
            "Ad Durayhimi",
            "Aden",
            "Adh Dhlia'ah",
            "Aflah Al Yaman",
            "Aflah Ash Shawm",
            "Ahwar",
            "Ain",
            "Al  Hawtah",
            "Al ‘Āqir",
            "Al ‘Inān",
            "Al ‘Udayn",
            "Al A'rsh",
            "Al Abdiyah",
            "Al Abr",
            "Al Ashah",
            "Al Bayda",
            "Al Bayda City",
            "Al Bayḑā’",
            "Al Buraiqeh",
            "Al Dhaher",
            "Al Dhihar",
            "Al Garrahi",
            "Al Ghaydah",
            "Al Ghayl",
            "Al Ghayz̧ah",
            "Al Ḩabīlayn",
            "Al Had",
            "Al Hada",
            "Al Hajjaylah",
            "Al Hali",
            "Al Ḩamdī",
            "Al Hashwah",
            "Al Hawak",
            "Al Haymah Ad Dakhiliyah",
            "Al Haymah Al Kharijiyah",
            "Al Hazm",
            "Al Ḩudaydah",
            "Al Humaydat",
            "Al Husn",
            "Al Jabin",
            "Al Jafariyah",
            "Al Jamimah",
            "Al Jubah",
            "Al Khabt",
            "Al Khalq",
            "Al Khāniq",
            "Al Ma'afer",
            "Al Madan",
            "Al Madaribah Wa Al Arah",
            "Al Maflahy",
            "Al Maghrabah",
            "Al Mahabishah",
            "Al Mahfad",
            "Al Mahwait",
            "Al Maḩwīt",
            "Al Makhādir",
            "Al Malagim",
            "Al Manar",
            "Al Mansura",
            "Al Mansuriyah",
            "Al Maqatirah",
            "Al Marawi'ah",
            "Al Mashannah",
            "Al Masilah",
            "Al Maslub",
            "Al Matammah",
            "Al Maton",
            "Al Mawasit",
            "Al Miftah",
            "Al Mighlaf",
            "Al Milah",
            "Al Mina",
            "Al Misrakh",
            "Al Mualla",
            "Al Mudhaffar",
            "Al Mukalla",
            "Al Mukalla City",
            "Al Mukhā’",
            "Al Munirah",
            "Al Musaymir",
            "Al Qabbaytah",
            "Al Qaf",
            "Al Qaflah",
            "Al Qafr",
            "Al Qahirah",
            "Al Qanawis",
            "Al Qatn",
            "Al Quraishyah",
            "Al Talh",
            "Al Wade'a",
            "Al Wazi'iyah",
            "AL-khashā upper",
            "Al-Medy Village قرية المدي",
            "Alluheyah",
            "Amd",
            "An Nādirah",
            "Ar Radmah",
            "Ar Rawdah",
            "Ar Raydah Wa Qusayar",
            "Ar Rujum",
            "Ar Ryashyyah",
            "Arhab",
            "Arma",
            "As Sabrah",
            "As Saddah",
            "As Safra",
            "As Said",
            "As Salafiyah",
            "As Salif",
            "As Sawadiyah",
            "As Sawd",
            "As Sawm",
            "As Sawma'ah",
            "As Sayyani",
            "As Silw",
            "As Sudah",
            "As Sukhnah",
            "Ash Sha'ir",
            "Ash Shaghadirah",
            "Ash Shahil",
            "Ash Shaikh Outhman",
            "Ash Shamayatayn",
            "Ash Sharafayn",
            "Ash Shawātī",
            "Ash Shihr",
            "Aslem",
            "At Ta‘izzīyah",
            "At Taffah",
            "At Taḩāluf",
            "Aţ Ţawīlah",
            "At Tuhayat",
            "Ataq",
            "Attawahi",
            "Attyal",
            "Az Zahir",
            "Az Zahir",
            "Az Zaydīyah",
            "Az Zuhrah",
            "Ba'dan",
            "Bājil",
            "Bakil Al Mir",
            "Banī al ‘Awwām",
            "Bani Al Awam",
            "Bani Dhabyan",
            "Bani Hushaysh",
            "Bani Matar",
            "Bani Qa'is",
            "Bani Sa'd",
            "Bani Suraim",
            "Baqim",
            "Barţ al ‘Anān",
            "Bayhan",
            "Bayt al Faqīh",
            "Bidbadah",
            "Bilad Ar Rus",
            "Bilad At Ta'am",
            "Brom Mayfa",
            "Bura",
            "Craiter",
            "Dar Sad",
            "Daw'an",
            "Dawran Aness",
            "Dhamār",
            "Dhar",
            "Dhī as Sufāl",
            "Dhi Bin",
            "Dhi Na'im",
            "Dhubab",
            "Dimnat Khadir",
            "Far Al Udayn",
            "Ghamr",
            "Ghayl Ba Wazir",
            "Ghayl Bin Yamin",
            "Habban",
            "Habil Jabr",
            "Habur Zulaymah",
            "Hadibu",
            "Hagr As Sai'ar",
            "Hajjah",
            "Hajr",
            "Halimayn",
            "Hamdān",
            "Harad District",
            "Harf Sufyan",
            "Ḩarīb",
            "Harib Al Qaramish",
            "Hat",
            "Hatib",
            "Hawf",
            "Haydan",
            "Hayfan",
            "Hayran",
            "Hays",
            "Hazm Al Udayn",
            "Hidaybu",
            "Hooth",
            "Hubaysh",
            "Hufash",
            "Huraidhah",
            "Huswain",
            "Ibb",
            "Iyal Surayh",
            "Jabal Ash sharq",
            "Jabal Habashy",
            "Jabal Iyal Yazid",
            "Jabal Murad",
            "Jabal Ra's",
            "Jahran",
            "Jardan",
            "Jawf al Maqbābah",
            "Jayshan",
            "Jiblah",
            "Jihanah",
            "Kamaran",
            "Khabb wa ash Sha'af",
            "Khamir",
            "Khanfir",
            "Kharab Al Marashi",
            "Kharif",
            "Khayran Al Muharraq",
            "Khimār",
            "Khwlan",
            "Kilmia",
            "Kitaf wa Al Boqe'e",
            "Ku'aydinah",
            "Kuhlan Affar",
            "Kuhlan Ash Sharaf",
            "Kushar",
            "Kusmah",
            "Laḩij",
            "Lawdar",
            "Ma'rib",
            "Mabyan",
            "Maghirib Ans",
            "Mahliyah",
            "Majz",
            "Majzar",
            "Man'ar",
            "Manakhah",
            "Maqbanah",
            "Marib",
            "Marib City",
            "Mashra'a Wa Hadnan",
            "Maswar",
            "Maswarah",
            "Māwīyah",
            "Mawza",
            "Mayfa'a",
            "Mayfa'at Anss",
            "Mazhar",
            "Medghal",
            "Merkhah Al Ulya",
            "Merkhah As Sufla",
            "Midi",
            "Milhan",
            "Monabbih",
            "Mudhaykhirah",
            "Mudiyah",
            "Mukalla",
            "Mukayras",
            "Mustaba",
            "Na'man",
            "Najrah",
            "Nati'",
            "Nihm",
            "Nisab",
            "Qafl Shamer",
            "Qalansīyah",
            "Qarah",
            "Qatabir",
            "Qishn",
            "Qulensya Wa Abd Al Kuri",
            "Radā‘",
            "Radfan",
            "Radman Al Awad",
            "Raghwan",
            "Rahabah",
            "Rajuzah",
            "Rakhyah",
            "Rasad",
            "Raydah",
            "Rāziḩ",
            "Rudum",
            "Rumah",
            "Sa'dah",
            "Sa'fan",
            "Şa‘dah",
            "Sabah",
            "Sabir Al Mawadim",
            "Sah",
            "Saḩar",
            "Saḩār",
            "Salh",
            "Sama",
            "Sanaa",
            "Sanhan",
            "Saqayn",
            "Sarar",
            "Sayhut",
            "Sayun",
            "Sayyān",
            "Shada'a",
            "Shahan",
            "Shahārah",
            "Shara'b Ar Rawnah",
            "Shara'b As Salam",
            "Sharas",
            "Shibam",
            "Shibām Kawkabān",
            "Sibah",
            "Sirwah",
            "Suḩayl Shibām",
            "Suwayr",
            "Ta‘izz",
            "Tarim",
            "Thamud",
            "Thula",
            "Tuban",
            "Tur Al Bahah",
            "Usaylan",
            "Utmah",
            "Village of ALAMRAH",
            "Wadhrah",
            "Wadi Al Ayn",
            "Wald Rabi'",
            "Washḩah",
            "Wusab Al Ali",
            "Wusab As Safil",
            "Yabuth",
            "Yafa'a",
            "Yahr",
            "Yarīm",
            "Zabīd",
            "Zamakh wa Manwakh",
            "Zingibar",
            "Zinjibār"
        ]
    },
    {
        "label": "Zambia",
        "code": "ZM",
        "phone": "260",
        "cities": [
            "Chadiza",
            "Chama",
            "Chambishi",
            "Chibombo",
            "Chibombo District",
            "Chililabombwe",
            "Chingola",
            "Chingola District",
            "Chinsali",
            "Chipata",
            "Choma",
            "Chongwe",
            "Gwembe",
            "Isoka",
            "Itezhi-Tezhi District",
            "Kabompo",
            "Kabwe",
            "Kafue",
            "Kalabo",
            "Kalengwa",
            "Kalulushi",
            "Kansanshi",
            "Kaoma",
            "Kapiri Mposhi",
            "Kaputa",
            "Kasama",
            "Kasempa",
            "Kataba",
            "Kawambwa",
            "Kitwe",
            "Limulunga",
            "Livingstone",
            "Luangwa",
            "Luanshya",
            "Lukulu",
            "Lundazi",
            "Lusaka",
            "Luwingu",
            "Maamba",
            "Mansa",
            "Mazabuka",
            "Mbala",
            "Mkushi",
            "Mongu",
            "Monze",
            "Mpika",
            "Mpongwe",
            "Mporokoso",
            "Mpulungu",
            "Mufulira",
            "Mufumbwe",
            "Mumbwa",
            "Mungwi",
            "Mwense",
            "Mwinilunga",
            "Nakambala",
            "Nakonde",
            "Namwala",
            "Nchelenge",
            "Ndola",
            "Nyimba",
            "Petauke",
            "Samfya",
            "Senanga",
            "Serenje",
            "Sesheke",
            "Siavonga",
            "Siavonga District",
            "Sinazongwe",
            "Solwezi",
            "Zambezi"
        ]
    },
    {
        "label": "Zimbabwe",
        "code": "ZW",
        "phone": "263",
        "cities": [
            "Banket",
            "Beatrice",
            "Beitbridge",
            "Beitbridge District",
            "Bikita District",
            "Bindura",
            "Bindura District",
            "Binga",
            "Binga District",
            "Bubi District",
            "Buhera District",
            "Bulawayo",
            "Centenary",
            "Centenary District",
            "Chakari",
            "Chegutu",
            "Chegutu District",
            "Chimanimani",
            "Chimanimani District",
            "Chinhoyi",
            "Chipinge",
            "Chipinge District",
            "Chiredzi",
            "Chiredzi District",
            "Chirundu",
            "Chitungwiza",
            "Chivhu",
            "Chivi District",
            "Concession",
            "Dete",
            "Dorowa Mining Lease",
            "Epworth",
            "Esigodini",
            "Filabusi",
            "Glendale",
            "Gokwe",
            "Goromonzi District",
            "Guruve District",
            "Gutu District",
            "Gwanda",
            "Gwanda District",
            "Gweru",
            "Gweru District",
            "Harare",
            "Headlands",
            "Hurungwe District",
            "Hwange",
            "Hwange District",
            "Insiza",
            "Insiza District",
            "Inyati",
            "Kadoma",
            "Kadoma District",
            "Kamativi Mine",
            "Kariba",
            "Kariba District",
            "Karoi",
            "Kwekwe",
            "Kwekwe District",
            "Lalapanzi",
            "Lupane",
            "Lupane District",
            "Macheke",
            "Makonde District",
            "Makoni District",
            "Mangwe District",
            "Marondera",
            "Marondera District",
            "Mashava",
            "Masvingo",
            "Masvingo District",
            "Matobo",
            "Mazowe",
            "Mazowe District",
            "Mberengwa District",
            "Mhangura",
            "Mount Darwin",
            "Mudzi District",
            "Murehwa",
            "Murehwa District",
            "Mutare",
            "Mutare District",
            "Mutasa District",
            "Mutoko",
            "Mvuma",
            "Mvurwi",
            "Mwenezi District",
            "Nkayi District",
            "Norton",
            "Nyanga",
            "Nyanga District",
            "Nyazura",
            "Odzi",
            "Penhalonga",
            "Plumtree",
            "Raffingora",
            "Redcliff",
            "Rusape",
            "Rushinga District",
            "Ruwa",
            "Shamva",
            "Shamva District",
            "Shangani",
            "Shurugwi",
            "Shurugwi District",
            "Umzingwane District",
            "Victoria Falls",
            "Zvishavane",
            "Zvishavane District"
        ]
    }
]