import React, { useEffect, useState } from "react";
import { primary_color } from "../../../utils/color";
import { Container, Grid, Stack, Typography } from "@mui/material";
import Six_by_six from "../../../component/grid/Six_by_six";
import login_img from "../../../assets/login_img.png";
import "../auth.css";
import Input from "../../../component/input/Input";
import PasswordInput from "../../../component/input/PasswordInput";
import Btn from "../../../component/button/Button";
import DividerCom from "../../../component/divider/Divider";
import { BsFacebook, BsApple, BsGoogle } from "react-icons/bs";
import { CountrySelect } from "../../../component/select/CountrySelect";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register_user_post_async } from "../../../services/authService";
import { Show_Error_Message } from "../../../utils/common/Show_Error_Message";
import { asyncStatus } from "../../../utils/async_status";
import { setIdleStatus } from "../../../store/slice/AuthSlice";
import useTheme from "../../../hooks/theme";
import CountryInput from "../../../component/CountryInput/CountryInput";
import CityInput from "../../../component/CityInput/CityInput";
import style_css from "./style.module.css";

const Signup = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { register_status, register_data, register_error, register_error_msg } =
    useSelector((state) => state.authSlice);

  console.log(register_status);

  const [data, setData] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  const sethandlePhoneNumber = (e) => {
    // console.log(e);
    setData({ ...data, phone_number: e });
  };

  const setIsCheckedhandle = (e) => {
    setIsChecked(e);
  };
  const [passErr, setPassErr] = useState("");
  const [cities, setCities] = useState([]);
  const submitHandle = () => {
    const passwordPattern =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

    const validatePassword = (password) => {
      return passwordPattern.test(password);
    };
    if (validatePassword(data?.password)) {
      dispatch(register_user_post_async(data));
    } else {
      setPassErr(
        "Use 8 or more characters with a mix of letters, numbers & symbols in password!"
      );
    }

    // console.log("========>>>", data);
  };
  // useEffect(() => {
  //   if (register_status === asyncStatus.SUCCEEDED) {
  //     navigation("/verifyOtp");
  //     dispatch(setIdleStatus(setIdleStatus));
  //   }
  // }, [, register_status]);

  const select_country_handle = (event, value) => {
    if (value) {
      console.log("value", value);
      const { cities, label } = value;

      setCities(cities);
      setData((pre) => {
        return { ...pre, country: label, city: "" };
      });
    }
  };

  const select_city_handle = (event, value) => {
    console.log("value", value);
    // const { cities, country } = value;

    //   setCities(cities);

    setData((pre) => {
      return { ...pre, city: value };
    });
  };

  return (
    <Stack
      sx={{
        backgroundColor: primary_color,
        // height: { md: "140vh", lg: "140vh", sm: "110vh", xs: "110vh" },
      }}
    >
      <Container maxWidth={"lg"} sx={{ p: { md: 5, lg: 5, sm: 0, xs: 0 } }}>
        <Six_by_six
          first_section={
            <Stack>
              <Stack className="heading">Welcome to APPQuaintance!</Stack>
              <Stack className="sub_heading">Discover Your Passions and</Stack>
              <Stack className="sub_heading">Connect with the World</Stack>
              <Stack>
                <img width={530} src={login_img} />
              </Stack>
            </Stack>
          }
          second_section={
            <Stack
              sx={{
                backgroundColor: "#FFFFFF",
                p: { md: 8, lg: 8, sm: 5, xs: 5 },
                borderRadius: "10px",
                mt: 2,
              }}
            >
              <Stack className={style_css.title}>Sign up now</Stack>
              <Stack mt={register_error_msg ? 2 : 0}>
                <Show_Error_Message message={register_error_msg} />
              </Stack>
              <Stack mt={5}>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Input
                      id="first_name"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="First name"
                    />
                  </Grid>
                  <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Input
                      id="last_name"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="Last name"
                    />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Input
                      id="email"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="Email address"
                    />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Input
                      id="user_name"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="Username"
                    />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    {/* <Input
                      id="country"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="Country"
                    /> */}

                    <CountryInput
                      onChange={select_country_handle}
                      label="Country"
                      id="country"
                    />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    {/* <Input
                      id="city"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="City"
                    /> */}
                    <CityInput
                      onChange={select_city_handle}
                      cities_array={cities}
                      value={data?.city}
                    />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Input
                      className={style_css.phone_number}
                      id="phone_number"
                      type={"number"}
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="Phone number"
                    />
                    <Typography
                      sx={{
                        color: theme.text_color,
                        fontSize: 12,
                        textAlign: "end",
                      }}
                    >
                      Optional
                    </Typography>
                  </Grid>
                  {/* <Grid item md={12} lg={12} sm={12} xs={12}>
                    <CountrySelect
                      sethandlePhoneNumber={sethandlePhoneNumber}
                    />
                  </Grid> */}
                  <Grid item md={12} lg={12} sm={12} xs={12}>
                    <PasswordInput
                      setIsCheckedhandle={setIsCheckedhandle}
                      id="password"
                      onChange={(e) =>
                        setData({ ...data, [e.target.id]: e.target.value })
                      }
                      label="Password"
                      checkDetails={
                        "By creating an account, I agree to our Terms of Use and Privacy Policy"
                      }
                    />
                    {passErr ? (
                      <Typography color={"red"} fontSize={12}>
                        {passErr}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <Btn
                    disabled={!isChecked}
                    loading={
                      register_status === asyncStatus.LOADING ? true : false
                    }
                    onClick={() => submitHandle()}
                    label={"Sign Up"}
                    fullWidth={true}
                    sx={{
                      p: 1,
                      // backgroundColor: "#0D4587",
                      borderRadius: "8px",
                      mt: 3,
                    }}
                  />
                </Grid>
                <Stack
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <p> Already have an account?</p>{" "}
                  <p
                    className="loginButtonStyle"
                    onClick={() => navigation("/login")}
                    style={{
                      color: primary_color,
                      cursor: "pointer",
                      fontSize: "15.5px",
                      marginTop: "12px",
                      // fontWeight: "bold",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Log in
                  </p>
                </Stack>
              </Stack>
            </Stack>
          }
        />
      </Container>
    </Stack>
  );
};

export default Signup;
