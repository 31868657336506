import "./messages.css";
import React, { useEffect, useRef, useState } from "react";
import "./main.css";
import useTheme from "../../../hooks/theme";
import VoteBtn from "../../../component/VoteButton/VoteBtn";
import { ImArrowDown } from "react-icons/im";
import { Avatar, CircularProgress, Stack, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { get_messengers_async } from "../../../services/chatService";
import { asyncStatus } from "../../../utils/async_status";
import { setCurrentChat } from "../../../store/slice/chatSlice";
import Input from "../../../component/input/Input";
import MessageInput from "../../../component/input/MessageInput";
import { connectSocket } from "../../../utils/socket_functions";
import { socket } from "../../../config/apiHandle/apiHandle";
import { primary_color } from "../../../utils/color";
import { chatTimeAgo } from "../../../utils/common/dateFormater";
import { arraysEqual } from "../../../utils/common/common_function";
const Messages = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { messenger_status, messenger_data, messenger_error, currentChat } =
    useSelector((state) => state.chats);
  const { user_data } = useSelector((state) => state.authSlice);
  useEffect(() => {
    dispatch(get_messengers_async());
  }, []);
  const [message, setMessage] = useState("");
  // useEffect(() => {
  //   connectSocket();
  // }, []);
  // useEffect(() => {
  //   socket.emit("join_room", currentChat?._id);
  //   socket.on("join_room", currentChat?._id);
  // }, [currentChat]);
  // const sendMessage = () => {
  //   socket.emit("send_message", {
  //     room: currentChat?._id,
  //     message: message,
  //     id: user_data?._id,
  //     time:
  //       new Date(Date.now()).getHours() +
  //       ":" +
  //       new Date(Date.now()).getMinutes(),
  //     seen: false,
  //   });
  //   console.log("send message")
  // };
  const [messagesArray, setMessagesArray] = useState([]);
  // useEffect(() => {
  //   socket.on("receive_message", (message) => {
  //     let arr = [...messagesArray];
  //     arr.push(message);
  //     console.log(message);
  //     setMessagesArray(arr);
  //   });
  // }, []);

  const sendMessage = async () => {
    if (message !== "") {
      const messageData = {
        sender: user_data?._id,
        receiver: currentChat?.user_id,
        message: message,
      };
      // console.log(messageData);

      await socket.emit("send_message", messageData);
      setMessage("");
    }
  };

  // After sending a message
  useEffect(() => {
    socket.emit("get_previous_messages", {
      sender: user_data?._id,
      receiver: currentChat?.user_id,
    });
    // }, [,currentChat])
  });

  // Handle the received previous messages
  useEffect(() => {
    socket.on("previous_messages", (previousMessages) => {
      // let matched = true;
      // if (previousMessages.length === messagesArray.length) {
      //   previousMessages?.forEach((element, ind) => {
      //     if (element?._id !== messagesArray[ind]?._id) {
      //       console.log("a");
      //       matched = false;
      //     }
      //   });
      //   console.log("it is running", matched);
      //   if(matched){
      //     setMessagesArray(previousMessages);
      //   }
      // } else if (previousMessages.length !== messagesArray.length) {
        console.log("length",messagesArray.length,messagesArray);
      if (messagesArray.length) {
        if (arraysEqual(previousMessages, messagesArray)) {
          setMessagesArray([...previousMessages]);
          console.log("set",previousMessages);
        }
      } else {
        setMessagesArray([...previousMessages]);
        console.log("set2",previousMessages);
      }
      // }
    });
    
  }, []);
  console.log(messagesArray);
  const chatEndRef = useRef(null); // Reference to the bottom of the chat window

  // Function to scroll to the bottom of the chat window
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  useEffect(() => {
    // Whenever messages change, scroll to the bottom
    setTimeout(() => {
      
      scrollToBottom();
    }, 1000);
  }, [currentChat]);

  return (
    <div
      className="screen_position_container"
      style={{
        backgroundColor: theme.bg_color,
        color: theme.text_color,
        gap: "20px",
        // paddingTop: 10,
      }}
    >
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={1}
        padding={5}
        paddingBottom={0}
        paddingTop={2}
      >
        <Stack
          width={{ xl: 0.3, lg: 0.3, md: 0.3, sm: 1, xs: 1 }}
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: currentChat ? "none" : "flex",
            xs: currentChat ? "none" : "flex",
          }}
          height={"80vh"}
          sx={{ boxShadow: 2, border: "1px solid lightgray" }}
        >
          <Stack
            padding={2}
            margin={0}
            sx={{ backgroundColor: theme.card_bg_color }}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{ color: theme.icon_color, fontSize: 16, fontWeight: "bold" }}
            >
              Messages
            </Typography>
            {/* <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <MoreHorizIcon size={25} color="lightgray" />
            </Stack> */}
          </Stack>

          <Stack padding={2} className="scrolling" gap={2}>
            {messenger_status === asyncStatus.IDLE ||
            messenger_status === asyncStatus.LOADING ? (
              <Stack
                height={"80vh"}
                padding={2}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : messenger_status === asyncStatus.SUCCEEDED &&
              messenger_data.length ? (
              messenger_data?.map((e, i) => {
                return (
                  <Stack
                    key={i}
                    sx={{ cursor: "pointer" }}
                    onClick={() => dispatch(setCurrentChat(e))}
                    flexDirection={"row"}
                    alignItems={"center"}
                    backgroundColor={
                      currentChat?._id === e?._id ? theme.light_gray : ""
                    }
                  >
                    <Avatar
                      variant="rounded"
                      alt="Remy Sharp"
                      src={e?.profile_pic}
                      sx={{ width: 40, height: 40, cursor: "pointer" }}
                    />
                    <Stack
                      justifyContent={"space-between"}
                      alignItems={"start"}
                    >
                      <Typography
                        sx={{
                          color: theme.text_color,
                          fontSize: 12,
                          fontWeight: "bold",
                          ml: 2,
                        }}
                      >
                        {e?.first_name + " " + e?.last_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "gray",
                          fontWeight: "bolder",
                          fontSize: 10,
                          ml: 2,
                        }}
                      >
                        4:12pm
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })
            ) : messenger_status === asyncStatus.ERROR ? (
              <Stack padding={2}>
                <Typography>{messenger_error}</Typography>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <Stack
          width={{ xl: 0.7, lg: 0.7, md: 0.7, sm: 1, xs: 1 }}
          height={"80vh"}
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: !currentChat ? "none" : "flex",
            xs: !currentChat ? "none" : "flex",
          }}
          sx={{ boxShadow: 2, border: "1px solid lightgray" }}
        >
          {currentChat ? (
            <Stack height={"100%"}>
              <Stack
                padding={2}
                margin={0}
                sx={{ backgroundColor: theme.card_bg_color }}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Avatar
                    alt={currentChat?.first_name + " " + currentChat?.last_name}
                    src={currentChat?.profile_pic}
                    sx={{ width: 56, height: 56, cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      color: theme.text_color,
                      fontSize: 16,
                      fontWeight: "bold",
                      ml: 2,
                    }}
                  >
                    {currentChat?.first_name + " " + currentChat?.last_name}
                  </Typography>
                </Stack>
                <CloseIcon
                  onClick={() => dispatch(setCurrentChat(null))}
                  sx={{ cursor: "pointer", fontSize: 20, fontWeight: "bold" }}
                  color={theme.text_color}
                />
              </Stack>
              <Stack gap={2} padding={2} className="scrolling" height={"100%"}>
                {messagesArray?.map((e, i) => {
                  return (
                    <Stack
                      // ref={chatEndRef}
                      key={i}
                      alignItems={
                        e?.receiver === user_data?._id ? "start" : "end"
                      }
                    >
                      <Typography
                        sx={{
                          backgroundColor:
                            e?.receiver !== user_data?._id
                              ? primary_color
                              : theme.light_gray,
                          borderRadius: 2,
                          color: theme.text_color,
                          minWidth: 80,
                          padding: 2,
                          textAlign: "start",
                          display: "inline-block",
                          fontSize: 14,
                          position: "relative",
                        }}
                      >
                        {e.message}
                        <Typography
                          sx={{
                            fontSize: 10,
                            fontWeight: "bold",
                            color:
                              e?.receiver !== user_data?._id
                                ? "black"
                                : theme.text_color,
                            textAlign: "end",
                            paddingBottom: 1,
                            paddingRight: 1,
                            position: "absolute",
                            bottom: "-4px",
                            right: "-4px",
                          }}
                        >
                          {chatTimeAgo(e?.timestamp)}
                        </Typography>
                      </Typography>
                    </Stack>
                  );
                })}
                <div ref={chatEndRef}></div>
              </Stack>
              <Stack
                sx={{
                  bottom: 5,
                  backgroundColor: theme.card_bg_color,
                  margin: 1,
                }}
              >
                <MessageInput
                  iconClick={sendMessage}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={(e) => {
                    console.log("e", e);
                    if (e?.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack
              padding={2}
              margin={0}
              // flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"start"}
            >
              <Typography sx={{ color: theme.text_color }}>
                No chats selected
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default Messages;
