import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import { countries_data } from "../../utils/country";

export default function CityInput({ cities_array, value, onChange }) {
  console.log(value);
  return (
    <Autocomplete
      value={value || ""}
      id="country-select-demo"
      sx={{
        width: "100%",
        marginTop: 1,
      }}
      onChange={onChange}
      options={cities_array}
      autoHighlight
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {/* <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          /> */}

          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select your city"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
