import { Button, Popover } from "@mui/material";
import React, { useState } from "react";

const PopOver = ({popUpOpen,handlePopUpClose,children}) => {
  return (
    <div>
    <Popover
      id={"simple-popover"}
      open={popUpOpen?"simple-popover":undefined}
    //   anchorEl={anchorEl}
      onClose={handlePopUpClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
   {children}
    </Popover>
  </div>
  );
};

export default PopOver;
