import { createAsyncThunk } from "@reduxjs/toolkit";
import { type_constants } from "../store/constants/constants";
import { apiHandle } from "../config/apiHandle/apiHandle";

export const get_profile_async = createAsyncThunk(
    type_constants.GET_USER_PROFILE,
    async (id) => {
      try {
        const response = await apiHandle.get(`/user/get-profile/${id}`);
        const res_data = await response.data;
  
        return res_data;
      } catch (error) {
        console.log("error", { error });
        if (error?.response?.data) {
          throw Error(error.response.data.message);
        } else {
          throw Error(error.message);
        }
      }
    }
  );

export const follow_unfollow_user_async = createAsyncThunk(
    type_constants.FOLLOW_UNFOLLOW,
    async (id) => {
      try {
        const response = await apiHandle.post(`/user/follow-unfollow/${id}`);
        const res_data = await response.data;
  
        return res_data;
      } catch (error) {
        console.log("error", { error });
        if (error?.response?.data) {
          throw Error(error.response.data.message);
        } else {
          throw Error(error.message);
        }
      }
    }
  );

  

export const change_password_async = createAsyncThunk(
    type_constants.CHANGE_PASSWORD,
    async (obj) => {
      console.log("changing",obj);
      try {
        const response = await apiHandle.post(`/change-password`,obj);
        const res_data = await response.data;
  
        return res_data;
      } catch (error) {
        console.log("error", { error });
        if (error?.response?.data) {
          throw Error(error.response.data.message);
        } else {
          throw Error(error.message);
        }
      }
    }
  );

  

export const edit_profile_async = createAsyncThunk(
    type_constants.EDIT_PROFILE,
    async (obj) => {
      console.log("changing",obj);
      try {
        const response = await apiHandle.put(`/edit-profile`,obj);
        const res_data = await response.data;
  
        return res_data;
      } catch (error) {
        console.log("error", { error });
        if (error?.response?.data) {
          throw Error(error.response.data.message);
        } else {
          throw Error(error.message);
        }
      }
    }
  );

