import React, { useEffect, useState } from "react";
import "./Post.css"; // Post component ke liye CSS file
import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import { BiMessageRounded } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { IoMdShareAlt } from "react-icons/io";
import { primary_color } from "../../utils/color";
import CommentInput from "../input/CommentInput ";
import useTheme from "../../hooks/theme";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  bookmark_post_async,
  edited_post_async,
  post_comment_async,
  vote_post_async,
} from "../../services/newsFeedService";
import { useNavigate } from "react-router-dom";
import { asyncStatus } from "../../utils/async_status";
import { setVoteStatus } from "../../store/slice/newsFeedSlice";
import Btn from "../button/Button";
import VoteBtn from "../VoteButton/VoteBtn";
import useUiThemeStyles from "../../hooks/theme_styles";
import { timeAgo } from "../../utils/common/dateFormater";
import Carousel from "../Carousel/Carousel";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import SharePopOver from "../popover/SharePopOver";
import { baseURL } from "../../config/apiHandle/apiHandle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CreatePostCard from "../CreatePostCard/CreatePostCard";
import CreatePostModal from "../CreatePostModal/CreatePostModal";

const Post = ({ sideicon, data, edit_handle }) => {
  const { userAuth, user_data } = useSelector((state) => state.authSlice);
  const theme = useTheme();
  const {
    first_name,
    last_name,
    profile_pic,
    score,
    text_html_code_block,
    updatedAt,
    vote,
    image_url_array,
    comments_count,
    bookmarked,
    _id,
    title,
    user_id,
    community,
  } = data;
  console.log("data", data);
  const [isHovered, setIsHovered] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [likedPosts, setLikedPosts] = useState([]);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("edit modal is here...!");
  //   dispatch(
  //     edited_post_async({
  //       id: data.sub_topic_id,
  //       post_obj: {
  //         post_title: data.title,
  //         post_textarea: data.text_html_code_block,
  //         community_id: data.community_id,
  //       },
  //     })
  //   );
  // }, []);

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const navigation = useNavigate();
  const { vote_status, post_comment_status, bookmark_post_status } =
    useSelector((state) => state.newsFeed);
  useEffect(() => {
    if (vote_status === asyncStatus.SUCCEEDED) {
      dispatch(setVoteStatus(asyncStatus.IDLE));
    }
  }, [vote_status]);
  useEffect(() => {
    if (post_comment_status === asyncStatus.SUCCEEDED) {
      setCommentText("");
    }
  }, [post_comment_status]);
  const ui_styles = useUiThemeStyles();
  const [hovering, setHovering] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [bookmarkId, setBookmarkId] = useState("");
  console.log("likedPosts", likedPosts);

  return (
    <div
      className="post-container"
      style={{
        backgroundColor: theme.card_bg_color,
        borderRadius: "5px",
      }}
    >
      <Stack flexDirection={"row"} justifyContent={"space-between"} p={2}>
        <Stack flexDirection={"row"}>
          <Avatar
            alt="Remy Sharp"
            onClick={() => {
              if (userAuth) {
                console.log("aaa", user_id, user_data?._id);
                if (user_id !== user_data?._id) {
                  navigation(`/UnknownProfile/${user_id}`);
                } else {
                  navigation(`/Profile`);
                }
              } else {
                navigation("/login");
              }
            }}
            src={
              profile_pic
                ? profile_pic
                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeZOOwgZCtafWlGHkSlYjG278BQU7PEAQVvI1011y9&s"
            }
            sx={{ width: 57, height: 56, cursor: "pointer" }}
          />
          <Stack alignItems={"start"} ml={2}>
            <Typography
              textAlign={"start"}
              sx={{
                color: "#b5b5b5",
                fontSize: 13.5,
                mt: -0.6,
                cursor: "pointer",
              }}
              onClick={() => navigation(`/CommunityDetails/${community?._id}`)}
            >
              {community?.title}
            </Typography>
            <Typography
              onClick={() => {
                if (userAuth) {
                  if (user_id !== user_data?._id) {
                    navigation(`/UnknownProfile/${user_id}`);
                  } else {
                    navigation(`/Profile`);
                  }
                } else {
                  navigation("/login");
                }
              }}
              textAlign={"start"}
              sx={{
                color: theme.text_color,
                fontSize: 17,
                fontWeight: 700,
                mt: -0.3,
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              {first_name + " " + last_name}
            </Typography>
            <Typography
              textAlign={"start"}
              sx={{
                color: "#b5b5b5",
                fontSize: 13,
                mt: -0.2,
              }}
            >
              {timeAgo(updatedAt)}
            </Typography>
          </Stack>
        </Stack>

        <Stack onClick={edit_handle} style={{ marginLeft: "850px" }}>
          <EditNoteIcon
            sx={{
              fontSize: "28px",
              cursor: "pointer",
              color: theme.text_color,
            }}
          />
        </Stack>

        <Stack>{sideicon}</Stack>
      </Stack>
      <Stack alignItems={"start"} px={2} pt={1} mb={1}>
        <Typography color={theme.text_color} variant="h5">
          {title}
        </Typography>
      </Stack>
      <Stack p={2} pt={0}>
        <ReactQuill
          value={text_html_code_block}
          readOnly={true}
          theme="snow"
          style={{
            color: theme.text_color,
            fontSize: "10px",
          }}
          modules={{
            toolbar: false,
          }}
          formats={[
            "bold",
            "italic",
            "underline",
            "strike",
            "color",
            "list",
            "bullet",
            "ordered",
            "header",
            "align",
            "link",
            "image",
            "code-block",
            "blockquote",
          ]}
          dangerouslySetInnerHTML={{ __html: text_html_code_block }}
        />
      </Stack>
      {image_url_array?.length ? (
        <Stack width={1}>
          <Carousel images_array={image_url_array} />
        </Stack>
      ) : null}

      <Stack p={2}>
        <Stack flexDirection={"row"} alignItems={"center"} width={1}>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={1}
          >
            <Stack
              sx={{
                gap: { xs: 1, sm: 1, md: 2, lg: 2, xl: 3 },
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <VoteBtn
                sx={
                  vote === "upvote"
                    ? {
                        color: primary_color,
                        backgroundColor: "transparent",
                        "&.Mui-disabled": {
                          color: primary_color,
                        },
                      }
                    : {
                        color: "gray",
                        backgroundColor: "transparent",
                        "&.Mui-disabled": {
                          color: primary_color,
                        },
                      }
                }
                disabled={
                  vote_status === asyncStatus.LOADING && loadingId === _id
                }
                onClick={() => {
                  if (likedPosts.includes(_id) || vote) {
                    let a = likedPosts.filter((x) => x !== _id);
                    setLikedPosts(a);
                  } else {
                    setLikedPosts([...likedPosts, _id]);
                  }
                  setLoadingId(_id);
                  console.log("upvote");

                  if (vote === "upvote") {
                    dispatch(vote_post_async({ post_id: _id, vote: "" }));
                  } else {
                    dispatch(vote_post_async({ post_id: _id, vote: "upvote" }));
                  }
                }}
                icon={
                  likedPosts.includes(_id) || vote ? (
                    <FaHeart size={25} style={{ marginRight: "-12px" }} />
                  ) : (
                    <FaRegHeart size={25} style={{ marginRight: "-12px" }} />
                  )
                }
              />
              <Typography
                variant="p"
                sx={{
                  color: theme.text_color,
                  fontSize: 17,
                  fontWeight: "bold",
                }}
              >
                {score}
              </Typography>

              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
                onClick={() => navigation(`/Post/${_id}`)}
              >
                <BiMessageRounded
                  color={theme.dark_icon_text_color}
                  size={31}
                  style={{ marginRight: "12px" }}
                />
                <Typography
                  color={theme.dark_icon_text_color}
                  fontWeight={"bold"}
                  fontSize={16}
                  marginRight={1}
                >
                  {comments_count}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                gap: { xs: 1, sm: 1, md: 2, lg: 2, xl: 3 },
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  setBookmarkId(_id);
                  dispatch(bookmark_post_async(_id));
                }}
                disabled={
                  _id === bookmarkId &&
                  bookmark_post_status === asyncStatus.LOADING
                }
                children={
                  bookmarked ? (
                    <BookmarkOutlinedIcon color={"primary"} size={31} />
                  ) : (
                    <BookmarkBorderIcon
                      sx={{ color: theme.dark_icon_text_color }}
                      size={31}
                    />
                  )
                }
              />
            </Stack>
          </Stack>
          <Stack alignItems={"center"}>
            <SharePopOver
              // navigator={navigation}
              url={`https://app-quaintance-web.web.app/Post/${_id}`}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack p={2} flexDirection={"row"} alignItems={"center"} gap={1}>
        <Stack>
          <Avatar
            alt="Remy Sharp"
            src={profile_pic}
            sx={{ width: 56, height: 56 }}
          />
        </Stack>
        <Stack flexGrow={1}>
          <CommentInput
            loading={post_comment_status === asyncStatus.LOADING}
            onChange={(e) => setCommentText(e.target.value)}
            value={commentText}
            onClick={() => {
              dispatch(
                post_comment_async({ post_id: _id, comment_text: commentText })
              );
            }}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default Post;
